var controllers = require('../../module');

controllers.controller('SettingsShiftRoutinesCtrl', ['$scope', 'SettingsService', 'AlertService',
    function ($scope, SettingsService, AlertService) {
        $scope.dataLoaded = false;
        $scope.formData = {
            saveActioning: false
        };
        $scope.routines = [];

        SettingsService.getShiftRoutines()
            .success(function (data) {
                $scope.dataLoaded = true;
                $scope.routines = data.routines;
            })
            .error(function (err, status) {
                if (status != 403) {
                    AlertService.add('danger', 'We weren\'t able to load the shift routines just then. Please try again');
                }

                $state.go('dashboard');
            });

        $scope.addRoutine = function () {
            var name = $scope.formData.name;

            if (!name) return;

            var newRoutine = {
                name: name,
                items: [
                    {
                        displayOrder: 0,
                        typeId: 0,
                        length: 4
                    }
                ],
                numberOfMappings: 0
            };

            $scope.formData.saveActioning = true;

            SettingsService.addShiftRoutine({ routine: newRoutine })
                .success(function (data) {
                    $scope.routines.unshift(data.routine);

                    $scope.formData = {
                        saveActioning: false
                    };
                })
                .error(function (err, status) {
                    if (status != 403) {
                        AlertService.add('danger', 'We couldn\'t save the shift routine just then. Please try again');
                    }

                    $scope.formData.saveActioning = false;
                });
        };

        $scope.deleteRoutine = function (id) {
            if (!confirm("Are you sure you want to delete this shift routine?")) {
                return;
            }

            $scope.formData.saveActioning = true;

            SettingsService.deleteShiftRoutine(id)
                .success(function () {
                    $scope.routines.forEach(function (routine, index) {
                        if (routine.id === id) {
                            $scope.routines[index].isDeleted = true;
                        }
                    });

                    $scope.formData.saveActioning = false;
                })
                .error(function (err, status) {
                    if (status != 403) {
                        AlertService.add('danger', 'We couldn\'t delete the shift routine just then. Please try again');
                    }

                    $scope.formData.saveActioning = false;
                });
        };

        $scope.numberDisabled = function () {
            var numDisabled = 0;
            $scope.routines.forEach(function (routine) {
                if (routine.isDeleted) {
                    numDisabled++;
                }
            });

            return numDisabled;
        };

        $scope.hasEmptyRoutines = function () {
            var hasEmpty = false;

            $scope.routines.forEach(function (routine) {
                if (hasEmpty) return;

                if (!routine.items.length) {
                    hasEmpty = true;
                    return;
                }
            });

            return hasEmpty;
        };
    }
]);
