var controllers = require('../../module');

controllers.controller('RoutineShiftModalCtrl', ['$scope', '$uibModalInstance', 'data', 'EnvironmentDataService',
    function($scope, $uibModalInstance, data, EnvironmentDataService) {
        $scope.dataLoaded = false;
        $scope.shift = data.shift;
        $scope.readOnly = data.readOnly;

      EnvironmentDataService.fetch(EnvironmentDataService.DataType.ShiftType)
        .then(({ data }) => {
            $scope.shiftTypes = data.filter((s) => !s.deleted);
            $scope.dataLoaded = true;
        });

        $scope.close = function() {
            $uibModalInstance.dismiss('close');
        }

        $scope.save = function() {
            $scope.shift.start = $scope.shift.start.format('HH:mm:ss');
            $scope.shift.end = $scope.shift.end.format('HH:mm:ss');
            $scope.shift.rest = $scope.shift.rest.format('HH:mm:ss');

            $uibModalInstance.close($scope.shift);
        };
    }
]);
