module.exports = (
  $scope,
  $uibModal,
  $uibModalDrawer,
  $state,
  $window,
  $translate,
  $q,
  StaffService,
  AlertService,
  EnvironmentDataService,
  SessionService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.VIEW.DOCUMENTS.ERROR_500',
    'STAFF.VIEW.DOCUMENTS.ERROR_403',
    'STAFF.VIEW.DOCUMENTS.PERSONAL.ALERT_CONFIRM_ACKNOWLEDGE',
  ]);

  const userId = SessionService.getUserId();

  $scope.props = {
    loadingData: true,
    data: [],
  };

  $scope.onCategoryFilter = (selectedOptions) => {
    const [selectedCategoryId] = selectedOptions;

    if (selectedCategoryId) {
      $scope.props.data = $scope.props.masterList
        .filter(({ categoryId }) => categoryId === selectedCategoryId);
    } else {
      $scope.props.data = $scope.props.masterList.slice();
    }
  };

  function onApiError(status) {
    $scope.props.loadingData = false;

    if (status === 500) {
      AlertService.add('danger', translations['STAFF.VIEW.DOCUMENTS.ERROR_500']);
      return;
    }

    if (status === 403) {
      AlertService.add('warning', translations['STAFF.VIEW.DOCUMENTS.ERROR_403']);
      return;
    }

    $state.go('personal.schedule');
  }

  function loadData() {
    $scope.props.loadingData = true;

    $q.all([
      StaffService.getDocuments(userId),
      EnvironmentDataService.fetch(EnvironmentDataService.DataType.DocumentCategory),
    ]).then(([
      documentsResponse,
      documentCategoriesResponse,
    ]) => {
      const { results: documents } = documentsResponse.data;

      const documentCategories = documentCategoriesResponse.data
        .filter(({ deleted, employeeCanView }) => !deleted && employeeCanView);

      $scope.props.categoryOptions = documentCategories
        .map((category) => ({ ...category, label: category.name }));

      $scope.props.masterList = documents
        .flatMap((document) => {
          const category = $scope.props.categoryOptions
            .find(({ id }) => id === document.categoryId);

          if (!category) {
            return [];
          }

          return [{
            ...document,
            category,
            expiryDate: moment(document.expiryDate),
            expired: moment(document.expiryDate).add(1, 'day').isBefore(),
            friendlyFileSize: StaffService.getDocumentFriendlyFileSize(document.fileSize),
            uploadedDate: moment(document.uploadedBy.date),
          }];
        });

      $scope.props.masterList
        .sort((a, b) => b.uploadedDate.valueOf() - a.uploadedDate.valueOf());

      $scope.props.data = $scope.props.masterList.slice();
      $scope.props.loadingData = false;
    }).catch(({ status }) => onApiError(status));
  }

  loadData();

  $scope.acknowledgeDocument = (document) => {
    if (document.actionInProgress || document.acknowledged) {
      return;
    }

    if (!$window.confirm(translations['STAFF.VIEW.DOCUMENTS.PERSONAL.ALERT_CONFIRM_ACKNOWLEDGE'])) {
      return false;
    }

    document.actionInProgress = true;

    StaffService.updateDocument(document.id, { acknowledged: true })
      .then(() => {
        document.acknowledged = true;
        document.actionInProgress = false;
      })
      .catch(({ status }) => {
        document.actionInProgress = false;
        onApiError(status);
      });
  };

  $scope.viewDocument = (document) => {
    if (document.actionInProgress) {
      return;
    }

    document.actionInProgress = true;

    StaffService.getDocument(document.id, { download: true })
      .then(({ data }) => {
        const { signedUrl } = data;

        if (signedUrl) {
          $window.location = signedUrl;
        }

        document.actionInProgress = false;
      })
      .catch(({ status }) => {
        document.actionInProgress = false;
        onApiError(status);
      });
  };
};
