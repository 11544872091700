const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/addRoutineMappingForm.html');

var directives = require('../module');

directives.directive('rrAddRoutineMappingForm', function () {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        scope: {
            routine: '=',
            offset: '=',
            startDate: '=',
            endDate: '=',
            dateChanged: '&',
            user: '=',
            routines: '=',
            hasEnd: '=',
            valid: '='
        },
        controller: function ($scope) {
            if (!$scope.startDate) {
                $scope.startDate = moment();
            }

            if (!$scope.endDate) {
                $scope.endDate = moment();
            }

            if (!$scope.hasEnd) {
                $scope.hasEnd = false;
            }


            $scope.$watch('mappingForm.$valid', function (newVal) {
                $scope.valid = newVal;
            });

            $scope.startDateChanged = function (newDate) {
                $scope.startDate = newDate.clone();
                $scope.dateChanged({ newDate: newDate, isStart: true });
            };

            $scope.endDateChanged = function (newDate) {
                $scope.endDate = newDate.clone();
                $scope.dateChanged({ newDate: newDate, isStart: false });
            };

            $scope.currentRoutineLength = 0;

            $scope.$watch('routine', function (routineId) {
                if (!routineId) return;

                $scope.routines.forEach(function (routine) {
                    if (routine.id === routineId) {
                        $scope.currentRoutineLength = routine.length;
                    }
                });
            });
        }
    };
});
