const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/editRoutineMappingModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/staff/view/addRoutineMappingModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/staff/view/deleteRoutineMappingModal.html');

var controllers = require('../../module');

controllers.controller('ShiftRoutinesViewStaffCtrl', ['$scope', '$state', 'StaffService', 'SessionService', '$uibModal', 'AlertService', 'CommonService',
    function($scope, $state, StaffService, SessionService, $uibModal, AlertService, CommonService) {
        $scope.details = {
            dataLoaded: false
        };

        CommonService.getMetadata(['shiftRoutines'], [SessionService.getEntity()], true)
            .success(function(data) {
                $scope.details.metadata = data.shiftRoutines;

                refreshMappings();
            })
            .error(function() {
                $state.go('staff.overview');
            });

        $scope.isActive = function(index) {
            var mapping = $scope.details.mappings[index];

            var start = moment(mapping.start);
            var end = moment(mapping.end);

            if (!end.isValid()) {
                return moment().isAfter(start);
            }

            return moment().isAfter(start) && moment().startOf('day').isBefore(end);
        };

        function refreshMappings() {
            $scope.details.dataLoaded = false;

            StaffService.getRoutineMappings($scope.userId)
                .success(function(data) {
                        $scope.details.dataLoaded = true;
                        $scope.details.mappings = data.mappings;
                        $scope.details.mappings.forEach(function(mapping) {
                            if (mapping.end) {
                                var displayEnd = moment(mapping.end);
                                displayEnd.subtract(1, 'day');
                                mapping.display_end = displayEnd.format();
                            } else {
                                // If we don't do this, display_end is undefined which causes moment to default to now
                                mapping.display_end = null;
                            }
                        });

                })
                .error(function(err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', 'We couldn\'t find the staff member you were looking for. Please try again.');
                    } else {
                        AlertService.add('warning', 'You don\'t have sufficient permissions to perform that action!');
                    }

                    $state.go('staff.overview');
                });
        }

        $scope.editEndDate = function(index) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'EditRoutineMappingStaffModalCtrl',
                resolve: {
                    data: function() {
                        return {
                            mapping: $scope.details.mappings[index],
                            userId: parseInt($scope.userId),
                            mappings: $scope.details.mappings
                        }
                    }
                }
            });

            modalInstance.result.then(function() {
                refreshMappings();
            });
        };

        $scope.addRoutine = function() {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'AddRoutineMappingStaffModalCtrl',
                resolve: {
                    data: function() {
                        return {
                            user: $scope.userData,
                            mappings: $scope.details.mappings
                        }
                    }
                }
            });

            modalInstance.result.then(function() {
                refreshMappings();
            });
        };

        $scope.deleteRoutine = function(index) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate3,
                controller: 'DeleteRoutineMappingStaffModalCtrl',
                resolve: {
                    data: function() {
                        return {
                            mappingId: $scope.details.mappings[index].id,
                            userId: $scope.userId,
                        }
                    }
                }
            });

            modalInstance.result.then(function () {
                refreshMappings();
            });
        }
    }
]);
