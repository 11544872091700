export const getColumns = (
  $translate,
) => {
  const translations = $translate.instant([
    'STAFF.OVERVIEW.COLUMN_ID',
    'STAFF.OVERVIEW.COLUMN_HR_ID',
    'STAFF.OVERVIEW.COLUMN_PAYROLL_ID',
    'STAFF.OVERVIEW.COLUMN_BIRTH_DATE',
    'STAFF.OVERVIEW.COLUMN_EMAIL',
    'STAFF.OVERVIEW.COLUMN_MOBILE',
    'STAFF.OVERVIEW.COLUMN_OTHER_NAMES',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_LINE_1',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_LINE_2',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_CITY',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_COUNTY',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_POST_CODE',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_COUNTRY',
    'STAFF.OVERVIEW.COLUMN_PREFERRED_NAME',
    'STAFF.OVERVIEW.COLUMN_GENDER',
    'STAFF.OVERVIEW.COLUMN_TITLE',
    'STAFF.OVERVIEW.COLUMN_ETHNICITY',
    'STAFF.OVERVIEW.COLUMN_NATIONALITY',
    'STAFF.OVERVIEW.COLUMN_MARITAL_STATUS',
    'STAFF.OVERVIEW.COLUMN_SETTLED_STATUS',
    'STAFF.OVERVIEW.COLUMN_PASSPORT_NUMBER',
    'STAFF.OVERVIEW.COLUMN_PASSPORT_EXPIRY_DATE',
    'STAFF.OVERVIEW.COLUMN_WORK_EMAIL',
    'STAFF.OVERVIEW.COLUMN_WORK_PHONE',
    'STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_NAME',
    'STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_NUMBER',
    'STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_SORT_CODE',
    'STAFF.OVERVIEW.COLUMN_PAYROLL_PAYMENT_METHOD',
    'STAFF.OVERVIEW.COLUMN_NATIONAL_INSURANCE_NUMBER',
    'STAFF.OVERVIEW.COLUMN_STUDENT_LOANS',
    'STAFF.OVERVIEW.COLUMN_AVERAGE_HOURS_DAY',
    'STAFF.OVERVIEW.COLUMN_AVERAGE_HOURS_HALF_DAY',
    'STAFF.OVERVIEW.COLUMN_AVERAGE_HOURS_WEEK',
    'STAFF.OVERVIEW.COLUMN_PRE_AVERAGE_HOURS_PER_DAY',
    'STAFF.OVERVIEW.COLUMN_PRE_AVERAGE_HOURS_DATE',
    'STAFF.OVERVIEW.COLUMN_PERMISSION_LEVEL_NAME',
    'STAFF.OVERVIEW.COLUMN_ANONYMISED',
    'STAFF.OVERVIEW.COLUMN_ANONYMISE_DATE',
    'STAFF.OVERVIEW.COLUMN_JOIN_DATE',
    'STAFF.OVERVIEW.COLUMN_LEAVE_DATE',
    'STAFF.OVERVIEW.COLUMN_LEAVE_DATE_EDITED_BY',
    'STAFF.OVERVIEW.COLUMN_LEAVE_DATE_EDITED_DATE',
    'STAFF.OVERVIEW.COLUMN_LENGTH_OF_SERVICE',
    'STAFF.OVERVIEW.COLUMN_RECRUITMENT_SOURCE',
    'STAFF.OVERVIEW.COLUMN_LEAVER_REASON',
    'STAFF.OVERVIEW.COLUMN_LEAVER_REHIRE',
    'STAFF.OVERVIEW.COLUMN_EMPLOYEE_NUMBER',
    'STAFF.OVERVIEW.COLUMN_NOTICE_PERIOD',
    'STAFF.OVERVIEW.COLUMN_STARTER_DECLARATION',
    'STAFF.OVERVIEW.COLUMN_ALLOWANCES_ATTESTED_FINALISED',
    'STAFF.OVERVIEW.COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY',
    'STAFF.OVERVIEW.COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE',
    'STAFF.OVERVIEW.COLUMN_SITE_NAME',
    'STAFF.OVERVIEW.COLUMN_SITE_ID',
    'STAFF.OVERVIEW.COLUMN_DEPARTMENT_NAME',
    'STAFF.OVERVIEW.COLUMN_DEPARTMENT_ID',
    'STAFF.OVERVIEW.COLUMN_JOB_ROLE_NAME',
    'STAFF.OVERVIEW.COLUMN_COMPANY_NAME',
    'STAFF.OVERVIEW.COLUMN_CONTRACT_BASIS',
    'STAFF.OVERVIEW.COLUMN_LINE_MANAGER_NAME',
    'STAFF.OVERVIEW.COLUMN_STAFF_GROUP_NAME',
    'STAFF.OVERVIEW.COLUMN_WORKS_PUBLIC_HOLIDAYS',
    'STAFF.OVERVIEW.COLUMN_WORKING_TIME_OPT_OUT',
    'STAFF.OVERVIEW.COLUMN_MIN_OBLIGATION_VALUE',
    'STAFF.OVERVIEW.COLUMN_MIN_OBLIGATION_UNIT',
    'STAFF.OVERVIEW.COLUMN_MIN_OBLIGATION_PERIOD',
    'STAFF.OVERVIEW.COLUMN_MAX_OBLIGATION_VALUE',
    'STAFF.OVERVIEW.COLUMN_MAX_OBLIGATION_UNIT',
    'STAFF.OVERVIEW.COLUMN_MAX_OBLIGATION_PERIOD',
    'STAFF.OVERVIEW.COLUMN_DEPARTMENT_CURRENCY_CODE',
    'STAFF.OVERVIEW.COLUMN_PAYROLL_CALENDAR_NAME',
    'STAFF.OVERVIEW.COLUMN_PAYROLL_CALENDAR_FREQUENCY',
    'STAFF.OVERVIEW.COLUMN_PAY_AMOUNT',
    'STAFF.OVERVIEW.COLUMN_PAY_AMOUNT_TYPE',
    'STAFF.OVERVIEW.COLUMN_PAY_UPLIFT',
  ]);

  return {
    id: translations['STAFF.OVERVIEW.COLUMN_ID'],
    hrId: translations['STAFF.OVERVIEW.COLUMN_HR_ID'],
    payrollId: translations['STAFF.OVERVIEW.COLUMN_PAYROLL_ID'],
    birthDate: translations['STAFF.OVERVIEW.COLUMN_BIRTH_DATE'],
    email: translations['STAFF.OVERVIEW.COLUMN_EMAIL'],
    mobile: translations['STAFF.OVERVIEW.COLUMN_MOBILE'],
    otherNames: translations['STAFF.OVERVIEW.COLUMN_OTHER_NAMES'],
    addressLine1: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_LINE_1'],
    addressLine2: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_LINE_2'],
    addressCity: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_CITY'],
    addressCounty: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_COUNTY'],
    addressPostCode: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_POST_CODE'],
    addressCountry: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_COUNTRY'],
    preferredName: translations['STAFF.OVERVIEW.COLUMN_PREFERRED_NAME'],
    genderName: translations['STAFF.OVERVIEW.COLUMN_GENDER'],
    titleName: translations['STAFF.OVERVIEW.COLUMN_TITLE'],
    ethnicityName: translations['STAFF.OVERVIEW.COLUMN_ETHNICITY'],
    nationalityName: translations['STAFF.OVERVIEW.COLUMN_NATIONALITY'],
    maritalStatusName: translations['STAFF.OVERVIEW.COLUMN_MARITAL_STATUS'],
    ukSettledStatusName: translations['STAFF.OVERVIEW.COLUMN_SETTLED_STATUS'],
    passportNumber: translations['STAFF.OVERVIEW.COLUMN_PASSPORT_NUMBER'],
    passportExpiryDate: translations['STAFF.OVERVIEW.COLUMN_PASSPORT_EXPIRY_DATE'],
    workEmail: translations['STAFF.OVERVIEW.COLUMN_WORK_EMAIL'],
    workPhone: translations['STAFF.OVERVIEW.COLUMN_WORK_PHONE'],
    bankAccountName: translations['STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_NAME'],
    bankAccountNumber: translations['STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_NUMBER'],
    bankAccountSortCode: translations['STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_SORT_CODE'],
    payrollPaymentMethod: translations['STAFF.OVERVIEW.COLUMN_PAYROLL_PAYMENT_METHOD'],
    ukNationalInsuranceNumber: translations['STAFF.OVERVIEW.COLUMN_NATIONAL_INSURANCE_NUMBER'],
    ukStudentLoans: translations['STAFF.OVERVIEW.COLUMN_STUDENT_LOANS'],
    averageHoursDay: translations['STAFF.OVERVIEW.COLUMN_AVERAGE_HOURS_DAY'],
    averageHoursHalfDay: translations['STAFF.OVERVIEW.COLUMN_AVERAGE_HOURS_HALF_DAY'],
    averageHoursWeek: translations['STAFF.OVERVIEW.COLUMN_AVERAGE_HOURS_WEEK'],
    preRotareadyAverageHoursPerDay: translations['STAFF.OVERVIEW.COLUMN_PRE_AVERAGE_HOURS_PER_DAY'],
    preRotareadyAverageHoursDate: translations['STAFF.OVERVIEW.COLUMN_PRE_AVERAGE_HOURS_DATE'],
    permissionLevelName: translations['STAFF.OVERVIEW.COLUMN_PERMISSION_LEVEL_NAME'],
    anonymised: translations['STAFF.OVERVIEW.COLUMN_ANONYMISED'],
    anonymisedDate: translations['STAFF.OVERVIEW.COLUMN_ANONYMISE_DATE'],
    employmentJoinDate: translations['STAFF.OVERVIEW.COLUMN_JOIN_DATE'],
    employmentLeaveDate: translations['STAFF.OVERVIEW.COLUMN_LEAVE_DATE'],
    employmentLeaveDateLastEditedFullName: translations['STAFF.OVERVIEW.COLUMN_LEAVE_DATE_EDITED_BY'],
    employmentLeaveDateLastEditedDate: translations['STAFF.OVERVIEW.COLUMN_LEAVE_DATE_EDITED_DATE'],
    employmentLengthOfService: translations['STAFF.OVERVIEW.COLUMN_LENGTH_OF_SERVICE'],
    employmentRecruitmentSourceName: translations['STAFF.OVERVIEW.COLUMN_RECRUITMENT_SOURCE'],
    employmentLeaverReasonName: translations['STAFF.OVERVIEW.COLUMN_LEAVER_REASON'],
    employmentLeaverRehire: translations['STAFF.OVERVIEW.COLUMN_LEAVER_REHIRE'],
    employmentEmployeeNumber: translations['STAFF.OVERVIEW.COLUMN_EMPLOYEE_NUMBER'],
    employmentNoticePeriod: translations['STAFF.OVERVIEW.COLUMN_NOTICE_PERIOD'],
    employmentUkStarterDeclaration: translations['STAFF.OVERVIEW.COLUMN_STARTER_DECLARATION'],
    employmentAllowancesFinalised: translations['STAFF.OVERVIEW.COLUMN_ALLOWANCES_ATTESTED_FINALISED'],
    employmentAllowancesFinalisedFullName: translations['STAFF.OVERVIEW.COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY'],
    employmentAllowancesFinalisedDate: translations['STAFF.OVERVIEW.COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE'],
    appointmentEntityGroupName: translations['STAFF.OVERVIEW.COLUMN_SITE_NAME'],
    appointmentEntityGroupId: translations['STAFF.OVERVIEW.COLUMN_SITE_ID'],
    appointmentEntityName: translations['STAFF.OVERVIEW.COLUMN_DEPARTMENT_NAME'],
    appointmentEntityId: translations['STAFF.OVERVIEW.COLUMN_DEPARTMENT_ID'],
    appointmentPositionName: translations['STAFF.OVERVIEW.COLUMN_JOB_ROLE_NAME'],
    appointmentCompanyName: translations['STAFF.OVERVIEW.COLUMN_COMPANY_NAME'],
    appointmentContractBasis: translations['STAFF.OVERVIEW.COLUMN_CONTRACT_BASIS'],
    appointmentLineManagerName: translations['STAFF.OVERVIEW.COLUMN_LINE_MANAGER_NAME'],
    appointmentStaffGroupName: translations['STAFF.OVERVIEW.COLUMN_STAFF_GROUP_NAME'],
    appointmentWorksPublicHolidays: translations['STAFF.OVERVIEW.COLUMN_WORKS_PUBLIC_HOLIDAYS'],
    appointmentWorkingTimeOptOut: translations['STAFF.OVERVIEW.COLUMN_WORKING_TIME_OPT_OUT'],
    appointmentMinObligationValue: translations['STAFF.OVERVIEW.COLUMN_MIN_OBLIGATION_VALUE'],
    appointmentMinObligationUnit: translations['STAFF.OVERVIEW.COLUMN_MIN_OBLIGATION_UNIT'],
    appointmentMinObligationPeriod: translations['STAFF.OVERVIEW.COLUMN_MIN_OBLIGATION_PERIOD'],
    appointmentMaxObligationValue: translations['STAFF.OVERVIEW.COLUMN_MAX_OBLIGATION_VALUE'],
    appointmentMaxObligationUnit: translations['STAFF.OVERVIEW.COLUMN_MAX_OBLIGATION_UNIT'],
    appointmentMaxObligationPeriod: translations['STAFF.OVERVIEW.COLUMN_MAX_OBLIGATION_PERIOD'],
    appointmentEntityCurrencyCode: translations['STAFF.OVERVIEW.COLUMN_DEPARTMENT_CURRENCY_CODE'],
    appointmentPayrollCalendarName: translations['STAFF.OVERVIEW.COLUMN_PAYROLL_CALENDAR_NAME'],
    appointmentPayrollCalendarFrequency: translations['STAFF.OVERVIEW.COLUMN_PAYROLL_CALENDAR_FREQUENCY'],
    payAmount: translations['STAFF.OVERVIEW.COLUMN_PAY_AMOUNT'],
    payAmountType: translations['STAFF.OVERVIEW.COLUMN_PAY_AMOUNT_TYPE'],
    payUplift: translations['STAFF.OVERVIEW.COLUMN_PAY_UPLIFT'],
  };
};

export const defaultColumnIds = [
  'appointmentEntityGroupName',
  'appointmentEntityName',
  'appointmentPositionName',
];
