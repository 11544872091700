const moment = require('moment-timezone');
const { default: TypingActions } = require('../../constants/messaging/typingActions');
const { TIME_MINUTES_BETWEEN_TIMESTAMPS, UTC_TO_UNIX_PRECISION } = require('../../constants/messaging/constants');

module.exports = (
  $scope,
  $document,
  $timeout,
  $translate,
  SessionService,
  MessagingService,
) => {
  'ngInject';

  const PAGINATED_HISTORY_COUNT = 15;

  $scope.props = {
    listOfMessages: [],
    isLoadingMore: false,
    isAllMessagesFetched: false,
    ownUserId: null,
    hasNewMessages: false,
    typingUsersMessage: '',
  };

  $scope.channelName = null;
  $scope.conversationUsers = [];
  $scope.earliestConversationMessage = null;
  $scope.typingUsers = [];

  const formatMessageListWithDate = ({ messageList, isNewMessage = false }) => {
    if (!messageList) {
      return null;
    }

    // Get the last formatted message. On adding a new message,
    // this will be in position length-2 as length-1 is a new message
    const lastFormattedMessage = messageList.length > 1
      ? messageList[messageList.length - (isNewMessage ? 2 : 1)]
      : {};

    let { messageDate: previousMessageDate } = lastFormattedMessage;

    return messageList.map((message) => {
      const { showTimestamp, timetoken } = message;

      if (showTimestamp !== undefined) {
        return message;
      }

      const messageDate = moment.unix(timetoken / UTC_TO_UNIX_PRECISION);

      // We should show a timestamp if the previous message was received more
      // than TIME_MINUTES_BETWEEN_TIMESTAMPS minutes ago
      const shouldShowTimestamp = previousMessageDate
        ? messageDate.diff(previousMessageDate, 'minutes') > TIME_MINUTES_BETWEEN_TIMESTAMPS
        : true;

      previousMessageDate = messageDate;

      return {
        showTimestamp: shouldShowTimestamp,
        messageDate,
        ...message,
      };
    });
  };

  const listener = {
    message: async ({ message, channel, timetoken }) => {
      if (channel === $scope.channelName) {
        const timestamp = moment.unix(timetoken / UTC_TO_UNIX_PRECISION);
        const user = $scope.conversationUsers.find(({ userId }) => message.userId === userId);

        const list = $scope.props.listOfMessages;

        list.push({
          timetoken: +timetoken,
          entry: {
            ...message,
            user,
          },
          timestamp,
        });

        $scope.props.listOfMessages = formatMessageListWithDate({
          messageList: list,
          isNewMessage: true,
        });

        if ($document[0].getElementById('messagesList')) {
          const { scrollHeight, scrollTop, clientHeight } = $document[0].getElementById('messagesList');

          // Without the $scope.$apply(), the update to the UI takes a long time (sometimes >10s).
          // There must be a reason for this being the case. However, triggering the apply
          // triggers an instantaneous update which resembles what should be expected.
          if ((scrollHeight - scrollTop) === clientHeight) {
            await MessagingService.setLastReadTimetoken($scope.channelName, timetoken);
            $scope.$apply();
            $scope.scrollToBottom();
          } else {
            $scope.props.hasNewMessages = true;
            $scope.$apply();
          }
        } else {
          await MessagingService.setLastReadTimetoken($scope.channelName, timetoken);
        }
      }
    },
    signal: ({
      channel,
      message: {
        type,
        userId,
      },
    }) => {
      if (userId !== $scope.props.ownUserId && $scope.channelName === channel) {
        if (type === TypingActions.TYPING) {
          $scope.typingUsers.push(userId);
        } else {
          const filteredUsers = $scope.typingUsers.filter((u) => u !== userId);
          $scope.typingUsers = filteredUsers;
        }
      }

      if ($scope.typingUsers.length === 1) {
        const [typingUserId] = $scope.typingUsers;
        const { firstName, lastName } = $scope.conversationUsers
          .find((u) => u.userId === typingUserId);

        $scope.props.typingUsersMessage = $translate.instant('MESSAGING.CONVERSATION.INDIVIDUAL_TYPING', { name: `${firstName} ${lastName}` });
      } else if ($scope.typingUsers.length > 1) {
        $scope.props.typingUsersMessage = $translate.instant('MESSAGING.CONVERSATION.MULTIPLE_TYPING');
      } else {
        $scope.props.typingUsersMessage = '';
      }
      $scope.$apply();
    },
  };

  $scope.$on('$destroy', () => {
    MessagingService.removeListener(listener);
  });

  $scope.scrollToBottom = () => {
    if ($document[0].getElementById('messagesList') != null) {
      $document[0].getElementById('messagesList').scrollTop = $document[0].getElementById('messagesList').scrollHeight;
    }

    $scope.props.hasNewMessages = false;
  };

  $scope.openConversation = async () => {
    $scope.props.ownUserId = SessionService.getUserId();
    $scope.channelName = $scope.channelId;

    if (!$scope.conversationUsers.length) {
      $scope.conversationUsers = await MessagingService.getUserInformation($scope.participantIds);
    }

    const response = await MessagingService.getMessages({
      channel: $scope.channelName,
      conversationUsers: $scope.conversationUsers,
      count: PAGINATED_HISTORY_COUNT,
    });

    if (response) {
      const { mappedMessages, startTimeToken, endTimeToken } = response;

      if (mappedMessages.length < PAGINATED_HISTORY_COUNT) {
        $scope.isAllMessagesFetched = true;
      }

      $scope.earliestConversationMessage = startTimeToken;
      $scope.props.listOfMessages = formatMessageListWithDate({
        messageList: mappedMessages,
      });

      MessagingService.setLastReadTimetoken($scope.channelName, endTimeToken);
      MessagingService.checkAndUpdateUnreadCount($scope.channelName);
    }

    $timeout($scope.scrollToBottom, 500);
  };

  $scope.fetchMoreMessages = async () => {
    if ($scope.props.isLoadingMore === false && !$scope.isAllMessagesFetched) {
      $scope.props.isLoadingMore = true;

      const { mappedMessages, startTimeToken } = await MessagingService.getMessages({
        channel: $scope.channelName,
        conversationUsers: $scope.conversationUsers,
        start: $scope.earliestConversationMessage,
        count: PAGINATED_HISTORY_COUNT,
      });

      if (mappedMessages && mappedMessages.length) {
        if (mappedMessages.length < PAGINATED_HISTORY_COUNT) {
          $scope.isAllMessagesFetched = true;
        }

        $scope.earliestConversationMessage = startTimeToken;

        const newMessageList = [
          ...mappedMessages,
          ...$scope.props.listOfMessages,
        ];

        $scope.props.listOfMessages = formatMessageListWithDate({
          messageList: newMessageList,
        });
      } else {
        $scope.earliestConversationMessage = 0;
      }

      $scope.props.isLoadingMore = false;
    }
  };

  $timeout(async () => {
    MessagingService.addListener(listener);
    $scope.openConversation();
  });
};
