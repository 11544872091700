var controllers = require('../../module');

controllers.controller(
    'PayRunsXeroCtrl',
    [
        '$scope',
        '$http',
        'AlertService',
        'ENDPOINT_API',
        function ($scope, $http, AlertService, ENDPOINT_API) {
            var apiBaseUrl = ENDPOINT_API + '/integration/xero';
            $scope.dataLoaded = false;
            $scope.formData = {};
            $scope.payRunsLoaded = true;
            $scope.payRuns = [];

            function handleError(error, status) {
                if (status !== 403) {
                    AlertService.add('danger', 'We\'re unable to communicate with Xero. Please check the settings to ensure you are connected to Xero.');
                }
            }

            $http.get(apiBaseUrl + '/organisations')
                .success(function (data) {
                    $scope.organisations = data.organisations;
                    $scope.dataLoaded = true;
                })
                .error(function (error, status) {
                    handleError(error, status);
                });

            $scope.loadPayRuns = function () {
                if (!$scope.formData.organisationId) {
                    return;
                }

                $scope.payRunsLoaded = false;

                $http.get(apiBaseUrl + '/organisation/' + $scope.formData.organisationId + '/payRuns')
                    .success(function (data) {
                        $scope.payRuns = data.payRuns;
                        $scope.payRunCalendars = data.payRunCalendars;

                        $scope.payRuns.forEach(function (run) {
                            var payRunCalendar = $scope.payRunCalendars.find(function (calendar) {
                                return calendar.payrollCalendarID === run.payrollCalendarID;
                            });

                            var text = '';

                            if (run.payRunType === 'Unscheduled') {
                                text += 'Unscheduled - ';
                            }

                            if (payRunCalendar) {
                                text += payRunCalendar.name + ' ';
                            }

                            text += 'ending ' + moment(run.periodEndDate).format('ll');

                            run.payPeriodText = text;
                        });

                        $scope.payRunsLoaded = true;
                    })
                    .error(function (error, status) {
                        handleError(error, status);
                    });
            }
        }
    ]
);
