const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/auditUserWidget.html');

const directives = require('../module');

directives.directive('rrAuditUserWidget', () => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      audit: '=?',
      size: '@?',
      sref: '@?',
      preamble: '@?',
    },
    link: ($scope) => {
      $scope.props = {};

      $scope.$watch('audit', (audit) => {
        if (!audit) {
          return;
        }

        const date = audit.date ? moment(audit.date) : undefined;

        if (date) {
          $scope.props.dateFromNow = date.fromNow();
          $scope.props.dateFormat = date.format('lll');
        }
      });
    },
  };
});
