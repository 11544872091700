module.exports = (
  $scope,
  $state,
  $stateParams,
  $translate,
  $window,
  StaffCommon,
  AvailabilityService,
  EnvironmentDataService,
  SessionService,
  AlertService,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.ABSENCE_EXPORT.ERROR_500',
  ]);

  let selectedDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('week');
  let selectedDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('week').add(6, 'days');
  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];
  const defaultTypeIds = $stateParams.typeIds
    ? $stateParams.typeIds.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];
  const defaultApprovalStates = $stateParams.statuses
    ? $stateParams.statuses.split(',') : [1];
  const defaultUserId = $stateParams.userId || undefined;
  const defaultPositionIds = $stateParams.positionIds ? $stateParams.positionIds.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];
  const defaultStaffGroupIds = $stateParams.staffGroupIds ? $stateParams.staffGroupIds.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];

  $scope.props = {
    loadingData: false,
    noMoreData: false,
    data: [],
    approvalStates: [
      {
        id: 0,
        label: StaffCommon.getLabelFromApprovalState(0),
      },
      {
        id: 1,
        label: StaffCommon.getLabelFromApprovalState(1),
      },
      {
        id: 2,
        label: StaffCommon.getLabelFromApprovalState(2),
      },
    ],
    defaultEntityIds,
    defaultTypeIds,
    defaultApprovalStates,
    defaultUserId,
    defaultPositionIds,
    defaultStaffGroupIds,
    defaultDateFilter: selectedDateStart && selectedDateEnd ? {
      option: 4,
      dateStart: selectedDateStart,
      dateEnd: selectedDateEnd,
    } : {
      option: 1,
    },
    includeCancelled: false,
    activeUsers: true,
  };

  $scope.getDataParams = {
    overlapsPeriod: selectedDateStart && selectedDateEnd
      ? `${selectedDateStart.format('YYYY-MM-DD')}/${selectedDateEnd.clone().add(1, 'day').format('YYYY-MM-DD')}`
      : undefined,
    'userCurrentEntityIds[]': defaultEntityIds,
    'absenceTypeIds[]': defaultTypeIds,
    'statuses[]': defaultApprovalStates,
    'currentPositionIds[]': defaultPositionIds,
    'currentStaffGroupIds[]': defaultStaffGroupIds,
    userId: defaultUserId,
    cancelled: false,
    userIsActive: true,
    locale: SessionService.getUserLocale(),
    sortOrder: 'startDateAsc',
    page: 1,
    limit: 50,
  };

  $scope.loadData = async (reset) => {
    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.loadingData = true;

    if (reset) {
      $scope.getDataParams.page = 1;
      $scope.props.noMoreData = false;
      $scope.props.data = [];
    }

    const { getDataParams } = $scope;
    const entityIds = getDataParams['userCurrentEntityIds[]'];
    const typeIds = getDataParams['absenceTypeIds[]'];
    const statuses = getDataParams['statuses[]'];
    const positionIds = getDataParams['currentPositionIds[]'];
    const staffGroupIds = getDataParams['currentStaffGroupIds[]'];

    $state.go('.', {
      start: selectedDateStart ? selectedDateStart.format('YYYY-MM-DD') : undefined,
      end: selectedDateEnd ? selectedDateEnd.format('YYYY-MM-DD') : undefined,
      entityIds: entityIds ? entityIds.join(',') : undefined,
      typeIds: typeIds ? typeIds.join(',') : undefined,
      statuses: statuses ? statuses.join(',') : undefined,
      userId: getDataParams.userId,
      positionIds: positionIds ? positionIds.join(',') : undefined,
      staffGroupIds: staffGroupIds ? staffGroupIds.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
      inherit: false,
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    try {
      const { data } = await AvailabilityService.exportAbsence(getDataParams);
      const { results } = data;

      $scope.props.data.push(...results);
      $scope.getDataParams.page += 1;

      if (results.length < getDataParams.limit) {
        $scope.props.noMoreData = true;
      }

      $scope.props.loadingData = false;
      $scope.$apply();
    } catch ({ status }) {
      $scope.props.noMoreData = true;
      $scope.props.loadingData = false;

      if (status === 500) {
        AlertService.add('danger', translations['REPORTS.ABSENCE_EXPORT.ERROR_500']);
      }
    }
  };

  $scope.onDateFilter = ({
    option,
    dateEnd,
    dateStart,
  }) => {
    if (option === 1) {
      selectedDateStart = undefined;
      selectedDateEnd = undefined;
      $scope.getDataParams.overlapsPeriod = undefined;
    } else {
      selectedDateStart = dateStart.clone();
      selectedDateEnd = dateEnd.clone();
      $scope.getDataParams.overlapsPeriod = `${dateStart.format('YYYY-MM-DD')}/${dateEnd.clone().add(1, 'day').format('YYYY-MM-DD')}`;
    }

    $scope.loadData(true);
  };

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.getDataParams['userCurrentEntityIds[]'] = entityIds.length ? entityIds : undefined;
    $scope.loadData(true);
  };

  $scope.onApprovalStateFilter = (selectedOptions) => {
    $scope.getDataParams['statuses[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  $scope.onAbsenceTypeFilter = (selectedOptions) => {
    $scope.getDataParams['absenceTypeIds[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  $scope.onCancelledFilter = () => {
    $scope.getDataParams.cancelled = $scope.props.includeCancelled ? undefined : false;
    $scope.loadData(true);
  };

  $scope.onActiveUsersFilter = () => {
    $scope.getDataParams.userIsActive = $scope.props.activeUsers ? true : undefined;
    $scope.loadData(true);
  };

  $scope.onUserFilter = (selectedUser) => {
    $scope.getDataParams.userId = selectedUser ? selectedUser.id : undefined;
    $scope.loadData(true);
  };

  $scope.onPositionFilter = (selectedOptions) => {
    $scope.getDataParams['currentPositionIds[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  $scope.onStaffGroupFilter = (selectedOptions) => {
    $scope.getDataParams['currentStaffGroupIds[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.AbsenceType,
    EnvironmentDataService.DataType.Position,
    EnvironmentDataService.DataType.StaffGroup,
  ])
    .then(([
      entityGroup,
      absenceType,
      position,
      staffGroup,
    ]) => {
      $scope.props.entityGroupList = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => ({
            id: entity.id,
            label: entity.name,
            parentId: entity.groupId,
            depth: 1,
          })),
        ]));

      $scope.props.absenceTypeList = absenceType.data
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
      $scope.props.positions = position.data
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
      $scope.props.staffGroups = staffGroup.data
        .filter(({ entityId }) => !entityId)
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
      $scope.loadData();
    });

  $scope.export = async () => {
    $scope.props.loadingData = true;

    const {
      data: {
        signedUrl,
      },
    } = await AvailabilityService.exportAbsence($scope.getDataParams, {
      'Content-Type': 'text/csv',
    });

    if (signedUrl) {
      $window.location = signedUrl;
    }

    $scope.props.loadingData = false;
  };
};
