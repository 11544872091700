const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/avatar.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/other/editAvatarModal.html');

import directives from '../module';

directives.directive('rrStaffAvatar', (
  $uibModal,
) => {
  'ngInject';

  const textLight = '#ffffff';
  const textDark = '#052066';

  const colourOptions = [
    { background: '#ffc400', text: textDark },
    { background: '#6e49f4', text: textLight },
    { background: '#dc1e7c', text: textLight },
    { background: '#00ab5b', text: textLight },
    { background: '#00d1b2', text: textDark },
    { background: '#ff8e35', text: textDark },
    { background: '#052066', text: textLight },
    { background: '#c5d2f5', text: textDark },
    { background: '#ff1e0e', text: textLight },
    { background: '#bbc3ca', text: textDark },
  ];

  const colourOptionCount = colourOptions.length;

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      user: '=',
      size: '@',
      canEdit: '@',
    },
    link: ($scope) => {
      $scope.props = {
        canEdit: $scope.canEdit,
      };

      // GraphQL user objects have different property names to those returned
      // from the API. The below getters try to access GQL props first.
      function getUserId() {
        const { user } = $scope;
        return user.userId || user.id;
      }

      function getPictureUrl() {
        const { user } = $scope;
        return user.userPicture || user.picture;
      }

      function updateDisplay(user) {
        const initials = `${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`;
        const avatarStyle = colourOptions[
          (user.userAvatarModuloDividend || getUserId()) % colourOptionCount];
        const pictureUrl = getPictureUrl();

        $scope.props.avatarStyle = {
          'background-color': avatarStyle.background,
          color: avatarStyle.text,
        };

        if (pictureUrl) {
          $scope.props.avatarStyle['background-image'] = `url(${pictureUrl})`;
          $scope.props.avatarStyle['background-color'] = 'transparent';
          $scope.props.avatarText = null;
        } else {
          $scope.props.avatarText = initials;
        }
      }

      $scope.$watch('user', (user) => {
        if (!user) {
          return;
        }

        updateDisplay(user);
      });

      $scope.onClick = () => {
        if (!$scope.props.canEdit) {
          return;
        }

        $uibModal.open({
          templateUrl: autoNgTemplateLoaderTemplate2,
          controller: require('../../controllers/other/editAvatarModal'),
          resolve: {
            data: () => ({
              userId: getUserId(),
              pictureUrl: getPictureUrl(),
            }),
          },
        }).result.then(({ pictureUrl }) => {
          $scope.user.userPicture = pictureUrl;
          $scope.user.picture = pictureUrl;

          updateDisplay($scope.user);
        });
      };
    },
  };
});
