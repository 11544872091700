module.exports = (
  $scope,
  $state,
  $stateParams,
  $window,
  $translate,
  StaffService,
  AlertService,
  SessionService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.VIEW.NOTES.ERROR_500',
    'STAFF.VIEW.NOTES.ALERT_CONFIRM_DELETE',
    'STAFF.VIEW.NOTES.VISIBILITY_SELF',
    'STAFF.VIEW.NOTES.VISIBILITY_SENSITIVE',
    'STAFF.VIEW.NOTES.VISIBILITY_GENERAL',
    'STAFF.VIEW.NOTES.VISIBILITY_SELF_HELP',
    'STAFF.VIEW.NOTES.VISIBILITY_SENSITIVE_HELP',
    'STAFF.VIEW.NOTES.VISIBILITY_GENERAL_HELP',
  ]);

  const { id: rawUserId } = $stateParams;
  const userId = parseInt(rawUserId, 10);
  const sourceUserId = SessionService.getUserId();

  $scope.props = {
    loadingData: true,
    data: [],
    formData: {
      body: '',
      visibility: 'GENERAL',
    },
    visibilityList: [
      {
        id: 'GENERAL',
        label: translations['STAFF.VIEW.NOTES.VISIBILITY_GENERAL'],
      },
      {
        id: 'SENSITIVE',
        label: translations['STAFF.VIEW.NOTES.VISIBILITY_SENSITIVE'],
      },
      {
        id: 'SELF',
        label: translations['STAFF.VIEW.NOTES.VISIBILITY_SELF'],
      },
    ],
  };

  function embellishNotes() {
    $scope.props.data = $scope.props.data.map((note) => ({
      ...note,
      isSelf: note.added.user && note.added.user.id === sourceUserId,
      dateFromNow: moment(note.added.date).fromNow(),
      dateFormatted: moment(note.added.date).format('ll - LT'),
      editedDateFormatted: note.editedDate ? moment(note.editedDate).format('ll - LT') : undefined,
    }));
  }

  function onApiError(status) {
    $scope.props.loadingData = false;

    if (status === 500) {
      AlertService.add('danger', translations['STAFF.VIEW.NOTES.ERROR_500']);
      return;
    }

    $state.go('staff.overview');
  }

  function loadData() {
    $scope.props.loadingData = true;

    StaffService.getNotes(userId)
      .then(({ data }) => {
        $scope.props.data = data.results;
        $scope.props.data.reverse();
        embellishNotes();
        $scope.onVisibilityChange();

        $scope.props.loadingData = false;
      }).catch(({ status }) => onApiError(status));
  }

  loadData();

  $scope.onVisibilityChange = () => {
    const { visibility } = $scope.props.formData;
    let helpText;

    switch (visibility) {
      default:
      case 'GENERAL':
        helpText = translations['STAFF.VIEW.NOTES.VISIBILITY_GENERAL_HELP'];
        break;
      case 'SENSITIVE':
        helpText = translations['STAFF.VIEW.NOTES.VISIBILITY_SENSITIVE_HELP'];
        break;
      case 'SELF':
        helpText = translations['STAFF.VIEW.NOTES.VISIBILITY_SELF_HELP'];
        break;
    }

    $scope.props.visibilityHelpText = helpText;
  };

  $scope.add = () => {
    const {
      formData: {
        body,
        visibility,
      },
      saveActioning,
    } = $scope.props;

    if (saveActioning || !body || body.length === 0) {
      return;
    }

    $scope.props.saveActioning = true;

    StaffService.addNote(userId, {
      body,
      visibility,
    })
      .then(({ data }) => {
        const nameComponents = SessionService.getUserFullName().split(' ');

        $scope.props.data.unshift({
          id: data.noteIds[0],
          userId,
          body,
          added: {
            user: {
              id: sourceUserId,
              firstName: nameComponents[0],
              lastName: nameComponents.length > 1 ? nameComponents.slice(1).join(' ') : '',
            },
            date: moment(),
          },
          visibility,
        });

        embellishNotes();

        $scope.props.formData.body = '';
        $scope.props.saveActioning = false;
      }).catch(({ status }) => onApiError(status));
  };

  $scope.remove = (note, index) => {
    if (!note.isSelf || note.actionInProgress) {
      return;
    }

    if (!$window.confirm(translations['STAFF.VIEW.NOTES.ALERT_CONFIRM_DELETE'])) {
      return;
    }

    note.actionInProgress = true;

    StaffService.removeNote(note.id, userId)
      .then(() => {
        $scope.props.data.splice(index, 1);
      })
      .catch(({ status }) => {
        note.actionInProgress = false;
        onApiError(status);
      });
  };

  $scope.update = (note, body, visibility) => {
    if (!note.isSelf || note.actionInProgress) {
      return;
    }

    note.actionInProgress = true;

    StaffService.updateNote(note.id, userId, {
      body,
      visibility,
    })
      .then(() => {
        note.body = body;
        note.visibility = visibility;
        note.actionInProgress = false;
      })
      .catch(({ status }) => {
        note.actionInProgress = false;
        onApiError(status);
      });
  };
};
