module.exports = (
  $scope,
  $uibModalInstance,
  $translate,
  $window,
  AlertService,
  StaffService,
  data,
) => {
  'ngInject';

  const {
    userId,
    address,
  } = data;

  $scope.props = {
    formData: address ? {
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      county: address.county,
      postCode: address.postCode,
      country: address.country,
      notes: address.notes,
    } : {},
    actionInProgress: false,
    countryList: StaffService.getCountryList()
      .map(({ id, title }) => ({ id, label: title })),
    isAdd: !address,
    isEditHistoricalAddress: address && address.id,
    lastEdited: address ? address.lastEdited : undefined,
  };

  const translations = $translate.instant([
    'STAFF.VIEW.ADDRESS_HISTORY.ALERT_ERROR_500',
    'STAFF.VIEW.ADDRESS_HISTORY.CONFIRM_DELETE',
  ]);

  $scope.addNew = () => {
    $scope.props.isAdd = true;
    $scope.props.isEditHistoricalAddress = false;
    $scope.props.formData = {};
  };

  $scope.close = () => {
    $uibModalInstance.dismiss('cancel');
  };

  function handleApiError(status) {
    $scope.props.actionInProgress = false;

    if (status !== 403) {
      AlertService.add('danger', translations['STAFF.VIEW.ADDRESS_HISTORY.ALERT_ERROR_500']);
    }
  }

  function handleApiSuccess(refreshHistorical, newCurrentAddress) {
    $scope.props.actionInProgress = false;
    $uibModalInstance.close({
      refreshHistorical,
      newCurrentAddress,
    });
  }

  $scope.remove = () => {
    if (!$window.confirm(translations['STAFF.VIEW.ADDRESS_HISTORY.CONFIRM_DELETE'])) {
      return;
    }

    $scope.props.actionInProgress = true;

    StaffService.removeAddress(address.id, userId)
      .then(() => handleApiSuccess(true))
      .catch(({ status }) => handleApiError(status));
  };

  $scope.save = () => {
    const {
      formData: {
        line1,
        line2,
        city,
        county,
        postCode,
        country,
      },
    } = $scope.props;

    if (!line1 && !city) {
      return;
    }

    $scope.props.actionInProgress = true;

    if ($scope.props.isEditHistoricalAddress) {
      StaffService.updateAddress(address.id, userId, $scope.props.formData)
        .then(() => handleApiSuccess(true))
        .catch(({ status }) => handleApiError(status));
    } else {
      StaffService.updateAccount(userId, {
        user: {
          addressLine1: line1,
          addressLine2: line2,
          addressCity: city,
          addressCounty: county,
          addressPostCode: postCode,
          addressCountry: country,
        },
      })
        .then(() => {
          // If this is an 'add new address' operation and an address was
          // previously set on the employee record (rather than null)
          // add it to their address history.
          if ($scope.props.isAdd && address) {
            StaffService.addAddress(userId, address)
              .then(() => handleApiSuccess(true, $scope.props.formData))
              .catch(({ status }) => handleApiError(status));
          } else {
            handleApiSuccess(false, $scope.props.formData);
          }
        })
        .catch(({ status }) => handleApiError(status));
    }
  };
};
