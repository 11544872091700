'use strict';
var controllers = require('../../module');

controllers.controller('AbsenceRequestsStaffCtrl', ['$scope', '$state', '$translate', 'AvailabilityService', 'AlertService', 'SessionService', 'EnvironmentDataService', 'StaffCommon',
    function ($scope, $state, $translate, AvailabilityService, AlertService, SessionService, EnvironmentDataService, StaffCommon) {
        var translations = $translate.instant([
            'STAFF.REQUESTS.ABSENCE.ERROR_500',
        ]);

        $scope.viewAbsence = function (id) {
          $state.go('absenceEdit.review', { id });
        };

        $scope.props = {
            entityId: SessionService.getEntity(),
            loadingData: false,
            noMoreData: false,
            absenceRequests: [],
        };

        $scope.getAbsenceParams = {
            'approvalStates[]': [0],
            'currentEntityIds[]': [$scope.props.entityId],
            'currentPositionIds[]': [],
            'currentStaffGroupIds[]': [],
            'absenceTypeIds[]': [],
            limit: 20,
            page: 1,
            sortOrder: 'submittedDateAsc',
        };

        $scope.onEntitySelect = function (entity) {
            $scope.getAbsenceParams['currentEntityIds[]'] = entity ? [entity.id] : undefined;
            $scope.loadAbsence(true);
        };

        $scope.onPositionFilter = function (selectedOptions) {
            var option = selectedOptions[0];
            $scope.getAbsenceParams['currentPositionIds[]'] = option || [];
            $scope.loadAbsence(true);
        };

        $scope.onStaffGroupFilter = function (selectedOptions) {
            var option = selectedOptions[0];
            $scope.getAbsenceParams['currentStaffGroupIds[]'] = option || [];
            $scope.loadAbsence(true);
        };

        $scope.onAbsenceTypeFilter = function (selectedOptions) {
            var option = selectedOptions[0];
            $scope.getAbsenceParams['absenceTypeIds[]'] = option || [];
            $scope.loadAbsence(true);
        };

        $scope.onUserFilter = function (selectedUser) {
            $scope.getAbsenceParams.userId = selectedUser ? selectedUser.id : undefined;
            $scope.loadAbsence(true);
        };

      EnvironmentDataService.fetchAll([
        EnvironmentDataService.DataType.EntityGroup,
        EnvironmentDataService.DataType.Position,
        EnvironmentDataService.DataType.StaffGroup,
        EnvironmentDataService.DataType.AbsenceType,
      ])
        .then(([
          entityGroup,
          position,
          staffGroup,
          absenceType,
        ]) => {
          $scope.props.entities = entityGroup.data;
          $scope.props.positions = position.data
            .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
          $scope.props.staffGroups = staffGroup.data
            .filter(({ entityId }) => !entityId)
            .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
          $scope.props.absenceTypes = absenceType.data
            .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
        })
        .catch(() => {
          AlertService.add('danger', translations['STAFF.REQUESTS.ABSENCE.ERROR_500']);
        });

        $scope.loadAbsence = function (reset) {
            if ($scope.props.loadingData) {
                return;
            }

            $scope.props.loadingData = true;

            if (reset) {
                $scope.getAbsenceParams.page = 1;
                $scope.props.noMoreData = false;
                $scope.props.absenceRequests = [];
            }

            AvailabilityService.getAbsencePaginated($scope.getAbsenceParams)
                .success(function (data) {
                  $scope.props.absenceRequests.push(...data.absence.map((r) => ({
                    ...r,
                    submittedFromNow: moment(r.submittedBy.date).fromNow(),
                    scopeLabel: StaffCommon.getLabelFromScope(r.scope),
                  })));

                    $scope.getAbsenceParams.page += 1;

                    if (data.absence.length < $scope.getAbsenceParams.limit) {
                        $scope.props.noMoreData = true;
                    }

                    $scope.props.loadingData = false;
                })
                .error(function (error, status) {
                    if (status === 500) {
                        AlertService.add('danger', translations['STAFF.REQUESTS.ABSENCE.ERROR_500']);
                    }
                });
        };
    }]);
