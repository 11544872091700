'use strict';
var controllers = require('../module');

controllers.controller('ManageSubscriptionsModalCtrl', ['$scope', '$uibModalInstance', '$timeout', '$animate', '$translate', 'NotificationService', 'CommonService',
    function ($scope, $uibModalInstance, $timeout, $animate, $translate, NotificationService, CommonService) {
        $scope.data = {
            loaded: false,
            subscriptions: {},
            filteredSubscriptions: {},
            filterSelection: {},
            empty: false,
            emptyFilterResults: false,
        };

        $scope.maps = {
            entityName: {},
            entityGroup: {},
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.unsubscribe = function (category, eventObjects) {
            var subscriptionIds = eventObjects.map(function(evt) {
                return evt.id;
            });

            var postData = {
                subscriptionIds: subscriptionIds,
            };

            NotificationService.unsubscribe(postData)
                .success(function () {
                    // Animate UI
                    $animate.enabled(true);

                    subscriptionIds.forEach(function (id) {
                        $scope.data.filteredSubscriptions[category] = $scope.data.filteredSubscriptions[category]
                            .filter(function(x) {
                                return x.id !== id;
                            });
                    });

                    // Update data after animation end
                    $timeout(function() {
                        $animate.enabled(false);
                        updateSubscriptionData(subscriptionIds, category);
                    }, 300);
                })
                .error(function () {
                    alert('We were unable to update your subscription preferences. Please try again.');
                });
        };

        // Uses main subscriptions data source to build a filtered subscriptions list to show in the UI
        $scope.filterSubscriptions = function (activeFilter) {
            if (!activeFilter || !activeFilter.id) {
                angular.copy($scope.data.subscriptions, $scope.data.filteredSubscriptions);
                $scope.data.emptyFilterResults = false;
                return;
            }

            // Apply filter to subscription list shown in UI
            var newFilteredSubscriptions = angular.copy($scope.data.subscriptions);

            $scope.data.emptyFilterResults = true;

            Object.keys(newFilteredSubscriptions).forEach(function(category) {
                newFilteredSubscriptions[category] = newFilteredSubscriptions[category]
                    .filter(function(subscription) {
                        return ((activeFilter.type === 'entity' && subscription.entityId === activeFilter.id) ||
                            (activeFilter.type === 'group' && subscription.entityGroupId === activeFilter.id));
                    });

                if (!!newFilteredSubscriptions[category].length) {
                    $scope.data.emptyFilterResults = false;
                }
            });

            $scope.data.filteredSubscriptions = newFilteredSubscriptions;
        };

        var validEventTypeIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16,
            17, 29, 30, 31, 32, 36, 37, 38];

        var translationKeys = [
            'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_ABSENCE',
            'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_ROTA',
            'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_ACCOUNT',
            'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_SWAP',
            'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_COST_CONTROL',
            'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_DOCUMENT',
            'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_BROADCAST',
        ];

        validEventTypeIds.forEach(function (eventTypeId) {
            translationKeys.push('DIRECTIVES.SUBSCRIBE_BUTTON.LABEL_ETID_' + eventTypeId);
        });

        var translations = $translate.instant(translationKeys);

        $scope.getLabelFromEventTypeId = function (eventTypeId) {
            return translations['DIRECTIVES.SUBSCRIBE_BUTTON.LABEL_ETID_' + eventTypeId];
        };

        $scope.getResourceDescriptor = function (resourceType) {
            var data = NotificationService.getSubscriptionResourceTypeData(resourceType);
            return translations[data.translation];
        };

        function updateSubscriptionData(subscriptionIds, category) {
            // After unsubscribe event, remove the subscriptions from the main data reference
            subscriptionIds.forEach(function (id) {
                $scope.data.subscriptions[category] = $scope.data.subscriptions[category]
                    .filter(function(x) {
                        return x.id !== id;
                    });
            });

            if (!$scope.data.subscriptions[category].length) {
                delete $scope.data.subscriptions[category];
            }

            evaluateNoSubscriptions();

            $scope.filterSubscriptions($scope.data.filterSelection);
        }

        function evaluateNoSubscriptions() {
            $scope.data.empty = Object.keys($scope.data.subscriptions).every(function (category) {
                return !$scope.data.subscriptions[category].length;
            });
        }

        $animate.enabled(false);

        NotificationService.getSubscriptions({ excludeResourceSpecific: true })
            .success(function (data) {
                Object.keys(data.byCategory).forEach(function(category) {
                    $scope.data.subscriptions[category] = data.byCategory[category];
                });

                $scope.filterSubscriptions();
                evaluateNoSubscriptions();
                $scope.data.loaded = true;
            })
            .error(function () {
                $uibModalInstance.dismiss('cancel');
            });

        CommonService.getMetadata(['entities'], false, true)
            .success(function (data) {
                data.entities.forEach(function(group) {
                    // Map groupId to groupName and entities
                    $scope.maps.entityGroup[group.id] = { name: group.name, entities: group.entities };

                    group.entities.forEach(function(entity) {
                        // Map entityId to entityName
                        $scope.maps.entityName[entity.id] = entity.name;
                    });
                });
            })
            .error(function () {
                alert('We were unable to find the names of the site(s) you are subscribed to.');
            });
    }]);
