var controllers = require('../../module');

controllers.controller('SettingsEditPayRateModalCtrl', ['$scope', '$uibModalInstance', 'EnvironmentDataService', 'AlertService', 'data',
    function ($scope, $uibModalInstance, EnvironmentDataService, AlertService, data) {
        $scope.props = {
            dataLoaded: false,
            ruleType: data.ruleType,
        };

        if (data.isAdd) {
            $scope.allowScheduled = data.rates.length ? !data.rates[0].isScheduled : true;
            $scope.allowOvertime = data.rates.length ? data.rates[0].isScheduled : true;

            $scope.formData = {
                typeId: 0,
                elementTypeId: 1,
                capTypeId: 2,
                hasCap: false,
                timeCapStart: moment('09:00:00', 'HH:mm:ss'),
                timeCapEnd: moment('17:00:00', 'HH:mm:ss'),
                minuteCap: 20,
                isScheduled: $scope.props.ruleType === 'THRESHOLD' ? false : $scope.allowScheduled,
            };
        } else {
            var rate = data.rate;

            $scope.formData = {
                typeId: rate.typeId,
                elementTypeId: rate.elementTypeId ? rate.elementTypeId : 1,
                capTypeId: rate.capTypeId === 1 ? 2 : rate.capTypeId,
                hasCap: rate.capTypeId !== 1,
                timeCapStart: rate.capTypeId === 2 ? moment(rate.timeCapStart, 'HH:mm:ss') : moment('09:00:00', 'HH:mm:ss'),
                timeCapEnd: rate.capTypeId === 2 ? moment(rate.timeCapEnd, 'HH:mm:ss') : moment('17:00:00', 'HH:mm:ss'),
                minuteCap: rate.capTypeId === 3 ? rate.minuteCap : 20,
                isScheduled: rate.isScheduled,
            };

            $scope.allowScheduled = data.rates.length === 2 ? rate.isScheduled : true;
            $scope.allowOvertime = data.rates.length === 2 ? !rate.isScheduled : true;
        }

      EnvironmentDataService.fetch(EnvironmentDataService.DataType.PayElementType)
        .then(({ data }) => {
          $scope.payElementTypeNamesById = {};

          $scope.payElementTypes = data.filter((type) => {
            if (type.variant === 'FIXED_AMOUNT') {
              return false;
            }

            if (type.isDeleted && type.id !== $scope.formData.elementTypeId) {
              return false;
            }

            $scope.payElementTypeNamesById[type.id] = type.name;
            return true;
          });

          $scope.props.dataLoaded = true;
        });

        $scope.done = function () {
            if (!$scope.formData.hasCap) {
                $scope.formData.capTypeId = 1;
            }

            $uibModalInstance.close($scope.formData);
        };

        $scope.close = function () {
            $uibModalInstance.dismiss();
        };

        $scope.capValueChanged = function () {
            $scope.formData.timeCapStart = moment('09:00:00', 'HH:mm:ss');
            $scope.formData.timeCapEnd = moment('17:00:00', 'HH:mm;ss');
            $scope.formData.minuteCap = 20;
        };
    }
]);
