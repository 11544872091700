const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/shiftLocations/addLocationModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/shiftLocations/editLocationModal.html');

'use strict';
var controllers = require('../../module');

controllers.controller('LocationsRmCtrl', ['$scope', '$uibModal', '$state', '$location', '$anchorScroll', '$timeout',
    '$compile', '$debounce', '$translate', 'NgMap', 'RotaService', 'AlertService',
    function ($scope, $uibModal, $state, $location, $anchorScroll, $timeout, $compile, $debounce, $translate, NgMap, RotaService, AlertService) {
        $scope.locations = [];
        $scope.dataLoaded = false;
        $scope.searchActive = false;

        var markers = [];
        var geocoder = new google.maps.Geocoder();
        var infoWindow = new google.maps.InfoWindow({
            content:'',
            maxWidth: 300
        });

        function refreshLocations() {
            $scope.dataLoaded = false;

            RotaService.getShiftLocations()
                .success(function (data) {
                    $scope.locations = data.asTree;
                    $scope.locationArray = data.asArray;
                    $scope.dataLoaded = true;

                    if ($scope.locations.length) {
                        refitBounds();
                    }
                })
                .error(function () {
                    AlertService.add("danger", $translate.instant('ROTA_MANAGEMENT.LOCATIONS.INDEX.ERROR_LOAD'));
                    $state.go('settings.index');
                });
        }

        NgMap.getMap({ id: 'shiftLocationsMap' }).then(function(map) {
            $scope.map = map;

            // Create the search box and link it to the UI element.
            var searchControl = document.getElementById('searchControl');
            var input = /** @type {HTMLInputElement} */(document.getElementById('pac-input'));
            map.controls[google.maps.ControlPosition.TOP_LEFT].push(searchControl);

            var searchBox = new google.maps.places.SearchBox(
                /** @type {HTMLInputElement} */(input));

            // Listen for the event fired when the user selects an item from the
            // pick list. Retrieve the matching places for that item.
            google.maps.event.addListener(searchBox, 'places_changed', function() {
                $scope.searchActive = true;
                infoWindow.close();

                var places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }
                for (var i = 0, marker; marker = markers[i]; i++) {
                    marker.setMap(null);
                }

                // For each place, get the icon, place name, and location.
                markers = [];
                var bounds = new google.maps.LatLngBounds();
                for (var i = 0, place; place = places[i]; i++) {
                    // Create a marker for each place.
                    var marker = new google.maps.Marker({
                        map: map,
                        title: place.name,
                        position: place.geometry.location,
                        draggable: true,
                        animation: google.maps.Animation.DROP,
                        icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
                    });

                    markers.push(marker);

                    google.maps.event.addListener(marker, 'click', showAddLocationInfoWindow);
                    google.maps.event.addListener(marker, 'dragstart', function(evt){
                        infoWindow.close();
                    });

                    bounds.extend(place.geometry.location);
                }

                if (markers.length === 1) {
                    map.setCenter(markers[0].position);
                    map.setZoom(15);
                } else {
                    map.fitBounds(bounds);
                }
            });

            // Bias the SearchBox results towards places that are within the bounds of the
            // current map's viewport.
            google.maps.event.addListener(map, 'bounds_changed', function() {
                var bounds = map.getBounds();
                searchBox.setBounds(bounds);
            });

            refreshLocations();
        });

        $scope.clearSearch = function () {
            $scope.searchActive = false;
            for (var i = 0, marker; marker = markers[i]; i++) {
                marker.setMap(null);
            }

            var inputDiv = $('#pac-input');

            inputDiv.blur();
            $timeout(function () {
                inputDiv.val('');
                inputDiv.focus();
            }, 10);
        };

        function showAddLocationInfoWindow (event) {
            setNewInfoWindowContent(this);
            infoWindow.open($scope.map, this);
        }

        $scope.showMarkerInfo = function (event, locationId) {
            setInfoWindowContent(locationId);
            infoWindow.open($scope.map, this);
        };

        $scope.addLocation = function (lat, lng, address) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'AddLocationModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            address: address,
                            lat: lat,
                            lng: lng
                        }
                    }
                }
            });

            modalInstance.result.then(function () {
                $scope.clearSearch();
                $scope.clearFilterQuery();
                refreshLocations();
            });
        };

        $scope.editLocation = function (location) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'EditLocationModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            location: location
                        }
                    }
                }
            });

            modalInstance.result.then(function () {
                refreshLocations();
            });
        };

        $scope.goToMarker = function (locationId, $event) {
            if ($event) {
                // Prevent bubbling up to editLocation()
                $event.stopPropagation();
                $event.preventDefault();
            }

            $location.hash('map');
            $anchorScroll();

            angular.forEach($scope.map.markers, function (marker) {
                if (marker.id === ('marker' + locationId)) {
                    google.maps.event.trigger($scope.map, 'resize');
                    $scope.map.panTo(marker.getPosition());
                    $scope.map.setZoom(15);

                    setInfoWindowContent(locationId);
                    infoWindow.open($scope.map, marker);
                }
            });
        };

        function getLocationById(id) {
            for (var i = 0; i < $scope.locationArray.length; i++) {
                if ($scope.locationArray[i].id === id) {
                    return $scope.locationArray[i];
                }
            }

            return false;
        }

        function setInfoWindowContent(locationId) {
            var location = getLocationById(locationId);
            var details = location.details;

            if (!details) {
                details = $translate.instant('ROTA_MANAGEMENT.LOCATIONS.INDEX.NO_ADDRESS');
            }

            infoWindow.setContent('<div class="locationInfoWindow">' +
                '<p><strong>' + location.name + '</strong></p>' +
                '<p>' + details.replace(/\n/g, '<br />') + '</p></div>');
        }

        function setNewInfoWindowContent(marker) {
            infoWindow.setContent('<div class="locationInfoWindow"><img src="/images/loading-sm.gif"></div>');

            geocoder.geocode({'latLng': marker.getPosition()}, function (results, status) {
                if (status !== google.maps.GeocoderStatus.OK) {
                    AlertService.add("danger", $translate.instant('ROTA_MANAGEMENT.LOCATIONS.INDEX.ERROR_LOOKUP'));
                    return;
                }

                var address = $translate.instant('ROTA_MANAGEMENT.LOCATIONS.INDEX.UNKNOWN_ADDRESS');

                if (results[1] && results[1].formatted_address) {
                    address = results[1].formatted_address;
                }

                var escapedAddress = address.replace(/'/g, "\\'");

                var htmlData = '<div class="locationInfoWindow">' +
                    '<p><a ng-click="addLocation(\'' + marker.getPosition().lat() + '\',\'' +
                    marker.getPosition().lng() + '\',\'' + escapedAddress + '\')">' +
                    $translate.instant('ROTA_MANAGEMENT.LOCATIONS.INDEX.ADD_AS_NEW') + '</a></p>' +
                    '<p>' + address + '<br /><small>(' +
                    $translate.instant('ROTA_MANAGEMENT.LOCATIONS.INDEX.DRAG_TO_CHANGE') + ')</small></p></div>';

                var compiled = $compile(htmlData)($scope);

                infoWindow.setContent(compiled[0]);
                infoWindow.open($scope.map, marker);
            });
        }

        function refitBounds() {
            $timeout(function () {
                var bounds = new google.maps.LatLngBounds();
                angular.forEach($scope.map.markers, function (marker) {
                    bounds.extend(marker.getPosition());
                });

                $scope.map.fitBounds(bounds);

                // If all the locations are very close to each other, or you simply have one location
                // the default zoom will be extremely close up, so this pulls it back out a bit
                if ($scope.map.getZoom() > 15) {
                    $scope.map.setZoom(15);
                }
            });
        }

        $scope.$watch('searchInput', function(newValue, oldValue) {
            if (newValue === oldValue) { return; }
            $debounce(applyQuery, 350);
        });
        var applyQuery = function() {
            $scope.query = $scope.searchInput;
        };

        $scope.clearFilterQuery = function() {
            $scope.query = '';
            $scope.searchInput = '';
        };
    }]);
