module.exports = (
  $scope,
  $uibModalInstance,
  data,
) => {
  'ngInject';

  const {
    issues,
    issueCountByCode,
  } = data.validationResult;

  const issuesByCode = {};

  issues.forEach((issue) => {
    const { code } = issue;

    if (issuesByCode[code]) {
      issuesByCode[code].push(issue);
    } else {
      issuesByCode[code] = [issue];
    }
  });

  $scope.props = {
    issuesByCode,
    issueCountByCode,
    truncateIssueLimit: 25,
    canTryAgain: false,
  };

  $scope.$watch('props.issuesByCode', (issuesByCode) => {
    if (!issuesByCode) {
      return;
    }

    const totalIssues = Object.values(issuesByCode).reduce((acc, array) => (acc + array.length), 0);

    if (totalIssues <= 0) {
      $scope.props.canTryAgain = true;
    }
  }, true);

  $scope.close = (tryAgain) => {
    if (tryAgain) {
      $uibModalInstance.close(true);
    }

    $uibModalInstance.dismiss();
  };
};
