const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/documents/uploadModal.html');

module.exports = (
  $scope,
  $uibModalInstance,
  $http,
  $window,
  $translate,
  $uibModal,
  EnvironmentDataService,
  AlertService,
  StaffService,
  ENDPOINT_API,
  data,
) => {
  'ngInject';

  const {
    record,
  } = data;

  const {
    id,
    signatureMethod,
    status,
    employeeUser: {
      id: employeeUserId,
    },
    destinationDocumentCategoryId,
    expiryDate,
    templateId,
    documentId,
  } = record;

  const translations = $translate.instant([
    'REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_PENDING',
    'REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_REJECTED',
    'REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_SIGNED',
    'REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.CANCEL_ERROR_500',
    'REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.CANCEL_SUCCESS',
    'REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.REMINDER_ERROR_500',
    'REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.REMINDER_ERROR_400',
    'REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.REMINDER_SUCCESS',
    'REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.DOWNLOAD_ERROR_500',
  ]);

  const statuses = new Map([
    ['PENDING', { id: 'PENDING', label: translations['REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_PENDING'] }],
    ['REJECTED', { id: 'REJECTED', label: translations['REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_REJECTED'] }],
    ['SIGNED', { id: 'SIGNED', label: translations['REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_SIGNED'] }],
  ]);

  $scope.props = {
    actionInProgress: true,
    employeeResponse: record.employeeResponse
      ? statuses.get(record.employeeResponse) : statuses.get('PENDING'),
    employerResponse: record.employerResponse
      ? statuses.get(record.employerResponse) : statuses.get('PENDING'),
    employeeResponseFromNow: record.employeeResponseDate
      ? moment(record.employeeResponseDate).fromNow() : undefined,
    employerResponseFromNow: record.employerResponseDate
      ? moment(record.employerResponseDate).fromNow() : undefined,
    canUpload: signatureMethod === 'MANUAL' && status === 'PENDING',
    canSendReminder: signatureMethod === 'ELECTRONIC' && status === 'PENDING',
    canCancel: status === 'PENDING',
  };

  $scope.record = record;

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.DocumentCategory,
    EnvironmentDataService.DataType.DocumentTemplate,
  ])
    .then(([
      documentCategory,
      documentTemplate,
    ]) => {
      $scope.props.destinationCategory = documentCategory.data
        .find((c) => c.id === destinationDocumentCategoryId);
      $scope.props.template = templateId
        ? documentTemplate.data.find((t) => t.id === templateId) : undefined;

      $scope.props.actionInProgress = false;
    });

  $scope.close = () => {
    $uibModalInstance.dismiss();
  };

  $scope.downloadOriginal = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    $scope.props.actionInProgress = true;

    $http.get(`${ENDPOINT_API}/document/signature/${id}/signedUrl`)
      .then(({ data }) => {
        $scope.props.actionInProgress = false;
        $window.location = data.signedUrl;
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.DOWNLOAD_ERROR_500']);
        }
      });
  };

  $scope.downloadSignedCopy = () => {
    if ($scope.props.actionInProgress || !documentId) {
      return;
    }

    $scope.props.actionInProgress = true;

    StaffService.getDocument(documentId, { download: true })
      .then(({ data }) => {
        $scope.props.actionInProgress = false;
        $window.location = data.signedUrl;
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.DOWNLOAD_ERROR_500']);
        }
      });
  };

  $scope.upload = () => {
    if (!$scope.props.canUpload) {
      return;
    }

    $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('../../staff/view/documents/uploadModal'),
      resolve: {
        data: () => ({
          userId: employeeUserId,
          categoryId: destinationDocumentCategoryId,
          expiryDate: moment.utc(expiryDate),
          signatureId: id,
          redirectOnComplete: true,
        }),
      },
    });

    $scope.close();
  };

  $scope.sendReminder = () => {
    if (!$scope.props.canSendReminder || $scope.props.actionInProgress) {
      return;
    }

    $scope.props.actionInProgress = true;

    $http.put(`${ENDPOINT_API}/document/signature/${id}`, {
      sendReminder: true,
    })
      .then(() => {
        $scope.props.actionInProgress = false;
        AlertService.add('success', translations['REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.REMINDER_SUCCESS']);
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 400) {
          AlertService.add('warning', translations['REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.REMINDER_ERROR_400']);
        }

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.REMINDER_ERROR_500']);
        }
      });
  };

  $scope.cancel = () => {
    if (!$scope.props.canCancel || $scope.props.actionInProgress) {
      return;
    }

    $scope.props.actionInProgress = true;

    $http.delete(`${ENDPOINT_API}/document/signature/${id}`)
      .then(() => {
        AlertService.add('success', translations['REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.CANCEL_SUCCESS']);
        $uibModalInstance.close({
          id,
          props: {
            status: 'CANCELLED',
          },
        });
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.DOCUMENT_SIGNATURES.VIEW_MODAL.CANCEL_ERROR_500']);
        }
      });
  };
};
