const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/pay/studentLoan.html');

module.exports = (
  $scope,
  $translate,
  StaffService,
  AlertService,
  $uibModalDrawer,
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.VIEW.PAY.VIEW_FINANCIALS.SAVE_SUCCESS',
    'STAFF.VIEW.PAY.VIEW_FINANCIALS.ERROR_500',
    'STAFF.VIEW.PAY.VIEW_FINANCIALS.ERROR_403',
    'STAFF.VIEW.PAY.VIEW_FINANCIALS.LOAN_TABLE_DATE_NOT_SET',
  ]);

  $scope.props = {
    permitted: !!$scope.userData.financial,
    paymentMethodList: StaffService.getPayrollPaymentMethods()
      .map(({ id, title, deleted }) => ({ id, label: title, deleted })),
    studentLoanPlanTypes: StaffService.getStudentLoanPlanTypes()
      .map(({ id, title, deleted }) => ({ id, label: title, deleted })),
    studentLoanPlanTypesById: Object.fromEntries(
      new Map(StaffService.getStudentLoanPlanTypes().map(({ id, title }) => ([id, title])))),
    formData: {
      ...($scope.userData.financial || {}),
    },
    addLoanForm: {},
  };

  $scope.save = () => {
    const {
      formData,
      permitted,
    } = $scope.props;

    if (!permitted) {
      return;
    }

    $scope.props.saveActioning = true;

    StaffService.updateAccount($scope.userId, {
      user: {
        ...formData,
      },
    })
      .then(() => {
        Object.assign($scope.userData.financial, formData);

        $scope.props.saveActioning = false;
        AlertService.add('success', translations['STAFF.VIEW.PAY.VIEW_FINANCIALS.SAVE_SUCCESS']);
      })
      .catch(({ status }) => {
        $scope.props.saveActioning = false;

        if (status === 403) {
          AlertService.add('warning', translations['STAFF.VIEW.PAY.VIEW_FINANCIALS.ERROR_403']);
          return;
        }

        AlertService.add('danger', translations['STAFF.VIEW.PAY.VIEW_FINANCIALS.ERROR_500']);
      });
  };

  $scope.loanEffectiveDateChanged = (newDate) => {
    $scope.props.addLoanForm.effectiveDate = newDate.clone();
  };

  $scope.addStudentLoan = () => {
    const {
      planType,
      effectiveDate,
    } = $scope.props.addLoanForm;

    if (!planType || !effectiveDate) {
      return;
    }

    $scope.props.formData.ukStudentLoans.push({
      planType,
      effectiveDate: effectiveDate.format('YYYY-MM-DD'),
    });

    $scope.save();
  };

  $scope.deleteStudentLoan = (index) => {
    $scope.props.formData.ukStudentLoans.splice(index, 1);
    $scope.save();
  };

  $scope.editStudentLoan = (index) => {
    const drawerInstance = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./studentLoan'),
      resolve: {
        data: () => ({
          studentLoanIndex: index,
          studentLoanPlanTypes: $scope.props.studentLoanPlanTypes,
          userData: $scope.userData,
          userId: $scope.userId,
        }),
      },
    });

    drawerInstance.result.then((data) => {
      Object.assign($scope.userData.financial.ukStudentLoans, data);
          $scope.$broadcast('user:reload')
    });
  }

  $scope.formatEffectiveDate = (effectiveDate) => {
    if (!effectiveDate) {
      return translations['STAFF.VIEW.PAY.VIEW_FINANCIALS.LOAN_TABLE_DATE_NOT_SET'];
    }
    return moment(effectiveDate).format('L');
  }
};
