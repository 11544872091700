const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/resourceSubscriptionButton.html');

var directives = require('./module');

directives.directive('rrResourceSubscriptionButton', ['NotificationService', 'AlertService', '$translate',
    function (NotificationService, AlertService, $translate) {
        return {
            restrict: 'E',
            templateUrl: autoNgTemplateLoaderTemplate1,
            scope: {
                resourceTypeId: '@',
                resourceId: '@',
                inModal: '=',
            },
            link: function ($scope) {
                function getResourceTypeName(resourceTypeId) {
                    var labelTranslations = $translate.instant([
                        'DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_ABSENCE',
                        'DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_ROTA',
                        'DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_ACCOUNT',
                        'DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_SWAP',
                        'DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_COST_CONTROL',
                        'DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_DOCUMENT',
                        'DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_BROADCAST',
                    ]);

                    switch (parseInt(resourceTypeId, 10)) {
                        case 1: return labelTranslations['DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_ABSENCE'];
                        case 2: return labelTranslations['DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_ROTA'];
                        case 3: return labelTranslations['DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_ACCOUNT'];
                        case 4: return labelTranslations['DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_SWAP'];
                        case 5: return labelTranslations['DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_DOCUMENT'];
                        case 6: return labelTranslations['DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_COST_CONTROL'];
                        case 9: return labelTranslations['DIRECTIVES.SUBSCRIBE_BUTTON.NOUN_BROADCAST'];
                        default: return '';
                    }
                }

                var labelTranslations = $translate.instant([
                    'DIRECTIVES.SUBSCRIBE_BUTTON.RESOURCE_SUBSCRIBE_TOOLTIP',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.RESOURCE_UNSUBSCRIBE_TOOLTIP'
                ], {
                    name: getResourceTypeName($scope.resourceTypeId)
                });

                $scope.buttonProps = {
                    active: false,
                    updating: true,
                    subscribeMsg: labelTranslations['DIRECTIVES.SUBSCRIBE_BUTTON.RESOURCE_SUBSCRIBE_TOOLTIP'],
                    unsubscribeMsg: labelTranslations['DIRECTIVES.SUBSCRIBE_BUTTON.RESOURCE_UNSUBSCRIBE_TOOLTIP'],
                };

                $scope.updateSubscription = function () {
                    $scope.buttonProps.updating = true;

                    if ($scope.buttonProps.active) {
                        // Unsubscribe
                        if (!$scope.subscriptionId) {
                            return;
                        }

                        var postData = {
                            subscriptionIds: [$scope.subscriptionId],
                        };

                        NotificationService.unsubscribe(postData)
                            .success(function () {
                                $scope.buttonProps.active = false;
                                $scope.buttonProps.updating = false;
                            });
                    } else {
                        // Subscribe
                        var postData = {
                            subscriptions: [{
                                resourceId: $scope.resourceId,
                                resourceTypeId: $scope.resourceTypeId,
                            }],
                        };

                        NotificationService.subscribe(postData)
                            .success(function (data) {
                                $scope.subscriptionId = data[0].id;
                                $scope.buttonProps.active = true;
                                $scope.buttonProps.updating = false;
                            });
                    }
                };

                // Check if user is subscribed to resource - loads ative state of button
                var params = {
                    resourceTypeId: $scope.resourceTypeId,
                    resourceId: $scope.resourceId,
                };

                NotificationService.getSubscriptions(params)
                    .success(function (data) {
                        if (data.subscriptions.length === 1) {
                            $scope.buttonProps.active = true;
                            $scope.subscriptionId = data.subscriptions[0].id;
                        }

                        $scope.buttonProps.updating = false;
                    });
            },
        };
    }
]);
