const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/attendance/editEventModal.html');

'use strict';
var controllers = require('../module');

controllers.controller('ViewEventAttendanceModalCtrl', ['$scope', '$uibModal', '$uibModalInstance', '$timeout', 'NgMap', 'AttendanceService', 'CommonService', 'AuthService', 'SessionService', 'data', '$translate',
    function ($scope, $uibModal, $uibModalInstance, $timeout, NgMap, AttendanceService, CommonService, AuthService, SessionService, data, $translate) {
        $scope.isFacialRecognition = SessionService.getSetting('attendance.facialRecognition') === 'true';

        var labelTranslations = $translate.instant([
            'ATTENDANCE.VIEW_EVENT_MODAL.CLOCK_IN',
            'ATTENDANCE.VIEW_EVENT_MODAL.CLOCK_OUT',
            'ATTENDANCE.VIEW_EVENT_MODAL.BREAK_ON',
            'ATTENDANCE.VIEW_EVENT_MODAL.BREAK_OFF',
            'ATTENDANCE.VIEW_EVENT_MODAL.HAPPENED_HERE',
        ]);

        $scope.dataLoaded = false;
        $scope.props = {};

        $scope.eventTypes = [
            '',
            labelTranslations["ATTENDANCE.VIEW_EVENT_MODAL.CLOCK_IN"],
            labelTranslations["ATTENDANCE.VIEW_EVENT_MODAL.CLOCK_OUT"],
            labelTranslations["ATTENDANCE.VIEW_EVENT_MODAL.BREAK_ON"],
            labelTranslations["ATTENDANCE.VIEW_EVENT_MODAL.BREAK_OFF"],
        ];

        $scope.metadata = {
            realm: AuthService.getRealm()
        };

        $scope.dateChanged = function (newDate) {
            $scope.datePicker.date = newDate.clone();
        };

        AttendanceService.getEvent(data.id)
            .success(function (result) {
                $scope.event = result.event;

                $scope.datePicker = {
                    date: moment($scope.event.date),
                    min: data.minDate ? moment(data.minDate) : moment($scope.event.date).startOf('day').subtract(1, 'day'),
                    max: moment($scope.event.date).endOf('day').add(1, 'day')
                };

                if ($scope.event.photoType === 2) {
                    $scope.fileExt = 'jpg';
                } else if ($scope.event.photoType === 1) {
                    $scope.fileExt = 'png';
                }

                if ($scope.event.facialRecognitionResult) {
                    var percent = Math.round($scope.event.facialRecognitionResult.confidence * 100);

                    if (percent < 15) {
                        $scope.event.facialRecognitionResult.confidenceType = 'danger';
                    } else if (percent < 35) {
                        $scope.event.facialRecognitionResult.confidenceType = 'warning';
                    } else {
                        $scope.event.facialRecognitionResult.confidenceType = 'success';
                    }

                    $scope.event.facialRecognitionResult.confidencePercent = percent;
                }

                $scope.dataLoaded = true;
            })
            .error(function () {
                $uibModalInstance.dismiss('cancel');
            });

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.doEdit = function () {
            $uibModalInstance.dismiss('cancel');

            $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'EditEventAttendanceModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            id: data.id,
                        };
                    }
                }
            });
        };

        $scope.photoTabSelected = function () {
            $('#attendancePhoto').contextmenu(function () {
                return false;
            });
        };

        var googleMap;

        $scope.locationTabSelected = function () {
            if (!googleMap) {
                NgMap.initMap('eventLocationMap');
            }

            NgMap.getMap({id: 'eventLocationMap'}).then(function (map) {
                googleMap = map;

                $timeout(function () {
                    google.maps.event.trigger(googleMap, 'resize');

                    var marker = new google.maps.Marker({
                        position: {lat: $scope.event.gpsLatitude, lng: $scope.event.gpsLongitude},
                        map: googleMap,
                        title: $scope.eventTypes[$scope.event.eventType] + ' ' + labelTranslations['ATTENDANCE.VIEW_EVENT_MODAL.HAPPENED_HERE']
                    });

                    googleMap.panTo(marker.getPosition());
                    googleMap.setZoom(14);
                }, 500);
            });
        };

        $scope.auditTabSelected = () => {
          if ($scope.props.auditEntries) {
            return;
          }

          $scope.props.auditEntriesLoaded = false;

          AttendanceService.getEventAudit(data.id)
            .then(({ data }) => {
              $scope.props.auditEntries = data.results;

              if ($scope.props.auditEntries.length) {
                $scope.props.auditEntries.push($scope.event);
              }

              $scope.props.auditEntries.reverse();

              $scope.props.auditEntriesLoaded = true;
            });
        };
    }]);
