var controllers = require('../../../module');

controllers.controller(
    'SettingsIntegrationLightspeedKSeriesCtrl',
    [
        '$scope',
        '$http',
        '$window',
        '$stateParams',
        'EnvironmentDataService',
        'AlertService',
        'AuthService',
        'ENDPOINT_API',
        function ($scope, $http, $window, $stateParams, EnvironmentDataService, AlertService, AuthService, ENDPOINT_API) {
            var apiBaseUrl = ENDPOINT_API + '/integration/lightspeedKSeries';
            $scope.dataLoaded = false;
            $scope.realmId = AuthService.getRealm();
            $scope.metadata = {};
            $scope.formData = {};

            if ($stateParams.success === 'true') {
                AlertService.add('success', 'Lightspeed K Series has been successfully connected to Rotaready! Please finish setup by adding mappings below.');
            } else if ($stateParams.success === 'false') {
                AlertService.add('danger', 'We were unable to connect to Lightspeed K Series. Please try again.');
            }

            function handleError(error, status) {
                if (status !== 403) {
                    AlertService.add('danger', 'We weren\'t able to load or modify the settings just then. Please try again');
                }
            }

            function loadSettings() {
                $scope.dataLoaded = false;

                $http.get(apiBaseUrl + '/settings')
                    .success(function (data) {
                        $scope.authorized = data.authorized;
                        $scope.configuration = data.configuration;

                        $scope.metadata.locations = [];
                        $scope.metadata.locationById = {};

                        data.businesses.forEach(function (business) {
                            business.locations.forEach(function (location) {
                                $scope.metadata.locations.push({
                                    business: business,
                                    location: location,
                                });

                                $scope.metadata.locationById[location.id] = location.name;
                            });
                        });

                        $scope.dataLoaded = true;
                    })
                    .error(function (error, status) {
                        handleError(error, status);
                    });
            }

            function loadMetadata() {
              EnvironmentDataService.fetchAll([
                EnvironmentDataService.DataType.EntityGroup,
                EnvironmentDataService.DataType.Stream,
              ])
                .then(([
                  entityGroup,
                  stream,
                ]) => {
                  $scope.metadata.entities = entityGroup.data;
                  $scope.metadata.streams = stream.data.filter(({
                    deleted,
                    applicableToSales,
                  }) => !deleted && applicableToSales);

                  $scope.metadata.entityById = {};
                  $scope.metadata.streamById = {};

                  entityGroup.data.forEach(function (group) {
                    group.entities.forEach(function (entity) {
                      $scope.metadata.entityById[entity.id] = entity.name;
                    });
                  });

                  stream.data.forEach(function (stream) {
                    $scope.metadata.streamById[stream.id] = stream.name;
                  });
                });
            }

            loadSettings();
            loadMetadata();

            $scope.save = function () {
                $scope.saveActioning = true;

                var data = {
                    configuration: $scope.configuration,
                };

                $http.put(apiBaseUrl + '/settings', data)
                    .success(function () {
                        AlertService.add('success', 'Your settings have been updated.');
                        $scope.saveActioning = false;
                    })
                    .error(function (error, status) {
                        handleError(error, status);
                        $scope.saveActioning = false;
                    });
            };

            $scope.connect = function () {
                $scope.redirectLoading = true;

                $http.get(apiBaseUrl + '/authorizeUrl', {
                    params: {
                        returnUrl: $window.location.href,
                    },
                })
                    .success(function (data) {
                        $window.location.href = data.authorizeUrl;
                    })
                    .error(function (error, status) {
                        $scope.redirectLoading = false;
                        handleError(error, status);
                    });
            };

            $scope.accountingGroups = {
                loading: false,
                data: [],
            };

            $scope.loadAccountingGroups = function () {
                $scope.accountingGroups.loading = true;

                $http.get(apiBaseUrl + '/accountingGroups', {
                    params: {
                        locationId: $scope.formData.location.location.id,
                    },
                })
                    .success(function (data) {
                        $scope.accountingGroups.data = data.accountingGroups;
                        $scope.accountingGroups.loading = false;
                    })
                    .error(function (error, status) {
                        $scope.accountingGroups.loading = false;
                        handleError(error, status);
                    });
            };

            $scope.onEntitySelect = function (entity) {
                if (!entity) {
                    return;
                }

                $scope.formData.entityId = entity.id;
            };

            $scope.addMapping = function () {
                var location = $scope.formData.location;
                var accountingGroup = $scope.formData.accountingGroup;
                var entityId = $scope.formData.entityId;
                var streamId = $scope.formData.streamId;

                if (!location || !accountingGroup || !entityId || !streamId) {
                    return;
                }

                var mapping = {
                    businessId: location.business.id,
                    locationId: location.location.id,
                    accountingGroupId: accountingGroup.id,
                    accountingGroupName: accountingGroup.name,
                    entityId: entityId,
                    streamId: streamId,
                };

                $scope.configuration.mappings.push(mapping);
            };

            $scope.removeMapping = function (index) {
                $scope.configuration.mappings.splice(index, 1);
            };
        }
    ]
);
