module.exports = (
  $scope,
  $translate,
  $uibModalInstance,
  StaffService,
  StaffCommon,
  EnvironmentDataService,
  AlertService,
  data,
) => {
  'ngInject';

  const {
    appointment,
    employment,
    isAdd,
    isEmploymentAddFlow,
  } = data;

  const {
    id: employmentId,
    userId,
  } = employment;

  const translations = $translate.instant([
    'STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ALERT_MAPPINGS',
    'STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ALERT_SAVED',
    'STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ALERT_DELETED',
    'STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ERROR_500',
  ]);

  $scope.props = {
    isAdd: !!isAdd,
    isEmploymentAddFlow: !!isEmploymentAddFlow,
    appointment: angular.copy(appointment),
    isFirstAppointment: false,
    actionInProgress: true,
    streamsById: {},
    contractualUnitOptions: StaffService.getContractualObligationUnits().map((p) => ({
      ...p,
      name: p.title.toLowerCase(),
    })),
    contractualPeriodOptions: StaffService.getContractualObligationPeriods().map((p) => ({
      ...p,
      name: p.title.toLowerCase(),
    })),
    minObligationEnabled: !!appointment.minObligation,
    maxObligationEnabled: !!appointment.maxObligation,
    newStreamMappingForm: {
      streamId: 1,
      amount: 100,
    },
    noMappingsDeclaration: isAdd || isEmploymentAddFlow
      ? false : !appointment.costStreamMappings.length,
  };

  if ($scope.props.isAdd) {
    $scope.props.appointment.localDateStart = moment();
  } else {
    $scope.props.isFirstAppointment = appointment.localDateStart === employment.dateStart;
  }

  const updateShape = {};
  let staffGroupMasterList = [];

  function updateStaffGroupList(selectedEntityId) {
    $scope.props.staffGroupList = staffGroupMasterList
      .filter((g) => !g.entityId || g.entityId === selectedEntityId)
      .map(({ id, name, deleted }) => ({ id, label: name, deleted }));

    const groupSelectionExists = $scope.props.staffGroupList
      .find((g) => g.id === $scope.props.appointment.groupId);

    if (!groupSelectionExists && $scope.props.staffGroupList.length) {
      $scope.props.appointment.groupId = $scope.props.staffGroupList[0].id;
    }
  }

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.Position,
    EnvironmentDataService.DataType.StaffGroup,
    EnvironmentDataService.DataType.Stream,
    EnvironmentDataService.DataType.Company,
    EnvironmentDataService.DataType.PayrollCalendar,
    EnvironmentDataService.DataType.ContractBasis,
  ])
    .then(([
      entityGroup,
      position,
      staffGroup,
      stream,
      company,
      payrollCalendar,
      contractBasis,
    ]) => {
      $scope.props.entityGroupList = entityGroup.data;

      $scope.props.positionList = position.data
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
      $scope.props.companyList = company.data
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
      $scope.props.payrollCalendarList = payrollCalendar.data
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
      $scope.props.contractBasisList = contractBasis.data
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));

      $scope.props.streamList = stream.data
        .filter(({ deleted, applicableToLabour }) => !deleted && applicableToLabour)
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
      stream.data.forEach((stream) => {
        $scope.props.streamsById[stream.id] = stream;
      });
      $scope.props.newStreamMappingForm.streamId = $scope.props.streamList[0].id;

      staffGroupMasterList = staffGroup.data;
      updateStaffGroupList($scope.props.appointment.entityId);

      $scope.props.actionInProgress = false;
    });

  $scope.close = () => $uibModalInstance.dismiss();

  $scope.contractualObligationChanged = () => {
    if ($scope.props.minObligationEnabled) {
      $scope.props.appointment.minObligation = { value: 0, period: 0, unit: 0 };
    } else {
      $scope.props.appointment.minObligation = null;
    }
  };

  $scope.workingLimitChanged = () => {
    if ($scope.props.maxObligationEnabled) {
      $scope.props.appointment.maxObligation = { value: 0, period: 0, unit: 0 };
    } else {
      $scope.props.appointment.maxObligation = null;
    }
  };

  $scope.deleteStreamMapping = (index) => {
    $scope.props.appointment.costStreamMappings.splice(index, 1);
  };

  $scope.addStreamMapping = () => {
    const {
      streamId,
      amount: amountRaw,
    } = $scope.props.newStreamMappingForm;

    const amount = parseInt(amountRaw, 10);

    if (!amount) {
      return;
    }

    const totalAmount = $scope.props.appointment.costStreamMappings
      .reduce((acc, mapping) => (mapping.amount + acc), 0) + amount;

    if (totalAmount > 100) {
      return;
    }

    const includesSameStream = $scope.props.appointment.costStreamMappings
      .find((mapping) => mapping.streamId === streamId);

    if (includesSameStream) {
      return;
    }

    $scope.props.appointment.costStreamMappings.push({ streamId, amount });
  };

  $scope.onDateStartChange = (dateStart) => {
    updateShape.dateStart = dateStart.format('YYYY-MM-DD');
  };

  $scope.onLineManagerChange = (user) => {
    updateShape.lineManagerId = user ? user.id : null;
  };

  $scope.onEntityChange = (entity) => {
    const { id } = entity;
    updateShape.entityId = id;
    updateStaffGroupList(id);
  };

  $scope.save = () => {
    const {
      props: {
        appointment,
        noMappingsDeclaration,
      },
    } = $scope;

    if (!appointment.costStreamMappings.length && !noMappingsDeclaration) {
      return AlertService.add('danger', translations['STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ALERT_MAPPINGS']);
    }

    const shape = {
      ...updateShape,
      positionId: appointment.position.id,
      companyId: appointment.company.id,
      groupId: appointment.groupId,
      contractBasis: appointment.contractBasis,
      worksBankHolidays: !!appointment.worksBankHolidays,
      wtdOptOut: !!appointment.wtdOptOut,
      minObligation: $scope.props.minObligationEnabled ? appointment.minObligation : null,
      maxObligation: $scope.props.maxObligationEnabled ? appointment.maxObligation : null,
      costStreamMappings: appointment.costStreamMappings ? appointment.costStreamMappings
        .map(({ streamId, amount }) => ({
          streamId,
          amount,
        })) : undefined,
      payrollCalendarId: appointment.payrollCalendarId,
    };

    $scope.props.actionInProgress = 'save';

    if ($scope.props.isAdd) {
      const addShape = {
        employmentId,
        dateStart: appointment.localDateStart.format('YYYY-MM-DD'),
        entityId: appointment.entityId,
        lineManagerId: appointment.lineManager ? appointment.lineManager.id : undefined,
        ...shape,
      };

      StaffService.addAppointment(userId, addShape)
        .then(({ data }) => {
          AlertService.add('success', translations['STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ALERT_SAVED']);

          $uibModalInstance.close({
            action: 'create',
            response: data,
          });
        })
        .catch(({ status, data }) => {
          $scope.props.actionInProgress = false;

          if (status === 500) {
            AlertService.add('danger', translations['STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ERROR_500']);
            return;
          }

          if (status === 400) {
            const modal = StaffCommon.onEmploymentValidationResponse(data.validationResult);

            modal.result.then((result) => {
              if (result === true) {
                $scope.save();
              }
            });
          }
        });
    } else {
      StaffService.updateAppointment(appointment.id, userId, shape)
        .then(({ data }) => {
          AlertService.add('success', translations['STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ALERT_SAVED']);

          $uibModalInstance.close({
            action: 'update',
            response: data,
          });
        })
        .catch(({ status, data }) => {
          $scope.props.actionInProgress = false;

          if (status === 500) {
            AlertService.add('danger', translations['STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ERROR_500']);
            return;
          }

          if (status === 400) {
            const modal = StaffCommon.onEmploymentValidationResponse(data.validationResult);

            modal.result.then((result) => {
              if (result === true) {
                $scope.save();
              }
            });
          }
        });
    }
  };

  $scope.delete = () => {
    $scope.props.actionInProgress = 'delete';
    const { props: { appointment } } = $scope;

    StaffService.deleteAppointment(appointment.id, userId)
      .then(({ data }) => {
        AlertService.add('success', translations['STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ALERT_DELETED']);

        $uibModalInstance.close({
          action: 'delete',
          response: data,
        });
      })
      .catch(({ status, data }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.EMPLOYMENT.APPOINTMENT_FORM.ERROR_500']);
          return;
        }

        if (status === 400) {
          const modal = StaffCommon.onEmploymentValidationResponse(data.validationResult);

          modal.result.then((result) => {
            if (result === true) {
              $scope.delete();
            }
          });
        }
      });
  };
};
