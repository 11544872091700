const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/pay/addTroncModal.html');

import { TroncUserApportionsQuery } from '../../../../fragments';
import controllers from '../../../module';

controllers.controller('TroncPayStaffCtrl', ['$scope', '$state', '$translate', '$uibModal', 'apollo', 'AlertService',
    function ($scope, $state, $translate, $uibModal, apollo, AlertService) {
        const translations = $translate.instant([
            'STAFF.VIEW.PAY.VIEW_TRONC.ERROR_500',
            'STAFF.VIEW.PAY.VIEW_TRONC.ERROR_403',
            'STAFF.VIEW.PAY.VIEW_TRONC.APPORTION_TYPE_CLASSIC_HOURLY',
            'STAFF.VIEW.PAY.VIEW_TRONC.APPORTION_TYPE_CLASSIC_SALARY',
            'STAFF.VIEW.PAY.VIEW_TRONC.APPORTION_TYPE_POINTS',
        ]);

        $scope.props = {
            loadingData: false,
            data: [],
        };

        function apportionTypeToLabel(apportionType) {
            switch (apportionType) {
                case 'POINTS':
                default:
                    return translations['STAFF.VIEW.PAY.VIEW_TRONC.APPORTION_TYPE_POINTS'];
                case 'CLASSIC_HOURLY':
                    return translations['STAFF.VIEW.PAY.VIEW_TRONC.APPORTION_TYPE_CLASSIC_HOURLY'];
                case 'CLASSIC_SALARY':
                    return translations['STAFF.VIEW.PAY.VIEW_TRONC.APPORTION_TYPE_CLASSIC_SALARY'];
            }
        }

        function formatNode(node) {
            const {
                startDate,
                endDate,
                apportionType,
            } = node;

            const start = moment(startDate);
            const end = endDate ? moment(endDate) : null;
            let chronoType;

            if (start.isAfter()) {
                chronoType = 2;
            } else if (!end || end.isAfter()) {
                chronoType = 1;
            } else {
                chronoType = 3;
            }

            return {
                ...node,
                chronoType,
                conflationKey: startDate,
                startDateMoment: start,
                endDateMoment: end,
                apportionTypeLabel: apportionTypeToLabel(apportionType),
                lastEditedMoment: moment(node.lastEdited.date),
            };
        }

        function conflateNodes(nodes) {
            const nodesByDate = new Map();

            const allNodes = [...$scope.props.data, ...nodes];

            allNodes.forEach((rawNode) => {
                const node = formatNode(rawNode);

                const {
                    conflationKey,
                    lastEditedMoment,
                } = node;

                if (!nodesByDate.has(conflationKey) || nodesByDate.get(conflationKey).lastEditedMoment.isBefore(lastEditedMoment)) {
                    nodesByDate.set(conflationKey, node);
                }
            });

            $scope.props.data = Array.from(nodesByDate.values())
                .sort((a, b) => b.startDate.valueOf() - a.startDate.valueOf());
        }

        $scope.loadData = (reset) => {
            if ($scope.props.loadingData) {
                return;
            }

            $scope.props.loadingData = true;

            if (reset) {
                $scope.props.cursor = null;
                $scope.props.noMoreData = false;
                $scope.props.data = [];
            }

            apollo
            .query({
                query: TroncUserApportionsQuery,
                variables: {
                    userId: parseInt($scope.userId, 10),
                    lastCursor: $scope.props.cursor,
                },
            })
            .then(({ data }) => {
                const {
                    troncUserApportions: {
                        edges,
                        pageInfo: {
                            endCursor,
                            hasNextPage,
                        },
                    },
                } = data;

                conflateNodes(edges.map(({ node }) => node));

                $scope.props.cursor = endCursor;

                if (!hasNextPage) {
                    $scope.props.noMoreData = true;
                }

                $scope.props.loadingData = false;
            })
            .catch(({ graphQLErrors, networkError }) => {
                $scope.props.loadingData = false;
                $scope.props.noMoreData = true;

                if (networkError && networkError.statusCode === 403) {
                    AlertService.add('warning', translations['STAFF.VIEW.PAY.VIEW_TRONC.ERROR_403']);
                } else {
                    AlertService.add('danger', translations['STAFF.VIEW.PAY.VIEW_TRONC.ERROR_500']);
                }
            });
        };

        $scope.addNew = () => {
            const modal = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'AddTroncModalCtrl',
                resolve: {
                    data: () => ({
                        user: $scope.userData,
                    }),
                },
            });

            modal.result.then((result) => {
                conflateNodes([result.node]);
            });
        };
    }]);
