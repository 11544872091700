const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/attendance/addEventModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/dialog/twoButtonDialog.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/dialog/twoButtonDialog.html');
const autoNgTemplateLoaderTemplate4 = require('/home/circleci/project/app/htdocs/views/rotamanagement/shift/editModal.html');
const autoNgTemplateLoaderTemplate5 = require('/home/circleci/project/app/htdocs/views/dialog/twoButtonDialog.html');

'use strict';
var services = require('./module');

services.factory('AttendanceCommon', ['$uibModal', '$state', '$translate', 'AlertService', 'AttendanceService',
    function ($uibModal, $state, $translate, AlertService, AttendanceService) {
        var translations = $translate.instant([
            'ATTENDANCE.VALIDATION_CODES.CODE_1',
            'ATTENDANCE.VALIDATION_CODES.CODE_2',
            'ATTENDANCE.VALIDATION_CODES.CODE_3',
            'ATTENDANCE.VALIDATION_CODES.CODE_4',
            'ATTENDANCE.VALIDATION_CODES.CODE_5',
            'ATTENDANCE.VALIDATION_CODES.CODE_6',
            'ATTENDANCE.VALIDATION_CODES.CODE_7',
            'ATTENDANCE.VALIDATION_CODES.CODE_8',
            'ATTENDANCE.COMMON.EVENT_ADD_SUCCESS',
            'ATTENDANCE.COMMON.ABSENCE_ADD_SUCCESS',
            'ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_TITLE',
            'ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_MESSAGE',
            'ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_ADD_EVENT',
            'ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_ADD_EVENT_DESC',
            'ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_ADD_ABSENCE',
            'ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC',
            'ATTENDANCE.COMMON.UNMATCHED_MODAL_TITLE',
            'ATTENDANCE.COMMON.UNMATCHED_MODAL_MESSAGE',
            'ATTENDANCE.COMMON.UNMATCHED_MODAL_ADD_SHIFT',
            'ATTENDANCE.COMMON.UNMATCHED_MODAL_ADD_SHIFT_DESC',
            'ATTENDANCE.COMMON.UNMATCHED_MODAL_EDIT_EVENT',
            'ATTENDANCE.COMMON.UNMATCHED_MODAL_EDIT_EVENT_DESC',
            'ATTENDANCE.COMMON.OVERTIME_MODAL_TITLE',
            'ATTENDANCE.COMMON.OVERTIME_MODAL_APPROVE',
            'ATTENDANCE.COMMON.OVERTIME_MODAL_REJECT',
            'ATTENDANCE.COMMON.OVERTIME_UPDATE_ERROR',
            'ATTENDANCE.COMMON.MODAL_USER_NOTES_LABEL',
        ]);

        var retObj = {};

        retObj.displayValidationError = function (response) {
            var error = response.errors[0];

            if (error) {
                var validationCode = error.payload.validationCode;
                AlertService.add('danger', translations['ATTENDANCE.VALIDATION_CODES.CODE_' + validationCode]);
            }
        };

        retObj.openModalAddEvent = function (eventType, user, date, entityId, rollTimeForwards, callback) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'AddEventAttendanceModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            eventType: eventType,
                            date: rollTimeForwards ? moment(date).add(1, 'minute') : moment(date),
                            entityId: entityId,
                            userId: user.id,
                        };
                    }
                }
            });

            modalInstance.result.then(function (newEvent) {
                if (newEvent) {
                    AlertService.add('success', translations['ATTENDANCE.COMMON.EVENT_ADD_SUCCESS']);
                }

                callback(newEvent);
            });
        };

        retObj.openModalNoClockIn = function (user, date, entityId, callback, userNotes) {
            var userNotesTitle = $translate.instant('ATTENDANCE.COMMON.MODAL_USER_NOTES_LABEL', {
                name: user.firstName,
            })

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'TwoButtonDialogCtrl',
                resolve: {
                    data: function () {
                        return {
                            title: translations['ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_TITLE'],
                            message: translations['ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_MESSAGE'],
                            user,
                            userNotes,
                            userNotesTitle,
                            optionATitle: translations['ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_ADD_EVENT'],
                            optionAText: translations['ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_ADD_EVENT_DESC'],
                            optionBTitle: translations['ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_ADD_ABSENCE'],
                            optionBText: translations['ATTENDANCE.COMMON.NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC'],
                        };
                    }
                }
            });

            modalInstance.result.then(function (optionASelected) {
                if (optionASelected) {
                    retObj.openModalAddEvent(1, user, date, entityId, false, callback);
                } else {
                  $state.go('absenceRequest', {
                    start: moment(date).format('YYYY-MM-DD'),
                    end: moment(date).add(1, 'day').format('YYYY-MM-DD'),
                    userId: user.id,
                    overlapShifts: true,
                  });
                }
            });
        };

        retObj.openModalUnmatchedAttendance = function (clockInId, clockInDate, clockOutDate, user, callback, userNotes) {
            var userNotesTitle = $translate.instant('ATTENDANCE.COMMON.MODAL_USER_NOTES_LABEL', {
                name: user.firstName,
            })

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate3,
                controller: 'TwoButtonDialogCtrl',
                resolve: {
                    data: function () {
                        return {
                            title: translations['ATTENDANCE.COMMON.UNMATCHED_MODAL_TITLE'],
                            message: translations['ATTENDANCE.COMMON.UNMATCHED_MODAL_MESSAGE'],
                            user,
                            userNotes,
                            userNotesTitle,
                            optionATitle: translations['ATTENDANCE.COMMON.UNMATCHED_MODAL_ADD_SHIFT'],
                            optionAText: translations['ATTENDANCE.COMMON.UNMATCHED_MODAL_ADD_SHIFT_DESC'],
                            optionBTitle: translations['ATTENDANCE.COMMON.UNMATCHED_MODAL_EDIT_EVENT'],
                            optionBText: translations['ATTENDANCE.COMMON.UNMATCHED_MODAL_EDIT_EVENT_DESC'],
                        };
                    }
                }
            });

            modalInstance.result.then(function (optionASelected) {
                if (optionASelected) {
                    var start = moment(clockInDate);

                    var addShiftModal = $uibModal.open({
                        templateUrl: autoNgTemplateLoaderTemplate4,
                        controller: 'EditShiftModalCtrl',
                        resolve: {
                            data: function () {
                                return {
                                    isAdd: true,
                                    start: start,
                                    end: clockOutDate ? moment(clockOutDate) : start.clone().add(2, 'hours'),
                                    assignedStaff: [{
                                        id: user.id,
                                        name: user.firstName + ' ' + user.lastName
                                    }],
                                    isPublished: true,
                                    rotaId: false
                                }
                            }
                        }
                    });

                    addShiftModal.result.then(function (shiftAdded) {
                        if (!shiftAdded) {
                            return;
                        }

                        callback();
                    });
                } else {
                    $state.go('staff.view.attendance', {
                        id: user.id,
                        event: clockInId,
                    });
                }
            });
        };

        retObj.openModalOvertimeApproval = function (isClockIn, attendanceEvent, scheduledEvent, user, callback, userNotes) {
            var earlierTime = isClockIn ? moment(attendanceEvent.date) : moment(scheduledEvent.end);
            var laterTime = isClockIn ? moment(scheduledEvent.start) : moment(attendanceEvent.date);

            var duration = laterTime.diff(earlierTime, 'minutes');

            var optionAText = $translate.instant('ATTENDANCE.COMMON.OVERTIME_MODAL_APPROVE_DESC', {
                name: user.firstName,
                duration: duration,
            });

            var optionBText = $translate.instant('ATTENDANCE.COMMON.OVERTIME_MODAL_REJECT_DESC', {
                name: user.firstName,
            });

            var message = isClockIn ? $translate.instant('ATTENDANCE.COMMON.OVERTIME_MODAL_MESSAGE_IN', {
                name: user.firstName,
            }) : $translate.instant('ATTENDANCE.COMMON.OVERTIME_MODAL_MESSAGE_OUT', {
                name: user.firstName,
            });

            var userNotesTitle = $translate.instant('ATTENDANCE.COMMON.MODAL_USER_NOTES_LABEL', {
                name: user.firstName,
            })

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate5,
                controller: 'TwoButtonDialogCtrl',
                resolve: {
                    data: function () {
                        return {
                            title: translations['ATTENDANCE.COMMON.OVERTIME_MODAL_TITLE'],
                            message: message,
                            user,
                            userNotes,
                            userNotesTitle,
                            optionATitle: translations['ATTENDANCE.COMMON.OVERTIME_MODAL_APPROVE'],
                            optionAText: optionAText,
                            optionBTitle: translations['ATTENDANCE.COMMON.OVERTIME_MODAL_REJECT'],
                            optionBText: optionBText,
                        };
                    }
                }
            });

            modalInstance.result.then(function (optionASelected) {
                var fields = {
                    overtimeApprovalState: optionASelected ? 1 : 2,
                };

                AttendanceService.updateEvent(attendanceEvent.id, fields)
                    .success(function () {
                        callback();
                    })
                    .error(function (error, status) {
                        if (status === 403) {
                            return;
                        }

                        if (status === 400) {
                            retObj.displayValidationError(error);
                            return;
                        }

                        AlertService.add('danger', translations['ATTENDANCE.COMMON.OVERTIME_UPDATE_ERROR']);
                    });
            });
        };

        return retObj;
    }
]);
