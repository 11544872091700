var controllers = require('../../../module');

controllers.controller(
    'SettingsIntegrationXeroCtrl',
    [
        '$scope',
        '$http',
        '$window',
        '$stateParams',
        'EnvironmentDataService',
        'AlertService',
        'AuthService',
        'ENDPOINT_API',
        function ($scope, $http, $window, $stateParams, EnvironmentDataService, AlertService, AuthService, ENDPOINT_API) {
            var apiBaseUrl = ENDPOINT_API + '/integration/xero';
            $scope.dataLoaded = false;
            $scope.realmId = AuthService.getRealm();
            $scope.metadata = {};

            if ($stateParams.success === 'true') {
                AlertService.add('success', 'Your Xero organisation has been successfully connected to Rotaready! Please finish setup by mapping the relevant data types below.');
            } else if ($stateParams.success === 'false') {
                AlertService.add('danger', 'We were unable to connect your Xero organisation to Rotaready. Please try again.');
            }

            function handleError(error, status) {
                if (status !== 403) {
                    AlertService.add('danger', 'We weren\'t able to load or modify the settings just then. Please try again');
                }
            }

            function loadSettings() {
                $scope.dataLoaded = false;

                $http.get(apiBaseUrl + '/settings')
                    .success(function (data) {
                        $scope.authorized = data.authorized;
                        $scope.connections = data.connections;
                        $scope.configuration = data.configuration;

                        $scope.dataLoaded = true;
                    })
                    .error(function (error, status) {
                        handleError(error, status);
                    });
            }

            function loadMetadata() {
                EnvironmentDataService.fetchAll([
                    EnvironmentDataService.DataType.Company,
                    EnvironmentDataService.DataType.AbsenceType,
                    EnvironmentDataService.DataType.PayElementType,
                    EnvironmentDataService.DataType.PayrollCalendar,
                ])
                .then(function (result) {
                    $scope.metadata.companies = result[0].data.map(function (item) {
                        return {
                            id: item.id,
                            name: item.deleted ? (item.name + ' (Deleted)') : item.name,
                        };
                    });

                    $scope.metadata.absenceTypes = result[1].data.map(function (item) {
                        return {
                            id: item.id,
                            name: item.deleted ? (item.name + ' (Deleted)') : item.name,
                        };
                    });

                    $scope.metadata.payElementTypes = result[2].data.map(function (item) {
                        return {
                            id: item.id,
                            name: item.isDeleted ? (item.name + ' (Deleted)') : item.name,
                        };
                    });

                    $scope.metadata.payrollCalendars = result[3].data.map(function (item) {
                        return {
                            id: item.id,
                            name: item.deleted ? (item.name + ' (Deleted)') : item.name,
                        };
                    });
                });
            }

            loadSettings();
            loadMetadata();

            $scope.save = function () {
                var companyIdsSeen = [];
                var duplicateCompanyIds = false;
                var duplicateAbsenceTypes = false;
                var duplicatePayElementTypes = false;
                var duplicatePayrollCalendars = false;

                Object.keys($scope.configuration.organisations).forEach(function (k) {
                    var organisation = $scope.configuration.organisations[k];

                    if (!organisation.isActive) {
                        return;
                    }

                    var companyId = organisation.companyId;

                    if (companyIdsSeen.indexOf(companyId) !== -1) {
                        duplicateCompanyIds = true;
                    }

                    companyIdsSeen.push(companyId);

                    var absenceTypeIds = organisation.absenceTypeMappings.map(function (m) {
                        return m.absenceTypeId;
                    });

                    var dupeAbsenceTypes = absenceTypeIds.filter(function (id, index) {
                        return absenceTypeIds.indexOf(id) !== index;
                    });

                    if (dupeAbsenceTypes.length) {
                        duplicateAbsenceTypes = true;
                    }

                    var payElementTypeIds = organisation.payElementTypeMappings.map(function (m) {
                        return m.payElementTypeId;
                    });

                    var dupePayElementTypes = payElementTypeIds.filter(function (id, index) {
                        return payElementTypeIds.indexOf(id) !== index;
                    });

                    if (dupePayElementTypes.length) {
                        duplicatePayElementTypes = true;
                    }

                    var payrollCalendarIds = organisation.payrollCalendarMappings.map(function (m) {
                        return m.rotareadyId;
                    })

                    var dupePayrollCalendarIds = payrollCalendarIds.filter(function (id, index) {
                        return payrollCalendarIds.indexOf(id) !== index;
                    });

                    if (dupePayrollCalendarIds.length) {
                        duplicatePayrollCalendars = true;
                    }
                });

                if (duplicateCompanyIds) {
                    return alert('One company has been mapped to multiple Xero organisations. Please correct this before saving.');
                }

                if (duplicateAbsenceTypes) {
                    return alert('A Rotaready absence type has been mapped twice. Please correct this before saving.');
                }

                if (duplicatePayElementTypes) {
                    return alert('A Rotaready pay element type has been mapped twice. Please correct this before saving.');
                }

                if (duplicatePayrollCalendars) {
                    return alert('A Rotaready payroll calendar has been mapped twice. Please correct this before saving.');
                }

                $scope.saveActioning = true;

                var data = {
                    configuration: $scope.configuration,
                };

                $http.put(apiBaseUrl + '/settings', data)
                    .success(function () {
                        AlertService.add('success', 'Your Xero settings have been updated.');
                        $scope.saveActioning = false;
                    })
                    .error(function (error, status) {
                        handleError(error, status);
                        $scope.saveActioning = false;
                    });
            };

            $scope.connectToXero = function () {
                $scope.redirectLoading = true;

                $http.get(apiBaseUrl + '/authorizeUrl', {
                    params: {
                        returnUrl: $window.location.href,
                    },
                })
                    .success(function (data) {
                        $window.location.href = data.authorizeUrl;
                    })
                    .error(function (error, status) {
                        $scope.redirectLoading = false;
                        handleError(error, status);
                    });
            };

            $scope.disconnect = function (id, organisation) {
                if (!confirm('Are you sure you wish to disconnect this organisation?')) {
                    return;
                }

                organisation.isDisconnecting = true;

                $http.delete(apiBaseUrl + '/organisation/' + id)
                    .success(function () {
                        delete $scope.configuration.organisations[id];
                        AlertService.add('success', 'Your organisation has been disconnected from Xero.');
                    })
                    .error(function (error, status) {
                        organisation.isDisconnecting = false;
                        handleError(error, status);
                    });
            };

            $scope.addAbsenceTypeMapping = function (orgId) {
                $scope.configuration.organisations[orgId].absenceTypeMappings.push({
                    absenceTypeId: $scope.metadata.absenceTypes[0].id,
                    leaveTypeId: '',
                });
            };

            $scope.removeAbsenceTypeMapping = function (orgId, index) {
                $scope.configuration.organisations[orgId].absenceTypeMappings.splice(index, 1);
            };

            $scope.addPayElementTypeMapping = function (orgId) {
                $scope.configuration.organisations[orgId].payElementTypeMappings.push({
                    payElementTypeId: $scope.metadata.payElementTypes[0].id,
                    earningsRateId: '',
                });
            };

            $scope.removePayElementTypeMapping = function (orgId, index) {
                $scope.configuration.organisations[orgId].payElementTypeMappings.splice(index, 1);
            };

            $scope.addPayrollCalendarMapping = function (orgId) {
                if (!$scope.configuration.organisations[orgId].payrollCalendarMappings) {
                    $scope.configuration.organisations[orgId].payrollCalendarMappings = [];
                }

                $scope.configuration.organisations[orgId].payrollCalendarMappings.push({
                    rotareadyId: $scope.metadata.payrollCalendars[0].id,
                    xeroId: '',
                });
            };

            $scope.removePayrollCalendarMapping = function (orgId, index) {
                $scope.configuration.organisations[orgId].payrollCalendarMappings.splice(index, 1);
            };
        }
    ]
);
