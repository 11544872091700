const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/timepickerPop.html');

'use strict';
var directives = require('./module');

directives.factory('timepickerState', function () {
    var pickers = [];
    return {
        addPicker: function (picker) {
            pickers.push(picker);
        },
        closeAll: function () {
            for (var i = 0; i < pickers.length; i++) {
                pickers[i].close();
            }
        }
    };
})

    .directive('timepickerPop', function ($document, $timeout, timepickerState, SessionService) {
        return {
            restrict: 'E',
            templateUrl: autoNgTemplateLoaderTemplate1,
            scope: {
                inputTime: "=",
                disabled: "=?",
                minuteStep: "=?",
                hourStep: "=?",
                isSmall: "=?",
                onClosed: "&",
                onOpen: "&",
                isDuration: '=?',
            },
            controller: function ($scope) {
                $scope.isOpen = false;
                $scope.hours = '';
                $scope.minutes = '';
                $scope.meridiem = '';
                $scope.is12HourClockMode = !$scope.isDuration && SessionService.is12HourClockMode();

                $scope.disabled = angular.isDefined($scope.disabled) ? $scope.disabled : false;
                $scope.minuteStep = angular.isDefined($scope.minuteStep) ? parseInt($scope.minuteStep) : 5;
                $scope.hourStep = angular.isDefined($scope.hourStep) ? parseInt($scope.hourStep) : 1;
                $scope.isSmall = angular.isDefined($scope.isSmall) ? $scope.isSmall : false;

                $scope.toggle = function () {
                    if ($scope.isOpen) {
                        $scope.close();
                    } else {
                        $scope.open();
                    }
                };

                $scope.$watch('inputTime', function (newVal) {
                    if (newVal === undefined) {
                        return;
                    }

                    if (!moment.isMoment(newVal)) {
                        if (typeof newVal === 'string') {
                            // If it's in the format of HH:mm, let's get it into HH:mm:ss
                            if (newVal.length === 5) {
                                newVal = newVal + ':00';
                            }

                            if (newVal.length === 8) {
                                // Handle time-only strings
                                $scope.inputTime = moment(newVal, "HH:mm:ss");
                            } else {
                                // Also handle full ISO date strings
                                $scope.inputTime = moment(newVal);
                            }
                        } else {
                            // If all else fails, default to the start of the day
                            $scope.inputTime = moment().startOf('day');
                        }
                    }

                    $scope.initialTime = $scope.inputTime.clone();

                    updateDisplay();
                });

                function updateDisplay() {
                    $scope.invalidHours = false;
                    $scope.invalidMinutes = false;

                    $scope.hours = $scope.inputTime.format($scope.is12HourClockMode ? 'h' : 'HH');
                    $scope.minutes = $scope.inputTime.format('mm');
                    $scope.meridiem = $scope.inputTime.format('A');
                    $scope.timeValue = $scope.inputTime.format($scope.isDuration ? 'HH:mm' : 'LT');
                }

                $scope.updateHours = function () {
                    var val = $scope.hours;

                    if (isNaN(parseInt(val))) {
                        $scope.invalidHours = true;
                        return;
                    }

                    val = parseInt(val);

                    if (val < 0 || val > 23) {
                        $scope.invalidHours = true;
                        return;
                    }

                    if ($scope.is12HourClockMode) {
                        // The current time is in the PM, keep it so
                        if ($scope.inputTime.hours() >= 12 && val > 0 && val < 12) {
                            val += 12;
                        }

                        // The current time is in the AM, keep it so
                        if ($scope.inputTime.hours() < 12 && val >= 12) {
                            val -= 12;
                        }
                    }

                    $scope.inputTime.hours(val);
                    updateDisplay();
                };

                $scope.updateMinutes = function () {
                    var val = $scope.minutes;

                    if (isNaN(parseInt(val))) {
                        $scope.invalidMinutes = true;
                        return;
                    }

                    val = parseInt(val);

                    if (val < 0 || val > 59) {
                        $scope.invalidMinutes = true;
                        return;
                    }

                    $scope.inputTime.minutes(val);
                    updateDisplay();
                };

                $scope.flipMeridiem = function () {
                    var hours = $scope.inputTime.hours();

                    if (hours < 12) {
                        hours += 12;
                    } else {
                        hours -= 12;
                    }

                    $scope.inputTime.hours(hours);
                    updateDisplay();
                };

                function resetDate() {
                    $scope.inputTime.set({
                        year: $scope.initialTime.year(),
                        month: $scope.initialTime.month(),
                        date: $scope.initialTime.date()
                    });
                }

                // In the four functions below we reset the date after any change
                $scope.incrementHours = function () {
                    $scope.inputTime.add($scope.hourStep, 'hour');
                    resetDate();
                    updateDisplay();
                };

                $scope.decrementHours = function () {
                    $scope.inputTime.subtract($scope.hourStep, 'hour');
                    resetDate();
                    updateDisplay();
                };

                $scope.incrementMinutes = function () {
                    $scope.inputTime.add($scope.minuteStep, 'minute');
                    resetDate();
                    updateDisplay();
                };

                $scope.decrementMinutes = function () {
                    $scope.inputTime.subtract($scope.minuteStep, 'minute');
                    resetDate();
                    updateDisplay();
                };
            },
            link: function (scope, element) {
                var onCloseFunc;

                var picker = {
                    open: function () {
                        timepickerState.closeAll();
                        scope.isOpen = true;

                        if (scope.onClosed) {
                            onCloseFunc = scope.onClosed;
                        }

                        if (scope.onOpen) {
                            scope.onOpen();
                        }
                    },
                    close: function () {
                        scope.isOpen = false;

                        if (onCloseFunc) {
                            onCloseFunc();
                            onCloseFunc = null;
                        }
                    }
                };

                timepickerState.addPicker(picker);

                scope.open = picker.open;
                scope.close = picker.close;

                var minutesKeyUpPresses = 0;

                function isEnterOrEscape($event) {
                    return $event.keyCode === 13 || $event.keyCode === 27;
                }

                scope.onHoursKeyUp = function ($event) {
                    if (isEnterOrEscape($event)) {
                        scope.updateHours();
                        picker.close();
                        return;
                    }
                };

                scope.onMinutesKeyUp = function ($event) {
                    if (isEnterOrEscape($event)) {
                        scope.updateMinutes();
                        picker.close();
                        return;
                    }

                    // ng-keyup can fire immediately thanks to the key-up that just happened in the hours
                    // input, so we're checking that a genuine key-up has occurred here at least once first
                    minutesKeyUpPresses++;

                    if (minutesKeyUpPresses === 1) {
                        return;
                    }

                    if (scope.minutes.length === 2) {
                        // Problem here is that we close before updateMinutes() is called
                        scope.updateMinutes();
                        picker.close();
                    }
                };

                element.bind('click', function (event) {
                    event.preventDefault();
                    event.stopPropagation();

                    var isParentElement = $(event.target).hasClass('picker-display');

                    if (!isParentElement) {
                        return;
                    }

                    // Automatically select the hour value for quick keyboard entry
                    // Then progress to the minute input when 2 digits have been entered for hours
                    var inputs = $(":input", this);

                    if (inputs.length > 2) {
                        // This needs to be wrapped $() for IE
                        var container = $(inputs[1]).closest('tr');

                        container.keyup(function (e) {
                            var target = $(e.target);
                            var maxLength = target.attr('maxlength');
                            var myLength = target.val().length;

                            if (myLength >= maxLength) {
                                inputs[2].select();
                                minutesKeyUpPresses = 0;
                            }
                        });

                        $timeout(function () {
                            inputs[1].select();
                        });
                    }
                });

                $document.bind('click', function () {
                    scope.$apply(function () {
                        picker.close();
                    });
                });
            }
        };
    });
