const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/filterStaffSearchButton.html');

const directives = require('./module');

directives.directive('rrFilterStaffSearchButton', (
  $translate,
  $timeout,
  StaffService,
) => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      onSelect: '&',
      readOnly: '=?',
      defaultUserId: '=?',
      defaultText: '@?',
      icon: '@?',
      includeAny: '@?',
      includeAnyText: '@?',
      borderless: '@?',
      textInputAppearance: '@?',
      searchActiveOnly: '@?',
      searchScope: '@?',
    },
    link: ($scope, element) => {
      const translations = $translate.instant([
        'DIRECTIVES.FILTER_STAFF_SEARCH_BUTTON.IS',
        'DIRECTIVES.FILTER_STAFF_SEARCH_BUTTON.ANY',
      ]);

      $scope.props = {
        displayText: $scope.defaultText
          ? `${$scope.defaultText} ${translations['DIRECTIVES.FILTER_STAFF_SEARCH_BUTTON.IS']}` : '',
        anyText: $scope.includeAnyText || translations['DIRECTIVES.FILTER_STAFF_SEARCH_BUTTON.ANY'],
        isEnabled: true,
        isMenuOpen: false,
        icon: $scope.icon,
        initialised: true,
        selectedUser: undefined,
        searchActiveOnly: $scope.searchActiveOnly === 'true',
        searchScope: $scope.searchScope,
      };

      function evaluateEnabledState() {
        $scope.props.isEnabled = !$scope.readOnly && $scope.props.initialised;
      }

      $scope.$watch('readOnly', (val) => {
        if (val === undefined) {
          return;
        }

        evaluateEnabledState();
      });

      $scope.$watch('props.initialised', (val) => {
        if (val === undefined) {
          return;
        }

        evaluateEnabledState();
      });

      $scope.$watch('defaultUserId', (val) => {
        if (!val || Number.isNaN(val)) {
          return;
        }

        $scope.props.initialised = false;

        StaffService.getAccount(val)
          .then(({ data }) => {
            $scope.props.selectedUser = data.user;
            $scope.props.initialised = true;
          })
          .catch(() => {
            $scope.props.initialised = true;
          });
      });

      function onFinish() {
        const obj = {
          selectedUser: $scope.props.selectedUser,
        };

        if ($scope.onSelect) {
          $scope.onSelect(obj);
        }
      }

      $scope.onElementClick = () => {
        const searchElements = element.find('input');
        const count = searchElements.length;

        if (!count) {
          return;
        }

        const searchElement = angular.element(element.find('input')[count - 1]);

        $timeout(() => {
          searchElement.focus();
        }, 100);
      };

      $scope.onSearchSelect = (user) => {
        if (!user) {
          return;
        }

        $scope.props.selectedUser = user;
        $scope.props.isMenuOpen = false;
        onFinish();
      };

      $scope.clearSelectedUser = function () {
        $scope.props.selectedUser = undefined;
        $scope.props.isMenuOpen = false;
        onFinish();
      };

      evaluateEnabledState();
    },
  };
});
