const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messaging/conversationControls.html');

import directives from '../module';
import conversationControlsController from '../../controllers/messaging/conversationControls';

directives.directive('rrConversationControls', () => ({
  restrict: 'A',
  templateUrl: autoNgTemplateLoaderTemplate1,
  replace: false,
  controller: conversationControlsController,
  scope: {
    participantIds: '=',
    channelId: '=',
  },
}));
