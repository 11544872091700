const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/rotamanagement/shift/viewModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/absence/viewModal.html');

'use strict';
var controllers = require('./module');

controllers.controller('DashboardCtrl', ['$scope', '$uibModal', '$window', 'RotaService', 'AvailabilityService', 'SessionService', 'AttendanceService',
    function ($scope, $uibModal, $window, RotaService, AvailabilityService) {
        $scope.dateToday = moment().format('YYMMDD');

        $scope.upcomingShiftsLoaded = false;
        $scope.scheduleOverviewLoaded = false;
        $scope.itemsLoaded = 0;

        // todo This should be a directive
        $scope.$watch('itemsLoaded', function (newVal) {
            if (newVal == 2) {
                $('#dashboardOverlay').hide(500);
            }
        });

        $scope.upcomingShifts = [];
        $scope.scheduleOverview = [];

        var periodBegin = moment().startOf('day');
        var periodEnd = periodBegin.clone().endOf('day').add(1, 'week');

        var sortByDateAsc = function (lhs, rhs) {
            return lhs.start > rhs.start ? 1 : lhs.start < rhs.start ? -1 : 0;
        };

        function convertEventsToDays(data) {
            // Iterate over each day in the period
            for (var currentDay = moment(periodBegin); currentDay.isBefore(periodEnd); currentDay.add(1, 'days')) {
                var events = [];
                var shiftCount = 0;
                var earliestStart = moment(currentDay).endOf('day');
                var latestFinish = moment(currentDay);

                angular.forEach(data, function (event) {
                    // Ignore everything except shifts and absence
                    if (event.eventType !== 1 && event.eventType !== 3) {
                        return;
                    }

                    // Collect up all events on this day
                    if (!currentDay.isSame(event.start, 'day')) {
                        return;
                    }

                    // Adjust the day's start/finish times
                    if (event.eventType === 1) {
                        event.hasTime = true;
                        event.title = event.typeName;
                        event.subtitle = event.isCover ? event.workEntityName : false;

                        shiftCount++;

                        if (event.start.isBefore(earliestStart)) {
                            earliestStart = event.start.clone();
                        }

                        if (event.end.isAfter(latestFinish)) {
                            latestFinish = event.end.clone();
                        }
                    }

                    if (event.eventType === 3) {
                        event.hasTime = false;
                        event.title = event.type.name;
                    }

                    events.push(event);
                });

                $scope.upcomingShifts.push({
                    id: currentDay.format('YYYYMMDD'),
                    shortName: currentDay.format('dddd Do'),
                    startTime: earliestStart.format('LT'),
                    endTime: latestFinish.format('LT'),
                    events: events,
                    shiftCount: shiftCount
                });
            }
        }

        AvailabilityService.getCalendar(periodBegin, periodEnd, false, false)
            .success(function (data) {
                data.sort(sortByDateAsc);

                var events = [];

                // Pad out absence events that span more than 1 day
                angular.forEach(data, function (event) {
                    event.start = moment(event.start);
                    event.end = moment(event.end);

                    if (event.eventType === 3) {
                        var daysBetween = event.end.diff(event.start, 'days');

                        if (daysBetween > 1) {
                            for (var i = 0; i < daysBetween; i++) {
                                var clonedEvent = angular.copy(event);

                                clonedEvent.start = event.start.clone().add(i, 'days');
                                clonedEvent.end = event.start.clone().add(i + 1, 'days');

                                events.push(clonedEvent);
                            }

                            return;
                        }
                    }

                    events.push(event);
                });

                convertEventsToDays(events);

                $scope.upcomingShiftsLoaded = true;
                $scope.itemsLoaded++;
            });

        function loadScheduleOverview() {
            $scope.scheduleOverviewLoaded = false;
            $scope.scheduleOverview = [];
            var scheduleGroups = [];

            var overviewBegin = moment().startOf('day');
            var overviewEnd = overviewBegin.clone().endOf('day');

            RotaService.getShifts(overviewBegin, overviewEnd)
                .success(function (data) {
                    angular.forEach(data, function (entry) {
                        // Ignore 'Unassigned' shifts
                        if (entry.userId === 0) {
                            return;
                        }

                        var entryStart = moment(entry.start);

                        var resourceEntry;
                        angular.forEach(scheduleGroups, function (val) {
                            if (val.id === entry.typeId) {
                                resourceEntry = val;
                            }
                        });

                        if (!resourceEntry) {
                            resourceEntry = {
                                id: entry.typeId,
                                times: [],
                                total: 0,
                                name: entry.typeName
                            };

                            scheduleGroups.push(resourceEntry);
                        }

                        var timeEntry;

                        angular.forEach(resourceEntry.times, function (val) {
                            if (val.start.unix() === entryStart.unix()) {
                                timeEntry = val;
                            }
                        });

                        if (!timeEntry) {
                            timeEntry = {
                                start: moment(entry.start),
                                staff: []
                            };
                            resourceEntry.times.push(timeEntry);
                        }

                        resourceEntry.total++;
                        timeEntry.staff.push({ id: entry.userId, name: entry.userName, shiftId: entry.id });
                    });

                    var mod = 0;
                    var width = $window.innerWidth;

                    if (width >= 1200) {
                        mod = 4;
                    } else if (width >= 992) {
                        mod = 3;
                    } else if (width >= 768) {
                        mod = 2;
                    } else {
                        mod = 1;
                    }

                    angular.forEach(scheduleGroups, function (group, idx) {
                        if (group.times) {
                            group.times.sort(sortByDateAsc);
                        }

                        if (idx % mod === 0) {
                            $scope.scheduleOverview.push([]);
                        }

                        $scope.scheduleOverview[$scope.scheduleOverview.length - 1].push(group);
                    });

                    $scope.scheduleOverviewLoaded = true;
                    $scope.itemsLoaded++;
                });
        }

        loadScheduleOverview();

        $scope.openShiftModal = function (id) {
            $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'ViewShiftModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            id: id
                        };
                    }
                }
            });
        };

        $scope.openEvent = function (event) {
            if (event.eventType === 1) {
                $scope.openShiftModal(event.id);
            }

            if (event.eventType === 3) {
                $uibModal.open({
                    templateUrl: autoNgTemplateLoaderTemplate2,
                    controller: require('./absence/viewModal'),
                    resolve: {
                        data: function () {
                            return {
                                id: event.id,
                            };
                        }
                    }
                });
            }
        };
    }]);
