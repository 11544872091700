const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/reports/signOffProgress/statusModal.html');

module.exports = (
  $scope,
  $http,
  $translate,
  $uibModal,
  $state,
  $stateParams,
  EnvironmentDataService,
  AlertService,
  ExportService,
  ENDPOINT_API,
) => {
  'ngInject';

  const translations = $translate.instant([
    'REPORTS.SIGN_OFF_PROGRESS.TITLE',
    'REPORTS.SIGN_OFF_PROGRESS.STATUS_INCOMPLETE',
    'REPORTS.SIGN_OFF_PROGRESS.STATUS_REPORTING_DATE',
    'REPORTS.SIGN_OFF_PROGRESS.STATUS_COMPLETE',
    'REPORTS.SIGN_OFF_PROGRESS.TOGGLE_COMPARAND_FORECAST',
    'REPORTS.SIGN_OFF_PROGRESS.TOGGLE_COMPARAND_BUDGET',
    'REPORTS.SIGN_OFF_PROGRESS.ERROR_500',
  ]);

  let isFirstLoad = true;
  const defaultWeekStart = $stateParams.week && moment($stateParams.week).isValid()
    ? moment($stateParams.week) : moment().subtract(1, 'week').startOf('week');
  const defaultEntityGroupIds = $stateParams.entityGroupIds
    ? $stateParams.entityGroupIds.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];

  $scope.props = {
    startDate: defaultWeekStart,
    loadingData: false,
    data: [],
    signOffStatuses: [
      { id: 'incomplete', label: translations['REPORTS.SIGN_OFF_PROGRESS.STATUS_INCOMPLETE'] },
      { id: 'reportingDate', label: translations['REPORTS.SIGN_OFF_PROGRESS.STATUS_REPORTING_DATE'] },
      { id: 'complete', label: translations['REPORTS.SIGN_OFF_PROGRESS.STATUS_COMPLETE'] },
    ],
    defaultSignOffStatus: [''],
    defaultEntityGroupIds,
    hasSelectedGroups: !!defaultEntityGroupIds.length,
    comparandOptions: [
      { id: 'forecast', label: translations['REPORTS.SIGN_OFF_PROGRESS.TOGGLE_COMPARAND_FORECAST'] },
      { id: 'budget', label: translations['REPORTS.SIGN_OFF_PROGRESS.TOGGLE_COMPARAND_BUDGET'] },
    ],
    defaultComparandOptions: ['forecast'],
    comparand: 'forecast',
  };

  $scope.getDataParams = {
    weekStartDate: $scope.props.startDate.format('YYYY-MM-DD'),
    'entityGroupIds[]': defaultEntityGroupIds.length ? defaultEntityGroupIds : undefined,
  };

  $scope.onSignOffStatusChange = (selectedOptions) => {
    const option = selectedOptions[0];
    $scope.getDataParams.signOffStatus = option || [];
    $scope.loadData();
  };

  $scope.onEntityGroupFilter = (selectedOptions) => {
    $scope.getDataParams['entityGroupIds[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.props.hasSelectedGroups = !!selectedOptions.length;
    $scope.loadData();
  };

  $scope.onDateChange = (newDate) => {
    const weekStartDate = newDate.startOf('week').clone();
    $scope.getDataParams.weekStartDate = weekStartDate.format('YYYY-MM-DD');

    $scope.loadData();
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    if (when === 'lastWeek') {
      $scope.props.startDate = moment().startOf('week').subtract(1, 'week');
    } else if (when === 'thisWeek') {
      $scope.props.startDate = moment().startOf('week');
    } else if (when === 'nextWeek') {
      $scope.props.startDate = moment().startOf('week').add(1, 'week');
    }

    $scope.onDateChange($scope.props.startDate);
  };

  function recalculateDelta(row) {
    row.change = {
      wages: '',
      sales: '',
      hours: '',
    };

    // For entities check they have been signed off
    // If this is the 'total' row, don't check
    if (!row.hasActual) {
      return;
    }

    const data = $scope.props.comparand === 'budget' ? row.budget.total : row.forecast.total;
    const actual = row.actual.total;

    if (!data) {
      return;
    }

    if (data.wages > 0) {
      row.change.wages = actual.wages - data.wages;
    }

    if (data.sales > 0) {
      row.change.sales = actual.sales - data.sales;
    }

    if (data.hours > 0) {
      row.change.hours = actual.hours - data.hours;
    }
  }

  function recalculateDeltas() {
    $scope.props.data.byEntityGroup.forEach((group) => {
      group.byEntity.forEach(({ byType }) => recalculateDelta(byType));
      group.byCurrencyCode.forEach(({ byType }) => recalculateDelta(byType));
    });

    $scope.props.data.byCurrencyCode.forEach(({ byType }) => recalculateDelta(byType));
  }

  $scope.onComparandChange = (selectedOptions) => {
    $scope.props.comparand = selectedOptions[0];
    recalculateDeltas();
  };

  $scope.loadData = () => {
    if (!$scope.props.hasSelectedGroups) {
      return;
    }

    $scope.props.loadingData = true;

    const {
      getDataParams: {
        weekStartDate,
        'entityGroupIds[]': entityGroupIds,
      },
    } = $scope;

    $state.go('.', {
      week: weekStartDate,
      entityGroupIds: entityGroupIds && entityGroupIds.length ? entityGroupIds.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
      inherit: false,
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $http.get(`${ENDPOINT_API}/report/signOffProgress`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const {
          statusByEntity,
          financials,
        } = data;

        $scope.props.statusByEntity = statusByEntity;
        $scope.props.data = financials;

        recalculateDeltas();

        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.SIGN_OFF_PROGRESS.ERROR_500']);
        }
      });
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
    .then(({ data }) => {
      $scope.props.entityGroups = data
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
    });

  $scope.export = ($event, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.SIGN_OFF_PROGRESS.TITLE'],
      'exportTable', format);
  };

  $scope.viewMore = (row) => {
    const modal = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./statusModal'),
      resolve: {
        data: () => ({
          entity: row.entity,
          status: $scope.props.statusByEntity[row.entity.id],
          periodStart: $scope.props.startDate,
          periodEnd: $scope.props.startDate.clone().add(1, 'week'),
        }),
      },
    });

    modal.result.then((isChangesMade) => {
      if (!isChangesMade) {
        return;
      }

      $scope.loadData();
    });
  };

  $scope.loadData();
};
