const directives = require('./module');

directives.directive('percentage', () => ({
  restrict: 'E',
  replace: true,
  template: '<span ng-show="isVisible">{{integerPart}}<span class="percentage-fractional">{{fractionalPart}}%</span></span>',
  scope: {
    value: '=ngModel',
    decimalPlaces: '@',
  },
  link: ($scope) => {
    $scope.$watch('value', (newVal) => {
      const value = parseFloat(newVal);

      if (Number.isNaN(value)) {
        $scope.isVisible = false;
        return;
      }

      $scope.isVisible = true;

      let multiplier = 10; // default to 1dp
      const exponent = parseInt($scope.decimalPlaces, 10);

      if (!Number.isNaN(exponent)) {
        multiplier = 10 ** exponent;
      }

      const adjustedValue = Math.round((value * 100) * multiplier) / multiplier;
      const [integer, fractional] = adjustedValue.toString().split('.');

      $scope.integerPart = integer;
      $scope.fractionalPart = '';

      if (fractional && fractional !== '0') {
        $scope.fractionalPart = `.${fractional}`;
      }
    });
  },
}));
