const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/attendance/editEventModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/attendance/viewEventModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/rotamanagement/shift/viewModal.html');
const autoNgTemplateLoaderTemplate4 = require('/home/circleci/project/app/htdocs/views/absence/viewModal.html');

'use strict';
var controllers = require('../module');

controllers.controller('OverviewAttendanceCtrl', ['$scope', '$state', '$stateParams', '$uibModal', 'AttendanceService',
    'AlertService', 'SessionService', 'EnvironmentDataService', 'AttendanceCommon', '$translate',
    function ($scope, $state, $stateParams, $uibModal, AttendanceService, AlertService, SessionService, EnvironmentDataService,
              AttendanceCommon, $translate) {
        $scope.autoMatchThreshold = SessionService.getSetting('attendance.autoMatchThreshold') || 3;
        var dayBoundary = SessionService.getSetting('absence.oysterDayBoundary')
          ? parseInt(SessionService.getSetting('absence.oysterDayBoundary'), 10) : 4;
        var timelinessAlertThreshold = SessionService.getSetting('attendance.viewer.warnThreshld') || 45;

        var defaultEntityId = $stateParams.entity || SessionService.getEntity();

        let isFirstLoad = true;
        var translations = $translate.instant([
            'ATTENDANCE.OVERVIEW.OPTIONS_DAILY',
            'ATTENDANCE.OVERVIEW.OPTIONS_WEEKLY',
            'ATTENDANCE.OVERVIEW.ERROR_LOADING_ATTENDANCE',
            'ATTENDANCE.OVERVIEW.MIDNIGHT',
        ]);

        $scope.props = {
            entityId: defaultEntityId,
            loadingData: false,
            data: [],
            dayBoundaryOptions: [],
            defaultDayBoundaryOption: [dayBoundary],
            selectedDayBoundaryOption: dayBoundary,
            selectedDateStart: moment($stateParams.date || undefined),
            dateRangeOptions: [
                { id: 'day', label: translations['ATTENDANCE.OVERVIEW.OPTIONS_DAILY'] },
                { id: 'week', label: translations['ATTENDANCE.OVERVIEW.OPTIONS_WEEKLY'] },
            ],
        };

        function getDateRangeOptionById(id) {
            return $scope.props.dateRangeOptions.find(function (o) {
                return o.id === id;
            });
        }

        $scope.props.selectedDateRangeOption = getDateRangeOptionById($stateParams.view) || getDateRangeOptionById('day');

        for (var hour = 0; hour <= 9; hour += 1) {
            var label = hour === 0 ? translations['ATTENDANCE.OVERVIEW.MIDNIGHT'] : moment.utc().hour(hour).minute(0).second(0).format('Ha');

            $scope.props.dayBoundaryOptions.push({
                id: hour, label: label,
            });
        }

        function evaluateDateProperties() {
            $scope.props.selectedDateStart = $scope.props.selectedDateStart.clone().startOf($scope.props.selectedDateRangeOption.id);
            $scope.props.selectedDateEnd = $scope.props.selectedDateStart.clone().add(1, $scope.props.selectedDateRangeOption.id);

            var start = $scope.props.selectedDateStart.clone().startOf('day').hour($scope.props.selectedDayBoundaryOption);
            var end = $scope.props.selectedDateEnd.clone().startOf('day').hour($scope.props.selectedDayBoundaryOption);

            $scope.getDataParams.start = start.format();
            $scope.getDataParams.end = end.format();

            $scope.props.dateLabel = $scope.props.selectedDateStart.format('MMMM D');

            if ($scope.props.selectedDateRangeOption.id === 'week') {
                var inclusiveEnd = $scope.props.selectedDateEnd.clone().subtract(1, 'day');
                var format = $scope.props.selectedDateStart.month() === inclusiveEnd.month() ? 'D' : 'MMMM D';
                $scope.props.dateLabel += ' - ' + inclusiveEnd.format(format);
            }

            var entityId = $scope.getDataParams['entityIds[]'] ? $scope.getDataParams['entityIds[]'][0] : undefined;

            $state.go('.', {
                date: $scope.props.selectedDateStart.format('YYYY-MM-DD'),
                view: $scope.props.selectedDateRangeOption.id,
                entity: entityId,
            }, {
              notify: false,
              location: isFirstLoad ? true : 'replace',
            });

            if (isFirstLoad) {
              isFirstLoad = false;
            }
        }

        $scope.getDataParams = {
            'entityIds[]': [$scope.props.entityId],
            'currentPositionIds[]': [],
            sanitise: true,
        };

        $scope.onEntitySelect = function (entity) {
            $scope.getDataParams['entityIds[]'] = entity ? [entity.id] : undefined;
            $scope.loadData();
        };

        $scope.onPositionFilter = function (selectedOptions) {
            var option = selectedOptions[0];
            $scope.getDataParams['currentPositionIds[]'] = option || [];
            $scope.loadData();
        };

        $scope.onUserFilter = function (selectedUser) {
            $scope.getDataParams['userIds[]'] = selectedUser ? selectedUser.id : undefined;
            $scope.loadData();
        };

        $scope.onDayBoundaryChange = function (selectedOptions) {
            $scope.props.selectedDayBoundaryOption = selectedOptions[0];
            $scope.loadData();
        };

        $scope.onDateChange = function (newDate) {
            $scope.props.selectedDateStart = newDate;
            $scope.loadData();
        };

        $scope.onDateRangeChange = function (selectedOption) {
            $scope.props.selectedDateRangeOption = selectedOption;
            $scope.loadData();
        };

        EnvironmentDataService.fetchAll([
          EnvironmentDataService.DataType.EntityGroup,
          EnvironmentDataService.DataType.Position,
        ])
          .then(([
            entityGroup,
            position,
          ]) => {
            $scope.props.entities = entityGroup.data;
            $scope.props.positions = position.data.map((item) => ({
              ...item,
              label: item.name,
            }));

            $scope.loadData();
          });

        function getTimelinessAlert(diff) {
            var absDiff = Math.abs(diff);
            var minorThreshold = timelinessAlertThreshold / 2;
            var isEarly = diff < 0;

            var message = isEarly ? $translate.instant('ATTENDANCE.OVERVIEW.TIMELINESS_EARLY_TOOLTIP', {
                duration: absDiff,
            }) : $translate.instant('ATTENDANCE.OVERVIEW.TIMELINESS_LATE_TOOLTIP', {
                duration: absDiff,
            });

            var obj = {
                level: 0,
                message: message,
            };

            if (absDiff > timelinessAlertThreshold) {
                obj.level = 2;
            } else if (absDiff > minorThreshold) {
                obj.level = 1;
            }

            return obj;
        }

        function getScheduledPaidDuration(shift) {
            var duration = moment.duration();

            if (shift.paid) {
                duration.add(moment.duration(moment(shift.end).diff(shift.start)));

                if (shift.breakType === 2) {
                    duration.subtract(moment.duration(shift.breakValue));
                }
            }

            return +duration.asHours().toFixed(2);
        }

        function getTruncatedShiftType(shift) {
            return shift.typeName.length > 20 ? (shift.typeName.substring(0, 17) + '...') : shift.typeName;
        }

        $scope.loadData = function () {
            if ($scope.props.loadingData) {
                return;
            }

            $scope.props.loadingData = true;

            evaluateDateProperties();

            AttendanceService.getAutoMatchedEvents($scope.getDataParams)
                .success(function (data) {
                    var buckets = [];

                    for (var date = $scope.props.selectedDateStart.clone(); !date.isAfter($scope.props.selectedDateEnd); date.add(1, 'day')) {
                        buckets.push({
                            start: date.clone(),
                            end: date.clone().add(1, 'day'),
                            rows: [],
                        });
                    }

                    data.matched.forEach(function (row) {
                        var bucket = buckets.find(function (b) {
                            return !b.start.isAfter(row.scheduled.start) && b.end.isAfter(row.scheduled.start);
                        });

                        if (!bucket) {
                            return;
                        }

                        if (row.clockIn && !row.clockInOvertimeRequiresApproval && !row.clockIn.overtimeApprovalState) {
                            row.clockInAlert = getTimelinessAlert(row.clockInDiff);
                        }

                        if (row.clockOut && !row.clockOutOvertimeRequiresApproval && !row.clockOut.overtimeApprovalState) {
                            row.clockOutAlert = getTimelinessAlert(row.clockOutDiff);
                        }

                        if (row.clockIn && row.clockOut) {
                            row.attendanceDayDiff = moment(row.clockOut.date).startOf('day').diff(moment(row.clockIn.date).startOf('day'), 'days');
                        }

                        row.scheduledPaidHours = getScheduledPaidDuration(row.scheduled);
                        row.scheduled.typeNameTruncated = getTruncatedShiftType(row.scheduled);

                        bucket.rows.push(row);
                    });

                    $scope.props.data = {
                        matched: buckets,
                        matchedRowCount: data.matched.length,
                        unmatched: data.unmatched,
                        moreDataAvailable: data.moreDataAvailable,
                    };

                    $scope.props.loadingData = false;
                })
                .error(function (error, status) {
                    if (status === 500) {
                        AlertService.add('danger', translations['ATTENDANCE.OVERVIEW.ERROR_LOADING_ATTENDANCE']);
                    }
                });
        };

        $scope.$on('shiftUpdate', function () {
            $scope.loadData();
        });

        $scope.$on('attendanceUpdate', function () {
            $scope.loadData();
        });

        $scope.viewEvent = function (eventId, edit) {
            if (edit) {
                $uibModal.open({
                    templateUrl: autoNgTemplateLoaderTemplate1,
                    controller: 'EditEventAttendanceModalCtrl',
                    resolve: {
                        data: function () {
                            return {
                                id: eventId,
                            };
                        }
                    }
                });
            } else {
                $uibModal.open({
                    templateUrl: autoNgTemplateLoaderTemplate2,
                    controller: 'ViewEventAttendanceModalCtrl',
                    resolve: {
                        data: function () {
                            return {
                                id: eventId,
                            };
                        }
                    }
                });
            }
        };

        $scope.addAttendanceEvent = function (eventType, user, date, entityId, rollTimeForwards) {
            AttendanceCommon.openModalAddEvent(eventType, user, date, entityId, rollTimeForwards, function () {
                $scope.loadData();
            });
        };

        $scope.addEvent = function (user, date, entityId) {
            AttendanceCommon.openModalNoClockIn(user, date, entityId, function () {
                $scope.loadData();
            });
        };

        $scope.viewShift = function (id) {
            $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate3,
                controller: 'ViewShiftModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            id: id
                        };
                    }
                }
            });
        };

        $scope.viewAbsence = function (id) {
            $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate4,
                controller: require('../absence/viewModal'),
                resolve: {
                    data: function () {
                        return {
                            id: id,
                            launchedFromStaffModule: true,
                        };
                    }
                }
            });
        };

        $scope.resolveUnmatched = function (row) {
            var clockInNotes = row.clockIn && row.clockIn.notes || ''
            var clockOutNotes = row.clockOut && row.clockOut.notes || ''
            var userNotes = [clockInNotes, clockOutNotes].filter(Boolean)

            AttendanceCommon.openModalUnmatchedAttendance(row.clockIn.id, row.clockIn.date,
                row.clockOut ? row.clockOut.date : false, row.user, function () {
                    $scope.loadData();
                }, userNotes);
        };

        $scope.openOvertimeApprovalModal = function (row, isClockIn) {
            var attendanceEvent = isClockIn ? row.clockIn : row.clockOut;

            AttendanceCommon.openModalOvertimeApproval(isClockIn, attendanceEvent, row.scheduled, row.user, function () {
                $scope.loadData();
            }, getUserNotes(attendanceEvent));
        };
    }]);

function getUserNotes(attendanceEvent) {
    return [attendanceEvent.notes].filter(Boolean)
}
