const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/rotaTemplates/editConstraintsModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/rotaTemplates/editShiftSetModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/settings/rotaTemplates/editShiftSetModal.html');
const autoNgTemplateLoaderTemplate4 = require('/home/circleci/project/app/htdocs/views/settings/rotaTemplates/editConstraintsModal.html');
const autoNgTemplateLoaderTemplate5 = require('/home/circleci/project/app/htdocs/views/settings/rotaTemplates/fixedStaffingShiftSetModal.html');
const autoNgTemplateLoaderTemplate6 = require('/home/circleci/project/app/htdocs/views/settings/rotaTemplates/fixedStaffingShiftSetModal.html');

'use strict';
var controllers = require('../../module');

controllers.controller('EditTemplateRmCtrl', ['$scope', '$stateParams', '$timeout', '$debounce', '$uibModal', '$state', '$translate', 'TemplateService', 'AlertService', 'SessionService',
    function ($scope, $stateParams, $timeout, $debounce, $uibModal, $state, $translate, templateService, alertService, SessionService) {
        var textById = $translate.instant([
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.GLOBAL',
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.TEMPLATE',
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.WEEK',
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.GROUP',
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.TASK',
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.SHIFT_SET',
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.ERRORS.COULDNT_FIND_TEMPLATE',
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.ERRORS.OOPS',
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.CONFIRM.DELETE',
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.CONFIRM.DELETE_SHIFT_SET',
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.ERRORS.TEMPLATE_STATE_CHANGE',
        ]);

        var levelNames = [
            textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.GLOBAL'],
            textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.TEMPLATE'],
            textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.WEEK'],
            textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.GROUP'],
            textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.TASK'],
            textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.LEVEL_NAMES.SHIFT_SET']
        ];

        var dowNames = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

        $scope.dataLoaded = false;
        $scope.isMultiShift = true;

        templateService.getTemplate($stateParams.templateId)
            .success(function (data) {
                $scope.template = data.tree;
                $scope.weekNode = $scope.template.children[0];

                calculateHoursInGroups();
                $scope.dataLoaded = true;
            })
            .error(function () {
                alertService.add("danger", textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.ERRORS.COULDNT_FIND_TEMPLATE']);
                $state.go('^.index');
            });

        $scope.onEditGroupNameCancel = function (group) {
            group.isEditName = false;
        };

        $scope.editGroupName = function (group) {
            $scope.onEditGroupNameCancel(group);
            // todo loading indicator, if error, display to user and dont let them save
            // todo only push change to server if the user actually changed the text
            templateService.updateNode(group.node_id, {node_name: group.node_name});
        };

        $scope.editLeafInline = function (leaf, scope) {
            scope.saveActioning = true;

            var toSend = angular.copy(leaf);
            toSend.leaf_time_start = moment(toSend.leaf_time_start).format('HH:mm:ss');
            toSend.leaf_time_end = moment(toSend.leaf_time_end).format('HH:mm:ss');

            templateService.updateLeaf(leaf.leaf_id, toSend)
                .success(function () {
                    scope.isEditLeaf = false;
                    scope.saveActioning = false;

                    recalculateHoursForGroupNode(scope.node);
                })
                .error(function () {
                    scope.saveActioning = false;
                    alertService.add("danger", textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.ERRORS.OOPS']);
                });
        };

        $scope.deleteNode = function (parentNode, nodeId) {
            if (!confirm(textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.CONFIRM.DELETE']))
                return;

            templateService.deleteNode(nodeId)
                .success(function () {
                    // If they just deleted a week.. we need to change the active week
                    if (parentNode.node_depth === 0) {
                        $scope.weekNode = $scope.template.children[0];
                    }

                    angular.forEach(parentNode.children, function (node, idx) {
                        if (node.node_id === nodeId) {
                            parentNode.children.splice(idx, 1);
                        }
                    });

                    recalculateHoursForGroupNode(parentNode);
                })
                .error(function () {
                    alertService.add("danger", textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.ERRORS.OOPS']);
                });
        };

        $scope.deleteLeaf = function (parentNode, leafId) {
            if (!confirm(textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.CONFIRM.DELETE_SHIFT_SET']))
                return;

            templateService.deleteLeaf(leafId)
                .success(function () {
                    angular.forEach(parentNode.leaves, function (leaf, idx) {
                        if (leaf.leaf_id === leafId) {
                            parentNode.leaves.splice(idx, 1);
                        }
                    });

                    recalculateHoursForGroupNode(parentNode);
                })
                .error(function () {
                    alertService.add("danger", textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.ERRORS.OOPS']);
                });
        };

        $scope.addNode = function (parentNode) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'EditConstraintsModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            levelNames: levelNames,
                            flattenedConstraints: getFlattenedConstraints(parentNode, true),
                            nodeInfo: parentNode,
                            isAdd: true
                        }
                    }
                }
            });

            modalInstance.result.then(function (newNode) {
                if (!angular.isObject(newNode))
                    return;

                // Clear the filter if we've just added a group
                // So the user can see it appear at the top of page 1
                if (newNode.node_depth == 2) {
                    $scope.clearQuery();
                    parentNode.children.unshift(newNode);

                    newNode.node_duration = 0; // init to zero (the number of total hours in the group node)
                } else {
                    // Otherwise if it's a task, for example, just add it on the end
                    parentNode.children.push(newNode);
                }
            });
        };

        $scope.addLeaf = function (parentNode) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'EditShiftSetModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: true,
                            nodeId: parentNode.node_id,
                            flattenedConstraints: getFlattenedConstraints(parentNode, true),
                        }
                    }
                }
            });

            modalInstance.result.then(function (newLeaf) {
                if (!angular.isObject(newLeaf))
                    return;

                parentNode.leaves.push(newLeaf);
                recalculateHoursForGroupNode(parentNode);
            });
        };

        $scope.editLeaf = function (parentNode, leaf) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate3,
                controller: 'EditShiftSetModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: false,
                            leaf: leaf,
                            flattenedConstraints: getFlattenedConstraints(parentNode, true),
                        }
                    }
                }
            });

            modalInstance.result.then(function (updatedLeaf) {
                angular.forEach(parentNode.leaves, function (l, idx) {
                    if (l.leaf_id === updatedLeaf.leaf_id) {
                        parentNode.leaves[idx] = updatedLeaf;
                    }
                });

                recalculateHoursForGroupNode(parentNode);
            });
        };

        $scope.editConstraints = function (node) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate4,
                controller: 'EditConstraintsModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            levelNames: levelNames,
                            flattenedConstraints: getFlattenedConstraints(node, false),
                            nodeInfo: node,
                            isAdd: false
                        }
                    }
                }
            });

            modalInstance.result.then(function (updatedNode) {
                if (!angular.isObject(updatedNode))
                    return;

                node.node_name = updatedNode.node_name;
                node.constraints = updatedNode.constraints;
            });
        };

        $scope.onPageChange = function (newPageNumber) {
            $timeout(function() {
                $('body').animate({scrollTop: $('#templateEditor').offset().top}, "slow");
            }, 0, false);
        };

        $scope.$watch('searchInput', function(newValue, oldValue) {
            if (newValue === oldValue) { return; }
            $debounce(applyQuery, 350);
        });
        var applyQuery = function() {
            $scope.query = $scope.searchInput;
        };

        $scope.clearQuery = function() {
            $scope.query = '';
            $scope.searchInput = '';
        };

        function getFlattenedConstraints(node, includeThisLevel) {
            // Get an array of the hierarchy of constraints that apply at this point
            var track = [];

            var traverseTree = function (node, nodeId) {
                if (node.node_id === nodeId) {
                    track.push(node);
                    return true;
                }

                if (!node.children || node.children.length == 0) {
                    return false;
                }

                for (var i = 0; i < node.children.length; i++) {
                    if (traverseTree(node.children[i], nodeId)) {
                        track.push(node);
                        return true;
                    }
                }
            };

            // Get path from this node to the root node
            traverseTree($scope.template, node.node_id);

            // Add the global constraints to the end of the path
            // after the root node, acting like a fake node higher than the root
            track.push({
                constraints: $scope.template.globalConstraints,
                node_depth: -1,
                node_id: -1
            });

            var flattened = [];

            // Get a flattened set of constraints, by going through our path
            // taking the first of every constraint type we find
            for (var j = 0; j < track.length; j++) {
                // skip the first level if requested
                if (!includeThisLevel && j === 0)
                    continue;

                var nodeInChain = track[j];
                var constraints = track[j].constraints;

                for (var k = 0; k < constraints.length; k++) {
                    var constraint = constraints[k];

                    if (!(constraint.const_type in flattened)) {
                        flattened[constraint.const_type] = {node: nodeInChain, constraint: constraint};
                    }
                }
            }

            return flattened;
        }

        function recalculateHoursForGroupNode(node) {
            if (node.node_depth == 3) {
                calculateHoursInGroups(node.node_parentid);
            } else if (node.node_depth == 2) {
                calculateHoursInGroups(node.node_id);
            }
        }

        function calculateHoursInGroups(groupNodeId) {
            angular.forEach($scope.template.children, function (weekNode) {
                angular.forEach(weekNode.children, function (groupNode) {
                    // Optionally only recalculate for a specific groupNode
                    if (groupNodeId && groupNode.node_id !== groupNodeId) {
                        return;
                    }

                    var duration = moment.duration();

                    if (groupNode.children.length) {
                        angular.forEach(groupNode.children, function (subgroupNode) {
                            angular.forEach(subgroupNode.leaves, function (leaf) {
                                addLeafToDuration(leaf, duration);
                            });
                        });

                        groupNode.node_duration = +parseFloat(duration.asHours()).toFixed(2);
                    } else {
                        angular.forEach(groupNode.leaves, function (leaf) {
                            addLeafToDuration(leaf, duration);
                        });

                        groupNode.node_duration = +parseFloat(duration.asHours()).toFixed(2);
                    }
                });
            });
        }

        function addLeafToDuration(leaf, duration) {
            var startDate = moment(leaf.leaf_time_start);
            var endDate = moment(leaf.leaf_time_end);

            if (startDate.isAfter(endDate)) {
                endDate.add(1, 'day');
            }

            var diff = endDate.diff(startDate);
            var multiplier = 0;

            for (var i = 0; i < 7; i++) {
                multiplier += leaf['leaf_num_' + dowNames[i]];
            }

            duration.add(diff * multiplier);
        }

        $scope.filterGroupNodes = function (byName, showFixedStaffingOnly) {
            return function (groupNode) {
                if (byName && byName.length > 1) {
                    var n = groupNode.node_name.search(new RegExp(byName, "i"));

                    if (n == -1) {
                        return false;
                    }
                }

                var isFixedStaffing = false;

                groupNode.constraints.forEach(function (c) {
                    if (c.const_type === 1 && c.const_var2 == 4) {
                        isFixedStaffing = true;
                    }
                });

                return showFixedStaffingOnly == isFixedStaffing;
            };
        };

        $scope.addFixedStaffingShiftSet = function (groupNode) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate5,
                controller: 'FixedStaffingShiftSetModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: true,
                            node: groupNode
                        }
                    }
                }
            });

            modalInstance.result.then(function (newChild) {
                if (!angular.isObject(newChild))
                    return;

                groupNode.children.push(newChild);
                recalculateHoursForGroupNode(groupNode);
            });
        };

        $scope.editFixedStaffingShiftSet = function (subgroupNode, leaf) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate6,
                controller: 'FixedStaffingShiftSetModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: false,
                            leaf: leaf,
                            node: subgroupNode,
                        }
                    }
                }
            });

            modalInstance.result.then(function (updatedData) {
                var leaf = updatedData.leaf;

                angular.forEach(subgroupNode.leaves, function (l, idx) {
                    if (l.leaf_id === leaf.leaf_id) {
                        subgroupNode.leaves[idx] = leaf;
                    }
                });

                subgroupNode.node_name = updatedData.nodeName;

                recalculateHoursForGroupNode(subgroupNode);
            });
        };

        $scope.toggleActive = function (node) {
            var newState = !node.node_isinactive;
            node.node_isinactive = 2; // loading state

            templateService.updateNode(node.node_id, {node_isinactive: newState})
                .success(function () {
                    node.node_isinactive = newState;

                    // Collapse/show the group to make the new state obvious to the user
                    node.isCollapsed = newState;
                })
                .error(function (error, status) {
                    node.node_isinactive = !newState;

                    if (status == 500) {
                        alertService.add("danger", textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_TEMPLATE.ERRORS.TEMPLATE_STATE_CHANGE']);
                    }
                });
        };

        $scope.toggleNodeDisplay = function (node, $event) {
            var target = angular.element($event.target);
            if (target.hasClass('collapse-target')) {
                node.isCollapsed = !node.isCollapsed;
            }
        };
    }]);
