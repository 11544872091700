var controllers = require('../../module');

controllers.controller('BudgetingEditPeriodModalCtrl', ['$scope', '$uibModalInstance', 'SettingsService', 'AlertService', 'data',
    function ($scope, $uibModalInstance, SettingsService, AlertService, data) {
        $scope.dataLoaded = true;

        $scope.formData = {
            weekCount: 1,
            namePrefix: moment().format('YYYY')
        };

        // Date picker
        $scope.datePickerDate = moment().add(1, 'week').startOf('week');

        $scope.updateDates = function (newDate) {
            var date = newDate || $scope.datePickerDate;
            date.startOf('week');

            $scope.formData.startDate = date.clone();
            $scope.formData.endDate = date.clone().add($scope.formData.weekCount, 'weeks');
            $scope.formData.namePrefix = date.format('YYYY');
        };
        $scope.updateDates();

        $scope.add = function () {
            if (!$scope.formData.name) {
                return alert("Please provide a name that'll help you identify this budget period in the future.");
            }

            $scope.addActioning = true;

            var fields = {
                entityId: data.entityId,
                startDate: $scope.formData.startDate.format('YYYY-MM-DD'),
                weekCount: $scope.formData.weekCount,
                name: $scope.formData.namePrefix + ', ' + $scope.formData.name
            };

            SettingsService.addBudgetPeriod(fields)
                .success(function (result) {
                    $uibModalInstance.close(result);
                })
                .error(function (error, status) {
                    $scope.addActioning = false;

                    if (status == 500) {
                        alert('Oops, it seems we were unable to save just then. Please try again.');
                    }

                    if (status == 400) {
                        alert('Oops, we couldn\'t save the budget just then. Check it doesn\'t overlap with an existing budget.');
                    }
                });
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);
