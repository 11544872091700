const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/requests/confirmSwapApprovalModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/rotamanagement/shift/viewModal.html');

'use strict';
var controllers = require('../../module');

controllers.controller('SwapRequestsStaffCtrl', ['$scope', '$state', '$uibModal', 'RotaService', 'AlertService', 'EnvironmentDataService', 'SessionService', 'NotificationService',
    function ($scope, $state, $uibModal, RotaService, AlertService, EnvironmentDataService, SessionService, NotificationService) {
        $scope.props = {
            entityId: SessionService.getEntity(),
            loadingData: false,
            noMoreData: false,
            requests: [],
        };

        $scope.getSwapsParams = {
            'workEntityIds[]': [$scope.props.entityId],
            'statusCodes[]': [1],
            limit: 20,
            page: 1,
            sortOrder: 'acceptedDateAsc',
        };

        $scope.onEntitySelect = function (entity) {
            $scope.getSwapsParams['workEntityIds[]'] = entity ? [entity.id] : undefined;
            $scope.loadRequests(true);
        };

        $scope.onUserFilter = function (selectedUser) {
            $scope.getSwapsParams.participantUserId = selectedUser ? selectedUser.id : undefined;
            $scope.loadRequests(true);
        };

      EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
        .then(({ data }) => {
          $scope.props.entities = data;
        });

        $scope.loadRequests = function (reset) {
            if ($scope.props.loadingData) {
                return;
            }

            $scope.props.loadingData = true;

            if (reset) {
                $scope.getSwapsParams.page = 1;
                $scope.props.noMoreData = false;
                $scope.props.requests = [];
            }

            RotaService.getSwapRequests($scope.getSwapsParams)
                .success(function (data) {
                    data.swaps.forEach(function (r) {
                        r.swap.createdDateFromNow = moment(r.swap.createdDate).fromNow();
                        r.swap.acceptedDateFromNow = moment(r.swap.acceptedDate).fromNow();

                        $scope.props.requests.push(r);
                    });

                    $scope.getSwapsParams.page += 1;

                    if (data.swaps.length < $scope.getSwapsParams.limit) {
                        $scope.props.noMoreData = true;
                    }

                    $scope.props.loadingData = false;
                })
                .error(function (error, status) {
                    if (status === 500) {
                        AlertService.add('danger', 'We weren\'t able to load swaps just then. Please try again.');
                        $state.go('staff.overview');
                    }
                });
        };

        function removeRequest(id) {
            angular.forEach($scope.props.requests, function (r, key) {
                if (r.swap.id === id) {
                    $scope.props.requests.splice(key, 1);
                }
            });

            NotificationService.dismiss(NotificationService.types.ROTA_SHIFT_SWAP);
        }

        $scope.approveRequest = function (id) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'ApproveSwapRequestStaffModalCtrl',
                resolve: {
                    data: function() {
                        return {
                            offerId: id
                        };
                    }
                }
            });

            modalInstance.result.then(function () {
                removeRequest(id);
            });
        };

        $scope.rejectRequest = function (id) {
            if (!confirm('Are you sure you want to reject this shift swap?')) {
                return;
            }

            $scope.saveActioning = true;

            RotaService.rejectSwapRequest(id)
                .success(function() {
                    AlertService.add("success", "Shift swap request successfully rejected!");
                    $scope.saveActioning = false;

                    removeRequest(id);
                })
                .error(function() {
                    $scope.saveActioning = false;
                });
        };

        $scope.viewShift = function (id) {
            $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'ViewShiftModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            id: id
                        };
                    }
                }
            });
        };
    }
]);
