require('./availability/overview');
require('./availability/editPatternExceptionsModal');

require('./availability/absence');

require('./availability/availability');
require('./availability/weeklyAvailabilityModal');
require('./availability/weeklyAvailabilitySingleModal');
require('./availability/monthlyAvailabilityModal');
require('./availability/dailyAvailabilityModal');

require('./rotamanagement/dashboard');
require('./rotamanagement/dashboard/wtdViolationsModal');

require('./rotamanagement/shift/editModal');
require('./rotamanagement/shift/viewModal');

require('./staff');

require('./staff/view/pay/viewTronc');
require('./staff/view/pay/addTroncModal');

require('./staff/view/shiftRoutines');
require('./staff/view/editRoutineMappingModal');
require('./staff/view/addRoutineMappingModal');
require('./staff/view/deleteRoutineMappingModal');

require('./staff/view/absence');
require('./staff/view/attendance');

require('./staff/requests');
require('./staff/requests/absence');
require('./staff/requests/swaps');
require('./staff/requests/confirmSwapApprovalModal');
require('./staff/requests/documents');
require('./staff/requests/newJoiners');

require('./staff/absence/absence');

require('./other/editPreferencesModal');
require('./other/manageSubscriptionsModal');
require('./other/releaseRefreshModal');

require('./attendance/overview');
require('./attendance/addEventModal');
require('./attendance/editEventModal');
require('./attendance/viewEventModal');
require('./attendance/issues');

require('./dialog/twoButtonDialog');

require('./dashboard');

require('./messaging/messagingPopover');

require('./settings/rotaTemplates');
require('./settings/rotaTemplates/editTemplate');
require('./settings/rotaTemplates/editConstraintsModal');
require('./settings/rotaTemplates/editShiftSetModal');
require('./settings/rotaTemplates/fixedStaffingShiftSetModal');
require('./settings/rotaTemplates/newTemplateModal');
require('./settings/shiftLocations');
require('./settings/shiftLocations/addLocationModal');
require('./settings/shiftLocations/editLocationModal');
require('./settings/shiftTypes');
require('./settings/shiftTypes/editShiftTypeModal');
require('./settings/tagsGroups');
require('./settings/tagsGroups/viewTagModal');
require('./settings/tagsGroups/viewGroupModal');
require('./settings/payElements');
require('./settings/payElements/editTypeModal');
require('./settings/restBreaks');
require('./settings/shiftRoutines');
require('./settings/shiftRoutines/routineShiftModal');
require('./settings/payRules');
require('./settings/payRules/editPayRateModal');
require('./settings/payRules/editRuleGroupModal');
require('./settings/payRules/editRuleModal');
require('./settings/permissions');
require('./settings/permissions/editPermissionLevelModal');
require('./settings/permissions/deletePermissionLevelModal');
require('./settings/budgeting');
require('./settings/budgeting/editPeriodModal');
require('./settings/budgeting/editWeekModal');
require('./settings/budgeting/byPeriod');
require('./settings/budgeting/byEntity');
require('./settings/integrations/sevenRooms');
require('./settings/integrations/flow');
require('./settings/integrations/xero');
require('./settings/integrations/lightspeedKSeries');
require('./settings/index');

require('./reports/xero/payRun');
require('./reports/xero/payRuns');
