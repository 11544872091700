'use strict';
var controllers = require('../../module');

controllers.controller('EditShiftSetModalCtrl', ['$scope', '$uibModalInstance', '$translate', 'TemplateService', 'CommonService', 'SessionService', 'data',
    function ($scope, $uibModalInstance, $translate, TemplateService, CommonService, SessionService, data) {
        $scope.dataLoaded = false;
        $scope.isAdd = data.isAdd;

        var textById = $translate.instant([
            'ROTA_MANAGEMENT.TEMPLATES.EDIT_SHIFT_SET_MODAL.ERRORS.UNABLE_TO_ADD',
        ]);

        if (data.isAdd) {
            $scope.formData = {
                leaf_time_start: moment().startOf('day').add({
                    hours: 9, minutes: 0
                }),
                leaf_time_end: moment().startOf('day').add({
                    hours: 17, minutes: 0
                }),
                leaf_num_mon: 0,
                leaf_num_tue: 0,
                leaf_num_wed: 0,
                leaf_num_thu: 0,
                leaf_num_fri: 0,
                leaf_num_sat: 0,
                leaf_num_sun: 0,
                leaf_num_type: 0,
                node_id: data.nodeId
            };
        } else {
            $scope.formData = angular.copy(data.leaf);
            $scope.formData.leaf_time_start = moment($scope.formData.leaf_time_start);
            $scope.formData.leaf_time_end = moment($scope.formData.leaf_time_end);
        }

        $scope.showDayOverflowWarning = false;

        function getWorkEntity() {
            var workEntityConstraintType = 17;

            var item = data.flattenedConstraints.find(function (item) {
                if (!item) {
                    return;
                }

                return item.constraint.const_type === workEntityConstraintType;
            });

            if (!item) {
                return SessionService.getEntity();
            }

            return item.constraint.const_var1;
        }

        function checkForDayOverflow() {
            $scope.showDayOverflowWarning = $scope.formData.leaf_time_end < $scope.formData.leaf_time_start;
        }

        $scope.$watch('formData.leaf_time_start', function() {
            checkForDayOverflow();
        });
        $scope.$watch('formData.leaf_time_end', function() {
            checkForDayOverflow();
        });

        CommonService.getMetadata(['shiftLocations'], [getWorkEntity()], true)
            .success(function (metadata) {
                $scope.metadata = metadata;
                $scope.dataLoaded = true;
            });

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.saveShiftSet = function () {
            $scope.saveActioning = true;

            var toSend = angular.copy($scope.formData);
            toSend.leaf_time_start = moment(toSend.leaf_time_start).format('HH:mm:ss');
            toSend.leaf_time_end = moment(toSend.leaf_time_end).format('HH:mm:ss');

            if (data.isAdd) {
                TemplateService.addLeaf(toSend)
                    .success(function (newLeafData) {
                        $uibModalInstance.close(newLeafData);
                    })
                    .error(function (error) {
                        $scope.saveActioning = false;
                        alert(textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_SHIFT_SET_MODAL.ERRORS.UNABLE_TO_ADD']);
                    });
            } else {
                TemplateService.updateLeaf(data.leaf.leaf_id, toSend)
                    .success(function (updatedLeafData) {
                        $uibModalInstance.close(updatedLeafData);
                    })
                    .error(function (error) {
                        $scope.saveActioning = false;
                        alert(textById['ROTA_MANAGEMENT.TEMPLATES.EDIT_SHIFT_SET_MODAL.ERRORS.UNABLE_TO_ADD']);
                    });
            }
        };
    }]);
