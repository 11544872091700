const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/pay/payForm.html');

module.exports = (
  $rootScope,
  $scope,
  $state,
  $stateParams,
  $translate,
  $uibModalDrawer,
  AlertService,
  StaffService,
  StaffCommon,
  EnvironmentDataService,
) => {
  'ngInject';

  const { userId } = $scope;
  const { payId: highlightPayId } = $stateParams;

  const translations = $translate.instant([
    'STAFF.VIEW.PAY.VIEW_HISTORY.FILTER_TYPES_SALARY',
    'STAFF.VIEW.PAY.VIEW_HISTORY.FILTER_TYPES_POINT_IN_TIME',
    'STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_500',
    'STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_403',
    'STAFF.VIEW.PAY.VIEW_HISTORY.CONFIRM_DELETE',
  ]);

  $scope.props = {
    loadingData: false,
    data: [],
    flavourFilterOptions: [
      { id: 'RATE_PER_UNIT', label: translations['STAFF.VIEW.PAY.VIEW_HISTORY.FILTER_TYPES_SALARY'] },
      { id: 'FIXED_AMOUNT', label: translations['STAFF.VIEW.PAY.VIEW_HISTORY.FILTER_TYPES_POINT_IN_TIME'] },
    ],
    defaultFlavourFilterOptions: ['RATE_PER_UNIT', 'FIXED_AMOUNT'],
    highlightPayId: highlightPayId ? parseInt(highlightPayId, 10) : undefined,
  };

  $scope.filterParams = {
    flavours: $scope.props.defaultFlavourFilterOptions,
    elementTypes: [],
  };

  $scope.onFlavourFilter = (selectedOptions) => {
    $scope.filterParams.flavours = selectedOptions;
  };

  $scope.onElementTypeFilter = (selectedOptions) => {
    const option = selectedOptions[0];
    $scope.filterParams.elementTypes = option ? [option] : [];
  };

  $scope.recordFilter = ({ elementType }) => {
    const { id, variant } = elementType;

    if ($scope.filterParams.flavours.length
      && !$scope.filterParams.flavours.includes(variant)) {
      return false;
    }

    if ($scope.filterParams.elementTypes.length
      && !$scope.filterParams.elementTypes.includes(id)) {
      return false;
    }

    return true;
  };

  function onApiError(status, data) {
    if (status === 400) {
      StaffCommon.onEmploymentValidationResponse(data.validationResult);
      return;
    }

    if (status === 500) {
      AlertService.add('danger', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_500']);
    }

    if (status === 403) {
      AlertService.add('warning', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_403']);
    }

    $state.go('staff.overview');
  }

  $scope.loadData = () => {
    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.loadingData = true;

    StaffService.getPay($scope.userId)
      .then(({ data }) => {
        $scope.props.data = data.payHistory.map((record) => {
          const {
            dateStart,
            dateEnd,
            openEnded,
            amountType,
            elementType: {
              variant,
            },
            lastEdited,
          } = record;

          const active = moment(dateStart).isSameOrBefore()
            && (openEnded || moment(dateEnd).isAfter());

          const amountTypeObject = StaffService.getPayAmountTypes()
            .find((t) => t.id === amountType);

          let chronoType;

          if (variant === 'RATE_PER_UNIT') {
            if (active) {
              chronoType = 1;
            } else {
              chronoType = moment(dateStart).isAfter() ? 2 : 3;
            }
          }

          return {
            ...record,
            active,
            amountTypeLabel: amountTypeObject ? amountTypeObject.title : '',
            chronoType,
            lastEditedTooltip: $translate.instant('STAFF.VIEW.PAY.VIEW_HISTORY.LAST_EDITED_TOOLTIP', {
              userName: lastEdited.user ? `${lastEdited.user.firstName} ${lastEdited.user.lastName}` : 'Rotaready',
              date: moment(lastEdited.date).format('lll'),
            }),
          };
        }).reverse();

        $scope.props.loadingData = false;
      })
      .catch(({ status, data }) => {
        $scope.props.loadingData = false;
        onApiError(status, data);
      });
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.PayElementType,
  ])
    .then(([
      payElementType,
    ]) => {
      $scope.props.payElementTypes = payElementType.data
        .filter(({ variant }) => ['RATE_PER_UNIT', 'FIXED_AMOUNT'].includes(variant))
        .map(({ id, name, isDeleted }) => ({ id, label: name, deleted: isDeleted }));

      $scope.loadData();
    });

  $scope.editPayRecord = (pay) => {
    const drawerInstance = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./payForm'),
      resolve: {
        data: () => ({
          userId,
          userCurrentAppointmentDateStart: $scope.userData.appointment.dateStart,
          isAdd: !pay,
          pay,
          payRecordCount: $scope.props.data.length,
        }),
      },
    });

    drawerInstance.result.then(() => {
      $rootScope.$broadcast('user:update');
      $scope.loadData();
    });
  };

  $scope.delete = (row) => {
    if (!confirm(translations['STAFF.VIEW.PAY.VIEW_HISTORY.CONFIRM_DELETE'])) {
      return;
    }

    row.isDeleting = true;

    StaffService.deletePay(row.id, $scope.userId)
      .then(() => {
        $scope.props.data.forEach((p, idx) => {
          if (p.id === row.id) {
            $scope.props.data.splice(idx, 1);
          }
        });
      })
      .catch(({ status, data }) => {
        row.isDeleting = false;
        onApiError(status, data);
      });
  };
};
