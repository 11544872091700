const directives = require('./module');

directives.directive('rrMonetary', (iso4217) => ({
  restrict: 'E',
  replace: true,
  template: '<span class="exportable-value" data-export-value="{{round(value, props.fractionSize)}}">{{value|number:props.fractionSize}}<span ng-if="props.showCurrencyCode"> {{currencyCode}}</span></span>',
  scope: {
    value: '=ngValue',
    currencyCode: '=',
    fractionSize: '@?',
    showCurrencyCode: '@?',
  },
  link: ($scope) => {
    const isFractionSizeOverriden = angular.isDefined($scope.fractionSize);

    $scope.props = {
      showCurrencyCode: $scope.showCurrencyCode !== 'false',
      fractionSize: $scope.fractionSize || 0,
    };

    // We can't use angular's number filter in our template as it
    // inserts thousand separators which cannot be turned off.
    $scope.round = (number, fractionSize) => {
      const component = 10 ** fractionSize;
      return Math.round(number * component) / component;
    };

    $scope.$watch('currencyCode', (val) => {
      if (!val) {
        return;
      }

      const currency = iso4217.getCurrencyByCode($scope.currencyCode);

      if (currency && !isFractionSizeOverriden) {
        $scope.props.fractionSize = currency.fraction;
      }
    });
  },
}));
