const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/trustedDevices/newDeviceModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/trustedDevices/editDeviceModal.html');

module.exports = (
  $scope,
  $uibModal,
  $state,
  $translate,
  $window,
  AlertService,
  AttendanceService,
  EnvironmentDataService,
  AuthService,
  SessionService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'SETTINGS.TRUSTED_DEVICES.ALERT_CONFIRM_TRUST',
    'SETTINGS.TRUSTED_DEVICES.ALERT_CONFIRM_UNTRUST',
    'SETTINGS.TRUSTED_DEVICES.ERROR_500',
    'SETTINGS.TRUSTED_DEVICES.ERROR_403',
    'SETTINGS.TRUSTED_DEVICES.STATUS_PENDING',
    'SETTINGS.TRUSTED_DEVICES.STATUS_TRUSTED',
    'SETTINGS.TRUSTED_DEVICES.STATUS_UNTRUSTED',
  ]);

  function getLabelForStatus(status) {
    switch (status) {
      default:
      case 0:
        return translations['SETTINGS.TRUSTED_DEVICES.STATUS_PENDING'];
      case 1:
        return translations['SETTINGS.TRUSTED_DEVICES.STATUS_TRUSTED'];
      case 2:
        return translations['SETTINGS.TRUSTED_DEVICES.STATUS_UNTRUSTED'];
    }
  }

  $scope.props = {
    loadingData: true,
    data: [],
    entityGroupById: {},
    entityById: {},
    statuses: [
      {
        id: 0,
        label: getLabelForStatus(0),
      },
      {
        id: 1,
        label: getLabelForStatus(1),
      },
      {
        id: 2,
        label: getLabelForStatus(2),
      },
    ],
    defaultStatuses: [],
    defaultEntityIds: [],
    getDataParams: {},
  };

  function onApiError(status) {
    $scope.props.loadingData = false;

    if (status === 500) {
      AlertService.add('danger', translations['SETTINGS.TRUSTED_DEVICES.ERROR_500']);
      return;
    }

    if (status === 403) {
      AlertService.add('warning', translations['SETTINGS.TRUSTED_DEVICES.ERROR_403']);
      return;
    }

    $state.go('settings.index');
  }

  $scope.loadData = () => {
    $scope.props.loadingData = true;

    AttendanceService.getTrustedDevices($scope.props.getDataParams)
      .then(({ data }) => {
        $scope.props.data = data.results
          .filter((row) => $scope.props.entityById[row.entityId] !== undefined)
          .map((row) => ({
            ...row,
            lastEventAgo: moment(row.lastActivityDate).fromNow(),
            entity: row.entityId !== undefined
              ? $scope.props.entityById[row.entityId] : null,
            entityGroup: row.entityId !== undefined
              ? $scope.props.entityGroupById[$scope.props.entityById[row.entityId].groupId] : null,
            statusLabel: getLabelForStatus(row.status),
          })).reverse();

        $scope.props.loadingData = false;
      })
      .catch(({ status }) => onApiError(status));
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
    .then(({ data }) => {
      data.forEach((entityGroup) => {
        $scope.props.entityGroupById[entityGroup.id] = entityGroup;

        entityGroup.entities.forEach((entity) => {
          $scope.props.entityById[entity.id] = entity;
        });
      });

      $scope.props.groupedEntities = data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => ({
            id: entity.id,
            label: entity.name,
            parentId: entity.groupId,
            depth: 1,
          })),
        ]));

      $scope.loadData();
    });

  $scope.onStatusFilter = (selectedOptions) => {
    $scope.props.getDataParams['statuses[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData();
  };

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.props.getDataParams['mappedEntityIds[]'] = entityIds.length ? entityIds : undefined;
    $scope.loadData();
  };

  $scope.update = (device, status) => {
    if (device.actionInProgress) {
      return;
    }

    const messageKey = status === 1
      ? 'SETTINGS.TRUSTED_DEVICES.ALERT_CONFIRM_TRUST'
      : 'SETTINGS.TRUSTED_DEVICES.ALERT_CONFIRM_UNTRUST';

    if (!$window.confirm(translations[messageKey])) {
      return;
    }

    device.actionInProgress = true;

    AttendanceService.updateTrustedDevice(device.id, { status })
      .then(() => {
        device.status = status;
        device.statusLabel = getLabelForStatus(status);
        device.actionInProgress = false;
      })
      .catch(({ status }) => onApiError(status));
  };

  $scope.newDevice = () => {
    const modalInstance = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: ($scope, $uibModalInstance, data) => {
        $scope.props = {
          ...data,
        };

        $scope.submit = () => {
          $uibModalInstance.close(true);
        };

        $scope.close = () => {
          $uibModalInstance.dismiss();
        };
      },
      resolve: {
        data: () => ({
          realmId: AuthService.getRealm(),
          entityId: SessionService.getEntity(),
        }),
      },
    });

    modalInstance.result.then(() => $scope.loadData());
  };

  $scope.edit = (device) => {
    const modalInstance = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate2,
      controller: require('./editDeviceModal'),
      resolve: {
        data: () => ({
          device,
        }),
      },
    });

    modalInstance.result.then((updated) => {
      if (updated) {
        $scope.loadData();
      }
    });
  };
};
