const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/attendance/viewEventModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/attendance/addEventModal.html');

'use strict';
var controllers = require('../../../module');

controllers.controller('StaffAttendanceCtrl', ['$scope', '$state', '$stateParams', '$uibModal', '$timeout',
    'AttendanceService', 'AttendanceCommon', 'StaffService', 'SessionService', 'AlertService', '$translate',
    function ($scope, $state, $stateParams, $uibModal, $timeout, AttendanceService, AttendanceCommon, StaffService,
              SessionService, AlertService, $translate) {
        var labelTranslations = $translate.instant([
            'ATTENDANCE.BY_STAFF.CLOCK_IN',
            'ATTENDANCE.BY_STAFF.CLOCK_OUT',
            'ATTENDANCE.BY_STAFF.BREAK_ON',
            'ATTENDANCE.BY_STAFF.BREAK_OFF',
            'ATTENDANCE.BY_STAFF.ERROR_LOADING_EVENTS',
            'ATTENDANCE.BY_STAFF.ERROR_DELETING',
            'ATTENDANCE.BY_STAFF.DELETE_SUCCESS',
            'ATTENDANCE.BY_STAFF.ADD_SUCCESS',
        ]);

        $scope.eventTypes = {
            1: labelTranslations['ATTENDANCE.BY_STAFF.CLOCK_IN'],
            2: labelTranslations['ATTENDANCE.BY_STAFF.CLOCK_OUT'],
            3: labelTranslations['ATTENDANCE.BY_STAFF.BREAK_ON'],
            4: labelTranslations['ATTENDANCE.BY_STAFF.BREAK_OFF'],
        };

        $scope.props = {
            loadingData: false,
            noMoreData: false,
            data: [],
            highlightEvent: $stateParams.event ? parseInt($stateParams.event, 10) : undefined,
            highlightAnimationComplete: false,
        };

        $scope.getDataParams = {
            userId: $scope.userId,
            limit: 50,
            page: 1,
            sortOrder: 'eventDateDesc',
        };

        $scope.onDateFilter = function (state) {
            var option = state.option;

            if (option === 1) {
                $scope.getDataParams.start = undefined;
                $scope.getDataParams.end = undefined;
                $scope.getDataParams.sortOrder = 'eventDateDesc';
            } else {
                var start = state.dateStart;
                var end = state.dateEnd;

                if (option === 2) {
                    $scope.getDataParams.start = undefined;
                    $scope.getDataParams.end = start.format();
                    $scope.getDataParams.sortOrder = 'eventDateDesc';
                } else if (option === 3) {
                    $scope.getDataParams.start = start.clone().add(1, 'day').format();
                    $scope.getDataParams.end = undefined;
                    $scope.getDataParams.sortOrder = 'eventDateAsc';
                } else if (option === 4) {
                    $scope.getDataParams.start = start.format();
                    $scope.getDataParams.end = end.clone().add(1, 'day').format();
                    $scope.getDataParams.sortOrder = 'eventDateAsc';
                }
            }

            $scope.loadData(true);
        };

        $scope.loadData = function (reset) {
            if ($scope.props.loadingData) {
                return;
            }

            $scope.props.loadingData = true;

            if (reset) {
                $scope.getDataParams.page = 1;
                $scope.props.noMoreData = false;
                $scope.props.data = [];
            }

            $scope.props.arrowDirection = $scope.getDataParams.sortOrder === 'eventDateAsc' ? 'down' : 'up';

            AttendanceService.getEvents($scope.getDataParams)
                .success(function (data) {
                    data.events.forEach(function (r) {
                        $scope.props.data.push(r);
                    });

                    $scope.getDataParams.page += 1;

                    if (data.events.length < $scope.getDataParams.limit) {
                        $scope.props.noMoreData = true;
                    }

                    if ($scope.props.highlightEvent && !$scope.props.highlightAnimationComplete) {
                        $timeout(function () {
                            $('html,body').animate({scrollTop: $('#event' + $scope.props.highlightEvent).offset().top - 100}, 'slow');
                            $scope.props.highlightAnimationComplete = true;
                        }, 0, false);
                    }

                    $scope.props.loadingData = false;
                })
                .error(function (error, status) {
                    if (status === 500) {
                        AlertService.add('danger', labelTranslations['ATTENDANCE.BY_STAFF.ERROR_LOADING_EVENTS']);
                    }
                });
        };

        var sortEvents = function (lhs, rhs) {
            var value;

            if (lhs.date > rhs.date) {
                value = 1;
            } else if (lhs.date < rhs.date) {
                value = -1;
            } else {
                value = lhs.eventType > rhs.eventType ? 1 : lhs.eventType < rhs.eventType ? -1 : 0;
            }

            return $scope.getDataParams.sortOrder === 'eventDateAsc' ? value : value * -1;
        };

        $scope.deleteEvent = function (event) {
            if (!confirm('Are you sure you wish to delete this event?\nIf it\'s a clock-in, the corresponding clock-out will also be removed.')) {
                return;
            }

            event.isDeleting = true;

            AttendanceService.deleteEvent(event.id)
                .success(function (result) {
                    $scope.props.data = $scope.props.data.filter(function (event) {
                        return result.eventIds.indexOf(event.id) === -1;
                    });

                    AlertService.add('success', labelTranslations['ATTENDANCE.BY_STAFF.DELETE_SUCCESS']);
                })
                .error(function (error, status) {
                    event.isDeleting = false;

                    if (status === 400) {
                        AttendanceCommon.displayValidationError(error);
                    }

                    if (status === 500) {
                        AlertService.add('danger', labelTranslations['ATTENDANCE.BY_STAFF.ERROR_DELETING']);
                    }
                });
        };

        $scope.$on('attendanceUpdate', function (event, payload) {
            var operation = payload.operation;

            if (operation === 1) {
                payload.events.forEach(function (update) {
                    $scope.props.data.forEach(function (event, index) {
                        if (event.id === update.id) {
                            $scope.props.data[index] = update;
                        }
                    });
                });
            } else if (operation === 2) {
                $scope.props.data = $scope.props.data.filter(function (event) {
                    return payload.eventIds.indexOf(event.id) === -1;
                });
            }

            $scope.props.data.sort(sortEvents);
        });

        $scope.viewEvent = function (eventId) {
            $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'ViewEventAttendanceModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            id: eventId,
                        };
                    }
                }
            });
        };

        $scope.addEvent = function (index) {
            var precedingEvent;

            if (index >= 0) {
                if ($scope.getDataParams.sortOrder === 'eventDateAsc') {
                    precedingEvent = $scope.props.data[index - 1];
                } else {
                    precedingEvent = $scope.props.data[index];
                }
            }

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'AddEventAttendanceModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            dateOffset: precedingEvent ? moment(precedingEvent.date) : undefined,
                            userId: $scope.userId,
                        };
                    }
                }
            });

            modalInstance.result.then(function (result) {
                result.events.forEach(function (event) {
                    $scope.props.data.push(event);
                });

                $scope.props.data.sort(sortEvents);

                AlertService.add('success', labelTranslations['ATTENDANCE.BY_STAFF.ADD_SUCCESS']);
            });
        };
    }]);
