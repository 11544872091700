import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloLink, fromPromise } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';

import introspectionResult from '../../../src/generated/fragmentTypes.json';

import services from './module';

services.provider('AuthService', (
  VISA_ENDPOINT_AUTH,
  ENDPOINT_API,
  COOKIE_DOMAIN,
  ACCESS_TOKEN_COOKIE_NAME,
  REFRESH_TOKEN_COOKIE_NAME,
) => {
  'ngInject';

  const authData = {
    realmSettings: {
      rotareadyLoginEnabled: true,
      OIDCLoginEnabled: false,
    },
  };

  // Cached Settings and Keys
  const realmKey = 'authService.realm';
  const realmSettingsKey = 'authService.realmSettings';

  // Constants
  const clientId = 'rr_ui';
  const authServer = VISA_ENDPOINT_AUTH;
  const authReturnPath = '/authReturn';
  const localDomain = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  const localReturnEndpoint = localDomain + authReturnPath;

  // Variables
  let isAuthenticated = false;
  let refreshInProgress = false;
  let $cookies;
  const pendingApolloRequests = [];
  const result = {};

  // Endpoints
  const authEndpoint = `${authServer}/login`;
  const logoutEndpoint = `${authServer}/logout`;
  const changePasswordEndpoint = `${authServer}/edit-password`;
  const tokenEndpoint = `${ENDPOINT_API}/oauth/token`;
  const providerLogout = `${ENDPOINT_API}/oauth/sign-out`;

  angular.injector(['ngCookies']).invoke(['$cookies', (_$cookies_) => {
    $cookies = _$cookies_;
  }]);

  result.addPendingApolloRequest = (request) => {
    pendingApolloRequests.push(request);
  };

  result.getAccessToken = () => $cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  result.$get = (
    $http,
    $rootScope,
    $location,
    $cookies,
    localStorageService,
    SecurityRetryQueue,
  ) => {
    'ngInject';

    pendingApolloRequests.push = (e) => {
      Array.prototype.push.call(pendingApolloRequests, e);
      refreshAccessToken();
    };

    function isAuthenticationInProgress() {
      // Trim the trailing slash, if there is one
      return $location.path().replace(/\/+$/, '') === authReturnPath;
    }

    function setRealm(realm) {
      authData.realm = realm;
      localStorageService.set(realmKey, realm);
    }

    function setRealmSettings(settings) {
      authData.realmSettings = settings;
      localStorageService.set(realmSettingsKey, settings);
    }

    function setTokens(access, refresh) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 365);

      const params = {
        domain: COOKIE_DOMAIN,
        path: '/',
        expires,
      };

      $cookies.put(ACCESS_TOKEN_COOKIE_NAME, access, params);
      $cookies.put(REFRESH_TOKEN_COOKIE_NAME, refresh, params);
    }

    function redirectToAuthServer(reasonCode, toCurrentUrl, reauth) {
      const redirectUri = encodeURIComponent(localReturnEndpoint);

      const realmURI = authData.realm ? ('/' + authData.realm) : '';
      const returnPath = toCurrentUrl ? ('&return_path=' + encodeURIComponent($location.url())) : '';

      window.location = authEndpoint + realmURI +
          '?reauth=' + reauth +
          '&client_id=' + clientId +
          '&redirect_uri=' + redirectUri +
          returnPath;
    }

    function onSuccessfulAuthorization(accessToken, refreshToken, realmSettings) {
      setTokens(accessToken, refreshToken);
      setRealmSettings(realmSettings);

      isAuthenticated = true;
      refreshInProgress = false;

      SecurityRetryQueue.retryAll();

      pendingApolloRequests.map((callback) => callback());
      pendingApolloRequests.length = 0;
    }

    function requestAccessToken(authCode, returnPath) {
      const payload = {
        client_id: clientId,
        grant_type: 'authorization_code',
        code: authCode,
        redirect_uri: returnPath,
      };

      $http.post(tokenEndpoint, payload, {
        responseStatusAuthority: [401, 403],
      })
        .then(({ data }) => {
          const {
            access_token: accessToken,
            refresh_token: refreshToken,
            realm_settings: settings,
          } = data;

          onSuccessfulAuthorization(accessToken, refreshToken, settings);

          const r = new RegExp('^(?:[a-z]+:)?//', 'i');

          if (returnPath && !r.test(returnPath)) {
            $location.url(returnPath);
          } else {
            $location.url(localDomain);
          }
        })
        .catch(() => {
          isAuthenticated = false;
          redirectToAuthServer('invalidAuthCode', false, true);
        });
    }

    function refreshAccessToken() {
      if (refreshInProgress || isAuthenticationInProgress()) {
        return;
      }

      if (!isAuthenticated) {
        redirectToAuthServer('loginRequired', true, true);
        return;
      }

      refreshInProgress = true;

      const payload = {
        client_id: clientId,
        grant_type: 'refresh_token',
        refresh_token: $cookies.get(REFRESH_TOKEN_COOKIE_NAME),
      };

      $http.post(tokenEndpoint, payload, {
        responseStatusAuthority: [401, 403],
      })
        .then(({ data }) => {
          const {
            access_token: accessToken,
            refresh_token: refreshToken,
            realm_settings: settings,
          } = data;

          onSuccessfulAuthorization(accessToken, refreshToken, settings);
        })
        .catch(() => {
          isAuthenticated = false;
          refreshInProgress = false;

          SecurityRetryQueue.cancelAll();
          pendingApolloRequests.length = 0;

          redirectToAuthServer('sessionExpired', true, true);
        });
    }

    $rootScope.$on('auth:rejectedRequest', () => {
      if (!SecurityRetryQueue.hasMore()) {
        return;
      }

      refreshAccessToken();
    });

    return {
      init: () => {
        if (isAuthenticationInProgress()) {
          return;
        }

        const realm = localStorageService.get(realmKey);
        const realmSettings = localStorageService.get(realmSettingsKey);

        if (!realm || !realmSettings) {
          return redirectToAuthServer('realmUnknown', true, false);
        }

        setRealm(realm);
        setRealmSettings(realmSettings);

        const accessToken = $cookies.get(ACCESS_TOKEN_COOKIE_NAME);
        const refreshToken = $cookies.get(REFRESH_TOKEN_COOKIE_NAME);

        if (!accessToken || !refreshToken) {
          return redirectToAuthServer('loginRequired', true, true);
        }

        setTokens(accessToken, refreshToken);
        isAuthenticated = true;
      },

      getRealm: () => authData.realm,
      isRotareadyLoginEnabled: () => authData.realmSettings.rotareadyLoginEnabled,
      isOIDCLoginEnabled: () => authData.realmSettings.OIDCLoginEnabled,

      getAccessToken: () => $cookies.get(ACCESS_TOKEN_COOKIE_NAME),

      signOut: () => {
        $http.get(providerLogout).then(() => {
          $cookies.remove(ACCESS_TOKEN_COOKIE_NAME, { domain: COOKIE_DOMAIN, path: '/' });
          $cookies.remove(REFRESH_TOKEN_COOKIE_NAME, { domain: COOKIE_DOMAIN, path: '/' });
          const queryArray = [];

          const params = {
            realm: authData.realm,
            client_id: clientId,
            redirect_uri: encodeURI(localReturnEndpoint),
          };

          for (const key in params) {
            const value = params[key];
            queryArray.push(key + '=' + value);
          }

          const queryString = queryArray.join('&');
          const path = logoutEndpoint + '/' + authData.realm;

          window.location = path + '?' + queryString;
        });
      },

      handleAuthResponse: () => {
        setRealm($location.search().realm);
        requestAccessToken($location.search().code, $location.search().return_path);
      },

      changePassword: (email) => {
        window.location = changePasswordEndpoint + '/'
          + authData.realm
          + '?client_id=' + clientId
          + '&email=' + encodeURIComponent(email)
          + '&redirect_uri=' + encodeURIComponent(localDomain);
      },
    };
  };

  return result;
});

services.factory('SecurityRetryQueue', ['$q', '$rootScope',
    function SecurityRetryQueue($q, $rootScope) {
        var retryQueue = [];

        var service = {
            hasMore: function() {
                return retryQueue.length > 0;
            },
            push: function(retryItem) {
                retryQueue.push(retryItem);
                $rootScope.$emit('auth:rejectedRequest');
            },
            pushRetryFn: function(retryFn) {
                // The deferred object that will be resolved or
                // rejected by calling retry or cancel
                var deferred = $q.defer();
                var retryItem = {
                    retry: function() {
                        // Wrap the result of the retryFn into a promise
                        $q.when(retryFn()).then(function(value) {
                            // If it was successful then resolve our deferred
                            deferred.resolve(value);
                        }, function(value) {
                            // Otherwise reject it
                            deferred.reject(value);
                        });
                    },
                    cancel: function() {
                        // Give up on retrying and reject our deferred
                        deferred.reject();
                    }
                };
                service.push(retryItem);
                return deferred.promise;
            },
            cancelAll: function() {
                while(service.hasMore()) {
                    retryQueue.shift().cancel();
                }
            },
            retryAll: function() {
                while(service.hasMore()) {
                    retryQueue.shift().retry();
                }
            }
        };

        return service;
    }
]);

services.factory('SecurityInterceptor', ['$injector', '$q', 'SecurityRetryQueue',
    function SecurityInterceptor($injector, $q, SecurityRetryQueue) {
        function isStatusCodeHandledByCaller(response, statusCode) {
            return response && response.config && response.config.responseStatusAuthority
                && response.config.responseStatusAuthority.indexOf(statusCode) !== -1;
        }

        return {
            request: function (config) {
                // Callers can opt-out of the request interceptor, useful when
                // calling 3rd party APIs, such as direct-to-S3 file uploads
                if (config.noIntercept) {
                    return config;
                }

                var accessToken = $injector.get('AuthService').getAccessToken();

                if (accessToken) {
                    config.headers['Authorization'] = 'Bearer ' + accessToken;
                }

                return config;
            },

            responseError: function (response) {
                if (response && response.config && response.config.noIntercept) {
                    return $q.reject(response);
                }

                // Include status 0 or -1 (CORS on IE10 returns this instead of a 401)
                if ((response.status === 401 || response.status <= 0) && !isStatusCodeHandledByCaller(response, 401)) {
                    // The request bounced because it was not authorized
                    return SecurityRetryQueue.pushRetryFn(function retryRequest() {
                        // We must use $injector to get the
                        // $http service to prevent circular dependency
                        return $injector.get('$http')(response.config);
                    });
                }

                if (response.status === 403 && !isStatusCodeHandledByCaller(response, 403)) {
                    $injector.get('AlertService').add('warning', 'You don\'t have permission to access that.', 'AuthService');
                }

                return $q.reject(response);
            }
        };
    }
])

.config(['$httpProvider', 'apolloProvider', 'AuthServiceProvider', 'ENDPOINT_GRAPHQL',
    function($httpProvider, apolloProvider, AuthServiceProvider, ENDPOINT_GRAPHQL) {
        $httpProvider.interceptors.push('SecurityInterceptor');

        // Initialize get if not there
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }

        // Disable IE9 ajax request caching
        $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';

        const httpLink = createHttpLink({
            uri: ENDPOINT_GRAPHQL,
        });

        const authLink = setContext((_, { headers }) => {
            const token = AuthServiceProvider.getAccessToken();

            return {
                headers: {
                    ...headers,
                    Authorization: `Bearer ${token || ''}`,
                },
            };
        });

        const errorLink = onError(
            ({ graphQLErrors, networkError = {}, operation, forward }) => {
                if (networkError.statusCode === 401) {
                    const forward$ = fromPromise(
                        new Promise(resolve => {
                            AuthServiceProvider.addPendingApolloRequest(() => resolve());
                        })
                    );

                    return forward$.flatMap(() => forward(operation));
                }
            }
        );

        const fragmentMatcher = new IntrospectionFragmentMatcher({
            introspectionQueryResultData: introspectionResult,
        });

        const client = new ApolloClient({
            link: ApolloLink.from([errorLink, authLink, httpLink]),
            cache: new InMemoryCache({
                fragmentMatcher,
            }),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'cache-and-network',
                    errorPolicy: 'ignore',
                },
                query: {
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
            }
        });

        apolloProvider.defaultClient(client);
    }
]);
