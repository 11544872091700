var controllers = require('../../../module');

controllers.controller(
  'SettingsIntegrationSevenRoomsCtrl',
  [
    '$scope',
    '$http',
    'SettingsService',
    'AlertService',
    'AuthService',
    'EnvironmentDataService',
    'ENDPOINT_API',
    function ($scope, $http, SettingsService, AlertService, AuthService, EnvironmentDataService, ENDPOINT_API) {
      var integrationId = 'seven_rooms';

      $scope.dataLoaded = false;
      $scope.realmId = AuthService.getRealm();
      $scope.validationRequired = false;

      var sevenRoomsMetadataEndpoint = ENDPOINT_API + '/integration/sevenRooms/metadata';
      $scope.SRMetadataLoaded = false;
      $scope.loadingSRMetadata = false;

      var unvalidatedInitialVenueMapping = {};
      $scope.initialVenueMapping = {};
      var venueMappings = {};

      $scope.entityMapping = {
        metadataLoaded: false,
        mapping: {},
        optionList: [],
        entityGroups: [],
      };

      function handleError(error, status) {
        if (status !== 403) {
          AlertService.add('danger', 'We weren\'t able to load or modify the settings just then. Please try again');
        }
      }

      function getSettings() {
        $scope.dataLoaded = false;

        SettingsService.getIntegrationSettings(integrationId)
          .success(function(data) {
            var loadedSettings = data.settings;
            var settings = {};

            settings.status = loadedSettings.status || 'false';
            $scope.validationRequired = settings.status === 'true';

            settings.entityMappings = loadedSettings.entityMappings || [];
            settings.clientId = loadedSettings.clientId || '';
            settings.clientSecret = loadedSettings.clientSecret || '';

            $scope.formData = settings;

            unvalidatedInitialVenueMapping = loadedSettings.venueMappings || {};

            $scope.dataLoaded = true;

            if (settings.clientId && settings.clientSecret) {
              $scope.loadSRMetadata();
            }
          })
          .error(function(err, status) {
            handleError(err, status);
          });
      }

      getSettings();

      $scope.$watch('formData.status', function(newStatus) {
        $scope.validationRequired = newStatus === 'true';
      });

      $scope.save = function () {
        $scope.saveActioning = true;

        var settings = angular.merge({}, $scope.formData, { venueMappings: venueMappings });

        SettingsService.updateIntegrationSettings(integrationId, { settings: settings })
          .success(function () {
            $scope.saveActioning = false;
            AlertService.add('success', 'The settings have been successfully updated.');
          })
          .error(function (error, status) {
            $scope.saveActioning = false;
            handleError(error, status);
          });
      };

      $scope.loadSRMetadata = function ($event) {
        if ($event && $event.preventDefault) {
          $event.preventDefault();
        }

        $scope.loadingSRMetadata = true;
        $scope.SRMetadataLoaded = false;

        var clientId = $scope.formData.clientId;
        var clientSecret = $scope.formData.clientSecret;

        $http.get(sevenRoomsMetadataEndpoint, {
          params: {
            clientId: clientId,
            clientSecret: clientSecret,
          }
        })
          .success(function (data) {
            $scope.loadingSRMetadata = false;
            $scope.entityMapping.optionList = [];

            AlertService.add('success', 'Successfully authenticated and fetched venues.');

            var rawVenues = data.data.venues;
            var venueLookupIds = [];

            rawVenues.forEach(function (venue) {
              var lookupId = venue.venue_group_id + '-' + venue.id;

              venueLookupIds.push(lookupId);

              $scope.entityMapping.optionList.push({
                label: venue.name,
                id: venue.id,
                groupId: venue.venue_group_id,
              });
            });

            for (var entity in unvalidatedInitialVenueMapping) {
              var selectedVenue = unvalidatedInitialVenueMapping[entity];

              var lookupId = selectedVenue.groupId + '-' + selectedVenue.id;

              if (venueLookupIds.indexOf(lookupId) > -1) {
                $scope.initialVenueMapping[entity] = selectedVenue;
              } else {
                AlertService.add('warning', 'A venue (' + selectedVenue.label + ') has changed ID or group in SevenRooms and we are unable to load your mapping for it. Please adjust your mappings and save to fix.', `sevenrooms-${selectedVenue.label}`);
              }
            }

            $scope.SRMetadataLoaded = true;
            loadEntityMappingData();
          })
          .error(function (error, status) {
            $scope.loadingSRMetadata = false;
            handleError(error, status);
          });
      };

      $scope.updateOption = () => {
        const outputMapping = {};

        for (var entity in $scope.entityMapping.mapping) {
          var selectedOption = $scope.entityMapping.mapping[entity];

          if (selectedOption) {
            outputMapping[entity] = selectedOption;
          }
        }

        venueMappings = outputMapping;
      };

      function loadEntityMappingData() {
        EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
          .then(({ data }) => {
            var entityIds = [];

            $scope.entityMapping.entityGroups = data;

            for (var group in data) {
              var entityList = data[group].entities;

              entityList.forEach(function (entity) {
                entityIds.push(entity.id);
              });
            }

            if ($scope.initialVenueMapping) {
              for (var entity in $scope.initialVenueMapping) {
                var selectedOption = $scope.initialVenueMapping[entity];

                if (entityIds.indexOf(entity) > -1) {
                  $scope.entityMapping.mapping[entity] = selectedOption;
                }
              }
            }

            $scope.entityMapping.metadataLoaded = true;
          });
      }
    }
  ]
);
