module.exports = (
  $scope,
  $state,
  $stateParams,
  $translate,
  $window,
  $http,
  AlertService,
  StaffCommon,
  StaffService,
  SessionService,
  ExportService,
  EnvironmentDataService,
  ENDPOINT_API,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.UNTAKEN_HOLIDAY_COST.TITLE',
    'REPORTS.UNTAKEN_HOLIDAY_COST.ERROR_500',
    'REPORTS.UNTAKEN_HOLIDAY_COST.ERROR_400',
    'REPORTS.UNTAKEN_HOLIDAY_COST.LABEL_DEDUCTIBLE_YES',
    'REPORTS.UNTAKEN_HOLIDAY_COST.LABEL_DEDUCTIBLE_NO',
  ]);

  let selectedDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('year');
  let selectedDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('year').add(1, 'year').subtract(1, 'day');
  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [SessionService.getEntity()];

  $scope.props = {
    loadingData: false,
    defaultEntityIds,
    defaultDateFilter: selectedDateStart && selectedDateEnd ? {
      option: 4,
      dateStart: selectedDateStart,
      dateEnd: selectedDateEnd,
    } : {
      option: 1,
    },
    activeUsers: true,
    getLabelFromScheduleOfAccrual: StaffCommon.getLabelFromScheduleOfAccrual,
    getLabelFromAllowanceUnit: StaffCommon.getLabelFromAllowanceUnit,
    getLabelFromDeductible: (deductible) => (deductible
      ? translations['REPORTS.UNTAKEN_HOLIDAY_COST.LABEL_DEDUCTIBLE_YES']
      : translations['REPORTS.UNTAKEN_HOLIDAY_COST.LABEL_DEDUCTIBLE_NO']),
    getLabelFromPayAmountType: (amountType) => {
      const value = StaffService.getPayAmountTypes().find(({ id }) => id === amountType);
      return value ? value.title : '';
    },
  };

  $scope.getDataParams = {
    allowanceStartsInPeriod: selectedDateStart && selectedDateEnd
      ? `${selectedDateStart.format('YYYY-MM-DD')}/${selectedDateEnd.format('YYYY-MM-DD')}`
      : undefined,
    'entityIds[]': defaultEntityIds,
    userIsActive: true,
  };

  $scope.getUntaken = (allowance) => (Math.round((allowance.totalAccrued
    - allowance.bookedAndElapsed) * 100) / 100);

  $scope.loadData = () => {
    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.loadingData = true;

    const { getDataParams } = $scope;
    const entityIds = getDataParams['entityIds[]'];

    $state.go('.', {
      start: selectedDateStart ? selectedDateStart.format('YYYY-MM-DD') : undefined,
      end: selectedDateEnd ? selectedDateEnd.format('YYYY-MM-DD') : undefined,
      entityIds: entityIds ? entityIds.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
      inherit: false,
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $http.get(`${ENDPOINT_API}/report/untakenHolidayCost`, {
      params: getDataParams,
    })
      .then(({ data }) => {
        $scope.report = data.untakenHolidayCost;
        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.UNTAKEN_HOLIDAY_COST.ERROR_500']);
        }

        if (status === 400) {
          AlertService.add('danger', translations['REPORTS.UNTAKEN_HOLIDAY_COST.ERROR_400']);
        }
      });
  };

  $scope.onDateFilter = ({
    option,
    dateEnd,
    dateStart,
  }) => {
    if (option === 1) {
      selectedDateStart = undefined;
      selectedDateEnd = undefined;
      $scope.getDataParams.allowanceStartsInPeriod = undefined;
    } else {
      selectedDateStart = dateStart.clone();
      selectedDateEnd = dateEnd.clone();
      $scope.getDataParams.allowanceStartsInPeriod = `${dateStart.format('YYYY-MM-DD')}/${dateEnd.format('YYYY-MM-DD')}`;
    }

    $scope.loadData();
  };

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.getDataParams['entityIds[]'] = entityIds.length ? entityIds : undefined;
    $scope.loadData();
  };

  $scope.onActiveUsersFilter = () => {
    $scope.getDataParams.userIsActive = $scope.props.activeUsers ? true : undefined;
    $scope.loadData();
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
  ])
    .then(([
      entityGroup,
    ]) => {
      $scope.props.entityGroupList = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => ({
            id: entity.id,
            label: entity.name,
            parentId: entity.groupId,
            depth: 1,
          })),
        ]));

      $scope.loadData();
    });

  $scope.export = ($event, tableId, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.UNTAKEN_HOLIDAY_COST.TITLE'],
      tableId, format);
  };
};
