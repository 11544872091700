const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/other/releaseRefreshModal.html');

'use strict';
var services = require('./module');

import Pusher from 'pusher-js';

services.factory('NotificationService', ['$rootScope', '$http', '$state', '$timeout', '$uibModal', 'AuthService', 'localStorageService', 'PUSHER_KEY', 'ENDPOINT_API', 'WFM_ENDPOINT',
    function ($rootScope, $http, $state, $timeout, $uibModal, AuthService, localStorageService, PUSHER_KEY, ENDPOINT_API, WFM_ENDPOINT) {
        var key = 'notificationService.persisted';
        var baseUrl = ENDPOINT_API + '/notifications/';

        var notificationService = {
            types: Object.freeze({
                ROTA_PUBLISHED: 1,
                ROTA_SHIFT_ASSIGNED: 2,
                ROTA_SHIFT_UNASSIGNED: 3,
                ABSENCE_REQUEST_UPDATED: 4,
                ROTA_SWAPS_ACCEPTED: 9,
                ROTA_SWAPS_APPROVED: 10,
                ROTA_SWAPS_REJECTED: 11,
                ROTA_DRAFT_BUILT: 15,
                REPORT_FINISHED: 34,
                ESD_ONBOARDING_SUBMITTED: 45,
                ESD_UPDATES_SUBMITTED: 46,
                // 'Fake' notifications - generated on-the-fly by the API when we get a user's session
                ATTENDANCE_NEW_ISSUES: 5000,
                STAFF_NEW_REQUESTS: 5001,
                ATTENDANCE_FACIAL_CONCERNS: 5002,
                ROTA_SHIFT_SWAP: 5003,
            })
        };

        $rootScope.notificationService = {
            notifications: [],
            totalUnread: 0
        };

        var refreshDates = function() {
            angular.forEach($rootScope.notificationService.notifications, function (n) {
                n.timeAgo = n.dateActivity.fromNow();
            });

            $timeout(refreshDates, 30000);
        };

        refreshDates();

        var notificationSkeletons = {};

        function addNotificationSkeleton(typeId, iconClass, uiSref, wfmLink) {
            notificationSkeletons[typeId] = {
                typeId: typeId,
                iconClass: iconClass,
                uiSref: uiSref,
                wfmLink: wfmLink,
                // The below properties are defaults that might get overwritten when we clone the skeleton
                persistId: false,
                dateActivity: moment(),
                isRead: false
            };
        }

        addNotificationSkeleton(notificationService.types.ROTA_PUBLISHED, 'glyphicon-pushpin');
        addNotificationSkeleton(notificationService.types.ROTA_SHIFT_ASSIGNED, 'glyphicon-plus-sign');
        addNotificationSkeleton(notificationService.types.ROTA_SHIFT_UNASSIGNED, 'glyphicon-minus-sign');
        addNotificationSkeleton(notificationService.types.ABSENCE_REQUEST_UPDATED, 'glyphicon-calendar', 'personal.absence.bookings');
        addNotificationSkeleton(notificationService.types.ROTA_SWAPS_ACCEPTED, 'glyphicon-time', 'personal.schedule');
        addNotificationSkeleton(notificationService.types.ROTA_SWAPS_APPROVED, 'glyphicon-transfer', 'personal.schedule');
        addNotificationSkeleton(notificationService.types.ROTA_SWAPS_REJECTED, 'glyphicon-thumbs-down', 'personal.schedule');
        addNotificationSkeleton(notificationService.types.REPORT_FINISHED, 'glyphicon-list-alt', 'rota.overview');
        addNotificationSkeleton(notificationService.types.ATTENDANCE_NEW_ISSUES, 'glyphicon-flag', 'attendance.issues');
        addNotificationSkeleton(notificationService.types.STAFF_NEW_REQUESTS, 'glyphicon-plane', 'staff.requests.absence');
        addNotificationSkeleton(notificationService.types.ROTA_DRAFT_BUILT, 'glyphicon-ok');
        addNotificationSkeleton(notificationService.types.ATTENDANCE_FACIAL_CONCERNS, 'glyphicon-camera', 'attendance.issues');
        addNotificationSkeleton(notificationService.types.ROTA_SHIFT_SWAP, 'glyphicon-transfer', 'staff.requests.swaps');
        addNotificationSkeleton(notificationService.types.ESD_ONBOARDING_SUBMITTED, 'glyphicon-user', null, `${WFM_ENDPOINT}/staff/employee-submitted-details`);
        addNotificationSkeleton(notificationService.types.ESD_UPDATES_SUBMITTED, 'glyphicon-user', null, `${WFM_ENDPOINT}/staff/employee-submitted-details`);

        $rootScope.notificationService.markAllRead = function () {
            notificationService.markAllRead();
        };

        $rootScope.notificationService.go = function (notification) {
            notificationService.markAsRead(notification);

            if (!notification.uiSref && !notification.wfmLink) {
              return;
            }

            if (notification.wfmLink) {
                window.location.replace(notification.wfmLink);
            }

            if (notification.payload) {
                $state.go(notification.uiSref, notification.payload);
            } else {
                $state.go(notification.uiSref);
            }
        };

        notificationService.init = function (withNotifications, userId) {
            $rootScope.notificationService.notifications = [];
            $rootScope.notificationService.totalUnread = 0;

            if (withNotifications !== false) {
                notificationService.addAll(withNotifications);
            } else {
                notificationService.addAll(localStorageService.get(key));
            }

            const pusher = new Pusher(PUSHER_KEY, {
                authEndpoint: `${baseUrl}auth`,
                forceTLS: ENDPOINT_API.substring(0, 5) === 'https',
                auth: {
                    headers: {
                        'Authorization': `Bearer ${AuthService.getAccessToken()}`,
                    },
                },
            });

            const privateChannel = pusher.subscribe(['private', AuthService.getRealm(), userId].join('-'));
            const serviceChannel = pusher.subscribe('service');

            privateChannel.bind('notification', (data) => {
                notificationService.add(data, true);
            });

            serviceChannel.bind('release', () => {
                $uibModal.open({
                    templateUrl: autoNgTemplateLoaderTemplate1,
                    controller: 'ReleaseRefreshModalCtrl',
                    backdrop: 'static',
                    keyboard: false,
                    size: 'sm',
                });
            });
        };

        notificationService.markAllRead = function () {
            if ($rootScope.notificationService.totalUnread === 0) {
                return;
            }

            for (var i = 0; i < $rootScope.notificationService.notifications.length; i++) {
                $rootScope.notificationService.notifications[i].isRead = true;
            }

            $rootScope.notificationService.totalUnread = 0;

            notificationService.persist();

            $http.delete(baseUrl + 'markRead');
        };

        notificationService.dismiss = function (notificationType) {
            for (var i = 0; i < $rootScope.notificationService.notifications.length; i++) {
                var n = $rootScope.notificationService.notifications[i];

                if (n.isRead || n.typeId !== notificationType) {
                    continue;
                }

                n.isRead = true;
                $rootScope.notificationService.totalUnread--;

                if (n.persistId) {
                    $http.delete(baseUrl + 'markRead/' + n.persistId);
                }
            }

            notificationService.persist();
        };

        notificationService.markAsRead = function (notification) {
            if (notification.isRead) {
                return;
            }

            notification.isRead = true;
            $rootScope.notificationService.totalUnread--;

            if (notification.persistId) {
                $http.delete(baseUrl + 'markRead/' + notification.persistId);
            }

            notificationService.persist();
        };

        notificationService.addAll = function (notifications) {
            if (!notifications || !notifications.length) {
                return;
            }

            notifications.sort(function (o1, o2) {
                if (o1.dateActivity < o2.dateActivity) return -1;
                if (o1.dateActivity > o2.dateActivity) return 1;
                return 0;
            });

            angular.forEach(notifications, function (n) {
                notificationService.add(n, false);
            });

            notificationService.persist();
        };

        // notification object properties: typeId, title, body, [persistId, payload, isRead, dateActivity]
        notificationService.add = function (n, persist) {
            // Ignore notification types we don't recognise
            if (!notificationSkeletons.hasOwnProperty(n.typeId)) {
                return;
            }

            // Ignore notifications with no title
            if (!n.title) {
                return;
            }

            // Clone the skeleton and copy the properties onto it
            var notification = angular.copy(notificationSkeletons[n.typeId]);

            notification.title = n.title;
            notification.body = n.body;

            if (n.persistId) {
                notification.persistId = n.persistId;
            }

            if (n.payload) {
                notification.payload = n.payload;
            }

            if (n.hasOwnProperty('isRead')) {
                notification.isRead = n.isRead;
            }

            if (n.dateActivity) {
                // Compensate for server time being a few seconds ahead of client time
                notification.dateActivity = moment.min(moment(n.dateActivity), moment());
            }

            // And now process whatever we need to do...
            notification.timeAgo = notification.dateActivity.fromNow();

            if (!notification.isRead) {
                $rootScope.notificationService.totalUnread++;
            }

            if (!notification.persistId) {
                // If it doesn't have a persist id, we should only hold 1 for its given typeId
                // So now we need to clear out any notifications for this typeId
                var i = $rootScope.notificationService.notifications.length;

                while (i--) {
                    var j = $rootScope.notificationService.notifications[i];

                    if (j.typeId === n.typeId) {
                        if (!j.isRead) {
                            $rootScope.notificationService.totalUnread--;
                        }

                        $rootScope.notificationService.notifications.splice(i, 1);
                    }
                }
            }

            // Add the new notification to the top of the list, and broadcast it to the rest of the app
            $rootScope.notificationService.notifications.unshift(notification);
            $rootScope.$broadcast('notification', notification);

            if (persist) {
                notificationService.persist();
            }
        };

        notificationService.persist = function () {
            localStorageService.set(key, $rootScope.notificationService.notifications);
        };

        notificationService.getSubscriptions = function (params) {
            return $http.get(ENDPOINT_API + '/subscriptions', { params: params });
        };

        notificationService.unsubscribe = function (data) {
            return $http.put(ENDPOINT_API + '/subscriptions/unsubscribe', data);
        };

        notificationService.subscribe = function (data) {
            return $http.post(ENDPOINT_API + '/subscriptions/subscribe', data);
        };

        notificationService.getSubscriptionResourceTypeData = function (resourceType) {
            switch (resourceType) {
                case 'absence': return {
                    translation: 'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_ABSENCE',
                    eventTypeIds: [1, 2, 3, 4],
                };
                case 'rota': return {
                    translation: 'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_ROTA',
                    eventTypeIds: [5, 6, 7],
                };
                case 'account': return {
                    translation: 'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_ACCOUNT',
                    eventTypeIds: [9, 10],
                };
                case 'swap': return {
                    translation: 'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_SWAP',
                    eventTypeIds: [11, 13, 14, 15],
                };
                case 'costControl': return {
                    translation: 'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_COST_CONTROL',
                    eventTypeIds: [8, 36, 37, 38],
                };
                case 'document': return {
                    translation: 'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_DOCUMENT',
                    eventTypeIds: [16, 17],
                };
                case 'broadcast': return {
                    translation: 'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_BROADCAST',
                    eventTypeIds: [29, 30, 31, 32],
                };
                default: return undefined;
            }
        };

        return notificationService;
    }]);
