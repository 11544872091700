const { getFilterRegistry } = require('./filters');
const {
  getColumns,
  defaultColumnIds,
} = require('./columns');

module.exports = (
  $scope,
  $state,
  $stateParams,
  $translate,
  $window,
  $timeout,
  StaffService,
  EnvironmentDataService,
  SessionService,
  AlertService,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'STAFF.OVERVIEW.ERROR_500',
  ]);

  let columnRegistryInitialised = false;
  let filterRegistryInitialised = false;
  const defaultEntityId = SessionService.getEntity();
  const filterRegistry = getFilterRegistry($translate, EnvironmentDataService, defaultEntityId);
  const filterStates = new Map();

  $scope.onFilterSelect = (selectedOptions, id) => {
    filterStates.set(id, selectedOptions);
    $scope.loadData(true);
  };

  const enableFilter = async (id) => {
    if ($scope.props.enabledFilterIds.includes(id)) {
      return;
    }

    const filter = filterRegistry.get(id);

    if (!filter) {
      return;
    }

    $scope.props.enabledFilterIds = [...$scope.props.enabledFilterIds, id];

    if (!$scope.props.filterOptions[id] && filter.getOptions) {
      $scope.props.filterOptions[id] = await filter.getOptions();
    }
  };

  const disableFilter = (id) => {
    if (!$scope.props.enabledFilterIds.includes(id)) {
      return;
    }

    filterStates.delete(id);
    $scope.props.filterDefaultValues[id] = undefined;
    $scope.props.enabledFilterIds = $scope.props.enabledFilterIds
      .filter((filter) => filter !== id);
  };

  const initialiseFilterRegistry = async () => {
    if (filterRegistryInitialised) {
      return;
    }

    const { filter: filterBase64 } = $stateParams;
    let filterStateParams;

    try {
      const parsed = JSON.parse(atob(filterBase64));

      if (typeof parsed === 'object') {
        filterStateParams = parsed;
      }
    } catch (e) {}

    for (const [id, filter] of filterRegistry) {
      if (filterStateParams) {
        const { isEnabled, value } = filter.fromStateParams(filterStateParams);

        if (isEnabled) {
          await enableFilter(id);
        }

        filterStates.set(id, value);
        $scope.props.filterDefaultValues[id] = value;
      } else {
        const { defaultValue } = filter;

        if (defaultValue) {
          await enableFilter(id);

          filterStates.set(id, defaultValue);
          $scope.props.filterDefaultValues[id] = defaultValue;
        }
      }
    }

    $scope.props.availableFilters = Array.from(filterRegistry.values())
      .map(({ id, label }) => ({ id, label }));

    filterRegistryInitialised = true;
  };

  const initialiseColumnRegistry = () => {
    if (columnRegistryInitialised) {
      return;
    }

    const columns = getColumns($translate);
    const validColumnIds = Object.keys(columns);

    $scope.props.columnList = Object.entries(columns).map(([id, label]) => ({
      id,
      label,
    }));

    const { columns: columnsBase64 } = $stateParams;
    let columnsStateParams = [];

    try {
      const parsed = JSON.parse(atob(columnsBase64));

      if (Array.isArray(parsed)) {
        columnsStateParams = parsed;
      }
    } catch (e) {}

    const chosenColumnIds = columnsStateParams
      .filter((columnId) => validColumnIds.includes(columnId));

    $scope.onColumnChange(chosenColumnIds.length ? chosenColumnIds : defaultColumnIds, false);
    columnRegistryInitialised = true;
  };

  $scope.onUserChangeEnabledFilters = (selectedOptions) => {
    const toEnable = selectedOptions
      .filter((id) => !$scope.props.enabledFilterIds.includes(id));

    const toDisable = $scope.props.enabledFilterIds
      .filter((id) => !selectedOptions.includes(id));

    if (!toEnable.length && !toDisable.length) {
      return;
    }

    toDisable.forEach((id) => disableFilter(id));
    toEnable.forEach((id) => enableFilter(id));

    $scope.loadData(true);
  };

  $scope.props = {
    loadingData: false,
    noMoreData: false,
    page: 1,
    data: [],
    availableFilters: [],
    enabledFilterIds: [],
    filterOptions: {},
    filterDefaultValues: {},
    columnList: [],
    selectedColumns: [],
    selectedColumnIds: [],
    cardView: false,
  };

  const setQueryState = (reset) => {
    if (reset) {
      $scope.props.page = 1;
      $scope.props.noMoreData = false;
      $scope.props.data = [];
    }

    let filterStateParams = {};
    let requestParams = {};

    $scope.props.enabledFilterIds.forEach((filterId) => {
      const selectedOptions = filterStates.get(filterId);
      const filter = filterRegistry.get(filterId);

      if (selectedOptions === undefined) {
        return;
      }

      filterStateParams = {
        ...filterStateParams,
        ...filter.toStateParams(selectedOptions),
      };

      requestParams = {
        ...requestParams,
        ...filter.toRequestParams(selectedOptions),
      };
    });

    const { selectedColumnIds } = $scope.props;

    $state.go('.', {
      filter: Object.keys(filterStateParams).length
        ? btoa(JSON.stringify(filterStateParams)) : undefined,
      columns: selectedColumnIds.length
        ? btoa(JSON.stringify(selectedColumnIds)) : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
      inherit: false,
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $scope.getDataParams = {
      ...requestParams,
      entityVisible: true,
      'columns[]': selectedColumnIds,
      page: $scope.props.page,
      locale: SessionService.getUserLocale(),
      limit: 25,
      sortOrder: 'lastNameFirstNameAsc',
    };
  };

  $scope.loadData = async (reset) => {
    if ($scope.props.loadingData) {
      return;
    }

    await initialiseFilterRegistry();
    initialiseColumnRegistry();
    setQueryState(reset);

    $scope.props.loadingData = true;

    try {
      const { data } = await StaffService.exportAccounts($scope.getDataParams);
      const { results } = data;

      $scope.props.data.push(...results);
      $scope.props.page += 1;

      if (results.length < $scope.getDataParams.limit) {
        $scope.props.noMoreData = true;
      }

      $scope.props.loadingData = false;
      $scope.$apply();
    } catch ({ status }) {
      $scope.props.noMoreData = true;
      $scope.props.loadingData = false;

      if (status === 500) {
        AlertService.add('danger', translations['STAFF.OVERVIEW.ERROR_500']);
      }
    }
  };

  $scope.onColumnChange = (selectedOptions, loadData) => {
    $scope.props.selectedColumnIds = selectedOptions;
    $scope.props.selectedColumns = $scope.props.columnList
      .filter(({ id }) => selectedOptions.includes(id));

    if (loadData) {
      $scope.loadData(true);
    }
  };

  $scope.export = async () => {
    $scope.props.loadingData = true;

    const {
      data: {
        signedUrl,
      },
    } = await StaffService.exportAccounts($scope.getDataParams, {
      'Content-Type': 'text/csv',
    });

    if (signedUrl) {
      $window.location = signedUrl;
    }

    $scope.props.loadingData = false;
  };

  $scope.onCardViewChange = () => {
    $timeout(() => {
      // force table header to recalculate its width
      $window.dispatchEvent(new Event('resize'));
    }, 50);
  };
};
