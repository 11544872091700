module.exports = (
  $scope,
  $uibModalInstance,
  $translate,
  EnvironmentDataService,
  AlertService,
  StaffService,
  data,
) => {
  'ngInject';

  const {
    organisation,
  } = data;

  const translations = $translate.instant([
    'SETTINGS.OUTSOURCING.EDIT_MODAL.ALERT_SUCCESS',
    'SETTINGS.OUTSOURCING.EDIT_MODAL.ALERT_ERROR_500',
    'SETTINGS.OUTSOURCING.EDIT_MODAL.ALERT_ERROR_400',
    'SETTINGS.OUTSOURCING.EDIT_MODAL.HOURLY_OPTION',
    'SETTINGS.OUTSOURCING.EDIT_MODAL.FIXED_OPTION',
  ]);

  $scope.props = {
    actionInProgress: true,
    tagList: [],
    selectedTags: [],
    payTypeList: [
      {
        id: 0,
        label: translations['SETTINGS.OUTSOURCING.EDIT_MODAL.HOURLY_OPTION'],
      },
      {
        id: 2,
        label: translations['SETTINGS.OUTSOURCING.EDIT_MODAL.FIXED_OPTION'],
      },
    ],
    formData: organisation ? {
      name: organisation.name,
      entityId: organisation.entityId ? organisation.entityId : null,
      contractPayType: organisation.contractPayType,
      contractDefaultRate: organisation.contractDefaultRate,
      earliestStart: organisation.earliestStart,
      latestFinish: organisation.latestFinish,
      quantityAvailable: organisation.quantityAvailable,
    } : {
      entityId: null,
      contractPayType: 0,
      contractDefaultRate: 10,
      earliestStart: '00:00:00',
      latestFinish: '00:00:00',
      quantityAvailable: 0,
    },
    isAdd: !organisation,
    organisation,
  };

  function onApiError(status) {
    $scope.props.actionInProgress = false;

    if (status === 500) {
      AlertService.add('danger', translations['SETTINGS.OUTSOURCING.EDIT_MODAL.ALERT_ERROR_500']);
      return;
    }

    if (status === 400) {
      AlertService.add('danger', translations['SETTINGS.OUTSOURCING.EDIT_MODAL.ALERT_ERROR_400']);
    }
  }

  function onApiSuccess() {
    $scope.props.actionInProgress = false;

    AlertService.add('success', translations['SETTINGS.OUTSOURCING.EDIT_MODAL.ALERT_SUCCESS']);
    $uibModalInstance.close({ success: true });
  }

  function getTagAssociations() {
    if (!organisation) {
      $scope.props.actionInProgress = false;
      return;
    }

    StaffService.getOutsourcingOrganisationTagAssociations(organisation.id)
      .then(({ data }) => {
        $scope.props.selectedTags = data.results.map(({ tagId }) => tagId);
        $scope.props.actionInProgress = false;
      })
      .catch(({ status }) => onApiError(status));
  }

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.Tag,
  ])
    .then(([
      entityGroup,
      tag,
    ]) => {
      $scope.props.entityList = entityGroup.data;
      $scope.props.tagList = tag.data
        .filter(({ entityId, deleted }) => !entityId && !deleted);
      $scope.props.tagListInitialised = true;

      getTagAssociations();
    });

  $scope.close = () => {
    $uibModalInstance.dismiss();
  };

  $scope.onEntitySelect = (entity) => {
    $scope.props.formData.entityId = entity ? entity.id : null;
  };

  $scope.submit = () => {
    const {
      props: {
        formData,
        actionInProgress,
        selectedTags: tags,
      },
    } = $scope;

    if (!formData.name
      || !formData.name.length
      || Number.isNaN(formData.contractDefaultRate)) {
      return;
    }

    if (actionInProgress) {
      return;
    }

    $scope.props.actionInProgress = true;

    const toSend = {
      ...$scope.props.formData,
      earliestStart: moment(formData.earliestStart).format('HH:mm:ss'),
      latestFinish: moment(formData.latestFinish).format('HH:mm:ss'),
    };

    if ($scope.props.isAdd) {
      StaffService.addOutsourcingOrganisation(toSend)
        .then(({ data }) => {
          const { ids: [id] } = data;

          StaffService.updateOutsourcingOrganisationTagAssociations(id, { tags })
            .then(() => onApiSuccess())
            .catch(({ status }) => onApiError(status));
        })
        .catch(({ status }) => onApiError(status));
    } else {
      StaffService.updateOutsourcingOrganisation(organisation.id, toSend)
        .then(() => {
          StaffService.updateOutsourcingOrganisationTagAssociations(organisation.id, { tags })
            .then(() => onApiSuccess())
            .catch(({ status }) => onApiError(status));
        })
        .catch(({ status }) => onApiError(status));
    }
  };
};
