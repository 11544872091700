'use strict';
var controllers = require('../module');

controllers.controller('AddEventAttendanceModalCtrl', ['$scope', '$debounce', '$uibModalInstance', 'AttendanceService',
    'CommonService', 'SessionService', 'AttendanceCommon', 'AlertService', 'data', '$translate',
    function ($scope, $debounce, $uibModalInstance, AttendanceService, CommonService, SessionService,
              AttendanceCommon, AlertService, data, $translate) {
        var recordBreaks = SessionService.getSetting('attendance.recordBreaks') === 'true';
        var breakDurations = [15, 20, 30, 45, 60, 90, 120, 180, 240];
        var timeAdjustOptions = [1, 5, 15, 30, 60];
        var preventFutureDateTolerance = 12; // hours

        var labelTranslations = $translate.instant([
            'ATTENDANCE.ADD_EVENT_MODAL.CLOCK_IN',
            'ATTENDANCE.ADD_EVENT_MODAL.CLOCK_OUT',
            'ATTENDANCE.ADD_EVENT_MODAL.BREAK_ON',
            'ATTENDANCE.ADD_EVENT_MODAL.BREAK_OFF',
            'ATTENDANCE.ADD_EVENT_MODAL.ERROR_ACTIONING',
            'ATTENDANCE.ADD_EVENT_MODAL.BREAK_MINUTES',
        ]);

        $scope.eventTypes = [
            { id: 1, label: labelTranslations['ATTENDANCE.ADD_EVENT_MODAL.CLOCK_IN'] },
            { id: 2, label: labelTranslations['ATTENDANCE.ADD_EVENT_MODAL.CLOCK_OUT'] },
            { id: 3, label: labelTranslations['ATTENDANCE.ADD_EVENT_MODAL.BREAK_ON'] },
            { id: 4, label: labelTranslations['ATTENDANCE.ADD_EVENT_MODAL.BREAK_OFF'] },
        ];

        $scope.eventTypesById = {};
        $scope.eventTypes.forEach(function (e) { $scope.eventTypesById[e.id] = e; });

        function getDefaultDate() {
            if (data.date) {
                return data.date.clone();
            }

            if (data.dateOffset) {
                return data.dateOffset.clone().add(1, 'minute');
            }

            return moment();
        }

        $scope.props = {
            loadingData: false,
            priorEvent: null,
            priorEventConflict: false,
            dateInFutureError: false,
            eventTypes: [],
            selectedDate: getDefaultDate(),
            breakDurations: [],
            selectedBreakDuration: breakDurations[0],
            selectedEventType: data.eventType || 1,
            timeAdjustOptions: [],
        };

        breakDurations.forEach(function (duration) {
            $scope.props.breakDurations.push({
                id: duration,
                label: duration + ' ' + labelTranslations['ATTENDANCE.ADD_EVENT_MODAL.BREAK_MINUTES'],
            });
        });

        timeAdjustOptions.forEach(function (duration) {
            $scope.props.timeAdjustOptions.unshift({
                id: -duration,
                label: '-' + duration,
            });

            $scope.props.timeAdjustOptions.push({
               id: duration,
               label: '+' + duration,
            });
        });

        $scope.onDateChange = function (newDate) {
            $scope.props.selectedDate = newDate;
            $scope.loadPriorEvents();
        };

        $scope.adjustTime = function (mins) {
            $scope.props.selectedDate = $scope.props.selectedDate.clone().add(mins, 'minutes');
            $debounce($scope.loadPriorEvents, 500);
        };

        $scope.loadPriorEvents = function () {
            $scope.props.selectedDate.seconds(0);
            $scope.props.dateInFutureError = $scope.props.selectedDate.clone()
                .subtract(preventFutureDateTolerance, 'hours').isAfter();

            if ($scope.props.dateInFutureError) {
                return;
            }

            if ($scope.props.loadingData) {
                return;
            }

            $scope.props.loadingData = true;

            var getDataParams = {
                userId: data.userId,
                end: $scope.props.selectedDate.clone().seconds(59).format(),
                limit: 1,
                page: 1,
                sortOrder: 'eventDateDesc',
            };

            AttendanceService.getEvents(getDataParams)
                .success(function (result) {
                    if (result.events.length) {
                        var event = result.events[0];

                        $scope.props.priorEvent = event;
                        $scope.props.priorEventConflict = moment(event.date).isSame($scope.props.selectedDate, 'minute');

                        var eventTypes = [];

                        switch (event.eventType) {
                            case 1:
                            case 4:
                            default:
                                eventTypes.push($scope.eventTypesById[2]);

                                if (recordBreaks) {
                                    eventTypes.push($scope.eventTypesById[3]);
                                }
                                break;
                            case 2:
                                eventTypes.push($scope.eventTypesById[1]);
                                break;
                            case 3:
                                eventTypes.push($scope.eventTypesById[4]);
                                break;
                        }

                        $scope.props.eventTypes = eventTypes;
                    } else {
                        $scope.props.priorEvent = null;
                        $scope.props.priorEventConflict = false;
                        $scope.props.eventTypes = [$scope.eventTypesById[1]];
                    }

                    var selectedEventTypeValid = $scope.props.eventTypes.find(function (e) {
                        return e.id === $scope.props.selectedEventType;
                    });

                    if (!selectedEventTypeValid) {
                        $scope.props.selectedEventType = $scope.props.eventTypes[0].id;
                    }

                    $scope.props.loadingData = false;
                });
        };

        $scope.loadPriorEvents();

        $scope.submit = function () {
            $scope.props.loadingData = true;

            var events = [{
                date: $scope.props.selectedDate.format(),
                eventType: $scope.props.selectedEventType,
            }];

            if ($scope.props.selectedEventType === 3 && $scope.props.selectedBreakDuration) {
                events.push({
                    date: $scope.props.selectedDate.clone().add($scope.props.selectedBreakDuration, 'minutes').format(),
                    eventType: 4,
                });
            }

            var fields = {
                userId: data.userId,
                events: events,
            };

            AttendanceService.addEvent(fields)
                .success(function (result) {
                    $uibModalInstance.close({
                        events: result.events,
                    });
                })
                .error(function (error, status) {
                    $scope.props.loadingData = false;

                    if (status === 403) {
                        return;
                    }

                    if (status === 400) {
                        AttendanceCommon.displayValidationError(error);
                        return;
                    }

                    AlertService.add('danger', labelTranslations['ATTENDANCE.ADD_EVENT_MODAL.ERROR_ACTIONING']);
                });
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);
