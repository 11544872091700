module.exports = (
  $scope,
  $translate,
  $window,
  AvailabilityService,
  AlertService,
  BetaService,
  StaffCommon,
  RotaService,
) => {
  'ngInject';

  const isoDateFormat = 'YYYY-MM-DD';

  const translations = $translate.instant([
    'ABSENCE.EDIT.ALERT_BULK_SHIFT_EDIT_FAILURE',
    'ABSENCE.EDIT.ALERT_SINGLE_SHIFT_DELETE_FAILURE',
  ]);

  $scope.concurrentAbsenceParams = {
    'approvalStates[]': [1],
    'currentEntityIds[]': [$scope.absence.header.user.appointment.entityId],
    'currentStaffGroupIds[]': [],
    'absenceTypeIds[]': [],
    dateContext: 'overlapping',
    start: $scope.props.selectedDateStart.format(),
    end: $scope.props.selectedDateEnd.format(),
    includeCancelled: false,
    limit: 15,
    page: 1,
    sortOrder: 'startDateAsc',
  };

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.concurrentAbsenceParams['currentEntityIds[]'] = entityIds.length ? entityIds : undefined;
    $scope.loadConcurrentAbsence();
  };

  $scope.onStaffGroupFilter = (selectedOptions) => {
    const [option] = selectedOptions;
    $scope.concurrentAbsenceParams['currentStaffGroupIds[]'] = option || [];
    $scope.loadConcurrentAbsence();
  };

  $scope.onAbsenceTypeFilter = (selectedOptions) => {
    const [option] = selectedOptions;
    $scope.concurrentAbsenceParams['absenceTypeIds[]'] = option || [];
    $scope.loadConcurrentAbsence();
  };

  $scope.loadConcurrentAbsence = () => {
    AvailabilityService.getAbsencePaginated($scope.concurrentAbsenceParams)
      .then(({ data }) => {
        $scope.props.overlappingAbsence = data.absence
          .filter(({ id }) => id !== $scope.props.headerId)
          .map((row) => ({
            ...row,
            scopeLabel: StaffCommon.getLabelFromScope(row.scope),
          }));
      });
  };

  $scope.loadOverlappingShifts = () => {
    const {
      selectedDateStart,
      selectedDateEnd,
    } = $scope.props;

    const params = {
      startDateMin: selectedDateStart.format(),
      startDateMax: selectedDateEnd.format(),
      published: true,
      'userIds[]': [$scope.absence.header.userId],
      'originEntityIds[]': [$scope.absence.header.user.appointment.entityId],
      limit: 15,
      page: 1,
      sortOrder: 'startDateAsc',
    };

    RotaService.getShiftsPaginated(params)
      .then(({ data }) => {
        $scope.props.overlappingShifts = data.shifts;
      });
  };

  $scope.loadNotableEvents = () => {
    const {
      selectedDateStart: start,
      selectedDateEnd: end,
    } = $scope.props;

    const params = {
      overlapsPeriod: `${start.format(isoDateFormat)}/${end.format(isoDateFormat)}`,
      'entityIds[]': [$scope.absence.header.user.appointment.entityId],
      limit: 15,
      page: 1,
      sortOrder: 'startDateAsc',
    };

    AvailabilityService.getNotableEvents(params)
      .then(({ data }) => {
        $scope.props.notableEvents = data.results;
      });
  };

  $scope.loadConcurrentAbsence();
  $scope.loadOverlappingShifts();
  $scope.loadNotableEvents();

  $scope.shiftSelectionProps = {
    countSelected: 0,
    actionInProgress: false,
    allSelected: false,
  };

  $scope.toggleAllShiftsSelected = () => {
    const { allSelected } = $scope.shiftSelectionProps;

    $scope.props.overlappingShifts.forEach((shift) => {
      shift.isSelected = allSelected;
    });

    $scope.shiftSelectionProps.countSelected = allSelected
      ? $scope.props.overlappingShifts.length : 0;
  };

  $scope.toggleShiftSelected = () => {
    const shiftsSelected = $scope.props.overlappingShifts
      .reduce((acc, shift) => (acc + (shift.isSelected ? 1 : 0)), 0);

    $scope.shiftSelectionProps.countSelected = shiftsSelected;
    $scope.shiftSelectionProps.allSelected = shiftsSelected === $scope
      .props.overlappingShifts.length;
  };

  $scope.bulkActionShifts = (operation) => {
    const { overlappingShifts } = $scope.props;
    const { countSelected, actionInProgress } = $scope.shiftSelectionProps;

    if (!countSelected || actionInProgress) {
      return;
    }

    const alertMessage = $translate.instant('ABSENCE.EDIT.ALERT_BULK_SHIFT_CONFIRM', {
      count: countSelected,
    });

    if (!$window.confirm(alertMessage)) {
      return;
    }

    $scope.shiftSelectionProps.actionInProgress = true;

    const shiftUserTuples = overlappingShifts.filter((shift) => shift.isSelected).map((shift) => {
      shift.actionInProgress = true;
      return [shift.id, shift.userId];
    });

    const data = {
      shiftUserTuples,
      ignoreValidation: true,
      operation: operation === 'delete' ? 1 : 2,
    };

    RotaService.bulkUpdateShifts(data)
      .then(() => {
        $scope.props.overlappingShifts = overlappingShifts.filter(({ isSelected }) => !isSelected);

        $scope.shiftSelectionProps.allSelected = false;
        $scope.shiftSelectionProps.countSelected = 0;
        $scope.shiftSelectionProps.actionInProgress = false;
      })
      .catch(() => {
        overlappingShifts.forEach((shift) => {
          shift.actionInProgress = false;
        });

        $scope.shiftSelectionProps.actionInProgress = false;
        AlertService.add('danger', translations['ABSENCE.EDIT.ALERT_BULK_SHIFT_EDIT_FAILURE']);
      });
  };

  $scope.deleteShift = (row, index) => {
    if (row.actionInProgress || $scope.shiftSelectionProps.actionInProgress) {
      return;
    }

    row.actionInProgress = true;
    $scope.shiftSelectionProps.actionInProgress = true;

    RotaService.deleteShift(row.id, row.userId, true)
      .then(() => {
        $scope.props.overlappingShifts.splice(index, 1);
        $scope.shiftSelectionProps.actionInProgress = false;
      })
      .catch(() => {
        row.actionInProgress = false;
        $scope.shiftSelectionProps.actionInProgress = false;
        AlertService.add('danger', translations['ABSENCE.EDIT.ALERT_SINGLE_SHIFT_DELETE_FAILURE']);
      });
  };

  $scope.goToEditor = (shift) => {
    if (shift.actionInProgress || $scope.shiftSelectionProps.actionInProgress) {
      return;
    }

    BetaService.redirect(BetaService.Features.ROTA_EDITOR, {
      startDate: moment(shift.start).startOf('week').format(isoDateFormat),
      entityId: shift.originEntityId,
    });
  };
};
