module.exports = (
  $scope,
  $translate,
  $uibModalInstance,
  StaffService,
  StaffCommon,
  EnvironmentDataService,
  AlertService,
  data,
) => {
  'ngInject';

  const {
    employment,
  } = data;

  const translations = $translate.instant([
    'STAFF.VIEW.EMPLOYMENT.LEAVER_FORM.ALERT_SUCCESS',
    'STAFF.VIEW.EMPLOYMENT.LEAVER_FORM.ERROR_500',
  ]);

  $scope.props = {
    actionInProgress: true,
    dateEnd: moment(),
    formData: {
      dateEnd: moment(),
      leaverRehire: true,
    },
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.LeaverReason)
    .then(({ data }) => {
      $scope.props.leaverReasonList = data
        .map(({ id, title, deleted }) => ({ id, label: title, deleted }));

      $scope.props.actionInProgress = false;
    });

  $scope.close = () => $uibModalInstance.dismiss();

  $scope.onDateEndChanged = (date) => {
    $scope.props.formData.dateEnd = date.clone();
  };

  $scope.save = () => {
    $scope.props.actionInProgress = true;

    const {
      id,
      userId,
    } = employment;

    const fields = {
      ...$scope.props.formData,
      dateEnd: $scope.props.formData.dateEnd.clone().add(1, 'day').format('YYYY-MM-DD'),
    };

    StaffService.updateEmployment(id, userId, fields)
      .then(({ data }) => {
        AlertService.add('success', translations['STAFF.VIEW.EMPLOYMENT.LEAVER_FORM.ALERT_SUCCESS']);

        $uibModalInstance.close({
          action: 'update',
          response: data,
          request: fields,
        });
      })
      .catch(({ status, data }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.EMPLOYMENT.LEAVER_FORM.ERROR_500']);
          return;
        }

        if (status === 400) {
          const modal = StaffCommon.onEmploymentValidationResponse(data.validationResult);

          modal.result.then((result) => {
            if (result === true) {
              $scope.save();
            }
          });
        }
      });
  };
};
