const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/dashboard.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/other/editPreferencesModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/other/manageSubscriptionsModal.html');
const autoNgTemplateLoaderTemplate4 = require('/home/circleci/project/app/htdocs/views/sign-out.html');
const autoNgTemplateLoaderTemplate5 = require('/home/circleci/project/app/htdocs/views/availability/overview.html');
const autoNgTemplateLoaderTemplate6 = require('/home/circleci/project/app/htdocs/views/availability/availability.html');
const autoNgTemplateLoaderTemplate7 = require('/home/circleci/project/app/htdocs/views/availability/absence.html');
const autoNgTemplateLoaderTemplate8 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/viewBookings.html');
const autoNgTemplateLoaderTemplate9 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/viewAllowances.html');
const autoNgTemplateLoaderTemplate10 = require('/home/circleci/project/app/htdocs/views/staff/view/documents/personal.html');
const autoNgTemplateLoaderTemplate11 = require('/home/circleci/project/app/htdocs/views/rotamanagement/dashboard/index.html');
const autoNgTemplateLoaderTemplate12 = require('/home/circleci/project/app/htdocs/views/settings/index.html');
const autoNgTemplateLoaderTemplate13 = require('/home/circleci/project/app/htdocs/views/settings/indexLanding.html');
const autoNgTemplateLoaderTemplate14 = require('/home/circleci/project/app/htdocs/views/settings/rotaTemplates/index.html');
const autoNgTemplateLoaderTemplate15 = require('/home/circleci/project/app/htdocs/views/settings/rotaTemplates/editTemplate.html');
const autoNgTemplateLoaderTemplate16 = require('/home/circleci/project/app/htdocs/views/settings/shiftLocations/index.html');
const autoNgTemplateLoaderTemplate17 = require('/home/circleci/project/app/htdocs/views/settings/shiftTypes/index.html');
const autoNgTemplateLoaderTemplate18 = require('/home/circleci/project/app/htdocs/views/settings/tagsGroups/index.html');
const autoNgTemplateLoaderTemplate19 = require('/home/circleci/project/app/htdocs/views/settings/outsourcing/index.html');
const autoNgTemplateLoaderTemplate20 = require('/home/circleci/project/app/htdocs/views/settings/absenceTypes/index.html');
const autoNgTemplateLoaderTemplate21 = require('/home/circleci/project/app/htdocs/views/settings/documentCategories/index.html');
const autoNgTemplateLoaderTemplate22 = require('/home/circleci/project/app/htdocs/views/settings/documentTemplates/index.html');
const autoNgTemplateLoaderTemplate23 = require('/home/circleci/project/app/htdocs/views/settings/trustedDevices/index.html');
const autoNgTemplateLoaderTemplate24 = require('/home/circleci/project/app/htdocs/views/settings/payElements/index.html');
const autoNgTemplateLoaderTemplate25 = require('/home/circleci/project/app/htdocs/views/settings/restBreaks/index.html');
const autoNgTemplateLoaderTemplate26 = require('/home/circleci/project/app/htdocs/views/settings/shiftRoutines/index.html');
const autoNgTemplateLoaderTemplate27 = require('/home/circleci/project/app/htdocs/views/settings/payRules/index.html');
const autoNgTemplateLoaderTemplate28 = require('/home/circleci/project/app/htdocs/views/settings/permissions/index.html');
const autoNgTemplateLoaderTemplate29 = require('/home/circleci/project/app/htdocs/views/settings/budgeting/index.html');
const autoNgTemplateLoaderTemplate30 = require('/home/circleci/project/app/htdocs/views/settings/budgeting/byEntity.html');
const autoNgTemplateLoaderTemplate31 = require('/home/circleci/project/app/htdocs/views/settings/budgeting/byPeriod.html');
const autoNgTemplateLoaderTemplate32 = require('/home/circleci/project/app/htdocs/views/settings/integrations/sevenRooms/index.html');
const autoNgTemplateLoaderTemplate33 = require('/home/circleci/project/app/htdocs/views/settings/integrations/flow/index.html');
const autoNgTemplateLoaderTemplate34 = require('/home/circleci/project/app/htdocs/views/settings/integrations/xero/index.html');
const autoNgTemplateLoaderTemplate35 = require('/home/circleci/project/app/htdocs/views/settings/integrations/lightspeedKSeries/index.html');
const autoNgTemplateLoaderTemplate36 = require('/home/circleci/project/app/htdocs/views/settings/integrations/clover/index.html');
const autoNgTemplateLoaderTemplate37 = require('/home/circleci/project/app/htdocs/views/staff/index.html');
const autoNgTemplateLoaderTemplate38 = require('/home/circleci/project/app/htdocs/views/staff/overview/index.html');
const autoNgTemplateLoaderTemplate39 = require('/home/circleci/project/app/htdocs/views/staff/view/index.html');
const autoNgTemplateLoaderTemplate40 = require('/home/circleci/project/app/htdocs/views/staff/view/account.html');
const autoNgTemplateLoaderTemplate41 = require('/home/circleci/project/app/htdocs/views/staff/view/personal.html');
const autoNgTemplateLoaderTemplate42 = require('/home/circleci/project/app/htdocs/views/staff/view/emergencyContacts/index.html');
const autoNgTemplateLoaderTemplate43 = require('/home/circleci/project/app/htdocs/views/staff/view/employment/index.html');
const autoNgTemplateLoaderTemplate44 = require('/home/circleci/project/app/htdocs/views/staff/view/pay/index.html');
const autoNgTemplateLoaderTemplate45 = require('/home/circleci/project/app/htdocs/views/staff/view/pay/viewHistory.html');
const autoNgTemplateLoaderTemplate46 = require('/home/circleci/project/app/htdocs/views/staff/view/pay/viewTronc.html');
const autoNgTemplateLoaderTemplate47 = require('/home/circleci/project/app/htdocs/views/staff/view/pay/viewFinancials.html');
const autoNgTemplateLoaderTemplate48 = require('/home/circleci/project/app/htdocs/views/staff/view/schedule.html');
const autoNgTemplateLoaderTemplate49 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/index.html');
const autoNgTemplateLoaderTemplate50 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/viewBookings.html');
const autoNgTemplateLoaderTemplate51 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/viewAllowances.html');
const autoNgTemplateLoaderTemplate52 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/viewSettings.html');
const autoNgTemplateLoaderTemplate53 = require('/home/circleci/project/app/htdocs/views/availability/availability.html');
const autoNgTemplateLoaderTemplate54 = require('/home/circleci/project/app/htdocs/views/staff/view/shiftRoutines.html');
const autoNgTemplateLoaderTemplate55 = require('/home/circleci/project/app/htdocs/views/staff/view/documents/index.html');
const autoNgTemplateLoaderTemplate56 = require('/home/circleci/project/app/htdocs/views/staff/view/notes/index.html');
const autoNgTemplateLoaderTemplate57 = require('/home/circleci/project/app/htdocs/views/staff/view/attendance/index.html');
const autoNgTemplateLoaderTemplate58 = require('/home/circleci/project/app/htdocs/views/staff/requests/index.html');
const autoNgTemplateLoaderTemplate59 = require('/home/circleci/project/app/htdocs/views/staff/requests/absence.html');
const autoNgTemplateLoaderTemplate60 = require('/home/circleci/project/app/htdocs/views/staff/requests/swaps.html');
const autoNgTemplateLoaderTemplate61 = require('/home/circleci/project/app/htdocs/views/staff/requests/documents/index.html');
const autoNgTemplateLoaderTemplate62 = require('/home/circleci/project/app/htdocs/views/staff/requests/newJoiners/index.html');
const autoNgTemplateLoaderTemplate63 = require('/home/circleci/project/app/htdocs/views/staff/absence/absence.html');
const autoNgTemplateLoaderTemplate64 = require('/home/circleci/project/app/htdocs/views/reports/indexList.html');
const autoNgTemplateLoaderTemplate65 = require('/home/circleci/project/app/htdocs/views/reports/troncStatus/index.html');
const autoNgTemplateLoaderTemplate66 = require('/home/circleci/project/app/htdocs/views/reports/currentlyClockedIn/index.html');
const autoNgTemplateLoaderTemplate67 = require('/home/circleci/project/app/htdocs/views/reports/rotaProgress/index.html');
const autoNgTemplateLoaderTemplate68 = require('/home/circleci/project/app/htdocs/views/reports/signOffProgress/index.html');
const autoNgTemplateLoaderTemplate69 = require('/home/circleci/project/app/htdocs/views/reports/payrollExport/index.html');
const autoNgTemplateLoaderTemplate70 = require('/home/circleci/project/app/htdocs/views/reports/labourBreakdown/index.html');
const autoNgTemplateLoaderTemplate71 = require('/home/circleci/project/app/htdocs/views/reports/labourBreakdownAsyncFileExport/index.html');
const autoNgTemplateLoaderTemplate72 = require('/home/circleci/project/app/htdocs/views/reports/payrollAsyncFileExport/index.html');
const autoNgTemplateLoaderTemplate73 = require('/home/circleci/project/app/htdocs/views/reports/costControlRawDataAsyncFileExport/index.html');
const autoNgTemplateLoaderTemplate74 = require('/home/circleci/project/app/htdocs/views/reports/contractualObligationSummary/index.html');
const autoNgTemplateLoaderTemplate75 = require('/home/circleci/project/app/htdocs/views/reports/workingTimeViolations/index.html');
const autoNgTemplateLoaderTemplate76 = require('/home/circleci/project/app/htdocs/views/reports/documentSignatures/index.html');
const autoNgTemplateLoaderTemplate77 = require('/home/circleci/project/app/htdocs/views/reports/staffTurnover/index.html');
const autoNgTemplateLoaderTemplate78 = require('/home/circleci/project/app/htdocs/views/reports/anniversary/index.html');
const autoNgTemplateLoaderTemplate79 = require('/home/circleci/project/app/htdocs/views/reports/employmentChanges/index.html');
const autoNgTemplateLoaderTemplate80 = require('/home/circleci/project/app/htdocs/views/reports/absenceExport/index.html');
const autoNgTemplateLoaderTemplate81 = require('/home/circleci/project/app/htdocs/views/reports/allowanceExport/index.html');
const autoNgTemplateLoaderTemplate82 = require('/home/circleci/project/app/htdocs/views/reports/untakenHolidayCost/index.html');
const autoNgTemplateLoaderTemplate83 = require('/home/circleci/project/app/htdocs/views/reports/costControlRawData/index.html');
const autoNgTemplateLoaderTemplate84 = require('/home/circleci/project/app/htdocs/views/reports/newHireTurnover/index.html');
const autoNgTemplateLoaderTemplate85 = require('/home/circleci/project/app/htdocs/views/reports/absenceDaysExport/index.html');
const autoNgTemplateLoaderTemplate86 = require('/home/circleci/project/app/htdocs/views/reports/shiftTypeStreamBreakdown/index.html');
const autoNgTemplateLoaderTemplate87 = require('/home/circleci/project/app/htdocs/views/reports/documentPresence/index.html');
const autoNgTemplateLoaderTemplate88 = require('/home/circleci/project/app/htdocs/views/reports/documentsExport/index.html');
const autoNgTemplateLoaderTemplate89 = require('/home/circleci/project/app/htdocs/views/reports/attendanceBreakdown/index.html');
const autoNgTemplateLoaderTemplate90 = require('/home/circleci/project/app/htdocs/views/reports/lateness/index.html');
const autoNgTemplateLoaderTemplate91 = require('/home/circleci/project/app/htdocs/views/reports/xero/payRuns.html');
const autoNgTemplateLoaderTemplate92 = require('/home/circleci/project/app/htdocs/views/reports/xero/payRun.html');
const autoNgTemplateLoaderTemplate93 = require('/home/circleci/project/app/htdocs/views/attendance/overview.html');
const autoNgTemplateLoaderTemplate94 = require('/home/circleci/project/app/htdocs/views/attendance/issues.html');
const autoNgTemplateLoaderTemplate95 = require('/home/circleci/project/app/htdocs/views/absence/request.html');
const autoNgTemplateLoaderTemplate96 = require('/home/circleci/project/app/htdocs/views/absence/edit/index.html');
const autoNgTemplateLoaderTemplate97 = require('/home/circleci/project/app/htdocs/views/absence/edit/dates.html');
const autoNgTemplateLoaderTemplate98 = require('/home/circleci/project/app/htdocs/views/absence/edit/review.html');
const autoNgTemplateLoaderTemplate99 = require('/home/circleci/project/app/htdocs/views/absence/edit/documents.html');
const autoNgTemplateLoaderTemplate100 = require('/home/circleci/project/app/htdocs/views/absence/edit/calendar.html');

const enTranslations = require('../translations/en-gb');
const deTranslations = require('../translations/de-de');
const itTranslations = require('../translations/it-it');
const esTranslations = require('../translations/es-es');
const frTranslations = require('../translations/fr-fr');
const nlTranslations = require('../translations/nl-nl');
const elTranslations = require('../translations/el-gr');
const sqTranslations = require('../translations/sq-al');
const roTranslations = require('../translations/ro-ro');

/* eslint-disable global-require */
module.exports = (app) => {
  app.config(($stateProvider, $urlRouterProvider, $locationProvider, $translateProvider) => {
    $locationProvider.html5Mode(true).hashPrefix('!');
    $translateProvider.useSanitizeValueStrategy('escapeParameters');
    $translateProvider
      .translations('en-gb', enTranslations)
      .translations('de-de', deTranslations)
      .translations('it-it', itTranslations)
      .translations('es-es', esTranslations)
      .translations('fr-fr', frTranslations)
      .translations('nl-nl', nlTranslations)
      .translations('el-gr', elTranslations)
      .translations('sq-al', sqTranslations)
      .translations('ro-ro', roTranslations)
      .preferredLanguage('en-gb');

    $urlRouterProvider
      .rule(($injector, $location) => {
        const path = $location.url();

        // check to see if the path has a trailing slash
        if (path[path.length - 1] === '/') {
          return path.replace(/\/$/, '');
        }

        if (path.indexOf('/?') > 0) {
          return path.replace('/?', '?');
        }

        return false;
      })
      .when('/authReturn', ($location, SessionService, AuthService) => {
        SessionService.destroy();
        AuthService.handleAuthResponse();
      })
      .otherwise('/');

    const legacyHqPermissionCheck = {
      redirect: (SessionService, AlertService, $q) => {
        'ngInject';

        if (!SessionService.isPermissioned('hq')) {
          AlertService.add('warning', 'You don\'t have permission to access that.', 'AuthService');
          return $q.reject();
        }
      },
    };

    $stateProvider
      .state('dashboard', {
        url: '/',
        pageTitle: 'Dashboard',
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'DashboardCtrl',
      })

      // TODO: Remove once 'Edit Preferences' implemented in WFM - Allows a user to access edit preferences whilst on WFM
      .state('editPreferencesDirect', {
        url: '/edit-preferences',
        onEnter($uibModal) {
          $uibModal.open({
            templateUrl: autoNgTemplateLoaderTemplate2,
            controller: 'EditPreferencesModalCtrl',
          }).closed.then(() => {
            window.location.replace('/');
          });
        },
      })

      // TODO: Remove once 'Manage Subscriptions' implemented in WFM - Allows a user to access manage subscriptions whilst on WFM
      .state('manageSubscriptions', {
        url: '/manage-subscriptions',
        onEnter($uibModal) {
          $uibModal.open({
            templateUrl: autoNgTemplateLoaderTemplate3,
            controller: 'ManageSubscriptionsModalCtrl',
          }).closed.then(() => {
            window.location.replace('/');
          });
        },
      })

      // TODO: Remove once 'Sign out' implemented properly in WFM - This ensure user is signed out in both app and WFM.
      .state('signOutDirect', {
        url: '/sign-out',
        pageTitle: 'Sign Out',
        templateUrl: autoNgTemplateLoaderTemplate4,
        onEnter(AuthService) {
          AuthService.signOut();
        },
      })

      .state('personal', {
        url: '/personal',
        template: '<ui-view/>',
        abstract: true,
      })
      .state('personal.schedule', {
        url: '/schedule',
        pageTitle: 'My Schedule',
        templateUrl: autoNgTemplateLoaderTemplate5,
        controller: 'OverviewScheduleCtrl',
      })
      .state('personal.availability', {
        url: '/availability',
        pageTitle: 'Availability',
        templateUrl: autoNgTemplateLoaderTemplate6,
        controller: 'AvailabilityScheduleCtrl',
      })

      .state('personal.absence', {
        url: '/absence',
        abstract: true,
        templateUrl: autoNgTemplateLoaderTemplate7,
        controller: 'AbsenceUserCtrl',
      })
      .state('personal.absence.bookings', {
        url: '/bookings',
        pageTitle: 'Absence bookings',
        templateUrl: autoNgTemplateLoaderTemplate8,
        controller: require('./controllers/staff/view/absence/viewBookings'),
      })
      .state('personal.absence.allowances', {
        url: '/allowances',
        pageTitle: 'Absence allowances',
        templateUrl: autoNgTemplateLoaderTemplate9,
        controller: require('./controllers/staff/view/absence/viewAllowances'),
      })
      .state('personal.documents', {
        url: '/documents',
        pageTitle: 'My Documents',
        templateUrl: autoNgTemplateLoaderTemplate10,
        controller: require('./controllers/staff/view/documents/personal'),
      })

      .state('rota', {
        url: '/rota',
        template: '<ui-view/>',
        abstract: true,
      })
      .state('rota.overview', {
        url: '',
        pageTitle: 'Rota Dashboard',
        templateUrl: autoNgTemplateLoaderTemplate11,
        controller: 'DashboardRmCtrl',
      })

      .state('settings', {
        url: '/settings',
        abstract: true,
        templateUrl: autoNgTemplateLoaderTemplate12,
        controller: 'SettingsCtrl',
      })
      .state('settings.index', {
        url: '',
        pageTitle: 'Settings',
        templateUrl: autoNgTemplateLoaderTemplate13,
      })

      .state('settings.rotaTemplates', {
        url: '/rota-templates',
        template: '<ui-view/>',
        abstract: true,
      })
      .state('settings.rotaTemplates.index', {
        url: '',
        pageTitle: 'Rota Templates',
        templateUrl: autoNgTemplateLoaderTemplate14,
        controller: 'TemplatesRmCtrl',
      })
      .state('settings.rotaTemplates.edit', {
        url: '/edit/:templateId',
        pageTitle: 'Edit rota template',
        templateUrl: autoNgTemplateLoaderTemplate15,
        controller: 'EditTemplateRmCtrl',
      })
      .state('settings.shiftLocations', {
        url: '/shift-locations',
        pageTitle: 'Shift Locations',
        templateUrl: autoNgTemplateLoaderTemplate16,
        controller: 'LocationsRmCtrl',
      })
      .state('settings.shiftTypes', {
        url: '/shift-types',
        pageTitle: 'Shift Types',
        templateUrl: autoNgTemplateLoaderTemplate17,
        controller: 'ShiftTypesRmCtrl',
      })
      .state('settings.tagsGroups', {
        url: '/tags-groups',
        pageTitle: 'Tags and Groups',
        templateUrl: autoNgTemplateLoaderTemplate18,
        controller: 'TagsGroupsStaffCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.outsourcing', {
        url: '/outsourcing',
        pageTitle: 'Outsourcing',
        templateUrl: autoNgTemplateLoaderTemplate19,
        controller: require('./controllers/settings/outsourcing'),
      })
      .state('settings.absenceTypes', {
        url: '/absence-types',
        pageTitle: 'Absence Types',
        templateUrl: autoNgTemplateLoaderTemplate20,
        controller: require('./controllers/settings/absenceTypes'),
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.documentCategories', {
        url: '/document-categories',
        pageTitle: 'Document Categories',
        templateUrl: autoNgTemplateLoaderTemplate21,
      })
      .state('settings.documentTemplates', {
        url: '/document-templates',
        pageTitle: 'Document Templates',
        templateUrl: autoNgTemplateLoaderTemplate22,
        controller: require('./controllers/settings/documentTemplates'),
      })
      .state('settings.trustedDevices', {
        url: '/trusted-devices',
        pageTitle: 'Trusted Devices',
        templateUrl: autoNgTemplateLoaderTemplate23,
        controller: require('./controllers/settings/trustedDevices'),
      })
      .state('settings.payElements', {
        url: '/pay-elements',
        pageTitle: 'Pay Elements',
        templateUrl: autoNgTemplateLoaderTemplate24,
        controller: 'SettingsPayElementTypesCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.restBreaks', {
        url: '/rest-breaks',
        pageTitle: 'Rest Breaks',
        templateUrl: autoNgTemplateLoaderTemplate25,
        controller: 'SettingsRestBreaksCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.shiftRoutines', {
        url: '/shift-routines',
        pageTitle: 'Shift Routines',
        templateUrl: autoNgTemplateLoaderTemplate26,
        controller: 'SettingsShiftRoutinesCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.payRules', {
        url: '/pay-rules?edit',
        pageTitle: 'Pay Rules',
        templateUrl: autoNgTemplateLoaderTemplate27,
        controller: 'SettingsPayRulesCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.permissions', {
        url: '/permissions',
        pageTitle: 'Permissions',
        templateUrl: autoNgTemplateLoaderTemplate28,
        controller: 'PermissionsSettingsCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.budgeting', {
        url: '/budgeting',
        template: '<ui-view/>',
        abstract: true,
      })
      .state('settings.budgeting.index', {
        url: '',
        pageTitle: 'Budgeting',
        templateUrl: autoNgTemplateLoaderTemplate29,
        controller: 'BudgetingCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.budgeting.byEntity', {
        url: '/:entity',
        pageTitle: 'Budgeting',
        templateUrl: autoNgTemplateLoaderTemplate30,
        controller: 'BudgetingByEntityCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.budgeting.byPeriod', {
        url: '/:entity/:period',
        pageTitle: 'Budgeting',
        templateUrl: autoNgTemplateLoaderTemplate31,
        controller: 'BudgetingByPeriodCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.sevenrooms', {
        url: '/sevenrooms',
        pageTitle: 'SevenRooms',
        templateUrl: autoNgTemplateLoaderTemplate32,
        controller: 'SettingsIntegrationSevenRoomsCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.flow', {
        url: '/flow',
        pageTitle: 'Flow',
        templateUrl: autoNgTemplateLoaderTemplate33,
        controller: 'SettingsIntegrationFlowCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.xero', {
        url: '/xero',
        pageTitle: 'Xero',
        templateUrl: autoNgTemplateLoaderTemplate34,
        controller: 'SettingsIntegrationXeroCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.lightspeed', {
        url: '/lightspeed-k-series',
        pageTitle: 'Lightspeed K Series',
        templateUrl: autoNgTemplateLoaderTemplate35,
        controller: 'SettingsIntegrationLightspeedKSeriesCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('settings.clover', {
        url: '/clover',
        pageTitle: 'Clover',
        templateUrl: autoNgTemplateLoaderTemplate36,
        controller: require('./controllers/settings/integrations/clover/index'),
        resolve: legacyHqPermissionCheck,
      })

      .state('staff', {
        url: '/staff',
        templateUrl: autoNgTemplateLoaderTemplate37,
        controller: 'IndexStaffCtrl',
        abstract: true,
      })
      .state('staff.overview', {
        url: '?filter&columns',
        pageTitle: 'Staff',
        templateUrl: autoNgTemplateLoaderTemplate38,
        controller: require('./controllers/staff/overview'),
      })

      .state('staff.view', {
        url: '/view/:id',
        abstract: true,
        templateUrl: autoNgTemplateLoaderTemplate39,
        controller: require('./controllers/staff/view'),
      })
      .state('staff.view.account', {
        url: '/account',
        pageTitle: 'Account',
        templateUrl: autoNgTemplateLoaderTemplate40,
        controller: require('./controllers/staff/view/account'),
      })
      .state('staff.view.personal', {
        url: '/personal',
        pageTitle: 'Personal Details',
        templateUrl: autoNgTemplateLoaderTemplate41,
        controller: require('./controllers/staff/view/personal'),
      })
      .state('staff.view.emergencyContacts', {
        url: '/emergency-contacts',
        pageTitle: 'Emergency Contacts',
        templateUrl: autoNgTemplateLoaderTemplate42,
        controller: require('./controllers/staff/view/emergencyContacts/index'),
      })
      .state('staff.view.employment', {
        url: '/employment?employmentId&addNew',
        pageTitle: 'Employment',
        templateUrl: autoNgTemplateLoaderTemplate43,
        controller: require('./controllers/staff/view/employment'),
      })

      .state('staff.view.pay', {
        url: '/pay',
        abstract: true,
        templateUrl: autoNgTemplateLoaderTemplate44,
        controller: require('./controllers/staff/view/pay'),
      })
      .state('staff.view.pay.history', {
        url: '/history?payId',
        pageTitle: 'Pay',
        templateUrl: autoNgTemplateLoaderTemplate45,
        controller: require('./controllers/staff/view/pay/viewHistory'),
      })
      .state('staff.view.pay.tronc', {
        url: '/tronc',
        pageTitle: 'Tronc - Pay',
        templateUrl: autoNgTemplateLoaderTemplate46,
        controller: 'TroncPayStaffCtrl',
      })
      .state('staff.view.pay.financials', {
        url: '/financials',
        pageTitle: 'Financials',
        templateUrl: autoNgTemplateLoaderTemplate47,
        controller: require('./controllers/staff/view/pay/viewFinancials'),
      })

      .state('staff.view.schedule', {
        url: '/schedule',
        pageTitle: 'Staff schedule',
        templateUrl: autoNgTemplateLoaderTemplate48,
        controller: 'OverviewScheduleCtrl',
      })

      .state('staff.view.absence', {
        url: '/absence',
        abstract: true,
        templateUrl: autoNgTemplateLoaderTemplate49,
        controller: 'AbsenceStaffCtrl',
      })
      .state('staff.view.absence.bookings', {
        url: '/bookings',
        pageTitle: 'Absence bookings',
        templateUrl: autoNgTemplateLoaderTemplate50,
        controller: require('./controllers/staff/view/absence/viewBookings'),
      })
      .state('staff.view.absence.allowances', {
        url: '/allowances',
        pageTitle: 'Absence allowances',
        templateUrl: autoNgTemplateLoaderTemplate51,
        controller: require('./controllers/staff/view/absence/viewAllowances'),
      })
      .state('staff.view.absence.settings', {
        url: '/settings',
        pageTitle: 'Settings',
        templateUrl: autoNgTemplateLoaderTemplate52,
        controller: require('./controllers/staff/view/absence/viewSettings'),
      })

      .state('staff.view.availability', {
        url: '/availability',
        pageTitle: 'Staff availability',
        templateUrl: autoNgTemplateLoaderTemplate53,
        controller: 'AvailabilityScheduleCtrl',
      })
      .state('staff.view.shiftRoutines', {
        url: '/routines',
        pageTitle: 'Shift routines',
        templateUrl: autoNgTemplateLoaderTemplate54,
        controller: 'ShiftRoutinesViewStaffCtrl',
      })
      .state('staff.view.documents', {
        url: '/documents',
        pageTitle: 'Documents',
        templateUrl: autoNgTemplateLoaderTemplate55,
        controller: require('./controllers/staff/view/documents'),
      })
      .state('staff.view.notes', {
        url: '/notes',
        pageTitle: 'Notes',
        templateUrl: autoNgTemplateLoaderTemplate56,
        controller: require('./controllers/staff/view/notes'),
      })
      .state('staff.view.attendance', {
        url: '/attendance?event',
        pageTitle: 'Attendance',
        templateUrl: autoNgTemplateLoaderTemplate57,
        controller: 'StaffAttendanceCtrl',
      })

      .state('staff.requests', {
        url: '/requests',
        abstract: true,
        templateUrl: autoNgTemplateLoaderTemplate58,
        controller: 'ViewStaffRequestsCtrl',
      })
      .state('staff.requests.absence', {
        url: '/absence',
        pageTitle: 'Absence Requests',
        templateUrl: autoNgTemplateLoaderTemplate59,
        controller: 'AbsenceRequestsStaffCtrl',
      })
      .state('staff.requests.swaps', {
        url: '/swaps',
        pageTitle: 'Swap Requests',
        templateUrl: autoNgTemplateLoaderTemplate60,
        controller: 'SwapRequestsStaffCtrl',
      })
      .state('staff.requests.documents', {
        url: '/documents',
        pageTitle: 'Expiring Documents',
        templateUrl: autoNgTemplateLoaderTemplate61,
        controller: require('./controllers/staff/requests/documents'),
      })
      .state('staff.requests.newJoiners', {
        url: '/new-joiners',
        pageTitle: 'New Joiners',
        templateUrl: autoNgTemplateLoaderTemplate62,
        controller: 'NewJoinerRequestsStaffCtrl',
      })

      .state('staff.absence', {
        url: '/absence',
        pageTitle: 'Absence',
        templateUrl: autoNgTemplateLoaderTemplate63,
        controller: 'AbsenceOverviewCtrl',
      })

      .state('reports', {
        url: '/reports',
        template: '<ui-view/>',
        abstract: true,
      })
      .state('reports.index', {
        url: '',
        pageTitle: 'Reports',
        templateUrl: autoNgTemplateLoaderTemplate64,
        controller: require('./controllers/reports'),
      })
      .state('reports.troncStatus', {
        url: '/tronc-status',
        pageTitle: 'Tronc Status - Reports',
        templateUrl: autoNgTemplateLoaderTemplate65,
        controller: require('./controllers/reports/troncStatus'),
      })
      .state('reports.currentlyClockedIn', {
        url: '/currently-clocked-in?entityIds',
        pageTitle: 'People Clocked-In - Reports',
        templateUrl: autoNgTemplateLoaderTemplate66,
        controller: require('./controllers/reports/currentlyClockedIn'),
      })
      .state('reports.rotaProgress', {
        url: '/rota-progress?week&entityGroupIds',
        pageTitle: 'Rota Progress - Reports',
        templateUrl: autoNgTemplateLoaderTemplate67,
        controller: require('./controllers/reports/rotaProgress'),
      })
      .state('reports.signOffProgress', {
        url: '/sign-off-progress?week&entityGroupIds',
        pageTitle: 'Sign-off Progress - Reports',
        templateUrl: autoNgTemplateLoaderTemplate68,
        controller: require('./controllers/reports/signOffProgress'),
      })
      .state('reports.payrollExport', {
        url: '/payroll-export?start&end&entityIds&payAmountTypes&aggregation&userId&payrollCalendarId&companyId',
        pageTitle: 'Payroll Export - Reports',
        templateUrl: autoNgTemplateLoaderTemplate69,
        controller: require('./controllers/reports/payrollExport'),
      })
      .state('reports.labourBreakdown', {
        url: '/labour-breakdown?start&end&entityIds&payAmountTypes&userId&dataSource&behaviour&shiftTypeIds',
        pageTitle: 'Labour Breakdown - Reports',
        templateUrl: autoNgTemplateLoaderTemplate70,
        controller: require('./controllers/reports/labourBreakdown'),
      })
      .state('reports.labourBreakdownAsyncFileExport', {
        url: '/labour-breakdown-file-export',
        pageTitle: 'Labour Breakdown File Export - Reports',
        templateUrl: autoNgTemplateLoaderTemplate71,
        controller: require('./controllers/reports/labourBreakdownAsyncFileExport'),
      })
      .state('reports.payrollAsyncFileExport', {
        url: '/payroll-file-export',
        pageTitle: 'Payroll File Export - Reports',
        templateUrl: autoNgTemplateLoaderTemplate72,
        controller: require('./controllers/reports/payrollAsyncFileExport'),
      })
      .state('reports.rawCostControlAsyncFileExport', {
        url: '/cost-control-raw-data-file-export',
        pageTitle: 'Cost Control Raw Data File Export - Reports',
        templateUrl: autoNgTemplateLoaderTemplate73,
        controller: require('./controllers/reports/costControlRawDataAsyncFileExport'),
      })
      .state('reports.contractualObligationSummary', {
        url: '/contractual-obligation-summary?start&end&entityIds&payAmountTypes&userId&dataSource',
        pageTitle: 'Contractual Obligation Summary - Reports',
        templateUrl: autoNgTemplateLoaderTemplate74,
        controller: require('./controllers/reports/contractualObligationSummary'),
      })
      .state('reports.workingTimeViolations', {
        url: '/working-time-violations?start&end&entityIds',
        pageTitle: 'Working Time Violations - Reports',
        templateUrl: autoNgTemplateLoaderTemplate75,
        controller: require('./controllers/reports/workingTimeViolations'),
      })
      .state('reports.documentSignatures', {
        url: '/document-signatures?entityIds&recipientId&senderId&method&statuses',
        pageTitle: 'Document Signatures - Reports',
        templateUrl: autoNgTemplateLoaderTemplate76,
        controller: require('./controllers/reports/documentSignatures'),
      })
      .state('reports.staffTurnover', {
        url: '/staff-turnover?start&end&leaverReasonIds',
        pageTitle: 'Staff Turnover - Reports',
        templateUrl: autoNgTemplateLoaderTemplate77,
        controller: require('./controllers/reports/staffTurnover'),
      })
      .state('reports.anniversary', {
        url: '/anniversary?start&end&entityIds&types',
        pageTitle: 'Anniversaries - Reports',
        templateUrl: autoNgTemplateLoaderTemplate78,
        controller: require('./controllers/reports/anniversary'),
      })
      .state('reports.employmentChanges', {
        url: '/employment-changes?start&end&entityIds',
        pageTitle: 'Employment Changes - Reports',
        templateUrl: autoNgTemplateLoaderTemplate79,
        controller: require('./controllers/reports/employmentChanges'),
      })
      .state('reports.absenceExport', {
        url: '/absence?start&end&entityIds&typeIds&statuses&userId&positionIds&staffGroupIds',
        pageTitle: 'Absence Export - Reports',
        templateUrl: autoNgTemplateLoaderTemplate80,
        controller: require('./controllers/reports/absenceExport'),
      })
      .state('reports.allowanceExport', {
        url: '/allowances?start&end&entityIds&schedulesOfAccrual&absenceTypeIds&userId',
        pageTitle: 'Allowances Export - Reports',
        templateUrl: autoNgTemplateLoaderTemplate81,
        controller: require('./controllers/reports/allowanceExport'),
      })
      .state('reports.untakenHolidayCost', {
        url: '/untaken-holiday-cost?start&end&entityIds',
        pageTitle: 'Untaken Holiday Cost - Reports',
        templateUrl: autoNgTemplateLoaderTemplate82,
        controller: require('./controllers/reports/untakenHolidayCost'),
      })
      .state('reports.costControlRawData', {
        url: '/cost-control-raw-data',
        pageTitle: 'Cost Control Raw Data - Reports',
        templateUrl: autoNgTemplateLoaderTemplate83,
        controller: require('./controllers/reports/costControlRawData'),
      })
      .state('reports.newHireTurnover', {
        url: '/new-hire-turnover?start&end&days&leaverReasonIds',
        pageTitle: 'New Hire Turnover - Reports',
        templateUrl: autoNgTemplateLoaderTemplate84,
        controller: require('./controllers/reports/newHireTurnover'),
      })
      .state('reports.absenceDaysExport', {
        url: '/absence-days?start&end&entityIds&typeIds&statuses&userId&positionIds&staffGroupIds',
        pageTitle: 'Absence Days Export - Reports',
        templateUrl: autoNgTemplateLoaderTemplate85,
        controller: require('./controllers/reports/absenceDaysExport'),
      })
      .state('reports.shiftTypeStreamBreakdown', {
        url: '/shift-type-stream-breakdown?start&end&entityIds&payAmountTypes&userId&dataSource&behaviour',
        pageTitle: 'Shift Type Stream Breakdown - Reports',
        templateUrl: autoNgTemplateLoaderTemplate86,
        controller: require('./controllers/reports/shiftTypeStreamBreakdown'),
      })
      .state('reports.documentPresence', {
        url: '/document-presence?entityIds&categoryIds',
        pageTitle: 'Document Presence - Reports',
        templateUrl: autoNgTemplateLoaderTemplate87,
        controller: require('./controllers/reports/documentPresence'),
      })
      .state('reports.documentsExport', {
        url: '/documents-export?entityIds&categoryIds',
        pageTitle: 'Documents Export - Reports',
        templateUrl: autoNgTemplateLoaderTemplate88,
        controller: require('./controllers/reports/documentsExport'),
      })
      .state('reports.attendanceBreakdown', {
        url: '/attendance-breakdown?start&end&entityIds&userId&overtimeApprovalStates&filters',
        pageTitle: 'Attendance Breakdown - Reports',
        templateUrl: autoNgTemplateLoaderTemplate89,
        controller: require('./controllers/reports/attendanceBreakdown'),
      })
      .state('reports.lateness', {
        url: '/lateness?start&end&entityIds&userId&overtimeApprovalStates&filters',
        pageTitle: 'Lateness - Reports',
        templateUrl: autoNgTemplateLoaderTemplate90,
        controller: require('./controllers/reports/lateness'),
      })

      .state('reports.xero', {
        url: '/xero',
        template: '<ui-view/>',
        abstract: true,
      })
      .state('reports.xero.payRuns', {
        url: '/payRuns',
        pageTitle: 'Xero - Pay Runs',
        templateUrl: autoNgTemplateLoaderTemplate91,
        controller: 'PayRunsXeroCtrl',
        resolve: legacyHqPermissionCheck,
      })
      .state('reports.xero.payRun', {
        url: '/payRun/:organisationId/:payRunId',
        pageTitle: 'Xero - Pay Run',
        templateUrl: autoNgTemplateLoaderTemplate92,
        controller: 'PayRunXeroCtrl',
      })

      .state('attendance', {
        url: '/attendance',
        template: '<ui-view/>',
        abstract: true,
      })
      .state('attendance.overview', {
        url: '?date&view&entity',
        pageTitle: 'Attendance',
        templateUrl: autoNgTemplateLoaderTemplate93,
        controller: 'OverviewAttendanceCtrl',
      })
      .state('attendance.issues', {
        url: '/issues?start&end&entity&type',
        pageTitle: 'Attendance Issues',
        templateUrl: autoNgTemplateLoaderTemplate94,
        controller: 'IssuesAttendanceCtrl',
      })

      .state('absenceRequest', {
        url: '/absence/request?start&end&userId&overlapShifts',
        pageTitle: 'Absence Request',
        templateUrl: autoNgTemplateLoaderTemplate95,
        controller: require('./controllers/absence/request'),
        resolve: {
          PreviousState: ($state) => ({
            name: $state.current.name,
            params: angular.copy($state.params),
          }),
        },
      })
      .state('absenceEdit', {
        url: '/absence/edit/:id',
        abstract: true,
        templateUrl: autoNgTemplateLoaderTemplate96,
        controller: require('./controllers/absence/edit'),
        resolve: {
          PreviousState: ($state) => ({
            name: $state.current.name,
            params: angular.copy($state.params),
          }),
        },
      })
      .state('absenceEdit.dates', {
        url: '/dates',
        pageTitle: 'Edit dates',
        templateUrl: autoNgTemplateLoaderTemplate97,
        controller: require('./controllers/absence/edit/dates'),
      })
      .state('absenceEdit.review', {
        url: '/review',
        pageTitle: 'Review booking',
        templateUrl: autoNgTemplateLoaderTemplate98,
        controller: require('./controllers/absence/edit/review'),
      })
      .state('absenceEdit.documents', {
        url: '/documents',
        pageTitle: 'Absence documents',
        templateUrl: autoNgTemplateLoaderTemplate99,
        controller: require('./controllers/absence/edit/documents'),
      })
      .state('absenceEdit.calendar', {
        url: '/calendar',
        pageTitle: 'Absence calendar',
        templateUrl: autoNgTemplateLoaderTemplate100,
        controller: require('./controllers/absence/edit/calendar'),
      });
  });
};
