var directives = require('./module');

directives.directive('tagTypeaheadInput', function () {
    return {
        restrict: 'E',
        scope: {
            list: '=',
            placeholder: '@',
            tagModel: '=',
            isRequired: '@',
            isSmall: "=",
            minLength: '@'
        },
        link: function ($scope, element, attrs) {
            var masterList = false;
            var masterListById = {};

            $scope.required = $scope.isRequired;

            function getPreselectedIds() {
                var preSelectedIds = [];

                if (angular.isDefined($scope.tagModel)) {
                    // Case 1: '1,5,7,15'
                    if (angular.isString($scope.tagModel)) {
                        preSelectedIds = $scope.tagModel.split(',');
                    }

                    // Case 2: [1,5,7,15]
                    if (angular.isArray($scope.tagModel)) {
                        preSelectedIds = $scope.tagModel;
                    }

                    // Convert each id to an integer, just in case
                    preSelectedIds = preSelectedIds.map(function (i) {
                        return parseInt(i, 10);
                    });
                }

                return preSelectedIds;
            }

            $scope.$watch('list', function (newValue) {
                // We cannot reinitialise from the master list being set, we can only initialise once
                if (!angular.isArray(newValue)) {
                    return;
                }

                if (masterList) {
                    return;
                }

                // Take a copy of the master list the first time we initialise
                masterList = angular.copy(newValue);

                var preSelectedIds = getPreselectedIds();

                $scope.list = angular.copy(masterList).filter(function (item) {
                    return preSelectedIds.indexOf(item.id) === -1;
                });

                angular.forEach(masterList, function (item) {
                    masterListById[item.id] = item;
                });
            });

            $scope.$watch('tagModel', function (newValue) {
                if (typeof newValue === 'undefined' || newValue === 0) return;

                if (angular.isDefined(newValue) && angular.isString(newValue)) {
                    if (newValue.length) {
                        $scope.tagModel = newValue.split(',').map(function (t) {
                            return parseInt(t, 10);
                        });
                    } else {
                        $scope.tagModel = [];
                    }
                }

                if (!$scope.list) return;

                // We need to update the list here and remove any elements in the tagModel
                var preSelectedIds = getPreselectedIds();

                $scope.list = masterList.filter(function (item) {
                    return preSelectedIds.indexOf(item.id) === -1;
                });
            });

            if (attrs.isSmall) {
                element.find('input').first().addClass('input-sm');
                element.find('input').first().css({
                    width: '150px'
                });
            }

            $scope.onSelect = function (id) {
                if (typeof $scope.tagModel === 'undefined' || $scope.tagModel === 0) $scope.tagModel = [];

                var index = false;

                angular.forEach($scope.list, function (item, idx) {
                    if (item.id === id) {
                        index = idx;
                    }
                });

                if (index !== false) {
                    $scope.list.splice(index, 1);
                    $scope.tagModel.push(masterListById[id].id);
                }

                if ($scope.tagModel.length) {
                    $scope.required = false;
                }
            };

            $scope.onDelete = function (id) {
                var index = false;

                angular.forEach($scope.tagModel, function (item, idx) {
                    if (item === id) {
                        index = idx;
                    }
                });

                if (index !== false) {
                    $scope.tagModel.splice(index, 1);
                    $scope.list.push(masterListById[id]);
                }

                if (!$scope.tagModel.length) {
                    $scope.required = $scope.isRequired ? true : false;
                }
            };

            $scope.buildTags = function () {
                if (!masterList) return [];

                var validIds = masterList.map(function (i) {
                    return parseInt(i.id, 10);
                });

                var preSelectedIds = getPreselectedIds();

                return preSelectedIds.filter(function (id) {
                    return validIds.indexOf(id) !== -1;
                }).map(function (id) {
                    return masterListById[id];
                });
            }
        },
        template: '<div>' +
        '<input type="text" placeholder="{{placeholder}}" ng-required="required" ng-model="tagInput" ' +
        'class="form-control" uib-typeahead="item.id as item.name for item in list | filter: $viewValue" ' +
        'typeahead-editable="false" typeahead-min-length="minLength" typeahead-on-select="onSelect($item.id);tagInput = \'\'" ' +
        'autocomplete="off" />' +
        '<div class="tag-typeahead-buttons">' +
        '<a ng-repeat="tag in buildTags() | filter: $viewValue" class="btn btn-info btn-xs" ng-click="onDelete(tag.id)" title="Remove">{{tag.name}}</a>' +
        '</div>' +
        '</div>'
    };
});
