var controllers = require('../../module');

controllers.controller('DeleteRoutineMappingStaffModalCtrl', ['$uibModalInstance', '$scope', 'data', 'StaffService', 'AlertService',
    function($uibModalInstance, $scope, data, StaffService, AlertService) {
        $scope.cancel = function() {
            $uibModalInstance.dismiss();
        }

        $scope.delete = function() {
            $scope.deleteActioning = true;

            StaffService.deleteRoutineMapping(data.userId, data.mappingId)
                .success(function() {
                    AlertService.add('success', 'Shift routine deleted successfully.');
                    $uibModalInstance.close();
                })
                .error(function(err, status) {
                    $scope.deleteActioning = false;
                    if (status == 400) {
                        $scope.validationFailure = err.validationFailure;
                        return;
                    }

                    if (status !== 403) {
                        AlertService.add('danger', 'We couldn\'t delete the shift routine just then. Please try again.');
                    }
                    
                    $uibModalInstance.dismiss();
                });
        }
    }
]);
