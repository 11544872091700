const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messaging/conversationDrawer.html');

const uuidv4 = require("uuid").v4;

module.exports = (
  $scope,
  $uibModalDrawer,
  $uibModalStack,
  $translate,
  SessionService,
  MessagingService,
  AlertService,
) => {
  'ngInject';

  const ownUserId = SessionService.getUserId();

  $scope.conversationUserList = [];

  $scope.props = {
    isLoading: false,
    userListEmpty: true,
  };

  $scope.onUserSelect = (user) => {
    if (ownUserId !== user.id && !$scope.conversationUserList.some(({ id }) => id === user.id)) {
      $scope.conversationUserList.push(user);
      $scope.props.userListEmpty = false;
    }
  };

  $scope.onDeleteUser = (user) => {
    $scope.conversationUserList = $scope.conversationUserList.filter(
      ({ id: userId }) => user.id !== userId);

    if ($scope.conversationUserList.length === 0) {
      $scope.props.userListEmpty = true;
    }
  };

  $scope.goBackFromStartConverstationDrawer = () => {
    const { key: openedModalKey } = $uibModalStack.getTop();
    $uibModalStack.dismiss(openedModalKey);
  };

  $scope.closeConversationMessagingDrawer = () => {
    $uibModalStack.dismissAll();
  };

  $scope.startConversation = async () => {
    $scope.props.isLoading = true;
    const uuid = uuidv4();
    const userListByIds = $scope.conversationUserList.map(({ userId }) => userId);
    userListByIds.push(ownUserId);

    const conversationUsers = userListByIds.sort((a, b) => a - b);
    const channelName = uuid;

    const isSuccess = await MessagingService.startConversation(
      channelName,
      conversationUsers,
    );

    if (isSuccess) {
      $uibModalDrawer.open({
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: require('./conversationDrawer'), // eslint-disable-line
        backdrop: 'static',
        resolve: {
          data: () => ({
            participants: $scope.conversationUserList,
            participantIds: userListByIds,
            channelId: channelName,
          }),
        },
      });

      const { key: openedModalKey } = $uibModalStack.getTop();
      $uibModalStack.dismiss(openedModalKey);
    } else {
      AlertService.add('danger', $translate.instant('MESSAGING.ALERTS.START_CONVERSATION'));
    }

    $scope.props.isLoading = false;
    $scope.$apply();
  };
};
