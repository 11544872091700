const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/fileUpload.html');

const directives = require('./module');

directives.directive('rrFileUpload', (
  $translate,
  AlertService,
  StaffService,
  Upload,
) => {
  'ngInject';

  const translations = $translate.instant([
    'DIRECTIVES.FILE_UPLOAD.ALERT_UNSUPPORTED_FILE_TYPE',
    'DIRECTIVES.FILE_UPLOAD.ALERT_FILE_SIZE',
  ]);

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      onSuccessfulUpload: '&',
      onFileRemoved: '&?',
      readOnly: '=?',
      maxFiles: '@',
      maxSize: '@',
      permittedExtensions: '=',
    },
    link: ($scope) => {
      const {
        onSuccessfulUpload,
        onFileRemoved,
        maxFiles,
        maxSize,
        permittedExtensions,
      } = $scope;

      $scope.props = {
        maxSize,
        permittedExtensions: permittedExtensions.join(','),
        permittedExtensionsHumanised: permittedExtensions.join(', '),
        files: [],
        canRemovePostUpload: !!$scope.onFileRemoved,
      };

      function getSanitisedFileObject({ name, key }) {
        return {
          name,
          key,
        };
      }

      $scope.deleteFile = (file) => {
        if (file.deleted) {
          return;
        }

        file.deleted = true;

        if (file.uploadSuccess === undefined) {
          file.upload.abort();
        }

        if (file.uploadSuccess === true && onFileRemoved) {
          onFileRemoved({
            file: getSanitisedFileObject(file),
          });
        }
      };

      function handleInvalidFile(file) {
        const { $error } = file;

        if ($error === 'pattern') {
          AlertService.add('warning', translations['DIRECTIVES.FILE_UPLOAD.ALERT_UNSUPPORTED_FILE_TYPE']);
        }

        if ($error === 'maxSize') {
          AlertService.add('warning', translations['DIRECTIVES.FILE_UPLOAD.ALERT_FILE_SIZE']);
        }
      }

      function handleValidFile(file) {
        const nonDeletedFiles = $scope.props.files.filter((f) => !f.deleted).length;

        if (nonDeletedFiles >= maxFiles) {
          return;
        }

        const { name } = file;

        const params = {
          fileName: file.name,
        };

        const fileIndex = $scope.props.files.push({
          name,
          uploadProgress: 0,
          uploadSuccess: undefined,
        }) - 1;

        StaffService.getDocumentSignedUrl(params)
          .then(({ data }) => {
            const {
              uploadUrl,
              contentType,
              contentDisposition,
              key,
            } = data;

            const upload = Upload.http({
              url: uploadUrl,
              method: 'PUT',
              data: file,
              headers: {
                'Content-Type': contentType,
                'Content-Disposition': contentDisposition,
              },
              // prevent AuthService adding Authorization header (which S3 won't like)
              noIntercept: true,
            });

            $scope.props.files[fileIndex].key = key;
            $scope.props.files[fileIndex].upload = upload;

            upload.then(() => {
              $scope.props.files[fileIndex].uploadSuccess = true;

              onSuccessfulUpload({
                file: getSanitisedFileObject($scope.props.files[fileIndex]),
              });
            }, () => {
              $scope.props.files[fileIndex].uploadSuccess = false;
            }, (evt) => {
              $scope.props.files[fileIndex].uploadProgress = Math.min(100,
                parseInt(100.0 * (evt.loaded / evt.total), 10));
            });
          })
          .catch(() => {
            $scope.props.files[fileIndex].uploadSuccess = false;
          });
      }

      $scope.onFileUpload = (files, invalidFiles) => {
        files.forEach((file) => handleValidFile(file));
        invalidFiles.forEach((file) => handleInvalidFile(file));
      };
    },
  };
});
