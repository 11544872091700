'use strict';
var services = require('./module');

services.factory('AlertService', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
    var alertService;
    var alertIndex = 0;
    $rootScope.alerts = [];

    return alertService = {
        add: function (type, msg, id, timeout) {
            // Don't add multiple alerts with the same id
            if (id) {
                var addAlert = true;
                $rootScope.alerts.forEach(function (alert) {
                    if (alert.id == id)
                        addAlert = false;
                });

                if (!addAlert)
                    return;
            }

            $rootScope.alerts.push({
                index: ++alertIndex,
                id: id,
                type: type,
                msg: msg,
                close: function () {
                    return alertService.closeAlert(this);
                }
            });

            // Default the timeout if none provided
            timeout = typeof timeout !== 'undefined' ? timeout : 6000;
            $timeout(alertService.closeAlertIdx.bind(this, alertIndex), timeout);
        },
        closeAlert: function (alert) {
            return this.closeAlertIdx(alert.index);
        },
        closeAlertIdx: function (index) {
            for (var i = $rootScope.alerts.length - 1; i >= 0; i--) {
                if ($rootScope.alerts[i].index === index) $rootScope.alerts.splice(i, 1);
            }
        }

    };
}]);
