var controllers = require('../../module');

controllers.controller('EditRoutineMappingStaffModalCtrl', ['$uibModalInstance', '$scope', 'data', 'StaffService', 'AlertService',
    function($uibModalInstance, $scope, data, StaffService, AlertService) {

        $scope.datePickerDate = moment();

        if (!data.mapping.end) {
            $scope.noEnd = true;
        } else {
            $scope.datePickerDate = moment(data.mapping.end).subtract(1, 'day');
        }

        $scope.saveRoutine = function (skipIssues) {
            $scope.saveActioning = true;

            var body = {
                date_end: $scope.noEnd ? null : $scope.datePickerDate.clone().add(1, 'day').format('YYYY-MM-DD'),
                skip_issues: skipIssues
            };

            StaffService.updateRoutineMapping(data.userId, data.mapping.id, body)
                .success(function() {
                    $scope.saveActioning = false;
                    $uibModalInstance.close();
                })
                .error(function(err, status) {
                    if (status == 400) {
                        $scope.validationFailure = err.validationFailure;
                        $scope.saveActioning = false;
                        return;
                    }

                    if (status !== 403) {
                        AlertService.add('danger', 'We couldn\'t save the shift routine just then. Please try again.');
                    }
                    $uibModalInstance.dismiss();
                });
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.dateChanged = function (newDate) {
            if (!newDate) {
                newDate = $scope.datePickerDate.clone();
            } else {
                $scope.datePickerDate = newDate.clone();
            }

            var startDate = moment(data.mapping.start).clone().startOf('day');
            var endDate = newDate.clone();

            $scope.hasOverlap = false;
            $scope.endBeforeStart = false;

            var openEnded = $scope.noEnd;

            if (!openEnded && endDate.isBefore(startDate)) {
                $scope.endBeforeStart = true;
                return;
            }

            data.mappings.forEach(function (mapping) {
                if ($scope.hasOverlap || mapping.id === data.mapping.id) return;

                var mappingStart = moment(mapping.start).clone().startOf('day');
                // Since the date on the mapping we're editing is inclusive (we add a day before sending to API),
                // we compare with the 'display_end' property on the mapping we're comparing to, which is also inclusive
                var mappingEnd = moment(mapping.display_end).clone().startOf('day');

                // If both mappings have no end, there's an overlap
                if (openEnded && !mappingEnd.isValid()) {
                    $scope.hasOverlap = true;
                    return;
                }

                var newEnd;

                // If the existing mapping is finite but our new one is open ended,
                // we can spoof an end date by making it one day after the end of the existing mapping
                if (openEnded) {
                    newEnd = mappingEnd.clone().add(1, 'day');
                } else {
                    newEnd = endDate.clone();
                }

                var existingEnd;

                // Similarly, if our new mapping is finite but the existing one has no end,
                // we can spoof an end date by making it end one day after our new mapping
                if (!mappingEnd.isValid()) {
                    existingEnd = endDate.clone().add(1, 'day');
                } else {
                    existingEnd = mappingEnd.clone();
                }

                // Since we've got two finite date ranges we can just do a normal date comparison
                if ((mappingStart.isBefore(newEnd) || mappingStart.isSame(newEnd)) &&
                    (existingEnd.isAfter(startDate) || existingEnd.isSame(startDate))) {
                    $scope.hasOverlap = true;
                    return;
                }
            });
        }

    }
]);
