const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/documentTemplates/uploadModal.html');

module.exports = (
  $scope,
  $uibModal,
  $state,
  $window,
  $translate,
  $q,
  StaffService,
  AlertService,
  EnvironmentDataService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'SETTINGS.DOCUMENT_TEMPLATES.ERROR_500',
    'SETTINGS.DOCUMENT_TEMPLATES.ERROR_403',
    'SETTINGS.DOCUMENT_TEMPLATES.ALERT_CONFIRM_DELETE',
  ]);

  $scope.props = {
    loadingData: true,
    data: [],
    categoryOptions: [],
    noCategories: false,
  };

  $scope.onCategoryFilter = (selectedOptions) => {
    const [selectedCategoryId] = selectedOptions;
    $scope.props.data.forEach((r) => {
      r.visible = selectedCategoryId ? r.categoryId === selectedCategoryId : true;
    });
  };

  function onApiError(status) {
    $scope.props.loadingData = false;

    if (status === 500) {
      AlertService.add('danger', translations['SETTINGS.DOCUMENT_TEMPLATES.ERROR_500']);
      return;
    }

    if (status === 403) {
      AlertService.add('warning', translations['SETTINGS.DOCUMENT_TEMPLATES.ERROR_403']);
      return;
    }

    $state.go('settings.index');
  }

  function loadData() {
    $scope.props.loadingData = true;

    EnvironmentDataService.invalidateCache(EnvironmentDataService.DataType.DocumentTemplate);

    EnvironmentDataService.fetchAll([
      EnvironmentDataService.DataType.DocumentTemplate,
      EnvironmentDataService.DataType.DocumentTemplateCategory,
      EnvironmentDataService.DataType.DocumentCategory,
    ]).then(([
      templateResponse,
      documentTemplateCategoriesResponse,
      documentCategoriesResponse,
    ]) => {
      const { data: templates } = templateResponse;
      const { data: templateCategories } = documentTemplateCategoriesResponse;
      const { data: documentCategories } = documentCategoriesResponse;

      $scope.props.categoryOptions = templateCategories
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));

      $scope.props.data = templates.flatMap((template) => {
        const {
          deleted,
          categoryId,
          destinationDocumentCategoryId,
          fileSizeInKb,
        } = template;

        if (deleted) {
          return [];
        }

        return [{
          ...template,
          visible: true,
          friendlyFileSize: StaffService.getDocumentFriendlyFileSize(fileSizeInKb),
          templateCategoryMeta: templateCategories
            .find(({ id }) => categoryId === id),
          documentCategoryMeta: documentCategories
            .find(({ id }) => destinationDocumentCategoryId === id),
        }];
      });

      $scope.props.noCategories = !templateCategories.length;
      $scope.props.loadingData = false;
    }).catch(({ status }) => onApiError(status));
  }

  loadData();

  $scope.deleteTemplate = (row, index) => {
    if (row.actionInProgress) {
      return;
    }

    if (!confirm(translations['SETTINGS.DOCUMENT_TEMPLATES.ALERT_CONFIRM_DELETE'])) {
      return false;
    }

    row.actionInProgress = true;

    StaffService.deleteDocumentTemplate(row.id)
      .then(() => {
        $scope.props.data.splice(index, 1);
      })
      .catch(({ status }) => {
        row.actionInProgress = false;
        onApiError(status);
      });
  };

  $scope.viewTemplate = (row) => {
    if (row.actionInProgress) {
      return;
    }

    row.actionInProgress = true;

    StaffService.getDocumentTemplate(row.id, { download: true })
      .then(({ data }) => {
        const { signedUrl } = data;

        if (signedUrl) {
          $window.location = signedUrl;
        }

        row.actionInProgress = false;
      })
      .catch(({ status }) => {
        row.actionInProgress = false;
        onApiError(status);
      });
  };

  $scope.upload = () => {
    if ($scope.props.noCategories) {
      return;
    }

    const uploadModal = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./uploadModal'),
    });

    uploadModal.result.then((result) => {
      if (!result) {
        return;
      }

      loadData();
    });
  };
};
