'use strict';
var controllers = require('../../module');

controllers.controller('AddLocationModalCtrl', ['$scope', '$uibModalInstance', '$translate', 'RotaService', 'CommonService', 'SessionService', 'data',
    function ($scope, $uibModalInstance, $translate, RotaService, CommonService, SessionService, data) {
        $scope.dataLoaded = false;
        $scope.disabledIds = [];

        $scope.formData = {
            existing: 0,
            details: data.address.replace(/ *, */g, '\n'),
            latitude: data.lat,
            longitude: data.lng
        };

        CommonService.getMetadata(['shiftLocations'], [SessionService.getEntity()], true)
            .success(function (metadata) {
                $scope.metadata = {
                    shiftLocations: metadata.shiftLocations,
                };

                if ($scope.metadata.shiftLocations.length) {
                    $scope.formData.existingId = $scope.metadata.shiftLocations[0].id;
                }

                $scope.dataLoaded = true;
            })
            .error(function () {
                $scope.close();
            });

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.typeChange = function () {
            if (!$scope.formData.existing) {
                $scope.formData.name = '';
            } else {
                $scope.existingLocationChange();
            }
        };

        $scope.existingLocationChange = function () {
            angular.forEach($scope.metadata.shiftLocations, function (location) {
                if (location.id == $scope.formData.existingId) {
                    $scope.formData.name = location.name;
                }
            });
        };

        $scope.save = function () {
            if (!$scope.formData.name) {
                return alert($translate.instant('ROTA_MANAGEMENT.LOCATIONS.ADD_LOCATION_MODAL.ERROR_ENTER_NAME'));
            }

            $scope.saveActioning = true;

            if ($scope.formData.existing) {
                RotaService.updateShiftLocation($scope.formData.existingId, $scope.formData)
                    .success(function (updatedLocation) {
                        $uibModalInstance.close(updatedLocation);
                    })
                    .error(function () {
                        $scope.saveActioning = false;
                        alert($translate.instant('ROTA_MANAGEMENT.LOCATIONS.ADD_LOCATION_MODAL.ERROR_UPDATE'));
                    });
            } else {
                RotaService.addShiftLocation($scope.formData)
                    .success(function (newLocation) {
                        $uibModalInstance.close(newLocation);
                    })
                    .error(function () {
                        $scope.saveActioning = false;
                        alert($translate.instant('ROTA_MANAGEMENT.LOCATIONS.ADD_LOCATION_MODAL.ERROR_ADD'));
                    });
            }
        };
    }]);
