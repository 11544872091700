module.exports = (
  $scope,
  $http,
  $translate,
  $uibModalInstance,
  AlertService,
  ENDPOINT_API,
  data,
) => {
  'ngInject';

  const translations = $translate.instant([
    'REPORTS.SIGN_OFF_PROGRESS.STATUS_MODAL.REVERSE_CONFIRM',
    'REPORTS.SIGN_OFF_PROGRESS.STATUS_MODAL.REVERSE_SUCCESS',
    'REPORTS.SIGN_OFF_PROGRESS.STATUS_MODAL.REVERSE_ERROR',
  ]);

  $scope.entity = data.entity;
  $scope.rows = [];

  let isChangesMade = false;

  for (let date = data.periodStart.clone(); date.isBefore(data.periodEnd); date.add(1, 'day')) {
    const dateFormatted = date.format('YYYY-MM-DD');

    $scope.rows.push({
      date: date.clone(),
      dateFormatted,
      signedOff: data.status.signedOffDates.includes(dateFormatted),
      isReportingDate: data.status.reportingDatesInPeriod.includes(dateFormatted),
    });
  }

  function determineIfWeekCanBeReversed() {
    $scope.canReverseWeek = $scope.rows.some((r) => r.signedOff);
  }

  determineIfWeekCanBeReversed();

  $scope.close = function () {
    $uibModalInstance.close(isChangesMade);
  };

  $scope.$on('modal.closing', (event, reason, isProperClose) => {
    if (!isProperClose) {
      event.preventDefault();
      $scope.close();
    }
  });

  $scope.reverse = (row) => {
    if ($scope.reverseActioning) {
      return;
    }

    if (!confirm(translations['REPORTS.SIGN_OFF_PROGRESS.STATUS_MODAL.REVERSE_CONFIRM'])) {
      return;
    }

    $scope.reverseActioning = true;

    if (row) {
      row.isDeleting = true;
    }

    const fields = {
      dates: row ? [row.dateFormatted] : $scope.rows.map((r) => r.dateFormatted),
      entityId: $scope.entity.id,
    };

    $http.put(`${ENDPOINT_API}/rota/signOff/unlock`, fields)
      .then(() => {
        if (row) {
          row.signedOff = false;
        } else {
          $scope.rows.forEach((r) => {
            r.signedOff = false;
          });
        }

        determineIfWeekCanBeReversed();

        isChangesMade = true;
        $scope.reverseActioning = false;

        if (row) {
          row.isDeleting = false;
        }

        AlertService.add('success', translations['REPORTS.SIGN_OFF_PROGRESS.STATUS_MODAL.REVERSE_SUCCESS']);
      })
      .catch(({ status }) => {
        $scope.reverseActioning = false;

        if (row) {
          row.isDeleting = false;
        }

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.SIGN_OFF_PROGRESS.STATUS_MODAL.REVERSE_ERROR']);
        }
      });
  };
};
