const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messaging/conversationDrawer.html');

const moment = require('moment-timezone');

module.exports = (
  $scope,
  $uibModalDrawer,
  $translate,
  SessionService,
) => {
  'ngInject';

  $scope.ownUserId = SessionService.getUserId();

  $scope.formatTimeText = (messageDate) => {
    if (messageDate.isSame(moment(), 'day')) {
      return messageDate.format('LT');
    }

    if (messageDate.isSame(moment().subtract(1, 'days'), 'day')) {
      return $translate.instant('MESSAGING.MESSAGE_HUB.YESTERDAY');
    }

    return messageDate.format('L');
  };

  $scope.openConversationMessagingDrawer = ({ participants, participantIds, channelId }) => {
    $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./conversationDrawer'), // eslint-disable-line
      backdrop: 'static',
      resolve: {
        data: () => ({
          participants,
          participantIds,
          channelId,
        }),
      },
    });
  };
};
