const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messaging/startConversationDrawer.html');

module.exports = (
  $scope,
  $uibModalDrawer,
  $uibModalStack,
  $timeout,
  $q,
  MessagingService,
) => {
  'ngInject';

  $scope.props = {
    loadingData: false,
    mappedConversationsWithMetaData: [],
  };

  const getConversationsWithMetadata = async (channels, channelMetaData) => {
    const conversationsWithMetadata = await MessagingService
      .fetchConversationMessages(channels, channelMetaData);

    const allParticipantIds = new Set();

    Object.values(conversationsWithMetadata).forEach(({ channel, metadata }) => {
      let participantIds;

      if (metadata) {
        participantIds = metadata.custom.participantIds.split(',');
      } else {
        participantIds = [];
      }

      participantIds
        .filter((p) => p !== '')
        .forEach((p) => allParticipantIds.add(+p));
    });

    $scope.props.mappedConversationsWithMetaData = await MessagingService
      .getConversationWithParticipants(Array.from(allParticipantIds));
  };

  $scope.$on('messageListUpdated', async () => {
    if ($scope.props.mappedConversationsWithMetaData.length) {
      // Calling with null as these values will already be pre-populated
      await getConversationsWithMetadata(null, null);
    } else {
      // No messages have ever been fetched, fetch the full flow
      $scope.getMessages();
    }
  });

  $scope.getMessages = async () => {
    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.loadingData = true;

    const channels = await MessagingService.getChannels();

    if (channels && channels.length > 0) {
      const metadata = await MessagingService.getChannelsMetadata();
      const channelMetaData = Object.values(metadata);

      await getConversationsWithMetadata(channels, channelMetaData);
    }

    $scope.props.loadingData = false;

    return $q.resolve();
  };

  $scope.openStartConversationDrawer = () => {
    $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./startConversationDrawer'), // eslint-disable-line
      backdrop: 'static',
    });
  };

  $scope.closeMessagingHubDrawer = () => $uibModalStack.dismissAll();

  $timeout(async () => {
    await $scope.getMessages();
  });
};
