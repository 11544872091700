const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/availability/editPatternExceptionsModal.html');

'use strict';
import Clipboard from 'clipboard';
var controllers = require('../module');

controllers.controller('OverviewScheduleCtrl', ['$scope', '$uibModal', 'SessionService', 'AvailabilityService', 'AuthService', 'FEED_LOCATION',
    function ($scope, $uibModal, SessionService, AvailabilityService, AuthService, FEED_LOCATION) {
        var clipboard = new Clipboard('#iCalCopy');

        $scope.editPatternExceptions = function () {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'EditPatternExceptionsModalCtrl',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    data: function () {
                        return {
                            date: $('#schedule').fullCalendar('getDate'),
                            user: $scope.userId
                        };
                    }
                }
            });

            modalInstance.result.then(function (doRefresh) {
                if (doRefresh) {
                    $('#schedule').fullCalendar('refetchEvents');
                }
            });
        };

        $scope.iCalGenerated = false;

        $scope.generateICal = function () {
            $scope.iCalLoading = true;

            AvailabilityService.getICalToken(SessionService.getUserId())
                .success(function(data) {
                    $scope.iCalLoading = false;

                    $scope.iCalURL = FEED_LOCATION + '/calendar/' + AuthService.getRealm() +
                        '?token=' + data.token;
                })
                .error(function (err, status) {
                    $scope.iCalLoading = false;
                });
        }

        $scope.showingTooltip = false;

        $scope.leaveCopy = function() {
            $scope.showingTooltip = false;
        }
    }]);
