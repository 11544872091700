const controllers = require('../module');

controllers.controller('SettingsCtrl', ['$scope', 'SessionService', 'WFM_ENDPOINT', 'NEW_HR_FEATURES_ENABLED','DOCUMENT_CATEGORIES_ENABLED', 'WFM_OUTSOURCED_GROUPS_ENABLED', 'ENTITY_GROUP_MANAGER_ENABLED',
  function ($scope, SessionService, WFM_ENDPOINT, NEW_HR_FEATURES_ENABLED, DOCUMENT_CATEGORIES_ENABLED, WFM_OUTSOURCED_GROUPS_ENABLED, ENTITY_GROUP_MANAGER_ENABLED) {
    $scope.areShiftRoutinesEnabled = SessionService.areShiftRoutinesEnabled();
    $scope.isAssetRegistryEnabled = SessionService.isAssetRegistryEnabled();
    $scope.hasAccessToSiteAccessManager = SessionService.isPermissioned('hq.siteAccess');
    $scope.hasAccessToSiteManager = ENTITY_GROUP_MANAGER_ENABLED && SessionService.isPermissioned('settings.siteManager');
    $scope.hasAccessToHR = NEW_HR_FEATURES_ENABLED && SessionService.isPermissioned('settings.hr');
    $scope.tagsGroupsLink = `${WFM_ENDPOINT}/settings/tags-groups`;
    $scope.outsourcingLink = `${WFM_ENDPOINT}/settings/outsourcing`;
    $scope.outsourcingOnWfm = WFM_OUTSOURCED_GROUPS_ENABLED;
    $scope.absenceTypesLink = `${WFM_ENDPOINT}/settings/absence-types`;
    $scope.assetTypesLink = `${WFM_ENDPOINT}/settings/asset-types`;
    $scope.entityGroupManager = `${WFM_ENDPOINT}/settings/site-manager`;
    $scope.entityGroupSubscriptions = `${WFM_ENDPOINT}/settings/site-access-manager`;
    $scope.positions = `${WFM_ENDPOINT}/settings/positions`;
    $scope.leaverReasons = `${WFM_ENDPOINT}/settings/leaver-reasons`;
    $scope.recruitmentSources = `${WFM_ENDPOINT}/settings/recruitment-sources`;
    $scope.documentCategories = `${WFM_ENDPOINT}/settings/document-categories`;
    $scope.labourDeploymentLink = `${WFM_ENDPOINT}/settings/labour-deployment`;
    $scope.areDocumentCategoriesEnabled = DOCUMENT_CATEGORIES_ENABLED && SessionService.isPermissioned('settings.hr');
    $scope.isLabourDeploymentEnabled = SessionService.isLabourDeploymentEnabled() && SessionService.isPermissioned('rota');
  },
]);
