module.exports = (
  $scope,
  $state,
  $translate,
  $window,
  $uibModalInstance,
  AlertService,
  AvailabilityService,
  StaffCommon,
  StaffService,
  data,
) => {
  'ngInject';

  const { allowance } = data;

  const translations = $translate.instant([
    'STAFF.VIEW.ABSENCE.VIEW_FINALISATION.ERROR_500',
    'STAFF.VIEW.ABSENCE.VIEW_FINALISATION.ERROR_403',
    'STAFF.VIEW.ABSENCE.VIEW_FINALISATION.CONFIRM_REVERT',
    'STAFF.VIEW.ABSENCE.VIEW_FINALISATION.ALERT_SUCCESS',
  ]);

  $scope.props = {
    actionInProgress: false,
    allowance,
  };

  $scope.close = () => $uibModalInstance.dismiss();

  function closeAndRefresh() {
    AlertService.add('success', translations['STAFF.VIEW.ABSENCE.VIEW_FINALISATION.ALERT_SUCCESS']);
    $scope.props.actionInProgress = false;
    $uibModalInstance.close(true);
  }

  function onApiError(status, data) {
    $scope.props.actionInProgress = false;

    if (status === 400) {
      StaffCommon.onAllowanceValidationResponse(data);
      return;
    }

    if (status === 500) {
      AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.VIEW_FINALISATION.ERROR_500']);
    }
  }

  function undoAction() {
    $scope.props.actionInProgress = true;

    const {
      id,
      finalisedPayId,
      finalisedNotes,
    } = allowance;

    AvailabilityService.updateAllowance(id, {
      finalised: true,
      finalisedPayId,
      finalisedNotes,
    })
      .then(() => {
        $scope.props.actionInProgress = false;
      })
      .catch(({ status, data }) => {
        onApiError(status, data);
      });
  }

  $scope.revert = () => {
    if (!$window.confirm(translations['STAFF.VIEW.ABSENCE.VIEW_FINALISATION.CONFIRM_REVERT'])) {
      return;
    }

    $scope.props.actionInProgress = true;

    const {
      id,
      userId,
      finalisedPayId,
    } = allowance;

    AvailabilityService.updateAllowance(id, {
      finalised: false,
    })
      .then(() => {
        if (!finalisedPayId) {
          closeAndRefresh();
          return;
        }

        StaffService.deletePay(finalisedPayId, userId)
          .then(() => {
            closeAndRefresh();
          })
          .catch(({ status, data }) => {
            $scope.props.actionInProgress = false;

            undoAction();

            if (status === 400) {
              StaffCommon.onEmploymentValidationResponse(data.validationResult);
              return;
            }

            if (status === 403) {
              AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.VIEW_FINALISATION.ERROR_403']);
              return;
            }

            if (status === 500) {
              AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.VIEW_FINALISATION.ERROR_500']);
            }
          });
      })
      .catch(({ status, data }) => {
        onApiError(status, data);
      });
  };

  $scope.goToPayRecord = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    $state.go('staff.view.pay.history', {
      id: allowance.userId,
      payId: $scope.props.allowance.finalisedPayId,
    });
    $scope.close();
  };
};
