module.exports = (
  $scope,
  $state,
  $stateParams,
  $http,
  $translate,
  $uibModal,
  SessionService,
  EnvironmentDataService,
  AlertService,
  ExportService,
  ENDPOINT_API,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.TITLE',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.PAY_AMOUNT_TYPE_HOURLY',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.PAY_AMOUNT_TYPE_DAILY',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.PAY_AMOUNT_TYPE_ANNUAL',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.DATA_SOURCE_SHIFTS',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.DATA_SOURCE_MATCHED',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_UNIT_HOURS',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_UNIT_DAYS',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_PERIOD_WEEK',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_PERIOD_MONTH',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_PERIOD_WEEKLY',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_PERIOD_MONTHLY',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.DATE_ERROR_DIFF',
    'REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.ERROR_500',
  ]);

  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];
  const defaultDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('week').subtract(1, 'week');
  const defaultDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('week').subtract(1, 'day');
  const defaultPayAmountTypes = $stateParams.payAmountTypes
    ? $stateParams.payAmountTypes.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];
  const defaultDataSources = $stateParams.dataSource ? [$stateParams.dataSource] : ['sanitised'];
  const defaultUserId = $stateParams.userId || undefined;

  const payAmountTypes = new Map([
    [0, { id: 0, label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.PAY_AMOUNT_TYPE_HOURLY'] }],
    [2, { id: 2, label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.PAY_AMOUNT_TYPE_DAILY'] }],
    [1, { id: 1, label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.PAY_AMOUNT_TYPE_ANNUAL'] }],
  ]);

  const contractualUnits = new Map([
    [0, { id: 0, label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_UNIT_HOURS'] }],
    [1, { id: 1, label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_UNIT_DAYS'] }],
  ]);

  const contractualPeriods = new Map([
    [0, { id: 0, label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_PERIOD_WEEK'] }],
    [1, { id: 1, label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_PERIOD_MONTH'] }],
  ]);

  const contractualPeriodOptions = [
    { id: 0, label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_PERIOD_WEEKLY'] },
    { id: 1, label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.CONTRACTUAL_PERIOD_MONTHLY'] },
  ];

  $scope.props = {
    loadingData: false,
    data: [],
    defaultDateFilter: {
      option: 4,
      dateStart: defaultDateStart,
      dateEnd: defaultDateEnd,
    },
    defaultEntityIds,
    payAmountTypes: Array.from(payAmountTypes.values()),
    selectedDateStart: defaultDateStart,
    selectedDateEnd: defaultDateEnd,
    selectedEntityIds: defaultEntityIds,
    selectedPayAmountTypes: defaultPayAmountTypes,
    entityGroupsById: {},
    entityById: {},
    dataSources: [
      { id: 'shifts', label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.DATA_SOURCE_SHIFTS'] },
      { id: 'sanitised', label: translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.DATA_SOURCE_MATCHED'] },
    ],
    selectedDataSources: defaultDataSources,
    selectedUserId: defaultUserId,
    contractualUnits: Array.from(contractualUnits.values()),
    contractualUnitsById: Object.fromEntries(contractualUnits),
    contractualPeriods: Array.from(contractualPeriods.values()),
    contractualPeriodsById: Object.fromEntries(contractualPeriods),
    contractualPeriodOptions,
    selectedContractualPeriods: [],
  };

  function evaluateQueryParams() {
    $state.go('.', {
      start: $scope.props.selectedDateStart.format(),
      end: $scope.props.selectedDateEnd.format(),
      entityIds: $scope.props.selectedEntityIds ? $scope.props.selectedEntityIds.join(',') : undefined,
      payAmountTypes: $scope.props.selectedPayAmountTypes ? $scope.props.selectedPayAmountTypes.join(',') : undefined,
      dataSource: $scope.props.selectedDataSources[0],
      userId: $scope.props.selectedUserId,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $scope.getDataParams = {
      startDate: $scope.props.selectedDateStart.format(),
      endDate: $scope.props.selectedDateEnd.clone().add(1, 'day').format(),
      timezone: SessionService.getTimezone(),
      'entityIds[]': $scope.props.selectedEntityIds,
      'userPayAmountTypes[]': $scope.props.selectedPayAmountTypes,
      dataSource: $scope.props.selectedDataSources[0],
      userId: $scope.props.selectedUserId,
    };
  }

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.props.selectedEntityIds = entityIds.length ? entityIds : undefined;
    $scope.loadData();
  };

  $scope.onPayAmountTypeFilter = (selectedOptions) => {
    $scope.props.selectedPayAmountTypes = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData();
  };

  $scope.onDataSourceFilter = (selectedOptions) => {
    $scope.props.selectedDataSources = selectedOptions;
    $scope.loadData();
  };

  $scope.onUserFilter = (selectedUser) => {
    $scope.props.selectedUserId = selectedUser ? selectedUser.id : undefined;
    $scope.loadData();
  };

  $scope.onContractualPeriodFilter = (selectedOptions) => {
    $scope.props.selectedContractualPeriods = selectedOptions.length ? selectedOptions : [];
  };

  $scope.rowFilter = (row) => {
    if (!$scope.props.selectedContractualPeriods.length) {
      return true;
    }

    const { obligation: { period } } = row.data;

    return $scope.props.selectedContractualPeriods.includes(period);
  };

  $scope.onDateFilter = ({
    dateEnd,
    dateStart,
  }) => {
    $scope.props.selectedDateStart = dateStart;
    $scope.props.selectedDateEnd = dateEnd;

    $scope.loadData();
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    let dateStart;
    let dateEnd;

    if (when === 'thisWeek') {
      dateStart = moment().startOf('week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastWeek') {
      dateStart = moment().startOf('week').subtract(1, 'week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastTwoWeeks') {
      dateStart = moment().startOf('week').subtract(2, 'weeks');
      dateEnd = dateStart.clone().add(13, 'days');
    } else if (when === 'lastMonth') {
      dateStart = moment().startOf('month').subtract(1, 'month');
      dateEnd = moment().startOf('month').subtract(1, 'day');
    }

    $scope.props.defaultDateFilter = {
      option: 4,
      dateStart,
      dateEnd,
    };

    $scope.onDateFilter({
      dateStart,
      dateEnd,
    });
  };

  $scope.loadData = () => {
    $scope.props.hasRequiredFilters = ($scope.props.selectedEntityIds
      && $scope.props.selectedEntityIds.length) || $scope.props.selectedUserId;

    if (!$scope.props.hasRequiredFilters) {
      return;
    }

    const diff = $scope.props.selectedDateEnd.clone().add(1, 'day').diff($scope.props.selectedDateStart, 'weeks', true);

    if (diff < 0 || diff > 14) {
      AlertService.add('info', translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.DATE_ERROR_DIFF']);
      return;
    }

    evaluateQueryParams();

    $scope.props.loadingData = true;

    $http.get(`${ENDPOINT_API}/report/contractualObligationSummary`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const {
          summaryRows,
        } = data;

        $scope.props.data = summaryRows;
        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.ERROR_500']);
        }
      });
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
    .then(({ data }) => {
      data.forEach((group) => {
        $scope.props.entityGroupsById[group.id] = group;
      });

      $scope.props.groupedEntities = data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => {
            $scope.props.entityById[entity.id] = entity;

            return {
              id: entity.id,
              label: entity.name,
              parentId: entity.groupId,
              depth: 1,
            };
          }),
        ]));
    });

  $scope.export = ($event, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.CONTRACTUAL_OBLIGATION_SUMMARY.TITLE'],
      'exportTable', format);
  };

  $scope.loadData();
};
