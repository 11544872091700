module.exports = (
  $scope,
  $translate,
  AvailabilityService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'ABSENCE.EDIT.CALENDAR_UNPAID_DAY',
  ]);

  const { id: headerId } = $scope.absence.header;

  $scope.props.calendarShowAll = false;

  $scope.props.calendarEvents = $scope.props.days.map(({
    date,
    isDayOff,
  }) => ({
    title: isDayOff
      ? translations['ABSENCE.EDIT.CALENDAR_UNPAID_DAY']
      : $scope.absence.header.type.name,
    start: moment(date),
    end: moment(date).add(1, 'day'),
    allDay: true,
    className: 'event-unconfirmed',
    resource: $scope.absence.header.userId,
  }));

  $scope.calendarTypeChanged = () => {
    $('#schedule').fullCalendar('refetchEvents');
  };

  function fetchAbsenceData(callback) {
    AvailabilityService.getAbsencePaginated($scope.getAbsenceDataParams)
      .then(({ data }) => {
        $scope.getAbsenceDataState.data.push(...data.absence
          .filter((r) => r.id !== headerId)
          .map((r) => ({
            id: `3-${r.id}`,
            title: `${r.user.firstName} ${r.user.lastName}`,
            allDay: true,
            start: moment(r.localDateStart),
            end: moment(r.localDateEnd),
            resource: r.type.id,
            className: 'event-absence',
            editable: false,
            eventType: 3,
            eventId: r.id,
            color: r.type.colour,
          })));

        $scope.getAbsenceDataParams.page += 1;

        if (data.absence.length < $scope.getAbsenceDataParams.limit) {
          $scope.getAbsenceDataState.noMoreData = true;
          callback();
        } else {
          fetchAbsenceData(callback);
        }
      });
  }

  $scope.eventSources = [(start, end, timezone, callback) => {
    if ($scope.props.calendarShowAll) {
      $scope.getAbsenceDataParams = {
        dateContext: 'overlapping',
        'approvalStates[]': [1],
        'currentEntityIds[]': [$scope.absence.header.user.appointment.entityId],
        start: moment(start).format(),
        end: moment(end).format(),
        limit: 100,
        page: 1,
        sortOrder: 'startDateAsc',
      };

      $scope.getAbsenceDataState = {
        noMoreData: false,
        data: [],
      };

      fetchAbsenceData(() => {
        callback($scope.getAbsenceDataState.data);
      });
    } else {
      AvailabilityService.getCalendar(start, end, $scope.absence.header.userId, true)
        .then(({ data }) => {
          callback(data
            .filter((e) => !(e.eventId === headerId && e.eventType === 3))
            .map((e) => ({
              ...e,
              // We MUST do this otherwise fullcalendar ignores the timezone set on moment
              // by sessionService and uses the client's local timezone instead
              start: moment(e.start),
              end: moment(e.end),
              // Prevent objects from being dragged or resized
              editable: false,
            })));
        });
    }
  }];
};
