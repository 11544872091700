var filters = require('./module');

filters.filter('momentFormat', function () {
    return function (date, format, adjustment, unit) {
        if (!date) {
            return '';
        }

        if (typeof date === 'string') {
            if (date.length === 8) {
                // Handle time-only strings
                date = moment(date, "HH:mm:ss");
            } else if (date.length > 8) {
                // Also handle full ISO date strings
                date = moment(date);
            }
        } else if (typeof date === 'number') {
            // Handle a unix timestamp
            date = moment(date);
        }

        if (adjustment) {
            date.add(adjustment, unit);
        }

        return date.format(format);
    };
});
