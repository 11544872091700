var controllers = require('../../module');

controllers.controller('SettingsEditPayElementTypeModalCtrl', ['$scope', '$uibModalInstance', 'data', 'SettingsService', 'AlertService',
    function ($scope, $uibModalInstance, data, SettingsService, AlertService) {
        $scope.formData = {};
        $scope.saveActioning = false;

        $scope.isAdd = data.isAdd;

        if (!data.isAdd) {
            var type = data.type;

            $scope.formData = {
                name: type.name,
                variant: type.variant,
                multipleOfRate: type.multipleOfRate,
                ratePerUnit: type.ratePerUnit,
            };
        } else {
            $scope.formData = {
                name: '',
                variant: 'RATE_PER_UNIT',
            };
        }

        function handleError(err, status) {
            if (status !== 403) {
                AlertService.add('danger', 'We weren\'t able to update the pay type just then. Please try again');
            }

            $scope.saveActioning = false;
        }

        $scope.save = function () {
            $scope.saveActioning = true;

            if ($scope.isAdd) {
                SettingsService.addPayElementType($scope.formData)
                    .success(function () {
                        $uibModalInstance.close();
                    })
                    .error(handleError);
            } else {
                SettingsService.updatePayElementType(data.type.id, $scope.formData)
                    .success(function () {
                        $uibModalInstance.close();
                    })
                    .error(handleError);
            }
        };

        $scope.delete = function () {
            $scope.saveActioning = true;

            SettingsService.deletePayElementType(data.type.id)
                .success(function () {
                    $uibModalInstance.close();
                })
                .error(handleError);
        };

        $scope.close = function () {
            $uibModalInstance.dismiss();
        };
    }
]);
