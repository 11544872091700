module.exports = (
  $scope,
  $state,
  $stateParams,
  $http,
  $translate,
  EnvironmentDataService,
  AlertService,
  ExportService,
  ENDPOINT_API,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.ANNIVERSARY.TITLE',
    'REPORTS.ANNIVERSARY.DATE_ERROR_DIFF',
    'REPORTS.ANNIVERSARY.ERROR_500',
    'REPORTS.ANNIVERSARY.TYPE_BIRTHDAY',
    'REPORTS.ANNIVERSARY.TYPE_EMPLOYMENT',
  ]);

  const anniversaryTypes = [
    { id: 'Birthday', label: translations['REPORTS.ANNIVERSARY.TYPE_BIRTHDAY'] },
    { id: 'Employment', label: translations['REPORTS.ANNIVERSARY.TYPE_EMPLOYMENT'] },
  ];
  const anniversaryTypesById = new Map(anniversaryTypes.map((value) => ([value.id, value])));

  const defaultDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('week');
  const defaultDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('week').add(6, 'days');
  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];
  const defaultAnniversaryTypes = $stateParams.types
    ? $stateParams.types.split(',') : [];

  $scope.props = {
    loadingData: false,
    data: {},
    defaultDateFilter: {
      option: 4,
      dateStart: defaultDateStart,
      dateEnd: defaultDateEnd,
    },
    defaultEntityIds,
    selectedDateStart: defaultDateStart,
    selectedDateEnd: defaultDateEnd,
    selectedEntityIds: defaultEntityIds,
    selectedAnniversaryTypes: defaultAnniversaryTypes,
    entityById: {},
    anniversaryTypes,
    anniversaryTypesById,
  };

  function evaluateQueryParams() {
    const {
      selectedDateStart: start,
      selectedDateEnd: end,
      selectedEntityIds,
      selectedAnniversaryTypes,
    } = $scope.props;

    $state.go('.', {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
      entityIds: selectedEntityIds ? selectedEntityIds.join(',') : undefined,
      types: selectedAnniversaryTypes ? selectedAnniversaryTypes.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $scope.getDataParams = {
      dateRange: [
        start.format('YYYY-MM-DD'),
        end.clone().add(1, 'day').format('YYYY-MM-DD'),
      ].join('/'),
      'currentEntityIds[]': selectedEntityIds,
      'anniversaryTypes[]': selectedAnniversaryTypes,
    };
  }

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.props.selectedEntityIds = entityIds.length ? entityIds : undefined;
    $scope.loadData();
  };

  $scope.onAnniversaryTypeFilter = (selectedOptions) => {
    $scope.props.selectedAnniversaryTypes = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData();
  };

  $scope.onDateFilter = ({
    dateEnd,
    dateStart,
  }) => {
    $scope.props.selectedDateStart = dateStart;
    $scope.props.selectedDateEnd = dateEnd;

    $scope.loadData();
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    let dateStart;
    let dateEnd;

    if (when === 'thisWeek') {
      dateStart = moment().startOf('week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastWeek') {
      dateStart = moment().startOf('week').subtract(1, 'week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastTwoWeeks') {
      dateStart = moment().startOf('week').subtract(2, 'weeks');
      dateEnd = dateStart.clone().add(13, 'days');
    } else if (when === 'lastMonth') {
      dateStart = moment().startOf('month').subtract(1, 'month');
      dateEnd = moment().startOf('month').subtract(1, 'day');
    }

    $scope.props.defaultDateFilter = {
      option: 4,
      dateStart,
      dateEnd,
    };

    $scope.onDateFilter({
      dateStart,
      dateEnd,
    });
  };

  $scope.loadData = () => {
    const diff = $scope.props.selectedDateEnd.clone().add(1, 'day').diff($scope.props.selectedDateStart, 'years', true);

    if (diff < 0 || diff > 1) {
      AlertService.add('info', translations['REPORTS.ANNIVERSARY.DATE_ERROR_DIFF']);
      return;
    }

    evaluateQueryParams();

    $scope.props.loadingData = true;

    $http.get(`${ENDPOINT_API}/report/anniversary`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const { results } = data;
        $scope.props.data = results;
        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.ANNIVERSARY.ERROR_500']);
        }
      });
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
  ])
    .then(([
      entityGroup,
    ]) => {
      $scope.props.entityGroupList = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => {
            $scope.props.entityById[entity.id] = entity;

            return {
              id: entity.id,
              label: entity.name,
              parentId: entity.groupId,
              depth: 1,
            };
          }),
        ]));

      $scope.loadData();
    });

  $scope.export = ($event, tableId, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.ANNIVERSARY.TITLE'],
      tableId, format);
  };
};
