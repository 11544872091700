module.exports = (
  $scope,
  $translate,
  $window,
  AvailabilityService,
  SessionService,
  AlertService,
  StaffService,
  StaffCommon,
) => {
  'ngInject';

  const { headerId } = $scope.props;

  $scope.props.permittedExtensions = StaffCommon.permittedFileExtensions;

  const translations = $translate.instant([
    'ABSENCE.EDIT.ERROR_500',
    'ABSENCE.EDIT.FILES_CONFIRM_DELETE',
  ]);

  $scope.onFileUpload = ({ name: fileName, key }) => {
    $scope.props.actionInProgress = true;

    AvailabilityService.addAbsenceDocument(headerId, {
      documents: [{
        fileName,
        key,
      }],
    })
      .then(({ data }) => {
        const [id] = data.documentIds;
        const name = SessionService.getUserFullName().split(' ');

        $scope.absence.documents.push({
          id,
          fileName,
          uploadedBy: {
            date: moment(),
            user: {
              id: SessionService.getUserId(),
              firstName: name[0],
              lastName: name.slice(1, name.length).join(' '),
            },
          },
        });

        $scope.props.actionInProgress = false;
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status !== 403) {
          AlertService.add('danger', translations['ABSENCE.EDIT.ERROR_500']);
        }
      });
  };

  $scope.deleteDocument = (document, index) => {
    if (document.actionInProgress) {
      return;
    }

    if (!$window.confirm(translations['ABSENCE.EDIT.FILES_CONFIRM_DELETE'])) {
      return false;
    }

    document.actionInProgress = true;

    StaffService.deleteDocument(document.id)
      .then(() => {
        $scope.absence.documents.splice(index, 1);
      })
      .catch(({ status }) => {
        document.actionInProgress = false;

        if (status !== 403) {
          AlertService.add('danger', translations['ABSENCE.EDIT.ERROR_500']);
        }
      });
  };

  $scope.viewDocument = (document) => {
    if (document.actionInProgress) {
      return;
    }

    document.actionInProgress = true;

    StaffService.getDocument(document.id, { download: true })
      .then(({ data }) => {
        const { signedUrl } = data;

        if (signedUrl) {
          $window.location = signedUrl;
        }

        document.actionInProgress = false;
      })
      .catch(({ status }) => {
        document.actionInProgress = false;

        if (status !== 403) {
          AlertService.add('danger', translations['ABSENCE.EDIT.ERROR_500']);
        }
      });
  };
};
