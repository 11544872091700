const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/danger/anonymiseForm.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/dialog/textboxDialog.html');

module.exports = (
  $scope,
  $state,
  $stateParams,
  $translate,
  $window,
  $uibModal,
  $uibModalDrawer,
  StaffService,
  AlertService,
  SessionService,
  WFM_ENDPOINT,
) => {
  'ngInject';

  // Setting this causes child scopes (such as those in the absence and availability controllers) to
  // include it in their requests
  $scope.userId = $stateParams.id;
  $scope.dataLoaded = false;
  $scope.areShiftRoutinesEnabled = SessionService.areShiftRoutinesEnabled();
  $scope.isAssetRegistryEnabled = SessionService.isAssetRegistryEnabled();

  const translations = $translate.instant([
    'STAFF.OVERVIEW.ACCOUNT_TAB_LABEL',
    'STAFF.OVERVIEW.PERSONAL_TAB_LABEL',
    'STAFF.OVERVIEW.EMPLOYMENT_TAB_LABEL',
    'STAFF.OVERVIEW.PAY_TAB_LABEL',
    'STAFF.OVERVIEW.SCHEDULE_TAB_LABEL',
    'STAFF.OVERVIEW.ABSENCE_TAB_LABEL',
    'STAFF.OVERVIEW.AVAILABILITY_TAB_LABEL',
    'STAFF.OVERVIEW.SHIFT_ROUTINE_TAB_LABEL',
    'STAFF.OVERVIEW.DOCUMENTS_TAB_LABEL',
    'STAFF.OVERVIEW.ATTENDANCE_TAB_LABEL',
    'STAFF.OVERVIEW.NOTES_TAB_LABEL',
    'STAFF.OVERVIEW.ASSET_REGISTRY_TAB_LABEL',
    'STAFF.VIEW.INDEX.ALERT_MAGIC_LINK_SUCCESS_ALL',
    'STAFF.VIEW.INDEX.ALERT_MAGIC_LINK_SUCCESS_EMAIL',
    'STAFF.VIEW.INDEX.ALERT_MAGIC_LINK_SUCCESS_SMS',
    'STAFF.VIEW.INDEX.ALERT_MAGIC_LINK_ERROR_GENERIC',
    'STAFF.VIEW.INDEX.ALERT_MAGIC_LINK_ERROR_NO_DETAILS',
    'STAFF.VIEW.INDEX.ALERT_APPROVE_SUCCESS',
    'STAFF.VIEW.INDEX.ALERT_APPROVE_ERROR',
    'STAFF.VIEW.INDEX.ALERT_ACCOUNT_ERROR_500',
    'STAFF.VIEW.INDEX.ALERT_ACCOUNT_ERROR_404',
    'STAFF.VIEW.INDEX.DELETE_ACCOUNT_TITLE',
    'STAFF.VIEW.INDEX.DELETE_ACCOUNT_MESSAGE',
    'STAFF.VIEW.INDEX.DELETE_ACCOUNT_BUTTON',
    'STAFF.VIEW.INDEX.DELETE_ACCOUNT_ERROR',
    'STAFF.VIEW.INDEX.DELETE_ACCOUNT_SUCCESS',
  ]);

  $scope.tabs = {
    options: [
      {
        state: 'account',
        label: translations['STAFF.OVERVIEW.ACCOUNT_TAB_LABEL'],
      },
      {
        state: 'personal',
        label: translations['STAFF.OVERVIEW.PERSONAL_TAB_LABEL'],
      },
      {
        state: 'employment',
        label: translations['STAFF.OVERVIEW.EMPLOYMENT_TAB_LABEL'],
      },
      {
        state: 'pay',
        label: translations['STAFF.OVERVIEW.PAY_TAB_LABEL'],
        transitionTo: 'pay.history',
      },
      {
        state: 'schedule',
        label: translations['STAFF.OVERVIEW.SCHEDULE_TAB_LABEL'],
      },
      {
        state: 'absence',
        label: translations['STAFF.OVERVIEW.ABSENCE_TAB_LABEL'],
        transitionTo: 'absence.bookings',
      },
      {
        state: 'documents',
        label: translations['STAFF.OVERVIEW.DOCUMENTS_TAB_LABEL'],
      },
      {
        state: 'notes',
        label: translations['STAFF.OVERVIEW.NOTES_TAB_LABEL'],
      },
      {
        state: 'attendance',
        label: translations['STAFF.OVERVIEW.ATTENDANCE_TAB_LABEL'],
      },
      {
        state: 'availability',
        label: translations['STAFF.OVERVIEW.AVAILABILITY_TAB_LABEL'],
      },
      $scope.areShiftRoutinesEnabled && {
        state: 'shiftRoutines',
        label: translations['STAFF.OVERVIEW.SHIFT_ROUTINE_TAB_LABEL'],
      },
      $scope.isAssetRegistryEnabled && {
        state: 'assetRegistry',
        label: translations['STAFF.OVERVIEW.ASSET_REGISTRY_TAB_LABEL'],
        icon: 'fal fa-external-link',
        externalLink: () => `${WFM_ENDPOINT}/staff/${$scope.userData.employment.id}/assets`,
      },
    ].filter(Boolean),
    currentState: $state.current.name.split('.')[2],
  };

  $scope.onTabClick = (tab) => {
    $scope.tabs.currentState = tab.state;
    $scope.onTabChange();
  };

  $scope.onTabChange = () => {
    const tab = $scope.tabs.options.find((t) => t.state === $scope.tabs.currentState);

    if (tab) {
      if (tab.externalLink) {
        $window.location = tab.externalLink();
      } else {
        $state.go(`staff.view.${tab.transitionTo || tab.state}`);
      }
    }
  };

  function loadAccount() {
    StaffService.getAccount($scope.userId)
      .then(({ data }) => {
        $scope.userData = data.user;
        $scope.dataLoaded = true;
      })
      .catch(({ status }) => {
        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.INDEX.ALERT_ACCOUNT_ERROR_500']);
        } else if (status === 404) {
          AlertService.add('danger', translations['STAFF.VIEW.INDEX.ALERT_ACCOUNT_ERROR_404']);
        }

        $state.go('staff.overview');
      });
  }

  loadAccount();

  $scope.$on('user:reload', () => loadAccount());

  $scope.sendMagicLink = () => {
    if ($scope.userData.flags.setupComplete) {
      return;
    }

    StaffService.resendInstructions($scope.userData.id)
      .then(() => {
        const {
          personal: {
            email,
            mobile,
            welcomeAttempts,
          },
        } = $scope.userData;

        const hasEmail = !!email;
        const hasMobile = !!mobile;

        // This should never be hit, but a fallback just in case
        if (!hasEmail && !hasMobile) {
          AlertService.add('danger', translations['STAFF.VIEW.INDEX.ALERT_MAGIC_LINK_ERROR_NO_DETAILS']);
          return;
        }

        let message;

        if (hasEmail) {
          if (welcomeAttempts > 1 && hasMobile) {
            message = translations['STAFF.VIEW.INDEX.ALERT_MAGIC_LINK_SUCCESS_ALL'];
          } else {
            message = translations['STAFF.VIEW.INDEX.ALERT_MAGIC_LINK_SUCCESS_EMAIL'];
          }
        } else if (hasMobile) {
          message = translations['STAFF.VIEW.INDEX.ALERT_MAGIC_LINK_SUCCESS_SMS'];
        }

        AlertService.add('success', message);
      })
      .catch(({ status }) => {
        if (status !== 403) {
          AlertService.add('danger', translations['STAFF.VIEW.INDEX.ALERT_MAGIC_LINK_ERROR_GENERIC']);
        }
      });
  };

  $scope.approve = () => {
    if ($scope.userData.flags.approved) {
      return;
    }

    StaffService.approveAccount($scope.userData.id, { approve: true })
      .then(() => {
        AlertService.add('success', translations['STAFF.VIEW.INDEX.ALERT_APPROVE_SUCCESS']);
        $scope.userData.flags.approved = true;
      })
      .catch(({ status }) => {
        if (status !== 403) {
          AlertService.add('danger', translations['STAFF.VIEW.INDEX.ALERT_APPROVE_ERROR']);
        }
      });
  };

  $scope.anonymise = () => {
    const {
      employment: {
        dateEnd,
      },
      account: {
        anonymised,
        anonymisedDate,
      },
    } = $scope.userData;

    if (!dateEnd || anonymised) {
      return;
    }

    const anonymiseModal = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./danger/anonymiseForm'),
      resolve: {
        data: () => ({
          userId: $scope.userId,
          employmentDateEnd: dateEnd,
          anonymisedDate,
        }),
      },
    });

    anonymiseModal.result.then(({ anonymisedDate }) => {
      $scope.userData.account.anonymisedDate = anonymisedDate || undefined;
    });
  };

  $scope.delete = () => {
    const {
      id,
      firstName,
      lastName,
      flags: {
        active,
      },
    } = $scope.userData;

    if (active) {
      return;
    }

    const placeholder = `${firstName} ${lastName}`;

    $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate2,
      controller: ($scope, $state, $uibModalInstance, StaffService) => {
        $scope.info = {
          title: translations['STAFF.VIEW.INDEX.DELETE_ACCOUNT_TITLE'],
          message: translations['STAFF.VIEW.INDEX.DELETE_ACCOUNT_MESSAGE'],
        };

        $scope.textBox = {
          placeholder,
        };

        $scope.btn = {
          className: 'btn-danger',
          label: translations['STAFF.VIEW.INDEX.DELETE_ACCOUNT_BUTTON'],
          disabled: true,
        };

        $scope.$watch('textBox.model', (newValue) => {
          if (!newValue) {
            return;
          }

          $scope.btn.disabled = newValue.toLowerCase() !== placeholder.toLowerCase();
        });

        $scope.buttonClick = (option) => {
          if (!option) {
            return $uibModalInstance.dismiss('cancel');
          }

          $scope.btn.disabled = true;

          StaffService.updateAccount(id, {
            user: {
              deleted: true,
            },
          })
            .then(() => {
              AlertService.add('success', translations['STAFF.VIEW.INDEX.DELETE_ACCOUNT_SUCCESS']);
              $uibModalInstance.close();
              $state.go('staff.overview');
            })
            .catch(() => {
              $scope.btn.disabled = false;
              AlertService.add('danger', translations['STAFF.VIEW.INDEX.DELETE_ACCOUNT_ERROR']);
            });
        };
      },
    });
  };
};
