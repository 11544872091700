const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messaging/conversationDrawer.html');

const controllers = require('../module');

controllers.controller('MessagingPopoverCtrl', ['$scope', '$uibModalDrawer', 'MessagingService', ($scope, $uibModalDrawer, MessagingService) => {
  const { title, message, channel } = MessagingService.getLatestNotification();

  $scope.props = {
    title,
    message,
  };

  $scope.onClick = async () => {
    const participantIds = await MessagingService.getConversationParticipantsIds(channel);
    const [{ participants, channelId }] = await MessagingService
      .getConversationWithParticipants(participantIds);

    $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./conversationDrawer'), // eslint-disable-line
      backdrop: 'static',
      resolve: {
        data: () => ({
          channelId,
          participants,
          participantIds,
        }),
      },
    });
  };
}]);
