const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/availability/weeklyAvailabilitySingleModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/availability/weeklyAvailabilityModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/availability/monthlyAvailabilityModal.html');
const autoNgTemplateLoaderTemplate4 = require('/home/circleci/project/app/htdocs/views/availability/dailyAvailabilityModal.html');
const autoNgTemplateLoaderTemplate5 = require('/home/circleci/project/app/htdocs/views/availability/weeklyAvailabilityModal.html');
const autoNgTemplateLoaderTemplate6 = require('/home/circleci/project/app/htdocs/views/availability/monthlyAvailabilityModal.html');
const autoNgTemplateLoaderTemplate7 = require('/home/circleci/project/app/htdocs/views/availability/dailyAvailabilityModal.html');

'use strict';
var controllers = require('../module');

controllers.controller('AvailabilityScheduleCtrl', ['$scope', '$uibModal', '$state', 'AvailabilityService', 'AlertService', '$translate',
    function ($scope, $uibModal, $state, availabilityService, alertService, $translate) {
        var labelTranslations = $translate.instant([
            'AVAILABILITY.AVAILABILITY.CONFIRM_DELETE_PATTERN',
            'AVAILABILITY.AVAILABILITY.DELETE_PATTERN_SUCCESS',
            'AVAILABILITY.AVAILABILITY.ERROR_DELETING_PATTERN',
            'AVAILABILITY.AVAILABILITY.ERROR_TOGGLING_PATTERN',
            'AVAILABILITY.AVAILABILITY.ERROR_LOADING_PATTERNS',
        ])
        $scope.dataLoaded = false;

        $scope.newOneOffWeek = function () {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'WeeklyAvailabilitySingleModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            userIdOverride: $scope.userId
                        }
                    }
                }
            });

            modalInstance.result.then(function (newPattern) {
                $scope.availability.push(newPattern);
            });
        };

        $scope.newWeeklyPattern = function () {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'WeeklyAvailabilityModalCtrl',
                size: 'lg',
                resolve: {
                    data: function () {
                        return {
                            isAdd: true,
                            userIdOverride: $scope.userId
                        }
                    }
                }
            });

            modalInstance.result.then(function (newPattern) {
                $scope.availability.push(newPattern);
            });
        };

        $scope.newMonthlyPattern = function () {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate3,
                controller: 'MonthlyAvailabilityModalCtrl',
                size: 'lg',
                resolve: {
                    data: function () {
                        return {
                            isAdd: true,
                            userIdOverride: $scope.userId
                        }
                    }
                }
            });

            modalInstance.result.then(function (newPattern) {
                $scope.availability.push(newPattern);
            });
        };

        $scope.newDailyPattern = function () {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate4,
                controller: 'DailyAvailabilityModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: true,
                            userIdOverride: $scope.userId
                        }
                    }
                }
            });

            modalInstance.result.then(function (newPattern) {
                $scope.availability.push(newPattern);
            });
        };

        $scope.editPattern = function (id, type) {
            var modalInstance;

            if (type == 1) {
                modalInstance = $uibModal.open({
                    templateUrl: autoNgTemplateLoaderTemplate5,
                    controller: 'WeeklyAvailabilityModalCtrl',
                    size: 'lg',
                    resolve: {
                        data: function () {
                            return {
                                isAdd: false,
                                id: id
                            }
                        }
                    }
                });
            } else if (type == 2) {
                modalInstance = $uibModal.open({
                    templateUrl: autoNgTemplateLoaderTemplate6,
                    controller: 'MonthlyAvailabilityModalCtrl',
                    size: 'lg',
                    resolve: {
                        data: function () {
                            return {
                                isAdd: false,
                                id: id
                            }
                        }
                    }
                });
            } else {
                modalInstance = $uibModal.open({
                    templateUrl: autoNgTemplateLoaderTemplate7,
                    controller: 'DailyAvailabilityModalCtrl',
                    resolve: {
                        data: function () {
                            return {
                                isAdd: false,
                                id: id
                            }
                        }
                    }
                });
            }

            modalInstance.result.then(function (pattern) {
                angular.forEach($scope.availability, function (availability, key) {
                    if (availability.id.toString() === pattern.id.toString()) {
                        $scope.availability[key] = pattern;
                    }
                });
            });
        };

        $scope.deletePattern = function (id) {
            if (!confirm(labelTranslations['AVAILABILITY.AVAILABILITY.CONFIRM_DELETE_PATTERN']))
                return;

            availabilityService.deleteAvailabilityPattern(id)
                .success(function () {
                    angular.forEach($scope.availability, function (availability, key) {
                        if (availability.id.toString() === id.toString()) {
                            $scope.availability.splice(key, 1);
                        }
                    });

                    alertService.add("success", labelTranslations['AVAILABILITY.AVAILABILITY.DELETE_PATTERN_SUCCESS']);
                })
                .error(function () {
                    alertService.add("danger", labelTranslations['AVAILABILITY.AVAILABILITY.ERROR_DELETING_PATTERN']);
                });
        };

        $scope.toggleActive = function (id, isActive) {
            angular.forEach($scope.availability, function (availability) {
                if (availability.id.toString() === id.toString()) {
                    availability.active = 2;
                }
            });

            var newState = !isActive;

            availabilityService.updatePatternState(id, {isActive: newState})
                .success(function () {
                    angular.forEach($scope.availability, function (availability) {
                        if (availability.id.toString() === id.toString()) {
                            availability.active = newState;
                        }
                    });
                })
                .error(function (error, status) {
                    angular.forEach($scope.availability, function (availability) {
                        if (availability.id.toString() === id.toString()) {
                            availability.active = isActive;
                        }
                    });

                    if (status == 500) {
                        alertService.add("danger", labelTranslations['AVAILABILITY.AVAILABILITY.ERROR_TOGGLING_PATTERN']);
                    }
                });
        };

        // Look for an override userId set on the scope (by a parent controller, i.e. the view staff page)
        availabilityService.getAvailabilityPatterns($scope.userId)
            .success(function (data) {
                $scope.availability = data;
                $scope.dataLoaded = true;
            })
            .error(function () {
                alertService.add("danger", labelTranslations['AVAILABILITY.AVAILABILITY.ERROR_LOADING_PATTERNS']);
                $state.go('^.schedule');
            });
    }]);
