/*
READ THIS FIRST:
https://rotaready.atlassian.net/wiki/spaces/~5b239199be73de20759a9308/pages/3275751425/Asynchronous+Reports
*/

module.exports = (
  $scope,
  $state,
  $stateParams,
  $http,
  $translate,
  $window,
  EnvironmentDataService,
  AlertService,
  SessionService,
  ENDPOINT_API,
) => {
  'ngInject';

  const translations = $translate.instant([
    'REPORTS.COST_CONTROL_RAW_DATA.TITLE',
    'REPORTS.COST_CONTROL_RAW_DATA.DATE_ERROR_DIFF',
    'REPORTS.COST_CONTROL_RAW_DATA.CALCULATE_ACTUAL_ERROR',
    'REPORTS.COST_CONTROL_RAW_DATA.ERROR_500',
    'REPORTS.COST_CONTROL_RAW_DATA.METRIC_HOURS',
    'REPORTS.COST_CONTROL_RAW_DATA.METRIC_WAGES',
    'REPORTS.COST_CONTROL_RAW_DATA.METRIC_SALES',
    'REPORTS.COST_CONTROL_RAW_DATA.METRIC_COVERS',
    'REPORTS.COST_CONTROL_RAW_DATA.SUBDIVISION_PREDICTION',
    'REPORTS.COST_CONTROL_RAW_DATA.SUBDIVISION_FORECAST',
    'REPORTS.COST_CONTROL_RAW_DATA.SUBDIVISION_ACTUAL',
  ]);

  const defaultDateStart = moment().startOf('week').subtract(1, 'week');
  const defaultDateEnd = moment().startOf('week').subtract(1, 'day');
  const defaultEntityIds = [];
  const defaultMetricOptions = ['hours', 'wages'];
  const defaultSubdivisionOptions = ['forecast', 'actual'];

  $scope.props = {
    loadingData: false,
    defaultDateFilter: {
      option: 4,
      dateStart: defaultDateStart,
      dateEnd: defaultDateEnd,
    },
    selectedDateStart: defaultDateStart,
    selectedDateEnd: defaultDateEnd,
    defaultEntityIds,
    metricOptions: [
      { id: 'hours', label: translations['REPORTS.COST_CONTROL_RAW_DATA.METRIC_HOURS'] },
      { id: 'wages', label: translations['REPORTS.COST_CONTROL_RAW_DATA.METRIC_WAGES'] },
      { id: 'sales', label: translations['REPORTS.COST_CONTROL_RAW_DATA.METRIC_SALES'] },
      { id: 'covers', label: translations['REPORTS.COST_CONTROL_RAW_DATA.METRIC_COVERS'] },
    ],
    defaultMetricOptions,
    subdivisionOptions: [
      { id: 'prediction', label: translations['REPORTS.COST_CONTROL_RAW_DATA.SUBDIVISION_PREDICTION'] },
      { id: 'forecast', label: translations['REPORTS.COST_CONTROL_RAW_DATA.SUBDIVISION_FORECAST'] },
      { id: 'actual', label: translations['REPORTS.COST_CONTROL_RAW_DATA.SUBDIVISION_ACTUAL'] },
    ],
    defaultSubdivisionOptions,
    reportInProgress: false,
  };

  $scope.props.formData = {
    'entityIds[]': defaultEntityIds,
    'metrics[]': defaultMetricOptions,
    'subdivisions[]': defaultSubdivisionOptions,
    calculateActual: false,
  };

  $scope.onDateFilter = ({
    dateEnd,
    dateStart,
  }) => {
    $scope.props.selectedDateStart = dateStart;
    $scope.props.selectedDateEnd = dateEnd;
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    let dateStart;
    let dateEnd;

    if (when === 'thisWeek') {
      dateStart = moment().startOf('week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastWeek') {
      dateStart = moment().startOf('week').subtract(1, 'week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastTwoWeeks') {
      dateStart = moment().startOf('week').subtract(2, 'weeks');
      dateEnd = dateStart.clone().add(13, 'days');
    } else if (when === 'lastMonth') {
      dateStart = moment().startOf('month').subtract(1, 'month');
      dateEnd = moment().startOf('month').subtract(1, 'day');
    }

    $scope.props.defaultDateFilter = {
      option: 4,
      dateStart,
      dateEnd,
    };

    $scope.onDateFilter({
      dateStart,
      dateEnd,
    });
  };

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.props.formData['entityIds[]'] = entityIds.length ? entityIds : undefined;
  };

  $scope.onMetricFilter = (selectedOptions) => {
    $scope.props.formData['metrics[]'] = selectedOptions.length ? selectedOptions : undefined;
  };

  $scope.onSubdivisionFilter = (selectedOptions) => {
    $scope.props.formData['subdivisions[]'] = selectedOptions.length ? selectedOptions : undefined;
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
  ])
    .then(([
      entityGroup,
    ]) => {
      $scope.props.entityGroupList = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => ({
            id: entity.id,
            label: entity.name,
            parentId: entity.groupId,
            depth: 1,
          })),
        ]));
    });

  $scope.export = async () => {
    const {
      selectedDateStart: start,
      selectedDateEnd: end,
      formData: {
        'entityIds[]': entityIds,
      },
    } = $scope.props;

    if (!entityIds.length || start.isAfter(end)) {
      return;
    }

    $scope.props.loadingData = true;
    $scope.props.reportInProgress = true;

    await $http({
      method: 'GET',
      url: `${ENDPOINT_API}/report/costControlRawDataAsyncFileExport`,
      data: '', // angular removes the content-type header unless this is present
      params: {
        dateRange: `${start.format('YYYY-MM-DD')}/${end.clone().add(1, 'day').format('YYYY-MM-DD')}`,
        locale: SessionService.getUserLocale(),
        timezone: SessionService.getTimezone(),
        ...$scope.props.formData,
      },
    });

    $scope.props.loadingData = false;
    $scope.props.reportInProgress = false;
  };
};
