var controllers = require('../../module');

controllers.controller('AddRoutineMappingStaffModalCtrl', ['$uibModalInstance', '$scope', 'data', 'StaffService', 'AlertService',
    function($uibModalInstance, $scope, data, StaffService, AlertService) {
        $scope.user = data.user;

        $scope.startDate = moment();
        $scope.endDate = moment();

        StaffService.getShiftRoutines()
            .success(function(data) {
                $scope.routines = data;
            })
            .error(function() {
                $uibModalInstance.dismiss();
            });

        $scope.dateChanged = function(newDate, isStart) {
            $scope.hasOverlap = false;
            $scope.endBeforeStart = false;

            var openEnded = !$scope.hasEnd;

            var startDate = $scope.startDate.clone();
            var endDate = $scope.endDate.clone();

            if (newDate && isStart) {
                startDate = newDate;
            } else if (newDate) {
                endDate = newDate;
            }

            if (!openEnded && endDate.isBefore(startDate)) {
                $scope.endBeforeStart = true;
                return;
            }

            data.mappings.forEach(function (mapping) {
                if ($scope.hasOverlap) return;

                var mappingStart = moment(mapping.date_start).clone().startOf('day');
                // Our end date is inclusive so we grab to 'display_end' of the mapping we're comparing to,
                // which is also inclusive
                var mappingEnd = moment(mapping.display_end).clone().startOf('day');

                // If both mappings have no end, there's an overlap
                if (openEnded && !mappingEnd.isValid()) {
                    $scope.hasOverlap = true;
                    return;
                }

                var newEnd;

                // If the existing mapping is finite but our new one is open ended,
                // we can spoof an end date by making it one day after the end of the existing mapping
                if (openEnded) {
                    newEnd = mappingEnd.clone().add(1, 'day');
                } else {
                    newEnd = endDate;
                }

                var existingEnd;

                // Similarly, if our new mapping is finite but the existing one has no end,
                // we can spoof an end date by making it end one day after our new mapping
                if (!mappingEnd.isValid()) {
                    existingEnd = endDate.clone().add(1, 'day');
                } else {
                    existingEnd = mappingEnd;
                }

                // Since we've got two finite date ranges we can just do a normal date comparison
                if ((mappingStart.isBefore(newEnd) || mappingStart.isSame(newEnd)) &&
                (existingEnd.isAfter(startDate) || existingEnd.isSame(startDate))) {
                    $scope.hasOverlap = true;
                    return;
                }
            });
        };

        $scope.dateChanged();

        $scope.saveRoutine = function(skipIssues) {
            $scope.saveActioning = true;

            var fields = {
                user_id: $scope.user.id,
                routine_id: $scope.routine,
                date_start: $scope.startDate.format('YYYY-MM-DD'),
                date_end: $scope.hasEnd ? $scope.endDate.clone().add(1, 'day').format('YYYY-MM-DD') : null,
                offset: $scope.offset,
                skip_issues: skipIssues
            };

            StaffService.addRoutineMapping($scope.user.id, fields)
                .success(function() {
                    $scope.saveActioning = false;
                    AlertService.add('success', 'Shift routine saved successfully.');
                    $uibModalInstance.close();
                })
                .error(function(err, status) {
                    $scope.saveActioning = false;
                    if (status == 400) {
                        $scope.validationFailure = err.validationFailure;
                        return;
                    }

                    // They don't have permission, so close the modal
                    $uibModalInstance.dismiss();
                });
        };

        $scope.close = function() {
            $uibModalInstance.dismiss();
        }

    }
]);
