const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/dateTimePicker.html');

const directives = require('./module');

directives.directive('rrWrappedDateTimePicker', () => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      date: '=',
      config: '=',
      onChange: '&',
      beforeRender: '&',
    },
    link: ($scope) => {
      function onChange(newDate, oldDate) {
        if (oldDate && newDate.valueOf() === oldDate.valueOf()) {
          return;
        }

        $scope.$broadcast('refreshDatePicker');

        if ($scope.onChange) {
          $scope.onChange({ newDate, oldDate });
        }
      }

      $scope.goToToday = () => {
        const oldDate = moment($scope.date);
        $scope.date = moment().startOf('day');

        onChange($scope.date, oldDate);
      };

      $scope.dateChanged = (newDate, oldDate) => {
        onChange(newDate, oldDate);
      };
    },
  };
});
