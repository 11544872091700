const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messaging/messagesList.html');

import directives from '../module';
import messageListController from '../../controllers/messaging/messageList';

directives.directive('rrMessagesList', () => ({
  restrict: 'A',
  templateUrl: autoNgTemplateLoaderTemplate1,
  replace: false,
  controller: messageListController,
  scope: {
    participantIds: '=',
    channelId: '=',
  },
}));
