const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messagingPopover.html');

import '../sass/main.scss';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Core Angular.js imports
import 'angular';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-animate';
import 'angular-cookies';

// This polyfill is required to support Apollo in IE11
// We must reference the index.js file: https://github.com/developit/unfetch/issues/101
import 'unfetch/polyfill/index.js';
import AngularApollo from 'angular1-apollo';

// Third party plugins
import './3rdParty/fullcalendar';
import 'angular-ui-router';
import 'angular-ui-bootstrap';
import 'angular-local-storage';
import 'angular-ui-calendar';
import 'angular-ui-notification';
import 'angular-utils-pagination';
import 'ngmap';
import 'angular-drag-and-drop-lists';
import 'angular-bootstrap-colorpicker';
import 'angular-dynamic-locale';
import 'ng-currency';
import 'angular-bootstrap-datetimepicker';
import 'gm.datepicker-multi-select';
import 'angular-google-chart';
import 'angular-sticky-plugin';
import 'angular-translate';
import 'ng-infinite-scroll';
import 'ng-file-upload';
import 'iso-currency';
import 'angular-fixed-table-header';
import 'ui-cropper';

// Our own modules
import 'AppConfig';
import './animations';
import './controllers';
import './directives';
import './filters';
import './services';
import locales from './constants/locales';

// Side-effect only imports of html templates used by ng-include
const ngIncludes = [
  '3rdParty/dirPagination.tpl.html',
  'directiveTpls/modalDrawer.html',
  'rotamanagement/dashboard/rotaChecklistTab.html',
  'rotamanagement/dashboard/labourSpendTab.html',
  'reports/signOffProgress/statusModal.html',
  'reports/payrollExport/exportModal.html',
  'reports/payrollExport/signOffStatus.html',
  'staff/addNew/duplicateModal.html',
  'staff/view/emergencyContacts/index.html',
  'staff/view/employment/appointmentForm.html',
  'staff/view/employment/employmentForm.html',
  'staff/view/employment/leaverForm.html',
  'staff/view/employment/validationModal.html',
  'staff/view/pay/payForm.html',
  'staff/view/documents/signatureModal.html',
  'staff/view/documents/uploadModal.html',
  'staff/view/documents/editForm.html',
  'reports/documentSignatures/viewModal.html',
  'settings/documentTemplates/uploadModal.html',
  'settings/absenceTypes/editModal.html',
  'settings/outsourcing/editModal.html',
  'absence/requestCalendar.html',
  'absence/validationModal.html',
  'absence/viewModal.html',
  'dialog/twoButtonDialog.html',
  'staff/view/absence/allowanceForm.html',
  'staff/view/absence/allowanceTransactions.html',
  'staff/view/absence/allowanceValidationModal.html',
  'staff/view/absence/viewFinalisationDrawer.html',
  'staff/view/absence/allowanceFinaliseForm.html',
  'staff/view/absence/allowanceFinalisePrompt.html',
  'staff/view/emergencyContacts/index.html',
  'staff/view/emergencyContacts/addModal.html',
  'staff/view/danger/anonymiseForm.html',
  'settings/trustedDevices/newDeviceModal.html',
  'settings/trustedDevices/editDeviceModal.html',
  'staff/view/address/drawer.html',
  'other/editAvatarModal.html',
  'staff/view/visas/drawer.html',
  'staff/view/pay/studentLoan.html',
];

for (let i = 1; i <= 21; i += 1) {
  ngIncludes.push(`partials/validation/issue${i}.html`);
}

ngIncludes.forEach((include) => (require(`../htdocs/views/${include}`)));

const app = angular.module('rotareadyUiApp', [
  'ngResource',
  'ui.router',
  'ui.bootstrap',
  'ui.calendar',
  'ui-notification',
  'LocalStorageModule',
  'angularUtils.directives.dirPagination',
  'ngMap',
  'dndLists',
  'tmh.dynamicLocale',
  'ng-currency',
  'ui.bootstrap.datetimepicker',
  'gm.datepickerMultiSelect',
  'colorpicker.module',
  'googlechart',
  'hl.sticky',
  'infinite-scroll',
  'ngFileUpload',
  AngularApollo,
  'rotareadyUiApp.config',
  'rotareadyUiApp.services',
  'rotareadyUiApp.controllers',
  'rotareadyUiApp.filters',
  'rotareadyUiApp.directives',
  'rotareadyUiApp.animations',
  'ngCookies',
  'pascalprecht.translate',
  'isoCurrency.common',
  'fixed.table.header',
  'uiCropper',
]);

require('./routes')(app);

app.run(($rootScope, $state, $urlRouter, AuthService,
  SessionService, MessagingService, $translate, $window, WFM_ENDPOINT) => {
  $rootScope.$state = $state;
  AuthService.init();

  const messagingListener = {
    message: (message) => {
      const { message: { userId, comment, userFullName }, channel } = message;

      const ownUserId = SessionService.getUserId();
      const activeChannel = MessagingService.getActiveChannel();

      if (userId !== ownUserId && activeChannel !== channel) {
        MessagingService.showNotification({
          title: 'You have a new message',
          message: `${userFullName}: ${comment}`,
          channel,
        });
      }

      MessagingService.updateUnreadCountFromNewMessage(message);
    },
  };

  $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
    if (SessionService.isInitialised()) {
      return;
    }

    event.preventDefault();
    // For the reason behind the below line, see:
    // https://github.com/angular-ui/ui-router/issues/1348
    $urlRouter.update(true);

    SessionService.init(false, () => {
      const language = SessionService.getLanguage();
      const containsMatchingLanguage = language && locales.some((locale) => locale.id === language);

      if (containsMatchingLanguage) {
        $translate.use(language);
      }

      if (toState.name === 'dashboard' && SessionService.isPermissioned('rota')) {
        if (SessionService.dashboardsEnabled()) {
          $window.location = `${WFM_ENDPOINT}/dashboard`;
        } else {
          $state.go('rota.overview');
        }
      } else {
        $state.go(toState, toParams);
      }

      if (SessionService.isMessagingEnabled() && !MessagingService.isInitialised()) {
        MessagingService.init();
        MessagingService.addListener(messagingListener);
      }
    });
  });

  $rootScope.openIntercom = () => {
    Intercom('show');
  };

  $rootScope.$on('$destroy', () => {
    MessagingService.removeListener(messagingListener);
  });
});

app.config(($compileProvider, $uibModalProvider, $rootScopeProvider, NotificationProvider) => {
  $compileProvider.debugInfoEnabled(false);
  $uibModalProvider.options.animation = false;
  NotificationProvider.setOptions({
    startTop: 60,
    templateUrl: autoNgTemplateLoaderTemplate1,
  });

  // This is only needed for the permissions manager settings page to work with heavily
  // nested permission trees. It's due to the recursive ng-init and ng-includes.
  $rootScopeProvider.digestTtl(30);
});
