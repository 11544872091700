import directives from './module';

const SCROLL_THRESHOLD = 1;

directives.directive('rrInverseInfiniteScroll', ($timeout) => {
  'ngInject';

  return (scope, element, attr) => {
    const raw = element[0];

    $timeout(() => {
      raw.scrollTop = raw.scrollHeight;
    });

    element.bind('scroll', () => {
      if (raw.scrollTop < SCROLL_THRESHOLD) {
        const initialScrollHeight = raw.scrollHeight;

        scope.$apply(attr.rrInverseInfiniteScroll);
        raw.scrollTop = raw.scrollHeight - initialScrollHeight;
      }
    });
  };
});
