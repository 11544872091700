const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/payRules/editRuleModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/payRules/editRuleModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/settings/payRules/editRuleGroupModal.html');
const autoNgTemplateLoaderTemplate4 = require('/home/circleci/project/app/htdocs/views/settings/payRules/editRuleGroupModal.html');

var controllers = require('../../module');

controllers.controller('SettingsPayRulesCtrl', ['$scope', '$stateParams', '$uibModal', '$state', 'SettingsService', 'AlertService',
    function ($scope, $stateParams, $uibModal, $state, SettingsService, AlertService) {
        $scope.dataLoaded = false;
        $scope.saveActioning = false;
        $scope.canEdit = !!$stateParams.edit;

        function refreshGroups() {
            $scope.dataLoaded = false;

            SettingsService.getPayRuleGroups()
                .then(function (resp) {
                    $scope.groups = resp.data.groups;

                    $scope.groups.forEach(function (group) {
                        if (group.dateEnd) {
                            group.displayEnd = moment(group.dateEnd).subtract(1, 'day').format('YYYY-MM-DD');
                        }

                        group.isOpen = group.active;
                    });

                    $scope.dataLoaded = true;
                })
                .catch(function (err) {
                    if (err.status != 403) {
                        AlertService.add('danger', 'We weren\'t able to load the pay rules just then. Please try again');
                    }

                    $state.go('dashboard');
                });
        }

        refreshGroups();

        $scope.addRule = function (groupIndex) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SettingsEditPayRuleModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: true,
                            groupId: $scope.groups[groupIndex].id,
                            displayOrder: $scope.groups[groupIndex].rules.length,
                            canEdit: $scope.canEdit,
                        };
                    }
                }
            });

            modalInstance.result.then(refreshGroups);
        }

        $scope.editRule = function (groupIndex, ruleIndex) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'SettingsEditPayRuleModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: false,
                            rule: $scope.groups[groupIndex].rules[ruleIndex],
                            groupId: $scope.groups[groupIndex].id,
                            canEdit: $scope.canEdit,
                        };
                    }
                }
            });

            modalInstance.result.then(refreshGroups);
        };

        $scope.editGroup = function (index) {
            if (!$scope.canEdit) {
                return;
            }

            var group = $scope.groups[index];

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate3,
                controller: 'SettingsEditPayRuleGroupModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: false,
                            group: group
                        };
                    }
                }
            });

            modalInstance.result.then(refreshGroups);
        };

        $scope.addGroup = function () {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate4,
                controller: 'SettingsEditPayRuleGroupModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: true
                        };
                    }
                }
            });

            modalInstance.result.then(refreshGroups);
        };

        function moveRule(group, from, to) {
            group.rules.splice(to, 0, group.rules.splice(from, 1)[0]);

            var rule = group.rules[to];

            $scope.saveActioning = true;

            // Joi doesn't let us send the rule or pay rate ids along unless it's included in the schema, so we remove
            // them
            var id = rule.id;

            delete rule.id;
            delete rule.groupId;
            delete rule.group;
            delete rule.lastEdited;
            delete rule.deleted;

            var approvalSettings = rule.approvalSettings;

            if (approvalSettings && !approvalSettings.clockInAutoApprovalThreshold) {
                approvalSettings.clockInAutoApprovalThreshold = 0;
            }

            if (approvalSettings && !approvalSettings.clockOutAutoApprovalThreshold) {
                approvalSettings.clockOutAutoApprovalThreshold = 0;
            }

            rule.payRates.forEach(function (rate) {
                delete rate.id;
            });

            SettingsService.updatePayRule(group.id, id, rule)
                .success(function () {
                    $scope.saveActioning = false;

                    refreshGroups();
                })
                .error(function (err, status) {
                    if (status != 403) {
                        AlertService.add('danger', 'We weren\'t able to save the rule just then. Please try again');
                    }

                    $state.go('dashboard');
                });
        }

        $scope.moveUp = function (groupIndex, ruleIndex) {
            if (!$scope.canEdit) {
                return;
            }

            if(ruleIndex != 0) {
                $scope.groups[groupIndex].rules[ruleIndex].displayOrder -= 1;
                moveRule($scope.groups[groupIndex], ruleIndex, ruleIndex-1);
            }
        };

        $scope.moveDown = function (groupIndex, ruleIndex) {
            if (!$scope.canEdit) {
                return;
            }

            if(ruleIndex != $scope.groups[groupIndex].rules.length-1) {
                $scope.groups[groupIndex].rules[ruleIndex].displayOrder += 1;
                moveRule($scope.groups[groupIndex], ruleIndex, ruleIndex+1);
            }
        };

        $scope.generateDescription = function (rule) {
            var captureProperties = rule.captureProperties;

            var description = '';

            if (!captureProperties.entityIds.length) {
                description += 'All sites';
            } else {
                description += captureProperties.entityIds.length + ' sites';
            }

            if (rule.ruleType === 'THRESHOLD') {
                return description;
            }

            description += ', ';

            if (captureProperties.daysOfWeek.length === 7) {
                description += 'every day';
            } else {
                description += captureProperties.daysOfWeek.length + ' days a week';
            }

            description += ', ';

            if (captureProperties.dates.length) {
                if (captureProperties.dates.length === 1) {
                    description += moment(captureProperties.dates[0], 'YYYY-MM-DD').format('ll');
                } else {
                    description += captureProperties.dates.length + ' dates';
                }

                description += ', ';
            }

            if (captureProperties.times.length) {
                if (captureProperties.times.length === 1) {
                    description += moment(captureProperties.times[0].start, 'HH:mm:ss').format('LT') + ' to ' + moment(captureProperties.times[0].end, 'HH:mm:ss').format('LT');
                } else {
                    description += captureProperties.times.length + ' times';
                }

                description += ', ';
            }

            if (!captureProperties.shiftTypeIds.length) {
                description += 'all shift types';
            } else {
                description += captureProperties.shiftTypeIds.length + ' shift types';
            }

            return description;
        };
    }
]);
