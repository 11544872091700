var directives = require('./module');

directives.directive('numericBinding', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            model: '=ngModel'
        },
        link: function (scope) {
            // It's f*king annoying that we have to do this, but Angular still haven't resolved this issue
            // https://github.com/angular/angular.js/issues/6683
            if (scope.model && typeof scope.model == 'string') {
                scope.model = parseFloat(scope.model);
            }
            scope.$watch('model', function(val) {
                if (typeof val == 'string') {
                    scope.model = parseFloat(val);
                }
            });
        }
    };
});
