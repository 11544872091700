'use strict';
var controllers = require('../../module');

controllers.controller('EditLocationModalCtrl', ['$scope', '$uibModalInstance', '$translate', 'RotaService', 'CommonService', 'SessionService', 'NgMap', 'data',
    function ($scope, $uibModalInstance, $translate, RotaService, CommonService, SessionService, NgMap, data) {
        var mapId = 'locationPreviewMap';
        $scope.dataLoaded = false;

        $scope.formData = angular.copy(data.location);
        $scope.location = data.location;

        function hasSchedulingMinOccupancy() {
            return !isNaN(parseInt($scope.formData.schedulingMinOccupancy, 10));
        }

        function hasSchedulingMaxOccupancy() {
            return !isNaN(parseInt($scope.formData.schedulingMaxOccupancy, 10));
        }

        $scope.formData.hasParent = $scope.formData.parentId !== 0;
        $scope.formData.schedulingMinOccupancyToggle = hasSchedulingMinOccupancy();
        $scope.formData.schedulingMaxOccupancyToggle = hasSchedulingMaxOccupancy();
        $scope.formData.attendanceRadiusToggle = $scope.formData.attendanceRadius !== 0;

        delete $scope.formData.children;

        // OPENING TIMES TOOL
        $scope.daysOfWeek = [];
        $scope.daysOfWeekByIndex = {};

        var daysOfWeekDate = moment().startOf('isoWeek');

        for (var i = 0; i < 7; i++) {
            var day = daysOfWeekDate.format('dddd');

            $scope.daysOfWeek.push({
                index: i,
                day: day
            });

            $scope.daysOfWeekByIndex[i] = day;

            daysOfWeekDate.add(1, 'day');
        }

        $scope.regularOpeningTimesForm = {
            dayIndex: 0,
            open: moment().set({ hour: 9, minute: 0, second: 0 }),
            close: moment().set({ hour: 17, minute: 0, second: 0 })
        };

        $scope.addOpeningTime = function () {
            $scope.formData.openingTimes.push({
                dayIndex: $scope.regularOpeningTimesForm.dayIndex,
                open: $scope.regularOpeningTimesForm.open.format('HH:mm'),
                close: $scope.regularOpeningTimesForm.close.format('HH:mm')
            });

            $scope.formData.openingTimes.sort(function (a, b) {
                return a.dayIndex - b.dayIndex;
            });
        };

        $scope.removeOpeningTime = function (index) {
            $scope.formData.openingTimes.splice(index, 1);
        };

        // SPECIAL PERIODS
        $scope.specialPeriodForm = {
            closed: true,
            start: moment().startOf('day'),
            end: moment().add(1, 'week').startOf('day')
        };

        $scope.removeSpecialPeriod = function (index) {
            $scope.formData.openingTimesSpecialPeriods.splice(index, 1);
        };

        $scope.addSpecialPeriod = function () {
            if (!$scope.specialPeriodForm.start.isBefore($scope.specialPeriodForm.end)) {
                return;
            }

            $scope.formData.openingTimesSpecialPeriods.push({
                reason: $scope.specialPeriodForm.reason,
                closed: $scope.specialPeriodForm.closed,
                start: $scope.specialPeriodForm.start.clone(),
                end: $scope.specialPeriodForm.end.clone()
            });

            $scope.formData.openingTimesSpecialPeriods.sort(function (a, b) {
                return a.start.valueOf() - b.start.valueOf();
            });
        };

        $scope.specialPeriodDateChanged = function (date, isStart) {
            if (isStart) {
                $scope.formData.openingTimesSpecialPeriods.start = date.clone();
            } else {
                $scope.formData.openingTimesSpecialPeriods.end = date.clone();
            }
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.hasParentChanged = function () {
            if (!$scope.formData.hasParent) {
                $scope.formData.parentId = 0;
            }
        };

        function updateDisabledParentIds() {
            $scope.disabledParentIds = [];

            // Disable itself (and any children)
            $scope.disabledParentIds.push($scope.formData.id);

            // Disable root notes in other entities
            angular.forEach($scope.metadata.shiftLocations, function (t) {
                if (t.depth === 0 && t.entityId !== $scope.location.entityId) {
                    $scope.disabledParentIds.push(t.id);
                }
            });
        }

        CommonService.getMetadata(['shiftLocations'], [SessionService.getEntity()], true)
            .success(function (metadata) {
                $scope.metadata = {
                    shiftLocations: metadata.shiftLocations
                };

                updateDisabledParentIds();

                $scope.dataLoaded = true;
            })
            .error(function () {
                $scope.close();
            });

        $scope.save = function () {
            if (!$scope.formData.name || $scope.formData.name.length < 1) {
                return alert($translate.instant('ROTA_MANAGEMENT.LOCATIONS.EDIT_LOCATION_MODAL.ALERT_NAME'));
            }

            if ($scope.formData.hasParent) {
                if (!$scope.formData.parentId) {
                    return alert($translate.instant('ROTA_MANAGEMENT.LOCATIONS.EDIT_LOCATION_MODAL.ALERT_PERIOD'));
                }

                var parentItem = false;

                angular.forEach($scope.metadata.shiftLocations, function (s) {
                    if (s.id === $scope.formData.parentId) {
                        parentItem = s;
                    }
                });

                if (!parentItem || $scope.formData.parentId === $scope.formData.id) {
                    return alert($translate.instant('ROTA_MANAGEMENT.LOCATIONS.EDIT_LOCATION_MODAL.ALERT_VALID_PARENT'));
                }

                if (parentItem.entityId !== $scope.formData.entityId) {
                    return alert($translate.instant('ROTA_MANAGEMENT.LOCATIONS.EDIT_LOCATION_MODAL.ALERT_PARENT_LOCATION'));
                }
            }

            if (hasSchedulingMinOccupancy() && hasSchedulingMaxOccupancy() &&
                $scope.formData.schedulingMinOccupancy > $scope.formData.schedulingMaxOccupancy) {
                return alert($translate.instant('ROTA_MANAGEMENT.LOCATIONS.EDIT_LOCATION_MODAL.ALERT_MINIMUM'));
            }

            $scope.saveActioning = true;

            if (!$scope.formData.attendanceRadiusToggle) {
              $scope.formData.attendanceRadius = 0;
            }

            RotaService.updateShiftLocation($scope.formData.id, $scope.formData)
                .success(function () {
                    $uibModalInstance.close();
                })
                .error(function () {
                    $scope.saveActioning = false;
                    alert($translate.instant('ROTA_MANAGEMENT.LOCATIONS.EDIT_LOCATION_MODAL.ERROR_UPDATE'));
                });
        };

        $scope.delete = function () {
            if ($scope.location.children.length) {
                if (!confirm($translate.instant('ROTA_MANAGEMENT.LOCATIONS.EDIT_LOCATION_MODAL.ALERT_SUB_CONFIRM'))) {
                    return;
                }
            } else {
                if (!confirm($translate.instant('ROTA_MANAGEMENT.LOCATIONS.EDIT_LOCATION_MODAL.ALERT_CONFIRM'))) {
                    return;
                }
            }

            $scope.deleteActioning = true;

            RotaService.deleteShiftLocation($scope.location.id)
                .success(function () {
                    $uibModalInstance.close();
                })
                .error(function () {
                    $scope.deleteActioning = false;
                    alert($translate.instant('ROTA_MANAGEMENT.LOCATIONS.EDIT_LOCATION_MODAL.ERROR_DELETE'));
                })
        };

        $scope.$watch('formData.attendanceRadius', function (value) {
            if (!value || !$scope.map) {
                return;
            }

            if ($scope.map.shapes) {
                $scope.map.shapes[0].set('radius', value);
            }
        });

        $scope.$watch('formData.schedulingMinOccupancyToggle', function (value) {
            if (value === undefined) {
                return;
            }

            if (value) {
                if (!hasSchedulingMinOccupancy()) {
                    $scope.formData.schedulingMinOccupancy = 1;
                }
            } else {
                $scope.formData.schedulingMinOccupancy = null;
            }
        });

        $scope.$watch('formData.schedulingMaxOccupancyToggle', function (value) {
            if (value === undefined) {
                return;
            }

            if (value) {
                if (!hasSchedulingMaxOccupancy()) {
                    $scope.formData.schedulingMaxOccupancy = 10;
                }
            } else {
                $scope.formData.schedulingMaxOccupancy = null;
            }
        });

        $scope.$watch('formData.attendanceRadiusToggle', (newValue, oldValue) => {
          if (newValue === true && oldValue === false) {
            $scope.formData.attendanceRadius = 100;
          }
        });

        $uibModalInstance.rendered.then(function () {
            NgMap.getMap({ id: mapId }).then(function(map) {
                $scope.map = map;

                google.maps.event.trigger(map, 'resize');
                map.setCenter(new google.maps.LatLng($scope.location.latitude, $scope.location.longitude));
                map.setZoom(16);
            });
        });
    }]);
