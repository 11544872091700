module.exports = (
  $scope,
  $state,
  $translate,
  $window,
  $uibModalInstance,
  AlertService,
  AvailabilityService,
  StaffCommon,
  StaffService,
  EnvironmentDataService,
  data,
) => {
  'ngInject';

  const basicPayElementTypeId = 1;

  const {
    allowance,
    userCurrentEmploymentDateStart,
    userCurrentEmploymentDateEnd,
    averageHoursPerDay,
  } = data;

  const translations = $translate.instant([
    'STAFF.VIEW.ABSENCE.FINALISE_ALLOWANCE_FORM.ERROR_500',
    'STAFF.VIEW.ABSENCE.FINALISE_ALLOWANCE_FORM.ERROR_403',
    'STAFF.VIEW.ABSENCE.FINALISE_ALLOWANCE_FORM.ALERT_SUCCESS',
  ]);

  const defaultReferenceDate = userCurrentEmploymentDateEnd
    ? moment(userCurrentEmploymentDateEnd).subtract(1, 'day') : moment().startOf('day');

  $scope.props = {
    actionInProgress: true,
    allowance,
    isEmploymentEnded: !!userCurrentEmploymentDateEnd,
    averageHoursPerDay,
    isAllowanceFutureDated: moment(allowance.startDate).isAfter(),
    payElementTypeFixed: false,
    formData: {
      referenceDate: defaultReferenceDate.clone(),
      payElementTypeId: undefined,
      payAmount: 0,
    },
    getLabelFromPayAmountType: (amountType) => {
      const value = StaffService.getPayAmountTypes().find(({ id }) => id === amountType);
      return value ? value.title : '';
    },
  };

  $scope.getPayRecordOnReferenceDate = () => {
    const {
      payRecordLoading,
      chosenDate,
    } = $scope.props;

    if (payRecordLoading) {
      return;
    }

    $scope.props.payRecordLoading = true;
    $scope.props.payRecordPermitted = true;

    StaffService.getPay(allowance.userId)
      .then(({ data }) => {
        $scope.props.payRecord = data.payHistory.find((record) => {
          const {
            dateStart,
            dateEnd,
            openEnded,
            elementType: {
              id: elementTypeId,
            },
          } = record;

          const active = moment(dateStart).isSameOrBefore(chosenDate)
            && (openEnded || moment(dateEnd).isAfter(chosenDate));

          return active && elementTypeId === basicPayElementTypeId;
        });

        $scope.props.payRecordLoading = false;
      })
      .catch(({ status }) => {
        $scope.props.payRecordLoading = false;

        if (status === 403) {
          $scope.props.payRecordPermitted = false;
          return;
        }

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.FINALISE_ALLOWANCE_FORM.ERROR_500']);
        }
      });
  };

  function getTotalAccruedValue() {
    const {
      effectiveDate: allowanceStartDate,
      endDate: allowanceEndDate,
      scheduleOfAccrual,
      totalAccrued,
      totalToAccrue,
    } = allowance;

    if (scheduleOfAccrual === 'EACH_HOUR_WORKED') {
      return totalAccrued;
    }

    const employmentStart = userCurrentEmploymentDateStart
      ? moment.utc(userCurrentEmploymentDateStart) : null;
    const startDate = employmentStart && employmentStart.isAfter(allowanceStartDate)
      ? employmentStart : allowanceStartDate;

    const {
      chosenDate,
    } = $scope.props;

    const chosenDateExclusive = chosenDate.clone().add(1, 'day');

    if (chosenDateExclusive.isBefore(startDate)) {
      return 0;
    }

    const annualTotal = scheduleOfAccrual === 'EACH_CALENDAR_MONTH'
      ? totalToAccrue : totalAccrued;

    if (chosenDateExclusive.isSameOrAfter(allowanceEndDate)) {
      return annualTotal;
    }

    // This logic also accommodates the niche case of someone joining mid-holiday year
    // (so their allowance was pro-rated) and then leaving in the same holiday year.
    const daysInYear = moment.utc(allowanceEndDate).diff(moment.utc(startDate), 'days');
    const percentageAccrued = chosenDateExclusive.diff(startDate, 'days') / daysInYear;
    return Math.round(annualTotal * percentageAccrued * 10) / 10;
  }

  $scope.onReferenceDateChange = (dateStart) => {
    $scope.props.chosenDate = dateStart.clone();
    $scope.props.formData.payEffectiveDate = dateStart.clone().add(1, 'day');
    $scope.getPayRecordOnReferenceDate();

    const totalAccrued = getTotalAccruedValue();

    $scope.props.totalAccruedValue = totalAccrued;
    $scope.props.remainingValue = Math.round((totalAccrued - allowance.booked) * 100) / 100;
  };

  $scope.onPayEffectiveDateChange = (dateStart) => {
    $scope.props.formData.payEffectiveDate = dateStart.clone();
  };

  $scope.close = () => $uibModalInstance.dismiss();

  function saveAllowance(allowanceId, finalisedPayId, finalisedNotes, finalisedReferenceDate) {
    AvailabilityService.updateAllowance(allowanceId, {
      finalised: true,
      finalisedPayId,
      finalisedNotes,
      finalisedReferenceDate: finalisedReferenceDate.format('YYYY-MM-DD'),
    })
      .then(() => {
        $scope.props.actionInProgress = false;
        $uibModalInstance.close(true);
      })
      .catch(({ status, data }) => {
        $scope.props.actionInProgress = false;

        if (status === 400) {
          StaffCommon.onAllowanceValidationResponse(data);
          return;
        }

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.FINALISE_ALLOWANCE_FORM.ERROR_500']);
        }
      });
  }

  $scope.save = () => {
    const {
      actionInProgress,
      formData,
      payRecord,
      payRecordLoading,
      payRecordPermitted,
      totalAccruedValue,
      remainingValue,
      chosenDate,
    } = $scope.props;

    const {
      id: allowanceId,
      userId,
      booked,
    } = allowance;

    if (payRecordLoading || actionInProgress) {
      return;
    }

    const {
      payAmount,
      payElementId,
      payEffectiveDate,
      notes,
    } = formData;

    let amendedNotes = notes;

    if (!amendedNotes) {
      amendedNotes = `${totalAccruedValue} - ${booked} = ${remainingValue}`;
    }

    $scope.props.actionInProgress = true;

    if (payRecord && payRecordPermitted && payElementId && payAmount !== 0) {
      StaffService.addPay(userId, {
        payHistory: {
          effectiveDate: payEffectiveDate.format('YYYY-MM-DD'),
          amount: payAmount,
          elementId: payElementId,
        },
      })
        .then(({ data }) => {
          const { payIds } = data;
          saveAllowance(allowanceId, payIds[0], amendedNotes, chosenDate);
        })
        .catch(({ status, data }) => {
          $scope.props.actionInProgress = false;

          if (status === 500) {
            AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.FINALISE_ALLOWANCE_FORM.ERROR_500']);
            return;
          }

          if (status === 403) {
            AlertService.add('warning', translations['STAFF.VIEW.ABSENCE.FINALISE_ALLOWANCE_FORM.ERROR_403']);
            return;
          }

          if (status === 400) {
            StaffCommon.onEmploymentValidationResponse(data.validationResult);
          }
        });
    } else {
      saveAllowance(allowanceId, null, amendedNotes, chosenDate);
    }
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.PayElementType,
    EnvironmentDataService.DataType.AbsenceType,
  ])
    .then(([
      payElementType,
      absenceType,
    ]) => {
      $scope.props.payElementList = payElementType.data
        .filter(({ variant, isDeleted }) => variant === 'FIXED_AMOUNT' && !isDeleted)
        .map(({ id, name, isDeleted }) => ({ id, label: name, deleted: isDeleted }));

      if ($scope.props.payElementList.length) {
        $scope.props.formData.payElementId = $scope.props.payElementList[0].id;
      }

      const matchingAbsenceType = absenceType.data
        .find(({ id }) => id === allowance.absenceType.id);

      if (matchingAbsenceType && matchingAbsenceType.fixedAmountPayElementId) {
        $scope.props.payElementTypeFixed = true;
        $scope.props.formData.payElementId = matchingAbsenceType.fixedAmountPayElementId;
      }

      $scope.onReferenceDateChange(defaultReferenceDate);
      $scope.props.actionInProgress = false;
    });
};
