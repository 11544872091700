'use strict';
var controllers = require('../../module');

controllers.controller('ViewGroupModalCtrl', ['$scope', '$uibModalInstance', 'StaffService', 'data',
    function ($scope, $uibModalInstance, StaffService, data) {
        $scope.dataLoaded = false;
        $scope.saveGroupActioning = false;
        $scope.deleteGroupActioning = false;
        $scope.formData = {};

        $scope.disabled = {
            deleteTab: false
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.saveGroup = function () {
            if (!$scope.formData.name || $scope.formData.name.length < 1) {
                alert('Please ensure you have provided a valid name for the group.');
                return;
            }

            $scope.saveGroupActioning = true;

            StaffService.updateGroup(data.id, $scope.formData)
                .success(function () {
                    $uibModalInstance.close();
                })
                .error(function () {
                    $scope.saveGroupActioning = false;
                    alert('We were unable to save your group just now.\n\n' +
                        'It could be that your group name is not unique. Please check and try again.');
                });
        };

        $scope.deleteGroup = function () {
            if ($scope.formData.confirmName !== $scope.groupName) {
                return alert('Please type the group name correctly.');
            }

            $scope.deleteGroupActioning = true;

            StaffService.deleteGroup(data.id, $scope.formData.moveTo)
                .success(function () {
                    $uibModalInstance.close(data.id);
                })
                .error(function () {
                    $scope.deleteGroupActioning = false;
                    alert('We were unable to delete your group just now.');
                });
        };

        StaffService.getGroup(data.id)
            .success(function (group) {
                $scope.formData = group.group;
                $scope.groupName = group.group.name;
                $scope.entityName = group.group.entityName;

                $scope.groups = angular.copy(group.moveToGroups);
                $scope.members = group.members;

                // Don't let them delete the last group
                if (!$scope.groups.length) {
                    $scope.disabled.deleteTab = true;
                } else {
                    $scope.formData.moveTo = $scope.groups[0].id;
                }

                $scope.totalMembers = group.members.reduce(function (accumulator, entity) {
                    return accumulator + entity.members.length;
                }, 0);

                $scope.dataLoaded = true;
            })
            .error(function () {
                $uibModalInstance.dismiss('cancel');
            });
    }]);
