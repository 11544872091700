module.exports = (
  $scope,
  $state,
  $translate,
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.VIEW.PAY.INDEX.TAB_HISTORY',
    'STAFF.VIEW.PAY.INDEX.TAB_TRONC',
    'STAFF.VIEW.PAY.INDEX.TAB_FINANCIALS',
  ]);

  $scope.props = {
    tabs: [
      { state: 'history', label: translations['STAFF.VIEW.PAY.INDEX.TAB_HISTORY'] },
      { state: 'tronc', label: translations['STAFF.VIEW.PAY.INDEX.TAB_TRONC'] },
      { state: 'financials', label: translations['STAFF.VIEW.PAY.INDEX.TAB_FINANCIALS'] },
    ],
  };

  $scope.setSelectedTab = (tab) => {
    $state.go(`staff.view.pay.${tab.state}`);
  };
};
