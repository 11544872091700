module.exports = (
  $scope,
  $state,
  $uibModalInstance,
  $translate,
  $window,
  AlertService,
  AvailabilityService,
  StaffCommon,
  StaffService,
  SessionService,
  EnvironmentDataService,
  data,
) => {
  'ngInject';

  const {
    id: headerId,
    launchedFromStaffModule,
  } = data;

  const translations = $translate.instant([
    'ABSENCE.VIEW_MODAL.ERROR_500',
    'ABSENCE.VIEW_MODAL.ERROR_404',
    'ABSENCE.VIEW_MODAL.CONFIRM_CANCEL',
    'ABSENCE.VIEW_MODAL.ALERT_IS_CANCELLED',
    'ABSENCE.VIEW_MODAL.FILES_CONFIRM_DELETE',
  ]);

  $scope.props = {
    headerId,
    actionInProgress: true,
    permittedExtensions: StaffCommon.permittedFileExtensions,
    launchedFromStaffModule,
  };

  function loadBooking() {
    $scope.props.actionInProgress = true;

    AvailabilityService.getAbsenceRequest(headerId)
      .then(({ data }) => {
        $scope.absence = data;

        $scope.absenceType = $scope.environmentData.absenceType
          .find((type) => type.id === $scope.absence.header.type.id);

        $scope.absenceTypeReason = $scope.environmentData.absenceTypeReason
          .find((reason) => reason.id === $scope.absence.header.typeReasonId);

        if ($scope.absenceType && $scope.absenceType.fileUploadCategoryId) {
          $scope.props.canViewFiles = $scope.absenceType.fileUploadOnSubmission !== 'NONE'
            || $scope.absenceType.fileUploadOnReview !== 'NONE';

          $scope.props.canUploadFiles = $scope.absenceType.fileUploadOnReview !== 'NONE';

          $scope.documentCategory = $scope.environmentData.documentCategory
            .find((category) => category.id === $scope.absenceType.fileUploadCategoryId);
        }

        $scope.props.canEdit = !data.header.cancelled && data.header.approvalState !== 2;
        $scope.props.canCancel = !data.header.cancelled;

        $scope.props.approvalStateLabel = StaffCommon
          .getLabelFromApprovalState(data.header.approvalState);
        $scope.props.scopeLabel = StaffCommon.getLabelFromScope(data.header.scope);

        $scope.props.alertSuspectCustomHours = data.header.scope === 3
          && data.days[0].hours > data.header.userHoursHalfDay;

        $scope.props.actionInProgress = false;
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['ABSENCE.VIEW_MODAL.ERROR_500']);
        } else if (status === 404) {
          AlertService.add('danger', translations['ABSENCE.VIEW_MODAL.ERROR_404']);
        }

        $scope.close();
      });
  }

  // If this was launched outside the staff module, there's no guarantee that
  // the user will have permission to fetch document categories.
  const dataTypes = launchedFromStaffModule
    ? [
      EnvironmentDataService.DataType.AbsenceType,
      EnvironmentDataService.DataType.AbsenceTypeReason,
      EnvironmentDataService.DataType.DocumentCategory,
    ] : [
      EnvironmentDataService.DataType.AbsenceType,
      EnvironmentDataService.DataType.AbsenceTypeReason,
    ];

  EnvironmentDataService.fetchAll(dataTypes)
    .then(([
      absenceType,
      absenceTypeReason,
      documentCategory,
    ]) => {
      $scope.environmentData = {
        absenceType: absenceType.data,
        documentCategory: documentCategory ? documentCategory.data : [],
        absenceTypeReason: absenceTypeReason.data,
      };

      loadBooking();
    });

  $scope.close = () => {
    $uibModalInstance.dismiss();
  };

  $scope.edit = () => {
    if (!$scope.props.canEdit) {
      return;
    }

    $state.go('absenceEdit.dates', { id: headerId });
    $uibModalInstance.dismiss();
  };

  $scope.cancel = () => {
    if (!$scope.props.canCancel
      || !$window.confirm(translations['ABSENCE.VIEW_MODAL.CONFIRM_CANCEL'])) {
      return;
    }

    $scope.props.actionInProgress = true;

    AvailabilityService.cancelAbsenceRequest(headerId)
      .then(() => {
        AlertService.add('success', translations['ABSENCE.VIEW_MODAL.ALERT_IS_CANCELLED']);
        $uibModalInstance.close(true);
      })
      .catch(({ status, data }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['ABSENCE.VIEW_MODAL.ERROR_500']);
          return;
        }

        if (status === 400) {
          StaffCommon.onAbsenceValidationResponse(data);
        }
      });
  };

  $scope.onFileUpload = ({ name: fileName, key }) => {
    $scope.props.actionInProgress = true;

    AvailabilityService.addAbsenceDocument(headerId, {
      documents: [{
        fileName,
        key,
      }],
    })
      .then(({ data }) => {
        const [id] = data.documentIds;
        const name = SessionService.getUserFullName().split(' ');

        $scope.absence.documents.push({
          id,
          fileName,
          uploadedBy: {
            date: moment(),
            user: {
              id: SessionService.getUserId(),
              firstName: name[0],
              lastName: name.slice(1, name.length).join(' '),
            },
          },
        });

        $scope.props.actionInProgress = false;
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status !== 403) {
          AlertService.add('danger', translations['ABSENCE.VIEW_MODAL.ERROR_500']);
        }
      });
  };

  $scope.deleteDocument = (document, index) => {
    if (document.actionInProgress) {
      return;
    }

    if (!$window.confirm(translations['ABSENCE.VIEW_MODAL.FILES_CONFIRM_DELETE'])) {
      return false;
    }

    document.actionInProgress = true;

    StaffService.deleteDocument(document.id)
      .then(() => {
        $scope.absence.documents.splice(index, 1);
      })
      .catch(({ status }) => {
        document.actionInProgress = false;

        if (status !== 403) {
          AlertService.add('danger', translations['ABSENCE.VIEW_MODAL.ERROR_500']);
        }
      });
  };

  $scope.viewDocument = (document) => {
    if (document.actionInProgress) {
      return;
    }

    document.actionInProgress = true;

    StaffService.getDocument(document.id, { download: true })
      .then(({ data }) => {
        const { signedUrl } = data;

        if (signedUrl) {
          $window.location = signedUrl;
        }

        document.actionInProgress = false;
      })
      .catch(({ status }) => {
        document.actionInProgress = false;

        if (status !== 403) {
          AlertService.add('danger', translations['ABSENCE.VIEW_MODAL.ERROR_500']);
        }
      });
  };
};
