var controllers = require('../../module');

controllers.controller('DeletePermissionLevelModalCtrl', ['$scope', '$uibModalInstance', 'data',
    function ($scope, $uibModalInstance, data) {
        $scope.dataLoaded = false;
        $scope.level = data.level;
        $scope.formData = {};

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.delete = function () {
            $uibModalInstance.close($scope.formData.moveTo);
        };

        $scope.levels = [];

        angular.forEach(data.tree, function (level) {
            flattenChildLevels(level, data.level.id);
        });

        $scope.formData.moveTo = $scope.levels[0].id;

        $scope.dataLoaded = true;

        function flattenChildLevels(level, excludeLevelId) {
            if (excludeLevelId === level.id) {
                return;
            }

            if (level.depth > 0) {
                level.name = Array(level.depth+1).join("-") + ' ' + level.name;
            }

            if (level.is_fullaccess) {
                level.name += ' [Full access]';
            }

            $scope.levels.push(level);

            if (level.children && level.children.length) {
                angular.forEach(level.children, function (childLevel) {
                    flattenChildLevels(childLevel, excludeLevelId);
                });

                delete level.children;
            }
        }
    }]);
