const translations = {
  "STAFF": {
    "CONSTANTS": {
      "UK_STARTER_DECLARATION_A": "Përgjigje: Kjo është puna e tyre e parë që nga fillimi i vitit tatimor (6 Prill)",
      "UK_STARTER_DECLARATION_B": "B: Kjo është detyra e vetme e tyre aktualisht",
      "UK_STARTER_DECLARATION_C": "C: Ata kanë një punë tjetër ose pension",
      "UK_STUDENT_LOAN_PLAN_1": "Plani 1",
      "UK_STUDENT_LOAN_PLAN_2": "Plani 2",
      "UK_STUDENT_LOAN_PLAN_4": "Plani 4",
      "UK_STUDENT_LOAN_PLAN_5": "Plani 5",
      "UK_STUDENT_LOAN_PLAN_POSTGRADUATE": "Pasdiplomike",
      "UK_SETTLED_STATUS_NA": "Nuk zbatohet",
      "UK_SETTLED_STATUS_PRE_SETTLED": "Para-vendosur",
      "UK_SETTLED_STATUS_SETTLED": "Vendosur",
      "MARITAL_STATUS_SINGLE": "Vetëm",
      "MARITAL_STATUS_MARRIED": "I martuar",
      "MARITAL_STATUS_DIVORCED": "Divorcuar",
      "MARITAL_STATUS_WIDOWED": "I vejushur",
      "MARITAL_STATUS_CIVIL_PARTNERSHIP": "Partneriteti Civil",
      "PAYMENT_METHOD_ELECTRONIC": "Transferimi elektronik",
      "PAYMENT_METHOD_CHEQUE": "Çeki",
      "PAYMENT_METHOD_MANUAL": "Manual",
      "CONTRACTUAL_OBLIGATION_PERIOD_WEEK": "Në javë",
      "CONTRACTUAL_OBLIGATION_PERIOD_MONTH": "Në muaj",
      "CONTRACTUAL_OBLIGATION_UNIT_HOURS": "Orë",
      "CONTRACTUAL_OBLIGATION_UNIT_DAYS": "Ditët",
      "PAY_AMOUNT_TYPE_HOURLY": "Tarifa sipas orës",
      "PAY_AMOUNT_TYPE_DAILY": "Norma ditore",
      "PAY_AMOUNT_TYPE_ANNUAL": "Paga vjetore",
      "PAY_FREQUENCY_WEEKLY": "javore",
      "PAY_FREQUENCY_FORTNIGHTLY": "Dy javë",
      "PAY_FREQUENCY_MONTHLY": "mujore",
      "PAY_FREQUENCY_FOUR_WEEKLY": "Katër javore"
    },
    "OVERVIEW": {
      "ERROR_500": "Nuk mund të ngarkojmë asnjë të dhënë vetëm atëherë. Ju lutemi provoni përsëri.",
      "TITLE": "Stafi",
      "ADD_STAFF": "Shto të reja",
      "NO_MATCH": "Nuk mund të gjejmë askënd që përputhet me filtrat tuaj.",
      "NO_ACTIVE_APPT_TAG": "joaktiv",
      "NOT_APPROVED_TAG": "Nuk është aprovuar",
      "DOCUMENTS_INVALID_TAG": "Dokumente të pavlefshme",
      "NOT_INVITED_TAG": "Nuk është i ftuar",
      "NOT_SETUP_TAG": "I ftuar",
      "EMPLOYER_INCOMPLETE_TAG": "I paplotë",
      "SELF_ONBOARDING": "Vetë-futja",
      "ACCOUNT_TAB_LABEL": "llogari",
      "PERSONAL_TAB_LABEL": "Personal",
      "EMPLOYMENT_TAB_LABEL": "Punësimi",
      "PAY_TAB_LABEL": "Paguaj",
      "SCHEDULE_TAB_LABEL": "Orari",
      "ABSENCE_TAB_LABEL": "Mungesa",
      "AVAILABILITY_TAB_LABEL": "disponueshmëria",
      "SHIFT_ROUTINE_TAB_LABEL": "Rutina e ndërrimit",
      "DOCUMENTS_TAB_LABEL": "Dokumentet",
      "ATTENDANCE_TAB_LABEL": "Pjesëmarrja",
      "NOTES_TAB_LABEL": "Shënime",
      "FILTER_LABEL_SITE": "Faqit/Departamenti",
      "FILTER_LABEL_POSITION": "Roli i punës",
      "FILTER_LABEL_STAFF_GROUP": "Grupi i stafit",
      "FILTER_LABEL_EMPLOYMENT": "Statusi i punësimit",
      "FILTER_LABEL_APPROVAL_STATUS": "Statusi i miratimit",
      "FILTER_LABEL_EMPLOYEE_SETUP": "Ftesë Rotaready",
      "FILTER_LABEL_EMPLOYER_SETUP": "Përfundimi i llogarisë",
      "FILTER_LABEL_EMPLOYMENT_START": "Data e bashkimit",
      "FILTER_LABEL_EMPLOYMENT_END": "Lini datën",
      "FILTER_LABEL_EMPLOYMENT_START_OR_END": "Datën e bashkimit ose largohuni",
      "BUTTON_COLUMNS": "Kolona",
      "BUTTON_EXPORT": "Eksporti",
      "EMPLOYMENT_STATUS_ACTIVE": "Aktiv",
      "EMPLOYMENT_STATUS_INACTIVE": "joaktiv",
      "APPROVAL_STATUS_APPROVED": "Miratuar",
      "APPROVAL_STATUS_NOT_APPROVED": "Nuk është aprovuar",
      "EMPLOYEE_SETUP_COMPLETE": "Pranuar",
      "EMPLOYEE_SETUP_NOT_COMPLETE": "Në pritje ose nuk është dërguar",
      "EMPLOYER_SETUP_COMPLETE": "I plotë",
      "EMPLOYER_SETUP_NOT_COMPLETE": "I paplotë",
      "COLUMN_NAME": "Emri",
      "COLUMN_NEXT_SHIFT": "Ndërrimi i radhës",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_BIRTH_DATE": "Data e lindjes",
      "COLUMN_EMAIL": "Email",
      "COLUMN_MOBILE": "Telefoni",
      "COLUMN_OTHER_NAMES": "Emri (et) të tjerë",
      "COLUMN_ADDRESS_LINE_1": "Linja e adresës 1",
      "COLUMN_ADDRESS_LINE_2": "Linja e adresës 2",
      "COLUMN_ADDRESS_CITY": "Qyteti/qytet",
      "COLUMN_ADDRESS_COUNTY": "Qarq/rajon",
      "COLUMN_ADDRESS_POST_CODE": "Kodi Postar",
      "COLUMN_ADDRESS_COUNTRY": "Shteti",
      "COLUMN_PREFERRED_NAME": "Emri i preferuar",
      "COLUMN_GENDER": "Gjinia",
      "COLUMN_TITLE": "Titulli",
      "COLUMN_ETHNICITY": "Etnikësia",
      "COLUMN_NATIONALITY": "Kombësia",
      "COLUMN_MARITAL_STATUS": "Statusi martesor",
      "COLUMN_SETTLED_STATUS": "Statusi i vendosur (UK)",
      "COLUMN_PASSPORT_NUMBER": "Numri i pasaportës",
      "COLUMN_PASSPORT_EXPIRY_DATE": "Skadi i pasaportës",
      "COLUMN_WORK_EMAIL": "Email dytësor",
      "COLUMN_WORK_PHONE": "Telefoni dytësor",
      "COLUMN_BANK_ACCOUNT_NAME": "Emri i llogarisë bankare",
      "COLUMN_BANK_ACCOUNT_NUMBER": "Numri i llogarisë bankare",
      "COLUMN_BANK_ACCOUNT_SORT_CODE": "Kodi i renditjes bankare",
      "COLUMN_PAYROLL_PAYMENT_METHOD": "Metoda e pagesës",
      "COLUMN_NATIONAL_INSURANCE_NUMBER": "Numri i Sigurimit Kombëtar (UK)",
      "COLUMN_STUDENT_LOANS": "Kredi studentore (Mbretëria e Bashkuar)",
      "COLUMN_AVERAGE_HOURS_DAY": "Orë/ditë mesatare",
      "COLUMN_AVERAGE_HOURS_HALF_DAY": "Orë mesatare/gjysmë dite",
      "COLUMN_AVERAGE_HOURS_WEEK": "Orë/javë mesatare",
      "COLUMN_PRE_AVERAGE_HOURS_PER_DAY": "Orë/ditë mesatare Pre-Rotaready",
      "COLUMN_PRE_AVERAGE_HOURS_DATE": "Data mesatare e orëve Pre-Rotaready",
      "COLUMN_PERMISSION_LEVEL_NAME": "Niveli i lejes",
      "COLUMN_ANONYMISED": "anonimizuar",
      "COLUMN_ANONYMISE_DATE": "Anonimizoni datën",
      "COLUMN_JOIN_DATE": "Data e bashkimit",
      "COLUMN_LEAVE_DATE": "Lini datën",
      "COLUMN_LEAVE_DATE_EDITED_BY": "Lëreni datën e caktuar nga",
      "COLUMN_LEAVE_DATE_EDITED_DATE": "Lëreni datën e caktuar",
      "COLUMN_LENGTH_OF_SERVICE": "Kohëzgjatja e shërbimit (ditë)",
      "COLUMN_RECRUITMENT_SOURCE": "Burimi i rekrutimit",
      "COLUMN_LEAVER_REASON": "Arsyeja e lëvizjes",
      "COLUMN_LEAVER_REHIRE": "Ripunësimi i kualifikuar",
      "COLUMN_EMPLOYEE_NUMBER": "Numri i punëtorëve",
      "COLUMN_NOTICE_PERIOD": "Periudha e njoftimit",
      "COLUMN_STARTER_DECLARATION": "Deklarata fillestare (UK)",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED": "Shpenzimet e finalizuara",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY": "Shpenzimet e finalizuara nga",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE": "Data e përfundimit të shpenzimeve",
      "COLUMN_SITE_NAME": "Faqja",
      "COLUMN_SITE_ID": "ID e faqes",
      "COLUMN_DEPARTMENT_NAME": "ministri",
      "COLUMN_DEPARTMENT_ID": "ID e Departamentit",
      "COLUMN_JOB_ROLE_NAME": "Roli i punës",
      "COLUMN_COMPANY_NAME": "Kompania",
      "COLUMN_CONTRACT_BASIS": "Lloji i kontratës",
      "COLUMN_LINE_MANAGER_NAME": "Menaxher i linjës",
      "COLUMN_STAFF_GROUP_NAME": "Grupi i stafit",
      "COLUMN_WORKS_PUBLIC_HOLIDAYS": "Punon festat publike",
      "COLUMN_WORKING_TIME_OPT_OUT": "Përjashtimi i rregulloreve të kohës së punës",
      "COLUMN_MIN_OBLIGATION_VALUE": "Vlera e detyrimit kontraktual",
      "COLUMN_MIN_OBLIGATION_UNIT": "Njësia e detyrimeve kontraktuale",
      "COLUMN_MIN_OBLIGATION_PERIOD": "Periudha e obligimit kontraktual",
      "COLUMN_MAX_OBLIGATION_VALUE": "Vlera e kufirit të punës",
      "COLUMN_MAX_OBLIGATION_UNIT": "Njësia e kufirit të punës",
      "COLUMN_MAX_OBLIGATION_PERIOD": "Periudha e kufirit të punës",
      "COLUMN_DEPARTMENT_CURRENCY_CODE": "Kodi i monedhës",
      "COLUMN_PAYROLL_CALENDAR_NAME": "Kalendari i pagave",
      "COLUMN_PAYROLL_CALENDAR_FREQUENCY": "Frekuenca e kalendarit të pagave",
      "COLUMN_PAY_AMOUNT": "Shuma e paguajës",
      "COLUMN_PAY_AMOUNT_TYPE": "Lloji i shumës së pagesës",
      "COLUMN_PAY_UPLIFT": "Ngritja e pagave",
      "ASSET_REGISTRY_TAB_LABEL": "Regjistri i aseteve"
    },
    "VIEW": {
      "ACCOUNT": {
        "ERROR_500": "Ne nuk mund të përpunojmë ndryshimin tuaj tani. Ju lutemi provoni përsëri.",
        "ERROR_403": "Ju nuk keni leje për të shikuar/modifikuar informacionin e llogarisë së këtij personi.",
        "SAVE_SUCCESS": "Ndryshimet tuaja janë ruajtur.",
        "TITLE": "llogari",
        "LAST_SIGN_IN_LABEL": "Identifikimi i fundit",
        "LAST_SIGN_IN_NEVER": "Asnjëherë",
        "SECTION_PERMISSIONS_TITLE": "Lejet",
        "PERMISSION_LEVEL_LABEL": "Niveli i lejes",
        "PERMISSION_LEVEL_HELP": "Kjo do të ndryshojë atë që punonjësi mund të shohë dhe të bëjë në Rotaready.",
        "SITE_ACCESS_LABEL": "Qasja në faqen",
        "SITE_ACCESS_OPTION_APPOINTMENT": "Faqja e tyre aktuale",
        "SITE_ACCESS_OPTION_ALL": "Të gjitha faqet",
        "SITE_ACCESS_OPTION_CUSTOM": "Faqja e tyre aktuale dhe faqet e personalizuara",
        "CLOCK_IN_PIN_LABEL": "Rivendosni PIN-in e hyrjes së orës",
        "CLOCK_IN_PIN_HELP": "Futni një kod të ri me 4 shifra për të ndryshuar PIN-in e orës së këtij punonjësi.",
        "TAGS_LABEL": "Etiketat",
        "TAGS_TIP": "Etiketoni punonjësit me çdo gjë kuptimplote, siç janë aftësitë ose atributet",
        "TAGS_PLACEHOLDER": "Shkruani për të gjetur etiketat",
        "SAVE_BUTTON_LOADING": "Kursimi...",
        "SAVE_BUTTON_TEXT": "Ruaj",
        "LANGUAGE_LABEL": "Gjuha",
        "LANGUAGE_HELP": "Rotaready do të shfaqet në gjuhën e zgjedhur.",
        "ENTITY_GROUP_SUBSCRIPTION_TOOLTIP":"Ky punonjës caktohet automatikisht në këtë faqe. Ju mund të menaxhoni qasjen e tyre në faqen e parametrave të Menaxherit të Qasjes në Faqe."
      },
      "PAY": {
        "INDEX": {
          "TAB_HISTORY": "Regjistrimet e pagave",
          "TAB_TRONC": "Tronc",
          "TAB_FINANCIALS": "Financimet"
        },
        "VIEW_HISTORY": {
          "FILTER_TYPES_LABEL": "Lloji",
          "FILTER_TYPES_SALARY": "Paga",
          "FILTER_TYPES_POINT_IN_TIME": "Pagesa të njëhershme",
          "FILTER_ELEMENT_TYPES_LABEL": "Elementi i pagesës",
          "STATE_ACTIVE": "Aktiv",
          "STATE_HISTORICAL": "Historike",
          "STATE_FUTURE_DATED": "Datuar në të ardhmen",
          "COLUMN_EFFECTIVE_DATE": "Data e fuqisë",
          "COLUMN_STATUS": "Statusi",
          "COLUMN_ELEMENT_TYPE": "Elementi i pagesës",
          "COLUMN_CURRENCY": "Monedha",
          "COLUMN_AMOUNT": "Shuma",
          "COLUMN_AMOUNT_TYPE": "Lloji i shumës",
          "COLUMN_OPTIONS": "Opsionet",
          "ERROR_500": "Ne nuk mund të ngarkojmë regjistrat e pagave tani. Ju lutemi provoni përsëri.",
          "ERROR_403": "Ju nuk keni leje për të shikuar/modifikuar të dhënat e pagave të këtij personi.",
          "ALERT_SUCCESS": "Rekordi i pagave është ruajtur.",
          "ALERT_DELETED": "Regjistrimi i pagave është fshirë.",
          "OUTSIDE_OF_APPOINTMENT_WARNING": "Ky regjistër pagash është i pavlefshëm sepse ata nuk janë të punësuar në mënyrë aktive në këtë datë. Ju lutemi ndryshoni datën e fuqisë.",
          "DELETE_LINK": "Fshini",
          "LAST_EDITED_TOOLTIP": "Redaktuar për herë të fundit nga {{UserName}} më {{date}}",
          "NO_RECORDS": "Nuk ka regjistra të pagave për të treguar.",
          "ADD_NEW_BUTTON": "Rekord i ri i pagave",
          "CONFIRM_DELETE": "A jeni i sigurt që dëshironi të fshini këtë regjistër pagash? Kjo nuk mund të çaktivizohet."
        },
        "PAY_FORM": {
          "TITLE": "Paguaj",
          "LABEL_EFFECTIVE_DATE": "Data e fuqisë",
          "LABEL_ELEMENT": "Elementi i pagesës",
          "LABEL_AMOUNT": "Shuma",
          "LABEL_AMOUNT_TYPE": "Lloji i shumës",
          "LABEL_UPLIFT": "Ngritja e pagave",
          "ALERT_NO_CURRENCY": "Punonjësi nuk është i punësuar në mënyrë aktive në këtë datë.",
          "ALERT_DUPLICATE_PAY": "Tashmë ekziston një regjistër pagash për elementin tuaj të zgjedhur të pagës në atë datë.",
          "HELP_TEXT_UPLIFT": "Kjo do të rritë pagat e punonjësve në Kontrollin e Kostos. Përdoreni atë për të pasqyruar çdo kosto shtesë që bëni, si një pension. Mos u shqetësoni për taksat pasi ato përfshihen automatikisht.",
          "BUTTON_SAVE": "Ruaj",
          "BUTTON_SAVE_LOADING": "Kursimi...",
          "BUTTON_DELETE": "Fshini",
          "BUTTON_DELETE_LOADING": "Fshirja e..."
        },
        "VIEW_FINANCIALS": {
          "ERROR_500": "Ne nuk mund të ngarkojmë informacionin financiar tani. Ju lutemi provoni përsëri.",
          "ERROR_403": "Ju nuk keni leje për të shikuar/modifikuar informacionin financiar të këtij personi.",
          "SAVE_SUCCESS": "Ndryshimet tuaja janë ruajtur.",
          "SECTION_BANK_ACCOUNT": "Llogari bankare",
          "SECTION_TAXES": "Taksat",
          "ACCOUNT_NUMBER_LABEL": "Numri i llogarisë",
          "SORT_CODE_LABEL": "Renditja e kodit",
          "ACCOUNT_NAME_LABEL": "Emri i llogarisë",
          "PAYMENT_METHOD": "Metoda e pagesës",
          "NI_NUMBER_LABEL": "Numri i sigurimit kombëtar",
          "SECTION_STUDENT_LOANS": "Kredi studentore (Mbretëria e Bashkuar)",
          "PLAN_TYPE_LABEL": "Lloji i planit",
          "EFFECTIVE_DATE_LABEL": "Data e fuqisë",
          "LOAN_TABLE_PLAN_COLUMN": "Lloji i planit",
          "LOAN_TABLE_DATE_COLUMN": "Data e fuqisë",
          "LOAN_TABLE_OPTIONS_COLUMN": "Opsionet",
          "LOAN_TABLE_NO_ROWS": "Nuk janë shtuar kredi studentore.",
          "LOAN_TABLE_DATE_NOT_SET": "Nuk është caktuar",
          "SUBHEADING_ADD_STUDENT_LOAN": "Shto kredi studentore",
          "OPTION_DELETE": "Fshini",
          "ADD_LOAN_BUTTON": "Shto kredi studentore",
          "SAVE_BUTTON_TEXT": "Ruaj"
        },
        "EDIT_STUDENT_LOAN": {
          "HEADING": "Redakto kredinë studentore",
          "FORM_PLAN_TYPE_LABEL": "Lloji i planit",
          "FORM_EFFECTIVE_DATE_LABEL": "Data e hyrjes në fuqi",
          "SAVE_BUTTON_TEXT": "Ruaj"
        },
        "VIEW_TRONC": {
          "COLUMN_EFFECTIVE_DATE": "Data e fuqisë",
          "COLUMN_STATUS": "Statusi",
          "COLUMN_AMOUNT": "Shuma",
          "COLUMN_APPORTION_TYPE": "Lloji i ndarjes",
          "COLUMN_LAST_EDITED": "Redaktuar për herë të fundit",
          "STATE_ACTIVE": "Aktiv",
          "STATE_HISTORICAL": "Historike",
          "STATE_FUTURE_DATED": "Datuar në të ardhmen",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Tarifa sipas orës",
          "APPORTION_TYPE_CLASSIC_SALARY": "Paga vjetore",
          "APPORTION_TYPE_POINTS": "Pikët",
          "NO_RECORDS": "Nuk ka tarifa Tronc për të treguar.",
          "ADD_NEW_BUTTON": "Norma e re e Tronc",
          "ERROR_500": "Ne nuk mund të ngarkojmë tarifat e Tronc tani. Ju lutemi provoni përsëri.",
          "ERROR_403": "Ju nuk keni leje për të parë tarifat e Tronc të këtij personi."
        },
        "ADD_TRONC_MODAL": {
          "TITLE": "Norma e re e Tronc",
          "BUTTON_SAVE": "Ruaj",
          "BUTTON_CANCEL": "Anuloni",
          "LABEL_PERSON": "Person",
          "LABEL_DATE": "Data e fuqisë",
          "LABEL_APPORTION_TYPE": "Lloji i ndarjes",
          "LABEL_AMOUNT": "Shuma",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Tarifa sipas orës",
          "APPORTION_TYPE_CLASSIC_SALARY": "Paga vjetore",
          "APPORTION_TYPE_POINTS": "Pikët",
          "APPORTION_TYPE_HELP": "Tronc mund të punojë në një nga dy mënyrat: bazuar në pikë ose klasike, e cila përdor një tarifë ose pagë për orë. Kjo kontrollohet nga një mjedis për organizatën tuaj ose vendin/vendin specifik.",
          "ERROR_OTHER": "Ne nuk mund të kursejmë normën Tronc tani. Ju lutemi provoni përsëri.",
          "ERROR_UNAUTHORIZED": "Ju nuk keni leje për të modifikuar tarifat e Tronc të këtij personi.",
          "ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE": "Ky punonjës nuk ka një takim aktiv në datën tuaj të zgjedhur të fuqisë.",
          "ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE": "Dikush tashmë ka filluar hartimin e Tronc për datën tuaj të zgjedhur të fuqisë. Ju lutemi përdorni mjetin Tronc për të modifikuar tarifat për këtë datë.",
          "ERROR_NOT_SUPPORTED": "Ju nuk mund të zgjidhni atë lloj ndarjeje. Tronc nuk është konfiguruar për të punuar në atë mënyrë."
        }
      },
      "INDEX": {
        "OPTIONS_LABEL": "Opsionet",
        "OPTION_APPROVE_LINK": "Miratoni për rotat dhe listën e pagave",
        "OPTION_SEND_MAGIC_LINK": "Ftoni në Rotaready (dërgoni një lidhje magjike)",
        "OPTION_SEND_MAGIC_LINK_TOOLTIP": "Ky person tashmë ka vendosur llogarinë e tij. Nëse ata kanë probleme me hyrjen, kërkoni atyre të ndjekin lidhjen e rikuperimit të llogarisë në faqen e hyrjes.",
        "OPTION_ANONYMISE": "Anonimizoni",
        "OPTION_DELETE": "Fshini",
        "ALERT_APPROVE_SUCCESS": "Ata tani janë miratuar dhe gati për t'u dhënë ndërrime.",
        "ALERT_APPROVE_ERROR": "Nuk mund ta miratojmë llogarinë vetëm atëherë. Ju lutemi provoni përsëri.",
        "ALERT_MAGIC_LINK_SUCCESS_ALL": "Kemi dërguar një email dhe një mesazh me një lidhje magjike. Ju lutemi lejoni disa minuta që të mbërrijë.",
        "ALERT_MAGIC_LINK_SUCCESS_EMAIL": "Kemi dërguar një email me një lidhje magjike. Ju lutemi lejoni disa minuta që të mbërrijë.",
        "ALERT_MAGIC_LINK_SUCCESS_SMS": "Kemi dërguar një mesazh me një lidhje magjike. Ju lutemi lejoni disa minuta që të mbërrijë.",
        "ALERT_MAGIC_LINK_ERROR_GENERIC": "Nuk mund të dërgojmë një lidhje magjike vetëm atëherë.",
        "ALERT_MAGIC_LINK_ERROR_NO_DETAILS": "Ne nuk mund të dërgojmë një lidhje magjike pa një adresë emaili ose numër telefoni.",
        "ALERT_ACCOUNT_ERROR_500": "Nuk mund ta ngarkojmë punonjësin vetëm atëherë. Ju lutemi provoni përsëri.",
        "ALERT_ACCOUNT_ERROR_404": "Nuk ka asnjë llogari punonjësi të lidhur me atë ID.",
        "DELETE_ACCOUNT_TITLE": "Fshi punonjësit",
        "DELETE_ACCOUNT_MESSAGE": "Paralajmërim! Fshirja e një punonjësi është e përhershme. Bëni këtë vetëm nëse e keni krijuar punonjësin gabimisht. Për të vazhduar, shkruani emrin e tyre të plotë më poshtë.",
        "DELETE_ACCOUNT_BUTTON": "Fshini përgjithmonë",
        "DELETE_ACCOUNT_ERROR": "Punonjësi nuk mund të fshihet. Ju mund të mos keni leje për ta bërë këtë.",
        "DELETE_ACCOUNT_SUCCESS": "Punonjësi është fshirë."
      },
      "PERSONAL": {
        "ERROR_500": "Ne nuk mund të përpunojmë ndryshimin tuaj tani. Ju lutemi provoni përsëri.",
        "ERROR_403": "Ju nuk keni leje për të shikuar/modifikuar informacionin personal të këtij personi.",
        "SAVE_SUCCESS": "Ndryshimet tuaja janë ruajtur.",
        "EMERGENCY_CONTACTS_LINK_TEXT": "Kontaktet emergjente",
        "FINANCIALS_LINK_TEXT": "Financë/Taksat",
        "LABEL_OPTIONS": "Më shumë opsione",
        "LABEL_APPROVED_BY": "Miratuar nga",
        "TITLE_LABEL": "Titulli",
        "FIRST_NAME_LABEL": "Emri i parë",
        "LAST_NAME_LABEL": "Mbiemri",
        "OTHER_NAME_LABEL": "Emra të tjerë (opsionale)",
        "DOB_LABEL": "Data e lindjes",
        "DOB_HELP": "{{moshë}} vjeç",
        "PREFERRED_NAME_LABEL": "Emri i preferuar",
        "SECTION_ADDRESS": "Adresa",
        "SECTION_VISAS": "Viza",
        "SECTION_IDENTITY": "Identiteti",
        "SECTION_CONTACT": "Kontakt",
        "SECTION_OTHER": "Të tjera",
        "SECTION_RIGHT_TO_WORK": "E drejta për të punuar",
        "NATIONALITY_LABEL": "Kombësia",
        "GENDER_LABEL": "Gjinia",
        "ETHNICITY_LABEL": "Etnikësia",
        "MARITAL_STATUS_LABEL": "Statusi martesor",
        "PASSPORT_NUMBER_LABEL": "Numri i pasaportës",
        "PASSPORT_EXPIRY_LABEL": "Data e skadimit të pasaportave",
        "SETTLED_STATUS_LABEL": "Statusi i vendosur (UK)",
        "PRIMARY_EMAIL_LABEL": "Email kryesor",
        "PRIMARY_EMAIL_HELP": "Punonjësi e përdor këtë për të hyrë në Rotaready. Mund të ndryshohet vetëm nëse ata nuk janë hyrë më parë.",
        "PRIMARY_PHONE_LABEL": "Telefoni kryesor",
        "PRIMARY_PHONE_HELP": "Punonjësi e përdor këtë për të marrë njoftime. Prefiksi i saktë i kodit të vendit do të zbatohet automatikisht pasi të keni ruajtur.",
        "SECONDARY_EMAIL_LABEL": "Email dytësor",
        "SECONDARY_PHONE_LABEL": "Telefoni dytësor",
        "HR_ID_LABEL": "ID HR",
        "HR_ID_HELP": "Nëse ky punonjës ka një numër ID të përdorur për qëllime të burimeve njerëzore, mund ta ruani këtu.",
        "PAYROLL_ID_LABEL": "ID e pagës",
        "PAYROLL_ID_HELP": "Nëse ky punonjës ka një numër ID të përdorur për qëllime të pagave, mund ta ruani këtu.",
        "SAVE_BUTTON_LOADING": "Kursimi...",
        "SAVE_BUTTON_TEXT": "Ruaj",
        "LINK_ADD_ADDRESS": "Shto adresë të re",
        "LINK_VIEW_ADDRESS_HISTORY": "Shikoni adresat e mëparshme",
        "NO_ADDRESS_HISTORY_TEXT": "Nuk ka adresa të mëparshme",
        "CURRENT_ADDRESS_LABEL": "Aktualisht",
        "SHARE_CODE_LABEL": "Kodi i ndarjes",
        "SHARE_CODE_ERROR_LENGTH": "Kodi i ndarjes duhet të jetë 9 karaktere i gjatë.",
        "SHARE_CODE_ERROR_FORMAT": "Kodi i ndarjes duhet të përmbajë vetëm karaktere alfanumerike.",
        "SHARE_CODE_EXPIRY_DATE_LABEL": "Data e skadimit të kodit të ndarjes",
      },
      "ADDRESS_HISTORY": {
        "ALERT_ERROR_500": "Ne nuk mund të përpunojmë ndryshimin tuaj tani. Ju lutemi provoni përsëri.",
        "TITLE_ADD": "Shto adresë të re",
        "TITLE_EDIT": "Redakto adresën",
        "ADVICE_EDIT": "Nëse adresa e punonjësit po ndryshon, në vend të kësaj duhet të shtoni një adresë të re. Rotaready automatikisht do të zhvendosë adresën aktuale në historinë e tyre të adresave.",
        "ADVICE_EDIT_LINK": "Shto adresë të re",
        "SAVE_BUTTON_TEXT": "Ruaj",
        "DELETE_BUTTON_TEXT": "Fshini",
        "ADDRESS_LINE_1_LABEL": "Linja 1",
        "ADDRESS_LINE_2_LABEL": "Linja 2 (opsionale)",
        "ADDRESS_CITY_LABEL": "Qyteti/qytet",
        "ADDRESS_COUNTY_LABEL": "qark",
        "ADDRESS_POST_CODE_LABEL": "Kodi Postar",
        "ADDRESS_COUNTRY_LABEL": "Shteti",
        "NOTES_LABEL": "Shënime",
        "CONFIRM_DELETE": "A jeni i sigurt që dëshironi ta fshini këtë adresë?"
      },
      "VISAS": {
        "ADD_BTN": "Shto vizë",
        "CURRENT_HEADING": "Viza aktive",
        "NO_CURRENT_VISA": "Ky përdorues nuk ka një vizë aktive.",
        "CURRENT_LABEL": "Aktive",
        "VISA_TYPE_LABEL": "Lloji i vizës",
        "VISA_NUMBER_LABEL": "Numri i vizës",
        "VISA_EXPIRY_LABEL": "Data e skadimit të vizës",
        "VISA_EXPIRY_NOT_APPLICABLE": "Nuk aplikohet",
        "VISA_EDIT_BTN": "Redakto vizën",
        "PREVIOUS_LABEL": "E mëparshme",
        "SHOW_PREVIOUS_BTN": "Trego vizat e mëparshme",
        "HIDE_PREVIOUS_BTN": "Fshih vizat e mëparshme",
        "PREVIOUS_HEADING": "Viza e mëparshme",
        "MODAL": {
          "ADD_HEADING": "Shto një vizë të re",
          "ALERT_ERROR_500": "Nuk mund të përpunojmë ndryshimin tuaj tani. Ju lutemi provoni përsëri.",
          "FORM_LABEL_VISA_TYPE": "Lloji i vizës",
          "FORM_LABEL_VISA_NUMBER": "Numri i vizës",
          "FORM_LABEL_IS_CURRENT": "Kjo është viza aktive e përdoruesit",
          "FORM_LABEL_HAS_EXPIRY_DATE": "Kjo vizë ka një datë skadimi",
          "FORM_LABEL_VISA_EXPIRY": "Data e skadimit të vizës",
          "FORM_BTN_SAVE": "Ruaj",
          "EDIT_VISA_HEADING": "Redakto vizën",
          "DELETE_VISA_BTN": "Fshi vizën",
          "CONFIRM_DELETE": "A jeni i sigurt që dëshironi të fshini këtë vizë?"
        }
      },
      "SCHEDULE": {
        "TITLE": "Kalendari më poshtë tregon orarin e plotë të {{firstName}}, duke përfshirë ndërrimet, mungesën dhe disponueshmërinë.",
        "SHIFTS_LABEL": "Ndryshimet",
        "ABSENCE_LABEL": "Mungesa",
        "UNAVAILABLE_LABEL": "E padisponueshme (modele)",
        "EDIT_LINK_TEXT": "Redakto përjashtimet"
      },
      "ADD_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Rutina e zhvendosjes së",
        "HAS_OVERLAP_TEXT": "Datat e mësipërme mbivendosen me një rutinë ekzistuese të ndryshimit",
        "END_BEFORE_START_TEXT": "Data e fillimit është para datës së përfundimit.",
        "SAVE_BUTTON_TEXT": "Ruaj",
        "SAVE_BUTTON_LOADING": "Kursimi...",
        "CANCEL_BUTTON_TEXT": "Anuloni"
      },
      "DELETE_ROUTINE_MAPPING_MODAL": {
        "TITLE": "A jeni i sigurt?",
        "WARNING": "Fshirja e kësaj hartë do të heqë të gjitha ndryshimet e ardhshme që lidhen me të.",
        "YES_BUTTON_TEXT": "po",
        "YES_BUTTON_ADDITIONAL_TEXT": "Fshini këtë hartë",
        "NO_BUTTON_TEXT": "Jo",
        "NO_BUTTON_ADDITIONAL_TEXT": "Unë kam ndryshuar mendjen time",
        "CANCEL_BUTTON_TEXT": "Anuloni"
      },
      "EDIT_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Redaktoni hartimin rutinë të ndërri",
        "END_DATE_LABEL": "Data e përfundimit",
        "CONTINUE_FOREVER_LABEL": "Vazhdoni rutinën e zhvendosjes",
        "END_BEFORE_START_TEXT": "Data e përfundimit është para datës së fillimit.",
        "HAS_OVERLAP_NO_END": "Vendosja e kësaj datë përfundimtare do të shkaktonte një mbivendosje me një rutinë ekzistuese",
        "HAS_OVERLAP_END": "Vendosja e datës së përfundimit do të shkaktonte një mbivendosje me një rutinë ekzistuese",
        "SAVE_BUTTON_TEXT": "Ruaj",
        "SAVE_BUTTON_LOADING_TEXT": "Kursimi...",
        "CANCEL_BUTTON_TEXT": "Anuloni"
      },
      "SHIFT_ROUTINES": {
        "MAP_ROUTINE_BUTTON_TEXT": "Harta në rutinë",
        "MAP_ROUTINE_SUBTITLE": "Ndryshoni hartimet rutinë",
        "MAP_ROUTINE_TIP": "Hartimi i dikujt në një rutinë ndërrimi bën që ndërrimet të krijohen automatikisht. Ato do të fshihen nëse fshini hartën, ose nëse shtoni një datë përfundimi.",
        "EDIT_END_DATE_TEXT": "Redaktoni datën e përfundimit",
        "ADD_END_DATE_TEXT": "Shto datën e përfundimit",
        "MAPPING_NO_END": "Që nga ",
        "MAPPING_END": " të ",
        "USER_HAS_NO_MAPPING": "{{firstName}} nuk hartohet me asnjë rutinë ndërrimi. Ju mund të shtoni një duke përdorur butonin e mësipërm."
      },
      "ABSENCE": {
        "INDEX": {
          "TAB_ABSENCE": "Rezervimet e mungesës",
          "TAB_ALLOWANCES": "Shtesat",
          "TAB_SETTINGS": "Cilësimet"
        },
        "VIEW_SETTINGS": {
          "ABSENCE_HOURS_LABEL": "Orët mesatare në ditë",
          "AVERAGE_LENGTH_LABEL": "Llogaritni automatikisht bazuar në kohëzgjatjen mesatare të ditës së tyre të punës",
          "SET_MANUALLY_LABEL": "Vendosni manualisht",
          "HOURS_FULL_DAY_LABEL": "Dita e plotë",
          "HOURS_LABEL": "orë",
          "HOURS_HELP": "Mungesa e një dite të plotë do të jetë e vlefshme kaq shumë orë.",
          "HOURS_HALF_DAY_LABEL": "Gjysmë dite",
          "HOURS_HALF_DAY_HELP": "Një mungesë e gjysmës ditore do të jetë e vlefshme kaq shumë orë.",
          "DELEGATE_APPROVERS": "Miratësit e deleguar",
          "DELEGATE_APPROVERS_REMOVE": "Hiq",
          "DELEGATE_APPROVERS_HELP": "Nëse ky punonjës mundëson Mos shqetësoni në aplikacionin Rotaready dhe ngrihet një kërkesë për mungesë që kërkon miratimin e tyre, miratuesit e delegatëve të tyre do të njoftohen në vend të kësaj.",
          "SAVE_BUTTON_TEXT": "Ruaj",
          "SAVE_BUTTON_LOADING_TEXT": "Kursimi...",
          "PRE_ROTAREADY_AVERAGE_HOURS_LABEL": "Orët mesatare të Pre-Rotaready në ditë",
          "PRE_ROTAREADY_AVERAGE_HOURS_HELP": "Nëse keni kaluar në Rotaready nga një sistem tjetër gjatë punësimit të këtij personi, futni 'orët mesatare në ditë' të fundit të njohura dhe datën në të cilën kjo ishte e vlefshme.",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_LABEL": "E fundit e vlefshme në",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_HELP": "Kur llogaritni një vlerë të re për orët mesatare në ditë, nëse punonjësi nuk ka punuar të gjitha 52 javët e fundit në Rotaready, llogaritja jonë do të përdorë vlerën pre-ROTAReady për javët para kësaj date."
        },
        "VIEW_BOOKINGS": {
          "INCLUDE_CANCELLED_TOGGLE_LABEL": "Përfshini anuluar",
          "NO_BOOKINGS_MATCH_TEXT": "Ne nuk mund të gjetëm asnjë rezervim që përputhet me filtrat tuaj.",
          "BOOK_ABSENCE_BUTTON_TEXT": "Mungesa e librit",
          "CANCEL_LINK": "Anuloni",
          "DATES_COLUMN_TEXT": "Data (et)",
          "ABSENCE_TYPE_COLUMN_TEXT": "Lloji",
          "HOURS_COLUMN_TEXT": "Orë",
          "DAYS_COLUMN_TEXT": "Ditët",
          "STATUS_COLUMN_TEXT": "Statusi",
          "SUBMITTED_COLUMN_TEXT": "Paraqitur",
          "APPROVED_COLUMN_TEXT": "Shqyrtuar",
          "CANCELLED_COLUMN_TEXT": "Anuluar",
          "FILTER_LABEL_APPROVAL_STATE": "Statusi",
          "FILTER_LABEL_ABSENCE_TYPE": "Lloji",
          "FILTER_LABEL_DATE": "datë",
          "CANCEL_REQUEST": {
            "CONFIRM": "A jeni i sigurt që dëshironi të anuloni këtë rezervim të mungesës? Kjo nuk mund të çaktivizohet.",
            "SUCCESS": "Rezervimi i mungesës është anuluar.",
            "ERROR_500": "Nuk mund ta anulonim këtë vetëm tani. Ju lutemi provoni përsëri."
          }
        },
        "VIEW_ALLOWANCES": {
          "ERROR_500": "Ups, ne goditëm një pengesë vetëm atëherë. Ju lutemi provoni përsëri.",
          "BUTTON_ADD": "Shpërblim i ri",
          "BUTTON_OPTIONS": "Opsionet",
          "BUTTON_TRANSACTIONS": "Shiko transaksionet",
          "BUTTON_EDIT": "Ndrysho",
          "BUTTON_DELETE": "Fshini",
          "NO_ALLOWANCES_TEXT": "Nuk ka asnjë shtesë që përputhet me filtrat tuaj.",
          "FILTER_LABEL_STATUS": "Statusi",
          "FILTER_LABEL_ABSENCE_TYPE": "Lloji",
          "CHRONO_TYPE_ACTIVE": "Aktiv",
          "CHRONO_TYPE_FUTURE": "E ardhmja",
          "CHRONO_TYPE_HISTORICAL": "Historike",
          "LABEL_NOT_DEDUCTIBLE": "Jo i zbritshëm",
          "METRIC_ACCRUED": "E drejta",
          "METRIC_BOOKED": "Rezervuar",
          "METRIC_REMAINING": "Të mbetura",
          "METRIC_ACCRUED_LIMIT": "nga {{count}}",
          "METRIC_ACCRUED_LIMIT_HELP": "Ky është maksimumi që mund të përllogaritet.",
          "METRIC_ELAPSED": "kaluar",
          "METRIC_ELAPSED_HELP": "Ky është numri i ditës/orëve të rezervuara që janë në të kaluarën. Kjo rillogaritet automatikisht brenda natës.",
          "METRIC_OVERSPEND": "shpenzim i tepërt",
          "METRIC_OVERSPEND_HELP": "Ky është numri i ditëve/orëve që mund të rezervohen mbi shumën e përllogaritur.",
          "METRIC_ACCRUAL_ESTIMATE": "për të grumbulluar",
          "METRIC_ACCRUAL_ESTIMATE_HELP": "Bazuar në orët tuaja mesatare të punës, ne vlerësojmë se ju do ta grumbulloni këtë deri në fund të vitit të ndihmës.",
          "SCHEDULE_BEGINNING_OF_ACCRUAL_YEAR": "Përllogaritet në fillim të çdo viti të përllogaritjes",
          "SCHEDULE_EACH_CALENDAR_MONTH": "Përllogaritet çdo muaj kalendarik",
          "SCHEDULE_EACH_HOUR_WORKED": "Përllogaritet për çdo orë të punuar",
          "SCHEDULE_EACH_HOUR_WORKED_RATE": "Përllogaritet për çdo orë të punuar me {{rate}}%",
          "CARRIED_OVER": "{{summa}} e transferuar nga viti i kaluar",
          "LINK_TRANSACTIONS": "Shiko transaksionet",
          "ALERT_CONFIRM_DELETE": "A jeni i sigurt që dëshironi ta fshini këtë ndihmë? Kjo nuk mund të çaktivizohet.",
          "ALERT_SUCCESS_DELETE": "Ndihma është fshirë.",
          "FINALISED": "🔒 Finalizuar ({{date}})",
          "FINALISE": "Finalizoni",
          "CONVERT_HOURS_TO_DAYS_TOGGLE_LABEL": "Konvertoni orët në ditë"
        },
        "ALLOWANCE_VALIDATION_MODAL": {
          "TITLE": "Diçka nuk është mjaft e drejtë",
          "DESCRIPTION": "Fatkeqësisht ajo që po përpiqeni të bëni shkakton një ose më shumë probleme.",
          "BUTTON_CLOSE": "Mbyll",
          "INVALID_ALLOWANCE_TITLE": "Shtesë e pavlefshme",
          "INVALID_ALLOWANCE_DESCRIPTION": "Që atëherë ndihma është fshirë dhe nuk mund të redaktohet më.",
          "OVERLAPPING_ALLOWANCE_TITLE": "Shtesat mbivendosura",
          "OVERLAPPING_ALLOWANCE_DESCRIPTION": "Kjo ndihmë do të mbivendoset me një ndihmë ekzistuese. Ju nuk mund të keni shpenzime të mbivendosura për të njëjtin lloj mungese.",
          "FINALISED_ALLOWANCE_TITLE": "Shpërblimi i përfunduar",
          "FINALISED_ALLOWANCE_DESCRIPTION": "Kjo ndihmë është përfunduar. Për ta modifikuar ose fshirë atë, së pari duhet të ktheheni lehtësimin.",
          "INVALID_UNIT_TITLE": "Njësia e pavlefshme",
          "INVALID_UNIT_DESCRIPTION": "Një ndihmë me orarin e përllogaritjes të caktuar për Çdo orë të punuar duhet të ketë njësinë të vendosur në Orë.",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_TITLE": "Data e pavlefshme e fuqisë",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "Data e zgjedhur e fuqisë duhet të bjerë midis fillimit dhe fundit të vitit të ndihmës.",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_TITLE": "Data e pavlefshme e referencës",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "Data e referencës duhet të bjerë midis datës së efektimit të ndihmës dhe përfundimit të vitit të ndihmës."
        },
        "ALLOWANCE_FORM": {
          "ERROR_500": "Ups, ne goditëm një pengesë vetëm atëherë. Ju lutemi provoni përsëri.",
          "ALERT_SUCCESS_ADD": "Shtesa është shtuar.",
          "ALERT_SUCCESS_UPDATE": "Ndihma është përditësuar.",
          "ALERT_ERROR_CALCULATE_BOOKED": "Ne nuk mund të llogarisim vlerën e tyre të rezervuar tani. Ju lutemi provoni përsëri.",
          "BUTTON_SAVE": "Ruaj",
          "BUTTON_SAVE_LOADING": "Kursimi...",
          "TITLE": "Shpërblim",
          "LABEL_YEAR_STARTS": "Viti fillon",
          "YEAR_STARTS_HELP": "Viti i paracaktuar i festave të organizatës suaj shkon nga {{settingDate}}.",
          "YEAR_STARTS_CUSTOMISE_INITIATE": "Zgjidhni një ditë ose muaj tjetër.",
          "YEAR_STARTS_CUSTOMISE_CANCEL": "Kthehuni në ditën dhe muajin e paracaktuar.",
          "LABEL_EFFECTIVE_DATE": "Data e fuqisë",
          "EFFECTIVE_DATE_HELP": "Nëse kjo ndihmë po zëvendëson një ndihmë ekzistuese në të njëjtin vit, duhet të zgjidhni një datë kur kjo është në fuqësi.",
          "ALERT_MISALIGNED_YEAR_START": "Kjo nuk përputhet me vitin e paracaktuar të festave të organizatës suaj.",
          "ALERT_MISALIGNED_EFFECTIVE_DATE": "Data juaj e fuqisë nuk është brenda vitit të ndihmës.",
          "ALERT_ALTERED_EFFECTIVE_DATE": "Data juaj e fuqisë nuk përputhet me datën e fillimit të vitit të ndihmës. Ju duhet ta bëni këtë vetëm nëse po zëvendësoni një ndihmë ekzistuese në të njëjtin vit.",
          "LABEL_ABSENCE_TYPE": "Lloji i mungesës",
          "LABEL_SCHEDULE_OF_ACCRUAL": "Orari i përllogaritjes",
          "TOOLTIP_SCHEDULE_OF_ACCRUAL": "Për të caktuar orë ose ditë manualisht, zgjidhni 'fillimi i vitit të përllogaritës'. Për Rotaready të caktojë orë ose ditë çdo muaj kalendarik, zgjidhni 'çdo muaj kalendarik'. Për të grumbulluar automatikisht bazuar në orët e punës, zgjidhni “çdo orë e punuar”.",
          "LABEL_UNIT": "Njësia",
          "LABEL_DEDUCTIBLE": "I zbritshëm",
          "TOOLTIP_DEDUCTIBLE": "Kontrolloni nëse punonjësi mund të vendosë kërkesa për mungesë që zbriten nga ky ndihmë.",
          "DEDUCTIBLE_YES": "po",
          "DEDUCTIBLE_NO": "Jo",
          "LABEL_PROJECT": "Parashikoni të përllogaritur gjatë rezervimit",
          "TOOLTIP_PROJECT": "Kur vendosni një kërkesë për mungesë, parashikoni orët që ka të ngjarë të jenë përllogaritur dhe përfshini ato në shifrën e përllogaritur të punonjësit.",
          "PROJECT_YES": "po",
          "PROJECT_NO": "Jo",
          "LABEL_TOTAL_ACCRUED_ANNUALLY": "Totali i përllogaritur çdo vit",
          "LABEL_TOTAL_ACCRUED": "Totali i përllogaritur",
          "TOOLTIP_TOTAL_ACCRUED": "Futni numrin e orëve ose ditëve që ky punonjës ka përllogaritur deri më tani. Rotaready do ta përditësojë këtë automatikisht duke shkuar përpara.",
          "LABEL_BOOKED": "Rezervuar",
          "TOOLTIP_BOOKED": "Shkruani numrin e orëve ose ditëve që ky punonjës ka rezervuar. Rotaready do ta mbajë këtë automatikisht.",
          "ALERT_BOOKED": "Ne e kemi llogaritur këtë për ju bazuar në mungesën e rezervuar në Rotaready. Ndryshoni këtë vetëm nëse mungesa është rezervuar jashtë Rotaready.",
          "TOOLTIP_BOOKED_BUTTON": "Llogaritet automatikisht",
          "LABEL_TOTAL_TO_ACCRUE": "Maksimumi për të grumbulluar",
          "TOOLTIP_TOTAL_TO_ACCRUE_HOURLY": "Futni numrin total të orëve ose ditëve që ky punonjës ka të drejtë të grumbullojë. Lëreni bosh për të mos zbatuar një limit.",
          "TOOLTIP_TOTAL_TO_ACCRUE_MONTHLY": "Shkruani numrin total të orëve ose ditëve që ky punonjës duhet të grumbullojë për vitin.",
          "LABEL_ACCRUAL_RATE": "Anashkalimi i normës së përllogaritjes",
          "TOOLTIP_ACCRUAL_RATE": "Opsionale specifikoni një normë përllogaritjeje nëse dëshironi të anashkaloni normën e paracaktuar të juridiksionit në të cilin punon punonjësi.",
          "LABEL_OVERSPEND_LIMIT": "Shpenzimi i tepërt",
          "TOOLTIP_OVERSPEND_LIMIT": "Opsionale lejoni punonjësin të rezervojë më shumë orë ose ditë sesa kanë përllogaritur.",
          "LABEL_CARRIED_OVER": "Transportuar nga viti i kaluar",
          "BUTTON_PRO_RATA": "Pro-rate",
          "LABEL_EMPLOYMENT_START": "Data e fillimit të punësimit",
          "LABEL_PRO_RATA_TOTAL": "Totali i përllogaritur nëse punësohet gjatë gjithë vitit",
          "HELP_TEXT_PRO_RATA": "Ne do të vendosim totalin e përllogaritur në {{value}}.",
          "CREATE_NEXT_YEAR_CHECKBOX": "Krijoni një ndihmë tjetër për vitin e ardhshëm duke përdorur totalin e përllogaritur nëse punësohet gjatë gjithë vitit.",
          "ALERT_SUCCESS_CALCULATE_TOTAL_ACCRUED": "Ne e kemi llogaritur këtë për ju duke përdorur orët e regjistrimit për këtë punonjës.",
          "ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED": "Ju nuk keni leje për të hyrë në orët e regjistrimit për këtë punonjës. Pa këtë, ne nuk mund të llogarisim totalin e përllogaritur për ju.",
          "ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED": "Ne nuk mund të llogarisim orët totale të përllogaritura automatikisht. Ju lutemi provoni përsëri.",
          "TOOLTIP_CALCULATE_TOTAL_ACCRUED": "Llogaritet automatikisht"
        },
        "ALLOWANCE_TRANSACTIONS": {
          "TITLE": "Transaksionet",
          "DESCRIPTION": "Këtu janë të gjitha transaksionet që ndikojnë në lehtësimin, duke shfaqur së pari më të fundit:",
          "TYPE_ACCRUAL_HOURS_WORKED": "Përllogaritja nga orët e punës",
          "TYPE_ABSENCE_REQUESTED": "Mungesa e kërkuar",
          "TYPE_ABSENCE_REJECTED_OR_CANCELLED": "Mungesa e refuzuar ose anuluar",
          "TYPE_MANUAL_ADJUSTMENT": "Rregullimi manual",
          "TYPE_ACCRUAL_SCHEDULE_CHANGED": "Ndryshuar orari i përllogaritjes",
          "TYPE_CARRY_OVER": "Përcjellë nga viti i kaluar",
          "TYPE_ACCRUAL_TIME_BASED": "Përllogaritja e bazuar në kohë",
          "TYPE_ABSENCE_UPDATED": "Datat e mungesës ndryshojnë",
          "TYPE_HOURS_PER_DAY_RECALCULATION": "Orët në ditë të përditësuar",
          "ERROR_500": "Ups, ne goditëm një pengesë vetëm atëherë. Ju lutemi provoni përsëri.",
          "NO_TRANSACTIONS": "Nuk ka transaksione për të treguar.",
          "BUTTON_CLOSE": "Mbyll",
          "TRANSACTION_TYPE": "Filtro sipas llojit"
        },
        "VIEW_FINALISATION": {
          "TITLE": "Shpërblimi i përfunduar",
          "BUTTON_CLOSE": "Mbyll",
          "BUTTON_REVERT": "Kthehu",
          "CONFIRM_REVERT": "A jeni i sigurt që dëshironi ta ktheheni këtë ndihmë? Kjo gjithashtu do të fshijë regjistrin përkatës të pagës, nëse ka një.",
          "LABEL_REFERENCE_DATE": "Data e referencës",
          "LABEL_PAY_RECORD": "Paga/zbritje",
          "PAY_SEE_MORE": "Shikoni regjistrin e pagave",
          "PAY_NONE": "Asnjë pagë nuk i detyrohet punonjësit, as nuk duhet të zbritet asnjë pagë.",
          "LABEL_NOTES": "Shënime",
          "NOTES_NONE": "Asnjë shënime nuk është regjistruar.",
          "ERROR_500": "Ne nuk mund të ruajmë ndryshimet tuaja vetëm atëherë. Ju lutemi provoni përsëri.",
          "ERROR_403": "Ju nuk keni leje për të modifikuar regjistrat e pagave të këtij personi, kështu që ne nuk mund ta kthejmë ndihmën.",
          "ALERT_SUCCESS": "Ndihma është kthyer."
        },
        "FINALISE_ALLOWANCE_FORM": {
          "TITLE": "Finalizoni ndihmën",
          "BUTTON_SAVE": "Finalizoni",
          "ERROR_500": "Ne nuk mund të ruajmë ndryshimet tuaja vetëm atëherë. Ju lutemi provoni përsëri.",
          "ERROR_403": "Ju nuk keni leje për të modifikuar regjistrat e pagave të këtij personi, kështu që ne nuk mund të finalizojmë ndihmën.",
          "ALERT_SUCCESS": "Ndihma është përfunduar.",
          "GUIDANCE": "Ne rekomandojmë të finalizoni një ndihmë kur mund të jeni i sigurt që nuk do të grumbullohet, rezervohet ose anulohet më mungesë. Kjo zakonisht është sa më afër ditës së fundit të dikujt.",
          "GUIDANCE_FUTURE_YEAR": "Ju duhet ta bëni këtë vetëm nëse punonjësi largohet në këtë vit ndihmë.",
          "LABEL_EFFECTIVE_DATE": "Data e referencës",
          "EFFECTIVE_DATE_LEAVER_HELP": "Pagesa do të përfundojë në këtë datë. Meqenëse ky person është një largues, ne e kemi vendosur atë në ditën e tyre të fundit.",
          "EFFECTIVE_DATE_NOT_LEAVER_HELP": "Pagesa do të përfundojë në këtë datë. Data që zgjidhni do të përdoret më poshtë për të llogaritur orët ose ditët totale të përllogaritura.",
          "LABEL_BASIC_PAY": "Pagesa themelore",
          "ALERT_BASIC_PAY_NOT_PERMITTED": "Ju nuk keni leje për të parë regjistrat e pagave të këtij punonjësi, kështu që nuk mund ta përdorni këtë veçori për të krijuar një pagesë ose zbritje të njëhershme.",
          "ALERT_BASIC_PAY_NOT_AVAILABLE": "Punonjësi nuk ka një regjistër bazë të pagës në datën tuaj të zgjedhur të referencës.",
          "HELP_TEXT_BASIC_PAY": "Kjo është paga bazë e punonjësit në datën tuaj të zgjedhur të referencës.",
          "LABEL_TOTAL_ACCRUED_LIVE": "Totali i përllogaritur",
          "HELP_TEXT_TOTAL_ACCRUED_LIVE": "Kjo është orët totale të përllogaritura për këtë ndihmë, bazuar në të gjitha ditët që janë regjistruar.",
          "LABEL_ACCRUED_PRO_RATED": "Përllogaritur (proporcionalisht)",
          "HELP_TEXT_TOTAL_ACCRUED_PRO_RATED": "Duke përdorur datën tuaj të zgjedhur të referencës, orë/ditët totale të përllogaritura janë vlerësuar pro.",
          "LABEL_BOOKED": "Totali i rezervuar",
          "HELP_TEXT_BOOKED": "Kjo është mungesa totale që është rezervuar deri më sot, duke përfshirë kërkesat në pritje dhe të miratuara.",
          "LABEL_ACCRUED_UNTAKEN": "Përllogaritur por pa marrë",
          "STATUS_PAYMENT_DUE": "Kjo do të thotë se punonjësi ka marrë më pak mungesë sesa ka përllogaritur. Shumë punëdhënës zgjedhin të paguajnë punonjësin për këtë.",
          "STATUS_DEDUCTION_DUE": "Kjo do të thotë se punonjësi ka marrë më shumë mungesë sesa ka përllogaritur. Disa punëdhënës zgjedhin ta zbritin këtë nga fletëpalosja përfundimtare e pagës së punonjësit.",
          "LABEL_AVERAGE_HOURS": "Orët mesatare në ditë",
          "LABEL_ONE_OFF_PAYMENT": "Pagesë/zbritje e njëhershme",
          "HELP_TEXT_ONE_OFF_PAYMENT": "Nëse futni një shumë, Rotaready do të krijojë një regjistër pagash të njëhershme dhe do ta lidhë atë me këtë ndihmë të përfunduar. Për një zbritje, futni një shumë negative.",
          "HELP_TEXT_ONE_OFF_PAYMENT_LINK": "Ndihmë se si ta llogarisni këtë",
          "LABEL_PAY_ELEMENT": "Elementi i pagesës",
          "LABEL_PAYMENT_DATE": "Data e zbatueshme",
          "HELP_TEXT_PAYMENT_DATE": "Zgjidhni me kujdes për të siguruar që kjo pagesë të përfshihet në pagën përfundimtare të punonjësit.",
          "LABEL_NOTES": "Shënime",
          "PLACEHOLDER_NOTES": "Nëse nuk futni asnjë shënim, ne automatikisht do të përfshijmë shifrat nga llogaritja e mësipërme."
        },
        "FINALISE_ALLOWANCE_PROMPT": {
          "TITLE": "A janë përfunduar të gjitha shpenzimet?",
          "DESCRIPTION": "Nëse jeni të lumtur që të gjitha shtesat tani janë finalizuar, duhet ta konfirmoni këtë, kështu që departamentet si pagat e pagave të mund të mbështeten në shifrat.",
          "DESCRIPTION_PART_2": "Ju gjithashtu mund ta bëni këtë në skedën Punësimi në një datë të mëvonshme.",
          "BUTTON_ATTEST": "Konfirmo",
          "BUTTON_LATER": "Kam më shumë për të bërë",
          "ALERT_ERROR": "Nuk mund ta ruajmë konfirmimin tuaj vetëm atëherë. Ju lutemi provoni këtë në skedën Punësimi në vend të kësaj."
        }
      },
      "EMERGENCY_CONTACTS": {
        "ADD_MODAL": {
          "TITLE": "Kontakt i ri emergjent",
          "RELATIONSHIP_LABEL": "Marrëdhënia me punëtorin",
          "FIRST_NAME_LABEL": "Emri i parë",
          "LAST_NAME_LABEL": "Mbiemri",
          "OTHER_NAME_LABEL": "Emri (et) të tjerë",
          "PRIMARY_CONTACT_NUMBER_LABEL": "Numri kryesor i kontaktit",
          "SECONDARY_CONTACT_NUMBER_LABEL": "Numri i kontaktit dytësor",
          "ADDRESS_LABEL": "Adresa",
          "SAVE_BUTTON_TEXT": "Ruaj",
          "CLOSE_BUTTON_TEXT": "Mbyll",
          "ALERT_ERROR_500": "Ne nuk mund të ruajmë ndryshimet tuaja vetëm atëherë. Ju lutemi provoni përsëri."
        },
        "INDEX": {
          "TITLE": "Kontaktet emergjente",
          "RELATIONSHIP_COLUMN_TEXT": "Marrëdhënia",
          "NAME_COLUMN_TEXT": "Emri",
          "PHONE_COLUMN_TEXT": "Numri (et) i telefonit",
          "ADDRESS_COLUMN_TEXT": "Adresa",
          "NONE_TO_SHOW": "Nuk ka kontakte emergjente për të treguar.",
          "ADD_BUTTON_TEXT": "Kontakt i ri",
          "OPTIONS_COLUMN_TEXT": "Opsionet",
          "DELETE_LINK": "Fshini",
          "ALERT_ERROR_500": "Nuk mund të ngarkojmë kontaktet emergjente vetëm atëherë. Ju lutemi provoni përsëri.",
          "CONFIRM_DELETE": "A jeni i sigurt që dëshironi të fshini këtë kontakt emergjent?\nKjo nuk mund të çaktivizohet."
        }
      },
      "EMPLOYMENT": {
        "LABEL_LENGTH_OF_SERVICE": "Kohëzgjatja e shërbimit",
        "LABEL_STARTS_IN": "Fillon në",
        "LABEL_OPTIONS": "Opsionet",
        "OPTION_NEW_EMPLOYMENT": "Punësim i ri",
        "OPTION_END_EMPLOYMENT": "Përfundoni punësimin",
        "OPTION_REINSTATE_EMPLOYMENT": "Rivendosni punësimin",
        "OPTION_DELETE_EMPLOYMENT": "Fshini punësimin",
        "TEXT_TERMINATED": "Ky punësim është ndërprerë, duke përfunduar",
        "LABEL_REASON": "Arsyeja",
        "LABEL_REHIRE": "Ripunësoni",
        "LABEL_REHIRE_YES": "po",
        "LABEL_REHIRE_NO": "Jo",
        "BUTTON_ATTEST": "Konfirmo",
        "TEXT_ALLOWANCES_NOT_FINALISED": "Ju ende nuk keni konfirmuar se shpenzimet e mungesës së punonjësve janë finalizuar.",
        "TEXT_ALLOWANCES_NOT_FINALISED_PART_2": "Kjo zakonisht bëhet në ose para ditës së fundit të punonjësit, para përfundimit të pagës së tyre.",
        "TEXT_ALLOWANCES_NOT_FINALISED_LINK": "Shikoni shtesat",
        "BUTTON_UNDO": "Çaktivizo",
        "TEXT_ALLOWANCES_FINALISED": "Shpenzimet e mungesës janë konfirmuar si të finalizuara për këtë punësim.",
        "LABEL_JOIN_DATE": "Data e bashkimit",
        "LABEL_NOTICE_PERIOD": "Periudha e njoftimit",
        "BUTTON_SET_NOTICE_PERIOD": "Vendosni një periudhë njoftimi...",
        "LABEL_EMPLOYEE_NUMBER": "Numri i punëtorëve",
        "TOOLTIP_EMPLOYEE_NUMBER": "Opsionale. Në Mbretërinë e Bashkuar, HMRC kërkon një numër unik për secilin punonjës. Ne do t'i dërgojmë këtë ofruesit tuaj të pagave nëse aktivizohet një integrim i pagave.",
        "LABEL_RECRUITMENT_SOURCE": "Burimi i rekrutimit",
        "LABEL_STARTER_DECLARATION": "Deklarata fillestare (UK)",
        "BUTTON_SAVE": "Ruaj",
        "BUTTON_SAVE_LOADING": "Kursimi...",
        "BUTTON_NEW_APPOINTMENT": "Emërim i ri",
        "TITLE_APPOINTMENTS": "Emërimet",
        "COLUMN_EFFECTIVE_DATE": "Data e fuqisë",
        "COLUMN_SITE": "Faqja",
        "COLUMN_DEPARTMENT": "ministri",
        "COLUMN_POSITION": "Roli i punës",
        "COLUMN_LINE_MANAGER": "Menaxher i linjës",
        "COLUMN_STAFF_GROUP": "Grupi i stafit",
        "COLUMN_OBLIGATION": "Detyrimi",
        "TOOLTIP_ACTIVE_APPOINTMENT": "Ky është emërimi aktiv.",
        "LINE_MANAGER_NOT_SET": "Nuk është vendosur",
        "MIN_OBLIGATION_ZERO_HOURS": "Zero orë",
        "ERROR_500": "Ne nuk mund të ngarkojmë punësime tani. Ju lutemi provoni përsëri.",
        "ERROR_403": "Ju nuk keni leje për të shikuar/modifikuar të dhënat e punësimit të këtij personi.",
        "LABEL_HOURS": "orë",
        "LABEL_DAYS": "ditë",
        "LABEL_WEEK": "javë",
        "LABEL_MONTH": "muaj",
        "LABEL_CURRENT_EMPLOYMENT": "Punësimi aktual",
        "ALERT_CHANGES_SAVED": "Ndryshimet tuaja janë ruajtur.",
        "CONFIRM_DELETE": "A jeni i sigurt që dëshironi ta bëni këtë? Fshirja e një punësimi është e pakthyeshme.",
        "CONFIRM_REINSTATE": "A jeni i sigurt që dëshironi ta bëni këtë?\n\nNëse dikush po bashkohet me organizatën tuaj, duhet të krijoni një punësim të ri në vend të kësaj. Ju duhet të rivendosni një punësim vetëm nëse është përfunduar gabimisht.",
        "VALIDATION_MODAL": {
          "TITLE": "Nuk mund ta bëjmë këtë ndryshim",
          "DESCRIPTION": "Fatkeqësisht ajo që po përpiqeni të bëni shkakton një ose më shumë probleme.",
          "NO_EMPLOYMENT_TITLE": "Nuk ka punësim",
          "NO_EMPLOYMENT_DESCRIPTION": "Ndryshimi juaj do ta lërë punonjësin pa punësim; gjithmonë duhet të ketë të paktën një.",
          "EMPLOYMENT_OVERLAPPING_TITLE": "Punësimet mbivendosen",
          "EMPLOYMENT_OVERLAPPING_DESCRIPTION": "Ndryshimi juaj do të shkaktojë që dy punësime të mbivendosen:",
          "EMPLOYMENT_NOT_TERMINATED_TITLE": "Punësimi nuk përfundohet",
          "EMPLOYMENT_NOT_TERMINATED_DESCRIPTION": "Ju duhet t'i jepni fund punësimit të mëparshëm para se të shtoni një të ri.",
          "EMPLOYMENT_NO_APPOINTMENTS_TITLE": "Punësim pa emërime",
          "EMPLOYMENT_NO_APPOINTMENTS_DESCRIPTION": "Një punësim nuk mund të jetë bosh, duhet të përmbajë të paktën një takim.",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_TITLE": "Emërimi jashtë punësimit",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Ndryshimi juaj do të bëjë që një pjesë e këtij emërimi të bjerë jashtë punësimit.",
          "APPOINTMENT_OVERLAPPING_TITLE": "Takimet mbivendosen",
          "APPOINTMENT_OVERLAPPING_TITLE_DESCRIPTION": "Ndryshimi juaj do të bëjë që dy takime të mbivendosen:",
          "APPOINTMENT_NO_EMPLOYMENT_TITLE": "Takim pa punësim",
          "APPOINTMENT_NO_EMPLOYMENT_DESCRIPTION": "Një takim nuk lidhet me një punësim. Kjo nuk duhet të ndodhë normalisht!",
          "SHIFTS_OUTSIDE_EMPLOYMENT_TITLE": "Ndërrimet jashtë punësimit",
          "SHIFTS_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Ndryshimi juaj do të linte ndryshimet {{count}} në datat që bien para fillimit të punësimit ose pas përfundimit të punës. Për të vazhduar, këto ndërrime mund të fshihen, të pacaktohen (gjë që i zhvendos ato në Open Shifts) ose të modifikohen në vetë rota:",
          "SHIFT_ENTITY_MISMATCH_TITLE": "Ndërrimet në faqe/departament të ndryshëm",
          "SHIFT_ENTITY_MISMATCH_DESCRIPTION": "Ndryshimi juaj do të linte ndryshimet {{count}} që origjinojnë në rotën e një departamenti që nuk përputhet me departamentin në emërimin e punonjësit. Për të vazhduar, këto ndërrime mund të fshihen, të pacaktohen (gjë që i zhvendos ato në Open Shifts) ose të modifikohen në vetë rota:",
          "ABSENCE_OUTSIDE_EMPLOYMENT_TITLE": "Mungesa jashtë punësimit",
          "ABSENCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Ndryshimi juaj do të linte rezervimet e mungesës së {{count}} në datat që bien para fillimit të punësimit ose pas përfundimit të tij. Për të vazhduar, ato duhet të anulohen:",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_TITLE": "Pjesëmarrja jashtë punës",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Ndryshimi juaj do të linte ngjarjen (et) e pjesëmarrjes {{count}} në datat që bien para fillimit të punësimit ose pas përfundimit të tij. Për të vazhduar, ato duhet të fshihen:",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_TITLE": "Ndryshimi i hartimit rutinë jashtë pun",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Ndryshimi juaj do të linte hartat rutinore të {{count}} në datat që bien para fillimit të punësimit ose pas përfundimit të saj. Për të vazhduar, ato duhet të përfundojnë ose të fshihen:",
          "EMAIL_EXISTS_TITLE": "Adresa e emailit tashmë ekziston",
          "EMAIL_EXISTS_DESCRIPTION": "Adresa e postës elektronike që keni futur tashmë është në përdorim nga një punonjës tjetër. Nëse ky person ka punuar për organizatën tuaj më parë, kërkoni për ta dhe shtoni një Punësim të Ri.",
          "MOBILE_EXISTS_TITLE": "Numri i telefonit celular tashmë ekziston",
          "MOBILE_EXISTS_DESCRIPTION": "Numri i telefonit celular që keni futur tashmë është në përdorim nga një punonjës tjetër. Nëse ky person ka punuar për organizatën tuaj më parë, kërkoni për ta dhe shtoni një Punësim të Ri.",
          "INVALID_DATE_TITLE": "Data e pavlefshme",
          "INVALID_DATE_DESCRIPTION": "Nëse jeni duke u përpjekur të mbyllni një punësim, data e përfundimit që keni zgjedhur është para fillimit të punësimit.",
          "PERIOD_SIGNED_OFF_TITLE": "Data e regjistrimit",
          "PERIOD_SIGNED_OFF_DESCRIPTION": "Ndryshimi juaj mbivendoset me një ose më shumë data që janë nënshkruar.",
          "PAY_ELEMENT_INVALID_TITLE": "Element i pavlefshëm i pagës",
          "PAY_ELEMENT_INVALID_DESCRIPTION": "Elementi i zgjedhur i pagesës është i pavlefshëm.",
          "PAY_RECORD_SAME_DATE_TITLE": "Regjistri i pagës së kopjuar",
          "PAY_RECORD_SAME_DATE_DESCRIPTION": "Një regjistër pagash tashmë ekziston për elementin tuaj të zgjedhur të pagës në atë datë.",
          "RECORD_MISSING_TITLE": "Regjistrimi i pagës fshihet",
          "RECORD_MISSING_DESCRIPTION": "Ky regjistër pagash është fshirë dhe nuk mund të modifikohet më.",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_TITLE": "Mospërputhja e kalendarit të pagave",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_DESCRIPTION": "Ndryshimi juaj do të linte rezervimet e mungesës së {{count}} të lidhura me një kalendar tjetër të pagave. Për të vazhduar, ato duhet të anulohen:",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_TITLE": "Lidhur me ndihmën e përfunduar",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_DESCRIPTION": "Ky regjistër i pagave është i lidhur me një ndihmë të finalizuar dhe nuk mund të redaktohet. Për ta fshirë atë, ju lutemi gjeni ndihmën dhe kthejeni atë.",
          "BUTTON_TRY_AGAIN": "Provo përsëri",
          "BUTTON_CLOSE": "Mbyll"
        },
        "LEAVER_FORM": {
          "TITLE": "Përfundoni punësimin",
          "ALERT_SUCCESS": "Punësimi ka përfunduar.",
          "ERROR_500": "Ne nuk mund të ruajmë ndryshimet tuaja vetëm atëherë. Ju lutemi provoni përsëri.",
          "LABEL_END_DATE": "Dita e fundit e punësimit",
          "LABEL_REASON": "Arsyeja e largimit",
          "LABEL_REHIRE": "Ri-punësimi",
          "CHECKBOX_REHIRE": "Ky punonjës konsiderohet i drejtë për ri-punësim në të ardhmen.",
          "LABEL_NOTES": "Shënime",
          "PLACEHOLDER_NOTES": "Regjistroni opsionale disa shënime që lidhen me largimin e këtij punonjësi.",
          "BUTTON_SAVE": "Ruaj",
          "BUTTON_SAVE_LOADING": "Kursimi..."
        },
        "EMPLOYMENT_FORM": {
          "TITLE": "Punësim i ri",
          "RESET_WARNING": "Llogaria e punonjësit do të shënohet si e paaprovuar dhe atribute të ndryshme do të rivendosen, siç janë orët mesatare të punuara në ditë dhe në javë.",
          "LABEL_START_DATE": "Data e fillimit të punësimit",
          "LABEL_NOTICE_PERIOD": "Periudha e njoftimit",
          "LABEL_EMPLOYEE_NUMBER": "Numri i punëtorëve",
          "TOOLTIP_EMPLOYEE_NUMBER": "Opsionale. Në Mbretërinë e Bashkuar, HMRC kërkon një numër unik për secilin punonjës. Ne do t'i dërgojmë këtë ofruesit tuaj të pagave nëse aktivizohet një integrim i pagave.",
          "LABEL_RECRUITMENT_SOURCE": "Burimi i rekrutimit",
          "LABEL_STARTER_DECLARATION": "Deklarata fillestare (UK)",
          "LABEL_INVITE": "Ftoni në Rotaready",
          "CHECKBOX_INVITE": "Dërgoni një lidhje magjike këtij punonjësi, i cili i fton ata të vendosin fjalëkalimin e tyre dhe të identifikohen për të parë ndërrimet e tyre.",
          "BUTTON_ADD": "Shto",
          "BUTTON_ADD_LOADING": "Shtimi i...",
          "ALERT_SUCCESS": "Punësimi është krijuar.",
          "ERROR_500": "Ne nuk mund të ruajmë ndryshimet tuaja vetëm atëherë. Ju lutemi provoni përsëri."
        },
        "APPOINTMENT_FORM": {
          "TITLE": "Emërimi",
          "ALERT_MAPPINGS": "Ju lutemi plotësoni hartimet e parazgjedhura të rrymës ose kontrolloni deklaratën.",
          "ALERT_SAVED": "Takimi është ruajtur.",
          "ALERT_DELETED": "Emërimi është fshirë.",
          "ERROR_500": "Ne nuk mund të ruajmë ndryshimet tuaja vetëm atëherë. Ju lutemi provoni përsëri.",
          "INFO_NEW_APPOINTMENT": "Ne kemi përdorur takimin më të fundit për të para-plotësuar këtë formular për ju.",
          "LABEL_EFFECTIVE_DATE": "Data e fuqisë",
          "HELP_TEXT_EFFECTIVE_DATE": "Meqenëse ky është emërimi i parë, data e fuqisë është e lidhur me Datën e Bashkimit të punësimit.",
          "INFO_NEW_EMPLOYEE": "Për të përfunduar, konfiguroni emërimin e ri të këtij punonjësi duke vendosur faqen/departamentin e tyre, rolin e punës dhe atributet e tjera kryesore.",
          "LABEL_LINE_MANAGER": "Menaxher i linjës",
          "LABEL_LINE_MANAGER_NOT_SET": "Nuk është vendosur",
          "TITLE_JOB_ROLE": "Roli i punës",
          "LABEL_SITE_DEPARTMENT": "Faqit/Departamenti",
          "LABEL_JOB_ROLE": "Roli i punës",
          "LABEL_STAFF_GROUP": "Grupi i stafit",
          "HELP_TEXT_STAFF_GROUP": "Grupet e stafit përdoren për të ndarë vizualisht njerëzit në rota dhe nëpër mjete të tjera.",
          "TITLE_EMPLOYMENT_CONTRACT": "Kontrata e punës",
          "LABEL_COMPANY": "Kompania",
          "LABEL_PAYROLL_CALENDAR": "Kalendari i pagave",
          "LABEL_CONTRACT_TYPE": "Lloji i kontratës",
          "LABEL_PUBLIC_HOLIDAYS": "Pushimet publike",
          "HELP_TEXT_PUBLIC_HOLIDAYS": "Ky punonjës zakonisht punon në festat publike.",
          "LABEL_WORKING_TIME": "Rregulloret e kohës së punës",
          "HELP_TEXT_WORKING_TIME": "Ky punonjës ka hequr dorë nga kufijtë opsionale të punës të rregulloreve të kohës së punës.",
          "TITLE_CONTRACTUAL_OBLIGATION": "Detyrimi kontraktual",
          "LABEL_MIN_OBLIGATION": "Orë/ditë të garantuara",
          "HELP_TEXT_MIN_OBLIGATION": "Këtij punonjësi i garantohet një sasi minimale e punës.",
          "LABEL_MAX_OBLIGATION": "Kufiri i punës",
          "HELP_TEXT_MAX_OBLIGATION": "Zbatoni një kufi të sipërm në sasinë e orëve ose ditëve që ky punonjës mund të planifikohet të punojë.",
          "TITLE_STREAM_MAPPINGS": "Mapjet e parazgjedhura të transmetimit",
          "HELP_TEXT_STREAM_MAPPINGS": "Kur ky punonjës punon në një ndërrim, hartimet e llojit të ndërrimit përcaktojnë rrjedhën (et) për të ndarë koston e tyre të pagës. Nëse lloji i ndërrimit nuk ka asnjë, këto përdoren në vend të kësaj:",
          "COLUMN_STREAM": "Rrjedha",
          "COLUMN_ATTRIBUTION": "Atribuimi",
          "COLUMN_OPTIONS": "Opsionet",
          "OPTION_DELETE": "Fshini",
          "NO_MAPPINGS": "Nuk ka asnjë për të treguar.",
          "LABEL_ADD_MAPPING": "Shto një hartë të re",
          "OPTION_ADD": "Shto",
          "LABEL_NO_MAPPINGS": "Asnjë hartë",
          "CHECKBOX_NO_MAPPINGS": "Unë pranoj që pa hartime të parazgjedhura të rrymës, mund të ketë pasaktësi në Kontrollin e Kostos dhe disa raporte.",
          "HELP_TEXT_NO_MAPPINGS": "Kjo është në rregull nëse nuk do të gjurmoni koston e pagës së këtij punonjësi.",
          "BUTTON_SAVE": "Ruaj",
          "BUTTON_SAVE_LOADING": "Kursimi...",
          "BUTTON_DELETE": "Fshini",
          "BUTTON_DELETE_LOADING": "Fshirja e..."
        }
      },
      "DOCUMENTS": {
        "FILTER_LABEL_CATEGORY": "Kategori",
        "BUTTON_UPLOAD": "Ngarko skedarin",
        "BUTTON_SIGNATURES": "nënshkrimet e dokumenteve",
        "OPTION_SEND_TO_SIGN": "Dërgoni një dokument për të nënshkruar",
        "OPTION_PENDING_SIGNATURES": "Shikoni dokumentet që presin nënshkrimin",
        "ERROR_500": "Nuk mund të ngarkojmë dokumentet tani. Ju lutemi provoni përsëri.",
        "ERROR_403": "Ju nuk keni leje për të shikuar/modifikuar dokumentet e këtij personi.",
        "ERROR_NO_CATEGORIES": "Dokumentet nuk janë të aktivizuara për organizatën tuaj. Bisedoni me ne për të filluar.",
        "TOOLTIP_CATEGORY_NOT_APPLICABLE": "Kategoria e shënuar si nuk kërkohet",
        "TOOLTIP_CATEGORY_INVALID": "Dokumenti mungon ose skadon",
        "INFO_CATEGORY_NOT_APPLICABLE": "Kjo kategori është shënuar si jo e kërkuar për këtë punonjës.",
        "LINK_MARK_CATEGORY_REQUIRED": "Shënoni siç kërkohet",
        "LINK_MARK_CATEGORY_NOT_REQUIRED": "Shënoni si nuk kërkohet",
        "INFO_CATEGORY_NO_DOCUMENTS": "Nuk ka dokumente në këtë kategori.",
        "COLUMN_NAME": "Emri",
        "COLUMN_EXPIRES": "Skadon",
        "COLUMN_FILE_SIZE": "Madhësia e skedarit",
        "COLUMN_UPLOADED_BY": "Ngarkuar nga",
        "COLUMN_OPTIONS": "Opsionet",
        "LINK_DELETE": "Fshini",
        "LINK_EDIT": "Ndrysho",
        "ALERT_CONFIRM_DELETE": "A jeni i sigurt që dëshironi ta fshini këtë dokument? Kjo nuk mund të çaktivizohet!",
        "TOOLTIP_SIGNATURE_ELECTRONIC": "Ky dokument u nënshkrua në mënyrë elektronike përmes Rotaready",
        "TOOLTIP_SIGNATURE_MANUAL": "Ky dokument është nënshkruar manualisht",
        "TOOLTIP_NOTES": "Shënimet janë në dispozicion",
        "PILL_SENSITIVE": "I ndjeshëm",
        "PILL_CAN_EXPIRE": "Mund të skadojë",
        "PILL_VISIBLE_TO_EMPLOYEE": "I dukshëm për punonjësit",
        "TOOLTIP_ACKNOWLEDGED": "Pranohet: {{date}}",
        "PERSONAL": {
          "TITLE": "Dokumentet e mia",
          "ALERT_CONFIRM_ACKNOWLEDGE": "Duke klikuar pranoni ju konfirmoni se e keni lexuar dhe kuptuar dokumentin.",
          "INFO_NO_DOCUMENTS": "Ju nuk keni dokumente për të parë.",
          "LINK_ACKNOWLEDGE": "Pranoni",
          "PILL_NEEDS_ACKNOWLEDGEMENT": "Nevojitet mirënjohje",
          "PILL_ACKNOWLEDGED": "E pranuar",
          "COLUMN_NAME": "Emri",
          "COLUMN_CATEGORY": "Kategori",
          "COLUMN_EXPIRES": "Skadon",
          "COLUMN_FILE_SIZE": "Madhësia e skedarit",
          "COLUMN_OPTIONS": "Opsionet",
          "FILTER_LABEL_CATEGORY": "Kategori"
        }
      },
      "DOCUMENT_EDIT_FORM": {
        "TITLE": "Redakto dokumentin",
        "BUTTON_CANCEL": "Anuloni",
        "BUTTON_SAVE": "Ruaj",
        "LABEL_EXPIRY_DATE": "Data e skadimit",
        "LABEL_NOTES": "Shënime",
        "HELP_TEXT_EXPIRY": "Dokumentit duhet t'i jepet një datë skadimi nëse kategoria kërkon një. Ju do të paralajmëroheni për të ndërmarrë veprime ndërsa data afrohet dhe përsëri nëse ajo zhduket.",
        "ALERT_SUCCESS": "Dokumenti është përditësuar.",
        "ALERT_ERROR_500": "Ka pasur një gabim në përditësimin e dokumentit. Ju lutemi provoni përsëri ose na kontaktoni nëse ky mesazh vazhdon.",
        "ALERT_ERROR_400": "Ka diçka jo të drejtë në lidhje me ndryshimet tuaja."
      },
      "DOCUMENT_UPLOAD_MODAL": {
        "TITLE": "Ngarko një dokument",
        "ALERT_SIGNATURE": "Ne do ta shënojmë këtë dokument si të nënshkruar manualisht.",
        "LABEL_UPLOAD_TO": "Ngarko në",
        "LABEL_CATEGORY": "Kategori",
        "LABEL_EXPIRY_DATE": "Data e skadimit",
        "HELP_TEXT_EXPIRY": "Dokumentit duhet t'i jepet një datë skadimi nëse kategoria kërkon një. Ju do të paralajmëroheni për të ndërmarrë veprime ndërsa data afrohet dhe përsëri nëse ajo zhduket.",
        "LABEL_UPLOAD": "Ngarko",
        "LABEL_NOTES": "Shënime",
        "BUTTON_CANCEL": "Anuloni",
        "BUTTON_SAVE": "Ruaj",
        "ALERT_SUCCESS": "Dokumenti juaj është ngarkuar.",
        "ALERT_ERROR_500": "Ka pasur një gabim në ngarkimin e dokumentit. Ju lutemi provoni përsëri ose na kontaktoni nëse ky mesazh vazhdon.",
        "ALERT_ERROR_400": "Ka diçka jo të drejtë në lidhje me kategorinë tuaj dhe datën e skadimit."
      },
      "DOCUMENT_SIGNATURE_MODAL": {
        "ALERT_PICK_CATEGORY": "Ju lutemi zgjidhni një kategori për të ruajtur dokumentin.",
        "ALERT_SUCCESS_MANUAL": "Dokumenti juaj është shkarkuar.",
        "ALERT_SUCCESS_ELECTRONIC": "Dokumenti juaj është dërguar për nënshkrim elektronik.",
        "ALERT_ERROR_500": "Ka pasur një gabim në ndërtimin e dokumentit. Ju lutemi provoni përsëri ose na kontaktoni nëse ky mesazh vazhdon.",
        "ALERT_ERROR_400": "Nuk mund të krijojmë dokumentet tuaja. Kontrolloni etiketat tuaja të bashkimit dhe provoni përsëri.",
        "TITLE": "Dërgoni një dokument për të nënshkruar",
        "ALERT_INFO": "Duke vendosur Merge Tags në modelin ose skedarin tuaj, Rotaready mund të futë pjesë kyçe të informacionit në lidhje me punonjësin ku po e dërgoni atë, si emri ose roli i tyre i punës. Kjo është gjithashtu mënyra se si kontrolloni se ku shfaqet kutia e nënshkrimit.",
        "ALERT_INFO_LINK": "Zbuloni se si",
        "LABEL_SEND_TO": "Dërgo në",
        "LABEL_DOCUMENT": "Dokumenti",
        "LABEL_DOCUMENT_TEMPLATE": "Përdorni një model ekzistues",
        "LABEL_DOCUMENT_UPLOAD": "Ngarkoni një skedar të njëhershëm",
        "LABEL_TEMPLATE": "Modeli",
        "ALERT_NO_TEMPLATES_TITLE": "Ju nuk keni asnjë shabllon.",
        "ALERT_NO_TEMPLATES_HELP": "Kurseni kohë, ngarkoni një skedar një herë dhe ripërdorni atë për shumë njerëz.",
        "ALERT_NO_TEMPLATES_BUTTON": "Ngarko një shabllon",
        "LABEL_UPLOAD": "Ngarko",
        "SECTION_SIGNING_INFO": "Informacioni i nënshkrimit",
        "LABEL_TITLE": "Titulli",
        "HELP_TEXT_TITLE": "Jepini këtij dokumenti një titull në mënyrë që personi të cilit po e dërgoni të dijë se çfarë është.",
        "LABEL_EMPLOYER_SIGNS": "Shenjat punëdhënëse",
        "OPTION_EMPLOYER_SIGN_FALSE": "Jo, vetëm punonjësi",
        "OPTION_EMPLOYER_SIGN_TRUE": "Kërkohet një nënshkrim në emër të punëdhënësit",
        "LABEL_EMPLOYER_SIGNATORY": "Punëdhënësi nënshkrues",
        "HELP_TEXT_EMPLOYER_SIGNATORY": "Zgjidhni kush duhet ta nënshkruajë këtë dokument në emër të punëdhënësit.",
        "LABEL_SIGNATURE_METHOD": "Metoda e nënshkrimit",
        "OPTION_SIGNATURE_METHOD_MANUAL": "Manual, me stilolaps dhe letër",
        "OPTION_SIGNATURE_METHOD_ELECTRONIC": "Elektronike, me nënshkrim elektronik plotësisht të ligjshëm",
        "LABEL_EMAIL_MESSAGE": "Mesazh me email",
        "HELP_TEXT_EMAIL_MESSAGE": "Shtoni një mesazh opsional në njoftimin e postës elektronike të dërguar për të gjitha palët nënshkruese.",
        "SECTION_UPON_COMPLETION": "Pas përfundimit",
        "SECTION_METHOD": "Metoda e nënshkrimit",
        "LABEL_SAVE_TO": "Ruaj në",
        "HELP_TEXT_SAVE_TO": "Kur nënshkruhet, skedari do të ruhet në dokumentet e punonjësit nën kategorinë e zgjedhur.",
        "LABEL_EXPIRY_DATE": "Data e skadimit",
        "HELP_TEXT_EXPIRY_DATE": "Dokumentit duhet t'i jepet një datë skadimi nëse kategoria e destinacionit kërkon një. Ju do të paralajmëroheni për të ndërmarrë veprime ndërsa data afrohet dhe përsëri nëse ajo zhduket.",
        "CREDITS_DEPLETED_TEXT": "Ju duhen kredite për të dërguar këtë dokument për nënshkrim elektronik.",
        "CREDITS_DEPLETED_CTA": "Bisedoni me ne tani",
        "CREDITS_AVAILABLE_TEXT": "Ju keni <b>kredi {{balance}}</b> të mbetura. Dërgimi i këtij dokumenti do të përdorë një kredi.",
        "CREDITS_AVAILABLE_CTA": "Është e lehtë të plotësoni kreditë tuaja. Thjesht na dërgoni një mesazh në chat live.",
        "BUTTON_CANCEL": "Anuloni",
        "BUTTON_DOWNLOAD_PREVIEW": "Shkarkoni pamjen paraprake",
        "BUTTON_SAVE_DOWNLOAD": "Ruaj dhe shkarko",
        "BUTTON_SEND": "Dërgo"
      },
      "NOTES": {
        "ERROR_500": "Ne nuk mund të ngarkojmë shënime tani. Ju lutemi provoni përsëri.",
        "ALERT_CONFIRM_DELETE": "A jeni i sigurt që dëshironi ta fshini këtë shënim?",
        "BODY_PLACEHOLDER": "Shkruani një shënim të ri...",
        "SAVE_BUTTON_TEXT": "Shto shënim",
        "VISIBILITY_SELF": "Private",
        "VISIBILITY_SELF_HELP": "Vetëm e dukshme nga ju",
        "VISIBILITY_SENSITIVE": "I ndjeshëm",
        "VISIBILITY_SENSITIVE_HELP": "I dukshëm vetëm nga njerëzit me leje për të parë shënime të ndjeshme",
        "VISIBILITY_GENERAL": "i gjithanshëm",
        "VISIBILITY_GENERAL_HELP": "I dukshëm vetëm nga njerëzit me leje për të parë shënimet e këtij punonjësi",
        "EDITED_LABEL": "Redaktuar",
        "EDIT_LABEL": "Ndrysho",
        "DELETE_LABEL": "Fshini",
        "NO_NOTES": "Nuk ka shënim për të treguar."
      },
      "DANGER_ZONE": {
        "ANONYMISE": {
          "TITLE": "Anonimizoni punonjësit",
          "ERROR_500": "Nuk mund të shpëtojmë vetëm atëherë. Ju lutemi provoni përsëri.",
          "ERROR_403": "Ju nuk keni leje për të hyrë në detajet personale të këtij punonjësi. Kjo do të thotë që ju nuk jeni në gjendje të planifikoni profilin e tyre që të jetë anonimizuar.",
          "LABEL_END_DATE": "Data e fundit të punësimit",
          "HELP_TEXT": "Për të ndihmuar në përputhjen me ligjet e mbrojtjes së të dhënave, Rotaready mund të heqë të gjitha informacionet personale nga ky profil i punonjësve pas një periudhe të zgjedhur kohe. Në Mbretërinë e Bashkuar, HMRC kërkon që regjistrat të mbahen për një minimum prej gjashtë vjetësh.",
          "HELP_TEXT_LEARN": "Mësoni më shumë",
          "LABEL_ANONYMISE": "Anonimizoni",
          "CHECKBOX_ANONYMISE": "Anonimizoni këtë punonjës",
          "LABEL_ANONYMISE_DATE": "Anonimizoni në",
          "ALERT_ANONYMISE_DATE": "Për të anonimizuar këtë punonjës, ju lutemi zgjidhni një datë që është si në të ardhmen ashtu edhe pas ditës së fundit të punonjësit në punë.",
          "BUTTON_SAVE": "Ruaj"
        }
      }
    },
    "ABSENCE": {
      "ABSENCE": {
        "TITLE": "Kalendari i mungesës",
        "FILTER_LABEL_APPROVAL_STATE": "Statusi",
        "FILTER_LABEL_POSITION": "Roli i punës",
        "FILTER_LABEL_STAFF_GROUP": "Grupi i stafit",
        "ERROR_500": "Nuk mund të ngarkojmë mungesën vetëm atëherë. Ju lutemi provoni përsëri.",
        "FILTER_LABEL_ABSENCE_TYPE": "Lloji",
        "FILTER_LABEL_REQUESTER": "Kërkuesi"
      }
    },
    "REQUESTS": {
      "ABSENCE": {
        "DATES_COLUMN_TEXT": "Data (et)",
        "WHO_COLUMN_TEXT": "Kush",
        "ABSENCE_TYPE_COLUMN_TEXT": "Lloji",
        "SUBMITTED_DATE_TEXT": "Paraqitur",
        "HOURS_COLUMN_TEXT": "Orë",
        "DAYS_COLUMN_TEXT": "Ditët",
        "NO_STAFF_MATCH_TEXT": "Ne nuk mund të gjetëm asnjë kërkesë që përputhet me filtrat tuaj.",
        "FILTER_LABEL_POSITION": "Roli i punës",
        "FILTER_LABEL_STAFF_GROUP": "Grupi i stafit",
        "ERROR_500": "Nuk mund të ngarkojmë mungesën vetëm atëherë. Ju lutemi provoni përsëri.",
        "FILTER_LABEL_ABSENCE_TYPE": "Lloji",
        "FILTER_LABEL_REQUESTER": "Kërkuesi"
      },
      "CONFIRM_SWAP_APPROVAL_MODAL": {
        "TITLE": "A jeni i sigurt?",
        "VALIDATION_MANUAL_TEXT": "Për të hequr këtë shkëmbim të ndryshimit, do të duhet të redaktoni manualisht rotën.",
        "YES_TITLE": "po",
        "APPROVE_BUTTON_TEXT": "Miratoni këtë shkëmbim tani dhe njoftoni të dy anëtarët e stafit",
        "NO_TITLE": "Jo",
        "DO_NOTHING_BUTTON_TEXT": "Nuk bëni asgjë - mos e miratoni këtë shkëmbim akoma",
        "CANCEL_BUTTON_TEXT": "Anuloni"
      },
      "INDEX": {
        "HEADING": "Artikujt që kërkojnë vëmendje",
        "ABSENCE_LABEL": "kërkesat për mungesë",
        "SWAPS_LABEL": "Shkëmbimet e ndryshimeve",
        "DOCUMENTS_EXPIRING_LABEL": "Dokumentet që skadojnë",
        "ACCOUNT_APPROVAL_LABEL": "Bashkëpunëtorë të rinj"
      },
      "SWAPS": {
        "INITIATED_BY_TEXT": "Iniciuar nga",
        "INITIATED_TOOLTIP": "Anëtari i stafit që dëshiron të largojë zhvendosjen e tyre",
        "SHIFT_COLUMN_TEXT": "Ndërrimi i tyre",
        "SWAPPING_WITH_COLUMN_TEXT": "Shkëmbimi me",
        "SWAPPING_TOOLTIP": "Anëtari i stafit që dëshiron të punojë në ndërrim",
        "RETURN_SHIFT_COLUMN_TEXT": "Ndërrimi i tyre",
        "OPTION_COLUMN_TEXT": "Opsionet",
        "ACCEPTED_DATE_COLUMN_TEXT": "Duke pritur që nga",
        "NONE_TEXT": "Asnjë",
        "NO_STAFF_MATCH_TEXT": "Ne nuk mund të gjetëm asnjë kërkesë që përputhet me filtrat tuaj.",
        "FILTER_LABEL_PARTICIPANT": "Pjesëmarrës"
      },
      "DOCUMENTS": {
        "NAME_COLUMN_LABEL": "Emri",
        "FILE_NAME_COLUMN_LABEL": "Dokumenti",
        "FILE_SIZE_COLUMN_LABEL": "Madhësia",
        "CATEGORY_COLUMN_LABEL": "Kategori",
        "EXPIRY_DATE_COLUMN_LABEL": "Skadon",
        "OPTIONS_COLUMN_LABEL": "Opsionet",
        "EXPIRED": "I skaduar",
        "UPLOAD_NEW_LINK": "Ngarko të ri",
        "NO_DOCS_MATCH_TEXT": "Nuk mund të gjetëm asnjë dokument që përputhet me filtrat tuaj.",
        "NO_CATEGORIES_TEXT": "Ju nuk keni asnjë kategori për dokumente që mund të skadojnë.",
        "FILTER_LABEL_SITE": "Faqit/Departamenti",
        "FILTER_LABEL_APPOINTMENT": "Emërimi",
        "FILTER_LABEL_PERSON": "Person",
        "FILTER_LABEL_CATEGORY": "Kategori",
        "FILTER_LABEL_EXPIRY": "skadimi",
        "APPOINTMENT_STATUS_ACTIVE": "Aktiv",
        "APPOINTMENT_STATUS_INACTIVE": "joaktiv",
        "X_WEEKS_OR_LESS": " javë ose më pak"
      },
      "NEW_JOINERS": {
        "NAME_COLUMN_LABEL": "Emri",
        "POSITION_COLUMN_LABEL": "Roli i punës",
        "ENTITY_COLUMN_LABEL": "Faqja",
        "START_DATE_COLUMN_LABEL": "Fillon në",
        "OPTIONS_COLUMN_LABEL": "Opsionet",
        "NO_STAFF_MATCH_TEXT": "Nuk mund të gjejmë askënd që përputhet me filtrat tuaj.",
        "APPROVE_LINK": "Miratoni",
        "FILTER_LABEL_APPOINTMENT": "Emërimi",
        "FILTER_LABEL_POSITION": "Roli i punës",
        "FILTER_LABEL_DATE_START": "Data e fillimit",
        "APPOINTMENT_STATUS_ACTIVE": "Aktiv",
        "APPOINTMENT_STATUS_INACTIVE": "joaktiv"
      }
    },
    "TAGS_GROUPS": {
      "TAGS_GROUPS": {
        "HEADING": "Etiketat dhe grupet",
        "ASIDE_TEXT": "Një anëtar i stafit mund t'i përkasë vetëm një grupi, por atyre mund t'u caktohen shumë etiketa. Grupet janë të ngjashme me departamentin ose ekipin e dikujt, ndërsa etiketat mund të përfaqësojnë gjithçka që ju gjeni kuptimplotë, si aftësitë ose cilësi personale.",
        "CREATE_NEW_TAG_PLACEHOLDER": "Krijo etiketë të re...",
        "ADDING_BUTTON_TEXT": "Shtimi i...",
        "ADD_BUTTON_TEXT": "Shto",
        "TAG_HELP": "Klikoni mbi një etiketë për ta modifikuar atë dhe për të parë se kush është caktuar:",
        "CREATE_ONE_TEXT": "Aktualisht nuk keni etiketa. Pse të mos krijojmë një?",
        "CREATE_NEW_GROUP_PLACEHOLDER": "Krijoni një grup të ri...",
        "GROUPS_TITLE": "Grupet",
        "EDIT_LINK_TEXT": "redaktoj"
      },
      "VIEW_GROUP_MODAL": {
        "EDIT_GROUP_TITLE": "Redakto grupin",
        "SITE_LABEL": "Faqja",
        "EDIT_TAB_HEADING": "Ndrysho",
        "ORG_WIDE_LABEL": "Në të gjithë organizatën",
        "GROUP_NAME_LABEL": "Emri i grupit",
        "AVAILABILITY_LABEL": "disponueshmëria",
        "ALWAYS_AVAILABLE_TEXT": " Gjithmonë në dispozicion",
        "ALWAYS_AVAILABLE_HELP": "Stafi në këtë grup konsiderohet gjithmonë i disponueshëm për punë, përveç kur modelet e disponueshmërisë ose mungesa e tyre diktojnë ndryshe.",
        "ALWAYS_UNAVAILABLE_TEXT": " Gjithmonë në dispozicion",
        "ALWAYS_UNAVAILABLE_HELP": "Stafi në këtë grup është si parazgjedhje nuk është i disponueshëm. Ata do të përdorin 'përjashtime' dhe 'disponueshmërinë e një javë' për të shënuar kur janë falas.",
        "MEMBERS_TAB_HEADING": "Anëtarët",
        "NO_ACTIVE_MEMBERS_HELP": "Nuk ka anëtarë aktivë në këtë grup. <br /><br />Stafi është caktuar në një grup përmes emërimit të tyre. Gjeni dikë në listën e stafit, klikoni skedën Emërime dhe redaktoni një takim ekzistues ose shtoni një të ri.",
        "DELETE_TAB_HEADING": "Fshini",
        "MOVE_MEMBERS_TO_LABEL": "Zhvendosni anëtarët në",
        "DELETE_TAB_ALERT": "Fshirja e këtij grupi do të bëjë që njerëzit {{totalMembers}} të kalojnë në një grup tjetër. Kjo është e pakthyeshme dhe nuk mund të zhvendoset.",
        "CONFIRM_NAME_LABEL": "Lloji emrin e grupit",
        "CONFIRM_NAME_HELP_TEXT": "Për të na bindur se dëshironi ta bëni këtë, ju lutemi shkruani emrin e grupit në kutinë.",
        "DELETING_BUTTON_TEXT": "Fshirja e...",
        "DELETE_BUTTON_TEXT": "Fshini",
        "SAVING_BUTTON_TEXT": "Kursimi...",
        "SAVE_BUTTON_TEXT": "Ruaj",
        "CANCEL_BUTTON_TEXT": "Anuloni"
      },
      "VIEW_TAG_MODAL": {
        "EDIT_TAG_TITLE": "Redakto etiketën",
        "EDIT_CLONE_LABEL": "Redakto/klononi",
        "EDIT_EXISTING_TAG_TEXT": " Redakto etiketën ekzistuese",
        "CLONE_TAG_TEXT": " Klononi etiketën dhe ruani si të re",
        "TAG_NAME_LABEL": "Emri i etiketës",
        "STAFF_MEMBERS_LABEL": "Anëtarët e stafit",
        "TAG_TYPEAHEAD_PLACEHLDER": "Lloji për të etiketuar stafin",
        "LAST_EDITED_LABEL": "Redaktuar për herë të fundit",
        "ON_TEXT": "në {{datë|momentFormat: 'llll'}}",
        "DELETING_BUTTON_TEXT": "Fshirja e...",
        "DELETE_BUTTON_TEXT": "Fshini",
        "SAVING_BUTTON_TEXT": "Kursimi...",
        "SAVE_BUTTON_TEXT": "Ruaj",
        "CLONING_BUTTON_TEXT": "Klonimi...",
        "CLONE_BUTTON_TEXT": "Klon",
        "CANCEL_BUTTON_TEXT": "Anuloni",
        "CLOSE_BUTTON_TEXT": "Mbyll"
      }
    },
    "ADD_NEW": {
      "TITLE": "Shto një punonjës",
      "LABEL_FIRST_NAME": "Emri i parë",
      "LABEL_LAST_NAME": "Mbiemri",
      "LABEL_DOB": "Data e lindjes",
      "LABEL_EMAIL": "Adresa e postës elektronike",
      "HELP_TEXT_EMAIL_OIDC": "Kjo duhet të përputhet saktësisht me atë që punonjësi përdor për shërbimin tuaj të Identifikimit të Vetëm. Zakonisht përfundon me emrin e domainit të kompanisë suaj.",
      "HELP_TEXT_EMAIL_ROTAREADY": "Gjithkush ka një llogari në Rotaready dhe përdor adresën e tyre të postës elektronike për t'u identifikuar.",
      "LABEL_COPY_EXISTING": "Kopjoni një punonjës ekzistues",
      "PLACEHOLDER_COPY_EXISTING_NO_THANKS": "Jo faleminderit",
      "HELP_TEXT_COPY_EXISTING": "Kopjoni emërimin, ndihmën e pushimeve dhe etiketat nga një punonjës tjetër.",
      "LABEL_DATE_START": "Data e fillimit të punësimit",
      "LABEL_SITE": "Faqit/Departamenti",
      "LABEL_PERMISSIONS": "Niveli i lejes",
      "LABEL_INVITE": "Ftoni në Rotaready",
      "HELP_TEXT_INVITE_OIDC": "Dërgoni një lidhje magjike këtij punonjësi, i cili i fton ata të identifikohen për herë të parë.",
      "HELP_TEXT_INVITE_ROTAREADY": "Dërgoni një lidhje magjike këtij punonjësi, i cili i fton ata të vendosin fjalëkalimin e tyre dhe të identifikohen për të parë ndërrimet e tyre.",
      "LABEL_ONBOARDING": "Vendosja",
      "CHECKBOX_ONBOARDING_OPT_IN": "Kërkoni që ky punonjës të përfundojë vetë-angazhimin kur të identifikohen për herë të parë në Rotaready. Në varësi të cilësimeve të organizatës suaj, atyre do t'u kërkohet të japin informacione personale si adresa e tyre, kontaktet e emergjencës dhe dokumentacioni i drejtës për të punuar.",
      "BUTTON_ADD": "Shto",
      "BUTTON_ADD_LOADING": "Shtimi i...",
      "ALERT_SUCCESS": "Suksesi! Përfundoni konfigurimin e punonjësit tuaj duke plotësuar secilën skedë.",
      "ALERT_500": "Nuk mund ta shtojmë punonjësin vetëm atëherë. Ju lutemi provoni përsëri.",
      "ALERT_403": "Ju nuk keni leje për të shtuar një punonjës të ri.",
      "ALERT_403_PERMISSION_LEVEL": "Ju nuk keni leje për të shtuar dikë në këtë nivel leje.",
      "ALERT_403_COPY_EXISTING": "Ju nuk keni leje për të kopjuar këtë punonjës.",
      "ALERT_403_ENTITY": "Ju nuk keni leje për të shtuar dikë në këtë sajt/departament.",
      "DUPLICATE_MODAL": {
        "TITLE": "Punëtorët ekzistues",
        "DESCRIPTION": "Personi që po përpiqeni të shtoni ndan disa atribute me një ose më shumë punonjës ekzistues. Nëse ky person ka punuar për organizatën tuaj më parë, shtoni një Punësim të Ri.",
        "COLUMN_NAME": "Emri",
        "COLUMN_EMPLOYMENT": "Punësimi",
        "COLUMN_SITE": "Faqit/Departamenti",
        "COLUMN_SIMILARITIES": "ngjashmëritë",
        "COLUMN_OPTIONS": "Opsionet",
        "STATUS_ACTIVE": "Aktiv",
        "STATUS_INACTIVE": "joaktiv",
        "OPTION_VIEW": "Shiko punonjësit",
        "OPTION_NEW_EMPLOYMENT": "Punësim i ri",
        "BUTTON_PROCEED": "Vazhdoni gjithsesi",
        "BUTTON_BACK": "Kthehu",
        "SIMILARITY_NAME": "Emri",
        "SIMILARITY_EMAIL": "Email",
        "SIMILARITY_MOBILE": "Numri i telefonit celular",
        "SIMILARITY_DOB": "Data e lindjes"
      }
    }
  },
  "DIRECTIVES": {
    "STAFF": {
      "SEARCH": {
        "PLACEHOLDER": "Kërko...",
        "AND_X_MORE": "Dhe {{x}} më shumë...",
        "INACTIVE_APPOINTMENT": "joaktiv"
      },
      "ADD_ROUTINE_MAPPING_FORM": {
        "STARTS_LABEL": "Fillon",
        "STARTS_TEXT": "Dita e parë e {{firstName}} në këtë rutinë",
        "ENDS_INPUT_TEXT": " Përfundon",
        "ENDS_TEXT": "Dita e fundit e {{firstName}} në këtë rutinë",
        "ONGOING_TEXT": "Në vazhdim",
        "SHIFT_ROUTINE_LABEL": "Rutina e ndërrimit",
        "OFFSET_LABEL": "kompensim",
        "OFFSET_HELP": "Ky është numri i ditëve që {{firstName}} duhet të fillojnë në këtë model."
      },
      "AVAILABILITY_RULE": {
        "IM_UNAVAILABLE_TEXT": "Do të jem i padisponueshëm",
        "CANCEL_TEXT": "anuloj",
        "AVAILABLE_TEXT": "NË DISPOZICION",
        "UNAVAILABLE_TEXT": "I PADISPONUESHËM",
        "AVAILABLE_FROM_TEXT": "Unë jam në dispozicion vetëm nga ",
        "UNAVAILABLE_FROM_TEXT": "Unë nuk jam i disponueshëm nga ",
        "TO_TEXT": " të ",
        "ALL_DAY": "Gjithë ditën",
        "AM_ONLY": "Vetëm AM",
        "PM_ONLY": "Vetëm PM",
        "AVAIALABLE_CUSTOM": "Në dispozicion (me porosi)",
        "UNAVAILABLE_CUSTOM": "E padisponueshme (me porosi)",
        "UNAVAILABLE": "I padisponueshëm",
        "AVAILABLE": "Në dispozicion",
        "AVAILABLE_AM": "Në dispozicion (vetëm AM)",
        "AVAILABLE_PM": "Në dispozicion (vetëm PM)"
      },
      "AUDIT_USER_WIDGET": {
        "EDITED_BY": "Redaktuar nga"
      },
      "VALIDATION_BULK_ABSENCE_EDIT": {
        "INFO_TRUNCATED": "Për shkak të vëllimit të rezervimeve të mungesës së prekur, ato nuk mund të redaktohen me shumicë këtu. Ju lutemi përdorni skedën Mungesa në profilin e punonjësit ose kontaktoni Rotaready për ndihmë të mëtejshme.",
        "INFO_SUCCESS": "Nuk ka mbetur rezervime kundërshtuese të mungesës.",
        "ERROR_500": "Vetëm atëherë kemi goditur një gabim të papritur. Ju lutemi provoni përsëri.",
        "ERROR_400": "Ky ndryshim nuk mund të bëhet sepse mbivendoset me një datë që është nënshkruar.",
        "COLUMN_DATES": "Data (et)",
        "COLUMN_TYPE": "Lloji",
        "COLUMN_HOURS": "Orë",
        "COLUMN_DAYS": "Ditët",
        "COLUMN_STATUS": "Statusi",
        "COLUMN_OPTIONS": "Opsionet",
        "OPTION_CANCEL": "Anuloni"
      },
      "VALIDATION_BULK_ATTENDANCE_EDIT": {
        "INFO_TRUNCATED": "Për shkak të vëllimit të ngjarjeve të pjesëmarrjes së prekur, ato nuk mund të redaktohen me shumicë këtu. Ju lutemi përdorni skedën Pjesëmarrja në profilin e punonjësit ose kontaktoni Rotaready për ndihmë të mëtejshme.",
        "INFO_SUCCESS": "Nuk ka mbetur ngjarje kontradiktore të pjesëmarrjes.",
        "ERROR_500": "Vetëm atëherë kemi goditur një gabim të papritur. Ju lutemi provoni përsëri.",
        "ERROR_400": "Ky ndryshim nuk mund të bëhet sepse përkon me një datë që është nënshkruar.",
        "COLUMN_EVENT": "Ngjarje",
        "COLUMN_DATE": "datë",
        "COLUMN_OPTIONS": "Opsionet",
        "EVENT_TYPE_CLOCK_IN": "Hyrja e orës",
        "OPTION_DELETE": "Fshini"
      },
      "VALIDATION_BULK_ROUTINE_MAPPING_EDIT": {
        "INFO_TRUNCATED": "Për shkak të vëllimit të hartimeve rutinë të ndërrimit të prekur, ato nuk mund të redaktohen me shumicë këtu. Ju lutemi përdorni skedën Shift Rutines në profilin e punonjësit ose kontaktoni Rotaready për ndihmë të mëtejshme.",
        "INFO_SUCCESS": "Nuk ka mbetur hartimet rutinore të ndryshimit konfliktues.",
        "ERROR_500": "Vetëm atëherë kemi goditur një gabim të papritur. Ju lutemi provoni përsëri.",
        "ERROR_400": "Ky ndryshim nuk mund të bëhet sepse mbivendoset me një datë që është nënshkruar.",
        "COLUMN_START_DATE": "Data e fillimit",
        "COLUMN_END_DATE": "Data e përfundimit",
        "COLUMN_SHIFT_ROUTINE": "Rutina e ndërrimit",
        "COLUMN_OPTIONS": "Opsionet",
        "LABEL_OPEN_ENDED": "I hapur",
        "OPTION_ADD_END_DATE": "Shto datën e përfundimit",
        "OPTION_DELETE": "Fshini"
      },
      "VALIDATION_BULK_SHIFT_EDIT": {
        "INFO_TRUNCATED": "Për shkak të vëllimit të ndërrimeve të prekura, ato nuk mund të redaktohen me shumicë këtu. Ju lutemi ndryshoni rotat përkatëse ose kontaktoni Rotaready për ndihmë të mëtejshme.",
        "INFO_SUCCESS": "Nuk mbeten asnjë ndryshim konfliktues.",
        "ERROR_500": "Vetëm atëherë kemi goditur një gabim të papritur. Ju lutemi provoni përsëri.",
        "ERROR_400": "Ky ndryshim nuk mund të bëhet. Mund të mbivendoset me një datë që është nënshkruar. Për të mësuar më shumë, provoni këtë në vetë rota.",
        "BUTTON_UNASSIGN_SELECTED": "Un-cakto përzgjedhur",
        "BUTTON_DELETE_SELECTED": "Fshini zgjedhjen",
        "COLUMN_DATE": "datë",
        "COLUMN_TIME": "Koha",
        "COLUMN_DEPARTMENT": "ministri",
        "COLUMN_TYPE": "Lloji",
        "COLUMN_STATUS": "Statusi",
        "COLUMN_OPTIONS": "Opsionet",
        "STATUS_PUBLISHED": "Publikuar",
        "STATUS_DRAFT": "Draft",
        "OPTION_UNASSIGN": "Njo-caktoni",
        "OPTION_DELETE": "Fshini"
      },
      "VALIDATION_EPHEMERAL_RECORDS": {
        "LABEL_OPEN_ENDED": "(i hapur)",
        "LABEL_NEW": "e re",
        "LABEL_EXISTING": "Ekzistues",
        "EMPLOYMENT": "Punësimi",
        "APPOINTMENT": "Emërimi"
      },
      "ACCOUNT_COMPLETION_TRACKER": {
        "CONSTITUENT_PERSONAL": "Personal",
        "CONSTITUENT_EMPLOYMENT": "Punësimi",
        "CONSTITUENT_PAY": "Paguaj",
        "CONSTITUENT_HOLIDAY_ALLOWANCE": "Shtesë për pushime"
      }
    },
    "CALENDAR": {
      "TIP": "Për të kërkuar mungesë thjesht klikoni në një ditë, ose klikoni dhe tërhiqni për të zgjedhur disa ditë në të njëjtën kohë.",
      "TODAY": "Sot",
      "MONTH": "Muaji",
      "WEEK": "javë",
      "DAY": "Dita"
    },
    "HIERARCHY_ITEM_PICKER": {
      "PICKER": "Përzgjedhës",
      "PLACEHOLDER": "Asnjë {{ItemDescriptor}} nuk është zgjedhur",
      "FILTER_TO": "Filtro në...",
      "RESET": "Rivendosni",
      "REMOVED_BY_FILTER": "{{ItemDescriptor}} i zgjedhur është hequr nga filtri.",
      "NO_SELECTED": "Asnjë {{ItemDescriptor}} nuk është zgjedhur"
    },
    "SUBSCRIBE_BUTTON": {
      "LABEL_ETID_1": "kërkuar",
      "LABEL_ETID_2": "anuluar",
      "LABEL_ETID_3": "i miratuar",
      "LABEL_ETID_4": "refuzuar",
      "LABEL_ETID_5": "shënuar për miratim",
      "LABEL_ETID_6": "botuar",
      "LABEL_ETID_7": "kthehet në draft",
      "LABEL_ETID_8": "Java është regjistruar",
      "LABEL_ETID_9": "në pritje të miratimit",
      "LABEL_ETID_10": "i miratuar",
      "LABEL_ETID_11": "kërkuar",
      "LABEL_ETID_13": "i miratuar",
      "LABEL_ETID_14": "refuzuar",
      "LABEL_ETID_15": "anuluar",
      "LABEL_ETID_16": "po afrohet skadimit",
      "LABEL_ETID_17": "skaduar",
      "LABEL_ETID_29": "nisa",
      "LABEL_ETID_30": "pranuar nga dikush",
      "LABEL_ETID_31": "refuzuar nga dikush",
      "LABEL_ETID_32": "i përfunduar",
      "LABEL_ETID_36": "Java është e kthyer",
      "LABEL_ETID_37": "Dita është regjistruar",
      "LABEL_ETID_38": "Dita është e kthyer",
      "DESCRIPTOR_ABSENCE": "Një kërkesë për mungesë është",
      "DESCRIPTOR_ROTA": "një rota është",
      "DESCRIPTOR_ACCOUNT": "Një punonjës është",
      "DESCRIPTOR_SWAP": "një shkëmbim i ndërrimit është",
      "DESCRIPTOR_COST_CONTROL": "Kontrolli i kostos",
      "DESCRIPTOR_DOCUMENT": "Një dokument është",
      "DESCRIPTOR_BROADCAST": "Një transmetim i ndryshimit është",
      "NOUN_ABSENCE": "rezervimi i mungesës",
      "NOUN_ROTA": "Rrota",
      "NOUN_ACCOUNT": "punonjës",
      "NOUN_SWAP": "shkëmbimi i ndërrimit",
      "NOUN_COST_CONTROL": "regjistri i kontrollit të kostos",
      "NOUN_DOCUMENT": "dokument",
      "NOUN_BROADCAST": "transmetimi i ndryshimit",
      "NOTIFY_SENTENCE_EXORDIUM": "Më njofto kur",
      "NOTIFY_SENTENCE_TRAILING_IN": "në...",
      "UNSUBSCRIBE": "Çabonimi",
      "SUBSCRIBE": "Regjistrohu",
      "SEARCH_PLACEHOLDER": "Kërko...",
      "BACK_LINK": "Mbrapa",
      "RESOURCE_SUBSCRIBE_TOOLTIP": "Njoftohuni për ndryshimet në këtë {{name}}",
      "RESOURCE_UNSUBSCRIBE_TOOLTIP": "Çabonimi nga ndryshimet në këtë {{name}}"
    },
    "ROTA": {
      "DASHBOARD_WEEK": {
        "VIEW_ROTA": "Shikoni rrotën",
        "LAST_WEEK": "Javën e kaluar",
        "THIS_WEEK": "Këtë javë",
        "NO_ATTENDANCE_ISSUES": "Nuk ka çështje pjesëmarrje",
        "ATTENDANCE_ISSUES": "çështja (e) e pjesëmarrjes",
        "RESOLVE": "Zgjidhja",
        "ACTUAL_SALES_ENTERED": "Shifrat aktuale të shitjeve të futura",
        "ACTUAL_SALES_MISSING": "<strong>Shifrat aktuale të shit</strong> jeve mungojnë",
        "WTD_COMPLIANT": "Në përputhje me rregullat e kohës së punës",
        "VIEW": "Shikoni",
        "WTD_VIOLATIONS": "Shkelje (e) e kohës së punës",
        "SIGN_OFF_NOT_COMPLETED": "<strong>Nënshkrimi</strong> nuk është përfunduar ende",
        "SIGN_OFF": "Fikni",
        "WEEK_SIGNED_OFF": "Java është nënshkruar",
        "ABSENCE_REQUEST": "kërkesat për mungesë",
        "ADDED_SALES_FORECAST": "Shtuar parashikimi i shitjeve",
        "DRAFTED_ROTA": "Rrotulli i hartuar",
        "MARKED_FOR_APPROVAL": "Rota shënuar për miratim",
        "PUBLISHED": "Rrotulli i publikuar",
        "OUTSTANDING": "{{mungesa}} e jashtëzakonshme",
        "NOT_ADDED": "Nuk shtohet",
        "NOT_DRAFTED": "Nuk është hartuar",
        "NOT_DONE": "Nuk është bërë",
        "NOT_PUBLISHED": "Nuk është publikuar"
      }
    },
    "SIDEBAR": {
      "NOTIFICATION_POPOVER": {
        "NO_NOTIFICATIONS": "Asnjë njoftim!",
        "MARK_ALL_READ": "Shënoni të gjitha të lexuar"
      },
      "SETTINGS_POPOVER": {
        "TIMEZONE": "Kjo zonë kohore është e ndryshme nga zona juaj kohore lokale.",
        "EDIT_PREFERENCES": "Ndrysho preferencat",
        "MANAGE_SUBSCRIPTIONS": "Menaxhoni abonimet",
        "SHOW_INTERCOM": "Trego bisedën mbështetëse",
        "HIDE_INTERCOM": "Fshih bisedën e mbështetjes",
        "SIGN_OUT": "Dilni jashtë"
      }
    },
    "DATE_PICKER": {
      "CALENDAR": "Kalendari",
      "GO_TO_TODAY": "Shko sot"
    },
    "ENTITY_SELECTOR": {
      "ORG_WIDE": "Në të gjithë organizatën",
      "NO_OPTIONS": "Nuk ka mundësi",
      "SEARCH_PLACEHOLDER": "Kërko..."
    },
    "FILTER_OPTIONS_BUTTON": {
      "IS": "është",
      "AND": "dhe",
      "OR": "ose",
      "ANY": "Çdo",
      "SEARCH_PLACEHOLDER": "Kërko...",
      "NO_OPTIONS": "Nuk ka mundësi",
      "SELECT_ALL": "Zgjidhni të gjitha",
      "CLEAR_SELECTED": "Pastroni zgjedhjen"
    },
    "FILTER_STAFF_SEARCH_BUTTON": {
      "IS": "është",
      "ANY": "Çdo",
      "RESET": "Rivendosni"
    },
    "FILTER_DATE_BUTTON": {
      "ANY": "asnjë",
      "BEFORE": "para",
      "AFTER": "prapa",
      "BETWEEN": "ndërmjet",
      "IS": "është",
      "AND": "dhe",
      "DONE": "Bërë"
    },
    "FILTER_NUMBER_BUTTON": {
      "ANY": "është ndonjë",
      "GREATER": "është më i madh se",
      "LESS": "është më pak se",
      "EXACTLY": "është saktësisht",
      "DONE": "Bërë"
    },
    "VALIDATION_FAILURE": {
      "TITLE": "Çështjet e validimit",
      "ISSUE": "çështje",
      "ISSUES": "çështjet",
      "CHANGE_MESSAGE": "Ndryshimi që po përpiqeni të bëni ka rezultuar në ",
      "IGNORE_CONTINUE": "Injoroni dhe vazhdoni gjithsesi",
      "IGNORE_PROBLEM": "Injoroni ndryshimet e problemeve dhe provoni përsëri",
      "LOADING": "Ngarkohet..."
    },
    "PICK_LIST": {
      "NO_OPTIONS": "Nuk ka mundësi",
      "SEARCH_PLACEHOLDER": "Kërko...",
      "DEFAULT_NULL_OPTION_LABEL": "Asnjë"
    },
    "FILE_UPLOAD": {
      "ALERT_UNSUPPORTED_FILE_TYPE": "Fatkeqësisht ky lloj skedari nuk mbështetet.",
      "ALERT_FILE_SIZE": "Fatkeqësisht ky dokument është shumë i madh.",
      "LABEL_ACCEPTED_FILE_TYPES": "Llojet e pranuara të skedarëve",
      "LABEL_LIMIT": "limit",
      "INFO_DROP_AVAILABLE_PART_1": "Tërhiqni dhe lëshoni një skedar këtu ose",
      "INFO_DROP_AVAILABLE_PART_2": "Klikoni për të shfletuar",
      "INFO_DROP_NOT_AVAILABLE": "Klikoni për të shfletuar një skedar",
      "INFO_DRAG_ACTIVE": "Qëllim i mirë, koha për të rënë!",
      "INFO_UPLOAD_FAILED": "Ngarkimi dështoi, ju lutemi provoni përsëri"
    }
  },
  "AVAILABILITY": {
    "ABSENCE": {
      "TAB_ABSENCE": "Rezervimet e mungesës",
      "TAB_ALLOWANCES": "Shtesat"
    },
    "WEEKLY_AVAILABILITY_MODAL": {
      "ADD_TITLE": "Shto disponueshmëri javore",
      "EDIT_TITLE": "Redaktoni disponueshmërinë",
      "NICKNAME": "Pseudonim",
      "PLACEHOLDER": "dmth. Gjatë studimit",
      "HELP": "Ju duhet ta emërtoni këtë model të disponueshmërisë pas diçkaje kuptimplotë, si “Ndërsa Studioni” nëse kjo përfaqëson se kur mund të punoni gjatë studimit për provime, ose “Pushime verore” kur jeni të lirë shumicën e kohës, për shembull.",
      "STARTING_FROM": "Duke filluar nga",
      "NEVER_ENDING": "Kurrë nuk përfundon",
      "YES_PATTERN_FOREVER": "Po, le të vazhdojë ky model përgjithmonë",
      "NO_PATTERN_DATE": "Jo, ka një datë kur do të doja që ky model të ndalet",
      "STOP_DATE": "Data e ndalimit",
      "PATTERN_APPLIES": "Modeli zbatohet",
      "EVERY": "Çdo",
      "WEEK": "javë",
      "SAVING_BUTTON_TEXT": "Kursimi...",
      "SAVE_BUTTON_TEXT": "Ruaj",
      "CANCEL": "Anuloni"
    },
    "WEEKLY_AVAILABILITY_MODAL_SINGLE": {
      "TITLE": "Shtoni disponueshmëri të njëhershme",
      "SUBTITLE": "Për një javë të vetme",
      "WEEK_BEGINS": "Java fillon",
      "HELP": "Këtë javë dëshironi të shënoni disponueshmërinë tuaj për të.",
      "SAVING_BUTTON_TEXT": "Kursimi...",
      "SAVE_BUTTON_TEXT": "Ruaj",
      "CANCEL": "Anuloni"
    },
    "AVAILABILITY": {
      "ADD_AVAILABILITY": "Shto disponibilitet",
      "NEW_WEEKLY_PATTERN": "Modeli i ri javor",
      "NEW_MONTHLY_PATTERN": "Modeli i ri mujor",
      "NEW_CUSTOM_PATTERN": "Modeli i ri i personalizuar",
      "ONE_OFF_PATTERN": "Një herë (javë e vetme)",
      "AVAILABILITY": "disponueshmëria",
      "AVAILABILITY_HELP": "Menaxhoni disponueshmërinë këtu përmes modeleve të disponueshmërisë; mendoni për këto si ngjarje të përsëritura në",
      "AVAILABILITY_TIP": "Përdorni një model mujor për të synuar data si “e mërkura e parë” ose “e shtuna e fundit” e çdo muaji.",
      "NICKNAME": "Pseudonim",
      "APPLIES_FROM_TO": "Zbatohet nga (/në)",
      "OPTIONS": "Opsionet",
      "NO_AVAILABILITY_PATTERNS": "Nuk ka modele të disponueshmërisë. Shtoni një duke përdorur butonat e mësipërm.",
      "TURN_OFF": "Fikni",
      "TURN_ON": "Aktivizoni",
      "NO_NICKNAME": "Asnjë pseudonim",
      "WEEKLY": "javore",
      "MONTHLY": "mujore",
      "CUSTOM": "Me porosi",
      "EDIT": "Ndrysho",
      "TOGGLE_DROPDOWN": "Ndryshoni menunë zbritëse",
      "DELETE": "Fshini",
      "CONFIRM_DELETE_PATTERN": "A jeni i sigurt? Fshirja e këtij modeli të disponueshmërisë nuk mund të çaktivizohet.",
      "DELETE_PATTERN_SUCCESS": "Suksesi! Modeli juaj i disponueshmërisë është fshirë.",
      "ERROR_DELETING_PATTERN": "Ne nuk ishim në gjendje ta fshijmë këtë model tani. Ju lutemi provoni përsëri.",
      "ERROR_TOGGLING_PATTERN": "Ne nuk ishim në gjendje ta aktivizojmë atë model tani. Ju lutemi provoni përsëri.",
      "ERROR_LOADING_PATTERNS": "Ne ishim në gjendje të ngarkojmë modelet tuaja të disponueshmërisë. Ju lutemi provoni përsëri."
    },
    "DAILY_AVAILABILITY_MODAL": {
      "ADD_CUSTOM_AVAILABILITY": "Shtoni disponueshmërinë e",
      "EDIT_CUSTOM_AVAILABILITY": "Redaktoni disponueshmërinë",
      "NICKNAME": "Pseudonim",
      "NICKNAME_PLACEHOLDER": "dmth. Gjatë studimit",
      "NICKNAME_HELP": "Ju duhet ta emërtoni këtë model pas diçkaje kuptimplotë.",
      "STARTING_FROM": "Duke filluar nga",
      "NEVER_ENDING": "Kurrë nuk përfundon",
      "CONTINUE_FOREVER": "Po, le të vazhdojë ky model përgjithmonë",
      "PATTERN_STOP": "Jo, ka një datë kur do të doja që ky model të ndalet",
      "STOP_DATE": "Data e ndalimit",
      "PATTERN_DAYS": "Ditët e modelit",
      "PATTERN_NO_DAYS": "Ky model nuk ka ditë. Shtoni një duke përdorur butonin më poshtë.",
      "DAY_NUMBER": "Dita {{numër}}",
      "ILL_BE": "Unë do të jem",
      "DELETE": "Fshini",
      "ADD_DAY": "Shto ditë",
      "SAVE": "Ruaj",
      "SAVE_LOADING": "Kursimi...",
      "CANCEL": "Anuloni",
      "UNAVAILABLE": "I padisponueshëm",
      "AVAILABLE": "Në dispozicion",
      "AVAILABLE_AM": "Në dispozicion (vetëm AM)",
      "AVAILABLE_PM": "Në dispozicion (vetëm PM)",
      "OTHER": "Të tjera (specifikoni...)",
      "MINIMUM_TWO_DAYS": "Ju duhet të keni një minimum prej dy ditësh në këtë model.",
      "STOPS_BEFORE_STARTS": "Modeli juaj aktualisht është vendosur të ndalet para se të fillojë!",
      "AVAILABILITY_END_BEFORE_START": "Një ose më shumë nga rregullat tuaja të disponueshmërisë kanë një kohë përfundimi që bie para kohës së fillimit.",
      "ERROR_SAVING_PATTERN": "Aktualisht nuk është në gjendje të ruhet ky model disponueshmërie. Ju lutemi provoni përsëri.",
      "ERROR_UPDATING_PATTERN": "Aktualisht nuk mund të përditësohet ky model disponueshmërie. Ju lutemi provoni përsëri."
    },
    "EDIT_PATTERN_EXCEPTIONS_MODAL": {
      "EDIT_EXCEPTIONS": "Redakto përjashtimet",
      "EXCEPTIONS_TIP": "Modelet tuaja të disponueshmërisë shënojnë kur nuk jeni të lirë të punoni. Sidoqoftë, mund të përdorni kalendarin tuaj për të na thënë se jeni të lirë të punoni në një ditë që normalisht nuk do të jeni në gjendje ta bëni. Thjesht klikoni në shënuesin e kuq 'i padisponueshëm' dhe do të jeni në gjendje të bëni një përjashtim.",
      "EXCEPTIONS": "Lista e mëposhtme tregon përjashtimet që keni bërë në modelin tuaj të disponueshmërisë. Ne ju konsiderojmë të lirë të punoni në këto ditë:",
      "DATE": "datë",
      "OPTIONS": "Opsionet",
      "UNDO": "Çaktivizo",
      "NO_EXCEPTIONS": "Nuk keni bërë asnjë përjashtim akoma.",
      "CLOSE": "Mbyll",
      "ERROR_DELETING_EXCEPTION": "Fatkeqësisht nuk ishim në gjendje ta fshijmë përjashtimin vetëm atëherë. Ju lutemi provoni përsëri."
    },
    "MONTHLY_AVAILABILITY_MODAL": {
      "ADD_MONTHLY_AVAILABILITY": "Shto dispozicion mujor",
      "EDIT_MONTHLY_AVAILABILITY": "Redaktoni disponueshmërinë",
      "NICKNAME": "Pseudonim",
      "NICKNAME_PLACEHOLDER": "dmth. Gjatë studimit",
      "NICKNAME_HELP": "Ju duhet ta emërtoni këtë model të disponueshmërisë pas diçkaje kuptimplotë, si “Ndërsa Studioni” nëse kjo përfaqëson se kur mund të punoni gjatë studimit për provime, ose “Pushime verore” kur jeni të lirë shumicën e kohës, për shembull.",
      "STARTING_FROM": "Duke filluar nga",
      "NEVER_ENDING": "Kurrë nuk përfundon",
      "CONTINUE_FOREVER": "Po, le të vazhdojë ky model përgjithmonë",
      "PATTERN_STOP": "Jo, ka një datë kur do të doja që ky model të ndalet",
      "STOP_DATE": "Data e ndalimit",
      "PATTERN_RULES": "Rregullat e modelit",
      "PATTERN_NO_RULES": "Ky model nuk ka rregulla. Shtoni një duke përdorur butonin më poshtë.",
      "EVERY": "Çdo",
      "ON_THE": "në",
      "DELETE": "Fshini",
      "ADD_RULE": "Shto rregull",
      "SAVE": "Ruaj",
      "SAVE_LOADING": "Kursimi...",
      "CANCEL": "Anuloni",
      "ORDINAL_LAST": "i fundit",
      "ORDINAL_FIRST": "së pari",
      "ORDINAL_SECOND": "i dytë",
      "ORDINAL_THIRD": "i tretë",
      "ORDINAL_FOURTH": "i katërt",
      "ORDINAL_FIFTH": "i pestë",
      "MONDAY": "e hënë",
      "TUESDAY": "e martë",
      "WEDNESDAY": "e mërkurë",
      "THURSDAY": "e enjte",
      "FRIDAY": "e premte",
      "SATURDAY": "e shtunë",
      "SUNDAY": "e diel",
      "AVAILABLE": "Në dispozicion",
      "AVAILABLE_AM": "Vetëm AM",
      "AVAILABLE_PM": "Vetëm PM",
      "MONTH": "muaj",
      "MONTHS": "muaj",
      "OTHER": "Të tjera (specifikoni...)",
      "STOPS_BEFORE_STARTS": "Modeli juaj aktualisht është vendosur të ndalet para se të fillojë!",
      "AVAILABILITY_END_BEFORE_START": "Një ose më shumë nga rregullat tuaja të disponueshmërisë kanë një kohë përfundimi që bie para kohës së fillimit.",
      "ERROR_SAVING_PATTERN": "Aktualisht nuk është në gjendje të ruhet ky model disponueshmërie. Ju lutemi provoni përsëri.",
      "ERROR_UPDATING_PATTERN": "Aktualisht nuk mund të përditësohet ky model disponueshmërie. Ju lutemi provoni përsëri."
    },
    "OVERVIEW": {
      "SCHEDULE": "Orari im",
      "SCHEDULE_HELP": "Ky është orari juaj i plotë, duke përfshirë ndërrimet që ju janë caktuar, mungesën e aprovuar dhe disponueshmërinë.",
      "SHIFTS": "Ndryshimet",
      "ABSENCE": "Mungesa",
      "UNAVAILABLE": "E padisponueshme (modele)",
      "EDIT_EXCEPTIONS": "Redakto përjashtimet",
      "ICAL_FEED": "Feed iCalendar",
      "ICAL_FEED_HELP": "Një burim iCalendar do t'ju lejojë të shikoni orarin tuaj jashtë Rotaready.",
      "ICAL_FEED_TIP": "Pasi të keni gjeneruar një URL të burimit iCalendar, mund ta kopjoni dhe ngjisni atë në <a href=\"https://support.apple.com/en-gb/HT202361\" target=\"_blank\">Apple Calendar, <a href=\"https://support.google.com/calendar/answer/37100\" target=\"_blank\">Google</a> Calendar</a> ose ndonjë softuer tjetër të kalendarit.",
      "COPIED": "Kopjuar në clipboard!",
      "GENERATE": "Gjeneroni"
    }
  },
  "ATTENDANCE": {
    "VALIDATION_CODES": {
      "CODE_1": "Data përkatëse është nënshkruar, kështu që ne nuk mund të bëjmë asnjë ndryshim në të.",
      "CODE_2": "Bërja e kësaj do të rezultonte në një sekuencë të pavlefshme të ngjarjeve. Për shembull, një dalje e orës duhet të vijë pas një hyrjeje të orës, por nuk mund të vijë para një orës tjetër. Kontrolloni ngjarjet përreth pastaj provoni përsëri.",
      "CODE_3": "Siti/departamenti i zgjedhur është i pavlefshëm.",
      "CODE_4": "Ne nuk mund të shtojmë një orë këtu pasi punonjësi i zgjedhur nuk ka një takim në këtë datë.",
      "CODE_5": "Ne nuk mund të shtojmë një ngjarje këtu pasi tashmë ka një ngjarje ekzistuese në të njëjtën kohë.",
      "CODE_6": "Punonjësi i zgjedhur është i pavlefshëm.",
      "CODE_7": "Ky ndryshim do ta zhvendoste ngjarjen në një kohë më të hershme sesa ngjarja që vjen para saj. Provoni të zgjidhni një kohë tjetër ose redaktoni fillimisht ngjarjen e mëparshme.",
      "CODE_8": "Ky ndryshim do ta zhvendoste ngjarjen në një kohë më të vonshme sesa ngjarja që e ndjek atë. Provoni të zgjidhni një kohë tjetër ose redaktoni fillimisht ngjarjen e mëvonshme."
    },
    "COMMON": {
      "EVENT_ADD_SUCCESS": "Ngjarja u shtua me sukses.",
      "ABSENCE_ADD_SUCCESS": "Ata janë shënuar si të munguar.",
      "NO_CLOCK_IN_MODAL_TITLE": "Shto ngjarje",
      "NO_CLOCK_IN_MODAL_MESSAGE": "Çfarë do të dëshironit të bëni?",
      "NO_CLOCK_IN_MODAL_ADD_EVENT": "Shto orën në",
      "NO_CLOCK_IN_MODAL_ADD_EVENT_DESC": "Shtoni manualisht një rekord pjesëmarrjeje",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE": "Shënim mungon",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC": "Shtoni një regjistër mungese si sëmundje",
      "UNMATCHED_MODAL_TITLE": "Pjesëmarrje e pakrahas",
      "UNMATCHED_MODAL_MESSAGE": "Ka dy mënyra për të zgjidhur pjesëmarrjen e pakrahasueshme:",
      "UNMATCHED_MODAL_ADD_SHIFT": "Shto ndërrim",
      "UNMATCHED_MODAL_ADD_SHIFT_DESC": "Krijoni një ndryshim të ri për të reflektuar atë që është punuar",
      "UNMATCHED_MODAL_EDIT_EVENT": "Redaktoni pjesëmarrjen",
      "UNMATCHED_MODAL_EDIT_EVENT_DESC": "Rregulloni regjistrimet e pjesëmarrjes nëse nuk janë të sakta",
      "OVERTIME_MODAL_TITLE": "Rishikoni orën shtesë",
      "OVERTIME_MODAL_MESSAGE_IN": "{{name}} ka hyrë herët dhe zhvendosja ka përputhur me një rregull të pagës. Çfarë do të donit të bëni?",
      "OVERTIME_MODAL_MESSAGE_OUT": "{{name}} ka mbyllur me vonesë dhe ndërrimi ka përputhur me një rregull të pagës. Çfarë do të dëshironit të bëni?",
      "OVERTIME_MODAL_APPROVE": "Miratoni orën shtesë",
      "OVERTIME_MODAL_APPROVE_DESC": "{{name}} do të paguhet për {{kohëzgjatje}} minuta jashtë orarit",
      "OVERTIME_MODAL_REJECT": "Refuzoni orën shtesë",
      "OVERTIME_MODAL_REJECT_DESC": "{{name}} nuk paguhet për punën jashtë orarit",
      "OVERTIME_UPDATE_ERROR": "Ne nuk mund ta përditësojmë ngjarjen tani. Ju lutemi provoni përsëri.",
      "MODAL_USER_NOTES_LABEL": "Shënime nga  {{name}}"
    },
    "BY_STAFF": {
      "FILTER_LABEL_DATE": "datë",
      "MANUAL_CLOCK_IN": "Hyrja manuale e orës",
      "EVENT_TYPE": "Ngjarje",
      "RECORDED_TIME": "Koha e regjistruar",
      "METHOD": "Kapur nga",
      "OPTIONS": "Opsionet",
      "TRUSTED_DEVICE": "Pajisja e besuar",
      "MOBILE_APP": "Aplikacioni Rotaready",
      "MANUALLY_OVERRIDDEN": "Zëvendësoni manualisht",
      "MANUAL_ENTRY": "Hyrja manuale",
      "DELETE": "Fshini",
      "NO_EVENTS": "Nuk ka ngjarje të pjesëmarrjes për të shfaqur.",
      "CLOCK_IN": "Ora brenda",
      "CLOCK_OUT": "Ora jashtë",
      "BREAK_ON": "Në pushim",
      "BREAK_OFF": "Pushimi i jashtëm",
      "ERROR_LOADING_EVENTS": "Nuk mund të ngarkojmë pjesëmarrjen tani. Ju lutemi provoni përsëri.",
      "ERROR_DELETING": "Nuk mund ta fshijmë pjesëmarrjen tani. Ju lutemi provoni përsëri.",
      "DELETE_SUCCESS": "Ngjarja është fshirë me sukses.",
      "ADD_SUCCESS": "Ngjarja u shtua me sukses.",
      "LABEL_EDITED": "Redaktuar"
    },
    "ISSUES": {
      "TITLE": "Çështje pjesëmarrëse",
      "FILTER_DATE": "datë",
      "FILTER_ISSUE_TYPE": "Lloji i çështjes",
      "FILTER_POSITION": "Roli i punës",
      "FILTER_USER": "Person",
      "ISSUES_HELP": "Çështjet duhet të zgjidhen, përndryshe ato do të shkaktojnë mospërputhje në raportet dhe listën e pagave. Si parazgjedhje, Rotaready shfaqet nga ditët e fundit {{DefaultIssuePeriod}}.",
      "IS_THIS": "A është kjo",
      "YES": "po",
      "NO": "Jo",
      "HEADING_NAME": "Emri",
      "HEADING_SITE": "Faqja",
      "HEADING_ISSUE": "Çështje",
      "HEADING_SHIFT": "Ndryshimi",
      "HEADING_START": "Filloni",
      "HEADING_FINISH": "Përfundoj",
      "HEADING_CLOCK_IN": "Hyrja e orës",
      "HEADING_CLOCK_OUT": "Dalja e orës",
      "HEADING_OPTIONS": "Opsionet",
      "NOT_FOUND": "Nuk u gjet",
      "RESOLVE": "Zgjidhja",
      "DISMISS": "Shkarkoni",
      "NO_ATTENDANCE_ISSUES": "Ju nuk keni probleme me pjesëmarrjen, mirë është bërë!",
      "UNMATCHED_ATTENDANCE": "Pjesëmarrje e pakrahas",
      "NO_CLOCK_IN": "Nuk ka hyrje në orë",
      "NO_CLOCK_OUT": "Asnjë dalje e orës",
      "SUSPECT_CLOCK_OUT": "Përjashtimi i orës së dyshuarve",
      "SUSPECT_ATTENDANCE": "Pjesëmarrja e të",
      "BREAK_NOT_TAKEN": "Pushimi nuk është marrë",
      "INVALID_BREAKS": "Pushimet e pavlefshme",
      "SUSPECT_BREAK": "Tërheqja e dyshu",
      "EARLY_CLOCK_IN": "Hyrja e hershme e orës",
      "LATE_CLOCK_OUT": "Dalja e orës së vonë",
      "UNMATCHED_ATTENDANCE_EXPLANATION": "Rotaready nuk mund të gjejë një ndryshim për të përputhur këtë frekuencë me",
      "NO_CLOCK_IN_EXPLANATION": "Anëtari i stafit nuk erdhi në kohën për ndërrimin e tyre",
      "NO_CLOCK_OUT_EXPLANATION": "Anëtari i stafit nuk ka dalë pas ndërrimit",
      "SUSPECT_CLOCK_OUT_EXPLANATION": "Anëtari i stafit doli shumë më vonë se koha e planifikuar e përfundimit",
      "SUSPECT_ATTENDANCE_EXPLANATION": "Anëtari i stafit u regjistrua për një kohë shumë më të shkurtër se sa ishte planifikuar",
      "BREAK_NOT_TAKEN_EXPLANATION": "Ndërrimi ka një pushim, por një nuk u mor",
      "INVALID_BREAKS_EXPLANATION": "Pushimet në këtë pjesëmarrje janë jo të plota (d.m.th. mungon një ndërprerje)",
      "SUSPECT_BREAK_EXPLANATION": "Anëtari i stafit ishte në pushim për shumë më gjatë se ishte planifikuar",
      "EARLY_CLOCK_IN_EXPLANATION": "Ndërrimi përputhet me një rregull të pagës i cili kërkon miratim për një orë të hershme",
      "LATE_CLOCK_OUT_EXPLANATION": "Ndërrimi përputhet me një rregull të pagës i cili kërkon miratimin për një kohë të vonuar",
      "ERROR_LOADING_ATTENDANCE": "Ne nuk mund të ngarkojmë çështjet tuaja të pjesëmarrjes tani. Ju lutemi provoni përsëri.",
      "SOMETHING_WENT_WRONG": "Ups, diçka thjesht shkoi keq. Ju mund të duhet të provoni përsëri!",
      "CONFIRM_DISMISS": "A jeni i sigurt që dëshironi ta refuzoni këtë çështje të frekuencës?",
      "DATE_RANGE_TOO_WIDE": "Ju nuk mund të zgjidhni më shumë se 5 javë. Ju lutemi kufizoni intervalin tuaj të datave.",
      "CLOCK_OUT_DAYS_LATER": "Kjo orë është një ose më shumë ditë pas hyrjes së orës."
    },
    "OVERVIEW": {
      "UNMATCHED_LABEL": "{{count}} i pakrahasueshëm",
      "OPTIONS_DAILY": "Ditore",
      "OPTIONS_WEEKLY": "javore",
      "OPTIONS_DAY_ROLL_OVER": "Rrotullimi i ditës",
      "FILTER_POSITION": "Roli i punës",
      "FILTER_USER": "Person",
      "MIDNIGHT": "Mesnatë",
      "TOOLS": "Mjetet",
      "CURRENTLY_CLOCKED_IN": "Njerëzit që aktualisht janë në orë",
      "ATTENDANCE_HELP": "Pjesëmarrja përputhet automatikisht me ndër <b>rimet nëse një hyrje ose mbyllje e orës ndodh brenda orës {{AutomatchThreshold}} nga koha e planifikuar e fillimit ose përfundimit.</b>",
      "MATCHED_HEADING_SCHEDULED": "Planifikuar",
      "MATCHED_HEADING_ACTUAL": "Aktualisht",
      "MATCHED_HEADING_AFTER_SANITISATION": "Pas sanitizimit",
      "MATCHED_HEADING_NAME": "Emri",
      "MATCHED_HEADING_SITE": "Faqja",
      "MATCHED_HEADING_START": "Filloni",
      "MATCHED_HEADING_FINISH": "Përfundoj",
      "MATCHED_HEADING_PAID_HOURS": "Orë të paguara",
      "MATCHED_HEADING_UNPAID_HOURS": "Orë të papaguara",
      "COVER_SHIFT": "Ndërrimi i mbulesës",
      "NO_CLOCK_IN": "Ende nuk ka orë",
      "NO_CLOCK_OUT": "Ende nuk ka dalje të orës",
      "OVERTIME_APPROVED": "Miratuar",
      "OVERTIME_REJECTED": "Refuzuar",
      "OVERTIME_REVIEW": "Rishikimi",
      "OVERTIME_APPROVED_BY": "Koha shtesë e miratuar nga {{name}}",
      "OVERTIME_REJECTED_BY": "Koha shtesë refuzuar nga {{name}}",
      "MATCHED_NO_RESULTS": "Nuk ka ndryshime për të treguar.",
      "UNMATCHED_ATTENDANCE": "Pjesëmarrje e pakrahas",
      "UNMATCHED_ATTENDANCE_HELP": "Nëse pjesëmarrja nuk bie brenda orës <b>{{AutomAtchThreshold}} nga koha e fillimit dhe përfundim</b> it të një ndërrimi, do t'ju duhet të rregulloni manualisht ndërrimin ose regjistrimet e pjesëmarrjes për t'i sjellë ato në radhë:",
      "UNMATCHED_HEADING_NAME": "Emri",
      "UNMATCHED_HEADING_SITE": "Faqja",
      "UNMATCHED_HEADING_DATE": "datë",
      "UNMATCHED_HEADING_CLOCK_IN": "Ora brenda",
      "UNMATCHED_HEADING_CLOCK_OUT": "Ora jashtë",
      "UNMATCHED_HEADING_OPTIONS": "Opsionet",
      "NO_UNMATCHED_ATTENDANCE": "Nuk ka pjesëmarrje të pakrahasueshme këtu. Punë e bukur!",
      "NONE_YET": "Asnjë ende",
      "RESOLVE": "Zgjidhja",
      "ERROR_LOADING_ATTENDANCE": "Ne nuk mund të ngarkojmë historikun tuaj të pjesëmarrjes tani. Ju lutemi provoni përsëri.",
      "TIMELINESS_EARLY_TOOLTIP": "{{kohëzgjatja}} minuta më herët",
      "TIMELINESS_LATE_TOOLTIP": "{{kohëzgjatja}} minuta vonesë",
      "TRUNCATED_RESULT_MESSAGE": "Ka më shumë për të parë, por për t'i mbajtur gjërat të funksionojnë pa probleme, na është dashur të shkurtojmë rezultatin tuaj. <br />Provoni të filtroni sipas rolit të punës, sipas faqes/departamentit ose zvogëloni intervalin e datave."
    },
    "VIEW_EVENT_MODAL": {
      "VIEW_ATTENDANCE": "Shikoni pjesëmarrjen",
      "STAFF_MEMBER": "Kush",
      "EVENT_TYPE": "Ngjarje",
      "RECORDED_TIME": "Koha e regjistruar",
      "METHOD": "Kapur nga",
      "TRUSTED_DEVICE": "Pajisja e besuar",
      "MOBILE_APP": "Aplikacioni Rotaready",
      "MANUALLY_OVERRIDDEN": "Zëvendësohet manualisht",
      "MANUAL_ENTRY": "Hyrja manuale",
      "ON": "në",
      "OVERTIME": "Ora shtesë",
      "APPROVED": "Miratuar",
      "REJECTED": "Refuzuar",
      "FACIAL_RECOGNITION": "Njohje e fytyrës",
      "FACIAL_RECOGNITION_PROCESS": "Imazhet zakonisht përpunohen brenda disa minutave nga kapja",
      "FACIAL_RECOGNITION_WAITING": "Në pritje të përpunimit...",
      "TRUSTS_PHOTO": "Rotaready i beson kësaj foto",
      "NO_TRUSTS_PHOTO": "Rotaready nuk i beson kësaj foto",
      "CONFIDENT": "i sigurt",
      "UNABLE_TO_IDENTIFY": "Nuk mund të identifikojmë qartë një fytyrë",
      "PROCESSED_ON": "Përpunuar në",
      "ACKNOWLEDGED_BY": "Pranohet nga {{name}} në",
      "REFERENCE_IMAGE": "Imazhi i referencës",
      "REFERENCE_IMAGE_HELP": "Ky imazh nuk do të përpunohet, por përkundrazi do të përdoret si një referencë për krahasim",
      "EDIT": "Ndrysho",
      "CLOSE": "Mbyll",
      "TAB_DETAILS": "Detajet",
      "TAB_PHOTO": "Foto",
      "TAB_LOCATION": "Vendndodhja",
      "CLOCK_IN": "Ora brenda",
      "CLOCK_OUT": "Ora jashtë",
      "BREAK_ON": "Shkëputni",
      "BREAK_OFF": "Shkëputni",
      "HAPPENED_HERE": "ndodhi këtu",
      "TAB_AUDIT": "Histori",
      "AUDIT_TABLE_TIME": "Koha e pjesëmarrjes",
      "AUDIT_TABLE_OVERTIME": "Ora shtesë",
      "AUDIT_TABLE_WHO": "Regjistruar nga",
      "NOTES": "Shënime"
    },
    "EDIT_EVENT_MODAL": {
      "TITLE": "Redaktoni pjesëmarrjen",
      "CLOCK_IN": "Ora brenda",
      "CLOCK_OUT": "Ora jashtë",
      "BREAK_ON": "Shkëputni",
      "BREAK_OFF": "Shkëputni",
      "ERROR_UPDATING": "Ne nuk mund ta përditësojmë ngjarjen tani. Ju lutemi provoni përsëri.",
      "ERROR_DELETING": "Ne nuk mund ta fshijmë ngjarjen tani. Ju lutemi provoni përsëri.",
      "CONFIRM_DELETE": "A jeni i sigurt që dëshironi ta fshini këtë ngjarje?\nNëse është një hyrje në orë, dalja përkatëse e orës gjithashtu do të hiqet.",
      "PRIOR_EVENT_INFO": "Ngjarja e mëparshme është një <b>{{eventType}} në</b> <b>{{date|momentFormat: 'll</b> - LT'}}",
      "PRIOR_EVENT_CONFLICT": "Tashmë ekziston një <b>{{eventType}} në</b> <b>{{date|momentFormat: 'll -</b> LT'}}. Ju do të duhet të zgjidhni një kohë tjetër.",
      "LABEL_WHO": "Kush",
      "LABEL_EVENT": "Ngjarje",
      "LABEL_DATE": "Data dhe ora",
      "DATE_IN_FUTURE": "Kjo kohë ende nuk ka kaluar!",
      "LABEL_OVERTIME": "Ora shtesë",
      "APPROVE": "Miratoni",
      "REJECT": "Refuzoni",
      "LABEL_OVERTIME_HELP": "Rotaready identifikoi disa orë shtesë kur e përputhnin këtë ngjarje të pjesëmarrjes me një ndërrim. Nëse e miratoni atë, orari shtesë do të paguhet në përputhje me rregullin e pagës së ndërrimit.",
      "DELETE": "Fshini",
      "CANCEL": "Anuloni",
      "SAVE": "Ruaj ndryshimet"
    },
    "ADD_EVENT_MODAL": {
      "TITLE": "Shto pjesëmarrje",
      "CLOCK_IN": "Ora brenda",
      "CLOCK_OUT": "Ora jashtë",
      "BREAK_ON": "Shkëputni",
      "BREAK_OFF": "Shkëputni",
      "ERROR_ACTIONING": "Nuk mund ta shtojmë ngjarjen tani. Ju lutemi provoni përsëri.",
      "PRIOR_EVENT_INFO": "Ngjarja e mëparshme është një <b>{{eventType}} në</b> <b>{{date|momentFormat: 'll</b> - LT'}}",
      "PRIOR_EVENT_CONFLICT": "Tashmë ekziston një <b>{{eventType}} në</b> <b>{{date|momentFormat: 'll -</b> LT'}}. Ju do të duhet të zgjidhni një kohë tjetër.",
      "LABEL_EVENT": "Ngjarje",
      "LABEL_DATE": "Data dhe ora",
      "LABEL_BREAK": "Kohëzgjatja e pus",
      "LABEL_BREAK_HELP": "Një ndërprerje do të shtohet për ju pas kohëzgjatjes së zgjedhur.",
      "DATE_IN_FUTURE": "Kjo kohë ende nuk ka kaluar!",
      "CANCEL": "Anuloni",
      "SAVE": "Shto pjesëmarrje",
      "BREAK_MINUTES": "minuta"
    }
  },
  "DIALOG": {
    "TEXTBOX_DIALOG": {
      "CANCEL": "Anuloni"
    },
    "TWO_BUTTON_DIALOG": {
      "CANCEL": "Anuloni"
    }
  },
  "OTHER": {
    "EDIT_PREFERENCES_MODAL": {
      "TITLE": "Përditësoni preferencat",
      "SUBTITLE": "Informacionet e kontaktit",
      "SUBTITLE_HELP": "Nëse harroni fjalëkalimin tuaj, ne mund t'ju ndihmojmë ta rivendosni atë vetëm nëse kemi mënyra alternative për t'ju kontaktuar",
      "EMAIL_LABEL": "Adresa e postës elektronike",
      "EMAIL_PLACEHOLDER": "Asnjë nuk është dhënë",
      "EMAIL_HELP": "Ju lutemi jepni një adresë të vlefshme të emailit.",
      "MOBILE_LABEL": "Telefoni celular",
      "MOBILE_PLACEHOLDER": "Asnjë nuk është dhënë",
      "MOBILE_HELP": "Ju lutemi jepni një numër celular të vlefshëm, ose asnjë asnjë.",
      "ACCOUNT_INFO": "Informacioni i llogarisë",
      "ACCOUNT_INFO_HELP": "Këtu mund të vendosni gjuhën tuaj",
      "LANGUAGE_LABEL": "Gjuha",
      "LANGUAGE_HELP": "Rotaready do të shfaqet në gjuhën tuaj të zgjedhur.",
      "OTHER_SETTINGS_TITLE": "Cilësimet e tjera",
      "CLOCK_IN_LABEL": "PIN i hyrjes së orës",
      "CLOCK_IN_HELP": "Nëse ju kërkohet të futni një kod PIN kur hyni në/daljen, mund të futni një të ri këtu. Duhet të jetë 4 shifra.",
      "NOTIFICATIONS_TITLE": "Njoftimet",
      "NOTIFICATIONS_HELP": "Kontrolloni se si dëshironi të njoftoheni për ngjarjet në të cilat jeni abonuar ",
      "MANAGE_SUBSCRIPTIONS": "menaxhoni abonimet",
      "ROTA_UPDATES": "Përditësimet e Rota",
      "CRITIAL_SHIFT": "Përditësimet kritike të ndryshim",
      "EMAIL": " Email",
      "MOBILE_APP": " Aplikacion celular",
      "SHIFT_UPDATED": "Kur një ndërrim që do të punoni në 24 orët e ardhshme është përditësuar",
      "REGULAR_SHIFT": "Përditësimet e rregullta të",
      "SHIFT_UPDATED_BEYOND": "Përditësime për çdo ndërrim që do të punoni përtej 24 orëve të ardhshme",
      "WEEKLY_SUMMARY": "Përmbledhje javore",
      "SMS": " SMS",
      "UPCOMING_WEEK": "Një përmbledhje e ndërrimeve tuaja për javën e ardhshme, dërguar tek ju në mbrëmje para fillimit të javës",
      "APPROVAL": "Miratimi",
      "APPROVAL_HELP": "Kur një rota shënohet si e gatshme për miratim",
      "PUBLISHING": "Publikimi",
      "PUBLISHING_HELP": "Kur një rota publikohet ose nuk publikohet",
      "SHIFT_SWAPS": "Shkëmbimet e ndryshimeve",
      "SWAP_UPDATES": "Përditësime shkëmbimi ",
      "REQUESTED": "Kërkohet",
      "REQUESTED_HELP": "Kur dikush kërkon një shkëmbim të ri të ndërrimit",
      "ACCEPTED": "Pranuar",
      "ACCEPTED_HELP": "Kur dikush pranon një shkëmbim ndërrimi",
      "APPROVED": "Miratuar",
      "APPROVED_HELP": "Kur një shkëmbim ndërrimi miratohet nga menaxhmenti",
      "REJECTED": "Refuzuar",
      "REJECTED_HELP": "Kur një shkëmbim ndërrimi refuzohet nga menaxhmenti",
      "CANCELLED": "Anuluar",
      "CANCELLED_HELP": "Kur një shkëmbim i ndërrimit anulohet",
      "ABSENCE_UPDATES": "Përditësimet e mungesës/pushimeve",
      "ABSENCE_REQUESTED": "Mungesa e kërkuar",
      "ABSENCE_REQUESTED_HELP": "Kur kërkohet një mungesë e re",
      "ABSENCE_UPDATE": "Azhurnimi i mungesës",
      "ABSENCE_UPDATE_HELP": " Kur një kërkesë për mungesë miratohet, refuzohet ose anulohet nga menaxhmenti",
      "COST_CONTROL": "Kontrolli i kostos",
      "SIGN_OFF": "Fikni",
      "SIGN_OFF_HELP": "Kur një faqe shfaqet një javë",
      "SIGN_OFF_REVERSE": "Nënshkrimi i kthyer",
      "SIGN_OFF_REVERSE_HELP": "Kur një faqe përmbys nënshkrimin për një javë",
      "ADMIN": "Admin",
      "ACCOUNT_APPROVAL": "Miratimi i llogarisë",
      "ACCOUNT_APPROVAL_HELP": "Kur një llogari është në pritje të miratimit ose është miratuar",
      "DOCUMENTS": "Dokumentet",
      "DOCUMENTS_HELP": "Kur një dokument ka skaduar ose do të skadojë së shpejti",
      "OTHER": "Të tjera",
      "COMPANY_ANNOUNCEMENTS": "Njoftimet e kompanisë",
      "COMPANY_HELP": "Njoftime të rëndësishme nga punëdhënësi juaj",
      "DAILY_ADMIN_UPDATE": "Azhurnimi ditor i administratorit",
      "DAILY_UPDATE_HELP": "Ne do t'ju njoftojmë nëse keni ndonjë problem të pjesëmarrjes, ose nëse ka ndonjë kërkesë për mungesë ose shkëmbim ndërrimi në pritje të miratimit.",
      "PHONE_ONLY": "Njoftimet me këtë ikonë janë në dispozicion vetëm me aplikacionet Rotaready iPhone dhe Android.",
      "CHANGE_PASSWORD": "Ndërroni fjalëkalimin",
      "SAVE": "Ruaj",
      "SAVING": "Kursimi...",
      "CANCEL": "Anuloni"
    },
    "MANAGE_SUBSCRIPTIONS_MODAL": {
      "TITLE": "Menaxhoni abonimet",
      "HELP": "Abonimet tuaja kontrollojnë atë për çfarë dëshironi të njoftoheni.",
      "UNSUBSCRIBE_ALL": "Çabonimi nga të gjitha",
      "NOTIFY_WHEN": "Më njofto kur {{përshkrues}}",
      "UNSUBSCRIBE_TOOLTIP": "Çabonimi",
      "UNSUBSCRIBE_ALL_TOOLTIP": "Çabonimi nga të gjitha",
      "ACTION_IN": "{{action}} në ",
      "FILTER_NO_SUBSCRIPTIONS": "Ju nuk keni abonime aktive në {{name}}.",
      "ALL_NO_SUBSCRIPTIONS": "Ju nuk keni abonime aktive.",
      "CLOSE": "Mbyll"
    },
    "RELEASE_REFRESH_MODAL": {
      "TITLE": "Përditësimi Rotaready",
      "BODY": "Ne sapo kemi lëshuar një përditësim për Rotaready që kërkon që ju të rimbushni faqen. Ju lutemi klikoni butonin më poshtë.",
      "REFRESH": "Rifreskoni faqen"
    },
    "EDIT_AVATAR_MODAL": {
      "TITLE": "Foto e profilit",
      "INTRO_COPY": "Një foto ndihmon njerëzit t'ju njohin dhe ju lejon të dini kur jeni regjistruar në llogarinë tuaj.",
      "BUTTON_CHANGE": "Ngarko një foto të re",
      "BUTTON_CANCEL": "Anuloni",
      "BUTTON_SAVE": "Ruaj",
      "ALERT_REMOVE": "A jeni i sigurt që dëshironi ta hiqni këtë foto të profilit?",
      "ALERT_FILE_TYPE_NOT_ALLOWED": "Ky lloj i skedarit nuk lejohet.",
      "ALERT_ERROR_500": "Nuk mund ta përditësojmë fotografinë tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "ALERT_SUCCESS_ADD": "Fotografia juaj e profilit është përditësuar. Mund të duhet pak kohë për t'u përditësuar në të gjitha shërbimet e Rotaready.",
      "ALERT_SUCCESS_REMOVE": "Fotografia juaj e profilit është hequr. Mund të duhet pak kohë për t'u përditësuar në të gjitha shërbimet e Rotaready."
    }
  },
  "REPORTS": {
    "INDEX_LIST": {
      "TITLE": "Raportet",
      "TRONC_STATUS": "Statusi i Tronc",
      "CURRENTLY_CLOCKED_IN": "Njerëzit aktualisht të hyjnë",
      "ROTA_PROGRESS": "Rrota Progress",
      "SIGN_OFF_PROGRESS": "Progresi i nënshkrimit",
      "PAYROLL_EXPORT": "Eksporti i pagave",
      "LABOUR_BREAKDOWN": "Ndarja e punës",
      "WORKING_TIME_VIOLATIONS": "Shkeljet e kohës së punës",
      "CONTRACTUAL_OBLIGATION_SUMMARY": "Përmbledhje e detyrimit",
      "DOCUMENT_SIGNATURES": "nënshkrimi i dokumenteve",
      "EXPIRING_DOCUMENTS": "Dokumentet që skadojnë",
      "EMPLOYEE_EXPORT": "Detajet e Punëtor",
      "JOINERS_LEAVERS": "Bashkëpunësit dhe lëshuesit",
      "STAFF_TURNOVER": "Qarkullimi i stafit",
      "ANNIVERSARY": "Përvjetorët",
      "EMPLOYMENT_CHANGES": "Ndryshimet e punës",
      "CATEGORY_HR": "BURIMET NJERËZORE",
      "CATEGORY_DOCUMENTS": "Dokumentet",
      "CATEGORY_ROTAS": "Planifikimi",
      "CATEGORY_PAYROLL": "Pagat e listës",
      "CATEGORY_ATTENDANCE": "Pjesëmarrja",
      "CATEGORY_ABSENCE": "Mungesa",
      "CATEGORY_COST_CONTROL": "Kontrolli i kostos",
      "ABSENCE_EXPORT": "Mungesa e eksportit",
      "ALLOWANCES_EXPORT": "Shtesat Eksporti",
      "UNTAKEN_HOLIDAY_COST": "Kostoja e papërpunuar e pushimeve",
      "COST_CONTROL_RAW_DATA": "Eksporti i të dhënave të para",
      "NEW_HIRE_TURNOVER": "Qarkullimi i ri i qirasë",
      "ABSENCE_DAYS_EXPORT": "Eksporti i ditëve të munges",
      "SHIFT_TYPE_STREAM_BREAKDOWN": "Lloji i ndërrimit dhe ndarja e rrymës",
      "XERO_EXPORT": "Eksporti në Xero",
      "DOCUMENT_PRESENCE": "Prania e Dokumentit",
      "DOCUMENTS_EXPORT": "Eksporti i Dokumenteve",
      "ATTENDANCE_BREAKDOWN": "Ndarja e pjesëmarr",
      "LATENESS": "vonesa",
      "LABOUR_BREAKDOWN_FILE_EXPORT": "Eksporti i skedarit të ndarjes së punës",
      "CATEGORY_ASYNC_FILE_EXPORTS": "Raportet e mëdha",
      "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": "Eksportimi i skedarit të ndarjes së punës",
      "PAYROLL_ASYNC_FILE_EXPORT": "Eksporti i skedarit të pagave",
      "RAW_COST_CONTROL_ASYNC_FILE_EXPORT": "Eksportimi i skedarit të kontrollit të kostos së papërpunuar"
    },
    "LATENESS": {
      "TITLE": "vonesa",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE_APPOINTMENT": "Faqja (emërimi)",
      "COLUMN_DEPT_APPOINTMENT": "Departamenti (emërimi)",
      "COLUMN_INSTANCES": "Rastet",
      "COLUMN_VARIANCE": "Varianca mesatare",
      "FILTER_DATE": "datë",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_USER": "Person",
      "FILTER_OVERTIME": "Ora shtesë",
      "FILTER_OVERTIME_NA": "Nuk pranohet",
      "FILTER_OVERTIME_APPROVED": "Miratuar",
      "FILTER_OVERTIME_REJECTED": "Refuzuar",
      "FILTER_CLOCK_IN": "Hyrja e orës",
      "FILTER_CLOCK_OUT": "Dalja e orës",
      "FILTER_MINS_LATE": "minuta vonë",
      "FILTER_MINS_EARLY": "minuta më herët",
      "LABEL_MINUTES": "minuta",
      "BUTTON_EXPORT": "Eksporti",
      "NO_MATCH": "Ne nuk mund të gjetëm asnjë të dhënë që përputhet me filtrat tuaj."
    },
    "ATTENDANCE_BREAKDOWN": {
      "TITLE": "Ndarja e pjesëmarr",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE_APPOINTMENT": "Faqja (emërimi)",
      "COLUMN_DEPT_APPOINTMENT": "Departamenti (emërimi)",
      "COLUMN_SITE_ATTRIBUTION": "Faqja (atribuimi)",
      "COLUMN_DEPT_ATTRIBUTION": "Departamenti (atribuimi)",
      "COLUMN_DATE": "Data e biznesit",
      "COLUMN_SHIFT_TYPE": "Lloji i ndërrimit",
      "COLUMN_RECORD_TYPE": "Lloji i regjistrimit",
      "COLUMN_SCHEDULED": "Planifikuar",
      "COLUMN_ACTUAL": "Aktualisht",
      "COLUMN_VARIANCE": "Variancë",
      "COLUMN_CAPTURED_BY": "Kapur nga",
      "COLUMN_EDITED_BY": "Redaktuar nga",
      "COLUMN_EDITED_DATE": "Data e redaktuar",
      "COLUMN_OVERTIME_STATE": "Miratimi i orës shtesë",
      "COLUMN_OVERTIME_EDITED_BY": "Koha shtesë e konfirmuar nga",
      "COLUMN_OVERTIME_EDITED_DATE": "Data e konfirmuar jashtë orarit",
      "FILTER_DATE": "datë",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_USER": "Person",
      "FILTER_OVERTIME": "Ora shtesë",
      "FILTER_OVERTIME_NA": "Nuk pranohet",
      "FILTER_OVERTIME_APPROVED": "Miratuar",
      "FILTER_OVERTIME_REJECTED": "Refuzuar",
      "FILTER_CLOCK_IN": "Hyrja e orës",
      "FILTER_CLOCK_OUT": "Dalja e orës",
      "FILTER_MINS_LATE": "minuta vonë",
      "FILTER_MINS_EARLY": "minuta më herët",
      "BUTTON_EXPORT": "Eksporti",
      "NO_MATCH": "Ne nuk mund të gjetëm asnjë të dhënë që përputhet me filtrat tuaj."
    },
    "DOCUMENTS_EXPORT": {
      "TITLE": "Eksporti i Dokumenteve",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_TOGGLE_USER_ACTIVE": "Punonjësit aktivë",
      "FILTER_USER": "Person",
      "FILTER_CATEGORY": "Kategori",
      "FILTER_ACKNOWLEDGEMENT": "Statusi",
      "FILTER_OPTION_ACKNOWLEDGED": "E pranuar",
      "FILTER_OPTION_NOT_ACKNOWLEDGED": "Nuk pranohet",
      "BUTTON_EXPORT": "Eksporti",
      "COLUMN_USER_ID": "ID e punonjësit",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE": "Faqja",
      "COLUMN_DEPARTMENT": "ministri",
      "COLUMN_CATEGORY": "Kategori",
      "COLUMN_FILE_NAME": "Emri",
      "COLUMN_FILE_SIZE": "Madhësia e dokumentit (KB)",
      "COLUMN_UPLOADED_BY": "Ngarkuar nga",
      "COLUMN_UPLOADED_DATE": "Data e ngarkimit",
      "COLUMN_EXPIRY_DATE": "Data e skadimit",
      "COLUMN_SIGNATURE_METHOD": "Metoda e nënshkrimit",
      "COLUMN_EDITED_BY": "Redaktuar për herë të fundit nga",
      "COLUMN_EDITED_DATE": "Data e redaktimit të fundit",
      "COLUMN_ACKNOWLEDGED": "E pranuar",
      "COLUMN_ACKNOWLEDGED_DATE": "Data e pranuar",
      "COLUMN_NOTES": "Shënime",
      "NO_MATCH": "Ne nuk mund të gjetëm asnjë punonjës për filtrat tuaj."
    },
    "DOCUMENT_PRESENCE": {
      "TITLE": "Prania e Dokumentit",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_TOGGLE_USER_ACTIVE": "Punonjësit aktivë",
      "FILTER_CATEGORY": "Kategori",
      "BUTTON_EXPORT": "Eksporti",
      "COLUMN_USER_ID": "ID e punonjësit",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE": "Faqja",
      "COLUMN_DEPARTMENT": "ministri",
      "COLUMN_EMPLOYMENT_ACTIVE": "Punësim aktiv",
      "COLUMN_JOIN_DATE": "Data e bashkimit",
      "COLUMN_LEAVE_DATE": "Lini datën",
      "COLUMN_DOCUMENTS_VALIDITY": "Dokumentet e vlefshme",
      "NO_MATCH": "Ne nuk mund të gjetëm asnjë punonjës për filtrat tuaj."
    },
    "SHIFT_TYPE_STREAM_BREAKDOWN": {
      "TITLE": "Ndarja e llojit të ndryshim",
      "FILTER_DATE": "datë",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_LAST_TWO_WEEKS": "Dy javët e fundit",
      "JUMP_TO_LAST_MONTH": "Muajin e kaluar",
      "FILTER_SITE": "Faqit/Departamenti",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "FILTER_DATA_SOURCE": "Burimi i të dhënave",
      "FILTER_USER": "Person",
      "FILTER_BEHAVIOUR": "Sjellja",
      "BEHAVIOUR_SHIFT_TYPE": "Lloji i ndërrimit",
      "BEHAVIOUR_STREAM": "Rrjedha",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE": "Faqja aktuale",
      "COLUMN_DEPARTMENT": "Departamenti aktual",
      "COLUMN_STAFF_GROUP": "Grupi aktual i stafit",
      "COLUMN_TOTAL": "Gjithsej",
      "ROW_TOTAL": "Gjithsej",
      "INFO_NO_DATA": "Nuk ka regjistra që përputhen me filtrat tuaj.",
      "INFO_NO_FILTER": "Zgjidhni një faqe/departament më lart për të filluar.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tarifa sipas orës",
      "PAY_AMOUNT_TYPE_DAILY": "Norma ditore",
      "PAY_AMOUNT_TYPE_ANNUAL": "Paga vjetore",
      "DATA_SOURCE_SHIFTS": "Ndryshimet",
      "DATA_SOURCE_MATCHED": "Sanitizuar",
      "DATE_ERROR_DIFF": "Ju nuk mund të zgjidhni më shumë se 6 javë. Ju lutemi kufizoni intervalin tuaj të datave.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "ERROR_400": "Parametrat tuaj shkaktojnë që të gjenerohen shumë të dhëna për një raport të vetëm. Ju lutemi zvogëloni fushëveprimin e kërkesës tuaj dhe provoni përsëri.",
      "STREAM_UNALLOCATED": "I papërcaktuar",
      "FILTER_METRIC": "Vlera",
      "METRIC_WAGES": "Kostoja bazë e pagës",
      "METRIC_HOURS": "Orë të paguara"
    },
    "ABSENCE_DAYS_EXPORT": {
      "TITLE": "Eksporti i ditëve të munges",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "COLUMN_USER_ID": "ID e punonjësit",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE": "Faqja",
      "COLUMN_DEPARTMENT": "ministri",
      "COLUMN_STAFF_GROUP": "Grupi i stafit",
      "COLUMN_JOB_ROLE_NAME": "Roli i punës",
      "COLUMN_DATE": "datë",
      "COLUMN_HOURS": "Orë",
      "COLUMN_HEADER_ID": "ID kryesore",
      "COLUMN_ABSENCE_TYPE": "Lloji",
      "COLUMN_ABSENCE_TYPE_REASON": "Arsyeja",
      "COLUMN_STATUS": "Statusi",
      "COLUMN_SCOPE": "Shtrirja",
      "COLUMN_START_DATE": "Data e fillimit të titullit",
      "COLUMN_END_DATE": "Data e përfundimit të titullit",
      "COLUMN_START_TIME": "Koha e fillimit",
      "COLUMN_END_TIME": "Koha e përfundimit",
      "COLUMN_TOTAL_HOURS": "Orët e kryerjes",
      "COLUMN_TOTAL_DAYS": "Ditët e titullit",
      "COLUMN_CANCELLED": "Anuluar",
      "FILTER_DATE": "datë",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_STATUS": "Statusi",
      "FILTER_TYPE": "Lloji",
      "FILTER_USER": "Person",
      "FILTER_TOGGLE_CANCELLED": "Përfshini anuluar",
      "FILTER_TOGGLE_USER_ACTIVE": "Punonjësit aktivë",
      "BUTTON_EXPORT": "Eksporti",
      "NO_MATCH": "Nuk mund të gjetëm asnjë mungesë që përputhet me filtrat tuaj."
    },
    "NEW_HIRE_TURNOVER": {
      "TITLE": "Qarkullimi i ri i qirasë",
      "FILTER_DATE": "Data e bashkimit",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_LAST_TWO_WEEKS": "Dy javët e fundit",
      "JUMP_TO_LAST_MONTH": "Muajin e kaluar",
      "FILTER_AGGREGATION": "Mbledhja",
      "AGGREGATION_ENTITY_GROUP": "Faqit/Departamenti",
      "AGGREGATION_POSITION": "Roli i punës",
      "AGGREGATION_STAFF_GROUP": "Grupi i stafit",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "DATE_ERROR_DIFF": "Ju nuk mund të zgjidhni më shumë se 12 muaj. Ju lutemi kufizoni intervalin tuaj të datave.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "COLUMN_AGGREGATION_LABEL": "Emri",
      "COLUMN_JOINERS": "Bashkëpunëtorë",
      "COLUMN_LEAVERS": "Lëshuesit",
      "COLUMN_TURNOVER_RATE": "Shkalla e qarkullimit",
      "DEPARTED_WITHIN": "Lini datën",
      "DAYS_LABEL": "brenda {{count}} ditëve",
      "LABEL_TOTAL": "Gjithsej",
      "LABEL_GRAND_TOTAL": "Totali i madh",
      "FILTER_LEAVER_REASON": "Arsyeja e lëvizjes"
    },
    "COST_CONTROL_RAW_DATA": {
      "TITLE": "Eksporti i të dhënave të papërpunuara të",
      "ERROR_500": "Ne nuk ishim në gjendje të eksportojmë të dhënat vetëm atëherë. Ju lutemi provoni përsëri.",
      "BUTTON_EXPORT": "Eksporti",
      "LOADING_TEXT": "Eksporti juaj po gjenerohet.",
      "METRIC_HOURS": "Orë",
      "METRIC_WAGES": "Pagat",
      "METRIC_SALES": "Shitjet",
      "METRIC_COVERS": "Mbulon",
      "SUBDIVISION_PREDICTION": "Parashikimet",
      "SUBDIVISION_FORECAST": "Parashikimet",
      "SUBDIVISION_ACTUAL": "Aktualisht",
      "FILTER_METRICS": "Metrikë",
      "FILTER_SUBDIVISIONS": "Ndarjet",
      "FILTER_ENTITY": "Faqit/Departamenti",
      "FILTER_ENTITY_BUTTON": "Faqit/Departamenti",
      "FILTER_DATE": "datë",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_LAST_TWO_WEEKS": "Dy javët e fundit",
      "JUMP_TO_LAST_MONTH": "Muajin e kaluar",
      "CALCULATE_ACTUAL_CHECKBOX": "Llogaritni orat/pagat aktuale gjatë fluturimit, ku të dhënat e regjistruara nuk janë të pranishme.",
      "DATE_ERROR_DIFF": "Ju nuk mund të zgjidhni më shumë se 2 muaj. Ju lutemi kufizoni intervalin tuaj të datave.",
      "CALCULATE_ACTUAL_ERROR": "Orët/pagat aktuale nuk mund të llogariten në fluturim për më shumë se një javë ose pesëmbëdhjetë vende/departamente."
    },
    "ABSENCE_EXPORT": {
      "TITLE": "Mungesa e eksportit",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_USER_ID": "ID e punonjësit",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE": "Faqja",
      "COLUMN_DEPARTMENT": "ministri",
      "COLUMN_STAFF_GROUP": "Grupi i stafit",
      "COLUMN_JOB_ROLE_NAME": "Roli i punës",
      "COLUMN_ABSENCE_TYPE": "Lloji",
      "COLUMN_ABSENCE_TYPE_REASON": "Arsyeja",
      "COLUMN_STATUS": "Statusi",
      "COLUMN_SCOPE": "Shtrirja",
      "COLUMN_START_DATE": "Data e fillimit",
      "COLUMN_END_DATE": "Data e përfundimit",
      "COLUMN_START_TIME": "Koha e fillimit",
      "COLUMN_END_TIME": "Koha e përfundimit",
      "COLUMN_TOTAL_HOURS": "Orë",
      "COLUMN_TOTAL_DAYS": "Ditët",
      "COLUMN_REQUEST_NAME": "Kërkohet nga",
      "COLUMN_REQUEST_DATE": "Data e kërkesës",
      "COLUMN_REQUEST_MESSAGE": "Kërkoni mesazh",
      "COLUMN_REVIEW_NAME": "Shqyrtuar nga",
      "COLUMN_REVIEW_DATE": "Data e rishikimit",
      "COLUMN_REVIEW_MESSAGE": "Rishikoni mesazhin",
      "COLUMN_EDITED_NAME": "Redaktuar për herë të fundit nga",
      "COLUMN_EDITED_DATE": "Data e redaktimit të fundit",
      "COLUMN_CANCELLED": "Anuluar",
      "COLUMN_CANCELLED_NAME": "Anulohet nga",
      "COLUMN_CANCELLED_DATE": "Data e anuluar",
      "FILTER_DATE": "Data e fillime/përfundimit",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_STATUS": "Statusi",
      "FILTER_TYPE": "Lloji",
      "FILTER_USER": "Person",
      "FILTER_TOGGLE_CANCELLED": "Përfshini anuluar",
      "FILTER_TOGGLE_USER_ACTIVE": "Punonjësit aktivë",
      "BUTTON_EXPORT": "Eksporti",
      "NO_MATCH": "Nuk mund të gjetëm asnjë mungesë që përputhet me filtrat tuaj."
    },
    "ALLOWANCE_EXPORT": {
      "TITLE": "Shtesat Eksporti",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_USER_ID": "ID e punonjësit",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE": "Faqja",
      "COLUMN_DEPARTMENT": "ministri",
      "COLUMN_ABSENCE_TYPE": "Lloji i mungesës",
      "COLUMN_YEAR_STARTS": "Viti fillon",
      "COLUMN_EFFECTIVE_DATE": "Data e fuqisë",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Orari i përllogaritjes",
      "COLUMN_UNIT": "Njësia",
      "COLUMN_DEDUCTIBLE": "I zbritshëm",
      "COLUMN_TOTAL_ACCRUED": "Totali i përllogaritur",
      "COLUMN_OPENING_BALANCE": "Bilanci i hapjes",
      "COLUMN_BOOKED": "Rezervuar",
      "COLUMN_BOOKED_AND_ELAPSED": "Rezervuar dhe kaluar",
      "COLUMN_REMAINING": "Të mbetura",
      "COLUMN_ACCRUAL_ESTIMATE": "Vlerësimi i llogaritjes",
      "COLUMN_CARRIED_OVER": "Mbajtur",
      "COLUMN_OVERSPEND_LIMIT": "Shpenzimi i tepërt",
      "COLUMN_TOTAL_TO_ACCRUE": "Maksimumi për të grumbulluar",
      "COLUMN_ACCRUAL_RATE": "Anashkalimi i normës së përllogaritjes",
      "COLUMN_ADDED_NAME": "Shtuar nga",
      "COLUMN_ADDED_DATE": "Data e shtuar",
      "COLUMN_EDITED_NAME": "Redaktuar për herë të fundit nga",
      "COLUMN_EDITED_DATE": "Data e redaktimit të fundit",
      "COLUMN_FINALISED": "Finalizuar",
      "COLUMN_FINALISED_REFERENCE_DATE": "Data e referencës së përfunduar",
      "COLUMN_FINALISED_EDITED_NAME": "Finalizuar nga",
      "COLUMN_FINALISED_EDITED_DATE": "Finalizuar në",
      "COLUMN_FINALISED_NOTES": "Shënimet e përfunduara",
      "COLUMN_PROJECT_TOTAL_ACCRUED": "Parashikoni të përllogaritur gjatë rezervimit",
      "FILTER_DATE": "Data e fuqisë",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_TYPE": "Lloji i mungesës",
      "FILTER_SCHEDULE_OF_ACCRUAL": "Orari i përllogaritjes",
      "FILTER_USER": "Person",
      "FILTER_TOGGLE_USER_ACTIVE": "Punonjësit aktivë",
      "BUTTON_EXPORT": "Eksporti",
      "NO_MATCH": "Ne nuk mund të gjetëm asnjë shtesë që përputhet me filtrat tuaj."
    },
    "UNTAKEN_HOLIDAY_COST": {
      "TITLE": "Kostoja e papërpunuar e pushimeve",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "ERROR_400": "Diapazoni juaj i datës efektive është shumë i gjerë, ju lutemi ngushtoni atë dhe provoni përsëri.",
      "BUTTON_EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "FILTER_DATE": "Data e fuqisë së ndihmës",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_TOGGLE_USER_ACTIVE": "Punonjësit aktivë",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE": "Faqja",
      "COLUMN_DEPARTMENT": "ministri",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Orari i përllogaritjes",
      "COLUMN_UNIT": "Njësia",
      "COLUMN_DEDUCTIBLE": "I zbritshëm",
      "COLUMN_TOTAL_ACCRUED": "Totali i përllogaritur",
      "COLUMN_BOOKED": "Rezervuar",
      "COLUMN_BOOKED_AND_ELAPSED": "I kaluar",
      "COLUMN_UNTAKEN": "I papërfillur",
      "COLUMN_PAY_AMOUNT": "Shuma",
      "COLUMN_PAY_AMOUNT_TYPE": "Lloji",
      "COLUMN_PAY_PROJECTED_COST": "Kostoja e parashikuar",
      "COLUMN_HEADER_ALLOWANCE": "Shpërblim",
      "COLUMN_HEADER_PAY": "Paguaj",
      "LABEL_DEDUCTIBLE_YES": "po",
      "LABEL_DEDUCTIBLE_NO": "Jo",
      "TEXT_GRAND_TOTAL": "Totali i madh"
    },
    "EMPLOYMENT_CHANGES": {
      "TITLE": "Ndryshimet e punës",
      "MODEL_APPOINTMENT": "Emërimi",
      "MODEL_PAY": "Paguaj",
      "MODEL_EMPLOYEE": "Punonjës",
      "PROPERTY_EMPLOYEE_FIRST_NAME": "Emri i parë",
      "PROPERTY_EMPLOYEE_LAST_NAME": "Mbiemri",
      "PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET": "Orët mesatare vendosen manualisht",
      "PROPERTY_APPOINTMENT_ENTITY_GROUP": "Faqja",
      "PROPERTY_APPOINTMENT_ENTITY": "ministri",
      "PROPERTY_APPOINTMENT_GROUP": "Grupi i stafit",
      "PROPERTY_APPOINTMENT_POSITION": "Roli i punës",
      "PROPERTY_APPOINTMENT_COMPANY": "Kompania",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE": "Detyrimi minimal",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT": "Detyrimi minimal (njësi)",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD": "Detyrimi minimal (periudha)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE": "Detyrimi maksimal",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT": "Detyrimi maksimal (njësi)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD": "Detyrimi maksimal (periudha)",
      "PROPERTY_APPOINTMENT_LINE_MANAGER": "Menaxher i linjës",
      "PROPERTY_APPOINTMENT_PAYROLL_CALENDAR": "Kalendari i pagave",
      "PROPERTY_PAY_AMOUNT": "Shuma",
      "PROPERTY_PAY_AMOUNT_TYPE": "Lloji i shumës",
      "PROPERTY_PAY_UPLIFT": "Ngritja e pagave",
      "FILTER_DATE": "datë",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_LAST_TWO_WEEKS": "Dy javët e fundit",
      "JUMP_TO_LAST_MONTH": "Muajin e kaluar",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_PROPERTY": "Pronë",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "DATE_ERROR_DIFF": "Ju nuk mund të zgjidhni më shumë se 12 muaj. Ju lutemi kufizoni intervalin tuaj të datave.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE": "Faqja",
      "COLUMN_DEPARTMENT": "ministri",
      "COLUMN_CHANGE": "Ndryshimi",
      "COLUMN_EFFECTIVE_DATE": "Efektive",
      "COLUMN_PROPERTY": "Pronë",
      "COLUMN_NEW_VALUE": "Vlera e re",
      "COLUMN_OLD_VALUE": "Vlera e vjetër",
      "COLUMN_EDITED_BY": "Ndryshuar nga",
      "COLUMN_EDITED_ON": "Data e ndryshimit"
    },
    "ANNIVERSARY": {
      "TITLE": "Përvjetorët",
      "FILTER_DATE": "datë",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_LAST_TWO_WEEKS": "Dy javët e fundit",
      "JUMP_TO_LAST_MONTH": "Muajin e kaluar",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_TYPE": "Përvjetori",
      "TYPE_BIRTHDAY": "Ditëlindja",
      "TYPE_EMPLOYMENT": "Përvjetori i punës",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "DATE_ERROR_DIFF": "Ju nuk mund të zgjidhni më shumë se 12 muaj. Ju lutemi kufizoni intervalin tuaj të datave.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE": "Faqja",
      "COLUMN_DEPARTMENT": "ministri",
      "COLUMN_TYPE": "Përvjetori",
      "COLUMN_YEARS": "Vite",
      "COLUMN_DATE": "datë"
    },
    "STAFF_TURNOVER": {
      "TITLE": "Qarkullimi i stafit",
      "FILTER_DATE": "datë",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_LAST_TWO_WEEKS": "Dy javët e fundit",
      "JUMP_TO_LAST_MONTH": "Muajin e kaluar",
      "FILTER_AGGREGATION": "Mbledhja",
      "AGGREGATION_ENTITY_GROUP": "Faqit/Departamenti",
      "AGGREGATION_POSITION": "Roli i punës",
      "AGGREGATION_STAFF_GROUP": "Grupi i stafit",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "DATE_ERROR_DIFF": "Ju nuk mund të zgjidhni më shumë se 12 muaj. Ju lutemi kufizoni intervalin tuaj të datave.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "COLUMN_AGGREGATION_LABEL": "Emri",
      "COLUMN_HEADCOUNT_START": "Numri i punëtorëve (fillim)",
      "COLUMN_HEADCOUNT_END": "Numri i punëtorëve (fund)",
      "COLUMN_JOINERS": "Bashkëpunëtorë",
      "COLUMN_REHIRES": "Ripunësime",
      "COLUMN_LEAVERS": "Lëshuesit",
      "COLUMN_TRANSFERS_IN": "Transferimet në",
      "COLUMN_TRANSFERS_OUT": "Transferimet jashtë",
      "COLUMN_ATTRITION_RATE": "Shkalla e shkarkimit",
      "COLUMN_AVERAGE_SERVICE": "Shërbimi mesatar",
      "LABEL_TOTAL": "Gjithsej",
      "LABEL_GRAND_TOTAL": "Totali i madh",
      "FILTER_LEAVER_REASON": "Arsyeja e lëvizjes"
    },
    "DOCUMENT_SIGNATURES": {
      "TITLE": "nënshkrimi i dokumenteve",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "INFO_NO_DATA": "Nuk ka regjistra që përputhen me filtrat tuaj.",
      "LABEL_METHOD_MANUAL": "Manual",
      "LABEL_METHOD_ELECTRONIC": "Elektronike",
      "LABEL_STATUS_PENDING": "Në pritje",
      "LABEL_STATUS_REJECTED": "Refuzuar",
      "LABEL_STATUS_SIGNED": "Nënshkruar",
      "LABEL_STATUS_CANCELLED": "Anuluar",
      "LABEL_RESPONSE_NONE": "Në pritje të përgjigjes",
      "LABEL_RESPONSE_REJECTED": "Refuzuar",
      "LABEL_RESPONSE_SIGNED": "Nënshkruar",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_RECIPIENT_USER": "Marrësi",
      "FILTER_ADDED_USER": "Dërguesi",
      "FILTER_METHOD": "Metoda e nënshkrimit",
      "FILTER_STATUS": "Statusi",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "COLUMN_RECIPIENT": "Marrësi",
      "COLUMN_EMPLOYER": "Palë kundërshtare",
      "COLUMN_DOCUMENT": "Dokumenti",
      "COLUMN_DOCUMENT_CATEGORY": "Kategoria e destinacionit",
      "COLUMN_METHOD": "Metoda",
      "COLUMN_STATUS": "Statusi",
      "COLUMN_ADDED_USER": "Dërguesi",
      "COLUMN_ADDED_DATE": "datë",
      "EMPLOYER_NOT_SET": "Nuk kërkohet",
      "VIEW_MODAL": {
        "TITLE": "Dokument për të nënshkruar",
        "LABEL_FILE": "Dokumenti",
        "LABEL_EXPIRY": "Skadimi i dokumentit",
        "LABEL_TEMPLATE": "Modeli i përdorur",
        "LABEL_CATEGORY": "Kategoria e destinacionit",
        "LABEL_ADDED_BY": "Shtuar nga",
        "LABEL_METHOD": "Metoda",
        "TEMPLATE_NOT_USED": "Asnjë",
        "EXPIRY_NOT_APPLICABLE": "Nuk zbatohet",
        "BUTTON_CLOSE": "Mbyll",
        "HEADING_SIGNERS": "Nënshkruesit",
        "EMPLOYEE": "Punonjës",
        "EMPLOYER": "Punëdhënësi",
        "COLUMN_NAME": "Emri",
        "COLUMN_ROLE": "Roli",
        "COLUMN_STATUS": "Statusi",
        "COLUMN_DATE": "datë",
        "LABEL_OPTIONS": "Opsionet",
        "OPTION_REMINDER": "Dërgo një kujtesë",
        "OPTION_CANCEL": "Anuloni dokumentin",
        "OPTION_UPLOAD": "Ngarko dokumentin e nënshkruar",
        "CANCEL_ERROR_500": "Nuk mund ta anulonim dokumentin vetëm atëherë.",
        "CANCEL_SUCCESS": "Dokumenti është anuluar.",
        "REMINDER_ERROR_500": "Nuk mund të dërgojmë një kujtesë vetëm atëherë.",
        "REMINDER_ERROR_400": "Një kujtesë tashmë është dërguar kohët e fundit. Ju lutemi prisni pak kohë para se të dërgoni një kujtesë tjetër.",
        "REMINDER_SUCCESS": "E kuptova! Ne do të dërgojmë një kujtesë me email tani.",
        "DOWNLOAD_ERROR_500": "Nuk mund ta shkarkojmë skedarin vetëm atëherë. Provojeni përsëri ose kontaktoni nëse kjo vazhdon.",
        "LABEL_DOWNLOAD": "Shkarko",
        "OPTION_DOWNLOAD_ORIGINAL": "Shkarko origjinalin",
        "OPTION_DOWNLOAD_SIGNED_COPY": "Shkarko kopjen e nënshkruar"
      }
    },
    "WORKING_TIME_VIOLATIONS": {
      "TITLE": "Shkeljet e kohës së punës",
      "FILTER_DATE": "datë",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_LAST_TWO_WEEKS": "Dy javët e fundit",
      "JUMP_TO_LAST_MONTH": "Muajin e kaluar",
      "FILTER_SITE": "Faqit/Departamenti",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "COLUMN_SITE_DEPARTMENT": "Faqit/Departamenti",
      "COLUMN_VIOLATION_HOURS": "Orari i punës",
      "COLUMN_VIOLATION_HOURS_TOOLTIP": "Numri i ngjarjeve ku një punonjës tejkaloi 48 orë pune (mesatarisht mbi 17 javë).",
      "COLUMN_VIOLATION_DAYS_OFF": "Ditët e pushimit",
      "COLUMN_VIOLATION_DAYS_OFF_TOOLTIP": "Numri i ngjarjeve ku një punonjës kishte më pak se 2 ditë pushim (në një periudhë 2 javore).",
      "COLUMN_VIOLATION_REST": "Pushim",
      "COLUMN_VIOLATION_REST_TOOLTIP": "Numri i ngjarjeve ku një punonjës kishte më pak se 11 orë pushim pas një dite pune.",
      "COLUMN_VIOLATION_U18_DAYS_OFF": "U18: Ditë pushimi",
      "COLUMN_VIOLATION_U18_DAYS_OFF_TOOLTIP": "Numri i ngjarjeve ku një punonjës nën moshën 18 vjeç kishte më pak se 2 ditë pushimi rresht (në një javë të caktuar).",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH": "U18: Ndërrim i gjatë",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH_TOOLTIP": "Numri i ngjarjeve ku një punonjës nën moshën 18 vjeç kishte një ndërrim më të gjatë se 8 orë.",
      "COLUMN_VIOLATION_U18_REST": "U18: Pushim",
      "COLUMN_VIOLATION_U18_REST_TOOLTIP": "Numri i ngjarjeve ku një punonjës nën moshën 18 vjeç kishte më pak se 11 orë pushim pas një dite pune.",
      "COLUMN_TOTAL": "Gjithsej",
      "ROW_TOTAL": "Gjithsej",
      "ROW_GRAND_TOTAL": "Totali i madh",
      "INFO_NO_FILTER": "Zgjidhni një faqe/departament më lart për të filluar.",
      "DATE_ERROR_DIFF": "Ju nuk mund të zgjidhni më shumë se 12 muaj. Ju lutemi kufizoni intervalin tuaj të datave.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "VIOLATION_TYPE_1_DESC": "Nuk mund të tejkalojë mesataren e 48 orëve pune gjatë 17 javëve",
      "VIOLATION_TYPE_2_DESC": "Nuk mund të ketë më pak se 2 ditë pushim në ndonjë periudhë 2 javore",
      "VIOLATION_TYPE_3_DESC": "Nën 18 vjeç: Duhet të ketë 2 ditë pushim rresht në javë",
      "VIOLATION_TYPE_4_DESC": "Nën 18 vjeç: Ndërrimi më gjatë se 8 orë",
      "VIOLATION_TYPE_5_DESC": "Nën 18 vjeç: Më pak se 12 orë pushim pas ditës së punës",
      "VIOLATION_TYPE_6_DESC": "Më pak se 11 orë pushim pas ditës së punës",
      "VIOLATION_TYPE_1": "Shumë orë pune (mesatarisht)",
      "VIOLATION_TYPE_2": "Ditët e pamjaftueshme",
      "VIOLATION_TYPE_3": "Nën 18: Ditë pushimi të pamjaftueshme",
      "VIOLATION_TYPE_4": "Nën 18 vjeç: Ndërrimi shumë i gjatë",
      "VIOLATION_TYPE_5": "Nën 18: Pushim i pamjaftueshëm midis ndërrimeve",
      "VIOLATION_TYPE_6": "Pushim i pamjaftueshëm ndërmjet",
      "FILTER_VIOLATION_TYPE": "Lloji i shkeljes",
      "FILTER_PER_PAGE": "Rreshta për faqe",
      "DETAIL_COLUMN_ID": "KARTË IDENTITETI",
      "DETAIL_COLUMN_HR_ID": "ID HR",
      "DETAIL_COLUMN_PAYROLL_ID": "ID e pagës",
      "DETAIL_COLUMN_FIRST_NAME": "Emri i parë",
      "DETAIL_COLUMN_LAST_NAME": "Mbiemri",
      "DETAIL_COLUMN_SITE": "Faqja",
      "DETAIL_COLUMN_DEPARTMENT": "ministri",
      "DETAIL_COLUMN_VIOLATION": "Shkelje",
      "DETAIL_COLUMN_DATE_FROM": "Data nga",
      "DETAIL_COLUMN_DATE_TO": "Data deri në",
      "DETAIL_COLUMN_VALUE": "Vlera",
      "DETAIL_LABEL_WORKING_HOURS": "orët e punës",
      "DETAIL_LABEL_WORKING_DAYS": "ditët e punës",
      "DETAIL_LABEL_HOURS_REST": "orë pushim",
      "DETAIL_INFO_NO_RECORDS": "Ju nuk keni shkelje të Kohës së Punës, puna kryesore!",
      "DETAIL_INFO_EMPTY_FILTER_RESULT": "Nuk ka shkelje që përputhen me filtrin (et) tuaj."
    },
    "CONTRACTUAL_OBLIGATION_SUMMARY": {
      "TITLE": "Përmbledhje e detyrimit",
      "FILTER_DATE": "datë",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_LAST_TWO_WEEKS": "Dy javët e fundit",
      "JUMP_TO_LAST_MONTH": "Muajin e kaluar",
      "FILTER_SITE": "Faqit/Departamenti",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "FILTER_DATA_SOURCE": "Burimi i të dhënave",
      "FILTER_USER": "Person",
      "COLUMN_GROUP_TOTAL": "Totali (paguar)",
      "COLUMN_GROUP_OBLIGATION": "Detyrimi kontraktual",
      "COLUMN_GROUP_ANALYSIS_META": "Analizuar mbi",
      "COLUMN_GROUP_ANALYSIS": "Analizë",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE": "Faqja",
      "COLUMN_DEPARTMENT": "ministri",
      "COLUMN_DAYS_WORKED": "Ditët",
      "COLUMN_PAID_HOURS": "Orë",
      "COLUMN_AVERAGE_HOURS": "Mesatare",
      "COLUMN_AVERAGE_HOURS_TOOLTIP": "Orët mesatare të punës në ditë.",
      "COLUMN_OBLIGATION_VALUE": "Vlera",
      "COLUMN_OBLIGATION_UNIT": "Njësia",
      "COLUMN_OBLIGATION_PERIOD": "Periudha",
      "COLUMN_ANALYSIS_START": "Filloni",
      "COLUMN_ANALYSIS_END": "Fundi",
      "COLUMN_ANALYSIS_PERIODS": "# Periudhat",
      "COLUMN_ANALYSIS_PERIODS_TOOLTIP": "Numri i javëve ose muajve që janë analizuar.",
      "COLUMN_ANALYSIS_AVERAGE": "Mesatare",
      "COLUMN_ANALYSIS_AVERAGE_TOOLTIP": "Numri mesatar i orëve ose ditëve të punuara.",
      "COLUMN_ANALYSIS_DIFFERENCE": "Ndryshimi.",
      "COLUMN_ANALYSIS_DIFFERENCE_TOOLTIP": "Dallimi midis orëve/ditëve minimale që duhet të ishin punuar (detyrimi kontraktual shumëzuar me numrin e periudhave) dhe asaj që është punuar në të vërtetë (orët ose ditët totale).",
      "INFO_NO_DATA": "Nuk ka regjistra që përputhen me filtrat tuaj.",
      "INFO_NO_FILTER": "Zgjidhni një faqe/departament më lart për të filluar.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tarifa sipas orës",
      "PAY_AMOUNT_TYPE_DAILY": "Norma ditore",
      "PAY_AMOUNT_TYPE_ANNUAL": "Paga vjetore",
      "DATA_SOURCE_SHIFTS": "Ndryshimet",
      "DATA_SOURCE_ATTENDANCE": "Pjesëmarrja",
      "DATA_SOURCE_MATCHED": "Sanitizuar",
      "CONTRACTUAL_UNIT_HOURS": "Orë",
      "CONTRACTUAL_UNIT_DAYS": "Ditët",
      "CONTRACTUAL_PERIOD_WEEK": "javë",
      "CONTRACTUAL_PERIOD_MONTH": "Muaji",
      "CONTRACTUAL_PERIOD_WEEKLY": "javore",
      "CONTRACTUAL_PERIOD_MONTHLY": "mujore",
      "DATE_ERROR_DIFF": "Ju nuk mund të zgjidhni më shumë se 14 javë. Ju lutemi kufizoni intervalin tuaj të datave.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri."
    },
    "LABOUR_BREAKDOWN": {
      "TITLE": "Ndarja e punës",
      "FILE_EXPORT_TITLE": "Eksporti i skedarit të ndarjes së punës",
      "FILTER_DATE": "datë",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_LAST_TWO_WEEKS": "Dy javët e fundit",
      "JUMP_TO_LAST_MONTH": "Muajin e kaluar",
      "FILTER_SITE": "Faqit/Departamenti",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "FILTER_DATA_SOURCE": "Burimi i të dhënave",
      "FILTER_USER": "Person",
      "FILTER_BEHAVIOUR": "Sjellja",
      "BEHAVIOUR_COST_CONTROL": "Kontrolli i kostos",
      "BEHAVIOUR_PAYROLL": "Pagat e listës",
      "FILTER_SHIFT_TYPE": "Lloji i ndërrimit",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE_APPOINTMENT": "Faqja (emërimi)",
      "COLUMN_DEPT_APPOINTMENT": "Departamenti (emërimi)",
      "COLUMN_EVENT_TYPE": "Lloji i ngjarjes",
      "COLUMN_SHIFT_TYPE": "Lloji i ndërrimit",
      "COLUMN_SITE_ATTRIBUTION": "Faqja (atribuimi)",
      "COLUMN_DEPT_ATTRIBUTION": "Departamenti (atribuimi)",
      "COLUMN_START_TIME": "Filloni",
      "COLUMN_END_TIME": "Përfundoj",
      "COLUMN_PAID_HOURS": "Orë të paguara",
      "COLUMN_UNPAID_HOURS": "Orë të papaguara",
      "COLUMN_BASE_PAY": "Pagesa bazë",
      "COLUMN_ROLLED_UP_HOLIDAY": "Pushime të përllogaritur",
      "COLUMN_TAXES": "Taksat",
      "COLUMN_WAGE_UPLIFT": "Ngritja e pagave",
      "COLUMN_TOTAL_COST": "Kostoja totale",
      "INFO_NO_DATA": "Nuk ka regjistra që përputhen me filtrat tuaj.",
      "INFO_NO_FILTER": "Zgjidhni një faqe/departament më lart për të filluar.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tarifa sipas orës",
      "PAY_AMOUNT_TYPE_DAILY": "Norma ditore",
      "PAY_AMOUNT_TYPE_ANNUAL": "Paga vjetore",
      "EVENT_TYPE_SHIFT": "Ndryshimi",
      "EVENT_TYPE_ABSENCE": "Mungesa",
      "EVENT_TYPE_SALARY": "Paga",
      "EVENT_TYPE_TAXES": "Taksat",
      "DATA_SOURCE_SHIFTS": "Ndryshimet",
      "DATA_SOURCE_ATTENDANCE": "Pjesëmarrja",
      "DATA_SOURCE_MATCHED": "Sanitizuar",
      "DATE_ERROR_DIFF": "Ju nuk mund të zgjidhni më shumë se 14 javë. Ju lutemi kufizoni intervalin tuaj të datave.",
      "DATE_WARNING_WEEKS": "Ju keni zgjedhur një interval datash që nuk përputhet me javët kalendarike. Disa taksa të punëdhënësve llogariten duke përdorur pragjet javore, kështu që taksat e shfaqura këtu mund të jenë artificialisht më të ulëta se realiteti. Pagesa bazë dhe pushimet e përllogaritura mbeten të sakta, por ju lutemi rregulloni datat tuaja me javët kalendarike për taksat e sakta dhe koston totale.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "ERROR_400": "Parametrat tuaj shkaktojnë që të gjenerohen shumë të dhëna për një raport të vetëm. Ju lutemi zvogëloni fushëveprimin e kërkesës tuaj dhe provoni përsëri."
    },
    "LABOUR_BREAKDOWN_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Gjeneroni dhe dërgoni email raportin.",
      "REPORT_GENERATED": "Raporti juaj po gjenerohet dhe do t'ju dërgohet me email kur të përfundojë."
    },
    "PAYROLL_EXPORT": {
      "TITLE": "Eksporti i pagave",
      "FILTER_DATE": "datë",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_LAST_TWO_WEEKS": "Dy javët e fundit",
      "JUMP_TO_LAST_MONTH": "Muajin e kaluar",
      "FILTER_SITE": "Faqit/Departamenti",
      "EXPORT": "Eksporti",
      "EXPORT_PAYROLL_SOFTWARE": "Softueri i pagave",
      "EXPORT_PRINT": "Printoni",
      "FILTER_AGGREGATION": "Segmentimi",
      "FILTER_ABSENCE_TYPES": "Llojet e mungesës",
      "FILTER_PAY_ELEMENT_TYPES": "Llojet e elementeve të pagesës",
      "FILTER_USER": "Person",
      "FILTER_COMPANY": "Kompania",
      "TOGGLE_SHOW_ROLLED_UP_HOLIDAY": "Trego pushimet e rrotulluara",
      "COLUMN_ID": "KARTË IDENTITETI",
      "COLUMN_HR_ID": "ID HR",
      "COLUMN_PAYROLL_ID": "ID e pagës",
      "COLUMN_FIRST_NAME": "Emri i parë",
      "COLUMN_LAST_NAME": "Mbiemri",
      "COLUMN_SITE_ATTRIBUTION": "Faqja (atribuimi)",
      "COLUMN_DEPT_ATTRIBUTION": "Departamenti (atribuimi)",
      "COLUMN_SITE_APPOINTMENT": "Faqja (emërimi)",
      "COLUMN_DEPT_APPOINTMENT": "Departamenti (emërimi)",
      "COLUMN_GROUP": "Grupi",
      "COLUMN_POSITION": "Roli i punës",
      "COLUMN_DAYS_WORKED": "Ditët e punuara",
      "COLUMN_PAY_RATE": "Norma e pagesës",
      "COLUMN_AMOUNT_TYPE": "Lloji i shumës",
      "COLUMN_PAY_TYPE": "Lloji i pagesës",
      "COLUMN_PAID_SHIFT_HOURS": "Orët e këmbimit të paguar",
      "COLUMN_UNPAID_SHIFT_HOURS": "Orët e papaguar të ndërri",
      "COLUMN_BASE_PAY": "Pagesa bazë",
      "COLUMN_ROLLED_UP_HOLIDAY": "Pushime të rrotulluara",
      "COLUMN_ABSENCE_DAYS": "{{label}} ditë",
      "COLUMN_ABSENCE_HOURS": "{{label}} orë",
      "INFO_NO_DATA": "Nuk ka regjistra që përputhen me filtrat tuaj. A keni regjistruar? Mos harroni, ky raport përdor vetëm të dhëna të regjistruara.",
      "INFO_NO_FILTER": "Zgjidhni një faqe/departament më lart për të filluar.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tarifa sipas orës",
      "PAY_AMOUNT_TYPE_DAILY": "Norma ditore",
      "PAY_AMOUNT_TYPE_ANNUAL": "Paga vjetore",
      "AGGREGATION_OPTION_ATTRIBUTION": "Siti/departamentit në të cilin u atribuohen orët",
      "AGGREGATION_OPTION_APPOINTMENT": "Faqit/departamenti në të cilin ishte vendosur personi në atë kohë",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "ABSENCE_TYPE_DELETED_SUFFIX": "fshirë",
      "DATE_ERROR_MIN": "Ju lutemi zgjidhni një datë pas 12 dhjetorit 2018.",
      "DATE_ERROR_DIFF": "Ju nuk mund të zgjidhni më shumë se gjashtë muaj. Ju lutemi kufizoni intervalin tuaj të datave.",
      "EXPORT_MODAL": {
        "TITLE": "Eksporti në Softuerin e Pagave",
        "LABEL_EXPORT_TO": "Eksporti në",
        "BUTTON_DOWNLOAD": "Shkarko",
        "BUTTON_CLOSE": "Mbyll",
        "SUCCESS": "Eksporti juaj është shkarkuar me sukses.",
        "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri."
      },
      "SIGN_OFF_STATUS_COMPLETE": "Nënshkruar plotësisht",
      "SIGN_OFF_STATUS_NONE": "Nuk është nënshkruar",
      "SIGN_OFF_STATUS_PARTIAL": "Nënshkruar pjesërisht",
      "SIGN_OFF_STATUS_DRAWER": {
        "TITLE": "Statusi i fikjes",
        "BACK_TO_DATES": "Kthehu në datat"
      },
      "FILTER_PAYROLL_CALENDAR": "Kalendari i pagave",
      "FILTER_PAY_RUN": "Vendosja e pagave"
    },
    "ROTA_PROGRESS": {
      "TITLE": "Rrota Progress",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_NEXT_WEEK": "Javën e ardhshme",
      "FILTER_SITE": "Faqit/Departamenti",
      "LIFECYCLE_DRAFT": "Draft",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Shënuar për Miratim",
      "LIFECYCLE_PUBLISHED": "Publikuar",
      "INCLUDE_NO_DRAFT": "Shfaq faqet pa draft",
      "NO_DRAFT": "Asnjë draft",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "COLUMN_SITE": "Faqit/Departamenti",
      "COLUMN_LIFECYCLE": "Statusi",
      "COLUMN_SCHEDULED_HOURS": "Orët e planifikuara",
      "COLUMN_SCHEDULED_WAGES": "Pagat e planifikuara",
      "COLUMN_FORECAST_SALES": "Parashikimi i shitjeve",
      "COLUMN_WAGE_PERCENTAGE": "Paga%",
      "ROW_GRAND_TOTAL": "Totali i madh",
      "TOOLTIP_BUDGET": "Buxheti",
      "INFO_NO_DATA": "Nuk ka faqe/departamente që përputhen me filtrat tuaj.",
      "INFO_NO_FILTER": "Zgjidhni një faqe/departament më lart për të filluar.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri."
    },
    "SIGN_OFF_PROGRESS": {
      "TITLE": "Progresi i nënshkrimit",
      "JUMP_TO": "Hidheni në",
      "JUMP_TO_LAST_WEEK": "Javën e kaluar",
      "JUMP_TO_THIS_WEEK": "Këtë javë",
      "JUMP_TO_NEXT_WEEK": "Javën e ardhshme",
      "FILTER_SITE": "Faqit/Departamenti",
      "FILTER_STATUS": "Statusi i nënshkrimit",
      "STATUS_NO_ROTA": "Asnjë rrotullim",
      "STATUS_INCOMPLETE": "I paplotë",
      "STATUS_REPORTING_DATE": "Data e raportimit",
      "STATUS_COMPLETE": "I plotë",
      "STATUS_TOOLTIP_NO_ROTA": "Nuk ka asnjë rota të publikuar këtë javë.",
      "STATUS_TOOLTIP_INCOMPLETE": "Nënshkrimi nuk është përfunduar për çdo ditë këtë javë.",
      "STATUS_TOOLTIP_REPORTING_DATE": "Nënshkrimi ka përfunduar për ditët deri në dhe duke përfshirë datën tuaj të raportimit.",
      "STATUS_TOOLTIP_COMPLETE": "Nënshkrimi është përfunduar për çdo ditë këtë javë.",
      "TOGGLE_COMPARAND": "Krahasoni",
      "TOGGLE_COMPARAND_FORECAST": "Parashikimi",
      "TOGGLE_COMPARAND_BUDGET": "Buxheti",
      "EXPORT": "Eksporti",
      "EXPORT_PRINT": "Printoni",
      "COLUMN_WAGES": "Pagat",
      "COLUMN_SALES": "Shitjet",
      "COLUMN_WAGE_PERCENTAGE": "Paga%",
      "COLUMN_HOURS": "Orë",
      "COLUMN_SITE": "Faqit/Departamenti",
      "COLUMN_STATUS": "Statusi",
      "COLUMN_FORECAST": "Parashikimi",
      "COLUMN_BUDGET": "Buxheti",
      "COLUMN_ACTUAL": "Aktualisht",
      "ROW_GRAND_TOTAL": "Totali i madh",
      "INFO_NO_DATA": "Nuk ka faqe/departamente që përputhen me filtrat tuaj.",
      "INFO_NO_FILTER": "Zgjidhni një faqe/departament më lart për të filluar.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë raportin tuaj vetëm atëherë. Ju lutemi provoni përsëri.",
      "STATUS_MODAL": {
        "REVERSE_CONFIRM": "A jeni i sigurt që dëshironi ta ktheheni këtë shenjë?\n\nKjo do të zhbllokojë ndërrimet dhe regjistrat e pjesëmarrjes në këto data (e) në mënyrë që të mund të bëhen ndryshime.",
        "REVERSE_SUCCESS": "Nënshkrimi është kthyer me sukses.",
        "REVERSE_ERROR": "Nuk mund ta ktheheshim nënshkrimin vetëm atëherë. Ju lutemi provoni përsëri.",
        "TITLE": "Statusi i nënshkrimit",
        "COLUMN_DATE": "datë",
        "COLUMN_STATUS": "Statusi",
        "COLUMN_OPTIONS": "Opsionet",
        "STATUS_NO_SIGN_OFF": "Nuk ka nënshkrim",
        "STATUS_SIGN_OFF": "Regjistruar",
        "BUTTON_REVERSE_DAY": "I kundërt",
        "BUTTON_REVERSE_WEEK": "Përkundrazi tërë javën",
        "BUTTON_CLOSE": "Mbyll"
      }
    },
    "TRONC_STATUS": {
      "TITLE": "Statusi i Tronc",
      "LIFECYCLE_DRAFT": "Draft",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Shënuar për Miratim",
      "LIFECYCLE_LOCKED": "I mbyllur",
      "INCLUDE_NO_HEADER": "Shfaq faqet pa draft",
      "NO_HEADER": "Asnjë draft",
      "COLUMN_SITE": "Faqit/Departamenti",
      "COLUMN_LIFECYCLE": "Statusi",
      "COLUMN_RECEIVED": "Gjithsej i pranuar",
      "COLUMN_PAID_OUT": "Totali i paguar",
      "COLUMN_EDITED_WHO": "Redaktuar nga",
      "COLUMN_EDITED_WHEN": "Kur",
      "INFO_NO_DATA": "Nuk ka faqe/departamente Tronc që përputhen me filtrat tuaj.",
      "ERROR_500": "Nuk ishim në gjendje të ngarkojmë Tronc atëherë. Ju lutemi provoni përsëri."
    },
    "CURRENTLY_CLOCKED_IN": {
      "TITLE": "Njerëzit aktualisht të hyjnë",
      "NAME": "Emri",
      "SITE": "Faqit/Departamenti",
      "POSITION": "Roli i punës",
      "CLOCK_IN_TIME": "Koha e hyrjes së orës",
      "NOBODY_CLOCKED_IN": "Askush nuk ka hyrë në orë për momentin!",
      "ERROR_500": "Nuk ishim në gjendje ta ngarkojmë raportin vetëm atëherë. Ju lutemi provoni përsëri."
    },
    "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Gjenero dhe dërgo raportin me email.",
      "REPORT_GENERATED": "Raporti juaj po gjenerohet dhe do t'ju dërgohet me email kur të përfundojë.",
      "FILTER_DATE": "Data",
      "FILTER_ENTITY": "Situs/departamenti",
      "FILTER_PAY_TYPES": "Lloje pagesash",
      "FILTER_DATA_SOURCE": "Burimi i të dhënave",
      "FILTER_USER": "Person",
      "FILTER_EVENT_TYPES": "Llojet e ngjarjeve",
      "FILTER_BEHAVIOUR": "Djegësi",
      "FILTER_SHIFT_TYPE": "Lloji i ndryshimit",
    },
    "PAYROLL_EXPORT_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Gjenero dhe dërgo raportin me email.",
      "REPORT_GENERATED": "Raporti juaj po gjenerohet dhe do t'ju dërgohet me email kur të përfundojë.",
      "FILTER_PAYROLL_CALENDAR": "Kalendar pagese",
      "FILTER_PAY_RUN": "Ekzekutim pagese",
      "FILTER_DATE": "Data",
      "FILTER_ENTITY": "Situs/departamenti",
      "FILTER_AGGREGATION_OPTIONS": "Segmentimi",
      "FILTER_USER": "Person",
      "FILTER_ABSENCE_TYPES": "Llojet e mungesës",
      "FILTER_PAY_ELEMENT_TYPES": "Llojet e elementeve pagesë",
      "FILTER_PAY_AMOUNT_TYPE": "Lloji i sasisë së pagesës",
      "FILTER_ROLLED_UP_HOLIDAY": "Festat e përmbledhura",
      "FILTER_COMPANY": "Kompania",
    },
  },
  "ROTA_MANAGEMENT": {
    "DASHBOARD": {
      "INDEX": {
        "TITLE": "Paneli",
        "CHECKLIST": "Lista e kontrollit të Rota",
        "LABOUR_SPEND": "Shpenzimet e punës"
      },
      "LABOUR_SPEND_TAB": {
        "TITLE": "Përmbledhje e punës",
        "SHOW_ACTUAL_TIP": "Për ditët e kësaj jave që ende nuk kanë kaluar, projektoni shitjet e parashikuara dhe pagat/orët e planifikuara në totalet aktuale",
        "SHOW_ACTUAL_TEXT": "Parashikimi i projektit në të vërtetë",
        "FORECAST": "Parashikimi",
        "ACTUAL": "Aktualisht",
        "BUDGET": "Buxheti",
        "SALES": "Shitjet",
        "WAGES": "Pagat",
        "HOURS": "Orë",
        "WAGE_PERCENT": "Paga%",
        "LABOUR_COMP": "Krahasimi i punës",
        "ACTUAL_PROJECTED": "Aktual (i parashikuar)",
        "TODAYS_VS_SCHEDULED": "Pjesëmarrja e sotme vs planifikuar"
      },
      "WTD_VIOLATIONS_MODAL": {
        "TITLE": "Shkeljet e kohës së punës",
        "BODY": "Këto janë rastet kur kemi gjetur se oraret tuaja po shkelin rregulloret e kohës së punës.",
        "TO": "të ",
        "ALL": "Të gjitha shkeljet",
        "TOO_MANY": "Shumë orë pune (mesatarisht)",
        "INSUF_DAYS": "Ditët e pamjaftueshme",
        "INSUF_REST": "Pushim i pamjaftueshëm ndërmjet",
        "UNDER_18_DAYS": "Nën 18: Ditë pushimi të pamjaftueshme",
        "UNDER_18_LONG": "Nën 18 vjeç: Ndërrimi shumë i gjatë",
        "UNDER_18_REST": "Nën 18: Pushim i pamjaftueshëm midis ndërrimeve",
        "STAFF_MEMBER": "Anëtar i stafit",
        "VIOLATION": "Shkelje",
        "VALUE": "Vlera",
        "NO_VIOLATIONS": "Ju nuk keni shkelje të Kohës së Punës, puna kryesore!",
        "NO_FILTER_VIOLATIONS": "Nuk ka shkelje që përputhen me filtrin (et) tuaj.",
        "AVE_48_OVER_17": "Nuk mund të tejkalojë mesataren e 48 orëve pune gjatë 17 javëve",
        "LESS_2_DAYS_2_WEEKS": "Nuk mund të ketë më pak se 2 ditë pushim në ndonjë periudhë 2 javore",
        "UNDER_18_2_PER_WEEK": "Nën 18 vjeç: Duhet të ketë 2 ditë pushim rresht në javë",
        "UNDER_18_LONGER_8": "Nën 18 vjeç: Ndërrimi më gjatë se 8 orë",
        "UNDER_18_LESS_12_REST": "Nën 18 vjeç: Më pak se 12 orë pushim pas ditës së punës",
        "LESS_11_WEEK": "Më pak se 11 orë pushim pas ditës së punës",
        "WORKING_HOURS": " orët e punës",
        "WORKING_DAYS": " ditët e punës",
        "HOURS_REST": " orë pushim",
        "RECALC": "Ri-llogaritni",
        "LOADING": "Ngarkohet...",
        "CLOSE": "Mbyll"
      }
    },
    "LOCATIONS": {
      "ADD_LOCATION_MODAL": {
        "NEW_SHIFT_LOCATION": "Zona e re e frekuencës",
        "TYPE": "Lloji",
        "NEW_LOCATION": "Zona e re",
        "CHANGE_EXISTING_LOCATION": "Ndërroni zonën ekzistuese",
        "EXISTING_LOCATION": "Zona ekzistuese",
        "NAME": "Emri",
        "DETAILS": "Detajet",
        "HELP": "Rotaready kujton pozicionin e saktë të shënuesit në hartë, kështu që këto detaje janë vetëm për referencën tuaj. Mos ngurroni të përfshini disa shënime ose një adresë të plotë.",
        "SAVING": "Kursimi...",
        "SAVE": "Ruaj",
        "CANCEL": "Anuloni",
        "ERROR_ENTER_NAME": "Ju lutemi shkruani një emër për këtë zonë.",
        "ERROR_UPDATE": "Aktualisht nuk mund të përditësohet kjo zonë. Ju lutemi provoni përsëri.",
        "ERROR_ADD": "Aktualisht nuk mund të shtojmë këtë zonë. Ju lutemi provoni përsëri."
      },
      "EDIT_LOCATION_MODAL": {
        "EDIT_LOCATION": "Redakto zonën",
        "HIERARCHY": "Hierarkia",
        "MASTER_LOCATION": "Zona master",
        "SUB_LOCATION": "Nën-zonë",
        "HELP_HIERARCHY": "Rotaready ju lejon të krijoni një hierarki të zonave. Një nënzonë mund të vendoset nën çdo zonë tjetër.",
        "PARENT_LOCATION": "Zona prindër",
        "NAME": "Emri",
        "DETAILS": "Detajet",
        "HELP_DETAILS": "Rotaready kujton pozicionin e saktë të shënuesit në hartë, kështu që këto detaje janë vetëm për referencën tuaj. Mos ngurroni të përfshini disa shënime ose një adresë të plotë.",
        "ATTENDANCE": "Pjesëmarrja",
        "ALLOW_CLOCKING": "Lejoni stafin të hyjë dhe të dalë nga ora këtu",
        "STAFF_WORKING": "Vetëm kur stafi është duke punuar në një ndërrim të shënuar me këtë zonë",
        "HELP_CLOCKING": "Këto cilësime ndikojnë nëse stafi mund të hyjë dhe të dalë nga ora duke përdorur aplikacionin celular Rotaready.",
        "ATTENDANCE_SENSITIVITY": "Ndjeshmëria e pjes",
        "METRES": "metra",
        "HELP_CLOSE_LOCATION": "Kjo përcakton se sa afër duhet të jetë dikush me zonën përpara se aplikacioni celular Rotaready t'i lejojë ata të hyjnë ose të dalin. Më pak se 50 metra nuk rekomandohet pasi njerëzit me sinjal të dobët nuk do të jenë në gjendje të hyjnë në orë.",
        "MIN_OCCUPANCY": "Minimumi i zëvendësimit",
        "HELP_MIN_OCCUPANCY": "Sa herë që dikush është në ndërrim, Rotaready do t'ju paralajmërojë nëse numri i përgjithshëm i njerëzve gjithashtu në ndërrim bie nën këtë numër.",
        "MAX_OCCUPANCY": "Maksimumi i zëvendësimit",
        "HELP_MAX_OCCUPANCY": "Nëse sigurohet, Rotaready nuk do t'ju lejojë të caktoni më shumë staf këtu (në çdo kohë) sesa zëvendësimi maksimal.",
        "TIP_OPENING": "Specifikoni kohën e hapjes që Rotaready t'ju parandalojë të planifikoni ndërrimet në këtë zonë kur është e mbyllur.",
        "TIP_SUB_LOC": "Meqenëse kjo është një nënzonë, nëse zona e saj mëmë është e mbyllur në çdo kohë, kjo nënzonë do të konsiderohet e mbyllur gjithashtu.",
        "REGULAR_OPENING_TIMES": "Kohët e rregullta të hapjes",
        "OPEN_247": "Kjo zonë është e hapur 24/7.",
        "TO": "të",
        "SPECIAL_PERIODS": "Periudha të veçanta",
        "NO_SPECIAL_PERIODS": "Nuk ka periudha të veçanta.",
        "OPEN": "Hapur",
        "CLOSED": "Mbyllur",
        "ADD_SPECIAL_PERIOD": "Shto periudhë të veçantë",
        "REASON": "Arsyeja",
        "REASON_PLACEHOLDER": "p.sh. Rinovimi i verës",
        "FROM": "Nga",
        "TO_CAPS": "Për",
        "ADD": "Shto",
        "DELETE": "Fshini",
        "SAVING": "Kursimi...",
        "SAVE": "Ruaj",
        "CANCEL": "Anuloni",
        "ALERT_NAME": "Ju lutemi sigurohuni që të keni dhënë një emër të vlefshëm për zonën e pjesëmarrjes.",
        "ALERT_PARENT": "Që kjo të jetë një nën-zonë, duhet të zgjidhni një zonë prind.",
        "ALERT_VALID_PARENT": "Ju lutemi zgjidhni një zonë prindër të vlefshme.",
        "ALERT_PARENT_LOCATION": "Kjo zonë e pjesëmarrjes dhe zona prindër juaj e zgjedhur duhet të përkasin të njëjtit Faqe (ose të dyja të jenë në të gjithë Organizatën).",
        "ALERT_MINIMUM": "Ju nuk mund të keni një nivel minimal të zëvendësimit më të lartë se niveli maksimal i zënimit.",
        "ERROR_UPDATE": "Nuk mund ta përditësojmë zonën vetëm atëherë. Ju lutemi provoni përsëri.",
        "ALERT_SUB_CONFIRM": "Kjo zonë ka ende nën-zona. Edhe ato do të fshihen.\n\nA jeni i sigurt që dëshironi të vazhdoni?",
        "ALERT_CONFIRM": "A jeni i sigurt që dëshironi ta fshini këtë zonë?",
        "ERROR_DELETE": "Ne nuk ishim në gjendje të fshijmë zonën vetëm atëherë. Ju lutemi provoni përsëri!"
      },
      "INDEX": {
        "SHIFT_LOCATIONS": "Zonat e pjesëmarrjes",
        "TIP_FIND": "Për të shtuar një zonë të re, ose për të ndryshuar një zonë ekzistuese, së pari duhet ta gjeni atë me hartën. Kërkoni për ndonjë adresë ose kod postar, pastaj tërhiqni shënuesin e gjelbër për ta ripozicionuar atë.",
        "PLACEHOLDER_SEARCH": "Kërkoni për të shtuar një zonë të re",
        "HELP_NO_LOCATIONS": "Ju nuk keni zona ekzistuese. Kërkoni në hartë për të shtuar një.",
        "HELP_NO_RESULTS": "Asnjë zonë pjesëmarrjeje nuk përputhet me termat tuaja të kër",
        "TOOLTIP_SHOW": "Trego në hartë",
        "TOOLTIP_CLOCKING_ALWAYS": "Stafi gjithmonë mund të hyj/dalë me aplikacionin celular Rotaready këtu",
        "TOOLTIP_CLOCKING_WORKING": "Stafi mund të hyj/dalë me aplikacionin celular Rotaready këtu, vetëm nëse ata janë duke punuar në një ndërrim këtu",
        "ERROR_LOAD": "Ne nuk ishim në gjendje të ngarkojmë zonat tuaja të frekuencës. Ju lutemi provoni përsëri.",
        "NO_ADDRESS": "Nuk ka detaje/adresë",
        "ERROR_LOOKUP": "Ne nuk dështuam të kërkojmë vendndodhjen e këtij shënuesi.",
        "UNKNOWN_ADDRESS": "Adresa e panjohur",
        "ADD_AS_NEW": "Shto si zonë të re",
        "DRAG_TO_CHANGE": "Më tërhiqni për të ndryshuar vendndodhjen",
        "FILTER_LOCATIONS": "Zonat e filtrimit..."
      }
    },
    "SHIFT_TYPES": {
      "EDIT_SHIFT_TYPES_MODAL": {
        "TITLE_NEW": "Lloji i ri i ndërrimit",
        "TITLE_EDIT": "Redaktoni llojin e ndërrimit",
        "SITE_LABEL": "Faqja",
        "HIERARCHY_LABEL": "Hierarkia",
        "MASTER_TYPE_LABEL": "Lloji i ndërrimit master",
        "SUB_TYPE_LABEL": "Lloji i nën-ndërrimit",
        "SUB_TYPE_HELP": "Rotaready ju lejon të krijoni një hierarki të llojeve të ndryshimeve. Një lloj nën-ndërrimi mund të vendoset nën çdo lloj tjetër të ndërrimit.",
        "PARENT_TYPE_LABEL": "Lloji i ndërrimit prind",
        "NAME_LABEL": "Emri",
        "COLOUR_LABEL": "Ngjyrë",
        "COST_STREAM_MAPPINGS_LABEL": "Mapjet e transmetimit",
        "COST_STREAM_TABLE": {
          "COST_STREAM_HEADING": "Rrjedha",
          "ATTRIBUTED_HEADING": "atribuohet",
          "EMPTY_LABEL": "Kjo nuk është hartuar në asnjë rrymë.",
          "ADD_BUTTON": "Shto të reja"
        },
        "DELETE_BUTTON": "Fshini",
        "SAVE_BUTTON": "Ruaj",
        "CANCEL_BUTTON": "Anuloni",
        "VALIDATION": {
          "NAME_REQUIRED": "Ju lutemi sigurohuni që të keni dhënë një emër të vlefshëm për llojin e ndërrimit.",
          "PARENT_REQUIRED": "Që ky të jetë një lloj nën-ndërrimi, duhet të zgjidhni një lloj ndërrimi prind.",
          "PARENT_VALID": "Ju lutemi zgjidhni një lloj të vlefshëm të ndërrimit prind.",
          "PARENT_SAME_SITE": "Ky lloj ndërrimi dhe lloji i zgjedhur i ndërrimit prind duhet të përkasin të njëjtit Faqe (ose të dy të jenë në të gjithë Organizatën).",
          "ATTRIBUTION_OVERAGE": "Ne kemi përmbledhur përqindjen e atribuuar secilës rrjedhë dhe totali tejkalon 100%. Ju mund të atribuoni vetëm një maksimum prej 100% në një ose më shumë rrymë.",
          "ATTRIBUTION_VALID": "Ju keni futur një 'përqindje të atribuuar' të pavlefshme për një ose më shumë rrjedhje. Një vlerë prej '50', për shembull, do t'i atribuojë 50% të kostos së pagës së këtij lloji që punon në këtë lloj ndryshimi në rrjedhën përkatëse.",
          "ATTRIBUTION_NONE": "Ju duhet t'i atribuoni llojin e zhvendosjes së paku një rrymë.",
          "DUPLICATE_COST_STREAM_MAPPING": "Një hartë për këtë rrymë tashmë ekziston! Ju nuk mund t'i atribuoni një lloj ndryshimi në të njëjtën rrjedhë dy herë."
        },
        "ERRORS": {
          "ADD_ERROR": "Ne nuk mund të shtojmë llojin tuaj të ndërrimit vetëm atëherë. Ka të ngjarë që një lloj ndryshimi me të njëjtin emër tashmë ekziston! Provoni të zgjidhni një emër tjetër.",
          "UPDATE_ERROR": "Nuk mund të përditësojmë llojin e ndërrimit vetëm atëherë. Ka të ngjarë që një lloj ndryshimi me të njëjtin emër tashmë ekziston! Provoni të zgjidhni një emër tjetër.",
          "DELETE_TYPE": "Ne nuk ishim në gjendje të fshijmë llojin e ndryshimit vetëm atëherë. Ju lutemi provoni përsëri!"
        },
        "CONFIRM": {
          "DELETE_SUB_TYPES": "Ky lloj ndërrimi ende ka lloje nën-ndërrimi. Edhe ato do të fshihen. A jeni i sigurt që dëshironi të vazhdoni?",
          "DELETE_TYPE": "A jeni i sigurt që dëshironi të fshini këtë lloj ndryshimi?"
        }
      },
      "INDEX": {
        "TITLE": "Llojet e ndërrimit",
        "SUBTITLE": "Mendoni për llojet e ndërrimit si kategori për llojet e ndryshme të punës që njerëzit bëjnë në organizatën tuaj:",
        "SUB_TYPE_HELP": "Ju mund të shtoni një 'lloj nën-ndërrime' duke klikuar ikonën plus pranë çdo lloji të ndërrimit. Kjo është e dobishme nëse keni nevojë të ndaheni një lloj ndryshimi në detyra ose role më specifike.",
        "TYPES_EMPTY": "Nuk ka lloje të ndryshimeve për të treguar.",
        "ONLY_ENTITY": "vetëm",
        "WHOLE_ORGANISATION": "Në të gjithë organizatën",
        "SHOW_MORE": "Trego më shumë...",
        "ERRORS": {
          "LOAD_ERROR": "Ups, ne nuk mund të ngarkojmë llojet e ndërrimit vetëm atëherë. Ju lutemi provoni përsëri!"
        },
        "TOOLTIPS": {
          "ADD_SUB_SHIFT_TYPE": "Shto llojin nën-ndërrimi"
        },
        "ADD_SHIFT_TYPE": "Shto llojin e ndërrimit"
      }
    },
    "TEMPLATES": {
      "EDIT_CONSTRAINTS_MODAL": {
        "ADD_TITLE": "Shto të re {{currentLevelName}}",
        "EDIT_TITLE": "Redaktoni kufizimet e {{currentLevelName}}",
        "NAME_LABEL": "emër",
        "LOADING": {
          "SAVE": "Kursimi..."
        },
        "SAVE_AND_CLOSE_BUTTON": "Ruaj dhe mbyll",
        "CANCEL_BUTTON": "Anuloni",
        "OVERRIDE_CONSTRAINT_BUTTON": "Anashkaloni kufizimin {{levelName}}",
        "DELETE_OVERRIDE_BUTTON": "Fshini anashkalimin",
        "TAB_HEADINGS": {
          "POPULAR": "popullore",
          "STAFF": "Stafi",
          "REST": "Pushim",
          "OTHERS": "Të tjerët"
        },
        "STAFF_SELECTION": {
          "TITLE": "Përzgjedhja",
          "FLEXIBLE_STAFFING_LABEL": "Personeli fleksibël",
          "FAIRLY_HINT": "Zgjidhni të drejtë nga të gjithë stafi",
          "FAIRLY_ANY_TAGS_HINT": "Zgjidhni në mënyrë të drejtë nga ata që përputhen <strong>me ndonjë</strong> nga etiketat e mëposhtme",
          "FAIRLY_ALL_TAGS_HINT": "Zgjidhni në mënyrë të drejtë nga ata që përpu <strong>then me të gjitha</strong> etiketat e mëposhtme",
          "POOLING_HINT": "Trajtoni etiketat e mëposhtme si pishina të stafit dhe shkarkoni secil <strong>ën sipas rendit</strong>",
          "FIXED_STAFFING_LABEL": "Personeli fiks",
          "SPECIFIC_STAFF_MEMBER_HINT": "Caktoni ndërrimin (et) për një anëtar specifik të stafit",
          "ASSIGNEE_LABEL": "Përcjellësi",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Shkruani për të shtuar etiketa"
          }
        },
        "PREFERRED_STAFF_SELECTION": {
          "TITLE": "Zgjedhja e preferuar e",
          "HINT": "Nëse stafi i shumtë kanë të drejtë për një ndërrim të caktuar, Rotaready do të përpiqet të paragjykojë përzgjedhjen e tij sipas kritereve të zgjedhura:",
          "NO_PREFERENCE": "Mos preferoni asnjë staf mbi të tjerët, përveç për arsye të drejtësisë",
          "PREFER_ANY_TAG": "Preferoni ata që përputhen me <strong>ndonjë</strong> nga etiketat e mëposhtme",
          "PREFER_ALL_TAG": "Preferoni ata që përputhen me <strong>të gjitha</strong> etiketat e mëposhtme",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Shkruani për të shtuar etiketa"
          }
        },
        "STAFF_SELECTION_GROUPS": {
          "TITLE": "Përzgjedhja e stafit (grupe)",
          "HINT": "Kjo ju lejon të kufizoni përzgjedhjen e stafit tek ata që janë anëtarë të ndonjë prej grupeve më poshtë. Grupet do të merren burime në rendin në të cilin ato janë specifikuar:",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Shkruani për të shtuar etiketa"
          }
        },
        "STAFF_TYPECASTING": {
          "TITLE": "Përcaktimi i llojit të stafit",
          "HINT": "<em>Kur caktojmë dikë në një ndërrim të <em>tipizuar</em>, atij mund t'i caktohen vetëm ndërrime të mëtejshme atë ditë që janë a) gjithashtu të sh <em>typuara me</em> të paktën një nga këto etiketa, ose b) nuk janë shtypur fare:</em>",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Shkruani për të shtuar etiketa"
          }
        },
        "OUTSOURCING": {
          "TITLE": "Nënjashtimi",
          "HINT": "Nëse nuk ka asnjë staf të brendshëm në dispozicion, lejoni kontraktimin në organizatat e mëposhtme, sipas rendit në të cilin ato janë specifikuar:",
          "PLACEHOLDERS": {
            "TYPE_OUTSOURCE_ORGANISATIONS": "Lloji për të shtuar organizatat e jashtme"
          }
        },
        "SELECTION_CONSISTENCY": {
          "TITLE": "Konsistenca e zgjedhjes",
          "HINT": "Për grupet e ndërrimeve që zgjasin shumë ditë në një javë të caktuar, kur dikush shpërndahet në ndërrimin e ditës së parë, ata duhet të:",
          "NO_EFFECT": "nuk kanë asnjë efekt nëse zgjidhen për ndërrimet e ditës tjetër",
          "ALLOCATE": "të ndahen për të gjitha ndërrimet e ditëve të tjera në ndërrimin e caktuar, ku është e mundur"
        },
        "REST_PERIOD": {
          "TITLE": "Periudha e pushimit",
          "HINT": "Pas punës në një ndërrim, sigurohuni që një anëtar i stafit të pushojë (dhe kështu të mos jepet një ndërrim tjetër) për të paktën:"
        },
        "LOCATION_TOLERANCE": {
          "TITLE": "Toleranca e vendndodhjes",
          "HINT": "Shpesh është e rëndësishme që dy ndërrime njëpasnjëshme me të njëjtin vend të specifikuar t'i caktohen të njëjtit anëtar të stafit. Specifikoni numrin maksimal të minutave midis dy ndërrimeve të tilla (fundi i së parës deri në fillimin e të dytës) që ne t'i konsiderojmë ato njëpasnjëshme në këtë drejtim:",
          "MINUTES": "minuta",
          "PLACEHOLDERS": {
            "MINUTES": "Minuta midis përfundimit të një ndërrimi deri në fillimin e një tjetër"
          }
        },
        "DAILY_WINDOW_DEFINITION": {
          "TITLE": "Përcaktimi i dritares ditore",
          "HINT": "Disa organizata kanë stafin e tyre që punon “ndërrime” të shumta në një ditë të caktuar dhe për secilin person, kjo ngarkesë ditore e ndërrimeve duhet të qëndrojë brenda një dritare të caktuar kohe. Këto dritare të kohës mund të ndryshojnë dhe ju mund të përcaktoni të gjitha variacionet e mundshme këtu:",
          "LIMITED_TIMES": "Ne kemi një grup të kufizuar, fiks të kohëve të fillimit dhe përfundimit për dritaret tona",
          "REGULAR_INTERVALS": "Dritaret tona bien në intervale të rregullta pas një kohe të caktuar fillimi"
        },
        "TIMES": {
          "WINDOW_TIMES_TITLE": "Kohët e dritareve",
          "SPLIT_SHIFT_WINDOW_TIMES": "Kohët e dritareve me ndërrim të ndarë",
          "WINDOW_START_TIME": "Koha e fillimit të dritares më të hershme",
          "WINDOW_INTERVAL": "Intervali mes dritareve të vlefshme",
          "WINDOW_FINISH_TIME": "Koha e përfundimit të dritares së fundit të mundshme",
          "PLACEHOLDERS": {
            "COMMA_SEPERATED_TIMES": "Lista e kohëve të ndarë me presje, dmth. 9:30-17:30,13:00-20:00"
          }
        },
        "SHIFT_PAY_RATE": {
          "TITLE": "Shkalla e pagës së ndryshimit",
          "HINT": "Ndërrimet e punës të stafit jo-pagues të gjeneruara nga ky {{currentLevelName}} do të fitojnë me tarifën e tyre të zakonshme për orë. Ju mund ta anashkaloni këtë më poshtë nëse kërkohet:",
          "OPTIONS": {
            "NORMAL": "Normalisht: stafi fiton me tarifën e tyre të zakonshme për orë",
            "ZERO": "Zero: stafi nuk paguhet për këtë ndërrim",
            "MULTIPLE": "Shumëfishtë: stafi fiton shumëfishin e tarifës së tyre të zakonshme për orë",
            "FIXED": "Fiksuar: anashkaloni tarifën e tyre të zakonshme për orë me një tarifë fikse"
          },
          "MULTIPLIER": "Shumëzuesi i normës",
          "HOURLY_RATE_OVERRIDE": "Anashkalimi i tarifës për orë",
          "PLACEHOLDERS": {
            "MULTIPLIER": "Shumëzuesi i normës për orë",
            "HOURLY_RATE_OVERRIDE": "Tarifa për orë në monedhën lokale, dmth. 8.50"
          }
        },
        "SHIFT_TYPE": {
          "TITLE": "Lloji i ndërrimit",
          "HINT": "Kjo tregon 'llojin' të ndryshimeve që do të gjenerohen nga ky {{currentLevelName}}. Stafi juaj do ta shohë atë si titullin e ndërrimit, dhe nëse përdorni veçoritë e kontrollit të kostos të Rotaready, secili lloj ndërrimi mund të hartohet në një ose më shumë rrjedhë të kostos."
        },
        "SITE_DEPARTMENT": {
          "TITLE": "Faqit/Departamenti",
          "HINT": "Ndryshimet e atributeve të gjeneruara nga ky {{currentLevelName}} në një faqe/departament tjetër. Kjo është e dobishme për gjenerimin e ndërrimeve të mbulimit.",
          "ATTRIBUTE_TO_SITE": "Atribuoni koston e pagës dhe orët në faqen e zgjedhur"
        }
      },
      "EDIT_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Shto grupin e ndërrimit",
        "ADD_SUBTITLE": "Jepni detaje në lidhje me këtë grup ndërrimi",
        "EDIT_TITLE": "Redaktoni grupin e ndërrimit",
        "EDIT_SUBTITLE": "Ndryshoni detajet në lidhje me këtë grup ndërrimi",
        "TIP": "Programuesi i automatizuar i Rotaready do të gjenerojë ndërrime nga ky grup ndërrimesh dhe do të ndajë stafin në përputhje me rrethanat, në një mënyrë të drejtë dhe ekonomike.",
        "TIME_LABEL": "Koha e fillimit dhe përfundimit",
        "TO": "të",
        "OVERFLOW_WARNING": "Ky ndryshim do të mbushet në ditën tjetër.",
        "LOCATION_LABEL": "Zona e pjesëmarrjes",
        "MINIMUM_LABEL": "Të paktën",
        "MINIMUM_EXPLANATION": "Unë dua një numër minimal të stafit në ditë, por më shumë nëse është e mundur",
        "STAFF_NUMBER_LABEL": "Saktësisht",
        "STAFF_NUMBER_EXPLANATION": "Unë dua një numër të saktë të stafit në ditë",
        "MAXIMUM_LABEL": "Deri në",
        "MAXIMUM_EXPLANATION": "Unë dua sa më shumë staf të jetë e mundur në ditë, deri në një limit maksimal",
        "SHORT_DAYS": {
          "MONDAY": "E hënën",
          "TUESDAY": "E martë",
          "WEDNESDAY": "E martë",
          "THURSDAY": "E enjtë",
          "FRIDAY": "E premte",
          "SATURDAY": "E shtunë",
          "SUNDAY": "Dielli"
        },
        "SAVE_BUTTON": "Ruaj grupin e ndërrimit",
        "CANCEL_BUTTON": "Anuloni",
        "LOADING": {
          "SAVE": "Kursimi..."
        },
        "ERRORS": {
          "UNABLE_TO_ADD": "Aktualisht nuk është në gjendje të shtojë këtë grup ndërrimi. Ju lutemi provoni përsëri."
        }
      },
      "EDIT_TEMPLATE": {
        "LOADING": {
          "SAVE": "Kursimi..."
        },
        "EDIT_CONSTRAINTS": "Redaktoni kufizimet",
        "CONSTRAINTS_LABEL": "Kufizimet e modelit",
        "CONSTRAINTS_TEXT": "Vendosni kufizime që do të zbatohen për të gjithë modelin.",
        "PLACEHOLDERS": {
          "SEARCH_FILTER": "Filtroni..."
        },
        "ADD_GROUP_BUTTON": "Shto grup",
        "TAB_HEADINGS": {
          "FLEXIBLE_STAFFING": "Personeli fleksibël",
          "FIXED_STAFFING": "Personeli fiks"
        },
        "TOOLTIPS": {
          "ACTIVE_CONSTRAINTS": "Kufizimet aktive",
          "DISABLE": "Çaktivizo",
          "ENABLE": "Aktivizo"
        },
        "GROUP_OPTIONS_BUTTON": {
          "LABEL": "Opsionet e grupit",
          "ADD_TASK": "Shto detyrë",
          "ADD_SHIFT_SET": "Shto grupin e ndërrimit",
          "EDIT_CONSTRAINTS": "Redaktoni kufizimet",
          "DELETE_GROUP": "Fshini grupin"
        },
        "HOURS": "orë",
        "SHORT_DAYS": {
          "MONDAY": "E hënën",
          "TUESDAY": "E martë",
          "WEDNESDAY": "E martë",
          "THURSDAY": "E enjtë",
          "FRIDAY": "E premte",
          "SATURDAY": "E shtunë",
          "SUNDAY": "Dielli"
        },
        "CHAR_DAYS": {
          "MONDAY": "M",
          "TUESDAY": "T",
          "WEDNESDAY": "W",
          "THURSDAY": "T",
          "FRIDAY": "F",
          "SATURDAY": "S",
          "SUNDAY": "S"
        },
        "NO_SHIFT_SETS": "Nuk ka grupe ndryshimesh në këtë grup. Shtoni një duke përdorur butonin 'Opsionet e grupi'.",
        "OPTIONS_BUTTON": {
          "LABEL": "Opsionet",
          "ADD_SHIFT_SET": "Shto grupin e ndërrimit",
          "EDIT_CONSTRAINTS": "Redaktoni kufizimet",
          "DELETE_TASK": "Fshini detyrën"
        },
        "NO_FLEXIBLE_STAFF_GROUPS_THIS_WEEK": "Ju nuk keni grupe fleksibël të stafit. Pse të mos shtoni një duke klikuar 'Shto grup' më lart?",
        "NO_FLEXIBLE_STAFF_GROUPS": "Asnjë grup fleksibël i stafit nuk përputhet me atë që kërkoni.",
        "GROUP_SHIFT_SETS_TABLE": {
          "HEADINGS": {
            "SHIFT_TYPE": "Lloji i ndërrimit",
            "TIME": "Koha",
            "DAYS_OF_WEEK": "Ditët e javës"
          }
        },
        "NO_FIXED_STAFF_GROUPS_THIS_WEEK": "Ju nuk keni grupe fikse të stafit. Pse të mos shtoni një duke klikuar 'Shto grup' më lart?",
        "NO_FIXED_STAFF_GROUPS": "Asnjë grup i stafit fiks nuk përputhet me atë që po kërkoni.",
        "EDIT_BUTTON": "Ndrysho",
        "TOGGLE_DROPDOWN_BUTTON": "Ndryshoni menunë zbritëse",
        "DELETE_BUTTON": "Fshini",
        "SAVE_BUTTON": "Ruaj",
        "ERRORS": {
          "COULDNT_FIND_TEMPLATE": "Ne nuk mund të gjetëm modelin që po kërkonit. Ju lutemi provoni përsëri.",
          "OOPS": "Ups, diçka thjesht shkoi keq. Ju mund të duhet të provoni përsëri!",
          "TEMPLATE_STATE_CHANGE": "Ne nuk ishim në gjendje për të aktivizuar/çaktivizuar një pjesë të modelit tuaj tani. Ju lutemi provoni përsëri."
        },
        "LEVEL_NAMES": {
          "GLOBAL": "global",
          "TEMPLATE": "shabllon",
          "WEEK": "javë",
          "GROUP": "grup",
          "TASK": "detyrë",
          "SHIFT_SET": "set e ndërrimit"
        },
        "CONFIRM": {
          "DELETE": "A jeni i sigurt që dëshironi ta fshini këtë?",
          "DELETE_SHIFT_SET": "A jeni i sigurt që dëshironi të fshini këtë grup ndërrimi?"
        }
      },
      "FIXED_STAFFING_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Shto grupin e ndërrimit",
        "ADD_SUBTITLE": "Jepni detaje në lidhje me këtë grup ndërrimi",
        "EDIT_TITLE": "Redaktoni grupin e ndërrimit",
        "EDIT_SUBTITLE": "Ndryshoni detajet në lidhje me këtë grup ndërrimi",
        "START_END_LABEL": "Koha e fillimit dhe përfundimit",
        "SHIFT_OVERFLOW_WARNING": "Ky ndryshim do të mbushet në ditën tjetër.",
        "SHIFT_TYPE_LABEL": "Lloji i ndërrimit",
        "LOCATION_LABEL": "Zona e pjesëmarrjes",
        "SHORT_DAYS": {
          "MONDAY": "E hënën",
          "TUESDAY": "E martë",
          "WEDNESDAY": "E martë",
          "THURSDAY": "E enjtë",
          "FRIDAY": "E premte",
          "SATURDAY": "E shtunë",
          "SUNDAY": "Dielli"
        },
        "SAVE_BUTTON": "Ruaj grupin e ndërrimit",
        "CANCEL_BUTTON": "Anuloni",
        "LOADING": {
          "SAVE": "Kursimi..."
        },
        "UNKNOWN_SHIFT_TYPE": "Lloji i panjohur i ndryshimit",
        "ERRORS": {
          "UNABLE_ADD": "Ups, aktualisht nuk jemi në gjendje të shtojmë këtë grup ndërrimi. Ju lutemi provoni përsëri.",
          "UNABLE_UPDATE": "Ups, aktualisht nuk jemi në gjendje ta përditësojmë këtë grup ndryshimesh. Ju lutemi provoni përsëri."
        }
      },
      "INDEX": {
        "NEW_BUTTON": "Shto të reja",
        "ALERT_LINK_WARNING": "Modelet janë zhvendosur në menunë Cilësimet. Lidhja ekzistuese nën menunë Rotas do të zhduket së shpejti 👀",
        "TITLE": "Modelet e rrotave",
        "SEARCH_FILTER": "Kërko...",
        "TEMPLATE_TABLE": {
          "NAME_HEADING": "Emri",
          "CREATED_BY_HEADING": "Krijuar nga",
          "LAST_EDITED_HEADING": "Redaktuar për herë të fundit",
          "OPTIONS_HEADING": "Opsionet"
        },
        "NO_TEMPLATES": "Nuk ka shabllone rota për të shfaqur.",
        "CLONE_TEMPLATE": "Klon",
        "DELETE_TEMPLATE": "Fshini",
        "EDITED_ON": "në",
        "CONFIRM": {
          "DELETE_NODE": "A jeni i sigurt që dëshironi ta fshini këtë?"
        },
        "SUCCESS": {
          "DELETE_NODE": "Parashikimi juaj i kërkesës është fshirë me sukses."
        },
        "ERRORS": {
          "DELETE_NODE": "Ups, diçka thjesht shkoi keq. Ju mund të duhet të provoni përsëri!"
        }
      },
      "NEW_TEMPLATE_MODAL": {
        "TITLE": "Modell i ri",
        "REMINDER": "<strong>Psst!</strong> Ju tashmë keni <strong>modelin {{TemplateCount}}</strong>. Ju duhet të krijoni një të re vetëm nëse kërkesat tuaja të planifikimit kanë ndryshuar ndjeshëm. Përndryshe, duhet të redaktoni një ekzistues.",
        "TYPE_LABEL": "Lloji",
        "NEW_BLANK_TEMPLATE": "Modeli i ri i zbrazët",
        "CLONE_EXISTING_TEMPLATE": "Klononi modelin ekzistues",
        "CLONE_FROM_LABEL": "Klon nga",
        "NAME_LABEL": "Emri i modelit",
        "NAME_HELP": "Ju do të gjeneroni rota nga ky model rregullisht dhe një emër i mirë përshkruan se kujt ose për çfarë do të jenë këto rota, si “Rrota e stafit mbështetës”.",
        "NAME_PLACEHOLDER": "dmth. Sezoni i pikut, ose B Team rota",
        "DEFAULT_SHIFT_TYPE_LABEL": "Lloji i paracaktuar i n",
        "DEFAULT_SHIFT_TYPE_HELP": "Kur ndërtoni modelin tuaj, do të jeni në gjendje të ndryshoni llojin e ndërrimit për secilin grup ose detyrë dhe është thjesht e dobishme për ne që të vendosim një parazgjedhje tani.",
        "CREATE_BUTTON": "Krijo!",
        "CREATE_BUTTON_LOADING": "Ju lutem prisni, kjo mund të zgjasë një moment...",
        "CANCEL_BUTTON": "Anuloni",
        "CONFIRM_CLONE": "Klonimi i një shablloni do të zgjasë një ose dy moment, në varësi të madhësisë së shabllonit. A jeni i sigurt që dëshironi të vazhdoni?",
        "CREATE_ERROR": "Aktualisht nuk mund të krijojë këtë model. Ju lutemi provoni përsëri."
      },
      "DELETE_TEMPLATE_MODAL": {
        "INFO_TITLE": "Fshini modelin",
        "INFO_BODY": "Fshirja e një shablloni është e pakthyeshme dhe nuk mund të çaktivizohet. Për të na bindur se dëshironi ta bëni këtë, ju lutemi shkruani emrin e modelit në kutinë më poshtë, pastaj klikoni fshi.",
        "DELETE_TEMPLATE_BUTTON": "Fshini modelin",
        "SUCCESS": {
          "DELETE_TEMPLATE": "Modeli juaj është fshirë me sukses."
        },
        "ERRORS": {
          "DELETE_TEMPLATE": "Ups, diçka thjesht shkoi keq. Ju mund të duhet të provoni përsëri!"
        }
      }
    },
    "SHIFT": {
      "EDIT_MODAL": {
        "SHIFT": "Ndryshimi",
        "SITE": "Faqja",
        "START": "Filloni",
        "BREAK": "Pushim",
        "NONE": "Asnjë",
        "FINISH": "Përfundoj",
        "NEW_SHIFT": "Ndryshimi i ri",
        "ATTRIBUTE_WAGE_COST_TO_SITE": " Atribuoni koston e pagës dhe orët në faqen e zgjedhur",
        "DATE_AND_TIME": "Data dhe ora",
        "TO": " të ",
        "THEN_REST": " pastaj pusho ",
        "SHIFT_TYPE": "Lloji i ndërrimit",
        "LOCATION": "Zona e pjesëmarrjes",
        "NO_BREAK": "Asnjë pushim",
        "PAID_BREAK": "Pushim i paguar",
        "UNPAID_BREAK": "Pushim i papaguar",
        "OF_LENGTH": "të gjatësisë",
        "BREAK_MESSAGE_WARNING": "Stafi i caktuar për këtë ndërrim ka kërkesa të ndryshme pushimi. Kur të keni mbaruar, Rotaready do të kursejë një ndërrim të veçantë për secilin person me grupin e duhur të pushimit.",
        "BREAK_MESSAGE_SUCCESS": "Ne kemi aplikuar automatikisht pushimin e duhur në këtë ndryshim.",
        "PAY_RULE": "Rregulli i pagës",
        "APPLIES": " vlen",
        "FOR_THE_SCHEDULED_DURATION": "Për kohëzgjatjen e planifikuar",
        "FOR_ANY_OVERTIME": "Për çdo kohë shtesë",
        "STAFF_SELECTION": "Përzgjedhja",
        "STAFF_SELECTION_HELP": "Redaktoni stafin e caktuar dhe përdorni etiketat për të filtruar të tjerët që janë të pranueshëm",
        "STAFF_MUST_MATCH": "Stafi duhet të përputhet",
        "ANY_OF_THE_TAGS": "<strong>ndonjë</strong> nga etiketat",
        "ANY": "asnjë",
        "ALL": "të gjithë",
        "OF_THE_TAGS": " të etiketave",
        "ALL_OF_THE_TAGS": "<strong>Të</strong> gjitha etiketat",
        "TAG_TYPEAHEAD_PLACEHOLDER": "Shkruani për të gjetur etiketat",
        "STAFF_ASSIGNED": "Caktuar",
        "NOBODY_IS_ASSIGNED": "Askush nuk është caktuar",
        "STAFF_ELIGIBLE": "I pranueshëm",
        "STAFF_FILTER_PLACEHOLDER": "Kërko...",
        "SHOW": "Trego",
        "MORE": " më shumë...",
        "NOBODY_MATCHES_YOUR_TAGS": "Askush nuk përputhet me etiketat tuaja",
        "NOBODY_ELSE_IS_AVAILABLE": "Askush tjetër nuk është në dispozicion",
        "MORE_OPTIONS": "Më shumë opsione",
        "ADD_NOTES_AND_ADJUST_PAY_RATES": "Shtoni shënime dhe rregulloni normat e pagave",
        "DETAILS": "Detajet",
        "DETAILS_TEXTAREA_PLACEHOLDER": "Shtoni shënime, detyra dhe detaje të tjera këtu...",
        "ROTA": "Rota",
        "NO_ROTA": "Asnjë rrotullim",
        "PAY_LABEL": "Paguaj",
        "PAY_TOGGLE": "Ky është një ndërrim i paguar",
        "DELETE": "Fshini",
        "VIEW_BROADCAST": "Shikoni transmetimin",
        "START_BROADCAST": "Filloni transmetimin",
        "SAVE": "Ruaj",
        "CANCEL": "Anuloni"
      },
      "SHIFT_BROADCAST_MODAL": {
        "SHIFT": "Ndryshimi",
        "SITE": "Faqja",
        "START": "Filloni",
        "BREAK": "Pushim",
        "NONE": "Asnjë",
        "FINISH": "Përfundoj",
        "ORIGINAL_PLANNED_STAFF": "Stafi origjinal i planifikuar",
        "EXTRA_STAFF_REQUIRED": "Kërkohet staf shtesë",
        "NOT_SHIFT_BROADCASTING_HELP": "Vendosni në 0 për të kërkuar sa më shumë staf të jetë e mundur.",
        "START_BROADCASTING": "Filloni transmetimin",
        "START_BROADCASTING_LOADING": "Fillimi i transmetimit...",
        "CANCEL": "Anuloni",
        "ACCEPTED": "Pranuar",
        "PENDING": "Në pritje",
        "REJECTED": "Refuzuar",
        "EVERYONE": "Të gjithë",
        "NO_PARTICIPANTS_TO_SHOW": "Nuk ka pjesëmarrës në transmetim për të shfaqur.",
        "NEEDED": "Nevojitet",
        "SHIFT_BROADCASTING_HELP": "Transmetimi juaj është aktualisht në vazhdim. Lista e mëposhtme do të përditësohet ndërsa stafi pranon ose refuzon ndërrimin. Transmetimi do të përfundojë automatikisht kur numri i saktë i stafit të ketë pranuar ose kur të gjithë të jenë përgjigjur.",
        "STOP_BROADCASTING": "Ndaloni transmetimin",
        "STOP_BROADCASTING_LOADING": "Ndalimi i transmetimit...",
        "PAUSE_BROADCAST": "Ndaloni transmetimin",
        "PAUSE_BROADCAST_LOADING": "Ndalimi i transmetimit...",
        "RESUME_BROADCAST": "Rifilloni transmetimin",
        "RESUME_BROADCAST_LOADING": "Rifillimi i transmetimit...",
        "CLOSE": "Mbyll"
      },
      "VIEW_MODAL": {
        "SHIFT": "Ndryshimi",
        "SITE": "Faqja",
        "START": "Filloni",
        "BREAK": "Pushim",
        "NONE": "Asnjë",
        "FINISH": "Përfundoj",
        "LOCATION": "Zona e pjesëmarrjes",
        "NONE_SPECIFIED": "Asnjë nuk është specifikuar",
        "REST": "Pushim",
        "DETAILS": "Detajet",
        "ALLOTTED_BREAK": "Pushim i caktuar",
        "ASSIGNED_STAFF": "Stafi i caktuar",
        "ON": "në",
        "SHIFT_ROUTINE": "Rutina e ndërrimit",
        "LAST_UPDATED": "Përditësuar së fundmi",
        "GET_DIRECTIONS": "Merrni udhëzime",
        "SHOW_TRAFFIC": "Shfaq trafikun",
        "EDIT": " Ndrysho",
        "CLOSE": "Mbyll"
      }
    }
  },
  "DASHBOARD": {
    "TIME_AND_ATTENDANCE": "Koha dhe Pjesëmarrja",
    "YOU_ARE_NOW": "Ju jeni tani",
    "ERROR_PLEASE_TRY_AGAIN": "Gabim, ju lutemi provoni përsëri",
    "MY_UPCOMING_SHIFTS": "Ndërrimet e mia të ardhshme",
    "YOU_HAVE_NO_SHIFTS_TODAY": "Sot nuk keni ndërrime.",
    "ALL_DAY": "Gjithë ditën",
    "AM": "JAM",
    "PM": "PASDITE",
    "TODAY_S_SCHEDULE": "Orari i sotëm",
    "THERE_ARE_NO_SHIFTS_SCHEDULED_TODAY": "Nuk ka ndërrime të planifikuara sot.",
    "WHATEVER": "Duke filluar në <strong>{{startTime}} dhe duke përfun</strong> duar në <strong>{{EndTime</strong>}}"
  },
  "SIGN_OUT": {
    "LOGGING_OUT": "Duke dalë nga..."
  },
  "PARTIALS": {
    "VALIDATION": {
      "ISSUE_1": {
        "HEADING": "Dita e punës shumë e gjatë",
        "BODY": "Ndryshimi juaj do të thotë se dita e punës e <b>{{name}}</b> do të bëhet shumë e gjatë (sipas cilësimeve të organizatës suaj). Aktualisht fillon në <b>{{start|MomentFormat: 'lll'}}</b> dhe përfundon në <b>{{end|MomentFormat: 'lll'}} (pastaj pushon deri në {{endrest|momentFormat: 'lll'}}</b>."
      },
      "ISSUE_2": {
        "HEADING": "Dita e punës që mbivendoset",
        "BODY": "Ndryshimi juaj do të thotë që <b>{{name}}</b> ka një ditë pune nga <b>{{primaryStart|momentFormat: 'lll'}} deri në <b>{{primary</b> End|momentFormat: 'LT'}}</b>. Për fat të keq, kjo mbivendoset me një ditë pune që ata tashmë kanë nga <b>{{secondaryStart|MomentFormat: 'lll'}} deri në {{secondaryEnd|MomentFormat: 'LT'}}</b> <b>(pastaj pushojnë deri në {{endrest|momentFormat: 'LT'}}</b>)."
      },
      "ISSUE_3": {
        "HEADING": "Ndërrimi jashtë ditës së punës",
        "BODY": "<b>{{name}}</b> ka një zhvendosje të planifikuar nga <b>{{start|MomentFormat: 'LT'}}</b> në <b>{{end|MomentFormat: 'LT'}}</b> (pastaj pushon deri në {{endrest|momentFormat: 'LT'}}). Ndryshimi juaj do të thotë se ky ndërrim bie pjesërisht ose tërësisht jashtë ditës së punës."
      },
      "ISSUE_4": {
        "HEADING": "Nuk është në dispozicion",
        "BODY": "<b>{{name}}</b> nuk është i disponueshëm nga <b>{{start|MomentFormat: 'lll'}}</b> deri në <b>{{end|MomentFormat: 'LT'</b>}} siç janë",
        "REASON_1": "duke punuar një ndërrim ({{eventType}} në {{entityName}})",
        "REASON_2": "i zënë gjatë një dite pune",
        "REASON_3": "mungon ({{EventType}})",
        "REASON_4": "në dispozicion për shkak të modelit të disponueshmërisë",
        "BUTTON": "Bëni një përjashtim dhe provoni përsëri",
        "BUTTON_LOADING": "Ngarkohet..."
      },
      "ISSUE_5": {
        "HEADING": "Detyrimi kontraktual nuk plotësohet",
        "BODY": "Ndryshimi juaj do të thotë se <b>{{name}} nuk</b> do të përmbushë detyrimin e tyre kontraktuale. Tani janë ditët e <b>{{currentValue}} por duhet të jenë të paktën ditë (et)</b> <b>{{comparatorValue}}</b>."
      },
      "ISSUE_6": {
        "HEADING": "Detyrimi kontraktual nuk plotësohet",
        "BODY": "Ndryshimi juaj do të thotë se <b>{{name}} nuk</b> do të përmbushë detyrimin e tyre kontraktuale. Tani janë <b>{{currentValue}} orë (a) por</b> duhet të jenë të paktën <b>{{comparatorValue}} orë</b> (a)."
      },
      "ISSUE_7": {
        "HEADING": "Etiketat mospërputhje",
        "BODY": "<b>{{name}} nuk</b> ka etiketat e kërkuara për të punuar këtë ndërrim. Kjo do të thotë se atyre mund të mungojnë një aftësi, kualifikim ose atribut tjetër."
      },
      "ISSUE_8": {
        "HEADING": "Transmetim aktiv",
        "BODY": "Aktualisht ekziston një transmetim aktiv i Shift për këtë ndryshim. Meqenëse stafi juaj mund të jetë në procesin e përgjigjes, ne nuk mund të lejojmë që ndërrimi të modifikohet nëse së pari nuk anuloni transmetimin e Shift."
      },
      "ISSUE_9": {
        "HEADING": "Ditët e pamjaftueshme",
        "BODY": "Ndryshimi juaj do të thotë se <b>{{name}}</b> do të punojë shumë ditë këtë javë. Ata tani do të kenë <b>ditën e {{currentValue}} pushuar,</b> e cila është më e vogël se <b>dita e tyre {{comparatorValue}} e kërkuar.</b>"
      },
      "ISSUE_10": {
        "HEADING": "Anëtari i stafit joaktiv",
        "BODY": "<b>{{name}} nuk mund</b> të punojë në <b>{{start|MomentFormat: 'll'</b>}}. Llogaria e tyre është ose joaktive në këtë datë ose janë zhvendosur në një vend tjetër."
      },
      "ISSUE_11": {
        "HEADING": "Rota shënuar për miratim",
        "BODY": "Ju nuk mund të bëni asnjë ndryshim në këtë rota pasi është shënuar për miratim."
      },
      "ISSUE_12": {
        "HEADING": "Periudha e regjistrimit",
        "BODY": "Ju nuk mund të bëni asnjë ndryshim në këtë rota pasi është nënshkruar."
      },
      "ISSUE_13": {
        "HEADING": "Kufiri i punës i tejkaluar",
        "BODY": "Ndryshimi juaj do të thotë se <b>{{name}}</b> do të punojë më gjatë se sa lejohet. Ata tani do të punojnë ditën e <b>{{currentValue}} që është</b> më shumë se kufiri i tyre i <b>ditës {{comparatorValue}}</b>."
      },
      "ISSUE_14": {
        "HEADING": "Kufiri i punës i tejkaluar",
        "BODY": "Ndryshimi juaj do të thotë se <b>{{name}}</b> do të punojë më gjatë se sa lejohet. Ata tani do të punojnë <b>{{currentValue}} orë (s)</b> që është më shumë se kufiri i tyre i <b>{{comparatorValue}} orëve</b> (s)."
      },
      "ISSUE_15": {
        "HEADING": "Zona e pjesëmarrjes mbyllu",
        "BODY": "Zona e pjesëmarrjes së caktuar nga ndërrimi (<b>{{assignedLocation}}</b>) është e mbyllur në këtë kohë.",
        "REASON": "Kjo është për shkak të: {{reason}}."
      },
      "ISSUE_16": {
        "HEADING": "Zënimet e zonës së frekuencës tejkaluan",
        "BODY": "Zona e caktuar e frekuencës së këtij ndërrimi ka një zëvendësim maksimal <b>prej {{zënë}}</b> persona (e) në çdo kohë. Me këtë ndryshim zëvendësimi do të ishte {{currentOccupancy}}."
      },
      "ISSUE_17": {
        "HEADING": "Shumë pak staf në zonën e frekuencës",
        "BODY": "Nga {{start|MomentFormat: 'lll'}} deri në {{end|MomentFormat: 'lll'}}, ka një kohë kur vetëm personat (ët) <b>{{current</b> Value}} janë duke punuar. Sa herë që dikush është në një ndërrim, kërkohet një minimum prej <b>{{comparatorValue}}</b> personash (e)."
      },
      "ISSUE_18": {
        "HEADING": "Anëtari i stafit nuk është miratuar",
        "BODY": "{{name}} nuk mund të punojë në <b>{{start|MomentFormat: 'll'</b>}}. Llogaria e tyre ende nuk është aprovuar për planifikim."
      },
      "ISSUE_19": {
        "HEADING": "Dokumentet e anëtarit të stafit mungo/të skaduara",
        "BODY": "<b>{{name}} nuk mund</b> të punojë në <b>{{start|MomentFormat: 'll'</b>}}. Një ose më shumë dokumente të kërkuara ose mungojnë ose kanë skaduar."
      },
      "ISSUE_20": {
        "HEADING": "Ndërrimi shumë i gjatë (nën 18 vjeç)",
        "BODY": "Ndryshimi juaj do të thotë se <b>{{name}}</b> do të punojë për <b>{{kohëzgjatje|humanizeduration</b>}}. Meqenëse ata janë nën 18 vjeç, kufiri i Direktivës së Kohës së Punës është <b>{{limit|Humaniz</b> eduration}}."
      },
      "ISSUE_21": {
        "HEADING": "Zhvendosje jashtë kufijve",
        "BODY": "<b>Ju nuk mund të planifikoni një ndryshim për <b>{{name}}</b> në <b>{{start|MomentFormat: 'll'}}</b> sepse i përket një rota që fillon në <b>{{periodStart|momentFormat: 'll'}} dhe përfundon në {{periodend|MomentFormat: 'll'}}</b>.</b>"
      }
    }
  },
  "NAV": {
    "HOME": "Shtëpi",
    "ROTAS": "Rotas",
    "STAFF": "Stafi",
    "ATTENDANCE": "Pjesëmarrja",
    "REPORTS": "Raportet",
    "SETTINGS": "Cilësimet",
    "VIEW_ROTA": "Shikoni rrotën",
    "SCHEDULE": "Orari im",
    "ABSENCE": "Mungesa ime",
    "AVAILABILITY": "Disponueshmëria ime",
    "DOCUMENTS": "Dokumentet e mia",
    "ROTA_DASHBOARD": "Paneli",
    "ROTA_ROTA_EDITOR": "Redaktor Rota",
    "ROTA_TRONC": "Tronc",
    "ROTA_EVENT_CALENDAR": "Planuesi i ngjarjeve",
    "STAFF_DIRECTORY": "drejtoria",
    "STAFF_REQUESTS": "Kërkesat",
    "STAFF_ABSENCES": "Kalendari i mungesës",
    "STAFF_EMPLOYEE_SUBMITTED_DETAILS": "Punonjësi paraqiti detaje",
    "ATTENDANCE_OVERVIEW": "Pjesëmarrja",
    "ATTENDANCE_ISSUES": "Çështjet",
    "COST_CONTROL": "Kontrolli i kostos",
    "COST_CONTROL_SIGN_OFF": "Kontrolli i kostos dhe nënshkrimi",
    "COST_CONTROL_AGGREGATED_TOTALS": "Totali i grumbulluar",
    "ASSET_REGISTRY": "Regjistri i aseteve",
    "WFM_DASHBOARD": "Panelat e mia"
  },
  "SETTINGS": {
    "INDEX": {
      "TITLE": "Cilësimet",
      "PLACEHOLDER_TEXT": "👈 Ju lutemi zgjidhni një cilësim në të majtë për të filluar.",
      "CATEGORY_HR": "BURIMET NJERËZORE",
      "CATEGORY_ROTAS": "Rotas",
      "CATEGORY_ATTENDANCE": "Pjesëmarrja",
      "CATEGORY_INTEGRATIONS": "Integrimet",
      "ITEM_ROTA_TEMPLATES": "Modelet e rrotave",
      "ITEM_TRUSTED_DEVICES": "Pajisjet e besuara",
      "ITEM_SHIFT_LOCATIONS": "Zonat e pjesëmarrjes",
      "ITEM_SHIFT_TYPES": "Llojet e ndërrimit",
      "ITEM_OUTSOURCING": "Nënjashtimi",
      "ITEM_ABSENCE_TYPES": "Llojet e mungesës",
      "ITEM_DOCUMENT_CATEGORIES": "Kategoritë e dokumenteve",
      "ITEM_DOCUMENT_TEMPLATES": "Modelet e dokumenteve",
      "ITEM_TAGS_GROUPS": "Etiketat dhe grupet",
      "ITEM_SHIFT_ROUTINES": "Rutina e ndërrimit",
      "ITEM_REST_BREAKS": "Pushimet e pushimit",
      "ITEM_PAY_ELEMENTS": "Elementet e pagesës",
      "ITEM_PAY_RULES": "Rregullat e pagesës",
      "ITEM_PERMISSIONS": "Lejet",
      "ITEM_BUDGETS": "Buxhetet",
      "ITEM_ASSET_TYPES": "Llojet e aseteve",
      "ITEM_ENTITY_GROUP_SUBSCRIPTIONS": 'Menaxheri i Qasjes në Faqe',
      "ITEM_ENTITY_GROUPS": 'Menaxher i faqes',
      "ITEM_POSITIONS": 'Rolet e punës',
      "ITEM_LEAVER_REASONS": 'Arsyet e largimit',
      "ITEM_RECRUITMENT_SOURCES": 'Burimet e rekrutimit',
      "ITEM_LABOUR_DEPLOYMENT": 'Shpërndarja e punës',
    },
    "DOCUMENT_TEMPLATES": {
      "TITLE": "Modelet e dokumenteve",
      "FILTER_LABEL_CATEGORY": "Kategori",
      "BUTTON_UPLOAD": "Ngarko",
      "NAME_COLUMN_LABEL": "Emri",
      "TEMPLATE_CATEGORY_COLUMN_LABEL": "Kategori",
      "FILE_NAME_COLUMN_LABEL": "Emri i skedarit",
      "FILE_SIZE_COLUMN_LABEL": "Madhësia e skedarit",
      "DESTINATION_CATEGORY_COLUMN_LABEL": "Kategoria e destinacionit",
      "SIGNATORIES_COLUMN_LABEL": "Nënshkruesit",
      "EDITED_COLUMN_LABEL": "Shtuar nga",
      "OPTIONS_COLUMN_LABEL": "Opsionet",
      "LINK_DELETE": "Fshini",
      "SIGNATORIES_BOTH": "Punonjësi, punëdhënës",
      "SIGNATORIES_EMPLOYEE": "Punonjës",
      "NO_DOCS_MATCH_TEXT": "Ne nuk mund të gjetëm asnjë shabllon dokumenti që përputhet me filtrat tuaj.",
      "ERROR_NO_CATEGORIES": "Modelet e dokumenteve nuk janë aktivizuar për organizatën tuaj. Bisedoni me ne për të filluar.",
      "ALERT_CONFIRM_DELETE": "A jeni i sigurt që dëshironi të fshini këtë model? Kjo nuk mund të çaktivizohet!",
      "ERROR_500": "Ne nuk mund të ngarkojmë shabllone të dokumenteve tani. Ju lutemi provoni përsëri.",
      "ERROR_403": "Ju nuk keni leje për të shikuar/modifikuar modelet e dokumenteve.",
      "UPLOAD_MODAL": {
        "TITLE": "Ngarko një model dokumenti",
        "LABEL_NAME": "Emri",
        "HELP_TEXT_NAME": "Jepini modelit tuaj një emër që do ta njihni.",
        "LABEL_CATEGORY": "Kategoria e shabllonit",
        "HELP_TEXT_CATEGORY": "Mbani modelet tuaja të organizuara duke zgjedhur një kategori.",
        "LABEL_DESTINATION_CATEGORY": "Kategoria e dokumentit të destinacionit",
        "HELP_TEXT_DESTINATION_CATEGORY": "Kur përdoret ky model dhe nënshkruhet një dokument i gjeneruar, zgjidhni kategorinë e dokumentit në të cilën skedari duhet të ruhet.",
        "LABEL_SIGNATORIES": "Nënshkruesit",
        "SIGNATORIES_OPTION_BOTH": "Si punonjësi ashtu edhe punëdhënësi duhet të nënshkruajnë.",
        "SIGNATORIES_OPTION_EMPLOYEE": "Vetëm punonjësi duhet të nënshkruajë.",
        "LABEL_UPLOAD": "Ngarko",
        "BUTTON_CANCEL": "Anuloni",
        "BUTTON_SAVE": "Ruaj",
        "ALERT_SUCCESS": "Modeli juaj i dokumentit është ruajtur.",
        "ALERT_ERROR_500": "Ka pasur një gabim në ngarkimin e modelit të dokumentit. Ju lutemi provoni përsëri ose na kontaktoni nëse ky mesazh vazhdon.",
        "ALERT_ERROR_400": "Ka diçka jo në rregull me modelin tuaj të dokumentit."
      }
    },
    "ABSENCE_TYPES": {
      "TITLE": "Llojet e mungesës",
      "ERROR_500": "Ne nuk mund të ngarkojmë llojet e mungesës tani. Ju lutemi provoni përsëri.",
      "ERROR_403": "Ju nuk keni leje për të shikuar/modifikuar llojet e mungesës.",
      "BUTTON_ADD_NEW": "Shto të reja",
      "BUTTON_TOGGLE_DELETED": "Përfshini fshirë",
      "COLUMN_NAME": "Emri",
      "COLUMN_BOOKABLE": "Rezervuar",
      "COLUMN_APPROVAL": "Miratimi i kërkohet",
      "COLUMN_PAID": "Paguar",
      "COLUMN_EDITED": "Redaktuar nga",
      "COLUMN_OPTIONS": "Opsionet",
      "YES_LABEL": "po",
      "NO_LABEL": "Jo",
      "LINK_EDIT": "Ndrysho",
      "LINK_DELETE": "Fshini",
      "LINK_REINSTATE": "Rivendosni",
      "PILL_DELETED": "fshirë",
      "ALERT_CONFIRM_DELETE": "A jeni i sigurt që dëshironi të fshini këtë lloj mungese?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Lloji juaj i mungesës është ruajtur.",
        "ALERT_ERROR_500": "Ka pasur një gabim duke krijuar llojin tuaj të mungesës. Ju lutemi provoni përsëri ose na kontaktoni nëse ky mesazh vazhdon.",
        "ALERT_ERROR_400": "Një lloj mungese tashmë ekziston me këtë emër. Ju lutemi zgjidhni një tjetër.",
        "ADD_TITLE": "Lloji i ri i mungesës",
        "EDIT_TITLE": "Redaktoni llojin e mungesës",
        "BUTTON_SAVE": "Ruaj",
        "BUTTON_CANCEL": "Anuloni",
        "LABEL_NAME": "Emri",
        "LABEL_COLOUR": "Ngjyrë",
        "LABEL_BOOKABLE": "Rezervuar",
        "LABEL_BOOKABLE_HELP": "Lejoni punonjësit të bëjnë kërkesa për mungesë të këtij lloji.",
        "LABEL_APPROVAL": "Miratimi",
        "LABEL_APPROVAL_HELP": "Kërkesat e këtij lloji kërkojnë miratim.",
        "LABEL_PAID": "Paguar",
        "LABEL_PAID_HELP": "Mungesa e këtij lloji paguhet.",
        "LABEL_WORKED_HOURS": "Orët e punës",
        "LABEL_WORKED_HOURS_HELP": "Mungesa e këtij lloji kontribuon në “orët e punës”.",
        "LABEL_ACCRUES": "Përllogaritet pushime",
        "LABEL_ACCRUES_HELP": "Punonjësit do të grumbullojnë pushime për mungesë të këtij lloji nëse ata kanë një ndihmë për pushime të bazuar në përllogaritje.",
        "LABEL_OVERLAPS_SHIFTS": "Ndeshje ndërrimi",
        "LABEL_OVERLAPS_SHIFTS_HELP": "Mungesa e këtij lloji mund të përputhet me ndërrimet, në vend të orës dhe orës.",
        "LABEL_TRONC": "Tronc",
        "LABEL_TRONC_HELP": "Mungesa e këtij lloji kontribuon në orët e punës në Tronc.",
        "LABEL_ALLOWANCE_REQUIRED": "Kërkohet ndihma",
        "LABEL_ALLOWANCE_REQUIRED_HELP": "Për të kërkuar mungesë të këtij lloji, sigurohuni që punonjësi të ketë një ndihmë aktive.",
        "TITLE_FILE_UPLOADS": "Ngarkimet e skedarëve",
        "LABEL_SUBMISSION_UPLOAD": "Sipas kërkesës",
        "LABEL_SUBMISSION_UPLOAD_HELP": "Lejoni që një skedar të bashkëngjitet kur kërkohet mungesa e këtij lloji.",
        "LABEL_REVIEW_UPLOAD": "Në rishikim",
        "LABEL_REVIEW_UPLOAD_HELP": "Lejoni që një skedar të bashkëngjitet kur mungesa e këtij lloji aprovohet ose refuzohet.",
        "LABEL_SAVE_TO": "Ruaj në",
        "HELP_TEXT_SAVE_TO": "Kur një skedar i bashkëngjitet mungesës së këtij lloji, ai do të ruhet në dokumentet e punonjësit nën kategorinë e zgjedhur.",
        "FILE_UPLOAD_OPTION_NONE": "I fikur",
        "FILE_UPLOAD_OPTION_OPTIONAL": "Opsionale",
        "FILE_UPLOAD_OPTION_MANDATORY": "Kërkohet",
        "INFO_LOCKED": "Ky është një lloj i veçantë mungese i përdorur nga Rotaready. Ne kemi fshehur disa pronë që nuk mund të ndryshohen. Ju lutemi kontaktoni nëse keni nevojë për ndihmë me këtë lloj mungese.",
        "TITLE_PAY": "Paguaj",
        "LABEL_FIXED_AMOUNTS": "Shuma fikse",
        "HELP_TEXT_FIXED_AMOUNTS": "Kur paguani një punonjësi një shumë fikse, një pagesë e tillë për mungesë të përllogaritur por të pakarkuar, ky element pagese do të përdoret.",
        "TITLE_REASONS": "Arsyet",
        "COLUMN_TITLE": "Arsyeja",
        "COLUMN_OPTIONS": "Opsionet",
        "LINK_DELETE": "Fshini",
        "NO_REASONS": "Asnjë arsye nuk është shtuar.",
        "PLACEHOLDER_REASON": "Arsyeja e re...",
        "LINK_ADD": "Shto"
      }
    },
    "OUTSOURCING": {
      "TITLE": "Nënjashtimi",
      "BUTTON_ADD_NEW": "Shto të reja",
      "BUTTON_TOGGLE_DELETED": "Përfshini fshirë",
      "NO_DATA_TEXT": "Nuk ka organizata të jashtme për të treguar.",
      "NAME_COLUMN_TEXT": "Emri",
      "ENTITY_COLUMN_TEXT": "Faqit/Departamenti",
      "RESOURCES_COLUMN_TEXT": "Burimet",
      "HOURS_COLUMN_TEXT": "Orët në dispozicion",
      "COST_COLUMN_TEXT": "Kostoja",
      "LAST_EDITED_COLUMN_TEXT": "Redaktuar nga",
      "OPTIONS_COLUMN_TEXT": "Opsionet",
      "UNLIMITED_TEXT": "I pakufizuar",
      "ALWAYS_24_TEXT": "Gjithmonë (24 orë)",
      "PER_HOUR_TEXT": "/orë",
      "PER_DAY_TEXT": "/ditë",
      "ORG_WIDE_TEXT": "Në të gjithë organizatën",
      "LINK_EDIT": "Ndrysho",
      "LINK_DELETE": "Fshini",
      "LINK_REINSTATE": "Rivendosni",
      "PILL_DELETED": "fshirë",
      "ERROR_500": "Ne nuk mund të ngarkojmë organizatat e jashtme për momentin. Ju lutemi provoni përsëri.",
      "ERROR_403": "Ju nuk keni leje për të shikuar/modifikuar organizatat e jashtme.",
      "ALERT_CONFIRM_DELETE": "A jeni i sigurt që dëshironi të fshini këtë organizatë të jashtme?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Organizata juaj e jashtme është ruajtur.",
        "ALERT_ERROR_500": "Ka pasur një gabim duke krijuar organizatën tuaj të jashtme. Ju lutemi provoni përsëri ose na kontaktoni nëse ky mesazh vazhdon.",
        "ALERT_ERROR_400": "Një organizatë e jashtme tashmë ekziston me këtë emër. Ju lutemi zgjidhni një tjetër.",
        "EDIT_TITLE": "Ndrysho organizatën",
        "ADD_TITLE": "Shto organizatë",
        "NAME_LABEL": "Emri",
        "ENTITY_LABEL": "Faqit/Departamenti",
        "PAY_LABEL": "Paguaj",
        "HOURLY_OPTION": "Paguar sipas orës",
        "FIXED_OPTION": "Kostoja fikse në ditë",
        "HOURLY_RATE_LABEL": "Tarifa sipas orës",
        "DAILY_RATE_LABEL": "Norma ditore",
        "AUTOMATIC_SCHEDULE_TITLE": "Planifikimi i automatizuar",
        "HOURS_AVAILABLE_LABEL": "Orët në dispozicion",
        "TO_TEXT": " të ",
        "HOURS_AVAILABLE_HELP": "Koha gjatë së cilës punëtorët nga kjo organizatë janë në dispozicion",
        "RESOURCES_AVAILABLE_LABEL": "Burimet në dispozicion",
        "RESOURCES_AVAILABLE_HELP": "Numri maksimal i punëtorëve në dispozicionin tuaj në ditë (futni 0 për të pakufizuar)",
        "TAGS_LABEL": "Etiketat",
        "TAGS_PLACEHOLDER": "Shkruani për të kërkuar etiketat",
        "TAGS_HELP": "Caktoni etiketa siç do t'i bëni një anëtari normal të stafit. Këto do të zbatohen për të gjithë punëtorët e kësaj organizate.",
        "BUTTON_SAVE": "Ruaj",
        "BUTTON_CANCEL": "Anuloni"
      }
    },
    "TRUSTED_DEVICES": {
      "TITLE": "Pajisjet e besuara",
      "DEVICE": "Pajisja",
      "ENTITY": "Faqja kryesore/departamenti",
      "DEVICE_TYPE": "Agjenti i përdoruesit",
      "EDITED_BY": "Redaktuar nga",
      "LAST_ACTIVITY": "Aktiviteti i fundit",
      "STATUS": "Statusi",
      "OPTIONS": "Opsionet",
      "NO_DEVICES": "Nuk ka pajisje të besuara për regjistrimin e pjesëmarrjes.",
      "NO_ACTIVITY": "Asnjë aktivitet",
      "LINK_UNTRUST": "Mosbesimi",
      "LINK_TRUST": "Besimi",
      "LINK_EDIT": "Ndrysho",
      "ALERT_CONFIRM_TRUST": "A jeni i sigurt që dëshironi t'i besoni kësaj pajisjeje për kapjen e pjesëmarrjes?",
      "ALERT_CONFIRM_UNTRUST": "A jeni i sigurt që dëshironi të mos i besoni kësaj pajisjeje në mënyrë që të mos përdoret më për kapjen e pjesëmarrjes? Kjo nuk mund të ndryshohet.",
      "ERROR_500": "Ne nuk mund të ngarkojmë pajisje të besuara tani. Ju lutemi provoni përsëri.",
      "ERROR_403": "Ju nuk keni leje për të shikuar/modifikuar pajisjet e besuara.",
      "STATUS_PENDING": "Në pritje",
      "STATUS_TRUSTED": "Besuar",
      "STATUS_UNTRUSTED": "I pabesueshëm",
      "FILTER_SITE": "Faqit/departamentin e hartuar",
      "FILTER_STATUS": "Statusi",
      "BUTTON_NEW_DEVICE": "Pajisje e re",
      "NEW_DEVICE_MODAL": {
        "TITLE": "Shto një pajisje të re",
        "BUTTON_CANCEL": "Anuloni",
        "BUTTON_DONE": "Vazhdoni",
        "TEXT_STEP_1": "Hapi 1",
        "TEXT_STEP_2": "Hapi 2",
        "TEXT_STEP_1_BODY": "Merrni tabletin ose laptopin tuaj, hapni një shfletues në internet dhe shkoni në URL-në e mëposhtme:",
        "TEXT_STEP_2_BODY_A": "Kur kërkohet për një “Realm ID”, futni vlerën e mëposhtme:",
        "TEXT_STEP_2_BODY_B": "Kur kërkohet për një “ID të entitetit”, futni vlerën e mëposhtme:",
        "TEXT_STEP_3_BODY": "Kur të keni mbaruar, klikoni Vazhdo për t'u kthyer në listën e pajisjeve. Pajisja juaj e re pastaj duhet të shfaqet. Klikoni Trust përkrah pajisjes për të përfunduar procesin e çiftimit."
      },
      "EDIT_DEVICE_MODAL": {
        "TITLE": "Ndrysho pajisjen",
        "BUTTON_CANCEL": "Anuloni",
        "BUTTON_SAVE": "Ruaj",
        "BODY": "Duke zgjedhur më shumë faqe ose departamente më poshtë, punonjësit e lidhur do të shfaqen në ekranin kryesor të pajisjes. Ju nuk mund të heqni zgjedhjen e faqes/departamentit kryesor; duhet të mos i besoni pajisjes nëse dëshironi ta ndryshoni këtë.",
        "ALERT_200": "Pajisja është përditësuar.",
        "ALERT_500": "Nuk mund ta përditësojmë pajisjen vetëm atëherë. Ju lutemi provoni përsëri më vonë.",
        "LABEL_NAME": "Emri i pajisjes"
      }
    }
  },
  "MESSAGING": {
    "BETA": "BETA",
    "MESSAGE_HUB": {
      "TITLE": "Mesazhet",
      "NO_MESSAGES": "Asnjë mesazh",
      "START_NEW": "Filloni bisedë të re",
      "YESTERDAY": "dje"
    },
    "CONVERSATION": {
      "MESSAGE_PLACEHOLDER": "Shto mesazhin tuaj",
      "INDIVIDUAL_TYPING": "{{name}} është duke shtypur...",
      "MULTIPLE_TYPING": "Shumë njerëz shkruajnë..."
    },
    "START_NEW": {
      "TITLE": "Filloni bisedë të re",
      "TO": "Për:",
      "REMOVE": "Hiq",
      "START_CONVERSATION": "Filloni bisedën"
    },
    "ALERTS": {
      "START_CONVERSATION": "Ka pasur një gabim duke filluar bisedën tuaj. Ju lutemi provoni përsëri.",
      "SUBSCRIBE_TO_CHANNELS": "Ekziston një problem për t'u abonuar në bisedat tuaja. Ju nuk mund të merrni asnjë mesazh. Ju lutemi rifreskoni ose kontaktoni nëse kjo vazhdon.",
      "FETCH_MESSAGES": "Ekziston një problem me marrjen e mesazheve tuaja. Ju lutemi rifreskoni ose kontaktoni nëse kjo vazhdon.",
      "FETCH_NEW_MESSAGES": "Ka pasur një gabim duke marrë mesazhe të reja. Ju lutemi rifreskoni ose kontaktoni nëse kjo vazhdon.",
      "FETCH_CONVERSATIONS": "Ka pasur një gabim në marrjen e bisedave tuaja. Ju lutemi rifreskoni ose kontaktoni nëse kjo vazhdon.",
      "SEND_MESSAGE": "Ka pasur një gabim duke dërguar mesazhin tuaj. Ju lutemi provoni përsëri."
    }
  },
  "ABSENCE": {
    "REQUEST": {
      "TITLE": "kërkesa për mungesë",
      "BUTTON_SUBMIT": "Dorëzoni",
      "LABEL_TYPE": "Zgjidhni një lloj",
      "LABEL_REASON": "Zgjidhni një arsye",
      "LABEL_EMPLOYEE": "Punonjës",
      "LABEL_START_DATE": "Data e fillimit",
      "LABEL_END_DATE": "Data e përfundimit",
      "LABEL_SCOPE_ALL_DAY": "Gjithë ditën",
      "LABEL_SCOPE_AM": "Vetëm AM",
      "LABEL_SCOPE_PM": "Vetëm PM",
      "LABEL_SCOPE_CUSTOM": "Zgjidhni orë specifike",
      "LABEL_START_TIME": "Koha e fillimit",
      "LABEL_END_TIME": "Koha e përfundimit",
      "ALERT_OVERLAPPING_ABSENCE_USER": "Ju keni një rezervim tjetër mungesë që mbivendoset me datën (et) tuaja të zgjedhura.",
      "ALERT_OVERLAPPING_ABSENCE_OTHERS": "Vetëm për ta ditur, ka njerëz të tjerë gjithashtu mungojnë në këtë kohë.",
      "ERROR_500": "Ne goditëm një goditje pikërisht atëherë. Ju lutemi provoni përsëri!",
      "LABEL_DAYS": "ditë",
      "LABEL_HOURS": "orë",
      "SECTION_UNPAID_DAYS": "Ditë të rregullta pushimi",
      "HELP_TEXT_NO_UNPAID_DAYS": "Nuk ka ditë pushimi të rregullt në këtë kërkesë.",
      "BUTTON_OPTIONS": "Opsionet",
      "BUTTON_CHANGE": "Ndryshoni ditët e rregullta të",
      "BUTTON_RESET": "Kthejeni ditët e rregullta të pushimit në sugjeruar",
      "BUTTON_CLEAR": "Pastroni të gjitha ditët e rregullt pushimi",
      "MODAL_CALENDAR_TITLE": "Zgjidhni ditë pushimi të rregullt",
      "MODAL_BUTTON_SAVE": "Ruaj",
      "LABEL_ADD_A_NOTE": "Shtoni një shënim në këtë kërkesë",
      "BUTTON_COMPLETE": "Më merr përsëri",
      "COMPLETE_TITLE": "Ju jeni të gjitha të gatshëm.",
      "COMPLETE_LABEL_APPROVED": "Miratuar",
      "COMPLETE_TEXT_APPROVED": "Kërkesa juaj është dorëzuar dhe aprovuar automatikisht ✅",
      "COMPLETE_LABEL_PENDING": "Miratimi në pritje",
      "COMPLETE_TEXT_PENDING": "Kërkesa juaj është dorëzuar dhe është në pritje të miratimit. Ne do t'ju njoftojmë kur kjo ndryshojë 👍"
    },
    "VALIDATION_MODAL": {
      "TITLE": "Diçka nuk është mjaft e drejtë",
      "DESCRIPTION": "Fatkeqësisht ajo që po përpiqeni të bëni shkakton një ose më shumë probleme.",
      "BUTTON_CLOSE": "Mbyll",
      "INVALID_DATES_TITLE": "Datat e pavlefshme",
      "INVALID_DATES_DESCRIPTION": "Ju mund të zgjidhni vetëm AM, vetëm PM ose orë të personalizuara vetëm nëse data juaj e fillimit dhe përfundimit është e njëjta ditë.",
      "INVALID_HEADER_TITLE": "Rekord i pavlefshëm i munges",
      "INVALID_HEADER_DESCRIPTION": "Ju nuk mund ta redaktoni atë regjistër mungese. Mund të jetë fshirë.",
      "INVALID_ACTION_TITLE": "Veprim i pavlefshëm",
      "INVALID_ACTION_DESCRIPTION": "Ju nuk mund të ndryshoni kohën e një rezervimi të mungesës pasi të jetë refuzuar ose anuluar.",
      "INVALID_DAYS_TITLE": "Ditë të pavlefshme",
      "INVALID_DAYS_DESCRIPTION": "Ju nuk mund të vendosni një kërkesë për mungesë për zero orë ose zero ditë.",
      "OUTSIDE_EMPLOYMENT_TITLE": "Punësimi jashtë",
      "OUTSIDE_EMPLOYMENT_DESCRIPTION": "Data juaj e zgjedhur e fillimit ose përfundimit bie jashtë datave të punësimit të punësimit.",
      "SPANS_TIMEZONES_TITLE": "Diapazoni i datës përfshin dy zona kohore",
      "SPANS_TIMEZONES_DESCRIPTION": "Të dhënat e takimit të punonjësve tregojnë se ata lëvizin faqen gjatë datave tuaja të zgjedhura dhe faqja e tyre ekzistuese është në një zonë tjetër kohore me faqen e tyre të re. Sigurohuni që rezervimi juaj i mungesës të përfundojë para se të lëvizin faqen ose të fillojë pasi të lëvizin faqen.",
      "SPANS_PAYROLL_CALENDARS_TITLE": "Diapazoni i datave përfshin dy kalendarë të pagave",
      "SPANS_PAYROLL_CALENDARS_DESCRIPTION": "Të dhënat e takimeve të punonjësit tregojnë se ata ndryshojnë kalendarin e pagave gjatë datave tuaja të zgjedhura. Sigurohuni që rezervimi juaj i mungesës të përfundojë përpara se të ndryshojnë kalendarin e pagave, ose të fillojë pasi të ndryshojnë kalendarin",
      "INVALID_PAYROLL_CALENDAR_TITLE": "Kalendari i pagave i pavlef",
      "INVALID_PAYROLL_CALENDAR_DESCRIPTION": "Diapazoni i zgjedhur i datës fillon para regjistrimit të parë të pagave të kalendarit të pagave të punonjësit.",
      "OVERLAPPING_ABSENCE_TITLE": "Mungesa mbivendosur",
      "OVERLAPPING_ABSENCE_DESCRIPTION": "Punonjësi tashmë mungon në një ose më shumë ditë që mbivendosen me datat tuaja të zgjedhura.",
      "OVERLAPPING_SHIFTS_TITLE": "Ndërrimet mbivendosura",
      "OVERLAPPING_SHIFTS_DESCRIPTION": "Punonjësi ka një ose më shumë ndërrime të publikuara që mbivendosen me datat tuaja të zgjedhura. Këto duhet të fshihen ose të mos caktohen së pari.",
      "SIGNED_OFF_TITLE": "Datat e regjistrimit",
      "SIGNED_OFF_DESCRIPTION": "Datat mbivendosen me një ose më shumë ditë pushimi. Ju nuk mund të miratoni ose anuloni mungesën për ditë që tashmë janë regjistruar.",
      "ALLOWANCE_REQUIRED_TITLE": "Kërkohet ndihma",
      "ALLOWANCE_REQUIRED_DESCRIPTION": "Ju nuk mund të rezervoni mungesë të këtij lloji. Duhet të ketë një ndihmë aktive për të zbritur.",
      "ALLOWANCE_NOT_DEDUCTIBLE_TITLE": "Ndihma jo e zbritshme",
      "ALLOWANCE_NOT_DEDUCTIBLE_DESCRIPTION": "Nuk mund të rezervoni mungesë të këtij lloji. Ekziston një ndihmë aktive, por është shënuar si jo e zbritshme.",
      "ALLOWANCE_BALANCE_INSUFFICIENT_TITLE": "Bilanci i pamjaftuesh",
      "ALLOWANCE_BALANCE_INSUFFICIENT_DESCRIPTION": "Bilanci i ndihmës është shumë i ulët për të paraqitur këtë kërkesë.",
      "FILE_UPLOAD_REQUIRED_TITLE": "Ngarkimi i skedarit kërkohet",
      "FILE_UPLOAD_REQUIRED_DESCRIPTION": "Ky lloj mungese kërkon që ju të ngarkoni një skedar para se të paraqitet ose miratohet."
    },
    "VIEW_MODAL": {
      "TITLE": "Mungesa",
      "BUTTON_OPTIONS": "Opsionet",
      "BUTTON_EDIT": "Redakto/rishikoni rezervimin",
      "BUTTON_CANCEL": "Anuloje rezervimin",
      "BUTTON_CLOSE": "Mbyll",
      "STATUS_CANCELLED": "Anuluar",
      "LABEL_START_DATE": "Fillon",
      "LABEL_END_DATE": "Përfundon",
      "LABEL_ABSENCE_TYPE": "Lloji",
      "LABEL_SCOPE": "Kohëzgjatja",
      "ERROR_500": "Ne goditëm një pengesë të vogël. Ju lutemi provoni përsëri.",
      "ERROR_404": "Kjo kërkesë për mungesë nuk ekziston më.",
      "LABEL_SUBMITTED_BY": "Paraqitur nga",
      "LABEL_REVIEWED_BY": "Shqyrtuar nga",
      "LABEL_CANCELLED_BY": "Anulohet nga",
      "LABEL_EDITED_BY": "Redaktuar nga",
      "DURATION_TEXT": "{{orë}} orë, {{ditë}} ditë",
      "TAB_BREAKDOWN": "Ndarja",
      "TITLE_PAY_PERIODS": "Sipas periudhës së pagës",
      "NO_PAY_PERIODS": "Punonjësi nuk ishte i lidhur me një kalendar të pagave kur u bë ky rezervim.",
      "TITLE_DAILY_BREAKDOWN": "Nga dita",
      "NO_DAYS": "Nuk ka ditë në këtë rezervim.",
      "TAB_FILES": "Dokumentet",
      "FILES_NONE": "Asgjë nuk është ngarkuar për këtë rezervim.",
      "FILES_AVAILABLE": "Këto dokumente mund të gjenden gjithashtu në zonën e stafit kur shikoni profilin e punonjësit. Ju do t'i gjeni nën këtë kategori: {{CategoryName}}",
      "FILES_COLUMN_NAME": "Emri",
      "FILES_COLUMN_UPLOADED_BY": "Ngarkuar nga",
      "FILES_COLUMN_OPTIONS": "Opsionet",
      "FILES_LINK_DELETE": "Fshini",
      "FILES_CONFIRM_DELETE": "A jeni i sigurt që dëshironi ta fshini këtë? Gjithashtu do të hiqet nga skeda Dokumentet e profilit të punonjësit.",
      "CONFIRM_CANCEL": "A jeni i sigurt që dëshironi të anuloni këtë rezervim të mungesës? Kjo nuk mund të çaktivizohet.",
      "ALERT_IS_CANCELLED": "Rezervimi i mungesës është anuluar.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Paralajmërim! Kërkuesi zgjodhi një kohë fillimi dhe përfundimi që barazohet me më shumë se {{HalfdayHours}} orë, që është numri i orëve që ata aktualisht punojnë për një gjysmë dite.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Paralajmërim! Kërkuesi zgjodhi të ndryshojë ditët e rregullta të pushimit, në vend që të përdorë ditët e sugjeruara të Rotaready."
    },
    "EDIT": {
      "TITLE": "Mungesa",
      "BUTTON_SUBMIT": "Ruaj",
      "BUTTON_APPROVE": "Miratoni",
      "BUTTON_REJECT": "Refuzoni",
      "TAB_EDIT_BOOKING": "Ndrysho datat",
      "TAB_REVIEW": "Rishikimi",
      "TAB_FILES": "Dokumentet",
      "TAB_CALENDAR": "Kalendari",
      "LABEL_TYPE": "Lloji",
      "LABEL_REASON": "Zgjidhni një arsye",
      "LABEL_EMPLOYEE": "Punonjës",
      "LABEL_START_DATE": "Data e fillimit",
      "LABEL_END_DATE": "Data e përfundimit",
      "LABEL_SCOPE_ALL_DAY": "Gjithë ditën",
      "LABEL_SCOPE_AM": "Vetëm AM",
      "LABEL_SCOPE_PM": "Vetëm PM",
      "LABEL_SCOPE_CUSTOM": "Zgjidhni orë specifike",
      "LABEL_START_TIME": "Koha e fillimit",
      "LABEL_END_TIME": "Koha e përfundimit",
      "ERROR_500": "Ne goditëm një goditje pikërisht atëherë. Ju lutemi provoni përsëri!",
      "LABEL_DAYS": "ditë",
      "LABEL_HOURS": "orë",
      "SECTION_UNPAID_DAYS": "Ditë të rregullta pushimi",
      "HELP_TEXT_NO_UNPAID_DAYS": "Nuk ka ditë pushimi të rregullt në këtë kërkesë.",
      "BUTTON_OPTIONS": "Opsionet",
      "BUTTON_CHANGE": "Ndryshoni ditët e rregullta të",
      "BUTTON_RESET": "Kthehu në rezervimin fillimisht",
      "BUTTON_RECALCULATE": "Rillogaritni përsëri",
      "BUTTON_CLEAR": "Pastroni të gjitha ditët e rregullt pushimi",
      "UNPAID_DAYS_RECALCULATED_LABEL": "Rillogaritur",
      "UNPAID_DAYS_RECALCULATED_HELP": "Ju keni zgjedhur të llogarisni ditët e rregullta të pushimit dhe të përdorni vlerën mbizotëruese për “orët në ditë pune” të punonjësit në vend të asaj që ishte kur u bë rezervimi fillimisht.",
      "UNPAID_DAYS_MERGED_LABEL": "Bashkuar",
      "UNPAID_DAYS_MERGED_HELP": "Meqenëse keni ndryshuar datën, ne kemi përfshirë çdo ditë të re pushimi, kemi hequr ato që nuk janë më në fushëveprim dhe i kemi lënë të tjerat të paprekura.",
      "MODAL_CALENDAR_TITLE": "Zgjidhni ditë pushimi të rregullt",
      "MODAL_BUTTON_SAVE": "Ruaj",
      "BUTTON_COMPLETE": "Më merr përsëri",
      "COMPLETE_TITLE": "Ju jeni mirë të shkoni.",
      "COMPLETE_TEXT": "Ne kemi ruajtur rezervimin e mungesës dhe njerëzit përkatës janë njoftuar 👍",
      "INVALID_HEADER_TITLE": "Mungesa e pavlefshme",
      "INVALID_HEADER_TEXT": "Nuk mund ta ngarkojmë rezervimin e mungesës. Ndoshta është anuluar ose nuk keni leje për ta parë atë.",
      "TITLE_OVERLAPPING_ABSENCE": "Mungesa e njëkohshme",
      "TITLE_OVERLAPPING_SHIFTS": "Ndërrimet mbivendosura",
      "TITLE_NOTABLE_EVENTS": "Ngjarje të dukshme",
      "COLUMN_OVERLAPPING_ABSENCE_DATES": "Datat",
      "COLUMN_OVERLAPPING_ABSENCE_TYPE": "Lloji",
      "COLUMN_OVERLAPPING_ABSENCE_WHO": "Kush",
      "NO_OVERLAPPING_ABSENCE": "Nuk ka mungesë të njëkohshme që përputhet me filtrat tuaj.",
      "NO_OVERLAPPING_SHIFTS": "Nuk ka ndërrime të mbivendosura në vendin/departamentin e punonjësit.",
      "NO_NOTABLE_EVENTS": "Nuk ka ngjarje të dukshme në kohën e kësaj mungese.",
      "COLUMN_OVERLAPPING_SHIFTS_DATE": "datë",
      "COLUMN_OVERLAPPING_SHIFTS_SITE": "Faqit/Departamenti",
      "COLUMN_OVERLAPPING_SHIFTS_TYPE": "Lloji i ndërrimit",
      "COLUMN_OVERLAPPING_SHIFTS_OPTIONS": "Opsionet",
      "LINK_EDIT_SHIFT": "Redakto rrotën",
      "LINK_DELETE_SHIFT": "Fshini",
      "BUTTON_BULK_SHIFT_OPTIONS": "Veprimet me shumicë",
      "BUTTON_BULK_SHIFT_UNASSIGN_ALL": "Çcaktoni përzgjedhjen",
      "BUTTON_BULK_SHIFT_DELETE_ALL": "Fshini zgjedhjen",
      "ALERT_BULK_SHIFT_CONFIRM": "A jeni i sigurt që dëshironi ta bëni këtë në ndërrimet {{count}}?",
      "ALERT_BULK_SHIFT_EDIT_FAILURE": "Ne nuk mund të ndryshojmë ndërrimet sepse do të shkaktonte një çështje të vërtetimit. Ju lutemi bëni këtë ndryshim në rota për të parë më shumë informacion.",
      "ALERT_SINGLE_SHIFT_DELETE_FAILURE": "Ne nuk mund ta fshinim atë ndryshim sepse do të shkaktonte një problem të vërtetimit. Ju lutemi bëni këtë ndryshim në rota për të parë më shumë informacion.",
      "COLUMN_NOTABLE_EVENTS_NAME": "Emri",
      "COLUMN_NOTABLE_EVENTS_DATE": "datë",
      "NOTABLE_EVENTS_ABSENCE_RESTRICTION_TOOLTIP": "Mungesa është e kufizuar gjatë kësaj ngjarjeje.",
      "LABEL_ADD_A_NOTE": "Opsionale shkruani një mesazh për punonjësin",
      "OVERLAPPING_SHIFTS_MORE": "Ne po tregojmë vetëm pesëmbëdhjetë ndërrimet e para të mbivendosura. Zgjidhni këto për të parë më shumë.",
      "OVERLAPPING_ABSENCE_MORE": "Ne po tregojmë vetëm pesëmbëdhjetë rezervimet e para të mbivendosura.",
      "CALENDAR_UNPAID_DAY": "Ditë e rregullt pushimi",
      "FILTER_LABEL_ENTITY": "Faqit/Departamenti",
      "FILTER_LABEL_STAFF_GROUP": "Grupi i stafit",
      "FILTER_LABEL_ABSENCE_TYPE": "Lloji",
      "FILES_NONE": "Asgjë nuk është ngarkuar për këtë rezervim.",
      "FILES_AVAILABLE": "Këto dokumente mund të gjenden gjithashtu në zonën e stafit kur shikoni profilin e punonjësit. Ju do t'i gjeni nën këtë kategori: {{CategoryName}}",
      "FILES_COLUMN_NAME": "Emri",
      "FILES_COLUMN_UPLOADED_BY": "Ngarkuar nga",
      "FILES_COLUMN_OPTIONS": "Opsionet",
      "FILES_LINK_DELETE": "Fshini",
      "FILES_CONFIRM_DELETE": "A jeni i sigurt që dëshironi ta fshini këtë? Gjithashtu do të hiqet nga skeda Dokumentet e profilit të punonjësit.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Paralajmërim! Kërkuesi zgjodhi një kohë fillimi dhe përfundimi që barazohet me më shumë se {{HalfdayHours}} orë, që është numri i orëve që ata aktualisht punojnë për një gjysmë dite.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Paralajmërim! Kërkuesi zgjodhi të ndryshojë ditët e rregullta të pushimit, në vend që të përdorë ditët e sugjeruara të Rotaready. Ju lutemi rishikoni ato me kujdes në skedën Redakto datat dhe zgjidhni opsionin Rikllogaritni të ri nëse është e nevojshme."
    },
    "ENUM_SCOPE": {
      "ALL_DAY": "Gjithë ditën",
      "AM": "Vetëm AM",
      "PM": "Vetëm PM",
      "CUSTOM": "Orët e personalizuara"
    },
    "ENUM_APPROVAL_STATE": {
      "APPROVED": "Miratuar",
      "REJECTED": "Refuzuar",
      "PENDING": "Në pritje"
    },
    "ENUM_ALLOWANCE_UNIT": {
      "DAYS": "Ditët",
      "HOURS": "Orë"
    },
    "ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL": {
      "BEGINNING_OF_ACCRUAL_YEAR": "Fillimi i vitit të përllogaritjes",
      "EACH_CALENDAR_MONTH": "Çdo muaj kalendarik",
      "EACH_HOUR_WORKED": "Çdo orë punuar"
    },
    "ALLOWANCE_IMPACT_DIRECTIVE": {
      "DAYS_DEBIT": "Zbritja e ditës {{count}}",
      "HOURS_DEBIT": "{{count}} zbritje e orës",
      "DAYS_CREDIT": "Kredi ditore {{count}}",
      "HOURS_CREDIT": "Kredi orësore {{count}}",
      "REMAINING": "({{count}} mbetur)",
      "WARNING_EXCEEDS": "Kërkesa juaj tejkalon këtë ndihmë, por ende mund të dorëzohet.",
      "WARNING_NOT_PERMITTED": "Kërkesa juaj tejkalon këtë ndihmë.",
      "TOOLTIP_PROJECTED": "Rotaready ka përfshirë një parashikim të asaj që ka të ngjarë të grumbulloni. Kjo mund të jetë e kufizuar sipas politikave të punëdhënësit tuaj.",
      "CALC_TOTAL_ACCRUED": "Totali i përllogaritur",
      "CALC_BOOKED": "Rezervuar",
      "CALC_PROJECTED": "Llogaritja e parashikuar",
      "CALC_THIS_REQUEST": "Kjo kërkesë",
      "CALC_REMAINING": "Të mbetura",
      "CALC_CONVERTED_TO_DAYS": "Si ditë"
    }
  }
};

module.exports = translations;
