const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/rotamanagement/shift/viewModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/attendance/viewEventModal.html');

'use strict';
var controllers = require('../module');

controllers.controller('IssuesAttendanceCtrl', ['$scope', '$state', '$stateParams', '$uibModal', 'AttendanceService',
    'AlertService', 'AuthService', 'SessionService', 'NotificationService', 'EnvironmentDataService', 'AttendanceCommon', '$translate',
    function ($scope, $state, $stateParams, $uibModal, AttendanceService, AlertService, AuthService, SessionService,
              NotificationService, EnvironmentDataService, AttendanceCommon, $translate) {
        $scope.defaultIssuePeriod = SessionService.getSetting('attendance.issues.period') || 21;
        $scope.isFacialRecognition = SessionService.getSetting('attendance.facialRecognition') === 'true';

        let isFirstLoad = true;
        var labelTranslations = $translate.instant([
            'ATTENDANCE.ISSUES.UNMATCHED_ATTENDANCE',
            'ATTENDANCE.ISSUES.NO_CLOCK_IN',
            'ATTENDANCE.ISSUES.NO_CLOCK_OUT',
            'ATTENDANCE.ISSUES.SUSPECT_CLOCK_OUT',
            'ATTENDANCE.ISSUES.SUSPECT_ATTENDANCE',
            'ATTENDANCE.ISSUES.BREAK_NOT_TAKEN',
            'ATTENDANCE.ISSUES.INVALID_BREAKS',
            'ATTENDANCE.ISSUES.SUSPECT_BREAK',
            'ATTENDANCE.ISSUES.EARLY_CLOCK_IN',
            'ATTENDANCE.ISSUES.LATE_CLOCK_OUT',
            'ATTENDANCE.ISSUES.UNMATCHED_ATTENDANCE_EXPLANATION',
            'ATTENDANCE.ISSUES.NO_CLOCK_IN_EXPLANATION',
            'ATTENDANCE.ISSUES.NO_CLOCK_OUT_EXPLANATION',
            'ATTENDANCE.ISSUES.SUSPECT_CLOCK_OUT_EXPLANATION',
            'ATTENDANCE.ISSUES.SUSPECT_ATTENDANCE_EXPLANATION',
            'ATTENDANCE.ISSUES.BREAK_NOT_TAKEN_EXPLANATION',
            'ATTENDANCE.ISSUES.INVALID_BREAKS_EXPLANATION',
            'ATTENDANCE.ISSUES.SUSPECT_BREAK_EXPLANATION',
            'ATTENDANCE.ISSUES.EARLY_CLOCK_IN_EXPLANATION',
            'ATTENDANCE.ISSUES.LATE_CLOCK_OUT_EXPLANATION',
            'ATTENDANCE.ISSUES.ERROR_LOADING_ATTENDANCE',
            'ATTENDANCE.ISSUES.SOMETHING_WENT_WRONG',
            'ATTENDANCE.ISSUES.CONFIRM_DISMISS',
            'ATTENDANCE.ISSUES.DATE_RANGE_TOO_WIDE',
        ]);

        $scope.issueTypes = [
            {
                id: 1,
                label: labelTranslations['ATTENDANCE.ISSUES.UNMATCHED_ATTENDANCE'],
                verbose: labelTranslations['ATTENDANCE.ISSUES.UNMATCHED_ATTENDANCE_EXPLANATION'],
            },
            {
                id: 2,
                label: labelTranslations['ATTENDANCE.ISSUES.NO_CLOCK_IN'],
                verbose: labelTranslations['ATTENDANCE.ISSUES.NO_CLOCK_IN_EXPLANATION'],
            },
            {
                id: 3,
                label: labelTranslations['ATTENDANCE.ISSUES.NO_CLOCK_OUT'],
                verbose: labelTranslations['ATTENDANCE.ISSUES.NO_CLOCK_OUT_EXPLANATION'],
            },
            {
                id: 4,
                label: labelTranslations['ATTENDANCE.ISSUES.SUSPECT_CLOCK_OUT'],
                verbose: labelTranslations['ATTENDANCE.ISSUES.SUSPECT_CLOCK_OUT_EXPLANATION'],
                canDismiss: true,
            },
            {
                id: 5,
                label: labelTranslations['ATTENDANCE.ISSUES.SUSPECT_ATTENDANCE'],
                verbose: labelTranslations['ATTENDANCE.ISSUES.SUSPECT_ATTENDANCE_EXPLANATION'],
                canDismiss: true,
            },
            {
                id: 6,
                label: labelTranslations['ATTENDANCE.ISSUES.BREAK_NOT_TAKEN'],
                verbose: labelTranslations['ATTENDANCE.ISSUES.BREAK_NOT_TAKEN_EXPLANATION'],
                canDismiss: true,
            },
            {
                id: 7,
                label: labelTranslations['ATTENDANCE.ISSUES.INVALID_BREAKS'],
                verbose: labelTranslations['ATTENDANCE.ISSUES.INVALID_BREAKS_EXPLANATION'],
            },
            {
                id: 8,
                label: labelTranslations['ATTENDANCE.ISSUES.SUSPECT_BREAK'],
                verbose: labelTranslations['ATTENDANCE.ISSUES.SUSPECT_BREAK_EXPLANATION'],
                canDismiss: true,
            },
            {
                id: 9,
                label: labelTranslations['ATTENDANCE.ISSUES.EARLY_CLOCK_IN'],
                verbose: labelTranslations['ATTENDANCE.ISSUES.EARLY_CLOCK_IN_EXPLANATION'],
            },
            {
                id: 10,
                label: labelTranslations['ATTENDANCE.ISSUES.LATE_CLOCK_OUT'],
                verbose: labelTranslations['ATTENDANCE.ISSUES.LATE_CLOCK_OUT_EXPLANATION'],
            },
        ];

        $scope.issueTypesById = {};
        $scope.issueTypes.forEach(function (i) { $scope.issueTypesById[i.id] = i; });

        var defaultEntityId = $stateParams.entity || SessionService.getEntity();
        var defaultDateStart = $stateParams.start && moment($stateParams.start).isValid() ?
            moment($stateParams.start) : moment().subtract($scope.defaultIssuePeriod, 'days').startOf('day');
        var defaultDateEnd = $stateParams.end && moment($stateParams.end).isValid() ?
            moment($stateParams.end) : moment().startOf('day');
        var defaultIssueTypes = $stateParams.type ? [parseInt($stateParams.type, 10)] : undefined;

        $scope.props = {
            issuesPerPage: 15,
            entityId: defaultEntityId,
            loadingData: false,
            data: [],
            issueTypeOptions: $scope.issueTypes,
            selectedIssueTypeOptions: defaultIssueTypes,
            defaultDateFilter: {
                option: 4,
                dateStart: defaultDateStart,
                dateEnd: defaultDateEnd,
            },
        };

        function evaluateDateParams() {
            $scope.props.selectedDateStart = $scope.props.selectedDateStart || defaultDateStart;
            $scope.props.selectedDateEnd = $scope.props.selectedDateEnd || defaultDateEnd;

            $scope.getDataParams.start = $scope.props.selectedDateStart.format();
            $scope.getDataParams.end = $scope.props.selectedDateEnd.clone().add(1, 'day').format();

            var entityId = $scope.getDataParams['entityIds[]'] ? $scope.getDataParams['entityIds[]'] : undefined;
            var type = $scope.getDataParams['issueTypeIds[]'] ? $scope.getDataParams['issueTypeIds[]'] : undefined;

            $state.go('.', {
                start: $scope.props.selectedDateStart.format('YYYY-MM-DD'),
                end: $scope.props.selectedDateEnd.format('YYYY-MM-DD'),
                entity: entityId,
                type: type,
            }, {
              notify: false,
              location: isFirstLoad ? true : 'replace',
            });

            if (isFirstLoad) {
              isFirstLoad = false;
            }
        }

        $scope.getDataParams = {
            'entityIds[]': [$scope.props.entityId],
            'currentPositionIds[]': [],
            'issueTypeIds[]': $scope.props.selectedIssueTypeOptions,
        };

        $scope.onEntitySelect = function (entity) {
            $scope.getDataParams['entityIds[]'] = entity ? entity.id : undefined;
            $scope.loadData();
        };

        $scope.onPositionFilter = function (selectedOptions) {
            var option = selectedOptions[0];
            $scope.getDataParams['currentPositionIds[]'] = option || [];
            $scope.loadData();
        };

        $scope.onUserFilter = function (selectedUser) {
            $scope.getDataParams['userIds[]'] = selectedUser ? selectedUser.id : undefined;
            $scope.loadData();
        };

        $scope.onIssueTypeFilter = function (selectedOptions) {
            var option = selectedOptions[0];
            $scope.getDataParams['issueTypeIds[]'] = option || undefined;
            $scope.loadData();
        };

        $scope.onDateFilter = function (state) {
            var diff = state.dateEnd.diff(state.dateStart, 'weeks', true);

            if (diff < 0 || diff > 5) {
                AlertService.add('info', 'You can\'t choose more than 5 weeks. Please restrict your date range.');
                return;
            }

            $scope.props.selectedDateStart = state.dateStart;
            $scope.props.selectedDateEnd = state.dateEnd;

            $scope.loadData();
        };

        EnvironmentDataService.fetchAll([
          EnvironmentDataService.DataType.EntityGroup,
          EnvironmentDataService.DataType.Position,
        ])
          .then(([
            entityGroup,
            position,
          ]) => {
            $scope.props.entities = entityGroup.data;
            $scope.props.positions = position.data.map((item) => ({
              ...item,
              label: item.name,
            }));

            $scope.loadData();
          });

        $scope.loadData = function () {
            if ($scope.props.loadingData) {
                return;
            }

            $scope.props.loadingData = true;

            evaluateDateParams();

            AttendanceService.getIssues($scope.getDataParams)
                .success(function (data) {
                    var buckets = [];

                    for (var date = $scope.props.selectedDateStart.clone(); !date.isAfter($scope.props.selectedDateEnd); date.add(1, 'day')) {
                        buckets.push({
                            start: date.clone(),
                            end: date.clone().add(1, 'day'),
                            rows: [],
                        });
                    }

                    data.attendanceIssues.forEach(function (row) {
                        var date = row.scheduledEvent ? row.scheduledEvent.start : (row.clockIn ? row.clockIn.date : row.clockOut.date);

                        var bucket = buckets.find(function (b) {
                            return !b.start.isAfter(date) && b.end.isAfter(date);
                        });

                        if (!bucket) {
                            return;
                        }

                        if (row.clockIn && row.clockOut) {
                            row.attendanceDayDiff = moment(row.clockOut.date).startOf('day').diff(moment(row.clockIn.date).startOf('day'), 'days');
                        }

                        bucket.rows.push(row);
                    });

                    data.facialRecognition.forEach(function (row) {
                        var ext = row.photoType === 2 ? 'jpg' : 'png';
                        row.imageSrc = 'https://s3-eu-west-1.amazonaws.com/rrcdn/tak/' + AuthService.getRealm() + '/' +
                            row.userId + '-' + row.id + '.' + ext;
                        row.confidencePercent = Math.round(row.confidence * 100);
                    });

                    $scope.props.data = {
                        issues: buckets,
                        facialRecognition: data.facialRecognition,
                        totalIssueCount: data.attendanceIssues.length,
                    };

                    NotificationService.dismiss(NotificationService.types.ATTENDANCE_NEW_ISSUES);

                    $scope.props.loadingData = false;
                })
                .error(function (error, status) {
                    if (status === 500) {
                        AlertService.add('danger', labelTranslations['ATTENDANCE.ISSUES.DATE_RANGE_TOO_WIDE']);
                        $state.go('attendance.overview');
                    }
                });
        };

        $scope.$on('shiftUpdate', function () {
            $scope.loadData();
        });

        $scope.$on('attendanceUpdate', function () {
            $scope.loadData();
        });

        $scope.viewShift = function (id) {
            $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'ViewShiftModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            id: id
                        };
                    }
                }
            });
        };

        $scope.dismissEvent = function (event, override, index) {
            event.isLoading = true;

            var fields = {
                override: override,
            };

            AttendanceService.dismissFacialConcern(event.id, fields)
                .success(function () {
                    $scope.props.data.facialRecognition.splice(index, 1);
                })
                .error(function () {
                    event.isLoading = false;
                    AlertService.add('danger', labelTranslations['ATTENDANCE.ISSUES.SOMETHING_WENT_WRONG']);
                });
        };

        $scope.viewEvent = function (event) {
            $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'ViewEventAttendanceModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            id: event.id,
                        };
                    }
                }
            });
        };

        $scope.resolve = function (issue) {
            if (issue.issueType === 1) {
                // Unmatched attendance
                AttendanceCommon.openModalUnmatchedAttendance(issue.clockIn.id, issue.clockIn.date,
                    issue.clockOut ? issue.clockOut.date : false, issue.user, function () {
                        $scope.loadData();
                    }, getUserNotes(issue));
            } else if (issue.issueType === 2) {
                // No clock-in
                var date = issue.scheduledEvent ? issue.scheduledEvent.start : moment();
                var entityId = issue.scheduledEvent ? issue.scheduledEvent.workEntityId : null;

                AttendanceCommon.openModalNoClockIn(issue.user, date, entityId, function () {
                    $scope.loadData();
                }, getUserNotes(issue));
            } else if (issue.issueType === 3) {
                // No clock-out
                var date = issue.scheduledEvent ? issue.scheduledEvent.end : moment();

                AttendanceCommon.openModalAddEvent(2, issue.user, date, issue.clockIn.entityId, false, function () {
                    $scope.loadData();
                }, getUserNotes(issue));
            } else if (issue.issueType === 9) {
                // Overtime requires approval (early clock-in)
                AttendanceCommon.openModalOvertimeApproval(true, issue.clockIn, issue.scheduledEvent, issue.user, function () {
                    $scope.loadData();
                }, getEarlyClockInNotes(issue));
            } else if (issue.issueType === 10) {
                // Overtime requires approval (late clock-out)
                AttendanceCommon.openModalOvertimeApproval(false, issue.clockOut, issue.scheduledEvent, issue.user, function () {
                    $scope.loadData();
                }, getLateClockOutNotes(issue));
            } else {
                $state.go('staff.view.attendance', {
                    id: issue.user.id,
                    event: issue.clockIn.id,
                });
            }
        };

        $scope.dismissIssue = function (issue, bucket, index) {
            if (!confirm(labelTranslations['ATTENDANCE.ISSUES.CONFIRM_DISMISS'])) {
                return;
            }

            var data = {
                issueTypeId: issue.issueType,
                userId: issue.user.id,
            };

            if (issue.clockIn) {
                data.clockInId = issue.clockIn.id;
            }

            if (issue.clockOut) {
                data.clockOutId = issue.clockOut.id;
            }

            if (issue.scheduledEvent) {
                data.scheduledEventId = issue.scheduledEvent.id;
            }

            issue.isDeleting = true;

            AttendanceService.dismissIssue(data)
                .success(function () {
                    bucket.rows.splice(index, 1);
                })
                .error(function () {
                    AlertService.add('danger', labelTranslations['ATTENDANCE.ISSUES.SOMETHING_WENT_WRONG']);
                });
        };

        $scope.canDismiss = function (issue) {
            return $scope.issueTypesById[issue.issueType].canDismiss;
        };
    }]);

function getUserNotes(issue) {
    return [issue.clockIn.notes, issue.clockOut.notes].filter(Boolean)
}
function getEarlyClockInNotes(issue) {
    return [issue.clockIn.notes].filter(Boolean)
}
function getLateClockOutNotes(issue) {
    return [issue.clockOut.notes].filter(Boolean)
}
