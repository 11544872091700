const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/payElements/editTypeModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/payElements/editTypeModal.html');

var controllers = require('../../module');

controllers.controller('SettingsPayElementTypesCtrl', ['$scope', '$uibModal', '$state', 'AlertService', 'SettingsService',
    function ($scope, $uibModal, $state, AlertService, SettingsService) {
        function loadData() {
            $scope.dataLoaded = false;

            SettingsService.getPayElementTypes()
                .success(function (data) {
                    $scope.payElementTypes = data.payElementTypes.filter(function (row) {
                        return !row.isDeleted;
                    });
                    $scope.dataLoaded = true;
                })
                .error(function (err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', 'We weren\'t able to load the pay types just then. Please try again');
                    }

                    $state.go('dashboard');
                });
        }

        loadData();

        $scope.addType = function () {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SettingsEditPayElementTypeModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: true
                        }
                    }
                }
            });

            modalInstance.result.then(function () {
                loadData();
            });
        };

        $scope.editType = function (index) {
            var type = $scope.payElementTypes[index];

            if (type.isLocked) {
                return;
            }

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'SettingsEditPayElementTypeModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: false,
                            type: type
                        }
                    }
                }
            });

            modalInstance.result.then(function () {
                loadData();
            });
        };
    }
]);
