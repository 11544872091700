const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/employment/appointmentForm.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/staff/view/employment/appointmentForm.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/staff/view/employment/leaverForm.html');
const autoNgTemplateLoaderTemplate4 = require('/home/circleci/project/app/htdocs/views/staff/view/danger/anonymiseForm.html');
const autoNgTemplateLoaderTemplate5 = require('/home/circleci/project/app/htdocs/views/staff/view/employment/employmentForm.html');

module.exports = (
  $rootScope,
  $scope,
  $state,
  $stateParams,
  $q,
  $translate,
  $uibModal,
  $uibModalDrawer,
  $timeout,
  AlertService,
  StaffService,
  StaffCommon,
  EnvironmentDataService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.VIEW.EMPLOYMENT.ERROR_500',
    'STAFF.VIEW.EMPLOYMENT.ERROR_403',
    'STAFF.VIEW.EMPLOYMENT.LABEL_HOURS',
    'STAFF.VIEW.EMPLOYMENT.LABEL_DAYS',
    'STAFF.VIEW.EMPLOYMENT.LABEL_WEEK',
    'STAFF.VIEW.EMPLOYMENT.LABEL_MONTH',
    'STAFF.VIEW.EMPLOYMENT.LABEL_CURRENT_EMPLOYMENT',
    'STAFF.VIEW.EMPLOYMENT.ALERT_CHANGES_SAVED',
    'STAFF.VIEW.EMPLOYMENT.CONFIRM_DELETE',
    'STAFF.VIEW.EMPLOYMENT.CONFIRM_REINSTATE',
  ]);

  const { userId } = $scope;
  const noticePeriodCategories = ['days', 'weeks', 'months'];
  const noticePeriodOptions = noticePeriodCategories.map((thing) => ({
    id: thing.charAt(0).toUpperCase(),
    label: moment.duration(2, thing).humanize({ d: 7, w: 4 }).split(' ')[1],
  }));
  let isFirstLoad = true;

  $scope.props = {
    loadingData: true,
    durationOptions: {
      units: ['y', 'mo', 'w'],
      maxDecimalPoints: 0,
      largest: 2,
    },
    obligationUnitById: {
      0: translations['STAFF.VIEW.EMPLOYMENT.LABEL_HOURS'],
      1: translations['STAFF.VIEW.EMPLOYMENT.LABEL_DAYS'],
    },
    obligationPeriodById: {
      0: translations['STAFF.VIEW.EMPLOYMENT.LABEL_WEEK'],
      1: translations['STAFF.VIEW.EMPLOYMENT.LABEL_MONTH'],
    },
    noticePeriodOptions,
    ukStarterDeclarationOptions: StaffService.getUkStarterDeclarationOptions(),
  };

  function onApiError(status) {
    $scope.props.loadingData = false;

    if (status === 500) {
      AlertService.add('danger', translations['STAFF.VIEW.EMPLOYMENT.ERROR_500']);
      return;
    }

    if (status === 403) {
      AlertService.add('warning', translations['STAFF.VIEW.EMPLOYMENT.ERROR_403']);
      return;
    }

    $state.go('staff.overview');
  }

  function getEmploymentDuration(employment) {
    const now = moment();
    const end = employment.openEnded ? undefined : moment(employment.dateEnd);
    const comparatorEnd = end && end.isBefore(now) ? end : now;
    return moment.duration(moment(employment.dateStart).diff(comparatorEnd));
  }

  function isActive(start, end, openEnded) {
    return start.isSameOrBefore() && (!openEnded ? end.isAfter() : true);
  }

  function getEmploymentWithId(id) {
    return $scope.props.employments.find((e) => e.id === id);
  }

  function getSelectedEmploymentId(switchToEmploymentId) {
    if (switchToEmploymentId) {
      return switchToEmploymentId;
    }

    const { lastChosenEmploymentId } = $scope.props;

    if (lastChosenEmploymentId && getEmploymentWithId(lastChosenEmploymentId)) {
      return lastChosenEmploymentId;
    }

    const { employmentId: selectedIdRaw } = $stateParams;

    if (selectedIdRaw) {
      const selectedId = parseInt(selectedIdRaw, 10);

      if (selectedId && getEmploymentWithId(selectedId)) {
        return selectedId;
      }
    }

    const activeEmployment = $scope.props.employments.find((e) => e.isActive);

    if (activeEmployment) {
      return activeEmployment.id;
    }

    return $scope.props.employments[0].id;
  }

  function loadData(switchToEmploymentId, onComplete) {
    $scope.props.loadingData = true;

    $q.all([
      StaffService.getEmployments(userId),
      StaffService.getAppointments(userId),
      EnvironmentDataService.fetchAll([
        EnvironmentDataService.DataType.RecruitmentSource,
        EnvironmentDataService.DataType.LeaverReason,
      ]),
    ]).then(([
      employmentsResult,
      appointmentsResult,
      environmentData,
    ]) => {
      const [
        recruitmentSource,
        leaverReason,
      ] = environmentData;

      $scope.props.recruitmentSources = recruitmentSource.data
        .map(({ id, title, deleted }) => ({ id, label: title, deleted }));

      $scope.props.leaverReasons = leaverReason.data
        .map(({ id, title, deleted }) => ({ id, label: title, deleted }));

      const { data: { employments } } = employmentsResult;
      const { data: { appointments } } = appointmentsResult;

      $scope.props.employments = employments.reverse().map((e) => {
        let { noticePeriod } = e;
        const noticePeriodDuration = moment.duration(noticePeriod);

        if (!noticePeriodDuration.isValid() || noticePeriodDuration.asMilliseconds() === 0) {
          noticePeriod = 'P0D';
        }

        const [, noticePeriodNumberStr, noticePeriodOption] = noticePeriod.split(/(\d+)/);
        const noticePeriodNumber = parseInt(noticePeriodNumberStr, 10);

        const {
          dateStart,
          dateEnd,
          openEnded,
        } = e;

        const start = moment(dateStart);
        const end = openEnded ? null : moment(dateEnd);

        const active = isActive(start, end, openEnded);
        const hasBegun = start.isBefore();
        const startDateToNow = hasBegun ? undefined : start.toNow(true);

        const endDateString = openEnded ? '' : ` - ${end.clone().subtract(1, 'day').format('L')}`;
        const tabTitle = active ? translations['STAFF.VIEW.EMPLOYMENT.LABEL_CURRENT_EMPLOYMENT'] : `${start.format('L')}${endDateString}`;

        return {
          ...e,
          duration: getEmploymentDuration(e),
          isActive: active,
          hasBegun,
          startDateToNow,
          tabTitle,
          isHistoricalLeaver: !openEnded && end.isBefore(),
          leaverReasonLabel: e.leaverReasonId
            ? $scope.props.leaverReasons.find((r) => r.id === e.leaverReasonId).label : undefined,
          noticePeriodNumber,
          noticePeriodOption,
          showNoticePeriodControls: !!noticePeriodNumber,
          appointments: [],
        };
      });

      appointments.reverse().forEach((a) => {
        const {
          employmentId,
          localDateStart,
          localDateEnd,
          openEnded,
        } = a;

        const start = moment(localDateStart);
        const end = openEnded ? null : moment(localDateEnd);

        const employment = getEmploymentWithId(employmentId);

        if (employment) {
          employment.appointments.push({
            ...a,
            isActive: isActive(start, end, openEnded),
          });
        }
      });

      $timeout(() => {
        $scope.props.loadingData = false;
        $scope.props.activeEmploymentId = getSelectedEmploymentId(switchToEmploymentId);
      }, 100);

      if (onComplete) {
        onComplete();
      }
    }).catch(({ status }) => onApiError(status));
  }

  $scope.onTabSelect = (employmentId) => {
    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.lastChosenEmploymentId = employmentId;

    $state.go('.', {
      employmentId,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }
  };

  $scope.editAppointment = (appointment, employment, isEmploymentAddFlow) => {
    const drawerInstance = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./appointmentForm'),
      resolve: {
        data: () => ({
          appointment,
          employment,
          isEmploymentAddFlow,
        }),
      },
    });

    drawerInstance.result.then((data) => {
      $rootScope.$broadcast('user:update');
      $scope.userData.flags.active = data.response.userStatusResult.active;
      loadData();
    });
  };

  $scope.addAppointment = (employment) => {
    const drawerInstance = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate2,
      controller: require('./appointmentForm'),
      resolve: {
        data: () => ({
          employment,
          appointment: employment.appointments[0],
          isAdd: true,
        }),
      },
    });

    drawerInstance.result.then((data) => {
      $scope.userData.flags.active = data.response.userStatusResult.active;
      loadData();
    });
  };

  $scope.startDateChanged = (newDate, employment) => {
    employment.dateStart = newDate.format('YYYY-MM-DD');
  };

  function executeEmploymentUpdate(employment, fields) {
    const {
      id,
      userId,
    } = employment;

    employment.saveActioning = true;

    StaffService.updateEmployment(id, userId, fields)
      .then(({ data }) => {
        AlertService.add('success', translations['STAFF.VIEW.EMPLOYMENT.ALERT_CHANGES_SAVED']);
        $scope.userData.flags.active = data.userStatusResult.active;
        employment.saveActioning = false;

        loadData(undefined, () => $rootScope.$broadcast('user:reload'));
      })
      .catch(({ status, data }) => {
        employment.saveActioning = false;
        employment.isReinstating = false;

        if (status === 400) {
          const modal = StaffCommon.onEmploymentValidationResponse(data.validationResult);

          modal.result.then((result) => {
            if (result === true) {
              executeEmploymentUpdate(employment, fields);
            }
          });

          return;
        }

        onApiError(status);
      });
  }

  function executeEmploymentDelete(employment) {
    const {
      id,
      userId,
    } = employment;

    employment.saveActioning = true;

    StaffService.deleteEmployment(id, userId)
      .then(({ data }) => {
        AlertService.add('success', translations['STAFF.VIEW.EMPLOYMENT.ALERT_CHANGES_SAVED']);
        $scope.userData.flags.active = data.userStatusResult.active;
        employment.saveActioning = false;

        loadData();
      })
      .catch(({ status, data }) => {
        employment.saveActioning = false;
        employment.isReinstating = false;

        if (status === 400) {
          const modal = StaffCommon.onEmploymentValidationResponse(data.validationResult);

          modal.result.then((result) => {
            if (result === true) {
              executeEmploymentDelete(employment);
            }
          });

          return;
        }

        onApiError(status);
      });
  }

  $scope.updateEmployment = (employment) => {
    const {
      dateStart,
      noticePeriodNumber,
      noticePeriodOption,
      employeeNumber,
      recruitmentSourceId,
      ukStarterDeclaration,
    } = employment;

    executeEmploymentUpdate(employment, {
      dateStart,
      noticePeriod: `P${noticePeriodNumber}${noticePeriodOption}`,
      employeeNumber,
      recruitmentSourceId,
      ukStarterDeclaration,
    });
  };

  $scope.endEmployment = (employment) => {
    if (!employment.openEnded || employment.saveActioning) {
      return;
    }

    const drawerInstance = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate3,
      controller: require('./leaverForm'),
      resolve: {
        data: () => ({
          employment,
        }),
      },
    });

    drawerInstance.result.then((data) => {
      $scope.userData.flags.active = data.response.userStatusResult.active;
      loadData(undefined, () => $rootScope.$broadcast('user:reload'));

      const anonymiseModal = $uibModalDrawer.open({
        templateUrl: autoNgTemplateLoaderTemplate4,
        controller: require('../danger/anonymiseForm'),
        resolve: {
          data: () => ({
            userId,
            employmentDateEnd: data.request.dateEnd,
          }),
        },
      });

      anonymiseModal.result.then(({ anonymisedDate }) => {
        $scope.userData.account.anonymisedDate = anonymisedDate || undefined;
      });
    });
  };

  $scope.deleteEmployment = (employment) => {
    if (employment.saveActioning) {
      return;
    }

    if (!confirm(translations['STAFF.VIEW.EMPLOYMENT.CONFIRM_DELETE'])) {
      return;
    }

    executeEmploymentDelete(employment);
  };

  $scope.reinstate = (employment) => {
    if (employment.isReinstating) {
      return;
    }

    if (!confirm(translations['STAFF.VIEW.EMPLOYMENT.CONFIRM_REINSTATE'])) {
      return;
    }

    employment.isReinstating = true;

    executeEmploymentUpdate(employment, {
      dateEnd: null,
      leaverRehire: null,
      leaverReasonId: null,
      leaverNotes: null,
      leaverHolidayProcessed: false,
    });
  };

  function newEmployment() {
    const drawerInstance = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate5,
      controller: require('./employmentForm'),
      resolve: {
        data: () => ({
          userId,
          noticePeriodOptions,
          activeAppointment: $scope.userData.flags.active,
          setupComplete: $scope.userData.flags.setupComplete,
        }),
      },
    });

    drawerInstance.result.then((data) => {
      const { employmentId } = data.response;

      loadData(employmentId, () => {
        const employment = getEmploymentWithId(employmentId);
        const [appointment] = employment.appointments;
        $scope.editAppointment(appointment, employment, true);
      });
    });
  }

  $scope.newEmployment = (employment, index) => {
    if (employment.openEnded || index !== 0) {
      return;
    }

    newEmployment();
  };

  loadData(null, () => {
    const { addNew } = $stateParams;

    if (addNew) {
      newEmployment();
    }
  });

  $scope.allowancesAttest = (employment) => {
    executeEmploymentUpdate(employment, {
      leaverHolidayProcessed: true,
    });
  };

  $scope.allowancesUndo = (employment) => {
    executeEmploymentUpdate(employment, {
      leaverHolidayProcessed: false,
    });
  };
};
