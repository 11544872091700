'use strict';
var controllers = require('../module');

controllers.controller('TwoButtonDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function ($scope, $uibModalInstance, data) {
        $scope.data = data;
        $scope.option = function (cancel, a) {
            if (cancel) {
                return $uibModalInstance.dismiss();
            }

            $uibModalInstance.close(a ? true : false);
        }
    }]);