var controllers = require('../../../module');

controllers.controller(
  'SettingsIntegrationFlowCtrl',
  [
    '$scope',
    '$state',
    'SettingsService',
    'AlertService',
    'EnvironmentDataService',
    function ($scope, $state, SettingsService, AlertService, EnvironmentDataService) {
      var integrationId = 'flow';

      $scope.dataLoaded = false;
      $scope.validationRequired = false;

      function handleError(error, status) {
        if (status !== 403) {
          AlertService.add('danger', 'We weren\'t able to load or modify the settings just then. Please try again');
        }
      }

      function convertKeyedObjectToArray(object, parseKeyToInteger) {
        if (object === undefined) {
          return [];
        }

        return Object.keys(object).map(function (key) {
          return {
            from: parseKeyToInteger ? parseInt(key, 10) : key,
            to: object[key],
          };
        });
      }

      function getValueOrDefault(value, defaultValue) {
        if (value === undefined) {
          return defaultValue;
        }

        return value;
      }

      function getSettings() {
        $scope.dataLoaded = false;

        SettingsService.getIntegrationSettings(integrationId)
          .success(function(data) {
            var loadedSettings = data.settings;
            var settings = angular.copy(loadedSettings);

            settings.status = getValueOrDefault(loadedSettings.status, 'false');

            settings.branchSource = parseInt(getValueOrDefault(loadedSettings.branchSource, 2), 10);
            settings.branchMappings = convertKeyedObjectToArray(loadedSettings.branchMappings,
              settings.branchSource === 1);
            settings.defaultBranch = getValueOrDefault(loadedSettings.defaultBranch, '');

            settings.departmentSource = parseInt(getValueOrDefault(loadedSettings.departmentSource, 1), 10);
            settings.departmentMappings = convertKeyedObjectToArray(loadedSettings.departmentMappings, false);
            settings.defaultDepartment = getValueOrDefault(loadedSettings.defaultDepartment, '');

            settings.jobTitleSource = parseInt(getValueOrDefault(loadedSettings.jobTitleSource, 3), 10);
            settings.jobTitleMappings = convertKeyedObjectToArray(loadedSettings.jobTitleMappings, true);
            settings.defaultJobTitle = getValueOrDefault(loadedSettings.defaultJobTitle, '');
            settings.traineeIdentifierPrefix = getValueOrDefault(loadedSettings.traineeIdentifierPrefix, '');

            $scope.validationRequired = settings.status === 'true';

            $scope.formData = settings;

            $scope.dataLoaded = true;
          })
          .error(function(err, status) {
            handleError(err, status);
          });
      }

      EnvironmentDataService.fetchAll([
        EnvironmentDataService.DataType.EntityGroup,
        EnvironmentDataService.DataType.Position,
        EnvironmentDataService.DataType.StaffGroup,
      ])
        .then(([
          entityGroup,
          position,
          staffGroup,
        ]) => {
          $scope.metadata = {
            entityGroups: entityGroup.data,
            positions: position.data.filter(({ deleted }) => !deleted),
            staffGroups: staffGroup.data.filter(({ deleted, entityId }) => !deleted && !entityId),
            entities: entityGroup.data.flatMap(({ entities }) => entities),
          };

          getSettings();
        });

      $scope.$watch('formData.status', function (newStatus) {
        $scope.validationRequired = newStatus === 'true';
      });

      $scope.addBranchMapping = function () {
        var defaultValue = $scope.formData.branchSource === 1
          ? $scope.metadata.entityGroups[0].id
          : $scope.metadata.entities[0].id;

        $scope.formData.branchMappings.push({
          from: defaultValue,
          to: ''
        });
      };

      $scope.removeBranchMapping = function (idx) {
        $scope.formData.branchMappings.splice(idx, 1);
      };

      $scope.onBranchSourceChange = function () {
        $scope.formData.branchMappings.length = 0;
      };

      $scope.addDepartmentMapping = function () {
        $scope.formData.departmentMappings.push({
          from: $scope.metadata.entities[0].id,
          to: ''
        });
      };

      $scope.removeDepartmentMapping = function (idx) {
        $scope.formData.departmentMappings.splice(idx, 1);
      };

      $scope.onDepartmentSourceChange = function () {
        $scope.formData.departmentMappings.length = 0;
      };

      $scope.addJobTitleMapping = function () {
        var defaultValue = $scope.formData.jobTitleSource === 1 ?
          $scope.metadata.staffGroups[0].id : $scope.metadata.positions[0].id;

        $scope.formData.jobTitleMappings.push({
          from: defaultValue,
          to: '',
        });
      };

      $scope.removeJobTitleMapping = function (idx) {
        $scope.formData.jobTitleMappings.splice(idx, 1);
      };

      $scope.onJobTitleSourceChange = function () {
        $scope.formData.jobTitleMappings.length = 0;
      };

      $scope.save = function () {
        var invalidBranchMappings = false;
        var invalidDepartmentMappings = false;
        var invalidJobTitleMappings = false;

        var convertedBranchMappings = {};
        var convertedDepartmentMappings = {};
        var convertedJobTitleMappings = {};

        angular.forEach($scope.formData.branchMappings, function (m) {
          if (!m.to || !m.to.trim()) {
            invalidBranchMappings = true;
          }

          convertedBranchMappings[m.from] = m.to;
        });

        angular.forEach($scope.formData.departmentMappings, function (m) {
          if (!m.to || !m.to.trim()) {
            invalidDepartmentMappings = true;
          }

          convertedDepartmentMappings[m.from] = m.to;
        });

        angular.forEach($scope.formData.jobTitleMappings, function (m) {
          if (!m.to || !m.to.trim()) {
            invalidJobTitleMappings = true;
          }

          convertedJobTitleMappings[m.from] = m.to;
        });

        if ($scope.validationRequired
          && (invalidBranchMappings || invalidDepartmentMappings || invalidJobTitleMappings)) {
          return alert('One or more of your branch/department/job title mappings are invalid.');
        }

        $scope.saveActioning = true;

        var settingsToSend = angular.merge({}, $scope.formData);

        settingsToSend.branchMappings = convertedBranchMappings;
        settingsToSend.departmentMappings = convertedDepartmentMappings;
        settingsToSend.jobTitleMappings = convertedJobTitleMappings;

        SettingsService.updateIntegrationSettings(integrationId, { settings: settingsToSend })
          .success(function () {
            $scope.saveActioning = false;
            AlertService.add('success', 'The settings have been successfully updated.');
          })
          .error(function (error, status) {
            $scope.saveActioning = false;
            handleError(error, status);
          });
      };
    }
  ]
);
