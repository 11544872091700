/*
READ THIS FIRST:
https://rotaready.atlassian.net/wiki/spaces/~5b239199be73de20759a9308/pages/3275751425/Asynchronous+Reports
*/

module.exports = (
  $scope,
  $state,
  $stateParams,
  $http,
  $translate,
  $uibModal,
  SessionService,
  EnvironmentDataService,
  AlertService,
  ExportService,
  ENDPOINT_API,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.LABOUR_BREAKDOWN.TITLE',
    'REPORTS.LABOUR_BREAKDOWN.PAY_AMOUNT_TYPE_HOURLY',
    'REPORTS.LABOUR_BREAKDOWN.PAY_AMOUNT_TYPE_DAILY',
    'REPORTS.LABOUR_BREAKDOWN.PAY_AMOUNT_TYPE_ANNUAL',
    'REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_SHIFT',
    'REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_ABSENCE',
    'REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_SALARY',
    'REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_TAXES',
    'REPORTS.LABOUR_BREAKDOWN.DATA_SOURCE_SHIFTS',
    'REPORTS.LABOUR_BREAKDOWN.DATA_SOURCE_MATCHED',
    'REPORTS.LABOUR_BREAKDOWN.DATE_ERROR_DIFF',
    'REPORTS.LABOUR_BREAKDOWN.BEHAVIOUR_COST_CONTROL',
    'REPORTS.LABOUR_BREAKDOWN.BEHAVIOUR_PAYROLL',
    'REPORTS.LABOUR_BREAKDOWN.ERROR_500',
    'REPORTS.LABOUR_BREAKDOWN.ERROR_400',
  ]);

  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];
  const defaultDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('week').subtract(1, 'week');
  const defaultDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('week').subtract(1, 'day');
  const defaultPayAmountTypes = $stateParams.payAmountTypes
    ? $stateParams.payAmountTypes.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];
  const defaultDataSources = $stateParams.dataSource ? [$stateParams.dataSource] : ['sanitised'];
  const defaultBehaviours = $stateParams.behaviour ? [$stateParams.behaviour] : ['costControl'];
  const defaultUserId = $stateParams.userId || undefined;
  const defaultShiftTypeIds = $stateParams.shiftTypeIds
    ? $stateParams.shiftTypeIds.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];

  const payAmountTypes = new Map([
    [0, { id: 0, label: translations['REPORTS.LABOUR_BREAKDOWN.PAY_AMOUNT_TYPE_HOURLY'] }],
    [2, { id: 2, label: translations['REPORTS.LABOUR_BREAKDOWN.PAY_AMOUNT_TYPE_DAILY'] }],
    [1, { id: 1, label: translations['REPORTS.LABOUR_BREAKDOWN.PAY_AMOUNT_TYPE_ANNUAL'] }],
  ]);

  const distinctEventTypes = [
    { id: 'shift', label: translations['REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_SHIFT'], eventTypeIds: [1, 2, 3] },
    { id: 'absence', label: translations['REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_ABSENCE'], eventTypeIds: [4] },
    { id: 'salary', label: translations['REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_SALARY'], eventTypeIds: [5] },
    { id: 'taxes', label: translations['REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_TAXES'], eventTypeIds: [6] },
  ];

  const eventTypes = new Map([
    [1, {
      name: translations['REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_SHIFT'],
      showTime: true,
      showShiftType: true,
    }],
    [2, {
      name: translations['REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_SHIFT'],
      showTime: true,
      showShiftType: true,
    }],
    [3, {
      name: translations['REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_SHIFT'],
      showTime: true,
      showShiftType: true,
    }],
    [4, {
      name: translations['REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_ABSENCE'],
      showTime: false,
      showShiftType: false,
    }],
    [5, {
      name: translations['REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_SALARY'],
      showTime: false,
      showShiftType: false,
    }],
    [6, {
      name: translations['REPORTS.LABOUR_BREAKDOWN.EVENT_TYPE_TAXES'],
      showTime: false,
      showShiftType: false,
    }],
  ]);

  $scope.props = {
    loadingData: false,
    data: [],
    defaultDateFilter: {
      option: 4,
      dateStart: defaultDateStart,
      dateEnd: defaultDateEnd,
    },
    defaultEntityIds,
    payAmountTypes: Array.from(payAmountTypes.values()),
    selectedDateStart: defaultDateStart,
    selectedDateEnd: defaultDateEnd,
    selectedEntityIds: defaultEntityIds,
    selectedPayAmountTypes: defaultPayAmountTypes,
    selectedShiftTypeIds: defaultShiftTypeIds,
    defaultShiftTypeIds,
    entityGroupsById: {},
    entityById: {},
    eventTypeById: Object.fromEntries(eventTypes),
    distinctEventTypes,
    selectedDistinctEventTypes: [],
    dataSources: [
      { id: 'shifts', label: translations['REPORTS.LABOUR_BREAKDOWN.DATA_SOURCE_SHIFTS'] },
      { id: 'sanitised', label: translations['REPORTS.LABOUR_BREAKDOWN.DATA_SOURCE_MATCHED'] },
    ],
    behaviours: [
      { id: 'costControl', label: translations['REPORTS.LABOUR_BREAKDOWN.BEHAVIOUR_COST_CONTROL'] },
      { id: 'payroll', label: translations['REPORTS.LABOUR_BREAKDOWN.BEHAVIOUR_PAYROLL'] },
    ],
    selectedBehaviours: defaultBehaviours,
    selectedDataSources: defaultDataSources,
    selectedUserId: defaultUserId,
    showCalendarWeekAlert: false,
    shiftTypeById: {},
  };

  function evaluateQueryParams() {
    $state.go('.', {
      start: $scope.props.selectedDateStart.format('YYYY-MM-DD'),
      end: $scope.props.selectedDateEnd.format('YYYY-MM-DD'),
      entityIds: $scope.props.selectedEntityIds ? $scope.props.selectedEntityIds.join(',') : undefined,
      payAmountTypes: $scope.props.selectedPayAmountTypes ? $scope.props.selectedPayAmountTypes.join(',') : undefined,
      dataSource: $scope.props.selectedDataSources[0],
      userId: $scope.props.selectedUserId,
      behaviour: $scope.props.selectedBehaviours[0],
      shiftTypeIds: $scope.props.selectedShiftTypeIds ? $scope.props.selectedShiftTypeIds.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $scope.getDataParams = {
      startDate: $scope.props.selectedDateStart.format(),
      endDate: $scope.props.selectedDateEnd.clone().add(1, 'day').format(),
      timezone: SessionService.getTimezone(),
      'entityIds[]': $scope.props.selectedEntityIds,
      'userPayAmountTypes[]': $scope.props.selectedPayAmountTypes,
      includeAbsence: true,
      dataSource: $scope.props.selectedDataSources[0],
      userId: $scope.props.selectedUserId,
      includeAccruedHoliday: true,
      'shiftTypeIds[]': $scope.props.selectedShiftTypeIds,
    };
  }

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.props.selectedEntityIds = entityIds.length ? entityIds : undefined;
    $scope.loadData();
  };

  $scope.onPayAmountTypeFilter = (selectedOptions) => {
    $scope.props.selectedPayAmountTypes = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData();
  };

  $scope.onEventTypeFilter = (selectedOptions) => {
    $scope.props.selectedDistinctEventTypes = selectedOptions.length ? selectedOptions : [];
  };

  $scope.onDataSourceFilter = (selectedOptions) => {
    $scope.props.selectedDataSources = selectedOptions;
    $scope.loadData();
  };

  $scope.onUserFilter = (selectedUser) => {
    $scope.props.selectedUserId = selectedUser ? selectedUser.id : undefined;
    $scope.loadData();
  };

  $scope.onBehaviourToggle = (selectedOptions) => {
    $scope.props.selectedBehaviours = selectedOptions;
    $scope.loadData();
  };

  $scope.onShiftTypeFilter = (selectedOptions) => {
    $scope.props.selectedShiftTypeIds = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData();
  };

  $scope.rowFilter = (row) => {
    if (!$scope.props.selectedDistinctEventTypes.length) {
      return true;
    }

    const { eventType } = row.event;
    const permittedEventTypeIds = $scope.props.selectedDistinctEventTypes.flatMap((id) => (
      distinctEventTypes.find((d) => d.id === id).eventTypeIds));

    return permittedEventTypeIds.includes(eventType);
  };

  $scope.onDateFilter = ({
    dateEnd,
    dateStart,
  }) => {
    $scope.props.selectedDateStart = dateStart;
    $scope.props.selectedDateEnd = dateEnd;

    $scope.loadData();
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    let dateStart;
    let dateEnd;

    if (when === 'thisWeek') {
      dateStart = moment().startOf('week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastWeek') {
      dateStart = moment().startOf('week').subtract(1, 'week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastTwoWeeks') {
      dateStart = moment().startOf('week').subtract(2, 'weeks');
      dateEnd = dateStart.clone().add(13, 'days');
    } else if (when === 'lastMonth') {
      dateStart = moment().startOf('month').subtract(1, 'month');
      dateEnd = moment().startOf('month').subtract(1, 'day');
    }

    $scope.props.defaultDateFilter = {
      option: 4,
      dateStart,
      dateEnd,
    };

    $scope.onDateFilter({
      dateStart,
      dateEnd,
    });
  };

  $scope.loadData = () => {
    $scope.props.hasRequiredFilters = ($scope.props.selectedEntityIds
      && $scope.props.selectedEntityIds.length) || $scope.props.selectedUserId;

    if (!$scope.props.hasRequiredFilters) {
      return;
    }

    const diff = $scope.props.selectedDateEnd.clone().add(1, 'day').diff($scope.props.selectedDateStart, 'weeks', true);

    const firstDow = parseInt(SessionService.getSetting('locale.firstDow'), 10);
    $scope.props.showCalendarWeekAlert = $scope.props.selectedDateStart.day() !== firstDow
      || $scope.props.selectedDateEnd.clone().add(1, 'day').day() !== firstDow;

    if (diff < 0 || diff > 14) {
      AlertService.add('info', translations['REPORTS.LABOUR_BREAKDOWN.DATE_ERROR_DIFF']);
      return;
    }

    evaluateQueryParams();

    $scope.props.loadingData = true;

    $http.get(`${ENDPOINT_API}/report/labourBreakdown`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const {
          events,
          users,
        } = data;

        $scope.props.data = events.map((event) => {
          const user = users.find((u) => u.id === event.userId);

          // When the desired behaviour is 'cost control like', zero the non-contributing
          // events instead of filtering them out.
          if ($scope.props.selectedBehaviours[0] === 'costControl' && !event.contributesToCostControl) {
            event.pay = {
              ...event.pay,
              durationPaid: 0,
              durationUnpaid: 0,
              durationAccruedHoliday: 0,
              base: 0,
              accruedHoliday: 0,
              tax: 0,
              wageUplift: 0,
              totalPay: 0,
            };
          }

          return {
            event,
            user,
          };
        });

        $scope.props.data.sort((a, b) => a.user.id - b.user.id);
        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.LABOUR_BREAKDOWN.ERROR_500']);
        }

        if (status === 400) {
          AlertService.add('danger', translations['REPORTS.LABOUR_BREAKDOWN.ERROR_400']);
        }
      });
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.ShiftType,
  ])
    .then(([
      entityGroup,
      shiftType,
    ]) => {
      $scope.props.groupedEntities = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => {
            $scope.props.entityById[entity.id] = entity;

            return {
              id: entity.id,
              label: entity.name,
              parentId: entity.groupId,
              depth: 1,
            };
          }),
        ]));

      entityGroup.data.forEach((group) => {
        $scope.props.entityGroupsById[group.id] = group;
      });

      shiftType.data.forEach((type) => {
        $scope.props.shiftTypeById[type.id] = type;
      });

      const shiftTypeList = shiftType.data.map((type) => ({
        ...type,
        label: type.concatenatedName,
      }));

      shiftTypeList.sort((a, b) => a.label.localeCompare(b.label));

      $scope.props.shiftTypeList = shiftTypeList;

      $scope.loadData();
    });

  $scope.export = ($event, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.LABOUR_BREAKDOWN.TITLE'],
      'exportTable', format);
  };
};
