module.exports = (
  $scope,
  $uibModalInstance,
  $state,
  $translate,
  AlertService,
  AttendanceService,
  EnvironmentDataService,
  data,
) => {
  'ngInject';

  const { device } = data;

  $scope.props = {
    device,
    loadingData: true,
    mappedEntityIds: [],
    formData: {
      name: device.name,
    },
  };

  const translations = $translate.instant([
    'SETTINGS.TRUSTED_DEVICES.EDIT_DEVICE_MODAL.ALERT_200',
    'SETTINGS.TRUSTED_DEVICES.EDIT_DEVICE_MODAL.ALERT_500',
  ]);

  $scope.close = () => {
    $uibModalInstance.dismiss();
  };

  $scope.submit = () => {
    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.loadingData = true;

    const {
      mappedEntityIds: entityIds,
      formData: {
        name,
      },
    } = $scope.props;

    AttendanceService.updateTrustedDevice(device.id, { name })
      .then(() => {
        AttendanceService.updateTrustedDeviceMappings(device.id, { entityIds })
          .then(() => {
            AlertService.add('success', translations['SETTINGS.TRUSTED_DEVICES.EDIT_DEVICE_MODAL.ALERT_200']);
            $uibModalInstance.close(true);
          })
          .catch(() => {
            AlertService.add('danger', translations['SETTINGS.TRUSTED_DEVICES.EDIT_DEVICE_MODAL.ALERT_500']);
            $scope.close();
          });
      })
      .catch(() => {
        AlertService.add('danger', translations['SETTINGS.TRUSTED_DEVICES.EDIT_DEVICE_MODAL.ALERT_500']);
        $scope.close();
      });
  };

  $scope.toggleEntity = (entity) => {
    const { id } = entity;
    const index = $scope.props.mappedEntityIds.indexOf(id);

    if (index === -1) {
      $scope.props.mappedEntityIds.push(id);
    } else {
      $scope.props.mappedEntityIds.splice(index, 1);
    }
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
    .then(({ data }) => {
      $scope.props.entityGroupList = data.filter((d) => !d.deleted);

      AttendanceService.getTrustedDeviceMappings(device.id)
        .then(({ data }) => {
          $scope.props.mappedEntityIds = data.results.map(({ entityId }) => entityId);
          $scope.props.loadingData = false;
        })
        .catch(() => $scope.close());
    });
};
