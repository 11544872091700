'use strict';
var services = require('./module');

services.factory('RotaService', ['$http', 'SessionService', 'ENDPOINT_API',
    function ($http, SessionService, ENDPOINT_API) {
        var baseUrl = ENDPOINT_API + '/rota/';
        var dateFormat = 'YYYY-MM-DD';

        return {
            getShifts: function (start, end, rotaId, userId) {
                return $http.get(baseUrl + 'shift', {
                    params: {
                        start: start.format(),
                        end: end.format(),
                        workEntity: SessionService.getEntity(),
                        rotaId: rotaId || undefined,
                        userId: userId,
                    }
                });
            },
            getShiftsPaginated: (params) => $http.get(`${baseUrl}shift/paginated`, { params }),
            updateShift: function (id, data) {
                return $http.put(baseUrl + 'shift/' + id, data);
            },
            deleteShiftCollaborative: (params) => $http.delete(`${baseUrl}shift/collaborative`, { params }),
            addShift: function (data) {
                return $http.post(baseUrl + 'shift', data);
            },
            getShift: function (id) {
                return $http.get(baseUrl + 'shift/' + id);
            },
            deleteShift: function (id, userId, ignoreValidation, excludeValidationIssueTypes) {
                var url = baseUrl + 'shift/' + id;

                if (userId !== false) {
                    url += '/' + userId;
                }

                return $http.delete(url, {
                    params: {
                        ignoreValidation: ignoreValidation,
                        excludeValidationIssueTypes: excludeValidationIssueTypes ?
                            excludeValidationIssueTypes.join(',') : undefined,
                    }
                });
            },

            getCalculatedProperties: function (propertyTypes, start, end, userIds, workEntityId, shiftTypeId) {
                return $http.get(baseUrl + 'shift/calculatedProperties', {
                    params: {
                        propertyTypes: propertyTypes.join(','),
                        start: start.format('YYYY-MM-DD HH:mm:ss'),
                        end: end.format('YYYY-MM-DD HH:mm:ss'),
                        userIds: userIds.join(','),
                        workEntityId: workEntityId,
                        shiftTypeId: shiftTypeId
                    }
                });
            },

            getShiftLocations: function () {
                return $http.get(baseUrl + 'shiftLocation');
            },
            addShiftLocation: function (data) {
                return $http.post(baseUrl + 'shiftLocation', data);
            },
            updateShiftLocation: function (id, data) {
                return $http.put(baseUrl + 'shiftLocation/' + id, data);
            },
            deleteShiftLocation: function (id) {
                return $http.delete(baseUrl + 'shiftLocation/' + id);
            },
            getOpenShiftLocations: function (start, end, entityId, draftRotaId, capacityRequired) {
                return $http.get(baseUrl + 'shiftLocation/open', {
                    params: {
                        start: start.format(),
                        end: end.format(),
                        entityId: entityId,
                        draftRotaId: draftRotaId,
                        capacityRequired: capacityRequired,
                    },
                });
            },

            getShiftTypes: function () {
                return $http.get(baseUrl + 'shiftType');
            },
            addShiftType: function (data) {
                return $http.post(baseUrl + 'shiftType', data);
            },
            updateShiftType: function (id, data) {
                return $http.put(baseUrl + 'shiftType/' + id, data);
            },
            deleteShiftType: function (id) {
                return $http.delete(baseUrl + 'shiftType/' + id);
            },

            getRotasSpanningDate: function (date, entityId) {
                return $http.get(baseUrl + 'helpers/spanningDate', {
                    params: {
                        date: date.format(dateFormat),
                        entityId,
                    },
                });
            },

            getAvailableStaffOnDate: function (date, entityId) {
                return $http.get(baseUrl + 'helpers/availableStaff', {
                    params: {
                        date: date.format(dateFormat),
                        entityId,
                    },
                });
            },

            getSwapRequests: function(params) {
                return $http.get(baseUrl + 'shift/swaps', { params: params });
            },
            approveSwapRequest: function(id, data) {
                return $http.put(baseUrl + 'shift/offer/' + id + '/approve', data);
            },
            rejectSwapRequest: function(id) {
                return $http.put(baseUrl + 'shift/offer/' + id + '/reject');
            },

            bulkUpdateShifts: function(data) {
                return $http.put(baseUrl + 'shift/bulk', data);
            },

            getStaffingProfile: function (date, entityId) {
                return $http.get(baseUrl + 'dashboard/chart/staffingProfile',
                {
                    params: {
                        date: date.format('YYYY-MM-DD'),
                        entityId,
                    },
                    responseStatusAuthority: [403]
                });
            },

            getWeekComparison: function(week1, week2, entityId) {
                return $http.get(baseUrl + 'dashboard/chart/weekComparison', {
                    params: {
                        weeks: [week1.format('YYYY-MM-DD'), week2.format('YYYY-MM-DD')].join(','),
                        entityId,
                    },
                    responseStatusAuthority: [403]
                });
            },

            getRotaFinancials: function(week, project, entityId) {
                return $http.get(baseUrl + 'dashboard/chart/rotaFinancials', {
                    params: {
                        week: week.format('YYYY-MM-DD'),
                        project,
                        entityId,
                    },
                    responseStatusAuthority: [403]
                });
            },

            getDashboardWeeks: function(weeks, entityId) {
                return $http.get(baseUrl + 'dashboard/weeks', {
                    params: {
                        weeks: weeks.map(function (week) { return week.format('YYYY-MM-DD') }).join(','),
                        entityId,
                    }
                });
            },

            getWtdViolations: function (startDate, endDate, entityId) {
                return $http.get(baseUrl + 'wtdViolations', {
                    params: {
                        startDate: startDate.format('YYYY-MM-DD'),
                        endDate: endDate.format('YYYY-MM-DD'),
                        userCurrentEntityIds: [entityId],
                    },
                });
            },

            recalculateWtdViolations: function (entityId) {
                return $http.put(baseUrl + 'wtdViolations', { entityId });
            },
        }
    }]);
