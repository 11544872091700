module.exports = (
  $scope,
  $http,
  $window,
  $translate,
  AlertService,
  ENDPOINT_API,
  WFM_ENDPOINT,
) => {
  'ngInject';

  const translations = $translate.instant([
    'REPORTS.TRONC_STATUS.LIFECYCLE_DRAFT',
    'REPORTS.TRONC_STATUS.LIFECYCLE_MARKED_FOR_APPROVAL',
    'REPORTS.TRONC_STATUS.LIFECYCLE_LOCKED',
    'REPORTS.TRONC_STATUS.ERROR_500',
  ]);

  $scope.props = {
    startDate: moment().subtract(1, 'week').startOf('week'),
    loadingData: false,
    data: [],
    lifecycleStates: [
      { id: 'DRAFT', label: translations['REPORTS.TRONC_STATUS.LIFECYCLE_DRAFT'] },
      { id: 'MARKED_FOR_APPROVAL', label: translations['REPORTS.TRONC_STATUS.LIFECYCLE_MARKED_FOR_APPROVAL'] },
      { id: 'LOCKED', label: translations['REPORTS.TRONC_STATUS.LIFECYCLE_LOCKED'] },
    ],
    defaultLifecycleStates: [],
    includeEntitiesWithNoHeader: true,
  };

  $scope.getDataParams = {
    weekStartDate: $scope.props.startDate.format('YYYY-MM-DD'),
    includeEntitiesWithNoHeader: $scope.props.includeEntitiesWithNoHeader,
  };

  $scope.onLifecycleStateFilter = (selectedOptions) => {
    $scope.getDataParams['lifecycles[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData();
  };

  $scope.onNoHeaderFilter = function () {
    $scope.getDataParams.includeEntitiesWithNoHeader = $scope.props.includeEntitiesWithNoHeader;
    $scope.loadData();
  };

  $scope.onDateChange = (newDate) => {
    const weekStartDate = newDate.startOf('week').clone();
    $scope.getDataParams.weekStartDate = weekStartDate.format('YYYY-MM-DD');

    $scope.loadData();
  };

  $scope.loadData = () => {
    $scope.props.loadingData = true;

    $http.get(`${ENDPOINT_API}/report/troncStatus`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const {
          entities,
          headers,
          groups,
        } = data;

        $scope.props.data = groups.reduce((acc, group) => {
          const filteredEntities = entities.filter((e) => e.groupId === group.id);

          if (filteredEntities.length) {
            acc.push({
              group,
              entities: filteredEntities.map((entity) => {
                const header = headers.find((h) => h.entityId === entity.id);

                if (header) {
                  header.editedWhenFromNow = moment(header.editedWhen).fromNow();
                }

                return {
                  entity,
                  header,
                };
              }),
            });
          }

          return acc;
        }, []);

        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.TRONC_STATUS.ERROR_500']);
        }
      });
  };

  $scope.viewTronc = (entityId) => {
    const startDate = $scope.props.startDate.format('YYYY-MM-DD');
    $window.location = `${WFM_ENDPOINT}/tronc?startDate=${startDate}&entityId=${entityId}`;
  };

  $scope.loadData();
};
