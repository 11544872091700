var services = require('./module');

services.factory('CommonService', ['$http', 'ENDPOINT_API',
    function ($http, ENDPOINT_API) {
        var baseUrl = ENDPOINT_API + '/common/';

        return {
            getMetadata: function(include, entities, global, userId) {
                return $http.get(baseUrl + 'metadata', { params: {
                    include: include.join(','),
                    entities: entities? entities.join(',') : null,
                    global: global,
                    userId: userId
                }});
            }
        }
    }]);
