const dateFilterToInterval = (state) => {
  const {
    option,
    dateStart,
    dateEnd,
  } = state;

  let interval;

  if (option === 2) {
    interval = [moment('1900-01-01').format('YYYY-MM-DD'), dateStart.format('YYYY-MM-DD')];
  } else if (option === 3) {
    interval = [dateStart.clone().add(1, 'day').format('YYYY-MM-DD'), moment('2100-01-01').format('YYYY-MM-DD')];
  } else if (option === 4) {
    interval = [dateStart.format('YYYY-MM-DD'), dateEnd.clone().add(1, 'day').format('YYYY-MM-DD')];
  }

  return interval ? interval.join('/') : undefined;
};

const parseDateFilterToWire = ({
  option,
  dateStart,
  dateEnd,
}) => ({
  option,
  dateStart: dateStart ? dateStart.format('YYYY-MM-DD') : undefined,
  dateEnd: dateEnd ? dateEnd.format('YYYY-MM-DD') : undefined,
});

const parseDateFilterFromWire = ({
  option,
  dateStart,
  dateEnd,
}) => ({
  option,
  dateStart: dateStart ? moment(dateStart) : undefined,
  dateEnd: dateEnd ? moment(dateEnd) : undefined,
});

// eslint-disable-next-line import/prefer-default-export
export const getFilterRegistry = (
  $translate,
  EnvironmentDataService,
  defaultEntityId,
) => {
  const translations = $translate.instant([
    'STAFF.OVERVIEW.FILTER_LABEL_SITE',
    'STAFF.OVERVIEW.FILTER_LABEL_POSITION',
    'STAFF.OVERVIEW.FILTER_LABEL_STAFF_GROUP',
    'STAFF.OVERVIEW.FILTER_LABEL_EMPLOYMENT',
    'STAFF.OVERVIEW.FILTER_LABEL_APPROVAL_STATUS',
    'STAFF.OVERVIEW.FILTER_LABEL_EMPLOYEE_SETUP',
    'STAFF.OVERVIEW.FILTER_LABEL_EMPLOYER_SETUP',
    'STAFF.OVERVIEW.FILTER_LABEL_EMPLOYMENT_START',
    'STAFF.OVERVIEW.FILTER_LABEL_EMPLOYMENT_END',
    'STAFF.OVERVIEW.FILTER_LABEL_EMPLOYMENT_START_OR_END',
    'STAFF.OVERVIEW.EMPLOYMENT_STATUS_ACTIVE',
    'STAFF.OVERVIEW.EMPLOYMENT_STATUS_INACTIVE',
    'STAFF.OVERVIEW.APPROVAL_STATUS_APPROVED',
    'STAFF.OVERVIEW.APPROVAL_STATUS_NOT_APPROVED',
    'STAFF.OVERVIEW.EMPLOYEE_SETUP_COMPLETE',
    'STAFF.OVERVIEW.EMPLOYEE_SETUP_NOT_COMPLETE',
    'STAFF.OVERVIEW.EMPLOYER_SETUP_COMPLETE',
    'STAFF.OVERVIEW.EMPLOYER_SETUP_NOT_COMPLETE',
  ]);

  const registry = new Map();

  registry.set('entityIds', {
    id: 'entityIds',
    label: translations['STAFF.OVERVIEW.FILTER_LABEL_SITE'],
    defaultValue: [defaultEntityId],
    fromStateParams: ({ entityIds }) => ({
      isEnabled: entityIds && entityIds.length,
      value: entityIds,
    }),
    toStateParams: (selectedOptions) => {
      const entityIds = selectedOptions.filter((o) => typeof o === 'string');
      return {
        entityIds: entityIds.length ? entityIds : undefined,
      };
    },
    toRequestParams: (selectedOptions) => {
      const entityIds = selectedOptions.filter((o) => typeof o === 'string');
      return {
        'currentEntityIds[]': entityIds.length ? entityIds : undefined,
      };
    },
    getOptions: async () => {
      const { data } = await EnvironmentDataService
        .fetch(EnvironmentDataService.DataType.EntityGroup);

      return data.flatMap(({
        id: groupId,
        name,
        entities,
        deleted,
      }) => ([
        {
          id: groupId,
          label: name,
          depth: 0,
          deleted,
        },
        ...entities.map((entity) => ({
          id: entity.id,
          label: entity.name,
          parentId: entity.groupId,
          depth: 1,
        })),
      ]));
    },
  });

  registry.set('currentPositionIds', {
    id: 'currentPositionIds',
    label: translations['STAFF.OVERVIEW.FILTER_LABEL_POSITION'],
    fromStateParams: ({ currentPositionIds }) => ({
      isEnabled: currentPositionIds && currentPositionIds.length,
      value: currentPositionIds,
    }),
    toStateParams: (selectedOptions) => ({
      currentPositionIds: selectedOptions.length ? selectedOptions : undefined,
    }),
    toRequestParams: (selectedOptions) => ({
      'currentPositionIds[]': selectedOptions.length ? selectedOptions : undefined,
    }),
    getOptions: async () => {
      const { data } = await EnvironmentDataService
        .fetch(EnvironmentDataService.DataType.Position);

      return data.map(({ id, name, deleted }) => ({ id, label: name, deleted }));
    },
  });

  registry.set('currentStaffGroupIds', {
    id: 'currentStaffGroupIds',
    label: translations['STAFF.OVERVIEW.FILTER_LABEL_STAFF_GROUP'],
    fromStateParams: ({ currentStaffGroupIds }) => ({
      isEnabled: currentStaffGroupIds && currentStaffGroupIds.length,
      value: currentStaffGroupIds,
    }),
    toStateParams: (selectedOptions) => ({
      currentStaffGroupIds: selectedOptions.length ? selectedOptions : undefined,
    }),
    toRequestParams: (selectedOptions) => ({
      'currentStaffGroupIds[]': selectedOptions.length ? selectedOptions : undefined,
    }),
    getOptions: async () => {
      const { data } = await EnvironmentDataService
        .fetch(EnvironmentDataService.DataType.StaffGroup);

      return data.map(({ id, name, deleted }) => ({ id, label: name, deleted }));
    },
  });

  registry.set('employmentActive', {
    id: 'employmentActive',
    label: translations['STAFF.OVERVIEW.FILTER_LABEL_EMPLOYMENT'],
    defaultValue: [true],
    fromStateParams: ({ employmentActive }) => ({
      isEnabled: typeof employmentActive === 'boolean',
      value: [employmentActive],
    }),
    toStateParams: (selectedOptions) => ({
      employmentActive: selectedOptions[0],
    }),
    toRequestParams: (selectedOptions) => ({
      employmentActive: selectedOptions[0],
    }),
    getOptions: async () => ([
      { id: true, label: translations['STAFF.OVERVIEW.EMPLOYMENT_STATUS_ACTIVE'] },
      { id: false, label: translations['STAFF.OVERVIEW.EMPLOYMENT_STATUS_INACTIVE'] },
    ]),
  });

  registry.set('accountApproved', {
    id: 'accountApproved',
    label: translations['STAFF.OVERVIEW.FILTER_LABEL_APPROVAL_STATUS'],
    fromStateParams: ({ accountApproved }) => ({
      isEnabled: typeof accountApproved === 'boolean',
      value: [accountApproved],
    }),
    toStateParams: (selectedOptions) => ({
      accountApproved: selectedOptions[0],
    }),
    toRequestParams: (selectedOptions) => ({
      accountApproved: selectedOptions[0],
    }),
    getOptions: async () => ([
      { id: true, label: translations['STAFF.OVERVIEW.APPROVAL_STATUS_APPROVED'] },
      { id: false, label: translations['STAFF.OVERVIEW.APPROVAL_STATUS_NOT_APPROVED'] },
    ]),
  });

  registry.set('accountSetupComplete', {
    id: 'accountSetupComplete',
    label: translations['STAFF.OVERVIEW.FILTER_LABEL_EMPLOYEE_SETUP'],
    fromStateParams: ({ accountSetupComplete }) => ({
      isEnabled: typeof accountSetupComplete === 'boolean',
      value: [accountSetupComplete],
    }),
    toStateParams: (selectedOptions) => ({
      accountSetupComplete: selectedOptions[0],
    }),
    toRequestParams: (selectedOptions) => ({
      accountSetupComplete: selectedOptions[0],
    }),
    getOptions: async () => ([
      { id: true, label: translations['STAFF.OVERVIEW.EMPLOYEE_SETUP_COMPLETE'] },
      { id: false, label: translations['STAFF.OVERVIEW.EMPLOYEE_SETUP_NOT_COMPLETE'] },
    ]),
  });

  registry.set('employerSetupComplete', {
    id: 'employerSetupComplete',
    label: translations['STAFF.OVERVIEW.FILTER_LABEL_EMPLOYER_SETUP'],
    fromStateParams: ({ employerSetupComplete }) => ({
      isEnabled: typeof employerSetupComplete === 'boolean',
      value: [employerSetupComplete],
    }),
    toStateParams: (selectedOptions) => ({
      employerSetupComplete: selectedOptions[0],
    }),
    toRequestParams: (selectedOptions) => ({
      employerSetupComplete: selectedOptions[0],
    }),
    getOptions: async () => ([
      { id: true, label: translations['STAFF.OVERVIEW.EMPLOYER_SETUP_COMPLETE'] },
      { id: false, label: translations['STAFF.OVERVIEW.EMPLOYER_SETUP_NOT_COMPLETE'] },
    ]),
  });

  registry.set('employmentStartsBetween', {
    id: 'employmentStartsBetween',
    label: translations['STAFF.OVERVIEW.FILTER_LABEL_EMPLOYMENT_START'],
    fromStateParams: ({ employmentStartsBetween }) => ({
      isEnabled: !!employmentStartsBetween,
      value: employmentStartsBetween
        ? parseDateFilterFromWire(employmentStartsBetween) : undefined,
    }),
    toStateParams: (state) => ({
      employmentStartsBetween: parseDateFilterToWire(state),
    }),
    toRequestParams: (state) => ({
      employmentStartsBetween: dateFilterToInterval(state),
    }),
  });

  registry.set('employmentEndsBetween', {
    id: 'employmentEndsBetween',
    label: translations['STAFF.OVERVIEW.FILTER_LABEL_EMPLOYMENT_END'],
    fromStateParams: ({ employmentEndsBetween }) => ({
      isEnabled: !!employmentEndsBetween,
      value: employmentEndsBetween
        ? parseDateFilterFromWire(employmentEndsBetween) : undefined,
    }),
    toStateParams: (state) => ({
      employmentEndsBetween: parseDateFilterToWire(state),
    }),
    toRequestParams: (state) => ({
      employmentEndsBetween: dateFilterToInterval(state),
    }),
  });

  registry.set('employmentStartsOrEndsBetween', {
    id: 'employmentStartsOrEndsBetween',
    label: translations['STAFF.OVERVIEW.FILTER_LABEL_EMPLOYMENT_START_OR_END'],
    fromStateParams: ({ employmentStartsOrEndsBetween }) => ({
      isEnabled: !!employmentStartsOrEndsBetween,
      value: employmentStartsOrEndsBetween
        ? parseDateFilterFromWire(employmentStartsOrEndsBetween) : undefined,
    }),
    toStateParams: (state) => ({
      employmentStartsOrEndsBetween: parseDateFilterToWire(state),
    }),
    toRequestParams: (state) => ({
      employmentStartsOrEndsBetween: dateFilterToInterval(state),
    }),
  });

  return registry;
};
