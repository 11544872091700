const translations = {
  "STAFF": {
    "CONSTANTS": {
      "UK_STARTER_DECLARATION_A": "R : Il s'agit de leur premier emploi depuis le début de l'année fiscale (6 avril)",
      "UK_STARTER_DECLARATION_B": "B : C'est actuellement leur seul travail",
      "UK_STARTER_DECLARATION_C": "C : Ils ont un autre emploi ou une autre pension",
      "UK_STUDENT_LOAN_PLAN_1": "Plan 1",
      "UK_STUDENT_LOAN_PLAN_2": "Plan 2",
      "UK_STUDENT_LOAN_PLAN_4": "Plan 4",
      "UK_STUDENT_LOAN_PLAN_5": "Plan 5",
      "UK_STUDENT_LOAN_PLAN_POSTGRADUATE": "Diplôme de troisième cycle",
      "UK_SETTLED_STATUS_NA": "Non applicable",
      "UK_SETTLED_STATUS_PRE_SETTLED": "Préréglé",
      "UK_SETTLED_STATUS_SETTLED": "Installé",
      "MARITAL_STATUS_SINGLE": "Unique",
      "MARITAL_STATUS_MARRIED": "Marié",
      "MARITAL_STATUS_DIVORCED": "Divorcé",
      "MARITAL_STATUS_WIDOWED": "Veuf",
      "MARITAL_STATUS_CIVIL_PARTNERSHIP": "Partenariat civil",
      "PAYMENT_METHOD_ELECTRONIC": "Virement électronique",
      "PAYMENT_METHOD_CHEQUE": "Chèque",
      "PAYMENT_METHOD_MANUAL": "Manuel",
      "CONTRACTUAL_OBLIGATION_PERIOD_WEEK": "Par semaine",
      "CONTRACTUAL_OBLIGATION_PERIOD_MONTH": "Par mois",
      "CONTRACTUAL_OBLIGATION_UNIT_HOURS": "Heures",
      "CONTRACTUAL_OBLIGATION_UNIT_DAYS": "Journées",
      "PAY_AMOUNT_TYPE_HOURLY": "Taux horaire",
      "PAY_AMOUNT_TYPE_DAILY": "Tarif journalier",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salaire annuel",
      "PAY_FREQUENCY_WEEKLY": "Hebdo",
      "PAY_FREQUENCY_FORTNIGHTLY": "Tous les quinze jours",
      "PAY_FREQUENCY_MONTHLY": "Mensuel",
      "PAY_FREQUENCY_FOUR_WEEKLY": "Quatre fois par semaine"
    },
    "OVERVIEW": {
      "ERROR_500": "Nous n'avons pas pu charger de données à ce moment-là. Réessayez, s'il vous plaît.",
      "TITLE": "Le personnel",
      "ADD_STAFF": "Ajouter un nouveau",
      "NO_MATCH": "Nous n'avons trouvé personne correspondant à vos filtres.",
      "NO_ACTIVE_APPT_TAG": "Inactif",
      "NOT_APPROVED_TAG": "Non approuvé",
      "DOCUMENTS_INVALID_TAG": "Documents non valides",
      "NOT_INVITED_TAG": "Non invité",
      "NOT_SETUP_TAG": "Invité",
      "EMPLOYER_INCOMPLETE_TAG": "Incomplet",
      "SELF_ONBOARDING": "Auto-intégration",
      "ACCOUNT_TAB_LABEL": "Compte",
      "PERSONAL_TAB_LABEL": "Personnel",
      "EMPLOYMENT_TAB_LABEL": "L'emploi",
      "PAY_TAB_LABEL": "Payez",
      "SCHEDULE_TAB_LABEL": "Calendrier",
      "ABSENCE_TAB_LABEL": "Absence",
      "AVAILABILITY_TAB_LABEL": "Disponibilité",
      "SHIFT_ROUTINE_TAB_LABEL": "Routines de travail",
      "DOCUMENTS_TAB_LABEL": "Documents",
      "ATTENDANCE_TAB_LABEL": "Présence",
      "NOTES_TAB_LABEL": "Remarques",
      "FILTER_LABEL_SITE": "Site/département",
      "FILTER_LABEL_POSITION": "Rôle du poste",
      "FILTER_LABEL_STAFF_GROUP": "Groupe du personnel",
      "FILTER_LABEL_EMPLOYMENT": "Statut en matière d'emploi",
      "FILTER_LABEL_APPROVAL_STATUS": "État d'approbation",
      "FILTER_LABEL_EMPLOYEE_SETUP": "Invitation Rotaready",
      "FILTER_LABEL_EMPLOYER_SETUP": "Complétion du compte",
      "FILTER_LABEL_EMPLOYMENT_START": "Date d'adhésion",
      "FILTER_LABEL_EMPLOYMENT_END": "Date de départ",
      "FILTER_LABEL_EMPLOYMENT_START_OR_END": "Date d'adhésion ou de départ",
      "BUTTON_COLUMNS": "Colonnes",
      "BUTTON_EXPORT": "Exporter",
      "EMPLOYMENT_STATUS_ACTIVE": "Actif",
      "EMPLOYMENT_STATUS_INACTIVE": "Inactif",
      "APPROVAL_STATUS_APPROVED": "Approuvé",
      "APPROVAL_STATUS_NOT_APPROVED": "Non approuvé",
      "EMPLOYEE_SETUP_COMPLETE": "Accepté",
      "EMPLOYEE_SETUP_NOT_COMPLETE": "En attente ou non envoyé",
      "EMPLOYER_SETUP_COMPLETE": "Achevée",
      "EMPLOYER_SETUP_NOT_COMPLETE": "Incomplet",
      "COLUMN_NAME": "Nom",
      "COLUMN_NEXT_SHIFT": "Prochain quart de travail",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_BIRTH_DATE": "Date de naissance",
      "COLUMN_EMAIL": "Courrier électronique",
      "COLUMN_MOBILE": "Téléphone",
      "COLUMN_OTHER_NAMES": "Autre (s) nom (s)",
      "COLUMN_ADDRESS_LINE_1": "Ligne d'adresse 1",
      "COLUMN_ADDRESS_LINE_2": "Ligne d'adresse 2",
      "COLUMN_ADDRESS_CITY": "Localité/ville",
      "COLUMN_ADDRESS_COUNTY": "Département/région",
      "COLUMN_ADDRESS_POST_CODE": "Code postal",
      "COLUMN_ADDRESS_COUNTRY": "Pays",
      "COLUMN_PREFERRED_NAME": "Nom préféré",
      "COLUMN_GENDER": "Genre",
      "COLUMN_TITLE": "Titre",
      "COLUMN_ETHNICITY": "L'ethnicité",
      "COLUMN_NATIONALITY": "Nationalité",
      "COLUMN_MARITAL_STATUS": "État civil",
      "COLUMN_SETTLED_STATUS": "Statut de résident permanent (Royaume-Uni)",
      "COLUMN_PASSPORT_NUMBER": "Numéro de passeport",
      "COLUMN_PASSPORT_EXPIRY_DATE": "Expiration du passeport",
      "COLUMN_WORK_EMAIL": "E-mail secondaire",
      "COLUMN_WORK_PHONE": "Téléphone secondaire",
      "COLUMN_BANK_ACCOUNT_NAME": "Nom du compte bancaire",
      "COLUMN_BANK_ACCOUNT_NUMBER": "Numéro de compte bancaire",
      "COLUMN_BANK_ACCOUNT_SORT_CODE": "Code de tri bancaire",
      "COLUMN_PAYROLL_PAYMENT_METHOD": "Mode de paiement",
      "COLUMN_NATIONAL_INSURANCE_NUMBER": "Numéro d'assurance nationale (Royaume-Uni)",
      "COLUMN_STUDENT_LOANS": "Prêts étudiants (Royaume-Uni)",
      "COLUMN_AVERAGE_HOURS_DAY": "Nombre moyen d'heures par jour",
      "COLUMN_AVERAGE_HOURS_HALF_DAY": "Nombre moyen d'heures/demi-journée",
      "COLUMN_AVERAGE_HOURS_WEEK": "Nombre moyen d'heures par semaine",
      "COLUMN_PRE_AVERAGE_HOURS_PER_DAY": "Nombre moyen d'heures par jour avant la rotation",
      "COLUMN_PRE_AVERAGE_HOURS_DATE": "Date d'ouverture moyenne avant la rotation",
      "COLUMN_PERMISSION_LEVEL_NAME": "Niveau d'autorisation",
      "COLUMN_ANONYMISED": "Anonymisé",
      "COLUMN_ANONYMISE_DATE": "Anonymiser la date",
      "COLUMN_JOIN_DATE": "Date d'adhésion",
      "COLUMN_LEAVE_DATE": "Date de départ",
      "COLUMN_LEAVE_DATE_EDITED_BY": "Date de congé fixée par",
      "COLUMN_LEAVE_DATE_EDITED_DATE": "Date de départ : fixez la date",
      "COLUMN_LENGTH_OF_SERVICE": "Durée de service (jours)",
      "COLUMN_RECRUITMENT_SOURCE": "Source de recrutement",
      "COLUMN_LEAVER_REASON": "Motif du départ",
      "COLUMN_LEAVER_REHIRE": "Éligible à la réembauche",
      "COLUMN_EMPLOYEE_NUMBER": "Numéro d'employé",
      "COLUMN_NOTICE_PERIOD": "Délai de préavis",
      "COLUMN_STARTER_DECLARATION": "Déclaration de démarrage (Royaume-Uni)",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED": "Allocations finalisées",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY": "Allocations finalisées par",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE": "Date de finalisation des allocations",
      "COLUMN_SITE_NAME": "Site",
      "COLUMN_SITE_ID": "Identifiant du site",
      "COLUMN_DEPARTMENT_NAME": "Département",
      "COLUMN_DEPARTMENT_ID": "ID du département",
      "COLUMN_JOB_ROLE_NAME": "Rôle du poste",
      "COLUMN_COMPANY_NAME": "L'entreprise",
      "COLUMN_CONTRACT_BASIS": "Type de contrat",
      "COLUMN_LINE_MANAGER_NAME": "Responsable hiérarchique",
      "COLUMN_STAFF_GROUP_NAME": "Groupe du personnel",
      "COLUMN_WORKS_PUBLIC_HOLIDAYS": "Travaille les jours fériés",
      "COLUMN_WORKING_TIME_OPT_OUT": "Désabonnement à la réglementation sur le temps",
      "COLUMN_MIN_OBLIGATION_VALUE": "Valeur de l'obligation contractuelle",
      "COLUMN_MIN_OBLIGATION_UNIT": "Unité des obligations contractuelles",
      "COLUMN_MIN_OBLIGATION_PERIOD": "Période d'obligation contractuelle",
      "COLUMN_MAX_OBLIGATION_VALUE": "Valeur limite de travail",
      "COLUMN_MAX_OBLIGATION_UNIT": "Unité de limite de travail",
      "COLUMN_MAX_OBLIGATION_PERIOD": "Période limite de travail",
      "COLUMN_DEPARTMENT_CURRENCY_CODE": "Code de devise",
      "COLUMN_PAYROLL_CALENDAR_NAME": "Calendrier de paie",
      "COLUMN_PAYROLL_CALENDAR_FREQUENCY": "Fréquence du calendrier de paie",
      "COLUMN_PAY_AMOUNT": "Montant du salaire",
      "COLUMN_PAY_AMOUNT_TYPE": "Type de montant de paiement",
      "COLUMN_PAY_UPLIFT": "Hausse de salaire",
      "ASSET_REGISTRY_TAB_LABEL": "Registre des actifs"
    },
    "VIEW": {
      "ACCOUNT": {
        "ERROR_500": "Nous ne pouvons pas traiter votre modification pour le moment. Réessayez, s'il vous plaît.",
        "ERROR_403": "Vous n'êtes pas autorisé à consulter/modifier les informations de compte de cette personne.",
        "SAVE_SUCCESS": "Vos modifications ont été enregistrées.",
        "TITLE": "Compte",
        "LAST_SIGN_IN_LABEL": "Dernière connexion",
        "LAST_SIGN_IN_NEVER": "Jamais",
        "SECTION_PERMISSIONS_TITLE": "Autorisations",
        "PERMISSION_LEVEL_LABEL": "Niveau d'autorisation",
        "PERMISSION_LEVEL_HELP": "Cela modifiera ce que l'employé peut voir et faire dans Rotaready.",
        "SITE_ACCESS_LABEL": "Accès au site",
        "SITE_ACCESS_OPTION_APPOINTMENT": "Leur site actuel",
        "SITE_ACCESS_OPTION_ALL": "Tous les sites",
        "SITE_ACCESS_OPTION_CUSTOM": "Leur site actuel et leurs sites personnalisés",
        "CLOCK_IN_PIN_LABEL": "Réinitialiser le code PIN d'horloge",
        "CLOCK_IN_PIN_HELP": "Entrez un nouveau code à 4 chiffres pour modifier le code PIN d'enregistrement de cet employé.",
        "TAGS_LABEL": "Balises",
        "TAGS_TIP": "Identifiez les employés avec tout élément significatif, comme des compétences ou des attributs.",
        "TAGS_PLACEHOLDER": "Tapez pour rechercher des balises",
        "SAVE_BUTTON_LOADING": "Économiser...",
        "SAVE_BUTTON_TEXT": "Enregistrer",
        "LANGUAGE_LABEL": "Langue",
        "LANGUAGE_HELP": "Rotaready s'affichera dans la langue choisie.",
        "ENTITY_GROUP_SUBSCRIPTION_TOOLTIP": "Cet employé est automatiquement affecté à ce site. Vous pouvez gérer leur accès dans la page des paramètres du Gestionnaire d'accès au site."
      },
      "PAY": {
        "INDEX": {
          "TAB_HISTORY": "Dossiers de paie",
          "TAB_TRONC": "Tronc",
          "TAB_FINANCIALS": "Finances"
        },
        "VIEW_HISTORY": {
          "FILTER_TYPES_LABEL": "Tapez",
          "FILTER_TYPES_SALARY": "Salaire",
          "FILTER_TYPES_POINT_IN_TIME": "Paiements ponctuels",
          "FILTER_ELEMENT_TYPES_LABEL": "Élément de rémunération",
          "STATE_ACTIVE": "Actif",
          "STATE_HISTORICAL": "Historique",
          "STATE_FUTURE_DATED": "Daté à terme",
          "COLUMN_EFFECTIVE_DATE": "Date d'entrée en vigueur",
          "COLUMN_STATUS": "État",
          "COLUMN_ELEMENT_TYPE": "Élément de rémunération",
          "COLUMN_CURRENCY": "Devise",
          "COLUMN_AMOUNT": "Montant",
          "COLUMN_AMOUNT_TYPE": "Type de montant",
          "COLUMN_OPTIONS": "Options",
          "ERROR_500": "Nous ne pouvons pas charger les relevés de paie pour le moment. Veuillez réessayer.",
          "ERROR_403": "Vous n'êtes pas autorisé à consulter/modifier les relevés de paie de cette personne.",
          "ALERT_SUCCESS": "Le dossier de paie a été enregistré.",
          "ALERT_DELETED": "Le dossier de paie a été supprimé.",
          "OUTSIDE_OF_APPOINTMENT_WARNING": "Ce dossier de paie n'est pas valide car ils ne sont pas activement employés à cette date. Veuillez modifier la date d'entrée en vigueur.",
          "DELETE_LINK": "Supprimer",
          "LAST_EDITED_TOOLTIP": "Modifié pour la dernière fois par {{UserName}} le {{date}}",
          "NO_RECORDS": "Il n'y a aucun relevé de paie à afficher.",
          "ADD_NEW_BUTTON": "Nouveau record salarial",
          "CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer cette fiche de paie ? Cela ne peut pas être annulé."
        },
        "PAY_FORM": {
          "TITLE": "Payez",
          "LABEL_EFFECTIVE_DATE": "Date d'entrée en vigueur",
          "LABEL_ELEMENT": "Élément de rémunération",
          "LABEL_AMOUNT": "Montant",
          "LABEL_AMOUNT_TYPE": "Type de montant",
          "LABEL_UPLIFT": "Hausse des salaires",
          "ALERT_NO_CURRENCY": "L'employé n'est pas activement employé à cette date.",
          "ALERT_DUPLICATE_PAY": "Il existe déjà un dossier de paie pour l'élément de rémunération que vous avez choisi à cette date.",
          "HELP_TEXT_UPLIFT": "Cela fera gonfler les salaires des employés dans Cost Control. Utilisez-le pour refléter tous les frais supplémentaires que vous encourez, comme une pension. Ne vous inquiétez pas pour les taxes car elles sont incluses automatiquement.",
          "BUTTON_SAVE": "Enregistrer",
          "BUTTON_SAVE_LOADING": "Économiser...",
          "BUTTON_DELETE": "Supprimer",
          "BUTTON_DELETE_LOADING": "Supprimer..."
        },
        "VIEW_FINANCIALS": {
          "ERROR_500": "Nous ne pouvons pas charger les informations financières pour le moment. Veuillez réessayer.",
          "ERROR_403": "Vous n'êtes pas autorisé à consulter/modifier les informations financières de cette personne.",
          "SAVE_SUCCESS": "Vos modifications ont été enregistrées.",
          "SECTION_BANK_ACCOUNT": "Compte bancaire",
          "SECTION_TAXES": "Impôts",
          "ACCOUNT_NUMBER_LABEL": "Numéro de compte",
          "SORT_CODE_LABEL": "Code de tri",
          "ACCOUNT_NAME_LABEL": "Nom du compte",
          "PAYMENT_METHOD": "Mode de paiement",
          "NI_NUMBER_LABEL": "Numéro d'assurance nationale",
          "SECTION_STUDENT_LOANS": "Prêts étudiants (Royaume-Uni)",
          "PLAN_TYPE_LABEL": "Type de plan",
          "EFFECTIVE_DATE_LABEL": "Date d'entrée en vigueur",
          "LOAN_TABLE_PLAN_COLUMN": "Type de plan",
          "LOAN_TABLE_DATE_COLUMN": "Date d'entrée en vigueur",
          "LOAN_TABLE_OPTIONS_COLUMN": "Options",
          "LOAN_TABLE_NO_ROWS": "Aucun prêt étudiant n'a été ajouté.",
          "LOAN_TABLE_DATE_NOT_SET": "Non défini",
          "SUBHEADING_ADD_STUDENT_LOAN": "Ajouter un prêt étudiant",
          "OPTION_DELETE": "Supprimer",
          "ADD_LOAN_BUTTON": "Ajouter un prêt étudiant",
          "SAVE_BUTTON_TEXT": "Enregistrer"
        },
        "EDIT_STUDENT_LOAN": {
          "HEADING": "Modifier le prêt étudiant",
          "FORM_PLAN_TYPE_LABEL": "Type de plan",
          "FORM_EFFECTIVE_DATE_LABEL": "Date d'effet",
          "SAVE_BUTTON_TEXT": "Enregistrer"
        },
        "VIEW_TRONC": {
          "COLUMN_EFFECTIVE_DATE": "Date d'entrée en vigueur",
          "COLUMN_STATUS": "État",
          "COLUMN_AMOUNT": "Montant",
          "COLUMN_APPORTION_TYPE": "Type de répartition",
          "COLUMN_LAST_EDITED": "Dernière modification",
          "STATE_ACTIVE": "Actif",
          "STATE_HISTORICAL": "Historique",
          "STATE_FUTURE_DATED": "Daté à terme",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Taux horaire",
          "APPORTION_TYPE_CLASSIC_SALARY": "Salaire annuel",
          "APPORTION_TYPE_POINTS": "Points",
          "NO_RECORDS": "Il n'y a aucun tarif Tronc à afficher.",
          "ADD_NEW_BUTTON": "Nouveau tarif Tronc",
          "ERROR_500": "Nous ne pouvons pas charger les tarifs Tronc pour le moment. Veuillez réessayer.",
          "ERROR_403": "Vous n'êtes pas autorisé à consulter les tarifs Tronc de cette personne."
        },
        "ADD_TRONC_MODAL": {
          "TITLE": "Nouveau tarif Tronc",
          "BUTTON_SAVE": "Enregistrer",
          "BUTTON_CANCEL": "Annuler",
          "LABEL_PERSON": "Personne",
          "LABEL_DATE": "Date d'entrée en vigueur",
          "LABEL_APPORTION_TYPE": "Type de répartition",
          "LABEL_AMOUNT": "Montant",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Taux horaire",
          "APPORTION_TYPE_CLASSIC_SALARY": "Salaire annuel",
          "APPORTION_TYPE_POINTS": "Points",
          "APPORTION_TYPE_HELP": "Tronc peut travailler selon l'un des deux modes suivants : basé sur des points ou classique, qui utilise un taux horaire ou un salaire. Ceci est contrôlé par un paramètre propre à votre organisation ou au site/lieu spécifique.",
          "ERROR_OTHER": "Nous ne pouvons pas enregistrer le taux Tronc pour le moment. Veuillez réessayer.",
          "ERROR_UNAUTHORIZED": "Vous n'êtes pas autorisé à modifier les tarifs Tronc de cette personne.",
          "ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE": "Cet employé n'a pas de rendez-vous actif à la date d'entrée en vigueur que vous avez choisie.",
          "ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE": "Quelqu'un a déjà commencé à rédiger Tronc pour la date d'entrée en vigueur que vous avez choisie. Veuillez utiliser l'outil Tronc pour modifier les taux pour cette date.",
          "ERROR_NOT_SUPPORTED": "Vous ne pouvez pas choisir ce type de répartition. Tronc n'est pas configuré pour fonctionner dans ce mode."
        }
      },
      "INDEX": {
        "OPTIONS_LABEL": "Options",
        "OPTION_APPROVE_LINK": "Approuver les rotations et la paie",
        "OPTION_SEND_MAGIC_LINK": "Inviter à Rotaready (envoyer un lien magique)",
        "OPTION_SEND_MAGIC_LINK_TOOLTIP": "Cette personne a déjà créé son compte. S'ils rencontrent des difficultés pour se connecter, demandez-leur de suivre le lien de récupération de compte sur la page de connexion.",
        "OPTION_ANONYMISE": "Anonymiser",
        "OPTION_DELETE": "Supprimer",
        "ALERT_APPROVE_SUCCESS": "Ils sont maintenant approuvés et prêts à être affectés à des quarts de travail.",
        "ALERT_APPROVE_ERROR": "Nous n'avons pas pu approuver le compte à ce moment-là. Réessayez, s'il vous plaît.",
        "ALERT_MAGIC_LINK_SUCCESS_ALL": "Nous avons envoyé un e-mail et un SMS contenant un lien magique. Veuillez prévoir quelques minutes pour qu'il arrive.",
        "ALERT_MAGIC_LINK_SUCCESS_EMAIL": "Nous avons envoyé un e-mail contenant un lien magique. Veuillez prévoir quelques minutes pour qu'il arrive.",
        "ALERT_MAGIC_LINK_SUCCESS_SMS": "Nous avons envoyé un texto contenant un lien magique. Veuillez prévoir quelques minutes pour qu'il arrive.",
        "ALERT_MAGIC_LINK_ERROR_GENERIC": "Nous n'avons pas pu envoyer de lien magique à ce moment-là.",
        "ALERT_MAGIC_LINK_ERROR_NO_DETAILS": "Nous ne pouvons pas envoyer de lien magique sans adresse e-mail ou numéro de téléphone.",
        "ALERT_ACCOUNT_ERROR_500": "Nous n'avons pas pu charger l'employé à ce moment-là. Veuillez réessayer.",
        "ALERT_ACCOUNT_ERROR_404": "Aucun compte d'employé n'est associé à cet identifiant.",
        "DELETE_ACCOUNT_TITLE": "Supprimer un employé",
        "DELETE_ACCOUNT_MESSAGE": "Avertissement ! La suppression d'un employé est définitive. Ne le faites que si vous avez créé l'employé par erreur. Pour continuer, saisissez leur nom complet ci-dessous.",
        "DELETE_ACCOUNT_BUTTON": "Supprimer définitivement",
        "DELETE_ACCOUNT_ERROR": "L'employé n'a pas pu être supprimé. Vous n'êtes peut-être pas autorisé à le faire.",
        "DELETE_ACCOUNT_SUCCESS": "L'employé a été supprimé."
      },
      "PERSONAL": {
        "ERROR_500": "Nous ne pouvons pas traiter votre modification pour le moment. Réessayez, s'il vous plaît.",
        "ERROR_403": "Vous n'êtes pas autorisé à consulter/modifier les informations personnelles de cette personne.",
        "SAVE_SUCCESS": "Vos modifications ont été enregistrées.",
        "EMERGENCY_CONTACTS_LINK_TEXT": "Contacts d'urgence",
        "FINANCIALS_LINK_TEXT": "Finances/impôts",
        "LABEL_OPTIONS": "Plus d'options",
        "LABEL_APPROVED_BY": "Approuvé par",
        "TITLE_LABEL": "Titre",
        "FIRST_NAME_LABEL": "Prénom",
        "LAST_NAME_LABEL": "Nom de famille",
        "OTHER_NAME_LABEL": "Autres noms (facultatif)",
        "DOB_LABEL": "Date de naissance",
        "DOB_HELP": "{{age}} ␞ ans",
        "PREFERRED_NAME_LABEL": "Nom préféré",
        "SECTION_ADDRESS": "Adresse",
        "SECTION_VISAS": "Visas",
        "SECTION_IDENTITY": "Identité",
        "SECTION_CONTACT": "Contacter",
        "SECTION_OTHER": "Autres",
        "SECTION_RIGHT_TO_WORK": "Droit de travailler",
        "NATIONALITY_LABEL": "Nationalité",
        "GENDER_LABEL": "Genre",
        "ETHNICITY_LABEL": "L'ethnicité",
        "MARITAL_STATUS_LABEL": "État civil",
        "PASSPORT_NUMBER_LABEL": "Numéro de passeport",
        "PASSPORT_EXPIRY_LABEL": "Date d'expiration du passeport",
        "SETTLED_STATUS_LABEL": "Statut de résident permanent (Royaume-Uni)",
        "PRIMARY_EMAIL_LABEL": "E-mail principal",
        "PRIMARY_EMAIL_HELP": "L'employé l'utilise pour se connecter à Rotaready. Il ne peut être modifié que s'ils ne se sont jamais connectés auparavant.",
        "PRIMARY_PHONE_LABEL": "Téléphone principal",
        "PRIMARY_PHONE_HELP": "L'employé l'utilise pour recevoir des notifications. Le préfixe de code de pays correct sera automatiquement appliqué une fois que vous aurez enregistré.",
        "SECONDARY_EMAIL_LABEL": "E-mail secondaire",
        "SECONDARY_PHONE_LABEL": "Téléphone secondaire",
        "HR_ID_LABEL": "IL A FAIT",
        "HR_ID_HELP": "Si cet employé possède un numéro d'identification utilisé à des fins RH, vous pouvez le stocker ici.",
        "PAYROLL_ID_LABEL": "Numéro de paie",
        "PAYROLL_ID_HELP": "Si cet employé possède un numéro d'identification utilisé à des fins de paie, vous pouvez le stocker ici.",
        "SAVE_BUTTON_LOADING": "Économiser...",
        "SAVE_BUTTON_TEXT": "Enregistrer",
        "LINK_ADD_ADDRESS": "Ajouter une nouvelle adresse",
        "LINK_VIEW_ADDRESS_HISTORY": "Voir les adresses précédentes",
        "NO_ADDRESS_HISTORY_TEXT": "Il n'y a pas d'adresses précédentes",
        "CURRENT_ADDRESS_LABEL": "Actuel",
        "SHARE_CODE_LABEL": "Code de partage",
        "SHARE_CODE_ERROR_LENGTH": "Le code de partage doit comporter 9 caractères.",
        "SHARE_CODE_ERROR_FORMAT": "Le code de partage doit contenir uniquement des caractères alphanumériques.",
        "SHARE_CODE_EXPIRY_DATE_LABEL": "Date d'expiration du code de partage",
      },
      "ADDRESS_HISTORY": {
        "ALERT_ERROR_500": "Nous ne pouvons pas traiter votre modification pour le moment. Réessayez, s'il vous plaît.",
        "TITLE_ADD": "Ajouter une nouvelle adresse",
        "TITLE_EDIT": "Modifier l'adresse",
        "ADVICE_EDIT": "Si l'adresse de l'employé change, vous devez plutôt ajouter une nouvelle adresse. Rotaready déplacera automatiquement l'adresse actuelle vers son historique d'adresses.",
        "ADVICE_EDIT_LINK": "Ajouter une nouvelle adresse",
        "SAVE_BUTTON_TEXT": "Enregistrer",
        "DELETE_BUTTON_TEXT": "Supprimer",
        "ADDRESS_LINE_1_LABEL": "Ligne 1",
        "ADDRESS_LINE_2_LABEL": "Ligne 2 (facultatif)",
        "ADDRESS_CITY_LABEL": "Localité/ville",
        "ADDRESS_COUNTY_LABEL": "Comté",
        "ADDRESS_POST_CODE_LABEL": "Code postal",
        "ADDRESS_COUNTRY_LABEL": "Pays",
        "NOTES_LABEL": "Remarques",
        "CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer cette adresse ?"
      },
      "VISAS": {
        "ADD_BTN": "Ajouter un visa",
        "CURRENT_HEADING": "Visa actif",
        "NO_CURRENT_VISA": "Cet utilisateur n'a pas de visa actif.",
        "CURRENT_LABEL": "Actif",
        "VISA_TYPE_LABEL": "Type de visa",
        "VISA_NUMBER_LABEL": "Numéro de visa",
        "VISA_EXPIRY_LABEL": "Date d'expiration du visa",
        "VISA_EXPIRY_NOT_APPLICABLE": "Non applicable",
        "VISA_EDIT_BTN": "Modifier le visa",
        "PREVIOUS_LABEL": "Précédent",
        "SHOW_PREVIOUS_BTN": "Afficher les visas précédents",
        "HIDE_PREVIOUS_BTN": "Masquer les visas précédents",
        "PREVIOUS_HEADING": "Visa précédent",
        "MODAL": {
          "ADD_HEADING": "Ajouter un nouveau visa",
          "ALERT_ERROR_500": "Nous ne pouvons pas traiter votre modification pour le moment. Veuillez réessayer.",
          "FORM_LABEL_VISA_TYPE": "Type de visa",
          "FORM_LABEL_VISA_NUMBER": "Numéro de visa",
          "FORM_LABEL_IS_CURRENT": "C'est le visa actuel de l'utilisateur",
          "FORM_LABEL_HAS_EXPIRY_DATE": "Ce visa a une date d'expiration",
          "FORM_LABEL_VISA_EXPIRY": "Date d'expiration du visa",
          "FORM_BTN_SAVE": "Enregistrer",
          "EDIT_VISA_HEADING": "Modifier le visa",
          "DELETE_VISA_BTN": "Supprimer le visa",
          "CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer ce visa ?"
        }
      },
      "SCHEDULE": {
        "TITLE": "Le calendrier ci-dessous présente le programme complet de {{firstName}} FirstName}, y compris les quarts de travail, les absences et les disponibilités.",
        "SHIFTS_LABEL": "quarts de travail",
        "ABSENCE_LABEL": "Absence",
        "UNAVAILABLE_LABEL": "Non disponible (modèles)",
        "EDIT_LINK_TEXT": "Modifier les exceptions"
      },
      "ADD_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Routine de changement de carte",
        "HAS_OVERLAP_TEXT": "Les dates ci-dessus recoupent une routine de travail existante.",
        "END_BEFORE_START_TEXT": "La date de début est antérieure à la date de fin.",
        "SAVE_BUTTON_TEXT": "Enregistrer",
        "SAVE_BUTTON_LOADING": "Économiser...",
        "CANCEL_BUTTON_TEXT": "Annuler"
      },
      "DELETE_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Tu es sûr ?",
        "WARNING": "La suppression de ce mappage supprimera tous les futurs quarts de travail qui y sont associés.",
        "YES_BUTTON_TEXT": "Oui",
        "YES_BUTTON_ADDITIONAL_TEXT": "Supprimer ce mappage",
        "NO_BUTTON_TEXT": "Non",
        "NO_BUTTON_ADDITIONAL_TEXT": "J'ai changé d'avis",
        "CANCEL_BUTTON_TEXT": "Annuler"
      },
      "EDIT_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Modifier le mappage des routines de travail",
        "END_DATE_LABEL": "Date de fin",
        "CONTINUE_FOREVER_LABEL": "Continuez la routine des quarts de travail pour toujours",
        "END_BEFORE_START_TEXT": "La date de fin est antérieure à la date de début.",
        "HAS_OVERLAP_NO_END": "La définition de cette date de fin entraînerait un chevauchement avec une routine de travail existante.",
        "HAS_OVERLAP_END": "L'absence de date de fin entraînerait un chevauchement avec une routine de travail existante.",
        "SAVE_BUTTON_TEXT": "Enregistrer",
        "SAVE_BUTTON_LOADING_TEXT": "Économiser...",
        "CANCEL_BUTTON_TEXT": "Annuler"
      },
      "SHIFT_ROUTINES": {
        "MAP_ROUTINE_BUTTON_TEXT": "De la carte à la routine",
        "MAP_ROUTINE_SUBTITLE": "Cartographies des routines Shift",
        "MAP_ROUTINE_TIP": "Associer une personne à une routine de travail entraîne la création automatique de quarts de travail. Ils seront supprimés si vous supprimez le mappage ou si vous ajoutez une date de fin.",
        "EDIT_END_DATE_TEXT": "Modifier la date de fin",
        "ADD_END_DATE_TEXT": "Ajouter une date de fin",
        "MAPPING_NO_END": "À partir du ",
        "MAPPING_END": " pour ",
        "USER_HAS_NO_MAPPING": "{{FirstName}} {FirstName} n'est mappé à aucune routine de travail. Vous pouvez en ajouter un à l'aide du bouton ci-dessus."
      },
      "ABSENCE": {
        "INDEX": {
          "TAB_ABSENCE": "Réservations d'absences",
          "TAB_ALLOWANCES": "Allocations",
          "TAB_SETTINGS": "Réglages"
        },
        "VIEW_SETTINGS": {
          "ABSENCE_HOURS_LABEL": "Nombre moyen d'heures par jour",
          "AVERAGE_LENGTH_LABEL": "Calculez automatiquement en fonction de la durée moyenne de leur journée de travail",
          "SET_MANUALLY_LABEL": "Régler manuellement",
          "HOURS_FULL_DAY_LABEL": "Journée complète",
          "HOURS_LABEL": "heures",
          "HOURS_HELP": "Une journée complète d'absence vaudra autant d'heures.",
          "HOURS_HALF_DAY_LABEL": "Demi-journée",
          "HOURS_HALF_DAY_HELP": "Une demi-journée d'absence vaudra autant d'heures.",
          "DELEGATE_APPROVERS": "Déléguez les approbateurs",
          "DELEGATE_APPROVERS_REMOVE": "Supprimer",
          "DELEGATE_APPROVERS_HELP": "Si cet employé active la fonction Ne pas déranger dans l'application Rotaready et qu'une demande d'absence nécessitant son approbation est émise, ses approbateurs délégués en seront informés à la place.",
          "SAVE_BUTTON_TEXT": "Enregistrer",
          "SAVE_BUTTON_LOADING_TEXT": "Économiser...",
          "PRE_ROTAREADY_AVERAGE_HOURS_LABEL": "Nombre moyen d'heures par jour avant la rotation",
          "PRE_ROTAREADY_AVERAGE_HOURS_HELP": "Si vous êtes passé d'un autre système à Rotaready pendant l'emploi de cette personne, entrez ses dernières « heures moyennes par jour » connues et la date à laquelle elle était valide.",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_LABEL": "Dernière validité le",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_HELP": "Lors du calcul d'une nouvelle valeur pour la moyenne des heures par jour, si l'employé n'a pas travaillé toutes les 52 dernières semaines sur Rotaready, notre calcul utilisera la valeur pré-Rotaready pour les semaines précédant cette date."
        },
        "VIEW_BOOKINGS": {
          "INCLUDE_CANCELLED_TOGGLE_LABEL": "Inclure annulé",
          "NO_BOOKINGS_MATCH_TEXT": "Nous n'avons trouvé aucune réservation correspondant à vos filtres.",
          "BOOK_ABSENCE_BUTTON_TEXT": "Absence de livre",
          "CANCEL_LINK": "Annuler",
          "DATES_COLUMN_TEXT": "Date (s)",
          "ABSENCE_TYPE_COLUMN_TEXT": "Tapez",
          "HOURS_COLUMN_TEXT": "Heures",
          "DAYS_COLUMN_TEXT": "Journées",
          "STATUS_COLUMN_TEXT": "État",
          "SUBMITTED_COLUMN_TEXT": "Soumis",
          "APPROVED_COLUMN_TEXT": "Révisé",
          "CANCELLED_COLUMN_TEXT": "Annulé",
          "FILTER_LABEL_APPROVAL_STATE": "État",
          "FILTER_LABEL_ABSENCE_TYPE": "Tapez",
          "FILTER_LABEL_DATE": "Date",
          "CANCEL_REQUEST": {
            "CONFIRM": "Êtes-vous sûr de vouloir annuler cette réservation d'absence ? Cela ne peut pas être annulé.",
            "SUCCESS": "La réservation d'absence a été annulée.",
            "ERROR_500": "Nous ne pouvions pas annuler cela pour l'instant. Réessayez, s'il vous plaît."
          }
        },
        "VIEW_ALLOWANCES": {
          "ERROR_500": "Oups, nous avons rencontré un problème à ce moment-là. Réessayez, s'il vous plaît.",
          "BUTTON_ADD": "Nouvelle allocation",
          "BUTTON_OPTIONS": "Options",
          "BUTTON_TRANSACTIONS": "Afficher les transactions",
          "BUTTON_EDIT": "Modifier",
          "BUTTON_DELETE": "Supprimer",
          "NO_ALLOWANCES_TEXT": "Aucune allocation ne correspond à vos filtres.",
          "FILTER_LABEL_STATUS": "État",
          "FILTER_LABEL_ABSENCE_TYPE": "Tapez",
          "CHRONO_TYPE_ACTIVE": "Actif",
          "CHRONO_TYPE_FUTURE": "Futur",
          "CHRONO_TYPE_HISTORICAL": "Historique",
          "LABEL_NOT_DEDUCTIBLE": "Non déductible",
          "METRIC_ACCRUED": "Admissibilité",
          "METRIC_BOOKED": "Réservé",
          "METRIC_REMAINING": "Restant",
          "METRIC_ACCRUED_LIMIT": "de {{count}}",
          "METRIC_ACCRUED_LIMIT_HELP": "Il s'agit du maximum qui peut être cumulé.",
          "METRIC_ELAPSED": "écoulé",
          "METRIC_ELAPSED_HELP": "Il s'agit du nombre de jours/heures réservés dans le passé. Cela se recalcule automatiquement du jour au lendemain.",
          "METRIC_OVERSPEND": "dépenses excessives",
          "METRIC_OVERSPEND_HELP": "Il s'agit du nombre de jours/heures qui peuvent être réservés en plus du montant cumulé.",
          "METRIC_ACCRUAL_ESTIMATE": "à accumuler",
          "METRIC_ACCRUAL_ESTIMATE_HELP": "Sur la base de vos heures de travail moyennes, nous estimons que vous accumulerez cette somme d'ici la fin de l'année d'allocation.",
          "SCHEDULE_BEGINNING_OF_ACCRUAL_YEAR": "S'accumule au début de chaque année d'exercice",
          "SCHEDULE_EACH_CALENDAR_MONTH": "S'accumule chaque mois civil",
          "SCHEDULE_EACH_HOUR_WORKED": "S'accumule pour chaque heure travaillée",
          "SCHEDULE_EACH_HOUR_WORKED_RATE": "S'accumule pour chaque heure travaillée à {{rate}} %",
          "CARRIED_OVER": "{{amount}} reporté de l'année dernière",
          "LINK_TRANSACTIONS": "Afficher les transactions",
          "ALERT_CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer cette allocation ? Cela ne peut pas être annulé.",
          "ALERT_SUCCESS_DELETE": "L'allocation a été supprimée.",
          "FINALISED": "🔒 Finalisé ({{date}})",
          "FINALISE": "Finaliser",
          "CONVERT_HOURS_TO_DAYS_TOGGLE_LABEL": "Convertissez les heures en jours"
        },
        "ALLOWANCE_VALIDATION_MODAL": {
          "TITLE": "Quelque chose ne va pas",
          "DESCRIPTION": "Malheureusement, ce que vous essayez de faire entraîne un ou plusieurs problèmes.",
          "BUTTON_CLOSE": "Fermer",
          "INVALID_ALLOWANCE_TITLE": "Allocation non valide",
          "INVALID_ALLOWANCE_DESCRIPTION": "L'allocation a depuis été supprimée et ne peut plus être modifiée.",
          "OVERLAPPING_ALLOWANCE_TITLE": "Indemnités cumul",
          "OVERLAPPING_ALLOWANCE_DESCRIPTION": "Cette allocation se superposera à une allocation existante. Vous ne pouvez pas cumuler des indemnités pour le même type d'absence.",
          "FINALISED_ALLOWANCE_TITLE": "Allocation finalisée",
          "FINALISED_ALLOWANCE_DESCRIPTION": "Cette allocation a été finalisée. Pour le modifier ou le supprimer, vous devez d'abord rétablir l'allocation.",
          "INVALID_UNIT_TITLE": "Unité non valide",
          "INVALID_UNIT_DESCRIPTION": "Une allocation dont le calendrier d'accumulation est fixé à Chaque heure travaillée doit avoir pour unité les heures.",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_TITLE": "Date d'entrée en vigueur non valide",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "La date d'entrée en vigueur choisie doit se situer entre le début et la fin de l'année d'allocation.",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_TITLE": "Date de référence non valide",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "La date de référence doit se situer entre la date d'entrée en vigueur de l'allocation et la fin de l'année d'allocation."
        },
        "ALLOWANCE_FORM": {
          "ERROR_500": "Oups, nous avons rencontré un problème à ce moment-là. Réessayez, s'il vous plaît.",
          "ALERT_SUCCESS_ADD": "L'allocation a été ajoutée.",
          "ALERT_SUCCESS_UPDATE": "L'allocation a été mise à jour.",
          "ALERT_ERROR_CALCULATE_BOOKED": "Nous n'avons pas pu calculer leur valeur réservée pour le moment. Veuillez réessayer.",
          "BUTTON_SAVE": "Enregistrer",
          "BUTTON_SAVE_LOADING": "Économiser...",
          "TITLE": "Allocation",
          "LABEL_YEAR_STARTS": "Début de l'année",
          "YEAR_STARTS_HELP": "L'année de vacances par défaut de votre organisation commence à {{settingDate}}.",
          "YEAR_STARTS_CUSTOMISE_INITIATE": "Choisissez un autre jour ou un autre mois.",
          "YEAR_STARTS_CUSTOMISE_CANCEL": "Revenez au jour et au mois par défaut.",
          "LABEL_EFFECTIVE_DATE": "Date d'entrée en vigueur",
          "EFFECTIVE_DATE_HELP": "Si cette allocation remplace une allocation existante la même année, vous devez choisir une date à partir de laquelle elle entrera en vigueur.",
          "ALERT_MISALIGNED_YEAR_START": "Cela ne correspond pas à l'année de vacances par défaut de votre organisation.",
          "ALERT_MISALIGNED_EFFECTIVE_DATE": "Votre date d'entrée en vigueur ne se situe pas dans l'année d'allocation.",
          "ALERT_ALTERED_EFFECTIVE_DATE": "Votre date d'entrée en vigueur ne correspond pas à la date de début de l'année d'allocation. Vous ne devez le faire que si vous souhaitez remplacer une allocation existante au cours de la même année.",
          "LABEL_ABSENCE_TYPE": "Type d'absence",
          "LABEL_SCHEDULE_OF_ACCRUAL": "Calendrier de comptabilisation",
          "TOOLTIP_SCHEDULE_OF_ACCRUAL": "Pour attribuer les heures ou les jours manuellement, choisissez « début de l'année comptable ». Pour que Rotaready attribue des heures ou des jours à chaque mois civil, choisissez « chaque mois civil ». Pour cumuler automatiquement en fonction des heures travaillées, choisissez « chaque heure travaillée ».",
          "LABEL_UNIT": "Unité",
          "LABEL_DEDUCTIBLE": "Déductible",
          "TOOLTIP_DEDUCTIBLE": "Contrôlez si l'employé peut faire des demandes d'absence qui déduisent de cette indemnité.",
          "DEDUCTIBLE_YES": "Oui",
          "DEDUCTIBLE_NO": "Non",
          "LABEL_PROJECT": "Prédiction cumulée lors de la réservation",
          "TOOLTIP_PROJECT": "Lorsque vous faites une demande d'absence, prévoyez les heures qui sont susceptibles d'avoir été accumulées et incluez-les dans le chiffre cumulé de l'employé.",
          "PROJECT_YES": "Oui",
          "PROJECT_NO": "Non",
          "LABEL_TOTAL_ACCRUED_ANNUALLY": "Total cumulé chaque année",
          "LABEL_TOTAL_ACCRUED": "Total cumulé",
          "TOOLTIP_TOTAL_ACCRUED": "Entrez le nombre d'heures ou de jours que cet employé a accumulés jusqu'à présent. Rotaready le mettra à jour automatiquement à l'avenir.",
          "LABEL_BOOKED": "Réservé",
          "TOOLTIP_BOOKED": "Entrez le nombre d'heures ou de jours réservés par cet employé. Rotaready le maintiendra automatiquement.",
          "ALERT_BOOKED": "Nous l'avons calculé pour vous sur la base des absences enregistrées dans Rotaready. Ne modifiez cela que si l'absence a été réservée en dehors de Rotaready.",
          "TOOLTIP_BOOKED_BUTTON": "Calculez automatiquement",
          "LABEL_TOTAL_TO_ACCRUE": "Maximum à cumuler",
          "TOOLTIP_TOTAL_TO_ACCRUE_HOURLY": "Entrez le nombre total d'heures ou de jours que cet employé est en droit d'accumuler. Laissez ce champ vide pour ne pas appliquer de limite.",
          "TOOLTIP_TOTAL_TO_ACCRUE_MONTHLY": "Entrez le nombre total d'heures ou de jours que cet employé doit cumuler pour l'année.",
          "LABEL_ACCRUAL_RATE": "DéroGATION au taux d'accumulation",
          "TOOLTIP_ACCRUAL_RATE": "Vous pouvez éventuellement spécifier un taux cumulé si vous souhaitez remplacer le taux par défaut de la juridiction dans laquelle l'employé travaille.",
          "LABEL_OVERSPEND_LIMIT": "Dépenses excessives",
          "TOOLTIP_OVERSPEND_LIMIT": "Autorisez éventuellement l'employé à réserver plus d'heures ou de jours que ce qu'il a cumulé.",
          "LABEL_CARRIED_OVER": "Reporté de l'année dernière",
          "BUTTON_PRO_RATA": "Au prorata",
          "LABEL_EMPLOYMENT_START": "Date de début d'emploi",
          "LABEL_PRO_RATA_TOTAL": "Total cumulé si vous avez travaillé toute l'année",
          "HELP_TEXT_PRO_RATA": "Nous allons définir le total cumulé à {{value}}.",
          "CREATE_NEXT_YEAR_CHECKBOX": "Créez une autre allocation pour l'année prochaine en utilisant le total cumulé si vous avez travaillé toute l'année.",
          "ALERT_SUCCESS_CALCULATE_TOTAL_ACCRUED": "Nous l'avons calculé pour vous en utilisant les heures approuvées pour cet employé.",
          "ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED": "Vous n'êtes pas autorisé à accéder aux heures approuvées de cet employé. Sans cela, nous ne pouvons pas calculer le total cumulé pour vous.",
          "ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED": "Nous n'avons pas pu calculer automatiquement le total des heures accumulées. Réessayez, s'il vous plaît.",
          "TOOLTIP_CALCULATE_TOTAL_ACCRUED": "Calculez automatiquement"
        },
        "ALLOWANCE_TRANSACTIONS": {
          "TITLE": "Transactions",
          "DESCRIPTION": "Voici toutes les transactions qui ont une incidence sur l'allocation, en affichant la plus récente en premier :",
          "TYPE_ACCRUAL_HOURS_WORKED": "Cumul des heures travaillées",
          "TYPE_ABSENCE_REQUESTED": "Absence demandée",
          "TYPE_ABSENCE_REJECTED_OR_CANCELLED": "Absence refusée ou annulée",
          "TYPE_MANUAL_ADJUSTMENT": "Réglage manuel",
          "TYPE_ACCRUAL_SCHEDULE_CHANGED": "Calendrier de comptabilisation modifié",
          "TYPE_CARRY_OVER": "Reporté de l'année précédente",
          "TYPE_ACCRUAL_TIME_BASED": "Accumulation basée sur le temps",
          "TYPE_ABSENCE_UPDATED": "Dates d'absence modifiées",
          "TYPE_HOURS_PER_DAY_RECALCULATION": "Heures par jour mises à jour",
          "ERROR_500": "Oups, nous avons rencontré un problème à ce moment-là. Réessayez, s'il vous plaît.",
          "NO_TRANSACTIONS": "Il n'y a aucune transaction à afficher.",
          "BUTTON_CLOSE": "Fermer",
          "TRANSACTION_TYPE": "Filtrer par type"
        },
        "VIEW_FINALISATION": {
          "TITLE": "Allocation finalisée",
          "BUTTON_CLOSE": "Fermer",
          "BUTTON_REVERT": "Revenir",
          "CONFIRM_REVERT": "Êtes-vous certain de vouloir annuler cette allocation ? Cela supprimera également le dossier de paie correspondant, s'il y en a un.",
          "LABEL_REFERENCE_DATE": "Date de référence",
          "LABEL_PAY_RECORD": "Paye/déduction",
          "PAY_SEE_MORE": "Afficher le dossier de paie",
          "PAY_NONE": "Aucun salaire n'est dû à l'employé et aucun salaire ne doit être déduit.",
          "LABEL_NOTES": "Remarques",
          "NOTES_NONE": "Aucune note n'a été enregistrée.",
          "ERROR_500": "Nous n'avons pas pu enregistrer vos modifications à ce moment-là. Veuillez réessayer.",
          "ERROR_403": "Vous n'êtes pas autorisé à modifier les dossiers de paie de cette personne, nous ne pouvons donc pas annuler l'indemnité.",
          "ALERT_SUCCESS": "L'allocation a été annulée."
        },
        "FINALISE_ALLOWANCE_FORM": {
          "TITLE": "Finaliser l'allocation",
          "BUTTON_SAVE": "Finaliser",
          "ERROR_500": "Nous n'avons pas pu enregistrer vos modifications à ce moment-là. Veuillez réessayer.",
          "ERROR_403": "Vous n'êtes pas autorisé à modifier les dossiers de paie de cette personne, nous ne pouvons donc pas finaliser l'allocation.",
          "ALERT_SUCCESS": "L'allocation a été finalisée.",
          "GUIDANCE": "Nous vous recommandons de finaliser une allocation lorsque vous pouvez être sûr qu'aucune autre absence ne sera cumulée, réservée ou annulée. C'est généralement aussi proche que possible du dernier jour de quelqu'un.",
          "GUIDANCE_FUTURE_YEAR": "Vous n'êtes tenu de le faire que si l'employé part au cours de cette année d'allocation.",
          "LABEL_EFFECTIVE_DATE": "Date de référence",
          "EFFECTIVE_DATE_LEAVER_HELP": "L'allocation prendra fin à cette date. Comme cette personne est en train de partir, nous avons fixé à son dernier jour.",
          "EFFECTIVE_DATE_NOT_LEAVER_HELP": "L'allocation prendra fin à cette date. La date que vous choisissez sera utilisée ci-dessous pour calculer le total des heures ou des jours cumulés.",
          "LABEL_BASIC_PAY": "Rémunération de base",
          "ALERT_BASIC_PAY_NOT_PERMITTED": "Vous n'êtes pas autorisé à consulter les relevés de paie de cet employé. Vous ne pouvez donc pas utiliser cette fonctionnalité pour créer un paiement ou une déduction ponctuels.",
          "ALERT_BASIC_PAY_NOT_AVAILABLE": "L'employé n'a pas de fiche de paie de base à la date de référence que vous avez choisie.",
          "HELP_TEXT_BASIC_PAY": "Il s'agit du salaire de base de l'employé à la date de référence que vous avez choisie.",
          "LABEL_TOTAL_ACCRUED_LIVE": "Total cumulé",
          "HELP_TEXT_TOTAL_ACCRUED_LIVE": "Il s'agit du total des heures accumulées pour cette indemnité, sur la base de tous les jours qui ont été approuvés.",
          "LABEL_ACCRUED_PRO_RATED": "Accumulé (au prorata)",
          "HELP_TEXT_TOTAL_ACCRUED_PRO_RATED": "En utilisant la date de référence que vous avez choisie, le total des heures/jours cumulés a été calculé au prorata.",
          "LABEL_BOOKED": "Total réservé",
          "HELP_TEXT_BOOKED": "Il s'agit de l'absence totale qui a été réservée à ce jour, y compris les demandes en attente et approuvées.",
          "LABEL_ACCRUED_UNTAKEN": "Accumulé mais non pris",
          "STATUS_PAYMENT_DUE": "Cela signifie que l'employé a pris moins d'absences qu'il n'en a accumulées. De nombreux employeurs choisissent de payer l'employé pour cela.",
          "STATUS_DEDUCTION_DUE": "Cela signifie que l'employé a pris plus d'absences qu'il n'en a accumulées. Certains employeurs choisissent de le déduire de la dernière fiche de paie de l'employé.",
          "LABEL_AVERAGE_HOURS": "Nombre moyen d'heures par jour",
          "LABEL_ONE_OFF_PAYMENT": "Versement/déduction ponctuel",
          "HELP_TEXT_ONE_OFF_PAYMENT": "Si vous saisissez un montant, Rotaready créera un relevé de paie unique et le reliera à cette allocation finalisée. Pour une déduction, entrez un montant négatif.",
          "HELP_TEXT_ONE_OFF_PAYMENT_LINK": "Aide sur la façon de calculer cela",
          "LABEL_PAY_ELEMENT": "Élément de rémunération",
          "LABEL_PAYMENT_DATE": "Date applicable",
          "HELP_TEXT_PAYMENT_DATE": "Choisissez avec soin pour vous assurer que ce paiement est inclus dans le dernier cycle de paie de l'employé.",
          "LABEL_NOTES": "Remarques",
          "PLACEHOLDER_NOTES": "Si vous ne saisissez aucune note, nous inclurons automatiquement les chiffres du calcul ci-dessus."
        },
        "FINALISE_ALLOWANCE_PROMPT": {
          "TITLE": "Toutes les allocations sont-elles finalisées ?",
          "DESCRIPTION": "Si vous êtes satisfait que toutes les indemnités soient désormais finalisées, vous devez le confirmer afin que les services tels que la paie puissent se fier aux chiffres.",
          "DESCRIPTION_PART_2": "Vous pouvez également le faire ultérieurement dans l'onglet Emploi.",
          "BUTTON_ATTEST": "Confirmer",
          "BUTTON_LATER": "J'ai encore beaucoup à faire",
          "ALERT_ERROR": "Nous n'avons pas pu enregistrer votre confirmation à ce moment-là. Essayez plutôt cette option dans l'onglet Emploi."
        }
      },
      "EMERGENCY_CONTACTS": {
        "ADD_MODAL": {
          "TITLE": "Nouveau contact d'urgence",
          "RELATIONSHIP_LABEL": "Relation avec l'employé",
          "FIRST_NAME_LABEL": "Prénom",
          "LAST_NAME_LABEL": "Nom de famille",
          "OTHER_NAME_LABEL": "Autre (s) nom (s)",
          "PRIMARY_CONTACT_NUMBER_LABEL": "Numéro de contact principal",
          "SECONDARY_CONTACT_NUMBER_LABEL": "Numéro de contact secondaire",
          "ADDRESS_LABEL": "Adresse",
          "SAVE_BUTTON_TEXT": "Enregistrer",
          "CLOSE_BUTTON_TEXT": "Fermer",
          "ALERT_ERROR_500": "Nous n'avons pas pu enregistrer vos modifications à ce moment-là. Veuillez réessayer."
        },
        "INDEX": {
          "TITLE": "Contacts d'urgence",
          "RELATIONSHIP_COLUMN_TEXT": "Relation",
          "NAME_COLUMN_TEXT": "Nom",
          "PHONE_COLUMN_TEXT": "Numéro (s) de téléphone",
          "ADDRESS_COLUMN_TEXT": "Adresse",
          "NONE_TO_SHOW": "Il n'y a aucun contact d'urgence à indiquer.",
          "ADD_BUTTON_TEXT": "Nouveau contact",
          "OPTIONS_COLUMN_TEXT": "Options",
          "DELETE_LINK": "Supprimer",
          "ALERT_ERROR_500": "Nous n'avons pas pu charger les contacts d'urgence à ce moment-là. Veuillez réessayer.",
          "CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer ce contact d'urgence ?\nCela ne peut pas être annulé."
        }
      },
      "EMPLOYMENT": {
        "LABEL_LENGTH_OF_SERVICE": "Durée de service",
        "LABEL_STARTS_IN": "Commence en",
        "LABEL_OPTIONS": "Options",
        "OPTION_NEW_EMPLOYMENT": "Nouvel emploi",
        "OPTION_END_EMPLOYMENT": "Fin de l'emploi",
        "OPTION_REINSTATE_EMPLOYMENT": "Rétablir un emploi",
        "OPTION_DELETE_EMPLOYMENT": "Supprimer un emploi",
        "TEXT_TERMINATED": "Cet emploi a été résilié, prenant fin le",
        "LABEL_REASON": "Motif",
        "LABEL_REHIRE": "Recruter",
        "LABEL_REHIRE_YES": "Oui",
        "LABEL_REHIRE_NO": "Non",
        "BUTTON_ATTEST": "Confirmer",
        "TEXT_ALLOWANCES_NOT_FINALISED": "Vous n'avez pas encore confirmé que les indemnités d'absence de l'employé ont été finalisées.",
        "TEXT_ALLOWANCES_NOT_FINALISED_PART_2": "Cela se fait généralement au plus tard le dernier jour de l'employé, avant sa dernière période de paie.",
        "TEXT_ALLOWANCES_NOT_FINALISED_LINK": "Afficher les allocations",
        "BUTTON_UNDO": "Annuler",
        "TEXT_ALLOWANCES_FINALISED": "Les indemnités d'absence ont été confirmées telles qu'elles ont été finalisées pour cet emploi.",
        "LABEL_JOIN_DATE": "Date d'adhésion",
        "LABEL_NOTICE_PERIOD": "Délai de préavis",
        "BUTTON_SET_NOTICE_PERIOD": "Fixez un délai de préavis...",
        "LABEL_EMPLOYEE_NUMBER": "Numéro d'employé",
        "TOOLTIP_EMPLOYEE_NUMBER": "Facultatif. Au Royaume-Uni, le HMRC exige un numéro unique pour chaque employé. Nous l'enverrons à votre fournisseur de services de paie si une intégration de paie est activée.",
        "LABEL_RECRUITMENT_SOURCE": "Source de recrutement",
        "LABEL_STARTER_DECLARATION": "Déclaration de démarrage (Royaume-Uni)",
        "BUTTON_SAVE": "Enregistrer",
        "BUTTON_SAVE_LOADING": "Économiser...",
        "BUTTON_NEW_APPOINTMENT": "Nouveau rendez-vous",
        "TITLE_APPOINTMENTS": "Rendez-vous",
        "COLUMN_EFFECTIVE_DATE": "Date d'entrée en vigueur",
        "COLUMN_SITE": "Site",
        "COLUMN_DEPARTMENT": "Département",
        "COLUMN_POSITION": "Rôle du poste",
        "COLUMN_LINE_MANAGER": "Responsable hiérarchique",
        "COLUMN_STAFF_GROUP": "Groupe du personnel",
        "COLUMN_OBLIGATION": "Obligation",
        "TOOLTIP_ACTIVE_APPOINTMENT": "Il s'agit du rendez-vous actif.",
        "LINE_MANAGER_NOT_SET": "Non défini",
        "MIN_OBLIGATION_ZERO_HOURS": "Zéro heure",
        "ERROR_500": "Nous ne pouvons pas charger d'offres d'emploi pour le moment. Veuillez réessayer.",
        "ERROR_403": "Vous n'êtes pas autorisé à consulter/modifier les dossiers d'emploi de cette personne.",
        "LABEL_HOURS": "heures",
        "LABEL_DAYS": "jours",
        "LABEL_WEEK": "semaine",
        "LABEL_MONTH": "mois",
        "LABEL_CURRENT_EMPLOYMENT": "Emploi actuel",
        "ALERT_CHANGES_SAVED": "Vos modifications ont été enregistrées.",
        "CONFIRM_DELETE": "Tu es sûre de vouloir faire ça ? La suppression d'un emploi est irréversible.",
        "CONFIRM_REINSTATE": "Tu es sûre de vouloir faire ça ?\n\nSi quelqu'un rejoint votre organisation, vous devez plutôt créer un nouvel emploi. Vous ne devez rétablir un emploi que s'il a été résilié à tort.",
        "VALIDATION_MODAL": {
          "TITLE": "Nous ne pouvons pas faire ce changement",
          "DESCRIPTION": "Malheureusement, ce que vous essayez de faire entraîne un ou plusieurs problèmes.",
          "NO_EMPLOYMENT_TITLE": "Pas d'emploi",
          "NO_EMPLOYMENT_DESCRIPTION": "Votre changement laisserait l'employé sans emploi ; il doit toujours y en avoir au moins un.",
          "EMPLOYMENT_OVERLAPPING_TITLE": "Des emplois qui se chevauchent",
          "EMPLOYMENT_OVERLAPPING_DESCRIPTION": "Votre changement entraînerait le chevauchement de deux emplois :",
          "EMPLOYMENT_NOT_TERMINATED_TITLE": "Emploi non résilié",
          "EMPLOYMENT_NOT_TERMINATED_DESCRIPTION": "Vous devez mettre fin à l'emploi précédent avant d'en ajouter un nouveau.",
          "EMPLOYMENT_NO_APPOINTMENTS_TITLE": "Emploi sans rendez-vous",
          "EMPLOYMENT_NO_APPOINTMENTS_DESCRIPTION": "Un poste ne peut pas être vide, il doit contenir au moins un rendez-vous.",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_TITLE": "Rendez-vous en dehors de l'emploi",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Votre changement ferait en sorte qu'une partie de ce rendez-vous ne relève pas de l'emploi.",
          "APPOINTMENT_OVERLAPPING_TITLE": "Rendez-vous qui se chevauchent",
          "APPOINTMENT_OVERLAPPING_TITLE_DESCRIPTION": "Votre changement entraînerait le chevauchement de deux rendez-vous :",
          "APPOINTMENT_NO_EMPLOYMENT_TITLE": "Rendez-vous sans emploi",
          "APPOINTMENT_NO_EMPLOYMENT_DESCRIPTION": "Un rendez-vous n'est pas associé à un emploi. Cela ne devrait pas se produire normalement !",
          "SHIFTS_OUTSIDE_EMPLOYMENT_TITLE": "Changements en dehors de l'emploi",
          "SHIFTS_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Votre changement laisserait {{count}} shift (s) à des dates tombant avant le début de l'emploi ou après sa fin. Pour continuer, ces quarts de travail peuvent être supprimés, annulés (ce qui les déplace vers Open Shifts) ou modifiés sur la rotation elle-même :",
          "SHIFT_ENTITY_MISMATCH_TITLE": "Changements dans différents sites/départements",
          "SHIFT_ENTITY_MISMATCH_DESCRIPTION": "Votre changement supprimerait {{count}} shift (s) dont l'origine est la rotation d'un service qui ne correspond pas à celle du service au moment du rendez-vous de l'employé. Pour continuer, ces quarts de travail peuvent être supprimés, annulés (ce qui les déplace vers Open Shifts) ou modifiés sur la rotation elle-même :",
          "ABSENCE_OUTSIDE_EMPLOYMENT_TITLE": "Absence en dehors du travail",
          "ABSENCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Votre modification supprimerait {{count}} booking (s) d'absence à des dates tombant avant le début de l'emploi ou après sa fin. Pour continuer, ils doivent être annulés :",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_TITLE": "Présence en dehors de l'emploi",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "En cas de modification, {{count}} event (s) de participation aura lieu (s) à des dates qui tombent avant le début de l'emploi ou après sa fin. Pour continuer, ils doivent être supprimés :",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_TITLE": "Cartographie des horaires de travail",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Votre modification laisserait {{count}} mappages de routine des quarts de travail à des dates qui précèdent ou suivent le début de l'emploi. Pour continuer, ils doivent être résiliés ou supprimés :",
          "EMAIL_EXISTS_TITLE": "L'adresse e-mail existe déjà",
          "EMAIL_EXISTS_DESCRIPTION": "L'adresse e-mail que vous avez saisie est déjà utilisée par un autre employé. Si cette personne a déjà travaillé pour votre organisation, recherchez-la et ajoutez un nouvel emploi.",
          "MOBILE_EXISTS_TITLE": "Le numéro de téléphone portable existe déjà",
          "MOBILE_EXISTS_DESCRIPTION": "Le numéro de téléphone portable que vous avez saisi est déjà utilisé par un autre employé. Si cette personne a déjà travaillé pour votre organisation, recherchez-la et ajoutez un nouvel emploi.",
          "INVALID_DATE_TITLE": "Date non valide",
          "INVALID_DATE_DESCRIPTION": "Si vous tentez de mettre fin à un emploi, la date de fin que vous avez choisie est antérieure au début de l'emploi.",
          "PERIOD_SIGNED_OFF_TITLE": "Date de signature",
          "PERIOD_SIGNED_OFF_DESCRIPTION": "Votre modification chevauche une ou plusieurs dates qui ont été approuvées.",
          "PAY_ELEMENT_INVALID_TITLE": "Élément de rémunération non valide",
          "PAY_ELEMENT_INVALID_DESCRIPTION": "L'élément de rémunération choisi n'est pas valide.",
          "PAY_RECORD_SAME_DATE_TITLE": "Dossier de paie dupliqué",
          "PAY_RECORD_SAME_DATE_DESCRIPTION": "Un dossier de paie existe déjà pour l'élément de rémunération que vous avez choisi à cette date.",
          "RECORD_MISSING_TITLE": "Fiche de paie supprimée",
          "RECORD_MISSING_DESCRIPTION": "Ce relevé de paie a été supprimé et ne peut plus être modifié.",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_TITLE": "Incompatibilité du calendrier de paie",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_DESCRIPTION": "Votre modification laisserait {{count}} convocation (s) d'absence associée (s) à un calendrier de paie différent. Pour continuer, ils doivent être annulés :",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_TITLE": "Lié à l'allocation finalisée",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_DESCRIPTION": "Ce relevé de paie est lié à une allocation finalisée et ne peut pas être modifié. Pour le supprimer, recherchez l'allocation et annulez-la.",
          "BUTTON_TRY_AGAIN": "Essayez à nouveau",
          "BUTTON_CLOSE": "Fermer"
        },
        "LEAVER_FORM": {
          "TITLE": "Fin de l'emploi",
          "ALERT_SUCCESS": "L'emploi a pris fin.",
          "ERROR_500": "Nous n'avons pas pu enregistrer vos modifications à ce moment-là. Veuillez réessayer.",
          "LABEL_END_DATE": "Dernier jour de travail",
          "LABEL_REASON": "Motif du départ",
          "LABEL_REHIRE": "Réembauchez",
          "CHECKBOX_REHIRE": "Cet employé est considéré comme éligible à une réembauche à l'avenir.",
          "LABEL_NOTES": "Remarques",
          "PLACEHOLDER_NOTES": "Enregistrez éventuellement certaines notes associées au départ de cet employé.",
          "BUTTON_SAVE": "Enregistrer",
          "BUTTON_SAVE_LOADING": "Économiser..."
        },
        "EMPLOYMENT_FORM": {
          "TITLE": "Nouvel emploi",
          "RESET_WARNING": "Le compte de l'employé sera marqué comme non approuvé et divers attributs seront réinitialisés, tels que le nombre moyen d'heures travaillées par jour et par semaine.",
          "LABEL_START_DATE": "Date de début d'emploi",
          "LABEL_NOTICE_PERIOD": "Délai de préavis",
          "LABEL_EMPLOYEE_NUMBER": "Numéro d'employé",
          "TOOLTIP_EMPLOYEE_NUMBER": "Facultatif. Au Royaume-Uni, le HMRC exige un numéro unique pour chaque employé. Nous l'enverrons à votre fournisseur de services de paie si une intégration de paie est activée.",
          "LABEL_RECRUITMENT_SOURCE": "Source de recrutement",
          "LABEL_STARTER_DECLARATION": "Déclaration de démarrage (Royaume-Uni)",
          "LABEL_INVITE": "Inviter à Rotaready",
          "CHECKBOX_INVITE": "Envoyez un lien magique à cet employé, qui l'invite à définir son mot de passe et à se connecter pour voir ses quarts de travail.",
          "BUTTON_ADD": "Ajouter",
          "BUTTON_ADD_LOADING": "Ajouter...",
          "ALERT_SUCCESS": "L'emploi a été créé.",
          "ERROR_500": "Nous n'avons pas pu enregistrer vos modifications à ce moment-là. Veuillez réessayer."
        },
        "APPOINTMENT_FORM": {
          "TITLE": "Rendez-vous",
          "ALERT_MAPPINGS": "Veuillez compléter les mappages de flux par défaut ou vérifier la déclaration.",
          "ALERT_SAVED": "Le rendez-vous a été enregistré.",
          "ALERT_DELETED": "Le rendez-vous a été supprimé.",
          "ERROR_500": "Nous n'avons pas pu enregistrer vos modifications à ce moment-là. Veuillez réessayer.",
          "INFO_NEW_APPOINTMENT": "Nous avons utilisé le dernier rendez-vous pour pré-remplir ce formulaire pour vous.",
          "LABEL_EFFECTIVE_DATE": "Date d'entrée en vigueur",
          "HELP_TEXT_EFFECTIVE_DATE": "Comme il s'agit du premier rendez-vous, la date d'entrée en vigueur est liée à la date d'entrée en fonction.",
          "INFO_NEW_EMPLOYEE": "Pour terminer, configurez le nouveau rendez-vous de cet employé en définissant son site/service, son poste et d'autres attributs clés.",
          "LABEL_LINE_MANAGER": "Responsable hiérarchique",
          "LABEL_LINE_MANAGER_NOT_SET": "Non défini",
          "TITLE_JOB_ROLE": "Rôle du poste",
          "LABEL_SITE_DEPARTMENT": "Site/département",
          "LABEL_JOB_ROLE": "Rôle du poste",
          "LABEL_STAFF_GROUP": "Groupe du personnel",
          "HELP_TEXT_STAFF_GROUP": "Les groupes de personnel sont utilisés pour séparer visuellement les personnes qui font partie de la rotation et celles qui utilisent d'autres outils.",
          "TITLE_EMPLOYMENT_CONTRACT": "Contrat de travail",
          "LABEL_COMPANY": "L'entreprise",
          "LABEL_PAYROLL_CALENDAR": "Calendrier de paie",
          "LABEL_CONTRACT_TYPE": "Type de contrat",
          "LABEL_PUBLIC_HOLIDAYS": "Jours fériés",
          "HELP_TEXT_PUBLIC_HOLIDAYS": "Cet employé travaille généralement les jours fériés.",
          "LABEL_WORKING_TIME": "Réglementation du temps de travail",
          "HELP_TEXT_WORKING_TIME": "Cet employé a choisi de ne pas bénéficier des limites de travail facultatives prévues par la réglementation sur le temps de travail.",
          "TITLE_CONTRACTUAL_OBLIGATION": "Obligation contractuelle",
          "LABEL_MIN_OBLIGATION": "Heures/jours garantis",
          "HELP_TEXT_MIN_OBLIGATION": "Un minimum de travail est garanti à cet employé.",
          "LABEL_MAX_OBLIGATION": "Limite de travail",
          "HELP_TEXT_MAX_OBLIGATION": "Appliquez une limite supérieure au nombre d'heures ou de jours que cet employé peut être amené à travailler.",
          "TITLE_STREAM_MAPPINGS": "Mappages de flux par défaut",
          "HELP_TEXT_STREAM_MAPPINGS": "Lorsque cet employé travaille par équipes, les mappages du type de poste déterminent le ou les flux dans lesquels répartir son coût salarial. Si le type de décalage n'en comporte aucun, ceux-ci sont utilisés à la place :",
          "COLUMN_STREAM": "Diffusez",
          "COLUMN_ATTRIBUTION": "Attribution",
          "COLUMN_OPTIONS": "Options",
          "OPTION_DELETE": "Supprimer",
          "NO_MAPPINGS": "Il n'y en a aucune à montrer.",
          "LABEL_ADD_MAPPING": "Ajouter un nouveau mappage",
          "OPTION_ADD": "Ajouter",
          "LABEL_NO_MAPPINGS": "Aucun mappage",
          "CHECKBOX_NO_MAPPINGS": "Je reconnais qu'en l'absence de mappage des flux par défaut, il peut y avoir des inexactitudes dans Cost Control et dans certains rapports.",
          "HELP_TEXT_NO_MAPPINGS": "Ce n'est pas grave si vous ne voulez pas suivre le coût salarial de cet employé.",
          "BUTTON_SAVE": "Enregistrer",
          "BUTTON_SAVE_LOADING": "Économiser...",
          "BUTTON_DELETE": "Supprimer",
          "BUTTON_DELETE_LOADING": "Supprimer..."
        }
      },
      "DOCUMENTS": {
        "FILTER_LABEL_CATEGORY": "Catégorie",
        "BUTTON_UPLOAD": "Télécharger un fichier",
        "BUTTON_SIGNATURES": "Signatures de documents",
        "OPTION_SEND_TO_SIGN": "Envoyer un document à signer",
        "OPTION_PENDING_SIGNATURES": "Afficher les documents en attente de signature",
        "ERROR_500": "Nous ne pouvons pas charger de documents pour le moment. Veuillez réessayer.",
        "ERROR_403": "Vous n'êtes pas autorisé à consulter/modifier les documents de cette personne.",
        "ERROR_NO_CATEGORIES": "Les documents ne sont pas activés pour votre organisation. Discutez avec nous pour commencer.",
        "TOOLTIP_CATEGORY_NOT_APPLICABLE": "Catégorie marquée comme non obligatoire",
        "TOOLTIP_CATEGORY_INVALID": "Document manquant ou expiré",
        "INFO_CATEGORY_NOT_APPLICABLE": "Cette catégorie a été marquée comme non obligatoire pour cet employé.",
        "LINK_MARK_CATEGORY_REQUIRED": "Marquer comme requis",
        "LINK_MARK_CATEGORY_NOT_REQUIRED": "Marquer comme non obligatoire",
        "INFO_CATEGORY_NO_DOCUMENTS": "Il n'y a aucun document dans cette catégorie.",
        "COLUMN_NAME": "Nom",
        "COLUMN_EXPIRES": "Expire",
        "COLUMN_FILE_SIZE": "Taille du fichier",
        "COLUMN_UPLOADED_BY": "Téléchargé par",
        "COLUMN_OPTIONS": "Options",
        "LINK_DELETE": "Supprimer",
        "LINK_EDIT": "Modifier",
        "ALERT_CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer ce document ? Cela ne peut pas être annulé !",
        "TOOLTIP_SIGNATURE_ELECTRONIC": "Ce document a été signé électroniquement via Rotaready",
        "TOOLTIP_SIGNATURE_MANUAL": "Ce document a été signé manuellement",
        "TOOLTIP_NOTES": "Les notes sont disponibles",
        "PILL_SENSITIVE": "Sensible",
        "PILL_CAN_EXPIRE": "Peut expirer",
        "PILL_VISIBLE_TO_EMPLOYEE": "Visible pour l'employé",
        "TOOLTIP_ACKNOWLEDGED": "Reconnu : {{date}}",
        "PERSONAL": {
          "TITLE": "Mes documents",
          "ALERT_CONFIRM_ACKNOWLEDGE": "En cliquant sur « Accepter », vous confirmez que vous avez lu et compris le document.",
          "INFO_NO_DOCUMENTS": "Vous n'avez aucun document à consulter.",
          "LINK_ACKNOWLEDGE": "Reconnaître",
          "PILL_NEEDS_ACKNOWLEDGEMENT": "Reconnaissance des besoins",
          "PILL_ACKNOWLEDGED": "Reconnu",
          "COLUMN_NAME": "Nom",
          "COLUMN_CATEGORY": "Catégorie",
          "COLUMN_EXPIRES": "Expire",
          "COLUMN_FILE_SIZE": "Taille du fichier",
          "COLUMN_OPTIONS": "Options",
          "FILTER_LABEL_CATEGORY": "Catégorie"
        }
      },
      "DOCUMENT_EDIT_FORM": {
        "TITLE": "Modifier le document",
        "BUTTON_CANCEL": "Annuler",
        "BUTTON_SAVE": "Enregistrer",
        "LABEL_EXPIRY_DATE": "Date d'expiration",
        "LABEL_NOTES": "Remarques",
        "HELP_TEXT_EXPIRY": "Une date d'expiration doit être attribuée au document si la catégorie l'exige. Vous serez invité à prendre des mesures à l'approche de la date, puis à nouveau si elle est dépassée.",
        "ALERT_SUCCESS": "Le document a été mis à jour.",
        "ALERT_ERROR_500": "Une erreur s'est produite lors de la mise à jour du document. Réessayez ou contactez-nous si ce message persiste.",
        "ALERT_ERROR_400": "Il y a quelque chose qui ne va pas dans vos modifications."
      },
      "DOCUMENT_UPLOAD_MODAL": {
        "TITLE": "Téléchargez un document",
        "ALERT_SIGNATURE": "Nous marquerons ce document comme étant signé manuellement.",
        "LABEL_UPLOAD_TO": "Télécharger vers",
        "LABEL_CATEGORY": "Catégorie",
        "LABEL_EXPIRY_DATE": "Date d'expiration",
        "HELP_TEXT_EXPIRY": "Une date d'expiration doit être attribuée au document si la catégorie l'exige. Vous serez invité à prendre des mesures à l'approche de la date, puis à nouveau si elle est dépassée.",
        "LABEL_UPLOAD": "Téléverser",
        "LABEL_NOTES": "Remarques",
        "BUTTON_CANCEL": "Annuler",
        "BUTTON_SAVE": "Enregistrer",
        "ALERT_SUCCESS": "Votre document a été téléchargé.",
        "ALERT_ERROR_500": "Une erreur s'est produite lors du chargement du document. Réessayez ou contactez-nous si ce message persiste.",
        "ALERT_ERROR_400": "Il y a quelque chose qui ne va pas dans votre catégorie et sa date d'expiration."
      },
      "DOCUMENT_SIGNATURE_MODAL": {
        "ALERT_PICK_CATEGORY": "Sélectionnez une catégorie dans laquelle enregistrer le document.",
        "ALERT_SUCCESS_MANUAL": "Votre document a été téléchargé.",
        "ALERT_SUCCESS_ELECTRONIC": "Votre document a été envoyé pour signature électronique.",
        "ALERT_ERROR_500": "Une erreur s'est produite lors de la création du document. Réessayez ou contactez-nous si ce message persiste.",
        "ALERT_ERROR_400": "Nous n'avons pas pu créer votre document. Vérifiez vos balises de fusion et réessayez.",
        "TITLE": "Envoyer un document à signer",
        "ALERT_INFO": "En insérant des balises de fusion dans votre modèle ou votre fichier, Rotaready peut insérer des informations clés sur l'employé à qui vous les envoyez, comme son nom ou son poste. C'est également la façon dont vous contrôlez l'emplacement de la zone de signature.",
        "ALERT_INFO_LINK": "Découvrez comment",
        "LABEL_SEND_TO": "Envoyer à",
        "LABEL_DOCUMENT": "Document",
        "LABEL_DOCUMENT_TEMPLATE": "Utiliser un modèle existant",
        "LABEL_DOCUMENT_UPLOAD": "Téléchargez un fichier unique",
        "LABEL_TEMPLATE": "Modèle",
        "ALERT_NO_TEMPLATES_TITLE": "Vous n'avez aucun modèle.",
        "ALERT_NO_TEMPLATES_HELP": "Gagnez du temps, chargez un fichier une seule fois et réutilisez-le pour plusieurs personnes.",
        "ALERT_NO_TEMPLATES_BUTTON": "Téléchargez un modèle",
        "LABEL_UPLOAD": "Téléverser",
        "SECTION_SIGNING_INFO": "Informations de signature",
        "LABEL_TITLE": "Titre",
        "HELP_TEXT_TITLE": "Donnez un titre à ce document afin que la personne à qui vous l'envoyez sache de quoi il s'agit.",
        "LABEL_EMPLOYER_SIGNS": "Panneaux de l'employeur",
        "OPTION_EMPLOYER_SIGN_FALSE": "Non, seul l'employé",
        "OPTION_EMPLOYER_SIGN_TRUE": "Une signature au nom de l'employeur est requise",
        "LABEL_EMPLOYER_SIGNATORY": "Employeur signataire",
        "HELP_TEXT_EMPLOYER_SIGNATORY": "Sélectionnez qui doit signer ce document au nom de l'employeur.",
        "LABEL_SIGNATURE_METHOD": "Méthode de signature",
        "OPTION_SIGNATURE_METHOD_MANUAL": "Manuel, avec stylo et papier",
        "OPTION_SIGNATURE_METHOD_ELECTRONIC": "Électronique, avec signature électronique entièrement légale",
        "LABEL_EMAIL_MESSAGE": "Message électronique",
        "HELP_TEXT_EMAIL_MESSAGE": "Ajoutez un message facultatif à la notification par e-mail envoyée à tous les signataires.",
        "SECTION_UPON_COMPLETION": "Une fois terminé",
        "SECTION_METHOD": "Méthode de signature",
        "LABEL_SAVE_TO": "Enregistrer dans",
        "HELP_TEXT_SAVE_TO": "Une fois signé, le fichier sera enregistré dans les documents de l'employé dans la catégorie choisie.",
        "LABEL_EXPIRY_DATE": "Date d'expiration",
        "HELP_TEXT_EXPIRY_DATE": "Le document doit être assorti d'une date d'expiration si la catégorie de destination l'exige. Vous serez invité à prendre des mesures à l'approche de la date, puis à nouveau si elle est dépassée.",
        "CREDITS_DEPLETED_TEXT": "Vous avez besoin de crédits pour envoyer ce document pour signature électronique.",
        "CREDITS_DEPLETED_CTA": "Discutez avec nous dès maintenant",
        "CREDITS_AVAILABLE_TEXT": "Il vous <b>reste {{balance}}</b> crédit (s). L'envoi de ce document entraînera l'utilisation d'un crédit.",
        "CREDITS_AVAILABLE_CTA": "Il est facile de recharger vos crédits. Envoyez-nous simplement un message sur le chat en direct.",
        "BUTTON_CANCEL": "Annuler",
        "BUTTON_DOWNLOAD_PREVIEW": "Télécharger l'aperçu",
        "BUTTON_SAVE_DOWNLOAD": "Enregistrer et télécharger",
        "BUTTON_SEND": "Envoyer"
      },
      "NOTES": {
        "ERROR_500": "Nous ne pouvons pas charger de notes pour le moment. Veuillez réessayer.",
        "ALERT_CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer cette note ?",
        "BODY_PLACEHOLDER": "Écrivez une nouvelle note...",
        "SAVE_BUTTON_TEXT": "Ajouter une note",
        "VISIBILITY_SELF": "Privé",
        "VISIBILITY_SELF_HELP": "Visible uniquement par vous",
        "VISIBILITY_SENSITIVE": "Sensible",
        "VISIBILITY_SENSITIVE_HELP": "Visible uniquement par les personnes autorisées à consulter les notes sensibles",
        "VISIBILITY_GENERAL": "Général",
        "VISIBILITY_GENERAL_HELP": "Visible uniquement par les personnes autorisées à voir les notes de cet employé",
        "EDITED_LABEL": "Edité",
        "EDIT_LABEL": "Modifier",
        "DELETE_LABEL": "Supprimer",
        "NO_NOTES": "Il n'y a aucune note à afficher."
      },
      "DANGER_ZONE": {
        "ANONYMISE": {
          "TITLE": "Anonymiser un employé",
          "ERROR_500": "Nous n'avons pas pu économiser à ce moment-là. Veuillez réessayer.",
          "ERROR_403": "Vous n'êtes pas autorisé à accéder aux informations personnelles de cet employé. Cela signifie que vous ne pouvez pas programmer l'anonymisation de leur profil.",
          "LABEL_END_DATE": "Date de fin d'emploi",
          "HELP_TEXT": "Pour se conformer aux lois sur la protection des données, Rotaready peut supprimer toutes les informations personnelles de ce profil d'employé après une période donnée. Au Royaume-Uni, le HMRC exige que les dossiers soient conservés pendant au moins six ans.",
          "HELP_TEXT_LEARN": "En savoir plus",
          "LABEL_ANONYMISE": "Anonymiser",
          "CHECKBOX_ANONYMISE": "Anonymiser cet employé",
          "LABEL_ANONYMISE_DATE": "Anonymisez sur",
          "ALERT_ANONYMISE_DATE": "Pour rendre cet employé anonyme, veuillez choisir une date qui se situe à la fois dans le futur et qui tombe après son dernier jour de travail.",
          "BUTTON_SAVE": "Enregistrer"
        }
      }
    },
    "ABSENCE": {
      "ABSENCE": {
        "TITLE": "Calendrier des absences",
        "FILTER_LABEL_APPROVAL_STATE": "État",
        "FILTER_LABEL_POSITION": "Rôle du poste",
        "FILTER_LABEL_STAFF_GROUP": "Groupe du personnel",
        "ERROR_500": "Nous n'avons pas pu charger l'absence à ce moment-là. Veuillez réessayer.",
        "FILTER_LABEL_ABSENCE_TYPE": "Tapez",
        "FILTER_LABEL_REQUESTER": "Demandeur"
      }
    },
    "REQUESTS": {
      "ABSENCE": {
        "DATES_COLUMN_TEXT": "Date (s)",
        "WHO_COLUMN_TEXT": "Qui",
        "ABSENCE_TYPE_COLUMN_TEXT": "Tapez",
        "SUBMITTED_DATE_TEXT": "Soumis",
        "HOURS_COLUMN_TEXT": "Heures",
        "DAYS_COLUMN_TEXT": "Journées",
        "NO_STAFF_MATCH_TEXT": "Nous n'avons trouvé aucune demande correspondant à vos filtres.",
        "FILTER_LABEL_POSITION": "Rôle du poste",
        "FILTER_LABEL_STAFF_GROUP": "Groupe du personnel",
        "ERROR_500": "Nous n'avons pas pu charger l'absence à ce moment-là. Veuillez réessayer.",
        "FILTER_LABEL_ABSENCE_TYPE": "Tapez",
        "FILTER_LABEL_REQUESTER": "Demandeur"
      },
      "CONFIRM_SWAP_APPROVAL_MODAL": {
        "TITLE": "Tu es sûr ?",
        "VALIDATION_MANUAL_TEXT": "Pour annuler cet échange de quarts de travail, vous devrez modifier manuellement la rotation.",
        "YES_TITLE": "Oui",
        "APPROVE_BUTTON_TEXT": "Approuvez cet échange dès maintenant et informez les deux membres du personnel",
        "NO_TITLE": "Non",
        "DO_NOTHING_BUTTON_TEXT": "Ne faites rien, n'approuvez pas cet échange pour l'instant",
        "CANCEL_BUTTON_TEXT": "Annuler"
      },
      "INDEX": {
        "HEADING": "Éléments nécessitant une attention particulière",
        "ABSENCE_LABEL": "Demandes d'absence",
        "SWAPS_LABEL": "Swaps d'équipes",
        "DOCUMENTS_EXPIRING_LABEL": "Documents expirant",
        "ACCOUNT_APPROVAL_LABEL": "Nouveaux adhérents"
      },
      "SWAPS": {
        "INITIATED_BY_TEXT": "Initié par",
        "INITIATED_TOOLTIP": "Le membre du personnel qui souhaite offrir son poste de travail",
        "SHIFT_COLUMN_TEXT": "Leur changement",
        "SWAPPING_WITH_COLUMN_TEXT": "Échange avec",
        "SWAPPING_TOOLTIP": "Le membre du personnel qui souhaite travailler par équipes",
        "RETURN_SHIFT_COLUMN_TEXT": "Leur changement",
        "OPTION_COLUMN_TEXT": "Options",
        "ACCEPTED_DATE_COLUMN_TEXT": "En attente depuis",
        "NONE_TEXT": "Aucune",
        "NO_STAFF_MATCH_TEXT": "Nous n'avons trouvé aucune demande correspondant à vos filtres.",
        "FILTER_LABEL_PARTICIPANT": "Participant"
      },
      "DOCUMENTS": {
        "NAME_COLUMN_LABEL": "Nom",
        "FILE_NAME_COLUMN_LABEL": "Document",
        "FILE_SIZE_COLUMN_LABEL": "Taille",
        "CATEGORY_COLUMN_LABEL": "Catégorie",
        "EXPIRY_DATE_COLUMN_LABEL": "Expire",
        "OPTIONS_COLUMN_LABEL": "Options",
        "EXPIRED": "Expiré",
        "UPLOAD_NEW_LINK": "Charger un nouveau",
        "NO_DOCS_MATCH_TEXT": "Nous n'avons trouvé aucun document correspondant à vos filtres.",
        "NO_CATEGORIES_TEXT": "Il n'existe aucune catégorie pour les documents susceptibles d'expirer.",
        "FILTER_LABEL_SITE": "Site/département",
        "FILTER_LABEL_APPOINTMENT": "Rendez-vous",
        "FILTER_LABEL_PERSON": "Personne",
        "FILTER_LABEL_CATEGORY": "Catégorie",
        "FILTER_LABEL_EXPIRY": "Expiration",
        "APPOINTMENT_STATUS_ACTIVE": "Actif",
        "APPOINTMENT_STATUS_INACTIVE": "Inactif",
        "X_WEEKS_OR_LESS": " semaines ou moins"
      },
      "NEW_JOINERS": {
        "NAME_COLUMN_LABEL": "Nom",
        "POSITION_COLUMN_LABEL": "Rôle du poste",
        "ENTITY_COLUMN_LABEL": "Site",
        "START_DATE_COLUMN_LABEL": "Commence le",
        "OPTIONS_COLUMN_LABEL": "Options",
        "NO_STAFF_MATCH_TEXT": "Nous n'avons trouvé personne correspondant à vos filtres.",
        "APPROVE_LINK": "Approuver",
        "FILTER_LABEL_APPOINTMENT": "Rendez-vous",
        "FILTER_LABEL_POSITION": "Rôle du poste",
        "FILTER_LABEL_DATE_START": "Date de début",
        "APPOINTMENT_STATUS_ACTIVE": "Actif",
        "APPOINTMENT_STATUS_INACTIVE": "Inactif"
      }
    },
    "TAGS_GROUPS": {
      "TAGS_GROUPS": {
        "HEADING": "Tags et groupes",
        "ASIDE_TEXT": "Un membre du personnel ne peut appartenir qu'à un seul groupe, mais plusieurs balises peuvent lui être attribuées. Les groupes s'apparentent au département ou à l'équipe d'une personne, tandis que les tags peuvent représenter tout ce que vous trouvez significatif, comme les compétences ou les qualités personnelles.",
        "CREATE_NEW_TAG_PLACEHOLDER": "Créer une nouvelle étiquette...",
        "ADDING_BUTTON_TEXT": "Ajouter...",
        "ADD_BUTTON_TEXT": "Ajouter",
        "TAG_HELP": "Cliquez sur un tag pour le modifier et voir à qui il a été attribué :",
        "CREATE_ONE_TEXT": "Vous n'avez actuellement aucun tag. Pourquoi ne pas en créer un ?",
        "CREATE_NEW_GROUP_PLACEHOLDER": "Créer un nouveau groupe...",
        "GROUPS_TITLE": "Groupes",
        "EDIT_LINK_TEXT": "modifier"
      },
      "VIEW_GROUP_MODAL": {
        "EDIT_GROUP_TITLE": "Modifier le groupe",
        "SITE_LABEL": "Site",
        "EDIT_TAB_HEADING": "Modifier",
        "ORG_WIDE_LABEL": "À l'échelle de l'organisation",
        "GROUP_NAME_LABEL": "Nom du groupe",
        "AVAILABILITY_LABEL": "Disponibilité",
        "ALWAYS_AVAILABLE_TEXT": " Toujours disponible",
        "ALWAYS_AVAILABLE_HELP": "Les membres du personnel de ce groupe sont considérés comme étant toujours disponibles pour travailler, sauf lorsque leurs habitudes de disponibilité ou d'absence l'exigent.",
        "ALWAYS_UNAVAILABLE_TEXT": " Toujours indisponible",
        "ALWAYS_UNAVAILABLE_HELP": "Le personnel de ce groupe n'est pas disponible par défaut. Ils utiliseront les « exceptions » et la « disponibilité pour une seule semaine » pour indiquer quand ils sont gratuits.",
        "MEMBERS_TAB_HEADING": "Membres",
        "NO_ACTIVE_MEMBERS_HELP": "Il n'y a aucun membre actif dans ce groupe. <br /><br />Les membres du personnel sont affectés à un groupe par le biais de leur rendez-vous. Trouvez quelqu'un dans la liste du personnel, cliquez sur l'onglet Rendez-vous et modifiez un rendez-vous existant ou ajoutez-en un nouveau.",
        "DELETE_TAB_HEADING": "Supprimer",
        "MOVE_MEMBERS_TO_LABEL": "Déplacer les membres vers",
        "DELETE_TAB_ALERT": "La suppression de ce groupe entraînera le transfert de {{TotalMembers}} vers un autre groupe. Cela est irréversible et ne peut être annulé.",
        "CONFIRM_NAME_LABEL": "Tapez le nom du groupe",
        "CONFIRM_NAME_HELP_TEXT": "Pour nous convaincre que vous souhaitez le faire, veuillez saisir le nom du groupe dans la case.",
        "DELETING_BUTTON_TEXT": "Supprimer...",
        "DELETE_BUTTON_TEXT": "Supprimer",
        "SAVING_BUTTON_TEXT": "Économiser...",
        "SAVE_BUTTON_TEXT": "Enregistrer",
        "CANCEL_BUTTON_TEXT": "Annuler"
      },
      "VIEW_TAG_MODAL": {
        "EDIT_TAG_TITLE": "Modifier le tag",
        "EDIT_CLONE_LABEL": "Modifier/cloner",
        "EDIT_EXISTING_TAG_TEXT": " Modifier un tag existant",
        "CLONE_TAG_TEXT": " Clonez la balise et enregistrez-la comme nouvelle",
        "TAG_NAME_LABEL": "Nom du tag",
        "STAFF_MEMBERS_LABEL": "Membres du personnel",
        "TAG_TYPEAHEAD_PLACEHLDER": "Tapez pour étiqueter le personnel",
        "LAST_EDITED_LABEL": "Dernière modification",
        "ON_TEXT": "sur {{date|momentFormat : 'llll'}}",
        "DELETING_BUTTON_TEXT": "Supprimer...",
        "DELETE_BUTTON_TEXT": "Supprimer",
        "SAVING_BUTTON_TEXT": "Économiser...",
        "SAVE_BUTTON_TEXT": "Enregistrer",
        "CLONING_BUTTON_TEXT": "Clonage...",
        "CLONE_BUTTON_TEXT": "Cloner",
        "CANCEL_BUTTON_TEXT": "Annuler",
        "CLOSE_BUTTON_TEXT": "Fermer"
      }
    },
    "ADD_NEW": {
      "TITLE": "Ajouter un employé",
      "LABEL_FIRST_NAME": "Prénom",
      "LABEL_LAST_NAME": "Nom de famille",
      "LABEL_DOB": "Date de naissance",
      "LABEL_EMAIL": "Adresse e-mail",
      "HELP_TEXT_EMAIL_OIDC": "Cela doit correspondre exactement à ce que l'employé utilise pour votre service d'authentification unique. Il se termine généralement par le nom de domaine de votre entreprise.",
      "HELP_TEXT_EMAIL_ROTAREADY": "Tout le monde possède un compte sur Rotaready et utilise son adresse e-mail pour se connecter.",
      "LABEL_COPY_EXISTING": "Copier un employé existant",
      "PLACEHOLDER_COPY_EXISTING_NO_THANKS": "Non merci",
      "HELP_TEXT_COPY_EXISTING": "Copiez le rendez-vous, l'indemnité de vacances et les étiquettes d'un autre employé.",
      "LABEL_DATE_START": "Date de début d'emploi",
      "LABEL_SITE": "Site/département",
      "LABEL_PERMISSIONS": "Niveau d'autorisation",
      "LABEL_INVITE": "Inviter à Rotaready",
      "HELP_TEXT_INVITE_OIDC": "Envoyez un lien magique à cet employé, qui l'invite à se connecter pour la première fois.",
      "HELP_TEXT_INVITE_ROTAREADY": "Envoyez un lien magique à cet employé, qui l'invite à définir son mot de passe et à se connecter pour voir ses quarts de travail.",
      "LABEL_ONBOARDING": "Intégration",
      "CHECKBOX_ONBOARDING_OPT_IN": "Demandez à cet employé de terminer son auto-intégration lors de sa première connexion à Rotaready. Selon les paramètres de votre organisation, il leur sera demandé de fournir des informations personnelles telles que leur adresse, les personnes à contacter en cas d'urgence et les documents relatifs au droit au travail.",
      "BUTTON_ADD": "Ajouter",
      "BUTTON_ADD_LOADING": "Ajouter...",
      "ALERT_SUCCESS": "Succès ! Terminez la configuration de votre employé en complétant chaque onglet.",
      "ALERT_500": "Nous n'avons pas pu ajouter l'employé à ce moment-là. Veuillez réessayer.",
      "ALERT_403": "Vous n'êtes pas autorisé à ajouter un nouvel employé.",
      "ALERT_403_PERMISSION_LEVEL": "Vous n'êtes pas autorisé à ajouter quelqu'un à ce niveau d'autorisation.",
      "ALERT_403_COPY_EXISTING": "Vous n'êtes pas autorisé à copier cet employé.",
      "ALERT_403_ENTITY": "Vous n'êtes pas autorisé à ajouter quelqu'un à ce site/département.",
      "DUPLICATE_MODAL": {
        "TITLE": "Employés existants",
        "DESCRIPTION": "La personne que vous essayez d'ajouter partage certains attributs avec un ou plusieurs employés existants. Si cette personne a déjà travaillé pour votre organisation, ajoutez un nouvel emploi.",
        "COLUMN_NAME": "Nom",
        "COLUMN_EMPLOYMENT": "L'emploi",
        "COLUMN_SITE": "Site/département",
        "COLUMN_SIMILARITIES": "Similitudes",
        "COLUMN_OPTIONS": "Options",
        "STATUS_ACTIVE": "Actif",
        "STATUS_INACTIVE": "Inactif",
        "OPTION_VIEW": "Afficher l'employé",
        "OPTION_NEW_EMPLOYMENT": "Nouvel emploi",
        "BUTTON_PROCEED": "Continuez quand même",
        "BUTTON_BACK": "Retournez",
        "SIMILARITY_NAME": "Nom",
        "SIMILARITY_EMAIL": "Courrier électronique",
        "SIMILARITY_MOBILE": "Numéro de téléphone portable",
        "SIMILARITY_DOB": "Date de naissance"
      }
    }
  },
  "DIRECTIVES": {
    "STAFF": {
      "SEARCH": {
        "PLACEHOLDER": "Rechercher...",
        "AND_X_MORE": "Et {{x}} plus encore...",
        "INACTIVE_APPOINTMENT": "Inactif"
      },
      "ADD_ROUTINE_MAPPING_FORM": {
        "STARTS_LABEL": "Commence",
        "STARTS_TEXT": "Premier jour de cette routine pour {{firstName}}",
        "ENDS_INPUT_TEXT": " Se termine",
        "ENDS_TEXT": "Dernier jour de cette routine pour {{FirstName}}",
        "ONGOING_TEXT": "En cours",
        "SHIFT_ROUTINE_LABEL": "Routine de travail",
        "OFFSET_LABEL": "Offset",
        "OFFSET_HELP": "Il s'agit du nombre de jours pendant lesquels {{FirstName}} devrait commencer selon ce schéma."
      },
      "AVAILABILITY_RULE": {
        "IM_UNAVAILABLE_TEXT": "Je ne serai pas disponible",
        "CANCEL_TEXT": "annuler",
        "AVAILABLE_TEXT": "DISPONIBLE",
        "UNAVAILABLE_TEXT": "INDISPONIBLE",
        "AVAILABLE_FROM_TEXT": "Je ne suis disponible que depuis ",
        "UNAVAILABLE_FROM_TEXT": "Je ne suis pas disponible depuis ",
        "TO_TEXT": " pour ",
        "ALL_DAY": "Toute la journée",
        "AM_ONLY": "AM uniquement",
        "PM_ONLY": "PM uniquement",
        "AVAIALABLE_CUSTOM": "Disponible (personnalisé)",
        "UNAVAILABLE_CUSTOM": "Non disponible (personnalisé)",
        "UNAVAILABLE": "Non disponible",
        "AVAILABLE": "Disponible",
        "AVAILABLE_AM": "Disponible (matin uniquement)",
        "AVAILABLE_PM": "Disponible (après-midi uniquement)"
      },
      "AUDIT_USER_WIDGET": {
        "EDITED_BY": "Edité par"
      },
      "VALIDATION_BULK_ABSENCE_EDIT": {
        "INFO_TRUNCATED": "En raison du volume de réservations d'absences concernées, elles ne peuvent pas être modifiées en bloc ici. Utilisez l'onglet Absence sur le profil de l'employé ou contactez Rotaready pour obtenir de l'aide.",
        "INFO_SUCCESS": "Il ne reste plus aucune réservation d'absence contradictoire.",
        "ERROR_500": "Nous avons rencontré une erreur inattendue à ce moment-là. Réessayez, s'il vous plaît.",
        "ERROR_400": "Cette modification ne peut pas être apportée car elle chevauche une date qui a été approuvée.",
        "COLUMN_DATES": "Date (s)",
        "COLUMN_TYPE": "Tapez",
        "COLUMN_HOURS": "Heures",
        "COLUMN_DAYS": "Journées",
        "COLUMN_STATUS": "État",
        "COLUMN_OPTIONS": "Options",
        "OPTION_CANCEL": "Annuler"
      },
      "VALIDATION_BULK_ATTENDANCE_EDIT": {
        "INFO_TRUNCATED": "En raison du volume de participation aux événements concernés, ils ne peuvent pas être modifiés en bloc ici. Utilisez l'onglet Présence sur le profil de l'employé ou contactez Rotaready pour obtenir de l'aide.",
        "INFO_SUCCESS": "Il ne reste plus aucun événement de participation conflictuel.",
        "ERROR_500": "Nous avons rencontré une erreur inattendue à ce moment-là. Réessayez, s'il vous plaît.",
        "ERROR_400": "Cette modification ne peut pas être effectuée car elle coïncide avec une date qui a été approuvée.",
        "COLUMN_EVENT": "Événement",
        "COLUMN_DATE": "Date",
        "COLUMN_OPTIONS": "Options",
        "EVENT_TYPE_CLOCK_IN": "Déclencher",
        "OPTION_DELETE": "Supprimer"
      },
      "VALIDATION_BULK_ROUTINE_MAPPING_EDIT": {
        "INFO_TRUNCATED": "En raison du volume de mappages des horaires de travail concernés, ils ne peuvent pas être modifiés en bloc ici. Veuillez utiliser l'onglet Shift Routines sur le profil de l'employé ou contacter Rotaready pour obtenir de l'aide supplémentaire.",
        "INFO_SUCCESS": "Il ne reste plus aucun mappage de routine des quarts de travail conflictuel.",
        "ERROR_500": "Nous avons rencontré une erreur inattendue à ce moment-là. Réessayez, s'il vous plaît.",
        "ERROR_400": "Cette modification ne peut pas être apportée car elle chevauche une date qui a été approuvée.",
        "COLUMN_START_DATE": "Date de début",
        "COLUMN_END_DATE": "Date de fin",
        "COLUMN_SHIFT_ROUTINE": "Routine de travail",
        "COLUMN_OPTIONS": "Options",
        "LABEL_OPEN_ENDED": "Durée indéterminée",
        "OPTION_ADD_END_DATE": "Ajouter une date de fin",
        "OPTION_DELETE": "Supprimer"
      },
      "VALIDATION_BULK_SHIFT_EDIT": {
        "INFO_TRUNCATED": "En raison du volume de quarts de travail concernés, il n'est pas possible de les modifier en bloc ici. Veuillez modifier les rotations correspondantes ou contacter Rotaready pour obtenir de l'aide.",
        "INFO_SUCCESS": "Il ne reste aucun changement contradictoire.",
        "ERROR_500": "Nous avons rencontré une erreur inattendue à ce moment-là. Réessayez, s'il vous plaît.",
        "ERROR_400": "Ce changement ne peut pas être effectué. Elle peut chevaucher une date qui a été approuvée. Pour en savoir plus, essayez ceci sur la rotation elle-même.",
        "BUTTON_UNASSIGN_SELECTED": "Annuler l'attribution de la sélection",
        "BUTTON_DELETE_SELECTED": "Supprimer la sélection",
        "COLUMN_DATE": "Date",
        "COLUMN_TIME": "Heure",
        "COLUMN_DEPARTMENT": "Département",
        "COLUMN_TYPE": "Tapez",
        "COLUMN_STATUS": "État",
        "COLUMN_OPTIONS": "Options",
        "STATUS_PUBLISHED": "Publié",
        "STATUS_DRAFT": "Brouillon",
        "OPTION_UNASSIGN": "Annuler l'attribution",
        "OPTION_DELETE": "Supprimer"
      },
      "VALIDATION_EPHEMERAL_RECORDS": {
        "LABEL_OPEN_ENDED": "(à durée indéterminée)",
        "LABEL_NEW": "Nouveau",
        "LABEL_EXISTING": "Existant",
        "EMPLOYMENT": "L'emploi",
        "APPOINTMENT": "Rendez-vous"
      },
      "ACCOUNT_COMPLETION_TRACKER": {
        "CONSTITUENT_PERSONAL": "Personnel",
        "CONSTITUENT_EMPLOYMENT": "L'emploi",
        "CONSTITUENT_PAY": "Payez",
        "CONSTITUENT_HOLIDAY_ALLOWANCE": "Allocation de vacances"
      }
    },
    "CALENDAR": {
      "TIP": "Pour demander une absence, il suffit de cliquer sur un jour, ou de cliquer et de faire glisser le pointeur pour sélectionner plusieurs jours à la fois.",
      "TODAY": "Aujourd'hui",
      "MONTH": "Mois",
      "WEEK": "Semaine",
      "DAY": "Journée"
    },
    "HIERARCHY_ITEM_PICKER": {
      "PICKER": "Cueilleur",
      "PLACEHOLDER": "Aucun {{itemDescriptor}} sélectionné",
      "FILTER_TO": "Filtrer pour...",
      "RESET": "Réinitialiser",
      "REMOVED_BY_FILTER": "Le {{ItemDescriptor}} que vous avez sélectionné a été supprimé par le filtre.",
      "NO_SELECTED": "Aucun {{itemDescriptor}} n'a été sélectionné"
    },
    "SUBSCRIBE_BUTTON": {
      "LABEL_ETID_1": "demandé",
      "LABEL_ETID_2": "annulé",
      "LABEL_ETID_3": "approuvé",
      "LABEL_ETID_4": "rejetés",
      "LABEL_ETID_5": "marqué pour approbation",
      "LABEL_ETID_6": "publié",
      "LABEL_ETID_7": "revenu au brouillon",
      "LABEL_ETID_8": "la semaine est terminée",
      "LABEL_ETID_9": "en attente d'approbation",
      "LABEL_ETID_10": "approuvé",
      "LABEL_ETID_11": "demandé",
      "LABEL_ETID_13": "approuvé",
      "LABEL_ETID_14": "rejetés",
      "LABEL_ETID_15": "annulé",
      "LABEL_ETID_16": "expiration imminente",
      "LABEL_ETID_17": "expiré",
      "LABEL_ETID_29": "commencé",
      "LABEL_ETID_30": "accepté par quelqu'un",
      "LABEL_ETID_31": "rejeté par quelqu'un",
      "LABEL_ETID_32": "terminé",
      "LABEL_ETID_36": "la semaine est inversée",
      "LABEL_ETID_37": "la journée est clôturée",
      "LABEL_ETID_38": "le jour est inversé",
      "DESCRIPTOR_ABSENCE": "une demande d'absence est",
      "DESCRIPTOR_ROTA": "une rotation est",
      "DESCRIPTOR_ACCOUNT": "un employé est",
      "DESCRIPTOR_SWAP": "un échange de quarts de travail est",
      "DESCRIPTOR_COST_CONTROL": "une maîtrise des coûts",
      "DESCRIPTOR_DOCUMENT": "un document est",
      "DESCRIPTOR_BROADCAST": "une diffusion par équipes est",
      "NOUN_ABSENCE": "réservation des absences",
      "NOUN_ROTA": "rotation",
      "NOUN_ACCOUNT": "employé",
      "NOUN_SWAP": "échange de quarts de travail",
      "NOUN_COST_CONTROL": "record de contrôle des coûts",
      "NOUN_DOCUMENT": "document",
      "NOUN_BROADCAST": "diffusion par équipes",
      "NOTIFY_SENTENCE_EXORDIUM": "M'avertir quand",
      "NOTIFY_SENTENCE_TRAILING_IN": "dans...",
      "UNSUBSCRIBE": "Désabonnement",
      "SUBSCRIBE": "Souscrire",
      "SEARCH_PLACEHOLDER": "Rechercher...",
      "BACK_LINK": "Retour",
      "RESOURCE_SUBSCRIBE_TOOLTIP": "Soyez informé des modifications apportées à ce {{name}}",
      "RESOURCE_UNSUBSCRIBE_TOOLTIP": "Désabonnez-vous des modifications apportées à ce {{name}}"
    },
    "ROTA": {
      "DASHBOARD_WEEK": {
        "VIEW_ROTA": "Afficher la rotation",
        "LAST_WEEK": "La semaine dernière",
        "THIS_WEEK": "Cette semaine",
        "NO_ATTENDANCE_ISSUES": "Aucun problème de présence",
        "ATTENDANCE_ISSUES": "problème (s) de présence",
        "RESOLVE": "Résoudre",
        "ACTUAL_SALES_ENTERED": "Chiffres de vente réels saisis",
        "ACTUAL_SALES_MISSING": "Chiffres <strong>de ventes réels</strong> manquants",
        "WTD_COMPLIANT": "Conforme à la réglementation sur le temps de travail",
        "VIEW": "Afficher",
        "WTD_VIOLATIONS": "Violation (s) du temps de travail",
        "SIGN_OFF_NOT_COMPLETED": "La <strong>déconnexion</strong> n'est pas encore terminée",
        "SIGN_OFF": "Déclencher",
        "WEEK_SIGNED_OFF": "Semaine terminée",
        "ABSENCE_REQUEST": "Demandes d'absence",
        "ADDED_SALES_FORECAST": "Ajout de prévisions de ventes",
        "DRAFTED_ROTA": "Rotation rédigée",
        "MARKED_FOR_APPROVAL": "Rota marqué pour approbation",
        "PUBLISHED": "Rotation publiée",
        "OUTSTANDING": "{{absences}} OSX",
        "NOT_ADDED": "Non ajouté",
        "NOT_DRAFTED": "Non rédigé",
        "NOT_DONE": "Pas terminé",
        "NOT_PUBLISHED": "Non publié"
      }
    },
    "SIDEBAR": {
      "NOTIFICATION_POPOVER": {
        "NO_NOTIFICATIONS": "Aucune notification !",
        "MARK_ALL_READ": "Marquer tout comme lu"
      },
      "SETTINGS_POPOVER": {
        "TIMEZONE": "Ce fuseau horaire est différent de votre fuseau horaire local.",
        "EDIT_PREFERENCES": "Modifier les préférences",
        "MANAGE_SUBSCRIPTIONS": "Gérer les abonnements",
        "SHOW_INTERCOM": "Afficher le chat d'assistance",
        "HIDE_INTERCOM": "Masquer le chat d'assistance",
        "SIGN_OUT": "Déconnectez-vous"
      }
    },
    "DATE_PICKER": {
      "CALENDAR": "Calendrier",
      "GO_TO_TODAY": "Passez à aujourd'hui"
    },
    "ENTITY_SELECTOR": {
      "ORG_WIDE": "À l'échelle de l'organisation",
      "NO_OPTIONS": "Aucune option",
      "SEARCH_PLACEHOLDER": "Rechercher..."
    },
    "FILTER_OPTIONS_BUTTON": {
      "IS": "est",
      "AND": "et",
      "OR": "ou",
      "ANY": "N'importe lequel",
      "SEARCH_PLACEHOLDER": "Rechercher...",
      "NO_OPTIONS": "Aucune option",
      "SELECT_ALL": "Tout sélectionner",
      "CLEAR_SELECTED": "Effacer la sélection"
    },
    "FILTER_STAFF_SEARCH_BUTTON": {
      "IS": "est",
      "ANY": "N'importe lequel",
      "RESET": "Réinitialiser"
    },
    "FILTER_DATE_BUTTON": {
      "ANY": "n'importe quel",
      "BEFORE": "avant",
      "AFTER": "après",
      "BETWEEN": "entre",
      "IS": "est",
      "AND": "et",
      "DONE": "Terminé"
    },
    "FILTER_NUMBER_BUTTON": {
      "ANY": "est n'importe quel",
      "GREATER": "est supérieur à",
      "LESS": "est inférieur à",
      "EXACTLY": "est exactement",
      "DONE": "Terminé"
    },
    "VALIDATION_FAILURE": {
      "TITLE": "Problèmes de validation",
      "ISSUE": "numéro",
      "ISSUES": "problèmes",
      "CHANGE_MESSAGE": "Le changement que vous essayez d'apporter a entraîné ",
      "IGNORE_CONTINUE": "Ignorez et continuez quand même",
      "IGNORE_PROBLEM": "Ignorez les changements problématiques et réessayez",
      "LOADING": "Chargement en cours..."
    },
    "PICK_LIST": {
      "NO_OPTIONS": "Aucune option",
      "SEARCH_PLACEHOLDER": "Rechercher...",
      "DEFAULT_NULL_OPTION_LABEL": "Aucune"
    },
    "FILE_UPLOAD": {
      "ALERT_UNSUPPORTED_FILE_TYPE": "Malheureusement, ce type de fichier n'est pas pris en charge.",
      "ALERT_FILE_SIZE": "Malheureusement, ce fichier est trop volumineux.",
      "LABEL_ACCEPTED_FILE_TYPES": "Types de fichiers acceptés",
      "LABEL_LIMIT": "limite",
      "INFO_DROP_AVAILABLE_PART_1": "Faites glisser et déposez un fichier ici ou",
      "INFO_DROP_AVAILABLE_PART_2": "cliquez pour parcourir",
      "INFO_DROP_NOT_AVAILABLE": "Cliquez pour rechercher un fichier",
      "INFO_DRAG_ACTIVE": "Joli objectif, c'est l'heure de lâcher !",
      "INFO_UPLOAD_FAILED": "Le téléchargement a échoué, veuillez réessayer"
    }
  },
  "AVAILABILITY": {
    "ABSENCE": {
      "TAB_ABSENCE": "Réservations d'absences",
      "TAB_ALLOWANCES": "Allocations"
    },
    "WEEKLY_AVAILABILITY_MODAL": {
      "ADD_TITLE": "Ajouter une disponibilité hebdomadaire",
      "EDIT_TITLE": "Modifier la disponibilité hebdomadaire",
      "NICKNAME": "Surnom",
      "PLACEHOLDER": "c'est à dire. Pendant les études",
      "HELP": "Vous devez donner à ce modèle de disponibilité un nom significatif, comme « Pendant vos études » si cela représente le moment où vous pouvez travailler tout en préparant des examens, ou « Vacances d'été » lorsque vous êtes libre la plupart du temps, par exemple.",
      "STARTING_FROM": "À partir de",
      "NEVER_ENDING": "Sans fin",
      "YES_PATTERN_FOREVER": "Oui, laissez ce schéma continuer pour toujours",
      "NO_PATTERN_DATE": "Non, il y a une date à laquelle j'aimerais que ce schéma cesse",
      "STOP_DATE": "Date d'arrêt",
      "PATTERN_APPLIES": "Le modèle s'applique",
      "EVERY": "Chaque",
      "WEEK": "Semaine",
      "SAVING_BUTTON_TEXT": "Économiser...",
      "SAVE_BUTTON_TEXT": "Enregistrer",
      "CANCEL": "Annuler"
    },
    "WEEKLY_AVAILABILITY_MODAL_SINGLE": {
      "TITLE": "Ajouter une disponibilité ponctuelle",
      "SUBTITLE": "Pour une seule semaine",
      "WEEK_BEGINS": "La semaine commence",
      "HELP": "C'est la semaine pour laquelle vous souhaitez indiquer votre disponibilité.",
      "SAVING_BUTTON_TEXT": "Économiser...",
      "SAVE_BUTTON_TEXT": "Enregistrer",
      "CANCEL": "Annuler"
    },
    "AVAILABILITY": {
      "ADD_AVAILABILITY": "Ajouter une disponibilité",
      "NEW_WEEKLY_PATTERN": "Nouveau schéma hebdomadaire",
      "NEW_MONTHLY_PATTERN": "Nouveau schéma mensuel",
      "NEW_CUSTOM_PATTERN": "Nouveau motif personnalisé",
      "ONE_OFF_PATTERN": "Ponctuel (une seule semaine)",
      "AVAILABILITY": "Disponibilité",
      "AVAILABILITY_HELP": "Gérez la disponibilité ici à l'aide de modèles de disponibilité ; considérez-les comme des événements de calendrier récurrents.",
      "AVAILABILITY_TIP": "Utilisez un schéma mensuel pour cibler des dates telles que « le premier mercredi » ou « le dernier samedi » de chaque mois.",
      "NICKNAME": "Surnom",
      "APPLIES_FROM_TO": "S'applique de (/à)",
      "OPTIONS": "Options",
      "NO_AVAILABILITY_PATTERNS": "Il n'existe aucun modèle de disponibilité. Ajoutez-en un à l'aide des boutons ci-dessus.",
      "TURN_OFF": "Éteindre",
      "TURN_ON": "Allumer",
      "NO_NICKNAME": "Pas de surnom",
      "WEEKLY": "Hebdo",
      "MONTHLY": "Mensuel",
      "CUSTOM": "Personnalisé",
      "EDIT": "Modifier",
      "TOGGLE_DROPDOWN": "Basculer la liste déroulante",
      "DELETE": "Supprimer",
      "CONFIRM_DELETE_PATTERN": "Tu es sûr ? La suppression de ce modèle de disponibilité ne peut pas être annulée.",
      "DELETE_PATTERN_SUCCESS": "Succès ! Votre modèle de disponibilité a été supprimé.",
      "ERROR_DELETING_PATTERN": "Nous n'avons pas pu supprimer ce modèle pour l'instant. Veuillez réessayer.",
      "ERROR_TOGGLING_PATTERN": "Nous n'avons pas pu activer/désactiver ce schéma pour le moment. Veuillez réessayer.",
      "ERROR_LOADING_PATTERNS": "Nous n'avons pas pu charger vos modèles de disponibilité. Veuillez réessayer."
    },
    "DAILY_AVAILABILITY_MODAL": {
      "ADD_CUSTOM_AVAILABILITY": "Ajouter une disponibilité personnalisée",
      "EDIT_CUSTOM_AVAILABILITY": "Modifier la disponibilité personnalisée",
      "NICKNAME": "Surnom",
      "NICKNAME_PLACEHOLDER": "c'est à dire. Pendant les études",
      "NICKNAME_HELP": "Vous devriez donner à ce modèle un nom significatif.",
      "STARTING_FROM": "À partir de",
      "NEVER_ENDING": "Sans fin",
      "CONTINUE_FOREVER": "Oui, laissez ce schéma continuer pour toujours",
      "PATTERN_STOP": "Non, il y a une date à laquelle j'aimerais que ce schéma cesse",
      "STOP_DATE": "Date d'arrêt",
      "PATTERN_DAYS": "Journées des modèles",
      "PATTERN_NO_DAYS": "Ce modèle n'a pas de jours. Ajoutez-en un à l'aide du bouton ci-dessous.",
      "DAY_NUMBER": "Jour {{numéro}}",
      "ILL_BE": "Je serai",
      "DELETE": "Supprimer",
      "ADD_DAY": "Ajouter un jour",
      "SAVE": "Enregistrer",
      "SAVE_LOADING": "Économiser...",
      "CANCEL": "Annuler",
      "UNAVAILABLE": "Non disponible",
      "AVAILABLE": "Disponible",
      "AVAILABLE_AM": "Disponible (matin uniquement)",
      "AVAILABLE_PM": "Disponible (après-midi uniquement)",
      "OTHER": "Autre (précisez...)",
      "MINIMUM_TWO_DAYS": "Vous devez avoir suivi ce schéma pendant au moins deux jours.",
      "STOPS_BEFORE_STARTS": "Votre schéma est actuellement configuré pour s'arrêter avant de commencer !",
      "AVAILABILITY_END_BEFORE_START": "L'heure de fin d'une ou de plusieurs de vos règles de disponibilité est antérieure à l'heure de début.",
      "ERROR_SAVING_PATTERN": "Impossible actuellement d'enregistrer ce modèle de disponibilité. Veuillez réessayer.",
      "ERROR_UPDATING_PATTERN": "Impossible actuellement de mettre à jour ce modèle de disponibilité. Veuillez réessayer."
    },
    "EDIT_PATTERN_EXCEPTIONS_MODAL": {
      "EDIT_EXCEPTIONS": "Modifier les exceptions",
      "EXCEPTIONS_TIP": "Vos habitudes de disponibilité indiquent quand vous n'êtes pas libre de travailler. Vous pouvez toutefois utiliser votre calendrier pour nous indiquer que vous êtes libre de travailler un jour où vous ne pourriez pas travailler normalement. Cliquez simplement sur le marqueur rouge « indisponible » et vous pourrez faire une exception.",
      "EXCEPTIONS": "La liste ci-dessous indique les exceptions que vous avez apportées à votre modèle de disponibilité. Nous considérons que vous êtes libre de travailler ces jours-ci :",
      "DATE": "Date",
      "OPTIONS": "Options",
      "UNDO": "Annuler",
      "NO_EXCEPTIONS": "Vous n'avez encore fait aucune exception.",
      "CLOSE": "Fermer",
      "ERROR_DELETING_EXCEPTION": "Malheureusement, nous n'avons pas pu supprimer l'exception à ce moment-là. Veuillez réessayer."
    },
    "MONTHLY_AVAILABILITY_MODAL": {
      "ADD_MONTHLY_AVAILABILITY": "Ajouter une disponibilité mensuelle",
      "EDIT_MONTHLY_AVAILABILITY": "Modifier la disponibilité mensuelle",
      "NICKNAME": "Surnom",
      "NICKNAME_PLACEHOLDER": "c'est à dire. Pendant les études",
      "NICKNAME_HELP": "Vous devez donner à ce modèle de disponibilité un nom significatif, comme « Pendant vos études » si cela représente le moment où vous pouvez travailler tout en préparant des examens, ou « Vacances d'été » lorsque vous êtes libre la plupart du temps, par exemple.",
      "STARTING_FROM": "À partir de",
      "NEVER_ENDING": "Sans fin",
      "CONTINUE_FOREVER": "Oui, laissez ce schéma continuer pour toujours",
      "PATTERN_STOP": "Non, il y a une date à laquelle j'aimerais que ce schéma cesse",
      "STOP_DATE": "Date d'arrêt",
      "PATTERN_RULES": "Règles relatives aux modèles",
      "PATTERN_NO_RULES": "Ce modèle n'a pas de règles. Ajoutez-en un à l'aide du bouton ci-dessous.",
      "EVERY": "Chaque",
      "ON_THE": "sur le",
      "DELETE": "Supprimer",
      "ADD_RULE": "Ajouter une règle",
      "SAVE": "Enregistrer",
      "SAVE_LOADING": "Économiser...",
      "CANCEL": "Annuler",
      "ORDINAL_LAST": "dernier",
      "ORDINAL_FIRST": "premier",
      "ORDINAL_SECOND": "seconde",
      "ORDINAL_THIRD": "troisième",
      "ORDINAL_FOURTH": "quatrième",
      "ORDINAL_FIFTH": "cinquième",
      "MONDAY": "Lundi",
      "TUESDAY": "Mardi",
      "WEDNESDAY": "Mercredi",
      "THURSDAY": "Jeudi",
      "FRIDAY": "Vendredi",
      "SATURDAY": "samedi",
      "SUNDAY": "dimanche",
      "AVAILABLE": "Disponible",
      "AVAILABLE_AM": "AM uniquement",
      "AVAILABLE_PM": "PM uniquement",
      "MONTH": "mois",
      "MONTHS": "mois",
      "OTHER": "Autre (précisez...)",
      "STOPS_BEFORE_STARTS": "Votre schéma est actuellement configuré pour s'arrêter avant de commencer !",
      "AVAILABILITY_END_BEFORE_START": "L'heure de fin d'une ou de plusieurs de vos règles de disponibilité est antérieure à l'heure de début.",
      "ERROR_SAVING_PATTERN": "Impossible actuellement d'enregistrer ce modèle de disponibilité. Veuillez réessayer.",
      "ERROR_UPDATING_PATTERN": "Impossible actuellement de mettre à jour ce modèle de disponibilité. Veuillez réessayer."
    },
    "OVERVIEW": {
      "SCHEDULE": "Mon emploi du temps",
      "SCHEDULE_HELP": "Il s'agit de votre horaire complet, y compris les quarts de travail qui vous ont été assignés, les absences approuvées et les disponibilités.",
      "SHIFTS": "quarts de travail",
      "ABSENCE": "Absence",
      "UNAVAILABLE": "Non disponible (modèles)",
      "EDIT_EXCEPTIONS": "Modifier les exceptions",
      "ICAL_FEED": "Fil iCalendar",
      "ICAL_FEED_HELP": "Un flux iCalendar vous permettra de consulter votre emploi du temps en dehors de Rotaready.",
      "ICAL_FEED_TIP": "Une fois que vous avez généré l'URL d'un flux iCalendar, vous pouvez le copier et le coller dans <a href=\"https://support.apple.com/en-gb/HT202361\" target=\"_blank\">Apple Calendar, <a href=\"https://support.google.com/calendar/answer/37100\" target=\"_blank\">Google Calendar</a></a> ou tout autre logiciel de calendrier.",
      "COPIED": "Copié dans le presse-papiers !",
      "GENERATE": "Générez"
    }
  },
  "ATTENDANCE": {
    "VALIDATION_CODES": {
      "CODE_1": "La date correspondante a été validée, nous ne pouvons donc y apporter aucune modification.",
      "CODE_2": "Cela entraînerait une séquence d'événements non valide. Par exemple, un chronométtage doit intervenir après un chronométtage, mais ne peut pas survenir avant un autre chronométtage. Vérifiez les événements environnants, puis réessayez.",
      "CODE_3": "Le site/département choisi n'est pas valide.",
      "CODE_4": "Nous ne pouvons pas ajouter d'heure ici car l'employé choisi n'a pas de rendez-vous à cette date.",
      "CODE_5": "Nous ne pouvons pas ajouter d'événement ici car il existe déjà un événement en même temps.",
      "CODE_6": "L'employé sélectionné n'est pas valide.",
      "CODE_7": "Cette modification replacerait l'événement à une date antérieure à l'événement qui le précède. Essayez de choisir une autre heure ou modifiez d'abord l'événement précédent.",
      "CODE_8": "Cette modification replacerait l'événement à une date ultérieure à l'événement qui le suit. Essayez de choisir une autre heure ou modifiez d'abord l'événement suivant."
    },
    "COMMON": {
      "EVENT_ADD_SUCCESS": "L'événement a été ajouté avec succès.",
      "ABSENCE_ADD_SUCCESS": "Ils ont été marqués comme absents.",
      "NO_CLOCK_IN_MODAL_TITLE": "Ajouter un événement",
      "NO_CLOCK_IN_MODAL_MESSAGE": "Qu'est-ce que tu aimerais faire ?",
      "NO_CLOCK_IN_MODAL_ADD_EVENT": "Ajouter une horloge",
      "NO_CLOCK_IN_MODAL_ADD_EVENT_DESC": "Ajouter manuellement un enregistrement de présence",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE": "Marque absente",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC": "Ajouter une fiche d'absence, telle qu'une maladie",
      "UNMATCHED_MODAL_TITLE": "Une fréquentation inégalée",
      "UNMATCHED_MODAL_MESSAGE": "Vous pouvez résoudre le problème de l'absentéisme de deux manières :",
      "UNMATCHED_MODAL_ADD_SHIFT": "Ajouter un quart de travail",
      "UNMATCHED_MODAL_ADD_SHIFT_DESC": "Créez un nouveau quart de travail pour refléter ce qui a été travaillé",
      "UNMATCHED_MODAL_EDIT_EVENT": "Modifier les présences",
      "UNMATCHED_MODAL_EDIT_EVENT_DESC": "Ajustez les relevés de présence s'ils ne sont pas corrects",
      "OVERTIME_MODAL_TITLE": "Réviser les heures supplémentaires",
      "OVERTIME_MODAL_MESSAGE_IN": "Le poste de travail de {{name}} est arrivé plus tôt que prévu et le quart de travail correspond à une règle salariale. Qu'est-ce que tu aimerais faire ?",
      "OVERTIME_MODAL_MESSAGE_OUT": "{{name}} s'est terminée en retard et le quart de travail a respecté une règle salariale. Qu'est-ce que tu aimerais faire ?",
      "OVERTIME_MODAL_APPROVE": "Approuver les heures supplémentaires",
      "OVERTIME_MODAL_APPROVE_DESC": "Les heures supplémentaires de {{duration}} seront payées pour {{duration}} minutes supplémentaires",
      "OVERTIME_MODAL_REJECT": "Rejeter les heures supplémentaires",
      "OVERTIME_MODAL_REJECT_DESC": "Les heures supplémentaires effectuées ne seront pas payées pour les heures supplémentaires effectuées par {{name}}",
      "OVERTIME_UPDATE_ERROR": "Nous ne pouvons pas mettre à jour l'événement pour le moment. Veuillez réessayer.",
      "MODAL_USER_NOTES_LABEL": 'Notes from {{name}}'
    },
    "BY_STAFF": {
      "FILTER_LABEL_DATE": "Date",
      "MANUAL_CLOCK_IN": "Déclenchement manuel",
      "EVENT_TYPE": "Événement",
      "RECORDED_TIME": "Heure enregistrée",
      "METHOD": "Capturé par",
      "OPTIONS": "Options",
      "TRUSTED_DEVICE": "Appareil fiable",
      "MOBILE_APP": "Application Rotaready",
      "MANUALLY_OVERRIDDEN": "Remplacée manuellement",
      "MANUAL_ENTRY": "Saisie manuelle",
      "DELETE": "Supprimer",
      "NO_EVENTS": "Il n'y a aucun événement de présence à afficher.",
      "CLOCK_IN": "Horloge",
      "CLOCK_OUT": "Horloge éteinte",
      "BREAK_ON": "En pause",
      "BREAK_OFF": "Pause",
      "ERROR_LOADING_EVENTS": "Nous ne pouvons pas charger le nombre de participants pour le moment. Réessayez, s'il vous plaît.",
      "ERROR_DELETING": "Nous ne pouvons pas supprimer la participation pour le moment. Veuillez réessayer.",
      "DELETE_SUCCESS": "L'événement a été supprimé avec succès.",
      "ADD_SUCCESS": "L'événement a été ajouté avec succès.",
      "LABEL_EDITED": "Edité"
    },
    "ISSUES": {
      "TITLE": "Problèmes de présence",
      "FILTER_DATE": "Date",
      "FILTER_ISSUE_TYPE": "Type de problème",
      "FILTER_POSITION": "Rôle du poste",
      "FILTER_USER": "Personne",
      "ISSUES_HELP": "Les problèmes doivent être résolus, sinon ils entraîneront des divergences dans les rapports et les salaires. Par défaut, Rotaready signale les problèmes survenus au cours des derniers {{defaultIssuePeriod}} jours.",
      "IS_THIS": "Est-ce que c'est",
      "YES": "Oui",
      "NO": "Non",
      "HEADING_NAME": "Nom",
      "HEADING_SITE": "Site",
      "HEADING_ISSUE": "Problème",
      "HEADING_SHIFT": "quart de travail",
      "HEADING_START": "Démarrer",
      "HEADING_FINISH": "Terminer",
      "HEADING_CLOCK_IN": "Déclencher",
      "HEADING_CLOCK_OUT": "Horloge",
      "HEADING_OPTIONS": "Options",
      "NOT_FOUND": "Non trouvé",
      "RESOLVE": "Résoudre",
      "DISMISS": "Rejeter",
      "NO_ATTENDANCE_ISSUES": "Vous n'avez aucun problème de présence, bravo !",
      "UNMATCHED_ATTENDANCE": "Une fréquentation inégalée",
      "NO_CLOCK_IN": "Pas de chronométtage",
      "NO_CLOCK_OUT": "Pas de chronométeur",
      "SUSPECT_CLOCK_OUT": "Horloge du suspect",
      "SUSPECT_ATTENDANCE": "Présence du suspect",
      "BREAK_NOT_TAKEN": "Pause non prise",
      "INVALID_BREAKS": "Pause (s) non valide (s)",
      "SUSPECT_BREAK": "Pause suspecte",
      "EARLY_CLOCK_IN": "Arrivez tôt",
      "LATE_CLOCK_OUT": "Horloge tardive",
      "UNMATCHED_ATTENDANCE_EXPLANATION": "Rotaready n'a pas trouvé de changement correspondant à cette fréquentation",
      "NO_CLOCK_IN_EXPLANATION": "Le membre du personnel ne s'est pas présenté pour son quart de travail",
      "NO_CLOCK_OUT_EXPLANATION": "Le membre du personnel n'a pas quitté son poste de travail après son quart de travail",
      "SUSPECT_CLOCK_OUT_EXPLANATION": "Le membre du personnel est parti bien plus tard que l'heure d'arrivée prévue",
      "SUSPECT_ATTENDANCE_EXPLANATION": "Le membre du personnel a été enregistré pour une durée beaucoup plus courte que prévu",
      "BREAK_NOT_TAKEN_EXPLANATION": "Le quart de travail comporte une pause mais aucune n'a été prise",
      "INVALID_BREAKS_EXPLANATION": "La ou les pauses relatives à cette participation sont incomplètes (c'est-à-dire qu'il n'y a pas eu de pause)",
      "SUSPECT_BREAK_EXPLANATION": "Le membre du personnel a été en pause beaucoup plus longtemps que prévu",
      "EARLY_CLOCK_IN_EXPLANATION": "Le quart de travail correspond à une règle salariale qui nécessite une approbation pour une arrivée anticipée",
      "LATE_CLOCK_OUT_EXPLANATION": "Le quart de travail correspond à une règle salariale qui nécessite une approbation en cas de départ tardif",
      "ERROR_LOADING_ATTENDANCE": "Nous ne pouvons pas charger vos problèmes de présence pour le moment. Veuillez réessayer.",
      "SOMETHING_WENT_WRONG": "Oups, quelque chose vient de mal tourner. Il se peut que vous deviez réessayer !",
      "CONFIRM_DISMISS": "Êtes-vous sûr de vouloir ignorer ce problème de présence ?",
      "DATE_RANGE_TOO_WIDE": "Vous ne pouvez pas choisir plus de 5 semaines. Veuillez limiter votre plage de dates.",
      "CLOCK_OUT_DAYS_LATER": "Ce déverrouillage a lieu un ou plusieurs jours après l'arrivée."
    },
    "OVERVIEW": {
      "UNMATCHED_LABEL": "{{count}} incessant",
      "OPTIONS_DAILY": "Tous les jours",
      "OPTIONS_WEEKLY": "Hebdo",
      "OPTIONS_DAY_ROLL_OVER": "Reconduction de la journée",
      "FILTER_POSITION": "Rôle du poste",
      "FILTER_USER": "Personne",
      "MIDNIGHT": "Minuit",
      "TOOLS": "Outils",
      "CURRENTLY_CLOCKED_IN": "Personnes actuellement inscrites",
      "ATTENDANCE_HELP": "Les présences sont automatiquement mises en correspondance avec les quarts de travail si un chronométrage ou un chronométrage a lieu dans les heures qui suivent l'heure de début ou de fin prévue dans la <b>{{AutomatchThreshold}} heure (s</b>).",
      "MATCHED_HEADING_SCHEDULED": "Planifié",
      "MATCHED_HEADING_ACTUAL": "Réel",
      "MATCHED_HEADING_AFTER_SANITISATION": "Après la désinfection",
      "MATCHED_HEADING_NAME": "Nom",
      "MATCHED_HEADING_SITE": "Site",
      "MATCHED_HEADING_START": "Démarrer",
      "MATCHED_HEADING_FINISH": "Terminer",
      "MATCHED_HEADING_PAID_HOURS": "Heures rémunérées",
      "MATCHED_HEADING_UNPAID_HOURS": "Heures non rémunérées",
      "COVER_SHIFT": "Changement de couverture",
      "NO_CLOCK_IN": "Pas encore d'enregistrement",
      "NO_CLOCK_OUT": "Pas encore de chronométeur",
      "OVERTIME_APPROVED": "Approuvé",
      "OVERTIME_REJECTED": "Rejeté",
      "OVERTIME_REVIEW": "Révision",
      "OVERTIME_APPROVED_BY": "Heures supplémentaires approuvées par {{name}}",
      "OVERTIME_REJECTED_BY": "Heures supplémentaires rejetées par {{name}}",
      "MATCHED_NO_RESULTS": "Il n'y a aucun changement à afficher.",
      "UNMATCHED_ATTENDANCE": "Une fréquentation inégalée",
      "UNMATCHED_ATTENDANCE_HELP": "Si les présences ne se situent pas dans les heures <b>{{AutomatchThreshold}} qui suivent l'heure</b> de début/de fin d'un quart de travail, vous devrez ajuster manuellement le quart de travail ou les relevés de présence pour les aligner :",
      "UNMATCHED_HEADING_NAME": "Nom",
      "UNMATCHED_HEADING_SITE": "Site",
      "UNMATCHED_HEADING_DATE": "Date",
      "UNMATCHED_HEADING_CLOCK_IN": "Horloge",
      "UNMATCHED_HEADING_CLOCK_OUT": "Horloge éteinte",
      "UNMATCHED_HEADING_OPTIONS": "Options",
      "NO_UNMATCHED_ATTENDANCE": "Il n'y a pas d'affluence inégalée ici. Bon travail !",
      "NONE_YET": "Aucune pour l'instant",
      "RESOLVE": "Résoudre",
      "ERROR_LOADING_ATTENDANCE": "Nous ne pouvons pas charger l'historique de vos présences pour le moment. Veuillez réessayer.",
      "TIMELINESS_EARLY_TOOLTIP": "{{duration}} minutes plus tôt",
      "TIMELINESS_LATE_TOOLTIP": "{{duration}} minutes de retard",
      "TRUNCATED_RESULT_MESSAGE": "Il y a encore beaucoup à voir, mais pour que tout fonctionne bien, nous avons dû tronquer votre résultat. <br />Essayez de filtrer par poste, par site/département ou réduisez la plage de dates."
    },
    "VIEW_EVENT_MODAL": {
      "VIEW_ATTENDANCE": "Afficher les présences",
      "STAFF_MEMBER": "Qui",
      "EVENT_TYPE": "Événement",
      "RECORDED_TIME": "Heure enregistrée",
      "METHOD": "Capturé par",
      "TRUSTED_DEVICE": "Appareil fiable",
      "MOBILE_APP": "Application Rotaready",
      "MANUALLY_OVERRIDDEN": "Remplacé manuellement",
      "MANUAL_ENTRY": "Saisie manuelle",
      "ON": "sur",
      "OVERTIME": "Heures supplémentaires",
      "APPROVED": "Approuvé",
      "REJECTED": "Rejeté",
      "FACIAL_RECOGNITION": "Reconnaissance faciale",
      "FACIAL_RECOGNITION_PROCESS": "Les images sont généralement traitées quelques minutes après leur capture",
      "FACIAL_RECOGNITION_WAITING": "En attente de traitement...",
      "TRUSTS_PHOTO": "Rotaready fait confiance à cette photo",
      "NO_TRUSTS_PHOTO": "Rotaready ne fait pas confiance à cette photo",
      "CONFIDENT": "confiant",
      "UNABLE_TO_IDENTIFY": "Impossible d'identifier clairement un visage",
      "PROCESSED_ON": "Traité le",
      "ACKNOWLEDGED_BY": "Reconnu par {{name}} le",
      "REFERENCE_IMAGE": "Image de référence",
      "REFERENCE_IMAGE_HELP": "Cette image ne sera pas traitée, mais utilisée comme référence pour la comparaison",
      "EDIT": "Modifier",
      "CLOSE": "Fermer",
      "TAB_DETAILS": "Détails",
      "TAB_PHOTO": "Photo",
      "TAB_LOCATION": "Lieu",
      "CLOCK_IN": "Horloge",
      "CLOCK_OUT": "Horloge éteinte",
      "BREAK_ON": "Faites une pause",
      "BREAK_OFF": "Pause",
      "HAPPENED_HERE": "s'est passé ici",
      "TAB_AUDIT": "L'histoire",
      "AUDIT_TABLE_TIME": "Temps de présence",
      "AUDIT_TABLE_OVERTIME": "Heures supplémentaires",
      "AUDIT_TABLE_WHO": "Enregistré par",
      "NOTES": 'Notes'
    },
    "EDIT_EVENT_MODAL": {
      "TITLE": "Modifier les présences",
      "CLOCK_IN": "Horloge",
      "CLOCK_OUT": "Horloge éteinte",
      "BREAK_ON": "Faites une pause",
      "BREAK_OFF": "Pause",
      "ERROR_UPDATING": "Nous ne pouvons pas mettre à jour l'événement pour le moment. Veuillez réessayer.",
      "ERROR_DELETING": "Nous ne pouvons pas supprimer l'événement pour le moment. Veuillez réessayer.",
      "CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer cet événement ?\nS'il s'agit d'un cadk-in, le clock-out correspondant sera également supprimé.",
      "PRIOR_EVENT_INFO": "L'événement précédent est un <b>{{EventType}} {{<b>Date|MomentFormat : 'll - LT'</b>}}</b>",
      "PRIOR_EVENT_CONFLICT": "Il existe déjà un <b>{{EventType}} {{<b>Date|MomentFormat : 'll - LT'</b>}}</b>. Vous devrez sélectionner une autre heure.",
      "LABEL_WHO": "Qui",
      "LABEL_EVENT": "Événement",
      "LABEL_DATE": "Date et heure",
      "DATE_IN_FUTURE": "Ce temps n'est pas encore écoulé !",
      "LABEL_OVERTIME": "Heures supplémentaires",
      "APPROVE": "Approuver",
      "REJECT": "Refuser",
      "LABEL_OVERTIME_HELP": "Rotaready a identifié quelques heures supplémentaires en faisant correspondre cet événement de participation à un quart de travail. Si vous l'approuvez, les heures supplémentaires seront payées conformément à la règle de rémunération du quart de travail.",
      "DELETE": "Supprimer",
      "CANCEL": "Annuler",
      "SAVE": "Enregistrer les modifications"
    },
    "ADD_EVENT_MODAL": {
      "TITLE": "Ajouter une présence",
      "CLOCK_IN": "Horloge",
      "CLOCK_OUT": "Horloge éteinte",
      "BREAK_ON": "Faites une pause",
      "BREAK_OFF": "Pause",
      "ERROR_ACTIONING": "Nous ne pouvons pas ajouter l'événement pour le moment. Veuillez réessayer.",
      "PRIOR_EVENT_INFO": "L'événement précédent est un <b>{{EventType}} {{<b>Date|MomentFormat : 'll - LT'</b>}}</b>",
      "PRIOR_EVENT_CONFLICT": "Il existe déjà un <b>{{EventType}} {{<b>Date|MomentFormat : 'll - LT'</b>}}</b>. Vous devrez sélectionner une autre heure.",
      "LABEL_EVENT": "Événement",
      "LABEL_DATE": "Date et heure",
      "LABEL_BREAK": "Durée de la pause",
      "LABEL_BREAK_HELP": "Une pause sera ajoutée pour vous après la durée choisie.",
      "DATE_IN_FUTURE": "Ce temps n'est pas encore écoulé !",
      "CANCEL": "Annuler",
      "SAVE": "Ajouter une présence",
      "BREAK_MINUTES": "minutes"
    }
  },
  "DIALOG": {
    "TEXTBOX_DIALOG": {
      "CANCEL": "Annuler"
    },
    "TWO_BUTTON_DIALOG": {
      "CANCEL": "Annuler"
    }
  },
  "OTHER": {
    "EDIT_PREFERENCES_MODAL": {
      "TITLE": "Mettre à jour les préférences",
      "SUBTITLE": "Informations de contact",
      "SUBTITLE_HELP": "Si vous oubliez votre mot de passe, nous ne pouvons vous aider à le réinitialiser que s'il existe d'autres moyens de vous contacter",
      "EMAIL_LABEL": "Adresse e-mail",
      "EMAIL_PLACEHOLDER": "Aucun fourni",
      "EMAIL_HELP": "Veuillez fournir une adresse e-mail valide.",
      "MOBILE_LABEL": "téléphone portable",
      "MOBILE_PLACEHOLDER": "Aucun fourni",
      "MOBILE_HELP": "Veuillez fournir un numéro de téléphone portable valide, ou pas du tout.",
      "ACCOUNT_INFO": "Informations sur le compte",
      "ACCOUNT_INFO_HELP": "Ici, vous pouvez définir votre langue",
      "LANGUAGE_LABEL": "Langue",
      "LANGUAGE_HELP": "Rotaready s'affichera dans la langue de votre choix.",
      "OTHER_SETTINGS_TITLE": "Autres paramètres",
      "CLOCK_IN_LABEL": "Code PIN d'enregistrement",
      "CLOCK_IN_HELP": "Si vous devez saisir un code PIN lors de l'entrée/de la sortie, vous pouvez en saisir un nouveau ici. Il doit comporter 4 chiffres.",
      "NOTIFICATIONS_TITLE": "Notifications",
      "NOTIFICATIONS_HELP": "Contrôlez la façon dont vous souhaitez être informé des événements auxquels vous êtes abonné ",
      "MANAGE_SUBSCRIPTIONS": "gérer les abonnements",
      "ROTA_UPDATES": "Mises à jour de la rotation",
      "CRITIAL_SHIFT": "Mises à jour des équipes critiques",
      "EMAIL": " Courrier électronique",
      "MOBILE_APP": " Application mobile",
      "SHIFT_UPDATED": "Lorsqu'un quart de travail que vous allez travailler dans les prochaines 24 heures a été mis à jour",
      "REGULAR_SHIFT": "Mises à jour régulières des équipes",
      "SHIFT_UPDATED_BEYOND": "Mises à jour pour tous les quarts de travail auxquels vous travaillerez au-delà des prochaines 24 heures",
      "WEEKLY_SUMMARY": "Résumé hebdomadaire",
      "SMS": " SMS",
      "UPCOMING_WEEK": "Un résumé de vos quarts de travail pour la semaine à venir, envoyé la veille du début de la semaine",
      "APPROVAL": "Approbation",
      "APPROVAL_HELP": "Lorsqu'une rotation est marquée comme prête à être approuvée",
      "PUBLISHING": "Publication",
      "PUBLISHING_HELP": "Lorsqu'une rotation est publiée ou non publiée",
      "SHIFT_SWAPS": "Swaps d'équipes",
      "SWAP_UPDATES": "Mises à jour des ",
      "REQUESTED": "Demandé",
      "REQUESTED_HELP": "Quand quelqu'un demande un nouvel échange d'équipes",
      "ACCEPTED": "Accepté",
      "ACCEPTED_HELP": "Quand quelqu'un accepte un échange d'équipes",
      "APPROVED": "Approuvé",
      "APPROVED_HELP": "Lorsqu'un échange d'équipes est approuvé par la direction",
      "REJECTED": "Rejeté",
      "REJECTED_HELP": "Lorsqu'un échange de quarts de travail est rejeté par la direction",
      "CANCELLED": "Annulé",
      "CANCELLED_HELP": "Lorsqu'un échange de quarts de travail est annulé",
      "ABSENCE_UPDATES": "Mises à jour sur les absences et les vacances",
      "ABSENCE_REQUESTED": "Absence demandée",
      "ABSENCE_REQUESTED_HELP": "Lorsqu'une nouvelle absence est demandée",
      "ABSENCE_UPDATE": "Actualisation des absences",
      "ABSENCE_UPDATE_HELP": " Lorsqu'une demande d'absence est approuvée, rejetée ou annulée par la direction",
      "COST_CONTROL": "Contrôle des coûts",
      "SIGN_OFF": "Déclencher",
      "SIGN_OFF_HELP": "Quand un site ferme ses activités une semaine",
      "SIGN_OFF_REVERSE": "Déconnexion inversée",
      "SIGN_OFF_REVERSE_HELP": "Quand un site annule la signature pendant une semaine",
      "ADMIN": "Administrateur",
      "ACCOUNT_APPROVAL": "Approbation du compte",
      "ACCOUNT_APPROVAL_HELP": "Lorsqu'un compte est en attente d'approbation ou a été approuvé",
      "DOCUMENTS": "Documents",
      "DOCUMENTS_HELP": "Lorsqu'un document a expiré ou va bientôt expirer",
      "OTHER": "Autres",
      "COMPANY_ANNOUNCEMENTS": "Annonces de l'entreprise",
      "COMPANY_HELP": "Annonces importantes de la part de votre employeur",
      "DAILY_ADMIN_UPDATE": "Mise à jour quotidienne de l'administration",
      "DAILY_UPDATE_HELP": "Nous vous informerons si vous rencontrez des problèmes de présence, ou si des demandes d'absence ou d'échange d'équipes sont en attente d'approbation.",
      "PHONE_ONLY": "Les notifications comportant cette icône ne sont disponibles qu'avec les applications Rotaready pour iPhone et Android.",
      "CHANGE_PASSWORD": "Changer le mot de passe",
      "SAVE": "Enregistrer",
      "SAVING": "Économiser...",
      "CANCEL": "Annuler"
    },
    "MANAGE_SUBSCRIPTIONS_MODAL": {
      "TITLE": "Gérer les abonnements",
      "HELP": "Vos abonnements contrôlent les sujets pour lesquels vous souhaitez recevoir des notifications.",
      "UNSUBSCRIBE_ALL": "Désabonnez-vous de tous",
      "NOTIFY_WHEN": "Prévenez-moi quand {{descriptor}}",
      "UNSUBSCRIBE_TOOLTIP": "Désabonnement",
      "UNSUBSCRIBE_ALL_TOOLTIP": "Désabonnez-vous de tous",
      "ACTION_IN": "{{action}} dans ",
      "FILTER_NO_SUBSCRIPTIONS": "Vous n'avez aucun abonnement actif pour {{name}}.",
      "ALL_NO_SUBSCRIPTIONS": "Vous n'avez aucun abonnement actif.",
      "CLOSE": "Fermer"
    },
    "RELEASE_REFRESH_MODAL": {
      "TITLE": "Mise à jour Rotaready",
      "BODY": "Nous venons de publier une mise à jour de Rotaready qui vous oblige à recharger la page. Cliquez sur le bouton ci-dessous.",
      "REFRESH": "Actualiser la page"
    },
    "EDIT_AVATAR_MODAL": {
      "TITLE": "Photo de profil",
      "INTRO_COPY": "Une photo permet aux gens de vous reconnaître et vous indique quand vous êtes connecté à votre compte.",
      "BUTTON_CHANGE": "Téléchargez une nouvelle photo",
      "BUTTON_CANCEL": "Annuler",
      "BUTTON_SAVE": "Enregistrer",
      "ALERT_REMOVE": "Êtes-vous sûr de vouloir supprimer cette photo de profil ?",
      "ALERT_FILE_TYPE_NOT_ALLOWED": "Ce type de fichier n'est pas autorisé.",
      "ALERT_ERROR_500": "Nous n'avons pas pu mettre à jour votre photo à ce moment-là. Réessayez, s'il vous plaît.",
      "ALERT_SUCCESS_ADD": "Votre photo de profil a été mise à jour. La mise à jour de tous les services de Rotaready peut prendre un certain temps.",
      "ALERT_SUCCESS_REMOVE": "Votre photo de profil a été supprimée. La mise à jour de tous les services de Rotaready peut prendre un certain temps."
    }
  },
  "REPORTS": {
    "INDEX_LIST": {
      "TITLE": "Rapports",
      "TRONC_STATUS": "État du tronc",
      "CURRENTLY_CLOCKED_IN": "Personnes actuellement inscrites",
      "ROTA_PROGRESS": "Progression de la rotation",
      "SIGN_OFF_PROGRESS": "Progression de la signature",
      "PAYROLL_EXPORT": "Exportation des salaires",
      "LABOUR_BREAKDOWN": "Répartition de la main-d'œuvre",
      "WORKING_TIME_VIOLATIONS": "Violations relatives au temps de travail",
      "CONTRACTUAL_OBLIGATION_SUMMARY": "Résumé des obligations contractuelles",
      "DOCUMENT_SIGNATURES": "Signature de documents",
      "EXPIRING_DOCUMENTS": "Documents expirant",
      "EMPLOYEE_EXPORT": "Informations sur les employés",
      "JOINERS_LEAVERS": "Menuisiers et sortants",
      "STAFF_TURNOVER": "Rotation du personnel",
      "ANNIVERSARY": "Anniversaires",
      "EMPLOYMENT_CHANGES": "Évolution de l'emploi",
      "CATEGORY_HR": "HR",
      "CATEGORY_DOCUMENTS": "Documents",
      "CATEGORY_ROTAS": "Planification",
      "CATEGORY_PAYROLL": "Paie",
      "CATEGORY_ATTENDANCE": "Présence",
      "CATEGORY_ABSENCE": "Absence",
      "CATEGORY_COST_CONTROL": "Contrôle des coûts",
      "ABSENCE_EXPORT": "Exportation des absences",
      "ALLOWANCES_EXPORT": "Exportation de quotas",
      "UNTAKEN_HOLIDAY_COST": "Frais de vacances non pris",
      "COST_CONTROL_RAW_DATA": "Exportation de données brutes",
      "NEW_HIRE_TURNOVER": "Rotation des nouvelles recrues",
      "ABSENCE_DAYS_EXPORT": "Exportation des jours d'absence",
      "SHIFT_TYPE_STREAM_BREAKDOWN": "Type de quart de travail et répartition des flux",
      "XERO_EXPORT": "Exporter vers Xero",
      "DOCUMENT_PRESENCE": "Présence du document",
      "DOCUMENTS_EXPORT": "Exportation de documents",
      "ATTENDANCE_BREAKDOWN": "Répartition des présences",
      "LATENESS": "Retard",
      "LABOUR_BREAKDOWN_FILE_EXPORT": "Exportation du fichier de répartition de la main-d'œuvre",
      "CATEGORY_ASYNC_FILE_EXPORTS": "Grands rapports",
      "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": "Exportation du fichier de répartition du travail",
      "PAYROLL_ASYNC_FILE_EXPORT": "Exportation du fichier de paie",
      "RAW_COST_CONTROL_ASYNC_FILE_EXPORT": "Exportation de fichiers de contrôle des coûts bruts"
    },
    "LATENESS": {
      "TITLE": "Retard",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE_APPOINTMENT": "Site (rendez-vous)",
      "COLUMN_DEPT_APPOINTMENT": "Département (rendez-vous)",
      "COLUMN_INSTANCES": "Instances",
      "COLUMN_VARIANCE": "Écart moyen",
      "FILTER_DATE": "Date",
      "FILTER_SITE": "Site/département",
      "FILTER_USER": "Personne",
      "FILTER_OVERTIME": "Heures supplémentaires",
      "FILTER_OVERTIME_NA": "Non reconnu",
      "FILTER_OVERTIME_APPROVED": "Approuvé",
      "FILTER_OVERTIME_REJECTED": "Rejeté",
      "FILTER_CLOCK_IN": "Déclencher",
      "FILTER_CLOCK_OUT": "Horloge",
      "FILTER_MINS_LATE": "5 minutes de retard",
      "FILTER_MINS_EARLY": "5 minutes plus tôt",
      "LABEL_MINUTES": "minutes",
      "BUTTON_EXPORT": "Exporter",
      "NO_MATCH": "Nous n'avons trouvé aucune donnée correspondant à vos filtres."
    },
    "ATTENDANCE_BREAKDOWN": {
      "TITLE": "Répartition des présences",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE_APPOINTMENT": "Site (rendez-vous)",
      "COLUMN_DEPT_APPOINTMENT": "Département (rendez-vous)",
      "COLUMN_SITE_ATTRIBUTION": "Site (attribution)",
      "COLUMN_DEPT_ATTRIBUTION": "Département (attribution)",
      "COLUMN_DATE": "Date d'ouverture",
      "COLUMN_SHIFT_TYPE": "Type de quart de travail",
      "COLUMN_RECORD_TYPE": "Type d'enregistrement",
      "COLUMN_SCHEDULED": "Planifié",
      "COLUMN_ACTUAL": "Réel",
      "COLUMN_VARIANCE": "Écart",
      "COLUMN_CAPTURED_BY": "Capturé par",
      "COLUMN_EDITED_BY": "Edité par",
      "COLUMN_EDITED_DATE": "Date modifiée",
      "COLUMN_OVERTIME_STATE": "Approbation des heures supplémentaires",
      "COLUMN_OVERTIME_EDITED_BY": "Heures supplémentaires confirmées par",
      "COLUMN_OVERTIME_EDITED_DATE": "Date confirmée des heures supplémentaires",
      "FILTER_DATE": "Date",
      "FILTER_SITE": "Site/département",
      "FILTER_USER": "Personne",
      "FILTER_OVERTIME": "Heures supplémentaires",
      "FILTER_OVERTIME_NA": "Non reconnu",
      "FILTER_OVERTIME_APPROVED": "Approuvé",
      "FILTER_OVERTIME_REJECTED": "Rejeté",
      "FILTER_CLOCK_IN": "Déclencher",
      "FILTER_CLOCK_OUT": "Horloge",
      "FILTER_MINS_LATE": "5 minutes de retard",
      "FILTER_MINS_EARLY": "5 minutes plus tôt",
      "BUTTON_EXPORT": "Exporter",
      "NO_MATCH": "Nous n'avons trouvé aucune donnée correspondant à vos filtres."
    },
    "DOCUMENTS_EXPORT": {
      "TITLE": "Exportation de documents",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "FILTER_SITE": "Site/département",
      "FILTER_TOGGLE_USER_ACTIVE": "Employés actifs",
      "FILTER_USER": "Personne",
      "FILTER_CATEGORY": "Catégorie",
      "FILTER_ACKNOWLEDGEMENT": "État",
      "FILTER_OPTION_ACKNOWLEDGED": "Reconnu",
      "FILTER_OPTION_NOT_ACKNOWLEDGED": "Non reconnu",
      "BUTTON_EXPORT": "Exporter",
      "COLUMN_USER_ID": "Identifiant de l'employé",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Département",
      "COLUMN_CATEGORY": "Catégorie",
      "COLUMN_FILE_NAME": "Nom",
      "COLUMN_FILE_SIZE": "Taille du fichier (Ko)",
      "COLUMN_UPLOADED_BY": "Téléchargé par",
      "COLUMN_UPLOADED_DATE": "Date de téléchargement",
      "COLUMN_EXPIRY_DATE": "Date d'expiration",
      "COLUMN_SIGNATURE_METHOD": "Méthode de signature",
      "COLUMN_EDITED_BY": "Modifié pour la dernière fois par",
      "COLUMN_EDITED_DATE": "Date de dernière modification",
      "COLUMN_ACKNOWLEDGED": "Reconnu",
      "COLUMN_ACKNOWLEDGED_DATE": "Date reconnue",
      "COLUMN_NOTES": "Remarques",
      "NO_MATCH": "Nous n'avons trouvé aucun employé pour vos filtres."
    },
    "DOCUMENT_PRESENCE": {
      "TITLE": "Présence du document",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "FILTER_SITE": "Site/département",
      "FILTER_TOGGLE_USER_ACTIVE": "Employés actifs",
      "FILTER_CATEGORY": "Catégorie",
      "BUTTON_EXPORT": "Exporter",
      "COLUMN_USER_ID": "Identifiant de l'employé",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Département",
      "COLUMN_EMPLOYMENT_ACTIVE": "L'emploi actif",
      "COLUMN_JOIN_DATE": "Date d'adhésion",
      "COLUMN_LEAVE_DATE": "Date de départ",
      "COLUMN_DOCUMENTS_VALIDITY": "Documents valides",
      "NO_MATCH": "Nous n'avons trouvé aucun employé pour vos filtres."
    },
    "SHIFT_TYPE_STREAM_BREAKDOWN": {
      "TITLE": "Répartition des types de postes",
      "FILTER_DATE": "Date",
      "JUMP_TO": "Passez à",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_LAST_TWO_WEEKS": "Les deux dernières semaines",
      "JUMP_TO_LAST_MONTH": "Le mois dernier",
      "FILTER_SITE": "Site/département",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "FILTER_DATA_SOURCE": "Source de données",
      "FILTER_USER": "Personne",
      "FILTER_BEHAVIOUR": "Comportement",
      "BEHAVIOUR_SHIFT_TYPE": "Type de quart de travail",
      "BEHAVIOUR_STREAM": "Diffusez",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE": "Site actuel",
      "COLUMN_DEPARTMENT": "Département actuel",
      "COLUMN_STAFF_GROUP": "Groupe de personnel actuel",
      "COLUMN_TOTAL": "Total",
      "ROW_TOTAL": "Total",
      "INFO_NO_DATA": "Aucun enregistrement ne correspond à vos filtres.",
      "INFO_NO_FILTER": "Sélectionnez un site/département ci-dessus pour commencer.",
      "PAY_AMOUNT_TYPE_HOURLY": "Taux horaire",
      "PAY_AMOUNT_TYPE_DAILY": "Tarif journalier",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salaire annuel",
      "DATA_SOURCE_SHIFTS": "quarts de travail",
      "DATA_SOURCE_MATCHED": "Assaini",
      "DATE_ERROR_DIFF": "Vous ne pouvez pas choisir plus de 6 semaines. Veuillez limiter votre plage de dates.",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "ERROR_400": "Vos paramètres génèrent trop de données pour un seul rapport. Réduisez la portée de votre demande et réessayez.",
      "STREAM_UNALLOCATED": "Non alloué",
      "FILTER_METRIC": "Valeur",
      "METRIC_WAGES": "Coût salarial de base",
      "METRIC_HOURS": "Heures rémunérées"
    },
    "ABSENCE_DAYS_EXPORT": {
      "TITLE": "Exportation des jours d'absence",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "COLUMN_USER_ID": "Identifiant de l'employé",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Département",
      "COLUMN_STAFF_GROUP": "Grupo de personal",
      "COLUMN_JOB_ROLE_NAME": "Función laboral",
      "COLUMN_DATE": "Date",
      "COLUMN_HOURS": "Heures",
      "COLUMN_HEADER_ID": "ID d'en-tête",
      "COLUMN_ABSENCE_TYPE": "Tapez",
      "COLUMN_ABSENCE_TYPE_REASON": "Motif",
      "COLUMN_STATUS": "État",
      "COLUMN_SCOPE": "Portée",
      "COLUMN_START_DATE": "Date de début de l'en-tête",
      "COLUMN_END_DATE": "Date de fin de l'en-tête",
      "COLUMN_START_TIME": "Heure de début",
      "COLUMN_END_TIME": "Heure de fin",
      "COLUMN_TOTAL_HOURS": "Heures d'en-tête",
      "COLUMN_TOTAL_DAYS": "Journées d'en-tête",
      "COLUMN_CANCELLED": "Annulé",
      "FILTER_DATE": "Date",
      "FILTER_SITE": "Site/département",
      "FILTER_STATUS": "État",
      "FILTER_TYPE": "Tapez",
      "FILTER_USER": "Personne",
      "FILTER_TOGGLE_CANCELLED": "Inclure annulé",
      "FILTER_TOGGLE_USER_ACTIVE": "Employés actifs",
      "BUTTON_EXPORT": "Exporter",
      "NO_MATCH": "Nous n'avons trouvé aucune absence correspondant à vos filtres."
    },
    "NEW_HIRE_TURNOVER": {
      "TITLE": "Rotation des nouvelles recrues",
      "FILTER_DATE": "Date d'adhésion",
      "JUMP_TO": "Passez à",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_LAST_TWO_WEEKS": "Les deux dernières semaines",
      "JUMP_TO_LAST_MONTH": "Le mois dernier",
      "FILTER_AGGREGATION": "Agrégation",
      "AGGREGATION_ENTITY_GROUP": "Site/département",
      "AGGREGATION_POSITION": "Rôle du poste",
      "AGGREGATION_STAFF_GROUP": "Groupe du personnel",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "DATE_ERROR_DIFF": "Vous ne pouvez pas choisir plus de 12 mois. Veuillez limiter votre plage de dates.",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "COLUMN_AGGREGATION_LABEL": "Nom",
      "COLUMN_JOINERS": "Menuisiers",
      "COLUMN_LEAVERS": "Sortants",
      "COLUMN_TURNOVER_RATE": "Taux de rotation",
      "DEPARTED_WITHIN": "Date de départ",
      "DAYS_LABEL": "dans un délai de {{count}} jours",
      "LABEL_TOTAL": "Total",
      "LABEL_GRAND_TOTAL": "Total général",
      "FILTER_LEAVER_REASON": "Motif du départ"
    },
    "COST_CONTROL_RAW_DATA": {
      "TITLE": "Contrôle des coûts et exportation de données brutes",
      "ERROR_500": "Nous n'étions pas en mesure d'exporter les données à ce moment-là. Réessayez, s'il vous plaît.",
      "BUTTON_EXPORT": "Exporter",
      "LOADING_TEXT": "Votre exportation est en cours de génération.",
      "METRIC_HOURS": "Heures",
      "METRIC_WAGES": "Salaires",
      "METRIC_SALES": "Ventes",
      "METRIC_COVERS": "Housses",
      "SUBDIVISION_PREDICTION": "Prédictions",
      "SUBDIVISION_FORECAST": "Prévisions",
      "SUBDIVISION_ACTUAL": "Réels",
      "FILTER_METRICS": "Métriques",
      "FILTER_SUBDIVISIONS": "Subdivisions",
      "FILTER_ENTITY": "Site/département",
      "FILTER_ENTITY_BUTTON": "Site/département",
      "FILTER_DATE": "Date",
      "JUMP_TO": "Passez à",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_LAST_TWO_WEEKS": "Les deux dernières semaines",
      "JUMP_TO_LAST_MONTH": "Le mois dernier",
      "CALCULATE_ACTUAL_CHECKBOX": "Calculez les heures et les salaires réels à la volée, lorsque les données approuvées ne sont pas présentes.",
      "DATE_ERROR_DIFF": "Vous ne pouvez pas choisir plus de 2 mois. Veuillez limiter votre plage de dates.",
      "CALCULATE_ACTUAL_ERROR": "Les heures/salaires réels ne peuvent pas être calculés à la volée pour plus d'une semaine ou quinze sites/départements."
    },
    "ABSENCE_EXPORT": {
      "TITLE": "Exportation des absences",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_USER_ID": "Identifiant de l'employé",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Département",
      "COLUMN_STAFF_GROUP": "Grupo de personal",
      "COLUMN_JOB_ROLE_NAME": "Función laboral",
      "COLUMN_ABSENCE_TYPE": "Tapez",
      "COLUMN_ABSENCE_TYPE_REASON": "Motif",
      "COLUMN_STATUS": "État",
      "COLUMN_SCOPE": "Portée",
      "COLUMN_START_DATE": "Date de début",
      "COLUMN_END_DATE": "Date de fin",
      "COLUMN_START_TIME": "Heure de début",
      "COLUMN_END_TIME": "Heure de fin",
      "COLUMN_TOTAL_HOURS": "Heures",
      "COLUMN_TOTAL_DAYS": "Journées",
      "COLUMN_REQUEST_NAME": "Demandé par",
      "COLUMN_REQUEST_DATE": "Date de la demande",
      "COLUMN_REQUEST_MESSAGE": "Message de demande",
      "COLUMN_REVIEW_NAME": "Révisé par",
      "COLUMN_REVIEW_DATE": "Date de révision",
      "COLUMN_REVIEW_MESSAGE": "Message de révision",
      "COLUMN_EDITED_NAME": "Modifié pour la dernière fois par",
      "COLUMN_EDITED_DATE": "Date de dernière modification",
      "COLUMN_CANCELLED": "Annulé",
      "COLUMN_CANCELLED_NAME": "Annulé par",
      "COLUMN_CANCELLED_DATE": "Date annulée",
      "FILTER_DATE": "Date de début/de fin",
      "FILTER_SITE": "Site/département",
      "FILTER_STATUS": "État",
      "FILTER_TYPE": "Tapez",
      "FILTER_USER": "Personne",
      "FILTER_TOGGLE_CANCELLED": "Inclure annulé",
      "FILTER_TOGGLE_USER_ACTIVE": "Employés actifs",
      "BUTTON_EXPORT": "Exporter",
      "NO_MATCH": "Nous n'avons trouvé aucune absence correspondant à vos filtres."
    },
    "ALLOWANCE_EXPORT": {
      "TITLE": "Exportation de quotas",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_USER_ID": "Identifiant de l'employé",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Département",
      "COLUMN_ABSENCE_TYPE": "Type d'absence",
      "COLUMN_YEAR_STARTS": "Début de l'année",
      "COLUMN_EFFECTIVE_DATE": "Date d'entrée en vigueur",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Calendrier de comptabilisation",
      "COLUMN_UNIT": "Unité",
      "COLUMN_DEDUCTIBLE": "Déductible",
      "COLUMN_TOTAL_ACCRUED": "Total cumulé",
      "COLUMN_OPENING_BALANCE": "Solde d'ouverture",
      "COLUMN_BOOKED": "Réservé",
      "COLUMN_BOOKED_AND_ELAPSED": "Réservé et écoulé",
      "COLUMN_REMAINING": "Restant",
      "COLUMN_ACCRUAL_ESTIMATE": "Estimation cumulé",
      "COLUMN_CARRIED_OVER": "Transporté",
      "COLUMN_OVERSPEND_LIMIT": "Dépenses excessives",
      "COLUMN_TOTAL_TO_ACCRUE": "Maximum à cumuler",
      "COLUMN_ACCRUAL_RATE": "DéroGATION au taux d'accumulation",
      "COLUMN_ADDED_NAME": "Ajouté par",
      "COLUMN_ADDED_DATE": "Date ajoutée",
      "COLUMN_EDITED_NAME": "Modifié pour la dernière fois par",
      "COLUMN_EDITED_DATE": "Date de dernière modification",
      "COLUMN_FINALISED": "Finalisé",
      "COLUMN_FINALISED_REFERENCE_DATE": "Date de référence finalisée",
      "COLUMN_FINALISED_EDITED_NAME": "Finalisé par",
      "COLUMN_FINALISED_EDITED_DATE": "Finalisé le",
      "COLUMN_FINALISED_NOTES": "Notes finalisées",
      "COLUMN_PROJECT_TOTAL_ACCRUED": "Prédiction cumulée lors de la réservation",
      "FILTER_DATE": "Date d'entrée en vigueur",
      "FILTER_SITE": "Site/département",
      "FILTER_TYPE": "Type d'absence",
      "FILTER_SCHEDULE_OF_ACCRUAL": "Calendrier de comptabilisation",
      "FILTER_USER": "Personne",
      "FILTER_TOGGLE_USER_ACTIVE": "Employés actifs",
      "BUTTON_EXPORT": "Exporter",
      "NO_MATCH": "Nous n'avons trouvé aucune allocation correspondant à vos filtres."
    },
    "UNTAKEN_HOLIDAY_COST": {
      "TITLE": "Frais de vacances non pris",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "ERROR_400": "Votre plage de dates d'entrée en vigueur est trop large, veuillez la réduire et réessayer.",
      "BUTTON_EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "FILTER_DATE": "Date d'entrée en vigueur des allocations",
      "FILTER_SITE": "Site/département",
      "FILTER_TOGGLE_USER_ACTIVE": "Employés actifs",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Département",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Calendrier de comptabilisation",
      "COLUMN_UNIT": "Unité",
      "COLUMN_DEDUCTIBLE": "Déductible",
      "COLUMN_TOTAL_ACCRUED": "Total cumulé",
      "COLUMN_BOOKED": "Réservé",
      "COLUMN_BOOKED_AND_ELAPSED": "Échappé",
      "COLUMN_UNTAKEN": "Incapté",
      "COLUMN_PAY_AMOUNT": "Montant",
      "COLUMN_PAY_AMOUNT_TYPE": "Tapez",
      "COLUMN_PAY_PROJECTED_COST": "Coût prévu",
      "COLUMN_HEADER_ALLOWANCE": "Allocation",
      "COLUMN_HEADER_PAY": "Payez",
      "LABEL_DEDUCTIBLE_YES": "Oui",
      "LABEL_DEDUCTIBLE_NO": "Non",
      "TEXT_GRAND_TOTAL": "Total général"
    },
    "EMPLOYMENT_CHANGES": {
      "TITLE": "Évolution de l'emploi",
      "MODEL_APPOINTMENT": "Rendez-vous",
      "MODEL_PAY": "Payez",
      "MODEL_EMPLOYEE": "Employé",
      "PROPERTY_EMPLOYEE_FIRST_NAME": "Prénom",
      "PROPERTY_EMPLOYEE_LAST_NAME": "Nom de famille",
      "PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET": "Heures moyennes réglées manuellement",
      "PROPERTY_APPOINTMENT_ENTITY_GROUP": "Site",
      "PROPERTY_APPOINTMENT_ENTITY": "Département",
      "PROPERTY_APPOINTMENT_GROUP": "Groupe du personnel",
      "PROPERTY_APPOINTMENT_POSITION": "Rôle du poste",
      "PROPERTY_APPOINTMENT_COMPANY": "L'entreprise",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE": "Obligation principale",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT": "Obligation minimale (unité)",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD": "Obligation principale (période)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE": "Obligation maximale",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT": "Obligation maximale (unité)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD": "Obligation maximale (période)",
      "PROPERTY_APPOINTMENT_LINE_MANAGER": "Responsable hiérarchique",
      "PROPERTY_APPOINTMENT_PAYROLL_CALENDAR": "Calendrier de paie",
      "PROPERTY_PAY_AMOUNT": "Montant",
      "PROPERTY_PAY_AMOUNT_TYPE": "Type de montant",
      "PROPERTY_PAY_UPLIFT": "Hausse des salaires",
      "FILTER_DATE": "Date",
      "JUMP_TO": "Passez à",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_LAST_TWO_WEEKS": "Les deux dernières semaines",
      "JUMP_TO_LAST_MONTH": "Le mois dernier",
      "FILTER_SITE": "Site/département",
      "FILTER_PROPERTY": "Propriété",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "DATE_ERROR_DIFF": "Vous ne pouvez pas choisir plus de 12 mois. Veuillez limiter votre plage de dates.",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Département",
      "COLUMN_CHANGE": "Changement",
      "COLUMN_EFFECTIVE_DATE": "Efficace",
      "COLUMN_PROPERTY": "Propriété",
      "COLUMN_NEW_VALUE": "Nouvelle valeur",
      "COLUMN_OLD_VALUE": "Ancienne valeur",
      "COLUMN_EDITED_BY": "Modifié par",
      "COLUMN_EDITED_ON": "Date modifiée"
    },
    "ANNIVERSARY": {
      "TITLE": "Anniversaires",
      "FILTER_DATE": "Date",
      "JUMP_TO": "Passez à",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_LAST_TWO_WEEKS": "Les deux dernières semaines",
      "JUMP_TO_LAST_MONTH": "Le mois dernier",
      "FILTER_SITE": "Site/département",
      "FILTER_TYPE": "anniversaire",
      "TYPE_BIRTHDAY": "anniversaire",
      "TYPE_EMPLOYMENT": "Anniversaire de travail",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "DATE_ERROR_DIFF": "Vous ne pouvez pas choisir plus de 12 mois. Veuillez limiter votre plage de dates.",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Département",
      "COLUMN_TYPE": "anniversaire",
      "COLUMN_YEARS": "Années",
      "COLUMN_DATE": "Date"
    },
    "STAFF_TURNOVER": {
      "TITLE": "Rotation du personnel",
      "FILTER_DATE": "Date",
      "JUMP_TO": "Passez à",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_LAST_TWO_WEEKS": "Les deux dernières semaines",
      "JUMP_TO_LAST_MONTH": "Le mois dernier",
      "FILTER_AGGREGATION": "Agrégation",
      "AGGREGATION_ENTITY_GROUP": "Site/département",
      "AGGREGATION_POSITION": "Rôle du poste",
      "AGGREGATION_STAFF_GROUP": "Groupe du personnel",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "DATE_ERROR_DIFF": "Vous ne pouvez pas choisir plus de 12 mois. Veuillez limiter votre plage de dates.",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "COLUMN_AGGREGATION_LABEL": "Nom",
      "COLUMN_HEADCOUNT_START": "Effectif (début)",
      "COLUMN_HEADCOUNT_END": "Effectif (fin)",
      "COLUMN_JOINERS": "Menuisiers",
      "COLUMN_REHIRES": "Réembauches",
      "COLUMN_LEAVERS": "Sortants",
      "COLUMN_TRANSFERS_IN": "Transferts en",
      "COLUMN_TRANSFERS_OUT": "Transferts sortants",
      "COLUMN_ATTRITION_RATE": "Taux d'attrition",
      "COLUMN_AVERAGE_SERVICE": "Service moyen",
      "LABEL_TOTAL": "Total",
      "LABEL_GRAND_TOTAL": "Total général",
      "FILTER_LEAVER_REASON": "Motif du départ"
    },
    "DOCUMENT_SIGNATURES": {
      "TITLE": "Signature de documents",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "INFO_NO_DATA": "Aucun enregistrement ne correspond à vos filtres.",
      "LABEL_METHOD_MANUAL": "Manuel",
      "LABEL_METHOD_ELECTRONIC": "Électronique",
      "LABEL_STATUS_PENDING": "En attente",
      "LABEL_STATUS_REJECTED": "Rejeté",
      "LABEL_STATUS_SIGNED": "Signé",
      "LABEL_STATUS_CANCELLED": "Annulé",
      "LABEL_RESPONSE_NONE": "En attente de réponse",
      "LABEL_RESPONSE_REJECTED": "Rejeté",
      "LABEL_RESPONSE_SIGNED": "Signé",
      "FILTER_SITE": "Site/département",
      "FILTER_RECIPIENT_USER": "Bénéficiaire",
      "FILTER_ADDED_USER": "Expéditeur",
      "FILTER_METHOD": "Méthode de signature",
      "FILTER_STATUS": "État",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "COLUMN_RECIPIENT": "Bénéficiaire",
      "COLUMN_EMPLOYER": "Contrepartie",
      "COLUMN_DOCUMENT": "Document",
      "COLUMN_DOCUMENT_CATEGORY": "Catégorie de destination",
      "COLUMN_METHOD": "Méthode",
      "COLUMN_STATUS": "État",
      "COLUMN_ADDED_USER": "Expéditeur",
      "COLUMN_ADDED_DATE": "Date",
      "EMPLOYER_NOT_SET": "Non obligatoire",
      "VIEW_MODAL": {
        "TITLE": "Document à signer",
        "LABEL_FILE": "Document",
        "LABEL_EXPIRY": "Expiration du document",
        "LABEL_TEMPLATE": "Modèle utilisé",
        "LABEL_CATEGORY": "Catégorie de destination",
        "LABEL_ADDED_BY": "Ajouté par",
        "LABEL_METHOD": "Méthode",
        "TEMPLATE_NOT_USED": "Aucune",
        "EXPIRY_NOT_APPLICABLE": "Non applicable",
        "BUTTON_CLOSE": "Fermer",
        "HEADING_SIGNERS": "Signataires",
        "EMPLOYEE": "Employé",
        "EMPLOYER": "Employeur",
        "COLUMN_NAME": "Nom",
        "COLUMN_ROLE": "Rôle",
        "COLUMN_STATUS": "État",
        "COLUMN_DATE": "Date",
        "LABEL_OPTIONS": "Options",
        "OPTION_REMINDER": "Envoyer un rappel",
        "OPTION_CANCEL": "Annuler le document",
        "OPTION_UPLOAD": "Télécharger un document signé",
        "CANCEL_ERROR_500": "Nous n'avons pas pu annuler le document à ce moment-là.",
        "CANCEL_SUCCESS": "Le document a été annulé.",
        "REMINDER_ERROR_500": "Nous n'avons pas pu envoyer de rappel à ce moment-là.",
        "REMINDER_ERROR_400": "Un rappel a déjà été envoyé récemment. Veuillez patienter un moment avant d'envoyer un autre rappel.",
        "REMINDER_SUCCESS": "J'ai compris ! Nous allons envoyer un rappel par e-mail dès maintenant.",
        "DOWNLOAD_ERROR_500": "Nous n'avons pas pu télécharger le fichier à ce moment-là. Réessayez ou contactez-nous si cela persiste.",
        "LABEL_DOWNLOAD": "Télécharger",
        "OPTION_DOWNLOAD_ORIGINAL": "Télécharger l'original",
        "OPTION_DOWNLOAD_SIGNED_COPY": "Télécharger la copie signée"
      }
    },
    "WORKING_TIME_VIOLATIONS": {
      "TITLE": "Violations relatives au temps de travail",
      "FILTER_DATE": "Date",
      "JUMP_TO": "Passez à",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_LAST_TWO_WEEKS": "Les deux dernières semaines",
      "JUMP_TO_LAST_MONTH": "Le mois dernier",
      "FILTER_SITE": "Site/département",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "COLUMN_SITE_DEPARTMENT": "Site/département",
      "COLUMN_VIOLATION_HOURS": "Horaires de travail",
      "COLUMN_VIOLATION_HOURS_TOOLTIP": "Nombre de cas où un employé a dépassé 48 heures de travail (en moyenne sur 17 semaines).",
      "COLUMN_VIOLATION_DAYS_OFF": "Jours de congé",
      "COLUMN_VIOLATION_DAYS_OFF_TOOLTIP": "Le nombre de cas où un employé a eu moins de 2 jours de congé (au cours d'une période de 2 semaines).",
      "COLUMN_VIOLATION_REST": "Repos",
      "COLUMN_VIOLATION_REST_TOOLTIP": "Le nombre de cas où un employé a eu moins de 11 heures de repos après une journée de travail.",
      "COLUMN_VIOLATION_U18_DAYS_OFF": "U18 : Jours de congé",
      "COLUMN_VIOLATION_U18_DAYS_OFF_TOOLTIP": "Le nombre de cas où un employé de moins de 18 ans a eu moins de 2 jours de congé consécutifs (au cours d'une semaine donnée).",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH": "U18 : Long quart de travail",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH_TOOLTIP": "Le nombre de cas où un employé de moins de 18 ans a effectué un quart de travail de plus de 8 heures.",
      "COLUMN_VIOLATION_U18_REST": "U18 : Repos",
      "COLUMN_VIOLATION_U18_REST_TOOLTIP": "Le nombre de cas où un employé de moins de 18 ans a eu moins de 11 heures de repos après une journée de travail.",
      "COLUMN_TOTAL": "Total",
      "ROW_TOTAL": "Total",
      "ROW_GRAND_TOTAL": "Total général",
      "INFO_NO_FILTER": "Sélectionnez un site/département ci-dessus pour commencer.",
      "DATE_ERROR_DIFF": "Vous ne pouvez pas choisir plus de 12 mois. Veuillez limiter votre plage de dates.",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "VIOLATION_TYPE_1_DESC": "Ne peut pas dépasser la moyenne de 48 heures de travail sur 17 semaines",
      "VIOLATION_TYPE_2_DESC": "Ne pas avoir moins de 2 jours de congé par période de 2 semaines",
      "VIOLATION_TYPE_3_DESC": "Moins de 18 ans : Doit bénéficier de 2 jours de congé consécutifs par semaine",
      "VIOLATION_TYPE_4_DESC": "Moins de 18 ans : quart de travail de plus de 8 heures",
      "VIOLATION_TYPE_5_DESC": "Moins de 18 ans : moins de 12 heures de repos après la journée de travail",
      "VIOLATION_TYPE_6_DESC": "Moins de 11 heures de repos après une journée de travail",
      "VIOLATION_TYPE_1": "Trop d'heures de travail (en moyenne)",
      "VIOLATION_TYPE_2": "Nombre de jours de congé insuffisant",
      "VIOLATION_TYPE_3": "Moins de 18 ans : jours de congé insuffisants",
      "VIOLATION_TYPE_4": "Moins de 18 ans : quart de travail trop long",
      "VIOLATION_TYPE_5": "Moins de 18 ans : repos insuffisant entre les quarts de travail",
      "VIOLATION_TYPE_6": "Repos insuffisant entre les quarts de travail",
      "FILTER_VIOLATION_TYPE": "Type de violation",
      "FILTER_PER_PAGE": "Lignes par page",
      "DETAIL_COLUMN_ID": "IDENTIFIANT",
      "DETAIL_COLUMN_HR_ID": "IL A FAIT",
      "DETAIL_COLUMN_PAYROLL_ID": "Numéro de paie",
      "DETAIL_COLUMN_FIRST_NAME": "Prénom",
      "DETAIL_COLUMN_LAST_NAME": "Nom de famille",
      "DETAIL_COLUMN_SITE": "Site",
      "DETAIL_COLUMN_DEPARTMENT": "Département",
      "DETAIL_COLUMN_VIOLATION": "Violation",
      "DETAIL_COLUMN_DATE_FROM": "Date du",
      "DETAIL_COLUMN_DATE_TO": "Date à",
      "DETAIL_COLUMN_VALUE": "Valeur",
      "DETAIL_LABEL_WORKING_HOURS": "heures de travail",
      "DETAIL_LABEL_WORKING_DAYS": "jours ouvrables",
      "DETAIL_LABEL_HOURS_REST": "heures de repos",
      "DETAIL_INFO_NO_RECORDS": "Vous n'avez commis aucune violation du temps de travail, top job !",
      "DETAIL_INFO_EMPTY_FILTER_RESULT": "Il n'y a aucune violation correspondant à vos filtres."
    },
    "CONTRACTUAL_OBLIGATION_SUMMARY": {
      "TITLE": "Résumé des obligations contractuelles",
      "FILTER_DATE": "Date",
      "JUMP_TO": "Passez à",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_LAST_TWO_WEEKS": "Les deux dernières semaines",
      "JUMP_TO_LAST_MONTH": "Le mois dernier",
      "FILTER_SITE": "Site/département",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "FILTER_DATA_SOURCE": "Source de données",
      "FILTER_USER": "Personne",
      "COLUMN_GROUP_TOTAL": "Total (payé)",
      "COLUMN_GROUP_OBLIGATION": "Obligation contractuelle",
      "COLUMN_GROUP_ANALYSIS_META": "Analysé plus",
      "COLUMN_GROUP_ANALYSIS": "Analyse",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Département",
      "COLUMN_DAYS_WORKED": "Journées",
      "COLUMN_PAID_HOURS": "Heures",
      "COLUMN_AVERAGE_HOURS": "Moyenne",
      "COLUMN_AVERAGE_HOURS_TOOLTIP": "Le nombre moyen d'heures travaillées par jour.",
      "COLUMN_OBLIGATION_VALUE": "Valeur",
      "COLUMN_OBLIGATION_UNIT": "Unité",
      "COLUMN_OBLIGATION_PERIOD": "Période",
      "COLUMN_ANALYSIS_START": "Démarrer",
      "COLUMN_ANALYSIS_END": "Fin",
      "COLUMN_ANALYSIS_PERIODS": "# Périodes",
      "COLUMN_ANALYSIS_PERIODS_TOOLTIP": "Le nombre de semaines ou de mois qui ont été analysés.",
      "COLUMN_ANALYSIS_AVERAGE": "Moyenne",
      "COLUMN_ANALYSIS_AVERAGE_TOOLTIP": "Nombre moyen d'heures ou de jours travaillés.",
      "COLUMN_ANALYSIS_DIFFERENCE": "Différent.",
      "COLUMN_ANALYSIS_DIFFERENCE_TOOLTIP": "La différence entre les heures/jours minimaux qui auraient dû être travaillés (l'obligation contractuelle multipliée par le nombre de périodes) et ce qui a été réellement travaillé (le total des heures ou des jours).",
      "INFO_NO_DATA": "Aucun enregistrement ne correspond à vos filtres.",
      "INFO_NO_FILTER": "Sélectionnez un site/département ci-dessus pour commencer.",
      "PAY_AMOUNT_TYPE_HOURLY": "Taux horaire",
      "PAY_AMOUNT_TYPE_DAILY": "Tarif journalier",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salaire annuel",
      "DATA_SOURCE_SHIFTS": "quarts de travail",
      "DATA_SOURCE_ATTENDANCE": "Présence",
      "DATA_SOURCE_MATCHED": "Assaini",
      "CONTRACTUAL_UNIT_HOURS": "Heures",
      "CONTRACTUAL_UNIT_DAYS": "Journées",
      "CONTRACTUAL_PERIOD_WEEK": "Semaine",
      "CONTRACTUAL_PERIOD_MONTH": "Mois",
      "CONTRACTUAL_PERIOD_WEEKLY": "Hebdo",
      "CONTRACTUAL_PERIOD_MONTHLY": "Mensuel",
      "DATE_ERROR_DIFF": "Vous ne pouvez pas choisir plus de 14 semaines. Veuillez limiter votre plage de dates.",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer."
    },
    "LABOUR_BREAKDOWN": {
      "TITLE": "Répartition de la main-d'œuvre",
      "FILE_EXPORT_TITLE": "Exportation du fichier de répartition de la main-d'œuvre",
      "FILTER_DATE": "Date",
      "JUMP_TO": "Passez à",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_LAST_TWO_WEEKS": "Les deux dernières semaines",
      "JUMP_TO_LAST_MONTH": "Le mois dernier",
      "FILTER_SITE": "Site/département",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "FILTER_DATA_SOURCE": "Source de données",
      "FILTER_USER": "Personne",
      "FILTER_BEHAVIOUR": "Comportement",
      "BEHAVIOUR_COST_CONTROL": "Contrôle des coûts",
      "BEHAVIOUR_PAYROLL": "Paie",
      "FILTER_SHIFT_TYPE": "Type de quart de travail",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE_APPOINTMENT": "Site (rendez-vous)",
      "COLUMN_DEPT_APPOINTMENT": "Département (rendez-vous)",
      "COLUMN_EVENT_TYPE": "Type d'événement",
      "COLUMN_SHIFT_TYPE": "Type de quart de travail",
      "COLUMN_SITE_ATTRIBUTION": "Site (attribution)",
      "COLUMN_DEPT_ATTRIBUTION": "Département (attribution)",
      "COLUMN_START_TIME": "Démarrer",
      "COLUMN_END_TIME": "Terminer",
      "COLUMN_PAID_HOURS": "Heures rémunérées",
      "COLUMN_UNPAID_HOURS": "Heures non rémunérées",
      "COLUMN_BASE_PAY": "Rémunération de base",
      "COLUMN_ROLLED_UP_HOLIDAY": "Congés cumulés",
      "COLUMN_TAXES": "Impôts",
      "COLUMN_WAGE_UPLIFT": "Hausse des salaires",
      "COLUMN_TOTAL_COST": "Coût total",
      "INFO_NO_DATA": "Aucun enregistrement ne correspond à vos filtres.",
      "INFO_NO_FILTER": "Sélectionnez un site/département ci-dessus pour commencer.",
      "PAY_AMOUNT_TYPE_HOURLY": "Taux horaire",
      "PAY_AMOUNT_TYPE_DAILY": "Tarif journalier",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salaire annuel",
      "EVENT_TYPE_SHIFT": "quart de travail",
      "EVENT_TYPE_ABSENCE": "Absence",
      "EVENT_TYPE_SALARY": "Salaire",
      "EVENT_TYPE_TAXES": "Impôts",
      "DATA_SOURCE_SHIFTS": "quarts de travail",
      "DATA_SOURCE_ATTENDANCE": "Présence",
      "DATA_SOURCE_MATCHED": "Assaini",
      "DATE_ERROR_DIFF": "Vous ne pouvez pas choisir plus de 14 semaines. Veuillez limiter votre plage de dates.",
      "DATE_WARNING_WEEKS": "Vous avez sélectionné une plage de dates qui ne correspond pas aux semaines calendaires. Certains impôts des employeurs sont calculés à l'aide de seuils hebdomadaires, de sorte que les impôts affichés ici peuvent être artificiellement inférieurs à la réalité. Le salaire de base et les congés accumulés restent corrects, mais veuillez aligner vos dates sur les semaines civiles pour obtenir des taxes et un coût total corrects.",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "ERROR_400": "Vos paramètres génèrent trop de données pour un seul rapport. Réduisez la portée de votre demande et réessayez."
    },
    "LABOUR_BREAKDOWN_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Générez un rapport par e-mail.",
      "REPORT_GENERATED": "Votre rapport est en cours de génération et vous sera envoyé par e-mail une fois terminé."
    },
    "PAYROLL_EXPORT": {
      "TITLE": "Exportation des salaires",
      "FILTER_DATE": "Date",
      "JUMP_TO": "Passez à",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_LAST_TWO_WEEKS": "Les deux dernières semaines",
      "JUMP_TO_LAST_MONTH": "Le mois dernier",
      "FILTER_SITE": "Site/département",
      "EXPORT": "Exporter",
      "EXPORT_PAYROLL_SOFTWARE": "Logiciel de paie",
      "EXPORT_PRINT": "Imprimer",
      "FILTER_AGGREGATION": "Segmentation",
      "FILTER_ABSENCE_TYPES": "Types d'absences",
      "FILTER_PAY_ELEMENT_TYPES": "Types d'éléments de rémunération",
      "FILTER_USER": "Personne",
      "FILTER_COMPANY": "Entreprise",
      "TOGGLE_SHOW_ROLLED_UP_HOLIDAY": "Afficher les vacances cumulées",
      "COLUMN_ID": "IDENTIFIANT",
      "COLUMN_HR_ID": "IL A FAIT",
      "COLUMN_PAYROLL_ID": "Numéro de paie",
      "COLUMN_FIRST_NAME": "Prénom",
      "COLUMN_LAST_NAME": "Nom de famille",
      "COLUMN_SITE_ATTRIBUTION": "Site (attribution)",
      "COLUMN_DEPT_ATTRIBUTION": "Département (attribution)",
      "COLUMN_SITE_APPOINTMENT": "Site (rendez-vous)",
      "COLUMN_DEPT_APPOINTMENT": "Département (rendez-vous)",
      "COLUMN_GROUP": "Groupe",
      "COLUMN_POSITION": "Rôle du poste",
      "COLUMN_DAYS_WORKED": "Jours travaillés",
      "COLUMN_PAY_RATE": "Taux de rémunération",
      "COLUMN_AMOUNT_TYPE": "Type de montant",
      "COLUMN_PAY_TYPE": "Type de rémunération",
      "COLUMN_PAID_SHIFT_HOURS": "Heures de travail rémunérées",
      "COLUMN_UNPAID_SHIFT_HOURS": "Heures de travail non rémunérées",
      "COLUMN_BASE_PAY": "Rémunération de base",
      "COLUMN_ROLLED_UP_HOLIDAY": "Vacances cumulées",
      "COLUMN_ABSENCE_DAYS": "{{label}} jours",
      "COLUMN_ABSENCE_HOURS": "{{label}} heures",
      "INFO_NO_DATA": "Aucun enregistrement ne correspond à vos filtres. Avez-vous déjà signé ? N'oubliez pas que ce rapport utilise uniquement des données approuvées.",
      "INFO_NO_FILTER": "Sélectionnez un site/département ci-dessus pour commencer.",
      "PAY_AMOUNT_TYPE_HOURLY": "Taux horaire",
      "PAY_AMOUNT_TYPE_DAILY": "Tarif journalier",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salaire annuel",
      "AGGREGATION_OPTION_ATTRIBUTION": "Le site ou le service auquel les heures ont été attribuées",
      "AGGREGATION_OPTION_APPOINTMENT": "Le site ou le service dans lequel la personne était basée à l'époque",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "ABSENCE_TYPE_DELETED_SUFFIX": "Supprimé",
      "DATE_ERROR_MIN": "Veuillez sélectionner une date postérieure au 12 décembre 2018.",
      "DATE_ERROR_DIFF": "Vous ne pouvez pas choisir plus de 6 mois. Veuillez limiter votre plage de dates.",
      "EXPORT_MODAL": {
        "TITLE": "Exporter vers un logiciel de paie",
        "LABEL_EXPORT_TO": "Exporter vers",
        "BUTTON_DOWNLOAD": "Télécharger",
        "BUTTON_CLOSE": "Fermer",
        "SUCCESS": "Votre exportation a été téléchargée avec succès.",
        "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer."
      },
      "SIGN_OFF_STATUS_COMPLETE": "Entièrement signé",
      "SIGN_OFF_STATUS_NONE": "Non signé",
      "SIGN_OFF_STATUS_PARTIAL": "Partiellement approuvé",
      "SIGN_OFF_STATUS_DRAWER": {
        "TITLE": "État de déconnexion",
        "BACK_TO_DATES": "Retour aux dates"
      },
      "FILTER_PAYROLL_CALENDAR": "Calendrier de paie",
      "FILTER_PAY_RUN": "Payrun"
    },
    "ROTA_PROGRESS": {
      "TITLE": "Progression de la rotation",
      "JUMP_TO": "Passez à",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_NEXT_WEEK": "La semaine prochaine",
      "FILTER_SITE": "Site/département",
      "LIFECYCLE_DRAFT": "Brouillon",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Marqué pour approbation",
      "LIFECYCLE_PUBLISHED": "Publié",
      "INCLUDE_NO_DRAFT": "Afficher les sites sans brouillon",
      "NO_DRAFT": "Pas de brouillon",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "COLUMN_SITE": "Site/département",
      "COLUMN_LIFECYCLE": "État",
      "COLUMN_SCHEDULED_HOURS": "Horaires prévus",
      "COLUMN_SCHEDULED_WAGES": "Salaires prévus",
      "COLUMN_FORECAST_SALES": "Ventes prévisionnelles",
      "COLUMN_WAGE_PERCENTAGE": "Salaire %",
      "ROW_GRAND_TOTAL": "Total général",
      "TOOLTIP_BUDGET": "Budget",
      "INFO_NO_DATA": "Aucun site/département ne correspond à vos filtres.",
      "INFO_NO_FILTER": "Sélectionnez un site/département ci-dessus pour commencer.",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer."
    },
    "SIGN_OFF_PROGRESS": {
      "TITLE": "Progression de la signature",
      "JUMP_TO": "Passez à",
      "JUMP_TO_LAST_WEEK": "La semaine dernière",
      "JUMP_TO_THIS_WEEK": "Cette semaine",
      "JUMP_TO_NEXT_WEEK": "La semaine prochaine",
      "FILTER_SITE": "Site/département",
      "FILTER_STATUS": "État de signature",
      "STATUS_NO_ROTA": "Pas de rotation",
      "STATUS_INCOMPLETE": "Incomplet",
      "STATUS_REPORTING_DATE": "Date du rapport",
      "STATUS_COMPLETE": "Achevée",
      "STATUS_TOOLTIP_NO_ROTA": "Il n'y a pas de rotation publiée cette semaine.",
      "STATUS_TOOLTIP_INCOMPLETE": "La signature n'a pas été terminée pour tous les jours de cette semaine.",
      "STATUS_TOOLTIP_REPORTING_DATE": "La signature est terminée pour les jours qui ont précédé la date de votre rapport incluse.",
      "STATUS_TOOLTIP_COMPLETE": "La signature est terminée pour tous les jours de cette semaine.",
      "TOGGLE_COMPARAND": "Comparer et",
      "TOGGLE_COMPARAND_FORECAST": "Prévision",
      "TOGGLE_COMPARAND_BUDGET": "Budget",
      "EXPORT": "Exporter",
      "EXPORT_PRINT": "Imprimer",
      "COLUMN_WAGES": "Salaires",
      "COLUMN_SALES": "Ventes",
      "COLUMN_WAGE_PERCENTAGE": "Salaire %",
      "COLUMN_HOURS": "Heures",
      "COLUMN_SITE": "Site/département",
      "COLUMN_STATUS": "État",
      "COLUMN_FORECAST": "Prévision",
      "COLUMN_BUDGET": "Budget",
      "COLUMN_ACTUAL": "Réel",
      "ROW_GRAND_TOTAL": "Total général",
      "INFO_NO_DATA": "Aucun site/département ne correspond à vos filtres.",
      "INFO_NO_FILTER": "Sélectionnez un site/département ci-dessus pour commencer.",
      "ERROR_500": "Nous n'avons pas pu charger votre rapport à ce moment-là. Veuillez réessayer.",
      "STATUS_MODAL": {
        "REVERSE_CONFIRM": "Êtes-vous sûr de vouloir annuler cette approbation ?\n\nCela débloquera les quarts de travail et les records de présence à ces dates afin que des modifications puissent être apportées.",
        "REVERSE_SUCCESS": "L'approbation a été annulée avec succès.",
        "REVERSE_ERROR": "Nous n'avons pas pu annuler l'approbation à ce moment-là. Veuillez réessayer.",
        "TITLE": "État de signature",
        "COLUMN_DATE": "Date",
        "COLUMN_STATUS": "État",
        "COLUMN_OPTIONS": "Options",
        "STATUS_NO_SIGN_OFF": "Aucune signature",
        "STATUS_SIGN_OFF": "Signé",
        "BUTTON_REVERSE_DAY": "Inverser",
        "BUTTON_REVERSE_WEEK": "Inverser toute la semaine",
        "BUTTON_CLOSE": "Fermer"
      }
    },
    "TRONC_STATUS": {
      "TITLE": "État du tronc",
      "LIFECYCLE_DRAFT": "Brouillon",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Marqué pour approbation",
      "LIFECYCLE_LOCKED": "Verrouillé",
      "INCLUDE_NO_HEADER": "Afficher les sites sans brouillon",
      "NO_HEADER": "Pas de brouillon",
      "COLUMN_SITE": "Site/département",
      "COLUMN_LIFECYCLE": "État",
      "COLUMN_RECEIVED": "Total reçu",
      "COLUMN_PAID_OUT": "Total versé",
      "COLUMN_EDITED_WHO": "Edité par",
      "COLUMN_EDITED_WHEN": "Quand",
      "INFO_NO_DATA": "Aucun site/département Tronc ne correspond à vos filtres.",
      "ERROR_500": "Nous n'avons pas pu charger Tronc à ce moment-là. Veuillez réessayer."
    },
    "CURRENTLY_CLOCKED_IN": {
      "TITLE": "Personnes actuellement inscrites",
      "NAME": "Nom",
      "SITE": "Site/département",
      "POSITION": "Rôle du poste",
      "CLOCK_IN_TIME": "Heure de chronométrage",
      "NOBODY_CLOCKED_IN": "Personne n'est arrivé en ce moment !",
      "ERROR_500": "Nous n'avons pas pu charger le rapport à ce moment-là. Veuillez réessayer."
    },
    "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Générer et envoyer le rapport par e-mail.",
      "REPORT_GENERATED": "Votre rapport est en cours de génération et vous sera envoyé par e-mail une fois terminé.",
      "FILTER_DATE": "Date",
      "FILTER_ENTITY": "Site/département",
      "FILTER_PAY_TYPES": "Types de paiement",
      "FILTER_DATA_SOURCE": "Source de données",
      "FILTER_USER": "Personne",
      "FILTER_EVENT_TYPES": "Types d'événements",
      "FILTER_BEHAVIOUR": "Comportement",
      "FILTER_SHIFT_TYPE": "Type de quart",
    },
    "PAYROLL_EXPORT_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Générer et envoyer le rapport par e-mail.",
      "REPORT_GENERATED": "Votre rapport est en cours de génération et vous sera envoyé par e-mail une fois terminé.",
      "FILTER_PAYROLL_CALENDAR": "Calendrier de paie",
      "FILTER_PAY_RUN": "Exécution de paie",
      "FILTER_DATE": "Date",
      "FILTER_ENTITY": "Site/département",
      "FILTER_AGGREGATION_OPTIONS": "Segmentation",
      "FILTER_USER": "Personne",
      "FILTER_ABSENCE_TYPES": "Types d'absence",
      "FILTER_PAY_ELEMENT_TYPES": "Types d'éléments de paiement",
      "FILTER_PAY_AMOUNT_TYPE": "Type de montant de paiement",
      "FILTER_ROLLED_UP_HOLIDAY": "Férié cumulé",
      "FILTER_COMPANY": "Entreprise",
    },
  },
  "ROTA_MANAGEMENT": {
    "DASHBOARD": {
      "INDEX": {
        "TITLE": "Tableau de bord",
        "CHECKLIST": "Liste de contrôle Rota",
        "LABOUR_SPEND": "Dépenses de main-d'œuvre"
      },
      "LABOUR_SPEND_TAB": {
        "TITLE": "Aperçu de la main-d'œuvre",
        "SHOW_ACTUAL_TIP": "Pour les jours de cette semaine qui ne sont pas encore écoulés, projetez les prévisions de ventes et les salaires/heures prévus dans les totaux réels",
        "SHOW_ACTUAL_TEXT": "Les prévisions du projet se transforment en données réelles",
        "FORECAST": "Prévision",
        "ACTUAL": "Réel",
        "BUDGET": "Budget",
        "SALES": "Ventes",
        "WAGES": "Salaires",
        "HOURS": "Heures",
        "WAGE_PERCENT": "Salaire %",
        "LABOUR_COMP": "Comparaison de la main-d'œuvre",
        "ACTUAL_PROJECTED": "Réel (prévu)",
        "TODAYS_VS_SCHEDULED": "Présence d'aujourd'hui par rapport à la programmation"
      },
      "WTD_VIOLATIONS_MODAL": {
        "TITLE": "Violations relatives au temps de travail",
        "BODY": "Dans ces cas, nous avons constaté que vos horaires enfreignaient la réglementation sur le temps de travail.",
        "TO": "pour ",
        "ALL": "Toutes les violations",
        "TOO_MANY": "Trop d'heures de travail (en moyenne)",
        "INSUF_DAYS": "Nombre de jours de congé insuffisant",
        "INSUF_REST": "Repos insuffisant entre les quarts de travail",
        "UNDER_18_DAYS": "Moins de 18 ans : jours de congé insuffisants",
        "UNDER_18_LONG": "Moins de 18 ans : quart de travail trop long",
        "UNDER_18_REST": "Moins de 18 ans : repos insuffisant entre les quarts de travail",
        "STAFF_MEMBER": "Membre du personnel",
        "VIOLATION": "Violation",
        "VALUE": "Valeur",
        "NO_VIOLATIONS": "Vous n'avez commis aucune violation du temps de travail, top job !",
        "NO_FILTER_VIOLATIONS": "Il n'y a aucune violation correspondant à vos filtres.",
        "AVE_48_OVER_17": "Ne peut pas dépasser la moyenne de 48 heures de travail sur 17 semaines",
        "LESS_2_DAYS_2_WEEKS": "Ne pas avoir moins de 2 jours de congé par période de 2 semaines",
        "UNDER_18_2_PER_WEEK": "Moins de 18 ans : Doit bénéficier de 2 jours de congé consécutifs par semaine",
        "UNDER_18_LONGER_8": "Moins de 18 ans : quart de travail de plus de 8 heures",
        "UNDER_18_LESS_12_REST": "Moins de 18 ans : moins de 12 heures de repos après la journée de travail",
        "LESS_11_WEEK": "Moins de 11 heures de repos après une journée de travail",
        "WORKING_HOURS": " heures de travail",
        "WORKING_DAYS": " jours ouvrables",
        "HOURS_REST": " heures de repos",
        "RECALC": "Recalculer",
        "LOADING": "Chargement en cours...",
        "CLOSE": "Fermer"
      }
    },
    "LOCATIONS": {
      "ADD_LOCATION_MODAL": {
        "NEW_SHIFT_LOCATION": "Nouvelle zone de fréquentation",
        "TYPE": "Tapez",
        "NEW_LOCATION": "Nouvelle zone",
        "CHANGE_EXISTING_LOCATION": "Modifier la zone existante",
        "EXISTING_LOCATION": "Zone existante",
        "NAME": "Nom",
        "DETAILS": "Détails",
        "HELP": "Rotaready mémorise la position précise du marqueur sur la carte. Ces informations ne sont donc fournies qu'à titre de référence. N'hésitez pas à inclure quelques notes ou une adresse complète.",
        "SAVING": "Économiser...",
        "SAVE": "Enregistrer",
        "CANCEL": "Annuler",
        "ERROR_ENTER_NAME": "Entrez un nom pour cette zone.",
        "ERROR_UPDATE": "Impossible de mettre à jour cette zone pour le moment. Réessayez, s'il vous plaît.",
        "ERROR_ADD": "Impossible d'ajouter cette zone pour le moment. Veuillez réessayer."
      },
      "EDIT_LOCATION_MODAL": {
        "EDIT_LOCATION": "Modifier la zone",
        "HIERARCHY": "Hiérarchie",
        "MASTER_LOCATION": "Zone principale",
        "SUB_LOCATION": "Sous-zone",
        "HELP_HIERARCHY": "Rotaready vous permet de créer une hiérarchie de zones. Une sous-zone peut être placée sous n'importe quelle autre zone.",
        "PARENT_LOCATION": "Zone parentale",
        "NAME": "Nom",
        "DETAILS": "Détails",
        "HELP_DETAILS": "Rotaready mémorise la position précise du marqueur sur la carte. Ces informations ne sont donc fournies qu'à titre de référence. N'hésitez pas à inclure quelques notes ou une adresse complète.",
        "ATTENDANCE": "Présence",
        "ALLOW_CLOCKING": "Autorisez le personnel à entrer et à sortir ici",
        "STAFF_WORKING": "Uniquement lorsque le personnel travaille un quart de travail marqué par cette zone",
        "HELP_CLOCKING": "Ces paramètres déterminent si le personnel peut entrer et sortir à l'aide de l'application mobile Rotaready.",
        "ATTENDANCE_SENSITIVITY": "Sensibilité à la fréquentation",
        "METRES": "mètres",
        "HELP_CLOSE_LOCATION": "Cela définit la distance à laquelle une personne doit se trouver par rapport à la zone avant que l'application mobile Rotaready ne lui permette d'entrer ou de sortir. Il n'est pas recommandé de descendre à moins de 50 mètres, car les personnes dont le signal est faible ne pourront pas entrer.",
        "MIN_OCCUPANCY": "Occupation minimale",
        "HELP_MIN_OCCUPANCY": "Chaque fois qu'une personne travaille, Rotaready vous alerte si le nombre total de personnes également en poste tombe en dessous de ce chiffre.",
        "MAX_OCCUPANCY": "Occupation maximale",
        "HELP_MAX_OCCUPANCY": "Si cette option est fournie, Rotaready ne vous permettra pas de programmer plus de personnel ici (à tout moment) que l'occupation maximale.",
        "TIP_OPENING": "Spécifiez les heures d'ouverture pour que Rotaready vous évite de planifier des quarts de travail dans cette zone lorsqu'elle est fermée.",
        "TIP_SUB_LOC": "Comme il s'agit d'une sous-zone, si sa zone mère est fermée à tout moment, cette sous-zone sera également considérée comme fermée.",
        "REGULAR_OPENING_TIMES": "Horaires d'ouverture réguliers",
        "OPEN_247": "Cette zone est ouverte 24h/24 et 7j/7.",
        "TO": "pour",
        "SPECIAL_PERIODS": "Périodes spéciales",
        "NO_SPECIAL_PERIODS": "Il n'y a pas de périodes spéciales.",
        "OPEN": "Ouvrir",
        "CLOSED": "Fermé",
        "ADD_SPECIAL_PERIOD": "Ajouter une période spéciale",
        "REASON": "Motif",
        "REASON_PLACEHOLDER": "par exemple. Rénovation estivale",
        "FROM": "À partir de",
        "TO_CAPS": "À",
        "ADD": "Ajouter",
        "DELETE": "Supprimer",
        "SAVING": "Économiser...",
        "SAVE": "Enregistrer",
        "CANCEL": "Annuler",
        "ALERT_NAME": "Assurez-vous d'avoir fourni un nom valide pour la zone de fréquentation.",
        "ALERT_PARENT": "Pour qu'il s'agisse d'une sous-zone, vous devez sélectionner une zone parent.",
        "ALERT_VALID_PARENT": "Sélectionnez une zone parent valide.",
        "ALERT_PARENT_LOCATION": "Cette zone de fréquentation et la zone parent que vous avez choisie doivent toutes deux appartenir au même site (ou appartenir à l'ensemble de l'organisation).",
        "ALERT_MINIMUM": "Vous ne pouvez pas avoir un niveau d'occupation minimum supérieur au niveau d'occupation maximum.",
        "ERROR_UPDATE": "Nous n'avons pas pu mettre à jour la zone à ce moment-là. Réessayez, s'il vous plaît.",
        "ALERT_SUB_CONFIRM": "Cette zone comporte toujours des sous-zones. Ils seront également supprimés.\n\nÊtes-vous sûr de vouloir continuer ?",
        "ALERT_CONFIRM": "Êtes-vous sûr de vouloir supprimer cette zone ?",
        "ERROR_DELETE": "Nous n'avons pas pu supprimer la zone à ce moment-là. Veuillez réessayer !"
      },
      "INDEX": {
        "SHIFT_LOCATIONS": "Zones de fréquentation",
        "TIP_FIND": "Pour ajouter une nouvelle zone ou modifier une zone existante, vous devez d'abord la trouver sur la carte. Recherchez une adresse ou un code postal, puis faites glisser le marqueur vert pour le repositionner.",
        "PLACEHOLDER_SEARCH": "Rechercher pour ajouter une nouvelle zone",
        "HELP_NO_LOCATIONS": "Vous n'avez aucune zone existante. Effectuez une recherche sur la carte pour en ajouter une.",
        "HELP_NO_RESULTS": "Aucune zone de fréquentation ne correspond à vos termes de recherche.",
        "TOOLTIP_SHOW": "Afficher sur la carte",
        "TOOLTIP_CLOCKING_ALWAYS": "Le personnel peut toujours entrer et sortir avec l'application mobile Rotaready ici",
        "TOOLTIP_CLOCKING_WORKING": "Le personnel peut entrer et sortir avec l'application mobile Rotaready ici, uniquement s'il travaille par équipes ici",
        "ERROR_LOAD": "Nous n'avons pas pu charger vos zones de présence. Veuillez réessayer.",
        "NO_ADDRESS": "Pas de détails/adresse",
        "ERROR_LOOKUP": "Nous n'avons pas réussi à trouver l'emplacement de ce marqueur.",
        "UNKNOWN_ADDRESS": "Adresse inconnue",
        "ADD_AS_NEW": "Ajouter en tant que nouvelle zone",
        "DRAG_TO_CHANGE": "Faites-moi glisser pour changer de position",
        "FILTER_LOCATIONS": "Zones de filtrage..."
      }
    },
    "SHIFT_TYPES": {
      "EDIT_SHIFT_TYPES_MODAL": {
        "TITLE_NEW": "Nouveau type de quart de travail",
        "TITLE_EDIT": "Modifier le type de quart de travail",
        "SITE_LABEL": "Site",
        "HIERARCHY_LABEL": "Hiérarchie",
        "MASTER_TYPE_LABEL": "Type de poste principal",
        "SUB_TYPE_LABEL": "Type de sous-équipe",
        "SUB_TYPE_HELP": "Rotaready vous permet de créer une hiérarchie des types de quarts de travail. Un type de sous-équipe peut être placé sous n'importe quel autre type de quart de travail.",
        "PARENT_TYPE_LABEL": "Type de changement de poste de parent",
        "NAME_LABEL": "Nom",
        "COLOUR_LABEL": "Couleur",
        "COST_STREAM_MAPPINGS_LABEL": "Mappages de flux",
        "COST_STREAM_TABLE": {
          "COST_STREAM_HEADING": "Diffusez",
          "ATTRIBUTED_HEADING": "attribué",
          "EMPTY_LABEL": "Cela n'est associé à aucun flux.",
          "ADD_BUTTON": "Ajouter un nouveau"
        },
        "DELETE_BUTTON": "Supprimer",
        "SAVE_BUTTON": "Enregistrer",
        "CANCEL_BUTTON": "Annuler",
        "VALIDATION": {
          "NAME_REQUIRED": "Assurez-vous d'avoir fourni un nom valide pour le type de quart de travail.",
          "PARENT_REQUIRED": "Pour qu'il s'agisse d'un sous-type d'équipe, vous devez sélectionner un type de quart de travail parent.",
          "PARENT_VALID": "Veuillez sélectionner un type de changement de poste parental valide.",
          "PARENT_SAME_SITE": "Ce type de quart de travail et le type de quart de travail que vous avez choisi pour le parent doivent appartenir au même site (ou tous deux appartenir à l'ensemble de l'organisation).",
          "ATTRIBUTION_OVERAGE": "Nous avons résumé le pourcentage attribué à chaque flux et le total dépasse 100 %. Vous ne pouvez attribuer qu'un maximum de 100 % à un ou plusieurs flux.",
          "ATTRIBUTION_VALID": "Vous avez saisi un « pourcentage attribué » non valide pour un ou plusieurs flux. Une valeur de « 50 », par exemple, attribuera 50 % du coût salarial de toute personne travaillant ce type de poste au flux correspondant.",
          "ATTRIBUTION_NONE": "Vous devez attribuer le type de décalage à au moins un flux.",
          "DUPLICATE_COST_STREAM_MAPPING": "Une cartographie de ce flux existe déjà ! Vous ne pouvez pas attribuer deux fois un type de décalage au même flux."
        },
        "ERRORS": {
          "ADD_ERROR": "Nous n'avons pas pu ajouter votre type de quart de travail à ce moment-là. Il est probable qu'un type de poste portant le même nom existe déjà ! Essayez de choisir un autre nom.",
          "UPDATE_ERROR": "Nous n'avons pas pu mettre à jour le type de quart de travail à ce moment-là. Il est probable qu'un type de poste portant le même nom existe déjà ! Essayez de choisir un autre nom.",
          "DELETE_TYPE": "Nous n'avons pas pu supprimer le type de quart de travail à ce moment-là. Veuillez réessayer !"
        },
        "CONFIRM": {
          "DELETE_SUB_TYPES": "Ce type de quart de travail comporte toujours des sous-types de quarts de travail. Ils seront également supprimés. Êtes-vous sûr de vouloir continuer ?",
          "DELETE_TYPE": "Êtes-vous sûr de vouloir supprimer ce type de quart de travail ?"
        }
      },
      "INDEX": {
        "TITLE": "Types de quarts de travail",
        "SUBTITLE": "Considérez les types de quarts de travail comme des catégories correspondant aux différents types de travail que les employés accomplissent dans votre organisation :",
        "SUB_TYPE_HELP": "Vous pouvez ajouter un « type de sous-équipe » en cliquant sur l'icône plus à côté de n'importe quel type de quart de travail. Ceci est utile si vous devez diviser un type de quart de travail en tâches ou rôles plus spécifiques.",
        "TYPES_EMPTY": "Il n'y a aucun type de quart de travail à afficher.",
        "ONLY_ENTITY": "seulement",
        "WHOLE_ORGANISATION": "À l'échelle de l'organisation",
        "SHOW_MORE": "Afficher plus...",
        "ERRORS": {
          "LOAD_ERROR": "Oups, nous n'avons pas pu charger les types de quarts de travail à ce moment-là. Veuillez réessayer !"
        },
        "TOOLTIPS": {
          "ADD_SUB_SHIFT_TYPE": "Ajouter un type de sous-poste"
        },
        "ADD_SHIFT_TYPE": "Ajouter un type de quart de travail"
      }
    },
    "TEMPLATES": {
      "EDIT_CONSTRAINTS_MODAL": {
        "ADD_TITLE": "Ajouter un nouveau {{currentLevelName}}",
        "EDIT_TITLE": "Modifier les contraintes {{CurrentLevelName}}",
        "NAME_LABEL": "nom",
        "LOADING": {
          "SAVE": "Économiser..."
        },
        "SAVE_AND_CLOSE_BUTTON": "Enregistrer et fermer",
        "CANCEL_BUTTON": "Annuler",
        "OVERRIDE_CONSTRAINT_BUTTON": "Remplacer la contrainte {{levelName}}",
        "DELETE_OVERRIDE_BUTTON": "Supprimer le remplacement",
        "TAB_HEADINGS": {
          "POPULAR": "Populaire",
          "STAFF": "Le personnel",
          "REST": "Repos",
          "OTHERS": "Autres"
        },
        "STAFF_SELECTION": {
          "TITLE": "Sélection du personnel",
          "FLEXIBLE_STAFFING_LABEL": "Dotation flexible",
          "FAIRLY_HINT": "Choisissez équitablement parmi tous les membres du personnel",
          "FAIRLY_ANY_TAGS_HINT": "Choisissez équitablement parmi ceux qui correspondent <strong>à</strong> l'une des balises ci-dessous",
          "FAIRLY_ALL_TAGS_HINT": "Choisissez équitablement parmi ceux qui correspondent à <strong>tous</strong> les tags ci-dessous",
          "POOLING_HINT": "Traitez les tags ci-dessous comme des pools de personnel et épuisez chacun d'entre eux <strong>dans l'ordre</strong>",
          "FIXED_STAFFING_LABEL": "Dotation fixe",
          "SPECIFIC_STAFF_MEMBER_HINT": "Attribuer le ou les quarts de travail à un membre du personnel en particulier",
          "ASSIGNEE_LABEL": "Cessionnaire",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tapez pour ajouter des balises"
          }
        },
        "PREFERRED_STAFF_SELECTION": {
          "TITLE": "Sélection préférée du personnel",
          "HINT": "Si plusieurs membres du personnel sont éligibles pour un poste donné, Rotaready tentera de biaiser sa sélection en fonction des critères choisis :",
          "NO_PREFERENCE": "Ne pas préférer un membre du personnel aux autres, sauf pour des raisons d'équité",
          "PREFER_ANY_TAG": "Préférez ceux qui correspondent <strong>à</strong> l'une des balises ci-dessous",
          "PREFER_ALL_TAG": "Préférez ceux qui correspondent à <strong>tous</strong> les tags ci-dessous",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tapez pour ajouter des balises"
          }
        },
        "STAFF_SELECTION_GROUPS": {
          "TITLE": "Sélection du personnel (groupes)",
          "HINT": "Cela vous permet de limiter la sélection du personnel aux membres de l'un des groupes ci-dessous. Les groupes seront dotés de ressources dans l'ordre dans lequel ils ont été spécifiés :",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tapez pour ajouter des balises"
          }
        },
        "STAFF_TYPECASTING": {
          "TITLE": "Désignation du personnel",
          "HINT": "<em>Lorsque nous affectons une personne à un poste <em>de travail classé</em>, il ne peut lui être attribué que d'autres quarts de travail ce jour-là qui sont a) également <em>typés avec au</em> moins l'une de ces balises, ou b) pas du tout classés :</em>",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tapez pour ajouter des balises"
          }
        },
        "OUTSOURCING": {
          "TITLE": "Externalisation",
          "HINT": "Si aucun personnel interne n'est disponible, autorisez l'externalisation vers les organisations suivantes, dans l'ordre dans lequel elles sont spécifiées :",
          "PLACEHOLDERS": {
            "TYPE_OUTSOURCE_ORGANISATIONS": "Tapez pour ajouter des organisations d'externalisation"
          }
        },
        "SELECTION_CONSISTENCY": {
          "TITLE": "Cohérence de sélection",
          "HINT": "Pour les équipes qui s'étendent sur plusieurs jours au cours d'une semaine donnée, lorsqu'une personne est affectée au quart de travail du premier jour, elle doit :",
          "NO_EFFECT": "n'ont aucune incidence sur le fait qu'ils soient sélectionnés pour les quarts de travail d'un autre jour",
          "ALLOCATE": "être affecté à tous les autres quarts de travail du groupe de travail, dans la mesure du possible"
        },
        "REST_PERIOD": {
          "TITLE": "Période de repos",
          "HINT": "Après un quart de travail, veillez à ce que le membre du personnel soit reposé (et qu'il ne lui soit donc pas confié un autre quart de travail) pendant au moins :"
        },
        "LOCATION_TOLERANCE": {
          "TITLE": "Tolérance de localisation",
          "HINT": "Il est souvent important que deux équipes consécutives ayant le même lieu de travail soient attribuées au même membre du personnel. Spécifiez le nombre maximum de minutes entre deux quarts de travail (de la fin du premier au début du second) pour que nous puissions les considérer comme consécutifs à cet égard :",
          "MINUTES": "minutes",
          "PLACEHOLDERS": {
            "MINUTES": "Minutes entre la fin d'un quart de travail et le début d'un autre"
          }
        },
        "DAILY_WINDOW_DEFINITION": {
          "TITLE": "Définition quotidienne de la fenêtre",
          "HINT": "Dans certaines organisations, leur personnel travaille plusieurs « équipes » au cours d'une journée donnée, et pour chaque personne, cette charge de travail quotidienne doit être respectée dans un certain laps de temps. Ces fenêtres temporelles peuvent varier et vous pouvez définir toutes les variations possibles ici :",
          "LIMITED_TIMES": "Nous avons un ensemble limité et fixe d'heures de début et de fin pour nos fenêtres",
          "REGULAR_INTERVALS": "Nos fenêtres tombent à intervalles réguliers après une heure de début donnée"
        },
        "TIMES": {
          "WINDOW_TIMES_TITLE": "Horaires des fenêtres",
          "SPLIT_SHIFT_WINDOW_TIMES": "Horaires de travail en équipes fractionnées",
          "WINDOW_START_TIME": "Heure de début de la première fenêtre",
          "WINDOW_INTERVAL": "Intervalle entre les fenêtres valides",
          "WINDOW_FINISH_TIME": "Heure de fin de la dernière fenêtre possible",
          "PLACEHOLDERS": {
            "COMMA_SEPERATED_TIMES": "Liste des heures séparées par des virgules, c'est-à-dire 9:30-17:30, 13:00-20:00"
          }
        },
        "SHIFT_PAY_RATE": {
          "TITLE": "Taux de rémunération par équipes",
          "HINT": "Les équipes de travail non salariées générées à partir de ce {{currentLevelName}} {CurrentLevelName}} seront rémunérées à leur taux horaire habituel. Vous pouvez modifier cette option ci-dessous si nécessaire :",
          "OPTIONS": {
            "NORMAL": "Normal : le personnel gagne à son taux horaire habituel",
            "ZERO": "Zéro : le personnel n'est pas payé pour ce quart de travail",
            "MULTIPLE": "Multiple : les employés gagnent un multiple de leur taux horaire habituel",
            "FIXED": "Fixe : remplacez leur taux horaire habituel par un taux fixe"
          },
          "MULTIPLIER": "multiplicateur de taux",
          "HOURLY_RATE_OVERRIDE": "DéroGATION au taux horaire",
          "PLACEHOLDERS": {
            "MULTIPLIER": "Multiplicateur de taux horaire",
            "HOURLY_RATE_OVERRIDE": "Taux horaire en monnaie locale, soit 8,50"
          }
        },
        "SHIFT_TYPE": {
          "TITLE": "Type de quart de travail",
          "HINT": "Cela indique le « type » de changements qui seront générés à partir de ce {{CurrentLevelName}}. Votre personnel y verra le titre de l'équipe, et si vous utilisez les fonctionnalités de contrôle des coûts de Rotaready, chaque type de quart de travail peut être associé à un ou plusieurs flux de coûts."
        },
        "SITE_DEPARTMENT": {
          "TITLE": "Site/département",
          "HINT": "Les changements d'attributs générés à partir de ce {{currentLevelName}} Ceci est utile pour générer des changements de couverture.",
          "ATTRIBUTE_TO_SITE": "Attribuez le coût salarial et les heures au site choisi"
        }
      },
      "EDIT_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Ajouter un set de travail",
        "ADD_SUBTITLE": "Fournissez des détails sur cet ensemble de quarts de travail",
        "EDIT_TITLE": "Modifier l'ensemble de quarts de travail",
        "EDIT_SUBTITLE": "Modifier les détails de cet ensemble de quarts de travail",
        "TIP": "Le planificateur automatique de Rotaready générera des quarts de travail à partir de cet ensemble de quarts de travail et répartira le personnel en conséquence, de manière équitable et économique.",
        "TIME_LABEL": "Heure de début/de fin",
        "TO": "pour",
        "OVERFLOW_WARNING": "Ce quart de travail se prolongera le lendemain.",
        "LOCATION_LABEL": "Zone de fréquentation",
        "MINIMUM_LABEL": "Au moins",
        "MINIMUM_EXPLANATION": "Je veux un minimum de personnel par jour, mais plus si possible",
        "STAFF_NUMBER_LABEL": "Exactement",
        "STAFF_NUMBER_EXPLANATION": "Je veux un nombre exact de membres du personnel par jour",
        "MAXIMUM_LABEL": "Jusqu'à",
        "MAXIMUM_EXPLANATION": "Je veux autant d'employés que possible par jour, dans la limite maximale",
        "SHORT_DAYS": {
          "MONDAY": "Lun",
          "TUESDAY": "Tue",
          "WEDNESDAY": "mer",
          "THURSDAY": "jeu",
          "FRIDAY": "Ven",
          "SATURDAY": "samedi",
          "SUNDAY": "Soleil"
        },
        "SAVE_BUTTON": "Enregistrer l'ensemble de quarts de travail",
        "CANCEL_BUTTON": "Annuler",
        "LOADING": {
          "SAVE": "Économiser..."
        },
        "ERRORS": {
          "UNABLE_TO_ADD": "Impossible d'ajouter cet ensemble de quarts de travail pour le moment. Veuillez réessayer."
        }
      },
      "EDIT_TEMPLATE": {
        "LOADING": {
          "SAVE": "Économiser..."
        },
        "EDIT_CONSTRAINTS": "Modifier les contraintes",
        "CONSTRAINTS_LABEL": "Contraintes relatives aux modèles",
        "CONSTRAINTS_TEXT": "Définissez des contraintes qui s'appliqueront à l'ensemble du modèle.",
        "PLACEHOLDERS": {
          "SEARCH_FILTER": "Filtre..."
        },
        "ADD_GROUP_BUTTON": "Ajouter un groupe",
        "TAB_HEADINGS": {
          "FLEXIBLE_STAFFING": "Dotation flexible",
          "FIXED_STAFFING": "Dotation fixe"
        },
        "TOOLTIPS": {
          "ACTIVE_CONSTRAINTS": "Contraintes actives",
          "DISABLE": "Désactiver",
          "ENABLE": "Activer"
        },
        "GROUP_OPTIONS_BUTTON": {
          "LABEL": "Options de groupe",
          "ADD_TASK": "Ajouter une tâche",
          "ADD_SHIFT_SET": "Ajouter un set de travail",
          "EDIT_CONSTRAINTS": "Modifier les contraintes",
          "DELETE_GROUP": "Supprimer le groupe"
        },
        "HOURS": "heures",
        "SHORT_DAYS": {
          "MONDAY": "Lun",
          "TUESDAY": "Tue",
          "WEDNESDAY": "mer",
          "THURSDAY": "jeu",
          "FRIDAY": "Ven",
          "SATURDAY": "samedi",
          "SUNDAY": "Soleil"
        },
        "CHAR_DAYS": {
          "MONDAY": "M",
          "TUESDAY": "T",
          "WEDNESDAY": "W",
          "THURSDAY": "T",
          "FRIDAY": "F",
          "SATURDAY": "S",
          "SUNDAY": "S"
        },
        "NO_SHIFT_SETS": "Il n'y a aucun groupe de travail dans ce groupe. Ajoutez-en un à l'aide du bouton « Options du groupe ».",
        "OPTIONS_BUTTON": {
          "LABEL": "Options",
          "ADD_SHIFT_SET": "Ajouter un set de travail",
          "EDIT_CONSTRAINTS": "Modifier les contraintes",
          "DELETE_TASK": "Supprimer la tâche"
        },
        "NO_FLEXIBLE_STAFF_GROUPS_THIS_WEEK": "Vous n'avez pas de groupes de personnel flexibles. Pourquoi ne pas en ajouter un en cliquant sur « Ajouter un groupe » ci-dessus ?",
        "NO_FLEXIBLE_STAFF_GROUPS": "Aucun groupe de personnel flexible ne correspond à ce que vous recherchez.",
        "GROUP_SHIFT_SETS_TABLE": {
          "HEADINGS": {
            "SHIFT_TYPE": "Type de quart de travail",
            "TIME": "Heure",
            "DAYS_OF_WEEK": "Jours de la semaine"
          }
        },
        "NO_FIXED_STAFF_GROUPS_THIS_WEEK": "Vous n'avez pas de groupes d'effectifs fixes. Pourquoi ne pas en ajouter un en cliquant sur « Ajouter un groupe » ci-dessus ?",
        "NO_FIXED_STAFF_GROUPS": "Aucun groupe de personnel fixe ne correspond à ce que vous recherchez.",
        "EDIT_BUTTON": "Modifier",
        "TOGGLE_DROPDOWN_BUTTON": "Basculer la liste déroulante",
        "DELETE_BUTTON": "Supprimer",
        "SAVE_BUTTON": "Enregistrer",
        "ERRORS": {
          "COULDNT_FIND_TEMPLATE": "Nous n'avons pas trouvé le modèle que vous recherchez. Veuillez réessayer.",
          "OOPS": "Oups, quelque chose vient de mal tourner. Il se peut que vous deviez réessayer !",
          "TEMPLATE_STATE_CHANGE": "Nous n'avons pas pu activer/désactiver une partie de votre modèle pour le moment. Veuillez réessayer."
        },
        "LEVEL_NAMES": {
          "GLOBAL": "globaux",
          "TEMPLATE": "modèle",
          "WEEK": "semaine",
          "GROUP": "groupe",
          "TASK": "tâche",
          "SHIFT_SET": "set de vitesses"
        },
        "CONFIRM": {
          "DELETE": "Êtes-vous sûr de vouloir le supprimer ?",
          "DELETE_SHIFT_SET": "Êtes-vous sûr de vouloir supprimer cet ensemble de quarts de travail ?"
        }
      },
      "FIXED_STAFFING_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Ajouter un set de travail",
        "ADD_SUBTITLE": "Fournissez des détails sur cet ensemble de quarts de travail",
        "EDIT_TITLE": "Modifier l'ensemble de quarts de travail",
        "EDIT_SUBTITLE": "Modifier les détails de cet ensemble de quarts de travail",
        "START_END_LABEL": "Heure de début/de fin",
        "SHIFT_OVERFLOW_WARNING": "Ce quart de travail se prolongera le lendemain.",
        "SHIFT_TYPE_LABEL": "Type de quart de travail",
        "LOCATION_LABEL": "Zone de fréquentation",
        "SHORT_DAYS": {
          "MONDAY": "Lun",
          "TUESDAY": "Tue",
          "WEDNESDAY": "mer",
          "THURSDAY": "jeu",
          "FRIDAY": "Ven",
          "SATURDAY": "samedi",
          "SUNDAY": "Soleil"
        },
        "SAVE_BUTTON": "Enregistrer l'ensemble de quarts de travail",
        "CANCEL_BUTTON": "Annuler",
        "LOADING": {
          "SAVE": "Économiser..."
        },
        "UNKNOWN_SHIFT_TYPE": "Type de quart de travail inconnu",
        "ERRORS": {
          "UNABLE_ADD": "Oups, nous ne sommes pas en mesure d'ajouter cet ensemble de quarts de travail pour le moment. Réessayez, s'il vous plaît.",
          "UNABLE_UPDATE": "Oups, nous ne sommes pas en mesure de mettre à jour cet ensemble de quarts de travail pour le moment. Veuillez réessayer."
        }
      },
      "INDEX": {
        "NEW_BUTTON": "Ajouter un nouveau",
        "ALERT_LINK_WARNING": "Les modèles ont été déplacés vers le menu Paramètres. Le lien existant dans le menu Rotas disparaîtra bientôt 👀",
        "TITLE": "Modèles de rotation",
        "SEARCH_FILTER": "Rechercher...",
        "TEMPLATE_TABLE": {
          "NAME_HEADING": "Nom",
          "CREATED_BY_HEADING": "Créé par",
          "LAST_EDITED_HEADING": "Dernière modification",
          "OPTIONS_HEADING": "Options"
        },
        "NO_TEMPLATES": "Il n'y a aucun modèle de rotation à afficher.",
        "CLONE_TEMPLATE": "Cloner",
        "DELETE_TEMPLATE": "Supprimer",
        "EDITED_ON": "sur",
        "CONFIRM": {
          "DELETE_NODE": "Êtes-vous sûr de vouloir le supprimer ?"
        },
        "SUCCESS": {
          "DELETE_NODE": "Votre prévision de demande a été supprimée avec succès."
        },
        "ERRORS": {
          "DELETE_NODE": "Oups, quelque chose vient de mal tourner. Il se peut que vous deviez réessayer !"
        }
      },
      "NEW_TEMPLATE_MODAL": {
        "TITLE": "Nouveau modèle",
        "REMINDER": "<strong>Passé !</strong> Vous possédez déjà <strong>{{TemplateCount}} template (s</strong>). Vous n'avez besoin d'en créer un nouveau que si vos exigences en matière de planification ont changé de manière significative. Sinon, vous devez en modifier un existant.",
        "TYPE_LABEL": "Tapez",
        "NEW_BLANK_TEMPLATE": "Nouveau modèle vierge",
        "CLONE_EXISTING_TEMPLATE": "Cloner un modèle existant",
        "CLONE_FROM_LABEL": "Cloner depuis",
        "NAME_LABEL": "Nom du modèle",
        "NAME_HELP": "Vous allez générer régulièrement des rotations à partir de ce modèle et un nom valide décrit à qui ou à quoi ces rotations seront destinées, comme « Rotation du personnel de support ».",
        "NAME_PLACEHOLDER": "c'est à dire. Haute saison, ou rotation de l'équipe B",
        "DEFAULT_SHIFT_TYPE_LABEL": "Type de quart de travail par défaut",
        "DEFAULT_SHIFT_TYPE_HELP": "Lorsque vous créerez votre modèle, vous pourrez modifier le type de quart de travail pour chaque groupe ou tâche. C'est très pratique pour nous de définir une valeur par défaut dès maintenant.",
        "CREATE_BUTTON": "Créez !",
        "CREATE_BUTTON_LOADING": "Veuillez patienter, cela peut prendre un moment...",
        "CANCEL_BUTTON": "Annuler",
        "CONFIRM_CLONE": "Le clonage d'un modèle peut prendre un moment ou deux, selon la taille du modèle. Êtes-vous sûr de vouloir continuer ?",
        "CREATE_ERROR": "Impossible de créer ce modèle pour le moment. Veuillez réessayer."
      },
      "DELETE_TEMPLATE_MODAL": {
        "INFO_TITLE": "Supprimer le modèle",
        "INFO_BODY": "La suppression d'un modèle est irréversible et ne peut pas être annulée. Pour nous convaincre que vous souhaitez procéder ainsi, saisissez le nom du modèle dans la case ci-dessous, puis cliquez sur Supprimer.",
        "DELETE_TEMPLATE_BUTTON": "Supprimer le modèle",
        "SUCCESS": {
          "DELETE_TEMPLATE": "Votre modèle a été supprimé avec succès."
        },
        "ERRORS": {
          "DELETE_TEMPLATE": "Oups, quelque chose vient de mal tourner. Il se peut que vous deviez réessayer !"
        }
      }
    },
    "SHIFT": {
      "EDIT_MODAL": {
        "SHIFT": "quart de travail",
        "SITE": "Site",
        "START": "Démarrer",
        "BREAK": "Pause",
        "NONE": "Aucune",
        "FINISH": "Terminer",
        "NEW_SHIFT": "Nouveau changement",
        "ATTRIBUTE_WAGE_COST_TO_SITE": " Attribuez le coût salarial et les heures au site choisi",
        "DATE_AND_TIME": "Date et heure",
        "TO": " pour ",
        "THEN_REST": " puis reposez-vous ",
        "SHIFT_TYPE": "Type de quart de travail",
        "LOCATION": "Zone de fréquentation",
        "NO_BREAK": "Pas de pause",
        "PAID_BREAK": "Pause payée",
        "UNPAID_BREAK": "Pause non rémunérée",
        "OF_LENGTH": "de longueur",
        "BREAK_MESSAGE_WARNING": "Le personnel affecté à ce quart de travail a des exigences de pause différentes. Lorsque vous aurez terminé, Rotaready enregistrera un quart de travail distinct pour chaque personne ayant le bon nombre de pauses.",
        "BREAK_MESSAGE_SUCCESS": "Nous avons automatiquement appliqué la pause correcte à ce quart de travail.",
        "PAY_RULE": "Règle de rémunération",
        "APPLIES": " s'applique",
        "FOR_THE_SCHEDULED_DURATION": "Pour la durée prévue",
        "FOR_ANY_OVERTIME": "Pour toute heure supplémentaire",
        "STAFF_SELECTION": "Sélection du personnel",
        "STAFF_SELECTION_HELP": "Modifiez le personnel assigné et utilisez des balises pour filtrer les autres personnes éligibles",
        "STAFF_MUST_MATCH": "Le personnel doit correspondre",
        "ANY_OF_THE_TAGS": "<strong>n'importe</strong> laquelle des balises",
        "ANY": "n'importe quel",
        "ALL": "tous",
        "OF_THE_TAGS": " des tags",
        "ALL_OF_THE_TAGS": "<strong>tous</strong> les tags",
        "TAG_TYPEAHEAD_PLACEHOLDER": "Tapez pour rechercher des balises",
        "STAFF_ASSIGNED": "Attribué",
        "NOBODY_IS_ASSIGNED": "Personne n'est assigné",
        "STAFF_ELIGIBLE": "Éligible",
        "STAFF_FILTER_PLACEHOLDER": "Rechercher...",
        "SHOW": "Afficher",
        "MORE": " plus...",
        "NOBODY_MATCHES_YOUR_TAGS": "Personne ne correspond à vos tags",
        "NOBODY_ELSE_IS_AVAILABLE": "Personne d'autre n'est disponible",
        "MORE_OPTIONS": "Plus d'options",
        "ADD_NOTES_AND_ADJUST_PAY_RATES": "Ajoutez des notes et ajustez les taux de rémunération",
        "DETAILS": "Détails",
        "DETAILS_TEXTAREA_PLACEHOLDER": "Ajoutez des notes, des devoirs et d'autres détails ici...",
        "ROTA": "Rota",
        "NO_ROTA": "Pas de rotation",
        "PAY_LABEL": "Payez",
        "PAY_TOGGLE": "Il s'agit d'un quart de travail rémunéré",
        "DELETE": "Supprimer",
        "VIEW_BROADCAST": "Afficher la diffusion",
        "START_BROADCAST": "Commencer la diffusion",
        "SAVE": "Enregistrer",
        "CANCEL": "Annuler"
      },
      "SHIFT_BROADCAST_MODAL": {
        "SHIFT": "quart de travail",
        "SITE": "Site",
        "START": "Démarrer",
        "BREAK": "Pause",
        "NONE": "Aucune",
        "FINISH": "Terminer",
        "ORIGINAL_PLANNED_STAFF": "Personnel initialement prévu",
        "EXTRA_STAFF_REQUIRED": "Personnel supplémentaire requis",
        "NOT_SHIFT_BROADCASTING_HELP": "Réglez sur 0 pour demander autant de membres du personnel que possible.",
        "START_BROADCASTING": "Commencer à diffuser",
        "START_BROADCASTING_LOADING": "Démarrage de la diffusion...",
        "CANCEL": "Annuler",
        "ACCEPTED": "Accepté",
        "PENDING": "En attente",
        "REJECTED": "Rejeté",
        "EVERYONE": "Tout le monde",
        "NO_PARTICIPANTS_TO_SHOW": "Il n'y a aucun participant à la diffusion à afficher.",
        "NEEDED": "Nécessaire",
        "SHIFT_BROADCASTING_HELP": "Votre diffusion est actuellement en cours. La liste ci-dessous sera mise à jour au fur et à mesure que le personnel acceptera ou rejettera le quart de travail. La diffusion se terminera automatiquement lorsque le nombre correct de membres du personnel aura accepté ou lorsque tout le monde aura répondu.",
        "STOP_BROADCASTING": "Arrêter la diffusion",
        "STOP_BROADCASTING_LOADING": "Arrêt de la diffusion...",
        "PAUSE_BROADCAST": "Suspendre la diffusion",
        "PAUSE_BROADCAST_LOADING": "Suspension de la diffusion...",
        "RESUME_BROADCAST": "Reprendre la diffusion",
        "RESUME_BROADCAST_LOADING": "Reprise de la diffusion...",
        "CLOSE": "Fermer"
      },
      "VIEW_MODAL": {
        "SHIFT": "quart de travail",
        "SITE": "Site",
        "START": "Démarrer",
        "BREAK": "Pause",
        "NONE": "Aucune",
        "FINISH": "Terminer",
        "LOCATION": "Zone de fréquentation",
        "NONE_SPECIFIED": "Aucune spécifiée",
        "REST": "Repos",
        "DETAILS": "Détails",
        "ALLOTTED_BREAK": "Pause allouée",
        "ASSIGNED_STAFF": "Personnel assigné",
        "ON": "sur",
        "SHIFT_ROUTINE": "Routine de travail",
        "LAST_UPDATED": "Dernière mise à jour",
        "GET_DIRECTIONS": "Obtenir un itinéraire",
        "SHOW_TRAFFIC": "Afficher le trafic",
        "EDIT": " Modifier",
        "CLOSE": "Fermer"
      }
    }
  },
  "DASHBOARD": {
    "TIME_AND_ATTENDANCE": "Temps et présence",
    "YOU_ARE_NOW": "Tu es maintenant",
    "ERROR_PLEASE_TRY_AGAIN": "Erreur, veuillez réessayer",
    "MY_UPCOMING_SHIFTS": "Mes prochains quarts de travail",
    "YOU_HAVE_NO_SHIFTS_TODAY": "Tu n'as pas de quarts de travail aujourd'hui.",
    "ALL_DAY": "Toute la journée",
    "AM": "AM",
    "PM": "APRÈS-MIDI",
    "TODAY_S_SCHEDULE": "Le programme du jour",
    "THERE_ARE_NO_SHIFTS_SCHEDULED_TODAY": "Aucun quart de travail n'est prévu aujourd'hui.",
    "WHATEVER": "Commençant à <strong>{{startTime}}</strong> et finissant à <strong>{{endTime</strong>}}"
  },
  "SIGN_OUT": {
    "LOGGING_OUT": "Déconnexion..."
  },
  "PARTIALS": {
    "VALIDATION": {
      "ISSUE_1": {
        "HEADING": "Journée de travail trop longue",
        "BODY": "Votre modification signifie que la journée de travail de <b>{{name}</b>} deviendrait trop longue (selon les paramètres de votre organisation). Actuellement, il commence le <b>{{start|MomentFormat : 'lll'}} SixStart:'ll'n'o et se termine le {{end|MomentFormat : 'lll'}}}</b> (puis se repose jusqu'à <b>{{EndRest|MomentFormat : 'lll'</b>}})."
      },
      "ISSUE_2": {
        "HEADING": "Journée de travail qui se chevauche",
        "BODY": "Votre modification signifie que <b>{{name}}</b> dispose d'un jour ouvrable <b>entre le {{primaryStart|MomentFormat : 'lll'<b>}</b></b>} Malheureusement, cela correspond à une journée de travail qu'ils ont déjà entre <b>{{secondaryStart|momentFormat : 'lll'}} <b>Rest|momentformat:'lt'n'}</b>)</b>."
      },
      "ISSUE_3": {
        "HEADING": "Quart de travail en dehors des jours ouvrables",
        "BODY": "<b>{{name}</b>} a un décalage programmé <b>de {{start|MomentFormat : 'LT</b><b>'</b>}}. Votre changement signifierait que ce quart de travail se situe partiellement ou totalement en dehors de la journée de travail."
      },
      "ISSUE_4": {
        "HEADING": "Non disponible",
        "BODY": "<b>{{name}}</b> <b>n'est pas disponible <b>entre {{start|MomentFormat : 'lll'}}</b></b>",
        "REASON_1": "travailler par équipes ({{EventType}} du {{EntityName}})",
        "REASON_2": "occupé pendant une journée de travail",
        "REASON_3": "absent ({{EventType}}}",
        "REASON_4": "non disponibles en raison de leur modèle de disponibilité",
        "BUTTON": "Faites une exception et réessayez",
        "BUTTON_LOADING": "Chargement en cours..."
      },
      "ISSUE_5": {
        "HEADING": "Obligation contractuelle non respectée",
        "BODY": "Votre changement signifie que <b>{{name}}</b> ne respectera pas son obligation contractuelle. Elle est maintenant fixée à <b>{{CurrentValue}} jour (s), mais elle doit être d'au moins {<b>{comparatorValue</b></b>}} jour (s)."
      },
      "ISSUE_6": {
        "HEADING": "Obligation contractuelle non respectée",
        "BODY": "Votre changement signifie que <b>{{name}}</b> ne respectera pas son obligation contractuelle. Elle est désormais fixée à <b>{{CurrentValue}} heure (s), mais elle doit être d'au moins {<b>{comparatorValue</b></b>}} heure (s)."
      },
      "ISSUE_7": {
        "HEADING": "Incompatibilité des tags",
        "BODY": "<b>{{name}} organization}</b> ne possède pas les balises requises pour effectuer ce shift. Cela signifie qu'ils peuvent ne pas avoir de compétence, de qualification ou d'autres attributs."
      },
      "ISSUE_8": {
        "HEADING": "Diffusion active",
        "BODY": "Un Shift Broadcast actif est actuellement en place pour ce changement. Comme votre personnel est peut-être en train de répondre, nous ne pouvons pas autoriser la modification du quart de travail à moins que vous n'annuliez d'abord la diffusion du quart de travail."
      },
      "ISSUE_9": {
        "HEADING": "Nombre de jours de congé insuffisant",
        "BODY": "Votre modification signifie que <b>{{name}}</b> travaillera trop de jours cette semaine. Ils auront désormais <b>{{CurrentValue}} jour (s) de congé, soit moins que leur {{comparatorValue</b><b>}}</b> jour (s) de congé requis."
      },
      "ISSUE_10": {
        "HEADING": "Membre du personnel inactif",
        "BODY": "<b>{{name}} ␞</b> ne peut pas fonctionner <b>sur {{start|MomentFormat :</b> 'll'}} Soit leur compte est inactif à cette date, soit ils ont été déplacés vers un autre site."
      },
      "ISSUE_11": {
        "HEADING": "Rota marqué pour approbation",
        "BODY": "Vous ne pouvez apporter aucune modification à cette rotation car elle a été marquée pour approbation."
      },
      "ISSUE_12": {
        "HEADING": "Période approuvée",
        "BODY": "Vous ne pouvez apporter aucune modification à ce tour de rôle car il a été approuvé."
      },
      "ISSUE_13": {
        "HEADING": "Limite de travail dépassée",
        "BODY": "Votre modification signifie que <b>{{name}</b>} fonctionnera plus longtemps que prévu. Ils travailleront désormais <b>{{CurrentValue}} jour (s), ce qui est supérieur à leur limite de {<b>{comparatorValue</b></b>}} jour (s)."
      },
      "ISSUE_14": {
        "HEADING": "Limite de travail dépassée",
        "BODY": "Votre modification signifie que <b>{{name}</b>} fonctionnera plus longtemps que prévu. Ils travailleront désormais <b>{{CurrentValue}} heure (s), soit plus que leur limite de {<b>{comparatorValue</b></b>}} heure (s)."
      },
      "ISSUE_15": {
        "HEADING": "Zone de fréquentation fermée",
        "BODY": "La zone de présence attribuée à l'équipe (<b>{{assignedLocation}}) est fermée pour le moment</b>.",
        "REASON": "Cela est dû à : {{reason}}."
      },
      "ISSUE_16": {
        "HEADING": "Taux d'occupation de la zone de fréquentation dépassé",
        "BODY": "La zone de présence attribuée à ce quart de travail peut accueillir au maximum <b>{{occupied}}</b> personne (s) à la fois. Avec ce changement, le taux d'occupation serait de {{currentOccupation}}."
      },
      "ISSUE_17": {
        "HEADING": "Trop peu de personnel dans la zone de fréquentation",
        "BODY": "<b>De {{start|MomentFormat : 'lll'}}</b> Chaque fois que quelqu'un travaille, un minimum de <b>{{comparatorValue}} (s) personne (s</b>) est requis."
      },
      "ISSUE_18": {
        "HEADING": "Membre du personnel non approuvé",
        "BODY": "{{name}} ␞ ne peut pas fonctionner <b>sur {{start|MomentFormat :</b> 'll'}} Leur compte n'a pas encore été approuvé pour la planification."
      },
      "ISSUE_19": {
        "HEADING": "Documents du personnel manquants/périmés",
        "BODY": "<b>{{name}} ␞</b> ne peut pas fonctionner <b>sur {{start|MomentFormat :</b> 'll'}} Un ou plusieurs documents requis sont manquants ou ont expiré."
      },
      "ISSUE_20": {
        "HEADING": "Durée de travail trop longue (moins de 18 ans)",
        "BODY": "Votre modification signifie que <b>{{name}} fonctionnera pendant la période {{</b><b>duration|HumaniseDuration|HumaniseDuration|HumaniseDuration|HumaniseDuration|HumaniseDuration}</b>. Comme ils ont moins de 18 ans, la limite fixée par la directive sur le temps de travail est de <b>{{limit|HumaniseDuration</b>}}."
      },
      "ISSUE_21": {
        "HEADING": "Sortez des limites",
        "BODY": "<b>Vous ne pouvez pas planifier un quart de travail pour <b>{{name}}</b> le {<b>{start|momentFormat : 'll'}}, car il fait partie d'une rotation qui commence le {{periodStart|momentFormat : 'll'}}</b> et se termine le <b>{{periodEnd|momentFormat : 'll'}} perioend|momentformat:'ll''s}</b>.</b>"
      }
    }
  },
  "NAV": {
    "HOME": "Accueil",
    "ROTAS": "Rotas",
    "STAFF": "Le personnel",
    "ATTENDANCE": "Présence",
    "REPORTS": "Rapports",
    "SETTINGS": "Réglages",
    "VIEW_ROTA": "Afficher la rotation",
    "SCHEDULE": "Mon emploi du temps",
    "ABSENCE": "Mon absence",
    "AVAILABILITY": "Mes disponibilités",
    "DOCUMENTS": "Mes documents",
    "ROTA_DASHBOARD": "Tableau de bord",
    "ROTA_ROTA_EDITOR": "Éditeur Rota",
    "ROTA_TRONC": "Tronc",
    "ROTA_EVENT_CALENDAR": "Planificateur d'événements",
    "STAFF_DIRECTORY": "Répertoire",
    "STAFF_REQUESTS": "Demandes",
    "STAFF_ABSENCES": "Calendrier des absences",
    "STAFF_EMPLOYEE_SUBMITTED_DETAILS": "Informations soumises par l'employé",
    "ATTENDANCE_OVERVIEW": "Présence",
    "ATTENDANCE_ISSUES": "Enjeux",
    "COST_CONTROL": "Contrôle des coûts",
    "COST_CONTROL_SIGN_OFF": "Contrôle des coûts et approbation",
    "COST_CONTROL_AGGREGATED_TOTALS": "Totaux agrégés",
    "ASSET_REGISTRY": "Registre des actifs",
    "WFM_DASHBOARD": "Mes tableaux de bord"
  },
  "SETTINGS": {
    "INDEX": {
      "TITLE": "Réglages",
      "PLACEHOLDER_TEXT": "👈 Sélectionnez un réglage sur la gauche pour commencer.",
      "CATEGORY_HR": "HR",
      "CATEGORY_ROTAS": "Rotas",
      "CATEGORY_ATTENDANCE": "Présence",
      "CATEGORY_INTEGRATIONS": "Intégrations",
      "ITEM_ROTA_TEMPLATES": "Modèles de rotation",
      "ITEM_TRUSTED_DEVICES": "Appareils fiables",
      "ITEM_SHIFT_LOCATIONS": "Zones de fréquentation",
      "ITEM_SHIFT_TYPES": "Types de quarts de travail",
      "ITEM_OUTSOURCING": "Externalisation",
      "ITEM_ABSENCE_TYPES": "Types d'absences",
      "ITEM_DOCUMENT_CATEGORIES": "Catégories de documents",
      "ITEM_DOCUMENT_TEMPLATES": "Modèles de documents",
      "ITEM_TAGS_GROUPS": "Tags et groupes",
      "ITEM_SHIFT_ROUTINES": "Routines de travail",
      "ITEM_REST_BREAKS": "Pauses de repos",
      "ITEM_PAY_ELEMENTS": "Éléments de rémunération",
      "ITEM_PAY_RULES": "Règles salariales",
      "ITEM_PERMISSIONS": "Autorisations",
      "ITEM_BUDGETS": "Budgets",
      "ITEM_ASSET_TYPES": "Types d'actifs",
      "ITEM_ENTITY_GROUP_SUBSCRIPTIONS": "Gestionnaire d'accès au site",
      "ITEM_ENTITY_GROUPS": "Gestionnaire de sites",
      "ITEM_POSITIONS": 'Rôles de travail',
      "ITEM_LEAVER_REASONS": 'Raisons de départ',
      "ITEM_RECRUITMENT_SOURCES": 'Sources de recrutement',
      "ITEM_LABOUR_DEPLOYMENT": "Déploiement de la main-d'œuvre",
    },
    "DOCUMENT_TEMPLATES": {
      "TITLE": "Modèles de documents",
      "FILTER_LABEL_CATEGORY": "Catégorie",
      "BUTTON_UPLOAD": "Téléverser",
      "NAME_COLUMN_LABEL": "Nom",
      "TEMPLATE_CATEGORY_COLUMN_LABEL": "Catégorie",
      "FILE_NAME_COLUMN_LABEL": "Nom du fichier",
      "FILE_SIZE_COLUMN_LABEL": "Taille du fichier",
      "DESTINATION_CATEGORY_COLUMN_LABEL": "Catégorie de destination",
      "SIGNATORIES_COLUMN_LABEL": "Signataires",
      "EDITED_COLUMN_LABEL": "Ajouté par",
      "OPTIONS_COLUMN_LABEL": "Options",
      "LINK_DELETE": "Supprimer",
      "SIGNATORIES_BOTH": "Employé, employeur",
      "SIGNATORIES_EMPLOYEE": "Employé",
      "NO_DOCS_MATCH_TEXT": "Nous n'avons trouvé aucun modèle de document correspondant à vos filtres.",
      "ERROR_NO_CATEGORIES": "Les modèles de documents ne sont pas activés pour votre organisation. Discutez avec nous pour commencer.",
      "ALERT_CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer ce modèle ? Cela ne peut pas être annulé !",
      "ERROR_500": "Nous ne pouvons pas charger de modèles de documents pour le moment. Veuillez réessayer.",
      "ERROR_403": "Vous n'êtes pas autorisé à visualiser/modifier les modèles de documents.",
      "UPLOAD_MODAL": {
        "TITLE": "Télécharger un modèle de document",
        "LABEL_NAME": "Nom",
        "HELP_TEXT_NAME": "Donnez à votre modèle un nom que vous reconnaîtrez.",
        "LABEL_CATEGORY": "Catégorie de modèle",
        "HELP_TEXT_CATEGORY": "Organisez vos modèles en sélectionnant une catégorie.",
        "LABEL_DESTINATION_CATEGORY": "Catégorie de document de destination",
        "HELP_TEXT_DESTINATION_CATEGORY": "Lorsque ce modèle est utilisé et qu'un document généré est signé, choisissez la catégorie de document dans laquelle le fichier doit être enregistré.",
        "LABEL_SIGNATORIES": "Signataires",
        "SIGNATORIES_OPTION_BOTH": "L'employé et l'employeur doivent tous deux signer.",
        "SIGNATORIES_OPTION_EMPLOYEE": "Seul l'employé doit signer.",
        "LABEL_UPLOAD": "Téléverser",
        "BUTTON_CANCEL": "Annuler",
        "BUTTON_SAVE": "Enregistrer",
        "ALERT_SUCCESS": "Votre modèle de document a été enregistré.",
        "ALERT_ERROR_500": "Une erreur s'est produite lors du chargement du modèle de document. Réessayez ou contactez-nous si ce message persiste.",
        "ALERT_ERROR_400": "Il y a quelque chose qui ne va pas avec votre modèle de document."
      }
    },
    "ABSENCE_TYPES": {
      "TITLE": "Types d'absences",
      "ERROR_500": "Nous ne pouvons pas charger les types d'absence pour le moment. Veuillez réessayer.",
      "ERROR_403": "Vous n'êtes pas autorisé à consulter/modifier les types d'absence.",
      "BUTTON_ADD_NEW": "Ajouter un nouveau",
      "BUTTON_TOGGLE_DELETED": "Inclure les fichiers supprimés",
      "COLUMN_NAME": "Nom",
      "COLUMN_BOOKABLE": "Réservable",
      "COLUMN_APPROVAL": "Approbation requise",
      "COLUMN_PAID": "Payé",
      "COLUMN_EDITED": "Edité par",
      "COLUMN_OPTIONS": "Options",
      "YES_LABEL": "Oui",
      "NO_LABEL": "Non",
      "LINK_EDIT": "Modifier",
      "LINK_DELETE": "Supprimer",
      "LINK_REINSTATE": "Réintégrer",
      "PILL_DELETED": "Supprimé",
      "ALERT_CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer ce type d'absence ?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Votre type d'absence a été enregistré.",
        "ALERT_ERROR_500": "Une erreur s'est produite lors de la création de votre type d'absence. Réessayez ou contactez-nous si ce message persiste.",
        "ALERT_ERROR_400": "Il existe déjà un type d'absence portant ce nom. Veuillez en choisir un autre.",
        "ADD_TITLE": "Nouveau type d'absence",
        "EDIT_TITLE": "Modifier le type d'absence",
        "BUTTON_SAVE": "Enregistrer",
        "BUTTON_CANCEL": "Annuler",
        "LABEL_NAME": "Nom",
        "LABEL_COLOUR": "Couleur",
        "LABEL_BOOKABLE": "Réservable",
        "LABEL_BOOKABLE_HELP": "Autorisez les employés à faire des demandes d'absence de ce type.",
        "LABEL_APPROVAL": "Approbation",
        "LABEL_APPROVAL_HELP": "Les demandes de ce type nécessitent une approbation.",
        "LABEL_PAID": "Payé",
        "LABEL_PAID_HELP": "Les absences de ce type sont rémunérées.",
        "LABEL_WORKED_HOURS": "Heures travaillées",
        "LABEL_WORKED_HOURS_HELP": "Les absences de ce type contribuent aux « heures travaillées ».",
        "LABEL_ACCRUES": "Congés cumulés",
        "LABEL_ACCRUES_HELP": "Les employés accumuleront des congés pour ce type d'absence s'ils disposent d'une indemnité de vacances calculée sur la base de la régularisation.",
        "LABEL_OVERLAPS_SHIFTS": "Match de travail",
        "LABEL_OVERLAPS_SHIFTS_HELP": "Une absence de ce type peut être compensée par des quarts de travail, au lieu d'un chronométtage et d'un chronométtage.",
        "LABEL_TRONC": "Tronc",
        "LABEL_TRONC_HELP": "Ce type d'absence contribue à la durée du travail à Tronc.",
        "LABEL_ALLOWANCE_REQUIRED": "Allocation requise",
        "LABEL_ALLOWANCE_REQUIRED_HELP": "Pour demander une absence de ce type, assurez-vous que l'employé dispose d'une indemnité active.",
        "TITLE_FILE_UPLOADS": "Chargements de fichiers",
        "LABEL_SUBMISSION_UPLOAD": "Sur demande",
        "LABEL_SUBMISSION_UPLOAD_HELP": "Autoriser la mise en pièce jointe d'un fichier lorsqu'une absence de ce type est demandée.",
        "LABEL_REVIEW_UPLOAD": "En cours de révision",
        "LABEL_REVIEW_UPLOAD_HELP": "Autoriser la saisie d'un fichier lorsqu'une absence de ce type est approuvée ou rejetée.",
        "LABEL_SAVE_TO": "Enregistrer dans",
        "HELP_TEXT_SAVE_TO": "Lorsqu'un dossier est joint à une absence de ce type, il sera enregistré dans les documents du salarié sous la catégorie choisie.",
        "FILE_UPLOAD_OPTION_NONE": "Désactivé",
        "FILE_UPLOAD_OPTION_OPTIONAL": "Facultatif",
        "FILE_UPLOAD_OPTION_MANDATORY": "Nécessaire",
        "INFO_LOCKED": "Il s'agit d'un type d'absence spécial utilisé par Rotaready. Nous avons masqué certaines propriétés qui ne peuvent pas être modifiées. N'hésitez pas à nous contacter si vous avez besoin d'aide concernant ce type d'absence.",
        "TITLE_PAY": "Payez",
        "LABEL_FIXED_AMOUNTS": "Montants fixes",
        "HELP_TEXT_FIXED_AMOUNTS": "Lorsque vous payez un montant fixe à un employé, tel que le paiement pour les absences accumulées mais non prises, cet élément de rémunération sera utilisé.",
        "TITLE_REASONS": "Motifs",
        "COLUMN_TITLE": "Motif",
        "COLUMN_OPTIONS": "Options",
        "LINK_DELETE": "Supprimer",
        "NO_REASONS": "Aucune raison n'a été ajoutée.",
        "PLACEHOLDER_REASON": "Nouvelle raison...",
        "LINK_ADD": "Ajouter"
      }
    },
    "OUTSOURCING": {
      "TITLE": "Externalisation",
      "BUTTON_ADD_NEW": "Ajouter un nouveau",
      "BUTTON_TOGGLE_DELETED": "Inclure les fichiers supprimés",
      "NO_DATA_TEXT": "Il n'y a aucune organisation d'externalisation à montrer.",
      "NAME_COLUMN_TEXT": "Nom",
      "ENTITY_COLUMN_TEXT": "Site/département",
      "RESOURCES_COLUMN_TEXT": "Ressources",
      "HOURS_COLUMN_TEXT": "Heures disponibles",
      "COST_COLUMN_TEXT": "Coût",
      "LAST_EDITED_COLUMN_TEXT": "Edité par",
      "OPTIONS_COLUMN_TEXT": "Options",
      "UNLIMITED_TEXT": "Illimité",
      "ALWAYS_24_TEXT": "Toujours (24 heures)",
      "PER_HOUR_TEXT": "/heure",
      "PER_DAY_TEXT": "/jour",
      "ORG_WIDE_TEXT": "À l'échelle de l'organisation",
      "LINK_EDIT": "Modifier",
      "LINK_DELETE": "Supprimer",
      "LINK_REINSTATE": "Réintégrer",
      "PILL_DELETED": "Supprimé",
      "ERROR_500": "Nous ne pouvons pas charger les organisations d'externalisation pour le moment. Veuillez réessayer.",
      "ERROR_403": "Vous n'êtes pas autorisé à consulter/modifier les organisations d'externalisation.",
      "ALERT_CONFIRM_DELETE": "Êtes-vous sûr de vouloir supprimer cette organisation d'externalisation ?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Votre organisation d'externalisation a été enregistrée.",
        "ALERT_ERROR_500": "Une erreur s'est produite lors de la création de votre organisation d'externalisation. Réessayez ou contactez-nous si ce message persiste.",
        "ALERT_ERROR_400": "Il existe déjà une organisation d'externalisation portant ce nom. Veuillez en choisir un autre.",
        "EDIT_TITLE": "Modifier l'organisation",
        "ADD_TITLE": "Ajouter une organisation",
        "NAME_LABEL": "Nom",
        "ENTITY_LABEL": "Site/département",
        "PAY_LABEL": "Payez",
        "HOURLY_OPTION": "Payé à l'heure",
        "FIXED_OPTION": "Coût fixe par jour",
        "HOURLY_RATE_LABEL": "Taux horaire",
        "DAILY_RATE_LABEL": "Tarif journalier",
        "AUTOMATIC_SCHEDULE_TITLE": "Planification automatisée",
        "HOURS_AVAILABLE_LABEL": "Heures disponibles",
        "TO_TEXT": " pour ",
        "HOURS_AVAILABLE_HELP": "Le temps pendant lequel les travailleurs de cette organisation sont disponibles",
        "RESOURCES_AVAILABLE_LABEL": "Ressources disponibles",
        "RESOURCES_AVAILABLE_HELP": "Le nombre maximum de travailleurs à votre disposition par jour (entrez 0 pour un nombre illimité)",
        "TAGS_LABEL": "Balises",
        "TAGS_PLACEHOLDER": "Tapez pour rechercher des balises",
        "TAGS_HELP": "Attribuez des tags comme vous le feriez à un membre normal du personnel. Elles s'appliqueront à tous les travailleurs de cette organisation.",
        "BUTTON_SAVE": "Enregistrer",
        "BUTTON_CANCEL": "Annuler"
      }
    },
    "TRUSTED_DEVICES": {
      "TITLE": "Appareils fiables",
      "DEVICE": "Appareil",
      "ENTITY": "Site/département principal",
      "DEVICE_TYPE": "Agent utilisateur",
      "EDITED_BY": "Edité par",
      "LAST_ACTIVITY": "Dernière activité",
      "STATUS": "État",
      "OPTIONS": "Options",
      "NO_DEVICES": "Il n'existe aucun appareil fiable pour enregistrer les présences.",
      "NO_ACTIVITY": "Aucune activité",
      "LINK_UNTRUST": "Méfiance",
      "LINK_TRUST": "Confiance",
      "LINK_EDIT": "Modifier",
      "ALERT_CONFIRM_TRUST": "Êtes-vous sûr de vouloir faire confiance à cet appareil pour la saisie des présences ?",
      "ALERT_CONFIRM_UNTRUST": "Voulez-vous vraiment vous méfier de cet appareil afin qu'il ne puisse plus être utilisé pour la saisie des présences ? Cela ne peut pas être inversé.",
      "ERROR_500": "Nous ne pouvons pas charger des appareils fiables pour le moment. Veuillez réessayer.",
      "ERROR_403": "Vous n'êtes pas autorisé à visualiser/modifier les appareils fiables.",
      "STATUS_PENDING": "En attente",
      "STATUS_TRUSTED": "Fiable",
      "STATUS_UNTRUSTED": "Non fiable",
      "FILTER_SITE": "Site/département cartographié",
      "FILTER_STATUS": "État",
      "BUTTON_NEW_DEVICE": "Nouvel appareil",
      "NEW_DEVICE_MODAL": {
        "TITLE": "Ajouter un nouvel appareil",
        "BUTTON_CANCEL": "Annuler",
        "BUTTON_DONE": "Continuer",
        "TEXT_STEP_1": "Étape 1",
        "TEXT_STEP_2": "Étape 2",
        "TEXT_STEP_1_BODY": "Prenez votre tablette ou votre ordinateur portable, ouvrez un navigateur Web et accédez à l'URL suivante :",
        "TEXT_STEP_2_BODY_A": "Lorsque vous êtes invité à saisir un « ID de domaine », entrez la valeur suivante :",
        "TEXT_STEP_2_BODY_B": "Lorsque vous êtes invité à saisir un « ID d'entité », entrez la valeur suivante :",
        "TEXT_STEP_3_BODY": "Lorsque vous avez terminé, cliquez sur Continuer pour revenir à la liste des appareils. Votre nouvel appareil devrait alors apparaître. Cliquez sur Trust à côté de l'appareil pour terminer le processus de couplage."
      },
      "EDIT_DEVICE_MODAL": {
        "TITLE": "Modifier l'appareil",
        "BUTTON_CANCEL": "Annuler",
        "BUTTON_SAVE": "Enregistrer",
        "BODY": "En sélectionnant d'autres sites ou départements ci-dessous, les employés associés apparaîtront sur l'écran d'accueil de l'appareil. Vous ne pouvez pas désélectionner le site ou le service principal ; vous devez vous méfier de l'appareil si vous souhaitez modifier cela.",
        "ALERT_200": "L'appareil a été mis à jour.",
        "ALERT_500": "Nous n'avons pas pu mettre à jour l'appareil à ce moment-là. Veuillez réessayer ultérieurement.",
        "LABEL_NAME": "Nom de l'appareil"
      }
    }
  },
  "MESSAGING": {
    "BETA": "BÊTA",
    "MESSAGE_HUB": {
      "TITLE": "Messages",
      "NO_MESSAGES": "Aucun message",
      "START_NEW": "Lancer une nouvelle conversation",
      "YESTERDAY": "hier"
    },
    "CONVERSATION": {
      "MESSAGE_PLACEHOLDER": "Ajoutez votre message",
      "INDIVIDUAL_TYPING": "{{name}} organization} est en train de taper...",
      "MULTIPLE_TYPING": "Plusieurs personnes sont en train de taper..."
    },
    "START_NEW": {
      "TITLE": "Lancer une nouvelle conversation",
      "TO": "À :",
      "REMOVE": "Supprimer",
      "START_CONVERSATION": "Commencer une conversation"
    },
    "ALERTS": {
      "START_CONVERSATION": "Une erreur s'est produite lors du démarrage de votre conversation. Veuillez réessayer.",
      "SUBSCRIBE_TO_CHANNELS": "Un problème s'est produit lors de l'inscription à vos conversations. Il est possible que vous ne receviez aucun message. Veuillez actualiser ou nous contacter si cela persiste.",
      "FETCH_MESSAGES": "Un problème s'est produit lors de la réception de vos messages. Veuillez actualiser la page ou nous contacter si cela persiste.",
      "FETCH_NEW_MESSAGES": "Une erreur s'est produite lors de la réception de nouveaux messages. Veuillez actualiser ou nous contacter si cela persiste.",
      "FETCH_CONVERSATIONS": "Une erreur s'est produite lors de la récupération de vos conversations. Veuillez actualiser ou nous contacter si cela persiste.",
      "SEND_MESSAGE": "Une erreur s'est produite lors de l'envoi de votre message. Réessayez, s'il vous plaît."
    }
  },
  "ABSENCE": {
    "REQUEST": {
      "TITLE": "Demande d'absence",
      "BUTTON_SUBMIT": "Soumettre",
      "LABEL_TYPE": "Choisissez un type",
      "LABEL_REASON": "Sélectionnez une raison",
      "LABEL_EMPLOYEE": "Employé",
      "LABEL_START_DATE": "Date de début",
      "LABEL_END_DATE": "Date de fin",
      "LABEL_SCOPE_ALL_DAY": "Toute la journée",
      "LABEL_SCOPE_AM": "AM uniquement",
      "LABEL_SCOPE_PM": "PM uniquement",
      "LABEL_SCOPE_CUSTOM": "Choisissez des heures spécifiques",
      "LABEL_START_TIME": "Heure de début",
      "LABEL_END_TIME": "Heure de fin",
      "ALERT_OVERLAPPING_ABSENCE_USER": "Vous avez une autre réservation d'absence qui coïncide avec la ou les dates que vous avez choisies.",
      "ALERT_OVERLAPPING_ABSENCE_OTHERS": "Juste pour que tu saches, d'autres personnes sont également absentes en ce moment.",
      "ERROR_500": "Nous avons rencontré un problème à ce moment-là. Veuillez réessayer !",
      "LABEL_DAYS": "jours",
      "LABEL_HOURS": "heures",
      "SECTION_UNPAID_DAYS": "Jours de congé réguliers",
      "HELP_TEXT_NO_UNPAID_DAYS": "Il n'y a pas de jours de congé réguliers pour cette demande.",
      "BUTTON_OPTIONS": "Options",
      "BUTTON_CHANGE": "Modifier les jours de congé habituels",
      "BUTTON_RESET": "Rétablir les jours de congé habituels aux jours de congé suggérés",
      "BUTTON_CLEAR": "Éliminez tous les jours de congé habituels",
      "MODAL_CALENDAR_TITLE": "Choisissez des jours de congé réguliers",
      "MODAL_BUTTON_SAVE": "Enregistrer",
      "LABEL_ADD_A_NOTE": "Ajouter une note à cette demande",
      "BUTTON_COMPLETE": "Ramène-moi",
      "COMPLETE_TITLE": "Tu es prête.",
      "COMPLETE_LABEL_APPROVED": "Approuvé",
      "COMPLETE_TEXT_APPROVED": "Votre demande a été envoyée et approuvée automatiquement ✅",
      "COMPLETE_LABEL_PENDING": "En attente d'approbation",
      "COMPLETE_TEXT_PENDING": "Votre demande a été envoyée et est en attente d'approbation. Nous vous informerons lorsque cela changera 👍"
    },
    "VALIDATION_MODAL": {
      "TITLE": "Quelque chose ne va pas",
      "DESCRIPTION": "Malheureusement, ce que vous essayez de faire entraîne un ou plusieurs problèmes.",
      "BUTTON_CLOSE": "Fermer",
      "INVALID_DATES_TITLE": "Dates non valides",
      "INVALID_DATES_DESCRIPTION": "Vous ne pouvez choisir que le matin, l'après-midi uniquement ou des heures personnalisées si vos dates de début et de fin sont le même jour.",
      "INVALID_HEADER_TITLE": "Fiche d'absence non valide",
      "INVALID_HEADER_DESCRIPTION": "Vous ne pouvez pas modifier cet enregistrement d'absence. Il a peut-être été supprimé.",
      "INVALID_ACTION_TITLE": "Action non valide",
      "INVALID_ACTION_DESCRIPTION": "Vous ne pouvez pas modifier l'heure d'une réservation d'absence après son rejet ou son annulation.",
      "INVALID_DAYS_TITLE": "Jours non valides",
      "INVALID_DAYS_DESCRIPTION": "Vous ne pouvez pas faire de demande d'absence pour zéro heure ou zéro jour.",
      "OUTSIDE_EMPLOYMENT_TITLE": "Emploi extérieur",
      "OUTSIDE_EMPLOYMENT_DESCRIPTION": "La date de début ou de fin que vous avez choisie se situe en dehors des dates d'embauche de l'employé.",
      "SPANS_TIMEZONES_TITLE": "La plage de dates couvre deux fuseaux horaires",
      "SPANS_TIMEZONES_DESCRIPTION": "Les dossiers de rendez-vous de l'employé indiquent qu'il change de site aux dates que vous avez choisies et que son site existant se trouve dans un fuseau horaire différent de celui de son nouveau site. Assurez-vous que votre réservation d'absence se termine avant qu'ils ne changent de site, ou qu'elle commence après qu'ils aient déménagé.",
      "SPANS_PAYROLL_CALENDARS_TITLE": "La plage de dates couvre deux calendriers de paie",
      "SPANS_PAYROLL_CALENDARS_DESCRIPTION": "Les relevés de rendez-vous de l'employé indiquent qu'il modifie son calendrier de paie aux dates que vous avez choisies. Assurez-vous que votre réservation d'absences se termine avant qu'ils ne modifient leur calendrier de paie, ou qu'elle commence après qu'ils aient modifié leur calendrier de paie.",
      "INVALID_PAYROLL_CALENDAR_TITLE": "Calendrier de paie non valide",
      "INVALID_PAYROLL_CALENDAR_DESCRIPTION": "La plage de dates choisie commence avant le premier cycle de paie du calendrier de paie de l'employé.",
      "OVERLAPPING_ABSENCE_TITLE": "Absence superposée",
      "OVERLAPPING_ABSENCE_DESCRIPTION": "L'employé est déjà absent un ou plusieurs jours qui coïncident avec les dates que vous avez choisies.",
      "OVERLAPPING_SHIFTS_TITLE": "Horaires qui se chevauchent",
      "OVERLAPPING_SHIFTS_DESCRIPTION": "L'employé a publié un ou plusieurs quarts de travail qui chevauchent les dates que vous avez choisies. Ceux-ci doivent d'abord être supprimés ou annulés.",
      "SIGNED_OFF_TITLE": "Dates de signature",
      "SIGNED_OFF_DESCRIPTION": "Les dates coïncident avec un ou plusieurs jours de clôture. Vous ne pouvez pas approuver ou annuler une absence pour des jours déjà approuvés.",
      "ALLOWANCE_REQUIRED_TITLE": "Allocation requise",
      "ALLOWANCE_REQUIRED_DESCRIPTION": "Vous ne pouvez pas réserver une absence de ce type. Il doit y avoir une allocation active à déduire.",
      "ALLOWANCE_NOT_DEDUCTIBLE_TITLE": "Allocation non déductible",
      "ALLOWANCE_NOT_DEDUCTIBLE_DESCRIPTION": "Vous ne pouvez pas réserver une absence de ce type. Il existe une allocation active, mais elle est marquée comme non déductible.",
      "ALLOWANCE_BALANCE_INSUFFICIENT_TITLE": "Équilibre insuffisant",
      "ALLOWANCE_BALANCE_INSUFFICIENT_DESCRIPTION": "Le solde de l'allocation est trop faible pour que cette demande soit soumise.",
      "FILE_UPLOAD_REQUIRED_TITLE": "Téléchargement de fichiers requis",
      "FILE_UPLOAD_REQUIRED_DESCRIPTION": "Ce type d'absence nécessite que vous téléchargiez un fichier avant qu'il ne soit soumis ou approuvé."
    },
    "VIEW_MODAL": {
      "TITLE": "Absence",
      "BUTTON_OPTIONS": "Options",
      "BUTTON_EDIT": "Modifier/réviser la réservation",
      "BUTTON_CANCEL": "Annuler la réservation",
      "BUTTON_CLOSE": "Fermer",
      "STATUS_CANCELLED": "Annulé",
      "LABEL_START_DATE": "Commence",
      "LABEL_END_DATE": "Se termine",
      "LABEL_ABSENCE_TYPE": "Tapez",
      "LABEL_SCOPE": "Durée",
      "ERROR_500": "Nous avons rencontré un petit problème. Veuillez réessayer.",
      "ERROR_404": "Cette demande d'absence n'existe plus.",
      "LABEL_SUBMITTED_BY": "Soumis par",
      "LABEL_REVIEWED_BY": "Révisé par",
      "LABEL_CANCELLED_BY": "Annulé par",
      "LABEL_EDITED_BY": "Edité par",
      "DURATION_TEXT": "{{hours}} heures, {{days}} jours",
      "TAB_BREAKDOWN": "Répartition",
      "TITLE_PAY_PERIODS": "Par période de paie",
      "NO_PAY_PERIODS": "L'employé n'était pas associé à un calendrier de paie lorsque cette réservation a été effectuée.",
      "TITLE_DAILY_BREAKDOWN": "Par jour",
      "NO_DAYS": "Il n'y a aucun jour dans cette réservation.",
      "TAB_FILES": "Documents",
      "FILES_NONE": "Rien n'a été téléchargé pour cette réservation.",
      "FILES_AVAILABLE": "Ces documents se trouvent également dans la zone Personnel lors de la consultation du profil de l'employé. Vous les trouverez dans cette catégorie : {{categoryName}}",
      "FILES_COLUMN_NAME": "Nom",
      "FILES_COLUMN_UPLOADED_BY": "Téléchargé par",
      "FILES_COLUMN_OPTIONS": "Options",
      "FILES_LINK_DELETE": "Supprimer",
      "FILES_CONFIRM_DELETE": "Êtes-vous sûr de vouloir le supprimer ? Il sera également supprimé de l'onglet Documents du profil de l'employé.",
      "CONFIRM_CANCEL": "Êtes-vous sûr de vouloir annuler cette réservation d'absence ? Cela ne peut pas être annulé.",
      "ALERT_IS_CANCELLED": "La réservation d'absence a été annulée.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Avertissement ! Le demandeur a choisi une heure de début et de fin équivalant à plus de {{HalfDayHours}} heures, soit le nombre d'heures qu'il travaille actuellement pendant une demi-journée.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Avertissement ! Le demandeur a choisi de modifier les jours de congé habituels, plutôt que d'utiliser les jours suggérés par Rotaready."
    },
    "EDIT": {
      "TITLE": "Absence",
      "BUTTON_SUBMIT": "Enregistrer",
      "BUTTON_APPROVE": "Approuver",
      "BUTTON_REJECT": "Refuser",
      "TAB_EDIT_BOOKING": "Modifier les dates",
      "TAB_REVIEW": "Révision",
      "TAB_FILES": "Documents",
      "TAB_CALENDAR": "Calendrier",
      "LABEL_TYPE": "Tapez",
      "LABEL_REASON": "Sélectionnez une raison",
      "LABEL_EMPLOYEE": "Employé",
      "LABEL_START_DATE": "Date de début",
      "LABEL_END_DATE": "Date de fin",
      "LABEL_SCOPE_ALL_DAY": "Toute la journée",
      "LABEL_SCOPE_AM": "AM uniquement",
      "LABEL_SCOPE_PM": "PM uniquement",
      "LABEL_SCOPE_CUSTOM": "Choisissez des heures spécifiques",
      "LABEL_START_TIME": "Heure de début",
      "LABEL_END_TIME": "Heure de fin",
      "ERROR_500": "Nous avons rencontré un problème à ce moment-là. Veuillez réessayer !",
      "LABEL_DAYS": "jours",
      "LABEL_HOURS": "heures",
      "SECTION_UNPAID_DAYS": "Jours de congé réguliers",
      "HELP_TEXT_NO_UNPAID_DAYS": "Il n'y a pas de jours de congé réguliers pour cette demande.",
      "BUTTON_OPTIONS": "Options",
      "BUTTON_CHANGE": "Modifier les jours de congé habituels",
      "BUTTON_RESET": "Revenir à la réservation initiale",
      "BUTTON_RECALCULATE": "Recalculer à nouveau",
      "BUTTON_CLEAR": "Éliminez tous les jours de congé habituels",
      "UNPAID_DAYS_RECALCULATED_LABEL": "Recalculé",
      "UNPAID_DAYS_RECALCULATED_HELP": "Vous avez choisi de calculer à nouveau les jours de congé réguliers et d'utiliser la valeur en vigueur pour les « heures par jour ouvrable » de l'employé au lieu de ce qu'elle était au moment de la réservation initiale.",
      "UNPAID_DAYS_MERGED_LABEL": "Fusionné",
      "UNPAID_DAYS_MERGED_HELP": "Depuis que vous avez modifié la date, nous avons inclus tous les nouveaux jours de congé, supprimé ceux qui ne sont plus concernés et avons laissé les autres intacts.",
      "MODAL_CALENDAR_TITLE": "Choisissez des jours de congé réguliers",
      "MODAL_BUTTON_SAVE": "Enregistrer",
      "BUTTON_COMPLETE": "Ramène-moi",
      "COMPLETE_TITLE": "Tu es prête à y aller.",
      "COMPLETE_TEXT": "Nous avons enregistré la réservation des absences et les personnes concernées ont été prévenues 👍",
      "INVALID_HEADER_TITLE": "Absence non valide",
      "INVALID_HEADER_TEXT": "Nous ne pouvons pas charger cette réservation d'absence. Il a peut-être été annulé ou vous n'êtes pas autorisé à le voir.",
      "TITLE_OVERLAPPING_ABSENCE": "Absence concomitante",
      "TITLE_OVERLAPPING_SHIFTS": "Horaires qui se chevauchent",
      "TITLE_NOTABLE_EVENTS": "Événements marquants",
      "COLUMN_OVERLAPPING_ABSENCE_DATES": "Dates",
      "COLUMN_OVERLAPPING_ABSENCE_TYPE": "Tapez",
      "COLUMN_OVERLAPPING_ABSENCE_WHO": "Qui",
      "NO_OVERLAPPING_ABSENCE": "Il n'y a pas d'absence simultanée correspondant à vos filtres.",
      "NO_OVERLAPPING_SHIFTS": "Il n'y a pas de chevauchement des quarts de travail sur le site ou le service de l'employé.",
      "NO_NOTABLE_EVENTS": "Il n'y a aucun événement notable au moment de cette absence.",
      "COLUMN_OVERLAPPING_SHIFTS_DATE": "Date",
      "COLUMN_OVERLAPPING_SHIFTS_SITE": "Site/département",
      "COLUMN_OVERLAPPING_SHIFTS_TYPE": "Type de quart de travail",
      "COLUMN_OVERLAPPING_SHIFTS_OPTIONS": "Options",
      "LINK_EDIT_SHIFT": "Modifier la rotation",
      "LINK_DELETE_SHIFT": "Supprimer",
      "BUTTON_BULK_SHIFT_OPTIONS": "Actions groupées",
      "BUTTON_BULK_SHIFT_UNASSIGN_ALL": "Annuler l'attribution de la sélection",
      "BUTTON_BULK_SHIFT_DELETE_ALL": "Supprimer la sélection",
      "ALERT_BULK_SHIFT_CONFIRM": "Êtes-vous sûr de vouloir faire cela pour {{count}} shifts ?",
      "ALERT_BULK_SHIFT_EDIT_FAILURE": "Nous n'avons pas pu modifier les changements car cela entraînerait un problème de validation. Veuillez effectuer cette modification sur la rotation pour obtenir plus d'informations.",
      "ALERT_SINGLE_SHIFT_DELETE_FAILURE": "Nous n'avons pas pu supprimer ce changement car cela entraînerait un problème de validation. Veuillez effectuer cette modification sur la rotation pour obtenir plus d'informations.",
      "COLUMN_NOTABLE_EVENTS_NAME": "Nom",
      "COLUMN_NOTABLE_EVENTS_DATE": "Date",
      "NOTABLE_EVENTS_ABSENCE_RESTRICTION_TOOLTIP": "Les absences sont limitées lors de cet événement.",
      "LABEL_ADD_A_NOTE": "Écrivez éventuellement un message pour l'employé",
      "OVERLAPPING_SHIFTS_MORE": "Nous ne montrons que les quinze premiers quarts de travail qui se chevauchent. Résolvez-les pour en savoir plus.",
      "OVERLAPPING_ABSENCE_MORE": "Nous n'affichons que les quinze premières réservations qui se chevauchent.",
      "CALENDAR_UNPAID_DAY": "Jour de congé normal",
      "FILTER_LABEL_ENTITY": "Site/département",
      "FILTER_LABEL_STAFF_GROUP": "Groupe du personnel",
      "FILTER_LABEL_ABSENCE_TYPE": "Tapez",
      "FILES_NONE": "Rien n'a été téléchargé pour cette réservation.",
      "FILES_AVAILABLE": "Ces documents se trouvent également dans la zone Personnel lors de la consultation du profil de l'employé. Vous les trouverez dans cette catégorie : {{categoryName}}",
      "FILES_COLUMN_NAME": "Nom",
      "FILES_COLUMN_UPLOADED_BY": "Téléchargé par",
      "FILES_COLUMN_OPTIONS": "Options",
      "FILES_LINK_DELETE": "Supprimer",
      "FILES_CONFIRM_DELETE": "Êtes-vous sûr de vouloir le supprimer ? Il sera également supprimé de l'onglet Documents du profil de l'employé.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Avertissement ! Le demandeur a choisi une heure de début et de fin équivalant à plus de {{HalfDayHours}} heures, soit le nombre d'heures qu'il travaille actuellement pendant une demi-journée.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Avertissement ! Le demandeur a choisi de modifier les jours de congé habituels, plutôt que d'utiliser les jours suggérés par Rotaready. Veuillez les consulter attentivement dans l'onglet Modifier les dates et choisissez l'option Recalculer à nouveau si nécessaire."
    },
    "ENUM_SCOPE": {
      "ALL_DAY": "Toute la journée",
      "AM": "AM uniquement",
      "PM": "PM uniquement",
      "CUSTOM": "Horaires personnalisés"
    },
    "ENUM_APPROVAL_STATE": {
      "APPROVED": "Approuvé",
      "REJECTED": "Rejeté",
      "PENDING": "En attente"
    },
    "ENUM_ALLOWANCE_UNIT": {
      "DAYS": "Journées",
      "HOURS": "Heures"
    },
    "ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL": {
      "BEGINNING_OF_ACCRUAL_YEAR": "Début de l'année d'accumulation",
      "EACH_CALENDAR_MONTH": "Chaque mois civil",
      "EACH_HOUR_WORKED": "Chaque heure travaillée"
    },
    "ALLOWANCE_IMPACT_DIRECTIVE": {
      "DAYS_DEBIT": "Déduction {{count}} de jours",
      "HOURS_DEBIT": "Déduction {{count}} pour les heures de travail",
      "DAYS_CREDIT": "crédit par jour {{count}}",
      "HOURS_CREDIT": "crédit d'heures {{count}}",
      "REMAINING": "({{count}} ({{count})",
      "WARNING_EXCEEDS": "Votre demande dépasse cette limite mais peut tout de même être soumise.",
      "WARNING_NOT_PERMITTED": "Votre demande dépasse cette indemnité.",
      "TOOLTIP_PROJECTED": "Rotaready a inclus une prévision de ce que vous êtes susceptible d'accumuler. Cela peut être limité en fonction des politiques de votre employeur.",
      "CALC_TOTAL_ACCRUED": "Total cumulé",
      "CALC_BOOKED": "Réservé",
      "CALC_PROJECTED": "Accumulation prévue",
      "CALC_THIS_REQUEST": "Cette demande",
      "CALC_REMAINING": "Restant",
      "CALC_CONVERTED_TO_DAYS": "En tant que jours"
    }
  }
};

module.exports = translations;
