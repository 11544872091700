const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/rota/dashboardWeek.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/rotamanagement/dashboard/wtdViolationsModal.html');

var directives = require('../module');

directives.directive('rrDashboardWeek', function () {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        scope: {
            week: '=',
            entityId: '=',
        },
        controller: function ($scope, $state, $uibModal, SessionService, BetaService) {
            const {
              week,
              entityId,
            } = $scope;

            $scope.isLastWeek = moment(week.start).isSame(moment().subtract(1, 'week').startOf('week'));
            $scope.costControlDate = moment(week.start).format('YYYY-MM-DD');

            $scope.isCurrentWeek = moment(week.start).isSame(moment().startOf('week'));

            $scope.showSales = (SessionService.getSetting('costControl.salesForecasted') || 'true') === 'true' &&
                SessionService.isPermissioned('rota.cost');
            $scope.showIssues = SessionService.isPermissioned('attendance.edit');
            $scope.showAbsenceRequests = SessionService.isPermissioned('availability.absence.requests.view');
            $scope.approvalRequired = SessionService.getSetting('rota.drafts.approvalReqd') === 'true';

            $scope.viewRota = function (isPublished, rota) {
              BetaService.redirect(BetaService.Features.ROTA_EDITOR, {
                startDate: moment(rota.periodStart).format('YYYY-MM-DD'),
                entityId,
              });
            };

            $scope.viewWtdReport = function () {
                $uibModal.open({
                    templateUrl: autoNgTemplateLoaderTemplate2,
                    controller: 'WtdViolationsModalCtrl',
                    size: 'lg',
                    resolve: {
                      data: () => ({
                        entityId,
                      }),
                    },
                });
            };

          $scope.showDraftRotas = function () {
            BetaService.redirect(BetaService.Features.ROTA_EDITOR, {
              startDate: moment(week.start).format('YYYY-MM-DD'),
              entityId,
            });
          };

          $scope.goToCostControl = function () {
            BetaService.redirect(BetaService.Features.COST_CONTROL, {
              startDate: $scope.costControlDate,
              entityId,
            });
          };

          $scope.goToAttendanceIssues = () => {
            $state.go('attendance.issues', {
              start: moment(week.start).format('YYYY-MM-DD'),
              end: moment(week.start).clone().add(6, 'days').format('YYYY-MM-DD'),
              entity: entityId,
            });
          };
        }
    };
});
