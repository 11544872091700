'use strict';
var controllers = require('../../module');

controllers.controller('EditConstraintsModalCtrl', ['$scope', '$uibModalInstance', 'CommonService', 'TemplateService', 'SessionService', 'data',
    function ($scope, $uibModalInstance, CommonService, TemplateService, SessionService, data) {
        var maxConstraintId = 17;
        $scope.dataLoaded = false;
        $scope.editable = {};

        $scope.entityId = SessionService.getEntity();

        CommonService.getMetadata(['tags', 'outsourcedGroups', 'staffGroups', 'shiftTypes', 'entities'], [$scope.entityId], true)
            .success(function (data) {
                $scope.metadata = data;
                $scope.metadata.typecastingTags = angular.copy(data.tags);
                $scope.metadata.preferredTags = angular.copy(data.tags);
                $scope.shiftIds = data.shiftTypes.map(function(x) { return x.id });
                $scope.dataLoaded = true;
            });

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.onStaffSelect = function (selectedUser, id) {
            $scope.activeConstraint[id].const_var3 = selectedUser.id;
        };

        // If we're adding
        $scope.isAdd = data.isAdd;
        $scope.levelNames = data.levelNames;
        $scope.currentLevelName = data.levelNames[data.nodeInfo.node_depth+2];
        $scope.currentLevel = data.nodeInfo.node_depth+2;
        $scope.editable.nodeName = '';

        // Anything above us in tree, flattened, keyed by type
        $scope.parentConstraints = angular.copy(data.flattenedConstraints);
        // This level's constraints
        $scope.constraints = [];

        // Pointer for each constraint, to this level's array or the parent array
        $scope.activeConstraint = [];

        // If we're editing
        if (!data.isAdd) {
            var constraints = [];

            // Build up an array of constraints at this level, keyed by type
            angular.forEach(data.nodeInfo.constraints, function (constraint) {
                constraints[constraint.const_type] = constraint;
            });

            $scope.constraints = angular.copy(constraints);
            $scope.currentLevelName = data.levelNames[data.nodeInfo.node_depth+1];
            $scope.currentLevel = data.nodeInfo.node_depth+1;
            $scope.editable.nodeName = data.nodeInfo.node_name;
        }

        $scope.toggleWindowBounds = function (constraint) {
            constraint.const_var1 = '00:00';
            constraint.const_var2 = '00:00';
        };

        // Initialise pointer array, for the models
        function initPointerArray() {
            var pointerArray = [];

            // Fill with the parent constraints first
            angular.forEach($scope.parentConstraints, function (constraint) {
                if (!constraint)
                    return;

                constraint.constraint.isParent = true;
                pointerArray[constraint.constraint.const_type] = constraint.constraint;
            });

            // Then add or overwrite with any at this level
            angular.forEach($scope.constraints, function (constraint) {
                if (!constraint)
                    return;

                pointerArray[constraint.const_type] = constraint;
            });

            // Finally fill in the blanks with empty constraint objects that can be bound to by ng-models in the form
            for (var i = 1; i <= maxConstraintId; i++) {
                if (!pointerArray[i]) {
                    pointerArray[i] = {
                        const_type: i,
                        node_id: data.isAdd ? 0 : data.nodeInfo.node_id,
                        node_rootid: data.isAdd ? 0 : data.nodeInfo.node_rootid,
                        const_enforced: 1,
                        const_var1: 0,
                        const_var2: 0,
                        const_var3: 0,
                        const_var4: 0
                    };
                }
            }

            // Shift type constraint must be int for hierarchy picker
            if (pointerArray[16] && pointerArray[16].const_type === 16) {
                pointerArray[16].const_var1 = parseInt(pointerArray[16].const_var1, 10);
            }

            $scope.activeConstraint = pointerArray;
        }

        initPointerArray();

        $scope.addOverride = function (constType) {
            $scope.constraints[constType] = angular.copy($scope.parentConstraints[constType].constraint);
            $scope.activeConstraint[constType] = $scope.constraints[constType];
            $scope.activeConstraint[constType].isOverride = true;
        };

        $scope.removeOverride = function (constType) {
            delete $scope.constraints[constType];
            $scope.activeConstraint[constType] = angular.copy($scope.parentConstraints[constType].constraint);
        };

        $scope.saveActioning = false;
        $scope.save = function () {
            if (!$scope.editable.nodeName.length) {
                return alert('You must provide a '+ $scope.currentLevelName +' name.');
            }

            $scope.saveActioning = true;

            var constraintsToSend = [];
            angular.forEach($scope.activeConstraint, function (constraint) {
                if (!constraint) return;

                // Only include constraints at this level, and overrides of parent constraints
                if (!constraint.isParent || (constraint.isParent && constraint.isOverride)) {
                    var constraintCopy = angular.copy(constraint);

                    for (var i = 1; i <= 4; i++) {
                        var propName = 'const_var' + i;

                        if (moment.isMoment(constraintCopy[propName])) {
                            constraintCopy[propName] = constraintCopy[propName].format('HH:mm:ss');
                        }
                    }

                    constraintsToSend.push(constraintCopy);
                }
            });

            if ($scope.isAdd) {
                TemplateService.addNode({
                    parent_nodeid: data.nodeInfo.node_id,
                    node_name: $scope.editable.nodeName,
                    constraints: constraintsToSend
                })
                    .success(function (data) {
                        $uibModalInstance.close(data);
                    })
                    .error(function (error) {
                        $scope.saveActioning = false;
                        alert('Currently unable to add this '+ $scope.currentLevelName +'. Please try again.');
                    });
            } else {
                TemplateService.updateNode(data.nodeInfo.node_id, {
                    node_name: $scope.editable.nodeName,
                    constraints: constraintsToSend
                })
                    .success(function (data) {
                        $uibModalInstance.close(data);
                    })
                    .error(function (error) {
                        $scope.saveActioning = false;
                        alert('Currently unable to save this '+ $scope.currentLevelName +'. Please try again.');
                    });
            }
        };

        // Disables all shifts in hierarchy picker when constraint override is not active
        $scope.disabledShiftIds = function (constraint_id) {
            if ($scope.parentConstraints[constraint_id] && !$scope.constraints[constraint_id]) {
                // Disable all shift types
                if ($scope.shiftIds) return $scope.shiftIds;
            }
            return "[]";
        };

        $scope.entityChanged = function (entity, constraint, varIndex) {
            constraint['const_var' + varIndex] = entity.id;
        };
    }]);
