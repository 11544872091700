module.exports = (
  $scope,
  $state,
  $stateParams,
  $translate,
  $window,
  $http,
  EnvironmentDataService,
  SessionService,
  AlertService,
  ENDPOINT_API,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.LATENESS.ERROR_500',
    'REPORTS.LATENESS.FILTER_OVERTIME_NA',
    'REPORTS.LATENESS.FILTER_OVERTIME_APPROVED',
    'REPORTS.LATENESS.FILTER_OVERTIME_REJECTED',
    'REPORTS.EMPLOYMENT_CHANGES.DATE_ERROR_DIFF',
  ]);

  let selectedDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('week').subtract(1, 'week');
  let selectedDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('week').subtract(1, 'week').add(6, 'days');
  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];
  const defaultUserId = $stateParams.userId || undefined;
  const defaultOvertimeFilterOptions = $stateParams.overtimeApprovalStates
    ? $stateParams.overtimeApprovalStates.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];

  const { filters: filtersBase64 } = $stateParams;
  let defaultFilters;

  try {
    const parsed = JSON.parse(atob(filtersBase64));

    if (typeof parsed === 'object') {
      defaultFilters = parsed;
    }
  } catch (e) {}

  $scope.props = {
    loadingData: false,
    noMoreData: false,
    data: [],
    defaultEntityIds,
    defaultUserId,
    defaultDateFilter: {
      option: 4,
      dateStart: selectedDateStart,
      dateEnd: selectedDateEnd,
    },
    overtimeFilterOptions: [
      { id: 0, label: translations['REPORTS.LATENESS.FILTER_OVERTIME_NA'] },
      { id: 1, label: translations['REPORTS.LATENESS.FILTER_OVERTIME_APPROVED'] },
      { id: 2, label: translations['REPORTS.LATENESS.FILTER_OVERTIME_REJECTED'] },
    ],
    defaultOvertimeFilterOptions,
    filters: defaultFilters || {
      clockInLate: {
        option: 'greaterThan',
        value: 15,
      },
    },
  };

  const {
    clockInLate,
    clockInEarly,
    clockOutLate,
    clockOutEarly,
  } = $scope.props.filters;

  $scope.getDataParams = {
    dateRange: selectedDateStart && selectedDateEnd
      ? `${selectedDateStart.format('YYYY-MM-DD')}/${selectedDateEnd.clone().add(1, 'day').format('YYYY-MM-DD')}`
      : undefined,
    'userAppointmentEntityIds[]': defaultEntityIds,
    userId: defaultUserId,
    clockInLateOperator: clockInLate ? clockInLate.option : undefined,
    clockInLateValue: clockInLate ? clockInLate.value : undefined,
    clockInEarlyOperator: clockInEarly ? clockInEarly.option : undefined,
    clockInEarlyValue: clockInEarly ? clockInEarly.value : undefined,
    clockOutLateOperator: clockOutLate ? clockOutLate.option : undefined,
    clockOutLateValue: clockOutLate ? clockOutLate.value : undefined,
    clockOutEarlyOperator: clockOutEarly ? clockOutEarly.option : undefined,
    clockOutEarlyValue: clockOutEarly ? clockOutEarly.value : undefined,
    locale: SessionService.getUserLocale(),
    sortOrder: 'countDesc',
    page: 1,
    limit: 50,
  };

  $scope.loadData = async (reset) => {
    const diff = selectedDateEnd.clone().add(1, 'day').diff(selectedDateStart, 'years', true);

    if (diff < 0 || diff > 1) {
      AlertService.add('info', translations['REPORTS.EMPLOYMENT_CHANGES.DATE_ERROR_DIFF']);
      return;
    }

    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.loadingData = true;

    if (reset) {
      $scope.getDataParams.page = 1;
      $scope.props.noMoreData = false;
      $scope.props.data = [];
    }

    const { getDataParams } = $scope;
    const entityIds = getDataParams['userAppointmentEntityIds[]'];
    const overtimeApprovalStates = getDataParams['overtimeApprovalStates[]'];

    $state.go('.', {
      start: selectedDateStart ? selectedDateStart.format('YYYY-MM-DD') : undefined,
      end: selectedDateEnd ? selectedDateEnd.format('YYYY-MM-DD') : undefined,
      entityIds: entityIds ? entityIds.join(',') : undefined,
      userId: getDataParams.userId,
      overtimeApprovalStates: overtimeApprovalStates ? overtimeApprovalStates.join(',') : undefined,
      filters: btoa(JSON.stringify($scope.props.filters)),
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
      inherit: false,
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    try {
      const { data } = await $http.get(`${ENDPOINT_API}/report/lateness`, {
        params: getDataParams,
      });

      const { results } = data;

      $scope.props.data.push(...results);
      $scope.getDataParams.page += 1;

      if (results.length < getDataParams.limit) {
        $scope.props.noMoreData = true;
      }

      $scope.props.loadingData = false;
      $scope.$apply();
    } catch ({ status }) {
      $scope.props.noMoreData = true;
      $scope.props.loadingData = false;

      if (status === 500) {
        AlertService.add('danger', translations['REPORTS.LATENESS.ERROR_500']);
      }
    }
  };

  $scope.onDateFilter = ({
    option,
    dateEnd,
    dateStart,
  }) => {
    if (option === 1) {
      selectedDateStart = undefined;
      selectedDateEnd = undefined;
      $scope.getDataParams.dateRange = undefined;
    } else {
      selectedDateStart = dateStart.clone();
      selectedDateEnd = dateEnd.clone();
      $scope.getDataParams.dateRange = `${dateStart.format('YYYY-MM-DD')}/${dateEnd.clone().add(1, 'day').format('YYYY-MM-DD')}`;
    }

    $scope.loadData(true);
  };

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.getDataParams['userAppointmentEntityIds[]'] = entityIds.length ? entityIds : undefined;
    $scope.loadData(true);
  };

  $scope.onUserFilter = (selectedUser) => {
    $scope.getDataParams.userId = selectedUser ? selectedUser.id : undefined;
    $scope.loadData(true);
  };

  $scope.onOvertimeFilter = (selectedOptions) => {
    $scope.getDataParams['overtimeApprovalStates[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  function updateFilter(property, option, value) {
    if (option === 'any') {
      $scope.getDataParams[`${property}Operator`] = undefined;
      $scope.getDataParams[`${property}Value`] = undefined;
      $scope.props.filters[property] = undefined;
    } else {
      $scope.getDataParams[`${property}Operator`] = option;
      $scope.getDataParams[`${property}Value`] = value;
      $scope.props.filters[property] = { option, value };
    }

    $scope.loadData(true);
  }

  $scope.onClockInLateFilter = ({
    option,
    value,
  }) => {
    updateFilter('clockInLate', option, value);
  };

  $scope.onClockInEarlyFilter = ({
    option,
    value,
  }) => {
    updateFilter('clockInEarly', option, value);
  };

  $scope.onClockOutLateFilter = ({
    option,
    value,
  }) => {
    updateFilter('clockOutLate', option, value);
  };

  $scope.onClockOutEarlyFilter = ({
    option,
    value,
  }) => {
    updateFilter('clockOutEarly', option, value);
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
  ])
    .then(([
      entityGroup,
    ]) => {
      $scope.props.entityGroupList = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => ({
            id: entity.id,
            label: entity.name,
            parentId: entity.groupId,
            depth: 1,
          })),
        ]));

      $scope.loadData();
    });

  $scope.export = async () => {
    $scope.props.loadingData = true;

    const {
      data: {
        signedUrl,
      },
    } = await $http({
      method: 'GET',
      url: `${ENDPOINT_API}/report/lateness`,
      data: '', // angular removes the content-type header unless this is present
      params: $scope.getDataParams,
      headers: {
        'Content-Type': 'text/csv',
      },
    });

    if (signedUrl) {
      $window.location = signedUrl;
    }

    $scope.props.loadingData = false;
  };

  $scope.interrogate = (userId) => {
    const url = $state.href('reports.attendanceBreakdown', {
      ...$state.params,
      userId,
    });

    $window.open(url, '_blank');
  };
};
