import gql from 'graphql-tag';

export const TroncUserApportion = gql`
  fragment TroncUserApportion_troncUserApportion on TroncUserApportion {
    id
    troncUserApportionID
    startDate
    endDate
    amount
    apportionType
    lastEdited {
      date
      user {
        fullName
      }
    }
  }
`;

export const TroncUserApportionsQuery = gql`
  query GetUserApportionsQuery(
    $userId: Int!
    $first: Int = 20
    $lastCursor: String
  ) {
    troncUserApportions(userId: $userId, first: $first, after: $lastCursor) {
      edges {
        node {
          ...TroncUserApportion_troncUserApportion
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${TroncUserApportion}
`;

export const ConversationUserQuery = gql`
  query ConversationUserQuery($ids: [Int!]!) {
    usersById(ids: $ids) {
      userId
      firstName
      lastName
      userPicture
      userAvatarModuloDividend
    }
  }
`;

export const PostMessageMutation = gql`
  mutation postMessageMutation($input: PostMessageInput!) {
    postMessage(input: $input) {
      response {
        __typename

        ... on PostedMessage {
          message
        }

        ... on PostMessageErrorResponse {
          message
        }
      }
    }
  }
`;

export const StartChannelMutation = gql`
  mutation startChannelMutation($input: StartChannelInput!) {
    startChannel(input: $input) {
      response {
        __typename

        ... on StartChannel {
          success
        }

        ... on AddChannelsErrorResponse {
          message
        }

        ... on SetChannelMetadataErrorResponse {
          message
        }
      }
    }
  }
`;
export const RealmWideStaffListQuery = gql`
    query RealmWideStaffListQuery(
        $name: String,
        $hideSelf: Boolean,
        $isActive: Boolean,
        $date: String!,
        $count: Int! = 10,
    ) {
        staff(first: $count, name: $name, hideSelf: $hideSelf, isActive: $isActive) {
            edges {
                node {
                    id
                    name: fullName
                    userId
                    firstName
                    lastName
                    userPicture
                    userAvatarModuloDividend
                    appointment: simpleAppointment(atDate: $date) {
                        entityGroup: staffGroup {
                            name
                        }
                        entity: site {
                            name
                        }
                    }
                }
            }
        }
    }
`;
