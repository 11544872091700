const moment = require('moment-timezone');
const { TYPING_TIMEOUT_MS } = require('../../constants/messaging/constants');

module.exports = (
  $scope,
  $timeout,
  SessionService,
  MessagingService,
) => {
  'ngInject';

  $scope.isWebkit = navigator.userAgent.toLowerCase().indexOf('webkit') > 0;

  $scope.formData = {
    conversationText: '',
  };

  $scope.lastTypingTime = null;

  let typingTimeout;

  const setStoppedTyping = (channelName, userId) => {
    MessagingService.setUserStopTyping(channelName, userId);
    $scope.lastTypingTime = null;
  };

  $scope.sendMessage = async () => {
    const userId = SessionService.getUserId();
    const userFullName = SessionService.getUserFullName();
    const channelName = $scope.channelId;

    if ($scope.formData.conversationText !== '') {
      await MessagingService.postMessage(
        channelName,
        userId,
        userFullName,
        $scope.formData.conversationText,
        $scope.participantIds,
      );

      $scope.formData.conversationText = '';

      if (typingTimeout !== undefined) {
        $timeout.cancel(typingTimeout);

        setStoppedTyping(channelName, userId);
      }
    }
  };

  $scope.userStartedTyping = () => {
    const userId = SessionService.getUserId();
    const channelName = $scope.channelId;

    if (!$scope.lastTypingTime) {
      MessagingService.setUserTyping(channelName, userId);
    }

    const timestamp = moment().valueOf();
    $scope.lastTypingTime = timestamp;

    if (typingTimeout !== undefined) {
      $timeout.cancel(typingTimeout);
    }

    typingTimeout = $timeout(() => {
      setStoppedTyping(channelName, userId);
    }, TYPING_TIMEOUT_MS);
  };

  $scope.onKeyPress = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      $scope.sendMessage();
    }
  };
};
