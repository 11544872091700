'use strict';
var controllers = require('../../module');

controllers.controller('ViewStaffRequestsCtrl', ['$scope', '$state', '$translate',
    function ($scope, $state, $translate) {
        var labelTranslations = $translate.instant([
            'STAFF.REQUESTS.INDEX.ABSENCE_LABEL',
            'STAFF.REQUESTS.INDEX.SWAPS_LABEL',
            'STAFF.REQUESTS.INDEX.DOCUMENTS_EXPIRING_LABEL',
            'STAFF.REQUESTS.INDEX.ACCOUNT_APPROVAL_LABEL',
        ]);

        $scope.tabs = {
            options: [
                { state: 'absence', label: labelTranslations['STAFF.REQUESTS.INDEX.ABSENCE_LABEL'] },
                { state: 'swaps', label: labelTranslations['STAFF.REQUESTS.INDEX.SWAPS_LABEL'] },
                { state: 'documents', label: labelTranslations['STAFF.REQUESTS.INDEX.DOCUMENTS_EXPIRING_LABEL'] },
                { state: 'newJoiners', label: labelTranslations['STAFF.REQUESTS.INDEX.ACCOUNT_APPROVAL_LABEL'] },
            ],
            currentState: $state.current.name.split('.')[2],
        };

        $scope.onTabClick = function (tab) {
            $scope.tabs.currentState = tab.state;
            $scope.onTabChange();
        };

        $scope.onTabChange = function () {
            var tab = $scope.tabs.options.find(function (t) {
                return t.state === $scope.tabs.currentState;
            });

            if (tab) {
                $state.go('staff.requests.' + tab.state);
            }
        };
    }]);
