const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/addNew/drawer.html');

const controllers = require('../module');

controllers.controller('IndexStaffCtrl', ['$scope', '$uibModalDrawer', '$state',
  ($scope, $uibModalDrawer, $state) => {
    $scope.onStaffSelect = ({ id }) => {
      const params = { id };

      if ($state.includes('staff.view.**')) {
        $state.go($state.$current.name, params);
      } else {
        $state.go('staff.view.account', params);
      }
    };

    $scope.addStaff = () => {
      $uibModalDrawer.open({
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: require('./addNew/drawer'), // eslint-disable-line
      });
    };
  }]);
