const translations = {
  STAFF: {
    CONSTANTS: {
      UK_STARTER_DECLARATION_A: 'A: This is their first job since the start of the tax year (6th April)',
      UK_STARTER_DECLARATION_B: 'B: This is currently their only job',
      UK_STARTER_DECLARATION_C: 'C: They have another job or pension',
      UK_STUDENT_LOAN_PLAN_1: 'Plan 1',
      UK_STUDENT_LOAN_PLAN_2: 'Plan 2',
      UK_STUDENT_LOAN_PLAN_4: 'Plan 4',
      UK_STUDENT_LOAN_PLAN_5: 'Plan 5',
      UK_STUDENT_LOAN_PLAN_POSTGRADUATE: 'Postgraduate',
      UK_SETTLED_STATUS_NA: 'Not applicable',
      UK_SETTLED_STATUS_PRE_SETTLED: 'Pre-settled',
      UK_SETTLED_STATUS_SETTLED: 'Settled',
      MARITAL_STATUS_SINGLE: 'Single',
      MARITAL_STATUS_MARRIED: 'Married',
      MARITAL_STATUS_DIVORCED: 'Divorced',
      MARITAL_STATUS_WIDOWED: 'Widowed',
      MARITAL_STATUS_CIVIL_PARTNERSHIP: 'Civil Partnership',
      PAYMENT_METHOD_ELECTRONIC: 'Electronic transfer',
      PAYMENT_METHOD_CHEQUE: 'Cheque',
      PAYMENT_METHOD_MANUAL: 'Manual',
      CONTRACTUAL_OBLIGATION_PERIOD_WEEK: 'Per week',
      CONTRACTUAL_OBLIGATION_PERIOD_MONTH: 'Per month',
      CONTRACTUAL_OBLIGATION_UNIT_HOURS: 'Hours',
      CONTRACTUAL_OBLIGATION_UNIT_DAYS: 'Days',
      PAY_AMOUNT_TYPE_HOURLY: 'Hourly rate',
      PAY_AMOUNT_TYPE_DAILY: 'Daily rate',
      PAY_AMOUNT_TYPE_ANNUAL: 'Annual salary',
      PAY_FREQUENCY_WEEKLY: 'Weekly',
      PAY_FREQUENCY_FORTNIGHTLY: 'Fortnightly',
      PAY_FREQUENCY_MONTHLY: 'Monthly',
      PAY_FREQUENCY_FOUR_WEEKLY: 'Four-weekly',
    },
    OVERVIEW: {
      ERROR_500: 'We couldn\'t load any data just then. Please try again.',
      TITLE: 'Staff',
      ADD_STAFF: 'Add new',
      NO_MATCH: 'We couldn\'t find anyone who matches your filters.',
      NO_ACTIVE_APPT_TAG: 'Inactive',
      NOT_APPROVED_TAG: 'Not approved',
      DOCUMENTS_INVALID_TAG: 'Invalid docs',
      NOT_INVITED_TAG: 'Not invited',
      NOT_SETUP_TAG: 'Invited',
      EMPLOYER_INCOMPLETE_TAG: 'Incomplete',
      SELF_ONBOARDING: 'Self-onboarding',
      ACCOUNT_TAB_LABEL: 'Account',
      PERSONAL_TAB_LABEL: 'Personal',
      EMPLOYMENT_TAB_LABEL: 'Employment',
      PAY_TAB_LABEL: 'Pay',
      SCHEDULE_TAB_LABEL: 'Schedule',
      ABSENCE_TAB_LABEL: 'Absence',
      AVAILABILITY_TAB_LABEL: 'Availability',
      SHIFT_ROUTINE_TAB_LABEL: 'Shift routines',
      DOCUMENTS_TAB_LABEL: 'Documents',
      ATTENDANCE_TAB_LABEL: 'Attendance',
      NOTES_TAB_LABEL: 'Notes',
      FILTER_LABEL_SITE: 'Site/department',
      FILTER_LABEL_POSITION: 'Job role',
      FILTER_LABEL_STAFF_GROUP: 'Staff group',
      FILTER_LABEL_EMPLOYMENT: 'Employment status',
      FILTER_LABEL_APPROVAL_STATUS: 'Approval status',
      FILTER_LABEL_EMPLOYEE_SETUP: 'Rotaready invitation',
      FILTER_LABEL_EMPLOYER_SETUP: 'Account completion',
      FILTER_LABEL_EMPLOYMENT_START: 'Join date',
      FILTER_LABEL_EMPLOYMENT_END: 'Leave date',
      FILTER_LABEL_EMPLOYMENT_START_OR_END: 'Join or leave date',
      BUTTON_COLUMNS: 'Columns',
      BUTTON_EXPORT: 'Export',
      EMPLOYMENT_STATUS_ACTIVE: 'Active',
      EMPLOYMENT_STATUS_INACTIVE: 'Inactive',
      APPROVAL_STATUS_APPROVED: 'Approved',
      APPROVAL_STATUS_NOT_APPROVED: 'Not approved',
      EMPLOYEE_SETUP_COMPLETE: 'Accepted',
      EMPLOYEE_SETUP_NOT_COMPLETE: 'Pending or not sent',
      EMPLOYER_SETUP_COMPLETE: 'Complete',
      EMPLOYER_SETUP_NOT_COMPLETE: 'Incomplete',
      COLUMN_NAME: 'Name',
      COLUMN_NEXT_SHIFT: 'Next shift',
      COLUMN_ID: 'ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_BIRTH_DATE: 'Birth date',
      COLUMN_EMAIL: 'Email',
      COLUMN_MOBILE: 'Phone',
      COLUMN_OTHER_NAMES: 'Other name(s)',
      COLUMN_ADDRESS_LINE_1: 'Address line 1',
      COLUMN_ADDRESS_LINE_2: 'Address line 2',
      COLUMN_ADDRESS_CITY: 'Town/city',
      COLUMN_ADDRESS_COUNTY: 'County/region',
      COLUMN_ADDRESS_POST_CODE: 'Post code',
      COLUMN_ADDRESS_COUNTRY: 'Country',
      COLUMN_PREFERRED_NAME: 'Preferred name',
      COLUMN_GENDER: 'Gender',
      COLUMN_TITLE: 'Title',
      COLUMN_ETHNICITY: 'Ethnicity',
      COLUMN_NATIONALITY: 'Nationality',
      COLUMN_MARITAL_STATUS: 'Marital status',
      COLUMN_SETTLED_STATUS: 'Settled status (UK)',
      COLUMN_PASSPORT_NUMBER: 'Passport number',
      COLUMN_PASSPORT_EXPIRY_DATE: 'Passport expiry',
      COLUMN_WORK_EMAIL: 'Secondary email',
      COLUMN_WORK_PHONE: 'Secondary phone',
      COLUMN_BANK_ACCOUNT_NAME: 'Bank account name',
      COLUMN_BANK_ACCOUNT_NUMBER: 'Bank account number',
      COLUMN_BANK_ACCOUNT_SORT_CODE: 'Bank sort code',
      COLUMN_PAYROLL_PAYMENT_METHOD: 'Payment method',
      COLUMN_NATIONAL_INSURANCE_NUMBER: 'National Insurance number (UK)',
      COLUMN_STUDENT_LOANS: 'Student loans (UK)',
      COLUMN_AVERAGE_HOURS_DAY: 'Average hours/day',
      COLUMN_AVERAGE_HOURS_HALF_DAY: 'Average hours/half day',
      COLUMN_AVERAGE_HOURS_WEEK: 'Average hours/week',
      COLUMN_PRE_AVERAGE_HOURS_PER_DAY: 'Pre-Rotaready average hours/day',
      COLUMN_PRE_AVERAGE_HOURS_DATE: 'Pre-Rotaready average hours date',
      COLUMN_PERMISSION_LEVEL_NAME: 'Permission level',
      COLUMN_ANONYMISED: 'Anonymised',
      COLUMN_ANONYMISE_DATE: 'Anonymise date',
      COLUMN_JOIN_DATE: 'Join date',
      COLUMN_LEAVE_DATE: 'Leave date',
      COLUMN_LEAVE_DATE_EDITED_BY: 'Leave date set by',
      COLUMN_LEAVE_DATE_EDITED_DATE: 'Leave date set date',
      COLUMN_LENGTH_OF_SERVICE: 'Length of service (days)',
      COLUMN_RECRUITMENT_SOURCE: 'Recruitment source',
      COLUMN_LEAVER_REASON: 'Leaver reason',
      COLUMN_LEAVER_REHIRE: 'Rehire eligible',
      COLUMN_EMPLOYEE_NUMBER: 'Employee number',
      COLUMN_NOTICE_PERIOD: 'Notice period',
      COLUMN_STARTER_DECLARATION: 'Starter declaration (UK)',
      COLUMN_ALLOWANCES_ATTESTED_FINALISED: 'Allowances finalised',
      COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY: 'Allowances finalised by',
      COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE: 'Allowances finalised date',
      COLUMN_SITE_NAME: 'Site',
      COLUMN_SITE_ID: 'Site ID',
      COLUMN_DEPARTMENT_NAME: 'Department',
      COLUMN_DEPARTMENT_ID: 'Department ID',
      COLUMN_JOB_ROLE_NAME: 'Job role',
      COLUMN_COMPANY_NAME: 'Company',
      COLUMN_CONTRACT_BASIS: 'Contract type',
      COLUMN_LINE_MANAGER_NAME: 'Line manager',
      COLUMN_STAFF_GROUP_NAME: 'Staff group',
      COLUMN_WORKS_PUBLIC_HOLIDAYS: 'Works public holidays',
      COLUMN_WORKING_TIME_OPT_OUT: 'Working Time regulations opt-out',
      COLUMN_MIN_OBLIGATION_VALUE: 'Contractual obligation value',
      COLUMN_MIN_OBLIGATION_UNIT: 'Contractual obligation unit',
      COLUMN_MIN_OBLIGATION_PERIOD: 'Contractual obligation period',
      COLUMN_MAX_OBLIGATION_VALUE: 'Working limit value',
      COLUMN_MAX_OBLIGATION_UNIT: 'Working limit unit',
      COLUMN_MAX_OBLIGATION_PERIOD: 'Working limit period',
      COLUMN_DEPARTMENT_CURRENCY_CODE: 'Currency code',
      COLUMN_PAYROLL_CALENDAR_NAME: 'Payroll calendar',
      COLUMN_PAYROLL_CALENDAR_FREQUENCY: 'Payroll calendar frequency',
      COLUMN_PAY_AMOUNT: 'Pay amount',
      COLUMN_PAY_AMOUNT_TYPE: 'Pay amount type',
      COLUMN_PAY_UPLIFT: 'Pay uplift',
      ASSET_REGISTRY_TAB_LABEL: 'Asset registry',
    },
    VIEW: {
      ACCOUNT: {
        ERROR_500: 'We can\'t process your change right now. Please try again.',
        ERROR_403: 'You don\'t have permission to view/modify this person\'s account information.',
        SAVE_SUCCESS: 'Your changes have been saved.',
        TITLE: 'Account',
        LAST_SIGN_IN_LABEL: 'Last sign-in',
        LAST_SIGN_IN_NEVER: 'Never',
        SECTION_PERMISSIONS_TITLE: 'Permissions',
        PERMISSION_LEVEL_LABEL: 'Permission level',
        PERMISSION_LEVEL_HELP: 'This will alter what the employee can see and do in Rotaready.',
        SITE_ACCESS_LABEL: 'Site access',
        SITE_ACCESS_OPTION_APPOINTMENT: 'Their current site',
        SITE_ACCESS_OPTION_ALL: 'All sites',
        SITE_ACCESS_OPTION_CUSTOM: 'Their current site, and custom sites',
        CLOCK_IN_PIN_LABEL: 'Reset Clock-in PIN',
        CLOCK_IN_PIN_HELP: 'Enter a new 4 digit code to change this employee\'s clock-in PIN.',
        TAGS_LABEL: 'Tags',
        TAGS_TIP: 'Tag employees with anything meaningful, such as skills or attributes.',
        TAGS_PLACEHOLDER: 'Type to find tags',
        SAVE_BUTTON_LOADING: 'Saving...',
        SAVE_BUTTON_TEXT: 'Save',
        LANGUAGE_LABEL: 'Language',
        LANGUAGE_HELP: 'Rotaready will display in the chosen language.',
        ENTITY_GROUP_SUBSCRIPTION_TOOLTIP: 'This employee is automatically assigned to this site. You can manage their access in the Site Access Manager settings page.',
      },
      PAY: {
        INDEX: {
          TAB_HISTORY: 'Pay records',
          TAB_TRONC: 'Tronc',
          TAB_FINANCIALS: 'Financials',
        },
        VIEW_HISTORY: {
          FILTER_TYPES_LABEL: 'Type',
          FILTER_TYPES_SALARY: 'Salary',
          FILTER_TYPES_POINT_IN_TIME: 'One-off payments',
          FILTER_ELEMENT_TYPES_LABEL: 'Pay element',
          STATE_ACTIVE: 'Active',
          STATE_HISTORICAL: 'Historical',
          STATE_FUTURE_DATED: 'Future-dated',
          COLUMN_EFFECTIVE_DATE: 'Effective date',
          COLUMN_STATUS: 'Status',
          COLUMN_ELEMENT_TYPE: 'Pay element',
          COLUMN_CURRENCY: 'Currency',
          COLUMN_AMOUNT: 'Amount',
          COLUMN_AMOUNT_TYPE: 'Amount type',
          COLUMN_OPTIONS: 'Options',
          ERROR_500: 'We can\'t load pay records right now. Please try again.',
          ERROR_403: 'You don\'t have permission to view/modify this person\'s pay records.',
          ALERT_SUCCESS: 'The pay record has been saved.',
          ALERT_DELETED: 'The pay record has been deleted.',
          OUTSIDE_OF_APPOINTMENT_WARNING: 'This pay record is invalid because they aren\'t actively employed on this date. Please change the effective date.',
          DELETE_LINK: 'Delete',
          LAST_EDITED_TOOLTIP: 'Last edited by {{userName}} on {{date}}',
          NO_RECORDS: 'There are no pay records to show.',
          ADD_NEW_BUTTON: 'New pay record',
          CONFIRM_DELETE: 'Are you sure you wish to delete this pay record? This can\'t be undone.',
        },
        PAY_FORM: {
          TITLE: 'Pay',
          LABEL_EFFECTIVE_DATE: 'Effective date',
          LABEL_ELEMENT: 'Pay element',
          LABEL_AMOUNT: 'Amount',
          LABEL_AMOUNT_TYPE: 'Amount type',
          LABEL_UPLIFT: 'Wage uplift',
          ALERT_NO_CURRENCY: 'The employee is not actively employed on this date.',
          ALERT_DUPLICATE_PAY: 'There\'s already a pay record for your chosen pay element on that date.',
          HELP_TEXT_UPLIFT: 'This will inflate the employee\'s wages in Cost Control. Use it to reflect any extra costs you incur, like a pension. Don\'t worry about taxes as these are included automatically.',
          BUTTON_SAVE: 'Save',
          BUTTON_SAVE_LOADING: 'Saving...',
          BUTTON_DELETE: 'Delete',
          BUTTON_DELETE_LOADING: 'Deleting...',
        },
        VIEW_FINANCIALS: {
          ERROR_500: 'We can\'t load the financial information right now. Please try again.',
          ERROR_403: 'You don\'t have permission to view/modify this person\'s financial information.',
          SAVE_SUCCESS: 'Your changes have been saved.',
          SECTION_BANK_ACCOUNT: 'Bank account',
          SECTION_TAXES: 'Taxes',
          ACCOUNT_NUMBER_LABEL: 'Account number',
          SORT_CODE_LABEL: 'Sort code',
          ACCOUNT_NAME_LABEL: 'Account name',
          PAYMENT_METHOD: 'Payment method',
          NI_NUMBER_LABEL: 'National Insurance number',
          SECTION_STUDENT_LOANS: 'Student loans (UK)',
          PLAN_TYPE_LABEL: 'Plan type',
          EFFECTIVE_DATE_LABEL: 'Effective date',
          LOAN_TABLE_PLAN_COLUMN: 'Plan type',
          LOAN_TABLE_DATE_COLUMN: 'Effective date',
          LOAN_TABLE_OPTIONS_COLUMN: 'Options',
          LOAN_TABLE_NO_ROWS: 'No student loans added.',
          LOAN_TABLE_DATE_NOT_SET: 'Not set',
          SUBHEADING_ADD_STUDENT_LOAN: 'Add student loan',
          OPTION_DELETE: 'Delete',
          ADD_LOAN_BUTTON: 'Add student loan',
          SAVE_BUTTON_TEXT: 'Save',
        },
        EDIT_STUDENT_LOAN: {
          HEADING: 'Edit student loan',
          FORM_PLAN_TYPE_LABEL: 'Plan type',
          FORM_EFFECTIVE_DATE_LABEL: 'Effective date',
          SAVE_BUTTON_TEXT: 'Save',
        },
        VIEW_TRONC: {
          COLUMN_EFFECTIVE_DATE: 'Effective date',
          COLUMN_STATUS: 'Status',
          COLUMN_AMOUNT: 'Amount',
          COLUMN_APPORTION_TYPE: 'Apportion type',
          COLUMN_LAST_EDITED: 'Last edited',
          STATE_ACTIVE: 'Active',
          STATE_HISTORICAL: 'Historical',
          STATE_FUTURE_DATED: 'Future-dated',
          APPORTION_TYPE_CLASSIC_HOURLY: 'Hourly rate',
          APPORTION_TYPE_CLASSIC_SALARY: 'Annual salary',
          APPORTION_TYPE_POINTS: 'Points',
          NO_RECORDS: 'There are no Tronc rates to show.',
          ADD_NEW_BUTTON: 'New Tronc rate',
          ERROR_500: 'We can\'t load Tronc rates right now. Please try again.',
          ERROR_403: 'You don\'t have permission to view this person\'s Tronc rates.',
        },
        ADD_TRONC_MODAL: {
          TITLE: 'New Tronc rate',
          BUTTON_SAVE: 'Save',
          BUTTON_CANCEL: 'Cancel',
          LABEL_PERSON: 'Person',
          LABEL_DATE: 'Effective date',
          LABEL_APPORTION_TYPE: 'Apportion type',
          LABEL_AMOUNT: 'Amount',
          APPORTION_TYPE_CLASSIC_HOURLY: 'Hourly rate',
          APPORTION_TYPE_CLASSIC_SALARY: 'Annual salary',
          APPORTION_TYPE_POINTS: 'Points',
          APPORTION_TYPE_HELP: 'Tronc can work in one of two modes: points-based or classic, which uses an hourly rate or salary. This is controlled by a setting for your organisation or the specific site/venue.',
          ERROR_OTHER: 'We can\'t save the Tronc rate right now. Please try again.',
          ERROR_UNAUTHORIZED: 'You don\'t have permission to modify this person\'s Tronc rates.',
          ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE: 'This employee does not have an active appointment on your chosen effective date.',
          ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE: 'Someone has already begun drafting Tronc for your chosen effective date. Please use the Tronc tool to edit rates for this date.',
          ERROR_NOT_SUPPORTED: 'You can\'t choose that apportion type. Tronc isn\'t configured to work in that mode.',
        },
      },
      INDEX: {
        OPTIONS_LABEL: 'Options',
        OPTION_APPROVE_LINK: 'Approve for rotas and payroll',
        OPTION_SEND_MAGIC_LINK: 'Invite to Rotaready (send a magic link)',
        OPTION_SEND_MAGIC_LINK_TOOLTIP: 'This person has already set up their account. If they have trouble logging in, ask them to follow the account recovery link on the login page.',
        OPTION_ANONYMISE: 'Anonymise',
        OPTION_DELETE: 'Delete',
        ALERT_APPROVE_SUCCESS: 'They\'re now approved and ready to be given shifts.',
        ALERT_APPROVE_ERROR: 'We couldn\'t approve the account just then. Please try again.',
        ALERT_MAGIC_LINK_SUCCESS_ALL: 'We\'ve sent an email and a text message with a magic link. Please allow a few minutes for it to arrive.',
        ALERT_MAGIC_LINK_SUCCESS_EMAIL: 'We\'ve sent an email with a magic link. Please allow a few minutes for it to arrive.',
        ALERT_MAGIC_LINK_SUCCESS_SMS: 'We\'ve sent a text message with a magic link. Please allow a few minutes for it to arrive.',
        ALERT_MAGIC_LINK_ERROR_GENERIC: 'We couldn\'t send a magic link just then.',
        ALERT_MAGIC_LINK_ERROR_NO_DETAILS: 'We can\'t send a magic link without an email address or phone number.',
        ALERT_ACCOUNT_ERROR_500: 'We couldn\'t load the employee just then. Please try again.',
        ALERT_ACCOUNT_ERROR_404: 'There is no employee account associated with that ID.',
        DELETE_ACCOUNT_TITLE: 'Delete employee',
        DELETE_ACCOUNT_MESSAGE: 'Warning! Deleting an employee is permanent. Only do this if you created the employee by mistake. To continue, type their full name below.',
        DELETE_ACCOUNT_BUTTON: 'Permanently delete',
        DELETE_ACCOUNT_ERROR: 'The employee could not be deleted. You may not have permission to do this.',
        DELETE_ACCOUNT_SUCCESS: 'The employee has been deleted.',
      },
      PERSONAL: {
        ERROR_500: 'We can\'t process your change right now. Please try again.',
        ERROR_403: 'You don\'t have permission to view/modify this person\'s personal information.',
        SAVE_SUCCESS: 'Your changes have been saved.',
        EMERGENCY_CONTACTS_LINK_TEXT: 'Emergency contacts',
        FINANCIALS_LINK_TEXT: 'Financials/taxes',
        LABEL_OPTIONS: 'More options',
        LABEL_APPROVED_BY: 'Approved by',
        TITLE_LABEL: 'Title',
        FIRST_NAME_LABEL: 'First name',
        LAST_NAME_LABEL: 'Last name',
        OTHER_NAME_LABEL: 'Other names (optional)',
        DOB_LABEL: 'Birth date',
        DOB_HELP: '{{age}} years old',
        PREFERRED_NAME_LABEL: 'Preferred name',
        SECTION_ADDRESS: 'Address',
        SECTION_VISAS: 'Visas',
        SECTION_IDENTITY: 'Identity',
        SECTION_CONTACT: 'Contact',
        SECTION_OTHER: 'Other',
        SECTION_RIGHT_TO_WORK: 'Right to work',
        NATIONALITY_LABEL: 'Nationality',
        GENDER_LABEL: 'Gender',
        ETHNICITY_LABEL: 'Ethnicity',
        MARITAL_STATUS_LABEL: 'Marital status',
        PASSPORT_NUMBER_LABEL: 'Passport number',
        PASSPORT_EXPIRY_LABEL: 'Passport expiry date',
        SETTLED_STATUS_LABEL: 'Settled status (UK)',
        PRIMARY_EMAIL_LABEL: 'Primary email',
        PRIMARY_EMAIL_HELP: 'The employee uses this to login to Rotaready. It can only be changed if they haven\'t logged in before.',
        PRIMARY_PHONE_LABEL: 'Primary phone',
        PRIMARY_PHONE_HELP: 'The employee uses this to receive notifications. The correct country code prefix will be applied automatically after you save.',
        SECONDARY_EMAIL_LABEL: 'Secondary email',
        SECONDARY_PHONE_LABEL: 'Secondary phone',
        HR_ID_LABEL: 'HR ID',
        HR_ID_HELP: 'If this employee has an ID number used for HR purposes, you can store it here.',
        PAYROLL_ID_LABEL: 'Payroll ID',
        PAYROLL_ID_HELP: 'If this employee has an ID number used for payroll purposes, you can store it here.',
        SAVE_BUTTON_LOADING: 'Saving...',
        SAVE_BUTTON_TEXT: 'Save',
        LINK_ADD_ADDRESS: 'Add new address',
        LINK_VIEW_ADDRESS_HISTORY: 'See previous addresses',
        NO_ADDRESS_HISTORY_TEXT: 'There are no previous addresses',
        CURRENT_ADDRESS_LABEL: 'Current',
        SHARE_CODE_LABEL: 'Share code',
        SHARE_CODE_ERROR_LENGTH: 'The share code must be 9 characters long.',
        SHARE_CODE_ERROR_FORMAT: 'The share code must contain only alphanumeric characters.',
        SHARE_CODE_EXPIRY_DATE_LABEL: 'Share code expiry date',
      },
      ADDRESS_HISTORY: {
        ALERT_ERROR_500: 'We can\'t process your change right now. Please try again.',
        TITLE_ADD: 'Add new address',
        TITLE_EDIT: 'Edit address',
        ADVICE_EDIT: 'If the employee\'s address is changing, you should add a new address instead. Rotaready will automatically move the current address to their address history.',
        ADVICE_EDIT_LINK: 'Add new address',
        SAVE_BUTTON_TEXT: 'Save',
        DELETE_BUTTON_TEXT: 'Delete',
        ADDRESS_LINE_1_LABEL: 'Line 1',
        ADDRESS_LINE_2_LABEL: 'Line 2 (optional)',
        ADDRESS_CITY_LABEL: 'Town/city',
        ADDRESS_COUNTY_LABEL: 'County',
        ADDRESS_POST_CODE_LABEL: 'Post code',
        ADDRESS_COUNTRY_LABEL: 'Country',
        NOTES_LABEL: 'Notes',
        CONFIRM_DELETE: 'Are you sure you wish to delete this address?',
      },
      VISAS: {
        ADD_BTN: 'Add visa',
        CURRENT_HEADING: 'Active visa',
        NO_CURRENT_VISA: 'This user has no active visa.',
        CURRENT_LABEL: 'Active',
        VISA_TYPE_LABEL: 'Visa type',
        VISA_NUMBER_LABEL: 'Visa number',
        VISA_EXPIRY_LABEL: 'Visa expiry date',
        VISA_EXPIRY_NOT_APPLICABLE: 'Not applicable',
        VISA_EDIT_BTN: 'Edit visa',
        PREVIOUS_LABEL: 'Previous',
        SHOW_PREVIOUS_BTN: 'Show previous visas',
        HIDE_PREVIOUS_BTN: 'Hide previous visas',
        PREVIOUS_HEADING: 'Previous visa',
        MODAL: {
          ADD_HEADING: 'Add a new Visa',
          ALERT_ERROR_500: 'We can\'t process your change right now. Please try again.',
          FORM_LABEL_VISA_TYPE: 'Visa type',
          FORM_LABEL_VISA_NUMBER: 'Visa number',
          FORM_LABEL_IS_CURRENT: 'This is the user\'s active visa',
          FORM_LABEL_HAS_EXPIRY_DATE: 'This visa has an expiry date',
          FORM_LABEL_VISA_EXPIRY: 'Visa expiry date',
          FORM_BTN_SAVE: 'Save',
          EDIT_VISA_HEADING: 'Edit visa',
          DELETE_VISA_BTN: 'Delete visa',
          CONFIRM_DELETE: 'Are you sure you wish to delete this visa?',
        }
      },
      SCHEDULE: {
        TITLE: 'The calendar below shows {{firstName}}\'s full schedule, including shifts, absence and availability.',
        SHIFTS_LABEL: 'Shifts',
        ABSENCE_LABEL: 'Absence',
        UNAVAILABLE_LABEL: 'Unavailable (patterns)',
        EDIT_LINK_TEXT: 'Edit exceptions',
      },
      ADD_ROUTINE_MAPPING_MODAL: {
        TITLE: 'Map shift routine',
        HAS_OVERLAP_TEXT: 'The above dates overlap with an existing shift routine.',
        END_BEFORE_START_TEXT: 'The start date is before the end date.',
        SAVE_BUTTON_TEXT: 'Save',
        SAVE_BUTTON_LOADING: 'Saving...',
        CANCEL_BUTTON_TEXT: 'Cancel',
      },
      DELETE_ROUTINE_MAPPING_MODAL: {
        TITLE: 'Are you sure?',
        WARNING: 'Deleting this mapping will remove all future shifts associated with it.',
        YES_BUTTON_TEXT: 'Yes',
        YES_BUTTON_ADDITIONAL_TEXT: 'Delete this mapping',
        NO_BUTTON_TEXT: 'No',
        NO_BUTTON_ADDITIONAL_TEXT: 'I\'ve changed my mind',
        CANCEL_BUTTON_TEXT: 'Cancel',
      },
      EDIT_ROUTINE_MAPPING_MODAL: {
        TITLE: 'Edit shift routine mapping',
        END_DATE_LABEL: 'End date',
        CONTINUE_FOREVER_LABEL: 'Continue shift routine forever',
        END_BEFORE_START_TEXT: 'The end date is before the start date.',
        HAS_OVERLAP_NO_END: 'Setting this end date would cause an overlap with an existing shift routine.',
        HAS_OVERLAP_END: 'Setting no end date would cause an overlap with an existing shift routine.',
        SAVE_BUTTON_TEXT: 'Save',
        SAVE_BUTTON_LOADING_TEXT: 'Saving...',
        CANCEL_BUTTON_TEXT: 'Cancel',
      },
      SHIFT_ROUTINES: {
        MAP_ROUTINE_BUTTON_TEXT: 'Map to routine',
        MAP_ROUTINE_SUBTITLE: 'Shift routine mappings',
        MAP_ROUTINE_TIP: 'Mapping someone to a shift routine causes shifts to be created automatically. They\'ll be deleted if you delete the mapping, or if you add an end date.',
        EDIT_END_DATE_TEXT: 'Edit end date',
        ADD_END_DATE_TEXT: 'Add end date',
        MAPPING_NO_END: 'As of ',
        MAPPING_END: ' to ',
        USER_HAS_NO_MAPPING: '{{firstName}} isn\'t mapped to any shift routines. You can add one using the button above.',
      },
      ABSENCE: {
        INDEX: {
          TAB_ABSENCE: 'Absence bookings',
          TAB_ALLOWANCES: 'Allowances',
          TAB_SETTINGS: 'Settings',
        },
        VIEW_SETTINGS: {
          ABSENCE_HOURS_LABEL: 'Average hours per day',
          AVERAGE_LENGTH_LABEL: 'Calculate automatically based on the average duration of their working day',
          SET_MANUALLY_LABEL: 'Set manually',
          HOURS_FULL_DAY_LABEL: 'Full day',
          HOURS_LABEL: 'hours',
          HOURS_HELP: 'A full day\'s absence will be worth this many hours.',
          HOURS_HALF_DAY_LABEL: 'Half day',
          HOURS_HALF_DAY_HELP: 'A half day\'s absence will be worth this many hours.',
          DELEGATE_APPROVERS: 'Delegate approvers',
          DELEGATE_APPROVERS_REMOVE: 'Remove',
          DELEGATE_APPROVERS_HELP: 'If this employee enables Do Not Disturb in the Rotaready app and an absence request is raised that requires their approval, their delegate approvers will be notified instead.',
          SAVE_BUTTON_TEXT: 'Save',
          SAVE_BUTTON_LOADING_TEXT: 'Saving...',
          PRE_ROTAREADY_AVERAGE_HOURS_LABEL: 'Pre-Rotaready average hours per day',
          PRE_ROTAREADY_AVERAGE_HOURS_HELP: 'If you transitioned to Rotaready from another system during this person\'s employment, enter their last known \'average hours per day\' and the date this was valid on.',
          PRE_ROTAREADY_AVERAGE_HOURS_DATE_LABEL: 'Last valid on',
          PRE_ROTAREADY_AVERAGE_HOURS_DATE_HELP: 'When calculating a new value for average hours per day, if the employee has not worked all of the last 52 weeks on Rotaready, our calculation will use the pre-Rotaready value for the weeks prior to this date.',
        },
        VIEW_BOOKINGS: {
          INCLUDE_CANCELLED_TOGGLE_LABEL: 'Include cancelled',
          NO_BOOKINGS_MATCH_TEXT: 'We couldn\'t find any bookings matching your filters.',
          BOOK_ABSENCE_BUTTON_TEXT: 'Book absence',
          CANCEL_LINK: 'Cancel',
          DATES_COLUMN_TEXT: 'Date(s)',
          ABSENCE_TYPE_COLUMN_TEXT: 'Type',
          HOURS_COLUMN_TEXT: 'Hours',
          DAYS_COLUMN_TEXT: 'Days',
          STATUS_COLUMN_TEXT: 'Status',
          SUBMITTED_COLUMN_TEXT: 'Submitted',
          APPROVED_COLUMN_TEXT: 'Reviewed',
          CANCELLED_COLUMN_TEXT: 'Cancelled',
          FILTER_LABEL_APPROVAL_STATE: 'Status',
          FILTER_LABEL_ABSENCE_TYPE: 'Type',
          FILTER_LABEL_DATE: 'Date',
          CANCEL_REQUEST: {
            CONFIRM: 'Are you sure you want to cancel this absence booking? This cannot be undone.',
            SUCCESS: 'The absence booking was cancelled.',
            ERROR_500: 'We couldn\'t cancel that just now. Please try again.',
          },
        },
        VIEW_ALLOWANCES: {
          ERROR_500: 'Oops, we hit a snag just then. Please try again.',
          BUTTON_ADD: 'New allowance',
          BUTTON_OPTIONS: 'Options',
          BUTTON_TRANSACTIONS: 'View transactions',
          BUTTON_EDIT: 'Edit',
          BUTTON_DELETE: 'Delete',
          NO_ALLOWANCES_TEXT: 'There are no allowances matching your filters.',
          FILTER_LABEL_STATUS: 'Status',
          FILTER_LABEL_ABSENCE_TYPE: 'Type',
          CHRONO_TYPE_ACTIVE: 'Active',
          CHRONO_TYPE_FUTURE: 'Future',
          CHRONO_TYPE_HISTORICAL: 'Historical',
          LABEL_NOT_DEDUCTIBLE: 'Not deductible',
          METRIC_ACCRUED: 'Entitlement',
          METRIC_BOOKED: 'Booked',
          METRIC_REMAINING: 'Remaining',
          METRIC_ACCRUED_LIMIT: 'of {{count}}',
          METRIC_ACCRUED_LIMIT_HELP: 'This is the maximum that can be accrued.',
          METRIC_ELAPSED: 'elapsed',
          METRIC_ELAPSED_HELP: 'This is the number of booked days/hours that are in the past. This re-calculates automatically overnight.',
          METRIC_OVERSPEND: 'overspend',
          METRIC_OVERSPEND_HELP: 'This is the number of days/hours that can be booked over the accrued amount.',
          METRIC_ACCRUAL_ESTIMATE: 'to accrue',
          METRIC_ACCRUAL_ESTIMATE_HELP: 'Based on your average working hours, we estimate you will accrue this by the end of the allowance year.',
          SCHEDULE_BEGINNING_OF_ACCRUAL_YEAR: 'Accrues at the beginning of each accrual year',
          SCHEDULE_EACH_CALENDAR_MONTH: 'Accrues each calendar month',
          SCHEDULE_EACH_HOUR_WORKED: 'Accrues for each hour worked',
          SCHEDULE_EACH_HOUR_WORKED_RATE: 'Accrues for each hour worked at {{rate}}%',
          CARRIED_OVER: '{{amount}} carried over from last year',
          LINK_TRANSACTIONS: 'View transactions',
          ALERT_CONFIRM_DELETE: 'Are you sure you want to delete this allowance? This can\'t be undone.',
          ALERT_SUCCESS_DELETE: 'The allowance has been deleted.',
          FINALISED: '🔒 Finalised ({{date}})',
          FINALISE: 'Finalise',
          CONVERT_HOURS_TO_DAYS_TOGGLE_LABEL: 'Convert hours to days',
        },
        ALLOWANCE_VALIDATION_MODAL: {
          TITLE: 'Something\'s not quite right',
          DESCRIPTION: 'Unfortunately what you\'re attempting to do causes one or more issues.',
          BUTTON_CLOSE: 'Close',
          INVALID_ALLOWANCE_TITLE: 'Invalid allowance',
          INVALID_ALLOWANCE_DESCRIPTION: 'The allowance has since been deleted and can no longer be edited.',
          OVERLAPPING_ALLOWANCE_TITLE: 'Overlapping allowances',
          OVERLAPPING_ALLOWANCE_DESCRIPTION: 'This allowance will overlap with an existing allowance. You can\'t have overlapping allowances for the same absence type.',
          FINALISED_ALLOWANCE_TITLE: 'Finalised allowance',
          FINALISED_ALLOWANCE_DESCRIPTION: 'This allowance has been finalised. To edit or delete it, you must first revert the allowance.',
          INVALID_UNIT_TITLE: 'Invalid unit',
          INVALID_UNIT_DESCRIPTION: 'An allowance with the schedule of accrual set to Each Hour Worked must have the unit set to Hours.',
          EFFECTIVE_DATE_OUT_OF_BOUNDS_TITLE: 'Invalid effective date',
          EFFECTIVE_DATE_OUT_OF_BOUNDS_DESCRIPTION: 'The chosen effective date must fall between the start and end of the allowance year.',
          FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_TITLE: 'Invalid reference date',
          FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_DESCRIPTION: 'The reference date must fall between the allowance effective date and the end of the allowance year.',
        },
        ALLOWANCE_FORM: {
          ERROR_500: 'Oops, we hit a snag just then. Please try again.',
          ALERT_SUCCESS_ADD: 'The allowance has been added.',
          ALERT_SUCCESS_UPDATE: 'The allowance has been updated.',
          ALERT_ERROR_CALCULATE_BOOKED: 'We couldn\'t calculate their booked value just now. Please try again.',
          BUTTON_SAVE: 'Save',
          BUTTON_SAVE_LOADING: 'Saving...',
          TITLE: 'Allowance',
          LABEL_YEAR_STARTS: 'Holiday year',
          YEAR_STARTS_HELP: 'Your organisation\'s default holiday year runs from {{settingDate}}.',
          YEAR_STARTS_CUSTOMISE_INITIATE: 'Choose a different day or month.',
          YEAR_STARTS_CUSTOMISE_CANCEL: 'Revert to default day and month.',
          LABEL_EFFECTIVE_DATE: 'Effective date',
          EFFECTIVE_DATE_HELP: 'If this allowance is replacing an existing allowance in the same holiday year, you must choose a date when this is effective from.',
          ALERT_MISALIGNED_YEAR_START: 'This doesn\'t align with your organisation\'s default holiday year.',
          ALERT_MISALIGNED_EFFECTIVE_DATE: 'Your effective date is not within the allowance year.',
          ALERT_ALTERED_EFFECTIVE_DATE: 'Your effective date does not match the holiday year start date. You should only do this if you are replacing an existing allowance in the same year.',
          LABEL_ABSENCE_TYPE: 'Absence type',
          LABEL_SCHEDULE_OF_ACCRUAL: 'Schedule of accrual',
          TOOLTIP_SCHEDULE_OF_ACCRUAL: 'To assign hours or days manually, choose \'beginning of accrual year\'. To have Rotaready assign hours or days each calendar month, choose \'each calendar month\'. To accrue automatically based on hours worked, choose \'each hour worked\'.',
          LABEL_UNIT: 'Unit',
          LABEL_DEDUCTIBLE: 'Deductible',
          TOOLTIP_DEDUCTIBLE: 'Control whether the employee can place absence requests that deduct from this allowance.',
          DEDUCTIBLE_YES: 'Yes',
          DEDUCTIBLE_NO: 'No',
          LABEL_PROJECT: 'Predict accrued while booking',
          TOOLTIP_PROJECT: 'When placing an absence request, predict the hours that are likely to have accrued and include them in the employee\'s accrued figure.',
          PROJECT_YES: 'Yes',
          PROJECT_NO: 'No',
          LABEL_TOTAL_ACCRUED_ANNUALLY: 'Total accrued annually',
          LABEL_TOTAL_ACCRUED: 'Total accrued',
          TOOLTIP_TOTAL_ACCRUED: 'Enter the number of hours or days that this employee has accrued so far. Rotaready will update this automatically going forwards.',
          LABEL_BOOKED: 'Booked',
          TOOLTIP_BOOKED: 'Enter the number of hours or days that this employee has booked. Rotaready will maintain this automatically.',
          ALERT_BOOKED: 'We\'ve calculated this for you based on the absence booked in Rotaready. Only change this if absence has been booked outside of Rotaready.',
          TOOLTIP_BOOKED_BUTTON: 'Automatically calculate',
          LABEL_TOTAL_TO_ACCRUE: 'Maximum to accrue',
          TOOLTIP_TOTAL_TO_ACCRUE_HOURLY: 'Enter the total number of hours or days that this employee is entitled to accrue. Leave blank to not enforce a limit.',
          TOOLTIP_TOTAL_TO_ACCRUE_MONTHLY: 'Enter the total number of hours or days that this employee should accrue for the year.',
          LABEL_ACCRUAL_RATE: 'Accrual rate override',
          TOOLTIP_ACCRUAL_RATE: 'Optionally specify an accrual rate if you wish to override the default rate of the jurisdiction in which the employee works.',
          LABEL_OVERSPEND_LIMIT: 'Overspend',
          TOOLTIP_OVERSPEND_LIMIT: 'Optionally allow the employee to book more hours or days than they have accrued.',
          LABEL_CARRIED_OVER: 'Carried over from last year',
          BUTTON_PRO_RATA: 'Pro-rata',
          LABEL_EMPLOYMENT_START: 'Employment start date',
          LABEL_PRO_RATA_TOTAL: 'Total accrued if employed all year',
          HELP_TEXT_PRO_RATA: 'We\'ll set the total accrued to {{value}}.',
          CREATE_NEXT_YEAR_CHECKBOX: 'Create another allowance for next year using the total accrued if employed all year.',
          ALERT_SUCCESS_CALCULATE_TOTAL_ACCRUED: 'We\'ve calculated this for you using the signed-off hours for this employee.',
          ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED: 'You don\'t have permission to access the signed-off hours for this employee. Without this, we can\'t calculate the total accrued for you.',
          ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED: 'We couldn\'t calculate the total accrued hours automatically. Please try again.',
          TOOLTIP_CALCULATE_TOTAL_ACCRUED: 'Automatically calculate',
        },
        ALLOWANCE_TRANSACTIONS: {
          TITLE: 'Transactions',
          DESCRIPTION: 'Here\'s all the transactions affecting the allowance, displaying the most recent first:',
          TYPE_ACCRUAL_HOURS_WORKED: 'Accrual from hours worked',
          TYPE_ABSENCE_REQUESTED: 'Absence requested',
          TYPE_ABSENCE_REJECTED_OR_CANCELLED: 'Absence rejected or cancelled',
          TYPE_MANUAL_ADJUSTMENT: 'Manual adjustment',
          TYPE_ACCRUAL_SCHEDULE_CHANGED: 'Schedule of accrual changed',
          TYPE_CARRY_OVER: 'Carried over from previous year',
          TYPE_ACCRUAL_TIME_BASED: 'Time-based accrual',
          TYPE_ABSENCE_UPDATED: 'Absence dates changed',
          TYPE_HOURS_PER_DAY_RECALCULATION: 'Hours per day updated',
          ERROR_500: 'Oops, we hit a snag just then. Please try again.',
          NO_TRANSACTIONS: 'There are no transactions to show.',
          BUTTON_CLOSE: 'Close',
          TRANSACTION_TYPE: 'Filter by type',
        },
        VIEW_FINALISATION: {
          TITLE: 'Finalised allowance',
          BUTTON_CLOSE: 'Close',
          BUTTON_REVERT: 'Revert',
          CONFIRM_REVERT: 'Are you sure you wish to revert this allowance? This will also delete the corresponding pay record, if there is one.',
          LABEL_REFERENCE_DATE: 'Reference date',
          LABEL_PAY_RECORD: 'Pay/deduction',
          PAY_SEE_MORE: 'View pay record',
          PAY_NONE: 'No pay is owed to the employee, nor should any pay be deducted.',
          LABEL_NOTES: 'Notes',
          NOTES_NONE: 'No notes were recorded.',
          ERROR_500: 'We couldn\'t save your changes just then. Please try again.',
          ERROR_403: 'You don\'t have permission to modify this person\'s pay records, so we can\'t revert the allowance.',
          ALERT_SUCCESS: 'The allowance has been reverted.',
        },
        FINALISE_ALLOWANCE_FORM: {
          TITLE: 'Finalise allowance',
          BUTTON_SAVE: 'Finalise',
          ERROR_500: 'We couldn\'t save your changes just then. Please try again.',
          ERROR_403: 'You don\'t have permission to modify this person\'s pay records, so we can\'t finalise the allowance.',
          ALERT_SUCCESS: 'The allowance has been finalised.',
          GUIDANCE: 'We recommend finalising an allowance when you can be sure no more absence will be accrued, booked or cancelled. This is usually as close as possible to someone\'s last day.',
          GUIDANCE_FUTURE_YEAR: 'You only have to do this if the employee is leaving in this allowance year.',
          LABEL_EFFECTIVE_DATE: 'Reference date',
          EFFECTIVE_DATE_LEAVER_HELP: 'The allowance will end on this date. As this person is a leaver, we\'ve set it to their last day.',
          EFFECTIVE_DATE_NOT_LEAVER_HELP: 'The allowance will end on this date. The date you choose will be used below to calculate the total accrued hours or days.',
          LABEL_BASIC_PAY: 'Basic pay',
          ALERT_BASIC_PAY_NOT_PERMITTED: 'You don\'t have permission to view this employee\'s pay records, so you can\'t use this feature to create a one-off payment or deduction.',
          ALERT_BASIC_PAY_NOT_AVAILABLE: 'The employee does not have a basic pay record on your chosen reference date.',
          HELP_TEXT_BASIC_PAY: 'This is the employee\'s basic pay on your chosen reference date.',
          LABEL_TOTAL_ACCRUED_LIVE: 'Total accrued',
          HELP_TEXT_TOTAL_ACCRUED_LIVE: 'This is the total accrued hours for this allowance, based on all days that have been signed-off.',
          LABEL_ACCRUED_PRO_RATED: 'Accrued (pro rata)',
          HELP_TEXT_TOTAL_ACCRUED_PRO_RATED: 'Using your chosen reference date, the total accrued hours/days have been pro-rated.',
          LABEL_BOOKED: 'Total booked',
          HELP_TEXT_BOOKED: 'This is the total absence that has been booked to-date, including both pending and approved requests.',
          LABEL_ACCRUED_UNTAKEN: 'Accrued but untaken',
          STATUS_PAYMENT_DUE: 'This means the employee has taken less absence than they have accrued. Many employers choose to pay the employee for this.',
          STATUS_DEDUCTION_DUE: 'This means the employee has taken more absence than they have accrued. Some employers choose to deduct this from the employee\'s final pay slip.',
          LABEL_AVERAGE_HOURS: 'Average hours per day',
          LABEL_ONE_OFF_PAYMENT: 'One-off payment/deduction',
          HELP_TEXT_ONE_OFF_PAYMENT: 'If you enter an amount, Rotaready will create a one-off pay record and tie it to this finalised allowance. For a deduction, enter a negative amount.',
          HELP_TEXT_ONE_OFF_PAYMENT_LINK: 'Help on how to calculate this',
          LABEL_PAY_ELEMENT: 'Pay element',
          LABEL_PAYMENT_DATE: 'Applicable date',
          HELP_TEXT_PAYMENT_DATE: 'Choose carefully to ensure this payment is included in the employee\'s final pay run.',
          LABEL_NOTES: 'Notes',
          PLACEHOLDER_NOTES: 'If you don\'t enter any notes, we\'ll automatically include the figures from the calculation above.',
        },
        FINALISE_ALLOWANCE_PROMPT: {
          TITLE: 'Are all allowances finalised?',
          DESCRIPTION: 'If you\'re happy that all allowances are now finalised, you should confirm this, so departments like payroll can rely on the figures.',
          DESCRIPTION_PART_2: 'You can also do this on the Employment tab at a later date.',
          BUTTON_ATTEST: 'Confirm',
          BUTTON_LATER: 'I have more to do',
          ALERT_ERROR: 'We couldn\'t save your confirmation just then. Please try this on the Employment tab instead.',
        },
      },
      EMERGENCY_CONTACTS: {
        ADD_MODAL: {
          TITLE: 'New emergency contact',
          RELATIONSHIP_LABEL: 'Relationship to employee',
          FIRST_NAME_LABEL: 'First name',
          LAST_NAME_LABEL: 'Last name',
          OTHER_NAME_LABEL: 'Other name(s)',
          PRIMARY_CONTACT_NUMBER_LABEL: 'Primary contact number',
          SECONDARY_CONTACT_NUMBER_LABEL: 'Secondary contact number',
          ADDRESS_LABEL: 'Address',
          SAVE_BUTTON_TEXT: 'Save',
          CLOSE_BUTTON_TEXT: 'Close',
          ALERT_ERROR_500: 'We couldn\'t save your changes just then. Please try again.',
        },
        INDEX: {
          TITLE: 'Emergency contacts',
          RELATIONSHIP_COLUMN_TEXT: 'Relationship',
          NAME_COLUMN_TEXT: 'Name',
          PHONE_COLUMN_TEXT: 'Phone number(s)',
          ADDRESS_COLUMN_TEXT: 'Address',
          NONE_TO_SHOW: 'There are no emergency contacts to show.',
          ADD_BUTTON_TEXT: 'New contact',
          OPTIONS_COLUMN_TEXT: 'Options',
          DELETE_LINK: 'Delete',
          ALERT_ERROR_500: 'We couldn\'t load emergency contacts just then. Please try again.',
          CONFIRM_DELETE: 'Are you sure you wish to delete this emergency contact?\nThis cannot be undone.',
        },
      },
      EMPLOYMENT: {
        LABEL_LENGTH_OF_SERVICE: 'Length of service',
        LABEL_STARTS_IN: 'Starts in',
        LABEL_OPTIONS: 'Options',
        OPTION_NEW_EMPLOYMENT: 'New employment',
        OPTION_END_EMPLOYMENT: 'End employment',
        OPTION_REINSTATE_EMPLOYMENT: 'Reinstate employment',
        OPTION_DELETE_EMPLOYMENT: 'Delete employment',
        TEXT_TERMINATED: 'This employment has been terminated, ending on',
        LABEL_REASON: 'Reason',
        LABEL_REHIRE: 'Rehire',
        LABEL_REHIRE_YES: 'Yes',
        LABEL_REHIRE_NO: 'No',
        BUTTON_ATTEST: 'Confirm',
        TEXT_ALLOWANCES_NOT_FINALISED: 'You have not yet confirmed that the employee\'s absence allowances have been finalised.',
        TEXT_ALLOWANCES_NOT_FINALISED_PART_2: 'This is typically done on or before the employee\'s last day, in advance of their final pay run.',
        TEXT_ALLOWANCES_NOT_FINALISED_LINK: 'View allowances',
        BUTTON_UNDO: 'Undo',
        TEXT_ALLOWANCES_FINALISED: 'Absence allowances have been confirmed as finalised for this employment.',
        LABEL_JOIN_DATE: 'Join date',
        LABEL_NOTICE_PERIOD: 'Notice period',
        BUTTON_SET_NOTICE_PERIOD: 'Set a notice period...',
        LABEL_EMPLOYEE_NUMBER: 'Employee number',
        TOOLTIP_EMPLOYEE_NUMBER: 'Optional. In the UK, HMRC requires a unique number for each employee. We\'ll send this to your payroll provider if a payroll integration is enabled.',
        LABEL_RECRUITMENT_SOURCE: 'Recruitment source',
        LABEL_STARTER_DECLARATION: 'Starter declaration (UK)',
        BUTTON_SAVE: 'Save',
        BUTTON_SAVE_LOADING: 'Saving...',
        BUTTON_NEW_APPOINTMENT: 'New appointment',
        TITLE_APPOINTMENTS: 'Appointments',
        COLUMN_EFFECTIVE_DATE: 'Effective date',
        COLUMN_SITE: 'Site',
        COLUMN_DEPARTMENT: 'Department',
        COLUMN_POSITION: 'Job role',
        COLUMN_LINE_MANAGER: 'Line manager',
        COLUMN_STAFF_GROUP: 'Staff group',
        COLUMN_OBLIGATION: 'Obligation',
        TOOLTIP_ACTIVE_APPOINTMENT: 'This is the active appointment.',
        LINE_MANAGER_NOT_SET: 'Not set',
        MIN_OBLIGATION_ZERO_HOURS: 'Zero hours',
        ERROR_500: 'We can\'t load employments right now. Please try again.',
        ERROR_403: 'You don\'t have permission to view/modify this person\'s employment records.',
        LABEL_HOURS: 'hours',
        LABEL_DAYS: 'days',
        LABEL_WEEK: 'week',
        LABEL_MONTH: 'month',
        LABEL_CURRENT_EMPLOYMENT: 'Current Employment',
        ALERT_CHANGES_SAVED: 'Your changes have been saved.',
        CONFIRM_DELETE: 'Are you sure you want to do this? Deleting an employment is irreversible.',
        CONFIRM_REINSTATE: 'Are you sure you want to do this?\n\nIf someone is re-joining your organisation, you must create a new employment instead. You should only re-instate an employment if it was wrongly ended.',
        VALIDATION_MODAL: {
          TITLE: 'We can\'t make that change',
          DESCRIPTION: 'Unfortunately what you\'re attempting to do causes one or more issues.',
          NO_EMPLOYMENT_TITLE: 'No employment',
          NO_EMPLOYMENT_DESCRIPTION: 'Your change would leave the employee without an employment; there must always be at least one.',
          EMPLOYMENT_OVERLAPPING_TITLE: 'Employments overlapping',
          EMPLOYMENT_OVERLAPPING_DESCRIPTION: 'Your change would cause two employments to overlap:',
          EMPLOYMENT_NOT_TERMINATED_TITLE: 'Employment not terminated',
          EMPLOYMENT_NOT_TERMINATED_DESCRIPTION: 'You must end the previous employment before adding a new one.',
          EMPLOYMENT_NO_APPOINTMENTS_TITLE: 'Employment with no appointments',
          EMPLOYMENT_NO_APPOINTMENTS_DESCRIPTION: 'An employment can\'t be empty, it must contain at least one appointment.',
          APPOINTMENT_OUTSIDE_EMPLOYMENT_TITLE: 'Appointment outside employment',
          APPOINTMENT_OUTSIDE_EMPLOYMENT_DESCRIPTION: 'Your change would cause part of this appointment to fall outside the employment.',
          APPOINTMENT_OVERLAPPING_TITLE: 'Appointments overlapping',
          APPOINTMENT_OVERLAPPING_TITLE_DESCRIPTION: 'Your change would cause two appointments to overlap:',
          APPOINTMENT_NO_EMPLOYMENT_TITLE: 'Appointment with no employment',
          APPOINTMENT_NO_EMPLOYMENT_DESCRIPTION: 'An appointment isn\'t associated with an employment. This shouldn\'t normally happen!',
          SHIFTS_OUTSIDE_EMPLOYMENT_TITLE: 'Shifts outside employment',
          SHIFTS_OUTSIDE_EMPLOYMENT_DESCRIPTION: 'Your change would leave {{count}} shift(s) on dates that fall before the employment starts or after it ends. To proceed, these shifts can be deleted, unassigned (which moves them to Open Shifts), or modified on the rota itself:',
          SHIFT_ENTITY_MISMATCH_TITLE: 'Shifts in different site/department',
          SHIFT_ENTITY_MISMATCH_DESCRIPTION: 'Your change would leave {{count}} shift(s) originating in a department\'s rota that doesn\'t match the department on the employee\'s appointment. To proceed, these shifts can be deleted, unassigned (which moves them to Open Shifts), or modified on the rota itself:',
          ABSENCE_OUTSIDE_EMPLOYMENT_TITLE: 'Absence outside employment',
          ABSENCE_OUTSIDE_EMPLOYMENT_DESCRIPTION: 'Your change would leave {{count}} absence booking(s) on dates that fall before the employment starts or after it ends. To proceed, they must be cancelled:',
          ATTENDANCE_OUTSIDE_EMPLOYMENT_TITLE: 'Attendance outside employment',
          ATTENDANCE_OUTSIDE_EMPLOYMENT_DESCRIPTION: 'Your change would leave {{count}} attendance event(s) on dates that fall before the employment starts or after it ends. To proceed, they must be deleted:',
          ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_TITLE: 'Shift routine mapping outside employment',
          ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_DESCRIPTION: 'Your change would leave {{count}} shift routine mapping(s) on dates that fall before the employment starts or after it ends. To proceed, they must be terminated or deleted:',
          EMAIL_EXISTS_TITLE: 'Email address already exists',
          EMAIL_EXISTS_DESCRIPTION: 'The email address you entered is already in use by another employee. If this person has worked for your organisation before, search for them and add a New Employment.',
          MOBILE_EXISTS_TITLE: 'Mobile phone number already exists',
          MOBILE_EXISTS_DESCRIPTION: 'The mobile phone number you entered is already in use by another employee. If this person has worked for your organisation before, search for them and add a New Employment.',
          INVALID_DATE_TITLE: 'Invalid date',
          INVALID_DATE_DESCRIPTION: 'If you\'re attempting to terminate an employment, the end date you\'ve chosen is before the employment starts.',
          PERIOD_SIGNED_OFF_TITLE: 'Date signed-off',
          PERIOD_SIGNED_OFF_DESCRIPTION: 'Your change overlaps with one or more dates that have been signed-off.',
          PAY_ELEMENT_INVALID_TITLE: 'Invalid pay element',
          PAY_ELEMENT_INVALID_DESCRIPTION: 'The chosen pay element is invalid.',
          PAY_RECORD_SAME_DATE_TITLE: 'Duplicate pay record',
          PAY_RECORD_SAME_DATE_DESCRIPTION: 'A pay record already exists for your chosen pay element on that date.',
          RECORD_MISSING_TITLE: 'Pay record deleted',
          RECORD_MISSING_DESCRIPTION: 'This pay record has been deleted and can no longer be modified.',
          ABSENCE_PAYROLL_CALENDAR_MISMATCH_TITLE: 'Payroll calendar mismatch',
          ABSENCE_PAYROLL_CALENDAR_MISMATCH_DESCRIPTION: 'Your change would leave {{count}} absence booking(s) associated with a different payroll calendar. To proceed, they must be cancelled:',
          PAY_LINKED_TO_FINALISED_ALLOWANCE_TITLE: 'Linked to finalised allowance',
          PAY_LINKED_TO_FINALISED_ALLOWANCE_DESCRIPTION: 'This pay record is linked to a finalised allowance and cannot be edited. To delete it, please find the allowance and revert it.',
          BUTTON_TRY_AGAIN: 'Try again',
          BUTTON_CLOSE: 'Close',
        },
        LEAVER_FORM: {
          TITLE: 'End employment',
          ALERT_SUCCESS: 'The employment has been ended.',
          ERROR_500: 'We couldn\'t save your changes just then. Please try again.',
          LABEL_END_DATE: 'Last day of employment',
          LABEL_REASON: 'Reason for leaving',
          LABEL_REHIRE: 'Re-hire',
          CHECKBOX_REHIRE: 'This employee is considered eligible for re-hire in the future.',
          LABEL_NOTES: 'Notes',
          PLACEHOLDER_NOTES: 'Optionally record some notes associated with this employee\'s departure.',
          BUTTON_SAVE: 'Save',
          BUTTON_SAVE_LOADING: 'Saving...',
        },
        EMPLOYMENT_FORM: {
          TITLE: 'New employment',
          RESET_WARNING: 'The employee\'s account will be marked as unapproved and various attributes will be reset, such as the average hours worked per day and per week.',
          LABEL_START_DATE: 'Employment start date',
          LABEL_NOTICE_PERIOD: 'Notice period',
          LABEL_EMPLOYEE_NUMBER: 'Employee number',
          TOOLTIP_EMPLOYEE_NUMBER: 'Optional. In the UK, HMRC requires a unique number for each employee. We\'ll send this to your payroll provider if a payroll integration is enabled.',
          LABEL_RECRUITMENT_SOURCE: 'Recruitment source',
          LABEL_STARTER_DECLARATION: 'Starter declaration (UK)',
          LABEL_INVITE: 'Invite to Rotaready',
          CHECKBOX_INVITE: 'Send a magic link to this employee, which invites them to set their password and log-in to see their shifts.',
          BUTTON_ADD: 'Add',
          BUTTON_ADD_LOADING: 'Adding...',
          ALERT_SUCCESS: 'The employment has been created.',
          ERROR_500: 'We couldn\'t save your changes just then. Please try again.',
        },
        APPOINTMENT_FORM: {
          TITLE: 'Appointment',
          ALERT_MAPPINGS: 'Please complete the default stream mappings or check the declaration.',
          ALERT_SAVED: 'The appointment has been saved.',
          ALERT_DELETED: 'The appointment has been deleted.',
          ERROR_500: 'We couldn\'t save your changes just then. Please try again.',
          INFO_NEW_APPOINTMENT: 'We\'ve used the most recent appointment to pre-populate this form for you.',
          LABEL_EFFECTIVE_DATE: 'Effective date',
          HELP_TEXT_EFFECTIVE_DATE: 'As this is the first appointment, the effective date is tied to the employment Join Date.',
          INFO_NEW_EMPLOYEE: 'To finish, configure this employee\'s new appointment by setting their site/department, job role and other key attributes.',
          LABEL_LINE_MANAGER: 'Line manager',
          LABEL_LINE_MANAGER_NOT_SET: 'Not set',
          TITLE_JOB_ROLE: 'Job role',
          LABEL_SITE_DEPARTMENT: 'Site/department',
          LABEL_JOB_ROLE: 'Job role',
          LABEL_STAFF_GROUP: 'Staff group',
          HELP_TEXT_STAFF_GROUP: 'Staff groups are used to visually separate people on the rota and across other tools.',
          TITLE_EMPLOYMENT_CONTRACT: 'Employment contract',
          LABEL_COMPANY: 'Company',
          LABEL_PAYROLL_CALENDAR: 'Payroll calendar',
          LABEL_CONTRACT_TYPE: 'Contract type',
          LABEL_PUBLIC_HOLIDAYS: 'Public holidays',
          HELP_TEXT_PUBLIC_HOLIDAYS: 'This employee typically works on public holidays.',
          LABEL_WORKING_TIME: 'Working time regulations',
          HELP_TEXT_WORKING_TIME: 'This employee has opted-out of the optional working limits of the working time regulations.',
          TITLE_CONTRACTUAL_OBLIGATION: 'Contractual obligation',
          LABEL_MIN_OBLIGATION: 'Guaranteed hours/days',
          HELP_TEXT_MIN_OBLIGATION: 'This employee is guaranteed a minimum amount of work.',
          LABEL_MAX_OBLIGATION: 'Working limit',
          HELP_TEXT_MAX_OBLIGATION: 'Enforce an upper limit on the amount of hours or days this employee can be scheduled to work.',
          TITLE_STREAM_MAPPINGS: 'Default stream mappings',
          HELP_TEXT_STREAM_MAPPINGS: 'When this employee works a shift, the shift type\'s mappings determine the stream(s) to allocate their wage cost. If the shift type has none, these are used instead:',
          COLUMN_STREAM: 'Stream',
          COLUMN_ATTRIBUTION: 'Attribution',
          COLUMN_OPTIONS: 'Options',
          OPTION_DELETE: 'Delete',
          NO_MAPPINGS: 'There are none to show.',
          LABEL_ADD_MAPPING: 'Add a new mapping',
          OPTION_ADD: 'Add',
          LABEL_NO_MAPPINGS: 'No mappings',
          CHECKBOX_NO_MAPPINGS: 'I accept that with no default stream mappings, there may be inaccuracies in Cost Control and some reports.',
          HELP_TEXT_NO_MAPPINGS: 'This is okay if you won\'t be tracking this employee\'s wage cost.',
          BUTTON_SAVE: 'Save',
          BUTTON_SAVE_LOADING: 'Saving...',
          BUTTON_DELETE: 'Delete',
          BUTTON_DELETE_LOADING: 'Deleting...',
        },
      },
      DOCUMENTS: {
        FILTER_LABEL_CATEGORY: 'Category',
        BUTTON_UPLOAD: 'Upload file',
        BUTTON_SIGNATURES: 'Document signatures',
        OPTION_SEND_TO_SIGN: 'Send a document to sign',
        OPTION_PENDING_SIGNATURES: 'View documents awaiting signature',
        ERROR_500: 'We can\'t load documents right now. Please try again.',
        ERROR_403: 'You don\'t have permission to view/modify this person\'s documents.',
        ERROR_NO_CATEGORIES: 'Documents aren\'t enabled for your organisation. Chat with us to get started.',
        TOOLTIP_CATEGORY_NOT_APPLICABLE: 'Category marked as not required',
        TOOLTIP_CATEGORY_INVALID: 'Document missing or expired',
        INFO_CATEGORY_NOT_APPLICABLE: 'This category has been marked as not required for this employee.',
        LINK_MARK_CATEGORY_REQUIRED: 'Mark as required',
        LINK_MARK_CATEGORY_NOT_REQUIRED: 'Mark as not required',
        INFO_CATEGORY_NO_DOCUMENTS: 'There are no documents in this category.',
        COLUMN_NAME: 'Name',
        COLUMN_EXPIRES: 'Expires',
        COLUMN_FILE_SIZE: 'File size',
        COLUMN_UPLOADED_BY: 'Uploaded by',
        COLUMN_OPTIONS: 'Options',
        LINK_DELETE: 'Delete',
        LINK_EDIT: 'Edit',
        ALERT_CONFIRM_DELETE: 'Are you sure you wish to delete this document? This can\'t be undone!',
        TOOLTIP_SIGNATURE_ELECTRONIC: 'This document was signed electronically through Rotaready',
        TOOLTIP_SIGNATURE_MANUAL: 'This document was signed manually',
        TOOLTIP_NOTES: 'Notes are available',
        PILL_SENSITIVE: 'Sensitive',
        PILL_CAN_EXPIRE: 'Can expire',
        PILL_VISIBLE_TO_EMPLOYEE: 'Visible to employee',
        TOOLTIP_ACKNOWLEDGED: 'Acknowledged: {{date}}',
        PERSONAL: {
          TITLE: 'My documents',
          ALERT_CONFIRM_ACKNOWLEDGE: 'By clicking acknowledge you confirm you have read and understood the document.',
          INFO_NO_DOCUMENTS: 'You have no documents to view.',
          LINK_ACKNOWLEDGE: 'Acknowledge',
          PILL_NEEDS_ACKNOWLEDGEMENT: 'Needs acknowledgement',
          PILL_ACKNOWLEDGED: 'Acknowledged',
          COLUMN_NAME: 'Name',
          COLUMN_CATEGORY: 'Category',
          COLUMN_EXPIRES: 'Expires',
          COLUMN_FILE_SIZE: 'File size',
          COLUMN_OPTIONS: 'Options',
          FILTER_LABEL_CATEGORY: 'Category',
        },
      },
      DOCUMENT_EDIT_FORM: {
        TITLE: 'Edit document',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_SAVE: 'Save',
        LABEL_EXPIRY_DATE: 'Expiry date',
        LABEL_NOTES: 'Notes',
        HELP_TEXT_EXPIRY: 'The document must be given an expiry date if the category requires one. You\'ll be alerted to take action as the date approaches and again if it lapses.',
        ALERT_SUCCESS: 'The document has been updated.',
        ALERT_ERROR_500: 'There was an error updating the document. Please try again or contact us if this message persists.',
        ALERT_ERROR_400: 'There\'s something not quite right about your changes.',
      },
      DOCUMENT_UPLOAD_MODAL: {
        TITLE: 'Upload a document',
        ALERT_SIGNATURE: 'We\'ll mark this document as manually signed.',
        LABEL_UPLOAD_TO: 'Upload to',
        LABEL_CATEGORY: 'Category',
        LABEL_EXPIRY_DATE: 'Expiry date',
        HELP_TEXT_EXPIRY: 'The document must be given an expiry date if the category requires one. You\'ll be alerted to take action as the date approaches and again if it lapses.',
        LABEL_UPLOAD: 'Upload',
        LABEL_NOTES: 'Notes',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_SAVE: 'Save',
        ALERT_SUCCESS: 'Your document has been uploaded.',
        ALERT_ERROR_500: 'There was an error uploading the document. Please try again or contact us if this message persists.',
        ALERT_ERROR_400: 'There\'s something not quite right about your category and expiry date.',
      },
      DOCUMENT_SIGNATURE_MODAL: {
        ALERT_PICK_CATEGORY: 'Please select a category to save the document to.',
        ALERT_SUCCESS_MANUAL: 'Your document has been downloaded.',
        ALERT_SUCCESS_ELECTRONIC: 'Your document has been sent for e-signature.',
        ALERT_ERROR_500: 'There was an error building the document. Please try again or contact us if this message persists.',
        ALERT_ERROR_400: 'We couldn\'t build your document. Check your Merge Tags and try again.',
        TITLE: 'Send a document to sign',
        ALERT_INFO: 'By placing Merge Tags in your template or file, Rotaready can insert key pieces of information about the employee you\'re sending it to, like their name or job role. It\'s also how you control where the signature box appears.',
        ALERT_INFO_LINK: 'Find out how',
        LABEL_SEND_TO: 'Send to',
        LABEL_DOCUMENT: 'Document',
        LABEL_DOCUMENT_TEMPLATE: 'Use an existing template',
        LABEL_DOCUMENT_UPLOAD: 'Upload a one-off file',
        LABEL_TEMPLATE: 'Template',
        ALERT_NO_TEMPLATES_TITLE: 'You don\'t have any templates.',
        ALERT_NO_TEMPLATES_HELP: 'Save time, upload a file once and re-use it for multiple people.',
        ALERT_NO_TEMPLATES_BUTTON: 'Upload a template',
        LABEL_UPLOAD: 'Upload',
        SECTION_SIGNING_INFO: 'Signing information',
        LABEL_TITLE: 'Title',
        HELP_TEXT_TITLE: 'Give this document a title so the person you\'re sending it to knows what it is.',
        LABEL_EMPLOYER_SIGNS: 'Employer signs',
        OPTION_EMPLOYER_SIGN_FALSE: 'No, only the employee',
        OPTION_EMPLOYER_SIGN_TRUE: 'A signature on behalf of the employer is required',
        LABEL_EMPLOYER_SIGNATORY: 'Employer signatory',
        HELP_TEXT_EMPLOYER_SIGNATORY: 'Select who should sign this document on behalf of the employer.',
        LABEL_SIGNATURE_METHOD: 'Signature method',
        OPTION_SIGNATURE_METHOD_MANUAL: 'Manual, with pen and paper',
        OPTION_SIGNATURE_METHOD_ELECTRONIC: 'Electronic, with fully legal e-signature',
        LABEL_EMAIL_MESSAGE: 'Email message',
        HELP_TEXT_EMAIL_MESSAGE: 'Add an optional message to the email notification sent to all signing parties.',
        SECTION_UPON_COMPLETION: 'Upon completion',
        SECTION_METHOD: 'Signature method',
        LABEL_SAVE_TO: 'Save to',
        HELP_TEXT_SAVE_TO: 'When signed, the file will be saved to the employee\'s documents under the chosen category.',
        LABEL_EXPIRY_DATE: 'Expiry date',
        HELP_TEXT_EXPIRY_DATE: 'The document must be given an expiry date if the destination category requires one. You\'ll be alerted to take action as the date approaches and again if it lapses.',
        CREDITS_DEPLETED_TEXT: 'You need credits to send this document for electronic signature.',
        CREDITS_DEPLETED_CTA: 'Chat with us now',
        CREDITS_AVAILABLE_TEXT: 'You have <b>{{balance}}</b> credit(s) remaining. Sending this document will use one credit.',
        CREDITS_AVAILABLE_CTA: 'It\'s easy to top-up your credits. Just send us a message on live chat.',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_DOWNLOAD_PREVIEW: 'Download preview',
        BUTTON_SAVE_DOWNLOAD: 'Save & download',
        BUTTON_SEND: 'Send',
      },
      NOTES: {
        ERROR_500: 'We can\'t load notes right now. Please try again.',
        ALERT_CONFIRM_DELETE: 'Are you sure you wish to delete this note?',
        BODY_PLACEHOLDER: 'Write a new note...',
        SAVE_BUTTON_TEXT: 'Add note',
        VISIBILITY_SELF: 'Private',
        VISIBILITY_SELF_HELP: 'Only visible by you',
        VISIBILITY_SENSITIVE: 'Sensitive',
        VISIBILITY_SENSITIVE_HELP: 'Only visible by people with permission to see sensitive notes',
        VISIBILITY_GENERAL: 'General',
        VISIBILITY_GENERAL_HELP: 'Only visible by people with permission to see this employee\'s notes',
        EDITED_LABEL: 'Edited',
        EDIT_LABEL: 'Edit',
        DELETE_LABEL: 'Delete',
        NO_NOTES: 'There are no notes to show.',
      },
      DANGER_ZONE: {
        ANONYMISE: {
          TITLE: 'Anonymise employee',
          ERROR_500: 'We couldn\'t save just then. Please try again.',
          ERROR_403: 'You don\'t have permission to access this employee\'s personal details. This means you are unable to schedule their profile to be anonymised.',
          LABEL_END_DATE: 'Employment end date',
          HELP_TEXT: 'To help comply with data protection laws, Rotaready can remove all personal information from this employee profile after a chosen period of time. In the UK, HMRC requires records to be kept for a minimum of six years.',
          HELP_TEXT_LEARN: 'Learn more',
          LABEL_ANONYMISE: 'Anonymise',
          CHECKBOX_ANONYMISE: 'Anonymise this employee',
          LABEL_ANONYMISE_DATE: 'Anonymise on',
          ALERT_ANONYMISE_DATE: 'To anonymise this employee, please choose a date that is both in the future and falls after the employee\'s last day at work.',
          BUTTON_SAVE: 'Save',
        },
      },
    },
    ABSENCE: {
      ABSENCE: {
        TITLE: 'Absence calendar',
        FILTER_LABEL_APPROVAL_STATE: 'Status',
        FILTER_LABEL_POSITION: 'Job role',
        FILTER_LABEL_STAFF_GROUP: 'Staff group',
        ERROR_500: 'We couldn\'t load absence just then. Please try again.',
        FILTER_LABEL_ABSENCE_TYPE: 'Type',
        FILTER_LABEL_REQUESTER: 'Requester',
      },
    },
    REQUESTS: {
      ABSENCE: {
        DATES_COLUMN_TEXT: 'Date(s)',
        WHO_COLUMN_TEXT: 'Who',
        ABSENCE_TYPE_COLUMN_TEXT: 'Type',
        SUBMITTED_DATE_TEXT: 'Submitted',
        HOURS_COLUMN_TEXT: 'Hours',
        DAYS_COLUMN_TEXT: 'Days',
        NO_STAFF_MATCH_TEXT: 'We couldn\'t find any requests matching your filters.',
        FILTER_LABEL_POSITION: 'Job role',
        FILTER_LABEL_STAFF_GROUP: 'Staff group',
        ERROR_500: 'We couldn\'t load absence just then. Please try again.',
        FILTER_LABEL_ABSENCE_TYPE: 'Type',
        FILTER_LABEL_REQUESTER: 'Requester',
      },
      CONFIRM_SWAP_APPROVAL_MODAL: {
        TITLE: 'Are you sure?',
        VALIDATION_MANUAL_TEXT: 'In order to undo this shift swap you\'ll have to manually edit the rota.',
        YES_TITLE: 'Yes',
        APPROVE_BUTTON_TEXT: 'Approve this swap now and notify both staff members',
        NO_TITLE: 'No',
        DO_NOTHING_BUTTON_TEXT: 'Do nothing - don\'t approve this swap just yet',
        CANCEL_BUTTON_TEXT: 'Cancel',
      },
      INDEX: {
        HEADING: 'Items requiring attention',
        ABSENCE_LABEL: 'Absence requests',
        SWAPS_LABEL: 'Shift swaps',
        DOCUMENTS_EXPIRING_LABEL: 'Expiring documents',
        ACCOUNT_APPROVAL_LABEL: 'New joiners',
      },
      SWAPS: {
        INITIATED_BY_TEXT: 'Initiated by',
        INITIATED_TOOLTIP: 'The staff member who wants to give their shift away',
        SHIFT_COLUMN_TEXT: 'Their shift',
        SWAPPING_WITH_COLUMN_TEXT: 'Swapping with',
        SWAPPING_TOOLTIP: 'The staff member who wants to work the shift',
        RETURN_SHIFT_COLUMN_TEXT: 'Their shift',
        OPTION_COLUMN_TEXT: 'Options',
        ACCEPTED_DATE_COLUMN_TEXT: 'Waiting since',
        NONE_TEXT: 'None',
        NO_STAFF_MATCH_TEXT: 'We couldn\'t find any requests matching your filters.',
        FILTER_LABEL_PARTICIPANT: 'Participant',
      },
      DOCUMENTS: {
        NAME_COLUMN_LABEL: 'Name',
        FILE_NAME_COLUMN_LABEL: 'Document',
        FILE_SIZE_COLUMN_LABEL: 'Size',
        CATEGORY_COLUMN_LABEL: 'Category',
        EXPIRY_DATE_COLUMN_LABEL: 'Expires',
        OPTIONS_COLUMN_LABEL: 'Options',
        EXPIRED: 'Expired',
        UPLOAD_NEW_LINK: 'Upload new',
        NO_DOCS_MATCH_TEXT: 'We couldn\'t find any documents matching your filters.',
        NO_CATEGORIES_TEXT: 'You don\'t have any categories for documents that can expire.',
        FILTER_LABEL_SITE: 'Site/department',
        FILTER_LABEL_APPOINTMENT: 'Appointment',
        FILTER_LABEL_PERSON: 'Person',
        FILTER_LABEL_CATEGORY: 'Category',
        FILTER_LABEL_EXPIRY: 'Expiry',
        APPOINTMENT_STATUS_ACTIVE: 'Active',
        APPOINTMENT_STATUS_INACTIVE: 'Inactive',
        X_WEEKS_OR_LESS: ' weeks or less',
      },
      NEW_JOINERS: {
        NAME_COLUMN_LABEL: 'Name',
        POSITION_COLUMN_LABEL: 'Job role',
        ENTITY_COLUMN_LABEL: 'Site',
        START_DATE_COLUMN_LABEL: 'Starts on',
        OPTIONS_COLUMN_LABEL: 'Options',
        NO_STAFF_MATCH_TEXT: 'We couldn\'t find anyone matching your filters.',
        APPROVE_LINK: 'Approve',
        FILTER_LABEL_APPOINTMENT: 'Appointment',
        FILTER_LABEL_POSITION: 'Job role',
        FILTER_LABEL_DATE_START: 'Start date',
        APPOINTMENT_STATUS_ACTIVE: 'Active',
        APPOINTMENT_STATUS_INACTIVE: 'Inactive',
      },
    },
    TAGS_GROUPS: {
      TAGS_GROUPS: {
        HEADING: 'Tags & groups',
        ASIDE_TEXT: 'A staff member can only belong to one group, but they can be assigned many tags. Groups are akin to someone\'s department or team, while tags can represent anything you find meaningful, like skills or personal qualities.',
        CREATE_NEW_TAG_PLACEHOLDER: 'Create new tag...',
        ADDING_BUTTON_TEXT: 'Adding...',
        ADD_BUTTON_TEXT: 'Add',
        TAG_HELP: 'Click on a tag to edit it and to see who\'s assigned:',
        CREATE_ONE_TEXT: 'You currently have no tags. Why not create one?',
        CREATE_NEW_GROUP_PLACEHOLDER: 'Create new group...',
        GROUPS_TITLE: 'Groups',
        EDIT_LINK_TEXT: 'edit',
      },
      VIEW_GROUP_MODAL: {
        EDIT_GROUP_TITLE: 'Edit group',
        SITE_LABEL: 'Site',
        EDIT_TAB_HEADING: 'Edit',
        ORG_WIDE_LABEL: 'Organisation-wide',
        GROUP_NAME_LABEL: 'Group name',
        AVAILABILITY_LABEL: 'Availability',
        ALWAYS_AVAILABLE_TEXT: ' Always available',
        ALWAYS_AVAILABLE_HELP: 'Staff in this group are considered always available for work, except when their availability patterns or absence dictate otherwise.',
        ALWAYS_UNAVAILABLE_TEXT: ' Always unavailable',
        ALWAYS_UNAVAILABLE_HELP: 'Staff in this group are by default not available. They will use \'exceptions\' and \'single week availability\' to mark when they are free.',
        MEMBERS_TAB_HEADING: 'Members',
        NO_ACTIVE_MEMBERS_HELP: 'There are no active members in this group.<br /><br /> Staff are assigned to a group through their appointment. Find someone in the staff list, click the Appointments tab and edit an existing appointment or add a new one.',
        DELETE_TAB_HEADING: 'Delete',
        MOVE_MEMBERS_TO_LABEL: 'Move members to',
        DELETE_TAB_ALERT: 'Deleting this group will cause {{totalMembers}} people to move into a different group. This is irreversible and cannot be undone.',
        CONFIRM_NAME_LABEL: 'Type group name',
        CONFIRM_NAME_HELP_TEXT: 'To convince us that you want to do this, please type the name of the group into the box.',
        DELETING_BUTTON_TEXT: 'Deleting...',
        DELETE_BUTTON_TEXT: 'Delete',
        SAVING_BUTTON_TEXT: 'Saving...',
        SAVE_BUTTON_TEXT: 'Save',
        CANCEL_BUTTON_TEXT: 'Cancel',
      },
      VIEW_TAG_MODAL: {
        EDIT_TAG_TITLE: 'Edit tag',
        EDIT_CLONE_LABEL: 'Edit/clone',
        EDIT_EXISTING_TAG_TEXT: ' Edit existing tag',
        CLONE_TAG_TEXT: ' Clone tag and save as new',
        TAG_NAME_LABEL: 'Tag name',
        STAFF_MEMBERS_LABEL: 'Staff members',
        TAG_TYPEAHEAD_PLACEHLDER: 'Type to tag staff',
        LAST_EDITED_LABEL: 'Last edited',
        ON_TEXT: "on {{date|momentFormat:'llll'}}",
        DELETING_BUTTON_TEXT: 'Deleting...',
        DELETE_BUTTON_TEXT: 'Delete',
        SAVING_BUTTON_TEXT: 'Saving...',
        SAVE_BUTTON_TEXT: 'Save',
        CLONING_BUTTON_TEXT: 'Cloning...',
        CLONE_BUTTON_TEXT: 'Clone',
        CANCEL_BUTTON_TEXT: 'Cancel',
        CLOSE_BUTTON_TEXT: 'Close',
      },
    },
    ADD_NEW: {
      TITLE: 'Add an employee',
      LABEL_FIRST_NAME: 'First name',
      LABEL_LAST_NAME: 'Last name',
      LABEL_DOB: 'Date of birth',
      LABEL_EMAIL: 'Email address',
      HELP_TEXT_EMAIL_OIDC: 'This must exactly match what the employee uses for your Single Sign-On service. It usually ends with your company domain name.',
      HELP_TEXT_EMAIL_ROTAREADY: 'Everyone has an account on Rotaready and uses their email address to sign-in.',
      LABEL_COPY_EXISTING: 'Copy an existing employee',
      PLACEHOLDER_COPY_EXISTING_NO_THANKS: 'No thanks',
      HELP_TEXT_COPY_EXISTING: 'Copy the appointment, holiday allowance and tags from another employee.',
      LABEL_DATE_START: 'Employment start date',
      LABEL_SITE: 'Site/department',
      LABEL_PERMISSIONS: 'Permission level',
      LABEL_INVITE: 'Invite to Rotaready',
      HELP_TEXT_INVITE_OIDC: 'Send a magic link to this employee, which invites them to log-in for the first time.',
      HELP_TEXT_INVITE_ROTAREADY: 'Send a magic link to this employee, which invites them to set their password and log-in to see their shifts.',
      LABEL_ONBOARDING: 'Onboarding',
      CHECKBOX_ONBOARDING_OPT_IN: 'Require this employee to complete self-onboarding when they first sign-in to Rotaready. Depending on your organisation\'s settings, they\'ll be asked to provide personal information like their address, emergency contacts and right-to-work documentation.',
      BUTTON_ADD: 'Add',
      BUTTON_ADD_LOADING: 'Adding...',
      ALERT_SUCCESS: 'Success! Finish setting up your employee by completing each tab.',
      ALERT_500: 'We couldn\'t add the employee just then. Please try again.',
      ALERT_403: 'You don\'t have permission to add a new employee.',
      ALERT_403_PERMISSION_LEVEL: 'You don\'t have permission to add someone at this permission level.',
      ALERT_403_COPY_EXISTING: 'You don\'t have permission to copy this employee.',
      ALERT_403_ENTITY: 'You don\'t have permission to add someone to this site/department.',
      DUPLICATE_MODAL: {
        TITLE: 'Existing employees',
        DESCRIPTION: 'The person you\'re trying to add shares some attributes with one or more existing employees. If this person has worked for your organisation before, add a New Employment.',
        COLUMN_NAME: 'Name',
        COLUMN_EMPLOYMENT: 'Employment',
        COLUMN_SITE: 'Site/department',
        COLUMN_SIMILARITIES: 'Similarities',
        COLUMN_OPTIONS: 'Options',
        STATUS_ACTIVE: 'Active',
        STATUS_INACTIVE: 'Inactive',
        OPTION_VIEW: 'View employee',
        OPTION_NEW_EMPLOYMENT: 'New employment',
        BUTTON_PROCEED: 'Proceed anyway',
        BUTTON_BACK: 'Go back',
        SIMILARITY_NAME: 'Name',
        SIMILARITY_EMAIL: 'Email',
        SIMILARITY_MOBILE: 'Mobile phone number',
        SIMILARITY_DOB: 'Date of birth',
      },
    },
  },
  DIRECTIVES: {
    STAFF: {
      SEARCH: {
        PLACEHOLDER: 'Search...',
        AND_X_MORE: 'And {{x}} more...',
        INACTIVE_APPOINTMENT: 'Inactive',
      },
      ADD_ROUTINE_MAPPING_FORM: {
        STARTS_LABEL: 'Starts',
        STARTS_TEXT: '{{firstName}}\'s first day on this routine',
        ENDS_INPUT_TEXT: ' Ends',
        ENDS_TEXT: '{{firstName}}\'s last day on this routine',
        ONGOING_TEXT: 'Ongoing',
        SHIFT_ROUTINE_LABEL: 'Shift routine',
        OFFSET_LABEL: 'Offset',
        OFFSET_HELP: 'This is the number of days {{firstName}} should start in to this pattern.',
      },
      AVAILABILITY_RULE: {
        IM_UNAVAILABLE_TEXT: 'I\'ll be unavailable',
        CANCEL_TEXT: 'cancel',
        AVAILABLE_TEXT: 'AVAILABLE',
        UNAVAILABLE_TEXT: 'UNAVAILABLE',
        AVAILABLE_FROM_TEXT: 'I\'m only available from ',
        UNAVAILABLE_FROM_TEXT: 'I\'m unavailable from ',
        TO_TEXT: ' to ',
        ALL_DAY: 'All day',
        AM_ONLY: 'AM only',
        PM_ONLY: 'PM only',
        AVAIALABLE_CUSTOM: 'Available (Custom)',
        UNAVAILABLE_CUSTOM: 'Unavailable (Custom)',
        UNAVAILABLE: 'Unavailable',
        AVAILABLE: 'Available',
        AVAILABLE_AM: 'Available (AM only)',
        AVAILABLE_PM: 'Available (PM only)',
      },
      AUDIT_USER_WIDGET: {
        EDITED_BY: 'Edited by',
      },
      VALIDATION_BULK_ABSENCE_EDIT: {
        INFO_TRUNCATED: 'Due to the volume of absence bookings affected, they can\'t be edited in bulk here. Please use the Absence tab on the employee\'s profile or contact Rotaready for further assistance.',
        INFO_SUCCESS: 'There are no conflicting absence bookings remaining.',
        ERROR_500: 'We hit an unexpected error just then. Please try again.',
        ERROR_400: 'That change can\'t be made because it overlaps with a date that has been signed-off.',
        COLUMN_DATES: 'Date(s)',
        COLUMN_TYPE: 'Type',
        COLUMN_HOURS: 'Hours',
        COLUMN_DAYS: 'Days',
        COLUMN_STATUS: 'Status',
        COLUMN_OPTIONS: 'Options',
        OPTION_CANCEL: 'Cancel',
      },
      VALIDATION_BULK_ATTENDANCE_EDIT: {
        INFO_TRUNCATED: 'Due to the volume of attendance events affected, they can\'t be edited in bulk here. Please use the Attendance tab on the employee\'s profile or contact Rotaready for further assistance.',
        INFO_SUCCESS: 'There are no conflicting attendance events remaining.',
        ERROR_500: 'We hit an unexpected error just then. Please try again.',
        ERROR_400: 'That change can\'t be made because it coincides with a date that has been signed-off.',
        COLUMN_EVENT: 'Event',
        COLUMN_DATE: 'Date',
        COLUMN_OPTIONS: 'Options',
        EVENT_TYPE_CLOCK_IN: 'Clock-in',
        OPTION_DELETE: 'Delete',
      },
      VALIDATION_BULK_ROUTINE_MAPPING_EDIT: {
        INFO_TRUNCATED: 'Due to the volume of shift routine mappings affected, they can\'t be edited in bulk here. Please use the Shift Routines tab on the employee\'s profile or contact Rotaready for further assistance.',
        INFO_SUCCESS: 'There are no conflicting shift routine mappings remaining.',
        ERROR_500: 'We hit an unexpected error just then. Please try again.',
        ERROR_400: 'That change can\'t be made because it overlaps with a date that has been signed-off.',
        COLUMN_START_DATE: 'Start date',
        COLUMN_END_DATE: 'End date',
        COLUMN_SHIFT_ROUTINE: 'Shift routine',
        COLUMN_OPTIONS: 'Options',
        LABEL_OPEN_ENDED: 'Open-ended',
        OPTION_ADD_END_DATE: 'Add end date',
        OPTION_DELETE: 'Delete',
      },
      VALIDATION_BULK_SHIFT_EDIT: {
        INFO_TRUNCATED: 'Due to the volume of shifts affected, they can\'t be edited in bulk here. Please amend the corresponding rotas or contact Rotaready for further assistance.',
        INFO_SUCCESS: 'There are no conflicting shifts remaining.',
        ERROR_500: 'We hit an unexpected error just then. Please try again.',
        ERROR_400: 'That change can\'t be made. It might overlap with a date that has been signed-off. To find out more, try this on the rota itself.',
        BUTTON_UNASSIGN_SELECTED: 'Un-assign selected',
        BUTTON_DELETE_SELECTED: 'Delete selected',
        COLUMN_DATE: 'Date',
        COLUMN_TIME: 'Time',
        COLUMN_DEPARTMENT: 'Department',
        COLUMN_TYPE: 'Type',
        COLUMN_STATUS: 'Status',
        COLUMN_OPTIONS: 'Options',
        STATUS_PUBLISHED: 'Published',
        STATUS_DRAFT: 'Draft',
        OPTION_UNASSIGN: 'Un-assign',
        OPTION_DELETE: 'Delete',
      },
      VALIDATION_EPHEMERAL_RECORDS: {
        LABEL_OPEN_ENDED: '(open-ended)',
        LABEL_NEW: 'New',
        LABEL_EXISTING: 'Existing',
        EMPLOYMENT: 'Employment',
        APPOINTMENT: 'Appointment',
      },
      ACCOUNT_COMPLETION_TRACKER: {
        CONSTITUENT_PERSONAL: 'Personal',
        CONSTITUENT_EMPLOYMENT: 'Employment',
        CONSTITUENT_PAY: 'Pay',
        CONSTITUENT_HOLIDAY_ALLOWANCE: 'Holiday allowance',
      },
    },
    CALENDAR: {
      TIP: 'To request absence just click on a day, or click and drag to select multiple days at once.',
      TODAY: 'Today',
      MONTH: 'Month',
      WEEK: 'Week',
      DAY: 'Day',
    },
    HIERARCHY_ITEM_PICKER: {
      PICKER: 'Picker',
      PLACEHOLDER: 'No {{ itemDescriptor }} selected',
      FILTER_TO: 'Filter to...',
      RESET: 'Reset',
      REMOVED_BY_FILTER: 'Your selected {{ itemDescriptor }} has been removed by the filter.',
      NO_SELECTED: 'No {{itemDescriptor}} selected',
    },
    SUBSCRIBE_BUTTON: {
      LABEL_ETID_1: 'requested',
      LABEL_ETID_2: 'cancelled',
      LABEL_ETID_3: 'approved',
      LABEL_ETID_4: 'rejected',
      LABEL_ETID_5: 'marked for approval',
      LABEL_ETID_6: 'published',
      LABEL_ETID_7: 'reverted to draft',
      LABEL_ETID_8: 'week is signed-off',
      LABEL_ETID_9: 'pending approval',
      LABEL_ETID_10: 'approved',
      LABEL_ETID_11: 'requested',
      LABEL_ETID_13: 'approved',
      LABEL_ETID_14: 'rejected',
      LABEL_ETID_15: 'cancelled',
      LABEL_ETID_16: 'approaching expiry',
      LABEL_ETID_17: 'expired',
      LABEL_ETID_29: 'started',
      LABEL_ETID_30: 'accepted by someone',
      LABEL_ETID_31: 'rejected by someone',
      LABEL_ETID_32: 'completed',
      LABEL_ETID_36: 'week is reversed',
      LABEL_ETID_37: 'day is signed-off',
      LABEL_ETID_38: 'day is reversed',
      DESCRIPTOR_ABSENCE: 'an absence request is',
      DESCRIPTOR_ROTA: 'a rota is',
      DESCRIPTOR_ACCOUNT: 'an employee is',
      DESCRIPTOR_SWAP: 'a shift swap is',
      DESCRIPTOR_COST_CONTROL: 'a cost control',
      DESCRIPTOR_DOCUMENT: 'a document is',
      DESCRIPTOR_BROADCAST: 'a shift broadcast is',
      NOUN_ABSENCE: 'absence booking',
      NOUN_ROTA: 'rota',
      NOUN_ACCOUNT: 'employee',
      NOUN_SWAP: 'shift swap',
      NOUN_COST_CONTROL: 'cost control record',
      NOUN_DOCUMENT: 'document',
      NOUN_BROADCAST: 'shift broadcast',
      NOTIFY_SENTENCE_EXORDIUM: 'Notify me when',
      NOTIFY_SENTENCE_TRAILING_IN: 'in...',
      UNSUBSCRIBE: 'Unsubscribe',
      SUBSCRIBE: 'Subscribe',
      SEARCH_PLACEHOLDER: 'Search...',
      BACK_LINK: 'Back',
      RESOURCE_SUBSCRIBE_TOOLTIP: 'Be notified of changes to this {{name}}',
      RESOURCE_UNSUBSCRIBE_TOOLTIP: 'Unsubscribe from changes to this {{name}}',
    },
    ROTA: {
      DASHBOARD_WEEK: {
        VIEW_ROTA: 'View rota',
        LAST_WEEK: 'Last week',
        THIS_WEEK: 'This week',
        NO_ATTENDANCE_ISSUES: 'No attendance issues',
        ATTENDANCE_ISSUES: 'attendance issue(s)',
        RESOLVE: 'Resolve',
        ACTUAL_SALES_ENTERED: 'Actual sales figures entered',
        ACTUAL_SALES_MISSING: '<strong>Actual sales</strong> figures missing',
        WTD_COMPLIANT: 'Compliant with Working Time regulations',
        VIEW: 'View',
        WTD_VIOLATIONS: 'Working Time violation(s)',
        SIGN_OFF_NOT_COMPLETED: '<strong>Sign off</strong> not yet completed',
        SIGN_OFF: 'Sign off',
        WEEK_SIGNED_OFF: 'Week signed off',
        ABSENCE_REQUEST: 'Absence requests',
        ADDED_SALES_FORECAST: 'Added sales forecast',
        DRAFTED_ROTA: 'Drafted rota',
        MARKED_FOR_APPROVAL: 'Rota marked for approval',
        PUBLISHED: 'Published rota',
        OUTSTANDING: '{{absences}} outstanding',
        NOT_ADDED: 'Not added',
        NOT_DRAFTED: 'Not drafted',
        NOT_DONE: 'Not done',
        NOT_PUBLISHED: 'Not published',
      },
    },
    SIDEBAR: {
      NOTIFICATION_POPOVER: {
        NO_NOTIFICATIONS: 'No notifications!',
        MARK_ALL_READ: 'Mark all read',
      },
      SETTINGS_POPOVER: {
        TIMEZONE: 'This timezone is different to your local timezone.',
        EDIT_PREFERENCES: 'Edit preferences',
        MANAGE_SUBSCRIPTIONS: 'Manage subscriptions',
        SHOW_INTERCOM: 'Show support chat',
        HIDE_INTERCOM: 'Hide support chat',
        SIGN_OUT: 'Sign out',
      },
    },
    DATE_PICKER: {
      CALENDAR: 'Calendar',
      GO_TO_TODAY: 'Go to today',
    },
    ENTITY_SELECTOR: {
      ORG_WIDE: 'Organisation-wide',
      NO_OPTIONS: 'No options',
      SEARCH_PLACEHOLDER: 'Search...',
    },
    FILTER_OPTIONS_BUTTON: {
      IS: 'is',
      AND: 'and',
      OR: 'or',
      ANY: 'Any',
      SEARCH_PLACEHOLDER: 'Search...',
      NO_OPTIONS: 'No options',
      SELECT_ALL: 'Select all',
      CLEAR_SELECTED: 'Clear selected',
    },
    FILTER_STAFF_SEARCH_BUTTON: {
      IS: 'is',
      ANY: 'Any',
      RESET: 'Reset',
    },
    FILTER_DATE_BUTTON: {
      ANY: 'any',
      BEFORE: 'before',
      AFTER: 'after',
      BETWEEN: 'between',
      IS: 'is',
      AND: 'and',
      DONE: 'Done',
    },
    FILTER_NUMBER_BUTTON: {
      ANY: 'is any',
      GREATER: 'is greater than',
      LESS: 'is less than',
      EXACTLY: 'is exactly',
      DONE: 'Done',
    },
    VALIDATION_FAILURE: {
      TITLE: 'Validation issues',
      ISSUE: 'issue',
      ISSUES: 'issues',
      CHANGE_MESSAGE: 'The change you\'re trying to make has resulted in ',
      IGNORE_CONTINUE: 'Ignore & continue anyway',
      IGNORE_PROBLEM: 'Ignore problem shifts and try again',
      LOADING: 'Loading...',
    },
    PICK_LIST: {
      NO_OPTIONS: 'No options',
      SEARCH_PLACEHOLDER: 'Search...',
      DEFAULT_NULL_OPTION_LABEL: 'None',
    },
    FILE_UPLOAD: {
      ALERT_UNSUPPORTED_FILE_TYPE: 'Unfortunately that type of file isn\'t supported.',
      ALERT_FILE_SIZE: 'Unfortunately that file is too large.',
      LABEL_ACCEPTED_FILE_TYPES: 'Accepted file types',
      LABEL_LIMIT: 'limit',
      INFO_DROP_AVAILABLE_PART_1: 'Drag & drop a file here or',
      INFO_DROP_AVAILABLE_PART_2: 'click to browse',
      INFO_DROP_NOT_AVAILABLE: 'Click to browse for a file',
      INFO_DRAG_ACTIVE: 'Nice aim, time to drop!',
      INFO_UPLOAD_FAILED: 'Upload failed, please try again',
    },
  },
  AVAILABILITY: {
    ABSENCE: {
      TAB_ABSENCE: 'Absence bookings',
      TAB_ALLOWANCES: 'Allowances',
    },
    WEEKLY_AVAILABILITY_MODAL: {
      ADD_TITLE: 'Add weekly availability',
      EDIT_TITLE: 'Edit weekly availability',
      NICKNAME: 'Nickname',
      PLACEHOLDER: 'ie. While Studying',
      HELP: 'You should name this availability pattern after something meaningful, like \'While Studying\' if this represents when you can work while studying for exams, or \'Summer Holiday\' for when you\'re free most of the time, for example.',
      STARTING_FROM: 'Starting from',
      NEVER_ENDING: 'Never ending',
      YES_PATTERN_FOREVER: 'Yes, let this pattern continue forever',
      NO_PATTERN_DATE: 'No, there\'s a date when I\'d like this pattern to stop',
      STOP_DATE: 'Stop date',
      PATTERN_APPLIES: 'Pattern applies',
      EVERY: 'Every',
      WEEK: 'Week',
      SAVING_BUTTON_TEXT: 'Saving...',
      SAVE_BUTTON_TEXT: 'Save',
      CANCEL: 'Cancel',
    },
    WEEKLY_AVAILABILITY_MODAL_SINGLE: {
      TITLE: 'Add one-off availability',
      SUBTITLE: 'For a single week',
      WEEK_BEGINS: 'Week begins',
      HELP: 'This the week you\'d like to mark your availability for.',
      SAVING_BUTTON_TEXT: 'Saving...',
      SAVE_BUTTON_TEXT: 'Save',
      CANCEL: 'Cancel',
    },
    AVAILABILITY: {
      ADD_AVAILABILITY: 'Add availability',
      NEW_WEEKLY_PATTERN: 'New weekly pattern',
      NEW_MONTHLY_PATTERN: 'New monthly pattern',
      NEW_CUSTOM_PATTERN: 'New custom pattern',
      ONE_OFF_PATTERN: 'One-off (single week)',
      AVAILABILITY: 'Availability',
      AVAILABILITY_HELP: 'Manage availability here through availability patterns; think of these like reoccurring calendar events.',
      AVAILABILITY_TIP: 'Use a monthly pattern to target dates like \'the first Wednesday\' or \'the last Saturday\' of each month.',
      NICKNAME: 'Nickname',
      APPLIES_FROM_TO: 'Applies from (/to)',
      OPTIONS: 'Options',
      NO_AVAILABILITY_PATTERNS: 'There\'s no availability patterns. Add one using the buttons above.',
      TURN_OFF: 'Turn off',
      TURN_ON: 'Turn on',
      NO_NICKNAME: 'No nickname',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly',
      CUSTOM: 'Custom',
      EDIT: 'Edit',
      TOGGLE_DROPDOWN: 'Toggle dropdown',
      DELETE: 'Delete',
      CONFIRM_DELETE_PATTERN: 'Are you sure? Deleting this availability pattern cannot be undone.',
      DELETE_PATTERN_SUCCESS: 'Success! Your availability pattern has been deleted.',
      ERROR_DELETING_PATTERN: 'We were unable to delete that pattern just now. Please try again.',
      ERROR_TOGGLING_PATTERN: 'We were unable to turn on/off that pattern just now. Please try again.',
      ERROR_LOADING_PATTERNS: 'We were unable to load your availability patterns. Please try again.',
    },
    DAILY_AVAILABILITY_MODAL: {
      ADD_CUSTOM_AVAILABILITY: 'Add custom availability',
      EDIT_CUSTOM_AVAILABILITY: 'Edit custom availability',
      NICKNAME: 'Nickname',
      NICKNAME_PLACEHOLDER: 'ie. While Studying',
      NICKNAME_HELP: 'You should name this pattern after something meaningful.',
      STARTING_FROM: 'Starting from',
      NEVER_ENDING: 'Never ending',
      CONTINUE_FOREVER: 'Yes, let this pattern continue forever',
      PATTERN_STOP: 'No, there\'s a date when I\'d like this pattern to stop',
      STOP_DATE: 'Stop date',
      PATTERN_DAYS: 'Pattern days',
      PATTERN_NO_DAYS: 'This pattern has no days. Add one using the button below.',
      DAY_NUMBER: 'Day {{number}}',
      ILL_BE: 'I\'ll be',
      DELETE: 'Delete',
      ADD_DAY: 'Add day',
      SAVE: 'Save',
      SAVE_LOADING: 'Saving...',
      CANCEL: 'Cancel',
      UNAVAILABLE: 'Unavailable',
      AVAILABLE: 'Available',
      AVAILABLE_AM: 'Available (AM only)',
      AVAILABLE_PM: 'Available (PM only)',
      OTHER: 'Other (specify...)',
      MINIMUM_TWO_DAYS: 'You must have a minimum of two days in this pattern.',
      STOPS_BEFORE_STARTS: 'Your pattern is currently set to stop before it starts!',
      AVAILABILITY_END_BEFORE_START: 'One or more of your availability rules have an end time that falls before the start time.',
      ERROR_SAVING_PATTERN: 'Currently unable to save this availability pattern. Please try again.',
      ERROR_UPDATING_PATTERN: 'Currently unable to update this availability pattern. Please try again.',
    },
    EDIT_PATTERN_EXCEPTIONS_MODAL: {
      EDIT_EXCEPTIONS: 'Edit exceptions',
      EXCEPTIONS_TIP: 'Your availability patterns mark when you\'re not free to work. You can, however, use your calendar to tell us that you\'re free to work on a day that you wouldn\'t normally be able to. Simply click on the red \'unavailable\' marker and you\'ll be able to make an exception.',
      EXCEPTIONS: 'The below list shows the exceptions you\'ve made to your availability pattern. We consider you free to work on these days:',
      DATE: 'Date',
      OPTIONS: 'Options',
      UNDO: 'Undo',
      NO_EXCEPTIONS: 'You haven\'t made any exceptions yet.',
      CLOSE: 'Close',
      ERROR_DELETING_EXCEPTION: 'Unfortunately we weren\'t able to delete the exception just then. Please try again.',
    },
    MONTHLY_AVAILABILITY_MODAL: {
      ADD_MONTHLY_AVAILABILITY: 'Add monthly availability',
      EDIT_MONTHLY_AVAILABILITY: 'Edit monthly availability',
      NICKNAME: 'Nickname',
      NICKNAME_PLACEHOLDER: 'ie. While Studying',
      NICKNAME_HELP: 'You should name this availability pattern after something meaningful, like \'While Studying\' if this represents when you can work while studying for exams, or \'Summer Holiday\' for when you\'re free most of the time, for example.',
      STARTING_FROM: 'Starting from',
      NEVER_ENDING: 'Never ending',
      CONTINUE_FOREVER: 'Yes, let this pattern continue forever',
      PATTERN_STOP: 'No, there\'s a date when I\'d like this pattern to stop',
      STOP_DATE: 'Stop date',
      PATTERN_RULES: 'Pattern rules',
      PATTERN_NO_RULES: 'This pattern has no rules. Add one using the button below.',
      EVERY: 'Every',
      ON_THE: 'on the',
      DELETE: 'Delete',
      ADD_RULE: 'Add rule',
      SAVE: 'Save',
      SAVE_LOADING: 'Saving...',
      CANCEL: 'Cancel',
      ORDINAL_LAST: 'last',
      ORDINAL_FIRST: 'first',
      ORDINAL_SECOND: 'second',
      ORDINAL_THIRD: 'third',
      ORDINAL_FOURTH: 'fourth',
      ORDINAL_FIFTH: 'fifth',
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday',
      AVAILABLE: 'Available',
      AVAILABLE_AM: 'AM only',
      AVAILABLE_PM: 'PM only',
      MONTH: 'month',
      MONTHS: 'months',
      OTHER: 'Other (specify...)',
      STOPS_BEFORE_STARTS: 'Your pattern is currently set to stop before it starts!',
      AVAILABILITY_END_BEFORE_START: 'One or more of your availability rules have an end time that falls before the start time.',
      ERROR_SAVING_PATTERN: 'Currently unable to save this availability pattern. Please try again.',
      ERROR_UPDATING_PATTERN: 'Currently unable to update this availability pattern. Please try again.',
    },
    OVERVIEW: {
      SCHEDULE: 'My schedule',
      SCHEDULE_HELP: 'This is your full schedule, including shifts you\'ve been assigned, approved absence and availability.',
      SHIFTS: 'Shifts',
      ABSENCE: 'Absence',
      UNAVAILABLE: 'Unavailable (patterns)',
      EDIT_EXCEPTIONS: 'Edit exceptions',
      ICAL_FEED: 'iCalendar Feed',
      ICAL_FEED_HELP: 'An iCalendar feed will allow you to view your schedule outside of Rotaready.',
      ICAL_FEED_TIP: 'Once you\'ve generated an iCalendar feed URL, you can copy and paste it in to <a href="https://support.apple.com/en-gb/HT202361" target="_blank">Apple Calendar</a>, <a href="https://support.google.com/calendar/answer/37100" target="_blank">Google Calendar</a> or any other calendar software.',
      COPIED: 'Copied to clipboard!',
      GENERATE: 'Generate',
    },
  },
  ATTENDANCE: {
    VALIDATION_CODES: {
      CODE_1: 'The corresponding date has been signed-off, so we can\'t make any changes to it.',
      CODE_2: 'Doing this would result in an invalid sequence of events. For example, a clock-out must come after a clock-in, but can\'t come before another clock-out. Check the surrounding events then try again.',
      CODE_3: 'The chosen site/department is invalid.',
      CODE_4: 'We can\'t add a clock-in here as the chosen employee doesn\'t have an appointment on this date.',
      CODE_5: 'We can\'t add an event here as there\'s already an existing event at the same time.',
      CODE_6: 'The chosen employee is invalid.',
      CODE_7: 'This change would move the event to an earlier time than the event that comes before it. Try choosing a different time or edit the earlier event first.',
      CODE_8: 'This change would move the event to a later time than the event that follows it. Try choosing a different time or edit the later event first.',
    },
    COMMON: {
      EVENT_ADD_SUCCESS: 'The event was successfully added.',
      ABSENCE_ADD_SUCCESS: 'They\'ve been marked as absent.',
      NO_CLOCK_IN_MODAL_TITLE: 'Add event',
      NO_CLOCK_IN_MODAL_MESSAGE: 'What would you like to do?',
      NO_CLOCK_IN_MODAL_ADD_EVENT: 'Add clock in',
      NO_CLOCK_IN_MODAL_ADD_EVENT_DESC: 'Manually add an attendance record',
      NO_CLOCK_IN_MODAL_ADD_ABSENCE: 'Mark absent',
      NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC: 'Add an absence record like sickness',
      UNMATCHED_MODAL_TITLE: 'Unmatched attendance',
      UNMATCHED_MODAL_MESSAGE: 'There are two ways you can resolve unmatched attendance:',
      UNMATCHED_MODAL_ADD_SHIFT: 'Add shift',
      UNMATCHED_MODAL_ADD_SHIFT_DESC: 'Create a new shift to reflect what was worked',
      UNMATCHED_MODAL_EDIT_EVENT: 'Edit attendance',
      UNMATCHED_MODAL_EDIT_EVENT_DESC: 'Adjust the attendance records if they\'re not correct',
      OVERTIME_MODAL_TITLE: 'Review overtime',
      OVERTIME_MODAL_MESSAGE_IN: '{{name}} has clocked-in early and the shift has matched a pay rule. What would you like to do?',
      OVERTIME_MODAL_MESSAGE_OUT: '{{name}} has clocked-out late and the shift has matched a pay rule. What would you like to do?',
      OVERTIME_MODAL_APPROVE: 'Approve overtime',
      OVERTIME_MODAL_APPROVE_DESC: '{{name}} will be paid for {{duration}} minutes overtime',
      OVERTIME_MODAL_REJECT: 'Reject overtime',
      OVERTIME_MODAL_REJECT_DESC: '{{name}} will not be paid for the overtime worked',
      OVERTIME_UPDATE_ERROR: 'We can\'t update the event right now. Please try again.',
      MODAL_USER_NOTES_LABEL: 'Notes from {{name}}'
    },
    BY_STAFF: {
      FILTER_LABEL_DATE: 'Date',
      MANUAL_CLOCK_IN: 'Manual clock-in',
      EVENT_TYPE: 'Event',
      RECORDED_TIME: 'Recorded time',
      METHOD: 'Captured by',
      OPTIONS: 'Options',
      TRUSTED_DEVICE: 'Trusted device',
      MOBILE_APP: 'Rotaready app',
      MANUALLY_OVERRIDDEN: 'Manually overidden',
      MANUAL_ENTRY: 'Manual entry',
      DELETE: 'Delete',
      NO_EVENTS: 'There are no attendance events to show.',
      CLOCK_IN: 'Clock in',
      CLOCK_OUT: 'Clock out',
      BREAK_ON: 'On break',
      BREAK_OFF: 'Off break',
      ERROR_LOADING_EVENTS: 'We can\'t load attendance right now. Please try again.',
      ERROR_DELETING: 'We can\'t delete the attendance right now. Please try again.',
      DELETE_SUCCESS: 'The event was successfully deleted.',
      ADD_SUCCESS: 'The event was successfully added.',
      LABEL_EDITED: 'Edited',
    },
    ISSUES: {
      TITLE: 'Attendance issues',
      FILTER_DATE: 'Date',
      FILTER_ISSUE_TYPE: 'Issue type',
      FILTER_POSITION: 'Job role',
      FILTER_USER: 'Person',
      ISSUES_HELP: 'Issues should be resolved, otherwise they\'ll cause discrepancies in reports and payroll. By default, Rotaready flags issues from the last {{defaultIssuePeriod}} days.',
      IS_THIS: 'Is this',
      YES: 'Yes',
      NO: 'No',
      HEADING_NAME: 'Name',
      HEADING_SITE: 'Site',
      HEADING_ISSUE: 'Issue',
      HEADING_SHIFT: 'Shift',
      HEADING_START: 'Start',
      HEADING_FINISH: 'Finish',
      HEADING_CLOCK_IN: 'Clock-in',
      HEADING_CLOCK_OUT: 'Clock-out',
      HEADING_OPTIONS: 'Options',
      NOT_FOUND: 'Not found',
      RESOLVE: 'Resolve',
      DISMISS: 'Dismiss',
      NO_ATTENDANCE_ISSUES: 'You have no attendance issues, nicely done!',
      UNMATCHED_ATTENDANCE: 'Unmatched attendance',
      NO_CLOCK_IN: 'No clock-in',
      NO_CLOCK_OUT: 'No clock-out',
      SUSPECT_CLOCK_OUT: 'Suspect clock-out',
      SUSPECT_ATTENDANCE: 'Suspect attendance',
      BREAK_NOT_TAKEN: 'Break not taken',
      INVALID_BREAKS: 'Invalid break(s)',
      SUSPECT_BREAK: 'Suspect break',
      EARLY_CLOCK_IN: 'Early clock-in',
      LATE_CLOCK_OUT: 'Late clock-out',
      UNMATCHED_ATTENDANCE_EXPLANATION: 'Rotaready could not find a shift to match this attendance to',
      NO_CLOCK_IN_EXPLANATION: 'The staff member did not clock-in for their shift',
      NO_CLOCK_OUT_EXPLANATION: 'The staff member did not clock-out after their shift',
      SUSPECT_CLOCK_OUT_EXPLANATION: 'The staff member clocked-out much later than their scheduled finish time',
      SUSPECT_ATTENDANCE_EXPLANATION: 'The staff member was clocked-in for a much shorter amount of time than scheduled',
      BREAK_NOT_TAKEN_EXPLANATION: 'The shift has a break but one was not taken',
      INVALID_BREAKS_EXPLANATION: 'The break(s) on this attendance are incomplete (i.e. missing a break-off)',
      SUSPECT_BREAK_EXPLANATION: 'The staff member was on break for much longer than scheduled',
      EARLY_CLOCK_IN_EXPLANATION: 'The shift matches a pay rule which requires approval for an early clock in',
      LATE_CLOCK_OUT_EXPLANATION: 'The shift matches a pay rule which requires approval for a late clock out',
      ERROR_LOADING_ATTENDANCE: 'We can\'t load your attendance issues right now. Please try again.',
      SOMETHING_WENT_WRONG: 'Oops, something just went wrong. You may have to try again!',
      CONFIRM_DISMISS: 'Are you sure you want to dismiss this attendance issue?',
      DATE_RANGE_TOO_WIDE: 'You can\'t choose more than 5 weeks. Please restrict your date range.',
      CLOCK_OUT_DAYS_LATER: 'This clock-out is one or more days after the clock-in.',
    },
    OVERVIEW: {
      UNMATCHED_LABEL: '{{count}} unmatched',
      OPTIONS_DAILY: 'Daily',
      OPTIONS_WEEKLY: 'Weekly',
      OPTIONS_DAY_ROLL_OVER: 'Day roll-over',
      FILTER_POSITION: 'Job role',
      FILTER_USER: 'Person',
      MIDNIGHT: 'Midnight',
      TOOLS: 'Tools',
      CURRENTLY_CLOCKED_IN: 'People currently clocked-in',
      ATTENDANCE_HELP: 'Attendance is automatically matched up with shifts if a clock-in or clock-out occurs within <b>{{autoMatchThreshold}} hour(s)</b> of a scheduled start or finish time.',
      MATCHED_HEADING_SCHEDULED: 'Scheduled',
      MATCHED_HEADING_ACTUAL: 'Actual',
      MATCHED_HEADING_AFTER_SANITISATION: 'After sanitisation',
      MATCHED_HEADING_NAME: 'Name',
      MATCHED_HEADING_SITE: 'Site',
      MATCHED_HEADING_START: 'Start',
      MATCHED_HEADING_FINISH: 'Finish',
      MATCHED_HEADING_PAID_HOURS: 'Paid hrs',
      MATCHED_HEADING_UNPAID_HOURS: 'Unpaid hrs',
      COVER_SHIFT: 'Cover shift',
      NO_CLOCK_IN: 'No clock-in yet',
      NO_CLOCK_OUT: 'No clock-out yet',
      OVERTIME_APPROVED: 'Approved',
      OVERTIME_REJECTED: 'Rejected',
      OVERTIME_REVIEW: 'Review',
      OVERTIME_APPROVED_BY: 'Overtime approved by {{name}}',
      OVERTIME_REJECTED_BY: 'Overtime rejected by {{name}}',
      MATCHED_NO_RESULTS: 'There are no shifts to show.',
      UNMATCHED_ATTENDANCE: 'Unmatched attendance',
      UNMATCHED_ATTENDANCE_HELP: 'If attendance doesn\'t fall within <b>{{autoMatchThreshold}} hour(s)</b> of a shift\'s start/finish time, you\'ll need to manually adjust the shift or the attendance records to bring them in-line:',
      UNMATCHED_HEADING_NAME: 'Name',
      UNMATCHED_HEADING_SITE: 'Site',
      UNMATCHED_HEADING_DATE: 'Date',
      UNMATCHED_HEADING_CLOCK_IN: 'Clock in',
      UNMATCHED_HEADING_CLOCK_OUT: 'Clock out',
      UNMATCHED_HEADING_OPTIONS: 'Options',
      NO_UNMATCHED_ATTENDANCE: 'There\'s no unmatched attendance here. Nice job!',
      NONE_YET: 'None yet',
      RESOLVE: 'Resolve',
      ERROR_LOADING_ATTENDANCE: 'We can\'t load your attendance history right now. Please try again.',
      TIMELINESS_EARLY_TOOLTIP: '{{duration}} minutes early',
      TIMELINESS_LATE_TOOLTIP: '{{duration}} minutes late',
      TRUNCATED_RESULT_MESSAGE: 'There\'s more to see, but to keep things running smoothly we\'ve had to truncate your result.<br />Try filtering by job role, by site/department or reduce the date range.',
    },
    VIEW_EVENT_MODAL: {
      VIEW_ATTENDANCE: 'View attendance',
      STAFF_MEMBER: 'Who',
      EVENT_TYPE: 'Event',
      RECORDED_TIME: 'Recorded time',
      METHOD: 'Captured by',
      TRUSTED_DEVICE: 'Trusted device',
      MOBILE_APP: 'Rotaready app',
      MANUALLY_OVERRIDDEN: 'Manually overridden',
      MANUAL_ENTRY: 'Manual entry',
      ON: 'on',
      OVERTIME: 'Overtime',
      APPROVED: 'Approved',
      REJECTED: 'Rejected',
      FACIAL_RECOGNITION: 'Facial recognition',
      FACIAL_RECOGNITION_PROCESS: 'Images are usually processed within several minutes of being captured',
      FACIAL_RECOGNITION_WAITING: 'Awaiting processing...',
      TRUSTS_PHOTO: 'Rotaready trusts this photo',
      NO_TRUSTS_PHOTO: 'Rotaready doesn\'t trust this photo',
      CONFIDENT: 'confident',
      UNABLE_TO_IDENTIFY: 'Unable to clearly identify a face',
      PROCESSED_ON: 'Processed on',
      ACKNOWLEDGED_BY: 'Acknowledged by {{name}} on',
      REFERENCE_IMAGE: 'Reference image',
      REFERENCE_IMAGE_HELP: 'This image will not be processed, but instead used as a reference for comparison',
      EDIT: 'Edit',
      CLOSE: 'Close',
      TAB_DETAILS: 'Details',
      TAB_PHOTO: 'Photo',
      TAB_LOCATION: 'Location',
      CLOCK_IN: 'Clock in',
      CLOCK_OUT: 'Clock out',
      BREAK_ON: 'Break on',
      BREAK_OFF: 'Break off',
      HAPPENED_HERE: 'happened here',
      TAB_AUDIT: 'History',
      AUDIT_TABLE_TIME: 'Attendance time',
      AUDIT_TABLE_OVERTIME: 'Overtime',
      AUDIT_TABLE_WHO: 'Recorded by',
      NOTES: 'Notes'
    },
    EDIT_EVENT_MODAL: {
      TITLE: 'Edit attendance',
      CLOCK_IN: 'Clock in',
      CLOCK_OUT: 'Clock out',
      BREAK_ON: 'Break on',
      BREAK_OFF: 'Break off',
      ERROR_UPDATING: 'We can\'t update the event right now. Please try again.',
      ERROR_DELETING: 'We can\'t delete the event right now. Please try again.',
      CONFIRM_DELETE: 'Are you sure you wish to delete this event?\nIf it\'s a clock-in, the corresponding clock-out will also be removed.',
      PRIOR_EVENT_INFO: 'The preceding event is a <b>{{eventType}}</b> at <b>{{date|momentFormat:\'ll - LT\'}}</b>',
      PRIOR_EVENT_CONFLICT: 'There\'s already a <b>{{eventType}}</b> at <b>{{date|momentFormat:\'ll - LT\'}}</b>. You\'ll need to select a different time.',
      LABEL_WHO: 'Who',
      LABEL_EVENT: 'Event',
      LABEL_DATE: 'Date & time',
      DATE_IN_FUTURE: 'This time hasn\'t yet elapsed!',
      LABEL_OVERTIME: 'Overtime',
      APPROVE: 'Approve',
      REJECT: 'Reject',
      LABEL_OVERTIME_HELP: 'Rotaready identified some overtime when matching this attendance event with a shift. If you approve it, the overtime will be paid in accordance with the shift\'s pay rule.',
      DELETE: 'Delete',
      CANCEL: 'Cancel',
      SAVE: 'Save changes',
    },
    ADD_EVENT_MODAL: {
      TITLE: 'Add attendance',
      CLOCK_IN: 'Clock in',
      CLOCK_OUT: 'Clock out',
      BREAK_ON: 'Break on',
      BREAK_OFF: 'Break off',
      ERROR_ACTIONING: 'We can\'t add the event right now. Please try again.',
      PRIOR_EVENT_INFO: 'The preceding event is a <b>{{eventType}}</b> at <b>{{date|momentFormat:\'ll - LT\'}}</b>',
      PRIOR_EVENT_CONFLICT: 'There\'s already a <b>{{eventType}}</b> at <b>{{date|momentFormat:\'ll - LT\'}}</b>. You\'ll need to select a different time.',
      LABEL_EVENT: 'Event',
      LABEL_DATE: 'Date & time',
      LABEL_BREAK: 'Break duration',
      LABEL_BREAK_HELP: 'A break-off will be added for you after the chosen duration.',
      DATE_IN_FUTURE: 'This time hasn\'t yet elapsed!',
      CANCEL: 'Cancel',
      SAVE: 'Add attendance',
      BREAK_MINUTES: 'minutes',
    },
  },
  DIALOG: {
    TEXTBOX_DIALOG: {
      CANCEL: 'Cancel',
    },
    TWO_BUTTON_DIALOG: {
      CANCEL: 'Cancel',
    },
  },
  OTHER: {
    EDIT_PREFERENCES_MODAL: {
      TITLE: 'Update preferences',
      SUBTITLE: 'Contact information',
      SUBTITLE_HELP: 'If you forget your password, we can only help you reset it if we have alternative ways to contact you',
      EMAIL_LABEL: 'Email address',
      EMAIL_PLACEHOLDER: 'None provided',
      EMAIL_HELP: 'Please provide a valid email address.',
      MOBILE_LABEL: 'Mobile phone',
      MOBILE_PLACEHOLDER: 'None provided',
      MOBILE_HELP: 'Please provide a valid mobile number, or none at all.',
      ACCOUNT_INFO: 'Account information',
      ACCOUNT_INFO_HELP: 'Here you can set your language',
      LANGUAGE_LABEL: 'Language',
      LANGUAGE_HELP: 'Rotaready will display in your chosen language.',
      OTHER_SETTINGS_TITLE: 'Other settings',
      CLOCK_IN_LABEL: 'Clock-in PIN',
      CLOCK_IN_HELP: 'If you\'re required to enter a PIN code when clocking in/out, you can enter a new one here. It must be 4 digits.',
      NOTIFICATIONS_TITLE: 'Notifications',
      NOTIFICATIONS_HELP: 'Control how you\'d like to be notified of events you\'re subscribed to ',
      MANAGE_SUBSCRIPTIONS: 'manage subscriptions',
      ROTA_UPDATES: 'Rota updates',
      CRITIAL_SHIFT: 'Critical shift updates',
      EMAIL: ' Email',
      MOBILE_APP: ' Mobile app',
      SHIFT_UPDATED: 'When a shift you\'ll be working in the next 24 hours has been updated',
      REGULAR_SHIFT: 'Regular shift updates',
      SHIFT_UPDATED_BEYOND: 'Updates for any shifts you\'ll be working beyond the next 24 hours',
      WEEKLY_SUMMARY: 'Weekly summary',
      SMS: ' SMS',
      UPCOMING_WEEK: 'A summary of your shifts for the upcoming week, sent to you the evening before the week begins',
      APPROVAL: 'Approval',
      APPROVAL_HELP: 'When a rota is marked as ready for approval',
      PUBLISHING: 'Publishing',
      PUBLISHING_HELP: 'When a rota is published or unpublished',
      SHIFT_SWAPS: 'Shift swaps',
      SWAP_UPDATES: 'Swap updates ',
      REQUESTED: 'Requested',
      REQUESTED_HELP: 'When someone requests a new shift swap',
      ACCEPTED: 'Accepted',
      ACCEPTED_HELP: 'When someone accepts a shift swap',
      APPROVED: 'Approved',
      APPROVED_HELP: 'When a shift swap is approved by management',
      REJECTED: 'Rejected',
      REJECTED_HELP: 'When a shift swap is rejected by management',
      CANCELLED: 'Cancelled',
      CANCELLED_HELP: 'When a shift swap is cancelled',
      ABSENCE_UPDATES: 'Absence/holiday updates',
      ABSENCE_REQUESTED: 'Absence requested',
      ABSENCE_REQUESTED_HELP: 'When a new absence is requested',
      ABSENCE_UPDATE: 'Absence update',
      ABSENCE_UPDATE_HELP: ' When an absence request is approved, rejected or cancelled by management',
      COST_CONTROL: 'Cost control',
      SIGN_OFF: 'Sign off',
      SIGN_OFF_HELP: 'When a site signs off a week',
      SIGN_OFF_REVERSE: 'Sign off reversed',
      SIGN_OFF_REVERSE_HELP: 'When a site reverses sign-off for a week',
      ADMIN: 'Admin',
      ACCOUNT_APPROVAL: 'Account approval',
      ACCOUNT_APPROVAL_HELP: 'When an account is either awaiting approval or has been approved',
      DOCUMENTS: 'Documents',
      DOCUMENTS_HELP: 'When a document has expired or will be expiring soon',
      OTHER: 'Other',
      COMPANY_ANNOUNCEMENTS: 'Company announcments',
      COMPANY_HELP: 'Important announcements from your employer',
      DAILY_ADMIN_UPDATE: 'Daily admin update',
      DAILY_UPDATE_HELP: 'We\'ll notify you if you have any attendance issues, or if there are any absence requests or shift swaps pending approval.',
      PHONE_ONLY: 'Notifications with this icon are only available with the Rotaready iPhone and Android apps.',
      CHANGE_PASSWORD: 'Change password',
      SAVE: 'Save',
      SAVING: 'Saving...',
      CANCEL: 'Cancel',
    },
    MANAGE_SUBSCRIPTIONS_MODAL: {
      TITLE: 'Manage subscriptions',
      HELP: 'Your subscriptions control what you\'d like to be notified about.',
      UNSUBSCRIBE_ALL: 'Unsubscribe from all',
      NOTIFY_WHEN: 'Notify me when {{descriptor}}',
      UNSUBSCRIBE_TOOLTIP: 'Unsubscribe',
      UNSUBSCRIBE_ALL_TOOLTIP: 'Unsubscribe from all',
      ACTION_IN: '{{action}} in ',
      FILTER_NO_SUBSCRIPTIONS: 'You have no active subscriptions in {{name}}.',
      ALL_NO_SUBSCRIPTIONS: 'You have no active subscriptions.',
      CLOSE: 'Close',
    },
    RELEASE_REFRESH_MODAL: {
      TITLE: 'Rotaready update',
      BODY: 'We\'ve just released an update to Rotaready that requires you to reload the page. Please click the button below.',
      REFRESH: 'Refresh page',
    },
    EDIT_AVATAR_MODAL: {
      TITLE: 'Profile picture',
      INTRO_COPY: 'A picture helps people to recognise you and lets you know when you\'re signed in to your account.',
      BUTTON_CHANGE: 'Upload a new picture',
      BUTTON_CANCEL: 'Cancel',
      BUTTON_SAVE: 'Save',
      ALERT_REMOVE: 'Are you sure you wish to remove this profile picture?',
      ALERT_FILE_TYPE_NOT_ALLOWED: 'That file type is not allowed.',
      ALERT_ERROR_500: 'We couldn\'t update your picture just then. Please try again.',
      ALERT_SUCCESS_ADD: 'Your profile picture has been updated. It may take a while to update across all of Rotaready\'s services.',
      ALERT_SUCCESS_REMOVE: 'Your profile picture has been removed. It may take a while to update across all of Rotaready\'s services.',
    },
  },
  REPORTS: {
    INDEX_LIST: {
      TITLE: 'Reports',
      TRONC_STATUS: 'Tronc Status',
      CURRENTLY_CLOCKED_IN: 'People Currently Clocked-In',
      ROTA_PROGRESS: 'Rota Progress',
      SIGN_OFF_PROGRESS: 'Sign-off Progress',
      PAYROLL_EXPORT: 'Payroll Export',
      LABOUR_BREAKDOWN: 'Labour Breakdown',
      WORKING_TIME_VIOLATIONS: 'Working Time Violations',
      CONTRACTUAL_OBLIGATION_SUMMARY: 'Contractual Obligation Summary',
      DOCUMENT_SIGNATURES: 'Document Signing',
      EXPIRING_DOCUMENTS: 'Expiring Documents',
      EMPLOYEE_EXPORT: 'Employee Details',
      JOINERS_LEAVERS: 'Joiners & Leavers',
      STAFF_TURNOVER: 'Staff Turnover',
      ANNIVERSARY: 'Anniversaries',
      EMPLOYMENT_CHANGES: 'Employment Changes',
      CATEGORY_HR: 'HR',
      CATEGORY_DOCUMENTS: 'Documents',
      CATEGORY_ROTAS: 'Scheduling',
      CATEGORY_PAYROLL: 'Payroll',
      CATEGORY_ATTENDANCE: 'Attendance',
      CATEGORY_ABSENCE: 'Absence',
      CATEGORY_COST_CONTROL: 'Cost control',
      ABSENCE_EXPORT: 'Absence Export',
      ALLOWANCES_EXPORT: 'Allowances Export',
      UNTAKEN_HOLIDAY_COST: 'Untaken Holiday Cost',
      COST_CONTROL_RAW_DATA: 'Raw Data Export',
      NEW_HIRE_TURNOVER: 'New Hire Turnover',
      ABSENCE_DAYS_EXPORT: 'Absence Days Export',
      SHIFT_TYPE_STREAM_BREAKDOWN: 'Shift Type & Stream Breakdown',
      XERO_EXPORT: 'Export to Xero',
      DOCUMENT_PRESENCE: 'Document Presence',
      DOCUMENTS_EXPORT: 'Documents Export',
      ATTENDANCE_BREAKDOWN: 'Attendance Breakdown',
      LATENESS: 'Lateness',
      CATEGORY_ASYNC_FILE_EXPORTS: 'Large reports',
      LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT: 'Labour Breakdown File Export',
      PAYROLL_ASYNC_FILE_EXPORT: 'Payroll File Export',
      RAW_COST_CONTROL_ASYNC_FILE_EXPORT: 'Raw Cost Control File Export',
    },
    LATENESS: {
      TITLE: 'Lateness',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      COLUMN_ID: 'ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE_APPOINTMENT: 'Site (appointment)',
      COLUMN_DEPT_APPOINTMENT: 'Department (appointment)',
      COLUMN_INSTANCES: 'Instances',
      COLUMN_VARIANCE: 'Average variance',
      FILTER_DATE: 'Date',
      FILTER_SITE: 'Site/department',
      FILTER_USER: 'Person',
      FILTER_OVERTIME: 'Overtime',
      FILTER_OVERTIME_NA: 'Not acknowledged',
      FILTER_OVERTIME_APPROVED: 'Approved',
      FILTER_OVERTIME_REJECTED: 'Rejected',
      FILTER_CLOCK_IN: 'Clock-in',
      FILTER_CLOCK_OUT: 'Clock-out',
      FILTER_MINS_LATE: 'mins late',
      FILTER_MINS_EARLY: 'mins early',
      LABEL_MINUTES: 'minutes',
      BUTTON_EXPORT: 'Export',
      NO_MATCH: 'We couldn\'t find any data matching your filters.',
    },
    ATTENDANCE_BREAKDOWN: {
      TITLE: 'Attendance Breakdown',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      COLUMN_ID: 'ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE_APPOINTMENT: 'Site (appointment)',
      COLUMN_DEPT_APPOINTMENT: 'Department (appointment)',
      COLUMN_SITE_ATTRIBUTION: 'Site (attribution)',
      COLUMN_DEPT_ATTRIBUTION: 'Department (attribution)',
      COLUMN_DATE: 'Business date',
      COLUMN_SHIFT_TYPE: 'Shift type',
      COLUMN_RECORD_TYPE: 'Record type',
      COLUMN_SCHEDULED: 'Scheduled',
      COLUMN_ACTUAL: 'Actual',
      COLUMN_VARIANCE: 'Variance',
      COLUMN_CAPTURED_BY: 'Captured by',
      COLUMN_EDITED_BY: 'Edited by',
      COLUMN_EDITED_DATE: 'Edited date',
      COLUMN_OVERTIME_STATE: 'Overtime approval',
      COLUMN_OVERTIME_EDITED_BY: 'Overtime confirmed by',
      COLUMN_OVERTIME_EDITED_DATE: 'Overtime confirmed date',
      FILTER_DATE: 'Date',
      FILTER_SITE: 'Site/department',
      FILTER_USER: 'Person',
      FILTER_OVERTIME: 'Overtime',
      FILTER_OVERTIME_NA: 'Not acknowledged',
      FILTER_OVERTIME_APPROVED: 'Approved',
      FILTER_OVERTIME_REJECTED: 'Rejected',
      FILTER_CLOCK_IN: 'Clock-in',
      FILTER_CLOCK_OUT: 'Clock-out',
      FILTER_MINS_LATE: 'mins late',
      FILTER_MINS_EARLY: 'mins early',
      BUTTON_EXPORT: 'Export',
      NO_MATCH: 'We couldn\'t find any data matching your filters.',
    },
    DOCUMENTS_EXPORT: {
      TITLE: 'Documents Export',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      FILTER_SITE: 'Site/department',
      FILTER_TOGGLE_USER_ACTIVE: 'Active employees',
      FILTER_USER: 'Person',
      FILTER_CATEGORY: 'Category',
      FILTER_ACKNOWLEDGEMENT: 'Status',
      FILTER_OPTION_ACKNOWLEDGED: 'Acknowledged',
      FILTER_OPTION_NOT_ACKNOWLEDGED: 'Not acknowledged',
      BUTTON_EXPORT: 'Export',
      COLUMN_USER_ID: 'Employee ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE: 'Site',
      COLUMN_DEPARTMENT: 'Department',
      COLUMN_CATEGORY: 'Category',
      COLUMN_FILE_NAME: 'Name',
      COLUMN_FILE_SIZE: 'File size (KB)',
      COLUMN_UPLOADED_BY: 'Uploaded by',
      COLUMN_UPLOADED_DATE: 'Uploaded date',
      COLUMN_EXPIRY_DATE: 'Expiry date',
      COLUMN_SIGNATURE_METHOD: 'Signature method',
      COLUMN_EDITED_BY: 'Last edited by',
      COLUMN_EDITED_DATE: 'Last edited date',
      COLUMN_ACKNOWLEDGED: 'Acknowledged',
      COLUMN_ACKNOWLEDGED_DATE: 'Acknowledged date',
      COLUMN_NOTES: 'Notes',
      NO_MATCH: 'We couldn\'t find any employees for your filters.',
    },
    DOCUMENT_PRESENCE: {
      TITLE: 'Document Presence',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      FILTER_SITE: 'Site/department',
      FILTER_TOGGLE_USER_ACTIVE: 'Active employees',
      FILTER_CATEGORY: 'Category',
      BUTTON_EXPORT: 'Export',
      COLUMN_USER_ID: 'Employee ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE: 'Site',
      COLUMN_DEPARTMENT: 'Department',
      COLUMN_EMPLOYMENT_ACTIVE: 'Active employment',
      COLUMN_JOIN_DATE: 'Join date',
      COLUMN_LEAVE_DATE: 'Leave date',
      COLUMN_DOCUMENTS_VALIDITY: 'Documents valid',
      NO_MATCH: 'We couldn\'t find any employees for your filters.',
    },
    SHIFT_TYPE_STREAM_BREAKDOWN: {
      TITLE: 'Shift Type Breakdown',
      FILTER_DATE: 'Date',
      JUMP_TO: 'Jump to',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_LAST_TWO_WEEKS: 'Last two weeks',
      JUMP_TO_LAST_MONTH: 'Last month',
      FILTER_SITE: 'Site/department',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      FILTER_DATA_SOURCE: 'Data source',
      FILTER_USER: 'Person',
      FILTER_BEHAVIOUR: 'Behaviour',
      BEHAVIOUR_SHIFT_TYPE: 'Shift type',
      BEHAVIOUR_STREAM: 'Stream',
      COLUMN_ID: 'ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE: 'Current site',
      COLUMN_DEPARTMENT: 'Current department',
      COLUMN_STAFF_GROUP: 'Current staff group',
      COLUMN_TOTAL: 'Total',
      ROW_TOTAL: 'Total',
      INFO_NO_DATA: 'There are no records that match your filters.',
      INFO_NO_FILTER: 'Select a site/department above to get started.',
      PAY_AMOUNT_TYPE_HOURLY: 'Hourly rate',
      PAY_AMOUNT_TYPE_DAILY: 'Daily rate',
      PAY_AMOUNT_TYPE_ANNUAL: 'Annual salary',
      DATA_SOURCE_SHIFTS: 'Shifts',
      DATA_SOURCE_MATCHED: 'Sanitised',
      DATE_ERROR_DIFF: 'You can\'t choose more than 6 weeks. Please restrict your date range.',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      ERROR_400: 'Your parameters cause too much data to be generated for a single report. Please reduce the scope of your request and try again.',
      STREAM_UNALLOCATED: 'Unallocated',
      FILTER_METRIC: 'Value',
      METRIC_WAGES: 'Base wage cost',
      METRIC_HOURS: 'Paid hours',
    },
    ABSENCE_DAYS_EXPORT: {
      TITLE: 'Absence Days Export',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      COLUMN_USER_ID: 'Employee ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE: 'Site',
      COLUMN_DEPARTMENT: 'Department',
      COLUMN_STAFF_GROUP: 'Staff group',
      COLUMN_JOB_ROLE_NAME: 'Job role',
      COLUMN_DATE: 'Date',
      COLUMN_HOURS: 'Hours',
      COLUMN_HEADER_ID: 'Header ID',
      COLUMN_ABSENCE_TYPE: 'Type',
      COLUMN_ABSENCE_TYPE_REASON: 'Reason',
      COLUMN_STATUS: 'Status',
      COLUMN_SCOPE: 'Scope',
      COLUMN_START_DATE: 'Header start date',
      COLUMN_END_DATE: 'Header end date',
      COLUMN_START_TIME: 'Start time',
      COLUMN_END_TIME: 'End time',
      COLUMN_TOTAL_HOURS: 'Header hours',
      COLUMN_TOTAL_DAYS: 'Header days',
      COLUMN_CANCELLED: 'Cancelled',
      FILTER_DATE: 'Date',
      FILTER_SITE: 'Site/department',
      FILTER_STATUS: 'Status',
      FILTER_TYPE: 'Type',
      FILTER_USER: 'Person',
      FILTER_TOGGLE_CANCELLED: 'Include cancelled',
      FILTER_TOGGLE_USER_ACTIVE: 'Active employees',
      BUTTON_EXPORT: 'Export',
      NO_MATCH: 'We couldn\'t find any absence matching your filters.',
    },
    NEW_HIRE_TURNOVER: {
      TITLE: 'New Hire Turnover',
      FILTER_DATE: 'Join date',
      JUMP_TO: 'Jump to',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_LAST_TWO_WEEKS: 'Last two weeks',
      JUMP_TO_LAST_MONTH: 'Last month',
      FILTER_AGGREGATION: 'Aggregation',
      AGGREGATION_ENTITY_GROUP: 'Site/department',
      AGGREGATION_POSITION: 'Job role',
      AGGREGATION_STAFF_GROUP: 'Staff group',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      DATE_ERROR_DIFF: 'You can\'t choose more than 12 months. Please restrict your date range.',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      COLUMN_AGGREGATION_LABEL: 'Name',
      COLUMN_JOINERS: 'Joiners',
      COLUMN_LEAVERS: 'Leavers',
      COLUMN_TURNOVER_RATE: 'Turnover rate',
      DEPARTED_WITHIN: 'Leave date',
      DAYS_LABEL: 'within {{count}} days',
      LABEL_TOTAL: 'Total',
      LABEL_GRAND_TOTAL: 'Grand Total',
      FILTER_LEAVER_REASON: 'Leaver reason',
    },
    COST_CONTROL_RAW_DATA: {
      TITLE: 'Cost Control Raw Data Export',
      ERROR_500: 'We weren\'t able to export the data just then. Please try again.',
      BUTTON_EXPORT: 'Export',
      LOADING_TEXT: 'Your export is being generated.',
      METRIC_HOURS: 'Hours',
      METRIC_WAGES: 'Wages',
      METRIC_SALES: 'Sales',
      METRIC_COVERS: 'Covers',
      SUBDIVISION_PREDICTION: 'Predictions',
      SUBDIVISION_FORECAST: 'Forecasts',
      SUBDIVISION_ACTUAL: 'Actuals',
      FILTER_METRICS: 'Metrics',
      FILTER_SUBDIVISIONS: 'Subdivisions',
      FILTER_ENTITY: 'Site/department',
      FILTER_ENTITY_BUTTON: 'Site/department',
      FILTER_DATE: 'Date',
      JUMP_TO: 'Jump to',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_LAST_TWO_WEEKS: 'Last two weeks',
      JUMP_TO_LAST_MONTH: 'Last month',
      CALCULATE_ACTUAL_CHECKBOX: 'Calculate actual hours/wages on the fly, where signed-off data isn\'t present.',
      DATE_ERROR_DIFF: 'You can\'t choose more than 2 months. Please restrict your date range.',
      CALCULATE_ACTUAL_ERROR: 'Actual hours/wages cannot be calculated on the fly for more than one week or fifteen sites/departments.',
      REPORT_GENERATED: 'Your report is being generated and will be emailed to you when completed.',
    },
    ABSENCE_EXPORT: {
      TITLE: 'Absence Export',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      COLUMN_ID: 'ID',
      COLUMN_USER_ID: 'Employee ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE: 'Site',
      COLUMN_DEPARTMENT: 'Department',
      COLUMN_STAFF_GROUP: 'Staff group',
      COLUMN_JOB_ROLE_NAME: 'Job role',
      COLUMN_ABSENCE_TYPE: 'Type',
      COLUMN_ABSENCE_TYPE_REASON: 'Reason',
      COLUMN_STATUS: 'Status',
      COLUMN_SCOPE: 'Scope',
      COLUMN_START_DATE: 'Start date',
      COLUMN_END_DATE: 'End date',
      COLUMN_START_TIME: 'Start time',
      COLUMN_END_TIME: 'End time',
      COLUMN_TOTAL_HOURS: 'Hours',
      COLUMN_TOTAL_DAYS: 'Days',
      COLUMN_REQUEST_NAME: 'Requested by',
      COLUMN_REQUEST_DATE: 'Request date',
      COLUMN_REQUEST_MESSAGE: 'Request message',
      COLUMN_REVIEW_NAME: 'Reviewed by',
      COLUMN_REVIEW_DATE: 'Review date',
      COLUMN_REVIEW_MESSAGE: 'Review message',
      COLUMN_EDITED_NAME: 'Last edited by',
      COLUMN_EDITED_DATE: 'Last edited date',
      COLUMN_CANCELLED: 'Cancelled',
      COLUMN_CANCELLED_NAME: 'Cancelled by',
      COLUMN_CANCELLED_DATE: 'Cancelled date',
      FILTER_DATE: 'Start/end date',
      FILTER_SITE: 'Site/department',
      FILTER_STATUS: 'Status',
      FILTER_TYPE: 'Type',
      FILTER_USER: 'Person',
      FILTER_TOGGLE_CANCELLED: 'Include cancelled',
      FILTER_TOGGLE_USER_ACTIVE: 'Active employees',
      BUTTON_EXPORT: 'Export',
      NO_MATCH: 'We couldn\'t find any absence matching your filters.',
    },
    ALLOWANCE_EXPORT: {
      TITLE: 'Allowances Export',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      COLUMN_ID: 'ID',
      COLUMN_USER_ID: 'Employee ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE: 'Site',
      COLUMN_DEPARTMENT: 'Department',
      COLUMN_ABSENCE_TYPE: 'Absence type',
      COLUMN_YEAR_STARTS: 'Year starts',
      COLUMN_EFFECTIVE_DATE: 'Effective date',
      COLUMN_SCHEDULE_OF_ACCRUAL: 'Schedule of accrual',
      COLUMN_UNIT: 'Unit',
      COLUMN_DEDUCTIBLE: 'Deductible',
      COLUMN_TOTAL_ACCRUED: 'Total accrued',
      COLUMN_OPENING_BALANCE: 'Opening balance',
      COLUMN_BOOKED: 'Booked',
      COLUMN_BOOKED_AND_ELAPSED: 'Booked and elapsed',
      COLUMN_REMAINING: 'Remaining',
      COLUMN_ACCRUAL_ESTIMATE: 'Accrual estimate',
      COLUMN_CARRIED_OVER: 'Carried over',
      COLUMN_OVERSPEND_LIMIT: 'Overspend',
      COLUMN_TOTAL_TO_ACCRUE: 'Maximum to accrue',
      COLUMN_ACCRUAL_RATE: 'Accrual rate override',
      COLUMN_ADDED_NAME: 'Added by',
      COLUMN_ADDED_DATE: 'Added date',
      COLUMN_EDITED_NAME: 'Last edited by',
      COLUMN_EDITED_DATE: 'Last edited date',
      COLUMN_FINALISED: 'Finalised',
      COLUMN_FINALISED_REFERENCE_DATE: 'Finalised reference date',
      COLUMN_FINALISED_EDITED_NAME: 'Finalised by',
      COLUMN_FINALISED_EDITED_DATE: 'Finalised on',
      COLUMN_FINALISED_NOTES: 'Finalised notes',
      COLUMN_PROJECT_TOTAL_ACCRUED: 'Predict accrued while booking',
      FILTER_DATE: 'Effective date',
      FILTER_SITE: 'Site/department',
      FILTER_TYPE: 'Absence type',
      FILTER_SCHEDULE_OF_ACCRUAL: 'Schedule of accrual',
      FILTER_USER: 'Person',
      FILTER_TOGGLE_USER_ACTIVE: 'Active employees',
      BUTTON_EXPORT: 'Export',
      NO_MATCH: 'We couldn\'t find any allowances matching your filters.',
    },
    UNTAKEN_HOLIDAY_COST: {
      TITLE: 'Untaken Holiday Cost',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      ERROR_400: 'Your effective date range is too wide, please narrow it and try again.',
      BUTTON_EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      FILTER_DATE: 'Allowance effective date',
      FILTER_SITE: 'Site/department',
      FILTER_TOGGLE_USER_ACTIVE: 'Active employees',
      COLUMN_ID: 'ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE: 'Site',
      COLUMN_DEPARTMENT: 'Department',
      COLUMN_SCHEDULE_OF_ACCRUAL: 'Schedule of accrual',
      COLUMN_UNIT: 'Unit',
      COLUMN_DEDUCTIBLE: 'Deductible',
      COLUMN_TOTAL_ACCRUED: 'Total accrued',
      COLUMN_BOOKED: 'Booked',
      COLUMN_BOOKED_AND_ELAPSED: 'Elapsed',
      COLUMN_UNTAKEN: 'Untaken',
      COLUMN_PAY_AMOUNT: 'Amount',
      COLUMN_PAY_AMOUNT_TYPE: 'Type',
      COLUMN_PAY_PROJECTED_COST: 'Projected cost',
      COLUMN_HEADER_ALLOWANCE: 'Allowance',
      COLUMN_HEADER_PAY: 'Pay',
      LABEL_DEDUCTIBLE_YES: 'Yes',
      LABEL_DEDUCTIBLE_NO: 'No',
      TEXT_GRAND_TOTAL: 'Grand Total',
    },
    EMPLOYMENT_CHANGES: {
      TITLE: 'Employment Changes',
      MODEL_APPOINTMENT: 'Appointment',
      MODEL_PAY: 'Pay',
      MODEL_EMPLOYEE: 'Employee',
      PROPERTY_EMPLOYEE_FIRST_NAME: 'First name',
      PROPERTY_EMPLOYEE_LAST_NAME: 'Last name',
      PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET: 'Average hours manually set',
      PROPERTY_APPOINTMENT_ENTITY_GROUP: 'Site',
      PROPERTY_APPOINTMENT_ENTITY: 'Department',
      PROPERTY_APPOINTMENT_GROUP: 'Staff group',
      PROPERTY_APPOINTMENT_POSITION: 'Job role',
      PROPERTY_APPOINTMENT_COMPANY: 'Company',
      PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE: 'Min obligation',
      PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT: 'Min obligation (unit)',
      PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD: 'Min obligation (period)',
      PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE: 'Max obligation',
      PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT: 'Max obligation (unit)',
      PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD: 'Max obligation (period)',
      PROPERTY_APPOINTMENT_LINE_MANAGER: 'Line manager',
      PROPERTY_APPOINTMENT_PAYROLL_CALENDAR: 'Payroll calendar',
      PROPERTY_PAY_AMOUNT: 'Amount',
      PROPERTY_PAY_AMOUNT_TYPE: 'Amount type',
      PROPERTY_PAY_UPLIFT: 'Wage uplift',
      FILTER_DATE: 'Date',
      JUMP_TO: 'Jump to',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_LAST_TWO_WEEKS: 'Last two weeks',
      JUMP_TO_LAST_MONTH: 'Last month',
      FILTER_SITE: 'Site/department',
      FILTER_PROPERTY: 'Property',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      DATE_ERROR_DIFF: 'You can\'t choose more than 12 months. Please restrict your date range.',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      COLUMN_ID: 'ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE: 'Site',
      COLUMN_DEPARTMENT: 'Department',
      COLUMN_CHANGE: 'Change',
      COLUMN_EFFECTIVE_DATE: 'Effective',
      COLUMN_PROPERTY: 'Property',
      COLUMN_NEW_VALUE: 'New value',
      COLUMN_OLD_VALUE: 'Old value',
      COLUMN_EDITED_BY: 'Changed by',
      COLUMN_EDITED_ON: 'Changed date',
    },
    ANNIVERSARY: {
      TITLE: 'Anniversaries',
      FILTER_DATE: 'Date',
      JUMP_TO: 'Jump to',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_LAST_TWO_WEEKS: 'Last two weeks',
      JUMP_TO_LAST_MONTH: 'Last month',
      FILTER_SITE: 'Site/department',
      FILTER_TYPE: 'Anniversary',
      TYPE_BIRTHDAY: 'Birthday',
      TYPE_EMPLOYMENT: 'Work anniversary',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      DATE_ERROR_DIFF: 'You can\'t choose more than 12 months. Please restrict your date range.',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      COLUMN_ID: 'ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE: 'Site',
      COLUMN_DEPARTMENT: 'Department',
      COLUMN_TYPE: 'Anniversary',
      COLUMN_YEARS: 'Years',
      COLUMN_DATE: 'Date',
    },
    STAFF_TURNOVER: {
      TITLE: 'Staff Turnover',
      FILTER_DATE: 'Date',
      JUMP_TO: 'Jump to',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_LAST_TWO_WEEKS: 'Last two weeks',
      JUMP_TO_LAST_MONTH: 'Last month',
      FILTER_AGGREGATION: 'Aggregation',
      AGGREGATION_ENTITY_GROUP: 'Site/department',
      AGGREGATION_POSITION: 'Job role',
      AGGREGATION_STAFF_GROUP: 'Staff group',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      DATE_ERROR_DIFF: 'You can\'t choose more than 12 months. Please restrict your date range.',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      COLUMN_AGGREGATION_LABEL: 'Name',
      COLUMN_HEADCOUNT_START: 'Headcount (start)',
      COLUMN_HEADCOUNT_END: 'Headcount (end)',
      COLUMN_JOINERS: 'Joiners',
      COLUMN_REHIRES: 'Rehires',
      COLUMN_LEAVERS: 'Leavers',
      COLUMN_TRANSFERS_IN: 'Transfers in',
      COLUMN_TRANSFERS_OUT: 'Transfers out',
      COLUMN_ATTRITION_RATE: 'Attrition rate',
      COLUMN_AVERAGE_SERVICE: 'Average service',
      LABEL_TOTAL: 'Total',
      LABEL_GRAND_TOTAL: 'Grand Total',
      FILTER_LEAVER_REASON: 'Leaver reason',
    },
    DOCUMENT_SIGNATURES: {
      TITLE: 'Document Signing',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      INFO_NO_DATA: 'There are no records that match your filters.',
      LABEL_METHOD_MANUAL: 'Manual',
      LABEL_METHOD_ELECTRONIC: 'Electronic',
      LABEL_STATUS_PENDING: 'Pending',
      LABEL_STATUS_REJECTED: 'Rejected',
      LABEL_STATUS_SIGNED: 'Signed',
      LABEL_STATUS_CANCELLED: 'Cancelled',
      LABEL_RESPONSE_NONE: 'Awaiting response',
      LABEL_RESPONSE_REJECTED: 'Rejected',
      LABEL_RESPONSE_SIGNED: 'Signed',
      FILTER_SITE: 'Site/department',
      FILTER_RECIPIENT_USER: 'Recipient',
      FILTER_ADDED_USER: 'Sender',
      FILTER_METHOD: 'Signature method',
      FILTER_STATUS: 'Status',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      COLUMN_RECIPIENT: 'Recipient',
      COLUMN_EMPLOYER: 'Counterparty',
      COLUMN_DOCUMENT: 'Document',
      COLUMN_DOCUMENT_CATEGORY: 'Destination category',
      COLUMN_METHOD: 'Method',
      COLUMN_STATUS: 'Status',
      COLUMN_ADDED_USER: 'Sender',
      COLUMN_ADDED_DATE: 'Date',
      EMPLOYER_NOT_SET: 'Not required',
      VIEW_MODAL: {
        TITLE: 'Document to sign',
        LABEL_FILE: 'Document',
        LABEL_EXPIRY: 'Document expiry',
        LABEL_TEMPLATE: 'Template used',
        LABEL_CATEGORY: 'Destination category',
        LABEL_ADDED_BY: 'Added by',
        LABEL_METHOD: 'Method',
        TEMPLATE_NOT_USED: 'None',
        EXPIRY_NOT_APPLICABLE: 'Not applicable',
        BUTTON_CLOSE: 'Close',
        HEADING_SIGNERS: 'Signers',
        EMPLOYEE: 'Employee',
        EMPLOYER: 'Employer',
        COLUMN_NAME: 'Name',
        COLUMN_ROLE: 'Role',
        COLUMN_STATUS: 'Status',
        COLUMN_DATE: 'Date',
        LABEL_OPTIONS: 'Options',
        OPTION_REMINDER: 'Send a reminder',
        OPTION_CANCEL: 'Cancel document',
        OPTION_UPLOAD: 'Upload signed document',
        CANCEL_ERROR_500: 'We couldn\'t cancel the document just then.',
        CANCEL_SUCCESS: 'The document has been cancelled.',
        REMINDER_ERROR_500: 'We couldn\'t send a reminder just then.',
        REMINDER_ERROR_400: 'A reminder has already been sent recently. Please wait a while before sending another reminder.',
        REMINDER_SUCCESS: 'Got it! We\'ll send an email reminder now.',
        DOWNLOAD_ERROR_500: 'We couldn\'t download the file just then. Try again or get in touch if this persists.',
        LABEL_DOWNLOAD: 'Download',
        OPTION_DOWNLOAD_ORIGINAL: 'Download original',
        OPTION_DOWNLOAD_SIGNED_COPY: 'Download signed copy',
      },
    },
    WORKING_TIME_VIOLATIONS: {
      TITLE: 'Working Time Violations',
      FILTER_DATE: 'Date',
      JUMP_TO: 'Jump to',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_LAST_TWO_WEEKS: 'Last two weeks',
      JUMP_TO_LAST_MONTH: 'Last month',
      FILTER_SITE: 'Site/department',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      COLUMN_SITE_DEPARTMENT: 'Site/department',
      COLUMN_VIOLATION_HOURS: 'Working hours',
      COLUMN_VIOLATION_HOURS_TOOLTIP: 'The number of occurrences where an employee exceeded 48 working hours (averaged over 17 weeks).',
      COLUMN_VIOLATION_DAYS_OFF: 'Days off',
      COLUMN_VIOLATION_DAYS_OFF_TOOLTIP: 'The number of occurrences where an employee had less than 2 days off (in a 2 week period).',
      COLUMN_VIOLATION_REST: 'Rest',
      COLUMN_VIOLATION_REST_TOOLTIP: 'The number of occurrences where an employee had less than 11 hours rest after a working day.',
      COLUMN_VIOLATION_U18_DAYS_OFF: 'U18: Days off',
      COLUMN_VIOLATION_U18_DAYS_OFF_TOOLTIP: 'The number of occurrences where an employee under the age of 18 had fewer than 2 consecutive days off (in a given week).',
      COLUMN_VIOLATION_U18_SHIFT_LENGTH: 'U18: Long shift',
      COLUMN_VIOLATION_U18_SHIFT_LENGTH_TOOLTIP: 'The number of occurrences where an employee under the age of 18 had a shift longer than 8 hours.',
      COLUMN_VIOLATION_U18_REST: 'U18: Rest',
      COLUMN_VIOLATION_U18_REST_TOOLTIP: 'The number of occurrences where an employee under the age of 18 had less than 11 hours rest after a working day.',
      COLUMN_TOTAL: 'Total',
      ROW_TOTAL: 'Total',
      ROW_GRAND_TOTAL: 'Grand Total',
      INFO_NO_FILTER: 'Select a site/department above to get started.',
      DATE_ERROR_DIFF: 'You can\'t choose more than 12 months. Please restrict your date range.',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      VIOLATION_TYPE_1_DESC: 'Cannot exceed average of 48 working hours over 17 weeks',
      VIOLATION_TYPE_2_DESC: 'Cannot have less than 2 days off in any 2 week period',
      VIOLATION_TYPE_3_DESC: 'Under 18: Must have 2 consecutive days off per week',
      VIOLATION_TYPE_4_DESC: 'Under 18: Shift longer than 8 hours',
      VIOLATION_TYPE_5_DESC: 'Under 18: Less than 12 hours rest after working day',
      VIOLATION_TYPE_6_DESC: 'Less than 11 hours rest after working day',
      VIOLATION_TYPE_1: 'Too many working hours (on average)',
      VIOLATION_TYPE_2: 'Insufficient days off',
      VIOLATION_TYPE_3: 'Under 18: Insufficient days off',
      VIOLATION_TYPE_4: 'Under 18: Shift too long',
      VIOLATION_TYPE_5: 'Under 18: Insufficient rest between shifts',
      VIOLATION_TYPE_6: 'Insufficient rest between shifts',
      FILTER_VIOLATION_TYPE: 'Violation type',
      FILTER_PER_PAGE: 'Rows per page',
      DETAIL_COLUMN_ID: 'ID',
      DETAIL_COLUMN_HR_ID: 'HR ID',
      DETAIL_COLUMN_PAYROLL_ID: 'Payroll ID',
      DETAIL_COLUMN_FIRST_NAME: 'First name',
      DETAIL_COLUMN_LAST_NAME: 'Last name',
      DETAIL_COLUMN_SITE: 'Site',
      DETAIL_COLUMN_DEPARTMENT: 'Department',
      DETAIL_COLUMN_VIOLATION: 'Violation',
      DETAIL_COLUMN_DATE_FROM: 'Date from',
      DETAIL_COLUMN_DATE_TO: 'Date to',
      DETAIL_COLUMN_VALUE: 'Value',
      DETAIL_LABEL_WORKING_HOURS: 'working hours',
      DETAIL_LABEL_WORKING_DAYS: 'working days',
      DETAIL_LABEL_HOURS_REST: 'hours rest',
      DETAIL_INFO_NO_RECORDS: 'You have no Working Time violations, top job!',
      DETAIL_INFO_EMPTY_FILTER_RESULT: 'There are no violations matching your filter(s).',
    },
    CONTRACTUAL_OBLIGATION_SUMMARY: {
      TITLE: 'Contractual Obligation Summary',
      FILTER_DATE: 'Date',
      JUMP_TO: 'Jump to',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_LAST_TWO_WEEKS: 'Last two weeks',
      JUMP_TO_LAST_MONTH: 'Last month',
      FILTER_SITE: 'Site/department',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      FILTER_DATA_SOURCE: 'Data source',
      FILTER_USER: 'Person',
      COLUMN_GROUP_TOTAL: 'Total (paid)',
      COLUMN_GROUP_OBLIGATION: 'Contractual obligation',
      COLUMN_GROUP_ANALYSIS_META: 'Analysed over',
      COLUMN_GROUP_ANALYSIS: 'Analysis',
      COLUMN_ID: 'ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE: 'Site',
      COLUMN_DEPARTMENT: 'Department',
      COLUMN_DAYS_WORKED: 'Days',
      COLUMN_PAID_HOURS: 'Hours',
      COLUMN_AVERAGE_HOURS: 'Average',
      COLUMN_AVERAGE_HOURS_TOOLTIP: 'The average hours worked per day.',
      COLUMN_OBLIGATION_VALUE: 'Value',
      COLUMN_OBLIGATION_UNIT: 'Unit',
      COLUMN_OBLIGATION_PERIOD: 'Period',
      COLUMN_ANALYSIS_START: 'Start',
      COLUMN_ANALYSIS_END: 'End',
      COLUMN_ANALYSIS_PERIODS: '# Periods',
      COLUMN_ANALYSIS_PERIODS_TOOLTIP: 'The number of weeks or months that have been analysed.',
      COLUMN_ANALYSIS_AVERAGE: 'Average',
      COLUMN_ANALYSIS_AVERAGE_TOOLTIP: 'The average number of hours or days worked.',
      COLUMN_ANALYSIS_DIFFERENCE: 'Diff.',
      COLUMN_ANALYSIS_DIFFERENCE_TOOLTIP: 'The difference between the minimum hours/days that should have been worked (the contractual obligation multiplied by the number of periods) and what was actually worked (the total hours or days).',
      INFO_NO_DATA: 'There are no records that match your filters.',
      INFO_NO_FILTER: 'Select a site/department above to get started.',
      PAY_AMOUNT_TYPE_HOURLY: 'Hourly rate',
      PAY_AMOUNT_TYPE_DAILY: 'Daily rate',
      PAY_AMOUNT_TYPE_ANNUAL: 'Annual salary',
      DATA_SOURCE_SHIFTS: 'Shifts',
      DATA_SOURCE_ATTENDANCE: 'Attendance',
      DATA_SOURCE_MATCHED: 'Sanitised',
      CONTRACTUAL_UNIT_HOURS: 'Hours',
      CONTRACTUAL_UNIT_DAYS: 'Days',
      CONTRACTUAL_PERIOD_WEEK: 'Week',
      CONTRACTUAL_PERIOD_MONTH: 'Month',
      CONTRACTUAL_PERIOD_WEEKLY: 'Weekly',
      CONTRACTUAL_PERIOD_MONTHLY: 'Monthly',
      DATE_ERROR_DIFF: 'You can\'t choose more than 14 weeks. Please restrict your date range.',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
    },
    LABOUR_BREAKDOWN: {
      TITLE: 'Labour Breakdown',
      FILE_EXPORT_TITLE: 'Labour Breakdown File Export',
      FILTER_DATE: 'Date',
      JUMP_TO: 'Jump to',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_LAST_TWO_WEEKS: 'Last two weeks',
      JUMP_TO_LAST_MONTH: 'Last month',
      FILTER_SITE: 'Site/department',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      FILTER_DATA_SOURCE: 'Data source',
      FILTER_USER: 'Person',
      FILTER_BEHAVIOUR: 'Behaviour',
      BEHAVIOUR_COST_CONTROL: 'Cost control',
      BEHAVIOUR_PAYROLL: 'Payroll',
      FILTER_SHIFT_TYPE: 'Shift type',
      COLUMN_ID: 'ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE_APPOINTMENT: 'Site (appointment)',
      COLUMN_DEPT_APPOINTMENT: 'Department (appointment)',
      COLUMN_EVENT_TYPE: 'Event type',
      COLUMN_SHIFT_TYPE: 'Shift type',
      COLUMN_SITE_ATTRIBUTION: 'Site (attribution)',
      COLUMN_DEPT_ATTRIBUTION: 'Department (attribution)',
      COLUMN_START_TIME: 'Start',
      COLUMN_END_TIME: 'Finish',
      COLUMN_PAID_HOURS: 'Paid hours',
      COLUMN_UNPAID_HOURS: 'Unpaid hours',
      COLUMN_BASE_PAY: 'Base pay',
      COLUMN_ROLLED_UP_HOLIDAY: 'Accrued holiday',
      COLUMN_TAXES: 'Taxes',
      COLUMN_WAGE_UPLIFT: 'Wage uplift',
      COLUMN_TOTAL_COST: 'Total cost',
      INFO_NO_DATA: 'There are no records that match your filters.',
      INFO_NO_FILTER: 'Select a site/department above to get started.',
      PAY_AMOUNT_TYPE_HOURLY: 'Hourly rate',
      PAY_AMOUNT_TYPE_DAILY: 'Daily rate',
      PAY_AMOUNT_TYPE_ANNUAL: 'Annual salary',
      EVENT_TYPE_SHIFT: 'Shift',
      EVENT_TYPE_ABSENCE: 'Absence',
      EVENT_TYPE_SALARY: 'Salary',
      EVENT_TYPE_TAXES: 'Taxes',
      DATA_SOURCE_SHIFTS: 'Shifts',
      DATA_SOURCE_ATTENDANCE: 'Attendance',
      DATA_SOURCE_MATCHED: 'Sanitised',
      DATE_ERROR_DIFF: 'You can\'t choose more than 14 weeks. Please restrict your date range.',
      DATE_WARNING_WEEKS: 'You\'ve selected a date range that doesn\'t align to calendar weeks. Some employer\'s taxes are calculated using weekly thresholds, so the taxes displayed here may be artificially lower than reality. Base pay and accrued holiday remain correct, but please align your dates to calendar weeks for correct taxes and total cost.',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      ERROR_400: 'Your parameters cause too much data to be generated for a single report. Please reduce the scope of your request and try again.',
    },
    PAYROLL_EXPORT: {
      TITLE: 'Payroll Export',
      FILTER_DATE: 'Date',
      JUMP_TO: 'Jump to',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_LAST_TWO_WEEKS: 'Last two weeks',
      JUMP_TO_LAST_MONTH: 'Last month',
      FILTER_SITE: 'Site/department',
      EXPORT: 'Export',
      EXPORT_PAYROLL_SOFTWARE: 'Payroll software',
      EXPORT_PRINT: 'Print',
      FILTER_AGGREGATION: 'Segmentation',
      FILTER_ABSENCE_TYPES: 'Absence types',
      FILTER_PAY_ELEMENT_TYPES: 'Pay element types',
      FILTER_USER: 'Person',
      FILTER_COMPANY: 'Company',
      TOGGLE_SHOW_ROLLED_UP_HOLIDAY: 'Show rolled-up holiday',
      COLUMN_ID: 'ID',
      COLUMN_HR_ID: 'HR ID',
      COLUMN_PAYROLL_ID: 'Payroll ID',
      COLUMN_FIRST_NAME: 'First name',
      COLUMN_LAST_NAME: 'Last name',
      COLUMN_SITE_ATTRIBUTION: 'Site (attribution)',
      COLUMN_DEPT_ATTRIBUTION: 'Department (attribution)',
      COLUMN_SITE_APPOINTMENT: 'Site (appointment)',
      COLUMN_DEPT_APPOINTMENT: 'Department (appointment)',
      COLUMN_GROUP: 'Group',
      COLUMN_POSITION: 'Job role',
      COLUMN_DAYS_WORKED: 'Days worked',
      COLUMN_PAY_RATE: 'Pay rate',
      COLUMN_AMOUNT_TYPE: 'Amount type',
      COLUMN_PAY_TYPE: 'Pay type',
      COLUMN_PAID_SHIFT_HOURS: 'Paid shift hours',
      COLUMN_UNPAID_SHIFT_HOURS: 'Unpaid shift hours',
      COLUMN_BASE_PAY: 'Base pay',
      COLUMN_ROLLED_UP_HOLIDAY: 'Rolled-up holiday',
      COLUMN_ABSENCE_DAYS: '{{label}} days',
      COLUMN_ABSENCE_HOURS: '{{label}} hours',
      INFO_NO_DATA: 'There are no records that match your filters. Have you signed-off? Don\'t forget, this report uses signed-off data only.',
      INFO_NO_FILTER: 'Select a site/department above to get started.',
      PAY_AMOUNT_TYPE_HOURLY: 'Hourly rate',
      PAY_AMOUNT_TYPE_DAILY: 'Daily rate',
      PAY_AMOUNT_TYPE_ANNUAL: 'Annual salary',
      AGGREGATION_OPTION_ATTRIBUTION: 'The site/department the hours were attributed to',
      AGGREGATION_OPTION_APPOINTMENT: 'The site/department the person was based in at the time',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      ABSENCE_TYPE_DELETED_SUFFIX: 'Deleted',
      DATE_ERROR_MIN: 'Please select a date after 12th December 2018.',
      DATE_ERROR_DIFF: 'You can\'t choose more than 6 months. Please restrict your date range.',
      EXPORT_MODAL: {
        TITLE: 'Export to Payroll Software',
        LABEL_EXPORT_TO: 'Export to',
        BUTTON_DOWNLOAD: 'Download',
        BUTTON_CLOSE: 'Close',
        SUCCESS: 'Your export has successfully downloaded.',
        ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      },
      SIGN_OFF_STATUS_COMPLETE: 'Fully signed off',
      SIGN_OFF_STATUS_NONE: 'Not signed off',
      SIGN_OFF_STATUS_PARTIAL: 'Partially signed off',
      SIGN_OFF_STATUS_DRAWER: {
        TITLE: 'Sign off status',
        BACK_TO_DATES: 'Back to dates',
      },
      FILTER_PAYROLL_CALENDAR: 'Payroll calendar',
      FILTER_PAY_RUN: 'Pay run',
    },
    ROTA_PROGRESS: {
      TITLE: 'Rota Progress',
      JUMP_TO: 'Jump to',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_NEXT_WEEK: 'Next week',
      FILTER_SITE: 'Site/department',
      LIFECYCLE_DRAFT: 'Draft',
      LIFECYCLE_MARKED_FOR_APPROVAL: 'Marked for Approval',
      LIFECYCLE_PUBLISHED: 'Published',
      INCLUDE_NO_DRAFT: 'Show sites with no draft',
      NO_DRAFT: 'No draft',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      COLUMN_SITE: 'Site/department',
      COLUMN_LIFECYCLE: 'Status',
      COLUMN_SCHEDULED_HOURS: 'Scheduled hours',
      COLUMN_SCHEDULED_WAGES: 'Scheduled wages',
      COLUMN_FORECAST_SALES: 'Forecast sales',
      COLUMN_WAGE_PERCENTAGE: 'Wage %',
      ROW_GRAND_TOTAL: 'Grand Total',
      TOOLTIP_BUDGET: 'Budget',
      INFO_NO_DATA: 'There are no sites/departments matching your filters.',
      INFO_NO_FILTER: 'Select a site/department above to get started.',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
    },
    SIGN_OFF_PROGRESS: {
      TITLE: 'Sign-off Progress',
      JUMP_TO: 'Jump to',
      JUMP_TO_LAST_WEEK: 'Last week',
      JUMP_TO_THIS_WEEK: 'This week',
      JUMP_TO_NEXT_WEEK: 'Next week',
      FILTER_SITE: 'Site/department',
      FILTER_STATUS: 'Sign-off status',
      STATUS_NO_ROTA: 'No rota',
      STATUS_INCOMPLETE: 'Incomplete',
      STATUS_REPORTING_DATE: 'Reporting date',
      STATUS_COMPLETE: 'Complete',
      STATUS_TOOLTIP_NO_ROTA: 'There is no published rota this week.',
      STATUS_TOOLTIP_INCOMPLETE: 'Sign-off not been completed for every day this week.',
      STATUS_TOOLTIP_REPORTING_DATE: 'Sign-off has been completed for the days up to and including your reporting date.',
      STATUS_TOOLTIP_COMPLETE: 'Sign-off has been completed for every day this week.',
      TOGGLE_COMPARAND: 'Comparand',
      TOGGLE_COMPARAND_FORECAST: 'Forecast',
      TOGGLE_COMPARAND_BUDGET: 'Budget',
      EXPORT: 'Export',
      EXPORT_PRINT: 'Print',
      COLUMN_WAGES: 'Wages',
      COLUMN_SALES: 'Sales',
      COLUMN_WAGE_PERCENTAGE: 'Wage %',
      COLUMN_HOURS: 'Hours',
      COLUMN_SITE: 'Site/department',
      COLUMN_STATUS: 'Status',
      COLUMN_FORECAST: 'Forecast',
      COLUMN_BUDGET: 'Budget',
      COLUMN_ACTUAL: 'Actual',
      ROW_GRAND_TOTAL: 'Grand Total',
      INFO_NO_DATA: 'There are no sites/departments matching your filters.',
      INFO_NO_FILTER: 'Select a site/department above to get started.',
      ERROR_500: 'We weren\'t able to load your report just then. Please try again.',
      STATUS_MODAL: {
        REVERSE_CONFIRM: 'Are you sure you wish to reverse this sign-off?\n\nThis will unlock the shifts and attendance records on these date(s) so changes can be made.',
        REVERSE_SUCCESS: 'The sign-off has been successfully reversed.',
        REVERSE_ERROR: 'We couldn\'t reverse the sign-off just then. Please try again.',
        TITLE: 'Sign-off status',
        COLUMN_DATE: 'Date',
        COLUMN_STATUS: 'Status',
        COLUMN_OPTIONS: 'Options',
        STATUS_NO_SIGN_OFF: 'No sign-off',
        STATUS_SIGN_OFF: 'Signed-off',
        BUTTON_REVERSE_DAY: 'Reverse',
        BUTTON_REVERSE_WEEK: 'Reverse entire week',
        BUTTON_CLOSE: 'Close',
      },
    },
    TRONC_STATUS: {
      TITLE: 'Tronc Status',
      LIFECYCLE_DRAFT: 'Draft',
      LIFECYCLE_MARKED_FOR_APPROVAL: 'Marked for Approval',
      LIFECYCLE_LOCKED: 'Locked',
      INCLUDE_NO_HEADER: 'Show sites with no draft',
      NO_HEADER: 'No draft',
      COLUMN_SITE: 'Site/department',
      COLUMN_LIFECYCLE: 'Status',
      COLUMN_RECEIVED: 'Total received',
      COLUMN_PAID_OUT: 'Total paid out',
      COLUMN_EDITED_WHO: 'Edited by',
      COLUMN_EDITED_WHEN: 'When',
      INFO_NO_DATA: 'There are no Tronc sites/departments matching your filters.',
      ERROR_500: 'We weren\'t able to load Tronc just then. Please try again.',
    },
    CURRENTLY_CLOCKED_IN: {
      TITLE: 'People Currently Clocked-In',
      NAME: 'Name',
      SITE: 'Site/department',
      POSITION: 'Job role',
      CLOCK_IN_TIME: 'Clock-in time',
      NOBODY_CLOCKED_IN: 'There\'s nobody clocked-in right now!',
      ERROR_500: 'We weren\'t able to load the report just then. Please try again.',
    },
    LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT: {
      FILE_EXPORT_BUTTON_EXPORT: 'Generate and email report',
      REPORT_GENERATED: 'Your report is being generated and will be emailed to you when completed.',
      FILTER_DATE: 'Date',
      FILTER_ENTITY: 'Site/department',
      FILTER_PAY_TYPES: 'Pay types',
      FILTER_DATA_SOURCE: 'Data source',
      FILTER_USER: 'Person',
      FILTER_EVENT_TYPES: 'Event types',
      FILTER_BEHAVIOUR: 'Behaviour',
      FILTER_SHIFT_TYPE: 'Shift type',
    },
    PAYROLL_EXPORT_ASYNC_FILE_EXPORT: {
      FILE_EXPORT_BUTTON_EXPORT: 'Generate and email report',
      REPORT_GENERATED: 'Your report is being generated and will be emailed to you when completed.',
      FILTER_PAYROLL_CALENDAR: 'Payroll calendar',
      FILTER_PAY_RUN: 'Pay run',
      FILTER_DATE: 'Date',
      FILTER_ENTITY: 'Site/department',
      FILTER_AGGREGATION_OPTIONS: 'Segmentation',
      FILTER_USER: 'Person',
      FILTER_ABSENCE_TYPES: 'Absence types',
      FILTER_PAY_ELEMENT_TYPES: 'Pay element types',
      FILTER_PAY_AMOUNT_TYPE: 'Amount type',
      FILTER_ROLLED_UP_HOLIDAY: 'Rolled-up holiday',
      FILTER_COMPANY: 'Company',
    },

  },
  ROTA_MANAGEMENT: {
    DASHBOARD: {
      INDEX: {
        TITLE: 'Dashboard',
        CHECKLIST: 'Rota checklist',
        LABOUR_SPEND: 'Labour spend',
      },
      LABOUR_SPEND_TAB: {
        TITLE: 'Labour overview',
        SHOW_ACTUAL_TIP: 'For the days this week that haven\'t yet elapsed, project the forecast sales and scheduled wages/hours into the actual totals',
        SHOW_ACTUAL_TEXT: 'Project forecast into actual',
        FORECAST: 'Forecast',
        ACTUAL: 'Actual',
        BUDGET: 'Budget',
        SALES: 'Sales',
        WAGES: 'Wages',
        HOURS: 'Hours',
        WAGE_PERCENT: 'Wage %',
        LABOUR_COMP: 'Labour comparison',
        ACTUAL_PROJECTED: 'Actual (projected)',
        TODAYS_VS_SCHEDULED: 'Today\'s attendance vs scheduled',
      },
      WTD_VIOLATIONS_MODAL: {
        TITLE: 'Working Time Violations',
        BODY: 'These are the occasions where we\'ve found your schedules to be violating the Working Time regulations.',
        TO: 'to ',
        ALL: 'All violations',
        TOO_MANY: 'Too many working hours (on average)',
        INSUF_DAYS: 'Insufficient days off',
        INSUF_REST: 'Insufficient rest between shifts',
        UNDER_18_DAYS: 'Under 18: Insufficient days off',
        UNDER_18_LONG: 'Under 18: Shift too long',
        UNDER_18_REST: 'Under 18: Insufficient rest between shifts',
        STAFF_MEMBER: 'Staff member',
        VIOLATION: 'Violation',
        VALUE: 'Value',
        NO_VIOLATIONS: 'You have no Working Time violations, top job!',
        NO_FILTER_VIOLATIONS: 'There are no violations matching your filter(s).',
        AVE_48_OVER_17: 'Cannot exceed average of 48 working hours over 17 weeks',
        LESS_2_DAYS_2_WEEKS: 'Cannot have less than 2 days off in any 2 week period',
        UNDER_18_2_PER_WEEK: 'Under 18: Must have 2 consecutive days off per week',
        UNDER_18_LONGER_8: 'Under 18: Shift longer than 8 hours',
        UNDER_18_LESS_12_REST: 'Under 18: Less than 12 hours rest after working day',
        LESS_11_WEEK: 'Less than 11 hours rest after working day',
        WORKING_HOURS: ' working hours',
        WORKING_DAYS: ' working days',
        HOURS_REST: ' hours rest',
        RECALC: 'Re-calculate',
        LOADING: 'Loading...',
        CLOSE: 'Close',
      },
    },
    LOCATIONS: {
      ADD_LOCATION_MODAL: {
        NEW_SHIFT_LOCATION: 'New attendance zone',
        TYPE: 'Type',
        NEW_LOCATION: 'New zone',
        CHANGE_EXISTING_LOCATION: 'Change existing zone',
        EXISTING_LOCATION: 'Existing zone',
        NAME: 'Name',
        DETAILS: 'Details',
        HELP: 'Rotaready remembers the precise position of the marker on the map, so these details are just for your reference. Feel free to include some notes or a full address.',
        SAVING: 'Saving...',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        ERROR_ENTER_NAME: 'Please enter a name for this zone.',
        ERROR_UPDATE: 'Currently unable to update this zone. Please try again.',
        ERROR_ADD: 'Currently unable to add this zone. Please try again.',
      },
      EDIT_LOCATION_MODAL: {
        EDIT_LOCATION: 'Edit zone',
        HIERARCHY: 'Hierarchy',
        MASTER_LOCATION: 'Master zone',
        SUB_LOCATION: 'Sub zone',
        HELP_HIERARCHY: 'Rotaready lets you create a hierarchy of zones. A sub zone can be placed under any other zone.',
        PARENT_LOCATION: 'Parent zone',
        NAME: 'Name',
        DETAILS: 'Details',
        HELP_DETAILS: 'Rotaready remembers the precise position of the marker on the map, so these details are just for your reference. Feel free to include some notes or a full address.',
        ATTENDANCE: 'Attendance',
        ALLOW_CLOCKING: 'Allow staff to clock-in and clock-out here',
        STAFF_WORKING: 'Only when staff are working a shift marked with this zone',
        HELP_CLOCKING: 'These settings affect whether staff can clock-in and clock-out using the Rotaready mobile app.',
        ATTENDANCE_SENSITIVITY: 'Attendance sensitivity',
        METRES: 'metres',
        HELP_CLOSE_LOCATION: 'This defines how close someone must be to the zone before the Rotaready mobile app will allow them to clock-in or out. Less than 50 metres is not recommended as people with weak signal will be unable to clock-in.',
        MIN_OCCUPANCY: 'Min occupancy',
        HELP_MIN_OCCUPANCY: 'Whenever someone is on shift, Rotaready will alert you if the total number of people also on shift falls below this number.',
        MAX_OCCUPANCY: 'Max occupancy',
        HELP_MAX_OCCUPANCY: 'If provided, Rotaready won\'t allow you to schedule more staff here (at any one time) than the max occupancy.',
        TIP_OPENING: 'Specify opening times to have Rotaready prevent you from scheduling shifts at this zone when it\'s closed.',
        TIP_SUB_LOC: 'As this is a sub-zone, if its parent zone is closed at any time, this sub-zone will be deemed closed too.',
        REGULAR_OPENING_TIMES: 'Regular opening times',
        OPEN_247: 'This zone is open 24/7.',
        TO: 'to',
        SPECIAL_PERIODS: 'Special periods',
        NO_SPECIAL_PERIODS: 'There are no special periods.',
        OPEN: 'Open',
        CLOSED: 'Closed',
        ADD_SPECIAL_PERIOD: 'Add special period',
        REASON: 'Reason',
        REASON_PLACEHOLDER: 'eg. Summer refurbishment',
        FROM: 'From',
        TO_CAPS: 'To',
        ADD: 'Add',
        DELETE: 'Delete',
        SAVING: 'Saving...',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        ALERT_NAME: 'Please ensure you have provided a valid name for the attendance zone.',
        ALERT_PARENT: 'For this to be a sub zone, you must select a parent zone.',
        ALERT_VALID_PARENT: 'Please select a valid parent zone.',
        ALERT_PARENT_LOCATION: 'This attendance zone and your chosen parent zone must both belong to the same Site (or both be Organisation-wide).',
        ALERT_MINIMUM: 'You cannot have a minimum occupancy level higher than the maximum occupancy level.',
        ERROR_UPDATE: 'We couldn\'t update the zone just then. Please try again.',
        ALERT_SUB_CONFIRM: 'This zone still has sub zones. They will be deleted too.\n\nAre you sure you want to proceed?',
        ALERT_CONFIRM: 'Are you sure you want to delete this zone?',
        ERROR_DELETE: 'We were unable to delete the zone just then. Please try again!',
      },
      INDEX: {
        SHIFT_LOCATIONS: 'Attendance zones',
        TIP_FIND: 'To add a new zone, or to change an existing zone, you must first find it with the map. Search for any address or post code, then drag the green marker to reposition it.',
        PLACEHOLDER_SEARCH: 'Search to add a new zone',
        HELP_NO_LOCATIONS: 'You have no existing zones. Search the map to add one.',
        HELP_NO_RESULTS: 'No attendance zones match your search terms.',
        TOOLTIP_SHOW: 'Show on map',
        TOOLTIP_CLOCKING_ALWAYS: 'Staff can always clock-in/out with the Rotaready mobile app here',
        TOOLTIP_CLOCKING_WORKING: 'Staff can clock-in/out with the Rotaready mobile app here, only if they\'re working a shift here',
        ERROR_LOAD: 'We were unable to load your attendance zones. Please try again.',
        NO_ADDRESS: 'No details/address',
        ERROR_LOOKUP: 'We failed to lookup the location of that marker.',
        UNKNOWN_ADDRESS: 'Unknown address',
        ADD_AS_NEW: 'Add as new zone',
        DRAG_TO_CHANGE: 'Drag me to change the location',
        FILTER_LOCATIONS: 'Filter zones...',
      },
    },
    SHIFT_TYPES: {
      EDIT_SHIFT_TYPES_MODAL: {
        TITLE_NEW: 'New shift type',
        TITLE_EDIT: 'Edit shift type',
        SITE_LABEL: 'Site',
        HIERARCHY_LABEL: 'Hierarchy',
        MASTER_TYPE_LABEL: 'Master shift type',
        SUB_TYPE_LABEL: 'Sub shift type',
        SUB_TYPE_HELP: 'Rotaready lets you create a hierarchy of shift types. A sub shift type can be placed under any other shift type.',
        PARENT_TYPE_LABEL: 'Parent shift type',
        NAME_LABEL: 'Name',
        COLOUR_LABEL: 'Colour',
        COST_STREAM_MAPPINGS_LABEL: 'Stream mappings',
        COST_STREAM_TABLE: {
          COST_STREAM_HEADING: 'Stream',
          ATTRIBUTED_HEADING: 'attributed',
          EMPTY_LABEL: 'This isn\'t mapped to any streams.',
          ADD_BUTTON: 'Add new',
        },
        DELETE_BUTTON: 'Delete',
        SAVE_BUTTON: 'Save',
        CANCEL_BUTTON: 'Cancel',
        VALIDATION: {
          NAME_REQUIRED: 'Please ensure you have provided a valid name for the shift type.',
          PARENT_REQUIRED: 'For this to be a sub shift type, you must select a parent shift type.',
          PARENT_VALID: 'Please select a valid parent shift type.',
          PARENT_SAME_SITE: 'This shift type and your chosen parent shift type must both belong to the same Site (or both be Organisation-wide).',
          ATTRIBUTION_OVERAGE: 'We\'ve summed up the percentage attributed to each stream and the total exceeds 100%. You can only attribute a maximum of 100% across one or more streams.',
          ATTRIBUTION_VALID: 'You\'ve entered an invalid \'percentage attributed\' for one or more of the streams. A value of \'50\', for example, will attribute 50% of the wage cost of anyone working this shift type to the corresponding stream.',
          ATTRIBUTION_NONE: 'You must attribute the shift type to at least one stream.',
          DUPLICATE_COST_STREAM_MAPPING: 'A mapping for this stream already exists! You can\'t attribute a shift type to the same stream twice.',
        },
        ERRORS: {
          ADD_ERROR: 'We couldn\'t add your shift type just then. It\'s likely a shift type with the same name already exists! Try choosing another name.',
          UPDATE_ERROR: 'We couldn\'t update the shift type just then. It\'s likely a shift type with the same name already exists! Try choosing another name.',
          DELETE_TYPE: 'We were unable to delete the shift type just then. Please try again!',
        },
        CONFIRM: {
          DELETE_SUB_TYPES: 'This shift type still has sub shift types. They will be deleted too. Are you sure you want to proceed?',
          DELETE_TYPE: 'Are you sure you want to delete this shift type?',
        },
      },
      INDEX: {
        TITLE: 'Shift types',
        SUBTITLE: 'Think of shift types as categories for the different kinds of work people do in your organisation:',
        SUB_TYPE_HELP: 'You can add a \'sub shift type\' by clicking the plus icon next to any shift type. This is useful if you need to break a shift type into more specific duties or roles.',
        TYPES_EMPTY: 'There are no shift types to show.',
        ONLY_ENTITY: 'only',
        WHOLE_ORGANISATION: 'Organisation-wide',
        SHOW_MORE: 'Show more...',
        ERRORS: {
          LOAD_ERROR: 'Oops, we couldn\'t load the shift types just then. Please try again!',
        },
        TOOLTIPS: {
          ADD_SUB_SHIFT_TYPE: 'Add sub shift type',
        },
        ADD_SHIFT_TYPE: 'Add shift type',
      },
    },
    TEMPLATES: {
      EDIT_CONSTRAINTS_MODAL: {
        ADD_TITLE: 'Add new {{currentLevelName}}',
        EDIT_TITLE: 'Edit {{currentLevelName}} constraints',
        NAME_LABEL: 'name',
        LOADING: {
          SAVE: 'Saving...',
        },
        SAVE_AND_CLOSE_BUTTON: 'Save & close',
        CANCEL_BUTTON: 'Cancel',
        OVERRIDE_CONSTRAINT_BUTTON: 'Override {{levelName}} constraint',
        DELETE_OVERRIDE_BUTTON: 'Delete override',
        TAB_HEADINGS: {
          POPULAR: 'Popular',
          STAFF: 'Staff',
          REST: 'Rest',
          OTHERS: 'Others',
        },
        STAFF_SELECTION: {
          TITLE: 'Staff selection',
          FLEXIBLE_STAFFING_LABEL: 'Flexible staffing',
          FAIRLY_HINT: 'Pick fairly from all staff',
          FAIRLY_ANY_TAGS_HINT: 'Pick fairly from those who match <strong>any</strong> of the below tags',
          FAIRLY_ALL_TAGS_HINT: 'Pick fairly from those who match <strong>all</strong> of the below tags',
          POOLING_HINT: 'Treat the below tags as pools of staff and exhaust each one <strong>in order</strong>',
          FIXED_STAFFING_LABEL: 'Fixed staffing',
          SPECIFIC_STAFF_MEMBER_HINT: 'Assign the shift(s) to a specific staff member',
          ASSIGNEE_LABEL: 'Assignee',
          PLACEHOLDERS: {
            TYPE_TAGS: 'Type to add tags',
          },
        },
        PREFERRED_STAFF_SELECTION: {
          TITLE: 'Preferred staff selection',
          HINT: 'If multiple staff are eligible for a given shift, Rotaready will attempt to bias its selection according to the chosen criteria:',
          NO_PREFERENCE: 'Do not prefer any staff over others, except on the grounds of fairness',
          PREFER_ANY_TAG: 'Prefer those who match <strong>any</strong> of the below tags',
          PREFER_ALL_TAG: 'Prefer those who match <strong>all</strong> of the below tags',
          PLACEHOLDERS: {
            TYPE_TAGS: 'Type to add tags',
          },
        },
        STAFF_SELECTION_GROUPS: {
          TITLE: 'Staff selection (groups)',
          HINT: 'This allows you to restrict the selection of staff to those who are a member of any of the groups below. The groups will be resourced in the order in which they are specified:',
          PLACEHOLDERS: {
            TYPE_TAGS: 'Type to add tags',
          },
        },
        STAFF_TYPECASTING: {
          TITLE: 'Staff typecasting',
          HINT: 'When we assign someone to a <em>typecasted</em> shift, they can only be assigned further shifts that day that are a) also <em>typecasted</em> with at least one of these tags, or b) not <em>typecasted</em> at all:',
          PLACEHOLDERS: {
            TYPE_TAGS: 'Type to add tags',
          },
        },
        OUTSOURCING: {
          TITLE: 'Outsourcing',
          HINT: 'If no internal staff are available, allow outsourcing to the following organisations, in the order in which they are specified:',
          PLACEHOLDERS: {
            TYPE_OUTSOURCE_ORGANISATIONS: 'Type to add outsourcing organisations',
          },
        },
        SELECTION_CONSISTENCY: {
          TITLE: 'Selection consistency',
          HINT: 'For shift sets that span multiple days in a given week, when anyone gets allocated to the first day\'s shift, they should:',
          NO_EFFECT: 'have no effect on whether they are picked for any other day\'s shifts',
          ALLOCATE: 'be allocated to all other day\'s shifts in the shift set, where possible',
        },
        REST_PERIOD: {
          TITLE: 'Rest period',
          HINT: 'After working a shift, ensure a staff member is rested (and thus not given another shift) for at least:',
        },
        LOCATION_TOLERANCE: {
          TITLE: 'Location tolerance',
          HINT: 'It is often important that two consecutive shifts with the same specified location are assigned to the same staff member. Specify the maximum number of minutes between two such shifts (end of the first to the start of the second) for us to consider them consecutive in this regard:',
          MINUTES: 'minutes',
          PLACEHOLDERS: {
            MINUTES: 'Minutes between the end of one shift to the start of another',
          },
        },
        DAILY_WINDOW_DEFINITION: {
          TITLE: 'Daily window definition',
          HINT: 'Some organisations have their staff working multiple \'shifts\' in a given day, and for each person, this daily workload of shifts must sit within a certain window of time. These windows of time can vary, and you can define all possible variations here:',
          LIMITED_TIMES: 'We have a limited, fixed set of start/end times for our windows',
          REGULAR_INTERVALS: 'Our windows fall in regular intervals after a given start time',
        },
        TIMES: {
          WINDOW_TIMES_TITLE: 'Window times',
          SPLIT_SHIFT_WINDOW_TIMES: 'Split-shift window times',
          WINDOW_START_TIME: 'Start time of earliest window',
          WINDOW_INTERVAL: 'Interval between valid windows',
          WINDOW_FINISH_TIME: 'Finish time of latest possible window',
          PLACEHOLDERS: {
            COMMA_SEPERATED_TIMES: 'Comma separated list of times, ie. 9:30-17:30,13:00-20:00',
          },
        },
        SHIFT_PAY_RATE: {
          TITLE: 'Shift pay rate',
          HINT: 'Non-salaried staff working shifts generated from this {{currentLevelName}} will earn at their usual hourly rate. You can override this below if required:',
          OPTIONS: {
            NORMAL: 'Normal: staff earn at their usual hourly rate',
            ZERO: 'Zero: staff aren\'t paid for this shift',
            MULTIPLE: 'Multiple: staff earn a multiple of their usual hourly rate',
            FIXED: 'Fixed: override their usual hourly rate with a fixed rate',
          },
          MULTIPLIER: 'Rate multiplier',
          HOURLY_RATE_OVERRIDE: 'Hourly rate override',
          PLACEHOLDERS: {
            MULTIPLIER: 'Hourly rate multiplier',
            HOURLY_RATE_OVERRIDE: 'Hourly rate in local currency, i.e. 8.50',
          },
        },
        SHIFT_TYPE: {
          TITLE: 'Shift type',
          HINT: 'This denotes the \'kind\' of shifts that will be generated from this {{currentLevelName}}. Your staff will see it as the shift\'s title, and if you use Rotaready\'s cost control features, each shift type can be mapped to one or more cost stream.',
        },
        SITE_DEPARTMENT: {
          TITLE: 'Site/department',
          HINT: 'Attribute shifts generated from this {{currentLevelName}} to a different site/department. This is useful for generating cover shifts.',
          ATTRIBUTE_TO_SITE: 'Attribute the wage cost and hours to the chosen site',
        },
      },
      EDIT_SHIFT_SET_MODAL: {
        ADD_TITLE: 'Add shift set',
        ADD_SUBTITLE: 'Provide details about this shift set',
        EDIT_TITLE: 'Edit shift set',
        EDIT_SUBTITLE: 'Modify details about this shift set',
        TIP: 'Rotaready\'s automated scheduler will generate shifts from this shift set and allocate staff accordingly, in a fair and economical manner.',
        TIME_LABEL: 'Start/end time',
        TO: 'to',
        OVERFLOW_WARNING: 'This shift will overflow into the next day.',
        LOCATION_LABEL: 'Attendance zone',
        MINIMUM_LABEL: 'At least',
        MINIMUM_EXPLANATION: 'I want a minimum number of staff per day, but more if possible',
        STAFF_NUMBER_LABEL: 'Exactly',
        STAFF_NUMBER_EXPLANATION: 'I want an exact number of staff per day',
        MAXIMUM_LABEL: 'Up-to',
        MAXIMUM_EXPLANATION: 'I want as many staff as possible per day, up to a max limit',
        SHORT_DAYS: {
          MONDAY: 'Mon',
          TUESDAY: 'Tue',
          WEDNESDAY: 'Wed',
          THURSDAY: 'Thu',
          FRIDAY: 'Fri',
          SATURDAY: 'Sat',
          SUNDAY: 'Sun',
        },
        SAVE_BUTTON: 'Save shift set',
        CANCEL_BUTTON: 'Cancel',
        LOADING: {
          SAVE: 'Saving...',
        },
        ERRORS: {
          UNABLE_TO_ADD: 'Currently unable to add this shift set. Please try again.',
        },
      },
      EDIT_TEMPLATE: {
        LOADING: {
          SAVE: 'Saving...',
        },
        EDIT_CONSTRAINTS: 'Edit constraints',
        CONSTRAINTS_LABEL: 'Template constraints',
        CONSTRAINTS_TEXT: 'Set constraints that will apply to the entire template.',
        PLACEHOLDERS: {
          SEARCH_FILTER: 'Filter...',
        },
        ADD_GROUP_BUTTON: 'Add group',
        TAB_HEADINGS: {
          FLEXIBLE_STAFFING: 'Flexible staffing',
          FIXED_STAFFING: 'Fixed staffing',
        },
        TOOLTIPS: {
          ACTIVE_CONSTRAINTS: 'Active constraints',
          DISABLE: 'Disable',
          ENABLE: 'Enable',
        },
        GROUP_OPTIONS_BUTTON: {
          LABEL: 'Group options',
          ADD_TASK: 'Add task',
          ADD_SHIFT_SET: 'Add shift set',
          EDIT_CONSTRAINTS: 'Edit constraints',
          DELETE_GROUP: 'Delete group',
        },
        HOURS: 'hours',
        SHORT_DAYS: {
          MONDAY: 'Mon',
          TUESDAY: 'Tue',
          WEDNESDAY: 'Wed',
          THURSDAY: 'Thu',
          FRIDAY: 'Fri',
          SATURDAY: 'Sat',
          SUNDAY: 'Sun',
        },
        CHAR_DAYS: {
          MONDAY: 'M',
          TUESDAY: 'T',
          WEDNESDAY: 'W',
          THURSDAY: 'T',
          FRIDAY: 'F',
          SATURDAY: 'S',
          SUNDAY: 'S',
        },
        NO_SHIFT_SETS: 'There are no shift sets in this group. Add one using the \'Group options\' button.',
        OPTIONS_BUTTON: {
          LABEL: 'Options',
          ADD_SHIFT_SET: 'Add shift set',
          EDIT_CONSTRAINTS: 'Edit constraints',
          DELETE_TASK: 'Delete task',
        },
        NO_FLEXIBLE_STAFF_GROUPS_THIS_WEEK: 'You have no flexible staffing groups. Why not add one by clicking \'Add group\' above?',
        NO_FLEXIBLE_STAFF_GROUPS: 'No flexible staffing groups match what you\'re looking for.',
        GROUP_SHIFT_SETS_TABLE: {
          HEADINGS: {
            SHIFT_TYPE: 'Shift type',
            TIME: 'Time',
            DAYS_OF_WEEK: 'Days of week',
          },
        },
        NO_FIXED_STAFF_GROUPS_THIS_WEEK: 'You have no fixed staffing groups. Why not add one by clicking \'Add group\' above?',
        NO_FIXED_STAFF_GROUPS: 'No fixed staffing groups match what you\'re looking for.',
        EDIT_BUTTON: 'Edit',
        TOGGLE_DROPDOWN_BUTTON: 'Toggle dropdown',
        DELETE_BUTTON: 'Delete',
        SAVE_BUTTON: 'Save',
        ERRORS: {
          COULDNT_FIND_TEMPLATE: 'We couldn\'t find the template you were looking for. Please try again.',
          OOPS: 'Oops, something just went wrong. You may have to try again!',
          TEMPLATE_STATE_CHANGE: 'We were unable to enable/disable part of your template just now. Please try again.',
        },
        LEVEL_NAMES: {
          GLOBAL: 'global',
          TEMPLATE: 'template',
          WEEK: 'week',
          GROUP: 'group',
          TASK: 'task',
          SHIFT_SET: 'shift set',
        },
        CONFIRM: {
          DELETE: 'Are you sure you wish to delete this?',
          DELETE_SHIFT_SET: 'Are you sure you wish to delete this shift set?',
        },
      },
      FIXED_STAFFING_SHIFT_SET_MODAL: {
        ADD_TITLE: 'Add shift set',
        ADD_SUBTITLE: 'Provide details about this shift set',
        EDIT_TITLE: 'Edit shift set',
        EDIT_SUBTITLE: 'Modify details about this shift set',
        START_END_LABEL: 'Start/end time',
        SHIFT_OVERFLOW_WARNING: 'This shift will overflow into the next day.',
        SHIFT_TYPE_LABEL: 'Shift type',
        LOCATION_LABEL: 'Attendance zone',
        SHORT_DAYS: {
          MONDAY: 'Mon',
          TUESDAY: 'Tue',
          WEDNESDAY: 'Wed',
          THURSDAY: 'Thu',
          FRIDAY: 'Fri',
          SATURDAY: 'Sat',
          SUNDAY: 'Sun',
        },
        SAVE_BUTTON: 'Save shift set',
        CANCEL_BUTTON: 'Cancel',
        LOADING: {
          SAVE: 'Saving...',
        },
        UNKNOWN_SHIFT_TYPE: 'Unknown shift type',
        ERRORS: {
          UNABLE_ADD: 'Oops, we\'re currently unable to add this shift set. Please try again.',
          UNABLE_UPDATE: 'Oops, we\'re currently unable to update this shift set. Please try again.',
        },
      },
      INDEX: {
        NEW_BUTTON: 'Add new',
        ALERT_LINK_WARNING: 'Templates has moved to the Settings menu. The existing link under the Rotas menu will disappear soon 👀',
        TITLE: 'Rota templates',
        SEARCH_FILTER: 'Search...',
        TEMPLATE_TABLE: {
          NAME_HEADING: 'Name',
          CREATED_BY_HEADING: 'Created by',
          LAST_EDITED_HEADING: 'Last edited',
          OPTIONS_HEADING: 'Options',
        },
        NO_TEMPLATES: 'There are no rota templates to show.',
        CLONE_TEMPLATE: 'Clone',
        DELETE_TEMPLATE: 'Delete',
        EDITED_ON: 'on',
        CONFIRM: {
          DELETE_NODE: 'Are you sure you wish to delete this?',
        },
        SUCCESS: {
          DELETE_NODE: 'Your demand forecast was successfully deleted.',
        },
        ERRORS: {
          DELETE_NODE: 'Oops, something just went wrong. You may have to try again!',
        },
      },
      NEW_TEMPLATE_MODAL: {
        TITLE: 'New template',
        REMINDER: '<strong>Psst!</strong> You already have <strong>{{templateCount}}</strong> template(s). You only need to create a new one if your scheduling requirements have significantly changed. Otherwise, you should edit an existing one.',
        TYPE_LABEL: 'Type',
        NEW_BLANK_TEMPLATE: 'New blank template',
        CLONE_EXISTING_TEMPLATE: 'Clone existing template',
        CLONE_FROM_LABEL: 'Clone from',
        NAME_LABEL: 'Template name',
        NAME_HELP: 'You\'ll be generating rotas from this template on a regular basis & a good name describes who or what these rotas will be for, like \'Support staff rota\'.',
        NAME_PLACEHOLDER: 'ie. Peak season, or B Team rota',
        DEFAULT_SHIFT_TYPE_LABEL: 'Default shift type',
        DEFAULT_SHIFT_TYPE_HELP: 'When you build out your template, you\'ll be able to change the shift type for each group or task & it\'s just handy for us to set a default now.',
        CREATE_BUTTON: 'Create!',
        CREATE_BUTTON_LOADING: 'Please wait, this may take a moment...',
        CANCEL_BUTTON: 'Cancel',
        CONFIRM_CLONE: 'Cloning a template will take a moment or two, depending on the size of the template. Are you sure you want to proceed?',
        CREATE_ERROR: 'Currently unable to create this template. Please try again.',
      },
      DELETE_TEMPLATE_MODAL: {
        INFO_TITLE: 'Delete template',
        INFO_BODY: 'Deleting a template is irreversible and cannot be undone. To convince us that you want to do this, please type the name of the template into the box below, then click delete.',
        DELETE_TEMPLATE_BUTTON: 'Delete template',
        SUCCESS: {
          DELETE_TEMPLATE: 'Your template was successfully deleted.',
        },
        ERRORS: {
          DELETE_TEMPLATE: 'Oops, something just went wrong. You may have to try again!',
        },
      },
    },
    SHIFT: {
      EDIT_MODAL: {
        SHIFT: 'Shift',
        SITE: 'Site',
        START: 'Start',
        BREAK: 'Break',
        NONE: 'None',
        FINISH: 'Finish',
        NEW_SHIFT: 'New shift',
        ATTRIBUTE_WAGE_COST_TO_SITE: ' Attribute the wage cost and hours to the chosen site',
        DATE_AND_TIME: 'Date & time',
        TO: ' to ',
        THEN_REST: ' then rest ',
        SHIFT_TYPE: 'Shift type',
        LOCATION: 'Attendance zone',
        NO_BREAK: 'No break',
        PAID_BREAK: 'Paid break',
        UNPAID_BREAK: 'Unpaid break',
        OF_LENGTH: 'of length',
        BREAK_MESSAGE_WARNING: 'The staff assigned to this shift have different break requirements. When you\'re done, Rotaready will save a separate shift for each person with the correct break set.',
        BREAK_MESSAGE_SUCCESS: 'We\'ve automatically applied the correct break to this shift.',
        PAY_RULE: 'Pay rule',
        APPLIES: ' applies',
        FOR_THE_SCHEDULED_DURATION: 'For the scheduled duration',
        FOR_ANY_OVERTIME: 'For any overtime',
        STAFF_SELECTION: 'Staff selection',
        STAFF_SELECTION_HELP: 'Edit assigned staff and use tags to filter others who are eligible',
        STAFF_MUST_MATCH: 'Staff must match',
        ANY_OF_THE_TAGS: '<strong>any</strong> of the tags',
        ANY: 'any',
        ALL: 'all',
        OF_THE_TAGS: ' of the tags',
        ALL_OF_THE_TAGS: '<strong>all</strong> of the tags',
        TAG_TYPEAHEAD_PLACEHOLDER: 'Type to find tags',
        STAFF_ASSIGNED: 'Assigned',
        NOBODY_IS_ASSIGNED: 'Nobody is assigned',
        STAFF_ELIGIBLE: 'Eligible',
        STAFF_FILTER_PLACEHOLDER: 'Search...',
        SHOW: 'Show',
        MORE: ' more...',
        NOBODY_MATCHES_YOUR_TAGS: 'Nobody matches your tags',
        NOBODY_ELSE_IS_AVAILABLE: 'Nobody else is available',
        MORE_OPTIONS: 'More options',
        ADD_NOTES_AND_ADJUST_PAY_RATES: 'Add notes and adjust pay rates',
        DETAILS: 'Details',
        DETAILS_TEXTAREA_PLACEHOLDER: 'Add notes, duties and other details here...',
        ROTA: 'Rota',
        NO_ROTA: 'No rota',
        PAY_LABEL: 'Pay',
        PAY_TOGGLE: 'This is a paid shift',
        DELETE: 'Delete',
        VIEW_BROADCAST: 'View Broadcast',
        START_BROADCAST: 'Start Broadcast',
        SAVE: 'Save',
        CANCEL: 'Cancel',
      },
      SHIFT_BROADCAST_MODAL: {
        SHIFT: 'Shift',
        SITE: 'Site',
        START: 'Start',
        BREAK: 'Break',
        NONE: 'None',
        FINISH: 'Finish',
        ORIGINAL_PLANNED_STAFF: 'Original Planned Staff',
        EXTRA_STAFF_REQUIRED: 'Extra Staff Required',
        NOT_SHIFT_BROADCASTING_HELP: 'Set to 0 to request as many staff as possible.',
        START_BROADCASTING: 'Start Broadcasting',
        START_BROADCASTING_LOADING: 'Starting Broadcast...',
        CANCEL: 'Cancel',
        ACCEPTED: 'Accepted',
        PENDING: 'Pending',
        REJECTED: 'Rejected',
        EVERYONE: 'Everyone',
        NO_PARTICIPANTS_TO_SHOW: 'There are no broadcast participants to display.',
        NEEDED: 'Needed',
        SHIFT_BROADCASTING_HELP: 'Your broadcast is currently under way. The below list will update as staff accept or reject the shift. The broadcast will automatically end when the correct number of staff have accepted or when everyone has responded.',
        STOP_BROADCASTING: 'Stop Broadcasting',
        STOP_BROADCASTING_LOADING: 'Stopping Broadcast...',
        PAUSE_BROADCAST: 'Pause Broadcast',
        PAUSE_BROADCAST_LOADING: 'Pausing Broadcast...',
        RESUME_BROADCAST: 'Resume Broadcast',
        RESUME_BROADCAST_LOADING: 'Resuming Broadcast...',
        CLOSE: 'Close',
      },
      VIEW_MODAL: {
        SHIFT: 'Shift',
        SITE: 'Site',
        START: 'Start',
        BREAK: 'Break',
        NONE: 'None',
        FINISH: 'Finish',
        LOCATION: 'Attendance zone',
        NONE_SPECIFIED: 'None specified',
        REST: 'Rest',
        DETAILS: 'Details',
        ALLOTTED_BREAK: 'Allotted break',
        ASSIGNED_STAFF: 'Assigned staff',
        ON: 'on',
        SHIFT_ROUTINE: 'Shift routine',
        LAST_UPDATED: 'Last updated',
        GET_DIRECTIONS: 'Get directions',
        SHOW_TRAFFIC: 'Show traffic',
        EDIT: ' Edit',
        CLOSE: 'Close',
      },
    },
  },
  DASHBOARD: {
    TIME_AND_ATTENDANCE: 'Time & Attendance',
    YOU_ARE_NOW: 'You are now',
    ERROR_PLEASE_TRY_AGAIN: 'Error, please try again',
    MY_UPCOMING_SHIFTS: 'My upcoming shifts',
    YOU_HAVE_NO_SHIFTS_TODAY: 'You have no shifts today.',
    ALL_DAY: 'All day',
    AM: 'AM',
    PM: 'PM',
    TODAY_S_SCHEDULE: 'Today\'s schedule',
    THERE_ARE_NO_SHIFTS_SCHEDULED_TODAY: 'There are no shifts scheduled today.',
    WHATEVER: 'Starting at <strong>{{startTime}}</strong> and finishing at <strong>{{endTime}}</strong>',
  },
  SIGN_OUT: {
    LOGGING_OUT: 'Logging out...',
  },
  PARTIALS: {
    VALIDATION: {
      ISSUE_1: {
        HEADING: 'Working day too long',
        BODY: "Your change means <b>{{name}}</b>'s working day would become too long (as per your organisation's settings). Currently it starts on <b>{{start|momentFormat:'lll'}}</b> and finishes at <b>{{end|momentFormat:'lll'}}</b> (then resting until {{endRest|momentFormat:'lll'}}.",
      },
      ISSUE_2: {
        HEADING: 'Overlapping working day',
        BODY: 'Your change means <b>{{name}}</b> has a working day from <b>{{primaryStart|momentFormat:\'lll\'}}</b> until <b>{{primaryEnd|momentFormat:\'LT\'}}</b>. Unfortunately, this overlaps with a working day they already have from <b>{{secondaryStart|momentFormat:\'lll\'}}</b> until <b>{{secondaryEnd|momentFormat:\'LT\'}}</b> (then resting until {{endRest|momentFormat:\'LT\'}}).',
      },
      ISSUE_3: {
        HEADING: 'Shift outside working day',
        BODY: '<b>{{name}}</b> has a shift scheduled from <b>{{start|momentFormat:\'LT\'}}</b> to <b>{{end|momentFormat:\'LT\'}}</b> (then resting until {{endRest|momentFormat:\'LT\'}}). Your change would mean this shift falls partially or wholly outside of the working day.',
      },
      ISSUE_4: {
        HEADING: 'Not available',
        BODY: '<b>{{name}}</b> is unavailable from <b>{{start|momentFormat:\'lll\'}}</b> to <b>{{end|momentFormat:\'LT\'}}</b> as they are',
        REASON_1: 'working a shift ({{eventType}} in {{entityName}})',
        REASON_2: 'busy during a working day',
        REASON_3: 'absent ({{eventType}})',
        REASON_4: 'unavailable due to their availability pattern',
        BUTTON: 'Make an exception & try again',
        BUTTON_LOADING: 'Loading...',
      },
      ISSUE_5: {
        HEADING: 'Contractual obligation not met',
        BODY: 'Your change means <b>{{name}}</b> will not be meeting their contractual obligation. It\'s now <b>{{currentValue}} days(s)</b> but needs to be at least <b>{{comparatorValue}} day(s)</b>.',
      },
      ISSUE_6: {
        HEADING: 'Contractual obligation not met',
        BODY: 'Your change means <b>{{name}}</b> will not be meeting their contractual obligation. It\'s now <b>{{currentValue}} hours(s)</b> but needs to be at least <b>{{comparatorValue}} hours(s)</b>.',
      },
      ISSUE_7: {
        HEADING: 'Tags mismatch',
        BODY: '<b>{{name}}</b> does not have the required tags to work this shift. This means they may lack a skill, qualification or other attribute.',
      },
      ISSUE_8: {
        HEADING: 'Active broadcast',
        BODY: 'There\'s currently an active Shift Broadcast in place for this shift. As your staff might be in the process of responding, we can\'t allow the shift to be modified unless you first cancel the Shift Broadcast.',
      },
      ISSUE_9: {
        HEADING: 'Insufficient days off',
        BODY: 'Your change means <b>{{name}}</b> will be working too many days this week. They\'ll now have <b>{{currentValue}} day(s)</b> off, which is less than their <b>{{comparatorValue}} day(s)</b> required off.',
      },
      ISSUE_10: {
        HEADING: 'Staff member inactive',
        BODY: '<b>{{name}}</b> cannot work on <b>{{start|momentFormat:\'ll\'}}</b>. Their account is either inactive on this date or they have been moved to another site.',
      },
      ISSUE_11: {
        HEADING: 'Rota marked for approval',
        BODY: 'You cannot make any changes to this rota as it has been marked for approval.',
      },
      ISSUE_12: {
        HEADING: 'Period signed-off',
        BODY: 'You cannot make any changes to this rota as it has been signed-off.',
      },
      ISSUE_13: {
        HEADING: 'Working limit exceeded',
        BODY: 'Your change means <b>{{name}}</b> will be working longer than they are allowed to. They\'ll now be working <b>{{currentValue}} day(s)</b> which is more than their limit of <b>{{comparatorValue}} day(s)</b>.',
      },
      ISSUE_14: {
        HEADING: 'Working limit exceeded',
        BODY: 'Your change means <b>{{name}}</b> will be working longer than they are allowed to. They\'ll now be working <b>{{currentValue}} hours(s)</b> which is more than their limit of <b>{{comparatorValue}} hours(s)</b>.',
      },
      ISSUE_15: {
        HEADING: 'Attendance zone closed',
        BODY: 'The shift\'s assigned attendance zone (<b>{{assignedLocation}}</b>) is closed at this time.',
        REASON: 'This is because of: {{reason}}.',
      },
      ISSUE_16: {
        HEADING: 'Attendance zone occupancy exceeded',
        BODY: 'This shift\'s assigned attendance zone has a max occupancy of <b>{{occupancy}}</b> person(s) at any one time. With this shift the occupancy would be {{currentOccupancy}}.',
      },
      ISSUE_17: {
        HEADING: 'Too few staff at attendance zone',
        BODY: 'From {{start|momentFormat:\'lll\'}} to {{end|momentFormat:\'lll\'}}, there is a time when only <b>{{currentValue}}</b> person(s) are working. Whenever someone is on a shift, a minimum of <b>{{comparatorValue}}</b> persons(s) are required.',
      },
      ISSUE_18: {
        HEADING: 'Staff member not approved',
        BODY: '{{name}} cannot work on <b>{{start|momentFormat:\'ll\'}}</b>. Their account has not yet been approved for scheduling.',
      },
      ISSUE_19: {
        HEADING: 'Staff member documents missing/expired',
        BODY: '<b>{{name}}</b> cannot work on <b>{{start|momentFormat:\'ll\'}}</b>. One or more required documents are either missing or expired.',
      },
      ISSUE_20: {
        HEADING: 'Shift too long (Under 18)',
        BODY: 'Your change means <b>{{name}}</b> will be working for <b>{{duration|humaniseDuration}}</b>. As they are under 18 years olf, the Working Time Directive limit is <b>{{limit|humaniseDuration}}</b>.',
      },
      ISSUE_21: {
        HEADING: 'Shift out of bounds',
        BODY: 'You can\'t schedule a shift for <b>{{name}}</b> on <b>{{start|momentFormat:\'ll\'}}</b> because it belongs on a rota that starts on <b>{{periodStart|momentFormat:\'ll\'}}</b> and ends on <b>{{periodEnd|momentFormat:\'ll\'}}</b>.',
      },
    },
  },
  NAV: {
    HOME: 'Home',
    ROTAS: 'Rotas',
    STAFF: 'Staff',
    ATTENDANCE: 'Attendance',
    REPORTS: 'Reports',
    SETTINGS: 'Settings',
    VIEW_ROTA: 'View rota',
    SCHEDULE: 'My schedule',
    ABSENCE: 'My absence',
    AVAILABILITY: 'My availability',
    DOCUMENTS: 'My documents',
    ROTA_DASHBOARD: 'Dashboard',
    ROTA_ROTA_EDITOR: 'Rota editor',
    ROTA_TRONC: 'Tronc',
    ROTA_EVENT_CALENDAR: 'Event planner',
    STAFF_DIRECTORY: 'Directory',
    STAFF_REQUESTS: 'Requests',
    STAFF_ABSENCES: 'Absence calendar',
    STAFF_EMPLOYEE_SUBMITTED_DETAILS: 'Employee submitted details',
    ATTENDANCE_OVERVIEW: 'Attendance',
    ATTENDANCE_ISSUES: 'Issues',
    COST_CONTROL: 'Cost control',
    COST_CONTROL_SIGN_OFF: 'Cost control & sign-off',
    COST_CONTROL_AGGREGATED_TOTALS: 'Aggregated totals',
    ASSET_REGISTRY: 'Asset registry',
    WFM_DASHBOARD: 'My dashboards',
  },
  SETTINGS: {
    INDEX: {
      TITLE: 'Settings',
      PLACEHOLDER_TEXT: '👈 Please select a setting on the left to get started.',
      CATEGORY_HR: 'HR',
      CATEGORY_ROTAS: 'Rotas',
      CATEGORY_ATTENDANCE: 'Attendance',
      CATEGORY_INTEGRATIONS: 'Integrations',
      ITEM_ROTA_TEMPLATES: 'Rota templates',
      ITEM_TRUSTED_DEVICES: 'Trusted devices',
      ITEM_SHIFT_LOCATIONS: 'Attendance zones',
      ITEM_SHIFT_TYPES: 'Shift types',
      ITEM_OUTSOURCING: 'Outsourcing',
      ITEM_ABSENCE_TYPES: 'Absence types',
      ITEM_DOCUMENT_CATEGORIES: 'Document categories',
      ITEM_DOCUMENT_TEMPLATES: 'Document templates',
      ITEM_TAGS_GROUPS: 'Tags & groups',
      ITEM_SHIFT_ROUTINES: 'Shift routines',
      ITEM_REST_BREAKS: 'Rest breaks',
      ITEM_PAY_ELEMENTS: 'Pay elements',
      ITEM_PAY_RULES: 'Pay rules',
      ITEM_PERMISSIONS: 'Permissions',
      ITEM_BUDGETS: 'Budgets',
      ITEM_ASSET_TYPES: 'Asset types',
      ITEM_ENTITY_GROUP_SUBSCRIPTIONS: 'Site access manager',
      ITEM_ENTITY_GROUPS: 'Site manager',
      ITEM_POSITIONS: 'Job roles',
      ITEM_LEAVER_REASONS: 'Leaver reasons',
      ITEM_RECRUITMENT_SOURCES: 'Recruitment sources',
      ITEM_LABOUR_DEPLOYMENT: 'Labour deployment',
    },
    DOCUMENT_TEMPLATES: {
      TITLE: 'Document templates',
      FILTER_LABEL_CATEGORY: 'Category',
      BUTTON_UPLOAD: 'Upload',
      NAME_COLUMN_LABEL: 'Name',
      TEMPLATE_CATEGORY_COLUMN_LABEL: 'Category',
      FILE_NAME_COLUMN_LABEL: 'File name',
      FILE_SIZE_COLUMN_LABEL: 'File size',
      DESTINATION_CATEGORY_COLUMN_LABEL: 'Destination category',
      SIGNATORIES_COLUMN_LABEL: 'Signatories',
      EDITED_COLUMN_LABEL: 'Added by',
      OPTIONS_COLUMN_LABEL: 'Options',
      LINK_DELETE: 'Delete',
      SIGNATORIES_BOTH: 'Employee, employer',
      SIGNATORIES_EMPLOYEE: 'Employee',
      NO_DOCS_MATCH_TEXT: 'We couldn\'t find any document templates matching your filters.',
      ERROR_NO_CATEGORIES: 'Document templates aren\'t enabled for your organisation. Chat with us to get started.',
      ALERT_CONFIRM_DELETE: 'Are you sure you wish to delete this template? This can\'t be undone!',
      ERROR_500: 'We can\'t load document templates right now. Please try again.',
      ERROR_403: 'You don\'t have permission to view/modify document templates.',
      UPLOAD_MODAL: {
        TITLE: 'Upload a document template',
        LABEL_NAME: 'Name',
        HELP_TEXT_NAME: 'Give your template a name you\'ll recognise.',
        LABEL_CATEGORY: 'Template category',
        HELP_TEXT_CATEGORY: 'Keep your templates organised by selecting a category.',
        LABEL_DESTINATION_CATEGORY: 'Destination document category',
        HELP_TEXT_DESTINATION_CATEGORY: 'When this template is used and a generated document is signed, choose the document category the file should be saved to.',
        LABEL_SIGNATORIES: 'Signatories',
        SIGNATORIES_OPTION_BOTH: 'Both the employee and the employer must sign.',
        SIGNATORIES_OPTION_EMPLOYEE: 'Only the employee needs to sign.',
        LABEL_UPLOAD: 'Upload',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_SAVE: 'Save',
        ALERT_SUCCESS: 'Your document template has been saved.',
        ALERT_ERROR_500: 'There was an error uploading the document template. Please try again or contact us if this message persists.',
        ALERT_ERROR_400: 'There\'s something not quite right with your document template.',
      },
    },
    ABSENCE_TYPES: {
      TITLE: 'Absence types',
      ERROR_500: 'We can\'t load absence types right now. Please try again.',
      ERROR_403: 'You don\'t have permission to view/modify absence types.',
      BUTTON_ADD_NEW: 'Add new',
      BUTTON_TOGGLE_DELETED: 'Include deleted',
      COLUMN_NAME: 'Name',
      COLUMN_BOOKABLE: 'Bookable',
      COLUMN_APPROVAL: 'Approval required',
      COLUMN_PAID: 'Paid',
      COLUMN_EDITED: 'Edited by',
      COLUMN_OPTIONS: 'Options',
      YES_LABEL: 'Yes',
      NO_LABEL: 'No',
      LINK_EDIT: 'Edit',
      LINK_DELETE: 'Delete',
      LINK_REINSTATE: 'Reinstate',
      PILL_DELETED: 'Deleted',
      ALERT_CONFIRM_DELETE: 'Are you sure you want to delete this absence type?',
      EDIT_MODAL: {
        ALERT_SUCCESS: 'Your absence type has been saved.',
        ALERT_ERROR_500: 'There was an error creating your absence type. Please try again or contact us if this message persists.',
        ALERT_ERROR_400: 'An absence type already exists with that name. Please choose another.',
        ADD_TITLE: 'New absence type',
        EDIT_TITLE: 'Edit absence type',
        BUTTON_SAVE: 'Save',
        BUTTON_CANCEL: 'Cancel',
        LABEL_NAME: 'Name',
        LABEL_COLOUR: 'Colour',
        LABEL_BOOKABLE: 'Bookable',
        LABEL_BOOKABLE_HELP: 'Allow employees to make absence requests of this type.',
        LABEL_APPROVAL: 'Approval',
        LABEL_APPROVAL_HELP: 'Requests of this type require approval.',
        LABEL_PAID: 'Paid',
        LABEL_PAID_HELP: 'Absence of this type is paid.',
        LABEL_WORKED_HOURS: 'Worked hours',
        LABEL_WORKED_HOURS_HELP: 'Absence of this type contribute towards \'worked hours\'.',
        LABEL_ACCRUES: 'Accrues holiday',
        LABEL_ACCRUES_HELP: 'Employees will accrue holiday for absence of this type if they have an accrual-based holiday allowance.',
        LABEL_OVERLAPS_SHIFTS: 'Shift match',
        LABEL_OVERLAPS_SHIFTS_HELP: 'Absence of this type can match-up with shifts, in place of a clock-in and a clock-out.',
        LABEL_TRONC: 'Tronc',
        LABEL_TRONC_HELP: 'Absence of this type contributes towards worked hours in Tronc.',
        LABEL_ALLOWANCE_REQUIRED: 'Allowance required',
        LABEL_ALLOWANCE_REQUIRED_HELP: 'To request absence of this type, ensure the employee has an active allowance.',
        TITLE_FILE_UPLOADS: 'File uploads',
        LABEL_SUBMISSION_UPLOAD: 'On request',
        LABEL_SUBMISSION_UPLOAD_HELP: 'Allow a file to be attached when absence of this type is requested.',
        LABEL_REVIEW_UPLOAD: 'On review',
        LABEL_REVIEW_UPLOAD_HELP: 'Allow a file to be attached when absence of this type is approved or rejected.',
        LABEL_SAVE_TO: 'Save to',
        HELP_TEXT_SAVE_TO: 'When a file is attached to absence of this type, it will be saved to the employee\'s documents under the chosen category.',
        FILE_UPLOAD_OPTION_NONE: 'Turned off',
        FILE_UPLOAD_OPTION_OPTIONAL: 'Optional',
        FILE_UPLOAD_OPTION_MANDATORY: 'Required',
        INFO_LOCKED: 'This is a special absence type used by Rotaready. We\'ve hidden some properties that can\'t be changed. Please get in touch if you need help with this absence type.',
        TITLE_PAY: 'Pay',
        LABEL_FIXED_AMOUNTS: 'Fixed amounts',
        HELP_TEXT_FIXED_AMOUNTS: 'When paying an employee a fixed amount, such payment for accrued but untaken absence, this pay element will be used.',
        TITLE_REASONS: 'Reasons',
        COLUMN_TITLE: 'Reason',
        COLUMN_OPTIONS: 'Options',
        LINK_DELETE: 'Delete',
        NO_REASONS: 'No reasons have been added.',
        PLACEHOLDER_REASON: 'New reason...',
        LINK_ADD: 'Add',
      },
    },
    OUTSOURCING: {
      TITLE: 'Outsourcing',
      BUTTON_ADD_NEW: 'Add new',
      BUTTON_TOGGLE_DELETED: 'Include deleted',
      NO_DATA_TEXT: 'There are no outsourcing organisations to show.',
      NAME_COLUMN_TEXT: 'Name',
      ENTITY_COLUMN_TEXT: 'Site/department',
      RESOURCES_COLUMN_TEXT: 'Resources',
      HOURS_COLUMN_TEXT: 'Hours available',
      COST_COLUMN_TEXT: 'Cost',
      LAST_EDITED_COLUMN_TEXT: 'Edited by',
      OPTIONS_COLUMN_TEXT: 'Options',
      UNLIMITED_TEXT: 'Unlimited',
      ALWAYS_24_TEXT: 'Always (24 hours)',
      PER_HOUR_TEXT: '/hour',
      PER_DAY_TEXT: '/day',
      ORG_WIDE_TEXT: 'Organisation-wide',
      LINK_EDIT: 'Edit',
      LINK_DELETE: 'Delete',
      LINK_REINSTATE: 'Reinstate',
      PILL_DELETED: 'Deleted',
      ERROR_500: 'We can\'t load outsourcing organisations right now. Please try again.',
      ERROR_403: 'You don\'t have permission to view/modify outsourcing organisations.',
      ALERT_CONFIRM_DELETE: 'Are you sure you want to delete this outsourcing organisation?',
      EDIT_MODAL: {
        ALERT_SUCCESS: 'Your outsourcing organisation has been saved.',
        ALERT_ERROR_500: 'There was an error creating your outsourcing organisation. Please try again or contact us if this message persists.',
        ALERT_ERROR_400: 'An outsourcing organisation already exists with that name. Please choose another.',
        EDIT_TITLE: 'Edit organisation',
        ADD_TITLE: 'Add organisation',
        NAME_LABEL: 'Name',
        ENTITY_LABEL: 'Site/department',
        PAY_LABEL: 'Pay',
        HOURLY_OPTION: 'Hourly paid',
        FIXED_OPTION: 'Fixed cost per day',
        HOURLY_RATE_LABEL: 'Hourly rate',
        DAILY_RATE_LABEL: 'Daily rate',
        AUTOMATIC_SCHEDULE_TITLE: 'Automated scheduling',
        HOURS_AVAILABLE_LABEL: 'Hours available',
        TO_TEXT: ' to ',
        HOURS_AVAILABLE_HELP: 'The time during which workers from this organisation are available',
        RESOURCES_AVAILABLE_LABEL: 'Resources available',
        RESOURCES_AVAILABLE_HELP: 'The max number of workers at your disposal per day (enter 0 for unlimited)',
        TAGS_LABEL: 'Tags',
        TAGS_PLACEHOLDER: 'Type to search tags',
        TAGS_HELP: 'Assign tags like you would to a normal staff member. These will apply to all workers of this organisation.',
        BUTTON_SAVE: 'Save',
        BUTTON_CANCEL: 'Cancel',
      },
    },
    TRUSTED_DEVICES: {
      TITLE: 'Trusted devices',
      DEVICE: 'Device',
      ENTITY: 'Primary site/department',
      DEVICE_TYPE: 'User agent',
      EDITED_BY: 'Edited by',
      LAST_ACTIVITY: 'Last activity',
      STATUS: 'Status',
      OPTIONS: 'Options',
      NO_DEVICES: 'There are no trusted devices for recording attendance.',
      NO_ACTIVITY: 'No activity',
      LINK_UNTRUST: 'Untrust',
      LINK_TRUST: 'Trust',
      LINK_EDIT: 'Edit',
      ALERT_CONFIRM_TRUST: 'Are you sure you wish to trust this device for attendance capture?',
      ALERT_CONFIRM_UNTRUST: 'Are you sure you wish to untrust this device so it can no longer be used for attendance capture? This cannot be reversed.',
      ERROR_500: 'We can\'t load trusted devices right now. Please try again.',
      ERROR_403: 'You don\'t have permission to view/modify trusted devices.',
      STATUS_PENDING: 'Pending',
      STATUS_TRUSTED: 'Trusted',
      STATUS_UNTRUSTED: 'Untrusted',
      FILTER_SITE: 'Mapped site/department',
      FILTER_STATUS: 'Status',
      BUTTON_NEW_DEVICE: 'New device',
      NEW_DEVICE_MODAL: {
        TITLE: 'Add a new device',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_DONE: 'Continue',
        TEXT_STEP_1: 'Step 1',
        TEXT_STEP_2: 'Step 2',
        TEXT_STEP_1_BODY: 'Grab your tablet or laptop, open a web browser and navigate to the following URL:',
        TEXT_STEP_2_BODY_A: 'When asked for a "Realm ID", enter the following value:',
        TEXT_STEP_2_BODY_B: 'When asked for an "Entity ID", enter the following value:',
        TEXT_STEP_3_BODY: 'When you\'re done, click Continue to return to the devices list. Your new device should then appear. Click Trust alongside the device to complete the pairing process.',
      },
      EDIT_DEVICE_MODAL: {
        TITLE: 'Edit device',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_SAVE: 'Save',
        BODY: 'By selecting more sites or departments below, the associated employees will appear on the home screen of the device. You cannot deselect the primary site/department; you must untrust the device if you wish to change this.',
        ALERT_200: 'The device has been updated.',
        ALERT_500: 'We could not update the device just then. Please try again later.',
        LABEL_NAME: 'Device name',
      },
    },
  },
  MESSAGING: {
    BETA: 'BETA',
    MESSAGE_HUB: {
      TITLE: 'Messages',
      NO_MESSAGES: 'No messages',
      START_NEW: 'Start new conversation',
      YESTERDAY: 'yesterday',
    },
    CONVERSATION: {
      MESSAGE_PLACEHOLDER: 'Add your message',
      INDIVIDUAL_TYPING: '{{name}} is typing...',
      MULTIPLE_TYPING: 'Multiple people are typing...',
    },
    START_NEW: {
      TITLE: 'Start new conversation',
      TO: 'To:',
      REMOVE: 'Remove',
      START_CONVERSATION: 'Start conversation',
    },
    ALERTS: {
      START_CONVERSATION: 'There has been an error starting your conversation. Please try again.',
      SUBSCRIBE_TO_CHANNELS: 'There is an issue subscribing to your conversations. You may not receive any messages. Please refresh, or get in touch if this persists.',
      FETCH_MESSAGES: 'There is an issue getting your messages. Please refresh, or get in touch if this persists.',
      FETCH_NEW_MESSAGES: 'There has been an error getting new messages. Please refresh, or get in touch if this persists.',
      FETCH_CONVERSATIONS: 'There has been an error fetching your conversations. Please refresh, or get in touch if this persists.',
      SEND_MESSAGE: 'There has been an error sending your message. Please try again.',
    },
  },
  ABSENCE: {
    REQUEST: {
      TITLE: 'Absence request',
      BUTTON_SUBMIT: 'Submit',
      LABEL_TYPE: 'Choose a type',
      LABEL_REASON: 'Select a reason',
      LABEL_EMPLOYEE: 'Employee',
      LABEL_START_DATE: 'Start date',
      LABEL_END_DATE: 'End date',
      LABEL_SCOPE_ALL_DAY: 'All day',
      LABEL_SCOPE_AM: 'AM only',
      LABEL_SCOPE_PM: 'PM only',
      LABEL_SCOPE_CUSTOM: 'Choose specific hours',
      LABEL_START_TIME: 'Start time',
      LABEL_END_TIME: 'End time',
      ALERT_OVERLAPPING_ABSENCE_USER: 'You have another absence booking that overlaps with your chosen date(s).',
      ALERT_OVERLAPPING_ABSENCE_OTHERS: 'Just so you know, there are other people also absent at this time.',
      ERROR_500: 'We hit a snag just then. Please try again!',
      LABEL_DAYS: 'days',
      LABEL_HOURS: 'hours',
      SECTION_UNPAID_DAYS: 'Regular days off',
      HELP_TEXT_NO_UNPAID_DAYS: 'There are no regular days off in this request.',
      BUTTON_OPTIONS: 'Options',
      BUTTON_CHANGE: 'Change regular days off',
      BUTTON_RESET: 'Revert regular days off to suggested',
      BUTTON_CLEAR: 'Clear all regular days off',
      MODAL_CALENDAR_TITLE: 'Choose regular days off',
      MODAL_BUTTON_SAVE: 'Save',
      LABEL_ADD_A_NOTE: 'Add a note to this request',
      BUTTON_COMPLETE: 'Take me back',
      COMPLETE_TITLE: 'You\'re all set.',
      COMPLETE_LABEL_APPROVED: 'Approved',
      COMPLETE_TEXT_APPROVED: 'Your request has been submitted and approved automatically ✅',
      COMPLETE_LABEL_PENDING: 'Pending approval',
      COMPLETE_TEXT_PENDING: 'Your request has been submitted and is awaiting approval. We\'ll notify you when this changes 👍',
    },
    VALIDATION_MODAL: {
      TITLE: 'Something\'s not quite right',
      DESCRIPTION: 'Unfortunately what you\'re attempting to do causes one or more issues.',
      BUTTON_CLOSE: 'Close',
      INVALID_DATES_TITLE: 'Invalid dates',
      INVALID_DATES_DESCRIPTION: 'You can only choose AM only, PM only, or custom hours if your start and end date is the same day.',
      INVALID_HEADER_TITLE: 'Invalid absence record',
      INVALID_HEADER_DESCRIPTION: 'You can\'t edit that absence record. It might have been deleted.',
      INVALID_ACTION_TITLE: 'Invalid action',
      INVALID_ACTION_DESCRIPTION: 'You can\'t change the time of an absence booking after it\'s been rejected or cancelled.',
      INVALID_DAYS_TITLE: 'Invalid days',
      INVALID_DAYS_DESCRIPTION: 'You can\'t place an absence request for zero hours or zero days.',
      OUTSIDE_EMPLOYMENT_TITLE: 'Outside employment',
      OUTSIDE_EMPLOYMENT_DESCRIPTION: 'Your chosen start or end date falls outside of the employee\'s employment dates.',
      SPANS_TIMEZONES_TITLE: 'Date range spans two timezones',
      SPANS_TIMEZONES_DESCRIPTION: 'The employee\'s appointment records indicate that they move site during your chosen dates, and their existing site is in a different timezone to their new site. Make sure your absence booking ends before they move site, or starts after they move site.',
      SPANS_PAYROLL_CALENDARS_TITLE: 'Date range spans two payroll calendars',
      SPANS_PAYROLL_CALENDARS_DESCRIPTION: 'The employee\'s appointment records indicate that they change payroll calendar during your chosen dates. Make sure your absence booking ends before they change payroll calendar, or starts after they change payroll calendar.',
      INVALID_PAYROLL_CALENDAR_TITLE: 'Payroll calendar invalid',
      INVALID_PAYROLL_CALENDAR_DESCRIPTION: 'The chosen date range starts before the first pay run of the employee\'s payroll calendar.',
      OVERLAPPING_ABSENCE_TITLE: 'Overlapping absence',
      OVERLAPPING_ABSENCE_DESCRIPTION: 'The employee is already absent on one or more days that overlap with your chosen dates.',
      OVERLAPPING_SHIFTS_TITLE: 'Overlapping shifts',
      OVERLAPPING_SHIFTS_DESCRIPTION: 'The employee has one or more published shifts that overlap with your chosen dates. These must be deleted or unassigned first.',
      SIGNED_OFF_TITLE: 'Dates signed-off',
      SIGNED_OFF_DESCRIPTION: 'The dates overlap with one or more signed-off days. You can\'t approve or cancel absence for days which have already been signed-off.',
      ALLOWANCE_REQUIRED_TITLE: 'Allowance required',
      ALLOWANCE_REQUIRED_DESCRIPTION: 'You can\'t book absence of this type. There must be an active allowance to deduct from.',
      ALLOWANCE_NOT_DEDUCTIBLE_TITLE: 'Allowance not deductible',
      ALLOWANCE_NOT_DEDUCTIBLE_DESCRIPTION: 'You can\'t book absence of this type. There is an active allowance but it\'s marked as not deductible.',
      ALLOWANCE_BALANCE_INSUFFICIENT_TITLE: 'Insufficient balance',
      ALLOWANCE_BALANCE_INSUFFICIENT_DESCRIPTION: 'The allowance balance is too low for this request to be submitted.',
      FILE_UPLOAD_REQUIRED_TITLE: 'File upload required',
      FILE_UPLOAD_REQUIRED_DESCRIPTION: 'This type of absence requires you to upload a file before it\'s submitted or approved.',
    },
    VIEW_MODAL: {
      TITLE: 'Absence',
      BUTTON_OPTIONS: 'Options',
      BUTTON_EDIT: 'Edit/review booking',
      BUTTON_CANCEL: 'Cancel booking',
      BUTTON_CLOSE: 'Close',
      STATUS_CANCELLED: 'Cancelled',
      LABEL_START_DATE: 'Starts',
      LABEL_END_DATE: 'Ends',
      LABEL_ABSENCE_TYPE: 'Type',
      LABEL_SCOPE: 'Duration',
      ERROR_500: 'We hit a little snag. Please try again.',
      ERROR_404: 'That absence request no longer exists.',
      LABEL_SUBMITTED_BY: 'Submitted by',
      LABEL_REVIEWED_BY: 'Reviewed by',
      LABEL_CANCELLED_BY: 'Cancelled by',
      LABEL_EDITED_BY: 'Edited by',
      DURATION_TEXT: '{{hours}} hours, {{days}} days',
      TAB_BREAKDOWN: 'Breakdown',
      TITLE_PAY_PERIODS: 'By pay period',
      NO_PAY_PERIODS: 'The employee was not associated with a payroll calendar when this booking was made.',
      TITLE_DAILY_BREAKDOWN: 'By day',
      NO_DAYS: 'There are no days in this booking.',
      TAB_FILES: 'Documents',
      FILES_NONE: 'Nothing has been uploaded for this booking.',
      FILES_AVAILABLE: 'These documents can also be found in the Staff area when viewing the employee\'s profile. You\'ll find them under this category: {{categoryName}}',
      FILES_COLUMN_NAME: 'Name',
      FILES_COLUMN_UPLOADED_BY: 'Uploaded by',
      FILES_COLUMN_OPTIONS: 'Options',
      FILES_LINK_DELETE: 'Delete',
      FILES_CONFIRM_DELETE: 'Are you sure you wish to delete this? It will also be removed from the Documents tab of the employee\'s profile.',
      CONFIRM_CANCEL: 'Are you sure you want to cancel this absence booking? This cannot be undone.',
      ALERT_IS_CANCELLED: 'The absence booking has been cancelled.',
      ALERT_SUSPECT_CUSTOM_HOURS: 'Warning! The requester chose a start and finish time that equates to more than {{halfDayHours}} hours, which is the number of hours they currently work for a half-day.',
      ALERT_DAYS_NOT_AS_SUGGESTED: 'Warning! The requester chose to amend the regular days off, rather than use Rotaready\'s suggested days.',
    },
    EDIT: {
      TITLE: 'Absence',
      BUTTON_SUBMIT: 'Save',
      BUTTON_APPROVE: 'Approve',
      BUTTON_REJECT: 'Reject',
      TAB_EDIT_BOOKING: 'Edit dates',
      TAB_REVIEW: 'Review',
      TAB_FILES: 'Documents',
      TAB_CALENDAR: 'Calendar',
      LABEL_TYPE: 'Type',
      LABEL_REASON: 'Select a reason',
      LABEL_EMPLOYEE: 'Employee',
      LABEL_START_DATE: 'Start date',
      LABEL_END_DATE: 'End date',
      LABEL_SCOPE_ALL_DAY: 'All day',
      LABEL_SCOPE_AM: 'AM only',
      LABEL_SCOPE_PM: 'PM only',
      LABEL_SCOPE_CUSTOM: 'Choose specific hours',
      LABEL_START_TIME: 'Start time',
      LABEL_END_TIME: 'End time',
      ERROR_500: 'We hit a snag just then. Please try again!',
      LABEL_DAYS: 'days',
      LABEL_HOURS: 'hours',
      SECTION_UNPAID_DAYS: 'Regular days off',
      HELP_TEXT_NO_UNPAID_DAYS: 'There are no regular days off in this request.',
      BUTTON_OPTIONS: 'Options',
      BUTTON_CHANGE: 'Change regular days off',
      BUTTON_RESET: 'Revert to originally booked',
      BUTTON_RECALCULATE: 'Recalculate afresh',
      BUTTON_CLEAR: 'Clear all regular days off',
      UNPAID_DAYS_RECALCULATED_LABEL: 'Recalculated',
      UNPAID_DAYS_RECALCULATED_HELP: 'You\'ve chosen to calculate the regular days off afresh and use the prevailing value for the employee\'s \'hours per working day\' instead of what it was when the booking was originally made.',
      UNPAID_DAYS_MERGED_LABEL: 'Merged',
      UNPAID_DAYS_MERGED_HELP: 'Since you\'ve changed the date, we\'ve included any new days off, removed any that are no longer in scope and left the others untouched.',
      MODAL_CALENDAR_TITLE: 'Choose regular days off',
      MODAL_BUTTON_SAVE: 'Save',
      BUTTON_COMPLETE: 'Take me back',
      COMPLETE_TITLE: 'You\'re good to go.',
      COMPLETE_TEXT: 'We\'ve saved the absence booking and the relevant people have been notified 👍',
      INVALID_HEADER_TITLE: 'Invalid absence',
      INVALID_HEADER_TEXT: 'We can\'t load that absence booking. Perhaps it\'s been cancelled or you don\'t have permission to see it.',
      TITLE_OVERLAPPING_ABSENCE: 'Concurrent absence',
      TITLE_OVERLAPPING_SHIFTS: 'Overlapping shifts',
      TITLE_NOTABLE_EVENTS: 'Notable events',
      COLUMN_OVERLAPPING_ABSENCE_DATES: 'Dates',
      COLUMN_OVERLAPPING_ABSENCE_TYPE: 'Type',
      COLUMN_OVERLAPPING_ABSENCE_WHO: 'Who',
      NO_OVERLAPPING_ABSENCE: 'There\'s no concurrent absence matching your filters.',
      NO_OVERLAPPING_SHIFTS: 'There are no overlapping shifts in the employee\'s site/department.',
      NO_NOTABLE_EVENTS: 'There are no notable events at the time of this absence.',
      COLUMN_OVERLAPPING_SHIFTS_DATE: 'Date',
      COLUMN_OVERLAPPING_SHIFTS_SITE: 'Site/department',
      COLUMN_OVERLAPPING_SHIFTS_TYPE: 'Shift type',
      COLUMN_OVERLAPPING_SHIFTS_OPTIONS: 'Options',
      LINK_EDIT_SHIFT: 'Edit rota',
      LINK_DELETE_SHIFT: 'Delete',
      BUTTON_BULK_SHIFT_OPTIONS: 'Bulk actions',
      BUTTON_BULK_SHIFT_UNASSIGN_ALL: 'Unassign selected',
      BUTTON_BULK_SHIFT_DELETE_ALL: 'Delete selected',
      ALERT_BULK_SHIFT_CONFIRM: 'Are you sure you wish to do this to {{count}} shifts?',
      ALERT_BULK_SHIFT_EDIT_FAILURE: 'We couldn\'t amend the shifts because it would cause a validation issue. Please make this change on the rota to see more information.',
      ALERT_SINGLE_SHIFT_DELETE_FAILURE: 'We couldn\'t delete that shift because it would cause a validation issue. Please make this change on the rota to see more information.',
      COLUMN_NOTABLE_EVENTS_NAME: 'Name',
      COLUMN_NOTABLE_EVENTS_DATE: 'Date',
      NOTABLE_EVENTS_ABSENCE_RESTRICTION_TOOLTIP: 'Absence is restricted during this event.',
      LABEL_ADD_A_NOTE: 'Optionally write a message for the employee',
      OVERLAPPING_SHIFTS_MORE: 'We\'re only showing the first fifteen overlapping shifts. Resolve these to see more.',
      OVERLAPPING_ABSENCE_MORE: 'We\'re only showing the first fifteen overlapping bookings.',
      CALENDAR_UNPAID_DAY: 'Regular day off',
      FILTER_LABEL_ENTITY: 'Site/department',
      FILTER_LABEL_STAFF_GROUP: 'Staff group',
      FILTER_LABEL_ABSENCE_TYPE: 'Type',
      FILES_NONE: 'Nothing has been uploaded for this booking.',
      FILES_AVAILABLE: 'These documents can also be found in the Staff area when viewing the employee\'s profile. You\'ll find them under this category: {{categoryName}}',
      FILES_COLUMN_NAME: 'Name',
      FILES_COLUMN_UPLOADED_BY: 'Uploaded by',
      FILES_COLUMN_OPTIONS: 'Options',
      FILES_LINK_DELETE: 'Delete',
      FILES_CONFIRM_DELETE: 'Are you sure you wish to delete this? It will also be removed from the Documents tab of the employee\'s profile.',
      ALERT_SUSPECT_CUSTOM_HOURS: 'Warning! The requester chose a start and finish time that equates to more than {{halfDayHours}} hours, which is the number of hours they currently work for a half-day.',
      ALERT_DAYS_NOT_AS_SUGGESTED: 'Warning! The requester chose to amend the regular days off, rather than use Rotaready\'s suggested days. Please review them carefully on the Edit Dates tab and choose the Recalculate Afresh option if necessary.',
    },
    ENUM_SCOPE: {
      ALL_DAY: 'All day',
      AM: 'AM only',
      PM: 'PM only',
      CUSTOM: 'Custom hours',
    },
    ENUM_APPROVAL_STATE: {
      APPROVED: 'Approved',
      REJECTED: 'Rejected',
      PENDING: 'Pending',
    },
    ENUM_ALLOWANCE_UNIT: {
      DAYS: 'Days',
      HOURS: 'Hours',
    },
    ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL: {
      BEGINNING_OF_ACCRUAL_YEAR: 'Beginning of accrual year',
      EACH_CALENDAR_MONTH: 'Each calendar month',
      EACH_HOUR_WORKED: 'Each hour worked',
    },
    ALLOWANCE_IMPACT_DIRECTIVE: {
      DAYS_DEBIT: '{{count}} day deduction',
      HOURS_DEBIT: '{{count}} hour deduction',
      DAYS_CREDIT: '{{count}} day credit',
      HOURS_CREDIT: '{{count}} hour credit',
      REMAINING: '({{count}} remaining)',
      WARNING_EXCEEDS: 'Your request exceeds this allowance but can still be submitted.',
      WARNING_NOT_PERMITTED: 'Your request exceeds this allowance.',
      TOOLTIP_PROJECTED: 'Rotaready has included a prediction of what you are likely to accrue. This may be limited according to your employer\'s policies.',
      CALC_TOTAL_ACCRUED: 'Total accrued',
      CALC_BOOKED: 'Booked',
      CALC_PROJECTED: 'Predicted accrual',
      CALC_THIS_REQUEST: 'This request',
      CALC_REMAINING: 'Remaining',
      CALC_CONVERTED_TO_DAYS: 'As days',
    },
  },
};

module.exports = translations;
