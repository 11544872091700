var controllers = require('../../module');

controllers.controller('EditPermissionLevelModalCtrl', ['$scope', '$uibModalInstance', 'data', 'SessionService', 'EVENT_CALENDAR_ENABLED', 'DOCUMENT_CATEGORIES_ENABLED', 'ENTITY_GROUP_MANAGER_ENABLED',
    function ($scope, $uibModalInstance, data, SessionService, EVENT_CALENDAR_ENABLED, DOCUMENT_CATEGORIES_ENABLED, ENTITY_GROUP_MANAGER_ENABLED) {
        var isNotableEventsSelfServiceEnabled = SessionService.isNotableEventsSelfServiceEnabled();
        var areShiftRoutinesEnabled = SessionService.areShiftRoutinesEnabled();
        var isAssetRegistryEnabled = SessionService.isAssetRegistryEnabled();
        var labourDeploymentEnabled = SessionService.isLabourDeploymentEnabled();

        if (data.isAdd) {
            $scope.formData = {
                name: '',
                isFullAccess: false,
                grantKeys: {}
            };
        } else {
            var level = data.level;

            var grantKeys = {};

            level.grantKeys.forEach(function (key) {
                grantKeys[key] = true;
            });

            $scope.formData = {
                name: level.name,
                isFullAccess: level.isFullAccess,
                grantKeys: grantKeys
            };
        }

        $scope.categories = [
            {
                name: 'HQ',
                rootKey: 'hq',
                keys: [
                    {
                        name: 'Dashboard',
                        description: 'Access to HQ dashboard',
                        options: [
                            {
                                name: 'Allowed'
                            }
                        ]
                    },
                    {
                        name: 'Site access',
                        description: 'Edit HQ site access for all staff',
                        baseKey: 'siteAccess',
                        options: [
                            {
                                name: 'Allowed'
                            }
                        ]
                    },
                    {
                        name: 'Rest breaks',
                        description: 'View or change break settings',
                        baseKey: 'restBreaks',
                        options: [
                            {
                                name: 'View',
                                key: 'view',
                            },
                            {
                                name: 'Edit',
                                key: 'edit'
                            }
                        ]
                    },
                    {
                        name: 'Shift routines',
                        description: 'View or change shift routine settings',
                        baseKey: 'shiftRoutines',
                        options: [
                            {
                                name: 'View',
                                key: 'view',
                            },
                            {
                                name: 'Edit',
                                key: 'edit'
                            }
                        ]
                    },
                    {
                        name: 'Pay types',
                        description: 'View or change pay type settings',
                        baseKey: 'payTypes',
                        options: [
                            {
                                name: 'View',
                                key: 'view',
                            },
                            {
                                name: 'Edit',
                                key: 'edit'
                            }
                        ]
                    },
                    {
                        name: 'Pay rules',
                        description: 'View or change pay rule settings',
                        baseKey: 'payRules',
                        options: [
                            {
                                name: 'View',
                                key: 'view',
                            },
                            {
                                name: 'Edit',
                                key: 'edit'
                            }
                        ]
                    },
                ].filter(Boolean)
            },
            {
                name: 'Staff',
                rootKey: 'staff',
                keys: [
                    {
                        name: 'Staff',
                        description: 'Access to Staff',
                        options: [
                          {
                            name: 'View directory',
                          },
                          {
                            name: 'Approve',
                            key: 'approve',
                          },
                          {
                            name: 'Anonymise',
                            key: 'anonymise',
                          },
                          {
                            name: 'Delete',
                            key: 'delete',
                          },
                        ],
                    },
                    {
                        name: 'Add staff',
                        description: 'Add new members of staff',
                        baseKey: 'add',
                        options: [
                            {
                                name: 'As reportees',
                            },
                            {
                                name: 'Any permission level',
                                key: 'anyPermissionLevel',
                            }
                        ]
                    },
                    {
                        name: 'Personal details',
                        description: 'View or change personal details',
                        baseKey: 'personal',
                        options: [
                            {
                                name: 'View reportees',
                                key: ';r;reportee'
                            },
                            {
                                name: 'View everyone',
                                key: ';r;*'
                            },
                            {
                                name: 'Edit reportees',
                                key: ';w;reportee'
                            },
                            {
                                name: 'Edit everyone',
                                key: ';w;*'
                            }
                        ]
                    },
                    {
                        name: 'Employments',
                        description: 'View or change employment records and appointments',
                        baseKey: 'appointment',
                        options: [
                            {
                                name: 'View reportees',
                                key: ';r;reportee'
                            },
                            {
                                name: 'View everyone',
                                key: ';r;*'
                            },
                            {
                                name: 'Edit reportees',
                                key: ';w;reportee'
                            },
                            {
                                name: 'Edit everyone',
                                key: ';w;*'
                            }
                        ]
                    },
                    {
                        name: 'Pay',
                        description: 'View or change pay records',
                        baseKey: 'pay',
                        options: [
                            {
                                name: 'View reportees',
                                key: ';r;reportee'
                            },
                            {
                                name: 'View everyone',
                                key: ';r;*'
                            },
                            {
                                name: 'Edit reportees',
                                key: ';w;reportee'
                            },
                            {
                                name: 'Edit everyone',
                                key: ';w;*'
                            }
                        ]
                    },
                    {
                        name: 'Financials',
                        description: 'View or change sensitive financial information',
                        baseKey: 'financial',
                        options: [
                            {
                                name: 'View reportees',
                                key: ';r;reportee'
                            },
                            {
                                name: 'View everyone',
                                key: ';r;*'
                            },
                            {
                                name: 'Edit reportees',
                                key: ';w;reportee'
                            },
                            {
                                name: 'Edit everyone',
                                key: ';w;*'
                            }
                        ]
                    },
                    {
                        name: 'Documents',
                        description: 'View or change documents',
                        baseKey: 'documents',
                        options: [
                            {
                                name: 'View reportees',
                                key: ';r;reportee'
                            },
                            {
                                name: 'View everyone',
                                key: ';r;*'
                            },
                            {
                                name: 'Edit reportees',
                                key: ';w;reportee'
                            },
                            {
                                name: 'Edit everyone',
                                key: ';w;*'
                            }
                        ]
                    },
                    {
                        name: 'Document Signatures',
                        description: 'Send documents for signature, see the Document Signing report, send reminders and cancel documents.',
                        baseKey: 'documentSigning',
                        options: [
                            {
                                name: 'Allowed'
                            }
                        ]
                    },
                    {
                        name: 'Sensitive Documents',
                        description: 'View and upload documents to categories marked as sensitive. These are typically reserved for HR.',
                        baseKey: 'sensitiveDocuments',
                        options: [
                            {
                                name: 'Allowed'
                            }
                        ]
                    },
                    {
                        name: 'Notes',
                        description: 'View or add employee notes',
                        baseKey: 'notes',
                        options: [
                            {
                                name: 'View reportees',
                                key: ';r;reportee'
                            },
                            {
                                name: 'View everyone',
                                key: ';r;*'
                            },
                            {
                                name: 'Add to reportees',
                                key: ';w;reportee'
                            },
                            {
                                name: 'Add to everyone',
                                key: ';w;*'
                            }
                        ]
                    },
                    {
                        name: 'Sensitive Notes',
                        description: 'View and add notes marked as sensitive. This is typically reserved for HR.',
                        baseKey: 'sensitiveNotes',
                        options: [
                            {
                                name: 'Allowed'
                            }
                        ]
                    },
                    {
                        name: 'Submitted Details',
                        description: 'Accept or ignore changes to personal details submitted by employees.',
                        baseKey: 'submittedDetails',
                        options: [
                            {
                                name: 'Reportees',
                                key: ';reportee'
                            },
                            {
                                name: 'Everyone',
                                key: ';*'
                            },
                        ]
                    },
                    areShiftRoutinesEnabled && {
                        name: 'Shift routines',
                        description: 'View or change shift routines for reportees',
                        baseKey: 'shiftRoutines',
                        options: [
                            {
                                name: 'View reportees',
                                key: 'view'
                            },
                            {
                                name: 'Edit reportees',
                                key: 'edit'
                            }
                        ]
                    },
                    areShiftRoutinesEnabled && {
                        name: 'Shift routines',
                        description: 'View or change shift routines for everyone',
                        baseKey: 'editAll',
                        options: [
                            {
                                name: 'View/edit everyone'
                            }
                        ]
                    }
                ].filter(Boolean),
            },
            {
                name: 'Absence & Availability',
                rootKey: 'availability',
                keys: [
                    {
                        name: 'Allowances',
                        baseKey: 'absence.allowances',
                        description: 'View or change absence allowances',
                        options: [
                            {
                                name: 'View own',
                                key: 'viewSelf'
                            },
                            {
                                name: 'View reportees',
                                key: 'view'
                            },
                            {
                                name: 'View everyone',
                                key: 'viewAll'
                            },
                            {
                                name: 'Edit reportees',
                                key: 'edit'
                            },
                            {
                                name: 'Edit everyone',
                                key: 'editAll'
                            }
                        ]
                    },
                    {
                        name: 'Bookings',
                        baseKey: 'absence.requests',
                        description: 'View, add or cancel absence bookings',
                        options: [
                            {
                                name: 'View reportees',
                                key: 'view'
                            },
                            {
                                name: 'View everyone',
                                key: 'viewAll'
                            },
                            {
                                name: 'Add/cancel reportees',
                                key: 'edit'
                            },
                            {
                                name: 'Add/cancel everyone',
                                key: 'editAll'
                            }
                        ]
                    },
                    {
                        name: 'Requests',
                        baseKey: 'absence.requests',
                        description: 'Approve or reject absence requests',
                        options: [
                            {
                                name: 'Approve/reject reportees',
                                key: 'approver'
                            },
                            {
                                name: 'Approve/reject everyone',
                                key: 'approverAll'
                            },
                            {
                                name: 'Auto-approve own',
                                key: 'autoApprove'
                            }
                        ]
                    },
                    {
                        name: 'Types',
                        baseKey: 'absence.types',
                        description: 'Add or edit absence types',
                        options: [
                            {
                                name: 'Add/edit absence types',
                                key: 'edit'
                            },
                        ]
                    },
                    {
                        name: 'Patterns',
                        baseKey: 'patterns',
                        description: 'View or edit availability patterns',
                        options: [
                            {
                                name: 'Edit own',
                                key: 'edit'
                            },
                            {
                                name: 'View everyone',
                                key: 'viewAll',
                            },
                            {
                                name: 'Edit everyone',
                                key: 'editAll'
                            },
                            {
                                name: 'Edit exceptions',
                                key: 'exceptions'
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Rotas',
                rootKey: 'rota',
                keys: [
                    {
                        name: 'Rotas',
                        description: 'Access to rotas',
                        options: [
                            {
                                name: 'Allowed'
                            }
                        ]
                    },
                    {
                        name: 'Cost control',
                        description: 'Access to the Cost Control tool and \'Hours & Cost\' in the rota',
                        baseKey: 'cost',
                        options: [
                            {
                                name: 'Allowed'
                            }
                        ]
                    },
                    {

                        name: 'Individual total pay',
                        description: "View an individual's total pay in the rota if I have the ability to view a person's pay",
                        baseKey: 'viewIndividualFinancials',
                        options: [
                            {
                                name: 'Allowed'
                            }
                        ]
                    },
                    {
                        name: 'Templates',
                        description: 'Generate from or add, edit and delete templates',
                        baseKey: 'template',
                        options: [
                            {
                                name: 'Edit',
                                key: 'edit'
                            },
                            {
                                name: 'Generate',
                                key: 'generate'
                            }
                        ]
                    },
                    {
                        name: 'Publishing',
                        description: 'Publish a draft rota',
                        baseKey: 'draft',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'publish'
                            }
                        ]
                    },
                    {
                        name: 'Shift types',
                        description: 'Add, edit or remove shift types',
                        baseKey: 'shiftTypes',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'editAll'
                            }
                        ]
                    },
                    {
                        name: 'Sign off',
                        description: 'Sign off weeks or reverse a sign off',
                        baseKey: 'signOff',
                        options: [
                            {
                                name: 'Sign off',
                            },
                            {
                                name: 'Reverse',
                                key: 'unlock'
                            }
                        ]
                    },
                    {
                        name: 'Shifts',
                        description: 'View certain properties of shifts',
                        baseKey: 'shifts',
                        options: [
                            {
                                name: 'Shifts other than your own',
                                key: 'viewNotAssigned',
                            },
                            {
                                name: 'Rest break',
                                key: 'viewBreak'
                            },
                            {
                                name: 'Location',
                                key: 'viewLocation'
                            },
                            {
                                name: 'Shift type',
                                key: 'viewShiftType'
                            },
                        ]
                    },
                ]
            },
            {
                name: 'Reports',
                rootKey: 'report',
                keys: [
                    {
                        name: 'Reports',
                        description: 'Access to Reports & Tools',
                        options: [
                            {
                                name: 'Allowed'
                            }
                        ]
                    },
                    {
                        name: 'Timesheet',
                        description: 'Run a Timesheet report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'timesheet'
                            },
                        ]
                    },
                    {
                        name: 'Timesheet (Payroll)',
                        description: 'Run a Timesheet (Payroll) report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'timesheetPayroll'
                            },
                        ]
                    },
                    {
                        name: 'Payroll Export',
                        description: 'Run a Payroll Export report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'payrollExport'
                            },
                        ]
                    },
                    {
                        name: 'Staff Activity',
                        description: 'Run a Staff Activity report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'staffActivity'
                            },
                        ]
                    },
                    {
                        name: 'Holiday Allowances',
                        description: 'Run a Holiday Allowances report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'holidayAllowances'
                            },
                        ]
                    },
                    {
                        name: 'Absence',
                        description: 'Run an Absence report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'absence'
                            },
                        ]
                    },
                    {
                        name: 'Contractual Obligations',
                        description: 'Run a Contractual Obligations report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'contractualObligations'
                            },
                        ]
                    },
                    {
                        name: 'WTD Violations',
                        description: 'Run a Working Time violations report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'wtdViolations'
                            },
                        ]
                    },
                    {
                        name: 'Shift Type Breakdown',
                        description: 'Run a Shift Type Breakdown report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'shiftTypeStreamBreakdown'
                            },
                        ]
                    },
                    {
                        name: 'Anniversary',
                        description: 'Run an Anniversary report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'anniversary'
                            },
                        ]
                    },
                    {
                        name: 'Employment Changes',
                        description: 'Run an Employment Changes report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'employmentChanges'
                            },
                        ]
                    },
                    {
                        name: 'Untaken Holiday Cost',
                        description: 'Run an Untaken Holiday Cost report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'untakenHolidayCost'
                            },
                        ]
                    },
                    {
                        name: 'Attendance breakdown',
                        description: 'Run an Attendance breakdown report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'attendanceBreakdown'
                            },
                        ]
                    },
                    {
                        name: 'Lateness',
                        description: 'Run a lateness report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'lateness'
                            },
                        ]
                    },
                    {
                        name: 'Rota Progress',
                        description: 'Run a rota progress report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'rotaProgress'
                            },
                        ]
                    },
                    {
                        name: 'Sign-off progress',
                        description: 'Run a sign-off progress report',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'signOffProgress'
                            },
                        ]
                    },
                ]
            },
            {
                name: 'Time & Attendance',
                rootKey: 'attendance',
                keys: [
                    {
                        name: 'Time & Attendance',
                        description: 'Access to Time & Attendance',
                        options: [
                            {
                                name: 'Allowed'
                            }
                        ]
                    },
                    {
                        name: 'Attendance',
                        description: 'View or change attendance data',
                        options: [
                            {
                                name: 'View own',
                                key: 'view.viewSelf'
                            },
                            {
                                name: 'View',
                                key: 'view'
                            },
                            {
                                name: 'Edit reportees',
                                key: 'edit'
                            },
                            {
                                name: 'Edit everyone',
                                key: 'editAll'
                            }
                        ]
                    },
                ]
            },
            {
                name: 'Tronc',
                rootKey: 'tronc',
                keys: [
                    {
                        name: 'Tronc',
                        description: 'Use the Tronc tool',
                        options: [
                            {
                                name: 'Allowed',
                            },
                        ]
                    },
                    {
                        name: 'View balance',
                        description: 'See a site\'s Tronc balance',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'balance'
                            },
                        ]
                    },
                    {
                        name: 'Troncmaster',
                        description: 'Lock/unlock Tronc',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'master'
                            },
                        ]
                    },
                    {
                        name: 'Reporting',
                        description: 'Run Tronc reports',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'report'
                            },
                        ]
                    }
                ],
            },
            {
                name: 'Settings',
                rootKey: 'settings',
                keys: [
                    {
                        name: 'Document templates',
                        description: 'Manage templates for document signing',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'documentTemplates',
                            },
                        ],
                    },
                    {
                        name: 'Outsourcing',
                        description: 'Manage outsourcing groups',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'outsourcing',
                            },
                        ],
                    },
                    {
                        name: 'Shift locations',
                        description: 'Manage shift locations',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'shiftLocations',
                            },
                        ],
                    },
                    {
                        name: 'Trusted devices',
                        description: 'Manage trusted devices',
                        options: [
                            {
                                name: 'Allowed',
                                key: 'trustedDevices',
                            },
                        ],
                    },
                    {
                        name: 'Staff groups',
                        description: 'Add, edit or remove staff groups',
                        options: [
                            {
                                name: 'Edit staff groups',
                                key: 'staffGroups',
                            },
                        ]
                    },
                    {
                        name: 'HR settings',
                        description: 'Add, edit or remove positions / leaver reasons / recruitment sources',
                        options: [
                            {
                                name: 'Edit HR settings',
                                key: 'hr',
                            },
                        ]
                    },
                    {
                        name: 'Tags',
                        description: 'Add, edit or remove tags',
                        options: [
                            {
                                name: 'Edit tags',
                                key: 'tags',
                            },
                        ]
                    },
                    DOCUMENT_CATEGORIES_ENABLED && {
                        name: 'Document categories',
                        description: 'View, edit or remove document categories',
                        baseKey: 'documentCategories',
                        options: [
                            {
                                name: 'View',
                                key: 'view',
                            },
                            {
                                name: 'Edit',
                                key: 'edit',
                            }
                        ]
                    },
                    labourDeploymentEnabled && {
                        name: 'Labour deployment models',
                        description: 'View or edit labour deployment models',
                        baseKey: 'labourDeployment',
                        options: [
                            {
                                name: 'Edit',
                                key: 'edit',
                            }
                        ]
                    },
                    ENTITY_GROUP_MANAGER_ENABLED && {
                        name: 'Site manager',
                        description: 'Add, edit or remove sites',
                        baseKey: 'siteManager',
                        options: [
                            {
                                name: 'Edit',
                                key: 'edit',
                            }
                        ],
                    },
                ].filter(Boolean),
            },
            EVENT_CALENDAR_ENABLED && {
                name: 'Notable events',
                rootKey: 'notableEvents',
                keys: [{
                    name: 'Notable events',
                    description: 'See Notable events',
                    options: [{
                        name: 'Allowed',
                    }]
                },
                isNotableEventsSelfServiceEnabled && {
                    name: 'Add single site events',
                    description: 'Add a notable event for a single site',
                    options: [{
                        name: 'Allowed',
                        key: 'singleSite',
                    }]
                },
                isNotableEventsSelfServiceEnabled && {
                    name: 'Add organisational wide events',
                    description: 'Add a notable event across an entire organisation',
                    options: [{
                        name: 'Allowed',
                        key: 'orgWide',
                    }]
                }].filter(Boolean)
            },
            isAssetRegistryEnabled && {
                name: 'Asset registry',
                rootKey: 'assetRegistry',
                keys: [{
                    name: 'View assets',
                    description: 'View assets in the asset registry',
                    options: [
                        {
                            name: 'Allowed',
                            key: 'viewAssets',
                        }
                    ],
                }, {
                    name: 'View asset types',
                    description: 'View asset types',
                    options: [
                        {
                            name: 'Allowed',
                            key: 'viewAssetTypes',
                        }
                    ],
                }, {
                    name: 'Add assets',
                    description: 'Assign an asset to an employee',
                    options: [
                        {
                            name: 'Allowed',
                            key: 'addAsset',
                        }
                    ],
                }, {
                    name: 'Update asset',
                    description: 'Update an asset assigned to an employee',
                    options: [
                        {
                            name: 'Allowed',
                            key: 'updateAsset',
                        }
                    ],
                },{
                    name: 'Add asset type',
                    description: 'Add an asset type to the asset registry',
                    options: [
                        {
                            name: 'Allowed',
                            key: 'addAssetType',
                        }
                    ],
                }, {
                    name: 'Update asset type',
                    description: 'Update an asset type in the asset registry',
                    options: [
                        {
                            name: 'Allowed',
                            key: 'updateAssetType',
                        }
                    ],
                }]
            }
        ].filter(Boolean);

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.save = function () {
            var grantKeys = [];

            Object.keys($scope.formData.grantKeys).forEach(function (key) {
                if ($scope.formData.grantKeys[key]) {
                    grantKeys.push(key);
                }
            });

            $uibModalInstance.close({
                name: $scope.formData.name,
                isFullAccess: $scope.formData.isFullAccess,
                grantKeys: grantKeys
            });
        };

        function compileKeys() {
            $scope.categories.forEach(function (category) {
                var rootKey = category.rootKey;

                category.keys.forEach(function (key) {
                    var baseKey = key.baseKey;

                    key.options.forEach(function (option) {
                        var key = option.key;

                        var finalKey = rootKey;

                        if (baseKey) {
                            finalKey += '.' + baseKey;
                        }

                        if (key && key.charAt(0) === ';') {
                            finalKey += key;
                        } else if (key) {
                            finalKey += '.' + key;
                        }

                        option.key = finalKey;
                    });
                });
            });
        }

        compileKeys();
    }]);
