var controllers = require('../../module');

controllers.controller(
    'PayRunXeroCtrl',
    [
        '$scope',
        '$http',
        '$stateParams',
        '$timeout',
        '$q',
        'AlertService',
        'ENDPOINT_API',
        function ($scope, $http, $stateParams, $timeout, $q, AlertService, ENDPOINT_API) {
            var apiBaseUrl = ENDPOINT_API + '/integration/xero';
            var alertServiceId = 'xero';

            var organisationId = $stateParams.organisationId;
            $scope.payRunId = $stateParams.payRunId;

            $scope.props = {
                isOneLineSelected: false,
                selectAllLines: false,
                dataLoaded: false,
                bulkActionInProgress: false,
                showOnlyMatchedUsers: false,
            };

            $scope.toggleLine = function () {
                var linesSelected = 0;

                $scope.summaryLines.forEach(function (r) {
                    if (r.isSelected) {
                        linesSelected++;
                    }
                });

                $scope.props.isOneLineSelected = linesSelected > 0;
                $scope.props.selectAllLines = linesSelected === $scope.summaryLines.length;
            };

            $scope.toggleAllLines = function () {
                $scope.summaryLines.forEach(function (r) {
                    r.isSelected = $scope.props.selectAllLines;
                });

                $scope.props.isOneLineSelected = $scope.props.selectAllLines;
            };

            $scope.getPayRun = function () {
                if ($scope.props.bulkActionInProgress) {
                    return;
                }

                $scope.props.dataLoaded = false;

                $http.get(apiBaseUrl + '/organisation/' + organisationId + '/payRuns/' + $scope.payRunId)
                    .success(function (data) {
                        $scope.organisation = data.organisation;
                        $scope.payRun = data.payRun;
                        $scope.summaryLines = data.summaryLines;

                        $scope.props.dataLoaded = true;
                    })
                    .error(function () {
                        AlertService.add('danger', 'We can\'t load the pay run right now. Please check you have configured Xero correctly in Rotaready settings.');
                    });
            };

            $scope.getPayRun();

            $scope.rowFilter = function (row) {
                if (!$scope.props.showOnlyMatchedUsers) {
                    return true;
                }

                return !!row.employeeId;
            };

            $scope.onRowFilterChange = function () {
                $scope.props.selectAllLines = false;
                $scope.toggleAllLines();
            };

            function isAnyActionInProgress() {
                return $scope.summaryLines.some(function (row) {
                    return row.actionInProgress;
                });
            }

            function handleError(response, row, retryFunction, resolveFunction, rejectFunction) {
                var status = response.status;
                var data = response.data;

                if (status === 400 && data.errors) {
                    var error = data.errors[0];

                    if (error && error.code === 7 && error.payload.canRetry && error.payload.retryAfter) {
                        var retryMillis = error.payload.retryAfter * 1000;
                        row.actionInProgress = true;

                        if (retryMillis > 3000) {
                            AlertService.add(
                                'warning',
                                "There's a limit to how many requests we can send Xero each minute. Please hold on, we'll automatically try again in a moment.",
                                'xeroThrottleAlert',
                                retryMillis);
                        }

                        $timeout(function () {
                            row.actionInProgress = false;
                            retryFunction();
                        }, retryMillis);
                        return;
                    }

                    if (error && error.code === 3) {
                        row.validationIssue = true;
                        resolveFunction();
                    }
                }

                rejectFunction();
            }

            function retryablePost(row, resolve, reject) {
                if (row.actionInProgress) {
                    return resolve();
                }

                var body = {
                    payRunId: $scope.payRunId,
                    userId: row.user.id,
                };

                row.actionInProgress = true;

                $http.post(apiBaseUrl + '/organisation/' + organisationId + '/timesheet', body)
                    .then(function (response) {
                        row.timesheet = response.data.timesheet;
                        row.canPush = false;
                        row.validationIssue = undefined;
                        row.actionInProgress = false;
                        resolve();
                    })
                    .catch(function (response) {
                        row.actionInProgress = false;

                        handleError(response, row, function () {
                            retryablePost(row, resolve, reject);
                        }, resolve, reject);
                    });
            }

            $scope.pushTimesheet = function (row) {
                return $q(function (resolve, reject) {
                    retryablePost(row, resolve, reject);
                });
            };

            $scope.pushTimesheets = function () {
                if (isAnyActionInProgress()) {
                    return;
                }

                var rows = $scope.summaryLines.filter(function (r) {
                    return r.isSelected && !r.timesheet && r.canPush;
                });

                if (!rows.length) {
                    return;
                }

                $scope.props.bulkActionInProgress = true;
                var chain = $q.when();

                angular.forEach(rows, function (row) {
                    chain = chain.then(function () {
                        return $scope.pushTimesheet(row);
                    });
                });

                chain.then(function () {
                    AlertService.add('success', 'Your timesheets have been pushed to Xero.', alertServiceId);
                    $scope.props.bulkActionInProgress = false;
                }).catch(function () {
                    AlertService.add('danger', 'We hit a snag just then. Please try again.', alertServiceId);
                    $scope.props.bulkActionInProgress = false;
                });
            };

            function retryableModify(action, row, resolve, reject) {
                if (row.actionInProgress) {
                    return resolve();
                }

                var body = {
                    action: action,
                    timesheetId: row.timesheet.timesheetID,
                };

                row.actionInProgress = true;

                $http.put(apiBaseUrl + '/organisation/' + organisationId + '/timesheet', body)
                    .then(function (response) {
                        row.timesheet = response.data.timesheet;
                        row.canPush = action === 'delete';
                        row.actionInProgress = false;
                        resolve();
                    })
                    .catch(function (response) {
                        row.actionInProgress = false;

                        handleError(response, row, function () {
                            retryableModify(action, row, resolve, reject);
                        }, resolve, reject);
                    });
            }

            $scope.modifyTimesheet = function (action, row) {
                return $q(function (resolve, reject) {
                    retryableModify(action, row, resolve, reject);
                });
            };

            $scope.modifyTimesheets = function (action) {
                if (isAnyActionInProgress()) {
                    return;
                }

                var rows = $scope.summaryLines.filter(function (r) {
                    if (!r.isSelected || !r.timesheet) {
                        return false;
                    }

                    var status = r.timesheet.status.toLocaleLowerCase();

                    if ((action === 'approve' || action === 'delete') && status !== 'draft') {
                        return false;
                    }

                    if (action === 'reverttodraft' && status !== 'approved') {
                        return false;
                    }

                    return true;
                });

                if (!rows.length) {
                    return;
                }

                $scope.props.bulkActionInProgress = true;
                var chain = $q.when();

                angular.forEach(rows, function (row) {
                    chain = chain.then(function () {
                        return $scope.modifyTimesheet(action, row);
                    });
                });

                chain.then(function () {
                    AlertService.add('success', 'Your timesheets have been updated in Xero.', alertServiceId);
                    $scope.props.bulkActionInProgress = false;
                }).catch(function () {
                    AlertService.add('danger', 'We hit a snag just then. Please try again.', alertServiceId);
                    $scope.props.bulkActionInProgress = false;
                });
            };
        }
    ]
);
