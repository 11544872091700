module.exports = (
  $scope,
  $state,
  $stateParams,
  $translate,
  EnvironmentDataService,
  AvailabilityService,
  AlertService,
  StaffCommon,
  PreviousState,
) => {
  'ngInject';

  const { id: rawId } = $stateParams;
  const headerId = parseInt(rawId, 10);
  const isoDateFormat = 'YYYY-MM-DD';

  const translations = $translate.instant([
    'ABSENCE.EDIT.ERROR_500',
    'ABSENCE.EDIT.TAB_EDIT_BOOKING',
    'ABSENCE.EDIT.TAB_REVIEW',
    'ABSENCE.EDIT.TAB_FILES',
    'ABSENCE.EDIT.TAB_CALENDAR',
  ]);

  $scope.props = {
    loadingData: true,
    actionInProgress: false,
    absenceType: undefined,
    headerId,
    absenceTypeReasonList: [],
  };

  $scope.tabs = {
    options: [
      { state: 'dates', label: translations['ABSENCE.EDIT.TAB_EDIT_BOOKING'] },
      { state: 'review', label: translations['ABSENCE.EDIT.TAB_REVIEW'] },
      { state: 'calendar', label: translations['ABSENCE.EDIT.TAB_CALENDAR'] },
    ],
    currentState: $state.current.name.split('.')[1],
  };

  $scope.onTabClick = (state) => {
    $scope.tabs.currentState = state;
    $state.go(`absenceEdit.${state}`);
  };

  $scope.back = () => {
    const {
      name,
      params,
    } = PreviousState;

    if (!name) {
      $state.go('staff.requests.absence');
      return;
    }

    $state.go(name, params);
  };

  $scope.submit = (approvalState) => {
    const {
      selectedDateStart,
      selectedDateEnd,
      formData: {
        startTimeLocal,
        endTimeLocal,
        scope,
        reviewMessage,
        typeReasonId,
      },
    } = $scope.props;

    if (!selectedDateEnd.isAfter(selectedDateStart)) {
      return;
    }

    $scope.props.actionInProgress = true;

    const date = `${selectedDateStart.format(isoDateFormat)}/${selectedDateEnd.format(isoDateFormat)}`;

    AvailabilityService.updateAbsenceRequest(headerId, {
      dateIntervalUtc: date,
      scope,
      startTimeLocal: scope === 3 ? startTimeLocal : undefined,
      endTimeLocal: scope === 3 ? endTimeLocal : undefined,
      days: $scope.props.mappedDays || $scope.absence.days,
      approvalState,
      reviewMessage: approvalState ? reviewMessage : undefined,
      typeReasonId,
    })
      .then(() => {
        $scope.props.requestComplete = true;
        $scope.props.actionInProgress = false;
      })
      .catch(({ status, data }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['ABSENCE.EDIT.ERROR_500']);
          return;
        }

        if (status === 400) {
          StaffCommon.onAbsenceValidationResponse(data);
        }
      });
  };

  function loadBooking() {
    AvailabilityService.getAbsenceRequest(headerId)
      .then(({ data }) => {
        $scope.absence = data;

        const {
          localDateStart,
          localDateEnd,
          start,
          end,
          scope,
          cancelled,
          type: {
            id: typeId,
          },
          typeReasonId,
          userHoursDay,
          userHoursHalfDay,
        } = $scope.absence.header;

        $scope.props.absenceType = $scope.environmentData.absenceTypeList
          .find((type) => type.id === typeId);

        $scope.props.absenceTypeReasonList = $scope.environmentData.absenceTypeReasonMasterList
          .filter((reason) => reason.typeId === typeId);

        if (!$scope.props.absenceType || cancelled) {
          $scope.props.invalidHeader = true;
          $scope.props.loadingData = false;
          return;
        }

        $scope.props.canViewFiles = $scope.props.absenceType.fileUploadOnSubmission !== 'NONE'
          || $scope.props.absenceType.fileUploadOnReview !== 'NONE';
        $scope.props.canUploadFiles = $scope.props.absenceType.fileUploadOnReview !== 'NONE';

        $scope.documentCategory = $scope.environmentData.documentCategoryList
          .find((category) => category.id === $scope.props.absenceType.fileUploadCategoryId);

        $scope.props.selectedDateStart = moment.utc(localDateStart);
        $scope.props.selectedDateEnd = moment.utc(localDateEnd);
        const startTimeLocal = scope === 3 ? moment(start).format('HH:mm:ss') : '09:00:00';
        const endTimeLocal = scope === 3 ? moment(end).format('HH:mm:ss') : '17:00:00';

        $scope.props.formData = {
          dateStart: $scope.props.selectedDateStart.clone(),
          dateEnd: $scope.props.selectedDateEnd.clone().subtract(1, 'day'),
          scope,
          startTimeLocal,
          endTimeLocal,
          typeReasonId,
        };

        $scope.props.hoursPerDay = userHoursDay;
        $scope.props.hoursPerHalfDay = userHoursHalfDay;

        $scope.props.cachedDays = $scope.absence.days.map(({ date, hours }) => ({
          date,
          isDayOff: hours === 0,
          hours,
        }));

        $scope.props.days = $scope.absence.days.map(({ date, hours }) => ({
          date,
          isDayOff: hours === 0,
          hours,
        }));

        $scope.props.approvalStateLabel = StaffCommon
          .getLabelFromApprovalState($scope.absence.header.approvalState);

        $scope.props.alertSuspectCustomHours = scope === 3
          && $scope.absence.days[0].hours > userHoursHalfDay;

        $scope.props.actionInProgress = false;
        $scope.props.loadingData = false;
      })
      .catch(() => {
        $scope.props.invalidHeader = true;
        $scope.props.loadingData = false;
      });
  }

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.StaffGroup,
    EnvironmentDataService.DataType.AbsenceType,
    EnvironmentDataService.DataType.AbsenceTypeReason,
    EnvironmentDataService.DataType.DocumentCategory,
  ])
    .then(([
      entityGroup,
      staffGroup,
      absenceType,
      absenceTypeReason,
      documentCategory,
    ]) => {
      $scope.environmentData = {
        entityGroupList: entityGroup.data
          .flatMap(({
            id: groupId,
            name,
            entities,
            deleted,
          }) => ([
            {
              id: groupId,
              label: name,
              depth: 0,
              deleted,
            },
            ...entities.map((entity) => ({
              id: entity.id,
              label: entity.name,
              parentId: entity.groupId,
              depth: 1,
            })),
          ])),
        staffGroupList: staffGroup.data
          .filter(({ entityId }) => !entityId)
          .map((group) => ({
            ...group,
            label: group.name,
          })),
        absenceTypeList: absenceType.data.map((type) => ({
          ...type,
          label: type.name,
        })),
        absenceTypeReasonMasterList: absenceTypeReason.data.map((reason) => ({
          ...reason,
          label: reason.title,
        })),
        documentCategoryList: documentCategory.data,
      };

      loadBooking();
    });
};
