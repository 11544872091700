const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/restBreaks/directive.html');

var module = require('../module');

module.directive('rrRestBreak', ['$state', 'EnvironmentDataService', 'AlertService', function($state, EnvironmentDataService, AlertService) {
    return {
        templateUrl: autoNgTemplateLoaderTemplate1,
        scope: {
            restBreak: '=',
            deleteRestBreak: '&',
            entity: '=',
            defaultBreak: '='
        },
        controller: function($scope) {
            $scope.metadataLoaded = false;

            function enrichRestBreak() {
                angular.forEach($scope.restBreak.rules, function(rule) {
                    rule.tags = angular.copy($scope.metadata.tags);
                    rule.groups = angular.copy($scope.metadata.staffGroups);
                });
            }

          EnvironmentDataService.fetchAll([
            EnvironmentDataService.DataType.Tag,
            EnvironmentDataService.DataType.StaffGroup,
          ])
            .then(([
              tag,
              staffGroup,
            ]) => {
              $scope.metadata = {};
              $scope.metadata.tags = tag.data.filter((t) => !t.deleted);
              $scope.metadata.staffGroups = staffGroup.data.filter((g) => !g.deleted && !g.entityId);
              $scope.metadataLoaded = true;
              enrichRestBreak();
            });

            function moveRule(from, to) {
                $scope.restBreak.rules.splice(to, 0, $scope.restBreak.rules.splice(from, 1)[0]);
            }

            $scope.moveUp = function(index) {
                if(index != 0) {
                    moveRule(index, index-1);
                }
            };

            $scope.moveDown = function(index) {
                if(index != $scope.restBreak.rules.length-1) {
                    moveRule(index, index+1);
                }
            };

            $scope.addRule = function() {
                $scope.restBreak.rules.push({
                    whoType: 0,
                    whenType: 0,
                    whenValue: moment().startOf('day').add(9, 'hours'),
                    duration: moment().startOf('day'),
                    start: moment().startOf('day').add(11, 'hours'),
                    startIdeal: moment().startOf('day').add(12, 'hours'),
                    end: moment().startOf('day').add(14, 'hours'),
                    tags: angular.copy($scope.metadata.tags),
                    groups: angular.copy($scope.metadata.staffGroups)
                });
            };

            $scope.deleteRule = function(index) {
                $scope.restBreak.rules.splice(index, 1);
            };

            $scope.whoTypeChanged = function (index) {
                var rule = $scope.restBreak.rules[index];

                if (rule.whoType !== 3 && rule.whoType !== 4) {
                    rule.whoValue = [];
                } else {
                    rule.whoValue = 18;
                }
            };
        }
    };
}]);
