import humanizeDuration from 'humanize-duration';

const filters = require('./module');

filters.filter('humaniseDuration', ['SessionService', function (SessionService) {
    return function (duration, options) {
        if (!duration) {
            return '';
        }

        let language = SessionService.getLocaleLanguageCode();

        if (language === 'zh') {
            language = 'zh_CN';
        }

        const opts = options ? {
          ...options,
          language,
        } : { language };

        return humanizeDuration(moment.duration(duration).asMilliseconds(), opts);
    };
}]);
