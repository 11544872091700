const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/entitySelector.html');

const directives = require('./module');

directives.directive('rrEntitySelector', (
  $translate,
  SessionService,
) => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      onSelect: '&',
      selectedEntityId: '=?',
      entityList: '=?',
      readOnly: '=?',
      showOrganisationWide: '=?',
      borderless: '@?',
      dropup: '@?',
    },
    link: ($scope, element) => {
      $scope.displayText = '';

      const entityGroupsById = new Map();
      const entitiesById = new Map();
      const searchInput = angular.element(element.find('input')[0]);
      let highlightPointer = 0;
      let hasMenuOpenedOnce = false;

      function evaluateEnabledState() {
        $scope.isEnabled = !$scope.readOnly && $scope.initialised;
      }

      $scope.$watch('readOnly', () => evaluateEnabledState());

      function updateDisplayText() {
        if ($scope.selectedEntityId === undefined) {
          $scope.displayText = $translate.instant('DIRECTIVES.ENTITY_SELECTOR.SEARCH_PLACEHOLDER');
          return;
        }

        const entity = entitiesById.get($scope.selectedEntityId);

        if (entity) {
          $scope.displayText = entity.name;
          const entityGroup = entityGroupsById.get(entity.groupId);

          if (entityGroup) {
            $scope.displayText += ` (${entityGroup.name})`;
          }
        } else {
          $scope.displayText = $translate.instant('DIRECTIVES.ENTITY_SELECTOR.ORG_WIDE');
        }
      }

      $scope.$watch('selectedEntityId', () => updateDisplayText());

      function setEntityData(data) {
        data.forEach((g) => {
          entityGroupsById.set(g.id, g);
          g.entities.forEach((e) => {
            entitiesById.set(e.id, e);
          });
        });

        $scope.entityGroups = data;
        $scope.initialised = true;
        evaluateEnabledState();
        updateDisplayText();
      }

      $scope.$watch('entityList', (newVal) => {
        if (!newVal || !Array.isArray(newVal)) {
          return;
        }

        setEntityData(newVal);
      });

      $scope.onOptionClick = (entity) => {
        $scope.selectedEntityId = entity ? entity.id : false;
        updateDisplayText();
        $scope.isMenuOpen = false;
      };

      $scope.onElementClick = () => {
        if (!$scope.isEnabled) {
          return;
        }

        searchInput.focus();
      };

      $scope.onFilterTextKeyPress = (event) => {
        if (!$scope.isEnabled) {
          return;
        }

        $scope.isMenuOpen = true;

        if (event.key === 'Escape') {
          event.preventDefault();
          $scope.filterText = '';
          $scope.onFilterTextChange();
          return;
        }

        if (event.key === 'Enter' && $scope.highlightedEntityId) {
          event.preventDefault();
          $scope.onOptionClick(entitiesById.get($scope.highlightedEntityId));
          return;
        }

        if (event.key === 'ArrowDown') {
          event.preventDefault();
          highlightPointer += 1;
          $scope.onFilterTextChange();
          return;
        }

        if (event.key === 'ArrowUp') {
          event.preventDefault();
          highlightPointer += 1;
          $scope.onFilterTextChange();
          return;
        }

        highlightPointer = 0;
      };

      $scope.onFilterTextChange = () => {
        $scope.showNoOptionsWarning = false;
        $scope.highlightedEntityId = false;

        const { filterText } = $scope;

        if (filterText) {
          const matches = [];

          entityGroupsById.forEach(({ name, entities }) => {
            entities.forEach((entity) => {
              if ($scope.filterEntity(name)(entity)) {
                matches.push(entity.id);
              }
            });
          });

          $scope.showNoOptionsWarning = !matches.length;
          const count = matches.length;

          if (count) {
            // Modulo wrap negative index to array size: https://stackoverflow.com/a/43827557/416191
            const elementId = ((highlightPointer % count) + count) % count;
            $scope.highlightedEntityId = matches[elementId];
          }
        }
      };

      function onFinish() {
        const entity = entitiesById.get($scope.selectedEntityId);

        const obj = {
          entity: entity || false,
        };

        searchInput.blur();

        if (entity) {
          SessionService.setEntity(entity);
        }

        if ($scope.onSelect) {
          $scope.onSelect(obj);
        }
      }

      $scope.$watch('isMenuOpen', (val) => {
        if (val === true) {
          hasMenuOpenedOnce = true;
          return;
        }

        if (val === false && hasMenuOpenedOnce) {
          $scope.filterText = '';
          $scope.highlightedEntityId = false;
          $scope.showNoOptionsWarning = false;
          highlightPointer = 0;
          onFinish();
        }
      });

      $scope.filterEntity = (groupName) => (({ name: entityName }) => {
        const { filterText } = $scope;

        if (!filterText) {
          return true;
        }

        // This is a very basic fuzzy search but it does a reasonable job
        const needles = filterText.toLocaleLowerCase().split(' ');
        const haystack = [
          groupName.toLocaleLowerCase().split(' '),
          entityName.toLocaleLowerCase().split(' '),
        ].flat();

        return haystack.some((word) => needles.some((needle) => word.indexOf(needle) !== -1));
      });

      $scope.filterEntityGroups = ({ name, entities }) => entities
        .some((e) => $scope.filterEntity(name)(e));
    },
  };
});
