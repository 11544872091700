const translations = {
  "STAFF": {
    "CONSTANTS": {
      "UK_STARTER_DECLARATION_A": "R: Acesta este primul lor loc de muncă de la începutul anului fiscal (6 aprilie)",
      "UK_STARTER_DECLARATION_B": "B: Acesta este în prezent singurul lor loc de muncă",
      "UK_STARTER_DECLARATION_C": "C: Au un alt loc de muncă sau o pensie",
      "UK_STUDENT_LOAN_PLAN_1": "Planul 1",
      "UK_STUDENT_LOAN_PLAN_2": "Planul 2",
      "UK_STUDENT_LOAN_PLAN_4": "Planul 4",
      "UK_STUDENT_LOAN_PLAN_5": "Planul 5",
      "UK_STUDENT_LOAN_PLAN_POSTGRADUATE": "Postuniversitar",
      "UK_SETTLED_STATUS_NA": "Nu se aplică",
      "UK_SETTLED_STATUS_PRE_SETTLED": "Pre-stabilit",
      "UK_SETTLED_STATUS_SETTLED": "Stabilit",
      "MARITAL_STATUS_SINGLE": "Singur",
      "MARITAL_STATUS_MARRIED": "Căsătorit",
      "MARITAL_STATUS_DIVORCED": "Divortat",
      "MARITAL_STATUS_WIDOWED": "Văduv",
      "MARITAL_STATUS_CIVIL_PARTNERSHIP": "Parteneriatul civil",
      "PAYMENT_METHOD_ELECTRONIC": "Transfer electronic",
      "PAYMENT_METHOD_CHEQUE": "cec",
      "PAYMENT_METHOD_MANUAL": "Manuale",
      "CONTRACTUAL_OBLIGATION_PERIOD_WEEK": "Pe săptămână",
      "CONTRACTUAL_OBLIGATION_PERIOD_MONTH": "Pe lună",
      "CONTRACTUAL_OBLIGATION_UNIT_HOURS": "Ore",
      "CONTRACTUAL_OBLIGATION_UNIT_DAYS": "Zile",
      "PAY_AMOUNT_TYPE_HOURLY": "Rata orară",
      "PAY_AMOUNT_TYPE_DAILY": "Rata zilnică",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salariu anual",
      "PAY_FREQUENCY_WEEKLY": "Săptămânal",
      "PAY_FREQUENCY_FORTNIGHTLY": "La două săptămâni",
      "PAY_FREQUENCY_MONTHLY": "Lunar",
      "PAY_FREQUENCY_FOUR_WEEKLY": "Patru săptămâni"
    },
    "OVERVIEW": {
      "ERROR_500": "Nu am putut încărca date chiar atunci. Vă rugăm să încercați din nou.",
      "TITLE": "Personalul",
      "ADD_STAFF": "Adaugă nou",
      "NO_MATCH": "Nu am putut găsi pe nimeni care să se potrivească filtrelor dvs.",
      "NO_ACTIVE_APPT_TAG": "Inactiv",
      "NOT_APPROVED_TAG": "Neaprobat",
      "DOCUMENTS_INVALID_TAG": "Documente nevalide",
      "NOT_INVITED_TAG": "Nu este invitat",
      "NOT_SETUP_TAG": "Invitat",
      "EMPLOYER_INCOMPLETE_TAG": "Incomplet",
      "SELF_ONBOARDING": "Auto-onboarding",
      "ACCOUNT_TAB_LABEL": "Cont",
      "PERSONAL_TAB_LABEL": "Personale",
      "EMPLOYMENT_TAB_LABEL": "Angajare",
      "PAY_TAB_LABEL": "Plătiți",
      "SCHEDULE_TAB_LABEL": "Program",
      "ABSENCE_TAB_LABEL": "Absența",
      "AVAILABILITY_TAB_LABEL": "Disponibilitate",
      "SHIFT_ROUTINE_TAB_LABEL": "Rutine de schimbare",
      "DOCUMENTS_TAB_LABEL": "Documente",
      "ATTENDANCE_TAB_LABEL": "Participare",
      "NOTES_TAB_LABEL": "notițe",
      "FILTER_LABEL_SITE": "Site/departament",
      "FILTER_LABEL_POSITION": "Rolul postului",
      "FILTER_LABEL_STAFF_GROUP": "Grupul de personal",
      "FILTER_LABEL_EMPLOYMENT": "Statutul ocupării forței de muncă",
      "FILTER_LABEL_APPROVAL_STATUS": "Statutul aprobării",
      "FILTER_LABEL_EMPLOYEE_SETUP": "Invitație Rotaready",
      "FILTER_LABEL_EMPLOYER_SETUP": "Finalizarea contului",
      "FILTER_LABEL_EMPLOYMENT_START": "Data înscrierii",
      "FILTER_LABEL_EMPLOYMENT_END": "Data plecării",
      "FILTER_LABEL_EMPLOYMENT_START_OR_END": "Data alăturării sau părăsirii",
      "BUTTON_COLUMNS": "Coloane",
      "BUTTON_EXPORT": "Export",
      "EMPLOYMENT_STATUS_ACTIVE": "Activ",
      "EMPLOYMENT_STATUS_INACTIVE": "Inactiv",
      "APPROVAL_STATUS_APPROVED": "Aprobat",
      "APPROVAL_STATUS_NOT_APPROVED": "Neaprobat",
      "EMPLOYEE_SETUP_COMPLETE": "Acceptat",
      "EMPLOYEE_SETUP_NOT_COMPLETE": "În așteptare sau nu a fost trimis",
      "EMPLOYER_SETUP_COMPLETE": "Complet",
      "EMPLOYER_SETUP_NOT_COMPLETE": "Incomplet",
      "COLUMN_NAME": "Nume",
      "COLUMN_NEXT_SHIFT": "Următoarea schimbare",
      "COLUMN_ID": "ID-UL",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_BIRTH_DATE": "Data nașterii",
      "COLUMN_EMAIL": "E-mailul",
      "COLUMN_MOBILE": "Telefonul",
      "COLUMN_OTHER_NAMES": "Alte nume",
      "COLUMN_ADDRESS_LINE_1": "Linia de adresă 1",
      "COLUMN_ADDRESS_LINE_2": "Linia de adresă 2",
      "COLUMN_ADDRESS_CITY": "Oraş/oraş",
      "COLUMN_ADDRESS_COUNTY": "Judet/Regiune",
      "COLUMN_ADDRESS_POST_CODE": "Cod poștal",
      "COLUMN_ADDRESS_COUNTRY": "Țara",
      "COLUMN_PREFERRED_NAME": "Numele preferat",
      "COLUMN_GENDER": "Sexul",
      "COLUMN_TITLE": "Titlu",
      "COLUMN_ETHNICITY": "etnie",
      "COLUMN_NATIONALITY": "Naționalitate",
      "COLUMN_MARITAL_STATUS": "Starea civilă",
      "COLUMN_SETTLED_STATUS": "Statut stabilit (Marea Britanie)",
      "COLUMN_PASSPORT_NUMBER": "Numărul pașaportului",
      "COLUMN_PASSPORT_EXPIRY_DATE": "Expirarea pașaportului",
      "COLUMN_WORK_EMAIL": "E-mail secundar",
      "COLUMN_WORK_PHONE": "Telefon secundar",
      "COLUMN_BANK_ACCOUNT_NAME": "Numele contului bancar",
      "COLUMN_BANK_ACCOUNT_NUMBER": "Numărul contului bancar",
      "COLUMN_BANK_ACCOUNT_SORT_CODE": "Codul de sortare al băncii",
      "COLUMN_PAYROLL_PAYMENT_METHOD": "Metoda de plată",
      "COLUMN_NATIONAL_INSURANCE_NUMBER": "Numărul asigurării naționale (Marea Britanie)",
      "COLUMN_STUDENT_LOANS": "Împrumuturi pentru studenți (Marea Britanie)",
      "COLUMN_AVERAGE_HOURS_DAY": "Ore medie/zi",
      "COLUMN_AVERAGE_HOURS_HALF_DAY": "Ore medie/jumătate de zi",
      "COLUMN_AVERAGE_HOURS_WEEK": "Ore medie/săptămână",
      "COLUMN_PRE_AVERAGE_HOURS_PER_DAY": "Pre-Rotaready ore/zi medii",
      "COLUMN_PRE_AVERAGE_HOURS_DATE": "Data medie a orelor înainte de Rotaready",
      "COLUMN_PERMISSION_LEVEL_NAME": "Nivelul permisiunii",
      "COLUMN_ANONYMISED": "Anonimizat",
      "COLUMN_ANONYMISE_DATE": "Data anonimizării",
      "COLUMN_JOIN_DATE": "Data înscrierii",
      "COLUMN_LEAVE_DATE": "Data plecării",
      "COLUMN_LEAVE_DATE_EDITED_BY": "Lăsați data setată de",
      "COLUMN_LEAVE_DATE_EDITED_DATE": "Lăsați data de setare a datei",
      "COLUMN_LENGTH_OF_SERVICE": "Durata serviciului (zile)",
      "COLUMN_RECRUITMENT_SOURCE": "Sursa de recrutare",
      "COLUMN_LEAVER_REASON": "Motivul părăsirii",
      "COLUMN_LEAVER_REHIRE": "Recrutare eligibilă",
      "COLUMN_EMPLOYEE_NUMBER": "Numărul angajatului",
      "COLUMN_NOTICE_PERIOD": "Perioada de preaviz",
      "COLUMN_STARTER_DECLARATION": "Declarație inițială (Marea Britanie)",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED": "Indemnizații finalizate",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY": "Indemnizații finalizate de",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE": "Data finalizării certificatelor",
      "COLUMN_SITE_NAME": "Site-ul",
      "COLUMN_SITE_ID": "ID-ul site-ului",
      "COLUMN_DEPARTMENT_NAME": "Divizie",
      "COLUMN_DEPARTMENT_ID": "ID-ul departamentului",
      "COLUMN_JOB_ROLE_NAME": "Rolul postului",
      "COLUMN_COMPANY_NAME": "Companie",
      "COLUMN_CONTRACT_BASIS": "Tipul contractului",
      "COLUMN_LINE_MANAGER_NAME": "Director de linie",
      "COLUMN_STAFF_GROUP_NAME": "Grupul de personal",
      "COLUMN_WORKS_PUBLIC_HOLIDAYS": "Sărbători legale de lucru",
      "COLUMN_WORKING_TIME_OPT_OUT": "Renunțarea la reglementările privind timpul de lucru",
      "COLUMN_MIN_OBLIGATION_VALUE": "Valoarea obligației contractuale",
      "COLUMN_MIN_OBLIGATION_UNIT": "Unitatea de obligații contractuale",
      "COLUMN_MIN_OBLIGATION_PERIOD": "Perioada de obligație contractuală",
      "COLUMN_MAX_OBLIGATION_VALUE": "Valoarea limită de lucru",
      "COLUMN_MAX_OBLIGATION_UNIT": "Unitate limită de lucru",
      "COLUMN_MAX_OBLIGATION_PERIOD": "Perioada limită de lucru",
      "COLUMN_DEPARTMENT_CURRENCY_CODE": "Codul monedei",
      "COLUMN_PAYROLL_CALENDAR_NAME": "Calendarul de salarizare",
      "COLUMN_PAYROLL_CALENDAR_FREQUENCY": "Frecvența calendarului salarizării",
      "COLUMN_PAY_AMOUNT": "Suma de plată",
      "COLUMN_PAY_AMOUNT_TYPE": "Tipul sumei de plată",
      "COLUMN_PAY_UPLIFT": "Creșterea salariilor",
      "ASSET_REGISTRY_TAB_LABEL": "Registrul activelor"
    },
    "VIEW": {
      "ACCOUNT": {
        "ERROR_500": "Nu putem procesa schimbarea ta chiar acum. Vă rugăm să încercați din nou.",
        "ERROR_403": "Nu aveți permisiunea de a vizualiza/modifica informațiile contului acestei persoane.",
        "SAVE_SUCCESS": "Modificările dvs. au fost salvate.",
        "TITLE": "Cont",
        "LAST_SIGN_IN_LABEL": "Ultima conectare",
        "LAST_SIGN_IN_NEVER": "Niciodată",
        "SECTION_PERMISSIONS_TITLE": "Permisiuni",
        "PERMISSION_LEVEL_LABEL": "Nivelul permisiunii",
        "PERMISSION_LEVEL_HELP": "Acest lucru va modifica ceea ce angajatul poate vedea și face în Rotaready.",
        "SITE_ACCESS_LABEL": "Accesul la site",
        "SITE_ACCESS_OPTION_APPOINTMENT": "Site-ul lor actual",
        "SITE_ACCESS_OPTION_ALL": "Toate site-urile",
        "SITE_ACCESS_OPTION_CUSTOM": "Site-ul lor actual și site-urile personalizate",
        "CLOCK_IN_PIN_LABEL": "Resetați codul PIN de intrare al ceasului",
        "CLOCK_IN_PIN_HELP": "Introduceți un nou cod de 4 cifre pentru a schimba codul PIN de ceasornic al acestui angajat.",
        "TAGS_LABEL": "Etichete",
        "TAGS_TIP": "Etichetați angajații cu orice lucru semnificativ, cum ar fi abilități sau atribute.",
        "TAGS_PLACEHOLDER": "Tastați pentru a găsi etichete",
        "SAVE_BUTTON_LOADING": "Salvarea...",
        "SAVE_BUTTON_TEXT": "Salvați",
        "LANGUAGE_LABEL": "Limba",
        "LANGUAGE_HELP": "Rotaready va fi afișat în limba aleasă.",
        "ENTITY_GROUP_SUBSCRIPTION_TOOLTIP":"Acest angajat este atribuit automat acestui site. Puteți gestiona accesul lor în pagina de setări a Managerului de acces la site."
      },
      "PAY": {
        "INDEX": {
          "TAB_HISTORY": "Înregistrări de plată",
          "TAB_TRONC": "Tronc",
          "TAB_FINANCIALS": "Financiare"
        },
        "VIEW_HISTORY": {
          "FILTER_TYPES_LABEL": "Tip",
          "FILTER_TYPES_SALARY": "Salariu",
          "FILTER_TYPES_POINT_IN_TIME": "Plăți unice",
          "FILTER_ELEMENT_TYPES_LABEL": "Element de plată",
          "STATE_ACTIVE": "Activ",
          "STATE_HISTORICAL": "Istoric",
          "STATE_FUTURE_DATED": "Datat în viitor",
          "COLUMN_EFFECTIVE_DATE": "Data intrării în vigoare",
          "COLUMN_STATUS": "Statut",
          "COLUMN_ELEMENT_TYPE": "Element de plată",
          "COLUMN_CURRENCY": "Moneda",
          "COLUMN_AMOUNT": "Suma",
          "COLUMN_AMOUNT_TYPE": "Tipul sumei",
          "COLUMN_OPTIONS": "Opțiuni",
          "ERROR_500": "Nu putem încărca înregistrările salariale chiar acum. Vă rugăm să încercați din nou.",
          "ERROR_403": "Nu aveți permisiunea de a vizualiza/modifica înregistrările salariale ale acestei persoane.",
          "ALERT_SUCCESS": "Dosarul de plată a fost salvat.",
          "ALERT_DELETED": "Înregistrarea salariilor a fost ștearsă.",
          "OUTSIDE_OF_APPOINTMENT_WARNING": "Această înregistrare de plată este invalidă, deoarece nu sunt angajați activ la această dată. Vă rugăm să modificați data intrării în vigoare.",
          "DELETE_LINK": "Șterge",
          "LAST_EDITED_TOOLTIP": "Ultima modificare de {{userName}} la {{date}}",
          "NO_RECORDS": "Nu există înregistrări de plată de afișat.",
          "ADD_NEW_BUTTON": "Noul record de salarizare",
          "CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți această înregistrare de plată? Acest lucru nu poate fi anulat."
        },
        "PAY_FORM": {
          "TITLE": "Plătiți",
          "LABEL_EFFECTIVE_DATE": "Data intrării în vigoare",
          "LABEL_ELEMENT": "Element de plată",
          "LABEL_AMOUNT": "Suma",
          "LABEL_AMOUNT_TYPE": "Tipul sumei",
          "LABEL_UPLIFT": "Creșterea salariilor",
          "ALERT_NO_CURRENCY": "Angajatul nu este angajat activ la această dată.",
          "ALERT_DUPLICATE_PAY": "Există deja o înregistrare de plată pentru elementul de plată ales la acea dată.",
          "HELP_TEXT_UPLIFT": "Acest lucru va umfla salariile angajatului în controlul costurilor. Utilizați-l pentru a reflecta orice costuri suplimentare pe care le suportați, cum ar fi o pensie. Nu vă faceți griji cu privire la impozite, deoarece acestea sunt incluse automat.",
          "BUTTON_SAVE": "Salvați",
          "BUTTON_SAVE_LOADING": "Salvarea...",
          "BUTTON_DELETE": "Șterge",
          "BUTTON_DELETE_LOADING": "Ștergerea..."
        },
        "VIEW_FINANCIALS": {
          "ERROR_500": "Nu putem încărca informațiile financiare chiar acum. Vă rugăm să încercați din nou.",
          "ERROR_403": "Nu aveți permisiunea de a vizualiza/modifica informațiile financiare ale acestei persoane.",
          "SAVE_SUCCESS": "Modificările dvs. au fost salvate.",
          "SECTION_BANK_ACCOUNT": "Cont bancar",
          "SECTION_TAXES": "Impozite",
          "ACCOUNT_NUMBER_LABEL": "Numărul contului",
          "SORT_CODE_LABEL": "Cod de sortare",
          "ACCOUNT_NAME_LABEL": "Numele contului",
          "PAYMENT_METHOD": "Metoda de plată",
          "NI_NUMBER_LABEL": "Numărul asigurării naționale",
          "SECTION_STUDENT_LOANS": "Împrumuturi pentru studenți (Marea Britanie)",
          "PLAN_TYPE_LABEL": "Tipul planului",
          "EFFECTIVE_DATE_LABEL": "Data intrării în vigoare",
          "LOAN_TABLE_PLAN_COLUMN": "Tipul planului",
          "LOAN_TABLE_DATE_COLUMN": "Data intrării în vigoare",
          "LOAN_TABLE_OPTIONS_COLUMN": "Opțiuni",
          "LOAN_TABLE_NO_ROWS": "Nu s-au adăugat împrumuturi studențești.",
          "LOAN_TABLE_DATE_NOT_SET": "Neconfigurat",
          "SUBHEADING_ADD_STUDENT_LOAN": "Adăugați împrumut studențesc",
          "OPTION_DELETE": "Șterge",
          "ADD_LOAN_BUTTON": "Adăugați împrumut studențesc",
          "SAVE_BUTTON_TEXT": "Salvați"
        },
        "EDIT_STUDENT_LOAN": {
          "HEADING": "Editare împrumut pentru studenți",
          "FORM_PLAN_TYPE_LABEL": "Tipul planului",
          "FORM_EFFECTIVE_DATE_LABEL": "Data intrării în vigoare",
          "SAVE_BUTTON_TEXT": "Salvați"
        },
        "VIEW_TRONC": {
          "COLUMN_EFFECTIVE_DATE": "Data intrării în vigoare",
          "COLUMN_STATUS": "Statut",
          "COLUMN_AMOUNT": "Suma",
          "COLUMN_APPORTION_TYPE": "Tipul de repartizare",
          "COLUMN_LAST_EDITED": "Editat ultima dată",
          "STATE_ACTIVE": "Activ",
          "STATE_HISTORICAL": "Istoric",
          "STATE_FUTURE_DATED": "Datat în viitor",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Rata orară",
          "APPORTION_TYPE_CLASSIC_SALARY": "Salariu anual",
          "APPORTION_TYPE_POINTS": "Puncte",
          "NO_RECORDS": "Nu există tarife Tronc de afișat.",
          "ADD_NEW_BUTTON": "Curs Tronc nou",
          "ERROR_500": "Nu putem încărca tarifele Tronc chiar acum. Vă rugăm să încercați din nou.",
          "ERROR_403": "Nu aveți permisiunea de a vizualiza tarifele Tronc ale acestei persoane."
        },
        "ADD_TRONC_MODAL": {
          "TITLE": "Curs Tronc nou",
          "BUTTON_SAVE": "Salvați",
          "BUTTON_CANCEL": "Anulează",
          "LABEL_PERSON": "Persoană",
          "LABEL_DATE": "Data intrării în vigoare",
          "LABEL_APPORTION_TYPE": "Tipul de repartizare",
          "LABEL_AMOUNT": "Suma",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Rata orară",
          "APPORTION_TYPE_CLASSIC_SALARY": "Salariu anual",
          "APPORTION_TYPE_POINTS": "Puncte",
          "APPORTION_TYPE_HELP": "Tronc poate lucra într-unul din cele două moduri: bazat pe puncte sau clasic, care folosește o rată orară sau un salariu. Acest lucru este controlat de o setare pentru organizația dvs. sau locația/locul specific.",
          "ERROR_OTHER": "Nu putem salva rata Tronc chiar acum. Vă rugăm să încercați din nou.",
          "ERROR_UNAUTHORIZED": "Nu aveți permisiunea de a modifica tarifele Tronc ale acestei persoane.",
          "ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE": "Acest angajat nu are o programare activă la data efectivă aleasă de dvs.",
          "ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE": "Cineva a început deja să elaboreze Tronc pentru data de intrare în vigoare aleasă. Vă rugăm să utilizați instrumentul Tronc pentru a edita tarifele pentru această dată.",
          "ERROR_NOT_SUPPORTED": "Nu puteți alege acel tip de repartizare. Tronc nu este configurat să funcționeze în acel mod."
        }
      },
      "INDEX": {
        "OPTIONS_LABEL": "Opțiuni",
        "OPTION_APPROVE_LINK": "Aprobare pentru rotas și salarizare",
        "OPTION_SEND_MAGIC_LINK": "Invitați la Rotaready (trimiteți un link magic)",
        "OPTION_SEND_MAGIC_LINK_TOOLTIP": "Această persoană și-a configurat deja contul. Dacă au probleme la conectare, cereți-i să urmeze linkul de recuperare a contului de pe pagina de conectare.",
        "OPTION_ANONYMISE": "Anonimizare",
        "OPTION_DELETE": "Șterge",
        "ALERT_APPROVE_SUCCESS": "Acum sunt aprobați și gata să primească schimburi.",
        "ALERT_APPROVE_ERROR": "Nu am putut aproba contul chiar atunci. Vă rugăm să încercați din nou.",
        "ALERT_MAGIC_LINK_SUCCESS_ALL": "Am trimis un e-mail și un mesaj text cu un link magic. Vă rugăm să acordați câteva minute pentru ca acesta să ajungă.",
        "ALERT_MAGIC_LINK_SUCCESS_EMAIL": "Am trimis un e-mail cu un link magic. Vă rugăm să acordați câteva minute pentru ca acesta să ajungă.",
        "ALERT_MAGIC_LINK_SUCCESS_SMS": "Am trimis un mesaj text cu un link magic. Vă rugăm să acordați câteva minute pentru ca acesta să ajungă.",
        "ALERT_MAGIC_LINK_ERROR_GENERIC": "Nu am putut trimite un link magic chiar atunci.",
        "ALERT_MAGIC_LINK_ERROR_NO_DETAILS": "Nu putem trimite un link magic fără o adresă de e-mail sau un număr de telefon.",
        "ALERT_ACCOUNT_ERROR_500": "Nu am putut încărca angajatul chiar atunci. Vă rugăm să încercați din nou.",
        "ALERT_ACCOUNT_ERROR_404": "Nu există niciun cont de angajat asociat cu acel ID.",
        "DELETE_ACCOUNT_TITLE": "Ștergeți angajatul",
        "DELETE_ACCOUNT_MESSAGE": "Atenție! Ștergerea unui angajat este permanentă. Faceți acest lucru numai dacă ați creat angajatul din greșeală. Pentru a continua, tastați numele complet mai jos.",
        "DELETE_ACCOUNT_BUTTON": "Ștergeți definitiv",
        "DELETE_ACCOUNT_ERROR": "Angajatul nu a putut fi șters. Este posibil să nu aveți permisiunea de a face acest lucru.",
        "DELETE_ACCOUNT_SUCCESS": "Angajatul a fost șters."
      },
      "PERSONAL": {
        "ERROR_500": "Nu putem procesa schimbarea ta chiar acum. Vă rugăm să încercați din nou.",
        "ERROR_403": "Nu aveți permisiunea de a vizualiza/modifica informațiile personale ale acestei persoane.",
        "SAVE_SUCCESS": "Modificările dvs. au fost salvate.",
        "EMERGENCY_CONTACTS_LINK_TEXT": "Contacte de urgență",
        "FINANCIALS_LINK_TEXT": "Financiare/impozite",
        "LABEL_OPTIONS": "Mai multe opțiuni",
        "LABEL_APPROVED_BY": "Aprobat de",
        "TITLE_LABEL": "Titlu",
        "FIRST_NAME_LABEL": "Prenume",
        "LAST_NAME_LABEL": "Nume de familie",
        "OTHER_NAME_LABEL": "Alte denumiri (opțional)",
        "DOB_LABEL": "Data nașterii",
        "DOB_HELP": "{{vârstă}} ani",
        "PREFERRED_NAME_LABEL": "Numele preferat",
        "SECTION_ADDRESS": "Adresă",
        "SECTION_VISAS": "Vize",
        "SECTION_IDENTITY": "Identitate",
        "SECTION_CONTACT": "Contact",
        "SECTION_OTHER": "Altele",
        "SECTION_RIGHT_TO_WORK": "Dreptul de a munci",
        "NATIONALITY_LABEL": "Naționalitate",
        "GENDER_LABEL": "Sexul",
        "ETHNICITY_LABEL": "etnie",
        "MARITAL_STATUS_LABEL": "Starea civilă",
        "PASSPORT_NUMBER_LABEL": "Numărul pașaportului",
        "PASSPORT_EXPIRY_LABEL": "Data expirării pașaportului",
        "SETTLED_STATUS_LABEL": "Statut stabilit (Marea Britanie)",
        "PRIMARY_EMAIL_LABEL": "E-mail primar",
        "PRIMARY_EMAIL_HELP": "Angajatul folosește acest lucru pentru a se conecta la Rotaready. Poate fi modificat numai dacă nu s-au conectat înainte.",
        "PRIMARY_PHONE_LABEL": "Telefon primar",
        "PRIMARY_PHONE_HELP": "Angajatul folosește acest lucru pentru a primi notificări. Prefixul corect al codului de țară va fi aplicat automat după salvare.",
        "SECONDARY_EMAIL_LABEL": "E-mail secundar",
        "SECONDARY_PHONE_LABEL": "Telefon secundar",
        "HR_ID_LABEL": "ID-UL HR",
        "HR_ID_HELP": "Dacă acest angajat are un număr de identificare utilizat în scopuri de resurse umane, îl puteți stoca aici.",
        "PAYROLL_ID_LABEL": "ID de salarizare",
        "PAYROLL_ID_HELP": "Dacă acest angajat are un număr de identificare utilizat în scopuri de salarizare, îl puteți stoca aici.",
        "SAVE_BUTTON_LOADING": "Salvarea...",
        "SAVE_BUTTON_TEXT": "Salvați",
        "LINK_ADD_ADDRESS": "Adaugă o adresă nouă",
        "LINK_VIEW_ADDRESS_HISTORY": "Vezi adresele anterioare",
        "NO_ADDRESS_HISTORY_TEXT": "Nu există adrese anterioare",
        "CURRENT_ADDRESS_LABEL": "Curent",
        "SHARE_CODE_LABEL": "Cod de partajare",
        "SHARE_CODE_ERROR_LENGTH": "Codul de partajare trebuie să aibă 9 caractere.",
        "SHARE_CODE_ERROR_FORMAT": "Codul de partajare trebuie să conțină doar caractere alfanumerice.",
        "SHARE_CODE_EXPIRY_DATE_LABEL": "Data de expirare a codului de partajare",
      },
      "ADDRESS_HISTORY": {
        "ALERT_ERROR_500": "Nu putem procesa schimbarea ta chiar acum. Vă rugăm să încercați din nou.",
        "TITLE_ADD": "Adaugă o adresă nouă",
        "TITLE_EDIT": "Editați adresa",
        "ADVICE_EDIT": "Dacă adresa angajatului se schimbă, ar trebui să adăugați o nouă adresă în schimb. Rotaready va muta automat adresa curentă în istoricul adreselor lor.",
        "ADVICE_EDIT_LINK": "Adaugă o adresă nouă",
        "SAVE_BUTTON_TEXT": "Salvați",
        "DELETE_BUTTON_TEXT": "Șterge",
        "ADDRESS_LINE_1_LABEL": "Linia 1",
        "ADDRESS_LINE_2_LABEL": "Linia 2 (opțional)",
        "ADDRESS_CITY_LABEL": "Oraş/oraş",
        "ADDRESS_COUNTY_LABEL": "Județ",
        "ADDRESS_POST_CODE_LABEL": "Cod poștal",
        "ADDRESS_COUNTRY_LABEL": "Țara",
        "NOTES_LABEL": "notițe",
        "CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți această adresă?"
      },
      "VISAS": {
        "ADD_BTN": "Adăugați viză",
        "CURRENT_HEADING": "Viza curentă",
        "NO_CURRENT_VISA": "Acest utilizator nu are o viză curentă.",
        "CURRENT_LABEL": "Curent",
        "VISA_TYPE_LABEL": "Tipul vizei",
        "VISA_NUMBER_LABEL": "Numărul vizei",
        "VISA_EXPIRY_LABEL": "Data de expirare a vizei",
        "VISA_EXPIRY_NOT_APPLICABLE": "Nu se aplică",
        "VISA_EDIT_BTN": "Editează viza",
        "PREVIOUS_LABEL": "Anterior",
        "SHOW_PREVIOUS_BTN": "Arată vizele anterioare",
        "HIDE_PREVIOUS_BTN": "Ascunde vizele anterioare",
        "PREVIOUS_HEADING": "Viza anterioară",
        "MODAL": {
          "ADD_HEADING": "Adăugați un nou Viză",
          "ALERT_ERROR_500": "Nu putem procesa modificarea dvs. în acest moment. Vă rugăm să încercați din nou.",
          "FORM_LABEL_VISA_TYPE": "Tipul de viză",
          "FORM_LABEL_VISA_NUMBER": "Numărul vizei",
          "FORM_LABEL_IS_CURRENT": "Aceasta este viza curentă a utilizatorului",
          "FORM_LABEL_HAS_EXPIRY_DATE": "Această viză are o dată de expirare",
          "FORM_LABEL_VISA_EXPIRY": "Data de expirare a vizei",
          "FORM_BTN_SAVE": "Salvați",
          "EDIT_VISA_HEADING": "Editare viză",
          "DELETE_VISA_BTN": "Șterge viza",
          "CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți această viză?"
        }
      },
      "SCHEDULE": {
        "TITLE": "Calendarul de mai jos arată programul complet al {{firstName}}, inclusiv schimburile, absența și disponibilitatea.",
        "SHIFTS_LABEL": "Schimburi",
        "ABSENCE_LABEL": "Absența",
        "UNAVAILABLE_LABEL": "Indisponibil (modele)",
        "EDIT_LINK_TEXT": "Editați excepțiile"
      },
      "ADD_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Rutina de schimbare a hărții",
        "HAS_OVERLAP_TEXT": "Datele de mai sus se suprapun cu o rutină de schimb existentă.",
        "END_BEFORE_START_TEXT": "Data de începere este înainte de data de încheiere.",
        "SAVE_BUTTON_TEXT": "Salvați",
        "SAVE_BUTTON_LOADING": "Salvarea...",
        "CANCEL_BUTTON_TEXT": "Anulează"
      },
      "DELETE_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Ești sigur?",
        "WARNING": "Ștergerea acestei mapări va elimina toate schimbările viitoare asociate acesteia.",
        "YES_BUTTON_TEXT": "da",
        "YES_BUTTON_ADDITIONAL_TEXT": "Ștergeți această mapare",
        "NO_BUTTON_TEXT": "Nu",
        "NO_BUTTON_ADDITIONAL_TEXT": "M-am răzgândit",
        "CANCEL_BUTTON_TEXT": "Anulează"
      },
      "EDIT_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Editați maparea rutinei de schimbare",
        "END_DATE_LABEL": "Data de încheiere",
        "CONTINUE_FOREVER_LABEL": "Continuați rutina de schimbare pentru totdeauna",
        "END_BEFORE_START_TEXT": "Data de încheiere este înainte de data de începere.",
        "HAS_OVERLAP_NO_END": "Setarea acestei date de încheiere ar cauza o suprapunere cu o rutină de schimb existentă.",
        "HAS_OVERLAP_END": "Stabilirea unei date de încheiere ar cauza o suprapunere cu o rutină de schimb existentă.",
        "SAVE_BUTTON_TEXT": "Salvați",
        "SAVE_BUTTON_LOADING_TEXT": "Salvarea...",
        "CANCEL_BUTTON_TEXT": "Anulează"
      },
      "SHIFT_ROUTINES": {
        "MAP_ROUTINE_BUTTON_TEXT": "Harta către rutină",
        "MAP_ROUTINE_SUBTITLE": "Schimbați mapările de rutină",
        "MAP_ROUTINE_TIP": "Cartografierea cuiva la o rutină de ture determină crearea automată a schimburilor. Acestea vor fi șterse dacă ștergeți maparea sau dacă adăugați o dată de încheiere.",
        "EDIT_END_DATE_TEXT": "Editați data de încheiere",
        "ADD_END_DATE_TEXT": "Adăugați data de încheiere",
        "MAPPING_NO_END": "Începând cu ",
        "MAPPING_END": " la ",
        "USER_HAS_NO_MAPPING": "{{firstName}} nu este mapat la nicio rutină de schimb. Puteți adăuga unul folosind butonul de mai sus."
      },
      "ABSENCE": {
        "INDEX": {
          "TAB_ABSENCE": "Rezervări de absență",
          "TAB_ALLOWANCES": "Indemnizații",
          "TAB_SETTINGS": "Setări"
        },
        "VIEW_SETTINGS": {
          "ABSENCE_HOURS_LABEL": "Ore medii pe zi",
          "AVERAGE_LENGTH_LABEL": "Calculați automat pe baza duratei medii a zilei lor de lucru",
          "SET_MANUALLY_LABEL": "Setați manual",
          "HOURS_FULL_DAY_LABEL": "Zi intreaga",
          "HOURS_LABEL": "ore",
          "HOURS_HELP": "O zi întreagă de absență va merita atât de multe ore.",
          "HOURS_HALF_DAY_LABEL": "Jumătate de zi",
          "HOURS_HALF_DAY_HELP": "O jumătate de zi de absență va merita atâtea ore.",
          "DELEGATE_APPROVERS": "Aprobatori delegați",
          "DELEGATE_APPROVERS_REMOVE": "Elimină",
          "DELEGATE_APPROVERS_HELP": "Dacă acest angajat activează opțiunea Nu deranjați în aplicația Rotaready și este formulată o cerere de absență care necesită aprobarea lor, aprobatorii delegați vor fi anunțați în schimb.",
          "SAVE_BUTTON_TEXT": "Salvați",
          "SAVE_BUTTON_LOADING_TEXT": "Salvarea...",
          "PRE_ROTAREADY_AVERAGE_HOURS_LABEL": "Pre-Rotaready ore medii pe zi",
          "PRE_ROTAREADY_AVERAGE_HOURS_HELP": "Dacă ați trecut la Rotaready dintr-un alt sistem în timpul angajării acestei persoane, introduceți ultimele sale „ore medii pe zi” cunoscute și data la care a fost valabilă.",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_LABEL": "Ultima valabilitate pe",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_HELP": "La calcularea unei noi valori pentru orele medii pe zi, dacă angajatul nu a lucrat în ultimele 52 de săptămâni pe Rotaready, calculul nostru va folosi valoarea pre-RotaReady pentru săptămânile anterioare acestei date."
        },
        "VIEW_BOOKINGS": {
          "INCLUDE_CANCELLED_TOGGLE_LABEL": "Includeți anulat",
          "NO_BOOKINGS_MATCH_TEXT": "Nu am putut găsi nicio rezervare care să corespundă filtrelor dvs.",
          "BOOK_ABSENCE_BUTTON_TEXT": "Absența cărții",
          "CANCEL_LINK": "Anulează",
          "DATES_COLUMN_TEXT": "Data (date)",
          "ABSENCE_TYPE_COLUMN_TEXT": "Tip",
          "HOURS_COLUMN_TEXT": "Ore",
          "DAYS_COLUMN_TEXT": "Zile",
          "STATUS_COLUMN_TEXT": "Statut",
          "SUBMITTED_COLUMN_TEXT": "Trimis",
          "APPROVED_COLUMN_TEXT": "Revizuit",
          "CANCELLED_COLUMN_TEXT": "Anulat",
          "FILTER_LABEL_APPROVAL_STATE": "Statut",
          "FILTER_LABEL_ABSENCE_TYPE": "Tip",
          "FILTER_LABEL_DATE": "Data",
          "CANCEL_REQUEST": {
            "CONFIRM": "Sunteți sigur că doriți să anulați această rezervare de absență? Acest lucru nu poate fi anulat.",
            "SUCCESS": "Rezervarea absenței a fost anulată.",
            "ERROR_500": "Nu am putut anula asta chiar acum. Vă rugăm să încercați din nou."
          }
        },
        "VIEW_ALLOWANCES": {
          "ERROR_500": "Hopa, am lovit un obstacol chiar atunci. Vă rugăm să încercați din nou.",
          "BUTTON_ADD": "Indemnizație nouă",
          "BUTTON_OPTIONS": "Opțiuni",
          "BUTTON_TRANSACTIONS": "Vizualizați tranzacțiile",
          "BUTTON_EDIT": "Editați",
          "BUTTON_DELETE": "Șterge",
          "NO_ALLOWANCES_TEXT": "Nu există cote care să corespundă filtrelor dvs.",
          "FILTER_LABEL_STATUS": "Statut",
          "FILTER_LABEL_ABSENCE_TYPE": "Tip",
          "CHRONO_TYPE_ACTIVE": "Activ",
          "CHRONO_TYPE_FUTURE": "Viitor",
          "CHRONO_TYPE_HISTORICAL": "Istoric",
          "LABEL_NOT_DEDUCTIBLE": "Nu este deductibil",
          "METRIC_ACCRUED": "Dreptul",
          "METRIC_BOOKED": "Rezervat",
          "METRIC_REMAINING": "Rămas",
          "METRIC_ACCRUED_LIMIT": "din {{count}}",
          "METRIC_ACCRUED_LIMIT_HELP": "Acesta este maximul care poate fi acumulat.",
          "METRIC_ELAPSED": "scurs",
          "METRIC_ELAPSED_HELP": "Acesta este numărul de zile/ore rezervate care sunt în trecut. Aceasta se recalculează automat peste noapte.",
          "METRIC_OVERSPEND": "cheltuiți excesiv",
          "METRIC_OVERSPEND_HELP": "Acesta este numărul de zile/ore care pot fi rezervate peste suma acumulată.",
          "METRIC_ACCRUAL_ESTIMATE": "a acumula",
          "METRIC_ACCRUAL_ESTIMATE_HELP": "Pe baza programului dvs. mediu de lucru, estimăm că veți acumula acest lucru până la sfârșitul anului alocației.",
          "SCHEDULE_BEGINNING_OF_ACCRUAL_YEAR": "Se acumulează la începutul fiecărui an de acumulare",
          "SCHEDULE_EACH_CALENDAR_MONTH": "Se acumulează în fiecare lună calendaristică",
          "SCHEDULE_EACH_HOUR_WORKED": "Acumulări pentru fiecare oră lucrată",
          "SCHEDULE_EACH_HOUR_WORKED_RATE": "Se acumulează pentru fiecare oră lucrată la {{rate}}%",
          "CARRIED_OVER": "{{amount}} reportat de anul trecut",
          "LINK_TRANSACTIONS": "Vizualizați tranzacțiile",
          "ALERT_CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți această alocație? Acest lucru nu poate fi anulat.",
          "ALERT_SUCCESS_DELETE": "Alocația a fost eliminată.",
          "FINALISED": "🔒 Finalizat ({{date}})",
          "FINALISE": "Finalizează",
          "CONVERT_HOURS_TO_DAYS_TOGGLE_LABEL": "Convertiți ore în zile"
        },
        "ALLOWANCE_VALIDATION_MODAL": {
          "TITLE": "Ceva nu este în regulă",
          "DESCRIPTION": "Din păcate, ceea ce încercați să faceți provoacă una sau mai multe probleme.",
          "BUTTON_CLOSE": "Închide",
          "INVALID_ALLOWANCE_TITLE": "Indemnizație nevalidă",
          "INVALID_ALLOWANCE_DESCRIPTION": "De atunci, alocația a fost ștearsă și nu mai poate fi editată.",
          "OVERLAPPING_ALLOWANCE_TITLE": "Indemnizații suprapuse",
          "OVERLAPPING_ALLOWANCE_DESCRIPTION": "Această indemnizație se va suprapune cu o indemnizație existentă. Nu puteți avea alocații suprapuse pentru același tip de absență.",
          "FINALISED_ALLOWANCE_TITLE": "Indemnizație finalizată",
          "FINALISED_ALLOWANCE_DESCRIPTION": "Această indemnizație a fost finalizată. Pentru a-l edita sau șterge, trebuie mai întâi să returnați indemnizația.",
          "INVALID_UNIT_TITLE": "Unitate nevalidă",
          "INVALID_UNIT_DESCRIPTION": "O indemnizație cu programul de acumulare setat la Fiecare oră lucrată trebuie să aibă unitatea setată la Ore.",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_TITLE": "Data intrării în vigoare nevalidă",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "Data efectivă aleasă trebuie să se încadreze între începutul și sfârșitul anului alocației.",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_TITLE": "Data de referință nevalidă",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "Data de referință trebuie să se încadreze între data intrării în vigoare a indemnizației și sfârșitul anului alocației."
        },
        "ALLOWANCE_FORM": {
          "ERROR_500": "Hopa, am lovit un obstacol chiar atunci. Vă rugăm să încercați din nou.",
          "ALERT_SUCCESS_ADD": "Indemnizația a fost adăugată.",
          "ALERT_SUCCESS_UPDATE": "Indemnizația a fost actualizată.",
          "ALERT_ERROR_CALCULATE_BOOKED": "Nu am putut calcula valoarea lor rezervată chiar acum. Vă rugăm să încercați din nou.",
          "BUTTON_SAVE": "Salvați",
          "BUTTON_SAVE_LOADING": "Salvarea...",
          "TITLE": "Indemnizație",
          "LABEL_YEAR_STARTS": "Anul începe",
          "YEAR_STARTS_HELP": "Anul de vacanță implicit al organizației dvs. începe de la {{settingDate}}.",
          "YEAR_STARTS_CUSTOMISE_INITIATE": "Alegeți o altă zi sau lună.",
          "YEAR_STARTS_CUSTOMISE_CANCEL": "Reveniți la ziua și luna implicite.",
          "LABEL_EFFECTIVE_DATE": "Data intrării în vigoare",
          "EFFECTIVE_DATE_HELP": "Dacă această indemnizație înlocuiește o indemnizație existentă în același an, trebuie să alegeți o dată la care aceasta intră în vigoare.",
          "ALERT_MISALIGNED_YEAR_START": "Acest lucru nu se aliniază cu anul de vacanță implicit al organizației.",
          "ALERT_MISALIGNED_EFFECTIVE_DATE": "Data intrării în vigoare nu este în anul alocației.",
          "ALERT_ALTERED_EFFECTIVE_DATE": "Data intrării în vigoare nu se potrivește cu data de începere a anului alocației. Ar trebui să faceți acest lucru numai dacă înlocuiți o indemnizație existentă în același an.",
          "LABEL_ABSENCE_TYPE": "Tipul absenței",
          "LABEL_SCHEDULE_OF_ACCRUAL": "Calendarul de acumulare",
          "TOOLTIP_SCHEDULE_OF_ACCRUAL": "Pentru a atribui manual ore sau zile, alegeți „începutul anului de acumulare”. Pentru ca Rotaready să atribuie ore sau zile în fiecare lună calendaristică, alegeți „fiecare lună calendaristică”. Pentru a acumula automat în funcție de orele lucrate, alegeți „fiecare oră lucrată”.",
          "LABEL_UNIT": "Unitate",
          "LABEL_DEDUCTIBLE": "Deductibil",
          "TOOLTIP_DEDUCTIBLE": "Controlați dacă angajatul poate plasa cereri de absență care se deduc din această indemnizație.",
          "DEDUCTIBLE_YES": "da",
          "DEDUCTIBLE_NO": "Nu",
          "LABEL_PROJECT": "Preziceți acumularea în timpul rezervării",
          "TOOLTIP_PROJECT": "Când plasați o cerere de absență, preziceți orele care este probabil să se fi acumulat și includeți-le în cifra acumulată a angajatului.",
          "PROJECT_YES": "da",
          "PROJECT_NO": "Nu",
          "LABEL_TOTAL_ACCRUED_ANNUALLY": "Total acumulat anual",
          "LABEL_TOTAL_ACCRUED": "Total acumulat",
          "TOOLTIP_TOTAL_ACCRUED": "Introduceți numărul de ore sau zile pe care acest angajat le-a acumulat până acum. Rotaready va actualiza automat acest lucru în continuare.",
          "LABEL_BOOKED": "Rezervat",
          "TOOLTIP_BOOKED": "Introduceți numărul de ore sau zile pe care le-a rezervat acest angajat. Rotaready va menține acest lucru automat.",
          "ALERT_BOOKED": "Am calculat acest lucru pentru dvs. pe baza absenței rezervate în Rotaready. Modificați acest lucru numai dacă absența a fost rezervată în afara Rotaready.",
          "TOOLTIP_BOOKED_BUTTON": "Calculați automat",
          "LABEL_TOTAL_TO_ACCRUE": "Maximum de acumulat",
          "TOOLTIP_TOTAL_TO_ACCRUE_HOURLY": "Introduceți numărul total de ore sau zile pe care acest angajat are dreptul să le acumuleze. Lăsați necompletat pentru a nu impune o limită.",
          "TOOLTIP_TOTAL_TO_ACCRUE_MONTHLY": "Introduceți numărul total de ore sau zile pe care acest angajat ar trebui să le acumuleze pentru anul respectiv.",
          "LABEL_ACCRUAL_RATE": "Suprascrierea ratei de acumulare",
          "TOOLTIP_ACCRUAL_RATE": "Opțional, specificați o rată de acumulare dacă doriți să suprascrieți rata implicită a jurisdicției în care lucrează angajatul.",
          "LABEL_OVERSPEND_LIMIT": "Cheltuieli excesive",
          "TOOLTIP_OVERSPEND_LIMIT": "Opțional, permiteți angajatului să rezerve mai multe ore sau zile decât au acumulat.",
          "LABEL_CARRIED_OVER": "Transferat de anul trecut",
          "BUTTON_PRO_RATA": "Pro-rata",
          "LABEL_EMPLOYMENT_START": "Data începerii angajării",
          "LABEL_PRO_RATA_TOTAL": "Total acumulat dacă este angajat pe tot parcursul anului",
          "HELP_TEXT_PRO_RATA": "Vom seta totalul acumulat la {{value}}.",
          "CREATE_NEXT_YEAR_CHECKBOX": "Creați o altă indemnizație pentru anul viitor folosind totalul acumulat dacă este angajat pe tot parcursul anului.",
          "ALERT_SUCCESS_CALCULATE_TOTAL_ACCRUED": "Am calculat acest lucru pentru dvs. folosind orele de deconectare pentru acest angajat.",
          "ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED": "Nu aveți permisiunea de a accesa orele semnate pentru acest angajat. Fără aceasta, nu putem calcula totalul acumulat pentru dvs.",
          "ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED": "Nu am putut calcula automat numărul total de ore acumulate. Vă rugăm să încercați din nou.",
          "TOOLTIP_CALCULATE_TOTAL_ACCRUED": "Calculați automat"
        },
        "ALLOWANCE_TRANSACTIONS": {
          "TITLE": "Tranzacţii",
          "DESCRIPTION": "Iată toate tranzacțiile care afectează indemnizația, afișând mai întâi cele mai recente:",
          "TYPE_ACCRUAL_HOURS_WORKED": "Acumularea din orele lucrate",
          "TYPE_ABSENCE_REQUESTED": "Absența solicitată",
          "TYPE_ABSENCE_REJECTED_OR_CANCELLED": "Absența respinsă sau anulată",
          "TYPE_MANUAL_ADJUSTMENT": "Reglare manuală",
          "TYPE_ACCRUAL_SCHEDULE_CHANGED": "Programul de acumulare modificat",
          "TYPE_CARRY_OVER": "Transferat din anul precedent",
          "TYPE_ACCRUAL_TIME_BASED": "Acumularea bazată pe timp",
          "TYPE_ABSENCE_UPDATED": "Datele absenței s-au schimbat",
          "TYPE_HOURS_PER_DAY_RECALCULATION": "Ore pe zi actualizate",
          "ERROR_500": "Hopa, am lovit un obstacol chiar atunci. Vă rugăm să încercați din nou.",
          "NO_TRANSACTIONS": "Nu există tranzacții de afișat.",
          "BUTTON_CLOSE": "Închide",
          "TRANSACTION_TYPE": "Filtrează după tip"
        },
        "VIEW_FINALISATION": {
          "TITLE": "Indemnizație finalizată",
          "BUTTON_CLOSE": "Închide",
          "BUTTON_REVERT": "Reveniți",
          "CONFIRM_REVERT": "Sunteți sigur că doriți să anulați această indemnizație? Aceasta va șterge, de asemenea, înregistrarea de plată corespunzătoare, dacă există una.",
          "LABEL_REFERENCE_DATE": "Data de referință",
          "LABEL_PAY_RECORD": "Plătiți/deducere",
          "PAY_SEE_MORE": "Vizualizați înregistrarea salariilor",
          "PAY_NONE": "Angajatului nu se datorează nicio plată și nici nu trebuie dedusă nicio plată.",
          "LABEL_NOTES": "notițe",
          "NOTES_NONE": "Nu au fost înregistrate note.",
          "ERROR_500": "Nu am putut salva modificările dvs. chiar atunci. Vă rugăm să încercați din nou.",
          "ERROR_403": "Nu aveți permisiunea de a modifica înregistrările salariale ale acestei persoane, deci nu putem restitui indemnizația.",
          "ALERT_SUCCESS": "Indemnizația a fost anulată."
        },
        "FINALISE_ALLOWANCE_FORM": {
          "TITLE": "Finalizați indemnizația",
          "BUTTON_SAVE": "Finalizează",
          "ERROR_500": "Nu am putut salva modificările dvs. chiar atunci. Vă rugăm să încercați din nou.",
          "ERROR_403": "Nu aveți permisiunea de a modifica înregistrările salariale ale acestei persoane, deci nu putem finaliza indemnizația.",
          "ALERT_SUCCESS": "Alocația a fost finalizată.",
          "GUIDANCE": "Vă recomandăm să finalizați o alocație atunci când puteți fi sigur că nu va mai fi acumulată, rezervată sau anulată nicio absență. Acest lucru este, de obicei, cât mai aproape de ultima zi a cuiva.",
          "GUIDANCE_FUTURE_YEAR": "Trebuie să faceți acest lucru numai dacă angajatul pleacă în acest an de indemnizație.",
          "LABEL_EFFECTIVE_DATE": "Data de referință",
          "EFFECTIVE_DATE_LEAVER_HELP": "Indemnizația se va încheia la această dată. Deoarece această persoană este o persoană care pleacă, am setat-o la ultima sa zi.",
          "EFFECTIVE_DATE_NOT_LEAVER_HELP": "Indemnizația se va încheia la această dată. Data pe care o alegeți va fi utilizată mai jos pentru a calcula numărul total de ore sau zile acumulate.",
          "LABEL_BASIC_PAY": "Salariu de bază",
          "ALERT_BASIC_PAY_NOT_PERMITTED": "Nu aveți permisiunea de a vizualiza înregistrările salariale ale acestui angajat, deci nu puteți utiliza această caracteristică pentru a crea o plată sau o deducere unică.",
          "ALERT_BASIC_PAY_NOT_AVAILABLE": "Angajatul nu are o înregistrare salarială de bază la data de referință aleasă.",
          "HELP_TEXT_BASIC_PAY": "Acesta este salariul de bază al angajatului la data de referință aleasă.",
          "LABEL_TOTAL_ACCRUED_LIVE": "Total acumulat",
          "HELP_TEXT_TOTAL_ACCRUED_LIVE": "Acesta este numărul total de ore acumulate pentru această indemnizație, pe baza tuturor zilelor care au fost semnate.",
          "LABEL_ACCRUED_PRO_RATED": "Acumulat (pro rata)",
          "HELP_TEXT_TOTAL_ACCRUED_PRO_RATED": "Folosind data de referință aleasă, numărul total de ore/zile acumulate a fost proporționat.",
          "LABEL_BOOKED": "Total rezervat",
          "HELP_TEXT_BOOKED": "Aceasta este absența totală care a fost rezervată până în prezent, incluzând atât cererile în așteptare, cât și cele aprobate.",
          "LABEL_ACCRUED_UNTAKEN": "Acumulate, dar neluate",
          "STATUS_PAYMENT_DUE": "Aceasta înseamnă că angajatul a luat mai puțină absență decât a acumulat. Mulți angajatori aleg să plătească angajatul pentru acest lucru.",
          "STATUS_DEDUCTION_DUE": "Aceasta înseamnă că angajatul a luat mai multă absență decât a acumulat. Unii angajatori aleg să deducă acest lucru din fișa de plată finală a angajatului.",
          "LABEL_AVERAGE_HOURS": "Ore medii pe zi",
          "LABEL_ONE_OFF_PAYMENT": "Plată/deducere unică",
          "HELP_TEXT_ONE_OFF_PAYMENT": "Dacă introduceți o sumă, Rotaready va crea o evidență de salarizare unică și o va lega de această indemnizație finalizată. Pentru o deducere, introduceți o sumă negativă.",
          "HELP_TEXT_ONE_OFF_PAYMENT_LINK": "Ajutor cu privire la modul de calculare a acestui lucru",
          "LABEL_PAY_ELEMENT": "Element de plată",
          "LABEL_PAYMENT_DATE": "Data aplicabilă",
          "HELP_TEXT_PAYMENT_DATE": "Alegeți cu atenție pentru a vă asigura că această plată este inclusă în salarizarea finală a angajatului.",
          "LABEL_NOTES": "notițe",
          "PLACEHOLDER_NOTES": "Dacă nu introduceți nicio notă, vom include automat cifrele din calculul de mai sus."
        },
        "FINALISE_ALLOWANCE_PROMPT": {
          "TITLE": "Sunt toate indemnizațiile finalizate?",
          "DESCRIPTION": "Dacă sunteți mulțumit că toate indemnizațiile sunt acum finalizate, ar trebui să confirmați acest lucru, astfel încât departamente precum salarizarea să se poată baza pe cifre.",
          "DESCRIPTION_PART_2": "De asemenea, puteți face acest lucru în fila Ocuparea forței de muncă la o dată ulterioară.",
          "BUTTON_ATTEST": "Confirmă",
          "BUTTON_LATER": "Am mai multe de făcut",
          "ALERT_ERROR": "Nu am putut salva confirmarea dvs. chiar atunci. Vă rugăm să încercați acest lucru în fila Ocuparea forței de muncă."
        }
      },
      "EMERGENCY_CONTACTS": {
        "ADD_MODAL": {
          "TITLE": "Noul contact de urgență",
          "RELATIONSHIP_LABEL": "Relația cu angajatul",
          "FIRST_NAME_LABEL": "Prenume",
          "LAST_NAME_LABEL": "Nume de familie",
          "OTHER_NAME_LABEL": "Alte nume",
          "PRIMARY_CONTACT_NUMBER_LABEL": "Numărul principal de contact",
          "SECONDARY_CONTACT_NUMBER_LABEL": "Număr de contact secundar",
          "ADDRESS_LABEL": "Adresă",
          "SAVE_BUTTON_TEXT": "Salvați",
          "CLOSE_BUTTON_TEXT": "Închide",
          "ALERT_ERROR_500": "Nu am putut salva modificările dvs. chiar atunci. Vă rugăm să încercați din nou."
        },
        "INDEX": {
          "TITLE": "Contacte de urgență",
          "RELATIONSHIP_COLUMN_TEXT": "Relație",
          "NAME_COLUMN_TEXT": "Nume",
          "PHONE_COLUMN_TEXT": "Număr (e) de telefon",
          "ADDRESS_COLUMN_TEXT": "Adresă",
          "NONE_TO_SHOW": "Nu există contacte de urgență de afișat.",
          "ADD_BUTTON_TEXT": "Contact nou",
          "OPTIONS_COLUMN_TEXT": "Opțiuni",
          "DELETE_LINK": "Șterge",
          "ALERT_ERROR_500": "Nu am putut încărca contactele de urgență chiar atunci. Vă rugăm să încercați din nou.",
          "CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți acest contact de urgență?\nAcest lucru nu poate fi anulat."
        }
      },
      "EMPLOYMENT": {
        "LABEL_LENGTH_OF_SERVICE": "Durata serviciului",
        "LABEL_STARTS_IN": "Începe în",
        "LABEL_OPTIONS": "Opțiuni",
        "OPTION_NEW_EMPLOYMENT": "Noi locuri de muncă",
        "OPTION_END_EMPLOYMENT": "Încheiați angajarea",
        "OPTION_REINSTATE_EMPLOYMENT": "Reinstaurarea ocupării forței de muncă",
        "OPTION_DELETE_EMPLOYMENT": "Ștergeți angajarea",
        "TEXT_TERMINATED": "Acest angajament a fost reziliat, încheindu-se la",
        "LABEL_REASON": "Motivul",
        "LABEL_REHIRE": "Reînchiriere",
        "LABEL_REHIRE_YES": "da",
        "LABEL_REHIRE_NO": "Nu",
        "BUTTON_ATTEST": "Confirmă",
        "TEXT_ALLOWANCES_NOT_FINALISED": "Nu ați confirmat încă că indemnizațiile de absență ale angajatului au fost finalizate.",
        "TEXT_ALLOWANCES_NOT_FINALISED_PART_2": "Acest lucru se face de obicei în sau înainte de ultima zi a angajatului, înainte de rularea finală a salariului.",
        "TEXT_ALLOWANCES_NOT_FINALISED_LINK": "Vizualizați indemnizațiile",
        "BUTTON_UNDO": "Anulează",
        "TEXT_ALLOWANCES_FINALISED": "Indemnizațiile de absență au fost confirmate ca fiind finalizate pentru acest loc de muncă.",
        "LABEL_JOIN_DATE": "Data înscrierii",
        "LABEL_NOTICE_PERIOD": "Perioada de preaviz",
        "BUTTON_SET_NOTICE_PERIOD": "Setați o perioadă de preaviz...",
        "LABEL_EMPLOYEE_NUMBER": "Numărul angajatului",
        "TOOLTIP_EMPLOYEE_NUMBER": "Opțional. În Marea Britanie, HMRC necesită un număr unic pentru fiecare angajat. Vom trimite acest lucru furnizorului dvs. de salarizare dacă este activată o integrare de salarizare.",
        "LABEL_RECRUITMENT_SOURCE": "Sursa de recrutare",
        "LABEL_STARTER_DECLARATION": "Declarație inițială (Marea Britanie)",
        "BUTTON_SAVE": "Salvați",
        "BUTTON_SAVE_LOADING": "Salvarea...",
        "BUTTON_NEW_APPOINTMENT": "Numire nouă",
        "TITLE_APPOINTMENTS": "Numiri",
        "COLUMN_EFFECTIVE_DATE": "Data intrării în vigoare",
        "COLUMN_SITE": "Site-ul",
        "COLUMN_DEPARTMENT": "Divizie",
        "COLUMN_POSITION": "Rolul postului",
        "COLUMN_LINE_MANAGER": "Director de linie",
        "COLUMN_STAFF_GROUP": "Grupul de personal",
        "COLUMN_OBLIGATION": "Obligație",
        "TOOLTIP_ACTIVE_APPOINTMENT": "Aceasta este numirea activă.",
        "LINE_MANAGER_NOT_SET": "Nu este setat",
        "MIN_OBLIGATION_ZERO_HOURS": "Zero ore",
        "ERROR_500": "Nu putem încărca locuri de muncă chiar acum. Vă rugăm să încercați din nou.",
        "ERROR_403": "Nu aveți permisiunea de a vizualiza/modifica înregistrările de angajare ale acestei persoane.",
        "LABEL_HOURS": "ore",
        "LABEL_DAYS": "zile",
        "LABEL_WEEK": "săptămână",
        "LABEL_MONTH": "lună",
        "LABEL_CURRENT_EMPLOYMENT": "Locul de muncă actual",
        "ALERT_CHANGES_SAVED": "Modificările dvs. au fost salvate.",
        "CONFIRM_DELETE": "Ești sigur că vrei să faci asta? Eliminarea unui loc de muncă este ireversibilă.",
        "CONFIRM_REINSTATE": "Ești sigur că vrei să faci asta?\n\nDacă cineva se alătură organizației dvs., trebuie să creați un nou loc de muncă. Ar trebui să restabiliți un loc de muncă numai dacă acesta a fost încheiat în mod greșit.",
        "VALIDATION_MODAL": {
          "TITLE": "Nu putem face această schimbare",
          "DESCRIPTION": "Din păcate, ceea ce încercați să faceți provoacă una sau mai multe probleme.",
          "NO_EMPLOYMENT_TITLE": "Fără locuri de muncă",
          "NO_EMPLOYMENT_DESCRIPTION": "Schimbarea dvs. ar lăsa angajatul fără un loc de muncă; trebuie să existe întotdeauna cel puțin unul.",
          "EMPLOYMENT_OVERLAPPING_TITLE": "Locurile de muncă care se suprapun",
          "EMPLOYMENT_OVERLAPPING_DESCRIPTION": "Schimbarea dvs. ar determina suprapunerea a două locuri de muncă:",
          "EMPLOYMENT_NOT_TERMINATED_TITLE": "Angajarea nu a încetat",
          "EMPLOYMENT_NOT_TERMINATED_DESCRIPTION": "Trebuie să încheiați angajarea anterioară înainte de a adăuga una nouă.",
          "EMPLOYMENT_NO_APPOINTMENTS_TITLE": "Angajare fără numiri",
          "EMPLOYMENT_NO_APPOINTMENTS_DESCRIPTION": "Un loc de muncă nu poate fi gol, trebuie să conțină cel puțin o întâlnire.",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_TITLE": "Numirea în afara locului de muncă",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Schimbarea dvs. ar face ca o parte din această numire să nu cadă în afara locului de muncă.",
          "APPOINTMENT_OVERLAPPING_TITLE": "Numirile care se suprapun",
          "APPOINTMENT_OVERLAPPING_TITLE_DESCRIPTION": "Modificarea dvs. ar determina suprapunerea a două întâlniri:",
          "APPOINTMENT_NO_EMPLOYMENT_TITLE": "Numire fără loc de muncă",
          "APPOINTMENT_NO_EMPLOYMENT_DESCRIPTION": "O programare nu este asociată cu un loc de muncă. Acest lucru nu ar trebui să se întâmple în mod normal!",
          "SHIFTS_OUTSIDE_EMPLOYMENT_TITLE": "Schimbări în afara locului de muncă",
          "SHIFTS_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Schimbarea dvs. va lăsa {{count}} schimburile la datele care se încadrează înainte de începerea angajării sau după încheierea acesteia. Pentru a continua, aceste schimburi pot fi șterse, neatribuite (ceea ce le mută în schimburi deschise) sau modificate pe rotația propriu-zisă:",
          "SHIFT_ENTITY_MISMATCH_TITLE": "Schimbări în diferite locații/departamente",
          "SHIFT_ENTITY_MISMATCH_DESCRIPTION": "Modificarea dvs. ar lăsa {{count}} schimburi provenite din rotația unui departament care nu se potrivește cu departamentul din numirea angajatului. Pentru a continua, aceste schimburi pot fi șterse, neatribuite (ceea ce le mută în schimburi deschise) sau modificate pe rotația propriu-zisă:",
          "ABSENCE_OUTSIDE_EMPLOYMENT_TITLE": "Absența în afara locului de muncă",
          "ABSENCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Modificarea dvs. va lăsa rezervarea (rezervările) de absență la datele care se încadrează înainte de începerea angajării sau după încheierea acesteia. Pentru a continua, acestea trebuie anulate:",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_TITLE": "Participarea în afara locului de muncă",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Modificarea dvs. va lăsa evenimentul (evenimentele) de prezență {{count}} la datele care se încadrează înainte de începerea angajării sau după încheierea acestuia. Pentru a continua, acestea trebuie șterse:",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_TITLE": "Schimbați cartografierea rutinei în afara locurilor de muncă",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Modificarea dvs. ar lăsa cartografierea rutinei de schimb {{count}} la datele care se încadrează înainte de începerea angajării sau după încheierea acesteia. Pentru a continua, acestea trebuie să fie reziliate sau șterse:",
          "EMAIL_EXISTS_TITLE": "Adresa de e-mail există deja",
          "EMAIL_EXISTS_DESCRIPTION": "Adresa de e-mail pe care ați introdus-o este deja utilizată de un alt angajat. Dacă această persoană a lucrat anterior pentru organizația dvs., căutați-o și adăugați un nou loc de muncă.",
          "MOBILE_EXISTS_TITLE": "Numărul de telefon mobil există deja",
          "MOBILE_EXISTS_DESCRIPTION": "Numărul de telefon mobil pe care l-ați introdus este deja utilizat de un alt angajat. Dacă această persoană a lucrat anterior pentru organizația dvs., căutați-o și adăugați un nou loc de muncă.",
          "INVALID_DATE_TITLE": "Data nevalidă",
          "INVALID_DATE_DESCRIPTION": "Dacă încercați să reziliați un loc de muncă, data de încheiere pe care ați ales-o este înainte de începerea angajării.",
          "PERIOD_SIGNED_OFF_TITLE": "Data deconectării",
          "PERIOD_SIGNED_OFF_DESCRIPTION": "Modificarea se suprapune cu una sau mai multe date care au fost deconectate.",
          "PAY_ELEMENT_INVALID_TITLE": "Element de plată nevalid",
          "PAY_ELEMENT_INVALID_DESCRIPTION": "Elementul de plată ales este invalid.",
          "PAY_RECORD_SAME_DATE_TITLE": "Înregistrare de plată duplicată",
          "PAY_RECORD_SAME_DATE_DESCRIPTION": "Există deja o înregistrare de plată pentru elementul de plată ales la acea dată.",
          "RECORD_MISSING_TITLE": "Înregistrarea plății ștearsă",
          "RECORD_MISSING_DESCRIPTION": "Această înregistrare de plată a fost ștearsă și nu mai poate fi modificată.",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_TITLE": "Nepotrivirea calendarului de salarizare",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_DESCRIPTION": "Modificarea dvs. ar lăsa rezervarea de absență asociată cu un calendar de salarizare diferit. Pentru a continua, acestea trebuie anulate:",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_TITLE": "Legat de indemnizația finalizată",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_DESCRIPTION": "Această înregistrare de salarizare este legată de o indemnizație finalizată și nu poate fi editată. Pentru a o șterge, vă rugăm să găsiți indemnizația și să o returnați.",
          "BUTTON_TRY_AGAIN": "Încearcă din nou",
          "BUTTON_CLOSE": "Închide"
        },
        "LEAVER_FORM": {
          "TITLE": "Încheiați angajarea",
          "ALERT_SUCCESS": "Angajarea a fost încheiată.",
          "ERROR_500": "Nu am putut salva modificările dvs. chiar atunci. Vă rugăm să încercați din nou.",
          "LABEL_END_DATE": "Ultima zi de angajare",
          "LABEL_REASON": "Motivul plecării",
          "LABEL_REHIRE": "Re-angajare",
          "CHECKBOX_REHIRE": "Acest angajat este considerat eligibil pentru re-angajare în viitor.",
          "LABEL_NOTES": "notițe",
          "PLACEHOLDER_NOTES": "Opțional înregistrați câteva note asociate plecării acestui angajat.",
          "BUTTON_SAVE": "Salvați",
          "BUTTON_SAVE_LOADING": "Salvarea..."
        },
        "EMPLOYMENT_FORM": {
          "TITLE": "Noi locuri de muncă",
          "RESET_WARNING": "Contul angajatului va fi marcat ca neaprobat și diverse atribute vor fi resetate, cum ar fi orele medii lucrate pe zi și pe săptămână.",
          "LABEL_START_DATE": "Data începerii angajării",
          "LABEL_NOTICE_PERIOD": "Perioada de preaviz",
          "LABEL_EMPLOYEE_NUMBER": "Numărul angajatului",
          "TOOLTIP_EMPLOYEE_NUMBER": "Opțional. În Marea Britanie, HMRC necesită un număr unic pentru fiecare angajat. Vom trimite acest lucru furnizorului dvs. de salarizare dacă este activată o integrare de salarizare.",
          "LABEL_RECRUITMENT_SOURCE": "Sursa de recrutare",
          "LABEL_STARTER_DECLARATION": "Declarație inițială (Marea Britanie)",
          "LABEL_INVITE": "Invitare la Rotaready",
          "CHECKBOX_INVITE": "Trimiteți un link magic acestui angajat, care îi invită să-și seteze parola și să se conecteze pentru a-și vedea schimburile.",
          "BUTTON_ADD": "Adaugă",
          "BUTTON_ADD_LOADING": "Adăugând...",
          "ALERT_SUCCESS": "Locurile de muncă au fost create.",
          "ERROR_500": "Nu am putut salva modificările dvs. chiar atunci. Vă rugăm să încercați din nou."
        },
        "APPOINTMENT_FORM": {
          "TITLE": "Numire",
          "ALERT_MAPPINGS": "Vă rugăm să completați mapările implicite ale fluxului sau să verificați declarația.",
          "ALERT_SAVED": "Programarea a fost salvată.",
          "ALERT_DELETED": "Numirea a fost ștearsă.",
          "ERROR_500": "Nu am putut salva modificările dvs. chiar atunci. Vă rugăm să încercați din nou.",
          "INFO_NEW_APPOINTMENT": "Am folosit cea mai recentă întâlnire pentru a pre-completa acest formular pentru dvs.",
          "LABEL_EFFECTIVE_DATE": "Data intrării în vigoare",
          "HELP_TEXT_EFFECTIVE_DATE": "Deoarece aceasta este prima programare, data intrării în vigoare este legată de Data de aderare la angajare.",
          "INFO_NEW_EMPLOYEE": "Pentru a termina, configurați noua programare a acestui angajat setându-i situl/departamentul, rolul postului și alte atribute cheie.",
          "LABEL_LINE_MANAGER": "Director de linie",
          "LABEL_LINE_MANAGER_NOT_SET": "Nu este setat",
          "TITLE_JOB_ROLE": "Rolul postului",
          "LABEL_SITE_DEPARTMENT": "Site/departament",
          "LABEL_JOB_ROLE": "Rolul postului",
          "LABEL_STAFF_GROUP": "Grupul de personal",
          "HELP_TEXT_STAFF_GROUP": "Grupurile de personal sunt folosite pentru a separa vizual persoanele pe rotație și în alte instrumente.",
          "TITLE_EMPLOYMENT_CONTRACT": "Contract de muncă",
          "LABEL_COMPANY": "Companie",
          "LABEL_PAYROLL_CALENDAR": "Calendarul de salarizare",
          "LABEL_CONTRACT_TYPE": "Tipul contractului",
          "LABEL_PUBLIC_HOLIDAYS": "Sărbători legale",
          "HELP_TEXT_PUBLIC_HOLIDAYS": "Acest angajat lucrează de obicei în sărbătorile legale.",
          "LABEL_WORKING_TIME": "Reglementări privind timpul de lucru",
          "HELP_TEXT_WORKING_TIME": "Acest angajat a renunțat la limitele de lucru opționale din reglementările privind timpul de lucru.",
          "TITLE_CONTRACTUAL_OBLIGATION": "Obligație contractuală",
          "LABEL_MIN_OBLIGATION": "Ore/zile garantate",
          "HELP_TEXT_MIN_OBLIGATION": "Acest angajat are garanția unei cantități minime de muncă.",
          "LABEL_MAX_OBLIGATION": "Limita de lucru",
          "HELP_TEXT_MAX_OBLIGATION": "Aplicați o limită superioară a numărului de ore sau zile pe care acest angajat poate fi programat să lucreze.",
          "TITLE_STREAM_MAPPINGS": "Mapări de flux implicite",
          "HELP_TEXT_STREAM_MAPPINGS": "Când acest angajat lucrează într-o tură, mapările tipului de schimb determină fluxul (fluxurile) pentru alocarea costurilor salariale. Dacă tipul de schimbare nu are niciunul, acestea sunt utilizate în schimb:",
          "COLUMN_STREAM": "Stream",
          "COLUMN_ATTRIBUTION": "Atribuire",
          "COLUMN_OPTIONS": "Opțiuni",
          "OPTION_DELETE": "Șterge",
          "NO_MAPPINGS": "Nu este nimeni de arătat.",
          "LABEL_ADD_MAPPING": "Adăugarea unei noi mapări",
          "OPTION_ADD": "Adaugă",
          "LABEL_NO_MAPPINGS": "Fără mapări",
          "CHECKBOX_NO_MAPPINGS": "Accept că, fără mapări implicite de flux, pot exista inexactități în controlul costurilor și în unele rapoarte.",
          "HELP_TEXT_NO_MAPPINGS": "Acest lucru este în regulă dacă nu veți urmări costul salarial al acestui angajat.",
          "BUTTON_SAVE": "Salvați",
          "BUTTON_SAVE_LOADING": "Salvarea...",
          "BUTTON_DELETE": "Șterge",
          "BUTTON_DELETE_LOADING": "Ștergerea..."
        }
      },
      "DOCUMENTS": {
        "FILTER_LABEL_CATEGORY": "Categorie",
        "BUTTON_UPLOAD": "Încărcați fișierul",
        "BUTTON_SIGNATURES": "Semnături de documente",
        "OPTION_SEND_TO_SIGN": "Trimiteți un document pentru a semna",
        "OPTION_PENDING_SIGNATURES": "Vizualizați documentele care așteaptă semnătura",
        "ERROR_500": "Nu putem încărca documente chiar acum. Vă rugăm să încercați din nou.",
        "ERROR_403": "Nu aveți permisiunea de a vizualiza/modifica documentele acestei persoane.",
        "ERROR_NO_CATEGORIES": "Documentele nu sunt activate pentru organizația dvs. Discutați cu noi pentru a începe.",
        "TOOLTIP_CATEGORY_NOT_APPLICABLE": "Categorie marcată ca neobligatorie",
        "TOOLTIP_CATEGORY_INVALID": "Documentul lipsește sau a expirat",
        "INFO_CATEGORY_NOT_APPLICABLE": "Această categorie a fost marcată ca neobligatorie pentru acest angajat.",
        "LINK_MARK_CATEGORY_REQUIRED": "Marcați după cum este necesar",
        "LINK_MARK_CATEGORY_NOT_REQUIRED": "Marcați ca neobligatoriu",
        "INFO_CATEGORY_NO_DOCUMENTS": "Nu există documente în această categorie.",
        "COLUMN_NAME": "Nume",
        "COLUMN_EXPIRES": "Expiră",
        "COLUMN_FILE_SIZE": "Dimensiunea fişierului",
        "COLUMN_UPLOADED_BY": "Încărcat de",
        "COLUMN_OPTIONS": "Opțiuni",
        "LINK_DELETE": "Șterge",
        "LINK_EDIT": "Editați",
        "ALERT_CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți acest document? Acest lucru nu poate fi anulat!",
        "TOOLTIP_SIGNATURE_ELECTRONIC": "Acest document a fost semnat electronic prin Rotaready",
        "TOOLTIP_SIGNATURE_MANUAL": "Acest document a fost semnat manual",
        "TOOLTIP_NOTES": "Notele sunt disponibile",
        "PILL_SENSITIVE": "Sensibil",
        "PILL_CAN_EXPIRE": "Poate expira",
        "PILL_VISIBLE_TO_EMPLOYEE": "Vizibil pentru angajat",
        "TOOLTIP_ACKNOWLEDGED": "Recunoscut: {{date}}",
        "PERSONAL": {
          "TITLE": "Documentele mele",
          "ALERT_CONFIRM_ACKNOWLEDGE": "Făcând clic pe confirmă confirmați că ați citit și înțeles documentul.",
          "INFO_NO_DOCUMENTS": "Nu aveți documente de vizualizat.",
          "LINK_ACKNOWLEDGE": "Recunoașteți",
          "PILL_NEEDS_ACKNOWLEDGEMENT": "Necesită recunoaștere",
          "PILL_ACKNOWLEDGED": "Recunoscută",
          "COLUMN_NAME": "Nume",
          "COLUMN_CATEGORY": "Categorie",
          "COLUMN_EXPIRES": "Expiră",
          "COLUMN_FILE_SIZE": "Dimensiunea fişierului",
          "COLUMN_OPTIONS": "Opțiuni",
          "FILTER_LABEL_CATEGORY": "Categorie"
        }
      },
      "DOCUMENT_EDIT_FORM": {
        "TITLE": "Editați documentul",
        "BUTTON_CANCEL": "Anulează",
        "BUTTON_SAVE": "Salvați",
        "LABEL_EXPIRY_DATE": "Data expirării",
        "LABEL_NOTES": "notițe",
        "HELP_TEXT_EXPIRY": "Documentul trebuie să primească o dată de expirare dacă categoria necesită una. Veți fi avertizat să luați măsuri pe măsură ce data se apropie și din nou dacă expiră.",
        "ALERT_SUCCESS": "Documentul a fost actualizat.",
        "ALERT_ERROR_500": "A apărut o eroare la actualizarea documentului. Vă rugăm să încercați din nou sau contactați-ne dacă acest mesaj persistă.",
        "ALERT_ERROR_400": "Există ceva care nu este în regulă în legătură cu schimbările tale."
      },
      "DOCUMENT_UPLOAD_MODAL": {
        "TITLE": "Încărcați un document",
        "ALERT_SIGNATURE": "Vom marca acest document ca fiind semnat manual.",
        "LABEL_UPLOAD_TO": "Încărcați la",
        "LABEL_CATEGORY": "Categorie",
        "LABEL_EXPIRY_DATE": "Data expirării",
        "HELP_TEXT_EXPIRY": "Documentul trebuie să primească o dată de expirare dacă categoria necesită una. Veți fi avertizat să luați măsuri pe măsură ce data se apropie și din nou dacă expiră.",
        "LABEL_UPLOAD": "Încărcați",
        "LABEL_NOTES": "notițe",
        "BUTTON_CANCEL": "Anulează",
        "BUTTON_SAVE": "Salvați",
        "ALERT_SUCCESS": "Documentul dvs. a fost încărcat.",
        "ALERT_ERROR_500": "A apărut o eroare la încărcarea documentului. Vă rugăm să încercați din nou sau contactați-ne dacă acest mesaj persistă.",
        "ALERT_ERROR_400": "Există ceva care nu este în regulă cu privire la categoria și data de expirare."
      },
      "DOCUMENT_SIGNATURE_MODAL": {
        "ALERT_PICK_CATEGORY": "Vă rugăm să selectați o categorie în care să salvați documentul.",
        "ALERT_SUCCESS_MANUAL": "Documentul dvs. a fost descărcat.",
        "ALERT_SUCCESS_ELECTRONIC": "Documentul dvs. a fost trimis pentru semnătură electronică.",
        "ALERT_ERROR_500": "A apărut o eroare la construirea documentului. Vă rugăm să încercați din nou sau contactați-ne dacă acest mesaj persistă.",
        "ALERT_ERROR_400": "Nu am putut să vă construim documentul. Verificați etichetele de îmbinare și încercați din nou.",
        "TITLE": "Trimiteți un document pentru a semna",
        "ALERT_INFO": "Plasând etichete de îmbinare în șablon sau fișier, Rotaready poate insera informații cheie despre angajatul căruia îi trimiteți, cum ar fi numele sau rolul postului. Este, de asemenea, modul în care controlați unde apare caseta de semnătură.",
        "ALERT_INFO_LINK": "Află cum",
        "LABEL_SEND_TO": "Trimiteți la",
        "LABEL_DOCUMENT": "Document",
        "LABEL_DOCUMENT_TEMPLATE": "Utilizarea unui șablon existent",
        "LABEL_DOCUMENT_UPLOAD": "Încărcați un fișier unic",
        "LABEL_TEMPLATE": "Șablon",
        "ALERT_NO_TEMPLATES_TITLE": "Nu aveți șabloane.",
        "ALERT_NO_TEMPLATES_HELP": "Economisiți timp, încărcați un fișier o dată și reutilizați-l pentru mai multe persoane.",
        "ALERT_NO_TEMPLATES_BUTTON": "Încărcați un șablon",
        "LABEL_UPLOAD": "Încărcați",
        "SECTION_SIGNING_INFO": "Informații de semnare",
        "LABEL_TITLE": "Titlu",
        "HELP_TEXT_TITLE": "Dați acestui document un titlu, astfel încât persoana căreia îi trimiteți să știe ce este.",
        "LABEL_EMPLOYER_SIGNS": "Semnele angajatorului",
        "OPTION_EMPLOYER_SIGN_FALSE": "Nu, doar angajatul",
        "OPTION_EMPLOYER_SIGN_TRUE": "Este necesară o semnătură în numele angajatorului",
        "LABEL_EMPLOYER_SIGNATORY": "Angajator semnatar",
        "HELP_TEXT_EMPLOYER_SIGNATORY": "Selectați cine ar trebui să semneze acest document în numele angajatorului.",
        "LABEL_SIGNATURE_METHOD": "Metoda semnăturii",
        "OPTION_SIGNATURE_METHOD_MANUAL": "Manual, cu stilou și hârtie",
        "OPTION_SIGNATURE_METHOD_ELECTRONIC": "Electronică, cu semnătură electronică complet legală",
        "LABEL_EMAIL_MESSAGE": "Mesaj de e-mail",
        "HELP_TEXT_EMAIL_MESSAGE": "Adăugați un mesaj opțional la notificarea prin e-mail trimisă tuturor părților semnatare.",
        "SECTION_UPON_COMPLETION": "După finalizare",
        "SECTION_METHOD": "Metoda semnăturii",
        "LABEL_SAVE_TO": "Salvați în",
        "HELP_TEXT_SAVE_TO": "Când este semnat, fișierul va fi salvat în documentele angajatului din categoria aleasă.",
        "LABEL_EXPIRY_DATE": "Data expirării",
        "HELP_TEXT_EXPIRY_DATE": "Documentul trebuie să primească o dată de expirare dacă categoria de destinație o cere. Veți fi avertizat să luați măsuri pe măsură ce data se apropie și din nou dacă expiră.",
        "CREDITS_DEPLETED_TEXT": "Aveți nevoie de credite pentru a trimite acest document pentru semnătură electronică.",
        "CREDITS_DEPLETED_CTA": "Discutați cu noi acum",
        "CREDITS_AVAILABLE_TEXT": "Aveți <b>{{sold}}</b> credit (e) rămas (e). Trimiterea acestui document va utiliza un credit.",
        "CREDITS_AVAILABLE_CTA": "Este ușor să vă completați creditele. Trimiteți-ne un mesaj pe chat live.",
        "BUTTON_CANCEL": "Anulează",
        "BUTTON_DOWNLOAD_PREVIEW": "Descarcă previzualizarea",
        "BUTTON_SAVE_DOWNLOAD": "Salvați și descărcați",
        "BUTTON_SEND": "Trimite"
      },
      "NOTES": {
        "ERROR_500": "Nu putem încărca note chiar acum. Vă rugăm să încercați din nou.",
        "ALERT_CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți această notă?",
        "BODY_PLACEHOLDER": "Scrie o notă nouă...",
        "SAVE_BUTTON_TEXT": "Adaugă notă",
        "VISIBILITY_SELF": "Privat",
        "VISIBILITY_SELF_HELP": "Vizibil numai de tine",
        "VISIBILITY_SENSITIVE": "Sensibil",
        "VISIBILITY_SENSITIVE_HELP": "Vizibil numai de persoanele cu permisiunea de a vedea note sensibile",
        "VISIBILITY_GENERAL": "General",
        "VISIBILITY_GENERAL_HELP": "Vizibil numai de persoanele care au permisiunea de a vedea notele acestui angajat",
        "EDITED_LABEL": "Editat",
        "EDIT_LABEL": "Editați",
        "DELETE_LABEL": "Șterge",
        "NO_NOTES": "Nu există note de arătat."
      },
      "DANGER_ZONE": {
        "ANONYMISE": {
          "TITLE": "Anonimizarea angajatului",
          "ERROR_500": "Nu am putut salva chiar atunci. Vă rugăm să încercați din nou.",
          "ERROR_403": "Nu aveți permisiunea de a accesa detaliile personale ale acestui angajat. Aceasta înseamnă că nu puteți programa profilul lor pentru a fi anonimizat.",
          "LABEL_END_DATE": "Data de încheiere a angajării",
          "HELP_TEXT": "Pentru a ajuta la respectarea legilor privind protecția datelor, Rotaready poate elimina toate informațiile personale din acest profil de angajat după o perioadă de timp aleasă. În Marea Britanie, HMRC solicită păstrarea înregistrărilor timp de cel puțin șase ani.",
          "HELP_TEXT_LEARN": "Află mai multe",
          "LABEL_ANONYMISE": "Anonimizare",
          "CHECKBOX_ANONYMISE": "Anonimizați acest angajat",
          "LABEL_ANONYMISE_DATE": "Anonimizați pe",
          "ALERT_ANONYMISE_DATE": "Pentru a anonimiza acest angajat, vă rugăm să alegeți o dată care este atât în viitor, cât și după ultima zi de muncă a angajatului.",
          "BUTTON_SAVE": "Salvați"
        }
      }
    },
    "ABSENCE": {
      "ABSENCE": {
        "TITLE": "Calendarul absențelor",
        "FILTER_LABEL_APPROVAL_STATE": "Statut",
        "FILTER_LABEL_POSITION": "Rolul postului",
        "FILTER_LABEL_STAFF_GROUP": "Grupul de personal",
        "ERROR_500": "Nu am putut încărca absența chiar atunci. Vă rugăm să încercați din nou.",
        "FILTER_LABEL_ABSENCE_TYPE": "Tip",
        "FILTER_LABEL_REQUESTER": "Solicitant"
      }
    },
    "REQUESTS": {
      "ABSENCE": {
        "DATES_COLUMN_TEXT": "Data (date)",
        "WHO_COLUMN_TEXT": "Cine",
        "ABSENCE_TYPE_COLUMN_TEXT": "Tip",
        "SUBMITTED_DATE_TEXT": "Trimis",
        "HOURS_COLUMN_TEXT": "Ore",
        "DAYS_COLUMN_TEXT": "Zile",
        "NO_STAFF_MATCH_TEXT": "Nu am putut găsi nicio solicitare care să corespundă filtrelor dvs.",
        "FILTER_LABEL_POSITION": "Rolul postului",
        "FILTER_LABEL_STAFF_GROUP": "Grupul de personal",
        "ERROR_500": "Nu am putut încărca absența chiar atunci. Vă rugăm să încercați din nou.",
        "FILTER_LABEL_ABSENCE_TYPE": "Tip",
        "FILTER_LABEL_REQUESTER": "Solicitant"
      },
      "CONFIRM_SWAP_APPROVAL_MODAL": {
        "TITLE": "Ești sigur?",
        "VALIDATION_MANUAL_TEXT": "Pentru a anula acest schimb de schimburi, va trebui să editați manual rotația.",
        "YES_TITLE": "da",
        "APPROVE_BUTTON_TEXT": "Aprobați acest schimb acum și anunțați ambii membri ai personalului",
        "NO_TITLE": "Nu",
        "DO_NOTHING_BUTTON_TEXT": "Nu faceți nimic - nu aprobați încă acest swap",
        "CANCEL_BUTTON_TEXT": "Anulează"
      },
      "INDEX": {
        "HEADING": "Elemente care necesită atenție",
        "ABSENCE_LABEL": "Cereri de absență",
        "SWAPS_LABEL": "Schimburi de schimbare",
        "DOCUMENTS_EXPIRING_LABEL": "Documente care expiră",
        "ACCOUNT_APPROVAL_LABEL": "Tamplari noi"
      },
      "SWAPS": {
        "INITIATED_BY_TEXT": "Inițiat de",
        "INITIATED_TOOLTIP": "Membrul personalului care vrea să-și dea tura",
        "SHIFT_COLUMN_TEXT": "Schimbarea lor",
        "SWAPPING_WITH_COLUMN_TEXT": "Schimbarea cu",
        "SWAPPING_TOOLTIP": "Membrul personalului care dorește să lucreze în schimb",
        "RETURN_SHIFT_COLUMN_TEXT": "Schimbarea lor",
        "OPTION_COLUMN_TEXT": "Opțiuni",
        "ACCEPTED_DATE_COLUMN_TEXT": "Așteptând de atunci",
        "NONE_TEXT": "Niciuna",
        "NO_STAFF_MATCH_TEXT": "Nu am putut găsi nicio solicitare care să corespundă filtrelor dvs.",
        "FILTER_LABEL_PARTICIPANT": "Participant"
      },
      "DOCUMENTS": {
        "NAME_COLUMN_LABEL": "Nume",
        "FILE_NAME_COLUMN_LABEL": "Document",
        "FILE_SIZE_COLUMN_LABEL": "Mărimea",
        "CATEGORY_COLUMN_LABEL": "Categorie",
        "EXPIRY_DATE_COLUMN_LABEL": "Expiră",
        "OPTIONS_COLUMN_LABEL": "Opțiuni",
        "EXPIRED": "Expirată",
        "UPLOAD_NEW_LINK": "Încărcați nou",
        "NO_DOCS_MATCH_TEXT": "Nu am putut găsi documente care să corespundă filtrelor dvs.",
        "NO_CATEGORIES_TEXT": "Nu aveți categorii pentru documente care pot expira.",
        "FILTER_LABEL_SITE": "Site/departament",
        "FILTER_LABEL_APPOINTMENT": "Numire",
        "FILTER_LABEL_PERSON": "Persoană",
        "FILTER_LABEL_CATEGORY": "Categorie",
        "FILTER_LABEL_EXPIRY": "Expirare",
        "APPOINTMENT_STATUS_ACTIVE": "Activ",
        "APPOINTMENT_STATUS_INACTIVE": "Inactiv",
        "X_WEEKS_OR_LESS": " săptămâni sau mai puțin"
      },
      "NEW_JOINERS": {
        "NAME_COLUMN_LABEL": "Nume",
        "POSITION_COLUMN_LABEL": "Rolul postului",
        "ENTITY_COLUMN_LABEL": "Site-ul",
        "START_DATE_COLUMN_LABEL": "Începe",
        "OPTIONS_COLUMN_LABEL": "Opțiuni",
        "NO_STAFF_MATCH_TEXT": "Nu am putut găsi pe nimeni care să se potrivească filtrelor dvs.",
        "APPROVE_LINK": "Aproba",
        "FILTER_LABEL_APPOINTMENT": "Numire",
        "FILTER_LABEL_POSITION": "Rolul postului",
        "FILTER_LABEL_DATE_START": "Data începerii",
        "APPOINTMENT_STATUS_ACTIVE": "Activ",
        "APPOINTMENT_STATUS_INACTIVE": "Inactiv"
      }
    },
    "TAGS_GROUPS": {
      "TAGS_GROUPS": {
        "HEADING": "Etichete și grupuri",
        "ASIDE_TEXT": "Un membru al personalului poate aparține unui singur grup, dar li se pot atribui mai multe etichete. Grupurile sunt asemănătoare departamentului sau echipei cuiva, în timp ce etichetele pot reprezenta orice considerați semnificativ, cum ar fi abilitățile sau calitățile personale.",
        "CREATE_NEW_TAG_PLACEHOLDER": "Creați o etichetă nouă...",
        "ADDING_BUTTON_TEXT": "Adăugând...",
        "ADD_BUTTON_TEXT": "Adaugă",
        "TAG_HELP": "Faceți clic pe o etichetă pentru a o edita și pentru a vedea cine este atribuit:",
        "CREATE_ONE_TEXT": "Momentan nu aveți etichete. De ce să nu creezi unul?",
        "CREATE_NEW_GROUP_PLACEHOLDER": "Creați un grup nou...",
        "GROUPS_TITLE": "Grupuri",
        "EDIT_LINK_TEXT": "corectură"
      },
      "VIEW_GROUP_MODAL": {
        "EDIT_GROUP_TITLE": "Editați grupul",
        "SITE_LABEL": "Site-ul",
        "EDIT_TAB_HEADING": "Editați",
        "ORG_WIDE_LABEL": "La nivel de organizație",
        "GROUP_NAME_LABEL": "Numele grupului",
        "AVAILABILITY_LABEL": "Disponibilitate",
        "ALWAYS_AVAILABLE_TEXT": " Întotdeauna disponibil",
        "ALWAYS_AVAILABLE_HELP": "Personalul din acest grup este considerat întotdeauna disponibil pentru muncă, cu excepția cazului în care modelele de disponibilitate sau absența lor dictează altfel.",
        "ALWAYS_UNAVAILABLE_TEXT": " Întotdeauna indisponibil",
        "ALWAYS_UNAVAILABLE_HELP": "Personalul din acest grup nu este disponibil în mod implicit. Ei vor folosi „excepții” și „disponibilitate pentru o singură săptămână” pentru a marca când sunt gratuite.",
        "MEMBERS_TAB_HEADING": "Membri",
        "NO_ACTIVE_MEMBERS_HELP": "Nu există membri activi în acest grup. <br /><br />Personalul este repartizat într-un grup prin numirea lor. Găsiți pe cineva în lista de personal, faceți clic pe fila Programări și editați o întâlnire existentă sau adăugați una nouă.",
        "DELETE_TAB_HEADING": "Șterge",
        "MOVE_MEMBERS_TO_LABEL": "Mutați membrii la",
        "DELETE_TAB_ALERT": "Ștergerea acestui grup va determina persoanele {{totalMembers}} să se mute într-un alt grup. Acest lucru este ireversibil și nu poate fi anulat.",
        "CONFIRM_NAME_LABEL": "Tastați numele grupului",
        "CONFIRM_NAME_HELP_TEXT": "Pentru a ne convinge că doriți să faceți acest lucru, vă rugăm să introduceți numele grupului în casetă.",
        "DELETING_BUTTON_TEXT": "Ștergerea...",
        "DELETE_BUTTON_TEXT": "Șterge",
        "SAVING_BUTTON_TEXT": "Salvarea...",
        "SAVE_BUTTON_TEXT": "Salvați",
        "CANCEL_BUTTON_TEXT": "Anulează"
      },
      "VIEW_TAG_MODAL": {
        "EDIT_TAG_TITLE": "Editați eticheta",
        "EDIT_CLONE_LABEL": "Editare/clonare",
        "EDIT_EXISTING_TAG_TEXT": " Editați eticheta existentă",
        "CLONE_TAG_TEXT": " Clonați eticheta și salvați ca nouă",
        "TAG_NAME_LABEL": "Numele etichetei",
        "STAFF_MEMBERS_LABEL": "Membrii personalului",
        "TAG_TYPEAHEAD_PLACEHLDER": "Tastați pentru a eticheta personalul",
        "LAST_EDITED_LABEL": "Editat ultima dată",
        "ON_TEXT": "pe {{date|momentFormat: 'llll'}}",
        "DELETING_BUTTON_TEXT": "Ștergerea...",
        "DELETE_BUTTON_TEXT": "Șterge",
        "SAVING_BUTTON_TEXT": "Salvarea...",
        "SAVE_BUTTON_TEXT": "Salvați",
        "CLONING_BUTTON_TEXT": "Clonarea...",
        "CLONE_BUTTON_TEXT": "Clonă",
        "CANCEL_BUTTON_TEXT": "Anulează",
        "CLOSE_BUTTON_TEXT": "Închide"
      }
    },
    "ADD_NEW": {
      "TITLE": "Adăugați un angajat",
      "LABEL_FIRST_NAME": "Prenume",
      "LABEL_LAST_NAME": "Nume de familie",
      "LABEL_DOB": "Data nașterii",
      "LABEL_EMAIL": "Adresa de e-mail",
      "HELP_TEXT_EMAIL_OIDC": "Acest lucru trebuie să corespundă exact cu ceea ce angajatul folosește pentru serviciul dvs. de conectare unică. De obicei, se termină cu numele de domeniu al companiei.",
      "HELP_TEXT_EMAIL_ROTAREADY": "Toată lumea are un cont pe Rotaready și își folosește adresa de e-mail pentru a se conecta.",
      "LABEL_COPY_EXISTING": "Copiați un angajat existent",
      "PLACEHOLDER_COPY_EXISTING_NO_THANKS": "Nu mulțumesc",
      "HELP_TEXT_COPY_EXISTING": "Copiați programarea, indemnizația de vacanță și etichetele de la un alt angajat.",
      "LABEL_DATE_START": "Data începerii angajării",
      "LABEL_SITE": "Site/departament",
      "LABEL_PERMISSIONS": "Nivelul permisiunii",
      "LABEL_INVITE": "Invitare la Rotaready",
      "HELP_TEXT_INVITE_OIDC": "Trimiteți un link magic acestui angajat, care îi invită să se conecteze pentru prima dată.",
      "HELP_TEXT_INVITE_ROTAREADY": "Trimiteți un link magic acestui angajat, care îi invită să-și seteze parola și să se conecteze pentru a-și vedea schimburile.",
      "LABEL_ONBOARDING": "Onboarding",
      "CHECKBOX_ONBOARDING_OPT_IN": "Solicitați acestui angajat să finalizeze auto-onboard-ul atunci când se conectează pentru prima dată la Rotaready. În funcție de setările organizației dvs., li se va cere să furnizeze informații personale, cum ar fi adresa, contactele de urgență și documentația privind dreptul de a lucra.",
      "BUTTON_ADD": "Adaugă",
      "BUTTON_ADD_LOADING": "Adăugând...",
      "ALERT_SUCCESS": "Succes! Finalizați configurarea angajatului dvs. completând fiecare filă.",
      "ALERT_500": "Nu am putut adăuga angajatul chiar atunci. Vă rugăm să încercați din nou.",
      "ALERT_403": "Nu aveți permisiunea de a adăuga un nou angajat.",
      "ALERT_403_PERMISSION_LEVEL": "Nu aveți permisiunea de a adăuga pe cineva la acest nivel de permisiune.",
      "ALERT_403_COPY_EXISTING": "Nu aveți permisiunea de a copia acest angajat.",
      "ALERT_403_ENTITY": "Nu aveți permisiunea de a adăuga pe cineva la acest site/departament.",
      "DUPLICATE_MODAL": {
        "TITLE": "Angajații existenți",
        "DESCRIPTION": "Persoana pe care încercați să o adăugați partajează anumite atribute cu unul sau mai mulți angajați existenți. Dacă această persoană a lucrat anterior pentru organizația dvs., adăugați un nou loc de muncă.",
        "COLUMN_NAME": "Nume",
        "COLUMN_EMPLOYMENT": "Angajare",
        "COLUMN_SITE": "Site/departament",
        "COLUMN_SIMILARITIES": "asemănări",
        "COLUMN_OPTIONS": "Opțiuni",
        "STATUS_ACTIVE": "Activ",
        "STATUS_INACTIVE": "Inactiv",
        "OPTION_VIEW": "Vizualizați angajatul",
        "OPTION_NEW_EMPLOYMENT": "Noi locuri de muncă",
        "BUTTON_PROCEED": "Continuați oricum",
        "BUTTON_BACK": "Du-te înapoi",
        "SIMILARITY_NAME": "Nume",
        "SIMILARITY_EMAIL": "E-mailul",
        "SIMILARITY_MOBILE": "Număr de telefon mobil",
        "SIMILARITY_DOB": "Data nașterii"
      }
    }
  },
  "DIRECTIVES": {
    "STAFF": {
      "SEARCH": {
        "PLACEHOLDER": "Caută...",
        "AND_X_MORE": "Și {{x}} mai mult...",
        "INACTIVE_APPOINTMENT": "Inactiv"
      },
      "ADD_ROUTINE_MAPPING_FORM": {
        "STARTS_LABEL": "Începe",
        "STARTS_TEXT": "Prima zi a lui {{firstName}} în această rutină",
        "ENDS_INPUT_TEXT": " Se termină",
        "ENDS_TEXT": "Ultima zi a lui {{firstName}} în această rutină",
        "ONGOING_TEXT": "În curs",
        "SHIFT_ROUTINE_LABEL": "Schimbați rutina",
        "OFFSET_LABEL": "Decalaj",
        "OFFSET_HELP": "Acesta este numărul de zile în care {{firstName}} ar trebui să înceapă în acest model."
      },
      "AVAILABILITY_RULE": {
        "IM_UNAVAILABLE_TEXT": "Voi fi indisponibil",
        "CANCEL_TEXT": "anula",
        "AVAILABLE_TEXT": "DISPONIBIL",
        "UNAVAILABLE_TEXT": "INDISPONIBIL",
        "AVAILABLE_FROM_TEXT": "Sunt disponibil numai de la ",
        "UNAVAILABLE_FROM_TEXT": "Sunt indisponibil din ",
        "TO_TEXT": " la ",
        "ALL_DAY": "Toată ziua",
        "AM_ONLY": "Numai AM",
        "PM_ONLY": "Numai PM",
        "AVAIALABLE_CUSTOM": "Disponibil (personalizat)",
        "UNAVAILABLE_CUSTOM": "Indisponibil (personalizat)",
        "UNAVAILABLE": "Indisponibil",
        "AVAILABLE": "Disponibil",
        "AVAILABLE_AM": "Disponibil (numai AM)",
        "AVAILABLE_PM": "Disponibil (numai PM)"
      },
      "AUDIT_USER_WIDGET": {
        "EDITED_BY": "Editat de"
      },
      "VALIDATION_BULK_ABSENCE_EDIT": {
        "INFO_TRUNCATED": "Din cauza volumului de rezervări de absență afectate, acestea nu pot fi editate în bloc aici. Vă rugăm să utilizați fila Absență din profilul angajatului sau contactați Rotaready pentru asistență suplimentară.",
        "INFO_SUCCESS": "Nu există rezervări de absențe conflictuale rămase.",
        "ERROR_500": "Am întâmpinat o eroare neașteptată chiar atunci. Vă rugăm să încercați din nou.",
        "ERROR_400": "Această modificare nu poate fi făcută deoarece se suprapune cu o dată care a fost semnată.",
        "COLUMN_DATES": "Data (date)",
        "COLUMN_TYPE": "Tip",
        "COLUMN_HOURS": "Ore",
        "COLUMN_DAYS": "Zile",
        "COLUMN_STATUS": "Statut",
        "COLUMN_OPTIONS": "Opțiuni",
        "OPTION_CANCEL": "Anulează"
      },
      "VALIDATION_BULK_ATTENDANCE_EDIT": {
        "INFO_TRUNCATED": "Din cauza volumului de participare afectat, acestea nu pot fi editate în bloc aici. Vă rugăm să utilizați fila Prezență din profilul angajatului sau contactați Rotaready pentru asistență suplimentară.",
        "INFO_SUCCESS": "Nu au mai rămas evenimente de prezență conflictuale.",
        "ERROR_500": "Am întâmpinat o eroare neașteptată chiar atunci. Vă rugăm să încercați din nou.",
        "ERROR_400": "Această modificare nu poate fi făcută deoarece coincide cu o dată care a fost semnată.",
        "COLUMN_EVENT": "Evenimentul",
        "COLUMN_DATE": "Data",
        "COLUMN_OPTIONS": "Opțiuni",
        "EVENT_TYPE_CLOCK_IN": "Clock-in",
        "OPTION_DELETE": "Șterge"
      },
      "VALIDATION_BULK_ROUTINE_MAPPING_EDIT": {
        "INFO_TRUNCATED": "Datorită volumului de mapări de rutină de schimbare afectate, acestea nu pot fi editate în bloc aici. Vă rugăm să utilizați fila Rutine de schimbare din profilul angajatului sau contactați Rotaready pentru asistență suplimentară.",
        "INFO_SUCCESS": "Nu au mai rămas mapări conflictuale de rutină de schimbare.",
        "ERROR_500": "Am întâmpinat o eroare neașteptată chiar atunci. Vă rugăm să încercați din nou.",
        "ERROR_400": "Această modificare nu poate fi făcută deoarece se suprapune cu o dată care a fost semnată.",
        "COLUMN_START_DATE": "Data începerii",
        "COLUMN_END_DATE": "Data de încheiere",
        "COLUMN_SHIFT_ROUTINE": "Schimbați rutina",
        "COLUMN_OPTIONS": "Opțiuni",
        "LABEL_OPEN_ENDED": "Deschis",
        "OPTION_ADD_END_DATE": "Adăugați data de încheiere",
        "OPTION_DELETE": "Șterge"
      },
      "VALIDATION_BULK_SHIFT_EDIT": {
        "INFO_TRUNCATED": "Din cauza volumului schimburilor afectate, acestea nu pot fi editate în bloc aici. Vă rugăm să modificați rutele corespunzătoare sau să contactați Rotaready pentru asistență suplimentară.",
        "INFO_SUCCESS": "Nu au mai rămas schimbări conflictuale.",
        "ERROR_500": "Am întâmpinat o eroare neașteptată chiar atunci. Vă rugăm să încercați din nou.",
        "ERROR_400": "Această schimbare nu poate fi făcută. S-ar putea suprapune cu o dată care a fost semnată. Pentru a afla mai multe, încercați acest lucru pe rota în sine.",
        "BUTTON_UNASSIGN_SELECTED": "Anulați atribuirea selecției",
        "BUTTON_DELETE_SELECTED": "Ștergeți selecția",
        "COLUMN_DATE": "Data",
        "COLUMN_TIME": "Timpul",
        "COLUMN_DEPARTMENT": "Divizie",
        "COLUMN_TYPE": "Tip",
        "COLUMN_STATUS": "Statut",
        "COLUMN_OPTIONS": "Opțiuni",
        "STATUS_PUBLISHED": "Publicat",
        "STATUS_DRAFT": "Proiect",
        "OPTION_UNASSIGN": "Anulați atribuirea",
        "OPTION_DELETE": "Șterge"
      },
      "VALIDATION_EPHEMERAL_RECORDS": {
        "LABEL_OPEN_ENDED": "(deschis)",
        "LABEL_NEW": "Nou",
        "LABEL_EXISTING": "Existentă",
        "EMPLOYMENT": "Angajare",
        "APPOINTMENT": "Numire"
      },
      "ACCOUNT_COMPLETION_TRACKER": {
        "CONSTITUENT_PERSONAL": "Personale",
        "CONSTITUENT_EMPLOYMENT": "Angajare",
        "CONSTITUENT_PAY": "Plătiți",
        "CONSTITUENT_HOLIDAY_ALLOWANCE": "Indemnizație de concediu"
      }
    },
    "CALENDAR": {
      "TIP": "Pentru a solicita absența, faceți clic pe o zi sau faceți clic și trageți pentru a selecta mai multe zile simultan.",
      "TODAY": "Astăzi",
      "MONTH": "Lună",
      "WEEK": "Săptămâna",
      "DAY": "Zi"
    },
    "HIERARCHY_ITEM_PICKER": {
      "PICKER": "Alegător",
      "PLACEHOLDER": "Nu este selectat {{itemDescriptor}}",
      "FILTER_TO": "Filtrează la...",
      "RESET": "Resetare",
      "REMOVED_BY_FILTER": "{{itemDescriptor}} selectat a fost eliminat de filtru.",
      "NO_SELECTED": "Nu este selectat {{itemDescriptor}}"
    },
    "SUBSCRIBE_BUTTON": {
      "LABEL_ETID_1": "solicitați",
      "LABEL_ETID_2": "anulat",
      "LABEL_ETID_3": "aprobat",
      "LABEL_ETID_4": "refuzat",
      "LABEL_ETID_5": "marcat pentru omologare",
      "LABEL_ETID_6": "publicată",
      "LABEL_ETID_7": "Revenit la draft",
      "LABEL_ETID_8": "Săptămâna este semnată",
      "LABEL_ETID_9": "în așteptarea aprobării",
      "LABEL_ETID_10": "aprobat",
      "LABEL_ETID_11": "solicitați",
      "LABEL_ETID_13": "aprobat",
      "LABEL_ETID_14": "refuzat",
      "LABEL_ETID_15": "anulat",
      "LABEL_ETID_16": "se apropie de expirare",
      "LABEL_ETID_17": "expirată",
      "LABEL_ETID_29": "a început",
      "LABEL_ETID_30": "Acceptat de cineva",
      "LABEL_ETID_31": "respins de cineva",
      "LABEL_ETID_32": "finalizată",
      "LABEL_ETID_36": "Săptămâna este inversată",
      "LABEL_ETID_37": "ziua este semnată",
      "LABEL_ETID_38": "Ziua este inversată",
      "DESCRIPTOR_ABSENCE": "Cererea de absenţă este",
      "DESCRIPTOR_ROTA": "o rotație este",
      "DESCRIPTOR_ACCOUNT": "Un angajat este",
      "DESCRIPTOR_SWAP": "un schimb de schimburi este",
      "DESCRIPTOR_COST_CONTROL": "un control al costurilor",
      "DESCRIPTOR_DOCUMENT": "Un document este",
      "DESCRIPTOR_BROADCAST": "o transmisie în schimburi este",
      "NOUN_ABSENCE": "rezervarea absenței",
      "NOUN_ROTA": "roata",
      "NOUN_ACCOUNT": "angajat",
      "NOUN_SWAP": "schimb de schimburi",
      "NOUN_COST_CONTROL": "înregistrarea controlului costurilor",
      "NOUN_DOCUMENT": "a documenta",
      "NOUN_BROADCAST": "transmisie în schimburi",
      "NOTIFY_SENTENCE_EXORDIUM": "Anunță-mă când",
      "NOTIFY_SENTENCE_TRAILING_IN": "în...",
      "UNSUBSCRIBE": "Dezabonare",
      "SUBSCRIBE": "Aboneaza-te",
      "SEARCH_PLACEHOLDER": "Caută...",
      "BACK_LINK": "Înapoi",
      "RESOURCE_SUBSCRIBE_TOOLTIP": "Fiți notificați cu privire la modificările aduse acestui {{name}}",
      "RESOURCE_UNSUBSCRIBE_TOOLTIP": "Dezabonați-vă de la modificările aduse acestui {{name}}"
    },
    "ROTA": {
      "DASHBOARD_WEEK": {
        "VIEW_ROTA": "Vizualizați rota",
        "LAST_WEEK": "Săptămâna trecută",
        "THIS_WEEK": "Săptămâna aceasta",
        "NO_ATTENDANCE_ISSUES": "Fără probleme de prezență",
        "ATTENDANCE_ISSUES": "problemă (probleme) de prezență",
        "RESOLVE": "Rezolva",
        "ACTUAL_SALES_ENTERED": "Cifrele reale de vânzări introduse",
        "ACTUAL_SALES_MISSING": "Cifrele <strong>reale de vânzări</strong> lipsesc",
        "WTD_COMPLIANT": "În conformitate cu reglementările privind timpul de lucru",
        "VIEW": "Vizualizare",
        "WTD_VIOLATIONS": "Încălcarea timpului de lucru",
        "SIGN_OFF_NOT_COMPLETED": "<strong>Deconectarea</strong> nu este încă finalizată",
        "SIGN_OFF": "Deconectați-vă",
        "WEEK_SIGNED_OFF": "Săptămâna semnată",
        "ABSENCE_REQUEST": "Cereri de absență",
        "ADDED_SALES_FORECAST": "Prognoza vânzărilor adăugată",
        "DRAFTED_ROTA": "Rota redactată",
        "MARKED_FOR_APPROVAL": "Rota marcată pentru omologare",
        "PUBLISHED": "Rota publicată",
        "OUTSTANDING": "{{absențe}} restante",
        "NOT_ADDED": "Nu a fost adăugat",
        "NOT_DRAFTED": "Nu a fost redactat",
        "NOT_DONE": "Nu a fost făcut",
        "NOT_PUBLISHED": "Nu a fost publicat"
      }
    },
    "SIDEBAR": {
      "NOTIFICATION_POPOVER": {
        "NO_NOTIFICATIONS": "Fără notificări!",
        "MARK_ALL_READ": "Marchează toate citite"
      },
      "SETTINGS_POPOVER": {
        "TIMEZONE": "Acest fus orar este diferit de fusul orar local.",
        "EDIT_PREFERENCES": "Editați preferințele",
        "MANAGE_SUBSCRIPTIONS": "Gestionați abonamentele",
        "SHOW_INTERCOM": "Afișați chat-ul de asistență",
        "HIDE_INTERCOM": "Ascundeți chat-ul de asistență",
        "SIGN_OUT": "Deconectați-vă"
      }
    },
    "DATE_PICKER": {
      "CALENDAR": "Calendarul",
      "GO_TO_TODAY": "Du-te la azi"
    },
    "ENTITY_SELECTOR": {
      "ORG_WIDE": "La nivel de organizație",
      "NO_OPTIONS": "Fără opțiuni",
      "SEARCH_PLACEHOLDER": "Caută..."
    },
    "FILTER_OPTIONS_BUTTON": {
      "IS": "este",
      "AND": "și",
      "OR": "sau",
      "ANY": "Oricare",
      "SEARCH_PLACEHOLDER": "Caută...",
      "NO_OPTIONS": "Fără opțiuni",
      "SELECT_ALL": "Selectați toate",
      "CLEAR_SELECTED": "Ștergeți selecția"
    },
    "FILTER_STAFF_SEARCH_BUTTON": {
      "IS": "este",
      "ANY": "Oricare",
      "RESET": "Resetare"
    },
    "FILTER_DATE_BUTTON": {
      "ANY": "orice",
      "BEFORE": "înainte",
      "AFTER": "după",
      "BETWEEN": "între",
      "IS": "este",
      "AND": "și",
      "DONE": "Terminat"
    },
    "FILTER_NUMBER_BUTTON": {
      "ANY": "este oricare",
      "GREATER": "este mai mare decât",
      "LESS": "este mai mică decât",
      "EXACTLY": "este exact",
      "DONE": "Terminat"
    },
    "VALIDATION_FAILURE": {
      "TITLE": "Probleme de validare",
      "ISSUE": "problemă",
      "ISSUES": "probleme",
      "CHANGE_MESSAGE": "Schimbarea pe care încerci să o faci a dus la ",
      "IGNORE_CONTINUE": "Ignorați și continuați oricum",
      "IGNORE_PROBLEM": "Ignorați schimbările de probleme și încercați din nou",
      "LOADING": "Se încarcă..."
    },
    "PICK_LIST": {
      "NO_OPTIONS": "Fără opțiuni",
      "SEARCH_PLACEHOLDER": "Caută...",
      "DEFAULT_NULL_OPTION_LABEL": "Niciuna"
    },
    "FILE_UPLOAD": {
      "ALERT_UNSUPPORTED_FILE_TYPE": "Din păcate, acest tip de fișier nu este acceptat.",
      "ALERT_FILE_SIZE": "Din păcate, acest fișier este prea mare.",
      "LABEL_ACCEPTED_FILE_TYPES": "Tipuri de fișiere acceptate",
      "LABEL_LIMIT": "a restricționa",
      "INFO_DROP_AVAILABLE_PART_1": "Glisați și fixați un fișier aici sau",
      "INFO_DROP_AVAILABLE_PART_2": "faceți clic pentru a răsfoi",
      "INFO_DROP_NOT_AVAILABLE": "Faceți clic pentru a căuta un fișier",
      "INFO_DRAG_ACTIVE": "Obiectiv frumos, timpul să renunți!",
      "INFO_UPLOAD_FAILED": "Încărcarea a eșuat, încercați din nou"
    }
  },
  "AVAILABILITY": {
    "ABSENCE": {
      "TAB_ABSENCE": "Rezervări de absență",
      "TAB_ALLOWANCES": "Indemnizații"
    },
    "WEEKLY_AVAILABILITY_MODAL": {
      "ADD_TITLE": "Adăugați disponibilitate săptămânală",
      "EDIT_TITLE": "Editați disponibilitatea săptămânală",
      "NICKNAME": "Poreclă",
      "PLACEHOLDER": "adică. În timp ce studiezi",
      "HELP": "Ar trebui să numiți acest model de disponibilitate după ceva semnificativ, cum ar fi „În timp ce studiați”, dacă acesta reprezintă momentul în care puteți lucra în timp ce studiați pentru examene sau „Vacanță de vară” pentru când sunteți liber de cele mai multe ori, de exemplu.",
      "STARTING_FROM": "Începând de la",
      "NEVER_ENDING": "Nu se termină niciodată",
      "YES_PATTERN_FOREVER": "Da, lăsați acest model să continue pentru totdeauna",
      "NO_PATTERN_DATE": "Nu, există o dată când aș vrea ca acest model să se oprească",
      "STOP_DATE": "Data opririi",
      "PATTERN_APPLIES": "Modelul se aplică",
      "EVERY": "Fiecare",
      "WEEK": "Săptămâna",
      "SAVING_BUTTON_TEXT": "Salvarea...",
      "SAVE_BUTTON_TEXT": "Salvați",
      "CANCEL": "Anulează"
    },
    "WEEKLY_AVAILABILITY_MODAL_SINGLE": {
      "TITLE": "Adăugați disponibilitate unică",
      "SUBTITLE": "Pentru o singură săptămână",
      "WEEK_BEGINS": "Săptămâna începe",
      "HELP": "Aceasta este săptămâna pentru care doriți să vă marcați disponibilitatea.",
      "SAVING_BUTTON_TEXT": "Salvarea...",
      "SAVE_BUTTON_TEXT": "Salvați",
      "CANCEL": "Anulează"
    },
    "AVAILABILITY": {
      "ADD_AVAILABILITY": "Adaugă disponibilitate",
      "NEW_WEEKLY_PATTERN": "Nou model săptămânal",
      "NEW_MONTHLY_PATTERN": "Nou model lunar",
      "NEW_CUSTOM_PATTERN": "Nou model personalizat",
      "ONE_OFF_PATTERN": "O singură dată (o singură săptămână)",
      "AVAILABILITY": "Disponibilitate",
      "AVAILABILITY_HELP": "Gestionați disponibilitatea aici prin tiparele de disponibilitate; gândiți-vă la acestea ca la evenimente din calendar recurente.",
      "AVAILABILITY_TIP": "Utilizați un model lunar pentru a viza date precum „prima miercuri” sau „ultima sâmbătă” a fiecărei luni.",
      "NICKNAME": "Poreclă",
      "APPLIES_FROM_TO": "Se aplică de la (/la)",
      "OPTIONS": "Opțiuni",
      "NO_AVAILABILITY_PATTERNS": "Nu există modele de disponibilitate. Adăugați unul folosind butoanele de mai sus.",
      "TURN_OFF": "Opriți",
      "TURN_ON": "Porniți",
      "NO_NICKNAME": "Fără poreclă",
      "WEEKLY": "Săptămânal",
      "MONTHLY": "Lunar",
      "CUSTOM": "Personalizat",
      "EDIT": "Editați",
      "TOGGLE_DROPDOWN": "Comutați meniul derulant",
      "DELETE": "Șterge",
      "CONFIRM_DELETE_PATTERN": "Ești sigur? Ștergerea acestui model de disponibilitate nu poate fi anulată.",
      "DELETE_PATTERN_SUCCESS": "Succes! Modelul dvs. de disponibilitate a fost șters.",
      "ERROR_DELETING_PATTERN": "Nu am putut șterge acest model chiar acum. Vă rugăm să încercați din nou.",
      "ERROR_TOGGLING_PATTERN": "Nu am putut porni/opri acest model chiar acum. Vă rugăm să încercați din nou.",
      "ERROR_LOADING_PATTERNS": "Nu am putut încărca tiparele de disponibilitate. Vă rugăm să încercați din nou."
    },
    "DAILY_AVAILABILITY_MODAL": {
      "ADD_CUSTOM_AVAILABILITY": "Adăugați disponibilitate personalizată",
      "EDIT_CUSTOM_AVAILABILITY": "Editați disponibilitatea personalizată",
      "NICKNAME": "Poreclă",
      "NICKNAME_PLACEHOLDER": "adică. În timp ce studiezi",
      "NICKNAME_HELP": "Ar trebui să numiți acest model după ceva semnificativ.",
      "STARTING_FROM": "Începând de la",
      "NEVER_ENDING": "Nu se termină niciodată",
      "CONTINUE_FOREVER": "Da, lăsați acest model să continue pentru totdeauna",
      "PATTERN_STOP": "Nu, există o dată când aș vrea ca acest model să se oprească",
      "STOP_DATE": "Data opririi",
      "PATTERN_DAYS": "Zile tiparului",
      "PATTERN_NO_DAYS": "Acest model nu are zile. Adăugați unul folosind butonul de mai jos.",
      "DAY_NUMBER": "Ziua {{number}}",
      "ILL_BE": "Voi fi",
      "DELETE": "Șterge",
      "ADD_DAY": "Adaugă ziua",
      "SAVE": "Salvați",
      "SAVE_LOADING": "Salvarea...",
      "CANCEL": "Anulează",
      "UNAVAILABLE": "Indisponibil",
      "AVAILABLE": "Disponibil",
      "AVAILABLE_AM": "Disponibil (numai AM)",
      "AVAILABLE_PM": "Disponibil (numai PM)",
      "OTHER": "Altele (specificați...)",
      "MINIMUM_TWO_DAYS": "Trebuie să aveți cel puțin două zile în acest model.",
      "STOPS_BEFORE_STARTS": "Modelul dvs. este setat în prezent să se oprească înainte de a începe!",
      "AVAILABILITY_END_BEFORE_START": "Una sau mai multe reguli de disponibilitate au o oră de încheiere care se încadrează înainte de ora de începere.",
      "ERROR_SAVING_PATTERN": "Momentan nu se poate salva acest model de disponibilitate. Vă rugăm să încercați din nou.",
      "ERROR_UPDATING_PATTERN": "Momentan nu se poate actualiza acest model de disponibilitate. Vă rugăm să încercați din nou."
    },
    "EDIT_PATTERN_EXCEPTIONS_MODAL": {
      "EDIT_EXCEPTIONS": "Editați excepțiile",
      "EXCEPTIONS_TIP": "Modelele dvs. de disponibilitate marchează momentul în care nu sunteți liber să lucrați. Cu toate acestea, puteți utiliza calendarul pentru a ne spune că sunteți liber să lucrați într-o zi pe care în mod normal nu ați putea să o faceți. Pur și simplu faceți clic pe marcajul roșu „indisponibil” și veți putea face o excepție.",
      "EXCEPTIONS": "Lista de mai jos prezintă excepțiile pe care le-ați făcut la modelul dvs. de disponibilitate. Considerăm că sunteți liber să lucrați în aceste zile:",
      "DATE": "Data",
      "OPTIONS": "Opțiuni",
      "UNDO": "Anulează",
      "NO_EXCEPTIONS": "Nu ai făcut încă nicio excepție.",
      "CLOSE": "Închide",
      "ERROR_DELETING_EXCEPTION": "Din păcate, nu am reușit să ștergem excepția chiar atunci. Vă rugăm să încercați din nou."
    },
    "MONTHLY_AVAILABILITY_MODAL": {
      "ADD_MONTHLY_AVAILABILITY": "Adăugați disponibilitate lunară",
      "EDIT_MONTHLY_AVAILABILITY": "Editați disponibilitatea lunară",
      "NICKNAME": "Poreclă",
      "NICKNAME_PLACEHOLDER": "adică. În timp ce studiezi",
      "NICKNAME_HELP": "Ar trebui să numiți acest model de disponibilitate după ceva semnificativ, cum ar fi „În timp ce studiați”, dacă acesta reprezintă momentul în care puteți lucra în timp ce studiați pentru examene sau „Vacanță de vară” pentru când sunteți liber de cele mai multe ori, de exemplu.",
      "STARTING_FROM": "Începând de la",
      "NEVER_ENDING": "Nu se termină niciodată",
      "CONTINUE_FOREVER": "Da, lăsați acest model să continue pentru totdeauna",
      "PATTERN_STOP": "Nu, există o dată când aș vrea ca acest model să se oprească",
      "STOP_DATE": "Data opririi",
      "PATTERN_RULES": "Reguli de model",
      "PATTERN_NO_RULES": "Acest model nu are reguli. Adăugați unul folosind butonul de mai jos.",
      "EVERY": "Fiecare",
      "ON_THE": "pe",
      "DELETE": "Șterge",
      "ADD_RULE": "Adăugați o regulă",
      "SAVE": "Salvați",
      "SAVE_LOADING": "Salvarea...",
      "CANCEL": "Anulează",
      "ORDINAL_LAST": "ultimul",
      "ORDINAL_FIRST": "în primul rând",
      "ORDINAL_SECOND": "al doilea",
      "ORDINAL_THIRD": "a treia",
      "ORDINAL_FOURTH": "a patra",
      "ORDINAL_FIFTH": "a cincea",
      "MONDAY": "Luni",
      "TUESDAY": "Marţi",
      "WEDNESDAY": "Miercuri",
      "THURSDAY": "Joi",
      "FRIDAY": "Vineri",
      "SATURDAY": "sâmbătă",
      "SUNDAY": "Duminică",
      "AVAILABLE": "Disponibil",
      "AVAILABLE_AM": "Numai AM",
      "AVAILABLE_PM": "Numai PM",
      "MONTH": "lună",
      "MONTHS": "luni",
      "OTHER": "Altele (specificați...)",
      "STOPS_BEFORE_STARTS": "Modelul dvs. este setat în prezent să se oprească înainte de a începe!",
      "AVAILABILITY_END_BEFORE_START": "Una sau mai multe reguli de disponibilitate au o oră de încheiere care se încadrează înainte de ora de începere.",
      "ERROR_SAVING_PATTERN": "Momentan nu se poate salva acest model de disponibilitate. Vă rugăm să încercați din nou.",
      "ERROR_UPDATING_PATTERN": "Momentan nu se poate actualiza acest model de disponibilitate. Vă rugăm să încercați din nou."
    },
    "OVERVIEW": {
      "SCHEDULE": "Programul meu",
      "SCHEDULE_HELP": "Acesta este programul dvs. complet, inclusiv schimburile care vi s-au atribuit, absența aprobată și disponibilitatea.",
      "SHIFTS": "Schimburi",
      "ABSENCE": "Absența",
      "UNAVAILABLE": "Indisponibil (modele)",
      "EDIT_EXCEPTIONS": "Editați excepțiile",
      "ICAL_FEED": "iCalendar Feed",
      "ICAL_FEED_HELP": "Un feed iCalendar vă va permite să vă vizualizați programul în afara Rotaready.",
      "ICAL_FEED_TIP": "După ce ați generat o adresă URL a fluxului iCalendar, o puteți copia și lipi în <a href=\"https://support.apple.com/en-gb/HT202361\" target=\"_blank\">Apple Calendar, Google Calend</a> <a href=\"https://support.google.com/calendar/answer/37100\" target=\"_blank\">ar sau orice alt software de calendar</a>.",
      "COPIED": "Copiat în clipboard!",
      "GENERATE": "Genera"
    }
  },
  "ATTENDANCE": {
    "VALIDATION_CODES": {
      "CODE_1": "Data corespunzătoare a fost semnată, deci nu putem face nicio modificare.",
      "CODE_2": "Acest lucru ar duce la o secvență nevalidă de evenimente. De exemplu, un ceas trebuie să vină după un clock-in, dar nu poate veni înainte de un alt ceas. Verificați evenimentele din jur, apoi încercați din nou.",
      "CODE_3": "Site-ul/departamentul ales este invalid.",
      "CODE_4": "Nu putem adăuga un ceas aici, deoarece angajatul ales nu are o programare la această dată.",
      "CODE_5": "Nu putem adăuga un eveniment aici, deoarece există deja un eveniment existent în același timp.",
      "CODE_6": "Angajatul ales este invalid.",
      "CODE_7": "Această schimbare ar muta evenimentul într-un moment mai devreme decât evenimentul care vine înaintea acestuia. Încercați să alegeți o altă oră sau să editați mai întâi evenimentul anterior.",
      "CODE_8": "Această modificare ar muta evenimentul într-un moment mai târziu decât evenimentul care îl urmează. Încercați să alegeți o altă oră sau să editați mai întâi evenimentul ulterior."
    },
    "COMMON": {
      "EVENT_ADD_SUCCESS": "Evenimentul a fost adăugat cu succes.",
      "ABSENCE_ADD_SUCCESS": "Au fost marcate ca absenți.",
      "NO_CLOCK_IN_MODAL_TITLE": "Adaugă eveniment",
      "NO_CLOCK_IN_MODAL_MESSAGE": "Ce ți-ar plăcea să faci?",
      "NO_CLOCK_IN_MODAL_ADD_EVENT": "Adaugă ceas",
      "NO_CLOCK_IN_MODAL_ADD_EVENT_DESC": "Adăugarea manuală a unei înregistrări de prezență",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE": "Marcă absent",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC": "Adăugați o înregistrare de absență, cum ar fi boala",
      "UNMATCHED_MODAL_TITLE": "Prezență de neegalat",
      "UNMATCHED_MODAL_MESSAGE": "Există două moduri în care puteți rezolva prezența de neegalat:",
      "UNMATCHED_MODAL_ADD_SHIFT": "Adaugă schimbare",
      "UNMATCHED_MODAL_ADD_SHIFT_DESC": "Creați o nouă schimbare pentru a reflecta ceea ce a funcționat",
      "UNMATCHED_MODAL_EDIT_EVENT": "Editați prezența",
      "UNMATCHED_MODAL_EDIT_EVENT_DESC": "Ajustați înregistrările de prezență dacă acestea nu sunt corecte",
      "OVERTIME_MODAL_TITLE": "Examinați orele suplimentare",
      "OVERTIME_MODAL_MESSAGE_IN": "{{name}} a intrat devreme și tura a corespuns unei reguli de plată. Ce ți-ar plăcea să faci?",
      "OVERTIME_MODAL_MESSAGE_OUT": "{{name}} s-a terminat cu întârziere, iar tura a corespuns unei reguli de plată. Ce ți-ar plăcea să faci?",
      "OVERTIME_MODAL_APPROVE": "Aprobați orele suplimentare",
      "OVERTIME_MODAL_APPROVE_DESC": "{{name}} va fi plătit pentru {{durata}} minute suplimentare",
      "OVERTIME_MODAL_REJECT": "Respingeți orele suplimentare",
      "OVERTIME_MODAL_REJECT_DESC": "{{name}} nu va fi plătit pentru orele suplimentare lucrate",
      "OVERTIME_UPDATE_ERROR": "Nu putem actualiza evenimentul chiar acum. Vă rugăm să încercați din nou.",
      "MODAL_USER_NOTES_LABEL": "Notițe de la {{name}}"
    },
    "BY_STAFF": {
      "FILTER_LABEL_DATE": "Data",
      "MANUAL_CLOCK_IN": "Ceasuri manuale",
      "EVENT_TYPE": "Evenimentul",
      "RECORDED_TIME": "Timp înregistrat",
      "METHOD": "Capturat de",
      "OPTIONS": "Opțiuni",
      "TRUSTED_DEVICE": "Dispozitiv de încredere",
      "MOBILE_APP": "Aplicația Rotaready",
      "MANUALLY_OVERRIDDEN": "Remediat manual",
      "MANUAL_ENTRY": "Introducere manuală",
      "DELETE": "Șterge",
      "NO_EVENTS": "Nu există evenimente de prezență de afișat.",
      "CLOCK_IN": "Ceas în",
      "CLOCK_OUT": "Opriți ceasul",
      "BREAK_ON": "În pauză",
      "BREAK_OFF": "Pauză de oprire",
      "ERROR_LOADING_EVENTS": "Nu putem încărca prezența chiar acum. Vă rugăm să încercați din nou.",
      "ERROR_DELETING": "Nu putem șterge prezența chiar acum. Vă rugăm să încercați din nou.",
      "DELETE_SUCCESS": "Evenimentul a fost șters cu succes.",
      "ADD_SUCCESS": "Evenimentul a fost adăugat cu succes.",
      "LABEL_EDITED": "Editat"
    },
    "ISSUES": {
      "TITLE": "Probleme de prezență",
      "FILTER_DATE": "Data",
      "FILTER_ISSUE_TYPE": "Tipul problemei",
      "FILTER_POSITION": "Rolul postului",
      "FILTER_USER": "Persoană",
      "ISSUES_HELP": "Problemele ar trebui rezolvate, altfel vor cauza discrepanțe în rapoarte și salarizare. În mod implicit, Rotaready semnalizează problemele din ultimele {{defaultIssuePeriod}} zile.",
      "IS_THIS": "Este aceasta",
      "YES": "da",
      "NO": "Nu",
      "HEADING_NAME": "Nume",
      "HEADING_SITE": "Site-ul",
      "HEADING_ISSUE": "Problema",
      "HEADING_SHIFT": "Schimbă",
      "HEADING_START": "Începe",
      "HEADING_FINISH": "Finisează",
      "HEADING_CLOCK_IN": "Clock-in",
      "HEADING_CLOCK_OUT": "Clock-out",
      "HEADING_OPTIONS": "Opțiuni",
      "NOT_FOUND": "Nu a fost găsit",
      "RESOLVE": "Rezolva",
      "DISMISS": "Respinge",
      "NO_ATTENDANCE_ISSUES": "Nu aveți probleme de prezență, bine făcut!",
      "UNMATCHED_ATTENDANCE": "Prezență de neegalat",
      "NO_CLOCK_IN": "Fără ceas",
      "NO_CLOCK_OUT": "Fără ceas",
      "SUSPECT_CLOCK_OUT": "Ceasul suspectului",
      "SUSPECT_ATTENDANCE": "Prezență suspectă",
      "BREAK_NOT_TAKEN": "Pauza nu a fost luată",
      "INVALID_BREAKS": "Pauză (pauze) nevalide",
      "SUSPECT_BREAK": "Pauză suspectă",
      "EARLY_CLOCK_IN": "Clock-in timpuriu",
      "LATE_CLOCK_OUT": "Ora târzie",
      "UNMATCHED_ATTENDANCE_EXPLANATION": "Rotaready nu a putut găsi o schimbare care să potrivească această prezență",
      "NO_CLOCK_IN_EXPLANATION": "Membrul personalului nu a intrat în tura lor",
      "NO_CLOCK_OUT_EXPLANATION": "Membrul personalului nu a plecat după tura lor",
      "SUSPECT_CLOCK_OUT_EXPLANATION": "Membrul personalului a plecat mult mai târziu decât ora de finalizare programată",
      "SUSPECT_ATTENDANCE_EXPLANATION": "Membrul personalului a fost angajat pentru o perioadă mult mai scurtă de timp decât era programată",
      "BREAK_NOT_TAKEN_EXPLANATION": "Schimbarea are o pauză, dar una nu a fost luată",
      "INVALID_BREAKS_EXPLANATION": "Pauza (pauzele) pentru această prezență este incompletă (adică lipsește o pauză)",
      "SUSPECT_BREAK_EXPLANATION": "Membrul personalului a fost în pauză mult mai mult decât era programat",
      "EARLY_CLOCK_IN_EXPLANATION": "Schimbul se potrivește cu o regulă de plată care necesită aprobarea pentru un ceas timpuriu în",
      "LATE_CLOCK_OUT_EXPLANATION": "Schimbul se potrivește cu o regulă de plată care necesită aprobarea pentru o pauză târzie",
      "ERROR_LOADING_ATTENDANCE": "Nu vă putem încărca problemele de prezență chiar acum. Vă rugăm să încercați din nou.",
      "SOMETHING_WENT_WRONG": "Hopa, ceva nu a mers bine. S-ar putea să trebuiască să încercați din nou!",
      "CONFIRM_DISMISS": "Sunteți sigur că doriți să respingeți această problemă de prezență?",
      "DATE_RANGE_TOO_WIDE": "Nu puteți alege mai mult de 5 săptămâni. Vă rugăm să restricționați intervalul de date.",
      "CLOCK_OUT_DAYS_LATER": "Acest ceas este la una sau mai multe zile după ceas."
    },
    "OVERVIEW": {
      "UNMATCHED_LABEL": "{{count}} neegalat",
      "OPTIONS_DAILY": "Zilnic",
      "OPTIONS_WEEKLY": "Săptămânal",
      "OPTIONS_DAY_ROLL_OVER": "Răsturnare de zi",
      "FILTER_POSITION": "Rolul postului",
      "FILTER_USER": "Persoană",
      "MIDNIGHT": "miezul nopții",
      "TOOLS": "Unelte",
      "CURRENTLY_CLOCKED_IN": "Persoanele vizate în prezent",
      "ATTENDANCE_HELP": "Prezența este asociată automat cu schimburile în cazul în care are loc o oră de începere sau de sfârșit programată în <b>{{AutoMatchThreshold}} ore (e) de la ora</b> de începere sau de sfârșit programată.",
      "MATCHED_HEADING_SCHEDULED": "Programat",
      "MATCHED_HEADING_ACTUAL": "Actuala",
      "MATCHED_HEADING_AFTER_SANITISATION": "După igienizare",
      "MATCHED_HEADING_NAME": "Nume",
      "MATCHED_HEADING_SITE": "Site-ul",
      "MATCHED_HEADING_START": "Începe",
      "MATCHED_HEADING_FINISH": "Finisează",
      "MATCHED_HEADING_PAID_HOURS": "Ore plătite",
      "MATCHED_HEADING_UNPAID_HOURS": "Ore neplătite",
      "COVER_SHIFT": "Schimbarea capacului",
      "NO_CLOCK_IN": "Niciun ceas încă",
      "NO_CLOCK_OUT": "Încă nu există ceas",
      "OVERTIME_APPROVED": "Aprobat",
      "OVERTIME_REJECTED": "Respins",
      "OVERTIME_REVIEW": "Recenzie",
      "OVERTIME_APPROVED_BY": "Orele suplimentare aprobate de {{name}}",
      "OVERTIME_REJECTED_BY": "Orele suplimentare respinse de {{name}}",
      "MATCHED_NO_RESULTS": "Nu există schimbări de arătat.",
      "UNMATCHED_ATTENDANCE": "Prezență de neegalat",
      "UNMATCHED_ATTENDANCE_HELP": "Dacă prezența nu se încadrează în <b>{{AutoMatchThreshold}} ore de la ora de înce</b> pere/terminare a unei ture, va trebui să ajustați manual tura sau înregistrările de prezență pentru a le aduce în linie:",
      "UNMATCHED_HEADING_NAME": "Nume",
      "UNMATCHED_HEADING_SITE": "Site-ul",
      "UNMATCHED_HEADING_DATE": "Data",
      "UNMATCHED_HEADING_CLOCK_IN": "Ceas în",
      "UNMATCHED_HEADING_CLOCK_OUT": "Opriți ceasul",
      "UNMATCHED_HEADING_OPTIONS": "Opțiuni",
      "NO_UNMATCHED_ATTENDANCE": "Nu există o prezență de neegalat aici. Frumoasă treabă!",
      "NONE_YET": "Nici unul încă",
      "RESOLVE": "Rezolva",
      "ERROR_LOADING_ATTENDANCE": "Nu vă putem încărca istoricul prezenței chiar acum. Vă rugăm să încercați din nou.",
      "TIMELINESS_EARLY_TOOLTIP": "{{durata}} minute mai devreme",
      "TIMELINESS_LATE_TOOLTIP": "{{durată}} minute întârziere",
      "TRUNCATED_RESULT_MESSAGE": "Mai sunt multe de văzut, dar pentru a menține lucrurile să funcționeze fără probleme, a trebuit să vă trunchiem rezultatul. <br />Încercați să filtrați după rolul postului, pe site-ul/departament sau să reduceți intervalul de date."
    },
    "VIEW_EVENT_MODAL": {
      "VIEW_ATTENDANCE": "Vizualizați prezența",
      "STAFF_MEMBER": "Cine",
      "EVENT_TYPE": "Evenimentul",
      "RECORDED_TIME": "Timp înregistrat",
      "METHOD": "Capturat de",
      "TRUSTED_DEVICE": "Dispozitiv de încredere",
      "MOBILE_APP": "Aplicația Rotaready",
      "MANUALLY_OVERRIDDEN": "Suprascrit manual",
      "MANUAL_ENTRY": "Introducere manuală",
      "ON": "pe",
      "OVERTIME": "Prelungiri",
      "APPROVED": "Aprobat",
      "REJECTED": "Respins",
      "FACIAL_RECOGNITION": "Recunoașterea facială",
      "FACIAL_RECOGNITION_PROCESS": "Imaginile sunt de obicei procesate în câteva minute de la capturare",
      "FACIAL_RECOGNITION_WAITING": "În așteptarea procesării...",
      "TRUSTS_PHOTO": "Rotaready are încredere în această fotografie",
      "NO_TRUSTS_PHOTO": "Rotaready nu are încredere în această fotografie",
      "CONFIDENT": "încrezător",
      "UNABLE_TO_IDENTIFY": "Imposibilitatea de a identifica clar o față",
      "PROCESSED_ON": "Procesat pe",
      "ACKNOWLEDGED_BY": "Recunoscut de {{name}} pe",
      "REFERENCE_IMAGE": "Imagine de referință",
      "REFERENCE_IMAGE_HELP": "Această imagine nu va fi procesată, ci folosită ca referință pentru comparație",
      "EDIT": "Editați",
      "CLOSE": "Închide",
      "TAB_DETAILS": "Detalii",
      "TAB_PHOTO": "Fotografie",
      "TAB_LOCATION": "Locaţie",
      "CLOCK_IN": "Ceas în",
      "CLOCK_OUT": "Opriți ceasul",
      "BREAK_ON": "Întrerupeți",
      "BREAK_OFF": "Întrerupeți",
      "HAPPENED_HERE": "s-a întâmplat aici",
      "TAB_AUDIT": "Istorie",
      "AUDIT_TABLE_TIME": "Timpul de participare",
      "AUDIT_TABLE_OVERTIME": "Prelungiri",
      "AUDIT_TABLE_WHO": "Înregistrat de",
      "NOTES": "Notițe"
    },
    "EDIT_EVENT_MODAL": {
      "TITLE": "Editați prezența",
      "CLOCK_IN": "Ceas în",
      "CLOCK_OUT": "Opriți ceasul",
      "BREAK_ON": "Întrerupeți",
      "BREAK_OFF": "Întrerupeți",
      "ERROR_UPDATING": "Nu putem actualiza evenimentul chiar acum. Vă rugăm să încercați din nou.",
      "ERROR_DELETING": "Nu putem șterge evenimentul chiar acum. Vă rugăm să încercați din nou.",
      "CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți acest eveniment?\nDacă este un clock-in, ceasul corespunzător va fi, de asemenea, eliminat.",
      "PRIOR_EVENT_INFO": "Evenimentul precedent este un <b>{{eventType}}</b> la <b>{{date|momentFormat: 'll</b> - LT'}}",
      "PRIOR_EVENT_CONFLICT": "Există deja un <b>{{eventType}}</b> la <b>{{date|momentFormat: 'll -</b> LT'}}. Va trebui să selectați o altă oră.",
      "LABEL_WHO": "Cine",
      "LABEL_EVENT": "Evenimentul",
      "LABEL_DATE": "Data și ora",
      "DATE_IN_FUTURE": "Acest timp nu a trecut încă!",
      "LABEL_OVERTIME": "Prelungiri",
      "APPROVE": "Aproba",
      "REJECT": "Respinge",
      "LABEL_OVERTIME_HELP": "Rotaready a identificat unele ore suplimentare atunci când a asociat acest eveniment de prezență cu o schimbare. Dacă îl aprobați, orele suplimentare vor fi plătite în conformitate cu regula de plată a schimbului.",
      "DELETE": "Șterge",
      "CANCEL": "Anulează",
      "SAVE": "Salvați modificările"
    },
    "ADD_EVENT_MODAL": {
      "TITLE": "Adaugă prezență",
      "CLOCK_IN": "Ceas în",
      "CLOCK_OUT": "Opriți ceasul",
      "BREAK_ON": "Întrerupeți",
      "BREAK_OFF": "Întrerupeți",
      "ERROR_ACTIONING": "Nu putem adăuga evenimentul chiar acum. Vă rugăm să încercați din nou.",
      "PRIOR_EVENT_INFO": "Evenimentul precedent este un <b>{{eventType}}</b> la <b>{{date|momentFormat: 'll</b> - LT'}}",
      "PRIOR_EVENT_CONFLICT": "Există deja un <b>{{eventType}}</b> la <b>{{date|momentFormat: 'll -</b> LT'}}. Va trebui să selectați o altă oră.",
      "LABEL_EVENT": "Evenimentul",
      "LABEL_DATE": "Data și ora",
      "LABEL_BREAK": "Durata pauzei",
      "LABEL_BREAK_HELP": "O pauză va fi adăugată pentru dvs. după durata aleasă.",
      "DATE_IN_FUTURE": "Acest timp nu a trecut încă!",
      "CANCEL": "Anulează",
      "SAVE": "Adaugă prezență",
      "BREAK_MINUTES": "minute"
    }
  },
  "DIALOG": {
    "TEXTBOX_DIALOG": {
      "CANCEL": "Anulează"
    },
    "TWO_BUTTON_DIALOG": {
      "CANCEL": "Anulează"
    }
  },
  "OTHER": {
    "EDIT_PREFERENCES_MODAL": {
      "TITLE": "Actualizați preferințele",
      "SUBTITLE": "Informatii de contact",
      "SUBTITLE_HELP": "Dacă ați uitat parola, vă putem ajuta să o resetați numai dacă avem modalități alternative de a vă contacta",
      "EMAIL_LABEL": "Adresa de e-mail",
      "EMAIL_PLACEHOLDER": "Niciuna furnizată",
      "EMAIL_HELP": "Vă rugăm să furnizați o adresă de e-mail validă.",
      "MOBILE_LABEL": "Telefonul mobil",
      "MOBILE_PLACEHOLDER": "Niciuna furnizată",
      "MOBILE_HELP": "Vă rugăm să furnizați un număr de telefon mobil valid sau deloc.",
      "ACCOUNT_INFO": "Informații despre cont",
      "ACCOUNT_INFO_HELP": "Aici puteți seta limba",
      "LANGUAGE_LABEL": "Limba",
      "LANGUAGE_HELP": "Rotaready va fi afișat în limba aleasă.",
      "OTHER_SETTINGS_TITLE": "Alte setări",
      "CLOCK_IN_LABEL": "PIN de intrare al ceasului",
      "CLOCK_IN_HELP": "Dacă vi se cere să introduceți un cod PIN atunci când intrați/ieșiți, puteți introduce unul nou aici. Trebuie să fie de 4 cifre.",
      "NOTIFICATIONS_TITLE": "Notificări",
      "NOTIFICATIONS_HELP": "Controlează modul în care dorești să fii notificat cu privire la evenimentele la care ești abonat ",
      "MANAGE_SUBSCRIPTIONS": "gestionați abonamentele",
      "ROTA_UPDATES": "Actualizări Rota",
      "CRITIAL_SHIFT": "Actualizări critice de schimbare",
      "EMAIL": " E-mailul",
      "MOBILE_APP": " Aplicație mobilă",
      "SHIFT_UPDATED": "Când o tură pe care o veți lucra în următoarele 24 de ore a fost actualizată",
      "REGULAR_SHIFT": "Actualizări periodice de schimb",
      "SHIFT_UPDATED_BEYOND": "Actualizări pentru orice schimburi pe care le veți lucra după următoarele 24 de ore",
      "WEEKLY_SUMMARY": "Rezumat săptămânal",
      "SMS": " SMS",
      "UPCOMING_WEEK": "Un rezumat al schimburilor tale pentru săptămâna viitoare, trimis în seara dinaintea începerii săptămânii",
      "APPROVAL": "Aprobare",
      "APPROVAL_HELP": "Când o rotație este marcată ca fiind pregătită pentru omologare",
      "PUBLISHING": "Publicare",
      "PUBLISHING_HELP": "Când o rotație este publicată sau nepublicată",
      "SHIFT_SWAPS": "Schimburi de schimbare",
      "SWAP_UPDATES": "Schimbați actualizările ",
      "REQUESTED": "Solicitat",
      "REQUESTED_HELP": "Când cineva solicită un nou schimb de ture",
      "ACCEPTED": "Acceptat",
      "ACCEPTED_HELP": "Când cineva acceptă un schimb de schimburi",
      "APPROVED": "Aprobat",
      "APPROVED_HELP": "Când un schimb de ture este aprobat de conducere",
      "REJECTED": "Respins",
      "REJECTED_HELP": "Atunci când un schimb de schimburi este respins de conducere",
      "CANCELLED": "Anulat",
      "CANCELLED_HELP": "Când un schimb de ture este anulat",
      "ABSENCE_UPDATES": "Actualizări de absență/sărbători",
      "ABSENCE_REQUESTED": "Absența solicitată",
      "ABSENCE_REQUESTED_HELP": "Când este solicitată o nouă absență",
      "ABSENCE_UPDATE": "Actualizare absență",
      "ABSENCE_UPDATE_HELP": " Când o cerere de absență este aprobată, respinsă sau anulată de conducere",
      "COST_CONTROL": "Controlul costurilor",
      "SIGN_OFF": "Deconectați-vă",
      "SIGN_OFF_HELP": "Când un site se semnează o săptămână",
      "SIGN_OFF_REVERSE": "Deconectare inversată",
      "SIGN_OFF_REVERSE_HELP": "Când un site anulează semnarea timp de o săptămână",
      "ADMIN": "Admin",
      "ACCOUNT_APPROVAL": "Aprobarea contului",
      "ACCOUNT_APPROVAL_HELP": "Când un cont fie așteaptă aprobarea, fie a fost aprobat",
      "DOCUMENTS": "Documente",
      "DOCUMENTS_HELP": "Când un document a expirat sau va expira în curând",
      "OTHER": "Altele",
      "COMPANY_ANNOUNCEMENTS": "Anunțuri de companie",
      "COMPANY_HELP": "Anunțuri importante de la angajatorul dvs.",
      "DAILY_ADMIN_UPDATE": "Actualizare zilnică a administratorului",
      "DAILY_UPDATE_HELP": "Vă vom notifica dacă aveți probleme de prezență sau dacă există solicitări de absență sau schimburi de ture în așteptarea aprobării.",
      "PHONE_ONLY": "Notificările cu această pictogramă sunt disponibile numai cu aplicațiile Rotaready iPhone și Android.",
      "CHANGE_PASSWORD": "Schimbă parola",
      "SAVE": "Salvați",
      "SAVING": "Salvarea...",
      "CANCEL": "Anulează"
    },
    "MANAGE_SUBSCRIPTIONS_MODAL": {
      "TITLE": "Gestionați abonamentele",
      "HELP": "Abonamentele dvs. controlează despre ce doriți să fiți notificat.",
      "UNSUBSCRIBE_ALL": "Dezabonați-vă de la toate",
      "NOTIFY_WHEN": "Anunță-mă când {{descriptor}}",
      "UNSUBSCRIBE_TOOLTIP": "Dezabonare",
      "UNSUBSCRIBE_ALL_TOOLTIP": "Dezabonați-vă de la toate",
      "ACTION_IN": "{{acțiune}} în ",
      "FILTER_NO_SUBSCRIPTIONS": "Nu aveți abonamente active în {{name}}.",
      "ALL_NO_SUBSCRIPTIONS": "Nu aveți abonamente active.",
      "CLOSE": "Închide"
    },
    "RELEASE_REFRESH_MODAL": {
      "TITLE": "Actualizare Rotaready",
      "BODY": "Tocmai am lansat o actualizare la Rotaready care necesită reîncărcarea paginii. Vă rugăm să faceți clic pe butonul de mai jos.",
      "REFRESH": "Reîmprospătați pagina"
    },
    "EDIT_AVATAR_MODAL": {
      "TITLE": "Poza de profil",
      "INTRO_COPY": "O imagine ajută utilizatorii să te recunoască și te informează când ești conectat la contul tău.",
      "BUTTON_CHANGE": "Încărcați o imagine nouă",
      "BUTTON_CANCEL": "Anulează",
      "BUTTON_SAVE": "Salvați",
      "ALERT_REMOVE": "Sunteți sigur că doriți să eliminați această imagine de profil?",
      "ALERT_FILE_TYPE_NOT_ALLOWED": "Acest tip de fișier nu este permis.",
      "ALERT_ERROR_500": "Nu am putut actualiza fotografia dvs. chiar atunci. Vă rugăm să încercați din nou.",
      "ALERT_SUCCESS_ADD": "Poza ta de profil a fost actualizată. Este posibil să dureze ceva timp pentru a actualiza toate serviciile Rotaready.",
      "ALERT_SUCCESS_REMOVE": "Poza ta de profil a fost eliminată. Este posibil să dureze ceva timp pentru a actualiza toate serviciile Rotaready."
    }
  },
  "REPORTS": {
    "INDEX_LIST": {
      "TITLE": "Rapoarte",
      "TRONC_STATUS": "Starea Tronc",
      "CURRENTLY_CLOCKED_IN": "Persoanele vizate în prezent",
      "ROTA_PROGRESS": "Rota Progress",
      "SIGN_OFF_PROGRESS": "Progresul semnării",
      "PAYROLL_EXPORT": "Export de salarizare",
      "LABOUR_BREAKDOWN": "Defalcarea forței de muncă",
      "WORKING_TIME_VIOLATIONS": "Încălcări ale timpului de lucru",
      "CONTRACTUAL_OBLIGATION_SUMMARY": "Rezumatul obligațiilor contractuale",
      "DOCUMENT_SIGNATURES": "Semnarea documentelor",
      "EXPIRING_DOCUMENTS": "Documente care expiră",
      "EMPLOYEE_EXPORT": "Detaliile angajaților",
      "JOINERS_LEAVERS": "Tamplari și părăsiți",
      "STAFF_TURNOVER": "Cifra de afaceri a personalului",
      "ANNIVERSARY": "Aniversări",
      "EMPLOYMENT_CHANGES": "Schimbări de angajare",
      "CATEGORY_HR": "RESURSE UMANE",
      "CATEGORY_DOCUMENTS": "Documente",
      "CATEGORY_ROTAS": "Programare",
      "CATEGORY_PAYROLL": "Salarizare",
      "CATEGORY_ATTENDANCE": "Participare",
      "CATEGORY_ABSENCE": "Absența",
      "CATEGORY_COST_CONTROL": "Controlul costurilor",
      "ABSENCE_EXPORT": "Export absență",
      "ALLOWANCES_EXPORT": "Exportul certificatelor",
      "UNTAKEN_HOLIDAY_COST": "Costul vacanței nepreluate",
      "COST_CONTROL_RAW_DATA": "Export de date brute",
      "NEW_HIRE_TURNOVER": "Cifra de afaceri a angajaților noi",
      "ABSENCE_DAYS_EXPORT": "Export de zile de absență",
      "SHIFT_TYPE_STREAM_BREAKDOWN": "Tipul schimbării și defalcarea fluxului",
      "XERO_EXPORT": "Exportați în Xero",
      "DOCUMENT_PRESENCE": "Prezența documentelor",
      "DOCUMENTS_EXPORT": "Exportul documentelor",
      "ATTENDANCE_BREAKDOWN": "Defalcarea prezenței",
      "LATENESS": "Întârziere",
      "LABOUR_BREAKDOWN_FILE_EXPORT": "Export fișier de defalcare a forței de muncă",
      "CATEGORY_ASYNC_FILE_EXPORTS": "Rapoarte mari",
      "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": "Export de fișiere de defalcare a muncii",
      "PAYROLL_ASYNC_FILE_EXPORT": "Export fisier salarizare",
      "RAW_COST_CONTROL_ASYNC_FILE_EXPORT": "Export de fișiere de control al costurilor brute"
    },
    "LATENESS": {
      "TITLE": "Întârziere",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "COLUMN_ID": "ID-UL",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE_APPOINTMENT": "Site (programare)",
      "COLUMN_DEPT_APPOINTMENT": "Departament (numire)",
      "COLUMN_INSTANCES": "Instanțe",
      "COLUMN_VARIANCE": "Variația medie",
      "FILTER_DATE": "Data",
      "FILTER_SITE": "Site/departament",
      "FILTER_USER": "Persoană",
      "FILTER_OVERTIME": "Prelungiri",
      "FILTER_OVERTIME_NA": "Nu este recunoscut",
      "FILTER_OVERTIME_APPROVED": "Aprobat",
      "FILTER_OVERTIME_REJECTED": "Respins",
      "FILTER_CLOCK_IN": "Clock-in",
      "FILTER_CLOCK_OUT": "Clock-out",
      "FILTER_MINS_LATE": "minute târziu",
      "FILTER_MINS_EARLY": "minute mai devreme",
      "LABEL_MINUTES": "minute",
      "BUTTON_EXPORT": "Export",
      "NO_MATCH": "Nu am putut găsi date care să se potrivească filtrelor dvs."
    },
    "ATTENDANCE_BREAKDOWN": {
      "TITLE": "Defalcarea prezenței",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "COLUMN_ID": "ID-UL",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE_APPOINTMENT": "Site (programare)",
      "COLUMN_DEPT_APPOINTMENT": "Departament (numire)",
      "COLUMN_SITE_ATTRIBUTION": "Site (atribuire)",
      "COLUMN_DEPT_ATTRIBUTION": "Departament (atribuire)",
      "COLUMN_DATE": "Data afacerii",
      "COLUMN_SHIFT_TYPE": "Tipul schimbării",
      "COLUMN_RECORD_TYPE": "Tipul înregistrării",
      "COLUMN_SCHEDULED": "Programat",
      "COLUMN_ACTUAL": "Actuala",
      "COLUMN_VARIANCE": "Variație",
      "COLUMN_CAPTURED_BY": "Capturat de",
      "COLUMN_EDITED_BY": "Editat de",
      "COLUMN_EDITED_DATE": "Data editării",
      "COLUMN_OVERTIME_STATE": "Aprobarea orelor suplimentare",
      "COLUMN_OVERTIME_EDITED_BY": "Prelungiri confirmate de",
      "COLUMN_OVERTIME_EDITED_DATE": "Data confirmată a orelor suplimentare",
      "FILTER_DATE": "Data",
      "FILTER_SITE": "Site/departament",
      "FILTER_USER": "Persoană",
      "FILTER_OVERTIME": "Prelungiri",
      "FILTER_OVERTIME_NA": "Nu este recunoscut",
      "FILTER_OVERTIME_APPROVED": "Aprobat",
      "FILTER_OVERTIME_REJECTED": "Respins",
      "FILTER_CLOCK_IN": "Clock-in",
      "FILTER_CLOCK_OUT": "Clock-out",
      "FILTER_MINS_LATE": "minute târziu",
      "FILTER_MINS_EARLY": "minute mai devreme",
      "BUTTON_EXPORT": "Export",
      "NO_MATCH": "Nu am putut găsi date care să se potrivească filtrelor dvs."
    },
    "DOCUMENTS_EXPORT": {
      "TITLE": "Exportul documentelor",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "FILTER_SITE": "Site/departament",
      "FILTER_TOGGLE_USER_ACTIVE": "Angajați activi",
      "FILTER_USER": "Persoană",
      "FILTER_CATEGORY": "Categorie",
      "FILTER_ACKNOWLEDGEMENT": "Statut",
      "FILTER_OPTION_ACKNOWLEDGED": "Recunoscută",
      "FILTER_OPTION_NOT_ACKNOWLEDGED": "Nu este recunoscut",
      "BUTTON_EXPORT": "Export",
      "COLUMN_USER_ID": "ID-ul angajatului",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE": "Site-ul",
      "COLUMN_DEPARTMENT": "Divizie",
      "COLUMN_CATEGORY": "Categorie",
      "COLUMN_FILE_NAME": "Nume",
      "COLUMN_FILE_SIZE": "Dimensiune fişier (KB)",
      "COLUMN_UPLOADED_BY": "Încărcat de",
      "COLUMN_UPLOADED_DATE": "Data încărcării",
      "COLUMN_EXPIRY_DATE": "Data expirării",
      "COLUMN_SIGNATURE_METHOD": "Metoda semnăturii",
      "COLUMN_EDITED_BY": "Editat ultima dată de",
      "COLUMN_EDITED_DATE": "Data ultimei editări",
      "COLUMN_ACKNOWLEDGED": "Recunoscută",
      "COLUMN_ACKNOWLEDGED_DATE": "Data recunoscută",
      "COLUMN_NOTES": "notițe",
      "NO_MATCH": "Nu am putut găsi angajați pentru filtrele dvs."
    },
    "DOCUMENT_PRESENCE": {
      "TITLE": "Prezența documentelor",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "FILTER_SITE": "Site/departament",
      "FILTER_TOGGLE_USER_ACTIVE": "Angajați activi",
      "FILTER_CATEGORY": "Categorie",
      "BUTTON_EXPORT": "Export",
      "COLUMN_USER_ID": "ID-ul angajatului",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE": "Site-ul",
      "COLUMN_DEPARTMENT": "Divizie",
      "COLUMN_EMPLOYMENT_ACTIVE": "Angajare activă",
      "COLUMN_JOIN_DATE": "Data înscrierii",
      "COLUMN_LEAVE_DATE": "Data plecării",
      "COLUMN_DOCUMENTS_VALIDITY": "Documente valabile",
      "NO_MATCH": "Nu am putut găsi angajați pentru filtrele dvs."
    },
    "SHIFT_TYPE_STREAM_BREAKDOWN": {
      "TITLE": "Defalcarea tipului de schimbare",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Sari la",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultimele două săptămâni",
      "JUMP_TO_LAST_MONTH": "Luna trecută",
      "FILTER_SITE": "Site/departament",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "FILTER_DATA_SOURCE": "Sursa de date",
      "FILTER_USER": "Persoană",
      "FILTER_BEHAVIOUR": "Comportament",
      "BEHAVIOUR_SHIFT_TYPE": "Tipul schimbării",
      "BEHAVIOUR_STREAM": "Stream",
      "COLUMN_ID": "ID-UL",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE": "Site-ul actual",
      "COLUMN_DEPARTMENT": "Departamentul curent",
      "COLUMN_STAFF_GROUP": "Grupul actual de personal",
      "COLUMN_TOTAL": "Total",
      "ROW_TOTAL": "Total",
      "INFO_NO_DATA": "Nu există înregistrări care să corespundă filtrelor dvs.",
      "INFO_NO_FILTER": "Selectați un site/departament de mai sus pentru a începe.",
      "PAY_AMOUNT_TYPE_HOURLY": "Rata orară",
      "PAY_AMOUNT_TYPE_DAILY": "Rata zilnică",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salariu anual",
      "DATA_SOURCE_SHIFTS": "Schimburi",
      "DATA_SOURCE_MATCHED": "Sanitizat",
      "DATE_ERROR_DIFF": "Nu puteți alege mai mult de 6 săptămâni. Vă rugăm să restricționați intervalul de date.",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "ERROR_400": "Parametrii dvs. determină generarea prea multor date pentru un singur raport. Vă rugăm să reduceți domeniul de aplicare al cererii dvs. și încercați din nou.",
      "STREAM_UNALLOCATED": "Nealocate",
      "FILTER_METRIC": "Valoare",
      "METRIC_WAGES": "Costul salariului de bază",
      "METRIC_HOURS": "Ore plătite"
    },
    "ABSENCE_DAYS_EXPORT": {
      "TITLE": "Export de zile de absență",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "COLUMN_USER_ID": "ID-ul angajatului",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE": "Site-ul",
      "COLUMN_DEPARTMENT": "Divizie",
      "COLUMN_STAFF_GROUP": "Grupul de personal",
      "COLUMN_JOB_ROLE_NAME": "Rolul postului",
      "COLUMN_DATE": "Data",
      "COLUMN_HOURS": "Ore",
      "COLUMN_HEADER_ID": "ID-ul antetului",
      "COLUMN_ABSENCE_TYPE": "Tip",
      "COLUMN_ABSENCE_TYPE_REASON": "Motivul",
      "COLUMN_STATUS": "Statut",
      "COLUMN_SCOPE": "Domeniul de aplicare",
      "COLUMN_START_DATE": "Data începerii antetului",
      "COLUMN_END_DATE": "Data de încheiere a antetului",
      "COLUMN_START_TIME": "Ora de începere",
      "COLUMN_END_TIME": "Ora de încheiere",
      "COLUMN_TOTAL_HOURS": "Ore de antet",
      "COLUMN_TOTAL_DAYS": "Zile de antet",
      "COLUMN_CANCELLED": "Anulat",
      "FILTER_DATE": "Data",
      "FILTER_SITE": "Site/departament",
      "FILTER_STATUS": "Statut",
      "FILTER_TYPE": "Tip",
      "FILTER_USER": "Persoană",
      "FILTER_TOGGLE_CANCELLED": "Includeți anulat",
      "FILTER_TOGGLE_USER_ACTIVE": "Angajați activi",
      "BUTTON_EXPORT": "Export",
      "NO_MATCH": "Nu am putut găsi nicio absență care să se potrivească filtrelor dvs."
    },
    "NEW_HIRE_TURNOVER": {
      "TITLE": "Cifra de afaceri a angajaților noi",
      "FILTER_DATE": "Data înscrierii",
      "JUMP_TO": "Sari la",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultimele două săptămâni",
      "JUMP_TO_LAST_MONTH": "Luna trecută",
      "FILTER_AGGREGATION": "agregare",
      "AGGREGATION_ENTITY_GROUP": "Site/departament",
      "AGGREGATION_POSITION": "Rolul postului",
      "AGGREGATION_STAFF_GROUP": "Grupul de personal",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "DATE_ERROR_DIFF": "Nu puteți alege mai mult de 12 luni. Vă rugăm să restricționați intervalul de date.",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "COLUMN_AGGREGATION_LABEL": "Nume",
      "COLUMN_JOINERS": "Tamplari",
      "COLUMN_LEAVERS": "Ieșiți",
      "COLUMN_TURNOVER_RATE": "Rata cifrei de afaceri",
      "DEPARTED_WITHIN": "Data plecării",
      "DAYS_LABEL": "în {{count}} zile",
      "LABEL_TOTAL": "Total",
      "LABEL_GRAND_TOTAL": "Marele Total",
      "FILTER_LEAVER_REASON": "Motivul părăsirii"
    },
    "COST_CONTROL_RAW_DATA": {
      "TITLE": "Controlul costurilor Export de date brute",
      "ERROR_500": "Nu am putut exporta datele chiar atunci. Vă rugăm să încercați din nou.",
      "BUTTON_EXPORT": "Export",
      "LOADING_TEXT": "Exportul dvs. este generat.",
      "METRIC_HOURS": "Ore",
      "METRIC_WAGES": "Salariile",
      "METRIC_SALES": "Vânzări",
      "METRIC_COVERS": "Huse",
      "SUBDIVISION_PREDICTION": "Predictii",
      "SUBDIVISION_FORECAST": "Previziuni",
      "SUBDIVISION_ACTUAL": "Actuali",
      "FILTER_METRICS": "Metrică",
      "FILTER_SUBDIVISIONS": "Subdiviziuni",
      "FILTER_ENTITY": "Site/departament",
      "FILTER_ENTITY_BUTTON": "Site/departament",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Sari la",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultimele două săptămâni",
      "JUMP_TO_LAST_MONTH": "Luna trecută",
      "CALCULATE_ACTUAL_CHECKBOX": "Calculați ore/salariile reale din mers, în cazul în care datele deconectate nu sunt prezente.",
      "DATE_ERROR_DIFF": "Nu puteți alege mai mult de 2 luni. Vă rugăm să restricționați intervalul de date.",
      "CALCULATE_ACTUAL_ERROR": "Orele reale/salariile nu pot fi calculate în zbor pentru mai mult de o săptămână sau cincisprezece locații/departamente."
    },
    "ABSENCE_EXPORT": {
      "TITLE": "Export absență",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "COLUMN_ID": "ID-UL",
      "COLUMN_USER_ID": "ID-ul angajatului",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE": "Site-ul",
      "COLUMN_DEPARTMENT": "Divizie",
      "COLUMN_STAFF_GROUP": "Grupul de personal",
      "COLUMN_JOB_ROLE_NAME": "Rolul postului",
      "COLUMN_ABSENCE_TYPE": "Tip",
      "COLUMN_ABSENCE_TYPE_REASON": "Motivul",
      "COLUMN_STATUS": "Statut",
      "COLUMN_SCOPE": "Domeniul de aplicare",
      "COLUMN_START_DATE": "Data începerii",
      "COLUMN_END_DATE": "Data de încheiere",
      "COLUMN_START_TIME": "Ora de începere",
      "COLUMN_END_TIME": "Ora de încheiere",
      "COLUMN_TOTAL_HOURS": "Ore",
      "COLUMN_TOTAL_DAYS": "Zile",
      "COLUMN_REQUEST_NAME": "Solicitat de",
      "COLUMN_REQUEST_DATE": "Data cererii",
      "COLUMN_REQUEST_MESSAGE": "Solicitare mesaj",
      "COLUMN_REVIEW_NAME": "Revizuit de",
      "COLUMN_REVIEW_DATE": "Data revizuirii",
      "COLUMN_REVIEW_MESSAGE": "Examinați mesajul",
      "COLUMN_EDITED_NAME": "Editat ultima dată de",
      "COLUMN_EDITED_DATE": "Data ultimei editări",
      "COLUMN_CANCELLED": "Anulat",
      "COLUMN_CANCELLED_NAME": "Anulat de",
      "COLUMN_CANCELLED_DATE": "Data anulată",
      "FILTER_DATE": "Data începerei/încheierii",
      "FILTER_SITE": "Site/departament",
      "FILTER_STATUS": "Statut",
      "FILTER_TYPE": "Tip",
      "FILTER_USER": "Persoană",
      "FILTER_TOGGLE_CANCELLED": "Includeți anulat",
      "FILTER_TOGGLE_USER_ACTIVE": "Angajați activi",
      "BUTTON_EXPORT": "Export",
      "NO_MATCH": "Nu am putut găsi nicio absență care să se potrivească filtrelor dvs."
    },
    "ALLOWANCE_EXPORT": {
      "TITLE": "Exportul certificatelor",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "COLUMN_ID": "ID-UL",
      "COLUMN_USER_ID": "ID-ul angajatului",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE": "Site-ul",
      "COLUMN_DEPARTMENT": "Divizie",
      "COLUMN_ABSENCE_TYPE": "Tipul absenței",
      "COLUMN_YEAR_STARTS": "Anul începe",
      "COLUMN_EFFECTIVE_DATE": "Data intrării în vigoare",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Calendarul de acumulare",
      "COLUMN_UNIT": "Unitate",
      "COLUMN_DEDUCTIBLE": "Deductibil",
      "COLUMN_TOTAL_ACCRUED": "Total acumulat",
      "COLUMN_OPENING_BALANCE": "Soldul de deschidere",
      "COLUMN_BOOKED": "Rezervat",
      "COLUMN_BOOKED_AND_ELAPSED": "Rezervat și scurs",
      "COLUMN_REMAINING": "Rămas",
      "COLUMN_ACCRUAL_ESTIMATE": "Estimarea acumulării",
      "COLUMN_CARRIED_OVER": "Transportat",
      "COLUMN_OVERSPEND_LIMIT": "Cheltuieli excesive",
      "COLUMN_TOTAL_TO_ACCRUE": "Maximum de acumulat",
      "COLUMN_ACCRUAL_RATE": "Suprascrierea ratei de acumulare",
      "COLUMN_ADDED_NAME": "Adăugat de",
      "COLUMN_ADDED_DATE": "Data adăugată",
      "COLUMN_EDITED_NAME": "Editat ultima dată de",
      "COLUMN_EDITED_DATE": "Data ultimei editări",
      "COLUMN_FINALISED": "Finalizat",
      "COLUMN_FINALISED_REFERENCE_DATE": "Data de referință finalizată",
      "COLUMN_FINALISED_EDITED_NAME": "Finalizat de",
      "COLUMN_FINALISED_EDITED_DATE": "Finalizat la",
      "COLUMN_FINALISED_NOTES": "Note finalizate",
      "COLUMN_PROJECT_TOTAL_ACCRUED": "Preziceți acumularea în timpul rezervării",
      "FILTER_DATE": "Data intrării în vigoare",
      "FILTER_SITE": "Site/departament",
      "FILTER_TYPE": "Tipul absenței",
      "FILTER_SCHEDULE_OF_ACCRUAL": "Calendarul de acumulare",
      "FILTER_USER": "Persoană",
      "FILTER_TOGGLE_USER_ACTIVE": "Angajați activi",
      "BUTTON_EXPORT": "Export",
      "NO_MATCH": "Nu am putut găsi nicio alocație care să corespundă filtrelor dvs."
    },
    "UNTAKEN_HOLIDAY_COST": {
      "TITLE": "Costul vacanței nepreluate",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "ERROR_400": "Intervalul dvs. de date efectiv este prea larg, vă rugăm să îl restrângeți și să încercați din nou.",
      "BUTTON_EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "FILTER_DATE": "Data intrării în vigoare a indemnizației",
      "FILTER_SITE": "Site/departament",
      "FILTER_TOGGLE_USER_ACTIVE": "Angajați activi",
      "COLUMN_ID": "ID-UL",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE": "Site-ul",
      "COLUMN_DEPARTMENT": "Divizie",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Calendarul de acumulare",
      "COLUMN_UNIT": "Unitate",
      "COLUMN_DEDUCTIBLE": "Deductibil",
      "COLUMN_TOTAL_ACCRUED": "Total acumulat",
      "COLUMN_BOOKED": "Rezervat",
      "COLUMN_BOOKED_AND_ELAPSED": "Scursă",
      "COLUMN_UNTAKEN": "Nepreluat",
      "COLUMN_PAY_AMOUNT": "Suma",
      "COLUMN_PAY_AMOUNT_TYPE": "Tip",
      "COLUMN_PAY_PROJECTED_COST": "Costul proiectat",
      "COLUMN_HEADER_ALLOWANCE": "Indemnizație",
      "COLUMN_HEADER_PAY": "Plătiți",
      "LABEL_DEDUCTIBLE_YES": "da",
      "LABEL_DEDUCTIBLE_NO": "Nu",
      "TEXT_GRAND_TOTAL": "Marele Total"
    },
    "EMPLOYMENT_CHANGES": {
      "TITLE": "Schimbări de angajare",
      "MODEL_APPOINTMENT": "Numire",
      "MODEL_PAY": "Plătiți",
      "MODEL_EMPLOYEE": "Angajat",
      "PROPERTY_EMPLOYEE_FIRST_NAME": "Prenume",
      "PROPERTY_EMPLOYEE_LAST_NAME": "Nume de familie",
      "PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET": "Ore medii setate manual",
      "PROPERTY_APPOINTMENT_ENTITY_GROUP": "Site-ul",
      "PROPERTY_APPOINTMENT_ENTITY": "Divizie",
      "PROPERTY_APPOINTMENT_GROUP": "Grupul de personal",
      "PROPERTY_APPOINTMENT_POSITION": "Rolul postului",
      "PROPERTY_APPOINTMENT_COMPANY": "Companie",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE": "Obligația minimă",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT": "Obligația minimă (unitate)",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD": "Obligația minimă (perioadă)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE": "Obligație maximă",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT": "Obligația maximă (unitate)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD": "Obligația maximă (perioadă)",
      "PROPERTY_APPOINTMENT_LINE_MANAGER": "Director de linie",
      "PROPERTY_APPOINTMENT_PAYROLL_CALENDAR": "Calendarul de salarizare",
      "PROPERTY_PAY_AMOUNT": "Suma",
      "PROPERTY_PAY_AMOUNT_TYPE": "Tipul sumei",
      "PROPERTY_PAY_UPLIFT": "Creșterea salariilor",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Sari la",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultimele două săptămâni",
      "JUMP_TO_LAST_MONTH": "Luna trecută",
      "FILTER_SITE": "Site/departament",
      "FILTER_PROPERTY": "Proprietate",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "DATE_ERROR_DIFF": "Nu puteți alege mai mult de 12 luni. Vă rugăm să restricționați intervalul de date.",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "COLUMN_ID": "ID-UL",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE": "Site-ul",
      "COLUMN_DEPARTMENT": "Divizie",
      "COLUMN_CHANGE": "Schimbare",
      "COLUMN_EFFECTIVE_DATE": "Eficace",
      "COLUMN_PROPERTY": "Proprietate",
      "COLUMN_NEW_VALUE": "Valoare nouă",
      "COLUMN_OLD_VALUE": "Valoare veche",
      "COLUMN_EDITED_BY": "Modificat de",
      "COLUMN_EDITED_ON": "Data modificată"
    },
    "ANNIVERSARY": {
      "TITLE": "Aniversări",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Sari la",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultimele două săptămâni",
      "JUMP_TO_LAST_MONTH": "Luna trecută",
      "FILTER_SITE": "Site/departament",
      "FILTER_TYPE": "Aniversare",
      "TYPE_BIRTHDAY": "Ziua de nastere",
      "TYPE_EMPLOYMENT": "Aniversarea muncii",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "DATE_ERROR_DIFF": "Nu puteți alege mai mult de 12 luni. Vă rugăm să restricționați intervalul de date.",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "COLUMN_ID": "ID-UL",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE": "Site-ul",
      "COLUMN_DEPARTMENT": "Divizie",
      "COLUMN_TYPE": "Aniversare",
      "COLUMN_YEARS": "Anii",
      "COLUMN_DATE": "Data"
    },
    "STAFF_TURNOVER": {
      "TITLE": "Cifra de afaceri a personalului",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Sari la",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultimele două săptămâni",
      "JUMP_TO_LAST_MONTH": "Luna trecută",
      "FILTER_AGGREGATION": "agregare",
      "AGGREGATION_ENTITY_GROUP": "Site/departament",
      "AGGREGATION_POSITION": "Rolul postului",
      "AGGREGATION_STAFF_GROUP": "Grupul de personal",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "DATE_ERROR_DIFF": "Nu puteți alege mai mult de 12 luni. Vă rugăm să restricționați intervalul de date.",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "COLUMN_AGGREGATION_LABEL": "Nume",
      "COLUMN_HEADCOUNT_START": "Numărul de angajați (început)",
      "COLUMN_HEADCOUNT_END": "Numărul de angajați (sfârșit)",
      "COLUMN_JOINERS": "Tamplari",
      "COLUMN_REHIRES": "Reangajări",
      "COLUMN_LEAVERS": "Ieșiți",
      "COLUMN_TRANSFERS_IN": "Transferuri în",
      "COLUMN_TRANSFERS_OUT": "Transferuri în afara",
      "COLUMN_ATTRITION_RATE": "Rata de uzură",
      "COLUMN_AVERAGE_SERVICE": "Serviciu mediu",
      "LABEL_TOTAL": "Total",
      "LABEL_GRAND_TOTAL": "Marele Total",
      "FILTER_LEAVER_REASON": "Motivul părăsirii"
    },
    "DOCUMENT_SIGNATURES": {
      "TITLE": "Semnarea documentelor",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "INFO_NO_DATA": "Nu există înregistrări care să corespundă filtrelor dvs.",
      "LABEL_METHOD_MANUAL": "Manuale",
      "LABEL_METHOD_ELECTRONIC": "Electronică",
      "LABEL_STATUS_PENDING": "În așteptare",
      "LABEL_STATUS_REJECTED": "Respins",
      "LABEL_STATUS_SIGNED": "Semnat",
      "LABEL_STATUS_CANCELLED": "Anulat",
      "LABEL_RESPONSE_NONE": "În așteptarea răspunsului",
      "LABEL_RESPONSE_REJECTED": "Respins",
      "LABEL_RESPONSE_SIGNED": "Semnat",
      "FILTER_SITE": "Site/departament",
      "FILTER_RECIPIENT_USER": "Destinatar",
      "FILTER_ADDED_USER": "Expeditorul",
      "FILTER_METHOD": "Metoda semnăturii",
      "FILTER_STATUS": "Statut",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "COLUMN_RECIPIENT": "Destinatar",
      "COLUMN_EMPLOYER": "contrapartidă",
      "COLUMN_DOCUMENT": "Document",
      "COLUMN_DOCUMENT_CATEGORY": "Categoria de destinație",
      "COLUMN_METHOD": "Metodă",
      "COLUMN_STATUS": "Statut",
      "COLUMN_ADDED_USER": "Expeditorul",
      "COLUMN_ADDED_DATE": "Data",
      "EMPLOYER_NOT_SET": "Nu este necesar",
      "VIEW_MODAL": {
        "TITLE": "Document de semnat",
        "LABEL_FILE": "Document",
        "LABEL_EXPIRY": "Expirarea documentului",
        "LABEL_TEMPLATE": "Șablon utilizat",
        "LABEL_CATEGORY": "Categoria de destinație",
        "LABEL_ADDED_BY": "Adăugat de",
        "LABEL_METHOD": "Metodă",
        "TEMPLATE_NOT_USED": "Niciuna",
        "EXPIRY_NOT_APPLICABLE": "Nu se aplică",
        "BUTTON_CLOSE": "Închide",
        "HEADING_SIGNERS": "Semnatari",
        "EMPLOYEE": "Angajat",
        "EMPLOYER": "Angajator",
        "COLUMN_NAME": "Nume",
        "COLUMN_ROLE": "Rolul",
        "COLUMN_STATUS": "Statut",
        "COLUMN_DATE": "Data",
        "LABEL_OPTIONS": "Opțiuni",
        "OPTION_REMINDER": "Trimiteți un memento",
        "OPTION_CANCEL": "Anulează documentul",
        "OPTION_UPLOAD": "Încărcați documentul semnat",
        "CANCEL_ERROR_500": "Nu am putut anula documentul chiar atunci.",
        "CANCEL_SUCCESS": "Documentul a fost anulat.",
        "REMINDER_ERROR_500": "Nu am putut trimite un memento chiar atunci.",
        "REMINDER_ERROR_400": "Un memento a fost deja trimis recent. Vă rugăm să așteptați un timp înainte de a trimite un alt memento.",
        "REMINDER_SUCCESS": "Am înţeles! Vom trimite un memento prin e-mail acum.",
        "DOWNLOAD_ERROR_500": "Nu am putut descărca fișierul chiar atunci. Încercați din nou sau luați legătura dacă acest lucru persistă.",
        "LABEL_DOWNLOAD": "Descarca",
        "OPTION_DOWNLOAD_ORIGINAL": "Descărcați originalul",
        "OPTION_DOWNLOAD_SIGNED_COPY": "Descărcați copia semnată"
      }
    },
    "WORKING_TIME_VIOLATIONS": {
      "TITLE": "Încălcări ale timpului de lucru",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Sari la",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultimele două săptămâni",
      "JUMP_TO_LAST_MONTH": "Luna trecută",
      "FILTER_SITE": "Site/departament",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "COLUMN_SITE_DEPARTMENT": "Site/departament",
      "COLUMN_VIOLATION_HOURS": "Orele de lucru",
      "COLUMN_VIOLATION_HOURS_TOOLTIP": "Numărul de evenimente în care un angajat a depășit 48 de ore de lucru (în medie peste 17 săptămâni).",
      "COLUMN_VIOLATION_DAYS_OFF": "Zile libere",
      "COLUMN_VIOLATION_DAYS_OFF_TOOLTIP": "Numărul de evenimente în care un angajat a avut mai puțin de 2 zile libere (într-o perioadă de 2 săptămâni).",
      "COLUMN_VIOLATION_REST": "Odihnă",
      "COLUMN_VIOLATION_REST_TOOLTIP": "Numărul de evenimente în care un angajat a avut mai puțin de 11 ore de odihnă după o zi lucrătoare.",
      "COLUMN_VIOLATION_U18_DAYS_OFF": "U18: Zile libere",
      "COLUMN_VIOLATION_U18_DAYS_OFF_TOOLTIP": "Numărul de evenimente în care un angajat sub vârsta de 18 ani a avut mai puțin de 2 zile libere consecutive (într-o anumită săptămână).",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH": "U18: Schimb lung",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH_TOOLTIP": "Numărul de evenimente în care un angajat sub vârsta de 18 ani a avut o schimbare mai mare de 8 ore.",
      "COLUMN_VIOLATION_U18_REST": "U18: Odihnă",
      "COLUMN_VIOLATION_U18_REST_TOOLTIP": "Numărul de evenimente în care un angajat sub vârsta de 18 ani a avut mai puțin de 11 ore de odihnă după o zi lucrătoare.",
      "COLUMN_TOTAL": "Total",
      "ROW_TOTAL": "Total",
      "ROW_GRAND_TOTAL": "Marele Total",
      "INFO_NO_FILTER": "Selectați un site/departament de mai sus pentru a începe.",
      "DATE_ERROR_DIFF": "Nu puteți alege mai mult de 12 luni. Vă rugăm să restricționați intervalul de date.",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "VIOLATION_TYPE_1_DESC": "Nu poate depăși media de 48 de ore de lucru pe parcursul a 17 săptămâni",
      "VIOLATION_TYPE_2_DESC": "Nu pot avea mai puțin de 2 zile libere în orice perioadă de 2 săptămâni",
      "VIOLATION_TYPE_3_DESC": "Sub 18 ani: Trebuie să aibă 2 zile libere consecutive pe săptămână",
      "VIOLATION_TYPE_4_DESC": "Sub 18 ani: Schimb mai lung de 8 ore",
      "VIOLATION_TYPE_5_DESC": "Sub 18 ani: mai puțin de 12 ore de odihnă după ziua lucrătoare",
      "VIOLATION_TYPE_6_DESC": "Mai puțin de 11 ore de odihnă după ziua lucrătoare",
      "VIOLATION_TYPE_1": "Prea multe ore de lucru (în medie)",
      "VIOLATION_TYPE_2": "Zile libere insuficiente",
      "VIOLATION_TYPE_3": "Sub 18 ani: zile libere insuficiente",
      "VIOLATION_TYPE_4": "Sub 18 ani: Schimbă prea mult",
      "VIOLATION_TYPE_5": "Sub 18 ani: odihnă insuficientă între schimburi",
      "VIOLATION_TYPE_6": "Odihnă insuficientă între schimburi",
      "FILTER_VIOLATION_TYPE": "Tipul încălcării",
      "FILTER_PER_PAGE": "Rânduri pe pagină",
      "DETAIL_COLUMN_ID": "ID-UL",
      "DETAIL_COLUMN_HR_ID": "ID-UL HR",
      "DETAIL_COLUMN_PAYROLL_ID": "ID de salarizare",
      "DETAIL_COLUMN_FIRST_NAME": "Prenume",
      "DETAIL_COLUMN_LAST_NAME": "Nume de familie",
      "DETAIL_COLUMN_SITE": "Site-ul",
      "DETAIL_COLUMN_DEPARTMENT": "Divizie",
      "DETAIL_COLUMN_VIOLATION": "Încălcarea",
      "DETAIL_COLUMN_DATE_FROM": "Data de la",
      "DETAIL_COLUMN_DATE_TO": "Data până la",
      "DETAIL_COLUMN_VALUE": "Valoare",
      "DETAIL_LABEL_WORKING_HOURS": "orele de lucru",
      "DETAIL_LABEL_WORKING_DAYS": "zile lucrătoare",
      "DETAIL_LABEL_HOURS_REST": "ore de odihnă",
      "DETAIL_INFO_NO_RECORDS": "Nu aveți încălcări ale timpului de lucru, loc de muncă de top!",
      "DETAIL_INFO_EMPTY_FILTER_RESULT": "Nu există încălcări care să se potrivească cu filtrul (filtrele) dvs."
    },
    "CONTRACTUAL_OBLIGATION_SUMMARY": {
      "TITLE": "Rezumatul obligațiilor contractuale",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Sari la",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultimele două săptămâni",
      "JUMP_TO_LAST_MONTH": "Luna trecută",
      "FILTER_SITE": "Site/departament",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "FILTER_DATA_SOURCE": "Sursa de date",
      "FILTER_USER": "Persoană",
      "COLUMN_GROUP_TOTAL": "Total (plătit)",
      "COLUMN_GROUP_OBLIGATION": "Obligație contractuală",
      "COLUMN_GROUP_ANALYSIS_META": "Analizat peste",
      "COLUMN_GROUP_ANALYSIS": "Analiză",
      "COLUMN_ID": "ID-UL",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE": "Site-ul",
      "COLUMN_DEPARTMENT": "Divizie",
      "COLUMN_DAYS_WORKED": "Zile",
      "COLUMN_PAID_HOURS": "Ore",
      "COLUMN_AVERAGE_HOURS": "Mediu",
      "COLUMN_AVERAGE_HOURS_TOOLTIP": "Orele medii lucrate pe zi.",
      "COLUMN_OBLIGATION_VALUE": "Valoare",
      "COLUMN_OBLIGATION_UNIT": "Unitate",
      "COLUMN_OBLIGATION_PERIOD": "Perioada",
      "COLUMN_ANALYSIS_START": "Începe",
      "COLUMN_ANALYSIS_END": "Sfârșit",
      "COLUMN_ANALYSIS_PERIODS": "# Perioade",
      "COLUMN_ANALYSIS_PERIODS_TOOLTIP": "Numărul de săptămâni sau luni care au fost analizate.",
      "COLUMN_ANALYSIS_AVERAGE": "Mediu",
      "COLUMN_ANALYSIS_AVERAGE_TOOLTIP": "Numărul mediu de ore sau zile lucrate.",
      "COLUMN_ANALYSIS_DIFFERENCE": "Diferență.",
      "COLUMN_ANALYSIS_DIFFERENCE_TOOLTIP": "Diferența dintre ore/zilele minime care ar fi trebuit lucrate (obligația contractuală înmulțită cu numărul de perioade) și ceea ce a fost efectiv lucrat (numărul total de ore sau zile).",
      "INFO_NO_DATA": "Nu există înregistrări care să corespundă filtrelor dvs.",
      "INFO_NO_FILTER": "Selectați un site/departament de mai sus pentru a începe.",
      "PAY_AMOUNT_TYPE_HOURLY": "Rata orară",
      "PAY_AMOUNT_TYPE_DAILY": "Rata zilnică",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salariu anual",
      "DATA_SOURCE_SHIFTS": "Schimburi",
      "DATA_SOURCE_ATTENDANCE": "Participare",
      "DATA_SOURCE_MATCHED": "Sanitizat",
      "CONTRACTUAL_UNIT_HOURS": "Ore",
      "CONTRACTUAL_UNIT_DAYS": "Zile",
      "CONTRACTUAL_PERIOD_WEEK": "Săptămâna",
      "CONTRACTUAL_PERIOD_MONTH": "Lună",
      "CONTRACTUAL_PERIOD_WEEKLY": "Săptămânal",
      "CONTRACTUAL_PERIOD_MONTHLY": "Lunar",
      "DATE_ERROR_DIFF": "Nu puteți alege mai mult de 14 săptămâni. Vă rugăm să restricționați intervalul de date.",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou."
    },
    "LABOUR_BREAKDOWN": {
      "TITLE": "Defalcarea forței de muncă",
      "FILE_EXPORT_TITLE": "Export fișier de defalcare a forței de muncă",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Sari la",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultimele două săptămâni",
      "JUMP_TO_LAST_MONTH": "Luna trecută",
      "FILTER_SITE": "Site/departament",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "FILTER_DATA_SOURCE": "Sursa de date",
      "FILTER_USER": "Persoană",
      "FILTER_BEHAVIOUR": "Comportament",
      "BEHAVIOUR_COST_CONTROL": "Controlul costurilor",
      "BEHAVIOUR_PAYROLL": "Salarizare",
      "FILTER_SHIFT_TYPE": "Tipul schimbării",
      "COLUMN_ID": "ID-UL",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE_APPOINTMENT": "Site (programare)",
      "COLUMN_DEPT_APPOINTMENT": "Departament (numire)",
      "COLUMN_EVENT_TYPE": "Tipul evenimentului",
      "COLUMN_SHIFT_TYPE": "Tipul schimbării",
      "COLUMN_SITE_ATTRIBUTION": "Site (atribuire)",
      "COLUMN_DEPT_ATTRIBUTION": "Departament (atribuire)",
      "COLUMN_START_TIME": "Începe",
      "COLUMN_END_TIME": "Finisează",
      "COLUMN_PAID_HOURS": "Ore plătite",
      "COLUMN_UNPAID_HOURS": "Ore neplătite",
      "COLUMN_BASE_PAY": "Salariul de bază",
      "COLUMN_ROLLED_UP_HOLIDAY": "Vacanță acumulată",
      "COLUMN_TAXES": "Impozite",
      "COLUMN_WAGE_UPLIFT": "Creșterea salariilor",
      "COLUMN_TOTAL_COST": "Cost total",
      "INFO_NO_DATA": "Nu există înregistrări care să corespundă filtrelor dvs.",
      "INFO_NO_FILTER": "Selectați un site/departament de mai sus pentru a începe.",
      "PAY_AMOUNT_TYPE_HOURLY": "Rata orară",
      "PAY_AMOUNT_TYPE_DAILY": "Rata zilnică",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salariu anual",
      "EVENT_TYPE_SHIFT": "Schimbă",
      "EVENT_TYPE_ABSENCE": "Absența",
      "EVENT_TYPE_SALARY": "Salariu",
      "EVENT_TYPE_TAXES": "Impozite",
      "DATA_SOURCE_SHIFTS": "Schimburi",
      "DATA_SOURCE_ATTENDANCE": "Participare",
      "DATA_SOURCE_MATCHED": "Sanitizat",
      "DATE_ERROR_DIFF": "Nu puteți alege mai mult de 14 săptămâni. Vă rugăm să restricționați intervalul de date.",
      "DATE_WARNING_WEEKS": "Ați selectat un interval de date care nu se aliniază săptămânilor calendaristice. Unele impozite ale angajatorilor sunt calculate folosind praguri săptămânale, astfel încât impozitele afișate aici pot fi artificial mai mici decât realitatea. Plata de bază și vacanța acumulată rămân corecte, dar vă rugăm să aliniați datele la săptămânile calendaristice pentru taxele corecte și costul total.",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "ERROR_400": "Parametrii dvs. determină generarea prea multor date pentru un singur raport. Vă rugăm să reduceți domeniul de aplicare al cererii dvs. și încercați din nou."
    },
    "LABOUR_BREAKDOWN_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Generați și trimiteți un raport prin e-mail.",
      "REPORT_GENERATED": "Raportul dvs. este generat și vă va fi trimis prin e-mail la finalizare."
    },
    "PAYROLL_EXPORT": {
      "TITLE": "Export de salarizare",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Sari la",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultimele două săptămâni",
      "JUMP_TO_LAST_MONTH": "Luna trecută",
      "FILTER_SITE": "Site/departament",
      "EXPORT": "Export",
      "EXPORT_PAYROLL_SOFTWARE": "Software de salarizare",
      "EXPORT_PRINT": "Imprimare",
      "FILTER_AGGREGATION": "Segmentare",
      "FILTER_ABSENCE_TYPES": "Tipuri de absență",
      "FILTER_PAY_ELEMENT_TYPES": "Tipuri de elemente de plată",
      "FILTER_USER": "Persoană",
      "FILTER_COMPANY": "Companie",
      "TOGGLE_SHOW_ROLLED_UP_HOLIDAY": "Afișați vacanța adunată",
      "COLUMN_ID": "ID-UL",
      "COLUMN_HR_ID": "ID-UL HR",
      "COLUMN_PAYROLL_ID": "ID de salarizare",
      "COLUMN_FIRST_NAME": "Prenume",
      "COLUMN_LAST_NAME": "Nume de familie",
      "COLUMN_SITE_ATTRIBUTION": "Site (atribuire)",
      "COLUMN_DEPT_ATTRIBUTION": "Departament (atribuire)",
      "COLUMN_SITE_APPOINTMENT": "Site (programare)",
      "COLUMN_DEPT_APPOINTMENT": "Departament (numire)",
      "COLUMN_GROUP": "Grupul",
      "COLUMN_POSITION": "Rolul postului",
      "COLUMN_DAYS_WORKED": "Zile lucrate",
      "COLUMN_PAY_RATE": "Rata de plată",
      "COLUMN_AMOUNT_TYPE": "Tipul sumei",
      "COLUMN_PAY_TYPE": "Tipul de plată",
      "COLUMN_PAID_SHIFT_HOURS": "Ore de schimb plătite",
      "COLUMN_UNPAID_SHIFT_HOURS": "Ore de schimb neplătite",
      "COLUMN_BASE_PAY": "Salariul de bază",
      "COLUMN_ROLLED_UP_HOLIDAY": "Vacanță adunată",
      "COLUMN_ABSENCE_DAYS": "{{label}} zile",
      "COLUMN_ABSENCE_HOURS": "{{label}} ore",
      "INFO_NO_DATA": "Nu există înregistrări care să se potrivească filtrelor dvs. Te-ai deconectat? Nu uitați, acest raport utilizează numai date deconectate.",
      "INFO_NO_FILTER": "Selectați un site/departament de mai sus pentru a începe.",
      "PAY_AMOUNT_TYPE_HOURLY": "Rata orară",
      "PAY_AMOUNT_TYPE_DAILY": "Rata zilnică",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salariu anual",
      "AGGREGATION_OPTION_ATTRIBUTION": "Locul/departamentul căruia i-au fost atribuite orele",
      "AGGREGATION_OPTION_APPOINTMENT": "Situl/departamentul în care persoana se afla la momentul respectiv",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "ABSENCE_TYPE_DELETED_SUFFIX": "Șters",
      "DATE_ERROR_MIN": "Vă rugăm să selectați o dată după 12 decembrie 2018.",
      "DATE_ERROR_DIFF": "Nu puteți alege mai mult de 6 luni. Vă rugăm să restricționați intervalul de date.",
      "EXPORT_MODAL": {
        "TITLE": "Exportați în software-ul de salarizare",
        "LABEL_EXPORT_TO": "Exportați în",
        "BUTTON_DOWNLOAD": "Descarca",
        "BUTTON_CLOSE": "Închide",
        "SUCCESS": "Exportul dvs. a fost descărcat cu succes.",
        "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou."
      },
      "SIGN_OFF_STATUS_COMPLETE": "Semnat complet",
      "SIGN_OFF_STATUS_NONE": "Nu a fost semnat",
      "SIGN_OFF_STATUS_PARTIAL": "Semnat parțial",
      "SIGN_OFF_STATUS_DRAWER": {
        "TITLE": "Starea deconectării",
        "BACK_TO_DATES": "Înapoi la date"
      },
      "FILTER_PAYROLL_CALENDAR": "Calendarul de salarizare",
      "FILTER_PAY_RUN": "Plătiți rularea"
    },
    "ROTA_PROGRESS": {
      "TITLE": "Rota Progress",
      "JUMP_TO": "Sari la",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_NEXT_WEEK": "Săptămâna viitoare",
      "FILTER_SITE": "Site/departament",
      "LIFECYCLE_DRAFT": "Proiect",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Marcat pentru aprobare",
      "LIFECYCLE_PUBLISHED": "Publicat",
      "INCLUDE_NO_DRAFT": "Afișează site-uri fără schiță",
      "NO_DRAFT": "Fără proiect",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "COLUMN_SITE": "Site/departament",
      "COLUMN_LIFECYCLE": "Statut",
      "COLUMN_SCHEDULED_HOURS": "Ore programate",
      "COLUMN_SCHEDULED_WAGES": "Salariile programate",
      "COLUMN_FORECAST_SALES": "Vânzări prognozate",
      "COLUMN_WAGE_PERCENTAGE": "Salariu%",
      "ROW_GRAND_TOTAL": "Marele Total",
      "TOOLTIP_BUDGET": "Buget",
      "INFO_NO_DATA": "Nu există site-uri/departamente care să se potrivească filtrelor dvs.",
      "INFO_NO_FILTER": "Selectați un site/departament de mai sus pentru a începe.",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou."
    },
    "SIGN_OFF_PROGRESS": {
      "TITLE": "Progresul semnării",
      "JUMP_TO": "Sari la",
      "JUMP_TO_LAST_WEEK": "Săptămâna trecută",
      "JUMP_TO_THIS_WEEK": "Săptămâna aceasta",
      "JUMP_TO_NEXT_WEEK": "Săptămâna viitoare",
      "FILTER_SITE": "Site/departament",
      "FILTER_STATUS": "Starea semnării",
      "STATUS_NO_ROTA": "Fără roată",
      "STATUS_INCOMPLETE": "Incomplet",
      "STATUS_REPORTING_DATE": "Data raportării",
      "STATUS_COMPLETE": "Complet",
      "STATUS_TOOLTIP_NO_ROTA": "Săptămâna aceasta nu a fost publicată nicio rotație.",
      "STATUS_TOOLTIP_INCOMPLETE": "Semnarea nu a fost finalizată pentru fiecare zi din această săptămână.",
      "STATUS_TOOLTIP_REPORTING_DATE": "Semnarea a fost finalizată pentru zilele până la data de raportare inclusiv.",
      "STATUS_TOOLTIP_COMPLETE": "Semnarea a fost finalizată pentru fiecare zi din această săptămână.",
      "TOGGLE_COMPARAND": "Comparand",
      "TOGGLE_COMPARAND_FORECAST": "Prognoză",
      "TOGGLE_COMPARAND_BUDGET": "Buget",
      "EXPORT": "Export",
      "EXPORT_PRINT": "Imprimare",
      "COLUMN_WAGES": "Salariile",
      "COLUMN_SALES": "Vânzări",
      "COLUMN_WAGE_PERCENTAGE": "Salariu%",
      "COLUMN_HOURS": "Ore",
      "COLUMN_SITE": "Site/departament",
      "COLUMN_STATUS": "Statut",
      "COLUMN_FORECAST": "Prognoză",
      "COLUMN_BUDGET": "Buget",
      "COLUMN_ACTUAL": "Actuala",
      "ROW_GRAND_TOTAL": "Marele Total",
      "INFO_NO_DATA": "Nu există site-uri/departamente care să se potrivească filtrelor dvs.",
      "INFO_NO_FILTER": "Selectați un site/departament de mai sus pentru a începe.",
      "ERROR_500": "Nu am putut să vă încărcăm raportul chiar atunci. Vă rugăm să încercați din nou.",
      "STATUS_MODAL": {
        "REVERSE_CONFIRM": "Sunteți sigur că doriți să anulați această semnare?\n\nAceasta va debloca schimburile și înregistrările de prezență la aceste date, astfel încât să poată fi făcute modificări.",
        "REVERSE_SUCCESS": "Semnarea a fost inversată cu succes.",
        "REVERSE_ERROR": "Nu am putut inversa semnarea chiar atunci. Vă rugăm să încercați din nou.",
        "TITLE": "Starea semnării",
        "COLUMN_DATE": "Data",
        "COLUMN_STATUS": "Statut",
        "COLUMN_OPTIONS": "Opțiuni",
        "STATUS_NO_SIGN_OFF": "Fără semnare",
        "STATUS_SIGN_OFF": "Deconectat",
        "BUTTON_REVERSE_DAY": "Reversa",
        "BUTTON_REVERSE_WEEK": "Inversați întreaga săptămână",
        "BUTTON_CLOSE": "Închide"
      }
    },
    "TRONC_STATUS": {
      "TITLE": "Starea Tronc",
      "LIFECYCLE_DRAFT": "Proiect",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Marcat pentru aprobare",
      "LIFECYCLE_LOCKED": "Încuiat",
      "INCLUDE_NO_HEADER": "Afișează site-uri fără schiță",
      "NO_HEADER": "Fără proiect",
      "COLUMN_SITE": "Site/departament",
      "COLUMN_LIFECYCLE": "Statut",
      "COLUMN_RECEIVED": "Total primit",
      "COLUMN_PAID_OUT": "Total plătit",
      "COLUMN_EDITED_WHO": "Editat de",
      "COLUMN_EDITED_WHEN": "Când",
      "INFO_NO_DATA": "Nu există site-uri/departamente Tronc care să corespundă filtrelor dvs.",
      "ERROR_500": "Nu am putut încărca Tronc chiar atunci. Vă rugăm să încercați din nou."
    },
    "CURRENTLY_CLOCKED_IN": {
      "TITLE": "Persoanele vizate în prezent",
      "NAME": "Nume",
      "SITE": "Site/departament",
      "POSITION": "Rolul postului",
      "CLOCK_IN_TIME": "Timpul ceasului",
      "NOBODY_CLOCKED_IN": "Nimeni nu este băgat în vizită chiar acum!",
      "ERROR_500": "Nu am putut încărca raportul chiar atunci. Vă rugăm să încercați din nou."
    },
    "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Generare și trimitere raport prin email.",
      "REPORT_GENERATED": "Raportul dvs. este în curs de generare și vă va fi trimis prin email când va fi completat.",
      "FILTER_DATE": "Dată",
      "FILTER_ENTITY": "Locație/departament",
      "FILTER_PAY_TYPES": "Tipuri de plată",
      "FILTER_DATA_SOURCE": "Sursa de date",
      "FILTER_USER": "Persoană",
      "FILTER_EVENT_TYPES": "Tipuri de evenimente",
      "FILTER_BEHAVIOUR": "Comportament",
      "FILTER_SHIFT_TYPE": "Tip de schimb",
    },
    "PAYROLL_EXPORT_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Generare și trimitere raport prin email.",
      "REPORT_GENERATED": "Raportul dvs. este în curs de generare și vă va fi trimis prin email când va fi completat.",
      "FILTER_PAYROLL_CALENDAR": "Calendar de salarizare",
      "FILTER_PAY_RUN": "Execuție salarizare",
      "FILTER_DATE": "Dată",
      "FILTER_ENTITY": "Locație/departament",
      "FILTER_AGGREGATION_OPTIONS": "Segmentare",
      "FILTER_USER": "Persoană",
      "FILTER_ABSENCE_TYPES": "Tipuri de absență",
      "FILTER_PAY_ELEMENT_TYPES": "Tipuri de elemente de plată",
      "FILTER_PAY_AMOUNT_TYPE": "Tip de sumă de plată",
      "FILTER_ROLLED_UP_HOLIDAY": "Concediu cumulat",
      "FILTER_COMPANY": "Companie",
    },
  },
  "ROTA_MANAGEMENT": {
    "DASHBOARD": {
      "INDEX": {
        "TITLE": "Tablou de bord",
        "CHECKLIST": "Lista de verificare Rota",
        "LABOUR_SPEND": "Cheltuieli de muncă"
      },
      "LABOUR_SPEND_TAB": {
        "TITLE": "Prezentare generală a forței de muncă",
        "SHOW_ACTUAL_TIP": "Pentru zilele din această săptămână care nu au trecut încă, proiectați vânzările prognozate și salariile/orele programate în totalurile reale",
        "SHOW_ACTUAL_TEXT": "Prognoza proiectului în realitate",
        "FORECAST": "Prognoză",
        "ACTUAL": "Actuala",
        "BUDGET": "Buget",
        "SALES": "Vânzări",
        "WAGES": "Salariile",
        "HOURS": "Ore",
        "WAGE_PERCENT": "Salariu%",
        "LABOUR_COMP": "Comparația forței de muncă",
        "ACTUAL_PROJECTED": "Actuală (proiectată)",
        "TODAYS_VS_SCHEDULED": "Prezența de astăzi vs programată"
      },
      "WTD_VIOLATIONS_MODAL": {
        "TITLE": "Încălcări ale timpului de lucru",
        "BODY": "Acestea sunt ocazii în care am constatat că programele dvs. încalcă reglementările privind timpul de lucru.",
        "TO": "la ",
        "ALL": "Toate încălcările",
        "TOO_MANY": "Prea multe ore de lucru (în medie)",
        "INSUF_DAYS": "Zile libere insuficiente",
        "INSUF_REST": "Odihnă insuficientă între schimburi",
        "UNDER_18_DAYS": "Sub 18 ani: zile libere insuficiente",
        "UNDER_18_LONG": "Sub 18 ani: Schimbă prea mult",
        "UNDER_18_REST": "Sub 18 ani: odihnă insuficientă între schimburi",
        "STAFF_MEMBER": "Membru al personalului",
        "VIOLATION": "Încălcarea",
        "VALUE": "Valoare",
        "NO_VIOLATIONS": "Nu aveți încălcări ale timpului de lucru, loc de muncă de top!",
        "NO_FILTER_VIOLATIONS": "Nu există încălcări care să se potrivească cu filtrul (filtrele) dvs.",
        "AVE_48_OVER_17": "Nu poate depăși media de 48 de ore de lucru pe parcursul a 17 săptămâni",
        "LESS_2_DAYS_2_WEEKS": "Nu pot avea mai puțin de 2 zile libere în orice perioadă de 2 săptămâni",
        "UNDER_18_2_PER_WEEK": "Sub 18 ani: Trebuie să aibă 2 zile libere consecutive pe săptămână",
        "UNDER_18_LONGER_8": "Sub 18 ani: Schimb mai lung de 8 ore",
        "UNDER_18_LESS_12_REST": "Sub 18 ani: mai puțin de 12 ore de odihnă după ziua lucrătoare",
        "LESS_11_WEEK": "Mai puțin de 11 ore de odihnă după ziua lucrătoare",
        "WORKING_HOURS": " orele de lucru",
        "WORKING_DAYS": " zile lucrătoare",
        "HOURS_REST": " ore de odihnă",
        "RECALC": "Re-calculați",
        "LOADING": "Se încarcă...",
        "CLOSE": "Închide"
      }
    },
    "LOCATIONS": {
      "ADD_LOCATION_MODAL": {
        "NEW_SHIFT_LOCATION": "Noua zonă de prezență",
        "TYPE": "Tip",
        "NEW_LOCATION": "Zonă nouă",
        "CHANGE_EXISTING_LOCATION": "Schimbați zona existentă",
        "EXISTING_LOCATION": "Zona existentă",
        "NAME": "Nume",
        "DETAILS": "Detalii",
        "HELP": "Rotaready își amintește poziția exactă a markerului pe hartă, deci aceste detalii sunt doar pentru referință. Simțiți-vă liber să includeți câteva note sau o adresă completă.",
        "SAVING": "Salvarea...",
        "SAVE": "Salvați",
        "CANCEL": "Anulează",
        "ERROR_ENTER_NAME": "Vă rugăm să introduceți un nume pentru această zonă.",
        "ERROR_UPDATE": "Momentan nu se poate actualiza această zonă. Vă rugăm să încercați din nou.",
        "ERROR_ADD": "Momentan nu se poate adăuga această zonă. Vă rugăm să încercați din nou."
      },
      "EDIT_LOCATION_MODAL": {
        "EDIT_LOCATION": "Editați zona",
        "HIERARCHY": "Ierarhie",
        "MASTER_LOCATION": "Zona principală",
        "SUB_LOCATION": "Subzona",
        "HELP_HIERARCHY": "Rotaready vă permite să creați o ierarhie a zonelor. O subzonă poate fi plasată sub orice altă zonă.",
        "PARENT_LOCATION": "Zona părintească",
        "NAME": "Nume",
        "DETAILS": "Detalii",
        "HELP_DETAILS": "Rotaready își amintește poziția exactă a markerului pe hartă, deci aceste detalii sunt doar pentru referință. Simțiți-vă liber să includeți câteva note sau o adresă completă.",
        "ATTENDANCE": "Participare",
        "ALLOW_CLOCKING": "Permiteți personalului să se angajeze și să cedeze aici",
        "STAFF_WORKING": "Numai atunci când personalul lucrează într-o tură marcată cu această zonă",
        "HELP_CLOCKING": "Aceste setări influențează dacă personalul poate intra și ieși cu ceasul folosind aplicația mobilă Rotaready.",
        "ATTENDANCE_SENSITIVITY": "Sensibilitate la prezență",
        "METRES": "metri",
        "HELP_CLOSE_LOCATION": "Aceasta definește cât de aproape trebuie să fie cineva de zonă înainte ca aplicația mobilă Rotaready să-i permită să intre sau să iasă. Nu este recomandat mai puțin de 50 de metri, deoarece persoanele cu semnal slab nu vor putea să cedeze.",
        "MIN_OCCUPANCY": "Ocuparea minimă",
        "HELP_MIN_OCCUPANCY": "Ori de câte ori cineva este în tură, Rotaready vă va avertiza dacă numărul total de persoane aflate în schimb scade sub acest număr.",
        "MAX_OCCUPANCY": "Capacitate maximă",
        "HELP_MAX_OCCUPANCY": "Dacă este furnizat, Rotaready nu vă va permite să programați mai mult personal aici (la un moment dat) decât gradul maxim de ocupare.",
        "TIP_OPENING": "Specificați orele de deschidere pentru ca Rotaready să vă împiedice să programați schimburi în această zonă atunci când este închisă.",
        "TIP_SUB_LOC": "Deoarece aceasta este o subzonă, dacă zona părinte este închisă în orice moment, această subzonă va fi considerată închisă și ea.",
        "REGULAR_OPENING_TIMES": "Orar regulat de deschidere",
        "OPEN_247": "Această zonă este deschisă 24/7.",
        "TO": "la",
        "SPECIAL_PERIODS": "Perioade speciale",
        "NO_SPECIAL_PERIODS": "Nu există perioade speciale.",
        "OPEN": "Deschis",
        "CLOSED": "Închis",
        "ADD_SPECIAL_PERIOD": "Adăugați o perioadă specială",
        "REASON": "Motivul",
        "REASON_PLACEHOLDER": "de ex. Renovare de vară",
        "FROM": "De la",
        "TO_CAPS": "Către",
        "ADD": "Adaugă",
        "DELETE": "Șterge",
        "SAVING": "Salvarea...",
        "SAVE": "Salvați",
        "CANCEL": "Anulează",
        "ALERT_NAME": "Vă rugăm să vă asigurați că ați furnizat un nume valid pentru zona de prezență.",
        "ALERT_PARENT": "Pentru ca aceasta să fie o subzonă, trebuie să selectați o zonă părinte.",
        "ALERT_VALID_PARENT": "Vă rugăm să selectați o zonă părinte validă.",
        "ALERT_PARENT_LOCATION": "Această zonă de prezență și zona părinte aleasă trebuie să aparțină aceluiași Site (sau ambele să fie la nivel de organizație).",
        "ALERT_MINIMUM": "Nu puteți avea un nivel minim de ocupare mai mare decât nivelul maxim de ocupare.",
        "ERROR_UPDATE": "Nu am putut actualiza zona chiar atunci. Vă rugăm să încercați din nou.",
        "ALERT_SUB_CONFIRM": "Această zonă are încă subzone. Vor fi șterse și ele.\n\nEști sigur că vrei să continui?",
        "ALERT_CONFIRM": "Sigur doriți să ștergeți această zonă?",
        "ERROR_DELETE": "Nu am putut șterge zona chiar atunci. Vă rugăm să încercați din nou!"
      },
      "INDEX": {
        "SHIFT_LOCATIONS": "Zonele de prezență",
        "TIP_FIND": "Pentru a adăuga o zonă nouă sau pentru a schimba o zonă existentă, trebuie mai întâi să o găsiți cu harta. Căutați orice adresă sau cod poștal, apoi trageți marcajul verde pentru a-l repoziționa.",
        "PLACEHOLDER_SEARCH": "Căutați pentru a adăuga o zonă nouă",
        "HELP_NO_LOCATIONS": "Nu aveți zone existente. Căutați pe hartă pentru a adăuga una.",
        "HELP_NO_RESULTS": "Nicio zonă de prezență nu se potrivește termenilor dvs. de căutare.",
        "TOOLTIP_SHOW": "Arată pe hartă",
        "TOOLTIP_CLOCKING_ALWAYS": "Personalul poate oricând să intre și să iasă cu aplicația mobilă Rotaready aici",
        "TOOLTIP_CLOCKING_WORKING": "Personalul poate intra în/ieși cu aplicația mobilă Rotaready aici, numai dacă lucrează în schimb aici",
        "ERROR_LOAD": "Nu am putut încărca zonele dvs. de prezență. Vă rugăm să încercați din nou.",
        "NO_ADDRESS": "Nu există detalii/adresă",
        "ERROR_LOOKUP": "Nu am reușit să căutăm locația acelui marker.",
        "UNKNOWN_ADDRESS": "Adresă necunoscută",
        "ADD_AS_NEW": "Adaugă ca zonă nouă",
        "DRAG_TO_CHANGE": "Trageţi-mă pentru a schimba locaţia",
        "FILTER_LOCATIONS": "Filtrează zonele..."
      }
    },
    "SHIFT_TYPES": {
      "EDIT_SHIFT_TYPES_MODAL": {
        "TITLE_NEW": "Noul tip de schimbare",
        "TITLE_EDIT": "Editați tipul de schimbare",
        "SITE_LABEL": "Site-ul",
        "HIERARCHY_LABEL": "Ierarhie",
        "MASTER_TYPE_LABEL": "Tipul schimbului principal",
        "SUB_TYPE_LABEL": "Tipul subschimbării",
        "SUB_TYPE_HELP": "Rotaready vă permite să creați o ierarhie a tipurilor de schimburi. Un tip de subschimbare poate fi plasat sub orice alt tip de schimbare.",
        "PARENT_TYPE_LABEL": "Tipul schimbului părinte",
        "NAME_LABEL": "Nume",
        "COLOUR_LABEL": "Culoare",
        "COST_STREAM_MAPPINGS_LABEL": "Mapări fluxuri",
        "COST_STREAM_TABLE": {
          "COST_STREAM_HEADING": "Stream",
          "ATTRIBUTED_HEADING": "atribuit",
          "EMPTY_LABEL": "Acest lucru nu este mapat la niciun flux.",
          "ADD_BUTTON": "Adaugă nou"
        },
        "DELETE_BUTTON": "Șterge",
        "SAVE_BUTTON": "Salvați",
        "CANCEL_BUTTON": "Anulează",
        "VALIDATION": {
          "NAME_REQUIRED": "Vă rugăm să vă asigurați că ați furnizat un nume valid pentru tipul de schimb.",
          "PARENT_REQUIRED": "Pentru ca acesta să fie un tip de subschimbare, trebuie să selectați un tip de schimbare părinte.",
          "PARENT_VALID": "Vă rugăm să selectați un tip valid de schimb părinte.",
          "PARENT_SAME_SITE": "Acest tip de schimb și tipul de schimb pentru părinți ales trebuie să aparțină aceluiași Site (sau ambele să fie la nivelul întregii Organizații).",
          "ATTRIBUTION_OVERAGE": "Am rezumat procentul atribuit fiecărui flux, iar totalul depășește 100%. Puteți atribui maximum 100% pentru unul sau mai multe fluxuri.",
          "ATTRIBUTION_VALID": "Ați introdus un „procent atribuit” nevalid pentru unul sau mai multe fluxuri. O valoare de „50”, de exemplu, va atribui 50% din costul salarial al oricărei persoane care lucrează în acest tip de schimb fluxului corespunzător.",
          "ATTRIBUTION_NONE": "Trebuie să atribuiți tipul de schimbare la cel puțin un flux.",
          "DUPLICATE_COST_STREAM_MAPPING": "O cartografiere pentru acest flux există deja! Nu puteți atribui un tip de schimbare aceluiași flux de două ori."
        },
        "ERRORS": {
          "ADD_ERROR": "Nu am putut adăuga tipul dvs. de schimb chiar atunci. Probabil că există deja un tip de schimb cu același nume! Încercați să alegeți un alt nume.",
          "UPDATE_ERROR": "Nu am putut actualiza tipul de schimbare chiar atunci. Probabil că există deja un tip de schimb cu același nume! Încercați să alegeți un alt nume.",
          "DELETE_TYPE": "Nu am putut șterge tipul de schimbare chiar atunci. Vă rugăm să încercați din nou!"
        },
        "CONFIRM": {
          "DELETE_SUB_TYPES": "Acest tip de schimbare are încă tipuri de subschimburi. Vor fi șterse și ele. Ești sigur că vrei să continui?",
          "DELETE_TYPE": "Sigur doriți să ștergeți acest tip de schimbare?"
        }
      },
      "INDEX": {
        "TITLE": "Tipuri de schimburi",
        "SUBTITLE": "Gândiți-vă la tipurile de ture ca la categorii pentru diferitele tipuri de muncă pe care oamenii le desfășoară în organizația dvs.:",
        "SUB_TYPE_HELP": "Puteți adăuga un „tip de schimbare sub” făcând clic pe pictograma plus de lângă orice tip de schimbare. Acest lucru este util dacă trebuie să împărțiți un tip de schimbare în sarcini sau roluri mai specifice.",
        "TYPES_EMPTY": "Nu există tipuri de schimburi de afișat.",
        "ONLY_ENTITY": "doar",
        "WHOLE_ORGANISATION": "La nivel de organizație",
        "SHOW_MORE": "Arată mai mult...",
        "ERRORS": {
          "LOAD_ERROR": "Hopa, nu am putut încărca tipurile de schimburi chiar atunci. Vă rugăm să încercați din nou!"
        },
        "TOOLTIPS": {
          "ADD_SUB_SHIFT_TYPE": "Adăugați tipul de schimbare sub"
        },
        "ADD_SHIFT_TYPE": "Adăugați tipul de schimbare"
      }
    },
    "TEMPLATES": {
      "EDIT_CONSTRAINTS_MODAL": {
        "ADD_TITLE": "Adaugă un nou {{currentLevelName}}",
        "EDIT_TITLE": "Editați constrângerile {{currentLevelName}}",
        "NAME_LABEL": "nume",
        "LOADING": {
          "SAVE": "Salvarea..."
        },
        "SAVE_AND_CLOSE_BUTTON": "Salvare și închidere",
        "CANCEL_BUTTON": "Anulează",
        "OVERRIDE_CONSTRAINT_BUTTON": "Suprascrie constrângerea {{levelName}}",
        "DELETE_OVERRIDE_BUTTON": "Ștergeți suprascrierea",
        "TAB_HEADINGS": {
          "POPULAR": "Popular",
          "STAFF": "Personalul",
          "REST": "Odihnă",
          "OTHERS": "Altele"
        },
        "STAFF_SELECTION": {
          "TITLE": "Selecția personalului",
          "FLEXIBLE_STAFFING_LABEL": "Personal flexibil",
          "FAIRLY_HINT": "Alege corect din tot personalul",
          "FAIRLY_ANY_TAGS_HINT": "Alegeți corect dintre cei care se potrivesc cu <strong>oricare</strong> dintre etichetele de mai jos",
          "FAIRLY_ALL_TAGS_HINT": "Alegeți corect dintre cei care se potrivesc <strong>cu toate</strong> etichetele de mai jos",
          "POOLING_HINT": "Tratați etichetele de mai jos ca grupuri de personal și epuizați-le pe fiecare <strong>în ordine</strong>",
          "FIXED_STAFFING_LABEL": "Personal fix",
          "SPECIFIC_STAFF_MEMBER_HINT": "Atribuiți schimbul (schimburile) unui anumit membru al personalului",
          "ASSIGNEE_LABEL": "Cesionar",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tastați pentru a adăuga etichete"
          }
        },
        "PREFERRED_STAFF_SELECTION": {
          "TITLE": "Selecția personalului preferat",
          "HINT": "Dacă mai mulți angajați sunt eligibili pentru o anumită tură, Rotaready va încerca să-și influențeze selecția în funcție de criteriile alese:",
          "NO_PREFERENCE": "Nu preferați niciun personal față de alții, cu excepția motivelor corectitudinii",
          "PREFER_ANY_TAG": "Preferă cei care se potrivesc <strong>cu oricare</strong> dintre etichetele de mai jos",
          "PREFER_ALL_TAG": "Preferă cei care se potrivesc <strong>cu toate</strong> etichetele de mai jos",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tastați pentru a adăuga etichete"
          }
        },
        "STAFF_SELECTION_GROUPS": {
          "TITLE": "Selecția personalului (grupuri)",
          "HINT": "Acest lucru vă permite să restricționați selecția personalului la cei care sunt membri ai oricăruia dintre grupurile de mai jos. Grupurile vor fi alocate în ordinea în care sunt specificate:",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tastați pentru a adăuga etichete"
          }
        },
        "STAFF_TYPECASTING": {
          "TITLE": "Tipecasting al personalului",
          "HINT": "<em>Când atribuim pe cineva unei ture <em>tipografice</em>, i se pot atribui doar schimburi suplimentare în acea zi care sunt a) de asemenea ti <em>părite</em> cu cel puțin una dintre aceste etichete sau b) deloc tipărite:</em>",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tastați pentru a adăuga etichete"
          }
        },
        "OUTSOURCING": {
          "TITLE": "Externalizare",
          "HINT": "În cazul în care nu există personal intern disponibil, permiteți externalizarea către următoarele organizații, în ordinea în care acestea sunt specificate:",
          "PLACEHOLDERS": {
            "TYPE_OUTSOURCE_ORGANISATIONS": "Tip pentru a adăuga organizații de externalizare"
          }
        },
        "SELECTION_CONSISTENCY": {
          "TITLE": "Coerența selecției",
          "HINT": "Pentru seturile de ture care se întind pe mai multe zile într-o anumită săptămână, atunci când cineva este alocat pentru tura din prima zi, ar trebui:",
          "NO_EFFECT": "nu au niciun efect asupra faptului dacă sunt aleși pentru schimburile de altă zi",
          "ALLOCATE": "să fie alocate tuturor celorlalte ture de zi din setul de ture, acolo unde este posibil"
        },
        "REST_PERIOD": {
          "TITLE": "Perioada de odihnă",
          "HINT": "După ce ați lucrat într-o tură, asigurați-vă că un membru al personalului este odihnit (și, prin urmare, nu i se acordă o altă tură) cel puțin:"
        },
        "LOCATION_TOLERANCE": {
          "TITLE": "Toleranța locației",
          "HINT": "Este adesea important ca două schimburi consecutive cu aceeași locație specificată să fie atribuite aceluiași membru al personalului. Specificați numărul maxim de minute între două astfel de schimburi (sfârșitul primei până la începutul celei de-a doua) pentru a le considera consecutive în această privință:",
          "MINUTES": "minute",
          "PLACEHOLDERS": {
            "MINUTES": "Minute între sfârșitul unei ture până la începutul alteia"
          }
        },
        "DAILY_WINDOW_DEFINITION": {
          "TITLE": "Definiția zilnică a ferestrei",
          "HINT": "Unele organizații au personalul lor care lucrează mai multe „ture” într-o anumită zi, iar pentru fiecare persoană, acest volum zilnic de ture trebuie să se încadreze într-o anumită perioadă de timp. Aceste ferestre de timp pot varia și puteți defini toate variațiile posibile aici:",
          "LIMITED_TIMES": "Avem un set limitat, fix de ore de început/sfârșit pentru ferestrele noastre",
          "REGULAR_INTERVALS": "Ferestrele noastre cad la intervale regulate după o anumită oră de pornire"
        },
        "TIMES": {
          "WINDOW_TIMES_TITLE": "Timpurile ferestrelor",
          "SPLIT_SHIFT_WINDOW_TIMES": "Timpuri de fereastră cu schimbare separată",
          "WINDOW_START_TIME": "Ora de începere a celei mai vechi ferestre",
          "WINDOW_INTERVAL": "Interval între ferestrele valide",
          "WINDOW_FINISH_TIME": "Ora de finalizare a ultimei ferestre posibile",
          "PLACEHOLDERS": {
            "COMMA_SEPERATED_TIMES": "Lista de ore separată prin virgulă, adică 9:30-17:30,13:00-20:00"
          }
        },
        "SHIFT_PAY_RATE": {
          "TITLE": "Rata de plată în schimburi",
          "HINT": "Personalul nesalariat care lucrează în schimburi generate de acest {{currentLevelName}} va câștiga la tariful orar obișnuit. Puteți suprascrie acest lucru mai jos, dacă este necesar:",
          "OPTIONS": {
            "NORMAL": "Normal: personalul câștigă la tariful orar obișnuit",
            "ZERO": "Zero: personalul nu este plătit pentru această schimbare",
            "MULTIPLE": "Multiple: personalul câștigă un multiplu din tariful orar obișnuit",
            "FIXED": "Fix: suprascrie tariful orar obișnuit cu o rată fixă"
          },
          "MULTIPLIER": "Multiplicator de rată",
          "HOURLY_RATE_OVERRIDE": "Anularea tarifelor orare",
          "PLACEHOLDERS": {
            "MULTIPLIER": "Multiplicator orar al ratei",
            "HOURLY_RATE_OVERRIDE": "Rata orară în moneda locală, adică 8,50"
          }
        },
        "SHIFT_TYPE": {
          "TITLE": "Tipul schimbării",
          "HINT": "Aceasta denotă „tipul” de schimbări care vor fi generate din acest {{currentLevelName}}. Personalul dvs. îl va vedea ca titlul schimbului, iar dacă utilizați funcțiile de control al costurilor Rotaready, fiecare tip de schimb poate fi mapat la unul sau mai multe fluxuri de costuri."
        },
        "SITE_DEPARTMENT": {
          "TITLE": "Site/departament",
          "HINT": "Schimbările de atribute generate de acest {{currentLevelName}} către un alt sit/departament. Acest lucru este util pentru generarea schimburilor de acoperire.",
          "ATTRIBUTE_TO_SITE": "Atribuiți costul salarial și orele site-ului ales"
        }
      },
      "EDIT_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Adăugați setul de schimburi",
        "ADD_SUBTITLE": "Furnizați detalii despre acest set de schimburi",
        "EDIT_TITLE": "Editați setul de schimburi",
        "EDIT_SUBTITLE": "Modificați detaliile despre acest set de schimburi",
        "TIP": "Planificatorul automat Rotaready va genera schimburi din acest set de ture și va aloca personalul corespunzător, într-o manieră echitabilă și economică.",
        "TIME_LABEL": "Ora de începere/sfârșit",
        "TO": "la",
        "OVERFLOW_WARNING": "Această schimbare se va revărsa în ziua următoare.",
        "LOCATION_LABEL": "Zona de prezență",
        "MINIMUM_LABEL": "Cel puțin",
        "MINIMUM_EXPLANATION": "Vreau un număr minim de personal pe zi, dar mai mult dacă este posibil",
        "STAFF_NUMBER_LABEL": "Exact",
        "STAFF_NUMBER_EXPLANATION": "Vreau un număr exact de personal pe zi",
        "MAXIMUM_LABEL": "Până la",
        "MAXIMUM_EXPLANATION": "Vreau cât mai mulți angajați pe zi, până la o limită maximă",
        "SHORT_DAYS": {
          "MONDAY": "Lun",
          "TUESDAY": "Marţi",
          "WEDNESDAY": "Mie",
          "THURSDAY": "joi",
          "FRIDAY": "Vineri",
          "SATURDAY": "Sâmbătă",
          "SUNDAY": "Soare"
        },
        "SAVE_BUTTON": "Salvați setul de schimburi",
        "CANCEL_BUTTON": "Anulează",
        "LOADING": {
          "SAVE": "Salvarea..."
        },
        "ERRORS": {
          "UNABLE_TO_ADD": "Momentan nu se poate adăuga acest set de schimburi. Vă rugăm să încercați din nou."
        }
      },
      "EDIT_TEMPLATE": {
        "LOADING": {
          "SAVE": "Salvarea..."
        },
        "EDIT_CONSTRAINTS": "Editați constrângerile",
        "CONSTRAINTS_LABEL": "Constrângeri de șablon",
        "CONSTRAINTS_TEXT": "Setați constrângeri care se vor aplica întregului șablon.",
        "PLACEHOLDERS": {
          "SEARCH_FILTER": "Filtrează..."
        },
        "ADD_GROUP_BUTTON": "Adaugă grup",
        "TAB_HEADINGS": {
          "FLEXIBLE_STAFFING": "Personal flexibil",
          "FIXED_STAFFING": "Personal fix"
        },
        "TOOLTIPS": {
          "ACTIVE_CONSTRAINTS": "Constrângeri active",
          "DISABLE": "Dezactivați",
          "ENABLE": "Activați"
        },
        "GROUP_OPTIONS_BUTTON": {
          "LABEL": "Opțiuni de grup",
          "ADD_TASK": "Adaugă activitate",
          "ADD_SHIFT_SET": "Adăugați setul de schimburi",
          "EDIT_CONSTRAINTS": "Editați constrângerile",
          "DELETE_GROUP": "Ștergeți grupul"
        },
        "HOURS": "ore",
        "SHORT_DAYS": {
          "MONDAY": "Lun",
          "TUESDAY": "Marţi",
          "WEDNESDAY": "Mie",
          "THURSDAY": "joi",
          "FRIDAY": "Vineri",
          "SATURDAY": "Sâmbătă",
          "SUNDAY": "Soare"
        },
        "CHAR_DAYS": {
          "MONDAY": "M",
          "TUESDAY": "T",
          "WEDNESDAY": "W",
          "THURSDAY": "T",
          "FRIDAY": "F",
          "SATURDAY": "S",
          "SUNDAY": "S"
        },
        "NO_SHIFT_SETS": "Nu există seturi de schimburi în acest grup. Adăugați unul folosind butonul „Opțiuni de grup”.",
        "OPTIONS_BUTTON": {
          "LABEL": "Opțiuni",
          "ADD_SHIFT_SET": "Adăugați setul de schimburi",
          "EDIT_CONSTRAINTS": "Editați constrângerile",
          "DELETE_TASK": "Ștergeți sarcina"
        },
        "NO_FLEXIBLE_STAFF_GROUPS_THIS_WEEK": "Nu aveți grupuri flexibile de personal. De ce să nu adăugați unul făcând clic pe „Adăugați grup” de mai sus?",
        "NO_FLEXIBLE_STAFF_GROUPS": "Nu există grupuri flexibile de personal care să se potrivească cu ceea ce căutați.",
        "GROUP_SHIFT_SETS_TABLE": {
          "HEADINGS": {
            "SHIFT_TYPE": "Tipul schimbării",
            "TIME": "Timpul",
            "DAYS_OF_WEEK": "Zile săptămânii"
          }
        },
        "NO_FIXED_STAFF_GROUPS_THIS_WEEK": "Nu aveți grupuri fixe de personal. De ce să nu adăugați unul făcând clic pe „Adăugați grup” de mai sus?",
        "NO_FIXED_STAFF_GROUPS": "Niciun grup fix de personal nu se potrivește cu ceea ce căutați.",
        "EDIT_BUTTON": "Editați",
        "TOGGLE_DROPDOWN_BUTTON": "Comutați meniul derulant",
        "DELETE_BUTTON": "Șterge",
        "SAVE_BUTTON": "Salvați",
        "ERRORS": {
          "COULDNT_FIND_TEMPLATE": "Nu am putut găsi șablonul pe care îl căutați. Vă rugăm să încercați din nou.",
          "OOPS": "Hopa, ceva nu a mers bine. S-ar putea să trebuiască să încercați din nou!",
          "TEMPLATE_STATE_CHANGE": "Nu am putut activa/dezactiva o parte din șablonul dvs. chiar acum. Vă rugăm să încercați din nou."
        },
        "LEVEL_NAMES": {
          "GLOBAL": "global",
          "TEMPLATE": "șablon",
          "WEEK": "săptămână",
          "GROUP": "grup",
          "TASK": "sarcină",
          "SHIFT_SET": "set de schimbare"
        },
        "CONFIRM": {
          "DELETE": "Sunteți sigur că doriți să ștergeți acest lucru?",
          "DELETE_SHIFT_SET": "Sigur doriți să ștergeți acest set de schimburi?"
        }
      },
      "FIXED_STAFFING_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Adăugați setul de schimburi",
        "ADD_SUBTITLE": "Furnizați detalii despre acest set de schimburi",
        "EDIT_TITLE": "Editați setul de schimburi",
        "EDIT_SUBTITLE": "Modificați detaliile despre acest set de schimburi",
        "START_END_LABEL": "Ora de începere/sfârșit",
        "SHIFT_OVERFLOW_WARNING": "Această schimbare se va revărsa în ziua următoare.",
        "SHIFT_TYPE_LABEL": "Tipul schimbării",
        "LOCATION_LABEL": "Zona de prezență",
        "SHORT_DAYS": {
          "MONDAY": "Lun",
          "TUESDAY": "Marţi",
          "WEDNESDAY": "Mie",
          "THURSDAY": "joi",
          "FRIDAY": "Vineri",
          "SATURDAY": "Sâmbătă",
          "SUNDAY": "Soare"
        },
        "SAVE_BUTTON": "Salvați setul de schimburi",
        "CANCEL_BUTTON": "Anulează",
        "LOADING": {
          "SAVE": "Salvarea..."
        },
        "UNKNOWN_SHIFT_TYPE": "Tip de schimbare necunoscut",
        "ERRORS": {
          "UNABLE_ADD": "Hopa, momentan nu putem adăuga acest set de schimburi. Vă rugăm să încercați din nou.",
          "UNABLE_UPDATE": "Hopa, momentan nu putem actualiza acest set de schimburi. Vă rugăm să încercați din nou."
        }
      },
      "INDEX": {
        "NEW_BUTTON": "Adaugă nou",
        "ALERT_LINK_WARNING": "Șabloanele s-au mutat în meniul Setări. Link-ul existent sub meniul Rotas va dispărea în curând 👀",
        "TITLE": "Șabloane Rota",
        "SEARCH_FILTER": "Caută...",
        "TEMPLATE_TABLE": {
          "NAME_HEADING": "Nume",
          "CREATED_BY_HEADING": "Creat de",
          "LAST_EDITED_HEADING": "Editat ultima dată",
          "OPTIONS_HEADING": "Opțiuni"
        },
        "NO_TEMPLATES": "Nu există șabloane de rotație de afișat.",
        "CLONE_TEMPLATE": "Clonă",
        "DELETE_TEMPLATE": "Șterge",
        "EDITED_ON": "pe",
        "CONFIRM": {
          "DELETE_NODE": "Sunteți sigur că doriți să ștergeți acest lucru?"
        },
        "SUCCESS": {
          "DELETE_NODE": "Prognoza cererii dvs. a fost ștearsă cu succes."
        },
        "ERRORS": {
          "DELETE_NODE": "Hopa, ceva nu a mers bine. S-ar putea să trebuiască să încercați din nou!"
        }
      },
      "NEW_TEMPLATE_MODAL": {
        "TITLE": "Șablon nou",
        "REMINDER": "<strong>Pss!</strong> Aveți deja <strong>{{templateCount}}</strong> șablon (e). Trebuie să creați unul nou numai dacă cerințele dvs. de programare s-au schimbat semnificativ. În caz contrar, ar trebui să editați unul existent.",
        "TYPE_LABEL": "Tip",
        "NEW_BLANK_TEMPLATE": "Șablon nou gol",
        "CLONE_EXISTING_TEMPLATE": "Clonează șablonul existent",
        "CLONE_FROM_LABEL": "Clonă de la",
        "NAME_LABEL": "Numele șablonului",
        "NAME_HELP": "Veți genera roturi din acest șablon în mod regulat și un nume bun descrie pentru cine sau pentru ce vor fi aceste rute, cum ar fi „Rota personalului de asistență”.",
        "NAME_PLACEHOLDER": "adică. Sezonul de vârf sau Rota echipei B",
        "DEFAULT_SHIFT_TYPE_LABEL": "Tipul de schimbare implicit",
        "DEFAULT_SHIFT_TYPE_HELP": "Când vă construiți șablonul, veți putea schimba tipul de schimb pentru fiecare grup sau sarcină și este la îndemână pentru noi să setăm un implicit acum.",
        "CREATE_BUTTON": "Creați!",
        "CREATE_BUTTON_LOADING": "Vă rog să așteptați, acest lucru poate dura un moment...",
        "CANCEL_BUTTON": "Anulează",
        "CONFIRM_CLONE": "Clonarea unui șablon va dura un moment sau două, în funcție de dimensiunea șablonului. Ești sigur că vrei să continui?",
        "CREATE_ERROR": "Momentan nu se poate crea acest șablon. Vă rugăm să încercați din nou."
      },
      "DELETE_TEMPLATE_MODAL": {
        "INFO_TITLE": "Ștergeți șablonul",
        "INFO_BODY": "Ștergerea unui șablon este ireversibilă și nu poate fi anulată. Pentru a ne convinge că doriți să faceți acest lucru, vă rugăm să introduceți numele șablonului în caseta de mai jos, apoi faceți clic pe ștergere.",
        "DELETE_TEMPLATE_BUTTON": "Ștergeți șablonul",
        "SUCCESS": {
          "DELETE_TEMPLATE": "Șablonul dvs. a fost șters cu succes."
        },
        "ERRORS": {
          "DELETE_TEMPLATE": "Hopa, ceva nu a mers bine. S-ar putea să trebuiască să încercați din nou!"
        }
      }
    },
    "SHIFT": {
      "EDIT_MODAL": {
        "SHIFT": "Schimbă",
        "SITE": "Site-ul",
        "START": "Începe",
        "BREAK": "Sparge",
        "NONE": "Niciuna",
        "FINISH": "Finisează",
        "NEW_SHIFT": "Schimbare nouă",
        "ATTRIBUTE_WAGE_COST_TO_SITE": " Atribuiți costul salarial și orele site-ului ales",
        "DATE_AND_TIME": "Data și ora",
        "TO": " la ",
        "THEN_REST": " apoi odihnește-te ",
        "SHIFT_TYPE": "Tipul schimbării",
        "LOCATION": "Zona de prezență",
        "NO_BREAK": "Fără pauză",
        "PAID_BREAK": "Pauză plătită",
        "UNPAID_BREAK": "Pauză neplătită",
        "OF_LENGTH": "de lungime",
        "BREAK_MESSAGE_WARNING": "Personalul alocat acestei ture are cerințe diferite de pauză. Când ați terminat, Rotaready va salva o tură separată pentru fiecare persoană cu setul corect de pauze.",
        "BREAK_MESSAGE_SUCCESS": "Am aplicat automat pauza corectă la această schimbare.",
        "PAY_RULE": "Regula de plată",
        "APPLIES": " se aplică",
        "FOR_THE_SCHEDULED_DURATION": "Pentru durata programată",
        "FOR_ANY_OVERTIME": "Pentru orice ore suplimentare",
        "STAFF_SELECTION": "Selecția personalului",
        "STAFF_SELECTION_HELP": "Editați personalul alocat și utilizați etichetele pentru a filtra alte persoane eligibile",
        "STAFF_MUST_MATCH": "Personalul trebuie să se potrivească",
        "ANY_OF_THE_TAGS": "<strong>oricare</strong> dintre etichete",
        "ANY": "orice",
        "ALL": "toate",
        "OF_THE_TAGS": " a etichetelor",
        "ALL_OF_THE_TAGS": "<strong>toate</strong> etichetele",
        "TAG_TYPEAHEAD_PLACEHOLDER": "Tastați pentru a găsi etichete",
        "STAFF_ASSIGNED": "Atribuit",
        "NOBODY_IS_ASSIGNED": "Nimeni nu este atribuit",
        "STAFF_ELIGIBLE": "Eligibil",
        "STAFF_FILTER_PLACEHOLDER": "Caută...",
        "SHOW": "Afişare",
        "MORE": " mai mult...",
        "NOBODY_MATCHES_YOUR_TAGS": "Nimeni nu se potrivește cu etichetele dvs.",
        "NOBODY_ELSE_IS_AVAILABLE": "Nimeni altcineva nu este disponibil",
        "MORE_OPTIONS": "Mai multe opțiuni",
        "ADD_NOTES_AND_ADJUST_PAY_RATES": "Adăugați note și ajustați ratele de plată",
        "DETAILS": "Detalii",
        "DETAILS_TEXTAREA_PLACEHOLDER": "Adăugați note, îndatoriri și alte detalii aici...",
        "ROTA": "Rota",
        "NO_ROTA": "Fără roată",
        "PAY_LABEL": "Plătiți",
        "PAY_TOGGLE": "Aceasta este o schimbare plătită",
        "DELETE": "Șterge",
        "VIEW_BROADCAST": "Vizualizați difuzarea",
        "START_BROADCAST": "Începeți difuzarea",
        "SAVE": "Salvați",
        "CANCEL": "Anulează"
      },
      "SHIFT_BROADCAST_MODAL": {
        "SHIFT": "Schimbă",
        "SITE": "Site-ul",
        "START": "Începe",
        "BREAK": "Sparge",
        "NONE": "Niciuna",
        "FINISH": "Finisează",
        "ORIGINAL_PLANNED_STAFF": "Personalul planificat inițial",
        "EXTRA_STAFF_REQUIRED": "Este necesar personal suplimentar",
        "NOT_SHIFT_BROADCASTING_HELP": "Setați la 0 pentru a solicita cât mai mulți angajați posibil.",
        "START_BROADCASTING": "Începeți difuzarea",
        "START_BROADCASTING_LOADING": "Începerea difuzării...",
        "CANCEL": "Anulează",
        "ACCEPTED": "Acceptat",
        "PENDING": "În așteptare",
        "REJECTED": "Respins",
        "EVERYONE": "Toată lumea",
        "NO_PARTICIPANTS_TO_SHOW": "Nu există participanți la difuzare de afișat.",
        "NEEDED": "Necesar",
        "SHIFT_BROADCASTING_HELP": "Difuzarea dvs. este în curs de desfășurare. Lista de mai jos se va actualiza pe măsură ce personalul acceptă sau respinge tura. Difuzarea se va încheia automat atunci când numărul corect de personal a acceptat sau când toată lumea a răspuns.",
        "STOP_BROADCASTING": "Opriți difuzarea",
        "STOP_BROADCASTING_LOADING": "Oprirea difuzării...",
        "PAUSE_BROADCAST": "Întrerupeți difuzarea",
        "PAUSE_BROADCAST_LOADING": "Întreruperea difuzării...",
        "RESUME_BROADCAST": "Reluați difuzarea",
        "RESUME_BROADCAST_LOADING": "Reluarea difuzării...",
        "CLOSE": "Închide"
      },
      "VIEW_MODAL": {
        "SHIFT": "Schimbă",
        "SITE": "Site-ul",
        "START": "Începe",
        "BREAK": "Sparge",
        "NONE": "Niciuna",
        "FINISH": "Finisează",
        "LOCATION": "Zona de prezență",
        "NONE_SPECIFIED": "Niciunul specificat",
        "REST": "Odihnă",
        "DETAILS": "Detalii",
        "ALLOTTED_BREAK": "Pauză alocată",
        "ASSIGNED_STAFF": "Personalul alocat",
        "ON": "pe",
        "SHIFT_ROUTINE": "Schimbați rutina",
        "LAST_UPDATED": "Ultima actualizare",
        "GET_DIRECTIONS": "Obțineți indicații",
        "SHOW_TRAFFIC": "Afișați traficul",
        "EDIT": " Editați",
        "CLOSE": "Închide"
      }
    }
  },
  "DASHBOARD": {
    "TIME_AND_ATTENDANCE": "Timp și prezență",
    "YOU_ARE_NOW": "Sunteți acum",
    "ERROR_PLEASE_TRY_AGAIN": "Eroare, încercați din nou",
    "MY_UPCOMING_SHIFTS": "Schimburile mele viitoare",
    "YOU_HAVE_NO_SHIFTS_TODAY": "Astăzi nu ai schimburi.",
    "ALL_DAY": "Toată ziua",
    "AM": "SUNT",
    "PM": "PM",
    "TODAY_S_SCHEDULE": "Programul de astăzi",
    "THERE_ARE_NO_SHIFTS_SCHEDULED_TODAY": "Nu există schimburi programate astăzi.",
    "WHATEVER": "Începând de la <strong>{{startTime}}</strong> și terminând la <strong>{{endTime</strong>}}"
  },
  "SIGN_OUT": {
    "LOGGING_OUT": "Deconectare..."
  },
  "PARTIALS": {
    "VALIDATION": {
      "ISSUE_1": {
        "HEADING": "Zi de lucru prea lungă",
        "BODY": "Modificarea dvs. înseamnă <b>că ziua lucrătoare a {{name}}</b> ar deveni prea lungă (conform setărilor organizației dvs.). În prezent începe pe <b>{{start|momentFormat: 'lll'}}</b> și se termină la <b>{{end|momentFormat: 'lll'}} (apoi se oprește până la {{endRest|momentFormat: 'lll'}}</b>."
      },
      "ISSUE_2": {
        "HEADING": "Suprapunerea zilei de lucru",
        "BODY": "Schimbarea dvs. înseamnă că <b>{{name}}</b> are o zi lucrătoare de la <b>{{primaryStart|momentFormat: 'lll'}} până la <b>{{primary</b> End|momentFormat: 'LT'}}</b>. Din păcate, acest lucru se suprapune cu o zi lucrătoare pe care o au deja de la <b>{{secondaryStart|momentFormat: 'lll'}} până la {{secondaryEnd|momentFormat: 'LT'}} (apoi odihnindu-se</b> <b>până la {{endRest|momentFormat: 'LT'}}</b>)."
      },
      "ISSUE_3": {
        "HEADING": "Schimbă în afara zilei de lucru",
        "BODY": "<b>{{name}}</b> are programată o schimbare de la <b>{{start|momentFormat: 'LT'}}</b> la <b>{{end|momentFormat: 'LT'}} (apoi se oprește până la {{endRest|momentFormat: 'LT'}}</b>). Schimbarea dvs. ar însemna că această schimbare se încadrează parțial sau integral în afara zilei de lucru."
      },
      "ISSUE_4": {
        "HEADING": "Nu este disponibil",
        "BODY": "<b>{{name}} nu</b> este disponibil de la <b>{{start|momentFormat: 'lll'}}</b> la <b>{{end|momentFormat: 'LT'</b>}} așa cum sunt",
        "REASON_1": "lucrând o tură ({{eventType}} în {{entityName}})",
        "REASON_2": "ocupat în timpul unei zile lucrătoare",
        "REASON_3": "absent ({{eventType}})",
        "REASON_4": "indisponibile din cauza tiparului de disponibilitate",
        "BUTTON": "Faceți o excepție și încercați din nou",
        "BUTTON_LOADING": "Se încarcă..."
      },
      "ISSUE_5": {
        "HEADING": "Obligația contractuală nerespectată",
        "BODY": "Schimbarea dvs. înseamnă <b>că {{name}}</b> nu își va îndeplini obligația contractuală. Acum este <b>{{currentValue}} zile, dar trebuie</b> să fie cel puțin <b>{{comparatorValue}} zile</b>."
      },
      "ISSUE_6": {
        "HEADING": "Obligația contractuală nerespectată",
        "BODY": "Schimbarea dvs. înseamnă <b>că {{name}}</b> nu își va îndeplini obligația contractuală. Acum este <b>{{currentValue}} ore (e)</b>, dar trebuie să fie de cel puțin <b>{{comparatorValue}} ore</b> (e)."
      },
      "ISSUE_7": {
        "HEADING": "Etichete nepotrivire",
        "BODY": "<b>{{name}}</b> nu are etichetele necesare pentru a lucra în această schimbare. Aceasta înseamnă că le poate lipsi o abilitate, calificare sau alt atribut."
      },
      "ISSUE_8": {
        "HEADING": "Difuzare activă",
        "BODY": "În prezent, există un Shift Broadcast activ pentru această schimbare. Deoarece personalul dvs. ar putea fi în proces de răspuns, nu putem permite modificarea schimbului decât dacă anulați mai întâi difuzarea pe Shift."
      },
      "ISSUE_9": {
        "HEADING": "Zile libere insuficiente",
        "BODY": "Schimbarea dvs. înseamnă <b>că {{name}}</b> va lucra prea multe zile în această săptămână. Acum vor avea <b>{{currentValue}} zile libere, ceea</b> ce este mai mic decât <b>{{comparatorValue}} zile libere necesare.</b>"
      },
      "ISSUE_10": {
        "HEADING": "Membru al personalului inactiv",
        "BODY": "<b>{{name}}</b> nu poate funcționa pe <b>{{start|momentFormat: 'll'</b>}}. Contul lor este fie inactiv la această dată, fie au fost mutați pe un alt site."
      },
      "ISSUE_11": {
        "HEADING": "Rota marcată pentru omologare",
        "BODY": "Nu puteți face modificări la această rotație, deoarece a fost marcată pentru aprobare."
      },
      "ISSUE_12": {
        "HEADING": "Perioada semnată",
        "BODY": "Nu puteți face nicio modificare la această rotație, deoarece a fost semnată."
      },
      "ISSUE_13": {
        "HEADING": "Limita de lucru depășită",
        "BODY": "Schimbarea dvs. înseamnă <b>că {{name}}</b> va funcționa mai mult decât li se permite. Acum vor lucra <b>{{currentValue}} zile (e)</b>, ceea ce este mai mult decât limita lor de <b>{{comparatorValue}} zile</b> (e)."
      },
      "ISSUE_14": {
        "HEADING": "Limita de lucru depășită",
        "BODY": "Schimbarea dvs. înseamnă <b>că {{name}}</b> va funcționa mai mult decât li se permite. Acum vor lucra <b>{{currentValue}} ore (e)</b>, ceea ce este mai mult decât limita lor de <b>{{comparatorValue}} ore</b> (e)."
      },
      "ISSUE_15": {
        "HEADING": "Zona de prezență închisă",
        "BODY": "Zona de prezență atribuită schimbului (<b>{{assignedLocation}}</b>) este închisă în acest moment.",
        "REASON": "Acest lucru se datorează: {{rațiune}}."
      },
      "ISSUE_16": {
        "HEADING": "Ocuparea zonei de prezență a fost depășită",
        "BODY": "Zona de prezență atribuită acestei ture are o ocupare maximă de <b>{{ocupație}}</b> persoană (i) în același timp. Cu această schimbare, gradul de ocupare ar fi {{currentOccupancy}}."
      },
      "ISSUE_17": {
        "HEADING": "Prea puțini angajați în zona de prezență",
        "BODY": "De la {{start|momentFormat: 'lll'}} la {{end|momentFormat: 'lll'}}, există un moment în care doar <b>{{current</b> Value}} persoană (e) lucrează. Ori de câte ori cineva se află într-o tură, este necesar un minim de <b>{{comparatorValue}}</b> persoane (e)."
      },
      "ISSUE_18": {
        "HEADING": "Membru al personalului neaprobat",
        "BODY": "{{name}} nu poate funcționa pe <b>{{start|momentFormat: 'll'</b>}}. Contul lor nu a fost încă aprobat pentru programare."
      },
      "ISSUE_19": {
        "HEADING": "Documentele membrilor personalului lipse/expirate",
        "BODY": "<b>{{name}}</b> nu poate funcționa pe <b>{{start|momentFormat: 'll'</b>}}. Unul sau mai multe documente necesare lipsesc sau expiră."
      },
      "ISSUE_20": {
        "HEADING": "Schimbă prea mult (Sub 18 ani)",
        "BODY": "Schimbarea dvs. înseamnă că <b>{{name}} va funcționa</b> pentru <b>{{duration|HumaniseDuration</b>}}. Deoarece sunt sub 18 ani, limita Directivei privind timpul de lucru este <b>{{limit|Humanise</b> Duration}}."
      },
      "ISSUE_21": {
        "HEADING": "Treceți în afara limitelor",
        "BODY": "Nu puteți programa o schimbare pentru <b>{{name}}</b> pe {<b>{start|momentFormat: 'll'}}</b> deoarece face parte dintr-o rotație care începe pe <b>{{periodStart|momentFormat: 'll'}} și se termină pe {<b>{perio</b> dend|momentFormat: 'll'}}</b>."
      }
    }
  },
  "NAV": {
    "HOME": "Acasă",
    "ROTAS": "Routes",
    "STAFF": "Personalul",
    "ATTENDANCE": "Participare",
    "REPORTS": "Rapoarte",
    "SETTINGS": "Setări",
    "VIEW_ROTA": "Vizualizați rota",
    "SCHEDULE": "Programul meu",
    "ABSENCE": "Absența mea",
    "AVAILABILITY": "Disponibilitatea mea",
    "DOCUMENTS": "Documentele mele",
    "ROTA_DASHBOARD": "Tablou de bord",
    "ROTA_ROTA_EDITOR": "Editor de rotație",
    "ROTA_TRONC": "Tronc",
    "ROTA_EVENT_CALENDAR": "Planificator de evenimente",
    "STAFF_DIRECTORY": "Director",
    "STAFF_REQUESTS": "Solicitări",
    "STAFF_ABSENCES": "Calendarul absențelor",
    "STAFF_EMPLOYEE_SUBMITTED_DETAILS": "Detaliile angajaților trimise",
    "ATTENDANCE_OVERVIEW": "Participare",
    "ATTENDANCE_ISSUES": "Probleme",
    "COST_CONTROL": "Controlul costurilor",
    "COST_CONTROL_SIGN_OFF": "Controlul costurilor și semnarea",
    "COST_CONTROL_AGGREGATED_TOTALS": "Totaluri agregate",
    "ASSET_REGISTRY": "Registrul activelor",
    "WFM_DASHBOARD": "Tablourile mele de bord"
  },
  "SETTINGS": {
    "INDEX": {
      "TITLE": "Setări",
      "PLACEHOLDER_TEXT": "👈 Selectați o setare din stânga pentru a începe.",
      "CATEGORY_HR": "RESURSE UMANE",
      "CATEGORY_ROTAS": "Routes",
      "CATEGORY_ATTENDANCE": "Participare",
      "CATEGORY_INTEGRATIONS": "Integrări",
      "ITEM_ROTA_TEMPLATES": "Șabloane Rota",
      "ITEM_TRUSTED_DEVICES": "Dispozitive de încredere",
      "ITEM_SHIFT_LOCATIONS": "Zonele de prezență",
      "ITEM_SHIFT_TYPES": "Tipuri de schimburi",
      "ITEM_OUTSOURCING": "Externalizare",
      "ITEM_ABSENCE_TYPES": "Tipuri de absență",
      "ITEM_DOCUMENT_CATEGORIES": "Categorii de documente",
      "ITEM_DOCUMENT_TEMPLATES": "Șabloane de documente",
      "ITEM_TAGS_GROUPS": "Etichete și grupuri",
      "ITEM_SHIFT_ROUTINES": "Rutine de schimbare",
      "ITEM_REST_BREAKS": "Pauze de odihnă",
      "ITEM_PAY_ELEMENTS": "Elemente de plată",
      "ITEM_PAY_RULES": "Reguli de plată",
      "ITEM_PERMISSIONS": "Permisiuni",
      "ITEM_BUDGETS": "Bugetele",
      "ITEM_ASSET_TYPES": "Tipuri de active",
      "ITEM_ENTITY_GROUP_SUBSCRIPTIONS": 'Manager de acces la site',
      "ITEM_ENTITY_GROUPS": 'Manager de site',
      "ITEM_POSITIONS": 'Roluri de muncă',
      "ITEM_LEAVER_REASONS": 'Motivele plecării',
      "ITEM_RECRUITMENT_SOURCES": 'Surse de recrutare',
      "ITEM_LABOUR_DEPLOYMENT": 'Distribuirea forței de muncă',
    },
    "DOCUMENT_TEMPLATES": {
      "TITLE": "Șabloane de documente",
      "FILTER_LABEL_CATEGORY": "Categorie",
      "BUTTON_UPLOAD": "Încărcați",
      "NAME_COLUMN_LABEL": "Nume",
      "TEMPLATE_CATEGORY_COLUMN_LABEL": "Categorie",
      "FILE_NAME_COLUMN_LABEL": "Nume fisier",
      "FILE_SIZE_COLUMN_LABEL": "Dimensiunea fişierului",
      "DESTINATION_CATEGORY_COLUMN_LABEL": "Categoria de destinație",
      "SIGNATORIES_COLUMN_LABEL": "Semnatari",
      "EDITED_COLUMN_LABEL": "Adăugat de",
      "OPTIONS_COLUMN_LABEL": "Opțiuni",
      "LINK_DELETE": "Șterge",
      "SIGNATORIES_BOTH": "Angajat, angajator",
      "SIGNATORIES_EMPLOYEE": "Angajat",
      "NO_DOCS_MATCH_TEXT": "Nu am putut găsi niciun șablon de document care să corespundă filtrelor dvs.",
      "ERROR_NO_CATEGORIES": "Șabloanele de documente nu sunt activate pentru organizația dvs. Discutați cu noi pentru a începe.",
      "ALERT_CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți acest șablon? Acest lucru nu poate fi anulat!",
      "ERROR_500": "Nu putem încărca șabloane de documente chiar acum. Vă rugăm să încercați din nou.",
      "ERROR_403": "Nu aveți permisiunea de a vizualiza/modifica șabloanele de documente.",
      "UPLOAD_MODAL": {
        "TITLE": "Încărcarea unui șablon de document",
        "LABEL_NAME": "Nume",
        "HELP_TEXT_NAME": "Dați șablonului dvs. un nume pe care îl veți recunoaște.",
        "LABEL_CATEGORY": "Categorie șablon",
        "HELP_TEXT_CATEGORY": "Păstrați șabloanele organizate selectând o categorie.",
        "LABEL_DESTINATION_CATEGORY": "Categoria documentului de destinație",
        "HELP_TEXT_DESTINATION_CATEGORY": "Când acest șablon este utilizat și un document generat este semnat, alegeți categoria de documente în care fișierul trebuie salvat.",
        "LABEL_SIGNATORIES": "Semnatari",
        "SIGNATORIES_OPTION_BOTH": "Atât angajatul, cât și angajatorul trebuie să semneze.",
        "SIGNATORIES_OPTION_EMPLOYEE": "Doar angajatul trebuie să semneze.",
        "LABEL_UPLOAD": "Încărcați",
        "BUTTON_CANCEL": "Anulează",
        "BUTTON_SAVE": "Salvați",
        "ALERT_SUCCESS": "Șablonul de document a fost salvat.",
        "ALERT_ERROR_500": "A apărut o eroare la încărcarea șablonului de document. Vă rugăm să încercați din nou sau să ne contactați dacă acest mesaj persistă.",
        "ALERT_ERROR_400": "Există ceva care nu este în regulă cu șablonul dvs. de document."
      }
    },
    "ABSENCE_TYPES": {
      "TITLE": "Tipuri de absență",
      "ERROR_500": "Nu putem încărca tipuri de absență chiar acum. Vă rugăm să încercați din nou.",
      "ERROR_403": "Nu aveți permisiunea de a vizualiza/modifica tipurile de absență.",
      "BUTTON_ADD_NEW": "Adaugă nou",
      "BUTTON_TOGGLE_DELETED": "Includeți șterse",
      "COLUMN_NAME": "Nume",
      "COLUMN_BOOKABLE": "Rezervabil",
      "COLUMN_APPROVAL": "Este necesară aprobarea",
      "COLUMN_PAID": "Plătit",
      "COLUMN_EDITED": "Editat de",
      "COLUMN_OPTIONS": "Opțiuni",
      "YES_LABEL": "da",
      "NO_LABEL": "Nu",
      "LINK_EDIT": "Editați",
      "LINK_DELETE": "Șterge",
      "LINK_REINSTATE": "Reinstaurarea",
      "PILL_DELETED": "Șters",
      "ALERT_CONFIRM_DELETE": "Sigur doriți să ștergeți acest tip de absență?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Tipul dvs. de absență a fost salvat.",
        "ALERT_ERROR_500": "A apărut o eroare la crearea tipului dvs. de absență. Vă rugăm să încercați din nou sau contactați-ne dacă acest mesaj persistă.",
        "ALERT_ERROR_400": "Există deja un tip de absență cu acel nume. Vă rugăm să alegeți altul.",
        "ADD_TITLE": "Noul tip de absență",
        "EDIT_TITLE": "Editați tipul de absență",
        "BUTTON_SAVE": "Salvați",
        "BUTTON_CANCEL": "Anulează",
        "LABEL_NAME": "Nume",
        "LABEL_COLOUR": "Culoare",
        "LABEL_BOOKABLE": "Rezervabil",
        "LABEL_BOOKABLE_HELP": "Permiteți angajaților să facă cereri de absență de acest tip.",
        "LABEL_APPROVAL": "Aprobare",
        "LABEL_APPROVAL_HELP": "Cererile de acest tip necesită aprobare.",
        "LABEL_PAID": "Plătit",
        "LABEL_PAID_HELP": "Absența acestui tip este plătită.",
        "LABEL_WORKED_HOURS": "Ore lucrate",
        "LABEL_WORKED_HOURS_HELP": "Absența acestui tip contribuie la „orele lucrate”.",
        "LABEL_ACCRUES": "Acumulează concediu",
        "LABEL_ACCRUES_HELP": "Angajații vor acumula concediu pentru absența acestui tip dacă au o indemnizație de concediu pe bază de angajamente.",
        "LABEL_OVERLAPS_SHIFTS": "Meciul de schimbare",
        "LABEL_OVERLAPS_SHIFTS_HELP": "Absența de acest tip se poate potrivi cu schimburi, în locul unui clock-in și a unui clock-out.",
        "LABEL_TRONC": "Tronc",
        "LABEL_TRONC_HELP": "Absența acestui tip contribuie la orele lucrate în Tronc.",
        "LABEL_ALLOWANCE_REQUIRED": "Indemnizație necesară",
        "LABEL_ALLOWANCE_REQUIRED_HELP": "Pentru a solicita absența acestui tip, asigurați-vă că angajatul are o indemnizație activă.",
        "TITLE_FILE_UPLOADS": "Încărcări de fișiere",
        "LABEL_SUBMISSION_UPLOAD": "La cerere",
        "LABEL_SUBMISSION_UPLOAD_HELP": "Permiteți atașarea unui fișier atunci când este solicitată absența acestui tip.",
        "LABEL_REVIEW_UPLOAD": "La revizuire",
        "LABEL_REVIEW_UPLOAD_HELP": "Permiteți atașarea unui fișier atunci când absența acestui tip este aprobată sau respinsă.",
        "LABEL_SAVE_TO": "Salvați în",
        "HELP_TEXT_SAVE_TO": "Când un fișier este atașat la absența acestui tip, acesta va fi salvat în documentele angajatului din categoria aleasă.",
        "FILE_UPLOAD_OPTION_NONE": "Dezactivat",
        "FILE_UPLOAD_OPTION_OPTIONAL": "Opțional",
        "FILE_UPLOAD_OPTION_MANDATORY": "Necesar",
        "INFO_LOCKED": "Acesta este un tip special de absență folosit de Rotaready. Am ascuns câteva proprietăți care nu pot fi modificate. Vă rugăm să luați legătura dacă aveți nevoie de ajutor cu acest tip de absență.",
        "TITLE_PAY": "Plătiți",
        "LABEL_FIXED_AMOUNTS": "Sume fixe",
        "HELP_TEXT_FIXED_AMOUNTS": "Atunci când plătiți unui angajat o sumă fixă, o astfel de plată pentru absența acumulată, dar neluată, acest element de plată va fi utilizat.",
        "TITLE_REASONS": "Motive",
        "COLUMN_TITLE": "Motivul",
        "COLUMN_OPTIONS": "Opțiuni",
        "LINK_DELETE": "Șterge",
        "NO_REASONS": "Nu au fost adăugate motive.",
        "PLACEHOLDER_REASON": "Un nou motiv...",
        "LINK_ADD": "Adaugă"
      }
    },
    "OUTSOURCING": {
      "TITLE": "Externalizare",
      "BUTTON_ADD_NEW": "Adaugă nou",
      "BUTTON_TOGGLE_DELETED": "Includeți șterse",
      "NO_DATA_TEXT": "Nu există organizații de externalizare de prezentat.",
      "NAME_COLUMN_TEXT": "Nume",
      "ENTITY_COLUMN_TEXT": "Site/departament",
      "RESOURCES_COLUMN_TEXT": "Resurse",
      "HOURS_COLUMN_TEXT": "Ore disponibile",
      "COST_COLUMN_TEXT": "Cost",
      "LAST_EDITED_COLUMN_TEXT": "Editat de",
      "OPTIONS_COLUMN_TEXT": "Opțiuni",
      "UNLIMITED_TEXT": "nelimitat",
      "ALWAYS_24_TEXT": "Întotdeauna (24 de ore)",
      "PER_HOUR_TEXT": "/oră",
      "PER_DAY_TEXT": "/zi",
      "ORG_WIDE_TEXT": "La nivel de organizație",
      "LINK_EDIT": "Editați",
      "LINK_DELETE": "Șterge",
      "LINK_REINSTATE": "Reinstaurarea",
      "PILL_DELETED": "Șters",
      "ERROR_500": "Nu putem încărca organizațiile de externalizare chiar acum. Vă rugăm să încercați din nou.",
      "ERROR_403": "Nu aveți permisiunea de a vizualiza/modifica organizațiile de externalizare.",
      "ALERT_CONFIRM_DELETE": "Sigur doriți să ștergeți această organizație de externalizare?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Organizația dvs. de externalizare a fost salvată.",
        "ALERT_ERROR_500": "A apărut o eroare la crearea organizației de externalizare. Vă rugăm să încercați din nou sau contactați-ne dacă acest mesaj persistă.",
        "ALERT_ERROR_400": "Există deja o organizație de externalizare cu acest nume. Vă rugăm să alegeți altul.",
        "EDIT_TITLE": "Editați organizația",
        "ADD_TITLE": "Adaugă organizație",
        "NAME_LABEL": "Nume",
        "ENTITY_LABEL": "Site/departament",
        "PAY_LABEL": "Plătiți",
        "HOURLY_OPTION": "Plătit pe oră",
        "FIXED_OPTION": "Cost fix pe zi",
        "HOURLY_RATE_LABEL": "Rata orară",
        "DAILY_RATE_LABEL": "Rata zilnică",
        "AUTOMATIC_SCHEDULE_TITLE": "Programare automată",
        "HOURS_AVAILABLE_LABEL": "Ore disponibile",
        "TO_TEXT": " la ",
        "HOURS_AVAILABLE_HELP": "Timpul în care lucrătorii din această organizație sunt disponibili",
        "RESOURCES_AVAILABLE_LABEL": "Resurse disponibile",
        "RESOURCES_AVAILABLE_HELP": "Numărul maxim de lucrători la dispoziția dvs. pe zi (introduceți 0 pentru nelimitat)",
        "TAGS_LABEL": "Etichete",
        "TAGS_PLACEHOLDER": "Tastați etichetele de căutare",
        "TAGS_HELP": "Atribuiți etichete așa cum ați face unui membru normal al personalului. Acestea se vor aplica tuturor lucrătorilor acestei organizații.",
        "BUTTON_SAVE": "Salvați",
        "BUTTON_CANCEL": "Anulează"
      }
    },
    "TRUSTED_DEVICES": {
      "TITLE": "Dispozitive de încredere",
      "DEVICE": "Dispozitiv",
      "ENTITY": "Site-ul primar/departamentul",
      "DEVICE_TYPE": "Agent utilizator",
      "EDITED_BY": "Editat de",
      "LAST_ACTIVITY": "Ultima activitate",
      "STATUS": "Statut",
      "OPTIONS": "Opțiuni",
      "NO_DEVICES": "Nu există dispozitive de încredere pentru înregistrarea prezenței.",
      "NO_ACTIVITY": "Nicio activitate",
      "LINK_UNTRUST": "Neîncredere",
      "LINK_TRUST": "Încredere",
      "LINK_EDIT": "Editați",
      "ALERT_CONFIRM_TRUST": "Sigur doriți să aveți încredere în acest dispozitiv pentru captarea prezenței?",
      "ALERT_CONFIRM_UNTRUST": "Sunteți sigur că doriți să nu aveți încredere în acest dispozitiv, astfel încât să nu mai poată fi utilizat pentru captarea prezenței? Acest lucru nu poate fi inversat.",
      "ERROR_500": "Nu putem încărca dispozitive de încredere chiar acum. Vă rugăm să încercați din nou.",
      "ERROR_403": "Nu aveți permisiunea de a vizualiza/modifica dispozitive de încredere.",
      "STATUS_PENDING": "În așteptare",
      "STATUS_TRUSTED": "De încredere",
      "STATUS_UNTRUSTED": "Necredincios",
      "FILTER_SITE": "Site/departament cartografiat",
      "FILTER_STATUS": "Statut",
      "BUTTON_NEW_DEVICE": "Dispozitiv nou",
      "NEW_DEVICE_MODAL": {
        "TITLE": "Adăugarea unui dispozitiv nou",
        "BUTTON_CANCEL": "Anulează",
        "BUTTON_DONE": "Continuă",
        "TEXT_STEP_1": "Pasul 1",
        "TEXT_STEP_2": "Pasul 2",
        "TEXT_STEP_1_BODY": "Luați tableta sau laptopul, deschideți un browser web și navigați la următoarea adresă URL:",
        "TEXT_STEP_2_BODY_A": "Când vi se solicită un „Realm ID”, introduceți următoarea valoare:",
        "TEXT_STEP_2_BODY_B": "Când vi se solicită un „ID de entitate”, introduceți următoarea valoare:",
        "TEXT_STEP_3_BODY": "După ce ați terminat, faceți clic pe Continuare pentru a reveni la lista de dispozitive. Noul dispozitiv ar trebui să apară apoi. Faceți clic pe Încredere lângă dispozitiv pentru a finaliza procesul de asociere."
      },
      "EDIT_DEVICE_MODAL": {
        "TITLE": "Editați dispozitivul",
        "BUTTON_CANCEL": "Anulează",
        "BUTTON_SAVE": "Salvați",
        "BODY": "Selectând mai multe site-uri sau departamente de mai jos, angajații asociați vor apărea pe ecranul de pornire al dispozitivului. Nu puteți deselecta site-ul/departamentul principal; trebuie să renunțați la dispozitiv dacă doriți să modificați acest lucru.",
        "ALERT_200": "Dispozitivul a fost actualizat.",
        "ALERT_500": "Nu am putut actualiza dispozitivul chiar atunci. Vă rugăm să încercați din nou mai târziu.",
        "LABEL_NAME": "Numele dispozitivului"
      }
    }
  },
  "MESSAGING": {
    "BETA": "BETA",
    "MESSAGE_HUB": {
      "TITLE": "Mesaje",
      "NO_MESSAGES": "Niciun mesaj",
      "START_NEW": "Începe o nouă conversație",
      "YESTERDAY": "ieri"
    },
    "CONVERSATION": {
      "MESSAGE_PLACEHOLDER": "Adaugă mesajul tău",
      "INDIVIDUAL_TYPING": "{{name}} tastează...",
      "MULTIPLE_TYPING": "Mai multe persoane tastează..."
    },
    "START_NEW": {
      "TITLE": "Începe o nouă conversație",
      "TO": "Către:",
      "REMOVE": "Elimină",
      "START_CONVERSATION": "Începeți conversația"
    },
    "ALERTS": {
      "START_CONVERSATION": "A apărut o eroare la începerea conversației. Vă rugăm să încercați din nou.",
      "SUBSCRIBE_TO_CHANNELS": "Există o problemă la abonarea la conversațiile dvs. Este posibil să nu primiți niciun mesaj. Vă rugăm să reîmprospătați sau să luați legătura dacă acest lucru persistă.",
      "FETCH_MESSAGES": "Există o problemă la primirea mesajelor. Vă rugăm să reîmprospătați sau să contactați dacă acest lucru persistă.",
      "FETCH_NEW_MESSAGES": "A apărut o eroare la primirea mesajelor noi. Vă rugăm să reîmprospătați sau să luați legătura dacă acest lucru persistă.",
      "FETCH_CONVERSATIONS": "A apărut o eroare la preluarea conversațiilor. Vă rugăm să reîmprospătați sau să luați legătura dacă acest lucru persistă.",
      "SEND_MESSAGE": "A apărut o eroare la trimiterea mesajului. Vă rugăm să încercați din nou."
    }
  },
  "ABSENCE": {
    "REQUEST": {
      "TITLE": "Cerere de absență",
      "BUTTON_SUBMIT": "Trimiteți",
      "LABEL_TYPE": "Alegeți un tip",
      "LABEL_REASON": "Selectați un motiv",
      "LABEL_EMPLOYEE": "Angajat",
      "LABEL_START_DATE": "Data începerii",
      "LABEL_END_DATE": "Data de încheiere",
      "LABEL_SCOPE_ALL_DAY": "Toată ziua",
      "LABEL_SCOPE_AM": "Numai AM",
      "LABEL_SCOPE_PM": "Numai PM",
      "LABEL_SCOPE_CUSTOM": "Alegeți ore specifice",
      "LABEL_START_TIME": "Ora de începere",
      "LABEL_END_TIME": "Ora de încheiere",
      "ALERT_OVERLAPPING_ABSENCE_USER": "Aveți o altă rezervare de absență care se suprapune cu data (datele) aleasă (e).",
      "ALERT_OVERLAPPING_ABSENCE_OTHERS": "Doar ca să știți, există și alte persoane absente în acest moment.",
      "ERROR_500": "Am lovit un obstacol chiar atunci. Vă rugăm să încercați din nou!",
      "LABEL_DAYS": "zile",
      "LABEL_HOURS": "ore",
      "SECTION_UNPAID_DAYS": "Zile libere regulate",
      "HELP_TEXT_NO_UNPAID_DAYS": "Nu există zile libere regulate în această cerere.",
      "BUTTON_OPTIONS": "Opțiuni",
      "BUTTON_CHANGE": "Schimbați zilele libere obișnuite",
      "BUTTON_RESET": "Reveniți zilele libere obișnuite la cele sugerate",
      "BUTTON_CLEAR": "Ștergeți toate zilele libere obișnuite",
      "MODAL_CALENDAR_TITLE": "Alegeți zile libere obișnuite",
      "MODAL_BUTTON_SAVE": "Salvați",
      "LABEL_ADD_A_NOTE": "Adaugă o notă la această solicitare",
      "BUTTON_COMPLETE": "Ia-ma inapoi",
      "COMPLETE_TITLE": "Ești gata.",
      "COMPLETE_LABEL_APPROVED": "Aprobat",
      "COMPLETE_TEXT_APPROVED": "Solicitarea dvs. a fost trimisă și aprobată automat ✅",
      "COMPLETE_LABEL_PENDING": "În așteptarea aprobării",
      "COMPLETE_TEXT_PENDING": "Solicitarea dvs. a fost trimisă și așteaptă aprobarea. Vă vom anunța când acest lucru se schimbă 👍"
    },
    "VALIDATION_MODAL": {
      "TITLE": "Ceva nu este în regulă",
      "DESCRIPTION": "Din păcate, ceea ce încercați să faceți provoacă una sau mai multe probleme.",
      "BUTTON_CLOSE": "Închide",
      "INVALID_DATES_TITLE": "Date nevalide",
      "INVALID_DATES_DESCRIPTION": "Puteți alege doar AM, doar PM sau ore personalizate numai dacă data de început și de sfârșit este aceeași zi.",
      "INVALID_HEADER_TITLE": "Înregistrare de absență nevalidă",
      "INVALID_HEADER_DESCRIPTION": "Nu puteți edita acea înregistrare de absență. S-ar putea să fi fost șters.",
      "INVALID_ACTION_TITLE": "Acțiune nevalidă",
      "INVALID_ACTION_DESCRIPTION": "Nu puteți modifica ora unei rezervări de absență după ce aceasta a fost respinsă sau anulată.",
      "INVALID_DAYS_TITLE": "Zile nevalide",
      "INVALID_DAYS_DESCRIPTION": "Nu puteți plasa o cerere de absență pentru zero ore sau zero zile.",
      "OUTSIDE_EMPLOYMENT_TITLE": "Angajare externă",
      "OUTSIDE_EMPLOYMENT_DESCRIPTION": "Data de începere sau de încheiere aleasă se încadrează în afara datelor de angajare ale angajatului.",
      "SPANS_TIMEZONES_TITLE": "Intervalul de date se întinde pe două zone orare",
      "SPANS_TIMEZONES_DESCRIPTION": "Înregistrările de programare ale angajatului indică faptul că își mută site-ul în timpul datelor alese, iar site-ul lor existent se află într-un fus orar diferit de noul lor site. Asigurați-vă că rezervarea dvs. de absență se încheie înainte de a muta site-ul sau începe după mutarea site-ului.",
      "SPANS_PAYROLL_CALENDARS_TITLE": "Intervalul de date se întinde pe două calendare de salarizare",
      "SPANS_PAYROLL_CALENDARS_DESCRIPTION": "Înregistrările de programare ale angajatului indică faptul că schimbă calendarul de salarizare în datele alese. Asigurați-vă că rezervarea dvs. de absență se încheie înainte de a schimba calendarul de salarizare sau începe după ce schimbă calendarul de salarizare.",
      "INVALID_PAYROLL_CALENDAR_TITLE": "Calendarul de salarizare nevalid",
      "INVALID_PAYROLL_CALENDAR_DESCRIPTION": "Intervalul de date ales începe înainte de prima rulare a salariilor din calendarul de salarizare al angajatului.",
      "OVERLAPPING_ABSENCE_TITLE": "Absența suprapusă",
      "OVERLAPPING_ABSENCE_DESCRIPTION": "Angajatul este deja absent în una sau mai multe zile care se suprapun cu datele alese.",
      "OVERLAPPING_SHIFTS_TITLE": "Schimburi suprapuse",
      "OVERLAPPING_SHIFTS_DESCRIPTION": "Angajatul are unul sau mai multe schimburi publicate care se suprapun cu datele alese. Acestea trebuie șterse sau neatribuite mai întâi.",
      "SIGNED_OFF_TITLE": "Datele de deconectare",
      "SIGNED_OFF_DESCRIPTION": "Datele se suprapun cu una sau mai multe zile semnate. Nu puteți aproba sau anula absența pentru zilele care au fost deja semnate.",
      "ALLOWANCE_REQUIRED_TITLE": "Indemnizație necesară",
      "ALLOWANCE_REQUIRED_DESCRIPTION": "Nu puteți rezerva absențe de acest tip. Trebuie să existe o indemnizație activă din care să se deducă.",
      "ALLOWANCE_NOT_DEDUCTIBLE_TITLE": "Indemnizația nu este deductibilă",
      "ALLOWANCE_NOT_DEDUCTIBLE_DESCRIPTION": "Nu puteți rezerva absențe de acest tip. Există o indemnizație activă, dar este marcată ca nedeductibilă.",
      "ALLOWANCE_BALANCE_INSUFFICIENT_TITLE": "Echilibru insuficient",
      "ALLOWANCE_BALANCE_INSUFFICIENT_DESCRIPTION": "Soldul indemnizației este prea mic pentru ca această cerere să poată fi depusă.",
      "FILE_UPLOAD_REQUIRED_TITLE": "Este necesară încărcarea fișierelor",
      "FILE_UPLOAD_REQUIRED_DESCRIPTION": "Acest tip de absență necesită încărcarea unui fișier înainte de a fi trimis sau aprobat."
    },
    "VIEW_MODAL": {
      "TITLE": "Absența",
      "BUTTON_OPTIONS": "Opțiuni",
      "BUTTON_EDIT": "Editare/revizuiți rezervarea",
      "BUTTON_CANCEL": "Anulează rezervarea",
      "BUTTON_CLOSE": "Închide",
      "STATUS_CANCELLED": "Anulat",
      "LABEL_START_DATE": "Începe",
      "LABEL_END_DATE": "Se termină",
      "LABEL_ABSENCE_TYPE": "Tip",
      "LABEL_SCOPE": "Durată",
      "ERROR_500": "Am lovit un mic obstacol. Vă rugăm să încercați din nou.",
      "ERROR_404": "Această cerere de absență nu mai există.",
      "LABEL_SUBMITTED_BY": "Trimis de",
      "LABEL_REVIEWED_BY": "Revizuit de",
      "LABEL_CANCELLED_BY": "Anulat de",
      "LABEL_EDITED_BY": "Editat de",
      "DURATION_TEXT": "{{ore}} ore, {{zile}} zile",
      "TAB_BREAKDOWN": "Defalcare",
      "TITLE_PAY_PERIODS": "După perioada de plată",
      "NO_PAY_PERIODS": "Angajatul nu a fost asociat cu un calendar de salarizare atunci când a fost făcută această rezervare.",
      "TITLE_DAILY_BREAKDOWN": "Pe zi",
      "NO_DAYS": "Nu există zile în această rezervare.",
      "TAB_FILES": "Documente",
      "FILES_NONE": "Nimic nu a fost încărcat pentru această rezervare.",
      "FILES_AVAILABLE": "Aceste documente pot fi găsite și în zona Personal atunci când vizualizați profilul angajatului. Le veți găsi în această categorie: {{categoryName}}",
      "FILES_COLUMN_NAME": "Nume",
      "FILES_COLUMN_UPLOADED_BY": "Încărcat de",
      "FILES_COLUMN_OPTIONS": "Opțiuni",
      "FILES_LINK_DELETE": "Șterge",
      "FILES_CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți acest lucru? De asemenea, va fi eliminat din fila Documente din profilul angajatului.",
      "CONFIRM_CANCEL": "Sunteți sigur că doriți să anulați această rezervare de absență? Acest lucru nu poate fi anulat.",
      "ALERT_IS_CANCELLED": "Rezervarea absenței a fost anulată.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Atenție! Solicitantul a ales un timp de început și de terminare care echivalează cu mai mult de {{HalfDayHours}} ore, care este numărul de ore pe care le lucrează în prezent pentru o jumătate de zi.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Atenție! Solicitantul a ales să modifice zilele libere obișnuite, mai degrabă decât să folosească zilele sugerate de Rotaready."
    },
    "EDIT": {
      "TITLE": "Absența",
      "BUTTON_SUBMIT": "Salvați",
      "BUTTON_APPROVE": "Aproba",
      "BUTTON_REJECT": "Respinge",
      "TAB_EDIT_BOOKING": "Editați datele",
      "TAB_REVIEW": "Recenzie",
      "TAB_FILES": "Documente",
      "TAB_CALENDAR": "Calendarul",
      "LABEL_TYPE": "Tip",
      "LABEL_REASON": "Selectați un motiv",
      "LABEL_EMPLOYEE": "Angajat",
      "LABEL_START_DATE": "Data începerii",
      "LABEL_END_DATE": "Data de încheiere",
      "LABEL_SCOPE_ALL_DAY": "Toată ziua",
      "LABEL_SCOPE_AM": "Numai AM",
      "LABEL_SCOPE_PM": "Numai PM",
      "LABEL_SCOPE_CUSTOM": "Alegeți ore specifice",
      "LABEL_START_TIME": "Ora de începere",
      "LABEL_END_TIME": "Ora de încheiere",
      "ERROR_500": "Am lovit un obstacol chiar atunci. Vă rugăm să încercați din nou!",
      "LABEL_DAYS": "zile",
      "LABEL_HOURS": "ore",
      "SECTION_UNPAID_DAYS": "Zile libere regulate",
      "HELP_TEXT_NO_UNPAID_DAYS": "Nu există zile libere regulate în această cerere.",
      "BUTTON_OPTIONS": "Opțiuni",
      "BUTTON_CHANGE": "Schimbați zilele libere obișnuite",
      "BUTTON_RESET": "Reveniți la rezervarea inițială",
      "BUTTON_RECALCULATE": "Recalculați din nou",
      "BUTTON_CLEAR": "Ștergeți toate zilele libere obișnuite",
      "UNPAID_DAYS_RECALCULATED_LABEL": "Recalculat",
      "UNPAID_DAYS_RECALCULATED_HELP": "Ați ales să calculați din nou zilele libere obișnuite și să utilizați valoarea predominantă pentru „orele pe zi lucrătoare” ale angajatului în loc de ceea ce a fost atunci când a fost făcută inițial rezervarea.",
      "UNPAID_DAYS_MERGED_LABEL": "Fuzionată",
      "UNPAID_DAYS_MERGED_HELP": "De când ați schimbat data, am inclus toate zilele libere noi, le-am eliminat pe cele care nu mai sunt în domeniul de aplicare și le-am lăsat neatinse.",
      "MODAL_CALENDAR_TITLE": "Alegeți zile libere obișnuite",
      "MODAL_BUTTON_SAVE": "Salvați",
      "BUTTON_COMPLETE": "Ia-ma inapoi",
      "COMPLETE_TITLE": "Ești gata să pleci.",
      "COMPLETE_TEXT": "Am salvat rezervarea absenței și persoanele relevante au fost anunțate 👍",
      "INVALID_HEADER_TITLE": "Absență invalidă",
      "INVALID_HEADER_TEXT": "Nu putem încărca această rezervare de absență. Poate că a fost anulat sau nu aveți permisiunea să o vedeți.",
      "TITLE_OVERLAPPING_ABSENCE": "Absența concomitentă",
      "TITLE_OVERLAPPING_SHIFTS": "Schimburi suprapuse",
      "TITLE_NOTABLE_EVENTS": "Evenimente notabile",
      "COLUMN_OVERLAPPING_ABSENCE_DATES": "Date",
      "COLUMN_OVERLAPPING_ABSENCE_TYPE": "Tip",
      "COLUMN_OVERLAPPING_ABSENCE_WHO": "Cine",
      "NO_OVERLAPPING_ABSENCE": "Nu există nicio absență concomitentă care să se potrivească filtrelor.",
      "NO_OVERLAPPING_SHIFTS": "Nu există schimburi suprapuse în situl/departamentul angajatului.",
      "NO_NOTABLE_EVENTS": "Nu există evenimente notabile în momentul acestei absențe.",
      "COLUMN_OVERLAPPING_SHIFTS_DATE": "Data",
      "COLUMN_OVERLAPPING_SHIFTS_SITE": "Site/departament",
      "COLUMN_OVERLAPPING_SHIFTS_TYPE": "Tipul schimbării",
      "COLUMN_OVERLAPPING_SHIFTS_OPTIONS": "Opțiuni",
      "LINK_EDIT_SHIFT": "Editați rotația",
      "LINK_DELETE_SHIFT": "Șterge",
      "BUTTON_BULK_SHIFT_OPTIONS": "Acțiuni în vrac",
      "BUTTON_BULK_SHIFT_UNASSIGN_ALL": "Anulați atribuirea selecției",
      "BUTTON_BULK_SHIFT_DELETE_ALL": "Ștergeți selecția",
      "ALERT_BULK_SHIFT_CONFIRM": "Sunteți sigur că doriți să faceți acest lucru la {{count}} schimburi?",
      "ALERT_BULK_SHIFT_EDIT_FAILURE": "Nu am putut modifica schimburile, deoarece ar cauza o problemă de validare. Vă rugăm să faceți această modificare pe rotație pentru a vedea mai multe informații.",
      "ALERT_SINGLE_SHIFT_DELETE_FAILURE": "Nu am putut șterge acea schimbare, deoarece ar cauza o problemă de validare. Vă rugăm să faceți această modificare pe rotație pentru a vedea mai multe informații.",
      "COLUMN_NOTABLE_EVENTS_NAME": "Nume",
      "COLUMN_NOTABLE_EVENTS_DATE": "Data",
      "NOTABLE_EVENTS_ABSENCE_RESTRICTION_TOOLTIP": "Absența este restricționată în timpul acestui eveniment.",
      "LABEL_ADD_A_NOTE": "Opțional scrieți un mesaj pentru angajat",
      "OVERLAPPING_SHIFTS_MORE": "Arătăm doar primele cincisprezece schimburi suprapuse. Rezolvați-le pentru a vedea mai multe.",
      "OVERLAPPING_ABSENCE_MORE": "Vă prezentăm doar primele cincisprezece rezervări suprapuse.",
      "CALENDAR_UNPAID_DAY": "Zi liberă obișnuită",
      "FILTER_LABEL_ENTITY": "Site/departament",
      "FILTER_LABEL_STAFF_GROUP": "Grupul de personal",
      "FILTER_LABEL_ABSENCE_TYPE": "Tip",
      "FILES_NONE": "Nimic nu a fost încărcat pentru această rezervare.",
      "FILES_AVAILABLE": "Aceste documente pot fi găsite și în zona Personal atunci când vizualizați profilul angajatului. Le veți găsi în această categorie: {{categoryName}}",
      "FILES_COLUMN_NAME": "Nume",
      "FILES_COLUMN_UPLOADED_BY": "Încărcat de",
      "FILES_COLUMN_OPTIONS": "Opțiuni",
      "FILES_LINK_DELETE": "Șterge",
      "FILES_CONFIRM_DELETE": "Sunteți sigur că doriți să ștergeți acest lucru? De asemenea, va fi eliminat din fila Documente din profilul angajatului.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Atenție! Solicitantul a ales un timp de început și de terminare care echivalează cu mai mult de {{HalfDayHours}} ore, care este numărul de ore pe care le lucrează în prezent pentru o jumătate de zi.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Atenție! Solicitantul a ales să modifice zilele libere obișnuite, mai degrabă decât să folosească zilele sugerate de Rotaready. Vă rugăm să le examinați cu atenție în fila Editare date și alegeți opțiunea Recalculați din nou, dacă este necesar."
    },
    "ENUM_SCOPE": {
      "ALL_DAY": "Toată ziua",
      "AM": "Numai AM",
      "PM": "Numai PM",
      "CUSTOM": "Ore personalizate"
    },
    "ENUM_APPROVAL_STATE": {
      "APPROVED": "Aprobat",
      "REJECTED": "Respins",
      "PENDING": "În așteptare"
    },
    "ENUM_ALLOWANCE_UNIT": {
      "DAYS": "Zile",
      "HOURS": "Ore"
    },
    "ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL": {
      "BEGINNING_OF_ACCRUAL_YEAR": "Începutul anului de acumulare",
      "EACH_CALENDAR_MONTH": "Fiecare lună calendaristică",
      "EACH_HOUR_WORKED": "Fiecare oră lucrată"
    },
    "ALLOWANCE_IMPACT_DIRECTIVE": {
      "DAYS_DEBIT": "{{count}} deducere de zi",
      "HOURS_DEBIT": "{{count}} deducere oră",
      "DAYS_CREDIT": "{{count}} credit de zi",
      "HOURS_CREDIT": "{{count}} credit pe oră",
      "REMAINING": "({{count}} rămase)",
      "WARNING_EXCEEDS": "Cererea dvs. depășește această alocație, dar poate fi depusă în continuare.",
      "WARNING_NOT_PERMITTED": "Cererea dvs. depășește această indemnizație.",
      "TOOLTIP_PROJECTED": "Rotaready a inclus o predicție a ceea ce este probabil să acumulați. Acest lucru poate fi limitat în funcție de politicile angajatorului dvs.",
      "CALC_TOTAL_ACCRUED": "Total acumulat",
      "CALC_BOOKED": "Rezervat",
      "CALC_PROJECTED": "Acumularea preconizată",
      "CALC_THIS_REQUEST": "Această solicitare",
      "CALC_REMAINING": "Rămas",
      "CALC_CONVERTED_TO_DAYS": "Ca zile"
    }
  }
};

module.exports = translations;
