module.exports = (
  $scope,
  $state,
  $stateParams,
  $translate,
  $window,
  $http,
  EnvironmentDataService,
  AlertService,
  ENDPOINT_API,
) => {
  'ngInject';

  const apiBaseUrl = `${ENDPOINT_API}/integration/clover`;

  $scope.props = {
    loadingData: true,
    redirectLoading: false,
    saveLoading: false,
    formData: {},
  };

  if ($stateParams.success === 'true') {
    AlertService.add('success', 'Clover has been successfully connected to Rotaready! Please finish setup by adding mappings below.');
  } else if ($stateParams.success === 'false') {
    AlertService.add('danger', 'We were unable to connect to Clover. Please try again.');
  }

  function handleError(error, status) {
    if (status !== 403) {
      AlertService.add('danger', 'We weren\'t able to load or modify the settings just then. Please try again');
    }
  }

  function loadSettings() {
    $scope.props.loadingData = true;

    $http.get(`${apiBaseUrl}/settings`)
      .then(({ data }) => {
        $scope.props.configuration = data;
        $scope.props.loadingData = false;
      })
      .catch(({ status, data }) => {
        handleError(data, status);
      });
  }

  function loadMetadata() {
    $scope.props.loadingData = true;

    EnvironmentDataService.fetchAll([
      EnvironmentDataService.DataType.EntityGroup,
      EnvironmentDataService.DataType.Stream,
    ])
      .then(([
        entityGroup,
        stream,
      ]) => {
        $scope.props.entityList = entityGroup.data;
        $scope.props.streamList = stream.data
          .filter(({ applicableToSales }) => applicableToSales)
          .map(({ id, name, deleted }) => ({ id, label: name, deleted }));

        $scope.props.entityById = {};
        $scope.props.streamById = {};

        entityGroup.data.forEach((group) => {
          group.entities.forEach((entity) => {
            $scope.props.entityById[entity.id] = entity.name;
          });
        });

        stream.data.forEach((stream) => {
          $scope.props.streamById[stream.id] = stream.name;
        });

        loadSettings();
      });
  }

  loadMetadata();

  $scope.save = async () => {
    $scope.props.saveLoading = true;

    const {
      props: {
        configuration: {
          status,
          mappings,
        },
      },
    } = $scope;

    const data = {
      status,
      mappings,
    };

    try {
      await $http.put(`${apiBaseUrl}/settings`, data);
      AlertService.add('success', 'Your settings have been updated.');
      $scope.props.saveLoading = false;
      $scope.$apply();
    } catch ({ error, status }) {
      $scope.props.saveLoading = false;
      handleError(error, status);
    }
  };

  $scope.connect = async () => {
    $scope.props.redirectLoading = true;

    try {
      const { data } = await $http.get(`${apiBaseUrl}/authorizeUrl`, {
        params: {
          returnUrl: $window.location.href,
        },
      });

      $window.location.href = data.authorizeUrl;
    } catch ({ error, status }) {
      $scope.props.redirectLoading = false;
      handleError(error, status);
    }
  };

  $scope.getCategories = async (merchantId) => {
    $scope.props.categoriesLoading = true;
    $scope.props.activeMerchant = merchantId;
    $scope.props.formData.category = undefined;
    $scope.props.formData.entityId = undefined;

    try {
      const { data } = await $http.get(`${apiBaseUrl}/categories`, {
        params: {
          merchantId,
        },
      });

      $scope.props.categoryList = data.categories
        .map((props) => ({ ...props, label: props.name }));
      $scope.props.categoryList.sort((a, b) => a.sortOrder - b.sortOrder);
      $scope.props.categoriesLoading = false;
      $scope.$apply();
    } catch ({ error, status }) {
      $scope.props.categoriesLoading = false;
      handleError(error, status);
    }
  };

  $scope.onEntitySelect = (entity) => {
    if (!entity) {
      return;
    }

    $scope.props.formData.entityId = entity.id;
  };

  $scope.addMapping = (merchantId) => {
    const {
      props: {
        formData: {
          categoryId,
          entityId,
          streamId,
        },
      },
    } = $scope;

    if (!categoryId || !entityId || !streamId) {
      return;
    }

    const category = $scope.props.categoryList.find(({ id }) => id === categoryId);

    if (!category) {
      return;
    }

    $scope.removeMapping(merchantId, category.id);

    $scope.props.configuration.mappings.push({
      merchantId,
      categoryId: category.id,
      categoryName: category.name,
      entityId,
      streamId,
    });
  };

  $scope.removeMapping = (merchantId, categoryId) => {
    const existing = $scope.props.configuration.mappings.findIndex((m) => m
      .merchantId === merchantId && m.categoryId === categoryId);

    if (existing !== -1) {
      $scope.props.configuration.mappings.splice(existing, 1);
    }
  };
};
