var controllers = require('../../module');

controllers.controller('BudgetingCtrl', ['$scope', '$uibModal', '$state', 'SettingsService', 'EnvironmentDataService', 'AlertService', 'LocaleService',
    function ($scope, $uibModal, $state, SettingsService, EnvironmentDataService, AlertService, LocaleService) {
        $scope.dataLoaded = false;

        SettingsService.getBudgetDashboard()
            .success(function (data) {
                $scope.entityGroups = data.entityGroups;

                angular.forEach($scope.entityGroups, function (group) {
                    for (var entityId in group.byEntity) {
                        if (!group.byEntity.hasOwnProperty(entityId)) {
                            continue;
                        }

                        var entityObj = group.byEntity[entityId];

                        if (entityObj.periods.length) {
                            var period = entityObj.periods[0];

                            period.tooltip = moment(period.startDate).format('ll') + ' to ' + moment(period.endDate).format('ll');
                        }
                    }
                });

              EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
                .then(({ data }) => {
                  $scope.entityList = data;
                  $scope.dataLoaded = true;
                });
            })
            .error(function (error, status) {
                if (status === 500) {
                    AlertService.add('danger', 'We couldn\'t load the budgeting tool just then. Please try again.');
                }

                $state.go('settings.index');
            });

        $scope.getSymbol = function (currencyCode) {
            return LocaleService.getCurrencySymbol(currencyCode);
        };

        $scope.manageEntity = function (entity) {
            if (!entity) {
                return;
            }

            $state.go('^.byEntity', {entity: entity.id});
        };
    }
]);
