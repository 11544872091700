const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/employment/validationModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/absence/validationModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/allowanceValidationModal.html');

const services = require('./module');

services.factory('StaffCommon', ($uibModal, $translate, SessionService) => {
  'ngInject';

  const translations = $translate.instant([
    'ABSENCE.ENUM_SCOPE.ALL_DAY',
    'ABSENCE.ENUM_SCOPE.AM',
    'ABSENCE.ENUM_SCOPE.PM',
    'ABSENCE.ENUM_SCOPE.CUSTOM',
    'ABSENCE.ENUM_APPROVAL_STATE.APPROVED',
    'ABSENCE.ENUM_APPROVAL_STATE.REJECTED',
    'ABSENCE.ENUM_APPROVAL_STATE.PENDING',
    'ABSENCE.ENUM_ALLOWANCE_UNIT.DAYS',
    'ABSENCE.ENUM_ALLOWANCE_UNIT.HOURS',
    'ABSENCE.ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL.BEGINNING_OF_ACCRUAL_YEAR',
    'ABSENCE.ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL.EACH_CALENDAR_MONTH',
    'ABSENCE.ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL.EACH_HOUR_WORKED',
  ]);

  return {
    onEmploymentValidationResponse: (validationResult) => {
      const largeIssueCodes = [8, 9, 10, 11, 12, 26];
      const issueCodes = Object.keys(validationResult.issueCountByCode).map(Number);
      const isLarge = issueCodes.some((code) => largeIssueCodes.includes(code));

      return $uibModal.open({
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: require('../controllers/staff/view/employment/validationModal'),
        size: isLarge ? 'lg' : 'md',
        resolve: {
          data: () => ({
            validationResult,
          }),
        },
      });
    },

    permittedFileExtensions: ['.pdf', '.xls', '.xlsx', '.doc', '.docx',
      '.ppt', '.pptx', '.csv', '.txt', '.png', '.gif', '.jpg',
      '.jpeg', '.heic', '.key', '.pages', '.numbers', '.eml', '.msg', '.webp'],

    canAccessSensitiveDocuments: () => SessionService.isPermissioned('staff.sensitiveDocuments'),

    onAbsenceValidationResponse: (result) => $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate2,
      controller: ($scope, $uibModalInstance, data) => {
        $scope.issueCodes = data.validationIssues.map(({ code }) => code);

        $scope.close = () => {
          $uibModalInstance.dismiss('cancel');
        };
      },
      resolve: {
        data: () => ({
          validationIssues: result.validationIssues,
        }),
      },
    }),

    onAllowanceValidationResponse: (result) => $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate3,
      controller: ($scope, $uibModalInstance, data) => {
        $scope.issueCodes = data.validationIssues.map(({ code }) => code);

        $scope.close = () => {
          $uibModalInstance.dismiss('cancel');
        };
      },
      resolve: {
        data: () => ({
          validationIssues: result.validationIssues,
        }),
      },
    }),

    getLabelFromApprovalState: (approvalState) => {
      switch (approvalState) {
        default:
        case 0:
          return translations['ABSENCE.ENUM_APPROVAL_STATE.PENDING'];
        case 1:
          return translations['ABSENCE.ENUM_APPROVAL_STATE.APPROVED'];
        case 2:
          return translations['ABSENCE.ENUM_APPROVAL_STATE.REJECTED'];
      }
    },

    getLabelFromScope: (scope) => {
      switch (scope) {
        default:
        case 0:
          return translations['ABSENCE.ENUM_SCOPE.ALL_DAY'];
        case 1:
          return translations['ABSENCE.ENUM_SCOPE.AM'];
        case 2:
          return translations['ABSENCE.ENUM_SCOPE.PM'];
        case 3:
          return translations['ABSENCE.ENUM_SCOPE.CUSTOM'];
      }
    },

    getLabelFromAllowanceUnit: (unit) => {
      switch (unit) {
        default:
        case 'DAYS':
          return translations['ABSENCE.ENUM_ALLOWANCE_UNIT.DAYS'];
        case 'HOURS':
          return translations['ABSENCE.ENUM_ALLOWANCE_UNIT.HOURS'];
      }
    },

    getLabelFromScheduleOfAccrual: (scheduleOfAccrual) => {
      switch (scheduleOfAccrual) {
        default:
        case 'BEGINNING_OF_ACCRUAL_YEAR':
          return translations['ABSENCE.ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL.BEGINNING_OF_ACCRUAL_YEAR'];
        case 'EACH_CALENDAR_MONTH':
          return translations['ABSENCE.ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL.EACH_CALENDAR_MONTH'];
        case 'EACH_HOUR_WORKED':
          return translations['ABSENCE.ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL.EACH_HOUR_WORKED'];
      }
    },
  };
});
