var controllers = require('../../module');

controllers.controller('WtdViolationsModalCtrl', ['$scope', '$uibModalInstance', 'RotaService', 'AlertService', 'data',
    function ($scope, $uibModalInstance, RotaService, AlertService, data) {
        const { entityId } = data;
        $scope.violationTypeFilter = '0';

        $scope.dateStart = moment().startOf('week');
        $scope.dateEnd = $scope.dateStart.clone().add(1, 'week');

        $scope.violationsFilter = function (row) {
            if ($scope.violationTypeFilter > 0 && row.violationType !== parseInt($scope.violationTypeFilter, 10)) {
                return false;
            }

            return true;
        };

        function getWtdViolations() {
            $scope.report = [];
            $scope.dataLoaded = false;

            RotaService.getWtdViolations($scope.dateStart, $scope.dateEnd, entityId)
                .success(function (data) {
                    $scope.report = data.violations;
                    $scope.dataLoaded = true;
                })
                .error(function () {
                    $scope.close();
                });
        }

        getWtdViolations();

        $scope.changeDate = function (newDate, isStart) {
            if (isStart) {
                $scope.dateStart = newDate.clone();

                if (!$scope.dateEnd.isAfter($scope.dateStart)) {
                    $scope.dateEnd = newDate.clone().add(1, 'week');
                }
            } else {
                $scope.dateEnd = newDate.clone();

                if (!$scope.dateEnd.isAfter($scope.dateStart)) {
                    $scope.dateStart = newDate.clone().subtract(1, 'week');
                }
            }

            // Limit to max 12 months (as per API restriction)
            if ($scope.dateEnd.diff($scope.dateStart, 'months') > 12) {
                $scope.dateEnd = $scope.dateStart.clone().add(12, 'months');
            }

            getWtdViolations();
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.recalculate = function () {
            $scope.recalculateActioning = true;

            RotaService.recalculateWtdViolations(entityId)
                .success(function () {
                    AlertService.add('success', 'Your report is now calculating. We\'ll send you a notification when it\'s ready to view.');
                    $scope.close();
                })
                .error(function () {
                    $scope.close();
                });
        };
    }]);
