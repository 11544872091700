require('./sessionService');
require('./authService');
require('./alertService');
require('./availabilityService');
require('./rotaService');
require('./staffService');
require('./staffCommon');
require('./templateService');
require('./notificationService');
require('./debounce');
require('./commonService');
require('./localeService');
require('./betaService');
require('./exportService');
require('./messagingService');
require('./uibModalDrawer');
require('./environmentDataService');
require('./settingsService');

require('./attendanceService');
require('./attendanceCommon');
