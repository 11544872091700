const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/addNew/duplicateModal.html');

module.exports = (
  $scope,
  $state,
  $translate,
  $uibModalInstance,
  $uibModal,
  AuthService,
  EnvironmentDataService,
  SessionService,
  StaffService,
  AlertService,
) => {
  'ngInject';

  const defaultDateStart = moment();
  const defaultDateOfBirth = moment('1970-01-01');

  const translations = $translate.instant([
    'STAFF.ADD_NEW.ALERT_SUCCESS',
    'STAFF.ADD_NEW.ALERT_500',
    'STAFF.ADD_NEW.ALERT_403',
  ]);

  const userSelfOnboardingEnabled = SessionService.isUserSelfOnboardingEnabled();

  $scope.props = {
    actionInProgress: true,
    oidcEnabled: AuthService.isOIDCLoginEnabled(),
    rotareadyLoginEnabled: AuthService.isRotareadyLoginEnabled(),
    userSelfOnboardingEnabled,
    defaultDateStart,
    defaultDateOfBirth,
    formData: {
      sendMagicLink: true,
      optInOnboarding: userSelfOnboardingEnabled,
      entityId: SessionService.getEntity(),
    },
  };

  $scope.onOptInOnboardingChange = () => {
    if ($scope.props.formData.optInOnboarding) {
      $scope.props.formData.sendMagicLink = $scope.props.formData.optInOnboarding;
    }
  };

  const addShape = {
    dateStart: defaultDateStart.format('YYYY-MM-DD'),
    dateOfBirth: defaultDateOfBirth.format('YYYY-MM-DD'),
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.StaffGroup,
    EnvironmentDataService.DataType.PermissionLevel,
    EnvironmentDataService.DataType.Position,
    EnvironmentDataService.DataType.Company,
  ])
    .then(([
      entityGroup,
      staffGroup,
      permissionLevel,
      position,
      company,
    ]) => {
      $scope.props.entityGroupList = entityGroup.data;
      $scope.props.permissionLevelList = permissionLevel.data
        .map(({ id, name }) => ({ id, label: name }));

      addShape.defaultStaffGroupId = staffGroup.data.find((r) => !r.deleted).id;
      addShape.defaultPositionId = position.data.find((r) => !r.deleted).id;
      addShape.defaultCompanyId = company.data.find((r) => !r.deleted).id;

      $scope.props.actionInProgress = false;
    });

  $scope.close = () => $uibModalInstance.dismiss();

  $scope.onDateStartChange = (dateStart) => {
    addShape.dateStart = dateStart.format('YYYY-MM-DD');
  };

  $scope.onDateOfBirthChange = (dateStart) => {
    addShape.dateOfBirth = dateStart.format('YYYY-MM-DD');
  };

  $scope.onCopyExistingUser = (user) => {
    addShape.cloneUserId = user ? user.id : undefined;
  };

  function addNewEmployee(fields) {
    $scope.props.actionInProgress = true;
    $scope.props.serverErrorCode = undefined;

    StaffService.addSingleAccount(fields)
      .then(({ data }) => {
        const { userIds } = data;
        const [id] = userIds;

        $uibModalInstance.close();
        $state.go('staff.view.personal', { id });
        AlertService.add('success', translations['STAFF.ADD_NEW.ALERT_SUCCESS']);
      })
      .catch(({ status, data }) => {
        $scope.props.actionInProgress = false;

        if (status === 403) {
          if (data.errors) {
            const {
              payload: {
                errorCode,
              },
            } = data.errors[0];

            if (errorCode) {
              $scope.props.serverErrorCode = errorCode;
              return;
            }
          }

          AlertService.add('warning', translations['STAFF.ADD_NEW.ALERT_403']);
        }

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.ADD_NEW.ALERT_500']);
        }
      });
  }

  $scope.save = () => {
    const {
      dateStart,
      dateOfBirth,
      cloneUserId,
      defaultStaffGroupId,
      defaultPositionId,
      defaultCompanyId,
    } = addShape;

    const {
      firstName,
      lastName,
      email,
      entityId,
      permissionLevelId,
      sendMagicLink,
      optInOnboarding,
    } = $scope.props.formData;

    if (!firstName || !lastName || !email || !permissionLevelId) {
      return;
    }

    const fields = {
      user: {
        firstName,
        lastName,
        birthDate: dateOfBirth,
        email,
        permissionLevelId,
      },
      employment: {
        dateStart,
      },
      appointments: [{
        dateStart,
        entityId,
        groupId: defaultStaffGroupId,
        positionId: defaultPositionId,
        companyId: defaultCompanyId,
      }],
      sendAccountSetupInvitation: sendMagicLink,
      allowSelfOnboarding: optInOnboarding,
      cloneUserId,
    };

    $scope.props.actionInProgress = true;

    StaffService.getDuplicateAccounts({
      firstName,
      lastName,
      email,
      dob: dateOfBirth,
      entityId,
    })
      .then(({ data }) => {
        const { results } = data;

        if (!results.length) {
          return addNewEmployee(fields);
        }

        $scope.props.actionInProgress = false;

        const modal = $uibModal.open({
          templateUrl: autoNgTemplateLoaderTemplate1,
          controller: require('./duplicateModal'),
          size: 'lg',
          resolve: {
            data: () => ({
              duplicates: results,
            }),
          },
        });

        modal.result.then((proceedAnyway) => {
          if (proceedAnyway) {
            addNewEmployee(fields);
          } else {
            $scope.close();
          }
        });
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.ADD_NEW.ALERT_500']);
        }
      });
  };
};
