const services = require('./module');

services.factory('AttendanceService', ['$http', 'ENDPOINT_API',
  function ($http, ENDPOINT_API) {
    const baseUrl = `${ENDPOINT_API}/attendance/`;

    return {
      getAutoMatchedEvents: (params) => $http.get(`${baseUrl}autoMatched`, { params }),

      getEvents: (params) => $http.get(`${baseUrl}events/paginated`, { params }),
      getEvent: (id) => $http.get(`${baseUrl}event/${id}`),
      addEvent: (data) => $http.post(`${baseUrl}event`, data),
      deleteEvent: (id) => $http.delete(`${baseUrl}event/${id}`),
      updateEvent: (id, data) => $http.put(`${baseUrl}event/${id}`, data),
      getEventAudit: (id) => $http.get(`${baseUrl}event/${id}/audit`),

      getTrustedDevices: (params) => $http.get(`${baseUrl}devices`, { params }),
      getTrustedDeviceMappings: (id) => $http.get(`${baseUrl}devices/${id}/mappings`),
      updateTrustedDevice: (id, data) => $http.put(`${baseUrl}devices/${id}`, data),
      updateTrustedDeviceMappings: (id, data) => $http.put(`${baseUrl}devices/${id}/mappings`, data),

      getIssues: (params) => $http.get(`${baseUrl}issues`, { params }),
      dismissFacialConcern: (id, data) => $http.put(`${baseUrl}issues/dismissFacialConcern/${id}`, data),
      dismissIssue: (data) => $http.put(`${baseUrl}issues/dismiss`, data),
    };
  }]);
