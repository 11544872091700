var controllers = require('../../module');

controllers.controller('BudgetingEditWeekModalCtrl', ['$scope', '$uibModalInstance', 'EnvironmentDataService', 'SettingsService', 'AlertService', 'data',
    function ($scope, $uibModalInstance, EnvironmentDataService, SettingsService, AlertService, data) {
        $scope.dataLoaded = false;
        $scope.isOverwrite = data.isOverwrite;
        $scope.weeks = data.weeks;
        $scope.currencySymbol = data.currencySymbol;

        var costStreams = [];

        $scope.formData = {
            budgetType: 1,
            budgetStyle: 0, // force them to pick
            byStream: {}
        };
        $scope.budgetStreams = [];

      EnvironmentDataService.fetch(EnvironmentDataService.DataType.Stream)
        .then(({ data: streams }) => {
          costStreams = streams.filter(({ deleted }) => !deleted);

          if (!data.isOverwrite && data.week) {
            var budget = data.week.budget;

            if (budget.total.hasMonetary && budget.total.hasHours) {
              $scope.formData.budgetType = 3;
            } else if (budget.total.hasHours) {
              $scope.formData.budgetType = 2;
            } else {
              $scope.formData.budgetType = 1;
            }

            $scope.formData.budgetStyle = budget.byStream && budget.byStream.length ? 2 : 1;
            $scope.changeBudgetStyle();
          }

          $scope.dataLoaded = true;
        });

        $scope.changeBudgetStyle = function () {
            if ($scope.formData.budgetStyle == 1) {
                // General budget
                $scope.budgetStreams = [{
                    id: 0,
                    name: 'Budget details'
                }];
            } else {
                // Separate budget per cost stream
                $scope.budgetStreams = angular.copy(costStreams);
            }

            $scope.formData.byStream = {};

            $scope.budgetStreams.forEach(function (s) {
                var values = {
                    sales: '1000.00',
                    wage: '1000.00',
                    wagePercentage: 100,
                    hours: 1,
                    lockRatios: true,
                };

                if (data.week) {
                    var budget = data.week.budget;
                    var component = budget.total;

                    if (budget.byStream && budget.byStream.length) {
                        const stream = budget.byStream.find(function (b) {
                            return b.id === s.id;
                        });

                        component = stream ? stream.budget : undefined;
                    }

                    if (component) {
                        values.sales = component.sales;
                        values.wage = component.wage;
                        values.hours = component.hours;
                        values.wagePercentage = convertWagePercentage(component.wagePercentage);

                        var wagePercentage = calculateWagePercentage(component);

                        if (wagePercentage !== values.wagePercentage) {
                            values.lockRatios = false;
                        }
                    }
                }

                $scope.formData.byStream[s.id] = values;
            });
        };

        $scope.updateWagePercentage = function (stream) {
            if (!stream.lockRatios) {
                return;
            }

            stream.wagePercentage = calculateWagePercentage(stream);
        };

        $scope.updateWageBudget = function (stream) {
            if (!stream.lockRatios) {
                return;
            }

            stream.wage = 0;

            if (!isNaN(stream.sales) && stream.sales > 0 && !isNaN(stream.wagePercentage)) {
                stream.wage = Math.round(((stream.wagePercentage / 100) * stream.sales) * 100) / 100;
            }
        };

        function convertWagePercentage(wagePercentage) {
            if (wagePercentage !== null) {
                return Math.round(wagePercentage * 1000) / 10;
            }

            return null;
        }

        function calculateWagePercentage(stream) {
            if (!isNaN(stream.sales) && stream.sales > 0 && !isNaN(stream.wage) && stream.wage > 0) {
                // Sensible default of 1dp
                return Math.round(((stream.wage / stream.sales) * 100) * 10) / 10;
            }

            return 0;
        }

        $scope.update = function () {
            var byStream = [];
            var isOk = true;

            var type = parseInt($scope.formData.budgetType, 10);
            var style = parseInt($scope.formData.budgetStyle, 10);

            if (!type || !style) {
                return alert("Please first pick a budget type and style.");
            }

            for (var streamId in $scope.formData.byStream) {
                if (!$scope.formData.byStream.hasOwnProperty(streamId)) {
                    continue;
                }

                const budget = {
                    sales: null,
                    wage: null,
                    wagePercentage: null,
                    hours: null,
                };

                const values = $scope.formData.byStream[streamId];

                const sales = parseFloat(values.sales);
                const wage = parseFloat(values.wage);
                const wagePercentage = parseFloat(values.wagePercentage);
                const hours = parseFloat(values.hours);

                if (type !== 2) {
                    if (Number.isNaN(sales)
                      && Number.isNaN(wage)
                      && Number.isNaN(wagePercentage)) {
                        isOk = false;
                        continue;
                    }

                    budget.sales = Number.isNaN(sales) ? null : sales;
                    budget.wage = Number.isNaN(wage) ? null : wage;
                    budget.wagePercentage = Number.isNaN(wagePercentage)
                      ? null : wagePercentage / 100;
                }

                if (type !== 1) {
                    if (Number.isNaN(hours)) {
                        isOk = false;
                        continue;
                    }

                    budget.hours = hours;
                }

                byStream.push({
                    id: parseInt(streamId, 10),
                    budget: budget,
                });
            }

            if (!isOk) {
                return alert("You've entered an invalid value for the sales, wage or hours budget for one of your cost streams.");
            }

            $scope.updateActioning = true;

            var fields = {
                type: type,
                style: style,
                weeks: []
            };

            if (!data.isOverwrite) {
                fields.weeks.push(moment(data.week.weekBeginning).format('YYYY-MM-DD'));
            } else {
                fields.weeks = data.weeks;
            }

            if (style === 1) {
                fields.budget = byStream.find(function (stream) {
                    return stream.id === 0;
                }).budget;
            } else {
                fields.byStream = byStream.filter(function (stream) {
                    return stream.id > 0;
                });
            }

            SettingsService.updateBudgetPeriodWeeks(data.periodId, fields)
                .success(function () {
                    AlertService.add("success", "The budget has been successfully updated for your chosen week(s).");
                    $uibModalInstance.close();
                })
                .error(function (error, status) {
                    $scope.updateActioning = false;

                    if (status == 500) {
                        alert('Oops, it seems we were unable to save just then. Please try again.');
                    }

                    if (status == 400) {
                        alert('Oops, we couldn\'t save the budget just then.');
                    }
                });
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);
