const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/validationEphemeralRecords.html');

const directives = require('../module');

directives.directive('rrValidationEphemeralRecords', () => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      issues: '=',
    },
    link: () => {},
  };
});
