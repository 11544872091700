module.exports = (
  $scope,
  $http,
  $translate,
  $state,
  $stateParams,
  AlertService,
  SessionService,
  EnvironmentDataService,
  ENDPOINT_API,
) => {
  'ngInject';

  const translations = $translate.instant([
    'REPORTS.CURRENTLY_CLOCKED_IN.ERROR_500',
  ]);

  let isFirstLoad = true;
  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [SessionService.getEntity()];

  $scope.props = {
    loadingData: true,
    data: [],
    defaultEntityIds,
  };

  $scope.getDataParams = {
    'entityIds[]': defaultEntityIds,
  };

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.getDataParams['entityIds[]'] = entityIds.length ? entityIds : undefined;
    $scope.loadData();
  };

  $scope.loadData = function () {
    $scope.props.loadingData = true;

    const { getDataParams } = $scope;
    const entityIds = getDataParams['entityIds[]'];

    $state.go('.', {
      entityIds: entityIds ? entityIds.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
      inherit: false,
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $http.get(`${ENDPOINT_API}/attendance/helpers/currentlyClockedIn`, {
      params: getDataParams,
    })
      .then(({ data }) => {
        $scope.props.data = data.events;
        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.TRONC_STATUS.ERROR_500']);
        }
      });
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
    .then(({ data }) => {
      $scope.props.entityGroupList = data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => ({
            id: entity.id,
            label: entity.name,
            parentId: entity.groupId,
            depth: 1,
          })),
        ]));

      $scope.loadData();
    });
};
