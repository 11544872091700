const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/avatarStack.html');

import directives from '../module';

const MAX_STACK_SIZE = 2;

const textLight = '#ffffff';
const textDark = '#052066';

const colourOptions = [
  { background: '#ffc400', text: textDark },
  { background: '#6e49f4', text: textLight },
  { background: '#dc1e7c', text: textLight },
  { background: '#00ab5b', text: textLight },
  { background: '#00d1b2', text: textDark },
  { background: '#ff8e35', text: textDark },
  { background: '#052066', text: textLight },
  { background: '#c5d2f5', text: textDark },
  { background: '#ff1e0e', text: textLight },
  { background: '#bbc3ca', text: textDark },
];

const colourOptionCount = colourOptions.length;

directives.directive('rrStaffAvatarStack', ['SessionService',
  (SessionService) => ({
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      users: '=',
    },
    link: ($scope) => {
      $scope.props = {};

      $scope.$watch('users', (users) => {
        const ownUserId = SessionService.getUserId();
        const otherParticipants = users != null
          ? users.filter(({ userId }) => userId !== ownUserId) : [];

        if (!otherParticipants.length) {
          return;
        }

        const mappedUsers = otherParticipants.map((user, index) => {
          const initials = `${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`;
          const pictureUrl = user.userPicture || user.picture;

          const style = colourOptions[
            (user.userAvatarModuloDividend || user.userId || user.id) % colourOptionCount];

          const avatarStyle = {
            'background-color': style.background,
            color: style.text,
            'z-index': 100,
            position: 'relative',
            left: `${index * -16}px`,
            cursor: 'default',
          };

          if (index !== MAX_STACK_SIZE - 1) {
            avatarStyle['box-shadow'] = '1px 1px 0 0px #fff';
          }

          let avatarText = null;
          if (pictureUrl) {
            avatarStyle['background-image'] = `url(${pictureUrl})`;
          } else {
            avatarText = initials;
          }

          return {
            avatarStyle,
            avatarText,
          };
        });

        let avatarUsers = mappedUsers;
        if (mappedUsers.length > MAX_STACK_SIZE) {
          avatarUsers = mappedUsers.slice(0, MAX_STACK_SIZE - 1);

          const avatarStyle = {
            'background-color': '#475b68',
            color: textLight,
            'z-index': 99,
            position: 'relative',
            left: `${(MAX_STACK_SIZE - 1) * -16}px`,
            cursor: 'default',
          };

          avatarUsers.push({
            avatarStyle,
            avatarText: `+${mappedUsers.length - avatarUsers.length}`,
          });
        }

        $scope.props.avatarUsers = avatarUsers;
        $scope.props.size = otherParticipants.length === 1 ? 'md' : 'sm';
      });
    },
  }),
]);
