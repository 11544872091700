const translations = {
  "STAFF": {
    "CONSTANTS": {
      "UK_STARTER_DECLARATION_A": "R: Este es su primer trabajo desde el inicio del año fiscal (6 de abril)",
      "UK_STARTER_DECLARATION_B": "B: Este es actualmente su único trabajo",
      "UK_STARTER_DECLARATION_C": "C: Tienen otro trabajo o pensión",
      "UK_STUDENT_LOAN_PLAN_1": "Plano 1",
      "UK_STUDENT_LOAN_PLAN_2": "Plano 2",
      "UK_STUDENT_LOAN_PLAN_4": "Plano 4",
      "UK_STUDENT_LOAN_PLAN_5": "Plano 5",
      "UK_STUDENT_LOAN_PLAN_POSTGRADUATE": "Postgrado",
      "UK_SETTLED_STATUS_NA": "No aplicable",
      "UK_SETTLED_STATUS_PRE_SETTLED": "Preestablecido",
      "UK_SETTLED_STATUS_SETTLED": "Resuelto",
      "MARITAL_STATUS_SINGLE": "Individual",
      "MARITAL_STATUS_MARRIED": "Casado",
      "MARITAL_STATUS_DIVORCED": "divorciado",
      "MARITAL_STATUS_WIDOWED": "Viudo",
      "MARITAL_STATUS_CIVIL_PARTNERSHIP": "Asociación civil",
      "PAYMENT_METHOD_ELECTRONIC": "Transferencia electrónica",
      "PAYMENT_METHOD_CHEQUE": "Cheque",
      "PAYMENT_METHOD_MANUAL": "Manual",
      "CONTRACTUAL_OBLIGATION_PERIOD_WEEK": "Por semana",
      "CONTRACTUAL_OBLIGATION_PERIOD_MONTH": "Por mes",
      "CONTRACTUAL_OBLIGATION_UNIT_HOURS": "Horas",
      "CONTRACTUAL_OBLIGATION_UNIT_DAYS": "Días",
      "PAY_AMOUNT_TYPE_HOURLY": "Tarifa por hora",
      "PAY_AMOUNT_TYPE_DAILY": "Tarifa diaria",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salario anual",
      "PAY_FREQUENCY_WEEKLY": "Semanalmente",
      "PAY_FREQUENCY_FORTNIGHTLY": "Quincenal",
      "PAY_FREQUENCY_MONTHLY": "Mensualmente",
      "PAY_FREQUENCY_FOUR_WEEKLY": "Cuatrienales"
    },
    "OVERVIEW": {
      "ERROR_500": "No pudimos cargar ningún dato en ese momento. Vuelva a intentarlo.",
      "TITLE": "Personal",
      "ADD_STAFF": "Añadir nuevo",
      "NO_MATCH": "No hemos podido encontrar a nadie que coincida con tus filtros.",
      "NO_ACTIVE_APPT_TAG": "Inactivo",
      "NOT_APPROVED_TAG": "No aprobado",
      "DOCUMENTS_INVALID_TAG": "Documentos no válidos",
      "NOT_INVITED_TAG": "No invitado",
      "NOT_SETUP_TAG": "Invitado",
      "EMPLOYER_INCOMPLETE_TAG": "Incompleto",
      "SELF_ONBOARDING": "Incorporación automática",
      "ACCOUNT_TAB_LABEL": "Cuenta",
      "PERSONAL_TAB_LABEL": "Personal",
      "EMPLOYMENT_TAB_LABEL": "Empleo",
      "PAY_TAB_LABEL": "Pagar",
      "SCHEDULE_TAB_LABEL": "Programar",
      "ABSENCE_TAB_LABEL": "Ausencia",
      "AVAILABILITY_TAB_LABEL": "Disponibilidad",
      "SHIFT_ROUTINE_TAB_LABEL": "Rutinas de turnos",
      "DOCUMENTS_TAB_LABEL": "Documentos",
      "ATTENDANCE_TAB_LABEL": "Asistencia",
      "NOTES_TAB_LABEL": "Notas",
      "FILTER_LABEL_SITE": "Sitio/departamento",
      "FILTER_LABEL_POSITION": "Función laboral",
      "FILTER_LABEL_STAFF_GROUP": "Grupo de personal",
      "FILTER_LABEL_EMPLOYMENT": "Situación laboral",
      "FILTER_LABEL_APPROVAL_STATUS": "Estado de aprobación",
      "FILTER_LABEL_EMPLOYEE_SETUP": "Invitación Rotaready",
      "FILTER_LABEL_EMPLOYER_SETUP": "Finalización de la cuenta",
      "FILTER_LABEL_EMPLOYMENT_START": "Fecha de incorporación",
      "FILTER_LABEL_EMPLOYMENT_END": "Fecha de salida",
      "FILTER_LABEL_EMPLOYMENT_START_OR_END": "Fecha de ingreso o de salida",
      "BUTTON_COLUMNS": "Columnas",
      "BUTTON_EXPORT": "Exportación",
      "EMPLOYMENT_STATUS_ACTIVE": "Activo",
      "EMPLOYMENT_STATUS_INACTIVE": "Inactivo",
      "APPROVAL_STATUS_APPROVED": "Aprobado",
      "APPROVAL_STATUS_NOT_APPROVED": "No aprobado",
      "EMPLOYEE_SETUP_COMPLETE": "Aceptado",
      "EMPLOYEE_SETUP_NOT_COMPLETE": "Pendiente o no enviado",
      "EMPLOYER_SETUP_COMPLETE": "Completa",
      "EMPLOYER_SETUP_NOT_COMPLETE": "Incompleto",
      "COLUMN_NAME": "Nombre",
      "COLUMN_NEXT_SHIFT": "Próximo turno",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_BIRTH_DATE": "Fecha de nacimiento",
      "COLUMN_EMAIL": "Correo electrónico",
      "COLUMN_MOBILE": "Teléfono",
      "COLUMN_OTHER_NAMES": "Otros nombres",
      "COLUMN_ADDRESS_LINE_1": "Línea de dirección 1",
      "COLUMN_ADDRESS_LINE_2": "Línea de dirección 2",
      "COLUMN_ADDRESS_CITY": "Pueblo/ciudad",
      "COLUMN_ADDRESS_COUNTY": "Condado/región",
      "COLUMN_ADDRESS_POST_CODE": "Código postal",
      "COLUMN_ADDRESS_COUNTRY": "País",
      "COLUMN_PREFERRED_NAME": "Nombre preferido",
      "COLUMN_GENDER": "Género",
      "COLUMN_TITLE": "Título",
      "COLUMN_ETHNICITY": "Etnia",
      "COLUMN_NATIONALITY": "Nacionalidad",
      "COLUMN_MARITAL_STATUS": "Estado civil",
      "COLUMN_SETTLED_STATUS": "Estatus establecido (Reino Unido)",
      "COLUMN_PASSPORT_NUMBER": "Número de pasaporte",
      "COLUMN_PASSPORT_EXPIRY_DATE": "Caducidad del pasaporte",
      "COLUMN_WORK_EMAIL": "Correo electrónico secundario",
      "COLUMN_WORK_PHONE": "Teléfono secundario",
      "COLUMN_BANK_ACCOUNT_NAME": "Nombre de la cuenta bancaria",
      "COLUMN_BANK_ACCOUNT_NUMBER": "Número de cuenta bancaria",
      "COLUMN_BANK_ACCOUNT_SORT_CODE": "Código de clasificación bancaria",
      "COLUMN_PAYROLL_PAYMENT_METHOD": "Método de pago",
      "COLUMN_NATIONAL_INSURANCE_NUMBER": "Número de seguro nacional (Reino Unido)",
      "COLUMN_STUDENT_LOANS": "Préstamos estudiantiles (Reino Unido)",
      "COLUMN_AVERAGE_HOURS_DAY": "Promedio de horas/día",
      "COLUMN_AVERAGE_HOURS_HALF_DAY": "Promedio de horas/medio día",
      "COLUMN_AVERAGE_HOURS_WEEK": "Promedio de horas por semana",
      "COLUMN_PRE_AVERAGE_HOURS_PER_DAY": "Promedio de horas por día preparadas para Pre-Rota",
      "COLUMN_PRE_AVERAGE_HOURS_DATE": "Fecha promedio de horas preparadas para Pre-Rota",
      "COLUMN_PERMISSION_LEVEL_NAME": "Nivel de permiso",
      "COLUMN_ANONYMISED": "Anonimizado",
      "COLUMN_ANONYMISE_DATE": "Anonimizar fecha",
      "COLUMN_JOIN_DATE": "Fecha de incorporación",
      "COLUMN_LEAVE_DATE": "Fecha de salida",
      "COLUMN_LEAVE_DATE_EDITED_BY": "Fecha de salida establecida por",
      "COLUMN_LEAVE_DATE_EDITED_DATE": "Fecha de salida, fecha de establecimiento",
      "COLUMN_LENGTH_OF_SERVICE": "Duración del servicio (días)",
      "COLUMN_RECRUITMENT_SOURCE": "Fuente de contratación",
      "COLUMN_LEAVER_REASON": "Motivo de abandono",
      "COLUMN_LEAVER_REHIRE": "Elegible para volver a contratar",
      "COLUMN_EMPLOYEE_NUMBER": "Número de empleado",
      "COLUMN_NOTICE_PERIOD": "Periodo de preaviso",
      "COLUMN_STARTER_DECLARATION": "Declaración inicial (Reino Unido)",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED": "Subsidios finalizados",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY": "Subsidios finalizados por",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE": "Fecha de finalización de las asignaciones",
      "COLUMN_SITE_NAME": "Sitio",
      "COLUMN_SITE_ID": "ID del sitio",
      "COLUMN_DEPARTMENT_NAME": "Departamento",
      "COLUMN_DEPARTMENT_ID": "ID de departamento",
      "COLUMN_JOB_ROLE_NAME": "Función laboral",
      "COLUMN_COMPANY_NAME": "Empresa",
      "COLUMN_CONTRACT_BASIS": "Tipo de contrato",
      "COLUMN_LINE_MANAGER_NAME": "Gerente de línea",
      "COLUMN_STAFF_GROUP_NAME": "Grupo de personal",
      "COLUMN_WORKS_PUBLIC_HOLIDAYS": "Funciona en días festivos",
      "COLUMN_WORKING_TIME_OPT_OUT": "Exclusión voluntaria de la normativa sobre el tiempo",
      "COLUMN_MIN_OBLIGATION_VALUE": "Valor de la obligación contractual",
      "COLUMN_MIN_OBLIGATION_UNIT": "Unidad de obligación contractual",
      "COLUMN_MIN_OBLIGATION_PERIOD": "Período de obligación contractual",
      "COLUMN_MAX_OBLIGATION_VALUE": "Valor límite de trabajo",
      "COLUMN_MAX_OBLIGATION_UNIT": "Unidad de límite de trabajo",
      "COLUMN_MAX_OBLIGATION_PERIOD": "Período límite de trabajo",
      "COLUMN_DEPARTMENT_CURRENCY_CODE": "Código de divisa",
      "COLUMN_PAYROLL_CALENDAR_NAME": "Calendario de nóminas",
      "COLUMN_PAYROLL_CALENDAR_FREQUENCY": "Frecuencia del calendario de nómina",
      "COLUMN_PAY_AMOUNT": "Monto de pago",
      "COLUMN_PAY_AMOUNT_TYPE": "Tipo de importe de pago",
      "COLUMN_PAY_UPLIFT": "Aumento salarial",
      "ASSET_REGISTRY_TAB_LABEL": "Registro de activos"
    },
    "VIEW": {
      "ACCOUNT": {
        "ERROR_500": "No podemos procesar tu cambio ahora mismo. Vuelva a intentarlo.",
        "ERROR_403": "No tienes permiso para ver o modificar la información de la cuenta de esta persona.",
        "SAVE_SUCCESS": "Se han guardado los cambios.",
        "TITLE": "Cuenta",
        "LAST_SIGN_IN_LABEL": "Último inicio de sesión",
        "LAST_SIGN_IN_NEVER": "Nunca",
        "SECTION_PERMISSIONS_TITLE": "Permisos",
        "PERMISSION_LEVEL_LABEL": "Nivel de permiso",
        "PERMISSION_LEVEL_HELP": "Esto alterará lo que el empleado puede ver y hacer en Rotaready.",
        "SITE_ACCESS_LABEL": "Acceso al sitio",
        "SITE_ACCESS_OPTION_APPOINTMENT": "Su sitio actual",
        "SITE_ACCESS_OPTION_ALL": "Todos los sitios",
        "SITE_ACCESS_OPTION_CUSTOM": "Su sitio actual y sitios personalizados",
        "CLOCK_IN_PIN_LABEL": "Restablecer el PIN de registro",
        "CLOCK_IN_PIN_HELP": "Ingresa un nuevo código de 4 dígitos para cambiar el PIN de registro de este empleado.",
        "TAGS_LABEL": "Etiquetas",
        "TAGS_TIP": "Etiquete a los empleados con algo significativo, como habilidades o atributos.",
        "TAGS_PLACEHOLDER": "Escriba para buscar etiquetas",
        "SAVE_BUTTON_LOADING": "Ahorrando...",
        "SAVE_BUTTON_TEXT": "Guardar",
        "LANGUAGE_LABEL": "Idioma",
        "LANGUAGE_HELP": "Rotaready se mostrará en el idioma elegido.",
        "ENTITY_GROUP_SUBSCRIPTION_TOOLTIP": "Este empleado se asigna automáticamente a este sitio. Puede administrar su acceso en la página de configuración del Administrador de acceso al sitio."
      },
      "PAY": {
        "INDEX": {
          "TAB_HISTORY": "Registros salariales",
          "TAB_TRONC": "Tronc",
          "TAB_FINANCIALS": "Finanzas"
        },
        "VIEW_HISTORY": {
          "FILTER_TYPES_LABEL": "Tipo",
          "FILTER_TYPES_SALARY": "Salario",
          "FILTER_TYPES_POINT_IN_TIME": "Pagos únicos",
          "FILTER_ELEMENT_TYPES_LABEL": "Elemento de pago",
          "STATE_ACTIVE": "Activo",
          "STATE_HISTORICAL": "Histórico",
          "STATE_FUTURE_DATED": "Con fecha futura",
          "COLUMN_EFFECTIVE_DATE": "Fecha de entrada en vigor",
          "COLUMN_STATUS": "Estado",
          "COLUMN_ELEMENT_TYPE": "Elemento de pago",
          "COLUMN_CURRENCY": "Moneda",
          "COLUMN_AMOUNT": "Monto",
          "COLUMN_AMOUNT_TYPE": "Tipo de importe",
          "COLUMN_OPTIONS": "Opciones",
          "ERROR_500": "No podemos cargar los registros de pago en este momento. Por favor, inténtelo de nuevo.",
          "ERROR_403": "No tienes permiso para ver o modificar los registros salariales de esta persona.",
          "ALERT_SUCCESS": "Se ha guardado el registro de pagos.",
          "ALERT_DELETED": "Se ha eliminado el registro de pago.",
          "OUTSIDE_OF_APPOINTMENT_WARNING": "Este registro salarial no es válido porque no tienen un empleo activo en esta fecha. Cambie la fecha de entrada en vigor.",
          "DELETE_LINK": "Borrar",
          "LAST_EDITED_TOOLTIP": "Editado por última vez por {{username}} el {{date}}",
          "NO_RECORDS": "No hay registros de pago que mostrar.",
          "ADD_NEW_BUTTON": "Nuevo registro salarial",
          "CONFIRM_DELETE": "¿Está seguro de que desea eliminar este registro de pago? Esto no se puede deshacer."
        },
        "PAY_FORM": {
          "TITLE": "Pagar",
          "LABEL_EFFECTIVE_DATE": "Fecha de entrada en vigor",
          "LABEL_ELEMENT": "Elemento de pago",
          "LABEL_AMOUNT": "Monto",
          "LABEL_AMOUNT_TYPE": "Tipo de importe",
          "LABEL_UPLIFT": "Aumento salarial",
          "ALERT_NO_CURRENCY": "El empleado no está empleado activamente en esta fecha.",
          "ALERT_DUPLICATE_PAY": "Ya existe un registro de pago para el elemento de pago elegido en esa fecha.",
          "HELP_TEXT_UPLIFT": "Esto aumentará los salarios del empleado en Cost Control. Utilízalo para reflejar cualquier coste adicional en el que incurras, como una pensión. No te preocupes por los impuestos, ya que se incluyen automáticamente.",
          "BUTTON_SAVE": "Guardar",
          "BUTTON_SAVE_LOADING": "Ahorrando...",
          "BUTTON_DELETE": "Borrar",
          "BUTTON_DELETE_LOADING": "Eliminando..."
        },
        "VIEW_FINANCIALS": {
          "ERROR_500": "No podemos cargar la información financiera ahora mismo. Vuelva a intentarlo.",
          "ERROR_403": "No tienes permiso para ver o modificar la información financiera de esta persona.",
          "SAVE_SUCCESS": "Se han guardado los cambios.",
          "SECTION_BANK_ACCOUNT": "Cuenta bancaria",
          "SECTION_TAXES": "Impuestos",
          "ACCOUNT_NUMBER_LABEL": "Número de cuenta",
          "SORT_CODE_LABEL": "Código de clasificación",
          "ACCOUNT_NAME_LABEL": "Nombre de la cuenta",
          "PAYMENT_METHOD": "Método de pago",
          "NI_NUMBER_LABEL": "Número de seguro nacional",
          "SECTION_STUDENT_LOANS": "Préstamos estudiantiles (Reino Unido)",
          "PLAN_TYPE_LABEL": "Tipo de plan",
          "EFFECTIVE_DATE_LABEL": "Fecha de entrada en vigor",
          "LOAN_TABLE_PLAN_COLUMN": "Tipo de plan",
          "LOAN_TABLE_DATE_COLUMN": "Fecha de entrada en vigor",
          "LOAN_TABLE_OPTIONS_COLUMN": "Opciones",
          "LOAN_TABLE_NO_ROWS": "No se han añadido préstamos estudiantiles.",
          "LOAN_TABLE_DATE_NOT_SET": "No establecido",
          "SUBHEADING_ADD_STUDENT_LOAN": "Añadir préstamo estudiantil",
          "OPTION_DELETE": "Borrar",
          "ADD_LOAN_BUTTON": "Añadir préstamo estudiantil",
          "SAVE_BUTTON_TEXT": "Guardar"
        },
        "EDIT_STUDENT_LOAN": {
          "HEADING": "Editar préstamo estudiantil",
          "FORM_PLAN_TYPE_LABEL": "Tipo de plan",
          "FORM_EFFECTIVE_DATE_LABEL": "Fecha de vigencia",
          "SAVE_BUTTON_TEXT": "Guardar"
        },
        "VIEW_TRONC": {
          "COLUMN_EFFECTIVE_DATE": "Fecha de entrada en vigor",
          "COLUMN_STATUS": "Estado",
          "COLUMN_AMOUNT": "Monto",
          "COLUMN_APPORTION_TYPE": "Tipo de prorrateo",
          "COLUMN_LAST_EDITED": "Editado por última vez",
          "STATE_ACTIVE": "Activo",
          "STATE_HISTORICAL": "Histórico",
          "STATE_FUTURE_DATED": "Con fecha futura",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Tarifa por hora",
          "APPORTION_TYPE_CLASSIC_SALARY": "Salario anual",
          "APPORTION_TYPE_POINTS": "Puntos",
          "NO_RECORDS": "No hay tarifas de Tronc que mostrar.",
          "ADD_NEW_BUTTON": "Nueva tarifa Tronc",
          "ERROR_500": "No podemos cargar las tarifas de Tronc en este momento. Vuelva a intentarlo.",
          "ERROR_403": "No tienes permiso para ver las tarifas de Tronc de esta persona."
        },
        "ADD_TRONC_MODAL": {
          "TITLE": "Nueva tarifa Tronc",
          "BUTTON_SAVE": "Guardar",
          "BUTTON_CANCEL": "Cancelar",
          "LABEL_PERSON": "Persona",
          "LABEL_DATE": "Fecha de entrada en vigor",
          "LABEL_APPORTION_TYPE": "Tipo de prorrateo",
          "LABEL_AMOUNT": "Monto",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Tarifa por hora",
          "APPORTION_TYPE_CLASSIC_SALARY": "Salario anual",
          "APPORTION_TYPE_POINTS": "Puntos",
          "APPORTION_TYPE_HELP": "Tronc puede trabajar en uno de dos modos: basado en puntos o clásico, que utiliza una tarifa por hora o un salario. Esto se controla mediante una configuración para su organización o para el sitio/lugar específico.",
          "ERROR_OTHER": "No podemos ahorrar la tarifa de Tronc ahora mismo. Vuelva a intentarlo.",
          "ERROR_UNAUTHORIZED": "No tienes permiso para modificar las tarifas de Tronc de esta persona.",
          "ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE": "Este empleado no tiene una cita activa en la fecha de entrada en vigor que eligió.",
          "ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE": "Alguien ya ha empezado a redactar Tronc para la fecha de entrada en vigor que has elegido. Utilice la herramienta Tronc para editar las tarifas para esta fecha.",
          "ERROR_NOT_SUPPORTED": "No puedes elegir ese tipo de prorrateo. Tronc no está configurado para funcionar en ese modo."
        }
      },
      "INDEX": {
        "OPTIONS_LABEL": "Opciones",
        "OPTION_APPROVE_LINK": "Aprobar las rotaciones y la nómina",
        "OPTION_SEND_MAGIC_LINK": "Invita a Rotaready (envía un enlace mágico)",
        "OPTION_SEND_MAGIC_LINK_TOOLTIP": "Esta persona ya ha configurado su cuenta. Si tienen problemas para iniciar sesión, pídeles que sigan el enlace de recuperación de la cuenta en la página de inicio de sesión.",
        "OPTION_ANONYMISE": "Anonimizar",
        "OPTION_DELETE": "Borrar",
        "ALERT_APPROVE_SUCCESS": "Ahora están aprobados y listos para recibir turnos.",
        "ALERT_APPROVE_ERROR": "No pudimos aprobar la cuenta en ese momento. Vuelva a intentarlo.",
        "ALERT_MAGIC_LINK_SUCCESS_ALL": "Hemos enviado un correo electrónico y un mensaje de texto con un enlace mágico. Por favor, espere unos minutos para que llegue.",
        "ALERT_MAGIC_LINK_SUCCESS_EMAIL": "Hemos enviado un correo electrónico con un enlace mágico. Por favor, espere unos minutos para que llegue.",
        "ALERT_MAGIC_LINK_SUCCESS_SMS": "Hemos enviado un mensaje de texto con un enlace mágico. Por favor, espere unos minutos para que llegue.",
        "ALERT_MAGIC_LINK_ERROR_GENERIC": "No pudimos enviar un enlace mágico en ese momento.",
        "ALERT_MAGIC_LINK_ERROR_NO_DETAILS": "No podemos enviar un enlace mágico sin una dirección de correo electrónico o un número de teléfono.",
        "ALERT_ACCOUNT_ERROR_500": "No pudimos cargar al empleado en ese momento. Vuelva a intentarlo.",
        "ALERT_ACCOUNT_ERROR_404": "No hay ninguna cuenta de empleado asociada a ese identificador.",
        "DELETE_ACCOUNT_TITLE": "Eliminar empleado",
        "DELETE_ACCOUNT_MESSAGE": "¡Advertencia! La eliminación de un empleado es permanente. Solo hazlo si creaste al empleado por error. Para continuar, escribe su nombre completo a continuación.",
        "DELETE_ACCOUNT_BUTTON": "Eliminar permanentemente",
        "DELETE_ACCOUNT_ERROR": "No se pudo eliminar el empleado. Es posible que no tenga permiso para hacerlo.",
        "DELETE_ACCOUNT_SUCCESS": "Se ha eliminado al empleado."
      },
      "PERSONAL": {
        "ERROR_500": "No podemos procesar tu cambio ahora mismo. Vuelva a intentarlo.",
        "ERROR_403": "No tienes permiso para ver o modificar la información personal de esta persona.",
        "SAVE_SUCCESS": "Se han guardado los cambios.",
        "EMERGENCY_CONTACTS_LINK_TEXT": "Contactos de emergencia",
        "FINANCIALS_LINK_TEXT": "Finanzas e impuestos",
        "LABEL_OPTIONS": "Más opciones",
        "LABEL_APPROVED_BY": "Aprobado por",
        "TITLE_LABEL": "Título",
        "FIRST_NAME_LABEL": "Nombre",
        "LAST_NAME_LABEL": "Apellidos",
        "OTHER_NAME_LABEL": "Otros nombres (opcional)",
        "DOB_LABEL": "Fecha de nacimiento",
        "DOB_HELP": "{{age}} años",
        "PREFERRED_NAME_LABEL": "Nombre preferido",
        "SECTION_ADDRESS": "Dirección",
        "SECTION_VISAS": "Visados",
        "SECTION_IDENTITY": "Identidad",
        "SECTION_CONTACT": "Contactar",
        "SECTION_OTHER": "Otros",
        "SECTION_RIGHT_TO_WORK": "Derecho a trabajar",
        "NATIONALITY_LABEL": "Nacionalidad",
        "GENDER_LABEL": "Género",
        "ETHNICITY_LABEL": "Etnia",
        "MARITAL_STATUS_LABEL": "Estado civil",
        "PASSPORT_NUMBER_LABEL": "Número de pasaporte",
        "PASSPORT_EXPIRY_LABEL": "Fecha de caducidad del pasaporte",
        "SETTLED_STATUS_LABEL": "Estatus establecido (Reino Unido)",
        "PRIMARY_EMAIL_LABEL": "Correo electrónico principal",
        "PRIMARY_EMAIL_HELP": "El empleado lo usa para iniciar sesión en Rotaready. Solo se puede cambiar si no han iniciado sesión antes.",
        "PRIMARY_PHONE_LABEL": "Teléfono principal",
        "PRIMARY_PHONE_HELP": "El empleado lo usa para recibir notificaciones. El prefijo de código de país correcto se aplicará automáticamente después de guardar.",
        "SECONDARY_EMAIL_LABEL": "Correo electrónico secundario",
        "SECONDARY_PHONE_LABEL": "Teléfono secundario",
        "HR_ID_LABEL": "ID DE HORA",
        "HR_ID_HELP": "Si este empleado tiene un número de identificación utilizado para fines de recursos humanos, puede guardarlo aquí.",
        "PAYROLL_ID_LABEL": "ID de nómina",
        "PAYROLL_ID_HELP": "Si este empleado tiene un número de identificación utilizado para la nómina, puede guardarlo aquí.",
        "SAVE_BUTTON_LOADING": "Ahorrando...",
        "SAVE_BUTTON_TEXT": "Guardar",
        "LINK_ADD_ADDRESS": "Agregar nueva dirección",
        "LINK_VIEW_ADDRESS_HISTORY": "Ver direcciones anteriores",
        "NO_ADDRESS_HISTORY_TEXT": "No hay direcciones anteriores",
        "CURRENT_ADDRESS_LABEL": "Actual",
        "SHARE_CODE_LABEL": "Código de compartición",
        "SHARE_CODE_ERROR_LENGTH": "El código de compartición debe tener 9 caracteres de longitud.",
        "SHARE_CODE_ERROR_FORMAT": "El código de compartición debe contener solo caracteres alfanuméricos.",
        "SHARE_CODE_EXPIRY_DATE_LABEL": "Fecha de caducidad del código de compartición",
      },
      "ADDRESS_HISTORY": {
        "ALERT_ERROR_500": "No podemos procesar tu cambio ahora mismo. Vuelva a intentarlo.",
        "TITLE_ADD": "Agregar nueva dirección",
        "TITLE_EDIT": "Editar dirección",
        "ADVICE_EDIT": "Si la dirección del empleado cambia, debes agregar una nueva dirección en su lugar. Rotaready moverá automáticamente la dirección actual a su historial de direcciones.",
        "ADVICE_EDIT_LINK": "Agregar nueva dirección",
        "SAVE_BUTTON_TEXT": "Guardar",
        "DELETE_BUTTON_TEXT": "Borrar",
        "ADDRESS_LINE_1_LABEL": "Línea 1",
        "ADDRESS_LINE_2_LABEL": "Línea 2 (opcional)",
        "ADDRESS_CITY_LABEL": "Pueblo/ciudad",
        "ADDRESS_COUNTY_LABEL": "Condado",
        "ADDRESS_POST_CODE_LABEL": "Código postal",
        "ADDRESS_COUNTRY_LABEL": "País",
        "NOTES_LABEL": "Notas",
        "CONFIRM_DELETE": "¿Está seguro de que desea eliminar esta dirección?"
      },
      "VISAS": {
        "ADD_BTN": "Agregar visa",
        "CURRENT_HEADING": "Visa activa",
        "NO_CURRENT_VISA": "Este usuario no tiene una visa activa.",
        "CURRENT_LABEL": "Activa",
        "VISA_TYPE_LABEL": "Tipo de visa",
        "VISA_NUMBER_LABEL": "Número de visa",
        "VISA_EXPIRY_LABEL": "Fecha de vencimiento de la visa",
        "VISA_EXPIRY_NOT_APPLICABLE": "No aplica",
        "VISA_EDIT_BTN": "Editar visa",
        "PREVIOUS_LABEL": "Anterior",
        "SHOW_PREVIOUS_BTN": "Mostrar visas anteriores",
        "HIDE_PREVIOUS_BTN": "Ocultar visas anteriores",
        "PREVIOUS_HEADING": "Visa anterior",
        "MODAL": {
          "ADD_HEADING": "Agregar una nueva visa",
          "ALERT_ERROR_500": "No podemos procesar tu cambio en este momento. Por favor, inténtalo de nuevo.",
          "FORM_LABEL_VISA_TYPE": "Tipo de visa",
          "FORM_LABEL_VISA_NUMBER": "Número de visa",
          "FORM_LABEL_IS_CURRENT": "Esta es la visa activa del usuario",
          "FORM_LABEL_HAS_EXPIRY_DATE": "Esta visa tiene una fecha de vencimiento",
          "FORM_LABEL_VISA_EXPIRY": "Fecha de vencimiento de la visa",
          "FORM_BTN_SAVE": "Guardar",
          "EDIT_VISA_HEADING": "Editar visa",
          "DELETE_VISA_BTN": "Eliminar visa",
          "CONFIRM_DELETE": "¿Estás seguro de que deseas eliminar esta visa?"
        }
      },
      "SCHEDULE": {
        "TITLE": "El siguiente calendario muestra el calendario completo de {{firstName}}, incluidos los turnos, las ausencias y la disponibilidad.",
        "SHIFTS_LABEL": "Turnos",
        "ABSENCE_LABEL": "Ausencia",
        "UNAVAILABLE_LABEL": "No disponible (patrones)",
        "EDIT_LINK_TEXT": "Editar excepciones"
      },
      "ADD_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Rutina de cambio de mapa",
        "HAS_OVERLAP_TEXT": "Las fechas anteriores se superponen con una rutina de turnos existente.",
        "END_BEFORE_START_TEXT": "La fecha de inicio es anterior a la fecha de finalización.",
        "SAVE_BUTTON_TEXT": "Guardar",
        "SAVE_BUTTON_LOADING": "Ahorrando...",
        "CANCEL_BUTTON_TEXT": "Cancelar"
      },
      "DELETE_ROUTINE_MAPPING_MODAL": {
        "TITLE": "¿Estás seguro?",
        "WARNING": "Al eliminar este mapeo, se eliminarán todos los turnos futuros asociados a él.",
        "YES_BUTTON_TEXT": "Sí",
        "YES_BUTTON_ADDITIONAL_TEXT": "Eliminar este mapeo",
        "NO_BUTTON_TEXT": "No",
        "NO_BUTTON_ADDITIONAL_TEXT": "He cambiado de opinión",
        "CANCEL_BUTTON_TEXT": "Cancelar"
      },
      "EDIT_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Editar el mapeo de rutinas de turnos",
        "END_DATE_LABEL": "Fecha de finalización",
        "CONTINUE_FOREVER_LABEL": "Continúa con la rutina de turnos para siempre",
        "END_BEFORE_START_TEXT": "La fecha de finalización es anterior a la fecha de inicio.",
        "HAS_OVERLAP_NO_END": "Establecer esta fecha de finalización provocaría una superposición con una rutina de turnos existente.",
        "HAS_OVERLAP_END": "No establecer una fecha de finalización provocaría una superposición con una rutina de turnos existente.",
        "SAVE_BUTTON_TEXT": "Guardar",
        "SAVE_BUTTON_LOADING_TEXT": "Ahorrando...",
        "CANCEL_BUTTON_TEXT": "Cancelar"
      },
      "SHIFT_ROUTINES": {
        "MAP_ROUTINE_BUTTON_TEXT": "Mapa de la rutina",
        "MAP_ROUTINE_SUBTITLE": "Mapeos de rutina de turnos",
        "MAP_ROUTINE_TIP": "Asignar a alguien a una rutina de turnos hace que los turnos se creen automáticamente. Se eliminarán si eliminas el mapeo o si agregas una fecha de finalización.",
        "EDIT_END_DATE_TEXT": "Editar fecha de finalización",
        "ADD_END_DATE_TEXT": "Añadir fecha de finalización",
        "MAPPING_NO_END": "A partir de ",
        "MAPPING_END": " a ",
        "USER_HAS_NO_MAPPING": "{{firstName}} no está asignado a ninguna rutina de turno. Puedes añadir uno usando el botón de arriba."
      },
      "ABSENCE": {
        "INDEX": {
          "TAB_ABSENCE": "Reservas de ausencias",
          "TAB_ALLOWANCES": "Subsidios",
          "TAB_SETTINGS": "Ajustes"
        },
        "VIEW_SETTINGS": {
          "ABSENCE_HOURS_LABEL": "Promedio de horas por día",
          "AVERAGE_LENGTH_LABEL": "Calcula automáticamente en función de la duración media de su jornada laboral",
          "SET_MANUALLY_LABEL": "Configurar manualmente",
          "HOURS_FULL_DAY_LABEL": "Día completo",
          "HOURS_LABEL": "horas",
          "HOURS_HELP": "Una ausencia de un día completo valdrá esta cantidad de horas.",
          "HOURS_HALF_DAY_LABEL": "Medio día",
          "HOURS_HALF_DAY_HELP": "Medio día de ausencia valdrá esta cantidad de horas.",
          "DELEGATE_APPROVERS": "Delegar aprobadores",
          "DELEGATE_APPROVERS_REMOVE": "Eliminar",
          "DELEGATE_APPROVERS_HELP": "Si este empleado habilita la opción No molestar en la aplicación Rotaready y se presenta una solicitud de ausencia que requiere su aprobación, en su lugar se notificará a sus aprobadores delegados.",
          "SAVE_BUTTON_TEXT": "Guardar",
          "SAVE_BUTTON_LOADING_TEXT": "Ahorrando...",
          "PRE_ROTAREADY_AVERAGE_HOURS_LABEL": "Promedio de horas por día preparadas para Pre-Rota",
          "PRE_ROTAREADY_AVERAGE_HOURS_HELP": "Si hiciste la transición a Rotaready desde otro sistema durante el empleo de esta persona, introduce su último «promedio de horas por día» conocido y la fecha en que fue válido.",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_LABEL": "Válido por última vez el",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_HELP": "Al calcular un nuevo valor para el promedio de horas por día, si el empleado no ha trabajado durante las últimas 52 semanas en Rotaready, nuestro cálculo utilizará el valor anterior a RotaReady de las semanas anteriores a esta fecha."
        },
        "VIEW_BOOKINGS": {
          "INCLUDE_CANCELLED_TOGGLE_LABEL": "Incluir cancelado",
          "NO_BOOKINGS_MATCH_TEXT": "No hemos podido encontrar ninguna reserva que coincida con tus filtros.",
          "BOOK_ABSENCE_BUTTON_TEXT": "Ausencia de libros",
          "CANCEL_LINK": "Cancelar",
          "DATES_COLUMN_TEXT": "Fecha (s)",
          "ABSENCE_TYPE_COLUMN_TEXT": "Tipo",
          "HOURS_COLUMN_TEXT": "Horas",
          "DAYS_COLUMN_TEXT": "Días",
          "STATUS_COLUMN_TEXT": "Estado",
          "SUBMITTED_COLUMN_TEXT": "Enviado",
          "APPROVED_COLUMN_TEXT": "Revisado",
          "CANCELLED_COLUMN_TEXT": "Cancelado",
          "FILTER_LABEL_APPROVAL_STATE": "Estado",
          "FILTER_LABEL_ABSENCE_TYPE": "Tipo",
          "FILTER_LABEL_DATE": "Fecha",
          "CANCEL_REQUEST": {
            "CONFIRM": "¿Estás seguro de que deseas cancelar esta reserva de ausencia? Esto no se puede deshacer.",
            "SUCCESS": "Se canceló la reserva de ausencia.",
            "ERROR_500": "No podíamos cancelarlo ahora mismo. Por favor, inténtelo de nuevo."
          }
        },
        "VIEW_ALLOWANCES": {
          "ERROR_500": "Vaya, nos topamos con un problema justo en ese momento. Vuelva a intentarlo.",
          "BUTTON_ADD": "Nueva asignación",
          "BUTTON_OPTIONS": "Opciones",
          "BUTTON_TRANSACTIONS": "Ver transacciones",
          "BUTTON_EDIT": "Editar",
          "BUTTON_DELETE": "Borrar",
          "NO_ALLOWANCES_TEXT": "No hay asignaciones que coincidan con tus filtros.",
          "FILTER_LABEL_STATUS": "Estado",
          "FILTER_LABEL_ABSENCE_TYPE": "Tipo",
          "CHRONO_TYPE_ACTIVE": "Activo",
          "CHRONO_TYPE_FUTURE": "Futuro",
          "CHRONO_TYPE_HISTORICAL": "Histórico",
          "LABEL_NOT_DEDUCTIBLE": "No es deducible",
          "METRIC_ACCRUED": "Derecho",
          "METRIC_BOOKED": "Reservado",
          "METRIC_REMAINING": "Restante",
          "METRIC_ACCRUED_LIMIT": "de {{count}}",
          "METRIC_ACCRUED_LIMIT_HELP": "Este es el máximo que se puede acumular.",
          "METRIC_ELAPSED": "transcurrido",
          "METRIC_ELAPSED_HELP": "Este es el número de días/horas reservadas en el pasado. Esto se vuelve a calcular automáticamente de la noche a la mañana.",
          "METRIC_OVERSPEND": "gastar de más",
          "METRIC_OVERSPEND_HELP": "Este es el número de días/horas que se pueden reservar por encima del importe acumulado.",
          "METRIC_ACCRUAL_ESTIMATE": "acumular",
          "METRIC_ACCRUAL_ESTIMATE_HELP": "En función de su promedio de horas de trabajo, calculamos que lo acumulará al final del año de asignación.",
          "SCHEDULE_BEGINNING_OF_ACCRUAL_YEAR": "Se acumula al principio de cada año de acumulación",
          "SCHEDULE_EACH_CALENDAR_MONTH": "Se acumula cada mes calendario",
          "SCHEDULE_EACH_HOUR_WORKED": "Se acumula por cada hora trabajada",
          "SCHEDULE_EACH_HOUR_WORKED_RATE": "Se acumula por cada hora trabajada al {{rate}}%",
          "CARRIED_OVER": "{{amount}} arrastrado del año pasado",
          "LINK_TRANSACTIONS": "Ver transacciones",
          "ALERT_CONFIRM_DELETE": "¿Estás seguro de que deseas eliminar esta asignación? Esto no se puede deshacer.",
          "ALERT_SUCCESS_DELETE": "Se ha eliminado la asignación.",
          "FINALISED": "🔒 Finalizado ({{date}})",
          "FINALISE": "Finalizar",
          "CONVERT_HOURS_TO_DAYS_TOGGLE_LABEL": "Convertir horas en días"
        },
        "ALLOWANCE_VALIDATION_MODAL": {
          "TITLE": "Algo no está del todo bien",
          "DESCRIPTION": "Lamentablemente, lo que intentas hacer provoca uno o más problemas.",
          "BUTTON_CLOSE": "Cerrar",
          "INVALID_ALLOWANCE_TITLE": "Subsidio no válido",
          "INVALID_ALLOWANCE_DESCRIPTION": "La asignación se ha eliminado desde entonces y ya no se puede editar.",
          "OVERLAPPING_ALLOWANCE_TITLE": "Subsidios superpuestos",
          "OVERLAPPING_ALLOWANCE_DESCRIPTION": "Esta asignación se superpondrá con una asignación existente. No puedes tener asignaciones superpuestas para el mismo tipo de ausencia.",
          "FINALISED_ALLOWANCE_TITLE": "Subsidio finalizado",
          "FINALISED_ALLOWANCE_DESCRIPTION": "Esta asignación se ha finalizado. Para editarla o eliminarla, primero debes revertir la asignación.",
          "INVALID_UNIT_TITLE": "Unidad no válida",
          "INVALID_UNIT_DESCRIPTION": "Una asignación con el cronograma de acumulación establecido en Cada hora trabajada debe tener la unidad configurada en Horas.",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_TITLE": "Fecha de entrada en vigor no válida",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "La fecha de entrada en vigor elegida debe estar entre el inicio y el final del año de asignación.",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_TITLE": "Fecha de referencia no válida",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "La fecha de referencia debe estar entre la fecha de entrada en vigor de la asignación y el final del año de asignación."
        },
        "ALLOWANCE_FORM": {
          "ERROR_500": "Vaya, nos topamos con un problema justo en ese momento. Vuelva a intentarlo.",
          "ALERT_SUCCESS_ADD": "Se ha añadido la asignación.",
          "ALERT_SUCCESS_UPDATE": "La asignación se ha actualizado.",
          "ALERT_ERROR_CALCULATE_BOOKED": "No hemos podido calcular el valor de su reserva en este momento. Por favor, inténtelo de nuevo.",
          "BUTTON_SAVE": "Guardar",
          "BUTTON_SAVE_LOADING": "Ahorrando...",
          "TITLE": "Subsidio",
          "LABEL_YEAR_STARTS": "Empieza el año",
          "YEAR_STARTS_HELP": "El año festivo predeterminado de tu organización comienza el {{settingDate}}.",
          "YEAR_STARTS_CUSTOMISE_INITIATE": "Elige otro día o mes.",
          "YEAR_STARTS_CUSTOMISE_CANCEL": "Vuelve al día y mes predeterminados.",
          "LABEL_EFFECTIVE_DATE": "Fecha de entrada en vigor",
          "EFFECTIVE_DATE_HELP": "Si esta asignación reemplaza a una asignación existente en el mismo año, debe elegir una fecha en la que entre en vigor.",
          "ALERT_MISALIGNED_YEAR_START": "Esto no coincide con el año festivo predeterminado de su organización.",
          "ALERT_MISALIGNED_EFFECTIVE_DATE": "Su fecha de entrada en vigor no está dentro del año de asignación.",
          "ALERT_ALTERED_EFFECTIVE_DATE": "Su fecha de entrada en vigor no coincide con la fecha de inicio del año de asignación. Solo debes hacerlo si vas a reemplazar una asignación existente en el mismo año.",
          "LABEL_ABSENCE_TYPE": "Tipo de ausencia",
          "LABEL_SCHEDULE_OF_ACCRUAL": "Calendario de acumulación",
          "TOOLTIP_SCHEDULE_OF_ACCRUAL": "Para asignar horas o días manualmente, elige «inicio del año de acumulación». Para que Rotaready asigne horas o días a cada mes calendario, seleccione «cada mes calendario». Para acumular automáticamente en función de las horas trabajadas, selecciona «cada hora trabajada».",
          "LABEL_UNIT": "Unidad",
          "LABEL_DEDUCTIBLE": "Deducible",
          "TOOLTIP_DEDUCTIBLE": "Controle si el empleado puede presentar solicitudes de ausencia que se deducen de esta asignación.",
          "DEDUCTIBLE_YES": "Sí",
          "DEDUCTIBLE_NO": "No",
          "LABEL_PROJECT": "Predecir lo acumulado durante la reserva",
          "TOOLTIP_PROJECT": "Al presentar una solicitud de ausencia, predice las horas que es probable que se hayan acumulado e inclúyelas en la cifra acumulada del empleado.",
          "PROJECT_YES": "Sí",
          "PROJECT_NO": "No",
          "LABEL_TOTAL_ACCRUED_ANNUALLY": "Total acumulado anualmente",
          "LABEL_TOTAL_ACCRUED": "Total acumulado",
          "TOOLTIP_TOTAL_ACCRUED": "Ingresa la cantidad de horas o días que este empleado ha acumulado hasta el momento. Rotaready actualizará esto automáticamente en el futuro.",
          "LABEL_BOOKED": "Reservado",
          "TOOLTIP_BOOKED": "Introduzca el número de horas o días que este empleado ha reservado. Rotaready lo mantendrá automáticamente.",
          "ALERT_BOOKED": "Hemos calculado esto para ti en función de la ausencia reservada en Rotaready. Cambie esto únicamente si la ausencia se ha reservado fuera de Rotaready.",
          "TOOLTIP_BOOKED_BUTTON": "Calcular automáticamente",
          "LABEL_TOTAL_TO_ACCRUE": "Máximo a acumular",
          "TOOLTIP_TOTAL_TO_ACCRUE_HOURLY": "Introduzca el número total de horas o días que este empleado tiene derecho a acumular. Deje el espacio en blanco para no imponer un límite.",
          "TOOLTIP_TOTAL_TO_ACCRUE_MONTHLY": "Introduzca el número total de horas o días que este empleado debe acumular durante el año.",
          "LABEL_ACCRUAL_RATE": "Anulación de la tasa de acumulación",
          "TOOLTIP_ACCRUAL_RATE": "Si lo desea, especifique una tasa de acumulación si desea anular la tasa predeterminada de la jurisdicción en la que trabaja el empleado.",
          "LABEL_OVERSPEND_LIMIT": "Gastar de más",
          "TOOLTIP_OVERSPEND_LIMIT": "Si lo desea, permita al empleado reservar más horas o días de los que ha acumulado.",
          "LABEL_CARRIED_OVER": "Se transfirió del año pasado",
          "BUTTON_PRO_RATA": "Prorrateo",
          "LABEL_EMPLOYMENT_START": "Fecha de inicio del empleo",
          "LABEL_PRO_RATA_TOTAL": "Total acumulado si está empleado todo el año",
          "HELP_TEXT_PRO_RATA": "Fijaremos el total acumulado en {{value}}.",
          "CREATE_NEXT_YEAR_CHECKBOX": "Cree otra asignación para el próximo año utilizando el total acumulado si estuvo empleado todo el año.",
          "ALERT_SUCCESS_CALCULATE_TOTAL_ACCRUED": "Hemos calculado esto para ti utilizando las horas de trabajo de este empleado.",
          "ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED": "No tienes permiso para acceder a las horas de trabajo firmadas de este empleado. Sin esto, no podemos calcular el total acumulado para ti.",
          "ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED": "No hemos podido calcular automáticamente el total de horas acumuladas. Por favor, inténtelo de nuevo.",
          "TOOLTIP_CALCULATE_TOTAL_ACCRUED": "Calcular automáticamente"
        },
        "ALLOWANCE_TRANSACTIONS": {
          "TITLE": "Transacciones",
          "DESCRIPTION": "Estas son todas las transacciones que afectan a la asignación, mostrando primero la más reciente:",
          "TYPE_ACCRUAL_HOURS_WORKED": "Acumulación por horas trabajadas",
          "TYPE_ABSENCE_REQUESTED": "Ausencia solicitada",
          "TYPE_ABSENCE_REJECTED_OR_CANCELLED": "Ausencia rechazada o cancelada",
          "TYPE_MANUAL_ADJUSTMENT": "Ajuste manual",
          "TYPE_ACCRUAL_SCHEDULE_CHANGED": "Se modificó el calendario de acumulación",
          "TYPE_CARRY_OVER": "Prorrogado del año anterior",
          "TYPE_ACCRUAL_TIME_BASED": "Acumulación basada en el tiempo",
          "TYPE_ABSENCE_UPDATED": "Se han modificado las fechas de ausencia",
          "TYPE_HOURS_PER_DAY_RECALCULATION": "Horas por día actualizadas",
          "ERROR_500": "Vaya, nos topamos con un problema justo en ese momento. Vuelva a intentarlo.",
          "NO_TRANSACTIONS": "No hay transacciones que mostrar.",
          "BUTTON_CLOSE": "Cerrar",
          "TRANSACTION_TYPE": "Filtrar por tipo"
        },
        "VIEW_FINALISATION": {
          "TITLE": "Subsidio finalizado",
          "BUTTON_CLOSE": "Cerrar",
          "BUTTON_REVERT": "Revertir",
          "CONFIRM_REVERT": "¿Está seguro de que desea anular esta asignación? Esto también eliminará el registro de pago correspondiente, si lo hay.",
          "LABEL_REFERENCE_DATE": "Fecha de referencia",
          "LABEL_PAY_RECORD": "Pago/deducción",
          "PAY_SEE_MORE": "Ver registro de pagos",
          "PAY_NONE": "No se le debe ningún pago al empleado, ni se debe deducir ningún pago.",
          "LABEL_NOTES": "Notas",
          "NOTES_NONE": "No se grabó ninguna nota.",
          "ERROR_500": "No pudimos guardar tus cambios en ese momento. Por favor, inténtelo de nuevo.",
          "ERROR_403": "No tienes permiso para modificar los registros salariales de esta persona, por lo que no podemos anular la asignación.",
          "ALERT_SUCCESS": "La asignación se ha revertido."
        },
        "FINALISE_ALLOWANCE_FORM": {
          "TITLE": "Finalizar asignación",
          "BUTTON_SAVE": "Finalizar",
          "ERROR_500": "No pudimos guardar tus cambios en ese momento. Por favor, inténtelo de nuevo.",
          "ERROR_403": "No tienes permiso para modificar los registros salariales de esta persona, por lo que no podemos finalizar la asignación.",
          "ALERT_SUCCESS": "La asignación se ha finalizado.",
          "GUIDANCE": "Te recomendamos que finalices la asignación cuando estés seguro de que no se acumularán, reservarán ni cancelarán más ausencias. Por lo general, esto es lo más parecido posible al último día de una persona.",
          "GUIDANCE_FUTURE_YEAR": "Solo tiene que hacerlo si el empleado se va en este año de asignación.",
          "LABEL_EFFECTIVE_DATE": "Fecha de referencia",
          "EFFECTIVE_DATE_LEAVER_HELP": "La asignación finalizará en esta fecha. Como esta persona se va, lo hemos fijado para su último día.",
          "EFFECTIVE_DATE_NOT_LEAVER_HELP": "La asignación finalizará en esta fecha. La fecha que elija se utilizará a continuación para calcular el total de horas o días acumulados.",
          "LABEL_BASIC_PAY": "Pago básico",
          "ALERT_BASIC_PAY_NOT_PERMITTED": "No tienes permiso para ver los registros salariales de este empleado, por lo que no puedes usar esta función para crear un pago único o una deducción.",
          "ALERT_BASIC_PAY_NOT_AVAILABLE": "El empleado no tiene un registro salarial básico en la fecha de referencia elegida.",
          "HELP_TEXT_BASIC_PAY": "Este es el salario básico del empleado en la fecha de referencia elegida.",
          "LABEL_TOTAL_ACCRUED_LIVE": "Total acumulado",
          "HELP_TEXT_TOTAL_ACCRUED_LIVE": "Este es el total de horas acumuladas para esta asignación, en función de todos los días que se han registrado.",
          "LABEL_ACCRUED_PRO_RATED": "Acumulado (prorrateado)",
          "HELP_TEXT_TOTAL_ACCRUED_PRO_RATED": "Utilizando la fecha de referencia elegida, se ha prorrateado el total de horas/días acumulados.",
          "LABEL_BOOKED": "Total reservado",
          "HELP_TEXT_BOOKED": "Esta es la ausencia total que se ha reservado hasta la fecha, incluidas las solicitudes pendientes y aprobadas.",
          "LABEL_ACCRUED_UNTAKEN": "Acumulado pero no utilizado",
          "STATUS_PAYMENT_DUE": "Esto significa que el empleado se ha ausentado menos de lo que ha acumulado. Muchos empleadores optan por pagar al empleado por esto.",
          "STATUS_DEDUCTION_DUE": "Esto significa que el empleado se ha ausentado más de lo que ha acumulado. Algunos empleadores optan por deducir esto del último recibo de pago del empleado.",
          "LABEL_AVERAGE_HOURS": "Promedio de horas por día",
          "LABEL_ONE_OFF_PAYMENT": "Deducción o pago único",
          "HELP_TEXT_ONE_OFF_PAYMENT": "Si ingresas una cantidad, Rotaready creará un registro de pago único y lo vinculará a esta asignación final. Para una deducción, introduce un importe negativo.",
          "HELP_TEXT_ONE_OFF_PAYMENT_LINK": "Ayuda sobre cómo calcularlo",
          "LABEL_PAY_ELEMENT": "Elemento de pago",
          "LABEL_PAYMENT_DATE": "Fecha aplicable",
          "HELP_TEXT_PAYMENT_DATE": "Elige con cuidado para asegurarte de que este pago se incluya en la última nómina del empleado.",
          "LABEL_NOTES": "Notas",
          "PLACEHOLDER_NOTES": "Si no ingresas ninguna nota, incluiremos automáticamente las cifras del cálculo anterior."
        },
        "FINALISE_ALLOWANCE_PROMPT": {
          "TITLE": "¿Están finalizadas todas las asignaciones?",
          "DESCRIPTION": "Si está satisfecho de que todas las asignaciones ya estén finalizadas, debe confirmarlo para que los departamentos, como el de nómina, puedan confiar en las cifras.",
          "DESCRIPTION_PART_2": "También puede hacerlo en la pestaña Empleo en una fecha posterior.",
          "BUTTON_ATTEST": "Confirmar",
          "BUTTON_LATER": "Tengo más que hacer",
          "ALERT_ERROR": "No pudimos guardar tu confirmación en ese momento. En su lugar, inténtelo en la pestaña Empleo."
        }
      },
      "EMERGENCY_CONTACTS": {
        "ADD_MODAL": {
          "TITLE": "Nuevo contacto de emergencia",
          "RELATIONSHIP_LABEL": "Relación con el empleado",
          "FIRST_NAME_LABEL": "Nombre",
          "LAST_NAME_LABEL": "Apellidos",
          "OTHER_NAME_LABEL": "Otros nombres",
          "PRIMARY_CONTACT_NUMBER_LABEL": "Número de contacto principal",
          "SECONDARY_CONTACT_NUMBER_LABEL": "Número de contacto secundario",
          "ADDRESS_LABEL": "Dirección",
          "SAVE_BUTTON_TEXT": "Guardar",
          "CLOSE_BUTTON_TEXT": "Cerrar",
          "ALERT_ERROR_500": "No pudimos guardar tus cambios en ese momento. Por favor, inténtelo de nuevo."
        },
        "INDEX": {
          "TITLE": "Contactos de emergencia",
          "RELATIONSHIP_COLUMN_TEXT": "Relación",
          "NAME_COLUMN_TEXT": "Nombre",
          "PHONE_COLUMN_TEXT": "Número (s) de teléfono",
          "ADDRESS_COLUMN_TEXT": "Dirección",
          "NONE_TO_SHOW": "No hay contactos de emergencia que mostrar.",
          "ADD_BUTTON_TEXT": "Nuevo contacto",
          "OPTIONS_COLUMN_TEXT": "Opciones",
          "DELETE_LINK": "Borrar",
          "ALERT_ERROR_500": "No pudimos cargar los contactos de emergencia en ese momento. Por favor, inténtelo de nuevo.",
          "CONFIRM_DELETE": "¿Estás seguro de que deseas eliminar este contacto de emergencia?\nEsto no se puede deshacer."
        }
      },
      "EMPLOYMENT": {
        "LABEL_LENGTH_OF_SERVICE": "Duración del servicio",
        "LABEL_STARTS_IN": "Empieza en",
        "LABEL_OPTIONS": "Opciones",
        "OPTION_NEW_EMPLOYMENT": "Nuevo empleo",
        "OPTION_END_EMPLOYMENT": "Terminar con el empleo",
        "OPTION_REINSTATE_EMPLOYMENT": "Reinstaurar el empleo",
        "OPTION_DELETE_EMPLOYMENT": "Eliminar empleo",
        "TEXT_TERMINATED": "Este empleo ha terminado y finaliza el",
        "LABEL_REASON": "Motivo",
        "LABEL_REHIRE": "Volver a contratar",
        "LABEL_REHIRE_YES": "Sí",
        "LABEL_REHIRE_NO": "No",
        "BUTTON_ATTEST": "Confirmar",
        "TEXT_ALLOWANCES_NOT_FINALISED": "Aún no ha confirmado que se hayan finalizado las asignaciones por ausencia del empleado.",
        "TEXT_ALLOWANCES_NOT_FINALISED_PART_2": "Por lo general, esto se hace el último día del empleado o antes, antes de su último pago.",
        "TEXT_ALLOWANCES_NOT_FINALISED_LINK": "Ver las asignaciones",
        "BUTTON_UNDO": "Deshacer",
        "TEXT_ALLOWANCES_FINALISED": "Se ha confirmado que los subsidios por ausencia están finalizados para este empleo.",
        "LABEL_JOIN_DATE": "Fecha de incorporación",
        "LABEL_NOTICE_PERIOD": "Periodo de preaviso",
        "BUTTON_SET_NOTICE_PERIOD": "Establece un período de preaviso...",
        "LABEL_EMPLOYEE_NUMBER": "Número de empleado",
        "TOOLTIP_EMPLOYEE_NUMBER": "Opcional. En el Reino Unido, HMRC exige un número único para cada empleado. Se lo enviaremos a su proveedor de nóminas si la integración de nóminas está habilitada.",
        "LABEL_RECRUITMENT_SOURCE": "Fuente de contratación",
        "LABEL_STARTER_DECLARATION": "Declaración inicial (Reino Unido)",
        "BUTTON_SAVE": "Guardar",
        "BUTTON_SAVE_LOADING": "Ahorrando...",
        "BUTTON_NEW_APPOINTMENT": "Nueva cita",
        "TITLE_APPOINTMENTS": "Nombramientos",
        "COLUMN_EFFECTIVE_DATE": "Fecha de entrada en vigor",
        "COLUMN_SITE": "Sitio",
        "COLUMN_DEPARTMENT": "Departamento",
        "COLUMN_POSITION": "Función laboral",
        "COLUMN_LINE_MANAGER": "Gerente de línea",
        "COLUMN_STAFF_GROUP": "Grupo de personal",
        "COLUMN_OBLIGATION": "Obligación",
        "TOOLTIP_ACTIVE_APPOINTMENT": "Esta es la cita activa.",
        "LINE_MANAGER_NOT_SET": "No establecido",
        "MIN_OBLIGATION_ZERO_HOURS": "Cero horas",
        "ERROR_500": "No podemos cargar empleos ahora mismo. Vuelva a intentarlo.",
        "ERROR_403": "No tiene permiso para ver o modificar los registros de empleo de esta persona.",
        "LABEL_HOURS": "horas",
        "LABEL_DAYS": "días",
        "LABEL_WEEK": "semana",
        "LABEL_MONTH": "mes",
        "LABEL_CURRENT_EMPLOYMENT": "Empleo actual",
        "ALERT_CHANGES_SAVED": "Se han guardado los cambios.",
        "CONFIRM_DELETE": "¿Estás seguro de que quieres hacer esto? La eliminación de un empleo es irreversible.",
        "CONFIRM_REINSTATE": "¿Estás seguro de que quieres hacer esto?\n\nSi alguien se reincorpora a su organización, debe crear un nuevo empleo en su lugar. Solo debe restablecer un empleo si se terminó injustamente.",
        "VALIDATION_MODAL": {
          "TITLE": "No podemos hacer ese cambio",
          "DESCRIPTION": "Lamentablemente, lo que intentas hacer provoca uno o más problemas.",
          "NO_EMPLOYMENT_TITLE": "Sin empleo",
          "NO_EMPLOYMENT_DESCRIPTION": "Su cambio dejaría al empleado sin empleo; siempre debe haber al menos uno.",
          "EMPLOYMENT_OVERLAPPING_TITLE": "Solapamiento de empleos",
          "EMPLOYMENT_OVERLAPPING_DESCRIPTION": "Su cambio provocaría que dos empleos se superpusieran:",
          "EMPLOYMENT_NOT_TERMINATED_TITLE": "Empleo no terminado",
          "EMPLOYMENT_NOT_TERMINATED_DESCRIPTION": "Debe terminar el empleo anterior antes de agregar uno nuevo.",
          "EMPLOYMENT_NO_APPOINTMENTS_TITLE": "Empleo sin citas",
          "EMPLOYMENT_NO_APPOINTMENTS_DESCRIPTION": "Un empleo no puede estar vacío, debe contener al menos una cita.",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_TITLE": "Nombramiento fuera del empleo",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Su cambio haría que parte de esta cita quedara fuera del empleo.",
          "APPOINTMENT_OVERLAPPING_TITLE": "Citas superpuestas",
          "APPOINTMENT_OVERLAPPING_TITLE_DESCRIPTION": "Su cambio provocaría que dos citas se superpusieran:",
          "APPOINTMENT_NO_EMPLOYMENT_TITLE": "Cita sin empleo",
          "APPOINTMENT_NO_EMPLOYMENT_DESCRIPTION": "Una cita no está asociada a un empleo. ¡Esto no debería suceder normalmente!",
          "SHIFTS_OUTSIDE_EMPLOYMENT_TITLE": "Turnos fuera del empleo",
          "SHIFTS_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Su cambio dejaría {{count}} turno (s) en fechas anteriores al inicio del empleo o después de su finalización. Para continuar, estos turnos se pueden eliminar, desasignar (lo que los mueve a Turnos abiertos) o modificar en la propia rotación:",
          "SHIFT_ENTITY_MISMATCH_TITLE": "Turnos en diferentes sitios/departamentos",
          "SHIFT_ENTITY_MISMATCH_DESCRIPTION": "Su cambio dejaría {{count}} turnos originados en una rotación departamental que no coincide con la del departamento de la cita del empleado. Para continuar, estos turnos se pueden eliminar, desasignar (lo que los mueve a Turnos abiertos) o modificar en la propia rotación:",
          "ABSENCE_OUTSIDE_EMPLOYMENT_TITLE": "Ausencia fuera del empleo",
          "ABSENCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Su cambio dejaría {{count}} reservaciones de ausencia en fechas anteriores al inicio del empleo o después de su finalización. Para continuar, deben cancelarse:",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_TITLE": "Asistencia fuera del empleo",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Su cambio dejaría {{count}} a los eventos de asistencia en fechas anteriores al inicio del empleo o después de su finalización. Para continuar, se deben eliminar:",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_TITLE": "Mapeo de la rutina de turnos fuera del empleo",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Su cambio dejaría {{count}} mapeos de rutina de turnos en fechas anteriores al inicio del empleo o después de su finalización. Para continuar, deben cancelarse o eliminarse:",
          "EMAIL_EXISTS_TITLE": "La dirección de correo electrónico ya existe",
          "EMAIL_EXISTS_DESCRIPTION": "La dirección de correo electrónico que has introducido ya está siendo utilizada por otro empleado. Si esta persona ha trabajado anteriormente para su organización, búsquela y añada un nuevo empleo.",
          "MOBILE_EXISTS_TITLE": "El número de teléfono móvil ya existe",
          "MOBILE_EXISTS_DESCRIPTION": "El número de teléfono móvil que ha introducido ya lo está utilizando otro empleado. Si esta persona ha trabajado anteriormente para su organización, búsquela y añada un nuevo empleo.",
          "INVALID_DATE_TITLE": "Fecha no válida",
          "INVALID_DATE_DESCRIPTION": "Si está intentando terminar un empleo, la fecha de finalización que ha elegido es antes de que comience el empleo.",
          "PERIOD_SIGNED_OFF_TITLE": "Fecha de firma",
          "PERIOD_SIGNED_OFF_DESCRIPTION": "El cambio se superpone con una o más fechas que se han firmado.",
          "PAY_ELEMENT_INVALID_TITLE": "Elemento de pago no válido",
          "PAY_ELEMENT_INVALID_DESCRIPTION": "El elemento de pago elegido no es válido.",
          "PAY_RECORD_SAME_DATE_TITLE": "Registro de pago duplicado",
          "PAY_RECORD_SAME_DATE_DESCRIPTION": "Ya existe un registro de pago para el elemento de pago elegido en esa fecha.",
          "RECORD_MISSING_TITLE": "Registro de pago eliminado",
          "RECORD_MISSING_DESCRIPTION": "Este registro de pago se ha eliminado y ya no se puede modificar.",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_TITLE": "Discordancia del calendario de nómina",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_DESCRIPTION": "Su cambio dejaría {{count}} reservas de ausencia asociadas a un calendario de nómina diferente. Para continuar, deben cancelarse:",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_TITLE": "Vinculado a la asignación finalizada",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_DESCRIPTION": "Este registro salarial está vinculado a una asignación finalizada y no se puede editar. Para eliminarlo, busca la asignación y reviértela.",
          "BUTTON_TRY_AGAIN": "Inténtalo de nuevo",
          "BUTTON_CLOSE": "Cerrar"
        },
        "LEAVER_FORM": {
          "TITLE": "Terminar con el empleo",
          "ALERT_SUCCESS": "El empleo ha terminado.",
          "ERROR_500": "No pudimos guardar tus cambios en ese momento. Por favor, inténtelo de nuevo.",
          "LABEL_END_DATE": "Último día de empleo",
          "LABEL_REASON": "Motivo de la salida",
          "LABEL_REHIRE": "Volver a contratar",
          "CHECKBOX_REHIRE": "Se considera que este empleado reúne los requisitos para ser recontratado en el futuro.",
          "LABEL_NOTES": "Notas",
          "PLACEHOLDER_NOTES": "Si lo desea, registre algunas notas asociadas con la salida de este empleado.",
          "BUTTON_SAVE": "Guardar",
          "BUTTON_SAVE_LOADING": "Ahorrando..."
        },
        "EMPLOYMENT_FORM": {
          "TITLE": "Nuevo empleo",
          "RESET_WARNING": "La cuenta del empleado se marcará como no aprobada y se restablecerán varios atributos, como el promedio de horas trabajadas por día y por semana.",
          "LABEL_START_DATE": "Fecha de inicio del empleo",
          "LABEL_NOTICE_PERIOD": "Periodo de preaviso",
          "LABEL_EMPLOYEE_NUMBER": "Número de empleado",
          "TOOLTIP_EMPLOYEE_NUMBER": "Opcional. En el Reino Unido, HMRC exige un número único para cada empleado. Se lo enviaremos a su proveedor de nóminas si la integración de nóminas está habilitada.",
          "LABEL_RECRUITMENT_SOURCE": "Fuente de contratación",
          "LABEL_STARTER_DECLARATION": "Declaración inicial (Reino Unido)",
          "LABEL_INVITE": "Invitar a Rotaready",
          "CHECKBOX_INVITE": "Envía un enlace mágico a este empleado, que lo invita a establecer su contraseña e iniciar sesión para ver sus turnos.",
          "BUTTON_ADD": "Añadir",
          "BUTTON_ADD_LOADING": "Agregando...",
          "ALERT_SUCCESS": "Se ha creado el empleo.",
          "ERROR_500": "No pudimos guardar tus cambios en ese momento. Por favor, inténtelo de nuevo."
        },
        "APPOINTMENT_FORM": {
          "TITLE": "Cita",
          "ALERT_MAPPINGS": "Complete las asignaciones de transmisión predeterminadas o compruebe la declaración.",
          "ALERT_SAVED": "La cita se ha guardado.",
          "ALERT_DELETED": "Se ha eliminado la cita.",
          "ERROR_500": "No pudimos guardar tus cambios en ese momento. Por favor, inténtelo de nuevo.",
          "INFO_NEW_APPOINTMENT": "Hemos utilizado la cita más reciente para rellenar previamente este formulario.",
          "LABEL_EFFECTIVE_DATE": "Fecha de entrada en vigor",
          "HELP_TEXT_EFFECTIVE_DATE": "Como esta es la primera cita, la fecha de entrada en vigor está vinculada a la fecha de incorporación al empleo.",
          "INFO_NEW_EMPLOYEE": "Para terminar, configure la nueva cita de este empleado configurando su sitio/departamento, su puesto de trabajo y otros atributos clave.",
          "LABEL_LINE_MANAGER": "Gerente de línea",
          "LABEL_LINE_MANAGER_NOT_SET": "No establecido",
          "TITLE_JOB_ROLE": "Función laboral",
          "LABEL_SITE_DEPARTMENT": "Sitio/departamento",
          "LABEL_JOB_ROLE": "Función laboral",
          "LABEL_STAFF_GROUP": "Grupo de personal",
          "HELP_TEXT_STAFF_GROUP": "Los grupos de personal se utilizan para separar visualmente a las personas en la rotación y en otras herramientas.",
          "TITLE_EMPLOYMENT_CONTRACT": "Contrato de trabajo",
          "LABEL_COMPANY": "Empresa",
          "LABEL_PAYROLL_CALENDAR": "Calendario de nóminas",
          "LABEL_CONTRACT_TYPE": "Tipo de contrato",
          "LABEL_PUBLIC_HOLIDAYS": "Días festivos",
          "HELP_TEXT_PUBLIC_HOLIDAYS": "Este empleado suele trabajar los días festivos.",
          "LABEL_WORKING_TIME": "Reglamentación del tiempo de trabajo",
          "HELP_TEXT_WORKING_TIME": "Este empleado ha optado por no participar en los límites de trabajo opcionales de las regulaciones sobre el tiempo de trabajo.",
          "TITLE_CONTRACTUAL_OBLIGATION": "Obligación contractual",
          "LABEL_MIN_OBLIGATION": "Horas/días garantizados",
          "HELP_TEXT_MIN_OBLIGATION": "A este empleado se le garantiza una cantidad mínima de trabajo.",
          "LABEL_MAX_OBLIGATION": "Límite de trabajo",
          "HELP_TEXT_MAX_OBLIGATION": "Establezca un límite máximo en la cantidad de horas o días en los que se puede programar el trabajo de este empleado.",
          "TITLE_STREAM_MAPPINGS": "Mapeos de transmisión predeterminados",
          "HELP_TEXT_STREAM_MAPPINGS": "Cuando este empleado trabaja en un turno, las asignaciones del tipo de turno determinan los flujos a los que asignar su costo salarial. Si el tipo de turno no tiene ninguno, se utilizan en su lugar:",
          "COLUMN_STREAM": "Transmitir",
          "COLUMN_ATTRIBUTION": "Atribución",
          "COLUMN_OPTIONS": "Opciones",
          "OPTION_DELETE": "Borrar",
          "NO_MAPPINGS": "No hay ninguno para mostrar.",
          "LABEL_ADD_MAPPING": "Agregar un nuevo mapeo",
          "OPTION_ADD": "Añadir",
          "LABEL_NO_MAPPINGS": "Sin mapeos",
          "CHECKBOX_NO_MAPPINGS": "Acepto que, si no hay mapeos de flujo predeterminados, es posible que haya imprecisiones en el control de costos y en algunos informes.",
          "HELP_TEXT_NO_MAPPINGS": "Esto está bien si no vas a hacer un seguimiento del costo salarial de este empleado.",
          "BUTTON_SAVE": "Guardar",
          "BUTTON_SAVE_LOADING": "Ahorrando...",
          "BUTTON_DELETE": "Borrar",
          "BUTTON_DELETE_LOADING": "Eliminando..."
        }
      },
      "DOCUMENTS": {
        "FILTER_LABEL_CATEGORY": "Categoría",
        "BUTTON_UPLOAD": "Cargar archivo",
        "BUTTON_SIGNATURES": "Firmas de documentos",
        "OPTION_SEND_TO_SIGN": "Enviar un documento para firmar",
        "OPTION_PENDING_SIGNATURES": "Ver documentos pendientes de firma",
        "ERROR_500": "No podemos cargar documentos en este momento. Por favor, inténtelo de nuevo.",
        "ERROR_403": "No tienes permiso para ver o modificar los documentos de esta persona.",
        "ERROR_NO_CATEGORIES": "Los documentos no están habilitados para tu organización. Chatea con nosotros para empezar.",
        "TOOLTIP_CATEGORY_NOT_APPLICABLE": "Categoría marcada como no obligatoria",
        "TOOLTIP_CATEGORY_INVALID": "Falta el documento o está caducado",
        "INFO_CATEGORY_NOT_APPLICABLE": "Esta categoría se ha marcado como no obligatoria para este empleado.",
        "LINK_MARK_CATEGORY_REQUIRED": "Marcar como necesario",
        "LINK_MARK_CATEGORY_NOT_REQUIRED": "Marcar como no obligatorio",
        "INFO_CATEGORY_NO_DOCUMENTS": "No hay documentos en esta categoría.",
        "COLUMN_NAME": "Nombre",
        "COLUMN_EXPIRES": "Caduca",
        "COLUMN_FILE_SIZE": "Tamaño del archivo",
        "COLUMN_UPLOADED_BY": "Subido por",
        "COLUMN_OPTIONS": "Opciones",
        "LINK_DELETE": "Borrar",
        "LINK_EDIT": "Editar",
        "ALERT_CONFIRM_DELETE": "¿Está seguro de que desea eliminar este documento? ¡Esto no se puede deshacer!",
        "TOOLTIP_SIGNATURE_ELECTRONIC": "Este documento se firmó electrónicamente a través de Rotaready",
        "TOOLTIP_SIGNATURE_MANUAL": "Este documento se firmó manualmente",
        "TOOLTIP_NOTES": "Las notas están disponibles",
        "PILL_SENSITIVE": "Sensible",
        "PILL_CAN_EXPIRE": "Puede caducar",
        "PILL_VISIBLE_TO_EMPLOYEE": "Visible para el empleado",
        "TOOLTIP_ACKNOWLEDGED": "Reconocido: {{date}}",
        "PERSONAL": {
          "TITLE": "Mis documentos",
          "ALERT_CONFIRM_ACKNOWLEDGE": "Al hacer clic en confirmar, confirmas que has leído y entendido el documento.",
          "INFO_NO_DOCUMENTS": "No tiene documentos que ver.",
          "LINK_ACKNOWLEDGE": "Reconocer",
          "PILL_NEEDS_ACKNOWLEDGEMENT": "Necesita reconocimiento",
          "PILL_ACKNOWLEDGED": "Reconocido",
          "COLUMN_NAME": "Nombre",
          "COLUMN_CATEGORY": "Categoría",
          "COLUMN_EXPIRES": "Caduca",
          "COLUMN_FILE_SIZE": "Tamaño del archivo",
          "COLUMN_OPTIONS": "Opciones",
          "FILTER_LABEL_CATEGORY": "Categoría"
        }
      },
      "DOCUMENT_EDIT_FORM": {
        "TITLE": "Editar documento",
        "BUTTON_CANCEL": "Cancelar",
        "BUTTON_SAVE": "Guardar",
        "LABEL_EXPIRY_DATE": "Fecha de caducidad",
        "LABEL_NOTES": "Notas",
        "HELP_TEXT_EXPIRY": "El documento debe tener una fecha de caducidad si la categoría lo requiere. Recibirás una alerta para que tomes medidas a medida que se acerque la fecha y, si ésta caduca, se te avisará nuevamente para que tomes medidas.",
        "ALERT_SUCCESS": "El documento se ha actualizado.",
        "ALERT_ERROR_500": "Se ha producido un error al actualizar el documento. Vuelve a intentarlo o ponte en contacto con nosotros si el mensaje persiste.",
        "ALERT_ERROR_400": "Hay algo que no está del todo bien en tus cambios."
      },
      "DOCUMENT_UPLOAD_MODAL": {
        "TITLE": "Cargar un documento",
        "ALERT_SIGNATURE": "Marcaremos este documento como firmado manualmente.",
        "LABEL_UPLOAD_TO": "Cargar a",
        "LABEL_CATEGORY": "Categoría",
        "LABEL_EXPIRY_DATE": "Fecha de caducidad",
        "HELP_TEXT_EXPIRY": "El documento debe tener una fecha de caducidad si la categoría lo requiere. Recibirás una alerta para que tomes medidas a medida que se acerque la fecha y, si ésta caduca, se te avisará nuevamente para que tomes medidas.",
        "LABEL_UPLOAD": "Cargar",
        "LABEL_NOTES": "Notas",
        "BUTTON_CANCEL": "Cancelar",
        "BUTTON_SAVE": "Guardar",
        "ALERT_SUCCESS": "Se ha subido tu documento.",
        "ALERT_ERROR_500": "Se ha producido un error al subir el documento. Vuelve a intentarlo o ponte en contacto con nosotros si el mensaje persiste.",
        "ALERT_ERROR_400": "Hay algo que no está del todo bien en tu categoría y fecha de caducidad."
      },
      "DOCUMENT_SIGNATURE_MODAL": {
        "ALERT_PICK_CATEGORY": "Seleccione una categoría en la que guardar el documento.",
        "ALERT_SUCCESS_MANUAL": "Se ha descargado el documento.",
        "ALERT_SUCCESS_ELECTRONIC": "Su documento se ha enviado para su firma electrónica.",
        "ALERT_ERROR_500": "Se ha producido un error al crear el documento. Vuelve a intentarlo o ponte en contacto con nosotros si el mensaje persiste.",
        "ALERT_ERROR_400": "No hemos podido crear tu documento. Comprueba las etiquetas Merge e inténtalo de nuevo.",
        "TITLE": "Enviar un documento para firmar",
        "ALERT_INFO": "Al colocar las etiquetas Merge en tu plantilla o archivo, Rotaready puede insertar datos clave sobre el empleado al que se lo estás enviando, como su nombre o puesto. También es la forma de controlar dónde aparece el cuadro de firma.",
        "ALERT_INFO_LINK": "Descubra cómo",
        "LABEL_SEND_TO": "Enviar a",
        "LABEL_DOCUMENT": "Documento",
        "LABEL_DOCUMENT_TEMPLATE": "Usar una plantilla existente",
        "LABEL_DOCUMENT_UPLOAD": "Sube un archivo único",
        "LABEL_TEMPLATE": "plantilla",
        "ALERT_NO_TEMPLATES_TITLE": "No tienes ninguna plantilla.",
        "ALERT_NO_TEMPLATES_HELP": "Ahorra tiempo, sube un archivo una vez y reutilízalo para varias personas.",
        "ALERT_NO_TEMPLATES_BUTTON": "Subir una plantilla",
        "LABEL_UPLOAD": "Cargar",
        "SECTION_SIGNING_INFO": "Información de firma",
        "LABEL_TITLE": "Título",
        "HELP_TEXT_TITLE": "Dale un título a este documento para que la persona a la que se lo envíes sepa de qué se trata.",
        "LABEL_EMPLOYER_SIGNS": "Señales del empleador",
        "OPTION_EMPLOYER_SIGN_FALSE": "No, solo el empleado",
        "OPTION_EMPLOYER_SIGN_TRUE": "Se requiere una firma en nombre del empleador",
        "LABEL_EMPLOYER_SIGNATORY": "Firmante del empleador",
        "HELP_TEXT_EMPLOYER_SIGNATORY": "Seleccione quién debe firmar este documento en nombre del empleador.",
        "LABEL_SIGNATURE_METHOD": "Método de firma",
        "OPTION_SIGNATURE_METHOD_MANUAL": "Manual, con bolígrafo y papel",
        "OPTION_SIGNATURE_METHOD_ELECTRONIC": "Electrónica, con firma electrónica totalmente legal",
        "LABEL_EMAIL_MESSAGE": "Mensaje de correo electrónico",
        "HELP_TEXT_EMAIL_MESSAGE": "Agregue un mensaje opcional a la notificación por correo electrónico que se envía a todas las partes firmantes.",
        "SECTION_UPON_COMPLETION": "Al finalizar",
        "SECTION_METHOD": "Método de firma",
        "LABEL_SAVE_TO": "Guardar en",
        "HELP_TEXT_SAVE_TO": "Cuando se firme, el archivo se guardará en los documentos del empleado en la categoría elegida.",
        "LABEL_EXPIRY_DATE": "Fecha de caducidad",
        "HELP_TEXT_EXPIRY_DATE": "El documento debe tener una fecha de caducidad si la categoría de destino lo requiere. Recibirás una alerta para que tomes medidas a medida que se acerque la fecha y, si ésta caduca, se te avisará nuevamente para que tomes medidas.",
        "CREDITS_DEPLETED_TEXT": "Necesita créditos para enviar este documento para su firma electrónica.",
        "CREDITS_DEPLETED_CTA": "Chatea con nosotros ahora",
        "CREDITS_AVAILABLE_TEXT": "Te quedan <b>{{balance}}</b> créditos. Para enviar este documento se utilizará un crédito.",
        "CREDITS_AVAILABLE_CTA": "Recargar tus créditos es fácil. Simplemente envíanos un mensaje en el chat en vivo.",
        "BUTTON_CANCEL": "Cancelar",
        "BUTTON_DOWNLOAD_PREVIEW": "Descargar vista previa",
        "BUTTON_SAVE_DOWNLOAD": "Guardar y descargar",
        "BUTTON_SEND": "Enviar"
      },
      "NOTES": {
        "ERROR_500": "No podemos cargar notas ahora mismo. Por favor, inténtelo de nuevo.",
        "ALERT_CONFIRM_DELETE": "¿Está seguro de que desea eliminar esta nota?",
        "BODY_PLACEHOLDER": "Escribe una nota nueva...",
        "SAVE_BUTTON_TEXT": "Añadir nota",
        "VISIBILITY_SELF": "Privada",
        "VISIBILITY_SELF_HELP": "Solo visible para ti",
        "VISIBILITY_SENSITIVE": "Sensible",
        "VISIBILITY_SENSITIVE_HELP": "Solo las pueden ver las personas con permiso para ver notas sensibles",
        "VISIBILITY_GENERAL": "General",
        "VISIBILITY_GENERAL_HELP": "Solo las pueden ver las personas con permiso para ver las notas de este empleado",
        "EDITED_LABEL": "Editado",
        "EDIT_LABEL": "Editar",
        "DELETE_LABEL": "Borrar",
        "NO_NOTES": "No hay notas que mostrar."
      },
      "DANGER_ZONE": {
        "ANONYMISE": {
          "TITLE": "Anonimizar empleado",
          "ERROR_500": "No podíamos ahorrar en ese momento. Vuelva a intentarlo.",
          "ERROR_403": "No tienes permiso para acceder a los datos personales de este empleado. Esto significa que no puedes programar su perfil para que sea anónimo.",
          "LABEL_END_DATE": "Fecha de finalización del empleo",
          "HELP_TEXT": "Para ayudar a cumplir con las leyes de protección de datos, Rotaready puede eliminar toda la información personal de este perfil de empleado después de un período de tiempo elegido. En el Reino Unido, HMRC exige que los registros se conserven durante un mínimo de seis años.",
          "HELP_TEXT_LEARN": "Obtenga más información",
          "LABEL_ANONYMISE": "Anonimizar",
          "CHECKBOX_ANONYMISE": "Anonimizar a este empleado",
          "LABEL_ANONYMISE_DATE": "Anonimizar en",
          "ALERT_ANONYMISE_DATE": "Para anonimizar a este empleado, elige una fecha que sea futura y posterior al último día de trabajo del empleado.",
          "BUTTON_SAVE": "Guardar"
        }
      }
    },
    "ABSENCE": {
      "ABSENCE": {
        "TITLE": "Calendario de ausencias",
        "FILTER_LABEL_APPROVAL_STATE": "Estado",
        "FILTER_LABEL_POSITION": "Función laboral",
        "FILTER_LABEL_STAFF_GROUP": "Grupo de personal",
        "ERROR_500": "No pudimos cargar la ausencia en ese momento. Vuelva a intentarlo.",
        "FILTER_LABEL_ABSENCE_TYPE": "Tipo",
        "FILTER_LABEL_REQUESTER": "Solicitante"
      }
    },
    "REQUESTS": {
      "ABSENCE": {
        "DATES_COLUMN_TEXT": "Fecha (s)",
        "WHO_COLUMN_TEXT": "Quién",
        "ABSENCE_TYPE_COLUMN_TEXT": "Tipo",
        "SUBMITTED_DATE_TEXT": "Enviado",
        "HOURS_COLUMN_TEXT": "Horas",
        "DAYS_COLUMN_TEXT": "Días",
        "NO_STAFF_MATCH_TEXT": "No hemos podido encontrar ninguna solicitud que coincida con tus filtros.",
        "FILTER_LABEL_POSITION": "Función laboral",
        "FILTER_LABEL_STAFF_GROUP": "Grupo de personal",
        "ERROR_500": "No pudimos cargar la ausencia en ese momento. Vuelva a intentarlo.",
        "FILTER_LABEL_ABSENCE_TYPE": "Tipo",
        "FILTER_LABEL_REQUESTER": "Solicitante"
      },
      "CONFIRM_SWAP_APPROVAL_MODAL": {
        "TITLE": "¿Estás seguro?",
        "VALIDATION_MANUAL_TEXT": "Para deshacer este cambio de turno, tendrá que editar manualmente la rotación.",
        "YES_TITLE": "Sí",
        "APPROVE_BUTTON_TEXT": "Apruebe este intercambio ahora y notifique a ambos miembros del personal",
        "NO_TITLE": "No",
        "DO_NOTHING_BUTTON_TEXT": "No hagas nada, no apruebes este intercambio todavía",
        "CANCEL_BUTTON_TEXT": "Cancelar"
      },
      "INDEX": {
        "HEADING": "Artículos que requieren atención",
        "ABSENCE_LABEL": "Solicitudes de ausencia",
        "SWAPS_LABEL": "Intercambios de turno",
        "DOCUMENTS_EXPIRING_LABEL": "Documentos que caducan",
        "ACCOUNT_APPROVAL_LABEL": "Nuevos miembros"
      },
      "SWAPS": {
        "INITIATED_BY_TEXT": "Iniciado por",
        "INITIATED_TOOLTIP": "El miembro del personal que quiere regalar su turno",
        "SHIFT_COLUMN_TEXT": "Su turno",
        "SWAPPING_WITH_COLUMN_TEXT": "Intercambiar con",
        "SWAPPING_TOOLTIP": "El miembro del personal que quiere trabajar en el turno",
        "RETURN_SHIFT_COLUMN_TEXT": "Su turno",
        "OPTION_COLUMN_TEXT": "Opciones",
        "ACCEPTED_DATE_COLUMN_TEXT": "Esperando desde",
        "NONE_TEXT": "Ninguna",
        "NO_STAFF_MATCH_TEXT": "No hemos podido encontrar ninguna solicitud que coincida con tus filtros.",
        "FILTER_LABEL_PARTICIPANT": "Participante"
      },
      "DOCUMENTS": {
        "NAME_COLUMN_LABEL": "Nombre",
        "FILE_NAME_COLUMN_LABEL": "Documento",
        "FILE_SIZE_COLUMN_LABEL": "Tamaño",
        "CATEGORY_COLUMN_LABEL": "Categoría",
        "EXPIRY_DATE_COLUMN_LABEL": "Caduca",
        "OPTIONS_COLUMN_LABEL": "Opciones",
        "EXPIRED": "Expirado",
        "UPLOAD_NEW_LINK": "Cargar nuevo",
        "NO_DOCS_MATCH_TEXT": "No hemos podido encontrar ningún documento que coincida con tus filtros.",
        "NO_CATEGORIES_TEXT": "No tienes ninguna categoría para los documentos que puedan caducar.",
        "FILTER_LABEL_SITE": "Sitio/departamento",
        "FILTER_LABEL_APPOINTMENT": "Cita",
        "FILTER_LABEL_PERSON": "Persona",
        "FILTER_LABEL_CATEGORY": "Categoría",
        "FILTER_LABEL_EXPIRY": "Caducidad",
        "APPOINTMENT_STATUS_ACTIVE": "Activo",
        "APPOINTMENT_STATUS_INACTIVE": "Inactivo",
        "X_WEEKS_OR_LESS": " semanas o menos"
      },
      "NEW_JOINERS": {
        "NAME_COLUMN_LABEL": "Nombre",
        "POSITION_COLUMN_LABEL": "Función laboral",
        "ENTITY_COLUMN_LABEL": "Sitio",
        "START_DATE_COLUMN_LABEL": "Empieza el",
        "OPTIONS_COLUMN_LABEL": "Opciones",
        "NO_STAFF_MATCH_TEXT": "No hemos podido encontrar a nadie que coincida con tus filtros.",
        "APPROVE_LINK": "Aprobar",
        "FILTER_LABEL_APPOINTMENT": "Cita",
        "FILTER_LABEL_POSITION": "Función laboral",
        "FILTER_LABEL_DATE_START": "Fecha de inicio",
        "APPOINTMENT_STATUS_ACTIVE": "Activo",
        "APPOINTMENT_STATUS_INACTIVE": "Inactivo"
      }
    },
    "TAGS_GROUPS": {
      "TAGS_GROUPS": {
        "HEADING": "Etiquetas y grupos",
        "ASIDE_TEXT": "Un miembro del personal solo puede pertenecer a un grupo, pero se le pueden asignar muchas etiquetas. Los grupos son similares al departamento o equipo de una persona, mientras que las etiquetas pueden representar cualquier cosa que consideres significativa, como habilidades o cualidades personales.",
        "CREATE_NEW_TAG_PLACEHOLDER": "Crear nueva etiqueta...",
        "ADDING_BUTTON_TEXT": "Agregando...",
        "ADD_BUTTON_TEXT": "Añadir",
        "TAG_HELP": "Haz clic en una etiqueta para editarla y ver a quién se le ha asignado:",
        "CREATE_ONE_TEXT": "En este momento no tienes ninguna etiqueta. ¿Por qué no crear uno?",
        "CREATE_NEW_GROUP_PLACEHOLDER": "Crear nuevo grupo...",
        "GROUPS_TITLE": "Grupos",
        "EDIT_LINK_TEXT": "editar"
      },
      "VIEW_GROUP_MODAL": {
        "EDIT_GROUP_TITLE": "Editar grupo",
        "SITE_LABEL": "Sitio",
        "EDIT_TAB_HEADING": "Editar",
        "ORG_WIDE_LABEL": "En toda la organización",
        "GROUP_NAME_LABEL": "Nombre del grupo",
        "AVAILABILITY_LABEL": "Disponibilidad",
        "ALWAYS_AVAILABLE_TEXT": " Siempre disponible",
        "ALWAYS_AVAILABLE_HELP": "El personal de este grupo se considera siempre disponible para trabajar, excepto cuando sus patrones de disponibilidad o ausencia indiquen lo contrario.",
        "ALWAYS_UNAVAILABLE_TEXT": " Siempre no disponible",
        "ALWAYS_UNAVAILABLE_HELP": "El personal de este grupo no está disponible de forma predeterminada. Usarán las «excepciones» y la «disponibilidad de una semana» para marcar cuándo son gratuitas.",
        "MEMBERS_TAB_HEADING": "Miembros",
        "NO_ACTIVE_MEMBERS_HELP": "No hay miembros activos en este grupo. <br /><br />El personal se asigna a un grupo a través de su cita. Busca a alguien en la lista de personal, haz clic en la pestaña Citas y edita una cita existente o agrega una nueva.",
        "DELETE_TAB_HEADING": "Borrar",
        "MOVE_MEMBERS_TO_LABEL": "Mover miembros a",
        "DELETE_TAB_ALERT": "Al eliminar este grupo, {{totalMembers}} personas se mudarán a otro grupo. Esto es irreversible y no se puede deshacer.",
        "CONFIRM_NAME_LABEL": "Escriba el nombre del grupo",
        "CONFIRM_NAME_HELP_TEXT": "Para convencernos de que quieres hacer esto, escribe el nombre del grupo en el cuadro.",
        "DELETING_BUTTON_TEXT": "Eliminando...",
        "DELETE_BUTTON_TEXT": "Borrar",
        "SAVING_BUTTON_TEXT": "Ahorrando...",
        "SAVE_BUTTON_TEXT": "Guardar",
        "CANCEL_BUTTON_TEXT": "Cancelar"
      },
      "VIEW_TAG_MODAL": {
        "EDIT_TAG_TITLE": "Editar etiqueta",
        "EDIT_CLONE_LABEL": "Editar/clonar",
        "EDIT_EXISTING_TAG_TEXT": " Editar etiqueta existente",
        "CLONE_TAG_TEXT": " Clona la etiqueta y guárdala como nueva",
        "TAG_NAME_LABEL": "Nombre de etiqueta",
        "STAFF_MEMBERS_LABEL": "Miembros del personal",
        "TAG_TYPEAHEAD_PLACEHLDER": "Escriba para etiquetar al personal",
        "LAST_EDITED_LABEL": "Editado por última vez",
        "ON_TEXT": "on {{date|momentFormat: 'llll'}}",
        "DELETING_BUTTON_TEXT": "Eliminando...",
        "DELETE_BUTTON_TEXT": "Borrar",
        "SAVING_BUTTON_TEXT": "Ahorrando...",
        "SAVE_BUTTON_TEXT": "Guardar",
        "CLONING_BUTTON_TEXT": "Clonación...",
        "CLONE_BUTTON_TEXT": "Clon",
        "CANCEL_BUTTON_TEXT": "Cancelar",
        "CLOSE_BUTTON_TEXT": "Cerrar"
      }
    },
    "ADD_NEW": {
      "TITLE": "Agregar un empleado",
      "LABEL_FIRST_NAME": "Nombre",
      "LABEL_LAST_NAME": "Apellidos",
      "LABEL_DOB": "Fecha de nacimiento",
      "LABEL_EMAIL": "Dirección de correo electrónico",
      "HELP_TEXT_EMAIL_OIDC": "Esto debe coincidir exactamente con lo que el empleado usa para su servicio de inicio de sesión único. Por lo general, termina con el nombre de dominio de su empresa.",
      "HELP_TEXT_EMAIL_ROTAREADY": "Todos tienen una cuenta en Rotaready y usan su dirección de correo electrónico para iniciar sesión.",
      "LABEL_COPY_EXISTING": "Copiar un empleado existente",
      "PLACEHOLDER_COPY_EXISTING_NO_THANKS": "No gracias",
      "HELP_TEXT_COPY_EXISTING": "Copie la cita, el subsidio de vacaciones y las etiquetas de otro empleado.",
      "LABEL_DATE_START": "Fecha de inicio del empleo",
      "LABEL_SITE": "Sitio/departamento",
      "LABEL_PERMISSIONS": "Nivel de permiso",
      "LABEL_INVITE": "Invitar a Rotaready",
      "HELP_TEXT_INVITE_OIDC": "Envía un enlace mágico a este empleado para invitarlo a iniciar sesión por primera vez.",
      "HELP_TEXT_INVITE_ROTAREADY": "Envía un enlace mágico a este empleado, que lo invita a establecer su contraseña e iniciar sesión para ver sus turnos.",
      "LABEL_ONBOARDING": "Incorporación",
      "CHECKBOX_ONBOARDING_OPT_IN": "Exija a este empleado que complete la incorporación automática cuando inicie sesión por primera vez en Rotaready. Según la configuración de su organización, se les pedirá que proporcionen información personal, como su dirección, sus contactos de emergencia y la documentación sobre el derecho al trabajo.",
      "BUTTON_ADD": "Añadir",
      "BUTTON_ADD_LOADING": "Agregando...",
      "ALERT_SUCCESS": "¡Éxito! Termine de configurar a su empleado completando cada pestaña.",
      "ALERT_500": "No pudimos añadir al empleado en ese momento. Por favor, inténtelo de nuevo.",
      "ALERT_403": "No tienes permiso para añadir un nuevo empleado.",
      "ALERT_403_PERMISSION_LEVEL": "No tienes permiso para agregar a alguien con este nivel de permisos.",
      "ALERT_403_COPY_EXISTING": "No tienes permiso para copiar a este empleado.",
      "ALERT_403_ENTITY": "No tiene permiso para agregar a alguien a este sitio/departamento.",
      "DUPLICATE_MODAL": {
        "TITLE": "Empleados actuales",
        "DESCRIPTION": "La persona a la que intentas agregar comparte algunos atributos con uno o más empleados existentes. Si esta persona ha trabajado anteriormente para su organización, añada un nuevo empleo.",
        "COLUMN_NAME": "Nombre",
        "COLUMN_EMPLOYMENT": "Empleo",
        "COLUMN_SITE": "Sitio/departamento",
        "COLUMN_SIMILARITIES": "Semejanzas",
        "COLUMN_OPTIONS": "Opciones",
        "STATUS_ACTIVE": "Activo",
        "STATUS_INACTIVE": "Inactivo",
        "OPTION_VIEW": "Ver empleado",
        "OPTION_NEW_EMPLOYMENT": "Nuevo empleo",
        "BUTTON_PROCEED": "Proceda de todos modos",
        "BUTTON_BACK": "Regresar",
        "SIMILARITY_NAME": "Nombre",
        "SIMILARITY_EMAIL": "Correo electrónico",
        "SIMILARITY_MOBILE": "Número de teléfono móvil",
        "SIMILARITY_DOB": "Fecha de nacimiento"
      }
    }
  },
  "DIRECTIVES": {
    "STAFF": {
      "SEARCH": {
        "PLACEHOLDER": "Buscar...",
        "AND_X_MORE": "Y {{x}} más...",
        "INACTIVE_APPOINTMENT": "Inactivo"
      },
      "ADD_ROUTINE_MAPPING_FORM": {
        "STARTS_LABEL": "Empieza",
        "STARTS_TEXT": "El primer día de {{firstName}} en esta rutina",
        "ENDS_INPUT_TEXT": " Termina",
        "ENDS_TEXT": "El último día de {{firstName}} en esta rutina",
        "ONGOING_TEXT": "En curso",
        "SHIFT_ROUTINE_LABEL": "Rutina de turnos",
        "OFFSET_LABEL": "Compensación",
        "OFFSET_HELP": "Este es el número de días que {{firstName}} debe comenzar en este patrón."
      },
      "AVAILABILITY_RULE": {
        "IM_UNAVAILABLE_TEXT": "No estaré disponible",
        "CANCEL_TEXT": "cancelar",
        "AVAILABLE_TEXT": "DISPONIBLE",
        "UNAVAILABLE_TEXT": "NO DISPONIBLE",
        "AVAILABLE_FROM_TEXT": "Solo estoy disponible desde ",
        "UNAVAILABLE_FROM_TEXT": "No estoy disponible desde ",
        "TO_TEXT": " a ",
        "ALL_DAY": "Todo el día",
        "AM_ONLY": "Solo AM",
        "PM_ONLY": "Solo PM",
        "AVAIALABLE_CUSTOM": "Disponible (personalizado)",
        "UNAVAILABLE_CUSTOM": "No disponible (personalizado)",
        "UNAVAILABLE": "No disponible",
        "AVAILABLE": "Disponible",
        "AVAILABLE_AM": "Disponible (solo AM)",
        "AVAILABLE_PM": "Disponible (solo PM)"
      },
      "AUDIT_USER_WIDGET": {
        "EDITED_BY": "Editado por"
      },
      "VALIDATION_BULK_ABSENCE_EDIT": {
        "INFO_TRUNCATED": "Debido al volumen de reservas de ausencias afectadas, no se pueden editar de forma masiva aquí. Utilice la pestaña Ausencias del perfil del empleado o póngase en contacto con Rotaready para obtener más ayuda.",
        "INFO_SUCCESS": "No quedan reservas de ausencias conflictivas.",
        "ERROR_500": "En ese momento nos encontramos con un error inesperado. Vuelva a intentarlo.",
        "ERROR_400": "Ese cambio no se puede hacer porque coincide con una fecha que se ha firmado.",
        "COLUMN_DATES": "Fecha (s)",
        "COLUMN_TYPE": "Tipo",
        "COLUMN_HOURS": "Horas",
        "COLUMN_DAYS": "Días",
        "COLUMN_STATUS": "Estado",
        "COLUMN_OPTIONS": "Opciones",
        "OPTION_CANCEL": "Cancelar"
      },
      "VALIDATION_BULK_ATTENDANCE_EDIT": {
        "INFO_TRUNCATED": "Debido al volumen de asistencia a los eventos afectados, no se pueden editar de forma masiva aquí. Utilice la pestaña Asistencia del perfil del empleado o póngase en contacto con Rotaready para obtener más ayuda.",
        "INFO_SUCCESS": "No quedan eventos de asistencia conflictivos.",
        "ERROR_500": "En ese momento nos encontramos con un error inesperado. Vuelva a intentarlo.",
        "ERROR_400": "Ese cambio no se puede hacer porque coincide con una fecha que se ha firmado.",
        "COLUMN_EVENT": "Evento",
        "COLUMN_DATE": "Fecha",
        "COLUMN_OPTIONS": "Opciones",
        "EVENT_TYPE_CLOCK_IN": "Relojes",
        "OPTION_DELETE": "Borrar"
      },
      "VALIDATION_BULK_ROUTINE_MAPPING_EDIT": {
        "INFO_TRUNCATED": "Debido al volumen de mapeos de rutina de turnos afectados, no se pueden editar de forma masiva aquí. Utilice la pestaña Rutinas de turno en el perfil del empleado o póngase en contacto con Rotaready para obtener más ayuda.",
        "INFO_SUCCESS": "No queda ningún mapeo conflictivo de la rutina de turnos.",
        "ERROR_500": "En ese momento nos encontramos con un error inesperado. Vuelva a intentarlo.",
        "ERROR_400": "Ese cambio no se puede hacer porque coincide con una fecha que se ha firmado.",
        "COLUMN_START_DATE": "Fecha de inicio",
        "COLUMN_END_DATE": "Fecha de finalización",
        "COLUMN_SHIFT_ROUTINE": "Rutina de turnos",
        "COLUMN_OPTIONS": "Opciones",
        "LABEL_OPEN_ENDED": "De final abierto",
        "OPTION_ADD_END_DATE": "Añadir fecha de finalización",
        "OPTION_DELETE": "Borrar"
      },
      "VALIDATION_BULK_SHIFT_EDIT": {
        "INFO_TRUNCATED": "Debido al volumen de turnos afectados, no se pueden editar de forma masiva aquí. Modifique las rotaciones correspondientes o póngase en contacto con Rotaready para obtener más ayuda.",
        "INFO_SUCCESS": "No quedan turnos conflictivos.",
        "ERROR_500": "En ese momento nos encontramos con un error inesperado. Vuelva a intentarlo.",
        "ERROR_400": "Ese cambio no se puede hacer. Es posible que se superponga con una fecha que se haya firmado. Para obtener más información, pruebe esto en la propia rotación.",
        "BUTTON_UNASSIGN_SELECTED": "Desasignar lo seleccionado",
        "BUTTON_DELETE_SELECTED": "Eliminar lo seleccionado",
        "COLUMN_DATE": "Fecha",
        "COLUMN_TIME": "Hora",
        "COLUMN_DEPARTMENT": "Departamento",
        "COLUMN_TYPE": "Tipo",
        "COLUMN_STATUS": "Estado",
        "COLUMN_OPTIONS": "Opciones",
        "STATUS_PUBLISHED": "Publicado",
        "STATUS_DRAFT": "Borrador",
        "OPTION_UNASSIGN": "Desasignar",
        "OPTION_DELETE": "Borrar"
      },
      "VALIDATION_EPHEMERAL_RECORDS": {
        "LABEL_OPEN_ENDED": "(de composición abierta)",
        "LABEL_NEW": "Nuevo",
        "LABEL_EXISTING": "Existente",
        "EMPLOYMENT": "Empleo",
        "APPOINTMENT": "Cita"
      },
      "ACCOUNT_COMPLETION_TRACKER": {
        "CONSTITUENT_PERSONAL": "Personal",
        "CONSTITUENT_EMPLOYMENT": "Empleo",
        "CONSTITUENT_PAY": "Pagar",
        "CONSTITUENT_HOLIDAY_ALLOWANCE": "Subsidio de vacaciones"
      }
    },
    "CALENDAR": {
      "TIP": "Para solicitar la ausencia, simplemente haga clic en un día o haga clic y arrastre para seleccionar varios días a la vez.",
      "TODAY": "Hoy",
      "MONTH": "Mes",
      "WEEK": "Semana",
      "DAY": "Día"
    },
    "HIERARCHY_ITEM_PICKER": {
      "PICKER": "Recogedor",
      "PLACEHOLDER": "No se ha seleccionado {{itemDescriptor}}",
      "FILTER_TO": "Filtrar para...",
      "RESET": "Reiniciar",
      "REMOVED_BY_FILTER": "El filtro ha eliminado el {{itemDescriptor}} que has seleccionado.",
      "NO_SELECTED": "No se ha seleccionado {{itemDescriptor}}"
    },
    "SUBSCRIBE_BUTTON": {
      "LABEL_ETID_1": "solicitada",
      "LABEL_ETID_2": "cancelado",
      "LABEL_ETID_3": "aprobado",
      "LABEL_ETID_4": "rechazado",
      "LABEL_ETID_5": "marcado para su aprobación",
      "LABEL_ETID_6": "publicado",
      "LABEL_ETID_7": "volvió a borrador",
      "LABEL_ETID_8": "la semana está cerrada",
      "LABEL_ETID_9": "pendiente de aprobación",
      "LABEL_ETID_10": "aprobado",
      "LABEL_ETID_11": "solicitada",
      "LABEL_ETID_13": "aprobado",
      "LABEL_ETID_14": "rechazado",
      "LABEL_ETID_15": "cancelado",
      "LABEL_ETID_16": "fecha de caducidad próxima",
      "LABEL_ETID_17": "caducado",
      "LABEL_ETID_29": "iniciado",
      "LABEL_ETID_30": "aceptado por alguien",
      "LABEL_ETID_31": "rechazado por alguien",
      "LABEL_ETID_32": "completado",
      "LABEL_ETID_36": "la semana se invierte",
      "LABEL_ETID_37": "el día está cerrado",
      "LABEL_ETID_38": "el día se invierte",
      "DESCRIPTOR_ABSENCE": "una solicitud de ausencia es",
      "DESCRIPTOR_ROTA": "una rotación es",
      "DESCRIPTOR_ACCOUNT": "un empleado es",
      "DESCRIPTOR_SWAP": "un cambio de turno es",
      "DESCRIPTOR_COST_CONTROL": "un control de costos",
      "DESCRIPTOR_DOCUMENT": "un documento es",
      "DESCRIPTOR_BROADCAST": "una transmisión por turnos es",
      "NOUN_ABSENCE": "reserva de ausencias",
      "NOUN_ROTA": "rota",
      "NOUN_ACCOUNT": "empleado",
      "NOUN_SWAP": "cambio de turno",
      "NOUN_COST_CONTROL": "registro de control de costos",
      "NOUN_DOCUMENT": "documento",
      "NOUN_BROADCAST": "transmisión por turnos",
      "NOTIFY_SENTENCE_EXORDIUM": "Notificarme cuando",
      "NOTIFY_SENTENCE_TRAILING_IN": "en...",
      "UNSUBSCRIBE": "Cancelar suscripción",
      "SUBSCRIBE": "Suscríbase",
      "SEARCH_PLACEHOLDER": "Buscar...",
      "BACK_LINK": "Atrás",
      "RESOURCE_SUBSCRIBE_TOOLTIP": "Reciba una notificación de los cambios en este {{name}}",
      "RESOURCE_UNSUBSCRIBE_TOOLTIP": "Cancelar la suscripción a los cambios en este {{name}}"
    },
    "ROTA": {
      "DASHBOARD_WEEK": {
        "VIEW_ROTA": "Ver rotación",
        "LAST_WEEK": "La semana pasada",
        "THIS_WEEK": "Esta semana",
        "NO_ATTENDANCE_ISSUES": "No hay problemas de asistencia",
        "ATTENDANCE_ISSUES": "problema (s) de asistencia",
        "RESOLVE": "Resolver",
        "ACTUAL_SALES_ENTERED": "Cifras de ventas reales ingresadas",
        "ACTUAL_SALES_MISSING": "Faltan cifras <strong>de ventas reales</strong>",
        "WTD_COMPLIANT": "Cumple con las normas de tiempo de trabajo",
        "VIEW": "Ver",
        "WTD_VIOLATIONS": "Violación (es) del tiempo de trabajo",
        "SIGN_OFF_NOT_COMPLETED": "El <strong>cierre de sesión</strong> aún no se ha completado",
        "SIGN_OFF": "Cerrar sesión",
        "WEEK_SIGNED_OFF": "Semana firmada",
        "ABSENCE_REQUEST": "Solicitudes de ausencia",
        "ADDED_SALES_FORECAST": "Previsión de ventas añadida",
        "DRAFTED_ROTA": "Rotación redactada",
        "MARKED_FOR_APPROVAL": "Ruta marcada para su aprobación",
        "PUBLISHED": "Rotaciones publicadas",
        "OUTSTANDING": "{{ausences}} pendientes",
        "NOT_ADDED": "No se ha añadido",
        "NOT_DRAFTED": "No está redactado",
        "NOT_DONE": "No está hecho",
        "NOT_PUBLISHED": "No publicado"
      }
    },
    "SIDEBAR": {
      "NOTIFICATION_POPOVER": {
        "NO_NOTIFICATIONS": "¡Sin notificaciones!",
        "MARK_ALL_READ": "Marcar todo como leído"
      },
      "SETTINGS_POPOVER": {
        "TIMEZONE": "Esta zona horaria es diferente a la de su zona horaria local.",
        "EDIT_PREFERENCES": "Editar preferencias",
        "MANAGE_SUBSCRIPTIONS": "Administrar suscripciones",
        "SHOW_INTERCOM": "Mostrar chat de soporte",
        "HIDE_INTERCOM": "Ocultar chat de soporte",
        "SIGN_OUT": "Cerrar sesión"
      }
    },
    "DATE_PICKER": {
      "CALENDAR": "Calendario",
      "GO_TO_TODAY": "Ir al día de hoy"
    },
    "ENTITY_SELECTOR": {
      "ORG_WIDE": "En toda la organización",
      "NO_OPTIONS": "Sin opciones",
      "SEARCH_PLACEHOLDER": "Buscar..."
    },
    "FILTER_OPTIONS_BUTTON": {
      "IS": "es",
      "AND": "y",
      "OR": "o",
      "ANY": "Cualquier",
      "SEARCH_PLACEHOLDER": "Buscar...",
      "NO_OPTIONS": "Sin opciones",
      "SELECT_ALL": "Selecciona todo",
      "CLEAR_SELECTED": "Borrar lo seleccionado"
    },
    "FILTER_STAFF_SEARCH_BUTTON": {
      "IS": "es",
      "ANY": "Cualquier",
      "RESET": "Reiniciar"
    },
    "FILTER_DATE_BUTTON": {
      "ANY": "ninguna",
      "BEFORE": "delante de",
      "AFTER": "después",
      "BETWEEN": "entre",
      "IS": "es",
      "AND": "y",
      "DONE": "Hecho"
    },
    "FILTER_NUMBER_BUTTON": {
      "ANY": "es cualquier",
      "GREATER": "es mayor que",
      "LESS": "es inferior a",
      "EXACTLY": "es exactamente",
      "DONE": "Hecho"
    },
    "VALIDATION_FAILURE": {
      "TITLE": "Problemas de validación",
      "ISSUE": "cuestión",
      "ISSUES": "asuntos",
      "CHANGE_MESSAGE": "El cambio que intentas hacer ha resultado en ",
      "IGNORE_CONTINUE": "Ignora y continúa de todos modos",
      "IGNORE_PROBLEM": "Ignora los cambios problemáticos e inténtalo de nuevo",
      "LOADING": "Cargando..."
    },
    "PICK_LIST": {
      "NO_OPTIONS": "Sin opciones",
      "SEARCH_PLACEHOLDER": "Buscar...",
      "DEFAULT_NULL_OPTION_LABEL": "Ninguna"
    },
    "FILE_UPLOAD": {
      "ALERT_UNSUPPORTED_FILE_TYPE": "Lamentablemente, ese tipo de archivo no es compatible.",
      "ALERT_FILE_SIZE": "Lamentablemente, el archivo es demasiado grande.",
      "LABEL_ACCEPTED_FILE_TYPES": "Tipos de archivos aceptados",
      "LABEL_LIMIT": "limitar",
      "INFO_DROP_AVAILABLE_PART_1": "Arrastra y suelta un archivo aquí o",
      "INFO_DROP_AVAILABLE_PART_2": "haga clic para navegar",
      "INFO_DROP_NOT_AVAILABLE": "Haga clic para buscar un archivo",
      "INFO_DRAG_ACTIVE": "Buena puntería, ¡hora de caer!",
      "INFO_UPLOAD_FAILED": "Error al subir, inténtalo de nuevo"
    }
  },
  "AVAILABILITY": {
    "ABSENCE": {
      "TAB_ABSENCE": "Reservas de ausencias",
      "TAB_ALLOWANCES": "Subsidios"
    },
    "WEEKLY_AVAILABILITY_MODAL": {
      "ADD_TITLE": "Añadir disponibilidad semanal",
      "EDIT_TITLE": "Editar la disponibilidad semanal",
      "NICKNAME": "Apodo",
      "PLACEHOLDER": "es decir. Mientras estudias",
      "HELP": "Deberías darle a este patrón de disponibilidad un nombre significativo, como «Mientras estudias» si esto representa cuándo puedes trabajar mientras estudias para los exámenes, o «Vacaciones de verano» si estás libre la mayor parte del tiempo, por ejemplo.",
      "STARTING_FROM": "A partir de",
      "NEVER_ENDING": "Sin fin",
      "YES_PATTERN_FOREVER": "Sí, deja que este patrón continúe para siempre",
      "NO_PATTERN_DATE": "No, hay una fecha en la que me gustaría detener este patrón",
      "STOP_DATE": "Fecha de finalización",
      "PATTERN_APPLIES": "Se aplica el patrón",
      "EVERY": "Cada",
      "WEEK": "Semana",
      "SAVING_BUTTON_TEXT": "Ahorrando...",
      "SAVE_BUTTON_TEXT": "Guardar",
      "CANCEL": "Cancelar"
    },
    "WEEKLY_AVAILABILITY_MODAL_SINGLE": {
      "TITLE": "Añadir disponibilidad única",
      "SUBTITLE": "Durante una sola semana",
      "WEEK_BEGINS": "Comienza la semana",
      "HELP": "Esta es la semana para la que quieres marcar tu disponibilidad.",
      "SAVING_BUTTON_TEXT": "Ahorrando...",
      "SAVE_BUTTON_TEXT": "Guardar",
      "CANCEL": "Cancelar"
    },
    "AVAILABILITY": {
      "ADD_AVAILABILITY": "Añadir disponibilidad",
      "NEW_WEEKLY_PATTERN": "Nuevo patrón semanal",
      "NEW_MONTHLY_PATTERN": "Nuevo patrón mensual",
      "NEW_CUSTOM_PATTERN": "Nuevo patrón personalizado",
      "ONE_OFF_PATTERN": "Una sola vez (una semana)",
      "AVAILABILITY": "Disponibilidad",
      "AVAILABILITY_HELP": "Gestione la disponibilidad aquí a través de patrones de disponibilidad; piense en ellos como eventos de calendario recurrentes.",
      "AVAILABILITY_TIP": "Usa un patrón mensual para fijar fechas como «el primer miércoles» o «el último sábado» de cada mes.",
      "NICKNAME": "Apodo",
      "APPLIES_FROM_TO": "Se aplica desde (/hasta)",
      "OPTIONS": "Opciones",
      "NO_AVAILABILITY_PATTERNS": "No hay patrones de disponibilidad. Añade uno usando los botones de arriba.",
      "TURN_OFF": "Apagar",
      "TURN_ON": "Encender",
      "NO_NICKNAME": "Sin apodo",
      "WEEKLY": "Semanalmente",
      "MONTHLY": "Mensualmente",
      "CUSTOM": "Personalizado",
      "EDIT": "Editar",
      "TOGGLE_DROPDOWN": "Alternar menú desplegable",
      "DELETE": "Borrar",
      "CONFIRM_DELETE_PATTERN": "¿Estás seguro? La eliminación de este patrón de disponibilidad no se puede deshacer.",
      "DELETE_PATTERN_SUCCESS": "¡Éxito! Se ha eliminado tu patrón de disponibilidad.",
      "ERROR_DELETING_PATTERN": "No hemos podido eliminar ese patrón en este momento. Vuelva a intentarlo.",
      "ERROR_TOGGLING_PATTERN": "No hemos podido activar/desactivar ese patrón en este momento. Por favor, inténtelo de nuevo.",
      "ERROR_LOADING_PATTERNS": "No hemos podido cargar tus patrones de disponibilidad. Vuelva a intentarlo."
    },
    "DAILY_AVAILABILITY_MODAL": {
      "ADD_CUSTOM_AVAILABILITY": "Añadir disponibilidad personalizada",
      "EDIT_CUSTOM_AVAILABILITY": "Editar la disponibilidad personalizada",
      "NICKNAME": "Apodo",
      "NICKNAME_PLACEHOLDER": "es decir. Mientras estudias",
      "NICKNAME_HELP": "Deberías darle a este patrón el nombre de algo significativo.",
      "STARTING_FROM": "A partir de",
      "NEVER_ENDING": "Sin fin",
      "CONTINUE_FOREVER": "Sí, deja que este patrón continúe para siempre",
      "PATTERN_STOP": "No, hay una fecha en la que me gustaría detener este patrón",
      "STOP_DATE": "Fecha de finalización",
      "PATTERN_DAYS": "Días de patrón",
      "PATTERN_NO_DAYS": "Este patrón no tiene días. Agregue uno usando el botón de abajo.",
      "DAY_NUMBER": "Día {{number}}",
      "ILL_BE": "Voy a ser",
      "DELETE": "Borrar",
      "ADD_DAY": "Añadir día",
      "SAVE": "Guardar",
      "SAVE_LOADING": "Ahorrando...",
      "CANCEL": "Cancelar",
      "UNAVAILABLE": "No disponible",
      "AVAILABLE": "Disponible",
      "AVAILABLE_AM": "Disponible (solo AM)",
      "AVAILABLE_PM": "Disponible (solo PM)",
      "OTHER": "Otros (especificar...)",
      "MINIMUM_TWO_DAYS": "Debes tener un mínimo de dos días en este patrón.",
      "STOPS_BEFORE_STARTS": "¡Tu patrón está actualmente configurado para detenerse antes de que comience!",
      "AVAILABILITY_END_BEFORE_START": "Una o más de tus reglas de disponibilidad tienen una hora de finalización anterior a la hora de inicio.",
      "ERROR_SAVING_PATTERN": "Actualmente no se puede guardar este patrón de disponibilidad. Vuelva a intentarlo.",
      "ERROR_UPDATING_PATTERN": "Actualmente no se puede actualizar este patrón de disponibilidad. Vuelva a intentarlo."
    },
    "EDIT_PATTERN_EXCEPTIONS_MODAL": {
      "EDIT_EXCEPTIONS": "Editar excepciones",
      "EXCEPTIONS_TIP": "Tus patrones de disponibilidad marcan cuándo no estás libre para trabajar. Sin embargo, puedes usar tu calendario para indicarnos que puedes trabajar un día en el que normalmente no podrías trabajar. Simplemente haz clic en el marcador rojo de «no disponible» y podrás hacer una excepción.",
      "EXCEPTIONS": "La siguiente lista muestra las excepciones que has hecho a tu patrón de disponibilidad. Te consideramos libre para trabajar en estos días:",
      "DATE": "Fecha",
      "OPTIONS": "Opciones",
      "UNDO": "Deshacer",
      "NO_EXCEPTIONS": "Aún no has hecho ninguna excepción.",
      "CLOSE": "Cerrar",
      "ERROR_DELETING_EXCEPTION": "Lamentablemente, no pudimos eliminar la excepción en ese momento. Vuelva a intentarlo."
    },
    "MONTHLY_AVAILABILITY_MODAL": {
      "ADD_MONTHLY_AVAILABILITY": "Añadir disponibilidad mensual",
      "EDIT_MONTHLY_AVAILABILITY": "Editar la disponibilidad mensual",
      "NICKNAME": "Apodo",
      "NICKNAME_PLACEHOLDER": "es decir. Mientras estudias",
      "NICKNAME_HELP": "Deberías darle a este patrón de disponibilidad un nombre significativo, como «Mientras estudias» si esto representa cuándo puedes trabajar mientras estudias para los exámenes, o «Vacaciones de verano» si estás libre la mayor parte del tiempo, por ejemplo.",
      "STARTING_FROM": "A partir de",
      "NEVER_ENDING": "Sin fin",
      "CONTINUE_FOREVER": "Sí, deja que este patrón continúe para siempre",
      "PATTERN_STOP": "No, hay una fecha en la que me gustaría detener este patrón",
      "STOP_DATE": "Fecha de finalización",
      "PATTERN_RULES": "Reglas de patrón",
      "PATTERN_NO_RULES": "Este patrón no tiene reglas. Agregue uno usando el botón de abajo.",
      "EVERY": "Cada",
      "ON_THE": "en el",
      "DELETE": "Borrar",
      "ADD_RULE": "Agregar regla",
      "SAVE": "Guardar",
      "SAVE_LOADING": "Ahorrando...",
      "CANCEL": "Cancelar",
      "ORDINAL_LAST": "último",
      "ORDINAL_FIRST": "primera",
      "ORDINAL_SECOND": "segundo",
      "ORDINAL_THIRD": "tercera",
      "ORDINAL_FOURTH": "cuarta",
      "ORDINAL_FIFTH": "quinto",
      "MONDAY": "lunes",
      "TUESDAY": "martes",
      "WEDNESDAY": "miércoles",
      "THURSDAY": "jueves",
      "FRIDAY": "viernes",
      "SATURDAY": "sábado",
      "SUNDAY": "domingo",
      "AVAILABLE": "Disponible",
      "AVAILABLE_AM": "Solo AM",
      "AVAILABLE_PM": "Solo PM",
      "MONTH": "mes",
      "MONTHS": "meses",
      "OTHER": "Otros (especificar...)",
      "STOPS_BEFORE_STARTS": "¡Tu patrón está actualmente configurado para detenerse antes de que comience!",
      "AVAILABILITY_END_BEFORE_START": "Una o más de tus reglas de disponibilidad tienen una hora de finalización anterior a la hora de inicio.",
      "ERROR_SAVING_PATTERN": "Actualmente no se puede guardar este patrón de disponibilidad. Vuelva a intentarlo.",
      "ERROR_UPDATING_PATTERN": "Actualmente no se puede actualizar este patrón de disponibilidad. Vuelva a intentarlo."
    },
    "OVERVIEW": {
      "SCHEDULE": "Mi agenda",
      "SCHEDULE_HELP": "Este es tu cronograma completo, incluidos los turnos que se te han asignado, las ausencias aprobadas y la disponibilidad.",
      "SHIFTS": "Turnos",
      "ABSENCE": "Ausencia",
      "UNAVAILABLE": "No disponible (patrones)",
      "EDIT_EXCEPTIONS": "Editar excepciones",
      "ICAL_FEED": "Feed iCalendar",
      "ICAL_FEED_HELP": "Un feed de iCalendar te permitirá ver tu agenda fuera de Rotaready.",
      "ICAL_FEED_TIP": "Una vez que hayas generado una URL de feed de iCalendar, puedes copiarla y pegarla en <a href=\"https://support.apple.com/en-gb/HT202361\" target=\"_blank\">Apple Calendar, <a href=\"https://support.google.com/calendar/answer/37100\" target=\"_blank\">Google Calendar</a></a> o cualquier otro software de calendario.",
      "COPIED": "¡Copiado al portapapeles!",
      "GENERATE": "Generar"
    }
  },
  "ATTENDANCE": {
    "VALIDATION_CODES": {
      "CODE_1": "Se ha firmado la fecha correspondiente, por lo que no podemos modificarla.",
      "CODE_2": "Si lo hace, se generaría una secuencia de eventos no válida. Por ejemplo, la hora de salida debe ir después de la hora de entrada, pero no puede ir antes de otra hora de salida. Comprueba los eventos circundantes e inténtalo de nuevo.",
      "CODE_3": "El sitio o departamento elegido no es válido.",
      "CODE_4": "No podemos añadir un horario aquí porque el empleado elegido no tiene una cita para esta fecha.",
      "CODE_5": "No podemos añadir un evento aquí porque ya existe un evento al mismo tiempo.",
      "CODE_6": "El empleado elegido no es válido.",
      "CODE_7": "Este cambio movería el evento a un momento anterior al evento anterior. Intente elegir una hora diferente o edite primero el evento anterior.",
      "CODE_8": "Este cambio trasladaría el evento a un momento posterior al evento que le sigue. Intente elegir una hora diferente o edite primero el evento posterior."
    },
    "COMMON": {
      "EVENT_ADD_SUCCESS": "El evento se agregó correctamente.",
      "ABSENCE_ADD_SUCCESS": "Se han marcado como ausentes.",
      "NO_CLOCK_IN_MODAL_TITLE": "Añadir evento",
      "NO_CLOCK_IN_MODAL_MESSAGE": "¿Qué te gustaría hacer?",
      "NO_CLOCK_IN_MODAL_ADD_EVENT": "Añadir reloj",
      "NO_CLOCK_IN_MODAL_ADD_EVENT_DESC": "Agregar manualmente un registro de asistencia",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE": "Marcar ausente",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC": "Añadir un registro de ausencias, como por ejemplo por enfermedad",
      "UNMATCHED_MODAL_TITLE": "Asistencia inigualable",
      "UNMATCHED_MODAL_MESSAGE": "Hay dos maneras de resolver una asistencia inigualable:",
      "UNMATCHED_MODAL_ADD_SHIFT": "Añadir turno",
      "UNMATCHED_MODAL_ADD_SHIFT_DESC": "Crea un nuevo turno para reflejar lo que se ha trabajado",
      "UNMATCHED_MODAL_EDIT_EVENT": "Editar asistencia",
      "UNMATCHED_MODAL_EDIT_EVENT_DESC": "Ajustar los registros de asistencia si no son correctos",
      "OVERTIME_MODAL_TITLE": "Revisar las horas extras",
      "OVERTIME_MODAL_MESSAGE_IN": "{{name}} llegó temprano y el turno se ajustó a una regla salarial. ¿Qué te gustaría hacer?",
      "OVERTIME_MODAL_MESSAGE_OUT": "{{name}} se ha retrasado y el turno ha cumplido con una regla salarial. ¿Qué te gustaría hacer?",
      "OVERTIME_MODAL_APPROVE": "Aprobar las horas extras",
      "OVERTIME_MODAL_APPROVE_DESC": "Se pagará a {{name}} por los {{duration}} minutos de horas extras",
      "OVERTIME_MODAL_REJECT": "Rechazar horas extras",
      "OVERTIME_MODAL_REJECT_DESC": "No se pagará a {{name}} por las horas extras trabajadas",
      "OVERTIME_UPDATE_ERROR": "No podemos actualizar el evento ahora mismo. Por favor, inténtelo de nuevo.",
      "MODAL_USER_NOTES_LABEL": 'Notas de {{name}}'
    },
    "BY_STAFF": {
      "FILTER_LABEL_DATE": "Fecha",
      "MANUAL_CLOCK_IN": "Reloj de entrada manual",
      "EVENT_TYPE": "Evento",
      "RECORDED_TIME": "Tiempo registrado",
      "METHOD": "Capturado por",
      "OPTIONS": "Opciones",
      "TRUSTED_DEVICE": "Dispositivo de confianza",
      "MOBILE_APP": "Aplicación Rotaready",
      "MANUALLY_OVERRIDDEN": "Anular manualmente",
      "MANUAL_ENTRY": "Entrada manual",
      "DELETE": "Borrar",
      "NO_EVENTS": "No hay eventos de asistencia que mostrar.",
      "CLOCK_IN": "Reloj en",
      "CLOCK_OUT": "Acaba el reloj",
      "BREAK_ON": "En pausa",
      "BREAK_OFF": "Fuera de descanso",
      "ERROR_LOADING_EVENTS": "No podemos aumentar la asistencia ahora mismo. Por favor, inténtelo de nuevo.",
      "ERROR_DELETING": "No podemos eliminar la asistencia ahora mismo. Por favor, inténtelo de nuevo.",
      "DELETE_SUCCESS": "El evento se ha eliminado correctamente.",
      "ADD_SUCCESS": "El evento se agregó correctamente.",
      "LABEL_EDITED": "Editado"
    },
    "ISSUES": {
      "TITLE": "Problemas de asistencia",
      "FILTER_DATE": "Fecha",
      "FILTER_ISSUE_TYPE": "Tipo de asunto",
      "FILTER_POSITION": "Función laboral",
      "FILTER_USER": "Persona",
      "ISSUES_HELP": "Los problemas deben resolverse; de lo contrario, provocarán discrepancias en los informes y la nómina. De forma predeterminada, Rotaready marca los problemas de los últimos {{defaultIssuePeriod}} días.",
      "IS_THIS": "Es esto",
      "YES": "Sí",
      "NO": "No",
      "HEADING_NAME": "Nombre",
      "HEADING_SITE": "Sitio",
      "HEADING_ISSUE": "Asunto",
      "HEADING_SHIFT": "Turno",
      "HEADING_START": "Comenzar",
      "HEADING_FINISH": "Finalizar",
      "HEADING_CLOCK_IN": "Relojes",
      "HEADING_CLOCK_OUT": "Reloj de salida",
      "HEADING_OPTIONS": "Opciones",
      "NOT_FOUND": "No se ha encontrado",
      "RESOLVE": "Resolver",
      "DISMISS": "Descartar",
      "NO_ATTENDANCE_ISSUES": "No tienes problemas de asistencia, ¡muy bien hecho!",
      "UNMATCHED_ATTENDANCE": "Asistencia inigualable",
      "NO_CLOCK_IN": "Sin registro",
      "NO_CLOCK_OUT": "Sin horario de cierre",
      "SUSPECT_CLOCK_OUT": "Salida del sospechoso",
      "SUSPECT_ATTENDANCE": "Asistencia del sospechoso",
      "BREAK_NOT_TAKEN": "Descanso no tomado",
      "INVALID_BREAKS": "Interrupciones inválidas",
      "SUSPECT_BREAK": "Sospechoso se",
      "EARLY_CLOCK_IN": "Registro anticipado",
      "LATE_CLOCK_OUT": "Salida tardía",
      "UNMATCHED_ATTENDANCE_EXPLANATION": "Rotaready no pudo encontrar un turno que igualara esta asistencia",
      "NO_CLOCK_IN_EXPLANATION": "El miembro del personal no llegó a su turno",
      "NO_CLOCK_OUT_EXPLANATION": "El miembro del personal no se retiró después de su turno",
      "SUSPECT_CLOCK_OUT_EXPLANATION": "El miembro del personal se retiró mucho más tarde de la hora de finalización programada",
      "SUSPECT_ATTENDANCE_EXPLANATION": "El miembro del personal estuvo recluido durante un período de tiempo mucho más corto del programado",
      "BREAK_NOT_TAKEN_EXPLANATION": "El turno tiene un descanso, pero no se tomó ninguno.",
      "INVALID_BREAKS_EXPLANATION": "Los descansos de esta asistencia están incompletos (es decir, faltan un descanso)",
      "SUSPECT_BREAK_EXPLANATION": "El miembro del personal estuvo de vacaciones mucho más tiempo del previsto.",
      "EARLY_CLOCK_IN_EXPLANATION": "El turno coincide con una regla salarial que requiere la aprobación para entrar temprano",
      "LATE_CLOCK_OUT_EXPLANATION": "El turno coincide con una regla salarial que requiere la aprobación para salir tarde.",
      "ERROR_LOADING_ATTENDANCE": "No podemos cargar tus problemas de asistencia en este momento. Vuelva a intentarlo.",
      "SOMETHING_WENT_WRONG": "Vaya, algo acaba de salir mal. ¡Puede que tengas que volver a intentarlo!",
      "CONFIRM_DISMISS": "¿Está seguro de que desea descartar este problema de asistencia?",
      "DATE_RANGE_TOO_WIDE": "No puedes elegir más de 5 semanas. Restrinja el intervalo de fechas.",
      "CLOCK_OUT_DAYS_LATER": "Esta hora de salida es uno o más días después de la hora de entrada."
    },
    "OVERVIEW": {
      "UNMATCHED_LABEL": "{{count}} inigualable",
      "OPTIONS_DAILY": "Diariamente",
      "OPTIONS_WEEKLY": "Semanalmente",
      "OPTIONS_DAY_ROLL_OVER": "Renovación de un día",
      "FILTER_POSITION": "Función laboral",
      "FILTER_USER": "Persona",
      "MIDNIGHT": "Medianoche",
      "TOOLS": "Herramientas",
      "CURRENTLY_CLOCKED_IN": "Personas actualmente reclutadas",
      "ATTENDANCE_HELP": "La asistencia se compara automáticamente con los turnos si se produce una entrada o una salida dentro de {<b>{automatchThreshold} hora (s) antes de la hora de inicio o finalización programada</b>.",
      "MATCHED_HEADING_SCHEDULED": "Programado",
      "MATCHED_HEADING_ACTUAL": "Actual",
      "MATCHED_HEADING_AFTER_SANITISATION": "Después de la sanitización",
      "MATCHED_HEADING_NAME": "Nombre",
      "MATCHED_HEADING_SITE": "Sitio",
      "MATCHED_HEADING_START": "Comenzar",
      "MATCHED_HEADING_FINISH": "Finalizar",
      "MATCHED_HEADING_PAID_HOURS": "Horas pagadas",
      "MATCHED_HEADING_UNPAID_HOURS": "Horas no remuneradas",
      "COVER_SHIFT": "Turno de portada",
      "NO_CLOCK_IN": "Aún no se ha registrado",
      "NO_CLOCK_OUT": "Aún no hay tiempo de cierre",
      "OVERTIME_APPROVED": "Aprobado",
      "OVERTIME_REJECTED": "Rechazado",
      "OVERTIME_REVIEW": "Revisar",
      "OVERTIME_APPROVED_BY": "Horas extras aprobadas por {{name}}",
      "OVERTIME_REJECTED_BY": "Horas extras rechazadas por {{name}}",
      "MATCHED_NO_RESULTS": "No hay turnos que mostrar.",
      "UNMATCHED_ATTENDANCE": "Asistencia inigualable",
      "UNMATCHED_ATTENDANCE_HELP": "Si la asistencia no se encuentra dentro de las <b>{{automatchThreshold} horas antes de la hora de inicio/finalización de un turno, tendrás que ajustar manualmente el turno o los registros de asistencia para que estén alineados</b>:",
      "UNMATCHED_HEADING_NAME": "Nombre",
      "UNMATCHED_HEADING_SITE": "Sitio",
      "UNMATCHED_HEADING_DATE": "Fecha",
      "UNMATCHED_HEADING_CLOCK_IN": "Reloj en",
      "UNMATCHED_HEADING_CLOCK_OUT": "Acaba el reloj",
      "UNMATCHED_HEADING_OPTIONS": "Opciones",
      "NO_UNMATCHED_ATTENDANCE": "Aquí no hay una asistencia inigualable. ¡Buen trabajo!",
      "NONE_YET": "Ninguno todavía",
      "RESOLVE": "Resolver",
      "ERROR_LOADING_ATTENDANCE": "No podemos cargar tu historial de asistencia en este momento. Por favor, inténtelo de nuevo.",
      "TIMELINESS_EARLY_TOOLTIP": "{{duration}} minutos antes",
      "TIMELINESS_LATE_TOOLTIP": "{{duration}} minutos de retraso",
      "TRUNCATED_RESULT_MESSAGE": "Hay más por ver, pero para que todo funcione sin problemas, hemos tenido que truncar el resultado. <br />Intente filtrar por puesto de trabajo, por sitio/departamento o reduzca el intervalo de fechas."
    },
    "VIEW_EVENT_MODAL": {
      "VIEW_ATTENDANCE": "Ver asistencia",
      "STAFF_MEMBER": "Quién",
      "EVENT_TYPE": "Evento",
      "RECORDED_TIME": "Tiempo registrado",
      "METHOD": "Capturado por",
      "TRUSTED_DEVICE": "Dispositivo de confianza",
      "MOBILE_APP": "Aplicación Rotaready",
      "MANUALLY_OVERRIDDEN": "Anulado manualmente",
      "MANUAL_ENTRY": "Entrada manual",
      "ON": "en",
      "OVERTIME": "Horas extras",
      "APPROVED": "Aprobado",
      "REJECTED": "Rechazado",
      "FACIAL_RECOGNITION": "Reconocimiento facial",
      "FACIAL_RECOGNITION_PROCESS": "Por lo general, las imágenes se procesan varios minutos después de haber sido capturadas",
      "FACIAL_RECOGNITION_WAITING": "En espera de procesamiento...",
      "TRUSTS_PHOTO": "Rotaready confía en esta foto",
      "NO_TRUSTS_PHOTO": "Rotaready no confía en esta foto",
      "CONFIDENT": "confiado",
      "UNABLE_TO_IDENTIFY": "Incapaz de identificar claramente un rostro",
      "PROCESSED_ON": "Procesado el",
      "ACKNOWLEDGED_BY": "Confirmado por {{name}} el",
      "REFERENCE_IMAGE": "Imagen de referencia",
      "REFERENCE_IMAGE_HELP": "Esta imagen no se procesará, sino que se utilizará como referencia para la comparación",
      "EDIT": "Editar",
      "CLOSE": "Cerrar",
      "TAB_DETAILS": "Detalles",
      "TAB_PHOTO": "Foto",
      "TAB_LOCATION": "Ubicación",
      "CLOCK_IN": "Reloj en",
      "CLOCK_OUT": "Acaba el reloj",
      "BREAK_ON": "Interrumpe",
      "BREAK_OFF": "Interrumpir",
      "HAPPENED_HERE": "ocurrió aquí",
      "TAB_AUDIT": "Historia",
      "AUDIT_TABLE_TIME": "Tiempo de asistencia",
      "AUDIT_TABLE_OVERTIME": "Horas extras",
      "AUDIT_TABLE_WHO": "Grabado por",
      "NOTES": "Notas"
    },
    "EDIT_EVENT_MODAL": {
      "TITLE": "Editar asistencia",
      "CLOCK_IN": "Reloj en",
      "CLOCK_OUT": "Acaba el reloj",
      "BREAK_ON": "Interrumpe",
      "BREAK_OFF": "Interrumpir",
      "ERROR_UPDATING": "No podemos actualizar el evento ahora mismo. Por favor, inténtelo de nuevo.",
      "ERROR_DELETING": "No podemos eliminar el evento ahora mismo. Vuelva a intentarlo.",
      "CONFIRM_DELETE": "¿Está seguro de que desea eliminar este evento?\nSi es un reloj de entrada, también se eliminará el reloj de salida correspondiente.",
      "PRIOR_EVENT_INFO": "El evento anterior es un <b>{{eventType}} at {{<b>date|momentFormat: 'll - LT'</b>}}</b>",
      "PRIOR_EVENT_CONFLICT": "Ya existe un <b>{{eventType}} en {{<b>date|momentFormat: 'll - LT'</b>}}</b>. Tendrás que seleccionar una hora diferente.",
      "LABEL_WHO": "Quién",
      "LABEL_EVENT": "Evento",
      "LABEL_DATE": "Fecha y hora",
      "DATE_IN_FUTURE": "¡Este tiempo aún no ha transcurrido!",
      "LABEL_OVERTIME": "Horas extras",
      "APPROVE": "Aprobar",
      "REJECT": "Rechazar",
      "LABEL_OVERTIME_HELP": "Rotaready identificó algunas horas extras al combinar este evento de asistencia con un turno. Si lo apruebas, las horas extras se pagarán de acuerdo con la regla de pago del turno.",
      "DELETE": "Borrar",
      "CANCEL": "Cancelar",
      "SAVE": "Guardar cambios"
    },
    "ADD_EVENT_MODAL": {
      "TITLE": "Añadir asistencia",
      "CLOCK_IN": "Reloj en",
      "CLOCK_OUT": "Acaba el reloj",
      "BREAK_ON": "Interrumpe",
      "BREAK_OFF": "Interrumpir",
      "ERROR_ACTIONING": "No podemos añadir el evento ahora mismo. Por favor, inténtelo de nuevo.",
      "PRIOR_EVENT_INFO": "El evento anterior es un <b>{{eventType}} at {{<b>date|momentFormat: 'll - LT'</b>}}</b>",
      "PRIOR_EVENT_CONFLICT": "Ya existe un <b>{{eventType}} en {{<b>date|momentFormat: 'll - LT'</b>}}</b>. Tendrás que seleccionar una hora diferente.",
      "LABEL_EVENT": "Evento",
      "LABEL_DATE": "Fecha y hora",
      "LABEL_BREAK": "Duración de las pausas",
      "LABEL_BREAK_HELP": "Se añadirá una pausa para ti después de la duración elegida.",
      "DATE_IN_FUTURE": "¡Este tiempo aún no ha transcurrido!",
      "CANCEL": "Cancelar",
      "SAVE": "Añadir asistencia",
      "BREAK_MINUTES": "minutos"
    }
  },
  "DIALOG": {
    "TEXTBOX_DIALOG": {
      "CANCEL": "Cancelar"
    },
    "TWO_BUTTON_DIALOG": {
      "CANCEL": "Cancelar"
    }
  },
  "OTHER": {
    "EDIT_PREFERENCES_MODAL": {
      "TITLE": "Actualizar preferencias",
      "SUBTITLE": "Información de contacto",
      "SUBTITLE_HELP": "Si olvidas tu contraseña, solo podemos ayudarte a restablecerla si tenemos formas alternativas de comunicarnos contigo",
      "EMAIL_LABEL": "Dirección de correo electrónico",
      "EMAIL_PLACEHOLDER": "No se ha proporcionado ninguno",
      "EMAIL_HELP": "Introduce una dirección de correo electrónico válida.",
      "MOBILE_LABEL": "teléfono móvil",
      "MOBILE_PLACEHOLDER": "No se ha proporcionado ninguno",
      "MOBILE_HELP": "Introduce un número de teléfono móvil válido o ninguno.",
      "ACCOUNT_INFO": "Información de la cuenta",
      "ACCOUNT_INFO_HELP": "Aquí puedes configurar tu idioma",
      "LANGUAGE_LABEL": "Idioma",
      "LANGUAGE_HELP": "Rotaready se mostrará en el idioma elegido.",
      "OTHER_SETTINGS_TITLE": "Otros ajustes",
      "CLOCK_IN_LABEL": "PIN de registro",
      "CLOCK_IN_HELP": "Si tienes que introducir un código PIN al entrar y salir, puedes introducir uno nuevo aquí. Debe tener 4 dígitos.",
      "NOTIFICATIONS_TITLE": "Notificaciones",
      "NOTIFICATIONS_HELP": "Controla cómo te gustaría recibir notificaciones de los eventos a los que estás suscrito ",
      "MANAGE_SUBSCRIPTIONS": "administrar suscripciones",
      "ROTA_UPDATES": "Actualizaciones de Rota",
      "CRITIAL_SHIFT": "Actualizaciones críticas de los turnos",
      "EMAIL": " Correo electrónico",
      "MOBILE_APP": " Aplicación móvil",
      "SHIFT_UPDATED": "Cuando se actualice un turno en el que trabajarás en las próximas 24 horas",
      "REGULAR_SHIFT": "Actualizaciones periódicas de turnos",
      "SHIFT_UPDATED_BEYOND": "Actualizaciones para cualquier turno en el que trabajarás más allá de las próximas 24 horas",
      "WEEKLY_SUMMARY": "Resumen semanal",
      "SMS": " SMS",
      "UPCOMING_WEEK": "Un resumen de sus turnos para la próxima semana, que se le enviará la noche anterior al inicio de la semana",
      "APPROVAL": "Aprobación",
      "APPROVAL_HELP": "Cuando una rotación está marcada como lista para su aprobación",
      "PUBLISHING": "Publicación",
      "PUBLISHING_HELP": "Cuando una rotación está publicada o no publicada",
      "SHIFT_SWAPS": "Intercambios de turno",
      "SWAP_UPDATES": "Actualizaciones de intercambio ",
      "REQUESTED": "Solicitada",
      "REQUESTED_HELP": "Cuando alguien solicita un cambio de turno nuevo",
      "ACCEPTED": "Aceptado",
      "ACCEPTED_HELP": "Cuando alguien acepta un cambio de turno",
      "APPROVED": "Aprobado",
      "APPROVED_HELP": "Cuando la gerencia aprueba un cambio de turno",
      "REJECTED": "Rechazado",
      "REJECTED_HELP": "Cuando la dirección rechaza un cambio de turno",
      "CANCELLED": "Cancelado",
      "CANCELLED_HELP": "Cuando se cancela un cambio de turno",
      "ABSENCE_UPDATES": "Actualizaciones sobre ausencias o vacaciones",
      "ABSENCE_REQUESTED": "Ausencia solicitada",
      "ABSENCE_REQUESTED_HELP": "Cuando se solicita una nueva ausencia",
      "ABSENCE_UPDATE": "Actualización de ausencias",
      "ABSENCE_UPDATE_HELP": " Cuando la administración aprueba, rechaza o cancela una solicitud de ausencia",
      "COST_CONTROL": "Control de costos",
      "SIGN_OFF": "Cerrar sesión",
      "SIGN_OFF_HELP": "Cuando un sitio cierra la sesión una semana",
      "SIGN_OFF_REVERSE": "Cierre de sesión invertido",
      "SIGN_OFF_REVERSE_HELP": "Cuando un sitio deja de cerrar sesión durante una semana",
      "ADMIN": "Administrador",
      "ACCOUNT_APPROVAL": "Aprobación de la cuenta",
      "ACCOUNT_APPROVAL_HELP": "Cuando una cuenta está pendiente de aprobación o ya ha sido aprobada",
      "DOCUMENTS": "Documentos",
      "DOCUMENTS_HELP": "Cuando un documento ha caducado o caducará pronto",
      "OTHER": "Otros",
      "COMPANY_ANNOUNCEMENTS": "Anuncios de la empresa",
      "COMPANY_HELP": "Anuncios importantes de su empleador",
      "DAILY_ADMIN_UPDATE": "Actualización diaria del administrador",
      "DAILY_UPDATE_HELP": "Te avisaremos si tienes algún problema de asistencia o si hay alguna solicitud de ausencia o cambio de turno pendiente de aprobación.",
      "PHONE_ONLY": "Las notificaciones con este icono solo están disponibles con las aplicaciones Rotaready para iPhone y Android.",
      "CHANGE_PASSWORD": "Cambiar contraseña",
      "SAVE": "Guardar",
      "SAVING": "Ahorrando...",
      "CANCEL": "Cancelar"
    },
    "MANAGE_SUBSCRIPTIONS_MODAL": {
      "TITLE": "Administrar suscripciones",
      "HELP": "Tus suscripciones controlan lo que te gustaría recibir notificaciones.",
      "UNSUBSCRIBE_ALL": "Darse de baja de todos",
      "NOTIFY_WHEN": "Notificarme cuando {{descriptor}}",
      "UNSUBSCRIBE_TOOLTIP": "Cancelar suscripción",
      "UNSUBSCRIBE_ALL_TOOLTIP": "Darse de baja de todos",
      "ACTION_IN": "{{action}} en ",
      "FILTER_NO_SUBSCRIPTIONS": "No tienes ninguna suscripción activa en {{name}}.",
      "ALL_NO_SUBSCRIPTIONS": "No tienes ninguna suscripción activa.",
      "CLOSE": "Cerrar"
    },
    "RELEASE_REFRESH_MODAL": {
      "TITLE": "Actualización de Rotaready",
      "BODY": "Acabamos de publicar una actualización de Rotaready que requiere que vuelvas a cargar la página. Haga clic en el botón de abajo.",
      "REFRESH": "Actualizar página"
    },
    "EDIT_AVATAR_MODAL": {
      "TITLE": "Foto de perfil",
      "INTRO_COPY": "Una imagen ayuda a las personas a reconocerte y te permite saber si has iniciado sesión en tu cuenta.",
      "BUTTON_CHANGE": "Sube una imagen nueva",
      "BUTTON_CANCEL": "Cancelar",
      "BUTTON_SAVE": "Guardar",
      "ALERT_REMOVE": "¿Estás seguro de que deseas eliminar esta foto de perfil?",
      "ALERT_FILE_TYPE_NOT_ALLOWED": "Ese tipo de archivo no está permitido.",
      "ALERT_ERROR_500": "No pudimos actualizar tu imagen en ese momento. Por favor, inténtelo de nuevo.",
      "ALERT_SUCCESS_ADD": "Se ha actualizado tu foto de perfil. La actualización de todos los servicios de Rotaready puede tardar un tiempo.",
      "ALERT_SUCCESS_REMOVE": "Se ha eliminado tu foto de perfil. La actualización de todos los servicios de Rotaready puede tardar un tiempo."
    }
  },
  "REPORTS": {
    "INDEX_LIST": {
      "TITLE": "Informes",
      "TRONC_STATUS": "Estado de Tronc",
      "CURRENTLY_CLOCKED_IN": "Personas actualmente recluidas",
      "ROTA_PROGRESS": "Progreso de rotación",
      "SIGN_OFF_PROGRESS": "Progreso de la firma",
      "PAYROLL_EXPORT": "Exportación de nóminas",
      "LABOUR_BREAKDOWN": "Desglose laboral",
      "WORKING_TIME_VIOLATIONS": "Infracciones del tiempo de trabajo",
      "CONTRACTUAL_OBLIGATION_SUMMARY": "Resumen de obligaciones contractuales",
      "DOCUMENT_SIGNATURES": "Firma de documentos",
      "EXPIRING_DOCUMENTS": "Documentos que caducan",
      "EMPLOYEE_EXPORT": "Detalles del empleado",
      "JOINERS_LEAVERS": "Carpinteros y egresados",
      "STAFF_TURNOVER": "Rotación de personal",
      "ANNIVERSARY": "Aniversarios",
      "EMPLOYMENT_CHANGES": "Cambios en el empleo",
      "CATEGORY_HR": "HORA",
      "CATEGORY_DOCUMENTS": "Documentos",
      "CATEGORY_ROTAS": "Programación",
      "CATEGORY_PAYROLL": "Nómina",
      "CATEGORY_ATTENDANCE": "Asistencia",
      "CATEGORY_ABSENCE": "Ausencia",
      "CATEGORY_COST_CONTROL": "Control de costos",
      "ABSENCE_EXPORT": "Exportación de ausencias",
      "ALLOWANCES_EXPORT": "Exportación de subsidios",
      "UNTAKEN_HOLIDAY_COST": "Costo de vacaciones no aprovechado",
      "COST_CONTROL_RAW_DATA": "Exportación de datos sin procesar",
      "NEW_HIRE_TURNOVER": "Rotación de nuevos empleados",
      "ABSENCE_DAYS_EXPORT": "Exportación de días de ausencia",
      "SHIFT_TYPE_STREAM_BREAKDOWN": "Tipo de turno y desglose de flujos",
      "XERO_EXPORT": "Exportar a Xero",
      "DOCUMENT_PRESENCE": "Presencia de documentos",
      "DOCUMENTS_EXPORT": "Exportación de documentos",
      "ATTENDANCE_BREAKDOWN": "Desglose de asistencia",
      "LATENESS": "Retraso",
      "LABOUR_BREAKDOWN_FILE_EXPORT": "Exportación de archivos de desglose de la mano",
      "CATEGORY_ASYNC_FILE_EXPORTS": "Informes grandes",
      "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": "Exportación de archivos de desglose laboral",
      "PAYROLL_ASYNC_FILE_EXPORT": "Exportación de archivos de nómina",
      "RAW_COST_CONTROL_ASYNC_FILE_EXPORT": "Exportación de archivos de control de costos sin procesar"
    },
    "LATENESS": {
      "TITLE": "Retraso",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE_APPOINTMENT": "Sitio (cita previa)",
      "COLUMN_DEPT_APPOINTMENT": "Departamento (cita previa)",
      "COLUMN_INSTANCES": "Instancias",
      "COLUMN_VARIANCE": "Varianza promedio",
      "FILTER_DATE": "Fecha",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_USER": "Persona",
      "FILTER_OVERTIME": "Horas extras",
      "FILTER_OVERTIME_NA": "No reconocido",
      "FILTER_OVERTIME_APPROVED": "Aprobado",
      "FILTER_OVERTIME_REJECTED": "Rechazado",
      "FILTER_CLOCK_IN": "Relojes",
      "FILTER_CLOCK_OUT": "Reloj de salida",
      "FILTER_MINS_LATE": "minutos tarde",
      "FILTER_MINS_EARLY": "minutos antes",
      "LABEL_MINUTES": "minutos",
      "BUTTON_EXPORT": "Exportación",
      "NO_MATCH": "No hemos podido encontrar ningún dato que coincida con tus filtros."
    },
    "ATTENDANCE_BREAKDOWN": {
      "TITLE": "Desglose de asistencia",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE_APPOINTMENT": "Sitio (cita previa)",
      "COLUMN_DEPT_APPOINTMENT": "Departamento (cita previa)",
      "COLUMN_SITE_ATTRIBUTION": "Sitio (atribución)",
      "COLUMN_DEPT_ATTRIBUTION": "Departamento (atribución)",
      "COLUMN_DATE": "Fecha comercial",
      "COLUMN_SHIFT_TYPE": "Tipo de turno",
      "COLUMN_RECORD_TYPE": "Tipo de registro",
      "COLUMN_SCHEDULED": "Programado",
      "COLUMN_ACTUAL": "Actual",
      "COLUMN_VARIANCE": "Varianza",
      "COLUMN_CAPTURED_BY": "Capturado por",
      "COLUMN_EDITED_BY": "Editado por",
      "COLUMN_EDITED_DATE": "Fecha de edición",
      "COLUMN_OVERTIME_STATE": "Aprobación de horas extras",
      "COLUMN_OVERTIME_EDITED_BY": "Horas extras confirmadas por",
      "COLUMN_OVERTIME_EDITED_DATE": "Fecha confirmada de horas extras",
      "FILTER_DATE": "Fecha",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_USER": "Persona",
      "FILTER_OVERTIME": "Horas extras",
      "FILTER_OVERTIME_NA": "No reconocido",
      "FILTER_OVERTIME_APPROVED": "Aprobado",
      "FILTER_OVERTIME_REJECTED": "Rechazado",
      "FILTER_CLOCK_IN": "Relojes",
      "FILTER_CLOCK_OUT": "Reloj de salida",
      "FILTER_MINS_LATE": "minutos tarde",
      "FILTER_MINS_EARLY": "minutos antes",
      "BUTTON_EXPORT": "Exportación",
      "NO_MATCH": "No hemos podido encontrar ningún dato que coincida con tus filtros."
    },
    "DOCUMENTS_EXPORT": {
      "TITLE": "Exportación de documentos",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_TOGGLE_USER_ACTIVE": "Empleados activos",
      "FILTER_USER": "Persona",
      "FILTER_CATEGORY": "Categoría",
      "FILTER_ACKNOWLEDGEMENT": "Estado",
      "FILTER_OPTION_ACKNOWLEDGED": "Reconocido",
      "FILTER_OPTION_NOT_ACKNOWLEDGED": "No reconocido",
      "BUTTON_EXPORT": "Exportación",
      "COLUMN_USER_ID": "ID de empleado",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE": "Sitio",
      "COLUMN_DEPARTMENT": "Departamento",
      "COLUMN_CATEGORY": "Categoría",
      "COLUMN_FILE_NAME": "Nombre",
      "COLUMN_FILE_SIZE": "Tamaño del archivo (KB)",
      "COLUMN_UPLOADED_BY": "Subido por",
      "COLUMN_UPLOADED_DATE": "Fecha de subida",
      "COLUMN_EXPIRY_DATE": "Fecha de caducidad",
      "COLUMN_SIGNATURE_METHOD": "Método de firma",
      "COLUMN_EDITED_BY": "Editado por última vez por",
      "COLUMN_EDITED_DATE": "Fecha de última edición",
      "COLUMN_ACKNOWLEDGED": "Reconocido",
      "COLUMN_ACKNOWLEDGED_DATE": "Fecha reconocida",
      "COLUMN_NOTES": "Notas",
      "NO_MATCH": "No hemos podido encontrar ningún empleado para tus filtros."
    },
    "DOCUMENT_PRESENCE": {
      "TITLE": "Presencia de documentos",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_TOGGLE_USER_ACTIVE": "Empleados activos",
      "FILTER_CATEGORY": "Categoría",
      "BUTTON_EXPORT": "Exportación",
      "COLUMN_USER_ID": "ID de empleado",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE": "Sitio",
      "COLUMN_DEPARTMENT": "Departamento",
      "COLUMN_EMPLOYMENT_ACTIVE": "Empleo activo",
      "COLUMN_JOIN_DATE": "Fecha de incorporación",
      "COLUMN_LEAVE_DATE": "Fecha de salida",
      "COLUMN_DOCUMENTS_VALIDITY": "Documentos válidos",
      "NO_MATCH": "No hemos podido encontrar ningún empleado para tus filtros."
    },
    "SHIFT_TYPE_STREAM_BREAKDOWN": {
      "TITLE": "Desglose por tipo de turno",
      "FILTER_DATE": "Fecha",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_LAST_TWO_WEEKS": "Las dos últimas semanas",
      "JUMP_TO_LAST_MONTH": "El mes pasado",
      "FILTER_SITE": "Sitio/departamento",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "FILTER_DATA_SOURCE": "Fuente de datos",
      "FILTER_USER": "Persona",
      "FILTER_BEHAVIOUR": "Comportamiento",
      "BEHAVIOUR_SHIFT_TYPE": "Tipo de turno",
      "BEHAVIOUR_STREAM": "Transmitir",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE": "Sitio actual",
      "COLUMN_DEPARTMENT": "Departamento actual",
      "COLUMN_STAFF_GROUP": "Grupo de personal actual",
      "COLUMN_TOTAL": "Total",
      "ROW_TOTAL": "Total",
      "INFO_NO_DATA": "No hay registros que coincidan con tus filtros.",
      "INFO_NO_FILTER": "Selecciona un sitio o departamento arriba para empezar.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tarifa por hora",
      "PAY_AMOUNT_TYPE_DAILY": "Tarifa diaria",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salario anual",
      "DATA_SOURCE_SHIFTS": "Turnos",
      "DATA_SOURCE_MATCHED": "Higienizado",
      "DATE_ERROR_DIFF": "No puedes elegir más de 6 semanas. Restrinja el intervalo de fechas.",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "ERROR_400": "Sus parámetros hacen que se generen demasiados datos para un solo informe. Reduzca el alcance de su solicitud e inténtelo de nuevo.",
      "STREAM_UNALLOCATED": "Sin asignar",
      "FILTER_METRIC": "Valor",
      "METRIC_WAGES": "Coste salarial base",
      "METRIC_HOURS": "Horas pagadas"
    },
    "ABSENCE_DAYS_EXPORT": {
      "TITLE": "Exportación de días de ausencia",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "COLUMN_USER_ID": "ID de empleado",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE": "Sitio",
      "COLUMN_DEPARTMENT": "Departamento",
      "COLUMN_STAFF_GROUP": "Grupo de personal",
      "COLUMN_JOB_ROLE_NAME": "Función laboral",
      "COLUMN_DATE": "Fecha",
      "COLUMN_HOURS": "Horas",
      "COLUMN_HEADER_ID": "ID de encabezado",
      "COLUMN_ABSENCE_TYPE": "Tipo",
      "COLUMN_ABSENCE_TYPE_REASON": "Motivo",
      "COLUMN_STATUS": "Estado",
      "COLUMN_SCOPE": "Alcance",
      "COLUMN_START_DATE": "Fecha de inicio del encabezado",
      "COLUMN_END_DATE": "Fecha de finalización del encabezado",
      "COLUMN_START_TIME": "Hora de inicio",
      "COLUMN_END_TIME": "Hora de finalización",
      "COLUMN_TOTAL_HOURS": "Horas de cabecera",
      "COLUMN_TOTAL_DAYS": "Días de cabecera",
      "COLUMN_CANCELLED": "Cancelado",
      "FILTER_DATE": "Fecha",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_STATUS": "Estado",
      "FILTER_TYPE": "Tipo",
      "FILTER_USER": "Persona",
      "FILTER_TOGGLE_CANCELLED": "Incluir cancelado",
      "FILTER_TOGGLE_USER_ACTIVE": "Empleados activos",
      "BUTTON_EXPORT": "Exportación",
      "NO_MATCH": "No hemos podido encontrar ninguna ausencia que coincida con tus filtros."
    },
    "NEW_HIRE_TURNOVER": {
      "TITLE": "Rotación de nuevos empleados",
      "FILTER_DATE": "Fecha de incorporación",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_LAST_TWO_WEEKS": "Las dos últimas semanas",
      "JUMP_TO_LAST_MONTH": "El mes pasado",
      "FILTER_AGGREGATION": "Agregación",
      "AGGREGATION_ENTITY_GROUP": "Sitio/departamento",
      "AGGREGATION_POSITION": "Función laboral",
      "AGGREGATION_STAFF_GROUP": "Grupo de personal",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "DATE_ERROR_DIFF": "No puedes elegir más de 12 meses. Restrinja el intervalo de fechas.",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "COLUMN_AGGREGATION_LABEL": "Nombre",
      "COLUMN_JOINERS": "Carpinteros",
      "COLUMN_LEAVERS": "Los que abandonan",
      "COLUMN_TURNOVER_RATE": "Tasa de rotación",
      "DEPARTED_WITHIN": "Fecha de salida",
      "DAYS_LABEL": "dentro de {{count} días",
      "LABEL_TOTAL": "Total",
      "LABEL_GRAND_TOTAL": "Total general",
      "FILTER_LEAVER_REASON": "Motivo de abandono"
    },
    "COST_CONTROL_RAW_DATA": {
      "TITLE": "Exportación de datos sin procesar para control de costos",
      "ERROR_500": "No pudimos exportar los datos en ese momento. Vuelva a intentarlo.",
      "BUTTON_EXPORT": "Exportación",
      "LOADING_TEXT": "Se está generando tu exportación.",
      "METRIC_HOURS": "Horas",
      "METRIC_WAGES": "Salarios",
      "METRIC_SALES": "Ventas",
      "METRIC_COVERS": "Fundas",
      "SUBDIVISION_PREDICTION": "Predicciones",
      "SUBDIVISION_FORECAST": "Previsiones",
      "SUBDIVISION_ACTUAL": "actuales",
      "FILTER_METRICS": "Métricas",
      "FILTER_SUBDIVISIONS": "Subdivisiones",
      "FILTER_ENTITY": "Sitio/departamento",
      "FILTER_ENTITY_BUTTON": "Sitio/departamento",
      "FILTER_DATE": "Fecha",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_LAST_TWO_WEEKS": "Las dos últimas semanas",
      "JUMP_TO_LAST_MONTH": "El mes pasado",
      "CALCULATE_ACTUAL_CHECKBOX": "Calcule las horas y los salarios reales sobre la marcha, cuando los datos firmados no estén presentes.",
      "DATE_ERROR_DIFF": "No puedes elegir más de 2 meses. Restrinja el intervalo de fechas.",
      "CALCULATE_ACTUAL_ERROR": "Las horas/salarios reales no se pueden calcular sobre la marcha para más de una semana o quince sitios/departamentos."
    },
    "ABSENCE_EXPORT": {
      "TITLE": "Exportación de ausencias",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_USER_ID": "ID de empleado",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE": "Sitio",
      "COLUMN_DEPARTMENT": "Departamento",
      "COLUMN_STAFF_GROUP": "Grupo de personal",
      "COLUMN_JOB_ROLE_NAME": "Función laboral",
      "COLUMN_ABSENCE_TYPE": "Tipo",
      "COLUMN_ABSENCE_TYPE_REASON": "Motivo",
      "COLUMN_STATUS": "Estado",
      "COLUMN_SCOPE": "Alcance",
      "COLUMN_START_DATE": "Fecha de inicio",
      "COLUMN_END_DATE": "Fecha de finalización",
      "COLUMN_START_TIME": "Hora de inicio",
      "COLUMN_END_TIME": "Hora de finalización",
      "COLUMN_TOTAL_HOURS": "Horas",
      "COLUMN_TOTAL_DAYS": "Días",
      "COLUMN_REQUEST_NAME": "Solicitado por",
      "COLUMN_REQUEST_DATE": "Fecha de solicitud",
      "COLUMN_REQUEST_MESSAGE": "Mensaje de solicitud",
      "COLUMN_REVIEW_NAME": "Revisado por",
      "COLUMN_REVIEW_DATE": "Fecha de revisión",
      "COLUMN_REVIEW_MESSAGE": "Revisar mensaje",
      "COLUMN_EDITED_NAME": "Editado por última vez por",
      "COLUMN_EDITED_DATE": "Fecha de última edición",
      "COLUMN_CANCELLED": "Cancelado",
      "COLUMN_CANCELLED_NAME": "Cancelado por",
      "COLUMN_CANCELLED_DATE": "Fecha cancelada",
      "FILTER_DATE": "Fecha de inicio/finalización",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_STATUS": "Estado",
      "FILTER_TYPE": "Tipo",
      "FILTER_USER": "Persona",
      "FILTER_TOGGLE_CANCELLED": "Incluir cancelado",
      "FILTER_TOGGLE_USER_ACTIVE": "Empleados activos",
      "BUTTON_EXPORT": "Exportación",
      "NO_MATCH": "No hemos podido encontrar ninguna ausencia que coincida con tus filtros."
    },
    "ALLOWANCE_EXPORT": {
      "TITLE": "Exportación de subsidios",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_USER_ID": "ID de empleado",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE": "Sitio",
      "COLUMN_DEPARTMENT": "Departamento",
      "COLUMN_ABSENCE_TYPE": "Tipo de ausencia",
      "COLUMN_YEAR_STARTS": "Empieza el año",
      "COLUMN_EFFECTIVE_DATE": "Fecha de entrada en vigor",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Calendario de acumulación",
      "COLUMN_UNIT": "Unidad",
      "COLUMN_DEDUCTIBLE": "Deducible",
      "COLUMN_TOTAL_ACCRUED": "Total acumulado",
      "COLUMN_OPENING_BALANCE": "Saldo inicial",
      "COLUMN_BOOKED": "Reservado",
      "COLUMN_BOOKED_AND_ELAPSED": "Reservado y transcurrido",
      "COLUMN_REMAINING": "Restante",
      "COLUMN_ACCRUAL_ESTIMATE": "Estimación de devengo",
      "COLUMN_CARRIED_OVER": "Transportado",
      "COLUMN_OVERSPEND_LIMIT": "Gastar de más",
      "COLUMN_TOTAL_TO_ACCRUE": "Máximo a acumular",
      "COLUMN_ACCRUAL_RATE": "Anulación de la tasa de acumulación",
      "COLUMN_ADDED_NAME": "Añadido por",
      "COLUMN_ADDED_DATE": "Fecha añadida",
      "COLUMN_EDITED_NAME": "Editado por última vez por",
      "COLUMN_EDITED_DATE": "Fecha de última edición",
      "COLUMN_FINALISED": "Finalizado",
      "COLUMN_FINALISED_REFERENCE_DATE": "Fecha de referencia finalizada",
      "COLUMN_FINALISED_EDITED_NAME": "Finalizado por",
      "COLUMN_FINALISED_EDITED_DATE": "Finalizado el",
      "COLUMN_FINALISED_NOTES": "Notas finalizadas",
      "COLUMN_PROJECT_TOTAL_ACCRUED": "Predecir lo acumulado durante la reserva",
      "FILTER_DATE": "Fecha de entrada en vigor",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_TYPE": "Tipo de ausencia",
      "FILTER_SCHEDULE_OF_ACCRUAL": "Calendario de acumulación",
      "FILTER_USER": "Persona",
      "FILTER_TOGGLE_USER_ACTIVE": "Empleados activos",
      "BUTTON_EXPORT": "Exportación",
      "NO_MATCH": "No hemos podido encontrar ninguna asignación que coincida con tus filtros."
    },
    "UNTAKEN_HOLIDAY_COST": {
      "TITLE": "Costo de vacaciones no aprovechado",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "ERROR_400": "El intervalo de fechas de entrada en vigor es demasiado amplio, redúzcalo e inténtalo de nuevo.",
      "BUTTON_EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "FILTER_DATE": "Fecha efectiva de la asignación",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_TOGGLE_USER_ACTIVE": "Empleados activos",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE": "Sitio",
      "COLUMN_DEPARTMENT": "Departamento",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Calendario de acumulación",
      "COLUMN_UNIT": "Unidad",
      "COLUMN_DEDUCTIBLE": "Deducible",
      "COLUMN_TOTAL_ACCRUED": "Total acumulado",
      "COLUMN_BOOKED": "Reservado",
      "COLUMN_BOOKED_AND_ELAPSED": "Transcurrido",
      "COLUMN_UNTAKEN": "Intomado",
      "COLUMN_PAY_AMOUNT": "Monto",
      "COLUMN_PAY_AMOUNT_TYPE": "Tipo",
      "COLUMN_PAY_PROJECTED_COST": "Coste proyectado",
      "COLUMN_HEADER_ALLOWANCE": "Subsidio",
      "COLUMN_HEADER_PAY": "Pagar",
      "LABEL_DEDUCTIBLE_YES": "Sí",
      "LABEL_DEDUCTIBLE_NO": "No",
      "TEXT_GRAND_TOTAL": "Total general"
    },
    "EMPLOYMENT_CHANGES": {
      "TITLE": "Cambios en el empleo",
      "MODEL_APPOINTMENT": "Cita",
      "MODEL_PAY": "Pagar",
      "MODEL_EMPLOYEE": "Empleado",
      "PROPERTY_EMPLOYEE_FIRST_NAME": "Nombre",
      "PROPERTY_EMPLOYEE_LAST_NAME": "Apellidos",
      "PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET": "Promedio de horas establecido manualmente",
      "PROPERTY_APPOINTMENT_ENTITY_GROUP": "Sitio",
      "PROPERTY_APPOINTMENT_ENTITY": "Departamento",
      "PROPERTY_APPOINTMENT_GROUP": "Grupo de personal",
      "PROPERTY_APPOINTMENT_POSITION": "Función laboral",
      "PROPERTY_APPOINTMENT_COMPANY": "Empresa",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE": "Obligación mínima",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT": "Obligación mínima (unidad)",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD": "Obligación mínima (periodo)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE": "Obligación máxima",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT": "Obligación máxima (unidad)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD": "Obligación máxima (periodo)",
      "PROPERTY_APPOINTMENT_LINE_MANAGER": "Gerente de línea",
      "PROPERTY_APPOINTMENT_PAYROLL_CALENDAR": "Calendario de nóminas",
      "PROPERTY_PAY_AMOUNT": "Monto",
      "PROPERTY_PAY_AMOUNT_TYPE": "Tipo de importe",
      "PROPERTY_PAY_UPLIFT": "Aumento salarial",
      "FILTER_DATE": "Fecha",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_LAST_TWO_WEEKS": "Las dos últimas semanas",
      "JUMP_TO_LAST_MONTH": "El mes pasado",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_PROPERTY": "Propiedad",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "DATE_ERROR_DIFF": "No puedes elegir más de 12 meses. Restrinja el intervalo de fechas.",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE": "Sitio",
      "COLUMN_DEPARTMENT": "Departamento",
      "COLUMN_CHANGE": "Cambiar",
      "COLUMN_EFFECTIVE_DATE": "Efectivo",
      "COLUMN_PROPERTY": "Propiedad",
      "COLUMN_NEW_VALUE": "Nuevo valor",
      "COLUMN_OLD_VALUE": "Valor antiguo",
      "COLUMN_EDITED_BY": "Modificado por",
      "COLUMN_EDITED_ON": "Fecha cambiada"
    },
    "ANNIVERSARY": {
      "TITLE": "Aniversarios",
      "FILTER_DATE": "Fecha",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_LAST_TWO_WEEKS": "Las dos últimas semanas",
      "JUMP_TO_LAST_MONTH": "El mes pasado",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_TYPE": "Aniversario",
      "TYPE_BIRTHDAY": "Cumpleaños",
      "TYPE_EMPLOYMENT": "Aniversario de trabajo",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "DATE_ERROR_DIFF": "No puedes elegir más de 12 meses. Restrinja el intervalo de fechas.",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE": "Sitio",
      "COLUMN_DEPARTMENT": "Departamento",
      "COLUMN_TYPE": "Aniversario",
      "COLUMN_YEARS": "Años",
      "COLUMN_DATE": "Fecha"
    },
    "STAFF_TURNOVER": {
      "TITLE": "Rotación de personal",
      "FILTER_DATE": "Fecha",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_LAST_TWO_WEEKS": "Las dos últimas semanas",
      "JUMP_TO_LAST_MONTH": "El mes pasado",
      "FILTER_AGGREGATION": "Agregación",
      "AGGREGATION_ENTITY_GROUP": "Sitio/departamento",
      "AGGREGATION_POSITION": "Función laboral",
      "AGGREGATION_STAFF_GROUP": "Grupo de personal",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "DATE_ERROR_DIFF": "No puedes elegir más de 12 meses. Restrinja el intervalo de fechas.",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "COLUMN_AGGREGATION_LABEL": "Nombre",
      "COLUMN_HEADCOUNT_START": "Número de empleados (inicio)",
      "COLUMN_HEADCOUNT_END": "Número de empleados (fin)",
      "COLUMN_JOINERS": "Carpinteros",
      "COLUMN_REHIRES": "Recontrataciones",
      "COLUMN_LEAVERS": "Los que abandonan",
      "COLUMN_TRANSFERS_IN": "Transferencias en",
      "COLUMN_TRANSFERS_OUT": "Transferencias de salida",
      "COLUMN_ATTRITION_RATE": "Tasa de deserción",
      "COLUMN_AVERAGE_SERVICE": "Servicio promedio",
      "LABEL_TOTAL": "Total",
      "LABEL_GRAND_TOTAL": "Total general",
      "FILTER_LEAVER_REASON": "Motivo de abandono"
    },
    "DOCUMENT_SIGNATURES": {
      "TITLE": "Firma de documentos",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "INFO_NO_DATA": "No hay registros que coincidan con tus filtros.",
      "LABEL_METHOD_MANUAL": "Manual",
      "LABEL_METHOD_ELECTRONIC": "Electrónica",
      "LABEL_STATUS_PENDING": "Pendiente",
      "LABEL_STATUS_REJECTED": "Rechazado",
      "LABEL_STATUS_SIGNED": "Firmado",
      "LABEL_STATUS_CANCELLED": "Cancelado",
      "LABEL_RESPONSE_NONE": "Esperando respuesta",
      "LABEL_RESPONSE_REJECTED": "Rechazado",
      "LABEL_RESPONSE_SIGNED": "Firmado",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_RECIPIENT_USER": "Receptor",
      "FILTER_ADDED_USER": "Remitente",
      "FILTER_METHOD": "Método de firma",
      "FILTER_STATUS": "Estado",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "COLUMN_RECIPIENT": "Receptor",
      "COLUMN_EMPLOYER": "Contraparte",
      "COLUMN_DOCUMENT": "Documento",
      "COLUMN_DOCUMENT_CATEGORY": "Categoría de destino",
      "COLUMN_METHOD": "Método",
      "COLUMN_STATUS": "Estado",
      "COLUMN_ADDED_USER": "Remitente",
      "COLUMN_ADDED_DATE": "Fecha",
      "EMPLOYER_NOT_SET": "No se requiere",
      "VIEW_MODAL": {
        "TITLE": "Documento a firmar",
        "LABEL_FILE": "Documento",
        "LABEL_EXPIRY": "Caducidad del documento",
        "LABEL_TEMPLATE": "Plantilla utilizada",
        "LABEL_CATEGORY": "Categoría de destino",
        "LABEL_ADDED_BY": "Añadido por",
        "LABEL_METHOD": "Método",
        "TEMPLATE_NOT_USED": "Ninguna",
        "EXPIRY_NOT_APPLICABLE": "No aplicable",
        "BUTTON_CLOSE": "Cerrar",
        "HEADING_SIGNERS": "Firmantes",
        "EMPLOYEE": "Empleado",
        "EMPLOYER": "Empleador",
        "COLUMN_NAME": "Nombre",
        "COLUMN_ROLE": "Rol",
        "COLUMN_STATUS": "Estado",
        "COLUMN_DATE": "Fecha",
        "LABEL_OPTIONS": "Opciones",
        "OPTION_REMINDER": "Enviar un recordatorio",
        "OPTION_CANCEL": "Cancelar documento",
        "OPTION_UPLOAD": "Subir documento firmado",
        "CANCEL_ERROR_500": "No pudimos cancelar el documento en ese momento.",
        "CANCEL_SUCCESS": "Se ha cancelado el documento.",
        "REMINDER_ERROR_500": "No pudimos enviar un recordatorio en ese momento.",
        "REMINDER_ERROR_400": "Ya se ha enviado un recordatorio recientemente. Espere un momento antes de enviar otro recordatorio.",
        "REMINDER_SUCCESS": "¡Lo tengo! Le enviaremos un recordatorio por correo electrónico ahora.",
        "DOWNLOAD_ERROR_500": "No pudimos descargar el archivo en ese momento. Vuelve a intentarlo o ponte en contacto con nosotros si el problema persiste.",
        "LABEL_DOWNLOAD": "Descargar",
        "OPTION_DOWNLOAD_ORIGINAL": "Descargar original",
        "OPTION_DOWNLOAD_SIGNED_COPY": "Descargar copia firmada"
      }
    },
    "WORKING_TIME_VIOLATIONS": {
      "TITLE": "Infracciones del tiempo de trabajo",
      "FILTER_DATE": "Fecha",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_LAST_TWO_WEEKS": "Las dos últimas semanas",
      "JUMP_TO_LAST_MONTH": "El mes pasado",
      "FILTER_SITE": "Sitio/departamento",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "COLUMN_SITE_DEPARTMENT": "Sitio/departamento",
      "COLUMN_VIOLATION_HOURS": "Horas de trabajo",
      "COLUMN_VIOLATION_HOURS_TOOLTIP": "El número de casos en los que un empleado superó las 48 horas de trabajo (con un promedio de 17 semanas).",
      "COLUMN_VIOLATION_DAYS_OFF": "Días libres",
      "COLUMN_VIOLATION_DAYS_OFF_TOOLTIP": "El número de casos en los que un empleado tuvo menos de 2 días libres (en un período de 2 semanas).",
      "COLUMN_VIOLATION_REST": "Descanso",
      "COLUMN_VIOLATION_REST_TOOLTIP": "El número de casos en los que un empleado descansó menos de 11 horas después de una jornada laboral.",
      "COLUMN_VIOLATION_U18_DAYS_OFF": "U18: Días libres",
      "COLUMN_VIOLATION_U18_DAYS_OFF_TOOLTIP": "El número de casos en los que un empleado menor de 18 años tuvo menos de 2 días libres consecutivos (en una semana determinada).",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH": "U18: Turno largo",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH_TOOLTIP": "El número de casos en los que un empleado menor de 18 años tuvo un turno de más de 8 horas.",
      "COLUMN_VIOLATION_U18_REST": "U18: Descanso",
      "COLUMN_VIOLATION_U18_REST_TOOLTIP": "El número de casos en los que un empleado menor de 18 años descansó menos de 11 horas después de una jornada laboral.",
      "COLUMN_TOTAL": "Total",
      "ROW_TOTAL": "Total",
      "ROW_GRAND_TOTAL": "Total general",
      "INFO_NO_FILTER": "Selecciona un sitio o departamento arriba para empezar.",
      "DATE_ERROR_DIFF": "No puedes elegir más de 12 meses. Restrinja el intervalo de fechas.",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "VIOLATION_TYPE_1_DESC": "No puede superar el promedio de 48 horas de trabajo durante 17 semanas",
      "VIOLATION_TYPE_2_DESC": "No puede tener menos de 2 días libres en un período de 2 semanas",
      "VIOLATION_TYPE_3_DESC": "Menores de 18 años: deben tener 2 días libres consecutivos por semana",
      "VIOLATION_TYPE_4_DESC": "Menores de 18 años: turno de más de 8 horas",
      "VIOLATION_TYPE_5_DESC": "Menores de 18 años: menos de 12 horas de descanso después de la jornada laboral",
      "VIOLATION_TYPE_6_DESC": "Menos de 11 horas de descanso después de la jornada laboral",
      "VIOLATION_TYPE_1": "Demasiadas horas de trabajo (en promedio)",
      "VIOLATION_TYPE_2": "Días libres insuficientes",
      "VIOLATION_TYPE_3": "Menores de 18 años: días libres insuficientes",
      "VIOLATION_TYPE_4": "Menores de 18 años: turno demasiado largo",
      "VIOLATION_TYPE_5": "Menores de 18 años: descanso insuficiente entre turnos",
      "VIOLATION_TYPE_6": "Descanso insuficiente entre turnos",
      "FILTER_VIOLATION_TYPE": "Tipo de infracción",
      "FILTER_PER_PAGE": "Filas por página",
      "DETAIL_COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "DETAIL_COLUMN_HR_ID": "ID DE HORA",
      "DETAIL_COLUMN_PAYROLL_ID": "ID de nómina",
      "DETAIL_COLUMN_FIRST_NAME": "Nombre",
      "DETAIL_COLUMN_LAST_NAME": "Apellidos",
      "DETAIL_COLUMN_SITE": "Sitio",
      "DETAIL_COLUMN_DEPARTMENT": "Departamento",
      "DETAIL_COLUMN_VIOLATION": "Violación",
      "DETAIL_COLUMN_DATE_FROM": "Fecha desde",
      "DETAIL_COLUMN_DATE_TO": "Fecha hasta",
      "DETAIL_COLUMN_VALUE": "Valor",
      "DETAIL_LABEL_WORKING_HOURS": "horas de trabajo",
      "DETAIL_LABEL_WORKING_DAYS": "días laborables",
      "DETAIL_LABEL_HOURS_REST": "horas de descanso",
      "DETAIL_INFO_NO_RECORDS": "¡No tienes ninguna infracción de horario laboral, excelente trabajo!",
      "DETAIL_INFO_EMPTY_FILTER_RESULT": "No hay infracciones que coincidan con tus filtros."
    },
    "CONTRACTUAL_OBLIGATION_SUMMARY": {
      "TITLE": "Resumen de obligaciones contractuales",
      "FILTER_DATE": "Fecha",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_LAST_TWO_WEEKS": "Las dos últimas semanas",
      "JUMP_TO_LAST_MONTH": "El mes pasado",
      "FILTER_SITE": "Sitio/departamento",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "FILTER_DATA_SOURCE": "Fuente de datos",
      "FILTER_USER": "Persona",
      "COLUMN_GROUP_TOTAL": "Total (pagado)",
      "COLUMN_GROUP_OBLIGATION": "Obligación contractual",
      "COLUMN_GROUP_ANALYSIS_META": "Analizado a lo largo",
      "COLUMN_GROUP_ANALYSIS": "Análisis",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE": "Sitio",
      "COLUMN_DEPARTMENT": "Departamento",
      "COLUMN_DAYS_WORKED": "Días",
      "COLUMN_PAID_HOURS": "Horas",
      "COLUMN_AVERAGE_HOURS": "Promedio",
      "COLUMN_AVERAGE_HOURS_TOOLTIP": "El promedio de horas trabajadas por día.",
      "COLUMN_OBLIGATION_VALUE": "Valor",
      "COLUMN_OBLIGATION_UNIT": "Unidad",
      "COLUMN_OBLIGATION_PERIOD": "Período",
      "COLUMN_ANALYSIS_START": "Comenzar",
      "COLUMN_ANALYSIS_END": "Finalizar",
      "COLUMN_ANALYSIS_PERIODS": "# Periodos",
      "COLUMN_ANALYSIS_PERIODS_TOOLTIP": "El número de semanas o meses que se han analizado.",
      "COLUMN_ANALYSIS_AVERAGE": "Promedio",
      "COLUMN_ANALYSIS_AVERAGE_TOOLTIP": "El número promedio de horas o días trabajados.",
      "COLUMN_ANALYSIS_DIFFERENCE": "Diferencial.",
      "COLUMN_ANALYSIS_DIFFERENCE_TOOLTIP": "La diferencia entre las horas/días mínimos que deberían haberse trabajado (la obligación contractual multiplicada por el número de períodos) y lo que realmente se trabajó (el total de horas o días).",
      "INFO_NO_DATA": "No hay registros que coincidan con tus filtros.",
      "INFO_NO_FILTER": "Selecciona un sitio o departamento arriba para empezar.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tarifa por hora",
      "PAY_AMOUNT_TYPE_DAILY": "Tarifa diaria",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salario anual",
      "DATA_SOURCE_SHIFTS": "Turnos",
      "DATA_SOURCE_ATTENDANCE": "Asistencia",
      "DATA_SOURCE_MATCHED": "Higienizado",
      "CONTRACTUAL_UNIT_HOURS": "Horas",
      "CONTRACTUAL_UNIT_DAYS": "Días",
      "CONTRACTUAL_PERIOD_WEEK": "Semana",
      "CONTRACTUAL_PERIOD_MONTH": "Mes",
      "CONTRACTUAL_PERIOD_WEEKLY": "Semanalmente",
      "CONTRACTUAL_PERIOD_MONTHLY": "Mensualmente",
      "DATE_ERROR_DIFF": "No puedes elegir más de 14 semanas. Restrinja el intervalo de fechas.",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo."
    },
    "LABOUR_BREAKDOWN": {
      "TITLE": "Desglose laboral",
      "FILE_EXPORT_TITLE": "Exportación de archivos de desglose de la mano",
      "FILTER_DATE": "Fecha",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_LAST_TWO_WEEKS": "Las dos últimas semanas",
      "JUMP_TO_LAST_MONTH": "El mes pasado",
      "FILTER_SITE": "Sitio/departamento",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "FILTER_DATA_SOURCE": "Fuente de datos",
      "FILTER_USER": "Persona",
      "FILTER_BEHAVIOUR": "Comportamiento",
      "BEHAVIOUR_COST_CONTROL": "Control de costos",
      "BEHAVIOUR_PAYROLL": "Nómina",
      "FILTER_SHIFT_TYPE": "Tipo de turno",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE_APPOINTMENT": "Sitio (cita previa)",
      "COLUMN_DEPT_APPOINTMENT": "Departamento (cita previa)",
      "COLUMN_EVENT_TYPE": "Tipo de evento",
      "COLUMN_SHIFT_TYPE": "Tipo de turno",
      "COLUMN_SITE_ATTRIBUTION": "Sitio (atribución)",
      "COLUMN_DEPT_ATTRIBUTION": "Departamento (atribución)",
      "COLUMN_START_TIME": "Comenzar",
      "COLUMN_END_TIME": "Finalizar",
      "COLUMN_PAID_HOURS": "Horas pagadas",
      "COLUMN_UNPAID_HOURS": "Horas no remuneradas",
      "COLUMN_BASE_PAY": "Salario base",
      "COLUMN_ROLLED_UP_HOLIDAY": "Vacaciones acumuladas",
      "COLUMN_TAXES": "Impuestos",
      "COLUMN_WAGE_UPLIFT": "Aumento salarial",
      "COLUMN_TOTAL_COST": "Coste total",
      "INFO_NO_DATA": "No hay registros que coincidan con tus filtros.",
      "INFO_NO_FILTER": "Selecciona un sitio o departamento arriba para empezar.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tarifa por hora",
      "PAY_AMOUNT_TYPE_DAILY": "Tarifa diaria",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salario anual",
      "EVENT_TYPE_SHIFT": "Turno",
      "EVENT_TYPE_ABSENCE": "Ausencia",
      "EVENT_TYPE_SALARY": "Salario",
      "EVENT_TYPE_TAXES": "Impuestos",
      "DATA_SOURCE_SHIFTS": "Turnos",
      "DATA_SOURCE_ATTENDANCE": "Asistencia",
      "DATA_SOURCE_MATCHED": "Higienizado",
      "DATE_ERROR_DIFF": "No puedes elegir más de 14 semanas. Restrinja el intervalo de fechas.",
      "DATE_WARNING_WEEKS": "Has seleccionado un intervalo de fechas que no coincide con las semanas naturales. Los impuestos de algunos empleadores se calculan utilizando umbrales semanales, por lo que los impuestos que se muestran aquí pueden ser artificialmente más bajos que los reales. El pago base y los días festivos acumulados siguen siendo correctos, pero alinea tus fechas con las semanas naturales para ver los impuestos y el costo total correctos.",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "ERROR_400": "Sus parámetros hacen que se generen demasiados datos para un solo informe. Reduzca el alcance de su solicitud e inténtelo de nuevo."
    },
    "LABOUR_BREAKDOWN_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Genere un informe por correo electrónico.",
      "REPORT_GENERATED": "Su informe se está generando y se le enviará por correo electrónico cuando esté completo."
    },
    "PAYROLL_EXPORT": {
      "TITLE": "Exportación de nóminas",
      "FILTER_DATE": "Fecha",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_LAST_TWO_WEEKS": "Las dos últimas semanas",
      "JUMP_TO_LAST_MONTH": "El mes pasado",
      "FILTER_SITE": "Sitio/departamento",
      "EXPORT": "Exportación",
      "EXPORT_PAYROLL_SOFTWARE": "Software de nómina",
      "EXPORT_PRINT": "Imprimir",
      "FILTER_AGGREGATION": "Segmentación",
      "FILTER_ABSENCE_TYPES": "Tipos de ausencias",
      "FILTER_PAY_ELEMENT_TYPES": "Tipos de elementos de pago",
      "FILTER_USER": "Persona",
      "FILTER_COMPANY": "Compañía",
      "TOGGLE_SHOW_ROLLED_UP_HOLIDAY": "Mostrar vacaciones acumuladas",
      "COLUMN_ID": "CARNÉ DE IDENTIDAD",
      "COLUMN_HR_ID": "ID DE HORA",
      "COLUMN_PAYROLL_ID": "ID de nómina",
      "COLUMN_FIRST_NAME": "Nombre",
      "COLUMN_LAST_NAME": "Apellidos",
      "COLUMN_SITE_ATTRIBUTION": "Sitio (atribución)",
      "COLUMN_DEPT_ATTRIBUTION": "Departamento (atribución)",
      "COLUMN_SITE_APPOINTMENT": "Sitio (cita previa)",
      "COLUMN_DEPT_APPOINTMENT": "Departamento (cita previa)",
      "COLUMN_GROUP": "Grupo",
      "COLUMN_POSITION": "Función laboral",
      "COLUMN_DAYS_WORKED": "Días trabajados",
      "COLUMN_PAY_RATE": "Tasa de pago",
      "COLUMN_AMOUNT_TYPE": "Tipo de importe",
      "COLUMN_PAY_TYPE": "Tipo de pago",
      "COLUMN_PAID_SHIFT_HOURS": "Horas de turno pagadas",
      "COLUMN_UNPAID_SHIFT_HOURS": "Horas de turno no remuneradas",
      "COLUMN_BASE_PAY": "Salario base",
      "COLUMN_ROLLED_UP_HOLIDAY": "Vacaciones acumuladas",
      "COLUMN_ABSENCE_DAYS": "{{label}} días",
      "COLUMN_ABSENCE_HOURS": "{{label}} horas",
      "INFO_NO_DATA": "No hay registros que coincidan con tus filtros. ¿Has firmado? No olvides que este informe solo usa datos firmados.",
      "INFO_NO_FILTER": "Selecciona un sitio o departamento arriba para empezar.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tarifa por hora",
      "PAY_AMOUNT_TYPE_DAILY": "Tarifa diaria",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salario anual",
      "AGGREGATION_OPTION_ATTRIBUTION": "El sitio/departamento al que se atribuyeron las horas",
      "AGGREGATION_OPTION_APPOINTMENT": "El sitio o departamento en el que se encontraba la persona en ese momento",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "ABSENCE_TYPE_DELETED_SUFFIX": "Eliminado",
      "DATE_ERROR_MIN": "Selecciona una fecha posterior al 12 de diciembre de 2018.",
      "DATE_ERROR_DIFF": "No puedes elegir más de 6 meses. Restrinja el intervalo de fechas.",
      "EXPORT_MODAL": {
        "TITLE": "Exportación a software de nómina",
        "LABEL_EXPORT_TO": "Exportar a",
        "BUTTON_DOWNLOAD": "Descargar",
        "BUTTON_CLOSE": "Cerrar",
        "SUCCESS": "La exportación se descargó correctamente.",
        "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo."
      },
      "SIGN_OFF_STATUS_COMPLETE": "Totalmente firmado",
      "SIGN_OFF_STATUS_NONE": "No firmado",
      "SIGN_OFF_STATUS_PARTIAL": "Firmado parcialmente",
      "SIGN_OFF_STATUS_DRAWER": {
        "TITLE": "Estado de cierre de sesión",
        "BACK_TO_DATES": "Volver a las fechas"
      },
      "FILTER_PAYROLL_CALENDAR": "Calendario de nóminas",
      "FILTER_PAY_RUN": "Corrida salarial"
    },
    "ROTA_PROGRESS": {
      "TITLE": "Progreso de rotación",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_NEXT_WEEK": "La semana que viene",
      "FILTER_SITE": "Sitio/departamento",
      "LIFECYCLE_DRAFT": "Borrador",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Marcado para su aprobación",
      "LIFECYCLE_PUBLISHED": "Publicado",
      "INCLUDE_NO_DRAFT": "Mostrar sitios sin borrador",
      "NO_DRAFT": "Sin borrador",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "COLUMN_SITE": "Sitio/departamento",
      "COLUMN_LIFECYCLE": "Estado",
      "COLUMN_SCHEDULED_HOURS": "Horas programadas",
      "COLUMN_SCHEDULED_WAGES": "Salarios programados",
      "COLUMN_FORECAST_SALES": "Previsión de ventas",
      "COLUMN_WAGE_PERCENTAGE": "% de salario",
      "ROW_GRAND_TOTAL": "Total general",
      "TOOLTIP_BUDGET": "Presupuesto",
      "INFO_NO_DATA": "No hay sitios ni departamentos que coincidan con tus filtros.",
      "INFO_NO_FILTER": "Selecciona un sitio o departamento arriba para empezar.",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo."
    },
    "SIGN_OFF_PROGRESS": {
      "TITLE": "Progreso de la firma",
      "JUMP_TO": "Saltar a",
      "JUMP_TO_LAST_WEEK": "La semana pasada",
      "JUMP_TO_THIS_WEEK": "Esta semana",
      "JUMP_TO_NEXT_WEEK": "La semana que viene",
      "FILTER_SITE": "Sitio/departamento",
      "FILTER_STATUS": "Estado de cierre de sesión",
      "STATUS_NO_ROTA": "Sin rotación",
      "STATUS_INCOMPLETE": "Incompleto",
      "STATUS_REPORTING_DATE": "Fecha de presentación de informes",
      "STATUS_COMPLETE": "Completa",
      "STATUS_TOOLTIP_NO_ROTA": "No hay ninguna rotación publicada esta semana.",
      "STATUS_TOOLTIP_INCOMPLETE": "No se completó la firma de todos los días de esta semana.",
      "STATUS_TOOLTIP_REPORTING_DATE": "Se ha completado la firma de los días anteriores a la fecha de presentación de su informe, inclusive.",
      "STATUS_TOOLTIP_COMPLETE": "Se ha completado la firma de todos los días de esta semana.",
      "TOGGLE_COMPARAND": "Comparando",
      "TOGGLE_COMPARAND_FORECAST": "Previsión",
      "TOGGLE_COMPARAND_BUDGET": "Presupuesto",
      "EXPORT": "Exportación",
      "EXPORT_PRINT": "Imprimir",
      "COLUMN_WAGES": "Salarios",
      "COLUMN_SALES": "Ventas",
      "COLUMN_WAGE_PERCENTAGE": "% de salario",
      "COLUMN_HOURS": "Horas",
      "COLUMN_SITE": "Sitio/departamento",
      "COLUMN_STATUS": "Estado",
      "COLUMN_FORECAST": "Previsión",
      "COLUMN_BUDGET": "Presupuesto",
      "COLUMN_ACTUAL": "Actual",
      "ROW_GRAND_TOTAL": "Total general",
      "INFO_NO_DATA": "No hay sitios ni departamentos que coincidan con tus filtros.",
      "INFO_NO_FILTER": "Selecciona un sitio o departamento arriba para empezar.",
      "ERROR_500": "No pudimos cargar tu informe en ese momento. Vuelva a intentarlo.",
      "STATUS_MODAL": {
        "REVERSE_CONFIRM": "¿Está seguro de que desea anular esta firma?\n\nEsto desbloqueará los registros de turnos y asistencia en estas fechas para que se puedan realizar cambios.",
        "REVERSE_SUCCESS": "La finalización de sesión se ha revertido correctamente.",
        "REVERSE_ERROR": "No pudimos anular la aprobación en ese momento. Vuelva a intentarlo.",
        "TITLE": "Estado de cierre de sesión",
        "COLUMN_DATE": "Fecha",
        "COLUMN_STATUS": "Estado",
        "COLUMN_OPTIONS": "Opciones",
        "STATUS_NO_SIGN_OFF": "Sin firma",
        "STATUS_SIGN_OFF": "Firmado",
        "BUTTON_REVERSE_DAY": "Invertir",
        "BUTTON_REVERSE_WEEK": "Invertir toda la semana",
        "BUTTON_CLOSE": "Cerrar"
      }
    },
    "TRONC_STATUS": {
      "TITLE": "Estado de Tronc",
      "LIFECYCLE_DRAFT": "Borrador",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Marcado para su aprobación",
      "LIFECYCLE_LOCKED": "Bloqueado",
      "INCLUDE_NO_HEADER": "Mostrar sitios sin borrador",
      "NO_HEADER": "Sin borrador",
      "COLUMN_SITE": "Sitio/departamento",
      "COLUMN_LIFECYCLE": "Estado",
      "COLUMN_RECEIVED": "Total recibido",
      "COLUMN_PAID_OUT": "Total desembolsado",
      "COLUMN_EDITED_WHO": "Editado por",
      "COLUMN_EDITED_WHEN": "Cuándo",
      "INFO_NO_DATA": "No hay sitios ni departamentos de Tronc que coincidan con sus filtros.",
      "ERROR_500": "En ese momento no pudimos cargar Tronc. Por favor, inténtelo de nuevo."
    },
    "CURRENTLY_CLOCKED_IN": {
      "TITLE": "Personas actualmente recluidas",
      "NAME": "Nombre",
      "SITE": "Sitio/departamento",
      "POSITION": "Función laboral",
      "CLOCK_IN_TIME": "Hora de entrada",
      "NOBODY_CLOCKED_IN": "¡No hay nadie registrado ahora mismo!",
      "ERROR_500": "No pudimos cargar el informe en ese momento. Vuelva a intentarlo."
    },
    "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Generar y enviar informe por correo electrónico.",
      "REPORT_GENERATED": "Su informe se está generando y se le enviará por correo electrónico cuando esté completo.",
      "FILTER_DATE": "Fecha",
      "FILTER_ENTITY": "Sitio/departamento",
      "FILTER_PAY_TYPES": "Tipos de pago",
      "FILTER_DATA_SOURCE": "Fuente de datos",
      "FILTER_USER": "Persona",
      "FILTER_EVENT_TYPES": "Tipos de evento",
      "FILTER_BEHAVIOUR": "Comportamiento",
      "FILTER_SHIFT_TYPE": "Tipo de turno",
    },
    "PAYROLL_EXPORT_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Generar y enviar informe por correo electrónico.",
      "REPORT_GENERATED": "Su informe se está generando y se le enviará por correo electrónico cuando esté completo.",
      "FILTER_PAYROLL_CALENDAR": "Calendario de nómina",
      "FILTER_PAY_RUN": "Ejecución de nómina",
      "FILTER_DATE": "Fecha",
      "FILTER_ENTITY": "Sitio/departamento",
      "FILTER_AGGREGATION_OPTIONS": "Segmentación",
      "FILTER_USER": "Persona",
      "FILTER_ABSENCE_TYPES": "Tipos de ausencia",
      "FILTER_PAY_ELEMENT_TYPES": "Tipos de elemento de pago",
      "FILTER_PAY_AMOUNT_TYPE": "Tipo de cantidad de pago",
      "FILTER_ROLLED_UP_HOLIDAY": "Feriado acumulado",
      "FILTER_COMPANY": "Compañía",
    },
  },
  "ROTA_MANAGEMENT": {
    "DASHBOARD": {
      "INDEX": {
        "TITLE": "Tablero",
        "CHECKLIST": "Lista de verificación de rotación",
        "LABOUR_SPEND": "Gasto laboral"
      },
      "LABOUR_SPEND_TAB": {
        "TITLE": "Panorama laboral",
        "SHOW_ACTUAL_TIP": "Para los días de esta semana que aún no han transcurrido, proyecta las ventas previstas y los salarios/horas programados en los totales reales",
        "SHOW_ACTUAL_TEXT": "De la previsión del proyecto a la realidad",
        "FORECAST": "Previsión",
        "ACTUAL": "Actual",
        "BUDGET": "Presupuesto",
        "SALES": "Ventas",
        "WAGES": "Salarios",
        "HOURS": "Horas",
        "WAGE_PERCENT": "% de salario",
        "LABOUR_COMP": "Comparación laboral",
        "ACTUAL_PROJECTED": "Efectivo (proyectado)",
        "TODAYS_VS_SCHEDULED": "La asistencia de hoy frente a la programada"
      },
      "WTD_VIOLATIONS_MODAL": {
        "TITLE": "Infracciones del tiempo de trabajo",
        "BODY": "Estas son las ocasiones en las que hemos descubierto que sus horarios infringen las normas sobre el tiempo de trabajo.",
        "TO": "a ",
        "ALL": "Todas las infracciones",
        "TOO_MANY": "Demasiadas horas de trabajo (en promedio)",
        "INSUF_DAYS": "Días libres insuficientes",
        "INSUF_REST": "Descanso insuficiente entre turnos",
        "UNDER_18_DAYS": "Menores de 18 años: días libres insuficientes",
        "UNDER_18_LONG": "Menores de 18 años: turno demasiado largo",
        "UNDER_18_REST": "Menores de 18 años: descanso insuficiente entre turnos",
        "STAFF_MEMBER": "Miembro del personal",
        "VIOLATION": "Violación",
        "VALUE": "Valor",
        "NO_VIOLATIONS": "¡No tienes ninguna infracción de horario laboral, excelente trabajo!",
        "NO_FILTER_VIOLATIONS": "No hay infracciones que coincidan con tus filtros.",
        "AVE_48_OVER_17": "No puede superar el promedio de 48 horas de trabajo durante 17 semanas",
        "LESS_2_DAYS_2_WEEKS": "No puede tener menos de 2 días libres en un período de 2 semanas",
        "UNDER_18_2_PER_WEEK": "Menores de 18 años: deben tener 2 días libres consecutivos por semana",
        "UNDER_18_LONGER_8": "Menores de 18 años: turno de más de 8 horas",
        "UNDER_18_LESS_12_REST": "Menores de 18 años: menos de 12 horas de descanso después de la jornada laboral",
        "LESS_11_WEEK": "Menos de 11 horas de descanso después de la jornada laboral",
        "WORKING_HOURS": " horas de trabajo",
        "WORKING_DAYS": " días laborables",
        "HOURS_REST": " horas de descanso",
        "RECALC": "Volver a calcular",
        "LOADING": "Cargando...",
        "CLOSE": "Cerrar"
      }
    },
    "LOCATIONS": {
      "ADD_LOCATION_MODAL": {
        "NEW_SHIFT_LOCATION": "Nueva zona de asistencia",
        "TYPE": "Tipo",
        "NEW_LOCATION": "Nueva zona",
        "CHANGE_EXISTING_LOCATION": "Cambiar zona existente",
        "EXISTING_LOCATION": "Zona existente",
        "NAME": "Nombre",
        "DETAILS": "Detalles",
        "HELP": "Rotaready recuerda la posición exacta del marcador en el mapa, por lo que estos detalles son solo para su referencia. No dudes en incluir algunas notas o una dirección completa.",
        "SAVING": "Ahorrando...",
        "SAVE": "Guardar",
        "CANCEL": "Cancelar",
        "ERROR_ENTER_NAME": "Introduzca un nombre para esta zona.",
        "ERROR_UPDATE": "Actualmente no se puede actualizar esta zona. Vuelva a intentarlo.",
        "ERROR_ADD": "Actualmente no se puede agregar esta zona. Por favor, inténtelo de nuevo."
      },
      "EDIT_LOCATION_MODAL": {
        "EDIT_LOCATION": "Zona de edición",
        "HIERARCHY": "Jerarquía",
        "MASTER_LOCATION": "Zona maestra",
        "SUB_LOCATION": "Subzona",
        "HELP_HIERARCHY": "Rotaready te permite crear una jerarquía de zonas. Una subzona se puede colocar debajo de cualquier otra zona.",
        "PARENT_LOCATION": "Zona principal",
        "NAME": "Nombre",
        "DETAILS": "Detalles",
        "HELP_DETAILS": "Rotaready recuerda la posición exacta del marcador en el mapa, por lo que estos detalles son solo para su referencia. No dudes en incluir algunas notas o una dirección completa.",
        "ATTENDANCE": "Asistencia",
        "ALLOW_CLOCKING": "Permita que el personal entre y salga aquí",
        "STAFF_WORKING": "Solo cuando el personal esté trabajando en un turno marcado con esta zona",
        "HELP_CLOCKING": "Estas configuraciones determinan si el personal puede iniciar y cerrar el horario con la aplicación móvil Rotaready.",
        "ATTENDANCE_SENSITIVITY": "Sensibilidad a la asistencia",
        "METRES": "metros",
        "HELP_CLOSE_LOCATION": "Esto define qué tan cerca debe estar una persona de la zona antes de que la aplicación móvil Rotaready le permita entrar o salir. No se recomienda ir a menos de 50 metros, ya que las personas con una señal débil no podrán cronometrar.",
        "MIN_OCCUPANCY": "Ocupación mínima",
        "HELP_MIN_OCCUPANCY": "Cuando alguien esté en turno, Rotaready te avisará si el número total de personas que también están en turno cae por debajo de este número.",
        "MAX_OCCUPANCY": "Ocupación máxima",
        "HELP_MAX_OCCUPANCY": "Si se proporciona, Rotaready no le permitirá programar más personal aquí (en ningún momento) que la ocupación máxima.",
        "TIP_OPENING": "Especifique los horarios de apertura para que Rotaready evite que programe turnos en esta zona cuando esté cerrada.",
        "TIP_SUB_LOC": "Como se trata de una subzona, si su zona principal está cerrada en cualquier momento, esta subzona también se considerará cerrada.",
        "REGULAR_OPENING_TIMES": "Horarios de apertura regulares",
        "OPEN_247": "Esta zona está abierta 24 horas al día.",
        "TO": "a",
        "SPECIAL_PERIODS": "Periodos especiales",
        "NO_SPECIAL_PERIODS": "No hay periodos especiales.",
        "OPEN": "Abrir",
        "CLOSED": "Cerrado",
        "ADD_SPECIAL_PERIOD": "Añadir período especial",
        "REASON": "Motivo",
        "REASON_PLACEHOLDER": "por ejemplo. Reforma de verano",
        "FROM": "Desde",
        "TO_CAPS": "Para",
        "ADD": "Añadir",
        "DELETE": "Borrar",
        "SAVING": "Ahorrando...",
        "SAVE": "Guardar",
        "CANCEL": "Cancelar",
        "ALERT_NAME": "Asegúrese de haber proporcionado un nombre válido para la zona de asistencia.",
        "ALERT_PARENT": "Para que sea una subzona, debe seleccionar una zona principal.",
        "ALERT_VALID_PARENT": "Seleccione una zona principal válida.",
        "ALERT_PARENT_LOCATION": "Esta zona de asistencia y la zona principal elegida deben pertenecer al mismo sitio (o ambas deben pertenecer a toda la organización).",
        "ALERT_MINIMUM": "No puede tener un nivel de ocupación mínimo superior al nivel de ocupación máximo.",
        "ERROR_UPDATE": "No pudimos actualizar la zona en ese momento. Vuelva a intentarlo.",
        "ALERT_SUB_CONFIRM": "Esta zona todavía tiene subzonas. También se eliminarán.\n\n¿Está seguro de que desea continuar?",
        "ALERT_CONFIRM": "¿Está seguro de que desea eliminar esta zona?",
        "ERROR_DELETE": "No pudimos eliminar la zona en ese momento. ¡Vuelve a intentarlo!"
      },
      "INDEX": {
        "SHIFT_LOCATIONS": "Zonas de asistencia",
        "TIP_FIND": "Para agregar una nueva zona o cambiar una zona existente, primero debe buscarla en el mapa. Busca cualquier dirección o código postal y, a continuación, arrastra el marcador verde para cambiarlo de posición.",
        "PLACEHOLDER_SEARCH": "Buscar para añadir una nueva zona",
        "HELP_NO_LOCATIONS": "No tienes zonas existentes. Busca en el mapa para agregar uno.",
        "HELP_NO_RESULTS": "No hay zonas de asistencia que coincidan con los términos de búsqueda.",
        "TOOLTIP_SHOW": "Mostrar en el mapa",
        "TOOLTIP_CLOCKING_ALWAYS": "El personal siempre puede entrar y salir con la aplicación móvil Rotaready aquí",
        "TOOLTIP_CLOCKING_WORKING": "El personal puede entrar y salir con la aplicación móvil Rotaready aquí, solo si trabajan un turno aquí",
        "ERROR_LOAD": "No hemos podido cargar sus zonas de asistencia. Vuelva a intentarlo.",
        "NO_ADDRESS": "Sin detalles ni dirección",
        "ERROR_LOOKUP": "No hemos podido buscar la ubicación de ese marcador.",
        "UNKNOWN_ADDRESS": "Dirección desconocida",
        "ADD_AS_NEW": "Agregar como nueva zona",
        "DRAG_TO_CHANGE": "Arrástrame para cambiar la ubicación",
        "FILTER_LOCATIONS": "Filtrar zonas..."
      }
    },
    "SHIFT_TYPES": {
      "EDIT_SHIFT_TYPES_MODAL": {
        "TITLE_NEW": "Nuevo tipo de turno",
        "TITLE_EDIT": "Editar tipo de turno",
        "SITE_LABEL": "Sitio",
        "HIERARCHY_LABEL": "Jerarquía",
        "MASTER_TYPE_LABEL": "Tipo de turno maestro",
        "SUB_TYPE_LABEL": "Tipo de turno secundario",
        "SUB_TYPE_HELP": "Rotaready le permite crear una jerarquía de tipos de turnos. Un tipo de turno secundario se puede colocar debajo de cualquier otro tipo de turno.",
        "PARENT_TYPE_LABEL": "Tipo de turno para padres",
        "NAME_LABEL": "Nombre",
        "COLOUR_LABEL": "Color",
        "COST_STREAM_MAPPINGS_LABEL": "Mapeos de arroyos",
        "COST_STREAM_TABLE": {
          "COST_STREAM_HEADING": "Transmitir",
          "ATTRIBUTED_HEADING": "atribuido",
          "EMPTY_LABEL": "Esto no está asignado a ningún arroyo.",
          "ADD_BUTTON": "Añadir nuevo"
        },
        "DELETE_BUTTON": "Borrar",
        "SAVE_BUTTON": "Guardar",
        "CANCEL_BUTTON": "Cancelar",
        "VALIDATION": {
          "NAME_REQUIRED": "Asegúrese de haber proporcionado un nombre válido para el tipo de turno.",
          "PARENT_REQUIRED": "Para que sea un tipo de turno secundario, debe seleccionar un tipo de turno principal.",
          "PARENT_VALID": "Seleccione un tipo de turno para padres válido.",
          "PARENT_SAME_SITE": "Este tipo de turno y el tipo de turno principal elegido deben pertenecer al mismo sitio (o ambos deben pertenecer a toda la organización).",
          "ATTRIBUTION_OVERAGE": "Hemos resumido el porcentaje atribuido a cada transmisión y el total supera el 100%. Solo puedes atribuir un máximo del 100% en una o más transmisiones.",
          "ATTRIBUTION_VALID": "Has introducido un «porcentaje atribuido» no válido para una o más de las transmisiones. Un valor de «50», por ejemplo, atribuirá el 50% del coste salarial de cualquier persona que trabaje en este tipo de turno al flujo correspondiente.",
          "ATTRIBUTION_NONE": "Debe atribuir el tipo de turno a al menos una transmisión.",
          "DUPLICATE_COST_STREAM_MAPPING": "¡Ya existe un mapeo para esta transmisión! No puedes atribuir un tipo de turno a la misma transmisión dos veces."
        },
        "ERRORS": {
          "ADD_ERROR": "No pudimos añadir tu tipo de turno en ese momento. ¡Es probable que ya exista un tipo de turno con el mismo nombre! Prueba a elegir otro nombre.",
          "UPDATE_ERROR": "No pudimos actualizar el tipo de turno en ese momento. ¡Es probable que ya exista un tipo de turno con el mismo nombre! Prueba a elegir otro nombre.",
          "DELETE_TYPE": "No pudimos eliminar el tipo de turno en ese momento. ¡Vuelve a intentarlo!"
        },
        "CONFIRM": {
          "DELETE_SUB_TYPES": "Este tipo de turno todavía tiene tipos de turnos secundarios. También se eliminarán. ¿Está seguro de que desea continuar?",
          "DELETE_TYPE": "¿Está seguro de que desea eliminar este tipo de turno?"
        }
      },
      "INDEX": {
        "TITLE": "Tipos de turnos",
        "SUBTITLE": "Piense en los tipos de turnos como categorías para los diferentes tipos de trabajo que realizan las personas en su organización:",
        "SUB_TYPE_HELP": "Puedes añadir un «tipo de turno secundario» haciendo clic en el icono con el signo más situado junto a cualquier tipo de turno. Esto es útil si necesitas dividir un tipo de turno en tareas o funciones más específicas.",
        "TYPES_EMPTY": "No hay ningún tipo de turno que mostrar.",
        "ONLY_ENTITY": "solamente",
        "WHOLE_ORGANISATION": "En toda la organización",
        "SHOW_MORE": "Mostrar más...",
        "ERRORS": {
          "LOAD_ERROR": "Vaya, no pudimos cargar los tipos de turnos en ese momento. ¡Vuelva a intentarlo!"
        },
        "TOOLTIPS": {
          "ADD_SUB_SHIFT_TYPE": "Añadir tipo de turno secundario"
        },
        "ADD_SHIFT_TYPE": "Añadir tipo de turno"
      }
    },
    "TEMPLATES": {
      "EDIT_CONSTRAINTS_MODAL": {
        "ADD_TITLE": "Añadir un nuevo {{currentLevelName}}",
        "EDIT_TITLE": "Editar restricciones de {{currentLevelName}}",
        "NAME_LABEL": "nombre",
        "LOADING": {
          "SAVE": "Ahorrando..."
        },
        "SAVE_AND_CLOSE_BUTTON": "Guardar y cerrar",
        "CANCEL_BUTTON": "Cancelar",
        "OVERRIDE_CONSTRAINT_BUTTON": "Anular la restricción {{levelName}}",
        "DELETE_OVERRIDE_BUTTON": "Eliminar anulación",
        "TAB_HEADINGS": {
          "POPULAR": "Popular",
          "STAFF": "Personal",
          "REST": "Descanso",
          "OTHERS": "Otros"
        },
        "STAFF_SELECTION": {
          "TITLE": "Selección de personal",
          "FLEXIBLE_STAFFING_LABEL": "Personal flexible",
          "FAIRLY_HINT": "Elige de forma justa entre todo el personal",
          "FAIRLY_ANY_TAGS_HINT": "Elige de forma justa entre las que coincidan con <strong>cualquiera</strong> de las siguientes etiquetas",
          "FAIRLY_ALL_TAGS_HINT": "Elige de forma justa entre las que coincidan con <strong>todas</strong> las etiquetas siguientes",
          "POOLING_HINT": "Trate las siguientes etiquetas como grupos de personal y agote cada una <strong>en orden</strong>",
          "FIXED_STAFFING_LABEL": "Dotación fija",
          "SPECIFIC_STAFF_MEMBER_HINT": "Asigne los turnos a un miembro del personal específico",
          "ASSIGNEE_LABEL": "Cesionario",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Escriba para agregar etiquetas"
          }
        },
        "PREFERRED_STAFF_SELECTION": {
          "TITLE": "Selección de personal preferente",
          "HINT": "Si varios empleados son elegibles para un turno determinado, Rotaready intentará sesgar su selección de acuerdo con los criterios elegidos:",
          "NO_PREFERENCE": "No prefiera a ningún miembro del personal sobre otros, excepto por motivos de equidad",
          "PREFER_ANY_TAG": "Prefiero aquellos que coincidan con <strong>alguna</strong> de las siguientes etiquetas",
          "PREFER_ALL_TAG": "Prefiero aquellos que coincidan con <strong>todas</strong> las etiquetas siguientes",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Escriba para agregar etiquetas"
          }
        },
        "STAFF_SELECTION_GROUPS": {
          "TITLE": "Selección de personal (grupos)",
          "HINT": "Esto le permite restringir la selección de personal a aquellos que sean miembros de cualquiera de los grupos siguientes. Los grupos recibirán los recursos en el orden en que se especifican:",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Escriba para agregar etiquetas"
          }
        },
        "STAFF_TYPECASTING": {
          "TITLE": "Encajamiento del personal",
          "HINT": "<em>Cuando asignamos a alguien a un turno <em>encasillado</em>, solo se le pueden asignar más turnos ese día que a) también estén <em>mecanografiados con al menos una de estas etiquetas, o b) no estén encasillados</em> en absoluto:</em>",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Escriba para agregar etiquetas"
          }
        },
        "OUTSOURCING": {
          "TITLE": "Externalización",
          "HINT": "Si no hay personal interno disponible, permita la subcontratación a las siguientes organizaciones, en el orden en que se especifican:",
          "PLACEHOLDERS": {
            "TYPE_OUTSOURCE_ORGANISATIONS": "Escriba para agregar organizaciones de subcontratación"
          }
        },
        "SELECTION_CONSISTENCY": {
          "TITLE": "Coherencia en la selección",
          "HINT": "En el caso de los turnos que abarcan varios días de una semana determinada, cuando a alguien se le asigna el turno del primer día, debe:",
          "NO_EFFECT": "no tienen ningún efecto sobre si son elegidos para cualquier otro turno de un día",
          "ALLOCATE": "asignarse a todos los turnos de los demás días del conjunto de turnos, siempre que sea posible"
        },
        "REST_PERIOD": {
          "TITLE": "Periodo de descanso",
          "HINT": "Después de trabajar un turno, asegúrese de que el miembro del personal descanse (y, por lo tanto, no se le asigne otro turno) durante al menos:"
        },
        "LOCATION_TOLERANCE": {
          "TITLE": "Tolerancia de ubicación",
          "HINT": "Con frecuencia es importante que se asignen dos turnos consecutivos con la misma ubicación especificada al mismo miembro del personal. Especifique el número máximo de minutos entre dos de esos turnos (desde el final del primero hasta el inicio del segundo) para que podamos considerarlos consecutivos en este sentido:",
          "MINUTES": "minutos",
          "PLACEHOLDERS": {
            "MINUTES": "Minutos entre el final de un turno y el inicio de otro"
          }
        },
        "DAILY_WINDOW_DEFINITION": {
          "TITLE": "Definición de ventana diaria",
          "HINT": "Algunas organizaciones tienen a su personal trabajando varios «turnos» en un día determinado y, para cada persona, esta carga de trabajo diaria de turnos debe estar dentro de un período de tiempo determinado. Estos intervalos de tiempo pueden variar y puedes definir todas las posibles variaciones aquí:",
          "LIMITED_TIMES": "Tenemos un conjunto fijo y limitado de horas de inicio y finalización para nuestras ventanas",
          "REGULAR_INTERVALS": "Nuestras ventanas caen en intervalos regulares después de una hora de inicio determinada"
        },
        "TIMES": {
          "WINDOW_TIMES_TITLE": "Horarios de ventana",
          "SPLIT_SHIFT_WINDOW_TIMES": "Horarios de ventana de turnos divididos",
          "WINDOW_START_TIME": "Hora de inicio de la ventana más temprana",
          "WINDOW_INTERVAL": "Intervalo entre ventanas válidas",
          "WINDOW_FINISH_TIME": "Hora de finalización de la última ventana posible",
          "PLACEHOLDERS": {
            "COMMA_SEPERATED_TIMES": "Lista de horas separadas por comas, es decir, 9:30-17:30,13:00-20:00"
          }
        },
        "SHIFT_PAY_RATE": {
          "TITLE": "Tasa de pago por turno",
          "HINT": "El personal no asalariado que trabaje en turnos generados a partir de este {{currentLevelName}} ganará según su tarifa por hora habitual. Puede anular esto a continuación si es necesario:",
          "OPTIONS": {
            "NORMAL": "Normal: el personal gana según su tarifa por hora habitual",
            "ZERO": "Cero: al personal no se le paga por este turno",
            "MULTIPLE": "Múltiple: el personal gana un múltiplo de su tarifa por hora habitual",
            "FIXED": "Corregido: sustituyen su tarifa horaria habitual por una tarifa fija"
          },
          "MULTIPLIER": "Multiplicador de tasas",
          "HOURLY_RATE_OVERRIDE": "Anulación de tarifas por hora",
          "PLACEHOLDERS": {
            "MULTIPLIER": "Multiplicador de tarifas por hora",
            "HOURLY_RATE_OVERRIDE": "Tarifa por hora en moneda local, es decir, 8,50"
          }
        },
        "SHIFT_TYPE": {
          "TITLE": "Tipo de turno",
          "HINT": "Esto indica el «tipo» de turnos que se generarán a partir de este {{currentLevelName}}. Su personal lo verá como el título del turno y, si usa las funciones de control de costos de Rotaready, cada tipo de turno se puede asignar a uno o más flujos de costos."
        },
        "SITE_DEPARTMENT": {
          "TITLE": "Sitio/departamento",
          "HINT": "Los cambios de atributos generados desde este {{currentLevelName}} se transfieren a un sitio o departamento diferente. Esto es útil para generar turnos de cobertura.",
          "ATTRIBUTE_TO_SITE": "Atribuya el salario, el costo y las horas al sitio elegido"
        }
      },
      "EDIT_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Añadir conjunto de turnos",
        "ADD_SUBTITLE": "Proporcione detalles sobre este conjunto de turnos",
        "EDIT_TITLE": "Editar conjunto de turnos",
        "EDIT_SUBTITLE": "Modificar los detalles de este conjunto de turnos",
        "TIP": "El planificador automático de Rotaready generará turnos a partir de este conjunto de turnos y asignará el personal en consecuencia, de manera justa y económica.",
        "TIME_LABEL": "Hora de inicio/finalización",
        "TO": "a",
        "OVERFLOW_WARNING": "Este turno se extenderá hasta el día siguiente.",
        "LOCATION_LABEL": "Zona de asistencia",
        "MINIMUM_LABEL": "Por lo menos",
        "MINIMUM_EXPLANATION": "Quiero una cantidad mínima de personal por día, pero más si es posible",
        "STAFF_NUMBER_LABEL": "Exactamente",
        "STAFF_NUMBER_EXPLANATION": "Quiero saber el número exacto de empleados por día",
        "MAXIMUM_LABEL": "Hasta",
        "MAXIMUM_EXPLANATION": "Quiero la mayor cantidad de personal posible por día, hasta un límite máximo",
        "SHORT_DAYS": {
          "MONDAY": "Lu",
          "TUESDAY": "Tua",
          "WEDNESDAY": "Mie",
          "THURSDAY": "Ju",
          "FRIDAY": "Vie",
          "SATURDAY": "Sáb",
          "SUNDAY": "Sol"
        },
        "SAVE_BUTTON": "Guardar conjunto de turnos",
        "CANCEL_BUTTON": "Cancelar",
        "LOADING": {
          "SAVE": "Ahorrando..."
        },
        "ERRORS": {
          "UNABLE_TO_ADD": "Actualmente no se puede agregar este conjunto de turnos. Por favor, inténtelo de nuevo."
        }
      },
      "EDIT_TEMPLATE": {
        "LOADING": {
          "SAVE": "Ahorrando..."
        },
        "EDIT_CONSTRAINTS": "Modificar restricciones",
        "CONSTRAINTS_LABEL": "Restricciones de plantilla",
        "CONSTRAINTS_TEXT": "Establezca las restricciones que se aplicarán a toda la plantilla.",
        "PLACEHOLDERS": {
          "SEARCH_FILTER": "Filtrar..."
        },
        "ADD_GROUP_BUTTON": "Añadir grupo",
        "TAB_HEADINGS": {
          "FLEXIBLE_STAFFING": "Personal flexible",
          "FIXED_STAFFING": "Dotación fija"
        },
        "TOOLTIPS": {
          "ACTIVE_CONSTRAINTS": "Restricciones activas",
          "DISABLE": "Desactivar",
          "ENABLE": "Habilitar"
        },
        "GROUP_OPTIONS_BUTTON": {
          "LABEL": "Opciones de grupo",
          "ADD_TASK": "Añadir tarea",
          "ADD_SHIFT_SET": "Añadir conjunto de turnos",
          "EDIT_CONSTRAINTS": "Modificar restricciones",
          "DELETE_GROUP": "Eliminar grupo"
        },
        "HOURS": "horas",
        "SHORT_DAYS": {
          "MONDAY": "Lu",
          "TUESDAY": "Tua",
          "WEDNESDAY": "Mie",
          "THURSDAY": "Ju",
          "FRIDAY": "Vie",
          "SATURDAY": "Sáb",
          "SUNDAY": "Sol"
        },
        "CHAR_DAYS": {
          "MONDAY": "M",
          "TUESDAY": "T",
          "WEDNESDAY": "W",
          "THURSDAY": "T",
          "FRIDAY": "F",
          "SATURDAY": "S",
          "SUNDAY": "S"
        },
        "NO_SHIFT_SETS": "No hay conjuntos de turnos en este grupo. Añade uno usando el botón «Opciones de grupo».",
        "OPTIONS_BUTTON": {
          "LABEL": "Opciones",
          "ADD_SHIFT_SET": "Añadir conjunto de turnos",
          "EDIT_CONSTRAINTS": "Modificar restricciones",
          "DELETE_TASK": "Eliminar tarea"
        },
        "NO_FLEXIBLE_STAFF_GROUPS_THIS_WEEK": "No tiene grupos de personal flexibles. ¿Por qué no añades uno haciendo clic en «Añadir grupo» más arriba?",
        "NO_FLEXIBLE_STAFF_GROUPS": "No hay grupos de personal flexibles que coincidan con lo que busca.",
        "GROUP_SHIFT_SETS_TABLE": {
          "HEADINGS": {
            "SHIFT_TYPE": "Tipo de turno",
            "TIME": "Hora",
            "DAYS_OF_WEEK": "Días de la semana"
          }
        },
        "NO_FIXED_STAFF_GROUPS_THIS_WEEK": "No tiene grupos de personal fijos. ¿Por qué no añades uno haciendo clic en «Añadir grupo» más arriba?",
        "NO_FIXED_STAFF_GROUPS": "No hay grupos de personal fijos que coincidan con lo que busca.",
        "EDIT_BUTTON": "Editar",
        "TOGGLE_DROPDOWN_BUTTON": "Alternar menú desplegable",
        "DELETE_BUTTON": "Borrar",
        "SAVE_BUTTON": "Guardar",
        "ERRORS": {
          "COULDNT_FIND_TEMPLATE": "No hemos podido encontrar la plantilla que estabas buscando. Por favor, inténtelo de nuevo.",
          "OOPS": "Vaya, algo acaba de salir mal. ¡Puede que tengas que volver a intentarlo!",
          "TEMPLATE_STATE_CHANGE": "No hemos podido activar/desactivar parte de tu plantilla en este momento. Vuelva a intentarlo."
        },
        "LEVEL_NAMES": {
          "GLOBAL": "global",
          "TEMPLATE": "modelo",
          "WEEK": "semana",
          "GROUP": "grupo",
          "TASK": "tarea",
          "SHIFT_SET": "conjunto de turnos"
        },
        "CONFIRM": {
          "DELETE": "¿Está seguro de que desea eliminar esto?",
          "DELETE_SHIFT_SET": "¿Está seguro de que desea eliminar este conjunto de turnos?"
        }
      },
      "FIXED_STAFFING_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Añadir conjunto de turnos",
        "ADD_SUBTITLE": "Proporcione detalles sobre este conjunto de turnos",
        "EDIT_TITLE": "Editar conjunto de turnos",
        "EDIT_SUBTITLE": "Modificar los detalles de este conjunto de turnos",
        "START_END_LABEL": "Hora de inicio/finalización",
        "SHIFT_OVERFLOW_WARNING": "Este turno se extenderá hasta el día siguiente.",
        "SHIFT_TYPE_LABEL": "Tipo de turno",
        "LOCATION_LABEL": "Zona de asistencia",
        "SHORT_DAYS": {
          "MONDAY": "Lu",
          "TUESDAY": "Tua",
          "WEDNESDAY": "Mie",
          "THURSDAY": "Ju",
          "FRIDAY": "Vie",
          "SATURDAY": "Sáb",
          "SUNDAY": "Sol"
        },
        "SAVE_BUTTON": "Guardar conjunto de turnos",
        "CANCEL_BUTTON": "Cancelar",
        "LOADING": {
          "SAVE": "Ahorrando..."
        },
        "UNKNOWN_SHIFT_TYPE": "Tipo de turno desconocido",
        "ERRORS": {
          "UNABLE_ADD": "Vaya, en este momento no podemos añadir este conjunto de turnos. Vuelva a intentarlo.",
          "UNABLE_UPDATE": "Vaya, en este momento no podemos actualizar este conjunto de turnos. Vuelva a intentarlo."
        }
      },
      "INDEX": {
        "NEW_BUTTON": "Añadir nuevo",
        "ALERT_LINK_WARNING": "Las plantillas se han trasladado al menú Configuración. El enlace existente en el menú Rotas desaparecerá pronto 👀",
        "TITLE": "Plantillas de rotación",
        "SEARCH_FILTER": "Buscar...",
        "TEMPLATE_TABLE": {
          "NAME_HEADING": "Nombre",
          "CREATED_BY_HEADING": "Creado por",
          "LAST_EDITED_HEADING": "Editado por última vez",
          "OPTIONS_HEADING": "Opciones"
        },
        "NO_TEMPLATES": "No hay plantillas de rotación para mostrar.",
        "CLONE_TEMPLATE": "Clon",
        "DELETE_TEMPLATE": "Borrar",
        "EDITED_ON": "en",
        "CONFIRM": {
          "DELETE_NODE": "¿Está seguro de que desea eliminar esto?"
        },
        "SUCCESS": {
          "DELETE_NODE": "Tu previsión de demanda se ha eliminado correctamente."
        },
        "ERRORS": {
          "DELETE_NODE": "Vaya, algo acaba de salir mal. ¡Puede que tengas que volver a intentarlo!"
        }
      },
      "NEW_TEMPLATE_MODAL": {
        "TITLE": "Nueva plantilla",
        "REMINDER": "<strong>¡Pasado!</strong> Ya tienes <strong>{{templateCount}} plantilla (s</strong>). Solo necesita crear uno nuevo si sus requisitos de programación han cambiado significativamente. De lo contrario, debes editar uno existente.",
        "TYPE_LABEL": "Tipo",
        "NEW_BLANK_TEMPLATE": "Nueva plantilla en blanco",
        "CLONE_EXISTING_TEMPLATE": "Clonar la plantilla existente",
        "CLONE_FROM_LABEL": "Clon de",
        "NAME_LABEL": "Nombre de la plantilla",
        "NAME_HELP": "Generarás rotaciones a partir de esta plantilla de forma regular y un buen nombre describe para quién o para qué servirán estas rotaciones, por ejemplo, «Rotación del personal de apoyo».",
        "NAME_PLACEHOLDER": "es decir. Temporada alta o rotación por equipos B",
        "DEFAULT_SHIFT_TYPE_LABEL": "Tipo de turno predeterminado",
        "DEFAULT_SHIFT_TYPE_HELP": "Cuando construyas tu plantilla, podrás cambiar el tipo de turno para cada grupo o tarea, y ahora es muy práctico para nosotros establecer un valor predeterminado.",
        "CREATE_BUTTON": "¡Crea!",
        "CREATE_BUTTON_LOADING": "Espere, esto puede tardar un momento...",
        "CANCEL_BUTTON": "Cancelar",
        "CONFIRM_CLONE": "La clonación de una plantilla tardará uno o dos momentos, según el tamaño de la plantilla. ¿Está seguro de que desea continuar?",
        "CREATE_ERROR": "Actualmente no se puede crear esta plantilla. Por favor, inténtelo de nuevo."
      },
      "DELETE_TEMPLATE_MODAL": {
        "INFO_TITLE": "Eliminar plantilla",
        "INFO_BODY": "La eliminación de una plantilla es irreversible y no se puede deshacer. Para convencernos de que quieres hacerlo, escribe el nombre de la plantilla en el cuadro de abajo y, a continuación, haz clic en eliminar.",
        "DELETE_TEMPLATE_BUTTON": "Eliminar plantilla",
        "SUCCESS": {
          "DELETE_TEMPLATE": "La plantilla se ha eliminado correctamente."
        },
        "ERRORS": {
          "DELETE_TEMPLATE": "Vaya, algo acaba de salir mal. ¡Puede que tengas que volver a intentarlo!"
        }
      }
    },
    "SHIFT": {
      "EDIT_MODAL": {
        "SHIFT": "Turno",
        "SITE": "Sitio",
        "START": "Comenzar",
        "BREAK": "Pausa",
        "NONE": "Ninguna",
        "FINISH": "Finalizar",
        "NEW_SHIFT": "Nuevo turno",
        "ATTRIBUTE_WAGE_COST_TO_SITE": " Atribuya el salario, el costo y las horas al sitio elegido",
        "DATE_AND_TIME": "Fecha y hora",
        "TO": " a ",
        "THEN_REST": " luego descansa ",
        "SHIFT_TYPE": "Tipo de turno",
        "LOCATION": "Zona de asistencia",
        "NO_BREAK": "Sin descanso",
        "PAID_BREAK": "Descanso pagado",
        "UNPAID_BREAK": "Receso no remunerado",
        "OF_LENGTH": "de longitud",
        "BREAK_MESSAGE_WARNING": "El personal asignado a este turno tiene diferentes requisitos de descanso. Cuando termines, Rotaready guardará un turno diferente para cada persona con el descanso correcto establecido.",
        "BREAK_MESSAGE_SUCCESS": "Hemos aplicado automáticamente el descanso correcto a este turno.",
        "PAY_RULE": "Regla salarial",
        "APPLIES": " afecta",
        "FOR_THE_SCHEDULED_DURATION": "Durante la duración programada",
        "FOR_ANY_OVERTIME": "Para cualquier tiempo extra",
        "STAFF_SELECTION": "Selección de personal",
        "STAFF_SELECTION_HELP": "Edita el personal asignado y usa etiquetas para filtrar a otros que sean elegibles",
        "STAFF_MUST_MATCH": "El personal debe coincidir",
        "ANY_OF_THE_TAGS": "<strong>cualquiera</strong> de las etiquetas",
        "ANY": "ninguna",
        "ALL": "todo",
        "OF_THE_TAGS": " de las etiquetas",
        "ALL_OF_THE_TAGS": "<strong>todas</strong> las etiquetas",
        "TAG_TYPEAHEAD_PLACEHOLDER": "Escriba para buscar etiquetas",
        "STAFF_ASSIGNED": "Asignado",
        "NOBODY_IS_ASSIGNED": "No hay nadie asignado",
        "STAFF_ELIGIBLE": "Elegible",
        "STAFF_FILTER_PLACEHOLDER": "Buscar...",
        "SHOW": "Mostrar",
        "MORE": " más...",
        "NOBODY_MATCHES_YOUR_TAGS": "Nadie coincide con tus etiquetas",
        "NOBODY_ELSE_IS_AVAILABLE": "No hay nadie más disponible",
        "MORE_OPTIONS": "Más opciones",
        "ADD_NOTES_AND_ADJUST_PAY_RATES": "Agrega notas y ajusta las tasas de pago",
        "DETAILS": "Detalles",
        "DETAILS_TEXTAREA_PLACEHOLDER": "Agregue notas, deberes y otros detalles aquí...",
        "ROTA": "Rota",
        "NO_ROTA": "Sin rotación",
        "PAY_LABEL": "Pagar",
        "PAY_TOGGLE": "Este es un turno pagado",
        "DELETE": "Borrar",
        "VIEW_BROADCAST": "Ver transmisión",
        "START_BROADCAST": "Iniciar transmisión",
        "SAVE": "Guardar",
        "CANCEL": "Cancelar"
      },
      "SHIFT_BROADCAST_MODAL": {
        "SHIFT": "Turno",
        "SITE": "Sitio",
        "START": "Comenzar",
        "BREAK": "Pausa",
        "NONE": "Ninguna",
        "FINISH": "Finalizar",
        "ORIGINAL_PLANNED_STAFF": "Personal planificado originalmente",
        "EXTRA_STAFF_REQUIRED": "Se requiere personal adicional",
        "NOT_SHIFT_BROADCASTING_HELP": "Establézcalo en 0 para solicitar la mayor cantidad de personal posible.",
        "START_BROADCASTING": "Comenzar a transmitir",
        "START_BROADCASTING_LOADING": "Iniciando la transmisión...",
        "CANCEL": "Cancelar",
        "ACCEPTED": "Aceptado",
        "PENDING": "Pendiente",
        "REJECTED": "Rechazado",
        "EVERYONE": "Todo el mundo",
        "NO_PARTICIPANTS_TO_SHOW": "No hay participantes de la transmisión que mostrar.",
        "NEEDED": "Necesario",
        "SHIFT_BROADCASTING_HELP": "Tu transmisión está actualmente en marcha. La siguiente lista se actualizará a medida que el personal acepte o rechace el turno. La transmisión finalizará automáticamente cuando el número correcto de miembros del personal haya aceptado o cuando todos hayan respondido.",
        "STOP_BROADCASTING": "Detener la transmisión",
        "STOP_BROADCASTING_LOADING": "Deteniendo la transmisión...",
        "PAUSE_BROADCAST": "Pausar la transmisión",
        "PAUSE_BROADCAST_LOADING": "Pausando la transmisión...",
        "RESUME_BROADCAST": "Reanudar transmisión",
        "RESUME_BROADCAST_LOADING": "Reanudando la transmisión...",
        "CLOSE": "Cerrar"
      },
      "VIEW_MODAL": {
        "SHIFT": "Turno",
        "SITE": "Sitio",
        "START": "Comenzar",
        "BREAK": "Pausa",
        "NONE": "Ninguna",
        "FINISH": "Finalizar",
        "LOCATION": "Zona de asistencia",
        "NONE_SPECIFIED": "No se especificó ninguno",
        "REST": "Descanso",
        "DETAILS": "Detalles",
        "ALLOTTED_BREAK": "Descanso asignado",
        "ASSIGNED_STAFF": "Personal asignado",
        "ON": "en",
        "SHIFT_ROUTINE": "Rutina de turnos",
        "LAST_UPDATED": "Última actualización",
        "GET_DIRECTIONS": "Obtenga direcciones",
        "SHOW_TRAFFIC": "Mostrar tráfico",
        "EDIT": " Editar",
        "CLOSE": "Cerrar"
      }
    }
  },
  "DASHBOARD": {
    "TIME_AND_ATTENDANCE": "Tiempo y asistencia",
    "YOU_ARE_NOW": "Ahora estás",
    "ERROR_PLEASE_TRY_AGAIN": "Error, inténtalo de nuevo",
    "MY_UPCOMING_SHIFTS": "Mis próximos turnos",
    "YOU_HAVE_NO_SHIFTS_TODAY": "Hoy no tienes turnos.",
    "ALL_DAY": "Todo el día",
    "AM": "SOY",
    "PM": "P.M.",
    "TODAY_S_SCHEDULE": "Programación de hoy",
    "THERE_ARE_NO_SHIFTS_SCHEDULED_TODAY": "No hay turnos programados para hoy.",
    "WHATEVER": "A partir de <strong>{{startTime}}</strong> y terminando a las <strong>{{endTime</strong>}}"
  },
  "SIGN_OUT": {
    "LOGGING_OUT": "Cerrando sesión..."
  },
  "PARTIALS": {
    "VALIDATION": {
      "ISSUE_1": {
        "HEADING": "Jornada laboral demasiado larga",
        "BODY": "Tu cambio significa que la jornada laboral de <b>{{name}</b>} sería demasiado larga (según la configuración de tu organización). Actualmente comienza en <b>{{start|momentFormat: 'lll'}} y termina en {{end|momentFormat: 'lll'}} (luego descansa</b> hasta <b>{{endrest|momentFormat: 'lll'</b>}}."
      },
      "ISSUE_2": {
        "HEADING": "Jornada laboral superpuesta",
        "BODY": "Tu cambio significa que <b>{{name}}</b> tiene un día laborable, <b>desde {{primaryStart|momentFormat: 'lll'<b>}</b></b>} Desafortunadamente, esto se superpone con un día hábil que ya tienen, desde <b>{{secondaryStart|momentFormat: 'lll'}}} hasta {{secondaryEnd|momentFormat: 'LT'}} (y descansando hasta <b>{{endrest|momentFormat</b>: 'LT'}} REST|MOMENTFORMAT:'LT'HING})</b>."
      },
      "ISSUE_3": {
        "HEADING": "Turno fuera de jornada laboral",
        "BODY": "<b>{{name}}</b> tiene un turno programado <b>desde {{start|momentFormat: 'LT'}}} hasta {{end|momentFormat: 'LT'}}</b> (luego descansa <b>hasta {{endrest|momentFormat: 'LT'</b>}}). Su cambio significaría que este turno cae parcial o totalmente fuera de la jornada laboral."
      },
      "ISSUE_4": {
        "HEADING": "No disponible",
        "BODY": "<b>{{name}}</b> <b>no está disponible <b>desde {{start|momentFormat: 'lll'}} ultima vez que {end|momentFormat: 'LT'}}</b></b>",
        "REASON_1": "trabajando un turno ({{eventType}} in {{entityName}}",
        "REASON_2": "ocupado durante una jornada laboral",
        "REASON_3": "ausente ({{eventType}})",
        "REASON_4": "no disponible debido a su patrón de disponibilidad",
        "BUTTON": "Haz una excepción e inténtalo de nuevo",
        "BUTTON_LOADING": "Cargando..."
      },
      "ISSUE_5": {
        "HEADING": "Obligación contractual no cumplida",
        "BODY": "Tu cambio significa que <b>{{name}}</b> no cumplirá con su obligación contractual. Ahora son <b>{{currentValue}} días (s), pero debe ser de al menos {<b>{comparatorValue</b></b>}} día (s)."
      },
      "ISSUE_6": {
        "HEADING": "Obligación contractual no cumplida",
        "BODY": "Tu cambio significa que <b>{{name}}</b> no cumplirá con su obligación contractual. Ahora son <b>{{currentValue}} horas, pero debe ser de al menos {<b>{comparatorValue</b></b>}} horas."
      },
      "ISSUE_7": {
        "HEADING": "Las etiquetas no coinciden",
        "BODY": "<b>{{name}}</b> no tiene las etiquetas necesarias para trabajar en este turno. Esto significa que pueden carecer de una habilidad, calificación u otro atributo."
      },
      "ISSUE_8": {
        "HEADING": "Transmisión activa",
        "BODY": "Actualmente hay una transmisión de turnos activa para este turno. Como es posible que su personal esté respondiendo, no podemos permitir que se modifique el turno a menos que primero cancele la transmisión del turno."
      },
      "ISSUE_9": {
        "HEADING": "Días libres insuficientes",
        "BODY": "Tu cambio significa que <b>{{name}}</b> trabajará demasiados días esta semana. Ahora tendrán <b>{{currentValue}} día (s)</b> libres, es decir, menos de los {<b>{comparatorValue}}</b> días libres requeridos."
      },
      "ISSUE_10": {
        "HEADING": "Funcionario inactivo",
        "BODY": "<b>{{name}}</b> no puede funcionar <b>en {{start|momentFormat:</b> 'll'}} Su cuenta está inactiva en esta fecha o se ha trasladado a otro sitio."
      },
      "ISSUE_11": {
        "HEADING": "Ruta marcada para su aprobación",
        "BODY": "No puede realizar ningún cambio en esta rotación, ya que se ha marcado para su aprobación."
      },
      "ISSUE_12": {
        "HEADING": "Período firmado",
        "BODY": "No puede realizar ningún cambio en esta rotación porque ya se ha firmado."
      },
      "ISSUE_13": {
        "HEADING": "Se ha superado el límite de trabajo",
        "BODY": "Tu cambio significa que <b>{{name}</b>} funcionará durante más tiempo del permitido. Ahora estarán trabajando <b>{{currentValue}} día (s), lo que supera su límite de {<b>{comparatorValue</b></b>}} día (s)."
      },
      "ISSUE_14": {
        "HEADING": "Se ha superado el límite de trabajo",
        "BODY": "Tu cambio significa que <b>{{name}</b>} funcionará durante más tiempo del permitido. Ahora trabajarán <b>{{currentValue}} horas, lo que supera su límite de {<b>{comparatorValue</b></b>}} horas."
      },
      "ISSUE_15": {
        "HEADING": "Zona de asistencia cerrada",
        "BODY": "La zona de asistencia asignada al turno (<b>{{assignedLocation}}) está cerrada en este momento</b>.",
        "REASON": "Esto se debe a: {{reason}}."
      },
      "ISSUE_16": {
        "HEADING": "Se superó la ocupación de la zona de asistencia",
        "BODY": "La zona de asistencia asignada a este turno tiene una ocupación máxima de <b>{{occupancy}</b>} personas en cualquier momento. Con este turno, la ocupación sería de {{currentOccupy}}."
      },
      "ISSUE_17": {
        "HEADING": "Muy poco personal en la zona de asistencia",
        "BODY": "<b>Desde {{start|momentFormat: 'lll'}}} hasta {{end|momentFormat: 'lll'}}, hay momentos en los que solo trabajan {{currentValue}} personas.</b> Siempre que alguien esté en un turno, se requiere un mínimo de <b>{{comparatorValue}} personas (s</b>)."
      },
      "ISSUE_18": {
        "HEADING": "Miembro del personal no aprobado",
        "BODY": "{{name}} no puede funcionar <b>en {{start|momentFormat:</b> 'll'}} Su cuenta aún no ha sido aprobada para programar."
      },
      "ISSUE_19": {
        "HEADING": "Faltan documentos de los miembros del personal o están caducados",
        "BODY": "<b>{{name}}</b> no puede funcionar <b>en {{start|momentFormat:</b> 'll'}} Faltan uno o más documentos obligatorios o están caducados."
      },
      "ISSUE_20": {
        "HEADING": "Turno demasiado largo (menores de 18 años)",
        "BODY": "Tu cambio significa que <b>{{name}} funcionará</b> durante {{<b>duration|humaniseDuration</b>}}. Como son menores de 18 años, el límite de la Directiva sobre el tiempo de trabajo es de <b>{{limit|HumaniseDuration</b>}}."
      },
      "ISSUE_21": {
        "HEADING": "Cambie fuera de los límites",
        "BODY": "<b>No puedes programar un turno para <b>{{name}} el día de {{</b><b>start|momentFormat: 'll'}} («ll»}) porque forma parte de un turno que comienza en {{periodStart|momentFormat: 'll'}}</b> (a) y termina el <b>{{periodend|momentFormat: 'll'} FORMAT PERIODEND</b>: 'll'UL'}.</b>"
      }
    }
  },
  "NAV": {
    "HOME": "Inicio",
    "ROTAS": "Rotas",
    "STAFF": "Personal",
    "ATTENDANCE": "Asistencia",
    "REPORTS": "Informes",
    "SETTINGS": "Ajustes",
    "VIEW_ROTA": "Ver rotación",
    "SCHEDULE": "Mi agenda",
    "ABSENCE": "Mi ausencia",
    "AVAILABILITY": "Mi disponibilidad",
    "DOCUMENTS": "Mis documentos",
    "ROTA_DASHBOARD": "Tablero",
    "ROTA_ROTA_EDITOR": "Editor de rotación",
    "ROTA_TRONC": "Tronc",
    "ROTA_EVENT_CALENDAR": "Planificador de eventos",
    "STAFF_DIRECTORY": "Directorio",
    "STAFF_REQUESTS": "Solicitudes",
    "STAFF_ABSENCES": "Calendario de ausencias",
    "STAFF_EMPLOYEE_SUBMITTED_DETAILS": "Detalles enviados por el empleado",
    "ATTENDANCE_OVERVIEW": "Asistencia",
    "ATTENDANCE_ISSUES": "Cuestiones",
    "COST_CONTROL": "Control de costos",
    "COST_CONTROL_SIGN_OFF": "Control de costes y aprobación",
    "COST_CONTROL_AGGREGATED_TOTALS": "Totales agregados",
    "ASSET_REGISTRY": "Registro de activos",
    "WFM_DASHBOARD": "Mis paneles"
  },
  "SETTINGS": {
    "INDEX": {
      "TITLE": "Ajustes",
      "PLACEHOLDER_TEXT": "👈 Selecciona un ajuste a la izquierda para empezar.",
      "CATEGORY_HR": "HORA",
      "CATEGORY_ROTAS": "Rotas",
      "CATEGORY_ATTENDANCE": "Asistencia",
      "CATEGORY_INTEGRATIONS": "Integraciones",
      "ITEM_ROTA_TEMPLATES": "Plantillas de rotación",
      "ITEM_TRUSTED_DEVICES": "Dispositivos de confianza",
      "ITEM_SHIFT_LOCATIONS": "Zonas de asistencia",
      "ITEM_SHIFT_TYPES": "Tipos de turnos",
      "ITEM_OUTSOURCING": "Externalización",
      "ITEM_ABSENCE_TYPES": "Tipos de ausencias",
      "ITEM_DOCUMENT_CATEGORIES": "Categorías de documentos",
      "ITEM_DOCUMENT_TEMPLATES": "Plantillas de documentos",
      "ITEM_TAGS_GROUPS": "Etiquetas y grupos",
      "ITEM_SHIFT_ROUTINES": "Rutinas de turnos",
      "ITEM_REST_BREAKS": "Pausas de descanso",
      "ITEM_PAY_ELEMENTS": "Elementos de pago",
      "ITEM_PAY_RULES": "Normas salariales",
      "ITEM_PERMISSIONS": "Permisos",
      "ITEM_BUDGETS": "Presupuestos",
      "ITEM_ASSET_TYPES": "Tipos de activos",
      "ITEM_ENTITY_GROUP_SUBSCRIPTIONS": 'Administrador de acceso al sitio',
      "ITEM_ENTITY_GROUPS": 'Administrador del sitio',
      "ITEM_POSITIONS": 'Roles de trabajo',
      "ITEM_LEAVER_REASONS": 'Razones de salida',
      "ITEM_RECRUITMENT_SOURCES": 'Fuentes de reclutamiento',
      "ITEM_LABOUR_DEPLOYMENT": 'Despliegue laboral',
    },
    "DOCUMENT_TEMPLATES": {
      "TITLE": "Plantillas de documentos",
      "FILTER_LABEL_CATEGORY": "Categoría",
      "BUTTON_UPLOAD": "Cargar",
      "NAME_COLUMN_LABEL": "Nombre",
      "TEMPLATE_CATEGORY_COLUMN_LABEL": "Categoría",
      "FILE_NAME_COLUMN_LABEL": "Nombre del archivo",
      "FILE_SIZE_COLUMN_LABEL": "Tamaño del archivo",
      "DESTINATION_CATEGORY_COLUMN_LABEL": "Categoría de destino",
      "SIGNATORIES_COLUMN_LABEL": "Signatarios",
      "EDITED_COLUMN_LABEL": "Añadido por",
      "OPTIONS_COLUMN_LABEL": "Opciones",
      "LINK_DELETE": "Borrar",
      "SIGNATORIES_BOTH": "Empleado, empleador",
      "SIGNATORIES_EMPLOYEE": "Empleado",
      "NO_DOCS_MATCH_TEXT": "No hemos podido encontrar ninguna plantilla de documento que coincida con tus filtros.",
      "ERROR_NO_CATEGORIES": "Las plantillas de documentos no están habilitadas para su organización. Chatea con nosotros para empezar.",
      "ALERT_CONFIRM_DELETE": "¿Está seguro de que desea eliminar esta plantilla? ¡Esto no se puede deshacer!",
      "ERROR_500": "No podemos cargar plantillas de documentos en este momento. Vuelva a intentarlo.",
      "ERROR_403": "No tiene permiso para ver o modificar las plantillas de documentos.",
      "UPLOAD_MODAL": {
        "TITLE": "Cargar una plantilla de documento",
        "LABEL_NAME": "Nombre",
        "HELP_TEXT_NAME": "Dale a tu plantilla un nombre que puedas reconocer.",
        "LABEL_CATEGORY": "Categoría de plantilla",
        "HELP_TEXT_CATEGORY": "Mantén tus plantillas organizadas seleccionando una categoría.",
        "LABEL_DESTINATION_CATEGORY": "Categoría de documento de destino",
        "HELP_TEXT_DESTINATION_CATEGORY": "Cuando se utilice esta plantilla y se firme un documento generado, elija la categoría de documento en la que se debe guardar el archivo.",
        "LABEL_SIGNATORIES": "Signatarios",
        "SIGNATORIES_OPTION_BOTH": "Tanto el empleado como el empleador deben firmar.",
        "SIGNATORIES_OPTION_EMPLOYEE": "Solo el empleado debe firmar.",
        "LABEL_UPLOAD": "Cargar",
        "BUTTON_CANCEL": "Cancelar",
        "BUTTON_SAVE": "Guardar",
        "ALERT_SUCCESS": "La plantilla del documento se ha guardado.",
        "ALERT_ERROR_500": "Se ha producido un error al cargar la plantilla del documento. Vuelve a intentarlo o ponte en contacto con nosotros si el mensaje persiste.",
        "ALERT_ERROR_400": "Hay algo que no está del todo bien con tu plantilla de documento."
      }
    },
    "ABSENCE_TYPES": {
      "TITLE": "Tipos de ausencias",
      "ERROR_500": "No podemos cargar los tipos de ausencias en este momento. Por favor, inténtelo de nuevo.",
      "ERROR_403": "No tienes permiso para ver o modificar los tipos de ausencias.",
      "BUTTON_ADD_NEW": "Añadir nuevo",
      "BUTTON_TOGGLE_DELETED": "Incluir eliminado",
      "COLUMN_NAME": "Nombre",
      "COLUMN_BOOKABLE": "Se puede reservar",
      "COLUMN_APPROVAL": "Se requiere aprobación",
      "COLUMN_PAID": "Pagado",
      "COLUMN_EDITED": "Editado por",
      "COLUMN_OPTIONS": "Opciones",
      "YES_LABEL": "Sí",
      "NO_LABEL": "No",
      "LINK_EDIT": "Editar",
      "LINK_DELETE": "Borrar",
      "LINK_REINSTATE": "Reinstaurar",
      "PILL_DELETED": "Eliminado",
      "ALERT_CONFIRM_DELETE": "¿Está seguro de que desea eliminar este tipo de ausencia?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Se ha guardado tu tipo de ausencia.",
        "ALERT_ERROR_500": "Se ha producido un error al crear tu tipo de ausencia. Vuelve a intentarlo o ponte en contacto con nosotros si el mensaje persiste.",
        "ALERT_ERROR_400": "Ya existe un tipo de ausencia con ese nombre. Por favor, elige otro.",
        "ADD_TITLE": "Nuevo tipo de ausencia",
        "EDIT_TITLE": "Editar tipo de ausencia",
        "BUTTON_SAVE": "Guardar",
        "BUTTON_CANCEL": "Cancelar",
        "LABEL_NAME": "Nombre",
        "LABEL_COLOUR": "Color",
        "LABEL_BOOKABLE": "Se puede reservar",
        "LABEL_BOOKABLE_HELP": "Permita a los empleados hacer solicitudes de ausencia de este tipo.",
        "LABEL_APPROVAL": "Aprobación",
        "LABEL_APPROVAL_HELP": "Las solicitudes de este tipo requieren aprobación.",
        "LABEL_PAID": "Pagado",
        "LABEL_PAID_HELP": "La ausencia de este tipo se paga.",
        "LABEL_WORKED_HOURS": "Horas trabajadas",
        "LABEL_WORKED_HOURS_HELP": "La ausencia de este tipo contribuye a las «horas trabajadas».",
        "LABEL_ACCRUES": "Acumula vacaciones",
        "LABEL_ACCRUES_HELP": "Los empleados acumularán vacaciones en caso de ausencia de este tipo si tienen una asignación de vacaciones basada en la acumulación.",
        "LABEL_OVERLAPS_SHIFTS": "Partido por turnos",
        "LABEL_OVERLAPS_SHIFTS_HELP": "La ausencia de este tipo puede coincidir con los turnos, en lugar de un horario de entrada y un horario de salida.",
        "LABEL_TRONC": "Tronc",
        "LABEL_TRONC_HELP": "La ausencia de este tipo contribuye a las horas trabajadas en Tronc.",
        "LABEL_ALLOWANCE_REQUIRED": "Subsidio requerido",
        "LABEL_ALLOWANCE_REQUIRED_HELP": "Para solicitar una ausencia de este tipo, asegúrese de que el empleado tenga una asignación activa.",
        "TITLE_FILE_UPLOADS": "Cargas de archivos",
        "LABEL_SUBMISSION_UPLOAD": "A petición",
        "LABEL_SUBMISSION_UPLOAD_HELP": "Permitir que se adjunte un archivo cuando se solicite la ausencia de este tipo.",
        "LABEL_REVIEW_UPLOAD": "En revisión",
        "LABEL_REVIEW_UPLOAD_HELP": "Permitir que se adjunte un archivo cuando se apruebe o rechace la ausencia de este tipo.",
        "LABEL_SAVE_TO": "Guardar en",
        "HELP_TEXT_SAVE_TO": "Cuando se adjunte un archivo a una ausencia de este tipo, se guardará en los documentos del empleado en la categoría elegida.",
        "FILE_UPLOAD_OPTION_NONE": "Apagado",
        "FILE_UPLOAD_OPTION_OPTIONAL": "Opcional",
        "FILE_UPLOAD_OPTION_MANDATORY": "Necesario",
        "INFO_LOCKED": "Este es un tipo de ausencia especial que utiliza Rotaready. Hemos ocultado algunas propiedades que no se pueden cambiar. Ponte en contacto con nosotros si necesitas ayuda con este tipo de ausencia.",
        "TITLE_PAY": "Pagar",
        "LABEL_FIXED_AMOUNTS": "Montos fijos",
        "HELP_TEXT_FIXED_AMOUNTS": "Al pagar a un empleado una cantidad fija, como el pago por una ausencia acumulada pero no asumida, se utilizará este elemento de pago.",
        "TITLE_REASONS": "Motivos",
        "COLUMN_TITLE": "Motivo",
        "COLUMN_OPTIONS": "Opciones",
        "LINK_DELETE": "Borrar",
        "NO_REASONS": "No se ha añadido ningún motivo.",
        "PLACEHOLDER_REASON": "Nueva razón...",
        "LINK_ADD": "Añadir"
      }
    },
    "OUTSOURCING": {
      "TITLE": "Externalización",
      "BUTTON_ADD_NEW": "Añadir nuevo",
      "BUTTON_TOGGLE_DELETED": "Incluir eliminado",
      "NO_DATA_TEXT": "No hay organizaciones de subcontratación que mostrar.",
      "NAME_COLUMN_TEXT": "Nombre",
      "ENTITY_COLUMN_TEXT": "Sitio/departamento",
      "RESOURCES_COLUMN_TEXT": "Recursos",
      "HOURS_COLUMN_TEXT": "Horas disponibles",
      "COST_COLUMN_TEXT": "Coste",
      "LAST_EDITED_COLUMN_TEXT": "Editado por",
      "OPTIONS_COLUMN_TEXT": "Opciones",
      "UNLIMITED_TEXT": "Ilimitado",
      "ALWAYS_24_TEXT": "Siempre (24 horas)",
      "PER_HOUR_TEXT": "/hora",
      "PER_DAY_TEXT": "/día",
      "ORG_WIDE_TEXT": "En toda la organización",
      "LINK_EDIT": "Editar",
      "LINK_DELETE": "Borrar",
      "LINK_REINSTATE": "Reinstaurar",
      "PILL_DELETED": "Eliminado",
      "ERROR_500": "No podemos cargar organizaciones de subcontratación ahora mismo. Vuelva a intentarlo.",
      "ERROR_403": "No tiene permiso para ver o modificar las organizaciones de subcontratación.",
      "ALERT_CONFIRM_DELETE": "¿Está seguro de que desea eliminar esta organización de subcontratación?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Su organización de subcontratación se ha salvado.",
        "ALERT_ERROR_500": "Se ha producido un error al crear tu organización de subcontratación. Vuelve a intentarlo o ponte en contacto con nosotros si el mensaje persiste.",
        "ALERT_ERROR_400": "Ya existe una organización de subcontratación con ese nombre. Por favor, elige otro.",
        "EDIT_TITLE": "Editar organización",
        "ADD_TITLE": "Añadir organización",
        "NAME_LABEL": "Nombre",
        "ENTITY_LABEL": "Sitio/departamento",
        "PAY_LABEL": "Pagar",
        "HOURLY_OPTION": "Pagado por hora",
        "FIXED_OPTION": "Coste fijo por día",
        "HOURLY_RATE_LABEL": "Tarifa por hora",
        "DAILY_RATE_LABEL": "Tarifa diaria",
        "AUTOMATIC_SCHEDULE_TITLE": "Programación automatizada",
        "HOURS_AVAILABLE_LABEL": "Horas disponibles",
        "TO_TEXT": " a ",
        "HOURS_AVAILABLE_HELP": "El tiempo durante el cual los trabajadores de esta organización están disponibles",
        "RESOURCES_AVAILABLE_LABEL": "Recursos disponibles",
        "RESOURCES_AVAILABLE_HELP": "El número máximo de trabajadores a su disposición por día (introduzca 0 para obtener un número ilimitado)",
        "TAGS_LABEL": "Etiquetas",
        "TAGS_PLACEHOLDER": "Escriba para buscar etiquetas",
        "TAGS_HELP": "Asigne etiquetas como lo haría a un miembro normal del personal. Estas se aplicarán a todos los trabajadores de esta organización.",
        "BUTTON_SAVE": "Guardar",
        "BUTTON_CANCEL": "Cancelar"
      }
    },
    "TRUSTED_DEVICES": {
      "TITLE": "Dispositivos de confianza",
      "DEVICE": "Dispositivo",
      "ENTITY": "Sitio/departamento principal",
      "DEVICE_TYPE": "Agente de usuario",
      "EDITED_BY": "Editado por",
      "LAST_ACTIVITY": "Última actividad",
      "STATUS": "Estado",
      "OPTIONS": "Opciones",
      "NO_DEVICES": "No hay dispositivos confiables para registrar la asistencia.",
      "NO_ACTIVITY": "Sin actividad",
      "LINK_UNTRUST": "Desconfianza",
      "LINK_TRUST": "Confianza",
      "LINK_EDIT": "Editar",
      "ALERT_CONFIRM_TRUST": "¿Está seguro de que desea confiar en este dispositivo para la captura de asistencia?",
      "ALERT_CONFIRM_UNTRUST": "¿Está seguro de que desea desconfiar de este dispositivo para que ya no pueda usarse para capturar la asistencia? Esto no se puede revertir.",
      "ERROR_500": "No podemos cargar dispositivos de confianza en este momento. Por favor, inténtelo de nuevo.",
      "ERROR_403": "No tienes permiso para ver o modificar los dispositivos de confianza.",
      "STATUS_PENDING": "Pendiente",
      "STATUS_TRUSTED": "De confianza",
      "STATUS_UNTRUSTED": "No es de confianza",
      "FILTER_SITE": "Sitio/departamento cartografiado",
      "FILTER_STATUS": "Estado",
      "BUTTON_NEW_DEVICE": "Nuevo dispositivo",
      "NEW_DEVICE_MODAL": {
        "TITLE": "Añadir un dispositivo nuevo",
        "BUTTON_CANCEL": "Cancelar",
        "BUTTON_DONE": "Continuar",
        "TEXT_STEP_1": "Paso 1",
        "TEXT_STEP_2": "Paso 2",
        "TEXT_STEP_1_BODY": "Toma tu tableta o portátil, abre un navegador web y navega hasta la siguiente URL:",
        "TEXT_STEP_2_BODY_A": "Cuando se te pida un «ID de dominio», introduce el siguiente valor:",
        "TEXT_STEP_2_BODY_B": "Cuando se le pida un «ID de entidad», introduzca el siguiente valor:",
        "TEXT_STEP_3_BODY": "Cuando hayas terminado, haz clic en Continuar para volver a la lista de dispositivos. A continuación, debería aparecer tu nuevo dispositivo. Haz clic en Confiar junto al dispositivo para completar el proceso de emparejamiento."
      },
      "EDIT_DEVICE_MODAL": {
        "TITLE": "Editar dispositivo",
        "BUTTON_CANCEL": "Cancelar",
        "BUTTON_SAVE": "Guardar",
        "BODY": "Al seleccionar más sitios o departamentos a continuación, los empleados asociados aparecerán en la pantalla de inicio del dispositivo. No puedes deseleccionar el sitio o departamento principal; debes desconfiar del dispositivo si deseas cambiarlo.",
        "ALERT_200": "Se ha actualizado el dispositivo.",
        "ALERT_500": "No pudimos actualizar el dispositivo en ese momento. Vuelva a intentarlo más tarde.",
        "LABEL_NAME": "Nombre del dispositivo"
      }
    }
  },
  "MESSAGING": {
    "BETA": "BETA",
    "MESSAGE_HUB": {
      "TITLE": "Mensajes",
      "NO_MESSAGES": "No hay mensajes",
      "START_NEW": "Iniciar una nueva conversación",
      "YESTERDAY": "ayer"
    },
    "CONVERSATION": {
      "MESSAGE_PLACEHOLDER": "Añade tu mensaje",
      "INDIVIDUAL_TYPING": "{{name}} está escribiendo...",
      "MULTIPLE_TYPING": "Varias personas están escribiendo..."
    },
    "START_NEW": {
      "TITLE": "Iniciar una nueva conversación",
      "TO": "Para:",
      "REMOVE": "Eliminar",
      "START_CONVERSATION": "Iniciar conversación"
    },
    "ALERTS": {
      "START_CONVERSATION": "Se ha producido un error al iniciar la conversación. Por favor, inténtelo de nuevo.",
      "SUBSCRIBE_TO_CHANNELS": "Hay un problema al suscribirse a tus conversaciones. Es posible que no reciba ningún mensaje. Actualiza o ponte en contacto con nosotros si el problema persiste.",
      "FETCH_MESSAGES": "Hay un problema al recibir tus mensajes. Actualiza o ponte en contacto con nosotros si el problema persiste.",
      "FETCH_NEW_MESSAGES": "Se ha producido un error al recibir mensajes nuevos. Actualiza o ponte en contacto con nosotros si el problema persiste.",
      "FETCH_CONVERSATIONS": "Se ha producido un error al recuperar tus conversaciones. Actualiza o ponte en contacto con nosotros si el problema persiste.",
      "SEND_MESSAGE": "Se ha producido un error al enviar tu mensaje. Por favor, inténtelo de nuevo."
    }
  },
  "ABSENCE": {
    "REQUEST": {
      "TITLE": "Solicitud de ausencia",
      "BUTTON_SUBMIT": "Enviar",
      "LABEL_TYPE": "Elige un tipo",
      "LABEL_REASON": "Selecciona un motivo",
      "LABEL_EMPLOYEE": "Empleado",
      "LABEL_START_DATE": "Fecha de inicio",
      "LABEL_END_DATE": "Fecha de finalización",
      "LABEL_SCOPE_ALL_DAY": "Todo el día",
      "LABEL_SCOPE_AM": "Solo AM",
      "LABEL_SCOPE_PM": "Solo PM",
      "LABEL_SCOPE_CUSTOM": "Elige horas específicas",
      "LABEL_START_TIME": "Hora de inicio",
      "LABEL_END_TIME": "Hora de finalización",
      "ALERT_OVERLAPPING_ABSENCE_USER": "Tienes otra reserva de ausencia que coincide con las fechas elegidas.",
      "ALERT_OVERLAPPING_ABSENCE_OTHERS": "Para que lo sepas, hay otras personas que también están ausentes en este momento.",
      "ERROR_500": "En ese momento nos topamos con un problema. ¡Vuelva a intentarlo!",
      "LABEL_DAYS": "días",
      "LABEL_HOURS": "horas",
      "SECTION_UNPAID_DAYS": "Días libres regulares",
      "HELP_TEXT_NO_UNPAID_DAYS": "No hay días libres regulares en esta solicitud.",
      "BUTTON_OPTIONS": "Opciones",
      "BUTTON_CHANGE": "Cambiar los días libres regulares",
      "BUTTON_RESET": "Revertir los días libres regulares a sugeridos",
      "BUTTON_CLEAR": "Borra todos los días libres habituales",
      "MODAL_CALENDAR_TITLE": "Elige días libres regulares",
      "MODAL_BUTTON_SAVE": "Guardar",
      "LABEL_ADD_A_NOTE": "Agregar una nota a esta solicitud",
      "BUTTON_COMPLETE": "Llévame de vuelta",
      "COMPLETE_TITLE": "Ya está todo listo.",
      "COMPLETE_LABEL_APPROVED": "Aprobado",
      "COMPLETE_TEXT_APPROVED": "Su solicitud se ha enviado y aprobado automáticamente ✅",
      "COMPLETE_LABEL_PENDING": "Pendiente de aprobación",
      "COMPLETE_TEXT_PENDING": "Su solicitud se ha enviado y está pendiente de aprobación. Te avisaremos cuando esto cambie 👍"
    },
    "VALIDATION_MODAL": {
      "TITLE": "Algo no está del todo bien",
      "DESCRIPTION": "Lamentablemente, lo que intentas hacer provoca uno o más problemas.",
      "BUTTON_CLOSE": "Cerrar",
      "INVALID_DATES_TITLE": "Fechas no válidas",
      "INVALID_DATES_DESCRIPTION": "Solo puedes elegir un horario por la mañana, solo por la tarde o personalizado si tu fecha de inicio y finalización es el mismo día.",
      "INVALID_HEADER_TITLE": "Registro de ausencias no válido",
      "INVALID_HEADER_DESCRIPTION": "No puedes editar ese registro de ausencias. Puede que se haya eliminado.",
      "INVALID_ACTION_TITLE": "Acción no válida",
      "INVALID_ACTION_DESCRIPTION": "No puedes cambiar la hora de una reserva de ausencia después de que se haya rechazado o cancelado.",
      "INVALID_DAYS_TITLE": "Días no válidos",
      "INVALID_DAYS_DESCRIPTION": "No puedes realizar una solicitud de ausencia de cero horas o cero días.",
      "OUTSIDE_EMPLOYMENT_TITLE": "Empleo externo",
      "OUTSIDE_EMPLOYMENT_DESCRIPTION": "La fecha de inicio o finalización elegida no coincide con las fechas de empleo del empleado.",
      "SPANS_TIMEZONES_TITLE": "El rango de fechas abarca dos zonas horarias",
      "SPANS_TIMEZONES_DESCRIPTION": "Los registros de citas del empleado indican que se mudan de sitio durante las fechas elegidas y que su sitio actual se encuentra en una zona horaria diferente a la del nuevo sitio. Asegúrate de que tu reserva de ausencia finalice antes de que se muden de sitio o comience después de que se muden de sitio.",
      "SPANS_PAYROLL_CALENDARS_TITLE": "El rango de fechas abarca dos calendarios de nómina",
      "SPANS_PAYROLL_CALENDARS_DESCRIPTION": "Los registros de citas del empleado indican que cambian el calendario de nómina durante las fechas elegidas. Asegúrese de que su reserva de ausencias finalice antes de que cambien el calendario de nómina o comience después de que cambien el calendario de nómina.",
      "INVALID_PAYROLL_CALENDAR_TITLE": "Calendario de nómina no válido",
      "INVALID_PAYROLL_CALENDAR_DESCRIPTION": "El intervalo de fechas elegido comienza antes de la primera ejecución salarial del calendario de nóminas del empleado.",
      "OVERLAPPING_ABSENCE_TITLE": "Ausencia superpuesta",
      "OVERLAPPING_ABSENCE_DESCRIPTION": "El empleado ya está ausente durante uno o más días que coinciden con las fechas elegidas.",
      "OVERLAPPING_SHIFTS_TITLE": "Turnos superpuestos",
      "OVERLAPPING_SHIFTS_DESCRIPTION": "El empleado tiene uno o más turnos publicados que coinciden con las fechas elegidas. Primero se deben eliminar o anular la asignación.",
      "SIGNED_OFF_TITLE": "Fechas firmadas",
      "SIGNED_OFF_DESCRIPTION": "Las fechas se superponen con uno o más días en los que se ha firmado. No puedes aprobar ni cancelar las ausencias de los días que ya se hayan firmado.",
      "ALLOWANCE_REQUIRED_TITLE": "Subsidio requerido",
      "ALLOWANCE_REQUIRED_DESCRIPTION": "No se pueden reservar ausencias de este tipo. Debe haber una asignación activa de la que deducir.",
      "ALLOWANCE_NOT_DEDUCTIBLE_TITLE": "Subsidio no deducible",
      "ALLOWANCE_NOT_DEDUCTIBLE_DESCRIPTION": "No se pueden reservar ausencias de este tipo. Hay una asignación activa, pero está marcada como no deducible.",
      "ALLOWANCE_BALANCE_INSUFFICIENT_TITLE": "Saldo insuficiente",
      "ALLOWANCE_BALANCE_INSUFFICIENT_DESCRIPTION": "El saldo de la asignación es demasiado bajo para enviar esta solicitud.",
      "FILE_UPLOAD_REQUIRED_TITLE": "Es necesario subir el archivo",
      "FILE_UPLOAD_REQUIRED_DESCRIPTION": "Este tipo de ausencia requiere que subas un archivo antes de enviarlo o aprobarlo."
    },
    "VIEW_MODAL": {
      "TITLE": "Ausencia",
      "BUTTON_OPTIONS": "Opciones",
      "BUTTON_EDIT": "Editar/revisar la reserva",
      "BUTTON_CANCEL": "Cancelar reserva",
      "BUTTON_CLOSE": "Cerrar",
      "STATUS_CANCELLED": "Cancelado",
      "LABEL_START_DATE": "Empieza",
      "LABEL_END_DATE": "Termina",
      "LABEL_ABSENCE_TYPE": "Tipo",
      "LABEL_SCOPE": "Duración",
      "ERROR_500": "Nos topamos con un pequeño inconveniente. Vuelva a intentarlo.",
      "ERROR_404": "Esa solicitud de ausencia ya no existe.",
      "LABEL_SUBMITTED_BY": "Presentado por",
      "LABEL_REVIEWED_BY": "Revisado por",
      "LABEL_CANCELLED_BY": "Cancelado por",
      "LABEL_EDITED_BY": "Editado por",
      "DURATION_TEXT": "{{hours}} horas, {{days}} días",
      "TAB_BREAKDOWN": "Desglose",
      "TITLE_PAY_PERIODS": "Por periodo de pago",
      "NO_PAY_PERIODS": "El empleado no estaba asociado a un calendario de nómina cuando se hizo la reserva.",
      "TITLE_DAILY_BREAKDOWN": "Por día",
      "NO_DAYS": "No hay días en esta reserva.",
      "TAB_FILES": "Documentos",
      "FILES_NONE": "No se ha subido nada para esta reserva.",
      "FILES_AVAILABLE": "Estos documentos también se pueden encontrar en el área de personal al ver el perfil del empleado. Los encontrarás en esta categoría: {{categoryName}}",
      "FILES_COLUMN_NAME": "Nombre",
      "FILES_COLUMN_UPLOADED_BY": "Subido por",
      "FILES_COLUMN_OPTIONS": "Opciones",
      "FILES_LINK_DELETE": "Borrar",
      "FILES_CONFIRM_DELETE": "¿Está seguro de que desea eliminar esto? También se eliminará de la pestaña Documentos del perfil del empleado.",
      "CONFIRM_CANCEL": "¿Estás seguro de que deseas cancelar esta reserva de ausencia? Esto no se puede deshacer.",
      "ALERT_IS_CANCELLED": "La reserva de ausencia se ha cancelado.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "¡Advertencia! El solicitante eligió una hora de inicio y finalización que equivale a más de {{HalfDayHours}} horas, que es la cantidad de horas que trabaja actualmente durante medio día.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "¡Advertencia! El solicitante optó por modificar los días libres habituales, en lugar de utilizar los días sugeridos por Rotaready."
    },
    "EDIT": {
      "TITLE": "Ausencia",
      "BUTTON_SUBMIT": "Guardar",
      "BUTTON_APPROVE": "Aprobar",
      "BUTTON_REJECT": "Rechazar",
      "TAB_EDIT_BOOKING": "Editar fechas",
      "TAB_REVIEW": "Revisar",
      "TAB_FILES": "Documentos",
      "TAB_CALENDAR": "Calendario",
      "LABEL_TYPE": "Tipo",
      "LABEL_REASON": "Selecciona un motivo",
      "LABEL_EMPLOYEE": "Empleado",
      "LABEL_START_DATE": "Fecha de inicio",
      "LABEL_END_DATE": "Fecha de finalización",
      "LABEL_SCOPE_ALL_DAY": "Todo el día",
      "LABEL_SCOPE_AM": "Solo AM",
      "LABEL_SCOPE_PM": "Solo PM",
      "LABEL_SCOPE_CUSTOM": "Elige horas específicas",
      "LABEL_START_TIME": "Hora de inicio",
      "LABEL_END_TIME": "Hora de finalización",
      "ERROR_500": "En ese momento nos topamos con un problema. ¡Vuelva a intentarlo!",
      "LABEL_DAYS": "días",
      "LABEL_HOURS": "horas",
      "SECTION_UNPAID_DAYS": "Días libres regulares",
      "HELP_TEXT_NO_UNPAID_DAYS": "No hay días libres regulares en esta solicitud.",
      "BUTTON_OPTIONS": "Opciones",
      "BUTTON_CHANGE": "Cambiar los días libres regulares",
      "BUTTON_RESET": "Volver a la reserva original",
      "BUTTON_RECALCULATE": "Recalcular de nuevo",
      "BUTTON_CLEAR": "Borra todos los días libres habituales",
      "UNPAID_DAYS_RECALCULATED_LABEL": "Recalculado",
      "UNPAID_DAYS_RECALCULATED_HELP": "Has decidido volver a calcular los días libres habituales y utilizar el valor actual para las «horas por día laborable» del empleado en lugar del valor que tenía cuando se hizo la reserva originalmente.",
      "UNPAID_DAYS_MERGED_LABEL": "Fusionado",
      "UNPAID_DAYS_MERGED_HELP": "Desde que cambiaste la fecha, hemos incluido los nuevos días libres, hemos eliminado los que ya no están disponibles y hemos dejado intactos los demás.",
      "MODAL_CALENDAR_TITLE": "Elige días libres regulares",
      "MODAL_BUTTON_SAVE": "Guardar",
      "BUTTON_COMPLETE": "Llévame de vuelta",
      "COMPLETE_TITLE": "Estás listo para irte.",
      "COMPLETE_TEXT": "Hemos guardado la reserva de ausencias y se ha notificado a las personas pertinentes 👍",
      "INVALID_HEADER_TITLE": "Ausencia inválida",
      "INVALID_HEADER_TEXT": "No podemos cargar esa reserva de ausencia. Quizás se haya cancelado o no tengas permiso para verlo.",
      "TITLE_OVERLAPPING_ABSENCE": "Ausencia concurrente",
      "TITLE_OVERLAPPING_SHIFTS": "Turnos superpuestos",
      "TITLE_NOTABLE_EVENTS": "Acontecimientos notables",
      "COLUMN_OVERLAPPING_ABSENCE_DATES": "Fechas",
      "COLUMN_OVERLAPPING_ABSENCE_TYPE": "Tipo",
      "COLUMN_OVERLAPPING_ABSENCE_WHO": "Quién",
      "NO_OVERLAPPING_ABSENCE": "No hay ninguna ausencia simultánea que coincida con tus filtros.",
      "NO_OVERLAPPING_SHIFTS": "No hay turnos superpuestos en el sitio o departamento del empleado.",
      "NO_NOTABLE_EVENTS": "No hay hechos destacables en el momento de esta ausencia.",
      "COLUMN_OVERLAPPING_SHIFTS_DATE": "Fecha",
      "COLUMN_OVERLAPPING_SHIFTS_SITE": "Sitio/departamento",
      "COLUMN_OVERLAPPING_SHIFTS_TYPE": "Tipo de turno",
      "COLUMN_OVERLAPPING_SHIFTS_OPTIONS": "Opciones",
      "LINK_EDIT_SHIFT": "Editar rotación",
      "LINK_DELETE_SHIFT": "Borrar",
      "BUTTON_BULK_SHIFT_OPTIONS": "Acciones masivas",
      "BUTTON_BULK_SHIFT_UNASSIGN_ALL": "Anular la asignación seleccionada",
      "BUTTON_BULK_SHIFT_DELETE_ALL": "Eliminar lo seleccionado",
      "ALERT_BULK_SHIFT_CONFIRM": "¿Estás seguro de que deseas hacer esto para los turnos de {{count}}?",
      "ALERT_BULK_SHIFT_EDIT_FAILURE": "No pudimos modificar los turnos porque eso provocaría un problema de validación. Realice este cambio en la rotación para ver más información.",
      "ALERT_SINGLE_SHIFT_DELETE_FAILURE": "No pudimos eliminar ese turno porque provocaría un problema de validación. Realice este cambio en la rotación para ver más información.",
      "COLUMN_NOTABLE_EVENTS_NAME": "Nombre",
      "COLUMN_NOTABLE_EVENTS_DATE": "Fecha",
      "NOTABLE_EVENTS_ABSENCE_RESTRICTION_TOOLTIP": "La ausencia está restringida durante este evento.",
      "LABEL_ADD_A_NOTE": "Si lo desea, escriba un mensaje para el empleado",
      "OVERLAPPING_SHIFTS_MORE": "Solo mostramos los primeros quince turnos superpuestos. Resuélvalos para ver más.",
      "OVERLAPPING_ABSENCE_MORE": "Solo mostramos las primeras quince reservas superpuestas.",
      "CALENDAR_UNPAID_DAY": "Día libre regular",
      "FILTER_LABEL_ENTITY": "Sitio/departamento",
      "FILTER_LABEL_STAFF_GROUP": "Grupo de personal",
      "FILTER_LABEL_ABSENCE_TYPE": "Tipo",
      "FILES_NONE": "No se ha subido nada para esta reserva.",
      "FILES_AVAILABLE": "Estos documentos también se pueden encontrar en el área de personal al ver el perfil del empleado. Los encontrarás en esta categoría: {{categoryName}}",
      "FILES_COLUMN_NAME": "Nombre",
      "FILES_COLUMN_UPLOADED_BY": "Subido por",
      "FILES_COLUMN_OPTIONS": "Opciones",
      "FILES_LINK_DELETE": "Borrar",
      "FILES_CONFIRM_DELETE": "¿Está seguro de que desea eliminar esto? También se eliminará de la pestaña Documentos del perfil del empleado.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "¡Advertencia! El solicitante eligió una hora de inicio y finalización que equivale a más de {{HalfDayHours}} horas, que es la cantidad de horas que trabaja actualmente durante medio día.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "¡Advertencia! El solicitante optó por modificar los días libres habituales, en lugar de utilizar los días sugeridos por Rotaready. Revíselas detenidamente en la pestaña Editar fechas y elija la opción Recalcular de nuevo si es necesario."
    },
    "ENUM_SCOPE": {
      "ALL_DAY": "Todo el día",
      "AM": "Solo AM",
      "PM": "Solo PM",
      "CUSTOM": "Horario personalizado"
    },
    "ENUM_APPROVAL_STATE": {
      "APPROVED": "Aprobado",
      "REJECTED": "Rechazado",
      "PENDING": "Pendiente"
    },
    "ENUM_ALLOWANCE_UNIT": {
      "DAYS": "Días",
      "HOURS": "Horas"
    },
    "ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL": {
      "BEGINNING_OF_ACCRUAL_YEAR": "Inicio del año de devengo",
      "EACH_CALENDAR_MONTH": "Cada mes natural",
      "EACH_HOUR_WORKED": "Cada hora trabajada"
    },
    "ALLOWANCE_IMPACT_DIRECTIVE": {
      "DAYS_DEBIT": "Deducción de {{count}} días",
      "HOURS_DEBIT": "Deducción de {{count}} horas",
      "DAYS_CREDIT": "Crédito diario de {{count}}",
      "HOURS_CREDIT": "Crédito de {{count}} horas",
      "REMAINING": "(Quedan {{count}})",
      "WARNING_EXCEEDS": "Tu solicitud supera este límite, pero aún se puede enviar.",
      "WARNING_NOT_PERMITTED": "Su solicitud supera esta asignación.",
      "TOOLTIP_PROJECTED": "Rotaready ha incluido una predicción de lo que es probable que acumule. Esto puede estar limitado de acuerdo con las políticas de su empleador.",
      "CALC_TOTAL_ACCRUED": "Total acumulado",
      "CALC_BOOKED": "Reservado",
      "CALC_PROJECTED": "Acumulación prevista",
      "CALC_THIS_REQUEST": "Esta solicitud",
      "CALC_REMAINING": "Restante",
      "CALC_CONVERTED_TO_DAYS": "Como días"
    }
  }
};

module.exports = translations;
