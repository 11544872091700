const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/shiftRoutines/directive.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/shiftRoutines/routineShiftModal.html');

var directives = require('../module');

directives.directive('rrShiftRoutine', ['$uibModal', '$q', 'SettingsService', 'AlertService', function($uibModal, $q, SettingsService, AlertService) {
    return {
        scope: {
            routine: '=',
            deleteRoutine: '&',
            saveActioning: '='
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: function($scope) {
            $scope.addItem = function() {
                if ($scope.saveActioning) {
                    return;
                }

                var newItem = {
                    displayOrder: $scope.routine.items.length,
                    typeId: 0,
                    length: 4
                };

                $scope.saveActioning = true;

                SettingsService.addItem($scope.routine.id, { item: newItem })
                    .success(function (data) {
                        $scope.routine.items = data.routine.items;

                        $scope.saveActioning = false;
                    })
                    .catch(function (err, status) {
                        if (status !== 403) {
                            AlertService.add('danger', 'We couldn\'t save the shift routine just then. Please try again');
                        }

                        $scope.saveActioning = false;
                    });
            };

            function openEditShiftModal(index, readOnly) {
                var shift;

                var item = $scope.routine.items[index];

                // Create the default shift
                if (!item.shift) {
                    shift = {
                        start: moment().set({hour: 9, minutes: 0, seconds: 0}),
                        end: moment().set({hour: 17, minutes: 0, seconds: 0}),
                        rest: moment().startOf('day'),
                        paid: true,
                    };
                } else {
                    shift = angular.copy(item.shift);
                }

                var modalInstance = $uibModal.open({
                    templateUrl: autoNgTemplateLoaderTemplate2,
                    controller: 'RoutineShiftModalCtrl',
                    keyboard: false,
                    backdrop: 'static',
                    resolve: {
                        data: function () {
                            return {
                                shift: shift,
                                readOnly: readOnly
                            };
                        }
                    }
                });

                return modalInstance.result;
            }

            function updateItem(index) {
                if (!$scope.routineForm.$valid) return;

                var item = $scope.routine.items[index];

                $scope.saveActioning = true;

                return SettingsService.updateItem($scope.routine.id, item.id, { item: item })
                    .success(function (data) {
                        $scope.routine.items = data.routine.items;
                        $scope.saveActioning = false;

                        return $q.resolve();
                    })
                    .catch(function (err, status) {
                        if (status !== 403) {
                            AlertService.add('danger', 'We couldn\'t save the shift routine just then. Please try again');
                        }

                        $scope.saveActioning = false;

                        return $q.reject();
                    });
            }

            $scope.typeSelected = function(index) {
                var item = $scope.routine.items[index];

                if (item.typeId == 1) {
                    openEditShiftModal(index)
                        .then(function (newShift) {
                            item.shift = newShift;

                            updateItem(index)
                                .catch(function () {
                                    item.shift = false;
                                    item.typeId = 0;
                                });
                        }, function () {
                            item.typeId = 0;
                        });
                } else {
                    var shift = item.shift;

                    item.shift = false;

                    updateItem(index)
                        .catch(function () {
                            item.shift = shift;
                            item.typeId = 1;
                        });
                }
            };

            $scope.lengthChanged = function(index) {
                updateItem(index);
            };

            function moveItem(from, to) {
                $scope.routine.items[from].displayOrder = to;

                updateItem(from)
                    .catch(function () {
                        $scope.routine.items[from].displayOrder = from;
                    });
            }

            $scope.moveUp = function(index) {
                if(index != 0 && !$scope.routine.numberOfMappings) {
                    moveItem(index, index-1);
                }
            };

            $scope.moveDown = function(index) {
                if(index != $scope.routine.items.length-1 && !$scope.routine.numberOfMappings) {
                    moveItem(index, index+1);
                }
            };

            $scope.deleteItem = function(index) {
                $scope.saveActioning = true;

                SettingsService.deleteItem($scope.routine.id, $scope.routine.items[index].id)
                    .success(function (data) {
                        $scope.routine.items = data.routine.items;
                        $scope.saveActioning = false;
                    })
                    .error(function (err, status) {
                        if (status !== 403) {
                            AlertService.add('danger', 'We couldn\'t save the shift routine just then. Please try again');
                        }

                        $scope.saveActioning = false;
                    });

            };

            $scope.editShift = function(index, readOnly) {
                var item = $scope.routine.items[index];

                openEditShiftModal(index, readOnly)
                    .then(function (newShift) {
                        if (readOnly) {
                            return;
                        }

                        item.shift = newShift;

                        updateItem(index)
                            .catch(function () {
                                item.shift = false;
                                item.typeId = 0;
                            });
                    });
            };
        }
    };
}]);
