module.exports = angular.module('rotareadyUiApp.config', [])
  .constant('VISA_ENDPOINT_AUTH', 'https://visa.rotaready.com')
  .constant('ENDPOINT_API', 'https://api.rotaready.com')
  .constant('ENDPOINT_GRAPHQL', 'https://graphql.rotaready.com/')
  .constant('PUSHER_KEY', '2d35b3db4436e60a679f')
  .constant('FEED_LOCATION', 'https://feeds.rotaready.com')
  .constant('WFM_ENDPOINT', 'https://wfm.rotaready.com')
  .constant('COOKIE_DOMAIN', '.rotaready.com')
  .constant('ACCESS_TOKEN_COOKIE_NAME', 'rr_access_token')
  .constant('REFRESH_TOKEN_COOKIE_NAME', 'rr_refresh_token')
  .constant('SELECTED_ENTITY_COOKIE_NAME', 'rr_selected_entity')
  .constant('INTERCOM_ID', 'kd3kz4na')
  .constant('PUBNUB_SUBSCRIBE_KEY', process.env.PUBNUB_SUBSCRIBE_KEY)
  .constant('PUBNUB_PUBLISH_KEY', process.env.PUBNUB_PUBLISH_KEY)
  .constant('ENV', 'prod')
  .constant('EVENT_CALENDAR_ENABLED', true)
  .constant('ASSET_REGISTRY_ENABLED', true)
  .constant('ASYNC_REPORTS_ENABLED', true)
  .constant('NEW_HR_FEATURES_ENABLED', true)
  .constant('DOCUMENT_CATEGORIES_ENABLED', true)
  .constant('VISAS_ENABLED', true)
  .constant('WFM_OUTSOURCED_GROUPS_ENABLED', false)
  .constant('ENTITY_GROUP_MANAGER_ENABLED', true);
