const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/availabilityRule.html');

var directives = require('../module');

directives.directive('rrAvailabilityRule', ['$translate', function ($translate) {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        scope: {
            start: '=',
            end: '=',
            optionId: '=',
            name: '@',
            prefix: '@',
            shortList: '=',
            hidePrefixes: '=',
            horizontal: '=',
            defaultId: '@'
        },
        controller: function($scope) {
            var labelTranslations = $translate.instant([
                'DIRECTIVES.STAFF.AVAILABILITY_RULE.ALL_DAY',
                'DIRECTIVES.STAFF.AVAILABILITY_RULE.AM_ONLY',
                'DIRECTIVES.STAFF.AVAILABILITY_RULE.PM_ONLY',
                'DIRECTIVES.STAFF.AVAILABILITY_RULE.AVAIALABLE_CUSTOM',
                'DIRECTIVES.STAFF.AVAILABILITY_RULE.UNAVAILABLE_CUSTOM',
                'DIRECTIVES.STAFF.AVAILABILITY_RULE.UNAVAILABLE',
                'DIRECTIVES.STAFF.AVAILABILITY_RULE.AVAILABLE',
                'DIRECTIVES.STAFF.AVAILABILITY_RULE.AVAILABLE_AM',
                'DIRECTIVES.STAFF.AVAILABILITY_RULE.AVAILABLE_PM',
            ]);

            if($scope.shortList) {
                $scope.availability = [
                    {id: 0, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.ALL_DAY']},
                    {id: 3, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.AM_ONLY']},
                    {id: 2, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.PM_ONLY']},
                    {id: 4, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.AVAIALABLE_CUSTOM']},
                    {id: 5, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.UNAVAILABLE_CUSTOM']}
                ];
            } else {
                $scope.availability = [
                    {id: 0, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.UNAVAILABLE']},
                    {id: 1, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.AVAILABLE']},
                    {id: 2, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.AVAILABLE_AM']},
                    {id: 3, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.AVAILABLE_PM']},
                    {id: 4, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.AVAIALABLE_CUSTOM']},
                    {id: 5, name: labelTranslations['DIRECTIVES.STAFF.AVAILABILITY_RULE.UNAVAILABLE_CUSTOM']}
                ]
            }

            $scope.hideTimeControls = function() {
                $scope.optionId = $scope.defaultId ? parseInt($scope.defaultId) : 0;
            };
        }
    }
}]);
