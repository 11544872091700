const locales = require('../../../constants/locales');

module.exports = (
  $scope,
  $translate,
  $q,
  AlertService,
  CommonService,
  StaffService,
  SessionService,
  EnvironmentDataService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.VIEW.ACCOUNT.SAVE_SUCCESS',
    'STAFF.VIEW.ACCOUNT.ERROR_403',
    'STAFF.VIEW.ACCOUNT.ERROR_500',
  ]);

  const {
    userData: {
      id: userId,
      account: {
        locale,
        dateLastVisited,
        permissionLevelId,
        entityAccess,
      },
      appointment: {
        entityId,
      },
    },
    $parent: {
      userData: {
        flags: {
          selfOnboardingStatus,
        },
      },
    },
  } = $scope;

  $scope.props = {
    loadingData: true,
    permitted: true,
    dateLastVisitedFromNow: dateLastVisited ? moment(dateLastVisited).fromNow() : undefined,
    dateLastVisitedFormatted: dateLastVisited ? moment(dateLastVisited).format('lll') : undefined,
    localeList: locales,
    userSelfOnboardingEnabled: SessionService.isUserSelfOnboardingEnabled(),
    formData: {
      locale,
      permissionLevelId,
      entityAccessType: entityAccess.type,
      entityAccessIds: entityAccess.ids || [],
    },
  };

  $q.all([
    EnvironmentDataService.fetchAll([
      EnvironmentDataService.DataType.EntityGroup,
      EnvironmentDataService.DataType.PermissionLevel,
      EnvironmentDataService.DataType.Tag,
    ]),
    // todo replace with dedicated endpoint when available
    CommonService.getMetadata(['usersTags'], false, true, userId),
    StaffService.getEntityGroupSubscriptions(userId),
  ]).then(([
    environmentData,
    metadata,
    entityGroupSubscriptions,
  ]) => {
    const [
      entityGroup,
      permissionLevel,
      tag,
    ] = environmentData;
    const entityGroupSubscriptionsMap = new Map(
      entityGroupSubscriptions.data.results.map((result) => [result.entityGroupId, result])
    );

    $scope.props.entityGroupList = entityGroup.data.map((data) => ({
      ...data,
      isUserSubscribed: entityGroupSubscriptionsMap.has(data.id),
    }));
    $scope.props.permissionLevelList = permissionLevel.data
      .map(({ id, name }) => ({ id, label: name }));
    $scope.props.tagList = tag.data
      .filter((tag) => (!tag.entityId || tag.entityId === entityId) && !tag.deleted)
      .map(({ id, name }) => ({ id, name }));

    const { usersTags } = metadata.data;
    $scope.props.userTags = usersTags.join(',');

    $scope.props.loadingData = false;
    $scope.props.permitted = true;
    $scope.props.hasAnyEntityGroupSubscriptions = entityGroupSubscriptionsMap.size > 0;
  }).catch(({ status }) => {
    $scope.props.loadingData = false;

    if (status === 403) {
      $scope.props.permitted = false;
      return;
    }

    AlertService.add('danger', translations['STAFF.VIEW.ACCOUNT.ERROR_500']);
  });

  $scope.onEntityAccessUpdate = (entityIds) => {
    $scope.props.formData.entityAccessIds = entityIds;
  };

  $scope.save = () => {
    const {
      formData: {
        locale,
        permissionLevelId,
        kioskPin,
        entityAccessType,
        entityAccessIds,
        selfOnboardingStatus,
      },
      permitted,
      userTags,
    } = $scope.props;

    if (!permitted) {
      return;
    }

    $scope.props.saveActioning = true;

    StaffService.updateAccount(userId, {
      user: {
        locale,
        permissionLevelId,
        kioskPin,
        selfOnboardingStatus,
      },
      entityAccess: {
        type: entityAccessType,
        ids: entityAccessType === 3 ? entityAccessIds : undefined,
      },
      tags: userTags,
    })
      .then(() => {
        Object.assign($scope.userData.account, {
          locale,
          permissionLevelId,
          selfOnboardingStatus,
          entityAccess: {
            type: entityAccessType,
            ids: entityAccessType === 3 ? entityAccessIds : undefined,
          },
        });

        // If this is the current user and they have updated themselves, force a session refresh
        if (userId === SessionService.getUserId()) {
          SessionService.init(true, () => {});
        }

        $scope.props.saveActioning = false;
        AlertService.add('success', translations['STAFF.VIEW.ACCOUNT.SAVE_SUCCESS']);
      })
      .catch(({ status }) => {
        $scope.props.saveActioning = false;

        if (status === 403) {
          AlertService.add('warning', translations['STAFF.VIEW.ACCOUNT.ERROR_403']);
          return;
        }

        AlertService.add('danger', translations['STAFF.VIEW.ACCOUNT.ERROR_500']);
      });
  };
};
