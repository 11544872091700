module.exports = (
  $scope,
  $state,
  ASYNC_REPORTS_ENABLED,
) => {
  'ngInject';

  $scope.goToJoinersLeavers = () => {
    const filter = btoa(JSON.stringify({
      employmentStartsOrEndsBetween: {
        option: 4,
        dateStart: moment().startOf('month').format('YYYY-MM-DD'),
        dateEnd: moment().endOf('month').format('YYYY-MM-DD'),
      },
    }));

    const columns = btoa(JSON.stringify([
      'appointmentEntityGroupName',
      'appointmentEntityName',
      'appointmentPositionName',
      'employmentJoinDate',
      'employmentLeaveDate',
      'employmentLeaveDateLastEditedFullName',
      'employmentLeaveDateLastEditedDate',
      'employmentLeaverReasonName',
      'employmentRecruitmentSourceName',
      'employmentLeaverRehire',
      'hrId',
      'payrollId',
      'employmentUkStarterDeclaration',
      'appointmentCompanyName',
      'appointmentLineManagerName',
      'appointmentEntityCurrencyCode',
      'appointmentPayrollCalendarName',
      'appointmentPayrollCalendarFrequency',
      'payAmount',
      'payAmountType',
      'employmentAllowancesFinalised',
      'employmentAllowancesFinalisedFullName',
      'employmentAllowancesFinalisedDate',
      'anonymisedDate',
    ]));

    $state.go('staff.overview', {
      filter,
      columns,
    });
  };

  $scope.shouldShowAsyncFileExport = ASYNC_REPORTS_ENABLED;
};
