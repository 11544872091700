const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/absenceTypes/editModal.html');

module.exports = (
  $scope,
  $uibModal,
  $state,
  $translate,
  AvailabilityService,
  EnvironmentDataService,
  AlertService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'SETTINGS.ABSENCE_TYPES.ERROR_500',
    'SETTINGS.ABSENCE_TYPES.ERROR_403',
    'SETTINGS.ABSENCE_TYPES.ALERT_CONFIRM_DELETE',
  ]);

  $scope.props = {
    loadingData: true,
    data: [],
    includeDeleted: false,
  };

  function onApiError(status) {
    $scope.props.loadingData = false;

    if (status === 500) {
      AlertService.add('danger', translations['SETTINGS.ABSENCE_TYPES.ERROR_500']);
      return;
    }

    if (status === 403) {
      AlertService.add('warning', translations['SETTINGS.ABSENCE_TYPES.ERROR_403']);
      return;
    }

    $state.go('settings.index');
  }

  function loadData() {
    $scope.props.loadingData = true;

    AvailabilityService.getAbsenceTypes()
      .then(({ data }) => {
        $scope.props.data = data.results
          .filter((row) => {
            if (!$scope.props.includeDeleted && row.deleted) {
              return false;
            }

            return true;
          });

        EnvironmentDataService.fetch(EnvironmentDataService.DataType.AbsenceTypeReason)
          .then(({ data }) => {
            $scope.props.absenceTypeReasonList = data;
            $scope.props.loadingData = false;
          });
      }).catch(({ status }) => onApiError(status));
  }

  loadData();

  $scope.onDeletedToggle = () => {
    loadData();
  };

  $scope.delete = (row) => {
    if (row.actionInProgress) {
      return;
    }

    if (!confirm(translations['SETTINGS.ABSENCE_TYPES.ALERT_CONFIRM_DELETE'])) {
      return false;
    }

    row.actionInProgress = true;

    AvailabilityService.deleteAbsenceType(row.id)
      .then(() => {
        loadData();
      })
      .catch(({ status }) => {
        row.actionInProgress = false;
        onApiError(status);
      });
  };

  $scope.reinstate = (row) => {
    if (row.actionInProgress) {
      return;
    }

    row.actionInProgress = true;

    AvailabilityService.updateAbsenceType(row.id, { deleted: false })
      .then(() => {
        loadData();
      })
      .catch(({ status }) => {
        row.actionInProgress = false;
        onApiError(status);
      });
  };

  $scope.edit = (absenceType) => {
    const modal = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./editModal'),
      resolve: {
        data: () => ({
          absenceType,
          reasons: absenceType
            ? $scope.props.absenceTypeReasonList
              .filter(({
                typeId,
                deleted,
              }) => !deleted && typeId === absenceType.id)
              .map(({ title }) => title)
            : [],
        }),
      },
    });

    modal.result.then((result) => {
      if (!result) {
        return;
      }

      loadData();
    });
  };
};
