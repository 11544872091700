'use strict';
var controllers = require('../../module');

controllers.controller('ViewTagModalCtrl', ['$scope', '$uibModalInstance', 'StaffService', 'data',
    function ($scope, $uibModalInstance, StaffService, data) {
        $scope.dataLoaded = false;
        $scope.saveTagActioning = false;
        $scope.deleteTagActioning = false;
        $scope.formData = {};

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.saveTag = function () {
            $scope.saveTagActioning = true;

            var fields = {
                name: $scope.formData.name,
                users: $scope.tagAssociations.map((t) => t.id),
            };

            if ($scope.formData.clone) {
                StaffService.addTag(fields)
                    .success(function (tag) {
                        $uibModalInstance.close();
                    })
                    .error(function (err) {
                        $scope.saveTagActioning = false;
                        alert('We were unable to save your tag.\n\nPlease check the name is unique and try again.');
                    });
            } else {
                StaffService.updateTag(data, fields)
                    .success(function (tag) {
                        $uibModalInstance.close();
                    })
                    .error(function (err) {
                        $scope.saveTagActioning = false;
                        alert('We were unable to save your tag.\n\nPlease check the name is unique and try again.');
                    });
            }
        };

        $scope.deleteTag = function () {
            if (!confirm('Are you sure you wish to delete this tag? All staff members associated with it will be untagged.'))
                return;

            $scope.deleteTagActioning = true;

            StaffService.deleteTag(data)
                .success(function () {
                    $uibModalInstance.close();
                })
                .error(function (err) {
                    $scope.deleteTagActioning = false;
                    alert('We were unable to delete your tag just now. Please try again.');
                });
        };

        StaffService.getTag(data)
            .success(function (result) {
                $scope.tag = result.tag;
                $scope.tagAssociations = result.associations;

                $scope.formData = {
                    clone: 0,
                    name: result.tag.name,
                };

                $scope.dataLoaded = true;
            })
            .error(function (error) {
                $uibModalInstance.dismiss('cancel');
            });

        $scope.onUserSelect = (user) => {
            const exists = $scope.tagAssociations.find((t) => t.id === user.id);

            if (!exists) {
              $scope.tagAssociations.push({
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
              });
            }
        };

        $scope.onTagDelete = (index) => {
          $scope.tagAssociations.splice(index, 1);
        };
    }]);
