const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/absence/requestCalendar.html');

module.exports = (
  $scope,
  $translate,
  $uibModal,
  AvailabilityService,
  AlertService,
) => {
  'ngInject';

  const isoDateFormat = 'YYYY-MM-DD';

  const translations = $translate.instant([
    'ABSENCE.EDIT.ERROR_500',
  ]);

  const timeSlotDate = moment.utc().startOf('day');
  const timeSlotMins = 5;

  $scope.props.timeSlots = [...Array((60 / timeSlotMins) * 24)]
    .map((_, i) => {
      const slot = timeSlotDate.clone().add(i * timeSlotMins, 'minutes');
      return {
        id: slot.format('HH:mm:ss'),
        label: slot.format('LT'),
      };
    });

  function recalculateTotals() {
    const {
      headerId,
      selectedDateStart,
      selectedDateEnd,
      hoursPerDay = 0,
      hoursPerHalfDay = 0,
      days,
      formData: {
        startTimeLocal,
        endTimeLocal,
        scope,
      },
      absenceType: {
        id: typeId,
        countsAsHrs,
      },
    } = $scope.props;

    if (!selectedDateStart || !selectedDateEnd) {
      return;
    }

    $scope.props.multipleDays = selectedDateEnd.diff(selectedDateStart, 'days') > 1;

    if ($scope.props.multipleDays) {
      $scope.props.formData.scope = 0;
    }

    if (!days || !days.length) {
      return;
    }

    let hours;
    let totalDays;

    switch (scope) {
      default:
      case 0:
        hours = hoursPerDay;
        break;
      case 1:
      case 2:
        hours = hoursPerHalfDay;
        totalDays = 0.5;
        break;
      case 3: {
        const pseudoStart = moment.utc(`2022-01-01T${startTimeLocal}`);
        const pseudoEnd = moment.utc(`2022-01-01T${endTimeLocal}`);

        if (!pseudoEnd.isAfter(pseudoStart)) {
          pseudoEnd.add(1, 'day');
        }

        hours = Math.round(pseudoEnd.diff(pseudoStart, 'hours', true) * 100) / 100;
        totalDays = 0.5;
        break;
      }
    }

    $scope.props.mappedDays = days.map(({
      date,
      isDayOff,
      hours: originalHours,
    }) => {
      const hoursValue = originalHours !== null
      && originalHours !== undefined
      && moment(date)
        .isBefore() ? originalHours : hours;

      return {
        date,
        hours: countsAsHrs && isDayOff ? 0 : hoursValue,
      };
    });

    $scope.props.totalHours = Math.round($scope.props.mappedDays
      .reduce((total, { hours }) => total + hours, 0) * 100) / 100;
    $scope.props.totalDays = totalDays || $scope.props.mappedDays
      .filter(({ hours }) => hours > 0).length;
    $scope.props.totalDaysUnpaid = $scope.props.mappedDays
      .filter(({ hours }) => hours === 0).length;

    $scope.props.actionInProgress = true;

    AvailabilityService.getAllowanceImpact({
      start: selectedDateStart.format(isoDateFormat),
      end: selectedDateEnd.format(isoDateFormat),
      typeId,
      scope,
      userId: $scope.absence.header.userId,
      days: $scope.props.mappedDays,
      excludeHeaderId: headerId,
    })
      .then(({ data }) => {
        $scope.props.allowanceTransactions = data.transactionItems;
        $scope.props.actionInProgress = false;
      })
      .catch(() => {
        AlertService.add('danger', translations['ABSENCE.EDIT.ERROR_500']);
        $scope.props.actionInProgress = false;
      });
  }

  function onDateChange(calculateAfresh) {
    const {
      selectedDateStart,
      selectedDateEnd,
      cachedDays,
    } = $scope.props;

    if (!selectedDateStart.isBefore(selectedDateEnd)) {
      return;
    }

    if (calculateAfresh) {
      $scope.props.daysOffRecalculated = true;
    }

    const params = {
      start: selectedDateStart.format(isoDateFormat),
      end: selectedDateEnd.format(isoDateFormat),
      userId: $scope.absence.header.userId,
      excludeHeaderId: $scope.props.headerId,
    };

    $scope.props.actionInProgress = true;

    AvailabilityService.getSuggestedDaysOff(params)
      .then(({ data }) => {
        if ($scope.props.daysOffRecalculated) {
          $scope.props.days = data.days;
          $scope.props.hoursPerDay = data.hoursPerDay;
          $scope.props.hoursPerHalfDay = data.hoursPerHalfDay;
        } else {
          // Merge the new values with the old ones
          $scope.props.days = data.days.map((newValue) => {
            const originalValue = cachedDays.find((d) => d.date === newValue.date);
            return originalValue || newValue;
          });
        }

        $scope.props.actionInProgress = false;
        recalculateTotals();
      })
      .catch(() => {
        AlertService.add('danger', translations['ABSENCE.EDIT.ERROR_500']);
        $scope.props.actionInProgress = false;
      });
  }

  $scope.onDateStartChange = (newDate) => {
    const date = moment.utc(newDate.format('YYYY-MM-DD'));

    $scope.props.selectedDateStart = date.clone();
    $scope.props.dateChanged = true;

    if (!$scope.props.selectedDateEnd.isAfter(date)) {
      $scope.props.formData.dateEnd = date.clone();
      $scope.props.selectedDateEnd = date.clone().add(1, 'day');
    }

    onDateChange();
  };

  $scope.onDateEndChange = (newDate) => {
    const date = moment.utc(newDate.format('YYYY-MM-DD'));

    $scope.props.selectedDateEnd = date.clone().add(1, 'day');
    $scope.props.dateChanged = true;
    onDateChange();
  };

  $scope.onTimeChange = () => {
    $scope.props.dateChanged = true;
    onDateChange();
  };

  $scope.onScopeChange = () => {
    recalculateTotals();
  };

  $scope.changeUnpaidDays = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    const {
      selectedDateStart,
      selectedDateEnd,
    } = $scope.props;

    const modal = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: ($scope, $uibModalInstance, data) => {
        const datePickerOptions = {
          showWeeks: false,
          formatDay: 'd',
          minDate: data.start.toDate(),
          maxDate: data.end.clone().subtract(1, 'day').toDate(),
          startingDay: moment.localeData().firstDayOfWeek(),
          ngModelOptions: {
            timezone: 'UTC',
          },
          customClass: ({ date }) => ($scope.props.selectedDates
            .includes(moment.utc(moment(date).format('YYYY-MM-DD')).valueOf()) ? 'selected' : ''),
        };

        $scope.props = {
          activeDate: data.start.toDate(),
          selectedDates: data.days
            .filter(({ isDayOff }) => isDayOff)
            .map(({ date }) => moment.utc(date).valueOf()),
          datePickerOptions,
        };

        $scope.save = () => {
          $uibModalInstance.close(data.days.map(({ date }) => ({
            date,
            isDayOff: $scope.props.selectedDates.includes(moment.utc(date).valueOf()),
          })));
        };
      },
      size: 'sm',
      resolve: {
        data: () => ({
          days: $scope.props.days,
          start: selectedDateStart,
          end: selectedDateEnd,
        }),
      },
    });

    modal.result.then((changedDays) => {
      $scope.props.days = changedDays;
      recalculateTotals();
    });
  };

  $scope.resetUnpaidDays = () => {
    const { cachedDays } = $scope.props;
    if ($scope.props.actionInProgress || !cachedDays || $scope.props.dateChanged) {
      return;
    }

    $scope.props.days = cachedDays.map(({ date, isDayOff }) => ({ date, isDayOff }));
    $scope.props.hoursPerDay = $scope.absence.header.userHoursDay;
    $scope.props.hoursPerHalfDay = $scope.absence.header.userHoursHalfDay;
    $scope.props.daysOffRecalculated = false;
    recalculateTotals();
  };

  $scope.recalculateUnpaidDays = () => {
    onDateChange(true);
  };

  $scope.clearUnpaidDays = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    $scope.props.days = $scope.props.days.map(({ date }) => ({ date, isDayOff: false }));
    recalculateTotals();
  };

  recalculateTotals();
};
