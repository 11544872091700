var controllers = require('../../module');

controllers.controller('SettingsEditPayRuleGroupModalCtrl', ['$scope', '$uibModalInstance', 'SettingsService', 'AlertService', 'data',
    function ($scope, $uibModalInstance, SettingsService, AlertService, data) {
        $scope.saveActioning = false;
        $scope.isAdd = data.isAdd;

        if ($scope.isAdd) {
            $scope.formData = {
                dateStart: moment().startOf('day'),
                dateEnd: moment().startOf('day'),
                hasEnd: false
            };
        } else {
            var group = data.group;

            $scope.formData = {
                name: group.name,
                dateStart: moment(group.dateStart),
                hasEnd: !group.openEnded,
                dateEnd: group.openEnded ? moment() : moment(group.displayEnd)
            };
        }

        $scope.save = function () {
            var fields = {
                name: $scope.formData.name,
                dateStart: $scope.formData.dateStart.format('YYYY-MM-DD')
            };

            if ($scope.formData.hasEnd) {
                fields.dateEnd = $scope.formData.dateEnd.clone().add(1, 'day').format('YYYY-MM-DD');
            }

            var promise;

            if ($scope.isAdd) {
                promise = SettingsService.addPayRuleGroup(fields);
            } else {
                promise = SettingsService.updatePayRuleGroup(data.group.id, fields);
            }

            promise
                .success(function () {
                    $uibModalInstance.close();
                })
                .error(function (err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', 'We weren\'t able to ' + ($scope.isAdd ? 'save' : 'update') + ' the pay rule group then. Please try again.');
                    }

                    $uibModalInstance.dismiss();
                });
        };

        $scope.startDateChanged = function (newStart) {
            $scope.formData.dateStart = newStart.startOf('day');
        };

        $scope.endDateChanged = function (newEnd) {
            $scope.formDate.dateEnd = newEnd.startOf('day');
        };

        $scope.close = function () {
            $uibModalInstance.dismiss();
        };

        $scope.delete = function () {
            if (!confirm("Are you sure you want to delete this rule period?")) {
                return;
            }

            SettingsService.deletePayRuleGroup(data.group.id)
                .success(function () {
                    $uibModalInstance.close();
                })
                .error(function (err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', 'We weren\'t able to delete the pay rule group then. Please try again.');
                    }

                    $uibModalInstance.dismiss();
                });
        };
    }
]);
