module.exports = (
  $scope,
  $uibModalInstance,
  data,
  StaffService,
  AlertService,
  $translate
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.VIEW.PAY.VIEW_FINANCIALS.SAVE_SUCCESS',
    'STAFF.VIEW.PAY.VIEW_FINANCIALS.ERROR_500',
    'STAFF.VIEW.PAY.VIEW_FINANCIALS.ERROR_403',
  ]);

  const { studentLoanIndex, studentLoanPlanTypes, userData, userId } = data;

  const userStudentLoans = userData.financial.ukStudentLoans;
  const studentLoan = userStudentLoans[studentLoanIndex];
  const effectiveDate = studentLoan.effectiveDate;
  const planType = studentLoan.planType;

  $scope.props = {
    actionInProgress: false,
    effectiveDate: effectiveDate ? moment(effectiveDate) : moment(),
    planType,
    studentLoanPlanTypes,
  };

  $scope.loanEffectiveDateChanged = (newDate) => {
    $scope.props.effectiveDate = newDate.clone();
  };

  $scope.save = () => {
    $scope.props.actionInProgress = true;

    const updatedLoan = {
      ...studentLoan,
      planType: $scope.props.planType,
      effectiveDate: $scope.props.effectiveDate.format('YYYY-MM-DD'),
    };
    const updatedLoans = [
      ...userStudentLoans.slice(0, studentLoanIndex),
      updatedLoan,
      ...userStudentLoans.slice(studentLoanIndex + 1),
    ];

    StaffService.updateAccount(userId, {
      user: {
        ukStudentLoans: updatedLoans,
      },
    })
      .then(() => {
        handleApiSuccess(updatedLoans);
      })
      .catch(({ status }) => {
        handleApiError(status);
      });
  };

  $scope.close = () => $uibModalInstance.dismiss();

  function handleApiSuccess(data) {
    $scope.props.actionInProgress = false;
    $uibModalInstance.close(data);
  }

  function handleApiError(status) {
    $scope.props.actionInProgress = false;

    if (status === 403) {
      AlertService.add(
        'warning',
        translations['STAFF.VIEW.PAY.VIEW_FINANCIALS.ERROR_403']
      );
      return;
    }

    AlertService.add(
      'danger',
      translations['STAFF.VIEW.PAY.VIEW_FINANCIALS.ERROR_500']
    );
  }
};
