'use strict';
var controllers = require('../module');

controllers.controller('EditPatternExceptionsModalCtrl', ['$scope', '$uibModalInstance', 'AvailabilityService', 'data', '$translate',
    function ($scope, $uibModalInstance, AvailabilityService, data, $translate) {
        $scope.dataLoaded = false;
        $scope.updateActioning = false;

        var changesMade = false;

        $scope.close = function () {
            $uibModalInstance.close(changesMade);
        };

        AvailabilityService.getPatternExceptions(data.date.startOf('month').format('YYYY-MM-DD'),
            data.date.endOf('month').add(1, 'week').endOf('week').format('YYYY-MM-DD'), data.user)
            .success(function (result) {
                $scope.exceptions = result.exceptions;

                $scope.dataLoaded = true;
            })
            .error(function () {
                $uibModalInstance.dismiss('cancel');
            });

        $scope.deleteException = function (exception) {
            $scope.updateActioning = true;
            var exceptionDate = moment(exception.date);

            AvailabilityService.deletePatternException(exception.userId, exceptionDate.format('YYYY-MM-DD'))
                .success(function () {
                    angular.forEach($scope.exceptions, function (e, idx) {
                        if (exceptionDate.isSame(e.date)) {
                            $scope.exceptions.splice(idx, 1);
                        }
                    });

                    changesMade = true;
                    $scope.updateActioning = false;
                })
                .error(function () {
                    alert($translate.instant('AVAILABILITY.EDIT_PATTERN_EXCEPTIONS_MODAL.ERROR_DELETING_EXCEPTION'));
                    $scope.updateActioning = false;
                });
        };
    }]);
