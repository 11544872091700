module.exports = (
  $scope,
  $translate,
  $uibModalInstance,
  AlertService,
  AvailabilityService,
  data,
) => {
  'ngInject';

  const { id } = data;

  const translations = $translate.instant([
    'STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.ERROR_500',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ACCRUAL_HOURS_WORKED',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ABSENCE_REQUESTED',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ABSENCE_REJECTED_OR_CANCELLED',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_MANUAL_ADJUSTMENT',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ACCRUAL_SCHEDULE_CHANGED',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_CARRY_OVER',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ACCRUAL_TIME_BASED',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ABSENCE_UPDATED',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_HOURS_PER_DAY_RECALCULATION',
  ]);

  function mapTypeToLabel(type) {
    switch (type) {
      case 'ACCRUAL_HOURS_WORKED':
        return translations['STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ACCRUAL_HOURS_WORKED'];
      case 'ABSENCE_REQUESTED':
        return translations['STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ABSENCE_REQUESTED'];
      case 'ABSENCE_REJECTED_OR_CANCELLED':
        return translations['STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ABSENCE_REJECTED_OR_CANCELLED'];
      case 'MANUAL_ADJUSTMENT':
        return translations['STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_MANUAL_ADJUSTMENT'];
      case 'ACCRUAL_SCHEDULE_CHANGED':
        return translations['STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ACCRUAL_SCHEDULE_CHANGED'];
      case 'CARRY_OVER':
        return translations['STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_CARRY_OVER'];
      case 'ACCRUAL_TIME_BASED':
        return translations['STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ACCRUAL_TIME_BASED'];
      case 'ABSENCE_UPDATED':
        return translations['STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_ABSENCE_UPDATED'];
      case 'HOURS_PER_DAY_RECALCULATION':
        return translations['STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.TYPE_HOURS_PER_DAY_RECALCULATION'];
      default:
        return '';
    }
  }

  $scope.props = {
    loadingData: true,
    data: [],
    typeOptions: [
      'ACCRUAL_HOURS_WORKED',
      'ABSENCE_REQUESTED',
      'ABSENCE_REJECTED_OR_CANCELLED',
      'MANUAL_ADJUSTMENT',
      'ACCRUAL_SCHEDULE_CHANGED',
      'CARRY_OVER',
      'ACCRUAL_TIME_BASED',
      'ABSENCE_UPDATED',
      'HOURS_PER_DAY_RECALCULATION',
    ].map((type) => ({
      id: type,
      label: mapTypeToLabel(type),
    })),
  };

  $scope.close = () => $uibModalInstance.dismiss();

  $scope.onTypeFilter = (selectedOptions) => {
    $scope.props.data = $scope.props.masterData
      .filter(({ type }) => selectedOptions.includes(type));
  };

  function fetchTransactions() {
    $scope.props.loadingData = true;

    AvailabilityService.getAllowanceAudit(id)
      .then(({ data }) => {
        $scope.props.masterData = data.results.map((row) => ({
          ...row,
          typeLabel: mapTypeToLabel(row.type),
        }));

        $scope.props.data.push(...$scope.props.masterData);

        $scope.props.loadingData = false;
      })
      .catch(() => {
        AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.ALLOWANCE_TRANSACTIONS.ERROR_500']);
        $scope.close();
      });
  }

  fetchTransactions();
};
