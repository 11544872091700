// Content Editable Div example taken from:
// https://docs.angularjs.org/api/ng/type/ngModel.NgModelController#custom-control-example
import directives from './module';

directives.directive('rrContentEditable', ($sce) => {
  'ngInject';

  return ({
    restrict: 'A',
    require: 'ngModel',
    link: ($scope, element, attr, ngModel) => {
      if (!ngModel) {
        return;
      }

      ngModel.$render = () => {
        element.html($sce.getTrustedHtml(ngModel.$viewValue || ''));
      };

      const read = () => {
        let html = element.html();

        // When we clear the content editable the browser leaves a <br> behind
        // If strip-br attribute is provided then we strip this out
        if (html === '<br>') {
          html = '';
        }

        ngModel.$setViewValue(html);
      };

      // Listen for change events to enable binding
      element.on('blur keyup change', () => {
        $scope.$evalAsync(read);
      });

      read();
    },
  });
});
