const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/reports/payrollExport/signOffStatus.html');

/*
READ THIS FIRST:
https://rotaready.atlassian.net/wiki/spaces/~5b239199be73de20759a9308/pages/3275751425/Asynchronous+Reports
*/

module.exports = (
  $scope,
  $state,
  $http,
  $translate,
  $uibModalDrawer,
  EnvironmentDataService,
  AlertService,
  ENDPOINT_API,
) => {
  'ngInject';

  // Earliest date the report can be run for is when the cache was deployed into prod
  const earliestRunDate = moment('2018-12-11');
  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.PAYROLL_EXPORT.TITLE',
    'REPORTS.PAYROLL_EXPORT.PAY_AMOUNT_TYPE_HOURLY',
    'REPORTS.PAYROLL_EXPORT.PAY_AMOUNT_TYPE_DAILY',
    'REPORTS.PAYROLL_EXPORT.PAY_AMOUNT_TYPE_ANNUAL',
    'REPORTS.PAYROLL_EXPORT.AGGREGATION_OPTION_ATTRIBUTION',
    'REPORTS.PAYROLL_EXPORT.AGGREGATION_OPTION_APPOINTMENT',
    'REPORTS.PAYROLL_EXPORT.ERROR_500',
    'REPORTS.PAYROLL_EXPORT.ABSENCE_TYPE_DELETED_SUFFIX',
    'REPORTS.PAYROLL_EXPORT.DATE_ERROR_MIN',
    'REPORTS.PAYROLL_EXPORT.DATE_ERROR_DIFF',
  ]);

  const defaultEntityIds = [];
  const defaultDateStart = moment().startOf('week').subtract(1, 'week');
  const defaultDateEnd =  moment().startOf('week').subtract(1, 'day');
  const defaultPayAmountTypes = [];
  const defaultAggregationOptions = [1];
  const defaultUserId = undefined;
  const defaultPayrollCalendarId = undefined;
  const defaultCompanyId = undefined;

  const payAmountTypes = new Map([
    [0, { id: 0, label: translations['REPORTS.PAYROLL_EXPORT.PAY_AMOUNT_TYPE_HOURLY'] }],
    [2, { id: 2, label: translations['REPORTS.PAYROLL_EXPORT.PAY_AMOUNT_TYPE_DAILY'] }],
    [1, { id: 1, label: translations['REPORTS.PAYROLL_EXPORT.PAY_AMOUNT_TYPE_ANNUAL'] }],
  ]);

  $scope.props = {
    startDate: moment().subtract(1, 'week').startOf('week'),
    loadingData: false,
    signOffStatus: {
      loading: false,
      status: 'unknown',
    },
    data: [],
    defaultDateFilter: {
      option: 4,
      dateStart: defaultDateStart,
      dateEnd: defaultDateEnd,
    },
    defaultEntityIds,
    payAmountTypes: Array.from(payAmountTypes.values()),
    defaultPayAmountTypes,
    payAmountTypesById: Object.fromEntries(payAmountTypes),
    aggregationOptions: [
      { id: 1, label: translations['REPORTS.PAYROLL_EXPORT.AGGREGATION_OPTION_ATTRIBUTION'] },
      { id: 2, label: translations['REPORTS.PAYROLL_EXPORT.AGGREGATION_OPTION_APPOINTMENT'] },
    ],
    defaultAggregationOptions,
    defaultAbsenceTypeIds: [],
    showRolledUpHoliday: false,
    selectedDateStart: defaultDateStart,
    selectedDateEnd: defaultDateEnd,
    selectedEntityIds: defaultEntityIds,
    selectedPayAmountTypes: defaultPayAmountTypes,
    selectedAggregation: defaultAggregationOptions,
    selectedAbsenceTypeIds: [],
    selectedPayElementTypeIds: [],
    selectedUserId: defaultUserId,
    selectedPayrollCalendarId: defaultPayrollCalendarId,
    selectedCompanyId: defaultCompanyId,
    absenceTypesById: {},
    payElementTypesById: {},
    entityGroupsById: {},
    reportInProgress: false,
    hasRequiredFilters: false,
  };

  function evaluateQueryParams() {
    $state.go('.', {
      start: $scope.props.selectedDateStart.format('YYYY-MM-DD'),
      end: $scope.props.selectedDateEnd.format('YYYY-MM-DD'),
      entityIds: $scope.props.selectedEntityIds ? $scope.props.selectedEntityIds.join(',') : undefined,
      payAmountTypes: $scope.props.selectedPayAmountTypes ? $scope.props.selectedPayAmountTypes.join(',') : undefined,
      aggregation: $scope.props.selectedAggregation ? $scope.props.selectedAggregation.join(',') : undefined,
      userId: $scope.props.selectedUserId,
      payrollCalendarId: $scope.props.selectedPayrollCalendarId,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $scope.getDataParams = {
      startDate: $scope.props.selectedDateStart.format('YYYY-MM-DD'),
      endDate: $scope.props.selectedDateEnd.clone().add(1, 'day').format('YYYY-MM-DD'),
      'entityIds[]': $scope.props.selectedEntityIds,
      'userPayAmountTypes[]': $scope.props.selectedPayAmountTypes,
      'aggregationOptions[]': $scope.props.selectedAggregation,
      includeTronc: true,
      userId: $scope.props.selectedUserId,
      payrollCalendarId: $scope.props.selectedPayrollCalendarId,
      'absenceTypeIds[]': $scope.props.selectedAbsenceTypeIds,
      showRolledUpHoliday: $scope.props.showRolledUpHoliday,
      companyId: $scope.props.selectedCompanyId,
      'payElementTypeIds[]': $scope.props.selectedPayElementTypeIds,
    };
  }

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.props.selectedEntityIds = entityIds.length ? entityIds : undefined;
    $scope.props.hasRequiredFilters = ($scope.props.selectedEntityIds
      && $scope.props.selectedEntityIds.length) || $scope.props.selectedUserId;
  };

  $scope.onPayAmountTypeFilter = (selectedOptions) => {
    $scope.props.selectedPayAmountTypes = selectedOptions.length ? selectedOptions : undefined;
  };

  $scope.onAggregationOptionFilter = (selectedOptions) => {
    $scope.props.selectedAggregation = selectedOptions.length ? selectedOptions : undefined;
  };

  $scope.onAbsenceTypeFilter = (selectedOptions) => {
    $scope.props.selectedAbsenceTypeIds = selectedOptions.length ? selectedOptions : [];
  };

  $scope.onPayElementTypeFilter = (selectedOptions) => {
    $scope.props.selectedPayElementTypeIds = selectedOptions.length ? selectedOptions : [];
  };

  $scope.onUserFilter = (selectedUser) => {
    $scope.props.selectedUserId = selectedUser ? selectedUser.id : undefined;
    $scope.props.hasRequiredFilters = ($scope.props.selectedEntityIds
      && $scope.props.selectedEntityIds.length) || $scope.props.selectedUserId;
  };

  $scope.onCompanyFilter = (selectedOptions) => {
    $scope.props.selectedCompanyId = selectedOptions.length && selectedOptions[0] !== ''
      ? selectedOptions[0] : undefined;
  };

  $scope.rowFilter = (row) => {
    const elementTypeId = row.data.payElementTypeId;

    // There was a time when pay element type ID was not cached, so it will be undefined
    // We should always show these
    if (elementTypeId === null || elementTypeId === undefined) {
      return true;
    }

    return $scope.props.selectedPayElementTypeIds.includes(elementTypeId);
  };

  $scope.onDateFilter = ({
    dateEnd,
    dateStart,
  }) => {
    $scope.props.selectedDateStart = dateStart;
    $scope.props.selectedDateEnd = dateEnd;
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    let dateStart;
    let dateEnd;

    if (when === 'thisWeek') {
      dateStart = moment().startOf('week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastWeek') {
      dateStart = moment().startOf('week').subtract(1, 'week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastTwoWeeks') {
      dateStart = moment().startOf('week').subtract(2, 'weeks');
      dateEnd = dateStart.clone().add(13, 'days');
    } else if (when === 'lastMonth') {
      dateStart = moment().startOf('month').subtract(1, 'month');
      dateEnd = moment().startOf('month').subtract(1, 'day');
    }

    $scope.props.defaultDateFilter = {
      option: 4,
      dateStart,
      dateEnd,
    };

    $scope.onDateFilter({
      dateStart,
      dateEnd,
    });
  };

  $scope.openSignOffStatusDrawer = () => {
    $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./signOffStatus'),
      resolve: {
        data: () => ({
          signOffData: $scope.props.signOffStatus.data,
          selectedDateStart: $scope.props.selectedDateStart,
          selectedDateEnd: $scope.props.selectedDateEnd,
          selectedEntityIds: $scope.props.selectedEntityIds,
          entityGroups: $scope.props.entityGroups,
        }),
      },
    });
  };

  function refreshSignOffStatus() {
    $scope.props.signOffStatus.loading = true;

    const {
      selectedDateStart: start,
      selectedDateEnd: endInclusive,
      selectedEntityIds: entityIds,
    } = $scope.props;

    const end = endInclusive.clone().add(1, 'day');

    $http.get(`${ENDPOINT_API}/rota/signOff`, {
      params: {
        dateRange: `${start.format('YYYY-MM-DD')}/${end.format('YYYY-MM-DD')}`,
        'entityIds[]': entityIds,
      },
    })
      .then(({ data }) => {
        $scope.props.signOffStatus.data = data.results;

        const uniqueSignOffs = new Set(data.results.flatMap(({
          entityId,
          date,
          reversed,
        }) => {
          if (reversed) {
            return [];
          }

          return [`${entityId}-${date}`];
        }));

        const requiredSignOffCount = end.diff(start, 'days') * entityIds.length;
        const actualSignOffCount = uniqueSignOffs.size;

        if (!actualSignOffCount) {
          $scope.props.signOffStatus.status = 'none';
        } else if (actualSignOffCount === requiredSignOffCount) {
          $scope.props.signOffStatus.status = 'complete';
        } else {
          $scope.props.signOffStatus.status = 'partial';
        }

        $scope.props.signOffStatus.loading = false;
      })
      .catch(() => {
        $scope.props.signOffStatus.loading = false;
        $scope.props.signOffStatus.status = 'unknown';
      });
  }

  $scope.export = async () => {
    $scope.props.hasRequiredFilters = ($scope.props.selectedEntityIds
      && $scope.props.selectedEntityIds.length) || $scope.props.selectedUserId;

    if (!$scope.props.hasRequiredFilters) {
      return;
    }

    if ($scope.props.selectedDateStart.isBefore(earliestRunDate)) {
      AlertService.add('info', translations['REPORTS.PAYROLL_EXPORT.DATE_ERROR_MIN']);
      return;
    }

    evaluateQueryParams();
    refreshSignOffStatus();

    $scope.props.loadingData = true;
    $scope.props.reportInProgress = false;

    $http.get(`${ENDPOINT_API}/report/payrollAsyncFileExport`, {
      params: $scope.getDataParams,
    })
      .then(() => {
        $scope.props.loadingData = false;
        $scope.props.reportInProgress = true;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;
        $scope.props.reportInProgress = false;
        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.PAYROLL_EXPORT.ERROR_500']);
        }
      });
  };

  function getUnitFromPayFrequency(frequency) {
    switch (frequency) {
      case 1:
      case 2:
      case 4:
        return 'weeks';
      case 3:
        return 'months';
      default:
        return undefined;
    }
  }

  function getUnitCountFromPayFrequency(frequency) {
    switch (frequency) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 4:
        return 4;
      case 3:
        return 1;
      default:
        return undefined;
    }
  }

  function getPayRunsForCalendar(calendarId) {
    const {
      dateStart: dateStartRaw,
      frequency,
    } = $scope.props.payrollCalendars.find(({ id }) => id === calendarId);

    const durationUnit = getUnitFromPayFrequency(frequency);
    const durationUnitCount = getUnitCountFromPayFrequency(frequency);
    const dateStart = moment.utc(dateStartRaw);
    const today = moment.utc(moment().startOf('day').format('YYYY-MM-DD'));

    if (!durationUnit || !durationUnitCount || today.isBefore(dateStart)) {
      return [];
    }

    const count = Math.floor(today.diff(dateStart, durationUnit, true));
    const periodsElapsed = Math.floor(count / durationUnitCount);

    const currentPayRunStart = dateStart
      .add(moment.duration(durationUnitCount * periodsElapsed, durationUnit));
    const currentPayRunEnd = currentPayRunStart.clone()
      .add(moment.duration(durationUnitCount, durationUnit));

    return Array(5).fill().map((_, index) => {
      const duration = moment.duration(durationUnitCount * index, durationUnit);
      const start = currentPayRunStart.clone().subtract(duration);
      const end = currentPayRunEnd.clone().subtract(duration).subtract(1, 'day');

      return {
        id: index + 1,
        label: `${start.format('ll')} to ${end.format('ll')}`,
        start,
        end,
      };
    });
  }

  $scope.onPayrollCalendarFilter = (selectedOptions, loadData = true) => {
    const selectedPayrollCalendarId = selectedOptions.length && selectedOptions[0] !== ''
      ? selectedOptions[0] : undefined;

    $scope.props.selectedPayrollCalendarId = selectedPayrollCalendarId;

    const {
      selectedDateStart: start,
      selectedDateEnd: end,
    } = $scope.props;

    if (selectedPayrollCalendarId === undefined) {
      $scope.props.payRuns = [];

      if (start && end) {
        $scope.props.defaultDateFilter = {
          option: 4,
          dateStart: start,
          dateEnd: end,
        };
      }
      return;
    }

    $scope.props.payRuns = getPayRunsForCalendar(selectedPayrollCalendarId);
    $scope.props.defaultPayRunOptions = [];

    if (start && end) {
      const payRun = $scope.props.payRuns.find((payRun) => payRun
        .start.format('YYYY-MM-DD') === start.format('YYYY-MM-DD') && payRun
        .end.format('YYYY-MM-DD') === end.format('YYYY-MM-DD'));

      if (payRun) {
        $scope.props.defaultPayRunOptions = [payRun.id];
      }
    }
  };

  $scope.onPayRunFilter = (selectedOptions) => {
    if (!selectedOptions.length) {
      return;
    }

    const [payRunId] = selectedOptions;
    const { start, end } = $scope.props.payRuns.find(({ id }) => id === payRunId);
    $scope.onDateFilter({ dateStart: start, dateEnd: end });
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.AbsenceType,
    EnvironmentDataService.DataType.PayElementType,
    EnvironmentDataService.DataType.PayrollCalendar,
    EnvironmentDataService.DataType.Company,
  ])
    .then(([
      entityGroup,
      absenceType,
      payElementType,
      payrollCalendar,
      company,
    ]) => {
      $scope.props.entityGroups = entityGroup.data;
      $scope.props.payrollCalendars = payrollCalendar.data
        .filter(({ deleted }) => !deleted)
        .map((calendar) => ({ ...calendar, label: calendar.name }));

      if (defaultPayrollCalendarId) {
        $scope.props.defaultPayrollCalendarOptions = [defaultPayrollCalendarId];
        $scope.onPayrollCalendarFilter([defaultPayrollCalendarId], false);
      }

      $scope.props.companies = company.data
        .filter(({ deleted }) => !deleted)
        .map((company) => ({ ...company, label: company.name }));

      if (defaultCompanyId) {
        $scope.props.defaultCompanyIds = [defaultCompanyId];
      }

      $scope.props.groupedEntities = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => ({
            id: entity.id,
            label: entity.name,
            parentId: entity.groupId,
            depth: 1,
          })),
        ]));

      entityGroup.data.forEach((group) => {
        $scope.props.entityGroupsById[group.id] = group;
      });

      $scope.props.absenceTypes = absenceType.data.map(({ id, name }) => {
        const type = {
          id,
          label: name,
        };

        $scope.props.absenceTypesById[id] = type;
        return type;
      });

      $scope.props.selectedAbsenceTypeIds = absenceType.data
        .filter(({ isPaid, deleted }) => isPaid && !deleted)
        .map(({ id }) => id);
      $scope.props.defaultAbsenceTypeIds = $scope.props.selectedAbsenceTypeIds.slice();

      $scope.props.payElementTypes = [];
      $scope.props.defaultPayElementTypeIds = [];

      const payElements = payElementType.data.slice();

      payElements.push({
        id: 0,
        name: 'Tronc',
        variant: 'RATE_PER_UNIT',
      });

      payElements.forEach(({
        id,
        name,
        isDeleted,
        variant,
      }) => {
        const type = {
          id,
          label: name,
          displayAmountType: variant !== 'FIXED_AMOUNT',
        };

        if (!isDeleted) {
          $scope.props.defaultPayElementTypeIds.push(id);
        } else {
          type.label += ` (${translations['REPORTS.PAYROLL_EXPORT.ABSENCE_TYPE_DELETED_SUFFIX']})`;
        }

        $scope.props.payElementTypes.push(type);
        $scope.props.selectedPayElementTypeIds.push(id);
        $scope.props.payElementTypesById[id] = type;
      });
    });
};
