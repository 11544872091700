const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/datePicker.html');

var directives = require('./module');

directives.directive('rrDatePicker', function () {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        scope: {
            date: '=',
            minDate: '=?',
            maxDate: '=?',
            readOnly: '=?',
            onChange: '&',
            minView: '@',
            startView: '@',
            pullRight: '@',
            format: '@',
            navigationAmount: '@',
            isSmall: '@',
            minuteStep: '@',
            borderless: '@',
            withWeekNumbers: '@',
            dropup: '@?',
            showClear: '=',
            clearDate: '&'
        },
        controller: function ($scope) {
            $scope.isOpen = false;

            $scope.config = {
                minView: $scope.minView || 'day',
                minuteStep: isNaN(parseInt($scope.minuteStep, 10)) ? 5 : parseInt($scope.minuteStep, 10),
                startView: $scope.startView ? $scope.startView : ($scope.minView || 'day'),
                modelType: 'moment',
                renderOn: 'refreshDatePicker'
            };

            function changeNavigationButtonState(date) {
                if ($scope.navigationAmount) {
                    $scope.prevDisabled = false;
                    $scope.nextDisabled = false;

                    if ($scope.minDate && date.isSame($scope.minDate, $scope.navigationAmount)) {
                        $scope.prevDisabled = true;
                    }

                    if ($scope.maxDate && date.isSame($scope.maxDate, $scope.navigationAmount)) {
                        $scope.nextDisabled = true;
                    }
                }
            }

            function onDateChange(newDate, oldDate) {
                changeNavigationButtonState(newDate);

                if ($scope.onChange) {
                    $scope.onChange({newDate: newDate, oldDate: oldDate});
                }
            }

            $scope.dateChanged = function (newDate, oldDate) {
                $scope.isOpen = false;
                onDateChange(newDate, oldDate);
            };

            $scope.beforeRender = function ($view, $dates) {
                changeNavigationButtonState($scope.date);

                var minDateValue = $scope.minDate ? $scope.minDate.clone() : false;
                var maxDateValue = $scope.maxDate ? $scope.maxDate.clone() : false;

                // Fix... details here:
                // https://jsfiddle.net/g89sL337/1/
                if (minDateValue) {
                    minDateValue = minDateValue.startOf($view);
                }

                $dates.filter(function (date) {
                    if (minDateValue !== false && date.localDateValue().valueOf() < minDateValue) {
                        return true;
                    }

                    if (maxDateValue !== false && date.localDateValue().valueOf() > maxDateValue) {
                        return true;
                    }

                    return false;
                }).forEach(function (date) {
                    date.selectable = false;
                });
            };

            $scope.navigate = function (forwards) {
                var oldDate = $scope.date.clone();

                if (forwards) {
                    $scope.date.add(1, $scope.navigationAmount);
                } else {
                    $scope.date.subtract(1, $scope.navigationAmount);
                }

                $scope.$broadcast('refreshDatePicker');

                onDateChange($scope.date, oldDate);
            };

            $scope.handleClearClick = function () {
                $scope.isOpen = false;
                $scope.clearDate();
            };
        }
    };
});
