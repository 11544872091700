module.exports = (
  $scope,
  $http,
  $window,
  $translate,
  $state,
  $stateParams,
  EnvironmentDataService,
  AlertService,
  ExportService,
  ENDPOINT_API,
  WFM_ENDPOINT,
) => {
  'ngInject';

  const translations = $translate.instant([
    'REPORTS.ROTA_PROGRESS.TITLE',
    'REPORTS.ROTA_PROGRESS.LIFECYCLE_DRAFT',
    'REPORTS.ROTA_PROGRESS.LIFECYCLE_MARKED_FOR_APPROVAL',
    'REPORTS.ROTA_PROGRESS.LIFECYCLE_PUBLISHED',
    'REPORTS.ROTA_PROGRESS.ERROR_500',
  ]);

  let isFirstLoad = true;
  const defaultWeekStart = $stateParams.week && moment($stateParams.week).isValid()
    ? moment($stateParams.week) : moment().add(1, 'week').startOf('week');
  const defaultEntityGroupIds = $stateParams.entityGroupIds
    ? $stateParams.entityGroupIds.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];

  $scope.props = {
    startDate: defaultWeekStart,
    loadingData: false,
    data: [],
    lifecycleStates: [
      { id: 0, label: translations['REPORTS.ROTA_PROGRESS.LIFECYCLE_DRAFT'] },
      { id: 1, label: translations['REPORTS.ROTA_PROGRESS.LIFECYCLE_MARKED_FOR_APPROVAL'] },
      { id: 2, label: translations['REPORTS.ROTA_PROGRESS.LIFECYCLE_PUBLISHED'] },
    ],
    defaultLifecycleStates: [],
    includeEntitiesWithNoDraft: true,
    defaultEntityGroupIds,
    hasSelectedGroups: !!defaultEntityGroupIds.length,
  };

  $scope.getDataParams = {
    weekStartDate: $scope.props.startDate.format('YYYY-MM-DD'),
    'entityGroupIds[]': defaultEntityGroupIds.length ? defaultEntityGroupIds : undefined,
    includeEntitiesWithNoDraft: $scope.props.includeEntitiesWithNoDraft,
  };

  $scope.onLifecycleStateFilter = (selectedOptions) => {
    $scope.getDataParams['lifecycles[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData();
  };

  $scope.onNoDraftFilter = () => {
    $scope.getDataParams.includeEntitiesWithNoDraft = $scope.props.includeEntitiesWithNoDraft;
    $scope.loadData();
  };

  $scope.onEntityGroupFilter = (selectedOptions) => {
    $scope.getDataParams['entityGroupIds[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.props.hasSelectedGroups = !!selectedOptions.length;
    $scope.loadData();
  };

  $scope.onDateChange = (newDate) => {
    const weekStartDate = newDate.startOf('week').clone();
    $scope.getDataParams.weekStartDate = weekStartDate.format('YYYY-MM-DD');

    $scope.loadData();
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    if (when === 'lastWeek') {
      $scope.props.startDate = moment().startOf('week').subtract(1, 'week');
    } else if (when === 'thisWeek') {
      $scope.props.startDate = moment().startOf('week');
    } else if (when === 'nextWeek') {
      $scope.props.startDate = moment().startOf('week').add(1, 'week');
    }

    $scope.onDateChange($scope.props.startDate);
  };

  function getBudgetStatus(byType) {
    const {
      hasBudget,
      budget,
      forecast,
    } = byType;

    if (!hasBudget) {
      return {};
    }

    return {
      wages: budget.total.wages ? forecast.total.wages <= budget.total.wages : false,
      hours: budget.total.hours ? forecast.total.hours <= budget.total.hours : false,
      sales: budget.total.sales ? forecast.total.sales >= budget.total.sales : false,
      wagePercentage: budget.total.wagePercentage
        ? forecast.total.wagePercentage <= budget.total.wagePercentage : false,
    };
  }

  $scope.loadData = () => {
    if (!$scope.props.hasSelectedGroups) {
      return;
    }

    $scope.props.loadingData = true;

    const {
      getDataParams: {
        weekStartDate,
        'entityGroupIds[]': entityGroupIds,
      },
    } = $scope;

    $state.go('.', {
      week: weekStartDate,
      entityGroupIds: entityGroupIds && entityGroupIds.length ? entityGroupIds.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
      inherit: false,
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $http.get(`${ENDPOINT_API}/report/rotaProgress`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const {
          rotasByEntity,
          financials,
        } = data;

        financials.byEntityGroup.forEach((group) => {
          group.byEntity.forEach((row) => {
            row.byType.budgetValidity = getBudgetStatus(row.byType);
            const rota = rotasByEntity[row.entity.id];

            if (rota) {
              const {
                header,
                lifecycle,
              } = rota;

              row.rota = {
                header,
                lifecycle,
              };
            }
          });

          group.byCurrencyCode.forEach((row) => {
            row.byType.budgetValidity = getBudgetStatus(row.byType);
          });
        });

        financials.byCurrencyCode.forEach((row) => {
          row.byType.budgetValidity = getBudgetStatus(row.byType);
        });

        $scope.props.data = financials;
        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.ROTA_PROGRESS.ERROR_500']);
        }
      });
  };

  $scope.viewRota = (entityId) => {
    const startDate = $scope.props.startDate.format('YYYY-MM-DD');
    $window.location = `${WFM_ENDPOINT}/new-rota?startDate=${startDate}&entityId=${entityId}`;
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
    .then(({ data }) => {
      $scope.props.entityGroups = data
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
    });

  $scope.export = ($event, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.ROTA_PROGRESS.TITLE'],
      'exportTable', format);
  };

  $scope.loadData();
};
