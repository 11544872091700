'use strict';
var controllers = require('../../module');

controllers.controller('NewTemplateModalCtrl', ['$scope', '$uibModalInstance', '$translate', 'TemplateService', 'CommonService', 'SessionService', 'data',
    function ($scope, $uibModalInstance, $translate, TemplateService, CommonService, SessionService, data) {
        $scope.formData = {};
        $scope.templates = data.templates;
        $scope.dataLoaded = false;

        var textById = $translate.instant([
            'ROTA_MANAGEMENT.TEMPLATES.NEW_TEMPLATE_MODAL.CONFIRM_CLONE',
            'ROTA_MANAGEMENT.TEMPLATES.NEW_TEMPLATE_MODAL.CREATE_ERROR'
        ]);

        CommonService.getMetadata(['shiftTypes'], [SessionService.getEntity()], true)
            .success(function (metadata) {
                $scope.shiftTypes = metadata.shiftTypes;
                $scope.formData.defaultShiftType = $scope.shiftTypes[0].id;

                $scope.dataLoaded = true;
            })
            .error(function () {
                $uibModalInstance.dismiss('cancel');
            });

        if (data.cloneNodeId > 0) {
            $scope.formData.clone = true;
            $scope.formData.cloneNodeId = data.cloneNodeId;
        } else {
            $scope.formData.clone = false;

            if (data.templates.length > 0) {
                $scope.formData.cloneNodeId = data.templates[0].id;
            }
        }

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.createTemplate = function () {
            if ($scope.formData.clone &&
                !confirm(textById['ROTA_MANAGEMENT.TEMPLATES.NEW_TEMPLATE_MODAL.CONFIRM_CLONE']))
                return;

            $scope.createActioning = true;

            TemplateService.addTemplate($scope.formData)
                .success(function (data) {
                    $uibModalInstance.close(data.templateId);
                })
                .error(function (error, status) {
                    if (status == 403) {
                        $uibModalInstance.dismiss('cancel');
                        return;
                    }

                    $scope.createActioning = false;
                    alert(textById['ROTA_MANAGEMENT.TEMPLATES.NEW_TEMPLATE_MODAL.CREATE_ERROR']);
                });
        };
    }]);
