const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/validationBulkShiftEdit.html');

const directives = require('../module');

directives.directive('rrValidationBulkShiftEdit', (
  $translate,
  $timeout,
  $q,
  RotaService,
) => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      issues: '=',
      totalIssueCount: '=',
      truncateIssueLimit: '@',
    },
    link: ($scope) => {
      $scope.props = {
        isAllSelected: false,
        selectedCount: 0,
        isLoading: false,
      };

      $scope.onIndividualToggle = () => {
        $scope.props.selectedCount = $scope.issues.reduce(
          (acc, issue) => (issue.isSelected ? acc + 1 : acc), 0);

        $scope.props.isAllSelected = $scope.props.selectedCount === $scope.issues.length;
      };

      $scope.onAllToggle = () => {
        $scope.issues.forEach((issue) => {
          issue.isSelected = $scope.props.isAllSelected;
        });

        $scope.props.selectedCount = $scope.props.isAllSelected ? $scope.issues.length : 0;
      };

      function handleError(status) {
        $scope.issues.forEach((i) => {
          i.isDeleting = false;
        });

        $scope.props.isLoading = false;
        $scope.props.displayErrorCode = status;

        $timeout(() => {
          $scope.displayErrorCode = undefined;
        }, 3000);
      }

      function resetToggles() {
        $scope.issues.forEach((issue) => {
          issue.isSelected = false;
        });

        $scope.props.isAllSelected = false;
        $scope.props.selectedCount = 0;
        $scope.props.isLoading = false;
        $scope.totalIssueCount = $scope.issues.length;
      }

      function removeIssuesByShiftId(shiftIds) {
        let index = $scope.issues.length;

        // eslint-disable-next-line no-plusplus
        while (index--) {
          const issue = $scope.issues[index];

          if (shiftIds.includes(issue.shift.id)) {
            $scope.issues.splice(index, 1);
          }
        }
      }

      function applyBulkAction(action, shiftUserTuples) {
        if (!shiftUserTuples.length) {
          return $q.resolve();
        }

        const fields = {
          shiftUserTuples,
          ignoreValidation: true,
          operation: action === 'unassign' ? 2 : 1,
        };

        return RotaService.bulkUpdateShifts(fields);
      }

      $scope.action = (action) => {
        const shiftsToAction = $scope.issues.filter((i) => i.isSelected);

        if (!shiftsToAction.length) {
          return;
        }

        shiftsToAction.forEach((i) => {
          i.isDeleting = true;
        });

        $scope.props.isLoading = true;

        const shiftUserTuplesPublished = shiftsToAction
          .filter((s) => s.isPublished)
          .map(({ shift }) => ([shift.id, shift.userId]));

        const shiftUserTuplesDraft = shiftsToAction
          .filter((s) => !s.isPublished)
          .map(({ shift }) => ([shift.id, shift.userId]));

        applyBulkAction(action, shiftUserTuplesPublished)
          .then(() => {
            removeIssuesByShiftId(shiftUserTuplesPublished.map(([shiftId]) => shiftId));

            applyBulkAction(action, shiftUserTuplesDraft)
              .then(() => {
                removeIssuesByShiftId(shiftUserTuplesDraft.map(([shiftId]) => shiftId));
                resetToggles();
              }).catch(({ status }) => handleError(status));
          }).catch(({ status }) => handleError(status));
      };

      $scope.unassign = (issue, index) => {
        if (issue.isDeleting) {
          return;
        }

        const fields = {
          shift: issue.shift,
          users: [{ id: 0 }],
          recalculateBreaks: true,
          unlinkToNew: false,
          ignoreValidation: true,
        };

        issue.isDeleting = true;
        $scope.props.isLoading = true;

        RotaService.updateShift(issue.shift.id, fields)
          .then(() => {
            $scope.issues.splice(index, 1);
            resetToggles();
          })
          .catch(({ status }) => handleError(status));
      };

      $scope.delete = (issue, index) => {
        if (issue.isDeleting) {
          return;
        }

        const fields = {
          'shiftIds[]': [issue.shift.id],
          ignoreValidation: true,
        };

        issue.isDeleting = true;
        $scope.props.isLoading = true;

        RotaService.deleteShiftCollaborative(fields)
          .then(() => {
            $scope.issues.splice(index, 1);
            resetToggles();
          })
          .catch(({ status }) => handleError(status));
      };
    },
  };
});
