const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/budgeting/editWeekModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/budgeting/editWeekModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/dialog/textboxDialog.html');

var controllers = require('../../module');

controllers.controller('BudgetingByPeriodCtrl', ['$scope', '$uibModal', '$state', '$stateParams', 'SettingsService', 'AlertService', 'LocaleService',
    function ($scope, $uibModal, $state, $stateParams, SettingsService, AlertService, LocaleService) {
        $scope.dataLoaded = false;

        function refreshData() {
            SettingsService.getBudgetPeriod($stateParams.period)
                .success(function (data) {
                    $scope.period = data.period;
                    $scope.weeks = data.weeks;
                    $scope.entity = data.entity;

                    $scope.streams = {};

                    data.streams.forEach(function (stream) {
                        $scope.streams[stream.id] = stream;
                    });

                    $scope.currencySymbol = LocaleService.getCurrencySymbol($scope.entity.currencyCode);

                    $scope.dataLoaded = true;
                })
                .error(function (error, status) {
                    if (status == 500) {
                        AlertService.add('danger', 'We couldn\'t load the period just then. Please try again.');
                    }

                    $state.go('^.byEntity', {entity: $stateParams.entity});
                });
        }

        refreshData();

        $scope.isOneWeekSelected = false;
        $scope.selectAllWeeks = false;

        $scope.toggleWeek = function () {
            var weeksSelected = 0;

            $scope.weeks.forEach(function (w) {
                if (w.isSelected) {
                    weeksSelected++;
                }
            });

            $scope.isOneWeekSelected = weeksSelected > 0;
            $scope.selectAllWeeks = weeksSelected == $scope.period.weekCount;
        };

        $scope.toggleAllWeeks = function () {
            $scope.weeks.forEach(function (w) {
                w.isSelected = $scope.selectAllWeeks;
            });

            $scope.isOneWeekSelected = $scope.selectAllWeeks;
        };

        $scope.editWeek = function (week) {
            var editModal = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'BudgetingEditWeekModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            periodId: $scope.period.id,
                            isOverwrite: false,
                            week: week,
                            currencySymbol: $scope.currencySymbol
                        };
                    }
                }
            });

            editModal.result.then(function () {
                refreshData();
            });
        };

        $scope.editMultipleWeeks = function () {
            var weeks = [];

            $scope.weeks.forEach(function (w) {
                if (w.isSelected) {
                    weeks.push(moment(w.weekBeginning).format('YYYY-MM-DD'));
                }
            });

            if (!weeks.length) {
                return;
            }

            var editModal = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'BudgetingEditWeekModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            periodId: $scope.period.id,
                            isOverwrite: true,
                            weeks: weeks,
                            currencySymbol: $scope.currencySymbol
                        };
                    }
                }
            });

            editModal.result.then(function () {
                refreshData();
            });
        };

        $scope.deletePeriod = function (period) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate3,
                controller: function ($scope, $uibModalInstance, SettingsService) {
                    $scope.info = {
                        title: 'Delete budget period',
                        message: 'To reassure us that you definitely want to delete this budget period, please type the full name of the period (exactly as shown) into the box below:'
                    };

                    $scope.textBox = {
                        placeholder: period.name
                    };

                    $scope.btn = {
                        className: 'btn-danger',
                        label: 'Delete',
                        disabled: true
                    };

                    $scope.$watch('textBox.model', function(newValue) {
                        if (!newValue) return;
                        $scope.btn.disabled = newValue.toLowerCase() != period.name.toLowerCase();
                    });

                    $scope.buttonClick = function (option) {
                        if (!option) {
                            return $uibModalInstance.dismiss('cancel');
                        } else {
                            $scope.btn.disabled = true;

                            SettingsService.deleteBudgetPeriod(period.id)
                                .success(function () {
                                    $uibModalInstance.close(true);
                                })
                                .error(function () {
                                    $uibModalInstance.close(false);
                                });
                        }
                    };
                }
            });

            modalInstance.result.then(function (isDeleted) {
                if (isDeleted) {
                    AlertService.add("success", "We've deleted the budget period for you.");
                    $state.go('^.byEntity', {entity: $stateParams.entity});
                } else {
                    AlertService.add('danger', "We couldn't delete the budget period just then. Please try again.");
                }
            });
        };
    }
]);
