var controllers = require('../../module');

controllers.controller('SettingsRestBreaksCtrl', ['$scope', '$state', 'SettingsService', 'AlertService', 'EnvironmentDataService',
  function ($scope, $state, SettingsService, AlertService, EnvironmentDataService) {
    $scope.dataLoaded = false;
    $scope.saveActioning = false;

    $scope.formData = {};
    $scope.formData.default = {
      isPaid: true
    };
    $scope.formData.specific = {
      isPaid: true
    };

    function splitRestBreaks(breaks) {
      var defaults = [];
      var specifics = [];

      breaks.forEach(function (restBreak) {
        restBreak.entityId ? specifics.push(restBreak) : defaults.push(restBreak);
      });

      $scope.restBreaks = {defaults: defaults, specifics: specifics};
    }

    // Load up the rest breaks and the metadata we need
    // We can't use .success() here because it's implementation doesn't allow for proper chaining :(
    SettingsService.getRestBreaks()
      .then(function (breaks) {
        splitRestBreaks(breaks.data);

        EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
          .then(({ data }) => {
            $scope.entities = data;
            $scope.dataLoaded = true;
          });
      })
      .catch(function (err, status) {
        if (status != 403) {
          AlertService.add('danger', 'We weren\'t able to load the rest breaks just then. Please try again');
        }

        $state.go('dashboard');
      });

    $scope.deleteRestBreak = function (index, isDefault) {
      if (isDefault) {
        $scope.restBreaks.defaults.splice(index, 1);
      } else {
        $scope.restBreaks.specifics.splice(index, 1);
      }
    };

    $scope.addRestBreak = function (specific) {
      var newBreak = {
        entityId: specific ? $scope.formData.specific.entity : null,
        name: specific ? $scope.formData.specific.name : $scope.formData.default.name,
        isPaid: specific ? ($scope.formData.specific.isPaid ? 1 : 0) : ($scope.formData.default.isPaid ? 1 : 0),
        rules: []
      };

      if (specific) {
        $scope.restBreaks.specifics.push(newBreak);
      } else {
        $scope.restBreaks.defaults.push(newBreak);
      }

      $scope.formData.default = {
        isPaid: true
      };
    };

    $scope.hasRestBreaks = function (entity) {
      var hasRestBreaks = false;

      angular.forEach($scope.restBreaks.specifics, function (restBreak) {
        if (restBreak.entityId == entity) {
          hasRestBreaks = true;
        }
      });

      return hasRestBreaks;
    };

    $scope.saveBreaks = function () {
      $scope.saveActioning = true;

      var restBreaks = angular.copy($scope.restBreaks.defaults).concat(angular.copy($scope.restBreaks.specifics));

      angular.forEach(restBreaks, function (restBreak) {
        angular.forEach(restBreak.rules, function (rule) {
          delete rule.tags;
          delete rule.groups;

          rule.daysApplicable = [0,1,2,3,4,5,6];

          // The timepickers convert our times/durations in to moment objects
          // If we just send the moment object along, it's sent in UTC and we end up losing/adding hours
          Object.keys(rule).forEach(function (key) {
            var value = rule[key];

            if (moment.isMoment(value)) {
              rule[key] = value.format('HH:mm:ss');
            }
          });
        });
      });

      SettingsService.updateRestBreaks({restBreaks: restBreaks})
        .success(function (breaks) {
          splitRestBreaks(breaks);
          $scope.saveActioning = false;
          AlertService.add('success', 'Rest break settings have been successfully saved.');
        })
        .error(function (err, status) {
          if (status != 403) {
            AlertService.add('danger', 'We weren\'t able to save the rest breaks just then. Please try again.');
          }

          $scope.saveActioning = false;
        });
    };

    $scope.allRulesValid = function () {
      var haveInvalidRule = false;

      var restBreaks = angular.copy($scope.restBreaks.defaults).concat(angular.copy($scope.restBreaks.specifics));

      restBreaks.forEach(function (restBreak) {
        if (haveInvalidRule) return;

        restBreak.rules.forEach(function (rule) {
          if (haveInvalidRule) return;

          if (rule.whoType === 1 || rule.whoType === 2) {
            return haveInvalidRule = !rule.whoValue.length;
          }

          if (rule.whoType === 3 || rule.whoType === 4) {
            return haveInvalidRule = !rule.whoValue || typeof rule.whoValue !== 'number';
          }
        });
      });

      return !haveInvalidRule;
    };

  }]);
