const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/search.html');

const directives = require('../module');

directives.directive('rrStaffSearch', (
  StaffService,
  SessionService,
) => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      onSelect: '&',
      readOnly: '=?',
      activeOnly: '=?',
      hideSelf: '=?',
      simpleSearch: '=?',
      useGraphql: '=?',
      scope: '@?',
    },
    link: ($scope) => {
      const ownUserId = SessionService.getUserId();
      const searchScope = $scope.scope !== '' && $scope.scope !== undefined
        ? $scope.scope : undefined;

      $scope.props = {
        searchInput: '',
        isEnabled: true,
      };

      $scope.$watch('readOnly', (value) => {
        if (value === undefined) {
          return;
        }

        $scope.props.isEnabled = !$scope.readOnly;
      });

      $scope.searchStaff = async function (searchTerm) {
        const validQueryTypes = ['id', 'name', 'hr', 'payroll', 'email'];
        const correspondingMatchValues = ['id', 'name', 'hrId', 'payrollId', 'email'];
        let match = 'name';
        let query = searchTerm;

        if (searchTerm.includes(':')) {
          const [queryType, queryTerm] = searchTerm.split(':');
          const queryTypeIndex = validQueryTypes.indexOf(queryType);

          if (queryTypeIndex !== -1) {
            match = correspondingMatchValues[queryTypeIndex];
            query = queryTerm;
          }
        }

        if (!query) {
          return [];
        }

        if ($scope.useGraphql) {
          const date = moment().format();
          const hideSelf = true;

          const response = await StaffService.searchGraphQL(
            query,
            hideSelf,
            !!$scope.activeOnly,
            date,
          );

          const users = [];

          if (response.data && response.data.staff && response.data.staff.edges) {
            response.data.staff.edges.forEach(({ node }) => {
              users.push(node);
            });
          }

          return users;
        }

        const response = await StaffService.search(query, !!$scope.activeOnly, match, searchScope);

        return response.data.users.flatMap((row) => {
          if ($scope.hideSelf && row.id === ownUserId) {
            return [];
          }

          row.name = row.preferredName
            ? `${row.firstName} (${row.preferredName}) ${row.lastName}`
            : `${row.firstName} ${row.lastName}`;
          return [row];
        });
      };

      $scope.onOptionSelected = (item, model) => {
        if ($scope.onSelect) {
          $scope.onSelect({
            user: model,
          });
        }
      };
    },
  };
});
