const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/documents/uploadModal.html');

module.exports = (
  $scope,
  $uibModal,
  $state,
  $translate,
  $window,
  StaffService,
  AlertService,
  SessionService,
  StaffCommon,
  EnvironmentDataService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.REQUESTS.DOCUMENTS.APPOINTMENT_STATUS_ACTIVE',
    'STAFF.REQUESTS.DOCUMENTS.APPOINTMENT_STATUS_INACTIVE',
    'STAFF.REQUESTS.DOCUMENTS.X_WEEKS_OR_LESS',
  ]);

  const expiryWeekOptions = [1, 2, 4, 8, 13, 26, 52];

  $scope.props = {
    defaultEntityIds: [SessionService.getEntity()],
    loadingData: false,
    noMoreData: false,
    data: [],
    appointmentStatusOptions: [
      { id: 'active', label: translations['STAFF.REQUESTS.DOCUMENTS.APPOINTMENT_STATUS_ACTIVE'] },
      { id: 'inactive', label: translations['STAFF.REQUESTS.DOCUMENTS.APPOINTMENT_STATUS_INACTIVE'] },
    ],
    defaultAppointmentStatusOption: ['active'],
    expiryOptions: expiryWeekOptions.map((week) => ({
      id: week, label: `${week}${translations['STAFF.REQUESTS.DOCUMENTS.X_WEEKS_OR_LESS']}`,
    })),
    defaultExpiryOption: [4],
    noExpirableCategories: false,
    documentCategoryById: {},
  };

  $scope.getDataParams = {
    'userCurrentEntityIds[]': $scope.props.defaultEntityIds,
    usersActive: true,
    start: moment.utc().startOf('day').subtract(4, 'weeks').format(),
    end: moment.utc().startOf('day').add(4, 'weeks').format(),
    page: 1,
    limit: 50,
    sortOrder: 'expiryDateAsc',
  };

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.getDataParams['userCurrentEntityIds[]'] = entityIds.length ? entityIds : undefined;
    $scope.loadData(true);
  };

  $scope.onAppointmentStatusFilter = (selectedOptions) => {
    const option = selectedOptions[0];

    if (option === 'active') {
      $scope.getDataParams.usersActive = true;
    } else if (option === 'inactive') {
      $scope.getDataParams.usersActive = false;
    } else {
      $scope.getDataParams.usersActive = undefined;
    }

    $scope.loadData(true);
  };

  $scope.onCategoryFilter = (selectedOptions) => {
    $scope.getDataParams['categoryIds[]'] = selectedOptions.length && selectedOptions[0] !== ''
      ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  $scope.onUserFilter = (selectedUser) => {
    $scope.getDataParams['userIds[]'] = selectedUser ? selectedUser.id : undefined;
    $scope.loadData(true);
  };

  $scope.onExpiryFilter = (selectedOptions) => {
    $scope.getDataParams.end = moment.utc().startOf('day').add(selectedOptions[0], 'weeks').format();
    $scope.loadData(true);
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.DocumentCategory,
  ])
    .then(([
      entityGroup,
      documentCategory,
    ]) => {
      $scope.props.categories = documentCategory.data
        .filter(({ expirable, sensitive }) => {
          if (sensitive && !StaffCommon.canAccessSensitiveDocuments()) {
            return false;
          }

          return expirable;
        })
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));

      $scope.props.categories.forEach((category) => {
        $scope.props.documentCategoryById[category.id] = category;
      });

      $scope.props.entityGroupList = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => ({
            id: entity.id,
            label: entity.name,
            parentId: entity.groupId,
            depth: 1,
          })),
        ]));

      if (!$scope.props.categories.length) {
        $scope.props.noExpirableCategories = true;
      }
    });

  function embellishRecord(r) {
    const expiryDate = moment(r.expiryDate);

    return {
      ...r,
      documentCategoryMeta:
        $scope.props.documentCategoryById[r.categoryId],
      expiryDateFromNow: expiryDate.clone().add(1, 'day').fromNow(),
      expiryDateFormat: expiryDate.format('ll'),
      expired: expiryDate.clone().add(1, 'day').isBefore(),
      friendlyFileSize: StaffService.getDocumentFriendlyFileSize(r.fileSize),
    };
  }

  $scope.loadData = (reset) => {
    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.loadingData = true;

    if (reset) {
      $scope.getDataParams.page = 1;
      $scope.props.noMoreData = false;
      $scope.props.data = [];
    }

    StaffService.getExpiringDocuments($scope.getDataParams)
      .then(({ data }) => {
        const { results } = data;

        $scope.props.data.push(...results.map((r) => embellishRecord(r)));
        $scope.getDataParams.page += 1;

        if (results.length < $scope.getDataParams.limit) {
          $scope.props.noMoreData = true;
        }

        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.noMoreData = true;
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.DOCUMENT_SIGNATURES.ERROR_500']);
        }
      });
  };

  $scope.viewDocument = (document) => {
    if (document.actionInProgress) {
      return;
    }

    document.actionInProgress = true;

    StaffService.getDocument(document.id, { download: true })
      .then(({ data }) => {
        const { signedUrl } = data;

        if (signedUrl) {
          $window.location = signedUrl;
        }

        document.actionInProgress = false;
      })
      .catch(() => {
        document.actionInProgress = false;
      });
  };

  $scope.uploadNew = ({ id, categoryId, userId }) => {
    const uploadModal = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('../../view/documents/uploadModal'),
      resolve: {
        data: () => ({
          userId,
          categoryId,
        }),
      },
    });

    uploadModal.result.then((result) => {
      if (!result) {
        return;
      }

      $scope.props.data.forEach((r, index) => {
        if (r.id === id) {
          $scope.props.data.splice(index, 1);
        }
      });
    });
  };
};
