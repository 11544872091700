const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/accountCompletionTracker.html');

import confetti from 'canvas-confetti';

const directives = require('../module');

directives.directive('rrStaffAccountCompletionTracker', (
  StaffService,
  $timeout,
  $window,
  $translate,
) => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      user: '=',
    },
    link: ($scope, element) => {
      const translations = $translate.instant([
        'DIRECTIVES.STAFF.ACCOUNT_COMPLETION_TRACKER.CONSTITUENT_PERSONAL',
        'DIRECTIVES.STAFF.ACCOUNT_COMPLETION_TRACKER.CONSTITUENT_EMPLOYMENT',
        'DIRECTIVES.STAFF.ACCOUNT_COMPLETION_TRACKER.CONSTITUENT_PAY',
        'DIRECTIVES.STAFF.ACCOUNT_COMPLETION_TRACKER.CONSTITUENT_HOLIDAY_ALLOWANCE',
      ]);

      $scope.props = {
        visible: false,
        loading: false,
        translations: {
          personal: translations['DIRECTIVES.STAFF.ACCOUNT_COMPLETION_TRACKER.CONSTITUENT_PERSONAL'],
          appointment: translations['DIRECTIVES.STAFF.ACCOUNT_COMPLETION_TRACKER.CONSTITUENT_EMPLOYMENT'],
          pay: translations['DIRECTIVES.STAFF.ACCOUNT_COMPLETION_TRACKER.CONSTITUENT_PAY'],
          allowance: translations['DIRECTIVES.STAFF.ACCOUNT_COMPLETION_TRACKER.CONSTITUENT_HOLIDAY_ALLOWANCE'],
        },
      };

      function blastConfetti() {
        const {
          top,
          height,
          left,
          width,
        } = element[0].getBoundingClientRect();

        const x = (left + width / 2) / $window.innerWidth;
        const y = (top + height / 2) / $window.innerHeight;

        confetti({
          origin: { x, y },
          zIndex: 2000,
          spread: 180,
        });
      }

      function hideAndReset() {
        $scope.props.constituents = null;
        $scope.props.userId = null;
        $scope.props.visible = false;
      }

      function refreshStatus() {
        if (!$scope.props.userId) {
          return;
        }

        $scope.props.loading = true;

        StaffService.getEmployerSetupStatus($scope.props.userId)
          .then(({ data }) => {
            const {
              complete,
              constituents,
            } = data;

            if (!complete && $scope.props.constituents) {
              const previousState = Object.values($scope.props.constituents);
              const newState = Object.values(constituents);

              const isImprovement = previousState
                .some((value, index) => value === false && newState[index] === true);

              if (isImprovement) {
                blastConfetti();
              }
            }

            if (complete) {
              blastConfetti();
            }

            $scope.props.constituents = constituents;
            $scope.props.loading = false;
            $scope.props.visible = true;
          });
      }

      $scope.$on('user:update', () => {
        refreshStatus();
      });

      $scope.$watch('user', (user) => {
        if (!user) {
          return;
        }

        if (user.flags.employerSetupComplete) {
          hideAndReset();
          return;
        }

        $scope.props.userId = user.id;
        refreshStatus();
      });
    },
  };
});
