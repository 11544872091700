const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/filterDateButton.html');

var directives = require('./module');

directives.directive('rrFilterDateButton', ['$translate', function ($translate) {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        scope: {
            onSelect: '&',
            readOnly: '=?',
            defaultState: '=?',
            exordium: '@',
            icon: '@?',
            includeAny: '@?',
            borderless: '@?',
            validOptions: '@?',
        },
        link: function ($scope) {
            $scope.props = {
                displayText: $scope.defaultText,
                isEnabled: true,
                isMenuOpen: false,
                icon: $scope.icon,
                initialised: true,
                state: {
                    option: 1, // 1: any, 2: before, 3: after, 4: between
                    dateStart: moment().startOf('day'),
                    dateEnd: moment().startOf('day'),
                },
                datePickerConfig: {
                    minView: 'day',
                    startView: 'day',
                    modelType: 'moment',
                    renderOn: 'refreshDatePicker',
                },
                validOptions: $scope.validOptions || [1, 2, 3, 4],
            };

            var hasMenuOpenedOnce = false;

            var translations = $translate.instant([
                'DIRECTIVES.FILTER_DATE_BUTTON.ANY',
                'DIRECTIVES.FILTER_DATE_BUTTON.BEFORE',
                'DIRECTIVES.FILTER_DATE_BUTTON.AFTER',
                'DIRECTIVES.FILTER_DATE_BUTTON.BETWEEN',
                'DIRECTIVES.FILTER_DATE_BUTTON.IS',
                'DIRECTIVES.FILTER_DATE_BUTTON.AND',
            ]);

            function evaluateEnabledState() {
                $scope.props.isEnabled = !$scope.readOnly && $scope.props.initialised;
            }

            $scope.$watch('readOnly', function (val) {
                if (val === undefined) {
                    return;
                }

                evaluateEnabledState();
            });

            function updateDisplayText() {
                var option = $scope.props.state.option;
                var sentence = [translations['DIRECTIVES.FILTER_DATE_BUTTON.IS']];

                if (option === 1) {
                    sentence.push(translations['DIRECTIVES.FILTER_DATE_BUTTON.ANY']);
                } else if (option === 2) {
                    sentence.push(translations['DIRECTIVES.FILTER_DATE_BUTTON.BEFORE'],
                        $scope.props.state.dateStart.format('ll'));
                } else if (option === 3) {
                    sentence.push(translations['DIRECTIVES.FILTER_DATE_BUTTON.AFTER'],
                        $scope.props.state.dateStart.format('ll'));
                } else if (option === 4) {
                    sentence.push(translations['DIRECTIVES.FILTER_DATE_BUTTON.BETWEEN'],
                        $scope.props.state.dateStart.format('ll'),
                        translations['DIRECTIVES.FILTER_DATE_BUTTON.AND'],
                        $scope.props.state.dateEnd.format('ll'));
                }

                $scope.props.displayText = sentence.join(' ');
            }

            function onFinish() {
                var obj = {
                    state: $scope.props.state,
                };

                if ($scope.onSelect) {
                    $scope.onSelect(obj);
                }
            }

            $scope.$watch('defaultState', function (val) {
                if (val === undefined || val === null || typeof val !== 'object') {
                    return;
                }

                if (val.option) {
                    $scope.props.state.option = val.option;
                }

                if (val.dateStart) {
                    $scope.props.state.dateStart = val.dateStart;
                }

                if (val.dateEnd) {
                    $scope.props.state.dateEnd = val.dateEnd;
                }

                updateDisplayText();
            });

            $scope.$watch('props.isMenuOpen', function (val) {
                if (val === true) {
                    hasMenuOpenedOnce = true;
                    return;
                }

                if (val === false && hasMenuOpenedOnce) {
                    if ($scope.props.state.option === 4 &&
                        $scope.props.state.dateEnd.isBefore($scope.props.state.dateStart)) {
                        $scope.props.state.dateEnd = $scope.props.state.dateStart.clone();
                    }

                    updateDisplayText();
                    onFinish();
                }
            });

            $scope.$watch('props.state.option', function (val) {
                if (!hasMenuOpenedOnce) {
                    return;
                }

                if (val === 4) {
                    $scope.props.state.dateEnd = $scope.props.state.dateStart.clone();
                }
            });

            evaluateEnabledState();
            updateDisplayText();

            $scope.dateStartChanged = function (newDate) {
                $scope.props.state.dateStart = newDate.clone();
            };

            $scope.dateEndChanged = function (newDate) {
                $scope.props.state.dateEnd = newDate.clone();
            };
        }
    };
}]);
