const services = require('./module');

services.factory('SettingsService', ['$http', 'ENDPOINT_API',
  function ($http, ENDPOINT_API) {
    const baseUrl = ENDPOINT_API + '/hq/';

    return {
      getRestBreaks: function() {
        return $http.get(baseUrl + 'settings/restBreaks');
      },
      updateRestBreaks: function(data) {
        return $http.put(baseUrl + 'settings/restBreaks', data);
      },

      getShiftRoutines: function() {
        return $http.get(baseUrl + 'settings/shiftRoutines');
      },
      addShiftRoutine: function(data) {
        return $http.post(baseUrl + 'settings/shiftRoutines', data);
      },
      deleteShiftRoutine: function(id) {
        return $http.delete(baseUrl + 'settings/shiftRoutines/' + id);
      },
      addItem: function(routineId, data) {
        return $http.post(baseUrl + 'settings/shiftRoutines/' + routineId + '/item', data);
      },
      updateItem: function(routineId, itemId, data) {
        return $http.put(baseUrl + 'settings/shiftRoutines/' + routineId + '/item/' + itemId, data);
      },
      deleteItem: function(routineId, itemId) {
        return $http.delete(baseUrl + 'settings/shiftRoutines/' + routineId + '/item/' + itemId);
      },

      getPayElementTypes: function() {
        return $http.get(baseUrl + 'settings/payElementTypes');
      },
      addPayElementType: function (data) {
        return $http.post(baseUrl + 'settings/payElementTypes', data);
      },
      updatePayElementType: function (id, data) {
        return $http.put(baseUrl + 'settings/payElementTypes/' + id, data);
      },
      deletePayElementType: function (id) {
        return $http.delete(baseUrl + 'settings/payElementTypes/' + id);
      },

      getPayRuleGroups: function () {
        return $http.get(baseUrl + 'settings/payRules');
      },
      addPayRuleGroup: function (data) {
        return $http.post(baseUrl + 'settings/payRules', data);
      },
      updatePayRuleGroup: function (id, data) {
        return $http.put(baseUrl + 'settings/payRules/' + id, data);
      },
      deletePayRuleGroup: function (id) {
        return $http.delete(baseUrl + 'settings/payRules/' + id);
      },
      updatePayRule: function (groupId, ruleId, data) {
        return $http.put(baseUrl + 'settings/payRules/' + groupId + '/rule/' + ruleId, data);
      },
      addPayRule: function (groupId, data) {
        return $http.post(baseUrl + 'settings/payRules/' + groupId + '/rule', data);
      },
      deletePayRule: function (groupId, ruleId) {
        return $http.delete(baseUrl + 'settings/payRules/' + groupId + '/rule/' + ruleId);
      },

      getPermissionLevels: function () {
        return $http.get(`${ENDPOINT_API}/staff/permissionLevels`);
      },
      updatePermissionLevels: function (data) {
        return $http.post(`${ENDPOINT_API}/staff/permissionLevels`, data);
      },
      checkPermissionsSandbox: function (data) {
        return $http.post(`${ENDPOINT_API}/staff/permissionLevels/sandbox`, data);
      },

      getBudgetDashboard: function () {
        return $http.get(baseUrl + 'budgeting/dashboard');
      },
      getBudgetEntityDashboard: function (entityId) {
        return $http.get(baseUrl + 'budgeting/dashboard/' + entityId);
      },
      getBudgetPeriod: function (id) {
        return $http.get(baseUrl + 'budgeting/period/' + id);
      },
      deleteBudgetPeriod: function (id) {
        return $http.delete(baseUrl + 'budgeting/period/' + id);
      },
      addBudgetPeriod: function (data) {
        return $http.post(baseUrl + 'budgeting/period', data);
      },
      updateBudgetPeriodWeeks: function (id, data) {
        return $http.put(baseUrl + 'budgeting/period/weeks/' + id, data);
      },

      getIntegrationSettings: function (id) {
        return $http.get(baseUrl + 'integration/settings/' + id);
      },
      updateIntegrationSettings: function (id, data) {
        const stringifiedData = JSON.stringify(data, function (key, value) {
          if (key === '$$hashKey') {
            return undefined;
          }

          return value;
        });

        return $http.put(baseUrl + 'integration/settings/' + id, stringifiedData);
      },
    };
  }]);
