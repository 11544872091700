const translations = {
  "STAFF": {
    "CONSTANTS": {
      "UK_STARTER_DECLARATION_A": "R: Questo è il loro primo lavoro dall'inizio dell'anno fiscale (6 aprile)",
      "UK_STARTER_DECLARATION_B": "B: Questo è attualmente il loro unico lavoro",
      "UK_STARTER_DECLARATION_C": "C: Hanno un altro lavoro o una pensione",
      "UK_STUDENT_LOAN_PLAN_1": "Piano 1",
      "UK_STUDENT_LOAN_PLAN_2": "Piano 2",
      "UK_STUDENT_LOAN_PLAN_4": "Piano 4",
      "UK_STUDENT_LOAN_PLAN_5": "Piano 5",
      "UK_STUDENT_LOAN_PLAN_POSTGRADUATE": "Post-laurea",
      "UK_SETTLED_STATUS_NA": "Non applicabile",
      "UK_SETTLED_STATUS_PRE_SETTLED": "Pre-regolato",
      "UK_SETTLED_STATUS_SETTLED": "Risolto",
      "MARITAL_STATUS_SINGLE": "Singola",
      "MARITAL_STATUS_MARRIED": "Sposato",
      "MARITAL_STATUS_DIVORCED": "Divorziato",
      "MARITAL_STATUS_WIDOWED": "Vedova",
      "MARITAL_STATUS_CIVIL_PARTNERSHIP": "Partenariato civile",
      "PAYMENT_METHOD_ELECTRONIC": "Trasferimento elettronico",
      "PAYMENT_METHOD_CHEQUE": "Assegno",
      "PAYMENT_METHOD_MANUAL": "Manuale",
      "CONTRACTUAL_OBLIGATION_PERIOD_WEEK": "A settimana",
      "CONTRACTUAL_OBLIGATION_PERIOD_MONTH": "Al mese",
      "CONTRACTUAL_OBLIGATION_UNIT_HOURS": "Ore",
      "CONTRACTUAL_OBLIGATION_UNIT_DAYS": "Giorni",
      "PAY_AMOUNT_TYPE_HOURLY": "Tariffa oraria",
      "PAY_AMOUNT_TYPE_DAILY": "Tariffa giornaliera",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salario annuo",
      "PAY_FREQUENCY_WEEKLY": "Settimanale",
      "PAY_FREQUENCY_FORTNIGHTLY": "Quindicinale",
      "PAY_FREQUENCY_MONTHLY": "Mensile",
      "PAY_FREQUENCY_FOUR_WEEKLY": "Quadri-settimanale"
    },
    "OVERVIEW": {
      "ERROR_500": "Non siamo riusciti a caricare alcun dato in quel momento. Per favore riprova.",
      "TITLE": "Personale",
      "ADD_STAFF": "Aggiungi nuovo",
      "NO_MATCH": "Non siamo riusciti a trovare nessuno che corrisponda ai tuoi filtri.",
      "NO_ACTIVE_APPT_TAG": "Inattivo",
      "NOT_APPROVED_TAG": "Non approvato",
      "DOCUMENTS_INVALID_TAG": "Documenti non validi",
      "NOT_INVITED_TAG": "Non invitato",
      "NOT_SETUP_TAG": "Invitato",
      "EMPLOYER_INCOMPLETE_TAG": "Incompleto",
      "SELF_ONBOARDING": "Imbarco automatico",
      "ACCOUNT_TAB_LABEL": "Account",
      "PERSONAL_TAB_LABEL": "Personale",
      "EMPLOYMENT_TAB_LABEL": "Occupazione",
      "PAY_TAB_LABEL": "Paga",
      "SCHEDULE_TAB_LABEL": "Pianificazione",
      "ABSENCE_TAB_LABEL": "Assenza",
      "AVAILABILITY_TAB_LABEL": "Disponibilità",
      "SHIFT_ROUTINE_TAB_LABEL": "Routine a turni",
      "DOCUMENTS_TAB_LABEL": "Documenti",
      "ATTENDANCE_TAB_LABEL": "Frequenza",
      "NOTES_TAB_LABEL": "Note",
      "FILTER_LABEL_SITE": "Sito/dipartimento",
      "FILTER_LABEL_POSITION": "Ruolo lavorativo",
      "FILTER_LABEL_STAFF_GROUP": "Gruppo di personale",
      "FILTER_LABEL_EMPLOYMENT": "Status occupazionale",
      "FILTER_LABEL_APPROVAL_STATUS": "Stato di approvazione",
      "FILTER_LABEL_EMPLOYEE_SETUP": "Invito Rotaready",
      "FILTER_LABEL_EMPLOYER_SETUP": "Completamento dell'account",
      "FILTER_LABEL_EMPLOYMENT_START": "Data di iscrizione",
      "FILTER_LABEL_EMPLOYMENT_END": "Data di partenza",
      "FILTER_LABEL_EMPLOYMENT_START_OR_END": "Data di iscrizione o partenza",
      "BUTTON_COLUMNS": "Colonne",
      "BUTTON_EXPORT": "Esporta",
      "EMPLOYMENT_STATUS_ACTIVE": "Attivo",
      "EMPLOYMENT_STATUS_INACTIVE": "Inattivo",
      "APPROVAL_STATUS_APPROVED": "Approvato",
      "APPROVAL_STATUS_NOT_APPROVED": "Non approvato",
      "EMPLOYEE_SETUP_COMPLETE": "Accettato",
      "EMPLOYEE_SETUP_NOT_COMPLETE": "In sospeso o non inviato",
      "EMPLOYER_SETUP_COMPLETE": "Completo",
      "EMPLOYER_SETUP_NOT_COMPLETE": "Incompleto",
      "COLUMN_NAME": "Nome",
      "COLUMN_NEXT_SHIFT": "Turno successivo",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_BIRTH_DATE": "Data di nascita",
      "COLUMN_EMAIL": "E-mail",
      "COLUMN_MOBILE": "Telefono",
      "COLUMN_OTHER_NAMES": "Altro (i) nome (i)",
      "COLUMN_ADDRESS_LINE_1": "Linea di indirizzo 1",
      "COLUMN_ADDRESS_LINE_2": "Linea di indirizzo 2",
      "COLUMN_ADDRESS_CITY": "Località/città",
      "COLUMN_ADDRESS_COUNTY": "Contea/regione",
      "COLUMN_ADDRESS_POST_CODE": "Codice postale",
      "COLUMN_ADDRESS_COUNTRY": "Paese",
      "COLUMN_PREFERRED_NAME": "Nome preferito",
      "COLUMN_GENDER": "Genere",
      "COLUMN_TITLE": "Titolo",
      "COLUMN_ETHNICITY": "Etnia",
      "COLUMN_NATIONALITY": "Nazionalità",
      "COLUMN_MARITAL_STATUS": "Stato civile",
      "COLUMN_SETTLED_STATUS": "Status residente (Regno Unito)",
      "COLUMN_PASSPORT_NUMBER": "Numero del passaporto",
      "COLUMN_PASSPORT_EXPIRY_DATE": "Scadenza del passaporto",
      "COLUMN_WORK_EMAIL": "Email secondaria",
      "COLUMN_WORK_PHONE": "Telefono secondario",
      "COLUMN_BANK_ACCOUNT_NAME": "Nome del conto bancario",
      "COLUMN_BANK_ACCOUNT_NUMBER": "Numero di conto bancario",
      "COLUMN_BANK_ACCOUNT_SORT_CODE": "Codice di ordinamento bancario",
      "COLUMN_PAYROLL_PAYMENT_METHOD": "Metodo di pagamento",
      "COLUMN_NATIONAL_INSURANCE_NUMBER": "Numero di previdenza nazionale (Regno Unito)",
      "COLUMN_STUDENT_LOANS": "Prestiti per studenti (Regno Unito)",
      "COLUMN_AVERAGE_HOURS_DAY": "Ore medie al giorno",
      "COLUMN_AVERAGE_HOURS_HALF_DAY": "Ore medie/mezza giornata",
      "COLUMN_AVERAGE_HOURS_WEEK": "Ore medie settimanali",
      "COLUMN_PRE_AVERAGE_HOURS_PER_DAY": "Ore giornaliere medie pre-Rotaready",
      "COLUMN_PRE_AVERAGE_HOURS_DATE": "Data oraria media pre-Rotaready",
      "COLUMN_PERMISSION_LEVEL_NAME": "Livello di autorizzazione",
      "COLUMN_ANONYMISED": "Anonimizzato",
      "COLUMN_ANONYMISE_DATE": "Data di anonimizzazione",
      "COLUMN_JOIN_DATE": "Data di iscrizione",
      "COLUMN_LEAVE_DATE": "Data di partenza",
      "COLUMN_LEAVE_DATE_EDITED_BY": "Data di partenza fissata da",
      "COLUMN_LEAVE_DATE_EDITED_DATE": "Data di partenza: data impostata",
      "COLUMN_LENGTH_OF_SERVICE": "Durata del servizio (giorni)",
      "COLUMN_RECRUITMENT_SOURCE": "Fonte di reclutamento",
      "COLUMN_LEAVER_REASON": "Motivo del licenziamento",
      "COLUMN_LEAVER_REHIRE": "Idoneo per la riassunzione",
      "COLUMN_EMPLOYEE_NUMBER": "Numero del dipendente",
      "COLUMN_NOTICE_PERIOD": "Periodo di avviso",
      "COLUMN_STARTER_DECLARATION": "Dichiarazione di avviamento (Regno Unito)",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED": "Indennità finalizzate",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY": "Indennità finalizzate da",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE": "Data di completamento delle indennità",
      "COLUMN_SITE_NAME": "Sito",
      "COLUMN_SITE_ID": "ID del sito",
      "COLUMN_DEPARTMENT_NAME": "Dipartimento",
      "COLUMN_DEPARTMENT_ID": "ID del dipartimento",
      "COLUMN_JOB_ROLE_NAME": "Ruolo lavorativo",
      "COLUMN_COMPANY_NAME": "Compagnia",
      "COLUMN_CONTRACT_BASIS": "Tipo di contratto",
      "COLUMN_LINE_MANAGER_NAME": "Responsabile di linea",
      "COLUMN_STAFF_GROUP_NAME": "Gruppo di personale",
      "COLUMN_WORKS_PUBLIC_HOLIDAYS": "Lavori e giorni festivi",
      "COLUMN_WORKING_TIME_OPT_OUT": "Disattivazione delle normative sull'orario di lavoro",
      "COLUMN_MIN_OBLIGATION_VALUE": "Valore dell'obbligo contrattuale",
      "COLUMN_MIN_OBLIGATION_UNIT": "Unità di obbligazione contrattuale",
      "COLUMN_MIN_OBLIGATION_PERIOD": "Periodo dell'obbligo contrattuale",
      "COLUMN_MAX_OBLIGATION_VALUE": "Valore limite di lavoro",
      "COLUMN_MAX_OBLIGATION_UNIT": "Unità limite di lavoro",
      "COLUMN_MAX_OBLIGATION_PERIOD": "Periodo limite di lavoro",
      "COLUMN_DEPARTMENT_CURRENCY_CODE": "Codice valuta",
      "COLUMN_PAYROLL_CALENDAR_NAME": "Calendario delle retribuzioni",
      "COLUMN_PAYROLL_CALENDAR_FREQUENCY": "Frequenza del calendario delle retribuzioni",
      "COLUMN_PAY_AMOUNT": "Importo del pagamento",
      "COLUMN_PAY_AMOUNT_TYPE": "Tipo di importo del pagamento",
      "COLUMN_PAY_UPLIFT": "Ascensore salariale",
      "ASSET_REGISTRY_TAB_LABEL": "Registro delle risorse"
    },
    "VIEW": {
      "ACCOUNT": {
        "ERROR_500": "Non possiamo elaborare la tua modifica in questo momento. Per favore riprova.",
        "ERROR_403": "Non sei autorizzato a visualizzare/modificare le informazioni sull'account di questa persona.",
        "SAVE_SUCCESS": "Le tue modifiche sono state salvate.",
        "TITLE": "Account",
        "LAST_SIGN_IN_LABEL": "Ultimo accesso",
        "LAST_SIGN_IN_NEVER": "Mai",
        "SECTION_PERMISSIONS_TITLE": "Autorizzazioni",
        "PERMISSION_LEVEL_LABEL": "Livello di autorizzazione",
        "PERMISSION_LEVEL_HELP": "Ciò modificherà ciò che il dipendente può vedere e fare in Rotaready.",
        "SITE_ACCESS_LABEL": "Accesso al sito",
        "SITE_ACCESS_OPTION_APPOINTMENT": "Il loro sito attuale",
        "SITE_ACCESS_OPTION_ALL": "Tutti i siti",
        "SITE_ACCESS_OPTION_CUSTOM": "Il loro sito attuale e i siti personalizzati",
        "CLOCK_IN_PIN_LABEL": "Reimposta il PIN di orologio",
        "CLOCK_IN_PIN_HELP": "Inserisci un nuovo codice a 4 cifre per modificare il PIN di orario di questo dipendente.",
        "TAGS_LABEL": "Etichette",
        "TAGS_TIP": "Contrassegna i dipendenti con qualcosa di significativo, ad esempio competenze o attributi.",
        "TAGS_PLACEHOLDER": "Digita per trovare i tag",
        "SAVE_BUTTON_LOADING": "Salvataggio...",
        "SAVE_BUTTON_TEXT": "Salva",
        "LANGUAGE_LABEL": "Lingua",
        "LANGUAGE_HELP": "Rotaready verrà visualizzato nella lingua scelta.",
        "ENTITY_GROUP_SUBSCRIPTION_TOOLTIP": "Questo dipendente viene assegnato automaticamente a questo sito. Puoi gestire il loro accesso nella pagina delle impostazioni del Gestore accessi al sito."
      },
      "PAY": {
        "INDEX": {
          "TAB_HISTORY": "Record di pagamento",
          "TAB_TRONC": "Tronco",
          "TAB_FINANCIALS": "Finanziari"
        },
        "VIEW_HISTORY": {
          "FILTER_TYPES_LABEL": "Tipo",
          "FILTER_TYPES_SALARY": "Salario",
          "FILTER_TYPES_POINT_IN_TIME": "Pagamenti una tantum",
          "FILTER_ELEMENT_TYPES_LABEL": "Elemento di pagamento",
          "STATE_ACTIVE": "Attivo",
          "STATE_HISTORICAL": "Storico",
          "STATE_FUTURE_DATED": "Datato al futuro",
          "COLUMN_EFFECTIVE_DATE": "Data di entrata in vigore",
          "COLUMN_STATUS": "Status",
          "COLUMN_ELEMENT_TYPE": "Elemento di pagamento",
          "COLUMN_CURRENCY": "Valuta",
          "COLUMN_AMOUNT": "Importo",
          "COLUMN_AMOUNT_TYPE": "Tipo di importo",
          "COLUMN_OPTIONS": "Opzioni",
          "ERROR_500": "Non possiamo caricare i registri delle retribuzioni in questo momento. Per favore riprova.",
          "ERROR_403": "Non sei autorizzato a visualizzare/modificare i record salariali di questa persona.",
          "ALERT_SUCCESS": "Il record salariale è stato salvato.",
          "ALERT_DELETED": "Il record di retribuzione è stato eliminato.",
          "OUTSIDE_OF_APPOINTMENT_WARNING": "Questo record di retribuzione non è valido perché non sono impiegati attivamente in questa data. Modifica la data di entrata in vigore.",
          "DELETE_LINK": "Elimina",
          "LAST_EDITED_TOOLTIP": "Ultima modifica effettuata da {{usernameuser}} il {{date}}",
          "NO_RECORDS": "Non ci sono registri salariali da mostrare.",
          "ADD_NEW_BUTTON": "Nuovo record salariale",
          "CONFIRM_DELETE": "Sei sicuro di voler eliminare questo record di retribuzione? Questo non può essere annullato."
        },
        "PAY_FORM": {
          "TITLE": "Paga",
          "LABEL_EFFECTIVE_DATE": "Data di entrata in vigore",
          "LABEL_ELEMENT": "Elemento di pagamento",
          "LABEL_AMOUNT": "Importo",
          "LABEL_AMOUNT_TYPE": "Tipo di importo",
          "LABEL_UPLIFT": "Aumento dei salari",
          "ALERT_NO_CURRENCY": "Il dipendente non è impiegato attivamente in questa data.",
          "ALERT_DUPLICATE_PAY": "Esiste già un record di retribuzione per l'elemento di pagamento scelto in quella data.",
          "HELP_TEXT_UPLIFT": "Ciò aumenterà i salari dei dipendenti nel controllo dei costi. Usalo per riflettere eventuali costi aggiuntivi sostenuti, come una pensione. Non preoccuparti delle tasse poiché vengono incluse automaticamente.",
          "BUTTON_SAVE": "Salva",
          "BUTTON_SAVE_LOADING": "Salvataggio...",
          "BUTTON_DELETE": "Elimina",
          "BUTTON_DELETE_LOADING": "Eliminazione in corso..."
        },
        "VIEW_FINANCIALS": {
          "ERROR_500": "Non possiamo caricare le informazioni finanziarie in questo momento. Per favore riprova.",
          "ERROR_403": "Non sei autorizzato a visualizzare/modificare le informazioni finanziarie di questa persona.",
          "SAVE_SUCCESS": "Le tue modifiche sono state salvate.",
          "SECTION_BANK_ACCOUNT": "Conto bancario",
          "SECTION_TAXES": "Imposte",
          "ACCOUNT_NUMBER_LABEL": "Numero di conto",
          "SORT_CODE_LABEL": "Codice di ordinamento",
          "ACCOUNT_NAME_LABEL": "Nome dell'account",
          "PAYMENT_METHOD": "Metodo di pagamento",
          "NI_NUMBER_LABEL": "Numero di assicurazione nazionale",
          "SECTION_STUDENT_LOANS": "Prestiti per studenti (Regno Unito)",
          "PLAN_TYPE_LABEL": "Tipo di piano",
          "EFFECTIVE_DATE_LABEL": "Data di entrata in vigore",
          "LOAN_TABLE_PLAN_COLUMN": "Tipo di piano",
          "LOAN_TABLE_DATE_COLUMN": "Data di entrata in vigore",
          "LOAN_TABLE_OPTIONS_COLUMN": "Opzioni",
          "LOAN_TABLE_NO_ROWS": "Nessun prestito studentesco aggiunto.",
          "LOAN_TABLE_DATE_NOT_SET": "Non impostato",
          "SUBHEADING_ADD_STUDENT_LOAN": "Aggiungi prestito studentesco",
          "OPTION_DELETE": "Elimina",
          "ADD_LOAN_BUTTON": "Aggiungi prestito studentesco",
          "SAVE_BUTTON_TEXT": "Salva"
        },
        "EDIT_STUDENT_LOAN": {
          "HEADING": "Modifica prestito studentesco",
          "FORM_PLAN_TYPE_LABEL": "Tipo di piano",
          "FORM_EFFECTIVE_DATE_LABEL": "Data di entrata in vigore",
          "SAVE_BUTTON_TEXT": "Salva"
        },
        "VIEW_TRONC": {
          "COLUMN_EFFECTIVE_DATE": "Data di entrata in vigore",
          "COLUMN_STATUS": "Status",
          "COLUMN_AMOUNT": "Importo",
          "COLUMN_APPORTION_TYPE": "Tipo di ripartizione",
          "COLUMN_LAST_EDITED": "Ultima modifica",
          "STATE_ACTIVE": "Attivo",
          "STATE_HISTORICAL": "Storico",
          "STATE_FUTURE_DATED": "Datato al futuro",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Tariffa oraria",
          "APPORTION_TYPE_CLASSIC_SALARY": "Salario annuo",
          "APPORTION_TYPE_POINTS": "Punti",
          "NO_RECORDS": "Non ci sono tariffe Tronc da mostrare.",
          "ADD_NEW_BUTTON": "Nuova tariffa Tronc",
          "ERROR_500": "Non possiamo caricare le tariffe Tronc in questo momento. Per favore riprova.",
          "ERROR_403": "Non sei autorizzato a visualizzare le tariffe Tronc di questa persona."
        },
        "ADD_TRONC_MODAL": {
          "TITLE": "Nuova tariffa Tronc",
          "BUTTON_SAVE": "Salva",
          "BUTTON_CANCEL": "Annulla",
          "LABEL_PERSON": "Persona",
          "LABEL_DATE": "Data di entrata in vigore",
          "LABEL_APPORTION_TYPE": "Tipo di ripartizione",
          "LABEL_AMOUNT": "Importo",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Tariffa oraria",
          "APPORTION_TYPE_CLASSIC_SALARY": "Salario annuo",
          "APPORTION_TYPE_POINTS": "Punti",
          "APPORTION_TYPE_HELP": "Tronc può funzionare in due modalità: a punti o classica, che utilizza una tariffa oraria o uno stipendio. Questo è controllato da un'impostazione per la tua organizzazione o per il sito/sede specifico.",
          "ERROR_OTHER": "Non possiamo salvare la tariffa Tronc in questo momento. Per favore riprova.",
          "ERROR_UNAUTHORIZED": "Non sei autorizzato a modificare le tariffe Tronc di questa persona.",
          "ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE": "Questo dipendente non ha un appuntamento attivo alla data di validità prescelta.",
          "ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE": "Qualcuno ha già iniziato a redigere Tronc per la data di validità prescelta. Utilizza lo strumento Tronc per modificare le tariffe per questa data.",
          "ERROR_NOT_SUPPORTED": "Non puoi scegliere quel tipo di ripartizione. Tronc non è configurato per funzionare in quella modalità."
        }
      },
      "INDEX": {
        "OPTIONS_LABEL": "Opzioni",
        "OPTION_APPROVE_LINK": "Approva turni e buste paga",
        "OPTION_SEND_MAGIC_LINK": "Invita a Rotaready (invia un link magico)",
        "OPTION_SEND_MAGIC_LINK_TOOLTIP": "Questa persona ha già impostato il proprio account. Se hanno problemi di accesso, chiedi loro di seguire il link per il ripristino dell'account nella pagina di accesso.",
        "OPTION_ANONYMISE": "Anonimizzazione",
        "OPTION_DELETE": "Elimina",
        "ALERT_APPROVE_SUCCESS": "Ora sono approvati e pronti per ricevere turni.",
        "ALERT_APPROVE_ERROR": "Non siamo riusciti ad approvare l'account in quel momento. Per favore riprova.",
        "ALERT_MAGIC_LINK_SUCCESS_ALL": "Abbiamo inviato un'email e un messaggio di testo con un link magico. Attendi qualche minuto prima che arrivi.",
        "ALERT_MAGIC_LINK_SUCCESS_EMAIL": "Abbiamo inviato un'email con un link magico. Attendi qualche minuto prima che arrivi.",
        "ALERT_MAGIC_LINK_SUCCESS_SMS": "Abbiamo inviato un messaggio di testo con un link magico. Attendi qualche minuto prima che arrivi.",
        "ALERT_MAGIC_LINK_ERROR_GENERIC": "Non siamo riusciti a inviare un link magico in quel momento.",
        "ALERT_MAGIC_LINK_ERROR_NO_DETAILS": "Non possiamo inviare un link magico senza un indirizzo email o un numero di telefono.",
        "ALERT_ACCOUNT_ERROR_500": "Non siamo riusciti a caricare il dipendente in quel momento. Per favore riprova.",
        "ALERT_ACCOUNT_ERROR_404": "A quell'ID non è associato alcun account dipendente.",
        "DELETE_ACCOUNT_TITLE": "Elimina dipendente",
        "DELETE_ACCOUNT_MESSAGE": "Avvertenza! L'eliminazione di un dipendente è permanente. Fatelo solo se avete creato il dipendente per errore. Per continuare, digita il loro nome completo qui sotto.",
        "DELETE_ACCOUNT_BUTTON": "Elimina definitivamente",
        "DELETE_ACCOUNT_ERROR": "Il dipendente non può essere eliminato. Potresti non avere il permesso di farlo.",
        "DELETE_ACCOUNT_SUCCESS": "Il dipendente è stato eliminato."
      },
      "PERSONAL": {
        "ERROR_500": "Non possiamo elaborare la tua modifica in questo momento. Per favore riprova.",
        "ERROR_403": "Non sei autorizzato a visualizzare/modificare le informazioni personali di questa persona.",
        "SAVE_SUCCESS": "Le tue modifiche sono state salvate.",
        "EMERGENCY_CONTACTS_LINK_TEXT": "Contatti di emergenza",
        "FINANCIALS_LINK_TEXT": "Finanziari/imposte",
        "LABEL_OPTIONS": "Altre opzioni",
        "LABEL_APPROVED_BY": "Approvato da",
        "TITLE_LABEL": "Titolo",
        "FIRST_NAME_LABEL": "Nome",
        "LAST_NAME_LABEL": "Cognome",
        "OTHER_NAME_LABEL": "Altri nomi (opzionale)",
        "DOB_LABEL": "Data di nascita",
        "DOB_HELP": "{{age}} anni",
        "PREFERRED_NAME_LABEL": "Nome preferito",
        "SECTION_ADDRESS": "Indirizzo",
        "SECTION_VISAS": "Visti",
        "SECTION_IDENTITY": "Identità",
        "SECTION_CONTACT": "Contatto",
        "SECTION_OTHER": "Altro",
        "SECTION_RIGHT_TO_WORK": "Diritto al lavoro",
        "NATIONALITY_LABEL": "Nazionalità",
        "GENDER_LABEL": "Genere",
        "ETHNICITY_LABEL": "Etnia",
        "MARITAL_STATUS_LABEL": "Stato civile",
        "PASSPORT_NUMBER_LABEL": "Numero del passaporto",
        "PASSPORT_EXPIRY_LABEL": "Data di scadenza del passaporto",
        "SETTLED_STATUS_LABEL": "Status residente (Regno Unito)",
        "PRIMARY_EMAIL_LABEL": "Email principale",
        "PRIMARY_EMAIL_HELP": "Il dipendente lo utilizza per accedere al Rotaready. Può essere modificato solo se non hanno effettuato l'accesso prima.",
        "PRIMARY_PHONE_LABEL": "Telefono principale",
        "PRIMARY_PHONE_HELP": "Il dipendente lo utilizza per ricevere notifiche. Il prefisso internazionale corretto verrà applicato automaticamente dopo il salvataggio.",
        "SECONDARY_EMAIL_LABEL": "Email secondaria",
        "SECONDARY_PHONE_LABEL": "Telefono secondario",
        "HR_ID_LABEL": "IL SUO ID",
        "HR_ID_HELP": "Se questo dipendente ha un numero identificativo utilizzato per scopi relativi alle risorse umane, puoi memorizzarlo qui.",
        "PAYROLL_ID_LABEL": "ID del libro paga",
        "PAYROLL_ID_HELP": "Se questo dipendente ha un numero identificativo utilizzato ai fini del libro paga, puoi memorizzarlo qui.",
        "SAVE_BUTTON_LOADING": "Salvataggio...",
        "SAVE_BUTTON_TEXT": "Salva",
        "LINK_ADD_ADDRESS": "Aggiungi nuovo indirizzo",
        "LINK_VIEW_ADDRESS_HISTORY": "Vedi gli indirizzi precedenti",
        "NO_ADDRESS_HISTORY_TEXT": "Non ci sono indirizzi precedenti",
        "CURRENT_ADDRESS_LABEL": "Corrente",
        "SHARE_CODE_LABEL": "Codice di condivisione",
        "SHARE_CODE_ERROR_LENGTH": "Il codice di condivisione deve essere lungo 9 caratteri.",
        "SHARE_CODE_ERROR_FORMAT": "Il codice di condivisione deve contenere solo caratteri alfanumerici.",
        "SHARE_CODE_EXPIRY_DATE_LABEL": "Data di scadenza del codice di condivisione",
      },
      "ADDRESS_HISTORY": {
        "ALERT_ERROR_500": "Non possiamo elaborare la tua modifica in questo momento. Per favore riprova.",
        "TITLE_ADD": "Aggiungi nuovo indirizzo",
        "TITLE_EDIT": "Modifica indirizzo",
        "ADVICE_EDIT": "Se l'indirizzo del dipendente sta cambiando, è necessario aggiungere un nuovo indirizzo. Rotaready sposterà automaticamente l'indirizzo corrente nella cronologia degli indirizzi.",
        "ADVICE_EDIT_LINK": "Aggiungi nuovo indirizzo",
        "SAVE_BUTTON_TEXT": "Salva",
        "DELETE_BUTTON_TEXT": "Elimina",
        "ADDRESS_LINE_1_LABEL": "Linea 1",
        "ADDRESS_LINE_2_LABEL": "Linea 2 (opzionale)",
        "ADDRESS_CITY_LABEL": "Località/città",
        "ADDRESS_COUNTY_LABEL": "Contea",
        "ADDRESS_POST_CODE_LABEL": "Codice postale",
        "ADDRESS_COUNTRY_LABEL": "Paese",
        "NOTES_LABEL": "Note",
        "CONFIRM_DELETE": "Sei sicuro di voler eliminare questo indirizzo?"
      },
      "VISAS": {
        "ADD_BTN": "Aggiungi visto",
        "CURRENT_HEADING": "Visto attivo",
        "NO_CURRENT_VISA": "Questo utente non ha un visto attivo.",
        "CURRENT_LABEL": "Attivo",
        "VISA_TYPE_LABEL": "Tipo di visto",
        "VISA_NUMBER_LABEL": "Numero di visto",
        "VISA_EXPIRY_LABEL": "Data di scadenza del visto",
        "VISA_EXPIRY_NOT_APPLICABLE": "Non applicabile",
        "VISA_EDIT_BTN": "Modifica visto",
        "PREVIOUS_LABEL": "Precedente",
        "SHOW_PREVIOUS_BTN": "Mostra visti precedenti",
        "HIDE_PREVIOUS_BTN": "Nascondi visti precedenti",
        "PREVIOUS_HEADING": "Visto precedente",
        "MODAL": {
          "ADD_HEADING": "Aggiungi un nuovo visto",
          "ALERT_ERROR_500": "Non possiamo elaborare la tua modifica in questo momento. Per favore riprova.",
          "FORM_LABEL_VISA_TYPE": "Tipo di visto",
          "FORM_LABEL_VISA_NUMBER": "Numero di visto",
          "FORM_LABEL_IS_CURRENT": "Questo è il visto attivo dell'utente",
          "FORM_LABEL_HAS_EXPIRY_DATE": "Questo visto ha una data di scadenza",
          "FORM_LABEL_VISA_EXPIRY": "Data di scadenza del visto",
          "FORM_BTN_SAVE": "Salva",
          "EDIT_VISA_HEADING": "Modifica visto",
          "DELETE_VISA_BTN": "Elimina visto",
          "CONFIRM_DELETE": "Sei sicuro di voler eliminare questo visto?"
        }
      },
      "SCHEDULE": {
        "TITLE": "Il calendario seguente mostra il programma completo di {{firstName}}, inclusi turni, assenze e disponibilità.",
        "SHIFTS_LABEL": "Turni",
        "ABSENCE_LABEL": "Assenza",
        "UNAVAILABLE_LABEL": "Non disponibile (modelli)",
        "EDIT_LINK_TEXT": "Modifica le eccezioni"
      },
      "ADD_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Routine del cambio di mappa",
        "HAS_OVERLAP_TEXT": "Le date sopra indicate si sovrappongono a una routine di turni esistente.",
        "END_BEFORE_START_TEXT": "La data di inizio è precedente alla data di fine.",
        "SAVE_BUTTON_TEXT": "Salva",
        "SAVE_BUTTON_LOADING": "Salvataggio...",
        "CANCEL_BUTTON_TEXT": "Annulla"
      },
      "DELETE_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Ne sei sicuro?",
        "WARNING": "L'eliminazione di questa mappatura rimuoverà tutti i turni futuri ad essa associati.",
        "YES_BUTTON_TEXT": "sì",
        "YES_BUTTON_ADDITIONAL_TEXT": "Elimina questa mappatura",
        "NO_BUTTON_TEXT": "No",
        "NO_BUTTON_ADDITIONAL_TEXT": "Ho cambiato idea",
        "CANCEL_BUTTON_TEXT": "Annulla"
      },
      "EDIT_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Modifica la mappatura della routine dei turni",
        "END_DATE_LABEL": "Data di fine",
        "CONTINUE_FOREVER_LABEL": "Continua la routine dei turni per sempre",
        "END_BEFORE_START_TEXT": "La data di fine è precedente alla data di inizio.",
        "HAS_OVERLAP_NO_END": "L'impostazione di questa data di fine provocherebbe una sovrapposizione con una routine di turni esistente.",
        "HAS_OVERLAP_END": "L'impossibilità di impostare una data di fine provocherebbe una sovrapposizione con una routine di turni esistente.",
        "SAVE_BUTTON_TEXT": "Salva",
        "SAVE_BUTTON_LOADING_TEXT": "Salvataggio...",
        "CANCEL_BUTTON_TEXT": "Annulla"
      },
      "SHIFT_ROUTINES": {
        "MAP_ROUTINE_BUTTON_TEXT": "Mappa alla routine",
        "MAP_ROUTINE_SUBTITLE": "Mappature della routine dei turni",
        "MAP_ROUTINE_TIP": "Associare qualcuno a una routine di turni fa sì che i turni vengano creati automaticamente. Verranno eliminati se elimini la mappatura o se aggiungi una data di fine.",
        "EDIT_END_DATE_TEXT": "Modifica data di fine",
        "ADD_END_DATE_TEXT": "Aggiungi data di fine",
        "MAPPING_NO_END": "A partire da ",
        "MAPPING_END": " a ",
        "USER_HAS_NO_MAPPING": "{{firstName}} non è associato a nessuna routine di turni. Puoi aggiungerne uno usando il pulsante qui sopra."
      },
      "ABSENCE": {
        "INDEX": {
          "TAB_ABSENCE": "Prenotazioni di assenze",
          "TAB_ALLOWANCES": "Indennità",
          "TAB_SETTINGS": "Impostazioni"
        },
        "VIEW_SETTINGS": {
          "ABSENCE_HOURS_LABEL": "Ore medie al giorno",
          "AVERAGE_LENGTH_LABEL": "Calcola automaticamente in base alla durata media della giornata lavorativa",
          "SET_MANUALLY_LABEL": "Impostare manualmente",
          "HOURS_FULL_DAY_LABEL": "Giornata intera",
          "HOURS_LABEL": "ore",
          "HOURS_HELP": "Un'intera giornata di assenza varrà così tante ore.",
          "HOURS_HALF_DAY_LABEL": "Mezza giornata",
          "HOURS_HALF_DAY_HELP": "Una mezza giornata di assenza varrà tutte queste ore.",
          "DELEGATE_APPROVERS": "Approvatori delegati",
          "DELEGATE_APPROVERS_REMOVE": "Rimuovi",
          "DELEGATE_APPROVERS_HELP": "Se questo dipendente abilita la funzione Non disturbare nell'app Rotaready e viene sollevata una richiesta di assenza che richiede la sua approvazione, i delegati approvatori riceveranno invece una notifica.",
          "SAVE_BUTTON_TEXT": "Salva",
          "SAVE_BUTTON_LOADING_TEXT": "Salvataggio...",
          "PRE_ROTAREADY_AVERAGE_HOURS_LABEL": "Ore medie giornaliere pre-Rotaready",
          "PRE_ROTAREADY_AVERAGE_HOURS_HELP": "Se sei passato al Rotaready da un altro sistema durante il rapporto di lavoro di questa persona, inserisci l'ultima «media di ore giornaliere» nota e la data in cui era valida.",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_LABEL": "Ultimo valido il",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_HELP": "Nel calcolare un nuovo valore per le ore medie giornaliere, se il dipendente non ha lavorato tutte le ultime 52 settimane su Rotaready, il nostro calcolo utilizzerà il valore Pre-Rotaready per le settimane precedenti a questa data."
        },
        "VIEW_BOOKINGS": {
          "INCLUDE_CANCELLED_TOGGLE_LABEL": "Includi annullato",
          "NO_BOOKINGS_MATCH_TEXT": "Non siamo riusciti a trovare nessuna prenotazione corrispondente ai tuoi filtri.",
          "BOOK_ABSENCE_BUTTON_TEXT": "Assenza del libro",
          "CANCEL_LINK": "Annulla",
          "DATES_COLUMN_TEXT": "Data/e",
          "ABSENCE_TYPE_COLUMN_TEXT": "Tipo",
          "HOURS_COLUMN_TEXT": "Ore",
          "DAYS_COLUMN_TEXT": "Giorni",
          "STATUS_COLUMN_TEXT": "Status",
          "SUBMITTED_COLUMN_TEXT": "Inviato",
          "APPROVED_COLUMN_TEXT": "Recensito",
          "CANCELLED_COLUMN_TEXT": "Annullato",
          "FILTER_LABEL_APPROVAL_STATE": "Status",
          "FILTER_LABEL_ABSENCE_TYPE": "Tipo",
          "FILTER_LABEL_DATE": "Data",
          "CANCEL_REQUEST": {
            "CONFIRM": "Sei sicuro di voler cancellare questa prenotazione di assenza? Questo non può essere annullato.",
            "SUCCESS": "La prenotazione dell'assenza è stata annullata.",
            "ERROR_500": "Non potevamo cancellarlo adesso. Per favore riprova."
          }
        },
        "VIEW_ALLOWANCES": {
          "ERROR_500": "Ops, abbiamo avuto un intoppo proprio in quel momento. Per favore riprova.",
          "BUTTON_ADD": "Nuova indennità",
          "BUTTON_OPTIONS": "Opzioni",
          "BUTTON_TRANSACTIONS": "Visualizza le transazioni",
          "BUTTON_EDIT": "Modifica",
          "BUTTON_DELETE": "Elimina",
          "NO_ALLOWANCES_TEXT": "Non ci sono quote corrispondenti ai tuoi filtri.",
          "FILTER_LABEL_STATUS": "Status",
          "FILTER_LABEL_ABSENCE_TYPE": "Tipo",
          "CHRONO_TYPE_ACTIVE": "Attivo",
          "CHRONO_TYPE_FUTURE": "Futuro",
          "CHRONO_TYPE_HISTORICAL": "Storico",
          "LABEL_NOT_DEDUCTIBLE": "Non deducibile",
          "METRIC_ACCRUED": "Diritto",
          "METRIC_BOOKED": "Prenotato",
          "METRIC_REMAINING": "Restante",
          "METRIC_ACCRUED_LIMIT": "di {{count}}",
          "METRIC_ACCRUED_LIMIT_HELP": "Questo è il massimo che può essere accumulato.",
          "METRIC_ELAPSED": "trascorso",
          "METRIC_ELAPSED_HELP": "Questo è il numero di giorni/ore prenotati che sono passati. Questo viene ricalcolato automaticamente durante la notte.",
          "METRIC_OVERSPEND": "spendere troppo",
          "METRIC_OVERSPEND_HELP": "Questo è il numero di giorni/ore che possono essere prenotati oltre l'importo maturato.",
          "METRIC_ACCRUAL_ESTIMATE": "maturare",
          "METRIC_ACCRUAL_ESTIMATE_HELP": "In base all'orario di lavoro medio, stimiamo che lo accumulerai entro la fine dell'anno di indennità.",
          "SCHEDULE_BEGINNING_OF_ACCRUAL_YEAR": "Maturazione all'inizio di ogni anno di competenza",
          "SCHEDULE_EACH_CALENDAR_MONTH": "Accumula ogni mese di calendario",
          "SCHEDULE_EACH_HOUR_WORKED": "Maturati per ogni ora lavorata",
          "SCHEDULE_EACH_HOUR_WORKED_RATE": "Accumula per ogni ora lavorata al {{rate}}%",
          "CARRIED_OVER": "{{amount}} riportato dall'anno scorso",
          "LINK_TRANSACTIONS": "Visualizza le transazioni",
          "ALERT_CONFIRM_DELETE": "Sei sicuro di voler eliminare questa indennità? Questo non può essere annullato.",
          "ALERT_SUCCESS_DELETE": "L'indennità è stata eliminata.",
          "FINALISED": "🔒 Finalizzato ({{date}})",
          "FINALISE": "Finalizzare",
          "CONVERT_HOURS_TO_DAYS_TOGGLE_LABEL": "Converti ore in giorni"
        },
        "ALLOWANCE_VALIDATION_MODAL": {
          "TITLE": "C'è qualcosa che non va",
          "DESCRIPTION": "Sfortunatamente ciò che stai tentando di fare causa uno o più problemi.",
          "BUTTON_CLOSE": "Chiudi",
          "INVALID_ALLOWANCE_TITLE": "Indennità non valida",
          "INVALID_ALLOWANCE_DESCRIPTION": "Da allora l'indennità è stata eliminata e non può più essere modificata.",
          "OVERLAPPING_ALLOWANCE_TITLE": "Indennità cumulative",
          "OVERLAPPING_ALLOWANCE_DESCRIPTION": "Questa indennità si sovrapporrà a un'indennità esistente. Non puoi avere indennità sovrapposte per lo stesso tipo di assenza.",
          "FINALISED_ALLOWANCE_TITLE": "Indennità definitiva",
          "FINALISED_ALLOWANCE_DESCRIPTION": "Questa indennità è stata completata. Per modificarlo o eliminarlo, devi prima ripristinare l'indennità.",
          "INVALID_UNIT_TITLE": "Unità non valida",
          "INVALID_UNIT_DESCRIPTION": "Un'indennità con il programma di maturazione impostato su Ogni ora lavorata deve avere l'unità impostata su Ore.",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_TITLE": "Data di validità non valida",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "La data di validità scelta deve essere compresa tra l'inizio e la fine dell'anno di indennità.",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_TITLE": "Data di riferimento non valida",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "La data di riferimento deve essere compresa tra la data di efficacia dell'indennità e la fine dell'anno dell'indennità."
        },
        "ALLOWANCE_FORM": {
          "ERROR_500": "Ops, abbiamo avuto un intoppo proprio in quel momento. Per favore riprova.",
          "ALERT_SUCCESS_ADD": "L'indennità è stata aggiunta.",
          "ALERT_SUCCESS_UPDATE": "L'indennità è stata aggiornata.",
          "ALERT_ERROR_CALCULATE_BOOKED": "Non siamo riusciti a calcolare il valore della loro prenotazione in questo momento. Per favore riprova.",
          "BUTTON_SAVE": "Salva",
          "BUTTON_SAVE_LOADING": "Salvataggio...",
          "TITLE": "Indennità",
          "LABEL_YEAR_STARTS": "L'anno inizia",
          "YEAR_STARTS_HELP": "L'anno festivo predefinito della tua organizzazione inizia da {{settingDate}}.",
          "YEAR_STARTS_CUSTOMISE_INITIATE": "Scegli un giorno o un mese diverso.",
          "YEAR_STARTS_CUSTOMISE_CANCEL": "Torna al giorno e al mese predefiniti.",
          "LABEL_EFFECTIVE_DATE": "Data di entrata in vigore",
          "EFFECTIVE_DATE_HELP": "Se questa indennità sostituisce un'indennità esistente nello stesso anno, è necessario scegliere una data a partire dalla quale questa sarà effettiva.",
          "ALERT_MISALIGNED_YEAR_START": "Questo non è in linea con l'anno festivo predefinito della tua organizzazione.",
          "ALERT_MISALIGNED_EFFECTIVE_DATE": "La data di validità non è compresa nell'anno dell'indennità.",
          "ALERT_ALTERED_EFFECTIVE_DATE": "La data di validità non corrisponde alla data di inizio dell'anno di indennità. Dovresti farlo solo se stai sostituendo un'indennità esistente nello stesso anno.",
          "LABEL_ABSENCE_TYPE": "Tipo di assenza",
          "LABEL_SCHEDULE_OF_ACCRUAL": "Calendario degli accantonamenti",
          "TOOLTIP_SCHEDULE_OF_ACCRUAL": "Per assegnare ore o giorni manualmente, scegli «inizio dell'anno di maturazione». Per fare in modo che Rotaready assegni ore o giorni ogni mese di calendario, scegli «ogni mese di calendario». Per accumulare automaticamente in base alle ore lavorate, scegli «ogni ora lavorata».",
          "LABEL_UNIT": "Unità",
          "LABEL_DEDUCTIBLE": "Deducibile",
          "TOOLTIP_DEDUCTIBLE": "Controlla se il dipendente può presentare richieste di assenza che vengono detratte da questa indennità.",
          "DEDUCTIBLE_YES": "sì",
          "DEDUCTIBLE_NO": "No",
          "LABEL_PROJECT": "Previsione maturata al momento della prenotazione",
          "TOOLTIP_PROJECT": "Quando si effettua una richiesta di assenza, è necessario prevedere le ore probabilmente maturate e includerle nella cifra maturata dal dipendente.",
          "PROJECT_YES": "sì",
          "PROJECT_NO": "No",
          "LABEL_TOTAL_ACCRUED_ANNUALLY": "Totale maturato annualmente",
          "LABEL_TOTAL_ACCRUED": "Totale maturato",
          "TOOLTIP_TOTAL_ACCRUED": "Inserisci il numero di ore o giorni che questo dipendente ha maturato finora. Rotaready lo aggiornerà automaticamente in futuro.",
          "LABEL_BOOKED": "Prenotato",
          "TOOLTIP_BOOKED": "Inserisci il numero di ore o giorni che questo dipendente ha prenotato. Rotaready lo manterrà automaticamente.",
          "ALERT_BOOKED": "L'abbiamo calcolato per te in base all'assenza prenotata in Rotaready. Modificare questa impostazione solo se l'assenza è stata prenotata al di fuori del Rotaready.",
          "TOOLTIP_BOOKED_BUTTON": "Calcola automaticamente",
          "LABEL_TOTAL_TO_ACCRUE": "Massimo da maturare",
          "TOOLTIP_TOTAL_TO_ACCRUE_HOURLY": "Inserire il numero totale di ore o giorni che il dipendente ha diritto a maturare. Lascia vuoto per non applicare un limite.",
          "TOOLTIP_TOTAL_TO_ACCRUE_MONTHLY": "Inserisci il numero totale di ore o giorni che questo dipendente deve maturare per l'anno.",
          "LABEL_ACCRUAL_RATE": "Sostituzione del tasso di competenza",
          "TOOLTIP_ACCRUAL_RATE": "Facoltativamente, specifica un tasso di competenza se desideri sostituire il tasso predefinito della giurisdizione in cui lavora il dipendente.",
          "LABEL_OVERSPEND_LIMIT": "Spendere troppo",
          "TOOLTIP_OVERSPEND_LIMIT": "Facoltativamente, consenti al dipendente di prenotare più ore o giorni di quelli accumulati.",
          "LABEL_CARRIED_OVER": "Recuperato dall'anno scorso",
          "BUTTON_PRO_RATA": "Pro-rata",
          "LABEL_EMPLOYMENT_START": "Data di inizio del rapporto di lavoro",
          "LABEL_PRO_RATA_TOTAL": "Totale maturato se impiegato tutto l'anno",
          "HELP_TEXT_PRO_RATA": "Imposteremo il totale maturato su {{value}}.",
          "CREATE_NEXT_YEAR_CHECKBOX": "Crea un'altra indennità per il prossimo anno utilizzando il totale maturato se impiegato tutto l'anno.",
          "ALERT_SUCCESS_CALCULATE_TOTAL_ACCRUED": "L'abbiamo calcolato per te utilizzando le ore di licenziamento di questo dipendente.",
          "ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED": "Non sei autorizzato ad accedere agli orari di disdetta per questo dipendente. Senza questo, non possiamo calcolare il totale maturato per te.",
          "ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED": "Non è stato possibile calcolare automaticamente il totale delle ore maturate. Per favore riprova.",
          "TOOLTIP_CALCULATE_TOTAL_ACCRUED": "Calcola automaticamente"
        },
        "ALLOWANCE_TRANSACTIONS": {
          "TITLE": "Transazioni",
          "DESCRIPTION": "Ecco tutte le transazioni che riguardano l'indennità, mostrando per prima la più recente:",
          "TYPE_ACCRUAL_HOURS_WORKED": "Maturazione delle ore lavorate",
          "TYPE_ABSENCE_REQUESTED": "Assenza richiesta",
          "TYPE_ABSENCE_REJECTED_OR_CANCELLED": "Assenza rifiutata o annullata",
          "TYPE_MANUAL_ADJUSTMENT": "Regolazione manuale",
          "TYPE_ACCRUAL_SCHEDULE_CHANGED": "Il calendario degli accantonamenti è cambiato",
          "TYPE_CARRY_OVER": "Riportato dall'anno precedente",
          "TYPE_ACCRUAL_TIME_BASED": "Accantonamento basato sul tempo",
          "TYPE_ABSENCE_UPDATED": "Le date di assenza sono cambiate",
          "TYPE_HOURS_PER_DAY_RECALCULATION": "Ore giornaliere aggiornate",
          "ERROR_500": "Ops, abbiamo avuto un intoppo proprio in quel momento. Per favore riprova.",
          "NO_TRANSACTIONS": "Non ci sono transazioni da mostrare.",
          "BUTTON_CLOSE": "Chiudi",
          "TRANSACTION_TYPE": "Filtra per tipo"
        },
        "VIEW_FINALISATION": {
          "TITLE": "Indennità definitiva",
          "BUTTON_CLOSE": "Chiudi",
          "BUTTON_REVERT": "Ripristina",
          "CONFIRM_REVERT": "Sei sicuro di voler ripristinare questa indennità? Ciò eliminerà anche il record salariale corrispondente, se presente.",
          "LABEL_REFERENCE_DATE": "Data di riferimento",
          "LABEL_PAY_RECORD": "Pagamento/detrazione",
          "PAY_SEE_MORE": "Visualizza il record salariale",
          "PAY_NONE": "Nessuna retribuzione è dovuta al dipendente, né deve essere detratta alcuna retribuzione.",
          "LABEL_NOTES": "Note",
          "NOTES_NONE": "Non sono state registrate note.",
          "ERROR_500": "Non siamo riusciti a salvare le tue modifiche in quel momento. Per favore riprova.",
          "ERROR_403": "Non sei autorizzato a modificare i registri salariali di questa persona, quindi non possiamo ripristinare l'indennità.",
          "ALERT_SUCCESS": "L'indennità è stata ripristinata."
        },
        "FINALISE_ALLOWANCE_FORM": {
          "TITLE": "Finalizza l'indennità",
          "BUTTON_SAVE": "Finalizzare",
          "ERROR_500": "Non siamo riusciti a salvare le tue modifiche in quel momento. Per favore riprova.",
          "ERROR_403": "Non sei autorizzato a modificare i registri salariali di questa persona, quindi non possiamo finalizzare l'indennità.",
          "ALERT_SUCCESS": "L'indennità è stata finalizzata.",
          "GUIDANCE": "Ti consigliamo di finalizzare un'indennità quando sei sicuro che non verranno accumulate, prenotate o cancellate altre assenze. Di solito è il più vicino possibile all'ultimo giorno di qualcuno.",
          "GUIDANCE_FUTURE_YEAR": "È necessario farlo solo se il dipendente parte in questo anno di indennità.",
          "LABEL_EFFECTIVE_DATE": "Data di riferimento",
          "EFFECTIVE_DATE_LEAVER_HELP": "L'indennità scadrà in questa data. Dato che questa persona se ne va, lo abbiamo fissato al suo ultimo giorno.",
          "EFFECTIVE_DATE_NOT_LEAVER_HELP": "L'indennità scadrà in questa data. La data scelta verrà utilizzata di seguito per calcolare il totale delle ore o dei giorni maturati.",
          "LABEL_BASIC_PAY": "Retribuzione base",
          "ALERT_BASIC_PAY_NOT_PERMITTED": "Non sei autorizzato a visualizzare i registri salariali di questo dipendente, quindi non puoi utilizzare questa funzione per creare un pagamento o una detrazione una tantum.",
          "ALERT_BASIC_PAY_NOT_AVAILABLE": "Il dipendente non dispone di un record salariale di base alla data di riferimento prescelta.",
          "HELP_TEXT_BASIC_PAY": "Si tratta della retribuzione base del dipendente alla data di riferimento prescelta.",
          "LABEL_TOTAL_ACCRUED_LIVE": "Totale maturato",
          "HELP_TEXT_TOTAL_ACCRUED_LIVE": "Questo è il totale delle ore maturate per questa indennità, in base a tutti i giorni che sono stati sottoscritti.",
          "LABEL_ACCRUED_PRO_RATED": "Accumulato (pro rata)",
          "HELP_TEXT_TOTAL_ACCRUED_PRO_RATED": "Utilizzando la data di riferimento scelta, il totale delle ore/giorni maturati è stato ripartito proporzionalmente.",
          "LABEL_BOOKED": "Totale prenotato",
          "HELP_TEXT_BOOKED": "Si tratta dell'assenza totale registrata fino ad oggi, comprese le richieste in sospeso e approvate.",
          "LABEL_ACCRUED_UNTAKEN": "Maturato ma non utilizzato",
          "STATUS_PAYMENT_DUE": "Ciò significa che il dipendente ha subito meno assenze di quelle accumulate. Molti datori di lavoro scelgono di pagare il dipendente per questo.",
          "STATUS_DEDUCTION_DUE": "Ciò significa che il dipendente ha subito più assenze di quante ne abbiano accumulate. Alcuni datori di lavoro scelgono di detrarlo dalla busta paga finale del dipendente.",
          "LABEL_AVERAGE_HOURS": "Ore medie al giorno",
          "LABEL_ONE_OFF_PAYMENT": "Pagamento/detrazione una tantum",
          "HELP_TEXT_ONE_OFF_PAYMENT": "Se inserisci un importo, il Rotaready creerà un record salariale una tantum e lo legherà a questa indennità definitiva. Per una detrazione, inserisci un importo negativo.",
          "HELP_TEXT_ONE_OFF_PAYMENT_LINK": "Aiuto su come calcolarlo",
          "LABEL_PAY_ELEMENT": "Elemento di pagamento",
          "LABEL_PAYMENT_DATE": "Data applicabile",
          "HELP_TEXT_PAYMENT_DATE": "Scegli attentamente per assicurarti che questo pagamento sia incluso nella retribuzione finale del dipendente.",
          "LABEL_NOTES": "Note",
          "PLACEHOLDER_NOTES": "Se non inserisci alcuna nota, includeremo automaticamente le cifre del calcolo precedente."
        },
        "FINALISE_ALLOWANCE_PROMPT": {
          "TITLE": "Tutte le indennità sono finalizzate?",
          "DESCRIPTION": "Se sei soddisfatto che tutte le indennità siano ora finalizzate, dovresti confermarlo, in modo che reparti come il libro paga possano fare affidamento sui dati.",
          "DESCRIPTION_PART_2": "Puoi farlo anche nella scheda Occupazione in un secondo momento.",
          "BUTTON_ATTEST": "Confermare",
          "BUTTON_LATER": "Ho altro da fare",
          "ALERT_ERROR": "Non siamo riusciti a salvare la tua conferma in quel momento. Prova invece a farlo nella scheda Occupazione."
        }
      },
      "EMERGENCY_CONTACTS": {
        "ADD_MODAL": {
          "TITLE": "Nuovo contatto di emergenza",
          "RELATIONSHIP_LABEL": "Rapporto con il dipendente",
          "FIRST_NAME_LABEL": "Nome",
          "LAST_NAME_LABEL": "Cognome",
          "OTHER_NAME_LABEL": "Altro (i) nome (i)",
          "PRIMARY_CONTACT_NUMBER_LABEL": "Numero di contatto principale",
          "SECONDARY_CONTACT_NUMBER_LABEL": "Numero di contatto secondario",
          "ADDRESS_LABEL": "Indirizzo",
          "SAVE_BUTTON_TEXT": "Salva",
          "CLOSE_BUTTON_TEXT": "Chiudi",
          "ALERT_ERROR_500": "Non siamo riusciti a salvare le tue modifiche in quel momento. Per favore riprova."
        },
        "INDEX": {
          "TITLE": "Contatti di emergenza",
          "RELATIONSHIP_COLUMN_TEXT": "Relazione",
          "NAME_COLUMN_TEXT": "Nome",
          "PHONE_COLUMN_TEXT": "Numero/i di telefono",
          "ADDRESS_COLUMN_TEXT": "Indirizzo",
          "NONE_TO_SHOW": "Non ci sono contatti di emergenza da mostrare.",
          "ADD_BUTTON_TEXT": "Nuovo contatto",
          "OPTIONS_COLUMN_TEXT": "Opzioni",
          "DELETE_LINK": "Elimina",
          "ALERT_ERROR_500": "Non siamo riusciti a caricare i contatti di emergenza in quel momento. Per favore riprova.",
          "CONFIRM_DELETE": "Sei sicuro di voler eliminare questo contatto di emergenza?\nQuesto non può essere annullato."
        }
      },
      "EMPLOYMENT": {
        "LABEL_LENGTH_OF_SERVICE": "Durata del servizio",
        "LABEL_STARTS_IN": "Inizia nel",
        "LABEL_OPTIONS": "Opzioni",
        "OPTION_NEW_EMPLOYMENT": "Nuova occupazione",
        "OPTION_END_EMPLOYMENT": "Fine dell'occupazione",
        "OPTION_REINSTATE_EMPLOYMENT": "Ripristinare l'occupazione",
        "OPTION_DELETE_EMPLOYMENT": "Eliminare un impiego",
        "TEXT_TERMINATED": "Questo rapporto di lavoro è stato interrotto, terminando il",
        "LABEL_REASON": "Motivo",
        "LABEL_REHIRE": "Riassumere",
        "LABEL_REHIRE_YES": "sì",
        "LABEL_REHIRE_NO": "No",
        "BUTTON_ATTEST": "Confermare",
        "TEXT_ALLOWANCES_NOT_FINALISED": "Non hai ancora confermato che le indennità di assenza del dipendente sono state finalizzate.",
        "TEXT_ALLOWANCES_NOT_FINALISED_PART_2": "Questo viene in genere fatto entro o prima dell'ultimo giorno del dipendente, prima della sua ultima retribuzione.",
        "TEXT_ALLOWANCES_NOT_FINALISED_LINK": "Visualizza le indennità",
        "BUTTON_UNDO": "Annulla",
        "TEXT_ALLOWANCES_FINALISED": "Le indennità di assenza sono state confermate come finalizzate per questo impiego.",
        "LABEL_JOIN_DATE": "Data di iscrizione",
        "LABEL_NOTICE_PERIOD": "Periodo di avviso",
        "BUTTON_SET_NOTICE_PERIOD": "Imposta un periodo di preavviso...",
        "LABEL_EMPLOYEE_NUMBER": "Numero del dipendente",
        "TOOLTIP_EMPLOYEE_NUMBER": "Facoltativo. Nel Regno Unito, l'HMRC richiede un numero univoco per ogni dipendente. Lo invieremo al tuo fornitore di servizi paghe se è abilitata l'integrazione delle retribuzioni.",
        "LABEL_RECRUITMENT_SOURCE": "Fonte di reclutamento",
        "LABEL_STARTER_DECLARATION": "Dichiarazione di avviamento (Regno Unito)",
        "BUTTON_SAVE": "Salva",
        "BUTTON_SAVE_LOADING": "Salvataggio...",
        "BUTTON_NEW_APPOINTMENT": "Nuovo appuntamento",
        "TITLE_APPOINTMENTS": "Appuntamenti",
        "COLUMN_EFFECTIVE_DATE": "Data di entrata in vigore",
        "COLUMN_SITE": "Sito",
        "COLUMN_DEPARTMENT": "Dipartimento",
        "COLUMN_POSITION": "Ruolo lavorativo",
        "COLUMN_LINE_MANAGER": "Responsabile di linea",
        "COLUMN_STAFF_GROUP": "Gruppo di personale",
        "COLUMN_OBLIGATION": "Impegno",
        "TOOLTIP_ACTIVE_APPOINTMENT": "Questo è l'appuntamento attivo.",
        "LINE_MANAGER_NOT_SET": "Non impostato",
        "MIN_OBLIGATION_ZERO_HOURS": "Zero ore",
        "ERROR_500": "Non possiamo caricare gli impieghi in questo momento. Per favore riprova.",
        "ERROR_403": "Non sei autorizzato a visualizzare/modificare i registri di lavoro di questa persona.",
        "LABEL_HOURS": "ore",
        "LABEL_DAYS": "giorni",
        "LABEL_WEEK": "settimana",
        "LABEL_MONTH": "mese",
        "LABEL_CURRENT_EMPLOYMENT": "Impiego attuale",
        "ALERT_CHANGES_SAVED": "Le tue modifiche sono state salvate.",
        "CONFIRM_DELETE": "Sei sicuro di volerlo fare? L'eliminazione di un impiego è irreversibile.",
        "CONFIRM_REINSTATE": "Sei sicuro di volerlo fare?\n\nSe qualcuno sta rientrando nella tua organizzazione, devi invece creare un nuovo impiego. Dovresti ripristinare un rapporto di lavoro solo se è stato interrotto erroneamente.",
        "VALIDATION_MODAL": {
          "TITLE": "Non possiamo apportare questo cambiamento",
          "DESCRIPTION": "Sfortunatamente ciò che stai tentando di fare causa uno o più problemi.",
          "NO_EMPLOYMENT_TITLE": "Nessun impiego",
          "NO_EMPLOYMENT_DESCRIPTION": "La modifica lascerebbe il dipendente senza un impiego; deve essercene sempre almeno uno.",
          "EMPLOYMENT_OVERLAPPING_TITLE": "Occupazioni che si sovrappongono",
          "EMPLOYMENT_OVERLAPPING_DESCRIPTION": "La tua modifica comporterebbe la sovrapposizione di due impieghi:",
          "EMPLOYMENT_NOT_TERMINATED_TITLE": "Rapporto di lavoro non terminato",
          "EMPLOYMENT_NOT_TERMINATED_DESCRIPTION": "È necessario terminare il precedente impiego prima di aggiungerne uno nuovo.",
          "EMPLOYMENT_NO_APPOINTMENTS_TITLE": "Impiego senza appuntamenti",
          "EMPLOYMENT_NO_APPOINTMENTS_DESCRIPTION": "Un impiego non può essere vuoto, deve contenere almeno un appuntamento.",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_TITLE": "Nomina fuori dal lavoro",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_DESCRIPTION": "La tua modifica farebbe sì che parte di questo appuntamento non rientri nel rapporto di lavoro.",
          "APPOINTMENT_OVERLAPPING_TITLE": "Appuntamenti che si sovrappongono",
          "APPOINTMENT_OVERLAPPING_TITLE_DESCRIPTION": "La modifica comporterebbe la sovrapposizione di due appuntamenti:",
          "APPOINTMENT_NO_EMPLOYMENT_TITLE": "Appuntamento senza impiego",
          "APPOINTMENT_NO_EMPLOYMENT_DESCRIPTION": "Un appuntamento non è associato a un impiego. Normalmente non dovrebbe succedere!",
          "SHIFTS_OUTSIDE_EMPLOYMENT_TITLE": "Turni al di fuori dell'occupazione",
          "SHIFTS_OUTSIDE_EMPLOYMENT_DESCRIPTION": "La modifica lascerebbe i {{count}} turni in date che precedono l'inizio o dopo la fine del rapporto di lavoro. Per procedere, questi turni possono essere eliminati, non assegnati (il che li sposta in Open Shifts) o modificati sulla rotazione stessa:",
          "SHIFT_ENTITY_MISMATCH_TITLE": "Turni in diversi siti/reparti",
          "SHIFT_ENTITY_MISMATCH_DESCRIPTION": "La modifica lascerebbe i {{count}} turni originati da turni di un reparto che non corrispondono al reparto all'appuntamento del dipendente. Per procedere, questi turni possono essere eliminati, non assegnati (il che li sposta in Open Shifts) o modificati sulla rotazione stessa:",
          "ABSENCE_OUTSIDE_EMPLOYMENT_TITLE": "Assenza al di fuori del lavoro",
          "ABSENCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "La tua modifica lascerebbe le {{count}} prenotazioni di assenza in date precedenti all'inizio o dopo la fine del rapporto di lavoro. Per procedere, devono essere cancellati:",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_TITLE": "Frequenza al di fuori del rapporto di lavoro",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "La tua modifica lascerebbe gli {{count}} eventi di partecipazione in date che precedono l'inizio o dopo la fine del rapporto di lavoro. Per procedere, devono essere cancellati:",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_TITLE": "Mappatura della routine dei turni al di fuori dell'occupazione",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_DESCRIPTION": "La modifica lascerebbe le {{count}} mappature della routine dei turni nelle date che precedono l'inizio o dopo la fine del rapporto di lavoro. Per procedere, devono essere terminati o eliminati:",
          "EMAIL_EXISTS_TITLE": "L'indirizzo email esiste già",
          "EMAIL_EXISTS_DESCRIPTION": "L'indirizzo email che hai inserito è già utilizzato da un altro dipendente. Se questa persona ha già lavorato per la tua organizzazione, cercala e aggiungi un nuovo impiego.",
          "MOBILE_EXISTS_TITLE": "Il numero di cellulare esiste già",
          "MOBILE_EXISTS_DESCRIPTION": "Il numero di cellulare inserito è già utilizzato da un altro dipendente. Se questa persona ha già lavorato per la tua organizzazione, cercala e aggiungi un nuovo impiego.",
          "INVALID_DATE_TITLE": "Data non valida",
          "INVALID_DATE_DESCRIPTION": "Se stai tentando di interrompere un rapporto di lavoro, la data di fine che hai scelto è precedente all'inizio del rapporto di lavoro.",
          "PERIOD_SIGNED_OFF_TITLE": "Data di disdetta",
          "PERIOD_SIGNED_OFF_DESCRIPTION": "La modifica si sovrappone a una o più date che sono state firmate.",
          "PAY_ELEMENT_INVALID_TITLE": "Elemento di pagamento non valido",
          "PAY_ELEMENT_INVALID_DESCRIPTION": "L'elemento di pagamento scelto non è valido.",
          "PAY_RECORD_SAME_DATE_TITLE": "Record salariale duplicato",
          "PAY_RECORD_SAME_DATE_DESCRIPTION": "Esiste già un record di retribuzione per l'elemento di pagamento scelto in quella data.",
          "RECORD_MISSING_TITLE": "Record di pagamento eliminato",
          "RECORD_MISSING_DESCRIPTION": "Questo record di retribuzione è stato eliminato e non può più essere modificato.",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_TITLE": "Mancata corrispondenza del calendario delle retribuzioni",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_DESCRIPTION": "La modifica lascerebbe le {{count}} prenotazioni di assenza associate a un calendario delle retribuzioni diverso. Per procedere, devono essere cancellati:",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_TITLE": "Collegato all'indennità definitiva",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_DESCRIPTION": "Questo record salariale è collegato a un'indennità definitiva e non può essere modificato. Per eliminarlo, trova l'indennità e ripristinala.",
          "BUTTON_TRY_AGAIN": "Riprova",
          "BUTTON_CLOSE": "Chiudi"
        },
        "LEAVER_FORM": {
          "TITLE": "Fine dell'occupazione",
          "ALERT_SUCCESS": "Il rapporto di lavoro è terminato.",
          "ERROR_500": "Non siamo riusciti a salvare le tue modifiche in quel momento. Per favore riprova.",
          "LABEL_END_DATE": "Ultimo giorno di lavoro",
          "LABEL_REASON": "Motivo della partenza",
          "LABEL_REHIRE": "Riassumere",
          "CHECKBOX_REHIRE": "Questo dipendente è considerato idoneo per una nuova assunzione in futuro.",
          "LABEL_NOTES": "Note",
          "PLACEHOLDER_NOTES": "Facoltativamente, registra alcune note associate alla partenza di questo dipendente.",
          "BUTTON_SAVE": "Salva",
          "BUTTON_SAVE_LOADING": "Salvataggio..."
        },
        "EMPLOYMENT_FORM": {
          "TITLE": "Nuova occupazione",
          "RESET_WARNING": "L'account del dipendente verrà contrassegnato come non approvato e vari attributi verranno ripristinati, come la media delle ore lavorate al giorno e alla settimana.",
          "LABEL_START_DATE": "Data di inizio del rapporto di lavoro",
          "LABEL_NOTICE_PERIOD": "Periodo di avviso",
          "LABEL_EMPLOYEE_NUMBER": "Numero del dipendente",
          "TOOLTIP_EMPLOYEE_NUMBER": "Facoltativo. Nel Regno Unito, l'HMRC richiede un numero univoco per ogni dipendente. Lo invieremo al tuo fornitore di servizi paghe se è abilitata l'integrazione delle retribuzioni.",
          "LABEL_RECRUITMENT_SOURCE": "Fonte di reclutamento",
          "LABEL_STARTER_DECLARATION": "Dichiarazione di avviamento (Regno Unito)",
          "LABEL_INVITE": "Invito a Rotaready",
          "CHECKBOX_INVITE": "Invia un link magico a questo dipendente, che lo invita a impostare la password e ad accedere per vedere i suoi turni.",
          "BUTTON_ADD": "Inserisci",
          "BUTTON_ADD_LOADING": "Aggiungendo...",
          "ALERT_SUCCESS": "L'occupazione è stata creata.",
          "ERROR_500": "Non siamo riusciti a salvare le tue modifiche in quel momento. Per favore riprova."
        },
        "APPOINTMENT_FORM": {
          "TITLE": "Appuntamento",
          "ALERT_MAPPINGS": "Completa le mappature di flusso predefinite o controlla la dichiarazione.",
          "ALERT_SAVED": "L'appuntamento è stato salvato.",
          "ALERT_DELETED": "L'appuntamento è stato eliminato.",
          "ERROR_500": "Non siamo riusciti a salvare le tue modifiche in quel momento. Per favore riprova.",
          "INFO_NEW_APPOINTMENT": "Abbiamo utilizzato l'appuntamento più recente per precompilare questo modulo per te.",
          "LABEL_EFFECTIVE_DATE": "Data di entrata in vigore",
          "HELP_TEXT_EFFECTIVE_DATE": "Trattandosi del primo appuntamento, la data di decorrenza è legata alla data di iscrizione al rapporto di lavoro.",
          "INFO_NEW_EMPLOYEE": "Per finire, configura il nuovo appuntamento di questo dipendente impostando il suo sito/reparto, il ruolo lavorativo e altri attributi chiave.",
          "LABEL_LINE_MANAGER": "Responsabile di linea",
          "LABEL_LINE_MANAGER_NOT_SET": "Non impostato",
          "TITLE_JOB_ROLE": "Ruolo lavorativo",
          "LABEL_SITE_DEPARTMENT": "Sito/dipartimento",
          "LABEL_JOB_ROLE": "Ruolo lavorativo",
          "LABEL_STAFF_GROUP": "Gruppo di personale",
          "HELP_TEXT_STAFF_GROUP": "I gruppi di personale vengono utilizzati per separare visivamente le persone a rotazione e tra altri strumenti.",
          "TITLE_EMPLOYMENT_CONTRACT": "Contratto di lavoro",
          "LABEL_COMPANY": "Compagnia",
          "LABEL_PAYROLL_CALENDAR": "Calendario delle retribuzioni",
          "LABEL_CONTRACT_TYPE": "Tipo di contratto",
          "LABEL_PUBLIC_HOLIDAYS": "Giorni festivi",
          "HELP_TEXT_PUBLIC_HOLIDAYS": "Questo dipendente lavora in genere nei giorni festivi.",
          "LABEL_WORKING_TIME": "Regolamenti sull'orario di lavoro",
          "HELP_TEXT_WORKING_TIME": "Questo dipendente ha rinunciato ai limiti di lavoro opzionali previsti dalle norme sull'orario di lavoro.",
          "TITLE_CONTRACTUAL_OBLIGATION": "Impegno contrattuale",
          "LABEL_MIN_OBLIGATION": "Ore/giorni garantiti",
          "HELP_TEXT_MIN_OBLIGATION": "A questo dipendente è garantita una quantità minima di lavoro.",
          "LABEL_MAX_OBLIGATION": "Limite di lavoro",
          "HELP_TEXT_MAX_OBLIGATION": "Applica un limite massimo alla quantità di ore o giorni in cui è possibile programmare il lavoro di questo dipendente.",
          "TITLE_STREAM_MAPPINGS": "Mappature predefinite dei flussi",
          "HELP_TEXT_STREAM_MAPPINGS": "Quando questo dipendente lavora a turni, le mappature del tipo di turno determinano i flussi in cui allocare il costo salariale. Se il tipo di turno non ne ha, vengono invece utilizzati questi:",
          "COLUMN_STREAM": "Stream",
          "COLUMN_ATTRIBUTION": "Attribuzione",
          "COLUMN_OPTIONS": "Opzioni",
          "OPTION_DELETE": "Elimina",
          "NO_MAPPINGS": "Non ce ne sono da mostrare.",
          "LABEL_ADD_MAPPING": "Aggiungere una nuova mappatura",
          "OPTION_ADD": "Inserisci",
          "LABEL_NO_MAPPINGS": "Nessuna mappatura",
          "CHECKBOX_NO_MAPPINGS": "Accetto che, in assenza di mappature di flusso predefinite, potrebbero esserci delle imprecisioni nel controllo dei costi e in alcuni report.",
          "HELP_TEXT_NO_MAPPINGS": "Questo va bene se non vuoi monitorare il costo salariale di questo dipendente.",
          "BUTTON_SAVE": "Salva",
          "BUTTON_SAVE_LOADING": "Salvataggio...",
          "BUTTON_DELETE": "Elimina",
          "BUTTON_DELETE_LOADING": "Eliminazione in corso..."
        }
      },
      "DOCUMENTS": {
        "FILTER_LABEL_CATEGORY": "Categoria",
        "BUTTON_UPLOAD": "Carica file",
        "BUTTON_SIGNATURES": "Firme dei documenti",
        "OPTION_SEND_TO_SIGN": "Inviare un documento da firmare",
        "OPTION_PENDING_SIGNATURES": "Visualizza i documenti in attesa di firma",
        "ERROR_500": "Non possiamo caricare i documenti in questo momento. Per favore riprova.",
        "ERROR_403": "Non sei autorizzato a visualizzare/modificare i documenti di questa persona.",
        "ERROR_NO_CATEGORIES": "I documenti non sono abilitati per la tua organizzazione. Chatta con noi per iniziare.",
        "TOOLTIP_CATEGORY_NOT_APPLICABLE": "Categoria contrassegnata come non obbligatoria",
        "TOOLTIP_CATEGORY_INVALID": "Documento mancante o scaduto",
        "INFO_CATEGORY_NOT_APPLICABLE": "Questa categoria è stata contrassegnata come non obbligatoria per questo dipendente.",
        "LINK_MARK_CATEGORY_REQUIRED": "Contrassegna come obbligatorio",
        "LINK_MARK_CATEGORY_NOT_REQUIRED": "Contrassegna come non obbligatorio",
        "INFO_CATEGORY_NO_DOCUMENTS": "Non ci sono documenti in questa categoria.",
        "COLUMN_NAME": "Nome",
        "COLUMN_EXPIRES": "Scade",
        "COLUMN_FILE_SIZE": "Dimensione del file",
        "COLUMN_UPLOADED_BY": "Caricato da",
        "COLUMN_OPTIONS": "Opzioni",
        "LINK_DELETE": "Elimina",
        "LINK_EDIT": "Modifica",
        "ALERT_CONFIRM_DELETE": "Sei sicuro di voler eliminare questo documento? Questo non può essere annullato!",
        "TOOLTIP_SIGNATURE_ELECTRONIC": "Questo documento è stato firmato elettronicamente tramite Rotaready",
        "TOOLTIP_SIGNATURE_MANUAL": "Questo documento è stato firmato manualmente",
        "TOOLTIP_NOTES": "Le note sono disponibili",
        "PILL_SENSITIVE": "Sensibile",
        "PILL_CAN_EXPIRE": "Può scadere",
        "PILL_VISIBLE_TO_EMPLOYEE": "Visibile al dipendente",
        "TOOLTIP_ACKNOWLEDGED": "Riconosciuto: {{date}}",
        "PERSONAL": {
          "TITLE": "I miei documenti",
          "ALERT_CONFIRM_ACKNOWLEDGE": "Facendo clic su conferma confermi di aver letto e compreso il documento.",
          "INFO_NO_DOCUMENTS": "Non hai documenti da visualizzare.",
          "LINK_ACKNOWLEDGE": "Riconoscere",
          "PILL_NEEDS_ACKNOWLEDGEMENT": "Ha bisogno di riconoscimento",
          "PILL_ACKNOWLEDGED": "Riconosciuto",
          "COLUMN_NAME": "Nome",
          "COLUMN_CATEGORY": "Categoria",
          "COLUMN_EXPIRES": "Scade",
          "COLUMN_FILE_SIZE": "Dimensione del file",
          "COLUMN_OPTIONS": "Opzioni",
          "FILTER_LABEL_CATEGORY": "Categoria"
        }
      },
      "DOCUMENT_EDIT_FORM": {
        "TITLE": "Modifica documento",
        "BUTTON_CANCEL": "Annulla",
        "BUTTON_SAVE": "Salva",
        "LABEL_EXPIRY_DATE": "Data di scadenza",
        "LABEL_NOTES": "Note",
        "HELP_TEXT_EXPIRY": "Al documento deve essere assegnata una data di scadenza se la categoria ne richiede una. Riceverai un avviso per intervenire con l'avvicinarsi della data e di nuovo se scade.",
        "ALERT_SUCCESS": "Il documento è stato aggiornato.",
        "ALERT_ERROR_500": "Si è verificato un errore durante l'aggiornamento del documento. Riprova o contattaci se il messaggio persiste.",
        "ALERT_ERROR_400": "C'è qualcosa che non va nelle tue modifiche."
      },
      "DOCUMENT_UPLOAD_MODAL": {
        "TITLE": "Carica un documento",
        "ALERT_SIGNATURE": "Contrassegneremo questo documento come firmato manualmente.",
        "LABEL_UPLOAD_TO": "Carica su",
        "LABEL_CATEGORY": "Categoria",
        "LABEL_EXPIRY_DATE": "Data di scadenza",
        "HELP_TEXT_EXPIRY": "Al documento deve essere assegnata una data di scadenza se la categoria ne richiede una. Riceverai un avviso per intervenire con l'avvicinarsi della data e di nuovo se scade.",
        "LABEL_UPLOAD": "Carica",
        "LABEL_NOTES": "Note",
        "BUTTON_CANCEL": "Annulla",
        "BUTTON_SAVE": "Salva",
        "ALERT_SUCCESS": "Il tuo documento è stato caricato.",
        "ALERT_ERROR_500": "Si è verificato un errore durante il caricamento del documento. Riprova o contattaci se il messaggio persiste.",
        "ALERT_ERROR_400": "C'è qualcosa che non va nella tua categoria e nella data di scadenza."
      },
      "DOCUMENT_SIGNATURE_MODAL": {
        "ALERT_PICK_CATEGORY": "Seleziona una categoria in cui salvare il documento.",
        "ALERT_SUCCESS_MANUAL": "Il tuo documento è stato scaricato.",
        "ALERT_SUCCESS_ELECTRONIC": "Il documento è stato inviato per la firma elettronica.",
        "ALERT_ERROR_500": "Si è verificato un errore durante la creazione del documento. Riprova o contattaci se il messaggio persiste.",
        "ALERT_ERROR_400": "Non siamo riusciti a creare il tuo documento. Controlla i tuoi Merge Tags e riprova.",
        "TITLE": "Inviare un documento da firmare",
        "ALERT_INFO": "Inserendo i Merge Tag nel modello o nel file, Rotaready può inserire informazioni chiave sul dipendente a cui li stai inviando, come il nome o il ruolo lavorativo. È anche il modo in cui controlli dove appare la casella della firma.",
        "ALERT_INFO_LINK": "Scopri come",
        "LABEL_SEND_TO": "Invia a",
        "LABEL_DOCUMENT": "Documento",
        "LABEL_DOCUMENT_TEMPLATE": "Usa un modello esistente",
        "LABEL_DOCUMENT_UPLOAD": "Carica un file una tantum",
        "LABEL_TEMPLATE": "Modello",
        "ALERT_NO_TEMPLATES_TITLE": "Non hai alcun modello.",
        "ALERT_NO_TEMPLATES_HELP": "Risparmia tempo, carica un file una sola volta e riutilizzalo per più persone.",
        "ALERT_NO_TEMPLATES_BUTTON": "Carica un modello",
        "LABEL_UPLOAD": "Carica",
        "SECTION_SIGNING_INFO": "Informazioni sulla firma",
        "LABEL_TITLE": "Titolo",
        "HELP_TEXT_TITLE": "Assegna un titolo a questo documento in modo che la persona a cui lo stai inviando sappia di cosa si tratta.",
        "LABEL_EMPLOYER_SIGNS": "Segnali del datore",
        "OPTION_EMPLOYER_SIGN_FALSE": "No, solo il dipendente",
        "OPTION_EMPLOYER_SIGN_TRUE": "È richiesta una firma per conto del datore di lavoro",
        "LABEL_EMPLOYER_SIGNATORY": "Datore di lavoro firmatario",
        "HELP_TEXT_EMPLOYER_SIGNATORY": "Seleziona chi deve firmare questo documento per conto del datore di lavoro.",
        "LABEL_SIGNATURE_METHOD": "Metodo di firma",
        "OPTION_SIGNATURE_METHOD_MANUAL": "Manuale, con carta e penna",
        "OPTION_SIGNATURE_METHOD_ELECTRONIC": "Elettronico, con firma elettronica completamente legale",
        "LABEL_EMAIL_MESSAGE": "Messaggio e-mail",
        "HELP_TEXT_EMAIL_MESSAGE": "Aggiungi un messaggio opzionale alla notifica e-mail inviata a tutte le parti firmatarie.",
        "SECTION_UPON_COMPLETION": "Al termine",
        "SECTION_METHOD": "Metodo di firma",
        "LABEL_SAVE_TO": "Salva in",
        "HELP_TEXT_SAVE_TO": "Una volta firmato, il file verrà salvato nei documenti del dipendente nella categoria scelta.",
        "LABEL_EXPIRY_DATE": "Data di scadenza",
        "HELP_TEXT_EXPIRY_DATE": "Al documento deve essere assegnata una data di scadenza se la categoria di destinazione ne richiede una. Riceverai un avviso per intervenire con l'avvicinarsi della data e di nuovo se scade.",
        "CREDITS_DEPLETED_TEXT": "Sono necessari crediti per inviare questo documento per la firma elettronica.",
        "CREDITS_DEPLETED_CTA": "Chatta con noi adesso",
        "CREDITS_AVAILABLE_TEXT": "Hai <b>{{balance}}</b> crediti rimanenti. L'invio di questo documento utilizzerà un credito.",
        "CREDITS_AVAILABLE_CTA": "Ricaricare i tuoi crediti è facile. Basta inviarci un messaggio sulla live chat.",
        "BUTTON_CANCEL": "Annulla",
        "BUTTON_DOWNLOAD_PREVIEW": "Scarica l'anteprima",
        "BUTTON_SAVE_DOWNLOAD": "Salva e scarica",
        "BUTTON_SEND": "Inviare"
      },
      "NOTES": {
        "ERROR_500": "Non possiamo caricare le note in questo momento. Per favore riprova.",
        "ALERT_CONFIRM_DELETE": "Sei sicuro di voler eliminare questa nota?",
        "BODY_PLACEHOLDER": "Scrivi una nuova nota...",
        "SAVE_BUTTON_TEXT": "Aggiungi nota",
        "VISIBILITY_SELF": "Privato",
        "VISIBILITY_SELF_HELP": "Visibile solo da te",
        "VISIBILITY_SENSITIVE": "Sensibile",
        "VISIBILITY_SENSITIVE_HELP": "Visibile solo dalle persone autorizzate a visualizzare note sensibili",
        "VISIBILITY_GENERAL": "Generale",
        "VISIBILITY_GENERAL_HELP": "Visibile solo dalle persone autorizzate a visualizzare le note di questo dipendente",
        "EDITED_LABEL": "Modificato",
        "EDIT_LABEL": "Modifica",
        "DELETE_LABEL": "Elimina",
        "NO_NOTES": "Non ci sono note da mostrare."
      },
      "DANGER_ZONE": {
        "ANONYMISE": {
          "TITLE": "Anonimizza il dipendente",
          "ERROR_500": "Non siamo riusciti a salvare in quel momento. Per favore riprova.",
          "ERROR_403": "Non sei autorizzato ad accedere ai dati personali di questo dipendente. Ciò significa che non puoi programmare l'anonimizzazione del loro profilo.",
          "LABEL_END_DATE": "Data di fine del rapporto di lavoro",
          "HELP_TEXT": "Per contribuire a rispettare le leggi sulla protezione dei dati, il Rotaready può rimuovere tutte le informazioni personali da questo profilo di dipendente dopo un determinato periodo di tempo. Nel Regno Unito, l'HMRC richiede che i registri siano conservati per un minimo di sei anni.",
          "HELP_TEXT_LEARN": "Scopri di più",
          "LABEL_ANONYMISE": "Anonimizzazione",
          "CHECKBOX_ANONYMISE": "Rendi anonimo questo dipendente",
          "LABEL_ANONYMISE_DATE": "Anonimizza su",
          "ALERT_ANONYMISE_DATE": "Per rendere anonimo questo dipendente, scegli una data futura e successiva all'ultimo giorno di lavoro del dipendente.",
          "BUTTON_SAVE": "Salva"
        }
      }
    },
    "ABSENCE": {
      "ABSENCE": {
        "TITLE": "Calendario delle assenze",
        "FILTER_LABEL_APPROVAL_STATE": "Status",
        "FILTER_LABEL_POSITION": "Ruolo lavorativo",
        "FILTER_LABEL_STAFF_GROUP": "Gruppo di personale",
        "ERROR_500": "Non siamo riusciti a caricare l'assenza in quel momento. Per favore riprova.",
        "FILTER_LABEL_ABSENCE_TYPE": "Tipo",
        "FILTER_LABEL_REQUESTER": "Richiedente"
      }
    },
    "REQUESTS": {
      "ABSENCE": {
        "DATES_COLUMN_TEXT": "Data/e",
        "WHO_COLUMN_TEXT": "Chi",
        "ABSENCE_TYPE_COLUMN_TEXT": "Tipo",
        "SUBMITTED_DATE_TEXT": "Inviato",
        "HOURS_COLUMN_TEXT": "Ore",
        "DAYS_COLUMN_TEXT": "Giorni",
        "NO_STAFF_MATCH_TEXT": "Non siamo riusciti a trovare alcuna richiesta corrispondente ai tuoi filtri.",
        "FILTER_LABEL_POSITION": "Ruolo lavorativo",
        "FILTER_LABEL_STAFF_GROUP": "Gruppo di personale",
        "ERROR_500": "Non siamo riusciti a caricare l'assenza in quel momento. Per favore riprova.",
        "FILTER_LABEL_ABSENCE_TYPE": "Tipo",
        "FILTER_LABEL_REQUESTER": "Richiedente"
      },
      "CONFIRM_SWAP_APPROVAL_MODAL": {
        "TITLE": "Ne sei sicuro?",
        "VALIDATION_MANUAL_TEXT": "Per annullare questo cambio di turni dovrai modificare manualmente la rotazione.",
        "YES_TITLE": "sì",
        "APPROVE_BUTTON_TEXT": "Approva subito questo scambio e invia una notifica a entrambi i membri dello staff",
        "NO_TITLE": "No",
        "DO_NOTHING_BUTTON_TEXT": "Non fare nulla, non approvare ancora questo scambio",
        "CANCEL_BUTTON_TEXT": "Annulla"
      },
      "INDEX": {
        "HEADING": "Articoli che richiedono attenzione",
        "ABSENCE_LABEL": "Richieste di assenza",
        "SWAPS_LABEL": "Scambi di turni",
        "DOCUMENTS_EXPIRING_LABEL": "Documenti in scadenza",
        "ACCOUNT_APPROVAL_LABEL": "Nuovi falegnami"
      },
      "SWAPS": {
        "INITIATED_BY_TEXT": "Iniziato da",
        "INITIATED_TOOLTIP": "Il membro dello staff che vuole rinunciare al proprio turno",
        "SHIFT_COLUMN_TEXT": "Il loro turno",
        "SWAPPING_WITH_COLUMN_TEXT": "Scambio con",
        "SWAPPING_TOOLTIP": "Il membro dello staff che vuole fare il turno",
        "RETURN_SHIFT_COLUMN_TEXT": "Il loro turno",
        "OPTION_COLUMN_TEXT": "Opzioni",
        "ACCEPTED_DATE_COLUMN_TEXT": "Aspettando da",
        "NONE_TEXT": "Nessuna",
        "NO_STAFF_MATCH_TEXT": "Non siamo riusciti a trovare alcuna richiesta corrispondente ai tuoi filtri.",
        "FILTER_LABEL_PARTICIPANT": "Partecipante"
      },
      "DOCUMENTS": {
        "NAME_COLUMN_LABEL": "Nome",
        "FILE_NAME_COLUMN_LABEL": "Documento",
        "FILE_SIZE_COLUMN_LABEL": "Taglia",
        "CATEGORY_COLUMN_LABEL": "Categoria",
        "EXPIRY_DATE_COLUMN_LABEL": "Scade",
        "OPTIONS_COLUMN_LABEL": "Opzioni",
        "EXPIRED": "Scaduto",
        "UPLOAD_NEW_LINK": "Carica nuovo",
        "NO_DOCS_MATCH_TEXT": "Non siamo riusciti a trovare alcun documento corrispondente ai tuoi filtri.",
        "NO_CATEGORIES_TEXT": "Non hai categorie di documenti che possono scadere.",
        "FILTER_LABEL_SITE": "Sito/dipartimento",
        "FILTER_LABEL_APPOINTMENT": "Appuntamento",
        "FILTER_LABEL_PERSON": "Persona",
        "FILTER_LABEL_CATEGORY": "Categoria",
        "FILTER_LABEL_EXPIRY": "Scadenza",
        "APPOINTMENT_STATUS_ACTIVE": "Attivo",
        "APPOINTMENT_STATUS_INACTIVE": "Inattivo",
        "X_WEEKS_OR_LESS": " settimane o meno"
      },
      "NEW_JOINERS": {
        "NAME_COLUMN_LABEL": "Nome",
        "POSITION_COLUMN_LABEL": "Ruolo lavorativo",
        "ENTITY_COLUMN_LABEL": "Sito",
        "START_DATE_COLUMN_LABEL": "Inizia il",
        "OPTIONS_COLUMN_LABEL": "Opzioni",
        "NO_STAFF_MATCH_TEXT": "Non siamo riusciti a trovare nessuno che corrisponda ai tuoi filtri.",
        "APPROVE_LINK": "Approvare",
        "FILTER_LABEL_APPOINTMENT": "Appuntamento",
        "FILTER_LABEL_POSITION": "Ruolo lavorativo",
        "FILTER_LABEL_DATE_START": "Data di inizio",
        "APPOINTMENT_STATUS_ACTIVE": "Attivo",
        "APPOINTMENT_STATUS_INACTIVE": "Inattivo"
      }
    },
    "TAGS_GROUPS": {
      "TAGS_GROUPS": {
        "HEADING": "Tag e gruppi",
        "ASIDE_TEXT": "Un membro dello staff può appartenere a un solo gruppo, ma gli possono essere assegnati più tag. I gruppi sono simili al reparto o al team di qualcuno, mentre i tag possono rappresentare tutto ciò che ritieni significativo, come competenze o qualità personali.",
        "CREATE_NEW_TAG_PLACEHOLDER": "Crea nuovo tag...",
        "ADDING_BUTTON_TEXT": "Aggiungendo...",
        "ADD_BUTTON_TEXT": "Inserisci",
        "TAG_HELP": "Fai clic su un tag per modificarlo e vedere a chi è assegnato:",
        "CREATE_ONE_TEXT": "Al momento non hai tag. Perché non crearne uno?",
        "CREATE_NEW_GROUP_PLACEHOLDER": "Crea nuovo gruppo...",
        "GROUPS_TITLE": "Gruppi",
        "EDIT_LINK_TEXT": "modificare"
      },
      "VIEW_GROUP_MODAL": {
        "EDIT_GROUP_TITLE": "Modifica gruppo",
        "SITE_LABEL": "Sito",
        "EDIT_TAB_HEADING": "Modifica",
        "ORG_WIDE_LABEL": "A livello di organizzazione",
        "GROUP_NAME_LABEL": "Nome del gruppo",
        "AVAILABILITY_LABEL": "Disponibilità",
        "ALWAYS_AVAILABLE_TEXT": " Sempre disponibile",
        "ALWAYS_AVAILABLE_HELP": "Il personale di questo gruppo è considerato sempre disponibile al lavoro, tranne quando i modelli di disponibilità o assenza impongano il contrario.",
        "ALWAYS_UNAVAILABLE_TEXT": " Sempre non disponibile",
        "ALWAYS_UNAVAILABLE_HELP": "Per impostazione predefinita, il personale di questo gruppo non è disponibile. Utilizzeranno «eccezioni» e «disponibilità per una settimana» per contrassegnare quando sono gratuiti.",
        "MEMBERS_TAB_HEADING": "Membri",
        "NO_ACTIVE_MEMBERS_HELP": "Non ci sono membri attivi in questo gruppo. <br /><br />Il personale viene assegnato a un gruppo tramite nomina. Trova qualcuno nell'elenco del personale, fai clic sulla scheda Appuntamenti e modifica un appuntamento esistente o aggiungine uno nuovo.",
        "DELETE_TAB_HEADING": "Elimina",
        "MOVE_MEMBERS_TO_LABEL": "Sposta i membri in",
        "DELETE_TAB_ALERT": "L'eliminazione di questo gruppo farà sì che le persone di {{totalMembers}} si spostino in un gruppo diverso. Questo è irreversibile e non può essere annullato.",
        "CONFIRM_NAME_LABEL": "Digita il nome del gruppo",
        "CONFIRM_NAME_HELP_TEXT": "Per convincerci che vuoi farlo, digita il nome del gruppo nella casella.",
        "DELETING_BUTTON_TEXT": "Eliminazione in corso...",
        "DELETE_BUTTON_TEXT": "Elimina",
        "SAVING_BUTTON_TEXT": "Salvataggio...",
        "SAVE_BUTTON_TEXT": "Salva",
        "CANCEL_BUTTON_TEXT": "Annulla"
      },
      "VIEW_TAG_MODAL": {
        "EDIT_TAG_TITLE": "Modifica tag",
        "EDIT_CLONE_LABEL": "Modifica/clona",
        "EDIT_EXISTING_TAG_TEXT": " Modifica tag esistente",
        "CLONE_TAG_TEXT": " Clona il tag e salva come nuovo",
        "TAG_NAME_LABEL": "Nome del tag",
        "STAFF_MEMBERS_LABEL": "Membri dello staff",
        "TAG_TYPEAHEAD_PLACEHLDER": "Digita per taggare il personale",
        "LAST_EDITED_LABEL": "Ultima modifica",
        "ON_TEXT": "su {{date|momentFormat: 'llll'}} Momentformat:'llll'HOUST}",
        "DELETING_BUTTON_TEXT": "Eliminazione in corso...",
        "DELETE_BUTTON_TEXT": "Elimina",
        "SAVING_BUTTON_TEXT": "Salvataggio...",
        "SAVE_BUTTON_TEXT": "Salva",
        "CLONING_BUTTON_TEXT": "Clonazione...",
        "CLONE_BUTTON_TEXT": "Clona",
        "CANCEL_BUTTON_TEXT": "Annulla",
        "CLOSE_BUTTON_TEXT": "Chiudi"
      }
    },
    "ADD_NEW": {
      "TITLE": "Aggiungere un dipendente",
      "LABEL_FIRST_NAME": "Nome",
      "LABEL_LAST_NAME": "Cognome",
      "LABEL_DOB": "Data di nascita",
      "LABEL_EMAIL": "Indirizzo email",
      "HELP_TEXT_EMAIL_OIDC": "Deve corrispondere esattamente a ciò che il dipendente utilizza per il servizio Single Sign-On. Di solito termina con il nome di dominio della tua azienda.",
      "HELP_TEXT_EMAIL_ROTAREADY": "Tutti hanno un account su Rotaready e utilizzano il proprio indirizzo email per accedere.",
      "LABEL_COPY_EXISTING": "Copiare un dipendente esistente",
      "PLACEHOLDER_COPY_EXISTING_NO_THANKS": "No grazie",
      "HELP_TEXT_COPY_EXISTING": "Copia l'appuntamento, l'indennità per le ferie e le etichette di un altro dipendente.",
      "LABEL_DATE_START": "Data di inizio del rapporto di lavoro",
      "LABEL_SITE": "Sito/dipartimento",
      "LABEL_PERMISSIONS": "Livello di autorizzazione",
      "LABEL_INVITE": "Invito a Rotaready",
      "HELP_TEXT_INVITE_OIDC": "Invia un link magico a questo dipendente, che lo invita ad accedere per la prima volta.",
      "HELP_TEXT_INVITE_ROTAREADY": "Invia un link magico a questo dipendente, che lo invita a impostare la password e ad accedere per vedere i suoi turni.",
      "LABEL_ONBOARDING": "Onboarding",
      "CHECKBOX_ONBOARDING_OPT_IN": "Richiedi a questo dipendente di completare l'inserimento automatico al primo accesso al Rotaready. A seconda delle impostazioni della tua organizzazione, verrà chiesto loro di fornire informazioni personali come indirizzo, contatti di emergenza e documentazione sul diritto al lavoro.",
      "BUTTON_ADD": "Inserisci",
      "BUTTON_ADD_LOADING": "Aggiungendo...",
      "ALERT_SUCCESS": "Successo! Completa la configurazione del tuo dipendente completando ogni scheda.",
      "ALERT_500": "Non siamo riusciti ad aggiungere il dipendente in quel momento. Per favore riprova.",
      "ALERT_403": "Non sei autorizzato ad aggiungere un nuovo dipendente.",
      "ALERT_403_PERMISSION_LEVEL": "Non sei autorizzato ad aggiungere qualcuno a questo livello di autorizzazione.",
      "ALERT_403_COPY_EXISTING": "Non sei autorizzato a copiare questo dipendente.",
      "ALERT_403_ENTITY": "Non hai l'autorizzazione per aggiungere qualcuno a questo sito/dipartimento.",
      "DUPLICATE_MODAL": {
        "TITLE": "Dipendenti esistenti",
        "DESCRIPTION": "La persona che stai cercando di aggiungere condivide alcuni attributi con uno o più dipendenti esistenti. Se questa persona ha già lavorato per la tua organizzazione, aggiungi un nuovo impiego.",
        "COLUMN_NAME": "Nome",
        "COLUMN_EMPLOYMENT": "Occupazione",
        "COLUMN_SITE": "Sito/dipartimento",
        "COLUMN_SIMILARITIES": "Somiglianze",
        "COLUMN_OPTIONS": "Opzioni",
        "STATUS_ACTIVE": "Attivo",
        "STATUS_INACTIVE": "Inattivo",
        "OPTION_VIEW": "Visualizza dipendente",
        "OPTION_NEW_EMPLOYMENT": "Nuova occupazione",
        "BUTTON_PROCEED": "Procedi comunque",
        "BUTTON_BACK": "Torna indietro",
        "SIMILARITY_NAME": "Nome",
        "SIMILARITY_EMAIL": "E-mail",
        "SIMILARITY_MOBILE": "Numero di cellulare",
        "SIMILARITY_DOB": "Data di nascita"
      }
    }
  },
  "DIRECTIVES": {
    "STAFF": {
      "SEARCH": {
        "PLACEHOLDER": "Cerca...",
        "AND_X_MORE": "E {{x}} altro...",
        "INACTIVE_APPOINTMENT": "Inattivo"
      },
      "ADD_ROUTINE_MAPPING_FORM": {
        "STARTS_LABEL": "Inizia",
        "STARTS_TEXT": "Il primo giorno di questa routine per {{firstName}}",
        "ENDS_INPUT_TEXT": " Termina",
        "ENDS_TEXT": "L'ultimo giorno di questa routine per {{firstName}}",
        "ONGOING_TEXT": "In corso",
        "SHIFT_ROUTINE_LABEL": "Routine del turno",
        "OFFSET_LABEL": "Offset",
        "OFFSET_HELP": "Questo è il numero di giorni in cui {{firstName}} dovrebbe iniziare secondo questo schema."
      },
      "AVAILABILITY_RULE": {
        "IM_UNAVAILABLE_TEXT": "Non sarò disponibile",
        "CANCEL_TEXT": "annullare",
        "AVAILABLE_TEXT": "DISPONIBILE",
        "UNAVAILABLE_TEXT": "NON DISPONIBILE",
        "AVAILABLE_FROM_TEXT": "Sono disponibile solo da ",
        "UNAVAILABLE_FROM_TEXT": "Non sono disponibile da ",
        "TO_TEXT": " a ",
        "ALL_DAY": "Tutto il giorno",
        "AM_ONLY": "Solo AM",
        "PM_ONLY": "Solo PM",
        "AVAIALABLE_CUSTOM": "Disponibile (personalizzato)",
        "UNAVAILABLE_CUSTOM": "Non disponibile (personalizzato)",
        "UNAVAILABLE": "Non disponibile",
        "AVAILABLE": "Disponibile",
        "AVAILABLE_AM": "Disponibile (solo AM)",
        "AVAILABLE_PM": "Disponibile (solo PM)"
      },
      "AUDIT_USER_WIDGET": {
        "EDITED_BY": "A cura di"
      },
      "VALIDATION_BULK_ABSENCE_EDIT": {
        "INFO_TRUNCATED": "A causa del volume delle prenotazioni di assenze interessate, non possono essere modificate in blocco qui. Utilizza la scheda Assenza sul profilo del dipendente o contatta il Rotaready per ulteriore assistenza.",
        "INFO_SUCCESS": "Non sono rimaste prenotazioni di assenze contrastanti.",
        "ERROR_500": "In quel momento abbiamo riscontrato un errore imprevisto. Riprova.",
        "ERROR_400": "Questa modifica non può essere apportata perché coincide con una data che è stata firmata.",
        "COLUMN_DATES": "Data/e",
        "COLUMN_TYPE": "Tipo",
        "COLUMN_HOURS": "Ore",
        "COLUMN_DAYS": "Giorni",
        "COLUMN_STATUS": "Status",
        "COLUMN_OPTIONS": "Opzioni",
        "OPTION_CANCEL": "Annulla"
      },
      "VALIDATION_BULK_ATTENDANCE_EDIT": {
        "INFO_TRUNCATED": "A causa del volume di partecipazione interessato, non è possibile modificarli in blocco qui. Utilizza la scheda Presenze sul profilo del dipendente o contatta Rotaready per ulteriore assistenza.",
        "INFO_SUCCESS": "Non sono rimasti eventi di partecipazione contrastanti.",
        "ERROR_500": "In quel momento abbiamo riscontrato un errore imprevisto. Riprova.",
        "ERROR_400": "Tale modifica non può essere apportata perché coincide con una data che è stata firmata.",
        "COLUMN_EVENT": "Evento",
        "COLUMN_DATE": "Data",
        "COLUMN_OPTIONS": "Opzioni",
        "EVENT_TYPE_CLOCK_IN": "Orologio",
        "OPTION_DELETE": "Elimina"
      },
      "VALIDATION_BULK_ROUTINE_MAPPING_EDIT": {
        "INFO_TRUNCATED": "A causa del volume delle mappature delle routine dei turni interessate, non è possibile modificarle in blocco qui. Utilizza la scheda Routine dei turni sul profilo del dipendente o contatta Rotaready per ulteriore assistenza.",
        "INFO_SUCCESS": "Non sono rimaste mappature contrastanti delle routine dei turni.",
        "ERROR_500": "In quel momento abbiamo riscontrato un errore imprevisto. Riprova.",
        "ERROR_400": "Questa modifica non può essere apportata perché coincide con una data che è stata firmata.",
        "COLUMN_START_DATE": "Data di inizio",
        "COLUMN_END_DATE": "Data di fine",
        "COLUMN_SHIFT_ROUTINE": "Routine del turno",
        "COLUMN_OPTIONS": "Opzioni",
        "LABEL_OPEN_ENDED": "A tempo indeterminato",
        "OPTION_ADD_END_DATE": "Aggiungi data di fine",
        "OPTION_DELETE": "Elimina"
      },
      "VALIDATION_BULK_SHIFT_EDIT": {
        "INFO_TRUNCATED": "A causa del volume dei turni interessati, non è possibile modificarli in blocco qui. Modifica i turni corrispondenti o contatta Rotaready per ulteriore assistenza.",
        "INFO_SUCCESS": "Non sono rimasti turni contrastanti.",
        "ERROR_500": "In quel momento abbiamo riscontrato un errore imprevisto. Riprova.",
        "ERROR_400": "Questo cambiamento non può essere fatto. Potrebbe sovrapporsi a una data che è stata firmata. Per saperne di più, prova questo sulla rotazione stessa.",
        "BUTTON_UNASSIGN_SELECTED": "Annulla l'assegnazione selezionata",
        "BUTTON_DELETE_SELECTED": "Elimina selezionati",
        "COLUMN_DATE": "Data",
        "COLUMN_TIME": "Ora",
        "COLUMN_DEPARTMENT": "Dipartimento",
        "COLUMN_TYPE": "Tipo",
        "COLUMN_STATUS": "Status",
        "COLUMN_OPTIONS": "Opzioni",
        "STATUS_PUBLISHED": "Pubblicato",
        "STATUS_DRAFT": "Bozza",
        "OPTION_UNASSIGN": "Annulla assegnazione",
        "OPTION_DELETE": "Elimina"
      },
      "VALIDATION_EPHEMERAL_RECORDS": {
        "LABEL_OPEN_ENDED": "(a tempo indeterminato)",
        "LABEL_NEW": "Nuovo",
        "LABEL_EXISTING": "Esistente",
        "EMPLOYMENT": "Occupazione",
        "APPOINTMENT": "Appuntamento"
      },
      "ACCOUNT_COMPLETION_TRACKER": {
        "CONSTITUENT_PERSONAL": "Personale",
        "CONSTITUENT_EMPLOYMENT": "Occupazione",
        "CONSTITUENT_PAY": "Paga",
        "CONSTITUENT_HOLIDAY_ALLOWANCE": "Indennità per ferie"
      }
    },
    "CALENDAR": {
      "TIP": "Per richiedere l'assenza basta fare clic su un giorno oppure fare clic e trascinare per selezionare più giorni contemporaneamente.",
      "TODAY": "Oggi",
      "MONTH": "Mese",
      "WEEK": "Settimana",
      "DAY": "Giorno"
    },
    "HIERARCHY_ITEM_PICKER": {
      "PICKER": "Raccoglitore",
      "PLACEHOLDER": "Nessun {{itemDescriptor}} selezionato",
      "FILTER_TO": "Filtra per...",
      "RESET": "Reimposta",
      "REMOVED_BY_FILTER": "Il file {{itemDescriptor}} selezionato è stato rimosso dal filtro.",
      "NO_SELECTED": "Nessun {{itemDescriptor}} selezionato"
    },
    "SUBSCRIBE_BUTTON": {
      "LABEL_ETID_1": "richiesta",
      "LABEL_ETID_2": "cancellato",
      "LABEL_ETID_3": "approvato",
      "LABEL_ETID_4": "respinto",
      "LABEL_ETID_5": "contrassegnato per l'approvazione",
      "LABEL_ETID_6": "pubblicato",
      "LABEL_ETID_7": "tornato alla bozza",
      "LABEL_ETID_8": "la settimana è firmata",
      "LABEL_ETID_9": "in attesa di approvazione",
      "LABEL_ETID_10": "approvato",
      "LABEL_ETID_11": "richiesta",
      "LABEL_ETID_13": "approvato",
      "LABEL_ETID_14": "respinto",
      "LABEL_ETID_15": "cancellato",
      "LABEL_ETID_16": "prossima alla scadenza",
      "LABEL_ETID_17": "scaduto",
      "LABEL_ETID_29": "iniziato",
      "LABEL_ETID_30": "accettato da qualcuno",
      "LABEL_ETID_31": "rifiutato da qualcuno",
      "LABEL_ETID_32": "completato",
      "LABEL_ETID_36": "la settimana è invertita",
      "LABEL_ETID_37": "il giorno è firmato",
      "LABEL_ETID_38": "il giorno è invertito",
      "DESCRIPTOR_ABSENCE": "una richiesta di assenza è",
      "DESCRIPTOR_ROTA": "una rotazione è",
      "DESCRIPTOR_ACCOUNT": "un dipendente è",
      "DESCRIPTOR_SWAP": "uno scambio di turni è",
      "DESCRIPTOR_COST_CONTROL": "un controllo dei costi",
      "DESCRIPTOR_DOCUMENT": "un documento è",
      "DESCRIPTOR_BROADCAST": "una trasmissione a turni è",
      "NOUN_ABSENCE": "prenotazione assenze",
      "NOUN_ROTA": "rota",
      "NOUN_ACCOUNT": "dipendente",
      "NOUN_SWAP": "cambio di turni",
      "NOUN_COST_CONTROL": "record di controllo dei costi",
      "NOUN_DOCUMENT": "documento",
      "NOUN_BROADCAST": "trasmissione a turni",
      "NOTIFY_SENTENCE_EXORDIUM": "Avvisami quando",
      "NOTIFY_SENTENCE_TRAILING_IN": "nel...",
      "UNSUBSCRIBE": "Annulla l'iscrizione",
      "SUBSCRIBE": "Abbonati",
      "SEARCH_PLACEHOLDER": "Cerca...",
      "BACK_LINK": "Indietro",
      "RESOURCE_SUBSCRIBE_TOOLTIP": "Ricevi una notifica delle modifiche a questo {{name}}",
      "RESOURCE_UNSUBSCRIBE_TOOLTIP": "Annulla l'iscrizione alle modifiche a questo {{name}}"
    },
    "ROTA": {
      "DASHBOARD_WEEK": {
        "VIEW_ROTA": "Visualizza rota",
        "LAST_WEEK": "La settimana scorsa",
        "THIS_WEEK": "Questa settimana",
        "NO_ATTENDANCE_ISSUES": "Nessun problema di partecipazione",
        "ATTENDANCE_ISSUES": "problema/e di partecipazione",
        "RESOLVE": "Risolvere",
        "ACTUAL_SALES_ENTERED": "Dati di vendita effettivi inseriti",
        "ACTUAL_SALES_MISSING": "Mancano i dati <strong>di vendita effettivi</strong>",
        "WTD_COMPLIANT": "Conforme alle normative sull'orario di lavoro",
        "VIEW": "Visualizza",
        "WTD_VIOLATIONS": "Violazioni dell'orario di lavoro",
        "SIGN_OFF_NOT_COMPLETED": "<strong>Disconnessione</strong> non ancora completata",
        "SIGN_OFF": "Disconnetti",
        "WEEK_SIGNED_OFF": "Settimana conclusa",
        "ABSENCE_REQUEST": "Richieste di assenza",
        "ADDED_SALES_FORECAST": "Aggiunte previsioni di vendita",
        "DRAFTED_ROTA": "Rotazione redatta",
        "MARKED_FOR_APPROVAL": "Rota marcato per l'approvazione",
        "PUBLISHED": "Rotazione pubblicata",
        "OUTSTANDING": "{{absences}} in sospeso",
        "NOT_ADDED": "Non aggiunto",
        "NOT_DRAFTED": "Non redatto",
        "NOT_DONE": "Non fatto",
        "NOT_PUBLISHED": "Non pubblicato"
      }
    },
    "SIDEBAR": {
      "NOTIFICATION_POPOVER": {
        "NO_NOTIFICATIONS": "Nessuna notifica!",
        "MARK_ALL_READ": "Segna tutto letto"
      },
      "SETTINGS_POPOVER": {
        "TIMEZONE": "Questo fuso orario è diverso dal fuso orario locale.",
        "EDIT_PREFERENCES": "Modifica le preferenze",
        "MANAGE_SUBSCRIPTIONS": "Gestisci gli abbonamenti",
        "SHOW_INTERCOM": "Mostra la chat di supporto",
        "HIDE_INTERCOM": "Nascondi la chat di supporto",
        "SIGN_OUT": "Esci"
      }
    },
    "DATE_PICKER": {
      "CALENDAR": "Calendario",
      "GO_TO_TODAY": "Vai a oggi"
    },
    "ENTITY_SELECTOR": {
      "ORG_WIDE": "A livello di organizzazione",
      "NO_OPTIONS": "Nessuna opzione",
      "SEARCH_PLACEHOLDER": "Cerca..."
    },
    "FILTER_OPTIONS_BUTTON": {
      "IS": "è",
      "AND": "e",
      "OR": "o",
      "ANY": "Qualsiasi",
      "SEARCH_PLACEHOLDER": "Cerca...",
      "NO_OPTIONS": "Nessuna opzione",
      "SELECT_ALL": "Seleziona tutto",
      "CLEAR_SELECTED": "Cancella selezionato"
    },
    "FILTER_STAFF_SEARCH_BUTTON": {
      "IS": "è",
      "ANY": "Qualsiasi",
      "RESET": "Reimposta"
    },
    "FILTER_DATE_BUTTON": {
      "ANY": "qualsiasi",
      "BEFORE": "prima",
      "AFTER": "dopo",
      "BETWEEN": "fra",
      "IS": "è",
      "AND": "e",
      "DONE": "Fatto"
    },
    "FILTER_NUMBER_BUTTON": {
      "ANY": "è qualsiasi",
      "GREATER": "è maggiore di",
      "LESS": "è inferiore a",
      "EXACTLY": "è esattamente",
      "DONE": "Fatto"
    },
    "VALIDATION_FAILURE": {
      "TITLE": "Problemi di convalida",
      "ISSUE": "problema",
      "ISSUES": "problemi",
      "CHANGE_MESSAGE": "La modifica che stai cercando di apportare ha comportato ",
      "IGNORE_CONTINUE": "Ignora e continua comunque",
      "IGNORE_PROBLEM": "Ignora i cambiamenti problematici e riprova",
      "LOADING": "Caricamento..."
    },
    "PICK_LIST": {
      "NO_OPTIONS": "Nessuna opzione",
      "SEARCH_PLACEHOLDER": "Cerca...",
      "DEFAULT_NULL_OPTION_LABEL": "Nessuna"
    },
    "FILE_UPLOAD": {
      "ALERT_UNSUPPORTED_FILE_TYPE": "Purtroppo questo tipo di file non è supportato.",
      "ALERT_FILE_SIZE": "Purtroppo il file è troppo grande.",
      "LABEL_ACCEPTED_FILE_TYPES": "Tipi di file accettati",
      "LABEL_LIMIT": "limite",
      "INFO_DROP_AVAILABLE_PART_1": "Trascina e rilascia un file qui o",
      "INFO_DROP_AVAILABLE_PART_2": "clicca per sfogliare",
      "INFO_DROP_NOT_AVAILABLE": "Fai clic per cercare un file",
      "INFO_DRAG_ACTIVE": "Bella mira, è ora di cadere!",
      "INFO_UPLOAD_FAILED": "Caricamento non riuscito, riprova"
    }
  },
  "AVAILABILITY": {
    "ABSENCE": {
      "TAB_ABSENCE": "Prenotazioni di assenze",
      "TAB_ALLOWANCES": "Indennità"
    },
    "WEEKLY_AVAILABILITY_MODAL": {
      "ADD_TITLE": "Aggiungi disponibilità settimanale",
      "EDIT_TITLE": "Modifica la disponibilità settimanale",
      "NICKNAME": "Soprannome",
      "PLACEHOLDER": "vale a dire. Mentre studia",
      "HELP": "Dovresti chiamare questo modello di disponibilità con qualcosa di significativo, ad esempio «Mentre studi» se questo rappresenta quando puoi lavorare mentre studi per gli esami, o «Vacanze estive» per quando sei libero la maggior parte del tempo, ad esempio.",
      "STARTING_FROM": "A partire da",
      "NEVER_ENDING": "Senza fine",
      "YES_PATTERN_FOREVER": "Sì, lascia che questo schema continui per sempre",
      "NO_PATTERN_DATE": "No, c'è una data in cui vorrei che questo schema finisse",
      "STOP_DATE": "Data di fine",
      "PATTERN_APPLIES": "Il modello si applica",
      "EVERY": "Ogni",
      "WEEK": "Settimana",
      "SAVING_BUTTON_TEXT": "Salvataggio...",
      "SAVE_BUTTON_TEXT": "Salva",
      "CANCEL": "Annulla"
    },
    "WEEKLY_AVAILABILITY_MODAL_SINGLE": {
      "TITLE": "Aggiungi una disponibilità una tantum",
      "SUBTITLE": "Per una sola settimana",
      "WEEK_BEGINS": "La settimana inizia",
      "HELP": "Questa è la settimana per cui desideri contrassegnare la tua disponibilità.",
      "SAVING_BUTTON_TEXT": "Salvataggio...",
      "SAVE_BUTTON_TEXT": "Salva",
      "CANCEL": "Annulla"
    },
    "AVAILABILITY": {
      "ADD_AVAILABILITY": "Aggiungi disponibilità",
      "NEW_WEEKLY_PATTERN": "Nuovo schema settimanale",
      "NEW_MONTHLY_PATTERN": "Nuovo modello mensile",
      "NEW_CUSTOM_PATTERN": "Nuovo modello personalizzato",
      "ONE_OFF_PATTERN": "Una tantum (una settimana)",
      "AVAILABILITY": "Disponibilità",
      "AVAILABILITY_HELP": "Gestisci la disponibilità qui attraverso modelli di disponibilità; pensali come eventi ricorrenti del calendario.",
      "AVAILABILITY_TIP": "Usa uno schema mensile per impostare date come «il primo mercoledì» o «l'ultimo sabato» di ogni mese.",
      "NICKNAME": "Soprannome",
      "APPLIES_FROM_TO": "Si applica da (/a)",
      "OPTIONS": "Opzioni",
      "NO_AVAILABILITY_PATTERNS": "Non ci sono modelli di disponibilità. Aggiungine uno usando i pulsanti qui sopra.",
      "TURN_OFF": "Spegnere",
      "TURN_ON": "Attivare",
      "NO_NICKNAME": "Nessun soprannome",
      "WEEKLY": "Settimanale",
      "MONTHLY": "Mensile",
      "CUSTOM": "Personalizzato",
      "EDIT": "Modifica",
      "TOGGLE_DROPDOWN": "Attiva/disattiva il menu a discesa",
      "DELETE": "Elimina",
      "CONFIRM_DELETE_PATTERN": "Ne sei sicuro? L'eliminazione di questo modello di disponibilità non può essere annullata.",
      "DELETE_PATTERN_SUCCESS": "Successo! Il tuo modello di disponibilità è stato eliminato.",
      "ERROR_DELETING_PATTERN": "Non siamo riusciti a eliminare quel pattern in questo momento. Riprova.",
      "ERROR_TOGGLING_PATTERN": "Non siamo riusciti ad attivare/disattivare quel pattern in questo momento. Riprova.",
      "ERROR_LOADING_PATTERNS": "Non siamo riusciti a caricare i tuoi modelli di disponibilità. Per favore riprova."
    },
    "DAILY_AVAILABILITY_MODAL": {
      "ADD_CUSTOM_AVAILABILITY": "Aggiungi disponibilità personalizzata",
      "EDIT_CUSTOM_AVAILABILITY": "Modifica la disponibilità personalizzata",
      "NICKNAME": "Soprannome",
      "NICKNAME_PLACEHOLDER": "vale a dire. Mentre studia",
      "NICKNAME_HELP": "Dovresti dare a questo modello il nome di qualcosa di significativo.",
      "STARTING_FROM": "A partire da",
      "NEVER_ENDING": "Senza fine",
      "CONTINUE_FOREVER": "Sì, lascia che questo schema continui per sempre",
      "PATTERN_STOP": "No, c'è una data in cui vorrei che questo schema finisse",
      "STOP_DATE": "Data di fine",
      "PATTERN_DAYS": "Giorni modello",
      "PATTERN_NO_DAYS": "Questo schema non ha giorni. Aggiungine uno usando il pulsante qui sotto.",
      "DAY_NUMBER": "Giorno {{number}}",
      "ILL_BE": "Sarò",
      "DELETE": "Elimina",
      "ADD_DAY": "Aggiungi giorno",
      "SAVE": "Salva",
      "SAVE_LOADING": "Salvataggio...",
      "CANCEL": "Annulla",
      "UNAVAILABLE": "Non disponibile",
      "AVAILABLE": "Disponibile",
      "AVAILABLE_AM": "Disponibile (solo AM)",
      "AVAILABLE_PM": "Disponibile (solo PM)",
      "OTHER": "Altro (specificare...)",
      "MINIMUM_TWO_DAYS": "Devi avere un minimo di due giorni in questo schema.",
      "STOPS_BEFORE_STARTS": "Il tuo pattern è attualmente impostato per fermarsi prima che inizi!",
      "AVAILABILITY_END_BEFORE_START": "Una o più regole di disponibilità hanno un'ora di fine che precede l'ora di inizio.",
      "ERROR_SAVING_PATTERN": "Al momento non è possibile salvare questo modello di disponibilità. Per favore riprova.",
      "ERROR_UPDATING_PATTERN": "Al momento non è possibile aggiornare questo modello di disponibilità. Per favore riprova."
    },
    "EDIT_PATTERN_EXCEPTIONS_MODAL": {
      "EDIT_EXCEPTIONS": "Modifica le eccezioni",
      "EXCEPTIONS_TIP": "I tuoi modelli di disponibilità segnano quando non sei libero di lavorare. Tuttavia, puoi utilizzare il tuo calendario per dirci che sei libero di lavorare in un giorno in cui normalmente non saresti in grado di farlo. Basta fare clic sull'indicatore rosso «non disponibile» e potrai fare un'eccezione.",
      "EXCEPTIONS": "L'elenco seguente mostra le eccezioni che hai apportato al tuo modello di disponibilità. Ti consideriamo libero di lavorare in questi giorni:",
      "DATE": "Data",
      "OPTIONS": "Opzioni",
      "UNDO": "Annulla",
      "NO_EXCEPTIONS": "Non hai ancora fatto eccezioni.",
      "CLOSE": "Chiudi",
      "ERROR_DELETING_EXCEPTION": "Purtroppo non siamo riusciti a eliminare l'eccezione in quel momento. Per favore riprova."
    },
    "MONTHLY_AVAILABILITY_MODAL": {
      "ADD_MONTHLY_AVAILABILITY": "Aggiungi disponibilità mensile",
      "EDIT_MONTHLY_AVAILABILITY": "Modifica la disponibilità mensile",
      "NICKNAME": "Soprannome",
      "NICKNAME_PLACEHOLDER": "vale a dire. Mentre studia",
      "NICKNAME_HELP": "Dovresti chiamare questo modello di disponibilità con qualcosa di significativo, ad esempio «Mentre studi» se questo rappresenta quando puoi lavorare mentre studi per gli esami, o «Vacanze estive» per quando sei libero la maggior parte del tempo, ad esempio.",
      "STARTING_FROM": "A partire da",
      "NEVER_ENDING": "Senza fine",
      "CONTINUE_FOREVER": "Sì, lascia che questo schema continui per sempre",
      "PATTERN_STOP": "No, c'è una data in cui vorrei che questo schema finisse",
      "STOP_DATE": "Data di fine",
      "PATTERN_RULES": "Regole del modello",
      "PATTERN_NO_RULES": "Questo schema non ha regole. Aggiungine uno usando il pulsante qui sotto.",
      "EVERY": "Ogni",
      "ON_THE": "sul",
      "DELETE": "Elimina",
      "ADD_RULE": "Aggiungi regola",
      "SAVE": "Salva",
      "SAVE_LOADING": "Salvataggio...",
      "CANCEL": "Annulla",
      "ORDINAL_LAST": "scorso",
      "ORDINAL_FIRST": "primo",
      "ORDINAL_SECOND": "secondo",
      "ORDINAL_THIRD": "terzo",
      "ORDINAL_FOURTH": "quarta",
      "ORDINAL_FIFTH": "quinto",
      "MONDAY": "Lunedì",
      "TUESDAY": "martedì",
      "WEDNESDAY": "Mercoledì",
      "THURSDAY": "Giovedì",
      "FRIDAY": "Venerdì",
      "SATURDAY": "Sabato",
      "SUNDAY": "Domenica",
      "AVAILABLE": "Disponibile",
      "AVAILABLE_AM": "Solo AM",
      "AVAILABLE_PM": "Solo PM",
      "MONTH": "mese",
      "MONTHS": "mesi",
      "OTHER": "Altro (specificare...)",
      "STOPS_BEFORE_STARTS": "Il tuo pattern è attualmente impostato per fermarsi prima che inizi!",
      "AVAILABILITY_END_BEFORE_START": "Una o più regole di disponibilità hanno un'ora di fine che precede l'ora di inizio.",
      "ERROR_SAVING_PATTERN": "Al momento non è possibile salvare questo modello di disponibilità. Per favore riprova.",
      "ERROR_UPDATING_PATTERN": "Al momento non è possibile aggiornare questo modello di disponibilità. Per favore riprova."
    },
    "OVERVIEW": {
      "SCHEDULE": "La mia agenda",
      "SCHEDULE_HELP": "Questo è il tuo programma completo, compresi i turni che ti sono stati assegnati, le assenze approvate e la disponibilità.",
      "SHIFTS": "Turni",
      "ABSENCE": "Assenza",
      "UNAVAILABLE": "Non disponibile (modelli)",
      "EDIT_EXCEPTIONS": "Modifica le eccezioni",
      "ICAL_FEED": "Feed iCalendar",
      "ICAL_FEED_HELP": "Un feed iCalendar ti permetterà di visualizzare la tua pianificazione al di fuori di Rotaready.",
      "ICAL_FEED_TIP": "Dopo aver generato l'URL di un feed iCalendar, puoi copiarlo e incollarlo in <a href=\"https://support.apple.com/en-gb/HT202361\" target=\"_blank\">Apple Calendar</a>, <a href=\"https://support.google.com/calendar/answer/37100\" target=\"_blank\">Google Calendar</a> o qualsiasi altro software di calendario.",
      "COPIED": "Copiato negli appunti!",
      "GENERATE": "Genera"
    }
  },
  "ATTENDANCE": {
    "VALIDATION_CODES": {
      "CODE_1": "La data corrispondente è stata cancellata, quindi non possiamo modificarla.",
      "CODE_2": "Questa operazione comporterebbe una sequenza di eventi non valida. Ad esempio, un orario deve avvenire dopo un orario di entrata, ma non può precedere un altro orario. Controlla gli eventi circostanti e riprova.",
      "CODE_3": "Il sito/reparto scelto non è valido.",
      "CODE_4": "Non possiamo aggiungere un orario qui poiché il dipendente prescelto non ha un appuntamento in questa data.",
      "CODE_5": "Non possiamo aggiungere un evento qui perché c'è già un evento esistente contemporaneamente.",
      "CODE_6": "Il dipendente scelto non è valido.",
      "CODE_7": "Questa modifica sposterebbe l'evento in un momento precedente rispetto all'evento precedente. Prova a scegliere un orario diverso o modifica prima l'evento precedente.",
      "CODE_8": "Questa modifica sposterebbe l'evento in un momento successivo rispetto all'evento che lo segue. Prova a scegliere un orario diverso o modifica prima l'evento successivo."
    },
    "COMMON": {
      "EVENT_ADD_SUCCESS": "L'evento è stato aggiunto con successo.",
      "ABSENCE_ADD_SUCCESS": "Sono stati contrassegnati come assenti.",
      "NO_CLOCK_IN_MODAL_TITLE": "Aggiungi evento",
      "NO_CLOCK_IN_MODAL_MESSAGE": "Cosa vorresti fare?",
      "NO_CLOCK_IN_MODAL_ADD_EVENT": "Aggiungi orologio",
      "NO_CLOCK_IN_MODAL_ADD_EVENT_DESC": "Aggiungere manualmente un record di presenze",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE": "Segna assente",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC": "Aggiungi un record di assenze come malattia",
      "UNMATCHED_MODAL_TITLE": "Partecipazione senza pari",
      "UNMATCHED_MODAL_MESSAGE": "Esistono due modi per risolvere il problema delle presenze senza pari:",
      "UNMATCHED_MODAL_ADD_SHIFT": "Aggiungi turno",
      "UNMATCHED_MODAL_ADD_SHIFT_DESC": "Crea un nuovo turno per riflettere ciò che ha funzionato",
      "UNMATCHED_MODAL_EDIT_EVENT": "Modifica la partecipazione",
      "UNMATCHED_MODAL_EDIT_EVENT_DESC": "Modifica i registri delle presenze se non sono corretti",
      "OVERTIME_MODAL_TITLE": "Rivedi gli straordinari",
      "OVERTIME_MODAL_MESSAGE_IN": "{{name}} è arrivato in anticipo e il turno ha rispettato una regola retributiva. Cosa vorresti fare?",
      "OVERTIME_MODAL_MESSAGE_OUT": "{{name}} è scaduto in ritardo e il turno ha rispettato una regola retributiva. Cosa vorresti fare?",
      "OVERTIME_MODAL_APPROVE": "Approva gli straordinari",
      "OVERTIME_MODAL_APPROVE_DESC": "{{name}} verrà pagato per {{duration}} minuti di straordinario",
      "OVERTIME_MODAL_REJECT": "Rifiuta gli straordinari",
      "OVERTIME_MODAL_REJECT_DESC": "{{name}} non verrà retribuito per gli straordinari prestati",
      "OVERTIME_UPDATE_ERROR": "Non possiamo aggiornare l'evento in questo momento. Per favore riprova.",
      "MODAL_USER_NOTES_LABEL": 'Note da {{name}}'
    },
    "BY_STAFF": {
      "FILTER_LABEL_DATE": "Data",
      "MANUAL_CLOCK_IN": "Orologio manuale",
      "EVENT_TYPE": "Evento",
      "RECORDED_TIME": "Tempo registrato",
      "METHOD": "Catturato da",
      "OPTIONS": "Opzioni",
      "TRUSTED_DEVICE": "Dispositivo affidabile",
      "MOBILE_APP": "App Rotaready",
      "MANUALLY_OVERRIDDEN": "Sovrascritto manualmente",
      "MANUAL_ENTRY": "Inserimento manuale",
      "DELETE": "Elimina",
      "NO_EVENTS": "Non ci sono eventi di partecipazione da mostrare.",
      "CLOCK_IN": "Orologio in",
      "CLOCK_OUT": "Orologio scaduto",
      "BREAK_ON": "In pausa",
      "BREAK_OFF": "Pausa fuori",
      "ERROR_LOADING_EVENTS": "Non possiamo caricare le presenze in questo momento. Per favore riprova.",
      "ERROR_DELETING": "Non possiamo eliminare la partecipazione in questo momento. Per favore riprova.",
      "DELETE_SUCCESS": "L'evento è stato eliminato con successo.",
      "ADD_SUCCESS": "L'evento è stato aggiunto con successo.",
      "LABEL_EDITED": "Modificato"
    },
    "ISSUES": {
      "TITLE": "Problemi di partecipazione",
      "FILTER_DATE": "Data",
      "FILTER_ISSUE_TYPE": "Tipo di problema",
      "FILTER_POSITION": "Ruolo lavorativo",
      "FILTER_USER": "Persona",
      "ISSUES_HELP": "I problemi devono essere risolti, altrimenti causeranno discrepanze nei report e nelle buste paga. Per impostazione predefinita, Rotaready segnala i problemi degli ultimi {{defaultIssuePeriod}} giorni.",
      "IS_THIS": "È questo",
      "YES": "sì",
      "NO": "No",
      "HEADING_NAME": "Nome",
      "HEADING_SITE": "Sito",
      "HEADING_ISSUE": "Problema",
      "HEADING_SHIFT": "Turno",
      "HEADING_START": "Inizio",
      "HEADING_FINISH": "Finitura",
      "HEADING_CLOCK_IN": "Orologio",
      "HEADING_CLOCK_OUT": "Orologio",
      "HEADING_OPTIONS": "Opzioni",
      "NOT_FOUND": "Non trovato",
      "RESOLVE": "Risolvere",
      "DISMISS": "Ignora",
      "NO_ATTENDANCE_ISSUES": "Non hai problemi di partecipazione, ben fatto!",
      "UNMATCHED_ATTENDANCE": "Partecipazione senza pari",
      "NO_CLOCK_IN": "Nessun clock-in",
      "NO_CLOCK_OUT": "Nessun clock-out",
      "SUSPECT_CLOCK_OUT": "Sospetto clock-out",
      "SUSPECT_ATTENDANCE": "Presenza sospetta",
      "BREAK_NOT_TAKEN": "Pausa non presa",
      "INVALID_BREAKS": "Interruzioni non valide",
      "SUSPECT_BREAK": "Break sospetto",
      "EARLY_CLOCK_IN": "Orologio anticipato",
      "LATE_CLOCK_OUT": "Ritardo del clockout",
      "UNMATCHED_ATTENDANCE_EXPLANATION": "Il Rotaready non è riuscito a trovare un turno all'altezza di questa partecipazione",
      "NO_CLOCK_IN_EXPLANATION": "Il membro dello staff non ha effettuato il check-in per il turno",
      "NO_CLOCK_OUT_EXPLANATION": "Il membro dello staff non è uscito dal lavoro dopo il turno",
      "SUSPECT_CLOCK_OUT_EXPLANATION": "Il membro dello staff è uscito molto più tardi dell'orario di arrivo previsto",
      "SUSPECT_ATTENDANCE_EXPLANATION": "Il membro dello staff è stato bloccato per un periodo di tempo molto più breve del previsto",
      "BREAK_NOT_TAKEN_EXPLANATION": "Il turno ha una pausa, ma una non è stata presa",
      "INVALID_BREAKS_EXPLANATION": "Le interruzioni relative a questa frequenza sono incomplete (cioè manca un'interruzione)",
      "SUSPECT_BREAK_EXPLANATION": "Il membro dello staff è stato in pausa molto più a lungo del previsto",
      "EARLY_CLOCK_IN_EXPLANATION": "Il turno corrisponde a una regola retributiva che richiede l'approvazione per un orario anticipato",
      "LATE_CLOCK_OUT_EXPLANATION": "Il turno corrisponde a una regola retributiva che richiede l'approvazione in caso di ritardo",
      "ERROR_LOADING_ATTENDANCE": "Non possiamo caricare i tuoi problemi di partecipazione in questo momento. Riprova.",
      "SOMETHING_WENT_WRONG": "Ops, qualcosa è andato storto. Potrebbe essere necessario riprovare!",
      "CONFIRM_DISMISS": "Sei sicuro di voler eliminare questo problema di partecipazione?",
      "DATE_RANGE_TOO_WIDE": "Non puoi scegliere più di 5 settimane. Limita l'intervallo di date.",
      "CLOCK_OUT_DAYS_LATER": "Questo clock-out è uno o più giorni dopo il clock-in."
    },
    "OVERVIEW": {
      "UNMATCHED_LABEL": "{{count}} impareggiabile",
      "OPTIONS_DAILY": "Quotidiano",
      "OPTIONS_WEEKLY": "Settimanale",
      "OPTIONS_DAY_ROLL_OVER": "Roll-over giornaliero",
      "FILTER_POSITION": "Ruolo lavorativo",
      "FILTER_USER": "Persona",
      "MIDNIGHT": "mezzanotte",
      "TOOLS": "Utensili",
      "CURRENTLY_CLOCKED_IN": "Persone attualmente registrate",
      "ATTENDANCE_HELP": "La frequenza viene automaticamente abbinata ai turni se si verifica un clock-in o clock-out entro {<b>{automatchThreshold}} ora/e da un orario di inizio o di fine pianificato</b>.",
      "MATCHED_HEADING_SCHEDULED": "Pianificato",
      "MATCHED_HEADING_ACTUAL": "Effettivo",
      "MATCHED_HEADING_AFTER_SANITISATION": "Dopo la sanificazione",
      "MATCHED_HEADING_NAME": "Nome",
      "MATCHED_HEADING_SITE": "Sito",
      "MATCHED_HEADING_START": "Inizio",
      "MATCHED_HEADING_FINISH": "Finitura",
      "MATCHED_HEADING_PAID_HOURS": "Ore retribuite",
      "MATCHED_HEADING_UNPAID_HOURS": "Ore non retribuite",
      "COVER_SHIFT": "Cambio di copertura",
      "NO_CLOCK_IN": "Ancora nessun clock-in",
      "NO_CLOCK_OUT": "Ancora nessun clock-out",
      "OVERTIME_APPROVED": "Approvato",
      "OVERTIME_REJECTED": "Respinto",
      "OVERTIME_REVIEW": "Recensione",
      "OVERTIME_APPROVED_BY": "Straordinario approvato da {{name}}",
      "OVERTIME_REJECTED_BY": "Tempo straordinario rifiutato da {{name}}",
      "MATCHED_NO_RESULTS": "Non ci sono turni da mostrare.",
      "UNMATCHED_ATTENDANCE": "Partecipazione senza pari",
      "UNMATCHED_ATTENDANCE_HELP": "Se la frequenza non rientra nelle <b>{{automatchThreshold} ore dall'orario di inizio/fine di un turno, dovrai modificare manualmente il turno o i</b> record delle presenze per allinearli:",
      "UNMATCHED_HEADING_NAME": "Nome",
      "UNMATCHED_HEADING_SITE": "Sito",
      "UNMATCHED_HEADING_DATE": "Data",
      "UNMATCHED_HEADING_CLOCK_IN": "Orologio in",
      "UNMATCHED_HEADING_CLOCK_OUT": "Orologio scaduto",
      "UNMATCHED_HEADING_OPTIONS": "Opzioni",
      "NO_UNMATCHED_ATTENDANCE": "Non c'è una partecipazione senza pari qui. Bel lavoro!",
      "NONE_YET": "Ancora nessuna",
      "RESOLVE": "Risolvere",
      "ERROR_LOADING_ATTENDANCE": "Non possiamo caricare la cronologia delle tue presenze in questo momento. Per favore riprova.",
      "TIMELINESS_EARLY_TOOLTIP": "{{duration}} minuti in anticipo",
      "TIMELINESS_LATE_TOOLTIP": "{{duration}} minuti di ritardo",
      "TRUNCATED_RESULT_MESSAGE": "C'è altro da vedere, ma per far sì che le cose funzionino senza intoppi abbiamo dovuto troncare il tuo risultato. <br />Prova a filtrare per ruolo professionale, per sito/reparto o riduci l'intervallo di date."
    },
    "VIEW_EVENT_MODAL": {
      "VIEW_ATTENDANCE": "Visualizza le presenze",
      "STAFF_MEMBER": "Chi",
      "EVENT_TYPE": "Evento",
      "RECORDED_TIME": "Tempo registrato",
      "METHOD": "Catturato da",
      "TRUSTED_DEVICE": "Dispositivo affidabile",
      "MOBILE_APP": "App Rotaready",
      "MANUALLY_OVERRIDDEN": "Sovrascritto manualmente",
      "MANUAL_ENTRY": "Inserimento manuale",
      "ON": "sul",
      "OVERTIME": "Ore straordinari",
      "APPROVED": "Approvato",
      "REJECTED": "Respinto",
      "FACIAL_RECOGNITION": "riconoscimento facciale",
      "FACIAL_RECOGNITION_PROCESS": "Le immagini vengono generalmente elaborate entro alcuni minuti dall'acquisizione.",
      "FACIAL_RECOGNITION_WAITING": "In attesa di elaborazione...",
      "TRUSTS_PHOTO": "Rotaready si fida di questa foto",
      "NO_TRUSTS_PHOTO": "Rotaready non si fida di questa foto",
      "CONFIDENT": "confidente",
      "UNABLE_TO_IDENTIFY": "Incapace di identificare chiaramente un volto",
      "PROCESSED_ON": "Elaborato il",
      "ACKNOWLEDGED_BY": "Riconosciuto da {{name}} il",
      "REFERENCE_IMAGE": "Immagine di riferimento",
      "REFERENCE_IMAGE_HELP": "Questa immagine non verrà elaborata, ma utilizzata come riferimento per il confronto",
      "EDIT": "Modifica",
      "CLOSE": "Chiudi",
      "TAB_DETAILS": "Dettagli",
      "TAB_PHOTO": "Foto",
      "TAB_LOCATION": "Posizione",
      "CLOCK_IN": "Orologio in",
      "CLOCK_OUT": "Orologio scaduto",
      "BREAK_ON": "Interrompi",
      "BREAK_OFF": "Interrompere",
      "HAPPENED_HERE": "è successo qui",
      "TAB_AUDIT": "Storia",
      "AUDIT_TABLE_TIME": "Orario di partecipazione",
      "AUDIT_TABLE_OVERTIME": "Ore straordinari",
      "AUDIT_TABLE_WHO": "Registrato da",
      "NOTES": "Note"
    },
    "EDIT_EVENT_MODAL": {
      "TITLE": "Modifica la partecipazione",
      "CLOCK_IN": "Orologio in",
      "CLOCK_OUT": "Orologio scaduto",
      "BREAK_ON": "Interrompi",
      "BREAK_OFF": "Interrompere",
      "ERROR_UPDATING": "Non possiamo aggiornare l'evento in questo momento. Per favore riprova.",
      "ERROR_DELETING": "Non possiamo eliminare l'evento in questo momento. Per favore riprova.",
      "CONFIRM_DELETE": "Sei sicuro di voler eliminare questo evento?\nSe si tratta di un clock-in, verrà rimosso anche il clock-out corrispondente.",
      "PRIOR_EVENT_INFO": "L'evento precedente è un <b>{{eventType}} in {{date|momentFormat: 'll - <b>LT'</b>}}</b>",
      "PRIOR_EVENT_CONFLICT": "C'è già un <b>{{eventType}} in {{<b>date|momentFormat: 'll - LT'</b>}}</b>. Dovrai selezionare un orario diverso.",
      "LABEL_WHO": "Chi",
      "LABEL_EVENT": "Evento",
      "LABEL_DATE": "Data e ora",
      "DATE_IN_FUTURE": "Questo tempo non è ancora passato!",
      "LABEL_OVERTIME": "Ore straordinari",
      "APPROVE": "Approvare",
      "REJECT": "Rifiuta",
      "LABEL_OVERTIME_HELP": "Il Rotaready ha identificato alcuni straordinari abbinando questo evento di partecipazione a un turno. Se lo approvi, gli straordinari verranno pagati secondo la regola retributiva del turno.",
      "DELETE": "Elimina",
      "CANCEL": "Annulla",
      "SAVE": "Salva modifiche"
    },
    "ADD_EVENT_MODAL": {
      "TITLE": "Aggiungi partecipazione",
      "CLOCK_IN": "Orologio in",
      "CLOCK_OUT": "Orologio scaduto",
      "BREAK_ON": "Interrompi",
      "BREAK_OFF": "Interrompere",
      "ERROR_ACTIONING": "Non possiamo aggiungere l'evento in questo momento. Riprova.",
      "PRIOR_EVENT_INFO": "L'evento precedente è un <b>{{eventType}} in {{date|momentFormat: 'll - <b>LT'</b>}}</b>",
      "PRIOR_EVENT_CONFLICT": "C'è già un <b>{{eventType}} in {{<b>date|momentFormat: 'll - LT'</b>}}</b>. Dovrai selezionare un orario diverso.",
      "LABEL_EVENT": "Evento",
      "LABEL_DATE": "Data e ora",
      "LABEL_BREAK": "Durata della pausa",
      "LABEL_BREAK_HELP": "Una pausa verrà aggiunta per te dopo la durata scelta.",
      "DATE_IN_FUTURE": "Questo tempo non è ancora passato!",
      "CANCEL": "Annulla",
      "SAVE": "Aggiungi partecipazione",
      "BREAK_MINUTES": "verbale"
    }
  },
  "DIALOG": {
    "TEXTBOX_DIALOG": {
      "CANCEL": "Annulla"
    },
    "TWO_BUTTON_DIALOG": {
      "CANCEL": "Annulla"
    }
  },
  "OTHER": {
    "EDIT_PREFERENCES_MODAL": {
      "TITLE": "Aggiorna le preferenze",
      "SUBTITLE": "Informazioni di contatto",
      "SUBTITLE_HELP": "Se dimentichi la password, possiamo aiutarti a reimpostarla solo se abbiamo modi alternativi per contattarti",
      "EMAIL_LABEL": "Indirizzo email",
      "EMAIL_PLACEHOLDER": "Nessuno fornito",
      "EMAIL_HELP": "Fornisci un indirizzo email valido.",
      "MOBILE_LABEL": "Telefono cellulare",
      "MOBILE_PLACEHOLDER": "Nessuno fornito",
      "MOBILE_HELP": "Fornisci un numero di cellulare valido o nessun numero.",
      "ACCOUNT_INFO": "Informazioni sull'account",
      "ACCOUNT_INFO_HELP": "Qui puoi impostare la tua lingua",
      "LANGUAGE_LABEL": "Lingua",
      "LANGUAGE_HELP": "Rotaready verrà visualizzato nella lingua scelta.",
      "OTHER_SETTINGS_TITLE": "Altre impostazioni",
      "CLOCK_IN_LABEL": "PIN di clock-in",
      "CLOCK_IN_HELP": "Se ti viene richiesto di inserire un codice PIN per l'entrata/uscita, puoi inserirne uno nuovo qui. Deve contenere 4 cifre.",
      "NOTIFICATIONS_TITLE": "Notifiche",
      "NOTIFICATIONS_HELP": "Controlla come desideri ricevere notifiche sugli eventi a cui sei iscritto ",
      "MANAGE_SUBSCRIPTIONS": "gestire gli abbonamenti",
      "ROTA_UPDATES": "Aggiornamenti Rota",
      "CRITIAL_SHIFT": "Aggiornamenti critici sui turni",
      "EMAIL": " E-mail",
      "MOBILE_APP": " App per dispositivi mobili",
      "SHIFT_UPDATED": "Quando un turno che lavorerai nelle prossime 24 ore è stato aggiornato",
      "REGULAR_SHIFT": "Aggiornamenti regolari sui turni",
      "SHIFT_UPDATED_BEYOND": "Aggiornamenti per eventuali turni in cui lavorerai oltre le prossime 24 ore",
      "WEEKLY_SUMMARY": "Riepilogo settimanale",
      "SMS": " SMS",
      "UPCOMING_WEEK": "Un riepilogo dei tuoi turni per la prossima settimana, inviato la sera prima dell'inizio della settimana",
      "APPROVAL": "Approvazione",
      "APPROVAL_HELP": "Quando una rotazione è contrassegnata come pronta per l'approvazione",
      "PUBLISHING": "Editoria",
      "PUBLISHING_HELP": "Quando una rotazione è pubblicata o non pubblicata",
      "SHIFT_SWAPS": "Scambi di turni",
      "SWAP_UPDATES": "Aggiornamenti di scambio ",
      "REQUESTED": "Richiesto",
      "REQUESTED_HELP": "Quando qualcuno richiede un nuovo cambio di turno",
      "ACCEPTED": "Accettato",
      "ACCEPTED_HELP": "Quando qualcuno accetta uno scambio di turni",
      "APPROVED": "Approvato",
      "APPROVED_HELP": "Quando uno scambio di turni viene approvato dalla direzione",
      "REJECTED": "Respinto",
      "REJECTED_HELP": "Quando uno scambio di turni viene rifiutato dalla direzione",
      "CANCELLED": "Annullato",
      "CANCELLED_HELP": "Quando uno scambio di turni viene annullato",
      "ABSENCE_UPDATES": "Aggiornamenti sulle assenze/festività",
      "ABSENCE_REQUESTED": "Assenza richiesta",
      "ABSENCE_REQUESTED_HELP": "Quando viene richiesta una nuova assenza",
      "ABSENCE_UPDATE": "Aggiornamento delle assenze",
      "ABSENCE_UPDATE_HELP": " Quando una richiesta di assenza viene approvata, rifiutata o annullata dalla direzione",
      "COST_CONTROL": "Controllo dei costi",
      "SIGN_OFF": "Disconnetti",
      "SIGN_OFF_HELP": "Quando un sito chiude una settimana",
      "SIGN_OFF_REVERSE": "Disconnessione invertita",
      "SIGN_OFF_REVERSE_HELP": "Quando un sito annulla la disconnessione per una settimana",
      "ADMIN": "Amministratore",
      "ACCOUNT_APPROVAL": "Approvazione dell'account",
      "ACCOUNT_APPROVAL_HELP": "Quando un account è in attesa di approvazione o è stato approvato",
      "DOCUMENTS": "Documenti",
      "DOCUMENTS_HELP": "Quando un documento è scaduto o scadrà a breve",
      "OTHER": "Altro",
      "COMPANY_ANNOUNCEMENTS": "Annunci aziendali",
      "COMPANY_HELP": "Annunci importanti del tuo datore di lavoro",
      "DAILY_ADMIN_UPDATE": "Aggiornamento giornaliero dell'amministratore",
      "DAILY_UPDATE_HELP": "Ti informeremo in caso di problemi di partecipazione o se ci sono richieste di assenze o cambi di turni in attesa di approvazione.",
      "PHONE_ONLY": "Le notifiche con questa icona sono disponibili solo con le app Rotaready per iPhone e Android.",
      "CHANGE_PASSWORD": "Cambia password",
      "SAVE": "Salva",
      "SAVING": "Salvataggio...",
      "CANCEL": "Annulla"
    },
    "MANAGE_SUBSCRIPTIONS_MODAL": {
      "TITLE": "Gestisci gli abbonamenti",
      "HELP": "I tuoi abbonamenti controllano ciò di cui desideri ricevere notifiche.",
      "UNSUBSCRIBE_ALL": "Annulla l'iscrizione a tutti",
      "NOTIFY_WHEN": "Avvisami quando {{descriptor}}",
      "UNSUBSCRIBE_TOOLTIP": "Annulla l'iscrizione",
      "UNSUBSCRIBE_ALL_TOOLTIP": "Annulla l'iscrizione a tutti",
      "ACTION_IN": "{{azione}} in ",
      "FILTER_NO_SUBSCRIPTIONS": "Non hai abbonamenti attivi in {{name}}.",
      "ALL_NO_SUBSCRIPTIONS": "Non hai abbonamenti attivi.",
      "CLOSE": "Chiudi"
    },
    "RELEASE_REFRESH_MODAL": {
      "TITLE": "Aggiornamento Rotaready",
      "BODY": "Abbiamo appena rilasciato un aggiornamento per Rotaready che richiede di ricaricare la pagina. Fai clic sul pulsante qui sotto.",
      "REFRESH": "Aggiorna pagina"
    },
    "EDIT_AVATAR_MODAL": {
      "TITLE": "Foto del profilo",
      "INTRO_COPY": "Un'immagine aiuta le persone a riconoscerti e ti fa sapere quando hai effettuato l'accesso al tuo account.",
      "BUTTON_CHANGE": "Carica una nuova foto",
      "BUTTON_CANCEL": "Annulla",
      "BUTTON_SAVE": "Salva",
      "ALERT_REMOVE": "Sei sicuro di voler rimuovere questa immagine del profilo?",
      "ALERT_FILE_TYPE_NOT_ALLOWED": "Questo tipo di file non è consentito.",
      "ALERT_ERROR_500": "Non siamo riusciti ad aggiornare la tua foto in quel momento. Per favore riprova.",
      "ALERT_SUCCESS_ADD": "La tua immagine del profilo è stata aggiornata. L'aggiornamento di tutti i servizi del Rotaready potrebbe richiedere un po' di tempo.",
      "ALERT_SUCCESS_REMOVE": "La tua immagine del profilo è stata rimossa. L'aggiornamento di tutti i servizi del Rotaready potrebbe richiedere un po' di tempo."
    }
  },
  "REPORTS": {
    "INDEX_LIST": {
      "TITLE": "Rapporti",
      "TRONC_STATUS": "Stato del tronco",
      "CURRENTLY_CLOCKED_IN": "Persone attualmente registrate",
      "ROTA_PROGRESS": "Progresso della rotazione",
      "SIGN_OFF_PROGRESS": "Progresso della disattivazione",
      "PAYROLL_EXPORT": "Esportazione delle buste paga",
      "LABOUR_BREAKDOWN": "Ripartizione della manodopera",
      "WORKING_TIME_VIOLATIONS": "Violazioni dell'orario di lavoro",
      "CONTRACTUAL_OBLIGATION_SUMMARY": "Riepilogo degli obblighi contrattuali",
      "DOCUMENT_SIGNATURES": "Firma dei documenti",
      "EXPIRING_DOCUMENTS": "Documenti in scadenza",
      "EMPLOYEE_EXPORT": "Dettagli del dipendente",
      "JOINERS_LEAVERS": "Falegnami e Leavers",
      "STAFF_TURNOVER": "Turnover del personale",
      "ANNIVERSARY": "Anniversari",
      "EMPLOYMENT_CHANGES": "Modifiche all'occupazione",
      "CATEGORY_HR": "RISORSE UMANE",
      "CATEGORY_DOCUMENTS": "Documenti",
      "CATEGORY_ROTAS": "Pianificazione",
      "CATEGORY_PAYROLL": "Libro paga",
      "CATEGORY_ATTENDANCE": "Frequenza",
      "CATEGORY_ABSENCE": "Assenza",
      "CATEGORY_COST_CONTROL": "Controllo dei costi",
      "ABSENCE_EXPORT": "Esportazione assenze",
      "ALLOWANCES_EXPORT": "Esportazione di quote",
      "UNTAKEN_HOLIDAY_COST": "Costo delle ferie non riscattato",
      "COST_CONTROL_RAW_DATA": "Esportazione di dati grezzi",
      "NEW_HIRE_TURNOVER": "Fatturato delle nuove assunzioni",
      "ABSENCE_DAYS_EXPORT": "Esportazione dei giorni di assenza",
      "SHIFT_TYPE_STREAM_BREAKDOWN": "Tipo di turno e suddivisione dello stream",
      "XERO_EXPORT": "Esporta in Xero",
      "DOCUMENT_PRESENCE": "Presenza del documento",
      "DOCUMENTS_EXPORT": "Esportazione di documenti",
      "ATTENDANCE_BREAKDOWN": "Ripartizione delle presenze",
      "LATENESS": "Ritardo",
      "LABOUR_BREAKDOWN_FILE_EXPORT": "Esportazione di file di ripartizione del lavoro",
      "CATEGORY_ASYNC_FILE_EXPORTS": "Rapporti grandi",
      "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": "Esportazione del file di ripartizione della manodopera",
      "PAYROLL_ASYNC_FILE_EXPORT": "Esportazione file buste paga",
      "RAW_COST_CONTROL_ASYNC_FILE_EXPORT": "Esportazione file di controllo dei costi grezzi"
    },
    "LATENESS": {
      "TITLE": "Ritardo",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "COLUMN_ID": "ID",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE_APPOINTMENT": "Sede (appuntamento)",
      "COLUMN_DEPT_APPOINTMENT": "Dipartimento (appuntamento)",
      "COLUMN_INSTANCES": "Istanze",
      "COLUMN_VARIANCE": "Varianza media",
      "FILTER_DATE": "Data",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_USER": "Persona",
      "FILTER_OVERTIME": "Ore straordinari",
      "FILTER_OVERTIME_NA": "Non riconosciuto",
      "FILTER_OVERTIME_APPROVED": "Approvato",
      "FILTER_OVERTIME_REJECTED": "Respinto",
      "FILTER_CLOCK_IN": "Orologio",
      "FILTER_CLOCK_OUT": "Orologio",
      "FILTER_MINS_LATE": "minuti in ritardo",
      "FILTER_MINS_EARLY": "minuti in anticipo",
      "LABEL_MINUTES": "verbale",
      "BUTTON_EXPORT": "Esporta",
      "NO_MATCH": "Non siamo riusciti a trovare alcun dato corrispondente ai tuoi filtri."
    },
    "ATTENDANCE_BREAKDOWN": {
      "TITLE": "Ripartizione delle presenze",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "COLUMN_ID": "ID",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE_APPOINTMENT": "Sede (appuntamento)",
      "COLUMN_DEPT_APPOINTMENT": "Dipartimento (appuntamento)",
      "COLUMN_SITE_ATTRIBUTION": "Sito (attribuzione)",
      "COLUMN_DEPT_ATTRIBUTION": "Dipartimento (attribuzione)",
      "COLUMN_DATE": "Data operativa",
      "COLUMN_SHIFT_TYPE": "Tipo di turno",
      "COLUMN_RECORD_TYPE": "Tipo di record",
      "COLUMN_SCHEDULED": "Pianificato",
      "COLUMN_ACTUAL": "Effettivo",
      "COLUMN_VARIANCE": "Varianza",
      "COLUMN_CAPTURED_BY": "Catturato da",
      "COLUMN_EDITED_BY": "A cura di",
      "COLUMN_EDITED_DATE": "Data di modifica",
      "COLUMN_OVERTIME_STATE": "Approvazione degli straordinari",
      "COLUMN_OVERTIME_EDITED_BY": "Tempo straordinario confermato da",
      "COLUMN_OVERTIME_EDITED_DATE": "Data confermata per gli straordinari",
      "FILTER_DATE": "Data",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_USER": "Persona",
      "FILTER_OVERTIME": "Ore straordinari",
      "FILTER_OVERTIME_NA": "Non riconosciuto",
      "FILTER_OVERTIME_APPROVED": "Approvato",
      "FILTER_OVERTIME_REJECTED": "Respinto",
      "FILTER_CLOCK_IN": "Orologio",
      "FILTER_CLOCK_OUT": "Orologio",
      "FILTER_MINS_LATE": "minuti in ritardo",
      "FILTER_MINS_EARLY": "minuti in anticipo",
      "BUTTON_EXPORT": "Esporta",
      "NO_MATCH": "Non siamo riusciti a trovare alcun dato corrispondente ai tuoi filtri."
    },
    "DOCUMENTS_EXPORT": {
      "TITLE": "Esportazione di documenti",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_TOGGLE_USER_ACTIVE": "Dipendenti attivi",
      "FILTER_USER": "Persona",
      "FILTER_CATEGORY": "Categoria",
      "FILTER_ACKNOWLEDGEMENT": "Status",
      "FILTER_OPTION_ACKNOWLEDGED": "Riconosciuto",
      "FILTER_OPTION_NOT_ACKNOWLEDGED": "Non riconosciuto",
      "BUTTON_EXPORT": "Esporta",
      "COLUMN_USER_ID": "ID dipendente",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE": "Sito",
      "COLUMN_DEPARTMENT": "Dipartimento",
      "COLUMN_CATEGORY": "Categoria",
      "COLUMN_FILE_NAME": "Nome",
      "COLUMN_FILE_SIZE": "Dimensione del file (KB)",
      "COLUMN_UPLOADED_BY": "Caricato da",
      "COLUMN_UPLOADED_DATE": "Data di caricamento",
      "COLUMN_EXPIRY_DATE": "Data di scadenza",
      "COLUMN_SIGNATURE_METHOD": "Metodo di firma",
      "COLUMN_EDITED_BY": "Ultima modifica di",
      "COLUMN_EDITED_DATE": "Data ultima modifica",
      "COLUMN_ACKNOWLEDGED": "Riconosciuto",
      "COLUMN_ACKNOWLEDGED_DATE": "Data riconosciuta",
      "COLUMN_NOTES": "Note",
      "NO_MATCH": "Non siamo riusciti a trovare dipendenti per i tuoi filtri."
    },
    "DOCUMENT_PRESENCE": {
      "TITLE": "Presenza del documento",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_TOGGLE_USER_ACTIVE": "Dipendenti attivi",
      "FILTER_CATEGORY": "Categoria",
      "BUTTON_EXPORT": "Esporta",
      "COLUMN_USER_ID": "ID dipendente",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE": "Sito",
      "COLUMN_DEPARTMENT": "Dipartimento",
      "COLUMN_EMPLOYMENT_ACTIVE": "Occupazione attiva",
      "COLUMN_JOIN_DATE": "Data di iscrizione",
      "COLUMN_LEAVE_DATE": "Data di partenza",
      "COLUMN_DOCUMENTS_VALIDITY": "Documenti validi",
      "NO_MATCH": "Non siamo riusciti a trovare dipendenti per i tuoi filtri."
    },
    "SHIFT_TYPE_STREAM_BREAKDOWN": {
      "TITLE": "Ripartizione del tipo di turno",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Salta a",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultime due settimane",
      "JUMP_TO_LAST_MONTH": "Il mese scorso",
      "FILTER_SITE": "Sito/dipartimento",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "FILTER_DATA_SOURCE": "Fonte dati",
      "FILTER_USER": "Persona",
      "FILTER_BEHAVIOUR": "Comportamento",
      "BEHAVIOUR_SHIFT_TYPE": "Tipo di turno",
      "BEHAVIOUR_STREAM": "Stream",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE": "Sito attuale",
      "COLUMN_DEPARTMENT": "Dipartimento attuale",
      "COLUMN_STAFF_GROUP": "Gruppo di personale attuale",
      "COLUMN_TOTAL": "Totale",
      "ROW_TOTAL": "Totale",
      "INFO_NO_DATA": "Non ci sono record che corrispondono ai tuoi filtri.",
      "INFO_NO_FILTER": "Seleziona un sito/reparto qui sopra per iniziare.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tariffa oraria",
      "PAY_AMOUNT_TYPE_DAILY": "Tariffa giornaliera",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salario annuo",
      "DATA_SOURCE_SHIFTS": "Turni",
      "DATA_SOURCE_MATCHED": "Igienizzato",
      "DATE_ERROR_DIFF": "Non puoi scegliere più di 6 settimane. Limita l'intervallo di date.",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "ERROR_400": "I tuoi parametri causano la generazione di troppi dati per un singolo report. Riduci l'ambito della tua richiesta e riprova.",
      "STREAM_UNALLOCATED": "Non allocato",
      "FILTER_METRIC": "Valore",
      "METRIC_WAGES": "Costo salariale base",
      "METRIC_HOURS": "Ore retribuite"
    },
    "ABSENCE_DAYS_EXPORT": {
      "TITLE": "Esportazione dei giorni di assenza",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "COLUMN_USER_ID": "ID dipendente",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE": "Sito",
      "COLUMN_DEPARTMENT": "Dipartimento",
      "COLUMN_STAFF_GROUP": "Gruppo di personale",
      "COLUMN_JOB_ROLE_NAME": "Ruolo lavorativo",
      "COLUMN_DATE": "Data",
      "COLUMN_HOURS": "Ore",
      "COLUMN_HEADER_ID": "ID dell'intestazione",
      "COLUMN_ABSENCE_TYPE": "Tipo",
      "COLUMN_ABSENCE_TYPE_REASON": "Motivo",
      "COLUMN_STATUS": "Status",
      "COLUMN_SCOPE": "Ambito",
      "COLUMN_START_DATE": "Data di inizio dell'intestazione",
      "COLUMN_END_DATE": "Data di fine dell'intestazione",
      "COLUMN_START_TIME": "Ora di inizio",
      "COLUMN_END_TIME": "Ora di fine",
      "COLUMN_TOTAL_HOURS": "Ore di intestazione",
      "COLUMN_TOTAL_DAYS": "Giorni di intestazione",
      "COLUMN_CANCELLED": "Annullato",
      "FILTER_DATE": "Data",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_STATUS": "Status",
      "FILTER_TYPE": "Tipo",
      "FILTER_USER": "Persona",
      "FILTER_TOGGLE_CANCELLED": "Includi annullato",
      "FILTER_TOGGLE_USER_ACTIVE": "Dipendenti attivi",
      "BUTTON_EXPORT": "Esporta",
      "NO_MATCH": "Non siamo riusciti a trovare alcuna assenza corrispondente ai tuoi filtri."
    },
    "NEW_HIRE_TURNOVER": {
      "TITLE": "Fatturato delle nuove assunzioni",
      "FILTER_DATE": "Data di iscrizione",
      "JUMP_TO": "Salta a",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultime due settimane",
      "JUMP_TO_LAST_MONTH": "Il mese scorso",
      "FILTER_AGGREGATION": "Aggregazione",
      "AGGREGATION_ENTITY_GROUP": "Sito/dipartimento",
      "AGGREGATION_POSITION": "Ruolo lavorativo",
      "AGGREGATION_STAFF_GROUP": "Gruppo di personale",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "DATE_ERROR_DIFF": "Non puoi scegliere più di 12 mesi. Limita l'intervallo di date.",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "COLUMN_AGGREGATION_LABEL": "Nome",
      "COLUMN_JOINERS": "Falegnami",
      "COLUMN_LEAVERS": "Leavers",
      "COLUMN_TURNOVER_RATE": "Tasso di fatturato",
      "DEPARTED_WITHIN": "Data di partenza",
      "DAYS_LABEL": "entro {{count}} giorni",
      "LABEL_TOTAL": "Totale",
      "LABEL_GRAND_TOTAL": "Totale complessivo",
      "FILTER_LEAVER_REASON": "Motivo del licenziamento"
    },
    "COST_CONTROL_RAW_DATA": {
      "TITLE": "Esportazione di dati grezzi con controllo dei costi",
      "ERROR_500": "Non siamo stati in grado di esportare i dati in quel momento. Per favore riprova.",
      "BUTTON_EXPORT": "Esporta",
      "LOADING_TEXT": "La tua esportazione è in fase di generazione.",
      "METRIC_HOURS": "Ore",
      "METRIC_WAGES": "Salari",
      "METRIC_SALES": "Vendite",
      "METRIC_COVERS": "Copertine",
      "SUBDIVISION_PREDICTION": "Pronostici",
      "SUBDIVISION_FORECAST": "Previsioni",
      "SUBDIVISION_ACTUAL": "I reali",
      "FILTER_METRICS": "Metriche",
      "FILTER_SUBDIVISIONS": "Suddivisioni",
      "FILTER_ENTITY": "Sito/dipartimento",
      "FILTER_ENTITY_BUTTON": "Sito/dipartimento",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Salta a",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultime due settimane",
      "JUMP_TO_LAST_MONTH": "Il mese scorso",
      "CALCULATE_ACTUAL_CHECKBOX": "Calcola le ore/i salari effettivi al volo, laddove non siano presenti dati firmati.",
      "DATE_ERROR_DIFF": "Non puoi scegliere più di 2 mesi. Limita l'intervallo di date.",
      "CALCULATE_ACTUAL_ERROR": "Le ore/i salari effettivi non possono essere calcolati al volo per più di una settimana o quindici siti/reparti."
    },
    "ABSENCE_EXPORT": {
      "TITLE": "Esportazione assenze",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "COLUMN_ID": "ID",
      "COLUMN_USER_ID": "ID dipendente",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE": "Sito",
      "COLUMN_DEPARTMENT": "Dipartimento",
      "COLUMN_STAFF_GROUP": "Gruppo di personale",
      "COLUMN_JOB_ROLE_NAME": "Ruolo lavorativo",
      "COLUMN_ABSENCE_TYPE": "Tipo",
      "COLUMN_ABSENCE_TYPE_REASON": "Motivo",
      "COLUMN_STATUS": "Status",
      "COLUMN_SCOPE": "Ambito",
      "COLUMN_START_DATE": "Data di inizio",
      "COLUMN_END_DATE": "Data di fine",
      "COLUMN_START_TIME": "Ora di inizio",
      "COLUMN_END_TIME": "Ora di fine",
      "COLUMN_TOTAL_HOURS": "Ore",
      "COLUMN_TOTAL_DAYS": "Giorni",
      "COLUMN_REQUEST_NAME": "Richiesto da",
      "COLUMN_REQUEST_DATE": "Data della richiesta",
      "COLUMN_REQUEST_MESSAGE": "Richiesta messaggio",
      "COLUMN_REVIEW_NAME": "Recensito da",
      "COLUMN_REVIEW_DATE": "Data della recensione",
      "COLUMN_REVIEW_MESSAGE": "Messaggio di revisione",
      "COLUMN_EDITED_NAME": "Ultima modifica di",
      "COLUMN_EDITED_DATE": "Data ultima modifica",
      "COLUMN_CANCELLED": "Annullato",
      "COLUMN_CANCELLED_NAME": "Annullato da",
      "COLUMN_CANCELLED_DATE": "Data annullata",
      "FILTER_DATE": "Data inizio/fine",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_STATUS": "Status",
      "FILTER_TYPE": "Tipo",
      "FILTER_USER": "Persona",
      "FILTER_TOGGLE_CANCELLED": "Includi annullato",
      "FILTER_TOGGLE_USER_ACTIVE": "Dipendenti attivi",
      "BUTTON_EXPORT": "Esporta",
      "NO_MATCH": "Non siamo riusciti a trovare alcuna assenza corrispondente ai tuoi filtri."
    },
    "ALLOWANCE_EXPORT": {
      "TITLE": "Esportazione di quote",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "COLUMN_ID": "ID",
      "COLUMN_USER_ID": "ID dipendente",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE": "Sito",
      "COLUMN_DEPARTMENT": "Dipartimento",
      "COLUMN_ABSENCE_TYPE": "Tipo di assenza",
      "COLUMN_YEAR_STARTS": "L'anno inizia",
      "COLUMN_EFFECTIVE_DATE": "Data di entrata in vigore",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Calendario degli accantonamenti",
      "COLUMN_UNIT": "Unità",
      "COLUMN_DEDUCTIBLE": "Deducibile",
      "COLUMN_TOTAL_ACCRUED": "Totale maturato",
      "COLUMN_OPENING_BALANCE": "Saldo iniziale",
      "COLUMN_BOOKED": "Prenotato",
      "COLUMN_BOOKED_AND_ELAPSED": "Prenotato e scaduto",
      "COLUMN_REMAINING": "Restante",
      "COLUMN_ACCRUAL_ESTIMATE": "Stima degli accantonamenti",
      "COLUMN_CARRIED_OVER": "Trasportato",
      "COLUMN_OVERSPEND_LIMIT": "Spendere troppo",
      "COLUMN_TOTAL_TO_ACCRUE": "Massimo da maturare",
      "COLUMN_ACCRUAL_RATE": "Sostituzione del tasso di competenza",
      "COLUMN_ADDED_NAME": "Aggiunto da",
      "COLUMN_ADDED_DATE": "Data aggiunta",
      "COLUMN_EDITED_NAME": "Ultima modifica di",
      "COLUMN_EDITED_DATE": "Data ultima modifica",
      "COLUMN_FINALISED": "Finalizzato",
      "COLUMN_FINALISED_REFERENCE_DATE": "Data di riferimento definitiva",
      "COLUMN_FINALISED_EDITED_NAME": "Finalizzato da",
      "COLUMN_FINALISED_EDITED_DATE": "Finalizzato il",
      "COLUMN_FINALISED_NOTES": "Note finalizzate",
      "COLUMN_PROJECT_TOTAL_ACCRUED": "Previsione maturata al momento della prenotazione",
      "FILTER_DATE": "Data di entrata in vigore",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_TYPE": "Tipo di assenza",
      "FILTER_SCHEDULE_OF_ACCRUAL": "Calendario degli accantonamenti",
      "FILTER_USER": "Persona",
      "FILTER_TOGGLE_USER_ACTIVE": "Dipendenti attivi",
      "BUTTON_EXPORT": "Esporta",
      "NO_MATCH": "Non siamo riusciti a trovare alcuna indennità corrispondente ai tuoi filtri."
    },
    "UNTAKEN_HOLIDAY_COST": {
      "TITLE": "Costo delle ferie non riscattato",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "ERROR_400": "L'intervallo di date di validità è troppo ampio, restringilo e riprova.",
      "BUTTON_EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "FILTER_DATE": "Data di validità dell'indennità",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_TOGGLE_USER_ACTIVE": "Dipendenti attivi",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE": "Sito",
      "COLUMN_DEPARTMENT": "Dipartimento",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Calendario degli accantonamenti",
      "COLUMN_UNIT": "Unità",
      "COLUMN_DEDUCTIBLE": "Deducibile",
      "COLUMN_TOTAL_ACCRUED": "Totale maturato",
      "COLUMN_BOOKED": "Prenotato",
      "COLUMN_BOOKED_AND_ELAPSED": "Trascorso",
      "COLUMN_UNTAKEN": "Inpreso",
      "COLUMN_PAY_AMOUNT": "Importo",
      "COLUMN_PAY_AMOUNT_TYPE": "Tipo",
      "COLUMN_PAY_PROJECTED_COST": "Costo previsto",
      "COLUMN_HEADER_ALLOWANCE": "Indennità",
      "COLUMN_HEADER_PAY": "Paga",
      "LABEL_DEDUCTIBLE_YES": "sì",
      "LABEL_DEDUCTIBLE_NO": "No",
      "TEXT_GRAND_TOTAL": "Totale complessivo"
    },
    "EMPLOYMENT_CHANGES": {
      "TITLE": "Modifiche all'occupazione",
      "MODEL_APPOINTMENT": "Appuntamento",
      "MODEL_PAY": "Paga",
      "MODEL_EMPLOYEE": "Dipendente",
      "PROPERTY_EMPLOYEE_FIRST_NAME": "Nome",
      "PROPERTY_EMPLOYEE_LAST_NAME": "Cognome",
      "PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET": "Ore medie impostate manualmente",
      "PROPERTY_APPOINTMENT_ENTITY_GROUP": "Sito",
      "PROPERTY_APPOINTMENT_ENTITY": "Dipartimento",
      "PROPERTY_APPOINTMENT_GROUP": "Gruppo di personale",
      "PROPERTY_APPOINTMENT_POSITION": "Ruolo lavorativo",
      "PROPERTY_APPOINTMENT_COMPANY": "Compagnia",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE": "Obbligo minimo",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT": "Obbligo minimo (unità)",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD": "Obbligo minimo (periodo)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE": "Obbligo massimo",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT": "Obbligo massimo (unità)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD": "Obbligo massimo (periodo)",
      "PROPERTY_APPOINTMENT_LINE_MANAGER": "Responsabile di linea",
      "PROPERTY_APPOINTMENT_PAYROLL_CALENDAR": "Calendario delle retribuzioni",
      "PROPERTY_PAY_AMOUNT": "Importo",
      "PROPERTY_PAY_AMOUNT_TYPE": "Tipo di importo",
      "PROPERTY_PAY_UPLIFT": "Aumento dei salari",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Salta a",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultime due settimane",
      "JUMP_TO_LAST_MONTH": "Il mese scorso",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_PROPERTY": "Proprietà",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "DATE_ERROR_DIFF": "Non puoi scegliere più di 12 mesi. Limita l'intervallo di date.",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE": "Sito",
      "COLUMN_DEPARTMENT": "Dipartimento",
      "COLUMN_CHANGE": "Cambia",
      "COLUMN_EFFECTIVE_DATE": "Efficace",
      "COLUMN_PROPERTY": "Proprietà",
      "COLUMN_NEW_VALUE": "Nuovo valore",
      "COLUMN_OLD_VALUE": "Valore vecchio",
      "COLUMN_EDITED_BY": "Modificato da",
      "COLUMN_EDITED_ON": "Data modificata"
    },
    "ANNIVERSARY": {
      "TITLE": "Anniversari",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Salta a",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultime due settimane",
      "JUMP_TO_LAST_MONTH": "Il mese scorso",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_TYPE": "Anniversario",
      "TYPE_BIRTHDAY": "Compleanno",
      "TYPE_EMPLOYMENT": "Anniversario di lavoro",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "DATE_ERROR_DIFF": "Non puoi scegliere più di 12 mesi. Limita l'intervallo di date.",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE": "Sito",
      "COLUMN_DEPARTMENT": "Dipartimento",
      "COLUMN_TYPE": "Anniversario",
      "COLUMN_YEARS": "Anni",
      "COLUMN_DATE": "Data"
    },
    "STAFF_TURNOVER": {
      "TITLE": "Turnover del personale",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Salta a",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultime due settimane",
      "JUMP_TO_LAST_MONTH": "Il mese scorso",
      "FILTER_AGGREGATION": "Aggregazione",
      "AGGREGATION_ENTITY_GROUP": "Sito/dipartimento",
      "AGGREGATION_POSITION": "Ruolo lavorativo",
      "AGGREGATION_STAFF_GROUP": "Gruppo di personale",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "DATE_ERROR_DIFF": "Non puoi scegliere più di 12 mesi. Limita l'intervallo di date.",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "COLUMN_AGGREGATION_LABEL": "Nome",
      "COLUMN_HEADCOUNT_START": "Numero di dipendenti (inizio)",
      "COLUMN_HEADCOUNT_END": "Numero di dipendenti (fine)",
      "COLUMN_JOINERS": "Falegnami",
      "COLUMN_LEAVERS": "Leavers",
      "COLUMN_REHIRES": "Riassunzioni",
      "COLUMN_TRANSFERS_IN": "Trasferimenti in",
      "COLUMN_TRANSFERS_OUT": "Trasferimenti in uscita",
      "COLUMN_ATTRITION_RATE": "Tasso di abbandono",
      "COLUMN_AVERAGE_SERVICE": "Servizio medio",
      "LABEL_TOTAL": "Totale",
      "LABEL_GRAND_TOTAL": "Totale complessivo",
      "FILTER_LEAVER_REASON": "Motivo del licenziamento"
    },
    "DOCUMENT_SIGNATURES": {
      "TITLE": "Firma dei documenti",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "INFO_NO_DATA": "Non ci sono record che corrispondono ai tuoi filtri.",
      "LABEL_METHOD_MANUAL": "Manuale",
      "LABEL_METHOD_ELECTRONIC": "Elettronico",
      "LABEL_STATUS_PENDING": "In sospeso",
      "LABEL_STATUS_REJECTED": "Respinto",
      "LABEL_STATUS_SIGNED": "Firmato",
      "LABEL_STATUS_CANCELLED": "Annullato",
      "LABEL_RESPONSE_NONE": "In attesa di risposta",
      "LABEL_RESPONSE_REJECTED": "Respinto",
      "LABEL_RESPONSE_SIGNED": "Firmato",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_RECIPIENT_USER": "Destinatario",
      "FILTER_ADDED_USER": "Mittente",
      "FILTER_METHOD": "Metodo di firma",
      "FILTER_STATUS": "Status",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "COLUMN_RECIPIENT": "Destinatario",
      "COLUMN_EMPLOYER": "Controparte",
      "COLUMN_DOCUMENT": "Documento",
      "COLUMN_DOCUMENT_CATEGORY": "Categoria di destinazione",
      "COLUMN_METHOD": "Metodo",
      "COLUMN_STATUS": "Status",
      "COLUMN_ADDED_USER": "Mittente",
      "COLUMN_ADDED_DATE": "Data",
      "EMPLOYER_NOT_SET": "Non richiesto",
      "VIEW_MODAL": {
        "TITLE": "Documento da firmare",
        "LABEL_FILE": "Documento",
        "LABEL_EXPIRY": "Scadenza del documento",
        "LABEL_TEMPLATE": "Modello utilizzato",
        "LABEL_CATEGORY": "Categoria di destinazione",
        "LABEL_ADDED_BY": "Aggiunto da",
        "LABEL_METHOD": "Metodo",
        "TEMPLATE_NOT_USED": "Nessuna",
        "EXPIRY_NOT_APPLICABLE": "Non applicabile",
        "BUTTON_CLOSE": "Chiudi",
        "HEADING_SIGNERS": "Firmatari",
        "EMPLOYEE": "Dipendente",
        "EMPLOYER": "datore di lavoro",
        "COLUMN_NAME": "Nome",
        "COLUMN_ROLE": "Ruolo",
        "COLUMN_STATUS": "Status",
        "COLUMN_DATE": "Data",
        "LABEL_OPTIONS": "Opzioni",
        "OPTION_REMINDER": "Inviare un promemoria",
        "OPTION_CANCEL": "Annulla documento",
        "OPTION_UPLOAD": "Carica documento firmato",
        "CANCEL_ERROR_500": "Non abbiamo potuto cancellare il documento in quel momento.",
        "CANCEL_SUCCESS": "Il documento è stato annullato.",
        "REMINDER_ERROR_500": "Non siamo riusciti a inviare un promemoria in quel momento.",
        "REMINDER_ERROR_400": "Un promemoria è già stato inviato di recente. Attendi qualche istante prima di inviare un altro promemoria.",
        "REMINDER_SUCCESS": "Capito! Ti invieremo subito un promemoria via email.",
        "DOWNLOAD_ERROR_500": "Non siamo riusciti a scaricare il file in quel momento. Riprova o contattaci se il problema persiste.",
        "LABEL_DOWNLOAD": "Scarica",
        "OPTION_DOWNLOAD_ORIGINAL": "Scarica l'originale",
        "OPTION_DOWNLOAD_SIGNED_COPY": "Scarica la copia firmata"
      }
    },
    "WORKING_TIME_VIOLATIONS": {
      "TITLE": "Violazioni dell'orario di lavoro",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Salta a",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultime due settimane",
      "JUMP_TO_LAST_MONTH": "Il mese scorso",
      "FILTER_SITE": "Sito/dipartimento",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "COLUMN_SITE_DEPARTMENT": "Sito/dipartimento",
      "COLUMN_VIOLATION_HOURS": "Ore lavorative",
      "COLUMN_VIOLATION_HOURS_TOOLTIP": "Il numero di casi in cui un dipendente ha superato le 48 ore lavorative (in media su 17 settimane).",
      "COLUMN_VIOLATION_DAYS_OFF": "Giorni liberi",
      "COLUMN_VIOLATION_DAYS_OFF_TOOLTIP": "Il numero di casi in cui un dipendente ha avuto meno di 2 giorni di ferie (in un periodo di 2 settimane).",
      "COLUMN_VIOLATION_REST": "Riposo",
      "COLUMN_VIOLATION_REST_TOOLTIP": "Il numero di casi in cui un dipendente ha avuto meno di 11 ore di riposo dopo una giornata lavorativa.",
      "COLUMN_VIOLATION_U18_DAYS_OFF": "U18: Giorni liberi",
      "COLUMN_VIOLATION_U18_DAYS_OFF_TOOLTIP": "Il numero di casi in cui un dipendente di età inferiore ai 18 anni ha avuto meno di 2 giorni liberi consecutivi (in una determinata settimana).",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH": "U18: Turno lungo",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH_TOOLTIP": "Il numero di casi in cui un dipendente di età inferiore ai 18 anni ha svolto un turno di lavoro più lungo di 8 ore.",
      "COLUMN_VIOLATION_U18_REST": "U18: Riposo",
      "COLUMN_VIOLATION_U18_REST_TOOLTIP": "Il numero di casi in cui un dipendente di età inferiore ai 18 anni ha avuto meno di 11 ore di riposo dopo una giornata lavorativa.",
      "COLUMN_TOTAL": "Totale",
      "ROW_TOTAL": "Totale",
      "ROW_GRAND_TOTAL": "Totale complessivo",
      "INFO_NO_FILTER": "Seleziona un sito/reparto qui sopra per iniziare.",
      "DATE_ERROR_DIFF": "Non puoi scegliere più di 12 mesi. Limita l'intervallo di date.",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "VIOLATION_TYPE_1_DESC": "Non può superare la media di 48 ore lavorative nell'arco di 17 settimane",
      "VIOLATION_TYPE_2_DESC": "Non può avere meno di 2 giorni liberi in un periodo di 2 settimane",
      "VIOLATION_TYPE_3_DESC": "Minori di 18 anni: deve avere 2 giorni liberi consecutivi a settimana",
      "VIOLATION_TYPE_4_DESC": "Minori di 18 anni: turno di lavoro superiore a 8 ore",
      "VIOLATION_TYPE_5_DESC": "Minori di 18 anni: meno di 12 ore di riposo dopo la giornata lavorativa",
      "VIOLATION_TYPE_6_DESC": "Meno di 11 ore di riposo dopo la giornata lavorativa",
      "VIOLATION_TYPE_1": "Troppe ore lavorative (in media)",
      "VIOLATION_TYPE_2": "Giorni liberi insufficienti",
      "VIOLATION_TYPE_3": "Minori di 18 anni: giorni liberi insufficienti",
      "VIOLATION_TYPE_4": "Minori di 18 anni: turni troppo lunghi",
      "VIOLATION_TYPE_5": "Minori di 18 anni: riposo insufficiente tra i turni",
      "VIOLATION_TYPE_6": "Riposo insufficiente tra i turni",
      "FILTER_VIOLATION_TYPE": "Tipo di violazione",
      "FILTER_PER_PAGE": "Righe per pagina",
      "DETAIL_COLUMN_ID": "ID",
      "DETAIL_COLUMN_HR_ID": "IL SUO ID",
      "DETAIL_COLUMN_PAYROLL_ID": "ID del libro paga",
      "DETAIL_COLUMN_FIRST_NAME": "Nome",
      "DETAIL_COLUMN_LAST_NAME": "Cognome",
      "DETAIL_COLUMN_SITE": "Sito",
      "DETAIL_COLUMN_DEPARTMENT": "Dipartimento",
      "DETAIL_COLUMN_VIOLATION": "Violazione",
      "DETAIL_COLUMN_DATE_FROM": "Data da",
      "DETAIL_COLUMN_DATE_TO": "Data a",
      "DETAIL_COLUMN_VALUE": "Valore",
      "DETAIL_LABEL_WORKING_HOURS": "ore lavorative",
      "DETAIL_LABEL_WORKING_DAYS": "giorni lavorativi",
      "DETAIL_LABEL_HOURS_REST": "ore di riposo",
      "DETAIL_INFO_NO_RECORDS": "Non hai violato l'orario di lavoro, ottimo lavoro!",
      "DETAIL_INFO_EMPTY_FILTER_RESULT": "Non ci sono violazioni corrispondenti ai tuoi filtri."
    },
    "CONTRACTUAL_OBLIGATION_SUMMARY": {
      "TITLE": "Riepilogo degli obblighi contrattuali",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Salta a",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultime due settimane",
      "JUMP_TO_LAST_MONTH": "Il mese scorso",
      "FILTER_SITE": "Sito/dipartimento",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "FILTER_DATA_SOURCE": "Fonte dati",
      "FILTER_USER": "Persona",
      "COLUMN_GROUP_TOTAL": "Totale (pagato)",
      "COLUMN_GROUP_OBLIGATION": "Impegno contrattuale",
      "COLUMN_GROUP_ANALYSIS_META": "Analizzato oltre",
      "COLUMN_GROUP_ANALYSIS": "Analisi",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE": "Sito",
      "COLUMN_DEPARTMENT": "Dipartimento",
      "COLUMN_DAYS_WORKED": "Giorni",
      "COLUMN_PAID_HOURS": "Ore",
      "COLUMN_AVERAGE_HOURS": "Media",
      "COLUMN_AVERAGE_HOURS_TOOLTIP": "La media delle ore lavorate al giorno.",
      "COLUMN_OBLIGATION_VALUE": "Valore",
      "COLUMN_OBLIGATION_UNIT": "Unità",
      "COLUMN_OBLIGATION_PERIOD": "Periodo",
      "COLUMN_ANALYSIS_START": "Inizio",
      "COLUMN_ANALYSIS_END": "Fine",
      "COLUMN_ANALYSIS_PERIODS": "# Periodi",
      "COLUMN_ANALYSIS_PERIODS_TOOLTIP": "Il numero di settimane o mesi che sono stati analizzati.",
      "COLUMN_ANALYSIS_AVERAGE": "Media",
      "COLUMN_ANALYSIS_AVERAGE_TOOLTIP": "Il numero medio di ore o giorni lavorati.",
      "COLUMN_ANALYSIS_DIFFERENCE": "Diff.",
      "COLUMN_ANALYSIS_DIFFERENCE_TOOLTIP": "La differenza tra le ore/giorni minimi che avrebbero dovuto essere lavorati (l'obbligazione contrattuale moltiplicata per il numero di periodi) e ciò che è stato effettivamente lavorato (il totale delle ore o dei giorni).",
      "INFO_NO_DATA": "Non ci sono record che corrispondono ai tuoi filtri.",
      "INFO_NO_FILTER": "Seleziona un sito/reparto qui sopra per iniziare.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tariffa oraria",
      "PAY_AMOUNT_TYPE_DAILY": "Tariffa giornaliera",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salario annuo",
      "DATA_SOURCE_SHIFTS": "Turni",
      "DATA_SOURCE_ATTENDANCE": "Frequenza",
      "DATA_SOURCE_MATCHED": "Igienizzato",
      "CONTRACTUAL_UNIT_HOURS": "Ore",
      "CONTRACTUAL_UNIT_DAYS": "Giorni",
      "CONTRACTUAL_PERIOD_WEEK": "Settimana",
      "CONTRACTUAL_PERIOD_MONTH": "Mese",
      "CONTRACTUAL_PERIOD_WEEKLY": "Settimanale",
      "CONTRACTUAL_PERIOD_MONTHLY": "Mensile",
      "DATE_ERROR_DIFF": "Non puoi scegliere più di 14 settimane. Limita l'intervallo di date.",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova."
    },
    "LABOUR_BREAKDOWN": {
      "TITLE": "Ripartizione della manodopera",
      "FILE_EXPORT_TITLE": "Esportazione di file di ripartizione del lavoro",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Salta a",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultime due settimane",
      "JUMP_TO_LAST_MONTH": "Il mese scorso",
      "FILTER_SITE": "Sito/dipartimento",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "FILTER_DATA_SOURCE": "Fonte dati",
      "FILTER_USER": "Persona",
      "FILTER_BEHAVIOUR": "Comportamento",
      "BEHAVIOUR_COST_CONTROL": "Controllo dei costi",
      "BEHAVIOUR_PAYROLL": "Libro paga",
      "FILTER_SHIFT_TYPE": "Tipo di turno",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE_APPOINTMENT": "Sede (appuntamento)",
      "COLUMN_DEPT_APPOINTMENT": "Dipartimento (appuntamento)",
      "COLUMN_EVENT_TYPE": "Tipo di evento",
      "COLUMN_SHIFT_TYPE": "Tipo di turno",
      "COLUMN_SITE_ATTRIBUTION": "Sito (attribuzione)",
      "COLUMN_DEPT_ATTRIBUTION": "Dipartimento (attribuzione)",
      "COLUMN_START_TIME": "Inizio",
      "COLUMN_END_TIME": "Finitura",
      "COLUMN_PAID_HOURS": "Ore retribuite",
      "COLUMN_UNPAID_HOURS": "Ore non retribuite",
      "COLUMN_BASE_PAY": "Retribuzione base",
      "COLUMN_ROLLED_UP_HOLIDAY": "Ferie maturate",
      "COLUMN_TAXES": "Imposte",
      "COLUMN_WAGE_UPLIFT": "Aumento dei salari",
      "COLUMN_TOTAL_COST": "Costo totale",
      "INFO_NO_DATA": "Non ci sono record che corrispondono ai tuoi filtri.",
      "INFO_NO_FILTER": "Seleziona un sito/reparto qui sopra per iniziare.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tariffa oraria",
      "PAY_AMOUNT_TYPE_DAILY": "Tariffa giornaliera",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salario annuo",
      "EVENT_TYPE_SHIFT": "Turno",
      "EVENT_TYPE_ABSENCE": "Assenza",
      "EVENT_TYPE_SALARY": "Salario",
      "EVENT_TYPE_TAXES": "Imposte",
      "DATA_SOURCE_SHIFTS": "Turni",
      "DATA_SOURCE_ATTENDANCE": "Frequenza",
      "DATA_SOURCE_MATCHED": "Igienizzato",
      "DATE_ERROR_DIFF": "Non puoi scegliere più di 14 settimane. Limita l'intervallo di date.",
      "DATE_WARNING_WEEKS": "Hai selezionato un intervallo di date che non è allineato alle settimane di calendario. Alcune imposte del datore di lavoro sono calcolate utilizzando soglie settimanali, pertanto le imposte visualizzate qui potrebbero essere artificialmente inferiori alla realtà. La retribuzione base e le ferie maturate rimangono corrette, ma allinea le date alle settimane di calendario per le tasse e il costo totale corretti.",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "ERROR_400": "I tuoi parametri causano la generazione di troppi dati per un singolo report. Riduci l'ambito della tua richiesta e riprova."
    },
    "LABOUR_BREAKDOWN_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Genera un report via email.",
      "REPORT_GENERATED": "Il rapporto è in fase di generazione e ti verrà inviato via email una volta completato."
    },
    "PAYROLL_EXPORT": {
      "TITLE": "Esportazione delle buste paga",
      "FILTER_DATE": "Data",
      "JUMP_TO": "Salta a",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_LAST_TWO_WEEKS": "Ultime due settimane",
      "JUMP_TO_LAST_MONTH": "Il mese scorso",
      "FILTER_SITE": "Sito/dipartimento",
      "EXPORT": "Esporta",
      "EXPORT_PAYROLL_SOFTWARE": "Software per le buste paga",
      "EXPORT_PRINT": "Stampa",
      "FILTER_AGGREGATION": "Segmentazione",
      "FILTER_ABSENCE_TYPES": "Tipi di assenza",
      "FILTER_PAY_ELEMENT_TYPES": "Tipi di elementi di pagamento",
      "FILTER_USER": "Persona",
      "FILTER_COMPANY": "Azienda",
      "TOGGLE_SHOW_ROLLED_UP_HOLIDAY": "Mostra festività programmata",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IL SUO ID",
      "COLUMN_PAYROLL_ID": "ID del libro paga",
      "COLUMN_FIRST_NAME": "Nome",
      "COLUMN_LAST_NAME": "Cognome",
      "COLUMN_SITE_ATTRIBUTION": "Sito (attribuzione)",
      "COLUMN_DEPT_ATTRIBUTION": "Dipartimento (attribuzione)",
      "COLUMN_SITE_APPOINTMENT": "Sede (appuntamento)",
      "COLUMN_DEPT_APPOINTMENT": "Dipartimento (appuntamento)",
      "COLUMN_GROUP": "Gruppo",
      "COLUMN_POSITION": "Ruolo lavorativo",
      "COLUMN_DAYS_WORKED": "Giorni lavorati",
      "COLUMN_PAY_RATE": "Percentuale di pagamento",
      "COLUMN_AMOUNT_TYPE": "Tipo di importo",
      "COLUMN_PAY_TYPE": "Tipo di pagamento",
      "COLUMN_PAID_SHIFT_HOURS": "Ore di turno retribuite",
      "COLUMN_UNPAID_SHIFT_HOURS": "Ore di turno non retribuite",
      "COLUMN_BASE_PAY": "Retribuzione base",
      "COLUMN_ROLLED_UP_HOLIDAY": "Vacanze arrotolate",
      "COLUMN_ABSENCE_DAYS": "{{label}} giorni",
      "COLUMN_ABSENCE_HOURS": "{{label}} ore",
      "INFO_NO_DATA": "Non ci sono record che corrispondono ai tuoi filtri. Ti sei disconnesso? Non dimenticare che questo rapporto utilizza solo dati disconnessi.",
      "INFO_NO_FILTER": "Seleziona un sito/reparto qui sopra per iniziare.",
      "PAY_AMOUNT_TYPE_HOURLY": "Tariffa oraria",
      "PAY_AMOUNT_TYPE_DAILY": "Tariffa giornaliera",
      "PAY_AMOUNT_TYPE_ANNUAL": "Salario annuo",
      "AGGREGATION_OPTION_ATTRIBUTION": "Il sito/dipartimento a cui sono state attribuite le ore",
      "AGGREGATION_OPTION_APPOINTMENT": "Il sito/dipartimento in cui la persona si trovava in quel momento",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "ABSENCE_TYPE_DELETED_SUFFIX": "Eliminato",
      "DATE_ERROR_MIN": "Seleziona una data successiva al 12 dicembre 2018.",
      "DATE_ERROR_DIFF": "Non puoi scegliere più di 6 mesi. Limita l'intervallo di date.",
      "EXPORT_MODAL": {
        "TITLE": "Esportazione nel software di gestione delle retribuzioni",
        "LABEL_EXPORT_TO": "Esporta in",
        "BUTTON_DOWNLOAD": "Scarica",
        "BUTTON_CLOSE": "Chiudi",
        "SUCCESS": "L'esportazione è stata scaricata con successo.",
        "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova."
      },
      "SIGN_OFF_STATUS_COMPLETE": "Completamente disattivato",
      "SIGN_OFF_STATUS_NONE": "Non disattivato",
      "SIGN_OFF_STATUS_PARTIAL": "Parzialmente firmato",
      "SIGN_OFF_STATUS_DRAWER": {
        "TITLE": "Stato di disconnessione",
        "BACK_TO_DATES": "Torna alle date"
      },
      "FILTER_PAYROLL_CALENDAR": "Calendario delle retribuzioni",
      "FILTER_PAY_RUN": "Corsa a pagamento"
    },
    "ROTA_PROGRESS": {
      "TITLE": "Progresso della rotazione",
      "JUMP_TO": "Salta a",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_NEXT_WEEK": "La prossima settimana",
      "FILTER_SITE": "Sito/dipartimento",
      "LIFECYCLE_DRAFT": "Bozza",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Contrassegnato per l'approvazione",
      "LIFECYCLE_PUBLISHED": "Pubblicato",
      "INCLUDE_NO_DRAFT": "Mostra siti senza bozze",
      "NO_DRAFT": "Nessuna bozza",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "COLUMN_SITE": "Sito/dipartimento",
      "COLUMN_LIFECYCLE": "Status",
      "COLUMN_SCHEDULED_HOURS": "Ore programmate",
      "COLUMN_SCHEDULED_WAGES": "Salari programmati",
      "COLUMN_FORECAST_SALES": "Previsione delle vendite",
      "COLUMN_WAGE_PERCENTAGE": "Salario%",
      "ROW_GRAND_TOTAL": "Totale complessivo",
      "TOOLTIP_BUDGET": "Bilancio",
      "INFO_NO_DATA": "Non ci sono siti/reparti corrispondenti ai tuoi filtri.",
      "INFO_NO_FILTER": "Seleziona un sito/reparto qui sopra per iniziare.",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova."
    },
    "SIGN_OFF_PROGRESS": {
      "TITLE": "Progresso della disattivazione",
      "JUMP_TO": "Salta a",
      "JUMP_TO_LAST_WEEK": "La settimana scorsa",
      "JUMP_TO_THIS_WEEK": "Questa settimana",
      "JUMP_TO_NEXT_WEEK": "La prossima settimana",
      "FILTER_SITE": "Sito/dipartimento",
      "FILTER_STATUS": "Stato di disconnessione",
      "STATUS_NO_ROTA": "Nessuna rotazione",
      "STATUS_INCOMPLETE": "Incompleto",
      "STATUS_REPORTING_DATE": "Data del rapporto",
      "STATUS_COMPLETE": "Completo",
      "STATUS_TOOLTIP_NO_ROTA": "Non ci sono turni pubblicati questa settimana.",
      "STATUS_TOOLTIP_INCOMPLETE": "La firma non è stata completata per tutti i giorni di questa settimana.",
      "STATUS_TOOLTIP_REPORTING_DATE": "La registrazione è stata completata per i giorni precedenti la data di segnalazione inclusa.",
      "STATUS_TOOLTIP_COMPLETE": "La firma è stata completata per tutti i giorni di questa settimana.",
      "TOGGLE_COMPARAND": "Comparando",
      "TOGGLE_COMPARAND_FORECAST": "Previsione",
      "TOGGLE_COMPARAND_BUDGET": "Bilancio",
      "EXPORT": "Esporta",
      "EXPORT_PRINT": "Stampa",
      "COLUMN_WAGES": "Salari",
      "COLUMN_SALES": "Vendite",
      "COLUMN_WAGE_PERCENTAGE": "Salario%",
      "COLUMN_HOURS": "Ore",
      "COLUMN_SITE": "Sito/dipartimento",
      "COLUMN_STATUS": "Status",
      "COLUMN_FORECAST": "Previsione",
      "COLUMN_BUDGET": "Bilancio",
      "COLUMN_ACTUAL": "Effettivo",
      "ROW_GRAND_TOTAL": "Totale complessivo",
      "INFO_NO_DATA": "Non ci sono siti/reparti corrispondenti ai tuoi filtri.",
      "INFO_NO_FILTER": "Seleziona un sito/reparto qui sopra per iniziare.",
      "ERROR_500": "Non siamo riusciti a caricare il tuo rapporto in quel momento. Riprova.",
      "STATUS_MODAL": {
        "REVERSE_CONFIRM": "Sei sicuro di voler annullare questa approvazione?\n\nQuesto sbloccherà i turni e i registri delle presenze in queste date in modo da poter apportare modifiche.",
        "REVERSE_SUCCESS": "La firma è stata annullata con successo.",
        "REVERSE_ERROR": "Non siamo riusciti a invertire la firma in quel momento. Per favore riprova.",
        "TITLE": "Stato di disconnessione",
        "COLUMN_DATE": "Data",
        "COLUMN_STATUS": "Status",
        "COLUMN_OPTIONS": "Opzioni",
        "STATUS_NO_SIGN_OFF": "Nessuna firma",
        "STATUS_SIGN_OFF": "Disconnesso",
        "BUTTON_REVERSE_DAY": "Reverse",
        "BUTTON_REVERSE_WEEK": "Inverti l'intera settimana",
        "BUTTON_CLOSE": "Chiudi"
      }
    },
    "TRONC_STATUS": {
      "TITLE": "Stato del tronco",
      "LIFECYCLE_DRAFT": "Bozza",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Contrassegnato per l'approvazione",
      "LIFECYCLE_LOCKED": "Bloccato",
      "INCLUDE_NO_HEADER": "Mostra siti senza bozze",
      "NO_HEADER": "Nessuna bozza",
      "COLUMN_SITE": "Sito/dipartimento",
      "COLUMN_LIFECYCLE": "Status",
      "COLUMN_RECEIVED": "Totale ricevuto",
      "COLUMN_PAID_OUT": "Totale versato",
      "COLUMN_EDITED_WHO": "A cura di",
      "COLUMN_EDITED_WHEN": "Quando",
      "INFO_NO_DATA": "Non ci sono siti/reparti Tronc corrispondenti ai tuoi filtri.",
      "ERROR_500": "Non siamo riusciti a caricare Tronc in quel momento. Riprova."
    },
    "CURRENTLY_CLOCKED_IN": {
      "TITLE": "Persone attualmente registrate",
      "NAME": "Nome",
      "SITE": "Sito/dipartimento",
      "POSITION": "Ruolo lavorativo",
      "CLOCK_IN_TIME": "Ora dell'orologio",
      "NOBODY_CLOCKED_IN": "Non c'è nessuno in questo momento!",
      "ERROR_500": "Non siamo riusciti a caricare il rapporto in quel momento. Riprova."
    },
    "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Genera e invia rapporto via email.",
      "REPORT_GENERATED": "Il tuo rapporto è in fase di generazione e ti verrà inviato via email una volta completato.",
      "FILTER_DATE": "Data",
      "FILTER_ENTITY": "Sito/dipartimento",
      "FILTER_PAY_TYPES": "Tipi di pagamento",
      "FILTER_DATA_SOURCE": "Fonte dati",
      "FILTER_USER": "Persona",
      "FILTER_EVENT_TYPES": "Tipi di evento",
      "FILTER_BEHAVIOUR": "Comportamento",
      "FILTER_SHIFT_TYPE": "Tipo turno",
    },
    "PAYROLL_EXPORT_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Genera e invia rapporto via email.",
      "REPORT_GENERATED": "Il tuo rapporto è in fase di generazione e ti verrà inviato via email una volta completato.",
      "FILTER_PAYROLL_CALENDAR": "Calendario paghe",
      "FILTER_PAY_RUN": "Esecuzione paghe",
      "FILTER_DATE": "Data",
      "FILTER_ENTITY": "Sito/dipartimento",
      "FILTER_AGGREGATION_OPTIONS": "Segmentazione",
      "FILTER_USER": "Persona",
      "FILTER_ABSENCE_TYPES": "Tipi di assenza",
      "FILTER_PAY_ELEMENT_TYPES": "Tipi di elemento di pagamento",
      "FILTER_PAY_AMOUNT_TYPE": "Tipo di importo di pagamento",
      "FILTER_ROLLED_UP_HOLIDAY": "Festività accumulate",
      "FILTER_COMPANY": "Azienda",
    },
  },
  "ROTA_MANAGEMENT": {
    "DASHBOARD": {
      "INDEX": {
        "TITLE": "Cruscotto",
        "CHECKLIST": "Lista di controllo Rota",
        "LABOUR_SPEND": "Spesa del lavoro"
      },
      "LABOUR_SPEND_TAB": {
        "TITLE": "Panoramica sulla manodopera",
        "SHOW_ACTUAL_TIP": "Per i giorni di questa settimana che non sono ancora trascorsi, proietta le vendite previste e i salari/ore programmati nei totali effettivi",
        "SHOW_ACTUAL_TEXT": "Previsione del progetto in realtà",
        "FORECAST": "Previsione",
        "ACTUAL": "Effettivo",
        "BUDGET": "Bilancio",
        "SALES": "Vendite",
        "WAGES": "Salari",
        "HOURS": "Ore",
        "WAGE_PERCENT": "Salario%",
        "LABOUR_COMP": "Confronto del lavoro",
        "ACTUAL_PROJECTED": "Effettivo (previsto)",
        "TODAYS_VS_SCHEDULED": "Le presenze di oggi vs. quelle programmate"
      },
      "WTD_VIOLATIONS_MODAL": {
        "TITLE": "Violazioni dell'orario di lavoro",
        "BODY": "Queste sono le occasioni in cui abbiamo riscontrato che i tuoi orari violano le norme sull'orario di lavoro.",
        "TO": "a ",
        "ALL": "Tutte le violazioni",
        "TOO_MANY": "Troppe ore lavorative (in media)",
        "INSUF_DAYS": "Giorni liberi insufficienti",
        "INSUF_REST": "Riposo insufficiente tra i turni",
        "UNDER_18_DAYS": "Minori di 18 anni: giorni liberi insufficienti",
        "UNDER_18_LONG": "Minori di 18 anni: turni troppo lunghi",
        "UNDER_18_REST": "Minori di 18 anni: riposo insufficiente tra i turni",
        "STAFF_MEMBER": "Membro dello staff",
        "VIOLATION": "Violazione",
        "VALUE": "Valore",
        "NO_VIOLATIONS": "Non hai violato l'orario di lavoro, ottimo lavoro!",
        "NO_FILTER_VIOLATIONS": "Non ci sono violazioni corrispondenti ai tuoi filtri.",
        "AVE_48_OVER_17": "Non può superare la media di 48 ore lavorative nell'arco di 17 settimane",
        "LESS_2_DAYS_2_WEEKS": "Non può avere meno di 2 giorni liberi in un periodo di 2 settimane",
        "UNDER_18_2_PER_WEEK": "Minori di 18 anni: deve avere 2 giorni liberi consecutivi a settimana",
        "UNDER_18_LONGER_8": "Minori di 18 anni: turno di lavoro superiore a 8 ore",
        "UNDER_18_LESS_12_REST": "Minori di 18 anni: meno di 12 ore di riposo dopo la giornata lavorativa",
        "LESS_11_WEEK": "Meno di 11 ore di riposo dopo la giornata lavorativa",
        "WORKING_HOURS": " ore lavorative",
        "WORKING_DAYS": " giorni lavorativi",
        "HOURS_REST": " ore di riposo",
        "RECALC": "Ricalcola",
        "LOADING": "Caricamento...",
        "CLOSE": "Chiudi"
      }
    },
    "LOCATIONS": {
      "ADD_LOCATION_MODAL": {
        "NEW_SHIFT_LOCATION": "Nuova zona presenze",
        "TYPE": "Tipo",
        "NEW_LOCATION": "Nuova zona",
        "CHANGE_EXISTING_LOCATION": "Cambia zona esistente",
        "EXISTING_LOCATION": "Zona esistente",
        "NAME": "Nome",
        "DETAILS": "Dettagli",
        "HELP": "Rotaready ricorda la posizione precisa dell'indicatore sulla mappa, quindi questi dettagli sono solo di riferimento. Sentiti libero di includere alcune note o un indirizzo completo.",
        "SAVING": "Salvataggio...",
        "SAVE": "Salva",
        "CANCEL": "Annulla",
        "ERROR_ENTER_NAME": "Inserisci un nome per questa zona.",
        "ERROR_UPDATE": "Al momento non è possibile aggiornare questa zona. Per favore riprova.",
        "ERROR_ADD": "Al momento non è possibile aggiungere questa zona. Per favore riprova."
      },
      "EDIT_LOCATION_MODAL": {
        "EDIT_LOCATION": "Modifica zona",
        "HIERARCHY": "Gerarchia",
        "MASTER_LOCATION": "Zona principale",
        "SUB_LOCATION": "Sottozona",
        "HELP_HIERARCHY": "Rotaready consente di creare una gerarchia di zone. Una sottozona può essere posizionata sotto qualsiasi altra zona.",
        "PARENT_LOCATION": "Zona principale",
        "NAME": "Nome",
        "DETAILS": "Dettagli",
        "HELP_DETAILS": "Rotaready ricorda la posizione precisa dell'indicatore sulla mappa, quindi questi dettagli sono solo di riferimento. Sentiti libero di includere alcune note o un indirizzo completo.",
        "ATTENDANCE": "Frequenza",
        "ALLOW_CLOCKING": "Consenti al personale di entrare e uscire qui",
        "STAFF_WORKING": "Solo quando il personale lavora in un turno contrassegnato da questa zona",
        "HELP_CLOCKING": "Queste impostazioni influiscono sulla possibilità per il personale di effettuare l'orario in entrata e in uscita utilizzando l'app mobile Rotaready.",
        "ATTENDANCE_SENSITIVITY": "Sensibilità alle presenze",
        "METRES": "metri",
        "HELP_CLOSE_LOCATION": "Questo definisce quanto deve essere vicino qualcuno alla zona prima che l'app mobile Rotaready consenta loro di entrare o uscire. Una distanza inferiore a 50 metri non è consigliata in quanto le persone con un segnale debole non saranno in grado di entrare.",
        "MIN_OCCUPANCY": "Occupazione minima",
        "HELP_MIN_OCCUPANCY": "Ogni volta che qualcuno è di turno, il Rotaready ti avviserà se il numero totale di persone anche in turno scende al di sotto di questo numero.",
        "MAX_OCCUPANCY": "Occupazione massima",
        "HELP_MAX_OCCUPANCY": "Se previsto, Rotaready non ti consentirà di programmare più personale qui (in qualsiasi momento) rispetto alla capienza massima.",
        "TIP_OPENING": "Specifica gli orari di apertura in modo che Rotaready ti impedisca di programmare i turni in questa zona quando è chiusa.",
        "TIP_SUB_LOC": "Trattandosi di una sottozona, se la zona madre viene chiusa in qualsiasi momento, anche questa sottozona sarà considerata chiusa.",
        "REGULAR_OPENING_TIMES": "Orari di apertura regolari",
        "OPEN_247": "Questa zona è aperta 24 ore su 24, 7 giorni su 7.",
        "TO": "a",
        "SPECIAL_PERIODS": "Periodi speciali",
        "NO_SPECIAL_PERIODS": "Non ci sono periodi speciali.",
        "OPEN": "Aperta",
        "CLOSED": "Chiuso",
        "ADD_SPECIAL_PERIOD": "Aggiungi periodo speciale",
        "REASON": "Motivo",
        "REASON_PLACEHOLDER": "ad esempio. Ristrutturazione estiva",
        "FROM": "Da",
        "TO_CAPS": "A",
        "ADD": "Inserisci",
        "DELETE": "Elimina",
        "SAVING": "Salvataggio...",
        "SAVE": "Salva",
        "CANCEL": "Annulla",
        "ALERT_NAME": "Assicurati di aver fornito un nome valido per la zona di partecipazione.",
        "ALERT_PARENT": "Affinché questa sia una zona secondaria, è necessario selezionare una zona principale.",
        "ALERT_VALID_PARENT": "Seleziona una zona padre valida.",
        "ALERT_PARENT_LOCATION": "Questa zona di partecipazione e la zona principale prescelta devono appartenere entrambe allo stesso sito (o entrambe a livello di organizzazione).",
        "ALERT_MINIMUM": "Non puoi avere un livello di occupazione minimo superiore al livello di occupazione massimo.",
        "ERROR_UPDATE": "Non siamo riusciti ad aggiornare la zona in quel momento. Per favore riprova.",
        "ALERT_SUB_CONFIRM": "Questa zona ha ancora delle zone secondarie. Anche loro verranno eliminati.\n\nSei sicuro di voler procedere?",
        "ALERT_CONFIRM": "Sei sicuro di voler eliminare questa zona?",
        "ERROR_DELETE": "In quel momento non siamo riusciti a eliminare la zona. Per favore riprova!"
      },
      "INDEX": {
        "SHIFT_LOCATIONS": "Zone di partecipazione",
        "TIP_FIND": "Per aggiungere una nuova zona o modificare una zona esistente, devi prima trovarla con la mappa. Cerca un indirizzo o un codice postale, quindi trascina l'indicatore verde per riposizionarlo.",
        "PLACEHOLDER_SEARCH": "Cerca per aggiungere una nuova zona",
        "HELP_NO_LOCATIONS": "Non hai zone esistenti. Cerca nella mappa per aggiungerne una.",
        "HELP_NO_RESULTS": "Nessuna zona di presenza corrisponde ai termini di ricerca.",
        "TOOLTIP_SHOW": "Mostra sulla mappa",
        "TOOLTIP_CLOCKING_ALWAYS": "Il personale può sempre effettuare l'orario di entrata/uscita con l'app mobile Rotaready qui",
        "TOOLTIP_CLOCKING_WORKING": "Il personale può entrare e uscire con l'app mobile Rotaready qui, solo se lavora un turno qui",
        "ERROR_LOAD": "Non siamo riusciti a caricare le tue zone di presenza. Per favore riprova.",
        "NO_ADDRESS": "Nessun dettaglio/indirizzo",
        "ERROR_LOOKUP": "Non siamo riusciti a trovare la posizione di quell'indicatore.",
        "UNKNOWN_ADDRESS": "Indirizzo sconosciuto",
        "ADD_AS_NEW": "Aggiungi come nuova zona",
        "DRAG_TO_CHANGE": "Trascinami per cambiare la posizione",
        "FILTER_LOCATIONS": "Filtra le zone..."
      }
    },
    "SHIFT_TYPES": {
      "EDIT_SHIFT_TYPES_MODAL": {
        "TITLE_NEW": "Nuovo tipo di turno",
        "TITLE_EDIT": "Modifica tipo di turno",
        "SITE_LABEL": "Sito",
        "HIERARCHY_LABEL": "Gerarchia",
        "MASTER_TYPE_LABEL": "Tipo di turno principale",
        "SUB_TYPE_LABEL": "Tipo di turni secondari",
        "SUB_TYPE_HELP": "Rotaready consente di creare una gerarchia di tipi di turni. Un tipo di turno secondario può essere posizionato sotto qualsiasi altro tipo di turno.",
        "PARENT_TYPE_LABEL": "Tipo di turno principale",
        "NAME_LABEL": "Nome",
        "COLOUR_LABEL": "Colore",
        "COST_STREAM_MAPPINGS_LABEL": "Mappature degli stream",
        "COST_STREAM_TABLE": {
          "COST_STREAM_HEADING": "Stream",
          "ATTRIBUTED_HEADING": "attribuito",
          "EMPTY_LABEL": "Questo non è mappato su nessuno stream.",
          "ADD_BUTTON": "Aggiungi nuovo"
        },
        "DELETE_BUTTON": "Elimina",
        "SAVE_BUTTON": "Salva",
        "CANCEL_BUTTON": "Annulla",
        "VALIDATION": {
          "NAME_REQUIRED": "Assicurati di aver fornito un nome valido per il tipo di turno.",
          "PARENT_REQUIRED": "Affinché questo sia un tipo di turno secondario, devi selezionare un tipo di turno principale.",
          "PARENT_VALID": "Seleziona un tipo di turno parentale valido.",
          "PARENT_SAME_SITE": "Questo tipo di turno e il tipo di turno principale scelto devono appartenere entrambi allo stesso sito (o entrambi devono essere a livello di organizzazione).",
          "ATTRIBUTION_OVERAGE": "Abbiamo sommato la percentuale attribuita a ogni stream e il totale supera il 100%. Puoi attribuire un massimo del 100% solo a uno o più stream.",
          "ATTRIBUTION_VALID": "Hai inserito una «percentuale attribuita» non valida per uno o più stream. Un valore pari a '50', ad esempio, attribuirà il 50% del costo salariale di chiunque lavori questo tipo di turni al flusso corrispondente.",
          "ATTRIBUTION_NONE": "Devi attribuire il tipo di turno ad almeno uno stream.",
          "DUPLICATE_COST_STREAM_MAPPING": "Esiste già una mappatura per questo stream! Non puoi attribuire due volte un tipo di turno allo stesso stream."
        },
        "ERRORS": {
          "ADD_ERROR": "Non siamo riusciti ad aggiungere il tuo tipo di turno in quel momento. È probabile che esista già un tipo di turno con lo stesso nome! Prova a scegliere un altro nome.",
          "UPDATE_ERROR": "Non siamo riusciti ad aggiornare il tipo di turno in quel momento. È probabile che esista già un tipo di turno con lo stesso nome! Prova a scegliere un altro nome.",
          "DELETE_TYPE": "In quel momento non siamo riusciti a eliminare il tipo di turno. Per favore riprova!"
        },
        "CONFIRM": {
          "DELETE_SUB_TYPES": "Questo tipo di turni ha ancora tipi di turni secondari. Anche loro verranno eliminati. Sei sicuro di voler procedere?",
          "DELETE_TYPE": "Sei sicuro di voler eliminare questo tipo di turno?"
        }
      },
      "INDEX": {
        "TITLE": "Tipi di turni",
        "SUBTITLE": "Pensa ai tipi di turni come a categorie per i diversi tipi di lavoro che le persone svolgono nella tua organizzazione:",
        "SUB_TYPE_HELP": "Puoi aggiungere un «tipo di turno secondario» facendo clic sull'icona più accanto a qualsiasi tipo di turno. Ciò è utile se è necessario suddividere un tipo di turno in mansioni o ruoli più specifici.",
        "TYPES_EMPTY": "Non ci sono tipi di turni da mostrare.",
        "ONLY_ENTITY": "solo",
        "WHOLE_ORGANISATION": "A livello di organizzazione",
        "SHOW_MORE": "Mostra altro...",
        "ERRORS": {
          "LOAD_ERROR": "Ops, non siamo riusciti a caricare i tipi di turni in quel momento. Per favore riprova!"
        },
        "TOOLTIPS": {
          "ADD_SUB_SHIFT_TYPE": "Aggiungi tipo di turno secondario"
        },
        "ADD_SHIFT_TYPE": "Aggiungi tipo di turno"
      }
    },
    "TEMPLATES": {
      "EDIT_CONSTRAINTS_MODAL": {
        "ADD_TITLE": "Aggiungi nuovo {{currentLevelName}}",
        "EDIT_TITLE": "Modifica i vincoli {{currentLevelName}}",
        "NAME_LABEL": "nome",
        "LOADING": {
          "SAVE": "Salvataggio..."
        },
        "SAVE_AND_CLOSE_BUTTON": "Salva e chiudi",
        "CANCEL_BUTTON": "Annulla",
        "OVERRIDE_CONSTRAINT_BUTTON": "Sostituisci il vincolo {{levelName}}",
        "DELETE_OVERRIDE_BUTTON": "Elimina l'override",
        "TAB_HEADINGS": {
          "POPULAR": "Popolare",
          "STAFF": "Personale",
          "REST": "Riposo",
          "OTHERS": "Altri"
        },
        "STAFF_SELECTION": {
          "TITLE": "Selezione del personale",
          "FLEXIBLE_STAFFING_LABEL": "Personale flessibile",
          "FAIRLY_HINT": "Scegli in modo equo tra tutto il personale",
          "FAIRLY_ANY_TAGS_HINT": "Scegli equamente tra quelli che corrispondono a <strong>uno</strong> dei tag seguenti",
          "FAIRLY_ALL_TAGS_HINT": "Scegli equamente tra quelli che corrispondono a <strong>tutti i</strong> tag seguenti",
          "POOLING_HINT": "Tratta i tag seguenti come gruppi di personale ed esaurisci ognuno di essi <strong>in ordine</strong>",
          "FIXED_STAFFING_LABEL": "Personale fisso",
          "SPECIFIC_STAFF_MEMBER_HINT": "Assegna i turni a un membro del personale specifico",
          "ASSIGNEE_LABEL": "Assegnatario",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Digita per aggiungere tag"
          }
        },
        "PREFERRED_STAFF_SELECTION": {
          "TITLE": "Selezione del personale preferito",
          "HINT": "Se più dipendenti sono idonei per un determinato turno, il Rotaready tenterà di orientare la selezione in base ai criteri scelti:",
          "NO_PREFERENCE": "Non preferire alcun personale rispetto ad altri, se non per motivi di equità",
          "PREFER_ANY_TAG": "Preferisci quelli che corrispondono a <strong>uno</strong> dei tag seguenti",
          "PREFER_ALL_TAG": "Preferisci quelli che corrispondono a <strong>tutti</strong> i tag seguenti",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Digita per aggiungere tag"
          }
        },
        "STAFF_SELECTION_GROUPS": {
          "TITLE": "Selezione del personale (gruppi)",
          "HINT": "Ciò consente di limitare la selezione del personale a coloro che sono membri di uno dei gruppi seguenti. Le risorse verranno assegnate ai gruppi nell'ordine in cui sono specificati:",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Digita per aggiungere tag"
          }
        },
        "STAFF_TYPECASTING": {
          "TITLE": "Typecasting del personale",
          "HINT": "<em>Quando assegniamo a qualcuno un turno <em>dattiloscritto</em>, quel giorno possono essere assegnati solo altri turni che a) siano anch'essi <em>digitati</em> con almeno uno di questi tag, oppure b) non digitati affatto:</em>",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Digita per aggiungere tag"
          }
        },
        "OUTSOURCING": {
          "TITLE": "Esternalizzazione",
          "HINT": "Se non è disponibile personale interno, consenti l'esternalizzazione alle seguenti organizzazioni, nell'ordine in cui sono specificate:",
          "PLACEHOLDERS": {
            "TYPE_OUTSOURCE_ORGANISATIONS": "Digita per aggiungere organizzazioni di outsourcing"
          }
        },
        "SELECTION_CONSISTENCY": {
          "TITLE": "Coerenza della selezione",
          "HINT": "Per i set di turni che si estendono su più giorni in una determinata settimana, quando qualcuno viene assegnato al turno del primo giorno, dovrebbe:",
          "NO_EFFECT": "non hanno alcun effetto sul fatto che vengano scelti per turni di altri giorni",
          "ALLOCATE": "essere assegnati a tutti gli altri turni diurni del set di turni, ove possibile"
        },
        "REST_PERIOD": {
          "TITLE": "Periodo di riposo",
          "HINT": "Dopo aver svolto un turno, assicurati che un membro del personale sia riposato (e quindi non gli venga assegnato un altro turno) per almeno:"
        },
        "LOCATION_TOLERANCE": {
          "TITLE": "Tolleranza di posizione",
          "HINT": "Spesso è importante che due turni consecutivi con la stessa sede specificata siano assegnati allo stesso membro del personale. Specifica il numero massimo di minuti tra due di questi turni (dalla fine del primo all'inizio del secondo) per considerarli consecutivi a questo proposito:",
          "MINUTES": "verbale",
          "PLACEHOLDERS": {
            "MINUTES": "Minuti tra la fine di un turno e l'inizio di un altro"
          }
        },
        "DAILY_WINDOW_DEFINITION": {
          "TITLE": "Definizione giornaliera della finestra",
          "HINT": "Alcune organizzazioni hanno il personale che lavora più «turni» in un dato giorno e, per ogni persona, questo carico di lavoro giornaliero di turni deve rientrare in un determinato lasso di tempo. Queste finestre temporali possono variare e puoi definire tutte le possibili variazioni qui:",
          "LIMITED_TIMES": "Abbiamo un set limitato e fisso di orari di inizio/fine per le nostre finestre",
          "REGULAR_INTERVALS": "Le nostre finestre cadono a intervalli regolari dopo un determinato orario di inizio"
        },
        "TIMES": {
          "WINDOW_TIMES_TITLE": "Orari delle finestre",
          "SPLIT_SHIFT_WINDOW_TIMES": "Orari delle finestre con turni divisi",
          "WINDOW_START_TIME": "Ora di inizio della prima finestra",
          "WINDOW_INTERVAL": "Intervallo tra finestre valide",
          "WINDOW_FINISH_TIME": "Ora di fine dell'ultima finestra possibile",
          "PLACEHOLDERS": {
            "COMMA_SEPERATED_TIMES": "Elenco degli orari separato da virgole, ad esempio 9:30-17:30,13:00-20:00"
          }
        },
        "SHIFT_PAY_RATE": {
          "TITLE": "Tariffa salariale per turni",
          "HINT": "I turni di lavoro del personale non retribuito generati da questo {{currentLevelName}} guadagneranno alla loro normale tariffa oraria. Se necessario, puoi sovrascriverlo di seguito:",
          "OPTIONS": {
            "NORMAL": "Normale: il personale guadagna alla normale tariffa oraria",
            "ZERO": "Zero: il personale non viene pagato per questo turno",
            "MULTIPLE": "Multiplo: il personale guadagna un multiplo della tariffa oraria abituale",
            "FIXED": "Risolto: sostituisci la loro normale tariffa oraria con una tariffa fissa"
          },
          "MULTIPLIER": "Moltiplicatore di tasso",
          "HOURLY_RATE_OVERRIDE": "Sostituzione della tariffa oraria",
          "PLACEHOLDERS": {
            "MULTIPLIER": "Moltiplicatore di tariffa oraria",
            "HOURLY_RATE_OVERRIDE": "Tariffa oraria in valuta locale, ossia 8,50"
          }
        },
        "SHIFT_TYPE": {
          "TITLE": "Tipo di turno",
          "HINT": "Questo indica il «tipo» di turni che verranno generati da questo {{currentLevelName}}. Il personale lo vedrà come titolo del turno e, se utilizzi le funzionalità di controllo dei costi di Rotaready, ogni tipo di turno può essere mappato a uno o più flussi di costi."
        },
        "SITE_DEPARTMENT": {
          "TITLE": "Sito/dipartimento",
          "HINT": "Spostamenti degli attributi generati da questo {{currentLevelName}} a un sito/reparto diverso. Ciò è utile per generare turni di copertura.",
          "ATTRIBUTE_TO_SITE": "Attribuisci il costo salariale e le ore al sito scelto"
        }
      },
      "EDIT_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Aggiungi set di turni",
        "ADD_SUBTITLE": "Fornisci dettagli su questo set di turni",
        "EDIT_TITLE": "Modifica set di turni",
        "EDIT_SUBTITLE": "Modifica i dettagli di questo set di turni",
        "TIP": "Lo scheduler automatico del Rotaready genererà turni da questo set di turni e assegnerà il personale di conseguenza, in modo equo ed economico.",
        "TIME_LABEL": "Ora di inizio/fine",
        "TO": "a",
        "OVERFLOW_WARNING": "Questo turno si protrarrà fino al giorno successivo.",
        "LOCATION_LABEL": "Zona di partecipazione",
        "MINIMUM_LABEL": "Almeno",
        "MINIMUM_EXPLANATION": "Voglio un numero minimo di dipendenti al giorno, ma di più se possibile",
        "STAFF_NUMBER_LABEL": "Esattamente",
        "STAFF_NUMBER_EXPLANATION": "Voglio un numero esatto di dipendenti al giorno",
        "MAXIMUM_LABEL": "Fino a",
        "MAXIMUM_EXPLANATION": "Voglio più personale possibile al giorno, fino a un limite massimo",
        "SHORT_DAYS": {
          "MONDAY": "Lunedì",
          "TUESDAY": "mar",
          "WEDNESDAY": "mer",
          "THURSDAY": "giovedì",
          "FRIDAY": "ven",
          "SATURDAY": "sab",
          "SUNDAY": "Sole"
        },
        "SAVE_BUTTON": "Salva set di turni",
        "CANCEL_BUTTON": "Annulla",
        "LOADING": {
          "SAVE": "Salvataggio..."
        },
        "ERRORS": {
          "UNABLE_TO_ADD": "Al momento non è possibile aggiungere questo set di turni. Riprova."
        }
      },
      "EDIT_TEMPLATE": {
        "LOADING": {
          "SAVE": "Salvataggio..."
        },
        "EDIT_CONSTRAINTS": "Modifica vincoli",
        "CONSTRAINTS_LABEL": "Vincoli del modello",
        "CONSTRAINTS_TEXT": "Imposta i vincoli che verranno applicati all'intero modello.",
        "PLACEHOLDERS": {
          "SEARCH_FILTER": "Filtro..."
        },
        "ADD_GROUP_BUTTON": "Aggiungi gruppo",
        "TAB_HEADINGS": {
          "FLEXIBLE_STAFFING": "Personale flessibile",
          "FIXED_STAFFING": "Personale fisso"
        },
        "TOOLTIPS": {
          "ACTIVE_CONSTRAINTS": "Vincoli attivi",
          "DISABLE": "Disabilita",
          "ENABLE": "Attivare"
        },
        "GROUP_OPTIONS_BUTTON": {
          "LABEL": "Opzioni di gruppo",
          "ADD_TASK": "Aggiungi attività",
          "ADD_SHIFT_SET": "Aggiungi set di turni",
          "EDIT_CONSTRAINTS": "Modifica vincoli",
          "DELETE_GROUP": "Elimina gruppo"
        },
        "HOURS": "ore",
        "SHORT_DAYS": {
          "MONDAY": "Lunedì",
          "TUESDAY": "mar",
          "WEDNESDAY": "mer",
          "THURSDAY": "giovedì",
          "FRIDAY": "ven",
          "SATURDAY": "sab",
          "SUNDAY": "Sole"
        },
        "CHAR_DAYS": {
          "MONDAY": "M",
          "TUESDAY": "T",
          "WEDNESDAY": "W",
          "THURSDAY": "T",
          "FRIDAY": "F",
          "SATURDAY": "S",
          "SUNDAY": "S"
        },
        "NO_SHIFT_SETS": "Non ci sono set di turni in questo gruppo. Aggiungine uno utilizzando il pulsante «Opzioni di gruppo».",
        "OPTIONS_BUTTON": {
          "LABEL": "Opzioni",
          "ADD_SHIFT_SET": "Aggiungi set di turni",
          "EDIT_CONSTRAINTS": "Modifica vincoli",
          "DELETE_TASK": "Elimina attività"
        },
        "NO_FLEXIBLE_STAFF_GROUPS_THIS_WEEK": "Non avete gruppi di personale flessibili. Perché non aggiungerne uno facendo clic su «Aggiungi gruppo» sopra?",
        "NO_FLEXIBLE_STAFF_GROUPS": "Nessun gruppo di personale flessibile corrisponde a ciò che stai cercando.",
        "GROUP_SHIFT_SETS_TABLE": {
          "HEADINGS": {
            "SHIFT_TYPE": "Tipo di turno",
            "TIME": "Ora",
            "DAYS_OF_WEEK": "Giorni della settimana"
          }
        },
        "NO_FIXED_STAFF_GROUPS_THIS_WEEK": "Non hai gruppi di personale fissi. Perché non aggiungerne uno facendo clic su «Aggiungi gruppo» sopra?",
        "NO_FIXED_STAFF_GROUPS": "Nessun gruppo di personale fisso corrisponde a quello che stai cercando.",
        "EDIT_BUTTON": "Modifica",
        "TOGGLE_DROPDOWN_BUTTON": "Attiva/disattiva il menu a discesa",
        "DELETE_BUTTON": "Elimina",
        "SAVE_BUTTON": "Salva",
        "ERRORS": {
          "COULDNT_FIND_TEMPLATE": "Non siamo riusciti a trovare il modello che stavi cercando. Riprova.",
          "OOPS": "Ops, qualcosa è andato storto. Potrebbe essere necessario riprovare!",
          "TEMPLATE_STATE_CHANGE": "Non siamo riusciti ad abilitare/disabilitare parte del tuo modello in questo momento. Per favore riprova."
        },
        "LEVEL_NAMES": {
          "GLOBAL": "globale",
          "TEMPLATE": "modello",
          "WEEK": "settimana",
          "GROUP": "gruppo",
          "TASK": "compito",
          "SHIFT_SET": "set di turni"
        },
        "CONFIRM": {
          "DELETE": "Sei sicuro di volerlo eliminare?",
          "DELETE_SHIFT_SET": "Sei sicuro di voler eliminare questo set di turni?"
        }
      },
      "FIXED_STAFFING_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Aggiungi set di turni",
        "ADD_SUBTITLE": "Fornisci dettagli su questo set di turni",
        "EDIT_TITLE": "Modifica set di turni",
        "EDIT_SUBTITLE": "Modifica i dettagli di questo set di turni",
        "START_END_LABEL": "Ora di inizio/fine",
        "SHIFT_OVERFLOW_WARNING": "Questo turno si protrarrà fino al giorno successivo.",
        "SHIFT_TYPE_LABEL": "Tipo di turno",
        "LOCATION_LABEL": "Zona di partecipazione",
        "SHORT_DAYS": {
          "MONDAY": "Lunedì",
          "TUESDAY": "mar",
          "WEDNESDAY": "mer",
          "THURSDAY": "giovedì",
          "FRIDAY": "ven",
          "SATURDAY": "sab",
          "SUNDAY": "Sole"
        },
        "SAVE_BUTTON": "Salva set di turni",
        "CANCEL_BUTTON": "Annulla",
        "LOADING": {
          "SAVE": "Salvataggio..."
        },
        "UNKNOWN_SHIFT_TYPE": "Tipo di turno sconosciuto",
        "ERRORS": {
          "UNABLE_ADD": "Ops, al momento non siamo in grado di aggiungere questo set di turni. Per favore riprova.",
          "UNABLE_UPDATE": "Ops, al momento non siamo in grado di aggiornare questo set di turni. Per favore riprova."
        }
      },
      "INDEX": {
        "NEW_BUTTON": "Aggiungi nuovo",
        "ALERT_LINK_WARNING": "Templates è stato spostato nel menu Impostazioni. Il link esistente nel menu Rotas scomparirà presto 👀",
        "TITLE": "Modelli Rota",
        "SEARCH_FILTER": "Cerca...",
        "TEMPLATE_TABLE": {
          "NAME_HEADING": "Nome",
          "CREATED_BY_HEADING": "Creato da",
          "LAST_EDITED_HEADING": "Ultima modifica",
          "OPTIONS_HEADING": "Opzioni"
        },
        "NO_TEMPLATES": "Non ci sono modelli di rotazione da mostrare.",
        "CLONE_TEMPLATE": "Clona",
        "DELETE_TEMPLATE": "Elimina",
        "EDITED_ON": "sul",
        "CONFIRM": {
          "DELETE_NODE": "Sei sicuro di volerlo eliminare?"
        },
        "SUCCESS": {
          "DELETE_NODE": "La tua previsione della domanda è stata eliminata con successo."
        },
        "ERRORS": {
          "DELETE_NODE": "Ops, qualcosa è andato storto. Potrebbe essere necessario riprovare!"
        }
      },
      "NEW_TEMPLATE_MODAL": {
        "TITLE": "Nuovo modello",
        "REMINDER": "<strong>Psst!</strong> Hai già <strong>{{templateCount}} template</strong>. È necessario crearne uno nuovo solo se i requisiti di pianificazione sono cambiati in modo significativo. Altrimenti, dovresti modificarne uno esistente.",
        "TYPE_LABEL": "Tipo",
        "NEW_BLANK_TEMPLATE": "Nuovo modello vuoto",
        "CLONE_EXISTING_TEMPLATE": "Clona modello esistente",
        "CLONE_FROM_LABEL": "Clona da",
        "NAME_LABEL": "Nome del modello",
        "NAME_HELP": "Genererai turni da questo modello su base regolare e un buon nome descrive a chi o a cosa serviranno questi turni, ad esempio «Rotazione del personale di supporto».",
        "NAME_PLACEHOLDER": "vale a dire. Alta stagione, o B Team Rota",
        "DEFAULT_SHIFT_TYPE_LABEL": "Tipo di turno predefinito",
        "DEFAULT_SHIFT_TYPE_HELP": "Quando crei il tuo modello, sarai in grado di cambiare il tipo di turno per ogni gruppo o attività ed è utile per noi impostare un valore predefinito ora.",
        "CREATE_BUTTON": "Crea!",
        "CREATE_BUTTON_LOADING": "Attendi, potrebbe volerci un attimo...",
        "CANCEL_BUTTON": "Annulla",
        "CONFIRM_CLONE": "La clonazione di un modello richiederà uno o due momenti, a seconda delle dimensioni del modello. Sei sicuro di voler procedere?",
        "CREATE_ERROR": "Al momento non è possibile creare questo modello. Per favore riprova."
      },
      "DELETE_TEMPLATE_MODAL": {
        "INFO_TITLE": "Elimina modello",
        "INFO_BODY": "L'eliminazione di un modello è irreversibile e non può essere annullata. Per convincerci che vuoi farlo, digita il nome del modello nella casella sottostante, quindi fai clic su Elimina.",
        "DELETE_TEMPLATE_BUTTON": "Elimina modello",
        "SUCCESS": {
          "DELETE_TEMPLATE": "Il tuo modello è stato eliminato con successo."
        },
        "ERRORS": {
          "DELETE_TEMPLATE": "Ops, qualcosa è andato storto. Potrebbe essere necessario riprovare!"
        }
      }
    },
    "SHIFT": {
      "EDIT_MODAL": {
        "SHIFT": "Turno",
        "SITE": "Sito",
        "START": "Inizio",
        "BREAK": "Pausa",
        "NONE": "Nessuna",
        "FINISH": "Finitura",
        "NEW_SHIFT": "Nuovo turno",
        "ATTRIBUTE_WAGE_COST_TO_SITE": " Attribuisci il costo salariale e le ore al sito scelto",
        "DATE_AND_TIME": "Data e ora",
        "TO": " a ",
        "THEN_REST": " poi riposa ",
        "SHIFT_TYPE": "Tipo di turno",
        "LOCATION": "Zona di partecipazione",
        "NO_BREAK": "Nessuna pausa",
        "PAID_BREAK": "Pausa retribuita",
        "UNPAID_BREAK": "Pausa non retribuita",
        "OF_LENGTH": "di lunghezza",
        "BREAK_MESSAGE_WARNING": "Il personale addetto a questo turno ha esigenze di pausa diverse. Al termine, Rotaready salverà un turno separato per ogni persona con il set di pause corretto.",
        "BREAK_MESSAGE_SUCCESS": "Abbiamo applicato automaticamente la pausa corretta a questo turno.",
        "PAY_RULE": "Regola di pagamento",
        "APPLIES": " si applica",
        "FOR_THE_SCHEDULED_DURATION": "Per la durata prevista",
        "FOR_ANY_OVERTIME": "Per eventuali straordinari",
        "STAFF_SELECTION": "Selezione del personale",
        "STAFF_SELECTION_HELP": "Modifica il personale assegnato e utilizza i tag per filtrare gli altri idonei",
        "STAFF_MUST_MATCH": "Il personale deve corrispondere",
        "ANY_OF_THE_TAGS": "<strong>uno qualsiasi</strong> dei tag",
        "ANY": "qualsiasi",
        "ALL": "tutti",
        "OF_THE_TAGS": " dei tag",
        "ALL_OF_THE_TAGS": "<strong>tutti</strong> i tag",
        "TAG_TYPEAHEAD_PLACEHOLDER": "Digita per trovare i tag",
        "STAFF_ASSIGNED": "Assegnata",
        "NOBODY_IS_ASSIGNED": "Nessuno è assegnato",
        "STAFF_ELIGIBLE": "Idoneo",
        "STAFF_FILTER_PLACEHOLDER": "Cerca...",
        "SHOW": "Mostra",
        "MORE": " altro...",
        "NOBODY_MATCHES_YOUR_TAGS": "Nessuno corrisponde ai tuoi tag",
        "NOBODY_ELSE_IS_AVAILABLE": "Nessun altro è disponibile",
        "MORE_OPTIONS": "Altre opzioni",
        "ADD_NOTES_AND_ADJUST_PAY_RATES": "Aggiungi note e modifica le aliquote retributive",
        "DETAILS": "Dettagli",
        "DETAILS_TEXTAREA_PLACEHOLDER": "Aggiungi note, doveri e altri dettagli qui...",
        "ROTA": "Rota",
        "NO_ROTA": "Nessuna rotazione",
        "PAY_LABEL": "Paga",
        "PAY_TOGGLE": "Questo è un turno retribuito",
        "DELETE": "Elimina",
        "VIEW_BROADCAST": "Visualizza trasmissione",
        "START_BROADCAST": "Avvia trasmissione",
        "SAVE": "Salva",
        "CANCEL": "Annulla"
      },
      "SHIFT_BROADCAST_MODAL": {
        "SHIFT": "Turno",
        "SITE": "Sito",
        "START": "Inizio",
        "BREAK": "Pausa",
        "NONE": "Nessuna",
        "FINISH": "Finitura",
        "ORIGINAL_PLANNED_STAFF": "Personale pianificato originale",
        "EXTRA_STAFF_REQUIRED": "Personale aggiuntivo richiesto",
        "NOT_SHIFT_BROADCASTING_HELP": "Impostato su 0 per richiedere il maggior numero possibile di personale.",
        "START_BROADCASTING": "Inizia a trasmettere",
        "START_BROADCASTING_LOADING": "Avvio della trasmissione...",
        "CANCEL": "Annulla",
        "ACCEPTED": "Accettato",
        "PENDING": "In sospeso",
        "REJECTED": "Respinto",
        "EVERYONE": "Tutti",
        "NO_PARTICIPANTS_TO_SHOW": "Non ci sono partecipanti alla trasmissione da visualizzare.",
        "NEEDED": "necessario",
        "SHIFT_BROADCASTING_HELP": "La tua trasmissione è attualmente in corso. L'elenco seguente verrà aggiornato man mano che il personale accetterà o rifiuterà il turno. La trasmissione terminerà automaticamente quando il numero corretto del personale avrà accettato o quando tutti avranno risposto.",
        "STOP_BROADCASTING": "Interrompi la trasmissione",
        "STOP_BROADCASTING_LOADING": "Interruzione della trasmissione...",
        "PAUSE_BROADCAST": "Metti in pausa la trasmissione",
        "PAUSE_BROADCAST_LOADING": "Sospesa della trasmissione...",
        "RESUME_BROADCAST": "Riprendi trasmissione",
        "RESUME_BROADCAST_LOADING": "Ripresa della trasmissione...",
        "CLOSE": "Chiudi"
      },
      "VIEW_MODAL": {
        "SHIFT": "Turno",
        "SITE": "Sito",
        "START": "Inizio",
        "BREAK": "Pausa",
        "NONE": "Nessuna",
        "FINISH": "Finitura",
        "LOCATION": "Zona di partecipazione",
        "NONE_SPECIFIED": "Nessuno specificato",
        "REST": "Riposo",
        "DETAILS": "Dettagli",
        "ALLOTTED_BREAK": "Pausa assegnata",
        "ASSIGNED_STAFF": "Personale assegnato",
        "ON": "sul",
        "SHIFT_ROUTINE": "Routine del turno",
        "LAST_UPDATED": "Ultimo aggiornamento",
        "GET_DIRECTIONS": "Ottieni indicazioni",
        "SHOW_TRAFFIC": "Mostra traffico",
        "EDIT": " Modifica",
        "CLOSE": "Chiudi"
      }
    }
  },
  "DASHBOARD": {
    "TIME_AND_ATTENDANCE": "Orario e presenze",
    "YOU_ARE_NOW": "Ora lo sei",
    "ERROR_PLEASE_TRY_AGAIN": "Errore, riprova",
    "MY_UPCOMING_SHIFTS": "I miei prossimi turni",
    "YOU_HAVE_NO_SHIFTS_TODAY": "Non hai turni oggi.",
    "ALL_DAY": "Tutto il giorno",
    "AM": "SONO",
    "PM": "PM",
    "TODAY_S_SCHEDULE": "Il programma di oggi",
    "THERE_ARE_NO_SHIFTS_SCHEDULED_TODAY": "Non ci sono turni programmati per oggi.",
    "WHATEVER": "A partire da <strong>{{startTime}}</strong> e terminando a <strong>{{endTime</strong>}}"
  },
  "SIGN_OUT": {
    "LOGGING_OUT": "Disconnettersi..."
  },
  "PARTIALS": {
    "VALIDATION": {
      "ISSUE_1": {
        "HEADING": "Giornata lavorativa troppo lunga",
        "BODY": "La modifica comporta che la giornata lavorativa di <b>{{name}</b>} diventi troppo lunga (secondo le impostazioni della tua organizzazione). Attualmente inizia il <b>{{start|MomentFormat: 'lll'}} Momentformat:'LLL'END|MomentFormat: 'lll'}} (per poi fermarsi</b> fino a <b>{{Endrest|momentFormat: 'lll</b>'}}}."
      },
      "ISSUE_2": {
        "HEADING": "Giorno lavorativo sovrapposto",
        "BODY": "La tua modifica significa che <b>{{name}}</b> ha un giorno lavorativo compreso tra {{<b>primarystart|momentFormat: 'lll'}}} momentformat:'ll'L'}} e termina con {{primaryEnd|momentFormat: 'LT'}</b>}<b>.</b> Sfortunatamente, questo si sovrappone a un giorno lavorativo che hanno già a disposizione da <b>{{SecondaryStart|momentFormat: 'lll'}}} (momentformat:'LL'LT '}) fino a {{SecondaryEnd|momentFormat:' LT '}</b> (quindi a riposo fino a <b>{{endRest|momentFormat:' LT '}} (</b>') '(')."
      },
      "ISSUE_3": {
        "HEADING": "Turno al di fuori della giornata lavorativa",
        "BODY": "<b>{{name}}</b> ha un turno programmato <b>da {{start|momentFormat: 'LT'}}} a {{end|momentFormat: 'LT'}</b>} (<b>quindi a riposo fino a {{endrest|momentFormat: 'LT'}} LT'INSIDE</b>). La tua modifica significherebbe che questo turno cade parzialmente o totalmente al di fuori della giornata lavorativa."
      },
      "ISSUE_4": {
        "HEADING": "Non disponibile",
        "BODY": "<b>{{name}}</b> non è disponibile <b>da {{start|MomentFormat: 'lll'}}} a {{<b>end|MomentFormat: 'LT'}</b></b>} così com'è",
        "REASON_1": "lavoro a turni ({{eventType}} in {{entityName}})",
        "REASON_2": "occupato durante una giornata lavorativa",
        "REASON_3": "assente ({{eventType}})",
        "REASON_4": "non disponibili a causa del loro modello di disponibilità",
        "BUTTON": "Fai un'eccezione e riprova",
        "BUTTON_LOADING": "Caricamento..."
      },
      "ISSUE_5": {
        "HEADING": "Obbligo contrattuale non soddisfatto",
        "BODY": "La modifica implica che <b>{{name}}</b> non adempirà ai propri obblighi contrattuali. Ora è <b>{{currentValue}} giorno/i, ma deve essere almeno {<b>{</b>comparatorValue}} giorno/i</b>."
      },
      "ISSUE_6": {
        "HEADING": "Obbligo contrattuale non soddisfatto",
        "BODY": "La modifica implica che <b>{{name}}</b> non adempirà ai propri obblighi contrattuali. Ora è <b>{{currentValue}} ora/e ma deve essere di almeno {<b>{</b>comparatorValue}} ora/e</b>."
      },
      "ISSUE_7": {
        "HEADING": "Mancata corrispondenza dei tag",
        "BODY": "<b>{{name}}</b> non dispone dei tag necessari per eseguire questo turno. Ciò significa che potrebbero non avere un'abilità, una qualifica o un altro attributo."
      },
      "ISSUE_8": {
        "HEADING": "Trasmissione attiva",
        "BODY": "Attualmente è attiva una trasmissione a turni per questo turno. Poiché il tuo staff potrebbe essere in procinto di rispondere, non possiamo consentire la modifica del turno a meno che tu non annulli prima la trasmissione del turno."
      },
      "ISSUE_9": {
        "HEADING": "Giorni liberi insufficienti",
        "BODY": "La modifica significa che <b>{{name}}</b> lavorerà troppi giorni questa settimana. Ora avranno <b>{{currentValue}} giorni liberi, ossia meno dei {<b>{comparatorValue</b>}} giorni di ferie</b> richiesti."
      },
      "ISSUE_10": {
        "HEADING": "Membro dello staff inattivo",
        "BODY": "<b>{{name}}</b> non può funzionare <b>su {{start|momentFormat: 'll'</b>}}}. Il loro account è inattivo in questa data oppure è stato spostato su un altro sito."
      },
      "ISSUE_11": {
        "HEADING": "Rota marcato per l'approvazione",
        "BODY": "Non è possibile apportare modifiche a questo turno poiché è stato contrassegnato per l'approvazione."
      },
      "ISSUE_12": {
        "HEADING": "Periodo di disdetta",
        "BODY": "Non è possibile apportare modifiche a questo turno poiché è stato disattivato."
      },
      "ISSUE_13": {
        "HEADING": "Limite di lavoro superato",
        "BODY": "La modifica implica che <b>{{name}}</b> funzionerà più a lungo di quanto consentito. Ora lavoreranno su <b>{{currentValue}} giorno/i, che è superiore al loro limite di {<b>{</b>comparatorValue}} giorno/i</b>."
      },
      "ISSUE_14": {
        "HEADING": "Limite di lavoro superato",
        "BODY": "La modifica implica che <b>{{name}}</b> funzionerà più a lungo di quanto consentito. Ora lavoreranno per <b>{{currentValue}} ore/i, che è superiore al loro limite di {<b>{</b>comparatorValue}}</b> ore/i."
      },
      "ISSUE_15": {
        "HEADING": "Zona presenze chiusa",
        "BODY": "La zona presenze assegnata al turno (<b>{{assignedLocation}})</b> è chiusa in questo momento.",
        "REASON": "Ciò è dovuto a: {{reason}}."
      },
      "ISSUE_16": {
        "HEADING": "L'occupazione della zona presenze è stata superata",
        "BODY": "La zona di presenza assegnata a questo turno ha un'occupazione massima di <b>{{occupancy}} persona/e alla volta</b>. Con questo spostamento l'occupazione sarebbe {{currentOccupancy}}."
      },
      "ISSUE_17": {
        "HEADING": "Troppo poco personale nella zona presenze",
        "BODY": "<b>Da {{start|MomentFormat: 'lll'}} a {{end|MomentFormat: 'lll'}}, c'è un momento in cui lavorano solo {{currentValue}} persone.</b> Ogni volta che qualcuno è in turno, è richiesto un minimo di <b>{{comparatorValue}} persona/e</b>."
      },
      "ISSUE_18": {
        "HEADING": "Membro del personale non approvato",
        "BODY": "{{name}} non può funzionare <b>su {{start|momentFormat: 'll'</b>}}}. Il loro account non è ancora stato approvato per la pianificazione."
      },
      "ISSUE_19": {
        "HEADING": "Documenti dei membri dello staff mancanti/scaduti",
        "BODY": "<b>{{name}}</b> non può funzionare <b>su {{start|momentFormat: 'll'</b>}}}. Uno o più documenti richiesti sono mancanti o scaduti."
      },
      "ISSUE_20": {
        "HEADING": "Turno troppo lungo (minori di 18 anni)",
        "BODY": "La modifica significa che <b>{{name}} funzionerà per {{</b><b>duration|Humaniseduration|Humaniseduration|Humaniseduration|Humaniseduration|Humaniseduration|Humaniseduration</b>}}. Poiché hanno meno di 18 anni, il limite della direttiva sull'orario di lavoro è <b>{{limit|HumaniseDuration</b>}}."
      },
      "ISSUE_21": {
        "HEADING": "Spostamento fuori dai limiti",
        "BODY": "<b>Non puoi programmare un turno per <b>{{name}} su {{</b><b>start|momentFormat: 'll'}} perché appartiene a una rotazione che inizia il {{periodStart|momentFormat: 'll'}</b><b>} e termina il {{periodEnd|momentFormat: 'll'}}}} Momentformat:'LL'SNACK</b>}.</b>"
      }
    }
  },
  "NAV": {
    "HOME": "Home",
    "ROTAS": "Rotas",
    "STAFF": "Personale",
    "ATTENDANCE": "Frequenza",
    "REPORTS": "Rapporti",
    "SETTINGS": "Impostazioni",
    "VIEW_ROTA": "Visualizza rota",
    "SCHEDULE": "La mia agenda",
    "ABSENCE": "La mia assenza",
    "AVAILABILITY": "La mia disponibilità",
    "DOCUMENTS": "I miei documenti",
    "ROTA_DASHBOARD": "Cruscotto",
    "ROTA_ROTA_EDITOR": "Redattore Rota",
    "ROTA_TRONC": "Tronco",
    "ROTA_EVENT_CALENDAR": "Organizzatore di eventi",
    "STAFF_DIRECTORY": "Elenco",
    "STAFF_REQUESTS": "Richieste",
    "STAFF_ABSENCES": "Calendario delle assenze",
    "STAFF_EMPLOYEE_SUBMITTED_DETAILS": "Dettagli inviati dal dipendente",
    "ATTENDANCE_OVERVIEW": "Frequenza",
    "ATTENDANCE_ISSUES": "Problemi",
    "COST_CONTROL": "Controllo dei costi",
    "COST_CONTROL_SIGN_OFF": "Controllo dei costi e approvazione",
    "COST_CONTROL_AGGREGATED_TOTALS": "Totali aggregati",
    "ASSET_REGISTRY": "Registro delle risorse",
    "WFM_DASHBOARD": "I miei cruscotti"
  },
  "SETTINGS": {
    "INDEX": {
      "TITLE": "Impostazioni",
      "PLACEHOLDER_TEXT": "👈 Seleziona un'impostazione a sinistra per iniziare.",
      "CATEGORY_HR": "RISORSE UMANE",
      "CATEGORY_ROTAS": "Rotas",
      "CATEGORY_ATTENDANCE": "Frequenza",
      "CATEGORY_INTEGRATIONS": "Integrazioni",
      "ITEM_ROTA_TEMPLATES": "Modelli Rota",
      "ITEM_TRUSTED_DEVICES": "Dispositivi affidabili",
      "ITEM_SHIFT_LOCATIONS": "Zone di partecipazione",
      "ITEM_SHIFT_TYPES": "Tipi di turni",
      "ITEM_OUTSOURCING": "Esternalizzazione",
      "ITEM_ABSENCE_TYPES": "Tipi di assenza",
      "ITEM_DOCUMENT_CATEGORIES": "Categorie di documenti",
      "ITEM_DOCUMENT_TEMPLATES": "Modelli di documento",
      "ITEM_TAGS_GROUPS": "Tag e gruppi",
      "ITEM_SHIFT_ROUTINES": "Routine a turni",
      "ITEM_REST_BREAKS": "Pause di riposo",
      "ITEM_PAY_ELEMENTS": "Elementi di pagamento",
      "ITEM_PAY_RULES": "Regole di pagamento",
      "ITEM_PERMISSIONS": "Autorizzazioni",
      "ITEM_BUDGETS": "Bilanci",
      "ITEM_ASSET_TYPES": "Tipi di asset",
      "ITEM_ENTITY_GROUP_SUBSCRIPTIONS": 'Gestore accessi al sito',
      "ITEM_ENTITY_GROUPS": 'Responsabile del sito',
      "ITEM_POSITIONS": 'Ruoli di lavoro',
      "ITEM_LEAVER_REASONS": 'Motivi di partenza',
      "ITEM_RECRUITMENT_SOURCES": 'Fonti di reclutamento',
      "ITEM_LABOUR_DEPLOYMENT": 'Distribuzione del lavoro',
    },
    "DOCUMENT_TEMPLATES": {
      "TITLE": "Modelli di documento",
      "FILTER_LABEL_CATEGORY": "Categoria",
      "BUTTON_UPLOAD": "Carica",
      "NAME_COLUMN_LABEL": "Nome",
      "TEMPLATE_CATEGORY_COLUMN_LABEL": "Categoria",
      "FILE_NAME_COLUMN_LABEL": "Nome del file",
      "FILE_SIZE_COLUMN_LABEL": "Dimensione del file",
      "DESTINATION_CATEGORY_COLUMN_LABEL": "Categoria di destinazione",
      "SIGNATORIES_COLUMN_LABEL": "Firmatari",
      "EDITED_COLUMN_LABEL": "Aggiunto da",
      "OPTIONS_COLUMN_LABEL": "Opzioni",
      "LINK_DELETE": "Elimina",
      "SIGNATORIES_BOTH": "Dipendente, datore di lavoro",
      "SIGNATORIES_EMPLOYEE": "Dipendente",
      "NO_DOCS_MATCH_TEXT": "Non siamo riusciti a trovare alcun modello di documento corrispondente ai tuoi filtri.",
      "ERROR_NO_CATEGORIES": "I modelli di documento non sono abilitati per la tua organizzazione. Chatta con noi per iniziare.",
      "ALERT_CONFIRM_DELETE": "Sei sicuro di voler eliminare questo modello? Questo non può essere annullato!",
      "ERROR_500": "Non possiamo caricare modelli di documento in questo momento. Per favore riprova.",
      "ERROR_403": "Non sei autorizzato a visualizzare/modificare i modelli di documento.",
      "UPLOAD_MODAL": {
        "TITLE": "Carica un modello di documento",
        "LABEL_NAME": "Nome",
        "HELP_TEXT_NAME": "Assegna al modello un nome che riconoscerai.",
        "LABEL_CATEGORY": "Categoria modello",
        "HELP_TEXT_CATEGORY": "Mantieni i tuoi modelli organizzati selezionando una categoria.",
        "LABEL_DESTINATION_CATEGORY": "Categoria del documento di destinazione",
        "HELP_TEXT_DESTINATION_CATEGORY": "Quando si utilizza questo modello e si firma un documento generato, scegli la categoria di documenti in cui salvare il file.",
        "LABEL_SIGNATORIES": "Firmatari",
        "SIGNATORIES_OPTION_BOTH": "Sia il dipendente che il datore di lavoro devono firmare.",
        "SIGNATORIES_OPTION_EMPLOYEE": "Solo il dipendente deve firmare.",
        "LABEL_UPLOAD": "Carica",
        "BUTTON_CANCEL": "Annulla",
        "BUTTON_SAVE": "Salva",
        "ALERT_SUCCESS": "Il modello di documento è stato salvato.",
        "ALERT_ERROR_500": "Si è verificato un errore durante il caricamento del modello di documento. Riprova o contattaci se il messaggio persiste.",
        "ALERT_ERROR_400": "C'è qualcosa che non va nel tuo modello di documento."
      }
    },
    "ABSENCE_TYPES": {
      "TITLE": "Tipi di assenza",
      "ERROR_500": "Non possiamo caricare i tipi di assenza in questo momento. Per favore riprova.",
      "ERROR_403": "Non sei autorizzato a visualizzare/modificare i tipi di assenza.",
      "BUTTON_ADD_NEW": "Aggiungi nuovo",
      "BUTTON_TOGGLE_DELETED": "Includi eliminato",
      "COLUMN_NAME": "Nome",
      "COLUMN_BOOKABLE": "Prenotabile",
      "COLUMN_APPROVAL": "Approvazione richiesta",
      "COLUMN_PAID": "Pagato",
      "COLUMN_EDITED": "A cura di",
      "COLUMN_OPTIONS": "Opzioni",
      "YES_LABEL": "sì",
      "NO_LABEL": "No",
      "LINK_EDIT": "Modifica",
      "LINK_DELETE": "Elimina",
      "LINK_REINSTATE": "Ripristinare",
      "PILL_DELETED": "Eliminato",
      "ALERT_CONFIRM_DELETE": "Sei sicuro di voler eliminare questo tipo di assenza?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Il tipo di assenza è stato salvato.",
        "ALERT_ERROR_500": "Si è verificato un errore durante la creazione del tipo di assenza. Riprova o contattaci se il messaggio persiste.",
        "ALERT_ERROR_400": "Esiste già un tipo di assenza con quel nome. Per favore scegline un altro.",
        "ADD_TITLE": "Nuovo tipo di assenza",
        "EDIT_TITLE": "Modifica il tipo di assenza",
        "BUTTON_SAVE": "Salva",
        "BUTTON_CANCEL": "Annulla",
        "LABEL_NAME": "Nome",
        "LABEL_COLOUR": "Colore",
        "LABEL_BOOKABLE": "Prenotabile",
        "LABEL_BOOKABLE_HELP": "Consenti ai dipendenti di effettuare richieste di assenza di questo tipo.",
        "LABEL_APPROVAL": "Approvazione",
        "LABEL_APPROVAL_HELP": "Le richieste di questo tipo richiedono l'approvazione.",
        "LABEL_PAID": "Pagato",
        "LABEL_PAID_HELP": "L'assenza di questo tipo viene pagata.",
        "LABEL_WORKED_HOURS": "Ore lavorate",
        "LABEL_WORKED_HOURS_HELP": "L'assenza di questo tipo contribuisce all' \"orario di lavoro».",
        "LABEL_ACCRUES": "Ferie maturate",
        "LABEL_ACCRUES_HELP": "I dipendenti matureranno ferie per assenza di questo tipo se dispongono di un'indennità per ferie basata sulla competenza.",
        "LABEL_OVERLAPS_SHIFTS": "Partita a turni",
        "LABEL_OVERLAPS_SHIFTS_HELP": "L'assenza di questo tipo può corrispondere a turni, al posto di un orologio in entrata e in uscita.",
        "LABEL_TRONC": "Tronco",
        "LABEL_TRONC_HELP": "L'assenza di questo tipo contribuisce all'orario di lavoro in Tronc.",
        "LABEL_ALLOWANCE_REQUIRED": "Indennità richiesta",
        "LABEL_ALLOWANCE_REQUIRED_HELP": "Per richiedere un'assenza di questo tipo, assicurati che il dipendente abbia un'indennità attiva.",
        "TITLE_FILE_UPLOADS": "Caricamenti di file",
        "LABEL_SUBMISSION_UPLOAD": "Su richiesta",
        "LABEL_SUBMISSION_UPLOAD_HELP": "Consente di allegare un file quando viene richiesta l'assenza di questo tipo.",
        "LABEL_REVIEW_UPLOAD": "In fase di revisione",
        "LABEL_REVIEW_UPLOAD_HELP": "Consente di allegare un file quando un'assenza di questo tipo viene approvata o rifiutata.",
        "LABEL_SAVE_TO": "Salva in",
        "HELP_TEXT_SAVE_TO": "Quando un file viene allegato ad un'assenza di questo tipo, verrà salvato nei documenti del dipendente nella categoria scelta.",
        "FILE_UPLOAD_OPTION_NONE": "Disattivata",
        "FILE_UPLOAD_OPTION_OPTIONAL": "Facoltativo",
        "FILE_UPLOAD_OPTION_MANDATORY": "Richiesto",
        "INFO_LOCKED": "Si tratta di un tipo di assenza speciale utilizzato da Rotaready. Abbiamo nascosto alcune proprietà che non possono essere modificate. Contattaci se hai bisogno di aiuto per questo tipo di assenza.",
        "TITLE_PAY": "Paga",
        "LABEL_FIXED_AMOUNTS": "Importi fissi",
        "HELP_TEXT_FIXED_AMOUNTS": "Quando si paga a un dipendente un importo fisso, ad esempio il pagamento per le assenze maturate ma non godute, verrà utilizzato questo elemento retributivo.",
        "TITLE_REASONS": "Motivi",
        "COLUMN_TITLE": "Motivo",
        "COLUMN_OPTIONS": "Opzioni",
        "LINK_DELETE": "Elimina",
        "NO_REASONS": "Non è stato aggiunto alcun motivo.",
        "PLACEHOLDER_REASON": "Nuovo motivo...",
        "LINK_ADD": "Inserisci"
      }
    },
    "OUTSOURCING": {
      "TITLE": "Esternalizzazione",
      "BUTTON_ADD_NEW": "Aggiungi nuovo",
      "BUTTON_TOGGLE_DELETED": "Includi eliminato",
      "NO_DATA_TEXT": "Non ci sono organizzazioni di outsourcing da mostrare.",
      "NAME_COLUMN_TEXT": "Nome",
      "ENTITY_COLUMN_TEXT": "Sito/dipartimento",
      "RESOURCES_COLUMN_TEXT": "Risorse",
      "HOURS_COLUMN_TEXT": "Ore disponibili",
      "COST_COLUMN_TEXT": "Costo",
      "LAST_EDITED_COLUMN_TEXT": "A cura di",
      "OPTIONS_COLUMN_TEXT": "Opzioni",
      "UNLIMITED_TEXT": "Illimitato",
      "ALWAYS_24_TEXT": "Sempre (24 ore)",
      "PER_HOUR_TEXT": "/ora",
      "PER_DAY_TEXT": "/giorno",
      "ORG_WIDE_TEXT": "A livello di organizzazione",
      "LINK_EDIT": "Modifica",
      "LINK_DELETE": "Elimina",
      "LINK_REINSTATE": "Ripristinare",
      "PILL_DELETED": "Eliminato",
      "ERROR_500": "Non possiamo caricare le organizzazioni di outsourcing in questo momento. Per favore riprova.",
      "ERROR_403": "Non sei autorizzato a visualizzare/modificare le organizzazioni di outsourcing.",
      "ALERT_CONFIRM_DELETE": "Sei sicuro di voler eliminare questa organizzazione di outsourcing?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "La tua organizzazione di outsourcing è stata salvata.",
        "ALERT_ERROR_500": "Si è verificato un errore durante la creazione della tua organizzazione di outsourcing. Riprova o contattaci se il messaggio persiste.",
        "ALERT_ERROR_400": "Esiste già un'organizzazione di outsourcing con quel nome. Per favore scegline un altro.",
        "EDIT_TITLE": "Modifica organizzazione",
        "ADD_TITLE": "Aggiungi organizzazione",
        "NAME_LABEL": "Nome",
        "ENTITY_LABEL": "Sito/dipartimento",
        "PAY_LABEL": "Paga",
        "HOURLY_OPTION": "Retribuzione oraria",
        "FIXED_OPTION": "Costo fisso giornaliero",
        "HOURLY_RATE_LABEL": "Tariffa oraria",
        "DAILY_RATE_LABEL": "Tariffa giornaliera",
        "AUTOMATIC_SCHEDULE_TITLE": "Pianificazione automatica",
        "HOURS_AVAILABLE_LABEL": "Ore disponibili",
        "TO_TEXT": " a ",
        "HOURS_AVAILABLE_HELP": "Il periodo durante il quale i lavoratori di questa organizzazione sono disponibili",
        "RESOURCES_AVAILABLE_LABEL": "Risorse disponibili",
        "RESOURCES_AVAILABLE_HELP": "Il numero massimo di lavoratori a tua disposizione al giorno (inserisci 0 per numero illimitato)",
        "TAGS_LABEL": "Etichette",
        "TAGS_PLACEHOLDER": "Digita per cercare i tag",
        "TAGS_HELP": "Assegna i tag come faresti con un normale membro dello staff. Questi si applicheranno a tutti i lavoratori di questa organizzazione.",
        "BUTTON_SAVE": "Salva",
        "BUTTON_CANCEL": "Annulla"
      }
    },
    "TRUSTED_DEVICES": {
      "TITLE": "Dispositivi affidabili",
      "DEVICE": "Dispositivo",
      "ENTITY": "Sede principale/dipartimento",
      "DEVICE_TYPE": "Agente utente",
      "EDITED_BY": "A cura di",
      "LAST_ACTIVITY": "Ultima attività",
      "STATUS": "Status",
      "OPTIONS": "Opzioni",
      "NO_DEVICES": "Non esistono dispositivi affidabili per la registrazione delle presenze.",
      "NO_ACTIVITY": "Nessuna attività",
      "LINK_UNTRUST": "Infiducia",
      "LINK_TRUST": "Fiducia",
      "LINK_EDIT": "Modifica",
      "ALERT_CONFIRM_TRUST": "Sei sicuro di volerti affidare a questo dispositivo per l'acquisizione delle presenze?",
      "ALERT_CONFIRM_UNTRUST": "Sei sicuro di non voler fidarti di questo dispositivo in modo che non possa più essere utilizzato per l'acquisizione delle presenze? Questo non può essere invertito.",
      "ERROR_500": "Al momento non possiamo caricare dispositivi affidabili. Per favore riprova.",
      "ERROR_403": "Non sei autorizzato a visualizzare/modificare i dispositivi attendibili.",
      "STATUS_PENDING": "In sospeso",
      "STATUS_TRUSTED": "Affidabile",
      "STATUS_UNTRUSTED": "Non affidabile",
      "FILTER_SITE": "Sito/dipartimento mappato",
      "FILTER_STATUS": "Status",
      "BUTTON_NEW_DEVICE": "Nuovo dispositivo",
      "NEW_DEVICE_MODAL": {
        "TITLE": "Aggiungi un nuovo dispositivo",
        "BUTTON_CANCEL": "Annulla",
        "BUTTON_DONE": "Continua",
        "TEXT_STEP_1": "Fase 1",
        "TEXT_STEP_2": "Fase 2",
        "TEXT_STEP_1_BODY": "Prendi il tuo tablet o laptop, apri un browser web e vai al seguente URL:",
        "TEXT_STEP_2_BODY_A": "Quando viene richiesto un «Realm ID», inserisci il seguente valore:",
        "TEXT_STEP_2_BODY_B": "Quando viene richiesto un «ID entità», inserisci il seguente valore:",
        "TEXT_STEP_3_BODY": "Al termine, fai clic su Continua per tornare all'elenco dei dispositivi. Il tuo nuovo dispositivo dovrebbe quindi apparire. Fai clic su Trust accanto al dispositivo per completare il processo di associazione."
      },
      "EDIT_DEVICE_MODAL": {
        "TITLE": "Modifica dispositivo",
        "BUTTON_CANCEL": "Annulla",
        "BUTTON_SAVE": "Salva",
        "BODY": "Selezionando altri siti o reparti di seguito, i dipendenti associati verranno visualizzati nella schermata principale del dispositivo. Non è possibile deselezionare il sito/reparto principale; è necessario non fidarsi del dispositivo se si desidera modificare questa impostazione.",
        "ALERT_200": "Il dispositivo è stato aggiornato.",
        "ALERT_500": "Non siamo riusciti ad aggiornare il dispositivo in quel momento. Riprova più tardi.",
        "LABEL_NAME": "Nome del dispositivo"
      }
    }
  },
  "MESSAGING": {
    "BETA": "BETA",
    "MESSAGE_HUB": {
      "TITLE": "Messaggi",
      "NO_MESSAGES": "Nessun messaggio",
      "START_NEW": "Inizia una nuova conversazione",
      "YESTERDAY": "ieri"
    },
    "CONVERSATION": {
      "MESSAGE_PLACEHOLDER": "Aggiungi il tuo messaggio",
      "INDIVIDUAL_TYPING": "{{name}} sta digitando...",
      "MULTIPLE_TYPING": "Più persone stanno digitando..."
    },
    "START_NEW": {
      "TITLE": "Inizia una nuova conversazione",
      "TO": "A:",
      "REMOVE": "Rimuovi",
      "START_CONVERSATION": "Inizia la conversazione"
    },
    "ALERTS": {
      "START_CONVERSATION": "Si è verificato un errore durante l'avvio della conversazione. Per favore riprova.",
      "SUBSCRIBE_TO_CHANNELS": "Si è verificato un problema durante l'iscrizione alle tue conversazioni. Potresti non ricevere alcun messaggio. Aggiorna o contattaci se il problema persiste.",
      "FETCH_MESSAGES": "Si è verificato un problema durante la ricezione dei messaggi. Aggiorna o contattaci se il problema persiste.",
      "FETCH_NEW_MESSAGES": "Si è verificato un errore durante la ricezione di nuovi messaggi. Aggiorna o contattaci se il problema persiste.",
      "FETCH_CONVERSATIONS": "Si è verificato un errore durante il recupero delle tue conversazioni. Aggiorna o contattaci se il problema persiste.",
      "SEND_MESSAGE": "Si è verificato un errore nell'invio del messaggio. Per favore riprova."
    }
  },
  "ABSENCE": {
    "REQUEST": {
      "TITLE": "Richiesta di assenza",
      "BUTTON_SUBMIT": "Invia",
      "LABEL_TYPE": "Scegli un tipo",
      "LABEL_REASON": "Seleziona un motivo",
      "LABEL_EMPLOYEE": "Dipendente",
      "LABEL_START_DATE": "Data di inizio",
      "LABEL_END_DATE": "Data di fine",
      "LABEL_SCOPE_ALL_DAY": "Tutto il giorno",
      "LABEL_SCOPE_AM": "Solo AM",
      "LABEL_SCOPE_PM": "Solo PM",
      "LABEL_SCOPE_CUSTOM": "Scegli orari specifici",
      "LABEL_START_TIME": "Ora di inizio",
      "LABEL_END_TIME": "Ora di fine",
      "ALERT_OVERLAPPING_ABSENCE_USER": "Hai un'altra prenotazione di assenza che coincide con le date scelte.",
      "ALERT_OVERLAPPING_ABSENCE_OTHERS": "Giusto perché tu lo sappia, ci sono anche altre persone assenti in questo momento.",
      "ERROR_500": "Abbiamo avuto un intoppo proprio in quel momento. Per favore riprova!",
      "LABEL_DAYS": "giorni",
      "LABEL_HOURS": "ore",
      "SECTION_UNPAID_DAYS": "Giorni liberi regolari",
      "HELP_TEXT_NO_UNPAID_DAYS": "Non ci sono giorni liberi regolari in questa richiesta.",
      "BUTTON_OPTIONS": "Opzioni",
      "BUTTON_CHANGE": "Cambia i giorni di riposo regolari",
      "BUTTON_RESET": "Riporta i giorni di riposo normali a quelli consigliati",
      "BUTTON_CLEAR": "Cancella tutti i normali giorni liberi",
      "MODAL_CALENDAR_TITLE": "Scegli giorni liberi regolari",
      "MODAL_BUTTON_SAVE": "Salva",
      "LABEL_ADD_A_NOTE": "Aggiungi una nota a questa richiesta",
      "BUTTON_COMPLETE": "Riportami indietro",
      "COMPLETE_TITLE": "È tutto pronto.",
      "COMPLETE_LABEL_APPROVED": "Approvato",
      "COMPLETE_TEXT_APPROVED": "La tua richiesta è stata inoltrata e approvata automaticamente ✅",
      "COMPLETE_LABEL_PENDING": "In attesa di approvazione",
      "COMPLETE_TEXT_PENDING": "La tua richiesta è stata inviata ed è in attesa di approvazione. Ti avviseremo quando questo cambierà 👍"
    },
    "VALIDATION_MODAL": {
      "TITLE": "C'è qualcosa che non va",
      "DESCRIPTION": "Sfortunatamente ciò che stai tentando di fare causa uno o più problemi.",
      "BUTTON_CLOSE": "Chiudi",
      "INVALID_DATES_TITLE": "Date non valide",
      "INVALID_DATES_DESCRIPTION": "Puoi scegliere solo AM, solo PM o orari personalizzati se la data di inizio e fine è lo stesso giorno.",
      "INVALID_HEADER_TITLE": "Record di assenza non valido",
      "INVALID_HEADER_DESCRIPTION": "Non puoi modificare quel record di assenza. Potrebbe essere stato eliminato.",
      "INVALID_ACTION_TITLE": "Azione non valida",
      "INVALID_ACTION_DESCRIPTION": "Non è possibile modificare l'orario di una prenotazione per assenza dopo che è stata rifiutata o cancellata.",
      "INVALID_DAYS_TITLE": "Giorni non validi",
      "INVALID_DAYS_DESCRIPTION": "Non puoi effettuare una richiesta di assenza per zero ore o zero giorni.",
      "OUTSIDE_EMPLOYMENT_TITLE": "Occupazione esterna",
      "OUTSIDE_EMPLOYMENT_DESCRIPTION": "La data di inizio o di fine scelta non rientra nelle date di assunzione del dipendente.",
      "SPANS_TIMEZONES_TITLE": "L'intervallo di date si estende su due fusi orari",
      "SPANS_TIMEZONES_DESCRIPTION": "I registri degli appuntamenti del dipendente indicano che il dipendente si è trasferito durante le date scelte e che il suo sito esistente è in un fuso orario diverso da quello del nuovo sito. Assicurati che la tua prenotazione di assenze termini prima che il cliente si sposti o inizi dopo il trasferimento del sito.",
      "SPANS_PAYROLL_CALENDARS_TITLE": "L'intervallo di date si estende su due calendari delle retribuzioni",
      "SPANS_PAYROLL_CALENDARS_DESCRIPTION": "I registri degli appuntamenti del dipendente indicano che il calendario delle retribuzioni viene modificato durante le date scelte. Assicurati che la tua prenotazione delle assenze termini prima che cambino il calendario delle retribuzioni o inizi dopo la modifica del calendario delle retribuzioni.",
      "INVALID_PAYROLL_CALENDAR_TITLE": "Calendario delle retribuzioni non valido",
      "INVALID_PAYROLL_CALENDAR_DESCRIPTION": "L'intervallo di date scelto inizia prima della prima serie retributiva del calendario delle retribuzioni del dipendente.",
      "OVERLAPPING_ABSENCE_TITLE": "Assenza sovrapposta",
      "OVERLAPPING_ABSENCE_DESCRIPTION": "Il dipendente è già assente in uno o più giorni che si sovrappongono alle date scelte.",
      "OVERLAPPING_SHIFTS_TITLE": "Turni sovrapposti",
      "OVERLAPPING_SHIFTS_DESCRIPTION": "Il dipendente ha uno o più turni pubblicati che si sovrappongono alle date scelte. Questi devono prima essere eliminati o annullati.",
      "SIGNED_OFF_TITLE": "Date di sottoscrizione",
      "SIGNED_OFF_DESCRIPTION": "Le date si sovrappongono a uno o più giorni di disconnessione. Non puoi approvare o annullare l'assenza per giorni che sono già stati disattivati.",
      "ALLOWANCE_REQUIRED_TITLE": "Indennità richiesta",
      "ALLOWANCE_REQUIRED_DESCRIPTION": "Non è possibile prenotare un'assenza di questo tipo. Deve esserci un'indennità attiva da cui detrarre.",
      "ALLOWANCE_NOT_DEDUCTIBLE_TITLE": "Indennità non deducibile",
      "ALLOWANCE_NOT_DEDUCTIBLE_DESCRIPTION": "Non è possibile prenotare un'assenza di questo tipo. C'è un'indennità attiva ma è contrassegnata come non deducibile.",
      "ALLOWANCE_BALANCE_INSUFFICIENT_TITLE": "Saldo insufficiente",
      "ALLOWANCE_BALANCE_INSUFFICIENT_DESCRIPTION": "Il saldo delle indennità è troppo basso per presentare questa richiesta.",
      "FILE_UPLOAD_REQUIRED_TITLE": "Caricamento del file richiesto",
      "FILE_UPLOAD_REQUIRED_DESCRIPTION": "Questo tipo di assenza richiede il caricamento di un file prima che venga inviato o approvato."
    },
    "VIEW_MODAL": {
      "TITLE": "Assenza",
      "BUTTON_OPTIONS": "Opzioni",
      "BUTTON_EDIT": "Modifica/rivedi la prenotazione",
      "BUTTON_CANCEL": "Cancellare la prenotazione",
      "BUTTON_CLOSE": "Chiudi",
      "STATUS_CANCELLED": "Annullato",
      "LABEL_START_DATE": "Inizia",
      "LABEL_END_DATE": "Termina",
      "LABEL_ABSENCE_TYPE": "Tipo",
      "LABEL_SCOPE": "Durata",
      "ERROR_500": "Abbiamo avuto un piccolo intoppo. Per favore riprova.",
      "ERROR_404": "Tale richiesta di assenza non esiste più.",
      "LABEL_SUBMITTED_BY": "Inviato da",
      "LABEL_REVIEWED_BY": "Recensito da",
      "LABEL_CANCELLED_BY": "Annullato da",
      "LABEL_EDITED_BY": "A cura di",
      "DURATION_TEXT": "{{hours}} ore, {{days}} giorni",
      "TAB_BREAKDOWN": "Ripartizione",
      "TITLE_PAY_PERIODS": "Per periodo di retribuzione",
      "NO_PAY_PERIODS": "Il dipendente non era associato a un calendario delle retribuzioni al momento della prenotazione.",
      "TITLE_DAILY_BREAKDOWN": "Di giorno",
      "NO_DAYS": "Non ci sono giorni in questa prenotazione.",
      "TAB_FILES": "Documenti",
      "FILES_NONE": "Non è stato caricato nulla per questa prenotazione.",
      "FILES_AVAILABLE": "Questi documenti sono disponibili anche nell'area Staff quando si visualizza il profilo del dipendente. Li troverai in questa categoria: {{categoryName}}",
      "FILES_COLUMN_NAME": "Nome",
      "FILES_COLUMN_UPLOADED_BY": "Caricato da",
      "FILES_COLUMN_OPTIONS": "Opzioni",
      "FILES_LINK_DELETE": "Elimina",
      "FILES_CONFIRM_DELETE": "Sei sicuro di volerlo eliminare? Verrà inoltre rimosso dalla scheda Documenti del profilo del dipendente.",
      "CONFIRM_CANCEL": "Sei sicuro di voler cancellare questa prenotazione di assenza? Questo non può essere annullato.",
      "ALERT_IS_CANCELLED": "La prenotazione dell'assenza è stata cancellata.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Avvertenza! Il richiedente ha scelto un orario di inizio e fine che equivale a più di {{halfDayHours}} ore, che è il numero di ore in cui attualmente lavora per mezza giornata.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Avvertenza! Il richiedente ha scelto di modificare i giorni di ferie regolari, anziché utilizzare i giorni suggeriti dal Rotaready."
    },
    "EDIT": {
      "TITLE": "Assenza",
      "BUTTON_SUBMIT": "Salva",
      "BUTTON_APPROVE": "Approvare",
      "BUTTON_REJECT": "Rifiuta",
      "TAB_EDIT_BOOKING": "Modifica date",
      "TAB_REVIEW": "Recensione",
      "TAB_FILES": "Documenti",
      "TAB_CALENDAR": "Calendario",
      "LABEL_TYPE": "Tipo",
      "LABEL_REASON": "Seleziona un motivo",
      "LABEL_EMPLOYEE": "Dipendente",
      "LABEL_START_DATE": "Data di inizio",
      "LABEL_END_DATE": "Data di fine",
      "LABEL_SCOPE_ALL_DAY": "Tutto il giorno",
      "LABEL_SCOPE_AM": "Solo AM",
      "LABEL_SCOPE_PM": "Solo PM",
      "LABEL_SCOPE_CUSTOM": "Scegli orari specifici",
      "LABEL_START_TIME": "Ora di inizio",
      "LABEL_END_TIME": "Ora di fine",
      "ERROR_500": "Abbiamo avuto un intoppo proprio in quel momento. Per favore riprova!",
      "LABEL_DAYS": "giorni",
      "LABEL_HOURS": "ore",
      "SECTION_UNPAID_DAYS": "Giorni liberi regolari",
      "HELP_TEXT_NO_UNPAID_DAYS": "Non ci sono giorni liberi regolari in questa richiesta.",
      "BUTTON_OPTIONS": "Opzioni",
      "BUTTON_CHANGE": "Cambia i giorni di riposo regolari",
      "BUTTON_RESET": "Torna alla prenotazione originale",
      "BUTTON_RECALCULATE": "Ricalcola nuovamente",
      "BUTTON_CLEAR": "Cancella tutti i normali giorni liberi",
      "UNPAID_DAYS_RECALCULATED_LABEL": "Ricalcolato",
      "UNPAID_DAYS_RECALCULATED_HELP": "Hai scelto di calcolare nuovamente i giorni di ferie regolari e di utilizzare il valore prevalente per le «ore per giorno lavorativo» del dipendente anziché quello che era al momento della prenotazione originale.",
      "UNPAID_DAYS_MERGED_LABEL": "Unito",
      "UNPAID_DAYS_MERGED_HELP": "Da quando hai modificato la data, abbiamo incluso eventuali nuovi giorni di ferie, rimosso quelli che non rientrano più nell'ambito di applicazione e abbiamo lasciato intatti gli altri.",
      "MODAL_CALENDAR_TITLE": "Scegli giorni liberi regolari",
      "MODAL_BUTTON_SAVE": "Salva",
      "BUTTON_COMPLETE": "Riportami indietro",
      "COMPLETE_TITLE": "Sei pronto per andare.",
      "COMPLETE_TEXT": "Abbiamo salvato la prenotazione delle assenze e le persone interessate sono state avvisate 👍",
      "INVALID_HEADER_TITLE": "Assenza non valida",
      "INVALID_HEADER_TEXT": "Non possiamo caricare la prenotazione delle assenze. Forse è stato cancellato o non hai il permesso di vederlo.",
      "TITLE_OVERLAPPING_ABSENCE": "Assenza concomitante",
      "TITLE_OVERLAPPING_SHIFTS": "Turni sovrapposti",
      "TITLE_NOTABLE_EVENTS": "Eventi degni di nota",
      "COLUMN_OVERLAPPING_ABSENCE_DATES": "Date",
      "COLUMN_OVERLAPPING_ABSENCE_TYPE": "Tipo",
      "COLUMN_OVERLAPPING_ABSENCE_WHO": "Chi",
      "NO_OVERLAPPING_ABSENCE": "Non ci sono assenze simultanee corrispondenti ai tuoi filtri.",
      "NO_OVERLAPPING_SHIFTS": "Non ci sono turni sovrapposti nella sede/nel reparto del dipendente.",
      "NO_NOTABLE_EVENTS": "Non ci sono eventi degni di nota al momento di questa assenza.",
      "COLUMN_OVERLAPPING_SHIFTS_DATE": "Data",
      "COLUMN_OVERLAPPING_SHIFTS_SITE": "Sito/dipartimento",
      "COLUMN_OVERLAPPING_SHIFTS_TYPE": "Tipo di turno",
      "COLUMN_OVERLAPPING_SHIFTS_OPTIONS": "Opzioni",
      "LINK_EDIT_SHIFT": "Modifica rotazione",
      "LINK_DELETE_SHIFT": "Elimina",
      "BUTTON_BULK_SHIFT_OPTIONS": "Azioni in blocco",
      "BUTTON_BULK_SHIFT_UNASSIGN_ALL": "Annulla l'assegnazione della selezione",
      "BUTTON_BULK_SHIFT_DELETE_ALL": "Elimina selezionati",
      "ALERT_BULK_SHIFT_CONFIRM": "Sei sicuro di volerlo fare a {{count}} turni?",
      "ALERT_BULK_SHIFT_EDIT_FAILURE": "Non abbiamo potuto modificare i turni perché ciò causerebbe un problema di convalida. Apporta questa modifica alla rotazione per visualizzare ulteriori informazioni.",
      "ALERT_SINGLE_SHIFT_DELETE_FAILURE": "Non abbiamo potuto eliminare quel turno perché causerebbe un problema di convalida. Apporta questa modifica alla rotazione per visualizzare ulteriori informazioni.",
      "COLUMN_NOTABLE_EVENTS_NAME": "Nome",
      "COLUMN_NOTABLE_EVENTS_DATE": "Data",
      "NOTABLE_EVENTS_ABSENCE_RESTRICTION_TOOLTIP": "L'assenza è limitata durante questo evento.",
      "LABEL_ADD_A_NOTE": "Facoltativamente, scrivi un messaggio per il dipendente",
      "OVERLAPPING_SHIFTS_MORE": "Stiamo mostrando solo i primi quindici turni sovrapposti. Risolvili per saperne di più.",
      "OVERLAPPING_ABSENCE_MORE": "Stiamo mostrando solo le prime quindici prenotazioni sovrapposte.",
      "CALENDAR_UNPAID_DAY": "Giorno libero regolare",
      "FILTER_LABEL_ENTITY": "Sito/dipartimento",
      "FILTER_LABEL_STAFF_GROUP": "Gruppo di personale",
      "FILTER_LABEL_ABSENCE_TYPE": "Tipo",
      "FILES_NONE": "Non è stato caricato nulla per questa prenotazione.",
      "FILES_AVAILABLE": "Questi documenti sono disponibili anche nell'area Staff quando si visualizza il profilo del dipendente. Li troverai in questa categoria: {{categoryName}}",
      "FILES_COLUMN_NAME": "Nome",
      "FILES_COLUMN_UPLOADED_BY": "Caricato da",
      "FILES_COLUMN_OPTIONS": "Opzioni",
      "FILES_LINK_DELETE": "Elimina",
      "FILES_CONFIRM_DELETE": "Sei sicuro di volerlo eliminare? Verrà inoltre rimosso dalla scheda Documenti del profilo del dipendente.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Avvertenza! Il richiedente ha scelto un orario di inizio e fine che equivale a più di {{halfDayHours}} ore, che è il numero di ore in cui attualmente lavora per mezza giornata.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Avvertenza! Il richiedente ha scelto di modificare i giorni di ferie regolari, anziché utilizzare i giorni suggeriti dal Rotaready. Rivedili attentamente nella scheda Modifica date e scegli l'opzione Ricalcola nuovamente se necessario."
    },
    "ENUM_SCOPE": {
      "ALL_DAY": "Tutto il giorno",
      "AM": "Solo AM",
      "PM": "Solo PM",
      "CUSTOM": "Orari personalizzati"
    },
    "ENUM_APPROVAL_STATE": {
      "APPROVED": "Approvato",
      "REJECTED": "Respinto",
      "PENDING": "In sospeso"
    },
    "ENUM_ALLOWANCE_UNIT": {
      "DAYS": "Giorni",
      "HOURS": "Ore"
    },
    "ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL": {
      "BEGINNING_OF_ACCRUAL_YEAR": "Inizio dell'anno di competenza",
      "EACH_CALENDAR_MONTH": "Ogni mese di calendario",
      "EACH_HOUR_WORKED": "Ogni ora lavorata"
    },
    "ALLOWANCE_IMPACT_DIRECTIVE": {
      "DAYS_DEBIT": "{{count}} detrazione giornaliera",
      "HOURS_DEBIT": "{{count}} detrazione oraria",
      "DAYS_CREDIT": "{{count}} credito giornaliero",
      "HOURS_CREDIT": "{{count}} credito orario",
      "REMAINING": "({{count}} rimanente)",
      "WARNING_EXCEEDS": "La tua richiesta supera questo limite ma può comunque essere inoltrata.",
      "WARNING_NOT_PERMITTED": "La tua richiesta supera questo limite.",
      "TOOLTIP_PROJECTED": "Il Rotaready ha incluso una previsione di ciò che è probabile che tu accumuli. Questo può essere limitato in base alle politiche del datore di lavoro.",
      "CALC_TOTAL_ACCRUED": "Totale maturato",
      "CALC_BOOKED": "Prenotato",
      "CALC_PROJECTED": "Accantonamento previsto",
      "CALC_THIS_REQUEST": "Questa richiesta",
      "CALC_REMAINING": "Restante",
      "CALC_CONVERTED_TO_DAYS": "Come giorni"
    }
  }
};

module.exports = translations;
