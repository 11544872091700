module.exports = (
  $scope,
  $state,
  $stateParams,
  $http,
  $translate,
  EnvironmentDataService,
  AlertService,
  ExportService,
  ENDPOINT_API,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.NEW_HIRE_TURNOVER.TITLE',
    'REPORTS.NEW_HIRE_TURNOVER.DATE_ERROR_DIFF',
    'REPORTS.NEW_HIRE_TURNOVER.ERROR_500',
    'REPORTS.NEW_HIRE_TURNOVER.AGGREGATION_ENTITY_GROUP',
    'REPORTS.NEW_HIRE_TURNOVER.AGGREGATION_POSITION',
    'REPORTS.NEW_HIRE_TURNOVER.AGGREGATION_STAFF_GROUP',
  ]);

  const defaultDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('week').subtract(1, 'week');
  const defaultDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('week').subtract(1, 'day');
  const defaultAggregationOptions = ['entityGroup'];
  const defaultDepartedWithinDays = $stateParams.days
    ? parseInt($stateParams.days, 10) : 90;
  const selectedLeaverReasonIds = $stateParams.leaverReasonIds
    ? $stateParams.leaverReasonIds.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];

  const departedWithinDays = [7, 14, 21, 30, 60, 90, 120, 180, 360, 365];

  $scope.props = {
    loadingData: false,
    data: {},
    defaultDateFilter: {
      option: 4,
      dateStart: defaultDateStart,
      dateEnd: defaultDateEnd,
    },
    selectedDateStart: defaultDateStart,
    selectedDateEnd: defaultDateEnd,
    aggregationOptions: [
      { id: 'entityGroup', label: translations['REPORTS.NEW_HIRE_TURNOVER.AGGREGATION_ENTITY_GROUP'] },
      { id: 'position', label: translations['REPORTS.NEW_HIRE_TURNOVER.AGGREGATION_POSITION'] },
      { id: 'staffGroup', label: translations['REPORTS.NEW_HIRE_TURNOVER.AGGREGATION_STAFF_GROUP'] },
    ],
    selectedAggregationOptions: defaultAggregationOptions,
    departedWithinDaysOptions: departedWithinDays.map((count) => ({
      id: count,
      label: $translate.instant('REPORTS.NEW_HIRE_TURNOVER.DAYS_LABEL', { count }),
    })),
    defaultDepartedWithinDays: [defaultDepartedWithinDays],
    selectedDepartedWithinDays: defaultDepartedWithinDays,
    selectedLeaverReasonIds,
  };

  function evaluateQueryParams() {
    const {
      selectedDateStart: start,
      selectedDateEnd: end,
      selectedDepartedWithinDays: days,
      selectedLeaverReasonIds,
    } = $scope.props;

    $state.go('.', {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
      days,
      leaverReasonIds: selectedLeaverReasonIds ? selectedLeaverReasonIds.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $scope.getDataParams = {
      employmentStartsBetween: [
        start.format('YYYY-MM-DD'),
        end.clone().add(1, 'day').format('YYYY-MM-DD'),
      ].join('/'),
      departedWithinDays: days,
      'leaverReasonIds[]': selectedLeaverReasonIds,
    };
  }

  $scope.onDateFilter = ({
    dateEnd,
    dateStart,
  }) => {
    $scope.props.selectedDateStart = dateStart;
    $scope.props.selectedDateEnd = dateEnd;

    $scope.loadData();
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    let dateStart;
    let dateEnd;

    if (when === 'thisWeek') {
      dateStart = moment().startOf('week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastWeek') {
      dateStart = moment().startOf('week').subtract(1, 'week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastTwoWeeks') {
      dateStart = moment().startOf('week').subtract(2, 'weeks');
      dateEnd = dateStart.clone().add(13, 'days');
    } else if (when === 'lastMonth') {
      dateStart = moment().startOf('month').subtract(1, 'month');
      dateEnd = moment().startOf('month').subtract(1, 'day');
    }

    $scope.props.defaultDateFilter = {
      option: 4,
      dateStart,
      dateEnd,
    };

    $scope.onDateFilter({
      dateStart,
      dateEnd,
    });
  };

  $scope.onAggregationFilter = (selectedOptions) => {
    $scope.props.selectedAggregationOptions = selectedOptions;
  };

  $scope.onDepartedWithinDaysChange = (selectedOptions) => {
    $scope.props.selectedDepartedWithinDays = selectedOptions[0];
    $scope.loadData();
  };

  $scope.onLeaverReasonFilter = (selectedOptions) => {
    $scope.props.selectedLeaverReasonIds = selectedOptions;
    $scope.loadData();
  };

  $scope.loadData = () => {
    const diff = $scope.props.selectedDateEnd.clone().add(1, 'day').diff($scope.props.selectedDateStart, 'months', true);

    if (diff < 0 || diff > 12) {
      AlertService.add('info', translations['REPORTS.NEW_HIRE_TURNOVER.DATE_ERROR_DIFF']);
      return;
    }

    evaluateQueryParams();

    $scope.props.loadingData = true;

    $http.get(`${ENDPOINT_API}/report/newHireTurnover`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const { results } = data;
        $scope.props.data = results;
        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.NEW_HIRE_TURNOVER.ERROR_500']);
        }
      });
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.Position,
    EnvironmentDataService.DataType.StaffGroup,
    EnvironmentDataService.DataType.LeaverReason,
  ])
    .then(([
      entityGroup,
      position,
      staffGroup,
      leaverReason,
    ]) => {
      $scope.props.entityGroupById = {};
      $scope.props.entityById = {};
      $scope.props.positionById = {};
      $scope.props.staffGroupById = {};

      entityGroup.data.forEach((group) => {
        $scope.props.entityGroupById[group.id] = group;

        group.entities.forEach((entity) => {
          $scope.props.entityById[entity.id] = entity;
        });
      });

      position.data.forEach((position) => {
        $scope.props.positionById[position.id] = position;
      });

      staffGroup.data.forEach((staffGroup) => {
        $scope.props.staffGroupById[staffGroup.id] = staffGroup;
      });

      $scope.props.leaverReasonList = leaverReason.data.map((reason) => ({
        ...reason,
        label: reason.title,
      }));

      $scope.loadData();
    });

  $scope.export = ($event, tableId, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.NEW_HIRE_TURNOVER.TITLE'],
      tableId, format);
  };
};
