const services = require('./module');

services.factory('AvailabilityService', ['$http', 'ENDPOINT_API',
  ($http, ENDPOINT_API) => {
    const availabilityUrl = `${ENDPOINT_API}/availability/`;
    const absenceUrl = `${ENDPOINT_API}/absence/`;

    return {
      // Availability patterns
      getAvailabilityPatterns: function (userId) {
        return $http.get(availabilityUrl + 'pattern', {
          params: { userId: userId }
        });
      },
      getAvailabilityPattern: function (id) {
        return $http.get(availabilityUrl + 'pattern/' + id);
      },
      addAvailabilityPattern: function (data) {
        return $http.post(availabilityUrl + 'pattern', data);
      },
      updateAvailabilityPattern: function (id, data) {
        return $http.put(availabilityUrl + 'pattern/' + id, data);
      },
      deleteAvailabilityPattern: function (id) {
        return $http.delete(availabilityUrl + 'pattern/' + id);
      },
      updatePatternState: function (id, data) {
        return $http.put(availabilityUrl + 'patternState/' + id, data);
      },

      addPatternException: function (data) {
        return $http.post(availabilityUrl + 'patternException', data);
      },
      getPatternExceptions: function (start, end, userId) {
        return $http.get(availabilityUrl + 'patternExceptions', {
          params: {
            from: start,
            to: end,
            userId: userId
          }
        });
      },
      deletePatternException: function (userId, date) {
        return $http.delete(availabilityUrl + 'patternException/' + userId + '/' + date);
      },

      // Absence: supporting placed bookings (pending/approved/etc)
      getAbsenceRequest: (id) => $http.get(`${absenceUrl}booking/${id}`),
      cancelAbsenceRequest: (id) => $http.delete(`${absenceUrl}booking/${id}`),
      updateAbsenceRequest: (id, data) => $http.put(`${absenceUrl}booking/${id}`, data),
      addAbsenceDocument: (id, data) => $http.post(`${absenceUrl}booking/${id}/document`, data),
      getAbsencePaginated: (params) => $http.get(`${absenceUrl}paginated`, { params }),

      exportAbsence: (params, headers = {}) => $http({
        method: 'GET',
        url: `${absenceUrl}export`,
        data: '', // angular removes the content-type header unless this is present
        params,
        headers,
      }),
      exportAbsenceDays: (params, headers = {}) => $http({
        method: 'GET',
        url: `${absenceUrl}day/export`,
        data: '', // angular removes the content-type header unless this is present
        params,
        headers,
      }),

      getAbsenceTypes: () => $http.get(`${absenceUrl}types`),
      addAbsenceType: (data) => $http.post(`${absenceUrl}types`, data),
      updateAbsenceType: (id, data) => $http.put(`${absenceUrl}types/${id}`, data),
      deleteAbsenceType: (id) => $http.delete(`${absenceUrl}types/${id}`),

      getNotableEvents: (params) => $http.get(`${ENDPOINT_API}/notableEvent`, { params }),

      // Absence: supporting the request process
      sendAbsenceRequest: (data) => $http.post(`${absenceUrl}request`, data),
      getSuggestedDaysOff: (params) => $http.get(`${absenceUrl}request/suggestDaysOff`, { params }),
      getConcurrentAbsence: (params) => $http.get(`${absenceUrl}request/concurrent`, { params }),
      getAllowanceImpact: (data) => $http.post(`${absenceUrl}request/allowanceImpact`, data, {
        responseStatusAuthority: [403],
      }),

      // Allowances
      getAllowanceAudit: (id) => $http.get(`${ENDPOINT_API}/allowance/${id}/audit`),
      updateAllowance: (id, data) => $http.put(`${ENDPOINT_API}/allowance/${id}`, data),
      addAllowance: (data) => $http.post(`${ENDPOINT_API}/allowance`, data),
      deleteAllowance: (id) => $http.delete(`${ENDPOINT_API}/allowance/${id}`),
      getAllowances: (params) => $http.get(`${ENDPOINT_API}/allowance`, {
        params,
        responseStatusAuthority: [403],
      }),

      exportAllowances: (params, headers = {}) => $http({
        method: 'GET',
        url: `${ENDPOINT_API}/allowance/export`,
        data: '', // angular removes the content-type header unless this is present
        params,
        headers,
      }),

      getCalendar: function (start, end, userId, fullCalendarFormat) {
        return $http.get(availabilityUrl + 'calendar', {
          params: {
            from: start.format('YYYY-MM-DD'),
            to: end.format('YYYY-MM-DD'),
            userId: userId,
            fullCalendarFormat: fullCalendarFormat
          }
        });
      },

      getICalToken: function (userId) {
        return $http.get(availabilityUrl + 'calendar/feedToken', {
          params: {
            userId: userId
          }
        });
      },
    };
  }]);
