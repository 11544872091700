module.exports = (
  $scope,
  $state,
  $uibModalInstance,
  $translate,
  data,
) => {
  'ngInject';

  const {
    duplicates,
  } = data;

  const translations = $translate.instant([
    'STAFF.ADD_NEW.DUPLICATE_MODAL.SIMILARITY_NAME',
    'STAFF.ADD_NEW.DUPLICATE_MODAL.SIMILARITY_EMAIL',
    'STAFF.ADD_NEW.DUPLICATE_MODAL.SIMILARITY_MOBILE',
    'STAFF.ADD_NEW.DUPLICATE_MODAL.SIMILARITY_DOB',
  ]);

  $scope.props = {
    duplicates,
    similarityTypeIdToString: {
      name: translations['STAFF.ADD_NEW.DUPLICATE_MODAL.SIMILARITY_NAME'],
      email: translations['STAFF.ADD_NEW.DUPLICATE_MODAL.SIMILARITY_EMAIL'],
      mobile: translations['STAFF.ADD_NEW.DUPLICATE_MODAL.SIMILARITY_MOBILE'],
      dob: translations['STAFF.ADD_NEW.DUPLICATE_MODAL.SIMILARITY_DOB'],
    },
    canProceedAnyway: duplicates.every(({ similarities }) => !similarities.includes('email')
      && !similarities.includes('mobile')),
  };

  $scope.close = (proceedAnyway) => {
    if (proceedAnyway) {
      $uibModalInstance.close(true);
    }

    $uibModalInstance.dismiss();
  };

  $scope.goToEmployee = (id, addNew) => {
    $uibModalInstance.close();
    $state.go('staff.view.employment', { id, addNew });
  };
};
