'use strict';
var services = require('./module');

services.factory('TemplateService', ['$http', 'ENDPOINT_API',
    function ($http, ENDPOINT_API) {
        var baseUrl = ENDPOINT_API + '/template/';

        return {
            getTemplates: function () {
                return $http.get(baseUrl + 'list');
            },
            getTemplate: function (id) {
                return $http.get(baseUrl + 'template/' + id);
            },
            addTemplate: function (data) {
                return $http.post(baseUrl + 'template', data, {timeout: 20000});
            },
            deleteTemplate: function (id) {
                return $http.delete(baseUrl + 'template/' + id);
            },

            updateNode: function (id, data) {
                return $http.put(baseUrl + 'node/' + id, data);
            },
            addNode: function (data) {
                return $http.post(baseUrl + 'node', data);
            },
            deleteNode: function (id) {
                return $http.delete(baseUrl + 'node/' + id);
            },

            updateLeaf: function (id, data) {
                return $http.put(baseUrl + 'leaf/' + id, data);
            },
            addLeaf: function (data) {
                return $http.post(baseUrl + 'leaf', data);
            },
            deleteLeaf: function (id) {
                return $http.delete(baseUrl + 'leaf/' + id);
            },
        };
    }]);
