module.exports = (
  $scope,
  $state,
  $uibModalInstance,
  $translate,
  EnvironmentDataService,
  AlertService,
  StaffService,
  StaffCommon,
  data,
) => {
  'ngInject';

  const {
    userId,
    signatureId,
    expiryDate,
    categoryId,
    redirectOnComplete,
  } = data;

  const translations = $translate.instant([
    'STAFF.VIEW.DOCUMENT_UPLOAD_MODAL.ALERT_SUCCESS',
    'STAFF.VIEW.DOCUMENT_UPLOAD_MODAL.ALERT_ERROR_500',
    'STAFF.VIEW.DOCUMENT_UPLOAD_MODAL.ALERT_ERROR_400',
  ]);

  $scope.props = {
    actionInProgress: true,
    defaultExpiryDate: expiryDate ? expiryDate.clone() : moment.utc().add(1, 'year'),
    signatureId,
    defaultUserId: userId,
    permittedExtensions: StaffCommon.permittedFileExtensions,
    documentCategoryMasterList: [],
    notes: '',
  };

  function onUserSelect(userId) {
    $scope.props.actionInProgress = true;
    $scope.props.selectedUserId = userId;

    StaffService.getDocumentCategoriesNotApplicable(userId)
      .then(({ data }) => {
        const invalidCategoryIds = data.results.map(({ categoryId }) => categoryId);
        $scope.props.documentCategoryList = $scope.props.documentCategoryMasterList
          .filter(({ id }) => !invalidCategoryIds.includes(id));

        if ($scope.props.selectedCategory
          && invalidCategoryIds.includes($scope.props.selectedCategory.id)) {
          $scope.props.selectedCategory = undefined;
          $scope.props.defaultCategoryId = undefined;
        }

        $scope.props.actionInProgress = false;
      });
  }

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.DocumentCategory)
    .then(({ data }) => {
      $scope.props.documentCategoryMasterList = data
        .filter(({ sensitive }) => {
          if (sensitive && !StaffCommon.canAccessSensitiveDocuments()) {
            return false;
          }

          return true;
        })
        .map((category) => ({ ...category, label: category.name }));
      $scope.props.documentCategoryList = $scope.props.documentCategoryMasterList.slice();

      if (categoryId) {
        const category = $scope.props.documentCategoryList.find((c) => c.id === categoryId);

        if (category) {
          $scope.props.defaultCategoryId = categoryId;
          $scope.onCategorySelect(category);
        }
      }

      $scope.props.actionInProgress = false;

      if ($scope.props.defaultUserId) {
        onUserSelect($scope.props.defaultUserId);
      }
    });

  $scope.close = () => {
    $uibModalInstance.dismiss();
  };

  $scope.onCategorySelect = (option) => {
    $scope.props.selectedCategory = option;
  };

  $scope.onUserSelect = (selectedUser) => {
    if (!selectedUser) {
      return;
    }

    onUserSelect(selectedUser.id);
  };

  $scope.onExpiryDateChange = (newDate) => {
    $scope.props.expiryDate = newDate.clone();
  };

  $scope.onFileUpload = (file) => {
    $scope.props.file = file;
  };

  $scope.onFileRemoved = () => {
    $scope.props.file = undefined;
  };

  $scope.submit = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    const {
      file,
      selectedCategory,
      expiryDate,
      defaultExpiryDate,
      selectedUserId,
      notes,
    } = $scope.props;

    if (!file || !selectedCategory || !selectedUserId) {
      return;
    }

    const body = {
      userId: selectedUserId,
      categoryId: selectedCategory.id,
      expiryDate: selectedCategory.expirable
        ? (expiryDate || defaultExpiryDate).format('YYYY-MM-DD') : undefined,
      key: file.key,
      fileName: file.name,
      signatureId,
      notes,
    };

    $scope.props.actionInProgress = true;

    StaffService.addDocument(body)
      .then(({ data }) => {
        $scope.props.actionInProgress = false;

        AlertService.add('success', translations['STAFF.VIEW.DOCUMENT_UPLOAD_MODAL.ALERT_SUCCESS']);

        if (redirectOnComplete) {
          $state.go('staff.view.documents', {
            id: selectedUserId,
          });
        }

        $uibModalInstance.close(data);
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.DOCUMENT_UPLOAD_MODAL.ALERT_ERROR_500']);
          return;
        }

        if (status === 400) {
          AlertService.add('danger', translations['STAFF.VIEW.DOCUMENT_UPLOAD_MODAL.ALERT_ERROR_400']);
        }
      });
  };
};
