module.exports = (
  $scope,
  $uibModalInstance,
  $translate,
  EnvironmentDataService,
  AlertService,
  StaffService,
  data,
) => {
  'ngInject';

  const { userId } = data;

  $scope.props = {
    formData: {},
    actionInProgress: true,
    relationshipList: [],
  };

  const translations = $translate.instant([
    'STAFF.VIEW.EMERGENCY_CONTACTS.ADD_MODAL.ALERT_ERROR_500',
  ]);

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.EmergencyContactRelationship)
    .then(({ data }) => {
      $scope.props.relationshipList = data
        .map((item) => ({ ...item, label: item.title }));
      $scope.props.actionInProgress = false;
    });

  $scope.close = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.addEmergencyContact = () => {
    if (!$scope.props.formData.relationshipId) {
      return;
    }

    $scope.props.actionInProgress = true;

    const emergencyContact = $scope.props.formData;

    StaffService.addEmergencyContact(userId, { emergencyContacts: [emergencyContact] })
      .then(({ data }) => {
        $scope.props.actionInProgress = false;

        $uibModalInstance.close({
          emergencyContact: {
            id: data.emergencyContactIds[0],
            userId,
            ...emergencyContact,
          },
        });
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status !== 403) {
          AlertService.add('danger', translations['STAFF.VIEW.EMERGENCY_CONTACTS.ADD_MODAL.ALERT_ERROR_500']);
        }
      });
  };
};
