module.exports = (
  $scope,
  $state,
  $stateParams,
  $translate,
  $window,
  StaffService,
  StaffCommon,
  EnvironmentDataService,
  SessionService,
  AlertService,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.DOCUMENTS_EXPORT.ERROR_500',
    'REPORTS.DOCUMENTS_EXPORT.FILTER_OPTION_ACKNOWLEDGED',
    'REPORTS.DOCUMENTS_EXPORT.FILTER_OPTION_NOT_ACKNOWLEDGED',
  ]);

  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];
  const defaultCategoryIds = $stateParams.categoryIds
    ? $stateParams.categoryIds.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];

  $scope.props = {
    loadingData: false,
    noMoreData: false,
    data: [],
    defaultEntityIds,
    defaultCategoryIds,
    activeUsers: true,
    selectedCategories: [],
    acknowledgementList: [
      {
        id: 'acknowledged',
        label: translations['REPORTS.DOCUMENTS_EXPORT.FILTER_OPTION_ACKNOWLEDGED'],
      },
      {
        id: 'notAcknowledged',
        label: translations['REPORTS.DOCUMENTS_EXPORT.FILTER_OPTION_NOT_ACKNOWLEDGED'],
      },
    ],
  };

  $scope.getDataParams = {
    'currentEntityIds[]': defaultEntityIds,
    'documentCategoryIds[]': defaultCategoryIds,
    employmentActive: true,
    locale: SessionService.getUserLocale(),
    sortOrder: 'idAsc',
    page: 1,
    limit: 50,
  };

  $scope.loadData = async (reset) => {
    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.loadingData = true;

    if (reset) {
      $scope.getDataParams.page = 1;
      $scope.props.noMoreData = false;
      $scope.props.data = [];
    }

    const { getDataParams } = $scope;
    const entityIds = getDataParams['currentEntityIds[]'];
    const categoryIds = getDataParams['documentCategoryIds[]'];

    $state.go('.', {
      entityIds: entityIds ? entityIds.join(',') : undefined,
      categoryIds: categoryIds ? categoryIds.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
      inherit: false,
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    try {
      const { data } = await StaffService.exportDocumentHeaders($scope.getDataParams);
      const { results } = data;

      $scope.props.data.push(...results);
      $scope.getDataParams.page += 1;

      if (results.length < getDataParams.limit) {
        $scope.props.noMoreData = true;
      }

      $scope.props.loadingData = false;
      $scope.$apply();
    } catch ({ status }) {
      $scope.props.noMoreData = true;
      $scope.props.loadingData = false;

      if (status === 500) {
        AlertService.add('danger', translations['REPORTS.DOCUMENTS_EXPORT.ERROR_500']);
      }
    }
  };

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.getDataParams['currentEntityIds[]'] = entityIds.length ? entityIds : undefined;
    $scope.loadData(true);
  };

  $scope.onCategoryFilter = (selectedOptions) => {
    $scope.getDataParams['documentCategoryIds[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  $scope.onActiveUsersFilter = () => {
    $scope.getDataParams.employmentActive = $scope.props.activeUsers ? true : undefined;
    $scope.loadData(true);
  };

  $scope.onAcknowledgementFilter = (selectedOptions) => {
    const option = selectedOptions[0];

    if (option === 'acknowledged') {
      $scope.getDataParams.isAcknowledged = true;
    } else if (option === 'notAcknowledged') {
      $scope.getDataParams.isAcknowledged = false;
    } else {
      $scope.getDataParams.isAcknowledged = undefined;
    }

    $scope.loadData(true);
  };

  $scope.onUserFilter = (selectedUser) => {
    $scope.getDataParams.userId = selectedUser ? selectedUser.id : undefined;
    $scope.loadData(true);
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.DocumentCategory,
  ])
    .then(([
      entityGroup,
      documentCategory,
    ]) => {
      $scope.props.entityGroupList = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => ({
            id: entity.id,
            label: entity.name,
            parentId: entity.groupId,
            depth: 1,
          })),
        ]));

      $scope.props.categoryList = documentCategory.data
        .filter(({ deleted, sensitive }) => {
          if (deleted) {
            return false;
          }

          if (sensitive && !StaffCommon.canAccessSensitiveDocuments()) {
            return false;
          }

          return true;
        })
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));

      $scope.loadData();
    });

  $scope.export = async () => {
    $scope.props.loadingData = true;

    const {
      data: {
        signedUrl,
      },
    } = await StaffService.exportDocumentHeaders($scope.getDataParams, {
      'Content-Type': 'text/csv',
    });

    if (signedUrl) {
      $window.location = signedUrl;
    }

    $scope.props.loadingData = false;
  };
};
