const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/eventSubscriptionButton.html');

'use strict';
var directives = require('./module');

directives.directive('rrEventSubscriptionButton', ['$translate', '$timeout', 'NotificationService', 'CommonService',
    function ($translate, $timeout, NotificationService, CommonService) {
        return {
            restrict: 'E',
            templateUrl: autoNgTemplateLoaderTemplate1,
            scope: {
                eventType: '@',
                disabled: '=?',
                small: '@',
            },
            link: function ($scope, element) {
                var searchElement = angular.element(element.find('input')[0]);

                var validEventTypeIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16,
                    17, 29, 30, 31, 32, 36, 37, 38];

                var translationKeys = [
                    'DIRECTIVES.SUBSCRIBE_BUTTON.SUBSCRIBE',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.UNSUBSCRIBE',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.NOTIFY_SENTENCE_EXORDIUM',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.NOTIFY_SENTENCE_TRAILING_IN',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_ABSENCE',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_ROTA',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_ACCOUNT',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_SWAP',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_COST_CONTROL',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_DOCUMENT',
                    'DIRECTIVES.SUBSCRIBE_BUTTON.DESCRIPTOR_BROADCAST',
                ];

                validEventTypeIds.forEach(function (eventTypeId) {
                    translationKeys.push('DIRECTIVES.SUBSCRIBE_BUTTON.LABEL_ETID_' + eventTypeId);
                });

                var translations = $translate.instant(translationKeys);

                $scope.props = {
                    isValidResource: false,
                    loading: false,
                    disabled: $scope.disabled,
                    isMenuOpen: false,
                    buttonLabel: '',
                    headerText: '',
                    eventTypeOptions: [],
                    selectedEventTypeId: false,
                    subscriptions: [],
                    entities: [],
                    entityGroups: [],
                    metadataCache: undefined,
                    searchInput: '',
                };

                function getResourceTypeData(resourceType) {
                    var data = NotificationService.getSubscriptionResourceTypeData(resourceType);

                    return {
                        descriptor: translations[data.translation],
                        eventTypeIds: data.eventTypeIds,
                    };
                }

                function constructHeaderText() {
                    var resourceData = getResourceTypeData($scope.eventType);
                    var eventTypeId = $scope.props.selectedEventTypeId;

                    var parts = [
                        translations['DIRECTIVES.SUBSCRIBE_BUTTON.NOTIFY_SENTENCE_EXORDIUM'],
                        resourceData.descriptor,
                    ];

                    if (eventTypeId) {
                        parts.push(
                            translations['DIRECTIVES.SUBSCRIBE_BUTTON.LABEL_ETID_' + eventTypeId],
                            translations['DIRECTIVES.SUBSCRIBE_BUTTON.NOTIFY_SENTENCE_TRAILING_IN']
                        );
                    }

                    $scope.props.headerText = parts.join(' ');
                }

                $scope.returnToEventTypeSelection = function () {
                    $scope.props.selectedEventTypeId = false;
                    constructHeaderText();
                };

                function getSubscriptionsForEventTypeId(id) {
                    return $scope.props.subscriptions.filter(function (s) {
                        return s.eventTypeId === id;
                    });
                }

                function updateTopLevelStatuses() {
                    $scope.props.eventTypeOptions.forEach(function (option) {
                        option.enabled = !!getSubscriptionsForEventTypeId(option.id).length;
                    });

                    $scope.props.buttonLabel = $scope.props.subscriptions.length ?
                        translations['DIRECTIVES.SUBSCRIBE_BUTTON.UNSUBSCRIBE'] :
                        translations['DIRECTIVES.SUBSCRIBE_BUTTON.SUBSCRIBE'];
                }

                function initialise() {
                    var resourceData = getResourceTypeData($scope.eventType);

                    if (!resourceData) {
                        return;
                    }

                    $scope.props.isValidResource = true;
                    $scope.props.loading = true;

                    NotificationService.getSubscriptions({ excludeResourceSpecific: true })
                        .success(function (data) {
                            $scope.props.subscriptions = data.byCategory[$scope.eventType] || [];
                            $scope.props.eventTypeOptions = resourceData.eventTypeIds.map(function (eventTypeId) {
                                return {
                                    id: eventTypeId,
                                    label: translations['DIRECTIVES.SUBSCRIBE_BUTTON.LABEL_ETID_' + eventTypeId],
                                    enabled: false,
                                };
                            });

                            updateTopLevelStatuses();
                            constructHeaderText();
                            $scope.props.loading = false;
                        });
                }

                function initialiseEntitySelectors(eventTypeId) {
                    $scope.props.entityGroups = [];
                    $scope.props.entities = [];

                    var subscriptions = getSubscriptionsForEventTypeId(eventTypeId);

                    $scope.props.metadataCache.forEach(function (group) {
                        $scope.props.entityGroups.push({
                            id: group.id,
                            label: group.name,
                            enabled: subscriptions.some(function (s) {
                                return s.entityGroupId === group.id;
                            }),
                        });

                        group.entities.forEach(function (entity) {
                            $scope.props.entities.push({
                                id: entity.id,
                                label: entity.name,
                                enabled: subscriptions.some(function (s) {
                                    return s.entityId === entity.id;
                                }),
                                groupId: group.id,
                            });
                        });
                    });
                }

                $scope.onButtonToggle = function (open) {
                    if (!open) {
                        return;
                    }

                    if (!$scope.props.isValidResource) {
                        $scope.props.isMenuOpen = false;
                        return;
                    }

                    $scope.props.selectedEventTypeId = false;
                    constructHeaderText();

                    if (!$scope.props.metadataCache) {
                        $scope.props.loading = true;

                        CommonService.getMetadata(['entities'], false, true)
                            .success(function (data) {
                                $scope.props.metadataCache = data.entities;
                                $scope.props.loading = false;
                            });
                    }
                };

                initialise();

                $scope.onEventTypeOption = function (option) {
                    $scope.props.selectedEventTypeId = option.id;

                    initialiseEntitySelectors($scope.props.selectedEventTypeId);
                    constructHeaderText();

                    $scope.props.searchInput = '';

                    $timeout(function () {
                        searchElement.focus();
                    }, 100);
                };

                function subscribe(eventTypeId, entityId, entityGroupId) {
                    var postData = {
                        subscriptions: [{
                            eventTypeId: eventTypeId,
                            entityId: entityId,
                            entityGroupId: entityGroupId,
                        }],
                    };

                    NotificationService.subscribe(postData)
                        .success(function (data) {
                            var subscription = data[0];
                            $scope.props.subscriptions.push(subscription);
                            updateTopLevelStatuses();
                        })
                        .error(function (error, status) {
                            // todo
                        });
                }

                function unsubscribe(eventTypeId, entityId, entityGroupId) {
                    var subscriptions = getSubscriptionsForEventTypeId(eventTypeId);

                    var subscriptionIds = subscriptions.filter(function (s) {
                        if (entityId && s.entityId !== entityId) {
                            return false;
                        }

                        if (entityGroupId && s.entityGroupId !== entityGroupId) {
                            return false;
                        }

                        return true;
                    }).map(function (s) {
                        return s.id;
                    });

                    if (!subscriptionIds.length) {
                        return;
                    }

                    var postData = {
                        subscriptionIds: subscriptionIds,
                    };

                    NotificationService.unsubscribe(postData)
                        .success(function () {
                            for (var i = $scope.props.subscriptions.length -1; i >= 0; i--) {
                                var subscription = $scope.props.subscriptions[i];

                                if (subscriptionIds.indexOf(subscription.id) !== -1) {
                                    $scope.props.subscriptions.splice(i, 1);
                                }
                            }

                            updateTopLevelStatuses();
                        })
                        .error(function (error, status) {
                            // todo
                        });
                }

                $scope.onEntityGroupSelect = function (option) {
                    if (option.enabled) {
                        unsubscribe($scope.props.selectedEventTypeId, undefined, option.id);
                    } else {
                        subscribe($scope.props.selectedEventTypeId, undefined, option.id);
                    }

                    option.enabled = !option.enabled;
                };

                $scope.onEntitySelect = function (option) {
                    if (option.enabled) {
                        unsubscribe($scope.props.selectedEventTypeId, option.id, undefined);
                    } else {
                        subscribe($scope.props.selectedEventTypeId, option.id, undefined);
                    }

                    option.enabled = !option.enabled;
                };

                function doesLabelMatchSearchInput(option) {
                    if (!$scope.props.searchInput) {
                        return true;
                    }

                    var needle = $scope.props.searchInput.toLocaleLowerCase();
                    return option.label.toLocaleLowerCase().indexOf(needle) !== -1;
                }

                $scope.filterEntity = function (groupId) {
                    return function (option) {
                        if (option.groupId !== groupId) {
                            return false;
                        }

                        return doesLabelMatchSearchInput(option);
                    };
                };

                $scope.filterEntityGroups = function (option) {
                    var entityMatches = $scope.props.entities.some(function (e) {
                        return $scope.filterEntity(option.id)(e);
                    });

                    return entityMatches || doesLabelMatchSearchInput(option);
                };
            }
        };
    }
]);
