const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/absence/viewModal.html');

module.exports = (
  $scope,
  $uibModal,
  $state,
  AvailabilityService,
  EnvironmentDataService,
  AlertService,
  SessionService,
  StaffCommon,
  $translate,
  $window,
) => {
  'ngInject';

  const isRegularUserMode = !$scope.userId;

  const translations = $translate.instant([
    'STAFF.VIEW.ABSENCE.VIEW_BOOKINGS.CANCEL_REQUEST.CONFIRM',
    'STAFF.VIEW.ABSENCE.VIEW_BOOKINGS.CANCEL_REQUEST.SUCCESS',
    'STAFF.VIEW.ABSENCE.VIEW_BOOKINGS.CANCEL_REQUEST.ERROR_500',
  ]);

  $scope.props = {
    loadingData: false,
    noMoreData: false,
    data: [],
    approvalStates: [
      {
        id: 0,
        label: StaffCommon.getLabelFromApprovalState(0),
      },
      {
        id: 1,
        label: StaffCommon.getLabelFromApprovalState(1),
      },
      {
        id: 2,
        label: StaffCommon.getLabelFromApprovalState(2),
      },
    ],
    defaultApprovalStates: isRegularUserMode ? [0, 1, 2] : [0, 1],
    includeCancelled: false,
  };

  $scope.getDataParams = {
    userId: isRegularUserMode ? SessionService.getUserId() : $scope.userId,
    'approvalStates[]': $scope.props.defaultApprovalStates,
    'absenceTypeIds[]': [],
    includeCancelled: $scope.props.includeCancelled,
    limit: 20,
    page: 1,
    sortOrder: 'startDateDesc',
  };

  $scope.onApprovalStateFilter = (selectedOptions) => {
    $scope.getDataParams['approvalStates[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  $scope.onAbsenceTypeFilter = (selectedOptions) => {
    const [option] = selectedOptions;
    $scope.getDataParams['absenceTypeIds[]'] = option || [];
    $scope.loadData(true);
  };

  $scope.onDateFilter = (state) => {
    const {
      option,
      dateStart: start,
      dateEnd: end,
    } = state;

    if (option === 1) {
      $scope.getDataParams.dateContext = undefined;
      $scope.getDataParams.start = undefined;
      $scope.getDataParams.end = undefined;
      $scope.getDataParams.sortOrder = 'startDateDesc';
    } else {
      $scope.getDataParams.dateContext = 'overlapping';
      $scope.getDataParams.sortOrder = 'startDateAsc';

      if (option === 2) {
        $scope.getDataParams.start = moment('1900-01-01')
          .format();
        $scope.getDataParams.end = start.format();
      } else if (option === 3) {
        $scope.getDataParams.start = start.clone()
          .add(1, 'day')
          .format();
        $scope.getDataParams.end = moment('2100-01-01')
          .format();
      } else if (option === 4) {
        $scope.getDataParams.start = start.format();
        $scope.getDataParams.end = end.clone()
          .add(1, 'day')
          .format();
      }
    }

    $scope.loadData(true);
  };

  $scope.onCancelledFilter = () => {
    $scope.getDataParams.includeCancelled = $scope.props.includeCancelled;
    $scope.loadData(true);
  };

  $scope.loadData = (reset) => {
    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.loadingData = true;

    if (reset) {
      $scope.getDataParams.page = 1;
      $scope.props.noMoreData = false;
      $scope.props.data = [];
    }

    AvailabilityService.getAbsencePaginated($scope.getDataParams)
      .then(({ data }) => {
        $scope.props.data.push(...data.absence.map((r) => ({
          ...r,
          scopeLabel: StaffCommon.getLabelFromScope(r.scope),
          approvalStateLabel: StaffCommon.getLabelFromApprovalState(r.approvalState),
        })));

        $scope.getDataParams.page += 1;

        if (data.absence.length < $scope.getDataParams.limit) {
          $scope.props.noMoreData = true;
        }

        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        if (status === 500) {
          AlertService.add('danger', 'We weren\'t able to load absence just then. Please try again.');
        }
      });
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.AbsenceType)
    .then(({ data }) => {
      $scope.props.absenceTypes = data.map(({
        id,
        name,
        deleted,
      }) => ({
        id,
        label: name,
        deleted,
      }));
    });

  function onAbsenceCancelled(id) {
    const absenceId = parseInt(id, 10);

    const index = $scope.props.data.findIndex((r) => r.id === absenceId);

    if (index >= 0) {
      if ($scope.getDataParams.includeCancelled) {
        const absence = $scope.props.data[index];
        absence.cancelled = true;
        absence.cancelledby = {
          userId: 0,
          userName: SessionService.getUserFullName(),
          date: moment().format(),
        };
      } else {
        $scope.props.data.splice(index, 1);
      }
    }
  }

  $scope.view = (id) => {
    const modalInstance = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('../../../absence/viewModal'),
      resolve: {
        data: () => ({
          id,
          launchedFromStaffModule: !isRegularUserMode,
        }),
      },
    });

    modalInstance.result.then((absenceCancelled) => {
      if (absenceCancelled) {
        onAbsenceCancelled(id);
      }
    });
  };

  $scope.cancel = (header) => {
    if (header.actionInProgress) {
      return;
    }

    if (!$window.confirm(translations['STAFF.VIEW.ABSENCE.VIEW_BOOKINGS.CANCEL_REQUEST.CONFIRM'])) {
      return;
    }

    header.actionInProgress = true;
    const { id } = header;

    AvailabilityService.cancelAbsenceRequest(id)
      .then(() => {
        header.actionInProgress = false;
        onAbsenceCancelled(id);
        AlertService.add('success', translations['STAFF.VIEW.ABSENCE.VIEW_BOOKINGS.CANCEL_REQUEST.SUCCESS']);
      })
      .catch(({ status, data }) => {
        header.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.VIEW_BOOKINGS.CANCEL_REQUEST.ERROR_500']);
          return;
        }

        if (status === 400) {
          StaffCommon.onAbsenceValidationResponse(data);
        }
      });
  };

  $scope.bookNew = () => {
    $state.go('absenceRequest', { userId: $scope.userId });
  };
};
