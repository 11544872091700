module.exports = (
  $scope,
  $uibModalInstance,
  $translate,
  EnvironmentDataService,
  AlertService,
  AvailabilityService,
  StaffCommon,
  data,
) => {
  'ngInject';

  const {
    absenceType,
    reasons,
  } = data;

  const translations = $translate.instant([
    'SETTINGS.ABSENCE_TYPES.EDIT_MODAL.ALERT_SUCCESS',
    'SETTINGS.ABSENCE_TYPES.EDIT_MODAL.ALERT_ERROR_500',
    'SETTINGS.ABSENCE_TYPES.EDIT_MODAL.ALERT_ERROR_400',
    'SETTINGS.ABSENCE_TYPES.EDIT_MODAL.FILE_UPLOAD_OPTION_NONE',
    'SETTINGS.ABSENCE_TYPES.EDIT_MODAL.FILE_UPLOAD_OPTION_OPTIONAL',
    'SETTINGS.ABSENCE_TYPES.EDIT_MODAL.FILE_UPLOAD_OPTION_MANDATORY',
  ]);

  $scope.props = {
    actionInProgress: true,
    documentCategoryList: [],
    fileUploadOptionList: [
      {
        id: 'NONE',
        label: translations['SETTINGS.ABSENCE_TYPES.EDIT_MODAL.FILE_UPLOAD_OPTION_NONE'],
      },
      {
        id: 'OPTIONAL',
        label: translations['SETTINGS.ABSENCE_TYPES.EDIT_MODAL.FILE_UPLOAD_OPTION_OPTIONAL'],
      },
      {
        id: 'MANDATORY',
        label: translations['SETTINGS.ABSENCE_TYPES.EDIT_MODAL.FILE_UPLOAD_OPTION_MANDATORY'],
      },
    ],
    formData: absenceType ? angular.copy(absenceType) : {
      approval: true,
      isAllowed: true,
      fileUploadOnSubmission: 'NONE',
      fileUploadOnReview: 'NONE',
    },
    isAdd: !absenceType,
    absenceType,
    isLockedAbsenceType: absenceType && absenceType.isLocked,
    reasons,
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.DocumentCategory,
    EnvironmentDataService.DataType.PayElementType,
  ])
    .then(([
      documentCategory,
      payElementType,
    ]) => {
      $scope.props.documentCategoryList = documentCategory.data
        .filter(({ sensitive, expirable }) => {
          if (expirable) {
            return false;
          }

          if (sensitive && !StaffCommon.canAccessSensitiveDocuments()) {
            return false;
          }

          return true;
        })
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));

      $scope.props.validDocumentCategories = !!$scope.props.documentCategoryList.length;

      $scope.props.payElementList = payElementType.data
        .filter(({ variant }) => variant === 'FIXED_AMOUNT')
        .map(({ id, name, isDeleted }) => ({ id, label: name, deleted: isDeleted }));

      $scope.props.validPayElementList = !!$scope.props.payElementList.length;

      $scope.props.actionInProgress = false;
    });

  $scope.close = () => {
    $uibModalInstance.dismiss();
  };

  function onApiError(status) {
    $scope.props.actionInProgress = false;

    if (status === 500) {
      AlertService.add('danger', translations['SETTINGS.ABSENCE_TYPES.EDIT_MODAL.ALERT_ERROR_500']);
      return;
    }

    if (status === 400) {
      AlertService.add('danger', translations['SETTINGS.ABSENCE_TYPES.EDIT_MODAL.ALERT_ERROR_400']);
    }
  }

  function onApiSuccess() {
    $scope.props.actionInProgress = false;

    AlertService.add('success', translations['SETTINGS.ABSENCE_TYPES.EDIT_MODAL.ALERT_SUCCESS']);
    $uibModalInstance.close({ success: true });
  }

  $scope.submit = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    const {
      name,
      colour,
      isAllowed,
      approval,
      isPaid,
      countsAsHrs,
      isAccruesHoliday,
      overlapShifts,
      isCountsTowardsTronc,
      allowanceRequired,
      fileUploadOnSubmission,
      fileUploadOnReview,
      fileUploadCategoryId,
      fixedAmountPayElementId,
    } = $scope.props.formData;

    if (!name || name.length < 3) {
      return;
    }

    const fileUploadsEnabled = fileUploadOnSubmission !== 'NONE' || fileUploadOnReview !== 'NONE';

    if (fileUploadsEnabled && !fileUploadCategoryId) {
      return;
    }

    const body = {
      name,
      approvalRequired: approval,
      colour: colour ? colour.slice(1) : null,
      countsAsHoursWorked: countsAsHrs,
      paid: isPaid,
      accruesHoliday: isAccruesHoliday,
      bookable: isAllowed,
      canOverlapShifts: overlapShifts,
      countsTowardsTronc: isCountsTowardsTronc,
      allowanceRequired,
      fileUploadOnSubmission,
      fileUploadOnReview,
      fileUploadCategoryId: fileUploadsEnabled ? fileUploadCategoryId : null,
      fixedAmountPayElementId,
      reasons: $scope.props.reasons,
    };

    $scope.props.actionInProgress = true;

    if ($scope.props.isAdd) {
      AvailabilityService.addAbsenceType(body)
        .then(() => {
          onApiSuccess();
        })
        .catch(({ status }) => {
          onApiError(status);
        });
    } else {
      AvailabilityService.updateAbsenceType(absenceType.id, body)
        .then(() => {
          onApiSuccess();
        })
        .catch(({ status }) => {
          onApiError(status);
        });
    }
  };

  $scope.deleteReason = (index) => {
    $scope.props.reasons.splice(index, 1);
  };

  $scope.addReason = () => {
    const { newReasonTitle } = $scope.props;

    if (!newReasonTitle) {
      return;
    }

    const trimmed = newReasonTitle.trim();
    const existingReasons = $scope.props.reasons.map((reason) => reason.toLowerCase());

    if (existingReasons.includes(trimmed.toLowerCase())) {
      return;
    }

    $scope.props.reasons.push(trimmed);
    $scope.props.newReasonTitle = '';
  };
};
