module.exports = (
  $scope,
  $state,
  $stateParams,
  $http,
  $translate,
  $uibModal,
  SessionService,
  EnvironmentDataService,
  AlertService,
  ExportService,
  ENDPOINT_API,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.TITLE',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.PAY_AMOUNT_TYPE_HOURLY',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.PAY_AMOUNT_TYPE_DAILY',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.PAY_AMOUNT_TYPE_ANNUAL',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.DATA_SOURCE_SHIFTS',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.DATA_SOURCE_MATCHED',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.DATE_ERROR_DIFF',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.BEHAVIOUR_SHIFT_TYPE',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.BEHAVIOUR_STREAM',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.ERROR_500',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.ERROR_400',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.STREAM_UNALLOCATED',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.METRIC_WAGES',
    'REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.METRIC_HOURS',
  ]);

  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];
  const defaultDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('week').subtract(1, 'week');
  const defaultDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('week').subtract(1, 'day');
  const defaultPayAmountTypes = $stateParams.payAmountTypes
    ? $stateParams.payAmountTypes.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];
  const defaultDataSources = $stateParams.dataSource ? [$stateParams.dataSource] : ['sanitised'];
  const defaultBehaviours = $stateParams.behaviour ? [$stateParams.behaviour] : ['shiftType'];
  const defaultUserId = $stateParams.userId || undefined;

  const payAmountTypes = new Map([
    [0, { id: 0, label: translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.PAY_AMOUNT_TYPE_HOURLY'] }],
    [2, { id: 2, label: translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.PAY_AMOUNT_TYPE_DAILY'] }],
    [1, { id: 1, label: translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.PAY_AMOUNT_TYPE_ANNUAL'] }],
  ]);

  $scope.props = {
    loadingData: false,
    data: [],
    defaultDateFilter: {
      option: 4,
      dateStart: defaultDateStart,
      dateEnd: defaultDateEnd,
    },
    defaultEntityIds,
    payAmountTypes: Array.from(payAmountTypes.values()),
    selectedDateStart: defaultDateStart,
    selectedDateEnd: defaultDateEnd,
    selectedEntityIds: defaultEntityIds,
    selectedPayAmountTypes: defaultPayAmountTypes,
    entityGroupsById: {},
    entityById: {},
    staffGroupById: {},
    dataSources: [
      { id: 'shifts', label: translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.DATA_SOURCE_SHIFTS'] },
      { id: 'sanitised', label: translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.DATA_SOURCE_MATCHED'] },
    ],
    behaviours: [
      { id: 'shiftType', label: translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.BEHAVIOUR_SHIFT_TYPE'] },
      { id: 'stream', label: translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.BEHAVIOUR_STREAM'] },
    ],
    selectedBehaviours: defaultBehaviours,
    selectedDataSources: defaultDataSources,
    selectedUserId: defaultUserId,
    axisValues: [],
    metricType: 'wages',
    selectedMetrics: ['wages'],
    metrics: [
      { id: 'wages', label: translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.METRIC_WAGES'] },
      { id: 'hours', label: translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.METRIC_HOURS'] },
    ],
  };

  function evaluateQueryParams() {
    $state.go('.', {
      start: $scope.props.selectedDateStart.format('YYYY-MM-DD'),
      end: $scope.props.selectedDateEnd.format('YYYY-MM-DD'),
      entityIds: $scope.props.selectedEntityIds ? $scope.props.selectedEntityIds.join(',') : undefined,
      payAmountTypes: $scope.props.selectedPayAmountTypes ? $scope.props.selectedPayAmountTypes.join(',') : undefined,
      dataSource: $scope.props.selectedDataSources[0],
      userId: $scope.props.selectedUserId,
      behaviour: $scope.props.selectedBehaviours[0],
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $scope.getDataParams = {
      dateRange: `${$scope.props.selectedDateStart.format('YYYY-MM-DD')}/${$scope.props.selectedDateEnd.clone().add(1, 'day').format('YYYY-MM-DD')}`,
      timezone: SessionService.getTimezone(),
      'entityIds[]': $scope.props.selectedEntityIds,
      'userPayAmountTypes[]': $scope.props.selectedPayAmountTypes,
      dataSource: $scope.props.selectedDataSources[0],
      userId: $scope.props.selectedUserId,
      resultGrouping: $scope.props.selectedBehaviours[0],
    };
  }

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.props.selectedEntityIds = entityIds.length ? entityIds : undefined;
    $scope.loadData();
  };

  $scope.onPayAmountTypeFilter = (selectedOptions) => {
    $scope.props.selectedPayAmountTypes = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData();
  };

  $scope.onDataSourceFilter = (selectedOptions) => {
    $scope.props.selectedDataSources = selectedOptions;
    $scope.loadData();
  };

  $scope.onUserFilter = (selectedUser) => {
    $scope.props.selectedUserId = selectedUser ? selectedUser.id : undefined;
    $scope.loadData();
  };

  $scope.onBehaviourToggle = (selectedOptions) => {
    $scope.props.selectedBehaviours = selectedOptions;
    $scope.loadData();
  };

  $scope.onMetricToggle = (selectedOptions) => {
    $scope.props.metricType = selectedOptions[0];
  };

  $scope.onDateFilter = ({
    dateEnd,
    dateStart,
  }) => {
    $scope.props.selectedDateStart = dateStart;
    $scope.props.selectedDateEnd = dateEnd;

    $scope.loadData();
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    let dateStart;
    let dateEnd;

    if (when === 'thisWeek') {
      dateStart = moment().startOf('week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastWeek') {
      dateStart = moment().startOf('week').subtract(1, 'week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastTwoWeeks') {
      dateStart = moment().startOf('week').subtract(2, 'weeks');
      dateEnd = dateStart.clone().add(13, 'days');
    } else if (when === 'lastMonth') {
      dateStart = moment().startOf('month').subtract(1, 'month');
      dateEnd = moment().startOf('month').subtract(1, 'day');
    }

    $scope.props.defaultDateFilter = {
      option: 4,
      dateStart,
      dateEnd,
    };

    $scope.onDateFilter({
      dateStart,
      dateEnd,
    });
  };

  $scope.loadData = () => {
    $scope.props.hasRequiredFilters = ($scope.props.selectedEntityIds
      && $scope.props.selectedEntityIds.length) || $scope.props.selectedUserId;

    if (!$scope.props.hasRequiredFilters) {
      return;
    }

    const diff = $scope.props.selectedDateEnd.clone().add(1, 'day').diff($scope.props.selectedDateStart, 'weeks', true);

    if (diff < 0 || diff > 6) {
      AlertService.add('info', translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.DATE_ERROR_DIFF']);
      return;
    }

    evaluateQueryParams();

    $scope.props.loadingData = true;

    $http.get(`${ENDPOINT_API}/report/shiftTypeStreamBreakdown`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const {
          results: {
            users,
            grandTotal,
          },
        } = data;

        if ($scope.props.selectedBehaviours[0] === 'stream') {
          $scope.props.axisValues = $scope.props.streams
            .filter(({ applicableToLabour, deleted }) => applicableToLabour && !deleted)
            .map((stream) => ({
              id: stream.id,
              name: stream.name,
            }));
        } else {
          $scope.props.axisValues = $scope.props.shiftTypes
            .filter(({ deleted }) => !deleted)
            .map((shiftType) => ({
              id: shiftType.id,
              name: shiftType.concatenatedName,
            }));
        }

        $scope.props.data = users;
        $scope.props.grandTotal = grandTotal;

        $scope.props.data.sort((a, b) => a.user.id - b.user.id);
        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.ERROR_500']);
        }

        if (status === 400) {
          AlertService.add('danger', translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.ERROR_400']);
        }
      });
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.ShiftType,
    EnvironmentDataService.DataType.Stream,
    EnvironmentDataService.DataType.StaffGroup,
  ])
    .then(([
      entityGroup,
      shiftType,
      stream,
      staffGroup,
    ]) => {
      $scope.props.groupedEntities = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => {
            $scope.props.entityById[entity.id] = entity;

            return {
              id: entity.id,
              label: entity.name,
              parentId: entity.groupId,
              depth: 1,
            };
          }),
        ]));

      entityGroup.data.forEach((group) => {
        $scope.props.entityGroupsById[group.id] = group;
      });

      staffGroup.data.forEach((group) => {
        $scope.props.staffGroupById[group.id] = group;
      });

      $scope.props.shiftTypes = shiftType.data;
      $scope.props.streams = stream.data.slice();
      $scope.props.streams.push({
        id: -1,
        name: translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.STREAM_UNALLOCATED'],
        applicableToLabour: true,
        deleted: false,
      });

      $scope.loadData();
    });

  $scope.export = ($event, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.SHIFT_TYPE_STREAM_BREAKDOWN.TITLE'],
      'exportTable', format);
  };
};
