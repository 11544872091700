const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/filterOptionsButton.html');

const directives = require('./module');

directives.directive('rrFilterOptionsButton', (
  $translate,
  $timeout,
) => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      onSelect: '&',
      optionList: '=',
      readOnly: '=?',
      defaultOptions: '=?',
      multiSelect: '@?',
      searchable: '@?',
      defaultText: '@',
      icon: '@?',
      includeAny: '@?',
      borderless: '@?',
      multiSelectBulkMode: '@?',
      multiSelectOptionCountBehaviour: '@?',
      pulse: '@?',
      pullRight: '@?',
      showSelectAll: '@?',
      buttonClass: '@?',
    },
    link: ($scope, element) => {
      $scope.props = {
        displayText: $scope.defaultText,
        isEnabled: true,
        isMenuOpen: false,
        selectedOptions: [],
        icon: $scope.icon,
        isSearchable: !!$scope.searchable,
        searchInput: '',
        highlightedOptionId: undefined,
        options: [],
        initialised: false,
        pullRight: $scope.pullRight,
        showSelectAll: $scope.multiSelect
          && $scope.multiSelectBulkMode
          && ($scope.showSelectAll === undefined || $scope.showSelectAll === 'true'),
        buttonClass: [
          'dropdown-toggle',
          $scope.buttonClass || 'btn-default',
          $scope.pulse ? 'pulse' : '',
          $scope.borderless ? 'borderless' : '',
        ].join(' '),
      };

      const simpleBulkBehaviour = $scope.multiSelectOptionCountBehaviour === 'SIMPLE';

      const searchElement = angular.element(element.find('input')[0]);
      let highlightPointer = 0;
      let hasMenuOpenedOnce = false;

      const translations = $translate.instant([
        'DIRECTIVES.FILTER_OPTIONS_BUTTON.AND',
        'DIRECTIVES.FILTER_OPTIONS_BUTTON.OR',
        'DIRECTIVES.FILTER_OPTIONS_BUTTON.IS',
        'DIRECTIVES.FILTER_OPTIONS_BUTTON.ANY',
      ]);

      function evaluateEnabledState() {
        $scope.props.isEnabled = !$scope.readOnly && $scope.props.initialised;
      }

      $scope.$watch('readOnly', (val) => {
        if (val === undefined) {
          return;
        }

        evaluateEnabledState();
      });

      $scope.$watch('optionList', (val) => {
        if (val === undefined || !Array.isArray(val)) {
          return;
        }

        $scope.props.options = angular.copy(val.filter(({ deleted }) => !deleted));

        if ($scope.includeAny && !$scope.multiSelect) {
          $scope.props.options.unshift({
            id: '',
            label: translations['DIRECTIVES.FILTER_OPTIONS_BUTTON.ANY'],
          });

          if (!$scope.defaultOptions) {
            $scope.defaultOptions = [''];
          }
        }

        $scope.props.initialised = true;
        evaluateEnabledState();
      });

      function selectOption(id) {
        if (!$scope.props.selectedOptions.includes(id)) {
          $scope.props.selectedOptions.push(id);
        }

        const option = $scope.props.options.find((o) => o.id === id);

        if (option && $scope.multiSelect) {
          $scope.props.options
            .filter((o) => o.parentId === id)
            .forEach(({ id }) => selectOption(id));
        }
      }

      function deselectOption(id, deselectParents = true, deselectChildren = true) {
        const index = $scope.props.selectedOptions.indexOf(id);

        if (index !== -1) {
          $scope.props.selectedOptions.splice(index, 1);
        }

        const option = $scope.props.options.find((o) => o.id === id);

        if (option && $scope.multiSelect && !simpleBulkBehaviour) {
          if (deselectParents) {
            const { parentId } = option;

            if (parentId) {
              deselectOption(option.parentId, true, false);
            }
          }

          if (deselectChildren) {
            $scope.props.options
              .filter((o) => o.parentId === id)
              .forEach(({ id }) => deselectOption(id, false, true));
          }
        }
      }

      function toggleOption(id) {
        if (!$scope.multiSelect) {
          $scope.props.selectedOptions = [];
        }

        if ($scope.props.selectedOptions.includes(id)) {
          deselectOption(id);
        } else {
          selectOption(id);
        }
      }

      function joinArrayWithFinalDelimiter(array, delimiter, final) {
        return array.join(delimiter).replace(/, ([^,]*)$/, ` ${final} $1`);
      }

      function updateDisplayText() {
        const selectedOptionCount = $scope.props.selectedOptions.length;

        if ($scope.multiSelectBulkMode) {
          const hasNested = $scope.props.options.filter((o) => o.depth > 0);
          const count = simpleBulkBehaviour || !hasNested
            ? selectedOptionCount : $scope.props.selectedOptions
              .filter((id) => {
                const option = $scope.props.options.find((o) => o.id === id);
                return option ? option.depth > 0 : false;
              }).length;

          $scope.props.displayText = count > 0
            ? `${$scope.defaultText} (${count})`
            : $scope.defaultText;
          return;
        }

        if (selectedOptionCount > 0) {
          // This logic (rather than map + nested find) orders the labels in the same
          // order as the option list, rather than the order in which they were selected.
          const labels = $scope.props.options
            .filter((o) => $scope.props.selectedOptions.includes(o.id))
            .map((o) => o.label);

          if ($scope.multiSelect) {
            $scope.props.displayText = joinArrayWithFinalDelimiter(labels, ', ',
              translations['DIRECTIVES.FILTER_OPTIONS_BUTTON.AND']);
          } else {
            $scope.props.displayText = [$scope.defaultText,
              translations['DIRECTIVES.FILTER_OPTIONS_BUTTON.IS'], labels.join()].join(' ');
          }
        } else {
          if ($scope.multiSelect) {
            const allLabels = $scope.props.options.map((option) => option.label);

            $scope.props.displayText = joinArrayWithFinalDelimiter(allLabels, ', ',
              translations['DIRECTIVES.FILTER_OPTIONS_BUTTON.OR']);
          } else {
            // This scenario should never be possible
            $scope.props.displayText = [$scope.defaultText,
              translations['DIRECTIVES.FILTER_OPTIONS_BUTTON.IS']].join(' ');
          }
        }
      }

      function onFinish() {
        const obj = {
          selectedOptions: $scope.props.selectedOptions,
        };

        if ($scope.onSelect) {
          $scope.onSelect(obj);
        }
      }

      $scope.$watch('defaultOptions', (val) => {
        if (val === undefined || !Array.isArray(val)) {
          return;
        }

        $scope.props.selectedOptions = [];
        val.forEach((id) => selectOption(id));
        updateDisplayText();
      });

      $scope.onOptionClick = (id, closeMenu) => {
        toggleOption(id);
        updateDisplayText();

        if (!$scope.multiSelect || closeMenu) {
          $scope.props.isMenuOpen = false;
        }
      };

      $scope.onElementClick = () => {
        if ($scope.props.isSearchable) {
          $timeout(() => {
            searchElement.focus();
          }, 100);
        }
      };

      $scope.$watch('props.isMenuOpen', (val) => {
        if (val === true) {
          hasMenuOpenedOnce = true;
          return;
        }

        if (val === false && hasMenuOpenedOnce) {
          $scope.props.searchInput = '';
          $scope.props.highlightedOptionId = undefined;
          highlightPointer = 0;
          onFinish();
        }
      });

      $scope.optionsSearchFilter = (option) => {
        const { id } = option;
        const { searchInput } = $scope.props;

        if (!searchInput) {
          return true;
        }

        // Exclude the 'Any' option from appearing in search results
        if (!id) {
          return false;
        }

        const isMatch = option.label.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase());

        if (isMatch) {
          return true;
        }

        // If one or more of my children match, show me too
        if ($scope.multiSelect
          && $scope.props.options.some((o) => o.parentId === id && $scope.optionsSearchFilter(o))) {
          return true;
        }

        return false;
      };

      $scope.updateHighlightedOption = () => {
        $scope.props.highlightedOptionId = undefined;

        const { searchInput } = $scope.props;

        if (searchInput) {
          const matches = $scope.props.options
            .filter((o) => $scope.optionsSearchFilter(o))
            .map((o) => o.id);

          const count = matches.length;

          if (count) {
            // Modulo wrap negative index to array size: https://stackoverflow.com/a/43827557/416191
            const elementId = ((highlightPointer % count) + count) % count;
            $scope.props.highlightedOptionId = matches[elementId];
          }
        }
      };

      $scope.onSearchInputKeyPress = (event) => {
        if (event.key === 'Enter' && $scope.props.highlightedOptionId) {
          event.preventDefault();
          $scope.onOptionClick($scope.props.highlightedOptionId, true);
          return;
        }

        if (event.key === 'ArrowDown') {
          event.preventDefault();
          highlightPointer += 1;
          $scope.updateHighlightedOption();
          return;
        }

        if (event.key === 'ArrowUp') {
          event.preventDefault();
          highlightPointer -= 1;
          $scope.updateHighlightedOption();
          return;
        }

        highlightPointer = 0;
      };

      $scope.selectAllClick = () => {
        $scope.props.selectedOptions = $scope.props.options.map((o) => o.id);
        updateDisplayText();
        $scope.props.isMenuOpen = false;
      };

      $scope.clearSelectedClick = ($event) => {
        $event.stopPropagation();
        $event.preventDefault();
        $scope.props.selectedOptions = [];
        updateDisplayText();
      };
    },
  };
});
