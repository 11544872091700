const services = require('./module');

services.factory('EnvironmentDataService', (
  $http,
  $q,
  ENDPOINT_API,
) => {
  'ngInject';

  const defaultTtl = moment.duration(12, 'hours');
  const cache = new Map();

  const DataType = Object.freeze({
    AbsenceType: 'absenceType',
    AbsenceTypeReason: 'absenceTypeReason',
    Company: 'company',
    ContractBasis: 'contractBasis',
    EmergencyContactRelationship: 'emergencyContactRelationship',
    EntityGroup: 'entityGroup',
    Ethnicity: 'ethnicity',
    Gender: 'gender',
    LeaverReason: 'leaverReason',
    PayElementType: 'payElementType',
    PayrollCalendar: 'payrollCalendar',
    PermissionLevel: 'permissionLevel',
    Position: 'position',
    RecruitmentSource: 'recruitmentSource',
    ShiftType: 'shiftType',
    StaffGroup: 'staffGroup',
    Stream: 'stream',
    Tag: 'tag',
    Title: 'title',
    DocumentCategory: 'documentCategory',
    DocumentTemplate: 'documentTemplate',
    DocumentTemplateCategory: 'documentTemplateCategory',
    VisaType: 'visaType',
  });

  const dataTypeToEndpoint = new Map([
    [DataType.AbsenceType, '/environment/absenceType'],
    [DataType.AbsenceTypeReason, '/environment/absenceTypeReason'],
    [DataType.Company, '/environment/company'],
    [DataType.ContractBasis, '/environment/contractBasis'],
    [DataType.EmergencyContactRelationship, '/environment/emergencyContactRelationship'],
    [DataType.EntityGroup, '/environment/entityGroup'],
    [DataType.Ethnicity, '/environment/ethnicity'],
    [DataType.Gender, '/environment/gender'],
    [DataType.LeaverReason, '/environment/leaverReason'],
    [DataType.PayElementType, '/environment/payElementType'],
    [DataType.PayrollCalendar, '/environment/payrollCalendar'],
    [DataType.PermissionLevel, '/environment/permissionLevel'],
    [DataType.Position, '/environment/position'],
    [DataType.RecruitmentSource, '/environment/recruitmentSource'],
    [DataType.ShiftType, '/environment/shiftType'],
    [DataType.StaffGroup, '/environment/staffGroup'],
    [DataType.Stream, '/environment/stream'],
    [DataType.Tag, '/environment/tag'],
    [DataType.Title, '/environment/title'],
    [DataType.DocumentCategory, '/document/category'],
    [DataType.DocumentTemplate, '/document/template'],
    [DataType.DocumentTemplateCategory, '/document/templateCategory'],
    [DataType.VisaType, '/environment/visaType'],
  ]);

  function getFromCache(dataType) {
    const result = cache.get(dataType);

    if (!result) {
      return undefined;
    }

    const {
      ttl,
      data,
    } = result;

    if (moment.utc(ttl).isBefore()) {
      return undefined;
    }

    return data;
  }

  function storeInCache(dataType, data) {
    cache.set(dataType, {
      data,
      ttl: moment.utc().add(defaultTtl).toISOString(),
    });
  }

  function invalidateCache(dataType) {
    cache.delete(dataType);
  }

  function fetch(dataType) {
    return $q((resolve, reject) => {
      const data = getFromCache(dataType);

      if (data) {
        return resolve({ data });
      }

      const endpoint = dataTypeToEndpoint.get(dataType);

      $http.get(`${ENDPOINT_API}${endpoint}`)
        .then(({ data }) => {
          storeInCache(dataType, data.results);
          resolve({ data: data.results });
        })
        .catch(() => {
          reject();
        });
    });
  }

  return {
    DataType,
    fetchAll: (dataTypes) => $q.all(dataTypes.map((dt) => fetch(dt))),
    fetch: (dataType) => fetch(dataType),
    invalidateCache: (dataType) => invalidateCache(dataType),
  };
});
