'use strict';
var controllers = require('../../module');

controllers.controller('AbsenceOverviewCtrl', ['$scope', '$timeout', '$translate', 'AvailabilityService', 'SessionService', 'AlertService', 'StaffCommon', 'EnvironmentDataService',
    function ($scope, $timeout, $translate, AvailabilityService, SessionService, AlertService, StaffCommon, EnvironmentDataService) {
        var translations = $translate.instant([
            'STAFF.ABSENCE.ABSENCE.ERROR_500',
        ]);

        $scope.props = {
            entityId: SessionService.getEntity(),
            loadingData: false,
            noMoreData: false,
            data: [],
            approvalStates: [
                { id: 0, label: StaffCommon.getLabelFromApprovalState(0) },
                { id: 1, label: StaffCommon.getLabelFromApprovalState(1) },
            ],
            defaultApprovalStates: [1],
        };

        $scope.getDataParams = {
            dateContext: 'overlapping',
            'approvalStates[]': $scope.props.defaultApprovalStates,
            'currentEntityIds[]': [$scope.props.entityId],
            'currentPositionIds[]': [],
            'currentStaffGroupIds[]': [],
            'absenceTypeIds[]': [],
            limit: 100,
            page: 1,
            sortOrder: 'startDateAsc',
        };

        $scope.onEntitySelect = function (entity) {
            $scope.getDataParams['currentEntityIds[]'] = entity ? [entity.id] : undefined;
            $scope.loadData();
        };

        $scope.onApprovalStateFilter = function (selectedOptions) {
            $scope.getDataParams['approvalStates[]'] = selectedOptions.length ? selectedOptions : undefined;
            $scope.loadData();
        };

        $scope.onPositionFilter = function (selectedOptions) {
            var option = selectedOptions[0];
            $scope.getDataParams['currentPositionIds[]'] = option || [];
            $scope.loadData();
        };

        $scope.onStaffGroupFilter = function (selectedOptions) {
            var option = selectedOptions[0];
            $scope.getDataParams['currentStaffGroupIds[]'] = option || [];
            $scope.loadData();
        };

        $scope.onAbsenceTypeFilter = function (selectedOptions) {
            var option = selectedOptions[0];
            $scope.getDataParams['absenceTypeIds[]'] = option || [];
            $scope.loadData();
        };

        $scope.onUserFilter = function (selectedUser) {
            $scope.getDataParams.userId = selectedUser ? selectedUser.id : undefined;
            $scope.loadData();
        };

      EnvironmentDataService.fetchAll([
        EnvironmentDataService.DataType.EntityGroup,
        EnvironmentDataService.DataType.Position,
        EnvironmentDataService.DataType.StaffGroup,
        EnvironmentDataService.DataType.AbsenceType,
      ])
        .then(([
          entityGroup,
          position,
          staffGroup,
          absenceType,
        ]) => {
          $scope.props.entities = entityGroup.data;
          $scope.props.positions = position.data
            .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
          $scope.props.staffGroups = staffGroup.data
            .filter(({ entityId }) => !entityId)
            .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
          $scope.props.absenceTypes = absenceType.data
            .map(({ id, name, deleted }) => ({ id, label: name, deleted }));
        })
        .catch(() => {
          AlertService.add('danger', translations['STAFF.ABSENCE.ABSENCE.ERROR_500']);
        });

        $scope.loadData = function () {
            if ($scope.props.loadingData) {
                return;
            }

            $('#schedule').fullCalendar('refetchEvents');
        };

        function hexToRgb(hex, opacity) {
            var bigint = parseInt(hex.replace(/[^0-9A-F]/gi, ''), 16);
            var r = (bigint >> 16) & 255;
            var g = (bigint >> 8) & 255;
            var b = bigint & 255;

            return [r,g,b,opacity].join();
        }

        function convertAbsenceRecord(r) {
            const eventTypeForAbsence = 3;
            var rgbColour = r.type.colour ? hexToRgb(r.type.colour, r.approvalState === 0 ? 0.6 : 1) : undefined;

            return {
              id: `${eventTypeForAbsence}-${r.id}`,
              title: `${r.user.firstName} ${r.user.lastName}`,
              allDay: true,
              start: moment(r.localDateStart),
              end: moment(r.localDateEnd),
              resource: r.type.id,
              className: 'event-absence',
              editable: false,
              eventType: 3,
              eventId: r.id,
              color: rgbColour ? 'rgba(' + rgbColour + ')' : undefined,
            };
        }

        function fetchData(callback) {
            $scope.props.loadingData = true;

            AvailabilityService.getAbsencePaginated($scope.getDataParams)
                .success(function (data) {
                    data.absence.forEach(function (r) {
                        $scope.props.data.push(convertAbsenceRecord(r));
                    });

                    $scope.getDataParams.page += 1;

                    if (data.absence.length < $scope.getDataParams.limit) {
                        $scope.props.noMoreData = true;
                        $scope.props.loadingData = false;
                        callback();
                    } else {
                        fetchData(callback);
                    }
                })
                .error(function (error, status) {
                    if (status === 500) {
                        AlertService.add('danger', translations['STAFF.ABSENCE.ABSENCE.ERROR_500']);
                    }
                });
        }

        $scope.eventSources = [function (start, end, timezone, callback) {
            $scope.getDataParams.page = 1;
            $scope.props.noMoreData = false;
            $scope.props.data = [];

            $scope.getDataParams.start = moment(start).format();
            $scope.getDataParams.end = moment(end).format();

            fetchData(function () {
                callback($scope.props.data);
            });
        }];
    }]);
