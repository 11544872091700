'use strict';
var controllers = require('../module');

controllers.controller('DailyAvailabilityModalCtrl', ['$scope', '$uibModalInstance', 'AvailabilityService', 'data', '$translate',
    function ($scope, $uibModalInstance, availabilityService, data, $translate) {
        var labelTranslations = $translate.instant([
            'AVAILABILITY.DAILY_AVAILABILITY_MODAL.UNAVAILABLE',
            'AVAILABILITY.DAILY_AVAILABILITY_MODAL.AVAILABLE',
            'AVAILABILITY.DAILY_AVAILABILITY_MODAL.AVAILABLE_AM',
            'AVAILABILITY.DAILY_AVAILABILITY_MODAL.AVAILABLE_PM',
            'AVAILABILITY.DAILY_AVAILABILITY_MODAL.OTHER',
            'AVAILABILITY.DAILY_AVAILABILITY_MODAL.MINIMUM_TWO_DAYS',
            'AVAILABILITY.DAILY_AVAILABILITY_MODAL.STOPS_BEFORE_STARTS',
            'AVAILABILITY.DAILY_AVAILABILITY_MODAL.AVAILABILITY_END_BEFORE_START',
            'AVAILABILITY.DAILY_AVAILABILITY_MODAL.ERROR_SAVING_PATTERN',
            'AVAILABILITY.DAILY_AVAILABILITY_MODAL.ERROR_UPDATING_PATTERN',
        ]);

        $scope.dataLoaded = data.isAdd;
        $scope.isAdd = data.isAdd;
        $scope.info = {
            hasStopDate: 0
        };
        $scope.days = [];

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.options = {
            availability: [
                {id: 0, name: labelTranslations['AVAILABILITY.DAILY_AVAILABILITY_MODAL.UNAVAILABLE']},
                {id: 1, name: labelTranslations['AVAILABILITY.DAILY_AVAILABILITY_MODAL.AVAILABLE']},
                {id: 2, name: labelTranslations['AVAILABILITY.DAILY_AVAILABILITY_MODAL.AVAILABLE_AM']},
                {id: 3, name: labelTranslations['AVAILABILITY.DAILY_AVAILABILITY_MODAL.AVAILABLE_PM']},
                {id: 4, name: labelTranslations['AVAILABILITY.DAILY_AVAILABILITY_MODAL.OTHER']}
            ]
        };

        // Week pickers for setting start/end dates
        $scope.datePickerStart = moment();
        $scope.datePickerEnd = moment().add(1, 'month');

        $scope.startDateChanged = function (newDate) {
            $scope.datePickerStart = newDate.clone();
        };

        $scope.endDateChanged = function (newDate) {
            $scope.datePickerEnd = newDate.clone();
        };

        if (!data.isAdd) {
            availabilityService.getAvailabilityPattern(data.id)
                .success(function (pattern) {
                    $scope.info = pattern.info;

                    $scope.datePickerStart = moment(pattern.info.date_start);

                    if (pattern.info.date_end) {
                        $scope.info.hasStopDate = 1;
                        $scope.datePickerEnd = moment(pattern.info.date_end);
                    } else {
                        $scope.info.hasStopDate = 0;
                    }

                    $scope.days = pattern.rules;

                    $scope.dataLoaded = true;
                })
                .error(function () {
                    $uibModalInstance.dismiss('cancel');
                });
        }

        $scope.addDay = function () {
            $scope.days.push({
                day: $scope.days.length,
                option_id: 0,
                start_time: moment('2014-01-01 09:00:00'),
                end_time: moment('2014-01-01 17:00:00')
            });
        };

        $scope.hideTimeControls = function (dayId) {
            angular.forEach($scope.days, function (rule) {
                if (rule.day == dayId) {
                    rule.option_id = 0;
                }
            });
        };

        $scope.deleteDay = function (dayId) {
            angular.forEach($scope.days, function (rule, key) {
                if (rule.day == dayId) {
                    $scope.days.splice(key, 1);
                }
            });
        };

        $scope.saveAvailability = function () {
            if ($scope.days.length < 2) {
                return alert(labelTranslations['AVAILABILITY.DAILY_AVAILABILITY_MODAL.MINIMUM_TWO_DAYS']);
            }

            if ($scope.info.hasStopDate && $scope.datePickerEnd.isSameOrBefore($scope.datePickerStart)) {
                return alert(labelTranslations['AVAILABILITY.DAILY_AVAILABILITY_MODAL.STOPS_BEFORE_STARTS']);
            }

            var toSend = {
                info: {
                    type: 3,
                    nickname: $scope.info.nickname,
                    dateStart: $scope.datePickerStart.format('YYYY-MM-DD'),
                    hasStopDate: $scope.info.hasStopDate,
                    dateEnd: $scope.datePickerEnd.format('YYYY-MM-DD'),
                    userId: data.userIdOverride
                },
                rules: angular.copy($scope.days)
            };

            var rulesOk = true;

            angular.forEach(toSend.rules, function (rule) {
                if (rule.option_id !== 4 && rule.option_id !== 5) {
                    return;
                }

                var startTime = moment(rule.start_time);
                var endTime = moment(rule.end_time);

                if (endTime.format('Hm') !== '00' && endTime.isSameOrBefore(startTime)) {
                    rulesOk = false;
                    return;
                }

                rule.start_time = startTime.format('HH:mm:ss');
                rule.end_time = endTime.format('HH:mm:ss');
            });

            if (!rulesOk) {
                return alert(labelTranslations['AVAILABILITY.DAILY_AVAILABILITY_MODAL.AVAILABILITY_END_BEFORE_START']);
            }

            $scope.saveActioning = true;

            if (data.isAdd) {
                availabilityService.addAvailabilityPattern(toSend)
                    .success(function (newPattern) {
                        $uibModalInstance.close(newPattern);
                    })
                    .error(function (error, status) {
                        if (status == 403) {
                            $uibModalInstance.dismiss('cancel');
                            return;
                        }

                        $scope.saveActioning = false;
                        alert(labelTranslations['AVAILABILITY.DAILY_AVAILABILITY_MODAL.ERROR_SAVING_PATTERN']);
                    });
            } else {
                availabilityService.updateAvailabilityPattern(data.id, toSend)
                    .success(function (updatedPattern) {
                        $uibModalInstance.close(updatedPattern);
                    })
                    .error(function (error, status) {
                        if (status == 403) {
                            $uibModalInstance.dismiss('cancel');
                            return;
                        }

                        $scope.saveActioning = false;
                        alert(labelTranslations['AVAILABILITY.DAILY_AVAILABILITY_MODAL.ERROR_UPDATING_PATTERN']);
                    });
            }
        };
    }]);
