module.exports = (
  $scope,
  $state,
  $uibModalInstance,
  $http,
  $window,
  $translate,
  SessionService,
  EnvironmentDataService,
  AlertService,
  StaffCommon,
  Upload,
  ENDPOINT_API,
  data,
) => {
  'ngInject';

  const {
    employeeUserId: defaultEmployeeUserId,
    templateId: defaultTemplateId,
  } = data;

  const translations = $translate.instant([
    'STAFF.VIEW.DOCUMENT_SIGNATURE_MODAL.ALERT_PICK_CATEGORY',
    'STAFF.VIEW.DOCUMENT_SIGNATURE_MODAL.ALERT_SUCCESS_MANUAL',
    'STAFF.VIEW.DOCUMENT_SIGNATURE_MODAL.ALERT_SUCCESS_ELECTRONIC',
    'STAFF.VIEW.DOCUMENT_SIGNATURE_MODAL.ALERT_ERROR_500',
    'STAFF.VIEW.DOCUMENT_SIGNATURE_MODAL.ALERT_ERROR_400',
  ]);

  const sourceUserId = SessionService.getUserId();

  $scope.props = {
    actionInProgress: true,
    defaultEmployeeUserId,
    source: 'template',
    hasTemplates: false,
    filteredDocumentTemplateList: [],
    defaultExpiryDate: moment.utc().add(1, 'year'),
    formData: {
      locale: SessionService.getUserLocale(),
      employerSignatureRequired: false,
      signatureMethod: 'ELECTRONIC',
    },
    credits: {
      loading: true,
    },
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.DocumentCategory,
    EnvironmentDataService.DataType.DocumentTemplate,
    EnvironmentDataService.DataType.DocumentTemplateCategory,
  ])
    .then(([
      documentCategory,
      documentTemplate,
      documentTemplateCategory,
    ]) => {
      $scope.props.documentCategoryList = documentCategory.data
        .filter(({ sensitive }) => {
          if (sensitive && !StaffCommon.canAccessSensitiveDocuments()) {
            return false;
          }

          return true;
        })
        .map((category) => ({ ...category, label: category.name }));

      $scope.props.documentTemplateList = documentTemplate.data
        .map((template) => ({ ...template, label: template.name }));

      $scope.props.documentTemplateCategoryList = documentTemplateCategory.data
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));

      const nonDeletedCategories = $scope.props.documentCategoryList
        .filter(({ deleted }) => !deleted);

      if (!nonDeletedCategories.length) {
        return $scope.close();
      }

      $scope.props.hasTemplates = !!$scope.props.documentTemplateList
        .filter(({ deleted }) => !deleted).length;

      if (defaultTemplateId) {
        const template = $scope.props.documentTemplateList.find((t) => t.id === defaultTemplateId);

        if (template) {
          const category = $scope.props.documentTemplateCategoryList
            .find((c) => c.id === template.categoryId);

          $scope.props.selectedTemplateCategoryId = category.id;
          $scope.onTemplateCategorySelected(category);
          $scope.props.formData.templateId = defaultTemplateId;
          $scope.onTemplateSelected(template);
        }
      }

      $scope.props.actionInProgress = false;
      $scope.refreshCreditsBalance();
    });

  function resetThings() {
    $scope.props.formData.templateId = undefined;
    $scope.props.formData.employerSignatureRequired = false;
    $scope.props.selectedTemplate = undefined;
    $scope.props.formData.destinationDocumentCategoryId = undefined;
    $scope.props.selectedDestinationCategory = undefined;
  }

  $scope.addTemplate = () => {
    $scope.close();
    $state.go('settings.documentTemplates');
  };

  $scope.onSourceChange = () => {
    if ($scope.props.source === 'upload') {
      $scope.props.selectedTemplateCategoryId = undefined;
    }

    resetThings();
  };

  $scope.onTemplateCategorySelected = (option) => {
    if (!option) {
      return;
    }

    resetThings();
    $scope.props.filteredDocumentTemplateList = $scope.props.documentTemplateList
      .filter((t) => t.categoryId === option.id);
  };

  $scope.onTemplateSelected = (option) => {
    if (!option) {
      return;
    }

    $scope.props.selectedTemplate = option;
    $scope.props.formData.employerSignatureRequired = option.employerSignatureRequired;

    if (!$scope.props.formData.title) {
      $scope.props.formData.title = option.label;
    }

    $scope.onDestinationDocumentCategorySelect($scope.props.documentCategoryList
      .find((c) => c.id === option.destinationDocumentCategoryId));
  };

  $scope.$watch('props.formData.employerSignatureRequired', (value) => {
    $scope.props.defaultEmployerUserId = value ? sourceUserId : undefined;
  });

  $scope.onDestinationDocumentCategorySelect = (option) => {
    $scope.props.formData.destinationDocumentCategoryId = option ? option.id : undefined;
    $scope.props.selectedDestinationCategory = option;
  };

  $scope.onEmployeeSelect = (user) => {
    $scope.props.formData.employeeUserId = user.id;
  };

  $scope.onEmployerSelect = (user) => {
    $scope.props.formData.employerUserId = user.id;
  };

  $scope.onExpiryDateChange = (newDate) => {
    $scope.props.formData.expiryDate = newDate.clone();
  };

  $scope.onFileUpload = (file) => {
    $scope.props.formData.key = file.key;
  };

  $scope.onFileRemoved = () => {
    $scope.props.formData.key = undefined;
  };

  $scope.submit = (preview) => {
    if ($scope.props.actionInProgress) {
      return;
    }

    const {
      source,
      defaultEmployeeUserId,
      defaultExpiryDate,
      selectedDestinationCategory,
    } = $scope.props;

    const {
      key,
      templateId,
      employeeUserId,
      employerUserId,
      employerSignatureRequired,
      locale,
      destinationDocumentCategoryId,
      expiryDate,
      signatureMethod,
      notificationMessage,
      title,
    } = $scope.props.formData;

    if (!title) {
      return;
    }

    if (!employeeUserId && !defaultEmployeeUserId) {
      return;
    }

    if (!key && !templateId) {
      return;
    }

    if (!selectedDestinationCategory || !destinationDocumentCategoryId) {
      AlertService.add('warning', translations['STAFF.VIEW.DOCUMENT_SIGNATURE_MODAL.ALERT_PICK_CATEGORY']);
      return;
    }

    const selectedEmployeeUserId = employeeUserId || defaultEmployeeUserId;
    const selectedEmployerUserId = employerSignatureRequired
      ? (employerUserId || sourceUserId) : undefined;

    // Prevent someone setting the two signatories to the same person
    if (employerSignatureRequired && selectedEmployeeUserId === selectedEmployerUserId) {
      return;
    }

    const body = {
      key: source === 'upload' ? key : undefined,
      templateId: source === 'template' ? templateId : undefined,
      employeeUserId: selectedEmployeeUserId,
      employerUserId: selectedEmployerUserId,
      locale,
      destinationDocumentCategoryId,
      expiryDate: selectedDestinationCategory.expirable
        ? (expiryDate || defaultExpiryDate).format('YYYY-MM-DD') : undefined,
      signatureMethod,
      notificationMessage,
      preview,
      title,
    };

    $scope.props.actionInProgress = true;

    $http.post(`${ENDPOINT_API}/document/signature`, body)
      .then(({ data }) => {
        $scope.props.actionInProgress = false;

        if (preview) {
          $window.location = data.signedUrl;
          return;
        }

        if (signatureMethod === 'MANUAL') {
          AlertService.add('success', translations['STAFF.VIEW.DOCUMENT_SIGNATURE_MODAL.ALERT_SUCCESS_MANUAL']);
        } else {
          AlertService.add('success', translations['STAFF.VIEW.DOCUMENT_SIGNATURE_MODAL.ALERT_SUCCESS_ELECTRONIC']);
        }

        $uibModalInstance.close({ id: data.id });
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.DOCUMENT_SIGNATURE_MODAL.ALERT_ERROR_500']);
          return;
        }

        if (status === 400) {
          AlertService.add('danger', translations['STAFF.VIEW.DOCUMENT_SIGNATURE_MODAL.ALERT_ERROR_400']);
        }
      });
  };

  $scope.refreshCreditsBalance = () => {
    $scope.props.credits.loading = true;

    $http.get(`${ENDPOINT_API}/document/signature/credit`)
      .then(({ data }) => {
        const { results } = data;

        $scope.props.credits.balance = results
          .reduce((total, { allocation, used }) => (total + (allocation - used)), 0);
        $scope.props.credits.loading = false;
      });
  };

  $scope.close = () => {
    $uibModalInstance.dismiss();
  };
};
