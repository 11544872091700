const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/reports/documentSignatures/viewModal.html');

module.exports = (
  $scope,
  $state,
  $stateParams,
  $http,
  $translate,
  $uibModal,
  EnvironmentDataService,
  AlertService,
  ExportService,
  ENDPOINT_API,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.DOCUMENT_SIGNATURES.TITLE',
    'REPORTS.DOCUMENT_SIGNATURES.ERROR_500',
    'REPORTS.DOCUMENT_SIGNATURES.LABEL_METHOD_MANUAL',
    'REPORTS.DOCUMENT_SIGNATURES.LABEL_METHOD_ELECTRONIC',
    'REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_PENDING',
    'REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_REJECTED',
    'REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_SIGNED',
    'REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_CANCELLED',
  ]);

  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];
  const defaultEmployeeUserId = $stateParams.recipientId || undefined;
  const defaultAddedUserId = $stateParams.senderId || undefined;
  const defaultSignatureMethods = $stateParams.method
    ? [$stateParams.method] : undefined;
  const defaultStatuses = $stateParams.statuses
    ? $stateParams.statuses.split(',') : ['PENDING', 'REJECTED', 'SIGNED'];

  const signatureMethods = new Map([
    ['MANUAL', { id: 'MANUAL', label: translations['REPORTS.DOCUMENT_SIGNATURES.LABEL_METHOD_MANUAL'] }],
    ['ELECTRONIC', { id: 'ELECTRONIC', label: translations['REPORTS.DOCUMENT_SIGNATURES.LABEL_METHOD_ELECTRONIC'] }],
  ]);

  const statuses = new Map([
    ['PENDING', { id: 'PENDING', label: translations['REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_PENDING'] }],
    ['REJECTED', { id: 'REJECTED', label: translations['REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_REJECTED'] }],
    ['SIGNED', { id: 'SIGNED', label: translations['REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_SIGNED'] }],
    ['CANCELLED', { id: 'CANCELLED', label: translations['REPORTS.DOCUMENT_SIGNATURES.LABEL_STATUS_CANCELLED'] }],
  ]);

  $scope.props = {
    loadingData: false,
    noMoreData: false,
    page: 1,
    data: [],
    defaultEntityIds,
    defaultEmployeeUserId,
    defaultAddedUserId,
    defaultSignatureMethods,
    defaultStatuses,
    selectedEntityIds: defaultEntityIds,
    selectedEmployeeUserIds: defaultEmployeeUserId ? [defaultEmployeeUserId] : undefined,
    selectedAddedUserIds: defaultAddedUserId ? [defaultAddedUserId] : undefined,
    selectedSignatureMethods: defaultSignatureMethods,
    selectedStatuses: defaultStatuses,
    signatureMethods: Array.from(signatureMethods.values()),
    statuses: Array.from(statuses.values()),
    entityGroupsById: {},
    entityById: {},
  };

  function evaluateQueryParams(reset) {
    if (reset) {
      $scope.props.page = 1;
      $scope.props.noMoreData = false;
      $scope.props.data = [];
    }

    $state.go('.', {
      entityIds: $scope.props.selectedEntityIds
        ? $scope.props.selectedEntityIds.join(',') : undefined,
      recipientId: $scope.props.selectedEmployeeUserIds
        ? $scope.props.selectedEmployeeUserIds[0] : undefined,
      senderId: $scope.props.selectedAddedUserIds
        ? $scope.props.selectedAddedUserIds[0] : undefined,
      method: $scope.props.selectedSignatureMethods && $scope.props.selectedSignatureMethods.length
        ? $scope.props.selectedSignatureMethods[0] : undefined,
      statuses: $scope.props.selectedStatuses && $scope.props.selectedStatuses.length
        ? $scope.props.selectedStatuses.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $scope.getDataParams = {
      'employeeCurrentEntityIds[]': $scope.props.selectedEntityIds,
      'employeeUserIds[]': $scope.props.selectedEmployeeUserIds,
      'addedUserIds[]': $scope.props.selectedAddedUserIds,
      'signatureMethods[]': $scope.props.selectedSignatureMethods,
      'statuses[]': $scope.props.selectedStatuses,
      page: $scope.props.page,
      limit: 50,
      sortOrder: 'addedDateDesc',
    };
  }

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.props.selectedEntityIds = entityIds.length ? entityIds : undefined;
    $scope.loadData(true);
  };

  $scope.onEmployeeUserFilter = (selectedUser) => {
    $scope.props.selectedEmployeeUserIds = selectedUser ? [selectedUser.id] : undefined;
    $scope.loadData(true);
  };

  $scope.onAddedUserFilter = (selectedUser) => {
    $scope.props.selectedAddedUserIds = selectedUser ? [selectedUser.id] : undefined;
    $scope.loadData(true);
  };

  $scope.onSignatureMethodFilter = (selectedOptions) => {
    $scope.props.selectedSignatureMethods = selectedOptions.length && selectedOptions[0] !== ''
      ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  $scope.onStatusFilter = (selectedOptions) => {
    $scope.props.selectedStatuses = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  function embellishRecord(r) {
    const addedDate = moment(r.added.date);

    return {
      ...r,
      destinationDocumentCategoryMeta:
        $scope.props.documentCategoryById[r.destinationDocumentCategoryId],
      signatureMethodMeta: signatureMethods.get(r.signatureMethod),
      statusMeta: statuses.get(r.status),
      addedDateFromNow: addedDate.fromNow(),
      addedDate: addedDate.format('lll'),
    };
  }

  $scope.loadData = (reset) => {
    if ($scope.props.loadingData) {
      return;
    }

    evaluateQueryParams(reset);

    $scope.props.loadingData = true;

    $http.get(`${ENDPOINT_API}/document/signature`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const { results } = data;

        $scope.props.data.push(...results.map((r) => embellishRecord(r)));
        $scope.props.page += 1;

        if (results.length < $scope.getDataParams.limit) {
          $scope.props.noMoreData = true;
        }

        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.DOCUMENT_SIGNATURES.ERROR_500']);
        }
      });
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.DocumentCategory,
  ])
    .then(([
      entityGroup,
      documentCategory,
    ]) => {
      $scope.props.documentCategoryById = {};

      documentCategory.data.forEach((category) => {
        $scope.props.documentCategoryById[category.id] = category;
      });

      $scope.props.entityGroupList = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => {
            $scope.props.entityById[entity.id] = entity;

            return {
              id: entity.id,
              label: entity.name,
              parentId: entity.groupId,
              depth: 1,
            };
          }),
        ]));
    });

  $scope.export = ($event, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.DOCUMENT_SIGNATURES.TITLE'],
      'exportTable', format);
  };

  $scope.view = (record) => {
    const modalInstance = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./viewModal'),
      resolve: {
        data: () => ({
          record,
        }),
      },
    });

    modalInstance.result.then((data) => {
      if (!data) {
        return;
      }

      const { id, props } = data;
      const index = $scope.props.data.findIndex((r) => r.id === id);

      $scope.props.data[index] = embellishRecord(Object.assign(record, props));
    });
  };
};
