'use strict';
var controllers = require('../../../module');

controllers.controller('AbsenceStaffCtrl', ['$scope', '$state', '$translate',
    function ($scope, $state, $translate) {
        var translations = $translate.instant([
            'STAFF.VIEW.ABSENCE.INDEX.TAB_ABSENCE',
            'STAFF.VIEW.ABSENCE.INDEX.TAB_ALLOWANCES',
            'STAFF.VIEW.ABSENCE.INDEX.TAB_SETTINGS',
        ]);

        $scope.props = {
            tabs: [
                {state: 'bookings', label: translations['STAFF.VIEW.ABSENCE.INDEX.TAB_ABSENCE']},
                {state: 'allowances', label: translations['STAFF.VIEW.ABSENCE.INDEX.TAB_ALLOWANCES']},
                {state: 'settings', label: translations['STAFF.VIEW.ABSENCE.INDEX.TAB_SETTINGS']},
            ],
        };

        $scope.setSelectedTab = function (tab) {
            $state.go('staff.view.absence.' + tab.state);
        };
    }]);
