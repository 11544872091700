const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/absence/requestCalendar.html');

module.exports = (
  $scope,
  $uibModal,
  $state,
  $stateParams,
  $translate,
  SessionService,
  EnvironmentDataService,
  AvailabilityService,
  AlertService,
  StaffCommon,
  PreviousState,
) => {
  'ngInject';

  const {
    start,
    end,
    userId,
    overlapShifts,
  } = $stateParams;

  const isoDateFormat = 'YYYY-MM-DD';
  let selectedDateStart = start && moment.utc(start).isValid()
    ? moment.utc(start) : moment.utc().startOf('day');
  let selectedDateEnd = end && moment.utc(end).isValid()
    ? moment.utc(end) : moment.utc().startOf('day').add(1, 'day');
  let selectedUserId = parseInt(userId, 10) || SessionService.getUserId();
  let cachedDays;

  const translations = $translate.instant([
    'ABSENCE.REQUEST.ERROR_500',
  ]);

  const timeSlotDate = moment.utc().startOf('day');
  const timeSlotMins = 5;
  const timeSlots = [...Array((60 / timeSlotMins) * 24)]
    .map((_, i) => {
      const slot = timeSlotDate.clone().add(i * timeSlotMins, 'minutes');
      return {
        id: slot.format('HH:mm:ss'),
        label: slot.format('LT'),
      };
    });

  $scope.props = {
    loadingData: true,
    formData: {
      dateStart: selectedDateStart,
      dateEnd: selectedDateEnd.clone().subtract(1, 'day'),
      scope: 0,
      startTimeLocal: '09:00:00',
      endTimeLocal: '17:00:00',
    },
    defaultEmployeeUserId: selectedUserId,
    actionInProgress: false,
    onBehalfOfEmployee: !!userId,
    timeSlots,
    selectedAbsenceType: undefined,
    totalHours: 0,
    totalDays: 0,
    totalDaysUnpaid: 0,
    permittedExtensions: StaffCommon.permittedFileExtensions,
    files: [],
    requestDaysNotAsSuggested: false,
    absenceTypeReasonList: [],
  };

  function recalculateTotals() {
    if (!$scope.props.selectedAbsenceType) {
      return;
    }

    const {
      hoursPerDay = 0,
      hoursPerHalfDay = 0,
      days,
      formData: {
        startTimeLocal,
        endTimeLocal,
        scope,
      },
      selectedAbsenceType: {
        id: typeId,
        countsAsHrs,
      },
    } = $scope.props;

    if (!days || !days.length) {
      return;
    }

    let hours;
    let totalDays;

    switch (scope) {
      default:
      case 0:
        hours = hoursPerDay;
        break;
      case 1:
      case 2:
        hours = hoursPerHalfDay;
        totalDays = 0.5;
        break;
      case 3: {
        const pseudoStart = moment.utc(`2022-01-01T${startTimeLocal}`);
        const pseudoEnd = moment.utc(`2022-01-01T${endTimeLocal}`);

        if (!pseudoEnd.isAfter(pseudoStart)) {
          pseudoEnd.add(1, 'day');
        }

        hours = Math.round(pseudoEnd.diff(pseudoStart, 'hours', true) * 100) / 100;
        totalDays = 0.5;
        break;
      }
    }

    $scope.props.mappedDays = days.map(({ date, isDayOff }) => ({
      date,
      hours: countsAsHrs && isDayOff ? 0 : hours,
    }));

    $scope.props.totalHours = Math.round($scope.props.mappedDays
      .reduce((total, { hours }) => total + hours, 0) * 100) / 100;
    $scope.props.totalDays = totalDays || $scope.props.mappedDays
      .filter(({ hours }) => hours > 0).length;
    $scope.props.totalDaysUnpaid = $scope.props.mappedDays
      .filter(({ hours }) => hours === 0).length;

    $scope.props.actionInProgress = true;

    AvailabilityService.getAllowanceImpact({
      start: selectedDateStart.format(isoDateFormat),
      end: selectedDateEnd.format(isoDateFormat),
      typeId,
      scope,
      userId: selectedUserId,
      days: $scope.props.mappedDays,
    })
      .then(({ data }) => {
        $scope.props.allowanceTransactions = data.transactionItems;
        $scope.props.actionInProgress = false;
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 403) {
          // Silently swallow a 403 - it's a valid use case that the user may
          // not be permitted to see their allowances
          return;
        }

        AlertService.add('danger', translations['ABSENCE.REQUEST.ERROR_500']);
      });
  }

  function evaluateDataChange() {
    if (!selectedDateStart.isBefore(selectedDateEnd)) {
      return;
    }

    $scope.props.multipleDays = selectedDateEnd.diff(selectedDateStart, 'days') > 1;

    if ($scope.props.multipleDays) {
      $scope.props.formData.scope = 0;
    }

    const params = {
      start: selectedDateStart.format(isoDateFormat),
      end: selectedDateEnd.format(isoDateFormat),
      userId: selectedUserId,
    };

    AvailabilityService.getConcurrentAbsence(params)
      .then(({ data }) => {
        const {
          entityGroup,
          entity,
          userGroup,
          user,
        } = data;

        if (entityGroup.count || entity.count || userGroup.count || user.count) {
          $scope.props.overlappingAbsence = data;
        } else {
          $scope.props.overlappingAbsence = null;
        }
      });

    if (!$scope.props.selectedAbsenceType) {
      return;
    }

    $scope.props.actionInProgress = true;

    AvailabilityService.getSuggestedDaysOff(params)
      .then(({ data }) => {
        // Deep copy the array, so we can reset to it later if needed
        cachedDays = data.days.map(({ date, isDayOff }) => ({ date, isDayOff }));

        $scope.props.days = data.days;
        $scope.props.hoursPerDay = data.hoursPerDay;
        $scope.props.hoursPerHalfDay = data.hoursPerHalfDay;

        $scope.props.actionInProgress = false;
        recalculateTotals();
      })
      .catch(() => {
        AlertService.add('danger', translations['ABSENCE.REQUEST.ERROR_500']);
        $scope.props.actionInProgress = false;
      });
  }

  $scope.onAbsenceTypeSelect = (option) => {
    $scope.props.selectedAbsenceType = option;

    $scope.props.absenceTypeReasonList = $scope.props.absenceTypeReasonMasterList
      .filter(({ typeId, deleted }) => typeId === option.id && !deleted);
    $scope.props.formData.typeReasonId = null;

    evaluateDataChange();
  };

  $scope.onEmployeeSelect = (selectedUser) => {
    if (!selectedUser) {
      return;
    }

    selectedUserId = selectedUser.id;
    evaluateDataChange();
  };

  $scope.onDateStartChange = (newDate) => {
    const date = moment.utc(newDate.format('YYYY-MM-DD'));
    selectedDateStart = date.clone();

    if (!selectedDateEnd.isAfter(date)) {
      $scope.props.formData.dateEnd = date.clone();
      selectedDateEnd = date.clone().add(1, 'day');
    }

    evaluateDataChange();
  };

  $scope.onDateEndChange = (newDate) => {
    const date = moment.utc(newDate.format('YYYY-MM-DD'));

    selectedDateEnd = date.clone().add(1, 'day');
    evaluateDataChange();
  };

  $scope.onTimeChange = () => {
    evaluateDataChange();
  };

  $scope.onScopeChange = () => {
    recalculateTotals();
  };

  $scope.changeUnpaidDays = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    const modal = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: ($scope, $uibModalInstance, data) => {
        const datePickerOptions = {
          showWeeks: false,
          formatDay: 'd',
          minDate: data.start.toDate(),
          maxDate: data.end.clone().subtract(1, 'day').toDate(),
          startingDay: moment.localeData().firstDayOfWeek(),
          ngModelOptions: {
            timezone: 'UTC',
          },
          customClass: ({ date }) => ($scope.props.selectedDates
            .includes(moment.utc(moment(date).format('YYYY-MM-DD')).valueOf()) ? 'selected' : ''),
        };

        $scope.props = {
          activeDate: data.start.toDate(),
          selectedDates: data.days
            .filter(({ isDayOff }) => isDayOff)
            .map(({ date }) => moment.utc(date).valueOf()),
          datePickerOptions,
        };

        $scope.save = () => {
          $uibModalInstance.close(data.days.map(({ date }) => ({
            date,
            isDayOff: $scope.props.selectedDates.includes(moment.utc(date).valueOf()),
          })));
        };
      },
      size: 'sm',
      resolve: {
        data: () => ({
          days: $scope.props.days,
          start: selectedDateStart,
          end: selectedDateEnd,
        }),
      },
    });

    modal.result.then((changedDays) => {
      $scope.props.days = changedDays;
      $scope.props.requestDaysNotAsSuggested = true;
      recalculateTotals();
    });
  };

  $scope.resetUnpaidDays = () => {
    if ($scope.props.actionInProgress || !cachedDays) {
      return;
    }

    $scope.props.days = cachedDays.map(({ date, isDayOff }) => ({ date, isDayOff }));
    $scope.props.requestDaysNotAsSuggested = false;
    recalculateTotals();
  };

  $scope.clearUnpaidDays = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    $scope.props.days = $scope.props.days.map(({ date }) => ({ date, isDayOff: false }));
    $scope.props.requestDaysNotAsSuggested = true;
    recalculateTotals();
  };

  $scope.back = () => {
    const {
      name,
      params,
    } = PreviousState;

    if (!name) {
      return;
    }

    $state.go(name, params);
  };

  $scope.onFileUpload = (file) => {
    $scope.props.files.push(file);
  };

  $scope.onFileRemoved = (file) => {
    const index = $scope.props.files.findIndex(({ key }) => key === file.key);

    if (index >= 0) {
      $scope.props.files.splice(index, 1);
    }
  };

  $scope.submit = () => {
    if (!selectedDateEnd.isAfter(selectedDateStart) || !$scope.props.selectedAbsenceType) {
      return;
    }

    const {
      formData: {
        startTimeLocal,
        endTimeLocal,
        scope,
        details,
        typeReasonId,
      },
      selectedAbsenceType: {
        id: typeId,
      },
      requestDaysNotAsSuggested,
    } = $scope.props;

    if ($scope.props.absenceTypeReasonList.length && !typeReasonId) {
      return;
    }

    $scope.props.actionInProgress = true;

    AvailabilityService.sendAbsenceRequest({
      startDate: selectedDateStart.format(isoDateFormat),
      endDate: selectedDateEnd.format(isoDateFormat),
      typeId,
      scope,
      userId: selectedUserId,
      details,
      startTimeLocal: scope === 3 ? startTimeLocal : undefined,
      endTimeLocal: scope === 3 ? endTimeLocal : undefined,
      days: $scope.props.mappedDays,
      documents: $scope.props.files.map(({ name: fileName, key }) => ({
        fileName,
        key,
      })),
      requestDaysNotAsSuggested,
      typeReasonId,
    })
      .then(({ data }) => {
        $scope.props.requestComplete = data;
        $scope.props.actionInProgress = false;
      })
      .catch(({ status, data }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['ABSENCE.REQUEST.ERROR_500']);
          return;
        }

        if (status === 400) {
          StaffCommon.onAbsenceValidationResponse(data);
        }
      });
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.AbsenceType,
    EnvironmentDataService.DataType.AbsenceTypeReason,
  ])
    .then(([
      absenceType,
      absenceTypeReason,
    ]) => {
      $scope.props.absenceTypeList = absenceType.data
        .filter((type) => {
          if (overlapShifts && !type.overlapShifts) {
            return false;
          }

          return $scope.props.onBehalfOfEmployee || type.isAllowed;
        })
        .map((type) => ({
          ...type,
          label: type.name,
        }));

      $scope.props.absenceTypeReasonMasterList = absenceTypeReason.data.map((reason) => ({
        ...reason,
        label: reason.title,
      }));

      const defaultType = $scope.props.absenceTypeList.find(({ id }) => id === 1);

      if (defaultType) {
        $scope.props.formData.typeId = defaultType.id;
        $scope.onAbsenceTypeSelect(defaultType);
      } else {
        evaluateDataChange();
      }

      $scope.props.loadingData = false;
    });
};
