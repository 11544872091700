module.exports = (
  $scope,
  $translate,
  $uibModalInstance,
  $window,
  StaffService,
  AlertService,
  data,
) => {
  'ngInject';

  const {
    userId,
    employmentDateEnd,
    anonymisedDate,
  } = data;

  const translations = $translate.instant([
    'STAFF.VIEW.DANGER_ZONE.ANONYMISE.ERROR_500',
    'STAFF.VIEW.DANGER_ZONE.ANONYMISE.ERROR_403',
    'STAFF.VIEW.DANGER_ZONE.ANONYMISE.ALERT_ANONYMISE_DATE',
  ]);

  const employmentDateEndInclusive = moment(employmentDateEnd).subtract(1, 'day');

  $scope.props = {
    actionInProgress: false,
    employmentEndDate: employmentDateEndInclusive.format('ll'),
    formData: {
      anonymiseEmployee: !!anonymisedDate,
      anonymisedDate: anonymisedDate
        ? moment(anonymisedDate) : moment(employmentDateEndInclusive).add(6, 'years'),
    },
  };

  $scope.close = () => $uibModalInstance.dismiss();

  $scope.onAnonymisedDateChanged = (date) => {
    $scope.props.formData.anonymisedDate = date.clone();
  };

  $scope.save = () => {
    const {
      anonymiseEmployee,
      anonymisedDate,
    } = $scope.props.formData;

    if (!data.anonymisedDate && !anonymiseEmployee) {
      $scope.close();
      return;
    }

    if (anonymiseEmployee
      && (!anonymisedDate.isAfter(employmentDateEndInclusive)
        || !anonymisedDate.isAfter(moment()))) {
      $window.alert(translations['STAFF.VIEW.DANGER_ZONE.ANONYMISE.ALERT_ANONYMISE_DATE']);
      return;
    }

    $scope.props.actionInProgress = true;

    const date = anonymiseEmployee
      ? anonymisedDate.clone().format('YYYY-MM-DD')
      : null;

    const fields = {
      user: {
        anonymisedDate: date,
      },
    };

    StaffService.updateAccount(userId, fields)
      .then(() => {
        $uibModalInstance.close({
          anonymisedDate: date,
        });
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.DANGER_ZONE.ANONYMISE.ERROR_500']);
        }

        if (status === 403) {
          AlertService.add('warning', translations['STAFF.VIEW.DANGER_ZONE.ANONYMISE.ERROR_403']);
        }
      });
  };
};
