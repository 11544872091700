'use strict';
var controllers = require('../module');

controllers.controller('WeeklyAvailabilitySingleModalCtrl', ['$scope', '$uibModalInstance', 'AvailabilityService', 'data',
    function ($scope, $uibModalInstance, availabilityService, data) {
        $scope.dataLoaded = true;

        $scope.pattern = {
            rules: []
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.weeklyAvailability = {
            dayOptionNames: ['Unavailable', 'Available', 'Available (AM only)', 'Available (PM only)', 'Other (specify...)'],
            dayOptions: [],

            initialise: function () {
                // Populate all the default day options
                var vm = this;
                angular.forEach(this.dayOptionNames, function (option, index) {
                    vm.dayOptions.push({
                        id: index,
                        name: option
                    });
                });
            },

            createAvailabilityOption: function (index) {
                return {
                    id: index,
                    startTime: moment('2014-01-01 09:00:00'),
                    endTime: moment('2014-01-01 17:00:00')
                };
            },

            // Resets an availability drop down to 1 (available) to hide time controls
            hideTimeControls: function (day) {
                this.getAvailabilityOption(day).id = 1;
            },

            // Getter for a given week/day of availability
            // Pads out a missing entry with a copy of the default option where necessary
            getAvailabilityOption: function (day) {
                if (!$scope.pattern.rules[day] ||
                    typeof $scope.pattern.rules[day] == 'undefined') {
                    $scope.pattern.rules[day] = this.createAvailabilityOption(1);
                }

                return $scope.pattern.rules[day];
            }
        };

        var daysOfWeek = [];
        for (var i = 0; i < 7; i++) {
            daysOfWeek.push({id: i, name: moment().weekday(i).format('dddd')});
        }

        $scope.weeklyAvailability.initialise();
        $scope.weeklyAvailability.daysOfWeek = daysOfWeek;

        // Week picker for setting start/end date
        $scope.datePickerStart = moment().startOf('week');

        $scope.startDateChanged = function (newDate) {
            $scope.datePickerStart = newDate.clone().startOf('week');
        };

        $scope.saveAvailability = function () {
            var toSend = {
                info: {
                    type: 1,
                    nickname: 'Single week',
                    dateStart: $scope.datePickerStart.format('YYYY-MM-DD'),
                    hasStopDate: 1,
                    dateEnd: $scope.datePickerStart.clone().add(6, 'days').format('YYYY-MM-DD'),
                    userId: data.userIdOverride
                },
                rules: [angular.copy($scope.pattern.rules)]
            };

            var rulesOk = true;

            angular.forEach(toSend.rules, function (week) {
                angular.forEach(week, function (day) {
                    if (day.id !== 4 && day.id !== 5) {
                        return;
                    }

                    var startTime = moment(day.startTime);
                    var endTime = moment(day.endTime);

                    if (endTime.format('Hm') !== '00' && endTime.isSameOrBefore(startTime)) {
                        rulesOk = false;
                        return;
                    }

                    day.startTime = startTime.format('HH:mm:ss');
                    day.endTime = endTime.format('HH:mm:ss');
                });
            });

            if (!rulesOk) {
                return alert('One or more of your availability rules have an end time that falls before the start time.');
            }

            $scope.saveActioning = true;

            availabilityService.addAvailabilityPattern(toSend)
                .success(function (newPattern) {
                    $uibModalInstance.close(newPattern);
                })
                .error(function (error, status) {
                    if (status == 403) {
                        $uibModalInstance.dismiss('cancel');
                        return;
                    }

                    $scope.saveActioning = false;
                    alert('Currently unable to save this availability. Please try again.');
                });

        };
    }]);
