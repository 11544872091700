const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/nav/index.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/other/editPreferencesModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/other/manageSubscriptionsModal.html');
const autoNgTemplateLoaderTemplate4 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messaging/messagingHubDrawer.html');

var directives = require('../module');

directives.directive('rrNav', ['$state', '$rootScope', '$window', '$uibModal', '$uibModalDrawer', '$cookies', 'Notification', 'SessionService', 'AuthService', 'WFM_ENDPOINT', 'EVENT_CALENDAR_ENABLED',
    function ($state, $rootScope, $window, $uibModal, $uibModalDrawer, $cookies, Notification, SessionService, AuthService, WFM_ENDPOINT, EVENT_CALENDAR_ENABLED) {
        return {
            restrict: 'A',
            templateUrl: autoNgTemplateLoaderTemplate1,
            replace: false,
            link: function ($scope) {
                $scope.navLinks = {
                    dashboard: false,
                    rotas: false,
                    costControl: false,
                    staff: false,
                    attendance: false,
                    settings: false,
                    troncUrl: `${WFM_ENDPOINT}/tronc`,
                    editorUrl: `${WFM_ENDPOINT}/new-rota`,
                    readOnlyRotaUrl: `${WFM_ENDPOINT}/rota/read-only`,
                    costControlUrl: `${WFM_ENDPOINT}/cost-control`,
                    employeeSubmittedDetails: `${WFM_ENDPOINT}/staff/employee-submitted-details`,
                    costControlAggregatedTotalsUrl: `${WFM_ENDPOINT}/cost-control/aggregated-totals`,
                    eventCalendarUrl: `${WFM_ENDPOINT}/events/calendar`,
                    assetRegistryUrl: `${WFM_ENDPOINT}/asset-registry`,
                    wfmDashboard: `${WFM_ENDPOINT}/dashboard`,
                    wfmDashboards: `${WFM_ENDPOINT}/dashboards`,
                };

                $scope.props = {
                  intercomVisible: true,
                  messagingEnabled: false,
                  eventCalendarEnabled: false,
                  employeeSubmittedDetailsEnabled: false,
                  dashboardsEnabled: false,
                };

                $scope.openMobileOverlay = function () {
                    if ($window.innerWidth <= 767) {
                        $scope.navContext.mobileOverlayVisible = true;
                    }
                };

                function initialise() {
                    $scope.navContext = {
                        userFullName: SessionService.getUserFullName(),
                        realmName: SessionService.getRealm() ? SessionService.getRealm().name : '',
                        entityName: SessionService.getEntityName(),
                        mobileOverlayVisible: false,
                        avatarUserObject: {
                            id: $rootScope.sessionData.user.userId,
                            firstName: $rootScope.sessionData.user.userFirstName,
                            lastName: $rootScope.sessionData.user.userLastName,
                            picture: $rootScope.sessionData.user.userPicture,
                        }
                    };

                    var visibility = SessionService.getModuleVisibility();

                    $scope.navVisibility = {
                        rotas: visibility.rota,
                        costControl: visibility.rota,
                        staff: visibility.staff,
                        attendance: visibility.attendance,
                        reports: visibility.reports,
                        settings: visibility.rotas || visibility.staff,
                        eventCalendar: visibility.eventCalendar,
                        assetRegistry: visibility.assetRegistry,
                    };

                    $scope.props.messagingEnabled = SessionService.isMessagingEnabled();
                    $scope.props.employeeSubmittedDetailsEnabled = SessionService.isUserSelfOnboardingEnabled() || SessionService.ssdEnabled();
                    $scope.props.eventCalendarEnabled = EVENT_CALENDAR_ENABLED;
                    $scope.props.dashboardsEnabled = SessionService.dashboardsEnabled() && visibility.rota;
                }

                // If we have to hit the server for session data, an event is fired
                // when this has successfully completed. Catch it here
                $rootScope.$on('session:loaded', function () {
                    initialise();
                });

                // On the other hand, if the session is loaded from the cache, the session
                // service will have already initialised before this directive is loaded
                if (SessionService.isInitialised()) {
                    initialise();
                }

                $scope.signOut = function () {
                    SessionService.destroy();
                    AuthService.signOut();
                };

                $scope.openEditPreferences = function () {
                    $uibModal.open({
                        templateUrl: autoNgTemplateLoaderTemplate2,
                        controller: 'EditPreferencesModalCtrl'
                    });
                };

                $scope.openManageSubscriptions = function () {
                    $uibModal.open({
                        templateUrl: autoNgTemplateLoaderTemplate3,
                        controller: 'ManageSubscriptionsModalCtrl'
                    });
                };

                $scope.openMessagingHubDrawer = async () => {
                    if (!SessionService.isMessagingEnabled()) {
                        return null;
                    }

                    await Notification.clearAll();

                    $uibModalDrawer.open({
                      templateUrl: autoNgTemplateLoaderTemplate4,
                      controller: require('../../controllers/messaging/messagingHub'),
                      backdrop: 'static',
                    });
                };

                $scope.wfmRedirect = (url, withEntity = true) => {
                  $window.location = `${url}${withEntity ? `?entityId=${SessionService.getEntity()}` : ''}`;
                };

                $scope.toggleIntercom = (display) => {
                  $scope.props.intercomVisible = display;

                  window.Intercom('update', {
                    hide_default_launcher: !display,
                  });
                };
            },
        };
    }]);
