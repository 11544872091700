module.exports = (
  $scope,
  $state,
  $stateParams,
  $http,
  $translate,
  EnvironmentDataService,
  AlertService,
  ExportService,
  ENDPOINT_API,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.WORKING_TIME_VIOLATIONS.TITLE',
    'REPORTS.WORKING_TIME_VIOLATIONS.DATE_ERROR_DIFF',
    'REPORTS.WORKING_TIME_VIOLATIONS.ERROR_500',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_1',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_2',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_3',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_4',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_5',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_6',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_1_DESC',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_2_DESC',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_3_DESC',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_4_DESC',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_5_DESC',
    'REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_6_DESC',
  ]);

  $scope.violationTypeDescriptionMap = new Map([
    [1, translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_1_DESC']],
    [2, translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_2_DESC']],
    [3, translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_3_DESC']],
    [4, translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_4_DESC']],
    [5, translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_5_DESC']],
    [6, translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_6_DESC']],
  ]);

  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];
  const defaultDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('week').subtract(1, 'week');
  const defaultDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('week').subtract(1, 'day');

  $scope.props = {
    loadingData: false,
    data: [],
    defaultDateFilter: {
      option: 4,
      dateStart: defaultDateStart,
      dateEnd: defaultDateEnd,
    },
    defaultEntityIds,
    selectedDateStart: defaultDateStart,
    selectedDateEnd: defaultDateEnd,
    selectedEntityIds: defaultEntityIds,
    entityGroupsById: new Map(),
    entityGroupsAsArray: [],
    entityById: new Map(),
    violationTypes: [
      { id: 1, label: translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_1'] },
      { id: 2, label: translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_2'] },
      { id: 6, label: translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_6'] },
      { id: 3, label: translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_3'] },
      { id: 4, label: translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_4'] },
      { id: 5, label: translations['REPORTS.WORKING_TIME_VIOLATIONS.VIOLATION_TYPE_5'] },
    ],
    perPageOptions: [
      { id: 25, label: '25' },
      { id: 50, label: '50' },
      { id: 100, label: '100' },
      { id: 500, label: '500' },
      { id: 1000, label: '1000' },
    ],
    defaultViolationsPerPage: [25],
    selectedViolationsPerPage: 25,
    selectedViolationTypes: [],
  };

  function evaluateQueryParams() {
    $state.go('.', {
      start: $scope.props.selectedDateStart.format('YYYY-MM-DD'),
      end: $scope.props.selectedDateEnd.format('YYYY-MM-DD'),
      entityIds: $scope.props.selectedEntityIds ? $scope.props.selectedEntityIds.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $scope.getDataParams = {
      startDate: $scope.props.selectedDateStart.format('YYYY-MM-DD'),
      endDate: $scope.props.selectedDateEnd.clone().add(1, 'day').format('YYYY-MM-DD'),
      userCurrentEntityIds: $scope.props.selectedEntityIds.join(','),
    };
  }

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.props.selectedEntityIds = entityIds.length ? entityIds : undefined;
    $scope.loadData();
  };

  $scope.onDateFilter = ({
    dateEnd,
    dateStart,
  }) => {
    $scope.props.selectedDateStart = dateStart;
    $scope.props.selectedDateEnd = dateEnd;

    $scope.loadData();
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    let dateStart;
    let dateEnd;

    if (when === 'thisWeek') {
      dateStart = moment().startOf('week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastWeek') {
      dateStart = moment().startOf('week').subtract(1, 'week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastTwoWeeks') {
      dateStart = moment().startOf('week').subtract(2, 'weeks');
      dateEnd = dateStart.clone().add(13, 'days');
    } else if (when === 'lastMonth') {
      dateStart = moment().startOf('month').subtract(1, 'month');
      dateEnd = moment().startOf('month').subtract(1, 'day');
    }

    $scope.props.defaultDateFilter = {
      option: 4,
      dateStart,
      dateEnd,
    };

    $scope.onDateFilter({
      dateStart,
      dateEnd,
    });
  };

  $scope.onViolationTypeFilter = function (selectedOptions) {
    $scope.props.selectedViolationTypes = selectedOptions || [];
  };

  $scope.onPerPageFilter = function (selectedOptions) {
    $scope.props.selectedViolationsPerPage = selectedOptions[0];
  };

  $scope.violationsFilter = (row) => {
    const { selectedViolationTypes } = $scope.props;

    if (!selectedViolationTypes.length) {
      return true;
    }

    return selectedViolationTypes.includes(row.violationType);
  };

  function constructSkeletonObject(id, name) {
    const retObj = {
      violations: {},
      totalViolations: 0,
      id,
      name,
    };

    $scope.props.violationTypes.forEach((type) => retObj.violations[type.id] = 0);

    return retObj;
  }

  function incrementViolationCount(skeletonObject, violationTypeId) {
    skeletonObject.totalViolations += 1;
    skeletonObject.violations[violationTypeId] += 1;
  }

  function calculateSummary() {
    $scope.props.summaryTotal = constructSkeletonObject();

    $scope.props.summary = $scope.props.entityGroupsAsArray.map(({ id, name, entities }) => {
      const retObj = constructSkeletonObject(id, name);
      retObj.entities = entities.filter(({ id }) => $scope.props.selectedEntityIds.includes(id))
        .map(({ id, name }) => constructSkeletonObject(id, name));
      return retObj;
    }).filter(({ entities }) => entities.length);

    $scope.props.data.forEach((violation) => {
      const {
        violationType,
        user: {
          appointment: {
            entityId,
          },
        },
      } = violation;

      const entityObject = $scope.props.entityById.get(entityId);

      if (!entityObject) {
        return;
      }

      const group = $scope.props.summary.find((g) => g.id === entityObject.groupId);

      if (!group) {
        return;
      }

      const entity = group.entities.find((e) => e.id === entityId);

      if (!entity) {
        return;
      }

      incrementViolationCount($scope.props.summaryTotal, violationType);
      incrementViolationCount(group, violationType);
      incrementViolationCount(entity, violationType);
    });
  }

  $scope.loadData = () => {
    $scope.props.hasRequiredFilters = $scope.props.selectedEntityIds
      && $scope.props.selectedEntityIds.length;

    if (!$scope.props.hasRequiredFilters) {
      return;
    }

    const diff = $scope.props.selectedDateEnd.clone().add(1, 'day').diff($scope.props.selectedDateStart, 'months', true);

    if (diff < 0 || diff > 12) {
      AlertService.add('info', translations['REPORTS.WORKING_TIME_VIOLATIONS.DATE_ERROR_DIFF']);
      return;
    }

    evaluateQueryParams();

    $scope.props.loadingData = true;

    $http.get(`${ENDPOINT_API}/rota/wtdViolations`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const {
          violations,
        } = data;

        $scope.props.data = violations.map((violation) => {
          const description = $scope.violationTypeDescriptionMap.get(violation.violationType);
          const entityObject = $scope.props.entityById.get(violation.user.appointment.entityId);
          const entityGroup = $scope.props.entityGroupsById.get(entityObject.groupId);

          return {
            ...violation,
            description,
            entityGroupName: entityGroup ? entityGroup.name : '',
          };
        });

        calculateSummary();

        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.WORKING_TIME_VIOLATIONS.ERROR_500']);
        }
      });
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
    .then(({ data }) => {
      $scope.props.entityGroupsAsArray = data;

      data.forEach((group) => {
        $scope.props.entityGroupsById.set(group.id, group);
      });

      $scope.props.groupedEntities = data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => {
            $scope.props.entityById.set(entity.id, entity);

            return {
              id: entity.id,
              label: entity.name,
              parentId: entity.groupId,
              depth: 1,
            };
          }),
        ]));

      $scope.loadData();
    });

  $scope.export = ($event, tableId, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.WORKING_TIME_VIOLATIONS.TITLE'],
      tableId, format);
  };
};
