module.exports = (
  $scope,
  $state,
  $uibModalInstance,
  $translate,
  AlertService,
  StaffService,
  data,
) => {
  'ngInject';

  const {
    document,
    category,
  } = data;

  const translations = $translate.instant([
    'STAFF.VIEW.DOCUMENT_EDIT_FORM.ALERT_SUCCESS',
    'STAFF.VIEW.DOCUMENT_EDIT_FORM.ALERT_ERROR_500',
    'STAFF.VIEW.DOCUMENT_EDIT_FORM.ALERT_ERROR_400',
  ]);

  $scope.props = {
    actionInProgress: false,
    category,
    document,
    formData: {
      expiryDate: document.expiryDate,
      notes: document.notes,
    },
  };

  $scope.close = () => {
    $uibModalInstance.dismiss();
  };

  $scope.onExpiryDateChange = (newDate) => {
    $scope.props.formData.expiryDate = newDate.clone();
  };

  $scope.save = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    const {
      formData,
    } = $scope.props;

    const body = {
      expiryDate: category.expirable ? formData.expiryDate.format('YYYY-MM-DD') : undefined,
      notes: formData.notes,
    };

    $scope.props.actionInProgress = true;

    StaffService.updateDocument(document.id, body)
      .then(({ data }) => {
        $scope.props.actionInProgress = false;

        AlertService.add('success', translations['STAFF.VIEW.DOCUMENT_EDIT_FORM.ALERT_SUCCESS']);
        $uibModalInstance.close(data);
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.DOCUMENT_EDIT_FORM.ALERT_ERROR_500']);
          return;
        }

        if (status === 400) {
          AlertService.add('danger', translations['STAFF.VIEW.DOCUMENT_EDIT_FORM.ALERT_ERROR_400']);
        }
      });
  };
};
