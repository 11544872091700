const services = require('./module');

services.factory('$uibModalDrawer', ($uibModal, $uibModalStack) => {
  'ngInject';

  return {
    open: (options) => {
      const modalInstance = $uibModal.open({
        ...options,
        windowTemplateUrl: '/views/directiveTpls/modalDrawer.html',
        animation: true,
        // backdropClass: 'modal-transparent-backdrop',
      });

      window.Intercom('update', {
        hide_default_launcher: true,
      });

      modalInstance.closed.then(() => {
        // Check whether another modal is still open
        if (!$uibModalStack.getTop()) {
          window.Intercom('update', {
            hide_default_launcher: false,
          });
        }
      });

      return modalInstance;
    },
  };
});
