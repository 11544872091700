const services = require('./module');

services.factory('ExportService', () => {
  const parser = new DOMParser();

  function isNumeric(n) {
    // Intentionally do not use Number.NaN() due to its behavioural difference
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  return {
    export: (anchor, filename, tableId, format) => (ExcellentExport.convert({
      format,
      anchor,
      filename: `Rotaready-${filename.replace(/ +/g, '')}`,
    }, [{
      name: filename,
      from: {
        table: tableId,
      },
      fixValue: (v) => {
        const dom = parser.parseFromString(v, 'text/html');
        // Elements can optionally declare an exportable value to be used instead of
        // their text content. This allows us to display "2,500 GBP" on screen but
        // export a simple numerical equivalent: 2500
        const exportableValues = dom.getElementsByClassName('exportable-value');
        let text;

        if (exportableValues.length) {
          text = [...exportableValues].map((n) => n.getAttribute('data-export-value')).join();
        } else {
          text = dom.body.textContent.trim();
        }

        // We must return the value as a float if it's numeric, otherwise the resulting
        // Excel cell data type will be text and require manual conversion to a number.
        return isNumeric(text) ? parseFloat(text) : text;
      },
    }])),
  };
});
