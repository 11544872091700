module.exports = (
  $scope,
  $http,
  $translate,
  $uibModalInstance,
  $window,
  AlertService,
  ENDPOINT_API,
  data,
) => {
  'ngInject';

  const translations = $translate.instant([
    'REPORTS.PAYROLL_EXPORT.EXPORT_MODAL.ERROR_500',
  ]);

  $scope.props = {
    stage: 1,
    format: 'brightpay',
  };

  $scope.close = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.download = function () {
    $scope.props.stage = 2;

    const params = {
      ...data.dataParams,
      thirdPartyFormat: $scope.props.format,
    };

    delete params['aggregationOptions[]'];

    $http.get(`${ENDPOINT_API}/report/payrollExport/download`, {
      params,
    })
      .then(({ data }) => {
        $scope.props.stage = 3;
        $window.location.href = data.url;
      })
      .catch(() => {
        $scope.props.stage = 1;
        AlertService.add('danger', translations['REPORTS.PAYROLL_EXPORT.EXPORT_MODAL.ERROR_500']);
      });
  };
};
