const translations = {
  "STAFF": {
    "CONSTANTS": {
      "UK_STARTER_DECLARATION_A": "Α: Αυτή είναι η πρώτη τους δουλειά από την αρχή του φορολογικού έτους (6 Απριλίου)",
      "UK_STARTER_DECLARATION_B": "Β: Αυτή είναι αυτή τη στιγμή η μόνη δουλειά τους",
      "UK_STARTER_DECLARATION_C": "Γ: Έχουν άλλη δουλειά ή σύνταξη",
      "UK_STUDENT_LOAN_PLAN_1": "Σχέδιο 1",
      "UK_STUDENT_LOAN_PLAN_2": "Σχέδιο 2",
      "UK_STUDENT_LOAN_PLAN_4": "Σχέδιο 4",
      "UK_STUDENT_LOAN_PLAN_5": "Σχέδιο 5",
      "UK_STUDENT_LOAN_PLAN_POSTGRADUATE": "Μεταπτυχιακό",
      "UK_SETTLED_STATUS_NA": "Μη εφαρμόσιμος",
      "UK_SETTLED_STATUS_PRE_SETTLED": "Προδιευθετημένο",
      "UK_SETTLED_STATUS_SETTLED": "Εγκαταστάθηκε",
      "MARITAL_STATUS_SINGLE": "Ενιαίος",
      "MARITAL_STATUS_MARRIED": "Παντρεμένος",
      "MARITAL_STATUS_DIVORCED": "Διαζευγμένος",
      "MARITAL_STATUS_WIDOWED": "Χήρος",
      "MARITAL_STATUS_CIVIL_PARTNERSHIP": "Πολιτική Εταιρική Σχέση",
      "PAYMENT_METHOD_ELECTRONIC": "Ηλεκτρονική μεταφορά",
      "PAYMENT_METHOD_CHEQUE": "Επιταγή",
      "PAYMENT_METHOD_MANUAL": "Εγχειρίδιο",
      "CONTRACTUAL_OBLIGATION_PERIOD_WEEK": "Ανά εβδομάδα",
      "CONTRACTUAL_OBLIGATION_PERIOD_MONTH": "Ανά μήνα",
      "CONTRACTUAL_OBLIGATION_UNIT_HOURS": "Ωρες",
      "CONTRACTUAL_OBLIGATION_UNIT_DAYS": "Ημέρες",
      "PAY_AMOUNT_TYPE_HOURLY": "Ωριαία τιμή",
      "PAY_AMOUNT_TYPE_DAILY": "Ημερήσια τιμή",
      "PAY_AMOUNT_TYPE_ANNUAL": "Ετήσιος μισθός",
      "PAY_FREQUENCY_WEEKLY": "Εβδομαδιαία",
      "PAY_FREQUENCY_FORTNIGHTLY": "Δεκαπενθήμερο",
      "PAY_FREQUENCY_MONTHLY": "Μηνιαία",
      "PAY_FREQUENCY_FOUR_WEEKLY": "Τέσσερα εβδομαδιαία"
    },
    "OVERVIEW": {
      "ERROR_500": "Δεν μπορούσαμε να φορτώσουμε δεδομένα τότε. Παρακαλώ προσπαθήστε ξανά.",
      "TITLE": "Προσωπικό",
      "ADD_STAFF": "Προσθήκη νέου",
      "NO_MATCH": "Δεν μπορέσαμε να βρούμε κανέναν που να ταιριάζει με τα φίλτρα σας.",
      "NO_ACTIVE_APPT_TAG": "Ανενεργό",
      "NOT_APPROVED_TAG": "Δεν έχει εγκριθεί",
      "DOCUMENTS_INVALID_TAG": "Μη έγκυρα έγγραφα",
      "NOT_INVITED_TAG": "Δεν έχει προσκληθεί",
      "NOT_SETUP_TAG": "Προσκεκλημένος",
      "EMPLOYER_INCOMPLETE_TAG": "Ελλιπής",
      "SELF_ONBOARDING": "Αυτοεπιβίβαση",
      "ACCOUNT_TAB_LABEL": "Λογαριασμός",
      "PERSONAL_TAB_LABEL": "Προσωπικά",
      "EMPLOYMENT_TAB_LABEL": "Απασχόληση",
      "PAY_TAB_LABEL": "Πληρωμή",
      "SCHEDULE_TAB_LABEL": "Πρόγραμμα",
      "ABSENCE_TAB_LABEL": "Απουσία",
      "AVAILABILITY_TAB_LABEL": "Διαθεσιμότητα",
      "SHIFT_ROUTINE_TAB_LABEL": "Ρουτίνες βάρδιας",
      "DOCUMENTS_TAB_LABEL": "Έγγραφα",
      "ATTENDANCE_TAB_LABEL": "Συμμετοχή",
      "NOTES_TAB_LABEL": "Σημειώσεις",
      "FILTER_LABEL_SITE": "Τοποθεσία/τμήμα",
      "FILTER_LABEL_POSITION": "Ρόλος εργασίας",
      "FILTER_LABEL_STAFF_GROUP": "Ομάδα προσωπικού",
      "FILTER_LABEL_EMPLOYMENT": "Κατάσταση απασχόλησης",
      "FILTER_LABEL_APPROVAL_STATUS": "Κατάσταση έγκρισης",
      "FILTER_LABEL_EMPLOYEE_SETUP": "Πρόσκληση Ροταρέιντι",
      "FILTER_LABEL_EMPLOYER_SETUP": "Ολοκλήρωση λογαριασμού",
      "FILTER_LABEL_EMPLOYMENT_START": "Ημερομηνία συμμετοχής",
      "FILTER_LABEL_EMPLOYMENT_END": "Ημερομηνία αναχώρησης",
      "FILTER_LABEL_EMPLOYMENT_START_OR_END": "Ημερομηνία συμμετοχής ή αποχώρησης",
      "BUTTON_COLUMNS": "Στήλες",
      "BUTTON_EXPORT": "Εξαγωγή",
      "EMPLOYMENT_STATUS_ACTIVE": "Ενεργός",
      "EMPLOYMENT_STATUS_INACTIVE": "Ανενεργό",
      "APPROVAL_STATUS_APPROVED": "Εγκεκριμένο",
      "APPROVAL_STATUS_NOT_APPROVED": "Δεν έχει εγκριθεί",
      "EMPLOYEE_SETUP_COMPLETE": "Αποδεκτή",
      "EMPLOYEE_SETUP_NOT_COMPLETE": "Εκκρεμεί ή δεν αποστέλλεται",
      "EMPLOYER_SETUP_COMPLETE": "Ολοκληρώθηκε",
      "EMPLOYER_SETUP_NOT_COMPLETE": "Ελλιπής",
      "COLUMN_NAME": "Όνομα",
      "COLUMN_NEXT_SHIFT": "Επόμενη βάρδια",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_BIRTH_DATE": "Ημερομηνία γέννησης",
      "COLUMN_EMAIL": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
      "COLUMN_MOBILE": "Τηλέφωνο",
      "COLUMN_OTHER_NAMES": "Άλλο (-α) όνομα (-α)",
      "COLUMN_ADDRESS_LINE_1": "Γραμμή διεύθυνσης 1",
      "COLUMN_ADDRESS_LINE_2": "Γραμμή διεύθυνσης 2",
      "COLUMN_ADDRESS_CITY": "Πόλη/πόλη",
      "COLUMN_ADDRESS_COUNTY": "Νομός/Περιοχή",
      "COLUMN_ADDRESS_POST_CODE": "Ταχυδρομικός Κώδικας",
      "COLUMN_ADDRESS_COUNTRY": "Χώρα",
      "COLUMN_PREFERRED_NAME": "Προτιμώμενο όνομα",
      "COLUMN_GENDER": "Φύλο",
      "COLUMN_TITLE": "Τίτλος",
      "COLUMN_ETHNICITY": "Εθνικότητα",
      "COLUMN_NATIONALITY": "Ιθαγένεια",
      "COLUMN_MARITAL_STATUS": "Οικογενειακή κατάσταση",
      "COLUMN_SETTLED_STATUS": "Εγκατεστημένο καθεστώς (Ηνωμένο Βασίλειο)",
      "COLUMN_PASSPORT_NUMBER": "Αριθμός διαβατηρίου",
      "COLUMN_PASSPORT_EXPIRY_DATE": "Λήξη διαβατηρίου",
      "COLUMN_WORK_EMAIL": "Δευτερεύον ηλεκτρονικό ταχυδρομείο",
      "COLUMN_WORK_PHONE": "Δευτερεύον τηλέφωνο",
      "COLUMN_BANK_ACCOUNT_NAME": "Όνομα τραπεζικού λογαριασμού",
      "COLUMN_BANK_ACCOUNT_NUMBER": "Αριθμός τραπεζικού λογαριασμού",
      "COLUMN_BANK_ACCOUNT_SORT_CODE": "Κωδικός ταξινόμησης τράπεζας",
      "COLUMN_PAYROLL_PAYMENT_METHOD": "Τρόπος πληρωμής",
      "COLUMN_NATIONAL_INSURANCE_NUMBER": "Αριθμός Εθνικής Ασφάλισης (Ηνωμένο Βασίλειο)",
      "COLUMN_STUDENT_LOANS": "Φοιτητικά δάνεια (Ηνωμένο Βασίλειο)",
      "COLUMN_AVERAGE_HOURS_DAY": "Μέσος όρος ώρεων/ημέρα",
      "COLUMN_AVERAGE_HOURS_HALF_DAY": "Μέσος όρος ώρεων/μισή ημέρα",
      "COLUMN_AVERAGE_HOURS_WEEK": "Μέσος όρος ώρες/εβδομάδα",
      "COLUMN_PRE_AVERAGE_HOURS_PER_DAY": "Μέσος όρος ωρών/ημέρα πριν από το Rotaready",
      "COLUMN_PRE_AVERAGE_HOURS_DATE": "Ημερομηνία μέσης ώρας πριν από το Rotaready",
      "COLUMN_PERMISSION_LEVEL_NAME": "Επίπεδο άδειας",
      "COLUMN_ANONYMISED": "Ανωνυμοποιημένο",
      "COLUMN_ANONYMISE_DATE": "Ημερομηνία ανωνυμοποίησης",
      "COLUMN_JOIN_DATE": "Ημερομηνία συμμετοχής",
      "COLUMN_LEAVE_DATE": "Ημερομηνία αναχώρησης",
      "COLUMN_LEAVE_DATE_EDITED_BY": "Αφήστε την ημερομηνία που έχει οριστεί από",
      "COLUMN_LEAVE_DATE_EDITED_DATE": "Ημερομηνία καθορισμένης ημερομηνίας αναχώρησης",
      "COLUMN_LENGTH_OF_SERVICE": "Διάρκεια υπηρεσίας (ημέρες)",
      "COLUMN_RECRUITMENT_SOURCE": "Πηγή προσλήψεων",
      "COLUMN_LEAVER_REASON": "Λόγος αποχώρησης",
      "COLUMN_LEAVER_REHIRE": "Επιλέξιμη επανάληψη",
      "COLUMN_EMPLOYEE_NUMBER": "Αριθμός εργαζομένου",
      "COLUMN_NOTICE_PERIOD": "Περίοδος ειδοποίησης",
      "COLUMN_STARTER_DECLARATION": "Δήλωση εκκίνησης (Ηνωμένο Βασίλειο)",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED": "Οριστικοποιημένες αποζημιώσεις",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY": "Επιδόματα που οριστικοποιήθηκαν από",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE": "Ημερομηνία οριστικοποίησης των δικαιωμάτων",
      "COLUMN_SITE_NAME": "Ιστότοπος",
      "COLUMN_SITE_ID": "Αναγνωριστικό τοποθεσίας",
      "COLUMN_DEPARTMENT_NAME": "Τμήμα",
      "COLUMN_DEPARTMENT_ID": "Αναγνωριστικό Τμήματος",
      "COLUMN_JOB_ROLE_NAME": "Ρόλος εργασίας",
      "COLUMN_COMPANY_NAME": "ΕΤΑΙΡΕΙΑ",
      "COLUMN_CONTRACT_BASIS": "Τύπος σύμβασης",
      "COLUMN_LINE_MANAGER_NAME": "Διαχειριστής γραμμής",
      "COLUMN_STAFF_GROUP_NAME": "Ομάδα προσωπικού",
      "COLUMN_WORKS_PUBLIC_HOLIDAYS": "Δημόσιες αργίες έργων",
      "COLUMN_WORKING_TIME_OPT_OUT": "Εξαίρεση των κανονισμών για το χρόνο εργασίας",
      "COLUMN_MIN_OBLIGATION_VALUE": "Αξία συμβατικής υποχρέωσης",
      "COLUMN_MIN_OBLIGATION_UNIT": "Μονάδα συμβατικής υποχρέωσης",
      "COLUMN_MIN_OBLIGATION_PERIOD": "Περίοδος συμβατικής υποχρέωσης",
      "COLUMN_MAX_OBLIGATION_VALUE": "Οριακή τιμή εργασίας",
      "COLUMN_MAX_OBLIGATION_UNIT": "Μονάδα ορίου εργασίας",
      "COLUMN_MAX_OBLIGATION_PERIOD": "Περίοδος ορίου εργασίας",
      "COLUMN_DEPARTMENT_CURRENCY_CODE": "Κωδικός νομίσματος",
      "COLUMN_PAYROLL_CALENDAR_NAME": "Ημερολόγιο μισθοδοσίας",
      "COLUMN_PAYROLL_CALENDAR_FREQUENCY": "Συχνότητα ημερολογίου μισθοδοσίας",
      "COLUMN_PAY_AMOUNT": "Ποσό πληρωμής",
      "COLUMN_PAY_AMOUNT_TYPE": "Τύπος ποσού πληρωμής",
      "COLUMN_PAY_UPLIFT": "Αύξηση αμοιβών",
      "ASSET_REGISTRY_TAB_LABEL": "Μητρώο περιουσιακών στοιχείων"
    },
    "VIEW": {
      "ACCOUNT": {
        "ERROR_500": "Δεν μπορούμε να επεξεργαστούμε την αλλαγή σας αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
        "ERROR_403": "Δεν έχετε άδεια να προβάλετε/τροποποιήσετε τα στοιχεία λογαριασμού αυτού του ατόμου.",
        "SAVE_SUCCESS": "Οι αλλαγές σας έχουν αποθηκευτεί.",
        "TITLE": "Λογαριασμός",
        "LAST_SIGN_IN_LABEL": "Τελευταία είσοδος",
        "LAST_SIGN_IN_NEVER": "Ποτέ",
        "SECTION_PERMISSIONS_TITLE": "Άδειες",
        "PERMISSION_LEVEL_LABEL": "Επίπεδο άδειας",
        "PERMISSION_LEVEL_HELP": "Αυτό θα αλλάξει αυτό που μπορεί να δει και να κάνει ο υπάλληλος στο Rotaready.",
        "SITE_ACCESS_LABEL": "Πρόσβαση στον ιστότοπο",
        "SITE_ACCESS_OPTION_APPOINTMENT": "Ο τρέχων ιστότοπός τους",
        "SITE_ACCESS_OPTION_ALL": "Όλες οι ιστοσελίδες",
        "SITE_ACCESS_OPTION_CUSTOM": "Ο τρέχων ιστότοπός τους, και προσαρμοσμένες τοποθεσίες",
        "CLOCK_IN_PIN_LABEL": "Επαναφορά κωδικού PIN εισόδου ρολογιού",
        "CLOCK_IN_PIN_HELP": "Εισαγάγετε έναν νέο τετραψήφιο κωδικό για να αλλάξετε το PIN ρολογιού αυτού του υπαλλήλου.",
        "TAGS_LABEL": "Ετικέτες",
        "TAGS_TIP": "Επισημάνετε τους υπαλλήλους με οτιδήποτε σημαντικό, όπως δεξιότητες ή χαρακτηριστικά.",
        "TAGS_PLACEHOLDER": "Πληκτρολογήστε για να βρείτε ετικέτες",
        "SAVE_BUTTON_LOADING": "Εξοικονόμηση...",
        "SAVE_BUTTON_TEXT": "Αποθήκευση",
        "LANGUAGE_LABEL": "Γλώσσα",
        "LANGUAGE_HELP": "Το Rotaready θα εμφανιστεί στην επιλεγμένη γλώσσα.",
        "ENTITY_GROUP_SUBSCRIPTION_TOOLTIP": "Αυτός ο εργαζόμενος ανατίθεται αυτόματα σε αυτόν τον ιστότοπο. Μπορείτε να διαχειριστείτε την πρόσβασή τους στη σελίδα ρυθμίσεων του Διαχειριστή Πρόσβασης Στον Ιστότοπο."
      },
      "PAY": {
        "INDEX": {
          "TAB_HISTORY": "Αρχεία πληρωμής",
          "TAB_TRONC": "Τρόνκ",
          "TAB_FINANCIALS": "Χρηματοοικονομικά"
        },
        "VIEW_HISTORY": {
          "FILTER_TYPES_LABEL": "Τύπος",
          "FILTER_TYPES_SALARY": "Μισθός",
          "FILTER_TYPES_POINT_IN_TIME": "Εφάπαξ πληρωμές",
          "FILTER_ELEMENT_TYPES_LABEL": "Στοιχείο πληρωμής",
          "STATE_ACTIVE": "Ενεργός",
          "STATE_HISTORICAL": "Ιστορικό",
          "STATE_FUTURE_DATED": "Μελλοντική ημερομηνία",
          "COLUMN_EFFECTIVE_DATE": "Ημερομηνία έναρξης ισχύος",
          "COLUMN_STATUS": "Κατάσταση",
          "COLUMN_ELEMENT_TYPE": "Στοιχείο πληρωμής",
          "COLUMN_CURRENCY": "Νόμισμα",
          "COLUMN_AMOUNT": "Ποσό",
          "COLUMN_AMOUNT_TYPE": "Τύπος ποσού",
          "COLUMN_OPTIONS": "Επιλογές",
          "ERROR_500": "Δεν μπορούμε να φορτώσουμε αρχεία πληρωμής αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
          "ERROR_403": "Δεν έχετε άδεια να προβάλετε/τροποποιήσετε τα αρχεία πληρωμής αυτού του ατόμου.",
          "ALERT_SUCCESS": "Το αρχείο πληρωμής έχει αποθηκευτεί.",
          "ALERT_DELETED": "Το αρχείο πληρωμής έχει διαγραφεί.",
          "OUTSIDE_OF_APPOINTMENT_WARNING": "Αυτό το αρχείο πληρωμής δεν είναι έγκυρο επειδή δεν απασχολούνται ενεργά αυτήν την ημερομηνία. Αλλάξτε την ημερομηνία έναρξης ισχύος.",
          "DELETE_LINK": "Διαγραφή",
          "LAST_EDITED_TOOLTIP": "Τελευταία επεξεργασία από {{UserName}} στις {{date}}",
          "NO_RECORDS": "Δεν υπάρχουν αρχεία πληρωμής για εμφάνιση.",
          "ADD_NEW_BUTTON": "Νέο ρεκόρ αμοιβών",
          "CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το αρχείο πληρωμής; Αυτό δεν μπορεί να αναιρεθεί."
        },
        "PAY_FORM": {
          "TITLE": "Πληρωμή",
          "LABEL_EFFECTIVE_DATE": "Ημερομηνία έναρξης ισχύος",
          "LABEL_ELEMENT": "Στοιχείο πληρωμής",
          "LABEL_AMOUNT": "Ποσό",
          "LABEL_AMOUNT_TYPE": "Τύπος ποσού",
          "LABEL_UPLIFT": "Αύξηση μισθών",
          "ALERT_NO_CURRENCY": "Ο εργαζόμενος δεν απασχολείται ενεργά αυτήν την ημερομηνία.",
          "ALERT_DUPLICATE_PAY": "Υπάρχει ήδη ένα αρχείο πληρωμής για το επιλεγμένο στοιχείο πληρωμής σας εκείνη την ημερομηνία.",
          "HELP_TEXT_UPLIFT": "Αυτό θα διογκώσει τους μισθούς του υπαλλήλου στον έλεγχο κόστους. Χρησιμοποιήστε το για να αντικατοπτρίσετε τυχόν επιπλέον έξοδα που επιβαρύνεστε, όπως μια σύνταξη. Μην ανησυχείτε για τους φόρους, καθώς αυτοί περιλαμβάνονται αυτόματα.",
          "BUTTON_SAVE": "Αποθήκευση",
          "BUTTON_SAVE_LOADING": "Εξοικονόμηση...",
          "BUTTON_DELETE": "Διαγραφή",
          "BUTTON_DELETE_LOADING": "Διαγράφω..."
        },
        "VIEW_FINANCIALS": {
          "ERROR_500": "Δεν μπορούμε να φορτώσουμε τις οικονομικές πληροφορίες αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
          "ERROR_403": "Δεν έχετε άδεια να προβάλετε/τροποποιήσετε τα οικονομικά στοιχεία αυτού του ατόμου.",
          "SAVE_SUCCESS": "Οι αλλαγές σας έχουν αποθηκευτεί.",
          "SECTION_BANK_ACCOUNT": "Τραπεζικός λογαριασμός",
          "SECTION_TAXES": "Φόροι",
          "ACCOUNT_NUMBER_LABEL": "Αριθμός Λογαριασμού",
          "SORT_CODE_LABEL": "Κωδικός ταξινόμησης",
          "ACCOUNT_NAME_LABEL": "Όνομα Λογαριασμού",
          "PAYMENT_METHOD": "Τρόπος πληρωμής",
          "NI_NUMBER_LABEL": "Αριθμός Εθνικής Ασφάλισης",
          "SECTION_STUDENT_LOANS": "Φοιτητικά δάνεια (Ηνωμένο Βασίλειο)",
          "PLAN_TYPE_LABEL": "Τύπος σχεδίου",
          "EFFECTIVE_DATE_LABEL": "Ημερομηνία έναρξης ισχύος",
          "LOAN_TABLE_PLAN_COLUMN": "Τύπος σχεδίου",
          "LOAN_TABLE_DATE_COLUMN": "Ημερομηνία έναρξης ισχύος",
          "LOAN_TABLE_OPTIONS_COLUMN": "Επιλογές",
          "LOAN_TABLE_NO_ROWS": "Δεν προστέθηκαν φοιτητικά δάνεια.",
          "LOAN_TABLE_DATE_NOT_SET": "Δεν έχει οριστεί",
          "SUBHEADING_ADD_STUDENT_LOAN": "Προσθήκη φοιτητικού δανείου",
          "OPTION_DELETE": "Διαγραφή",
          "ADD_LOAN_BUTTON": "Προσθήκη φοιτητικού δανείου",
          "SAVE_BUTTON_TEXT": "Αποθήκευση"
        },
        "EDIT_STUDENT_LOAN": {
          "HEADING": "Επεξεργασία φοιτητικού δανείου",
          "FORM_PLAN_TYPE_LABEL": "Τύπος πλάνου",
          "FORM_EFFECTIVE_DATE_LABEL": "Ημερομηνία έναρξης ισχύος",
          "SAVE_BUTTON_TEXT": "Αποθήκευση"
        },
        "VIEW_TRONC": {
          "COLUMN_EFFECTIVE_DATE": "Ημερομηνία έναρξης ισχύος",
          "COLUMN_STATUS": "Κατάσταση",
          "COLUMN_AMOUNT": "Ποσό",
          "COLUMN_APPORTION_TYPE": "Τύπος κατανομής",
          "COLUMN_LAST_EDITED": "Τελευταία επεξεργασία",
          "STATE_ACTIVE": "Ενεργός",
          "STATE_HISTORICAL": "Ιστορικό",
          "STATE_FUTURE_DATED": "Μελλοντική ημερομηνία",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Ωριαία τιμή",
          "APPORTION_TYPE_CLASSIC_SALARY": "Ετήσιος μισθός",
          "APPORTION_TYPE_POINTS": "Σημεία",
          "NO_RECORDS": "Δεν υπάρχουν τιμές Tronc για εμφάνιση.",
          "ADD_NEW_BUTTON": "Νέα ισοτιμία Tronc",
          "ERROR_500": "Δεν μπορούμε να φορτώσουμε τιμές Tronc αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
          "ERROR_403": "Δεν έχετε άδεια να δείτε τις τιμές Tronc αυτού του ατόμου."
        },
        "ADD_TRONC_MODAL": {
          "TITLE": "Νέα ισοτιμία Tronc",
          "BUTTON_SAVE": "Αποθήκευση",
          "BUTTON_CANCEL": "Ακύρωση",
          "LABEL_PERSON": "Πρόσωπο",
          "LABEL_DATE": "Ημερομηνία έναρξης ισχύος",
          "LABEL_APPORTION_TYPE": "Τύπος κατανομής",
          "LABEL_AMOUNT": "Ποσό",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Ωριαία τιμή",
          "APPORTION_TYPE_CLASSIC_SALARY": "Ετήσιος μισθός",
          "APPORTION_TYPE_POINTS": "Σημεία",
          "APPORTION_TYPE_HELP": "Το Tronc μπορεί να λειτουργήσει σε έναν από τους δύο τρόπους: με βάση πόντους ή κλασικό, το οποίο χρησιμοποιεί ωριαία τιμή ή μισθό. Αυτό ελέγχεται από μια ρύθμιση για τον οργανισμό σας ή τη συγκεκριμένη τοποθεσία/χώρο.",
          "ERROR_OTHER": "Δεν μπορούμε να κρατήσουμε την τιμή του Τρόνκ αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
          "ERROR_UNAUTHORIZED": "Δεν έχετε άδεια να τροποποιήσετε τις τιμές Tronc αυτού του ατόμου.",
          "ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE": "Αυτός ο υπάλληλος δεν έχει ενεργό ραντεβού την επιλεγμένη ημερομηνία έναρξης ισχύος.",
          "ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE": "Κάποιος έχει ήδη αρχίσει να συντάσσει το Tronc για την επιλεγμένη ημερομηνία έναρξης ισχύος. Χρησιμοποιήστε το εργαλείο Tronc για να επεξεργαστείτε τις τιμές για αυτήν την ημερομηνία.",
          "ERROR_NOT_SUPPORTED": "Δεν μπορείτε να επιλέξετε αυτόν τον τύπο κατανομής. Το Tronc δεν έχει ρυθμιστεί να λειτουργεί σε αυτήν τη λειτουργία."
        }
      },
      "INDEX": {
        "OPTIONS_LABEL": "Επιλογές",
        "OPTION_APPROVE_LINK": "Έγκριση για δρομολόγια και μισθοδοσία",
        "OPTION_SEND_MAGIC_LINK": "Προσκαλέστε στο Rotaready (στείλτε έναν μαγικό σύνδεσμο)",
        "OPTION_SEND_MAGIC_LINK_TOOLTIP": "Αυτό το άτομο έχει ήδη δημιουργήσει τον λογαριασμό του. Εάν έχουν πρόβλημα με τη σύνδεση, ζητήστε τους να ακολουθήσουν τον σύνδεσμο ανάκτησης λογαριασμού στη σελίδα σύνδεσης.",
        "OPTION_ANONYMISE": "Ανωνυμοποίηση",
        "OPTION_DELETE": "Διαγραφή",
        "ALERT_APPROVE_SUCCESS": "Τώρα έχουν εγκριθεί και είναι έτοιμοι να τους δοθούν βάρδιες.",
        "ALERT_APPROVE_ERROR": "Δεν μπορέσαμε να εγκρίνουμε τον λογαριασμό τότε. Παρακαλώ προσπαθήστε ξανά.",
        "ALERT_MAGIC_LINK_SUCCESS_ALL": "Έχουμε στείλει ένα email και ένα μήνυμα κειμένου με έναν μαγικό σύνδεσμο. Παρακαλούμε αφήστε λίγα λεπτά για να φτάσει.",
        "ALERT_MAGIC_LINK_SUCCESS_EMAIL": "Έχουμε στείλει ένα email με έναν μαγικό σύνδεσμο. Παρακαλούμε αφήστε λίγα λεπτά για να φτάσει.",
        "ALERT_MAGIC_LINK_SUCCESS_SMS": "Έχουμε στείλει ένα μήνυμα κειμένου με έναν μαγικό σύνδεσμο. Παρακαλούμε αφήστε λίγα λεπτά για να φτάσει.",
        "ALERT_MAGIC_LINK_ERROR_GENERIC": "Δεν μπορούσαμε να στείλουμε μαγικό σύνδεσμο τότε.",
        "ALERT_MAGIC_LINK_ERROR_NO_DETAILS": "Δεν μπορούμε να στείλουμε έναν μαγικό σύνδεσμο χωρίς διεύθυνση email ή αριθμό τηλεφώνου.",
        "ALERT_ACCOUNT_ERROR_500": "Δεν μπορούσαμε να φορτώσουμε τον υπάλληλο τότε. Παρακαλώ προσπαθήστε ξανά.",
        "ALERT_ACCOUNT_ERROR_404": "Δεν υπάρχει λογαριασμός υπαλλήλου που να σχετίζεται με αυτό το αναγνωριστικό.",
        "DELETE_ACCOUNT_TITLE": "Διαγραφή υπαλλήλου",
        "DELETE_ACCOUNT_MESSAGE": "Προειδοποίηση! Η διαγραφή ενός υπαλλήλου είναι μόνιμη. Κάνετε αυτό μόνο εάν δημιουργήσατε τον υπάλληλο κατά λάθος. Για να συνεχίσετε, πληκτρολογήστε το πλήρες όνομά τους παρακάτω.",
        "DELETE_ACCOUNT_BUTTON": "Διαγράψτε οριστικά",
        "DELETE_ACCOUNT_ERROR": "Ο υπάλληλος δεν μπορούσε να διαγραφεί. Μπορεί να μην έχετε άδεια να το κάνετε αυτό.",
        "DELETE_ACCOUNT_SUCCESS": "Ο υπάλληλος έχει διαγραφεί."
      },
      "PERSONAL": {
        "ERROR_500": "Δεν μπορούμε να επεξεργαστούμε την αλλαγή σας αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
        "ERROR_403": "Δεν έχετε άδεια να προβάλετε/τροποποιήσετε τα προσωπικά στοιχεία αυτού του ατόμου.",
        "SAVE_SUCCESS": "Οι αλλαγές σας έχουν αποθηκευτεί.",
        "EMERGENCY_CONTACTS_LINK_TEXT": "Επαφές έκτακτης ανάγκης",
        "FINANCIALS_LINK_TEXT": "Οικονομικά/φόροι",
        "LABEL_OPTIONS": "Περισσότερες επιλογές",
        "LABEL_APPROVED_BY": "Εγκεκριμένο από",
        "TITLE_LABEL": "Τίτλος",
        "FIRST_NAME_LABEL": "Πρώτο όνομα",
        "LAST_NAME_LABEL": "Επώνυμο",
        "OTHER_NAME_LABEL": "Άλλες ονομασίες (προαιρετικά)",
        "DOB_LABEL": "Ημερομηνία γέννησης",
        "DOB_HELP": "{{ηλικία}} ετών",
        "PREFERRED_NAME_LABEL": "Προτιμώμενο όνομα",
        "SECTION_ADDRESS": "Διεύθυνση",
        "SECTION_VISAS": "Βίζες",
        "SECTION_IDENTITY": "Ταυτότητα",
        "SECTION_CONTACT": "Επικοινωνία",
        "SECTION_OTHER": "Άλλο",
        "SECTION_RIGHT_TO_WORK": "Δικαίωμα εργασίας",
        "NATIONALITY_LABEL": "Ιθαγένεια",
        "GENDER_LABEL": "Φύλο",
        "ETHNICITY_LABEL": "Εθνικότητα",
        "MARITAL_STATUS_LABEL": "Οικογενειακή κατάσταση",
        "PASSPORT_NUMBER_LABEL": "Αριθμός διαβατηρίου",
        "PASSPORT_EXPIRY_LABEL": "Ημερομηνία λήξης διαβατηρίου",
        "SETTLED_STATUS_LABEL": "Εγκατεστημένο καθεστώς (Ηνωμένο Βασίλειο)",
        "PRIMARY_EMAIL_LABEL": "Κύριο ηλεκτρονικό ταχυδρομείο",
        "PRIMARY_EMAIL_HELP": "Ο υπάλληλος το χρησιμοποιεί για να συνδεθεί στο Rotaready. Μπορεί να αλλάξει μόνο εάν δεν έχουν συνδεθεί στο παρελθόν.",
        "PRIMARY_PHONE_LABEL": "Κύριο τηλέφωνο",
        "PRIMARY_PHONE_HELP": "Ο υπάλληλος το χρησιμοποιεί για να λαμβάνει ειδοποιήσεις. Το σωστό πρόθεμα κωδικού χώρας θα εφαρμοστεί αυτόματα μετά την αποθήκευση.",
        "SECONDARY_EMAIL_LABEL": "Δευτερεύον ηλεκτρονικό ταχυδρομείο",
        "SECONDARY_PHONE_LABEL": "Δευτερεύον τηλέφωνο",
        "HR_ID_LABEL": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
        "HR_ID_HELP": "Εάν αυτός ο υπάλληλος έχει έναν αριθμό ταυτότητας που χρησιμοποιείται για σκοπούς ανθρώπινου δυναμικού, μπορείτε να τον αποθηκεύσετε εδώ.",
        "PAYROLL_ID_LABEL": "Ταυτότητα μισθοδοσίας",
        "PAYROLL_ID_HELP": "Εάν αυτός ο υπάλληλος έχει έναν αριθμό ταυτότητας που χρησιμοποιείται για σκοπούς μισθοδοσίας, μπορείτε να το αποθηκεύσετε εδώ.",
        "SAVE_BUTTON_LOADING": "Εξοικονόμηση...",
        "SAVE_BUTTON_TEXT": "Αποθήκευση",
        "LINK_ADD_ADDRESS": "Προσθήκη νέας διεύθυνσης",
        "LINK_VIEW_ADDRESS_HISTORY": "Δείτε προηγούμενες διευθύνσεις",
        "NO_ADDRESS_HISTORY_TEXT": "Δεν υπάρχουν προηγούμενες διευθύνσεις",
        "CURRENT_ADDRESS_LABEL": "Τρέχουσα",
        "SHARE_CODE_LABEL": "Κωδικός κοινής χρήσης",
        "SHARE_CODE_ERROR_LENGTH": "Ο κωδικός κοινής χρήσης πρέπει να έχει μήκος 9 χαρακτήρες.",
        "SHARE_CODE_ERROR_FORMAT": "Ο κωδικός κοινής χρήσης πρέπει να περιέχει μόνο αλφαριθμητικούς χαρακτήρες.",
        "SHARE_CODE_EXPIRY_DATE_LABEL": "Ημερομηνία λήξης κωδικού κοινής χρήσης",
      },
      "ADDRESS_HISTORY": {
        "ALERT_ERROR_500": "Δεν μπορούμε να επεξεργαστούμε την αλλαγή σας αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
        "TITLE_ADD": "Προσθήκη νέας διεύθυνσης",
        "TITLE_EDIT": "Επεξεργασία διεύθυνσης",
        "ADVICE_EDIT": "Εάν η διεύθυνση του υπαλλήλου αλλάζει, θα πρέπει να προσθέσετε μια νέα διεύθυνση. Το Rotaready θα μετακινήσει αυτόματα την τρέχουσα διεύθυνση στο ιστορικό διευθύνσεών τους.",
        "ADVICE_EDIT_LINK": "Προσθήκη νέας διεύθυνσης",
        "SAVE_BUTTON_TEXT": "Αποθήκευση",
        "DELETE_BUTTON_TEXT": "Διαγραφή",
        "ADDRESS_LINE_1_LABEL": "Γραμμή 1",
        "ADDRESS_LINE_2_LABEL": "Γραμμή 2 (προαιρετική)",
        "ADDRESS_CITY_LABEL": "Πόλη/πόλη",
        "ADDRESS_COUNTY_LABEL": "Κομητεία",
        "ADDRESS_POST_CODE_LABEL": "Ταχυδρομικός Κώδικας",
        "ADDRESS_COUNTRY_LABEL": "Χώρα",
        "NOTES_LABEL": "Σημειώσεις",
        "CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν τη διεύθυνση;"
      },
      "VISAS": {
        "ADD_BTN": "Προσθήκη βίζας",
        "CURRENT_HEADING": "Ενεργή βίζα",
        "NO_CURRENT_VISA": "Αυτός ο χρήστης δεν έχει ενεργή βίζα.",
        "CURRENT_LABEL": "Ενεργή",
        "VISA_TYPE_LABEL": "Τύπος βίζας",
        "VISA_NUMBER_LABEL": "Αριθμός βίζας",
        "VISA_EXPIRY_LABEL": "Ημερομηνία λήξης βίζας",
        "VISA_EXPIRY_NOT_APPLICABLE": "Δεν εφαρμόζεται",
        "VISA_EDIT_BTN": "Επεξεργασία βίζας",
        "PREVIOUS_LABEL": "Προηγούμενη",
        "SHOW_PREVIOUS_BTN": "Εμφάνιση προηγούμενων βίζα",
        "HIDE_PREVIOUS_BTN": "Απόκρυψη προηγούμενων βίζα",
        "PREVIOUS_HEADING": "Προηγούμενη βίζα",
        "MODAL": {
          "ADD_HEADING": "Προσθήκη νέας βίζας",
          "ALERT_ERROR_500": "Δεν μπορούμε να επεξεργαστούμε την αλλαγή σας αυτή τη στιγμή. Παρακαλώ δοκιμάστε ξανά.",
          "FORM_LABEL_VISA_TYPE": "Τύπος βίζας",
          "FORM_LABEL_VISA_NUMBER": "Αριθμός βίζας",
          "FORM_LABEL_IS_CURRENT": "Αυτή είναι η ενεργή βίζα του χρήστη",
          "FORM_LABEL_HAS_EXPIRY_DATE": "Αυτή η βίζα έχει ημερομηνία λήξης",
          "FORM_LABEL_VISA_EXPIRY": "Ημερομηνία λήξης βίζας",
          "FORM_BTN_SAVE": "Αποθήκευση",
          "EDIT_VISA_HEADING": "Επεξεργασία βίζας",
          "DELETE_VISA_BTN": "Διαγραφή βίζας",
          "CONFIRM_DELETE": "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή τη βίζα;"
        }
      },
      "SCHEDULE": {
        "TITLE": "Το παρακάτω ημερολόγιο δείχνει το πλήρες πρόγραμμα του {{firstName}}, συμπεριλαμβανομένων των βάρδιων, της απουσίας και της διαθεσιμότητας.",
        "SHIFTS_LABEL": "Βάρδιες",
        "ABSENCE_LABEL": "Απουσία",
        "UNAVAILABLE_LABEL": "Μη διαθέσιμο (μοτίβα)",
        "EDIT_LINK_TEXT": "Επεξεργασία εξαιρέσεων"
      },
      "ADD_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Ρουτίνα μετατόπισης χάρτη",
        "HAS_OVERLAP_TEXT": "Οι παραπάνω ημερομηνίες αλληλεπικαλύπτονται με μια υπάρχουσα ρουτίνα βάρδιας.",
        "END_BEFORE_START_TEXT": "Η ημερομηνία έναρξης είναι πριν από την ημερομηνία λήξης.",
        "SAVE_BUTTON_TEXT": "Αποθήκευση",
        "SAVE_BUTTON_LOADING": "Εξοικονόμηση...",
        "CANCEL_BUTTON_TEXT": "Ακύρωση"
      },
      "DELETE_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Είσαι σίγουρη;",
        "WARNING": "Η διαγραφή αυτής της αντιστοίχισης θα καταργήσει όλες τις μελλοντικές αλλαγές που σχετίζονται με αυτήν.",
        "YES_BUTTON_TEXT": "Ναί",
        "YES_BUTTON_ADDITIONAL_TEXT": "Διαγραφή αυτής της αντιστοίχισης",
        "NO_BUTTON_TEXT": "Οχι",
        "NO_BUTTON_ADDITIONAL_TEXT": "Άλλαξα γνώμη.",
        "CANCEL_BUTTON_TEXT": "Ακύρωση"
      },
      "EDIT_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Επεξεργαστείτε τη χαρτογράφηση ρουτίνας",
        "END_DATE_LABEL": "Ημερομηνία λήξης",
        "CONTINUE_FOREVER_LABEL": "Συνεχίστε τη ρουτίνα βάρδιας",
        "END_BEFORE_START_TEXT": "Η ημερομηνία λήξης είναι πριν από την ημερομηνία έναρξης.",
        "HAS_OVERLAP_NO_END": "Ο καθορισμός αυτής της ημερομηνίας λήξης θα προκαλούσε αλληλεπικάλυψη με μια υπάρχουσα ρουτίνα βάρδιας.",
        "HAS_OVERLAP_END": "Ο καθορισμός μη ημερομηνίας λήξης θα προκαλούσε αλληλεπικάλυψη με μια υπάρχουσα ρουτίνα βάρδιας.",
        "SAVE_BUTTON_TEXT": "Αποθήκευση",
        "SAVE_BUTTON_LOADING_TEXT": "Εξοικονόμηση...",
        "CANCEL_BUTTON_TEXT": "Ακύρωση"
      },
      "SHIFT_ROUTINES": {
        "MAP_ROUTINE_BUTTON_TEXT": "Χάρτης στη ρουτίνα",
        "MAP_ROUTINE_SUBTITLE": "Αντιστοιχίσεις ρουτίνας μετατόπισης",
        "MAP_ROUTINE_TIP": "Η χαρτογράφηση κάποιου σε μια ρουτίνα βάρδιας προκαλεί αυτόματη δημιουργία βάρδιων. Θα διαγραφούν εάν διαγράψετε την αντιστοίχιση ή εάν προσθέσετε μια ημερομηνία λήξης.",
        "EDIT_END_DATE_TEXT": "Επεξεργασία ημερομηνίας λήξης",
        "ADD_END_DATE_TEXT": "Προσθήκη ημερομηνίας λήξης",
        "MAPPING_NO_END": "Από ",
        "MAPPING_END": " να ",
        "USER_HAS_NO_MAPPING": "Το {{firstName}} δεν αντιστοιχίζεται σε καμία ρουτίνα βάρδιας. Μπορείτε να προσθέσετε ένα χρησιμοποιώντας το παραπάνω κουμπί."
      },
      "ABSENCE": {
        "INDEX": {
          "TAB_ABSENCE": "Κρατήσεις απουσίας",
          "TAB_ALLOWANCES": "Επιδόματα",
          "TAB_SETTINGS": "Ρυθμίσεις"
        },
        "VIEW_SETTINGS": {
          "ABSENCE_HOURS_LABEL": "Μέσος όρος ωρών ανά ημέρα",
          "AVERAGE_LENGTH_LABEL": "Υπολογίστε αυτόματα με βάση τη μέση διάρκεια της εργάσιμης ημέρας",
          "SET_MANUALLY_LABEL": "Ρύθμιση χειροκίνητα",
          "HOURS_FULL_DAY_LABEL": "Ολόκληρη μέρα",
          "HOURS_LABEL": "ώρες",
          "HOURS_HELP": "Μια πλήρης απουσία θα αξίζει τόσες ώρες.",
          "HOURS_HALF_DAY_LABEL": "Μισή μέρα",
          "HOURS_HALF_DAY_HELP": "Μια μισή μέρα απουσίας θα αξίζει τόσες ώρες.",
          "DELEGATE_APPROVERS": "Εκπρόσωποι εγκρίνων",
          "DELEGATE_APPROVERS_REMOVE": "Αφαιρέστε",
          "DELEGATE_APPROVERS_HELP": "Εάν αυτός ο υπάλληλος ενεργοποιήσει το «Μην ενοχλείτε» στην εφαρμογή Rotaready και υποβληθεί ένα αίτημα απουσίας που απαιτεί την έγκρισή του, οι εξουσιοδοτητές του θα ειδοποιηθούν αντ' αυτού.",
          "SAVE_BUTTON_TEXT": "Αποθήκευση",
          "SAVE_BUTTON_LOADING_TEXT": "Εξοικονόμηση...",
          "PRE_ROTAREADY_AVERAGE_HOURS_LABEL": "Μέσος όρος ωρών ανά ημέρα πριν από το Rotaready",
          "PRE_ROTAREADY_AVERAGE_HOURS_HELP": "Εάν μεταβήκατε στο Rotaready από άλλο σύστημα κατά τη διάρκεια της απασχόλησης αυτού του ατόμου, εισαγάγετε τις τελευταίες γνωστές «μέσες ώρες ανά ημέρα» και την ημερομηνία που ίσχυε.",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_LABEL": "Τελευταία έγκυρη στις",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_HELP": "Κατά τον υπολογισμό μιας νέας τιμής για τις μέσες ώρες ανά ημέρα, εάν ο εργαζόμενος δεν έχει εργαστεί όλες τις τελευταίες 52 εβδομάδες στο Rotaready, ο υπολογισμός μας θα χρησιμοποιήσει την τιμή Pre-RotaReady για τις εβδομάδες πριν από αυτήν την ημερομηνία."
        },
        "VIEW_BOOKINGS": {
          "INCLUDE_CANCELLED_TOGGLE_LABEL": "Συμπεριλάβετε ακυρωμένο",
          "NO_BOOKINGS_MATCH_TEXT": "Δεν μπορέσαμε να βρούμε κρατήσεις που να ταιριάζουν με τα φίλτρα σας.",
          "BOOK_ABSENCE_BUTTON_TEXT": "Απουσία βιβλίου",
          "CANCEL_LINK": "Ακύρωση",
          "DATES_COLUMN_TEXT": "Ημερομηνία (ες)",
          "ABSENCE_TYPE_COLUMN_TEXT": "Τύπος",
          "HOURS_COLUMN_TEXT": "Ωρες",
          "DAYS_COLUMN_TEXT": "Ημέρες",
          "STATUS_COLUMN_TEXT": "Κατάσταση",
          "SUBMITTED_COLUMN_TEXT": "Υποβλήθηκε",
          "APPROVED_COLUMN_TEXT": "Αναθεωρήθηκε",
          "CANCELLED_COLUMN_TEXT": "Ακυρώθηκε",
          "FILTER_LABEL_APPROVAL_STATE": "Κατάσταση",
          "FILTER_LABEL_ABSENCE_TYPE": "Τύπος",
          "FILTER_LABEL_DATE": "Ημερομηνία",
          "CANCEL_REQUEST": {
            "CONFIRM": "Είστε βέβαιοι ότι θέλετε να ακυρώσετε αυτήν την κράτηση απουσίας; Αυτό δεν μπορεί να αναιρεθεί.",
            "SUCCESS": "Η κράτηση απουσίας ακυρώθηκε.",
            "ERROR_500": "Δεν μπορούσαμε να το ακυρώσουμε τώρα. Παρακαλώ προσπαθήστε ξανά."
          }
        },
        "VIEW_ALLOWANCES": {
          "ERROR_500": "Ωχ, χτυπήσαμε ένα εμπόδιο ακριβώς τότε. Παρακαλώ προσπαθήστε ξανά.",
          "BUTTON_ADD": "Νέο επίδομα",
          "BUTTON_OPTIONS": "Επιλογές",
          "BUTTON_TRANSACTIONS": "Προβολή συναλλαγών",
          "BUTTON_EDIT": "Επεξεργασία",
          "BUTTON_DELETE": "Διαγραφή",
          "NO_ALLOWANCES_TEXT": "Δεν υπάρχουν επιτρεπόμενα όρια που να ταιριάζουν με τα φίλτρα σας.",
          "FILTER_LABEL_STATUS": "Κατάσταση",
          "FILTER_LABEL_ABSENCE_TYPE": "Τύπος",
          "CHRONO_TYPE_ACTIVE": "Ενεργός",
          "CHRONO_TYPE_FUTURE": "Μέλλον",
          "CHRONO_TYPE_HISTORICAL": "Ιστορικό",
          "LABEL_NOT_DEDUCTIBLE": "Δεν εκπίπτει",
          "METRIC_ACCRUED": "Δικαίωμα",
          "METRIC_BOOKED": "Έγινε κράτηση",
          "METRIC_REMAINING": "Υπολειπόμενο",
          "METRIC_ACCRUED_LIMIT": "από {{count}}",
          "METRIC_ACCRUED_LIMIT_HELP": "Αυτό είναι το μέγιστο που μπορεί να συγκεντρωθεί.",
          "METRIC_ELAPSED": "παρήλθε",
          "METRIC_ELAPSED_HELP": "Αυτός είναι ο αριθμός των ημερών/ωρών που έχουν κλείσει στο παρελθόν. Αυτό επανυπολογίζεται αυτόματα κατά τη διάρκεια της νύχτας.",
          "METRIC_OVERSPEND": "υπερβολική δαπάνη",
          "METRIC_OVERSPEND_HELP": "Αυτός είναι ο αριθμός των ημερών/ωρών που μπορούν να κλείσουν πάνω από το δεδουλευμένο ποσό.",
          "METRIC_ACCRUAL_ESTIMATE": "να συσσωρευτεί",
          "METRIC_ACCRUAL_ESTIMATE_HELP": "Με βάση τις μέσες ώρες εργασίας σας, εκτιμούμε ότι θα συγκεντρώσετε αυτό μέχρι το τέλος του έτους αποζημίωσης.",
          "SCHEDULE_BEGINNING_OF_ACCRUAL_YEAR": "Συγκεντρώνονται στην αρχή κάθε έτους δεδουλευμένης παραγωγής",
          "SCHEDULE_EACH_CALENDAR_MONTH": "Συγκεντρώνεται κάθε ημερολογιακό μήνα",
          "SCHEDULE_EACH_HOUR_WORKED": "Συγκεντρώνονται για κάθε ώρα εργασίας",
          "SCHEDULE_EACH_HOUR_WORKED_RATE": "Συγκεντρώνονται για κάθε ώρα εργασίας σε {{rate}}%",
          "CARRIED_OVER": "{{amount}} μεταφέρθηκε από το προηγούμενο έτος",
          "LINK_TRANSACTIONS": "Προβολή συναλλαγών",
          "ALERT_CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το επίδομα; Αυτό δεν μπορεί να αναιρεθεί.",
          "ALERT_SUCCESS_DELETE": "Το επίδομα έχει διαγραφεί.",
          "FINALISED": "🔒 Ολοκληρώθηκε ({{date}})",
          "FINALISE": "Ολοκληρώστε",
          "CONVERT_HOURS_TO_DAYS_TOGGLE_LABEL": "Μετατροπή ωρών σε ημέρες"
        },
        "ALLOWANCE_VALIDATION_MODAL": {
          "TITLE": "Κάτι δεν πάει καλά",
          "DESCRIPTION": "Δυστυχώς, αυτό που προσπαθείτε να κάνετε προκαλεί ένα ή περισσότερα προβλήματα.",
          "BUTTON_CLOSE": "Κλείσιμο",
          "INVALID_ALLOWANCE_TITLE": "Μη έγκυρο επίδομα",
          "INVALID_ALLOWANCE_DESCRIPTION": "Το επίδομα έχει διαγραφεί έκτοτε και δεν μπορεί πλέον να επεξεργαστεί.",
          "OVERLAPPING_ALLOWANCE_TITLE": "Επικαλυπτόμενες επι",
          "OVERLAPPING_ALLOWANCE_DESCRIPTION": "Το επίδομα αυτό θα επικαλύπτεται με ένα υπάρχον επίδομα. Δεν μπορείτε να έχετε αλληλεπικαλυπτόμενα επιδόματα για τον ίδιο τύπο απουσίας.",
          "FINALISED_ALLOWANCE_TITLE": "Οριστικό επίδομα",
          "FINALISED_ALLOWANCE_DESCRIPTION": "Το επίδομα αυτό έχει οριστικοποιηθεί. Για να το επεξεργαστείτε ή να το διαγράψετε, πρέπει πρώτα να επαναφέρετε το επίδομα.",
          "INVALID_UNIT_TITLE": "Μη έγκυρη μονάδα",
          "INVALID_UNIT_DESCRIPTION": "Ένα επίδομα με το χρονοδιάγραμμα δεδουλευμένων που έχει οριστεί σε Κάθε ώρα εργασίας πρέπει να έχει τη μονάδα ρυθμισμένη σε Ώρες.",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_TITLE": "Μη έγκυρη ημερομηνία έναρξης ισχύος",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "Η επιλεγμένη ημερομηνία έναρξης ισχύος πρέπει να εμπίπτει μεταξύ της έναρξης και του τέλους του έτους αποζημίωσης.",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_TITLE": "Μη έγκυρη ημερομηνία αναφοράς",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "Η ημερομηνία αναφοράς πρέπει να κυμαίνεται μεταξύ της ημερομηνίας έναρξης ισχύος του επιδόματος και του τέλους του έτους αποζημίωσης."
        },
        "ALLOWANCE_FORM": {
          "ERROR_500": "Ωχ, χτυπήσαμε ένα εμπόδιο ακριβώς τότε. Παρακαλώ προσπαθήστε ξανά.",
          "ALERT_SUCCESS_ADD": "Το επίδομα προστέθηκε.",
          "ALERT_SUCCESS_UPDATE": "Το επίδομα έχει επικαιροποιηθεί.",
          "ALERT_ERROR_CALCULATE_BOOKED": "Δεν μπορούσαμε να υπολογίσουμε την κρατική τους αξία τώρα. Παρακαλώ προσπαθήστε ξανά.",
          "BUTTON_SAVE": "Αποθήκευση",
          "BUTTON_SAVE_LOADING": "Εξοικονόμηση...",
          "TITLE": "Επίδομα",
          "LABEL_YEAR_STARTS": "Το έτος ξεκινά",
          "YEAR_STARTS_HELP": "Το προεπιλεγμένο έτος διακοπών του οργανισμού σας ξεκινά από {{settingDate}}.",
          "YEAR_STARTS_CUSTOMISE_INITIATE": "Επιλέξτε μια διαφορετική ημέρα ή μήνα.",
          "YEAR_STARTS_CUSTOMISE_CANCEL": "Επαναφορά στην προεπιλεγμένη ημέρα και μήνα.",
          "LABEL_EFFECTIVE_DATE": "Ημερομηνία έναρξης ισχύος",
          "EFFECTIVE_DATE_HELP": "Εάν αυτό το επίδομα αντικαθιστά ένα υπάρχον επίδομα το ίδιο έτος, πρέπει να επιλέξετε μια ημερομηνία από την οποία αυτό ισχύει.",
          "ALERT_MISALIGNED_YEAR_START": "Αυτό δεν ευθυγραμμίζεται με το προεπιλεγμένο έτος διακοπών του οργανισμού σας.",
          "ALERT_MISALIGNED_EFFECTIVE_DATE": "Η ημερομηνία έναρξης ισχύος σας δεν είναι εντός του έτους αποζημίωσης.",
          "ALERT_ALTERED_EFFECTIVE_DATE": "Η ημερομηνία έναρξης ισχύος σας δεν ταιριάζει με την ημερομηνία έναρξης του έτους αποζημίωσης. Θα πρέπει να το κάνετε αυτό μόνο εάν αντικαθιστάτε ένα υπάρχον επίδομα το ίδιο έτος.",
          "LABEL_ABSENCE_TYPE": "Τύπος απουσίας",
          "LABEL_SCHEDULE_OF_ACCRUAL": "Χρονοδιάγραμμα δεδουλευμένων",
          "TOOLTIP_SCHEDULE_OF_ACCRUAL": "Για να εκχωρήσετε ώρες ή ημέρες χειροκίνητα, επιλέξτε «αρχή του έτους δεδουλευμένης παραγωγής». Για να ορίσει το Rotaready ώρες ή ημέρες κάθε ημερολογιακού μήνα, επιλέξτε «κάθε ημερολογιακό μήνα». Για να συσσωρεύσετε αυτόματα με βάση τις ώρες εργασίας, επιλέξτε «κάθε ώρα εργασίας».",
          "LABEL_UNIT": "Μονάδα",
          "LABEL_DEDUCTIBLE": "Εκπεστέο",
          "TOOLTIP_DEDUCTIBLE": "Ελέγξτε εάν ο εργαζόμενος μπορεί να υποβάλει αιτήματα απουσίας που αφαιρούν από αυτό το επίδομα.",
          "DEDUCTIBLE_YES": "Ναί",
          "DEDUCTIBLE_NO": "Οχι",
          "LABEL_PROJECT": "Προβλέψτε το συσσωρευμένο κατά την κράτηση",
          "TOOLTIP_PROJECT": "Κατά την υποβολή αίτησης απουσίας, προβλέψτε τις ώρες που είναι πιθανό να έχουν συσσωρευτεί και συμπεριλάβετε τις στο δεδουλευμένο ποσό του υπαλλήλου.",
          "PROJECT_YES": "Ναί",
          "PROJECT_NO": "Οχι",
          "LABEL_TOTAL_ACCRUED_ANNUALLY": "Σύνολο δεδουλευμένων ετησίως",
          "LABEL_TOTAL_ACCRUED": "Σύνολο δεδουλευμένων",
          "TOOLTIP_TOTAL_ACCRUED": "Καταχωρίστε τον αριθμό ωρών ή ημερών που έχει συγκεντρώσει μέχρι στιγμής αυτός ο υπάλληλος. Το Rotaready θα το ενημερώσει αυτόματα προς τα εμπρός.",
          "LABEL_BOOKED": "Έγινε κράτηση",
          "TOOLTIP_BOOKED": "Εισαγάγετε τον αριθμό των ωρών ή των ημερών που έχει κλείσει αυτός ο υπάλληλος. Το Rotaready θα το διατηρήσει αυτόματα.",
          "ALERT_BOOKED": "Το υπολογίσαμε αυτό για εσάς με βάση την απουσία που έγινε στο Rotaready. Αλλάξτε αυτό μόνο εάν έχει γίνει κράτηση απουσίας εκτός του Rotaready.",
          "TOOLTIP_BOOKED_BUTTON": "Υπολογίστε αυτόματα",
          "LABEL_TOTAL_TO_ACCRUE": "Μέγιστο προς συσσώρευση",
          "TOOLTIP_TOTAL_TO_ACCRUE_HOURLY": "Καταχωρίστε τον συνολικό αριθμό ωρών ή ημερών που δικαιούται να συγκεντρώσει αυτός ο υπάλληλος. Αφήστε κενό για να μην επιβάλετε ένα όριο.",
          "TOOLTIP_TOTAL_TO_ACCRUE_MONTHLY": "Καταχωρίστε τον συνολικό αριθμό ωρών ή ημερών που πρέπει να συγκεντρώσει αυτός ο υπάλληλος για το έτος.",
          "LABEL_ACCRUAL_RATE": "Παράκαμψη δεδουλευμένου επιτοκίου",
          "TOOLTIP_ACCRUAL_RATE": "Προαιρετικά, καθορίστε ένα ποσοστό δεδουλευμένων εάν θέλετε να παρακάμψετε το προεπιλεγμένο ποσοστό της δικαιοδοσίας στην οποία εργάζεται ο υπάλληλος.",
          "LABEL_OVERSPEND_LIMIT": "Υπερβολική δαπάνη",
          "TOOLTIP_OVERSPEND_LIMIT": "Προαιρετικά επιτρέψτε στον υπάλληλο να κλείσει περισσότερες ώρες ή ημέρες από ό, τι έχει συγκεντρώσει.",
          "LABEL_CARRIED_OVER": "Μεταφέρθηκε από πέρυσι",
          "BUTTON_PRO_RATA": "Αναλογικά",
          "LABEL_EMPLOYMENT_START": "Ημερομηνία έναρξης απασχόλησης",
          "LABEL_PRO_RATA_TOTAL": "Σύνολο δεδουλευμένου εάν απασχολείται όλο το έτος",
          "HELP_TEXT_PRO_RATA": "Θα ορίσουμε το σύνολο που συγκεντρώθηκε σε {{value}}.",
          "CREATE_NEXT_YEAR_CHECKBOX": "Δημιουργήστε ένα άλλο επίδομα για το επόμενο έτος χρησιμοποιώντας το σύνολο των δεδουλευμένων εάν απασχοληθείτε όλο το έτος.",
          "ALERT_SUCCESS_CALCULATE_TOTAL_ACCRUED": "Το υπολογίσαμε αυτό για εσάς χρησιμοποιώντας τις ώρες αποσύνδεσης για αυτόν τον υπάλληλο.",
          "ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED": "Δεν έχετε άδεια πρόσβασης στις ώρες αποσύνδεσης για αυτόν τον υπάλληλο. Χωρίς αυτό, δεν μπορούμε να υπολογίσουμε το σύνολο που έχει συσσωρευτεί για εσάς.",
          "ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED": "Δεν μπορέσαμε να υπολογίσουμε αυτόματα τις συνολικές δεδουλευμένες ώρες. Παρακαλώ προσπαθήστε ξανά.",
          "TOOLTIP_CALCULATE_TOTAL_ACCRUED": "Υπολογίστε αυτόματα"
        },
        "ALLOWANCE_TRANSACTIONS": {
          "TITLE": "Συναλλαγές",
          "DESCRIPTION": "Ακολουθούν όλες οι συναλλαγές που επηρεάζουν το επίδομα, εμφανίζοντας πρώτα τις πιο πρόσφατες:",
          "TYPE_ACCRUAL_HOURS_WORKED": "Συλλογή από ώρες εργασίας",
          "TYPE_ABSENCE_REQUESTED": "Ζητήθηκε απουσία",
          "TYPE_ABSENCE_REJECTED_OR_CANCELLED": "Η απουσία απορρίφθηκε ή ακυρώθηκε",
          "TYPE_MANUAL_ADJUSTMENT": "Χειροκίνητη ρύθμιση",
          "TYPE_ACCRUAL_SCHEDULE_CHANGED": "Το χρονοδιάγραμμα των δεδουλευμένων τροποποιήθηκε",
          "TYPE_CARRY_OVER": "Μεταφέρθηκε από το προηγούμενο έτος",
          "TYPE_ACCRUAL_TIME_BASED": "Δεσμευμένη με βάση το χρόνο",
          "TYPE_ABSENCE_UPDATED": "Οι ημερομηνίες απουσίας άλλαξαν",
          "TYPE_HOURS_PER_DAY_RECALCULATION": "Ώρες ανά ημέρα ενημερώνονται",
          "ERROR_500": "Ωχ, χτυπήσαμε ένα εμπόδιο ακριβώς τότε. Παρακαλώ προσπαθήστε ξανά.",
          "NO_TRANSACTIONS": "Δεν υπάρχουν συναλλαγές για εμφάνιση.",
          "BUTTON_CLOSE": "Κλείσιμο",
          "TRANSACTION_TYPE": "Φιλτράρισμα ανά τύπο"
        },
        "VIEW_FINALISATION": {
          "TITLE": "Οριστικό επίδομα",
          "BUTTON_CLOSE": "Κλείσιμο",
          "BUTTON_REVERT": "Επαναφορά",
          "CONFIRM_REVERT": "Είστε βέβαιοι ότι θέλετε να επιστρέψετε αυτό το επίδομα; Αυτό θα διαγράψει επίσης το αντίστοιχο αρχείο πληρωμής, εάν υπάρχει.",
          "LABEL_REFERENCE_DATE": "Ημερομηνία αναφοράς",
          "LABEL_PAY_RECORD": "Πληρωμή/αφαίρεση",
          "PAY_SEE_MORE": "Προβολή αρχείου πληρωμής",
          "PAY_NONE": "Καμία αμοιβή δεν οφείλεται στον εργαζόμενο, ούτε πρέπει να αφαιρεθεί καμία αμοιβή.",
          "LABEL_NOTES": "Σημειώσεις",
          "NOTES_NONE": "Δεν καταγράφηκαν σημειώσεις.",
          "ERROR_500": "Δεν μπορέσαμε να αποθηκεύσουμε τις αλλαγές σας τότε. Παρακαλώ προσπαθήστε ξανά.",
          "ERROR_403": "Δεν έχετε άδεια να τροποποιήσετε τα αρχεία αμοιβών αυτού του ατόμου, επομένως δεν μπορούμε να επαναφέρουμε το επίδομα.",
          "ALERT_SUCCESS": "Το επίδομα έχει ανασταλεί."
        },
        "FINALISE_ALLOWANCE_FORM": {
          "TITLE": "Οριστικοποιήστε το επίδομα",
          "BUTTON_SAVE": "Ολοκληρώστε",
          "ERROR_500": "Δεν μπορέσαμε να αποθηκεύσουμε τις αλλαγές σας τότε. Παρακαλώ προσπαθήστε ξανά.",
          "ERROR_403": "Δεν έχετε άδεια να τροποποιήσετε τα αρχεία αμοιβών αυτού του ατόμου, οπότε δεν μπορούμε να οριστικοποιήσουμε το επίδομα.",
          "ALERT_SUCCESS": "Το επίδομα έχει οριστικοποιηθεί.",
          "GUIDANCE": "Συνιστούμε να ολοκληρώσετε ένα επίδομα όταν μπορείτε να είστε σίγουροι ότι δεν θα συγκεντρωθεί, δεν θα κλείσει ή θα ακυρωθεί άλλη απουσία. Αυτό είναι συνήθως όσο το δυνατόν πιο κοντά στην τελευταία μέρα κάποιου.",
          "GUIDANCE_FUTURE_YEAR": "Πρέπει να το κάνετε μόνο εάν ο εργαζόμενος αποχωρεί σε αυτό το έτος αποζημίωσης.",
          "LABEL_EFFECTIVE_DATE": "Ημερομηνία αναφοράς",
          "EFFECTIVE_DATE_LEAVER_HELP": "Το επίδομα θα λήξει την ημερομηνία αυτή. Καθώς αυτό το άτομο είναι εγκαταλελειμμένο, το θέσαμε στην τελευταία του μέρα.",
          "EFFECTIVE_DATE_NOT_LEAVER_HELP": "Το επίδομα θα λήξει την ημερομηνία αυτή. Η ημερομηνία που επιλέγετε θα χρησιμοποιηθεί παρακάτω για τον υπολογισμό των συνολικών δεδουλευμένων ωρών ή ημερών.",
          "LABEL_BASIC_PAY": "Βασική αμοιβή",
          "ALERT_BASIC_PAY_NOT_PERMITTED": "Δεν έχετε άδεια να δείτε τα αρχεία αμοιβών αυτού του υπαλλήλου, επομένως δεν μπορείτε να χρησιμοποιήσετε αυτήν τη δυνατότητα για να δημιουργήσετε μια εφάπαξ πληρωμή ή έκπτωση.",
          "ALERT_BASIC_PAY_NOT_AVAILABLE": "Ο υπάλληλος δεν έχει βασικό μητρώο αμοιβής κατά την επιλεγμένη ημερομηνία αναφοράς.",
          "HELP_TEXT_BASIC_PAY": "Αυτή είναι η βασική αμοιβή του υπαλλήλου την ημερομηνία αναφοράς που επιλέξατε.",
          "LABEL_TOTAL_ACCRUED_LIVE": "Σύνολο δεδουλευμένων",
          "HELP_TEXT_TOTAL_ACCRUED_LIVE": "Πρόκειται για το σύνολο των δεδουλευμένων ωρών για αυτό το επίδομα, με βάση όλες τις ημέρες που έχουν αποσυνδεθεί.",
          "LABEL_ACCRUED_PRO_RATED": "Δεσμευμένα (αναλογικά)",
          "HELP_TEXT_TOTAL_ACCRUED_PRO_RATED": "Χρησιμοποιώντας την ημερομηνία αναφοράς που επιλέξατε, οι συνολικές δεδουλευμένες ώρες/ημέρες έχουν βαθμολογηθεί αναλογικά.",
          "LABEL_BOOKED": "Σύνολο κρατήσεων",
          "HELP_TEXT_BOOKED": "Αυτή είναι η συνολική απουσία που έχει κλείσει μέχρι σήμερα, συμπεριλαμβανομένων τόσο των εκκρεμών όσο και των εγκεκριμένων αιτημάτων.",
          "LABEL_ACCRUED_UNTAKEN": "Δεσμευμένα αλλά μη αναληφθέντα",
          "STATUS_PAYMENT_DUE": "Αυτό σημαίνει ότι ο εργαζόμενος έχει λάβει λιγότερη απουσία από ό, τι έχει συγκεντρώσει. Πολλοί εργοδότες επιλέγουν να πληρώσουν τον εργαζόμενο για αυτό.",
          "STATUS_DEDUCTION_DUE": "Αυτό σημαίνει ότι ο εργαζόμενος έχει λάβει περισσότερες απουσίες από ό, τι έχει συγκεντρώσει. Ορισμένοι εργοδότες επιλέγουν να το αφαιρέσουν από το τελικό δελτίο πληρωμής του εργαζομένου.",
          "LABEL_AVERAGE_HOURS": "Μέσος όρος ωρών ανά ημέρα",
          "LABEL_ONE_OFF_PAYMENT": "Εφάπαξ πληρωμή/έκπτωση",
          "HELP_TEXT_ONE_OFF_PAYMENT": "Εάν εισαγάγετε ένα ποσό, το Rotaready θα δημιουργήσει ένα εφάπαξ αρχείο αμοιβών και θα το συνδέσει με αυτό το οριστικό επίδομα. Για μια έκπτωση, εισαγάγετε ένα αρνητικό ποσό.",
          "HELP_TEXT_ONE_OFF_PAYMENT_LINK": "Βοήθεια για τον υπολογισμό αυτού",
          "LABEL_PAY_ELEMENT": "Στοιχείο πληρωμής",
          "LABEL_PAYMENT_DATE": "Εφαρμόσιμη ημερομηνία",
          "HELP_TEXT_PAYMENT_DATE": "Επιλέξτε προσεκτικά για να διασφαλίσετε ότι αυτή η πληρωμή περιλαμβάνεται στην τελική πληρωμή του υπαλλήλου.",
          "LABEL_NOTES": "Σημειώσεις",
          "PLACEHOLDER_NOTES": "Εάν δεν εισαγάγετε σημειώσεις, θα συμπεριλάβουμε αυτόματα τα στοιχεία από τον παραπάνω υπολογισμό."
        },
        "FINALISE_ALLOWANCE_PROMPT": {
          "TITLE": "Ολοκληρώθηκαν όλα τα επιδόματα;",
          "DESCRIPTION": "Εάν είστε ευχαριστημένοι που όλα τα επιδόματα έχουν πλέον οριστικοποιηθεί, θα πρέπει να το επιβεβαιώσετε, ώστε τμήματα όπως η μισθοδοσία να μπορούν να βασίζονται στα στοιχεία.",
          "DESCRIPTION_PART_2": "Μπορείτε επίσης να το κάνετε αυτό στην καρτέλα Απασχόληση αργότερα.",
          "BUTTON_ATTEST": "Επιβεβαιώστε",
          "BUTTON_LATER": "Έχω περισσότερα να κάνω",
          "ALERT_ERROR": "Δεν μπορέσαμε να αποθηκεύσουμε την επιβεβαίωσή σας τότε. Δοκιμάστε το στην καρτέλα Απασχόληση."
        }
      },
      "EMERGENCY_CONTACTS": {
        "ADD_MODAL": {
          "TITLE": "Νέα επαφή έκτακτης ανάγκης",
          "RELATIONSHIP_LABEL": "Σχέση με τον εργαζόμενο",
          "FIRST_NAME_LABEL": "Πρώτο όνομα",
          "LAST_NAME_LABEL": "Επώνυμο",
          "OTHER_NAME_LABEL": "Άλλο (-α) όνομα (-α)",
          "PRIMARY_CONTACT_NUMBER_LABEL": "Κύριος αριθμός επικοινωνίας",
          "SECONDARY_CONTACT_NUMBER_LABEL": "Δευτερεύων αριθμός επικοινωνίας",
          "ADDRESS_LABEL": "Διεύθυνση",
          "SAVE_BUTTON_TEXT": "Αποθήκευση",
          "CLOSE_BUTTON_TEXT": "Κλείσιμο",
          "ALERT_ERROR_500": "Δεν μπορέσαμε να αποθηκεύσουμε τις αλλαγές σας τότε. Παρακαλώ προσπαθήστε ξανά."
        },
        "INDEX": {
          "TITLE": "Επαφές έκτακτης ανάγκης",
          "RELATIONSHIP_COLUMN_TEXT": "Σχέση",
          "NAME_COLUMN_TEXT": "Όνομα",
          "PHONE_COLUMN_TEXT": "Τηλέφωνο (-ες)",
          "ADDRESS_COLUMN_TEXT": "Διεύθυνση",
          "NONE_TO_SHOW": "Δεν υπάρχουν επαφές έκτακτης ανάγκης για εμφάνιση.",
          "ADD_BUTTON_TEXT": "Νέα επαφή",
          "OPTIONS_COLUMN_TEXT": "Επιλογές",
          "DELETE_LINK": "Διαγραφή",
          "ALERT_ERROR_500": "Δεν μπορούσαμε να φορτώσουμε επαφές έκτακτης ανάγκης τότε. Παρακαλώ προσπαθήστε ξανά.",
          "CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν την επαφή έκτακτης ανάγκης;\nΑυτό δεν μπορεί να αναιρεθεί."
        }
      },
      "EMPLOYMENT": {
        "LABEL_LENGTH_OF_SERVICE": "Διάρκεια υπηρεσίας",
        "LABEL_STARTS_IN": "Ξεκινά στο",
        "LABEL_OPTIONS": "Επιλογές",
        "OPTION_NEW_EMPLOYMENT": "Νέα απασχόληση",
        "OPTION_END_EMPLOYMENT": "Τερματισμός απασχόλησης",
        "OPTION_REINSTATE_EMPLOYMENT": "Επαναφορά της απασχόλησης",
        "OPTION_DELETE_EMPLOYMENT": "Διαγραφή απασχόλησης",
        "TEXT_TERMINATED": "Αυτή η απασχόληση έχει τερματιστεί, λήγει στις",
        "LABEL_REASON": "Αιτιολογία",
        "LABEL_REHIRE": "Επανενοικίαση",
        "LABEL_REHIRE_YES": "Ναί",
        "LABEL_REHIRE_NO": "Οχι",
        "BUTTON_ATTEST": "Επιβεβαιώστε",
        "TEXT_ALLOWANCES_NOT_FINALISED": "Δεν έχετε επιβεβαιώσει ακόμη ότι τα επιδόματα απουσίας του εργαζομένου έχουν οριστικοποιηθεί.",
        "TEXT_ALLOWANCES_NOT_FINALISED_PART_2": "Αυτό γίνεται συνήθως την ή πριν από την τελευταία ημέρα του υπαλλήλου, πριν από την τελική πληρωμή του.",
        "TEXT_ALLOWANCES_NOT_FINALISED_LINK": "Προβολή επιδομάτων",
        "BUTTON_UNDO": "Αναίρεση",
        "TEXT_ALLOWANCES_FINALISED": "Τα επιδόματα απουσίας έχουν επιβεβαιωθεί ως οριστικοποιημένα για αυτήν την απασχόληση.",
        "LABEL_JOIN_DATE": "Ημερομηνία συμμετοχής",
        "LABEL_NOTICE_PERIOD": "Περίοδος ειδοποίησης",
        "BUTTON_SET_NOTICE_PERIOD": "Ορίστε μια περίοδο ειδοποίησης...",
        "LABEL_EMPLOYEE_NUMBER": "Αριθμός εργαζομένου",
        "TOOLTIP_EMPLOYEE_NUMBER": "Προαιρετικό. Στο Ηνωμένο Βασίλειο, το HMRC απαιτεί έναν μοναδικό αριθμό για κάθε υπάλληλο. Θα το στείλουμε στον πάροχο μισθοδοσίας σας εάν είναι ενεργοποιημένη η ενοποίηση μισθοδοσίας.",
        "LABEL_RECRUITMENT_SOURCE": "Πηγή προσλήψεων",
        "LABEL_STARTER_DECLARATION": "Δήλωση εκκίνησης (Ηνωμένο Βασίλειο)",
        "BUTTON_SAVE": "Αποθήκευση",
        "BUTTON_SAVE_LOADING": "Εξοικονόμηση...",
        "BUTTON_NEW_APPOINTMENT": "Νέο ραντεβού",
        "TITLE_APPOINTMENTS": "Ραντεβού",
        "COLUMN_EFFECTIVE_DATE": "Ημερομηνία έναρξης ισχύος",
        "COLUMN_SITE": "Ιστότοπος",
        "COLUMN_DEPARTMENT": "Τμήμα",
        "COLUMN_POSITION": "Ρόλος εργασίας",
        "COLUMN_LINE_MANAGER": "Διαχειριστής γραμμής",
        "COLUMN_STAFF_GROUP": "Ομάδα προσωπικού",
        "COLUMN_OBLIGATION": "Υποχρέωση",
        "TOOLTIP_ACTIVE_APPOINTMENT": "Αυτό είναι το ενεργό ραντεβού.",
        "LINE_MANAGER_NOT_SET": "Δεν έχει οριστεί",
        "MIN_OBLIGATION_ZERO_HOURS": "Μηδέν ώρες",
        "ERROR_500": "Δεν μπορούμε να φορτώσουμε θέσεις εργασίας αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
        "ERROR_403": "Δεν έχετε άδεια να προβάλετε/τροποποιήσετε τα αρχεία απασχόλησης αυτού του ατόμου.",
        "LABEL_HOURS": "ώρες",
        "LABEL_DAYS": "καιρός",
        "LABEL_WEEK": "εβδομάδας",
        "LABEL_MONTH": "μήνας",
        "LABEL_CURRENT_EMPLOYMENT": "Τρέχουσα απασχόληση",
        "ALERT_CHANGES_SAVED": "Οι αλλαγές σας έχουν αποθηκευτεί.",
        "CONFIRM_DELETE": "Είσαι σίγουρος ότι θέλεις να το κάνεις αυτό; Η διαγραφή μιας απασχόλησης είναι μη αναστρέψιμη.",
        "CONFIRM_REINSTATE": "Είσαι σίγουρος ότι θέλεις να το κάνεις αυτό;\n\nΕάν κάποιος επανενταχθεί στον οργανισμό σας, πρέπει να δημιουργήσετε μια νέα απασχόληση. Θα πρέπει να αποκαταστήσετε μια απασχόληση μόνο εάν τερματίστηκε λανθασμένα.",
        "VALIDATION_MODAL": {
          "TITLE": "Δεν μπορούμε να κάνουμε αυτή την αλλαγή",
          "DESCRIPTION": "Δυστυχώς, αυτό που προσπαθείτε να κάνετε προκαλεί ένα ή περισσότερα προβλήματα.",
          "NO_EMPLOYMENT_TITLE": "Καμία απασχόληση",
          "NO_EMPLOYMENT_DESCRIPTION": "Η αλλαγή σας θα άφηνε τον εργαζόμενο χωρίς απασχόληση. πρέπει πάντα να υπάρχει τουλάχιστον μία.",
          "EMPLOYMENT_OVERLAPPING_TITLE": "Επικαλυπτόμενες θέσεις εργασίας",
          "EMPLOYMENT_OVERLAPPING_DESCRIPTION": "Η αλλαγή σας θα προκαλούσε αλληλεπικάλυψη δύο θέσεων εργασίας:",
          "EMPLOYMENT_NOT_TERMINATED_TITLE": "Η απασχόληση δεν τερματίστηκε",
          "EMPLOYMENT_NOT_TERMINATED_DESCRIPTION": "Πρέπει να τερματίσετε την προηγούμενη απασχόληση πριν προσθέσετε μια νέα.",
          "EMPLOYMENT_NO_APPOINTMENTS_TITLE": "Απασχόληση χωρίς ραντεβού",
          "EMPLOYMENT_NO_APPOINTMENTS_DESCRIPTION": "Μια εργασία δεν μπορεί να είναι κενή, πρέπει να περιέχει τουλάχιστον ένα ραντεβού.",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_TITLE": "Διορισμός εκτός απασχόλησης",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Η αλλαγή σας θα είχε ως αποτέλεσμα μέρος αυτού του ραντεβού να πέσει εκτός της απασχόλησης.",
          "APPOINTMENT_OVERLAPPING_TITLE": "Επικαλυπτόμενα ραντεβού",
          "APPOINTMENT_OVERLAPPING_TITLE_DESCRIPTION": "Η αλλαγή σας θα προκαλούσε την επικάλυψη δύο ραντεβού:",
          "APPOINTMENT_NO_EMPLOYMENT_TITLE": "Ραντεβού χωρίς απασχόληση",
          "APPOINTMENT_NO_EMPLOYMENT_DESCRIPTION": "Ένα ραντεβού δεν σχετίζεται με μια απασχόληση. Αυτό δεν πρέπει κανονικά να συμβαίνει!",
          "SHIFTS_OUTSIDE_EMPLOYMENT_TITLE": "Μετατοπίσεις εκτός απασχόλησης",
          "SHIFTS_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Η αλλαγή σας θα αφήσει τις {{count}} βάρδιες σε ημερομηνίες που πέφτουν πριν από την έναρξη της απασχόλησης ή μετά τη λήξη της. Για να συνεχίσετε, αυτές οι βάρδιες μπορούν να διαγραφούν, να καταργηθούν (πράγμα που τις μετακινεί σε Ανοιχτές Βάρδιες) ή να τροποποιηθούν στην ίδια τη διαδρομή:",
          "SHIFT_ENTITY_MISMATCH_TITLE": "Βάρδιες σε διαφορετική τοποθεσία/τμήμα",
          "SHIFT_ENTITY_MISMATCH_DESCRIPTION": "Η αλλαγή σας θα αφήσει {{count}} βάρδιες που προέρχονται από το πρόγραμμα ενός τμήματος που δεν ταιριάζει με το τμήμα στο ραντεβού του υπαλλήλου. Για να συνεχίσετε, αυτές οι βάρδιες μπορούν να διαγραφούν, να καταργηθούν (πράγμα που τις μετακινεί σε Ανοιχτές Βάρδιες) ή να τροποποιηθούν στην ίδια τη διαδρομή:",
          "ABSENCE_OUTSIDE_EMPLOYMENT_TITLE": "Απουσία εκτός απασχόλησης",
          "ABSENCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Η αλλαγή σας θα αφήσει τις κρατήσεις απουσίας {{count}} σε ημερομηνίες που πέφτουν πριν από την έναρξη της απασχόλησης ή μετά τη λήξη της. Για να προχωρήσετε, πρέπει να ακυρωθούν:",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_TITLE": "Παρακολούθηση εκτός απασχόλησης",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Η αλλαγή σας θα αφήσει τις εκδηλώσεις συμμετοχής {{count}} σε ημερομηνίες που πέφτουν πριν από την έναρξη της απασχόλησης ή μετά τη λήξη της. Για να προχωρήσετε, πρέπει να διαγραφούν:",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_TITLE": "Χαρτογράφηση ρουτίνας βάρδιας εκτός απασχόλησης",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Η αλλαγή σας θα αφήσει τη χαρτογράφηση ρουτίνας βάρδιας {{count}} σε ημερομηνίες που πέφτουν πριν από την έναρξη της απασχόλησης ή μετά τη λήξη της. Για να προχωρήσετε, πρέπει να τερματιστούν ή να διαγραφούν:",
          "EMAIL_EXISTS_TITLE": "Η διεύθυνση ηλεκτρονικού ταχυδρομείου υπάρχει ήδη",
          "EMAIL_EXISTS_DESCRIPTION": "Η διεύθυνση ηλεκτρονικού ταχυδρομείου που εισαγάγατε χρησιμοποιείται ήδη από άλλο υπάλληλο. Εάν αυτό το άτομο έχει εργαστεί για τον οργανισμό σας στο παρελθόν, αναζητήστε το και προσθέστε μια Νέα Απασχόληση.",
          "MOBILE_EXISTS_TITLE": "Ο αριθμός κινητού τηλεφώνου υπάρχει ήδη",
          "MOBILE_EXISTS_DESCRIPTION": "Ο αριθμός κινητού τηλεφώνου που εισαγάγατε χρησιμοποιείται ήδη από άλλο υπάλληλο. Εάν αυτό το άτομο έχει εργαστεί για τον οργανισμό σας στο παρελθόν, αναζητήστε το και προσθέστε μια Νέα Απασχόληση.",
          "INVALID_DATE_TITLE": "Μη έγκυρη ημερομηνία",
          "INVALID_DATE_DESCRIPTION": "Εάν προσπαθείτε να τερματίσετε μια απασχόληση, η ημερομηνία λήξης που έχετε επιλέξει είναι πριν από την έναρξη της απασχόλησης.",
          "PERIOD_SIGNED_OFF_TITLE": "Ημερομηνία αποσύνδεσης",
          "PERIOD_SIGNED_OFF_DESCRIPTION": "Η αλλαγή σας επικαλύπτεται με μία ή περισσότερες ημερομηνίες που έχουν αποσυνδεθεί.",
          "PAY_ELEMENT_INVALID_TITLE": "Μη έγκυρο στοιχείο πληρωμής",
          "PAY_ELEMENT_INVALID_DESCRIPTION": "Το επιλεγμένο στοιχείο πληρωμής δεν είναι έγκυρο.",
          "PAY_RECORD_SAME_DATE_TITLE": "Διπλό αρχείο πληρωμής",
          "PAY_RECORD_SAME_DATE_DESCRIPTION": "Υπάρχει ήδη ένα αρχείο πληρωμής για το επιλεγμένο στοιχείο πληρωμής σας εκείνη την ημερομηνία.",
          "RECORD_MISSING_TITLE": "Το αρχείο πληρωμής διαγράφηκε",
          "RECORD_MISSING_DESCRIPTION": "Αυτό το αρχείο πληρωμής έχει διαγραφεί και δεν μπορεί πλέον να τροποποιηθεί.",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_TITLE": "Αναντιστοιχία ημερολογίου μισθοδοσίας",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_DESCRIPTION": "Η αλλαγή σας θα αφήσει τις {{count}} κρατήσεις απουσίας συνδεδεμένες με διαφορετικό ημερολόγιο μισθοδοσίας. Για να προχωρήσετε, πρέπει να ακυρωθούν:",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_TITLE": "Συνδέεται με το οριστικό επίδομα",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_DESCRIPTION": "Αυτό το αρχείο αμοιβών συνδέεται με ένα οριστικό επίδομα και δεν μπορεί να επεξεργαστεί. Για να το διαγράψετε, βρείτε το επίδομα και επαναφέρετε το.",
          "BUTTON_TRY_AGAIN": "Δοκιμάστε ξανά",
          "BUTTON_CLOSE": "Κλείσιμο"
        },
        "LEAVER_FORM": {
          "TITLE": "Τερματισμός απασχόλησης",
          "ALERT_SUCCESS": "Η απασχόληση έχει λήξει.",
          "ERROR_500": "Δεν μπορέσαμε να αποθηκεύσουμε τις αλλαγές σας τότε. Παρακαλώ προσπαθήστε ξανά.",
          "LABEL_END_DATE": "Τελευταία ημέρα απασχόλησης",
          "LABEL_REASON": "Λόγος αναχώρησης",
          "LABEL_REHIRE": "Επαναπροσλήρωση",
          "CHECKBOX_REHIRE": "Αυτός ο υπάλληλος θεωρείται επιλέξιμος για εκ νέου πρόσληψη στο μέλλον.",
          "LABEL_NOTES": "Σημειώσεις",
          "PLACEHOLDER_NOTES": "Προαιρετικά καταγράψτε ορισμένες σημειώσεις που σχετίζονται με την αναχώρηση αυτού του υπαλλήλου.",
          "BUTTON_SAVE": "Αποθήκευση",
          "BUTTON_SAVE_LOADING": "Εξοικονόμηση..."
        },
        "EMPLOYMENT_FORM": {
          "TITLE": "Νέα απασχόληση",
          "RESET_WARNING": "Ο λογαριασμός του υπαλλήλου θα επισημανθεί ως μη εγκεκριμένος και θα επαναφερθούν διάφορα χαρακτηριστικά, όπως οι μέσες ώρες εργασίας ανά ημέρα και ανά εβδομάδα.",
          "LABEL_START_DATE": "Ημερομηνία έναρξης απασχόλησης",
          "LABEL_NOTICE_PERIOD": "Περίοδος ειδοποίησης",
          "LABEL_EMPLOYEE_NUMBER": "Αριθμός εργαζομένου",
          "TOOLTIP_EMPLOYEE_NUMBER": "Προαιρετικό. Στο Ηνωμένο Βασίλειο, το HMRC απαιτεί έναν μοναδικό αριθμό για κάθε υπάλληλο. Θα το στείλουμε στον πάροχο μισθοδοσίας σας εάν είναι ενεργοποιημένη η ενοποίηση μισθοδοσίας.",
          "LABEL_RECRUITMENT_SOURCE": "Πηγή προσλήψεων",
          "LABEL_STARTER_DECLARATION": "Δήλωση εκκίνησης (Ηνωμένο Βασίλειο)",
          "LABEL_INVITE": "Προσκαλέστε τον/στο Rotaready",
          "CHECKBOX_INVITE": "Στείλτε έναν μαγικό σύνδεσμο σε αυτόν τον υπάλληλο, ο οποίος τους καλεί να ορίσουν τον κωδικό πρόσβασής τους και να συνδεθούν για να δουν τις βάρδιες τους.",
          "BUTTON_ADD": "Προσθήκη",
          "BUTTON_ADD_LOADING": "Προσθέτοντας...",
          "ALERT_SUCCESS": "Η απασχόληση έχει δημιουργηθεί.",
          "ERROR_500": "Δεν μπορέσαμε να αποθηκεύσουμε τις αλλαγές σας τότε. Παρακαλώ προσπαθήστε ξανά."
        },
        "APPOINTMENT_FORM": {
          "TITLE": "Ραντεβού",
          "ALERT_MAPPINGS": "Συμπληρώστε τις προεπιλεγμένες αντιστοιχίσεις ροής ή ελέγξτε τη δήλωση.",
          "ALERT_SAVED": "Το ραντεβού έχει αποθηκευτεί.",
          "ALERT_DELETED": "Το ραντεβού έχει διαγραφεί.",
          "ERROR_500": "Δεν μπορέσαμε να αποθηκεύσουμε τις αλλαγές σας τότε. Παρακαλώ προσπαθήστε ξανά.",
          "INFO_NEW_APPOINTMENT": "Χρησιμοποιήσαμε το πιο πρόσφατο ραντεβού για να συμπληρώσουμε εκ των προτέρων αυτήν τη φόρμα για εσάς.",
          "LABEL_EFFECTIVE_DATE": "Ημερομηνία έναρξης ισχύος",
          "HELP_TEXT_EFFECTIVE_DATE": "Καθώς αυτό είναι το πρώτο ραντεβού, η ημερομηνία έναρξης ισχύος συνδέεται με την ημερομηνία συμμετοχής στην απασχόληση.",
          "INFO_NEW_EMPLOYEE": "Για να ολοκληρώσετε, διαμορφώστε το νέο ραντεβού αυτού του υπαλλήλου ορίζοντας τον ιστότοπο/τμήμα, τον ρόλο εργασίας και άλλα βασικά χαρακτηριστικά.",
          "LABEL_LINE_MANAGER": "Διαχειριστής γραμμής",
          "LABEL_LINE_MANAGER_NOT_SET": "Δεν έχει οριστεί",
          "TITLE_JOB_ROLE": "Ρόλος εργασίας",
          "LABEL_SITE_DEPARTMENT": "Τοποθεσία/τμήμα",
          "LABEL_JOB_ROLE": "Ρόλος εργασίας",
          "LABEL_STAFF_GROUP": "Ομάδα προσωπικού",
          "HELP_TEXT_STAFF_GROUP": "Οι ομάδες προσωπικού χρησιμοποιούνται για τον οπτικό διαχωρισμό των ατόμων στη διαδρομή και σε άλλα εργαλεία.",
          "TITLE_EMPLOYMENT_CONTRACT": "Σύμβαση εργασίας",
          "LABEL_COMPANY": "ΕΤΑΙΡΕΙΑ",
          "LABEL_PAYROLL_CALENDAR": "Ημερολόγιο μισθοδοσίας",
          "LABEL_CONTRACT_TYPE": "Τύπος σύμβασης",
          "LABEL_PUBLIC_HOLIDAYS": "Δημόσιες αργίες",
          "HELP_TEXT_PUBLIC_HOLIDAYS": "Αυτός ο υπάλληλος εργάζεται συνήθως στις δημόσιες αργίες.",
          "LABEL_WORKING_TIME": "Κανονισμοί χρόνου εργασίας",
          "HELP_TEXT_WORKING_TIME": "Αυτός ο υπάλληλος έχει επιλέξει να εξαιρεθεί από τα προαιρετικά όρια εργασίας των κανονισμών για το χρόνο εργασίας.",
          "TITLE_CONTRACTUAL_OBLIGATION": "Συμβατική υποχρέωση",
          "LABEL_MIN_OBLIGATION": "Εγγυημένες ώρες/ημέρες",
          "HELP_TEXT_MIN_OBLIGATION": "Αυτός ο υπάλληλος εγγυάται ένα ελάχιστο ποσό εργασίας.",
          "LABEL_MAX_OBLIGATION": "Όριο εργασίας",
          "HELP_TEXT_MAX_OBLIGATION": "Επιβάλετε ένα ανώτατο όριο για το ποσό των ωρών ή των ημερών που μπορεί να προγραμματιστεί να εργαστεί αυτός ο υπάλληλος.",
          "TITLE_STREAM_MAPPINGS": "Προεπιλεγμένες αντιστοιχίσεις ροής",
          "HELP_TEXT_STREAM_MAPPINGS": "Όταν αυτός ο υπάλληλος εργάζεται σε βάρδια, οι αντιστοιχίσεις του τύπου βάρδιας καθορίζουν τη ροή (ές) για την κατανομή του μισθολογικού κόστους τους. Εάν ο τύπος αλλαγής ταχυτήτων δεν έχει κανένα, χρησιμοποιούνται αντί αυτού:",
          "COLUMN_STREAM": "Ροή",
          "COLUMN_ATTRIBUTION": "Αναφορά",
          "COLUMN_OPTIONS": "Επιλογές",
          "OPTION_DELETE": "Διαγραφή",
          "NO_MAPPINGS": "Δεν υπάρχει κανείς να δείξει.",
          "LABEL_ADD_MAPPING": "Προσθήκη νέας αντιστοίχισης",
          "OPTION_ADD": "Προσθήκη",
          "LABEL_NO_MAPPINGS": "Δεν υπάρχουν αντιστοιχίσεις",
          "CHECKBOX_NO_MAPPINGS": "Αποδέχομαι ότι χωρίς προεπιλεγμένες αντιστοιχίσεις ροής, ενδέχεται να υπάρχουν ανακρίβειες στον έλεγχο κόστους και σε ορισμένες αναφορές.",
          "HELP_TEXT_NO_MAPPINGS": "Αυτό είναι εντάξει αν δεν παρακολουθείτε το μισθολογικό κόστος αυτού του υπαλλήλου.",
          "BUTTON_SAVE": "Αποθήκευση",
          "BUTTON_SAVE_LOADING": "Εξοικονόμηση...",
          "BUTTON_DELETE": "Διαγραφή",
          "BUTTON_DELETE_LOADING": "Διαγράφω..."
        }
      },
      "DOCUMENTS": {
        "FILTER_LABEL_CATEGORY": "Κατηγορία",
        "BUTTON_UPLOAD": "Μεταφόρτωση αρχείου",
        "BUTTON_SIGNATURES": "Υπογραφές εγγράφων",
        "OPTION_SEND_TO_SIGN": "Αποστολή εγγράφου για υπογραφή",
        "OPTION_PENDING_SIGNATURES": "Προβολή εγγράφων που περιμένουν υπογραφή",
        "ERROR_500": "Δεν μπορούμε να φορτώσουμε έγγραφα αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
        "ERROR_403": "Δεν έχετε άδεια να προβάλετε/τροποποιήσετε τα έγγραφα αυτού του ατόμου.",
        "ERROR_NO_CATEGORIES": "Τα έγγραφα δεν είναι ενεργοποιημένα για τον οργανισμό σας. Συνομιλήστε μαζί μας για να ξεκινήσετε.",
        "TOOLTIP_CATEGORY_NOT_APPLICABLE": "Κατηγορία που επισημαίνεται ως μη υποχρεωτική",
        "TOOLTIP_CATEGORY_INVALID": "Το έγγραφο λείπει ή έχει λήξει",
        "INFO_CATEGORY_NOT_APPLICABLE": "Αυτή η κατηγορία έχει επισημανθεί ως μη απαιτούμενη για αυτόν τον υπάλληλο.",
        "LINK_MARK_CATEGORY_REQUIRED": "Σημειώστε όπως απαιτείται",
        "LINK_MARK_CATEGORY_NOT_REQUIRED": "Μαρκάρετε ως μη υποχρεωτικό",
        "INFO_CATEGORY_NO_DOCUMENTS": "Δεν υπάρχουν έγγραφα σε αυτήν την κατηγορία.",
        "COLUMN_NAME": "Όνομα",
        "COLUMN_EXPIRES": "Λήγει",
        "COLUMN_FILE_SIZE": "Μέγεθος αρχείου",
        "COLUMN_UPLOADED_BY": "Ανεβάστηκε από",
        "COLUMN_OPTIONS": "Επιλογές",
        "LINK_DELETE": "Διαγραφή",
        "LINK_EDIT": "Επεξεργασία",
        "ALERT_CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το έγγραφο; Αυτό δεν μπορεί να αναιρεθεί!",
        "TOOLTIP_SIGNATURE_ELECTRONIC": "Αυτό το έγγραφο υπογράφηκε ηλεκτρονικά μέσω του Rotaready",
        "TOOLTIP_SIGNATURE_MANUAL": "Αυτό το έγγραφο υπογράφηκε χειροκίνητα",
        "TOOLTIP_NOTES": "Οι σημειώσεις είναι διαθέσιμες",
        "PILL_SENSITIVE": "Ευαίσθητο",
        "PILL_CAN_EXPIRE": "Μπορεί να λήξει",
        "PILL_VISIBLE_TO_EMPLOYEE": "Ορατό στον εργαζόμενο",
        "TOOLTIP_ACKNOWLEDGED": "Αναγνωρισμένο: {{date}}",
        "PERSONAL": {
          "TITLE": "Τα έγγραφά μου",
          "ALERT_CONFIRM_ACKNOWLEDGE": "Κάνοντας κλικ στην επιλογή επιβεβαίωση επιβεβαιώνετε ότι έχετε διαβάσει και κατανοήσει το έγγραφο.",
          "INFO_NO_DOCUMENTS": "Δεν έχετε έγγραφα για προβολή.",
          "LINK_ACKNOWLEDGE": "Αναγνωρίστε",
          "PILL_NEEDS_ACKNOWLEDGEMENT": "Χρειάζεται αναγνώριση",
          "PILL_ACKNOWLEDGED": "Αναγνωρίζεται",
          "COLUMN_NAME": "Όνομα",
          "COLUMN_CATEGORY": "Κατηγορία",
          "COLUMN_EXPIRES": "Λήγει",
          "COLUMN_FILE_SIZE": "Μέγεθος αρχείου",
          "COLUMN_OPTIONS": "Επιλογές",
          "FILTER_LABEL_CATEGORY": "Κατηγορία"
        }
      },
      "DOCUMENT_EDIT_FORM": {
        "TITLE": "Επεξεργασία εγγράφου",
        "BUTTON_CANCEL": "Ακύρωση",
        "BUTTON_SAVE": "Αποθήκευση",
        "LABEL_EXPIRY_DATE": "Ημερομηνία λήξης",
        "LABEL_NOTES": "Σημειώσεις",
        "HELP_TEXT_EXPIRY": "Στο έγγραφο πρέπει να δοθεί ημερομηνία λήξης εάν η κατηγορία το απαιτεί. Θα ειδοποιηθείτε για να αναλάβετε δράση καθώς πλησιάζει η ημερομηνία και ξανά εάν λήξει.",
        "ALERT_SUCCESS": "Το έγγραφο έχει ενημερωθεί.",
        "ALERT_ERROR_500": "Παρουσιάστηκε σφάλμα κατά την ενημέρωση του εγγράφου. Δοκιμάστε ξανά ή επικοινωνήστε μαζί μας εάν αυτό το μήνυμα παραμένει.",
        "ALERT_ERROR_400": "Υπάρχει κάτι που δεν είναι σωστό στις αλλαγές σας."
      },
      "DOCUMENT_UPLOAD_MODAL": {
        "TITLE": "Μεταφόρτωση εγγράφου",
        "ALERT_SIGNATURE": "Θα επισημάνουμε αυτό το έγγραφο ως υπογεγραμμένο με μη αυτόματο τρόπο.",
        "LABEL_UPLOAD_TO": "Μεταφόρτωση σε",
        "LABEL_CATEGORY": "Κατηγορία",
        "LABEL_EXPIRY_DATE": "Ημερομηνία λήξης",
        "HELP_TEXT_EXPIRY": "Στο έγγραφο πρέπει να δοθεί ημερομηνία λήξης εάν η κατηγορία το απαιτεί. Θα ειδοποιηθείτε για να αναλάβετε δράση καθώς πλησιάζει η ημερομηνία και ξανά εάν λήξει.",
        "LABEL_UPLOAD": "Μεταφόρτωση",
        "LABEL_NOTES": "Σημειώσεις",
        "BUTTON_CANCEL": "Ακύρωση",
        "BUTTON_SAVE": "Αποθήκευση",
        "ALERT_SUCCESS": "Το έγγραφό σας έχει μεταφορτωθεί.",
        "ALERT_ERROR_500": "Παρουσιάστηκε σφάλμα κατά τη μεταφόρτωση του εγγράφου. Δοκιμάστε ξανά ή επικοινωνήστε μαζί μας εάν αυτό το μήνυμα παραμένει.",
        "ALERT_ERROR_400": "Υπάρχει κάτι που δεν είναι σωστό για την κατηγορία και την ημερομηνία λήξης."
      },
      "DOCUMENT_SIGNATURE_MODAL": {
        "ALERT_PICK_CATEGORY": "Επιλέξτε μια κατηγορία στην οποία θα αποθηκεύσετε το έγγραφο.",
        "ALERT_SUCCESS_MANUAL": "Το έγγραφό σας έχει μεταφορτωθεί.",
        "ALERT_SUCCESS_ELECTRONIC": "Το έγγραφό σας έχει σταλεί για ηλεκτρονική υπογραφή.",
        "ALERT_ERROR_500": "Παρουσιάστηκε σφάλμα κατά τη δημιουργία του εγγράφου. Δοκιμάστε ξανά ή επικοινωνήστε μαζί μας εάν αυτό το μήνυμα παραμένει.",
        "ALERT_ERROR_400": "Δεν μπορέσαμε να φτιάξουμε το έγγραφό σας. Ελέγξτε τις ετικέτες συγχώνευσης και δοκιμάστε ξανά.",
        "TITLE": "Αποστολή εγγράφου για υπογραφή",
        "ALERT_INFO": "Τοποθετώντας ετικέτες συγχώνευσης στο πρότυπο ή το αρχείο σας, το Rotaready μπορεί να εισαγάγει βασικές πληροφορίες σχετικά με τον υπάλληλο στον οποίο το στέλνετε, όπως το όνομά του ή τον ρόλο εργασίας του. Είναι επίσης ο τρόπος με τον οποίο ελέγχετε πού εμφανίζεται το πλαίσιο υπογραφής.",
        "ALERT_INFO_LINK": "Μάθετε πώς",
        "LABEL_SEND_TO": "Αποστολή σε",
        "LABEL_DOCUMENT": "Έγγραφο",
        "LABEL_DOCUMENT_TEMPLATE": "Χρήση υπάρχοντος προτύπου",
        "LABEL_DOCUMENT_UPLOAD": "Ανεβάστε ένα εφάπαξ αρχείο",
        "LABEL_TEMPLATE": "Πρότυπο",
        "ALERT_NO_TEMPLATES_TITLE": "Δεν έχετε πρότυπα.",
        "ALERT_NO_TEMPLATES_HELP": "Εξοικονομήστε χρόνο, ανεβάστε ένα αρχείο μία φορά και χρησιμοποιήστε το ξανά για πολλά άτομα.",
        "ALERT_NO_TEMPLATES_BUTTON": "Μεταφόρτωση προτύπου",
        "LABEL_UPLOAD": "Μεταφόρτωση",
        "SECTION_SIGNING_INFO": "Πληροφορίες υπογραφής",
        "LABEL_TITLE": "Τίτλος",
        "HELP_TEXT_TITLE": "Δώστε σε αυτό το έγγραφο έναν τίτλο, ώστε το άτομο στο οποίο το στέλνετε να γνωρίζει τι είναι.",
        "LABEL_EMPLOYER_SIGNS": "Σημάδια εργοδότη",
        "OPTION_EMPLOYER_SIGN_FALSE": "Όχι, μόνο ο υπάλληλος",
        "OPTION_EMPLOYER_SIGN_TRUE": "Απαιτείται υπογραφή εκ μέρους του εργοδότη",
        "LABEL_EMPLOYER_SIGNATORY": "Υπογράφων εργοδότης",
        "HELP_TEXT_EMPLOYER_SIGNATORY": "Επιλέξτε ποιος πρέπει να υπογράψει αυτό το έγγραφο εκ μέρους του εργοδότη.",
        "LABEL_SIGNATURE_METHOD": "Μέθοδος υπογραφής",
        "OPTION_SIGNATURE_METHOD_MANUAL": "Εγχειρίδιο, με στυλό και χαρτί",
        "OPTION_SIGNATURE_METHOD_ELECTRONIC": "Ηλεκτρονική, με πλήρως νόμιμη ηλεκτρονική υπογραφή",
        "LABEL_EMAIL_MESSAGE": "Μήνυμα ηλεκτρονικού ταχυδρομείου",
        "HELP_TEXT_EMAIL_MESSAGE": "Προσθέστε ένα προαιρετικό μήνυμα στην ειδοποίηση ηλεκτρονικού ταχυδρομείου που αποστέλλεται σε όλα τα υπογράφοντα μέρη.",
        "SECTION_UPON_COMPLETION": "Μετά την ολοκλήρωση",
        "SECTION_METHOD": "Μέθοδος υπογραφής",
        "LABEL_SAVE_TO": "Αποθήκευση σε",
        "HELP_TEXT_SAVE_TO": "Όταν υπογραφεί, το αρχείο θα αποθηκευτεί στα έγγραφα του υπαλλήλου στην επιλεγμένη κατηγορία.",
        "LABEL_EXPIRY_DATE": "Ημερομηνία λήξης",
        "HELP_TEXT_EXPIRY_DATE": "Στο έγγραφο πρέπει να δίνεται ημερομηνία λήξης εάν απαιτείται από την κατηγορία προορισμού. Θα ειδοποιηθείτε για να αναλάβετε δράση καθώς πλησιάζει η ημερομηνία και ξανά εάν λήξει.",
        "CREDITS_DEPLETED_TEXT": "Χρειάζεστε πιστώσεις για να στείλετε αυτό το έγγραφο για ηλεκτρονική υπογραφή.",
        "CREDITS_DEPLETED_CTA": "Συνομιλήστε μαζί μας τώρα",
        "CREDITS_AVAILABLE_TEXT": "Έχετε απο <b>μείνει {{υπόλοιπο}}</b> πίστωση (ες). Η αποστολή αυτού του εγγράφου θα χρησιμοποιήσει μία πίστωση.",
        "CREDITS_AVAILABLE_CTA": "Είναι εύκολο να συμπληρώσετε τις πιστώσεις σας. Απλώς στείλτε μας ένα μήνυμα στη ζωντανή συνομιλία.",
        "BUTTON_CANCEL": "Ακύρωση",
        "BUTTON_DOWNLOAD_PREVIEW": "Κατεβάστε την προεπισκόπηση",
        "BUTTON_SAVE_DOWNLOAD": "Αποθήκευση και λήψη",
        "BUTTON_SEND": "Αποστολή"
      },
      "NOTES": {
        "ERROR_500": "Δεν μπορούμε να φορτώσουμε σημειώσεις αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
        "ALERT_CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το σημείωμα;",
        "BODY_PLACEHOLDER": "Γράψτε μια νέα σημείωση...",
        "SAVE_BUTTON_TEXT": "Προσθήκη σημείωσης",
        "VISIBILITY_SELF": "Ιδιωτικό",
        "VISIBILITY_SELF_HELP": "Μόνο ορατό από εσάς",
        "VISIBILITY_SENSITIVE": "Ευαίσθητο",
        "VISIBILITY_SENSITIVE_HELP": "Ορατό μόνο από άτομα με άδεια να βλέπουν ευαίσθητες σημειώσεις",
        "VISIBILITY_GENERAL": "στρατηγός",
        "VISIBILITY_GENERAL_HELP": "Ορατό μόνο από άτομα με άδεια να βλέπουν τις σημειώσεις αυτού του υπαλλήλου",
        "EDITED_LABEL": "Επεξεργασμένο",
        "EDIT_LABEL": "Επεξεργασία",
        "DELETE_LABEL": "Διαγραφή",
        "NO_NOTES": "Δεν υπάρχουν σημειώσεις για εμφάνιση."
      },
      "DANGER_ZONE": {
        "ANONYMISE": {
          "TITLE": "Ανωνυμοποίηση υπαλλήλου",
          "ERROR_500": "Δεν μπορούσαμε να σώσουμε τότε. Παρακαλώ προσπαθήστε ξανά.",
          "ERROR_403": "Δεν έχετε άδεια πρόσβασης στα προσωπικά στοιχεία αυτού του υπαλλήλου. Αυτό σημαίνει ότι δεν μπορείτε να προγραμματίσετε το προφίλ τους να είναι ανώνυμο.",
          "LABEL_END_DATE": "Ημερομηνία λήξης απασχόλησης",
          "HELP_TEXT": "Για να βοηθήσει στη συμμόρφωση με τους νόμους περί προστασίας δεδομένων, η Rotaready μπορεί να αφαιρέσει όλες τις προσωπικές πληροφορίες από αυτό το προφίλ υπαλλήλου μετά από μια επιλεγμένη χρονική περίοδο. Στο Ηνωμένο Βασίλειο, το HMRC απαιτεί την τήρηση αρχείων για τουλάχιστον έξι χρόνια.",
          "HELP_TEXT_LEARN": "Μάθετε περισσότερα",
          "LABEL_ANONYMISE": "Ανωνυμοποίηση",
          "CHECKBOX_ANONYMISE": "Ανωνυμοποιήστε αυτόν τον υπάλληλο",
          "LABEL_ANONYMISE_DATE": "Ανωνυμοποίηση",
          "ALERT_ANONYMISE_DATE": "Για να ανωνυμοποιήσετε αυτόν τον υπάλληλο, επιλέξτε μια ημερομηνία που είναι τόσο στο μέλλον όσο και μετά την τελευταία ημέρα εργασίας του υπαλλήλου.",
          "BUTTON_SAVE": "Αποθήκευση"
        }
      }
    },
    "ABSENCE": {
      "ABSENCE": {
        "TITLE": "Ημερολόγιο απουσίας",
        "FILTER_LABEL_APPROVAL_STATE": "Κατάσταση",
        "FILTER_LABEL_POSITION": "Ρόλος εργασίας",
        "FILTER_LABEL_STAFF_GROUP": "Ομάδα προσωπικού",
        "ERROR_500": "Δεν μπορούσαμε να φορτώσουμε την απουσία τότε. Παρακαλώ προσπαθήστε ξανά.",
        "FILTER_LABEL_ABSENCE_TYPE": "Τύπος",
        "FILTER_LABEL_REQUESTER": "Αιτήτρια"
      }
    },
    "REQUESTS": {
      "ABSENCE": {
        "DATES_COLUMN_TEXT": "Ημερομηνία (ες)",
        "WHO_COLUMN_TEXT": "Ποιος",
        "ABSENCE_TYPE_COLUMN_TEXT": "Τύπος",
        "SUBMITTED_DATE_TEXT": "Υποβλήθηκε",
        "HOURS_COLUMN_TEXT": "Ωρες",
        "DAYS_COLUMN_TEXT": "Ημέρες",
        "NO_STAFF_MATCH_TEXT": "Δεν μπορέσαμε να βρούμε αιτήματα που να ταιριάζουν με τα φίλτρα σας.",
        "FILTER_LABEL_POSITION": "Ρόλος εργασίας",
        "FILTER_LABEL_STAFF_GROUP": "Ομάδα προσωπικού",
        "ERROR_500": "Δεν μπορούσαμε να φορτώσουμε την απουσία τότε. Παρακαλώ προσπαθήστε ξανά.",
        "FILTER_LABEL_ABSENCE_TYPE": "Τύπος",
        "FILTER_LABEL_REQUESTER": "Αιτήτρια"
      },
      "CONFIRM_SWAP_APPROVAL_MODAL": {
        "TITLE": "Είσαι σίγουρη;",
        "VALIDATION_MANUAL_TEXT": "Για να αναιρέσετε αυτήν την αλλαγή βάρδιας, θα πρέπει να επεξεργαστείτε χειροκίνητα τη διαδρομή.",
        "YES_TITLE": "Ναί",
        "APPROVE_BUTTON_TEXT": "Εγκρίνετε αυτήν την ανταλλαγή τώρα και ενημερώστε και τα δύο μέλη του προσωπικού",
        "NO_TITLE": "Οχι",
        "DO_NOTHING_BUTTON_TEXT": "Μην κάνετε τίποτα - μην εγκρίνετε αυτήν την ανταλλαγή ακόμα",
        "CANCEL_BUTTON_TEXT": "Ακύρωση"
      },
      "INDEX": {
        "HEADING": "Στοιχεία που απαιτούν προσοχή",
        "ABSENCE_LABEL": "Αιτήματα απουσίας",
        "SWAPS_LABEL": "Ανταλλαγές βάρδιας",
        "DOCUMENTS_EXPIRING_LABEL": "Έγγραφα που λήγουν",
        "ACCOUNT_APPROVAL_LABEL": "Νέοι συνεργάτες"
      },
      "SWAPS": {
        "INITIATED_BY_TEXT": "Ξεκίνησε από",
        "INITIATED_TOOLTIP": "Το μέλος του προσωπικού που θέλει να δώσει τη βάρδια του",
        "SHIFT_COLUMN_TEXT": "Η βάρδια τους",
        "SWAPPING_WITH_COLUMN_TEXT": "Ανταλλαγή με",
        "SWAPPING_TOOLTIP": "Το μέλος του προσωπικού που θέλει να εργαστεί τη βάρδια",
        "RETURN_SHIFT_COLUMN_TEXT": "Η βάρδια τους",
        "OPTION_COLUMN_TEXT": "Επιλογές",
        "ACCEPTED_DATE_COLUMN_TEXT": "Περιμένοντας από",
        "NONE_TEXT": "Κανένα",
        "NO_STAFF_MATCH_TEXT": "Δεν μπορέσαμε να βρούμε αιτήματα που να ταιριάζουν με τα φίλτρα σας.",
        "FILTER_LABEL_PARTICIPANT": "Συμμετέχων"
      },
      "DOCUMENTS": {
        "NAME_COLUMN_LABEL": "Όνομα",
        "FILE_NAME_COLUMN_LABEL": "Έγγραφο",
        "FILE_SIZE_COLUMN_LABEL": "Μέγεθος",
        "CATEGORY_COLUMN_LABEL": "Κατηγορία",
        "EXPIRY_DATE_COLUMN_LABEL": "Λήγει",
        "OPTIONS_COLUMN_LABEL": "Επιλογές",
        "EXPIRED": "Έληξε",
        "UPLOAD_NEW_LINK": "Μεταφόρτωση νέου",
        "NO_DOCS_MATCH_TEXT": "Δεν μπορέσαμε να βρούμε έγγραφα που να ταιριάζουν με τα φίλτρα σας.",
        "NO_CATEGORIES_TEXT": "Δεν έχετε κατηγορίες για έγγραφα που μπορούν να λήξουν.",
        "FILTER_LABEL_SITE": "Τοποθεσία/τμήμα",
        "FILTER_LABEL_APPOINTMENT": "Ραντεβού",
        "FILTER_LABEL_PERSON": "Πρόσωπο",
        "FILTER_LABEL_CATEGORY": "Κατηγορία",
        "FILTER_LABEL_EXPIRY": "Λήξη",
        "APPOINTMENT_STATUS_ACTIVE": "Ενεργός",
        "APPOINTMENT_STATUS_INACTIVE": "Ανενεργό",
        "X_WEEKS_OR_LESS": " εβδομάδες ή λιγότερο"
      },
      "NEW_JOINERS": {
        "NAME_COLUMN_LABEL": "Όνομα",
        "POSITION_COLUMN_LABEL": "Ρόλος εργασίας",
        "ENTITY_COLUMN_LABEL": "Ιστότοπος",
        "START_DATE_COLUMN_LABEL": "Ξεκινά στις",
        "OPTIONS_COLUMN_LABEL": "Επιλογές",
        "NO_STAFF_MATCH_TEXT": "Δεν μπορέσαμε να βρούμε κανέναν που να ταιριάζει με τα φίλτρα σας.",
        "APPROVE_LINK": "Έγκριση",
        "FILTER_LABEL_APPOINTMENT": "Ραντεβού",
        "FILTER_LABEL_POSITION": "Ρόλος εργασίας",
        "FILTER_LABEL_DATE_START": "Ημερομηνία έναρξης",
        "APPOINTMENT_STATUS_ACTIVE": "Ενεργός",
        "APPOINTMENT_STATUS_INACTIVE": "Ανενεργό"
      }
    },
    "TAGS_GROUPS": {
      "TAGS_GROUPS": {
        "HEADING": "Ετικέτες & ομάδες",
        "ASIDE_TEXT": "Ένα μέλος του προσωπικού μπορεί να ανήκει μόνο σε μία ομάδα, αλλά μπορούν να του εκχωρηθούν πολλές ετικέτες. Οι ομάδες μοιάζουν με το τμήμα ή την ομάδα κάποιου, ενώ οι ετικέτες μπορούν να αντιπροσωπεύουν οτιδήποτε θεωρείτε σημαντικό, όπως δεξιότητες ή προσωπικές ιδιότητες.",
        "CREATE_NEW_TAG_PLACEHOLDER": "Δημιουργία νέας ετικέτας...",
        "ADDING_BUTTON_TEXT": "Προσθέτοντας...",
        "ADD_BUTTON_TEXT": "Προσθήκη",
        "TAG_HELP": "Κάντε κλικ σε μια ετικέτα για να την επεξεργαστείτε και για να δείτε ποιος έχει εκχωρηθεί:",
        "CREATE_ONE_TEXT": "Προς το παρόν δεν έχετε ετικέτες. Γιατί να μην δημιουργήσετε ένα;",
        "CREATE_NEW_GROUP_PLACEHOLDER": "Δημιουργία νέας ομάδας...",
        "GROUPS_TITLE": "Ομάδες",
        "EDIT_LINK_TEXT": "επεξεργασία"
      },
      "VIEW_GROUP_MODAL": {
        "EDIT_GROUP_TITLE": "Επεξεργασία ομάδας",
        "SITE_LABEL": "Ιστότοπος",
        "EDIT_TAB_HEADING": "Επεξεργασία",
        "ORG_WIDE_LABEL": "Σε ολόκληρο τον οργανισμό",
        "GROUP_NAME_LABEL": "Όνομα ομάδας",
        "AVAILABILITY_LABEL": "Διαθεσιμότητα",
        "ALWAYS_AVAILABLE_TEXT": " Πάντα διαθέσιμο",
        "ALWAYS_AVAILABLE_HELP": "Το προσωπικό αυτής της ομάδας θεωρείται πάντα διαθέσιμο για εργασία, εκτός εάν τα πρότυπα διαθεσιμότητας ή η απουσία τους υπαγορεύουν διαφορετικά.",
        "ALWAYS_UNAVAILABLE_TEXT": " Πάντα μη διαθέσιμο",
        "ALWAYS_UNAVAILABLE_HELP": "Το προσωπικό αυτής της ομάδας δεν είναι από προεπιλογή διαθέσιμο. Θα χρησιμοποιήσουν «εξαιρέσεις» και «διαθεσιμότητα μίας εβδομάδας» για να επισημάνουν πότε είναι δωρεάν.",
        "MEMBERS_TAB_HEADING": "Μέλη",
        "NO_ACTIVE_MEMBERS_HELP": "Δεν υπάρχουν ενεργά μέλη σε αυτήν την ομάδα. <br /><br />Το προσωπικό ανατίθεται σε μια ομάδα μέσω του ραντεβού τους. Βρείτε κάποιον στη λίστα προσωπικού, κάντε κλικ στην καρτέλα Ραντεβού και επεξεργαστείτε μια υπάρχουσα συνάντηση ή προσθέστε μια νέα.",
        "DELETE_TAB_HEADING": "Διαγραφή",
        "MOVE_MEMBERS_TO_LABEL": "Μετακίνηση μελών σε",
        "DELETE_TAB_ALERT": "Η διαγραφή αυτής της ομάδας θα προκαλέσει τη μετακίνηση ατόμων {{totalMembers}} σε διαφορετική ομάδα. Αυτό είναι μη αναστρέψιμο και δεν μπορεί να αναιρεθεί.",
        "CONFIRM_NAME_LABEL": "Όνομα ομάδας τύπου",
        "CONFIRM_NAME_HELP_TEXT": "Για να μας πείσετε ότι θέλετε να το κάνετε αυτό, πληκτρολογήστε το όνομα της ομάδας στο πλαίσιο.",
        "DELETING_BUTTON_TEXT": "Διαγράφω...",
        "DELETE_BUTTON_TEXT": "Διαγραφή",
        "SAVING_BUTTON_TEXT": "Εξοικονόμηση...",
        "SAVE_BUTTON_TEXT": "Αποθήκευση",
        "CANCEL_BUTTON_TEXT": "Ακύρωση"
      },
      "VIEW_TAG_MODAL": {
        "EDIT_TAG_TITLE": "Επεξεργασία ετικέτας",
        "EDIT_CLONE_LABEL": "Επεξεργασία/κλωνοποίηση",
        "EDIT_EXISTING_TAG_TEXT": " Επεξεργασία υπάρχουσας ετικέτας",
        "CLONE_TAG_TEXT": " Κλωνοποίηση ετικέτας και αποθήκευση ως νέα",
        "TAG_NAME_LABEL": "Όνομα ετικέτας",
        "STAFF_MEMBERS_LABEL": "Μέλη του προσωπικού",
        "TAG_TYPEAHEAD_PLACEHLDER": "Πληκτρολογήστε για να προσθέσετε ετικέτα προσωπικού",
        "LAST_EDITED_LABEL": "Τελευταία επεξεργασία",
        "ON_TEXT": "στις {{ημερομηνία|Μορφή στιγμιότας:'}}",
        "DELETING_BUTTON_TEXT": "Διαγράφω...",
        "DELETE_BUTTON_TEXT": "Διαγραφή",
        "SAVING_BUTTON_TEXT": "Εξοικονόμηση...",
        "SAVE_BUTTON_TEXT": "Αποθήκευση",
        "CLONING_BUTTON_TEXT": "Κλωνοποίηση...",
        "CLONE_BUTTON_TEXT": "Κλώνος",
        "CANCEL_BUTTON_TEXT": "Ακύρωση",
        "CLOSE_BUTTON_TEXT": "Κλείσιμο"
      }
    },
    "ADD_NEW": {
      "TITLE": "Προσθήκη υπαλλήλου",
      "LABEL_FIRST_NAME": "Πρώτο όνομα",
      "LABEL_LAST_NAME": "Επώνυμο",
      "LABEL_DOB": "Ημερομηνία γέννησης",
      "LABEL_EMAIL": "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
      "HELP_TEXT_EMAIL_OIDC": "Αυτό πρέπει να ταιριάζει ακριβώς με αυτό που χρησιμοποιεί ο υπάλληλος για την υπηρεσία ενιαίας σύνδεσης. Συνήθως τελειώνει με το όνομα τομέα της εταιρείας σας.",
      "HELP_TEXT_EMAIL_ROTAREADY": "Όλοι έχουν λογαριασμό στο Rotaready και χρησιμοποιούν τη διεύθυνση email τους για να συνδεθούν.",
      "LABEL_COPY_EXISTING": "Αντιγράψτε έναν υπάρχοντα υπάλληλο",
      "PLACEHOLDER_COPY_EXISTING_NO_THANKS": "Όχι ευχαριστώ",
      "HELP_TEXT_COPY_EXISTING": "Αντιγράψτε το ραντεβού, το επίδομα διακοπών και τις ετικέτες από άλλο υπάλληλο.",
      "LABEL_DATE_START": "Ημερομηνία έναρξης απασχόλησης",
      "LABEL_SITE": "Τοποθεσία/τμήμα",
      "LABEL_PERMISSIONS": "Επίπεδο άδειας",
      "LABEL_INVITE": "Προσκαλέστε τον/στο Rotaready",
      "HELP_TEXT_INVITE_OIDC": "Στείλτε έναν μαγικό σύνδεσμο σε αυτόν τον υπάλληλο, ο οποίος τους προσκαλεί να συνδεθούν για πρώτη φορά.",
      "HELP_TEXT_INVITE_ROTAREADY": "Στείλτε έναν μαγικό σύνδεσμο σε αυτόν τον υπάλληλο, ο οποίος τους καλεί να ορίσουν τον κωδικό πρόσβασής τους και να συνδεθούν για να δουν τις βάρδιες τους.",
      "LABEL_ONBOARDING": "Επιβίβαση",
      "CHECKBOX_ONBOARDING_OPT_IN": "Ζητήστε από αυτόν τον υπάλληλο να ολοκληρώσει την αυτοεπιβίβαση κατά την πρώτη είσοδο στο Rotaready. Ανάλογα με τις ρυθμίσεις του οργανισμού σας, θα τους ζητηθεί να παράσχουν προσωπικές πληροφορίες, όπως τη διεύθυνσή τους, τις επαφές έκτακτης ανάγκης και την τεκμηρίωση για το δικαίωμα στην εργασία.",
      "BUTTON_ADD": "Προσθήκη",
      "BUTTON_ADD_LOADING": "Προσθέτοντας...",
      "ALERT_SUCCESS": "Επιτυχία! Ολοκληρώστε τη ρύθμιση του υπαλλήλου σας συμπληρώνοντας κάθε καρτέλα.",
      "ALERT_500": "Δεν μπορούσαμε να προσθέσουμε τον υπάλληλο τότε. Παρακαλώ προσπαθήστε ξανά.",
      "ALERT_403": "Δεν έχετε άδεια να προσθέσετε έναν νέο υπάλληλο.",
      "ALERT_403_PERMISSION_LEVEL": "Δεν έχετε δικαίωμα να προσθέσετε κάποιον σε αυτό το επίπεδο δικαιωμάτων.",
      "ALERT_403_COPY_EXISTING": "Δεν έχετε άδεια να αντιγράψετε αυτόν τον υπάλληλο.",
      "ALERT_403_ENTITY": "Δεν έχετε άδεια να προσθέσετε κάποιον σε αυτόν τον ιστότοπο/τμήμα.",
      "DUPLICATE_MODAL": {
        "TITLE": "Υφιστάμενοι εργαζόμενοι",
        "DESCRIPTION": "Το άτομο που προσπαθείτε να προσθέσετε μοιράζεται ορισμένα χαρακτηριστικά με έναν ή περισσότερους υπάρχοντες υπαλλήλους. Εάν αυτό το άτομο έχει εργαστεί για τον οργανισμό σας στο παρελθόν, προσθέστε μια νέα απασχόληση.",
        "COLUMN_NAME": "Όνομα",
        "COLUMN_EMPLOYMENT": "Απασχόληση",
        "COLUMN_SITE": "Τοποθεσία/τμήμα",
        "COLUMN_SIMILARITIES": "Ομοιότητες",
        "COLUMN_OPTIONS": "Επιλογές",
        "STATUS_ACTIVE": "Ενεργός",
        "STATUS_INACTIVE": "Ανενεργό",
        "OPTION_VIEW": "Προβολή υπαλλήλου",
        "OPTION_NEW_EMPLOYMENT": "Νέα απασχόληση",
        "BUTTON_PROCEED": "Συνεχίστε ούτως ή άλλ",
        "BUTTON_BACK": "Πήγαινε πίσω",
        "SIMILARITY_NAME": "Όνομα",
        "SIMILARITY_EMAIL": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
        "SIMILARITY_MOBILE": "Αριθμός κινητού τηλεφώνου",
        "SIMILARITY_DOB": "Ημερομηνία γέννησης"
      }
    }
  },
  "DIRECTIVES": {
    "STAFF": {
      "SEARCH": {
        "PLACEHOLDER": "Αναζήτηση...",
        "AND_X_MORE": "Και {{x}} περισσότερα...",
        "INACTIVE_APPOINTMENT": "Ανενεργό"
      },
      "ADD_ROUTINE_MAPPING_FORM": {
        "STARTS_LABEL": "Ξεκινά",
        "STARTS_TEXT": "Η πρώτη μέρα της {{firstName}} σε αυτή τη ρουτίνα",
        "ENDS_INPUT_TEXT": " Τελειώνει",
        "ENDS_TEXT": "Η τελευταία μέρα του {{firstName}} σε αυτή τη ρουτίνα",
        "ONGOING_TEXT": "Σε εξέλιξη",
        "SHIFT_ROUTINE_LABEL": "Ρουτίνα βάρδιας",
        "OFFSET_LABEL": "Μετατόπιση",
        "OFFSET_HELP": "Αυτός είναι ο αριθμός των ημερών που πρέπει να ξεκινήσει το {{firstName}} σε αυτό το μοτίβο."
      },
      "AVAILABILITY_RULE": {
        "IM_UNAVAILABLE_TEXT": "Δεν θα είμαι διαθέσιμος",
        "CANCEL_TEXT": "ακύρωση",
        "AVAILABLE_TEXT": "ΔΙΑΘΈΣΙΜΟ",
        "UNAVAILABLE_TEXT": "ΜΗ ΔΙΑΘΈΣΙΜΟ",
        "AVAILABLE_FROM_TEXT": "Είμαι διαθέσιμος μόνο από ",
        "UNAVAILABLE_FROM_TEXT": "Δεν είμαι διαθέσιμος από ",
        "TO_TEXT": " να ",
        "ALL_DAY": "Όλη μέρα",
        "AM_ONLY": "Μόνο AM",
        "PM_ONLY": "Μόνο PM",
        "AVAIALABLE_CUSTOM": "Διαθέσιμο (Προσαρμοσμένο)",
        "UNAVAILABLE_CUSTOM": "Μη διαθέσιμο (Προσαρμοσμένο)",
        "UNAVAILABLE": "Μη διαθέσιμο",
        "AVAILABLE": "Διαθέσιμο",
        "AVAILABLE_AM": "Διαθέσιμο (μόνο AM)",
        "AVAILABLE_PM": "Διαθέσιμο (μόνο PM)"
      },
      "AUDIT_USER_WIDGET": {
        "EDITED_BY": "Επεξεργασία από"
      },
      "VALIDATION_BULK_ABSENCE_EDIT": {
        "INFO_TRUNCATED": "Λόγω του όγκου των κρατήσεων απουσίας που επηρεάζονται, δεν είναι δυνατή η μαζική επεξεργασία τους εδώ. Χρησιμοποιήστε την καρτέλα Απουσία στο προφίλ του υπαλλήλου ή επικοινωνήστε με το Rotaready για περαιτέρω βοήθεια.",
        "INFO_SUCCESS": "Δεν υφίστανται αντιφατικές κρατήσεις απουσίας.",
        "ERROR_500": "Μόλις τότε σημειώσαμε ένα απροσδόκητο σφάλμα. Παρακαλώ προσπαθήστε ξανά.",
        "ERROR_400": "Αυτή η αλλαγή δεν μπορεί να γίνει επειδή επικαλύπτεται με μια ημερομηνία που έχει αποσυνδεθεί.",
        "COLUMN_DATES": "Ημερομηνία (ες)",
        "COLUMN_TYPE": "Τύπος",
        "COLUMN_HOURS": "Ωρες",
        "COLUMN_DAYS": "Ημέρες",
        "COLUMN_STATUS": "Κατάσταση",
        "COLUMN_OPTIONS": "Επιλογές",
        "OPTION_CANCEL": "Ακύρωση"
      },
      "VALIDATION_BULK_ATTENDANCE_EDIT": {
        "INFO_TRUNCATED": "Λόγω του όγκου των εκδηλώσεων συμμετοχής που επηρεάζονται, δεν είναι δυνατή η μαζική επεξεργασία τους εδώ. Χρησιμοποιήστε την καρτέλα Παρακολούθηση στο προφίλ του υπαλλήλου ή επικοινωνήστε με το Rotaready για περαιτέρω βοήθεια.",
        "INFO_SUCCESS": "Δεν υπάρχουν αντικρουόμενες εκδηλώσεις συμμετοχής.",
        "ERROR_500": "Μόλις τότε σημειώσαμε ένα απροσδόκητο σφάλμα. Παρακαλώ προσπαθήστε ξανά.",
        "ERROR_400": "Αυτή η αλλαγή δεν μπορεί να γίνει επειδή συμπίπτει με μια ημερομηνία που έχει αποσυνδεθεί.",
        "COLUMN_EVENT": "Εκδήλωση",
        "COLUMN_DATE": "Ημερομηνία",
        "COLUMN_OPTIONS": "Επιλογές",
        "EVENT_TYPE_CLOCK_IN": "Ρολόι",
        "OPTION_DELETE": "Διαγραφή"
      },
      "VALIDATION_BULK_ROUTINE_MAPPING_EDIT": {
        "INFO_TRUNCATED": "Λόγω του όγκου των αντιστοιχίσεων ρουτίνας βάρδιας που επηρεάζονται, δεν είναι δυνατή η μαζική επεξεργασία τους εδώ. Χρησιμοποιήστε την καρτέλα Shift Routines στο προφίλ του υπαλλήλου ή επικοινωνήστε με το Rotaready για περαιτέρω βοήθεια.",
        "INFO_SUCCESS": "Δεν υπάρχουν αντικρουόμενες αντιστοιχίσεις ρουτίνας βάρδιας.",
        "ERROR_500": "Μόλις τότε σημειώσαμε ένα απροσδόκητο σφάλμα. Παρακαλώ προσπαθήστε ξανά.",
        "ERROR_400": "Αυτή η αλλαγή δεν μπορεί να γίνει επειδή επικαλύπτεται με μια ημερομηνία που έχει αποσυνδεθεί.",
        "COLUMN_START_DATE": "Ημερομηνία έναρξης",
        "COLUMN_END_DATE": "Ημερομηνία λήξης",
        "COLUMN_SHIFT_ROUTINE": "Ρουτίνα βάρδιας",
        "COLUMN_OPTIONS": "Επιλογές",
        "LABEL_OPEN_ENDED": "Ανοιχτό τέλος",
        "OPTION_ADD_END_DATE": "Προσθήκη ημερομηνίας λήξης",
        "OPTION_DELETE": "Διαγραφή"
      },
      "VALIDATION_BULK_SHIFT_EDIT": {
        "INFO_TRUNCATED": "Λόγω του όγκου των βάρδιων που επηρεάζονται, δεν είναι δυνατή η μαζική επεξεργασία τους εδώ. Τροποποιήστε τις αντίστοιχες διαδρομές ή επικοινωνήστε με το Rotaready για περαιτέρω βοήθεια.",
        "INFO_SUCCESS": "Δεν υπάρχουν αντικρουόμενες βάρδιες που απομένουν.",
        "ERROR_500": "Μόλις τότε σημειώσαμε ένα απροσδόκητο σφάλμα. Παρακαλώ προσπαθήστε ξανά.",
        "ERROR_400": "Αυτή η αλλαγή δεν μπορεί να γίνει. Μπορεί να επικαλύπτεται με μια ημερομηνία που έχει αποσυνδεθεί. Για να μάθετε περισσότερα, δοκιμάστε το στο ίδιο το rota.",
        "BUTTON_UNASSIGN_SELECTED": "Κατάργηση εκχώρησης επιλεγμένων",
        "BUTTON_DELETE_SELECTED": "Διαγραφή επιλεγμένων",
        "COLUMN_DATE": "Ημερομηνία",
        "COLUMN_TIME": "Χρόνος",
        "COLUMN_DEPARTMENT": "Τμήμα",
        "COLUMN_TYPE": "Τύπος",
        "COLUMN_STATUS": "Κατάσταση",
        "COLUMN_OPTIONS": "Επιλογές",
        "STATUS_PUBLISHED": "Δημοσιεύθηκε",
        "STATUS_DRAFT": "Σχέδιο",
        "OPTION_UNASSIGN": "Κατάργηση εκχώρησης",
        "OPTION_DELETE": "Διαγραφή"
      },
      "VALIDATION_EPHEMERAL_RECORDS": {
        "LABEL_OPEN_ENDED": "(ανοικτού τύπου)",
        "LABEL_NEW": "Καινούργιο",
        "LABEL_EXISTING": "Υφιστάμενος",
        "EMPLOYMENT": "Απασχόληση",
        "APPOINTMENT": "Ραντεβού"
      },
      "ACCOUNT_COMPLETION_TRACKER": {
        "CONSTITUENT_PERSONAL": "Προσωπικά",
        "CONSTITUENT_EMPLOYMENT": "Απασχόληση",
        "CONSTITUENT_PAY": "Πληρωμή",
        "CONSTITUENT_HOLIDAY_ALLOWANCE": "Επίδομα διακοπών"
      }
    },
    "CALENDAR": {
      "TIP": "Για να ζητήσετε απουσία απλώς κάντε κλικ σε μια ημέρα, ή κάντε κλικ και σύρετε για να επιλέξετε πολλές ημέρες ταυτόχρονα.",
      "TODAY": "ΣΗΜΕΡΑ",
      "MONTH": "Μήνας",
      "WEEK": "Εβδομάδα",
      "DAY": "Ημέρα"
    },
    "HIERARCHY_ITEM_PICKER": {
      "PICKER": "Συλλέκτης",
      "PLACEHOLDER": "Δεν έχει επιλεγεί {{itemDescriptor}}",
      "FILTER_TO": "Φιλτράρετε σε...",
      "RESET": "Επαναφορά",
      "REMOVED_BY_FILTER": "Το επιλεγμένο {{itemDescriptor}} έχει αφαιρεθεί από το φίλτρο.",
      "NO_SELECTED": "Δεν έχει επιλεγεί {{itemDescriptor}}"
    },
    "SUBSCRIBE_BUTTON": {
      "LABEL_ETID_1": "ζητείται",
      "LABEL_ETID_2": "ακυρωθεί",
      "LABEL_ETID_3": "εγκεκριμένο",
      "LABEL_ETID_4": "απορρίφθηκε",
      "LABEL_ETID_5": "σήμανση για έγκριση",
      "LABEL_ETID_6": "δημοσιεύθηκε",
      "LABEL_ETID_7": "Επέστρεψε στο προσχέδιο",
      "LABEL_ETID_8": "Η εβδομάδα έχει αποσυνδεθεί",
      "LABEL_ETID_9": "εκκρεμεί έγκριση",
      "LABEL_ETID_10": "εγκεκριμένο",
      "LABEL_ETID_11": "ζητείται",
      "LABEL_ETID_13": "εγκεκριμένο",
      "LABEL_ETID_14": "απορρίφθηκε",
      "LABEL_ETID_15": "ακυρωθεί",
      "LABEL_ETID_16": "πλησιάζει η λήξη",
      "LABEL_ETID_17": "έληξε",
      "LABEL_ETID_29": "ξεκίνησε",
      "LABEL_ETID_30": "αποδεκτή από κάποιον",
      "LABEL_ETID_31": "Απορρίφθηκε από κάποιον",
      "LABEL_ETID_32": "ολοκληρώθηκε",
      "LABEL_ETID_36": "Η εβδομάδα αντιστρέφεται",
      "LABEL_ETID_37": "η ημέρα έχει αποσυνδεθεί",
      "LABEL_ETID_38": "Η ημέρα αντιστρέφεται",
      "DESCRIPTOR_ABSENCE": "Το αίτημα απουσίας είναι",
      "DESCRIPTOR_ROTA": "μια τροχιά είναι",
      "DESCRIPTOR_ACCOUNT": "Ένας υπάλληλος είναι",
      "DESCRIPTOR_SWAP": "μια ανταλλαγή βάρδιας είναι",
      "DESCRIPTOR_COST_CONTROL": "έναν έλεγχο κόστους",
      "DESCRIPTOR_DOCUMENT": "Ένα έγγραφο είναι",
      "DESCRIPTOR_BROADCAST": "μια μετάδοση βάρδιας είναι",
      "NOUN_ABSENCE": "κράτηση απουσίας",
      "NOUN_ROTA": "τροχό",
      "NOUN_ACCOUNT": "υπάλληλος",
      "NOUN_SWAP": "ανταλλαγή βάρδιων",
      "NOUN_COST_CONTROL": "αρχείο ελέγχου κόστους",
      "NOUN_DOCUMENT": "έγγραφο",
      "NOUN_BROADCAST": "μετάδοση βάρδιας",
      "NOTIFY_SENTENCE_EXORDIUM": "Ειδοποιήστε με πότε",
      "NOTIFY_SENTENCE_TRAILING_IN": "σε...",
      "UNSUBSCRIBE": "Κατάργηση εγγραφής",
      "SUBSCRIBE": "Εγγραφείτε",
      "SEARCH_PLACEHOLDER": "Αναζήτηση...",
      "BACK_LINK": "Πίσω",
      "RESOURCE_SUBSCRIBE_TOOLTIP": "Ενημερωθείτε για αλλαγές σε αυτό το {{name}}",
      "RESOURCE_UNSUBSCRIBE_TOOLTIP": "Κατάργηση εγγραφής από αλλαγές σε αυτό το {{name}}"
    },
    "ROTA": {
      "DASHBOARD_WEEK": {
        "VIEW_ROTA": "Προβολή διαδρομής",
        "LAST_WEEK": "Την περασμένη εβδομάδα",
        "THIS_WEEK": "Αυτή την εβδομάδα",
        "NO_ATTENDANCE_ISSUES": "Δεν υπάρχουν προβλήματα συμμετοχής",
        "ATTENDANCE_ISSUES": "Ζητήματα συμμετοχής",
        "RESOLVE": "Επιλύστε",
        "ACTUAL_SALES_ENTERED": "Πραγματικά στοιχεία πωλήσεων που εισήχθησαν",
        "ACTUAL_SALES_MISSING": "<strong>Πραγματικά στοιχεία πωλήσεων</strong> λείπουν",
        "WTD_COMPLIANT": "Συμμορφώνεται με τους κανονισμούς χρόνου εργασίας",
        "VIEW": "Προβολή",
        "WTD_VIOLATIONS": "Παραβίαση χρόνου εργασίας",
        "SIGN_OFF_NOT_COMPLETED": "<strong>Η αποσύνδεση</strong> δεν έχει ολοκληρωθεί ακόμα",
        "SIGN_OFF": "Αποσύνδεση",
        "WEEK_SIGNED_OFF": "Η εβδομάδα υπογράφηκε",
        "ABSENCE_REQUEST": "Αιτήματα απουσίας",
        "ADDED_SALES_FORECAST": "Προστέθηκε πρόβλεψη πωλήσεων",
        "DRAFTED_ROTA": "Σχεδιασμένο δρομολόγιο",
        "MARKED_FOR_APPROVAL": "Σημείωση Rota για έγκριση",
        "PUBLISHED": "Δημοσιευμένη λίστα",
        "OUTSTANDING": "{{απουσίες}} σε εκκρεμότητα",
        "NOT_ADDED": "Δεν προστέθηκε",
        "NOT_DRAFTED": "Δεν συντάχθηκε",
        "NOT_DONE": "Δεν έγινε",
        "NOT_PUBLISHED": "Δεν έχει δημοσιευθεί"
      }
    },
    "SIDEBAR": {
      "NOTIFICATION_POPOVER": {
        "NO_NOTIFICATIONS": "Δεν υπάρχουν ειδοποιήσεις!",
        "MARK_ALL_READ": "Σημειώστε όλα αναγνωσμένα"
      },
      "SETTINGS_POPOVER": {
        "TIMEZONE": "Αυτή η ζώνη ώρας είναι διαφορετική από την τοπική ζώνη ώρας.",
        "EDIT_PREFERENCES": "Επεξεργασία προτιμήσεων",
        "MANAGE_SUBSCRIPTIONS": "Διαχείριση συνδρομών",
        "SHOW_INTERCOM": "Εμφάνιση συνομιλίας υποστήριξης",
        "HIDE_INTERCOM": "Απόκρυψη συνομιλίας υποστήριξης",
        "SIGN_OUT": "Αποσύνδεση"
      }
    },
    "DATE_PICKER": {
      "CALENDAR": "Ημερολόγιο",
      "GO_TO_TODAY": "Πηγαίνετε στο σήμερα"
    },
    "ENTITY_SELECTOR": {
      "ORG_WIDE": "Σε ολόκληρο τον οργανισμό",
      "NO_OPTIONS": "Δεν υπάρχουν επιλογές",
      "SEARCH_PLACEHOLDER": "Αναζήτηση..."
    },
    "FILTER_OPTIONS_BUTTON": {
      "IS": "είναι",
      "AND": "και",
      "OR": "ή",
      "ANY": "Οποιοσδήποτε",
      "SEARCH_PLACEHOLDER": "Αναζήτηση...",
      "NO_OPTIONS": "Δεν υπάρχουν επιλογές",
      "SELECT_ALL": "Επιλογή όλων",
      "CLEAR_SELECTED": "Εκκαθάριση επιλεγμένων"
    },
    "FILTER_STAFF_SEARCH_BUTTON": {
      "IS": "είναι",
      "ANY": "Οποιοσδήποτε",
      "RESET": "Επαναφορά"
    },
    "FILTER_DATE_BUTTON": {
      "ANY": "οποιοδήποτε",
      "BEFORE": "μπροστά",
      "AFTER": "μετά",
      "BETWEEN": "μεταξύ",
      "IS": "είναι",
      "AND": "και",
      "DONE": "Έγινε"
    },
    "FILTER_NUMBER_BUTTON": {
      "ANY": "είναι οποιοσδήποτε",
      "GREATER": "είναι μεγαλύτερο από",
      "LESS": "είναι μικρότερη από",
      "EXACTLY": "είναι ακριβώς",
      "DONE": "Έγινε"
    },
    "VALIDATION_FAILURE": {
      "TITLE": "Ζητήματα επικύρωσης",
      "ISSUE": "θέμα",
      "ISSUES": "θέματα",
      "CHANGE_MESSAGE": "Η αλλαγή που προσπαθείτε να κάνετε έχει ως αποτέλεσμα ",
      "IGNORE_CONTINUE": "Αγνοήστε και συνεχίστε ούτως ή άλλως",
      "IGNORE_PROBLEM": "Αγνοήστε τις αλλαγές προβλημάτων και δοκιμάστε ξανά",
      "LOADING": "Φόρτωση..."
    },
    "PICK_LIST": {
      "NO_OPTIONS": "Δεν υπάρχουν επιλογές",
      "SEARCH_PLACEHOLDER": "Αναζήτηση...",
      "DEFAULT_NULL_OPTION_LABEL": "Κανένα"
    },
    "FILE_UPLOAD": {
      "ALERT_UNSUPPORTED_FILE_TYPE": "Δυστυχώς, αυτός ο τύπος αρχείου δεν υποστηρίζεται.",
      "ALERT_FILE_SIZE": "Δυστυχώς, αυτό το αρχείο είναι πολύ μεγάλο.",
      "LABEL_ACCEPTED_FILE_TYPES": "Αποδεκτοί τύποι αρχείων",
      "LABEL_LIMIT": "ορίου",
      "INFO_DROP_AVAILABLE_PART_1": "Μεταφέρετε και αποθέστε ένα αρχείο εδώ ή",
      "INFO_DROP_AVAILABLE_PART_2": "κάντε κλικ για να περιηγηθείτε",
      "INFO_DROP_NOT_AVAILABLE": "Κάντε κλικ για να αναζητήσετε ένα αρχείο",
      "INFO_DRAG_ACTIVE": "Ωραίος στόχος, ώρα να πέσεις!",
      "INFO_UPLOAD_FAILED": "Η μεταφόρτωση απέτυχε, δοκιμάστε ξανά"
    }
  },
  "AVAILABILITY": {
    "ABSENCE": {
      "TAB_ABSENCE": "Κρατήσεις απουσίας",
      "TAB_ALLOWANCES": "Επιδόματα"
    },
    "WEEKLY_AVAILABILITY_MODAL": {
      "ADD_TITLE": "Προσθήκη εβδομαδιαίας διαθεσιμότητας",
      "EDIT_TITLE": "Επεξεργασία εβδομαδιαίας διαθεσιμότητας",
      "NICKNAME": "ψευδώνυμο",
      "PLACEHOLDER": "δηλαδή. Ενώ μελετάτε",
      "HELP": "Θα πρέπει να ονομάσετε αυτό το μοτίβο διαθεσιμότητας μετά από κάτι σημαντικό, όπως «Ενώ σπουδάζετε» εάν αυτό αντιπροσωπεύει πότε μπορείτε να εργαστείτε ενώ σπουδάζετε για εξετάσεις ή «Καλοκαιρινές διακοπές» για όταν είστε ελεύθεροι τις περισσότερες φορές, για παράδειγμα.",
      "STARTING_FROM": "Ξεκινώντας από",
      "NEVER_ENDING": "Ποτέ δεν τελειώνει",
      "YES_PATTERN_FOREVER": "Ναι, αφήστε αυτό το μοτίβο να συνεχιστεί για πάντα",
      "NO_PATTERN_DATE": "Όχι, υπάρχει μια ημερομηνία που θα ήθελα να σταματήσει αυτό το μοτίβο",
      "STOP_DATE": "Ημερομηνία διακοπής",
      "PATTERN_APPLIES": "Ισχύει το μοτίβο",
      "EVERY": "Κάθε",
      "WEEK": "Εβδομάδα",
      "SAVING_BUTTON_TEXT": "Εξοικονόμηση...",
      "SAVE_BUTTON_TEXT": "Αποθήκευση",
      "CANCEL": "Ακύρωση"
    },
    "WEEKLY_AVAILABILITY_MODAL_SINGLE": {
      "TITLE": "Προσθήκη εφάπαξ διαθεσιμότητας",
      "SUBTITLE": "Για μία μόνο εβδομάδα",
      "WEEK_BEGINS": "Η εβδομάδα ξεκινά",
      "HELP": "Αυτή είναι η εβδομάδα για την οποία θέλετε να επισημάνετε τη διαθεσιμότητά σας.",
      "SAVING_BUTTON_TEXT": "Εξοικονόμηση...",
      "SAVE_BUTTON_TEXT": "Αποθήκευση",
      "CANCEL": "Ακύρωση"
    },
    "AVAILABILITY": {
      "ADD_AVAILABILITY": "Προσθήκη διαθεσιμότητας",
      "NEW_WEEKLY_PATTERN": "Νέο εβδομαδιαίο μοτίβο",
      "NEW_MONTHLY_PATTERN": "Νέο μηνιαίο μοτίβο",
      "NEW_CUSTOM_PATTERN": "Νέο προσαρμοσμένο μοτίβο",
      "ONE_OFF_PATTERN": "Εφάπαξ (μία εβδομάδα)",
      "AVAILABILITY": "Διαθεσιμότητα",
      "AVAILABILITY_HELP": "Διαχειριστείτε τη διαθεσιμότητα εδώ μέσω μοτίβων διαθεσιμότητας. Σκεφτείτε αυτά όπως επαναλαμβανόμενα συμβάντα ημερολογίου.",
      "AVAILABILITY_TIP": "Χρησιμοποιήστε ένα μηνιαίο μοτίβο για να στοχεύσετε ημερομηνίες όπως «την πρώτη Τετάρτη» ή «το τελευταίο Σάββατο» κάθε μήνα.",
      "NICKNAME": "ψευδώνυμο",
      "APPLIES_FROM_TO": "Ισχύει από (/έως)",
      "OPTIONS": "Επιλογές",
      "NO_AVAILABILITY_PATTERNS": "Δεν υπάρχουν μοτίβα διαθεσιμότητας. Προσθέστε ένα χρησιμοποιώντας τα παραπάνω κουμπιά.",
      "TURN_OFF": "Απενεργοποιήστε",
      "TURN_ON": "Ενεργοποιήστε",
      "NO_NICKNAME": "Χωρίς ψευδώνυμο",
      "WEEKLY": "Εβδομαδιαία",
      "MONTHLY": "Μηνιαία",
      "CUSTOM": "Προσαρμοσμένο",
      "EDIT": "Επεξεργασία",
      "TOGGLE_DROPDOWN": "Εναλλαγή αναπτυσσόμενου μενού",
      "DELETE": "Διαγραφή",
      "CONFIRM_DELETE_PATTERN": "Είσαι σίγουρη; Η διαγραφή αυτού του μοτίβου διαθεσιμότητας δεν μπορεί να αναιρεθεί.",
      "DELETE_PATTERN_SUCCESS": "Επιτυχία! Το μοτίβο διαθεσιμότητάς σας έχει διαγραφεί.",
      "ERROR_DELETING_PATTERN": "Δεν μπορέσαμε να διαγράψουμε αυτό το μοτίβο μόλις τώρα. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_TOGGLING_PATTERN": "Δεν μπορέσαμε να ενεργοποιήσετε/απενεργοποιήσουμε αυτό το μοτίβο μόλις τώρα. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_LOADING_PATTERNS": "Δεν μπορέσαμε να φορτώσουμε τα μοτίβα διαθεσιμότητάς σας. Παρακαλώ προσπαθήστε ξανά."
    },
    "DAILY_AVAILABILITY_MODAL": {
      "ADD_CUSTOM_AVAILABILITY": "Προσθήκη προσαρμοσμένης διαθεσιμότητας",
      "EDIT_CUSTOM_AVAILABILITY": "Επεξεργασία προσαρμοσμένης διαθεσιμότητας",
      "NICKNAME": "ψευδώνυμο",
      "NICKNAME_PLACEHOLDER": "δηλαδή. Ενώ μελετάτε",
      "NICKNAME_HELP": "Θα πρέπει να ονομάσετε αυτό το μοτίβο μετά από κάτι σημαντικό.",
      "STARTING_FROM": "Ξεκινώντας από",
      "NEVER_ENDING": "Ποτέ δεν τελειώνει",
      "CONTINUE_FOREVER": "Ναι, αφήστε αυτό το μοτίβο να συνεχιστεί για πάντα",
      "PATTERN_STOP": "Όχι, υπάρχει μια ημερομηνία που θα ήθελα να σταματήσει αυτό το μοτίβο",
      "STOP_DATE": "Ημερομηνία διακοπής",
      "PATTERN_DAYS": "Ημέρες μοτίβου",
      "PATTERN_NO_DAYS": "Αυτό το μοτίβο δεν έχει ημέρες. Προσθέστε ένα χρησιμοποιώντας το παρακάτω κουμπί.",
      "DAY_NUMBER": "Ημέρα {{αριθμός}}",
      "ILL_BE": "Θα είμαι",
      "DELETE": "Διαγραφή",
      "ADD_DAY": "Προσθήκη ημέρας",
      "SAVE": "Αποθήκευση",
      "SAVE_LOADING": "Εξοικονόμηση...",
      "CANCEL": "Ακύρωση",
      "UNAVAILABLE": "Μη διαθέσιμο",
      "AVAILABLE": "Διαθέσιμο",
      "AVAILABLE_AM": "Διαθέσιμο (μόνο AM)",
      "AVAILABLE_PM": "Διαθέσιμο (μόνο PM)",
      "OTHER": "Άλλο (διευκρινίστε...)",
      "MINIMUM_TWO_DAYS": "Πρέπει να έχετε τουλάχιστον δύο ημέρες σε αυτό το μοτίβο.",
      "STOPS_BEFORE_STARTS": "Το μοτίβο σας έχει ρυθμιστεί να σταματήσει πριν ξεκινήσει!",
      "AVAILABILITY_END_BEFORE_START": "Ένας ή περισσότεροι από τους κανόνες διαθεσιμότητας έχουν χρόνο λήξης που πέφτει πριν από την ώρα έναρξης.",
      "ERROR_SAVING_PATTERN": "Προς το παρόν δεν είναι δυνατή η αποθήκευση αυτού του μοτίβου διαθεσιμότητας. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_UPDATING_PATTERN": "Προς το παρόν δεν είναι δυνατή η ενημέρωση αυτού του μοτίβου διαθεσιμότητας. Παρακαλώ προσπαθήστε ξανά."
    },
    "EDIT_PATTERN_EXCEPTIONS_MODAL": {
      "EDIT_EXCEPTIONS": "Επεξεργασία εξαιρέσεων",
      "EXCEPTIONS_TIP": "Τα μοτίβα διαθεσιμότητάς σας σηματοδοτούν πότε δεν είστε ελεύθεροι να εργαστείτε. Μπορείτε, ωστόσο, να χρησιμοποιήσετε το ημερολόγιό σας για να μας πείτε ότι είστε ελεύθεροι να εργαστείτε σε μια ημέρα που κανονικά δεν θα μπορούσατε να εργαστείτε. Απλώς κάντε κλικ στον κόκκινο δείκτη «μη διαθέσιμο» και θα μπορείτε να κάνετε μια εξαίρεση.",
      "EXCEPTIONS": "Η παρακάτω λίστα εμφανίζει τις εξαιρέσεις που έχετε κάνει στο μοτίβο διαθεσιμότητάς σας. Θεωρούμε ότι είστε ελεύθεροι να εργαστείτε αυτές τις ημέρες:",
      "DATE": "Ημερομηνία",
      "OPTIONS": "Επιλογές",
      "UNDO": "Αναίρεση",
      "NO_EXCEPTIONS": "Δεν έχεις κάνει καμία εξαίρεση ακόμα.",
      "CLOSE": "Κλείσιμο",
      "ERROR_DELETING_EXCEPTION": "Δυστυχώς δεν μπορέσαμε να διαγράψουμε την εξαίρεση εκείνη τη στιγμή. Παρακαλώ προσπαθήστε ξανά."
    },
    "MONTHLY_AVAILABILITY_MODAL": {
      "ADD_MONTHLY_AVAILABILITY": "Προσθήκη μηνιαίας διαθεσιμότητας",
      "EDIT_MONTHLY_AVAILABILITY": "Επεξεργασία μηνιαίας διαθεσιμότητας",
      "NICKNAME": "ψευδώνυμο",
      "NICKNAME_PLACEHOLDER": "δηλαδή. Ενώ μελετάτε",
      "NICKNAME_HELP": "Θα πρέπει να ονομάσετε αυτό το μοτίβο διαθεσιμότητας μετά από κάτι σημαντικό, όπως «Ενώ σπουδάζετε» εάν αυτό αντιπροσωπεύει πότε μπορείτε να εργαστείτε ενώ σπουδάζετε για εξετάσεις ή «Καλοκαιρινές διακοπές» για όταν είστε ελεύθεροι τις περισσότερες φορές, για παράδειγμα.",
      "STARTING_FROM": "Ξεκινώντας από",
      "NEVER_ENDING": "Ποτέ δεν τελειώνει",
      "CONTINUE_FOREVER": "Ναι, αφήστε αυτό το μοτίβο να συνεχιστεί για πάντα",
      "PATTERN_STOP": "Όχι, υπάρχει μια ημερομηνία που θα ήθελα να σταματήσει αυτό το μοτίβο",
      "STOP_DATE": "Ημερομηνία διακοπής",
      "PATTERN_RULES": "Κανόνες μοτίβου",
      "PATTERN_NO_RULES": "Αυτό το μοτίβο δεν έχει κανόνες. Προσθέστε ένα χρησιμοποιώντας το παρακάτω κουμπί.",
      "EVERY": "Κάθε",
      "ON_THE": "στο",
      "DELETE": "Διαγραφή",
      "ADD_RULE": "Προσθήκη κανόνα",
      "SAVE": "Αποθήκευση",
      "SAVE_LOADING": "Εξοικονόμηση...",
      "CANCEL": "Ακύρωση",
      "ORDINAL_LAST": "τελευταία",
      "ORDINAL_FIRST": "πρώτος",
      "ORDINAL_SECOND": "δεύτερος",
      "ORDINAL_THIRD": "τρίτος",
      "ORDINAL_FOURTH": "τέταρτος",
      "ORDINAL_FIFTH": "πέμπτο",
      "MONDAY": "Δευτέρα",
      "TUESDAY": "Τρίτη",
      "WEDNESDAY": "Τετάρτη",
      "THURSDAY": "Πέμπτη",
      "FRIDAY": "Παρασκευή",
      "SATURDAY": "Σάββατο",
      "SUNDAY": "Κυριακή",
      "AVAILABLE": "Διαθέσιμο",
      "AVAILABLE_AM": "Μόνο AM",
      "AVAILABLE_PM": "Μόνο PM",
      "MONTH": "μήνας",
      "MONTHS": "μήνες",
      "OTHER": "Άλλο (διευκρινίστε...)",
      "STOPS_BEFORE_STARTS": "Το μοτίβο σας έχει ρυθμιστεί να σταματήσει πριν ξεκινήσει!",
      "AVAILABILITY_END_BEFORE_START": "Ένας ή περισσότεροι από τους κανόνες διαθεσιμότητας έχουν χρόνο λήξης που πέφτει πριν από την ώρα έναρξης.",
      "ERROR_SAVING_PATTERN": "Προς το παρόν δεν είναι δυνατή η αποθήκευση αυτού του μοτίβου διαθεσιμότητας. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_UPDATING_PATTERN": "Προς το παρόν δεν είναι δυνατή η ενημέρωση αυτού του μοτίβου διαθεσιμότητας. Παρακαλώ προσπαθήστε ξανά."
    },
    "OVERVIEW": {
      "SCHEDULE": "Το πρόγραμμά μου",
      "SCHEDULE_HELP": "Αυτό είναι το πλήρες πρόγραμμά σας, συμπεριλαμβανομένων των βάρδιων που σας έχουν ανατεθεί, την εγκεκριμένη απουσία και τη διαθεσιμότητα.",
      "SHIFTS": "Βάρδιες",
      "ABSENCE": "Απουσία",
      "UNAVAILABLE": "Μη διαθέσιμο (μοτίβα)",
      "EDIT_EXCEPTIONS": "Επεξεργασία εξαιρέσεων",
      "ICAL_FEED": "Ροή iCalendar",
      "ICAL_FEED_HELP": "Μια ροή iCalendar θα σας επιτρέψει να δείτε το πρόγραμμά σας έξω από το Rotaready.",
      "ICAL_FEED_TIP": "Μόλις δημιουργήσετε μια διεύθυνση URL τροφοδοσίας iCalendar, μπορείτε να την αντιγράψετε και να την επικολλήσετε στο Ημερο <a href=\"https://support.apple.com/en-gb/HT202361\" target=\"_blank\">λόγιο Apple, στο Ημερολόγιο</a> <a href=\"https://support.google.com/calendar/answer/37100\" target=\"_blank\">Google ή σε οποιοδήποτε άλλο λογισμικό ημερο</a> λογίου.",
      "COPIED": "Αντιγράφηκε στο πρόχειρο!",
      "GENERATE": "Δημιουργήστε"
    }
  },
  "ATTENDANCE": {
    "VALIDATION_CODES": {
      "CODE_1": "Η αντίστοιχη ημερομηνία έχει αποσυνδεθεί, επομένως δεν μπορούμε να κάνουμε αλλαγές σε αυτήν.",
      "CODE_2": "Κάτι τέτοιο θα είχε ως αποτέλεσμα μια μη έγκυρη ακολουθία συμβάντων. Για παράδειγμα, ένα ρολόι πρέπει να έρχεται μετά από ένα ρολόι, αλλά δεν μπορεί να έρθει πριν από ένα άλλο ρολόι. Ελέγξτε τα γύρω γεγονότα και δοκιμάστε ξανά.",
      "CODE_3": "Η επιλεγμένη τοποθεσία/τμήμα δεν είναι έγκυρη.",
      "CODE_4": "Δεν μπορούμε να προσθέσουμε ένα ρολόι εδώ, καθώς ο επιλεγμένος υπάλληλος δεν έχει ραντεβού αυτήν την ημερομηνία.",
      "CODE_5": "Δεν μπορούμε να προσθέσουμε ένα συμβάν εδώ, καθώς υπάρχει ήδη ένα υπάρχον συμβάν ταυτόχρονα.",
      "CODE_6": "Ο επιλεγμένος υπάλληλος είναι άκυρος.",
      "CODE_7": "Αυτή η αλλαγή θα μετακινήσει το συμβάν σε προγενέστερο χρόνο από το συμβάν που έρχεται πριν από αυτό. Δοκιμάστε να επιλέξετε διαφορετική ώρα ή να επεξεργαστείτε πρώτα το προηγούμενο συμβάν.",
      "CODE_8": "Αυτή η αλλαγή θα μετακινήσει το συμβάν σε μεταγενέστερο χρόνο από το συμβάν που το ακολουθεί. Δοκιμάστε να επιλέξετε διαφορετική ώρα ή να επεξεργαστείτε πρώτα το μεταγενέστερο συμβάν."
    },
    "COMMON": {
      "EVENT_ADD_SUCCESS": "Η εκδήλωση προστέθηκε με επιτυχία.",
      "ABSENCE_ADD_SUCCESS": "Έχουν επισημανθεί ως απουσιάζοντες.",
      "NO_CLOCK_IN_MODAL_TITLE": "Προσθήκη συμβάντος",
      "NO_CLOCK_IN_MODAL_MESSAGE": "Τι θα θέλατε να κάνετε;",
      "NO_CLOCK_IN_MODAL_ADD_EVENT": "Προσθήκη ρολογιού",
      "NO_CLOCK_IN_MODAL_ADD_EVENT_DESC": "Προσθήκη εγγραφής παρουσίας με μη αυτόματο τρόπο",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE": "Ο Μαρκ απουσιάζει",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC": "Προσθέστε ένα αρχείο απουσίας όπως ασθένεια",
      "UNMATCHED_MODAL_TITLE": "Απαράμιλλη συμμετοχή",
      "UNMATCHED_MODAL_MESSAGE": "Υπάρχουν δύο τρόποι με τους οποίους μπορείτε να επιλύσετε την απαράμιλλη συμμετοχή:",
      "UNMATCHED_MODAL_ADD_SHIFT": "Προσθήκη βάρδιας",
      "UNMATCHED_MODAL_ADD_SHIFT_DESC": "Δημιουργήστε μια νέα βάρδια για να αντικατοπτρίσετε τι λειτούργησε",
      "UNMATCHED_MODAL_EDIT_EVENT": "Επεξεργασία συμμετοχής",
      "UNMATCHED_MODAL_EDIT_EVENT_DESC": "Προσαρμόστε τα αρχεία παρακολούθησης εάν δεν είναι σωστά",
      "OVERTIME_MODAL_TITLE": "Ελέγξτε τις υπερωρίες",
      "OVERTIME_MODAL_MESSAGE_IN": "Το {{name}} έχει κλείσει νωρίς και η βάρδια ταιριάζει με έναν κανόνα αμοιβής. Τι θα θέλατε να κάνετε;",
      "OVERTIME_MODAL_MESSAGE_OUT": "Το {{name}} έχει καθυστερήσει και η βάρδια ταιριάζει με έναν κανόνα αμοιβής. Τι θα θέλατε να κάνετε;",
      "OVERTIME_MODAL_APPROVE": "Εγκρίνετε τις υπερωρίες",
      "OVERTIME_MODAL_APPROVE_DESC": "{{name}} θα πληρωθεί για {{διάρκεια}} λεπτά υπερωρίας",
      "OVERTIME_MODAL_REJECT": "Απόρριψη υπερωριών",
      "OVERTIME_MODAL_REJECT_DESC": "{{name}} δεν θα πληρωθεί για τις υπερωρίες",
      "OVERTIME_UPDATE_ERROR": "Δεν μπορούμε να ενημερώσουμε την εκδήλωση αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "MODAL_USER_NOTES_LABEL": 'Σημειώσεις από τον {{name}}'
    },
    "BY_STAFF": {
      "FILTER_LABEL_DATE": "Ημερομηνία",
      "MANUAL_CLOCK_IN": "Χειροκίνητο ρολόι",
      "EVENT_TYPE": "Εκδήλωση",
      "RECORDED_TIME": "Καταγεγραμμένος χρόνος",
      "METHOD": "Καταλήφθηκε από",
      "OPTIONS": "Επιλογές",
      "TRUSTED_DEVICE": "Αξιόπιστη συσκευή",
      "MOBILE_APP": "Εφαρμογή Rotaready",
      "MANUALLY_OVERRIDDEN": "Χειροκίνητη παραβίαση",
      "MANUAL_ENTRY": "Χειροκίνητη εισαγωγή",
      "DELETE": "Διαγραφή",
      "NO_EVENTS": "Δεν υπάρχουν εκδηλώσεις συμμετοχής για εμφάνιση.",
      "CLOCK_IN": "Ρολόι μέσα",
      "CLOCK_OUT": "Ρολόι έξω",
      "BREAK_ON": "Σε διάλειμμα",
      "BREAK_OFF": "Εκτός διαλείμματος",
      "ERROR_LOADING_EVENTS": "Δεν μπορούμε να φορτώσουμε την παρουσία αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_DELETING": "Δεν μπορούμε να διαγράψουμε την παρουσία αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "DELETE_SUCCESS": "Το συμβάν διαγράφηκε με επιτυχία.",
      "ADD_SUCCESS": "Η εκδήλωση προστέθηκε με επιτυχία.",
      "LABEL_EDITED": "Επεξεργασμένο"
    },
    "ISSUES": {
      "TITLE": "Θέματα συμμετοχής",
      "FILTER_DATE": "Ημερομηνία",
      "FILTER_ISSUE_TYPE": "Τύπος θέματος",
      "FILTER_POSITION": "Ρόλος εργασίας",
      "FILTER_USER": "Πρόσωπο",
      "ISSUES_HELP": "Τα ζητήματα πρέπει να επιλυθούν, διαφορετικά θα προκαλέσουν αποκλίσεις στις αναφορές και τη μισθοδοσία. Από προεπιλογή, το Rotaready επισημαίνει ζητήματα από τις τελευταίες {{defaultIsSuePeriod}} ημέρες.",
      "IS_THIS": "Είναι αυτό",
      "YES": "Ναί",
      "NO": "Οχι",
      "HEADING_NAME": "Όνομα",
      "HEADING_SITE": "Ιστότοπος",
      "HEADING_ISSUE": "Τεύχος",
      "HEADING_SHIFT": "Μετατόπιση",
      "HEADING_START": "Έναρξη",
      "HEADING_FINISH": "Τελειώστε",
      "HEADING_CLOCK_IN": "Ρολόι",
      "HEADING_CLOCK_OUT": "Ρολόι έξω",
      "HEADING_OPTIONS": "Επιλογές",
      "NOT_FOUND": "Δεν βρέθηκε",
      "RESOLVE": "Επιλύστε",
      "DISMISS": "Απορρίψτε",
      "NO_ATTENDANCE_ISSUES": "Δεν έχετε προβλήματα συμμετοχής, ωραία!",
      "UNMATCHED_ATTENDANCE": "Απαράμιλλη συμμετοχή",
      "NO_CLOCK_IN": "Χωρίς ρολόι",
      "NO_CLOCK_OUT": "Χωρίς ρολόι",
      "SUSPECT_CLOCK_OUT": "Ύποπτο ρολόι",
      "SUSPECT_ATTENDANCE": "Ύποπτη παρουσία",
      "BREAK_NOT_TAKEN": "Το διάλειμμα δεν έγινε",
      "INVALID_BREAKS": "Μη έγκυρο διάλειμμα",
      "SUSPECT_BREAK": "Ύποπτο διάλειμμα",
      "EARLY_CLOCK_IN": "Πρόωρη άφιξη",
      "LATE_CLOCK_OUT": "Καθυστερημένη ώρα",
      "UNMATCHED_ATTENDANCE_EXPLANATION": "Το Rotaready δεν μπόρεσε να βρει μια βάρδια που να ταιριάζει με αυτήν την παρουσία",
      "NO_CLOCK_IN_EXPLANATION": "Το μέλος του προσωπικού δεν επέστρεψε για τη βάρδια του",
      "NO_CLOCK_OUT_EXPLANATION": "Το μέλος του προσωπικού δεν αποχώρησε μετά τη βάρδια του",
      "SUSPECT_CLOCK_OUT_EXPLANATION": "Το μέλος του προσωπικού αποχώρησε πολύ αργότερα από την προγραμματισμένη ώρα τερματισμού",
      "SUSPECT_ATTENDANCE_EXPLANATION": "Το μέλος του προσωπικού κλείστηκε για πολύ μικρότερο χρονικό διάστημα από ό, τι είχε προγραμματιστεί",
      "BREAK_NOT_TAKEN_EXPLANATION": "Η βάρδια έχει ένα διάλειμμα αλλά δεν έγινε",
      "INVALID_BREAKS_EXPLANATION": "Το διάλειμμα αυτής της συμμετοχής είναι ελλιπές (δηλ. λείπει ένα διάλειμμα)",
      "SUSPECT_BREAK_EXPLANATION": "Το μέλος του προσωπικού ήταν σε διάλειμμα για πολύ περισσότερο από ό, τι είχε προγραμματιστεί",
      "EARLY_CLOCK_IN_EXPLANATION": "Η βάρδια ταιριάζει με έναν κανόνα αμοιβής που απαιτεί έγκριση για νωρίς το ρολόι",
      "LATE_CLOCK_OUT_EXPLANATION": "Η βάρδια ταιριάζει με έναν κανόνα αμοιβής που απαιτεί έγκριση για καθυστερημένη διακοπή",
      "ERROR_LOADING_ATTENDANCE": "Δεν μπορούμε να φορτώσουμε τα ζητήματα συμμετοχής σας αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "SOMETHING_WENT_WRONG": "Ωχ, κάτι πήγε στραβά. Ίσως χρειαστεί να προσπαθήσετε ξανά!",
      "CONFIRM_DISMISS": "Είστε βέβαιοι ότι θέλετε να απορρίψετε αυτό το ζήτημα συμμετοχής?",
      "DATE_RANGE_TOO_WIDE": "Δεν μπορείτε να επιλέξετε περισσότερες από 5 εβδομάδες. Περιορίστε το εύρος ημερομηνιών σας.",
      "CLOCK_OUT_DAYS_LATER": "Αυτό το ρολόι είναι μία ή περισσότερες ημέρες μετά το ρολόι."
    },
    "OVERVIEW": {
      "UNMATCHED_LABEL": "{{count}} απαράμιλλη",
      "OPTIONS_DAILY": "Καθημερινά",
      "OPTIONS_WEEKLY": "Εβδομαδιαία",
      "OPTIONS_DAY_ROLL_OVER": "Ημερήσια ανατροπή",
      "FILTER_POSITION": "Ρόλος εργασίας",
      "FILTER_USER": "Πρόσωπο",
      "MIDNIGHT": "Μεσάνυχτα",
      "TOOLS": "Εργαλεία",
      "CURRENTLY_CLOCKED_IN": "Άτομα που παρακολουθούνται αυτήν τη στιγμή",
      "ATTENDANCE_HELP": "Η προσέλευση αντιστοιχίζεται αυτόματα με βάρδιες εάν πραγματοποιηθεί χρονική είσοδος ή διακοπή εντός <b>{{AutoMatchThreshold}} ωρών από μια προγραμματισμένη ώρα έναρξης ή λήξης.</b>",
      "MATCHED_HEADING_SCHEDULED": "Προγραμματισμένη",
      "MATCHED_HEADING_ACTUAL": "Πραγματική",
      "MATCHED_HEADING_AFTER_SANITISATION": "Μετά την απολύμανση",
      "MATCHED_HEADING_NAME": "Όνομα",
      "MATCHED_HEADING_SITE": "Ιστότοπος",
      "MATCHED_HEADING_START": "Έναρξη",
      "MATCHED_HEADING_FINISH": "Τελειώστε",
      "MATCHED_HEADING_PAID_HOURS": "Ώρες επί πληρωμή",
      "MATCHED_HEADING_UNPAID_HOURS": "Μη αμειβόμενες ώρες",
      "COVER_SHIFT": "Μετατόπιση κάλυψης",
      "NO_CLOCK_IN": "Δεν υπάρχει χρονοδιάγραμμα ακόμα",
      "NO_CLOCK_OUT": "Δεν υπάρχει χρονοδιάγραμμα ακόμα",
      "OVERTIME_APPROVED": "Εγκεκριμένο",
      "OVERTIME_REJECTED": "Απορρίφθηκε",
      "OVERTIME_REVIEW": "Αναθεώρηση",
      "OVERTIME_APPROVED_BY": "Οι υπερωρίες εγκρίθηκαν από την {{name}}",
      "OVERTIME_REJECTED_BY": "Οι υπερωρίες απορρίφθηκαν από {{name}}",
      "MATCHED_NO_RESULTS": "Δεν υπάρχουν βάρδιες για εμφάνιση.",
      "UNMATCHED_ATTENDANCE": "Απαράμιλλη συμμετοχή",
      "UNMATCHED_ATTENDANCE_HELP": "Εάν η παρουσία δεν εμπίπτει σε <b>{{AutoMatchThreshold}} ώρες από την ώρα έναρξη</b> ς/λήξης μιας βάρδιας, θα πρέπει να προσαρμόσετε χειροκίνητα τη βάρδια ή τα αρχεία παρακολούθησης για να τα ενσωματώσετε:",
      "UNMATCHED_HEADING_NAME": "Όνομα",
      "UNMATCHED_HEADING_SITE": "Ιστότοπος",
      "UNMATCHED_HEADING_DATE": "Ημερομηνία",
      "UNMATCHED_HEADING_CLOCK_IN": "Ρολόι μέσα",
      "UNMATCHED_HEADING_CLOCK_OUT": "Ρολόι έξω",
      "UNMATCHED_HEADING_OPTIONS": "Επιλογές",
      "NO_UNMATCHED_ATTENDANCE": "Δεν υπάρχει απαράμιλλη συμμετοχή εδώ. Καλή δουλειά!",
      "NONE_YET": "Κανένα ακόμα",
      "RESOLVE": "Επιλύστε",
      "ERROR_LOADING_ATTENDANCE": "Δεν μπορούμε να φορτώσουμε το ιστορικό συμμετοχής σας αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "TIMELINESS_EARLY_TOOLTIP": "{{διάρκεια}} λεπτά νωρίτερα",
      "TIMELINESS_LATE_TOOLTIP": "{{διάρκεια}} λεπτά καθυστέρηση",
      "TRUNCATED_RESULT_MESSAGE": "Υπάρχουν περισσότερα να δείτε, αλλά για να διατηρήσουμε τα πράγματα ομαλά, έπρεπε να περικόψουμε το αποτέλεσμά σας. <br />Δοκιμάστε να φιλτράρετε ανά ρόλο εργασίας, ανά τοποθεσία/τμήμα ή μειώστε το εύρος ημερομηνιών."
    },
    "VIEW_EVENT_MODAL": {
      "VIEW_ATTENDANCE": "Προβολή συμμετοχής",
      "STAFF_MEMBER": "Ποιος",
      "EVENT_TYPE": "Εκδήλωση",
      "RECORDED_TIME": "Καταγεγραμμένος χρόνος",
      "METHOD": "Καταλήφθηκε από",
      "TRUSTED_DEVICE": "Αξιόπιστη συσκευή",
      "MOBILE_APP": "Εφαρμογή Rotaready",
      "MANUALLY_OVERRIDDEN": "Χειροκίνητη παράκαμψη",
      "MANUAL_ENTRY": "Χειροκίνητη εισαγωγή",
      "ON": "στις",
      "OVERTIME": "Υπερωρίες",
      "APPROVED": "Εγκεκριμένο",
      "REJECTED": "Απορρίφθηκε",
      "FACIAL_RECOGNITION": "Αναγνώριση προσώπου",
      "FACIAL_RECOGNITION_PROCESS": "Οι εικόνες συνήθως επεξεργάζονται μέσα σε λίγα λεπτά από τη λήψη",
      "FACIAL_RECOGNITION_WAITING": "Αναμονή επεξεργασίας...",
      "TRUSTS_PHOTO": "Η Rotaready εμπιστεύεται αυτήν τη φωτογραφία",
      "NO_TRUSTS_PHOTO": "Η Rotaready δεν εμπιστεύεται αυτή τη φωτογραφία",
      "CONFIDENT": "σίγουρος",
      "UNABLE_TO_IDENTIFY": "Δεν είναι δυνατή η σαφής αναγνώριση ενός προσώπου",
      "PROCESSED_ON": "Επεξεργάστηκε στις",
      "ACKNOWLEDGED_BY": "Αναγνωρίζεται από {{name}} στις",
      "REFERENCE_IMAGE": "Εικόνα αναφοράς",
      "REFERENCE_IMAGE_HELP": "Αυτή η εικόνα δεν θα υποβληθεί σε επεξεργασία, αλλά θα χρησιμοποιηθεί ως αναφορά για σύγκριση",
      "EDIT": "Επεξεργασία",
      "CLOSE": "Κλείσιμο",
      "TAB_DETAILS": "Λεπτομέρειες",
      "TAB_PHOTO": "φωτογραφία",
      "TAB_LOCATION": "Τοποθεσία",
      "CLOCK_IN": "Ρολόι μέσα",
      "CLOCK_OUT": "Ρολόι έξω",
      "BREAK_ON": "Διακοπή",
      "BREAK_OFF": "Διακοπή",
      "HAPPENED_HERE": "Συνέβη εδώ",
      "TAB_AUDIT": "Ιστορία",
      "AUDIT_TABLE_TIME": "Χρόνος παρακολούθησης",
      "AUDIT_TABLE_OVERTIME": "Υπερωρίες",
      "AUDIT_TABLE_WHO": "Καταγράφηκε από",
      "NOTES": "Σημειώσεις"
    },
    "EDIT_EVENT_MODAL": {
      "TITLE": "Επεξεργασία συμμετοχής",
      "CLOCK_IN": "Ρολόι μέσα",
      "CLOCK_OUT": "Ρολόι έξω",
      "BREAK_ON": "Διακοπή",
      "BREAK_OFF": "Διακοπή",
      "ERROR_UPDATING": "Δεν μπορούμε να ενημερώσουμε την εκδήλωση αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_DELETING": "Δεν μπορούμε να διαγράψουμε το συμβάν αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το συμβάν?\nΕάν πρόκειται για ρολόι, το αντίστοιχο ρολόι θα αφαιρεθεί επίσης.",
      "PRIOR_EVENT_INFO": "Το προηγούμενο συμβάν είναι ένα <b>{{eventType}}</b> στο <b>{{date|momentFormat: 'll</b> - LT'}}",
      "PRIOR_EVENT_CONFLICT": "Υπάρχει ήδη ένα <b>{{eventType}}</b> στο <b>{{date|momentFormat: 'll -</b> LT'}}. Θα πρέπει να επιλέξετε διαφορετική ώρα.",
      "LABEL_WHO": "Ποιος",
      "LABEL_EVENT": "Εκδήλωση",
      "LABEL_DATE": "Ημερομηνία & ώρα",
      "DATE_IN_FUTURE": "Αυτός ο χρόνος δεν έχει παρέλθει ακόμα!",
      "LABEL_OVERTIME": "Υπερωρίες",
      "APPROVE": "Έγκριση",
      "REJECT": "Απόρριψη",
      "LABEL_OVERTIME_HELP": "Ο Rotaready εντόπισε κάποιες υπερωρίες όταν αντιστοίχισε αυτήν την εκδήλωση παρακολούθησης με μια βάρδια. Εάν το εγκρίνετε, οι υπερωρίες θα πληρωθούν σύμφωνα με τον κανόνα αμοιβής της βάρδιας.",
      "DELETE": "Διαγραφή",
      "CANCEL": "Ακύρωση",
      "SAVE": "Αποθήκευση αλλαγών"
    },
    "ADD_EVENT_MODAL": {
      "TITLE": "Προσθήκη συμμετοχής",
      "CLOCK_IN": "Ρολόι μέσα",
      "CLOCK_OUT": "Ρολόι έξω",
      "BREAK_ON": "Διακοπή",
      "BREAK_OFF": "Διακοπή",
      "ERROR_ACTIONING": "Δεν μπορούμε να προσθέσουμε την εκδήλωση αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "PRIOR_EVENT_INFO": "Το προηγούμενο συμβάν είναι ένα <b>{{eventType}}</b> στο <b>{{date|momentFormat: 'll</b> - LT'}}",
      "PRIOR_EVENT_CONFLICT": "Υπάρχει ήδη ένα <b>{{eventType}}</b> στο <b>{{date|momentFormat: 'll -</b> LT'}}. Θα πρέπει να επιλέξετε διαφορετική ώρα.",
      "LABEL_EVENT": "Εκδήλωση",
      "LABEL_DATE": "Ημερομηνία & ώρα",
      "LABEL_BREAK": "Διάρκεια διαλείμματος",
      "LABEL_BREAK_HELP": "Μια διακοπή θα προστεθεί για εσάς μετά την επιλεγμένη διάρκεια.",
      "DATE_IN_FUTURE": "Αυτός ο χρόνος δεν έχει παρέλθει ακόμα!",
      "CANCEL": "Ακύρωση",
      "SAVE": "Προσθήκη συμμετοχής",
      "BREAK_MINUTES": "λεπτών"
    }
  },
  "DIALOG": {
    "TEXTBOX_DIALOG": {
      "CANCEL": "Ακύρωση"
    },
    "TWO_BUTTON_DIALOG": {
      "CANCEL": "Ακύρωση"
    }
  },
  "OTHER": {
    "EDIT_PREFERENCES_MODAL": {
      "TITLE": "Ενημέρωση προτιμήσεων",
      "SUBTITLE": "Στοιχεία επικοινωνίας",
      "SUBTITLE_HELP": "Εάν ξεχάσετε τον κωδικό πρόσβασής σας, μπορούμε να σας βοηθήσουμε να τον επαναφέρετε μόνο εάν έχουμε εναλλακτικούς τρόπους επικοινωνίας μαζί σας",
      "EMAIL_LABEL": "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
      "EMAIL_PLACEHOLDER": "Κανένα δεν παρέχεται",
      "EMAIL_HELP": "Δώστε μια έγκυρη διεύθυνση email.",
      "MOBILE_LABEL": "Κινητό τηλέφωνο",
      "MOBILE_PLACEHOLDER": "Κανένα δεν παρέχεται",
      "MOBILE_HELP": "Δώστε έναν έγκυρο αριθμό κινητού τηλεφώνου, ή καθόλου.",
      "ACCOUNT_INFO": "Στοιχεία λογαριασμού",
      "ACCOUNT_INFO_HELP": "Εδώ μπορείτε να ορίσετε τη γλώσσα σας",
      "LANGUAGE_LABEL": "Γλώσσα",
      "LANGUAGE_HELP": "Το Rotaready θα εμφανιστεί στην επιλεγμένη γλώσσα.",
      "OTHER_SETTINGS_TITLE": "Άλλες ρυθμίσεις",
      "CLOCK_IN_LABEL": "PIN ρολογιού",
      "CLOCK_IN_HELP": "Εάν απαιτείται να εισαγάγετε έναν κωδικό PIN κατά την ώρα εισόδου/εξόδου, μπορείτε να εισαγάγετε έναν νέο εδώ. Πρέπει να είναι 4 ψηφία.",
      "NOTIFICATIONS_TITLE": "Ειδοποιήσεις",
      "NOTIFICATIONS_HELP": "Ελέγξτε τον τρόπο με τον οποίο θέλετε να λαμβάνετε ειδοποιήσεις για συμβάντα στα οποία έχετε εγγραφεί ",
      "MANAGE_SUBSCRIPTIONS": "διαχείριση συνδρομών",
      "ROTA_UPDATES": "Ενημερώσεις Rota",
      "CRITIAL_SHIFT": "Κρίσιμες ενημερώσεις βάρδιας",
      "EMAIL": " ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
      "MOBILE_APP": " Εφαρμογή για κινητά",
      "SHIFT_UPDATED": "Πότε μια βάρδια που θα εργάζεστε τις επόμενες 24 ώρες έχει ενημερωθεί",
      "REGULAR_SHIFT": "Τακτικές ενημερώσεις βάρδιας",
      "SHIFT_UPDATED_BEYOND": "Ενημερώσεις για τυχόν βάρδιες που θα εργάζεστε πέραν των επόμενων 24 ωρών",
      "WEEKLY_SUMMARY": "Εβδομαδιαία περίληψη",
      "SMS": " SMS",
      "UPCOMING_WEEK": "Μια περίληψη των βάρδιων σας για την επερχόμενη εβδομάδα, που σας αποστέλλεται το βράδυ πριν από την έναρξη της εβδομάδας",
      "APPROVAL": "Έγκριση",
      "APPROVAL_HELP": "Όταν ένα δρομολόγιο επισημαίνεται ως έτοιμο για έγκριση",
      "PUBLISHING": "Δημοσίευση",
      "PUBLISHING_HELP": "Όταν δημοσιεύεται ή αδημοσιεύεται μια σειρά",
      "SHIFT_SWAPS": "Ανταλλαγές βάρδιας",
      "SWAP_UPDATES": "Εναλλαγή ενημερώσεων ",
      "REQUESTED": "Ζητήθηκε",
      "REQUESTED_HELP": "Όταν κάποιος ζητά μια νέα ανταλλαγή βάρδιας",
      "ACCEPTED": "Αποδεκτή",
      "ACCEPTED_HELP": "Όταν κάποιος δέχεται μια ανταλλαγή βάρδιας",
      "APPROVED": "Εγκεκριμένο",
      "APPROVED_HELP": "Όταν μια ανταλλαγή βάρδιας εγκρίνεται από τη διοίκηση",
      "REJECTED": "Απορρίφθηκε",
      "REJECTED_HELP": "Όταν μια ανταλλαγή βάρδιας απορρίπτεται από τη διοίκηση",
      "CANCELLED": "Ακυρώθηκε",
      "CANCELLED_HELP": "Όταν ακυρώνεται μια αλλαγή βάρδιας",
      "ABSENCE_UPDATES": "Απουσίες/ενημερώσεις διακοπών",
      "ABSENCE_REQUESTED": "Ζητήθηκε απουσία",
      "ABSENCE_REQUESTED_HELP": "Όταν ζητείται νέα απουσία",
      "ABSENCE_UPDATE": "Ενημέρωση απουσίας",
      "ABSENCE_UPDATE_HELP": " Όταν ένα αίτημα απουσίας εγκρίνεται, απορρίπτεται ή ακυρώνεται από τη διοίκηση",
      "COST_CONTROL": "Έλεγχος κόστους",
      "SIGN_OFF": "Αποσύνδεση",
      "SIGN_OFF_HELP": "Όταν ένας ιστότοπος υπογράφει μια εβδομάδα",
      "SIGN_OFF_REVERSE": "Η υπογραφή αντιστράφηκε",
      "SIGN_OFF_REVERSE_HELP": "Όταν ένας ιστότοπος αντιστρέφει την αποσύνδεση για μια εβδομάδα",
      "ADMIN": "Διαχειριστής",
      "ACCOUNT_APPROVAL": "Έγκριση λογαριασμού",
      "ACCOUNT_APPROVAL_HELP": "Όταν ένας λογαριασμός είτε αναμένει έγκριση είτε έχει εγκριθεί",
      "DOCUMENTS": "Έγγραφα",
      "DOCUMENTS_HELP": "Όταν ένα έγγραφο έχει λήξει ή θα λήξει σύντομα",
      "OTHER": "Άλλο",
      "COMPANY_ANNOUNCEMENTS": "Εταιρικές ανακοινώσεις",
      "COMPANY_HELP": "Σημαντικές ανακοινώσεις από τον εργοδότη σας",
      "DAILY_ADMIN_UPDATE": "Καθημερινή ενημέρωση διαχειριστή",
      "DAILY_UPDATE_HELP": "Θα σας ειδοποιήσουμε εάν έχετε προβλήματα συμμετοχής, ή εάν υπάρχουν αιτήματα απουσίας ή ανταλλαγές βάρδιας εν αναμονή έγκρισης.",
      "PHONE_ONLY": "Οι ειδοποιήσεις με αυτό το εικονίδιο είναι διαθέσιμες μόνο με τις εφαρμογές Rotaready iPhone και Android.",
      "CHANGE_PASSWORD": "Αλλαγή κωδικού πρόσβασης",
      "SAVE": "Αποθήκευση",
      "SAVING": "Εξοικονόμηση...",
      "CANCEL": "Ακύρωση"
    },
    "MANAGE_SUBSCRIPTIONS_MODAL": {
      "TITLE": "Διαχείριση συνδρομών",
      "HELP": "Οι συνδρομές σας ελέγχουν τι θέλετε να ενημερώνεστε.",
      "UNSUBSCRIBE_ALL": "Κατάργηση εγγραφής από όλα",
      "NOTIFY_WHEN": "Ειδοποιήστε με όταν {{περιγραφέας}}",
      "UNSUBSCRIBE_TOOLTIP": "Κατάργηση εγγραφής",
      "UNSUBSCRIBE_ALL_TOOLTIP": "Κατάργηση εγγραφής από όλα",
      "ACTION_IN": "{{δράση}} σε ",
      "FILTER_NO_SUBSCRIPTIONS": "Δεν έχετε ενεργές συνδρομές στο {{name}}.",
      "ALL_NO_SUBSCRIPTIONS": "Δεν έχετε ενεργές συνδρομές.",
      "CLOSE": "Κλείσιμο"
    },
    "RELEASE_REFRESH_MODAL": {
      "TITLE": "Ενημέρωση Rotaready",
      "BODY": "Μόλις κυκλοφορήσαμε μια ενημέρωση στο Rotaready που απαιτεί να φορτώσετε ξανά τη σελίδα. Κάντε κλικ στο παρακάτω κουμπί.",
      "REFRESH": "Ανανέωση σελίδας"
    },
    "EDIT_AVATAR_MODAL": {
      "TITLE": "Εικόνα προφίλ",
      "INTRO_COPY": "Μια εικόνα βοηθά τους χρήστες να σας αναγνωρίσουν και σας ενημερώνει πότε είστε συνδεδεμένοι στον λογαριασμό σας.",
      "BUTTON_CHANGE": "Ανεβάστε μια νέα εικόνα",
      "BUTTON_CANCEL": "Ακύρωση",
      "BUTTON_SAVE": "Αποθήκευση",
      "ALERT_REMOVE": "Είστε βέβαιοι ότι θέλετε να καταργήσετε αυτήν την εικόνα προφίλ?",
      "ALERT_FILE_TYPE_NOT_ALLOWED": "Αυτός ο τύπος αρχείου δεν επιτρέπεται.",
      "ALERT_ERROR_500": "Δεν μπορούσαμε να ενημερώσουμε τη φωτογραφία σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "ALERT_SUCCESS_ADD": "Η εικόνα του προφίλ σας έχει ενημερωθεί. Μπορεί να χρειαστεί λίγος χρόνος για την ενημέρωση σε όλες τις υπηρεσίες του Rotaready.",
      "ALERT_SUCCESS_REMOVE": "Η εικόνα του προφίλ σας έχει αφαιρεθεί. Μπορεί να χρειαστεί λίγος χρόνος για ενημέρωση σε όλες τις υπηρεσίες του Rotaready."
    }
  },
  "REPORTS": {
    "INDEX_LIST": {
      "TITLE": "Αναφορές",
      "TRONC_STATUS": "Κατάσταση Tronc",
      "CURRENTLY_CLOCKED_IN": "Άτομα που βρίσκονται αυτή τη στιγμή",
      "ROTA_PROGRESS": "Πρόοδος τροχού",
      "SIGN_OFF_PROGRESS": "Πρόοδος υπογραφής",
      "PAYROLL_EXPORT": "Εξαγωγή μισθοδοσίας",
      "LABOUR_BREAKDOWN": "Κατανομή εργασίας",
      "WORKING_TIME_VIOLATIONS": "Παραβιάσεις χρόνου εργασίας",
      "CONTRACTUAL_OBLIGATION_SUMMARY": "Περίληψη συμβατικής υποχρέωσης",
      "DOCUMENT_SIGNATURES": "Υπογραφή εγγράφων",
      "EXPIRING_DOCUMENTS": "Έγγραφα που λήγουν",
      "EMPLOYEE_EXPORT": "Στοιχεία Υπαλλήλων",
      "JOINERS_LEAVERS": "Ξυλουργοί & Αποχωρητές",
      "STAFF_TURNOVER": "Κύκλος εργασιών προσωπικού",
      "ANNIVERSARY": "Επετείους",
      "EMPLOYMENT_CHANGES": "Αλλαγές στην απασχόληση",
      "CATEGORY_HR": "HR",
      "CATEGORY_DOCUMENTS": "Έγγραφα",
      "CATEGORY_ROTAS": "Προγραμματισμός",
      "CATEGORY_PAYROLL": "Μισθοδοσία",
      "CATEGORY_ATTENDANCE": "Συμμετοχή",
      "CATEGORY_ABSENCE": "Απουσία",
      "CATEGORY_COST_CONTROL": "Έλεγχος κόστους",
      "ABSENCE_EXPORT": "Εξαγωγή απουσίας",
      "ALLOWANCES_EXPORT": "Εξαγωγή δικαιωμάτων",
      "UNTAKEN_HOLIDAY_COST": "Κόστος διακοπών που δεν έχουν αναληφθεί",
      "COST_CONTROL_RAW_DATA": "Εξαγωγή ακατέργαστων δεδομένων",
      "NEW_HIRE_TURNOVER": "Νέος κύκλος εργασιών προσλήψεων",
      "ABSENCE_DAYS_EXPORT": "Εξαγωγή ημερών απουσίας",
      "SHIFT_TYPE_STREAM_BREAKDOWN": "Τύπος βάρδιας και ανάλυση ροής",
      "XERO_EXPORT": "Εξαγωγή σε Xero",
      "DOCUMENT_PRESENCE": "Παρουσία εγγράφων",
      "DOCUMENTS_EXPORT": "Εξαγωγή εγγράφων",
      "ATTENDANCE_BREAKDOWN": "Ανάλυση συμμετοχής",
      "LATENESS": "Καθυστέρηση",
      "LABOUR_BREAKDOWN_FILE_EXPORT": "Εξαγωγή αρχείου κατανομής εργασίας",
      "CATEGORY_ASYNC_FILE_EXPORTS": "Μεγάλες aναφορές",
      "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": "Εξαγωγή αρχείου κατανομής εργασίας",
      "PAYROLL_ASYNC_FILE_EXPORT": "Εξαγωγή αρχείου μισθοδοσίας",
      "RAW_COST_CONTROL_ASYNC_FILE_EXPORT": "Εξαγωγή αρχείου ελέγχου ακατέργαστου κόστους",
    },
    "LATENESS": {
      "TITLE": "Καθυστέρηση",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE_APPOINTMENT": "Τοποθεσία (ραντεβού)",
      "COLUMN_DEPT_APPOINTMENT": "Τμήμα (διορισμός)",
      "COLUMN_INSTANCES": "Περιπτώσεις",
      "COLUMN_VARIANCE": "Μέση διακύμανση",
      "FILTER_DATE": "Ημερομηνία",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_USER": "Πρόσωπο",
      "FILTER_OVERTIME": "Υπερωρίες",
      "FILTER_OVERTIME_NA": "Δεν αναγνωρίζεται",
      "FILTER_OVERTIME_APPROVED": "Εγκεκριμένο",
      "FILTER_OVERTIME_REJECTED": "Απορρίφθηκε",
      "FILTER_CLOCK_IN": "Ρολόι",
      "FILTER_CLOCK_OUT": "Ρολόι έξω",
      "FILTER_MINS_LATE": "λεπτά καθυστέρηση",
      "FILTER_MINS_EARLY": "λεπτά νωρίτερα",
      "LABEL_MINUTES": "λεπτών",
      "BUTTON_EXPORT": "Εξαγωγή",
      "NO_MATCH": "Δεν μπορέσαμε να βρούμε δεδομένα που να ταιριάζουν με τα φίλτρα σας."
    },
    "ATTENDANCE_BREAKDOWN": {
      "TITLE": "Ανάλυση συμμετοχής",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE_APPOINTMENT": "Τοποθεσία (ραντεβού)",
      "COLUMN_DEPT_APPOINTMENT": "Τμήμα (διορισμός)",
      "COLUMN_SITE_ATTRIBUTION": "Ιστότοπος (απόδοση)",
      "COLUMN_DEPT_ATTRIBUTION": "Τμήμα (απόδοση)",
      "COLUMN_DATE": "Ημερομηνία επιχείρησης",
      "COLUMN_SHIFT_TYPE": "Τύπος αλλαγής ταχυτήτων",
      "COLUMN_RECORD_TYPE": "Τύπος εγγραφής",
      "COLUMN_SCHEDULED": "Προγραμματισμένη",
      "COLUMN_ACTUAL": "Πραγματική",
      "COLUMN_VARIANCE": "Διακύμανση",
      "COLUMN_CAPTURED_BY": "Καταλήφθηκε από",
      "COLUMN_EDITED_BY": "Επεξεργασία από",
      "COLUMN_EDITED_DATE": "Ημερομηνία επεξεργασίας",
      "COLUMN_OVERTIME_STATE": "Έγκριση υπερωριών",
      "COLUMN_OVERTIME_EDITED_BY": "Οι υπερωρίες επιβεβαιώνονται από",
      "COLUMN_OVERTIME_EDITED_DATE": "Επιβεβαιωμένη ημερομηνία υπερωρίας",
      "FILTER_DATE": "Ημερομηνία",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_USER": "Πρόσωπο",
      "FILTER_OVERTIME": "Υπερωρίες",
      "FILTER_OVERTIME_NA": "Δεν αναγνωρίζεται",
      "FILTER_OVERTIME_APPROVED": "Εγκεκριμένο",
      "FILTER_OVERTIME_REJECTED": "Απορρίφθηκε",
      "FILTER_CLOCK_IN": "Ρολόι",
      "FILTER_CLOCK_OUT": "Ρολόι έξω",
      "FILTER_MINS_LATE": "λεπτά καθυστέρηση",
      "FILTER_MINS_EARLY": "λεπτά νωρίτερα",
      "BUTTON_EXPORT": "Εξαγωγή",
      "NO_MATCH": "Δεν μπορέσαμε να βρούμε δεδομένα που να ταιριάζουν με τα φίλτρα σας."
    },
    "DOCUMENTS_EXPORT": {
      "TITLE": "Εξαγωγή εγγράφων",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_TOGGLE_USER_ACTIVE": "Ενεργοί υπάλληλοι",
      "FILTER_USER": "Πρόσωπο",
      "FILTER_CATEGORY": "Κατηγορία",
      "FILTER_ACKNOWLEDGEMENT": "Κατάσταση",
      "FILTER_OPTION_ACKNOWLEDGED": "Αναγνωρίζεται",
      "FILTER_OPTION_NOT_ACKNOWLEDGED": "Δεν αναγνωρίζεται",
      "BUTTON_EXPORT": "Εξαγωγή",
      "COLUMN_USER_ID": "Αναγνωριστικό εργαζομένου",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE": "Ιστότοπος",
      "COLUMN_DEPARTMENT": "Τμήμα",
      "COLUMN_CATEGORY": "Κατηγορία",
      "COLUMN_FILE_NAME": "Όνομα",
      "COLUMN_FILE_SIZE": "Μέγεθος αρχείου (KB)",
      "COLUMN_UPLOADED_BY": "Ανεβάστηκε από",
      "COLUMN_UPLOADED_DATE": "Ημερομηνία μεταφόρτωσης",
      "COLUMN_EXPIRY_DATE": "Ημερομηνία λήξης",
      "COLUMN_SIGNATURE_METHOD": "Μέθοδος υπογραφής",
      "COLUMN_EDITED_BY": "Τελευταία επεξεργασία από",
      "COLUMN_EDITED_DATE": "Τελευταία ημερομηνία επεξεργασίας",
      "COLUMN_ACKNOWLEDGED": "Αναγνωρίζεται",
      "COLUMN_ACKNOWLEDGED_DATE": "Αναγνωρισμένη ημερομηνία",
      "COLUMN_NOTES": "Σημειώσεις",
      "NO_MATCH": "Δεν μπορέσαμε να βρούμε υπαλλήλους για τα φίλτρα σας."
    },
    "DOCUMENT_PRESENCE": {
      "TITLE": "Παρουσία εγγράφων",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_TOGGLE_USER_ACTIVE": "Ενεργοί υπάλληλοι",
      "FILTER_CATEGORY": "Κατηγορία",
      "BUTTON_EXPORT": "Εξαγωγή",
      "COLUMN_USER_ID": "Αναγνωριστικό εργαζομένου",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE": "Ιστότοπος",
      "COLUMN_DEPARTMENT": "Τμήμα",
      "COLUMN_EMPLOYMENT_ACTIVE": "Ενεργή απασχόληση",
      "COLUMN_JOIN_DATE": "Ημερομηνία συμμετοχής",
      "COLUMN_LEAVE_DATE": "Ημερομηνία αναχώρησης",
      "COLUMN_DOCUMENTS_VALIDITY": "Έγκυρα έγγραφα",
      "NO_MATCH": "Δεν μπορέσαμε να βρούμε υπαλλήλους για τα φίλτρα σας."
    },
    "SHIFT_TYPE_STREAM_BREAKDOWN": {
      "TITLE": "Ανάλυση τύπου αλλαγής ταχυτήτων",
      "FILTER_DATE": "Ημερομηνία",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_LAST_TWO_WEEKS": "Τελευταίες δύο εβδομάδες",
      "JUMP_TO_LAST_MONTH": "Τον περασμένο μήνα",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "FILTER_DATA_SOURCE": "Πηγή δεδομένων",
      "FILTER_USER": "Πρόσωπο",
      "FILTER_BEHAVIOUR": "Συμπεριφορά",
      "BEHAVIOUR_SHIFT_TYPE": "Τύπος αλλαγής ταχυτήτων",
      "BEHAVIOUR_STREAM": "Ροή",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE": "Τρέχουσα ιστοσελίδα",
      "COLUMN_DEPARTMENT": "Τρέχον τμήμα",
      "COLUMN_STAFF_GROUP": "Τρέχουσα ομάδα προσωπικού",
      "COLUMN_TOTAL": "Σύνολο",
      "ROW_TOTAL": "Σύνολο",
      "INFO_NO_DATA": "Δεν υπάρχουν εγγραφές που να ταιριάζουν με τα φίλτρα σας.",
      "INFO_NO_FILTER": "Επιλέξτε έναν τοποθεσία/τμήμα παραπάνω για να ξεκινήσετε.",
      "PAY_AMOUNT_TYPE_HOURLY": "Ωριαία τιμή",
      "PAY_AMOUNT_TYPE_DAILY": "Ημερήσια τιμή",
      "PAY_AMOUNT_TYPE_ANNUAL": "Ετήσιος μισθός",
      "DATA_SOURCE_SHIFTS": "Βάρδιες",
      "DATA_SOURCE_MATCHED": "Απολυμαντικό",
      "DATE_ERROR_DIFF": "Δεν μπορείτε να επιλέξετε περισσότερες από 6 εβδομάδες. Περιορίστε το εύρος ημερομηνιών σας.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_400": "Οι παράμετροί σας προκαλούν τη δημιουργία πάρα πολλών δεδομένων για μία μόνο αναφορά. Μειώστε το εύρος του αιτήματός σας και δοκιμάστε ξανά.",
      "STREAM_UNALLOCATED": "Μη κατανεμημένο",
      "FILTER_METRIC": "Αξία",
      "METRIC_WAGES": "Βασικό μισθολογικό κόστος",
      "METRIC_HOURS": "Ώρες πληρωμής"
    },
    "ABSENCE_DAYS_EXPORT": {
      "TITLE": "Εξαγωγή ημερών απουσίας",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "COLUMN_USER_ID": "Αναγνωριστικό εργαζομένου",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE": "Ιστότοπος",
      "COLUMN_DEPARTMENT": "Τμήμα",
      "COLUMN_STAFF_GROUP": "Ομάδα προσωπικού",
      "COLUMN_JOB_ROLE_NAME": "Ρόλος εργασίας",
      "COLUMN_DATE": "Ημερομηνία",
      "COLUMN_HOURS": "Ωρες",
      "COLUMN_HEADER_ID": "Αναγνωριστικό κεφαλίδας",
      "COLUMN_ABSENCE_TYPE": "Τύπος",
      "COLUMN_ABSENCE_TYPE_REASON": "Αιτιολογία",
      "COLUMN_STATUS": "Κατάσταση",
      "COLUMN_SCOPE": "Πεδίο εφαρμογής",
      "COLUMN_START_DATE": "Ημερομηνία έναρξης κεφαλίδας",
      "COLUMN_END_DATE": "Ημερομηνία λήξης κεφαλίδας",
      "COLUMN_START_TIME": "Ώρα έναρξης",
      "COLUMN_END_TIME": "Ώρα λήξης",
      "COLUMN_TOTAL_HOURS": "Ώρες κεφαλίδας",
      "COLUMN_TOTAL_DAYS": "Ημέρες κεφαλίδας",
      "COLUMN_CANCELLED": "Ακυρώθηκε",
      "FILTER_DATE": "Ημερομηνία",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_STATUS": "Κατάσταση",
      "FILTER_TYPE": "Τύπος",
      "FILTER_USER": "Πρόσωπο",
      "FILTER_TOGGLE_CANCELLED": "Συμπεριλάβετε ακυρωμένο",
      "FILTER_TOGGLE_USER_ACTIVE": "Ενεργοί υπάλληλοι",
      "BUTTON_EXPORT": "Εξαγωγή",
      "NO_MATCH": "Δεν μπορέσαμε να βρούμε απουσία που να ταιριάζει με τα φίλτρα σας."
    },
    "NEW_HIRE_TURNOVER": {
      "TITLE": "Νέος κύκλος εργασιών προσλήψεων",
      "FILTER_DATE": "Ημερομηνία συμμετοχής",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_LAST_TWO_WEEKS": "Τελευταίες δύο εβδομάδες",
      "JUMP_TO_LAST_MONTH": "Τον περασμένο μήνα",
      "FILTER_AGGREGATION": "Συγκέντρωση",
      "AGGREGATION_ENTITY_GROUP": "Τοποθεσία/τμήμα",
      "AGGREGATION_POSITION": "Ρόλος εργασίας",
      "AGGREGATION_STAFF_GROUP": "Ομάδα προσωπικού",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "DATE_ERROR_DIFF": "Δεν μπορείτε να επιλέξετε περισσότερο από 12 μήνες. Περιορίστε το εύρος ημερομηνιών σας.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "COLUMN_AGGREGATION_LABEL": "Όνομα",
      "COLUMN_JOINERS": "Ξυλουργοί",
      "COLUMN_LEAVERS": "Αποχωρούντες",
      "COLUMN_TURNOVER_RATE": "Ποσοστό κύκλου εργασιών",
      "DEPARTED_WITHIN": "Ημερομηνία αναχώρησης",
      "DAYS_LABEL": "μέσα σε {{count}} ημέρες",
      "LABEL_TOTAL": "Σύνολο",
      "LABEL_GRAND_TOTAL": "Γενικό Σύνολο",
      "FILTER_LEAVER_REASON": "Λόγος αποχώρησης"
    },
    "COST_CONTROL_RAW_DATA": {
      "TITLE": "Έλεγχος κόστους Εξαγωγή ακατέργαστων δεδομένων",
      "ERROR_500": "Δεν μπορέσαμε να εξάγουμε τα δεδομένα τότε. Παρακαλώ προσπαθήστε ξανά.",
      "BUTTON_EXPORT": "Εξαγωγή",
      "LOADING_TEXT": "Η εξαγωγή σας δημιουργείται.",
      "METRIC_HOURS": "Ωρες",
      "METRIC_WAGES": "Μισθοί",
      "METRIC_SALES": "ΠΩΛΗΣΕΙΣ",
      "METRIC_COVERS": "Καλύμματα",
      "SUBDIVISION_PREDICTION": "Προβλέψεις",
      "SUBDIVISION_FORECAST": "Προβλέψεις",
      "SUBDIVISION_ACTUAL": "Πραγματικά",
      "FILTER_METRICS": "Μετρήσεις",
      "FILTER_SUBDIVISIONS": "Υποδιαιρέσεις",
      "FILTER_ENTITY": "Τοποθεσία/τμήμα",
      "FILTER_ENTITY_BUTTON": "Τοποθεσία/τμήμα",
      "FILTER_DATE": "Ημερομηνία",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_LAST_TWO_WEEKS": "Τελευταίες δύο εβδομάδες",
      "JUMP_TO_LAST_MONTH": "Τον περασμένο μήνα",
      "CALCULATE_ACTUAL_CHECKBOX": "Υπολογίστε τις πραγματικές ώρες/μισθούς εν κινήσει, όπου δεν υπάρχουν δεδομένα αποσύνδεσης.",
      "DATE_ERROR_DIFF": "Δεν μπορείτε να επιλέξετε περισσότερο από 2 μήνες. Περιορίστε το εύρος ημερομηνιών σας.",
      "CALCULATE_ACTUAL_ERROR": "Οι πραγματικές ώρες/μισθοί δεν μπορούν να υπολογιστούν εν πτήσει για περισσότερες από μία εβδομάδα ή δεκαπέντε τοποθεσίες/τμήματα."
    },
    "ABSENCE_EXPORT": {
      "TITLE": "Εξαγωγή απουσίας",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_USER_ID": "Αναγνωριστικό εργαζομένου",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE": "Ιστότοπος",
      "COLUMN_STAFF_GROUP": "Ομάδα προσωπικού",
      "COLUMN_JOB_ROLE_NAME": "Ρόλος εργασίας",
      "COLUMN_DEPARTMENT": "Τμήμα",
      "COLUMN_ABSENCE_TYPE": "Τύπος",
      "COLUMN_ABSENCE_TYPE_REASON": "Αιτιολογία",
      "COLUMN_STATUS": "Κατάσταση",
      "COLUMN_SCOPE": "Πεδίο εφαρμογής",
      "COLUMN_START_DATE": "Ημερομηνία έναρξης",
      "COLUMN_END_DATE": "Ημερομηνία λήξης",
      "COLUMN_START_TIME": "Ώρα έναρξης",
      "COLUMN_END_TIME": "Ώρα λήξης",
      "COLUMN_TOTAL_HOURS": "Ωρες",
      "COLUMN_TOTAL_DAYS": "Ημέρες",
      "COLUMN_REQUEST_NAME": "Ζητήθηκε από",
      "COLUMN_REQUEST_DATE": "Ημερομηνία αιτήματος",
      "COLUMN_REQUEST_MESSAGE": "Αίτημα μηνύματος",
      "COLUMN_REVIEW_NAME": "Αναθεωρήθηκε από",
      "COLUMN_REVIEW_DATE": "Ημερομηνία αναθεώρησης",
      "COLUMN_REVIEW_MESSAGE": "Αναθεώρηση μηνύματος",
      "COLUMN_EDITED_NAME": "Τελευταία επεξεργασία από",
      "COLUMN_EDITED_DATE": "Τελευταία ημερομηνία επεξεργασίας",
      "COLUMN_CANCELLED": "Ακυρώθηκε",
      "COLUMN_CANCELLED_NAME": "Ακυρώθηκε από",
      "COLUMN_CANCELLED_DATE": "Ημερομηνία ακυρώσεως",
      "FILTER_DATE": "Ημερομηνία έναρξης/λήξης",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_STATUS": "Κατάσταση",
      "FILTER_TYPE": "Τύπος",
      "FILTER_USER": "Πρόσωπο",
      "FILTER_TOGGLE_CANCELLED": "Συμπεριλάβετε ακυρωμένο",
      "FILTER_TOGGLE_USER_ACTIVE": "Ενεργοί υπάλληλοι",
      "BUTTON_EXPORT": "Εξαγωγή",
      "NO_MATCH": "Δεν μπορέσαμε να βρούμε απουσία που να ταιριάζει με τα φίλτρα σας."
    },
    "ALLOWANCE_EXPORT": {
      "TITLE": "Εξαγωγή δικαιωμάτων",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_USER_ID": "Αναγνωριστικό εργαζομένου",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE": "Ιστότοπος",
      "COLUMN_DEPARTMENT": "Τμήμα",
      "COLUMN_ABSENCE_TYPE": "Τύπος απουσίας",
      "COLUMN_YEAR_STARTS": "Το έτος ξεκινά",
      "COLUMN_EFFECTIVE_DATE": "Ημερομηνία έναρξης ισχύος",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Χρονοδιάγραμμα δεδουλευμένων",
      "COLUMN_UNIT": "Μονάδα",
      "COLUMN_DEDUCTIBLE": "Εκπεστέο",
      "COLUMN_TOTAL_ACCRUED": "Σύνολο δεδουλευμένων",
      "COLUMN_OPENING_BALANCE": "Ισορροπία έναρξης",
      "COLUMN_BOOKED": "Έγινε κράτηση",
      "COLUMN_BOOKED_AND_ELAPSED": "Έγινε κράτηση και παρέλευση",
      "COLUMN_REMAINING": "Υπολειπόμενο",
      "COLUMN_ACCRUAL_ESTIMATE": "Εκτίμηση δεδουλευμένων",
      "COLUMN_CARRIED_OVER": "Μεταφέρθηκε",
      "COLUMN_OVERSPEND_LIMIT": "Υπερβολική δαπάνη",
      "COLUMN_TOTAL_TO_ACCRUE": "Μέγιστο προς συσσώρευση",
      "COLUMN_ACCRUAL_RATE": "Παράκαμψη δεδουλευμένου επιτοκίου",
      "COLUMN_ADDED_NAME": "Προστέθηκε από",
      "COLUMN_ADDED_DATE": "Ημερομηνία προστέθηκε",
      "COLUMN_EDITED_NAME": "Τελευταία επεξεργασία από",
      "COLUMN_EDITED_DATE": "Τελευταία ημερομηνία επεξεργασίας",
      "COLUMN_FINALISED": "Ολοκληρώθηκε",
      "COLUMN_FINALISED_REFERENCE_DATE": "Οριστική ημερομηνία αναφοράς",
      "COLUMN_FINALISED_EDITED_NAME": "Ολοκληρώθηκε από",
      "COLUMN_FINALISED_EDITED_DATE": "Ολοκληρώθηκε στις",
      "COLUMN_FINALISED_NOTES": "Οριστικές σημειώσεις",
      "COLUMN_PROJECT_TOTAL_ACCRUED": "Προβλέψτε το συσσωρευμένο κατά την κράτηση",
      "FILTER_DATE": "Ημερομηνία έναρξης ισχύος",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_TYPE": "Τύπος απουσίας",
      "FILTER_SCHEDULE_OF_ACCRUAL": "Χρονοδιάγραμμα δεδουλευμένων",
      "FILTER_USER": "Πρόσωπο",
      "FILTER_TOGGLE_USER_ACTIVE": "Ενεργοί υπάλληλοι",
      "BUTTON_EXPORT": "Εξαγωγή",
      "NO_MATCH": "Δεν μπορέσαμε να βρούμε επιτρεπόμενα όρια που να ταιριάζουν με τα φίλτρα σας."
    },
    "UNTAKEN_HOLIDAY_COST": {
      "TITLE": "Κόστος διακοπών που δεν έχουν αναληφθεί",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_400": "Το πραγματικό εύρος ημερομηνιών σας είναι πολύ ευρύ, περιορίστε το και δοκιμάστε ξανά.",
      "BUTTON_EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "FILTER_DATE": "Ημερομηνία έναρξης ισχύος του επιδόματος",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_TOGGLE_USER_ACTIVE": "Ενεργοί υπάλληλοι",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE": "Ιστότοπος",
      "COLUMN_DEPARTMENT": "Τμήμα",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Χρονοδιάγραμμα δεδουλευμένων",
      "COLUMN_UNIT": "Μονάδα",
      "COLUMN_DEDUCTIBLE": "Εκπεστέο",
      "COLUMN_TOTAL_ACCRUED": "Σύνολο δεδουλευμένων",
      "COLUMN_BOOKED": "Έγινε κράτηση",
      "COLUMN_BOOKED_AND_ELAPSED": "Έχει παρέλθει",
      "COLUMN_UNTAKEN": "Μη ληφθείσα",
      "COLUMN_PAY_AMOUNT": "Ποσό",
      "COLUMN_PAY_AMOUNT_TYPE": "Τύπος",
      "COLUMN_PAY_PROJECTED_COST": "Προβλεπόμενο κόστος",
      "COLUMN_HEADER_ALLOWANCE": "Επίδομα",
      "COLUMN_HEADER_PAY": "Πληρωμή",
      "LABEL_DEDUCTIBLE_YES": "Ναί",
      "LABEL_DEDUCTIBLE_NO": "Οχι",
      "TEXT_GRAND_TOTAL": "Γενικό Σύνολο"
    },
    "EMPLOYMENT_CHANGES": {
      "TITLE": "Αλλαγές στην απασχόληση",
      "MODEL_APPOINTMENT": "Ραντεβού",
      "MODEL_PAY": "Πληρωμή",
      "MODEL_EMPLOYEE": "Υπάλληλος",
      "PROPERTY_EMPLOYEE_FIRST_NAME": "Πρώτο όνομα",
      "PROPERTY_EMPLOYEE_LAST_NAME": "Επώνυμο",
      "PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET": "Μέσος όρος ωρών ρυθμίζεται χειροκίνητα",
      "PROPERTY_APPOINTMENT_ENTITY_GROUP": "Ιστότοπος",
      "PROPERTY_APPOINTMENT_ENTITY": "Τμήμα",
      "PROPERTY_APPOINTMENT_GROUP": "Ομάδα προσωπικού",
      "PROPERTY_APPOINTMENT_POSITION": "Ρόλος εργασίας",
      "PROPERTY_APPOINTMENT_COMPANY": "ΕΤΑΙΡΕΙΑ",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE": "Ελάχιστη υποχρέωση",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT": "Ελάχιστη υποχρέωση (μονάδα)",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD": "Ελάχιστη υποχρέωση (περίοδος)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE": "Ανώτατη υποχρέωση",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT": "Ανώτατη υποχρέωση (μονάδα)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD": "Ανώτατη υποχρέωση (περίοδος)",
      "PROPERTY_APPOINTMENT_LINE_MANAGER": "Διαχειριστής γραμμής",
      "PROPERTY_APPOINTMENT_PAYROLL_CALENDAR": "Ημερολόγιο μισθοδοσίας",
      "PROPERTY_PAY_AMOUNT": "Ποσό",
      "PROPERTY_PAY_AMOUNT_TYPE": "Τύπος ποσού",
      "PROPERTY_PAY_UPLIFT": "Αύξηση μισθών",
      "FILTER_DATE": "Ημερομηνία",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_LAST_TWO_WEEKS": "Τελευταίες δύο εβδομάδες",
      "JUMP_TO_LAST_MONTH": "Τον περασμένο μήνα",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_PROPERTY": "Ιδιοκτησία",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "DATE_ERROR_DIFF": "Δεν μπορείτε να επιλέξετε περισσότερο από 12 μήνες. Περιορίστε το εύρος ημερομηνιών σας.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE": "Ιστότοπος",
      "COLUMN_DEPARTMENT": "Τμήμα",
      "COLUMN_CHANGE": "Αλλαγή",
      "COLUMN_EFFECTIVE_DATE": "Αποτελεσματική",
      "COLUMN_PROPERTY": "Ιδιοκτησία",
      "COLUMN_NEW_VALUE": "Νέα αξία",
      "COLUMN_OLD_VALUE": "Παλιά αξία",
      "COLUMN_EDITED_BY": "Αλλαγή από",
      "COLUMN_EDITED_ON": "Αλλαγή ημερομηνίας"
    },
    "ANNIVERSARY": {
      "TITLE": "Επετείους",
      "FILTER_DATE": "Ημερομηνία",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_LAST_TWO_WEEKS": "Τελευταίες δύο εβδομάδες",
      "JUMP_TO_LAST_MONTH": "Τον περασμένο μήνα",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_TYPE": "Επέτειος",
      "TYPE_BIRTHDAY": "Γενέθλια",
      "TYPE_EMPLOYMENT": "Επέτειος εργασίας",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "DATE_ERROR_DIFF": "Δεν μπορείτε να επιλέξετε περισσότερο από 12 μήνες. Περιορίστε το εύρος ημερομηνιών σας.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE": "Ιστότοπος",
      "COLUMN_DEPARTMENT": "Τμήμα",
      "COLUMN_TYPE": "Επέτειος",
      "COLUMN_YEARS": "Χρόνια",
      "COLUMN_DATE": "Ημερομηνία"
    },
    "STAFF_TURNOVER": {
      "TITLE": "Κύκλος εργασιών προσωπικού",
      "FILTER_DATE": "Ημερομηνία",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_LAST_TWO_WEEKS": "Τελευταίες δύο εβδομάδες",
      "JUMP_TO_LAST_MONTH": "Τον περασμένο μήνα",
      "FILTER_AGGREGATION": "Συγκέντρωση",
      "AGGREGATION_ENTITY_GROUP": "Τοποθεσία/τμήμα",
      "AGGREGATION_POSITION": "Ρόλος εργασίας",
      "AGGREGATION_STAFF_GROUP": "Ομάδα προσωπικού",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "DATE_ERROR_DIFF": "Δεν μπορείτε να επιλέξετε περισσότερο από 12 μήνες. Περιορίστε το εύρος ημερομηνιών σας.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "COLUMN_AGGREGATION_LABEL": "Όνομα",
      "COLUMN_HEADCOUNT_START": "Αριθμός προσωπικού (έναρξη)",
      "COLUMN_HEADCOUNT_END": "Αριθμός προσωπικού (τέλος)",
      "COLUMN_JOINERS": "Ξυλουργοί",
      "COLUMN_REHIRES": "Επαναπροσλήψεις",
      "COLUMN_LEAVERS": "Αποχωρούντες",
      "COLUMN_TRANSFERS_IN": "Μεταφορές σε",
      "COLUMN_TRANSFERS_OUT": "Μεταφορές προς τα έξω",
      "COLUMN_ATTRITION_RATE": "Ποσοστό φθοράς",
      "COLUMN_AVERAGE_SERVICE": "Μέση υπηρεσία",
      "LABEL_TOTAL": "Σύνολο",
      "LABEL_GRAND_TOTAL": "Γενικό Σύνολο",
      "FILTER_LEAVER_REASON": "Λόγος αποχώρησης"
    },
    "DOCUMENT_SIGNATURES": {
      "TITLE": "Υπογραφή εγγράφων",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "INFO_NO_DATA": "Δεν υπάρχουν εγγραφές που να ταιριάζουν με τα φίλτρα σας.",
      "LABEL_METHOD_MANUAL": "Εγχειρίδιο",
      "LABEL_METHOD_ELECTRONIC": "Ηλεκτρονική",
      "LABEL_STATUS_PENDING": "Σε αναμονή",
      "LABEL_STATUS_REJECTED": "Απορρίφθηκε",
      "LABEL_STATUS_SIGNED": "Υπογεγραμμένο",
      "LABEL_STATUS_CANCELLED": "Ακυρώθηκε",
      "LABEL_RESPONSE_NONE": "Αναμονή απάντησης",
      "LABEL_RESPONSE_REJECTED": "Απορρίφθηκε",
      "LABEL_RESPONSE_SIGNED": "Υπογεγραμμένο",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_RECIPIENT_USER": "Παραλήπτης",
      "FILTER_ADDED_USER": "Αποστολέας",
      "FILTER_METHOD": "Μέθοδος υπογραφής",
      "FILTER_STATUS": "Κατάσταση",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "COLUMN_RECIPIENT": "Παραλήπτης",
      "COLUMN_EMPLOYER": "Αντισυμβαλλόμενος",
      "COLUMN_DOCUMENT": "Έγγραφο",
      "COLUMN_DOCUMENT_CATEGORY": "Κατηγορία προορισμού",
      "COLUMN_METHOD": "Μέθοδος",
      "COLUMN_STATUS": "Κατάσταση",
      "COLUMN_ADDED_USER": "Αποστολέας",
      "COLUMN_ADDED_DATE": "Ημερομηνία",
      "EMPLOYER_NOT_SET": "Δεν απαιτείται",
      "VIEW_MODAL": {
        "TITLE": "Έγγραφο προς υπογραφή",
        "LABEL_FILE": "Έγγραφο",
        "LABEL_EXPIRY": "Λήξη εγγράφου",
        "LABEL_TEMPLATE": "Πρότυπο που χρησιμοποιείται",
        "LABEL_CATEGORY": "Κατηγορία προορισμού",
        "LABEL_ADDED_BY": "Προστέθηκε από",
        "LABEL_METHOD": "Μέθοδος",
        "TEMPLATE_NOT_USED": "Κανένα",
        "EXPIRY_NOT_APPLICABLE": "Μη εφαρμόσιμος",
        "BUTTON_CLOSE": "Κλείσιμο",
        "HEADING_SIGNERS": "Υπογράφοντες",
        "EMPLOYEE": "Υπάλληλος",
        "EMPLOYER": "Εργοδότης",
        "COLUMN_NAME": "Όνομα",
        "COLUMN_ROLE": "Ρόλος",
        "COLUMN_STATUS": "Κατάσταση",
        "COLUMN_DATE": "Ημερομηνία",
        "LABEL_OPTIONS": "Επιλογές",
        "OPTION_REMINDER": "Στείλτε μια υπενθύμιση",
        "OPTION_CANCEL": "Ακύρωση εγγράφου",
        "OPTION_UPLOAD": "Ανεβάστε υπογεγραμμένο έγγραφο",
        "CANCEL_ERROR_500": "Δεν μπορούσαμε να ακυρώσουμε το έγγραφο τότε.",
        "CANCEL_SUCCESS": "Το έγγραφο ακυρώθηκε.",
        "REMINDER_ERROR_500": "Δεν μπορούσαμε να στείλουμε υπενθύμιση τότε.",
        "REMINDER_ERROR_400": "Μια υπενθύμιση έχει ήδη σταλεί πρόσφατα. Περιμένετε λίγο πριν στείλετε άλλη υπενθύμιση.",
        "REMINDER_SUCCESS": "Το κατάλαβα! Θα στείλουμε μια υπενθύμιση μέσω email τώρα.",
        "DOWNLOAD_ERROR_500": "Δεν μπορούσαμε να κατεβάσουμε το αρχείο τότε. Δοκιμάστε ξανά ή επικοινωνήστε εάν αυτό επιμένει.",
        "LABEL_DOWNLOAD": "Κατεβάστε",
        "OPTION_DOWNLOAD_ORIGINAL": "Κατεβάστε το πρωτότυπο",
        "OPTION_DOWNLOAD_SIGNED_COPY": "Κατεβάστε υπογεγραμμένο αντίγραφο"
      }
    },
    "WORKING_TIME_VIOLATIONS": {
      "TITLE": "Παραβιάσεις χρόνου εργασίας",
      "FILTER_DATE": "Ημερομηνία",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_LAST_TWO_WEEKS": "Τελευταίες δύο εβδομάδες",
      "JUMP_TO_LAST_MONTH": "Τον περασμένο μήνα",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "COLUMN_SITE_DEPARTMENT": "Τοποθεσία/τμήμα",
      "COLUMN_VIOLATION_HOURS": "Ωρες εργασίας",
      "COLUMN_VIOLATION_HOURS_TOOLTIP": "Ο αριθμός των περιστατικών όπου ένας εργαζόμενος υπερέβη τις 48 ώρες εργασίας (κατά μέσο όρο πάνω από 17 εβδομάδες).",
      "COLUMN_VIOLATION_DAYS_OFF": "Ημέρες ρεπό",
      "COLUMN_VIOLATION_DAYS_OFF_TOOLTIP": "Ο αριθμός των περιστατικών όπου ένας εργαζόμενος είχε λιγότερο από 2 ημέρες άδειας (σε περίοδο 2 εβδομάδων).",
      "COLUMN_VIOLATION_REST": "Ξεκούραση",
      "COLUMN_VIOLATION_REST_TOOLTIP": "Ο αριθμός των περιστατικών όπου ένας εργαζόμενος είχε λιγότερο από 11 ώρες ανάπαυσης μετά από μια εργάσιμη ημέρα.",
      "COLUMN_VIOLATION_U18_DAYS_OFF": "U18: Αργίες",
      "COLUMN_VIOLATION_U18_DAYS_OFF_TOOLTIP": "Ο αριθμός των περιστατικών όπου ένας εργαζόμενος ηλικίας κάτω των 18 ετών είχε λιγότερες από 2 συνεχόμενες ημέρες άδειας (σε μια δεδομένη εβδομάδα).",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH": "U18: Μεγάλη βάρδια",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH_TOOLTIP": "Ο αριθμός των περιστατικών όπου ένας εργαζόμενος ηλικίας κάτω των 18 ετών είχε βάρδια μεγαλύτερη των 8 ωρών.",
      "COLUMN_VIOLATION_U18_REST": "U18: Ξεκούραση",
      "COLUMN_VIOLATION_U18_REST_TOOLTIP": "Ο αριθμός των περιστατικών όπου ένας εργαζόμενος ηλικίας κάτω των 18 ετών είχε λιγότερο από 11 ώρες ανάπαυσης μετά από μια εργάσιμη ημέρα.",
      "COLUMN_TOTAL": "Σύνολο",
      "ROW_TOTAL": "Σύνολο",
      "ROW_GRAND_TOTAL": "Γενικό Σύνολο",
      "INFO_NO_FILTER": "Επιλέξτε έναν τοποθεσία/τμήμα παραπάνω για να ξεκινήσετε.",
      "DATE_ERROR_DIFF": "Δεν μπορείτε να επιλέξετε περισσότερο από 12 μήνες. Περιορίστε το εύρος ημερομηνιών σας.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "VIOLATION_TYPE_1_DESC": "Δεν μπορεί να υπερβεί τον μέσο όρο των 48 ωρών εργασίας σε διάστημα 17 εβδομάδων",
      "VIOLATION_TYPE_2_DESC": "Δεν μπορούν να έχουν λιγότερες από 2 ημέρες άδειας σε οποιαδήποτε περίοδο 2 εβδομάδων",
      "VIOLATION_TYPE_3_DESC": "Κάτω των 18 ετών: Πρέπει να έχουν 2 συνεχόμενες ημέρες άδειας την εβδομάδα",
      "VIOLATION_TYPE_4_DESC": "Κάτω των 18 ετών: Βάρδια μεγαλύτερη από 8 ώρες",
      "VIOLATION_TYPE_5_DESC": "Κάτω των 18 ετών: Λιγότερο από 12 ώρες ανάπαυσης μετά την εργάσιμη ημέρα",
      "VIOLATION_TYPE_6_DESC": "Λιγότερο από 11 ώρες ανάπαυσης μετά την εργάσιμη ημέρα",
      "VIOLATION_TYPE_1": "Πάρα πολλές ώρες εργασίας (κατά μέσο όρο)",
      "VIOLATION_TYPE_2": "Ανεπαρκείς ημέρες αργίας",
      "VIOLATION_TYPE_3": "Κάτω των 18 ετών: Ανεπαρκείς ημέρες αργίας",
      "VIOLATION_TYPE_4": "Κάτω των 18 ετών: Μετατόπιση πολύ",
      "VIOLATION_TYPE_5": "Κάτω των 18 ετών: Ανεπαρκής ανάπαυση μεταξύ βάρδιων",
      "VIOLATION_TYPE_6": "Ανεπαρκής ξεκούραση μεταξύ βάρδιων",
      "FILTER_VIOLATION_TYPE": "Τύπος παραβίασης",
      "FILTER_PER_PAGE": "Σειρές ανά σελίδα",
      "DETAIL_COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "DETAIL_COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "DETAIL_COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "DETAIL_COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "DETAIL_COLUMN_LAST_NAME": "Επώνυμο",
      "DETAIL_COLUMN_SITE": "Ιστότοπος",
      "DETAIL_COLUMN_DEPARTMENT": "Τμήμα",
      "DETAIL_COLUMN_VIOLATION": "Παραβίαση",
      "DETAIL_COLUMN_DATE_FROM": "Ημερομηνία από",
      "DETAIL_COLUMN_DATE_TO": "Ημερομηνία έως",
      "DETAIL_COLUMN_VALUE": "Αξία",
      "DETAIL_LABEL_WORKING_HOURS": "ώρες εργασίας",
      "DETAIL_LABEL_WORKING_DAYS": "εργάσιμες ημέρες",
      "DETAIL_LABEL_HOURS_REST": "ώρες ανάπαυσης",
      "DETAIL_INFO_NO_RECORDS": "Δεν έχετε παραβιάσεις του χρόνου εργασίας, κορυφαία δουλειά!",
      "DETAIL_INFO_EMPTY_FILTER_RESULT": "Δεν υπάρχουν παραβιάσεις που να ταιριάζουν με τα φίλτρα σας."
    },
    "CONTRACTUAL_OBLIGATION_SUMMARY": {
      "TITLE": "Περίληψη συμβατικής υποχρέωσης",
      "FILTER_DATE": "Ημερομηνία",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_LAST_TWO_WEEKS": "Τελευταίες δύο εβδομάδες",
      "JUMP_TO_LAST_MONTH": "Τον περασμένο μήνα",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "FILTER_DATA_SOURCE": "Πηγή δεδομένων",
      "FILTER_USER": "Πρόσωπο",
      "COLUMN_GROUP_TOTAL": "Σύνολο (πληρωμένο)",
      "COLUMN_GROUP_OBLIGATION": "Συμβατική υποχρέωση",
      "COLUMN_GROUP_ANALYSIS_META": "Αναλύθηκε πάνω",
      "COLUMN_GROUP_ANALYSIS": "Ανάλυση",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE": "Ιστότοπος",
      "COLUMN_DEPARTMENT": "Τμήμα",
      "COLUMN_DAYS_WORKED": "Ημέρες",
      "COLUMN_PAID_HOURS": "Ωρες",
      "COLUMN_AVERAGE_HOURS": "Μέσος όρος",
      "COLUMN_AVERAGE_HOURS_TOOLTIP": "Οι μέσες ώρες εργασίας ανά ημέρα.",
      "COLUMN_OBLIGATION_VALUE": "Αξία",
      "COLUMN_OBLIGATION_UNIT": "Μονάδα",
      "COLUMN_OBLIGATION_PERIOD": "Περίοδος",
      "COLUMN_ANALYSIS_START": "Έναρξη",
      "COLUMN_ANALYSIS_END": "Τέλος",
      "COLUMN_ANALYSIS_PERIODS": "# Περίοδοι",
      "COLUMN_ANALYSIS_PERIODS_TOOLTIP": "Ο αριθμός των εβδομάδων ή των μηνών που έχουν αναλυθεί.",
      "COLUMN_ANALYSIS_AVERAGE": "Μέσος όρος",
      "COLUMN_ANALYSIS_AVERAGE_TOOLTIP": "Ο μέσος αριθμός ωρών ή ημερών εργασίας.",
      "COLUMN_ANALYSIS_DIFFERENCE": "Διαφορά.",
      "COLUMN_ANALYSIS_DIFFERENCE_TOOLTIP": "Η διαφορά μεταξύ των ελάχιστων ωρών/ημερών που θα έπρεπε να είχαν εργαστεί (η συμβατική υποχρέωση πολλαπλασιασμένη με τον αριθμό των περιόδων) και του τι πραγματικά εργάστηκε (οι συνολικές ώρες ή ημέρες).",
      "INFO_NO_DATA": "Δεν υπάρχουν εγγραφές που να ταιριάζουν με τα φίλτρα σας.",
      "INFO_NO_FILTER": "Επιλέξτε έναν τοποθεσία/τμήμα παραπάνω για να ξεκινήσετε.",
      "PAY_AMOUNT_TYPE_HOURLY": "Ωριαία τιμή",
      "PAY_AMOUNT_TYPE_DAILY": "Ημερήσια τιμή",
      "PAY_AMOUNT_TYPE_ANNUAL": "Ετήσιος μισθός",
      "DATA_SOURCE_SHIFTS": "Βάρδιες",
      "DATA_SOURCE_ATTENDANCE": "Συμμετοχή",
      "DATA_SOURCE_MATCHED": "Απολυμαντικό",
      "CONTRACTUAL_UNIT_HOURS": "Ωρες",
      "CONTRACTUAL_UNIT_DAYS": "Ημέρες",
      "CONTRACTUAL_PERIOD_WEEK": "Εβδομάδα",
      "CONTRACTUAL_PERIOD_MONTH": "Μήνας",
      "CONTRACTUAL_PERIOD_WEEKLY": "Εβδομαδιαία",
      "CONTRACTUAL_PERIOD_MONTHLY": "Μηνιαία",
      "DATE_ERROR_DIFF": "Δεν μπορείτε να επιλέξετε περισσότερες από 14 εβδομάδες. Περιορίστε το εύρος ημερομηνιών σας.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά."
    },
    "LABOUR_BREAKDOWN": {
      "TITLE": "Κατανομή εργασίας",
      "FILE_EXPORT_TITLE": "Εξαγωγή αρχείου κατανομής εργασίας",
      "FILTER_DATE": "Ημερομηνία",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_LAST_TWO_WEEKS": "Τελευταίες δύο εβδομάδες",
      "JUMP_TO_LAST_MONTH": "Τον περασμένο μήνα",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "FILTER_DATA_SOURCE": "Πηγή δεδομένων",
      "FILTER_USER": "Πρόσωπο",
      "FILTER_BEHAVIOUR": "Συμπεριφορά",
      "BEHAVIOUR_COST_CONTROL": "Έλεγχος κόστους",
      "BEHAVIOUR_PAYROLL": "Μισθοδοσία",
      "FILTER_SHIFT_TYPE": "Τύπος αλλαγής ταχυτήτων",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE_APPOINTMENT": "Τοποθεσία (ραντεβού)",
      "COLUMN_DEPT_APPOINTMENT": "Τμήμα (διορισμός)",
      "COLUMN_EVENT_TYPE": "Τύπος συμβάντος",
      "COLUMN_SHIFT_TYPE": "Τύπος αλλαγής ταχυτήτων",
      "COLUMN_SITE_ATTRIBUTION": "Ιστότοπος (απόδοση)",
      "COLUMN_DEPT_ATTRIBUTION": "Τμήμα (απόδοση)",
      "COLUMN_START_TIME": "Έναρξη",
      "COLUMN_END_TIME": "Τελειώστε",
      "COLUMN_PAID_HOURS": "Ώρες πληρωμής",
      "COLUMN_UNPAID_HOURS": "Μη αμειβόμενες ώρες",
      "COLUMN_BASE_PAY": "Βασική αμοιβή",
      "COLUMN_ROLLED_UP_HOLIDAY": "Δεσμευμένες διακοπές",
      "COLUMN_TAXES": "Φόροι",
      "COLUMN_WAGE_UPLIFT": "Αύξηση μισθών",
      "COLUMN_TOTAL_COST": "Συνολικό κόστος",
      "INFO_NO_DATA": "Δεν υπάρχουν εγγραφές που να ταιριάζουν με τα φίλτρα σας.",
      "INFO_NO_FILTER": "Επιλέξτε έναν τοποθεσία/τμήμα παραπάνω για να ξεκινήσετε.",
      "PAY_AMOUNT_TYPE_HOURLY": "Ωριαία τιμή",
      "PAY_AMOUNT_TYPE_DAILY": "Ημερήσια τιμή",
      "PAY_AMOUNT_TYPE_ANNUAL": "Ετήσιος μισθός",
      "EVENT_TYPE_SHIFT": "Μετατόπιση",
      "EVENT_TYPE_ABSENCE": "Απουσία",
      "EVENT_TYPE_SALARY": "Μισθός",
      "EVENT_TYPE_TAXES": "Φόροι",
      "DATA_SOURCE_SHIFTS": "Βάρδιες",
      "DATA_SOURCE_ATTENDANCE": "Συμμετοχή",
      "DATA_SOURCE_MATCHED": "Απολυμαντικό",
      "DATE_ERROR_DIFF": "Δεν μπορείτε να επιλέξετε περισσότερες από 14 εβδομάδες. Περιορίστε το εύρος ημερομηνιών σας.",
      "DATE_WARNING_WEEKS": "Έχετε επιλέξει ένα εύρος ημερομηνιών που δεν ευθυγραμμίζεται με τις ημερολογιακές εβδομάδες. Ορισμένοι φόροι εργοδότη υπολογίζονται χρησιμοποιώντας εβδομαδιαία όρια, επομένως οι φόροι που εμφανίζονται εδώ μπορεί να είναι τεχνητά χαμηλότεροι από την πραγματικότητα. Η βασική αμοιβή και οι δεδουλευμένες διακοπές παραμένουν σωστές, αλλά ευθυγραμμίστε τις ημερομηνίες σας με τις ημερολογιακές εβδομάδες για σωστούς φόρους και συνολικό κόστος.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_400": "Οι παράμετροί σας προκαλούν τη δημιουργία πάρα πολλών δεδομένων για μία μόνο αναφορά. Μειώστε το εύρος του αιτήματός σας και δοκιμάστε ξανά."
    },
    "LABOUR_BREAKDOWN_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Δημιουργήστε και στείλτε αναφορά μέσω email.",
      "REPORT_GENERATED": "Η αναφορά σας δημιουργείται και θα σας αποσταλεί μέσω ηλεκτρονικού ταχυδρομείου όταν ολοκληρωθεί."
    },
    "PAYROLL_EXPORT": {
      "TITLE": "Εξαγωγή μισθοδοσίας",
      "FILTER_DATE": "Ημερομηνία",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_LAST_TWO_WEEKS": "Τελευταίες δύο εβδομάδες",
      "JUMP_TO_LAST_MONTH": "Τον περασμένο μήνα",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PAYROLL_SOFTWARE": "Λογισμικό μισθοδοσίας",
      "EXPORT_PRINT": "Εκτύπωση",
      "FILTER_AGGREGATION": "Τμηματοποίηση",
      "FILTER_ABSENCE_TYPES": "Τύποι απουσίας",
      "FILTER_PAY_ELEMENT_TYPES": "Τύποι στοιχείων πληρωμής",
      "FILTER_USER": "Πρόσωπο",
      "FILTER_COMPANY": "Εταιρία",
      "TOGGLE_SHOW_ROLLED_UP_HOLIDAY": "Εμφάνιση συγκεντρωτικών διακοπών",
      "COLUMN_ID": "ΤΑΥΤΌΤΗΤΑ",
      "COLUMN_HR_ID": "ΑΝΑΓΝΩΡΙΣΤΙΚΌ HR",
      "COLUMN_PAYROLL_ID": "Ταυτότητα μισθοδοσίας",
      "COLUMN_FIRST_NAME": "Πρώτο όνομα",
      "COLUMN_LAST_NAME": "Επώνυμο",
      "COLUMN_SITE_ATTRIBUTION": "Ιστότοπος (απόδοση)",
      "COLUMN_DEPT_ATTRIBUTION": "Τμήμα (απόδοση)",
      "COLUMN_SITE_APPOINTMENT": "Τοποθεσία (ραντεβού)",
      "COLUMN_DEPT_APPOINTMENT": "Τμήμα (διορισμός)",
      "COLUMN_GROUP": "Ομάδα",
      "COLUMN_POSITION": "Ρόλος εργασίας",
      "COLUMN_DAYS_WORKED": "Ημέρες εργασίας",
      "COLUMN_PAY_RATE": "Ποσοστό πληρωμής",
      "COLUMN_AMOUNT_TYPE": "Τύπος ποσού",
      "COLUMN_PAY_TYPE": "Τύπος πληρωμής",
      "COLUMN_PAID_SHIFT_HOURS": "Ώρες βάρδιας επί πληρωμή",
      "COLUMN_UNPAID_SHIFT_HOURS": "Ώρες βάρδιας χωρίς αμοιβή",
      "COLUMN_BASE_PAY": "Βασική αμοιβή",
      "COLUMN_ROLLED_UP_HOLIDAY": "Συνδυασμένες διακοπές",
      "COLUMN_ABSENCE_DAYS": "{{label}} ημέρες",
      "COLUMN_ABSENCE_HOURS": "{{label}} ώρες",
      "INFO_NO_DATA": "Δεν υπάρχουν εγγραφές που να ταιριάζουν με τα φίλτρα σας. Έχετε αποσυνδεθεί; Μην ξεχνάτε ότι αυτή η αναφορά χρησιμοποιεί μόνο δεδομένα που έχουν αποσυνδεθεί.",
      "INFO_NO_FILTER": "Επιλέξτε έναν τοποθεσία/τμήμα παραπάνω για να ξεκινήσετε.",
      "PAY_AMOUNT_TYPE_HOURLY": "Ωριαία τιμή",
      "PAY_AMOUNT_TYPE_DAILY": "Ημερήσια τιμή",
      "PAY_AMOUNT_TYPE_ANNUAL": "Ετήσιος μισθός",
      "AGGREGATION_OPTION_ATTRIBUTION": "Ο τόπος/το τμήμα στο οποίο αποδόθηκαν οι ώρες",
      "AGGREGATION_OPTION_APPOINTMENT": "Η τοποθεσία/το τμήμα στο οποίο βρισκόταν το άτομο εκείνη τη στιγμή",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "ABSENCE_TYPE_DELETED_SUFFIX": "Διαγράφηκε",
      "DATE_ERROR_MIN": "Παρακαλούμε επιλέξτε μια ημερομηνία μετά τις 12 Δεκεμβρίου 2018.",
      "DATE_ERROR_DIFF": "Δεν μπορείτε να επιλέξετε περισσότερο από 6 μήνες. Περιορίστε το εύρος ημερομηνιών σας.",
      "EXPORT_MODAL": {
        "TITLE": "Εξαγωγή σε λογισμικό μισθοδοσίας",
        "LABEL_EXPORT_TO": "Εξαγωγή σε",
        "BUTTON_DOWNLOAD": "Κατεβάστε",
        "BUTTON_CLOSE": "Κλείσιμο",
        "SUCCESS": "Η λήψη της εξαγωγής σας έγινε επιτυχής.",
        "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά."
      },
      "SIGN_OFF_STATUS_COMPLETE": "Πλήρως υπογεγραμμένο",
      "SIGN_OFF_STATUS_NONE": "Δεν έχει υπογραφεί",
      "SIGN_OFF_STATUS_PARTIAL": "Μερικώς υπογεγραμμένο",
      "SIGN_OFF_STATUS_DRAWER": {
        "TITLE": "Κατάσταση αποσύνδεσης",
        "BACK_TO_DATES": "Επιστροφή στις ημερομηνίες"
      },
      "FILTER_PAYROLL_CALENDAR": "Ημερολόγιο μισθοδοσίας",
      "FILTER_PAY_RUN": "Τρέξιμο πληρωμής"
    },
    "ROTA_PROGRESS": {
      "TITLE": "Πρόοδος τροχού",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_NEXT_WEEK": "Την επόμενη εβδομάδα",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "LIFECYCLE_DRAFT": "Σχέδιο",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Επισημαίνεται για έγκριση",
      "LIFECYCLE_PUBLISHED": "Δημοσιεύθηκε",
      "INCLUDE_NO_DRAFT": "Εμφάνιση ιστότοπων χωρίς προσχέδιο",
      "NO_DRAFT": "Χωρίς προσχέδιο",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "COLUMN_SITE": "Τοποθεσία/τμήμα",
      "COLUMN_LIFECYCLE": "Κατάσταση",
      "COLUMN_SCHEDULED_HOURS": "Προγραμματισμένες ώρες",
      "COLUMN_SCHEDULED_WAGES": "Προγραμματισμένοι μισθοί",
      "COLUMN_FORECAST_SALES": "Πρόβλεψη πωλήσεων",
      "COLUMN_WAGE_PERCENTAGE": "Μισθός%",
      "ROW_GRAND_TOTAL": "Γενικό Σύνολο",
      "TOOLTIP_BUDGET": "Προϋπολογισμός",
      "INFO_NO_DATA": "Δεν υπάρχουν τοποθεσίες/τμήματα που να ταιριάζουν με τα φίλτρα σας.",
      "INFO_NO_FILTER": "Επιλέξτε έναν τοποθεσία/τμήμα παραπάνω για να ξεκινήσετε.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά."
    },
    "SIGN_OFF_PROGRESS": {
      "TITLE": "Πρόοδος υπογραφής",
      "JUMP_TO": "Μετάβαση σε",
      "JUMP_TO_LAST_WEEK": "Την περασμένη εβδομάδα",
      "JUMP_TO_THIS_WEEK": "Αυτή την εβδομάδα",
      "JUMP_TO_NEXT_WEEK": "Την επόμενη εβδομάδα",
      "FILTER_SITE": "Τοποθεσία/τμήμα",
      "FILTER_STATUS": "Κατάσταση υπογραφής",
      "STATUS_NO_ROTA": "Χωρίς τροχό",
      "STATUS_INCOMPLETE": "Ελλιπής",
      "STATUS_REPORTING_DATE": "Ημερομηνία αναφοράς",
      "STATUS_COMPLETE": "Ολοκληρώθηκε",
      "STATUS_TOOLTIP_NO_ROTA": "Δεν υπάρχει δημοσιευμένη λίστα αυτή την εβδομάδα.",
      "STATUS_TOOLTIP_INCOMPLETE": "Η υπογραφή δεν ολοκληρώθηκε για κάθε μέρα αυτήν την εβδομάδα.",
      "STATUS_TOOLTIP_REPORTING_DATE": "Η υπογραφή έχει ολοκληρωθεί για τις ημέρες έως και την ημερομηνία αναφοράς σας.",
      "STATUS_TOOLTIP_COMPLETE": "Η υπογραφή έχει ολοκληρωθεί για κάθε μέρα αυτήν την εβδομάδα.",
      "TOGGLE_COMPARAND": "Συγκρίνετε",
      "TOGGLE_COMPARAND_FORECAST": "Πρόβλεψη",
      "TOGGLE_COMPARAND_BUDGET": "Προϋπολογισμός",
      "EXPORT": "Εξαγωγή",
      "EXPORT_PRINT": "Εκτύπωση",
      "COLUMN_WAGES": "Μισθοί",
      "COLUMN_SALES": "ΠΩΛΗΣΕΙΣ",
      "COLUMN_WAGE_PERCENTAGE": "Μισθός%",
      "COLUMN_HOURS": "Ωρες",
      "COLUMN_SITE": "Τοποθεσία/τμήμα",
      "COLUMN_STATUS": "Κατάσταση",
      "COLUMN_FORECAST": "Πρόβλεψη",
      "COLUMN_BUDGET": "Προϋπολογισμός",
      "COLUMN_ACTUAL": "Πραγματική",
      "ROW_GRAND_TOTAL": "Γενικό Σύνολο",
      "INFO_NO_DATA": "Δεν υπάρχουν τοποθεσίες/τμήματα που να ταιριάζουν με τα φίλτρα σας.",
      "INFO_NO_FILTER": "Επιλέξτε έναν τοποθεσία/τμήμα παραπάνω για να ξεκινήσετε.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά σας τότε. Παρακαλώ προσπαθήστε ξανά.",
      "STATUS_MODAL": {
        "REVERSE_CONFIRM": "Είστε βέβαιοι ότι θέλετε να αντιστρέψετε αυτήν την υπογραφή;\n\nΑυτό θα ξεκλειδώσει τις βάρδιες και τα αρχεία παρακολούθησης σε αυτές τις ημερομηνίες, ώστε να μπορούν να γίνουν αλλαγές.",
        "REVERSE_SUCCESS": "Η υπογραφή αντιστράφηκε επιτυχώς.",
        "REVERSE_ERROR": "Δεν μπορούσαμε να αντιστρέψουμε την υπογραφή τότε. Παρακαλώ προσπαθήστε ξανά.",
        "TITLE": "Κατάσταση υπογραφής",
        "COLUMN_DATE": "Ημερομηνία",
        "COLUMN_STATUS": "Κατάσταση",
        "COLUMN_OPTIONS": "Επιλογές",
        "STATUS_NO_SIGN_OFF": "Καμία υπογραφή",
        "STATUS_SIGN_OFF": "Αποσυνδεδεμένος",
        "BUTTON_REVERSE_DAY": "Αντίστροφη",
        "BUTTON_REVERSE_WEEK": "Αντιστρέψτε ολόκληρη την εβδομάδα",
        "BUTTON_CLOSE": "Κλείσιμο"
      }
    },
    "TRONC_STATUS": {
      "TITLE": "Κατάσταση Tronc",
      "LIFECYCLE_DRAFT": "Σχέδιο",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Επισημαίνεται για έγκριση",
      "LIFECYCLE_LOCKED": "Κλειδωμένο",
      "INCLUDE_NO_HEADER": "Εμφάνιση ιστότοπων χωρίς προσχέδιο",
      "NO_HEADER": "Χωρίς προσχέδιο",
      "COLUMN_SITE": "Τοποθεσία/τμήμα",
      "COLUMN_LIFECYCLE": "Κατάσταση",
      "COLUMN_RECEIVED": "Σύνολο που ελήφθη",
      "COLUMN_PAID_OUT": "Σύνολο καταβληθέντων",
      "COLUMN_EDITED_WHO": "Επεξεργασία από",
      "COLUMN_EDITED_WHEN": "Πότε",
      "INFO_NO_DATA": "Δεν υπάρχουν ιστότοποίες/τμήματα Tronc που να ταιριάζουν με τα φίλτρα σας.",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε τον Τρόνκ τότε. Παρακαλώ προσπαθήστε ξανά."
    },
    "CURRENTLY_CLOCKED_IN": {
      "TITLE": "Άτομα που βρίσκονται αυτή τη στιγμή",
      "NAME": "Όνομα",
      "SITE": "Τοποθεσία/τμήμα",
      "POSITION": "Ρόλος εργασίας",
      "CLOCK_IN_TIME": "Ώρα χρονομέτρησης",
      "NOBODY_CLOCKED_IN": "Δεν υπάρχει κανένας προσκεκλημένος αυτή τη στιγμή!",
      "ERROR_500": "Δεν μπορέσαμε να φορτώσουμε την αναφορά εκείνη τη στιγμή. Παρακαλώ προσπαθήστε ξανά."
    },
    "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Δημιουργία και αποστολή αναφοράς μέσω email.",
      "REPORT_GENERATED": "Η αναφορά σας δημιουργείται και θα σας αποσταλεί με email όταν ολοκληρωθεί.",
      "FILTER_DATE": "Ημερομηνία",
      "FILTER_ENTITY": "Τοποθεσία/Τμήμα",
      "FILTER_PAY_TYPES": "Τύποι πληρωμής",
      "FILTER_DATA_SOURCE": "Πηγή δεδομένων",
      "FILTER_USER": "Άτομο",
      "FILTER_EVENT_TYPES": "Τύποι συμβάντων",
      "FILTER_BEHAVIOUR": "Συμπεριφορά",
      "FILTER_SHIFT_TYPE": "Τύπος βάρδιας",
    },
    "PAYROLL_EXPORT_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Δημιουργία και αποστολή αναφοράς μέσω email.",
      "REPORT_GENERATED": "Η αναφορά σας δημιουργείται και θα σας αποσταλεί με email όταν ολοκληρωθεί.",
      "FILTER_PAYROLL_CALENDAR": "Ημερολόγιο μισθοδοσίας",
      "FILTER_PAY_RUN": "Εκτέλεση μισθοδοσίας",
      "FILTER_DATE": "Ημερομηνία",
      "FILTER_ENTITY": "Τοποθεσία/Τμήμα",
      "FILTER_AGGREGATION_OPTIONS": "Αγωγή",
      "FILTER_USER": "Άτομο",
      "FILTER_ABSENCE_TYPES": "Τύποι απουσιών",
      "FILTER_PAY_ELEMENT_TYPES": "Τύποι στοιχείων πληρωμής",
      "FILTER_PAY_AMOUNT_TYPE": "Τύπος ποσότητας",
      "FILTER_ROLLED_UP_HOLIDAY": "Συνολικές διακοπές",
      "FILTER_COMPANY": "Εταιρία",
    },
  },
  "ROTA_MANAGEMENT": {
    "DASHBOARD": {
      "INDEX": {
        "TITLE": "Πίνακας ελέγχου",
        "CHECKLIST": "Λίστα ελέγχου Rota",
        "LABOUR_SPEND": "Δαπάνες εργασίας"
      },
      "LABOUR_SPEND_TAB": {
        "TITLE": "Επισκόπηση εργασίας",
        "SHOW_ACTUAL_TIP": "Για τις ημέρες αυτής της εβδομάδας που δεν έχουν παρέλθει ακόμη, προβάλετε τις προβλέψεις πωλήσεων και των προγραμματισμένων μισθών/ωρών στα πραγματικά σύνολα",
        "SHOW_ACTUAL_TEXT": "Πραγματική πρόβλεψη έργου",
        "FORECAST": "Πρόβλεψη",
        "ACTUAL": "Πραγματική",
        "BUDGET": "Προϋπολογισμός",
        "SALES": "ΠΩΛΗΣΕΙΣ",
        "WAGES": "Μισθοί",
        "HOURS": "Ωρες",
        "WAGE_PERCENT": "Μισθός%",
        "LABOUR_COMP": "Σύγκριση εργασίας",
        "ACTUAL_PROJECTED": "Πραγματική (προβλεπόμενη)",
        "TODAYS_VS_SCHEDULED": "Η σημερινή συμμετοχή έναντι προγραμματισμένης"
      },
      "WTD_VIOLATIONS_MODAL": {
        "TITLE": "Παραβιάσεις χρόνου εργασίας",
        "BODY": "Αυτές είναι οι περιπτώσεις όπου διαπιστώσαμε ότι τα προγράμματά σας παραβιάζουν τους κανονισμούς για το χρόνο εργασίας.",
        "TO": "να ",
        "ALL": "Όλες οι παραβιάσεις",
        "TOO_MANY": "Πάρα πολλές ώρες εργασίας (κατά μέσο όρο)",
        "INSUF_DAYS": "Ανεπαρκείς ημέρες αργίας",
        "INSUF_REST": "Ανεπαρκής ξεκούραση μεταξύ βάρδιων",
        "UNDER_18_DAYS": "Κάτω των 18 ετών: Ανεπαρκείς ημέρες αργίας",
        "UNDER_18_LONG": "Κάτω των 18 ετών: Μετατόπιση πολύ",
        "UNDER_18_REST": "Κάτω των 18 ετών: Ανεπαρκής ανάπαυση μεταξύ βάρδιων",
        "STAFF_MEMBER": "Μέλος του προσωπικού",
        "VIOLATION": "Παραβίαση",
        "VALUE": "Αξία",
        "NO_VIOLATIONS": "Δεν έχετε παραβιάσεις του χρόνου εργασίας, κορυφαία δουλειά!",
        "NO_FILTER_VIOLATIONS": "Δεν υπάρχουν παραβιάσεις που να ταιριάζουν με τα φίλτρα σας.",
        "AVE_48_OVER_17": "Δεν μπορεί να υπερβεί τον μέσο όρο των 48 ωρών εργασίας σε διάστημα 17 εβδομάδων",
        "LESS_2_DAYS_2_WEEKS": "Δεν μπορούν να έχουν λιγότερες από 2 ημέρες άδειας σε οποιαδήποτε περίοδο 2 εβδομάδων",
        "UNDER_18_2_PER_WEEK": "Κάτω των 18 ετών: Πρέπει να έχουν 2 συνεχόμενες ημέρες άδειας την εβδομάδα",
        "UNDER_18_LONGER_8": "Κάτω των 18 ετών: Βάρδια μεγαλύτερη από 8 ώρες",
        "UNDER_18_LESS_12_REST": "Κάτω των 18 ετών: Λιγότερο από 12 ώρες ανάπαυσης μετά την εργάσιμη ημέρα",
        "LESS_11_WEEK": "Λιγότερο από 11 ώρες ανάπαυσης μετά την εργάσιμη ημέρα",
        "WORKING_HOURS": " ώρες εργασίας",
        "WORKING_DAYS": " εργάσιμες ημέρες",
        "HOURS_REST": " ώρες ανάπαυσης",
        "RECALC": "Επανυπολογίστε",
        "LOADING": "Φόρτωση...",
        "CLOSE": "Κλείσιμο"
      }
    },
    "LOCATIONS": {
      "ADD_LOCATION_MODAL": {
        "NEW_SHIFT_LOCATION": "Νέα ζώνη συμμετοχής",
        "TYPE": "Τύπος",
        "NEW_LOCATION": "Νέα ζώνη",
        "CHANGE_EXISTING_LOCATION": "Αλλαγή υπάρχουσας ζώνης",
        "EXISTING_LOCATION": "Υφιστάμενη ζώνη",
        "NAME": "Όνομα",
        "DETAILS": "Λεπτομέρειες",
        "HELP": "Το Rotaready θυμάται την ακριβή θέση του δείκτη στο χάρτη, οπότε αυτές οι λεπτομέρειες είναι μόνο για την αναφορά σας. Μη διστάσετε να συμπεριλάβετε μερικές σημειώσεις ή μια πλήρη διεύθυνση.",
        "SAVING": "Εξοικονόμηση...",
        "SAVE": "Αποθήκευση",
        "CANCEL": "Ακύρωση",
        "ERROR_ENTER_NAME": "Εισαγάγετε ένα όνομα για αυτήν τη ζώνη.",
        "ERROR_UPDATE": "Προς το παρόν δεν είναι δυνατή η ενημέρωση αυτής της ζώνης. Παρακαλώ προσπαθήστε ξανά.",
        "ERROR_ADD": "Προς το παρόν δεν είναι δυνατή η προσθήκη αυτής της ζώνης. Παρακαλώ προσπαθήστε ξανά."
      },
      "EDIT_LOCATION_MODAL": {
        "EDIT_LOCATION": "Επεξεργασία ζώνης",
        "HIERARCHY": "Ιεραρχία",
        "MASTER_LOCATION": "Κύρια ζώνη",
        "SUB_LOCATION": "Υποζώνη",
        "HELP_HIERARCHY": "Το Rotaready σάς επιτρέπει να δημιουργήσετε μια ιεραρχία ζωνών. Μια υποζώνη μπορεί να τοποθετηθεί κάτω από οποιαδήποτε άλλη ζώνη.",
        "PARENT_LOCATION": "Γονική ζώνη",
        "NAME": "Όνομα",
        "DETAILS": "Λεπτομέρειες",
        "HELP_DETAILS": "Το Rotaready θυμάται την ακριβή θέση του δείκτη στο χάρτη, οπότε αυτές οι λεπτομέρειες είναι μόνο για την αναφορά σας. Μη διστάσετε να συμπεριλάβετε μερικές σημειώσεις ή μια πλήρη διεύθυνση.",
        "ATTENDANCE": "Συμμετοχή",
        "ALLOW_CLOCKING": "Επιτρέψτε στο προσωπικό να παρακολουθεί και να παρακολουθεί εδώ",
        "STAFF_WORKING": "Μόνο όταν το προσωπικό εργάζεται σε βάρδια που σημειώνεται με αυτήν τη ζώνη",
        "HELP_CLOCKING": "Αυτές οι ρυθμίσεις επηρεάζουν το εάν το προσωπικό μπορεί να κάνει ρολόι και να κάνει ρολόι χρησιμοποιώντας την εφαρμογή Rotaready για κινητά.",
        "ATTENDANCE_SENSITIVITY": "Ευαισθησία συμμετοχής",
        "METRES": "μέτρα",
        "HELP_CLOSE_LOCATION": "Αυτό καθορίζει πόσο κοντά πρέπει να είναι κάποιος στη ζώνη προτού η εφαρμογή Rotaready για κινητά του επιτρέψει να μπει ή να βγει. Λιγότερο από 50 μέτρα δεν συνιστάται, καθώς τα άτομα με αδύναμο σήμα δεν θα μπορούν να κάνουν ρολόι.",
        "MIN_OCCUPANCY": "Ελάχιστη χωρητικότητα",
        "HELP_MIN_OCCUPANCY": "Κάθε φορά που κάποιος είναι σε βάρδια, το Rotaready θα σας ειδοποιήσει εάν ο συνολικός αριθμός ατόμων που βρίσκονται επίσης σε βάρδια πέσει κάτω από αυτόν τον αριθμό.",
        "MAX_OCCUPANCY": "Μέγιστη χωρητικότητα",
        "HELP_MAX_OCCUPANCY": "Εάν παρέχεται, το Rotaready δεν θα σας επιτρέψει να προγραμματίσετε περισσότερο προσωπικό εδώ (ανά πάσα στιγμή) από τη μέγιστη χωρητικότητα.",
        "TIP_OPENING": "Καθορίστε ώρες λειτουργίας ώστε το Rotaready να σας εμποδίζει να προγραμματίσετε βάρδιες σε αυτήν τη ζώνη όταν είναι κλειστή.",
        "TIP_SUB_LOC": "Δεδομένου ότι πρόκειται για υποζώνη, εάν η μητρική της ζώνη είναι κλειστή ανά πάσα στιγμή, αυτή η υποζώνη θα θεωρείται επίσης κλειστή.",
        "REGULAR_OPENING_TIMES": "Τακτικές ώρες λειτουργίας",
        "OPEN_247": "Αυτή η ζώνη είναι ανοιχτή 24/7.",
        "TO": "να",
        "SPECIAL_PERIODS": "Ειδικές περίοδοι",
        "NO_SPECIAL_PERIODS": "Δεν υπάρχουν ειδικές περίοδοι.",
        "OPEN": "Ανοιχτό",
        "CLOSED": "Κλειστό",
        "ADD_SPECIAL_PERIOD": "Προσθήκη ειδικής περιόδου",
        "REASON": "Αιτιολογία",
        "REASON_PLACEHOLDER": "π.χ. Καλοκαιρινή ανακαίνιση",
        "FROM": "Από",
        "TO_CAPS": "Προς",
        "ADD": "Προσθήκη",
        "DELETE": "Διαγραφή",
        "SAVING": "Εξοικονόμηση...",
        "SAVE": "Αποθήκευση",
        "CANCEL": "Ακύρωση",
        "ALERT_NAME": "Βεβαιωθείτε ότι έχετε παράσχει ένα έγκυρο όνομα για τη ζώνη συμμετοχής.",
        "ALERT_PARENT": "Για να είναι μια υποζώνη, πρέπει να επιλέξετε μια γονική ζώνη.",
        "ALERT_VALID_PARENT": "Επιλέξτε μια έγκυρη γονική ζώνη.",
        "ALERT_PARENT_LOCATION": "Αυτή η ζώνη συμμετοχής και η γονική ζώνη που έχετε επιλέξει πρέπει να ανήκουν και οι δύο στον ίδιο Ιστότοπο (ή και οι δύο να είναι σε ολόκληρο τον Οργανισμό).",
        "ALERT_MINIMUM": "Δεν μπορείτε να έχετε ελάχιστο επίπεδο πληρότητας υψηλότερο από το μέγιστο επίπεδο πληρότητας.",
        "ERROR_UPDATE": "Δεν μπορούσαμε να ενημερώσουμε τη ζώνη τότε. Παρακαλώ προσπαθήστε ξανά.",
        "ALERT_SUB_CONFIRM": "Αυτή η ζώνη εξακολουθεί να έχει υποζώνες. Θα διαγραφούν επίσης.\n\nΕίσαι σίγουρη ότι θέλεις να προχωρήσεις;",
        "ALERT_CONFIRM": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν τη ζώνη;",
        "ERROR_DELETE": "Δεν μπορέσαμε να διαγράψουμε τη ζώνη τότε. Παρακαλώ προσπαθήστε ξανά!"
      },
      "INDEX": {
        "SHIFT_LOCATIONS": "Ζώνες συμμετοχής",
        "TIP_FIND": "Για να προσθέσετε μια νέα ζώνη ή για να αλλάξετε μια υπάρχουσα ζώνη, πρέπει πρώτα να την βρείτε με το χάρτη. Αναζητήστε οποιαδήποτε διεύθυνση ή ταχυδρομικό κώδικα και, στη συνέχεια, σύρετε τον πράσινο δείκτη για να τον επανατοποθετήσετε.",
        "PLACEHOLDER_SEARCH": "Αναζήτηση για προσθήκη νέας ζώνης",
        "HELP_NO_LOCATIONS": "Δεν έχετε υπάρχουσες ζώνες. Αναζητήστε τον χάρτη για να προσθέσετε έναν.",
        "HELP_NO_RESULTS": "Δεν υπάρχουν ζώνες παρουσίας που να ταιριάζουν με τους όρους αναζήτησής σας.",
        "TOOLTIP_SHOW": "Εμφάνιση στο χάρτη",
        "TOOLTIP_CLOCKING_ALWAYS": "Το προσωπικό μπορεί πάντα να πραγματοποιεί/βγει με την εφαρμογή Rotaready για κινητά εδώ",
        "TOOLTIP_CLOCKING_WORKING": "Το προσωπικό μπορεί να συνδεθεί με την εφαρμογή Rotaready για κινητά εδώ, μόνο εάν εργάζεται μια βάρδια εδώ",
        "ERROR_LOAD": "Δεν μπορέσαμε να φορτώσουμε τις ζώνες συμμετοχής σας. Παρακαλώ προσπαθήστε ξανά.",
        "NO_ADDRESS": "Δεν υπάρχουν λεπτομερεις/διεύθυνση",
        "ERROR_LOOKUP": "Αποτύχαμε να αναζητήσουμε τη θέση αυτού του δείκτη.",
        "UNKNOWN_ADDRESS": "Άγνωστη διεύθυνση",
        "ADD_AS_NEW": "Προσθήκη ως νέα ζώνη",
        "DRAG_TO_CHANGE": "Σύρετέ με για να αλλάξω την τοποθεσία",
        "FILTER_LOCATIONS": "Ζώνες φιλτραρίσματος..."
      }
    },
    "SHIFT_TYPES": {
      "EDIT_SHIFT_TYPES_MODAL": {
        "TITLE_NEW": "Νέος τύπος βάρδιας",
        "TITLE_EDIT": "Επεξεργασία τύπου αλλαγής ταχυτήτων",
        "SITE_LABEL": "Ιστότοπος",
        "HIERARCHY_LABEL": "Ιεραρχία",
        "MASTER_TYPE_LABEL": "Κύριος τύπος βάρδιας",
        "SUB_TYPE_LABEL": "Τύπος δευτερεύουσας αλλαγής ταχυτήτων",
        "SUB_TYPE_HELP": "Το Rotaready σάς επιτρέπει να δημιουργήσετε μια ιεραρχία τύπων βάρδιας. Ένας τύπος δευτερεύουσας αλλαγής ταχυτήτων μπορεί να τοποθετηθεί κάτω από οποιονδήποτε άλλο τύπο αλλαγής ταχυτήτων.",
        "PARENT_TYPE_LABEL": "Τύπος γονικής βάρδιας",
        "NAME_LABEL": "Όνομα",
        "COLOUR_LABEL": "Χρώμα",
        "COST_STREAM_MAPPINGS_LABEL": "Χαρτογράφηση ροής",
        "COST_STREAM_TABLE": {
          "COST_STREAM_HEADING": "Ροή",
          "ATTRIBUTED_HEADING": "αποδίδεται",
          "EMPTY_LABEL": "Αυτό δεν αντιστοιχίζεται σε καμία ροή.",
          "ADD_BUTTON": "Προσθήκη νέου"
        },
        "DELETE_BUTTON": "Διαγραφή",
        "SAVE_BUTTON": "Αποθήκευση",
        "CANCEL_BUTTON": "Ακύρωση",
        "VALIDATION": {
          "NAME_REQUIRED": "Βεβαιωθείτε ότι έχετε παράσχει ένα έγκυρο όνομα για τον τύπο βάρδιας.",
          "PARENT_REQUIRED": "Για να είναι αυτός ένας τύπος δευτερεύουσας αλλαγής ταχυτήτων, πρέπει να επιλέξετε έναν γονικό τύπο αλλαγής ταχυτήτων.",
          "PARENT_VALID": "Επιλέξτε έναν έγκυρο τύπο γονικής βάρδιας.",
          "PARENT_SAME_SITE": "Αυτός ο τύπος βάρδιας και ο τύπος γονικής βάρδιας που επιλέξατε πρέπει να ανήκουν και οι δύο στον ίδιο Ιστότοπο (ή και οι δύο να είναι σε ολόκληρο τον Οργανισμό).",
          "ATTRIBUTION_OVERAGE": "Συνοψίσαμε το ποσοστό που αποδίδεται σε κάθε ροή και το σύνολο υπερβαίνει το 100%. Μπορείτε να αποδώσετε το μέγιστο 100% μόνο σε μία ή περισσότερες ροές.",
          "ATTRIBUTION_VALID": "Έχετε εισαγάγει ένα μη έγκυρο «ποσοστό χαρακτηριστικού» για μία ή περισσότερες από τις ροές. Μια τιμή «50», για παράδειγμα, θα αποδώσει το 50% του μισθολογικού κόστους οποιουδήποτε εργάζεται σε αυτόν τον τύπο βάρδιας στην αντίστοιχη ροή.",
          "ATTRIBUTION_NONE": "Πρέπει να αποδώσετε τον τύπο μετατόπισης σε τουλάχιστον μία ροή.",
          "DUPLICATE_COST_STREAM_MAPPING": "Υπάρχει ήδη μια χαρτογράφηση για αυτήν τη ροή! Δεν μπορείτε να αποδώσετε έναν τύπο μετατόπισης στην ίδια ροή δύο φορές."
        },
        "ERRORS": {
          "ADD_ERROR": "Δεν μπορούσαμε να προσθέσουμε τον τύπο βάρδιας σας τότε. Είναι πιθανό να υπάρχει ήδη ένας τύπος βάρδιας με το ίδιο όνομα! Δοκιμάστε να επιλέξετε άλλο όνομα.",
          "UPDATE_ERROR": "Δεν μπορούσαμε να ενημερώσουμε τον τύπο βάρδιας τότε. Είναι πιθανό να υπάρχει ήδη ένας τύπος βάρδιας με το ίδιο όνομα! Δοκιμάστε να επιλέξετε άλλο όνομα.",
          "DELETE_TYPE": "Δεν μπορέσαμε να διαγράψουμε τον τύπο βάρδιας τότε. Παρακαλώ προσπαθήστε ξανά!"
        },
        "CONFIRM": {
          "DELETE_SUB_TYPES": "Αυτός ο τύπος αλλαγής ταχυτήτων εξακολουθεί να έχει τύπους δευτερεύουσας αλλαγής ταχυτήτων. Θα διαγραφούν επίσης. Είσαι σίγουρη ότι θέλεις να προχωρήσεις;",
          "DELETE_TYPE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον τύπο βάρδιας;"
        }
      },
      "INDEX": {
        "TITLE": "Τύποι βάρδιας",
        "SUBTITLE": "Σκεφτείτε τους τύπους βάρδιας ως κατηγορίες για τα διαφορετικά είδη εργασίας που κάνουν οι άνθρωποι στον οργανισμό σας:",
        "SUB_TYPE_HELP": "Μπορείτε να προσθέσετε έναν «τύπο δευτερεύουσας αλλαγής» κάνοντας κλικ στο εικονίδιο συν δίπλα σε οποιονδήποτε τύπο βάρδιας. Αυτό είναι χρήσιμο εάν πρέπει να χωρίσετε έναν τύπο βάρδιας σε πιο συγκεκριμένα καθήκοντα ή ρόλους.",
        "TYPES_EMPTY": "Δεν υπάρχουν τύποι βάρδιας για εμφάνιση.",
        "ONLY_ENTITY": "μόνος",
        "WHOLE_ORGANISATION": "Σε ολόκληρο τον οργανισμό",
        "SHOW_MORE": "Εμφάνιση περισσότερων...",
        "ERRORS": {
          "LOAD_ERROR": "Ωχ, δεν μπορούσαμε να φορτώσουμε τους τύπους βάρδιας τότε. Παρακαλώ προσπαθήστε ξανά!"
        },
        "TOOLTIPS": {
          "ADD_SUB_SHIFT_TYPE": "Προσθήκη τύπου δευτερεύουσας αλλαγής ταχυτήτων"
        },
        "ADD_SHIFT_TYPE": "Προσθήκη τύπου αλλαγής ταχυτήτων"
      }
    },
    "TEMPLATES": {
      "EDIT_CONSTRAINTS_MODAL": {
        "ADD_TITLE": "Προσθήκη νέου {{currentLevelName}}",
        "EDIT_TITLE": "Επεξεργασία περιορισμών {{currentLevelName}}",
        "NAME_LABEL": "ονομάζω",
        "LOADING": {
          "SAVE": "Εξοικονόμηση..."
        },
        "SAVE_AND_CLOSE_BUTTON": "Αποθήκευση & κλείσιμο",
        "CANCEL_BUTTON": "Ακύρωση",
        "OVERRIDE_CONSTRAINT_BUTTON": "Παράκαμψη του περιορισμού {{levelName}}",
        "DELETE_OVERRIDE_BUTTON": "Διαγραφή παράκαμψης",
        "TAB_HEADINGS": {
          "POPULAR": "Δημοφιλή",
          "STAFF": "Προσωπικό",
          "REST": "Ξεκούραση",
          "OTHERS": "Άλλοι"
        },
        "STAFF_SELECTION": {
          "TITLE": "Επιλογή προσωπικού",
          "FLEXIBLE_STAFFING_LABEL": "Ευέλικτο προσωπικό",
          "FAIRLY_HINT": "Διαλέξτε δίκαια από όλο το προσωπικό",
          "FAIRLY_ANY_TAGS_HINT": "Διαλέξτε δίκαια από εκείνους που ταιρι <strong>άζουν με οποιαδήποτε</strong> από τις παρακάτω ετικέτες",
          "FAIRLY_ALL_TAGS_HINT": "Επιλέξτε δίκαια από εκείνους που ταιρι <strong>άζουν με όλες</strong> τις παρακάτω ετικέτες",
          "POOLING_HINT": "Αντιμετωπίστε τις παρακάτω ετικέτες ως ομάδες προσωπικού και εξαντλήστε κάθε μία <strong>με τη σειρά</strong>",
          "FIXED_STAFFING_LABEL": "Σταθερή στελέχωση",
          "SPECIFIC_STAFF_MEMBER_HINT": "Αναθέστε τη βάρδια σε συγκεκριμένο μέλος του προσωπικού",
          "ASSIGNEE_LABEL": "Εκδοχέας",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Πληκτρολογήστε για να προσθέσετε ετικέτες"
          }
        },
        "PREFERRED_STAFF_SELECTION": {
          "TITLE": "Προτιμώμενη επιλογή προσωπικού",
          "HINT": "Εάν πολλοί υπάλληλοι είναι επιλέξιμοι για μια δεδομένη βάρδια, η Rotaready θα προσπαθήσει να παρακάμψει την επιλογή του σύμφωνα με τα επιλεγμένα κριτήρια:",
          "NO_PREFERENCE": "Μην προτιμάτε κανένα προσωπικό έναντι άλλων, εκτός από λόγους δικαιοσύνης",
          "PREFER_ANY_TAG": "Προτιμήστε εκείνους που ταιριάζουν <strong>με οποιαδήποτε</strong> από τις παρακάτω ετικέτες",
          "PREFER_ALL_TAG": "Προτιμήστε αυτούς που ταιρι <strong>άζουν με όλες</strong> τις παρακάτω ετικέτες",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Πληκτρολογήστε για να προσθέσετε ετικέτες"
          }
        },
        "STAFF_SELECTION_GROUPS": {
          "TITLE": "Επιλογή προσωπικού (ομάδες)",
          "HINT": "Αυτό σας επιτρέπει να περιορίσετε την επιλογή του προσωπικού σε όσους είναι μέλη οποιασδήποτε από τις παρακάτω ομάδες. Οι ομάδες θα λάβουν πόρους με τη σειρά με την οποία καθορίζονται:",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Πληκτρολογήστε για να προσθέσετε ετικέτες"
          }
        },
        "STAFF_TYPECASTING": {
          "TITLE": "Κριτική τύπου προσωπικού",
          "HINT": "<em>Όταν αναθέτουμε κάποιον σε μια τυπο <em>ποιημένη βάρ</em> δια, μπορούν να του ανατεθούν μόνο περαιτέρω βάρδιες εκείνη την ημέρα που α) επίσης πληκτρο <em>λογούνται με τουλάχιστον</em> μία από αυτές τις ετικέτες ή β) δεν έχουν τυπογραφηθεί καθόλου:</em>",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Πληκτρολογήστε για να προσθέσετε ετικέτες"
          }
        },
        "OUTSOURCING": {
          "TITLE": "Εξωτερική ανάθεση",
          "HINT": "Εάν δεν υπάρχει διαθέσιμο εσωτερικό προσωπικό, επιτρέψτε την εξωτερική ανάθεση στους ακόλουθους οργανισμούς, με τη σειρά με την οποία καθορίζονται:",
          "PLACEHOLDERS": {
            "TYPE_OUTSOURCE_ORGANISATIONS": "Πληκτρολογήστε για να προσθέσετε οργανισμούς εξωτερικής ανάθεσης"
          }
        },
        "SELECTION_CONSISTENCY": {
          "TITLE": "Συνέπεια επιλογής",
          "HINT": "Για σύνολα βάρδιας που εκτείνονται σε πολλές ημέρες σε μια δεδομένη εβδομάδα, όταν κάποιος κατανέμεται στη βάρδια της πρώτης ημέρας, θα πρέπει:",
          "NO_EFFECT": "δεν έχουν καμία επίδραση στο αν επιλέγονται για βάρδιες άλλης ημέρας",
          "ALLOCATE": "να κατανέμεται σε όλες τις υπόλοιπες ημερήσιες βάρδιες του σετ βάρδιων, όπου είναι δυνατόν"
        },
        "REST_PERIOD": {
          "TITLE": "Περίοδος ανάπαυσης",
          "HINT": "Αφού εργαστείτε σε βάρδια, βεβαιωθείτε ότι ένα μέλος του προσωπικού είναι ξεκούραστο (και συνεπώς δεν έχει δοθεί άλλη βάρδια) τουλάχιστον για:"
        },
        "LOCATION_TOLERANCE": {
          "TITLE": "Ανοχή τοποθεσίας",
          "HINT": "Συχνά είναι σημαντικό δύο διαδοχικές βάρδιες με την ίδια καθορισμένη τοποθεσία να ανατίθενται στο ίδιο μέλος του προσωπικού. Καθορίστε τον μέγιστο αριθμό λεπτών μεταξύ δύο τέτοιων βάρδιων (τέλος της πρώτης έως την αρχή της δεύτερης) για να τα θεωρήσουμε διαδοχικά από αυτή την άποψη:",
          "MINUTES": "λεπτών",
          "PLACEHOLDERS": {
            "MINUTES": "Λεπτά μεταξύ του τέλους μιας βάρδιας έως την έναρξη μιας άλλης"
          }
        },
        "DAILY_WINDOW_DEFINITION": {
          "TITLE": "Καθημερινός ορισμός παραθύρου",
          "HINT": "Ορισμένοι οργανισμοί έχουν το προσωπικό τους να εργάζεται πολλές «βάρδιες» σε μια δεδομένη ημέρα, και για κάθε άτομο, αυτός ο ημερήσιος φόρτος εργασίας των βάρδιων πρέπει να βρίσκεται εντός συγκεκριμένου χρονικού διαστήματος. Αυτά τα χρονικά παράθυρα μπορεί να διαφέρουν και μπορείτε να ορίσετε όλες τις πιθανές παραλλαγές εδώ:",
          "LIMITED_TIMES": "Έχουμε ένα περιορισμένο, σταθερό σύνολο ωρών έναρξης/λήξης για τα παράθυρά μας",
          "REGULAR_INTERVALS": "Τα παράθυρά μας πέφτουν σε τακτά χρονικά διαστήματα μετά από μια δεδομένη ώρα έναρξης"
        },
        "TIMES": {
          "WINDOW_TIMES_TITLE": "Χρόνοι παραθύρων",
          "SPLIT_SHIFT_WINDOW_TIMES": "Χρόνοι παραθύρου διαίρεσης βάρδιας",
          "WINDOW_START_TIME": "Ώρα έναρξης του πρώιμου παραθύρου",
          "WINDOW_INTERVAL": "Διάστημα μεταξύ έγκυρων παραθύρων",
          "WINDOW_FINISH_TIME": "Ώρα λήξης του τελευταίου δυνατού παραθύρου",
          "PLACEHOLDERS": {
            "COMMA_SEPERATED_TIMES": "Λίστα ωρών διαχωρισμένων με κόμμα, δηλαδή 9:30-17:30,13:00-20:00"
          }
        },
        "SHIFT_PAY_RATE": {
          "TITLE": "Ποσοστό πληρωμής βάρδιας",
          "HINT": "Το μη μισθωτό προσωπικό που εργάζεται σε βάρδιες που δημιουργούνται από αυτό το {{currentLevelName}} θα κερδίζει με τη συνήθη ωριαία αμοιβή τους. Μπορείτε να το παρακάμψετε παρακάτω, εάν απαιτείται:",
          "OPTIONS": {
            "NORMAL": "Κανονικό: το προσωπικό κερδίζει με τη συνήθη ωριαία αμοιβή",
            "ZERO": "Μηδέν: το προσωπικό δεν πληρώνεται για αυτή τη βάρδια",
            "MULTIPLE": "Πολλαπλάσια: το προσωπικό κερδίζει πολλαπλάσιο της συνήθους ωριαίας αμοιβής τους",
            "FIXED": "Σταθερό: παρακάμψτε τη συνήθη ωριαία τιμή τους με σταθερό επιτόκιο"
          },
          "MULTIPLIER": "Πολλαπλασιαστής ποσοστού",
          "HOURLY_RATE_OVERRIDE": "Παράκαμψη ωριαίας τιμής",
          "PLACEHOLDERS": {
            "MULTIPLIER": "Πολλαπλασιαστής ωριαίας τιμής",
            "HOURLY_RATE_OVERRIDE": "Ωριαία επιτόκιο σε τοπικό νόμισμα, δηλ. 8,50"
          }
        },
        "SHIFT_TYPE": {
          "TITLE": "Τύπος αλλαγής ταχυτήτων",
          "HINT": "Αυτό υποδηλώνει το «είδος» των μετατοπίσεων που θα δημιουργηθούν από αυτό το {{currentLevelName}}. Το προσωπικό σας θα το δει ως τον τίτλο της βάρδιας και εάν χρησιμοποιείτε τις δυνατότητες ελέγχου κόστους του Rotaready, κάθε τύπος βάρδιας μπορεί να αντιστοιχιστεί σε μία ή περισσότερες ροές κόστους."
        },
        "SITE_DEPARTMENT": {
          "TITLE": "Τοποθεσία/τμήμα",
          "HINT": "Μετατοπίσεις χαρακτηριστικών που δημιουργούνται από αυτό το {{currentLevelName}} σε διαφορετική τοποθεσία/τμήμα. Αυτό είναι χρήσιμο για τη δημιουργία βάρδιων κάλυψης.",
          "ATTRIBUTE_TO_SITE": "Αποδώστε το μισθολογικό κόστος και τις ώρες στον επιλεγμένο ιστότοπο"
        }
      },
      "EDIT_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Προσθήκη συνόλου βάρδιων",
        "ADD_SUBTITLE": "Δώστε λεπτομέρειες σχετικά με αυτό το σετ βάρδιας",
        "EDIT_TITLE": "Επεξεργασία συνόλου βάρδιων",
        "EDIT_SUBTITLE": "Τροποποίηση λεπτομερειών σχετικά με αυτό το σύνολο βάρδιων",
        "TIP": "Ο αυτοματοποιημένος προγραμματιστής της Rotaready θα δημιουργήσει βάρδιες από αυτό το σύνολο βάρδιων και θα κατανείμει το προσωπικό ανάλογα, με δίκαιο και οικονομικό τρόπο.",
        "TIME_LABEL": "Ώρα έναρξης/λήξης",
        "TO": "να",
        "OVERFLOW_WARNING": "Αυτή η μετατόπιση θα ξεχειλίσει στην επόμενη μέρα.",
        "LOCATION_LABEL": "Ζώνη συμμετοχής",
        "MINIMUM_LABEL": "Τουλάχιστον",
        "MINIMUM_EXPLANATION": "Θέλω έναν ελάχιστο αριθμό προσωπικού ανά ημέρα, αλλά περισσότερο εάν είναι δυνατόν",
        "STAFF_NUMBER_LABEL": "Ακριβώς",
        "STAFF_NUMBER_EXPLANATION": "Θέλω έναν ακριβή αριθμό προσωπικού ανά ημέρα",
        "MAXIMUM_LABEL": "Έως και",
        "MAXIMUM_EXPLANATION": "Θέλω όσο το δυνατόν περισσότερο προσωπικό ανά ημέρα, μέχρι ένα μέγιστο όριο",
        "SHORT_DAYS": {
          "MONDAY": "Δευ",
          "TUESDAY": "Τρίτη",
          "WEDNESDAY": "Τετ",
          "THURSDAY": "Πεμ",
          "FRIDAY": "Παρ",
          "SATURDAY": "Σάββατο",
          "SUNDAY": "Ήλιος"
        },
        "SAVE_BUTTON": "Αποθήκευση σετ βάρδιας",
        "CANCEL_BUTTON": "Ακύρωση",
        "LOADING": {
          "SAVE": "Εξοικονόμηση..."
        },
        "ERRORS": {
          "UNABLE_TO_ADD": "Προς το παρόν δεν είναι δυνατή η προσθήκη αυτού του συνόλου βάρδιων. Παρακαλώ προσπαθήστε ξανά."
        }
      },
      "EDIT_TEMPLATE": {
        "LOADING": {
          "SAVE": "Εξοικονόμηση..."
        },
        "EDIT_CONSTRAINTS": "Επεξεργασία περιορισμών",
        "CONSTRAINTS_LABEL": "Περιορισμοί προτύπου",
        "CONSTRAINTS_TEXT": "Ορίστε περιορισμούς που θα ισχύουν για ολόκληρο το πρότυπο.",
        "PLACEHOLDERS": {
          "SEARCH_FILTER": "Φίλτρο..."
        },
        "ADD_GROUP_BUTTON": "Προσθήκη ομάδας",
        "TAB_HEADINGS": {
          "FLEXIBLE_STAFFING": "Ευέλικτο προσωπικό",
          "FIXED_STAFFING": "Σταθερή στελέχωση"
        },
        "TOOLTIPS": {
          "ACTIVE_CONSTRAINTS": "Ενεργοί περιορισμοί",
          "DISABLE": "Απενεργοποίηση",
          "ENABLE": "Ενεργοποίηση"
        },
        "GROUP_OPTIONS_BUTTON": {
          "LABEL": "Επιλογές ομάδας",
          "ADD_TASK": "Προσθήκη εργασίας",
          "ADD_SHIFT_SET": "Προσθήκη συνόλου βάρδιων",
          "EDIT_CONSTRAINTS": "Επεξεργασία περιορισμών",
          "DELETE_GROUP": "Διαγραφή ομάδας"
        },
        "HOURS": "ώρες",
        "SHORT_DAYS": {
          "MONDAY": "Δευ",
          "TUESDAY": "Τρίτη",
          "WEDNESDAY": "Τετ",
          "THURSDAY": "Πεμ",
          "FRIDAY": "Παρ",
          "SATURDAY": "Σάββατο",
          "SUNDAY": "Ήλιος"
        },
        "CHAR_DAYS": {
          "MONDAY": "Μ",
          "TUESDAY": "Τ",
          "WEDNESDAY": "W",
          "THURSDAY": "Τ",
          "FRIDAY": "Φ",
          "SATURDAY": "S",
          "SUNDAY": "S"
        },
        "NO_SHIFT_SETS": "Δεν υπάρχουν σύνολα βάρδιας σε αυτήν την ομάδα. Προσθέστε ένα χρησιμοποιώντας το κουμπί «Επιλογές ομάδας».",
        "OPTIONS_BUTTON": {
          "LABEL": "Επιλογές",
          "ADD_SHIFT_SET": "Προσθήκη συνόλου βάρδιων",
          "EDIT_CONSTRAINTS": "Επεξεργασία περιορισμών",
          "DELETE_TASK": "Διαγραφή εργασίας"
        },
        "NO_FLEXIBLE_STAFF_GROUPS_THIS_WEEK": "Δεν έχετε ευέλικτες ομάδες στελέχωσης. Γιατί να μην προσθέσετε ένα κάνοντας κλικ στην επιλογή «Προσθήκη ομάδας» παραπάνω;",
        "NO_FLEXIBLE_STAFF_GROUPS": "Καμία ευέλικτη ομάδα στελέχωσης δεν ταιριάζει με αυτό που ψάχνετε.",
        "GROUP_SHIFT_SETS_TABLE": {
          "HEADINGS": {
            "SHIFT_TYPE": "Τύπος αλλαγής ταχυτήτων",
            "TIME": "Χρόνος",
            "DAYS_OF_WEEK": "Ημέρες της εβδομάδας"
          }
        },
        "NO_FIXED_STAFF_GROUPS_THIS_WEEK": "Δεν έχετε σταθερές ομάδες προσωπικού. Γιατί να μην προσθέσετε ένα κάνοντας κλικ στην επιλογή «Προσθήκη ομάδας» παραπάνω;",
        "NO_FIXED_STAFF_GROUPS": "Καμία ομάδα σταθερής στελέχωσης δεν ταιριάζει με αυτό που ψάχνετε.",
        "EDIT_BUTTON": "Επεξεργασία",
        "TOGGLE_DROPDOWN_BUTTON": "Εναλλαγή αναπτυσσόμενου μενού",
        "DELETE_BUTTON": "Διαγραφή",
        "SAVE_BUTTON": "Αποθήκευση",
        "ERRORS": {
          "COULDNT_FIND_TEMPLATE": "Δεν μπορέσαμε να βρούμε το πρότυπο που αναζητούσατε. Παρακαλώ προσπαθήστε ξανά.",
          "OOPS": "Ωχ, κάτι πήγε στραβά. Ίσως χρειαστεί να προσπαθήσετε ξανά!",
          "TEMPLATE_STATE_CHANGE": "Δεν μπορέσαμε να ενεργοποιήσουμε/απενεργοποιήσουμε μέρος του προτύπου σας μόλις τώρα. Παρακαλώ προσπαθήστε ξανά."
        },
        "LEVEL_NAMES": {
          "GLOBAL": "παγκόσμια",
          "TEMPLATE": "πρότυπο",
          "WEEK": "εβδομάδας",
          "GROUP": "ομαδικό",
          "TASK": "έργο",
          "SHIFT_SET": "σετ αλλαγής ταχυτήτων"
        },
        "CONFIRM": {
          "DELETE": "Είστε βέβαιοι ότι θέλετε να το διαγράψετε;",
          "DELETE_SHIFT_SET": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το σετ βάρδιας;"
        }
      },
      "FIXED_STAFFING_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Προσθήκη συνόλου βάρδιων",
        "ADD_SUBTITLE": "Δώστε λεπτομέρειες σχετικά με αυτό το σετ βάρδιας",
        "EDIT_TITLE": "Επεξεργασία συνόλου βάρδιων",
        "EDIT_SUBTITLE": "Τροποποίηση λεπτομερειών σχετικά με αυτό το σύνολο βάρδιων",
        "START_END_LABEL": "Ώρα έναρξης/λήξης",
        "SHIFT_OVERFLOW_WARNING": "Αυτή η μετατόπιση θα ξεχειλίσει στην επόμενη μέρα.",
        "SHIFT_TYPE_LABEL": "Τύπος αλλαγής ταχυτήτων",
        "LOCATION_LABEL": "Ζώνη συμμετοχής",
        "SHORT_DAYS": {
          "MONDAY": "Δευ",
          "TUESDAY": "Τρίτη",
          "WEDNESDAY": "Τετ",
          "THURSDAY": "Πεμ",
          "FRIDAY": "Παρ",
          "SATURDAY": "Σάββατο",
          "SUNDAY": "Ήλιος"
        },
        "SAVE_BUTTON": "Αποθήκευση σετ βάρδιας",
        "CANCEL_BUTTON": "Ακύρωση",
        "LOADING": {
          "SAVE": "Εξοικονόμηση..."
        },
        "UNKNOWN_SHIFT_TYPE": "Άγνωστος τύπος αλλαγής ταχυτήτων",
        "ERRORS": {
          "UNABLE_ADD": "Ωχ, προς το παρόν δεν μπορούμε να προσθέσουμε αυτό το σύνολο βάρδιων. Παρακαλώ προσπαθήστε ξανά.",
          "UNABLE_UPDATE": "Ωχ, προς το παρόν δεν μπορούμε να ενημερώσουμε αυτό το σύνολο βάρδιων. Παρακαλώ προσπαθήστε ξανά."
        }
      },
      "INDEX": {
        "NEW_BUTTON": "Προσθήκη νέου",
        "ALERT_LINK_WARNING": "Τα πρότυπα έχουν μετακινηθεί στο μενού Ρυθμίσεις. Ο υπάρχων σύνδεσμος κάτω από το μενού Rotas θα εξαφανιστεί σύντομα 👀",
        "TITLE": "Πρότυπα Rota",
        "SEARCH_FILTER": "Αναζήτηση...",
        "TEMPLATE_TABLE": {
          "NAME_HEADING": "Όνομα",
          "CREATED_BY_HEADING": "Δημιουργήθηκε από",
          "LAST_EDITED_HEADING": "Τελευταία επεξεργασία",
          "OPTIONS_HEADING": "Επιλογές"
        },
        "NO_TEMPLATES": "Δεν υπάρχουν πρότυπα δρομολογίων για εμφάνιση.",
        "CLONE_TEMPLATE": "Κλώνος",
        "DELETE_TEMPLATE": "Διαγραφή",
        "EDITED_ON": "στις",
        "CONFIRM": {
          "DELETE_NODE": "Είστε βέβαιοι ότι θέλετε να το διαγράψετε;"
        },
        "SUCCESS": {
          "DELETE_NODE": "Η πρόβλεψη ζήτησης διαγράφηκε επιτυχώς."
        },
        "ERRORS": {
          "DELETE_NODE": "Ωχ, κάτι πήγε στραβά. Ίσως χρειαστεί να προσπαθήσετε ξανά!"
        }
      },
      "NEW_TEMPLATE_MODAL": {
        "TITLE": "Νέο πρότυπο",
        "REMINDER": "<strong>Ψστ!</strong> Έχετε ήδη <strong>{{templateCount}}</strong> πρότυπο (α). Χρειάζεται να δημιουργήσετε ένα νέο μόνο εάν οι απαιτήσεις προγραμματισμού σας έχουν αλλάξει σημαντικά. Διαφορετικά, θα πρέπει να επεξεργαστείτε ένα υπάρχον.",
        "TYPE_LABEL": "Τύπος",
        "NEW_BLANK_TEMPLATE": "Νέο κενό πρότυπο",
        "CLONE_EXISTING_TEMPLATE": "Κλωνοποίηση υπάρχοντος προτύπου",
        "CLONE_FROM_LABEL": "Κλώνος από",
        "NAME_LABEL": "Όνομα προτύπου",
        "NAME_HELP": "Θα δημιουργείτε διαδρομές από αυτό το πρότυπο σε τακτική βάση και ένα καλό όνομα περιγράφει για ποιον ή τι θα είναι αυτά τα δρομολόγια, όπως «Rota προσωπικού υποστήριξης».",
        "NAME_PLACEHOLDER": "δηλαδή. Περίοδος αιχμής ή τροχιά ομάδας Β",
        "DEFAULT_SHIFT_TYPE_LABEL": "Προεπιλεγμένος τύπος αλλαγής ταχυτήτων",
        "DEFAULT_SHIFT_TYPE_HELP": "Όταν δημιουργείτε το πρότυπό σας, θα μπορείτε να αλλάξετε τον τύπο βάρδιας για κάθε ομάδα ή εργασία και είναι βολικό για εμάς να ορίσουμε μια προεπιλογή τώρα.",
        "CREATE_BUTTON": "Δημιουργήστε!",
        "CREATE_BUTTON_LOADING": "Παρακαλώ περιμένετε, αυτό μπορεί να πάρει λίγο χρόνο...",
        "CANCEL_BUTTON": "Ακύρωση",
        "CONFIRM_CLONE": "Η κλωνοποίηση ενός προτύπου θα διαρκέσει μια ή δύο λεπτά, ανάλογα με το μέγεθος του προτύπου. Είσαι σίγουρος ότι θέλεις να προχωρήσεις;",
        "CREATE_ERROR": "Προς το παρόν δεν είναι δυνατή η δημιουργία αυτού του προτύπου. Παρακαλώ προσπαθήστε ξανά."
      },
      "DELETE_TEMPLATE_MODAL": {
        "INFO_TITLE": "Διαγραφή προτύπου",
        "INFO_BODY": "Η διαγραφή ενός προτύπου είναι μη αναστρέψιμη και δεν μπορεί να αναιρεθεί. Για να μας πείσετε ότι θέλετε να το κάνετε αυτό, πληκτρολογήστε το όνομα του προτύπου στο παρακάτω πλαίσιο και, στη συνέχεια, κάντε κλικ στο κουμπί διαγραφή.",
        "DELETE_TEMPLATE_BUTTON": "Διαγραφή προτύπου",
        "SUCCESS": {
          "DELETE_TEMPLATE": "Το πρότυπό σας διαγράφηκε με επιτυχία."
        },
        "ERRORS": {
          "DELETE_TEMPLATE": "Ωχ, κάτι πήγε στραβά. Ίσως χρειαστεί να προσπαθήσετε ξανά!"
        }
      }
    },
    "SHIFT": {
      "EDIT_MODAL": {
        "SHIFT": "Μετατόπιση",
        "SITE": "Ιστότοπος",
        "START": "Έναρξη",
        "BREAK": "Διακοπή",
        "NONE": "Κανένα",
        "FINISH": "Τελειώστε",
        "NEW_SHIFT": "Νέα βάρδια",
        "ATTRIBUTE_WAGE_COST_TO_SITE": " Αποδώστε το μισθολογικό κόστος και τις ώρες στον επιλεγμένο ιστότοπο",
        "DATE_AND_TIME": "Ημερομηνία & ώρα",
        "TO": " να ",
        "THEN_REST": " τότε ξεκουραστείτε ",
        "SHIFT_TYPE": "Τύπος αλλαγής ταχυτήτων",
        "LOCATION": "Ζώνη συμμετοχής",
        "NO_BREAK": "Χωρίς διάλειμμα",
        "PAID_BREAK": "Πληρωμένο διάλειμμα",
        "UNPAID_BREAK": "Μη πληρωμένο διάλειμμα",
        "OF_LENGTH": "του μήκους",
        "BREAK_MESSAGE_WARNING": "Το προσωπικό που έχει ανατεθεί σε αυτήν τη βάρδια έχει διαφορετικές απαιτήσεις διαλείμματος. Όταν τελειώσετε, το Rotaready θα αποθηκεύσει μια ξεχωριστή βάρδια για κάθε άτομο με το σωστό σετ διακοπών.",
        "BREAK_MESSAGE_SUCCESS": "Έχουμε εφαρμόσει αυτόματα το σωστό διάλειμμα σε αυτή τη βάρδια.",
        "PAY_RULE": "Κανόνας πληρωμής",
        "APPLIES": " ισχύει",
        "FOR_THE_SCHEDULED_DURATION": "Για την προγραμματισμένη διάρκεια",
        "FOR_ANY_OVERTIME": "Για οποιεσδήποτε υπερωρίες",
        "STAFF_SELECTION": "Επιλογή προσωπικού",
        "STAFF_SELECTION_HELP": "Επεξεργαστείτε το προσωπικό που έχει ανατεθεί και χρησιμοποιήστε ετικέτες για να φιλτράρετε άλλους που πληρούν τις προϋποθέσεις",
        "STAFF_MUST_MATCH": "Το προσωπικό πρέπει να ταιριάζει",
        "ANY_OF_THE_TAGS": "<strong>οποιαδήποτε</strong> από τις ετικέτες",
        "ANY": "οποιοδήποτε",
        "ALL": "όλα",
        "OF_THE_TAGS": " των ετικετών",
        "ALL_OF_THE_TAGS": "<strong>Όλες</strong> οι ετικέτες",
        "TAG_TYPEAHEAD_PLACEHOLDER": "Πληκτρολογήστε για να βρείτε ετικέτες",
        "STAFF_ASSIGNED": "Ανατέθηκε",
        "NOBODY_IS_ASSIGNED": "Κανείς δεν έχει ανατεθεί",
        "STAFF_ELIGIBLE": "Επιλέξιμος",
        "STAFF_FILTER_PLACEHOLDER": "Αναζήτηση...",
        "SHOW": "Εμφάνιση",
        "MORE": " περισσότερα...",
        "NOBODY_MATCHES_YOUR_TAGS": "Κανείς δεν ταιριάζει με τις ετικέτες σας",
        "NOBODY_ELSE_IS_AVAILABLE": "Κανείς άλλος δεν είναι διαθέσιμος",
        "MORE_OPTIONS": "Περισσότερες επιλογές",
        "ADD_NOTES_AND_ADJUST_PAY_RATES": "Προσθέστε σημειώσεις και προσαρμόστε τα ποσοστά αμοιβής",
        "DETAILS": "Λεπτομέρειες",
        "DETAILS_TEXTAREA_PLACEHOLDER": "Προσθέστε σημειώσεις, καθήκοντα και άλλες λεπτομέρειες εδώ...",
        "ROTA": "Ρότα",
        "NO_ROTA": "Χωρίς τροχό",
        "PAY_LABEL": "Πληρωμή",
        "PAY_TOGGLE": "Αυτή είναι μια αμειβόμενη βάρδια",
        "DELETE": "Διαγραφή",
        "VIEW_BROADCAST": "Προβολή εκπομπής",
        "START_BROADCAST": "Έναρξη μετάδοσης",
        "SAVE": "Αποθήκευση",
        "CANCEL": "Ακύρωση"
      },
      "SHIFT_BROADCAST_MODAL": {
        "SHIFT": "Μετατόπιση",
        "SITE": "Ιστότοπος",
        "START": "Έναρξη",
        "BREAK": "Διακοπή",
        "NONE": "Κανένα",
        "FINISH": "Τελειώστε",
        "ORIGINAL_PLANNED_STAFF": "Αρχικό προγραμματισμένο προσωπικό",
        "EXTRA_STAFF_REQUIRED": "Απαιτείται επιπλέον προσωπικό",
        "NOT_SHIFT_BROADCASTING_HELP": "Ρυθμίστε το 0 για να ζητήσετε όσο το δυνατόν περισσότερο προσωπικό.",
        "START_BROADCASTING": "Ξεκινήστε τη μετάδοση",
        "START_BROADCASTING_LOADING": "Έναρξη μετάδοσης...",
        "CANCEL": "Ακύρωση",
        "ACCEPTED": "Αποδεκτή",
        "PENDING": "Σε αναμονή",
        "REJECTED": "Απορρίφθηκε",
        "EVERYONE": "Ολοι",
        "NO_PARTICIPANTS_TO_SHOW": "Δεν υπάρχουν συμμετέχοντες στην εκπομπή για εμφάνιση.",
        "NEEDED": "Απαιτείται",
        "SHIFT_BROADCASTING_HELP": "Η μετάδοσή σας βρίσκεται σε εξέλιξη. Η παρακάτω λίστα θα ενημερωθεί καθώς το προσωπικό δέχεται ή απορρίπτει τη βάρδια. Η μετάδοση θα τερματιστεί αυτόματα όταν ο σωστός αριθμός προσωπικού έχει αποδεχθεί ή όταν όλοι έχουν απαντήσει.",
        "STOP_BROADCASTING": "Σταματήστε τη μετάδοση",
        "STOP_BROADCASTING_LOADING": "Διακοπή μετάδοσης...",
        "PAUSE_BROADCAST": "Παύση μετάδοσης",
        "PAUSE_BROADCAST_LOADING": "Παύση μετάδοσης...",
        "RESUME_BROADCAST": "Συνέχιση μετάδοσης",
        "RESUME_BROADCAST_LOADING": "Συνέχιση της μετάδοσης...",
        "CLOSE": "Κλείσιμο"
      },
      "VIEW_MODAL": {
        "SHIFT": "Μετατόπιση",
        "SITE": "Ιστότοπος",
        "START": "Έναρξη",
        "BREAK": "Διακοπή",
        "NONE": "Κανένα",
        "FINISH": "Τελειώστε",
        "LOCATION": "Ζώνη συμμετοχής",
        "NONE_SPECIFIED": "Δεν έχει καθοριστεί",
        "REST": "Ξεκούραση",
        "DETAILS": "Λεπτομέρειες",
        "ALLOTTED_BREAK": "Καταχωρημένο διάλειμμα",
        "ASSIGNED_STAFF": "Ανατεθέν προσωπικό",
        "ON": "στις",
        "SHIFT_ROUTINE": "Ρουτίνα βάρδιας",
        "LAST_UPDATED": "Τελευταία ενημέρωση",
        "GET_DIRECTIONS": "Λήψη οδηγιών",
        "SHOW_TRAFFIC": "Εμφάνιση επισκεψιμότητας",
        "EDIT": " Επεξεργασία",
        "CLOSE": "Κλείσιμο"
      }
    }
  },
  "DASHBOARD": {
    "TIME_AND_ATTENDANCE": "Χρόνος & Παρακολούθηση",
    "YOU_ARE_NOW": "Είσαι τώρα",
    "ERROR_PLEASE_TRY_AGAIN": "Σφάλμα, δοκιμάστε ξανά",
    "MY_UPCOMING_SHIFTS": "Οι επερχόμενες βάρδιες μου",
    "YOU_HAVE_NO_SHIFTS_TODAY": "Δεν έχεις βάρδιες σήμερα.",
    "ALL_DAY": "Όλη μέρα",
    "AM": "ΕΊΜΑΙ",
    "PM": "ΜΜ",
    "TODAY_S_SCHEDULE": "Το σημερινό πρόγραμμα",
    "THERE_ARE_NO_SHIFTS_SCHEDULED_TODAY": "Δεν υπάρχουν προγραμματισμένες βάρδιες σήμερα.",
    "WHATEVER": "Ξεκινώντας από <strong>{{startTime}}</strong> και τελειώνοντας στο <strong>{{endTime</strong>}}"
  },
  "SIGN_OUT": {
    "LOGGING_OUT": "Αποσύνδεση..."
  },
  "PARTIALS": {
    "VALIDATION": {
      "ISSUE_1": {
        "HEADING": "Η εργάσιμη ημέρα είναι πολύ μεγάλη",
        "BODY": "Η αλλαγή σας σημαίνει <b>ότι η εργάσιμη ημέρα του {{name}}</b> θα γίνει πολύ μεγάλη (σύμφωνα με τις ρυθμίσεις του οργανισμού σας). Αυτή τη στιγμή ξεκινά στις <b>{{start|momentFormat: 'lll'}}</b> και τελειώνει στο <b>{{end|momentFormat: 'lll'}} (στη συνέχεια παραμένει μέχρι το {{endRest|momentFormat: 'lll'}}</b>."
      },
      "ISSUE_2": {
        "HEADING": "επικαλυπτόμενη εργάσιμη ημέρα",
        "BODY": "Η αλλαγή σας σημαίνει ότι <b>το {{name}}</b> έχει μια εργάσιμη ημέρα από το <b>{{primaryStart|momentFormat: 'lll'}} έως το <b>{{primary</b> End|momentFormat: 'LT'}}</b>. Δυστυχώς, αυτό επικαλύπτεται με μια εργάσιμη ημέρα που έχουν ήδη από το <b>{{secondaryStart|momentFormat: 'lll'}} έως το {{secondaryEnd|momentFormat: 'LT'}}</b> <b>(στη συνέχεια ανάπαυσε μέχρι το {{endRest|momentFormat: 'LT'}}</b>)."
      },
      "ISSUE_3": {
        "HEADING": "Βάρδια εκτός εργάσιμης ημέρας",
        "BODY": "<b>Το {{name}}</b> έχει προγραμματισμένη μετατόπιση από <b>{{start|momentFormat: 'LT'}}</b> σε <b>{{end|momentFormat: 'LT'}} (στη συνέχεια σε κατάσταση αναμονής μέχρι το {{endRest|momentFormat: 'LT'}}</b>). Η αλλαγή σας θα σήμαινε ότι αυτή η βάρδια πέφτει εν μέρει ή εξ ολοκλήρου εκτός της εργάσιμης ημέρας."
      },
      "ISSUE_4": {
        "HEADING": "Μη διαθέσιμο",
        "BODY": "<b>Το {{name}}</b> δεν είναι διαθέσιμο από <b>{{start|momentFormat: 'lll'}} έως</b> <b>{{end|momentFormat: 'LT'</b>}} όπως είναι",
        "REASON_1": "δουλεύοντας μια βάρδια ({{eventType}} σε {{entityName}})",
        "REASON_2": "απασχολημένος κατά τη διάρκεια μιας εργάσιμης ημέρας",
        "REASON_3": "απουσιάζει ({{EventType}})",
        "REASON_4": "μη διαθέσιμα λόγω του μοτίβου διαθεσιμότητάς τους",
        "BUTTON": "Κάντε μια εξαίρεση και δοκιμάστε ξανά",
        "BUTTON_LOADING": "Φόρτωση..."
      },
      "ISSUE_5": {
        "HEADING": "Μη εκπληρωμένη συμβατική υποχρέωση",
        "BODY": "Η αλλαγή σας σημαίνει ότι <b>η {{name}}</b> δεν θα εκπληρώσει τη συμβατική τους υποχρέωση. Είναι τώρα <b>{{currentValue}} ημέρες (ες) αλλά πρέπει</b> να είναι τουλάχιστον <b>{{comparatorValue}} ημέρα</b> (ες)."
      },
      "ISSUE_6": {
        "HEADING": "Μη εκπληρωμένη συμβατική υποχρέωση",
        "BODY": "Η αλλαγή σας σημαίνει ότι <b>η {{name}}</b> δεν θα εκπληρώσει τη συμβατική τους υποχρέωση. Τώρα είναι <b>{{currentValue}} ώρες (ες) αλλά πρέπει</b> να είναι τουλάχιστον <b>{{comparatorValue}} ώρες</b> (ες)."
      },
      "ISSUE_7": {
        "HEADING": "Ετικέτες αναντιστοιχία",
        "BODY": "<b>Το {{name}}</b> δεν έχει τις απαιτούμενες ετικέτες για να λειτουργήσει αυτή τη βάρδια. Αυτό σημαίνει ότι μπορεί να στερούνται δεξιοτήτων, προσόντων ή άλλου χαρακτηριστικού."
      },
      "ISSUE_8": {
        "HEADING": "Ενεργή μετάδοση",
        "BODY": "Αυτή τη στιγμή υπάρχει μια ενεργή μετάδοση βάρδιας για αυτήν τη βάρδια. Καθώς το προσωπικό σας ενδέχεται να βρίσκεται στη διαδικασία απάντησης, δεν μπορούμε να επιτρέψουμε την τροποποίηση της βάρδιας εκτός εάν ακυρώσετε πρώτα τη μετάδοση βάρδιας."
      },
      "ISSUE_9": {
        "HEADING": "Ανεπαρκείς ημέρες αργίας",
        "BODY": "Η αλλαγή σας σημαίνει <b>ότι το {{name}}</b> θα εργάζεται πάρα πολλές ημέρες αυτήν την εβδομάδα. Τώρα θα έχουν την <b>{{currentValue}} ημέρα (ες) ελεύθερη (ες)</b>, η οποία είναι μικρότερη από την ημέρα <b>(ες) που απαιτούνται για την {{comparatorValue}}</b>."
      },
      "ISSUE_10": {
        "HEADING": "Μέλος του προσωπικού ανενεργό",
        "BODY": "<b>Το {{name}}</b> δεν μπορεί να λειτουργήσει στο <b>{{start|momentFormat: 'll'</b>}}. Ο λογαριασμός τους είτε είναι ανενεργός αυτήν την ημερομηνία είτε έχουν μετακινηθεί σε άλλο ιστότοπο."
      },
      "ISSUE_11": {
        "HEADING": "Σημείωση Rota για έγκριση",
        "BODY": "Δεν μπορείτε να κάνετε αλλαγές σε αυτό το δρομολόγιο καθώς έχει επισημανθεί για έγκριση."
      },
      "ISSUE_12": {
        "HEADING": "Περίοδος απενεργοποίησης",
        "BODY": "Δεν μπορείτε να κάνετε αλλαγές σε αυτό το δρομολόγιο καθώς έχει αποσυνδεθεί."
      },
      "ISSUE_13": {
        "HEADING": "Υπέρβαση ορίου εργασίας",
        "BODY": "Η αλλαγή σας σημαίνει <b>ότι το {{name}}</b> θα λειτουργεί περισσότερο από όσο επιτρέπεται. Τώρα θα εργάζονται <b>{{currentValue}} ημέρες (ες)</b>, η οποία είναι μεγαλύτερη από το όριο των <b>{{comparatorValue}} ημερών</b> (ων)."
      },
      "ISSUE_14": {
        "HEADING": "Υπέρβαση ορίου εργασίας",
        "BODY": "Η αλλαγή σας σημαίνει <b>ότι το {{name}}</b> θα λειτουργεί περισσότερο από όσο επιτρέπεται. Τώρα θα εργάζονται <b>{{currentValue}} ώρες που υπερβ</b> αίνουν το όριο των <b>{{comparatorValue}} ωρών</b>."
      },
      "ISSUE_15": {
        "HEADING": "Κλειστή η ζώνη συμμετοχής",
        "BODY": "Η καθορισμένη ζώνη παρακολούθησης της βάρδιας (<b>{{assignedLocation}}</b>) είναι κλειστή αυτή τη στιγμή.",
        "REASON": "Αυτό οφείλεται σε: {{λόγος}}."
      },
      "ISSUE_16": {
        "HEADING": "Υπέρβαση της χωρητικότητας της ζώνης παρακολούθησης",
        "BODY": "Η καθορισμένη ζώνη παρακολούθησης αυτής της βάρδιας έχει <b>μέγιστη χωρητικότητα {{}}</b> ατόμων ανά πάσα στιγμή. Με αυτή τη μετατόπιση η χωρητικότητα θα είναι {{currentOccupancy}}."
      },
      "ISSUE_17": {
        "HEADING": "Πολύ λίγο προσωπικό στη ζώνη παρακολούθησης",
        "BODY": "Από {{start|momentFormat: 'lll'}} έως {{end|momentFormat: 'lll'}}, υπάρχει μια στιγμή που εργάζονται μόνο <b>{{current</b> Value}} άτομα. Κάθε φορά που κάποιος βρίσκεται σε βάρδια, <b>απαιτούνται τουλάχιστον {{comparatorValue}}</b> άτομα."
      },
      "ISSUE_18": {
        "HEADING": "Το μέλος του προσωπικού δεν έχει εγκριθεί",
        "BODY": "Το {{name}} δεν μπορεί να λειτουργήσει στο <b>{{start|momentFormat: 'll'</b>}}. Ο λογαριασμός τους δεν έχει ακόμη εγκριθεί για προγραμματισμό."
      },
      "ISSUE_19": {
        "HEADING": "Έγγραφα μελών του προσωπικού λείπονται/έληξαν",
        "BODY": "<b>Το {{name}}</b> δεν μπορεί να λειτουργήσει στο <b>{{start|momentFormat: 'll'</b>}}. Ένα ή περισσότερα απαιτούμενα έγγραφα είτε λείπουν είτε έχουν λήξει."
      },
      "ISSUE_20": {
        "HEADING": "Πολύ μεγάλη αλλαγή (Κάτω από 18)",
        "BODY": "Η αλλαγή σας σημαίνει ότι <b>το {{name}} θα λειτουργεί</b> για την <b>{{duration|HumaniseDuration</b>}}. Δεδομένου ότι είναι κάτω των 18 ετών, το όριο της Οδηγίας για το Χρόνο Εργασίας είναι <b>{{limit|Humanise</b> Duration}}."
      },
      "ISSUE_21": {
        "HEADING": "Μετατόπιση εκτός ορίων",
        "BODY": "Δεν μπορείτε να προγραμματίσετε μια βάρδια για το <b>{{name}}</b> στο <b>{{start|momentFormat: 'll'}}</b> επειδή ανήκει σε μια διαδρομή που ξεκινά από <b>{{periodStart|momentFormat: 'll'}} και τελειώνει σε {<b>{perio</b> dend|momentFormat: 'll'}}</b>."
      }
    }
  },
  "NAV": {
    "HOME": "Σπιτικό",
    "ROTAS": "Ρωτάς",
    "STAFF": "Προσωπικό",
    "ATTENDANCE": "Συμμετοχή",
    "REPORTS": "Αναφορές",
    "SETTINGS": "Ρυθμίσεις",
    "VIEW_ROTA": "Προβολή διαδρομής",
    "SCHEDULE": "Το πρόγραμμά μου",
    "ABSENCE": "Η απουσία μου",
    "AVAILABILITY": "Η διαθεσιμότητά μου",
    "DOCUMENTS": "Τα έγγραφά μου",
    "ROTA_DASHBOARD": "Πίνακας ελέγχου",
    "ROTA_ROTA_EDITOR": "Επεξεργαστής Rota",
    "ROTA_TRONC": "Τρόνκ",
    "ROTA_EVENT_CALENDAR": "Σχεδιαστής εκδηλώσεων",
    "STAFF_DIRECTORY": "Ευρετήριο",
    "STAFF_REQUESTS": "Αιτήματα",
    "STAFF_ABSENCES": "Ημερολόγιο απουσίας",
    "STAFF_EMPLOYEE_SUBMITTED_DETAILS": "Στοιχεία υπέβαλε υπάλληλος",
    "ATTENDANCE_OVERVIEW": "Συμμετοχή",
    "ATTENDANCE_ISSUES": "Θέματα",
    "COST_CONTROL": "Έλεγχος κόστους",
    "COST_CONTROL_SIGN_OFF": "Έλεγχος κόστους & υπογραφή",
    "COST_CONTROL_AGGREGATED_TOTALS": "Συγκεντρωτικά σύνολα",
    "ASSET_REGISTRY": "Μητρώο περιουσιακών στοιχείων",
    "WFM_DASHBOARD": "Οι πίνακες ελέγχου μου"
  },
  "SETTINGS": {
    "INDEX": {
      "TITLE": "Ρυθμίσεις",
      "PLACEHOLDER_TEXT": "👈 Επιλέξτε μια ρύθμιση στα αριστερά για να ξεκινήσετε.",
      "CATEGORY_HR": "HR",
      "CATEGORY_ROTAS": "Ρωτάς",
      "CATEGORY_ATTENDANCE": "Συμμετοχή",
      "CATEGORY_INTEGRATIONS": "Ενσωματώσεις",
      "ITEM_ROTA_TEMPLATES": "Πρότυπα Rota",
      "ITEM_TRUSTED_DEVICES": "Αξιόπιστες συσκευές",
      "ITEM_SHIFT_LOCATIONS": "Ζώνες συμμετοχής",
      "ITEM_SHIFT_TYPES": "Τύποι βάρδιας",
      "ITEM_OUTSOURCING": "Εξωτερική ανάθεση",
      "ITEM_ABSENCE_TYPES": "Τύποι απουσίας",
      "ITEM_DOCUMENT_CATEGORIES": "Κατηγορίες εγγράφων",
      "ITEM_DOCUMENT_TEMPLATES": "Πρότυπα εγγράφων",
      "ITEM_TAGS_GROUPS": "Ετικέτες & ομάδες",
      "ITEM_SHIFT_ROUTINES": "Ρουτίνες βάρδιας",
      "ITEM_REST_BREAKS": "Διακοπές ανάπαυσης",
      "ITEM_PAY_ELEMENTS": "Στοιχεία πληρωμής",
      "ITEM_PAY_RULES": "Κανόνες πληρωμής",
      "ITEM_PERMISSIONS": "Άδειες",
      "ITEM_BUDGETS": "Προϋπολογισμοί",
      "ITEM_ASSET_TYPES": "Τύποι περιουσιακών στοιχείων",
      "ITEM_ENTITY_GROUP_SUBSCRIPTIONS": 'Διαχειριστής πρόσβασης στον ιστότοπο',
      "ITEM_ENTITY_GROUPS": 'Διαχειριστής τοποθεσίας',
      "ITEM_POSITIONS": 'Ρόλοι εργασίας',
      "ITEM_LEAVER_REASONS": 'Λόγοι αποχώρησης',
      "ITEM_RECRUITMENT_SOURCES": 'Πηγές πρόσληψης',
      "ITEM_LABOUR_DEPLOYMENT": 'Ανάπτυξη εργατικού δυναμικού',
    },
    "DOCUMENT_TEMPLATES": {
      "TITLE": "Πρότυπα εγγράφων",
      "FILTER_LABEL_CATEGORY": "Κατηγορία",
      "BUTTON_UPLOAD": "Μεταφόρτωση",
      "NAME_COLUMN_LABEL": "Όνομα",
      "TEMPLATE_CATEGORY_COLUMN_LABEL": "Κατηγορία",
      "FILE_NAME_COLUMN_LABEL": "Όνομα αρχείου",
      "FILE_SIZE_COLUMN_LABEL": "Μέγεθος αρχείου",
      "DESTINATION_CATEGORY_COLUMN_LABEL": "Κατηγορία προορισμού",
      "SIGNATORIES_COLUMN_LABEL": "Υπογράφοντες",
      "EDITED_COLUMN_LABEL": "Προστέθηκε από",
      "OPTIONS_COLUMN_LABEL": "Επιλογές",
      "LINK_DELETE": "Διαγραφή",
      "SIGNATORIES_BOTH": "Εργαζόμενος, εργοδότης",
      "SIGNATORIES_EMPLOYEE": "Υπάλληλος",
      "NO_DOCS_MATCH_TEXT": "Δεν μπορέσαμε να βρούμε πρότυπα εγγράφων που να ταιριάζουν με τα φίλτρα σας.",
      "ERROR_NO_CATEGORIES": "Τα πρότυπα εγγράφων δεν είναι ενεργοποιημένα για τον οργανισμό σας. Συνομιλήστε μαζί μας για να ξεκινήσετε.",
      "ALERT_CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το πρότυπο; Αυτό δεν μπορεί να αναιρεθεί!",
      "ERROR_500": "Δεν μπορούμε να φορτώσουμε πρότυπα εγγράφων αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_403": "Δεν έχετε δικαιώματα προβολής/τροποποίησης προτύπων εγγράφων.",
      "UPLOAD_MODAL": {
        "TITLE": "Μεταφόρτωση προτύπου εγγράφου",
        "LABEL_NAME": "Όνομα",
        "HELP_TEXT_NAME": "Δώστε στο πρότυπο σας ένα όνομα που θα αναγνωρίσετε.",
        "LABEL_CATEGORY": "Κατηγορία προτύπων",
        "HELP_TEXT_CATEGORY": "Διατηρήστε τα πρότυπά σας οργανωμένα επιλέγοντας μια κατηγορία.",
        "LABEL_DESTINATION_CATEGORY": "Κατηγορία εγγράφου προορισμού",
        "HELP_TEXT_DESTINATION_CATEGORY": "Όταν χρησιμοποιείται αυτό το πρότυπο και υπογράφεται ένα δημιουργημένο έγγραφο, επιλέξτε την κατηγορία εγγράφου στην οποία θα πρέπει να αποθηκευτεί το αρχείο.",
        "LABEL_SIGNATORIES": "Υπογράφοντες",
        "SIGNATORIES_OPTION_BOTH": "Τόσο ο εργαζόμενος όσο και ο εργοδότης πρέπει να υπογράψουν.",
        "SIGNATORIES_OPTION_EMPLOYEE": "Μόνο ο υπάλληλος πρέπει να υπογράψει.",
        "LABEL_UPLOAD": "Μεταφόρτωση",
        "BUTTON_CANCEL": "Ακύρωση",
        "BUTTON_SAVE": "Αποθήκευση",
        "ALERT_SUCCESS": "Το πρότυπο εγγράφου σας έχει αποθηκευτεί.",
        "ALERT_ERROR_500": "Παρουσιάστηκε σφάλμα κατά τη μεταφόρτωση του προτύπου εγγράφου. Δοκιμάστε ξανά ή επικοινωνήστε μαζί μας εάν αυτό το μήνυμα παραμένει.",
        "ALERT_ERROR_400": "Υπάρχει κάτι που δεν πάει καλά με το πρότυπο εγγράφου σας."
      }
    },
    "ABSENCE_TYPES": {
      "TITLE": "Τύποι απουσίας",
      "ERROR_500": "Δεν μπορούμε να φορτώσουμε τύπους απουσίας αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_403": "Δεν έχετε δικαιώματα προβολής/τροποποίησης τύπων απουσίας.",
      "BUTTON_ADD_NEW": "Προσθήκη νέου",
      "BUTTON_TOGGLE_DELETED": "Συμπεριλάβετε διαγραμμένα",
      "COLUMN_NAME": "Όνομα",
      "COLUMN_BOOKABLE": "Με δυνατότητα κράτησης",
      "COLUMN_APPROVAL": "Απαιτείται έγκριση",
      "COLUMN_PAID": "Πληρώθηκε",
      "COLUMN_EDITED": "Επεξεργασία από",
      "COLUMN_OPTIONS": "Επιλογές",
      "YES_LABEL": "Ναί",
      "NO_LABEL": "Οχι",
      "LINK_EDIT": "Επεξεργασία",
      "LINK_DELETE": "Διαγραφή",
      "LINK_REINSTATE": "Επαναφορά",
      "PILL_DELETED": "Διαγράφηκε",
      "ALERT_CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον τύπο απουσίας;",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Ο τύπος απουσίας σας έχει αποθηκευτεί.",
        "ALERT_ERROR_500": "Παρουσιάστηκε σφάλμα κατά τη δημιουργία του τύπου απουσίας σας. Δοκιμάστε ξανά ή επικοινωνήστε μαζί μας εάν αυτό το μήνυμα παραμένει.",
        "ALERT_ERROR_400": "Υπάρχει ήδη ένας τύπος απουσίας με αυτό το όνομα. Παρακαλώ επιλέξτε ένα άλλο.",
        "ADD_TITLE": "Νέος τύπος απουσίας",
        "EDIT_TITLE": "Επεξεργασία τύπου απουσίας",
        "BUTTON_SAVE": "Αποθήκευση",
        "BUTTON_CANCEL": "Ακύρωση",
        "LABEL_NAME": "Όνομα",
        "LABEL_COLOUR": "Χρώμα",
        "LABEL_BOOKABLE": "Με δυνατότητα κράτησης",
        "LABEL_BOOKABLE_HELP": "Επιτρέψτε στους υπαλλήλους να υποβάλουν αιτήματα απουσίας αυτού του τύπου.",
        "LABEL_APPROVAL": "Έγκριση",
        "LABEL_APPROVAL_HELP": "Τα αιτήματα αυτού του τύπου απαιτούν έγκριση.",
        "LABEL_PAID": "Πληρώθηκε",
        "LABEL_PAID_HELP": "Η απουσία αυτού του τύπου καταβάλλεται.",
        "LABEL_WORKED_HOURS": "Ώρες εργασίας",
        "LABEL_WORKED_HOURS_HELP": "Η απουσία αυτού του τύπου συμβάλλει στις «ώρες εργασίας».",
        "LABEL_ACCRUES": "Συγκεντρώνει διακοπές",
        "LABEL_ACCRUES_HELP": "Οι εργαζόμενοι θα συγκεντρώνουν διακοπές για απουσία αυτού του τύπου εάν έχουν επίδομα διακοπών βάσει δεδουλευμένων.",
        "LABEL_OVERLAPS_SHIFTS": "Αγώνας βάρδιας",
        "LABEL_OVERLAPS_SHIFTS_HELP": "Η απουσία αυτού του τύπου μπορεί να συνδυαστεί με βάρδιες, στη θέση ενός ρολογιού και ενός ρολογιού.",
        "LABEL_TRONC": "Τρόνκ",
        "LABEL_TRONC_HELP": "Η απουσία αυτού του τύπου συμβάλλει στις ώρες εργασίας στο Tronc.",
        "LABEL_ALLOWANCE_REQUIRED": "Απαιτείται επίδομα",
        "LABEL_ALLOWANCE_REQUIRED_HELP": "Για να ζητήσετε απουσία αυτού του τύπου, βεβαιωθείτε ότι ο εργαζόμενος έχει ενεργό επίδομα.",
        "TITLE_FILE_UPLOADS": "Μεταφορτώσεις αρχείων",
        "LABEL_SUBMISSION_UPLOAD": "Κατόπιν συνεννόησης",
        "LABEL_SUBMISSION_UPLOAD_HELP": "Επιτρέψτε τη συνάρτηση ενός αρχείου όταν ζητείται απουσία αυτού του τύπου.",
        "LABEL_REVIEW_UPLOAD": "Κατά την αναθεώρηση",
        "LABEL_REVIEW_UPLOAD_HELP": "Επιτρέψτε τη συνάρτηση ενός αρχείου όταν η απουσία αυτού του τύπου έχει εγκριθεί ή απορριφθεί.",
        "LABEL_SAVE_TO": "Αποθήκευση σε",
        "HELP_TEXT_SAVE_TO": "Όταν ένα αρχείο επισυνάπτεται σε απουσία αυτού του τύπου, θα αποθηκευτεί στα έγγραφα του υπαλλήλου στην επιλεγμένη κατηγορία.",
        "FILE_UPLOAD_OPTION_NONE": "Απενεργοποιημένη",
        "FILE_UPLOAD_OPTION_OPTIONAL": "Προαιρετικό",
        "FILE_UPLOAD_OPTION_MANDATORY": "Απαιτείται",
        "INFO_LOCKED": "Αυτός είναι ένας ειδικός τύπος απουσίας που χρησιμοποιείται από το Rotaready. Έχουμε κρύψει κάποιες ιδιότητες που δεν μπορούν να αλλάξουν. Επικοινωνήστε εάν χρειάζεστε βοήθεια με αυτόν τον τύπο απουσίας.",
        "TITLE_PAY": "Πληρωμή",
        "LABEL_FIXED_AMOUNTS": "Σταθερά ποσά",
        "HELP_TEXT_FIXED_AMOUNTS": "Όταν πληρώνετε έναν υπάλληλο ένα σταθερό ποσό, μια τέτοια πληρωμή για δεδουλευμένη αλλά μη καταβληθείσα απουσία, θα χρησιμοποιηθεί αυτό το στοιχείο αμοιβής.",
        "TITLE_REASONS": "Λόγοι",
        "COLUMN_TITLE": "Αιτιολογία",
        "COLUMN_OPTIONS": "Επιλογές",
        "LINK_DELETE": "Διαγραφή",
        "NO_REASONS": "Δεν έχουν προστεθεί λόγοι.",
        "PLACEHOLDER_REASON": "Νέος λόγος...",
        "LINK_ADD": "Προσθήκη"
      }
    },
    "OUTSOURCING": {
      "TITLE": "Εξωτερική ανάθεση",
      "BUTTON_ADD_NEW": "Προσθήκη νέου",
      "BUTTON_TOGGLE_DELETED": "Συμπεριλάβετε διαγραμμένα",
      "NO_DATA_TEXT": "Δεν υπάρχουν οργανισμοί εξωτερικής ανάθεσης για εμφάνιση.",
      "NAME_COLUMN_TEXT": "Όνομα",
      "ENTITY_COLUMN_TEXT": "Τοποθεσία/τμήμα",
      "RESOURCES_COLUMN_TEXT": "Πόροι",
      "HOURS_COLUMN_TEXT": "Διαθέσιμες ώρες",
      "COST_COLUMN_TEXT": "Κόστος",
      "LAST_EDITED_COLUMN_TEXT": "Επεξεργασία από",
      "OPTIONS_COLUMN_TEXT": "Επιλογές",
      "UNLIMITED_TEXT": "Απεριόριστα",
      "ALWAYS_24_TEXT": "Πάντα (24 ώρες)",
      "PER_HOUR_TEXT": "/ώρα",
      "PER_DAY_TEXT": "/ημέρα",
      "ORG_WIDE_TEXT": "Σε ολόκληρο τον οργανισμό",
      "LINK_EDIT": "Επεξεργασία",
      "LINK_DELETE": "Διαγραφή",
      "LINK_REINSTATE": "Επαναφορά",
      "PILL_DELETED": "Διαγράφηκε",
      "ERROR_500": "Δεν μπορούμε να φορτώσουμε οργανισμούς εξωτερικής ανάθεσης αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_403": "Δεν έχετε άδεια να προβάλετε/τροποποιήσετε οργανισμούς εξωτερικής ανάθεσης.",
      "ALERT_CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον οργανισμό εξωτερικής ανάθεσης;",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Ο οργανισμός εξωτερικής ανάθεσης έχει σωθεί.",
        "ALERT_ERROR_500": "Παρουσιάστηκε σφάλμα κατά τη δημιουργία του οργανισμού εξωτερικής ανάθεσης. Δοκιμάστε ξανά ή επικοινωνήστε μαζί μας εάν αυτό το μήνυμα παραμένει.",
        "ALERT_ERROR_400": "Υπάρχει ήδη ένας οργανισμός εξωτερικής ανάθεσης με αυτό το όνομα. Παρακαλώ επιλέξτε ένα άλλο.",
        "EDIT_TITLE": "Επεξεργασία οργανισμού",
        "ADD_TITLE": "Προσθήκη οργανισμού",
        "NAME_LABEL": "Όνομα",
        "ENTITY_LABEL": "Τοποθεσία/τμήμα",
        "PAY_LABEL": "Πληρωμή",
        "HOURLY_OPTION": "Ωριαία αμειβόμενη",
        "FIXED_OPTION": "Σταθερό κόστος ανά ημέρα",
        "HOURLY_RATE_LABEL": "Ωριαία τιμή",
        "DAILY_RATE_LABEL": "Ημερήσια τιμή",
        "AUTOMATIC_SCHEDULE_TITLE": "Αυτοματοποιημένος προγραμματισμός",
        "HOURS_AVAILABLE_LABEL": "Διαθέσιμες ώρες",
        "TO_TEXT": " να ",
        "HOURS_AVAILABLE_HELP": "Ο χρόνος κατά τον οποίο οι εργαζόμενοι από αυτόν τον οργανισμό είναι διαθέσιμοι",
        "RESOURCES_AVAILABLE_LABEL": "Διαθέσιμοι πόροι",
        "RESOURCES_AVAILABLE_HELP": "Ο μέγιστος αριθμός εργαζομένων στη διάθεσή σας ανά ημέρα (εισάγετε 0 για απεριόριστο)",
        "TAGS_LABEL": "Ετικέτες",
        "TAGS_PLACEHOLDER": "Πληκτρολογήστε για ετικέτες αναζήτησης",
        "TAGS_HELP": "Εκχωρήστε ετικέτες όπως θα κάνατε σε ένα κανονικό μέλος του προσωπικού. Αυτά θα ισχύουν για όλους τους εργαζόμενους αυτής της οργάνωσης.",
        "BUTTON_SAVE": "Αποθήκευση",
        "BUTTON_CANCEL": "Ακύρωση"
      }
    },
    "TRUSTED_DEVICES": {
      "TITLE": "Αξιόπιστες συσκευές",
      "DEVICE": "Συσκευή",
      "ENTITY": "Πρωτοβάθμια τοποθεσία/τμήμα",
      "DEVICE_TYPE": "Πράκτορας χρήστη",
      "EDITED_BY": "Επεξεργασία από",
      "LAST_ACTIVITY": "Τελευταία δραστηριότητα",
      "STATUS": "Κατάσταση",
      "OPTIONS": "Επιλογές",
      "NO_DEVICES": "Δεν υπάρχουν αξιόπιστες συσκευές για την καταγραφή της συμμετοχής.",
      "NO_ACTIVITY": "Καμία δραστηριότητα",
      "LINK_UNTRUST": "Μη εμπιστοσύνη",
      "LINK_TRUST": "Εμπιστοσύνη",
      "LINK_EDIT": "Επεξεργασία",
      "ALERT_CONFIRM_TRUST": "Είστε βέβαιοι ότι θέλετε να εμπιστευτείτε αυτήν τη συσκευή για καταγραφή παρουσίας;",
      "ALERT_CONFIRM_UNTRUST": "Είστε βέβαιοι ότι θέλετε να μην εμπιστευτείτε αυτήν τη συσκευή, ώστε να μην μπορεί πλέον να χρησιμοποιηθεί για καταγραφή παρουσίας? Αυτό δεν μπορεί να αντιστραφεί.",
      "ERROR_500": "Δεν μπορούμε να φορτώσουμε αξιόπιστες συσκευές αυτή τη στιγμή. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_403": "Δεν έχετε δικαιώματα προβολής/τροποποίησης αξιόπιστων συσκευών.",
      "STATUS_PENDING": "Σε αναμονή",
      "STATUS_TRUSTED": "Αξιόπιστος",
      "STATUS_UNTRUSTED": "Μη αξιόπιστος",
      "FILTER_SITE": "Χαρτογραφημένη τοποθεσία/τμήμα",
      "FILTER_STATUS": "Κατάσταση",
      "BUTTON_NEW_DEVICE": "Νέα συσκευή",
      "NEW_DEVICE_MODAL": {
        "TITLE": "Προσθήκη νέας συσκευής",
        "BUTTON_CANCEL": "Ακύρωση",
        "BUTTON_DONE": "Συνέχισε",
        "TEXT_STEP_1": "Βήμα 1",
        "TEXT_STEP_2": "Βήμα 2",
        "TEXT_STEP_1_BODY": "Πάρτε το tablet ή το φορητό υπολογιστή σας, ανοίξτε ένα πρόγραμμα περιήγησης ιστού και μεταβείτε στην ακόλουθη διεύθυνση URL:",
        "TEXT_STEP_2_BODY_A": "Όταν σας ζητηθεί ένα «Realm ID», εισαγάγετε την ακόλουθη τιμή:",
        "TEXT_STEP_2_BODY_B": "Όταν σας ζητηθεί ένα «Αναγνωριστικό οντότητας», εισαγάγετε την ακόλουθη τιμή:",
        "TEXT_STEP_3_BODY": "Όταν τελειώσετε, κάντε κλικ στο κουμπί Συνέχεια για να επιστρέψετε στη λίστα συσκευών. Στη συνέχεια θα εμφανιστεί η νέα συσκευή σας. Κάντε κλικ στην επιλογή Εμπιστοσύνη δίπλα στη συσκευή για να ολοκληρώσετε τη διαδικασία αντιστοίχισης."
      },
      "EDIT_DEVICE_MODAL": {
        "TITLE": "Επεξεργασία συσκευής",
        "BUTTON_CANCEL": "Ακύρωση",
        "BUTTON_SAVE": "Αποθήκευση",
        "BODY": "Επιλέγοντας περισσότερους ιστότοπους ή τμήματα παρακάτω, οι σχετικοί υπάλληλοι θα εμφανιστούν στην αρχική οθόνη της συσκευής. Δεν μπορείτε να καταργήσετε την επιλογή της κύριας τοποθεσίας/τμήματος. πρέπει να μην εμπιστευτείτε τη συσκευή εάν θέλετε να το αλλάξετε.",
        "ALERT_200": "Η συσκευή έχει ενημερωθεί.",
        "ALERT_500": "Δεν μπορούσαμε να ενημερώσουμε τη συσκευή τότε. Παρακαλώ δοκιμάστε ξανά αργότερα.",
        "LABEL_NAME": "Όνομα συσκευής"
      }
    }
  },
  "MESSAGING": {
    "BETA": "ΒΉΤΑ",
    "MESSAGE_HUB": {
      "TITLE": "Μηνύματα",
      "NO_MESSAGES": "Δεν υπάρχουν μηνύματα",
      "START_NEW": "Έναρξη νέας συνομιλίας",
      "YESTERDAY": "χθες"
    },
    "CONVERSATION": {
      "MESSAGE_PLACEHOLDER": "Προσθέστε το μήνυμά σας",
      "INDIVIDUAL_TYPING": "{{name}} πληκτρολογεί...",
      "MULTIPLE_TYPING": "Πολλοί άνθρωποι πληκτρολογούν..."
    },
    "START_NEW": {
      "TITLE": "Έναρξη νέας συνομιλίας",
      "TO": "Προς:",
      "REMOVE": "Αφαιρέστε",
      "START_CONVERSATION": "Έναρξη συνομιλίας"
    },
    "ALERTS": {
      "START_CONVERSATION": "Παρουσιάστηκε σφάλμα κατά την έναρξη της συνομιλίας σας. Παρακαλώ προσπαθήστε ξανά.",
      "SUBSCRIBE_TO_CHANNELS": "Υπάρχει πρόβλημα εγγραφής στις συνομιλίες σας. Ενδέχεται να μην λαμβάνετε μηνύματα. Ανανεώστε, ή επικοινωνήστε εάν αυτό επιμένει.",
      "FETCH_MESSAGES": "Υπάρχει πρόβλημα με τη λήψη των μηνυμάτων σας. Ανανεώστε, ή επικοινωνήστε εάν αυτό επιμένει.",
      "FETCH_NEW_MESSAGES": "Παρουσιάστηκε σφάλμα κατά τη λήψη νέων μηνυμάτων. Ανανεώστε, ή επικοινωνήστε εάν αυτό επιμένει.",
      "FETCH_CONVERSATIONS": "Παρουσιάστηκε σφάλμα κατά την ανάκτηση των συνομιλιών σας. Ανανεώστε, ή επικοινωνήστε εάν αυτό επιμένει.",
      "SEND_MESSAGE": "Παρουσιάστηκε σφάλμα κατά την αποστολή του μηνύματός σας. Παρακαλώ προσπαθήστε ξανά."
    }
  },
  "ABSENCE": {
    "REQUEST": {
      "TITLE": "Αίτημα απουσίας",
      "BUTTON_SUBMIT": "Υποβολή",
      "LABEL_TYPE": "Επιλέξτε έναν τύπο",
      "LABEL_REASON": "Επιλέξτε έναν λόγο",
      "LABEL_EMPLOYEE": "Υπάλληλος",
      "LABEL_START_DATE": "Ημερομηνία έναρξης",
      "LABEL_END_DATE": "Ημερομηνία λήξης",
      "LABEL_SCOPE_ALL_DAY": "Όλη μέρα",
      "LABEL_SCOPE_AM": "Μόνο AM",
      "LABEL_SCOPE_PM": "Μόνο PM",
      "LABEL_SCOPE_CUSTOM": "Επιλέξτε συγκεκριμένες ώρες",
      "LABEL_START_TIME": "Ώρα έναρξης",
      "LABEL_END_TIME": "Ώρα λήξης",
      "ALERT_OVERLAPPING_ABSENCE_USER": "Έχετε μια άλλη κράτηση απουσίας που επικαλύπτεται με την επιλεγμένη ημερομηνία (ες).",
      "ALERT_OVERLAPPING_ABSENCE_OTHERS": "Ακριβώς για να ξέρετε, υπάρχουν και άλλοι άνθρωποι που απουσιάζουν αυτή τη στιγμή.",
      "ERROR_500": "Μόλις τότε χτυπήσαμε ένα εμπόδιο. Παρακαλώ προσπαθήστε ξανά!",
      "LABEL_DAYS": "καιρός",
      "LABEL_HOURS": "ώρες",
      "SECTION_UNPAID_DAYS": "Κανονικές ημέρες αργίας",
      "HELP_TEXT_NO_UNPAID_DAYS": "Δεν υπάρχουν κανονικές ημέρες αργίας σε αυτό το αίτημα.",
      "BUTTON_OPTIONS": "Επιλογές",
      "BUTTON_CHANGE": "Αλλάξτε τις κανονικές ημέρες αργίας",
      "BUTTON_RESET": "Επαναφέρετε τις κανονικές ημέρες αργίας στις προτεινόμενες",
      "BUTTON_CLEAR": "Καθαρίστε όλες τις κανονικές ημέρες αργίας",
      "MODAL_CALENDAR_TITLE": "Επιλέξτε κανονικές ημέρες αργίας",
      "MODAL_BUTTON_SAVE": "Αποθήκευση",
      "LABEL_ADD_A_NOTE": "Προσθήκη σημείωσης σε αυτό το αίτημα",
      "BUTTON_COMPLETE": "Πάρε με πίσω",
      "COMPLETE_TITLE": "Είσαι έτοιμος.",
      "COMPLETE_LABEL_APPROVED": "Εγκεκριμένο",
      "COMPLETE_TEXT_APPROVED": "Το αίτημά σας έχει υποβληθεί και εγκριθεί αυτόματα ✅",
      "COMPLETE_LABEL_PENDING": "Σε αναμονή έγκρισης",
      "COMPLETE_TEXT_PENDING": "Το αίτημά σας έχει υποβληθεί και αναμένει έγκριση. Θα σας ειδοποιήσουμε όταν αλλάξει αυτό 👍"
    },
    "VALIDATION_MODAL": {
      "TITLE": "Κάτι δεν πάει καλά",
      "DESCRIPTION": "Δυστυχώς, αυτό που προσπαθείτε να κάνετε προκαλεί ένα ή περισσότερα προβλήματα.",
      "BUTTON_CLOSE": "Κλείσιμο",
      "INVALID_DATES_TITLE": "Μη έγκυρες ημερομηνίες",
      "INVALID_DATES_DESCRIPTION": "Μπορείτε να επιλέξετε μόνο AM, PM μόνο, ή προσαρμοσμένες ώρες εάν η ημερομηνία έναρξης και λήξης είναι η ίδια ημέρα.",
      "INVALID_HEADER_TITLE": "Μη έγκυρη εγγραφή απουσίας",
      "INVALID_HEADER_DESCRIPTION": "Δεν μπορείτε να επεξεργαστείτε αυτό το αρχείο απουσίας. Μπορεί να έχει διαγραφεί.",
      "INVALID_ACTION_TITLE": "Μη έγκυρη ενέργεια",
      "INVALID_ACTION_DESCRIPTION": "Δεν μπορείτε να αλλάξετε την ώρα μιας κράτησης απουσίας αφού απορριφθεί ή ακυρωθεί.",
      "INVALID_DAYS_TITLE": "Μη έγκυρες ημέρες",
      "INVALID_DAYS_DESCRIPTION": "Δεν μπορείτε να υποβάλετε αίτημα απουσίας για μηδενικές ώρες ή μηδενικές ημέρες.",
      "OUTSIDE_EMPLOYMENT_TITLE": "Εξωτερική απασχόληση",
      "OUTSIDE_EMPLOYMENT_DESCRIPTION": "Η επιλεγμένη ημερομηνία έναρξης ή λήξης πέφτει εκτός των ημερομηνιών απασχόλησης του υπαλλήλου.",
      "SPANS_TIMEZONES_TITLE": "Το εύρος ημερομηνιών εκτείνεται σε δύο ζώνες ώρας",
      "SPANS_TIMEZONES_DESCRIPTION": "Τα αρχεία ραντεβού του υπαλλήλου υποδεικνύουν ότι μετακινούνται τον ιστότοπο κατά τις επιλεγμένες ημερομηνίες και ότι ο υπάρχων ιστότοπός του βρίσκεται σε διαφορετική ζώνη ώρας από τη νέα του τοποθεσία. Βεβαιωθείτε ότι η κράτηση απουσίας σας λήγει πριν μετακινήσουν τον ιστότοπο ή ξεκινά μετά τη μετακίνηση του ιστότοπου.",
      "SPANS_PAYROLL_CALENDARS_TITLE": "Το εύρος ημερομηνιών καλύπτει δύο ημερολόγια μισθοδοσίας",
      "SPANS_PAYROLL_CALENDARS_DESCRIPTION": "Τα αρχεία ραντεβού του υπαλλήλου δείχνουν ότι αλλάζουν ημερολόγιο μισθοδοσίας κατά τις επιλεγμένες ημερομηνίες. Βεβαιωθείτε ότι η κράτηση απουσίας σας λήγει πριν αλλάξουν το ημερολόγιο μισθοδοσίας ή ξεκινά αφού αλλάξουν το ημερολόγιο μισθοδοσίας.",
      "INVALID_PAYROLL_CALENDAR_TITLE": "Μη έγκυρο ημερολόγιο μισθοδοσίας",
      "INVALID_PAYROLL_CALENDAR_DESCRIPTION": "Το επιλεγμένο εύρος ημερομηνιών ξεκινά πριν από την πρώτη πληρωμή του ημερολογίου μισθοδοσίας του υπαλλήλου.",
      "OVERLAPPING_ABSENCE_TITLE": "Επικαλυπτόμενη απουσία",
      "OVERLAPPING_ABSENCE_DESCRIPTION": "Ο υπάλληλος απουσιάζει ήδη σε μία ή περισσότερες ημέρες που αλληλεπικαλύπτονται με τις ημερομηνίες που επιλέξατε.",
      "OVERLAPPING_SHIFTS_TITLE": "Επικαλυπτόμενες β",
      "OVERLAPPING_SHIFTS_DESCRIPTION": "Ο υπάλληλος έχει μία ή περισσότερες δημοσιευμένες βάρδιες που αλληλεπικαλύπτονται με τις ημερομηνίες που επιλέξατε. Αυτά πρέπει πρώτα να διαγραφούν ή να καταργηθούν.",
      "SIGNED_OFF_TITLE": "Ημερομηνίες αποσύνδεσης",
      "SIGNED_OFF_DESCRIPTION": "Οι ημερομηνίες αλληλεπικαλύπτονται με μία ή περισσότερες ημέρες που έχουν υπογραφεί. Δεν μπορείτε να εγκρίνετε ή να ακυρώσετε την απουσία για ημέρες που έχουν ήδη αποσυνδεθεί.",
      "ALLOWANCE_REQUIRED_TITLE": "Απαιτείται επίδομα",
      "ALLOWANCE_REQUIRED_DESCRIPTION": "Δεν μπορείτε να κλείσετε απουσία αυτού του τύπου. Πρέπει να υπάρχει ενεργό επίδομα για έκπτωση.",
      "ALLOWANCE_NOT_DEDUCTIBLE_TITLE": "Το επίδομα δεν εκπίπτει",
      "ALLOWANCE_NOT_DEDUCTIBLE_DESCRIPTION": "Δεν μπορείτε να κλείσετε απουσία αυτού του τύπου. Υπάρχει ένα ενεργό επίδομα, αλλά επισημαίνεται ως μη εκπεστέο.",
      "ALLOWANCE_BALANCE_INSUFFICIENT_TITLE": "Ανεπαρκής ισορροπία",
      "ALLOWANCE_BALANCE_INSUFFICIENT_DESCRIPTION": "Το υπόλοιπο των αποζημιώσεων είναι πολύ χαμηλό για να υποβληθεί αυτό το αίτημα.",
      "FILE_UPLOAD_REQUIRED_TITLE": "Απαιτείται μεταφόρτωση αρχείου",
      "FILE_UPLOAD_REQUIRED_DESCRIPTION": "Αυτός ο τύπος απουσίας απαιτεί να ανεβάσετε ένα αρχείο πριν υποβληθεί ή εγκριθεί."
    },
    "VIEW_MODAL": {
      "TITLE": "Απουσία",
      "BUTTON_OPTIONS": "Επιλογές",
      "BUTTON_EDIT": "Επεξεργασία/αναθεώρηση κράτησης",
      "BUTTON_CANCEL": "Ακύρωση κράτησης",
      "BUTTON_CLOSE": "Κλείσιμο",
      "STATUS_CANCELLED": "Ακυρώθηκε",
      "LABEL_START_DATE": "Ξεκινά",
      "LABEL_END_DATE": "Τελειώνει",
      "LABEL_ABSENCE_TYPE": "Τύπος",
      "LABEL_SCOPE": "Διάρκεια",
      "ERROR_500": "Χτυπήσαμε ένα μικρό εμπόδιο. Παρακαλώ προσπαθήστε ξανά.",
      "ERROR_404": "Αυτό το αίτημα απουσίας δεν υπάρχει πλέον.",
      "LABEL_SUBMITTED_BY": "Υποβλήθηκε από",
      "LABEL_REVIEWED_BY": "Αναθεωρήθηκε από",
      "LABEL_CANCELLED_BY": "Ακυρώθηκε από",
      "LABEL_EDITED_BY": "Επεξεργασία από",
      "DURATION_TEXT": "{{ώρες}} ώρες, {{ημέρες}} ημέρες",
      "TAB_BREAKDOWN": "Κατανομή",
      "TITLE_PAY_PERIODS": "Ανά περίοδο πληρωμής",
      "NO_PAY_PERIODS": "Ο υπάλληλος δεν συσχετίστηκε με ημερολόγιο μισθοδοσίας κατά την πραγματοποίηση αυτής της κράτησης.",
      "TITLE_DAILY_BREAKDOWN": "Κατά τη μέρα",
      "NO_DAYS": "Δεν υπάρχουν ημέρες σε αυτήν την κράτηση.",
      "TAB_FILES": "Έγγραφα",
      "FILES_NONE": "Τίποτα δεν έχει μεταφορτωθεί για αυτήν την κράτηση.",
      "FILES_AVAILABLE": "Αυτά τα έγγραφα μπορούν επίσης να βρεθούν στην περιοχή Προσωπικό κατά την προβολή του προφίλ του υπαλλήλου. Θα τα βρείτε κάτω από αυτή την κατηγορία: {{categoryName}}",
      "FILES_COLUMN_NAME": "Όνομα",
      "FILES_COLUMN_UPLOADED_BY": "Ανεβάστηκε από",
      "FILES_COLUMN_OPTIONS": "Επιλογές",
      "FILES_LINK_DELETE": "Διαγραφή",
      "FILES_CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να το διαγράψετε; Θα αφαιρεθεί επίσης από την καρτέλα Έγγραφα του προφίλ του υπαλλήλου.",
      "CONFIRM_CANCEL": "Είστε βέβαιοι ότι θέλετε να ακυρώσετε αυτήν την κράτηση απουσίας; Αυτό δεν μπορεί να αναιρεθεί.",
      "ALERT_IS_CANCELLED": "Η κράτηση απουσίας ακυρώθηκε.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Προειδοποίηση! Ο αιτών επέλεξε χρόνο έναρξης και λήξης που ισοδυναμεί με περισσότερες από {{HalfDayHours}} ώρες, που είναι ο αριθμός των ωρών που εργάζονται επί του παρόντος για μισή ημέρα.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Προειδοποίηση! Ο αιτών επέλεξε να τροποποιήσει τις κανονικές ημέρες αργίας, αντί να χρησιμοποιήσει τις προτεινόμενες ημέρες του Rotaready."
    },
    "EDIT": {
      "TITLE": "Απουσία",
      "BUTTON_SUBMIT": "Αποθήκευση",
      "BUTTON_APPROVE": "Έγκριση",
      "BUTTON_REJECT": "Απόρριψη",
      "TAB_EDIT_BOOKING": "Επεξεργασία ημερομηνιών",
      "TAB_REVIEW": "Αναθεώρηση",
      "TAB_FILES": "Έγγραφα",
      "TAB_CALENDAR": "Ημερολόγιο",
      "LABEL_TYPE": "Τύπος",
      "LABEL_REASON": "Επιλέξτε έναν λόγο",
      "LABEL_EMPLOYEE": "Υπάλληλος",
      "LABEL_START_DATE": "Ημερομηνία έναρξης",
      "LABEL_END_DATE": "Ημερομηνία λήξης",
      "LABEL_SCOPE_ALL_DAY": "Όλη μέρα",
      "LABEL_SCOPE_AM": "Μόνο AM",
      "LABEL_SCOPE_PM": "Μόνο PM",
      "LABEL_SCOPE_CUSTOM": "Επιλέξτε συγκεκριμένες ώρες",
      "LABEL_START_TIME": "Ώρα έναρξης",
      "LABEL_END_TIME": "Ώρα λήξης",
      "ERROR_500": "Μόλις τότε χτυπήσαμε ένα εμπόδιο. Παρακαλώ προσπαθήστε ξανά!",
      "LABEL_DAYS": "καιρός",
      "LABEL_HOURS": "ώρες",
      "SECTION_UNPAID_DAYS": "Κανονικές ημέρες αργίας",
      "HELP_TEXT_NO_UNPAID_DAYS": "Δεν υπάρχουν κανονικές ημέρες αργίας σε αυτό το αίτημα.",
      "BUTTON_OPTIONS": "Επιλογές",
      "BUTTON_CHANGE": "Αλλάξτε τις κανονικές ημέρες αργίας",
      "BUTTON_RESET": "Επαναφορά στην αρχική κράτηση",
      "BUTTON_RECALCULATE": "Επανυπολογίστε εκ νέου",
      "BUTTON_CLEAR": "Καθαρίστε όλες τις κανονικές ημέρες αργίας",
      "UNPAID_DAYS_RECALCULATED_LABEL": "Επανυπολογίστηκε",
      "UNPAID_DAYS_RECALCULATED_HELP": "Επιλέξατε να υπολογίσετε εκ νέου τις κανονικές ημέρες αργίας και να χρησιμοποιήσετε την επικρατούσα τιμή για τις «ώρες ανά εργάσιμη ημέρα» του υπαλλήλου αντί για εκείνη που ήταν κατά την αρχική πραγματοποίηση της κράτησης.",
      "UNPAID_DAYS_MERGED_LABEL": "Συγχωνευμένο",
      "UNPAID_DAYS_MERGED_HELP": "Αφού αλλάξατε την ημερομηνία, συμπεριλάβαμε τυχόν νέες ημέρες αργίας, αφαιρέσαμε όσες δεν εμπίπτουν πλέον στο πεδίο εφαρμογής και αφήσαμε τις άλλες ανέγγιχτες.",
      "MODAL_CALENDAR_TITLE": "Επιλέξτε κανονικές ημέρες αργίας",
      "MODAL_BUTTON_SAVE": "Αποθήκευση",
      "BUTTON_COMPLETE": "Πάρε με πίσω",
      "COMPLETE_TITLE": "Είσαι έτοιμος να φύγεις.",
      "COMPLETE_TEXT": "Αποθηκεύσαμε την κράτηση απουσίας και ενημερώθηκαν τα σχετικά άτομα 👍",
      "INVALID_HEADER_TITLE": "Μη έγκυρη απουσία",
      "INVALID_HEADER_TEXT": "Δεν μπορούμε να φορτώσουμε την κράτηση απουσίας. Ίσως έχει ακυρωθεί ή δεν έχετε άδεια να το δείτε.",
      "TITLE_OVERLAPPING_ABSENCE": "Ταυτόχρονη απουσία",
      "TITLE_OVERLAPPING_SHIFTS": "Επικαλυπτόμενες β",
      "TITLE_NOTABLE_EVENTS": "Αξιοσημείωτα γεγονότα",
      "COLUMN_OVERLAPPING_ABSENCE_DATES": "Ημερομηνίες",
      "COLUMN_OVERLAPPING_ABSENCE_TYPE": "Τύπος",
      "COLUMN_OVERLAPPING_ABSENCE_WHO": "Ποιος",
      "NO_OVERLAPPING_ABSENCE": "Δεν υπάρχει ταυτόχρονη απουσία που να ταιριάζει με τα φίλτρα σας.",
      "NO_OVERLAPPING_SHIFTS": "Δεν υπάρχουν αλληλεπικαλυπτόμενες βάρδιες στην τοποθεσία/τμήμα του υπαλλήλου.",
      "NO_NOTABLE_EVENTS": "Δεν υπάρχουν αξιοσημείωτα γεγονότα τη στιγμή αυτής της απουσίας.",
      "COLUMN_OVERLAPPING_SHIFTS_DATE": "Ημερομηνία",
      "COLUMN_OVERLAPPING_SHIFTS_SITE": "Τοποθεσία/τμήμα",
      "COLUMN_OVERLAPPING_SHIFTS_TYPE": "Τύπος αλλαγής ταχυτήτων",
      "COLUMN_OVERLAPPING_SHIFTS_OPTIONS": "Επιλογές",
      "LINK_EDIT_SHIFT": "Επεξεργασία διαδρομής",
      "LINK_DELETE_SHIFT": "Διαγραφή",
      "BUTTON_BULK_SHIFT_OPTIONS": "Μαζικές ενέργειες",
      "BUTTON_BULK_SHIFT_UNASSIGN_ALL": "Κατάργηση εκχώρησης επιλεγμένων",
      "BUTTON_BULK_SHIFT_DELETE_ALL": "Διαγραφή επιλεγμένων",
      "ALERT_BULK_SHIFT_CONFIRM": "Είστε βέβαιοι ότι θέλετε να το κάνετε αυτό σε βάρδιες {{count}};",
      "ALERT_BULK_SHIFT_EDIT_FAILURE": "Δεν μπορούσαμε να τροποποιήσουμε τις βάρδιες επειδή θα προκαλούσε πρόβλημα επικύρωσης. Κάντε αυτήν την αλλαγή στο δρομολόγιο για να δείτε περισσότερες πληροφορίες.",
      "ALERT_SINGLE_SHIFT_DELETE_FAILURE": "Δεν μπορέσαμε να διαγράψουμε αυτήν τη μετατόπιση επειδή θα προκαλούσε πρόβλημα επικύρωσης. Κάντε αυτήν την αλλαγή στο δρομολόγιο για να δείτε περισσότερες πληροφορίες.",
      "COLUMN_NOTABLE_EVENTS_NAME": "Όνομα",
      "COLUMN_NOTABLE_EVENTS_DATE": "Ημερομηνία",
      "NOTABLE_EVENTS_ABSENCE_RESTRICTION_TOOLTIP": "Η απουσία είναι περιορισμένη κατά τη διάρκεια αυτής της εκδήλωσης.",
      "LABEL_ADD_A_NOTE": "Προαιρετικά γράψτε ένα μήνυμα για τον υπάλληλο",
      "OVERLAPPING_SHIFTS_MORE": "Δείχνουμε μόνο τις πρώτες δεκαπέντε αλληλεπικαλυπτόμενες βάρδιες. Επιλύστε τα για να δείτε περισσότερα.",
      "OVERLAPPING_ABSENCE_MORE": "Εμφανίζουμε μόνο τις πρώτες δεκαπέντε επικαλυπτόμενες κρατήσεις.",
      "CALENDAR_UNPAID_DAY": "Κανονική ημέρα ρεπό",
      "FILTER_LABEL_ENTITY": "Τοποθεσία/τμήμα",
      "FILTER_LABEL_STAFF_GROUP": "Ομάδα προσωπικού",
      "FILTER_LABEL_ABSENCE_TYPE": "Τύπος",
      "FILES_NONE": "Τίποτα δεν έχει μεταφορτωθεί για αυτήν την κράτηση.",
      "FILES_AVAILABLE": "Αυτά τα έγγραφα μπορούν επίσης να βρεθούν στην περιοχή Προσωπικό κατά την προβολή του προφίλ του υπαλλήλου. Θα τα βρείτε κάτω από αυτή την κατηγορία: {{categoryName}}",
      "FILES_COLUMN_NAME": "Όνομα",
      "FILES_COLUMN_UPLOADED_BY": "Ανεβάστηκε από",
      "FILES_COLUMN_OPTIONS": "Επιλογές",
      "FILES_LINK_DELETE": "Διαγραφή",
      "FILES_CONFIRM_DELETE": "Είστε βέβαιοι ότι θέλετε να το διαγράψετε; Θα αφαιρεθεί επίσης από την καρτέλα Έγγραφα του προφίλ του υπαλλήλου.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Προειδοποίηση! Ο αιτών επέλεξε χρόνο έναρξης και λήξης που ισοδυναμεί με περισσότερες από {{HalfDayHours}} ώρες, που είναι ο αριθμός των ωρών που εργάζονται επί του παρόντος για μισή ημέρα.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Προειδοποίηση! Ο αιτών επέλεξε να τροποποιήσει τις κανονικές ημέρες αργίας, αντί να χρησιμοποιήσει τις προτεινόμενες ημέρες του Rotaready. Ελέγξτε τα προσεκτικά στην καρτέλα Επεξεργασία ημερομηνιών και επιλέξτε την επιλογή Επανυπολογισμός εκ νέου εάν είναι απαραίτητο."
    },
    "ENUM_SCOPE": {
      "ALL_DAY": "Όλη μέρα",
      "AM": "Μόνο AM",
      "PM": "Μόνο PM",
      "CUSTOM": "Προσαρμοσμένες ώρες"
    },
    "ENUM_APPROVAL_STATE": {
      "APPROVED": "Εγκεκριμένο",
      "REJECTED": "Απορρίφθηκε",
      "PENDING": "Σε αναμονή"
    },
    "ENUM_ALLOWANCE_UNIT": {
      "DAYS": "Ημέρες",
      "HOURS": "Ωρες"
    },
    "ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL": {
      "BEGINNING_OF_ACCRUAL_YEAR": "Έναρξη του έτους δεδουλευμένης παραγωγής",
      "EACH_CALENDAR_MONTH": "Κάθε ημερολογιακό μήνα",
      "EACH_HOUR_WORKED": "Κάθε ώρα εργασίας"
    },
    "ALLOWANCE_IMPACT_DIRECTIVE": {
      "DAYS_DEBIT": "{{count}} έκπτωση ημέρας",
      "HOURS_DEBIT": "{{count}} αφαίρεση ώρας",
      "DAYS_CREDIT": "{{count}} πίστωση ημέρας",
      "HOURS_CREDIT": "{{count}} πίστωση ώρας",
      "REMAINING": "({{count}} απομένει)",
      "WARNING_EXCEEDS": "Το αίτημά σας υπερβαίνει αυτό το επίδομα, αλλά μπορεί ακόμα να υποβληθεί.",
      "WARNING_NOT_PERMITTED": "Το αίτημά σας υπερβαίνει αυτό το επίδομα.",
      "TOOLTIP_PROJECTED": "Το Rotaready έχει συμπεριλάβει μια πρόβλεψη για το τι είναι πιθανό να συγκεντρώσετε. Αυτό μπορεί να περιοριστεί σύμφωνα με τις πολιτικές του εργοδότη σας.",
      "CALC_TOTAL_ACCRUED": "Σύνολο δεδουλευμένων",
      "CALC_BOOKED": "Έγινε κράτηση",
      "CALC_PROJECTED": "Προβλεπόμενη δεδουλευμένη",
      "CALC_THIS_REQUEST": "Αυτό το αίτημα",
      "CALC_REMAINING": "Υπολειπόμενο",
      "CALC_CONVERTED_TO_DAYS": "Ως ημέρες"
    }
  }
};

module.exports = translations;
