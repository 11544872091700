const translations = {
  "STAFF": {
    "CONSTANTS": {
      "UK_STARTER_DECLARATION_A": "A: Dit is hun eerste baan sinds het begin van het belastingjaar (6 april)",
      "UK_STARTER_DECLARATION_B": "B: Dit is momenteel hun enige baan",
      "UK_STARTER_DECLARATION_C": "C: Ze hebben een andere baan of pensioen",
      "UK_STUDENT_LOAN_PLAN_1": "Plan 1",
      "UK_STUDENT_LOAN_PLAN_2": "Plan 2",
      "UK_STUDENT_LOAN_PLAN_4": "Plan 4",
      "UK_STUDENT_LOAN_PLAN_5": "Plan 5",
      "UK_STUDENT_LOAN_PLAN_POSTGRADUATE": "Postdoctoraal",
      "UK_SETTLED_STATUS_NA": "Niet van toepassing",
      "UK_SETTLED_STATUS_PRE_SETTLED": "Vooraf geregeld",
      "UK_SETTLED_STATUS_SETTLED": "Geregeld",
      "MARITAL_STATUS_SINGLE": "Alleenstaand",
      "MARITAL_STATUS_MARRIED": "Getrouwd",
      "MARITAL_STATUS_DIVORCED": "Gescheiden",
      "MARITAL_STATUS_WIDOWED": "Weduwe",
      "MARITAL_STATUS_CIVIL_PARTNERSHIP": "Burgerlijk partnerschap",
      "PAYMENT_METHOD_ELECTRONIC": "Elektronische overdracht",
      "PAYMENT_METHOD_CHEQUE": "Cheque",
      "PAYMENT_METHOD_MANUAL": "Handboek",
      "CONTRACTUAL_OBLIGATION_PERIOD_WEEK": "Per week",
      "CONTRACTUAL_OBLIGATION_PERIOD_MONTH": "Per maand",
      "CONTRACTUAL_OBLIGATION_UNIT_HOURS": "Uren",
      "CONTRACTUAL_OBLIGATION_UNIT_DAYS": "Dagen",
      "PAY_AMOUNT_TYPE_HOURLY": "Uurtarief",
      "PAY_AMOUNT_TYPE_DAILY": "Dagtarief",
      "PAY_AMOUNT_TYPE_ANNUAL": "Jaarsalaris",
      "PAY_FREQUENCY_WEEKLY": "Wekelijks",
      "PAY_FREQUENCY_FORTNIGHTLY": "Tweewekelijks",
      "PAY_FREQUENCY_MONTHLY": "Maandelijks",
      "PAY_FREQUENCY_FOUR_WEEKLY": "Vierwekelijks"
    },
    "OVERVIEW": {
      "ERROR_500": "We konden op dat moment geen gegevens laden. Probeer het nog eens.",
      "TITLE": "Personeel",
      "ADD_STAFF": "Nieuwe toevoegen",
      "NO_MATCH": "We konden niemand vinden die aan je filters voldoet.",
      "NO_ACTIVE_APPT_TAG": "Inactief",
      "NOT_APPROVED_TAG": "Niet goedgekeurd",
      "DOCUMENTS_INVALID_TAG": "Ongeldige documenten",
      "NOT_INVITED_TAG": "Niet uitgenodigd",
      "NOT_SETUP_TAG": "Uitgenodigd",
      "EMPLOYER_INCOMPLETE_TAG": "Onvolledig",
      "SELF_ONBOARDING": "Zelf onboarden",
      "ACCOUNT_TAB_LABEL": "Rekening",
      "PERSONAL_TAB_LABEL": "Persoonlijk",
      "EMPLOYMENT_TAB_LABEL": "Werkgelegenheid",
      "PAY_TAB_LABEL": "Betalen",
      "SCHEDULE_TAB_LABEL": "Schema",
      "ABSENCE_TAB_LABEL": "Afwezigheid",
      "AVAILABILITY_TAB_LABEL": "Beschikbaarheid",
      "SHIFT_ROUTINE_TAB_LABEL": "Ploegendiensten",
      "DOCUMENTS_TAB_LABEL": "Documenten",
      "ATTENDANCE_TAB_LABEL": "Aanwezigheid",
      "NOTES_TAB_LABEL": "Notities",
      "FILTER_LABEL_SITE": "Site/afdeling",
      "FILTER_LABEL_POSITION": "Rol op het werk",
      "FILTER_LABEL_STAFF_GROUP": "Personeelsgroep",
      "FILTER_LABEL_EMPLOYMENT": "Werkgelegenheidsstatus",
      "FILTER_LABEL_APPROVAL_STATUS": "Goedkeuringsstatus",
      "FILTER_LABEL_EMPLOYEE_SETUP": "Rotaready-uitnodiging",
      "FILTER_LABEL_EMPLOYER_SETUP": "Accountvoltooiing",
      "FILTER_LABEL_EMPLOYMENT_START": "Datum van aanmelding",
      "FILTER_LABEL_EMPLOYMENT_END": "Verlofdatum",
      "FILTER_LABEL_EMPLOYMENT_START_OR_END": "Datum van aanmelding of vertrek",
      "BUTTON_COLUMNS": "Columns",
      "BUTTON_EXPORT": "Exporteren",
      "EMPLOYMENT_STATUS_ACTIVE": "Actief",
      "EMPLOYMENT_STATUS_INACTIVE": "Inactief",
      "APPROVAL_STATUS_APPROVED": "Goedgekeurd",
      "APPROVAL_STATUS_NOT_APPROVED": "Niet goedgekeurd",
      "EMPLOYEE_SETUP_COMPLETE": "Geaccepteerd",
      "EMPLOYEE_SETUP_NOT_COMPLETE": "In behandeling of niet verzonden",
      "EMPLOYER_SETUP_COMPLETE": "Compleet",
      "EMPLOYER_SETUP_NOT_COMPLETE": "Onvolledig",
      "COLUMN_NAME": "Naam",
      "COLUMN_NEXT_SHIFT": "Volgende shift",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_BIRTH_DATE": "Geboortedatum",
      "COLUMN_EMAIL": "Email",
      "COLUMN_MOBILE": "Telefoon",
      "COLUMN_OTHER_NAMES": "Andere naam (namen)",
      "COLUMN_ADDRESS_LINE_1": "Adresregel 1",
      "COLUMN_ADDRESS_LINE_2": "Adresregel 2",
      "COLUMN_ADDRESS_CITY": "Stad/stad",
      "COLUMN_ADDRESS_COUNTY": "Provincie/regio",
      "COLUMN_ADDRESS_POST_CODE": "Postcode",
      "COLUMN_ADDRESS_COUNTRY": "Land",
      "COLUMN_PREFERRED_NAME": "Voorkeursnaam",
      "COLUMN_GENDER": "Geslacht",
      "COLUMN_TITLE": "Titel",
      "COLUMN_ETHNICITY": "Etniciteit",
      "COLUMN_NATIONALITY": "Nationaliteit",
      "COLUMN_MARITAL_STATUS": "Burgerlijke staat",
      "COLUMN_SETTLED_STATUS": "Vaste status (VK)",
      "COLUMN_PASSPORT_NUMBER": "Paspoortnummer",
      "COLUMN_PASSPORT_EXPIRY_DATE": "Vervalt van het paspoort",
      "COLUMN_WORK_EMAIL": "Secundair e-mailadres",
      "COLUMN_WORK_PHONE": "Secundaire telefoon",
      "COLUMN_BANK_ACCOUNT_NAME": "Naam van de bankrekening",
      "COLUMN_BANK_ACCOUNT_NUMBER": "Bankrekeningnummer",
      "COLUMN_BANK_ACCOUNT_SORT_CODE": "Sorteercode van de bank",
      "COLUMN_PAYROLL_PAYMENT_METHOD": "Wijze van betaling",
      "COLUMN_NATIONAL_INSURANCE_NUMBER": "National Insurance-nummer (VK)",
      "COLUMN_STUDENT_LOANS": "Studieleningen (VK)",
      "COLUMN_AVERAGE_HOURS_DAY": "Gemiddeld aantal uren per dag",
      "COLUMN_AVERAGE_HOURS_HALF_DAY": "Gemiddeld aantal uren/halve dag",
      "COLUMN_AVERAGE_HOURS_WEEK": "Gemiddeld aantal uren per week",
      "COLUMN_PRE_AVERAGE_HOURS_PER_DAY": "Pre-Rotaready, gemiddeld aantal uren per dag",
      "COLUMN_PRE_AVERAGE_HOURS_DATE": "Datum van gemiddelde uren vóór Rotaready",
      "COLUMN_PERMISSION_LEVEL_NAME": "Machtigingsniveau",
      "COLUMN_ANONYMISED": "Geanonimiseerd",
      "COLUMN_ANONYMISE_DATE": "Datum anonimiseren",
      "COLUMN_JOIN_DATE": "Datum van aanmelding",
      "COLUMN_LEAVE_DATE": "Verlofdatum",
      "COLUMN_LEAVE_DATE_EDITED_BY": "Verlofdatum ingesteld op",
      "COLUMN_LEAVE_DATE_EDITED_DATE": "Verlofdatum, vaste datum",
      "COLUMN_LENGTH_OF_SERVICE": "Duur van de service (dagen)",
      "COLUMN_RECRUITMENT_SOURCE": "Bron van rekrutering",
      "COLUMN_LEAVER_REASON": "Reden voor vertrek",
      "COLUMN_LEAVER_REHIRE": "Komt in aanmerking voor herhuur",
      "COLUMN_EMPLOYEE_NUMBER": "Nummer van de werknemer",
      "COLUMN_NOTICE_PERIOD": "Opzegtermijn",
      "COLUMN_STARTER_DECLARATION": "Startverklaring (VK)",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED": "Vergoedingen afgerond",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY": "Vergoedingen afgerond door",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE": "Definitieve datum van de toelagen",
      "COLUMN_SITE_NAME": "Site",
      "COLUMN_SITE_ID": "Site-ID",
      "COLUMN_DEPARTMENT_NAME": "Afdeling",
      "COLUMN_DEPARTMENT_ID": "ID van de afdeling",
      "COLUMN_JOB_ROLE_NAME": "Rol op het werk",
      "COLUMN_COMPANY_NAME": "Bedrijf",
      "COLUMN_CONTRACT_BASIS": "Soort contract",
      "COLUMN_LINE_MANAGER_NAME": "Lijnmanager",
      "COLUMN_STAFF_GROUP_NAME": "Personeelsgroep",
      "COLUMN_WORKS_PUBLIC_HOLIDAYS": "Op feestdagen op het werk",
      "COLUMN_WORKING_TIME_OPT_OUT": "Opt-out van de arbeidstijdenregeling",
      "COLUMN_MIN_OBLIGATION_VALUE": "Waarde van de contractuele verplichting",
      "COLUMN_MIN_OBLIGATION_UNIT": "Eenheid voor contractuele verplichtingen",
      "COLUMN_MIN_OBLIGATION_PERIOD": "Contractuele verplichtingsperiode",
      "COLUMN_MAX_OBLIGATION_VALUE": "Grenswaarde voor de werking",
      "COLUMN_MAX_OBLIGATION_UNIT": "Werklimieteenheid",
      "COLUMN_MAX_OBLIGATION_PERIOD": "Arbeidslimietperiode",
      "COLUMN_DEPARTMENT_CURRENCY_CODE": "Valutacode",
      "COLUMN_PAYROLL_CALENDAR_NAME": "Salariskalender",
      "COLUMN_PAYROLL_CALENDAR_FREQUENCY": "Frequentie van de salariskalender",
      "COLUMN_PAY_AMOUNT": "Bedrag betalen",
      "COLUMN_PAY_AMOUNT_TYPE": "Soort betalingsbedrag",
      "COLUMN_PAY_UPLIFT": "Loonsverhoging",
      "ASSET_REGISTRY_TAB_LABEL": "Activaregister"
    },
    "VIEW": {
      "ACCOUNT": {
        "ERROR_500": "We kunnen je wijziging op dit moment niet verwerken. Probeer het nog eens.",
        "ERROR_403": "Je hebt geen toestemming om de accountgegevens van deze persoon te bekijken/wijzigen.",
        "SAVE_SUCCESS": "Je wijzigingen zijn opgeslagen.",
        "TITLE": "Rekening",
        "LAST_SIGN_IN_LABEL": "Laatste aanmelding",
        "LAST_SIGN_IN_NEVER": "Nooit",
        "SECTION_PERMISSIONS_TITLE": "Machtigingen",
        "PERMISSION_LEVEL_LABEL": "Machtigingsniveau",
        "PERMISSION_LEVEL_HELP": "Dit verandert wat de werknemer kan zien en doen in Rotaready.",
        "SITE_ACCESS_LABEL": "Toegang tot de site",
        "SITE_ACCESS_OPTION_APPOINTMENT": "Hun huidige site",
        "SITE_ACCESS_OPTION_ALL": "Alle sites",
        "SITE_ACCESS_OPTION_CUSTOM": "Hun huidige site en aangepaste sites",
        "CLOCK_IN_PIN_LABEL": "Inklok-pincode opnieuw instellen",
        "CLOCK_IN_PIN_HELP": "Voer een nieuwe viercijferige code in om de incheckcode van deze medewerker te wijzigen.",
        "TAGS_LABEL": "Labels",
        "TAGS_TIP": "Tag werknemers met alles wat zinvol is, zoals vaardigheden of eigenschappen.",
        "TAGS_PLACEHOLDER": "Typ om tags te vinden",
        "SAVE_BUTTON_LOADING": "Opslaan...",
        "SAVE_BUTTON_TEXT": "Opslaan",
        "LANGUAGE_LABEL": "taal",
        "LANGUAGE_HELP": "Rotaready wordt weergegeven in de gekozen taal.",
        "ENTITY_GROUP_SUBSCRIPTION_TOOLTIP": "Deze werknemer wordt automatisch toegewezen aan deze site. U kunt hun toegang beheren op de instellingenpagina van de Site Access Manager."
      },
      "PAY": {
        "INDEX": {
          "TAB_HISTORY": "Loonadministratie",
          "TAB_TRONC": "Tronc",
          "TAB_FINANCIALS": "Financiële zaken"
        },
        "VIEW_HISTORY": {
          "FILTER_TYPES_LABEL": "Typ",
          "FILTER_TYPES_SALARY": "Salaris",
          "FILTER_TYPES_POINT_IN_TIME": "Eenmalige betalingen",
          "FILTER_ELEMENT_TYPES_LABEL": "Betaal-element",
          "STATE_ACTIVE": "Actief",
          "STATE_HISTORICAL": "Historisch",
          "STATE_FUTURE_DATED": "Toekomstbestendig",
          "COLUMN_EFFECTIVE_DATE": "Ingangsdatum",
          "COLUMN_STATUS": "Status",
          "COLUMN_ELEMENT_TYPE": "Betaal-element",
          "COLUMN_CURRENCY": "Valuta",
          "COLUMN_AMOUNT": "Bedrag",
          "COLUMN_AMOUNT_TYPE": "Soort bedrag",
          "COLUMN_OPTIONS": "Opties",
          "ERROR_500": "We kunnen op dit moment geen loongegevens laden. Probeer het opnieuw.",
          "ERROR_403": "Je hebt geen toestemming om de loonadministratie van deze persoon te bekijken/wijzigen.",
          "ALERT_SUCCESS": "De loonadministratie is opgeslagen.",
          "ALERT_DELETED": "De loonadministratie is verwijderd.",
          "OUTSIDE_OF_APPOINTMENT_WARNING": "Dit loonrecord is ongeldig omdat ze op deze datum niet actief in dienst zijn. Gelieve de ingangsdatum te wijzigen.",
          "DELETE_LINK": "Verwijderen",
          "LAST_EDITED_TOOLTIP": "Laatst gewijzigd door {{userName}} op {{date}}",
          "NO_RECORDS": "Er zijn geen loongegevens om te tonen.",
          "ADD_NEW_BUTTON": "Nieuw loonrecord",
          "CONFIRM_DELETE": "Weet je zeker dat je dit loonrecord wilt verwijderen? Dit kan niet ongedaan worden gemaakt."
        },
        "PAY_FORM": {
          "TITLE": "Betalen",
          "LABEL_EFFECTIVE_DATE": "Ingangsdatum",
          "LABEL_ELEMENT": "Betaal-element",
          "LABEL_AMOUNT": "Bedrag",
          "LABEL_AMOUNT_TYPE": "Soort bedrag",
          "LABEL_UPLIFT": "Loonsverhoging",
          "ALERT_NO_CURRENCY": "De werknemer is op deze datum niet actief in dienst.",
          "ALERT_DUPLICATE_PAY": "Op die datum is er al een loonrecord voor het door jou gekozen betaalelement.",
          "HELP_TEXT_UPLIFT": "Dit zal het loon van de werknemer in Cost Control verhogen. Gebruik het om eventuele extra kosten weer te geven die u maakt, zoals een pensioen. Maak je geen zorgen over belastingen, deze worden automatisch inbegrepen.",
          "BUTTON_SAVE": "Opslaan",
          "BUTTON_SAVE_LOADING": "Opslaan...",
          "BUTTON_DELETE": "Verwijderen",
          "BUTTON_DELETE_LOADING": "Verwijderen..."
        },
        "VIEW_FINANCIALS": {
          "ERROR_500": "We kunnen de financiële informatie op dit moment niet laden. Probeer het nog eens.",
          "ERROR_403": "Je hebt geen toestemming om de financiële informatie van deze persoon te bekijken/wijzigen.",
          "SAVE_SUCCESS": "Je wijzigingen zijn opgeslagen.",
          "SECTION_BANK_ACCOUNT": "Bankrekening",
          "SECTION_TAXES": "Belastingen",
          "ACCOUNT_NUMBER_LABEL": "Rekeningnummer",
          "SORT_CODE_LABEL": "Sorteercode",
          "ACCOUNT_NAME_LABEL": "Accountnaam",
          "PAYMENT_METHOD": "Wijze van betaling",
          "NI_NUMBER_LABEL": "Nummer van de nationale verzekering",
          "SECTION_STUDENT_LOANS": "Studieleningen (VK)",
          "PLAN_TYPE_LABEL": "Soort abonnement",
          "EFFECTIVE_DATE_LABEL": "Ingangsdatum",
          "LOAN_TABLE_PLAN_COLUMN": "Soort abonnement",
          "LOAN_TABLE_DATE_COLUMN": "Ingangsdatum",
          "LOAN_TABLE_OPTIONS_COLUMN": "Opties",
          "LOAN_TABLE_NO_ROWS": "Geen studieleningen toegevoegd.",
          "LOAN_TABLE_DATE_NOT_SET": "Niet ingesteld",
          "SUBHEADING_ADD_STUDENT_LOAN": "Studielening toevoegen",
          "OPTION_DELETE": "Verwijderen",
          "ADD_LOAN_BUTTON": "Studielening toevoegen",
          "SAVE_BUTTON_TEXT": "Opslaan"
        },
        "EDIT_STUDENT_LOAN": {
          "HEADING": "Studielening bewerken",
          "FORM_PLAN_TYPE_LABEL": "Plan type",
          "FORM_EFFECTIVE_DATE_LABEL": "Ingangsdatum",
          "SAVE_BUTTON_TEXT": "Opslaan"
        },
        "VIEW_TRONC": {
          "COLUMN_EFFECTIVE_DATE": "Ingangsdatum",
          "COLUMN_STATUS": "Status",
          "COLUMN_AMOUNT": "Bedrag",
          "COLUMN_APPORTION_TYPE": "Soort verdeling",
          "COLUMN_LAST_EDITED": "Laatst bewerkt",
          "STATE_ACTIVE": "Actief",
          "STATE_HISTORICAL": "Historisch",
          "STATE_FUTURE_DATED": "Toekomstbestendig",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Uurtarief",
          "APPORTION_TYPE_CLASSIC_SALARY": "Jaarsalaris",
          "APPORTION_TYPE_POINTS": "Punten",
          "NO_RECORDS": "Er zijn geen Tronc-tarieven om weer te geven.",
          "ADD_NEW_BUTTON": "Nieuw Tronc-tarief",
          "ERROR_500": "We kunnen de tarieven van Tronc momenteel niet laden. Probeer het opnieuw.",
          "ERROR_403": "Je hebt geen toestemming om de Tronc-tarieven van deze persoon te bekijken."
        },
        "ADD_TRONC_MODAL": {
          "TITLE": "Nieuw Tronc-tarief",
          "BUTTON_SAVE": "Opslaan",
          "BUTTON_CANCEL": "Annuleer",
          "LABEL_PERSON": "Persoon",
          "LABEL_DATE": "Ingangsdatum",
          "LABEL_APPORTION_TYPE": "Soort verdeling",
          "LABEL_AMOUNT": "Bedrag",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Uurtarief",
          "APPORTION_TYPE_CLASSIC_SALARY": "Jaarsalaris",
          "APPORTION_TYPE_POINTS": "Punten",
          "APPORTION_TYPE_HELP": "Tronc kan op twee manieren werken: op punten gebaseerd of klassiek, waarbij een uurtarief of salaris wordt gebruikt. Dit wordt beheerd door een instelling voor uw organisatie of de specifieke locatie/locatie.",
          "ERROR_OTHER": "We kunnen het Tronc-tarief nu niet opslaan. Probeer het nog eens.",
          "ERROR_UNAUTHORIZED": "Je hebt geen toestemming om de Tronc-tarieven van deze persoon te wijzigen.",
          "ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE": "Deze medewerker heeft geen actieve afspraak op de door jou gekozen ingangsdatum.",
          "ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE": "Iemand is al begonnen met het opstellen van Tronc voor de door jou gekozen ingangsdatum. Gebruik de Tronc-tool om de tarieven voor deze datum te bewerken.",
          "ERROR_NOT_SUPPORTED": "Je kunt dat verdelingstype niet kiezen. Tronc is niet geconfigureerd om in die modus te werken."
        }
      },
      "INDEX": {
        "OPTIONS_LABEL": "Opties",
        "OPTION_APPROVE_LINK": "Goedkeuren voor roosters en salarisadministratie",
        "OPTION_SEND_MAGIC_LINK": "Nodig uit voor Rotaready (stuur een magische link)",
        "OPTION_SEND_MAGIC_LINK_TOOLTIP": "Deze persoon heeft zijn account al aangemaakt. Als ze problemen hebben met inloggen, vraag ze dan om de link voor accountherstel op de inlogpagina te volgen.",
        "OPTION_ANONYMISE": "Anonimiseren",
        "OPTION_DELETE": "Verwijderen",
        "ALERT_APPROVE_SUCCESS": "Ze zijn nu goedgekeurd en klaar voor ploegendiensten.",
        "ALERT_APPROVE_ERROR": "We konden het account op dat moment niet goedkeuren. Probeer het nog eens.",
        "ALERT_MAGIC_LINK_SUCCESS_ALL": "We hebben een e-mail en een sms gestuurd met een magische link. Houd er rekening mee dat het een paar minuten duurt voordat het aankomt.",
        "ALERT_MAGIC_LINK_SUCCESS_EMAIL": "We hebben een e-mail gestuurd met een magische link. Houd er rekening mee dat het een paar minuten duurt voordat het aankomt.",
        "ALERT_MAGIC_LINK_SUCCESS_SMS": "We hebben een sms gestuurd met een magische link. Houd er rekening mee dat het een paar minuten duurt voordat het aankomt.",
        "ALERT_MAGIC_LINK_ERROR_GENERIC": "We konden op dat moment geen magische link sturen.",
        "ALERT_MAGIC_LINK_ERROR_NO_DETAILS": "We kunnen geen magische link sturen zonder e-mailadres of telefoonnummer.",
        "ALERT_ACCOUNT_ERROR_500": "We konden de werknemer op dat moment niet inladen. Probeer het nog eens.",
        "ALERT_ACCOUNT_ERROR_404": "Er is geen werknemersaccount gekoppeld aan dat ID.",
        "DELETE_ACCOUNT_TITLE": "Medewerker verwijderen",
        "DELETE_ACCOUNT_MESSAGE": "Waarschuwing! Het verwijderen van een medewerker is permanent. Doe dit alleen als je de medewerker per ongeluk hebt aangemaakt. Typ hieronder hun volledige naam om verder te gaan.",
        "DELETE_ACCOUNT_BUTTON": "Definitief verwijderen",
        "DELETE_ACCOUNT_ERROR": "De werknemer kon niet worden verwijderd. Je hebt misschien geen toestemming om dit te doen.",
        "DELETE_ACCOUNT_SUCCESS": "De werknemer is verwijderd."
      },
      "PERSONAL": {
        "ERROR_500": "We kunnen je wijziging op dit moment niet verwerken. Probeer het nog eens.",
        "ERROR_403": "Je hebt geen toestemming om de persoonlijke informatie van deze persoon te bekijken/wijzigen.",
        "SAVE_SUCCESS": "Je wijzigingen zijn opgeslagen.",
        "EMERGENCY_CONTACTS_LINK_TEXT": "Contactpersonen voor noodgevallen",
        "FINANCIALS_LINK_TEXT": "Financiën/belastingen",
        "LABEL_OPTIONS": "Meer opties",
        "LABEL_APPROVED_BY": "Goedgekeurd door",
        "TITLE_LABEL": "Titel",
        "FIRST_NAME_LABEL": "Voornaam",
        "LAST_NAME_LABEL": "Achternaam",
        "OTHER_NAME_LABEL": "Andere namen (optioneel)",
        "DOB_LABEL": "Geboortedatum",
        "DOB_HELP": "{{age}} jaar oud",
        "PREFERRED_NAME_LABEL": "Voorkeursnaam",
        "SECTION_ADDRESS": "Adres",
        "SECTION_VISAS": "Visa",
        "SECTION_IDENTITY": "Identiteit",
        "SECTION_CONTACT": "Neem contact op",
        "SECTION_OTHER": "Andere",
        "SECTION_RIGHT_TO_WORK": "Recht om te werken",
        "NATIONALITY_LABEL": "Nationaliteit",
        "GENDER_LABEL": "Geslacht",
        "ETHNICITY_LABEL": "Etniciteit",
        "MARITAL_STATUS_LABEL": "Burgerlijke staat",
        "PASSPORT_NUMBER_LABEL": "Paspoortnummer",
        "PASSPORT_EXPIRY_LABEL": "Vervaldatum van het paspoort",
        "SETTLED_STATUS_LABEL": "Vaste status (VK)",
        "PRIMARY_EMAIL_LABEL": "Primaire e-mail",
        "PRIMARY_EMAIL_HELP": "De medewerker gebruikt dit om in te loggen op Rotaready. Het kan alleen worden gewijzigd als ze nog niet eerder zijn ingelogd.",
        "PRIMARY_PHONE_LABEL": "Primaire telefoon",
        "PRIMARY_PHONE_HELP": "De werknemer gebruikt dit om meldingen te ontvangen. Het juiste voorvoegsel voor de landcode wordt automatisch toegepast nadat u het bestand hebt opgeslagen.",
        "SECONDARY_EMAIL_LABEL": "Secundair e-mailadres",
        "SECONDARY_PHONE_LABEL": "Secundaire telefoon",
        "HR_ID_LABEL": "HR ID",
        "HR_ID_HELP": "Als deze medewerker een ID-nummer heeft dat wordt gebruikt voor HR-doeleinden, kunt u dit hier opslaan.",
        "PAYROLL_ID_LABEL": "Loonlijst-ID",
        "PAYROLL_ID_HELP": "Als deze werknemer een ID-nummer heeft dat wordt gebruikt voor salarisdoeleinden, kunt u dit hier opslaan.",
        "SAVE_BUTTON_LOADING": "Opslaan...",
        "SAVE_BUTTON_TEXT": "Opslaan",
        "LINK_ADD_ADDRESS": "Nieuw adres toevoegen",
        "LINK_VIEW_ADDRESS_HISTORY": "Eerdere adressen bekijken",
        "NO_ADDRESS_HISTORY_TEXT": "Er zijn geen eerdere adressen",
        "CURRENT_ADDRESS_LABEL": "Actueel",
        "SHARE_CODE_LABEL": "Deelcode",
        "SHARE_CODE_ERROR_LENGTH": "De deelcode moet 9 tekens lang zijn.",
        "SHARE_CODE_ERROR_FORMAT": "De deelcode mag alleen alfanumerieke tekens bevatten.",
        "SHARE_CODE_EXPIRY_DATE_LABEL": "Vervaldatum van de deelcode",
      },
      "ADDRESS_HISTORY": {
        "ALERT_ERROR_500": "We kunnen je wijziging op dit moment niet verwerken. Probeer het nog eens.",
        "TITLE_ADD": "Nieuw adres toevoegen",
        "TITLE_EDIT": "Adres bewerken",
        "ADVICE_EDIT": "Als het adres van de werknemer verandert, moet je in plaats daarvan een nieuw adres toevoegen. Rotaready verplaatst het huidige adres automatisch naar hun adresgeschiedenis.",
        "ADVICE_EDIT_LINK": "Nieuw adres toevoegen",
        "SAVE_BUTTON_TEXT": "Opslaan",
        "DELETE_BUTTON_TEXT": "Verwijderen",
        "ADDRESS_LINE_1_LABEL": "Lijn 1",
        "ADDRESS_LINE_2_LABEL": "Lijn 2 (optioneel)",
        "ADDRESS_CITY_LABEL": "Stad/stad",
        "ADDRESS_COUNTY_LABEL": "buurtschap",
        "ADDRESS_POST_CODE_LABEL": "Postcode",
        "ADDRESS_COUNTRY_LABEL": "Land",
        "NOTES_LABEL": "Notities",
        "CONFIRM_DELETE": "Weet je zeker dat je dit adres wilt verwijderen?"
      },
      "VISAS": {
        "ADD_BTN": "Visum toevoegen",
        "CURRENT_HEADING": "Actief visum",
        "NO_CURRENT_VISA": "Deze gebruiker heeft geen actief visum.",
        "CURRENT_LABEL": "Actief",
        "VISA_TYPE_LABEL": "Visumtype",
        "VISA_NUMBER_LABEL": "Visumnummer",
        "VISA_EXPIRY_LABEL": "Vervaldatum van het visum",
        "VISA_EXPIRY_NOT_APPLICABLE": "Niet van toepassing",
        "VISA_EDIT_BTN": "Visum bewerken",
        "PREVIOUS_LABEL": "Vorige",
        "SHOW_PREVIOUS_BTN": "Vorige visa tonen",
        "HIDE_PREVIOUS_BTN": "Vorige visa verbergen",
        "PREVIOUS_HEADING": "Vorig visum",
        "MODAL": {
          "ADD_HEADING": "Nieuw visum toevoegen",
          "ALERT_ERROR_500": "We kunnen uw wijziging momenteel niet verwerken. Probeer het opnieuw.",
          "FORM_LABEL_VISA_TYPE": "Visumtype",
          "FORM_LABEL_VISA_NUMBER": "Visumnummer",
          "FORM_LABEL_IS_CURRENT": "Dit is het actieve visum van de gebruiker",
          "FORM_LABEL_HAS_EXPIRY_DATE": "Dit visum heeft een vervaldatum",
          "FORM_LABEL_VISA_EXPIRY": "Vervaldatum van het visum",
          "FORM_BTN_SAVE": "Opslaan",
          "EDIT_VISA_HEADING": "Visum bewerken",
          "DELETE_VISA_BTN": "Visum verwijderen",
          "CONFIRM_DELETE": "Weet u zeker dat u dit visum wilt verwijderen?"
        }
      },
      "SCHEDULE": {
        "TITLE": "De kalender hieronder toont het volledige schema van {{firstName}}, inclusief ploegendiensten, afwezigheid en beschikbaarheid.",
        "SHIFTS_LABEL": "Ploegen",
        "ABSENCE_LABEL": "Afwezigheid",
        "UNAVAILABLE_LABEL": "Niet beschikbaar (patronen)",
        "EDIT_LINK_TEXT": "Uitzonderingen bewerken"
      },
      "ADD_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Routine voor kaartverschuiving",
        "HAS_OVERLAP_TEXT": "De bovenstaande datums overlappen met een bestaande ploegendienst.",
        "END_BEFORE_START_TEXT": "De startdatum ligt vóór de einddatum.",
        "SAVE_BUTTON_TEXT": "Opslaan",
        "SAVE_BUTTON_LOADING": "Opslaan...",
        "CANCEL_BUTTON_TEXT": "Annuleer"
      },
      "DELETE_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Weet je dat zeker?",
        "WARNING": "Als u deze toewijzing verwijdert, worden alle toekomstige verschuivingen verwijderd die eraan zijn gekoppeld.",
        "YES_BUTTON_TEXT": "Ja",
        "YES_BUTTON_ADDITIONAL_TEXT": "Deze toewijzing verwijderen",
        "NO_BUTTON_TEXT": "Nee",
        "NO_BUTTON_ADDITIONAL_TEXT": "Ik ben van gedachten veranderd",
        "CANCEL_BUTTON_TEXT": "Annuleer"
      },
      "EDIT_ROUTINE_MAPPING_MODAL": {
        "TITLE": "De toewijzing van shiftroutines bewerken",
        "END_DATE_LABEL": "Einddatum",
        "CONTINUE_FOREVER_LABEL": "Ga voor altijd door met ploegendienst",
        "END_BEFORE_START_TEXT": "De einddatum ligt vóór de begindatum.",
        "HAS_OVERLAP_NO_END": "Het instellen van deze einddatum zou een overlapping veroorzaken met een bestaande ploegendienst.",
        "HAS_OVERLAP_END": "Als er geen einddatum wordt ingesteld, ontstaat er een overlapping met een bestaande ploegendienst.",
        "SAVE_BUTTON_TEXT": "Opslaan",
        "SAVE_BUTTON_LOADING_TEXT": "Opslaan...",
        "CANCEL_BUTTON_TEXT": "Annuleer"
      },
      "SHIFT_ROUTINES": {
        "MAP_ROUTINE_BUTTON_TEXT": "Van kaart naar routine",
        "MAP_ROUTINE_SUBTITLE": "Routinematige toewijzingen van ploegen",
        "MAP_ROUTINE_TIP": "Als iemand wordt gekoppeld aan een ploegendienst, worden er automatisch ploegen aangemaakt. Ze worden verwijderd als je de toewijzing verwijdert of als je een einddatum toevoegt.",
        "EDIT_END_DATE_TEXT": "Einddatum bewerken",
        "ADD_END_DATE_TEXT": "Einddatum toevoegen",
        "MAPPING_NO_END": "Vanaf ",
        "MAPPING_END": " naar ",
        "USER_HAS_NO_MAPPING": "{{firstName}} is niet gekoppeld aan ploegendienstroutines. Je kunt er een toevoegen via de knop hierboven."
      },
      "ABSENCE": {
        "INDEX": {
          "TAB_ABSENCE": "Boekingen bij afwezigheid",
          "TAB_ALLOWANCES": "Vergoedingen",
          "TAB_SETTINGS": "Instellingen"
        },
        "VIEW_SETTINGS": {
          "ABSENCE_HOURS_LABEL": "Gemiddeld aantal uren per dag",
          "AVERAGE_LENGTH_LABEL": "Bereken automatisch op basis van de gemiddelde duur van hun werkdag",
          "SET_MANUALLY_LABEL": "Handmatig instellen",
          "HOURS_FULL_DAY_LABEL": "Hele dag",
          "HOURS_LABEL": "uren",
          "HOURS_HELP": "Een volledige dag afwezigheid is zoveel uren waard.",
          "HOURS_HALF_DAY_LABEL": "Halve dag",
          "HOURS_HALF_DAY_HELP": "Een afwezigheid van een halve dag is zoveel uren waard.",
          "DELEGATE_APPROVERS": "Goedkeurders delegeren",
          "DELEGATE_APPROVERS_REMOVE": "Verwijderen",
          "DELEGATE_APPROVERS_HELP": "Als deze medewerker Do Not Disturb inschakelt in de Rotaready-app en er een afwezigheidsverzoek wordt ingediend waarvoor hun goedkeuring vereist is, worden in plaats daarvan de gedelegeerde goedkeurders hiervan op de hoogte gesteld.",
          "SAVE_BUTTON_TEXT": "Opslaan",
          "SAVE_BUTTON_LOADING_TEXT": "Opslaan...",
          "PRE_ROTAREADY_AVERAGE_HOURS_LABEL": "Pre-Rotaready gemiddeld aantal uren per dag",
          "PRE_ROTAREADY_AVERAGE_HOURS_HELP": "Als u tijdens het dienstverband van deze persoon naar Rotaready bent overgestapt van een ander systeem, voer dan het laatst bekende 'gemiddelde aantal uren per dag' in en de datum waarop deze geldig was.",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_LABEL": "Laatst geldig op",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_HELP": "Als de werknemer bij het berekenen van een nieuwe waarde voor het gemiddelde aantal uren per dag niet de hele afgelopen 52 weken op Rotaready heeft gewerkt, wordt in onze berekening de pre-Rotaready-waarde van de weken vóór deze datum gebruikt."
        },
        "VIEW_BOOKINGS": {
          "INCLUDE_CANCELLED_TOGGLE_LABEL": "Inclusief geannuleerd",
          "NO_BOOKINGS_MATCH_TEXT": "We konden geen boekingen vinden die aan je filters voldoen.",
          "BOOK_ABSENCE_BUTTON_TEXT": "Afwezigheid boeken",
          "CANCEL_LINK": "Annuleer",
          "DATES_COLUMN_TEXT": "Datum (s)",
          "ABSENCE_TYPE_COLUMN_TEXT": "Typ",
          "HOURS_COLUMN_TEXT": "Uren",
          "DAYS_COLUMN_TEXT": "Dagen",
          "STATUS_COLUMN_TEXT": "Status",
          "SUBMITTED_COLUMN_TEXT": "Ingediend",
          "APPROVED_COLUMN_TEXT": "Beoordeeld",
          "CANCELLED_COLUMN_TEXT": "Geannuleerd",
          "FILTER_LABEL_APPROVAL_STATE": "Status",
          "FILTER_LABEL_ABSENCE_TYPE": "Typ",
          "FILTER_LABEL_DATE": "Datum",
          "CANCEL_REQUEST": {
            "CONFIRM": "Weet je zeker dat je deze afwezigheidsboeking wilt annuleren? Dit kan niet ongedaan worden gemaakt.",
            "SUCCESS": "De afwezigheidsboeking is geannuleerd.",
            "ERROR_500": "We konden dat net niet annuleren. Probeer het nog eens."
          }
        },
        "VIEW_ALLOWANCES": {
          "ERROR_500": "Oeps, we stuitten juist op dat moment op een probleem. Probeer het nog eens.",
          "BUTTON_ADD": "Nieuwe toelage",
          "BUTTON_OPTIONS": "Opties",
          "BUTTON_TRANSACTIONS": "Transacties bekijken",
          "BUTTON_EDIT": "Bewerken",
          "BUTTON_DELETE": "Verwijderen",
          "NO_ALLOWANCES_TEXT": "Er zijn geen vergoedingen die overeenkomen met je filters.",
          "FILTER_LABEL_STATUS": "Status",
          "FILTER_LABEL_ABSENCE_TYPE": "Typ",
          "CHRONO_TYPE_ACTIVE": "Actief",
          "CHRONO_TYPE_FUTURE": "Toekomst",
          "CHRONO_TYPE_HISTORICAL": "Historisch",
          "LABEL_NOT_DEDUCTIBLE": "Niet aftrekbaar",
          "METRIC_ACCRUED": "Recht",
          "METRIC_BOOKED": "Geboekt",
          "METRIC_REMAINING": "Resterend",
          "METRIC_ACCRUED_LIMIT": "van {{count}}",
          "METRIC_ACCRUED_LIMIT_HELP": "Dit is het maximum dat kan worden opgebouwd.",
          "METRIC_ELAPSED": "verstreken",
          "METRIC_ELAPSED_HELP": "Dit is het aantal geboekte dagen/uren dat in het verleden is geboekt. Dit wordt automatisch van de ene op de andere dag opnieuw berekend.",
          "METRIC_OVERSPEND": "te veel uitgeven",
          "METRIC_OVERSPEND_HELP": "Dit is het aantal dagen/uren dat geboekt kan worden boven het opgebouwde bedrag.",
          "METRIC_ACCRUAL_ESTIMATE": "om op te bouwen",
          "METRIC_ACCRUAL_ESTIMATE_HELP": "Op basis van je gemiddelde werkuren schatten we dat je dit tegen het einde van het toeslagejaar zult opbouwen.",
          "SCHEDULE_BEGINNING_OF_ACCRUAL_YEAR": "Opgeteld aan het begin van elk opbouwjaar",
          "SCHEDULE_EACH_CALENDAR_MONTH": "Elke kalendermaand wordt opgebouwd",
          "SCHEDULE_EACH_HOUR_WORKED": "Wordt berekend voor elk gewerkt uur",
          "SCHEDULE_EACH_HOUR_WORKED_RATE": "Wordt berekend voor elk gewerkt uur tegen {{rate}}%",
          "CARRIED_OVER": "{{amount}} overgedragen van vorig jaar",
          "LINK_TRANSACTIONS": "Transacties bekijken",
          "ALERT_CONFIRM_DELETE": "Weet je zeker dat je deze toelage wilt schrappen? Dit kan niet ongedaan worden gemaakt.",
          "ALERT_SUCCESS_DELETE": "De toelage is geschrapt.",
          "FINALISED": "🔒 Voltooid ({{date}})",
          "FINALISE": "Voltooien",
          "CONVERT_HOURS_TO_DAYS_TOGGLE_LABEL": "Uren naar dagen omrekenen"
        },
        "ALLOWANCE_VALIDATION_MODAL": {
          "TITLE": "Er klopt iets niet helemaal",
          "DESCRIPTION": "Helaas veroorzaakt wat u probeert te doen een of meer problemen.",
          "BUTTON_CLOSE": "Sluiten",
          "INVALID_ALLOWANCE_TITLE": "Ongeldige toelage",
          "INVALID_ALLOWANCE_DESCRIPTION": "De vergoeding is inmiddels verwijderd en kan niet meer worden bewerkt.",
          "OVERLAPPING_ALLOWANCE_TITLE": "Overlappende vergoedingen",
          "OVERLAPPING_ALLOWANCE_DESCRIPTION": "Deze vergoeding overlapt met een bestaande vergoeding. Je kunt geen overlappende vergoedingen hebben voor hetzelfde verzuimtype.",
          "FINALISED_ALLOWANCE_TITLE": "Definitieve vergoeding",
          "FINALISED_ALLOWANCE_DESCRIPTION": "Deze vergoeding is definitief. Als u deze wilt bewerken of verwijderen, moet u eerst de vergoeding ongedaan maken.",
          "INVALID_UNIT_TITLE": "Ongeldige eenheid",
          "INVALID_UNIT_DESCRIPTION": "Voor een vergoeding waarvan het opbouwschema is ingesteld op Elk gewerkt uur, moet de eenheid zijn ingesteld op Uren.",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_TITLE": "Ongeldige ingangsdatum",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "De gekozen ingangsdatum moet tussen het begin en het einde van het toeslagejaar vallen.",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_TITLE": "Ongeldige peildatum",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "De peildatum moet liggen tussen de ingangsdatum van de toelage en het einde van het uitkeringsjaar."
        },
        "ALLOWANCE_FORM": {
          "ERROR_500": "Oeps, we stuitten juist op dat moment op een probleem. Probeer het nog eens.",
          "ALERT_SUCCESS_ADD": "De vergoeding is toegevoegd.",
          "ALERT_SUCCESS_UPDATE": "De vergoeding is bijgewerkt.",
          "ALERT_ERROR_CALCULATE_BOOKED": "We konden hun geboekte waarde op dit moment niet berekenen. Probeer het nog eens.",
          "BUTTON_SAVE": "Opslaan",
          "BUTTON_SAVE_LOADING": "Opslaan...",
          "TITLE": "Toelage",
          "LABEL_YEAR_STARTS": "Het jaar begint",
          "YEAR_STARTS_HELP": "Het standaard vakantiejaar van je organisatie loopt vanaf {{settingDate}}.",
          "YEAR_STARTS_CUSTOMISE_INITIATE": "Kies een andere dag of maand.",
          "YEAR_STARTS_CUSTOMISE_CANCEL": "Keer terug naar de standaarddag en -maand.",
          "LABEL_EFFECTIVE_DATE": "Ingangsdatum",
          "EFFECTIVE_DATE_HELP": "Als deze vergoeding een bestaande uitkering in hetzelfde jaar vervangt, moet u een datum kiezen waarop deze van kracht is.",
          "ALERT_MISALIGNED_YEAR_START": "Dit komt niet overeen met het standaard vakantiejaar van je organisatie.",
          "ALERT_MISALIGNED_EFFECTIVE_DATE": "Je ingangsdatum valt niet binnen het uitkeringsjaar.",
          "ALERT_ALTERED_EFFECTIVE_DATE": "Je ingangsdatum komt niet overeen met de begindatum van het toeslagejaar. Je moet dit alleen doen als je in hetzelfde jaar een bestaande uitkering vervangt.",
          "LABEL_ABSENCE_TYPE": "Soort afwezigheid",
          "LABEL_SCHEDULE_OF_ACCRUAL": "Schema van opbouw",
          "TOOLTIP_SCHEDULE_OF_ACCRUAL": "Om uren of dagen handmatig toe te wijzen, kies 'begin van het opbouwjaar'. Om Rotaready uren of dagen per kalendermaand toe te wijzen, kies 'elke kalendermaand'. Als u automatisch wilt opbouwen op basis van gewerkte uren, kiest u 'elk gewerkt uur'.",
          "LABEL_UNIT": "Eenheid",
          "LABEL_DEDUCTIBLE": "Aftrekbaar",
          "TOOLTIP_DEDUCTIBLE": "Controleer of de werknemer verzuimverzoeken kan indienen die in mindering worden gebracht op deze vergoeding.",
          "DEDUCTIBLE_YES": "Ja",
          "DEDUCTIBLE_NO": "Nee",
          "LABEL_PROJECT": "Tijdens het boeken opgebouwde bedrag voorspellen",
          "TOOLTIP_PROJECT": "Voorspel bij het indienen van een verzuimverzoek de uren die waarschijnlijk zullen zijn opgebouwd en neem deze op in het opgebouwde cijfer van de werknemer.",
          "PROJECT_YES": "Ja",
          "PROJECT_NO": "Nee",
          "LABEL_TOTAL_ACCRUED_ANNUALLY": "Totaal jaarlijks opgebouwde",
          "LABEL_TOTAL_ACCRUED": "Totaal opgebouwde",
          "TOOLTIP_TOTAL_ACCRUED": "Vul het aantal uren of dagen in dat deze werknemer tot nu toe heeft opgebouwd. Rotaready zal dit in de toekomst automatisch updaten.",
          "LABEL_BOOKED": "Geboekt",
          "TOOLTIP_BOOKED": "Voer het aantal uren of dagen in dat deze medewerker heeft geboekt. Rotaready zal dit automatisch onderhouden.",
          "ALERT_BOOKED": "We hebben dit voor je berekend op basis van de geboekte afwezigheid in Rotaready. Wijzig dit alleen als er een afwezigheid is geboekt buiten Rotaready.",
          "TOOLTIP_BOOKED_BUTTON": "Automatisch berekenen",
          "LABEL_TOTAL_TO_ACCRUE": "Maximaal op te bouwen",
          "TOOLTIP_TOTAL_TO_ACCRUE_HOURLY": "Voer het totale aantal uren of dagen in dat deze werknemer mag opbouwen. Laat dit veld leeg om geen limiet af te dwingen.",
          "TOOLTIP_TOTAL_TO_ACCRUE_MONTHLY": "Voer het totale aantal uren of dagen in dat deze werknemer gedurende het jaar moet opbouwen.",
          "LABEL_ACCRUAL_RATE": "Overschrijding van het opbouwpercentage",
          "TOOLTIP_ACCRUAL_RATE": "Geef optioneel een opbouwpercentage op als u het standaardtarief van het rechtsgebied waar de werknemer werkt, wilt overschrijven.",
          "LABEL_OVERSPEND_LIMIT": "Te veel uitgeven",
          "TOOLTIP_OVERSPEND_LIMIT": "Laat de werknemer optioneel meer uren of dagen boeken dan hij heeft opgebouwd.",
          "LABEL_CARRIED_OVER": "Overgedragen van vorig jaar",
          "BUTTON_PRO_RATA": "Pro-rata",
          "LABEL_EMPLOYMENT_START": "Startdatum van het dienstverband",
          "LABEL_PRO_RATA_TOTAL": "Totaal opgebouwd indien het hele jaar in dienst is",
          "HELP_TEXT_PRO_RATA": "We stellen het totaal van de opgebouwde waarde in op {{value}}.",
          "CREATE_NEXT_YEAR_CHECKBOX": "Creëer een nieuwe vergoeding voor volgend jaar op basis van het totaal opgebouwde bedrag indien u het hele jaar in dienst bent.",
          "ALERT_SUCCESS_CALCULATE_TOTAL_ACCRUED": "We hebben dit voor je berekend op basis van de afgetekende uren voor deze medewerker.",
          "ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED": "Je hebt geen toestemming om toegang te krijgen tot de afgetekende uren voor deze werknemer. Zonder dit kunnen we het totaal voor u niet berekenen.",
          "ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED": "We konden het totaal aantal opgebouwde uren niet automatisch berekenen. Probeer het nog eens.",
          "TOOLTIP_CALCULATE_TOTAL_ACCRUED": "Automatisch berekenen"
        },
        "ALLOWANCE_TRANSACTIONS": {
          "TITLE": "Transacties",
          "DESCRIPTION": "Hier zijn alle transacties die van invloed zijn op de vergoeding, waarbij de meest recente eerst wordt weergegeven:",
          "TYPE_ACCRUAL_HOURS_WORKED": "Opbouw uit gewerkte uren",
          "TYPE_ABSENCE_REQUESTED": "Afwezigheid aangevraagd",
          "TYPE_ABSENCE_REJECTED_OR_CANCELLED": "Afwezigheid afgewezen of geannuleerd",
          "TYPE_MANUAL_ADJUSTMENT": "Handmatige aanpassing",
          "TYPE_ACCRUAL_SCHEDULE_CHANGED": "Het opbouwschema is gewijzigd",
          "TYPE_CARRY_OVER": "Overgedragen van vorig jaar",
          "TYPE_ACCRUAL_TIME_BASED": "Op tijd gebaseerde opbouw",
          "TYPE_ABSENCE_UPDATED": "De afwezigheidsdata zijn gewijzigd",
          "TYPE_HOURS_PER_DAY_RECALCULATION": "Uren per dag bijgewerkt",
          "ERROR_500": "Oeps, we stuitten juist op dat moment op een probleem. Probeer het nog eens.",
          "NO_TRANSACTIONS": "Er zijn geen transacties om te tonen.",
          "BUTTON_CLOSE": "Sluiten",
          "TRANSACTION_TYPE": "Filteren op type"
        },
        "VIEW_FINALISATION": {
          "TITLE": "Definitieve vergoeding",
          "BUTTON_CLOSE": "Sluiten",
          "BUTTON_REVERT": "Terugdraaien",
          "CONFIRM_REVERT": "Weet je zeker dat je deze vergoeding wilt terugdraaien? Hiermee wordt ook het bijbehorende loonrecord verwijderd, indien aanwezig.",
          "LABEL_REFERENCE_DATE": "Peildatum",
          "LABEL_PAY_RECORD": "Betaling en aftrek",
          "PAY_SEE_MORE": "Loonadministratie bekijken",
          "PAY_NONE": "Er is geen loon verschuldigd aan de werknemer en er mag ook geen loon worden afgetrokken.",
          "LABEL_NOTES": "Notities",
          "NOTES_NONE": "Er zijn geen notities opgenomen.",
          "ERROR_500": "We konden je wijzigingen op dat moment niet opslaan. Probeer het nog eens.",
          "ERROR_403": "Je hebt geen toestemming om de loonadministratie van deze persoon te wijzigen, dus we kunnen de vergoeding niet terugdraaien.",
          "ALERT_SUCCESS": "De vergoeding is teruggedraaid."
        },
        "FINALISE_ALLOWANCE_FORM": {
          "TITLE": "Uitkering afronden",
          "BUTTON_SAVE": "Voltooien",
          "ERROR_500": "We konden je wijzigingen op dat moment niet opslaan. Probeer het nog eens.",
          "ERROR_403": "Je hebt geen toestemming om de loonadministratie van deze persoon te wijzigen, dus we kunnen de vergoeding niet definitief maken.",
          "ALERT_SUCCESS": "De vergoeding is definitief.",
          "GUIDANCE": "We raden aan om een vergoeding definitief te maken als je er zeker van bent dat er geen afwezigheid meer wordt opgebouwd, geboekt of geannuleerd. Dit is meestal zo dicht mogelijk bij iemands laatste dag.",
          "GUIDANCE_FUTURE_YEAR": "Dit hoef je alleen te doen als de werknemer in dit toeslagejaar vertrekt.",
          "LABEL_EFFECTIVE_DATE": "Peildatum",
          "EFFECTIVE_DATE_LEAVER_HELP": "De vergoeding eindigt op deze datum. Aangezien deze persoon een verlater is, hebben we het op zijn laatste dag ingesteld.",
          "EFFECTIVE_DATE_NOT_LEAVER_HELP": "De vergoeding eindigt op deze datum. De datum die u kiest, wordt hieronder gebruikt om het totaal aantal opgebouwde uren of dagen te berekenen.",
          "LABEL_BASIC_PAY": "Basissalaris",
          "ALERT_BASIC_PAY_NOT_PERMITTED": "Je hebt geen toestemming om de loonadministratie van deze werknemer te bekijken, dus je kunt deze functie niet gebruiken om een eenmalige betaling of aftrek in te voeren.",
          "ALERT_BASIC_PAY_NOT_AVAILABLE": "De werknemer heeft geen basisloonadministratie op de door u gekozen peildatum.",
          "HELP_TEXT_BASIC_PAY": "Dit is het basissalaris van de werknemer op de door jou gekozen peildatum.",
          "LABEL_TOTAL_ACCRUED_LIVE": "Totaal opgebouwde",
          "HELP_TEXT_TOTAL_ACCRUED_LIVE": "Dit is het totaal aantal opgebouwde uren voor deze vergoeding, gebaseerd op alle dagen die zijn afgetekend.",
          "LABEL_ACCRUED_PRO_RATED": "Opgebouwd (naar rato)",
          "HELP_TEXT_TOTAL_ACCRUED_PRO_RATED": "Op basis van de door u gekozen peildatum zijn de totale opgebouwde uren/dagen pro rata berekend.",
          "LABEL_BOOKED": "Totaal geboekt",
          "HELP_TEXT_BOOKED": "Dit is de totale afwezigheid die tot nu toe is geboekt, inclusief zowel lopende als goedgekeurde aanvragen.",
          "LABEL_ACCRUED_UNTAKEN": "Opgebouwde maar niet opgenomen",
          "STATUS_PAYMENT_DUE": "Dit betekent dat de werknemer minder verzuim heeft opgenomen dan hij heeft opgebouwd. Veel werkgevers kiezen ervoor om de werknemer hiervoor te betalen.",
          "STATUS_DEDUCTION_DUE": "Dit betekent dat de werknemer meer verzuim heeft opgenomen dan hij heeft opgebouwd. Sommige werkgevers kiezen ervoor om dit in mindering te brengen op de definitieve loonstrook van de werknemer.",
          "LABEL_AVERAGE_HOURS": "Gemiddeld aantal uren per dag",
          "LABEL_ONE_OFF_PAYMENT": "Eenmalige betaling/aftrek",
          "HELP_TEXT_ONE_OFF_PAYMENT": "Als u een bedrag invoert, maakt Rotaready een eenmalige loonadministratie en koppelt dit aan deze definitieve vergoeding. Voer voor een aftrek een negatief bedrag in.",
          "HELP_TEXT_ONE_OFF_PAYMENT_LINK": "Hulp bij het berekenen van dit",
          "LABEL_PAY_ELEMENT": "Betaal-element",
          "LABEL_PAYMENT_DATE": "Toepasselijke datum",
          "HELP_TEXT_PAYMENT_DATE": "Kies zorgvuldig om ervoor te zorgen dat deze betaling wordt opgenomen in de laatste loonlijst van de werknemer.",
          "LABEL_NOTES": "Notities",
          "PLACEHOLDER_NOTES": "Als je geen notities invoert, nemen we automatisch de cijfers uit de bovenstaande berekening mee."
        },
        "FINALISE_ALLOWANCE_PROMPT": {
          "TITLE": "Zijn alle toelagen definitief?",
          "DESCRIPTION": "Als u tevreden bent dat alle vergoedingen nu zijn afgerond, moet u dit bevestigen, zodat afdelingen zoals de salarisadministratie op de cijfers kunnen vertrouwen.",
          "DESCRIPTION_PART_2": "Je kunt dit op een later tijdstip ook doen op het tabblad Werkgelegenheid.",
          "BUTTON_ATTEST": "Bevestigen",
          "BUTTON_LATER": "Ik heb meer te doen",
          "ALERT_ERROR": "We konden je bevestiging op dat moment niet opslaan. Probeer dit in plaats daarvan op het tabblad Werkgelegenheid."
        }
      },
      "EMERGENCY_CONTACTS": {
        "ADD_MODAL": {
          "TITLE": "Nieuw contactpunt voor noodgevallen",
          "RELATIONSHIP_LABEL": "Relatie met werknemer",
          "FIRST_NAME_LABEL": "Voornaam",
          "LAST_NAME_LABEL": "Achternaam",
          "OTHER_NAME_LABEL": "Andere naam (namen)",
          "PRIMARY_CONTACT_NUMBER_LABEL": "Primair contactnummer",
          "SECONDARY_CONTACT_NUMBER_LABEL": "Secundair contactnummer",
          "ADDRESS_LABEL": "Adres",
          "SAVE_BUTTON_TEXT": "Opslaan",
          "CLOSE_BUTTON_TEXT": "Sluiten",
          "ALERT_ERROR_500": "We konden je wijzigingen op dat moment niet opslaan. Probeer het nog eens."
        },
        "INDEX": {
          "TITLE": "Contactpersonen voor noodgevallen",
          "RELATIONSHIP_COLUMN_TEXT": "Relatie",
          "NAME_COLUMN_TEXT": "Naam",
          "PHONE_COLUMN_TEXT": "Telefoonnummer (s)",
          "ADDRESS_COLUMN_TEXT": "Adres",
          "NONE_TO_SHOW": "Er zijn geen contactpersonen voor noodgevallen om te tonen.",
          "ADD_BUTTON_TEXT": "Nieuw contact",
          "OPTIONS_COLUMN_TEXT": "Opties",
          "DELETE_LINK": "Verwijderen",
          "ALERT_ERROR_500": "We konden op dat moment geen contactpersonen voor noodgevallen laden. Probeer het opnieuw.",
          "CONFIRM_DELETE": "Weet je zeker dat je deze contactpersoon voor noodgevallen wilt verwijderen?\nDit kan niet ongedaan worden gemaakt."
        }
      },
      "EMPLOYMENT": {
        "LABEL_LENGTH_OF_SERVICE": "Duur van de service",
        "LABEL_STARTS_IN": "Begint in",
        "LABEL_OPTIONS": "Opties",
        "OPTION_NEW_EMPLOYMENT": "Nieuwe baan",
        "OPTION_END_EMPLOYMENT": "Werkgelegenheid beëindigen",
        "OPTION_REINSTATE_EMPLOYMENT": "De werkgelegenheid herstellen",
        "OPTION_DELETE_EMPLOYMENT": "Werkgelegenheid verwijderen",
        "TEXT_TERMINATED": "Dit dienstverband is beëindigd en eindigt op",
        "LABEL_REASON": "Reden",
        "LABEL_REHIRE": "Opnieuw inhuren",
        "LABEL_REHIRE_YES": "Ja",
        "LABEL_REHIRE_NO": "Nee",
        "BUTTON_ATTEST": "Bevestigen",
        "TEXT_ALLOWANCES_NOT_FINALISED": "U hebt nog niet bevestigd dat de verzuimvergoedingen van de werknemer definitief zijn.",
        "TEXT_ALLOWANCES_NOT_FINALISED_PART_2": "Dit gebeurt meestal op of vóór de laatste dag van de werknemer, voorafgaand aan zijn laatste loonperiode.",
        "TEXT_ALLOWANCES_NOT_FINALISED_LINK": "Vergoedingen bekijken",
        "BUTTON_UNDO": "Ongedaan maken",
        "TEXT_ALLOWANCES_FINALISED": "Voor dit dienstverband is bevestigd dat de verzuimvergoedingen definitief zijn.",
        "LABEL_JOIN_DATE": "Datum van aanmelding",
        "LABEL_NOTICE_PERIOD": "Opzegtermijn",
        "BUTTON_SET_NOTICE_PERIOD": "Stel een opzegtermijn in...",
        "LABEL_EMPLOYEE_NUMBER": "Nummer van de werknemer",
        "TOOLTIP_EMPLOYEE_NUMBER": "Optioneel. In het VK vereist HMRC voor elke werknemer een uniek nummer. We sturen dit naar je payrollprovider als een salarisintegratie is ingeschakeld.",
        "LABEL_RECRUITMENT_SOURCE": "Bron van rekrutering",
        "LABEL_STARTER_DECLARATION": "Startverklaring (VK)",
        "BUTTON_SAVE": "Opslaan",
        "BUTTON_SAVE_LOADING": "Opslaan...",
        "BUTTON_NEW_APPOINTMENT": "Nieuwe afspraak",
        "TITLE_APPOINTMENTS": "Afspraken",
        "COLUMN_EFFECTIVE_DATE": "Ingangsdatum",
        "COLUMN_SITE": "Site",
        "COLUMN_DEPARTMENT": "Afdeling",
        "COLUMN_POSITION": "Rol op het werk",
        "COLUMN_LINE_MANAGER": "Lijnmanager",
        "COLUMN_STAFF_GROUP": "Personeelsgroep",
        "COLUMN_OBLIGATION": "Verplichting",
        "TOOLTIP_ACTIVE_APPOINTMENT": "Dit is de actieve afspraak.",
        "LINE_MANAGER_NOT_SET": "Niet ingesteld",
        "MIN_OBLIGATION_ZERO_HOURS": "Nul uur",
        "ERROR_500": "We kunnen op dit moment geen vacatures laden. Probeer het nog eens.",
        "ERROR_403": "Je hebt geen toestemming om de arbeidsgegevens van deze persoon in te zien/te wijzigen.",
        "LABEL_HOURS": "uren",
        "LABEL_DAYS": "dagen",
        "LABEL_WEEK": "week",
        "LABEL_MONTH": "maand",
        "LABEL_CURRENT_EMPLOYMENT": "Huidig dienstverband",
        "ALERT_CHANGES_SAVED": "Je wijzigingen zijn opgeslagen.",
        "CONFIRM_DELETE": "Weet je zeker dat je dit wilt doen? Het schrappen van een dienstverband is onomkeerbaar.",
        "CONFIRM_REINSTATE": "Weet je zeker dat je dit wilt doen?\n\nAls iemand opnieuw bij uw organisatie komt, moet u in plaats daarvan een nieuwe baan creëren. Je mag een dienstverband alleen herstellen als het ten onrechte is beëindigd.",
        "VALIDATION_MODAL": {
          "TITLE": "We kunnen die wijziging niet aanbrengen",
          "DESCRIPTION": "Helaas veroorzaakt wat u probeert te doen een of meer problemen.",
          "NO_EMPLOYMENT_TITLE": "Geen tewerkstelling",
          "NO_EMPLOYMENT_DESCRIPTION": "Door uw wijziging zou de werknemer zonder werk komen te zitten; er moet er altijd minstens één zijn.",
          "EMPLOYMENT_OVERLAPPING_TITLE": "Overlappende tewerkstellingen",
          "EMPLOYMENT_OVERLAPPING_DESCRIPTION": "Door uw wijziging zouden twee dienstverbanden elkaar overlappen:",
          "EMPLOYMENT_NOT_TERMINATED_TITLE": "Het dienstverband is niet beëindigd",
          "EMPLOYMENT_NOT_TERMINATED_DESCRIPTION": "Je moet het vorige dienstverband beëindigen voordat je een nieuw dienstverband toevoegt.",
          "EMPLOYMENT_NO_APPOINTMENTS_TITLE": "Werkgelegenheid zonder aanstellingen",
          "EMPLOYMENT_NO_APPOINTMENTS_DESCRIPTION": "Een dienstverband mag niet leeg zijn, het moet minstens één afspraak bevatten.",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_TITLE": "Afspraak buiten dienstverband",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Uw wijziging zou ertoe leiden dat een deel van deze aanstelling buiten het dienstverband valt.",
          "APPOINTMENT_OVERLAPPING_TITLE": "Afspraken overlappen elkaar",
          "APPOINTMENT_OVERLAPPING_TITLE_DESCRIPTION": "Je wijziging zou ervoor zorgen dat twee afspraken elkaar overlappen:",
          "APPOINTMENT_NO_EMPLOYMENT_TITLE": "Afspraak zonder dienstverband",
          "APPOINTMENT_NO_EMPLOYMENT_DESCRIPTION": "Een afspraak is niet gekoppeld aan een dienstverband. Dit zou normaal niet moeten gebeuren!",
          "SHIFTS_OUTSIDE_EMPLOYMENT_TITLE": "Verschuivingen buiten het dienstverband",
          "SHIFTS_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Bij uw wijziging blijft de shift (en) van {{count}} over op data die vallen vóór het begin of na het einde van het dienstverband. Om verder te gaan, kunnen deze ploegen worden verwijderd, niet meer worden toegewezen (waardoor ze naar Open Shifts worden verplaatst) of gewijzigd op het rooster zelf:",
          "SHIFT_ENTITY_MISMATCH_TITLE": "Verschuivingen op verschillende locatie/afdeling",
          "SHIFT_ENTITY_MISMATCH_DESCRIPTION": "Je wijziging zou ervoor zorgen dat de shift (en) van {{count}} afkomstig is van het rooster van een afdeling dat niet overeenkomt met de afdeling op de afspraak van de werknemer. Om verder te gaan, kunnen deze ploegen worden verwijderd, niet meer worden toegewezen (waardoor ze naar Open Shifts worden verplaatst) of gewijzigd op het rooster zelf:",
          "ABSENCE_OUTSIDE_EMPLOYMENT_TITLE": "Afwezigheid buiten het dienstverband",
          "ABSENCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Uw wijziging zou ervoor zorgen dat {{count}} een afwezigheidsboeking (en) achterblijft op data die vallen vóór of na het einde van het dienstverband. Om verder te gaan, moeten ze worden geannuleerd:",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_TITLE": "Aanwezigheid buiten het dienstverband",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Je wijziging zou ervoor zorgen dat je evenement (en) niet meer aanwezig bent op data die vallen vóór het begin of na het einde van het dienstverband. Om verder te gaan, moeten ze worden verwijderd:",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_TITLE": "Routine in kaart brengen van ploegendiensten",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Uw wijziging zou ervoor zorgen dat {{count}} ploegendienst routinematig in kaart wordt gebracht op data die vallen vóór of na het einde van het dienstverband. Om verder te gaan, moeten ze worden beëindigd of verwijderd:",
          "EMAIL_EXISTS_TITLE": "E-mailadres bestaat al",
          "EMAIL_EXISTS_DESCRIPTION": "Het e-mailadres dat je hebt ingevoerd is al in gebruik door een andere medewerker. Als deze persoon eerder voor uw organisatie heeft gewerkt, zoek dan naar deze persoon en voeg een nieuwe baan toe.",
          "MOBILE_EXISTS_TITLE": "Mobiel telefoonnummer bestaat al",
          "MOBILE_EXISTS_DESCRIPTION": "Het mobiele telefoonnummer dat je hebt ingevoerd is al in gebruik door een andere medewerker. Als deze persoon eerder voor uw organisatie heeft gewerkt, zoek dan naar deze persoon en voeg een nieuwe baan toe.",
          "INVALID_DATE_TITLE": "Ongeldige datum",
          "INVALID_DATE_DESCRIPTION": "Als je een dienstverband probeert te beëindigen, is de einddatum die je hebt gekozen voordat het dienstverband begint.",
          "PERIOD_SIGNED_OFF_TITLE": "Datum van ondertekening",
          "PERIOD_SIGNED_OFF_DESCRIPTION": "Je wijziging overlapt met een of meer datums die zijn afgemeld.",
          "PAY_ELEMENT_INVALID_TITLE": "Ongeldig betaalelement",
          "PAY_ELEMENT_INVALID_DESCRIPTION": "Het gekozen betaalelement is ongeldig.",
          "PAY_RECORD_SAME_DATE_TITLE": "Duplicaat loonrecord",
          "PAY_RECORD_SAME_DATE_DESCRIPTION": "Er bestaat op die datum al een loonrecord voor het door jou gekozen betaalelement.",
          "RECORD_MISSING_TITLE": "Loonrecord verwijderd",
          "RECORD_MISSING_DESCRIPTION": "Dit loonrecord is verwijderd en kan niet meer worden gewijzigd.",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_TITLE": "Komt de loonkalender niet overeen",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_DESCRIPTION": "Je wijziging zou ervoor zorgen dat {{count}} afwezigheidsboeking (en) gekoppeld is aan een andere salariskalender. Om verder te gaan, moeten ze worden geannuleerd:",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_TITLE": "Gekoppeld aan de definitieve vergoeding",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_DESCRIPTION": "Deze loonadministratie is gekoppeld aan een definitieve vergoeding en kan niet worden bewerkt. Als u de vergoeding wilt verwijderen, zoekt u de vergoeding op en draait u deze terug.",
          "BUTTON_TRY_AGAIN": "Probeer het opnieuw",
          "BUTTON_CLOSE": "Sluiten"
        },
        "LEAVER_FORM": {
          "TITLE": "Werkgelegenheid beëindigen",
          "ALERT_SUCCESS": "Het dienstverband is beëindigd.",
          "ERROR_500": "We konden je wijzigingen op dat moment niet opslaan. Probeer het nog eens.",
          "LABEL_END_DATE": "Laatste dag van indiensttreding",
          "LABEL_REASON": "Reden van vertrek",
          "LABEL_REHIRE": "Opnieuw inhuren",
          "CHECKBOX_REHIRE": "Deze werknemer komt in aanmerking voor heraanwerving in de toekomst.",
          "LABEL_NOTES": "Notities",
          "PLACEHOLDER_NOTES": "Noteer eventueel enkele notities die verband houden met het vertrek van deze werknemer.",
          "BUTTON_SAVE": "Opslaan",
          "BUTTON_SAVE_LOADING": "Opslaan..."
        },
        "EMPLOYMENT_FORM": {
          "TITLE": "Nieuwe baan",
          "RESET_WARNING": "Het account van de werknemer wordt gemarkeerd als niet goedgekeurd en verschillende kenmerken worden gereset, zoals het gemiddelde aantal gewerkte uren per dag en per week.",
          "LABEL_START_DATE": "Startdatum van het dienstverband",
          "LABEL_NOTICE_PERIOD": "Opzegtermijn",
          "LABEL_EMPLOYEE_NUMBER": "Nummer van de werknemer",
          "TOOLTIP_EMPLOYEE_NUMBER": "Optioneel. In het VK vereist HMRC voor elke werknemer een uniek nummer. We sturen dit naar je payrollprovider als een salarisintegratie is ingeschakeld.",
          "LABEL_RECRUITMENT_SOURCE": "Bron van rekrutering",
          "LABEL_STARTER_DECLARATION": "Startverklaring (VK)",
          "LABEL_INVITE": "Nodig uit voor Rotaready",
          "CHECKBOX_INVITE": "Stuur een magische link naar deze medewerker, waarin ze worden uitgenodigd om hun wachtwoord in te stellen en in te loggen om hun diensten te zien.",
          "BUTTON_ADD": "Toevoegen",
          "BUTTON_ADD_LOADING": "Toevoegen...",
          "ALERT_SUCCESS": "De werkgelegenheid is gecreëerd.",
          "ERROR_500": "We konden je wijzigingen op dat moment niet opslaan. Probeer het nog eens."
        },
        "APPOINTMENT_FORM": {
          "TITLE": "Afspraak",
          "ALERT_MAPPINGS": "Vul de standaard streammappings in of controleer de declaratie.",
          "ALERT_SAVED": "De afspraak is opgeslagen.",
          "ALERT_DELETED": "De afspraak is verwijderd.",
          "ERROR_500": "We konden je wijzigingen op dat moment niet opslaan. Probeer het nog eens.",
          "INFO_NEW_APPOINTMENT": "We hebben de meest recente afspraak gebruikt om dit formulier vooraf voor je in te vullen.",
          "LABEL_EFFECTIVE_DATE": "Ingangsdatum",
          "HELP_TEXT_EFFECTIVE_DATE": "Aangezien dit de eerste afspraak is, is de ingangsdatum gekoppeld aan de toetredingsdatum van het dienstverband.",
          "INFO_NEW_EMPLOYEE": "Configureer tot slot de nieuwe afspraak van deze medewerker door de locatie/afdeling, functie en andere belangrijke kenmerken in te stellen.",
          "LABEL_LINE_MANAGER": "Lijnmanager",
          "LABEL_LINE_MANAGER_NOT_SET": "Niet ingesteld",
          "TITLE_JOB_ROLE": "Rol op het werk",
          "LABEL_SITE_DEPARTMENT": "Site/afdeling",
          "LABEL_JOB_ROLE": "Rol op het werk",
          "LABEL_STAFF_GROUP": "Personeelsgroep",
          "HELP_TEXT_STAFF_GROUP": "Personeelsgroepen worden gebruikt om mensen op het rooster en in andere tools visueel van elkaar te scheiden.",
          "TITLE_EMPLOYMENT_CONTRACT": "Arbeidsovereenkomst",
          "LABEL_COMPANY": "Bedrijf",
          "LABEL_PAYROLL_CALENDAR": "Salariskalender",
          "LABEL_CONTRACT_TYPE": "Soort contract",
          "LABEL_PUBLIC_HOLIDAYS": "Feestdagen",
          "HELP_TEXT_PUBLIC_HOLIDAYS": "Deze werknemer werkt doorgaans op feestdagen.",
          "LABEL_WORKING_TIME": "Arbeidstijdenvoorschriften",
          "HELP_TEXT_WORKING_TIME": "Deze werknemer heeft zich afgemeld voor de optionele werklimieten van de arbeidstijdregeling.",
          "TITLE_CONTRACTUAL_OBLIGATION": "Contractuele verplichting",
          "LABEL_MIN_OBLIGATION": "Gegarandeerde uren/dagen",
          "HELP_TEXT_MIN_OBLIGATION": "Deze werknemer is gegarandeerd van een minimale hoeveelheid werk.",
          "LABEL_MAX_OBLIGATION": "Werklimiet",
          "HELP_TEXT_MAX_OBLIGATION": "Handhaaf een bovengrens voor het aantal uren of dagen dat deze werknemer kan worden ingepland om te werken.",
          "TITLE_STREAM_MAPPINGS": "Standaard stream-toewijzingen",
          "HELP_TEXT_STREAM_MAPPINGS": "Wanneer deze werknemer in een ploegendienst werkt, bepalen de mappings van het ploegentype in welke stream (s) hun loonkosten moeten worden verdeeld. Als het ploegendiensttype er geen heeft, worden deze in plaats daarvan gebruikt:",
          "COLUMN_STREAM": "Streamen",
          "COLUMN_ATTRIBUTION": "Attributie",
          "COLUMN_OPTIONS": "Opties",
          "OPTION_DELETE": "Verwijderen",
          "NO_MAPPINGS": "Er is niets om te laten zien.",
          "LABEL_ADD_MAPPING": "Een nieuwe toewijzing toevoegen",
          "OPTION_ADD": "Toevoegen",
          "LABEL_NO_MAPPINGS": "Geen mappings",
          "CHECKBOX_NO_MAPPINGS": "Ik ga ermee akkoord dat als er geen standaard streamtoewijzingen zijn, er onnauwkeurigheden kunnen zijn in Cost Control en sommige rapporten.",
          "HELP_TEXT_NO_MAPPINGS": "Dit is prima als u de loonkosten van deze werknemer niet wilt bijhouden.",
          "BUTTON_SAVE": "Opslaan",
          "BUTTON_SAVE_LOADING": "Opslaan...",
          "BUTTON_DELETE": "Verwijderen",
          "BUTTON_DELETE_LOADING": "Verwijderen..."
        }
      },
      "DOCUMENTS": {
        "FILTER_LABEL_CATEGORY": "Categorie",
        "BUTTON_UPLOAD": "Bestand uploaden",
        "BUTTON_SIGNATURES": "Handtekeningen van documenten",
        "OPTION_SEND_TO_SIGN": "Een document ter ondertekening verzenden",
        "OPTION_PENDING_SIGNATURES": "Documenten bekijken die nog moeten worden ondertekend",
        "ERROR_500": "We kunnen op dit moment geen documenten laden. Probeer het nog eens.",
        "ERROR_403": "Je hebt geen toestemming om de documenten van deze persoon te bekijken/wijzigen.",
        "ERROR_NO_CATEGORIES": "Documenten zijn niet ingeschakeld voor je organisatie. Chat met ons om te beginnen.",
        "TOOLTIP_CATEGORY_NOT_APPLICABLE": "Categorie gemarkeerd als niet vereist",
        "TOOLTIP_CATEGORY_INVALID": "Document ontbreekt of is verlopen",
        "INFO_CATEGORY_NOT_APPLICABLE": "Deze categorie is gemarkeerd als niet vereist voor deze werknemer.",
        "LINK_MARK_CATEGORY_REQUIRED": "Markeer zoals vereist",
        "LINK_MARK_CATEGORY_NOT_REQUIRED": "Markeren als niet vereist",
        "INFO_CATEGORY_NO_DOCUMENTS": "Er zijn geen documenten in deze categorie.",
        "COLUMN_NAME": "Naam",
        "COLUMN_EXPIRES": "Vervalt",
        "COLUMN_FILE_SIZE": "Bestandsgrootte",
        "COLUMN_UPLOADED_BY": "Geüpload door",
        "COLUMN_OPTIONS": "Opties",
        "LINK_DELETE": "Verwijderen",
        "LINK_EDIT": "Bewerken",
        "ALERT_CONFIRM_DELETE": "Weet je zeker dat je dit document wilt verwijderen? Dit kan niet ongedaan worden gemaakt!",
        "TOOLTIP_SIGNATURE_ELECTRONIC": "Dit document is elektronisch ondertekend via Rotaready",
        "TOOLTIP_SIGNATURE_MANUAL": "Dit document is handmatig ondertekend",
        "TOOLTIP_NOTES": "Notities zijn beschikbaar",
        "PILL_SENSITIVE": "Gevoelig",
        "PILL_CAN_EXPIRE": "Kan verlopen",
        "PILL_VISIBLE_TO_EMPLOYEE": "Zichtbaar voor de werknemer",
        "TOOLTIP_ACKNOWLEDGED": "Erkend: {{date}}",
        "PERSONAL": {
          "TITLE": "Mijn documenten",
          "ALERT_CONFIRM_ACKNOWLEDGE": "Door op bevestigen te klikken bevestigt u dat u het document hebt gelezen en begrepen.",
          "INFO_NO_DOCUMENTS": "Je hebt geen documenten om te bekijken.",
          "LINK_ACKNOWLEDGE": "Erken",
          "PILL_NEEDS_ACKNOWLEDGEMENT": "Heeft erkenning nodig",
          "PILL_ACKNOWLEDGED": "Erkend",
          "COLUMN_NAME": "Naam",
          "COLUMN_CATEGORY": "Categorie",
          "COLUMN_EXPIRES": "Vervalt",
          "COLUMN_FILE_SIZE": "Bestandsgrootte",
          "COLUMN_OPTIONS": "Opties",
          "FILTER_LABEL_CATEGORY": "Categorie"
        }
      },
      "DOCUMENT_EDIT_FORM": {
        "TITLE": "Document bewerken",
        "BUTTON_CANCEL": "Annuleer",
        "BUTTON_SAVE": "Opslaan",
        "LABEL_EXPIRY_DATE": "Vervaldatum",
        "LABEL_NOTES": "Notities",
        "HELP_TEXT_EXPIRY": "Het document moet een vervaldatum hebben als de categorie dat vereist. Je wordt op de hoogte gebracht om actie te ondernemen als de datum nadert en opnieuw als de datum verstrijkt.",
        "ALERT_SUCCESS": "Het document is bijgewerkt.",
        "ALERT_ERROR_500": "Er is een fout opgetreden bij het updaten van het document. Probeer het opnieuw of neem contact met ons op als dit bericht zich blijft voordoen.",
        "ALERT_ERROR_400": "Er klopt iets niet helemaal met je wijzigingen."
      },
      "DOCUMENT_UPLOAD_MODAL": {
        "TITLE": "Een document uploaden",
        "ALERT_SIGNATURE": "We markeren dit document als handmatig ondertekend.",
        "LABEL_UPLOAD_TO": "Uploaden naar",
        "LABEL_CATEGORY": "Categorie",
        "LABEL_EXPIRY_DATE": "Vervaldatum",
        "HELP_TEXT_EXPIRY": "Het document moet een vervaldatum hebben als de categorie dat vereist. Je wordt op de hoogte gebracht om actie te ondernemen als de datum nadert en opnieuw als de datum verstrijkt.",
        "LABEL_UPLOAD": "Uploaden",
        "LABEL_NOTES": "Notities",
        "BUTTON_CANCEL": "Annuleer",
        "BUTTON_SAVE": "Opslaan",
        "ALERT_SUCCESS": "Je document is geüpload.",
        "ALERT_ERROR_500": "Er is een fout opgetreden bij het uploaden van het document. Probeer het opnieuw of neem contact met ons op als dit bericht zich blijft voordoen.",
        "ALERT_ERROR_400": "Er klopt iets niet helemaal met je categorie en vervaldatum."
      },
      "DOCUMENT_SIGNATURE_MODAL": {
        "ALERT_PICK_CATEGORY": "Selecteer een categorie om het document in op te slaan.",
        "ALERT_SUCCESS_MANUAL": "Je document is gedownload.",
        "ALERT_SUCCESS_ELECTRONIC": "Je document is verzonden voor elektronische ondertekening.",
        "ALERT_ERROR_500": "Er is een fout opgetreden bij het samenstellen van het document. Probeer het opnieuw of neem contact met ons op als dit bericht zich blijft voordoen.",
        "ALERT_ERROR_400": "We konden je document niet samenstellen. Controleer je Merge Tags en probeer het opnieuw.",
        "TITLE": "Een document ter ondertekening verzenden",
        "ALERT_INFO": "Door Merge Tags in uw sjabloon of bestand te plaatsen, kan Rotaready belangrijke informatie invoegen over de werknemer naar wie u de informatie stuurt, zoals hun naam of functie. Het is ook de manier waarop u bepaalt waar het handtekeningvak wordt weergegeven.",
        "ALERT_INFO_LINK": "Ontdek hoe",
        "LABEL_SEND_TO": "Verzenden naar",
        "LABEL_DOCUMENT": "Document",
        "LABEL_DOCUMENT_TEMPLATE": "Een bestaand sjabloon gebruiken",
        "LABEL_DOCUMENT_UPLOAD": "Een eenmalig bestand uploaden",
        "LABEL_TEMPLATE": "Sjabloon",
        "ALERT_NO_TEMPLATES_TITLE": "Je hebt geen sjablonen.",
        "ALERT_NO_TEMPLATES_HELP": "Bespaar tijd door een bestand één keer te uploaden en opnieuw te gebruiken voor meerdere personen.",
        "ALERT_NO_TEMPLATES_BUTTON": "Een sjabloon uploaden",
        "LABEL_UPLOAD": "Uploaden",
        "SECTION_SIGNING_INFO": "Informatie over de ondertekening",
        "LABEL_TITLE": "Titel",
        "HELP_TEXT_TITLE": "Geef dit document een titel zodat de persoon naar wie je het stuurt weet wat het is.",
        "LABEL_EMPLOYER_SIGNS": "Tekens van de werkgever",
        "OPTION_EMPLOYER_SIGN_FALSE": "Nee, alleen de werknemer",
        "OPTION_EMPLOYER_SIGN_TRUE": "Een handtekening namens de werkgever is vereist",
        "LABEL_EMPLOYER_SIGNATORY": "Ondertekenaar door de werkgever",
        "HELP_TEXT_EMPLOYER_SIGNATORY": "Selecteer wie dit document namens de werkgever moet ondertekenen.",
        "LABEL_SIGNATURE_METHOD": "Handtekeningsmethode",
        "OPTION_SIGNATURE_METHOD_MANUAL": "Handleiding, met pen en papier",
        "OPTION_SIGNATURE_METHOD_ELECTRONIC": "Elektronisch, met volledig legale e-handtekening",
        "LABEL_EMAIL_MESSAGE": "E-mailbericht",
        "HELP_TEXT_EMAIL_MESSAGE": "Voeg een optioneel bericht toe aan de e-mailmelding die naar alle ondertekenende partijen is verzonden.",
        "SECTION_UPON_COMPLETION": "Na voltooiing",
        "SECTION_METHOD": "Handtekeningsmethode",
        "LABEL_SAVE_TO": "Opslaan naar",
        "HELP_TEXT_SAVE_TO": "Na ondertekening wordt het bestand opgeslagen in de documenten van de werknemer onder de gekozen categorie.",
        "LABEL_EXPIRY_DATE": "Vervaldatum",
        "HELP_TEXT_EXPIRY_DATE": "Het document moet een vervaldatum krijgen als de bestemmingscategorie dit vereist. Je wordt gewaarschuwd om actie te ondernemen als de datum nadert en opnieuw als de datum verstrijkt.",
        "CREDITS_DEPLETED_TEXT": "Je hebt credits nodig om dit document te verzenden voor elektronische handtekening.",
        "CREDITS_DEPLETED_CTA": "Chat nu met ons",
        "CREDITS_AVAILABLE_TEXT": "Je hebt nog <b>{{balance}}</b> krediet (en) over. Voor het verzenden van dit document wordt één credit gebruikt.",
        "CREDITS_AVAILABLE_CTA": "Het is eenvoudig om je credits op te waarderen. Stuur ons gewoon een bericht via livechat.",
        "BUTTON_CANCEL": "Annuleer",
        "BUTTON_DOWNLOAD_PREVIEW": "Voorvertoning downloaden",
        "BUTTON_SAVE_DOWNLOAD": "Opslaan en downloaden",
        "BUTTON_SEND": "Verzenden"
      },
      "NOTES": {
        "ERROR_500": "We kunnen op dit moment geen notities laden. Probeer het nog eens.",
        "ALERT_CONFIRM_DELETE": "Weet je zeker dat je deze notitie wilt verwijderen?",
        "BODY_PLACEHOLDER": "Schrijf een nieuwe notitie...",
        "SAVE_BUTTON_TEXT": "Notitie toevoegen",
        "VISIBILITY_SELF": "Privé",
        "VISIBILITY_SELF_HELP": "Alleen zichtbaar voor jou",
        "VISIBILITY_SENSITIVE": "Gevoelig",
        "VISIBILITY_SENSITIVE_HELP": "Alleen zichtbaar voor mensen met toestemming om gevoelige notities te zien",
        "VISIBILITY_GENERAL": "Generaal",
        "VISIBILITY_GENERAL_HELP": "Alleen zichtbaar voor mensen die toestemming hebben om de notities van deze werknemer te zien",
        "EDITED_LABEL": "Bewerkt",
        "EDIT_LABEL": "Bewerken",
        "DELETE_LABEL": "Verwijderen",
        "NO_NOTES": "Er zijn geen notities om te tonen."
      },
      "DANGER_ZONE": {
        "ANONYMISE": {
          "TITLE": "Medewerker anonimiseren",
          "ERROR_500": "We konden toen niet redden. Probeer het opnieuw.",
          "ERROR_403": "Je hebt geen toestemming om toegang te krijgen tot de persoonlijke gegevens van deze werknemer. Dit betekent dat je niet kunt plannen dat hun profiel wordt geanonimiseerd.",
          "LABEL_END_DATE": "Einddatum van het dienstverband",
          "HELP_TEXT": "Om te helpen voldoen aan de wetgeving inzake gegevensbescherming, kan Rotaready na een bepaalde periode alle persoonlijke informatie uit dit werknemersprofiel verwijderen. In het VK vereist HMRC dat gegevens minimaal zes jaar worden bewaard.",
          "HELP_TEXT_LEARN": "Meer informatie",
          "LABEL_ANONYMISE": "Anonimiseren",
          "CHECKBOX_ANONYMISE": "Anonimiseer deze werknemer",
          "LABEL_ANONYMISE_DATE": "Anonimiseer op",
          "ALERT_ANONYMISE_DATE": "Om deze werknemer te anonimiseren, moet u een datum kiezen die zowel in de toekomst ligt als na de laatste werkdag van de werknemer.",
          "BUTTON_SAVE": "Opslaan"
        }
      }
    },
    "ABSENCE": {
      "ABSENCE": {
        "TITLE": "Verzuimkalender",
        "FILTER_LABEL_APPROVAL_STATE": "Status",
        "FILTER_LABEL_POSITION": "Rol op het werk",
        "FILTER_LABEL_STAFF_GROUP": "Personeelsgroep",
        "ERROR_500": "We konden de afwezigheid op dat moment niet laden. Probeer het opnieuw.",
        "FILTER_LABEL_ABSENCE_TYPE": "Typ",
        "FILTER_LABEL_REQUESTER": "Aanvrager"
      }
    },
    "REQUESTS": {
      "ABSENCE": {
        "DATES_COLUMN_TEXT": "Datum (s)",
        "WHO_COLUMN_TEXT": "Wie",
        "ABSENCE_TYPE_COLUMN_TEXT": "Typ",
        "SUBMITTED_DATE_TEXT": "Ingediend",
        "HOURS_COLUMN_TEXT": "Uren",
        "DAYS_COLUMN_TEXT": "Dagen",
        "NO_STAFF_MATCH_TEXT": "We konden geen aanvragen vinden die overeenkomen met je filters.",
        "FILTER_LABEL_POSITION": "Rol op het werk",
        "FILTER_LABEL_STAFF_GROUP": "Personeelsgroep",
        "ERROR_500": "We konden de afwezigheid op dat moment niet laden. Probeer het opnieuw.",
        "FILTER_LABEL_ABSENCE_TYPE": "Typ",
        "FILTER_LABEL_REQUESTER": "Aanvrager"
      },
      "CONFIRM_SWAP_APPROVAL_MODAL": {
        "TITLE": "Weet je dat zeker?",
        "VALIDATION_MANUAL_TEXT": "Om deze shift swap ongedaan te maken, moet je het rooster handmatig bewerken.",
        "YES_TITLE": "Ja",
        "APPROVE_BUTTON_TEXT": "Keur deze swap nu goed en stel beide medewerkers op de hoogte",
        "NO_TITLE": "Nee",
        "DO_NOTHING_BUTTON_TEXT": "Doe niets - keur deze swap nog niet goed",
        "CANCEL_BUTTON_TEXT": "Annuleer"
      },
      "INDEX": {
        "HEADING": "Items die aandacht behoeven",
        "ABSENCE_LABEL": "Verzoeken om afwezigheid",
        "SWAPS_LABEL": "Shift-swaps",
        "DOCUMENTS_EXPIRING_LABEL": "Documenten die verlopen",
        "ACCOUNT_APPROVAL_LABEL": "Nieuwe toetreders"
      },
      "SWAPS": {
        "INITIATED_BY_TEXT": "Geïnitieerd door",
        "INITIATED_TOOLTIP": "Het personeelslid dat zijn shift weg wil geven",
        "SHIFT_COLUMN_TEXT": "Hun shift",
        "SWAPPING_WITH_COLUMN_TEXT": "Ruilen met",
        "SWAPPING_TOOLTIP": "Het personeelslid dat in ploegendienst wil werken",
        "RETURN_SHIFT_COLUMN_TEXT": "Hun shift",
        "OPTION_COLUMN_TEXT": "Opties",
        "ACCEPTED_DATE_COLUMN_TEXT": "Wachtend sinds",
        "NONE_TEXT": "Geen",
        "NO_STAFF_MATCH_TEXT": "We konden geen aanvragen vinden die overeenkomen met je filters.",
        "FILTER_LABEL_PARTICIPANT": "Deelnemer"
      },
      "DOCUMENTS": {
        "NAME_COLUMN_LABEL": "Naam",
        "FILE_NAME_COLUMN_LABEL": "Document",
        "FILE_SIZE_COLUMN_LABEL": "Grootte",
        "CATEGORY_COLUMN_LABEL": "Categorie",
        "EXPIRY_DATE_COLUMN_LABEL": "Vervalt",
        "OPTIONS_COLUMN_LABEL": "Opties",
        "EXPIRED": "Vervallen",
        "UPLOAD_NEW_LINK": "Nieuw uploaden",
        "NO_DOCS_MATCH_TEXT": "We konden geen documenten vinden die aan je filters voldoen.",
        "NO_CATEGORIES_TEXT": "Je hebt geen categorieën voor documenten die kunnen verlopen.",
        "FILTER_LABEL_SITE": "Site/afdeling",
        "FILTER_LABEL_APPOINTMENT": "Afspraak",
        "FILTER_LABEL_PERSON": "Persoon",
        "FILTER_LABEL_CATEGORY": "Categorie",
        "FILTER_LABEL_EXPIRY": "Vervaldatum",
        "APPOINTMENT_STATUS_ACTIVE": "Actief",
        "APPOINTMENT_STATUS_INACTIVE": "Inactief",
        "X_WEEKS_OR_LESS": " weken of minder"
      },
      "NEW_JOINERS": {
        "NAME_COLUMN_LABEL": "Naam",
        "POSITION_COLUMN_LABEL": "Rol op het werk",
        "ENTITY_COLUMN_LABEL": "Site",
        "START_DATE_COLUMN_LABEL": "Begint op",
        "OPTIONS_COLUMN_LABEL": "Opties",
        "NO_STAFF_MATCH_TEXT": "We konden niemand vinden die aan je filters voldoet.",
        "APPROVE_LINK": "Goedkeuren",
        "FILTER_LABEL_APPOINTMENT": "Afspraak",
        "FILTER_LABEL_POSITION": "Rol op het werk",
        "FILTER_LABEL_DATE_START": "Startdatum",
        "APPOINTMENT_STATUS_ACTIVE": "Actief",
        "APPOINTMENT_STATUS_INACTIVE": "Inactief"
      }
    },
    "TAGS_GROUPS": {
      "TAGS_GROUPS": {
        "HEADING": "Tags en groepen",
        "ASIDE_TEXT": "Een medewerker kan maar tot één groep behoren, maar er kunnen veel tags aan worden toegewezen. Groepen lijken op iemands afdeling of team, terwijl tags alles kunnen vertegenwoordigen wat je zinvol vindt, zoals vaardigheden of persoonlijke kwaliteiten.",
        "CREATE_NEW_TAG_PLACEHOLDER": "Nieuwe tag aanmaken...",
        "ADDING_BUTTON_TEXT": "Toevoegen...",
        "ADD_BUTTON_TEXT": "Toevoegen",
        "TAG_HELP": "Klik op een tag om deze te bewerken en om te zien aan wie de tag is toegewezen:",
        "CREATE_ONE_TEXT": "Je hebt momenteel geen tags. Waarom maak je er niet een?",
        "CREATE_NEW_GROUP_PLACEHOLDER": "Nieuwe groep aanmaken...",
        "GROUPS_TITLE": "Groepen",
        "EDIT_LINK_TEXT": "bewerken"
      },
      "VIEW_GROUP_MODAL": {
        "EDIT_GROUP_TITLE": "Groep bewerken",
        "SITE_LABEL": "Site",
        "EDIT_TAB_HEADING": "Bewerken",
        "ORG_WIDE_LABEL": "Organisatiebreed",
        "GROUP_NAME_LABEL": "Naam van de groep",
        "AVAILABILITY_LABEL": "Beschikbaarheid",
        "ALWAYS_AVAILABLE_TEXT": " Altijd beschikbaar",
        "ALWAYS_AVAILABLE_HELP": "Medewerkers in deze groep worden geacht altijd beschikbaar te zijn voor werk, behalve wanneer hun beschikbaarheidspatroon of afwezigheid anders vereisen.",
        "ALWAYS_UNAVAILABLE_TEXT": " Altijd niet beschikbaar",
        "ALWAYS_UNAVAILABLE_HELP": "Medewerkers in deze groep zijn standaard niet beschikbaar. Ze gebruiken 'uitzonderingen' en 'beschikbaarheid voor één week' om aan te geven wanneer ze vrij zijn.",
        "MEMBERS_TAB_HEADING": "Leden",
        "NO_ACTIVE_MEMBERS_HELP": "Er zijn geen actieve leden in deze groep. <br /><br />Medewerkers worden via hun aanstelling aan een groep toegewezen. Zoek iemand in de personeelslijst, klik op het tabblad Afspraken en bewerk een bestaande afspraak of voeg een nieuwe toe.",
        "DELETE_TAB_HEADING": "Verwijderen",
        "MOVE_MEMBERS_TO_LABEL": "Leden verplaatsen naar",
        "DELETE_TAB_ALERT": "Als je deze groep verwijdert, gaan {{totalMembers}} mensen naar een andere groep. Dit is onomkeerbaar en kan niet ongedaan worden gemaakt.",
        "CONFIRM_NAME_LABEL": "Typ de naam van de groep",
        "CONFIRM_NAME_HELP_TEXT": "Om ons ervan te overtuigen dat u dit wilt doen, typt u de naam van de groep in het vak.",
        "DELETING_BUTTON_TEXT": "Verwijderen...",
        "DELETE_BUTTON_TEXT": "Verwijderen",
        "SAVING_BUTTON_TEXT": "Opslaan...",
        "SAVE_BUTTON_TEXT": "Opslaan",
        "CANCEL_BUTTON_TEXT": "Annuleer"
      },
      "VIEW_TAG_MODAL": {
        "EDIT_TAG_TITLE": "Tag bewerken",
        "EDIT_CLONE_LABEL": "Bewerken/klonen",
        "EDIT_EXISTING_TAG_TEXT": " Bestaande tag bewerken",
        "CLONE_TAG_TEXT": " Tag klonen en als nieuw opslaan",
        "TAG_NAME_LABEL": "Tag-naam",
        "STAFF_MEMBERS_LABEL": "Personeelsleden",
        "TAG_TYPEAHEAD_PLACEHLDER": "Typ om personeel te taggen",
        "LAST_EDITED_LABEL": "Laatst bewerkt",
        "ON_TEXT": "op {{date|momentFormat: 'llll'}}",
        "DELETING_BUTTON_TEXT": "Verwijderen...",
        "DELETE_BUTTON_TEXT": "Verwijderen",
        "SAVING_BUTTON_TEXT": "Opslaan...",
        "SAVE_BUTTON_TEXT": "Opslaan",
        "CLONING_BUTTON_TEXT": "Klonen...",
        "CLONE_BUTTON_TEXT": "Kloon",
        "CANCEL_BUTTON_TEXT": "Annuleer",
        "CLOSE_BUTTON_TEXT": "Sluiten"
      }
    },
    "ADD_NEW": {
      "TITLE": "Een medewerker toevoegen",
      "LABEL_FIRST_NAME": "Voornaam",
      "LABEL_LAST_NAME": "Achternaam",
      "LABEL_DOB": "Geboortedatum",
      "LABEL_EMAIL": "E-mailadres",
      "HELP_TEXT_EMAIL_OIDC": "Dit moet exact overeenkomen met wat de werknemer gebruikt voor uw Single Sign-On-service. Het eindigt meestal met de domeinnaam van uw bedrijf.",
      "HELP_TEXT_EMAIL_ROTAREADY": "Iedereen heeft een account op Rotaready en gebruikt zijn e-mailadres om in te loggen.",
      "LABEL_COPY_EXISTING": "Kopieer een bestaande medewerker",
      "PLACEHOLDER_COPY_EXISTING_NO_THANKS": "Nee, bedankt",
      "HELP_TEXT_COPY_EXISTING": "Kopieer de afspraak, het vakantiegeld en de tags van een andere medewerker.",
      "LABEL_DATE_START": "Startdatum van het dienstverband",
      "LABEL_SITE": "Site/afdeling",
      "LABEL_PERMISSIONS": "Machtigingsniveau",
      "LABEL_INVITE": "Nodig uit voor Rotaready",
      "HELP_TEXT_INVITE_OIDC": "Stuur een magische link naar deze medewerker, die hem uitnodigt om voor de eerste keer in te loggen.",
      "HELP_TEXT_INVITE_ROTAREADY": "Stuur een magische link naar deze medewerker, waarin ze worden uitgenodigd om hun wachtwoord in te stellen en in te loggen om hun diensten te zien.",
      "LABEL_ONBOARDING": "Onboarding",
      "CHECKBOX_ONBOARDING_OPT_IN": "Vereist dat deze medewerker de self-onboarding voltooit wanneer hij zich voor het eerst aanmeldt bij Rotaready. Afhankelijk van de instellingen van je organisatie worden ze gevraagd om persoonlijke informatie te verstrekken, zoals hun adres, contactpersonen voor noodgevallen en documenten over het recht op werk.",
      "BUTTON_ADD": "Toevoegen",
      "BUTTON_ADD_LOADING": "Toevoegen...",
      "ALERT_SUCCESS": "Succes! Voltooi het instellen van je medewerker door elk tabblad in te vullen.",
      "ALERT_500": "We konden de medewerker op dat moment niet toevoegen. Probeer het nog eens.",
      "ALERT_403": "Je hebt geen toestemming om een nieuwe medewerker toe te voegen.",
      "ALERT_403_PERMISSION_LEVEL": "Je hebt geen toestemming om iemand toe te voegen op dit machtigingsniveau.",
      "ALERT_403_COPY_EXISTING": "Je hebt geen toestemming om deze werknemer te kopiëren.",
      "ALERT_403_ENTITY": "U heeft geen toestemming om iemand toe te voegen aan deze site/afdeling.",
      "DUPLICATE_MODAL": {
        "TITLE": "Bestaande werknemers",
        "DESCRIPTION": "De persoon die je probeert toe te voegen, deelt enkele kenmerken met een of meer bestaande werknemers. Als deze persoon eerder voor je organisatie heeft gewerkt, voeg dan een nieuwe baan toe.",
        "COLUMN_NAME": "Naam",
        "COLUMN_EMPLOYMENT": "Werkgelegenheid",
        "COLUMN_SITE": "Site/afdeling",
        "COLUMN_SIMILARITIES": "Overeenkomsten",
        "COLUMN_OPTIONS": "Opties",
        "STATUS_ACTIVE": "Actief",
        "STATUS_INACTIVE": "Inactief",
        "OPTION_VIEW": "Bekijk de medewerker",
        "OPTION_NEW_EMPLOYMENT": "Nieuwe baan",
        "BUTTON_PROCEED": "Ga toch verder",
        "BUTTON_BACK": "Ga terug",
        "SIMILARITY_NAME": "Naam",
        "SIMILARITY_EMAIL": "Email",
        "SIMILARITY_MOBILE": "Mobiel telefoonnummer",
        "SIMILARITY_DOB": "Geboortedatum"
      }
    }
  },
  "DIRECTIVES": {
    "STAFF": {
      "SEARCH": {
        "PLACEHOLDER": "Zoek...",
        "AND_X_MORE": "En {{x}} meer...",
        "INACTIVE_APPOINTMENT": "Inactief"
      },
      "ADD_ROUTINE_MAPPING_FORM": {
        "STARTS_LABEL": "Begint",
        "STARTS_TEXT": "De eerste dag van deze routine van {{firstName}}",
        "ENDS_INPUT_TEXT": " Eindigt",
        "ENDS_TEXT": "De laatste dag van deze routine van {{firstName}}",
        "ONGOING_TEXT": "Doorlopend",
        "SHIFT_ROUTINE_LABEL": "Ploegendienst",
        "OFFSET_LABEL": "Offset",
        "OFFSET_HELP": "Dit is het aantal dagen dat {{firstName}} zou moeten beginnen met dit patroon."
      },
      "AVAILABILITY_RULE": {
        "IM_UNAVAILABLE_TEXT": "Ik zal niet beschikbaar zijn",
        "CANCEL_TEXT": "annuleren",
        "AVAILABLE_TEXT": "BESCHIKBAAR",
        "UNAVAILABLE_TEXT": "NIET BESCHIKBAAR",
        "AVAILABLE_FROM_TEXT": "Ik ben alleen beschikbaar vanaf ",
        "UNAVAILABLE_FROM_TEXT": "Ik ben niet beschikbaar vanaf ",
        "TO_TEXT": " naar ",
        "ALL_DAY": "De hele dag",
        "AM_ONLY": "Alleen AM",
        "PM_ONLY": "Alleen in de PM",
        "AVAIALABLE_CUSTOM": "Beschikbaar (op maat)",
        "UNAVAILABLE_CUSTOM": "Niet beschikbaar (aangepast)",
        "UNAVAILABLE": "Niet beschikbaar",
        "AVAILABLE": "Beschikbaar",
        "AVAILABLE_AM": "Beschikbaar (alleen's ochtends)",
        "AVAILABLE_PM": "Beschikbaar (alleen's middags)"
      },
      "AUDIT_USER_WIDGET": {
        "EDITED_BY": "Bewerkt door"
      },
      "VALIDATION_BULK_ABSENCE_EDIT": {
        "INFO_TRUNCATED": "Vanwege het grote aantal afwezigheidsboekingen kunnen ze hier niet in bulk worden bewerkt. Gebruik het tabblad Afwezigheid op het profiel van de werknemer of neem contact op met Rotaready voor verdere hulp.",
        "INFO_SUCCESS": "Er zijn geen tegenstrijdige afwezigheidsboekingen meer over.",
        "ERROR_500": "We kwamen net op dat moment een onverwachte fout tegen. Probeer het opnieuw.",
        "ERROR_400": "Die wijziging kan niet worden aangebracht omdat deze overlapt met een datum die is afgetekend.",
        "COLUMN_DATES": "Datum (s)",
        "COLUMN_TYPE": "Typ",
        "COLUMN_HOURS": "Uren",
        "COLUMN_DAYS": "Dagen",
        "COLUMN_STATUS": "Status",
        "COLUMN_OPTIONS": "Opties",
        "OPTION_CANCEL": "Annuleer"
      },
      "VALIDATION_BULK_ATTENDANCE_EDIT": {
        "INFO_TRUNCATED": "Vanwege het aantal bezochte evenementen kunnen ze hier niet in bulk worden bewerkt. Gebruik het tabblad Aanwezigheid op het profiel van de werknemer of neem contact op met Rotaready voor verdere hulp.",
        "INFO_SUCCESS": "Er zijn geen tegenstrijdige aanwezigheidsgebeurtenissen meer over.",
        "ERROR_500": "We kwamen net op dat moment een onverwachte fout tegen. Probeer het opnieuw.",
        "ERROR_400": "Die wijziging kan niet worden doorgevoerd omdat deze samenvalt met een datum die is afgetekend.",
        "COLUMN_EVENT": "Evenement",
        "COLUMN_DATE": "Datum",
        "COLUMN_OPTIONS": "Opties",
        "EVENT_TYPE_CLOCK_IN": "Inchecken",
        "OPTION_DELETE": "Verwijderen"
      },
      "VALIDATION_BULK_ROUTINE_MAPPING_EDIT": {
        "INFO_TRUNCATED": "Vanwege de hoeveelheid ploegendiensttoewijzingen die van invloed zijn, kunnen ze hier niet in bulk worden bewerkt. Gebruik het tabblad Shift Routines op het profiel van de werknemer of neem contact op met Rotaready voor verdere hulp.",
        "INFO_SUCCESS": "Er zijn geen conflicterende routingtoewijzingen van de ploegendienst meer over.",
        "ERROR_500": "We kwamen net op dat moment een onverwachte fout tegen. Probeer het opnieuw.",
        "ERROR_400": "Die wijziging kan niet worden aangebracht omdat deze overlapt met een datum die is afgetekend.",
        "COLUMN_START_DATE": "Startdatum",
        "COLUMN_END_DATE": "Einddatum",
        "COLUMN_SHIFT_ROUTINE": "Ploegendienst",
        "COLUMN_OPTIONS": "Opties",
        "LABEL_OPEN_ENDED": "Met een open einde",
        "OPTION_ADD_END_DATE": "Einddatum toevoegen",
        "OPTION_DELETE": "Verwijderen"
      },
      "VALIDATION_BULK_SHIFT_EDIT": {
        "INFO_TRUNCATED": "Vanwege het aantal betrokken ploegen kunnen ze hier niet in bulk worden bewerkt. Wijzig de bijbehorende roosters of neem contact op met Rotaready voor verdere hulp.",
        "INFO_SUCCESS": "Er zijn geen tegenstrijdige verschuivingen meer over.",
        "ERROR_500": "We kwamen net op dat moment een onverwachte fout tegen. Probeer het opnieuw.",
        "ERROR_400": "Die wijziging kan niet worden aangebracht. Het kan overlappen met een datum die is afgetekend. Om meer te weten te komen, probeer dit op de rota zelf.",
        "BUTTON_UNASSIGN_SELECTED": "De toewijzing van geselecteerde items ongedaan maken",
        "BUTTON_DELETE_SELECTED": "Geselecteerde verwijderen",
        "COLUMN_DATE": "Datum",
        "COLUMN_TIME": "Tijd",
        "COLUMN_DEPARTMENT": "Afdeling",
        "COLUMN_TYPE": "Typ",
        "COLUMN_STATUS": "Status",
        "COLUMN_OPTIONS": "Opties",
        "STATUS_PUBLISHED": "Gepubliceerd",
        "STATUS_DRAFT": "Ontwerp",
        "OPTION_UNASSIGN": "Toewijzen ongedaan maken",
        "OPTION_DELETE": "Verwijderen"
      },
      "VALIDATION_EPHEMERAL_RECORDS": {
        "LABEL_OPEN_ENDED": "(met een open einde)",
        "LABEL_NEW": "Nieuw",
        "LABEL_EXISTING": "Bestaande",
        "EMPLOYMENT": "Werkgelegenheid",
        "APPOINTMENT": "Afspraak"
      },
      "ACCOUNT_COMPLETION_TRACKER": {
        "CONSTITUENT_PERSONAL": "Persoonlijk",
        "CONSTITUENT_EMPLOYMENT": "Werkgelegenheid",
        "CONSTITUENT_PAY": "Betalen",
        "CONSTITUENT_HOLIDAY_ALLOWANCE": "Vakantietoeslag"
      }
    },
    "CALENDAR": {
      "TIP": "Om afwezigheid aan te vragen, klikt u op een dag of klikt en sleept u om meerdere dagen tegelijk te selecteren.",
      "TODAY": "Vandaag",
      "MONTH": "Maand",
      "WEEK": "Week",
      "DAY": "Dag"
    },
    "HIERARCHY_ITEM_PICKER": {
      "PICKER": "plukker",
      "PLACEHOLDER": "Er is geen {{itemDescriptor}} geselecteerd",
      "FILTER_TO": "Filteren naar...",
      "RESET": "Resetten",
      "REMOVED_BY_FILTER": "Je geselecteerde {{itemDescriptor}} is door het filter verwijderd.",
      "NO_SELECTED": "Er is geen {{itemDescriptor}} geselecteerd"
    },
    "SUBSCRIBE_BUTTON": {
      "LABEL_ETID_1": "aangevraagd",
      "LABEL_ETID_2": "geannuleerd",
      "LABEL_ETID_3": "goedgekeurd",
      "LABEL_ETID_4": "afgewezen",
      "LABEL_ETID_5": "gemarkeerd voor goedkeuring",
      "LABEL_ETID_6": "gepubliceerd",
      "LABEL_ETID_7": "teruggezet naar concept",
      "LABEL_ETID_8": "week is afgetekend",
      "LABEL_ETID_9": "in afwachting van goedkeuring",
      "LABEL_ETID_10": "goedgekeurd",
      "LABEL_ETID_11": "aangevraagd",
      "LABEL_ETID_13": "goedgekeurd",
      "LABEL_ETID_14": "afgewezen",
      "LABEL_ETID_15": "geannuleerd",
      "LABEL_ETID_16": "naderende vervaldatum",
      "LABEL_ETID_17": "verlopen",
      "LABEL_ETID_29": "begon",
      "LABEL_ETID_30": "door iemand geaccepteerd",
      "LABEL_ETID_31": "afgewezen door iemand",
      "LABEL_ETID_32": "voltooid",
      "LABEL_ETID_36": "week is omgekeerd",
      "LABEL_ETID_37": "dag is afgetekend",
      "LABEL_ETID_38": "dag is omgekeerd",
      "DESCRIPTOR_ABSENCE": "een afwezigheidsverzoek is",
      "DESCRIPTOR_ROTA": "een rooster is",
      "DESCRIPTOR_ACCOUNT": "een werknemer is",
      "DESCRIPTOR_SWAP": "een ploegenwissel is",
      "DESCRIPTOR_COST_CONTROL": "een kostenbeheersing",
      "DESCRIPTOR_DOCUMENT": "een document is",
      "DESCRIPTOR_BROADCAST": "een ploegenuitzending is",
      "NOUN_ABSENCE": "afwezigheidsboeking",
      "NOUN_ROTA": "rooster",
      "NOUN_ACCOUNT": "werknemer",
      "NOUN_SWAP": "ploegenruil",
      "NOUN_COST_CONTROL": "record inzake kostenbeheersing",
      "NOUN_DOCUMENT": "document",
      "NOUN_BROADCAST": "ploegendienst",
      "NOTIFY_SENTENCE_EXORDIUM": "Laat me weten wanneer",
      "NOTIFY_SENTENCE_TRAILING_IN": "in...",
      "UNSUBSCRIBE": "Afmelden",
      "SUBSCRIBE": "Abonneer",
      "SEARCH_PLACEHOLDER": "Zoek...",
      "BACK_LINK": "Terug",
      "RESOURCE_SUBSCRIBE_TOOLTIP": "Blijf op de hoogte van wijzigingen in deze {{name}}",
      "RESOURCE_UNSUBSCRIBE_TOOLTIP": "Afmelden voor wijzigingen in deze {{name}}"
    },
    "ROTA": {
      "DASHBOARD_WEEK": {
        "VIEW_ROTA": "Bekijk het rooster",
        "LAST_WEEK": "Vorige week",
        "THIS_WEEK": "Deze week",
        "NO_ATTENDANCE_ISSUES": "Geen aanwezigheidsproblemen",
        "ATTENDANCE_ISSUES": "aanwezigheidsprobleem (en)",
        "RESOLVE": "Oplossen",
        "ACTUAL_SALES_ENTERED": "Werkelijke verkoopcijfers ingevoerd",
        "ACTUAL_SALES_MISSING": "<strong>Werkelijke verkoopcijfers</strong> ontbreken",
        "WTD_COMPLIANT": "Voldoet aan de arbeidstijdenvoorschriften",
        "VIEW": "Bekijken",
        "WTD_VIOLATIONS": "Overtreding (en) van de arbeidstijd",
        "SIGN_OFF_NOT_COMPLETED": "<strong>Afmelden</strong> is nog niet voltooid",
        "SIGN_OFF": "Afmelden",
        "WEEK_SIGNED_OFF": "Week afgemeld",
        "ABSENCE_REQUEST": "Verzoeken om afwezigheid",
        "ADDED_SALES_FORECAST": "Verkoopprognose toegevoegd",
        "DRAFTED_ROTA": "Opgesteld rooster",
        "MARKED_FOR_APPROVAL": "Rota gemarkeerd voor goedkeuring",
        "PUBLISHED": "Gepubliceerd rooster",
        "OUTSTANDING": "{{afwezigheden}} uitstaande",
        "NOT_ADDED": "Niet toegevoegd",
        "NOT_DRAFTED": "Niet opgesteld",
        "NOT_DONE": "Nog niet klaar",
        "NOT_PUBLISHED": "Niet gepubliceerd"
      }
    },
    "SIDEBAR": {
      "NOTIFICATION_POPOVER": {
        "NO_NOTIFICATIONS": "Geen meldingen!",
        "MARK_ALL_READ": "Alles als gelezen markeren"
      },
      "SETTINGS_POPOVER": {
        "TIMEZONE": "Deze tijdzone is anders dan je lokale tijdzone.",
        "EDIT_PREFERENCES": "Voorkeuren bewerken",
        "MANAGE_SUBSCRIPTIONS": "Abonnementen beheren",
        "SHOW_INTERCOM": "Ondersteuningschat tonen",
        "HIDE_INTERCOM": "Ondersteuningschat verbergen",
        "SIGN_OUT": "Uitloggen"
      }
    },
    "DATE_PICKER": {
      "CALENDAR": "Agenda",
      "GO_TO_TODAY": "Ga naar vandaag"
    },
    "ENTITY_SELECTOR": {
      "ORG_WIDE": "Organisatiebreed",
      "NO_OPTIONS": "Geen opties",
      "SEARCH_PLACEHOLDER": "Zoek..."
    },
    "FILTER_OPTIONS_BUTTON": {
      "IS": "is",
      "AND": "en",
      "OR": "of",
      "ANY": "Elke",
      "SEARCH_PLACEHOLDER": "Zoek...",
      "NO_OPTIONS": "Geen opties",
      "SELECT_ALL": "Alles selecteren",
      "CLEAR_SELECTED": "Selectie wissen"
    },
    "FILTER_STAFF_SEARCH_BUTTON": {
      "IS": "is",
      "ANY": "Elke",
      "RESET": "Resetten"
    },
    "FILTER_DATE_BUTTON": {
      "ANY": "ieder",
      "BEFORE": "eerder",
      "AFTER": "na",
      "BETWEEN": "ertussen",
      "IS": "is",
      "AND": "en",
      "DONE": "Klaar"
    },
    "FILTER_NUMBER_BUTTON": {
      "ANY": "is elk",
      "GREATER": "is groter dan",
      "LESS": "is minder dan",
      "EXACTLY": "is precies",
      "DONE": "Klaar"
    },
    "VALIDATION_FAILURE": {
      "TITLE": "Validatieproblemen",
      "ISSUE": "probleem",
      "ISSUES": "problemen",
      "CHANGE_MESSAGE": "De wijziging die je probeert aan te brengen heeft geresulteerd in ",
      "IGNORE_CONTINUE": "Negeer en ga toch verder",
      "IGNORE_PROBLEM": "Negeer probleemverschuivingen en probeer het opnieuw",
      "LOADING": "Aan het laden..."
    },
    "PICK_LIST": {
      "NO_OPTIONS": "Geen opties",
      "SEARCH_PLACEHOLDER": "Zoek...",
      "DEFAULT_NULL_OPTION_LABEL": "Geen"
    },
    "FILE_UPLOAD": {
      "ALERT_UNSUPPORTED_FILE_TYPE": "Helaas wordt dat type bestand niet ondersteund.",
      "ALERT_FILE_SIZE": "Helaas is dat bestand te groot.",
      "LABEL_ACCEPTED_FILE_TYPES": "Toegestane bestandstypen",
      "LABEL_LIMIT": "limiet",
      "INFO_DROP_AVAILABLE_PART_1": "Sleep een bestand hierheen of",
      "INFO_DROP_AVAILABLE_PART_2": "klik om te bladeren",
      "INFO_DROP_NOT_AVAILABLE": "Klik om naar een bestand te bladeren",
      "INFO_DRAG_ACTIVE": "Mooi doel, tijd om te stoppen!",
      "INFO_UPLOAD_FAILED": "Uploaden is mislukt, probeer het opnieuw"
    }
  },
  "AVAILABILITY": {
    "ABSENCE": {
      "TAB_ABSENCE": "Boekingen bij afwezigheid",
      "TAB_ALLOWANCES": "Vergoedingen"
    },
    "WEEKLY_AVAILABILITY_MODAL": {
      "ADD_TITLE": "Wekelijkse beschikbaarheid toevoegen",
      "EDIT_TITLE": "Wekelijkse beschikbaarheid bewerken",
      "NICKNAME": "Bijnaam",
      "PLACEHOLDER": "dwz. Tijdens het studeren",
      "HELP": "Je zou dit beschikbaarheidspatroon moeten vernoemen naar iets dat betekenisvol is, zoals 'Tijdens het studeren' als dit aangeeft wanneer je kunt werken terwijl je studeert voor examens, of bijvoorbeeld 'Zomervakantie' voor als je meestal vrij bent.",
      "STARTING_FROM": "Beginnend vanaf",
      "NEVER_ENDING": "Eindeloos",
      "YES_PATTERN_FOREVER": "Ja, laat dit patroon voor altijd voortduren",
      "NO_PATTERN_DATE": "Nee, er is een datum waarop ik wil dat dit patroon stopt",
      "STOP_DATE": "Stopdatum",
      "PATTERN_APPLIES": "Patroon is van toepassing",
      "EVERY": "Elke",
      "WEEK": "Week",
      "SAVING_BUTTON_TEXT": "Opslaan...",
      "SAVE_BUTTON_TEXT": "Opslaan",
      "CANCEL": "Annuleer"
    },
    "WEEKLY_AVAILABILITY_MODAL_SINGLE": {
      "TITLE": "Eenmalige beschikbaarheid toevoegen",
      "SUBTITLE": "Voor een enkele week",
      "WEEK_BEGINS": "De week begint",
      "HELP": "Dit is de week waarvoor je je beschikbaarheid wilt markeren.",
      "SAVING_BUTTON_TEXT": "Opslaan...",
      "SAVE_BUTTON_TEXT": "Opslaan",
      "CANCEL": "Annuleer"
    },
    "AVAILABILITY": {
      "ADD_AVAILABILITY": "Beschikbaarheid toevoegen",
      "NEW_WEEKLY_PATTERN": "Nieuw weekpatroon",
      "NEW_MONTHLY_PATTERN": "Nieuw maandpatroon",
      "NEW_CUSTOM_PATTERN": "Nieuw aangepast patroon",
      "ONE_OFF_PATTERN": "Eenmalig (één week)",
      "AVAILABILITY": "Beschikbaarheid",
      "AVAILABILITY_HELP": "Beheer de beschikbaarheid hier aan de hand van beschikbaarheidspatronen; zie dit als terugkerende agenda-afspraken.",
      "AVAILABILITY_TIP": "Gebruik een maandelijks patroon om datums als 'de eerste woensdag' of 'de laatste zaterdag' van elke maand te targetten.",
      "NICKNAME": "Bijnaam",
      "APPLIES_FROM_TO": "Van toepassing vanaf (/tot)",
      "OPTIONS": "Opties",
      "NO_AVAILABILITY_PATTERNS": "Er zijn geen beschikbaarheidspatronen. Voeg er een toe met de knoppen hierboven.",
      "TURN_OFF": "Uitschakelen",
      "TURN_ON": "Zet aan",
      "NO_NICKNAME": "Geen bijnaam",
      "WEEKLY": "Wekelijks",
      "MONTHLY": "Maandelijks",
      "CUSTOM": "Op maat",
      "EDIT": "Bewerken",
      "TOGGLE_DROPDOWN": "Uitklapmenu in- en uitschakelen",
      "DELETE": "Verwijderen",
      "CONFIRM_DELETE_PATTERN": "Weet je dat zeker? Het verwijderen van dit beschikbaarheidspatroon kan niet ongedaan worden gemaakt.",
      "DELETE_PATTERN_SUCCESS": "Succes! Je beschikbaarheidspatroon is verwijderd.",
      "ERROR_DELETING_PATTERN": "We konden dat patroon op dit moment niet verwijderen. Probeer het opnieuw.",
      "ERROR_TOGGLING_PATTERN": "We konden dat patroon op dit moment niet in- of uitschakelen. Probeer het opnieuw.",
      "ERROR_LOADING_PATTERNS": "We konden je beschikbaarheidspatronen niet laden. Probeer het nog eens."
    },
    "DAILY_AVAILABILITY_MODAL": {
      "ADD_CUSTOM_AVAILABILITY": "Aangepaste beschikbaarheid toevoegen",
      "EDIT_CUSTOM_AVAILABILITY": "Aangepaste beschikbaarheid bewerken",
      "NICKNAME": "Bijnaam",
      "NICKNAME_PLACEHOLDER": "dwz. Tijdens het studeren",
      "NICKNAME_HELP": "Je moet dit patroon naar iets betekenisvols vernoemen.",
      "STARTING_FROM": "Beginnend vanaf",
      "NEVER_ENDING": "Eindeloos",
      "CONTINUE_FOREVER": "Ja, laat dit patroon voor altijd voortduren",
      "PATTERN_STOP": "Nee, er is een datum waarop ik wil dat dit patroon stopt",
      "STOP_DATE": "Stopdatum",
      "PATTERN_DAYS": "Patroondagen",
      "PATTERN_NO_DAYS": "Dit patroon kent geen dagen. Voeg er een toe via de knop hieronder.",
      "DAY_NUMBER": "Dag {{number}}",
      "ILL_BE": "Ik zal zijn",
      "DELETE": "Verwijderen",
      "ADD_DAY": "Dag toevoegen",
      "SAVE": "Opslaan",
      "SAVE_LOADING": "Opslaan...",
      "CANCEL": "Annuleer",
      "UNAVAILABLE": "Niet beschikbaar",
      "AVAILABLE": "Beschikbaar",
      "AVAILABLE_AM": "Beschikbaar (alleen's ochtends)",
      "AVAILABLE_PM": "Beschikbaar (alleen's middags)",
      "OTHER": "Andere (specificeer...)",
      "MINIMUM_TWO_DAYS": "Je moet minimaal twee dagen de tijd hebben om dit patroon te volgen.",
      "STOPS_BEFORE_STARTS": "Je patroon is momenteel ingesteld om te stoppen voordat het begint!",
      "AVAILABILITY_END_BEFORE_START": "Een of meer van je beschikbaarheidsregels hebben een eindtijd die vóór de starttijd valt.",
      "ERROR_SAVING_PATTERN": "Momenteel kan dit beschikbaarheidspatroon niet worden opgeslagen. Probeer het opnieuw.",
      "ERROR_UPDATING_PATTERN": "Momenteel kan dit beschikbaarheidspatroon niet worden bijgewerkt. Probeer het nog eens."
    },
    "EDIT_PATTERN_EXCEPTIONS_MODAL": {
      "EDIT_EXCEPTIONS": "Uitzonderingen bewerken",
      "EXCEPTIONS_TIP": "Je beschikbaarheidspatronen geven aan wanneer je niet vrij bent om te werken. Je kunt je agenda echter gebruiken om ons te laten weten dat je vrij bent om te werken op een dag die je normaal niet zou kunnen. Klik gewoon op de rode markering 'niet beschikbaar' en je kunt een uitzondering maken.",
      "EXCEPTIONS": "De onderstaande lijst toont de uitzonderingen die je hebt gemaakt op je beschikbaarheidspatroon. We beschouwen je als vrij om te werken op deze dagen:",
      "DATE": "Datum",
      "OPTIONS": "Opties",
      "UNDO": "Ongedaan maken",
      "NO_EXCEPTIONS": "Je hebt nog geen uitzonderingen gemaakt.",
      "CLOSE": "Sluiten",
      "ERROR_DELETING_EXCEPTION": "Helaas konden we de uitzondering op dat moment niet verwijderen. Probeer het nog eens."
    },
    "MONTHLY_AVAILABILITY_MODAL": {
      "ADD_MONTHLY_AVAILABILITY": "Maandelijkse beschikbaarheid toevoegen",
      "EDIT_MONTHLY_AVAILABILITY": "Maandelijkse beschikbaarheid bewerken",
      "NICKNAME": "Bijnaam",
      "NICKNAME_PLACEHOLDER": "dwz. Tijdens het studeren",
      "NICKNAME_HELP": "Je zou dit beschikbaarheidspatroon moeten vernoemen naar iets dat betekenisvol is, zoals 'Tijdens het studeren' als dit aangeeft wanneer je kunt werken terwijl je studeert voor examens, of bijvoorbeeld 'Zomervakantie' voor als je meestal vrij bent.",
      "STARTING_FROM": "Beginnend vanaf",
      "NEVER_ENDING": "Eindeloos",
      "CONTINUE_FOREVER": "Ja, laat dit patroon voor altijd voortduren",
      "PATTERN_STOP": "Nee, er is een datum waarop ik wil dat dit patroon stopt",
      "STOP_DATE": "Stopdatum",
      "PATTERN_RULES": "Patroonregels",
      "PATTERN_NO_RULES": "Dit patroon kent geen regels. Voeg er een toe via de knop hieronder.",
      "EVERY": "Elke",
      "ON_THE": "op de",
      "DELETE": "Verwijderen",
      "ADD_RULE": "Regel toevoegen",
      "SAVE": "Opslaan",
      "SAVE_LOADING": "Opslaan...",
      "CANCEL": "Annuleer",
      "ORDINAL_LAST": "laatste",
      "ORDINAL_FIRST": "aanvankelijk",
      "ORDINAL_SECOND": "tweede",
      "ORDINAL_THIRD": "derde",
      "ORDINAL_FOURTH": "vierde",
      "ORDINAL_FIFTH": "vijfde",
      "MONDAY": "Maandag",
      "TUESDAY": "dinsdag",
      "WEDNESDAY": "Woensdag",
      "THURSDAY": "donderdag",
      "FRIDAY": "Vrijdag",
      "SATURDAY": "Zaterdag",
      "SUNDAY": "zondag",
      "AVAILABLE": "Beschikbaar",
      "AVAILABLE_AM": "Alleen AM",
      "AVAILABLE_PM": "Alleen in de PM",
      "MONTH": "maand",
      "MONTHS": "maanden",
      "OTHER": "Andere (specificeer...)",
      "STOPS_BEFORE_STARTS": "Je patroon is momenteel ingesteld om te stoppen voordat het begint!",
      "AVAILABILITY_END_BEFORE_START": "Een of meer van je beschikbaarheidsregels hebben een eindtijd die vóór de starttijd valt.",
      "ERROR_SAVING_PATTERN": "Momenteel kan dit beschikbaarheidspatroon niet worden opgeslagen. Probeer het opnieuw.",
      "ERROR_UPDATING_PATTERN": "Momenteel kan dit beschikbaarheidspatroon niet worden bijgewerkt. Probeer het nog eens."
    },
    "OVERVIEW": {
      "SCHEDULE": "Mijn schema",
      "SCHEDULE_HELP": "Dit is je volledige schema, inclusief ploegen die je hebt toegewezen, goedgekeurde afwezigheid en beschikbaarheid.",
      "SHIFTS": "Ploegen",
      "ABSENCE": "Afwezigheid",
      "UNAVAILABLE": "Niet beschikbaar (patronen)",
      "EDIT_EXCEPTIONS": "Uitzonderingen bewerken",
      "ICAL_FEED": "iCalendar-feed",
      "ICAL_FEED_HELP": "Met een iCalendar-feed kun je je planning buiten Rotaready bekijken.",
      "ICAL_FEED_TIP": "Nadat u een iCalendar-feed-URL hebt gegenereerd, kunt u deze kopiëren en plakken in <a href=\"https://support.apple.com/en-gb/HT202361\" target=\"_blank\">Apple Calendar</a>, <a href=\"https://support.google.com/calendar/answer/37100\" target=\"_blank\">Google Agenda</a> of andere agendasoftware.",
      "COPIED": "Gekopieerd naar het klembord!",
      "GENERATE": "Genereer"
    }
  },
  "ATTENDANCE": {
    "VALIDATION_CODES": {
      "CODE_1": "De corresponderende datum is afgetekend, dus we kunnen er geen wijzigingen in aanbrengen.",
      "CODE_2": "Dit zou resulteren in een ongeldige reeks gebeurtenissen. Een klok moet bijvoorbeeld na een check-in komen, maar mag niet eerder komen dan een nieuwe klok. Controleer de gebeurtenissen in de omgeving en probeer het opnieuw.",
      "CODE_3": "De gekozen locatie/afdeling is ongeldig.",
      "CODE_4": "We kunnen hier geen check-in toevoegen omdat de gekozen medewerker op deze datum geen afspraak heeft.",
      "CODE_5": "We kunnen hier geen evenement toevoegen omdat er tegelijkertijd al een evenement bestaat.",
      "CODE_6": "De gekozen werknemer is ongeldig.",
      "CODE_7": "Door deze wijziging wordt de gebeurtenis naar een vroeger tijdstip verplaatst dan de gebeurtenis die eraan voorafgaat. Probeer een ander tijdstip te kiezen of bewerk eerst de eerdere gebeurtenis.",
      "CODE_8": "Deze wijziging zou de gebeurtenis naar een later tijdstip verplaatsen dan de gebeurtenis die erop volgt. Probeer een ander tijdstip te kiezen of bewerk eerst de latere gebeurtenis."
    },
    "COMMON": {
      "EVENT_ADD_SUCCESS": "Het evenement is succesvol toegevoegd.",
      "ABSENCE_ADD_SUCCESS": "Ze zijn gemarkeerd als afwezig.",
      "NO_CLOCK_IN_MODAL_TITLE": "Evenement toevoegen",
      "NO_CLOCK_IN_MODAL_MESSAGE": "Wat zou je graag willen doen?",
      "NO_CLOCK_IN_MODAL_ADD_EVENT": "Klok toevoegen",
      "NO_CLOCK_IN_MODAL_ADD_EVENT_DESC": "Handmatig een aanwezigheidsrecord toevoegen",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE": "Markeer afwezig",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC": "Voeg een verzuimrecord toe, zoals ziekte",
      "UNMATCHED_MODAL_TITLE": "Ongeëvenaarde opkomst",
      "UNMATCHED_MODAL_MESSAGE": "Er zijn twee manieren om ongeëvenaarde aanwezigheid op te lossen:",
      "UNMATCHED_MODAL_ADD_SHIFT": "Shift toevoegen",
      "UNMATCHED_MODAL_ADD_SHIFT_DESC": "Creëer een nieuwe shift om weer te geven wat er is gewerkt",
      "UNMATCHED_MODAL_EDIT_EVENT": "Aanwezigheid bewerken",
      "UNMATCHED_MODAL_EDIT_EVENT_DESC": "Pas de aanwezigheidsgegevens aan als ze niet correct zijn",
      "OVERTIME_MODAL_TITLE": "Overuren bekijken",
      "OVERTIME_MODAL_MESSAGE_IN": "{{name}} is vervroegd ingeklokt en de ploegendienst voldoet aan een loonregel. Wat zou je graag willen doen?",
      "OVERTIME_MODAL_MESSAGE_OUT": "{{name}} is te laat uitgeklokt en de ploegendienst voldoet aan een loonregel. Wat zou je graag willen doen?",
      "OVERTIME_MODAL_APPROVE": "Overuren goedkeuren",
      "OVERTIME_MODAL_APPROVE_DESC": "{{name}} wordt uitbetaald voor {{duration}} minuten overuren",
      "OVERTIME_MODAL_REJECT": "Overuren weigeren",
      "OVERTIME_MODAL_REJECT_DESC": "{{name}} wordt niet betaald voor de gemaakte overuren",
      "OVERTIME_UPDATE_ERROR": "We kunnen het evenement momenteel niet updaten. Probeer het opnieuw.",
      "MODAL_USER_NOTES_LABEL": "Notities van {{name}}"
    },
    "BY_STAFF": {
      "FILTER_LABEL_DATE": "Datum",
      "MANUAL_CLOCK_IN": "Handmatig inchecken",
      "EVENT_TYPE": "Evenement",
      "RECORDED_TIME": "Opgenomen tijd",
      "METHOD": "Gevangen door",
      "OPTIONS": "Opties",
      "TRUSTED_DEVICE": "Vertrouwd apparaat",
      "MOBILE_APP": "Rotaready-app",
      "MANUALLY_OVERRIDDEN": "Handmatig overschrijven",
      "MANUAL_ENTRY": "Handmatige invoer",
      "DELETE": "Verwijderen",
      "NO_EVENTS": "Er zijn geen evenementen om te laten zien.",
      "CLOCK_IN": "Inklokken",
      "CLOCK_OUT": "Klok uit",
      "BREAK_ON": "Tijdens de pauze",
      "BREAK_OFF": "Pauze",
      "ERROR_LOADING_EVENTS": "We kunnen het aantal bezoekers momenteel niet laden. Probeer het opnieuw.",
      "ERROR_DELETING": "We kunnen de aanwezigheid op dit moment niet verwijderen. Probeer het opnieuw.",
      "DELETE_SUCCESS": "De gebeurtenis is succesvol verwijderd.",
      "ADD_SUCCESS": "Het evenement is succesvol toegevoegd.",
      "LABEL_EDITED": "Bewerkt"
    },
    "ISSUES": {
      "TITLE": "Aanwezigheidsproblemen",
      "FILTER_DATE": "Datum",
      "FILTER_ISSUE_TYPE": "Soort probleem",
      "FILTER_POSITION": "Rol op het werk",
      "FILTER_USER": "Persoon",
      "ISSUES_HELP": "Problemen moeten worden opgelost, anders leiden ze tot verschillen in de rapporten en de salarisadministratie. Rotaready markeert standaard problemen van de laatste {{DefaultIssuePeriod}} dagen.",
      "IS_THIS": "Is dit",
      "YES": "Ja",
      "NO": "Nee",
      "HEADING_NAME": "Naam",
      "HEADING_SITE": "Site",
      "HEADING_ISSUE": "Probleem",
      "HEADING_SHIFT": "Shift",
      "HEADING_START": "Begin",
      "HEADING_FINISH": "Afmaken",
      "HEADING_CLOCK_IN": "Inchecken",
      "HEADING_CLOCK_OUT": "Uitklokken",
      "HEADING_OPTIONS": "Opties",
      "NOT_FOUND": "Niet gevonden",
      "RESOLVE": "Oplossen",
      "DISMISS": "Ontslaan",
      "NO_ATTENDANCE_ISSUES": "Je hebt geen aanwezigheidsproblemen, goed gedaan!",
      "UNMATCHED_ATTENDANCE": "Ongeëvenaarde opkomst",
      "NO_CLOCK_IN": "Geen check-in",
      "NO_CLOCK_OUT": "Geen uitklok",
      "SUSPECT_CLOCK_OUT": "Verdachte klokout",
      "SUSPECT_ATTENDANCE": "Aanwezigheid van verdachte",
      "BREAK_NOT_TAKEN": "Pauze niet genomen",
      "INVALID_BREAKS": "Ongeldige pauze (s)",
      "SUSPECT_BREAK": "Verdachte breekt",
      "EARLY_CLOCK_IN": "Vroeg inchecken",
      "LATE_CLOCK_OUT": "Late uitklok",
      "UNMATCHED_ATTENDANCE_EXPLANATION": "Rotaready kon geen shift vinden om deze opkomst te evenaren",
      "NO_CLOCK_IN_EXPLANATION": "Het personeelslid heeft niet ingeklokt voor zijn shift",
      "NO_CLOCK_OUT_EXPLANATION": "Het personeelslid klokte niet uit na zijn shift",
      "SUSPECT_CLOCK_OUT_EXPLANATION": "Het personeelslid klokte veel later uit dan de geplande eindtijd",
      "SUSPECT_ATTENDANCE_EXPLANATION": "Het personeelslid was veel korter ingeklokt dan gepland",
      "BREAK_NOT_TAKEN_EXPLANATION": "De shift heeft een pauze, maar er is geen pauze genomen",
      "INVALID_BREAKS_EXPLANATION": "De pauze (s) van deze aanwezigheid zijn onvolledig (d.w.z. er is een pauze gemist)",
      "SUSPECT_BREAK_EXPLANATION": "Het personeelslid had veel langer pauze dan gepland",
      "EARLY_CLOCK_IN_EXPLANATION": "De ploegendienst komt overeen met een loonregel die goedkeuring vereist voor een vroege klok",
      "LATE_CLOCK_OUT_EXPLANATION": "De ploegendienst komt overeen met een loonregel die goedkeuring vereist voor een late klokuitgang",
      "ERROR_LOADING_ATTENDANCE": "We kunnen je aanwezigheidsproblemen op dit moment niet laden. Probeer het nog eens.",
      "SOMETHING_WENT_WRONG": "Oeps, er is gewoon iets misgegaan. Misschien moet je het opnieuw proberen!",
      "CONFIRM_DISMISS": "Weet je zeker dat je dit aanwezigheidsprobleem wilt negeren?",
      "DATE_RANGE_TOO_WIDE": "Je kunt niet meer dan 5 weken kiezen. Beperk alstublieft uw datumbereik.",
      "CLOCK_OUT_DAYS_LATER": "Deze uitklok is een of meer dagen na de check-in."
    },
    "OVERVIEW": {
      "UNMATCHED_LABEL": "{{count}} ongeëvenaard",
      "OPTIONS_DAILY": "Dagelijks",
      "OPTIONS_WEEKLY": "Wekelijks",
      "OPTIONS_DAY_ROLL_OVER": "Dagverlenging",
      "FILTER_POSITION": "Rol op het werk",
      "FILTER_USER": "Persoon",
      "MIDNIGHT": "Middernacht",
      "TOOLS": "Hulpmiddelen",
      "CURRENTLY_CLOCKED_IN": "Mensen die momenteel zijn ingeklokt",
      "ATTENDANCE_HELP": "Aanwezigheid wordt automatisch gekoppeld aan ploegendiensten als er binnen <b>{{AutomatchThreshold}} uur (en) van een geplande start- of eindtijd wordt in- of uitgecheckt.</b>",
      "MATCHED_HEADING_SCHEDULED": "Gepland",
      "MATCHED_HEADING_ACTUAL": "Actueel",
      "MATCHED_HEADING_AFTER_SANITISATION": "Na ontsmetting",
      "MATCHED_HEADING_NAME": "Naam",
      "MATCHED_HEADING_SITE": "Site",
      "MATCHED_HEADING_START": "Begin",
      "MATCHED_HEADING_FINISH": "Afmaken",
      "MATCHED_HEADING_PAID_HOURS": "Betaalde uren",
      "MATCHED_HEADING_UNPAID_HOURS": "Onbetaalde uren",
      "COVER_SHIFT": "Cover shift",
      "NO_CLOCK_IN": "Nog niet ingeklokt",
      "NO_CLOCK_OUT": "Nog geen uitklok",
      "OVERTIME_APPROVED": "Goedgekeurd",
      "OVERTIME_REJECTED": "Afgewezen",
      "OVERTIME_REVIEW": "Recensie",
      "OVERTIME_APPROVED_BY": "Overuren goedgekeurd door {{name}}",
      "OVERTIME_REJECTED_BY": "Overuren afgewezen door {{name}}",
      "MATCHED_NO_RESULTS": "Er zijn geen verschuivingen om te laten zien.",
      "UNMATCHED_ATTENDANCE": "Ongeëvenaarde opkomst",
      "UNMATCHED_ATTENDANCE_HELP": "Als de aanwezigheid niet binnen de <b>{{automatchThreshold}} uur (en) van</b> de start- en eindtijd van een dienst valt, moet je de ploegendienst of de aanwezigheidsregistratie handmatig aanpassen om ze op één lijn te brengen:",
      "UNMATCHED_HEADING_NAME": "Naam",
      "UNMATCHED_HEADING_SITE": "Site",
      "UNMATCHED_HEADING_DATE": "Datum",
      "UNMATCHED_HEADING_CLOCK_IN": "Inklokken",
      "UNMATCHED_HEADING_CLOCK_OUT": "Klok uit",
      "UNMATCHED_HEADING_OPTIONS": "Opties",
      "NO_UNMATCHED_ATTENDANCE": "Er is hier geen ongeëvenaarde opkomst. Mooi werk!",
      "NONE_YET": "Nog geen",
      "RESOLVE": "Oplossen",
      "ERROR_LOADING_ATTENDANCE": "We kunnen je aanwezigheidsgeschiedenis op dit moment niet laden. Probeer het nog eens.",
      "TIMELINESS_EARLY_TOOLTIP": "{{duration}} minuten te vroeg",
      "TIMELINESS_LATE_TOOLTIP": "{{duration}} minuten te laat",
      "TRUNCATED_RESULT_MESSAGE": "Er is meer te zien, maar om alles soepel te laten verlopen hebben we je resultaat moeten inkorten. <br />Probeer te filteren op functie, op locatie/afdeling of verklein het datumbereik."
    },
    "VIEW_EVENT_MODAL": {
      "VIEW_ATTENDANCE": "Aanwezigheid bekijken",
      "STAFF_MEMBER": "Wie",
      "EVENT_TYPE": "Evenement",
      "RECORDED_TIME": "Opgenomen tijd",
      "METHOD": "Gevangen door",
      "TRUSTED_DEVICE": "Vertrouwd apparaat",
      "MOBILE_APP": "Rotaready-app",
      "MANUALLY_OVERRIDDEN": "Handmatig overschreven",
      "MANUAL_ENTRY": "Handmatige invoer",
      "ON": "op",
      "OVERTIME": "Overuren",
      "APPROVED": "Goedgekeurd",
      "REJECTED": "Afgewezen",
      "FACIAL_RECOGNITION": "Gezichtsherkenning",
      "FACIAL_RECOGNITION_PROCESS": "Afbeeldingen worden meestal binnen enkele minuten na de opname verwerkt",
      "FACIAL_RECOGNITION_WAITING": "In afwachting van verwerking...",
      "TRUSTS_PHOTO": "Rotaready vertrouwt deze foto",
      "NO_TRUSTS_PHOTO": "Rotaready vertrouwt deze foto niet",
      "CONFIDENT": "zelfverzekerd",
      "UNABLE_TO_IDENTIFY": "Niet in staat om een gezicht duidelijk te identificeren",
      "PROCESSED_ON": "Verwerkt op",
      "ACKNOWLEDGED_BY": "Erkend door {{name}} op",
      "REFERENCE_IMAGE": "Referentieafbeelding",
      "REFERENCE_IMAGE_HELP": "Deze afbeelding wordt niet verwerkt, maar gebruikt als vergelijkingsreferentie",
      "EDIT": "Bewerken",
      "CLOSE": "Sluiten",
      "TAB_DETAILS": "Details",
      "TAB_PHOTO": "Foto",
      "TAB_LOCATION": "Locatie",
      "CLOCK_IN": "Inklokken",
      "CLOCK_OUT": "Klok uit",
      "BREAK_ON": "Even doorbreken",
      "BREAK_OFF": "Afbreken",
      "HAPPENED_HERE": "is hier gebeurd",
      "TAB_AUDIT": "Historie",
      "AUDIT_TABLE_TIME": "Aanwezigheidstijd",
      "AUDIT_TABLE_OVERTIME": "Overuren",
      "AUDIT_TABLE_WHO": "Opgenomen door",
      "NOTES": "Notities"
    },
    "EDIT_EVENT_MODAL": {
      "TITLE": "Aanwezigheid bewerken",
      "CLOCK_IN": "Inklokken",
      "CLOCK_OUT": "Klok uit",
      "BREAK_ON": "Even doorbreken",
      "BREAK_OFF": "Afbreken",
      "ERROR_UPDATING": "We kunnen het evenement momenteel niet updaten. Probeer het opnieuw.",
      "ERROR_DELETING": "We kunnen de gebeurtenis op dit moment niet verwijderen. Probeer het nog eens.",
      "CONFIRM_DELETE": "Weet je zeker dat je dit evenement wilt verwijderen?\nAls het een inklok is, wordt de corresponderende uitklok ook verwijderd.",
      "PRIOR_EVENT_INFO": "De voorgaande gebeurtenis is een <b>{{eventType}} op {{<b>date|momentFormat: 'll - LT'</b>}}</b>",
      "PRIOR_EVENT_CONFLICT": "Er bestaat al een <b>{{eventType}} op {{<b>date|MomentFormat: 'll - LT'</b>}}</b>. Je moet een andere tijd selecteren.",
      "LABEL_WHO": "Wie",
      "LABEL_EVENT": "Evenement",
      "LABEL_DATE": "Datum en tijd",
      "DATE_IN_FUTURE": "Deze tijd is nog niet voorbij!",
      "LABEL_OVERTIME": "Overuren",
      "APPROVE": "Goedkeuren",
      "REJECT": "Weigeren",
      "LABEL_OVERTIME_HELP": "Rotaready identificeerde enkele overuren toen ze dit evenement combineerde met een ploegendienst. Als je het goedkeurt, worden de overuren uitbetaald volgens de regel van de ploegendienst.",
      "DELETE": "Verwijderen",
      "CANCEL": "Annuleer",
      "SAVE": "Wijzigingen opslaan"
    },
    "ADD_EVENT_MODAL": {
      "TITLE": "Aanwezigheid toevoegen",
      "CLOCK_IN": "Inklokken",
      "CLOCK_OUT": "Klok uit",
      "BREAK_ON": "Even doorbreken",
      "BREAK_OFF": "Afbreken",
      "ERROR_ACTIONING": "We kunnen het evenement op dit moment niet toevoegen. Probeer het nog eens.",
      "PRIOR_EVENT_INFO": "De voorgaande gebeurtenis is een <b>{{eventType}} op {{<b>date|momentFormat: 'll - LT'</b>}}</b>",
      "PRIOR_EVENT_CONFLICT": "Er bestaat al een <b>{{eventType}} op {{<b>date|MomentFormat: 'll - LT'</b>}}</b>. Je moet een andere tijd selecteren.",
      "LABEL_EVENT": "Evenement",
      "LABEL_DATE": "Datum en tijd",
      "LABEL_BREAK": "Duur van de pauze",
      "LABEL_BREAK_HELP": "Na de gekozen duur wordt er een break off voor je toegevoegd.",
      "DATE_IN_FUTURE": "Deze tijd is nog niet voorbij!",
      "CANCEL": "Annuleer",
      "SAVE": "Aanwezigheid toevoegen",
      "BREAK_MINUTES": "minuten"
    }
  },
  "DIALOG": {
    "TEXTBOX_DIALOG": {
      "CANCEL": "Annuleer"
    },
    "TWO_BUTTON_DIALOG": {
      "CANCEL": "Annuleer"
    }
  },
  "OTHER": {
    "EDIT_PREFERENCES_MODAL": {
      "TITLE": "Voorkeuren bijwerken",
      "SUBTITLE": "Contactgegevens",
      "SUBTITLE_HELP": "Als u uw wachtwoord bent vergeten, kunnen we u alleen helpen het opnieuw in te stellen als we alternatieve manieren hebben om contact met u op te nemen",
      "EMAIL_LABEL": "E-mailadres",
      "EMAIL_PLACEHOLDER": "Niet verstrekt",
      "EMAIL_HELP": "Geef een geldig e-mailadres op.",
      "MOBILE_LABEL": "Mobiele telefoon",
      "MOBILE_PLACEHOLDER": "Niet verstrekt",
      "MOBILE_HELP": "Geef een geldig mobiel nummer op, of helemaal geen nummer.",
      "ACCOUNT_INFO": "Accountgegevens",
      "ACCOUNT_INFO_HELP": "Hier kun je je taal instellen",
      "LANGUAGE_LABEL": "taal",
      "LANGUAGE_HELP": "Rotaready wordt weergegeven in de door jou gekozen taal.",
      "OTHER_SETTINGS_TITLE": "Andere instellingen",
      "CLOCK_IN_LABEL": "Pincode voor inklokken",
      "CLOCK_IN_HELP": "Als u bij het in- en uitklokken een pincode moet invoeren, kunt u hier een nieuwe invoeren. Het moet uit 4 cijfers bestaan.",
      "NOTIFICATIONS_TITLE": "Meldingen",
      "NOTIFICATIONS_HELP": "Bepaal hoe je op de hoogte wilt worden gehouden van evenementen waarop je bent geabonneerd ",
      "MANAGE_SUBSCRIPTIONS": "abonnementen beheren",
      "ROTA_UPDATES": "Rota wordt bijgewerkt",
      "CRITIAL_SHIFT": "Updates voor kritieke verschuivingen",
      "EMAIL": " Email",
      "MOBILE_APP": " Mobiele app",
      "SHIFT_UPDATED": "Wanneer een ploegendienst die je in de komende 24 uur gaat werken, is bijgewerkt",
      "REGULAR_SHIFT": "Regelmatige ploegenupdates",
      "SHIFT_UPDATED_BEYOND": "Updates voor alle ploegen die je na de volgende 24 uur zult werken",
      "WEEKLY_SUMMARY": "Wekelijkse samenvatting",
      "SMS": " SMS",
      "UPCOMING_WEEK": "Een samenvatting van je diensten voor de komende week, die je de avond voor aanvang van de week toegestuurd krijgt",
      "APPROVAL": "Goedkeuring",
      "APPROVAL_HELP": "Wanneer een rooster is gemarkeerd als klaar voor goedkeuring",
      "PUBLISHING": "Publiceren",
      "PUBLISHING_HELP": "Wanneer een rooster wordt gepubliceerd of niet is gepubliceerd",
      "SHIFT_SWAPS": "Shift-swaps",
      "SWAP_UPDATES": "Updates uitwisselen ",
      "REQUESTED": "Aangevraagd",
      "REQUESTED_HELP": "Wanneer iemand een nieuwe ploegenruil aanvraagt",
      "ACCEPTED": "Geaccepteerd",
      "ACCEPTED_HELP": "Wanneer iemand een ploegenruil accepteert",
      "APPROVED": "Goedgekeurd",
      "APPROVED_HELP": "Wanneer een ploegenruil is goedgekeurd door het management",
      "REJECTED": "Afgewezen",
      "REJECTED_HELP": "Wanneer een ploegenruil door het management wordt afgewezen",
      "CANCELLED": "Geannuleerd",
      "CANCELLED_HELP": "Wanneer een ploegenruil wordt geannuleerd",
      "ABSENCE_UPDATES": "Updates over afwezigheid/feestdagen",
      "ABSENCE_REQUESTED": "Afwezigheid aangevraagd",
      "ABSENCE_REQUESTED_HELP": "Wanneer een nieuwe afwezigheid wordt aangevraagd",
      "ABSENCE_UPDATE": "Update van afwezigheid",
      "ABSENCE_UPDATE_HELP": " Wanneer een afwezigheidsverzoek wordt goedgekeurd, afgewezen of geannuleerd door het management",
      "COST_CONTROL": "Kostenbeheersing",
      "SIGN_OFF": "Afmelden",
      "SIGN_OFF_HELP": "Wanneer een site zich een week afmeldt",
      "SIGN_OFF_REVERSE": "Afmelden omgekeerd",
      "SIGN_OFF_REVERSE_HELP": "Wanneer een site de afmelding gedurende een week omkeert",
      "ADMIN": "beheerder",
      "ACCOUNT_APPROVAL": "Goedkeuring van het account",
      "ACCOUNT_APPROVAL_HELP": "Wanneer een account in afwachting is van goedkeuring of is goedgekeurd",
      "DOCUMENTS": "Documenten",
      "DOCUMENTS_HELP": "Wanneer een document is verlopen of binnenkort verloopt",
      "OTHER": "Andere",
      "COMPANY_ANNOUNCEMENTS": "Aankondigingen van het bedrijf",
      "COMPANY_HELP": "Belangrijke mededelingen van je werkgever",
      "DAILY_ADMIN_UPDATE": "Dagelijkse admin-update",
      "DAILY_UPDATE_HELP": "We zullen je op de hoogte brengen als je aanwezigheidsproblemen hebt, of als er afwezigheidsverzoeken of ploegenwisselingen zijn in afwachting van goedkeuring.",
      "PHONE_ONLY": "Meldingen met dit pictogram zijn alleen beschikbaar met de Rotaready iPhone- en Android-apps.",
      "CHANGE_PASSWORD": "Wachtwoord wijzigen",
      "SAVE": "Opslaan",
      "SAVING": "Opslaan...",
      "CANCEL": "Annuleer"
    },
    "MANAGE_SUBSCRIPTIONS_MODAL": {
      "TITLE": "Abonnementen beheren",
      "HELP": "Je abonnementen bepalen waarover je op de hoogte wilt worden gehouden.",
      "UNSUBSCRIBE_ALL": "Afmelden voor alles",
      "NOTIFY_WHEN": "Stel me op de hoogte wanneer {{descriptor}}",
      "UNSUBSCRIBE_TOOLTIP": "Afmelden",
      "UNSUBSCRIBE_ALL_TOOLTIP": "Afmelden voor alles",
      "ACTION_IN": "{{action}} in ",
      "FILTER_NO_SUBSCRIPTIONS": "Je hebt geen actieve abonnementen in {{name}}.",
      "ALL_NO_SUBSCRIPTIONS": "Je hebt geen actieve abonnementen.",
      "CLOSE": "Sluiten"
    },
    "RELEASE_REFRESH_MODAL": {
      "TITLE": "Update die klaar is voor rotatie",
      "BODY": "We hebben zojuist een update voor Rotaready uitgebracht waarbij je de pagina opnieuw moet laden. Klik op de knop hieronder.",
      "REFRESH": "Pagina verversen"
    },
    "EDIT_AVATAR_MODAL": {
      "TITLE": "Profielfoto",
      "INTRO_COPY": "Een afbeelding helpt mensen je te herkennen en laat je weten wanneer je bent ingelogd op je account.",
      "BUTTON_CHANGE": "Een nieuwe foto uploaden",
      "BUTTON_CANCEL": "Annuleer",
      "BUTTON_SAVE": "Opslaan",
      "ALERT_REMOVE": "Weet je zeker dat je deze profielfoto wilt verwijderen?",
      "ALERT_FILE_TYPE_NOT_ALLOWED": "Dat bestandstype is niet toegestaan.",
      "ALERT_ERROR_500": "We konden je foto op dat moment niet updaten. Probeer het nog eens.",
      "ALERT_SUCCESS_ADD": "Je profielfoto is bijgewerkt. Het kan even duren voordat alle diensten van Rotaready zijn bijgewerkt.",
      "ALERT_SUCCESS_REMOVE": "Je profielfoto is verwijderd. Het kan even duren voordat alle diensten van Rotaready zijn bijgewerkt."
    }
  },
  "REPORTS": {
    "INDEX_LIST": {
      "TITLE": "Rapporten",
      "TRONC_STATUS": "Tronc-status",
      "CURRENTLY_CLOCKED_IN": "Mensen die momenteel zijn ingeklokt",
      "ROTA_PROGRESS": "Rota Progress",
      "SIGN_OFF_PROGRESS": "Voortgang van de afmelding",
      "PAYROLL_EXPORT": "Export van loonadministratie",
      "LABOUR_BREAKDOWN": "Uitsplitsing van de arbeid",
      "WORKING_TIME_VIOLATIONS": "Overtredingen van de werktijden",
      "CONTRACTUAL_OBLIGATION_SUMMARY": "Samenvatting van de contractuele verplichtingen",
      "DOCUMENT_SIGNATURES": "Ondertekening van documenten",
      "EXPIRING_DOCUMENTS": "Documenten die verlopen",
      "EMPLOYEE_EXPORT": "Gegevens van de werknemer",
      "JOINERS_LEAVERS": "Schrijnwerkers en verlaters",
      "STAFF_TURNOVER": "Personeelsverloop",
      "ANNIVERSARY": "Jubilea",
      "EMPLOYMENT_CHANGES": "Veranderingen in de werkgelegenheid",
      "CATEGORY_HR": "HR",
      "CATEGORY_DOCUMENTS": "Documenten",
      "CATEGORY_ROTAS": "Planning",
      "CATEGORY_PAYROLL": "Loonlijst",
      "CATEGORY_ATTENDANCE": "Aanwezigheid",
      "CATEGORY_ABSENCE": "Afwezigheid",
      "CATEGORY_COST_CONTROL": "Kostenbeheersing",
      "ABSENCE_EXPORT": "Exporteren van afwezigheid",
      "ALLOWANCES_EXPORT": "Export van emissierechten",
      "UNTAKEN_HOLIDAY_COST": "Ongebruikte vakantiekosten",
      "COST_CONTROL_RAW_DATA": "Exporteren van onbewerkte gegevens",
      "NEW_HIRE_TURNOVER": "Omzet van nieuwe medewerkers",
      "ABSENCE_DAYS_EXPORT": "Export van afwezigheidsdagen",
      "SHIFT_TYPE_STREAM_BREAKDOWN": "Shift-type en verdeling van de stream",
      "XERO_EXPORT": "Exporteren naar Xero",
      "DOCUMENT_PRESENCE": "Aanwezigheid van documenten",
      "DOCUMENTS_EXPORT": "Exporteren van documenten",
      "ATTENDANCE_BREAKDOWN": "Uitsplitsing van de aanwezigheid",
      "LATENESS": "Laatheid",
      "LABOUR_BREAKDOWN_FILE_EXPORT": "Exporteren van bestanden met arbeidsverdeling",
      "CATEGORY_ASYNC_FILE_EXPORTS": "Grote rapporten",
      "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": "Bestand exporteren voor arbeidsverdeling",
      "PAYROLL_ASYNC_FILE_EXPORT": "Salarisbestand exporteren",
      "RAW_COST_CONTROL_ASYNC_FILE_EXPORT": "Exporteren van onbewerkte kostenbeheerbestanden"
    },
    "LATENESS": {
      "TITLE": "Laatheid",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "COLUMN_ID": "ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE_APPOINTMENT": "Site (afspraak)",
      "COLUMN_DEPT_APPOINTMENT": "Departement (afspraak)",
      "COLUMN_INSTANCES": "Instanties",
      "COLUMN_VARIANCE": "Gemiddelde variantie",
      "FILTER_DATE": "Datum",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_USER": "Persoon",
      "FILTER_OVERTIME": "Overuren",
      "FILTER_OVERTIME_NA": "Niet erkend",
      "FILTER_OVERTIME_APPROVED": "Goedgekeurd",
      "FILTER_OVERTIME_REJECTED": "Afgewezen",
      "FILTER_CLOCK_IN": "Inchecken",
      "FILTER_CLOCK_OUT": "Uitklokken",
      "FILTER_MINS_LATE": "minuten te laat",
      "FILTER_MINS_EARLY": "minuten te vroeg",
      "LABEL_MINUTES": "minuten",
      "BUTTON_EXPORT": "Exporteren",
      "NO_MATCH": "We konden geen gegevens vinden die overeenkomen met je filters."
    },
    "ATTENDANCE_BREAKDOWN": {
      "TITLE": "Uitsplitsing van de aanwezigheid",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "COLUMN_ID": "ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE_APPOINTMENT": "Site (afspraak)",
      "COLUMN_DEPT_APPOINTMENT": "Departement (afspraak)",
      "COLUMN_SITE_ATTRIBUTION": "Site (naamsvermelding)",
      "COLUMN_DEPT_ATTRIBUTION": "Departement (attributie)",
      "COLUMN_DATE": "Zakelijke datum",
      "COLUMN_SHIFT_TYPE": "Soort ploegendienst",
      "COLUMN_RECORD_TYPE": "Soort record",
      "COLUMN_SCHEDULED": "Gepland",
      "COLUMN_ACTUAL": "Actueel",
      "COLUMN_VARIANCE": "Variantie",
      "COLUMN_CAPTURED_BY": "Gevangen door",
      "COLUMN_EDITED_BY": "Bewerkt door",
      "COLUMN_EDITED_DATE": "Datum bewerkt",
      "COLUMN_OVERTIME_STATE": "Goedkeuring van overuren",
      "COLUMN_OVERTIME_EDITED_BY": "Overuren bevestigd door",
      "COLUMN_OVERTIME_EDITED_DATE": "Datum van bevestigde overuren",
      "FILTER_DATE": "Datum",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_USER": "Persoon",
      "FILTER_OVERTIME": "Overuren",
      "FILTER_OVERTIME_NA": "Niet erkend",
      "FILTER_OVERTIME_APPROVED": "Goedgekeurd",
      "FILTER_OVERTIME_REJECTED": "Afgewezen",
      "FILTER_CLOCK_IN": "Inchecken",
      "FILTER_CLOCK_OUT": "Uitklokken",
      "FILTER_MINS_LATE": "minuten te laat",
      "FILTER_MINS_EARLY": "minuten te vroeg",
      "BUTTON_EXPORT": "Exporteren",
      "NO_MATCH": "We konden geen gegevens vinden die overeenkomen met je filters."
    },
    "DOCUMENTS_EXPORT": {
      "TITLE": "Exporteren van documenten",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_TOGGLE_USER_ACTIVE": "Actieve werknemers",
      "FILTER_USER": "Persoon",
      "FILTER_CATEGORY": "Categorie",
      "FILTER_ACKNOWLEDGEMENT": "Status",
      "FILTER_OPTION_ACKNOWLEDGED": "Erkend",
      "FILTER_OPTION_NOT_ACKNOWLEDGED": "Niet erkend",
      "BUTTON_EXPORT": "Exporteren",
      "COLUMN_USER_ID": "ID van de werknemer",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Afdeling",
      "COLUMN_CATEGORY": "Categorie",
      "COLUMN_FILE_NAME": "Naam",
      "COLUMN_FILE_SIZE": "Bestandsgrootte (kB)",
      "COLUMN_UPLOADED_BY": "Geüpload door",
      "COLUMN_UPLOADED_DATE": "Datum van geüploade",
      "COLUMN_EXPIRY_DATE": "Vervaldatum",
      "COLUMN_SIGNATURE_METHOD": "Handtekeningsmethode",
      "COLUMN_EDITED_BY": "Laatst bewerkt door",
      "COLUMN_EDITED_DATE": "Datum van laatste bewerking",
      "COLUMN_ACKNOWLEDGED": "Erkend",
      "COLUMN_ACKNOWLEDGED_DATE": "Erkende datum",
      "COLUMN_NOTES": "Notities",
      "NO_MATCH": "We konden geen werknemers vinden voor uw filters."
    },
    "DOCUMENT_PRESENCE": {
      "TITLE": "Aanwezigheid van documenten",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_TOGGLE_USER_ACTIVE": "Actieve werknemers",
      "FILTER_CATEGORY": "Categorie",
      "BUTTON_EXPORT": "Exporteren",
      "COLUMN_USER_ID": "ID van de werknemer",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Afdeling",
      "COLUMN_EMPLOYMENT_ACTIVE": "Actieve tewerkstelling",
      "COLUMN_JOIN_DATE": "Datum van aanmelding",
      "COLUMN_LEAVE_DATE": "Verlofdatum",
      "COLUMN_DOCUMENTS_VALIDITY": "Geldige documenten",
      "NO_MATCH": "We konden geen werknemers vinden voor uw filters."
    },
    "SHIFT_TYPE_STREAM_BREAKDOWN": {
      "TITLE": "Uitsplitsing van het ploegtype",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_LAST_TWO_WEEKS": "Afgelopen twee weken",
      "JUMP_TO_LAST_MONTH": "Vorige maand",
      "FILTER_SITE": "Site/afdeling",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "FILTER_DATA_SOURCE": "Gegevensbron",
      "FILTER_USER": "Persoon",
      "FILTER_BEHAVIOUR": "Gedrag",
      "BEHAVIOUR_SHIFT_TYPE": "Soort ploegendienst",
      "BEHAVIOUR_STREAM": "Streamen",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE": "Huidige site",
      "COLUMN_DEPARTMENT": "Huidige afdeling",
      "COLUMN_STAFF_GROUP": "Huidige personeelsgroep",
      "COLUMN_TOTAL": "Totaal",
      "ROW_TOTAL": "Totaal",
      "INFO_NO_DATA": "Er zijn geen records die overeenkomen met je filters.",
      "INFO_NO_FILTER": "Selecteer hierboven een locatie/afdeling om aan de slag te gaan.",
      "PAY_AMOUNT_TYPE_HOURLY": "Uurtarief",
      "PAY_AMOUNT_TYPE_DAILY": "Dagtarief",
      "PAY_AMOUNT_TYPE_ANNUAL": "Jaarsalaris",
      "DATA_SOURCE_SHIFTS": "Ploegen",
      "DATA_SOURCE_MATCHED": "Ontsmet",
      "DATE_ERROR_DIFF": "Je kunt niet meer dan 6 weken kiezen. Beperk alstublieft uw datumbereik.",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "ERROR_400": "Uw parameters zorgen ervoor dat er te veel gegevens worden gegenereerd voor één rapport. Verklein de reikwijdte van uw verzoek en probeer het opnieuw.",
      "STREAM_UNALLOCATED": "Niet toegewezen",
      "FILTER_METRIC": "Waarde",
      "METRIC_WAGES": "Kosten van het basisloon",
      "METRIC_HOURS": "Betaalde uren"
    },
    "ABSENCE_DAYS_EXPORT": {
      "TITLE": "Export van afwezigheidsdagen",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "COLUMN_USER_ID": "ID van de werknemer",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Afdeling",
      "COLUMN_STAFF_GROUP": "Personeelsgroep",
      "COLUMN_JOB_ROLE_NAME": "Rol op het werk",
      "COLUMN_DATE": "Datum",
      "COLUMN_HOURS": "Uren",
      "COLUMN_HEADER_ID": "Koptekst-ID",
      "COLUMN_ABSENCE_TYPE": "Typ",
      "COLUMN_ABSENCE_TYPE_REASON": "Reden",
      "COLUMN_STATUS": "Status",
      "COLUMN_SCOPE": "Toepassingsgebied",
      "COLUMN_START_DATE": "Startdatum van de koptekst",
      "COLUMN_END_DATE": "Einddatum van de koptekst",
      "COLUMN_START_TIME": "Starttijd",
      "COLUMN_END_TIME": "Eindtijd",
      "COLUMN_TOTAL_HOURS": "Openingstijden van de header",
      "COLUMN_TOTAL_DAYS": "Header dagen",
      "COLUMN_CANCELLED": "Geannuleerd",
      "FILTER_DATE": "Datum",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_STATUS": "Status",
      "FILTER_TYPE": "Typ",
      "FILTER_USER": "Persoon",
      "FILTER_TOGGLE_CANCELLED": "Inclusief geannuleerd",
      "FILTER_TOGGLE_USER_ACTIVE": "Actieve werknemers",
      "BUTTON_EXPORT": "Exporteren",
      "NO_MATCH": "We konden geen enkele afwezigheid vinden die overeenkomt met je filters."
    },
    "NEW_HIRE_TURNOVER": {
      "TITLE": "Omzet van nieuwe medewerkers",
      "FILTER_DATE": "Datum van aanmelding",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_LAST_TWO_WEEKS": "Afgelopen twee weken",
      "JUMP_TO_LAST_MONTH": "Vorige maand",
      "FILTER_AGGREGATION": "Aggregatie",
      "AGGREGATION_ENTITY_GROUP": "Site/afdeling",
      "AGGREGATION_POSITION": "Rol op het werk",
      "AGGREGATION_STAFF_GROUP": "Personeelsgroep",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "DATE_ERROR_DIFF": "Je kunt niet meer dan 12 maanden kiezen. Beperk alstublieft uw datumbereik.",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "COLUMN_AGGREGATION_LABEL": "Naam",
      "COLUMN_JOINERS": "Schrijnwerkers",
      "COLUMN_LEAVERS": "Verlaters",
      "COLUMN_TURNOVER_RATE": "Omzetsnelheid",
      "DEPARTED_WITHIN": "Verlofdatum",
      "DAYS_LABEL": "binnen {{count}} dagen",
      "LABEL_TOTAL": "Totaal",
      "LABEL_GRAND_TOTAL": "Totaal",
      "FILTER_LEAVER_REASON": "Reden voor vertrek"
    },
    "COST_CONTROL_RAW_DATA": {
      "TITLE": "Kostenbeheersing Export van onbewerkte gegevens",
      "ERROR_500": "We konden de gegevens op dat moment niet exporteren. Probeer het nog eens.",
      "BUTTON_EXPORT": "Exporteren",
      "LOADING_TEXT": "Je export wordt gegenereerd.",
      "METRIC_HOURS": "Uren",
      "METRIC_WAGES": "Lonen",
      "METRIC_SALES": "Verkoop",
      "METRIC_COVERS": "Hoezen",
      "SUBDIVISION_PREDICTION": "Voorspellingen",
      "SUBDIVISION_FORECAST": "Voorspellingen",
      "SUBDIVISION_ACTUAL": "Actueel",
      "FILTER_METRICS": "Metrieken",
      "FILTER_SUBDIVISIONS": "Onderverdelingen",
      "FILTER_ENTITY": "Site/afdeling",
      "FILTER_ENTITY_BUTTON": "Site/afdeling",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_LAST_TWO_WEEKS": "Afgelopen twee weken",
      "JUMP_TO_LAST_MONTH": "Vorige maand",
      "CALCULATE_ACTUAL_CHECKBOX": "Bereken de werkelijke uren/lonen in een handomdraai, als er geen ondertekende gegevens aanwezig zijn.",
      "DATE_ERROR_DIFF": "Je kunt niet meer dan 2 maanden kiezen. Beperk alstublieft uw datumbereik.",
      "CALCULATE_ACTUAL_ERROR": "De werkelijke uren/lonen kunnen niet direct worden berekend voor meer dan een week of vijftien locaties/afdelingen."
    },
    "ABSENCE_EXPORT": {
      "TITLE": "Exporteren van afwezigheid",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "COLUMN_ID": "ID",
      "COLUMN_USER_ID": "ID van de werknemer",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Afdeling",
      "COLUMN_STAFF_GROUP": "Personeelsgroep",
      "COLUMN_JOB_ROLE_NAME": "Rol op het werk",
      "COLUMN_ABSENCE_TYPE": "Typ",
      "COLUMN_ABSENCE_TYPE_REASON": "Reden",
      "COLUMN_STATUS": "Status",
      "COLUMN_SCOPE": "Toepassingsgebied",
      "COLUMN_START_DATE": "Startdatum",
      "COLUMN_END_DATE": "Einddatum",
      "COLUMN_START_TIME": "Starttijd",
      "COLUMN_END_TIME": "Eindtijd",
      "COLUMN_TOTAL_HOURS": "Uren",
      "COLUMN_TOTAL_DAYS": "Dagen",
      "COLUMN_REQUEST_NAME": "Aangevraagd door",
      "COLUMN_REQUEST_DATE": "Datum van aanvraag",
      "COLUMN_REQUEST_MESSAGE": "Bericht aanvragen",
      "COLUMN_REVIEW_NAME": "Beoordeeld door",
      "COLUMN_REVIEW_DATE": "Datum van beoordeling",
      "COLUMN_REVIEW_MESSAGE": "Bericht bekijken",
      "COLUMN_EDITED_NAME": "Laatst bewerkt door",
      "COLUMN_EDITED_DATE": "Datum van laatste bewerking",
      "COLUMN_CANCELLED": "Geannuleerd",
      "COLUMN_CANCELLED_NAME": "Geannuleerd door",
      "COLUMN_CANCELLED_DATE": "Geannuleerde datum",
      "FILTER_DATE": "Start-/einddatum",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_STATUS": "Status",
      "FILTER_TYPE": "Typ",
      "FILTER_USER": "Persoon",
      "FILTER_TOGGLE_CANCELLED": "Inclusief geannuleerd",
      "FILTER_TOGGLE_USER_ACTIVE": "Actieve werknemers",
      "BUTTON_EXPORT": "Exporteren",
      "NO_MATCH": "We konden geen enkele afwezigheid vinden die overeenkomt met je filters."
    },
    "ALLOWANCE_EXPORT": {
      "TITLE": "Export van emissierechten",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "COLUMN_ID": "ID",
      "COLUMN_USER_ID": "ID van de werknemer",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Afdeling",
      "COLUMN_ABSENCE_TYPE": "Soort afwezigheid",
      "COLUMN_YEAR_STARTS": "Het jaar begint",
      "COLUMN_EFFECTIVE_DATE": "Ingangsdatum",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Schema van opbouw",
      "COLUMN_UNIT": "Eenheid",
      "COLUMN_DEDUCTIBLE": "Aftrekbaar",
      "COLUMN_TOTAL_ACCRUED": "Totaal opgebouwde",
      "COLUMN_OPENING_BALANCE": "Beginsaldo",
      "COLUMN_BOOKED": "Geboekt",
      "COLUMN_BOOKED_AND_ELAPSED": "Geboekt en verlopen",
      "COLUMN_REMAINING": "Resterend",
      "COLUMN_ACCRUAL_ESTIMATE": "Schatting op transactiebasis",
      "COLUMN_CARRIED_OVER": "Overgedragen",
      "COLUMN_OVERSPEND_LIMIT": "Te veel uitgeven",
      "COLUMN_TOTAL_TO_ACCRUE": "Maximaal op te bouwen",
      "COLUMN_ACCRUAL_RATE": "Overschrijding van het opbouwpercentage",
      "COLUMN_ADDED_NAME": "Toegevoegd door",
      "COLUMN_ADDED_DATE": "Datum toegevoegd",
      "COLUMN_EDITED_NAME": "Laatst bewerkt door",
      "COLUMN_EDITED_DATE": "Datum van laatste bewerking",
      "COLUMN_FINALISED": "Afgeronde",
      "COLUMN_FINALISED_REFERENCE_DATE": "Definitieve peildatum",
      "COLUMN_FINALISED_EDITED_NAME": "Afgerondd door",
      "COLUMN_FINALISED_EDITED_DATE": "Voltooid op",
      "COLUMN_FINALISED_NOTES": "Voltooide notities",
      "COLUMN_PROJECT_TOTAL_ACCRUED": "Tijdens het boeken opgebouwde bedrag voorspellen",
      "FILTER_DATE": "Ingangsdatum",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_TYPE": "Soort afwezigheid",
      "FILTER_SCHEDULE_OF_ACCRUAL": "Schema van opbouw",
      "FILTER_USER": "Persoon",
      "FILTER_TOGGLE_USER_ACTIVE": "Actieve werknemers",
      "BUTTON_EXPORT": "Exporteren",
      "NO_MATCH": "We konden geen vergoedingen vinden die overeenkomen met je filters."
    },
    "UNTAKEN_HOLIDAY_COST": {
      "TITLE": "Ongebruikte vakantiekosten",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "ERROR_400": "Uw effectieve datumbereik is te breed, beperk het en probeer het opnieuw.",
      "BUTTON_EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "FILTER_DATE": "Ingangsdatum van de vergoeding",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_TOGGLE_USER_ACTIVE": "Actieve werknemers",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Afdeling",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Schema van opbouw",
      "COLUMN_UNIT": "Eenheid",
      "COLUMN_DEDUCTIBLE": "Aftrekbaar",
      "COLUMN_TOTAL_ACCRUED": "Totaal opgebouwde",
      "COLUMN_BOOKED": "Geboekt",
      "COLUMN_BOOKED_AND_ELAPSED": "Verstreken",
      "COLUMN_UNTAKEN": "Ongenomen",
      "COLUMN_PAY_AMOUNT": "Bedrag",
      "COLUMN_PAY_AMOUNT_TYPE": "Typ",
      "COLUMN_PAY_PROJECTED_COST": "Verwachte kosten",
      "COLUMN_HEADER_ALLOWANCE": "Toelage",
      "COLUMN_HEADER_PAY": "Betalen",
      "LABEL_DEDUCTIBLE_YES": "Ja",
      "LABEL_DEDUCTIBLE_NO": "Nee",
      "TEXT_GRAND_TOTAL": "Totaal"
    },
    "EMPLOYMENT_CHANGES": {
      "TITLE": "Veranderingen in de werkgelegenheid",
      "MODEL_APPOINTMENT": "Afspraak",
      "MODEL_PAY": "Betalen",
      "MODEL_EMPLOYEE": "Werknemer",
      "PROPERTY_EMPLOYEE_FIRST_NAME": "Voornaam",
      "PROPERTY_EMPLOYEE_LAST_NAME": "Achternaam",
      "PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET": "Gemiddeld aantal uren handmatig ingesteld",
      "PROPERTY_APPOINTMENT_ENTITY_GROUP": "Site",
      "PROPERTY_APPOINTMENT_ENTITY": "Afdeling",
      "PROPERTY_APPOINTMENT_GROUP": "Personeelsgroep",
      "PROPERTY_APPOINTMENT_POSITION": "Rol op het werk",
      "PROPERTY_APPOINTMENT_COMPANY": "Bedrijf",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE": "Min. verplichting",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT": "Minimale verplichting (eenheid)",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD": "Minimale verplichting (periode)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE": "Maximale verplichting",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT": "Maximale verplichting (eenheid)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD": "Maximale verplichting (periode)",
      "PROPERTY_APPOINTMENT_LINE_MANAGER": "Lijnmanager",
      "PROPERTY_APPOINTMENT_PAYROLL_CALENDAR": "Salariskalender",
      "PROPERTY_PAY_AMOUNT": "Bedrag",
      "PROPERTY_PAY_AMOUNT_TYPE": "Soort bedrag",
      "PROPERTY_PAY_UPLIFT": "Loonsverhoging",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_LAST_TWO_WEEKS": "Afgelopen twee weken",
      "JUMP_TO_LAST_MONTH": "Vorige maand",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_PROPERTY": "Eigendom",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "DATE_ERROR_DIFF": "Je kunt niet meer dan 12 maanden kiezen. Beperk alstublieft uw datumbereik.",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Afdeling",
      "COLUMN_CHANGE": "Verandering",
      "COLUMN_EFFECTIVE_DATE": "Effectief",
      "COLUMN_PROPERTY": "Eigendom",
      "COLUMN_NEW_VALUE": "Nieuwe waarde",
      "COLUMN_OLD_VALUE": "Oude waarde",
      "COLUMN_EDITED_BY": "Gewijzigd door",
      "COLUMN_EDITED_ON": "Datum gewijzigd"
    },
    "ANNIVERSARY": {
      "TITLE": "Jubilea",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_LAST_TWO_WEEKS": "Afgelopen twee weken",
      "JUMP_TO_LAST_MONTH": "Vorige maand",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_TYPE": "Jubileum",
      "TYPE_BIRTHDAY": "Verjaardag",
      "TYPE_EMPLOYMENT": "Werkjubileum",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "DATE_ERROR_DIFF": "Je kunt niet meer dan 12 maanden kiezen. Beperk alstublieft uw datumbereik.",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Afdeling",
      "COLUMN_TYPE": "Jubileum",
      "COLUMN_YEARS": "Jaren",
      "COLUMN_DATE": "Datum"
    },
    "STAFF_TURNOVER": {
      "TITLE": "Personeelsverloop",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_LAST_TWO_WEEKS": "Afgelopen twee weken",
      "JUMP_TO_LAST_MONTH": "Vorige maand",
      "FILTER_AGGREGATION": "Aggregatie",
      "AGGREGATION_ENTITY_GROUP": "Site/afdeling",
      "AGGREGATION_POSITION": "Rol op het werk",
      "AGGREGATION_STAFF_GROUP": "Personeelsgroep",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "DATE_ERROR_DIFF": "Je kunt niet meer dan 12 maanden kiezen. Beperk alstublieft uw datumbereik.",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "COLUMN_AGGREGATION_LABEL": "Naam",
      "COLUMN_HEADCOUNT_START": "Personeelsbestand (start)",
      "COLUMN_HEADCOUNT_END": "Personeelsbestand (einde)",
      "COLUMN_JOINERS": "Schrijnwerkers",
      "COLUMN_LEAVERS": "Verlaters",
      "COLUMN_REHIRES": "Herindiensttredingen",
      "COLUMN_TRANSFERS_IN": "Overboekingen in",
      "COLUMN_TRANSFERS_OUT": "Overschrijvingen",
      "COLUMN_ATTRITION_RATE": "Uitvalpercentage",
      "COLUMN_AVERAGE_SERVICE": "Gemiddelde service",
      "LABEL_TOTAL": "Totaal",
      "LABEL_GRAND_TOTAL": "Totaal",
      "FILTER_LEAVER_REASON": "Reden voor vertrek"
    },
    "DOCUMENT_SIGNATURES": {
      "TITLE": "Ondertekening van documenten",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "INFO_NO_DATA": "Er zijn geen records die overeenkomen met je filters.",
      "LABEL_METHOD_MANUAL": "Handboek",
      "LABEL_METHOD_ELECTRONIC": "Elektronisch",
      "LABEL_STATUS_PENDING": "In afwachting",
      "LABEL_STATUS_REJECTED": "Afgewezen",
      "LABEL_STATUS_SIGNED": "Ondertekend",
      "LABEL_STATUS_CANCELLED": "Geannuleerd",
      "LABEL_RESPONSE_NONE": "In afwachting van een reactie",
      "LABEL_RESPONSE_REJECTED": "Afgewezen",
      "LABEL_RESPONSE_SIGNED": "Ondertekend",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_RECIPIENT_USER": "Ontvanger",
      "FILTER_ADDED_USER": "Afzender",
      "FILTER_METHOD": "Handtekeningsmethode",
      "FILTER_STATUS": "Status",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "COLUMN_RECIPIENT": "Ontvanger",
      "COLUMN_EMPLOYER": "Tegenpartij",
      "COLUMN_DOCUMENT": "Document",
      "COLUMN_DOCUMENT_CATEGORY": "Categorie van bestemming",
      "COLUMN_METHOD": "Methode",
      "COLUMN_STATUS": "Status",
      "COLUMN_ADDED_USER": "Afzender",
      "COLUMN_ADDED_DATE": "Datum",
      "EMPLOYER_NOT_SET": "Niet vereist",
      "VIEW_MODAL": {
        "TITLE": "Document om te ondertekenen",
        "LABEL_FILE": "Document",
        "LABEL_EXPIRY": "Vervalt van het document",
        "LABEL_TEMPLATE": "Sjabloon gebruikt",
        "LABEL_CATEGORY": "Categorie van bestemming",
        "LABEL_ADDED_BY": "Toegevoegd door",
        "LABEL_METHOD": "Methode",
        "TEMPLATE_NOT_USED": "Geen",
        "EXPIRY_NOT_APPLICABLE": "Niet van toepassing",
        "BUTTON_CLOSE": "Sluiten",
        "HEADING_SIGNERS": "Ondertekenaars",
        "EMPLOYEE": "Werknemer",
        "EMPLOYER": "Werkgever",
        "COLUMN_NAME": "Naam",
        "COLUMN_ROLE": "Rol",
        "COLUMN_STATUS": "Status",
        "COLUMN_DATE": "Datum",
        "LABEL_OPTIONS": "Opties",
        "OPTION_REMINDER": "Een herinnering sturen",
        "OPTION_CANCEL": "Document annuleren",
        "OPTION_UPLOAD": "Ondertekend document uploaden",
        "CANCEL_ERROR_500": "We konden het document op dat moment niet annuleren.",
        "CANCEL_SUCCESS": "Het document is geannuleerd.",
        "REMINDER_ERROR_500": "We konden op dat moment geen herinnering sturen.",
        "REMINDER_ERROR_400": "Er is onlangs al een herinnering verzonden. Wacht even voordat je nog een herinnering stuurt.",
        "REMINDER_SUCCESS": "Ik heb het! We sturen nu een herinnering per e-mail.",
        "DOWNLOAD_ERROR_500": "We konden het bestand toen niet downloaden. Probeer het opnieuw of neem contact op als dit aanhoudt.",
        "LABEL_DOWNLOAD": "Downloaden",
        "OPTION_DOWNLOAD_ORIGINAL": "Origineel downloaden",
        "OPTION_DOWNLOAD_SIGNED_COPY": "Ondertekend exemplaar downloaden"
      }
    },
    "WORKING_TIME_VIOLATIONS": {
      "TITLE": "Overtredingen van de werktijden",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_LAST_TWO_WEEKS": "Afgelopen twee weken",
      "JUMP_TO_LAST_MONTH": "Vorige maand",
      "FILTER_SITE": "Site/afdeling",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "COLUMN_SITE_DEPARTMENT": "Site/afdeling",
      "COLUMN_VIOLATION_HOURS": "Werktijden",
      "COLUMN_VIOLATION_HOURS_TOOLTIP": "Het aantal gevallen waarbij een werknemer meer dan 48 werkuren heeft gewerkt (gemiddeld over 17 weken).",
      "COLUMN_VIOLATION_DAYS_OFF": "Vrije dagen",
      "COLUMN_VIOLATION_DAYS_OFF_TOOLTIP": "Het aantal gevallen waarin een werknemer minder dan 2 dagen vrij had (in een periode van 2 weken).",
      "COLUMN_VIOLATION_REST": "Rust",
      "COLUMN_VIOLATION_REST_TOOLTIP": "Het aantal gevallen waarbij een werknemer minder dan 11 uur rust had na een werkdag.",
      "COLUMN_VIOLATION_U18_DAYS_OFF": "U18: Vrije dagen",
      "COLUMN_VIOLATION_U18_DAYS_OFF_TOOLTIP": "Het aantal gevallen waarbij een werknemer jonger dan 18 jaar minder dan twee opeenvolgende vrije dagen had (in een bepaalde week).",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH": "U18: lange ploegendienst",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH_TOOLTIP": "Het aantal gevallen waarbij een werknemer jonger dan 18 jaar een dienst langer dan 8 uur had.",
      "COLUMN_VIOLATION_U18_REST": "U18: Rust",
      "COLUMN_VIOLATION_U18_REST_TOOLTIP": "Het aantal gevallen waarbij een werknemer jonger dan 18 jaar minder dan 11 uur rust had na een werkdag.",
      "COLUMN_TOTAL": "Totaal",
      "ROW_TOTAL": "Totaal",
      "ROW_GRAND_TOTAL": "Totaal",
      "INFO_NO_FILTER": "Selecteer hierboven een locatie/afdeling om aan de slag te gaan.",
      "DATE_ERROR_DIFF": "Je kunt niet meer dan 12 maanden kiezen. Beperk alstublieft uw datumbereik.",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "VIOLATION_TYPE_1_DESC": "Mag het gemiddelde van 48 werkuren gedurende 17 weken niet overschrijden",
      "VIOLATION_TYPE_2_DESC": "Mag niet minder dan 2 dagen vrij zijn in een periode van 2 weken",
      "VIOLATION_TYPE_3_DESC": "Jonger dan 18 jaar: moet 2 opeenvolgende vrije dagen per week hebben",
      "VIOLATION_TYPE_4_DESC": "Onder de 18 jaar: ploegendienst langer dan 8 uur",
      "VIOLATION_TYPE_5_DESC": "Onder de 18 jaar: minder dan 12 uur rust na een werkdag",
      "VIOLATION_TYPE_6_DESC": "Minder dan 11 uur rust na een werkdag",
      "VIOLATION_TYPE_1": "Te veel werkuren (gemiddeld)",
      "VIOLATION_TYPE_2": "Onvoldoende vrije dagen",
      "VIOLATION_TYPE_3": "Onder de 18 jaar: onvoldoende vrije dagen",
      "VIOLATION_TYPE_4": "Onder de 18 jaar: te lang schakelen",
      "VIOLATION_TYPE_5": "Onder de 18 jaar: onvoldoende rust tussen de ploegen",
      "VIOLATION_TYPE_6": "Onvoldoende rust tussen ploegen",
      "FILTER_VIOLATION_TYPE": "Soort overtreding",
      "FILTER_PER_PAGE": "Rijen per pagina",
      "DETAIL_COLUMN_ID": "ID",
      "DETAIL_COLUMN_HR_ID": "HR ID",
      "DETAIL_COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "DETAIL_COLUMN_FIRST_NAME": "Voornaam",
      "DETAIL_COLUMN_LAST_NAME": "Achternaam",
      "DETAIL_COLUMN_SITE": "Site",
      "DETAIL_COLUMN_DEPARTMENT": "Afdeling",
      "DETAIL_COLUMN_VIOLATION": "Overtreding",
      "DETAIL_COLUMN_DATE_FROM": "Datum van",
      "DETAIL_COLUMN_DATE_TO": "Datum tot",
      "DETAIL_COLUMN_VALUE": "Waarde",
      "DETAIL_LABEL_WORKING_HOURS": "werktijden",
      "DETAIL_LABEL_WORKING_DAYS": "werkdagen",
      "DETAIL_LABEL_HOURS_REST": "uren rust",
      "DETAIL_INFO_NO_RECORDS": "Je hebt geen arbeidstijdovertredingen, topbaan!",
      "DETAIL_INFO_EMPTY_FILTER_RESULT": "Er zijn geen overtredingen die overeenkomen met je filter (s)."
    },
    "CONTRACTUAL_OBLIGATION_SUMMARY": {
      "TITLE": "Samenvatting van de contractuele verplichtingen",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_LAST_TWO_WEEKS": "Afgelopen twee weken",
      "JUMP_TO_LAST_MONTH": "Vorige maand",
      "FILTER_SITE": "Site/afdeling",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "FILTER_DATA_SOURCE": "Gegevensbron",
      "FILTER_USER": "Persoon",
      "COLUMN_GROUP_TOTAL": "Totaal (betaald)",
      "COLUMN_GROUP_OBLIGATION": "Contractuele verplichting",
      "COLUMN_GROUP_ANALYSIS_META": "Meer dan geanalyseerd",
      "COLUMN_GROUP_ANALYSIS": "Analyse",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE": "Site",
      "COLUMN_DEPARTMENT": "Afdeling",
      "COLUMN_DAYS_WORKED": "Dagen",
      "COLUMN_PAID_HOURS": "Uren",
      "COLUMN_AVERAGE_HOURS": "Gemiddeld",
      "COLUMN_AVERAGE_HOURS_TOOLTIP": "Het gemiddelde aantal gewerkte uren per dag.",
      "COLUMN_OBLIGATION_VALUE": "Waarde",
      "COLUMN_OBLIGATION_UNIT": "Eenheid",
      "COLUMN_OBLIGATION_PERIOD": "Periode",
      "COLUMN_ANALYSIS_START": "Begin",
      "COLUMN_ANALYSIS_END": "Einde",
      "COLUMN_ANALYSIS_PERIODS": "# Perioden",
      "COLUMN_ANALYSIS_PERIODS_TOOLTIP": "Het aantal weken of maanden dat is geanalyseerd.",
      "COLUMN_ANALYSIS_AVERAGE": "Gemiddeld",
      "COLUMN_ANALYSIS_AVERAGE_TOOLTIP": "Het gemiddelde aantal gewerkte uren of dagen.",
      "COLUMN_ANALYSIS_DIFFERENCE": "Diff.",
      "COLUMN_ANALYSIS_DIFFERENCE_TOOLTIP": "Het verschil tussen de minimale uren/dagen die gewerkt hadden moeten worden (de contractuele verplichting vermenigvuldigd met het aantal periodes) en wat er werkelijk gewerkt werd (het totaal aantal uren of dagen).",
      "INFO_NO_DATA": "Er zijn geen records die overeenkomen met je filters.",
      "INFO_NO_FILTER": "Selecteer hierboven een locatie/afdeling om aan de slag te gaan.",
      "PAY_AMOUNT_TYPE_HOURLY": "Uurtarief",
      "PAY_AMOUNT_TYPE_DAILY": "Dagtarief",
      "PAY_AMOUNT_TYPE_ANNUAL": "Jaarsalaris",
      "DATA_SOURCE_SHIFTS": "Ploegen",
      "DATA_SOURCE_ATTENDANCE": "Aanwezigheid",
      "DATA_SOURCE_MATCHED": "Ontsmet",
      "CONTRACTUAL_UNIT_HOURS": "Uren",
      "CONTRACTUAL_UNIT_DAYS": "Dagen",
      "CONTRACTUAL_PERIOD_WEEK": "Week",
      "CONTRACTUAL_PERIOD_MONTH": "Maand",
      "CONTRACTUAL_PERIOD_WEEKLY": "Wekelijks",
      "CONTRACTUAL_PERIOD_MONTHLY": "Maandelijks",
      "DATE_ERROR_DIFF": "Je kunt niet meer dan 14 weken kiezen. Beperk alstublieft uw datumbereik.",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens."
    },
    "LABOUR_BREAKDOWN": {
      "TITLE": "Uitsplitsing van de arbeid",
      "FILE_EXPORT_TITLE": "Exporteren van bestanden met arbeidsverdeling",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_LAST_TWO_WEEKS": "Afgelopen twee weken",
      "JUMP_TO_LAST_MONTH": "Vorige maand",
      "FILTER_SITE": "Site/afdeling",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "FILTER_DATA_SOURCE": "Gegevensbron",
      "FILTER_USER": "Persoon",
      "FILTER_BEHAVIOUR": "Gedrag",
      "BEHAVIOUR_COST_CONTROL": "Kostenbeheersing",
      "BEHAVIOUR_PAYROLL": "Loonlijst",
      "FILTER_SHIFT_TYPE": "Soort ploegendienst",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE_APPOINTMENT": "Site (afspraak)",
      "COLUMN_DEPT_APPOINTMENT": "Departement (afspraak)",
      "COLUMN_EVENT_TYPE": "Soort evenement",
      "COLUMN_SHIFT_TYPE": "Soort ploegendienst",
      "COLUMN_SITE_ATTRIBUTION": "Site (naamsvermelding)",
      "COLUMN_DEPT_ATTRIBUTION": "Departement (attributie)",
      "COLUMN_START_TIME": "Begin",
      "COLUMN_END_TIME": "Afmaken",
      "COLUMN_PAID_HOURS": "Betaalde uren",
      "COLUMN_UNPAID_HOURS": "Onbetaalde uren",
      "COLUMN_BASE_PAY": "Basissalaris",
      "COLUMN_ROLLED_UP_HOLIDAY": "Opgebouwde vakantiedagen",
      "COLUMN_TAXES": "Belastingen",
      "COLUMN_WAGE_UPLIFT": "Loonsverhoging",
      "COLUMN_TOTAL_COST": "Totale kosten",
      "INFO_NO_DATA": "Er zijn geen records die overeenkomen met je filters.",
      "INFO_NO_FILTER": "Selecteer hierboven een locatie/afdeling om aan de slag te gaan.",
      "PAY_AMOUNT_TYPE_HOURLY": "Uurtarief",
      "PAY_AMOUNT_TYPE_DAILY": "Dagtarief",
      "PAY_AMOUNT_TYPE_ANNUAL": "Jaarsalaris",
      "EVENT_TYPE_SHIFT": "Shift",
      "EVENT_TYPE_ABSENCE": "Afwezigheid",
      "EVENT_TYPE_SALARY": "Salaris",
      "EVENT_TYPE_TAXES": "Belastingen",
      "DATA_SOURCE_SHIFTS": "Ploegen",
      "DATA_SOURCE_ATTENDANCE": "Aanwezigheid",
      "DATA_SOURCE_MATCHED": "Ontsmet",
      "DATE_ERROR_DIFF": "Je kunt niet meer dan 14 weken kiezen. Beperk alstublieft uw datumbereik.",
      "DATE_WARNING_WEEKS": "Je hebt een datumbereik geselecteerd dat niet is afgestemd op kalenderweken. Sommige werkgeversbelastingen worden berekend op basis van wekelijkse drempels, dus de belastingen die hier worden weergegeven, kunnen kunstmatig lager zijn dan de werkelijkheid. Het basissalaris en de opgebouwde vakantiedagen blijven correct, maar pas uw datums aan op de kalenderweken voor de juiste belastingen en totale kosten.",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "ERROR_400": "Uw parameters zorgen ervoor dat er te veel gegevens worden gegenereerd voor één rapport. Verklein de reikwijdte van uw verzoek en probeer het opnieuw."
    },
    "LABOUR_BREAKDOWN_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Rapport genereren en e-mailen.",
      "REPORT_GENERATED": "Je rapport wordt gegenereerd en wordt naar je gemaild als het klaar is."
    },
    "PAYROLL_EXPORT": {
      "TITLE": "Export van loonadministratie",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_LAST_TWO_WEEKS": "Afgelopen twee weken",
      "JUMP_TO_LAST_MONTH": "Vorige maand",
      "FILTER_SITE": "Site/afdeling",
      "EXPORT": "Exporteren",
      "EXPORT_PAYROLL_SOFTWARE": "Software voor salarisadministratie",
      "EXPORT_PRINT": "Afdrukken",
      "FILTER_AGGREGATION": "Segmentatie",
      "FILTER_ABSENCE_TYPES": "Soorten afwezigheid",
      "FILTER_PAY_ELEMENT_TYPES": "Soorten betaalelementen",
      "FILTER_USER": "Persoon",
      "FILTER_COMPANY": "Bedrijf",
      "TOGGLE_SHOW_ROLLED_UP_HOLIDAY": "Opgerolde vakantie tonen",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "HR ID",
      "COLUMN_PAYROLL_ID": "Loonlijst-ID",
      "COLUMN_FIRST_NAME": "Voornaam",
      "COLUMN_LAST_NAME": "Achternaam",
      "COLUMN_SITE_ATTRIBUTION": "Site (naamsvermelding)",
      "COLUMN_DEPT_ATTRIBUTION": "Departement (attributie)",
      "COLUMN_SITE_APPOINTMENT": "Site (afspraak)",
      "COLUMN_DEPT_APPOINTMENT": "Departement (afspraak)",
      "COLUMN_GROUP": "groep",
      "COLUMN_POSITION": "Rol op het werk",
      "COLUMN_DAYS_WORKED": "Dagen gewerkt",
      "COLUMN_PAY_RATE": "Loontarief",
      "COLUMN_AMOUNT_TYPE": "Soort bedrag",
      "COLUMN_PAY_TYPE": "Soort betaling",
      "COLUMN_PAID_SHIFT_HOURS": "Betaalde ploegendienst",
      "COLUMN_UNPAID_SHIFT_HOURS": "Onbetaalde ploegendiensten",
      "COLUMN_BASE_PAY": "Basissalaris",
      "COLUMN_ROLLED_UP_HOLIDAY": "Opgerolde vakantie",
      "COLUMN_ABSENCE_DAYS": "{{label}} dagen",
      "COLUMN_ABSENCE_HOURS": "{{label}} uren",
      "INFO_NO_DATA": "Er zijn geen records die overeenkomen met je filters. Heb je je afgemeld? Vergeet niet dat dit rapport alleen uitgelogde gegevens gebruikt.",
      "INFO_NO_FILTER": "Selecteer hierboven een locatie/afdeling om aan de slag te gaan.",
      "PAY_AMOUNT_TYPE_HOURLY": "Uurtarief",
      "PAY_AMOUNT_TYPE_DAILY": "Dagtarief",
      "PAY_AMOUNT_TYPE_ANNUAL": "Jaarsalaris",
      "AGGREGATION_OPTION_ATTRIBUTION": "De locatie/afdeling waaraan de uren zijn toegewezen",
      "AGGREGATION_OPTION_APPOINTMENT": "De locatie/afdeling waar de persoon op dat moment gevestigd was",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "ABSENCE_TYPE_DELETED_SUFFIX": "Verwijderd",
      "DATE_ERROR_MIN": "Selecteer een datum na 12 december 2018.",
      "DATE_ERROR_DIFF": "Je kunt niet meer dan 6 maanden kiezen. Beperk uw datumbereik.",
      "EXPORT_MODAL": {
        "TITLE": "Exporteren naar software voor salarisadministratie",
        "LABEL_EXPORT_TO": "Exporteren naar",
        "BUTTON_DOWNLOAD": "Downloaden",
        "BUTTON_CLOSE": "Sluiten",
        "SUCCESS": "Je export is succesvol gedownload.",
        "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens."
      },
      "SIGN_OFF_STATUS_COMPLETE": "Volledig afgemeld",
      "SIGN_OFF_STATUS_NONE": "Niet afgemeld",
      "SIGN_OFF_STATUS_PARTIAL": "Gedeeltelijk afgetekend",
      "SIGN_OFF_STATUS_DRAWER": {
        "TITLE": "Status van uitloggen",
        "BACK_TO_DATES": "Terug naar de datums"
      },
      "FILTER_PAYROLL_CALENDAR": "Salariskalender",
      "FILTER_PAY_RUN": "Loonrun"
    },
    "ROTA_PROGRESS": {
      "TITLE": "Rota Progress",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_NEXT_WEEK": "Volgende week",
      "FILTER_SITE": "Site/afdeling",
      "LIFECYCLE_DRAFT": "Ontwerp",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Gemarkeerd voor goedkeuring",
      "LIFECYCLE_PUBLISHED": "Gepubliceerd",
      "INCLUDE_NO_DRAFT": "Sites zonder concept tonen",
      "NO_DRAFT": "Geen ontwerp",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "COLUMN_SITE": "Site/afdeling",
      "COLUMN_LIFECYCLE": "Status",
      "COLUMN_SCHEDULED_HOURS": "Geplande uren",
      "COLUMN_SCHEDULED_WAGES": "Geplande lonen",
      "COLUMN_FORECAST_SALES": "Verwachte verkopen",
      "COLUMN_WAGE_PERCENTAGE": "Loon%",
      "ROW_GRAND_TOTAL": "Totaal",
      "TOOLTIP_BUDGET": "Begroting",
      "INFO_NO_DATA": "Er zijn geen sites/afdelingen die overeenkomen met je filters.",
      "INFO_NO_FILTER": "Selecteer hierboven een locatie/afdeling om aan de slag te gaan.",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens."
    },
    "SIGN_OFF_PROGRESS": {
      "TITLE": "Voortgang van de afmelding",
      "JUMP_TO": "Spring naar",
      "JUMP_TO_LAST_WEEK": "Vorige week",
      "JUMP_TO_THIS_WEEK": "Deze week",
      "JUMP_TO_NEXT_WEEK": "Volgende week",
      "FILTER_SITE": "Site/afdeling",
      "FILTER_STATUS": "Status van afmelding",
      "STATUS_NO_ROTA": "Geen rooster",
      "STATUS_INCOMPLETE": "Onvolledig",
      "STATUS_REPORTING_DATE": "Datum van rapportage",
      "STATUS_COMPLETE": "Compleet",
      "STATUS_TOOLTIP_NO_ROTA": "Er is deze week geen rooster gepubliceerd.",
      "STATUS_TOOLTIP_INCOMPLETE": "De afmelding is deze week niet voor elke dag voltooid.",
      "STATUS_TOOLTIP_REPORTING_DATE": "De afmelding is voltooid voor de dagen tot en met uw rapportagedatum.",
      "STATUS_TOOLTIP_COMPLETE": "De afmelding is deze week voor elke dag voltooid.",
      "TOGGLE_COMPARAND": "Comparand",
      "TOGGLE_COMPARAND_FORECAST": "Voorspelling",
      "TOGGLE_COMPARAND_BUDGET": "Begroting",
      "EXPORT": "Exporteren",
      "EXPORT_PRINT": "Afdrukken",
      "COLUMN_WAGES": "Lonen",
      "COLUMN_SALES": "Verkoop",
      "COLUMN_WAGE_PERCENTAGE": "Loon%",
      "COLUMN_HOURS": "Uren",
      "COLUMN_SITE": "Site/afdeling",
      "COLUMN_STATUS": "Status",
      "COLUMN_FORECAST": "Voorspelling",
      "COLUMN_BUDGET": "Begroting",
      "COLUMN_ACTUAL": "Actueel",
      "ROW_GRAND_TOTAL": "Totaal",
      "INFO_NO_DATA": "Er zijn geen sites/afdelingen die overeenkomen met je filters.",
      "INFO_NO_FILTER": "Selecteer hierboven een locatie/afdeling om aan de slag te gaan.",
      "ERROR_500": "We konden je rapport op dat moment niet laden. Probeer het nog eens.",
      "STATUS_MODAL": {
        "REVERSE_CONFIRM": "Weet je zeker dat je deze afmelding wilt omkeren?\n\nHiermee worden de diensten en aanwezigheidsgegevens op deze datum (s) ontgrendeld, zodat er wijzigingen kunnen worden aangebracht.",
        "REVERSE_SUCCESS": "De afmelding is succesvol teruggedraaid.",
        "REVERSE_ERROR": "We konden de afmelding op dat moment niet ongedaan maken. Probeer het nog eens.",
        "TITLE": "Status van afmelding",
        "COLUMN_DATE": "Datum",
        "COLUMN_STATUS": "Status",
        "COLUMN_OPTIONS": "Opties",
        "STATUS_NO_SIGN_OFF": "Geen afmelding",
        "STATUS_SIGN_OFF": "Afgetekend",
        "BUTTON_REVERSE_DAY": "Omgekeerd",
        "BUTTON_REVERSE_WEEK": "Hele week omkeren",
        "BUTTON_CLOSE": "Sluiten"
      }
    },
    "TRONC_STATUS": {
      "TITLE": "Tronc-status",
      "LIFECYCLE_DRAFT": "Ontwerp",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Gemarkeerd voor goedkeuring",
      "LIFECYCLE_LOCKED": "Vergrendeld",
      "INCLUDE_NO_HEADER": "Sites zonder concept tonen",
      "NO_HEADER": "Geen ontwerp",
      "COLUMN_SITE": "Site/afdeling",
      "COLUMN_LIFECYCLE": "Status",
      "COLUMN_RECEIVED": "Totaal ontvangen",
      "COLUMN_PAID_OUT": "Totaal uitbetaald",
      "COLUMN_EDITED_WHO": "Bewerkt door",
      "COLUMN_EDITED_WHEN": "Wanneer",
      "INFO_NO_DATA": "Er zijn geen sites/afdelingen van Tronc die overeenkomen met uw filters.",
      "ERROR_500": "We konden Tronc op dat moment niet laden. Probeer het opnieuw."
    },
    "CURRENTLY_CLOCKED_IN": {
      "TITLE": "Mensen die momenteel zijn ingeklokt",
      "NAME": "Naam",
      "SITE": "Site/afdeling",
      "POSITION": "Rol op het werk",
      "CLOCK_IN_TIME": "Inchecktijd",
      "NOBODY_CLOCKED_IN": "Er is op dit moment niemand ingecheckt!",
      "ERROR_500": "We konden het rapport op dat moment niet laden. Probeer het opnieuw."
    },
    "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Rapport genereren en per e-mail versturen.",
      "REPORT_GENERATED": "Uw rapport wordt gegenereerd en wordt naar u gemaild wanneer het gereed is.",
      "FILTER_DATE": "Datum",
      "FILTER_ENTITY": "Locatie/afdeling",
      "FILTER_PAY_TYPES": "Betaaltypes",
      "FILTER_DATA_SOURCE": "Gegevensbron",
      "FILTER_USER": "Persoon",
      "FILTER_EVENT_TYPES": "Gebeurtenistypes",
      "FILTER_BEHAVIOUR": "Gedrag",
      "FILTER_SHIFT_TYPE": "Diensttype",
    },
    "PAYROLL_EXPORT_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Rapport genereren en per e-mail versturen.",
      "REPORT_GENERATED": "Uw rapport wordt gegenereerd en wordt naar u gemaild wanneer het gereed is.",
      "FILTER_PAYROLL_CALENDAR": "Loonkalender",
      "FILTER_PAY_RUN": "Loonronde",
      "FILTER_DATE": "Datum",
      "FILTER_ENTITY": "Locatie/afdeling",
      "FILTER_AGGREGATION_OPTIONS": "Segmentatie",
      "FILTER_USER": "Persoon",
      "FILTER_ABSENCE_TYPES": "Afwezigheidstypes",
      "FILTER_PAY_ELEMENT_TYPES": "Betalingselementtypes",
      "FILTER_PAY_AMOUNT_TYPE": "Bedragstype",
      "FILTER_ROLLED_UP_HOLIDAY": "Opgenomen vakantie",
      "FILTER_COMPANY": "Bedrijf",
    },
  },
  "ROTA_MANAGEMENT": {
    "DASHBOARD": {
      "INDEX": {
        "TITLE": "Dashboard",
        "CHECKLIST": "Checklist voor het Rota",
        "LABOUR_SPEND": "Uitgaven voor arbeid"
      },
      "LABOUR_SPEND_TAB": {
        "TITLE": "Overzicht van de arbeid",
        "SHOW_ACTUAL_TIP": "Projecteer voor de dagen van deze week die nog niet zijn verstreken de verwachte verkopen en de geplande lonen/uren in de werkelijke totalen",
        "SHOW_ACTUAL_TEXT": "Prognose van het project in de praktijk",
        "FORECAST": "Voorspelling",
        "ACTUAL": "Actueel",
        "BUDGET": "Begroting",
        "SALES": "Verkoop",
        "WAGES": "Lonen",
        "HOURS": "Uren",
        "WAGE_PERCENT": "Loon%",
        "LABOUR_COMP": "Arbeidsvergelijking",
        "ACTUAL_PROJECTED": "Actueel (geprojecteerd)",
        "TODAYS_VS_SCHEDULED": "Aanwezigheid van vandaag versus gepland"
      },
      "WTD_VIOLATIONS_MODAL": {
        "TITLE": "Overtredingen van de werktijden",
        "BODY": "Dit zijn de gevallen waarin we hebben vastgesteld dat uw roosters in strijd zijn met de arbeidstijdvoorschriften.",
        "TO": "naar ",
        "ALL": "Alle overtredingen",
        "TOO_MANY": "Te veel werkuren (gemiddeld)",
        "INSUF_DAYS": "Onvoldoende vrije dagen",
        "INSUF_REST": "Onvoldoende rust tussen ploegen",
        "UNDER_18_DAYS": "Onder de 18 jaar: onvoldoende vrije dagen",
        "UNDER_18_LONG": "Onder de 18 jaar: te lang schakelen",
        "UNDER_18_REST": "Onder de 18 jaar: onvoldoende rust tussen de ploegen",
        "STAFF_MEMBER": "Personeelslid",
        "VIOLATION": "Overtreding",
        "VALUE": "Waarde",
        "NO_VIOLATIONS": "Je hebt geen arbeidstijdovertredingen, topbaan!",
        "NO_FILTER_VIOLATIONS": "Er zijn geen overtredingen die overeenkomen met je filter (s).",
        "AVE_48_OVER_17": "Mag het gemiddelde van 48 werkuren gedurende 17 weken niet overschrijden",
        "LESS_2_DAYS_2_WEEKS": "Mag niet minder dan 2 dagen vrij zijn in een periode van 2 weken",
        "UNDER_18_2_PER_WEEK": "Jonger dan 18 jaar: moet 2 opeenvolgende vrije dagen per week hebben",
        "UNDER_18_LONGER_8": "Onder de 18 jaar: ploegendienst langer dan 8 uur",
        "UNDER_18_LESS_12_REST": "Onder de 18 jaar: minder dan 12 uur rust na een werkdag",
        "LESS_11_WEEK": "Minder dan 11 uur rust na een werkdag",
        "WORKING_HOURS": " werktijden",
        "WORKING_DAYS": " werkdagen",
        "HOURS_REST": " uren rust",
        "RECALC": "Opnieuw berekenen",
        "LOADING": "Aan het laden...",
        "CLOSE": "Sluiten"
      }
    },
    "LOCATIONS": {
      "ADD_LOCATION_MODAL": {
        "NEW_SHIFT_LOCATION": "Nieuwe aanwezigheidszone",
        "TYPE": "Typ",
        "NEW_LOCATION": "Nieuwe zone",
        "CHANGE_EXISTING_LOCATION": "Bestaande zone wijzigen",
        "EXISTING_LOCATION": "Bestaande zone",
        "NAME": "Naam",
        "DETAILS": "Details",
        "HELP": "Rotaready onthoudt de precieze positie van de markering op de kaart, dus deze gegevens dienen enkel ter referentie. Voeg gerust enkele notities of een volledig adres toe.",
        "SAVING": "Opslaan...",
        "SAVE": "Opslaan",
        "CANCEL": "Annuleer",
        "ERROR_ENTER_NAME": "Voer een naam in voor deze zone.",
        "ERROR_UPDATE": "Momenteel kan deze zone niet worden bijgewerkt. Probeer het nog eens.",
        "ERROR_ADD": "Momenteel kan deze zone niet worden toegevoegd. Probeer het nog eens."
      },
      "EDIT_LOCATION_MODAL": {
        "EDIT_LOCATION": "Zone bewerken",
        "HIERARCHY": "Hiërarchie",
        "MASTER_LOCATION": "Hoofdzone",
        "SUB_LOCATION": "Subzone",
        "HELP_HIERARCHY": "Met Rotaready kun je een hiërarchie van zones creëren. Een subzone kan onder elke andere zone worden geplaatst.",
        "PARENT_LOCATION": "Ouderzone",
        "NAME": "Naam",
        "DETAILS": "Details",
        "HELP_DETAILS": "Rotaready onthoudt de precieze positie van de markering op de kaart, dus deze gegevens dienen enkel ter referentie. Voeg gerust enkele notities of een volledig adres toe.",
        "ATTENDANCE": "Aanwezigheid",
        "ALLOW_CLOCKING": "Laat medewerkers hier in- en uitchecken",
        "STAFF_WORKING": "Alleen als het personeel werkt in een ploegendienst die met deze zone is gemarkeerd",
        "HELP_CLOCKING": "Deze instellingen zijn van invloed op de vraag of medewerkers kunnen in- en uitklokken met de mobiele Rotaready-app.",
        "ATTENDANCE_SENSITIVITY": "Aanwezigheidsgevoeligheid",
        "METRES": "meters",
        "HELP_CLOSE_LOCATION": "Dit bepaalt hoe dicht iemand zich bij de zone moet bevinden voordat de mobiele app van Rotaready het mogelijk maakt om in of uit te checken. Minder dan 50 meter wordt niet aanbevolen omdat mensen met een zwak signaal niet kunnen inchecken.",
        "MIN_OCCUPANCY": "Minimale bezetting",
        "HELP_MIN_OCCUPANCY": "Wanneer iemand in ploegendienst is, zal Rotaready je waarschuwen als het totale aantal mensen dat ook in ploegendienst werkt onder dit aantal daalt.",
        "MAX_OCCUPANCY": "Maximale bezetting",
        "HELP_MAX_OCCUPANCY": "Indien beschikbaar, staat Rotaready u niet toe om hier (op enig moment) meer personeel in te plannen dan de maximale bezetting.",
        "TIP_OPENING": "Specificeer de openingstijden zodat Rotaready voorkomt dat u in deze zone ploegendiensten inplant wanneer deze gesloten is.",
        "TIP_SUB_LOC": "Aangezien dit een subzone is, wordt deze subzone ook als gesloten beschouwd als de bovenliggende zone op enig moment gesloten is.",
        "REGULAR_OPENING_TIMES": "Reguliere openingstijden",
        "OPEN_247": "Deze zone is 24/7 geopend.",
        "TO": "naar",
        "SPECIAL_PERIODS": "Speciale periodes",
        "NO_SPECIAL_PERIODS": "Er zijn geen speciale periodes.",
        "OPEN": "Open",
        "CLOSED": "Gesloten",
        "ADD_SPECIAL_PERIOD": "Speciale periode toevoegen",
        "REASON": "Reden",
        "REASON_PLACEHOLDER": "bijv. Renovatie in de zomer",
        "FROM": "Van",
        "TO_CAPS": "Naar",
        "ADD": "Toevoegen",
        "DELETE": "Verwijderen",
        "SAVING": "Opslaan...",
        "SAVE": "Opslaan",
        "CANCEL": "Annuleer",
        "ALERT_NAME": "Zorg ervoor dat je een geldige naam hebt opgegeven voor de aanwezigheidszone.",
        "ALERT_PARENT": "Als dit een subzone is, moet u een bovenliggende zone selecteren.",
        "ALERT_VALID_PARENT": "Selecteer een geldige ouderzone.",
        "ALERT_PARENT_LOCATION": "Deze aanwezigheidszone en de door u gekozen ouderzone moeten beide tot dezelfde site behoren (of beide organisatiebreed zijn).",
        "ALERT_MINIMUM": "Je mag geen minimale bezettingsgraad hebben die hoger is dan de maximale bezettingsgraad.",
        "ERROR_UPDATE": "We konden de zone op dat moment niet updaten. Probeer het opnieuw.",
        "ALERT_SUB_CONFIRM": "Deze zone heeft nog steeds subzones. Ze worden ook verwijderd.\n\nWeet je zeker dat je door wilt gaan?",
        "ALERT_CONFIRM": "Weet je zeker dat je deze zone wilt verwijderen?",
        "ERROR_DELETE": "We konden de zone op dat moment niet verwijderen. Probeer het opnieuw!"
      },
      "INDEX": {
        "SHIFT_LOCATIONS": "Aanwezigheidszones",
        "TIP_FIND": "Als u een nieuwe zone wilt toevoegen of een bestaande zone wilt wijzigen, moet u deze eerst bij de kaart vinden. Zoek naar een adres of postcode en sleep vervolgens de groene markering om deze te verplaatsen.",
        "PLACEHOLDER_SEARCH": "Zoek om een nieuwe zone toe te voegen",
        "HELP_NO_LOCATIONS": "Je hebt geen bestaande zones. Zoek op de kaart om er een toe te voegen.",
        "HELP_NO_RESULTS": "Er zijn geen aanwezigheidszones die overeenkomen met je zoektermen.",
        "TOOLTIP_SHOW": "Op de kaart weergeven",
        "TOOLTIP_CLOCKING_ALWAYS": "Medewerkers kunnen hier altijd in- en uitchecken met de mobiele Rotaready-app",
        "TOOLTIP_CLOCKING_WORKING": "Medewerkers kunnen hier met de mobiele Rotaready-app in- en uitchecken, alleen als ze hier in ploegendienst werken",
        "ERROR_LOAD": "We konden uw aanwezigheidszones niet laden. Probeer het opnieuw.",
        "NO_ADDRESS": "Geen gegevens/adres",
        "ERROR_LOOKUP": "We hebben de locatie van die marker niet kunnen opzoeken.",
        "UNKNOWN_ADDRESS": "Onbekend adres",
        "ADD_AS_NEW": "Als nieuwe zone toevoegen",
        "DRAG_TO_CHANGE": "Sleep me om de locatie te wijzigen",
        "FILTER_LOCATIONS": "Zones filteren..."
      }
    },
    "SHIFT_TYPES": {
      "EDIT_SHIFT_TYPES_MODAL": {
        "TITLE_NEW": "Nieuw ploegendiensttype",
        "TITLE_EDIT": "Shift-type bewerken",
        "SITE_LABEL": "Site",
        "HIERARCHY_LABEL": "Hiërarchie",
        "MASTER_TYPE_LABEL": "Type mastershift",
        "SUB_TYPE_LABEL": "Type subshift",
        "SUB_TYPE_HELP": "Met Rotaready kunt u een hiërarchie van ploegendiensten creëren. Een subploegendiensttype kan onder elk ander ploegtype worden geplaatst.",
        "PARENT_TYPE_LABEL": "Soort ouderdienst",
        "NAME_LABEL": "Naam",
        "COLOUR_LABEL": "Kleur",
        "COST_STREAM_MAPPINGS_LABEL": "Streamtoewijzingen",
        "COST_STREAM_TABLE": {
          "COST_STREAM_HEADING": "Streamen",
          "ATTRIBUTED_HEADING": "toegeschreven",
          "EMPTY_LABEL": "Dit is niet gekoppeld aan streams.",
          "ADD_BUTTON": "Nieuwe toevoegen"
        },
        "DELETE_BUTTON": "Verwijderen",
        "SAVE_BUTTON": "Opslaan",
        "CANCEL_BUTTON": "Annuleer",
        "VALIDATION": {
          "NAME_REQUIRED": "Zorg ervoor dat je een geldige naam hebt opgegeven voor het ploegtype.",
          "PARENT_REQUIRED": "Om dit een subploegendienst te laten zijn, moet u een ploegendiensttype als ouder selecteren.",
          "PARENT_VALID": "Selecteer een geldig type ouderdienst.",
          "PARENT_SAME_SITE": "Dit ploegendiensttype en het door u gekozen type ouderdienst moeten beide tot dezelfde site behoren (of beide organisatiebreed zijn).",
          "ATTRIBUTION_OVERAGE": "We hebben het percentage dat aan elke stream wordt toegekend bij elkaar opgeteld en het totaal is meer dan 100%. Je kunt maximaal 100% toekennen aan een of meer streams.",
          "ATTRIBUTION_VALID": "Je hebt een ongeldig 'percentage attributed' ingevoerd voor een of meer streams. Met de waarde '50' wordt bijvoorbeeld 50% van de loonkosten van iedereen die in deze ploegendienst werkt, aan de corresponderende stream toegerekend.",
          "ATTRIBUTION_NONE": "Je moet het shifttype aan minstens één stream toekennen.",
          "DUPLICATE_COST_STREAM_MAPPING": "Er bestaat al een mapping voor deze stream! Je kunt niet twee keer een shifttype aan dezelfde stream toekennen."
        },
        "ERRORS": {
          "ADD_ERROR": "We konden op dat moment je ploegendienst niet toevoegen. Waarschijnlijk bestaat er al een ploegendiensttype met dezelfde naam! Probeer een andere naam te kiezen.",
          "UPDATE_ERROR": "We konden het ploegentype op dat moment niet updaten. Waarschijnlijk bestaat er al een ploegendiensttype met dezelfde naam! Probeer een andere naam te kiezen.",
          "DELETE_TYPE": "We konden het ploegentype op dat moment niet verwijderen. Probeer het opnieuw!"
        },
        "CONFIRM": {
          "DELETE_SUB_TYPES": "Dit ploegentype heeft nog steeds subploegentypes. Ze worden ook verwijderd. Weet je zeker dat je door wilt gaan?",
          "DELETE_TYPE": "Weet je zeker dat je dit ploegentype wilt verwijderen?"
        }
      },
      "INDEX": {
        "TITLE": "Soorten ploegendiensten",
        "SUBTITLE": "Beschouw ploegendiensten als categorieën voor de verschillende soorten werk die mensen in uw organisatie doen:",
        "SUB_TYPE_HELP": "Je kunt een 'subploegendiensttype' toevoegen door op het plus-icoontje naast een willekeurig ploegendiensttype te klikken. Dit is handig als u een ploegendienst wilt onderverdelen in meer specifieke taken of rollen.",
        "TYPES_EMPTY": "Er zijn geen ploegensoorten om weer te geven.",
        "ONLY_ENTITY": "alleen",
        "WHOLE_ORGANISATION": "Organisatiebreed",
        "SHOW_MORE": "Meer weergeven...",
        "ERRORS": {
          "LOAD_ERROR": "Oeps, we konden de ploegentypes toen niet laden. Probeer het opnieuw!"
        },
        "TOOLTIPS": {
          "ADD_SUB_SHIFT_TYPE": "Subshifttype toevoegen"
        },
        "ADD_SHIFT_TYPE": "Shift-type toevoegen"
      }
    },
    "TEMPLATES": {
      "EDIT_CONSTRAINTS_MODAL": {
        "ADD_TITLE": "Nieuwe {{currentLevelName}} toevoegen",
        "EDIT_TITLE": "Beperkingen van {{currentLevelName}} bewerken",
        "NAME_LABEL": "naam",
        "LOADING": {
          "SAVE": "Opslaan..."
        },
        "SAVE_AND_CLOSE_BUTTON": "Opslaan en sluiten",
        "CANCEL_BUTTON": "Annuleer",
        "OVERRIDE_CONSTRAINT_BUTTON": "De beperking van {{levelName}} overschrijven",
        "DELETE_OVERRIDE_BUTTON": "Overschrijving verwijderen",
        "TAB_HEADINGS": {
          "POPULAR": "Populair",
          "STAFF": "Personeel",
          "REST": "Rust",
          "OTHERS": "Andere"
        },
        "STAFF_SELECTION": {
          "TITLE": "Selectie van personeel",
          "FLEXIBLE_STAFFING_LABEL": "Flexibele personeelsbezetting",
          "FAIRLY_HINT": "Kies eerlijk uit al het personeel",
          "FAIRLY_ANY_TAGS_HINT": "Kies eerlijk uit degenen <strong>die voldoen aan een</strong> van de onderstaande tags",
          "FAIRLY_ALL_TAGS_HINT": "Kies eerlijk uit degenen die aan <strong>alle</strong> onderstaande tags voldoen",
          "POOLING_HINT": "Behandel de onderstaande tags als groepen medewerkers en put ze allemaal <strong>in volgorde</strong> uit",
          "FIXED_STAFFING_LABEL": "Vast personeel",
          "SPECIFIC_STAFF_MEMBER_HINT": "Wijs de shift (en) toe aan een specifiek personeelslid",
          "ASSIGNEE_LABEL": "Geadresseerde",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Typ om tags toe te voegen"
          }
        },
        "PREFERRED_STAFF_SELECTION": {
          "TITLE": "Selectie van voorkeurspersoneel",
          "HINT": "Als meerdere medewerkers in aanmerking komen voor een bepaalde shift, zal Rotaready proberen de selectie te beïnvloeden op basis van de gekozen criteria:",
          "NO_PREFERENCE": "Geef geen voorkeur aan personeel boven anderen, behalve op grond van eerlijkheid",
          "PREFER_ANY_TAG": "Geef de voorkeur aan degenen <strong>die overeenkomen met een</strong> van de onderstaande tags",
          "PREFER_ALL_TAG": "Geef de voorkeur aan degenen die overeenkomen met <strong>alle</strong> onderstaande tags",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Typ om tags toe te voegen"
          }
        },
        "STAFF_SELECTION_GROUPS": {
          "TITLE": "Personeelsselectie (groepen)",
          "HINT": "Op deze manier kunt u de selectie van personeel beperken tot personen die lid zijn van een van de onderstaande groepen. De groepen krijgen middelen toegewezen in de volgorde waarin ze zijn gespecificeerd:",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Typ om tags toe te voegen"
          }
        },
        "STAFF_TYPECASTING": {
          "TITLE": "Typecasting van personeel",
          "HINT": "<em>Wanneer we iemand aan een <em>getypte</em> ploeg toewijzen, kunnen ze die dag alleen nog andere ploegen toegewezen krijgen die a) ook met ten minste één van deze tags zijn <em>getypecast</em>, of b) helemaal niet zijn getypecast:</em>",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Typ om tags toe te voegen"
          }
        },
        "OUTSOURCING": {
          "TITLE": "Uitbesteding",
          "HINT": "Als er geen intern personeel beschikbaar is, sta dan uitbesteding toe aan de volgende organisaties, in de volgorde waarin ze zijn gespecificeerd:",
          "PLACEHOLDERS": {
            "TYPE_OUTSOURCE_ORGANISATIONS": "Typ om outsourcingorganisaties toe te voegen"
          }
        },
        "SELECTION_CONSISTENCY": {
          "TITLE": "Consistentie van selectie",
          "HINT": "Voor ploegendiensten die meerdere dagen in een bepaalde week beslaan, moet iemand die wordt toegewezen aan de eerste ploegendienst:",
          "NO_EFFECT": "hebben geen invloed op het feit of ze worden geselecteerd voor andere dagdiensten",
          "ALLOCATE": "indien mogelijk worden toegewezen aan alle andere dagdiensten in de ploegendienst"
        },
        "REST_PERIOD": {
          "TITLE": "Rustperiode",
          "HINT": "Zorg ervoor dat een personeelslid na een ploegendienst uitgerust is (en dus geen nieuwe shift krijgt) gedurende ten minste:"
        },
        "LOCATION_TOLERANCE": {
          "TITLE": "Locatietolerantie",
          "HINT": "Het is vaak belangrijk dat twee opeenvolgende ploegen met dezelfde gespecificeerde locatie aan hetzelfde personeelslid worden toegewezen. Specificeer het maximum aantal minuten tussen twee van dergelijke diensten (einde van de eerste tot het begin van de tweede) zodat we ze in dit opzicht als opeenvolgend kunnen beschouwen:",
          "MINUTES": "minuten",
          "PLACEHOLDERS": {
            "MINUTES": "Minuten tussen het einde van de ene shift en het begin van een andere"
          }
        },
        "DAILY_WINDOW_DEFINITION": {
          "TITLE": "Definitie van een dagelijks venster",
          "HINT": "In sommige organisaties draait hun personeel meerdere 'ploegen' op een bepaalde dag, en voor elke persoon moet deze dagelijkse ploegendienst binnen een bepaald tijdsbestek liggen. Deze tijdvensters kunnen variëren en u kunt hier alle mogelijke variaties definiëren:",
          "LIMITED_TIMES": "We hebben een beperkt aantal vaste start-/eindtijden voor onze ramen",
          "REGULAR_INTERVALS": "Onze vensters vallen met regelmatige tussenpozen na een bepaalde starttijd"
        },
        "TIMES": {
          "WINDOW_TIMES_TITLE": "Venstertijden",
          "SPLIT_SHIFT_WINDOW_TIMES": "Venstertijden in split-shift",
          "WINDOW_START_TIME": "Starttijd van het vroegste venster",
          "WINDOW_INTERVAL": "Interval tussen geldige vensters",
          "WINDOW_FINISH_TIME": "Eindtijd van het laatst mogelijke venster",
          "PLACEHOLDERS": {
            "COMMA_SEPERATED_TIMES": "Door komma's gescheiden lijst van tijden, dwz 9:30-17:30,13:00-20:00"
          }
        },
        "SHIFT_PAY_RATE": {
          "TITLE": "Verschuivingstarief",
          "HINT": "Niet-bezoldigde medewerkers die in ploegendienst werken die op basis van deze {{currentLevelName}} worden gegenereerd, verdienen hun gebruikelijke uurtarief. Je kunt dit hieronder indien nodig overschrijven:",
          "OPTIONS": {
            "NORMAL": "Normaal: medewerkers verdienen volgens hun gebruikelijke uurtarief",
            "ZERO": "Nul: personeel wordt niet betaald voor deze shift",
            "MULTIPLE": "Meervoudig: medewerkers verdienen een veelvoud van hun gebruikelijke uurtarief",
            "FIXED": "Vast: hun gebruikelijke uurtarief overschrijven met een vast tarief"
          },
          "MULTIPLIER": "Snelheidsmultiplier",
          "HOURLY_RATE_OVERRIDE": "Overschrijding van het uurtarief",
          "PLACEHOLDERS": {
            "MULTIPLIER": "Vermenigvuldiger van het uurtarief",
            "HOURLY_RATE_OVERRIDE": "Uurtarief in lokale valuta, d.w.z. 8,50"
          }
        },
        "SHIFT_TYPE": {
          "TITLE": "Soort ploegendienst",
          "HINT": "Dit geeft het 'soort' verschuivingen aan dat gegenereerd zal worden op basis van deze {{currentLevelName}}. Je medewerkers zien dit als de titel van de shift en als je de kostenbeheersingsfuncties van Rotaready gebruikt, kan elk ploegtype worden toegewezen aan een of meer kostenstromen."
        },
        "SITE_DEPARTMENT": {
          "TITLE": "Site/afdeling",
          "HINT": "Attribuutverschuivingen die worden gegenereerd op basis van deze {{currentLevelName}} naar een andere site/afdeling. Dit is handig om dekkingsploegen te genereren.",
          "ATTRIBUTE_TO_SITE": "Ken de loonkosten en uren toe aan de gekozen locatie"
        }
      },
      "EDIT_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Shift-set toevoegen",
        "ADD_SUBTITLE": "Geef informatie over deze ploegenset",
        "EDIT_TITLE": "Shift-set bewerken",
        "EDIT_SUBTITLE": "Details over deze ploegenset wijzigen",
        "TIP": "De geautomatiseerde planner van Rotaready genereert op basis van deze ploegendienst en wijst het personeel dienovereenkomstig toe, op een eerlijke en voordelige manier.",
        "TIME_LABEL": "Start-/eindtijd",
        "TO": "naar",
        "OVERFLOW_WARNING": "Deze verschuiving loopt over naar de volgende dag.",
        "LOCATION_LABEL": "Aanwezigheidszone",
        "MINIMUM_LABEL": "Tenminste",
        "MINIMUM_EXPLANATION": "Ik wil een minimum aantal medewerkers per dag, maar meer indien mogelijk",
        "STAFF_NUMBER_LABEL": "Precies",
        "STAFF_NUMBER_EXPLANATION": "Ik wil een exact aantal medewerkers per dag",
        "MAXIMUM_LABEL": "Tot",
        "MAXIMUM_EXPLANATION": "Ik wil zoveel mogelijk personeel per dag, tot een maximum",
        "SHORT_DAYS": {
          "MONDAY": "Ma",
          "TUESDAY": "Tue",
          "WEDNESDAY": "trouwen",
          "THURSDAY": "Don",
          "FRIDAY": "vr",
          "SATURDAY": "Zat",
          "SUNDAY": "Zon"
        },
        "SAVE_BUTTON": "Shift-set opslaan",
        "CANCEL_BUTTON": "Annuleer",
        "LOADING": {
          "SAVE": "Opslaan..."
        },
        "ERRORS": {
          "UNABLE_TO_ADD": "Momenteel niet in staat om deze ploegenset toe te voegen. Probeer het nog eens."
        }
      },
      "EDIT_TEMPLATE": {
        "LOADING": {
          "SAVE": "Opslaan..."
        },
        "EDIT_CONSTRAINTS": "Beperkingen bewerken",
        "CONSTRAINTS_LABEL": "Sjabloonbeperkingen",
        "CONSTRAINTS_TEXT": "Stel beperkingen in die van toepassing zijn op de hele sjabloon.",
        "PLACEHOLDERS": {
          "SEARCH_FILTER": "Filteren..."
        },
        "ADD_GROUP_BUTTON": "Groep toevoegen",
        "TAB_HEADINGS": {
          "FLEXIBLE_STAFFING": "Flexibele personeelsbezetting",
          "FIXED_STAFFING": "Vast personeel"
        },
        "TOOLTIPS": {
          "ACTIVE_CONSTRAINTS": "Actieve beperkingen",
          "DISABLE": "Uitschakelen",
          "ENABLE": "Inschakelen"
        },
        "GROUP_OPTIONS_BUTTON": {
          "LABEL": "Opties voor groepen",
          "ADD_TASK": "Taak toevoegen",
          "ADD_SHIFT_SET": "Shift-set toevoegen",
          "EDIT_CONSTRAINTS": "Beperkingen bewerken",
          "DELETE_GROUP": "Groep verwijderen"
        },
        "HOURS": "uren",
        "SHORT_DAYS": {
          "MONDAY": "Ma",
          "TUESDAY": "Tue",
          "WEDNESDAY": "trouwen",
          "THURSDAY": "Don",
          "FRIDAY": "vr",
          "SATURDAY": "Zat",
          "SUNDAY": "Zon"
        },
        "CHAR_DAYS": {
          "MONDAY": "M",
          "TUESDAY": "T",
          "WEDNESDAY": "W",
          "THURSDAY": "T",
          "FRIDAY": "F",
          "SATURDAY": "S",
          "SUNDAY": "S"
        },
        "NO_SHIFT_SETS": "Er zijn geen ploegensets in deze groep. Voeg er een toe via de knop 'Groepsopties'.",
        "OPTIONS_BUTTON": {
          "LABEL": "Opties",
          "ADD_SHIFT_SET": "Shift-set toevoegen",
          "EDIT_CONSTRAINTS": "Beperkingen bewerken",
          "DELETE_TASK": "Taak verwijderen"
        },
        "NO_FLEXIBLE_STAFF_GROUPS_THIS_WEEK": "Je hebt geen flexibele personeelsgroepen. Waarom voeg je er niet een toe door hierboven op 'Groep toevoegen' te klikken?",
        "NO_FLEXIBLE_STAFF_GROUPS": "Er zijn geen flexibele personeelsgroepen die passen bij wat u zoekt.",
        "GROUP_SHIFT_SETS_TABLE": {
          "HEADINGS": {
            "SHIFT_TYPE": "Soort ploegendienst",
            "TIME": "Tijd",
            "DAYS_OF_WEEK": "Dagen van de week"
          }
        },
        "NO_FIXED_STAFF_GROUPS_THIS_WEEK": "Je hebt geen vaste personeelsgroepen. Waarom voeg je er niet een toe door hierboven op 'Groep toevoegen' te klikken?",
        "NO_FIXED_STAFF_GROUPS": "Er zijn geen vaste personeelsgroepen die overeenkomen met wat je zoekt.",
        "EDIT_BUTTON": "Bewerken",
        "TOGGLE_DROPDOWN_BUTTON": "Uitklapmenu in- en uitschakelen",
        "DELETE_BUTTON": "Verwijderen",
        "SAVE_BUTTON": "Opslaan",
        "ERRORS": {
          "COULDNT_FIND_TEMPLATE": "We konden het sjabloon dat je zocht niet vinden. Probeer het nog eens.",
          "OOPS": "Oeps, er is gewoon iets misgegaan. Misschien moet je het opnieuw proberen!",
          "TEMPLATE_STATE_CHANGE": "We konden een deel van je sjabloon op dit moment niet in- of uitschakelen. Probeer het nog eens."
        },
        "LEVEL_NAMES": {
          "GLOBAL": "globaal",
          "TEMPLATE": "sjabloon",
          "WEEK": "week",
          "GROUP": "groeperen",
          "TASK": "taak",
          "SHIFT_SET": "ploegenset"
        },
        "CONFIRM": {
          "DELETE": "Weet je zeker dat je dit wilt verwijderen?",
          "DELETE_SHIFT_SET": "Weet je zeker dat je deze ploegenset wilt verwijderen?"
        }
      },
      "FIXED_STAFFING_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Shift-set toevoegen",
        "ADD_SUBTITLE": "Geef informatie over deze ploegenset",
        "EDIT_TITLE": "Shift-set bewerken",
        "EDIT_SUBTITLE": "Details over deze ploegenset wijzigen",
        "START_END_LABEL": "Start-/eindtijd",
        "SHIFT_OVERFLOW_WARNING": "Deze verschuiving loopt over naar de volgende dag.",
        "SHIFT_TYPE_LABEL": "Soort ploegendienst",
        "LOCATION_LABEL": "Aanwezigheidszone",
        "SHORT_DAYS": {
          "MONDAY": "Ma",
          "TUESDAY": "Tue",
          "WEDNESDAY": "trouwen",
          "THURSDAY": "Don",
          "FRIDAY": "vr",
          "SATURDAY": "Zat",
          "SUNDAY": "Zon"
        },
        "SAVE_BUTTON": "Shift-set opslaan",
        "CANCEL_BUTTON": "Annuleer",
        "LOADING": {
          "SAVE": "Opslaan..."
        },
        "UNKNOWN_SHIFT_TYPE": "Onbekend ploegtype",
        "ERRORS": {
          "UNABLE_ADD": "Oeps, we kunnen deze ploegenset momenteel niet toevoegen. Probeer het opnieuw.",
          "UNABLE_UPDATE": "Oeps, we kunnen deze ploegenset momenteel niet updaten. Probeer het opnieuw."
        }
      },
      "INDEX": {
        "NEW_BUTTON": "Nieuwe toevoegen",
        "ALERT_LINK_WARNING": "De sjablonen zijn verplaatst naar het menu Instellingen. De bestaande link onder het Rotas-menu verdwijnt binnenkort 👀",
        "TITLE": "Rota-sjablonen",
        "SEARCH_FILTER": "Zoek...",
        "TEMPLATE_TABLE": {
          "NAME_HEADING": "Naam",
          "CREATED_BY_HEADING": "Gemaakt door",
          "LAST_EDITED_HEADING": "Laatst bewerkt",
          "OPTIONS_HEADING": "Opties"
        },
        "NO_TEMPLATES": "Er zijn geen roostersjablonen om weer te geven.",
        "CLONE_TEMPLATE": "Kloon",
        "DELETE_TEMPLATE": "Verwijderen",
        "EDITED_ON": "op",
        "CONFIRM": {
          "DELETE_NODE": "Weet je zeker dat je dit wilt verwijderen?"
        },
        "SUCCESS": {
          "DELETE_NODE": "Je vraagvoorspelling is succesvol verwijderd."
        },
        "ERRORS": {
          "DELETE_NODE": "Oeps, er is gewoon iets misgegaan. Misschien moet je het opnieuw proberen!"
        }
      },
      "NEW_TEMPLATE_MODAL": {
        "TITLE": "Nieuw sjabloon",
        "REMINDER": "<strong>Verleden!</strong> Je hebt al een sjabloon (s) voor <strong>{{TemplateCount}}</strong>. U hoeft alleen een nieuwe aan te maken als uw planningsvereisten aanzienlijk zijn gewijzigd. Anders moet je een bestaand bestand bewerken.",
        "TYPE_LABEL": "Typ",
        "NEW_BLANK_TEMPLATE": "Nieuwe lege sjabloon",
        "CLONE_EXISTING_TEMPLATE": "Bestaande sjabloon klonen",
        "CLONE_FROM_LABEL": "Kloon van",
        "NAME_LABEL": "Naam van de sjabloon",
        "NAME_HELP": "Je zult regelmatig roosters genereren op basis van dit sjabloon en een goede naam beschrijft voor wie of waarvoor deze roosters zullen zijn, zoals 'rooster van ondersteunend personeel'.",
        "NAME_PLACEHOLDER": "dwz. Hoogseizoen, of programma van het B-team",
        "DEFAULT_SHIFT_TYPE_LABEL": "Standaard ploegendiensttype",
        "DEFAULT_SHIFT_TYPE_HELP": "Wanneer u uw sjabloon samenstelt, kunt u het ploegendiensttype voor elke groep of taak wijzigen en het is handig voor ons om nu een standaardinstelling in te stellen.",
        "CREATE_BUTTON": "Creëer!",
        "CREATE_BUTTON_LOADING": "Even geduld, dit kan even duren...",
        "CANCEL_BUTTON": "Annuleer",
        "CONFIRM_CLONE": "Het klonen van een sjabloon duurt enkele ogenblikken, afhankelijk van de grootte van de sjabloon. Weet je zeker dat je door wilt gaan?",
        "CREATE_ERROR": "Momenteel kan dit sjabloon niet worden gemaakt. Probeer het nog eens."
      },
      "DELETE_TEMPLATE_MODAL": {
        "INFO_TITLE": "Sjabloon verwijderen",
        "INFO_BODY": "Het verwijderen van een sjabloon is onomkeerbaar en kan niet ongedaan worden gemaakt. Om ons ervan te overtuigen dat u dit wilt doen, typt u de naam van het sjabloon in het onderstaande vak en klikt u vervolgens op verwijderen.",
        "DELETE_TEMPLATE_BUTTON": "Sjabloon verwijderen",
        "SUCCESS": {
          "DELETE_TEMPLATE": "Je sjabloon is succesvol verwijderd."
        },
        "ERRORS": {
          "DELETE_TEMPLATE": "Oeps, er is gewoon iets misgegaan. Misschien moet je het opnieuw proberen!"
        }
      }
    },
    "SHIFT": {
      "EDIT_MODAL": {
        "SHIFT": "Shift",
        "SITE": "Site",
        "START": "Begin",
        "BREAK": "Pauze",
        "NONE": "Geen",
        "FINISH": "Afmaken",
        "NEW_SHIFT": "Nieuwe shift",
        "ATTRIBUTE_WAGE_COST_TO_SITE": " Ken de loonkosten en uren toe aan de gekozen locatie",
        "DATE_AND_TIME": "Datum en tijd",
        "TO": " naar ",
        "THEN_REST": " rust dan uit ",
        "SHIFT_TYPE": "Soort ploegendienst",
        "LOCATION": "Aanwezigheidszone",
        "NO_BREAK": "Geen pauze",
        "PAID_BREAK": "Betaalde pauze",
        "UNPAID_BREAK": "Onbetaalde pauze",
        "OF_LENGTH": "van lengte",
        "BREAK_MESSAGE_WARNING": "Het personeel dat voor deze shift wordt ingezet, heeft verschillende pauzevereisten. Als je klaar bent, bewaart Rotaready een aparte shift voor elke persoon met de juiste pauzeset.",
        "BREAK_MESSAGE_SUCCESS": "We hebben automatisch de juiste pauze toegepast op deze shift.",
        "PAY_RULE": "Beloningsregel",
        "APPLIES": " is van toepassing",
        "FOR_THE_SCHEDULED_DURATION": "Voor de geplande duur",
        "FOR_ANY_OVERTIME": "Voor eventuele overuren",
        "STAFF_SELECTION": "Selectie van personeel",
        "STAFF_SELECTION_HELP": "Bewerk toegewezen medewerkers en gebruik tags om anderen te filteren die daarvoor in aanmerking komen",
        "STAFF_MUST_MATCH": "Personeel moet overeenkomen",
        "ANY_OF_THE_TAGS": "<strong>een</strong> van de tags",
        "ANY": "ieder",
        "ALL": "allemaal",
        "OF_THE_TAGS": " van de tags",
        "ALL_OF_THE_TAGS": "<strong>alle</strong> tags",
        "TAG_TYPEAHEAD_PLACEHOLDER": "Typ om tags te vinden",
        "STAFF_ASSIGNED": "Toegewezen",
        "NOBODY_IS_ASSIGNED": "Niemand is toegewezen",
        "STAFF_ELIGIBLE": "Komt in aanmerking",
        "STAFF_FILTER_PLACEHOLDER": "Zoek...",
        "SHOW": "Laat zien",
        "MORE": " meer...",
        "NOBODY_MATCHES_YOUR_TAGS": "Niemand komt overeen met je tags",
        "NOBODY_ELSE_IS_AVAILABLE": "Er is niemand anders beschikbaar",
        "MORE_OPTIONS": "Meer opties",
        "ADD_NOTES_AND_ADJUST_PAY_RATES": "Notities toevoegen en loontarieven aanpassen",
        "DETAILS": "Details",
        "DETAILS_TEXTAREA_PLACEHOLDER": "Voeg hier notities, taken en andere details toe...",
        "ROTA": "Rota",
        "NO_ROTA": "Geen rooster",
        "PAY_LABEL": "Betalen",
        "PAY_TOGGLE": "Dit is een betaalde shift",
        "DELETE": "Verwijderen",
        "VIEW_BROADCAST": "Uitzending bekijken",
        "START_BROADCAST": "Uitzending starten",
        "SAVE": "Opslaan",
        "CANCEL": "Annuleer"
      },
      "SHIFT_BROADCAST_MODAL": {
        "SHIFT": "Shift",
        "SITE": "Site",
        "START": "Begin",
        "BREAK": "Pauze",
        "NONE": "Geen",
        "FINISH": "Afmaken",
        "ORIGINAL_PLANNED_STAFF": "Oorspronkelijk gepland personeel",
        "EXTRA_STAFF_REQUIRED": "Extra personeel vereist",
        "NOT_SHIFT_BROADCASTING_HELP": "Zet op 0 om zoveel mogelijk personeel aan te vragen.",
        "START_BROADCASTING": "Begin met uitzenden",
        "START_BROADCASTING_LOADING": "Uitzending starten...",
        "CANCEL": "Annuleer",
        "ACCEPTED": "Geaccepteerd",
        "PENDING": "In afwachting",
        "REJECTED": "Afgewezen",
        "EVERYONE": "Iedereen",
        "NO_PARTICIPANTS_TO_SHOW": "Er zijn geen deelnemers aan de uitzending om weer te geven.",
        "NEEDED": "Nodig",
        "SHIFT_BROADCASTING_HELP": "Je uitzending is momenteel aan de gang. De onderstaande lijst wordt bijgewerkt als het personeel de shift accepteert of weigert. De uitzending wordt automatisch beëindigd wanneer het juiste aantal medewerkers heeft geaccepteerd of wanneer iedereen heeft gereageerd.",
        "STOP_BROADCASTING": "Stop met uitzenden",
        "STOP_BROADCASTING_LOADING": "Uitzending stoppen...",
        "PAUSE_BROADCAST": "Uitzending onderbreken",
        "PAUSE_BROADCAST_LOADING": "Uitzending onderbreken...",
        "RESUME_BROADCAST": "Uitzending hervatten",
        "RESUME_BROADCAST_LOADING": "De uitzending wordt hervat...",
        "CLOSE": "Sluiten"
      },
      "VIEW_MODAL": {
        "SHIFT": "Shift",
        "SITE": "Site",
        "START": "Begin",
        "BREAK": "Pauze",
        "NONE": "Geen",
        "FINISH": "Afmaken",
        "LOCATION": "Aanwezigheidszone",
        "NONE_SPECIFIED": "Niet gespecificeerd",
        "REST": "Rust",
        "DETAILS": "Details",
        "ALLOTTED_BREAK": "Toegewezen pauze",
        "ASSIGNED_STAFF": "Toegewezen personeel",
        "ON": "op",
        "SHIFT_ROUTINE": "Ploegendienst",
        "LAST_UPDATED": "Laatst bijgewerkt",
        "GET_DIRECTIONS": "Routebeschrijving opvragen",
        "SHOW_TRAFFIC": "Traffic tonen",
        "EDIT": " Bewerken",
        "CLOSE": "Sluiten"
      }
    }
  },
  "DASHBOARD": {
    "TIME_AND_ATTENDANCE": "Tijdstip en aanwezigheid",
    "YOU_ARE_NOW": "Je bent nu",
    "ERROR_PLEASE_TRY_AGAIN": "Fout, probeer het opnieuw",
    "MY_UPCOMING_SHIFTS": "Mijn komende diensten",
    "YOU_HAVE_NO_SHIFTS_TODAY": "Je hebt vandaag geen ploegen.",
    "ALL_DAY": "De hele dag",
    "AM": "BEN",
    "PM": "'S MIDDAGS",
    "TODAY_S_SCHEDULE": "Het schema van vandaag",
    "THERE_ARE_NO_SHIFTS_SCHEDULED_TODAY": "Er zijn vandaag geen diensten gepland.",
    "WHATEVER": "Beginnend bij <strong>{{startTime}}</strong> en eindigend bij <strong>{{endTime</strong>}}"
  },
  "SIGN_OUT": {
    "LOGGING_OUT": "Uitloggen..."
  },
  "PARTIALS": {
    "VALIDATION": {
      "ISSUE_1": {
        "HEADING": "Te lange werkdag",
        "BODY": "Je wijziging betekent dat de werkdag van <b>{{name}</b>} te lang wordt (volgens de instellingen van je organisatie). Momenteel start het op <b>{{start|MomentFormat: 'lll'}}, en eindigt op {{End|momentFormat: 'lll'}} (en blijft vervolgens rusten tot {{EndRest|momentFormat: 'lll'}}</b> (en blijft dan rusten tot <b>{{EndRest|momentFormat: 'lll'</b>}}."
      },
      "ISSUE_2": {
        "HEADING": "Overlappende werkdag",
        "BODY": "Je wijziging betekent dat <b>{{name}}</b> een werkdag heeft van {{<b>PrimaryStart|MomentFormat: 'lll'}}, format:'lll'Occasion} tot en met {{PrimaryEnd|momentFormat: 'LT'<b>}</b></b>}. Helaas overlapt dit met een werkdag die ze al hebben, van <b>{{SecondaryStart|momentFormat: 'lll'}} (en dan rusten ze tot {{SecondaryEnd|momentFormat: 'LT'}}) (en dan rusten ze tot <b>{{EndRest|momentFormat: 'LT'} :'lt'*</b></b>)."
      },
      "ISSUE_3": {
        "HEADING": "Ploegendienst buiten de werkdag",
        "BODY": "<b>Voor {{name}}</b> is een verschuiving gepland <b>van {{start|MomentFormat: 'LT'}}} (en rust dan tot {{End|momentFormat: 'LT'}}</b> (en rust dan <b>tot {{EndRest|momentFormat: 'LT'</b>}}. Uw wijziging zou betekenen dat deze shift geheel of gedeeltelijk buiten de werkdag valt."
      },
      "ISSUE_4": {
        "HEADING": "Niet beschikbaar",
        "BODY": "<b>{{name}}</b> <b>is niet beschikbaar <b>van {{start|MomentFormat: 'lll'}} {start|momentFormat: 'LT'}}</b>, zoals ze zijn</b>",
        "REASON_1": "in ploegendienst werken ({{eventType}} in {{EntityName}})",
        "REASON_2": "druk tijdens een werkdag",
        "REASON_3": "afwezig ({{eventType}})",
        "REASON_4": "niet beschikbaar vanwege hun beschikbaarheidspatroon",
        "BUTTON": "Maak een uitzondering en probeer het opnieuw",
        "BUTTON_LOADING": "Aan het laden..."
      },
      "ISSUE_5": {
        "HEADING": "Contractuele verplichting niet nagekomen",
        "BODY": "Uw wijziging betekent dat <b>{{name}}</b> niet aan hun contractuele verplichting zal voldoen. Het zijn nu <b>{{currentValue}} dagen (en), maar het moeten minstens {<b>{ComparatorValue</b>}} dag (en)</b> zijn."
      },
      "ISSUE_6": {
        "HEADING": "Contractuele verplichting niet nagekomen",
        "BODY": "Uw wijziging betekent dat <b>{{name}}</b> niet aan hun contractuele verplichting zal voldoen. Het zijn nu <b>{{currentValue}} uur (en), maar het moet minstens {<b>{ComparatorValue</b>}} uur (en)</b> zijn."
      },
      "ISSUE_7": {
        "HEADING": "Tags komen niet overeen",
        "BODY": "<b>{{name}}</b> heeft niet de vereiste tags om in deze shift te kunnen werken. Dit betekent dat ze mogelijk een vaardigheid, kwalificatie of ander kenmerk missen."
      },
      "ISSUE_8": {
        "HEADING": "Actieve uitzending",
        "BODY": "Er is momenteel een actieve Shift Broadcast beschikbaar voor deze shift. Omdat uw medewerkers mogelijk bezig zijn met reageren, kunnen we niet toestaan dat de shift wordt gewijzigd, tenzij u eerst de Shift Broadcast annuleert."
      },
      "ISSUE_9": {
        "HEADING": "Onvoldoende vrije dagen",
        "BODY": "Je wijziging betekent dat <b>{{name}}</b> deze week te veel dagen werkt. Ze hebben nu <b>{{currentValue}} vrije dag (en), wat minder is dan hun vereiste dag (en)</b> voor {<b>{ComparatorValue}}</b>."
      },
      "ISSUE_10": {
        "HEADING": "Personeelslid inactief",
        "BODY": "<b>{{name}}</b> kan niet werken <b>op {{start|momentFormat:</b> 'll'}}. Hun account is op deze datum inactief of ze zijn verplaatst naar een andere site."
      },
      "ISSUE_11": {
        "HEADING": "Rota gemarkeerd voor goedkeuring",
        "BODY": "U kunt geen wijzigingen aanbrengen in dit rooster omdat het is gemarkeerd voor goedkeuring."
      },
      "ISSUE_12": {
        "HEADING": "Ondertekende periode",
        "BODY": "Je kunt geen wijzigingen aanbrengen in dit rooster omdat het is afgetekend."
      },
      "ISSUE_13": {
        "HEADING": "De werklimiet is overschreden",
        "BODY": "Je wijziging betekent dat <b>{{name}</b>} langer zal werken dan toegestaan is. Ze werken nu met <b>{{currentValue}} dag (en) die meer is dan hun limiet van {<b>{ComparatorValue</b></b>}} dag (en)."
      },
      "ISSUE_14": {
        "HEADING": "De werklimiet is overschreden",
        "BODY": "Je wijziging betekent dat <b>{{name}</b>} langer zal werken dan toegestaan is. Ze zullen nu <b>{{currentValue}} uren werken, wat meer is dan hun limiet van {<b>{ComparatorValue</b>}} uur (en)</b>."
      },
      "ISSUE_15": {
        "HEADING": "Aanwezigheidszone gesloten",
        "BODY": "De aanwezigheidszone die aan de shift is toegewezen (<b>{{AssignedLocation}}}) is op dit moment</b> gesloten.",
        "REASON": "Dit komt door: {{reason}}."
      },
      "ISSUE_16": {
        "HEADING": "De bezetting in de aanwezigheidszone is overschreden",
        "BODY": "De aanwezigheidszone die aan deze shift is toegewezen, heeft een maximale bezetting van <b>{{occupancy}}</b> perso (o) n (en) tegelijk. Met deze verschuiving zou de bezetting {{currentOccupancy}} zijn."
      },
      "ISSUE_17": {
        "HEADING": "Te weinig personeel in de aanwezigheidszone",
        "BODY": "<b>Van {{start|momentFormat: 'lll'}} start|MomentFormat: 'lll'}} is er een tijd dat alleen {{currentValue}} persoon (en) aan het werk zijn.</b> Wanneer iemand in een shift zit, zijn minimaal <b>{{ComparatorValue}} perso (o) n (en) vereist</b>."
      },
      "ISSUE_18": {
        "HEADING": "Personeelslid niet goedgekeurd",
        "BODY": "{{name}} kan niet werken <b>op {{start|momentFormat:</b> 'll'}}. Hun account is nog niet goedgekeurd voor de planning."
      },
      "ISSUE_19": {
        "HEADING": "Documenten van het personeelslid ontbrekend/verlopen",
        "BODY": "<b>{{name}}</b> kan niet werken <b>op {{start|momentFormat:</b> 'll'}}. Een of meer vereiste documenten ontbreken of zijn verlopen."
      },
      "ISSUE_20": {
        "HEADING": "Te lang schakelen (jonger dan 18 jaar)",
        "BODY": "Je wijziging betekent dat <b>{{name}}</b> werkt <b>voor {{Duration|HumaniseDuration</b>}}. Aangezien ze jonger zijn dan 18 jaar, is de limiet van de richtlijn betreffende de <b>arbeidstijden {{limit|HumaniseDuration</b>}}."
      },
      "ISSUE_21": {
        "HEADING": "Buiten de grenzen verleggen",
        "BODY": "<b>Je kunt geen shift plannen voor <b>{{name}} op {{</b><b>start|MomentFormat: 'll'}} omdat deze thuishoort in een rooster dat begint op {{PeriodStart|momentFormat: 'll'}}</b> en eindigt op <b>{{PeriodEnd|momentFormat</b>: 'll'}}} MomentFormat:'ll'}.</b>"
      }
    }
  },
  "NAV": {
    "HOME": "Home",
    "ROTAS": "Rotas",
    "STAFF": "Personeel",
    "ATTENDANCE": "Aanwezigheid",
    "REPORTS": "Rapporten",
    "SETTINGS": "Instellingen",
    "VIEW_ROTA": "Bekijk het rooster",
    "SCHEDULE": "Mijn schema",
    "ABSENCE": "Mijn afwezigheid",
    "AVAILABILITY": "Mijn beschikbaarheid",
    "DOCUMENTS": "Mijn documenten",
    "ROTA_DASHBOARD": "Dashboard",
    "ROTA_ROTA_EDITOR": "Rota-redacteur",
    "ROTA_TRONC": "Tronc",
    "ROTA_EVENT_CALENDAR": "Evenementenplanner",
    "STAFF_DIRECTORY": "adresboek",
    "STAFF_REQUESTS": "Verzoeken",
    "STAFF_ABSENCES": "Verzuimkalender",
    "STAFF_EMPLOYEE_SUBMITTED_DETAILS": "Gegevens ingediend door de werknemer",
    "ATTENDANCE_OVERVIEW": "Aanwezigheid",
    "ATTENDANCE_ISSUES": "Kwesties",
    "COST_CONTROL": "Kostenbeheersing",
    "COST_CONTROL_SIGN_OFF": "Kostenbeheersing en afmelding",
    "COST_CONTROL_AGGREGATED_TOTALS": "Geaggregeerde totalen",
    "ASSET_REGISTRY": "Activaregister",
    "WFM_DASHBOARD": "Mijn dashboards"
  },
  "SETTINGS": {
    "INDEX": {
      "TITLE": "Instellingen",
      "PLACEHOLDER_TEXT": "👈 Selecteer een instelling aan de linkerkant om aan de slag te gaan.",
      "CATEGORY_HR": "HR",
      "CATEGORY_ROTAS": "Rotas",
      "CATEGORY_ATTENDANCE": "Aanwezigheid",
      "CATEGORY_INTEGRATIONS": "Integraties",
      "ITEM_ROTA_TEMPLATES": "Rota-sjablonen",
      "ITEM_TRUSTED_DEVICES": "Vertrouwde apparaten",
      "ITEM_SHIFT_LOCATIONS": "Aanwezigheidszones",
      "ITEM_SHIFT_TYPES": "Soorten ploegendiensten",
      "ITEM_OUTSOURCING": "Uitbesteding",
      "ITEM_ABSENCE_TYPES": "Soorten afwezigheid",
      "ITEM_DOCUMENT_CATEGORIES": "Documentcategorieën",
      "ITEM_DOCUMENT_TEMPLATES": "Documentsjablonen",
      "ITEM_TAGS_GROUPS": "Tags en groepen",
      "ITEM_SHIFT_ROUTINES": "Ploegendiensten",
      "ITEM_REST_BREAKS": "Rustpauzes",
      "ITEM_PAY_ELEMENTS": "Betaalelementen",
      "ITEM_PAY_RULES": "Regels voor het betalen",
      "ITEM_PERMISSIONS": "Machtigingen",
      "ITEM_BUDGETS": "Budgetten",
      "ITEM_ASSET_TYPES": "Soorten activa",
      "ITEM_ENTITY_GROUP_SUBSCRIPTIONS": 'Site Toegangsbeheerder',
      "ITEM_ENTITY_GROUPS": 'Sitebeheerder',
      "ITEM_POSITIONS": 'Functierollen',
      "ITEM_LEAVER_REASONS": 'Redenen voor vertrek',
      "ITEM_RECRUITMENT_SOURCES": 'Wervingsbronnen',
      "ITEM_LABOUR_DEPLOYMENT": 'Arbeidsinzet',
    },
    "DOCUMENT_TEMPLATES": {
      "TITLE": "Documentsjablonen",
      "FILTER_LABEL_CATEGORY": "Categorie",
      "BUTTON_UPLOAD": "Uploaden",
      "NAME_COLUMN_LABEL": "Naam",
      "TEMPLATE_CATEGORY_COLUMN_LABEL": "Categorie",
      "FILE_NAME_COLUMN_LABEL": "Bestandsnaam",
      "FILE_SIZE_COLUMN_LABEL": "Bestandsgrootte",
      "DESTINATION_CATEGORY_COLUMN_LABEL": "Categorie van bestemming",
      "SIGNATORIES_COLUMN_LABEL": "Ondertekenaars",
      "EDITED_COLUMN_LABEL": "Toegevoegd door",
      "OPTIONS_COLUMN_LABEL": "Opties",
      "LINK_DELETE": "Verwijderen",
      "SIGNATORIES_BOTH": "Werknemer, werkgever",
      "SIGNATORIES_EMPLOYEE": "Werknemer",
      "NO_DOCS_MATCH_TEXT": "We konden geen documentsjablonen vinden die aan je filters voldoen.",
      "ERROR_NO_CATEGORIES": "Documentsjablonen zijn niet ingeschakeld voor uw organisatie. Chat met ons om aan de slag te gaan.",
      "ALERT_CONFIRM_DELETE": "Weet je zeker dat je dit sjabloon wilt verwijderen? Dit kan niet ongedaan worden gemaakt!",
      "ERROR_500": "We kunnen op dit moment geen documentsjablonen laden. Probeer het nog eens.",
      "ERROR_403": "Je hebt geen toestemming om documentsjablonen te bekijken/wijzigen.",
      "UPLOAD_MODAL": {
        "TITLE": "Een documentsjabloon uploaden",
        "LABEL_NAME": "Naam",
        "HELP_TEXT_NAME": "Geef je sjabloon een naam die je zult herkennen.",
        "LABEL_CATEGORY": "Categorie sjabloon",
        "HELP_TEXT_CATEGORY": "Houd je sjablonen geordend door een categorie te selecteren.",
        "LABEL_DESTINATION_CATEGORY": "Categorie van het bestemmingsdocument",
        "HELP_TEXT_DESTINATION_CATEGORY": "Wanneer dit sjabloon wordt gebruikt en een gegenereerd document is ondertekend, kiest u de documentcategorie waarin het bestand moet worden opgeslagen.",
        "LABEL_SIGNATORIES": "Ondertekenaars",
        "SIGNATORIES_OPTION_BOTH": "Zowel de werknemer als de werkgever moeten tekenen.",
        "SIGNATORIES_OPTION_EMPLOYEE": "Alleen de werknemer hoeft te tekenen.",
        "LABEL_UPLOAD": "Uploaden",
        "BUTTON_CANCEL": "Annuleer",
        "BUTTON_SAVE": "Opslaan",
        "ALERT_SUCCESS": "Je documentsjabloon is opgeslagen.",
        "ALERT_ERROR_500": "Er is een fout opgetreden bij het uploaden van de documentsjabloon. Probeer het opnieuw of neem contact met ons op als dit bericht zich blijft voordoen.",
        "ALERT_ERROR_400": "Er klopt iets niet helemaal met je documentsjabloon."
      }
    },
    "ABSENCE_TYPES": {
      "TITLE": "Soorten afwezigheid",
      "ERROR_500": "We kunnen op dit moment geen afwezigheidstypes laden. Probeer het nog eens.",
      "ERROR_403": "Je hebt geen toestemming om afwezigheidstypes te bekijken/wijzigen.",
      "BUTTON_ADD_NEW": "Nieuwe toevoegen",
      "BUTTON_TOGGLE_DELETED": "Verwijderd opnemen",
      "COLUMN_NAME": "Naam",
      "COLUMN_BOOKABLE": "Boekbaar",
      "COLUMN_APPROVAL": "Goedkeuring vereist",
      "COLUMN_PAID": "Betaald",
      "COLUMN_EDITED": "Bewerkt door",
      "COLUMN_OPTIONS": "Opties",
      "YES_LABEL": "Ja",
      "NO_LABEL": "Nee",
      "LINK_EDIT": "Bewerken",
      "LINK_DELETE": "Verwijderen",
      "LINK_REINSTATE": "Herstellen",
      "PILL_DELETED": "Verwijderd",
      "ALERT_CONFIRM_DELETE": "Weet je zeker dat je dit verzuimtype wilt verwijderen?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Je afwezigheidstype is opgeslagen.",
        "ALERT_ERROR_500": "Er is een fout opgetreden bij het aanmaken van je afwezigheidstype. Probeer het opnieuw of neem contact met ons op als dit bericht zich blijft voordoen.",
        "ALERT_ERROR_400": "Er bestaat al een verzuimtype met die naam. Kies alsjeblieft een andere.",
        "ADD_TITLE": "Nieuw afwezigheidstype",
        "EDIT_TITLE": "Verzuimtype bewerken",
        "BUTTON_SAVE": "Opslaan",
        "BUTTON_CANCEL": "Annuleer",
        "LABEL_NAME": "Naam",
        "LABEL_COLOUR": "Kleur",
        "LABEL_BOOKABLE": "Boekbaar",
        "LABEL_BOOKABLE_HELP": "Sta werknemers toe om dit soort afwezigheidsverzoeken in te dienen.",
        "LABEL_APPROVAL": "Goedkeuring",
        "LABEL_APPROVAL_HELP": "Verzoeken van dit type vereisen goedkeuring.",
        "LABEL_PAID": "Betaald",
        "LABEL_PAID_HELP": "Afwezigheid van dit type wordt betaald.",
        "LABEL_WORKED_HOURS": "Gewerkte uren",
        "LABEL_WORKED_HOURS_HELP": "Een dergelijke afwezigheid draagt bij aan de 'gewerkte uren'.",
        "LABEL_ACCRUES": "Opgebouwde vakantiedagen",
        "LABEL_ACCRUES_HELP": "Werknemers bouwen vakantiedagen op bij een dergelijk verzuim als ze een vakantiegeld op basis van opbouw hebben.",
        "LABEL_OVERLAPS_SHIFTS": "Shift-wedstrijd",
        "LABEL_OVERLAPS_SHIFTS_HELP": "Een afwezigheid van dit type kan gepaard gaan met ploegendiensten, in plaats van in- en uitklokken.",
        "LABEL_TRONC": "Tronc",
        "LABEL_TRONC_HELP": "Een dergelijke afwezigheid draagt bij aan de gewerkte uren in Tronc.",
        "LABEL_ALLOWANCE_REQUIRED": "Toelage vereist",
        "LABEL_ALLOWANCE_REQUIRED_HELP": "Als u een dergelijke afwezigheid wilt aanvragen, moet u ervoor zorgen dat de werknemer een actieve vergoeding heeft.",
        "TITLE_FILE_UPLOADS": "Uploads van bestanden",
        "LABEL_SUBMISSION_UPLOAD": "Op aanvraag",
        "LABEL_SUBMISSION_UPLOAD_HELP": "Sta toe dat een bestand wordt bijgevoegd wanneer om afwezigheid van dit type wordt gevraagd.",
        "LABEL_REVIEW_UPLOAD": "Over de beoordeling",
        "LABEL_REVIEW_UPLOAD_HELP": "Sta toe dat een bestand wordt bijgevoegd wanneer de afwezigheid van dit type is goedgekeurd of geweigerd.",
        "LABEL_SAVE_TO": "Opslaan naar",
        "HELP_TEXT_SAVE_TO": "Wanneer een bestand wordt bijgevoegd bij afwezigheid van dit type, wordt het opgeslagen in de documenten van de werknemer onder de gekozen categorie.",
        "FILE_UPLOAD_OPTION_NONE": "Uitgeschakeld",
        "FILE_UPLOAD_OPTION_OPTIONAL": "Optioneel",
        "FILE_UPLOAD_OPTION_MANDATORY": "Verplicht",
        "INFO_LOCKED": "Dit is een speciaal verzuimtype dat door Rotaready wordt gebruikt. We hebben enkele eigenschappen verborgen die niet kunnen worden gewijzigd. Neem contact met ons op als je hulp nodig hebt bij dit soort verzuim.",
        "TITLE_PAY": "Betalen",
        "LABEL_FIXED_AMOUNTS": "Vaste bedragen",
        "HELP_TEXT_FIXED_AMOUNTS": "Bij betaling van een vast bedrag aan een werknemer, zoals betaling voor opgebouwde maar nog niet opgenomen afwezigheid, wordt dit loonelement gebruikt.",
        "TITLE_REASONS": "Redenen",
        "COLUMN_TITLE": "Reden",
        "COLUMN_OPTIONS": "Opties",
        "LINK_DELETE": "Verwijderen",
        "NO_REASONS": "Er zijn geen redenen toegevoegd.",
        "PLACEHOLDER_REASON": "Nieuwe reden...",
        "LINK_ADD": "Toevoegen"
      }
    },
    "OUTSOURCING": {
      "TITLE": "Uitbesteding",
      "BUTTON_ADD_NEW": "Nieuwe toevoegen",
      "BUTTON_TOGGLE_DELETED": "Verwijderd opnemen",
      "NO_DATA_TEXT": "Er zijn geen outsourcing-organisaties om aan te tonen.",
      "NAME_COLUMN_TEXT": "Naam",
      "ENTITY_COLUMN_TEXT": "Site/afdeling",
      "RESOURCES_COLUMN_TEXT": "Hulpbronnen",
      "HOURS_COLUMN_TEXT": "Beschikbare uren",
      "COST_COLUMN_TEXT": "Kost",
      "LAST_EDITED_COLUMN_TEXT": "Bewerkt door",
      "OPTIONS_COLUMN_TEXT": "Opties",
      "UNLIMITED_TEXT": "Onbeperkt",
      "ALWAYS_24_TEXT": "Altijd (24 uur)",
      "PER_HOUR_TEXT": "/uur",
      "PER_DAY_TEXT": "/dag",
      "ORG_WIDE_TEXT": "Organisatiebreed",
      "LINK_EDIT": "Bewerken",
      "LINK_DELETE": "Verwijderen",
      "LINK_REINSTATE": "Herstellen",
      "PILL_DELETED": "Verwijderd",
      "ERROR_500": "We kunnen outsourcing-organisaties op dit moment niet belasten. Probeer het opnieuw.",
      "ERROR_403": "Je hebt geen toestemming om outsourcingorganisaties te bekijken/wijzigen.",
      "ALERT_CONFIRM_DELETE": "Weet je zeker dat je deze outsourcingorganisatie wilt verwijderen?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Uw outsourcingorganisatie is gered.",
        "ALERT_ERROR_500": "Er is een fout opgetreden bij het aanmaken van uw outsourcingorganisatie. Probeer het opnieuw of neem contact met ons op als dit bericht zich blijft voordoen.",
        "ALERT_ERROR_400": "Er bestaat al een outsourcingorganisatie met die naam. Kies alsjeblieft een andere.",
        "EDIT_TITLE": "Organisatie bewerken",
        "ADD_TITLE": "Organisatie toevoegen",
        "NAME_LABEL": "Naam",
        "ENTITY_LABEL": "Site/afdeling",
        "PAY_LABEL": "Betalen",
        "HOURLY_OPTION": "Per uur betaald",
        "FIXED_OPTION": "Vaste kosten per dag",
        "HOURLY_RATE_LABEL": "Uurtarief",
        "DAILY_RATE_LABEL": "Dagtarief",
        "AUTOMATIC_SCHEDULE_TITLE": "Geautomatiseerde planning",
        "HOURS_AVAILABLE_LABEL": "Beschikbare uren",
        "TO_TEXT": " naar ",
        "HOURS_AVAILABLE_HELP": "De tijd gedurende welke werknemers van deze organisatie beschikbaar zijn",
        "RESOURCES_AVAILABLE_LABEL": "Beschikbare bronnen",
        "RESOURCES_AVAILABLE_HELP": "Het maximale aantal werknemers dat per dag tot uw beschikking staat (voer 0 in voor onbeperkt)",
        "TAGS_LABEL": "Labels",
        "TAGS_PLACEHOLDER": "Typ om tags te zoeken",
        "TAGS_HELP": "Wijs tags toe zoals je dat zou doen aan een normaal personeelslid. Deze zullen van toepassing zijn op alle werknemers van deze organisatie.",
        "BUTTON_SAVE": "Opslaan",
        "BUTTON_CANCEL": "Annuleer"
      }
    },
    "TRUSTED_DEVICES": {
      "TITLE": "Vertrouwde apparaten",
      "DEVICE": "Apparaat",
      "ENTITY": "Primaire locatie/afdeling",
      "DEVICE_TYPE": "Gebruikersagent",
      "EDITED_BY": "Bewerkt door",
      "LAST_ACTIVITY": "Laatste activiteit",
      "STATUS": "Status",
      "OPTIONS": "Opties",
      "NO_DEVICES": "Er zijn geen vertrouwde apparaten voor het registreren van de aanwezigheid.",
      "NO_ACTIVITY": "Geen activiteit",
      "LINK_UNTRUST": "Onvertrouwen",
      "LINK_TRUST": "Vertrouwen",
      "LINK_EDIT": "Bewerken",
      "ALERT_CONFIRM_TRUST": "Weet u zeker dat u dit apparaat wilt vertrouwen voor het vastleggen van de aanwezigheid?",
      "ALERT_CONFIRM_UNTRUST": "Weet u zeker dat u dit apparaat niet meer wilt vertrouwen, zodat het niet langer kan worden gebruikt voor het vastleggen van de aanwezigheid? Dit kan niet worden teruggedraaid.",
      "ERROR_500": "We kunnen op dit moment geen vertrouwde apparaten laden. Probeer het nog eens.",
      "ERROR_403": "Je hebt geen toestemming om vertrouwde apparaten te bekijken/wijzigen.",
      "STATUS_PENDING": "In afwachting",
      "STATUS_TRUSTED": "Vertrouwd",
      "STATUS_UNTRUSTED": "Onvertrouwd",
      "FILTER_SITE": "In kaart gebrachte locatie/afdeling",
      "FILTER_STATUS": "Status",
      "BUTTON_NEW_DEVICE": "Nieuw apparaat",
      "NEW_DEVICE_MODAL": {
        "TITLE": "Een nieuw apparaat toevoegen",
        "BUTTON_CANCEL": "Annuleer",
        "BUTTON_DONE": "Ga verder",
        "TEXT_STEP_1": "Stap 1",
        "TEXT_STEP_2": "Stap 2",
        "TEXT_STEP_1_BODY": "Pak je tablet of laptop, open een webbrowser en navigeer naar de volgende URL:",
        "TEXT_STEP_2_BODY_A": "Wanneer u om een „Realm ID” wordt gevraagd, voert u de volgende waarde in:",
        "TEXT_STEP_2_BODY_B": "Wanneer u om een „Entiteits-ID” wordt gevraagd, voert u de volgende waarde in:",
        "TEXT_STEP_3_BODY": "Als u klaar bent, klikt u op Doorgaan om terug te keren naar de lijst met apparaten. Je nieuwe apparaat zou dan moeten verschijnen. Klik naast het apparaat op Vertrouwen om het koppelingsproces te voltooien."
      },
      "EDIT_DEVICE_MODAL": {
        "TITLE": "Apparaat bewerken",
        "BUTTON_CANCEL": "Annuleer",
        "BUTTON_SAVE": "Opslaan",
        "BODY": "Door hieronder meer sites of afdelingen te selecteren, verschijnen de bijbehorende medewerkers op het startscherm van het apparaat. U kunt de primaire locatie/afdeling niet deselecteren; u moet het apparaat niet vertrouwen als u dit wilt wijzigen.",
        "ALERT_200": "Het apparaat is bijgewerkt.",
        "ALERT_500": "We konden het apparaat op dat moment niet updaten. Probeer het later opnieuw.",
        "LABEL_NAME": "Naam van het apparaat"
      }
    }
  },
  "MESSAGING": {
    "BETA": "BÈTA",
    "MESSAGE_HUB": {
      "TITLE": "Berichten",
      "NO_MESSAGES": "Geen berichten",
      "START_NEW": "Een nieuw gesprek starten",
      "YESTERDAY": "gisteren"
    },
    "CONVERSATION": {
      "MESSAGE_PLACEHOLDER": "Voeg je bericht toe",
      "INDIVIDUAL_TYPING": "{{name}} is aan het typen...",
      "MULTIPLE_TYPING": "Meerdere mensen zijn aan het typen..."
    },
    "START_NEW": {
      "TITLE": "Een nieuw gesprek starten",
      "TO": "Aan:",
      "REMOVE": "Verwijderen",
      "START_CONVERSATION": "Conversatie starten"
    },
    "ALERTS": {
      "START_CONVERSATION": "Er is een fout opgetreden tijdens het starten van je gesprek. Probeer het nog eens.",
      "SUBSCRIBE_TO_CHANNELS": "Er is een probleem opgetreden bij het abonneren op je gesprekken. Het is mogelijk dat je geen berichten ontvangt. Vernieuw of neem contact op als dit aanhoudt.",
      "FETCH_MESSAGES": "Er is een probleem opgetreden bij het ontvangen van je berichten. Vernieuw of neem contact op als dit aanhoudt.",
      "FETCH_NEW_MESSAGES": "Er is een fout opgetreden bij het ontvangen van nieuwe berichten. Vernieuw of neem contact op als dit aanhoudt.",
      "FETCH_CONVERSATIONS": "Er is een fout opgetreden bij het ophalen van je gesprekken. Vernieuw of neem contact op als dit aanhoudt.",
      "SEND_MESSAGE": "Er is een fout opgetreden bij het verzenden van je bericht. Probeer het nog eens."
    }
  },
  "ABSENCE": {
    "REQUEST": {
      "TITLE": "Verzoek om afwezigheid",
      "BUTTON_SUBMIT": "Verzenden",
      "LABEL_TYPE": "Kies een type",
      "LABEL_REASON": "Selecteer een reden",
      "LABEL_EMPLOYEE": "Werknemer",
      "LABEL_START_DATE": "Startdatum",
      "LABEL_END_DATE": "Einddatum",
      "LABEL_SCOPE_ALL_DAY": "De hele dag",
      "LABEL_SCOPE_AM": "Alleen AM",
      "LABEL_SCOPE_PM": "Alleen in de PM",
      "LABEL_SCOPE_CUSTOM": "Kies specifieke uren",
      "LABEL_START_TIME": "Starttijd",
      "LABEL_END_TIME": "Eindtijd",
      "ALERT_OVERLAPPING_ABSENCE_USER": "Je hebt een andere afwezigheidsboeking die overlapt met de door jou gekozen datum (s).",
      "ALERT_OVERLAPPING_ABSENCE_OTHERS": "Even voor de duidelijkheid, er zijn op dit moment ook andere mensen afwezig.",
      "ERROR_500": "We stuitten op dat moment op een probleem. Probeer het opnieuw!",
      "LABEL_DAYS": "dagen",
      "LABEL_HOURS": "uren",
      "SECTION_UNPAID_DAYS": "Reguliere vrije dagen",
      "HELP_TEXT_NO_UNPAID_DAYS": "Er zijn geen normale vrije dagen in dit verzoek.",
      "BUTTON_OPTIONS": "Opties",
      "BUTTON_CHANGE": "Reguliere vrije dagen wijzigen",
      "BUTTON_RESET": "Zet normale vrije dagen terug naar de voorgestelde",
      "BUTTON_CLEAR": "Alle reguliere vrije dagen vrijmaken",
      "MODAL_CALENDAR_TITLE": "Kies normale vrije dagen",
      "MODAL_BUTTON_SAVE": "Opslaan",
      "LABEL_ADD_A_NOTE": "Voeg een opmerking toe aan dit verzoek",
      "BUTTON_COMPLETE": "Breng me terug",
      "COMPLETE_TITLE": "Je bent helemaal klaar.",
      "COMPLETE_LABEL_APPROVED": "Goedgekeurd",
      "COMPLETE_TEXT_APPROVED": "Je aanvraag is automatisch ingediend en goedgekeurd ✅",
      "COMPLETE_LABEL_PENDING": "In afwachting van goedkeuring",
      "COMPLETE_TEXT_PENDING": "Je aanvraag is ingediend en wacht op goedkeuring. We zullen je op de hoogte brengen wanneer dit verandert 👍"
    },
    "VALIDATION_MODAL": {
      "TITLE": "Er klopt iets niet helemaal",
      "DESCRIPTION": "Helaas veroorzaakt wat u probeert te doen een of meer problemen.",
      "BUTTON_CLOSE": "Sluiten",
      "INVALID_DATES_TITLE": "Ongeldige datums",
      "INVALID_DATES_DESCRIPTION": "Je kunt alleen's ochtends, alleen's middags of aangepaste uren kiezen als je begin- en einddatum dezelfde dag is.",
      "INVALID_HEADER_TITLE": "Ongeldig verzuimrecord",
      "INVALID_HEADER_DESCRIPTION": "Je kunt dat verzuimrecord niet bewerken. Het is mogelijk verwijderd.",
      "INVALID_ACTION_TITLE": "Ongeldige actie",
      "INVALID_ACTION_DESCRIPTION": "Je kunt het tijdstip van een afwezigheidsboeking niet wijzigen nadat deze is afgewezen of geannuleerd.",
      "INVALID_DAYS_TITLE": "Ongeldige dagen",
      "INVALID_DAYS_DESCRIPTION": "Je kunt geen verzuimverzoek indienen voor nul uur of nul dagen.",
      "OUTSIDE_EMPLOYMENT_TITLE": "Externe tewerkstelling",
      "OUTSIDE_EMPLOYMENT_DESCRIPTION": "De door u gekozen start- of einddatum valt buiten de arbeidsdata van de werknemer.",
      "SPANS_TIMEZONES_TITLE": "Het datumbereik omvat twee tijdzones",
      "SPANS_TIMEZONES_DESCRIPTION": "De afspraakgegevens van de werknemer geven aan dat ze op de door u gekozen datums van locatie zijn verhuisd en dat hun bestaande locatie zich in een andere tijdzone bevindt dan de nieuwe locatie. Zorg ervoor dat je verzuimboeking eindigt voordat ze naar een andere locatie verhuizen, of begint nadat ze van locatie zijn verhuisd.",
      "SPANS_PAYROLL_CALENDARS_TITLE": "Het datumbereik omvat twee salariskalenders",
      "SPANS_PAYROLL_CALENDARS_DESCRIPTION": "De afspraakgegevens van de werknemer geven aan dat ze de loonkalender wijzigen op de door u gekozen datums. Zorg ervoor dat je verzuimboeking eindigt voordat ze de salariskalender wijzigen, of begint nadat ze de salariskalender hebben gewijzigd.",
      "INVALID_PAYROLL_CALENDAR_TITLE": "Salariskalender is ongeldig",
      "INVALID_PAYROLL_CALENDAR_DESCRIPTION": "De gekozen periode begint vóór de eerste loonperiode van de loonkalender van de werknemer.",
      "OVERLAPPING_ABSENCE_TITLE": "Overlappende afwezigheid",
      "OVERLAPPING_ABSENCE_DESCRIPTION": "De werknemer is al afwezig op een of meer dagen die overlappen met de door u gekozen data.",
      "OVERLAPPING_SHIFTS_TITLE": "Overlappende diensten",
      "OVERLAPPING_SHIFTS_DESCRIPTION": "De werknemer heeft een of meer gepubliceerde ploegendiensten die overlappen met de door jou gekozen datums. Deze moeten eerst worden verwijderd of niet meer worden toegewezen.",
      "SIGNED_OFF_TITLE": "Datums die zijn afgemeld",
      "SIGNED_OFF_DESCRIPTION": "De datums overlappen met een of meer afgetekende dagen. Je kunt afwezigheid niet goedkeuren of annuleren voor dagen die al zijn afgemeld.",
      "ALLOWANCE_REQUIRED_TITLE": "Toelage vereist",
      "ALLOWANCE_REQUIRED_DESCRIPTION": "Je kunt dit soort afwezigheid niet boeken. Er moet een actieve uitkering zijn om van af te trekken.",
      "ALLOWANCE_NOT_DEDUCTIBLE_TITLE": "Uitkering niet aftrekbaar",
      "ALLOWANCE_NOT_DEDUCTIBLE_DESCRIPTION": "Je kunt dit soort afwezigheid niet boeken. Er is een actieve uitkering, maar deze is gemarkeerd als niet aftrekbaar.",
      "ALLOWANCE_BALANCE_INSUFFICIENT_TITLE": "Onvoldoende balans",
      "ALLOWANCE_BALANCE_INSUFFICIENT_DESCRIPTION": "Het vergoedingssaldo is te laag om dit verzoek in te dienen.",
      "FILE_UPLOAD_REQUIRED_TITLE": "Uploaden van bestanden is vereist",
      "FILE_UPLOAD_REQUIRED_DESCRIPTION": "Voor deze vorm van afwezigheid moet je een bestand uploaden voordat het wordt ingediend of goedgekeurd."
    },
    "VIEW_MODAL": {
      "TITLE": "Afwezigheid",
      "BUTTON_OPTIONS": "Opties",
      "BUTTON_EDIT": "Boeking bewerken/beoordelen",
      "BUTTON_CANCEL": "Boeking annuleren",
      "BUTTON_CLOSE": "Sluiten",
      "STATUS_CANCELLED": "Geannuleerd",
      "LABEL_START_DATE": "Begint",
      "LABEL_END_DATE": "Eindigt",
      "LABEL_ABSENCE_TYPE": "Typ",
      "LABEL_SCOPE": "Duur",
      "ERROR_500": "We stuiten op een probleempje. Probeer het opnieuw.",
      "ERROR_404": "Dat afwezigheidsverzoek bestaat niet meer.",
      "LABEL_SUBMITTED_BY": "Ingezonden door",
      "LABEL_REVIEWED_BY": "Beoordeeld door",
      "LABEL_CANCELLED_BY": "Geannuleerd door",
      "LABEL_EDITED_BY": "Bewerkt door",
      "DURATION_TEXT": "{{uren}} uren, {{dagen}} dagen",
      "TAB_BREAKDOWN": "Indeling",
      "TITLE_PAY_PERIODS": "Per betaalperiode",
      "NO_PAY_PERIODS": "De werknemer was niet gekoppeld aan een salariskalender toen deze boeking werd gemaakt.",
      "TITLE_DAILY_BREAKDOWN": "Overdag",
      "NO_DAYS": "Er zijn geen dagen in deze boeking.",
      "TAB_FILES": "Documenten",
      "FILES_NONE": "Er is niets geüpload voor deze boeking.",
      "FILES_AVAILABLE": "Deze documenten zijn ook te vinden in het gedeelte Personeel wanneer u het profiel van de werknemer bekijkt. Je vindt ze onder deze categorie: {{categoryName}}",
      "FILES_COLUMN_NAME": "Naam",
      "FILES_COLUMN_UPLOADED_BY": "Geüpload door",
      "FILES_COLUMN_OPTIONS": "Opties",
      "FILES_LINK_DELETE": "Verwijderen",
      "FILES_CONFIRM_DELETE": "Weet je zeker dat je dit wilt verwijderen? Het wordt ook verwijderd uit het tabblad Documenten van het profiel van de werknemer.",
      "CONFIRM_CANCEL": "Weet je zeker dat je deze afwezigheidsboeking wilt annuleren? Dit kan niet ongedaan worden gemaakt.",
      "ALERT_IS_CANCELLED": "De afwezigheidsboeking is geannuleerd.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Waarschuwing! De aanvrager heeft een begin- en eindtijd gekozen die gelijk is aan meer dan {{HalfDayHours}} uur, het aantal uren dat hij momenteel een halve dag werkt.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Waarschuwing! De aanvrager heeft ervoor gekozen om de reguliere vrije dagen te wijzigen in plaats van de door Rotaready voorgestelde dagen te gebruiken."
    },
    "EDIT": {
      "TITLE": "Afwezigheid",
      "BUTTON_SUBMIT": "Opslaan",
      "BUTTON_APPROVE": "Goedkeuren",
      "BUTTON_REJECT": "Weigeren",
      "TAB_EDIT_BOOKING": "Datums bewerken",
      "TAB_REVIEW": "Recensie",
      "TAB_FILES": "Documenten",
      "TAB_CALENDAR": "Agenda",
      "LABEL_TYPE": "Typ",
      "LABEL_REASON": "Selecteer een reden",
      "LABEL_EMPLOYEE": "Werknemer",
      "LABEL_START_DATE": "Startdatum",
      "LABEL_END_DATE": "Einddatum",
      "LABEL_SCOPE_ALL_DAY": "De hele dag",
      "LABEL_SCOPE_AM": "Alleen AM",
      "LABEL_SCOPE_PM": "Alleen in de PM",
      "LABEL_SCOPE_CUSTOM": "Kies specifieke uren",
      "LABEL_START_TIME": "Starttijd",
      "LABEL_END_TIME": "Eindtijd",
      "ERROR_500": "We stuitten op dat moment op een probleem. Probeer het opnieuw!",
      "LABEL_DAYS": "dagen",
      "LABEL_HOURS": "uren",
      "SECTION_UNPAID_DAYS": "Reguliere vrije dagen",
      "HELP_TEXT_NO_UNPAID_DAYS": "Er zijn geen normale vrije dagen in dit verzoek.",
      "BUTTON_OPTIONS": "Opties",
      "BUTTON_CHANGE": "Reguliere vrije dagen wijzigen",
      "BUTTON_RESET": "Terug naar oorspronkelijk geboekt",
      "BUTTON_RECALCULATE": "Opnieuw berekenen",
      "BUTTON_CLEAR": "Alle reguliere vrije dagen vrijmaken",
      "UNPAID_DAYS_RECALCULATED_LABEL": "Herberekend",
      "UNPAID_DAYS_RECALCULATED_HELP": "Je hebt ervoor gekozen om de reguliere vrije dagen opnieuw te berekenen en de geldende waarde te gebruiken voor de 'uren per werkdag' van de werknemer in plaats van wat het was toen de boeking oorspronkelijk werd gemaakt.",
      "UNPAID_DAYS_MERGED_LABEL": "Samengevoegd",
      "UNPAID_DAYS_MERGED_HELP": "Omdat je de datum hebt gewijzigd, hebben we alle nieuwe vrije dagen toegevoegd, alle dagen verwijderd die niet meer van toepassing zijn en de andere dagen onaangeroerd gelaten.",
      "MODAL_CALENDAR_TITLE": "Kies normale vrije dagen",
      "MODAL_BUTTON_SAVE": "Opslaan",
      "BUTTON_COMPLETE": "Breng me terug",
      "COMPLETE_TITLE": "Je bent klaar om te gaan.",
      "COMPLETE_TEXT": "We hebben de afwezigheidsboeking opgeslagen en de relevante personen zijn op de hoogte gebracht 👍",
      "INVALID_HEADER_TITLE": "Ongeldige afwezigheid",
      "INVALID_HEADER_TEXT": "We kunnen die afwezigheidsboeking niet laden. Misschien is het geannuleerd of heb je geen toestemming om het te zien.",
      "TITLE_OVERLAPPING_ABSENCE": "Gelijktijdige afwezigheid",
      "TITLE_OVERLAPPING_SHIFTS": "Overlappende diensten",
      "TITLE_NOTABLE_EVENTS": "Opmerkelijke gebeurtenissen",
      "COLUMN_OVERLAPPING_ABSENCE_DATES": "Datums",
      "COLUMN_OVERLAPPING_ABSENCE_TYPE": "Typ",
      "COLUMN_OVERLAPPING_ABSENCE_WHO": "Wie",
      "NO_OVERLAPPING_ABSENCE": "Er is geen gelijktijdige afwezigheid die overeenkomt met je filters.",
      "NO_OVERLAPPING_SHIFTS": "Er zijn geen overlappende diensten op de locatie/afdeling van de werknemer.",
      "NO_NOTABLE_EVENTS": "Op het moment van deze afwezigheid zijn er geen noemenswaardige gebeurtenissen geweest.",
      "COLUMN_OVERLAPPING_SHIFTS_DATE": "Datum",
      "COLUMN_OVERLAPPING_SHIFTS_SITE": "Site/afdeling",
      "COLUMN_OVERLAPPING_SHIFTS_TYPE": "Soort ploegendienst",
      "COLUMN_OVERLAPPING_SHIFTS_OPTIONS": "Opties",
      "LINK_EDIT_SHIFT": "Rota bewerken",
      "LINK_DELETE_SHIFT": "Verwijderen",
      "BUTTON_BULK_SHIFT_OPTIONS": "Bulkacties",
      "BUTTON_BULK_SHIFT_UNASSIGN_ALL": "De toewijzing van geselecteerde items ongedaan maken",
      "BUTTON_BULK_SHIFT_DELETE_ALL": "Geselecteerde verwijderen",
      "ALERT_BULK_SHIFT_CONFIRM": "Weet je zeker dat je dit wilt doen om ploegendiensten te {{count}}?",
      "ALERT_BULK_SHIFT_EDIT_FAILURE": "We konden de verschuivingen niet aanpassen omdat dat een validatieprobleem zou veroorzaken. Gelieve deze wijziging op het rooster aan te brengen voor meer informatie.",
      "ALERT_SINGLE_SHIFT_DELETE_FAILURE": "We konden die verschuiving niet verwijderen omdat dat een validatieprobleem zou veroorzaken. Gelieve deze wijziging op het rooster aan te brengen voor meer informatie.",
      "COLUMN_NOTABLE_EVENTS_NAME": "Naam",
      "COLUMN_NOTABLE_EVENTS_DATE": "Datum",
      "NOTABLE_EVENTS_ABSENCE_RESTRICTION_TOOLTIP": "Tijdens dit evenement is de afwezigheid beperkt.",
      "LABEL_ADD_A_NOTE": "Schrijf eventueel een bericht voor de medewerker",
      "OVERLAPPING_SHIFTS_MORE": "We tonen alleen de eerste vijftien overlappende verschuivingen. Los deze op om meer te zien.",
      "OVERLAPPING_ABSENCE_MORE": "We tonen alleen de eerste vijftien overlappende boekingen.",
      "CALENDAR_UNPAID_DAY": "Reguliere vrije dag",
      "FILTER_LABEL_ENTITY": "Site/afdeling",
      "FILTER_LABEL_STAFF_GROUP": "Personeelsgroep",
      "FILTER_LABEL_ABSENCE_TYPE": "Typ",
      "FILES_NONE": "Er is niets geüpload voor deze boeking.",
      "FILES_AVAILABLE": "Deze documenten zijn ook te vinden in het gedeelte Personeel wanneer u het profiel van de werknemer bekijkt. Je vindt ze onder deze categorie: {{categoryName}}",
      "FILES_COLUMN_NAME": "Naam",
      "FILES_COLUMN_UPLOADED_BY": "Geüpload door",
      "FILES_COLUMN_OPTIONS": "Opties",
      "FILES_LINK_DELETE": "Verwijderen",
      "FILES_CONFIRM_DELETE": "Weet je zeker dat je dit wilt verwijderen? Het wordt ook verwijderd uit het tabblad Documenten van het profiel van de werknemer.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Waarschuwing! De aanvrager heeft een begin- en eindtijd gekozen die gelijk is aan meer dan {{HalfDayHours}} uur, het aantal uren dat hij momenteel een halve dag werkt.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Waarschuwing! De aanvrager heeft ervoor gekozen om de reguliere vrije dagen te wijzigen in plaats van de door Rotaready voorgestelde dagen te gebruiken. Controleer ze zorgvuldig op het tabblad Datums bewerken en kies indien nodig de optie Opnieuw berekenen."
    },
    "ENUM_SCOPE": {
      "ALL_DAY": "De hele dag",
      "AM": "Alleen AM",
      "PM": "Alleen in de PM",
      "CUSTOM": "Aangepaste uren"
    },
    "ENUM_APPROVAL_STATE": {
      "APPROVED": "Goedgekeurd",
      "REJECTED": "Afgewezen",
      "PENDING": "In afwachting"
    },
    "ENUM_ALLOWANCE_UNIT": {
      "DAYS": "Dagen",
      "HOURS": "Uren"
    },
    "ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL": {
      "BEGINNING_OF_ACCRUAL_YEAR": "Begin van het opbouwjaar",
      "EACH_CALENDAR_MONTH": "Elke kalendermaand",
      "EACH_HOUR_WORKED": "Elk gewerkt uur"
    },
    "ALLOWANCE_IMPACT_DIRECTIVE": {
      "DAYS_DEBIT": "{{count}} dagaftrek",
      "HOURS_DEBIT": "{{count}} uuraftrek",
      "DAYS_CREDIT": "{{count}} dagkrediet",
      "HOURS_CREDIT": "{{count}} uurkrediet",
      "REMAINING": "({{count}} resterend)",
      "WARNING_EXCEEDS": "Uw aanvraag overschrijdt deze vergoeding, maar kan nog steeds worden ingediend.",
      "WARNING_NOT_PERMITTED": "Uw aanvraag overschrijdt deze vergoeding.",
      "TOOLTIP_PROJECTED": "Rotaready heeft een voorspelling toegevoegd van wat u waarschijnlijk zult opbouwen. Dit kan beperkt zijn volgens het beleid van uw werkgever.",
      "CALC_TOTAL_ACCRUED": "Totaal opgebouwde",
      "CALC_BOOKED": "Geboekt",
      "CALC_PROJECTED": "Voorspelde opbouw",
      "CALC_THIS_REQUEST": "Deze aanvraag",
      "CALC_REMAINING": "Resterend",
      "CALC_CONVERTED_TO_DAYS": "Zoals dagen"
    }
  }
};

module.exports = translations;
