const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/shiftTypes/editShiftTypeModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/shiftTypes/editShiftTypeModal.html');

'use strict';
var controllers = require('../../module');

controllers.controller('ShiftTypesRmCtrl', ['$scope', '$uibModal', '$state', '$translate', 'RotaService', 'AlertService',
    function ($scope, $uibModal, $state, $translate, RotaService, AlertService) {
        var textById = $translate.instant([
            'ROTA_MANAGEMENT.SHIFT_TYPES.INDEX.LOAD_ERROR',
        ]);

        function refreshShiftTypes() {
            $scope.dataLoaded = false;

            RotaService.getShiftTypes()
                .success(function (data) {
                    $scope.shiftTypes = data.shiftTypes;
                    $scope.dataLoaded = true;
                })
                .error(function (err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', textById['ROTA_MANAGEMENT.SHIFT_TYPES.INDEX.LOAD_ERROR']);
                    }

                    $state.go('rota.overview');
                });
        }

        refreshShiftTypes();

        $scope.addShiftType = function (parentShiftType, $event) {
            if ($event) {
                // Prevent bubbling up to editShiftType()
                $event.stopPropagation();
                $event.preventDefault();
            }

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'EditShiftTypeModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            shiftType: parentShiftType,
                            isAdd: true
                        }
                    }
                }
            });

            modalInstance.result.then(function () {
                refreshShiftTypes();
            });
        };

        $scope.editShiftType = function (shiftType) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'EditShiftTypeModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            shiftType: shiftType,
                            isAdd: false
                        }
                    }
                }
            });

            modalInstance.result.then(function () {
                refreshShiftTypes();
            });
        };
    }]);
