const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/payRules/editPayRateModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/payRules/editPayRateModal.html');

var controllers = require('../../module');

controllers.controller('SettingsEditPayRuleModalCtrl', ['$scope', '$uibModalInstance', '$uibModal', 'SettingsService', 'EnvironmentDataService', 'AlertService', 'data',
    function ($scope, $uibModalInstance, $uibModal, SettingsService, EnvironmentDataService, AlertService, data) {
        $scope.saveActioning = false;
        $scope.dataLoaded = false;
        $scope.elementTypeNamesById = {};
        $scope.isAdd = data.isAdd;
        $scope.canEdit = data.canEdit;

        $scope.daysOfWeek = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ];

      $scope.masterShiftTypeList = [];
      const seenShiftIds = [];

      function buildSortedShiftTypeList(masterList, filterByParentId) {
        const shiftTypes = filterByParentId
          ? masterList.filter((item) => item.parentId === filterByParentId)
          : masterList;

        shiftTypes.forEach((shiftType) => {
          const { id } = shiftType;
          if (seenShiftIds.includes(id)) {
            return;
          }

          $scope.masterShiftTypeList.push(shiftType);
          seenShiftIds.push(id);
          buildSortedShiftTypeList(masterList, id);
        });
      }

      EnvironmentDataService.fetchAll([
        EnvironmentDataService.DataType.EntityGroup,
        EnvironmentDataService.DataType.ShiftType,
        EnvironmentDataService.DataType.PayElementType,
      ])
        .then(([
          entityGroup,
          shiftType,
          payElementType,
        ]) => {
          $scope.entities = entityGroup.data
            .flatMap(({
              id: groupId,
              name,
              entities,
            }) => ([
              {
                id: groupId,
                label: name,
                depth: 0,
              },
              ...entities.map((entity) => ({
                id: entity.id,
                label: entity.name,
                parentId: entity.groupId,
                depth: 1,
              })),
            ]));

          $scope.entityIds = entityGroup.data.flatMap((group) => group.entities.map(({ id }) => id));

          payElementType.data.forEach((t) => {
              $scope.elementTypeNamesById[t.id] = t.name;
          });

          const shiftTypeList = shiftType.data.map((shiftType) => ({
            ...shiftType,
            label: shiftType.name,
          }));
          shiftTypeList.sort((a, b) => a.depth - b.depth);
          buildSortedShiftTypeList(shiftTypeList);

          if (!data.isAdd) {
            var rule = data.rule;

            $scope.formData = {
              name: rule.name,
              ruleType: rule.ruleType,
              entityIds: rule.captureProperties.entityIds.length ? rule.captureProperties.entityIds : $scope.entityIds,
              daysOfWeek: [false, false, false, false, false, false, false],
              filterDate: !!rule.captureProperties.dates.length,
              dates: [],
              filterTime: !!rule.captureProperties.times.length,
              times: [],
              shiftTypeIds: rule.captureProperties.shiftTypeIds,
              requiresApproval: rule.approvalSettings.requiresApproval,
              clockInAutoApprovalThreshold: rule.approvalSettings.clockInAutoApprovalThreshold || 0,
              clockOutAutoApprovalThreshold: rule.approvalSettings.clockOutAutoApprovalThreshold || 0,
              rates: [],
              displayOrder: rule.displayOrder,
              thresholdType: rule.thresholdType,
              thresholdValue: rule.thresholdValue,
              thresholdAllocation: rule.thresholdAllocation,
              thresholdIncludeAbsence: rule.thresholdIncludeAbsence,
            };

            $scope.onEntityFilter($scope.formData.entityIds);

            rule.captureProperties.dates.forEach(function (date) {
              $scope.formData.dates.push(moment(date, 'YYYY-MM-DD'));
            });

            rule.captureProperties.times.forEach(function (time) {
              $scope.formData.times.push({ start: moment(time.start, 'HH:mm:ss'), end: moment(time.end, 'HH:mm:ss') });
            });

            rule.captureProperties.daysOfWeek.forEach(function (index) {
              $scope.formData.daysOfWeek[index] = true;
            });

            rule.payRates.forEach(function (rate) {
              if (rate.capTypeId === 2) {
                rate.timeCapStart = moment(rate.timeCapStart, 'HH:mm:ss');
                rate.timeCapEnd = moment(rate.timeCapEnd, 'HH:mm;ss');
              }

              $scope.formData.rates.push(rate);
            });
          } else {
            $scope.formData = {
              daysOfWeek: [true, true, true, true, true, true, true],
              entityIds: [],
              dates: [],
              times: [],
              shiftTypeIds: [],
              requiresApproval: false,
              clockInAutoApprovalThreshold: 0,
              clockOutAutoApprovalThreshold: 0,
              rates: [],
              displayOrder: data.displayOrder,
              ruleType: 'SHIFT_SPECIFIC',
            };
          }

          $scope.formData.currentDate = moment();
          $scope.formData.currentStart = moment('09:00:00', 'HH:mm:ss');
          $scope.formData.currentEnd = moment('17:00:00', 'HH:mm:ss');

          $scope.dataLoaded = true;
        });

      $scope.onEntityFilter = (selectedOptions) => {
        const entityIds = selectedOptions.filter((o) => typeof o === 'string');
        $scope.formData.entityIds = entityIds;

        $scope.shiftTypes = $scope.masterShiftTypeList
          .filter((t) => !t.deleted && (!t.entityId || entityIds.includes(t.entityId)));

        const validShiftTypeIds = $scope.shiftTypes.map(({ id }) => id);

        $scope.formData.shiftTypeIds = $scope.formData.shiftTypeIds
          .filter((id) => validShiftTypeIds.includes(id));

        if (!$scope.isAdd && !$scope.formData.shiftTypeIds.length) {
          $scope.formData.shiftTypeIds = validShiftTypeIds;
        }
      };

      $scope.onShiftTypeFilter = (selectedOptions) => {
        $scope.formData.shiftTypeIds = selectedOptions;
      };

        $scope.addDate = function () {
            $scope.formData.dates.push($scope.formData.currentDate.clone());
        }

        $scope.deleteDate = function (index) {
            $scope.formData.dates.splice(index, 1);

            if (!$scope.formData.dates.length) {
                $scope.formData.filterDate = false;
            }
        };

        $scope.addTime = function () {
            $scope.formData.times.push({
                start: $scope.formData.currentStart.clone(),
                end: $scope.formData.currentEnd.clone(),
            });
        };

        $scope.deleteTime = function (index) {
            $scope.formData.times.splice(index, 1);

            if (!$scope.formData.times.length) {
                $scope.formData.filterTime = false;
            }
        }

        $scope.timeFilterChanged = function () {
            $scope.formData.currentStart = moment('09:00:00', 'HH:mm:ss');
            $scope.formData.currentEnd = moment('17:00:00', 'HH:mm:ss');
            $scope.formData.times = [];
        };

        $scope.dateFilterChanged = function () {
            $scope.formData.currentDate = moment();
            $scope.formData.dates = [];
        };

        $scope.ruleTypeChanged = function () {
            $scope.formData.rates = [];
        };

        $scope.editPayRate = function (index) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SettingsEditPayRateModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: false,
                            rate: $scope.formData.rates[index],
                            rates: $scope.formData.rates,
                        };
                    }
                }
            });

            modalInstance.result.then(function (rate) {
                $scope.formData.rates.splice(index, 1, rate);
            });
        };

        $scope.addPayRate = function () {
            var ratesCount = $scope.formData.rates.length;

            if (ratesCount === 2 || (ratesCount === 1 && $scope.formData.ruleType === 'THRESHOLD')) {
                return;
            }

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'SettingsEditPayRateModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: true,
                            rates: $scope.formData.rates,
                            ruleType: $scope.formData.ruleType,
                        };
                    }
                }
            });

            modalInstance.result.then(function (rate) {
                $scope.formData.rates.push(rate);
            });
        };

        $scope.deletePayRate = function (index) {
            $scope.formData.rates.splice(index, 1);
        };

        $scope.close = function () {
            $uibModalInstance.dismiss();
        };

        $scope.save = function () {
            if (!$scope.canEdit) {
                return;
            }

            var daysOfWeek = [];

            $scope.formData.daysOfWeek.forEach(function (active, index) {
                if (active) {
                    daysOfWeek.push(index);
                }
            });

            if (!$scope.formData.entityIds.length || !$scope.formData.rates.length) {
                return;
            }

            if ($scope.formData.ruleType === 'THRESHOLD') {
                 var thresholdType = parseInt($scope.formData.thresholdType, 10);
                 var thresholdAllocation = parseInt($scope.formData.thresholdAllocation, 10);

                 if (Number.isNaN(thresholdType) || Number.isNaN(thresholdAllocation)) {
                     return;
                 }

                 if (thresholdType !== 4 && !$scope.formData.thresholdValue) {
                     return;
                 }
            } else {
                if (!daysOfWeek.length) {
                    return;
                }
            }

            var fields = {
                name: $scope.formData.name,
                ruleType: $scope.formData.ruleType,
                thresholdType: $scope.formData.thresholdType,
                thresholdValue: $scope.formData.thresholdValue || null,
                thresholdAllocation: $scope.formData.thresholdAllocation,
                thresholdIncludeAbsence: !!$scope.formData.thresholdIncludeAbsence,
                captureProperties: {
                    entityIds: $scope.formData.entityIds.length === $scope.entityIds.length ? [] : $scope.formData.entityIds,
                    daysOfWeek: daysOfWeek,
                    dates: $scope.formData.filterDate ?
                        $scope.formData.dates.map(function (date) { return date.format('YYYY-MM-DD') }) : [],
                    times: $scope.formData.filterTime ?
                        $scope.formData.times.map(function (time) {
                            return { start: time.start.format('HH:mm:ss'), end: time.end.format('HH:mm:ss') }
                        }) : [],
                    shiftTypeIds: $scope.formData.shiftTypeIds.length === $scope.shiftTypes.length ?
                        [] : $scope.formData.shiftTypeIds,
                },
                approvalSettings: {
                    requiresApproval: $scope.formData.requiresApproval,
                    clockInAutoApprovalThreshold: $scope.formData.requiresApproval ?
                        $scope.formData.clockInAutoApprovalThreshold : 0,
                    clockOutAutoApprovalThreshold: $scope.formData.requiresApproval ?
                        $scope.formData.clockOutAutoApprovalThreshold : 0,
                },
                payRates: [],
                displayOrder: $scope.formData.displayOrder,
            };

            $scope.formData.rates.forEach(function (rate) {
                fields.payRates.push({
                    typeId: rate.typeId,
                    elementTypeId: rate.elementTypeId,
                    capTypeId: rate.capTypeId,
                    timeCapStart: rate.capTypeId === 2 ? rate.timeCapStart.format('HH:mm:ss') : null,
                    timeCapEnd: rate.capTypeId === 2 ? rate.timeCapEnd.format('HH:mm:ss'): null,
                    minuteCap: rate.capTypeId === 3 ? rate.minuteCap : null,
                    isScheduled: rate.isScheduled,
                });
            });

            $scope.saveActioning = true;

            if (data.isAdd) {
                SettingsService.addPayRule(data.groupId, fields)
                    .success(function () {
                        $uibModalInstance.close();
                    })
                    .error(function (err, status) {
                        if (status !== 403) {
                            AlertService.add('danger', 'We can\'t add the pay rule. Please try again.');
                        }

                        $scope.saveActioning = false;
                    });
            } else {
                SettingsService.updatePayRule(data.groupId, data.rule.id, fields)
                    .success(function () {
                        $uibModalInstance.close();
                    })
                    .error(function (err, status) {
                        if (status !== 403) {
                            AlertService.add('danger', 'We can\'t edit the pay rule. Please try again.');
                        }

                        $scope.saveActioning = false;
                    });
            }
        };

        $scope.delete = function () {
            if (!$scope.canEdit) {
                return;
            }

            if (!confirm("Are you sure you want to delete this pay rule?")) {
                return;
            }

            SettingsService.deletePayRule(data.groupId, data.rule.id)
                .success(function () {
                    $uibModalInstance.close();
                })
                .error(function (err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', 'We weren\'t able to delete the pay rule group then. Please try again.');
                    }

                    $uibModalInstance.dismiss();
                });
        };

        $scope.getRateDescription = function (rate) {
            var type = rate.typeId;
            var elementType = rate.elementTypeId;

            switch (type) {
                default:
                case 0:
                    return $scope.elementTypeNamesById[elementType];
                case 1:
                    return 'Zero';
            }
        };
    }
]);
