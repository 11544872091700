const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/hierarchyItemPicker.html');

var directives = require('./module');

directives.directive('rrHierarchyItemPicker', ['$translate',
    function ($translate) {
        return {
            restrict: 'E',
            templateUrl: autoNgTemplateLoaderTemplate1,
            scope: {
                items: '=?',
                itemDescriptor: '@',
                selectedItem: '=?',
                onChange: '&',
                disabledIds: '=?',
                asTypeahead: '=?',
                filters: '=?',
                uniqueId: '@',
            },
            link: function ($scope, element, attrs) {
                $scope.tree = [];
                $scope.itemsById = {};

                // The typeahead directive is bound using ng-if to this value
                // Without this, if we have a value for selectedItem the typeahead doesn't show the selected item's
                // name when it first loads...
                // https://github.com/angular-ui/bootstrap/issues/981
                // https://github.com/angular-ui/bootstrap/issues/2160
                $scope.isReady = false;

                var itemsClone;
                $scope.appliedFilterId = false;
                var isFirstRun = true;

                $scope.selectItem = function (item) {
                    if (item.disabled) {
                        return;
                    }

                    $scope.selectedItem = item.id;
                    $scope.onChange();
                };

                $scope.placeholder = $translate.instant('DIRECTIVES.HIERARCHY_ITEM_PICKER.PLACEHOLDER',
                { itemDescriptor: $scope.itemDescriptor });

                function setSelectedItemName() {
                    if (!$scope.isReady) {
                        return;
                    }

                    var id = $scope.selectedItem;

                    if (!id) {
                        return;
                    }

                    var parts = concatenateName(id, []);

                    // Shorten the name if it has over 3 items in the hierarchy
                    if (parts.length > 3) {
                        parts = [parts[0], '...', parts[parts.length - 1]];
                    }

                    $scope.selectedItemName = parts.reverse().join(' \u2192 ');
                    $scope.selectedItem = id;
                    $scope.selectedItemRemoved = false;
                }

                function concatenateName(id, parts) {
                    var found = false;

                    angular.forEach($scope.items, function (item) {
                        if (item.id === id) {
                            found = item;
                        }
                    });

                    if (!found) {
                        return parts;
                    }

                    parts.push(found.name);

                    if (found.parentId) {
                        return concatenateName(found.parentId, parts);
                    }

                    return parts;
                }

                function disableItem(item) {
                    item.disabled = true;

                    if (item.children && item.children.length) {
                        angular.forEach(item.children, function (child) {
                            disableItem(child);
                        });
                    }
                }

                function disableItems() {
                    if (!$scope.isReady) {
                        return;
                    }

                    Object.keys($scope.itemsById).forEach(function (id) {
                        $scope.itemsById[id].disabled = false;
                    });

                    if ($scope.disabledIds && $scope.disabledIds.length) {
                        angular.forEach($scope.disabledIds, function (id) {
                            if ($scope.itemsById.hasOwnProperty(id)) {
                                disableItem($scope.itemsById[id]);
                            }
                        });
                    }
                }

                $scope.$watch('items', function (items) {
                    if (!items || !items.length) {
                        return;
                    }

                    var firstRun = isFirstRun;
                    isFirstRun = false;

                    if (firstRun && $scope.filters && $scope.filters.length) {
                        // If we have any filters, pass the items array to each filter's
                        // applyAutomatically function. The first one that returns true should
                        // get applied. Go no further as the filter will assign a new array to
                        // $scope.items which will trigger this $watch again.
                        var filter = $scope.filters.find(function (filter) {
                            return filter.applyAutomatically && filter.applyAutomatically(items);
                        });

                        if (filter) {
                            return $scope.applyFilter(filter.id);
                        }
                    }

                    // Build a map of each item by ID, and set any properties we need
                    $scope.itemsById = {};

                    angular.forEach(items, function (item) {
                        item.children = [];
                        $scope.itemsById[item.id] = item;
                    });

                    // Build a hierarchical tree, and a flat list
                    $scope.tree = [];
                    $scope.flattenedList = [];

                    Object.keys($scope.itemsById).forEach(function (id) {
                        var item = $scope.itemsById[id];

                        if (item.depth !== 0 && $scope.itemsById.hasOwnProperty(item.parentId)) {
                            $scope.itemsById[item.parentId].children.push(item);
                        } else {
                            $scope.tree.push(item);
                        }

                        $scope.flattenedList.push(item);
                    });

                    $scope.isReady = true;

                    disableItems();
                    setSelectedItemName();
                });

                $scope.$watch('disabledIds', function () {
                    disableItems();
                });

                $scope.$watch('selectedItem', function () {
                    setSelectedItemName();
                });

                function runAppliedFilter() {
                    // If no filter has been applied, go no further
                    if (!$scope.appliedFilterId) {
                        return;
                    }

                    // Find the filter, be sure it exists and has an apply() method
                    var filter = $scope.filters.find(function (filter) {
                        return filter.id === $scope.appliedFilterId;
                    });

                    if (!filter || !filter.apply) {
                        return;
                    }

                    // The first time a filter is applied, clone the master list
                    // so we can later reset it (after overwriting it!)
                    if (!itemsClone) {
                        itemsClone = angular.copy($scope.items);
                    }

                    $scope.isReady = false;

                    filter.apply(function (err, filteredList) {
                        if (err) {
                            alert('We couldn\'t apply the filter. You may have to try again.');

                            $scope.isReady = true;
                            return;
                        }

                        $scope.items = filteredList;

                        // De-select the currently selected item if it no longer exists
                        // in the filtered list
                        if ($scope.selectedItem) {
                            var hasSelectedItem = filteredList.find(function (item) {
                                return item.id === $scope.selectedItem;
                            });

                            if (!hasSelectedItem) {
                                $scope.selectedItem = null;
                                $scope.selectedItemRemoved = true;
                            }
                        }
                    });
                }

                $scope.applyFilter = function (id) {
                    $scope.appliedFilterId = id;
                    runAppliedFilter();
                };

                $scope.$on('directive-hierarchyItemPicker', function (event, payload) {
                    if (!payload || !payload.id || payload.id !== $scope.uniqueId) {
                        return;
                    }

                    switch (payload.action) {
                        case 1:
                            runAppliedFilter();
                            break;
                    }
                });

                $scope.resetFilter = function () {
                    $scope.items = angular.copy(itemsClone);
                    $scope.appliedFilterId = false;
                    $scope.selectedItemRemoved = false;
                };
            }
        };
    }]);
