const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/filterNumberButton.html');

const directives = require('./module');

directives.directive('rrFilterNumberButton', (
  $translate,
  $timeout,
) => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      onSelect: '&',
      readOnly: '=?',
      defaultState: '=?',
      labelPrefix: '@',
      labelSuffix: '@',
      icon: '@?',
      borderless: '@?',
      validOptions: '@?',
    },
    link: ($scope, element) => {
      const translations = $translate.instant([
        'DIRECTIVES.FILTER_NUMBER_BUTTON.ANY',
        'DIRECTIVES.FILTER_NUMBER_BUTTON.GREATER',
        'DIRECTIVES.FILTER_NUMBER_BUTTON.LESS',
        'DIRECTIVES.FILTER_NUMBER_BUTTON.EXACTLY',
      ]);

      const options = ['any', 'greaterThan', 'lessThan', 'exactly'];
      let hasMenuOpenedOnce = false;

      $scope.props = {
        displayText: '',
        isEnabled: true,
        isMenuOpen: false,
        icon: $scope.icon,
        selectedUser: undefined,
        state: {
          option: 'any',
        },
        validOptions: $scope.validOptions || options,
      };

      function evaluateEnabledState() {
        $scope.props.isEnabled = !$scope.readOnly;
      }

      function updateDisplayText() {
        const {
          option,
          value,
        } = $scope.props.state;

        const sentence = [$scope.labelPrefix];

        if (option === 'any') {
          sentence.push(
            translations['DIRECTIVES.FILTER_NUMBER_BUTTON.ANY'],
            $scope.labelSuffix,
          );
        } else if (option === 'greaterThan') {
          sentence.push(
            translations['DIRECTIVES.FILTER_NUMBER_BUTTON.GREATER'],
            value,
            $scope.labelSuffix,
          );
        } else if (option === 'lessThan') {
          sentence.push(
            translations['DIRECTIVES.FILTER_NUMBER_BUTTON.LESS'],
            value,
            $scope.labelSuffix,
          );
        } else if (option === 'exactly') {
          sentence.push(
            translations['DIRECTIVES.FILTER_NUMBER_BUTTON.EXACTLY'],
            value,
            $scope.labelSuffix,
          );
        }

        $scope.props.displayText = sentence.join(' ');
      }

      $scope.$watch('readOnly', (val) => {
        if (val === undefined) {
          return;
        }

        evaluateEnabledState();
      });

      $scope.$watch('defaultState', (val) => {
        if (val === undefined || val === null || typeof val !== 'object') {
          return;
        }

        if (val.option && options.includes(val.option)) {
          $scope.props.state.option = val.option;
        }

        if (val.value && !Number.isNaN(parseInt(val.value, 10))) {
          $scope.props.state.value = parseInt(val.value, 10);
        }

        updateDisplayText();
      });

      function onFinish() {
        const obj = {
          state: $scope.props.state,
        };

        if ($scope.onSelect) {
          $scope.onSelect(obj);
        }
      }

      $scope.onElementClick = () => {
        const inputElements = element.find('input');
        const count = inputElements.length;

        if (!count) {
          return;
        }

        const inputElement = angular.element(element.find('input')[count - 1]);

        $timeout(() => {
          inputElement.focus();
        }, 100);
      };

      function onUserInput() {
        const { value } = $scope.props.state;

        const valueAsInteger = parseInt(value, 10);

        if (value === null || value === undefined || value === '' || Number.isNaN(valueAsInteger)) {
          $scope.props.state = {
            option: 'any',
            value: '',
          };
        }

        updateDisplayText();
        onFinish();
      }

      $scope.$watch('props.isMenuOpen', (val) => {
        if (val === true) {
          hasMenuOpenedOnce = true;
          return;
        }

        if (val === false && hasMenuOpenedOnce) {
          onUserInput();
        }
      });

      $scope.onInputKeyUp = ($event) => {
        if ($event.keyCode === 13) {
          $scope.props.isMenuOpen = false;
        }
      };

      evaluateEnabledState();
      updateDisplayText();
    },
  };
});
