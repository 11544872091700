module.exports = (
  $scope,
  $uibModalInstance,
  $translate,
  EnvironmentDataService,
  AlertService,
  StaffService,
  StaffCommon,
) => {
  'ngInject';

  const translations = $translate.instant([
    'SETTINGS.DOCUMENT_TEMPLATES.UPLOAD_MODAL.ALERT_SUCCESS',
    'SETTINGS.DOCUMENT_TEMPLATES.UPLOAD_MODAL.ALERT_ERROR_500',
    'SETTINGS.DOCUMENT_TEMPLATES.UPLOAD_MODAL.ALERT_ERROR_400',
  ]);

  $scope.props = {
    actionInProgress: true,
    permittedExtensions: ['.docx'],
    templateCategoryList: [],
    documentCategoryList: [],
    formData: {
      employerSignatureRequired: false,
    },
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.DocumentTemplateCategory,
    EnvironmentDataService.DataType.DocumentCategory,
  ])
    .then(([
      templateCategory,
      documentCategory,
    ]) => {
      $scope.props.templateCategoryList = templateCategory.data
        .map((category) => ({ ...category, label: category.name }));

      $scope.props.documentCategoryList = documentCategory.data
        .filter(({ sensitive }) => {
          if (sensitive && !StaffCommon.canAccessSensitiveDocuments()) {
            return false;
          }

          return true;
        })
        .map((category) => ({ ...category, label: category.name }));

      $scope.props.actionInProgress = false;
    });

  $scope.close = () => {
    $uibModalInstance.dismiss();
  };

  $scope.onTemplateCategorySelect = (option) => {
    $scope.props.selectedTemplateCategory = option;
  };

  $scope.onDocumentCategorySelect = (option) => {
    $scope.props.selectedDocumentCategory = option;
  };

  $scope.onFileUpload = (file) => {
    $scope.props.file = file;
  };

  $scope.onFileRemoved = () => {
    $scope.props.file = undefined;
  };

  $scope.submit = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    const {
      file,
      selectedTemplateCategory,
      selectedDocumentCategory,
    } = $scope.props;

    const {
      name,
      employerSignatureRequired,
    } = $scope.props.formData;

    if (!name || !file || !selectedTemplateCategory || !selectedDocumentCategory) {
      return;
    }

    const body = {
      name,
      categoryId: selectedTemplateCategory.id,
      key: file.key,
      fileName: file.name,
      destinationDocumentCategoryId: selectedDocumentCategory.id,
      employerSignatureRequired,
    };

    $scope.props.actionInProgress = true;

    StaffService.addDocumentTemplate(body)
      .then(({ data }) => {
        $scope.props.actionInProgress = false;

        AlertService.add('success', translations['SETTINGS.DOCUMENT_TEMPLATES.UPLOAD_MODAL.ALERT_SUCCESS']);
        $uibModalInstance.close(data);
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['SETTINGS.DOCUMENT_TEMPLATES.UPLOAD_MODAL.ALERT_ERROR_500']);
          return;
        }

        if (status === 400) {
          AlertService.add('danger', translations['SETTINGS.DOCUMENT_TEMPLATES.UPLOAD_MODAL.ALERT_ERROR_400']);
        }
      });
  };
};
