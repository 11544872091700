module.exports = (
  $scope,
  $translate,
  $uibModalInstance,
  StaffService,
  StaffCommon,
  EnvironmentDataService,
  AlertService,
  SessionService,
  data,
) => {
  'ngInject';

  const {
    userId,
    noticePeriodOptions,
    activeAppointment,
    setupComplete,
  } = data;

  const translations = $translate.instant([
    'STAFF.VIEW.EMPLOYMENT.EMPLOYMENT_FORM.ALERT_SUCCESS',
    'STAFF.VIEW.EMPLOYMENT.EMPLOYMENT_FORM.ERROR_500',
  ]);

  const defaultDateStart = moment();
  const allowSendMagicLink = !setupComplete || !activeAppointment;

  $scope.props = {
    noticePeriodOptions,
    actionInProgress: true,
    defaultDateStart,
    formData: {
      noticePeriodNumber: 0,
      noticePeriodOption: 'D',
      sendMagicLink: allowSendMagicLink,
    },
    allowSendMagicLink,
  };

  const addShape = {
    dateStart: defaultDateStart.format('YYYY-MM-DD'),
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.RecruitmentSource,
    EnvironmentDataService.DataType.StaffGroup,
    EnvironmentDataService.DataType.Position,
    EnvironmentDataService.DataType.Company,
  ])
    .then(([
      recruitmentSource,
      staffGroup,
      position,
      company,
    ]) => {
      $scope.props.recruitmentSources = recruitmentSource.data
        .map(({ id, title, deleted }) => ({ id, label: title, deleted }));

      addShape.defaultStaffGroupId = staffGroup.data.find((r) => !r.deleted).id;
      addShape.defaultPositionId = position.data.find((r) => !r.deleted).id;
      addShape.defaultCompanyId = company.data.find((r) => !r.deleted).id;

      $scope.props.actionInProgress = false;
    });

  $scope.close = () => $uibModalInstance.dismiss();

  $scope.onDateStartChange = (dateStart) => {
    addShape.dateStart = dateStart.format('YYYY-MM-DD');
  };

  $scope.save = () => {
    $scope.props.actionInProgress = true;

    const {
      dateStart,
      defaultStaffGroupId,
      defaultPositionId,
      defaultCompanyId,
    } = addShape;

    const {
      recruitmentSourceId,
      ukStarterDeclaration,
      noticePeriodNumber,
      noticePeriodOption,
      employeeNumber,
      sendMagicLink,
    } = $scope.props.formData;

    const fields = {
      employment: {
        dateStart,
        noticePeriod: noticePeriodNumber ? `P${noticePeriodNumber}${noticePeriodOption}` : undefined,
        recruitmentSourceId,
        ukStarterDeclaration,
        employeeNumber,
      },
      appointments: [{
        dateStart,
        entityId: SessionService.getEntity(),
        groupId: defaultStaffGroupId,
        positionId: defaultPositionId,
        companyId: defaultCompanyId,
      }],
      sendAccountSetupInvitation: sendMagicLink,
    };

    StaffService.addEmployment(userId, fields)
      .then(({ data }) => {
        AlertService.add('success', translations['STAFF.VIEW.EMPLOYMENT.EMPLOYMENT_FORM.ALERT_SUCCESS']);

        $uibModalInstance.close({
          action: 'create',
          response: data,
        });
      })
      .catch(({ status, data }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.EMPLOYMENT.EMPLOYMENT_FORM.ERROR_500']);
          return;
        }

        if (status === 400) {
          const modal = StaffCommon.onEmploymentValidationResponse(data.validationResult);

          modal.result.then((result) => {
            if (result === true) {
              $scope.save();
            }
          });
        }
      });
  };
};
