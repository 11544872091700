const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/outsourcing/editModal.html');

module.exports = (
  $scope,
  $uibModal,
  $state,
  $translate,
  $window,
  StaffService,
  AlertService,
  EnvironmentDataService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'SETTINGS.OUTSOURCING.ERROR_500',
    'SETTINGS.OUTSOURCING.ERROR_403',
    'SETTINGS.OUTSOURCING.ALERT_CONFIRM_DELETE',
  ]);

  $scope.props = {
    loadingData: true,
    data: [],
    includeDeleted: false,
    entityGroupById: {},
    entityById: {},
  };

  function onApiError(status) {
    $scope.props.loadingData = false;

    if (status === 500) {
      AlertService.add('danger', translations['SETTINGS.OUTSOURCING.ERROR_500']);
      return;
    }

    if (status === 403) {
      AlertService.add('warning', translations['SETTINGS.OUTSOURCING.ERROR_403']);
      return;
    }

    $state.go('settings.index');
  }

  function loadData() {
    $scope.props.loadingData = true;

    StaffService.getOutsourcingOrganisations()
      .then(({ data }) => {
        $scope.props.data = data.results
          .filter((row) => {
            if (!$scope.props.includeDeleted && row.deleted) {
              return false;
            }

            return row.entityId === undefined
              || $scope.props.entityById[row.entityId] !== undefined;
          })
          .map((row) => ({
            ...row,
            alwaysAvailable: row.earliestStart === '00:00:00' && row.latestFinish === '00:00:00',
            entity: row.entityId !== undefined
              ? $scope.props.entityById[row.entityId] : null,
            entityGroup: row.entityId !== undefined
              ? $scope.props.entityGroupById[$scope.props.entityById[row.entityId].groupId] : null,
          }));

        $scope.props.loadingData = false;
      }).catch(({ status }) => onApiError(status));
  }

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
    .then(({ data }) => {
      data.forEach((entityGroup) => {
        $scope.props.entityGroupById[entityGroup.id] = entityGroup;

        entityGroup.entities.forEach((entity) => {
          $scope.props.entityById[entity.id] = entity;
        });
      });

      loadData();
    });

  $scope.onDeletedToggle = () => {
    loadData();
  };

  $scope.delete = (row) => {
    if (row.actionInProgress) {
      return;
    }

    if (!$window.confirm(translations['SETTINGS.OUTSOURCING.ALERT_CONFIRM_DELETE'])) {
      return false;
    }

    row.actionInProgress = true;

    StaffService.updateOutsourcingOrganisation(row.id, { deleted: true })
      .then(() => {
        loadData();
      })
      .catch(({ status }) => {
        row.actionInProgress = false;
        onApiError(status);
      });
  };

  $scope.reinstate = (row) => {
    if (row.actionInProgress) {
      return;
    }

    row.actionInProgress = true;

    StaffService.updateOutsourcingOrganisation(row.id, { deleted: false })
      .then(() => {
        loadData();
      })
      .catch(({ status }) => {
        row.actionInProgress = false;
        onApiError(status);
      });
  };

  $scope.edit = (organisation) => {
    const modal = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./editModal'),
      resolve: {
        data: () => ({
          organisation,
        }),
      },
    });

    modal.result.then((result) => {
      if (!result) {
        return;
      }

      loadData();
    });
  };
};
