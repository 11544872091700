module.exports = (
  $scope,
  $translate,
  $uibModalInstance,
  $http,
  AlertService,
  SessionService,
  AvailabilityService,
  EnvironmentDataService,
  StaffCommon,
  ENDPOINT_API,
  data,
) => {
  'ngInject';

  const {
    userId,
    allowance,
    employmentDateStart,
  } = data;

  const translations = $translate.instant([
    'STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ERROR_500',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ALERT_SUCCESS_ADD',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ALERT_SUCCESS_UPDATE',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ALERT_ERROR_CALCULATE_BOOKED',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED',
    'STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED',
  ]);

  const defaultScheduleOfAccrual = 'BEGINNING_OF_ACCRUAL_YEAR';
  const defaultProjectAccruedSetting = SessionService.getSetting('absence.allowanceProjectAccrued') !== 'false';
  const holidayYearSetting = SessionService.getSetting('absence.holidayYearDate') || '01-01';
  const [monthRaw, dayOfMonthRaw] = holidayYearSetting.split('-');
  const monthSetting = parseInt(monthRaw, 10) - 1;
  const dayOfMonthSetting = parseInt(dayOfMonthRaw, 10);
  const defaultStartDate = moment().clone().month(monthSetting).date(dayOfMonthSetting).startOf('day');

  if (defaultStartDate.isAfter()) {
    defaultStartDate.subtract(1, 'year');
  }

  $scope.props = {
    loadingData: true,
    isAdd: !allowance,
    formData: allowance ? {
      deductible: allowance.deductible,
      projectTotalAccrued: allowance.projectTotalAccrued,
      totalAccrued: allowance.totalAccrued,
      booked: allowance.booked,
      carriedOver: allowance.carriedOver,
      overspendLimit: allowance.overspendLimit,
      totalToAccrue: allowance.totalToAccrue,
      accrualRate: allowance.accrualRate,
    } : {
      absenceTypeId: 1,
      scheduleOfAccrual: defaultScheduleOfAccrual,
      unit: 'DAYS',
      startDate: defaultStartDate.clone(),
      deductible: true,
      projectTotalAccrued: defaultProjectAccruedSetting,
      totalAccrued: 0,
      booked: 0,
      carriedOver: 0,
      overspendLimit: 0,
      totalToAccrue: null,
      accrualRate: null,
    },
    yearStarts: allowance
      ? moment.utc(allowance.startDate)
      : defaultStartDate.clone(),
    effectiveDate: allowance
      ? moment.utc(allowance.effectiveDate)
      : defaultStartDate.clone(),
    createForNextYear: true,
    totalAccruedBeforeProRata: 0,
    defaultProRataDate: employmentDateStart || moment().startOf('day'),
    lastEdited: allowance ? (allowance.lastEdited || allowance.added) : undefined,
    scheduleOfAccrual: allowance ? allowance.scheduleOfAccrual : defaultScheduleOfAccrual,
    scheduleOfAccrualList: [
      'BEGINNING_OF_ACCRUAL_YEAR',
      'EACH_CALENDAR_MONTH',
      'EACH_HOUR_WORKED',
    ].map((id) => ({
      id,
      label: StaffCommon.getLabelFromScheduleOfAccrual(id),
    })),
    holidayYearSettingAsDate: moment.utc().month(monthSetting).date(dayOfMonthSetting)
      .format('D MMMM'),
    customHolidayYear: false,
  };

  function evaluateDates() {
    const {
      yearStarts,
      effectiveDate,
    } = $scope.props;

    $scope.props.misalignedDateWarning = yearStarts.month() !== monthSetting
      || yearStarts.date() !== dayOfMonthSetting;
    $scope.props.yearEndDate = yearStarts.clone().add(1, 'year');

    $scope.props.effectiveDateMisaligned = effectiveDate.isBefore(yearStarts)
      || !effectiveDate.isBefore($scope.props.yearEndDate);

    $scope.props.effectiveDateAltered = effectiveDate
      .format('YYYY-MM-DD') !== yearStarts.format('YYYY-MM-DD');

    $scope.autoPopulateBooked();
  }

  $scope.close = () => $uibModalInstance.dismiss();

  $scope.onYearStartsChanged = (dateStart) => {
    if (!$scope.props.customHolidayYear) {
      dateStart.month(defaultStartDate.month()).date(defaultStartDate.date());
    }

    $scope.props.yearStarts = dateStart.clone();
    $scope.props.effectiveDate = dateStart.clone();
    evaluateDates();
  };

  $scope.toggleCustomHolidayYear = () => {
    const {
      customHolidayYear,
      yearStarts,
    } = $scope.props;

    $scope.props.customHolidayYear = !customHolidayYear;

    if (customHolidayYear) {
      // When disabling the custom holiday year, revert the day and month to default
      $scope.onYearStartsChanged(yearStarts.clone()
        .month(defaultStartDate.month()).date(defaultStartDate.date()));
    }
  };

  $scope.onEffectiveDateChange = (newDate) => {
    $scope.props.effectiveDate = newDate.clone();
    evaluateDates();
  };

  $scope.onAbsenceTypeChange = () => {
    $scope.autoPopulateBooked();
  };

  $scope.onUnitChange = () => {
    $scope.autoPopulateBooked();
  };

  $scope.onScheduleOfAccrualChange = () => {
    $scope.props.scheduleOfAccrual = $scope.props.formData.scheduleOfAccrual;

    if ($scope.props.scheduleOfAccrual === 'EACH_HOUR_WORKED') {
      $scope.props.formData.unit = 'HOURS';
      $scope.onUnitChange();
      $scope.autoPopulateAccrued();
    }

    if ($scope.props.scheduleOfAccrual === 'EACH_CALENDAR_MONTH') {
      $scope.props.showProRataControls = false;
    }
  };

  $scope.onShowProRataControlsChange = () => {
    if (!$scope.props.showProRataControls) {
      return;
    }

    $scope.props.totalAccruedBeforeProRata = $scope.props.formData.totalAccrued;
    $scope.calculateProRata();
  };

  $scope.autoPopulateBooked = () => {
    const {
      yearStarts,
      effectiveDate,
      isAdd,
      formData,
    } = $scope.props;

    if (!isAdd) {
      return;
    }

    const absenceTypeId = isAdd
      ? formData.absenceTypeId
      : allowance.absenceType.id;

    const unit = isAdd ? formData.unit : allowance.unit;

    if (!absenceTypeId || !unit) {
      return;
    }

    $scope.props.actionInProgress = 'populateBooked';
    const yearStartsMoment = moment.utc(yearStarts.format('YYYY-MM-DD'));
    const startDate = moment.utc(effectiveDate.format('YYYY-MM-DD'));
    const endDate = yearStartsMoment.clone().add(1, 'year');

    if (!startDate.isBefore(endDate)) {
      return;
    }

    AvailabilityService.getAbsencePaginated({
      userId,
      'approvalStates[]': [0, 1],
      'absenceTypeIds[]': [absenceTypeId],
      includeCancelled: false,
      dateContext: 'overlapping',
      start: startDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD'),
      fullRecords: true,
      limit: 100,
      page: 1,
      sortOrder: 'startDateDesc',
    })
      .then(({ data }) => {
        $scope.props.actionInProgress = false;

        const totalHours = data.absence
          .flatMap(({ days }) => days)
          .filter(({ date }) => startDate.isSameOrBefore(date) && endDate.isAfter(date))
          .reduce((total, { hours }) => (total + hours), 0);

        const totalDays = data.absence
          .map(({ header, days }) => {
            if (header.scope > 0) {
              return 0.5;
            }

            return days.filter(({ date, hours }) => hours > 0
              && startDate.isSameOrBefore(date)
              && endDate.isAfter(date)).length;
          })
          .reduce((total, value) => (total + value), 0);

        const totalDaysRounded = Math.round(totalDays * 10) / 10;
        const totalHoursRounded = Math.round(totalHours * 100) / 100;

        $scope.props.formData.booked = unit === 'DAYS' ? totalDaysRounded : totalHoursRounded;
        $scope.props.autoPopulateBookedValue = $scope.props.formData.booked;
      })
      .catch(() => {
        $scope.props.actionInProgress = false;
        AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ALERT_ERROR_CALCULATE_BOOKED']);
      });
  };

  $scope.autoPopulateAccrued = () => {
    const {
      yearStarts,
      effectiveDate,
      scheduleOfAccrual,
    } = $scope.props;

    const yearStartsMoment = moment.utc(yearStarts.format('YYYY-MM-DD'));
    const startDate = moment.utc(effectiveDate.format('YYYY-MM-DD'));
    const endDateCap = moment.utc().startOf('day').add(1, 'day');
    let endDate = yearStartsMoment.clone().add(52, 'weeks');

    if (endDate.isAfter(endDateCap)) {
      endDate = endDateCap;
    }

    if (!startDate.isBefore(endDate) || !startDate.isBefore() || scheduleOfAccrual !== 'EACH_HOUR_WORKED') {
      return;
    }

    $scope.props.actionInProgress = 'populateAccrued';

    $http.get(`${ENDPOINT_API}/report/signedOffHours`, {
      params: {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        userId,
      },
      responseStatusAuthority: [403],
    })
      .then(({ data }) => {
        $scope.props.actionInProgress = false;

        const accruedByWork = data.items.reduce((total, { work }) => total + work
          .reduce((workTotal, { calculatedPay }) => workTotal + calculatedPay
            .durationAccruedHoliday, 0), 0);

        const accruedByAbsence = data.items.reduce((total, { absence }) => Object
          .entries(absence || {})
          .reduce((sum, [, { hoursAccrued }]) => sum + (hoursAccrued || 0), total), 0);

        const value = Math.round((accruedByWork + accruedByAbsence) * 100) / 100;

        $scope.props.formData.totalAccrued = value;
        $scope.props.autoPopulateAccruedValue = value;
      })
      .catch(({ status }) => {
        $scope.props.actionInProgress = false;

        if (status === 403) {
          AlertService.add('warning', translations['STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED']);
        } else {
          AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED']);
        }
      });
  };

  $scope.calculateProRata = (newDate) => {
    const {
      yearStarts: allowanceStartDate,
      defaultProRataDate,
      totalAccruedBeforeProRata,
    } = $scope.props;

    const proRataDate = newDate || defaultProRataDate;

    if (proRataDate.isBefore(allowanceStartDate)) {
      $scope.props.formData.totalAccrued = totalAccruedBeforeProRata;
      return;
    }

    const comparatorDate = proRataDate.clone()
      .month(allowanceStartDate.month())
      .date(allowanceStartDate.date());

    if (comparatorDate.isSameOrBefore(proRataDate)) {
      comparatorDate.add(1, 'year');
    }

    const totalAccruedBeforeProRataFloat = parseFloat(totalAccruedBeforeProRata);

    if (Number.isNaN(totalAccruedBeforeProRataFloat)) {
      return;
    }

    const fte = comparatorDate.diff(proRataDate, 'days') / 365;
    $scope.props.formData.totalAccrued = Math.round(fte * totalAccruedBeforeProRataFloat * 10) / 10;
  };

  $scope.save = () => {
    if (($scope.props.isAdd && !$scope.props.formData.absenceTypeId)
      || $scope.props.actionInProgress) {
      return;
    }

    if ($scope.props.formData.scheduleOfAccrual === 'EACH_CALENDAR_MONTH'
      && $scope.props.formData.totalToAccrue <= 0) {
      return;
    }

    $scope.props.actionInProgress = 'save';

    if ($scope.props.isAdd) {
      const allowances = [{
        ...$scope.props.formData,
        startDate: $scope.props.yearStarts.format('YYYY-MM-DD'),
        effectiveDate: $scope.props.effectiveDate.format('YYYY-MM-DD'),
        userId,
      }];

      if ($scope.props.createForNextYear && $scope.props.totalAccruedBeforeProRata) {
        allowances.push({
          ...$scope.props.formData,
          startDate: $scope.props.yearStarts.clone().add(1, 'year').format('YYYY-MM-DD'),
          effectiveDate: $scope.props.effectiveDate.clone().add(1, 'year').format('YYYY-MM-DD'),
          userId,
          totalAccrued: $scope.props.totalAccruedBeforeProRata,
          booked: 0,
          carriedOver: 0,
        });
      }

      AvailabilityService.addAllowance({
        allowances,
      })
        .then(() => {
          AlertService.add('success', translations['STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ALERT_SUCCESS_ADD']);
          $uibModalInstance.close(true);
        })
        .catch(({ status, data }) => {
          $scope.props.actionInProgress = false;

          if (status === 400) {
            StaffCommon.onAllowanceValidationResponse(data);
            return;
          }

          if (status === 500) {
            AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ERROR_500']);
          }
        });
    } else {
      AvailabilityService.updateAllowance(allowance.id, $scope.props.formData)
        .then(() => {
          AlertService.add('success', translations['STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ALERT_SUCCESS_UPDATE']);
          $uibModalInstance.close(true);
        })
        .catch(({ status, data }) => {
          $scope.props.actionInProgress = false;

          if (status === 400) {
            StaffCommon.onAllowanceValidationResponse(data);
            return;
          }

          if (status === 500) {
            AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.ALLOWANCE_FORM.ERROR_500']);
          }
        });
    }
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.AbsenceType)
    .then(({ data }) => {
      $scope.props.absenceTypes = data
        .map((type) => ({
          ...type,
          label: type.name,
        }));

      if ($scope.props.isAdd) {
        evaluateDates();
      }

      $scope.props.loadingData = false;
    });
};
