const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/userWidget.html');

var directives = require('../module');

directives.directive('rrUserWidget', ['$state',
    function ($state) {
        return {
            restrict: 'E',
            templateUrl: autoNgTemplateLoaderTemplate1,
            scope: {
                user: '=?',
                size: '@?',
                sref: '@?',
            },
            link: function ($scope) {
                $scope.props = {
                    size: $scope.size || 'xxs',
                };

                $scope.userClick = function () {
                    var state = $scope.sref || 'staff.view.account';
                    $state.go(state, { id: $scope.user.id });
                };
            },
        };
    }]);
