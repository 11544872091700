const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messaging/startConversationDrawer.html');

import directives from '../module';
import messagingHubController from '../../controllers/messaging/messagingHub';

directives.directive('rrStartConversationDrawer', () => ({
  restrict: 'A',
  templateUrl: autoNgTemplateLoaderTemplate1,
  replace: false,
  controller: messagingHubController,
}));
