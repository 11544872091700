const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/allowanceForm.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/allowanceForm.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/allowanceTransactions.html');
const autoNgTemplateLoaderTemplate4 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/allowanceFinaliseForm.html');
const autoNgTemplateLoaderTemplate5 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/allowanceFinalisePrompt.html');
const autoNgTemplateLoaderTemplate6 = require('/home/circleci/project/app/htdocs/views/staff/view/absence/viewFinalisationDrawer.html');

module.exports = (
  $rootScope,
  $scope,
  $uibModal,
  $uibModalDrawer,
  $state,
  AvailabilityService,
  AlertService,
  StaffCommon,
  SessionService,
  $translate,
  $window,
  EnvironmentDataService,
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.ERROR_500',
    'STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.CHRONO_TYPE_ACTIVE',
    'STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.CHRONO_TYPE_FUTURE',
    'STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.CHRONO_TYPE_HISTORICAL',
    'STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.ALERT_CONFIRM_DELETE',
    'STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.ALERT_SUCCESS_DELETE',
    'STAFF.VIEW.ABSENCE.FINALISE_ALLOWANCE_PROMPT.ALERT_ERROR',
  ]);

  const defaultStatusOptions = ['active', 'future'];
  const userId = parseInt($scope.userId, 10);

  $scope.props = {
    canEdit: !!$scope.userId,
    statusOptions: [
      {
        id: 'historical',
        label: translations['STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.CHRONO_TYPE_HISTORICAL'],
      },
      {
        id: 'active',
        label: translations['STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.CHRONO_TYPE_ACTIVE'],
      },
      {
        id: 'future',
        label: translations['STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.CHRONO_TYPE_FUTURE'],
      },
    ],
    defaultStatusOptions,
    selectedStatusOptions: defaultStatusOptions,
    loadingData: true,
    data: [],
    convertHoursToDays: false,
  };

  $scope.getDataParams = {
    userId: $scope.props.canEdit ? userId : SessionService.getUserId(),
    convertHoursToDays: $scope.props.convertHoursToDays,
    sortOrder: 'startDateAsc',
    page: 1,
    limit: 100,
  };

  function refreshAllowances() {
    $scope.props.loadingData = true;

    AvailabilityService.getAllowances($scope.getDataParams)
      .then(({ data }) => {
        $scope.props.data = data.results
          .map((allowance) => {
            let chronoType = 'active';

            if (moment(allowance.endDate).isBefore()) {
              chronoType = 'historical';
            } else if (moment(allowance.startDate).isAfter()) {
              chronoType = 'future';
            }

            return {
              ...allowance,
              unitLabel: StaffCommon.getLabelFromAllowanceUnit(allowance.unit),
              chronoType,
              remaining: Math.round((allowance.totalAccrued - allowance.booked) * 100) / 100,
              effectiveDateFormatted: moment.utc(allowance.effectiveDate)
                .format(allowance.effectiveDate === allowance.startDate ? 'YYYY' : 'D MMM YYYY'),
              finalisedReferenceDateFormatted: allowance.finalisedReferenceDate
                ? moment.utc(allowance.finalisedReferenceDate).format('D MMM YYYY') : '',
            };
          })
          .filter((allowance) => $scope.props.selectedStatusOptions
            .includes(allowance.chronoType));

        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.ERROR_500']);
        }

        $scope.props.loadingData = false;
      });
  }

  $scope.onStatusFilter = (selectedOptions) => {
    $scope.props.selectedStatusOptions = selectedOptions;
    refreshAllowances();
  };

  $scope.onAbsenceTypeFilter = (selectedOptions) => {
    const [option] = selectedOptions;
    $scope.getDataParams['absenceTypeIds[]'] = option || [];
    refreshAllowances();
  };

  $scope.onConvertHoursToDays = () => {
    $scope.getDataParams.convertHoursToDays = $scope.props.convertHoursToDays;
    refreshAllowances();
  };

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.AbsenceType)
    .then(({ data }) => {
      $scope.props.absenceTypes = data
        .map((type) => ({
          ...type,
          label: type.name,
        }));

      refreshAllowances();
    });

  $scope.addAllowance = () => {
    const allowanceModal = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./allowanceForm'),
      resolve: {
        data: () => ({
          userId,
          employmentDateStart: moment.utc($scope.userData.employment.dateStart),
        }),
      },
    });

    allowanceModal.result.then(() => {
      $rootScope.$broadcast('user:update');
      refreshAllowances();
    });
  };

  $scope.editAllowance = (allowance) => {
    const allowanceModal = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate2,
      controller: require('./allowanceForm'),
      resolve: {
        data: () => ({
          userId,
          allowance,
        }),
      },
    });

    allowanceModal.result.then(() => {
      refreshAllowances();
    });
  };

  $scope.deleteAllowance = (allowance, index) => {
    if (!$window.confirm(translations['STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.ALERT_CONFIRM_DELETE'])) {
      return;
    }

    AvailabilityService.deleteAllowance(allowance.id)
      .then(() => {
        AlertService.add('success', translations['STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.ALERT_SUCCESS_DELETE']);
        $scope.props.data.splice(index, 1);
      })
      .catch(({ status, data }) => {
        if (status === 400) {
          StaffCommon.onAllowanceValidationResponse(data);
          return;
        }

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.ABSENCE.VIEW_ALLOWANCES.ERROR_500']);
        }
      });
  };

  $scope.viewTransactions = (allowance) => {
    $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate3,
      controller: require('./allowanceTransactions'),
      resolve: {
        data: () => ({
          id: allowance.id,
        }),
      },
    });
  };

  $scope.finaliseAllowance = (allowance) => {
    if (!$scope.props.canEdit) {
      return;
    }

    const {
      id: userId,
      absence: {
        absenceHoursDay: averageHoursPerDay,
      },
      employment: {
        id: employmentId,
        openEnded: userCurrentEmploymentOpenEnded,
        dateStart: userCurrentEmploymentDateStart,
        dateEnd: userCurrentEmploymentDateEnd,
        leaverHolidayProcessed: userCurrentEmploymentAttested,
      },
    } = $scope.userData;

    const modal = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate4,
      controller: require('./allowanceFinaliseForm'),
      resolve: {
        data: () => ({
          allowance,
          userCurrentEmploymentDateStart,
          userCurrentEmploymentDateEnd,
          averageHoursPerDay,
        }),
      },
    });

    modal.result.then((isFinalised) => {
      if (!isFinalised) {
        return;
      }

      refreshAllowances();

      if (userCurrentEmploymentOpenEnded || userCurrentEmploymentAttested) {
        return;
      }

      const attestModal = $uibModal.open({
        templateUrl: autoNgTemplateLoaderTemplate5,
        controller: ($scope, $uibModalInstance, StaffService) => {
          $scope.close = (isAttest) => {
            if (!isAttest) {
              $uibModalInstance.dismiss('cancel');
              return;
            }

            $scope.props.loading = true;

            StaffService.updateEmployment(employmentId, userId, {
              leaverHolidayProcessed: true,
            })
              .then(() => {
                $scope.props.loading = false;
                $uibModalInstance.close(true);
              })
              .catch(() => {
                $scope.props.loading = false;
                $window.alert(translations['STAFF.VIEW.ABSENCE.FINALISE_ALLOWANCE_PROMPT.ALERT_ERROR']);
              });
          };
        },
      });

      attestModal.result.then((isAttested) => {
        if (isAttested) {
          $scope.userData.employment.leaverHolidayProcessed = true;
        }
      });
    });
  };

  $scope.viewFinalisation = (allowance) => {
    if (!$scope.props.canEdit) {
      return;
    }

    const modal = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate6,
      controller: require('./viewFinalisationDrawer'),
      resolve: {
        data: () => ({ allowance }),
      },
    });

    modal.result.then(() => {
      refreshAllowances();
    });
  };
};
