'use strict';
var controllers = require('../module');

controllers.controller('EditEventAttendanceModalCtrl', ['$scope', '$rootScope', '$debounce', '$uibModalInstance',
    'AttendanceService', 'CommonService', 'AttendanceCommon', 'AlertService', 'data', '$translate',
    function ($scope, $rootScope, $debounce, $uibModalInstance, AttendanceService, CommonService,
              AttendanceCommon, AlertService, data, $translate) {
        var eventId = data.id;
        var timeAdjustOptions = [1, 5, 15, 30, 60];
        var preventFutureDateTolerance = 12; // hours

        var labelTranslations = $translate.instant([
            'ATTENDANCE.EDIT_EVENT_MODAL.CLOCK_IN',
            'ATTENDANCE.EDIT_EVENT_MODAL.CLOCK_OUT',
            'ATTENDANCE.EDIT_EVENT_MODAL.BREAK_ON',
            'ATTENDANCE.EDIT_EVENT_MODAL.BREAK_OFF',
            'ATTENDANCE.EDIT_EVENT_MODAL.ERROR_UPDATING',
            'ATTENDANCE.EDIT_EVENT_MODAL.ERROR_DELETING',
            'ATTENDANCE.EDIT_EVENT_MODAL.CONFIRM_DELETE',
        ]);

        $scope.eventTypes = [
            { id: 1, label: labelTranslations['ATTENDANCE.EDIT_EVENT_MODAL.CLOCK_IN'] },
            { id: 2, label: labelTranslations['ATTENDANCE.EDIT_EVENT_MODAL.CLOCK_OUT'] },
            { id: 3, label: labelTranslations['ATTENDANCE.EDIT_EVENT_MODAL.BREAK_ON'] },
            { id: 4, label: labelTranslations['ATTENDANCE.EDIT_EVENT_MODAL.BREAK_OFF'] },
        ];

        $scope.eventTypesById = {};
        $scope.eventTypes.forEach(function (e) { $scope.eventTypesById[e.id] = e; });

        $scope.props = {
            loadingData: false,
            priorEvent: null,
            priorEventConflict: false,
            dateInFutureError: false,
            timeAdjustOptions: [],
        };

        timeAdjustOptions.forEach(function (duration) {
            $scope.props.timeAdjustOptions.unshift({
                id: -duration,
                label: '-' + duration,
            });

            $scope.props.timeAdjustOptions.push({
                id: duration,
                label: '+' + duration,
            });
        });

        $scope.onDateChange = function (newDate) {
            $scope.props.selectedDate = newDate;
            $scope.loadPriorEvents();
        };

        $scope.adjustTime = function (mins) {
            $scope.props.selectedDate = $scope.props.selectedDate.clone().add(mins, 'minutes');
            $debounce($scope.loadPriorEvents, 500);
        };

        $scope.loadPriorEvents = function () {
            $scope.props.dateInFutureError = $scope.props.selectedDate.clone()
                .subtract(preventFutureDateTolerance, 'hours').isAfter();

            if ($scope.props.dateInFutureError) {
                return;
            }

            if ($scope.props.loadingData) {
                return;
            }

            $scope.props.loadingData = true;

            var getDataParams = {
                userId: $scope.props.event.userId,
                end: $scope.props.selectedDate.clone().seconds(59).format(),
                'excludeEventIds[]': eventId,
                limit: 1,
                page: 1,
                sortOrder: 'eventDateDesc',
            };

            AttendanceService.getEvents(getDataParams)
                .success(function (result) {
                    if (result.events.length) {
                        var event = result.events[0];

                        $scope.props.priorEvent = event;
                        $scope.props.priorEventConflict = moment(event.date).isSame($scope.props.selectedDate, 'minute');
                    } else {
                        $scope.props.priorEvent = null;
                        $scope.props.priorEventConflict = false;
                    }

                    $scope.props.loadingData = false;
                });
        };

        AttendanceService.getEvent(eventId)
            .success(function (data) {
                $scope.props.event = data.event;

                $scope.props.selectedDate = moment($scope.props.event.date).seconds(0);

                $scope.loadPriorEvents();
            })
            .error(function () {
                $uibModalInstance.dismiss('cancel');
            });

        $scope.submit = function () {
            $scope.props.loadingData = true;

            var fields = {
                date: $scope.props.selectedDate.format(),
            };

            if ($scope.props.event.overtimeApprovalState) {
                fields.overtimeApprovalState = $scope.props.event.overtimeApprovalState;
            }

            AttendanceService.updateEvent(eventId, fields)
                .success(function (result) {
                    $rootScope.$broadcast('attendanceUpdate', {
                        operation: 1,
                        events: result.events,
                    });

                    $scope.close();
                })
                .error(function (error, status) {
                    $scope.props.loadingData = false;

                    if (status === 403) {
                        return;
                    }

                    if (status === 400) {
                        AttendanceCommon.displayValidationError(error);
                        return;
                    }

                    AlertService.add('danger', labelTranslations['ATTENDANCE.EDIT_EVENT_MODAL.ERROR_UPDATING']);
                });
        };

        $scope.delete = function () {
            if (!confirm(labelTranslations['ATTENDANCE.EDIT_EVENT_MODAL.CONFIRM_DELETE'])) {
                return;
            }

            $scope.props.loadingData = true;

            AttendanceService.deleteEvent(eventId)
                .success(function (result) {
                    $rootScope.$broadcast('attendanceUpdate', {
                        operation: 2,
                        eventIds: result.eventIds,
                    });

                    $scope.close();
                })
                .error(function (error, status) {
                    $scope.props.loadingData = false;

                    if (status === 403) {
                        return;
                    }

                    if (status === 400) {
                        AttendanceCommon.displayValidationError(error);
                        return;
                    }

                    AlertService.add('danger', labelTranslations['ATTENDANCE.EDIT_EVENT_MODAL.ERROR_DELETING']);
                });
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);
