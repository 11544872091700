const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/budgeting/editPeriodModal.html');

var controllers = require('../../module');

controllers.controller('BudgetingByEntityCtrl', ['$scope', '$uibModal', '$state', '$stateParams', 'SettingsService', 'AlertService', 'LocaleService',
    function ($scope, $uibModal, $state, $stateParams, SettingsService, AlertService, LocaleService) {
        $scope.dataLoaded = false;
        var entityId = $stateParams.entity;

        SettingsService.getBudgetEntityDashboard(entityId)
            .success(function (data) {
                $scope.entity = data.entity;
                $scope.periods = data.periods;

                $scope.currencySymbol = LocaleService.getCurrencySymbol($scope.entity.currencyCode);

                data.periods.forEach(function (p) {
                    if (p.isCurrent) {
                        $scope.currentPeriod = p;
                    }
                });

                $scope.dataLoaded = true;
            })
            .error(function (error, status) {
                if (status == 500) {
                    AlertService.add('danger', 'We couldn\'t load the budget periods just then. Please try again.');
                }

                $state.go('^.index');
            });

        $scope.managePeriod = function (entity, periodId) {
            $state.go('^.byPeriod', {entity: entity, period: periodId});
        };

        $scope.newPeriod = function () {
            var editModal = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'BudgetingEditPeriodModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            entityId: entityId
                        };
                    }
                }
            });

            editModal.result.then(function (result) {
                AlertService.add('success', "Your budget period has been created. You can edit the weekly budgets here, either one by one or in bulk.");
                $state.go('^.byPeriod', {entity: entityId, period: result.period.id});
            });
        };
    }
]);
