'use strict';
var services = require('./module');

services.factory('LocaleService', [function () {
    var currencySymbolMap =
    { "AED": "د.إ"
        , "AFN": "؋"
        , "ALL": "L"
        , "ANG": "ƒ"
        , "AOA": "Kz"
        , "ARS": "$"
        , "AUD": "$"
        , "AWG": "ƒ"
        , "AZN": "₼"
        , "BAM": "KM"
        , "BBD": "$"
        , "BDT": "৳"
        , "BGN": "лв"
        , "BHD": ".د.ب"
        , "BIF": "FBu"
        , "BMD": "$"
        , "BND": "$"
        , "BOB": "Bs."
        , "BRL": "R$"
        , "BSD": "$"
        , "BTN": "Nu."
        , "BWP": "P"
        , "BYR": "p."
        , "BZD": "BZ$"
        , "CAD": "$"
        , "CDF": "FC"
        , "CHF": "Fr."
        , "CLP": "$"
        , "CNY": "¥"
        , "COP": "$"
        , "CRC": "₡"
        , "CUC": "$"
        , "CUP": "₱"
        , "CVE": "$"
        , "CZK": "Kč"
        , "DJF": "Fdj"
        , "DKK": "kr"
        , "DOP": "RD$"
        , "DZD": "دج"
        , "EEK": "kr"
        , "EGP": "£"
        , "ERN": "Nfk"
        , "ETB": "Br"
        , "EUR": "€"
        , "FJD": "$"
        , "FKP": "£"
        , "GBP": "£"
        , "GEL": "₾"
        , "GGP": "£"
        , "GHC": "₵"
        , "GHS": "GH₵"
        , "GIP": "£"
        , "GMD": "D"
        , "GNF": "FG"
        , "GTQ": "Q"
        , "GYD": "$"
        , "HKD": "$"
        , "HNL": "L"
        , "HRK": "kn"
        , "HTG": "G"
        , "HUF": "Ft"
        , "IDR": "Rp"
        , "ILS": "₪"
        , "IMP": "£"
        , "INR": "₹"
        , "IQD": "ع.د"
        , "IRR": "﷼"
        , "ISK": "kr"
        , "JEP": "£"
        , "JMD": "J$"
        , "JPY": "¥"
        , "KES": "KSh"
        , "KGS": "лв"
        , "KHR": "៛"
        , "KMF": "CF"
        , "KPW": "₩"
        , "KRW": "₩"
        , "KYD": "$"
        , "KZT": "₸"
        , "LAK": "₭"
        , "LBP": "£"
        , "LKR": "₨"
        , "LRD": "$"
        , "LSL": "M"
        , "LTL": "Lt"
        , "LVL": "Ls"
        , "MAD": "MAD"
        , "MDL": "lei"
        , "MGA": "Ar"
        , "MKD": "ден"
        , "MMK": "K"
        , "MNT": "₮"
        , "MOP": "MOP$"
        , "MUR": "₨"
        , "MVR": "Rf"
        , "MWK": "MK"
        , "MXN": "$"
        , "MYR": "RM"
        , "MZN": "MT"
        , "NAD": "$"
        , "NGN": "₦"
        , "NIO": "C$"
        , "NOK": "kr"
        , "NPR": "₨"
        , "NZD": "$"
        , "OMR": "﷼"
        , "PAB": "B/."
        , "PEN": "S/."
        , "PGK": "K"
        , "PHP": "₱"
        , "PKR": "₨"
        , "PLN": "zł"
        , "PYG": "Gs"
        , "QAR": "﷼"
        , "RMB": "￥"
        , "RON": "lei"
        , "RSD": "Дин."
        , "RUB": "₽"
        , "RWF": "R₣"
        , "SAR": "﷼"
        , "SBD": "$"
        , "SCR": "₨"
        , "SDG": "ج.س."
        , "SEK": "kr"
        , "SGD": "$"
        , "SHP": "£"
        , "SLL": "Le"
        , "SOS": "S"
        , "SRD": "$"
        , "SSP": "£"
        , "STD": "Db"
        , "SVC": "$"
        , "SYP": "£"
        , "SZL": "E"
        , "THB": "฿"
        , "TJS": "SM"
        , "TMT": "T"
        , "TND": "د.ت"
        , "TOP": "T$"
        , "TRL": "₤"
        , "TRY": "₺"
        , "TTD": "TT$"
        , "TVD": "$"
        , "TWD": "NT$"
        , "TZS": "TSh"
        , "UAH": "₴"
        , "UGX": "USh"
        , "USD": "$"
        , "UYU": "$U"
        , "UZS": "лв"
        , "VEF": "Bs"
        , "VND": "₫"
        , "VUV": "VT"
        , "WST": "WS$"
        , "XAF": "FCFA"
        , "XBT": "Ƀ"
        , "XCD": "$"
        , "XOF": "CFA"
        , "XPF": "₣"
        , "YER": "﷼"
        , "ZAR": "R"
        , "ZWD": "Z$"
    };

    return {
        getCurrencySymbol: function (currencyCode) {
            if (!currencyCode) return '';

            var code = currencyCode.toUpperCase();

            if (!currencySymbolMap.hasOwnProperty(code)) {
                return '';
            }

            return currencySymbolMap[code];
        }
    };
}]);
