const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/other/manageSubscriptionsModal.html');

'use strict';
var controllers = require('../module');
var locales = require('../../constants/locales');

controllers.controller('EditPreferencesModalCtrl', ['$scope', '$uibModalInstance', '$uibModal', 'SessionService', 'AuthService', '$translate',
    function ($scope, $uibModalInstance, $uibModal, SessionService, AuthService, $translate) {
        $scope.formData = {};
        $scope.notifications = {};

        $scope.saveActioning = false;
        $scope.dataLoaded = false;

        $scope.rotareadyLoginEnabled = AuthService.isRotareadyLoginEnabled();

        $scope.isCollapsed = true;

        $scope.showStaffNotifications = SessionService.getModuleVisibility().staff;

        $scope.locales = locales;

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.savePreferences = function (isValid) {
            if (!isValid) {
                alert("Something in your form isn't quite right. Have a scan through and correct any errors, then try again.");
                return;
            }

            $scope.saveActioning = true;

            var notifications = {};

            Object.keys($scope.notifications).forEach(function (name) {
                var n = $scope.notifications[name];

                notifications[n.id] = n;
            });

            var postData = {
                profile: $scope.formData,
                notifications: notifications
            };

            SessionService.updatePreferences(postData)
                .success(function () {
                    // If email has changed, update the session
                    if (SessionService.getEmail() !== $scope.formData.email) {
                        SessionService.setEmail($scope.formData.email)
                    }
                    if (SessionService.getLanguage() !== $scope.formData.locale) {
                        SessionService.setLanguage($scope.formData.locale);
                        $translate.use($scope.formData.locale);

                    }

                    $uibModalInstance.close();
                })
                .error(function () {
                    $scope.saveActioning = false;
                    alert('We were unable to save your preferences. Please try again.');
                });
        };

        SessionService.getPreferences()
            .success(function (data) {
                $scope.formData = angular.copy(data.profile);
                $scope.formData.locale = SessionService.getLanguage();
                data.notifications.forEach(function (n) {
                    $scope.notifications[n.name] = n;
                });

                $scope.dataLoaded = true;
            })
            .error(function () {
                $uibModalInstance.dismiss('cancel');
            });

        $scope.openManageSubscriptions = function() {
            $scope.close();

            $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'ManageSubscriptionsModalCtrl'
            });
        };

        $scope.changePassword = function() {
            AuthService.changePassword(SessionService.getEmail(), SessionService.getUsername());
        };
    }]);
