const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/validationBulkAttendanceEdit.html');

const directives = require('../module');

directives.directive('rrValidationBulkAttendanceEdit', (
  $translate,
  $timeout,
  AttendanceService,
) => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      issues: '=',
      totalIssueCount: '=',
      truncateIssueLimit: '@',
    },
    link: ($scope) => {
      $scope.props = {
        isLoading: false,
      };

      function handleError(status) {
        $scope.issues.forEach((i) => {
          i.isDeleting = false;
        });

        $scope.props.isLoading = false;
        $scope.props.displayErrorCode = status;

        $timeout(() => {
          $scope.displayErrorCode = undefined;
        }, 3000);
      }

      $scope.delete = (issue, index) => {
        if (issue.isDeleting) {
          return;
        }

        issue.isDeleting = true;
        $scope.props.isLoading = true;

        AttendanceService.deleteEvent(issue.attendanceEvent.id)
          .then(() => {
            $scope.issues.splice(index, 1);
            $scope.props.isLoading = false;
            $scope.totalIssueCount -= 1;
          })
          .catch(({ status }) => handleError(status));
      };
    },
  };
});
