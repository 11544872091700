module.exports = (
  $scope,
  $uibModalStack,
  SessionService,
  MessagingService,
  data,
) => {
  'ngInject';

  const {
    participants,
    participantIds,
    channelId,
  } = data;

  $scope.participants = participants;
  $scope.participantIds = (participantIds || participants.map((p) => p.userId))
    .sort((a, b) => a - b);

  $scope.channelId = channelId;

  const ownUserId = SessionService.getUserId();

  $scope.conversationParticipantsText = participants
    .filter(({ userId }) => userId !== ownUserId)
    .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
    .join(', ');

  $scope.closeConversationMessagingDrawer = () => {
    MessagingService.setActiveChannel(null);

    $uibModalStack.dismissAll();
  };

  $scope.goBackFromMessagingDrawer = () => {
    MessagingService.setActiveChannel(null);

    const { key: openedModalKey } = $uibModalStack.getTop();
    $uibModalStack.dismiss(openedModalKey);
  };
};
