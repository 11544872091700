var directives = require('./module');

directives.directive('rrInlineEdit', ['$timeout', '$window', function ($timeout, $window) {
    return {
        restrict: 'A',
        scope: {
            editCancel: '&',
            editSuccess: '&',
        },
        require: 'ngModel',
        link: function ($scope, element, attrs, ngModel) {
            var valuePreEdit = '';

            function isValueEmpty() {
                var val = element.val();

                if (val === null || val === undefined) {
                    return true;
                }

                // Convert to string then trim any surrounding whitespace
                var valAsString = val.toString().trim();

                // Coerce to a bool, if truthy then it can't be empty
                if (valAsString) {
                    return false;
                }

                return true;
            }

            function isValueChanged() {
                // Deliberately convert the live value to a string before doing a ===
                return valuePreEdit !== element.val().toString();
            }

            function editSuccess() {
                if ($scope.editSuccess) {
                    $scope.$apply(function () {
                        $scope.editSuccess();
                    });
                }
            }

            function editCancel() {
                if ($scope.editCancel) {
                    $scope.$apply(function () {
                        $scope.editCancel();
                    });
                }
            }

            function applyChange() {
                if (isValueChanged()) {
                    editSuccess();
                } else {
                    editCancel();
                }
            }

            element.on('focus', function () {
                valuePreEdit = element.val();

                if (!$window.getSelection().toString()) {
                    // Required for mobile Safari
                    this.setSelectionRange(0, this.value.length)
                }
            });

            // On 'click' doesn't work when the input uses ng-currency
            $timeout(function () {
                element.focus();
            });

            element.bind('keydown keypress', function (event) {
                // Prevent an empty value being provided
                if (isValueEmpty()) {
                    return;
                }

                // Tab
                if (event.which === 9) {
                    event.preventDefault();

                    // Get a reference to the tab-to element before we call applyChange as these will trigger DOM
                    // manipulation and likely remove 'element' from the DOM
                    var nextTd = $(element).closest('td').next('td');
                    var tabToElement = false;

                    if (nextTd) {
                        tabToElement = nextTd.find('.tabbable-element');
                    }

                    applyChange();

                    // Always tab to the element after calling applyChange, otherwise we'll inadvertently trigger
                    // a blur event which will call applyChange
                    if (tabToElement) {
                        angular.element(tabToElement).triggerHandler('click');
                    }

                    return;
                }

                // Escape
                if (event.which === 27) {
                    event.preventDefault();

                    // Undo their change
                    if (valuePreEdit) {
                        ngModel.$setViewValue(valuePreEdit);
                        ngModel.$render();
                    }

                    editCancel();
                    return;
                }

                // Enter
                if (event.which === 13) {
                    event.preventDefault();

                    applyChange();
                    return;
                }
            });

            element.bind('blur', function (event) {
                if (isValueEmpty()) {
                    event.stopPropagation();
                    event.cancelBubble = true;
                    return;
                }

                applyChange();
            });
        }
    }
}]);
