const translations = {
  "STAFF": {
    "CONSTANTS": {
      "UK_STARTER_DECLARATION_A": "A: Dies ist ihr erster Job seit Beginn des Steuerjahres (6. April)",
      "UK_STARTER_DECLARATION_B": "B: Dies ist derzeit ihr einziger Job",
      "UK_STARTER_DECLARATION_C": "C: Sie haben einen anderen Job oder eine andere Rente",
      "UK_STUDENT_LOAN_PLAN_1": "Plan 1",
      "UK_STUDENT_LOAN_PLAN_2": "Plan 2",
      "UK_STUDENT_LOAN_PLAN_4": "Plan 4",
      "UK_STUDENT_LOAN_PLAN_5": "Plan 5",
      "UK_STUDENT_LOAN_PLAN_POSTGRADUATE": "Postgraduierter",
      "UK_SETTLED_STATUS_NA": "Nicht zutreffend",
      "UK_SETTLED_STATUS_PRE_SETTLED": "Vorab abgerechnet",
      "UK_SETTLED_STATUS_SETTLED": "Abgerechnet",
      "MARITAL_STATUS_SINGLE": "Einzeln",
      "MARITAL_STATUS_MARRIED": "Verheiratet",
      "MARITAL_STATUS_DIVORCED": "Geschieden",
      "MARITAL_STATUS_WIDOWED": "Verwitwet",
      "MARITAL_STATUS_CIVIL_PARTNERSHIP": "Lebenspartnerschaft",
      "PAYMENT_METHOD_ELECTRONIC": "Elektronischer Transfer",
      "PAYMENT_METHOD_CHEQUE": "Scheck",
      "PAYMENT_METHOD_MANUAL": "Manuell",
      "CONTRACTUAL_OBLIGATION_PERIOD_WEEK": "Pro Woche",
      "CONTRACTUAL_OBLIGATION_PERIOD_MONTH": "Pro Monat",
      "CONTRACTUAL_OBLIGATION_UNIT_HOURS": "Stunden",
      "CONTRACTUAL_OBLIGATION_UNIT_DAYS": "Tage",
      "PAY_AMOUNT_TYPE_HOURLY": "Stundensatz",
      "PAY_AMOUNT_TYPE_DAILY": "Tagesrate",
      "PAY_AMOUNT_TYPE_ANNUAL": "Jahresgehalt",
      "PAY_FREQUENCY_WEEKLY": "Wöchentlich",
      "PAY_FREQUENCY_FORTNIGHTLY": "Vierzehntägig",
      "PAY_FREQUENCY_MONTHLY": "Monatlich",
      "PAY_FREQUENCY_FOUR_WEEKLY": "Vierwöchentlich"
    },
    "OVERVIEW": {
      "ERROR_500": "Wir konnten gerade keine Daten laden. Bitte versuchen Sie es erneut.",
      "TITLE": "Mitarbeiter",
      "ADD_STAFF": "Neues hinzufügen",
      "NO_MATCH": "Wir konnten niemanden finden, der Ihren Filtern entspricht.",
      "NO_ACTIVE_APPT_TAG": "Inaktiv",
      "NOT_APPROVED_TAG": "Nicht genehmigt",
      "DOCUMENTS_INVALID_TAG": "Ungültige Dokumente",
      "NOT_INVITED_TAG": "Nicht eingeladen",
      "NOT_SETUP_TAG": "Eingeladen",
      "EMPLOYER_INCOMPLETE_TAG": "Unvollständig",
      "SELF_ONBOARDING": "Selbsteinstieg",
      "ACCOUNT_TAB_LABEL": "Konto",
      "PERSONAL_TAB_LABEL": "Persönlich",
      "EMPLOYMENT_TAB_LABEL": "Beschäftigung",
      "PAY_TAB_LABEL": "Zahlen",
      "SCHEDULE_TAB_LABEL": "Zeitplan",
      "ABSENCE_TAB_LABEL": "Abwesenheit",
      "AVAILABILITY_TAB_LABEL": "Verfügbarkeit",
      "SHIFT_ROUTINE_TAB_LABEL": "Schichtroutinen",
      "DOCUMENTS_TAB_LABEL": "Dokumente",
      "ATTENDANCE_TAB_LABEL": "Teilnahme",
      "NOTES_TAB_LABEL": "Hinweise",
      "FILTER_LABEL_SITE": "Standort/Abteilung",
      "FILTER_LABEL_POSITION": "Berufliche Rolle",
      "FILTER_LABEL_STAFF_GROUP": "Gruppe der Mitarbeiter",
      "FILTER_LABEL_EMPLOYMENT": "Beschäftigungsstatus",
      "FILTER_LABEL_APPROVAL_STATUS": "Status der Genehmigung",
      "FILTER_LABEL_EMPLOYEE_SETUP": "Rotaready-Einladung",
      "FILTER_LABEL_EMPLOYER_SETUP": "Kontoabschluss",
      "FILTER_LABEL_EMPLOYMENT_START": "Datum des Beitritts",
      "FILTER_LABEL_EMPLOYMENT_END": "Datum der Abreise",
      "FILTER_LABEL_EMPLOYMENT_START_OR_END": "Beitritts- oder Austragsdatum",
      "BUTTON_COLUMNS": "Kolumnen",
      "BUTTON_EXPORT": "Exportieren",
      "EMPLOYMENT_STATUS_ACTIVE": "Aktiv",
      "EMPLOYMENT_STATUS_INACTIVE": "Inaktiv",
      "APPROVAL_STATUS_APPROVED": "Genehmigt",
      "APPROVAL_STATUS_NOT_APPROVED": "Nicht genehmigt",
      "EMPLOYEE_SETUP_COMPLETE": "Akzeptiert",
      "EMPLOYEE_SETUP_NOT_COMPLETE": "Ausstehend oder nicht gesendet",
      "EMPLOYER_SETUP_COMPLETE": "Vollständig",
      "EMPLOYER_SETUP_NOT_COMPLETE": "Unvollständig",
      "COLUMN_NAME": "Name",
      "COLUMN_NEXT_SHIFT": "Nächste Schicht",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_BIRTH_DATE": "Geburtsdatum",
      "COLUMN_EMAIL": "E-Mail",
      "COLUMN_MOBILE": "Telefon",
      "COLUMN_OTHER_NAMES": "Andere (n) Name (n)",
      "COLUMN_ADDRESS_LINE_1": "Adresszeile 1",
      "COLUMN_ADDRESS_LINE_2": "Adresszeile 2",
      "COLUMN_ADDRESS_CITY": "Ort/Stadt",
      "COLUMN_ADDRESS_COUNTY": "Bezirk/Region",
      "COLUMN_ADDRESS_POST_CODE": "PLZ",
      "COLUMN_ADDRESS_COUNTRY": "Land",
      "COLUMN_PREFERRED_NAME": "Bevorzugter Name",
      "COLUMN_GENDER": "Geschlecht",
      "COLUMN_TITLE": "Titel",
      "COLUMN_ETHNICITY": "Ethnizität",
      "COLUMN_NATIONALITY": "Nationalität",
      "COLUMN_MARITAL_STATUS": "Familienstand",
      "COLUMN_SETTLED_STATUS": "Status „Abgeschlossen“ (Vereinigtes Königreich)",
      "COLUMN_PASSPORT_NUMBER": "Nummer des Reisepasses",
      "COLUMN_PASSPORT_EXPIRY_DATE": "Ablauf des Reisepasses",
      "COLUMN_WORK_EMAIL": "Sekundäre E-Mail",
      "COLUMN_WORK_PHONE": "Sekundäres Telefon",
      "COLUMN_BANK_ACCOUNT_NAME": "Name des Bankkontos",
      "COLUMN_BANK_ACCOUNT_NUMBER": "Nummer des Bankkontos",
      "COLUMN_BANK_ACCOUNT_SORT_CODE": "Bankleitzahl",
      "COLUMN_PAYROLL_PAYMENT_METHOD": "Methode der Zahlung",
      "COLUMN_NATIONAL_INSURANCE_NUMBER": "Sozialversicherungsnummer (Vereinigtes Königreich)",
      "COLUMN_STUDENT_LOANS": "Studentendarlehen (Vereinigtes Königreich)",
      "COLUMN_AVERAGE_HOURS_DAY": "Durchschnittliche Stunden/Tag",
      "COLUMN_AVERAGE_HOURS_HALF_DAY": "Durchschnittliche Stunden/halber Tag",
      "COLUMN_AVERAGE_HOURS_WEEK": "Durchschnittliche Stunden/Woche",
      "COLUMN_PRE_AVERAGE_HOURS_PER_DAY": "Pre-Rotaready: durchschnittliche Stunden/Tag",
      "COLUMN_PRE_AVERAGE_HOURS_DATE": "Datum der durchschnittlichen Betriebsstunden vor Rota",
      "COLUMN_PERMISSION_LEVEL_NAME": "Berechtigungsstufe",
      "COLUMN_ANONYMISED": "Anonymisiert",
      "COLUMN_ANONYMISE_DATE": "Datum anonymisieren",
      "COLUMN_JOIN_DATE": "Datum des Beitritts",
      "COLUMN_LEAVE_DATE": "Datum der Abreise",
      "COLUMN_LEAVE_DATE_EDITED_BY": "Abreisedatum festgelegt von",
      "COLUMN_LEAVE_DATE_EDITED_DATE": "Abreisedatum Datum festlegen",
      "COLUMN_LENGTH_OF_SERVICE": "Dauer des Dienstes (Tage)",
      "COLUMN_RECRUITMENT_SOURCE": "Rekrutierungsquelle",
      "COLUMN_LEAVER_REASON": "Grund für den Austritt",
      "COLUMN_LEAVER_REHIRE": "Wiedereingliederung berechtigt",
      "COLUMN_EMPLOYEE_NUMBER": "Nummer des Mitarbeiters",
      "COLUMN_NOTICE_PERIOD": "Kündigungsfrist",
      "COLUMN_STARTER_DECLARATION": "Startererklärung (Vereinigtes Königreich)",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED": "Die Vergütungen sind abgeschlossen",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_BY": "Die Vergütungen wurden finalisiert von",
      "COLUMN_ALLOWANCES_ATTESTED_FINALISED_DATE": "Enddatum der Zulagen",
      "COLUMN_SITE_NAME": "Standort",
      "COLUMN_SITE_ID": "Seiten-ID",
      "COLUMN_DEPARTMENT_NAME": "Abteilung",
      "COLUMN_DEPARTMENT_ID": "Abteilungs-ID",
      "COLUMN_JOB_ROLE_NAME": "Berufliche Rolle",
      "COLUMN_COMPANY_NAME": "Firma",
      "COLUMN_CONTRACT_BASIS": "Art des Vertrags",
      "COLUMN_LINE_MANAGER_NAME": "Vorgesetzter",
      "COLUMN_STAFF_GROUP_NAME": "Gruppe der Mitarbeiter",
      "COLUMN_WORKS_PUBLIC_HOLIDAYS": "Arbeitet an Feiertagen",
      "COLUMN_WORKING_TIME_OPT_OUT": "Abmeldung von Arbeitszeitregelungen",
      "COLUMN_MIN_OBLIGATION_VALUE": "Wert der vertraglichen Verpflichtung",
      "COLUMN_MIN_OBLIGATION_UNIT": "Einheit für vertragliche Verpflichtungen",
      "COLUMN_MIN_OBLIGATION_PERIOD": "Vertragliche Bindungsdauer",
      "COLUMN_MAX_OBLIGATION_VALUE": "Arbeitsgrenzwert",
      "COLUMN_MAX_OBLIGATION_UNIT": "Arbeitsgrenzeinheit",
      "COLUMN_MAX_OBLIGATION_PERIOD": "Dauer der Arbeitsbegrenzung",
      "COLUMN_DEPARTMENT_CURRENCY_CODE": "Währungscode",
      "COLUMN_PAYROLL_CALENDAR_NAME": "Gehaltsabrechnungskalender",
      "COLUMN_PAYROLL_CALENDAR_FREQUENCY": "Häufigkeit des Abrechnungskalenders",
      "COLUMN_PAY_AMOUNT": "Betrag zahlen",
      "COLUMN_PAY_AMOUNT_TYPE": "Art des Zahlungsbetrags",
      "COLUMN_PAY_UPLIFT": "Gebührenerhöhung",
      "ASSET_REGISTRY_TAB_LABEL": "Vermögensregister"
    },
    "VIEW": {
      "ACCOUNT": {
        "ERROR_500": "Wir können Ihre Änderung derzeit nicht verarbeiten. Bitte versuchen Sie es erneut.",
        "ERROR_403": "Sie sind nicht berechtigt, die Kontoinformationen dieser Person einzusehen/zu ändern.",
        "SAVE_SUCCESS": "Ihre Änderungen wurden gespeichert.",
        "TITLE": "Konto",
        "LAST_SIGN_IN_LABEL": "Letzte Anmeldung",
        "LAST_SIGN_IN_NEVER": "Niemals",
        "SECTION_PERMISSIONS_TITLE": "Genehmigungen",
        "PERMISSION_LEVEL_LABEL": "Berechtigungsstufe",
        "PERMISSION_LEVEL_HELP": "Dadurch wird sich ändern, was der Mitarbeiter in Rotaready sehen und tun kann.",
        "SITE_ACCESS_LABEL": "Zugriff auf die Website",
        "SITE_ACCESS_OPTION_APPOINTMENT": "Ihre aktuelle Seite",
        "SITE_ACCESS_OPTION_ALL": "Alle Seiten",
        "SITE_ACCESS_OPTION_CUSTOM": "Ihre aktuelle Website und benutzerdefinierte Websites",
        "CLOCK_IN_PIN_LABEL": "Clock-in-PIN zurücksetzen",
        "CLOCK_IN_PIN_HELP": "Geben Sie einen neuen 4-stelligen Code ein, um die Anmelde-PIN dieses Mitarbeiters zu ändern.",
        "TAGS_LABEL": "Schlagworte",
        "TAGS_TIP": "Kennzeichnen Sie Mitarbeiter mit aussagekräftigen Informationen wie Fähigkeiten oder Attributen.",
        "TAGS_PLACEHOLDER": "Tippe, um Schlagworte zu finden",
        "SAVE_BUTTON_LOADING": "Wird gespeichert...",
        "SAVE_BUTTON_TEXT": "Speichern",
        "LANGUAGE_LABEL": "Sprache",
        "LANGUAGE_HELP": "Rotaready wird in der ausgewählten Sprache angezeigt.",
        "ENTITY_GROUP_SUBSCRIPTION_TOOLTIP": "Dieser Mitarbeiter wird automatisch dieser Website zugewiesen. Sie können ihren Zugang auf der Einstellungsseite des Site Access Managers verwalten."
      },
      "PAY": {
        "INDEX": {
          "TAB_HISTORY": "Aufzeichnungen bezahlen",
          "TAB_TRONC": "Tronc",
          "TAB_FINANCIALS": "Finanzdaten"
        },
        "VIEW_HISTORY": {
          "FILTER_TYPES_LABEL": "Typ",
          "FILTER_TYPES_SALARY": "Gehalt",
          "FILTER_TYPES_POINT_IN_TIME": "Einmalige Zahlungen",
          "FILTER_ELEMENT_TYPES_LABEL": "Element bezahlen",
          "STATE_ACTIVE": "Aktiv",
          "STATE_HISTORICAL": "Historisch",
          "STATE_FUTURE_DATED": "In die Zukunft datiert",
          "COLUMN_EFFECTIVE_DATE": "Datum des Inkrafttretens",
          "COLUMN_STATUS": "Status",
          "COLUMN_ELEMENT_TYPE": "Element bezahlen",
          "COLUMN_CURRENCY": "Währung",
          "COLUMN_AMOUNT": "Betrag",
          "COLUMN_AMOUNT_TYPE": "Art des Betrags",
          "COLUMN_OPTIONS": "Optionen",
          "ERROR_500": "Wir können derzeit keine Gehaltsaufzeichnungen laden. Bitte versuchen Sie es erneut.",
          "ERROR_403": "Sie sind nicht berechtigt, die Gehaltsaufzeichnungen dieser Person einzusehen/zu ändern.",
          "ALERT_SUCCESS": "Der Gehaltsnachweis wurde gespeichert.",
          "ALERT_DELETED": "Der Gehaltsdatensatz wurde gelöscht.",
          "OUTSIDE_OF_APPOINTMENT_WARNING": "Dieser Gehaltsnachweis ist ungültig, da sie an diesem Tag nicht aktiv angestellt sind. Bitte ändern Sie das Datum des Inkrafttretens.",
          "DELETE_LINK": "Löschen",
          "LAST_EDITED_TOOLTIP": "Zuletzt bearbeitet von {{userName}} am {{date}}",
          "NO_RECORDS": "Es sind keine Gehaltsnachweise vorzuweisen.",
          "ADD_NEW_BUTTON": "Neuer Gehaltsrekord",
          "CONFIRM_DELETE": "Sind Sie sicher, dass Sie diesen Gehaltsdatensatz löschen möchten? Das kann nicht rückgängig gemacht werden."
        },
        "PAY_FORM": {
          "TITLE": "Zahlen",
          "LABEL_EFFECTIVE_DATE": "Datum des Inkrafttretens",
          "LABEL_ELEMENT": "Element bezahlen",
          "LABEL_AMOUNT": "Betrag",
          "LABEL_AMOUNT_TYPE": "Art des Betrags",
          "LABEL_UPLIFT": "Lohnerhöhung",
          "ALERT_NO_CURRENCY": "Der Mitarbeiter ist an diesem Tag nicht aktiv angestellt.",
          "ALERT_DUPLICATE_PAY": "An diesem Tag gibt es bereits einen Gehaltsnachweis für das von Ihnen gewählte Zahlungselement.",
          "HELP_TEXT_UPLIFT": "Dadurch werden die Löhne des Mitarbeiters in Cost Control in die Höhe getrieben. Verwenden Sie es, um alle zusätzlichen Kosten abzubilden, die Ihnen entstehen, z. B. eine Rente. Machen Sie sich keine Sorgen um Steuern, da diese automatisch enthalten sind.",
          "BUTTON_SAVE": "Speichern",
          "BUTTON_SAVE_LOADING": "Wird gespeichert...",
          "BUTTON_DELETE": "Löschen",
          "BUTTON_DELETE_LOADING": "Löschen..."
        },
        "VIEW_FINANCIALS": {
          "ERROR_500": "Wir können die Finanzinformationen derzeit nicht laden. Bitte versuchen Sie es erneut.",
          "ERROR_403": "Sie sind nicht berechtigt, die Finanzinformationen dieser Person einzusehen/zu ändern.",
          "SAVE_SUCCESS": "Ihre Änderungen wurden gespeichert.",
          "SECTION_BANK_ACCOUNT": "Bankkonto",
          "SECTION_TAXES": "Steuern",
          "ACCOUNT_NUMBER_LABEL": "Kontonummer",
          "SORT_CODE_LABEL": "Sortiercode",
          "ACCOUNT_NAME_LABEL": "Kontoname",
          "PAYMENT_METHOD": "Methode der Zahlung",
          "NI_NUMBER_LABEL": "Sozialversicherungsnummer",
          "SECTION_STUDENT_LOANS": "Studentendarlehen (Vereinigtes Königreich)",
          "PLAN_TYPE_LABEL": "Art des Plans",
          "EFFECTIVE_DATE_LABEL": "Datum des Inkrafttretens",
          "LOAN_TABLE_PLAN_COLUMN": "Art des Plans",
          "LOAN_TABLE_DATE_COLUMN": "Datum des Inkrafttretens",
          "LOAN_TABLE_OPTIONS_COLUMN": "Optionen",
          "LOAN_TABLE_NO_ROWS": "Keine Studentendarlehen hinzugefügt.",
          "LOAN_TABLE_DATE_NOT_SET": "Nicht festgelegt",
          "SUBHEADING_ADD_STUDENT_LOAN": "Studentendarlehen hinzufügen",
          "OPTION_DELETE": "Löschen",
          "ADD_LOAN_BUTTON": "Studentendarlehen hinzufügen",
          "SAVE_BUTTON_TEXT": "Speichern"
        },
        "EDIT_STUDENT_LOAN": {
          "HEADING": "Studienkredit bearbeiten",
          "FORM_PLAN_TYPE_LABEL": "Plantyp",
          "FORM_EFFECTIVE_DATE_LABEL": "Gültigkeitsdatum",
          "SAVE_BUTTON_TEXT": "Speichern"
        },
        "VIEW_TRONC": {
          "COLUMN_EFFECTIVE_DATE": "Datum des Inkrafttretens",
          "COLUMN_STATUS": "Status",
          "COLUMN_AMOUNT": "Betrag",
          "COLUMN_APPORTION_TYPE": "Art der Aufteilung",
          "COLUMN_LAST_EDITED": "Zuletzt bearbeitet",
          "STATE_ACTIVE": "Aktiv",
          "STATE_HISTORICAL": "Historisch",
          "STATE_FUTURE_DATED": "In die Zukunft datiert",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Stundensatz",
          "APPORTION_TYPE_CLASSIC_SALARY": "Jahresgehalt",
          "APPORTION_TYPE_POINTS": "Punkte",
          "NO_RECORDS": "Es gibt keine Tronc-Tarife zum Anzeigen.",
          "ADD_NEW_BUTTON": "Neuer Tronc-Tarif",
          "ERROR_500": "Wir können derzeit keine Tronc-Tarife laden. Bitte versuchen Sie es erneut.",
          "ERROR_403": "Sie sind nicht berechtigt, die Tronc-Tarife dieser Person einzusehen."
        },
        "ADD_TRONC_MODAL": {
          "TITLE": "Neuer Tronc-Tarif",
          "BUTTON_SAVE": "Speichern",
          "BUTTON_CANCEL": "Stornieren",
          "LABEL_PERSON": "Person",
          "LABEL_DATE": "Datum des Inkrafttretens",
          "LABEL_APPORTION_TYPE": "Art der Aufteilung",
          "LABEL_AMOUNT": "Betrag",
          "APPORTION_TYPE_CLASSIC_HOURLY": "Stundensatz",
          "APPORTION_TYPE_CLASSIC_SALARY": "Jahresgehalt",
          "APPORTION_TYPE_POINTS": "Punkte",
          "APPORTION_TYPE_HELP": "Tronc kann in einem von zwei Modi arbeiten: punktebasiert oder klassisch, bei dem ein Stundensatz oder ein Gehalt verwendet wird. Dies wird durch eine Einstellung für Ihre Organisation oder den spezifischen Standort/Veranstaltungsort gesteuert.",
          "ERROR_OTHER": "Wir können den Tronc-Tarif momentan nicht speichern. Bitte versuchen Sie es erneut.",
          "ERROR_UNAUTHORIZED": "Sie sind nicht berechtigt, die Tronc-Tarife dieser Person zu ändern.",
          "ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE": "Dieser Mitarbeiter hat an dem von Ihnen gewählten Datum des Inkrafttretens keinen aktiven Termin.",
          "ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE": "Jemand hat bereits damit begonnen, Tronc für das von Ihnen gewählte Datum des Inkrafttretens zu entwerfen. Bitte verwenden Sie das Tronc-Tool, um die Preise für dieses Datum zu bearbeiten.",
          "ERROR_NOT_SUPPORTED": "Sie können diesen Aufteilungstyp nicht wählen. Tronc ist nicht für den Betrieb in diesem Modus konfiguriert."
        }
      },
      "INDEX": {
        "OPTIONS_LABEL": "Optionen",
        "OPTION_APPROVE_LINK": "Dienstpläne und Gehaltsabrechnung genehmigen",
        "OPTION_SEND_MAGIC_LINK": "Laden Sie zu Rotaready ein (senden Sie einen magischen Link)",
        "OPTION_SEND_MAGIC_LINK_TOOLTIP": "Diese Person hat ihr Konto bereits eingerichtet. Wenn sie Probleme beim Einloggen haben, bitten Sie sie, dem Link zur Kontowiederherstellung auf der Anmeldeseite zu folgen.",
        "OPTION_ANONYMISE": "Anonymisieren",
        "OPTION_DELETE": "Löschen",
        "ALERT_APPROVE_SUCCESS": "Sie sind jetzt zugelassen und bereit, Schichten zu erhalten.",
        "ALERT_APPROVE_ERROR": "Wir konnten das Konto gerade nicht genehmigen. Bitte versuchen Sie es erneut.",
        "ALERT_MAGIC_LINK_SUCCESS_ALL": "Wir haben eine E-Mail und eine Textnachricht mit einem magischen Link gesendet. Bitte warten Sie ein paar Minuten, bis es eintrifft.",
        "ALERT_MAGIC_LINK_SUCCESS_EMAIL": "Wir haben eine E-Mail mit einem magischen Link gesendet. Bitte warten Sie ein paar Minuten, bis es eintrifft.",
        "ALERT_MAGIC_LINK_SUCCESS_SMS": "Wir haben eine Textnachricht mit einem magischen Link gesendet. Bitte warten Sie ein paar Minuten, bis es eintrifft.",
        "ALERT_MAGIC_LINK_ERROR_GENERIC": "Wir konnten gerade keinen magischen Link senden.",
        "ALERT_MAGIC_LINK_ERROR_NO_DETAILS": "Wir können keinen magischen Link ohne E-Mail-Adresse oder Telefonnummer senden.",
        "ALERT_ACCOUNT_ERROR_500": "Wir konnten den Mitarbeiter gerade nicht laden. Bitte versuchen Sie es erneut.",
        "ALERT_ACCOUNT_ERROR_404": "Mit dieser ID ist kein Mitarbeiterkonto verknüpft.",
        "DELETE_ACCOUNT_TITLE": "Mitarbeiter löschen",
        "DELETE_ACCOUNT_MESSAGE": "Warnung! Das Löschen eines Mitarbeiters ist dauerhaft. Tun Sie dies nur, wenn Sie den Mitarbeiter versehentlich erstellt haben. Um fortzufahren, geben Sie unten ihren vollständigen Namen ein.",
        "DELETE_ACCOUNT_BUTTON": "Dauerhaft löschen",
        "DELETE_ACCOUNT_ERROR": "Der Mitarbeiter konnte nicht gelöscht werden. Möglicherweise haben Sie dazu keine Erlaubnis.",
        "DELETE_ACCOUNT_SUCCESS": "Der Mitarbeiter wurde gelöscht."
      },
      "PERSONAL": {
        "ERROR_500": "Wir können Ihre Änderung derzeit nicht verarbeiten. Bitte versuchen Sie es erneut.",
        "ERROR_403": "Sie sind nicht berechtigt, die persönlichen Daten dieser Person einzusehen/zu ändern.",
        "SAVE_SUCCESS": "Ihre Änderungen wurden gespeichert.",
        "EMERGENCY_CONTACTS_LINK_TEXT": "Kontakte für Notfälle",
        "FINANCIALS_LINK_TEXT": "Finanzen/Steuern",
        "LABEL_OPTIONS": "Mehr Optionen",
        "LABEL_APPROVED_BY": "Genehmigt von",
        "TITLE_LABEL": "Titel",
        "FIRST_NAME_LABEL": "Vorname",
        "LAST_NAME_LABEL": "Nachname",
        "OTHER_NAME_LABEL": "Andere Namen (optional)",
        "DOB_LABEL": "Geburtsdatum",
        "DOB_HELP": "{{age}} Jahre alt",
        "PREFERRED_NAME_LABEL": "Bevorzugter Name",
        "SECTION_ADDRESS": "Adresse",
        "SECTION_VISAS": 'Visa',
        "SECTION_IDENTITY": "Identität",
        "SECTION_CONTACT": "Kontakt",
        "SECTION_OTHER": "Andere",
        "SECTION_RIGHT_TO_WORK": "Arbeitsberechtigung",
        "NATIONALITY_LABEL": "Nationalität",
        "GENDER_LABEL": "Geschlecht",
        "ETHNICITY_LABEL": "Ethnizität",
        "MARITAL_STATUS_LABEL": "Familienstand",
        "PASSPORT_NUMBER_LABEL": "Nummer des Reisepasses",
        "PASSPORT_EXPIRY_LABEL": "Ablaufdatum des Reisepasses",
        "SETTLED_STATUS_LABEL": "Status „Abgeschlossen“ (Vereinigtes Königreich)",
        "PRIMARY_EMAIL_LABEL": "Primäre E-Mail",
        "PRIMARY_EMAIL_HELP": "Damit meldet sich der Mitarbeiter bei Rotaready an. Es kann nur geändert werden, wenn sie sich noch nicht angemeldet haben.",
        "PRIMARY_PHONE_LABEL": "Primäres Telefon",
        "PRIMARY_PHONE_HELP": "Der Mitarbeiter verwendet dies, um Benachrichtigungen zu erhalten. Das richtige Landeskennzahlpräfix wird nach dem Speichern automatisch angewendet.",
        "SECONDARY_EMAIL_LABEL": "Sekundäre E-Mail",
        "SECONDARY_PHONE_LABEL": "Sekundäres Telefon",
        "HR_ID_LABEL": "IHR KIND",
        "HR_ID_HELP": "Wenn dieser Mitarbeiter eine ID-Nummer hat, die für Personalzwecke verwendet wird, können Sie diese hier speichern.",
        "PAYROLL_ID_LABEL": "Gehaltsabrechnungsnummer",
        "PAYROLL_ID_HELP": "Wenn dieser Mitarbeiter eine Identifikationsnummer hat, die für Gehaltsabrechnungszwecke verwendet wird, können Sie diese hier speichern.",
        "SAVE_BUTTON_LOADING": "Wird gespeichert...",
        "SAVE_BUTTON_TEXT": "Speichern",
        "LINK_ADD_ADDRESS": "Neue Adresse hinzufügen",
        "LINK_VIEW_ADDRESS_HISTORY": "Frühere Adressen ansehen",
        "NO_ADDRESS_HISTORY_TEXT": "Es gibt keine früheren Adressen",
        "CURRENT_ADDRESS_LABEL": "Aktuell",
        "SHARE_CODE_LABEL": "Teilen-Code",
        "SHARE_CODE_ERROR_LENGTH": "Der Teilen-Code muss 9 Zeichen lang sein.",
        "SHARE_CODE_ERROR_FORMAT": "Der Teilen-Code darf nur alphanumerische Zeichen enthalten.",
        "SHARE_CODE_EXPIRY_DATE_LABEL": "Ablaufdatum des Teilen-Codes",
      },
      "ADDRESS_HISTORY": {
        "ALERT_ERROR_500": "Wir können Ihre Änderung derzeit nicht verarbeiten. Bitte versuchen Sie es erneut.",
        "TITLE_ADD": "Neue Adresse hinzufügen",
        "TITLE_EDIT": "Adresse bearbeiten",
        "ADVICE_EDIT": "Wenn sich die Adresse des Mitarbeiters ändert, sollten Sie stattdessen eine neue Adresse hinzufügen. Rotaready verschiebt die aktuelle Adresse automatisch in ihren Adressverlauf.",
        "ADVICE_EDIT_LINK": "Neue Adresse hinzufügen",
        "SAVE_BUTTON_TEXT": "Speichern",
        "DELETE_BUTTON_TEXT": "Löschen",
        "ADDRESS_LINE_1_LABEL": "Zeile 1",
        "ADDRESS_LINE_2_LABEL": "Zeile 2 (optional)",
        "ADDRESS_CITY_LABEL": "Ort/Stadt",
        "ADDRESS_COUNTY_LABEL": "Bezirk",
        "ADDRESS_POST_CODE_LABEL": "PLZ",
        "ADDRESS_COUNTRY_LABEL": "Land",
        "NOTES_LABEL": "Hinweise",
        "CONFIRM_DELETE": "Bist du sicher, dass du diese Adresse löschen möchtest?"
      },
      "VISAS": {
        "ADD_BTN": "Visum hinzufügen",
        "CURRENT_HEADING": "Aktives Visum",
        "NO_CURRENT_VISA": "Dieser Benutzer hat kein aktives Visum.",
        "CURRENT_LABEL": "Aktiv",
        "VISA_TYPE_LABEL": "Visumstyp",
        "VISA_NUMBER_LABEL": "Visumnummer",
        "VISA_EXPIRY_LABEL": "Ablaufdatum des Visums",
        "VISA_EXPIRY_NOT_APPLICABLE": "Nicht zutreffend",
        "VISA_EDIT_BTN": "Visum bearbeiten",
        "PREVIOUS_LABEL": "Vorherige",
        "SHOW_PREVIOUS_BTN": "Vorherige Visa anzeigen",
        "HIDE_PREVIOUS_BTN": "Vorherige Visa ausblenden",
        "PREVIOUS_HEADING": "Vorheriges Visum",
        "MODAL": {
          "ADD_HEADING": "Neues Visum hinzufügen",
          "ALERT_ERROR_500": "Wir können Ihre Änderung gerade nicht verarbeiten. Bitte versuchen Sie es erneut.",
          "FORM_LABEL_VISA_TYPE": "Visumstyp",
          "FORM_LABEL_VISA_NUMBER": "Visumnummer",
          "FORM_LABEL_IS_CURRENT": "Dies ist das aktuelle Visum des Benutzers",
          "FORM_LABEL_HAS_EXPIRY_DATE": "Dieses Visum hat ein Ablaufdatum",
          "FORM_LABEL_VISA_EXPIRY": "Ablaufdatum des Visums",
          "FORM_BTN_SAVE": "Speichern",
          "EDIT_VISA_HEADING": "Visum bearbeiten",
          "DELETE_VISA_BTN": "Visum löschen",
          "CONFIRM_DELETE": "Sind Sie sicher, dass Sie dieses Visum löschen möchten?",
        }
      },
      "SCHEDULE": {
        "TITLE": "Der Kalender unten zeigt den vollständigen Zeitplan von {{firstName}}, einschließlich Schichten, Fehlzeiten und Verfügbarkeit.",
        "SHIFTS_LABEL": "Schichten",
        "ABSENCE_LABEL": "Abwesenheit",
        "UNAVAILABLE_LABEL": "Nicht verfügbar (Muster)",
        "EDIT_LINK_TEXT": "Ausnahmen bearbeiten"
      },
      "ADD_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Routine für Kartenwechsel",
        "HAS_OVERLAP_TEXT": "Die oben genannten Daten überschneiden sich mit einer bestehenden Schichtroutine.",
        "END_BEFORE_START_TEXT": "Das Startdatum liegt vor dem Enddatum.",
        "SAVE_BUTTON_TEXT": "Speichern",
        "SAVE_BUTTON_LOADING": "Wird gespeichert...",
        "CANCEL_BUTTON_TEXT": "Stornieren"
      },
      "DELETE_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Bist du sicher?",
        "WARNING": "Wenn Sie diese Zuordnung löschen, werden alle damit verbundenen zukünftigen Schichten entfernt.",
        "YES_BUTTON_TEXT": "Ja",
        "YES_BUTTON_ADDITIONAL_TEXT": "Diese Zuordnung löschen",
        "NO_BUTTON_TEXT": "Nein",
        "NO_BUTTON_ADDITIONAL_TEXT": "Ich habe meine Meinung geändert",
        "CANCEL_BUTTON_TEXT": "Stornieren"
      },
      "EDIT_ROUTINE_MAPPING_MODAL": {
        "TITLE": "Schichtroutinenzuordnung bearbeiten",
        "END_DATE_LABEL": "Enddatum",
        "CONTINUE_FOREVER_LABEL": "Schichtroutine für immer fortsetzen",
        "END_BEFORE_START_TEXT": "Das Enddatum liegt vor dem Startdatum.",
        "HAS_OVERLAP_NO_END": "Die Festlegung dieses Enddatums würde zu einer Überschneidung mit einer bestehenden Schichtroutine führen.",
        "HAS_OVERLAP_END": "Wenn Sie kein Enddatum festlegen, würde dies zu einer Überschneidung mit einer bestehenden Schichtroutine führen.",
        "SAVE_BUTTON_TEXT": "Speichern",
        "SAVE_BUTTON_LOADING_TEXT": "Wird gespeichert...",
        "CANCEL_BUTTON_TEXT": "Stornieren"
      },
      "SHIFT_ROUTINES": {
        "MAP_ROUTINE_BUTTON_TEXT": "Karte zur Routine",
        "MAP_ROUTINE_SUBTITLE": "Routinezuordnungen für Schichten",
        "MAP_ROUTINE_TIP": "Wenn Sie jemanden einer Schichtroutine zuordnen, werden Schichten automatisch erstellt. Sie werden gelöscht, wenn du die Zuordnung löschst oder wenn du ein Enddatum hinzufügst.",
        "EDIT_END_DATE_TEXT": "Enddatum bearbeiten",
        "ADD_END_DATE_TEXT": "Enddatum hinzufügen",
        "MAPPING_NO_END": "Stand: ",
        "MAPPING_END": " zu ",
        "USER_HAS_NO_MAPPING": "{{firstName}} ist keinen Schichtroutinen zugeordnet. Sie können eine hinzufügen, indem Sie die Schaltfläche oben verwenden."
      },
      "ABSENCE": {
        "INDEX": {
          "TAB_ABSENCE": "Abwesenheitsbuchungen",
          "TAB_ALLOWANCES": "Zulagen",
          "TAB_SETTINGS": "Einstellungen"
        },
        "VIEW_SETTINGS": {
          "ABSENCE_HOURS_LABEL": "Durchschnittliche Stunden pro Tag",
          "AVERAGE_LENGTH_LABEL": "Automatische Berechnung auf der Grundlage der durchschnittlichen Dauer ihres Arbeitstages",
          "SET_MANUALLY_LABEL": "Manuell einstellen",
          "HOURS_FULL_DAY_LABEL": "Ganzer Tag",
          "HOURS_LABEL": "Stunden",
          "HOURS_HELP": "Ein ganzer Tag Abwesenheit wird so viele Stunden wert sein.",
          "HOURS_HALF_DAY_LABEL": "Halber Tag",
          "HOURS_HALF_DAY_HELP": "Ein halber Tag Abwesenheit wird so viele Stunden wert sein.",
          "DELEGATE_APPROVERS": "Genehmigende Personen delegieren",
          "DELEGATE_APPROVERS_REMOVE": "entfernen",
          "DELEGATE_APPROVERS_HELP": "Wenn dieser Mitarbeiter „Bitte nicht stören“ in der Rotaready-App aktiviert und eine Abwesenheitsanfrage gestellt wird, die seine Zustimmung erfordert, werden stattdessen die bevollmächtigten Genehmigenden benachrichtigt.",
          "SAVE_BUTTON_TEXT": "Speichern",
          "SAVE_BUTTON_LOADING_TEXT": "Wird gespeichert...",
          "PRE_ROTAREADY_AVERAGE_HOURS_LABEL": "Pre-Rotaready: durchschnittliche Stunden pro Tag",
          "PRE_ROTAREADY_AVERAGE_HOURS_HELP": "Wenn Sie während der Anstellung dieser Person von einem anderen System zu Rotaready gewechselt sind, geben Sie deren letzte bekannte „durchschnittliche Stundenzahl pro Tag“ und das Datum ein, an dem diese Person gültig war.",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_LABEL": "Zuletzt gültig am",
          "PRE_ROTAREADY_AVERAGE_HOURS_DATE_HELP": "Wenn der Mitarbeiter in den letzten 52 Wochen nicht mit Rotaready gearbeitet hat, wird bei der Berechnung eines neuen Werts für die durchschnittlichen Stunden pro Tag der Wert Pre-RotaReady für die Wochen vor diesem Datum verwendet."
        },
        "VIEW_BOOKINGS": {
          "INCLUDE_CANCELLED_TOGGLE_LABEL": "Storniert einbeziehen",
          "NO_BOOKINGS_MATCH_TEXT": "Wir konnten keine Buchungen finden, die Ihren Filtern entsprechen.",
          "BOOK_ABSENCE_BUTTON_TEXT": "Abwesenheit buchen",
          "CANCEL_LINK": "Stornieren",
          "DATES_COLUMN_TEXT": "Datum (e)",
          "ABSENCE_TYPE_COLUMN_TEXT": "Typ",
          "HOURS_COLUMN_TEXT": "Stunden",
          "DAYS_COLUMN_TEXT": "Tage",
          "STATUS_COLUMN_TEXT": "Status",
          "SUBMITTED_COLUMN_TEXT": "Eingereicht",
          "APPROVED_COLUMN_TEXT": "Überprüft",
          "CANCELLED_COLUMN_TEXT": "Abgesagt",
          "FILTER_LABEL_APPROVAL_STATE": "Status",
          "FILTER_LABEL_ABSENCE_TYPE": "Typ",
          "FILTER_LABEL_DATE": "Datum",
          "CANCEL_REQUEST": {
            "CONFIRM": "Möchten Sie diese Abwesenheitsbuchung wirklich stornieren? Das kann nicht rückgängig gemacht werden.",
            "SUCCESS": "Die Abwesenheitsbuchung wurde storniert.",
            "ERROR_500": "Das konnten wir gerade nicht stornieren. Bitte versuchen Sie es erneut."
          }
        },
        "VIEW_ALLOWANCES": {
          "ERROR_500": "Ups, da sind wir auf einen Haken gestoßen. Bitte versuchen Sie es erneut.",
          "BUTTON_ADD": "Neue Zulage",
          "BUTTON_OPTIONS": "Optionen",
          "BUTTON_TRANSACTIONS": "Transaktionen anzeigen",
          "BUTTON_EDIT": "Bearbeiten",
          "BUTTON_DELETE": "Löschen",
          "NO_ALLOWANCES_TEXT": "Es gibt keine Zulagen, die Ihren Filtern entsprechen.",
          "FILTER_LABEL_STATUS": "Status",
          "FILTER_LABEL_ABSENCE_TYPE": "Typ",
          "CHRONO_TYPE_ACTIVE": "Aktiv",
          "CHRONO_TYPE_FUTURE": "Zukunft",
          "CHRONO_TYPE_HISTORICAL": "Historisch",
          "LABEL_NOT_DEDUCTIBLE": "Nicht abzugsfähig",
          "METRIC_ACCRUED": "Anspruch",
          "METRIC_BOOKED": "Gebucht",
          "METRIC_REMAINING": "Verbleibend",
          "METRIC_ACCRUED_LIMIT": "von {{count}}",
          "METRIC_ACCRUED_LIMIT_HELP": "Dies ist das Maximum, das angesammelt werden kann.",
          "METRIC_ELAPSED": "abgelaufen",
          "METRIC_ELAPSED_HELP": "Dies ist die Anzahl der gebuchten Tage/Stunden, die in der Vergangenheit liegen. Dies wird über Nacht automatisch neu berechnet.",
          "METRIC_OVERSPEND": "zu viel ausgeben",
          "METRIC_OVERSPEND_HELP": "Dies ist die Anzahl der Tage/Stunden, die über den aufgelaufenen Betrag hinaus gebucht werden können.",
          "METRIC_ACCRUAL_ESTIMATE": "anfallen",
          "METRIC_ACCRUAL_ESTIMATE_HELP": "Auf der Grundlage Ihrer durchschnittlichen Arbeitszeit schätzen wir, dass Sie diese bis zum Ende des Vergütungsjahres anfallen werden.",
          "SCHEDULE_BEGINNING_OF_ACCRUAL_YEAR": "Fällt zu Beginn jedes Abgrenzungsjahres an",
          "SCHEDULE_EACH_CALENDAR_MONTH": "Fällt jeden Kalendermonat an",
          "SCHEDULE_EACH_HOUR_WORKED": "Fällt für jede geleistete Arbeitsstunde an",
          "SCHEDULE_EACH_HOUR_WORKED_RATE": "Fällt für jede geleistete Arbeitsstunde zu {{rate}}% an",
          "CARRIED_OVER": "{{amount}} aus dem letzten Jahr übertragen",
          "LINK_TRANSACTIONS": "Transaktionen anzeigen",
          "ALERT_CONFIRM_DELETE": "Möchten Sie diese Zulage wirklich löschen? Das kann nicht rückgängig gemacht werden.",
          "ALERT_SUCCESS_DELETE": "Die Zulage wurde gelöscht.",
          "FINALISED": "🔒 Abgeschlossen ({{date}})",
          "FINALISE": "Finalisieren",
          "CONVERT_HOURS_TO_DAYS_TOGGLE_LABEL": "Stunden nach Tagen umrechnen"
        },
        "ALLOWANCE_VALIDATION_MODAL": {
          "TITLE": "Etwas stimmt nicht ganz",
          "DESCRIPTION": "Leider verursacht das, was Sie versuchen zu tun, ein oder mehrere Probleme.",
          "BUTTON_CLOSE": "Schliessen",
          "INVALID_ALLOWANCE_TITLE": "Ungültiger Freibetrag",
          "INVALID_ALLOWANCE_DESCRIPTION": "Die Zulage wurde inzwischen gelöscht und kann nicht mehr bearbeitet werden.",
          "OVERLAPPING_ALLOWANCE_TITLE": "Überlappende Zulagen",
          "OVERLAPPING_ALLOWANCE_DESCRIPTION": "Diese Zulage überschneidet sich mit einer bestehenden Zulage. Sie können keine sich überschneidenden Zulagen für dieselbe Abwesenheitstyp haben.",
          "FINALISED_ALLOWANCE_TITLE": "Endgültige Zulage",
          "FINALISED_ALLOWANCE_DESCRIPTION": "Diese Zulage wurde abgeschlossen. Um es zu bearbeiten oder zu löschen, müssen Sie zuerst die Zulage rückgängig machen.",
          "INVALID_UNIT_TITLE": "Ungültige Einheit",
          "INVALID_UNIT_DESCRIPTION": "Bei einer Zulage, für die der Abgrenzungsplan „Jede geleistete Stunde“ festgelegt ist, muss die Einheit auf Stunden festgelegt sein.",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_TITLE": "Ungültiges Gültigkeitsdatum",
          "EFFECTIVE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "Das gewählte Datum des Inkrafttretens muss zwischen dem Beginn und dem Ende des Vergütungsjahres liegen.",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_TITLE": "Ungültiges Referenzdatum",
          "FINALISED_REFERENCE_DATE_OUT_OF_BOUNDS_DESCRIPTION": "Das Referenzdatum muss zwischen dem Datum des Inkrafttretens der Zulage und dem Ende des Zulagenjahres liegen."
        },
        "ALLOWANCE_FORM": {
          "ERROR_500": "Ups, da sind wir auf einen Haken gestoßen. Bitte versuchen Sie es erneut.",
          "ALERT_SUCCESS_ADD": "Die Zulage wurde hinzugefügt.",
          "ALERT_SUCCESS_UPDATE": "Die Zulage wurde aktualisiert.",
          "ALERT_ERROR_CALCULATE_BOOKED": "Wir konnten ihren gebuchten Wert gerade nicht berechnen. Bitte versuchen Sie es erneut.",
          "BUTTON_SAVE": "Speichern",
          "BUTTON_SAVE_LOADING": "Wird gespeichert...",
          "TITLE": "Zulage",
          "LABEL_YEAR_STARTS": "Das Jahr beginnt",
          "YEAR_STARTS_HELP": "Das Standard-Feiertagsjahr Ihrer Organisation beginnt am {{settingDate}}.",
          "YEAR_STARTS_CUSTOMISE_INITIATE": "Wählen Sie einen anderen Tag oder Monat.",
          "YEAR_STARTS_CUSTOMISE_CANCEL": "Kehren Sie zum Standardtag und -monat zurück.",
          "LABEL_EFFECTIVE_DATE": "Datum des Inkrafttretens",
          "EFFECTIVE_DATE_HELP": "Wenn diese Zulage eine bestehende Zulage im selben Jahr ersetzt, müssen Sie ein Datum wählen, ab dem diese Zulage in Kraft tritt.",
          "ALERT_MISALIGNED_YEAR_START": "Dies entspricht nicht dem standardmäßigen Feiertagsjahr Ihrer Organisation.",
          "ALERT_MISALIGNED_EFFECTIVE_DATE": "Ihr Datum des Inkrafttretens liegt nicht innerhalb des Zulagenjahres.",
          "ALERT_ALTERED_EFFECTIVE_DATE": "Ihr Datum des Inkrafttretens stimmt nicht mit dem Startdatum des Zulagenjahres überein. Sie sollten dies nur tun, wenn Sie im selben Jahr eine bestehende Zulage ablösen.",
          "LABEL_ABSENCE_TYPE": "Art der Abwesenheit",
          "LABEL_SCHEDULE_OF_ACCRUAL": "Zeitplan der Rückstellung",
          "TOOLTIP_SCHEDULE_OF_ACCRUAL": "Um Stunden oder Tage manuell zuzuweisen, wählen Sie „Beginn des Abgrenzungsjahres“. Damit Rotaready jedem Kalendermonat Stunden oder Tage zuweist, wählen Sie „Jeder Kalendermonat“. Um automatisch auf Grundlage der geleisteten Arbeitsstunden zu sammeln, wähle „Jede geleistete Stunde“.",
          "LABEL_UNIT": "Einheit",
          "LABEL_DEDUCTIBLE": "Absetzbar",
          "TOOLTIP_DEDUCTIBLE": "Kontrollieren Sie, ob der Mitarbeiter Abwesenheitsanträge stellen kann, die von dieser Zulage abgezogen werden.",
          "DEDUCTIBLE_YES": "Ja",
          "DEDUCTIBLE_NO": "Nein",
          "LABEL_PROJECT": "Bei der Buchung aufgelaufene Beträge vorhersagen",
          "TOOLTIP_PROJECT": "Wenn Sie eine Abwesenheitsanfrage stellen, prognostizieren Sie die Stunden, die wahrscheinlich angefallen sind, und rechnen Sie sie in die aufgelaufene Zahl des Mitarbeiters ein.",
          "PROJECT_YES": "Ja",
          "PROJECT_NO": "Nein",
          "LABEL_TOTAL_ACCRUED_ANNUALLY": "Jährlich aufgelaufener Gesamtbetrag",
          "LABEL_TOTAL_ACCRUED": "Aufgelaufener Gesamtbetrag",
          "TOOLTIP_TOTAL_ACCRUED": "Geben Sie die Anzahl der Stunden oder Tage ein, die dieser Mitarbeiter bisher gesammelt hat. Rotaready wird dies in Zukunft automatisch aktualisieren.",
          "LABEL_BOOKED": "Gebucht",
          "TOOLTIP_BOOKED": "Geben Sie die Anzahl der Stunden oder Tage ein, die dieser Mitarbeiter gebucht hat. Rotaready wird dies automatisch verwalten.",
          "ALERT_BOOKED": "Wir haben dies für Sie auf der Grundlage der in Rotaready gebuchten Abwesenheit berechnet. Ändern Sie dies nur, wenn eine Abwesenheit außerhalb von Rotaready gebucht wurde.",
          "TOOLTIP_BOOKED_BUTTON": "Automatisch berechnen",
          "LABEL_TOTAL_TO_ACCRUE": "Anfallender Höchstbetrag",
          "TOOLTIP_TOTAL_TO_ACCRUE_HOURLY": "Geben Sie die Gesamtzahl der Stunden oder Tage ein, auf die dieser Mitarbeiter Anspruch hat. Lassen Sie das Feld leer, um kein Limit durchzusetzen.",
          "TOOLTIP_TOTAL_TO_ACCRUE_MONTHLY": "Geben Sie die Gesamtzahl der Stunden oder Tage ein, die dieser Mitarbeiter für das Jahr sammeln soll.",
          "LABEL_ACCRUAL_RATE": "Überschreibung des Fälligkeitssatzes",
          "TOOLTIP_ACCRUAL_RATE": "Geben Sie optional einen Abgrenzungssatz an, wenn Sie den Standardsatz des Landes, in dem der Mitarbeiter arbeitet, außer Kraft setzen möchten.",
          "LABEL_OVERSPEND_LIMIT": "Zu viel ausgeben",
          "TOOLTIP_OVERSPEND_LIMIT": "Erlauben Sie dem Mitarbeiter optional, mehr Stunden oder Tage zu buchen, als er angesammelt hat.",
          "LABEL_CARRIED_OVER": "Aus dem letzten Jahr übernommen",
          "BUTTON_PRO_RATA": "anteilig",
          "LABEL_EMPLOYMENT_START": "Datum des Beginns der Beschäftigung",
          "LABEL_PRO_RATA_TOTAL": "Aufgelaufener Gesamtbetrag bei ganzjähriger Beschäftigung",
          "HELP_TEXT_PRO_RATA": "Wir setzen den Gesamtbetrag auf {{value}}.",
          "CREATE_NEXT_YEAR_CHECKBOX": "Erstellen Sie eine weitere Zulage für das nächste Jahr und verwenden Sie dabei den Gesamtbetrag, der anfällt, wenn Sie das ganze Jahr über beschäftigt sind.",
          "ALERT_SUCCESS_CALCULATE_TOTAL_ACCRUED": "Wir haben das für Sie anhand der abgesprochenen Stunden für diesen Mitarbeiter berechnet.",
          "ALERT_ERROR_403_CALCULATE_TOTAL_ACCRUED": "Sie sind nicht berechtigt, auf die abgeschilderten Stunden für diesen Mitarbeiter zuzugreifen. Ohne dies können wir den für Sie aufgelaufenen Gesamtbetrag nicht berechnen.",
          "ALERT_ERROR_500_CALCULATE_TOTAL_ACCRUED": "Wir konnten die Gesamtzahl der aufgelaufenen Stunden nicht automatisch berechnen. Bitte versuchen Sie es erneut.",
          "TOOLTIP_CALCULATE_TOTAL_ACCRUED": "Automatisch berechnen"
        },
        "ALLOWANCE_TRANSACTIONS": {
          "TITLE": "Transaktionen",
          "DESCRIPTION": "Hier sind alle Transaktionen aufgeführt, die sich auf den Freibetrag auswirken, wobei die neuesten zuerst angezeigt werden:",
          "TYPE_ACCRUAL_HOURS_WORKED": "Rückstellung aus geleisteten Arbeitsstunden",
          "TYPE_ABSENCE_REQUESTED": "Abwesenheit beantragt",
          "TYPE_ABSENCE_REJECTED_OR_CANCELLED": "Abwesenheit abgelehnt oder storniert",
          "TYPE_MANUAL_ADJUSTMENT": "Manuelle Einstellung",
          "TYPE_ACCRUAL_SCHEDULE_CHANGED": "Der Zeitplan für die Rückstellung wurde geändert",
          "TYPE_CARRY_OVER": "Aus dem Vorjahr übernommen",
          "TYPE_ACCRUAL_TIME_BASED": "Zeitabhängige Abgrenzung",
          "TYPE_ABSENCE_UPDATED": "Abwesenheitstermine geändert",
          "TYPE_HOURS_PER_DAY_RECALCULATION": "Stunden pro Tag aktualisiert",
          "ERROR_500": "Ups, da sind wir auf einen Haken gestoßen. Bitte versuchen Sie es erneut.",
          "NO_TRANSACTIONS": "Es sind keine Transaktionen zum Anzeigen vorhanden.",
          "BUTTON_CLOSE": "Schliessen",
          "TRANSACTION_TYPE": "Nach Typ filtern"
        },
        "VIEW_FINALISATION": {
          "TITLE": "Endgültige Zulage",
          "BUTTON_CLOSE": "Schliessen",
          "BUTTON_REVERT": "Rückgängig machen",
          "CONFIRM_REVERT": "Sind Sie sicher, dass Sie diese Zulage rückgängig machen möchten? Dadurch wird auch der entsprechende Gehaltsdatensatz gelöscht, falls vorhanden.",
          "LABEL_REFERENCE_DATE": "Referenzdatum",
          "LABEL_PAY_RECORD": "Bezahlung/Abzug",
          "PAY_SEE_MORE": "Gehaltsnachweis anzeigen",
          "PAY_NONE": "Dem Arbeitnehmer wird kein Gehalt geschuldet, und es sollte auch kein Gehalt abgezogen werden.",
          "LABEL_NOTES": "Hinweise",
          "NOTES_NONE": "Es wurden keine Notizen aufgenommen.",
          "ERROR_500": "Wir konnten Ihre Änderungen gerade nicht speichern. Bitte versuchen Sie es erneut.",
          "ERROR_403": "Sie sind nicht berechtigt, die Gehaltsdaten dieser Person zu ändern, daher können wir die Zulage nicht rückgängig machen.",
          "ALERT_SUCCESS": "Die Zulage wurde rückgängig gemacht."
        },
        "FINALISE_ALLOWANCE_FORM": {
          "TITLE": "Zulage finalisieren",
          "BUTTON_SAVE": "Finalisieren",
          "ERROR_500": "Wir konnten Ihre Änderungen gerade nicht speichern. Bitte versuchen Sie es erneut.",
          "ERROR_403": "Sie sind nicht berechtigt, die Gehaltsdaten dieser Person zu ändern, daher können wir die Zulage nicht endgültig festlegen.",
          "ALERT_SUCCESS": "Die Zulage wurde abgeschlossen.",
          "GUIDANCE": "Wir empfehlen, einen Freibetrag zu finalisieren, wenn Sie sicher sein können, dass keine Abwesenheiten mehr anfallen, gebucht oder storniert werden. Dies ist normalerweise so nah wie möglich am letzten Tag einer Person.",
          "GUIDANCE_FUTURE_YEAR": "Sie müssen dies nur tun, wenn der Arbeitnehmer in diesem Zulagenjahr ausscheidet.",
          "LABEL_EFFECTIVE_DATE": "Referenzdatum",
          "EFFECTIVE_DATE_LEAVER_HELP": "Die Zulage endet an diesem Tag. Da diese Person ein Abgänger ist, haben wir es auf ihren letzten Tag gesetzt.",
          "EFFECTIVE_DATE_NOT_LEAVER_HELP": "Die Zulage endet an diesem Tag. Das von Ihnen gewählte Datum wird unten verwendet, um die gesamten aufgelaufenen Stunden oder Tage zu berechnen.",
          "LABEL_BASIC_PAY": "Grundvergütung",
          "ALERT_BASIC_PAY_NOT_PERMITTED": "Sie sind nicht berechtigt, die Gehaltsaufzeichnungen dieses Mitarbeiters einzusehen, daher können Sie diese Funktion nicht verwenden, um eine einmalige Zahlung oder einen Abzug zu erstellen.",
          "ALERT_BASIC_PAY_NOT_AVAILABLE": "Der Mitarbeiter verfügt zum von Ihnen gewählten Stichtag nicht über eine Grundvergütung.",
          "HELP_TEXT_BASIC_PAY": "Dies ist das Grundgehalt des Mitarbeiters am von Ihnen gewählten Stichtag.",
          "LABEL_TOTAL_ACCRUED_LIVE": "Aufgelaufener Gesamtbetrag",
          "HELP_TEXT_TOTAL_ACCRUED_LIVE": "Dies ist die Gesamtzahl der für diese Zulage aufgelaufenen Stunden, basierend auf allen Tagen, die abgesegnet wurden.",
          "LABEL_ACCRUED_PRO_RATED": "Aufgelaufen (anteilig)",
          "HELP_TEXT_TOTAL_ACCRUED_PRO_RATED": "Unter Verwendung des von Ihnen gewählten Referenzdatums wurden die gesamten aufgelaufenen Stunden/Tage anteilig berechnet.",
          "LABEL_BOOKED": "Insgesamt gebucht",
          "HELP_TEXT_BOOKED": "Dies ist die gesamte Abwesenheit, die bisher gebucht wurde, einschließlich ausstehender und genehmigter Anfragen.",
          "LABEL_ACCRUED_UNTAKEN": "Aufgelaufene, aber noch nicht abgenommene",
          "STATUS_PAYMENT_DUE": "Dies bedeutet, dass der Mitarbeiter weniger Fehlzeiten in Anspruch genommen hat, als er angefallen ist. Viele Arbeitgeber entscheiden sich dafür, den Arbeitnehmer dafür zu bezahlen.",
          "STATUS_DEDUCTION_DUE": "Dies bedeutet, dass der Mitarbeiter mehr Fehlzeiten in Anspruch genommen hat, als er angesammelt hat. Einige Arbeitgeber ziehen dies von der endgültigen Gehaltsabrechnung des Arbeitnehmers ab.",
          "LABEL_AVERAGE_HOURS": "Durchschnittliche Stunden pro Tag",
          "LABEL_ONE_OFF_PAYMENT": "Einmalzahlung/Abzug",
          "HELP_TEXT_ONE_OFF_PAYMENT": "Wenn Sie einen Betrag eingeben, erstellt Rotaready einen einmaligen Gehaltsnachweis und verknüpft ihn mit dieser endgültigen Vergütung. Geben Sie für einen Abzug einen negativen Betrag ein.",
          "HELP_TEXT_ONE_OFF_PAYMENT_LINK": "Hilfe zur Berechnung",
          "LABEL_PAY_ELEMENT": "Element bezahlen",
          "LABEL_PAYMENT_DATE": "Anwendbares Datum",
          "HELP_TEXT_PAYMENT_DATE": "Wählen Sie sorgfältig aus, um sicherzustellen, dass diese Zahlung in der endgültigen Gehaltsabrechnung des Mitarbeiters enthalten ist.",
          "LABEL_NOTES": "Hinweise",
          "PLACEHOLDER_NOTES": "Wenn Sie keine Notizen eingeben, beziehen wir automatisch die Zahlen aus der obigen Berechnung mit ein."
        },
        "FINALISE_ALLOWANCE_PROMPT": {
          "TITLE": "Sind alle Zulagen endgültig festgelegt?",
          "DESCRIPTION": "Wenn Sie froh sind, dass alle Zulagen jetzt endgültig feststehen, sollten Sie dies bestätigen, damit sich Abteilungen wie die Gehaltsabrechnung auf die Zahlen verlassen können.",
          "DESCRIPTION_PART_2": "Sie können dies auch zu einem späteren Zeitpunkt auf der Registerkarte Beschäftigung tun.",
          "BUTTON_ATTEST": "Bestätigen",
          "BUTTON_LATER": "Ich habe noch mehr zu tun",
          "ALERT_ERROR": "Wir konnten Ihre Bestätigung gerade nicht speichern. Bitte versuchen Sie dies stattdessen auf der Registerkarte Beschäftigung."
        }
      },
      "EMERGENCY_CONTACTS": {
        "ADD_MODAL": {
          "TITLE": "Neuer Notfallkontakt",
          "RELATIONSHIP_LABEL": "Beziehung zum Mitarbeiter",
          "FIRST_NAME_LABEL": "Vorname",
          "LAST_NAME_LABEL": "Nachname",
          "OTHER_NAME_LABEL": "Andere (n) Name (n)",
          "PRIMARY_CONTACT_NUMBER_LABEL": "Primäre Kontaktnummer",
          "SECONDARY_CONTACT_NUMBER_LABEL": "Sekundäre Kontaktnummer",
          "ADDRESS_LABEL": "Adresse",
          "SAVE_BUTTON_TEXT": "Speichern",
          "CLOSE_BUTTON_TEXT": "Schliessen",
          "ALERT_ERROR_500": "Wir konnten Ihre Änderungen gerade nicht speichern. Bitte versuchen Sie es erneut."
        },
        "INDEX": {
          "TITLE": "Kontakte für Notfälle",
          "RELATIONSHIP_COLUMN_TEXT": "Beziehung",
          "NAME_COLUMN_TEXT": "Name",
          "PHONE_COLUMN_TEXT": "Telefonnummer (n)",
          "ADDRESS_COLUMN_TEXT": "Adresse",
          "NONE_TO_SHOW": "Es sind keine Notfallkontakte zum Vorzeigen vorhanden.",
          "ADD_BUTTON_TEXT": "Neuer Kontakt",
          "OPTIONS_COLUMN_TEXT": "Optionen",
          "DELETE_LINK": "Löschen",
          "ALERT_ERROR_500": "Wir konnten gerade keine Notfallkontakte laden. Bitte versuchen Sie es erneut.",
          "CONFIRM_DELETE": "Möchten Sie diesen Notfallkontakt wirklich löschen?\nDas kann nicht rückgängig gemacht werden."
        }
      },
      "EMPLOYMENT": {
        "LABEL_LENGTH_OF_SERVICE": "Dauer des Dienstes",
        "LABEL_STARTS_IN": "Beginnt in",
        "LABEL_OPTIONS": "Optionen",
        "OPTION_NEW_EMPLOYMENT": "Neue Anstellung",
        "OPTION_END_EMPLOYMENT": "Beschäftigung beenden",
        "OPTION_REINSTATE_EMPLOYMENT": "Wiederaufnahme der Beschäftigung",
        "OPTION_DELETE_EMPLOYMENT": "Beschäftigung löschen",
        "TEXT_TERMINATED": "Dieses Arbeitsverhältnis wurde beendet und endet am",
        "LABEL_REASON": "Grund",
        "LABEL_REHIRE": "Wiedereinstellen",
        "LABEL_REHIRE_YES": "Ja",
        "LABEL_REHIRE_NO": "Nein",
        "BUTTON_ATTEST": "Bestätigen",
        "TEXT_ALLOWANCES_NOT_FINALISED": "Sie haben noch nicht bestätigt, dass die Abwesenheitsvergütung des Mitarbeiters endgültig festgesetzt wurde.",
        "TEXT_ALLOWANCES_NOT_FINALISED_PART_2": "Dies erfolgt in der Regel am oder vor dem letzten Tag des Mitarbeiters, vor seiner endgültigen Gehaltszahlung.",
        "TEXT_ALLOWANCES_NOT_FINALISED_LINK": "Zulagen anzeigen",
        "BUTTON_UNDO": "Rückgängig machen",
        "TEXT_ALLOWANCES_FINALISED": "Die Abwesenheitsvergütung wurde für diese Beschäftigung als abgeschlossen bestätigt.",
        "LABEL_JOIN_DATE": "Datum des Beitritts",
        "LABEL_NOTICE_PERIOD": "Kündigungsfrist",
        "BUTTON_SET_NOTICE_PERIOD": "Legen Sie eine Kündigungsfrist fest...",
        "LABEL_EMPLOYEE_NUMBER": "Nummer des Mitarbeiters",
        "TOOLTIP_EMPLOYEE_NUMBER": "fakultativ In Großbritannien verlangt HMRC für jeden Mitarbeiter eine eindeutige Nummer. Wir senden dies an Ihren Lohn- und Gehaltsabrechnungsanbieter, wenn eine Gehaltsabrechnungsintegration aktiviert ist.",
        "LABEL_RECRUITMENT_SOURCE": "Rekrutierungsquelle",
        "LABEL_STARTER_DECLARATION": "Startererklärung (Vereinigtes Königreich)",
        "BUTTON_SAVE": "Speichern",
        "BUTTON_SAVE_LOADING": "Wird gespeichert...",
        "BUTTON_NEW_APPOINTMENT": "Neuer Termin",
        "TITLE_APPOINTMENTS": "Ernennungen",
        "COLUMN_EFFECTIVE_DATE": "Datum des Inkrafttretens",
        "COLUMN_SITE": "Standort",
        "COLUMN_DEPARTMENT": "Abteilung",
        "COLUMN_POSITION": "Berufliche Rolle",
        "COLUMN_LINE_MANAGER": "Vorgesetzter",
        "COLUMN_STAFF_GROUP": "Gruppe der Mitarbeiter",
        "COLUMN_OBLIGATION": "Verpflichtung",
        "TOOLTIP_ACTIVE_APPOINTMENT": "Dies ist der aktive Termin.",
        "LINE_MANAGER_NOT_SET": "Nicht gesetzt",
        "MIN_OBLIGATION_ZERO_HOURS": "Null Stunden",
        "ERROR_500": "Wir können momentan keine Jobs vergeben. Bitte versuchen Sie es erneut.",
        "ERROR_403": "Sie sind nicht berechtigt, die Beschäftigungsdaten dieser Person einzusehen/zu ändern.",
        "LABEL_HOURS": "Stunden",
        "LABEL_DAYS": "Tage",
        "LABEL_WEEK": "Woche",
        "LABEL_MONTH": "Monat",
        "LABEL_CURRENT_EMPLOYMENT": "Aktuelle Beschäftigung",
        "ALERT_CHANGES_SAVED": "Ihre Änderungen wurden gespeichert.",
        "CONFIRM_DELETE": "Bist du sicher, dass du das tun willst? Das Löschen einer Beschäftigung kann nicht rückgängig gemacht werden.",
        "CONFIRM_REINSTATE": "Bist du sicher, dass du das tun willst?\n\nWenn jemand wieder Ihrer Organisation beitritt, müssen Sie stattdessen eine neue Stelle schaffen. Sie sollten ein Arbeitsverhältnis nur dann wieder aufnehmen, wenn es zu Unrecht beendet wurde.",
        "VALIDATION_MODAL": {
          "TITLE": "Das können wir nicht ändern",
          "DESCRIPTION": "Leider verursacht das, was Sie versuchen zu tun, ein oder mehrere Probleme.",
          "NO_EMPLOYMENT_TITLE": "Keine Anstellung",
          "NO_EMPLOYMENT_DESCRIPTION": "Ihre Änderung würde dazu führen, dass der Mitarbeiter keine Anstellung hat; es muss immer mindestens eine geben.",
          "EMPLOYMENT_OVERLAPPING_TITLE": "Überlappende Beschäftigungen",
          "EMPLOYMENT_OVERLAPPING_DESCRIPTION": "Ihre Änderung würde dazu führen, dass sich zwei Anstellungen überschneiden:",
          "EMPLOYMENT_NOT_TERMINATED_TITLE": "Das Arbeitsverhältnis wurde nicht beendet",
          "EMPLOYMENT_NOT_TERMINATED_DESCRIPTION": "Sie müssen die vorherige Beschäftigung beenden, bevor Sie eine neue hinzufügen können.",
          "EMPLOYMENT_NO_APPOINTMENTS_TITLE": "Anstellung ohne Termine",
          "EMPLOYMENT_NO_APPOINTMENTS_DESCRIPTION": "Eine Anstellung darf nicht leer sein, sie muss mindestens einen Termin enthalten.",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_TITLE": "Ernennung außerhalb des Arbeitsverhältnisses",
          "APPOINTMENT_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Ihre Änderung würde dazu führen, dass ein Teil dieses Termins nicht in das Arbeitsverhältnis fällt.",
          "APPOINTMENT_OVERLAPPING_TITLE": "Termine überschneiden sich",
          "APPOINTMENT_OVERLAPPING_TITLE_DESCRIPTION": "Ihre Änderung würde dazu führen, dass sich zwei Termine überschneiden:",
          "APPOINTMENT_NO_EMPLOYMENT_TITLE": "Termin ohne Anstellung",
          "APPOINTMENT_NO_EMPLOYMENT_DESCRIPTION": "Eine Anstellung ist nicht mit einer Anstellung verbunden. Das sollte normalerweise nicht passieren!",
          "SHIFTS_OUTSIDE_EMPLOYMENT_TITLE": "Schichten außerhalb der Beschäftigung",
          "SHIFTS_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Durch Ihre Änderung würden {{count}} Schicht (n) an Tagen übrig bleiben, die vor Beginn oder nach Beendigung der Beschäftigung liegen. Um fortzufahren, können diese Schichten gelöscht, ihre Zuweisung aufgehoben (wodurch sie in die Kategorie Offene Schichten verschoben werden) oder im Dienstplan selbst geändert werden:",
          "SHIFT_ENTITY_MISMATCH_TITLE": "Schichten an einem anderen Standort/einer anderen Abteilung",
          "SHIFT_ENTITY_MISMATCH_DESCRIPTION": "Durch Ihre Änderung würden {{count}} Schicht (n) übrig bleiben, die aus dem Dienstplan einer Abteilung stammen, die nicht mit der Abteilung übereinstimmt, in der der Mitarbeiter ernannt wurde. Um fortzufahren, können diese Schichten gelöscht, ihre Zuweisung aufgehoben (wodurch sie in die Kategorie Offene Schichten verschoben werden) oder im Dienstplan selbst geändert werden:",
          "ABSENCE_OUTSIDE_EMPLOYMENT_TITLE": "Abwesenheit außerhalb der Beschäftigung",
          "ABSENCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Durch Ihre Änderung würden {{count}} Abwesenheitsbuchungen an Terminen übrig bleiben, die vor Beginn oder nach Beendigung des Beschäftigungsverhältnisses liegen. Um fortzufahren, müssen sie storniert werden:",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_TITLE": "Teilnahme außerhalb der Beschäftigung",
          "ATTENDANCE_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Ihre Änderung würde dazu führen, dass {{count}} Teilnahmeveranstaltungen an Terminen stattfinden, die vor Beginn der Beschäftigung oder nach deren Ende liegen. Um fortzufahren, müssen sie gelöscht werden:",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_TITLE": "Erfassung von Schichtabläufen außerhalb der Beschäftigung",
          "ROUTINE_MAPPING_OUTSIDE_EMPLOYMENT_DESCRIPTION": "Ihre Änderung würde dazu führen, dass {{count}} Routinezuordnungen für Termine übrig bleiben, die vor Beginn oder nach Beendigung der Beschäftigung liegen. Um fortzufahren, müssen sie beendet oder gelöscht werden:",
          "EMAIL_EXISTS_TITLE": "E-Mail-Adresse ist bereits vorhanden",
          "EMAIL_EXISTS_DESCRIPTION": "Die von Ihnen eingegebene E-Mail-Adresse wird bereits von einem anderen Mitarbeiter verwendet. Wenn diese Person schon einmal für Ihre Organisation gearbeitet hat, suchen Sie nach ihr und fügen Sie eine neue Beschäftigung hinzu.",
          "MOBILE_EXISTS_TITLE": "Handynummer ist bereits vorhanden",
          "MOBILE_EXISTS_DESCRIPTION": "Die von Ihnen eingegebene Handynummer wird bereits von einem anderen Mitarbeiter verwendet. Wenn diese Person schon einmal für Ihre Organisation gearbeitet hat, suchen Sie nach ihr und fügen Sie eine neue Beschäftigung hinzu.",
          "INVALID_DATE_TITLE": "Ungültiges Datum",
          "INVALID_DATE_DESCRIPTION": "Wenn Sie versuchen, ein Arbeitsverhältnis zu kündigen, liegt das von Ihnen gewählte Enddatum vor Beginn des Arbeitsverhältnisses.",
          "PERIOD_SIGNED_OFF_TITLE": "Datum der Abzeichnung",
          "PERIOD_SIGNED_OFF_DESCRIPTION": "Ihre Änderung überschneidet sich mit einem oder mehreren Daten, die abgesegnet wurden.",
          "PAY_ELEMENT_INVALID_TITLE": "Ungültiges Zahlungselement",
          "PAY_ELEMENT_INVALID_DESCRIPTION": "Das gewählte Zahlungselement ist ungültig.",
          "PAY_RECORD_SAME_DATE_TITLE": "Doppelter Gehaltsnachweis",
          "PAY_RECORD_SAME_DATE_DESCRIPTION": "Für das von Ihnen gewählte Zahlungselement ist an diesem Datum bereits ein Gehaltsdatensatz vorhanden.",
          "RECORD_MISSING_TITLE": "Zahlungsdatensatz gelöscht",
          "RECORD_MISSING_DESCRIPTION": "Dieser Gehaltsnachweis wurde gelöscht und kann nicht mehr geändert werden.",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_TITLE": "Gehaltsabrechnungskalender stimmt nicht überein",
          "ABSENCE_PAYROLL_CALENDAR_MISMATCH_DESCRIPTION": "Durch Ihre Änderung würden {{count}} Abwesenheitsbuchungen einem anderen Abrechnungskalender zugeordnet. Um fortzufahren, müssen sie storniert werden:",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_TITLE": "Verbunden mit der endgültigen Zulage",
          "PAY_LINKED_TO_FINALISED_ALLOWANCE_DESCRIPTION": "Dieser Gehaltsnachweis ist mit einer endgültigen Vergütung verknüpft und kann nicht bearbeitet werden. Um es zu löschen, suchen Sie bitte nach dem Freibetrag und machen Sie ihn rückgängig.",
          "BUTTON_TRY_AGAIN": "Versuche es noch einmal",
          "BUTTON_CLOSE": "Schliessen"
        },
        "LEAVER_FORM": {
          "TITLE": "Beschäftigung beenden",
          "ALERT_SUCCESS": "Das Arbeitsverhältnis wurde beendet.",
          "ERROR_500": "Wir konnten Ihre Änderungen gerade nicht speichern. Bitte versuchen Sie es erneut.",
          "LABEL_END_DATE": "Letzter Tag der Beschäftigung",
          "LABEL_REASON": "Grund für die Abreise",
          "LABEL_REHIRE": "Erneut einstellen",
          "CHECKBOX_REHIRE": "Dieser Mitarbeiter gilt als berechtigt, in Zukunft wieder eingestellt zu werden.",
          "LABEL_NOTES": "Hinweise",
          "PLACEHOLDER_NOTES": "Notieren Sie optional einige Notizen im Zusammenhang mit der Abreise dieses Mitarbeiters.",
          "BUTTON_SAVE": "Speichern",
          "BUTTON_SAVE_LOADING": "Wird gespeichert..."
        },
        "EMPLOYMENT_FORM": {
          "TITLE": "Neue Anstellung",
          "RESET_WARNING": "Das Konto des Mitarbeiters wird als nicht genehmigt markiert und verschiedene Attribute werden zurückgesetzt, z. B. die durchschnittlich geleisteten Arbeitsstunden pro Tag und pro Woche.",
          "LABEL_START_DATE": "Datum des Beginns der Beschäftigung",
          "LABEL_NOTICE_PERIOD": "Kündigungsfrist",
          "LABEL_EMPLOYEE_NUMBER": "Nummer des Mitarbeiters",
          "TOOLTIP_EMPLOYEE_NUMBER": "fakultativ In Großbritannien verlangt HMRC für jeden Mitarbeiter eine eindeutige Nummer. Wir senden dies an Ihren Lohn- und Gehaltsabrechnungsanbieter, wenn eine Gehaltsabrechnungsintegration aktiviert ist.",
          "LABEL_RECRUITMENT_SOURCE": "Rekrutierungsquelle",
          "LABEL_STARTER_DECLARATION": "Startererklärung (Vereinigtes Königreich)",
          "LABEL_INVITE": "Zu Rotaready einladen",
          "CHECKBOX_INVITE": "Senden Sie diesem Mitarbeiter einen magischen Link, der ihn auffordert, sein Passwort festzulegen und sich anzumelden, um seine Schichten zu sehen.",
          "BUTTON_ADD": "Hinzufügen",
          "BUTTON_ADD_LOADING": "Wird hinzugefügt...",
          "ALERT_SUCCESS": "Die Beschäftigung wurde geschaffen.",
          "ERROR_500": "Wir konnten Ihre Änderungen gerade nicht speichern. Bitte versuchen Sie es erneut."
        },
        "APPOINTMENT_FORM": {
          "TITLE": "Ernennung",
          "ALERT_MAPPINGS": "Bitte vervollständigen Sie die Standard-Stream-Mappings oder überprüfen Sie die Deklaration.",
          "ALERT_SAVED": "Der Termin wurde gespeichert.",
          "ALERT_DELETED": "Der Termin wurde gelöscht.",
          "ERROR_500": "Wir konnten Ihre Änderungen gerade nicht speichern. Bitte versuchen Sie es erneut.",
          "INFO_NEW_APPOINTMENT": "Wir haben den letzten Termin verwendet, um dieses Formular vorab für Sie auszufüllen.",
          "LABEL_EFFECTIVE_DATE": "Datum des Inkrafttretens",
          "HELP_TEXT_EFFECTIVE_DATE": "Da es sich um die erste Ernennung handelt, ist das Datum des Inkrafttretens an das Datum des Beschäftigungseintritts gebunden.",
          "INFO_NEW_EMPLOYEE": "Konfigurieren Sie abschließend den neuen Termin dieses Mitarbeiters, indem Sie seinen Standort/seine Abteilung, seine berufliche Rolle und andere wichtige Attribute festlegen.",
          "LABEL_LINE_MANAGER": "Vorgesetzter",
          "LABEL_LINE_MANAGER_NOT_SET": "Nicht gesetzt",
          "TITLE_JOB_ROLE": "Berufliche Rolle",
          "LABEL_SITE_DEPARTMENT": "Standort/Abteilung",
          "LABEL_JOB_ROLE": "Berufliche Rolle",
          "LABEL_STAFF_GROUP": "Gruppe der Mitarbeiter",
          "HELP_TEXT_STAFF_GROUP": "Mitarbeitergruppen werden verwendet, um Personen im Dienstplan und in anderen Tools visuell voneinander zu trennen.",
          "TITLE_EMPLOYMENT_CONTRACT": "Arbeitsvertrag",
          "LABEL_COMPANY": "Firma",
          "LABEL_PAYROLL_CALENDAR": "Gehaltsabrechnungskalender",
          "LABEL_CONTRACT_TYPE": "Art des Vertrags",
          "LABEL_PUBLIC_HOLIDAYS": "Gesetzliche Feiertage",
          "HELP_TEXT_PUBLIC_HOLIDAYS": "Dieser Mitarbeiter arbeitet in der Regel an Feiertagen.",
          "LABEL_WORKING_TIME": "Arbeitszeitregelungen",
          "HELP_TEXT_WORKING_TIME": "Dieser Mitarbeiter hat sich von den optionalen Arbeitszeitbeschränkungen der Arbeitszeitvorschriften abgemeldet.",
          "TITLE_CONTRACTUAL_OBLIGATION": "Vertragliche Verpflichtung",
          "LABEL_MIN_OBLIGATION": "Garantierte Stunden/Tage",
          "HELP_TEXT_MIN_OBLIGATION": "Diesem Mitarbeiter wird ein Mindestmaß an Arbeit garantiert.",
          "LABEL_MAX_OBLIGATION": "Arbeitslimit",
          "HELP_TEXT_MAX_OBLIGATION": "Setzen Sie eine Obergrenze für die Anzahl der Stunden oder Tage durch, für die dieser Mitarbeiter eingeplant werden kann.",
          "TITLE_STREAM_MAPPINGS": "Standard-Stream-Zuordnungen",
          "HELP_TEXT_STREAM_MAPPINGS": "Wenn dieser Mitarbeiter in einer Schicht arbeitet, bestimmen die Zuordnungen des Schichttyps den Stream (en), dem bzw. denen die Lohnkosten zugewiesen werden. Wenn der Schichttyp keinen hat, werden stattdessen diese verwendet:",
          "COLUMN_STREAM": "Streamen",
          "COLUMN_ATTRIBUTION": "Zuschreibung",
          "COLUMN_OPTIONS": "Optionen",
          "OPTION_DELETE": "Löschen",
          "NO_MAPPINGS": "Es gibt keine zu zeigen.",
          "LABEL_ADD_MAPPING": "Neues Mapping hinzufügen",
          "OPTION_ADD": "Hinzufügen",
          "LABEL_NO_MAPPINGS": "Keine Zuordnungen",
          "CHECKBOX_NO_MAPPINGS": "Ich akzeptiere, dass es ohne standardmäßige Streamzuordnungen zu Ungenauigkeiten in Cost Control und einigen Berichten kommen kann.",
          "HELP_TEXT_NO_MAPPINGS": "Das ist in Ordnung, wenn Sie die Lohnkosten dieses Mitarbeiters nicht verfolgen.",
          "BUTTON_SAVE": "Speichern",
          "BUTTON_SAVE_LOADING": "Wird gespeichert...",
          "BUTTON_DELETE": "Löschen",
          "BUTTON_DELETE_LOADING": "Löschen..."
        }
      },
      "DOCUMENTS": {
        "FILTER_LABEL_CATEGORY": "Kategorie",
        "BUTTON_UPLOAD": "Datei hochladen",
        "BUTTON_SIGNATURES": "Signaturen von Dokumenten",
        "OPTION_SEND_TO_SIGN": "Ein Dokument zum Unterschreiben senden",
        "OPTION_PENDING_SIGNATURES": "Dokumente anzeigen, die noch unterschrieben werden müssen",
        "ERROR_500": "Wir können derzeit keine Dokumente laden. Bitte versuchen Sie es erneut.",
        "ERROR_403": "Sie sind nicht berechtigt, die Dokumente dieser Person einzusehen/zu ändern.",
        "ERROR_NO_CATEGORIES": "Dokumente sind für Ihre Organisation nicht aktiviert. Chatten Sie mit uns, um loszulegen.",
        "TOOLTIP_CATEGORY_NOT_APPLICABLE": "Kategorie als nicht erforderlich markiert",
        "TOOLTIP_CATEGORY_INVALID": "Dokument fehlt oder ist abgelaufen",
        "INFO_CATEGORY_NOT_APPLICABLE": "Diese Kategorie wurde für diesen Mitarbeiter als nicht erforderlich markiert.",
        "LINK_MARK_CATEGORY_REQUIRED": "Als erforderlich markieren",
        "LINK_MARK_CATEGORY_NOT_REQUIRED": "Als nicht erforderlich markieren",
        "INFO_CATEGORY_NO_DOCUMENTS": "In dieser Kategorie gibt es keine Dokumente.",
        "COLUMN_NAME": "Name",
        "COLUMN_EXPIRES": "Läuft ab",
        "COLUMN_FILE_SIZE": "Größe der Datei",
        "COLUMN_UPLOADED_BY": "Hochgeladen von",
        "COLUMN_OPTIONS": "Optionen",
        "LINK_DELETE": "Löschen",
        "LINK_EDIT": "Bearbeiten",
        "ALERT_CONFIRM_DELETE": "Sind Sie sicher, dass Sie dieses Dokument löschen möchten? Das kann nicht rückgängig gemacht werden!",
        "TOOLTIP_SIGNATURE_ELECTRONIC": "Dieses Dokument wurde elektronisch über Rotaready unterzeichnet",
        "TOOLTIP_SIGNATURE_MANUAL": "Dieses Dokument wurde manuell signiert",
        "TOOLTIP_NOTES": "Notizen sind verfügbar",
        "PILL_SENSITIVE": "Sensibel",
        "PILL_CAN_EXPIRE": "Kann ablaufen",
        "PILL_VISIBLE_TO_EMPLOYEE": "Sichtbar für Mitarbeiter",
        "TOOLTIP_ACKNOWLEDGED": "Bestätigt: {{date}}",
        "PERSONAL": {
          "TITLE": "Meine Dokumente",
          "ALERT_CONFIRM_ACKNOWLEDGE": "Durch Klicken auf Bestätigen bestätigen Sie, dass Sie das Dokument gelesen und verstanden haben.",
          "INFO_NO_DOCUMENTS": "Sie haben keine Dokumente zum Ansehen.",
          "LINK_ACKNOWLEDGE": "Bestätigen",
          "PILL_NEEDS_ACKNOWLEDGEMENT": "Benötigt Anerkennung",
          "PILL_ACKNOWLEDGED": "Bestätigt",
          "COLUMN_NAME": "Name",
          "COLUMN_CATEGORY": "Kategorie",
          "COLUMN_EXPIRES": "Läuft ab",
          "COLUMN_FILE_SIZE": "Größe der Datei",
          "COLUMN_OPTIONS": "Optionen",
          "FILTER_LABEL_CATEGORY": "Kategorie"
        }
      },
      "DOCUMENT_EDIT_FORM": {
        "TITLE": "Dokument bearbeiten",
        "BUTTON_CANCEL": "Stornieren",
        "BUTTON_SAVE": "Speichern",
        "LABEL_EXPIRY_DATE": "Verfallsdatum",
        "LABEL_NOTES": "Hinweise",
        "HELP_TEXT_EXPIRY": "Das Dokument muss mit einem Ablaufdatum versehen werden, falls für die Kategorie ein solches erforderlich ist. Sie werden aufgefordert, Maßnahmen zu ergreifen, wenn das Datum näher rückt und erneut, wenn es abgelaufen ist.",
        "ALERT_SUCCESS": "Das Dokument wurde aktualisiert.",
        "ALERT_ERROR_500": "Beim Aktualisieren des Dokuments ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns, falls diese Meldung weiterhin angezeigt wird.",
        "ALERT_ERROR_400": "Irgendwas stimmt mit deinen Änderungen nicht ganz."
      },
      "DOCUMENT_UPLOAD_MODAL": {
        "TITLE": "Laden Sie ein Dokument hoch",
        "ALERT_SIGNATURE": "Wir kennzeichnen dieses Dokument als manuell signiert.",
        "LABEL_UPLOAD_TO": "Upload zu",
        "LABEL_CATEGORY": "Kategorie",
        "LABEL_EXPIRY_DATE": "Verfallsdatum",
        "HELP_TEXT_EXPIRY": "Das Dokument muss mit einem Ablaufdatum versehen werden, falls für die Kategorie ein solches erforderlich ist. Sie werden aufgefordert, Maßnahmen zu ergreifen, wenn das Datum näher rückt und erneut, wenn es abgelaufen ist.",
        "LABEL_UPLOAD": "Upload",
        "LABEL_NOTES": "Hinweise",
        "BUTTON_CANCEL": "Stornieren",
        "BUTTON_SAVE": "Speichern",
        "ALERT_SUCCESS": "Ihr Dokument wurde hochgeladen.",
        "ALERT_ERROR_500": "Beim Hochladen des Dokuments ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns, falls diese Meldung weiterhin angezeigt wird.",
        "ALERT_ERROR_400": "Mit Ihrer Kategorie und Ihrem Ablaufdatum stimmt etwas nicht ganz."
      },
      "DOCUMENT_SIGNATURE_MODAL": {
        "ALERT_PICK_CATEGORY": "Bitte wählen Sie eine Kategorie aus, in der das Dokument gespeichert werden soll.",
        "ALERT_SUCCESS_MANUAL": "Ihr Dokument wurde heruntergeladen.",
        "ALERT_SUCCESS_ELECTRONIC": "Ihr Dokument wurde zur elektronischen Signatur gesendet.",
        "ALERT_ERROR_500": "Beim Erstellen des Dokuments ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns, falls diese Meldung weiterhin angezeigt wird.",
        "ALERT_ERROR_400": "Wir konnten Ihr Dokument nicht erstellen. Überprüfe deine Merge-Tags und versuche es erneut.",
        "TITLE": "Ein Dokument zum Unterschreiben senden",
        "ALERT_INFO": "Indem Sie Merge-Tags in Ihrer Vorlage oder Datei platzieren, kann Rotaready wichtige Informationen über den Mitarbeiter, an den Sie sie senden, einfügen, z. B. seinen Namen oder seine berufliche Rolle. Auf diese Weise steuern Sie auch, wo das Signaturfeld angezeigt wird.",
        "ALERT_INFO_LINK": "Finde heraus wie",
        "LABEL_SEND_TO": "Senden an",
        "LABEL_DOCUMENT": "Dokument",
        "LABEL_DOCUMENT_TEMPLATE": "Verwenden Sie eine vorhandene Vorlage",
        "LABEL_DOCUMENT_UPLOAD": "Laden Sie eine einmalige Datei hoch",
        "LABEL_TEMPLATE": "Schablone",
        "ALERT_NO_TEMPLATES_TITLE": "Sie haben keine Vorlagen.",
        "ALERT_NO_TEMPLATES_HELP": "Sparen Sie Zeit, laden Sie eine Datei einmal hoch und verwenden Sie sie für mehrere Personen erneut.",
        "ALERT_NO_TEMPLATES_BUTTON": "Laden Sie eine Vorlage hoch",
        "LABEL_UPLOAD": "Upload",
        "SECTION_SIGNING_INFO": "Informationen zum Unterschreiben",
        "LABEL_TITLE": "Titel",
        "HELP_TEXT_TITLE": "Geben Sie diesem Dokument einen Titel, damit die Person, an die Sie es senden, weiß, um was es sich handelt.",
        "LABEL_EMPLOYER_SIGNS": "Arbeitgeberunterschriften",
        "OPTION_EMPLOYER_SIGN_FALSE": "Nein, nur der Mitarbeiter",
        "OPTION_EMPLOYER_SIGN_TRUE": "Eine Unterschrift im Namen des Arbeitgebers ist erforderlich",
        "LABEL_EMPLOYER_SIGNATORY": "Unterzeichner des Arbeitgebers",
        "HELP_TEXT_EMPLOYER_SIGNATORY": "Wählen Sie aus, wer dieses Dokument im Namen des Arbeitgebers unterschreiben soll.",
        "LABEL_SIGNATURE_METHOD": "Signaturmethode",
        "OPTION_SIGNATURE_METHOD_MANUAL": "Handbuch, mit Stift und Papier",
        "OPTION_SIGNATURE_METHOD_ELECTRONIC": "Elektronisch, mit vollständig legaler E-Signatur",
        "LABEL_EMAIL_MESSAGE": "E-Mail-Nachricht",
        "HELP_TEXT_EMAIL_MESSAGE": "Fügen Sie der E-Mail-Benachrichtigung, die an alle Unterzeichner gesendet wird, eine optionale Nachricht hinzu.",
        "SECTION_UPON_COMPLETION": "Nach Fertigstellung",
        "SECTION_METHOD": "Signaturmethode",
        "LABEL_SAVE_TO": "Speichern unter",
        "HELP_TEXT_SAVE_TO": "Nach der Unterzeichnung wird die Datei in den Dokumenten des Mitarbeiters unter der ausgewählten Kategorie gespeichert.",
        "LABEL_EXPIRY_DATE": "Verfallsdatum",
        "HELP_TEXT_EXPIRY_DATE": "Das Dokument muss mit einem Verfallsdatum versehen werden, falls die Zielkategorie eines verlangt. Sie werden aufgefordert, Maßnahmen zu ergreifen, wenn das Datum näher rückt und erneut, wenn es abgelaufen ist.",
        "CREDITS_DEPLETED_TEXT": "Sie benötigen Credits, um dieses Dokument zur elektronischen Signatur zu senden.",
        "CREDITS_DEPLETED_CTA": "Chatte jetzt mit uns",
        "CREDITS_AVAILABLE_TEXT": "Sie haben noch <b>{{balance}}</b> Guthaben übrig. Für das Senden dieses Dokuments wird ein Guthaben verbraucht.",
        "CREDITS_AVAILABLE_CTA": "Es ist ganz einfach, deine Credits aufzuladen. Senden Sie uns einfach eine Nachricht im Live-Chat.",
        "BUTTON_CANCEL": "Stornieren",
        "BUTTON_DOWNLOAD_PREVIEW": "Vorschau herunterladen",
        "BUTTON_SAVE_DOWNLOAD": "Speichern und herunterladen",
        "BUTTON_SEND": "Senden"
      },
      "NOTES": {
        "ERROR_500": "Wir können momentan keine Notizen laden. Bitte versuchen Sie es erneut.",
        "ALERT_CONFIRM_DELETE": "Bist du sicher, dass du diese Notiz löschen möchtest?",
        "BODY_PLACEHOLDER": "Schreiben Sie eine neue Notiz...",
        "SAVE_BUTTON_TEXT": "Notiz hinzufügen",
        "VISIBILITY_SELF": "Privat",
        "VISIBILITY_SELF_HELP": "Nur für dich sichtbar",
        "VISIBILITY_SENSITIVE": "Sensibel",
        "VISIBILITY_SENSITIVE_HELP": "Nur sichtbar für Personen mit der Erlaubnis, vertrauliche Notizen zu sehen",
        "VISIBILITY_GENERAL": "Allgemeines",
        "VISIBILITY_GENERAL_HELP": "Nur für Personen sichtbar, die die Erlaubnis haben, die Notizen dieses Mitarbeiters zu sehen",
        "EDITED_LABEL": "Bearbeitet",
        "EDIT_LABEL": "Bearbeiten",
        "DELETE_LABEL": "Löschen",
        "NO_NOTES": "Es sind keine Notizen zum Anzeigen vorhanden."
      },
      "DANGER_ZONE": {
        "ANONYMISE": {
          "TITLE": "Mitarbeiter anonymisieren",
          "ERROR_500": "Wir konnten gerade nicht sparen. Bitte versuchen Sie es erneut.",
          "ERROR_403": "Sie sind nicht berechtigt, auf die persönlichen Daten dieses Mitarbeiters zuzugreifen. Dies bedeutet, dass Sie nicht planen können, dass ihr Profil anonymisiert wird.",
          "LABEL_END_DATE": "Enddatum der Beschäftigung",
          "HELP_TEXT": "Zur Einhaltung der Datenschutzgesetze kann Rotaready nach einem bestimmten Zeitraum alle personenbezogenen Daten aus diesem Mitarbeiterprofil entfernen. Im Vereinigten Königreich verlangt das HMRC, dass Aufzeichnungen mindestens sechs Jahre lang aufbewahrt werden.",
          "HELP_TEXT_LEARN": "Erfahre mehr",
          "LABEL_ANONYMISE": "Anonymisieren",
          "CHECKBOX_ANONYMISE": "Anonymisieren Sie diesen Mitarbeiter",
          "LABEL_ANONYMISE_DATE": "Anonymisieren auf",
          "ALERT_ANONYMISE_DATE": "Um diesen Mitarbeiter zu anonymisieren, wählen Sie bitte ein Datum, das sowohl in der Zukunft liegt als auch nach dem letzten Arbeitstag des Mitarbeiters liegt.",
          "BUTTON_SAVE": "Speichern"
        }
      }
    },
    "ABSENCE": {
      "ABSENCE": {
        "TITLE": "Abwesenheitsplan",
        "FILTER_LABEL_APPROVAL_STATE": "Status",
        "FILTER_LABEL_POSITION": "Berufliche Rolle",
        "FILTER_LABEL_STAFF_GROUP": "Gruppe der Mitarbeiter",
        "ERROR_500": "Wir konnten Abwesenheiten gerade nicht laden. Bitte versuchen Sie es erneut.",
        "FILTER_LABEL_ABSENCE_TYPE": "Typ",
        "FILTER_LABEL_REQUESTER": "Anforderer"
      }
    },
    "REQUESTS": {
      "ABSENCE": {
        "DATES_COLUMN_TEXT": "Datum (e)",
        "WHO_COLUMN_TEXT": "Wer",
        "ABSENCE_TYPE_COLUMN_TEXT": "Typ",
        "SUBMITTED_DATE_TEXT": "Eingereicht",
        "HOURS_COLUMN_TEXT": "Stunden",
        "DAYS_COLUMN_TEXT": "Tage",
        "NO_STAFF_MATCH_TEXT": "Wir konnten keine Anfragen finden, die Ihren Filtern entsprechen.",
        "FILTER_LABEL_POSITION": "Berufliche Rolle",
        "FILTER_LABEL_STAFF_GROUP": "Gruppe der Mitarbeiter",
        "ERROR_500": "Wir konnten Abwesenheiten gerade nicht laden. Bitte versuchen Sie es erneut.",
        "FILTER_LABEL_ABSENCE_TYPE": "Typ",
        "FILTER_LABEL_REQUESTER": "Anforderer"
      },
      "CONFIRM_SWAP_APPROVAL_MODAL": {
        "TITLE": "Bist du sicher?",
        "VALIDATION_MANUAL_TEXT": "Um diesen Schichtwechsel rückgängig zu machen, musst du den Dienstplan manuell bearbeiten.",
        "YES_TITLE": "Ja",
        "APPROVE_BUTTON_TEXT": "Genehmigen Sie diesen Tausch jetzt und benachrichtigen Sie beide Mitarbeiter",
        "NO_TITLE": "Nein",
        "DO_NOTHING_BUTTON_TEXT": "Tun Sie nichts — genehmigen Sie diesen Tausch noch nicht",
        "CANCEL_BUTTON_TEXT": "Stornieren"
      },
      "INDEX": {
        "HEADING": "Artikel, die Aufmerksamkeit erfordern",
        "ABSENCE_LABEL": "Abwesenheitsanfragen",
        "SWAPS_LABEL": "Schichtwechsel",
        "DOCUMENTS_EXPIRING_LABEL": "Ablaufende Dokumente",
        "ACCOUNT_APPROVAL_LABEL": "Neue Schreiner"
      },
      "SWAPS": {
        "INITIATED_BY_TEXT": "Initiiert von",
        "INITIATED_TOOLTIP": "Der Mitarbeiter, der seine Schicht verschenken möchte",
        "SHIFT_COLUMN_TEXT": "Ihre Schicht",
        "SWAPPING_WITH_COLUMN_TEXT": "Tauschen mit",
        "SWAPPING_TOOLTIP": "Der Mitarbeiter, der in der Schicht arbeiten möchte",
        "RETURN_SHIFT_COLUMN_TEXT": "Ihre Schicht",
        "OPTION_COLUMN_TEXT": "Optionen",
        "ACCEPTED_DATE_COLUMN_TEXT": "Warte seitdem",
        "NONE_TEXT": "Keine",
        "NO_STAFF_MATCH_TEXT": "Wir konnten keine Anfragen finden, die Ihren Filtern entsprechen.",
        "FILTER_LABEL_PARTICIPANT": "Teilnehmer"
      },
      "DOCUMENTS": {
        "NAME_COLUMN_LABEL": "Name",
        "FILE_NAME_COLUMN_LABEL": "Dokument",
        "FILE_SIZE_COLUMN_LABEL": "Größe",
        "CATEGORY_COLUMN_LABEL": "Kategorie",
        "EXPIRY_DATE_COLUMN_LABEL": "Läuft ab",
        "OPTIONS_COLUMN_LABEL": "Optionen",
        "EXPIRED": "Abgelaufen",
        "UPLOAD_NEW_LINK": "Neues hochladen",
        "NO_DOCS_MATCH_TEXT": "Wir konnten keine Dokumente finden, die Ihren Filtern entsprechen.",
        "NO_CATEGORIES_TEXT": "Sie haben keine Kategorien für Dokumente, die ablaufen können.",
        "FILTER_LABEL_SITE": "Standort/Abteilung",
        "FILTER_LABEL_APPOINTMENT": "Ernennung",
        "FILTER_LABEL_PERSON": "Person",
        "FILTER_LABEL_CATEGORY": "Kategorie",
        "FILTER_LABEL_EXPIRY": "Ablauf",
        "APPOINTMENT_STATUS_ACTIVE": "Aktiv",
        "APPOINTMENT_STATUS_INACTIVE": "Inaktiv",
        "X_WEEKS_OR_LESS": " Wochen oder weniger"
      },
      "NEW_JOINERS": {
        "NAME_COLUMN_LABEL": "Name",
        "POSITION_COLUMN_LABEL": "Berufliche Rolle",
        "ENTITY_COLUMN_LABEL": "Standort",
        "START_DATE_COLUMN_LABEL": "Beginnt am",
        "OPTIONS_COLUMN_LABEL": "Optionen",
        "NO_STAFF_MATCH_TEXT": "Wir konnten niemanden finden, der Ihren Filtern entspricht.",
        "APPROVE_LINK": "Genehmigen",
        "FILTER_LABEL_APPOINTMENT": "Ernennung",
        "FILTER_LABEL_POSITION": "Berufliche Rolle",
        "FILTER_LABEL_DATE_START": "Startdatum",
        "APPOINTMENT_STATUS_ACTIVE": "Aktiv",
        "APPOINTMENT_STATUS_INACTIVE": "Inaktiv"
      }
    },
    "TAGS_GROUPS": {
      "TAGS_GROUPS": {
        "HEADING": "Schlagworte und Gruppen",
        "ASIDE_TEXT": "Ein Mitarbeiter kann nur einer Gruppe angehören, ihm können jedoch viele Schlagworte zugewiesen werden. Gruppen ähneln der Abteilung oder dem Team einer Person, während Schlagworte alles repräsentieren können, was Sie für wichtig halten, wie Fähigkeiten oder persönliche Eigenschaften.",
        "CREATE_NEW_TAG_PLACEHOLDER": "Neues Tag erstellen...",
        "ADDING_BUTTON_TEXT": "Wird hinzugefügt...",
        "ADD_BUTTON_TEXT": "Hinzufügen",
        "TAG_HELP": "Klicken Sie auf ein Schlagwort, um es zu bearbeiten und um zu sehen, wem es zugewiesen wurde:",
        "CREATE_ONE_TEXT": "Sie haben derzeit keine Schlagworte. Warum nicht einen erstellen?",
        "CREATE_NEW_GROUP_PLACEHOLDER": "Neue Gruppe erstellen...",
        "GROUPS_TITLE": "Gruppen",
        "EDIT_LINK_TEXT": "bearbeiten"
      },
      "VIEW_GROUP_MODAL": {
        "EDIT_GROUP_TITLE": "Gruppe bearbeiten",
        "SITE_LABEL": "Standort",
        "EDIT_TAB_HEADING": "Bearbeiten",
        "ORG_WIDE_LABEL": "Unternehmensweit",
        "GROUP_NAME_LABEL": "Name der Gruppe",
        "AVAILABILITY_LABEL": "Verfügbarkeit",
        "ALWAYS_AVAILABLE_TEXT": " Immer verfügbar",
        "ALWAYS_AVAILABLE_HELP": "Mitarbeiter in dieser Gruppe gelten als immer für die Arbeit verfügbar, es sei denn, ihre Verfügbarkeits- oder Abwesenheitsmuster schreiben etwas anderes vor.",
        "ALWAYS_UNAVAILABLE_TEXT": " Immer nicht verfügbar",
        "ALWAYS_UNAVAILABLE_HELP": "Mitarbeiter in dieser Gruppe sind standardmäßig nicht verfügbar. Sie verwenden „Ausnahmen“ und „Verfügbarkeit für eine Woche“, um zu kennzeichnen, wann sie kostenlos sind.",
        "MEMBERS_TAB_HEADING": "Mitglieder",
        "NO_ACTIVE_MEMBERS_HELP": "In dieser Gruppe gibt es keine aktiven Mitglieder. <br /><br />Mitarbeiter werden durch ihre Ernennung einer Gruppe zugewiesen. Suchen Sie jemanden in der Mitarbeiterliste, klicken Sie auf den Tab Termine und bearbeiten Sie einen bestehenden Termin oder fügen Sie einen neuen hinzu.",
        "DELETE_TAB_HEADING": "Löschen",
        "MOVE_MEMBERS_TO_LABEL": "Mitglieder verschieben nach",
        "DELETE_TAB_ALERT": "Das Löschen dieser Gruppe führt dazu, dass {{totalMembers}} Personen in eine andere Gruppe wechseln. Dies ist irreversibel und kann nicht rückgängig gemacht werden.",
        "CONFIRM_NAME_LABEL": "Geben Sie den Gruppennamen ein",
        "CONFIRM_NAME_HELP_TEXT": "Um uns davon zu überzeugen, dass Sie dies tun möchten, geben Sie bitte den Namen der Gruppe in das Feld ein.",
        "DELETING_BUTTON_TEXT": "Löschen...",
        "DELETE_BUTTON_TEXT": "Löschen",
        "SAVING_BUTTON_TEXT": "Wird gespeichert...",
        "SAVE_BUTTON_TEXT": "Speichern",
        "CANCEL_BUTTON_TEXT": "Stornieren"
      },
      "VIEW_TAG_MODAL": {
        "EDIT_TAG_TITLE": "Tag bearbeiten",
        "EDIT_CLONE_LABEL": "Bearbeiten/Klonen",
        "EDIT_EXISTING_TAG_TEXT": " Bestehendes Tag bearbeiten",
        "CLONE_TAG_TEXT": " Tag klonen und als neu speichern",
        "TAG_NAME_LABEL": "Tag-Name",
        "STAFF_MEMBERS_LABEL": "MitarbeiterInnen",
        "TAG_TYPEAHEAD_PLACEHLDER": "Tippen Sie, um Mitarbeiter zu kennzeichnen",
        "LAST_EDITED_LABEL": "Zuletzt bearbeitet",
        "ON_TEXT": "am {{date|momentFormat: 'llll'}}",
        "DELETING_BUTTON_TEXT": "Löschen...",
        "DELETE_BUTTON_TEXT": "Löschen",
        "SAVING_BUTTON_TEXT": "Wird gespeichert...",
        "SAVE_BUTTON_TEXT": "Speichern",
        "CLONING_BUTTON_TEXT": "Klonen...",
        "CLONE_BUTTON_TEXT": "Klonen",
        "CANCEL_BUTTON_TEXT": "Stornieren",
        "CLOSE_BUTTON_TEXT": "Schliessen"
      }
    },
    "ADD_NEW": {
      "TITLE": "Einen Mitarbeiter hinzufügen",
      "LABEL_FIRST_NAME": "Vorname",
      "LABEL_LAST_NAME": "Nachname",
      "LABEL_DOB": "Datum der Geburt",
      "LABEL_EMAIL": "E-Mail-Adresse",
      "HELP_TEXT_EMAIL_OIDC": "Dies muss genau dem entsprechen, was der Mitarbeiter für Ihren Single Sign-On-Dienst verwendet. Es endet normalerweise mit dem Domainnamen Ihres Unternehmens.",
      "HELP_TEXT_EMAIL_ROTAREADY": "Jeder hat ein Konto bei Rotaready und verwendet seine E-Mail-Adresse, um sich anzumelden.",
      "LABEL_COPY_EXISTING": "Einen vorhandenen Mitarbeiter kopieren",
      "PLACEHOLDER_COPY_EXISTING_NO_THANKS": "Nein danke",
      "HELP_TEXT_COPY_EXISTING": "Kopieren Sie den Termin, das Urlaubsgeld und die Tags von einem anderen Mitarbeiter.",
      "LABEL_DATE_START": "Datum des Beginns der Beschäftigung",
      "LABEL_SITE": "Standort/Abteilung",
      "LABEL_PERMISSIONS": "Berechtigungsstufe",
      "LABEL_INVITE": "Zu Rotaready einladen",
      "HELP_TEXT_INVITE_OIDC": "Senden Sie diesem Mitarbeiter einen magischen Link, der ihn einlädt, sich zum ersten Mal anzumelden.",
      "HELP_TEXT_INVITE_ROTAREADY": "Senden Sie diesem Mitarbeiter einen magischen Link, der ihn auffordert, sein Passwort festzulegen und sich anzumelden, um seine Schichten zu sehen.",
      "LABEL_ONBOARDING": "Einarbeitung",
      "CHECKBOX_ONBOARDING_OPT_IN": "Fordern Sie diesen Mitarbeiter auf, das Self-Onboarding abzuschließen, wenn er sich zum ersten Mal bei Rotaready anmeldet. Je nach den Einstellungen Ihres Unternehmens werden sie aufgefordert, persönliche Informationen wie ihre Adresse, Notfallkontakte und Dokumente zum Arbeitsrecht anzugeben.",
      "BUTTON_ADD": "Hinzufügen",
      "BUTTON_ADD_LOADING": "Wird hinzugefügt...",
      "ALERT_SUCCESS": "Erfolg! Beenden Sie die Einrichtung Ihres Mitarbeiters, indem Sie die einzelnen Tabs ausfüllen.",
      "ALERT_500": "Wir konnten den Mitarbeiter gerade nicht hinzufügen. Bitte versuchen Sie es erneut.",
      "ALERT_403": "Sie sind nicht berechtigt, einen neuen Mitarbeiter hinzuzufügen.",
      "ALERT_403_PERMISSION_LEVEL": "Sie sind nicht berechtigt, jemanden mit dieser Berechtigungsstufe hinzuzufügen.",
      "ALERT_403_COPY_EXISTING": "Sie sind nicht berechtigt, diesen Mitarbeiter zu kopieren.",
      "ALERT_403_ENTITY": "Sie sind nicht berechtigt, jemanden zu dieser Site/Abteilung hinzuzufügen.",
      "DUPLICATE_MODAL": {
        "TITLE": "Bestehende Mitarbeiter",
        "DESCRIPTION": "Die Person, die Sie hinzufügen möchten, teilt einige Attribute mit einem oder mehreren vorhandenen Mitarbeitern. Wenn diese Person schon einmal für Ihre Organisation gearbeitet hat, fügen Sie eine neue Beschäftigung hinzu.",
        "COLUMN_NAME": "Name",
        "COLUMN_EMPLOYMENT": "Beschäftigung",
        "COLUMN_SITE": "Standort/Abteilung",
        "COLUMN_SIMILARITIES": "Gemeinsamkeiten",
        "COLUMN_OPTIONS": "Optionen",
        "STATUS_ACTIVE": "Aktiv",
        "STATUS_INACTIVE": "Inaktiv",
        "OPTION_VIEW": "Mitarbeiter ansehen",
        "OPTION_NEW_EMPLOYMENT": "Neue Anstellung",
        "BUTTON_PROCEED": "Fahren Sie trotzdem fort",
        "BUTTON_BACK": "Geh zurück",
        "SIMILARITY_NAME": "Name",
        "SIMILARITY_EMAIL": "E-Mail",
        "SIMILARITY_MOBILE": "Handynummer",
        "SIMILARITY_DOB": "Datum der Geburt"
      }
    }
  },
  "DIRECTIVES": {
    "STAFF": {
      "SEARCH": {
        "PLACEHOLDER": "Suche...",
        "AND_X_MORE": "Und {{x}} mehr...",
        "INACTIVE_APPOINTMENT": "Inaktiv"
      },
      "ADD_ROUTINE_MAPPING_FORM": {
        "STARTS_LABEL": "Beginnt",
        "STARTS_TEXT": "Der erste Tag von {{firstName}} in dieser Routine",
        "ENDS_INPUT_TEXT": " Endet",
        "ENDS_TEXT": "Der letzte Tag von {{firstName}} an dieser Routine",
        "ONGOING_TEXT": "Fortlaufend",
        "SHIFT_ROUTINE_LABEL": "Schichtroutine",
        "OFFSET_LABEL": "Offset",
        "OFFSET_HELP": "Dies ist die Anzahl der Tage, an denen {{firstName}} mit diesem Muster beginnen sollte."
      },
      "AVAILABILITY_RULE": {
        "IM_UNAVAILABLE_TEXT": "Ich werde nicht verfügbar sein",
        "CANCEL_TEXT": "abbrechen",
        "AVAILABLE_TEXT": "VERFÜGBAR",
        "UNAVAILABLE_TEXT": "NICHT VERFÜGBAR",
        "AVAILABLE_FROM_TEXT": "Ich bin nur verfügbar von ",
        "UNAVAILABLE_FROM_TEXT": "Ich bin nicht verfügbar von ",
        "TO_TEXT": " zu ",
        "ALL_DAY": "Den ganzen Tag",
        "AM_ONLY": "Nur AM",
        "PM_ONLY": "Nur PM",
        "AVAIALABLE_CUSTOM": "Verfügbar (Benutzerdefiniert)",
        "UNAVAILABLE_CUSTOM": "Nicht verfügbar (Benutzerdefiniert)",
        "UNAVAILABLE": "Nicht verfügbar",
        "AVAILABLE": "Verfügbar",
        "AVAILABLE_AM": "Verfügbar (nur AM)",
        "AVAILABLE_PM": "Verfügbar (nur nachmittags)"
      },
      "AUDIT_USER_WIDGET": {
        "EDITED_BY": "Herausgegeben von"
      },
      "VALIDATION_BULK_ABSENCE_EDIT": {
        "INFO_TRUNCATED": "Aufgrund des Umfangs der betroffenen Abwesenheitsbuchungen können diese hier nicht in großen Mengen bearbeitet werden. Bitte verwenden Sie den Tab Abwesenheit im Profil des Mitarbeiters oder wenden Sie sich an Rotaready, um weitere Unterstützung zu erhalten.",
        "INFO_SUCCESS": "Es sind keine widersprüchlichen Abwesenheitsbuchungen mehr vorhanden.",
        "ERROR_500": "In diesem Moment ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.",
        "ERROR_400": "Diese Änderung kann nicht vorgenommen werden, da sie sich mit einem Datum überschneidet, das abgesegnet wurde.",
        "COLUMN_DATES": "Datum (e)",
        "COLUMN_TYPE": "Typ",
        "COLUMN_HOURS": "Stunden",
        "COLUMN_DAYS": "Tage",
        "COLUMN_STATUS": "Status",
        "COLUMN_OPTIONS": "Optionen",
        "OPTION_CANCEL": "Stornieren"
      },
      "VALIDATION_BULK_ATTENDANCE_EDIT": {
        "INFO_TRUNCATED": "Aufgrund des Umfangs der betroffenen Präsenzveranstaltungen können diese hier nicht in großen Mengen bearbeitet werden. Bitte verwenden Sie den Tab Anwesenheit im Profil des Mitarbeiters oder wenden Sie sich an Rotaready, um weitere Unterstützung zu erhalten.",
        "INFO_SUCCESS": "Es gibt keine widersprüchlichen Anwesenheitsveranstaltungen mehr.",
        "ERROR_500": "In diesem Moment ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.",
        "ERROR_400": "Diese Änderung kann nicht vorgenommen werden, da sie mit einem Datum zusammenfällt, das unterzeichnet wurde.",
        "COLUMN_EVENT": "Ereignis",
        "COLUMN_DATE": "Datum",
        "COLUMN_OPTIONS": "Optionen",
        "EVENT_TYPE_CLOCK_IN": "Einchecken",
        "OPTION_DELETE": "Löschen"
      },
      "VALIDATION_BULK_ROUTINE_MAPPING_EDIT": {
        "INFO_TRUNCATED": "Aufgrund des Umfangs der betroffenen Schichtroutinemappings können sie hier nicht in großen Mengen bearbeitet werden. Bitte verwenden Sie den Tab Schichtroutinen im Profil des Mitarbeiters oder wenden Sie sich an Rotaready, um weitere Unterstützung zu erhalten.",
        "INFO_SUCCESS": "Es sind keine widersprüchlichen Zuordnungen der Schichtroutinen mehr vorhanden.",
        "ERROR_500": "In diesem Moment ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.",
        "ERROR_400": "Diese Änderung kann nicht vorgenommen werden, da sie sich mit einem Datum überschneidet, das abgesegnet wurde.",
        "COLUMN_START_DATE": "Startdatum",
        "COLUMN_END_DATE": "Enddatum",
        "COLUMN_SHIFT_ROUTINE": "Schichtroutine",
        "COLUMN_OPTIONS": "Optionen",
        "LABEL_OPEN_ENDED": "Unbefristet",
        "OPTION_ADD_END_DATE": "Enddatum hinzufügen",
        "OPTION_DELETE": "Löschen"
      },
      "VALIDATION_BULK_SHIFT_EDIT": {
        "INFO_TRUNCATED": "Aufgrund des Umfangs der betroffenen Schichten können sie hier nicht in großen Mengen bearbeitet werden. Bitte ändern Sie die entsprechenden Dienstpläne oder wenden Sie sich an Rotaready, um weitere Unterstützung zu erhalten.",
        "INFO_SUCCESS": "Es gibt keine widersprüchlichen Schichten mehr.",
        "ERROR_500": "In diesem Moment ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.",
        "ERROR_400": "Diese Änderung kann nicht vorgenommen werden. Es könnte sich mit einem Datum überschneiden, das unterzeichnet wurde. Um mehr zu erfahren, probieren Sie dies auf der Rota selbst aus.",
        "BUTTON_UNASSIGN_SELECTED": "Zuweisung der ausgewählten Elemente aufheben",
        "BUTTON_DELETE_SELECTED": "Ausgewähltes löschen",
        "COLUMN_DATE": "Datum",
        "COLUMN_TIME": "Zeit",
        "COLUMN_DEPARTMENT": "Abteilung",
        "COLUMN_TYPE": "Typ",
        "COLUMN_STATUS": "Status",
        "COLUMN_OPTIONS": "Optionen",
        "STATUS_PUBLISHED": "veröffentlicht",
        "STATUS_DRAFT": "Entwurf",
        "OPTION_UNASSIGN": "Zuweisung aufheben",
        "OPTION_DELETE": "Löschen"
      },
      "VALIDATION_EPHEMERAL_RECORDS": {
        "LABEL_OPEN_ENDED": "(unbefristet)",
        "LABEL_NEW": "Neu",
        "LABEL_EXISTING": "Bestehende",
        "EMPLOYMENT": "Beschäftigung",
        "APPOINTMENT": "Ernennung"
      },
      "ACCOUNT_COMPLETION_TRACKER": {
        "CONSTITUENT_PERSONAL": "Persönlich",
        "CONSTITUENT_EMPLOYMENT": "Beschäftigung",
        "CONSTITUENT_PAY": "Zahlen",
        "CONSTITUENT_HOLIDAY_ALLOWANCE": "Urlaubsgeld"
      }
    },
    "CALENDAR": {
      "TIP": "Um eine Abwesenheit zu beantragen, klicken Sie einfach auf einen Tag oder klicken und ziehen Sie, um mehrere Tage gleichzeitig auszuwählen.",
      "TODAY": "Heute",
      "MONTH": "Monat",
      "WEEK": "Woche",
      "DAY": "Tag"
    },
    "HIERARCHY_ITEM_PICKER": {
      "PICKER": "Pflücker",
      "PLACEHOLDER": "Kein {{itemDescriptor}} ausgewählt",
      "FILTER_TO": "Filtern nach...",
      "RESET": "Zurücksetzen",
      "REMOVED_BY_FILTER": "Ihr ausgewählter {{itemDescriptor}} wurde vom Filter entfernt.",
      "NO_SELECTED": "Kein {{itemDescriptor}} ausgewählt"
    },
    "SUBSCRIBE_BUTTON": {
      "LABEL_ETID_1": "angefordert",
      "LABEL_ETID_2": "storniert",
      "LABEL_ETID_3": "genehmigt",
      "LABEL_ETID_4": "zurückgewiesen",
      "LABEL_ETID_5": "zur Zulassung gekennzeichnet",
      "LABEL_ETID_6": "veröffentlicht",
      "LABEL_ETID_7": "zum Entwurf zurückgekehrt",
      "LABEL_ETID_8": "Woche ist abgemeldet",
      "LABEL_ETID_9": "ausstehende Genehmigung",
      "LABEL_ETID_10": "genehmigt",
      "LABEL_ETID_11": "angefordert",
      "LABEL_ETID_13": "genehmigt",
      "LABEL_ETID_14": "zurückgewiesen",
      "LABEL_ETID_15": "storniert",
      "LABEL_ETID_16": "nähert sich dem Ablauf",
      "LABEL_ETID_17": "abgelaufen",
      "LABEL_ETID_29": "gestartet",
      "LABEL_ETID_30": "von jemandem akzeptiert",
      "LABEL_ETID_31": "von jemandem abgelehnt",
      "LABEL_ETID_32": "abgeschlossen",
      "LABEL_ETID_36": "Woche ist vertauscht",
      "LABEL_ETID_37": "Tag ist abgemeldet",
      "LABEL_ETID_38": "Tag ist vertauscht",
      "DESCRIPTOR_ABSENCE": "eine Abwesenheitsanfrage ist",
      "DESCRIPTOR_ROTA": "eine Rota ist",
      "DESCRIPTOR_ACCOUNT": "ein Mitarbeiter ist",
      "DESCRIPTOR_SWAP": "ein Schichtwechsel ist",
      "DESCRIPTOR_COST_CONTROL": "eine Kostenkontrolle",
      "DESCRIPTOR_DOCUMENT": "ein Dokument ist",
      "DESCRIPTOR_BROADCAST": "eine Schichtübertragung ist",
      "NOUN_ABSENCE": "Abwesenheitsbuchung",
      "NOUN_ROTA": "Rota",
      "NOUN_ACCOUNT": "Mitarbeiter",
      "NOUN_SWAP": "Schichttausch",
      "NOUN_COST_CONTROL": "Kostenkontrolldatensatz",
      "NOUN_DOCUMENT": "Dokument",
      "NOUN_BROADCAST": "Shift-Sendung",
      "NOTIFY_SENTENCE_EXORDIUM": "Benachrichtige mich, wenn",
      "NOTIFY_SENTENCE_TRAILING_IN": "im...",
      "UNSUBSCRIBE": "Abmelden",
      "SUBSCRIBE": "Abonnieren",
      "SEARCH_PLACEHOLDER": "Suche...",
      "BACK_LINK": "Zurück",
      "RESOURCE_SUBSCRIBE_TOOLTIP": "Lassen Sie sich über Änderungen an diesem {{name}} benachrichtigen",
      "RESOURCE_UNSUBSCRIBE_TOOLTIP": "Änderungen an diesem {{name}} abbestellen"
    },
    "ROTA": {
      "DASHBOARD_WEEK": {
        "VIEW_ROTA": "Rota ansehen",
        "LAST_WEEK": "Letzte Woche",
        "THIS_WEEK": "Diese Woche",
        "NO_ATTENDANCE_ISSUES": "Keine Anwesenheitsprobleme",
        "ATTENDANCE_ISSUES": "Anwesenheitsproblem (e)",
        "RESOLVE": "Auflösen",
        "ACTUAL_SALES_ENTERED": "Eingegebene tatsächliche Verkaufszahlen",
        "ACTUAL_SALES_MISSING": "<strong>Tatsächliche Verkaufszahlen</strong> fehlen",
        "WTD_COMPLIANT": "Entspricht den Arbeitszeitvorschriften",
        "VIEW": "Ansicht",
        "WTD_VIOLATIONS": "Arbeitszeitverstoß (en)",
        "SIGN_OFF_NOT_COMPLETED": "<strong>Abmeldung</strong> noch nicht abgeschlossen",
        "SIGN_OFF": "Abmelden",
        "WEEK_SIGNED_OFF": "Woche abgesegnet",
        "ABSENCE_REQUEST": "Abwesenheitsanfragen",
        "ADDED_SALES_FORECAST": "Verkaufsprognose hinzugefügt",
        "DRAFTED_ROTA": "Entworfener Dienstplan",
        "MARKED_FOR_APPROVAL": "Rota zur Genehmigung markiert",
        "PUBLISHED": "Veröffentlichte Rota",
        "OUTSTANDING": "{{absences}} hervorragend",
        "NOT_ADDED": "Nicht hinzugefügt",
        "NOT_DRAFTED": "Nicht ausgearbeitet",
        "NOT_DONE": "Nicht fertig",
        "NOT_PUBLISHED": "Nicht veröffentlicht"
      }
    },
    "SIDEBAR": {
      "NOTIFICATION_POPOVER": {
        "NO_NOTIFICATIONS": "Keine Benachrichtigungen!",
        "MARK_ALL_READ": "Alles als gelesen markieren"
      },
      "SETTINGS_POPOVER": {
        "TIMEZONE": "Diese Zeitzone unterscheidet sich von Ihrer lokalen Zeitzone.",
        "EDIT_PREFERENCES": "Einstellungen bearbeiten",
        "MANAGE_SUBSCRIPTIONS": "Abos verwalten",
        "SHOW_INTERCOM": "Support-Chat anzeigen",
        "HIDE_INTERCOM": "Support-Chat ausblenden",
        "SIGN_OUT": "Abmelden"
      }
    },
    "DATE_PICKER": {
      "CALENDAR": "Kalendarium",
      "GO_TO_TODAY": "Gehe zu heute"
    },
    "ENTITY_SELECTOR": {
      "ORG_WIDE": "Unternehmensweit",
      "NO_OPTIONS": "Keine Optionen",
      "SEARCH_PLACEHOLDER": "Suche..."
    },
    "FILTER_OPTIONS_BUTTON": {
      "IS": "ist",
      "AND": "und",
      "OR": "oder",
      "ANY": "Irgendein",
      "SEARCH_PLACEHOLDER": "Suche...",
      "NO_OPTIONS": "Keine Optionen",
      "SELECT_ALL": "Alles auswählen",
      "CLEAR_SELECTED": "Ausgewähltes löschen"
    },
    "FILTER_STAFF_SEARCH_BUTTON": {
      "IS": "ist",
      "ANY": "Irgendein",
      "RESET": "Zurücksetzen"
    },
    "FILTER_DATE_BUTTON": {
      "ANY": "irgendein",
      "BEFORE": "vor",
      "AFTER": "nach",
      "BETWEEN": "zwischen",
      "IS": "ist",
      "AND": "und",
      "DONE": "Erledigt"
    },
    "FILTER_NUMBER_BUTTON": {
      "ANY": "ist irgendein",
      "GREATER": "ist größer als",
      "LESS": "ist weniger als",
      "EXACTLY": "ist genau",
      "DONE": "Erledigt"
    },
    "VALIDATION_FAILURE": {
      "TITLE": "Probleme bei der Validierung",
      "ISSUE": "Ausgabe",
      "ISSUES": "Angelegenheiten",
      "CHANGE_MESSAGE": "Die Änderung, die Sie vornehmen möchten, hat dazu geführt ",
      "IGNORE_CONTINUE": "Ignoriere und fahre trotzdem fort",
      "IGNORE_PROBLEM": "Ignoriere Problemverschiebungen und versuche es erneut",
      "LOADING": "Wird geladen..."
    },
    "PICK_LIST": {
      "NO_OPTIONS": "Keine Optionen",
      "SEARCH_PLACEHOLDER": "Suche...",
      "DEFAULT_NULL_OPTION_LABEL": "Keine"
    },
    "FILE_UPLOAD": {
      "ALERT_UNSUPPORTED_FILE_TYPE": "Leider wird dieser Dateityp nicht unterstützt.",
      "ALERT_FILE_SIZE": "Leider ist diese Datei zu groß.",
      "LABEL_ACCEPTED_FILE_TYPES": "Zulässige Dateitypen",
      "LABEL_LIMIT": "Begrenzung",
      "INFO_DROP_AVAILABLE_PART_1": "Ziehen Sie eine Datei hierher und legen Sie sie dort ab oder",
      "INFO_DROP_AVAILABLE_PART_2": "zum Stöbern hier klicken",
      "INFO_DROP_NOT_AVAILABLE": "Klicken Sie hier, um nach einer Datei zu suchen",
      "INFO_DRAG_ACTIVE": "Tolles Ziel, Zeit zum Ablegen!",
      "INFO_UPLOAD_FAILED": "Upload fehlgeschlagen, bitte versuchen Sie es erneut"
    }
  },
  "AVAILABILITY": {
    "ABSENCE": {
      "TAB_ABSENCE": "Abwesenheitsbuchungen",
      "TAB_ALLOWANCES": "Zulagen"
    },
    "WEEKLY_AVAILABILITY_MODAL": {
      "ADD_TITLE": "Wöchentliche Verfügbarkeit hinzufügen",
      "EDIT_TITLE": "Wöchentliche Verfügbarkeit bearbeiten",
      "NICKNAME": "Spitzname",
      "PLACEHOLDER": "dh. Während des Studiums",
      "HELP": "Du solltest dieses Verfügbarkeitsmuster nach etwas Sinnvollem benennen, wie zum Beispiel „Während des Studiums“, wenn das bedeutet, dass du während des Studiums für Prüfungen arbeiten kannst, oder „Sommerferien“, wenn du die meiste Zeit frei hast, zum Beispiel.",
      "STARTING_FROM": "Ausgehend von",
      "NEVER_ENDING": "Nie endend",
      "YES_PATTERN_FOREVER": "Ja, lass dieses Muster für immer andauern",
      "NO_PATTERN_DATE": "Nein, es gibt ein Datum, an dem ich möchte, dass dieses Muster aufhört",
      "STOP_DATE": "Stoppdatum",
      "PATTERN_APPLIES": "Muster gilt",
      "EVERY": "Jeder",
      "WEEK": "Woche",
      "SAVING_BUTTON_TEXT": "Wird gespeichert...",
      "SAVE_BUTTON_TEXT": "Speichern",
      "CANCEL": "Stornieren"
    },
    "WEEKLY_AVAILABILITY_MODAL_SINGLE": {
      "TITLE": "Einmalige Verfügbarkeit hinzufügen",
      "SUBTITLE": "Für eine einzige Woche",
      "WEEK_BEGINS": "Die Woche beginnt",
      "HELP": "Dies ist die Woche, für die Sie Ihre Verfügbarkeit markieren möchten.",
      "SAVING_BUTTON_TEXT": "Wird gespeichert...",
      "SAVE_BUTTON_TEXT": "Speichern",
      "CANCEL": "Stornieren"
    },
    "AVAILABILITY": {
      "ADD_AVAILABILITY": "Verfügbarkeit hinzufügen",
      "NEW_WEEKLY_PATTERN": "Neues wöchentliches Muster",
      "NEW_MONTHLY_PATTERN": "Neues monatliches Muster",
      "NEW_CUSTOM_PATTERN": "Neues benutzerdefiniertes Muster",
      "ONE_OFF_PATTERN": "Einmalig (eine Woche)",
      "AVAILABILITY": "Verfügbarkeit",
      "AVAILABILITY_HELP": "Steuern Sie die Verfügbarkeit hier anhand von Verfügbarkeitsmustern. Stellen Sie sich diese wie wiederkehrende Kalenderereignisse vor.",
      "AVAILABILITY_TIP": "Verwenden Sie ein monatliches Muster, um Daten wie „den ersten Mittwoch“ oder „den letzten Samstag“ eines jeden Monats als Ziel festzulegen.",
      "NICKNAME": "Spitzname",
      "APPLIES_FROM_TO": "Gilt von (/bis)",
      "OPTIONS": "Optionen",
      "NO_AVAILABILITY_PATTERNS": "Es gibt keine Verfügbarkeitsmuster. Fügen Sie mit den Schaltflächen oben eine hinzu.",
      "TURN_OFF": "Ausschalten",
      "TURN_ON": "Einschalten",
      "NO_NICKNAME": "Kein Spitzname",
      "WEEKLY": "Wöchentlich",
      "MONTHLY": "Monatlich",
      "CUSTOM": "Benutzerdefiniert",
      "EDIT": "Bearbeiten",
      "TOGGLE_DROPDOWN": "Dropdown umschalten",
      "DELETE": "Löschen",
      "CONFIRM_DELETE_PATTERN": "Bist du sicher? Das Löschen dieses Verfügbarkeitsmusters kann nicht rückgängig gemacht werden.",
      "DELETE_PATTERN_SUCCESS": "Erfolg! Ihr Verfügbarkeitsmuster wurde gelöscht.",
      "ERROR_DELETING_PATTERN": "Wir konnten dieses Muster gerade nicht löschen. Bitte versuchen Sie es erneut.",
      "ERROR_TOGGLING_PATTERN": "Wir konnten dieses Muster gerade nicht ein-/ausschalten. Bitte versuchen Sie es erneut.",
      "ERROR_LOADING_PATTERNS": "Wir konnten Ihre Verfügbarkeitsmuster nicht laden. Bitte versuchen Sie es erneut."
    },
    "DAILY_AVAILABILITY_MODAL": {
      "ADD_CUSTOM_AVAILABILITY": "Benutzerdefinierte Verfügbarkeit hinzufügen",
      "EDIT_CUSTOM_AVAILABILITY": "Benutzerdefinierte Verfügbarkeit bearbeiten",
      "NICKNAME": "Spitzname",
      "NICKNAME_PLACEHOLDER": "dh. Während des Studiums",
      "NICKNAME_HELP": "Du solltest dieses Muster nach etwas Sinnvollem benennen.",
      "STARTING_FROM": "Ausgehend von",
      "NEVER_ENDING": "Nie endend",
      "CONTINUE_FOREVER": "Ja, lass dieses Muster für immer andauern",
      "PATTERN_STOP": "Nein, es gibt ein Datum, an dem ich möchte, dass dieses Muster aufhört",
      "STOP_DATE": "Stoppdatum",
      "PATTERN_DAYS": "Mustertage",
      "PATTERN_NO_DAYS": "Dieses Muster hat keine Tage. Fügen Sie eine hinzu, indem Sie die Schaltfläche unten verwenden.",
      "DAY_NUMBER": "Tag {{number}}",
      "ILL_BE": "Ich werde",
      "DELETE": "Löschen",
      "ADD_DAY": "Tag hinzufügen",
      "SAVE": "Speichern",
      "SAVE_LOADING": "Wird gespeichert...",
      "CANCEL": "Stornieren",
      "UNAVAILABLE": "Nicht verfügbar",
      "AVAILABLE": "Verfügbar",
      "AVAILABLE_AM": "Verfügbar (nur AM)",
      "AVAILABLE_PM": "Verfügbar (nur nachmittags)",
      "OTHER": "Andere (bitte angeben...)",
      "MINIMUM_TWO_DAYS": "Sie müssen mindestens zwei Tage in diesem Muster haben.",
      "STOPS_BEFORE_STARTS": "Ihr Muster ist derzeit so eingestellt, dass es stoppt, bevor es beginnt!",
      "AVAILABILITY_END_BEFORE_START": "Eine oder mehrere Ihrer Verfügbarkeitsregeln haben eine Endzeit, die vor der Startzeit liegt.",
      "ERROR_SAVING_PATTERN": "Dieses Verfügbarkeitsmuster kann derzeit nicht gespeichert werden. Bitte versuchen Sie es erneut.",
      "ERROR_UPDATING_PATTERN": "Dieses Verfügbarkeitsmuster kann derzeit nicht aktualisiert werden. Bitte versuchen Sie es erneut."
    },
    "EDIT_PATTERN_EXCEPTIONS_MODAL": {
      "EDIT_EXCEPTIONS": "Ausnahmen bearbeiten",
      "EXCEPTIONS_TIP": "Ihre Verfügbarkeitsmuster kennzeichnen, wann Sie nicht frei arbeiten können. Sie können uns jedoch Ihren Kalender verwenden, um uns mitzuteilen, dass Sie an einem Tag arbeiten können, an dem Sie normalerweise nicht arbeiten könnten. Klicken Sie einfach auf die rote Markierung „Nicht verfügbar“ und Sie können eine Ausnahme machen.",
      "EXCEPTIONS": "Die folgende Liste zeigt die Ausnahmen, die Sie von Ihrem Verfügbarkeitsmuster gemacht haben. Wir gehen davon aus, dass es Ihnen freisteht, an diesen Tagen zu arbeiten:",
      "DATE": "Datum",
      "OPTIONS": "Optionen",
      "UNDO": "Rückgängig machen",
      "NO_EXCEPTIONS": "Du hast noch keine Ausnahmen gemacht.",
      "CLOSE": "Schliessen",
      "ERROR_DELETING_EXCEPTION": "Leider konnten wir die Ausnahme gerade nicht löschen. Bitte versuchen Sie es erneut."
    },
    "MONTHLY_AVAILABILITY_MODAL": {
      "ADD_MONTHLY_AVAILABILITY": "Monatliche Verfügbarkeit hinzufügen",
      "EDIT_MONTHLY_AVAILABILITY": "Monatliche Verfügbarkeit bearbeiten",
      "NICKNAME": "Spitzname",
      "NICKNAME_PLACEHOLDER": "dh. Während des Studiums",
      "NICKNAME_HELP": "Du solltest dieses Verfügbarkeitsmuster nach etwas Sinnvollem benennen, wie zum Beispiel „Während des Studiums“, wenn das bedeutet, dass du während des Studiums für Prüfungen arbeiten kannst, oder „Sommerferien“, wenn du die meiste Zeit frei hast, zum Beispiel.",
      "STARTING_FROM": "Ausgehend von",
      "NEVER_ENDING": "Nie endend",
      "CONTINUE_FOREVER": "Ja, lass dieses Muster für immer andauern",
      "PATTERN_STOP": "Nein, es gibt ein Datum, an dem ich möchte, dass dieses Muster aufhört",
      "STOP_DATE": "Stoppdatum",
      "PATTERN_RULES": "Musterregeln",
      "PATTERN_NO_RULES": "Dieses Muster hat keine Regeln. Fügen Sie eine hinzu, indem Sie die Schaltfläche unten verwenden.",
      "EVERY": "Jeder",
      "ON_THE": "auf dem",
      "DELETE": "Löschen",
      "ADD_RULE": "Regel hinzufügen",
      "SAVE": "Speichern",
      "SAVE_LOADING": "Wird gespeichert...",
      "CANCEL": "Stornieren",
      "ORDINAL_LAST": "zuletzt",
      "ORDINAL_FIRST": "erstmalig",
      "ORDINAL_SECOND": "zweiter",
      "ORDINAL_THIRD": "dritte",
      "ORDINAL_FOURTH": "vierter",
      "ORDINAL_FIFTH": "Fünftel",
      "MONDAY": "Montag",
      "TUESDAY": "Dienstag",
      "WEDNESDAY": "Mittwoch",
      "THURSDAY": "Donnerstag",
      "FRIDAY": "Freitag",
      "SATURDAY": "Samstag",
      "SUNDAY": "Sonntag",
      "AVAILABLE": "Verfügbar",
      "AVAILABLE_AM": "Nur AM",
      "AVAILABLE_PM": "Nur PM",
      "MONTH": "Monat",
      "MONTHS": "Monate",
      "OTHER": "Andere (bitte angeben...)",
      "STOPS_BEFORE_STARTS": "Ihr Muster ist derzeit so eingestellt, dass es stoppt, bevor es beginnt!",
      "AVAILABILITY_END_BEFORE_START": "Eine oder mehrere Ihrer Verfügbarkeitsregeln haben eine Endzeit, die vor der Startzeit liegt.",
      "ERROR_SAVING_PATTERN": "Dieses Verfügbarkeitsmuster kann derzeit nicht gespeichert werden. Bitte versuchen Sie es erneut.",
      "ERROR_UPDATING_PATTERN": "Dieses Verfügbarkeitsmuster kann derzeit nicht aktualisiert werden. Bitte versuchen Sie es erneut."
    },
    "OVERVIEW": {
      "SCHEDULE": "Mein Terminplan",
      "SCHEDULE_HELP": "Dies ist Ihr vollständiger Zeitplan, einschließlich der Ihnen zugewiesenen Schichten, der genehmigten Abwesenheit und der Verfügbarkeit.",
      "SHIFTS": "Schichten",
      "ABSENCE": "Abwesenheit",
      "UNAVAILABLE": "Nicht verfügbar (Muster)",
      "EDIT_EXCEPTIONS": "Ausnahmen bearbeiten",
      "ICAL_FEED": "iCalendar-Feed",
      "ICAL_FEED_HELP": "Ein iCalendar-Feed ermöglicht es Ihnen, Ihren Zeitplan außerhalb von Rotaready einzusehen.",
      "ICAL_FEED_TIP": "Sobald Sie eine iCalendar-Feed-URL generiert haben, können Sie sie kopieren und in <a href=\"https://support.apple.com/en-gb/HT202361\" target=\"_blank\">Apple Calendar</a>, <a href=\"https://support.google.com/calendar/answer/37100\" target=\"_blank\">Google Calendar</a> oder eine andere Kalendersoftware einfügen.",
      "COPIED": "In die Zwischenablage kopiert!",
      "GENERATE": "Generieren"
    }
  },
  "ATTENDANCE": {
    "VALIDATION_CODES": {
      "CODE_1": "Das entsprechende Datum wurde abgesegnet, daher können wir keine Änderungen daran vornehmen.",
      "CODE_2": "Dies würde zu einer ungültigen Abfolge von Ereignissen führen. Zum Beispiel muss ein Clock-Out nach einem Clock-In kommen, kann aber nicht vor einem anderen Clock-Out kommen. Überprüfen Sie die Ereignisse in der Umgebung und versuchen Sie es erneut.",
      "CODE_3": "Der gewählte Standort/die gewählte Abteilung ist ungültig.",
      "CODE_4": "Wir können hier kein Clock-In hinzufügen, da der gewählte Mitarbeiter an diesem Tag keinen Termin hat.",
      "CODE_5": "Wir können hier keine Veranstaltung hinzufügen, da gleichzeitig bereits eine Veranstaltung existiert.",
      "CODE_6": "Der gewählte Mitarbeiter ist ungültig.",
      "CODE_7": "Diese Änderung würde das Ereignis auf einen früheren Zeitpunkt verschieben als das Ereignis, das davor liegt. Versuche, eine andere Uhrzeit zu wählen oder bearbeite zuerst das frühere Ereignis.",
      "CODE_8": "Diese Änderung würde das Ereignis auf einen späteren Zeitpunkt als das darauf folgende Ereignis verschieben. Versuche, eine andere Uhrzeit zu wählen oder bearbeite zuerst das spätere Ereignis."
    },
    "COMMON": {
      "EVENT_ADD_SUCCESS": "Die Veranstaltung wurde erfolgreich hinzugefügt.",
      "ABSENCE_ADD_SUCCESS": "Sie wurden als abwesend markiert.",
      "NO_CLOCK_IN_MODAL_TITLE": "Ereignis hinzufügen",
      "NO_CLOCK_IN_MODAL_MESSAGE": "Was würdest du gerne machen?",
      "NO_CLOCK_IN_MODAL_ADD_EVENT": "Uhr hinzufügen",
      "NO_CLOCK_IN_MODAL_ADD_EVENT_DESC": "Manuelles Hinzufügen eines Anwesenheitsnachweises",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE": "Mark abwesend",
      "NO_CLOCK_IN_MODAL_ADD_ABSENCE_DESC": "Fügen Sie einen Abwesenheitsdatensatz wie Krankheit hinzu",
      "UNMATCHED_MODAL_TITLE": "Unübertroffene Besucherzahl",
      "UNMATCHED_MODAL_MESSAGE": "Es gibt zwei Möglichkeiten, eine unübertroffene Besucherzahl zu beheben:",
      "UNMATCHED_MODAL_ADD_SHIFT": "Schicht hinzufügen",
      "UNMATCHED_MODAL_ADD_SHIFT_DESC": "Erstellen Sie eine neue Schicht, um widerzuspiegeln, was gearbeitet wurde",
      "UNMATCHED_MODAL_EDIT_EVENT": "Teilnahme bearbeiten",
      "UNMATCHED_MODAL_EDIT_EVENT_DESC": "Passen Sie die Anwesenheitslisten an, wenn sie nicht korrekt sind",
      "OVERTIME_MODAL_TITLE": "Überstunden überprüfen",
      "OVERTIME_MODAL_MESSAGE_IN": "{{name}} hat früher eingecheckt und die Schicht entspricht einer Gehaltsregel. Was würdest du gerne machen?",
      "OVERTIME_MODAL_MESSAGE_OUT": "{{name}} hat verspätet ausgeschieden und die Schicht entspricht einer Gehaltsregel. Was würdest du gerne machen?",
      "OVERTIME_MODAL_APPROVE": "Überstunden genehmigen",
      "OVERTIME_MODAL_APPROVE_DESC": "{{name}} wird für {{duration}} Minuten Überstunden bezahlt",
      "OVERTIME_MODAL_REJECT": "Überstunden ablehnen",
      "OVERTIME_MODAL_REJECT_DESC": "{{name}} wird nicht für die geleisteten Überstunden bezahlt",
      "OVERTIME_UPDATE_ERROR": "Wir können die Veranstaltung derzeit nicht aktualisieren. Bitte versuchen Sie es erneut.",
      "MODAL_USER_NOTES_LABEL": 'Notizen von {{name}}'
    },
    "BY_STAFF": {
      "FILTER_LABEL_DATE": "Datum",
      "MANUAL_CLOCK_IN": "Manuelles Eintakten",
      "EVENT_TYPE": "Ereignis",
      "RECORDED_TIME": "Aufgezeichnete Zeit",
      "METHOD": "Erfasst von",
      "OPTIONS": "Optionen",
      "TRUSTED_DEVICE": "Vertrauenswürdiges Gerät",
      "MOBILE_APP": "Rotaready-App",
      "MANUALLY_OVERRIDDEN": "Manuell überfahren",
      "MANUAL_ENTRY": "Manuelle Eingabe",
      "DELETE": "Löschen",
      "NO_EVENTS": "Es sind keine Präsenzveranstaltungen vorzuweisen.",
      "CLOCK_IN": "Uhr rein",
      "CLOCK_OUT": "Uhr aus",
      "BREAK_ON": "In der Pause",
      "BREAK_OFF": "Auszeit",
      "ERROR_LOADING_EVENTS": "Wir können die Teilnehmerzahl momentan nicht laden. Bitte versuchen Sie es erneut.",
      "ERROR_DELETING": "Wir können die Teilnahme derzeit nicht löschen. Bitte versuchen Sie es erneut.",
      "DELETE_SUCCESS": "Das Ereignis wurde erfolgreich gelöscht.",
      "ADD_SUCCESS": "Die Veranstaltung wurde erfolgreich hinzugefügt.",
      "LABEL_EDITED": "Bearbeitet"
    },
    "ISSUES": {
      "TITLE": "Probleme mit der Teilnahme",
      "FILTER_DATE": "Datum",
      "FILTER_ISSUE_TYPE": "Art der Ausgabe",
      "FILTER_POSITION": "Berufliche Rolle",
      "FILTER_USER": "Person",
      "ISSUES_HELP": "Probleme sollten gelöst werden, da sie sonst zu Unstimmigkeiten in Berichten und Gehaltsabrechnungen führen. Standardmäßig kennzeichnet Rotaready Ausgaben der letzten {{defaultIssuePeriod}} Tage.",
      "IS_THIS": "Ist das",
      "YES": "Ja",
      "NO": "Nein",
      "HEADING_NAME": "Name",
      "HEADING_SITE": "Standort",
      "HEADING_ISSUE": "Problem",
      "HEADING_SHIFT": "Schicht",
      "HEADING_START": "Starten",
      "HEADING_FINISH": "Fertig",
      "HEADING_CLOCK_IN": "Einchecken",
      "HEADING_CLOCK_OUT": "Ausschalten",
      "HEADING_OPTIONS": "Optionen",
      "NOT_FOUND": "Nicht gefunden",
      "RESOLVE": "Auflösen",
      "DISMISS": "Abweisen",
      "NO_ATTENDANCE_ISSUES": "Du hast keine Anwesenheitsprobleme, gut gemacht!",
      "UNMATCHED_ATTENDANCE": "Unübertroffene Besucherzahl",
      "NO_CLOCK_IN": "Kein Clock-In",
      "NO_CLOCK_OUT": "Kein Taktout",
      "SUSPECT_CLOCK_OUT": "Aussperrung des Verdächtigen",
      "SUSPECT_ATTENDANCE": "Verdächtige Anwesenheit",
      "BREAK_NOT_TAKEN": "Pause nicht eingelegt",
      "INVALID_BREAKS": "Ungültige Pause (n)",
      "SUSPECT_BREAK": "Verdächtiger Bruch",
      "EARLY_CLOCK_IN": "Frühe Einchecken",
      "LATE_CLOCK_OUT": "Spätes Auschecken",
      "UNMATCHED_ATTENDANCE_EXPLANATION": "Rotaready konnte keine Schicht finden, um dieser Besucherzahl zu entsprechen",
      "NO_CLOCK_IN_EXPLANATION": "Der Mitarbeiter hat sich nicht für seine Schicht eingeschrieben",
      "NO_CLOCK_OUT_EXPLANATION": "Der Mitarbeiter hat nach seiner Schicht nicht ausgecheckt",
      "SUSPECT_CLOCK_OUT_EXPLANATION": "Der Mitarbeiter ist viel später als zur geplanten Endzeit ausgelaufen",
      "SUSPECT_ATTENDANCE_EXPLANATION": "Der Mitarbeiter wurde für eine viel kürzere Zeit als geplant eingecheckt",
      "BREAK_NOT_TAKEN_EXPLANATION": "Die Schicht hat eine Pause, aber eine wurde nicht genommen",
      "INVALID_BREAKS_EXPLANATION": "Die Pause (n) dieser Teilnahme sind unvollständig (d. h. es fehlt eine Pause)",
      "SUSPECT_BREAK_EXPLANATION": "Der Mitarbeiter hatte viel länger als geplant Pause",
      "EARLY_CLOCK_IN_EXPLANATION": "Die Schicht entspricht einer Gehaltsregel, die eine Genehmigung für eine frühe Uhrzeit erfordert",
      "LATE_CLOCK_OUT_EXPLANATION": "Die Schicht entspricht einer Gehaltsregel, für die eine späte Abreise eine Genehmigung erforderlich ist",
      "ERROR_LOADING_ATTENDANCE": "Wir können Ihre Anwesenheitsprobleme derzeit nicht laden. Bitte versuchen Sie es erneut.",
      "SOMETHING_WENT_WRONG": "Ups, irgendwas ist schief gelaufen. Möglicherweise müssen Sie es erneut versuchen!",
      "CONFIRM_DISMISS": "Möchten Sie dieses Anwesenheitsproblem wirklich ignorieren?",
      "DATE_RANGE_TOO_WIDE": "Du kannst nicht mehr als 5 Wochen wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "CLOCK_OUT_DAYS_LATER": "Dieser Clock-Out liegt einen oder mehrere Tage nach dem Clock-In."
    },
    "OVERVIEW": {
      "UNMATCHED_LABEL": "{{count}} nicht gefunden",
      "OPTIONS_DAILY": "täglich",
      "OPTIONS_WEEKLY": "Wöchentlich",
      "OPTIONS_DAY_ROLL_OVER": "Rollover am Tag",
      "FILTER_POSITION": "Berufliche Rolle",
      "FILTER_USER": "Person",
      "MIDNIGHT": "Mitternacht",
      "TOOLS": "Werkzeuge",
      "CURRENTLY_CLOCKED_IN": "Personen, die gerade angemeldet sind",
      "ATTENDANCE_HELP": "Die Anwesenheit wird automatisch den Schichten zugeordnet, wenn die An- oder Abmeldung innerhalb von <b>{{autoMatchThreshold}} Stunde (n)</b> vor einer geplanten Start- oder Endzeit erfolgt.",
      "MATCHED_HEADING_SCHEDULED": "Geplant",
      "MATCHED_HEADING_ACTUAL": "Tatsächlich",
      "MATCHED_HEADING_AFTER_SANITISATION": "Nach der Desinfektion",
      "MATCHED_HEADING_NAME": "Name",
      "MATCHED_HEADING_SITE": "Standort",
      "MATCHED_HEADING_START": "Starten",
      "MATCHED_HEADING_FINISH": "Fertig",
      "MATCHED_HEADING_PAID_HOURS": "Bezahlte Stunden",
      "MATCHED_HEADING_UNPAID_HOURS": "Unbezahlte Stunden",
      "COVER_SHIFT": "Schicht abdecken",
      "NO_CLOCK_IN": "Noch kein Clock-In",
      "NO_CLOCK_OUT": "Noch kein Clockout",
      "OVERTIME_APPROVED": "Genehmigt",
      "OVERTIME_REJECTED": "Abgelehnt",
      "OVERTIME_REVIEW": "Bewertung",
      "OVERTIME_APPROVED_BY": "Überstunden genehmigt von {{name}}",
      "OVERTIME_REJECTED_BY": "Überstunden wurden von {{name}} abgelehnt",
      "MATCHED_NO_RESULTS": "Es sind keine Schichten vorzuweisen.",
      "UNMATCHED_ATTENDANCE": "Unübertroffene Besucherzahl",
      "UNMATCHED_ATTENDANCE_HELP": "Wenn die Anwesenheit nicht innerhalb von <b>{{autoMatchThreshold}} Stunde (n)</b> nach der Start-/Endzeit einer Schicht liegt, musst du die Schicht oder die Anwesenheitsaufzeichnungen manuell anpassen, um sie in Einklang zu bringen:",
      "UNMATCHED_HEADING_NAME": "Name",
      "UNMATCHED_HEADING_SITE": "Standort",
      "UNMATCHED_HEADING_DATE": "Datum",
      "UNMATCHED_HEADING_CLOCK_IN": "Uhr rein",
      "UNMATCHED_HEADING_CLOCK_OUT": "Uhr aus",
      "UNMATCHED_HEADING_OPTIONS": "Optionen",
      "NO_UNMATCHED_ATTENDANCE": "Hier gibt es keine unübertroffene Besucherzahl. Gute Arbeit!",
      "NONE_YET": "Noch keine",
      "RESOLVE": "Auflösen",
      "ERROR_LOADING_ATTENDANCE": "Wir können Ihre Anwesenheitshistorie derzeit nicht laden. Bitte versuchen Sie es erneut.",
      "TIMELINESS_EARLY_TOOLTIP": "{{duration}} Minuten zu früh",
      "TIMELINESS_LATE_TOOLTIP": "{{duration}} Minuten zu spät",
      "TRUNCATED_RESULT_MESSAGE": "Es gibt noch mehr zu sehen, aber damit alles reibungslos läuft, mussten wir dein Ergebnis kürzen. <br />Versuchen Sie, nach Tätigkeitsbereich, Standort/Abteilung zu filtern oder den Zeitraum zu reduzieren."
    },
    "VIEW_EVENT_MODAL": {
      "VIEW_ATTENDANCE": "Teilnahme ansehen",
      "STAFF_MEMBER": "Wer",
      "EVENT_TYPE": "Ereignis",
      "RECORDED_TIME": "Aufgezeichnete Zeit",
      "METHOD": "Erfasst von",
      "TRUSTED_DEVICE": "Vertrauenswürdiges Gerät",
      "MOBILE_APP": "Rotaready-App",
      "MANUALLY_OVERRIDDEN": "Manuell überschrieben",
      "MANUAL_ENTRY": "Manuelle Eingabe",
      "ON": "auf",
      "OVERTIME": "Überstunden",
      "APPROVED": "Genehmigt",
      "REJECTED": "Abgelehnt",
      "FACIAL_RECOGNITION": "Gesichtserkennung",
      "FACIAL_RECOGNITION_PROCESS": "Bilder werden in der Regel innerhalb weniger Minuten nach der Aufnahme verarbeitet.",
      "FACIAL_RECOGNITION_WAITING": "Wartet auf Bearbeitung...",
      "TRUSTS_PHOTO": "Rotaready vertraut diesem Foto",
      "NO_TRUSTS_PHOTO": "Rotaready traut diesem Foto nicht",
      "CONFIDENT": "zuversichtlich",
      "UNABLE_TO_IDENTIFY": "Ein Gesicht kann nicht eindeutig identifiziert werden",
      "PROCESSED_ON": "Verarbeitet am",
      "ACKNOWLEDGED_BY": "Bestätigt von {{name}} am",
      "REFERENCE_IMAGE": "Referenzbild",
      "REFERENCE_IMAGE_HELP": "Dieses Bild wird nicht verarbeitet, sondern als Referenz für den Vergleich verwendet",
      "EDIT": "Bearbeiten",
      "CLOSE": "Schliessen",
      "TAB_DETAILS": "Einzelheiten",
      "TAB_PHOTO": "Foto",
      "TAB_LOCATION": "Standort",
      "CLOCK_IN": "Uhr rein",
      "CLOCK_OUT": "Uhr aus",
      "BREAK_ON": "Brechen Sie ein",
      "BREAK_OFF": "Brechen Sie ab",
      "HAPPENED_HERE": "ist hier passiert",
      "TAB_AUDIT": "Geschichte",
      "AUDIT_TABLE_TIME": "Anwesenheitszeit",
      "AUDIT_TABLE_OVERTIME": "Überstunden",
      "AUDIT_TABLE_WHO": "Aufgenommen von",
      "NOTES": 'Notizen'
    },
    "EDIT_EVENT_MODAL": {
      "TITLE": "Teilnahme bearbeiten",
      "CLOCK_IN": "Uhr rein",
      "CLOCK_OUT": "Uhr aus",
      "BREAK_ON": "Brechen Sie ein",
      "BREAK_OFF": "Brechen Sie ab",
      "ERROR_UPDATING": "Wir können die Veranstaltung derzeit nicht aktualisieren. Bitte versuchen Sie es erneut.",
      "ERROR_DELETING": "Wir können das Event derzeit nicht löschen. Bitte versuchen Sie es erneut.",
      "CONFIRM_DELETE": "Bist du sicher, dass du dieses Event löschen möchtest?\nHandelt es sich um einen Clock-In, wird auch der entsprechende Clock-Out entfernt.",
      "PRIOR_EVENT_INFO": "Das vorhergehende Ereignis ist ein <b>{{eventType}}</b> am <b>{{date|momentFormat: 'll</b> - LT'}}",
      "PRIOR_EVENT_CONFLICT": "Am <b>{{<b>date|momentFormat: 'll - LT'}} gibt es bereits einen {{</b>eventType</b>}}. Sie müssen eine andere Uhrzeit auswählen.",
      "LABEL_WHO": "Wer",
      "LABEL_EVENT": "Ereignis",
      "LABEL_DATE": "Datum & Uhrzeit",
      "DATE_IN_FUTURE": "Diese Zeit ist noch nicht abgelaufen!",
      "LABEL_OVERTIME": "Überstunden",
      "APPROVE": "Genehmigen",
      "REJECT": "Ablehnen",
      "LABEL_OVERTIME_HELP": "Rotaready stellte einige Überstunden fest, als es diese Präsenzveranstaltung mit einer Schicht kombinierte. Wenn Sie dem zustimmen, werden die Überstunden gemäß der Lohnregel der Schicht vergütet.",
      "DELETE": "Löschen",
      "CANCEL": "Stornieren",
      "SAVE": "Änderungen speichern"
    },
    "ADD_EVENT_MODAL": {
      "TITLE": "Teilnahme hinzufügen",
      "CLOCK_IN": "Uhr rein",
      "CLOCK_OUT": "Uhr aus",
      "BREAK_ON": "Brechen Sie ein",
      "BREAK_OFF": "Brechen Sie ab",
      "ERROR_ACTIONING": "Wir können die Veranstaltung derzeit nicht hinzufügen. Bitte versuchen Sie es erneut.",
      "PRIOR_EVENT_INFO": "Das vorhergehende Ereignis ist ein <b>{{eventType}}</b> am <b>{{date|momentFormat: 'll</b> - LT'}}",
      "PRIOR_EVENT_CONFLICT": "Am <b>{{<b>date|momentFormat: 'll - LT'}} gibt es bereits einen {{</b>eventType</b>}}. Sie müssen eine andere Uhrzeit auswählen.",
      "LABEL_EVENT": "Ereignis",
      "LABEL_DATE": "Datum & Uhrzeit",
      "LABEL_BREAK": "Dauer der Pause",
      "LABEL_BREAK_HELP": "Nach der gewählten Dauer wird eine Pause für Sie hinzugefügt.",
      "DATE_IN_FUTURE": "Diese Zeit ist noch nicht abgelaufen!",
      "CANCEL": "Stornieren",
      "SAVE": "Teilnahme hinzufügen",
      "BREAK_MINUTES": "Minuten"
    }
  },
  "DIALOG": {
    "TEXTBOX_DIALOG": {
      "CANCEL": "Stornieren"
    },
    "TWO_BUTTON_DIALOG": {
      "CANCEL": "Stornieren"
    }
  },
  "OTHER": {
    "EDIT_PREFERENCES_MODAL": {
      "TITLE": "Einstellungen aktualisieren",
      "SUBTITLE": "Kontaktinformationen",
      "SUBTITLE_HELP": "Wenn Sie Ihr Passwort vergessen haben, können wir Ihnen nur helfen, es zurückzusetzen, wenn wir alternative Möglichkeiten haben, Sie zu kontaktieren",
      "EMAIL_LABEL": "E-Mail-Adresse",
      "EMAIL_PLACEHOLDER": "Keine angegeben",
      "EMAIL_HELP": "Bitte geben Sie eine gültige E-Mail-Adresse an.",
      "MOBILE_LABEL": "Handy",
      "MOBILE_PLACEHOLDER": "Keine angegeben",
      "MOBILE_HELP": "Bitte geben Sie eine gültige Handynummer oder gar keine an.",
      "ACCOUNT_INFO": "Informationen zum Konto",
      "ACCOUNT_INFO_HELP": "Hier kannst du deine Sprache einstellen",
      "LANGUAGE_LABEL": "Sprache",
      "LANGUAGE_HELP": "Rotaready wird in der von Ihnen gewählten Sprache angezeigt.",
      "OTHER_SETTINGS_TITLE": "Andere Einstellungen",
      "CLOCK_IN_LABEL": "PIN zum Einchecken",
      "CLOCK_IN_HELP": "Wenn Sie beim Ein- und Ausloggen einen PIN-Code eingeben müssen, können Sie hier einen neuen eingeben. Es muss vierstellig sein.",
      "NOTIFICATIONS_TITLE": "Benachrichtigungen",
      "NOTIFICATIONS_HELP": "Legen Sie fest, wie Sie über Ereignisse informiert werden möchten, die Sie abonniert haben ",
      "MANAGE_SUBSCRIPTIONS": "Abonnements verwalten",
      "ROTA_UPDATES": "Rota-Aktualisierungen",
      "CRITIAL_SHIFT": "Wichtige Schichtaktualisierungen",
      "EMAIL": " E-Mail",
      "MOBILE_APP": " App für Mobilgeräte",
      "SHIFT_UPDATED": "Wenn eine Schicht, die du in den nächsten 24 Stunden arbeiten wirst, aktualisiert wurde",
      "REGULAR_SHIFT": "Regelmäßige Schicht-Updates",
      "SHIFT_UPDATED_BEYOND": "Updates für alle Schichten, in denen Sie nach den nächsten 24 Stunden arbeiten werden",
      "WEEKLY_SUMMARY": "Wöchentliche Zusammenfassung",
      "SMS": " SMS",
      "UPCOMING_WEEK": "Eine Zusammenfassung Ihrer Schichten für die kommende Woche, die Ihnen am Abend vor Wochenbeginn zugeschickt wird",
      "APPROVAL": "Zulassung",
      "APPROVAL_HELP": "Wenn ein Dienstplan als genehmigungsbereit markiert ist",
      "PUBLISHING": "Publizieren",
      "PUBLISHING_HELP": "Wenn ein Rota veröffentlicht oder unveröffentlicht ist",
      "SHIFT_SWAPS": "Schichtwechsel",
      "SWAP_UPDATES": "Updates austauschen ",
      "REQUESTED": "Angefordert",
      "REQUESTED_HELP": "Wenn jemand einen neuen Schichtwechsel beantragt",
      "ACCEPTED": "Akzeptiert",
      "ACCEPTED_HELP": "Wenn jemand einen Schichtwechsel akzeptiert",
      "APPROVED": "Genehmigt",
      "APPROVED_HELP": "Wenn ein Schichtwechsel vom Management genehmigt wird",
      "REJECTED": "Abgelehnt",
      "REJECTED_HELP": "Wenn ein Schichtwechsel vom Management abgelehnt wird",
      "CANCELLED": "Abgesagt",
      "CANCELLED_HELP": "Wenn ein Schichtwechsel storniert wird",
      "ABSENCE_UPDATES": "Abwesenheits- und Feiertags-Updates",
      "ABSENCE_REQUESTED": "Abwesenheit beantragt",
      "ABSENCE_REQUESTED_HELP": "Wenn eine neue Abwesenheit beantragt wird",
      "ABSENCE_UPDATE": "Aktualisierung der Abwesenheit",
      "ABSENCE_UPDATE_HELP": " Wenn ein Abwesenheitsantrag vom Management genehmigt, abgelehnt oder storniert wird",
      "COST_CONTROL": "Kontrolle der Kosten",
      "SIGN_OFF": "Abmelden",
      "SIGN_OFF_HELP": "Wenn sich eine Website eine Woche lang abmeldet",
      "SIGN_OFF_REVERSE": "Abmeldung rückgängig gemacht",
      "SIGN_OFF_REVERSE_HELP": "Wenn eine Website die Abmeldung für eine Woche rückgängig macht",
      "ADMIN": "Admin",
      "ACCOUNT_APPROVAL": "Genehmigung des Kontos",
      "ACCOUNT_APPROVAL_HELP": "Wenn ein Konto entweder auf die Genehmigung wartet oder genehmigt wurde",
      "DOCUMENTS": "Dokumente",
      "DOCUMENTS_HELP": "Wenn ein Dokument abgelaufen ist oder bald ablaufen wird",
      "OTHER": "Andere",
      "COMPANY_ANNOUNCEMENTS": "Ankündigungen des Unternehmens",
      "COMPANY_HELP": "Wichtige Ankündigungen Ihres Arbeitgebers",
      "DAILY_ADMIN_UPDATE": "Tägliches Admin-Update",
      "DAILY_UPDATE_HELP": "Wir benachrichtigen Sie, wenn Sie Anwesenheitsprobleme haben oder wenn Abwesenheitsanfragen oder Schichtwechsel noch nicht genehmigt werden müssen.",
      "PHONE_ONLY": "Benachrichtigungen mit diesem Symbol sind nur mit den Rotaready iPhone- und Android-Apps verfügbar.",
      "CHANGE_PASSWORD": "Passwort ändern",
      "SAVE": "Speichern",
      "SAVING": "Wird gespeichert...",
      "CANCEL": "Stornieren"
    },
    "MANAGE_SUBSCRIPTIONS_MODAL": {
      "TITLE": "Abos verwalten",
      "HELP": "Ihre Abonnements legen fest, worüber Sie informiert werden möchten.",
      "UNSUBSCRIBE_ALL": "Von allen abmelden",
      "NOTIFY_WHEN": "Benachrichtige mich, wenn {{descriptor}}",
      "UNSUBSCRIBE_TOOLTIP": "Abmelden",
      "UNSUBSCRIBE_ALL_TOOLTIP": "Von allen abmelden",
      "ACTION_IN": "{{action}} in ",
      "FILTER_NO_SUBSCRIPTIONS": "Sie haben keine aktiven Abonnements in {{name}}.",
      "ALL_NO_SUBSCRIPTIONS": "Sie haben keine aktiven Abonnements.",
      "CLOSE": "Schliessen"
    },
    "RELEASE_REFRESH_MODAL": {
      "TITLE": "Rotaready-Aktualisierung",
      "BODY": "Wir haben gerade ein Update für Rotaready veröffentlicht, bei dem Sie die Seite neu laden müssen. Bitte klicken Sie auf die Schaltfläche unten.",
      "REFRESH": "Seite aktualisieren"
    },
    "EDIT_AVATAR_MODAL": {
      "TITLE": "Profilbild",
      "INTRO_COPY": "Ein Bild hilft anderen, Sie zu erkennen, und informiert Sie darüber, wann Sie in Ihrem Konto angemeldet sind.",
      "BUTTON_CHANGE": "Laden Sie ein neues Bild hoch",
      "BUTTON_CANCEL": "Stornieren",
      "BUTTON_SAVE": "Speichern",
      "ALERT_REMOVE": "Bist du sicher, dass du dieses Profilbild entfernen möchtest?",
      "ALERT_FILE_TYPE_NOT_ALLOWED": "Dieser Dateityp ist nicht zulässig.",
      "ALERT_ERROR_500": "Wir konnten dein Bild gerade nicht aktualisieren. Bitte versuchen Sie es erneut.",
      "ALERT_SUCCESS_ADD": "Ihr Profilbild wurde aktualisiert. Es kann eine Weile dauern, bis alle Dienste von Rotaready aktualisiert sind.",
      "ALERT_SUCCESS_REMOVE": "Ihr Profilbild wurde entfernt. Es kann eine Weile dauern, bis alle Dienste von Rotaready aktualisiert sind."
    }
  },
  "REPORTS": {
    "INDEX_LIST": {
      "TITLE": "Berichte",
      "TRONC_STATUS": "Status Tronc",
      "CURRENTLY_CLOCKED_IN": "Personen, die derzeit angemeldet sind",
      "ROTA_PROGRESS": "Rota Progress",
      "SIGN_OFF_PROGRESS": "Fortschritt der Abmeldung",
      "PAYROLL_EXPORT": "Export von Gehaltsabrechnungen",
      "LABOUR_BREAKDOWN": "Zusammenbruch der Erwerbsbevölkerung",
      "WORKING_TIME_VIOLATIONS": "Verstöße gegen die Arbeitszeit",
      "CONTRACTUAL_OBLIGATION_SUMMARY": "Zusammenfassung der vertraglichen Verpflichtungen",
      "DOCUMENT_SIGNATURES": "Signieren von Dokumenten",
      "EXPIRING_DOCUMENTS": "Ablaufende Dokumente",
      "EMPLOYEE_EXPORT": "Angaben zum Mitarbeiter",
      "JOINERS_LEAVERS": "Schreiner & Abgänger",
      "STAFF_TURNOVER": "Fluktuation",
      "ANNIVERSARY": "Jahrestage",
      "EMPLOYMENT_CHANGES": "Veränderungen bei der Beschäftigung",
      "CATEGORY_HR": "HR",
      "CATEGORY_DOCUMENTS": "Dokumente",
      "CATEGORY_ROTAS": "Terminplanung",
      "CATEGORY_PAYROLL": "Gehaltsabrechnung",
      "CATEGORY_ATTENDANCE": "Teilnahme",
      "CATEGORY_ABSENCE": "Abwesenheit",
      "CATEGORY_COST_CONTROL": "Kontrolle der Kosten",
      "ABSENCE_EXPORT": "Export der Abwesenheit",
      "ALLOWANCES_EXPORT": "Export von Zulagen",
      "UNTAKEN_HOLIDAY_COST": "Nicht in Anspruch genommene Urlaubskosten",
      "COST_CONTROL_RAW_DATA": "Rohdatenexport",
      "NEW_HIRE_TURNOVER": "Fluktuation bei Neueinstellungen",
      "ABSENCE_DAYS_EXPORT": "Export von Abwesenheitstagen",
      "SHIFT_TYPE_STREAM_BREAKDOWN": "Schichtart und Streamaufteilung",
      "XERO_EXPORT": "Export nach Xero",
      "DOCUMENT_PRESENCE": "Präsenz von Dokumenten",
      "DOCUMENTS_EXPORT": "Dokumente exportieren",
      "ATTENDANCE_BREAKDOWN": "Aufschlüsselung der Teilnahme",
      "LATENESS": "Verspätung",
      "LABOUR_BREAKDOWN_FILE_EXPORT": "Export von Arbeitsaufschlüsselungsdateien",
      "CATEGORY_ASYNC_FILE_EXPORTS": "Große berichte",
      "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": "Export von Arbeitsaufschlüsselungsdateien",
      "PAYROLL_ASYNC_FILE_EXPORT": "Export von Lohn- und Gehaltsabrechnungsdateien",
      "RAW_COST_CONTROL_ASYNC_FILE_EXPORT": "Export der Rohkostenkontrolldatei",
    },
    "LATENESS": {
      "TITLE": "Verspätung",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "COLUMN_ID": "ID",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE_APPOINTMENT": "Standort (Termin)",
      "COLUMN_DEPT_APPOINTMENT": "Abteilung (Ernennung)",
      "COLUMN_INSTANCES": "Instanzen",
      "COLUMN_VARIANCE": "Durchschnittliche Varianz",
      "FILTER_DATE": "Datum",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_USER": "Person",
      "FILTER_OVERTIME": "Überstunden",
      "FILTER_OVERTIME_NA": "Nicht bestätigt",
      "FILTER_OVERTIME_APPROVED": "Genehmigt",
      "FILTER_OVERTIME_REJECTED": "Abgelehnt",
      "FILTER_CLOCK_IN": "Einchecken",
      "FILTER_CLOCK_OUT": "Ausschalten",
      "FILTER_MINS_LATE": "5 Minuten zu spät",
      "FILTER_MINS_EARLY": "5 Minuten zu früh",
      "LABEL_MINUTES": "Minuten",
      "BUTTON_EXPORT": "Exportieren",
      "NO_MATCH": "Wir konnten keine Daten finden, die Ihren Filtern entsprechen."
    },
    "ATTENDANCE_BREAKDOWN": {
      "TITLE": "Aufschlüsselung der Teilnahme",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "COLUMN_ID": "ID",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE_APPOINTMENT": "Standort (Termin)",
      "COLUMN_DEPT_APPOINTMENT": "Abteilung (Ernennung)",
      "COLUMN_SITE_ATTRIBUTION": "Seite (Namensnennung)",
      "COLUMN_DEPT_ATTRIBUTION": "Abteilung (Namensnennung)",
      "COLUMN_DATE": "Geschäftliches Datum",
      "COLUMN_SHIFT_TYPE": "Art der Schicht",
      "COLUMN_RECORD_TYPE": "Typ des Datensatzes",
      "COLUMN_SCHEDULED": "Geplant",
      "COLUMN_ACTUAL": "Tatsächlich",
      "COLUMN_VARIANCE": "Varianz",
      "COLUMN_CAPTURED_BY": "Erfasst von",
      "COLUMN_EDITED_BY": "Herausgegeben von",
      "COLUMN_EDITED_DATE": "Datum der Bearbeitung",
      "COLUMN_OVERTIME_STATE": "Genehmigung von Überstunden",
      "COLUMN_OVERTIME_EDITED_BY": "Verlängerung bestätigt von",
      "COLUMN_OVERTIME_EDITED_DATE": "Datum der bestätigten Verlängerung",
      "FILTER_DATE": "Datum",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_USER": "Person",
      "FILTER_OVERTIME": "Überstunden",
      "FILTER_OVERTIME_NA": "Nicht bestätigt",
      "FILTER_OVERTIME_APPROVED": "Genehmigt",
      "FILTER_OVERTIME_REJECTED": "Abgelehnt",
      "FILTER_CLOCK_IN": "Einchecken",
      "FILTER_CLOCK_OUT": "Ausschalten",
      "FILTER_MINS_LATE": "5 Minuten zu spät",
      "FILTER_MINS_EARLY": "5 Minuten zu früh",
      "BUTTON_EXPORT": "Exportieren",
      "NO_MATCH": "Wir konnten keine Daten finden, die Ihren Filtern entsprechen."
    },
    "DOCUMENTS_EXPORT": {
      "TITLE": "Dokumente exportieren",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_TOGGLE_USER_ACTIVE": "Aktive Mitarbeiter",
      "FILTER_USER": "Person",
      "FILTER_CATEGORY": "Kategorie",
      "FILTER_ACKNOWLEDGEMENT": "Status",
      "FILTER_OPTION_ACKNOWLEDGED": "Bestätigt",
      "FILTER_OPTION_NOT_ACKNOWLEDGED": "Nicht bestätigt",
      "BUTTON_EXPORT": "Exportieren",
      "COLUMN_USER_ID": "Mitarbeiter-ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE": "Standort",
      "COLUMN_DEPARTMENT": "Abteilung",
      "COLUMN_CATEGORY": "Kategorie",
      "COLUMN_FILE_NAME": "Name",
      "COLUMN_FILE_SIZE": "Dateigröße (KB)",
      "COLUMN_UPLOADED_BY": "Hochgeladen von",
      "COLUMN_UPLOADED_DATE": "Datum des Hochladens",
      "COLUMN_EXPIRY_DATE": "Verfallsdatum",
      "COLUMN_SIGNATURE_METHOD": "Signaturmethode",
      "COLUMN_EDITED_BY": "Zuletzt bearbeitet von",
      "COLUMN_EDITED_DATE": "Datum der letzten Bearbeitung",
      "COLUMN_ACKNOWLEDGED": "Bestätigt",
      "COLUMN_ACKNOWLEDGED_DATE": "Bestätigtes Datum",
      "COLUMN_NOTES": "Hinweise",
      "NO_MATCH": "Wir konnten keine Mitarbeiter für Ihre Filter finden."
    },
    "DOCUMENT_PRESENCE": {
      "TITLE": "Präsenz von Dokumenten",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_TOGGLE_USER_ACTIVE": "Aktive Mitarbeiter",
      "FILTER_CATEGORY": "Kategorie",
      "BUTTON_EXPORT": "Exportieren",
      "COLUMN_USER_ID": "Mitarbeiter-ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE": "Standort",
      "COLUMN_DEPARTMENT": "Abteilung",
      "COLUMN_EMPLOYMENT_ACTIVE": "Aktive Beschäftigung",
      "COLUMN_JOIN_DATE": "Datum des Beitritts",
      "COLUMN_LEAVE_DATE": "Datum der Abreise",
      "COLUMN_DOCUMENTS_VALIDITY": "Dokumente gültig",
      "NO_MATCH": "Wir konnten keine Mitarbeiter für Ihre Filter finden."
    },
    "SHIFT_TYPE_STREAM_BREAKDOWN": {
      "TITLE": "Aufschlüsselung des Schichttyps",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_LAST_TWO_WEEKS": "Letzte zwei Wochen",
      "JUMP_TO_LAST_MONTH": "Letzter Monat",
      "FILTER_SITE": "Standort/Abteilung",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "FILTER_DATA_SOURCE": "Quelle der Daten",
      "FILTER_USER": "Person",
      "FILTER_BEHAVIOUR": "Verhalten",
      "BEHAVIOUR_SHIFT_TYPE": "Art der Schicht",
      "BEHAVIOUR_STREAM": "Streamen",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE": "Aktuelle Seite",
      "COLUMN_DEPARTMENT": "Aktuelle Abteilung",
      "COLUMN_STAFF_GROUP": "Aktuelle Mitarbeitergruppe",
      "COLUMN_TOTAL": "Insgesamt",
      "ROW_TOTAL": "Insgesamt",
      "INFO_NO_DATA": "Es gibt keine Datensätze, die Ihren Filtern entsprechen.",
      "INFO_NO_FILTER": "Wählen Sie oben einen Standort/eine Abteilung aus, um loszulegen.",
      "PAY_AMOUNT_TYPE_HOURLY": "Stundensatz",
      "PAY_AMOUNT_TYPE_DAILY": "Tagesrate",
      "PAY_AMOUNT_TYPE_ANNUAL": "Jahresgehalt",
      "DATA_SOURCE_SHIFTS": "Schichten",
      "DATA_SOURCE_MATCHED": "Desinfiziert",
      "DATE_ERROR_DIFF": "Du kannst nicht mehr als 6 Wochen wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "ERROR_400": "Ihre Parameter führen dazu, dass zu viele Daten für einen einzelnen Bericht generiert werden. Bitte reduzieren Sie den Umfang Ihrer Anfrage und versuchen Sie es erneut.",
      "STREAM_UNALLOCATED": "Nicht zugewiesen",
      "FILTER_METRIC": "Wert",
      "METRIC_WAGES": "Grundlohnkosten",
      "METRIC_HOURS": "Bezahlte Stunden"
    },
    "ABSENCE_DAYS_EXPORT": {
      "TITLE": "Export von Abwesenheitstagen",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "COLUMN_USER_ID": "Mitarbeiter-ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE": "Standort",
      "COLUMN_DEPARTMENT": "Abteilung",
      "COLUMN_STAFF_GROUP": 'Gruppe der Mitarbeiter',
      "COLUMN_JOB_ROLE_NAME": 'Berufliche Rolle',
      "COLUMN_DATE": "Datum",
      "COLUMN_HOURS": "Stunden",
      "COLUMN_HEADER_ID": "Header-ID",
      "COLUMN_ABSENCE_TYPE": "Typ",
      "COLUMN_ABSENCE_TYPE_REASON": "Grund",
      "COLUMN_STATUS": "Status",
      "COLUMN_SCOPE": "Geltungsbereich",
      "COLUMN_START_DATE": "Startdatum der Kopfzeile",
      "COLUMN_END_DATE": "Enddatum der Kopfzeile",
      "COLUMN_START_TIME": "Startzeit",
      "COLUMN_END_TIME": "Endzeit",
      "COLUMN_TOTAL_HOURS": "Header-Stunden",
      "COLUMN_TOTAL_DAYS": "Kopfzeilentage",
      "COLUMN_CANCELLED": "Abgesagt",
      "FILTER_DATE": "Datum",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_STATUS": "Status",
      "FILTER_TYPE": "Typ",
      "FILTER_USER": "Person",
      "FILTER_TOGGLE_CANCELLED": "Storniert einbeziehen",
      "FILTER_TOGGLE_USER_ACTIVE": "Aktive Mitarbeiter",
      "BUTTON_EXPORT": "Exportieren",
      "NO_MATCH": "Wir konnten keine Abwesenheit finden, die Ihren Filtern entspricht."
    },
    "NEW_HIRE_TURNOVER": {
      "TITLE": "Fluktuation bei Neueinstellungen",
      "FILTER_DATE": "Datum des Beitritts",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_LAST_TWO_WEEKS": "Letzte zwei Wochen",
      "JUMP_TO_LAST_MONTH": "Letzter Monat",
      "FILTER_AGGREGATION": "Aggregation",
      "AGGREGATION_ENTITY_GROUP": "Standort/Abteilung",
      "AGGREGATION_POSITION": "Berufliche Rolle",
      "AGGREGATION_STAFF_GROUP": "Gruppe der Mitarbeiter",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "DATE_ERROR_DIFF": "Sie können nicht mehr als 12 Monate wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "COLUMN_AGGREGATION_LABEL": "Name",
      "COLUMN_JOINERS": "Schreiner",
      "COLUMN_LEAVERS": "Abgänger",
      "COLUMN_TURNOVER_RATE": "Fluktuationsrate",
      "DEPARTED_WITHIN": "Datum der Abreise",
      "DAYS_LABEL": "innerhalb von {{count}} Tagen",
      "LABEL_TOTAL": "Insgesamt",
      "LABEL_GRAND_TOTAL": "Gesamtsumme",
      "FILTER_LEAVER_REASON": "Grund für den Austritt"
    },
    "COST_CONTROL_RAW_DATA": {
      "TITLE": "Export von Rohdaten zur Kostenkontrolle",
      "ERROR_500": "Wir konnten die Daten gerade nicht exportieren. Bitte versuchen Sie es erneut.",
      "BUTTON_EXPORT": "Exportieren",
      "LOADING_TEXT": "Ihr Export wird generiert.",
      "METRIC_HOURS": "Stunden",
      "METRIC_WAGES": "Löhne",
      "METRIC_SALES": "Verkäufe",
      "METRIC_COVERS": "Deckungen",
      "SUBDIVISION_PREDICTION": "Prognosen",
      "SUBDIVISION_FORECAST": "Prognosen",
      "SUBDIVISION_ACTUAL": "Istwerte",
      "FILTER_METRICS": "Metriken",
      "FILTER_SUBDIVISIONS": "Unterabteilungen",
      "FILTER_ENTITY": "Standort/Abteilung",
      "FILTER_ENTITY_BUTTON": "Standort/Abteilung",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_LAST_TWO_WEEKS": "Letzte zwei Wochen",
      "JUMP_TO_LAST_MONTH": "Letzter Monat",
      "CALCULATE_ACTUAL_CHECKBOX": "Berechnen Sie die tatsächlichen Stunden/Löhne im Handumdrehen, wenn keine abgemeldeten Daten vorhanden sind.",
      "DATE_ERROR_DIFF": "Sie können nicht mehr als 2 Monate wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "CALCULATE_ACTUAL_ERROR": "Die tatsächlichen Stunden/Löhne können nicht für mehr als eine Woche oder fünfzehn Standorte/Abteilungen im laufenden Betrieb berechnet werden."
    },
    "ABSENCE_EXPORT": {
      "TITLE": "Export der Abwesenheit",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "COLUMN_ID": "ID",
      "COLUMN_USER_ID": "Mitarbeiter-ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE": "Standort",
      "COLUMN_DEPARTMENT": "Abteilung",
      "COLUMN_STAFF_GROUP": 'Gruppe der Mitarbeiter',
      "COLUMN_JOB_ROLE_NAME": 'Berufliche Rolle',
      "COLUMN_ABSENCE_TYPE": "Typ",
      "COLUMN_ABSENCE_TYPE_REASON": "Grund",
      "COLUMN_STATUS": "Status",
      "COLUMN_SCOPE": "Geltungsbereich",
      "COLUMN_START_DATE": "Startdatum",
      "COLUMN_END_DATE": "Enddatum",
      "COLUMN_START_TIME": "Startzeit",
      "COLUMN_END_TIME": "Endzeit",
      "COLUMN_TOTAL_HOURS": "Stunden",
      "COLUMN_TOTAL_DAYS": "Tage",
      "COLUMN_REQUEST_NAME": "Angefordert von",
      "COLUMN_REQUEST_DATE": "Datum der Anfrage",
      "COLUMN_REQUEST_MESSAGE": "Nachricht anfordern",
      "COLUMN_REVIEW_NAME": "Überprüft von",
      "COLUMN_REVIEW_DATE": "Datum der Überprüfung",
      "COLUMN_REVIEW_MESSAGE": "Nachricht überprüfen",
      "COLUMN_EDITED_NAME": "Zuletzt bearbeitet von",
      "COLUMN_EDITED_DATE": "Datum der letzten Bearbeitung",
      "COLUMN_CANCELLED": "Abgesagt",
      "COLUMN_CANCELLED_NAME": "Abgesagt von",
      "COLUMN_CANCELLED_DATE": "Abgesagtes Datum",
      "FILTER_DATE": "Start-/Enddatum",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_STATUS": "Status",
      "FILTER_TYPE": "Typ",
      "FILTER_USER": "Person",
      "FILTER_TOGGLE_CANCELLED": "Storniert einbeziehen",
      "FILTER_TOGGLE_USER_ACTIVE": "Aktive Mitarbeiter",
      "BUTTON_EXPORT": "Exportieren",
      "NO_MATCH": "Wir konnten keine Abwesenheit finden, die Ihren Filtern entspricht."
    },
    "ALLOWANCE_EXPORT": {
      "TITLE": "Export von Zulagen",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "COLUMN_ID": "ID",
      "COLUMN_USER_ID": "Mitarbeiter-ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE": "Standort",
      "COLUMN_DEPARTMENT": "Abteilung",
      "COLUMN_ABSENCE_TYPE": "Art der Abwesenheit",
      "COLUMN_YEAR_STARTS": "Das Jahr beginnt",
      "COLUMN_EFFECTIVE_DATE": "Datum des Inkrafttretens",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Zeitplan der Rückstellung",
      "COLUMN_UNIT": "Einheit",
      "COLUMN_DEDUCTIBLE": "Absetzbar",
      "COLUMN_TOTAL_ACCRUED": "Aufgelaufener Gesamtbetrag",
      "COLUMN_OPENING_BALANCE": "Eröffnungsbilanz",
      "COLUMN_BOOKED": "Gebucht",
      "COLUMN_BOOKED_AND_ELAPSED": "Gebucht und abgelaufen",
      "COLUMN_REMAINING": "Verbleibend",
      "COLUMN_ACCRUAL_ESTIMATE": "Schätzung der Periodenabgrenzung",
      "COLUMN_CARRIED_OVER": "Übertragen",
      "COLUMN_OVERSPEND_LIMIT": "Zu viel ausgeben",
      "COLUMN_TOTAL_TO_ACCRUE": "Anfallender Höchstbetrag",
      "COLUMN_ACCRUAL_RATE": "Überschreibung des Fälligkeitssatzes",
      "COLUMN_ADDED_NAME": "Hinzugefügt von",
      "COLUMN_ADDED_DATE": "Datum hinzugefügt",
      "COLUMN_EDITED_NAME": "Zuletzt bearbeitet von",
      "COLUMN_EDITED_DATE": "Datum der letzten Bearbeitung",
      "COLUMN_FINALISED": "Finalisiert",
      "COLUMN_FINALISED_REFERENCE_DATE": "Endgültiges Referenzdatum",
      "COLUMN_FINALISED_EDITED_NAME": "Finalisiert von",
      "COLUMN_FINALISED_EDITED_DATE": "Finalisiert am",
      "COLUMN_FINALISED_NOTES": "Abgeschlossene Notizen",
      "COLUMN_PROJECT_TOTAL_ACCRUED": "Bei der Buchung aufgelaufene Beträge vorhersagen",
      "FILTER_DATE": "Datum des Inkrafttretens",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_TYPE": "Art der Abwesenheit",
      "FILTER_SCHEDULE_OF_ACCRUAL": "Zeitplan der Rückstellung",
      "FILTER_USER": "Person",
      "FILTER_TOGGLE_USER_ACTIVE": "Aktive Mitarbeiter",
      "BUTTON_EXPORT": "Exportieren",
      "NO_MATCH": "Wir konnten keine Zulagen finden, die Ihren Filtern entsprechen."
    },
    "UNTAKEN_HOLIDAY_COST": {
      "TITLE": "Nicht in Anspruch genommene Urlaubskosten",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "ERROR_400": "Ihr Gültigkeitszeitraum ist zu groß. Bitte schränken Sie ihn ein und versuchen Sie es erneut.",
      "BUTTON_EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "FILTER_DATE": "Datum des Inkrafttretens der Vergütung",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_TOGGLE_USER_ACTIVE": "Aktive Mitarbeiter",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE": "Standort",
      "COLUMN_DEPARTMENT": "Abteilung",
      "COLUMN_SCHEDULE_OF_ACCRUAL": "Zeitplan der Rückstellung",
      "COLUMN_UNIT": "Einheit",
      "COLUMN_DEDUCTIBLE": "Absetzbar",
      "COLUMN_TOTAL_ACCRUED": "Aufgelaufener Gesamtbetrag",
      "COLUMN_BOOKED": "Gebucht",
      "COLUMN_BOOKED_AND_ELAPSED": "Verstrichen",
      "COLUMN_UNTAKEN": "Ungenommen",
      "COLUMN_PAY_AMOUNT": "Betrag",
      "COLUMN_PAY_AMOUNT_TYPE": "Typ",
      "COLUMN_PAY_PROJECTED_COST": "Projizierte Kosten",
      "COLUMN_HEADER_ALLOWANCE": "Zulage",
      "COLUMN_HEADER_PAY": "Zahlen",
      "LABEL_DEDUCTIBLE_YES": "Ja",
      "LABEL_DEDUCTIBLE_NO": "Nein",
      "TEXT_GRAND_TOTAL": "Gesamtsumme"
    },
    "EMPLOYMENT_CHANGES": {
      "TITLE": "Veränderungen bei der Beschäftigung",
      "MODEL_APPOINTMENT": "Ernennung",
      "MODEL_PAY": "Zahlen",
      "MODEL_EMPLOYEE": "Mitarbeiter",
      "PROPERTY_EMPLOYEE_FIRST_NAME": "Vorname",
      "PROPERTY_EMPLOYEE_LAST_NAME": "Nachname",
      "PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET": "Manuell eingestellte Durchschnittsstunden",
      "PROPERTY_APPOINTMENT_ENTITY_GROUP": "Standort",
      "PROPERTY_APPOINTMENT_ENTITY": "Abteilung",
      "PROPERTY_APPOINTMENT_GROUP": "Gruppe der Mitarbeiter",
      "PROPERTY_APPOINTMENT_POSITION": "Berufliche Rolle",
      "PROPERTY_APPOINTMENT_COMPANY": "Firma",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE": "Hauptverpflichtung",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT": "Mindestverpflichtung (Einheit)",
      "PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD": "Mindestverpflichtung (Zeitraum)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE": "Max. Verpflichtung",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT": "Max. Verpflichtung (Einheit)",
      "PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD": "Max. Verpflichtung (Zeitraum)",
      "PROPERTY_APPOINTMENT_LINE_MANAGER": "Vorgesetzter",
      "PROPERTY_APPOINTMENT_PAYROLL_CALENDAR": "Gehaltsabrechnungskalender",
      "PROPERTY_PAY_AMOUNT": "Betrag",
      "PROPERTY_PAY_AMOUNT_TYPE": "Art des Betrags",
      "PROPERTY_PAY_UPLIFT": "Lohnerhöhung",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_LAST_TWO_WEEKS": "Letzte zwei Wochen",
      "JUMP_TO_LAST_MONTH": "Letzter Monat",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_PROPERTY": "Eigentum",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "DATE_ERROR_DIFF": "Sie können nicht mehr als 12 Monate wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE": "Standort",
      "COLUMN_DEPARTMENT": "Abteilung",
      "COLUMN_CHANGE": "Änderung",
      "COLUMN_EFFECTIVE_DATE": "Wirksam",
      "COLUMN_PROPERTY": "Eigentum",
      "COLUMN_NEW_VALUE": "Neuer Wert",
      "COLUMN_OLD_VALUE": "Alter Wert",
      "COLUMN_EDITED_BY": "Geändert von",
      "COLUMN_EDITED_ON": "Datum geändert"
    },
    "ANNIVERSARY": {
      "TITLE": "Jahrestage",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_LAST_TWO_WEEKS": "Letzte zwei Wochen",
      "JUMP_TO_LAST_MONTH": "Letzter Monat",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_TYPE": "Jubiläum",
      "TYPE_BIRTHDAY": "Geburtstag",
      "TYPE_EMPLOYMENT": "Arbeitsjubiläum",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "DATE_ERROR_DIFF": "Sie können nicht mehr als 12 Monate wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE": "Standort",
      "COLUMN_DEPARTMENT": "Abteilung",
      "COLUMN_TYPE": "Jubiläum",
      "COLUMN_YEARS": "Jahre",
      "COLUMN_DATE": "Datum"
    },
    "STAFF_TURNOVER": {
      "TITLE": "Fluktuation",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_LAST_TWO_WEEKS": "Letzte zwei Wochen",
      "JUMP_TO_LAST_MONTH": "Letzter Monat",
      "FILTER_AGGREGATION": "Aggregation",
      "AGGREGATION_ENTITY_GROUP": "Standort/Abteilung",
      "AGGREGATION_POSITION": "Berufliche Rolle",
      "AGGREGATION_STAFF_GROUP": "Gruppe der Mitarbeiter",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "DATE_ERROR_DIFF": "Sie können nicht mehr als 12 Monate wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "COLUMN_AGGREGATION_LABEL": "Name",
      "COLUMN_HEADCOUNT_START": "Mitarbeiterzahl (Start)",
      "COLUMN_HEADCOUNT_END": "Mitarbeiterzahl (Ende)",
      "COLUMN_JOINERS": "Schreiner",
      "COLUMN_REHIRES": "Wiedereinstellungen",
      "COLUMN_LEAVERS": "Abgänger",
      "COLUMN_TRANSFERS_IN": "Überweisungen in",
      "COLUMN_TRANSFERS_OUT": "Ausgehende Transfers",
      "COLUMN_ATTRITION_RATE": "Fluktuationsrate",
      "COLUMN_AVERAGE_SERVICE": "Durchschnittlicher Service",
      "LABEL_TOTAL": "Insgesamt",
      "LABEL_GRAND_TOTAL": "Gesamtsumme",
      "FILTER_LEAVER_REASON": "Grund für den Austritt"
    },
    "DOCUMENT_SIGNATURES": {
      "TITLE": "Signieren von Dokumenten",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "INFO_NO_DATA": "Es gibt keine Datensätze, die Ihren Filtern entsprechen.",
      "LABEL_METHOD_MANUAL": "Manuell",
      "LABEL_METHOD_ELECTRONIC": "Elektronisch",
      "LABEL_STATUS_PENDING": "Ausstehend",
      "LABEL_STATUS_REJECTED": "Abgelehnt",
      "LABEL_STATUS_SIGNED": "Signiert",
      "LABEL_STATUS_CANCELLED": "Abgesagt",
      "LABEL_RESPONSE_NONE": "Warte auf Antwort",
      "LABEL_RESPONSE_REJECTED": "Abgelehnt",
      "LABEL_RESPONSE_SIGNED": "Signiert",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_RECIPIENT_USER": "Empfänger",
      "FILTER_ADDED_USER": "Absender",
      "FILTER_METHOD": "Signaturmethode",
      "FILTER_STATUS": "Status",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "COLUMN_RECIPIENT": "Empfänger",
      "COLUMN_EMPLOYER": "Gegenpartei",
      "COLUMN_DOCUMENT": "Dokument",
      "COLUMN_DOCUMENT_CATEGORY": "Kategorie des Reiseziels",
      "COLUMN_METHOD": "Methode",
      "COLUMN_STATUS": "Status",
      "COLUMN_ADDED_USER": "Absender",
      "COLUMN_ADDED_DATE": "Datum",
      "EMPLOYER_NOT_SET": "Nicht erforderlich",
      "VIEW_MODAL": {
        "TITLE": "Zu unterschreigendes Dokument",
        "LABEL_FILE": "Dokument",
        "LABEL_EXPIRY": "Ablauf des Dokuments",
        "LABEL_TEMPLATE": "Verwendete Vorlage",
        "LABEL_CATEGORY": "Kategorie des Reiseziels",
        "LABEL_ADDED_BY": "Hinzugefügt von",
        "LABEL_METHOD": "Methode",
        "TEMPLATE_NOT_USED": "Keine",
        "EXPIRY_NOT_APPLICABLE": "Nicht zutreffend",
        "BUTTON_CLOSE": "Schliessen",
        "HEADING_SIGNERS": "Unterzeichner",
        "EMPLOYEE": "Mitarbeiter",
        "EMPLOYER": "Arbeitgeber",
        "COLUMN_NAME": "Name",
        "COLUMN_ROLE": "Rolle",
        "COLUMN_STATUS": "Status",
        "COLUMN_DATE": "Datum",
        "LABEL_OPTIONS": "Optionen",
        "OPTION_REMINDER": "Eine Erinnerung senden",
        "OPTION_CANCEL": "Dokument stornieren",
        "OPTION_UPLOAD": "Signiertes Dokument hochladen",
        "CANCEL_ERROR_500": "Wir konnten das Dokument gerade nicht stornieren.",
        "CANCEL_SUCCESS": "Das Dokument wurde storniert.",
        "REMINDER_ERROR_500": "Wir konnten gerade keine Erinnerung senden.",
        "REMINDER_ERROR_400": "Vor Kurzem wurde bereits eine Erinnerung versendet. Bitte warten Sie eine Weile, bevor Sie eine weitere Erinnerung senden.",
        "REMINDER_SUCCESS": "Ich hab's! Wir senden jetzt eine E-Mail-Erinnerung.",
        "DOWNLOAD_ERROR_500": "Wir konnten die Datei gerade nicht herunterladen. Versuchen Sie es erneut oder setzen Sie sich mit uns in Verbindung, falls das Problem weiterhin besteht.",
        "LABEL_DOWNLOAD": "Herunterladen",
        "OPTION_DOWNLOAD_ORIGINAL": "Original herunterladen",
        "OPTION_DOWNLOAD_SIGNED_COPY": "Signiertes Exemplar herunterladen"
      }
    },
    "WORKING_TIME_VIOLATIONS": {
      "TITLE": "Verstöße gegen die Arbeitszeit",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_LAST_TWO_WEEKS": "Letzte zwei Wochen",
      "JUMP_TO_LAST_MONTH": "Letzter Monat",
      "FILTER_SITE": "Standort/Abteilung",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "COLUMN_SITE_DEPARTMENT": "Standort/Abteilung",
      "COLUMN_VIOLATION_HOURS": "Arbeitszeiten",
      "COLUMN_VIOLATION_HOURS_TOOLTIP": "Die Anzahl der Fälle, in denen ein Mitarbeiter 48 Arbeitsstunden überschritt (durchschnittlich über 17 Wochen).",
      "COLUMN_VIOLATION_DAYS_OFF": "Ruhetage",
      "COLUMN_VIOLATION_DAYS_OFF_TOOLTIP": "Die Anzahl der Fälle, in denen ein Mitarbeiter weniger als 2 freie Tage hatte (in einem Zeitraum von 2 Wochen).",
      "COLUMN_VIOLATION_REST": "Ruhe",
      "COLUMN_VIOLATION_REST_TOOLTIP": "Die Anzahl der Fälle, in denen ein Mitarbeiter nach einem Arbeitstag weniger als 11 Stunden Ruhe hatte.",
      "COLUMN_VIOLATION_U18_DAYS_OFF": "U18: Ruhetage",
      "COLUMN_VIOLATION_U18_DAYS_OFF_TOOLTIP": "Die Anzahl der Fälle, in denen ein Mitarbeiter unter 18 Jahren weniger als 2 aufeinanderfolgende freie Tage hatte (in einer bestimmten Woche).",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH": "U18: Lange Schicht",
      "COLUMN_VIOLATION_U18_SHIFT_LENGTH_TOOLTIP": "Die Anzahl der Fälle, in denen ein Arbeitnehmer unter 18 Jahren eine Schicht von mehr als 8 Stunden hatte.",
      "COLUMN_VIOLATION_U18_REST": "U18: Rest",
      "COLUMN_VIOLATION_U18_REST_TOOLTIP": "Die Anzahl der Fälle, in denen ein Arbeitnehmer unter 18 Jahren nach einem Arbeitstag weniger als 11 Stunden Ruhe hatte.",
      "COLUMN_TOTAL": "Insgesamt",
      "ROW_TOTAL": "Insgesamt",
      "ROW_GRAND_TOTAL": "Gesamtsumme",
      "INFO_NO_FILTER": "Wählen Sie oben einen Standort/eine Abteilung aus, um loszulegen.",
      "DATE_ERROR_DIFF": "Sie können nicht mehr als 12 Monate wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "VIOLATION_TYPE_1_DESC": "Der Durchschnitt von 48 Arbeitsstunden über 17 Wochen darf nicht überschritten werden",
      "VIOLATION_TYPE_2_DESC": "In einem Zeitraum von 2 Wochen dürfen nicht weniger als 2 Tage frei sein",
      "VIOLATION_TYPE_3_DESC": "Unter 18 Jahren: Sie müssen 2 aufeinanderfolgende freie Tage pro Woche haben",
      "VIOLATION_TYPE_4_DESC": "Unter 18 Jahren: Schicht länger als 8 Stunden",
      "VIOLATION_TYPE_5_DESC": "Unter 18 Jahren: Weniger als 12 Stunden Pause nach dem Arbeitstag",
      "VIOLATION_TYPE_6_DESC": "Weniger als 11 Stunden Pause nach dem Arbeitstag",
      "VIOLATION_TYPE_1": "Zu viele Arbeitsstunden (im Durchschnitt)",
      "VIOLATION_TYPE_2": "Ungenügende freie Tage",
      "VIOLATION_TYPE_3": "Unter 18 Jahren: Zu wenig freie Tage",
      "VIOLATION_TYPE_4": "Unter 18 Jahren: Zu lange Schicht",
      "VIOLATION_TYPE_5": "Unter 18 Jahren: Ungenügende Ruhezeit zwischen den Schichten",
      "VIOLATION_TYPE_6": "Ungenügende Pause zwischen den Schichten",
      "FILTER_VIOLATION_TYPE": "Art des Verstoßes",
      "FILTER_PER_PAGE": "Zeilen pro Seite",
      "DETAIL_COLUMN_ID": "ID",
      "DETAIL_COLUMN_HR_ID": "IHR KIND",
      "DETAIL_COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "DETAIL_COLUMN_FIRST_NAME": "Vorname",
      "DETAIL_COLUMN_LAST_NAME": "Nachname",
      "DETAIL_COLUMN_SITE": "Standort",
      "DETAIL_COLUMN_DEPARTMENT": "Abteilung",
      "DETAIL_COLUMN_VIOLATION": "Verstoß",
      "DETAIL_COLUMN_DATE_FROM": "Datum von",
      "DETAIL_COLUMN_DATE_TO": "Datum bis",
      "DETAIL_COLUMN_VALUE": "Wert",
      "DETAIL_LABEL_WORKING_HOURS": "Arbeitszeiten",
      "DETAIL_LABEL_WORKING_DAYS": "werktags",
      "DETAIL_LABEL_HOURS_REST": "Stunden Ruhe",
      "DETAIL_INFO_NO_RECORDS": "Du hast keine Arbeitszeitverstöße, Top Job!",
      "DETAIL_INFO_EMPTY_FILTER_RESULT": "Es gibt keine Verstöße, die Ihren Filtern entsprechen."
    },
    "CONTRACTUAL_OBLIGATION_SUMMARY": {
      "TITLE": "Zusammenfassung der vertraglichen Verpflichtungen",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_LAST_TWO_WEEKS": "Letzte zwei Wochen",
      "JUMP_TO_LAST_MONTH": "Letzter Monat",
      "FILTER_SITE": "Standort/Abteilung",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "FILTER_DATA_SOURCE": "Quelle der Daten",
      "FILTER_USER": "Person",
      "COLUMN_GROUP_TOTAL": "Insgesamt (bezahlt)",
      "COLUMN_GROUP_OBLIGATION": "Vertragliche Verpflichtung",
      "COLUMN_GROUP_ANALYSIS_META": "Analysiert über",
      "COLUMN_GROUP_ANALYSIS": "Analyse",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE": "Standort",
      "COLUMN_DEPARTMENT": "Abteilung",
      "COLUMN_DAYS_WORKED": "Tage",
      "COLUMN_PAID_HOURS": "Stunden",
      "COLUMN_AVERAGE_HOURS": "Durchschnittlich",
      "COLUMN_AVERAGE_HOURS_TOOLTIP": "Die durchschnittlichen Arbeitsstunden pro Tag.",
      "COLUMN_OBLIGATION_VALUE": "Wert",
      "COLUMN_OBLIGATION_UNIT": "Einheit",
      "COLUMN_OBLIGATION_PERIOD": "Zeitraum",
      "COLUMN_ANALYSIS_START": "Starten",
      "COLUMN_ANALYSIS_END": "Ende",
      "COLUMN_ANALYSIS_PERIODS": "# Perioden",
      "COLUMN_ANALYSIS_PERIODS_TOOLTIP": "Die Anzahl der Wochen oder Monate, die analysiert wurden.",
      "COLUMN_ANALYSIS_AVERAGE": "Durchschnittlich",
      "COLUMN_ANALYSIS_AVERAGE_TOOLTIP": "Die durchschnittliche Anzahl der geleisteten Arbeitsstunden oder -tage.",
      "COLUMN_ANALYSIS_DIFFERENCE": "Diff.",
      "COLUMN_ANALYSIS_DIFFERENCE_TOOLTIP": "Die Differenz zwischen den Mindeststunden/Tagen, die hätten gearbeitet werden müssen (die vertragliche Verpflichtung multipliziert mit der Anzahl der Perioden) und dem, was tatsächlich gearbeitet wurde (die Gesamtzahl der Stunden oder Tage).",
      "INFO_NO_DATA": "Es gibt keine Datensätze, die Ihren Filtern entsprechen.",
      "INFO_NO_FILTER": "Wählen Sie oben einen Standort/eine Abteilung aus, um loszulegen.",
      "PAY_AMOUNT_TYPE_HOURLY": "Stundensatz",
      "PAY_AMOUNT_TYPE_DAILY": "Tagesrate",
      "PAY_AMOUNT_TYPE_ANNUAL": "Jahresgehalt",
      "DATA_SOURCE_SHIFTS": "Schichten",
      "DATA_SOURCE_ATTENDANCE": "Teilnahme",
      "DATA_SOURCE_MATCHED": "Desinfiziert",
      "CONTRACTUAL_UNIT_HOURS": "Stunden",
      "CONTRACTUAL_UNIT_DAYS": "Tage",
      "CONTRACTUAL_PERIOD_WEEK": "Woche",
      "CONTRACTUAL_PERIOD_MONTH": "Monat",
      "CONTRACTUAL_PERIOD_WEEKLY": "Wöchentlich",
      "CONTRACTUAL_PERIOD_MONTHLY": "Monatlich",
      "DATE_ERROR_DIFF": "Sie können nicht mehr als 14 Wochen wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut."
    },
    "LABOUR_BREAKDOWN": {
      "TITLE": "Zusammenbruch der Erwerbsbevölkerung",
      "FILE_EXPORT_TITLE": "Export von Arbeitsaufschlüsselungsdateien",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_LAST_TWO_WEEKS": "Letzte zwei Wochen",
      "JUMP_TO_LAST_MONTH": "Letzter Monat",
      "FILTER_SITE": "Standort/Abteilung",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "FILTER_DATA_SOURCE": "Quelle der Daten",
      "FILTER_USER": "Person",
      "FILTER_BEHAVIOUR": "Verhalten",
      "BEHAVIOUR_COST_CONTROL": "Kontrolle der Kosten",
      "BEHAVIOUR_PAYROLL": "Gehaltsabrechnung",
      "FILTER_SHIFT_TYPE": "Art der Schicht",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE_APPOINTMENT": "Standort (Termin)",
      "COLUMN_DEPT_APPOINTMENT": "Abteilung (Ernennung)",
      "COLUMN_EVENT_TYPE": "Art der Veranstaltung",
      "COLUMN_SHIFT_TYPE": "Art der Schicht",
      "COLUMN_SITE_ATTRIBUTION": "Seite (Namensnennung)",
      "COLUMN_DEPT_ATTRIBUTION": "Abteilung (Namensnennung)",
      "COLUMN_START_TIME": "Starten",
      "COLUMN_END_TIME": "Fertig",
      "COLUMN_PAID_HOURS": "Bezahlte Stunden",
      "COLUMN_UNPAID_HOURS": "Unbezahlte Stunden",
      "COLUMN_BASE_PAY": "Grundgehalt",
      "COLUMN_ROLLED_UP_HOLIDAY": "Aufgelaufener Urlaub",
      "COLUMN_TAXES": "Steuern",
      "COLUMN_WAGE_UPLIFT": "Lohnerhöhung",
      "COLUMN_TOTAL_COST": "Gesamtkosten",
      "INFO_NO_DATA": "Es gibt keine Datensätze, die Ihren Filtern entsprechen.",
      "INFO_NO_FILTER": "Wählen Sie oben einen Standort/eine Abteilung aus, um loszulegen.",
      "PAY_AMOUNT_TYPE_HOURLY": "Stundensatz",
      "PAY_AMOUNT_TYPE_DAILY": "Tagesrate",
      "PAY_AMOUNT_TYPE_ANNUAL": "Jahresgehalt",
      "EVENT_TYPE_SHIFT": "Schicht",
      "EVENT_TYPE_ABSENCE": "Abwesenheit",
      "EVENT_TYPE_SALARY": "Gehalt",
      "EVENT_TYPE_TAXES": "Steuern",
      "DATA_SOURCE_SHIFTS": "Schichten",
      "DATA_SOURCE_ATTENDANCE": "Teilnahme",
      "DATA_SOURCE_MATCHED": "Desinfiziert",
      "DATE_ERROR_DIFF": "Sie können nicht mehr als 14 Wochen wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "DATE_WARNING_WEEKS": "Sie haben einen Datumsbereich ausgewählt, der nicht den Kalenderwochen entspricht. Einige Arbeitgebersteuern werden anhand wöchentlicher Schwellenwerte berechnet, sodass die hier angegebenen Steuern möglicherweise künstlich unter der Realität liegen. Das Grundgehalt und der angefallene Urlaub bleiben korrekt, aber bitte passen Sie Ihre Daten den Kalenderwochen an, um die korrekten Steuern und Gesamtkosten zu erhalten.",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "ERROR_400": "Ihre Parameter führen dazu, dass zu viele Daten für einen einzelnen Bericht generiert werden. Bitte reduzieren Sie den Umfang Ihrer Anfrage und versuchen Sie es erneut."
    },
    "LABOUR_BREAKDOWN_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Bericht generieren und per E-Mail versenden.",
      "REPORT_GENERATED": "Ihr Bericht wird gerade erstellt und Ihnen nach Abschluss per E-Mail zugeschickt."
    },
    "PAYROLL_EXPORT": {
      "TITLE": "Export von Gehaltsabrechnungen",
      "FILTER_DATE": "Datum",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_LAST_TWO_WEEKS": "Letzte zwei Wochen",
      "JUMP_TO_LAST_MONTH": "Letzter Monat",
      "FILTER_SITE": "Standort/Abteilung",
      "EXPORT": "Exportieren",
      "EXPORT_PAYROLL_SOFTWARE": "Software zur Gehaltsabrechnung",
      "EXPORT_PRINT": "Drucken",
      "FILTER_AGGREGATION": "Segmentierung",
      "FILTER_ABSENCE_TYPES": "Abwesenheitstypen",
      "FILTER_PAY_ELEMENT_TYPES": "Arten von Zahlungselementen",
      "FILTER_USER": "Person",
      "FILTER_COMPANY": "Unternehmen",
      "TOGGLE_SHOW_ROLLED_UP_HOLIDAY": "Zusammengerollten Feiertag anzeigen",
      "COLUMN_ID": "ID",
      "COLUMN_HR_ID": "IHR KIND",
      "COLUMN_PAYROLL_ID": "Gehaltsabrechnungsnummer",
      "COLUMN_FIRST_NAME": "Vorname",
      "COLUMN_LAST_NAME": "Nachname",
      "COLUMN_SITE_ATTRIBUTION": "Seite (Namensnennung)",
      "COLUMN_DEPT_ATTRIBUTION": "Abteilung (Namensnennung)",
      "COLUMN_SITE_APPOINTMENT": "Standort (Termin)",
      "COLUMN_DEPT_APPOINTMENT": "Abteilung (Ernennung)",
      "COLUMN_GROUP": "Gruppe",
      "COLUMN_POSITION": "Berufliche Rolle",
      "COLUMN_DAYS_WORKED": "Arbeitstage",
      "COLUMN_PAY_RATE": "Vergütungssatz",
      "COLUMN_AMOUNT_TYPE": "Art des Betrags",
      "COLUMN_PAY_TYPE": "Art der Bezahlung",
      "COLUMN_PAID_SHIFT_HOURS": "Bezahlte Schichtstunden",
      "COLUMN_UNPAID_SHIFT_HOURS": "Unbezahlte Schichtstunden",
      "COLUMN_BASE_PAY": "Grundgehalt",
      "COLUMN_ROLLED_UP_HOLIDAY": "Zusammengerollter Urlaub",
      "COLUMN_ABSENCE_DAYS": "{{label}} Tage",
      "COLUMN_ABSENCE_HOURS": "{{label}} Stunden",
      "INFO_NO_DATA": "Es gibt keine Datensätze, die Ihren Filtern entsprechen. Hast du dich abgemeldet? Vergessen Sie nicht, dass dieser Bericht nur abgemeldete Daten verwendet.",
      "INFO_NO_FILTER": "Wählen Sie oben einen Standort/eine Abteilung aus, um loszulegen.",
      "PAY_AMOUNT_TYPE_HOURLY": "Stundensatz",
      "PAY_AMOUNT_TYPE_DAILY": "Tagesrate",
      "PAY_AMOUNT_TYPE_ANNUAL": "Jahresgehalt",
      "AGGREGATION_OPTION_ATTRIBUTION": "Der Standort/die Abteilung, der die Stunden zugeordnet wurden",
      "AGGREGATION_OPTION_APPOINTMENT": "Der Standort/die Abteilung, in der sich die Person zu diesem Zeitpunkt befand",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "ABSENCE_TYPE_DELETED_SUFFIX": "Gelöscht",
      "DATE_ERROR_MIN": "Bitte wählen Sie ein Datum nach dem 12. Dezember 2018.",
      "DATE_ERROR_DIFF": "Sie können nicht mehr als 6 Monate wählen. Bitte schränken Sie Ihren Datumsbereich ein.",
      "EXPORT_MODAL": {
        "TITLE": "Export zur Gehaltsabrechnungssoftware",
        "LABEL_EXPORT_TO": "Exportieren nach",
        "BUTTON_DOWNLOAD": "Herunterladen",
        "BUTTON_CLOSE": "Schliessen",
        "SUCCESS": "Ihr Export wurde erfolgreich heruntergeladen.",
        "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut."
      },
      "SIGN_OFF_STATUS_COMPLETE": "Vollständig abgemeldet",
      "SIGN_OFF_STATUS_NONE": "Nicht abgemeldet",
      "SIGN_OFF_STATUS_PARTIAL": "Teilweise abgemeldet",
      "SIGN_OFF_STATUS_DRAWER": {
        "TITLE": "Status der Abmeldung",
        "BACK_TO_DATES": "Zurück zu den Terminen"
      },
      "FILTER_PAYROLL_CALENDAR": "Gehaltsabrechnungskalender",
      "FILTER_PAY_RUN": "Payrun"
    },
    "ROTA_PROGRESS": {
      "TITLE": "Rota Progress",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_NEXT_WEEK": "Nächste Woche",
      "FILTER_SITE": "Standort/Abteilung",
      "LIFECYCLE_DRAFT": "Entwurf",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Zur Genehmigung markiert",
      "LIFECYCLE_PUBLISHED": "veröffentlicht",
      "INCLUDE_NO_DRAFT": "Websites ohne Entwurf anzeigen",
      "NO_DRAFT": "Kein Entwurf",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "COLUMN_SITE": "Standort/Abteilung",
      "COLUMN_LIFECYCLE": "Status",
      "COLUMN_SCHEDULED_HOURS": "Geplante Stunden",
      "COLUMN_SCHEDULED_WAGES": "Geplante Löhne",
      "COLUMN_FORECAST_SALES": "Umsatz prognostizieren",
      "COLUMN_WAGE_PERCENTAGE": "Lohn%",
      "ROW_GRAND_TOTAL": "Gesamtsumme",
      "TOOLTIP_BUDGET": "Haushaltsplan",
      "INFO_NO_DATA": "Es gibt keine Seiten/Abteilungen, die Ihren Filtern entsprechen.",
      "INFO_NO_FILTER": "Wählen Sie oben einen Standort/eine Abteilung aus, um loszulegen.",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut."
    },
    "SIGN_OFF_PROGRESS": {
      "TITLE": "Fortschritt der Abmeldung",
      "JUMP_TO": "Springe zu",
      "JUMP_TO_LAST_WEEK": "Letzte Woche",
      "JUMP_TO_THIS_WEEK": "Diese Woche",
      "JUMP_TO_NEXT_WEEK": "Nächste Woche",
      "FILTER_SITE": "Standort/Abteilung",
      "FILTER_STATUS": "Abmeldestatus",
      "STATUS_NO_ROTA": "Kein Rota",
      "STATUS_INCOMPLETE": "Unvollständig",
      "STATUS_REPORTING_DATE": "Datum der Berichterstattung",
      "STATUS_COMPLETE": "Vollständig",
      "STATUS_TOOLTIP_NO_ROTA": "Diese Woche gibt es keinen veröffentlichten Stundenplan.",
      "STATUS_TOOLTIP_INCOMPLETE": "Die Abmeldung wurde in dieser Woche nicht für jeden Tag abgeschlossen.",
      "STATUS_TOOLTIP_REPORTING_DATE": "Die Abmeldung für die Tage bis einschließlich Ihres Berichtsdatums wurde abgeschlossen.",
      "STATUS_TOOLTIP_COMPLETE": "Die Abmeldung für jeden Tag dieser Woche wurde abgeschlossen.",
      "TOGGLE_COMPARAND": "Komparand",
      "TOGGLE_COMPARAND_FORECAST": "Prognose",
      "TOGGLE_COMPARAND_BUDGET": "Haushaltsplan",
      "EXPORT": "Exportieren",
      "EXPORT_PRINT": "Drucken",
      "COLUMN_WAGES": "Löhne",
      "COLUMN_SALES": "Verkäufe",
      "COLUMN_WAGE_PERCENTAGE": "Lohn%",
      "COLUMN_HOURS": "Stunden",
      "COLUMN_SITE": "Standort/Abteilung",
      "COLUMN_STATUS": "Status",
      "COLUMN_FORECAST": "Prognose",
      "COLUMN_BUDGET": "Haushaltsplan",
      "COLUMN_ACTUAL": "Tatsächlich",
      "ROW_GRAND_TOTAL": "Gesamtsumme",
      "INFO_NO_DATA": "Es gibt keine Seiten/Abteilungen, die Ihren Filtern entsprechen.",
      "INFO_NO_FILTER": "Wählen Sie oben einen Standort/eine Abteilung aus, um loszulegen.",
      "ERROR_500": "Wir konnten Ihren Bericht gerade nicht laden. Bitte versuchen Sie es erneut.",
      "STATUS_MODAL": {
        "REVERSE_CONFIRM": "Möchten Sie diese Abmeldung wirklich rückgängig machen?\n\nDadurch werden die Schichten und Anwesenheitslisten an diesen Terminen freigeschaltet, sodass Änderungen vorgenommen werden können.",
        "REVERSE_SUCCESS": "Die Abmeldung wurde erfolgreich rückgängig gemacht.",
        "REVERSE_ERROR": "Wir konnten die Abzeichnung gerade nicht rückgängig machen. Bitte versuchen Sie es erneut.",
        "TITLE": "Abmeldestatus",
        "COLUMN_DATE": "Datum",
        "COLUMN_STATUS": "Status",
        "COLUMN_OPTIONS": "Optionen",
        "STATUS_NO_SIGN_OFF": "Keine Abmeldung",
        "STATUS_SIGN_OFF": "Abgezeichnet",
        "BUTTON_REVERSE_DAY": "Umgekehrt",
        "BUTTON_REVERSE_WEEK": "Ganze Woche rückgängig machen",
        "BUTTON_CLOSE": "Schliessen"
      }
    },
    "TRONC_STATUS": {
      "TITLE": "Status Tronc",
      "LIFECYCLE_DRAFT": "Entwurf",
      "LIFECYCLE_MARKED_FOR_APPROVAL": "Zur Genehmigung markiert",
      "LIFECYCLE_LOCKED": "Gesperrt",
      "INCLUDE_NO_HEADER": "Websites ohne Entwurf anzeigen",
      "NO_HEADER": "Kein Entwurf",
      "COLUMN_SITE": "Standort/Abteilung",
      "COLUMN_LIFECYCLE": "Status",
      "COLUMN_RECEIVED": "Insgesamt eingegangen",
      "COLUMN_PAID_OUT": "Ausbezahlter Gesamtbetrag",
      "COLUMN_EDITED_WHO": "Herausgegeben von",
      "COLUMN_EDITED_WHEN": "Wann",
      "INFO_NO_DATA": "Es gibt keine Tronc-Webseiten/Abteilungen, die Ihren Filtern entsprechen.",
      "ERROR_500": "Wir konnten Tronc gerade nicht laden. Bitte versuchen Sie es erneut."
    },
    "CURRENTLY_CLOCKED_IN": {
      "TITLE": "Personen, die derzeit angemeldet sind",
      "NAME": "Name",
      "SITE": "Standort/Abteilung",
      "POSITION": "Berufliche Rolle",
      "CLOCK_IN_TIME": "Uhrzeit der Uhr",
      "NOBODY_CLOCKED_IN": "Im Moment ist niemand eingecheckt!",
      "ERROR_500": "Wir konnten den Bericht gerade nicht laden. Bitte versuchen Sie es erneut."
    },
    "LABOUR_BREAKDOWN_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Bericht generieren und per E-Mail versenden.",
      "REPORT_GENERATED": "Ihr Bericht wird erstellt und Ihnen per E-Mail zugesandt, wenn er fertig ist.",
      "FILTER_DATE": "Datum",
      "FILTER_ENTITY": "Standort/Abteilung",
      "FILTER_PAY_TYPES": "Zahlungsarten",
      "FILTER_DATA_SOURCE": "Datenquelle",
      "FILTER_USER": "Person",
      "FILTER_EVENT_TYPES": "Ereignisarten",
      "FILTER_BEHAVIOUR": "Verhalten",
      "FILTER_SHIFT_TYPE": "Schichtart",
    },
    "PAYROLL_EXPORT_ASYNC_FILE_EXPORT": {
      "FILE_EXPORT_BUTTON_EXPORT": "Bericht generieren und per E-Mail versenden.",
      "REPORT_GENERATED": "Ihr Bericht wird erstellt und Ihnen per E-Mail zugesandt, wenn er fertig ist.",
      "FILTER_PAYROLL_CALENDAR": "Gehaltsabrechnungskalender",
      "FILTER_PAY_RUN": "Gehaltslauf",
      "FILTER_DATE": "Datum",
      "FILTER_ENTITY": "Standort/Abteilung",
      "FILTER_AGGREGATION_OPTIONS": "Segmentierung",
      "FILTER_USER": "Person",
      "FILTER_ABSENCE_TYPES": "Abwesenheitsarten",
      "FILTER_PAY_ELEMENT_TYPES": "Gehaltselementarten",
      "FILTER_PAY_AMOUNT_TYPE": "Mengentyp",
      "FILTER_ROLLED_UP_HOLIDAY": "Zusammengerechneter Urlaub",
      "FILTER_COMPANY": "Unternehmen",
      },
  },
  "ROTA_MANAGEMENT": {
    "DASHBOARD": {
      "INDEX": {
        "TITLE": "Armaturenbrett",
        "CHECKLIST": "Rota-Checkliste",
        "LABOUR_SPEND": "Arbeitsausgaben"
      },
      "LABOUR_SPEND_TAB": {
        "TITLE": "Überblick über die Arbeitswelt",
        "SHOW_ACTUAL_TIP": "Für die Tage dieser Woche, die noch nicht verstrichen sind, rechnest du den prognostizierten Umsatz und die geplanten Lohne/Stunden in die tatsächlichen Gesamtwerte um.",
        "SHOW_ACTUAL_TEXT": "Aus der Prognose wird die tatsächliche",
        "FORECAST": "Prognose",
        "ACTUAL": "Tatsächlich",
        "BUDGET": "Haushaltsplan",
        "SALES": "Verkäufe",
        "WAGES": "Löhne",
        "HOURS": "Stunden",
        "WAGE_PERCENT": "Lohn%",
        "LABOUR_COMP": "Vergleich von Arbeitskräften",
        "ACTUAL_PROJECTED": "Aktuell (prognostiziert)",
        "TODAYS_VS_SCHEDULED": "Heutige Besucherzahlen im Vergleich zu geplanten"
      },
      "WTD_VIOLATIONS_MODAL": {
        "TITLE": "Verstöße gegen die Arbeitszeit",
        "BODY": "In diesen Fällen haben wir festgestellt, dass Ihre Zeitpläne gegen die Arbeitszeitvorschriften verstoßen.",
        "TO": "zu ",
        "ALL": "Alle Verstöße",
        "TOO_MANY": "Zu viele Arbeitsstunden (im Durchschnitt)",
        "INSUF_DAYS": "Ungenügende freie Tage",
        "INSUF_REST": "Ungenügende Pause zwischen den Schichten",
        "UNDER_18_DAYS": "Unter 18 Jahren: Zu wenig freie Tage",
        "UNDER_18_LONG": "Unter 18 Jahren: Zu lange Schicht",
        "UNDER_18_REST": "Unter 18 Jahren: Ungenügende Ruhezeit zwischen den Schichten",
        "STAFF_MEMBER": "Mitarbeiter",
        "VIOLATION": "Verstoß",
        "VALUE": "Wert",
        "NO_VIOLATIONS": "Du hast keine Arbeitszeitverstöße, Top Job!",
        "NO_FILTER_VIOLATIONS": "Es gibt keine Verstöße, die Ihren Filtern entsprechen.",
        "AVE_48_OVER_17": "Der Durchschnitt von 48 Arbeitsstunden über 17 Wochen darf nicht überschritten werden",
        "LESS_2_DAYS_2_WEEKS": "In einem Zeitraum von 2 Wochen dürfen nicht weniger als 2 Tage frei sein",
        "UNDER_18_2_PER_WEEK": "Unter 18 Jahren: Sie müssen 2 aufeinanderfolgende freie Tage pro Woche haben",
        "UNDER_18_LONGER_8": "Unter 18 Jahren: Schicht länger als 8 Stunden",
        "UNDER_18_LESS_12_REST": "Unter 18 Jahren: Weniger als 12 Stunden Pause nach dem Arbeitstag",
        "LESS_11_WEEK": "Weniger als 11 Stunden Pause nach dem Arbeitstag",
        "WORKING_HOURS": " Arbeitszeiten",
        "WORKING_DAYS": " werktags",
        "HOURS_REST": " Stunden Ruhe",
        "RECALC": "Erneut berechnen",
        "LOADING": "Wird geladen...",
        "CLOSE": "Schliessen"
      }
    },
    "LOCATIONS": {
      "ADD_LOCATION_MODAL": {
        "NEW_SHIFT_LOCATION": "Neue Anwesenheitszone",
        "TYPE": "Typ",
        "NEW_LOCATION": "Neue Zone",
        "CHANGE_EXISTING_LOCATION": "Bestehende Zone ändern",
        "EXISTING_LOCATION": "Bestehende Zone",
        "NAME": "Name",
        "DETAILS": "Einzelheiten",
        "HELP": "Rotaready merkt sich die genaue Position der Markierung auf der Karte, daher dienen diese Details nur als Referenz. Fühlen Sie sich frei, einige Notizen oder eine vollständige Adresse beizufügen.",
        "SAVING": "Wird gespeichert...",
        "SAVE": "Speichern",
        "CANCEL": "Stornieren",
        "ERROR_ENTER_NAME": "Bitte geben Sie einen Namen für diese Zone ein.",
        "ERROR_UPDATE": "Diese Zone kann derzeit nicht aktualisiert werden. Bitte versuchen Sie es erneut.",
        "ERROR_ADD": "Diese Zone kann derzeit nicht hinzugefügt werden. Bitte versuchen Sie es erneut."
      },
      "EDIT_LOCATION_MODAL": {
        "EDIT_LOCATION": "Zone bearbeiten",
        "HIERARCHY": "Hierarchie",
        "MASTER_LOCATION": "Masterzone",
        "SUB_LOCATION": "Unterzone",
        "HELP_HIERARCHY": "Mit Rotaready können Sie eine Hierarchie von Zonen erstellen. Eine Unterzone kann unter jede andere Zone gelegt werden.",
        "PARENT_LOCATION": "Übergeordnete Zone",
        "NAME": "Name",
        "DETAILS": "Einzelheiten",
        "HELP_DETAILS": "Rotaready merkt sich die genaue Position der Markierung auf der Karte, daher dienen diese Details nur als Referenz. Fühlen Sie sich frei, einige Notizen oder eine vollständige Adresse beizufügen.",
        "ATTENDANCE": "Teilnahme",
        "ALLOW_CLOCKING": "Erlauben Sie den Mitarbeitern, hier ein- und auszuchecken",
        "STAFF_WORKING": "Nur wenn das Personal in einer Schicht arbeitet, die mit dieser Zone gekennzeichnet ist",
        "HELP_CLOCKING": "Diese Einstellungen wirken sich darauf aus, ob Mitarbeiter mit der mobilen Rotaready-App ein- und auschecken können.",
        "ATTENDANCE_SENSITIVITY": "Sensibilität bei der Teilnahme",
        "METRES": "Meter",
        "HELP_CLOSE_LOCATION": "Dies definiert, wie nah sich jemand an der Zone befinden muss, bevor er mit der mobilen Rotaready-App ein- oder aussteigen kann. Eine Entfernung von weniger als 50 Metern wird nicht empfohlen, da Personen mit schwachem Signal nicht einsteigen können.",
        "MIN_OCCUPANCY": "Mindestbelegung",
        "HELP_MIN_OCCUPANCY": "Immer wenn jemand in der Schicht ist, warnt Rotaready Sie, wenn die Gesamtzahl der Personen, die ebenfalls in der Schicht sind, unter diese Zahl fällt.",
        "MAX_OCCUPANCY": "Max. Belegung",
        "HELP_MAX_OCCUPANCY": "Falls angegeben, können Sie bei Rotaready nicht mehr Personal (gleichzeitig) als die maximale Belegung angeben.",
        "TIP_OPENING": "Geben Sie die Öffnungszeiten an, damit Rotaready Sie daran hindert, Schichten in dieser Zone zu planen, wenn diese geschlossen ist.",
        "TIP_SUB_LOC": "Da es sich um eine Unterzone handelt, gilt auch diese Unterzone als geschlossen, wenn ihre übergeordnete Zone zu irgendeinem Zeitpunkt geschlossen ist.",
        "REGULAR_OPENING_TIMES": "Reguläre Öffnungszeiten",
        "OPEN_247": "Diese Zone ist rund um die Uhr geöffnet.",
        "TO": "zu",
        "SPECIAL_PERIODS": "Besondere Zeiträume",
        "NO_SPECIAL_PERIODS": "Es gibt keine Sonderperioden.",
        "OPEN": "Offen",
        "CLOSED": "geschlossen",
        "ADD_SPECIAL_PERIOD": "Sonderzeitraum hinzufügen",
        "REASON": "Grund",
        "REASON_PLACEHOLDER": "z. B. Renovierung im Sommer",
        "FROM": "Von",
        "TO_CAPS": "Zu",
        "ADD": "Hinzufügen",
        "DELETE": "Löschen",
        "SAVING": "Wird gespeichert...",
        "SAVE": "Speichern",
        "CANCEL": "Stornieren",
        "ALERT_NAME": "Bitte stellen Sie sicher, dass Sie einen gültigen Namen für die Anwesenheitszone angegeben haben.",
        "ALERT_PARENT": "Damit dies eine Unterzone ist, müssen Sie eine übergeordnete Zone auswählen.",
        "ALERT_VALID_PARENT": "Bitte wählen Sie eine gültige übergeordnete Zone aus.",
        "ALERT_PARENT_LOCATION": "Diese Anwesenheitszone und die von Ihnen gewählte übergeordnete Zone müssen beide zur gleichen Website gehören (oder beide organisationsweit sein).",
        "ALERT_MINIMUM": "Sie können keine Mindestbelegung haben, die höher ist als die Maximalbelegung.",
        "ERROR_UPDATE": "Wir konnten die Zone gerade nicht aktualisieren. Bitte versuchen Sie es erneut.",
        "ALERT_SUB_CONFIRM": "Diese Zone hat immer noch Unterzonen. Sie werden ebenfalls gelöscht.\n\nSind Sie sicher, dass Sie fortfahren möchten?",
        "ALERT_CONFIRM": "Möchten Sie diese Zone wirklich löschen?",
        "ERROR_DELETE": "Wir konnten die Zone gerade nicht löschen. Bitte versuche es erneut!"
      },
      "INDEX": {
        "SHIFT_LOCATIONS": "Anwesenheitszonen",
        "TIP_FIND": "Um eine neue Zone hinzuzufügen oder eine bestehende Zone zu ändern, müssen Sie sie zuerst mit der Karte finden. Suchen Sie nach einer beliebigen Adresse oder Postleitzahl und ziehen Sie dann die grüne Markierung, um sie neu zu positionieren.",
        "PLACEHOLDER_SEARCH": "Suchen Sie, um eine neue Zone hinzuzufügen",
        "HELP_NO_LOCATIONS": "Sie haben keine vorhandenen Zonen. Durchsuche die Karte, um eine hinzuzufügen.",
        "HELP_NO_RESULTS": "Keine Anwesenheitszonen entsprechen Ihren Suchbegriffen.",
        "TOOLTIP_SHOW": "Auf der Karte zeigen",
        "TOOLTIP_CLOCKING_ALWAYS": "Mitarbeiter können sich hier jederzeit mit der mobilen Rotaready-App an- und abmelden",
        "TOOLTIP_CLOCKING_WORKING": "Mitarbeiter können sich hier mit der mobilen Rotaready-App an- und abmelden, nur wenn sie hier eine Schicht arbeiten",
        "ERROR_LOAD": "Wir konnten Ihre Anwesenheitszonen nicht laden. Bitte versuchen Sie es erneut.",
        "NO_ADDRESS": "Keine Angaben/Adresse",
        "ERROR_LOOKUP": "Wir konnten die Position dieser Markierung nicht nachschlagen.",
        "UNKNOWN_ADDRESS": "Unbekannte Adresse",
        "ADD_AS_NEW": "Als neue Zone hinzufügen",
        "DRAG_TO_CHANGE": "Zieh mich, um den Standort zu ändern",
        "FILTER_LOCATIONS": "Zonen filtern..."
      }
    },
    "SHIFT_TYPES": {
      "EDIT_SHIFT_TYPES_MODAL": {
        "TITLE_NEW": "Neuer Schichttyp",
        "TITLE_EDIT": "Schichttyp bearbeiten",
        "SITE_LABEL": "Standort",
        "HIERARCHY_LABEL": "Hierarchie",
        "MASTER_TYPE_LABEL": "Typ Master-Shift",
        "SUB_TYPE_LABEL": "Subshift-Art",
        "SUB_TYPE_HELP": "Mit Rotaready können Sie eine Hierarchie von Schichttypen erstellen. Ein Subshift-Typ kann unter jeden anderen Schichttyp gestellt werden.",
        "PARENT_TYPE_LABEL": "Art der Elternschicht",
        "NAME_LABEL": "Name",
        "COLOUR_LABEL": "Farbe",
        "COST_STREAM_MAPPINGS_LABEL": "Stream-Zuordnungen",
        "COST_STREAM_TABLE": {
          "COST_STREAM_HEADING": "Streamen",
          "ATTRIBUTED_HEADING": "zugeschrieben",
          "EMPTY_LABEL": "Dies ist keinem Stream zugeordnet.",
          "ADD_BUTTON": "Neues hinzufügen"
        },
        "DELETE_BUTTON": "Löschen",
        "SAVE_BUTTON": "Speichern",
        "CANCEL_BUTTON": "Stornieren",
        "VALIDATION": {
          "NAME_REQUIRED": "Bitte stellen Sie sicher, dass Sie einen gültigen Namen für den Schichttyp angegeben haben.",
          "PARENT_REQUIRED": "Damit dies ein Unterschichttyp ist, müssen Sie einen übergeordneten Schichttyp auswählen.",
          "PARENT_VALID": "Bitte wählen Sie einen gültigen übergeordneten Schichttyp aus.",
          "PARENT_SAME_SITE": "Diese Schichtart und die von dir gewählte übergeordnete Schichtart müssen beide zu derselben Website gehören (oder beide organisationsweit gelten).",
          "ATTRIBUTION_OVERAGE": "Wir haben den Prozentsatz, der jedem Stream zugeordnet ist, zusammengefasst und die Gesamtzahl liegt bei über 100%. Sie können einem oder mehreren Streams nur maximal 100% zuordnen.",
          "ATTRIBUTION_VALID": "Du hast für einen oder mehrere Streams einen ungültigen „Prozentsatz angegeben“ eingegeben. Bei einem Wert von '50' werden beispielsweise 50% der Lohnkosten einer Person, die in dieser Schicht arbeitet, dem entsprechenden Stream zugeordnet.",
          "ATTRIBUTION_NONE": "Sie müssen den Shift-Typ mindestens einem Stream zuordnen.",
          "DUPLICATE_COST_STREAM_MAPPING": "Ein Mapping für diesen Stream existiert bereits! Sie können demselben Stream nicht zweimal einen Shift-Typ zuordnen."
        },
        "ERRORS": {
          "ADD_ERROR": "Wir konnten deinen Schichttyp gerade nicht hinzufügen. Es ist wahrscheinlich, dass ein Schichttyp mit dem gleichen Namen bereits existiert! Versuche, einen anderen Namen zu wählen.",
          "UPDATE_ERROR": "Wir konnten den Schichttyp gerade nicht aktualisieren. Es ist wahrscheinlich, dass ein Schichttyp mit dem gleichen Namen bereits existiert! Versuche, einen anderen Namen zu wählen.",
          "DELETE_TYPE": "Wir konnten den Schichttyp gerade nicht löschen. Bitte versuche es erneut!"
        },
        "CONFIRM": {
          "DELETE_SUB_TYPES": "Dieser Schichttyp hat immer noch Unterschichttypen. Sie werden ebenfalls gelöscht. Sind Sie sicher, dass Sie fortfahren möchten?",
          "DELETE_TYPE": "Bist du sicher, dass du diese Schichtart löschen möchtest?"
        }
      },
      "INDEX": {
        "TITLE": "Schichttypen",
        "SUBTITLE": "Stellen Sie sich Schichttypen als Kategorien für die verschiedenen Arten von Arbeit vor, die Mitarbeiter in Ihrer Organisation verrichten:",
        "SUB_TYPE_HELP": "Du kannst einen „Unterschichttyp“ hinzufügen, indem du auf das Plus-Symbol neben einem beliebigen Schichttyp klickst. Dies ist nützlich, wenn Sie eine Schichtart in spezifischere Aufgaben oder Rollen unterteilen müssen.",
        "TYPES_EMPTY": "Es gibt keine anzuzeigenden Schichttypen.",
        "ONLY_ENTITY": "nur",
        "WHOLE_ORGANISATION": "Unternehmensweit",
        "SHOW_MORE": "Zeig mehr...",
        "ERRORS": {
          "LOAD_ERROR": "Ups, wir konnten die Schichttypen gerade nicht laden. Bitte versuche es erneut!"
        },
        "TOOLTIPS": {
          "ADD_SUB_SHIFT_TYPE": "Subshift-Typ hinzufügen"
        },
        "ADD_SHIFT_TYPE": "Schichttyp hinzufügen"
      }
    },
    "TEMPLATES": {
      "EDIT_CONSTRAINTS_MODAL": {
        "ADD_TITLE": "Neues {{currentLevelName}} hinzufügen",
        "EDIT_TITLE": "{{currentLevelName}} -Einschränkungen bearbeiten",
        "NAME_LABEL": "Name",
        "LOADING": {
          "SAVE": "Wird gespeichert..."
        },
        "SAVE_AND_CLOSE_BUTTON": "Speichern & schließen",
        "CANCEL_BUTTON": "Stornieren",
        "OVERRIDE_CONSTRAINT_BUTTON": "Beschränkung {{levelName}} überschreiben",
        "DELETE_OVERRIDE_BUTTON": "Überschreibung löschen",
        "TAB_HEADINGS": {
          "POPULAR": "Beliebt",
          "STAFF": "Mitarbeiter",
          "REST": "Ruhe",
          "OTHERS": "Andere"
        },
        "STAFF_SELECTION": {
          "TITLE": "Auswahl des Personals",
          "FLEXIBLE_STAFFING_LABEL": "Flexible Personalbesetzung",
          "FAIRLY_HINT": "Wählen Sie fair aus allen Mitarbeitern",
          "FAIRLY_ANY_TAGS_HINT": "Wählen Sie fair aus denen aus, die <strong>zu einem</strong> der folgenden Stichwörter passen",
          "FAIRLY_ALL_TAGS_HINT": "Wählen Sie fair aus denen aus, die <strong>allen</strong> folgenden Stichwörtern entsprechen",
          "POOLING_HINT": "Behandeln Sie die folgenden Tags als Mitarbeiterpools und schöpfen Sie sie <strong>der Reihe nach aus</strong>",
          "FIXED_STAFFING_LABEL": "Feste Personalbesetzung",
          "SPECIFIC_STAFF_MEMBER_HINT": "Weisen Sie die Schicht (n) einem bestimmten Mitarbeiter zu",
          "ASSIGNEE_LABEL": "Abtretungsempfänger",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tippen Sie, um Stichwörter hinzuzufügen"
          }
        },
        "PREFERRED_STAFF_SELECTION": {
          "TITLE": "Bevorzugte Personalauswahl",
          "HINT": "Wenn mehrere Mitarbeiter für eine bestimmte Schicht in Frage kommen, wird Rotaready versuchen, die Auswahl anhand der ausgewählten Kriterien zu beeinflussen:",
          "NO_PREFERENCE": "Bevorzugen Sie kein Personal gegenüber anderen, außer aus Gründen der Fairness",
          "PREFER_ANY_TAG": "Bevorzugen Sie diejenigen, die <strong>einem</strong> der folgenden Tags entsprechen",
          "PREFER_ALL_TAG": "Bevorzugen Sie diejenigen, die <strong>allen</strong> folgenden Tags entsprechen",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tippen Sie, um Stichwörter hinzuzufügen"
          }
        },
        "STAFF_SELECTION_GROUPS": {
          "TITLE": "Personalauswahl (Gruppen)",
          "HINT": "Auf diese Weise können Sie die Auswahl der Mitarbeiter auf diejenigen beschränken, die Mitglied einer der folgenden Gruppen sind. Die Ressourcen für die Gruppen werden in der Reihenfolge bereitgestellt, in der sie angegeben sind:",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tippen Sie, um Stichwörter hinzuzufügen"
          }
        },
        "STAFF_TYPECASTING": {
          "TITLE": "Typografie durch Mitarbeiter",
          "HINT": "<em>Wenn wir jemandem eine <em>typisierte</em> Schicht zuweisen, können ihm an diesem Tag nur weitere Schichten zugewiesen werden, die a) ebenfalls mit mindestens einem dieser Tags <em>typisiert</em> sind oder b) überhaupt nicht typisiert sind:</em>",
          "PLACEHOLDERS": {
            "TYPE_TAGS": "Tippen Sie, um Stichwörter hinzuzufügen"
          }
        },
        "OUTSOURCING": {
          "TITLE": "Outsourcing",
          "HINT": "Wenn kein internes Personal verfügbar ist, erlauben Sie die Auslagerung an die folgenden Organisationen in der angegebenen Reihenfolge:",
          "PLACEHOLDERS": {
            "TYPE_OUTSOURCE_ORGANISATIONS": "Geben Sie ein, um Outsourcing-Organisationen hinzuzufügen"
          }
        },
        "SELECTION_CONSISTENCY": {
          "TITLE": "Konsistenz der Auswahl",
          "HINT": "Bei Schichtgruppen, die sich über mehrere Tage in einer bestimmten Woche erstrecken, sollte jeder, der der Schicht am ersten Tag zugewiesen wird, wie folgt vorgehen:",
          "NO_EFFECT": "haben keinen Einfluss darauf, ob sie für andere Tagesschichten ausgewählt werden",
          "ALLOCATE": "nach Möglichkeit allen anderen Tagesschichten des Schichtsatzes zugewiesen werden"
        },
        "REST_PERIOD": {
          "TITLE": "Ruhezeit",
          "HINT": "Stellen Sie nach einer Schicht sicher, dass ein Mitarbeiter mindestens für die folgenden Zeiten ausgeruht ist (und somit keine weitere Schicht erhält):"
        },
        "LOCATION_TOLERANCE": {
          "TITLE": "Lagetoleranz",
          "HINT": "Oft ist es wichtig, dass zwei aufeinanderfolgende Schichten mit demselben angegebenen Standort demselben Mitarbeiter zugewiesen werden. Geben Sie die maximale Anzahl von Minuten zwischen zwei solchen Schichten (Ende der ersten bis Beginn der zweiten) an, damit wir sie in dieser Hinsicht als aufeinanderfolgend betrachten können:",
          "MINUTES": "Minuten",
          "PLACEHOLDERS": {
            "MINUTES": "Minuten zwischen dem Ende einer Schicht und dem Beginn einer anderen"
          }
        },
        "DAILY_WINDOW_DEFINITION": {
          "TITLE": "Definition des täglichen Fensters",
          "HINT": "In einigen Organisationen arbeiten ihre Mitarbeiter an einem bestimmten Tag in mehreren „Schichten“, und für jede Person muss diese tägliche Schichtbelastung innerhalb eines bestimmten Zeitfensters liegen. Diese Zeitfenster können variieren, und Sie können hier alle möglichen Variationen definieren:",
          "LIMITED_TIMES": "Wir haben eine begrenzte, feste Start-/Endzeit für unsere Fenster",
          "REGULAR_INTERVALS": "Unsere Fenster fallen in regelmäßigen Abständen nach einer bestimmten Startzeit"
        },
        "TIMES": {
          "WINDOW_TIMES_TITLE": "Fensterzeiten",
          "SPLIT_SHIFT_WINDOW_TIMES": "Fensterzeiten in geteilter Schicht",
          "WINDOW_START_TIME": "Startzeit des frühesten Fensters",
          "WINDOW_INTERVAL": "Intervall zwischen gültigen Fenstern",
          "WINDOW_FINISH_TIME": "Endzeit des spätestmöglichen Fensters",
          "PLACEHOLDERS": {
            "COMMA_SEPERATED_TIMES": "Kommagetrennte Zeitliste, z. B. 9:30-17:30, 13:00-20:00"
          }
        },
        "SHIFT_PAY_RATE": {
          "TITLE": "Lohnsatz ändern",
          "HINT": "Nicht angestellte Mitarbeiter, die aus diesem {{currentLevelName}} generierten Schichten arbeiten, verdienen zu ihrem üblichen Stundensatz. Sie können dies bei Bedarf unten überschreiben:",
          "OPTIONS": {
            "NORMAL": "Normal: Mitarbeiter verdienen zu ihrem üblichen Stundensatz",
            "ZERO": "Null: Mitarbeiter werden für diese Schicht nicht bezahlt",
            "MULTIPLE": "Mehrfach: Mitarbeiter verdienen ein Vielfaches ihres üblichen Stundensatzes",
            "FIXED": "Behoben: Setzt ihren üblichen Stundensatz durch einen festen Tarif außer Kraft"
          },
          "MULTIPLIER": "Ratenmultiplikator",
          "HOURLY_RATE_OVERRIDE": "Überschreibung des Stundensatzes",
          "PLACEHOLDERS": {
            "MULTIPLIER": "Stundensatzmultiplikator",
            "HOURLY_RATE_OVERRIDE": "Stundensatz in Landeswährung, d. h. 8,50"
          }
        },
        "SHIFT_TYPE": {
          "TITLE": "Art der Schicht",
          "HINT": "Dies bezeichnet die „Art“ der Schichten, die aus diesem {{currentLevelName}} generiert werden. Ihre Mitarbeiter sehen darin den Titel der Schicht. Wenn Sie die Kostenkontrollfunktionen von Rotaready verwenden, kann jede Schichtart einer oder mehreren Kostenströmen zugeordnet werden."
        },
        "SITE_DEPARTMENT": {
          "TITLE": "Standort/Abteilung",
          "HINT": "Attributverschiebungen, die von diesem {{currentLevelName}} generiert wurden, zu einer anderen Standort/Abteilung. Dies ist nützlich, um Deckungsschichten zu generieren.",
          "ATTRIBUTE_TO_SITE": "Ordnen Sie die Lohnkosten und Stunden dem ausgewählten Standort zu"
        }
      },
      "EDIT_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Schichtsatz hinzufügen",
        "ADD_SUBTITLE": "Geben Sie Details zu diesem Schichtsatz an",
        "EDIT_TITLE": "Schichtsatz bearbeiten",
        "EDIT_SUBTITLE": "Details zu diesem Schichtsatz ändern",
        "TIP": "Der automatische Planer von Rotaready generiert Schichten aus diesem Schichtplan und verteilt das Personal entsprechend, auf faire und wirtschaftliche Weise.",
        "TIME_LABEL": "Start-/Endzeit",
        "TO": "zu",
        "OVERFLOW_WARNING": "Diese Schicht wird in den nächsten Tag überlaufen.",
        "LOCATION_LABEL": "Anwesenheitszone",
        "MINIMUM_LABEL": "Wenigstens",
        "MINIMUM_EXPLANATION": "Ich möchte eine Mindestanzahl an Mitarbeitern pro Tag, aber wenn möglich mehr",
        "STAFF_NUMBER_LABEL": "Exakt",
        "STAFF_NUMBER_EXPLANATION": "Ich möchte eine genaue Anzahl von Mitarbeitern pro Tag",
        "MAXIMUM_LABEL": "Bis zu",
        "MAXIMUM_EXPLANATION": "Ich möchte so viele Mitarbeiter wie möglich pro Tag, bis zu einem Höchstlimit",
        "SHORT_DAYS": {
          "MONDAY": "Mon",
          "TUESDAY": "Tue",
          "WEDNESDAY": "Heiraten",
          "THURSDAY": "Do",
          "FRIDAY": "Freitag",
          "SATURDAY": "Sat.",
          "SUNDAY": "Sonne"
        },
        "SAVE_BUTTON": "Schichtsatz speichern",
        "CANCEL_BUTTON": "Stornieren",
        "LOADING": {
          "SAVE": "Wird gespeichert..."
        },
        "ERRORS": {
          "UNABLE_TO_ADD": "Dieser Schichtsatz kann derzeit nicht hinzugefügt werden. Bitte versuchen Sie es erneut."
        }
      },
      "EDIT_TEMPLATE": {
        "LOADING": {
          "SAVE": "Wird gespeichert..."
        },
        "EDIT_CONSTRAINTS": "Einschränkungen bearbeiten",
        "CONSTRAINTS_LABEL": "Einschränkungen bei Vorlagen",
        "CONSTRAINTS_TEXT": "Legen Sie Einschränkungen fest, die für die gesamte Vorlage gelten.",
        "PLACEHOLDERS": {
          "SEARCH_FILTER": "Filtern..."
        },
        "ADD_GROUP_BUTTON": "Gruppe hinzufügen",
        "TAB_HEADINGS": {
          "FLEXIBLE_STAFFING": "Flexible Personalbesetzung",
          "FIXED_STAFFING": "Feste Personalbesetzung"
        },
        "TOOLTIPS": {
          "ACTIVE_CONSTRAINTS": "Aktive Einschränkungen",
          "DISABLE": "Deaktiviert",
          "ENABLE": "Aktiviere"
        },
        "GROUP_OPTIONS_BUTTON": {
          "LABEL": "Optionen für Gruppen",
          "ADD_TASK": "Aufgabe hinzufügen",
          "ADD_SHIFT_SET": "Schichtsatz hinzufügen",
          "EDIT_CONSTRAINTS": "Einschränkungen bearbeiten",
          "DELETE_GROUP": "Gruppe löschen"
        },
        "HOURS": "Stunden",
        "SHORT_DAYS": {
          "MONDAY": "Mon",
          "TUESDAY": "Tue",
          "WEDNESDAY": "Heiraten",
          "THURSDAY": "Do",
          "FRIDAY": "Freitag",
          "SATURDAY": "Sat.",
          "SUNDAY": "Sonne"
        },
        "CHAR_DAYS": {
          "MONDAY": "M",
          "TUESDAY": "T",
          "WEDNESDAY": "W",
          "THURSDAY": "T",
          "FRIDAY": "F",
          "SATURDAY": "S",
          "SUNDAY": "S"
        },
        "NO_SHIFT_SETS": "In dieser Gruppe gibt es keine Schichtgruppen. Fügen Sie eine hinzu, indem Sie auf die Schaltfläche „Gruppenoptionen“ klicken.",
        "OPTIONS_BUTTON": {
          "LABEL": "Optionen",
          "ADD_SHIFT_SET": "Schichtsatz hinzufügen",
          "EDIT_CONSTRAINTS": "Einschränkungen bearbeiten",
          "DELETE_TASK": "Aufgabe löschen"
        },
        "NO_FLEXIBLE_STAFF_GROUPS_THIS_WEEK": "Sie haben keine flexiblen Personalgruppen. Warum fügen Sie nicht eine hinzu, indem Sie oben auf „Gruppe hinzufügen“ klicken?",
        "NO_FLEXIBLE_STAFF_GROUPS": "Keine flexible Personalgruppe passt zu dem, was Sie suchen.",
        "GROUP_SHIFT_SETS_TABLE": {
          "HEADINGS": {
            "SHIFT_TYPE": "Art der Schicht",
            "TIME": "Zeit",
            "DAYS_OF_WEEK": "Wochentage"
          }
        },
        "NO_FIXED_STAFF_GROUPS_THIS_WEEK": "Sie haben keine festen Personalgruppen. Warum fügen Sie nicht eine hinzu, indem Sie oben auf „Gruppe hinzufügen“ klicken?",
        "NO_FIXED_STAFF_GROUPS": "Keine festen Personalgruppen entsprechen dem, was Sie suchen.",
        "EDIT_BUTTON": "Bearbeiten",
        "TOGGLE_DROPDOWN_BUTTON": "Dropdown umschalten",
        "DELETE_BUTTON": "Löschen",
        "SAVE_BUTTON": "Speichern",
        "ERRORS": {
          "COULDNT_FIND_TEMPLATE": "Wir konnten die von Ihnen gesuchte Vorlage nicht finden. Bitte versuchen Sie es erneut.",
          "OOPS": "Ups, irgendwas ist schief gelaufen. Möglicherweise müssen Sie es erneut versuchen!",
          "TEMPLATE_STATE_CHANGE": "Wir konnten einen Teil Ihrer Vorlage gerade nicht aktivieren/deaktivieren. Bitte versuchen Sie es erneut."
        },
        "LEVEL_NAMES": {
          "GLOBAL": "weltweite",
          "TEMPLATE": "Muster",
          "WEEK": "Woche",
          "GROUP": "Gruppe",
          "TASK": "Aufgabe",
          "SHIFT_SET": "Schichtsatz"
        },
        "CONFIRM": {
          "DELETE": "Bist du sicher, dass du das löschen möchtest?",
          "DELETE_SHIFT_SET": "Möchten Sie diesen Schichtsatz wirklich löschen?"
        }
      },
      "FIXED_STAFFING_SHIFT_SET_MODAL": {
        "ADD_TITLE": "Schichtsatz hinzufügen",
        "ADD_SUBTITLE": "Geben Sie Details zu diesem Schichtsatz an",
        "EDIT_TITLE": "Schichtsatz bearbeiten",
        "EDIT_SUBTITLE": "Details zu diesem Schichtsatz ändern",
        "START_END_LABEL": "Start-/Endzeit",
        "SHIFT_OVERFLOW_WARNING": "Diese Schicht wird in den nächsten Tag überlaufen.",
        "SHIFT_TYPE_LABEL": "Art der Schicht",
        "LOCATION_LABEL": "Anwesenheitszone",
        "SHORT_DAYS": {
          "MONDAY": "Mon",
          "TUESDAY": "Tue",
          "WEDNESDAY": "Heiraten",
          "THURSDAY": "Do",
          "FRIDAY": "Freitag",
          "SATURDAY": "Sat.",
          "SUNDAY": "Sonne"
        },
        "SAVE_BUTTON": "Schichtsatz speichern",
        "CANCEL_BUTTON": "Stornieren",
        "LOADING": {
          "SAVE": "Wird gespeichert..."
        },
        "UNKNOWN_SHIFT_TYPE": "Unbekannter Schichttyp",
        "ERRORS": {
          "UNABLE_ADD": "Hoppla, wir können diesen Schichtsatz derzeit nicht hinzufügen. Bitte versuchen Sie es erneut.",
          "UNABLE_UPDATE": "Hoppla, wir können diesen Schichtsatz derzeit nicht aktualisieren. Bitte versuchen Sie es erneut."
        }
      },
      "INDEX": {
        "NEW_BUTTON": "Neues hinzufügen",
        "ALERT_LINK_WARNING": "Vorlagen wurden in das Einstellungsmenü verschoben. Der bestehende Link unter dem Rotas-Menü wird bald verschwinden 👀",
        "TITLE": "Rota-Vorlagen",
        "SEARCH_FILTER": "Suche...",
        "TEMPLATE_TABLE": {
          "NAME_HEADING": "Name",
          "CREATED_BY_HEADING": "Erstellt von",
          "LAST_EDITED_HEADING": "Zuletzt bearbeitet",
          "OPTIONS_HEADING": "Optionen"
        },
        "NO_TEMPLATES": "Es gibt keine anzuzeigenden Rota-Vorlagen.",
        "CLONE_TEMPLATE": "Klonen",
        "DELETE_TEMPLATE": "Löschen",
        "EDITED_ON": "auf",
        "CONFIRM": {
          "DELETE_NODE": "Bist du sicher, dass du das löschen möchtest?"
        },
        "SUCCESS": {
          "DELETE_NODE": "Ihre Bedarfsprognose wurde erfolgreich gelöscht."
        },
        "ERRORS": {
          "DELETE_NODE": "Ups, irgendwas ist schief gelaufen. Möglicherweise müssen Sie es erneut versuchen!"
        }
      },
      "NEW_TEMPLATE_MODAL": {
        "TITLE": "Neue Vorlage",
        "REMINDER": "<strong>Psst!</strong> Sie haben bereits <strong>{{templateCount}}</strong> Vorlage (n). Sie müssen nur dann eine neue erstellen, wenn sich Ihre Terminanforderungen erheblich geändert haben. Andernfalls sollten Sie ein vorhandenes bearbeiten.",
        "TYPE_LABEL": "Typ",
        "NEW_BLANK_TEMPLATE": "Neue leere Vorlage",
        "CLONE_EXISTING_TEMPLATE": "Bestehende Vorlage klonen",
        "CLONE_FROM_LABEL": "Klonen von",
        "NAME_LABEL": "Name der Vorlage",
        "NAME_HELP": "Sie werden regelmäßig Dienstpläne aus dieser Vorlage erstellen und ein guter Name beschreibt, für wen oder wofür diese Dienstpläne bestimmt sind, wie zum Beispiel „Dienstplan für Support-Mitarbeiter“.",
        "NAME_PLACEHOLDER": "dh. Hauptsaison oder B-Team-Rota",
        "DEFAULT_SHIFT_TYPE_LABEL": "Standard-Schichttyp",
        "DEFAULT_SHIFT_TYPE_HELP": "Wenn du deine Vorlage erstellst, kannst du den Schichttyp für jede Gruppe oder Aufgabe ändern. Es ist einfach praktisch für uns, jetzt eine Standardschicht festzulegen.",
        "CREATE_BUTTON": "Erschaffe!",
        "CREATE_BUTTON_LOADING": "Bitte warten Sie, das kann einen Moment dauern...",
        "CANCEL_BUTTON": "Stornieren",
        "CONFIRM_CLONE": "Das Klonen einer Vorlage dauert je nach Größe der Vorlage ein oder zwei Augenblicke. Sind Sie sicher, dass Sie fortfahren möchten?",
        "CREATE_ERROR": "Diese Vorlage kann derzeit nicht erstellt werden. Bitte versuchen Sie es erneut."
      },
      "DELETE_TEMPLATE_MODAL": {
        "INFO_TITLE": "Vorlage löschen",
        "INFO_BODY": "Das Löschen einer Vorlage kann nicht rückgängig gemacht werden und kann nicht rückgängig gemacht werden. Um uns davon zu überzeugen, dass Sie dies tun möchten, geben Sie bitte den Namen der Vorlage in das unten stehende Feld ein und klicken Sie dann auf Löschen.",
        "DELETE_TEMPLATE_BUTTON": "Vorlage löschen",
        "SUCCESS": {
          "DELETE_TEMPLATE": "Ihre Vorlage wurde erfolgreich gelöscht."
        },
        "ERRORS": {
          "DELETE_TEMPLATE": "Ups, irgendwas ist schief gelaufen. Möglicherweise müssen Sie es erneut versuchen!"
        }
      }
    },
    "SHIFT": {
      "EDIT_MODAL": {
        "SHIFT": "Schicht",
        "SITE": "Standort",
        "START": "Starten",
        "BREAK": "Pause",
        "NONE": "Keine",
        "FINISH": "Fertig",
        "NEW_SHIFT": "Neue Schicht",
        "ATTRIBUTE_WAGE_COST_TO_SITE": " Ordnen Sie die Lohnkosten und Stunden dem ausgewählten Standort zu",
        "DATE_AND_TIME": "Datum & Uhrzeit",
        "TO": " zu ",
        "THEN_REST": " dann ruh dich aus ",
        "SHIFT_TYPE": "Art der Schicht",
        "LOCATION": "Anwesenheitszone",
        "NO_BREAK": "Keine Pause",
        "PAID_BREAK": "Bezahlte Pause",
        "UNPAID_BREAK": "Unbezahlte Pause",
        "OF_LENGTH": "von Länge",
        "BREAK_MESSAGE_WARNING": "Die dieser Schicht zugewiesenen Mitarbeiter haben unterschiedliche Pausenanforderungen. Wenn Sie fertig sind, speichert Rotaready für jede Person mit dem richtigen Pausensatz eine separate Schicht.",
        "BREAK_MESSAGE_SUCCESS": "Wir haben automatisch die richtige Pause auf diese Schicht angewendet.",
        "PAY_RULE": "Bezahlregel",
        "APPLIES": " gilt",
        "FOR_THE_SCHEDULED_DURATION": "Für die geplante Dauer",
        "FOR_ANY_OVERTIME": "Für alle Überstunden",
        "STAFF_SELECTION": "Auswahl des Personals",
        "STAFF_SELECTION_HELP": "Bearbeiten Sie zugewiesene Mitarbeiter und verwenden Sie Stichwörter, um andere berechtigte Mitarbeiter zu filtern",
        "STAFF_MUST_MATCH": "Das Personal muss übereinstimmen",
        "ANY_OF_THE_TAGS": "<strong>eines</strong> der Tags",
        "ANY": "irgendein",
        "ALL": "alles",
        "OF_THE_TAGS": " der Schlagworte",
        "ALL_OF_THE_TAGS": "<strong>alle</strong> Schlagworte",
        "TAG_TYPEAHEAD_PLACEHOLDER": "Tippe, um Schlagworte zu finden",
        "STAFF_ASSIGNED": "Zugewiesen",
        "NOBODY_IS_ASSIGNED": "Niemand ist zugewiesen",
        "STAFF_ELIGIBLE": "Teilnahmeberechtigt",
        "STAFF_FILTER_PLACEHOLDER": "Suche...",
        "SHOW": "Zeige",
        "MORE": " mehr...",
        "NOBODY_MATCHES_YOUR_TAGS": "Niemand passt zu deinen Stichwörtern",
        "NOBODY_ELSE_IS_AVAILABLE": "Niemand sonst ist verfügbar",
        "MORE_OPTIONS": "Mehr Optionen",
        "ADD_NOTES_AND_ADJUST_PAY_RATES": "Fügen Sie Notizen hinzu und passen Sie die Lohnsätze an",
        "DETAILS": "Einzelheiten",
        "DETAILS_TEXTAREA_PLACEHOLDER": "Füge hier Notizen, Pflichten und andere Details hinzu...",
        "ROTA": "Rota",
        "NO_ROTA": "Kein Rota",
        "PAY_LABEL": "Zahlen",
        "PAY_TOGGLE": "Das ist eine bezahlte Schicht",
        "DELETE": "Löschen",
        "VIEW_BROADCAST": "Übertragung ansehen",
        "START_BROADCAST": "Übertragung starten",
        "SAVE": "Speichern",
        "CANCEL": "Stornieren"
      },
      "SHIFT_BROADCAST_MODAL": {
        "SHIFT": "Schicht",
        "SITE": "Standort",
        "START": "Starten",
        "BREAK": "Pause",
        "NONE": "Keine",
        "FINISH": "Fertig",
        "ORIGINAL_PLANNED_STAFF": "Ursprünglich geplantes Personal",
        "EXTRA_STAFF_REQUIRED": "Zusätzliches Personal erforderlich",
        "NOT_SHIFT_BROADCASTING_HELP": "Setzen Sie den Wert auf 0, um so viele Mitarbeiter wie möglich anzufordern.",
        "START_BROADCASTING": "Starten Sie die Übertragung",
        "START_BROADCASTING_LOADING": "Übertragung wird gestartet...",
        "CANCEL": "Stornieren",
        "ACCEPTED": "Akzeptiert",
        "PENDING": "Ausstehend",
        "REJECTED": "Abgelehnt",
        "EVERYONE": "Jeder",
        "NO_PARTICIPANTS_TO_SHOW": "Es sind keine Sendeteilnehmer zum Anzeigen vorhanden.",
        "NEEDED": "Erforderlich",
        "SHIFT_BROADCASTING_HELP": "Ihre Übertragung ist derzeit im Gange. Die folgende Liste wird aktualisiert, sobald die Mitarbeiter die Schicht akzeptieren oder ablehnen. Die Übertragung wird automatisch beendet, wenn die richtige Anzahl von Mitarbeitern zugesagt hat oder wenn alle geantwortet haben.",
        "STOP_BROADCASTING": "Übertragung beenden",
        "STOP_BROADCASTING_LOADING": "Übertragung wird beendet...",
        "PAUSE_BROADCAST": "Übertragung unterbrechen",
        "PAUSE_BROADCAST_LOADING": "Übertragung wird angehalten...",
        "RESUME_BROADCAST": "Übertragung fortsetzen",
        "RESUME_BROADCAST_LOADING": "Übertragung wird fortgesetzt...",
        "CLOSE": "Schliessen"
      },
      "VIEW_MODAL": {
        "SHIFT": "Schicht",
        "SITE": "Standort",
        "START": "Starten",
        "BREAK": "Pause",
        "NONE": "Keine",
        "FINISH": "Fertig",
        "LOCATION": "Anwesenheitszone",
        "NONE_SPECIFIED": "Keine angegeben",
        "REST": "Ruhe",
        "DETAILS": "Einzelheiten",
        "ALLOTTED_BREAK": "Zugeteilte Pause",
        "ASSIGNED_STAFF": "Zugewiesenes Personal",
        "ON": "auf",
        "SHIFT_ROUTINE": "Schichtroutine",
        "LAST_UPDATED": "Letzte Aktualisierung",
        "GET_DIRECTIONS": "Wegbeschreibungen abrufen",
        "SHOW_TRAFFIC": "Verkehr anzeigen",
        "EDIT": " Bearbeiten",
        "CLOSE": "Schliessen"
      }
    }
  },
  "DASHBOARD": {
    "TIME_AND_ATTENDANCE": "Zeit und Anwesenheit",
    "YOU_ARE_NOW": "Du bist jetzt",
    "ERROR_PLEASE_TRY_AGAIN": "Fehler, bitte versuchen Sie es erneut",
    "MY_UPCOMING_SHIFTS": "Meine nächsten Schichten",
    "YOU_HAVE_NO_SHIFTS_TODAY": "Du hast heute keine Schichten.",
    "ALL_DAY": "Den ganzen Tag",
    "AM": "BIN",
    "PM": "PM",
    "TODAY_S_SCHEDULE": "Der heutige Zeitplan",
    "THERE_ARE_NO_SHIFTS_SCHEDULED_TODAY": "Heute sind keine Schichten geplant.",
    "WHATEVER": "Beginnt bei <strong>{{startTime}}</strong> und endet bei <strong>{{endTime</strong>}}"
  },
  "SIGN_OUT": {
    "LOGGING_OUT": "Abmelden..."
  },
  "PARTIALS": {
    "VALIDATION": {
      "ISSUE_1": {
        "HEADING": "Arbeitstag zu lang",
        "BODY": "Ihre Änderung bedeutet, dass der Arbeitstag von <b>{{name}}</b> zu lang wird (gemäß den Einstellungen Ihrer Organisation). Derzeit beginnt es am <b>{{start|momentFormat: 'lll'}}</b> und endet bei <b>{{end|momentFormat: 'lll'}} (ruht dann bis {{endRest|momentFormat: 'lll'}}</b>."
      },
      "ISSUE_2": {
        "HEADING": "Überschneidender Arbeitstag",
        "BODY": "Ihre Änderung bedeutet, dass <b>{{name}}</b> einen Arbeitstag von <b>{{primaryStart|momentFormat: 'lll'}} bis {<b>{primaryEnd|momentFormat</b>: 'LT'}}</b> hat. Leider überschneidet sich das mit einem Arbeitstag, den sie bereits von <b>{{secondaryStart|momentFormat: 'lll'}} bis {{secondaryEnd|momentFormat: 'LT'}}</b> <b>haben (dann ruht sie bis {{endRest|momentFormat: 'LT'}}</b>)."
      },
      "ISSUE_3": {
        "HEADING": "Schicht außerhalb des Arbeitstages",
        "BODY": "<b>Bei {{name}}</b> ist eine Schicht von <b>{{start|momentFormat: 'LT'}} nach {{end|momentFormat: 'LT'}}</b> <b>geplant (dann ruht sie bis {{endRest|momentFormat: 'LT'}}</b>). Ihre Änderung würde bedeuten, dass diese Schicht teilweise oder vollständig außerhalb des Arbeitstages fällt."
      },
      "ISSUE_4": {
        "HEADING": "Nicht verfügbar",
        "BODY": "<b>{{name}}</b> ist von <b>{{start|momentFormat: 'lll'}} bis <b>{{end|momentFormat: 'LT'</b>}}</b> so wie sie sind nicht verfügbar",
        "REASON_1": "in einer Schicht arbeiten ({{eventType}} in {{entityName}})",
        "REASON_2": "während eines Arbeitstages beschäftigt",
        "REASON_3": "abwesend ({{eventType}})",
        "REASON_4": "aufgrund ihres Verfügbarkeitsmusters nicht verfügbar",
        "BUTTON": "Machen Sie eine Ausnahme und versuchen Sie es erneut",
        "BUTTON_LOADING": "Wird geladen..."
      },
      "ISSUE_5": {
        "HEADING": "Vertragliche Verpflichtung nicht erfüllt",
        "BODY": "Ihre Änderung bedeutet, dass <b>{{name}}</b> ihren vertraglichen Verpflichtungen nicht nachkommen wird. Es sind jetzt <b>{{currentValue}} Tage (e)</b>, müssen aber mindestens <b>{{comparatorValue}} Tag (</b>e) sein."
      },
      "ISSUE_6": {
        "HEADING": "Vertragliche Verpflichtung nicht erfüllt",
        "BODY": "Ihre Änderung bedeutet, dass <b>{{name}}</b> ihren vertraglichen Verpflichtungen nicht nachkommen wird. Es sind jetzt <b>{{currentValue}} Stunden (n)</b>, müssen aber mindestens <b>{{comparatorValue}} Stunden (</b>n) sein."
      },
      "ISSUE_7": {
        "HEADING": "Stichwörter stimmen nicht überein",
        "BODY": "<b>{{name}}</b> hat nicht die für diese Schicht erforderlichen Tags. Das bedeutet, dass ihnen möglicherweise eine Fähigkeit, Qualifikation oder ein anderes Attribut fehlt."
      },
      "ISSUE_8": {
        "HEADING": "Aktiver Rundfunk",
        "BODY": "Für diese Schicht gibt es derzeit einen aktiven Shift Broadcast. Da Ihre Mitarbeiter möglicherweise gerade antworten, können wir nicht zulassen, dass die Schicht geändert wird, es sei denn, Sie stornieren zuerst die Schichtübertragung."
      },
      "ISSUE_9": {
        "HEADING": "Ungenügende freie Tage",
        "BODY": "Ihre Änderung bedeutet, dass <b>{{name}}</b> diese Woche an zu vielen Tagen funktioniert. Sie haben jetzt <b>{{currentValue}} freie Tage</b>, was weniger ist als ihre <b>{{comparatorValue}} erforderlichen freien Tage.</b>"
      },
      "ISSUE_10": {
        "HEADING": "Mitarbeiter inaktiv",
        "BODY": "<b>{{name}}</b> kann nicht auf <b>{{start|momentFormat: 'll'</b>}} funktionieren. Ihr Konto ist an diesem Tag entweder inaktiv oder sie wurden auf eine andere Site verschoben."
      },
      "ISSUE_11": {
        "HEADING": "Rota zur Genehmigung markiert",
        "BODY": "Sie können keine Änderungen an diesem Dienstplan vornehmen, da er zur Genehmigung markiert wurde."
      },
      "ISSUE_12": {
        "HEADING": "Zeitraum abgesegnet",
        "BODY": "Sie können an diesem Dienstplan keine Änderungen vornehmen, da er bereits unterzeichnet wurde."
      },
      "ISSUE_13": {
        "HEADING": "Arbeitslimit überschritten",
        "BODY": "Deine Änderung bedeutet, dass <b>{{name}}</b> länger funktionieren wird, als es ihnen erlaubt ist. Sie arbeiten jetzt an <b>{{currentValue}} Tag (en)</b>, was über ihrem Limit von <b>{{comparatorValue}} Tag (</b>en) liegt."
      },
      "ISSUE_14": {
        "HEADING": "Arbeitslimit überschritten",
        "BODY": "Deine Änderung bedeutet, dass <b>{{name}}</b> länger funktionieren wird, als es ihnen erlaubt ist. Sie werden jetzt <b>{{currentValue}} Stunden (n)</b> arbeiten, was mehr ist als ihr Limit von <b>{{comparatorValue}} Stunden (</b>n)."
      },
      "ISSUE_15": {
        "HEADING": "Anwesenheitszone geschlossen",
        "BODY": "Die der Schicht zugewiesene Anwesenheitszone (<b>{{assignedLocation}}</b>) ist zu diesem Zeitpunkt geschlossen.",
        "REASON": "Das liegt an: {{reason}}."
      },
      "ISSUE_16": {
        "HEADING": "Belegung der Anwesenheitszone überschritten",
        "BODY": "Die dieser Schicht zugewiesene Anwesenheitszone hat eine maximale <b>Belegung von {{occupation}}</b> Personen gleichzeitig. Mit dieser Schicht wäre die Belegung {{currentOccupancy}}."
      },
      "ISSUE_17": {
        "HEADING": "Zu wenig Personal in der Anwesenheitszone",
        "BODY": "Von {{start|momentFormat: 'lll'}} bis {{end|momentFormat: 'lll'}} gibt es eine Zeit, in der nur <b>{{currentValue</b>}} Personen arbeiten. Immer wenn jemand in einer Schicht ist, sind mindestens <b>{{comparatorValue}}</b> Personen (en) erforderlich."
      },
      "ISSUE_18": {
        "HEADING": "Mitarbeiter nicht zugelassen",
        "BODY": "{{name}} kann nicht auf <b>{{start|momentFormat: 'll'</b>}} funktionieren. Ihr Konto wurde noch nicht für die Planung freigegeben."
      },
      "ISSUE_19": {
        "HEADING": "Dokumente eines Mitarbeiters fehlen/sind abgelaufen",
        "BODY": "<b>{{name}}</b> kann nicht auf <b>{{start|momentFormat: 'll'</b>}} funktionieren. Ein oder mehrere erforderliche Dokumente fehlen oder sind abgelaufen."
      },
      "ISSUE_20": {
        "HEADING": "Zu lange Schicht (unter 18 Jahren)",
        "BODY": "Ihre Änderung bedeutet, dass <b>{{name}}</b> für {<b>{Duration|humaniseDuration</b>}} funktioniert. Da sie unter 18 Jahre alt sind, liegt die Grenze der Arbeitszeitrichtlinie bei <b>{{limit|humaniseDuration</b>}}."
      },
      "ISSUE_21": {
        "HEADING": "Grenzen überschreiten",
        "BODY": "<b>Du kannst keine Schicht für <b>{{name}} am {{</b><b>start|momentFormat: 'll'}}</b> planen, weil sie zu einem Dienstplan gehört, der am {{<b>periodStart|momentFormat: 'll'}}} beginnt und am {{periodEnd|momentFormat: 'll'}}</b> endet.</b>"
      }
    }
  },
  "NAV": {
    "HOME": "Zuhause",
    "ROTAS": "Dienstpläne",
    "STAFF": "Mitarbeiter",
    "ATTENDANCE": "Teilnahme",
    "REPORTS": "Berichte",
    "SETTINGS": "Einstellungen",
    "VIEW_ROTA": "Rota ansehen",
    "SCHEDULE": "Mein Terminplan",
    "ABSENCE": "Meine Abwesenheit",
    "AVAILABILITY": "Meine Verfügbarkeit",
    "DOCUMENTS": "Meine Dokumente",
    "ROTA_DASHBOARD": "Armaturenbrett",
    "ROTA_ROTA_EDITOR": "Rota-Redakteur",
    "ROTA_TRONC": "Tronc",
    "ROTA_EVENT_CALENDAR": "Veranstaltungsplaner",
    "STAFF_DIRECTORY": "Verzeichnis",
    "STAFF_REQUESTS": "Anfragen",
    "STAFF_ABSENCES": "Abwesenheitsplan",
    "STAFF_EMPLOYEE_SUBMITTED_DETAILS": "Vom Mitarbeiter übermittelte Daten",
    "ATTENDANCE_OVERVIEW": "Teilnahme",
    "ATTENDANCE_ISSUES": "Probleme",
    "COST_CONTROL": "Kontrolle der Kosten",
    "COST_CONTROL_SIGN_OFF": "Kostenkontrolle und Freigabe",
    "COST_CONTROL_AGGREGATED_TOTALS": "Aggregierte Gesamtwerte",
    "ASSET_REGISTRY": "Vermögensregister",
    "WFM_DASHBOARD": "Mein Dashboards"
  },
  "SETTINGS": {
    "INDEX": {
      "TITLE": "Einstellungen",
      "PLACEHOLDER_TEXT": "👈 Bitte wählen Sie links eine Einstellung aus, um loszulegen.",
      "CATEGORY_HR": "HR",
      "CATEGORY_ROTAS": "Dienstpläne",
      "CATEGORY_ATTENDANCE": "Teilnahme",
      "CATEGORY_INTEGRATIONS": "Integrationen",
      "ITEM_ROTA_TEMPLATES": "Rota-Vorlagen",
      "ITEM_TRUSTED_DEVICES": "Vertrauenswürdige Geräte",
      "ITEM_SHIFT_LOCATIONS": "Anwesenheitszonen",
      "ITEM_SHIFT_TYPES": "Schichttypen",
      "ITEM_OUTSOURCING": "Outsourcing",
      "ITEM_ABSENCE_TYPES": "Abwesenheitstypen",
      "ITEM_DOCUMENT_CATEGORIES": "Dokumentenkategorien",
      "ITEM_DOCUMENT_TEMPLATES": "Vorlagen für Dokumente",
      "ITEM_TAGS_GROUPS": "Schlagworte und Gruppen",
      "ITEM_SHIFT_ROUTINES": "Schichtroutinen",
      "ITEM_REST_BREAKS": "Ruhepausen",
      "ITEM_PAY_ELEMENTS": "Bezahle Elemente",
      "ITEM_PAY_RULES": "Regeln für die Bezahlung",
      "ITEM_PERMISSIONS": "Genehmigungen",
      "ITEM_BUDGETS": "Budgets",
      "ITEM_ASSET_TYPES": "Arten von Vermögenswerten",
      "ITEM_ENTITY_GROUP_SUBSCRIPTIONS": 'Site-Zugriffsmanager',
      "ITEM_ENTITY_GROUPS": 'Site-manager',
      "ITEM_POSITIONS": 'Berufsrollen',
      "ITEM_LEAVER_REASONS": 'Gründe für das Verlassen',
      "ITEM_RECRUITMENT_SOURCES": 'Rekrutierungsquellen',
      "ITEM_LABOUR_DEPLOYMENT": 'Arbeitseinsatz',
    },
    "DOCUMENT_TEMPLATES": {
      "TITLE": "Vorlagen für Dokumente",
      "FILTER_LABEL_CATEGORY": "Kategorie",
      "BUTTON_UPLOAD": "Upload",
      "NAME_COLUMN_LABEL": "Name",
      "TEMPLATE_CATEGORY_COLUMN_LABEL": "Kategorie",
      "FILE_NAME_COLUMN_LABEL": "Name der Datei",
      "FILE_SIZE_COLUMN_LABEL": "Größe der Datei",
      "DESTINATION_CATEGORY_COLUMN_LABEL": "Kategorie des Reiseziels",
      "SIGNATORIES_COLUMN_LABEL": "Unterzeichner",
      "EDITED_COLUMN_LABEL": "Hinzugefügt von",
      "OPTIONS_COLUMN_LABEL": "Optionen",
      "LINK_DELETE": "Löschen",
      "SIGNATORIES_BOTH": "Arbeitnehmer, Arbeitgeber",
      "SIGNATORIES_EMPLOYEE": "Mitarbeiter",
      "NO_DOCS_MATCH_TEXT": "Wir konnten keine Dokumentvorlagen finden, die Ihren Filtern entsprechen.",
      "ERROR_NO_CATEGORIES": "Dokumentvorlagen sind für Ihre Organisation nicht aktiviert. Chatten Sie mit uns, um loszulegen.",
      "ALERT_CONFIRM_DELETE": "Sind Sie sicher, dass Sie diese Vorlage löschen möchten? Das kann nicht rückgängig gemacht werden!",
      "ERROR_500": "Wir können derzeit keine Dokumentvorlagen laden. Bitte versuchen Sie es erneut.",
      "ERROR_403": "Sie sind nicht berechtigt, Dokumentvorlagen anzuzeigen/zu ändern.",
      "UPLOAD_MODAL": {
        "TITLE": "Laden Sie eine Dokumentvorlage hoch",
        "LABEL_NAME": "Name",
        "HELP_TEXT_NAME": "Geben Sie Ihrer Vorlage einen Namen, den Sie wiedererkennen werden.",
        "LABEL_CATEGORY": "Vorlagenkategorie",
        "HELP_TEXT_CATEGORY": "Sorgen Sie für Ordnung in Ihren Vorlagen, indem Sie eine Kategorie auswählen.",
        "LABEL_DESTINATION_CATEGORY": "Kategorie des Zieldokuments",
        "HELP_TEXT_DESTINATION_CATEGORY": "Wenn diese Vorlage verwendet wird und ein generiertes Dokument signiert ist, wählen Sie die Dokumentkategorie, in der die Datei gespeichert werden soll.",
        "LABEL_SIGNATORIES": "Unterzeichner",
        "SIGNATORIES_OPTION_BOTH": "Sowohl der Arbeitnehmer als auch der Arbeitgeber müssen unterschreiben.",
        "SIGNATORIES_OPTION_EMPLOYEE": "Nur der Mitarbeiter muss unterschreiben.",
        "LABEL_UPLOAD": "Upload",
        "BUTTON_CANCEL": "Stornieren",
        "BUTTON_SAVE": "Speichern",
        "ALERT_SUCCESS": "Ihre Dokumentvorlage wurde gespeichert.",
        "ALERT_ERROR_500": "Beim Hochladen der Dokumentvorlage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns, falls diese Meldung weiterhin angezeigt wird.",
        "ALERT_ERROR_400": "Mit Ihrer Dokumentvorlage stimmt etwas nicht ganz."
      }
    },
    "ABSENCE_TYPES": {
      "TITLE": "Abwesenheitstypen",
      "ERROR_500": "Wir können derzeit keine Abwesenheitstypen laden. Bitte versuchen Sie es erneut.",
      "ERROR_403": "Sie sind nicht berechtigt, Abwesenheitstypen einzusehen/zu ändern.",
      "BUTTON_ADD_NEW": "Neues hinzufügen",
      "BUTTON_TOGGLE_DELETED": "Gelöscht einschließen",
      "COLUMN_NAME": "Name",
      "COLUMN_BOOKABLE": "Buchbar",
      "COLUMN_APPROVAL": "Genehmigung erforderlich",
      "COLUMN_PAID": "Bezahlt",
      "COLUMN_EDITED": "Herausgegeben von",
      "COLUMN_OPTIONS": "Optionen",
      "YES_LABEL": "Ja",
      "NO_LABEL": "Nein",
      "LINK_EDIT": "Bearbeiten",
      "LINK_DELETE": "Löschen",
      "LINK_REINSTATE": "Wiedereinsetzen",
      "PILL_DELETED": "Gelöscht",
      "ALERT_CONFIRM_DELETE": "Möchten Sie diese Abwesenheitsart wirklich löschen?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Ihre Abwesenheitstyp wurde gespeichert.",
        "ALERT_ERROR_500": "Beim Erstellen Ihrer Abwesenheitstyp ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns, falls diese Meldung weiterhin angezeigt wird.",
        "ALERT_ERROR_400": "Eine Abwesenheitstyp mit diesem Namen ist bereits vorhanden. Bitte wählen Sie eine andere.",
        "ADD_TITLE": "Neue Abwesenheitsart",
        "EDIT_TITLE": "Abwesenheitstyp bearbeiten",
        "BUTTON_SAVE": "Speichern",
        "BUTTON_CANCEL": "Stornieren",
        "LABEL_NAME": "Name",
        "LABEL_COLOUR": "Farbe",
        "LABEL_BOOKABLE": "Buchbar",
        "LABEL_BOOKABLE_HELP": "Erlauben Sie Mitarbeitern, Abwesenheitsanträge dieser Art zu stellen.",
        "LABEL_APPROVAL": "Zulassung",
        "LABEL_APPROVAL_HELP": "Anfragen dieser Art bedürfen der Genehmigung.",
        "LABEL_PAID": "Bezahlt",
        "LABEL_PAID_HELP": "Fehlzeiten dieser Art werden bezahlt.",
        "LABEL_WORKED_HOURS": "Arbeitsstunden",
        "LABEL_WORKED_HOURS_HELP": "Fehlzeiten dieser Art tragen zu den „Arbeitsstunden“ bei.",
        "LABEL_ACCRUES": "Fällt Urlaub an",
        "LABEL_ACCRUES_HELP": "Arbeitnehmer erhalten für diese Art von Fehlzeiten Urlaub, wenn sie eine periodengerechte Urlaubsvergütung erhalten.",
        "LABEL_OVERLAPS_SHIFTS": "Spiel wechseln",
        "LABEL_OVERLAPS_SHIFTS_HELP": "Ein Fehlen dieser Art kann mit Schichten einhergehen, anstelle von Ein- und Ausschalten.",
        "LABEL_TRONC": "Tronc",
        "LABEL_TRONC_HELP": "Eine solche Abwesenheit trägt zur Arbeitszeit in Tronc bei.",
        "LABEL_ALLOWANCE_REQUIRED": "Zulage erforderlich",
        "LABEL_ALLOWANCE_REQUIRED_HELP": "Um eine solche Abwesenheit zu beantragen, stellen Sie sicher, dass der Mitarbeiter über eine aktive Zulage verfügt.",
        "TITLE_FILE_UPLOADS": "Datei-Uploads",
        "LABEL_SUBMISSION_UPLOAD": "Auf Anfrage",
        "LABEL_SUBMISSION_UPLOAD_HELP": "Erlauben Sie das Anhängen einer Datei, wenn das Fehlen dieses Typs gewünscht wird.",
        "LABEL_REVIEW_UPLOAD": "Bei der Überprüfung",
        "LABEL_REVIEW_UPLOAD_HELP": "Erlauben Sie das Anhängen einer Datei, wenn ein Fehlen dieses Typs genehmigt oder abgelehnt wird.",
        "LABEL_SAVE_TO": "Speichern unter",
        "HELP_TEXT_SAVE_TO": "Wenn eine Datei an eine Abwesenheit dieses Typs angehängt wird, wird sie in den Dokumenten des Mitarbeiters unter der ausgewählten Kategorie gespeichert.",
        "FILE_UPLOAD_OPTION_NONE": "Abgeschaltet",
        "FILE_UPLOAD_OPTION_OPTIONAL": "Fakultativ",
        "FILE_UPLOAD_OPTION_MANDATORY": "Erforderlich",
        "INFO_LOCKED": "Dies ist ein spezieller Abwesenheitstyp, der von Rotaready verwendet wird. Wir haben einige Eigenschaften versteckt, die nicht geändert werden können. Bitte setzen Sie sich mit uns in Verbindung, wenn Sie Hilfe bei dieser Abwesenheitstyp benötigen.",
        "TITLE_PAY": "Zahlen",
        "LABEL_FIXED_AMOUNTS": "Feste Beträge",
        "HELP_TEXT_FIXED_AMOUNTS": "Wenn einem Mitarbeiter ein fester Betrag gezahlt wird, z. B. für aufgelaufene, aber nicht in Anspruch genommene Fehlzeiten, wird dieses Entgeltelement verwendet.",
        "TITLE_REASONS": "Gründe",
        "COLUMN_TITLE": "Grund",
        "COLUMN_OPTIONS": "Optionen",
        "LINK_DELETE": "Löschen",
        "NO_REASONS": "Es wurden keine Gründe hinzugefügt.",
        "PLACEHOLDER_REASON": "Neuer Grund...",
        "LINK_ADD": "Hinzufügen"
      }
    },
    "OUTSOURCING": {
      "TITLE": "Outsourcing",
      "BUTTON_ADD_NEW": "Neues hinzufügen",
      "BUTTON_TOGGLE_DELETED": "Gelöscht einschließen",
      "NO_DATA_TEXT": "Es gibt keine Outsourcing-Organisationen, die man vorweisen könnte.",
      "NAME_COLUMN_TEXT": "Name",
      "ENTITY_COLUMN_TEXT": "Standort/Abteilung",
      "RESOURCES_COLUMN_TEXT": "Ressourcen",
      "HOURS_COLUMN_TEXT": "Verfügbare Stunden",
      "COST_COLUMN_TEXT": "Kosten",
      "LAST_EDITED_COLUMN_TEXT": "Herausgegeben von",
      "OPTIONS_COLUMN_TEXT": "Optionen",
      "UNLIMITED_TEXT": "Unbegrenzt",
      "ALWAYS_24_TEXT": "Immer (24 Stunden)",
      "PER_HOUR_TEXT": "/Stunde",
      "PER_DAY_TEXT": "/Tag",
      "ORG_WIDE_TEXT": "Unternehmensweit",
      "LINK_EDIT": "Bearbeiten",
      "LINK_DELETE": "Löschen",
      "LINK_REINSTATE": "Wiedereinsetzen",
      "PILL_DELETED": "Gelöscht",
      "ERROR_500": "Wir können Outsourcing-Organisationen derzeit nicht auslasten. Bitte versuchen Sie es erneut.",
      "ERROR_403": "Sie sind nicht berechtigt, Outsourcing-Organisationen einzusehen/zu ändern.",
      "ALERT_CONFIRM_DELETE": "Möchten Sie diese Outsourcing-Organisation wirklich löschen?",
      "EDIT_MODAL": {
        "ALERT_SUCCESS": "Ihre Outsourcing-Organisation wurde gerettet.",
        "ALERT_ERROR_500": "Beim Erstellen Ihrer Outsourcing-Organisation ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns, falls diese Meldung weiterhin angezeigt wird.",
        "ALERT_ERROR_400": "Eine Outsourcing-Organisation mit diesem Namen gibt es bereits. Bitte wählen Sie eine andere.",
        "EDIT_TITLE": "Organisation bearbeiten",
        "ADD_TITLE": "Organisation hinzufügen",
        "NAME_LABEL": "Name",
        "ENTITY_LABEL": "Standort/Abteilung",
        "PAY_LABEL": "Zahlen",
        "HOURLY_OPTION": "Stündlich bezahlt",
        "FIXED_OPTION": "Feste Kosten pro Tag",
        "HOURLY_RATE_LABEL": "Stundensatz",
        "DAILY_RATE_LABEL": "Tagesrate",
        "AUTOMATIC_SCHEDULE_TITLE": "Automatisierte Terminplanung",
        "HOURS_AVAILABLE_LABEL": "Verfügbare Stunden",
        "TO_TEXT": " zu ",
        "HOURS_AVAILABLE_HELP": "Die Zeit, in der Mitarbeiter dieser Organisation verfügbar sind",
        "RESOURCES_AVAILABLE_LABEL": "Verfügbare Ressourcen",
        "RESOURCES_AVAILABLE_HELP": "Die maximale Anzahl an Mitarbeitern, die Ihnen pro Tag zur Verfügung stehen (geben Sie 0 für unbegrenzt ein)",
        "TAGS_LABEL": "Schlagworte",
        "TAGS_PLACEHOLDER": "Tippen Sie, um nach Stichwörtern zu suchen",
        "TAGS_HELP": "Weisen Sie Schlagworte zu, wie Sie es einem normalen Mitarbeiter tun würden. Diese gelten für alle Beschäftigten dieser Organisation.",
        "BUTTON_SAVE": "Speichern",
        "BUTTON_CANCEL": "Stornieren"
      }
    },
    "TRUSTED_DEVICES": {
      "TITLE": "Vertrauenswürdige Geräte",
      "DEVICE": "Gerät",
      "ENTITY": "Hauptstandort/Abteilung",
      "DEVICE_TYPE": "Benutzeragent",
      "EDITED_BY": "Herausgegeben von",
      "LAST_ACTIVITY": "Letzte Aktivität",
      "STATUS": "Status",
      "OPTIONS": "Optionen",
      "NO_DEVICES": "Es gibt keine vertrauenswürdigen Geräte für die Aufzeichnung der Anwesenheit.",
      "NO_ACTIVITY": "Keine Aktivität",
      "LINK_UNTRUST": "Unvertrauen",
      "LINK_TRUST": "Vertrauen",
      "LINK_EDIT": "Bearbeiten",
      "ALERT_CONFIRM_TRUST": "Sind Sie sicher, dass Sie diesem Gerät für die Anwesenheitserfassung vertrauen möchten?",
      "ALERT_CONFIRM_UNTRUST": "Sind Sie sicher, dass Sie diesem Gerät nicht mehr vertrauen möchten, damit es nicht mehr für die Anwesenheitserfassung verwendet werden kann? Dies kann nicht rückgängig gemacht werden.",
      "ERROR_500": "Wir können derzeit keine vertrauenswürdigen Geräte laden. Bitte versuchen Sie es erneut.",
      "ERROR_403": "Sie sind nicht berechtigt, vertrauenswürdige Geräte anzuzeigen/zu ändern.",
      "STATUS_PENDING": "Ausstehend",
      "STATUS_TRUSTED": "Vertrauenswürdig",
      "STATUS_UNTRUSTED": "Nicht vertrauenswürdig",
      "FILTER_SITE": "Kartografierter Standort/Abteilung",
      "FILTER_STATUS": "Status",
      "BUTTON_NEW_DEVICE": "Neues Gerät",
      "NEW_DEVICE_MODAL": {
        "TITLE": "Neues Gerät hinzufügen",
        "BUTTON_CANCEL": "Stornieren",
        "BUTTON_DONE": "Weiter",
        "TEXT_STEP_1": "1. Schritt",
        "TEXT_STEP_2": "Schritt 2",
        "TEXT_STEP_1_BODY": "Schnappen Sie sich Ihr Tablet oder Ihren Laptop, öffnen Sie einen Webbrowser und navigieren Sie zur folgenden URL:",
        "TEXT_STEP_2_BODY_A": "Wenn Sie nach einer „Realm-ID“ gefragt werden, geben Sie den folgenden Wert ein:",
        "TEXT_STEP_2_BODY_B": "Wenn Sie nach einer „Entitäts-ID“ gefragt werden, geben Sie den folgenden Wert ein:",
        "TEXT_STEP_3_BODY": "Wenn Sie fertig sind, klicken Sie auf Weiter, um zur Geräteliste zurückzukehren. Ihr neues Gerät sollte dann erscheinen. Klicken Sie neben dem Gerät auf Vertrauen, um den Kopplungsvorgang abzuschließen."
      },
      "EDIT_DEVICE_MODAL": {
        "TITLE": "Gerät bearbeiten",
        "BUTTON_CANCEL": "Stornieren",
        "BUTTON_SAVE": "Speichern",
        "BODY": "Wenn Sie unten weitere Standorte oder Abteilungen auswählen, werden die zugehörigen Mitarbeiter auf dem Startbildschirm des Geräts angezeigt. Sie können den Hauptstandort/die Hauptabteilung nicht abwählen. Sie müssen dem Gerät nicht vertrauen, wenn Sie dies ändern möchten.",
        "ALERT_200": "Das Gerät wurde aktualisiert.",
        "ALERT_500": "Wir konnten das Gerät gerade nicht aktualisieren. Bitte versuchen Sie es später erneut.",
        "LABEL_NAME": "Name des Geräts"
      }
    }
  },
  "MESSAGING": {
    "BETA": "BETA",
    "MESSAGE_HUB": {
      "TITLE": "Nachrichten",
      "NO_MESSAGES": "Keine Nachrichten",
      "START_NEW": "Starte eine neue Konversation",
      "YESTERDAY": "gestern"
    },
    "CONVERSATION": {
      "MESSAGE_PLACEHOLDER": "Füge deine Nachricht hinzu",
      "INDIVIDUAL_TYPING": "{{name}} tippt...",
      "MULTIPLE_TYPING": "Mehrere Leute tippen..."
    },
    "START_NEW": {
      "TITLE": "Starte eine neue Konversation",
      "TO": "Zu:",
      "REMOVE": "entfernen",
      "START_CONVERSATION": "Konversation beginnen"
    },
    "ALERTS": {
      "START_CONVERSATION": "Beim Starten Ihrer Konversation ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "SUBSCRIBE_TO_CHANNELS": "Beim Abonnieren Ihrer Konversationen ist ein Problem aufgetreten. Möglicherweise erhalten Sie keine Nachrichten. Bitte aktualisieren Sie es oder setzen Sie sich mit uns in Verbindung, falls das Problem weiterhin besteht.",
      "FETCH_MESSAGES": "Beim Abrufen Ihrer Nachrichten ist ein Problem aufgetreten. Bitte aktualisieren Sie es oder setzen Sie sich mit uns in Verbindung, falls das Problem weiterhin besteht.",
      "FETCH_NEW_MESSAGES": "Beim Abrufen neuer Nachrichten ist ein Fehler aufgetreten. Bitte aktualisieren Sie es oder setzen Sie sich mit uns in Verbindung, falls das Problem weiterhin besteht.",
      "FETCH_CONVERSATIONS": "Beim Abrufen Ihrer Konversationen ist ein Fehler aufgetreten. Bitte aktualisieren Sie es oder setzen Sie sich mit uns in Verbindung, falls das Problem weiterhin besteht.",
      "SEND_MESSAGE": "Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
    }
  },
  "ABSENCE": {
    "REQUEST": {
      "TITLE": "Abwesenheitsanfrage",
      "BUTTON_SUBMIT": "Einreichen",
      "LABEL_TYPE": "Wählen Sie einen Typ",
      "LABEL_REASON": "Wähle einen Grund",
      "LABEL_EMPLOYEE": "Mitarbeiter",
      "LABEL_START_DATE": "Startdatum",
      "LABEL_END_DATE": "Enddatum",
      "LABEL_SCOPE_ALL_DAY": "Den ganzen Tag",
      "LABEL_SCOPE_AM": "Nur AM",
      "LABEL_SCOPE_PM": "Nur PM",
      "LABEL_SCOPE_CUSTOM": "Wählen Sie bestimmte Stunden",
      "LABEL_START_TIME": "Startzeit",
      "LABEL_END_TIME": "Endzeit",
      "ALERT_OVERLAPPING_ABSENCE_USER": "Sie haben eine weitere Abwesenheitsbuchung, die sich mit den von Ihnen ausgewählten Terminen überschneidet.",
      "ALERT_OVERLAPPING_ABSENCE_OTHERS": "Nur damit du es weißt, zu dieser Zeit sind auch andere Leute abwesend.",
      "ERROR_500": "In dem Moment sind wir auf einen Haken gestoßen. Bitte versuche es erneut!",
      "LABEL_DAYS": "Tage",
      "LABEL_HOURS": "Stunden",
      "SECTION_UNPAID_DAYS": "Reguläre Ruhetage",
      "HELP_TEXT_NO_UNPAID_DAYS": "In dieser Anfrage gibt es keine regulären Ruhetage.",
      "BUTTON_OPTIONS": "Optionen",
      "BUTTON_CHANGE": "Reguläre Ruhetage ändern",
      "BUTTON_RESET": "Reguläre Ruhetage auf empfohlene zurücksetzen",
      "BUTTON_CLEAR": "Alle regulären Ruhetage löschen",
      "MODAL_CALENDAR_TITLE": "Wählen Sie reguläre Ruhetage",
      "MODAL_BUTTON_SAVE": "Speichern",
      "LABEL_ADD_A_NOTE": "Eine Notiz zu dieser Anfrage hinzufügen",
      "BUTTON_COMPLETE": "Bring mich zurück",
      "COMPLETE_TITLE": "Du bist startklar.",
      "COMPLETE_LABEL_APPROVED": "Genehmigt",
      "COMPLETE_TEXT_APPROVED": "Ihre Anfrage wurde automatisch eingereicht und genehmigt ✅",
      "COMPLETE_LABEL_PENDING": "Ausstehende Genehmigung",
      "COMPLETE_TEXT_PENDING": "Ihre Anfrage wurde eingereicht und wartet auf ihre Genehmigung. Wir benachrichtigen dich, wenn sich das ändert 👍"
    },
    "VALIDATION_MODAL": {
      "TITLE": "Etwas stimmt nicht ganz",
      "DESCRIPTION": "Leider verursacht das, was Sie versuchen zu tun, ein oder mehrere Probleme.",
      "BUTTON_CLOSE": "Schliessen",
      "INVALID_DATES_TITLE": "Ungültige Daten",
      "INVALID_DATES_DESCRIPTION": "Sie können nur AM, Nur PM oder benutzerdefinierte Stunden wählen, wenn Ihr Start- und Enddatum auf denselben Tag fallen.",
      "INVALID_HEADER_TITLE": "Ungültiger Abwesenheits",
      "INVALID_HEADER_DESCRIPTION": "Sie können diesen Abwesenheitsdatensatz nicht bearbeiten. Es könnte gelöscht worden sein.",
      "INVALID_ACTION_TITLE": "Ungültige Aktion",
      "INVALID_ACTION_DESCRIPTION": "Sie können den Zeitpunkt einer Abwesenheitsbuchung nicht mehr ändern, nachdem sie abgelehnt oder storniert wurde.",
      "INVALID_DAYS_TITLE": "Ungültige Tage",
      "INVALID_DAYS_DESCRIPTION": "Sie können keinen Abwesenheitsantrag für null Stunden oder null Tage stellen.",
      "OUTSIDE_EMPLOYMENT_TITLE": "Nebentätigkeit",
      "OUTSIDE_EMPLOYMENT_DESCRIPTION": "Ihr gewähltes Start- oder Enddatum liegt außerhalb der Beschäftigungsdaten des Mitarbeiters.",
      "SPANS_TIMEZONES_TITLE": "Der Datumsbereich erstreckt sich über zwei Zeitzonen",
      "SPANS_TIMEZONES_DESCRIPTION": "Aus den Terminaufzeichnungen des Mitarbeiters geht hervor, dass er zu den von Ihnen ausgewählten Terminen den Standort wechselt und sich sein vorhandener Standort in einer anderen Zeitzone befindet als sein neuer Standort. Stellen Sie sicher, dass Ihre Abwesenheitsbuchung endet, bevor sie den Standort wechseln, oder beginnt, nachdem sie den Standort gewechselt haben.",
      "SPANS_PAYROLL_CALENDARS_TITLE": "Der Datumsbereich erstreckt sich über zwei Abrechnungskalender",
      "SPANS_PAYROLL_CALENDARS_DESCRIPTION": "Aus den Terminaufzeichnungen des Mitarbeiters geht hervor, dass er den Gehaltsabrechnungskalender an den von Ihnen ausgewählten Terminen ändert. Stellen Sie sicher, dass Ihre Abwesenheitsbuchung endet, bevor sie den Gehaltsabrechnungskalender ändern, oder beginnt, nachdem sie den Gehaltsabrechnungskalender geändert haben.",
      "INVALID_PAYROLL_CALENDAR_TITLE": "Gehaltsabrechnungskalender ungültig",
      "INVALID_PAYROLL_CALENDAR_DESCRIPTION": "Der gewählte Zeitraum beginnt vor dem ersten Gehaltslauf des Gehaltsabrechnungskalenders des Mitarbeiters.",
      "OVERLAPPING_ABSENCE_TITLE": "Überlappende Abwesenheit",
      "OVERLAPPING_ABSENCE_DESCRIPTION": "Der Mitarbeiter ist bereits an einem oder mehreren Tagen abwesend, die sich mit den von Ihnen ausgewählten Terminen überschneiden.",
      "OVERLAPPING_SHIFTS_TITLE": "Überlappende Schichten",
      "OVERLAPPING_SHIFTS_DESCRIPTION": "Der Mitarbeiter hat eine oder mehrere veröffentlichte Schichten, die sich mit den von Ihnen ausgewählten Terminen überschneiden. Diese müssen zuerst gelöscht oder ihre Zuweisung aufgehoben werden.",
      "SIGNED_OFF_TITLE": "Abgemeldete Daten",
      "SIGNED_OFF_DESCRIPTION": "Die Daten überschneiden sich mit einem oder mehreren abgeschildeten Tagen. Sie können Abwesenheiten für Tage, die bereits abgesegnet wurden, nicht genehmigen oder stornieren.",
      "ALLOWANCE_REQUIRED_TITLE": "Zulage erforderlich",
      "ALLOWANCE_REQUIRED_DESCRIPTION": "Abwesenheiten dieser Art können Sie nicht buchen. Es muss eine aktive Zulage geben, von der abgezogen werden kann.",
      "ALLOWANCE_NOT_DEDUCTIBLE_TITLE": "Zulage nicht abzugsfähig",
      "ALLOWANCE_NOT_DEDUCTIBLE_DESCRIPTION": "Abwesenheiten dieser Art können Sie nicht buchen. Es gibt eine aktive Zulage, die jedoch als nicht abzugsfähig gekennzeichnet ist.",
      "ALLOWANCE_BALANCE_INSUFFICIENT_TITLE": "Ungenügendes Gleichgewicht",
      "ALLOWANCE_BALANCE_INSUFFICIENT_DESCRIPTION": "Der Saldo der Zulage ist zu niedrig, um diesen Antrag stellen zu können.",
      "FILE_UPLOAD_REQUIRED_TITLE": "Datei-Upload erforderlich",
      "FILE_UPLOAD_REQUIRED_DESCRIPTION": "Bei dieser Art von Abwesenheit müssen Sie eine Datei hochladen, bevor sie eingereicht oder genehmigt wird."
    },
    "VIEW_MODAL": {
      "TITLE": "Abwesenheit",
      "BUTTON_OPTIONS": "Optionen",
      "BUTTON_EDIT": "Buchung bearbeiten/überprüfen",
      "BUTTON_CANCEL": "Buchung stornieren",
      "BUTTON_CLOSE": "Schliessen",
      "STATUS_CANCELLED": "Abgesagt",
      "LABEL_START_DATE": "Beginnt",
      "LABEL_END_DATE": "Endet",
      "LABEL_ABSENCE_TYPE": "Typ",
      "LABEL_SCOPE": "Dauer",
      "ERROR_500": "Wir sind auf einen kleinen Haken gestoßen. Bitte versuchen Sie es erneut.",
      "ERROR_404": "Dieser Abwesenheitsantrag besteht nicht mehr.",
      "LABEL_SUBMITTED_BY": "Eingereicht von",
      "LABEL_REVIEWED_BY": "Überprüft von",
      "LABEL_CANCELLED_BY": "Abgesagt von",
      "LABEL_EDITED_BY": "Herausgegeben von",
      "DURATION_TEXT": "{{Stunden}} Stunden, {{Tage}} Tage",
      "TAB_BREAKDOWN": "Aufschlüsselung",
      "TITLE_PAY_PERIODS": "Nach Zahlungszeitraum",
      "NO_PAY_PERIODS": "Dem Mitarbeiter war bei dieser Buchung kein Abrechnungskalender zugeordnet.",
      "TITLE_DAILY_BREAKDOWN": "Tagsüber",
      "NO_DAYS": "Diese Buchung enthält keine Tage.",
      "TAB_FILES": "Dokumente",
      "FILES_NONE": "Für diese Buchung wurde nichts hochgeladen.",
      "FILES_AVAILABLE": "Diese Dokumente finden Sie auch im Bereich Mitarbeiter, wenn Sie das Profil des Mitarbeiters einsehen. Du findest sie unter dieser Kategorie: {{categoryName}}",
      "FILES_COLUMN_NAME": "Name",
      "FILES_COLUMN_UPLOADED_BY": "Hochgeladen von",
      "FILES_COLUMN_OPTIONS": "Optionen",
      "FILES_LINK_DELETE": "Löschen",
      "FILES_CONFIRM_DELETE": "Bist du sicher, dass du das löschen möchtest? Es wird auch aus dem Tab Dokumente des Mitarbeiterprofils entfernt.",
      "CONFIRM_CANCEL": "Möchten Sie diese Abwesenheitsbuchung wirklich stornieren? Das kann nicht rückgängig gemacht werden.",
      "ALERT_IS_CANCELLED": "Die Abwesenheitsbuchung wurde storniert.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Warnung! Der Anforderer hat eine Start- und Endzeit gewählt, die mehr als {{HalfDayHours}} Stunden entspricht. Dies ist die Anzahl der Stunden, die er derzeit an einem halben Tag arbeitet.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Warnung! Der Antragsteller entschied sich dafür, die regulären Ruhetage zu ändern, anstatt die von Rotaready vorgeschlagenen Tage zu verwenden."
    },
    "EDIT": {
      "TITLE": "Abwesenheit",
      "BUTTON_SUBMIT": "Speichern",
      "BUTTON_APPROVE": "Genehmigen",
      "BUTTON_REJECT": "Ablehnen",
      "TAB_EDIT_BOOKING": "Termine bearbeiten",
      "TAB_REVIEW": "Bewertung",
      "TAB_FILES": "Dokumente",
      "TAB_CALENDAR": "Kalendarium",
      "LABEL_TYPE": "Typ",
      "LABEL_REASON": "Wähle einen Grund",
      "LABEL_EMPLOYEE": "Mitarbeiter",
      "LABEL_START_DATE": "Startdatum",
      "LABEL_END_DATE": "Enddatum",
      "LABEL_SCOPE_ALL_DAY": "Den ganzen Tag",
      "LABEL_SCOPE_AM": "Nur AM",
      "LABEL_SCOPE_PM": "Nur PM",
      "LABEL_SCOPE_CUSTOM": "Wählen Sie bestimmte Stunden",
      "LABEL_START_TIME": "Startzeit",
      "LABEL_END_TIME": "Endzeit",
      "ERROR_500": "In dem Moment sind wir auf einen Haken gestoßen. Bitte versuche es erneut!",
      "LABEL_DAYS": "Tage",
      "LABEL_HOURS": "Stunden",
      "SECTION_UNPAID_DAYS": "Reguläre Ruhetage",
      "HELP_TEXT_NO_UNPAID_DAYS": "In dieser Anfrage gibt es keine regulären Ruhetage.",
      "BUTTON_OPTIONS": "Optionen",
      "BUTTON_CHANGE": "Reguläre Ruhetage ändern",
      "BUTTON_RESET": "Zur ursprünglich gebuchten zurückkehren",
      "BUTTON_RECALCULATE": "Erneut berechnen",
      "BUTTON_CLEAR": "Alle regulären Ruhetage löschen",
      "UNPAID_DAYS_RECALCULATED_LABEL": "Neu berechnet",
      "UNPAID_DAYS_RECALCULATED_HELP": "Sie haben sich dafür entschieden, die regulären freien Tage neu zu berechnen und den aktuellen Wert für die „Stunden pro Arbeitstag“ des Mitarbeiters zu verwenden, anstatt den Wert zum Zeitpunkt der ursprünglichen Buchung.",
      "UNPAID_DAYS_MERGED_LABEL": "Zusammengeführt",
      "UNPAID_DAYS_MERGED_HELP": "Da Sie das Datum geändert haben, haben wir alle neuen freien Tage aufgenommen, alle, die nicht mehr in den Geltungsbereich fallen, entfernt und die anderen unangetastet gelassen.",
      "MODAL_CALENDAR_TITLE": "Wählen Sie reguläre Ruhetage",
      "MODAL_BUTTON_SAVE": "Speichern",
      "BUTTON_COMPLETE": "Bring mich zurück",
      "COMPLETE_TITLE": "Du bist startklar.",
      "COMPLETE_TEXT": "Wir haben die Abwesenheitsbuchung gespeichert und die entsprechenden Personen wurden benachrichtigt 👍",
      "INVALID_HEADER_TITLE": "Ungültige Abwesenheit",
      "INVALID_HEADER_TEXT": "Wir können diese Abwesenheitsbuchung nicht laden. Vielleicht wurde es abgesagt oder Sie haben keine Erlaubnis, es zu sehen.",
      "TITLE_OVERLAPPING_ABSENCE": "Gleichzeitige Abwesenheit",
      "TITLE_OVERLAPPING_SHIFTS": "Überlappende Schichten",
      "TITLE_NOTABLE_EVENTS": "Bemerkenswerte Ereignisse",
      "COLUMN_OVERLAPPING_ABSENCE_DATES": "Datteln",
      "COLUMN_OVERLAPPING_ABSENCE_TYPE": "Typ",
      "COLUMN_OVERLAPPING_ABSENCE_WHO": "Wer",
      "NO_OVERLAPPING_ABSENCE": "Es gibt keine gleichzeitige Abwesenheit, die Ihren Filtern entspricht.",
      "NO_OVERLAPPING_SHIFTS": "Es gibt keine überlappenden Schichten am Standort/in der Abteilung des Mitarbeiters.",
      "NO_NOTABLE_EVENTS": "Zum Zeitpunkt dieser Abwesenheit gibt es keine nennenswerten Ereignisse.",
      "COLUMN_OVERLAPPING_SHIFTS_DATE": "Datum",
      "COLUMN_OVERLAPPING_SHIFTS_SITE": "Standort/Abteilung",
      "COLUMN_OVERLAPPING_SHIFTS_TYPE": "Art der Schicht",
      "COLUMN_OVERLAPPING_SHIFTS_OPTIONS": "Optionen",
      "LINK_EDIT_SHIFT": "Rota bearbeiten",
      "LINK_DELETE_SHIFT": "Löschen",
      "BUTTON_BULK_SHIFT_OPTIONS": "Massenaktionen",
      "BUTTON_BULK_SHIFT_UNASSIGN_ALL": "Zuweisung der ausgewählten Auswahl aufheben",
      "BUTTON_BULK_SHIFT_DELETE_ALL": "Ausgewähltes löschen",
      "ALERT_BULK_SHIFT_CONFIRM": "Bist du sicher, dass du das für {{count}} Schichten tun möchtest?",
      "ALERT_BULK_SHIFT_EDIT_FAILURE": "Wir konnten die Schichten nicht ändern, da dies zu einem Validierungsproblem führen würde. Bitte nehmen Sie diese Änderung auf der Rota vor, um weitere Informationen zu erhalten.",
      "ALERT_SINGLE_SHIFT_DELETE_FAILURE": "Wir konnten diese Verschiebung nicht löschen, da sie zu einem Validierungsproblem führen würde. Bitte nehmen Sie diese Änderung auf der Rota vor, um weitere Informationen zu erhalten.",
      "COLUMN_NOTABLE_EVENTS_NAME": "Name",
      "COLUMN_NOTABLE_EVENTS_DATE": "Datum",
      "NOTABLE_EVENTS_ABSENCE_RESTRICTION_TOOLTIP": "Während dieser Veranstaltung ist die Abwesenheit eingeschränkt.",
      "LABEL_ADD_A_NOTE": "Schreiben Sie optional eine Nachricht für den Mitarbeiter",
      "OVERLAPPING_SHIFTS_MORE": "Wir zeigen nur die ersten fünfzehn überlappenden Schichten. Löse diese, um mehr zu sehen.",
      "OVERLAPPING_ABSENCE_MORE": "Wir zeigen nur die ersten fünfzehn sich überschneidenden Buchungen.",
      "CALENDAR_UNPAID_DAY": "Normaler Ruhetag",
      "FILTER_LABEL_ENTITY": "Standort/Abteilung",
      "FILTER_LABEL_STAFF_GROUP": "Gruppe der Mitarbeiter",
      "FILTER_LABEL_ABSENCE_TYPE": "Typ",
      "FILES_NONE": "Für diese Buchung wurde nichts hochgeladen.",
      "FILES_AVAILABLE": "Diese Dokumente finden Sie auch im Bereich Mitarbeiter, wenn Sie das Profil des Mitarbeiters einsehen. Du findest sie unter dieser Kategorie: {{categoryName}}",
      "FILES_COLUMN_NAME": "Name",
      "FILES_COLUMN_UPLOADED_BY": "Hochgeladen von",
      "FILES_COLUMN_OPTIONS": "Optionen",
      "FILES_LINK_DELETE": "Löschen",
      "FILES_CONFIRM_DELETE": "Bist du sicher, dass du das löschen möchtest? Es wird auch aus dem Tab Dokumente des Mitarbeiterprofils entfernt.",
      "ALERT_SUSPECT_CUSTOM_HOURS": "Warnung! Der Anforderer hat eine Start- und Endzeit gewählt, die mehr als {{HalfDayHours}} Stunden entspricht. Dies ist die Anzahl der Stunden, die er derzeit an einem halben Tag arbeitet.",
      "ALERT_DAYS_NOT_AS_SUGGESTED": "Warnung! Der Antragsteller entschied sich dafür, die regulären Ruhetage zu ändern, anstatt die von Rotaready vorgeschlagenen Tage zu verwenden. Bitte überprüfen Sie sie sorgfältig auf der Registerkarte „Daten bearbeiten“ und wählen Sie gegebenenfalls die Option „Neu berechnen“."
    },
    "ENUM_SCOPE": {
      "ALL_DAY": "Den ganzen Tag",
      "AM": "Nur AM",
      "PM": "Nur PM",
      "CUSTOM": "Benutzerdefinierte Stunden"
    },
    "ENUM_APPROVAL_STATE": {
      "APPROVED": "Genehmigt",
      "REJECTED": "Abgelehnt",
      "PENDING": "Ausstehend"
    },
    "ENUM_ALLOWANCE_UNIT": {
      "DAYS": "Tage",
      "HOURS": "Stunden"
    },
    "ENUM_ALLOWANCE_SCHEDULE_OF_ACCRUAL": {
      "BEGINNING_OF_ACCRUAL_YEAR": "Beginn des Abgrenzungsjahres",
      "EACH_CALENDAR_MONTH": "Jeder Kalendermonat",
      "EACH_HOUR_WORKED": "Jede gearbeitete Stunde"
    },
    "ALLOWANCE_IMPACT_DIRECTIVE": {
      "DAYS_DEBIT": "{{count}} Tagesabzug",
      "HOURS_DEBIT": "{{count}} Stundenabzug",
      "DAYS_CREDIT": "{{count}} Tagesguthaben",
      "HOURS_CREDIT": "{{count}} Stundenguthaben",
      "REMAINING": "({{count}} verbleibend)",
      "WARNING_EXCEEDS": "Ihre Anfrage übersteigt diesen Freibetrag, kann aber trotzdem eingereicht werden.",
      "WARNING_NOT_PERMITTED": "Ihre Anfrage übersteigt diesen Freibetrag.",
      "TOOLTIP_PROJECTED": "Rotaready hat eine Prognose darüber aufgenommen, was bei Ihnen wahrscheinlich anfallen wird. Dies kann gemäß den Richtlinien Ihres Arbeitgebers begrenzt sein.",
      "CALC_TOTAL_ACCRUED": "Aufgelaufener Gesamtbetrag",
      "CALC_BOOKED": "Gebucht",
      "CALC_PROJECTED": "Voraussichtlicher Rückstellungsbetrag",
      "CALC_THIS_REQUEST": "Diese Anfrage",
      "CALC_REMAINING": "Verbleibend",
      "CALC_CONVERTED_TO_DAYS": "Als Tage"
    }
  }
};

module.exports = translations;
