const services = require('./module');

const { RealmWideStaffListQuery } = require('../fragments');

services.factory('StaffService', ['$http', 'apollo', 'SessionService', 'ENDPOINT_API', '$translate',
  function ($http, apollo, SessionService, ENDPOINT_API, $translate) {
    const baseUrl = `${ENDPOINT_API}/staff/`;

    const translations = $translate.instant([
      'STAFF.CONSTANTS.PAY_AMOUNT_TYPE_HOURLY',
      'STAFF.CONSTANTS.PAY_AMOUNT_TYPE_DAILY',
      'STAFF.CONSTANTS.PAY_AMOUNT_TYPE_ANNUAL',
      'STAFF.CONSTANTS.PAY_FREQUENCY_WEEKLY',
      'STAFF.CONSTANTS.PAY_FREQUENCY_FORTNIGHTLY',
      'STAFF.CONSTANTS.PAY_FREQUENCY_MONTHLY',
      'STAFF.CONSTANTS.PAY_FREQUENCY_FOUR_WEEKLY',
      'STAFF.CONSTANTS.UK_STARTER_DECLARATION_A',
      'STAFF.CONSTANTS.UK_STARTER_DECLARATION_B',
      'STAFF.CONSTANTS.UK_STARTER_DECLARATION_C',
      'STAFF.CONSTANTS.UK_STUDENT_LOAN_PLAN_1',
      'STAFF.CONSTANTS.UK_STUDENT_LOAN_PLAN_2',
      'STAFF.CONSTANTS.UK_STUDENT_LOAN_PLAN_4',
      'STAFF.CONSTANTS.UK_STUDENT_LOAN_PLAN_5',
      'STAFF.CONSTANTS.UK_STUDENT_LOAN_PLAN_POSTGRADUATE',
      'STAFF.CONSTANTS.UK_SETTLED_STATUS_NA',
      'STAFF.CONSTANTS.UK_SETTLED_STATUS_PRE_SETTLED',
      'STAFF.CONSTANTS.UK_SETTLED_STATUS_SETTLED',
      'STAFF.CONSTANTS.MARITAL_STATUS_SINGLE',
      'STAFF.CONSTANTS.MARITAL_STATUS_MARRIED',
      'STAFF.CONSTANTS.MARITAL_STATUS_DIVORCED',
      'STAFF.CONSTANTS.MARITAL_STATUS_WIDOWED',
      'STAFF.CONSTANTS.MARITAL_STATUS_CIVIL_PARTNERSHIP',
      'STAFF.CONSTANTS.PAYMENT_METHOD_ELECTRONIC',
      'STAFF.CONSTANTS.PAYMENT_METHOD_CHEQUE',
      'STAFF.CONSTANTS.PAYMENT_METHOD_MANUAL',
      'STAFF.CONSTANTS.CONTRACTUAL_OBLIGATION_PERIOD_WEEK',
      'STAFF.CONSTANTS.CONTRACTUAL_OBLIGATION_PERIOD_MONTH',
      'STAFF.CONSTANTS.CONTRACTUAL_OBLIGATION_UNIT_DAYS',
      'STAFF.CONSTANTS.CONTRACTUAL_OBLIGATION_UNIT_HOURS',
    ]);

    return {
      search: (term, activeOnly, match, scope) => $http.get(`${baseUrl}search`, {
        params: {
          term,
          activeOnly,
          match,
          scope,
        },
      }),

      searchGraphQL: (term, hideSelf, isActive, date) => apollo.query({
        query: RealmWideStaffListQuery,
        variables: {
          name: term,
          hideSelf,
          isActive,
          date,
        },
      }),

      exportAccounts: (params, headers = {}) => $http({
        method: 'GET',
        url: `${baseUrl}export`,
        data: '', // angular removes the content-type header unless this is present
        params,
        headers,
      }),
      getAccounts: (params) => $http.get(`${baseUrl}account/paginated`, { params }),
      getAccount: (id) => $http.get(`${baseUrl}account/${id}`),
      updateAccount: (id, data) => $http.put(`${baseUrl}account/${id}`, data, {
        responseStatusAuthority: [403],
      }),
      approveAccount: (id, data) => $http.put(`${baseUrl}account/${id}/approve`, data),
      resendInstructions: (id) => $http.put(`${baseUrl}account/${id}/resendInstructions`),

      addSingleAccount: (data) => $http.post(`${baseUrl}account`, data, {
        responseStatusAuthority: [403],
      }),
      getDuplicateAccounts: (params) => $http.get(`${baseUrl}duplicate`, { params }),
      getEmployerSetupStatus: (userId) => $http.get(`${baseUrl}account/${userId}/employerSetupStatus`),
      getEmployments: (userId) => $http.get(`${baseUrl}account/${userId}/employment`, {
        responseStatusAuthority: [403],
      }),
      updateEmployment: (id, userId, data) => $http.put(
        `${baseUrl}account/${userId}/employment/${id}`, data),
      deleteEmployment: (id, userId) => $http.delete(`${baseUrl}account/${userId}/employment/${id}`),
      addEmployment: (userId, data) => $http.post(`${baseUrl}account/${userId}/employment`, data),
      getAppointments: (userId) => $http.get(`${baseUrl}account/${userId}/appointment`, {
        responseStatusAuthority: [403],
      }),
      updateAppointment: (id, userId, data) => $http.put(`${baseUrl}account/${userId}/appointment/${id}`, data),
      deleteAppointment: (id, userId) => $http.delete(`${baseUrl}account/${userId}/appointment/${id}`),
      addAppointment: (userId, data) => $http.post(`${baseUrl}account/${userId}/appointment`, data),
      getPay: (userId) => $http.get(`${baseUrl}account/${userId}/pay`, {
        responseStatusAuthority: [403],
      }),
      updatePay: (id, userId, data) => $http.put(`${baseUrl}account/${userId}/pay/${id}`, data, {
        responseStatusAuthority: [403],
      }),
      addPay: (userId, data) => $http.post(`${baseUrl}account/${userId}/pay`, data, {
        responseStatusAuthority: [403],
      }),
      deletePay: (id, userId) => $http.delete(`${baseUrl}account/${userId}/pay/${id}`, {
        responseStatusAuthority: [403],
      }),
      getPayValidity: (
        userId,
        effectiveDate,
        elementId,
      ) => $http.get(`${baseUrl}account/${userId}/pay/validity`, {
        params: {
          effectiveDate: effectiveDate.format('YYYY-MM-DD'),
          elementId,
        },
      }),

      getShiftRoutines() {
        return $http.get(`${baseUrl}shiftRoutines`);
      },
      getRoutineMappings(userId) {
        return $http.get(`${baseUrl}account/${userId}/routineMappings`, {
          responseStatusAuthority: [403],
        });
      },
      updateRoutineMapping(userId, id, data) {
        return $http.put(`${baseUrl}account/${userId}/routineMappings/${id}`, data);
      },
      addRoutineMapping(userId, data) {
        return $http.post(`${baseUrl}account/${userId}/routineMappings`, data);
      },
      deleteRoutineMapping(userId, id) {
        return $http.delete(`${baseUrl}account/${userId}/routineMappings/${id}`);
      },

      getTags() {
        return $http.get(`${baseUrl}tags`, {
          params: {
            entityId: SessionService.getEntity(),
          },
        });
      },
      addTag(data) {
        return $http.post(`${baseUrl}tag`, data);
      },
      updateTag(id, data) {
        return $http.put(`${baseUrl}tag/${id}`, data);
      },
      deleteTag(id) {
        return $http.delete(`${baseUrl}tag/${id}`);
      },
      getTag(id) {
        return $http.get(`${baseUrl}tag/${id}`);
      },
      filterStaffByTags(data) {
        return $http.post(`${baseUrl}filterStaffByTags`, data);
      },

      addGroup(data) {
        return $http.post(`${baseUrl}group`, data);
      },
      updateGroup(id, data) {
        return $http.put(`${baseUrl}group/${id}`, data);
      },
      deleteGroup(id, moveTo) {
        return $http.delete(`${baseUrl}group/${id}/${moveTo}`);
      },
      getGroup(id) {
        return $http.get(`${baseUrl}group/${id}`);
      },

      getOutsourcingOrganisations: () => $http.get(`${baseUrl}outsourcing`),
      getOutsourcingOrganisationTagAssociations: (id) => $http.get(`${baseUrl}outsourcing/${id}/tags`),
      updateOutsourcingOrganisation: (id, data) => $http.put(`${baseUrl}outsourcing/${id}`, data),
      updateOutsourcingOrganisationTagAssociations: (id, data) => $http.put(`${baseUrl}outsourcing/${id}/tags`, data),
      addOutsourcingOrganisation: (data) => $http.post(`${baseUrl}outsourcing`, data),

      addEmergencyContact: (userId, data) => $http.post(`${baseUrl}account/${userId}/emergencyContact`, data),
      getEmergencyContacts: (userId) => $http.get(`${baseUrl}account/${userId}/emergencyContact`, {
        responseStatusAuthority: [403],
      }),
      removeEmergencyContact: (userId, id) => $http.delete(`${baseUrl}account/${userId}/emergencyContact/${id}`),

      addAddress: (userId, data) => $http.post(`${baseUrl}account/${userId}/address`, data),
      updateAddress: (id, userId, data) => $http.put(`${baseUrl}account/${userId}/address/${id}`, data),
      removeAddress: (id, userId) => $http.delete(`${baseUrl}account/${userId}/address/${id}`),
      getAddresses: (userId) => $http.get(`${baseUrl}account/${userId}/address`),

      addVisa: (userId, data) => $http.post(`${baseUrl}account/${userId}/visas`, data),
      updateVisa: (userId, id, data) => $http.put(`${baseUrl}account/${userId}/visas/${id}`, data),
      getVisas: (userId) => $http.get(`${baseUrl}account/${userId}/visas`),

      addNote: (userId, data) => $http.post(`${baseUrl}account/${userId}/note`, data),
      updateNote: (id, userId, data) => $http.put(`${baseUrl}account/${userId}/note/${id}`, data),
      removeNote: (id, userId) => $http.delete(`${baseUrl}account/${userId}/note/${id}`),
      getNotes: (userId) => $http.get(`${baseUrl}account/${userId}/note`),

      getDocuments: (userId) => $http.get(`${ENDPOINT_API}/document/user/${userId}`, {
        responseStatusAuthority: [403],
      }),
      getDocumentCategoriesNotApplicable: (userId) => $http.get(`${ENDPOINT_API}/document/user/${userId}/notApplicable`, {
        responseStatusAuthority: [403],
      }),
      markDocumentCategoryNotApplicable: (userId, categoryId) => $http.put(
        `${ENDPOINT_API}/document/user/${userId}/notApplicable/${categoryId}`),
      markDocumentCategoryApplicable: (userId, categoryId) => $http.delete(
        `${ENDPOINT_API}/document/user/${userId}/notApplicable/${categoryId}`),
      deleteDocument: (id) => $http.delete(`${ENDPOINT_API}/document/${id}`),
      getDocument: (id, params) => $http.get(`${ENDPOINT_API}/document/${id}`, { params }),
      addDocument: (data) => $http.post(`${ENDPOINT_API}/document`, data),
      updateDocument: (id, data) => $http.put(`${ENDPOINT_API}/document/${id}`, data),
      getExpiringDocuments: (params) => $http.get(`${ENDPOINT_API}/document/expiring`, { params }),
      getDocumentSignedUrl: (params) => $http.get(`${ENDPOINT_API}/document/signedUrl`, { params }),
      getDocumentFriendlyFileSize: (fileSizeInKb) => {
        let convertedFileSize = `${fileSizeInKb} KB`;

        if (fileSizeInKb >= 1000) {
          convertedFileSize = `${Math.round(fileSizeInKb / 100) / 10} MB`;
        }

        return convertedFileSize;
      },
      getDocumentTemplate: (id, params) => $http.get(`${ENDPOINT_API}/document/template/${id}`, { params }),
      addDocumentTemplate: (data) => $http.post(`${ENDPOINT_API}/document/template`, data),
      deleteDocumentTemplate: (id) => $http.delete(`${ENDPOINT_API}/document/template/${id}`),
      exportDocumentPresence: (params, headers = {}) => $http({
        method: 'GET',
        url: `${ENDPOINT_API}/document/export/presence`,
        data: '', // angular removes the content-type header unless this is present
        params,
        headers,
      }),
      exportDocumentHeaders: (params, headers = {}) => $http({
        method: 'GET',
        url: `${ENDPOINT_API}/document/export/header`,
        data: '', // angular removes the content-type header unless this is present
        params,
        headers,
      }),

      updateAvatar: (userId, data) => $http.put(`${ENDPOINT_API}/avatar/${userId}`, data),
      removeAvatar: (userId) => $http.delete(`${ENDPOINT_API}/avatar/${userId}`),

      getPayAmountTypes: () => ([
        { id: 0, title: translations['STAFF.CONSTANTS.PAY_AMOUNT_TYPE_HOURLY'] },
        { id: 1, title: translations['STAFF.CONSTANTS.PAY_AMOUNT_TYPE_ANNUAL'] },
        { id: 2, title: translations['STAFF.CONSTANTS.PAY_AMOUNT_TYPE_DAILY'] },
      ]),
      getPayFrequencyOptions: () => ([
        { id: 1, title: translations['STAFF.CONSTANTS.PAY_FREQUENCY_WEEKLY'] },
        { id: 2, title: translations['STAFF.CONSTANTS.PAY_FREQUENCY_FORTNIGHTLY'] },
        { id: 3, title: translations['STAFF.CONSTANTS.PAY_FREQUENCY_MONTHLY'] },
        { id: 4, title: translations['STAFF.CONSTANTS.PAY_FREQUENCY_FOUR_WEEKLY'] },
      ]),
      getMaritalStatusTypes: () => ([
        { id: 1, title: translations['STAFF.CONSTANTS.MARITAL_STATUS_SINGLE'] },
        { id: 2, title: translations['STAFF.CONSTANTS.MARITAL_STATUS_MARRIED'] },
        { id: 3, title: translations['STAFF.CONSTANTS.MARITAL_STATUS_DIVORCED'] },
        { id: 4, title: translations['STAFF.CONSTANTS.MARITAL_STATUS_WIDOWED'] },
        { id: 5, title: translations['STAFF.CONSTANTS.MARITAL_STATUS_CIVIL_PARTNERSHIP'] },
      ]),
      getUkSettledStatusTypes: () => ([
        { id: 'NA', title: translations['STAFF.CONSTANTS.UK_SETTLED_STATUS_NA'] },
        { id: 'PRE_SETTLED', title: translations['STAFF.CONSTANTS.UK_SETTLED_STATUS_PRE_SETTLED'] },
        { id: 'SETTLED', title: translations['STAFF.CONSTANTS.UK_SETTLED_STATUS_SETTLED'] },
      ]),
      getPayrollPaymentMethods: () => ([
        { id: 'ELECTRONIC', title: translations['STAFF.CONSTANTS.PAYMENT_METHOD_ELECTRONIC'] },
        { id: 'CHEQUE', title: translations['STAFF.CONSTANTS.PAYMENT_METHOD_CHEQUE'] },
        { id: 'MANUAL', title: translations['STAFF.CONSTANTS.PAYMENT_METHOD_MANUAL'] },
      ]),
      getStudentLoanPlanTypes: () => ([
        { id: 'Type1', title: translations['STAFF.CONSTANTS.UK_STUDENT_LOAN_PLAN_1'] },
        { id: 'Type2', title: translations['STAFF.CONSTANTS.UK_STUDENT_LOAN_PLAN_2'] },
        { id: 'Type4', title: translations['STAFF.CONSTANTS.UK_STUDENT_LOAN_PLAN_4'] },
        { id: 'Type5', title: translations['STAFF.CONSTANTS.UK_STUDENT_LOAN_PLAN_5'] },
        { id: 'Postgraduate', title: translations['STAFF.CONSTANTS.UK_STUDENT_LOAN_PLAN_POSTGRADUATE'] },
      ]),
      getUkStarterDeclarationOptions: () => ([
        { id: 'A', label: translations['STAFF.CONSTANTS.UK_STARTER_DECLARATION_A'] },
        { id: 'B', label: translations['STAFF.CONSTANTS.UK_STARTER_DECLARATION_B'] },
        { id: 'C', label: translations['STAFF.CONSTANTS.UK_STARTER_DECLARATION_C'] },
      ]),
      getContractualObligationPeriods: () => ([
        { id: 0, title: translations['STAFF.CONSTANTS.CONTRACTUAL_OBLIGATION_PERIOD_WEEK'] },
        { id: 1, title: translations['STAFF.CONSTANTS.CONTRACTUAL_OBLIGATION_PERIOD_MONTH'] },
      ]),
      getContractualObligationUnits: () => ([
        { id: 0, title: translations['STAFF.CONSTANTS.CONTRACTUAL_OBLIGATION_UNIT_HOURS'] },
        { id: 1, title: translations['STAFF.CONSTANTS.CONTRACTUAL_OBLIGATION_UNIT_DAYS'] },
      ]),
      getCountryList: () => ([
        { id: 'AF', title: 'Afghanistan' },
        { id: 'AX', title: 'Åland Islands' },
        { id: 'AL', title: 'Albania' },
        { id: 'DZ', title: 'Algeria' },
        { id: 'AS', title: 'American Samoa' },
        { id: 'AD', title: 'Andorra' },
        { id: 'AO', title: 'Angola' },
        { id: 'AI', title: 'Anguilla' },
        { id: 'AQ', title: 'Antarctica' },
        { id: 'AG', title: 'Antigua and Barbuda' },
        { id: 'AR', title: 'Argentina' },
        { id: 'AM', title: 'Armenia' },
        { id: 'AW', title: 'Aruba' },
        { id: 'AU', title: 'Australia' },
        { id: 'AT', title: 'Austria' },
        { id: 'AZ', title: 'Azerbaijan' },
        { id: 'BS', title: 'Bahamas' },
        { id: 'BH', title: 'Bahrain' },
        { id: 'BD', title: 'Bangladesh' },
        { id: 'BB', title: 'Barbados' },
        { id: 'BY', title: 'Belarus' },
        { id: 'BE', title: 'Belgium' },
        { id: 'BZ', title: 'Belize' },
        { id: 'BJ', title: 'Benin' },
        { id: 'BM', title: 'Bermuda' },
        { id: 'BT', title: 'Bhutan' },
        { id: 'BO', title: 'Bolivia (Plurinational State of)' },
        { id: 'BQ', title: 'Bonaire, Sint Eustatius and Saba' },
        { id: 'BA', title: 'Bosnia and Herzegovina' },
        { id: 'BW', title: 'Botswana' },
        { id: 'BV', title: 'Bouvet Island' },
        { id: 'BR', title: 'Brazil' },
        { id: 'IO', title: 'British Indian Ocean Territory' },
        { id: 'BN', title: 'Brunei Darussalam' },
        { id: 'BG', title: 'Bulgaria' },
        { id: 'BF', title: 'Burkina Faso' },
        { id: 'BI', title: 'Burundi' },
        { id: 'CV', title: 'Cabo Verde' },
        { id: 'KH', title: 'Cambodia' },
        { id: 'CM', title: 'Cameroon' },
        { id: 'CA', title: 'Canada' },
        { id: 'KY', title: 'Cayman Islands' },
        { id: 'CF', title: 'Central African Republic' },
        { id: 'TD', title: 'Chad' },
        { id: 'CL', title: 'Chile' },
        { id: 'CN', title: 'China' },
        { id: 'CX', title: 'Christmas Island' },
        { id: 'CC', title: 'Cocos (Keeling) Islands' },
        { id: 'CO', title: 'Colombia' },
        { id: 'KM', title: 'Comoros' },
        { id: 'CG', title: 'Congo' },
        { id: 'CD', title: 'Congo, Democratic Republic of the' },
        { id: 'CK', title: 'Cook Islands' },
        { id: 'CR', title: 'Costa Rica' },
        { id: 'CI', title: 'Côte d\'Ivoire' },
        { id: 'HR', title: 'Croatia' },
        { id: 'CU', title: 'Cuba' },
        { id: 'CW', title: 'Curaçao' },
        { id: 'CY', title: 'Cyprus' },
        { id: 'CZ', title: 'Czechia' },
        { id: 'DK', title: 'Denmark' },
        { id: 'DJ', title: 'Djibouti' },
        { id: 'DM', title: 'Dominica' },
        { id: 'DO', title: 'Dominican Republic' },
        { id: 'EC', title: 'Ecuador' },
        { id: 'EG', title: 'Egypt' },
        { id: 'SV', title: 'El Salvador' },
        { id: 'GQ', title: 'Equatorial Guinea' },
        { id: 'ER', title: 'Eritrea' },
        { id: 'EE', title: 'Estonia' },
        { id: 'SZ', title: 'Eswatini' },
        { id: 'ET', title: 'Ethiopia' },
        { id: 'FK', title: 'Falkland Islands' },
        { id: 'FO', title: 'Faroe Islands' },
        { id: 'FJ', title: 'Fiji' },
        { id: 'FI', title: 'Finland' },
        { id: 'FR', title: 'France' },
        { id: 'GF', title: 'French Guiana' },
        { id: 'PF', title: 'French Polynesia' },
        { id: 'TF', title: 'French Southern Territories' },
        { id: 'GA', title: 'Gabon' },
        { id: 'GM', title: 'Gambia' },
        { id: 'GE', title: 'Georgia' },
        { id: 'DE', title: 'Germany' },
        { id: 'GH', title: 'Ghana' },
        { id: 'GI', title: 'Gibraltar' },
        { id: 'GR', title: 'Greece' },
        { id: 'GL', title: 'Greenland' },
        { id: 'GD', title: 'Grenada' },
        { id: 'GP', title: 'Guadeloupe' },
        { id: 'GU', title: 'Guam' },
        { id: 'GT', title: 'Guatemala' },
        { id: 'GG', title: 'Guernsey' },
        { id: 'GN', title: 'Guinea' },
        { id: 'GW', title: 'Guinea-Bissau' },
        { id: 'GY', title: 'Guyana' },
        { id: 'HT', title: 'Haiti' },
        { id: 'HM', title: 'Heard Island and McDonald Islands' },
        { id: 'VA', title: 'Holy See' },
        { id: 'HN', title: 'Honduras' },
        { id: 'HK', title: 'Hong Kong' },
        { id: 'HU', title: 'Hungary' },
        { id: 'IS', title: 'Iceland' },
        { id: 'IN', title: 'India' },
        { id: 'ID', title: 'Indonesia' },
        { id: 'IR', title: 'Iran (Islamic Republic of)' },
        { id: 'IQ', title: 'Iraq' },
        { id: 'IE', title: 'Ireland' },
        { id: 'IM', title: 'Isle of Man' },
        { id: 'IL', title: 'Israel' },
        { id: 'IT', title: 'Italy' },
        { id: 'JM', title: 'Jamaica' },
        { id: 'JP', title: 'Japan' },
        { id: 'JE', title: 'Jersey' },
        { id: 'JO', title: 'Jordan' },
        { id: 'KZ', title: 'Kazakhstan' },
        { id: 'KE', title: 'Kenya' },
        { id: 'KI', title: 'Kiribati' },
        { id: 'KP', title: 'Korea (Democratic People\'s Republic of)' },
        { id: 'KR', title: 'Korea, Republic of' },
        { id: 'KW', title: 'Kuwait' },
        { id: 'KG', title: 'Kyrgyzstan' },
        { id: 'LA', title: 'Lao People\'s Democratic Republic' },
        { id: 'LV', title: 'Latvia' },
        { id: 'LB', title: 'Lebanon' },
        { id: 'LS', title: 'Lesotho' },
        { id: 'LR', title: 'Liberia' },
        { id: 'LY', title: 'Libya' },
        { id: 'LI', title: 'Liechtenstein' },
        { id: 'LT', title: 'Lithuania' },
        { id: 'LU', title: 'Luxembourg' },
        { id: 'MO', title: 'Macao' },
        { id: 'MG', title: 'Madagascar' },
        { id: 'MW', title: 'Malawi' },
        { id: 'MY', title: 'Malaysia' },
        { id: 'MV', title: 'Maldives' },
        { id: 'ML', title: 'Mali' },
        { id: 'MT', title: 'Malta' },
        { id: 'MH', title: 'Marshall Islands' },
        { id: 'MQ', title: 'Martinique' },
        { id: 'MR', title: 'Mauritania' },
        { id: 'MU', title: 'Mauritius' },
        { id: 'YT', title: 'Mayotte' },
        { id: 'MX', title: 'Mexico' },
        { id: 'FM', title: 'Micronesia (Federated States of)' },
        { id: 'MD', title: 'Moldova, Republic of' },
        { id: 'MC', title: 'Monaco' },
        { id: 'MN', title: 'Mongolia' },
        { id: 'ME', title: 'Montenegro' },
        { id: 'MS', title: 'Montserrat' },
        { id: 'MA', title: 'Morocco' },
        { id: 'MZ', title: 'Mozambique' },
        { id: 'MM', title: 'Myanmar' },
        { id: 'NA', title: 'Namibia' },
        { id: 'NR', title: 'Nauru' },
        { id: 'NP', title: 'Nepal' },
        { id: 'NL', title: 'Netherlands' },
        { id: 'NC', title: 'New Caledonia' },
        { id: 'NZ', title: 'New Zealand' },
        { id: 'NI', title: 'Nicaragua' },
        { id: 'NE', title: 'Niger' },
        { id: 'NG', title: 'Nigeria' },
        { id: 'NU', title: 'Niue' },
        { id: 'NF', title: 'Norfolk Island' },
        { id: 'MK', title: 'North Macedonia' },
        { id: 'MP', title: 'Northern Mariana Islands' },
        { id: 'NO', title: 'Norway' },
        { id: 'OM', title: 'Oman' },
        { id: 'PK', title: 'Pakistan' },
        { id: 'PW', title: 'Palau' },
        { id: 'PS', title: 'Palestine, State of' },
        { id: 'PA', title: 'Panama' },
        { id: 'PG', title: 'Papua New Guinea' },
        { id: 'PY', title: 'Paraguay' },
        { id: 'PE', title: 'Peru' },
        { id: 'PH', title: 'Philippines' },
        { id: 'PN', title: 'Pitcairn' },
        { id: 'PL', title: 'Poland' },
        { id: 'PT', title: 'Portugal' },
        { id: 'PR', title: 'Puerto Rico' },
        { id: 'QA', title: 'Qatar' },
        { id: 'RE', title: 'Réunion' },
        { id: 'RO', title: 'Romania' },
        { id: 'RU', title: 'Russian Federation' },
        { id: 'RW', title: 'Rwanda' },
        { id: 'BL', title: 'Saint Barthélemy' },
        { id: 'SH', title: 'Saint Helena, Ascension and Tristan da Cunha' },
        { id: 'KN', title: 'Saint Kitts and Nevis' },
        { id: 'LC', title: 'Saint Lucia' },
        { id: 'MF', title: 'Saint Martin (French part)' },
        { id: 'PM', title: 'Saint Pierre and Miquelon' },
        { id: 'VC', title: 'Saint Vincent and the Grenadines' },
        { id: 'WS', title: 'Samoa' },
        { id: 'SM', title: 'San Marino' },
        { id: 'ST', title: 'Sao Tome and Principe' },
        { id: 'SA', title: 'Saudi Arabia' },
        { id: 'SN', title: 'Senegal' },
        { id: 'RS', title: 'Serbia' },
        { id: 'SC', title: 'Seychelles' },
        { id: 'SL', title: 'Sierra Leone' },
        { id: 'SG', title: 'Singapore' },
        { id: 'SX', title: 'Sint Maarten (Dutch part)' },
        { id: 'SK', title: 'Slovakia' },
        { id: 'SI', title: 'Slovenia' },
        { id: 'SB', title: 'Solomon Islands' },
        { id: 'SO', title: 'Somalia' },
        { id: 'ZA', title: 'South Africa' },
        { id: 'GS', title: 'South Georgia and the South Sandwich Islands' },
        { id: 'SS', title: 'South Sudan' },
        { id: 'ES', title: 'Spain' },
        { id: 'LK', title: 'Sri Lanka' },
        { id: 'SD', title: 'Sudan' },
        { id: 'SR', title: 'Suriname' },
        { id: 'SJ', title: 'Svalbard and Jan Mayen' },
        { id: 'SE', title: 'Sweden' },
        { id: 'CH', title: 'Switzerland' },
        { id: 'SY', title: 'Syrian Arab Republic' },
        { id: 'TW', title: 'Taiwan, Province of China' },
        { id: 'TJ', title: 'Tajikistan' },
        { id: 'TZ', title: 'Tanzania, United Republic of' },
        { id: 'TH', title: 'Thailand' },
        { id: 'TL', title: 'Timor-Leste' },
        { id: 'TG', title: 'Togo' },
        { id: 'TK', title: 'Tokelau' },
        { id: 'TO', title: 'Tonga' },
        { id: 'TT', title: 'Trinidad and Tobago' },
        { id: 'TN', title: 'Tunisia' },
        { id: 'TR', title: 'Turkey' },
        { id: 'TM', title: 'Turkmenistan' },
        { id: 'TC', title: 'Turks and Caicos Islands' },
        { id: 'TV', title: 'Tuvalu' },
        { id: 'UG', title: 'Uganda' },
        { id: 'UA', title: 'Ukraine' },
        { id: 'AE', title: 'United Arab Emirates' },
        { id: 'GB', title: 'United Kingdom' },
        { id: 'US', title: 'United States of America' },
        { id: 'UM', title: 'United States Minor Outlying Islands' },
        { id: 'UY', title: 'Uruguay' },
        { id: 'UZ', title: 'Uzbekistan' },
        { id: 'VU', title: 'Vanuatu' },
        { id: 'VE', title: 'Venezuela (Bolivarian Republic of)' },
        { id: 'VN', title: 'Viet Nam' },
        { id: 'VG', title: 'Virgin Islands (British)' },
        { id: 'VI', title: 'Virgin Islands (U.S.)' },
        { id: 'WF', title: 'Wallis and Futuna' },
        { id: 'EH', title: 'Western Sahara' },
        { id: 'YE', title: 'Yemen' },
        { id: 'ZM', title: 'Zambia' },
        { id: 'ZW', title: 'Zimbabwe' },
      ]),
      getNationalityTypes: () => ([
        { id: 225, title: 'Afghan' },
        { id: 1, title: 'Albanian' },
        { id: 2, title: 'Algerian' },
        { id: 3, title: 'American' },
        { id: 4, title: 'Andorran' },
        { id: 5, title: 'Angolan' },
        { id: 6, title: 'Anguillan' },
        { id: 7, title: 'Argentine' },
        { id: 8, title: 'Armenian' },
        { id: 9, title: 'Australian' },
        { id: 10, title: 'Austrian' },
        { id: 11, title: 'Azerbaijani' },
        { id: 12, title: 'Bahamian' },
        { id: 13, title: 'Bahraini' },
        { id: 14, title: 'Bangladeshi' },
        { id: 15, title: 'Barbadian' },
        { id: 16, title: 'Belarusian' },
        { id: 17, title: 'Belgian' },
        { id: 18, title: 'Belizean' },
        { id: 19, title: 'Beninese' },
        { id: 20, title: 'Bermudian' },
        { id: 21, title: 'Bhutanese' },
        { id: 22, title: 'Bolivian' },
        { id: 23, title: 'Botswanan' },
        { id: 24, title: 'Brazilian' },
        { id: 25, title: 'British' },
        { id: 26, title: 'British Virgin Islander' },
        { id: 27, title: 'Bruneian' },
        { id: 28, title: 'Bulgarian' },
        { id: 29, title: 'Burkinan' },
        { id: 30, title: 'Burmese' },
        { id: 31, title: 'Burundian' },
        { id: 32, title: 'Cambodian' },
        { id: 33, title: 'Cameroonian' },
        { id: 34, title: 'Canadian' },
        { id: 35, title: 'Cape Verdean' },
        { id: 36, title: 'Cayman Islander' },
        { id: 37, title: 'Central African' },
        { id: 38, title: 'Chadian' },
        { id: 39, title: 'Chilean' },
        { id: 40, title: 'Chinese' },
        { id: 41, title: 'Citizen of Antigua and Barbuda' },
        { id: 42, title: 'Citizen of Bosnia and Herzegovina' },
        { id: 43, title: 'Citizen of Guinea-Bissau' },
        { id: 44, title: 'Citizen of Kiribati' },
        { id: 45, title: 'Citizen of Seychelles' },
        { id: 46, title: 'Citizen of the Dominican Republic' },
        { id: 47, title: 'Citizen of Vanuatu' },
        { id: 48, title: 'Colombian' },
        { id: 49, title: 'Comoran' },
        { id: 50, title: 'Congolese (Congo)' },
        { id: 51, title: 'Congolese (DRC)' },
        { id: 52, title: 'Cook Islander' },
        { id: 53, title: 'Costa Rican' },
        { id: 54, title: 'Croatian' },
        { id: 55, title: 'Cuban' },
        { id: 56, title: 'Cymraes' },
        { id: 57, title: 'Cymro' },
        { id: 58, title: 'Cypriot' },
        { id: 59, title: 'Czech' },
        { id: 60, title: 'Danish' },
        { id: 61, title: 'Djiboutian' },
        { id: 62, title: 'Dominican' },
        { id: 63, title: 'Dutch' },
        { id: 64, title: 'East Timorese' },
        { id: 65, title: 'Ecuadorean' },
        { id: 66, title: 'Egyptian' },
        { id: 67, title: 'Emirati' },
        { id: 68, title: 'English' },
        { id: 69, title: 'Equatorial Guinean' },
        { id: 70, title: 'Eritrean' },
        { id: 71, title: 'Estonian' },
        { id: 72, title: 'Ethiopian' },
        { id: 73, title: 'Faroese' },
        { id: 74, title: 'Fijian' },
        { id: 75, title: 'Filipino' },
        { id: 76, title: 'Finnish' },
        { id: 77, title: 'French' },
        { id: 78, title: 'Gabonese' },
        { id: 79, title: 'Gambian' },
        { id: 80, title: 'Georgian' },
        { id: 81, title: 'German' },
        { id: 82, title: 'Ghanaian' },
        { id: 83, title: 'Gibraltarian' },
        { id: 84, title: 'Greek' },
        { id: 85, title: 'Greenlandic' },
        { id: 86, title: 'Grenadian' },
        { id: 87, title: 'Guamanian' },
        { id: 88, title: 'Guatemalan' },
        { id: 89, title: 'Guinean' },
        { id: 90, title: 'Guyanese' },
        { id: 91, title: 'Haitian' },
        { id: 92, title: 'Honduran' },
        { id: 93, title: 'Hong Konger' },
        { id: 94, title: 'Hungarian' },
        { id: 95, title: 'Icelandic' },
        { id: 96, title: 'Indian' },
        { id: 97, title: 'Indonesian' },
        { id: 98, title: 'Iranian' },
        { id: 99, title: 'Iraqi' },
        { id: 100, title: 'Irish' },
        { id: 101, title: 'Israeli' },
        { id: 102, title: 'Italian' },
        { id: 103, title: 'Ivorian' },
        { id: 104, title: 'Jamaican' },
        { id: 105, title: 'Japanese' },
        { id: 106, title: 'Jordanian' },
        { id: 107, title: 'Kazakh' },
        { id: 108, title: 'Kenyan' },
        { id: 109, title: 'Kittitian' },
        { id: 110, title: 'Kosovan' },
        { id: 111, title: 'Kuwaiti' },
        { id: 112, title: 'Kyrgyz' },
        { id: 113, title: 'Lao' },
        { id: 114, title: 'Latvian' },
        { id: 115, title: 'Lebanese' },
        { id: 116, title: 'Liberian' },
        { id: 117, title: 'Libyan' },
        { id: 118, title: 'Liechtenstein citizen' },
        { id: 119, title: 'Lithuanian' },
        { id: 120, title: 'Luxembourger' },
        { id: 121, title: 'Macanese' },
        { id: 122, title: 'Macedonian' },
        { id: 123, title: 'Malagasy' },
        { id: 124, title: 'Malawian' },
        { id: 125, title: 'Malaysian' },
        { id: 126, title: 'Maldivian' },
        { id: 127, title: 'Malian' },
        { id: 128, title: 'Maltese' },
        { id: 129, title: 'Marshallese' },
        { id: 130, title: 'Martiniquais' },
        { id: 131, title: 'Mauritanian' },
        { id: 132, title: 'Mauritian' },
        { id: 133, title: 'Mexican' },
        { id: 134, title: 'Micronesian' },
        { id: 135, title: 'Moldovan' },
        { id: 136, title: 'Monegasque' },
        { id: 137, title: 'Mongolian' },
        { id: 138, title: 'Montenegrin' },
        { id: 139, title: 'Montserratian' },
        { id: 140, title: 'Moroccan' },
        { id: 141, title: 'Mosotho' },
        { id: 142, title: 'Mozambican' },
        { id: 143, title: 'Namibian' },
        { id: 144, title: 'Nauruan' },
        { id: 145, title: 'Nepalese' },
        { id: 146, title: 'New Zealander' },
        { id: 147, title: 'Nicaraguan' },
        { id: 148, title: 'Nigerian' },
        { id: 149, title: 'Nigerien' },
        { id: 150, title: 'Niuean' },
        { id: 151, title: 'North Korean' },
        { id: 152, title: 'Northern Irish' },
        { id: 153, title: 'Norwegian' },
        { id: 154, title: 'Omani' },
        { id: 155, title: 'Pakistani' },
        { id: 156, title: 'Palauan' },
        { id: 157, title: 'Palestinian' },
        { id: 158, title: 'Panamanian' },
        { id: 159, title: 'Papua New Guinean' },
        { id: 160, title: 'Paraguayan' },
        { id: 161, title: 'Peruvian' },
        { id: 162, title: 'Pitcairn Islander' },
        { id: 163, title: 'Polish' },
        { id: 164, title: 'Portuguese' },
        { id: 165, title: 'Prydeinig' },
        { id: 166, title: 'Puerto Rican' },
        { id: 167, title: 'Qatari' },
        { id: 168, title: 'Romanian' },
        { id: 169, title: 'Russian' },
        { id: 170, title: 'Rwandan' },
        { id: 171, title: 'Salvadorean' },
        { id: 172, title: 'Sammarinese' },
        { id: 173, title: 'Samoan' },
        { id: 174, title: 'Sao Tomean' },
        { id: 175, title: 'Saudi Arabian' },
        { id: 176, title: 'Scottish' },
        { id: 177, title: 'Senegalese' },
        { id: 178, title: 'Serbian' },
        { id: 179, title: 'Sierra Leonean' },
        { id: 180, title: 'Singaporean' },
        { id: 181, title: 'Slovak' },
        { id: 182, title: 'Slovenian' },
        { id: 183, title: 'Solomon Islander' },
        { id: 184, title: 'Somali' },
        { id: 185, title: 'South African' },
        { id: 186, title: 'South Korean' },
        { id: 187, title: 'South Sudanese' },
        { id: 188, title: 'Spanish' },
        { id: 189, title: 'Sri Lankan' },
        { id: 190, title: 'St Helenian' },
        { id: 191, title: 'St Lucian' },
        { id: 192, title: 'Stateless' },
        { id: 193, title: 'Sudanese' },
        { id: 194, title: 'Surinamese' },
        { id: 195, title: 'Swazi' },
        { id: 196, title: 'Swedish' },
        { id: 197, title: 'Swiss' },
        { id: 198, title: 'Syrian' },
        { id: 199, title: 'Taiwanese' },
        { id: 200, title: 'Tajik' },
        { id: 201, title: 'Tanzanian' },
        { id: 202, title: 'Thai' },
        { id: 203, title: 'Togolese' },
        { id: 204, title: 'Tongan' },
        { id: 205, title: 'Trinidadian' },
        { id: 206, title: 'Tristanian' },
        { id: 207, title: 'Tunisian' },
        { id: 208, title: 'Turkish' },
        { id: 209, title: 'Turkmen' },
        { id: 210, title: 'Turks and Caicos Islander' },
        { id: 211, title: 'Tuvaluan' },
        { id: 212, title: 'Ugandan' },
        { id: 213, title: 'Ukrainian' },
        { id: 214, title: 'Uruguayan' },
        { id: 215, title: 'Uzbek' },
        { id: 216, title: 'Vatican citizen' },
        { id: 217, title: 'Venezuelan' },
        { id: 218, title: 'Vietnamese' },
        { id: 219, title: 'Vincentian' },
        { id: 220, title: 'Wallisian' },
        { id: 221, title: 'Welsh' },
        { id: 222, title: 'Yemeni' },
        { id: 223, title: 'Zambian' },
        { id: 224, title: 'Zimbabwean' },
      ]),
      getEntityGroupSubscriptions: (userId) => $http.get(`${ENDPOINT_API}/entity-group-subscriptions/${userId}`),
    };
  }]);
