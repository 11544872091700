module.exports = (
  $scope,
  $window,
  $translate,
  $uibModalInstance,
  StaffService,
  AlertService,
  Upload,
  data,
) => {
  'ngInject';

  const {
    userId,
    pictureUrl,
  } = data;

  const defaultFileName = 'avatar.jpg';
  const allowedMimeTypes = ['image/jpeg', 'image/png'];

  const translations = $translate.instant([
    'OTHER.EDIT_AVATAR_MODAL.ALERT_REMOVE',
    'OTHER.EDIT_AVATAR_MODAL.ALERT_FILE_TYPE_NOT_ALLOWED',
    'OTHER.EDIT_AVATAR_MODAL.ALERT_ERROR_500',
    'OTHER.EDIT_AVATAR_MODAL.ALERT_SUCCESS_ADD',
    'OTHER.EDIT_AVATAR_MODAL.ALERT_SUCCESS_REMOVE',
  ]);

  $scope.props = {
    pictureUrl,
    rawImage: '',
    croppedImageDataUrl: '',
    croppedImage: '',
    resultImageFormat: 'image/jpeg',
    resultImageSize: {
      w: 300,
      h: 300,
    },
  };

  function handleError() {
    $scope.props.actionInProgress = false;
    AlertService.add('danger', translations['OTHER.EDIT_AVATAR_MODAL.ALERT_ERROR_500']);
  }

  const handleFileSelect = (evt) => {
    const file = evt.currentTarget.files[0];

    if (!allowedMimeTypes.includes(file.type)) {
      $window.alert(translations['OTHER.EDIT_AVATAR_MODAL.ALERT_FILE_TYPE_NOT_ALLOWED']);
      return;
    }

    const reader = new FileReader();

    reader.onload = (evt) => {
      $scope.$apply(($scope) => {
        $scope.props.rawImage = evt.target.result;
      });
    };
    reader.readAsDataURL(file);
  };

  $scope.change = () => {
    if ($scope.props.rawImage) {
      return;
    }

    angular.element($window.document.querySelector('#file-upload')).on('change', handleFileSelect);
  };

  $scope.remove = () => {
    if (!$window.confirm(translations['OTHER.EDIT_AVATAR_MODAL.ALERT_REMOVE'])) {
      return;
    }

    if ($scope.props.actionInProgress) {
      return;
    }

    $scope.props.actionInProgress = true;

    StaffService.removeAvatar(userId)
      .then(() => {
        AlertService.add('success', translations['OTHER.EDIT_AVATAR_MODAL.ALERT_SUCCESS_REMOVE']);
        $uibModalInstance.close({
          pictureUrl: null,
        });
      })
      .catch(({ status }) => handleError(status));
  };

  $scope.close = () => $uibModalInstance.dismiss();

  $scope.save = () => {
    const {
      actionInProgress,
      croppedImage,
    } = $scope.props;

    if (actionInProgress || !croppedImage) {
      return;
    }

    $scope.props.actionInProgress = true;

    StaffService.getDocumentSignedUrl({
      fileName: defaultFileName,
    })
      .then(({ data }) => {
        const {
          uploadUrl,
          contentType,
          contentDisposition,
          key,
        } = data;

        const upload = Upload.http({
          url: uploadUrl,
          method: 'PUT',
          data: croppedImage,
          headers: {
            'Content-Type': contentType,
            'Content-Disposition': contentDisposition,
          },
          // prevent AuthService adding Authorization header (which S3 won't like)
          noIntercept: true,
        });

        upload.then(() => {
          StaffService.updateAvatar(userId, {
            key,
            fileName: defaultFileName,
          })
            .then(({ data: updateResult }) => {
              AlertService.add('success', translations['OTHER.EDIT_AVATAR_MODAL.ALERT_SUCCESS_ADD']);
              $uibModalInstance.close({
                pictureUrl: updateResult.pictureUrl,
              });
            })
            .catch(({ status }) => handleError(status));
        }, () => handleError());
      })
      .catch(({ status }) => handleError(status));
  };
};
