const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/address/drawer.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/staff/view/visas/drawer.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/staff/view/visas/drawer.html');

module.exports = (
  $rootScope,
  $scope,
  $translate,
  $uibModal,
  $uibModalDrawer,
  AlertService,
  EnvironmentDataService,
  StaffService,
  AuthService,
  SessionService,
  StaffCommon,
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.VIEW.PERSONAL.SAVE_SUCCESS',
    'STAFF.VIEW.PERSONAL.ERROR_403',
    'STAFF.VIEW.PERSONAL.ERROR_500',
    'STAFF.VIEW.VISAS.SHOW_PREVIOUS_BTN',
    'STAFF.VIEW.VISAS.HIDE_PREVIOUS_BTN',
    'STAFF.VIEW.VISAS.PREVIOUS_HEADING',
    'STAFF.VIEW.VISAS.VISA_EXPIRY_NOT_APPLICABLE',
  ]);

  const {
    userData: {
      id: userId,
      firstName,
      lastName,
      preferredName,
      personal,
      extra: { hrId, payrollId },
      flags: { emailLocked, approvedLastEdited },
      account: { locale, lastEdited },
      visas,
    },
  } = $scope;

  const { currentVisa, previousVisas } = filterVisas(visas);

  $scope.areVisasEnabled = SessionService.areVisasEnabled();

  $scope.props = {
    loadingData: true,
    permitted: !!personal,
    canModifyEmail:
      !emailLocked ||
      (userId === SessionService.getUserId() &&
        AuthService.isRotareadyLoginEnabled()),
    nationalityList: StaffService.getNationalityTypes().map(
      ({ id, title, deleted }) => ({ id, label: title, deleted })
    ),
    maritalStatusList: StaffService.getMaritalStatusTypes().map(
      ({ id, title, deleted }) => ({ id, label: title, deleted })
    ),
    ukSettledStatusList: StaffService.getUkSettledStatusTypes().map(
      ({ id, title, deleted }) => ({ id, label: title, deleted })
    ),
    formData: {
      firstName,
      lastName,
      preferredName,
      hrId,
      payrollId,
    },
    lastEdited,
    approvedLastEdited,
    userId,
    visas: {
      current: currentVisa,
      previous: previousVisas,
      showPrevious: null,
    },
    ukShareCodeErrors: {
      format: false,
      length: false,
    },
  };

  if (personal) {
    const {
      dob,
      email,
      mobile,
      titleId,
      genderId,
      otherNames,
      nationalityId,
      workPhone,
      workEmail,
      ukSettledStatus,
      ethnicityId,
      maritalStatusId,
      addressLine1,
      addressLine2,
      addressCity,
      addressCounty,
      addressPostCode,
      addressCountry,
      passportNumber,
      passportExpiryDate,
      ukRightToWorkShareCode,
      ukRightToWorkShareCodeExpiryDate,
    } = personal;

    Object.assign($scope.props.formData, {
      birthDate: moment(dob),
      email,
      mobile,
      titleId,
      genderId,
      otherNames,
      nationalityId,
      workPhone,
      workEmail,
      ukSettledStatus,
      ethnicityId,
      maritalStatusId,
      passportNumber,
      passportExpiryDate: passportExpiryDate
        ? moment(passportExpiryDate)
        : null,
      ukRightToWorkShareCode: formatDisplayShareCode(ukRightToWorkShareCode),
      ukRightToWorkShareCodeExpiryDate,
    });

    $scope.props.currentAddress =
      addressLine1 && (addressCity || addressPostCode)
        ? {
            line1: addressLine1,
            line2: addressLine2,
            city: addressCity,
            county: addressCounty,
            postCode: addressPostCode,
            country: addressCountry,
          }
        : undefined;
  }

  $scope.loadAddressHistory = () => {
    $scope.props.addressHistory = undefined;
    $scope.props.addressHistoryActive = true;

    StaffService.getAddresses(userId).then(({ data }) => {
      $scope.props.addressHistory = data.results;
    });
  };

  $scope.concatenateAddress = (address) => {
    const { line1, line2, city, county, postCode, country } = address;

    const countryLabel = StaffService.getCountryList().find(
      (c) => c.id === country
    );

    const pieces = [
      line1,
      line2,
      city,
      county,
      postCode,
      countryLabel ? countryLabel.title : null,
    ];

    return pieces
      .filter((p) => p !== undefined && p !== null && p !== '')
      .join(', ');
  };

  $scope.editAddress = (address) => {
    const addressModal = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./address/drawer'),
      resolve: {
        data: () => ({
          userId,
          address: address || $scope.props.currentAddress,
        }),
      },
    });

    addressModal.result.then((result) => {
      const { refreshHistorical, newCurrentAddress } = result;

      if (refreshHistorical && $scope.props.addressHistoryActive) {
        $scope.loadAddressHistory();
      }

      if (newCurrentAddress) {
        $scope.props.currentAddress = newCurrentAddress;
        Object.assign($scope.userData.personal, {
          addressLine1: newCurrentAddress.line1,
          addressLine2: newCurrentAddress.line2,
          addressCity: newCurrentAddress.city,
          addressCounty: newCurrentAddress.county,
          addressPostCode: newCurrentAddress.postCode,
          addressCountry: newCurrentAddress.country,
        });
      }
    });
  };

  // Validate UK Right to Work Share Code input
  $scope.$watch('props.formData.ukRightToWorkShareCode', (newValue) => {
    if (!newValue) {
      $scope.props.ukShareCodeErrors.format = false;
      $scope.props.ukShareCodeErrors.length = false;
      return;
    }
    const chars = newValue.split('-').join('');
    const isValidLength = chars.length === 0 || chars.length === 9;
    const isAlphanumeric = /^[a-zA-Z0-9]*$/.test(chars);

    $scope.props.ukShareCodeErrors.format = !isAlphanumeric;
    $scope.props.ukShareCodeErrors.length = !isValidLength;
  });

  $scope.clearShareCodeExpiryDate = () =>
    $scope.props.formData.ukRightToWorkShareCodeExpiryDate = null;

  $scope.shareCodeExpiryDateChanged = (newDate) =>
    ($scope.props.formData.ukRightToWorkShareCodeExpiryDate = newDate.clone());

  $scope.showClearIcon = !!$scope.props.formData.ukRightToWorkShareCodeExpiryDate;

  function onApiError(status) {
    $scope.props.loadingData = false;

    if (status === 403) {
      AlertService.add(
        'warning',
        translations['STAFF.VIEW.PERSONAL.ERROR_403']
      );
      return;
    }

    AlertService.add('danger', translations['STAFF.VIEW.PERSONAL.ERROR_500']);
  }

  function calculateAge() {
    $scope.props.age = Math.max(
      moment().diff($scope.props.formData.birthDate, 'years'),
      0
    );
  }

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.Gender,
    EnvironmentDataService.DataType.Title,
    EnvironmentDataService.DataType.Ethnicity,
    EnvironmentDataService.DataType.VisaType
  ])
    .then(([gender, title, ethnicity, visaType]) => {
      $scope.props.genderList = gender.data.map(({ id, title, deleted }) => ({
        id,
        label: title,
        deleted,
      }));
      $scope.props.titleList = title.data.map(({ id, title, deleted }) => ({
        id,
        label: title,
        deleted,
      }));
      $scope.props.ethnicityList = ethnicity.data.map(
        ({ id, title, deleted }) => ({ id, label: title, deleted })
      );
      $scope.props.visaTypeList = visaType.data.map(
        ({ id, label, deleted }) => ({ id, label, deleted })
      );

      $scope.props.loadingData = false;
      calculateAge();
      enhanceVisas();
    })
    .catch(({ status }) => onApiError(status));

  $scope.birthDateChanged = (newDate) => {
    $scope.props.formData.birthDate = newDate.clone();
    calculateAge();
  };

  $scope.passportExpiryDateChanged = (newDate) => {
    $scope.props.formData.passportExpiryDate = newDate.clone();
  };

  $scope.hasFormErrors = () => {
    return !!$scope.props.ukShareCodeErrors.format ||
      !!$scope.props.ukShareCodeErrors.length;
  }

  // Display share code in format XXX-XXX-XXX for easier reading
  function formatDisplayShareCode(shareCode) {
    return shareCode
      ? shareCode.match(new RegExp('.{1,3}', 'g')).join('-')
      : null;
  }
  // Remove dashes for API submission
  function formatShareCode(shareCode) {
    return shareCode.split('-').join('');
  }

  $scope.save = () => {
    const { formData, permitted } = $scope.props;

    if (!permitted || $scope.hasFormErrors()) {
      return;
    }
    $scope.props.saveActioning = true;

    const birthDate = moment(formData.birthDate).format('YYYY-MM-DD');
    const passportExpiryDate = formData.passportExpiryDate
      ? moment(formData.passportExpiryDate).format('YYYY-MM-DD')
      : null;
    const ukRightToWorkShareCode = formData.ukRightToWorkShareCode
      ? formatShareCode(formData.ukRightToWorkShareCode)
      : null;
    const ukRightToWorkShareCodeExpiryDate = formData.ukRightToWorkShareCodeExpiryDate
      ? moment(formData.ukRightToWorkShareCodeExpiryDate).format('YYYY-MM-DD')
      : null;

    StaffService.updateAccount(userId, {
      user: {
        ...formData,
        birthDate,
        passportExpiryDate,
        ukRightToWorkShareCode,
        ukRightToWorkShareCodeExpiryDate,
        // Supplying the user's locale helps the API infer a country code when parsing the mobile
        // number, should the user not prefix the number with a country code. This should be
        // removed when the mobile number input is upgraded to include a country prefix, much like
        // the one in Frecl.
        locale,
      },
    })
      .then(() => {
        const {
          firstName,
          lastName,
          hrId,
          payrollId,
          email,
          mobile,
          titleId,
          genderId,
          otherNames,
          nationalityId,
          workPhone,
          workEmail,
          ukSettledStatus,
          ethnicityId,
          maritalStatusId,
          passportNumber,
          preferredName,
          ukRightToWorkShareCode,
        } = formData;

        Object.assign($scope.userData, {
          firstName,
          lastName,
          preferredName,
          extra: {
            hrId,
            payrollId,
          },
        });

        Object.assign($scope.userData.personal, {
          dob: birthDate,
          email,
          mobile,
          titleId,
          genderId,
          otherNames,
          nationalityId,
          workPhone,
          workEmail,
          ukSettledStatus,
          ethnicityId,
          maritalStatusId,
          passportNumber,
          passportExpiryDate,
          ukRightToWorkShareCode,
        });

        $rootScope.$broadcast('user:update');

        $scope.props.saveActioning = false;
        AlertService.add(
          'success',
          translations['STAFF.VIEW.PERSONAL.SAVE_SUCCESS']
        );
      })
      .catch(({ status, data }) => {
        $scope.props.saveActioning = false;

        const { validationResult } = data;

        if (status === 400 && validationResult) {
          StaffCommon.onEmploymentValidationResponse(validationResult);
          return;
        }

        onApiError(status);
      });
  };

  // Show visas without expiry first, then most recent
  function sortVisasByExpiry() {
    const previousVisas = $scope.props.visas.previous;

    const visasWithNoExpiry = previousVisas.filter((visa) => !visa.expiryDate);
    const visasWithExpiry = previousVisas.filter((visa) => visa.expiryDate);

    const sortedByExpiry = visasWithExpiry.sort((a, b) => {
      const aDate = moment(a.expiryDate);
      const bDate = moment(b.expiryDate);

      return aDate.isBefore(bDate) ? -1 : aDate.isAfter(bDate) ? 1 : 0;
    });

    $scope.props.visas.previous = [...visasWithNoExpiry, ...sortedByExpiry];
  }

  function enhanceVisas() {
    const currentVisa = $scope.props.visas.current;
    const previousVisas = $scope.props.visas.previous;
    const visaTypes = $scope.props.visaTypeList || [];

    $scope.props.visas.current = currentVisa
      ? formatVisa(currentVisa, visaTypes)
      : null;
    $scope.props.visas.previous = previousVisas.map((visa) =>
      formatVisa(visa, visaTypes)
    );

    sortVisasByExpiry();
  }

  function formatVisa(visa, visaTypes) {
    return {
      ...visa,
      displayExpiryDate: visa.expiryDate
        ? moment(visa.expiryDate).format('DD/MM/YYYY')
        : translations['STAFF.VIEW.VISAS.VISA_EXPIRY_NOT_APPLICABLE'],
      visaTypeLabel:
        visaTypes.find(({ id }) => id === visa.visaTypeId).label || '',
    };
  }

  $scope.toggleShowPreviousVisas = () =>
    ($scope.props.visas.showPrevious = !$scope.props.visas.showPrevious);

  $scope.showPreviousVisasText = () =>
    $scope.props.visas.showPrevious
      ? translations['STAFF.VIEW.VISAS.HIDE_PREVIOUS_BTN']
      : translations['STAFF.VIEW.VISAS.SHOW_PREVIOUS_BTN'];

  $scope.getPreviousVisaTitle = (index) =>
    `${translations['STAFF.VIEW.VISAS.PREVIOUS_HEADING']} ${index + 1}`;

  $scope.addVisa = () => {
    const visaModal = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate2,
      controller: require('./visas/drawer'),
      resolve: {
        data: () => ({
          userId: $scope.userData.id,
          visaTypes: $scope.props.visaTypeList,
        }),
      },
    });

    visaModal.result.then((result) => {
      $scope.loadVisas();
    });
  };

  $scope.editVisa = (visa) => {
    const visaModal = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate3,
      controller: require('./visas/drawer'),
      resolve: {
        data: () => ({
          initialFormValues: visa,
          isEdit: true,
          userId: $scope.userData.id,
          visaId: visa.id,
          visaTypes: $scope.props.visaTypeList,
        }),
      },
    });

    visaModal.result.then(() => $scope.loadVisas());
  };

  function filterVisas(visas = []) {
    const currentVisa = visas.find((visa) => visa.isCurrent && !visa.deleted);
    const previousVisas = visas.filter(
      (visa) => !visa.isCurrent && !visa.deleted
    );

    return { currentVisa, previousVisas };
  }

  $scope.loadVisas = () => {
    $scope.props.loadingData = true;

    StaffService.getVisas(userId)
      .then(({ data }) => {
        const { currentVisa, previousVisas } = filterVisas(data.results);
        $scope.props.visas.current = currentVisa;
        $scope.props.visas.previous = previousVisas;

        enhanceVisas();

        $scope.props.loadingData = false;
      })
      .catch(({ status }) => onApiError(status));
  };
};
