import moment from 'moment-timezone';
import PubNub from 'pubnub';

import services from './module';
import {
  ConversationUserQuery,
  PostMessageMutation,
  StartChannelMutation,
} from '../fragments';
import TypingActions from '../constants/messaging/typingActions';
import {
  USER_CONVERSATION_CHANNEL_PREFIX,
  UTC_TO_UNIX_PRECISION
} from '../constants/messaging/constants';

services.factory('MessagingService', (
  $q,
  apollo,
  $rootScope,
  $translate,
  Notification,
  SessionService,
  AlertService,
  PUBNUB_SUBSCRIBE_KEY,
  PUBNUB_PUBLISH_KEY,
) => {
  'ngInject';
  $rootScope.messagingService = {};

  let pubnub;

  let isServiceInitialised = false;
  let isInstantiatedWithUUID = false;
  let messageCounts = {};
  let activeChannel = null;
  let hasLoadedFromMessageHub = false;
  let conversationsWithMetadata = [];

  let latestNotificationTitle = '';
  let latestNotificationMessage = '';
  let latestNotificationChannel = '';

  let firstLoadChannels = null;

  const cachedUserInformation = [];

  const instantiatePubNubWithUUID = () => {
    const uuid = SessionService.getUserMessagingHash();

    pubnub.setUUID(uuid);
    isInstantiatedWithUUID = true;
  };

  const getUserInfo = async (userIds) => {
    const newUserIds = [];

    userIds.forEach((id) => {
      const result = cachedUserInformation.find(({ userId }) => userId === id);

      if (!result) {
        newUserIds.push(id);
      }
    });

    if (!newUserIds.length) {
      const filteredResults = cachedUserInformation.filter(({
        userId,
      }) => userIds.includes(userId));

      return $q.resolve(filteredResults);
    }

    const { data } = await apollo
      .query({
        query: ConversationUserQuery,
        variables: {
          ids: newUserIds.filter(Boolean),
        },
      });

    if (data) {
      const { usersById } = data;

      cachedUserInformation.push(...usersById);

      return cachedUserInformation.filter(({ userId }) => userIds.includes(userId));
    }
  };

  const getMemberships = async () => {
    try {
      if (!isInstantiatedWithUUID) {
        instantiatePubNubWithUUID();
      }

      const uuid = SessionService.getUserMessagingHash();

      const { data } = await pubnub.objects.getMemberships({
        uuid,
        include: {
          customFields: true,
        },
      });

      const memberships = Object.values(data);

      return memberships;
    } catch (e) {
      // Do nothing - This isn't a message to display to users
    }
  };

  const getUnreadCountForAllChannels = async () => {
    try {
      if (!isInstantiatedWithUUID) {
        instantiatePubNubWithUUID();
      }

      const memberships = await getMemberships();

      const channels = [];
      const channelTimetokens = [];

      if (memberships.length) {
        memberships.forEach((membership) => {
          const {
            channel: {
              id,
            },
            custom: {
              lastReadTimetoken,
            },
          } = membership;

          channels.push(id);

          const timetokenFrom = +lastReadTimetoken;

          channelTimetokens.push(timetokenFrom ?? 0);
        });

        const { channels: messageCounts } = await pubnub.messageCounts({
          channels,
          channelTimetokens,
        });

        return messageCounts;
      }
    } catch (e) {
      // Do nothing - This isn't a message to display to users
    }
  };

  const unsubscribeFromAllChannels = () => {
    try {
      if (!isInstantiatedWithUUID) {
        instantiatePubNubWithUUID();
      }

      pubnub.unsubscribeAll();
    } catch (e) {
      // Do nothing - This isn't a message to display to users
    }
  };

  const subscribeToChannelGroup = () => {
    try {
      if (firstLoadChannels && firstLoadChannels.length == 0) {
        return;
      }

      if (!isInstantiatedWithUUID) {
        instantiatePubNubWithUUID();
      }

      const realmId = SessionService.getRealm().id;
      const ownUserId = SessionService.getUserId();

      const channelGroup = `${USER_CONVERSATION_CHANNEL_PREFIX}_${realmId}_${ownUserId}`;

      unsubscribeFromAllChannels();

      pubnub.subscribe({
        channelGroups: [channelGroup],
      });
    } catch (e) {
      AlertService.add('danger', $translate.instant('MESSAGING.ALERTS.SUBSCRIBE_TO_CHANNELS'));
    }
  };

  const getSingleChannelMetadata = async (channel) => {
    try {
      if (!isInstantiatedWithUUID) {
        instantiatePubNubWithUUID();
      }

      const { data } = await pubnub.objects.getChannelMetadata({
        channel,
        include: {
          customFields: true,
        },
      });

      const channelMetadata = Object.values(data);

      return channelMetadata;
    } catch (e) {
      AlertService.add('danger', $translate.instant('MESSAGING.ALERTS.FETCH_MESSAGES'));
    }
  };

  const getChannels = async () => {
    try {
      const realmId = SessionService.getRealm().id;
      const ownUserId = SessionService.getUserId();

      if (!isInstantiatedWithUUID) {
        instantiatePubNubWithUUID();
      }

      const { channels } = await pubnub.channelGroups.listChannels({
        channelGroup: `${USER_CONVERSATION_CHANNEL_PREFIX}_${realmId}_${ownUserId}`,
      });

      return channels;
    } catch (e) {
      AlertService.add('danger', $translate.instant('MESSAGING.ALERTS.FETCH_CONVERSATIONS'));
    }
  }

  return {
    isInitialised() {
      return isServiceInitialised;
    },

    async init() {
      if (this.isInitialised()) {
        return
      }

      const uuid = SessionService.getUserMessagingHash();

      pubnub = new PubNub({
        publishKey: PUBNUB_PUBLISH_KEY,
        subscribeKey: PUBNUB_SUBSCRIBE_KEY,
        uuid,
      });

      if (!isInstantiatedWithUUID) {
        instantiatePubNubWithUUID();
      }

      messageCounts = await getUnreadCountForAllChannels();
      const unreadConversations = messageCounts ? Object.values(messageCounts).filter((count) => +count > 0).length : 0;

      $rootScope.messagingService.unreadMessagesCount = unreadConversations;
      $rootScope.$apply();

      firstLoadChannels = await getChannels();
      subscribeToChannelGroup();

      isServiceInitialised = true;
    },

    updateUnreadCountFromNewMessage: async ({ channel, message, timetoken }) => {
      try {
        const ownUserId = SessionService.getUserId();
        const { userId: messageUserId } = message;

        const isUserSelf = messageUserId == ownUserId;

        const conversationExists = conversationsWithMetadata.some((conversation) => conversation.channel === channel);

        const shouldMessageHighlight = !isUserSelf && activeChannel !== channel

        let updatedConversationsList;

        if (conversationExists) {
          updatedConversationsList = conversationsWithMetadata.map((conversation) => {
            if (conversation.channel !== channel) {
              return conversation;
            }

            return {
              ...conversation,
              message,
              unreadCount: shouldMessageHighlight ? (conversation.unreadCount ?? 0) + 1 : conversation.unreadCount ?? 0,
              timetoken,
            }
          })
        } else {
          const [id, name, description, custom, updated, eTag] = await getSingleChannelMetadata(channel);

          updatedConversationsList = [
            {
              channel,
              message,
              metadata: {
                id,
                name,
                description,
                custom,
                updated,
                eTag,
              },
              timetoken: +timetoken,
              unreadCount: isUserSelf ? 0 : 1,
            },
            ...conversationsWithMetadata,
          ];
        }

        conversationsWithMetadata = updatedConversationsList.sort(({ timetoken: ttA }, { timetoken: ttB }) => (ttA < ttB ? 1 : -1));;

        if (messageCounts) {
          const currentCount = messageCounts[channel] ?? 0;

          messageCounts[channel] = currentCount + 1;
        }

        if (shouldMessageHighlight) {
          const unreadConversations = updatedConversationsList ? updatedConversationsList.filter(({ unreadCount }) => unreadCount > 0).length : 0;

          $rootScope.messagingService.unreadMessagesCount = unreadConversations;
        }

        $rootScope.$apply();
        $rootScope.$broadcast('messageListUpdated');
      } catch (e) {
        AlertService.add('danger', $translate.instant('MESSAGING.ALERTS.FETCH_NEW_MESSAGES'));
      }
    },

    checkAndUpdateUnreadCount: (channel) => {
      const unreadCount = messageCounts ? messageCounts[channel] ?? 0 : 0;

      if (unreadCount > 0) {
        // Update badge
        $rootScope.messagingService.unreadMessagesCount = $rootScope.messagingService.unreadMessagesCount - 1;

        // Update counter on conversations list
        conversationsWithMetadata = conversationsWithMetadata.map((conversation) => {
          if (conversation.channel !== channel) {
            return conversation;
          }

          return {
            ...conversation,
            unreadCount: 0,
          }
        });

        $rootScope.$broadcast('messageListUpdated')
      }
    },

    getActiveChannel: () => activeChannel,

    setActiveChannel: (channel) => {
      activeChannel = channel;
    },

    getChannelName: (sortedUserIds) => {
      const realmId = SessionService.getRealm().id;

      return `${realmId}-chat-${sortedUserIds.join('-')}`;
    },

    getUserInformation: async (participantIds) => {
      const response = await getUserInfo(participantIds);
      return response;
    },

    getConversationParticipantsIds: (channel) => {
      const conversation = conversationsWithMetadata.find((conversation) => conversation.channel == channel);

      return conversation.metadata.custom.participantIds
        .split(',')
        .reduce((result, option) => {
          if (option !== '') {
            result.push(+option);
          }

          return result;
        }, []);
    },

    getConversationWithParticipants: async (
      participantIds,
    ) => {
      await getUserInfo(participantIds);

      return Object.values(conversationsWithMetadata)
        .map((conversation) => {
          const { channel, metadata, message, timetoken } = conversation;

          let participantIds;

          if (metadata) {
            participantIds = metadata.custom.participantIds
              .split(',');
          } else {
            participantIds = [];
          }

          const participants = participantIds
            .filter((p) => p !== '')
            .map((p) => cachedUserInformation.find((user) => (
              user.userId === +p
            )));

          const timestamp = moment.unix(timetoken / UTC_TO_UNIX_PRECISION);

          const mappedMessage = {
            ...message,
            user: participants.find((participant) => participant.userId === message.userId),
            timestamp,
          };

          const ownUserId = SessionService.getUserId();
          const otherParticipants = participants.filter(({ userId }) => userId !== ownUserId);

          let participantsTitle = '';
          otherParticipants.forEach(({ firstName, lastName }, index) => {
            participantsTitle = `${participantsTitle}${(index === 0 || index === otherParticipants.length) ? '' : ', '}${firstName} ${lastName}`;
          });

          return {
            ...conversation,
            channelId: channel,
            message: mappedMessage,
            participants,
            participantsTitle,
          };
        },
      );
    },

    addListener: (listener) => {
      try {
        if (!isInstantiatedWithUUID) {
          instantiatePubNubWithUUID();
        }

        pubnub.addListener(listener);
      } catch (e) {
        // Do nothing - This isn't a message to display to users
      }
    },

    removeListener: (listener) => {
      try {
        if (!isInstantiatedWithUUID) {
          instantiatePubNubWithUUID();
        }

        pubnub.removeListener(listener);
      } catch (e) {
        // Do nothing - This isn't a message to display to users
      }
    },

    getChannels: async () => {
      try {
        if (firstLoadChannels) {
          const channelsToReturn = firstLoadChannels;
          firstLoadChannels = undefined;
          return channelsToReturn;
        }

        return getChannels();
      } catch (e) {
        AlertService.add('danger', $translate.instant('MESSAGING.ALERTS.FETCH_CONVERSATIONS'));
      }
    },

    getChannelsMetadata: async () => {
      try {
        const realmId = SessionService.getRealm().id;
        const ownUserId = SessionService.getUserId();

        if (!isInstantiatedWithUUID) {
          instantiatePubNubWithUUID();
        }

        const { data } = await pubnub.objects.getAllChannelMetadata({
          include: {
            customFields: true,
          },
          filter: `custom.participantIds LIKE '*,${ownUserId},*' && custom.realmId == '${realmId}'`,
        });

        return data;
      } catch (e) {
        AlertService.add('danger', $translate.instant('MESSAGING.ALERTS.FETCH_CONVERSATIONS'));
      }
    },

    fetchConversationMessages: async (channels, channelMetaData) => {
      try {
        if (!isInstantiatedWithUUID) {
          instantiatePubNubWithUUID();
        }

        if (hasLoadedFromMessageHub && conversationsWithMetadata.length) {
          return conversationsWithMetadata;
        }

        const { channels: conversations } = await pubnub.fetchMessages(
          {
            channels,
            count: 1,
          },
        );

        conversationsWithMetadata = Object.values(conversations)
          .map(([conversation]) => {
            const { timetoken } = conversation;
            const metadata = channelMetaData.find((c) => c.id === conversation.channel);

            const unreadCount = messageCounts ? messageCounts[conversation.channel] ?? 0 : 0;

            return {
              ...conversation,
              timetoken: +timetoken,
              metadata,
              unreadCount,
            };
          }).sort(({ timetoken: ttA }, { timetoken: ttB }) => (ttA < ttB ? 1 : -1));

        hasLoadedFromMessageHub = true;

        return conversationsWithMetadata;
      } catch (e) {
        AlertService.add('danger', $translate.instant('MESSAGING.ALERTS.FETCH_CONVERSATIONS'));
      }
    },

    getMessages: async ({
      channel,
      conversationUsers,
      start,
      count = 25,
    }) => {
      try {
        if (!isInstantiatedWithUUID) {
          instantiatePubNubWithUUID();
        }

        activeChannel = channel;

        const { messages, startTimeToken, endTimeToken } = await pubnub.history({
          channel,
          count,
          start,
        });

        if (!messages || !messages.length) {
          return $q.resolve();
        }

        const mappedMessages = messages.map((message) => {
          const { entry, timetoken } = message;

          const timestamp = moment.unix(timetoken / UTC_TO_UNIX_PRECISION).format('LLL');

          const user = conversationUsers.find(({ userId }) => entry.userId === userId);

          return {
            ...message,
            entry: {
              ...entry,
              user,
            },
            timestamp,
          };
        });

        // Start is passed in with fetching more messages, therefore can use this
        // to flag when a new fetch, to set the unreadCount.
        if (!start) {
          conversationsWithMetadata = conversationsWithMetadata.map((conversation) => {
            if (conversation.channel != channel) {
              return conversation;
            }

            return {
              ...conversation,
              unreadCount: 0,
            }
          });
        }

        return $q.resolve({ mappedMessages, startTimeToken, endTimeToken });
      } catch (e) {
        AlertService.add('danger', $translate.instant('MESSAGING.ALERTS.FETCH_MESSAGES'));
      }
    },

    setLastReadTimetoken: async (channel, timetoken) => {
      try {
        if (!isInstantiatedWithUUID) {
          instantiatePubNubWithUUID();
        }

        // Add 2 to ensure we fetch the messages that have happened AFTER the last timetoken
        // +2 because JS BigInt Maths... e.g. 16158225476881284 + 1 = 16158225476881284
        await pubnub.objects.setMemberships({
          channels: [{
            id: channel,
            custom: {
              lastReadTimetoken: timetoken + 2,
            },
          }],
        });
      } catch (e) {
         // Do nothing - This isn't a message to display to users
      }
    },

    setUserTyping: async (channel, userId) => {
      try {
        if (!isInstantiatedWithUUID) {
          instantiatePubNubWithUUID();
        }

        pubnub.signal({
          message: {
            type: TypingActions.TYPING,
            userId,
          },
          channel,
        });
      } catch (e) {
         // Do nothing - This isn't a message to display to users
      }
    },

    setUserStopTyping: async (channel, userId) => {
      try {
        if (!isInstantiatedWithUUID) {
          instantiatePubNubWithUUID();
        }

        pubnub.signal({
          message: {
            type: TypingActions.TYPING_STOPPED,
            userId,
          },
          channel,
        });
      } catch (e) {
        // Do nothing - This isn't a message to display to users
      }
    },

    postMessage: async (channelName, userId, userFullName, comment, participants) => {
      const uuid = SessionService.getUserMessagingHash();

      const {
        data: {
          postMessage: {
            response: {
              __typename: typeName,
            },
          },
        }
      } = await apollo.mutate({
        mutation: PostMessageMutation,
        variables: {
          input: {
            uuid,
            userId,
            userFullName,
            comment,
            channelName,
            participantIds: participants,
          },
        },
      });

      if (typeName !== 'PostedMessage') {
        AlertService.add('danger', $translate.instant('MESSAGING.ALERTS.SEND_MESSAGE'));
      }
    },

    startConversation: async (channel, participantIds = []) => {
      const uuid = SessionService.getUserMessagingHash();

      const {
        data: {
          startChannel : {
            response: {
              __typename: typeName,
            },
          },
        }
      } = await apollo.mutate({
        mutation: StartChannelMutation,
        variables: {
          input: {
            uuid,
            channel,
            participantIds,
          },
        },
      });

      const isSuccess = typeName === 'StartChannel';

      if (isSuccess) {
        subscribeToChannelGroup()
      }

      return isSuccess;
    },

    showNotification: ({ title, message, channel }) => {
      latestNotificationTitle = title;
      latestNotificationMessage = message;
      latestNotificationChannel = channel;

      Notification.primary({
        title: title,
        message: message,
        closeOnClick: false,
        maxCount: 1,
      });
    },

    getLatestNotification: () => {
      return {
        title: latestNotificationTitle,
        message: latestNotificationMessage,
        channel: latestNotificationChannel,
      };
    },
  };
});
