'use strict';
var controllers = require('../../module');

controllers.controller('FixedStaffingShiftSetModalCtrl', ['$scope', '$uibModalInstance', '$translate', 'TemplateService', 'CommonService', 'SessionService', 'data',
    function ($scope, $uibModalInstance, $translate, TemplateService, CommonService, SessionService, data) {
        $scope.dataLoaded = false;
        $scope.isAdd = data.isAdd;

        var textById = $translate.instant([
            'ROTA_MANAGEMENT.TEMPLATES.FIXED_STAFFING_SHIFT_SET_MODAL.UNKNOWN_SHIFT_TYPE',
            'ROTA_MANAGEMENT.TEMPLATES.FIXED_STAFFING_SHIFT_SET_MODAL.ERRORS.UNABLE_ADD',
            'ROTA_MANAGEMENT.TEMPLATES.FIXED_STAFFING_SHIFT_SET_MODAL.ERRORS.UNABLE_UPDATE',
        ]);

        if (data.isAdd) {
            $scope.formData = {
                leaf_time_start: moment().startOf('day').add({
                    hours: 9, minutes: 0
                }),
                leaf_time_end: moment().startOf('day').add({
                    hours: 17, minutes: 0
                }),
                leaf_num_mon: 0,
                leaf_num_tue: 0,
                leaf_num_wed: 0,
                leaf_num_thu: 0,
                leaf_num_fri: 0,
                leaf_num_sat: 0,
                leaf_num_sun: 0,
                leaf_num_type: 0
            };
        } else {
            $scope.formData = angular.copy(data.leaf);
            $scope.formData.leaf_time_start = moment($scope.formData.leaf_time_start);
            $scope.formData.leaf_time_end = moment($scope.formData.leaf_time_end);
        }

        var defaultShiftType;

        // Default to the parent node's shift type if one is set
        data.node.constraints.forEach(function (c) {
            if (c.const_type === 16) {
                defaultShiftType = parseInt(c.const_var1, 10);
            }
        });

        $scope.showDayOverflowWarning = false;

        function checkForDayOverflow() {
            $scope.showDayOverflowWarning = $scope.formData.leaf_time_end < $scope.formData.leaf_time_start;
        }

        $scope.$watch('formData.leaf_time_start', function () {
            checkForDayOverflow();
        });
        $scope.$watch('formData.leaf_time_end', function () {
            checkForDayOverflow();
        });

        CommonService.getMetadata(['shiftLocations', 'shiftTypes'], [SessionService.getEntity()], true)
            .success(function (metadata) {
                $scope.metadata = metadata;
                $scope.formData.shiftTypeId = defaultShiftType || metadata.shiftTypes[0].id;

                $scope.dataLoaded = true;
            });

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.saveShiftSet = function () {
            $scope.saveActioning = true;

            var toSend = angular.copy($scope.formData);
            toSend.leaf_time_start = moment(toSend.leaf_time_start).format('HH:mm:ss');
            toSend.leaf_time_end = moment(toSend.leaf_time_end).format('HH:mm:ss');

            var shiftTypeName = textById['ROTA_MANAGEMENT.TEMPLATES.FIXED_STAFFING_SHIFT_SET_MODAL.UNKNOWN_SHIFT_TYPE'];

            $scope.metadata.shiftTypes.forEach(function (st) {
                if (st.id == $scope.formData.shiftTypeId) {
                    shiftTypeName = st.name;
                }
            });

            var constraints = [{
                const_type: 16,
                const_var1: $scope.formData.shiftTypeId,
            }];

            var parentNodeId = data.node.node_id;

            if ($scope.isAdd) {
                TemplateService.addNode({
                    parent_nodeid: parentNodeId,
                    node_name: shiftTypeName,
                    constraints: constraints,
                })
                    .success(function (newNode) {
                        toSend.node_id = newNode.node_id;

                        TemplateService.addLeaf(toSend)
                            .success(function (newLeafData) {
                                newNode.leaves.push(newLeafData);

                                $uibModalInstance.close(newNode);
                            })
                            .error(function () {
                                $scope.saveActioning = false;
                                alert(textById['ROTA_MANAGEMENT.TEMPLATES.FIXED_STAFFING_SHIFT_SET_MODAL.ERRORS.UNABLE_ADD']);
                            });
                    })
                    .error(function () {
                        $scope.saveActioning = false;
                        alert(textById['ROTA_MANAGEMENT.TEMPLATES.FIXED_STAFFING_SHIFT_SET_MODAL.ERRORS.UNABLE_ADD']);
                    });
            } else {
                TemplateService.updateNode(parentNodeId, {
                    node_name: shiftTypeName,
                    constraints: constraints,
                })
                    .success(function () {
                        TemplateService.updateLeaf(data.leaf.leaf_id, toSend)
                            .success(function (updatedLeafData) {
                                $uibModalInstance.close({
                                    leaf: updatedLeafData,
                                    nodeName: shiftTypeName,
                                });
                            })
                            .error(function () {
                                $scope.saveActioning = false;
                                alert(textById['ROTA_MANAGEMENT.TEMPLATES.FIXED_STAFFING_SHIFT_SET_MODAL.ERRORS.UNABLE_UPDATE']);
                            });
                    })
                    .error(function () {
                        $scope.saveActioning = false;
                        alert(textById['ROTA_MANAGEMENT.TEMPLATES.FIXED_STAFFING_SHIFT_SET_MODAL.ERRORS.UNABLE_UPDATE']);
                    });
            }
        };
    }]);
