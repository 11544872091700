var controllers = require('../../module');

controllers.controller('ApproveSwapRequestStaffModalCtrl', ['$uibModalInstance', '$scope', 'data', 'RotaService', 'AlertService',
    function($uibModalInstance, $scope, data, RotaService, AlertService) {
        $scope.cancel = function() {
            $uibModalInstance.dismiss();
        };

        $scope.approve = function(ignoreOptionalValidationIssues) {
            $scope.approveActioning = true;
            
            var body = {
                ignoreOptionalValidationIssues: ignoreOptionalValidationIssues
            };

            RotaService.approveSwapRequest(data.offerId, body)
                .success(function() {
                    AlertService.add("success", "Shift swap request successfully approved!");
                    $uibModalInstance.close();
                })
                .error(function(err, status) {
                    $scope.approveActioning = false;

                    if (status === 400) {
                        $scope.validationFailure = err.validationFailure;
                        return;
                    }

                    if (status !== 403) {
                        AlertService.add('danger', 'We couldn\'t approve the swap request just then. Please try again.');
                    }

                    $scope.cancel();
                });
        }
    }
]);
