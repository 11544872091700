module.exports = (
  $scope,
  $uibModalInstance,
  $translate,
  $window,
  data,
  AlertService,
  StaffService
) => {
  'ngInject';

  initProps();

  const translations = $translate.instant([
    'STAFF.VIEW.VISAS.MODAL.ADD_HEADING',
    'STAFF.VIEW.VISAS.MODAL.EDIT_VISA_HEADING',
    'STAFF.VIEW.VISAS.MODAL.CONFIRM_DELETE'
  ]);

  $scope.canSubmit = () => {
    if ($scope.props.actionInProcess) {
      return false;
    }
    const { formData } = $scope.props;
    const isExpiryValid = formData.hasExpiryDate
      ? formData.expiryDate.isValid()
      : true;
    const isVisaTypeValid = $scope.props.visaTypes.some(
      (visaType) => visaType.id === formData.visaTypeId
    );

    return isExpiryValid && isVisaTypeValid && !!formData.visaNumber;
  };

  $scope.close = () => $uibModalInstance.dismiss();

  $scope.save = () => {
    const {
      formData: {
        expiryDate,
        hasExpiryDate,
        isCurrent,
        visaNumber,
        visaTypeId,
      },
      userId,
    } = $scope.props;

    if (!$scope.canSubmit()) {
      return;
    }

    const payload = {
      expiryDate: hasExpiryDate ? getUtcIsoDate(expiryDate) : null,
      isCurrent: !!isCurrent,
      visaNumber,
      visaTypeId,
    };

    data.isEdit
      ? updateVisa(userId, data.visaId, payload)
      : addVisa(userId, payload);
  };

  function addVisa(userId, payload) {
    StaffService.addVisa(userId, payload)
      .then(() => handleApiSuccess())
      .catch(({ status }) => handleApiError(status));
  }

  function updateVisa(userId, visaId, payload) {
    StaffService.updateVisa(userId, visaId, payload)
      .then(() => handleApiSuccess())
      .catch(({ status }) => handleApiError(status));
  }

  function handleApiSuccess() {
    $scope.props.actionInProgress = false;
    $uibModalInstance.close();
  }

  function handleApiError(status) {
    $scope.props.actionInProgress = false;

    if (status !== 403) {
      AlertService.add(
        'danger',
        translations['STAFF.VIEW.VISAS.MODAL.ALERT_ERROR_500']
      );
    }
  }

  // Maintains the date whilst converting to UTC
  function getUtcIsoDate(dateTime) {
    const date = dateTime.get('date')
    return dateTime.utc().date(date).toISOString();
  }

  function initProps() {
    const initialFormValues = data.initialFormValues || {};

    const expiryDate = initialFormValues.expiryDate
      ? moment(initialFormValues.expiryDate)
      : moment();
    const hasExpiryDate = !!initialFormValues.expiryDate;

    const formData = {
      isCurrent: null,
      visaNumber: '',
      visaTypeId: null,
      ...initialFormValues,
      expiryDate,
      hasExpiryDate,
    };

    $scope.props = {
      actionInProcess: false,
      formData,
      isEdit: !!data.isEdit,
      userId: data.userId,
      visaTypes: data.visaTypes,
    };
  }

  $scope.getHeading = () =>
    data.isEdit
      ? translations['STAFF.VIEW.VISAS.MODAL.EDIT_VISA_HEADING']
      : translations['STAFF.VIEW.VISAS.MODAL.ADD_HEADING'];

  $scope.deleteVisa = () => {
    if ($scope.props.actionInProgress) {
      return;
    }

    if (
      !$window.confirm(translations['STAFF.VIEW.VISAS.MODAL.CONFIRM_DELETE'])
    ) {
      return;
    }

    $scope.props.actionInProgress = true;

    const payload = {
      deleted: true,
    };

    updateVisa(data.userId, data.visaId, payload);
  };
};
