module.exports = (
  $scope,
  $translate,
  $uibModalInstance,
  AlertService,
  StaffService,
  StaffCommon,
  EnvironmentDataService,
  data,
) => {
  'ngInject';

  const {
    userId,
    userCurrentAppointmentDateStart,
    isAdd,
    pay,
    payRecordCount,
  } = data;

  let effectiveDate;

  if (isAdd) {
    effectiveDate = payRecordCount > 1 ? moment().startOf('week') : moment(userCurrentAppointmentDateStart);
  } else {
    effectiveDate = moment(pay.dateStart);
  }

  const translations = $translate.instant([
    'STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_500',
    'STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_403',
    'STAFF.VIEW.PAY.VIEW_HISTORY.ALERT_SUCCESS',
    'STAFF.VIEW.PAY.VIEW_HISTORY.ALERT_DELETED',
    'STAFF.VIEW.PAY.VIEW_HISTORY.CONFIRM_DELETE',
  ]);

  $scope.props = {
    isAdd: !!isAdd,
    pay: isAdd ? {
      effectiveDate,
      elementId: 1,
      amount: 0,
      amountType: 0,
      uplift: 0,
    } : {
      effectiveDate,
      elementId: pay.elementTypeId,
      amount: pay.amount,
      amountType: pay.amountType,
      uplift: pay.uplift,
    },
    lastEdited: !isAdd ? pay.lastEdited : undefined,
    chosenDate: effectiveDate.clone(),
    actionInProgress: true,
    isValid: true,
    currencyCode: 'GBP',
    payAmountTypeList: StaffService.getPayAmountTypes()
      .map(({ id, title, deleted }) => ({ id, label: title, deleted })),
  };

  $scope.close = () => $uibModalInstance.dismiss();

  function refreshValidity() {
    $scope.props.actionInProgress = true;

    StaffService.getPayValidity(userId, $scope.props.chosenDate, $scope.props.pay.elementId)
      .then(({ data }) => {
        $scope.props.isDuplicatePay = isAdd
          ? !!data.payHistoryId : (data.payHistoryId && data.payHistoryId !== pay.id);
        $scope.props.currencyCode = data.currencyCode;
        $scope.props.isValid = $scope.props.currencyCode && !$scope.props.isDuplicatePay;

        $scope.props.actionInProgress = false;
      })
      .catch(() => {
        AlertService.add('danger', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_500']);
        $scope.close();
      });
  }

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.PayElementType,
  ])
    .then(([
      payElementType,
    ]) => {
      $scope.props.payElementList = payElementType.data
        .filter(({ variant }) => ['RATE_PER_UNIT', 'FIXED_AMOUNT'].includes(variant))
        .map((item) => ({
          ...item,
          label: item.name,
          deleted: item.isDeleted,
        }));

      $scope.props.chosenElement = $scope.props.payElementList
        .find((e) => e.id === $scope.props.pay.elementId);

      refreshValidity();
    });

  $scope.onDateStartChange = (dateStart) => {
    $scope.props.chosenDate = dateStart.clone();
    refreshValidity();
  };

  $scope.onPayElementChange = (option) => {
    $scope.props.chosenElement = option;
    refreshValidity();
  };

  $scope.save = () => {
    const payHistory = {
      effectiveDate: $scope.props.chosenDate.format('YYYY-MM-DD'),
      amount: $scope.props.pay.amount,
    };

    if ($scope.props.chosenElement.variant === 'RATE_PER_UNIT') {
      // Zero or negative rates shouldn't be allowed
      if ($scope.props.pay.amount <= 0) {
        return;
      }

      payHistory.amountType = $scope.props.pay.amountType;
      payHistory.uplift = $scope.props.pay.uplift;
    }

    $scope.props.actionInProgress = 'save';

    if (isAdd) {
      payHistory.elementId = $scope.props.chosenElement.id;

      StaffService.addPay(userId, { payHistory })
        .then(({ data }) => {
          AlertService.add('success', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ALERT_SUCCESS']);

          $uibModalInstance.close({
            action: 'create',
            response: data,
          });
        })
        .catch(({ status, data }) => {
          $scope.props.actionInProgress = false;

          if (status === 500) {
            AlertService.add('danger', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_500']);
            return;
          }

          if (status === 403) {
            AlertService.add('warning', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_403']);
            return;
          }

          if (status === 400) {
            StaffCommon.onEmploymentValidationResponse(data.validationResult);
          }
        });
    } else {
      StaffService.updatePay(pay.id, userId, { payHistory })
        .then(({ data }) => {
          AlertService.add('success', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ALERT_SUCCESS']);

          $uibModalInstance.close({
            action: 'update',
            response: data,
          });
        })
        .catch(({ status, data }) => {
          $scope.props.actionInProgress = false;

          if (status === 500) {
            AlertService.add('danger', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_500']);
            return;
          }

          if (status === 403) {
            AlertService.add('warning', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_403']);
            return;
          }

          if (status === 400) {
            StaffCommon.onEmploymentValidationResponse(data.validationResult);
          }
        });
    }
  };

  $scope.delete = () => {
    if (!confirm(translations['STAFF.VIEW.PAY.VIEW_HISTORY.CONFIRM_DELETE'])) {
      return;
    }

    $scope.props.actionInProgress = 'delete';

    StaffService.deletePay(pay.id, userId)
      .then(({ data }) => {
        AlertService.add('success', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ALERT_DELETED']);

        $uibModalInstance.close({
          action: 'delete',
          response: data,
        });
      })
      .catch(({ status, data }) => {
        $scope.props.actionInProgress = false;

        if (status === 500) {
          AlertService.add('danger', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_500']);
          return;
        }

        if (status === 403) {
          AlertService.add('warning', translations['STAFF.VIEW.PAY.VIEW_HISTORY.ERROR_403']);
          return;
        }

        if (status === 400) {
          StaffCommon.onEmploymentValidationResponse(data.validationResult);
        }
      });
  };
};
