require('./timepickerPop');
require('./tag-typeahead');
require('./btnLoading');
require('./rrInlineEdit');
require('./calendar');
require('./numericBinding');
require('./convertToNumber');
require('./datePicker');
require('./percentage');
require('./hierarchyItemPicker');
require('./validationFailure');
require('./entitySelector');
require('./entitySelectorMulti');
require('./resourceSubscriptionButton');
require('./eventSubscriptionButton');
require('./nav');
require('./filterOptionsButton');
require('./filterStaffSearchButton');
require('./filterDateButton');
require('./filterNumberButton');
require('./monetary');
require('./pickList');
require('./fileUpload');
require('./fillRemainingHeight');
require('./dateTimePicker');

require('./staff/addRoutineMappingForm');
require('./staff/availabilityRule');
require('./staff/search');
require('./staff/avatar');
require('./staff/avatarStack');
require('./staff/userWidget');
require('./staff/auditUserWidget');
require('./staff/validationBulkShiftEdit');
require('./staff/validationBulkAbsenceEdit');
require('./staff/validationBulkAttendanceEdit');
require('./staff/validationBulkRoutineMappingEdit');
require('./staff/validationEphemeralRecords');
require('./staff/accountCompletionTracker');
require('./staff/allowanceImpact');
require('./staff/ukShareCode');

require('./rota/dashboardWeek');

require('./messaging/conversation');
require('./messaging/conversationControls');
require('./messaging/message');
require('./messaging/messagesList');
require('./messaging/startConversationDrawer');

require('./settings/restBreak');
require('./settings/shiftRoutine');

require('./inverseInfiniteScroll');
require('./rrContentEditable');
