const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/rotaTemplates/newTemplateModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/dialog/textboxDialog.html');

'use strict';
var controllers = require('../../module');

controllers.controller('TemplatesRmCtrl', ['$scope', '$uibModal', '$state', '$stateParams', '$translate', 'TemplateService', 'AlertService',
    function ($scope, $uibModal, $state, $stateParams, $translate, TemplateService, AlertService) {
        $scope.props = {
          templates: [],
          filterText: '',
          loadingData: true,
          showLinkWarning: $stateParams.source === 'rotas',
        };

        var textById = $translate.instant([
          'ROTA_MANAGEMENT.TEMPLATES.DELETE_TEMPLATE_MODAL.INFO_TITLE',
          'ROTA_MANAGEMENT.TEMPLATES.DELETE_TEMPLATE_MODAL.INFO_BODY',
          'ROTA_MANAGEMENT.TEMPLATES.DELETE_TEMPLATE_MODAL.DELETE_TEMPLATE_BUTTON',
          'ROTA_MANAGEMENT.TEMPLATES.DELETE_TEMPLATE_MODAL.SUCCESS.DELETE_TEMPLATE',
          'ROTA_MANAGEMENT.TEMPLATES.DELETE_TEMPLATE_MODAL.ERRORS.DELETE_TEMPLATE',
          'ROTA_MANAGEMENT.TEMPLATES.INDEX.CONFIRM.DELETE_NODE',
          'ROTA_MANAGEMENT.TEMPLATES.INDEX.SUCCESS.DELETE_NODE',
          'ROTA_MANAGEMENT.TEMPLATES.INDEX.ERRORS.DELETE_NODE'
        ]);

        $scope.newTemplate = function (cloneNodeId) {
            if ($scope.props.loadingData) {
              return;
            }

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'NewTemplateModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            templates: $scope.props.templates,
                            cloneNodeId: cloneNodeId
                        }
                    }
                }
            });

            modalInstance.result.then(function (templateId) {
                $state.go('^.edit', { templateId: templateId });
            });
        };

        $scope.deleteTemplate = function (node) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: function ($scope, $uibModalInstance, TemplateService) {
                    $scope.info = {
                        title: textById['ROTA_MANAGEMENT.TEMPLATES.DELETE_TEMPLATE_MODAL.INFO_TITLE'],
                        message: textById['ROTA_MANAGEMENT.TEMPLATES.DELETE_TEMPLATE_MODAL.INFO_BODY']
                    };

                    $scope.textBox = {
                        placeholder: node.name
                    };

                    $scope.btn = {
                        className: 'btn-danger',
                        label: textById['ROTA_MANAGEMENT.TEMPLATES.DELETE_TEMPLATE_MODAL.DELETE_TEMPLATE_BUTTON'],
                        disabled: true
                    };

                    $scope.$watch('textBox.model', function(newValue) {
                        if (!newValue) return;
                        $scope.btn.disabled = newValue.toLowerCase() != node.name.toLowerCase();
                    });

                    $scope.buttonClick = function (option) {
                        if (!option) {
                            return $uibModalInstance.dismiss('cancel');
                        } else {
                            $scope.btn.disabled = true;

                            TemplateService.deleteTemplate(node.id)
                                .success(function () {
                                    $uibModalInstance.close(true);
                                })
                                .error(function () {
                                    $uibModalInstance.close(false);
                                });
                        }
                    };
                }
            });

            modalInstance.result.then(function (isDeleted) {
                if (isDeleted) {
                    AlertService.add('success', textById['ROTA_MANAGEMENT.TEMPLATES.DELETE_TEMPLATE_MODAL.SUCCESS.DELETE_TEMPLATE']);
                    refreshTemplates();
                } else {
                    AlertService.add("danger", textById['ROTA_MANAGEMENT.TEMPLATES.DELETE_TEMPLATE_MODAL.ERRORS.DELETE_TEMPLATE']);
                }
            });
        };

        $scope.deleteNode = function (nodeId) {
            if (!confirm(textById['ROTA_MANAGEMENT.TEMPLATES.INDEX.CONFIRM.DELETE_NODE']))
                return;

            TemplateService.deleteNode(nodeId)
                .success(function () {
                    AlertService.add("success", textById['ROTA_MANAGEMENT.TEMPLATES.INDEX.SUCCESS.DELETE_NODE']);
                    refreshTemplates();
                })
                .error(function () {
                    AlertService.add("danger", textById['ROTA_MANAGEMENT.TEMPLATES.INDEX.ERRORS.DELETE_NODE']);
                });
        };

        function refreshTemplates() {
            $scope.props.loadingData = true;

            TemplateService.getTemplates()
                .success(function (data) {
                    $scope.props.templates = data.templates.map((template) => ({
                      ...template,
                      editor_lastedited: moment.min(moment(), moment(template.editor_date)).fromNow(),
                    }));

                  $scope.props.loadingData = false;
                });
        }

        refreshTemplates();
    }]);
