var filters = require('./module');

filters.filter('percentage', function () {
    return function (input, decimalPlaces) {
        var value = parseFloat(input);

        if (isNaN(value)) {
            return '';
        }

        var multiplier = 10; // default to 1dp
        var exponent = parseInt(decimalPlaces);

        if (!isNaN(exponent)) {
            multiplier = Math.pow(10, exponent);
        }

        var adjustedValue = Math.round((value * 100) * multiplier) / multiplier;
        return adjustedValue + '%';
    };
});
