module.exports = (
  $scope,
  StaffService,
  SessionService,
  AlertService,
) => {
  'ngInject';

  const relationshipType = 1;
  const approvers = ($scope.userData.relationships || [])
    .filter((r) => r.type === relationshipType && r.isChild)
    .map((r) => r.user);
  const originalApproverUserIds = approvers.map((u) => u.id);

  const {
    absence: {
      absenceHoursDay,
      absenceHoursHalfDay,
      isAbsenceHoursOverridden,
    },
    work: {
      preRotareadyAverageHoursPerDay,
      preRotareadyAverageHoursDate,
    },
  } = $scope.userData;

  $scope.props = {
    disableCalculate: SessionService.getSetting('absence.enableAbsenceHoursJob') === 'false',
    approvers,
    formData: {
      absenceHoursDay,
      absenceHoursHalfDay,
      isAbsenceHoursOverridden,
      preRotareadyAverageHoursPerDay,
      preRotareadyAverageHoursDate,
    },
    preRotareadyAverageHoursDateFormatted: preRotareadyAverageHoursDate,
  };

  $scope.onPreRotareadyDateChange = (newDate) => {
    if (!newDate) {
      return;
    }

    $scope.props.preRotareadyAverageHoursDateFormatted = newDate.format('YYYY-MM-DD');
  };

  let approverUserIds = $scope.props.approvers.map((u) => u.id);

  $scope.onUserSelect = (user) => {
    if (user.id === parseInt($scope.userId, 10)
      || approverUserIds.includes(user.id)
      || approverUserIds.length >= 3) {
      return;
    }

    $scope.props.approvers.push(user);
    approverUserIds.push(user.id);
  };

  $scope.onUserRemove = (id) => {
    $scope.props.approvers = $scope.props.approvers.filter((u) => u.id !== id);
    approverUserIds = approverUserIds.filter((x) => x !== id);
  };

  $scope.save = () => {
    const { formData } = $scope.props;

    if (!formData.absenceHoursDay && !formData.absenceHoursHalfDay) {
      return;
    }

    $scope.props.saveActioning = true;

    StaffService.updateAccount($scope.userId, {
      user: {
        ...formData,
        preRotareadyAverageHoursDate: $scope.props.preRotareadyAverageHoursDateFormatted,
      },
      absenceApproverUserIds: approverUserIds,
    })
      .then(() => {
        Object.assign($scope.userData.absence, formData);

        $scope.userData.relationships = ($scope.userData.relationships || []).filter(
          (r) => !(r.type === relationshipType && r.isChild
            && originalApproverUserIds.includes(r.user.id)));

        $scope.userData.relationships.push(...$scope.props.approvers.map((user) => ({
          user,
          type: relationshipType,
          isChild: true,
        })));

        $scope.props.saveActioning = false;
        AlertService.add('success', 'Absence settings saved.');
      })
      .catch(({ status }) => {
        $scope.props.saveActioning = false;

        if (status === 403) {
          AlertService.add('warning', 'You don\'t have permission to change this employee\'s absence settings.');
          return;
        }

        AlertService.add('danger', 'We couldn\'t save just then. Please try again.');
      });
  };
};
