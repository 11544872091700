var directives = require("../module");

// Ensure UK Share Code input is formatted for readability
// "123456789" => "123-456-789"
directives.directive("ukShareCode", function () {
  return {
    require: "ngModel",
    restrict: "A",
    link: function (scope, element) {
      element[0].addEventListener("keydown", function (e) {
        // "-" messes up the "XXX-YYY-ZZZ" formatting and validation
        if (e.key === "-") {
          e.preventDefault();
        }
      });

      element[0].addEventListener("input", function (e) {
        const value = e.target.value;
        if (!value) {
          return;
        }

        const input = value.split("-").join("");
        const formatted = input.match(new RegExp(".{1,3}", "g")).join("-");

        scope.props.formData.ukRightToWorkShareCode = formatted;
        scope.$apply();
      });
    },
  };
});
