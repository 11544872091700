module.exports = (
  $scope,
  $state,
  $stateParams,
  $translate,
  $window,
  StaffCommon,
  AvailabilityService,
  EnvironmentDataService,
  SessionService,
  AlertService,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.ALLOWANCE_EXPORT.ERROR_500',
  ]);

  let selectedDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('year');
  let selectedDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('year').add(1, 'year').subtract(1, 'day');
  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];
  const defaultTypeIds = $stateParams.absenceTypeIds
    ? $stateParams.absenceTypeIds.split(',').map(Number).filter((n) => !Number.isNaN(n)) : [];
  const defaultSchedulesOfAccrual = $stateParams.schedulesOfAccrual
    ? $stateParams.schedulesOfAccrual.split(',') : [];
  const defaultUserId = $stateParams.userId || undefined;

  $scope.props = {
    loadingData: false,
    noMoreData: false,
    data: [],
    scheduleOfAccrualList: [
      'BEGINNING_OF_ACCRUAL_YEAR',
      'EACH_CALENDAR_MONTH',
      'EACH_HOUR_WORKED',
    ].map((id) => ({
      id,
      label: StaffCommon.getLabelFromScheduleOfAccrual(id),
    })),
    defaultEntityIds,
    defaultTypeIds,
    defaultSchedulesOfAccrual,
    defaultUserId,
    defaultDateFilter: selectedDateStart && selectedDateEnd ? {
      option: 4,
      dateStart: selectedDateStart,
      dateEnd: selectedDateEnd,
    } : {
      option: 1,
    },
    activeUsers: true,
  };

  $scope.getDataParams = {
    startsInPeriod: selectedDateStart && selectedDateEnd
      ? `${selectedDateStart.format('YYYY-MM-DD')}/${selectedDateEnd.format('YYYY-MM-DD')}`
      : undefined,
    'userCurrentEntityIds[]': defaultEntityIds,
    'absenceTypeIds[]': defaultTypeIds,
    'schedulesOfAccrual[]': defaultSchedulesOfAccrual,
    userId: defaultUserId,
    deleted: false,
    userIsActive: true,
    locale: SessionService.getUserLocale(),
    sortOrder: 'startDateAsc',
    page: 1,
    limit: 50,
  };

  $scope.loadData = async (reset) => {
    if ($scope.props.loadingData) {
      return;
    }

    $scope.props.loadingData = true;

    if (reset) {
      $scope.getDataParams.page = 1;
      $scope.props.noMoreData = false;
      $scope.props.data = [];
    }

    const { getDataParams } = $scope;
    const entityIds = getDataParams['userCurrentEntityIds[]'];
    const absenceTypeIds = getDataParams['absenceTypeIds[]'];
    const schedulesOfAccrual = getDataParams['schedulesOfAccrual[]'];

    $state.go('.', {
      start: selectedDateStart ? selectedDateStart.format('YYYY-MM-DD') : undefined,
      end: selectedDateEnd ? selectedDateEnd.format('YYYY-MM-DD') : undefined,
      entityIds: entityIds ? entityIds.join(',') : undefined,
      absenceTypeIds: absenceTypeIds ? absenceTypeIds.join(',') : undefined,
      schedulesOfAccrual: schedulesOfAccrual ? schedulesOfAccrual.join(',') : undefined,
      userId: getDataParams.userId,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
      inherit: false,
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    try {
      const { data } = await AvailabilityService.exportAllowances(getDataParams);
      const { results } = data;

      $scope.props.data.push(...results);
      $scope.getDataParams.page += 1;

      if (results.length < getDataParams.limit) {
        $scope.props.noMoreData = true;
      }

      $scope.props.loadingData = false;
      $scope.$apply();
    } catch ({ status }) {
      $scope.props.noMoreData = true;
      $scope.props.loadingData = false;

      if (status === 500) {
        AlertService.add('danger', translations['REPORTS.ALLOWANCE_EXPORT.ERROR_500']);
      }
    }
  };

  $scope.onDateFilter = ({
    option,
    dateEnd,
    dateStart,
  }) => {
    if (option === 1) {
      selectedDateStart = undefined;
      selectedDateEnd = undefined;
      $scope.getDataParams.startsInPeriod = undefined;
    } else {
      selectedDateStart = dateStart.clone();
      selectedDateEnd = dateEnd.clone();
      $scope.getDataParams.startsInPeriod = `${dateStart.format('YYYY-MM-DD')}/${dateEnd.format('YYYY-MM-DD')}`;
    }

    $scope.loadData(true);
  };

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.getDataParams['userCurrentEntityIds[]'] = entityIds.length ? entityIds : undefined;
    $scope.loadData(true);
  };

  $scope.onSchedulesOfAccrualFilter = (selectedOptions) => {
    $scope.getDataParams['schedulesOfAccrual[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  $scope.onAbsenceTypeFilter = (selectedOptions) => {
    $scope.getDataParams['absenceTypeIds[]'] = selectedOptions.length ? selectedOptions : undefined;
    $scope.loadData(true);
  };

  $scope.onActiveUsersFilter = () => {
    $scope.getDataParams.userIsActive = $scope.props.activeUsers ? true : undefined;
    $scope.loadData(true);
  };

  $scope.onUserFilter = (selectedUser) => {
    $scope.getDataParams.userId = selectedUser ? selectedUser.id : undefined;
    $scope.loadData(true);
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.AbsenceType,
  ])
    .then(([
      entityGroup,
      absenceType,
    ]) => {
      $scope.props.entityGroupList = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => ({
            id: entity.id,
            label: entity.name,
            parentId: entity.groupId,
            depth: 1,
          })),
        ]));

      $scope.props.absenceTypeList = absenceType.data
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));

      $scope.loadData();
    });

  $scope.export = async () => {
    $scope.props.loadingData = true;

    const {
      data: {
        signedUrl,
      },
    } = await AvailabilityService.exportAllowances($scope.getDataParams, {
      'Content-Type': 'text/csv',
    });

    if (signedUrl) {
      $window.location = signedUrl;
    }

    $scope.props.loadingData = false;
  };
};
