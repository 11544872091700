'use strict';
var controllers = require('../../../module');

controllers.controller('NewJoinerRequestsStaffCtrl', ['$scope', '$state', '$translate', 'StaffService', 'AlertService', 'EnvironmentDataService', 'SessionService',
    function ($scope, $state, $translate, StaffService, AlertService, EnvironmentDataService, SessionService) {
        var translations = $translate.instant([
            'STAFF.REQUESTS.NEW_JOINERS.APPOINTMENT_STATUS_ACTIVE',
            'STAFF.REQUESTS.NEW_JOINERS.APPOINTMENT_STATUS_INACTIVE',
        ]);

        $scope.props = {
            entityId: SessionService.getEntity(),
            loadingData: false,
            noMoreData: false,
            data: [],
            appointmentStatusOptions: [
                { id: 'active', label: translations['STAFF.REQUESTS.NEW_JOINERS.APPOINTMENT_STATUS_ACTIVE'] },
                { id: 'inactive', label: translations['STAFF.REQUESTS.NEW_JOINERS.APPOINTMENT_STATUS_INACTIVE'] },
            ],
            defaultDateFilter: {
                option: 4,
                dateStart: moment().subtract(4, 'weeks'),
                dateEnd: moment().add(4, 'weeks'),
            },
        };

        $scope.getDataParams = {
            'currentEntityIds[]': [$scope.props.entityId],
            'currentPositionIds[]': [],
            appointmentStatus: 1,
            approvalStatus: 3,
            limit: 20,
            page: 1,
            sortOrder: 'currentAppointmentStartAsc',
            dateContext: 5,
            start: $scope.props.defaultDateFilter.dateStart.format(),
            end: $scope.props.defaultDateFilter.dateEnd.format(),
        };

        $scope.onEntitySelect = function (entity) {
            $scope.getDataParams['currentEntityIds[]'] = entity ? [entity.id] : undefined;
            $scope.loadData(true);
        };

        $scope.onAppointmentStatusFilter = function (selectedOptions) {
            var option = selectedOptions[0];

            if (option === 'active') {
                $scope.getDataParams.appointmentStatus = 2;
            } else if (option === 'inactive') {
                $scope.getDataParams.appointmentStatus = 3;
            } else {
                $scope.getDataParams.appointmentStatus = 1;
            }

            $scope.loadData(true);
        };

        $scope.onPositionFilter = function (selectedOptions) {
            var option = selectedOptions[0];
            $scope.getDataParams['currentPositionIds[]'] = option || [];
            $scope.loadData(true);
        };

        $scope.onDateFilter = function (state) {
            var option = state.option;

            if (option === 1) {
                $scope.getDataParams.dateContext = undefined;
                $scope.getDataParams.start = undefined;
                $scope.getDataParams.end = undefined;
            } else {
                var start = state.dateStart;
                var end = state.dateEnd;
                $scope.getDataParams.dateContext = 5;

                if (option === 2) {
                    $scope.getDataParams.start = moment('1900-01-01').format();
                    $scope.getDataParams.end = start.format();
                } else if (option === 3) {
                    $scope.getDataParams.start = start.clone().add(1, 'day').format();
                    $scope.getDataParams.end = moment('2100-01-01').format();
                } else if (option === 4) {
                    $scope.getDataParams.start = start.format();
                    $scope.getDataParams.end = end.clone().add(1, 'day').format();
                }
            }

            $scope.loadData(true);
        };

      EnvironmentDataService.fetchAll([
        EnvironmentDataService.DataType.EntityGroup,
        EnvironmentDataService.DataType.Position,
      ])
        .then(([
          entityGroup,
          position,
        ]) => {
          $scope.props.entities = entityGroup.data;
          $scope.props.positions = position.data.map((item) => ({
            ...item,
            label: item.name,
          }));
        });

        $scope.loadData = function (reset) {
            if ($scope.props.loadingData) {
                return;
            }

            $scope.props.loadingData = true;

            if (reset) {
                $scope.getDataParams.page = 1;
                $scope.props.noMoreData = false;
                $scope.props.data = [];
            }

            StaffService.getAccounts($scope.getDataParams)
                .success(function (data) {
                    angular.forEach(data.users, function (user) {
                        $scope.props.data.push(user);
                    });

                    $scope.getDataParams.page += 1;

                    if (data.users.length < $scope.getDataParams.limit) {
                        $scope.props.noMoreData = true;
                    }

                    $scope.props.loadingData = false;
                })
                .error(function (error, status) {
                    if (status === 500) {
                        AlertService.add('danger', 'We weren\'t able to load new joiners just then. Please try again.');
                        $state.go('staff.overview');
                    }
                });
        };

        $scope.approve = function (user) {
            if (user.isApproving) {
                return;
            }

            user.isApproving = true;

            StaffService.approveAccount(user.id, { approve: true })
                .success(function () {
                    AlertService.add('success', 'They\'re now approved and ready to be given shifts.');

                    $scope.props.data.forEach(function (u, idx) {
                        if (u.id === user.id) {
                            $scope.props.data.splice(idx, 1);
                        }
                    });
                })
                .error(function (err, status) {
                    user.isApproving = false;

                    if (status !== 403) {
                        AlertService.add('danger', 'Oops, we couldn\'t approve the account just then.');
                    }
                });
        };
    }]);
