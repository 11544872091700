module.exports = [{
  id: 'en-gb',
  label: 'English',
}, {
  id: 'de-de',
  label: 'German',
}, {
  id: 'es-es',
  label: 'Spanish',
}, {
  id: 'it-it',
  label: 'Italian',
}, {
  id: 'fr-fr',
  label: 'French',
}, {
  id: 'nl-nl',
  label: 'Dutch',
}, {
  id: 'el-gr',
  label: 'Greek',
}, {
  id: 'sq-al',
  label: 'Albanian',
}, {
  id: 'ro-ro',
  label: 'Romanian',
}];
