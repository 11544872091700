'use strict';
var controllers = require('../../module');

controllers.controller('EditShiftTypeModalCtrl', ['$scope', '$uibModalInstance', '$translate', 'RotaService', 'EnvironmentDataService', 'SessionService', 'data',
    function ($scope, $uibModalInstance, $translate, RotaService, EnvironmentDataService, SessionService, data) {
        $scope.dataLoaded = false;
        $scope.formData = {};

        $scope.disabledParentIds = [];
        $scope.colours = new Array(16);
        $scope.isAdd = data.isAdd;
        $scope.addForm = {
            streamId: 0,
            amount: ''
        };

        var textById = $translate.instant([
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.NAME_REQUIRED',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.PARENT_REQUIRED',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.PARENT_VALID',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.PARENT_SAME_SITE',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.ATTRIBUTION_OVERAGE',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.ATTRIBUTION_VALID',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.ATTRIBUTION_NONE',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.ERRORS.ADD_ERROR',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.ERRORS.UPDATE_ERROR',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.DUPLICATE_COST_STREAM_MAPPING',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.CONFIRM.DELETE_SUB_TYPES',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.CONFIRM.DELETE_TYPE',
            'ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.ERRORS.DELETE_TYPE'
        ]);

        if (data.isAdd) {
            $scope.parentShiftType = data.shiftType;

            $scope.formData = {
                entityId: data.shiftType ? data.shiftType.entityId : null,
                colourId: 0,
                mappings: [],
                hasParent: !!data.shiftType,
                parentId: data.shiftType ? data.shiftType.id : 0
            };
        } else {
            $scope.shiftType = angular.copy(data.shiftType);
            $scope.formData = angular.copy(data.shiftType);

            $scope.formData.mappings = Object.keys(data.shiftType.streamMappings).map(function (id) {
                return {
                    streamId: parseInt(id, 10),
                    amount: data.shiftType.streamMappings[id]
                };
            });

            $scope.formData.hasParent = $scope.formData.parentId !== 0;

            delete $scope.formData.streamMappings;
            delete $scope.formData.children;
        }

        $scope.hasParentChanged = function () {
            if (!$scope.formData.hasParent) {
                $scope.formData.parentId = 0;
            }
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function updateDisabledParentIds(entityId) {
            $scope.disabledParentIds = [];

            // Disable itself (and any children)
            if (!$scope.isAdd) {
                $scope.disabledParentIds.push($scope.formData.id);
            }

            // Disable root notes in other entities
            angular.forEach($scope.metadata.shiftTypes, function (t) {
                if (t.depth === 0 && t.entityId !== entityId) {
                    $scope.disabledParentIds.push(t.id);
                }
            });
        }

        $scope.entityChanged = function (entity) {
            if ($scope.formData.entityId == entity.id) return;

            $scope.formData.parentId = 0;
            updateDisabledParentIds(entity ? entity.id : null);
        };

      EnvironmentDataService.fetchAll([
        EnvironmentDataService.DataType.EntityGroup,
        EnvironmentDataService.DataType.ShiftType,
        EnvironmentDataService.DataType.Stream,
      ])
        .then(([
          entityGroup,
          shiftType,
          stream,
        ]) => {
          $scope.metadata = {
            entities: entityGroup.data,
            shiftTypes: shiftType.data.filter(({ deleted, entityId }) => !deleted
              && (entityId === null || entityId === SessionService.getEntity())),
            costStreams: stream.data
              .filter(({ deleted, applicableToLabour }) => !deleted && applicableToLabour),
            costStreamsById: {},
          };

          stream.data.forEach((stream) => {
            $scope.metadata.costStreamsById[stream.id] = stream.name;
          });

          updateDisabledParentIds($scope.formData.entityId);

          $scope.dataLoaded = true;
        });

        $scope.save = function () {
            if (!$scope.formData.name || $scope.formData.name.length < 1) {
                return alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.NAME_REQUIRED']);
            }

            if ($scope.formData.hasParent) {
                if (!$scope.formData.parentId) {
                    return alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.PARENT_REQUIRED']);
                }

                var parentItem = false;

                angular.forEach($scope.metadata.shiftTypes, function (s) {
                    if (s.id === $scope.formData.parentId) {
                        parentItem = s;
                    }
                });

                if (!parentItem || $scope.formData.parentId === $scope.formData.id) {
                    return alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.PARENT_VALID']);
                }

                if (parentItem.entityId !== $scope.formData.entityId) {
                    return alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.PARENT_SAME_SITE']);
                }
            }

            let sumTotal = 0;
            let allValid = true;

            $scope.formData.mappings.forEach(({ amount: amountString }) => {
                const amount = parseFloat(amountString);

                if (Number.isNaN(amount) || amount <= 0 || amount > 100) {
                    allValid = false;
                    return;
                }

                sumTotal += amount;
            });

            if (sumTotal > 100) {
                return alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.ATTRIBUTION_OVERAGE']);
            }

            if (sumTotal === 0) {
                return alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.ATTRIBUTION_NONE']);
            }

            if (!allValid) {
                return alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.ATTRIBUTION_VALID']);
            }

            $scope.saveActioning = true;

            if ($scope.isAdd) {
                RotaService.addShiftType($scope.formData)
                    .success(function () {
                        EnvironmentDataService.invalidateCache(EnvironmentDataService.DataType.ShiftType);
                        $uibModalInstance.close();
                    })
                    .error(function () {
                        $scope.saveActioning = false;
                        alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.ERRORS.ADD_ERROR']);
                    });
            } else {
                RotaService.updateShiftType($scope.formData.id, $scope.formData)
                    .success(function () {
                        EnvironmentDataService.invalidateCache(EnvironmentDataService.DataType.ShiftType);
                        $uibModalInstance.close();
                    })
                    .error(function () {
                        $scope.saveActioning = false;
                        alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.ERRORS.UPDATE_ERROR']);
                    });
            }
        };

        $scope.removeMapping = function (index) {
            $scope.formData.mappings.splice(index, 1);
        };

        $scope.addMapping = function () {
            if (!$scope.addForm.streamId || !$scope.addForm.amount || isNaN($scope.addForm.amount)) {
                return;
            }

            var exists = false;

            $scope.formData.mappings.forEach(function (m) {
                if (m.streamId === $scope.addForm.streamId) {
                    exists = true;
                }
            });

            if (exists) {
                return alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.VALIDATION.DUPLICATE_COST_STREAM_MAPPING']);
            }

            $scope.formData.mappings.push(angular.copy($scope.addForm));

            $scope.addForm.streamId = 0;
            $scope.addForm.amount = '';
        };

        $scope.delete = function () {
            if ($scope.shiftType.children.length) {
                if (!confirm(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.CONFIRM.DELETE_SUB_TYPES'])) {
                    return;
                }
            } else {
                if (!confirm(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.CONFIRM.DELETE_TYPE'])) {
                    return;
                }
            }

            $scope.deleteActioning = true;

            RotaService.deleteShiftType($scope.shiftType.id)
                .success(function () {
                    $uibModalInstance.close();
                })
                .error(function () {
                    $scope.deleteActioning = false;
                    alert(textById['ROTA_MANAGEMENT.SHIFT_TYPES.EDIT_SHIFT_TYPES_MODAL.ERRORS.DELETE_TYPE']);
                });
        };
    }]);
