const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messaging/conversation.html');

import directives from '../module';
import conversationController from '../../controllers/messaging/conversation';

directives.directive('rrConversation', () => ({
  restrict: 'A',
  templateUrl: autoNgTemplateLoaderTemplate1,
  replace: false,
  controller: conversationController,
}));
