'use strict';
var controllers = require('../module');

controllers.controller('MonthlyAvailabilityModalCtrl', ['$scope', '$uibModalInstance', 'AvailabilityService', 'data', '$translate',
    function ($scope, $uibModalInstance, availabilityService, data, $translate) {
        var labelTranslations = $translate.instant([
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_LAST',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_FIRST',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_SECOND',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_THIRD',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_FOURTH',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_FIFTH',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.MONDAY',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.TUESDAY',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.WEDNESDAY',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.THURSDAY',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.FRIDAY',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.SATURDAY',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.SUNDAY',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.AVAILABLE',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.AVAILABLE_AM',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.AVAILABLE_PM',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.OTHER',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.MONTH',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.MONTHS',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.STOPS_BEFORE_STARTS',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.AVAILABILITY_END_BEFORE_START',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ERROR_SAVING_PATTERN',
            'AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ERROR_UPDATING_PATTERN',
        ])
        $scope.dataLoaded = data.isAdd;
        $scope.isAdd = data.isAdd;
        $scope.info = {
            hasStopDate: 0
        };
        $scope.rules = [];

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.options = {
            ordinals: [
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_LAST'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_FIRST'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_SECOND'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_THIRD'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_FOURTH'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ORDINAL_FIFTH'],
            ],
            months: [],
            days: [
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.MONDAY'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.TUESDAY'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.WEDNESDAY'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.THURSDAY'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.FRIDAY'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.SATURDAY'],
                labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.SUNDAY'],
            ],
            availability: [
                {id: 0, name: labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.AVAILABLE']},
                {id: 3, name: labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.AM_ONLY']},
                {id: 2, name: labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.PM_ONLY']},
                {id: 4, name: labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.OTHER']}
            ]
        };

        for (var i = 1; i <= 12; i++) {
            $scope.options.months.push({
                id: i-1,
                name: i === 1 ? ' ' + labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.MONTH'] : i + ' ' + labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.MONTHS']
            });
        }

        // Week pickers for setting start/end dates
        $scope.datePickerStart = moment().startOf('week');
        $scope.datePickerEnd = moment().add(1, 'month');

        $scope.startDateChanged = function (newDate) {
            $scope.datePickerStart = newDate.clone().startOf('week');
        };

        $scope.endDateChanged = function (newDate) {
            $scope.datePickerEnd = newDate.clone();
        };

        if (!data.isAdd) {
            availabilityService.getAvailabilityPattern(data.id)
                .success(function (pattern) {
                    $scope.info = pattern.info;

                    $scope.datePickerStart = moment(pattern.info.date_start);

                    if (pattern.info.date_end) {
                        $scope.info.hasStopDate = 1;
                        $scope.datePickerEnd = moment(pattern.info.date_end);
                    } else {
                        $scope.info.hasStopDate = 0;
                    }

                    $scope.rules = pattern.rules;

                    $scope.dataLoaded = true;
                })
                .error(function () {
                    $uibModalInstance.dismiss('cancel');
                });
        }

        var tempId = 0;
        $scope.addRule = function () {
            $scope.rules.push({
                id: 'temp' + tempId,
                period: 0,
                freq: 1,
                day: 0,
                option_id: 0,
                start_time: moment('2014-01-01 09:00:00'),
                end_time: moment('2014-01-01 17:00:00')
            });
            tempId++;
        };

        $scope.hideTimeControls = function (id) {
            angular.forEach($scope.rules, function (rule) {
                if (rule.id.toString() === id.toString()) {
                    rule.option_id = 0;
                }
            });
        };

        $scope.deleteRule = function (id) {
            angular.forEach($scope.rules, function (rule, key) {
                if (rule.id.toString() === id.toString()) {
                    $scope.rules.splice(key, 1);
                }
            });
        };

        $scope.parseInt = function(number) {
            return parseInt(number, 10);
        };

        $scope.saveAvailability = function () {
            if ($scope.info.hasStopDate && $scope.datePickerEnd.isSameOrBefore($scope.datePickerStart)) {
                return alert(labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.STOPS_BEFORE_STARTS']);
            }

            var toSend = {
                info: {
                    type: 2,
                    nickname: $scope.info.nickname,
                    dateStart: $scope.datePickerStart.format('YYYY-MM-DD'),
                    hasStopDate: $scope.info.hasStopDate,
                    dateEnd: $scope.datePickerEnd.format('YYYY-MM-DD'),
                    userId: data.userIdOverride
                },
                rules: angular.copy($scope.rules)
            };

            var rulesOk = true;

            angular.forEach(toSend.rules, function (rule) {
                if (rule.option_id !== 4 && rule.option_id !== 5) {
                    return;
                }

                var startTime = moment(rule.start_time);
                var endTime = moment(rule.end_time);

                if (endTime.format('Hm') !== '00' && endTime.isSameOrBefore(startTime)) {
                    rulesOk = false;
                    return;
                }

                rule.start_time = startTime.format('HH:mm:ss');
                rule.end_time = endTime.format('HH:mm:ss');
            });

            if (!rulesOk) {
                return alert(labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.AVAILABILITY_END_BEFORE_START']);
            }

            $scope.saveActioning = true;

            if (data.isAdd) {
                availabilityService.addAvailabilityPattern(toSend)
                    .success(function (newPattern) {
                        $uibModalInstance.close(newPattern);
                    })
                    .error(function (error, status) {
                        if (status == 403) {
                            $uibModalInstance.dismiss('cancel');
                            return;
                        }

                        $scope.saveActioning = false;
                        alert(labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ERROR_SAVING_PATTERN']);
                    });
            } else {
                availabilityService.updateAvailabilityPattern(data.id, toSend)
                    .success(function (updatedPattern) {
                        $uibModalInstance.close(updatedPattern);
                    })
                    .error(function (error, status) {
                        if (status == 403) {
                            $uibModalInstance.dismiss('cancel');
                            return;
                        }

                        $scope.saveActioning = false;
                        alert(labelTranslations['AVAILABILITY.MONTHLY_AVAILABILITY_MODAL.ERROR_UPDATING_PATTERN']);
                    });
            }
        };
    }]);
