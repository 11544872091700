const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/entitySelectorMulti.html');

var directives = require('./module');

directives.directive('rrEntitySelectorMulti', [function () {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        scope: {
            onSelect: '&',
            selectedEntityIds: '=?',
            entityList: '=?',
        },
        link: function ($scope) {
            $scope.props = {
                selectedEntityId: undefined,
            };

            function onChangesMade() {
                if ($scope.onSelect) {
                    $scope.onSelect({
                        entityIds: $scope.selectedEntityIds,
                    });
                }
            }

            $scope.onEntitySelect = function (entity) {
                if (!entity) {
                    return;
                }

                var index = $scope.selectedEntityIds.indexOf(entity.id);

                if (index === -1) {
                    $scope.selectedEntityIds.push(entity.id);
                }

                $scope.props.selectedEntityId = undefined;
                onChangesMade();
            };

            $scope.onEntityRemove = function (id) {
                var index = $scope.selectedEntityIds.indexOf(id);

                if (index !== -1) {
                    $scope.selectedEntityIds.splice(index, 1);
                }

                onChangesMade();
            };

            $scope.filterEntityDisplay = function (entity) {
                return $scope.selectedEntityIds.indexOf(entity.id) !== -1;
            };

            $scope.isEntitySelectedInGroup = function (group) {
                var entityIds = group.entities.map(function (entity) {
                    return entity.id;
                });

                return entityIds.some(function (id) {
                    return $scope.selectedEntityIds.indexOf(id) !== -1;
                });
            };
        }
    };
}]);
