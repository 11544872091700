var controllers = require('../../module');

controllers.controller('DashboardRmCtrl', ['$scope', '$filter', '$q', '$state', '$sce', 'RotaService', 'SessionService', 'AlertService', 'EnvironmentDataService',
    function ($scope, $filter, $q, $state, $sce, RotaService, SessionService, AlertService, EnvironmentDataService) {
        $scope.financialsLoaded = true;
        $scope.comparisonLoaded = true;
        $scope.staffingProfileLoaded = true;
        $scope.isLoaded = false;

        $scope.showStaffingProfile = true;
        $scope.showComparisonChart = true;
        $scope.showFinancialsChart = true;

        $scope.financialsWeek = moment().startOf('week');
        $scope.dashboardWeeks = [moment().subtract(1, 'week'), moment()];
        $scope.staffingProfileDay = moment();

        $scope.showSales = (SessionService.getSetting('costControl.salesForecasted') || 'true') === 'true';

        var horizon = SessionService.getSetting('rota.dashboard.horizon') ?
            parseInt(SessionService.getSetting('rota.dashboard.horizon')) : 2;

        for (var i = 1; i <= horizon; i++) {
            $scope.dashboardWeeks.push(moment().add(i, 'weeks'));
        }

        $scope.showProjectActual = true;

        $scope.formData = {
            projectActual: true,
        };

        $scope.props = {
          entityId: SessionService.getEntity(),
          loadingData: true,
          selectedTab: 'checklist',
        };

        EnvironmentDataService.fetch(EnvironmentDataService.DataType.EntityGroup)
          .then(({ data }) => {
            $scope.props.entityList = data;
            $scope.props.loadingData = false;
          });

        $scope.onEntitySelect = (entity) => {
          $scope.props.entityId = entity.id;
          $scope.selectTab($scope.props.selectedTab);
        };

        $scope.selectTab = (tab) => {
          $scope.props.selectedTab = tab;

          if (tab === 'checklist') {
            $scope.checklistSelected();
          } else if (tab === 'labour') {
            $scope.labourSpendSelected();
          }
        };

        $scope.intervalTypes = [
            {id: 1, name: 'Week(s)'},
            {id: 2, name: 'Month(s)'},
            {id: 3, name: 'Year(s)'}
        ];

        var startOfNextWeek = moment().add(1, 'week').startOf('week');

        $scope.labourComparison = {
            loaded: false,
            weekAChronology: -1,
            weekBChronology: 1,
            weekA: startOfNextWeek.clone().subtract(1, 'year').week(startOfNextWeek.week()).startOf('week'),
            weekB: startOfNextWeek.clone(),
            comparisonInterval: 1,
            comparisonIntervalType: 3
        };

        var columnChartOptions = {
            title: '',
            legend: 'none',
            vAxis: {
                gridlines: { count: 0 },
                viewWindow: { min: 0 }
            },
            series: {
                0: { color: '#C1CAD6' },
                1: { color: '#66C7F4' },
                2: { color: '#4286C5' }
            },
            chartArea: {
                width: '100%',
                height: '80%'
            },
            animation: {
                duration: 500,
                easing: 'in'
            },
            tooltip: {
                isHtml: true
            }
        };

        function getMaxValue(valueGroups) {
            var maxValue = 0;

            valueGroups.forEach(function (values) {
                values.forEach(function (value) {
                    if (value > maxValue) maxValue = value;
                });
            });

            return maxValue;
        }

        function getDisplayValue(value, groupIndex) {
            var displayValue = value;

            if (groupIndex === 0 || groupIndex === 1) {
                displayValue = $filter('currency')(displayValue);
            } else if (groupIndex === 2) {
                displayValue = $filter('number')(displayValue, 1);
            }

            return displayValue;
        }

        function getValueType(valueIndex) {
            var valueType;

            if (valueIndex === 0) {
                valueType = 'Forecast';
            } else if (valueIndex === 1) {
                valueType = 'Actual';
            } else if (valueIndex === 2) {
                valueType = 'Budget';
            }

            return valueType;
        }

        function getTooltipValue(valueType, displayValue, groupIndex) {
            var value = groupIndex === 3 ? $filter('percentage')(displayValue) : displayValue;
            return '<b>' + valueType + '</b><br />' + value;
        }

        function scaleValues(values, maxValue) {
            values.forEach(function (valueGroup, index) {
                var biggestValue = 0;
                var biggestValueIndex;

                valueGroup.forEach(function (value, index) {
                    if (value > biggestValue) {
                        biggestValue = value;
                        biggestValueIndex = index;
                    }
                });

                if (biggestValue === 0) return;

                valueGroup[biggestValueIndex] = maxValue;

                valueGroup.forEach(function (value, index) {
                    if (index === biggestValueIndex) return;

                    var newValue = (maxValue / biggestValue) * value;

                    valueGroup[index] = newValue;
                });
            });
        }

        function getValuesTitle(groupIndex) {
            var titles = ['Sales', 'Wages', 'Hours', 'Wage %'];

            if (!$scope.showSales) {
                titles = ['Wages', 'Hours'];
            }

            return titles[groupIndex];
        }

        function getValuesFromResponse(response) {
            return response.byEntityGroup[0].byEntity[0].byType;
        }

        function updateFinancialsChart(values) {
            $scope.financialsChart = {
                data: {
                    cols: [
                        {id: 't', label: 'Type', type: 'string'},
                        {id: 'f', label: 'Forecasted', type: 'number'},
                        {id: 't', label: 'Tooltip', type: 'string', role: 'tooltip', p: {html: true}},
                        {id: 'a', label: 'Actual', type: 'number'},
                        {id: 't', label: 'Tooltip', type: 'string', role: 'tooltip', p: {html: true}},
                        {id: 'b', label: 'Budgeted', type: 'number'},
                        {id: 't', label: 'Tooltip', type: 'string', role: 'tooltip', p: {html: true}}
                    ],
                    rows: []
                },
                type: 'ColumnChart',
                options: columnChartOptions
            };

            var finalValues = [
                [values.forecast.total.sales, values.actual.total.sales, values.budget.total.sales],
                [values.forecast.total.wages, values.actual.total.wages, values.budget.total.wages],
                [values.forecast.total.hours, values.actual.total.hours, values.budget.total.hours],
                [values.forecast.total.wagePercentage, values.actual.total.wagePercentage, values.budget.total.wagePercentage]
            ];

            $scope.financialsDisplayValues = [];
            var tooltipValues = [];

            finalValues.forEach(function (values, groupIndex) {
                $scope.financialsDisplayValues.push([]);
                tooltipValues.push([]);

                values.forEach(function (value, index) {
                    var displayValue = getDisplayValue(value, groupIndex);

                    $scope.financialsDisplayValues[groupIndex].push(displayValue);
                    tooltipValues[groupIndex].push(getTooltipValue(getValueType(index), displayValue, groupIndex));
                });
            });

            if (!$scope.showSales) {
                finalValues.splice(0, 1);
                finalValues.splice(2, 1);
                $scope.financialsDisplayValues.splice(0, 1);
                $scope.financialsDisplayValues.splice(2, 1);
                tooltipValues.splice(0, 1);
                tooltipValues.splice(2, 1);
            }

            var maxValue = getMaxValue(finalValues);

            scaleValues(finalValues, maxValue);

            finalValues.forEach(function (values, groupIndex) {
                var valuesTitle = getValuesTitle(groupIndex);

                $scope.financialsChart.data.rows.push({
                    c: [{ v: valuesTitle }, { v: values[0] }, { v: tooltipValues[groupIndex][0] },
                        { v: values[1] }, { v: tooltipValues[groupIndex][1] }, { v: values[2] },
                        { v: tooltipValues[groupIndex][2] }]
                });
            });
        }

        function updateComparisonChart(weeks) {
            var weekAValues = $scope.labourComparison.weekAChronology > 0 ?
                weeks[0].forecast.total : weeks[0].actual.total;
            var weekBValues = $scope.labourComparison.weekBChronology > 0 ?
                weeks[1].forecast.total : weeks[1].actual.total;

            var finalValues = [
                [weekAValues.sales, weekBValues.sales],
                [weekAValues.wages, weekBValues.wages],
                [weekAValues.hours, weekBValues.hours],
                [weekAValues.wagePercentage, weekBValues.wagePercentage]
            ];

            var tooltipValues = [];
            $scope.comparisonDisplayValues = [];

            finalValues.forEach(function (values, groupIndex) {
                $scope.comparisonDisplayValues.push([]);
                tooltipValues.push([]);

                values.forEach(function (value, index) {
                    var displayValue = getDisplayValue(value, groupIndex);

                    $scope.comparisonDisplayValues[groupIndex].push(displayValue);
                    tooltipValues[groupIndex].push(getTooltipValue(getValueType(index), displayValue, groupIndex));
                });
            });

            if (!$scope.showSales) {
                finalValues.splice(0, 1);
                finalValues.splice(2, 1);
                $scope.comparisonDisplayValues.splice(0, 1);
                $scope.comparisonDisplayValues.splice(2, 1);
                tooltipValues.splice(0, 1);
                tooltipValues.splice(2, 1);
            }

            // Add the deltas
            finalValues.forEach(function (group, groupIndex) {
                $scope.comparisonDisplayValues[groupIndex][2] =
                    getDisplayValue(finalValues[groupIndex][1] - finalValues[groupIndex][0], groupIndex);
            });

            $scope.comparisonChart = {
                data: {
                    cols: [
                        {id: 't', label: 'Type', type: 'string'},
                        {id: 'f', label: 'Forecasted', type: 'number'},
                        {id: 't', label: 'Tooltip', type: 'string', role: 'tooltip', p: {html: true}},
                        {id: 'a', label: 'Actual', type: 'number'},
                        {id: 't', label: 'Tooltip', type: 'string', role: 'tooltip', p: {html: true}},
                    ],
                    rows: []
                },
                type: 'ColumnChart',
                options: columnChartOptions
            };

            var maxValue = getMaxValue(finalValues);

            scaleValues(finalValues, maxValue);

            finalValues.forEach(function (values, groupIndex) {
                var valuesTitle = getValuesTitle(groupIndex);

                $scope.comparisonChart.data.rows.push({
                    c: [{ v: valuesTitle }, { v: values[0] }, { v: tooltipValues[groupIndex][0] },
                        { v: values[1] }, { v: tooltipValues[groupIndex][1] }]
                });
            });
        }

        function updateStaffingProfileChart(data) {
            $scope.staffingProfileChart = {
                data: {
                    cols: [
                        { id: 't', label: 'Time', type: 'string' },
                        { id: 's', label: 'Scheduled staff', type: 'number' },
                        { id: 'l', label: 'Live staff', type: 'number' }
                    ],
                    rows: []
                },
                type: 'SteppedAreaChart',
                options: {
                    title: '',
                    legend: 'none',
                    hAxis: {
                        showTextEvery: 4
                    },
                    vAxis: {
                        viewWindow: { min: 0 },
                        viewWindowMode: 'explicit'
                    },
                    series: {
                        0: {
                            color: '#C1CAD6'
                        },
                        1: {
                            color: '#FF1053'
                        }
                    },
                    chartArea: {
                        width: '90%',
                        height: '70%'
                    },
                    focusTarget: 'category'
                }
            };

            $scope.staffingProfileChart.data.rows = data.scheduled.map(function (interval) {
                return {
                    c: [
                        { v: moment.unix(interval.timestamp).format('HH:mm') },
                        { v: interval.total }
                    ]
                };
            });

            data.live.map(function (interval) {
                var scheduledIntervalIndex;

                data.scheduled.forEach(function (i, index) {
                    if (i.timestamp === interval.timestamp) scheduledIntervalIndex = index;
                });

                if (!$scope.staffingProfileChart.data.rows[scheduledIntervalIndex]) return;

                $scope.staffingProfileChart.data.rows[scheduledIntervalIndex].c.push({ v: interval.total });
            });
        }

        $scope.updateLabourComparisonWeekA = function () {
            var intervalType;

            switch ($scope.labourComparison.comparisonIntervalType) {
                case 1:
                    intervalType = 'week';
                    break;
                case 2:
                    intervalType = 'month';
                    break;
                case 3:
                default:
                    intervalType = 'year';
                    break;
            }

            var week = $scope.labourComparison.weekB.clone().subtract($scope.labourComparison.comparisonInterval, intervalType);

            if ($scope.labourComparison.comparisonIntervalType === 3) {
                week.week($scope.labourComparison.weekB.week());
            }

            week.startOf('week');

            $scope.labourComparison.weekA = week;
            $scope.labourComparison.weekAChronology = $scope.labourComparison.weekA.diff(moment().startOf('week'));

            updateLabourComparison();
        };

        $scope.updateFinancials = function() {
            $scope.financialsLoaded = false;

            RotaService.getRotaFinancials($scope.financialsWeek, $scope.formData.projectActual &&
                $scope.financialsWeek.isSameOrAfter(moment().startOf('week')), $scope.props.entityId)
                .success(function (data) {
                    $scope.financialsLoaded = true;

                    updateFinancialsChart(getValuesFromResponse(data.financials));
                })
                .error(function (err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', 'We couldn\'t update the chart just then. Please try again.');
                    }

                    $scope.financialsLoaded = true;
                });
        };

        $scope.financialsWeekChanged = function (newDate, oldDate) {
            newDate.startOf('week');
            $scope.financialsWeek = newDate;

            $scope.showProjectActual = $scope.financialsWeek.isSameOrAfter(moment().startOf('week'));

            if (!newDate.isSame(oldDate)) {
                $scope.updateFinancials();
            }
        };

        function updateLabourComparison() {
            $scope.labourComparison.loaded = false;

            return RotaService.getWeekComparison($scope.labourComparison.weekA, $scope.labourComparison.weekB, $scope.props.entityId)
                .success(function (data) {
                    updateComparisonChart([getValuesFromResponse(data.weeks[0]),
                        getValuesFromResponse(data.weeks[1])]);

                    $scope.labourComparison.loaded = true;
                })
                .error(function (err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', 'We couldn\'t update the chart just then. Please try again.');
                    }

                    $scope.labourComparison.loaded = true;
                });
        }

        $scope.labourComparisonWeekBChanged = function (newDate, oldDate) {
            if (!newDate) {
                return;
            }

            newDate.startOf('week');

            $scope.labourComparison.weekB = newDate;
            $scope.labourComparison.weekBChronology = $scope.labourComparison.weekB.diff(moment().startOf('week'));

            $scope.updateLabourComparisonWeekA();
        };

        $scope.checklistSelected = function () {
            $scope.isLoaded = false;

            RotaService.getDashboardWeeks($scope.dashboardWeeks, $scope.props.entityId)
                .then(function (resp) {
                    $scope.weeks = resp.data.results;
                    $scope.isLoaded = true;
                })
                .catch(function (err) {
                    if (err.status !== 403) {
                        AlertService.add('danger', 'Oops, something just went wrong. You may have to try again!');
                    }
                });
        };

        $scope.staffingProfileDayChanged = function (newDate, oldDate) {
            if (newDate.isSame(oldDate)) return;

            $scope.staffingProfileDay = newDate;

            $scope.staffingProfileLoaded = false;

            RotaService.getStaffingProfile($scope.staffingProfileDay, $scope.props.entityId)
                .success(function (data) {
                    $scope.staffingProfileLoaded = true;

                    updateStaffingProfileChart(data);
                })
                .error(function (err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', 'We couldn\'t update the chart just then. Please try again.');
                    }

                    $scope.staffingProfileLoaded = true;
                });
        };

        $scope.labourSpendSelected = function () {
            $scope.isLoaded = false;

            RotaService.getStaffingProfile($scope.staffingProfileDay, $scope.props.entityId)
                .catch(function (err) {
                    if (err.status === 403) {
                        $scope.showStaffingProfile = false;
                        return $q.resolve();
                    } else {
                        return $q.reject(err);
                    }
                })
                .then(function (resp) {
                    if (resp) updateStaffingProfileChart(resp.data);

                    return updateLabourComparison();
                })
                .catch(function (err) {
                    if (err.status === 403) {
                        $scope.showComparisonChart = false;
                        return $q.resolve();
                    } else {
                        return $q.reject(err);
                    }
                })
                .then(function (resp) {
                    if (resp) updateComparisonChart([getValuesFromResponse(resp.data.weeks[0]),
                        getValuesFromResponse(resp.data.weeks[1])]);

                    return RotaService.getRotaFinancials($scope.financialsWeek, $scope.formData.projectActual, $scope.props.entityId);
                })
                .catch(function (err) {
                    if (err.status === 403) {
                        $scope.showFinancialsChart = false;
                        return $q.resolve();
                    } else {
                        return $q.reject(err);
                    }
                })
                .then(function (resp) {
                    if (resp) {
                        updateFinancialsChart(getValuesFromResponse(resp.data.financials));
                    }

                    $scope.isLoaded = true;
                });
        };
    }]);
