'use strict';
var services = require('./module');

services.factory('SessionService', ['$http', '$cookies', '$rootScope', '$locale', 'tmhDynamicLocale', 'localStorageService', 'NotificationService', 'ENDPOINT_API', 'INTERCOM_ID', 'PUBNUB_SUBSCRIBE_KEY', 'PUBNUB_PUBLISH_KEY', 'COOKIE_DOMAIN', 'SELECTED_ENTITY_COOKIE_NAME', 'ASSET_REGISTRY_ENABLED', 'VISAS_ENABLED',
    function ($http, $cookies, $rootScope, $locale, tmhDynamicLocale, localStorageService, NotificationService, ENDPOINT_API, INTERCOM_ID, PUBNUB_SUBSCRIBE_KEY, PUBNUB_PUBLISH_KEY, COOKIE_DOMAIN, SELECTED_ENTITY_COOKIE_NAME, ASSET_REGISTRY_ENABLED, VISAS_ENABLED) {
        var sessionService = {};
        var key = 'sessionService.data';
        var isInitialised = false;
        var isInitialising = false;
        var moduleVisibility = false;

        function persistSessionLocally() {
            localStorageService.set(key, $rootScope.sessionData);
        }

        function setEntityOnRootScope(entity) {
          $rootScope.sessionData.user.entityId = entity.id;
          $rootScope.sessionData.user.entityName = entity.name;
          $rootScope.sessionData.user.entityTimezone = entity.timezone;
          $rootScope.sessionData.user.entityLocale = entity.locale;
        }

        sessionService.init = function (forceRefresh, callback) {
            // Prevent double initialising of the session service
            if (isInitialising) {
                return;
            }

            isInitialised = false;
            isInitialising = true;
            $rootScope.sessionData = localStorageService.get(key);

            // The session data is cached in local storage, this will invalidate this cache
            // and force a refresh API call whenever the expiry has elapsed
            if ($rootScope.sessionData && $rootScope.sessionData.expiry) {
                var expiry = moment($rootScope.sessionData.expiry);

                if (expiry.isValid() && expiry.isBefore()) {
                    forceRefresh = true;
                } else {
                    sessionService.attachSettings();

                    NotificationService.init(false, sessionService.getUserId());
                    return callback();
                }
            }

            if (!$rootScope.sessionData || !angular.isObject($rootScope.sessionData) || forceRefresh) {
                $http.get(ENDPOINT_API + '/session')
                    .success(function (result) {
                        $rootScope.sessionData = result;

                        result.expiry = moment().add(1, 'hour').toDate();
                        persistSessionLocally();

                        sessionService.attachSettings();

                        NotificationService.init($rootScope.sessionData.notifications, sessionService.getUserId());
                        return callback();
                    });
            }
        };

        sessionService.attachSettings = function () {
            const selectedEntity = $cookies.get(SELECTED_ENTITY_COOKIE_NAME);

            if (selectedEntity) {
              let entity;

              try {
                entity = JSON.parse(selectedEntity);
                setEntityOnRootScope(entity);
              } catch (e) {}
            }

            var localOffset = moment().format('Z');

            // Ignore the client's local timezone, we want to override this to the timezone
            // that the entity is in (which might differ). Thus, any dates received from the
            // server (in UTC or another zone) will get automatically adjusted accordingly
            moment.tz.setDefault(sessionService.getTimezone());

            var entityOffset = moment().format('Z');

            // By taking the offset before and after setting the timezone, we can highlight
            // to the user if the entity's offset differs to their local one...
            // (it's possible to be in a different tz but with the same offset, don't care about that)
            $rootScope.locale = {
                zoneAbbr: moment().format('z') + ' (GMT' + entityOffset + ')',
                zoneDiffersToLocal: localOffset != entityOffset
            };

            // Set the first day of the week to the entity's setting
            // This affects the way calendars and rotas are displayed
            var localeObj = {
                week: {
                    dow: parseInt(sessionService.getSetting('locale.firstDow'))
                }
            };

            var currentLocale = sessionService.getLocale();
            // For en-gb we have a few little overrides
            if (currentLocale == 'en-gb') {
                localeObj.longDateFormat = {
                    LT: 'HH:mm',
                    LTS: 'HH:mm:ss',
                    // Short date
                    L: 'DD/MM/YYYY',
                    // Date
                    LL: 'dddd D MMMM YYYY', // default is: D MMMM YYYY
                    // Date with time
                    LLL: 'D MMMM YYYY HH:mm',
                    // Long date with time
                    LLLL: 'dddd D MMMM YYYY [at] HH:mm' // default is: dddd, D MMMM YYYY HH:mm
                };
            }

            // Set the entity's locale on Moment.js and apply any overrides
            // This ensures dates/times are formatted appropriately... ie DD/MM/YY (uk) vs MM/DD/YY (usa)
            moment.locale(currentLocale);
            moment.updateLocale(moment.locale(), localeObj);

            // This forces Angular to use the new locale, which affects number formatting and other minor things
            tmhDynamicLocale.set(currentLocale);

            // Set what tabs/areas to make available to the user based on their permission keys
            // The API will check the permissions of any request, so this is just for display purposes
            moduleVisibility = {
                rota: sessionService.isPermissioned('rota'),
                staff: sessionService.isPermissioned('staff'),
                reports: sessionService.isPermissioned('report'),
                attendance: sessionService.isPermissioned('attendance'),
                eventCalendar: sessionService.isPermissioned('notableEvents'),
                assetRegistry: ASSET_REGISTRY_ENABLED,
            };

            // For now, only load Intercom for management-esque users
            if (moduleVisibility.rota || moduleVisibility.staff) {
                window.Intercom('update', {
                    app_id: INTERCOM_ID,
                    email: sessionService.getEmail(),
                    name: sessionService.getUserFullName(),
                    user_id: sessionService.getPlatformUserId(),
                    phone: sessionService.getUserMobile(),
                    company: {
                        company_id: sessionService.getRealm().id,
                        name: sessionService.getRealm().name,
                        'Realm ID': sessionService.getRealm().id,
                    },
                    'Current Entity Name': sessionService.getEntityName(),
                    'Current Entity ID': sessionService.getEntity(),
                    'Realm User Id': sessionService.getUserId(),
                    user_hash: sessionService.getUserIntercomHash(),
                });
            }

            isInitialised = true;
            isInitialising = false;

            $rootScope.$emit('session:loaded');
        };

        sessionService.isInitialised = function () {
            return isInitialised;
        };

        sessionService.destroy = function () {
            localStorageService.remove(key);
            $cookies.remove(SELECTED_ENTITY_COOKIE_NAME, { domain: COOKIE_DOMAIN, path: '/' });
        };

        sessionService.getSetting = function (key) {
            if ($rootScope.sessionData.settings.hasOwnProperty(key)) {
                return $rootScope.sessionData.settings[key];
            }

            return null;
        };

        // Permissions
        sessionService.isPermissioned = function (key) {
            if ($rootScope.sessionData.permissions.fullAccess) {
                return true;
            } else {
                return $rootScope.sessionData.permissions.keys.indexOf(key) != -1;
            }
        };

        // User/entity session info
        sessionService.getUserId = function () {
            return $rootScope.sessionData.user.userId;
        };

        sessionService.getPlatformUserId = function () {
            return $rootScope.sessionData.user.platformUserId;
        };

        sessionService.getUsername = function () {
            return $rootScope.sessionData.user.userName;
        };

        sessionService.setEmail = function (email) {
            $rootScope.sessionData.user.userEmail = email;
        };

        sessionService.getEmail = function () {
            return $rootScope.sessionData.user.userEmail;
        };

        sessionService.getUserFullName = function () {
            return $rootScope.sessionData.user.userFirstName + ' ' + $rootScope.sessionData.user.userLastName;
        };

        sessionService.getUserMobile = function () {
            return $rootScope.sessionData.user.userMobile;
        };

        sessionService.getUserIntercomHash = function () {
            return $rootScope.sessionData.user.intercomHash;
        };

        sessionService.getEntity = function () {
            return $rootScope.sessionData.user.entityId;
        };

        sessionService.setEntity = (entity) => {
          const expires = new Date();
          expires.setDate(expires.getDate() + 365);

          $cookies.put(SELECTED_ENTITY_COOKIE_NAME, JSON.stringify(entity), {
            domain: COOKIE_DOMAIN,
            path: '/',
            expires,
          });

          setEntityOnRootScope(entity);
        };

        sessionService.getEntityName = function () {
            return $rootScope.sessionData.user.entityName;
        };

        sessionService.getGroupId = function () {
            return $rootScope.sessionData.user.userGroupId;
        };

        sessionService.getTimezone = function () {
            return $rootScope.sessionData ? $rootScope.sessionData.user.entityTimezone : 'Europe/London';
        };

        sessionService.getLocale = function () {
            return $rootScope.sessionData ? $rootScope.sessionData.user.entityLocale : 'en-gb';
        };

        sessionService.getUserLocale = function () {
            return $rootScope.sessionData ? $rootScope.sessionData.user.userLocale : 'en-gb';
        };

        sessionService.getLocaleCountryCode = function () {
            return sessionService.getLocale().split('-')[1];
        };

        sessionService.getLocaleLanguageCode = function () {
            return sessionService.getLocale().split('-')[0];
        };

        sessionService.getRealm = function () {
            return $rootScope.sessionData.realm;
        };

        sessionService.getUserMessagingHash = function () {
            return $rootScope.sessionData.user.messagingUserIdHash;
        };

        sessionService.getModuleVisibility = function () {
            return moduleVisibility;
        };

        sessionService.getUserDateLastVisited = function () {
            return $rootScope.sessionData.user.userDateLastVisit || false;
        };

        // Environment & visibility
        sessionService.isMessagingEnabled = function () {
            let isEnabled = false;

            if (sessionService.getSetting('messaging.enabled') === 'true' && PUBNUB_SUBSCRIBE_KEY && PUBNUB_PUBLISH_KEY) {
                isEnabled = true;
            }
            return isEnabled;
        };

        sessionService.isUserSelfOnboardingEnabled = function () {
            return sessionService.getSetting('userSelfOnboarding.enabled') === 'true';
        }

        sessionService.is12HourClockMode = function () {
            return moment.localeData().longDateFormat('LT').toLocaleLowerCase().indexOf('a') !== -1;
        };

        // Preferences control
        sessionService.getPreferences = function () {
            return $http.get(ENDPOINT_API + '/preferences');
        };

        sessionService.updatePreferences = function (data) {
            return $http.put(ENDPOINT_API + '/preferences', data);
        };

        sessionService.getUserSetting = function (key) {
            return $rootScope.sessionData.userSettings ? $rootScope.sessionData.userSettings[key] : undefined;
        };

        sessionService.updateUserSetting = function (key, value) {
            if (!$rootScope.sessionData.userSettings) {
                $rootScope.sessionData.userSettings = {};
            }

            $rootScope.sessionData.userSettings[key] = value;

            persistSessionLocally();

            var settings = {};
            settings[key] = value;

            return $http.put(ENDPOINT_API + '/settings', {
                settings: settings,
            });
        };

        sessionService.getLanguage = function () {
            return $rootScope.sessionData.user.userLocale;
        };

        sessionService.setLanguage = function (language) {
            $rootScope.sessionData.user.userLocale = language;
            persistSessionLocally();
        };

        sessionService.isNotableEventsSelfServiceEnabled = function () {
            return sessionService.getSetting('notableEventsSelfService.enabled') === 'true';
        };

        sessionService.areShiftRoutinesEnabled = function () {
            return sessionService.getSetting('shiftRoutines.enabled') === 'true';
        };

        sessionService.isAssetRegistryEnabled = function () {
            return !!ASSET_REGISTRY_ENABLED;
        };

        sessionService.ssdEnabled = function () {
            return sessionService.getSetting('ssd.enabled') === 'true';
        };

        sessionService.dashboardsEnabled = function () {
            return sessionService.getSetting('dashboards.enabled') === 'true';
        };

        sessionService.areVisasEnabled = function () {
            return !!VISAS_ENABLED;
        }

        sessionService.isLabourDeploymentEnabled = function () {
            return sessionService.getSetting('labourDeploymentModels.enabled') === 'true';
        };

        return sessionService;
    }])

    .factory('SessionInterceptor', ['$injector',
        function SessionInterceptor($injector) {
            return {
                request: function (config) {
                    // Callers can opt-out of the request interceptor, useful when
                    // calling 3rd party APIs, such as direct-to-S3 file uploads
                    if (config.noIntercept) {
                        return config;
                    }

                    var timezone = $injector.get('SessionService').getTimezone();
                    if (timezone) {
                        config.headers['Time-Zone'] = timezone;
                    }
                    return config;
                }
            };
        }
    ])

    .config(['$httpProvider',
        function ($httpProvider) {
            $httpProvider.interceptors.push('SessionInterceptor');

            //initialize get if not there
            if (!$httpProvider.defaults.headers.get) {
                $httpProvider.defaults.headers.get = {};
            }
            //disable IE9 ajax request caching
            $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';
        }
    ])

    .config(['tmhDynamicLocaleProvider',
        function (tmhDynamicLocaleProvider) {
            tmhDynamicLocaleProvider.localeLocationPattern('angular-i18n/angular-locale_{{locale}}.js');
        }]);
