const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/emergencyContacts/addModal.html');

module.exports = (
  $scope,
  $uibModalDrawer,
  $translate,
  $window,
  StaffService,
  AlertService,
  EnvironmentDataService,
) => {
  'ngInject';

  const {
    userData: {
      id: userId,
      personal,
    },
  } = $scope;

  $scope.props = {
    permitted: !!personal,
    loadingData: true,
    relationshipById: {},
    data: [],
  };

  const translations = $translate.instant([
    'STAFF.VIEW.EMERGENCY_CONTACTS.INDEX.ALERT_ERROR_500',
    'STAFF.VIEW.EMERGENCY_CONTACTS.INDEX.CONFIRM_DELETE',
  ]);

  EnvironmentDataService.fetch(EnvironmentDataService.DataType.EmergencyContactRelationship)
    .then(({ data }) => {
      data.forEach(({ id, title }) => {
        $scope.props.relationshipById[id] = title;
      });

      if (!$scope.props.permitted) {
        return;
      }

      StaffService.getEmergencyContacts(userId)
        .then(({ data }) => {
          $scope.props.data = data.emergencyContacts;
          $scope.props.loadingData = false;
        })
        .catch(({ status }) => {
          $scope.close();

          if (status !== 403) {
            AlertService.add('danger', translations['STAFF.VIEW.EMERGENCY_CONTACTS.ADD_MODAL.ALERT_ERROR_500']);
          }
        });
    });

  $scope.addEmergencyContact = () => {
    const modalInstance = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./addModal'),
      resolve: {
        data: () => ({
          userId,
        }),
      },
    });

    modalInstance.result.then((result) => {
      if (result) {
        $scope.props.data.push(result.emergencyContact);
      }
    });
  };

  $scope.removeContact = (contact, index) => {
    if (contact.isDeleting) {
      return;
    }

    if (!$window.confirm(translations['STAFF.VIEW.EMERGENCY_CONTACTS.INDEX.CONFIRM_DELETE'])) {
      return;
    }

    contact.isDeleting = true;

    StaffService.removeEmergencyContact(userId, contact.id)
      .then(() => {
        $scope.props.data.splice(index, 1);
      })
      .catch(() => {
        $scope.close();
      });
  };
};
