const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/tagsGroups/viewTagModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/tagsGroups/viewGroupModal.html');

'use strict';
var controllers = require('../../module');

controllers.controller('TagsGroupsStaffCtrl', ['$scope', '$uibModal', '$state', 'StaffService', 'AlertService', 'SessionService', 'CommonService',
    function ($scope, $uibModal, $state, StaffService, AlertService, SessionService, CommonService) {
        $scope.formData = {};

        function refreshTags() {
            $scope.tagsLoaded = false;

            StaffService.getTags()
                .success(function (data) {
                    $scope.tags = data;

                    $scope.tagSizeBounds = [];

                    var max = Math.max.apply(Math, data.map(function (o) {
                        return o.userCount;
                    }));

                    if (!max || max < 10) {
                        $scope.tagSizeBounds = [4,8];
                    } else {
                        $scope.tagSizeBounds = [Math.round(max*0.33), Math.round(max*0.66)];
                    }

                    $scope.tagsLoaded = true;
                })
                .error(function () {
                    AlertService.add("danger", "We couldn't load your tags. Please try again.");
                    $state.go('^.overview');
                });
        }

        function refreshGroups() {
            $scope.groupsLoaded = false;

            CommonService.getMetadata(['staffGroups'], [SessionService.getEntity()], true)
                .success(function (data) {
                    $scope.staffGroups = data.staffGroups;
                    $scope.groupsLoaded = true;
                })
                .error(function () {
                    AlertService.add("danger", "We couldn't load your staff groups. Please try again.");
                    $state.go('^.overview');
                });
        }

        refreshTags();
        refreshGroups();

        $scope.addTagLoading = false;
        $scope.addTag = function () {
            if (!$scope.formData.tagName || $scope.formData.tagName.length < 1)
                return;

            $scope.addTagLoading = true;

            StaffService.addTag({ name: $scope.formData.tagName })
                .success(function (data) {
                    $scope.tags.push(data);

                    $scope.addTagLoading = false;
                    $scope.formData.tagName = '';
                    AlertService.add("success", "Success! Your tag has been added.");
                })
                .error(function () {
                    $scope.addTagLoading = false;
                    AlertService.add("danger", "We weren't able to add that tag.\n\nPlease check the name is unique and try again.");
                });
        };

        $scope.viewTag = function (tagId) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'ViewTagModalCtrl',
                resolve: {
                    data: function () {
                        return tagId;
                    }
                }
            });

            modalInstance.result.then(function () {
                refreshTags();
            });
        };

        $scope.editGroup = function (groupId) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'ViewGroupModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            id: groupId
                        }
                    }
                }
            });

            modalInstance.result.then(function (data) {
                // Delete the group
                if (angular.isNumber(data)) {
                    angular.forEach($scope.staffGroups, function (group, key) {
                        if (group.id.toString() === data.toString()) {
                            $scope.staffGroups.splice(key, 1);
                        }
                    });

                    AlertService.add('success', 'The group has been deleted.');
                } else {
                    refreshGroups();
                }
            });
        };

        $scope.addGroupLoading = false;
        $scope.addGroup = function () {
            if (!$scope.formData.groupName || $scope.formData.groupName.length < 1)
                return;

            $scope.addGroupLoading = true;

            StaffService.addGroup({
                name: $scope.formData.groupName,
            })
                .success(function (data) {
                    $scope.staffGroups.push(data);

                    $scope.addGroupLoading = false;
                    $scope.formData.groupName = '';
                    AlertService.add("success", "Success! Your group has been added.");
                })
                .error(function () {
                    $scope.addGroupLoading = false;
                    AlertService.add("danger", "We weren't able to add that group. You may not have permission to do so.");
                });
        };
    }]);
