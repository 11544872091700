const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/messaging/message.html');

import directives from '../module';

directives.directive('rrConversationMessage', () => ({
  restrict: 'A',
  templateUrl: autoNgTemplateLoaderTemplate1,
  replace: false,
  controller: ($scope, SessionService) => {
    $scope.ownUserId = SessionService.getUserId();
  },
}));
