const directives = require('./module');

directives.directive('rrFillRemainingHeight', (
  $window,
  $timeout,
) => {
  'ngInject';

  return {
    restrict: 'A',
    link: ($scope, element) => {
      function recalculate() {
        const { top } = element[0].getBoundingClientRect();
        const height = $window.innerHeight - top;
        element.css('height', `${Math.max(height, 0)}px`);
      }

      angular.element($window).bind('resize', () => {
        recalculate();
      });

      $timeout(() => {
        recalculate();
      }, 0);
    },
  };
});
