import gql from 'graphql-tag';
import { TroncUserApportion } from '../../../../fragments';
import controllers from '../../../module';

controllers.controller('AddTroncModalCtrl', ['$scope', '$uibModalInstance', '$translate', 'StaffService', 'AlertService', 'SessionService', 'apollo', 'data',
  function ($scope, $uibModalInstance, $translate, StaffService, AlertService, SessionService, apollo, data) {
    const apportionModeSetting = SessionService.getSetting('tronc.apportion.type') || 'BASIC';
    const userId = data.user.id;

    const translations = $translate.instant([
      'STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_OTHER',
      'STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_UNAUTHORIZED',
      'STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE',
      'STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE',
      'STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_NOT_SUPPORTED',
    ]);

    $scope.props = {
      loadingData: false,
      user: data.user,
      selectedDate: moment().startOf('week'),
      apportionModeSetting,
      apportionType: apportionModeSetting === 'BASIC' ? 'CLASSIC_HOURLY' : 'POINTS',
      amount: 0,
    };

    $scope.onDateChange = function (newDate) {
      $scope.props.selectedDate = newDate.startOf('week');
    };

    $scope.submit = function () {
      if ($scope.props.amount < 0) {
        return;
      }

      $scope.props.loadingData = true;

      apollo
        .mutate({
          mutation: gql`
                    mutation AddTroncUserApportion($userId: Int!, $amount: Float!, $startDate: String!, $apportionType: TroncApportionType!) {
                        addStandAloneTroncUserApportion(input: { userId: $userId, amount: $amount, startDate: $startDate, apportionType: $apportionType }) {
                            response {
                                __typename
                                ...TroncUserApportion_troncUserApportion
                            }
                        }
                    }
                    ${TroncUserApportion}
                `,
          variables: {
            userId,
            amount: parseFloat($scope.props.amount),
            startDate: $scope.props.selectedDate.startOf('week').format('YYYY-MM-DD'),
            apportionType: $scope.props.apportionType,
          },
          update: (store, { data }) => {
            $scope.props.loadingData = false;

            const {
              addStandAloneTroncUserApportion: {
                response: {
                  __typename: typeName,
                },
              },
            } = data;

            switch (typeName) {
              case 'NotAuthorizedResponse':
                AlertService.add('warning', translations['STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_UNAUTHORIZED']);
                break;
              case 'TroncApportionTypeNotSupported':
                AlertService.add('danger', translations['STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_NOT_SUPPORTED']);
                break;
              case 'TroncUserNotActiveForApportionDate':
                AlertService.add('danger', translations['STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_USER_NOT_ACTIVE_FOR_APPORTION_DATE']);
                break;
              case 'TroncHeaderExistsForApportion':
                AlertService.add('danger', translations['STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_TRONC_HEADER_EXISTS_FOR_APPORTION_DATE']);
                break;
              case 'TroncUserApportion': {
                const { response: node } = data.addStandAloneTroncUserApportion;

                const edge = {
                  node,
                  __typename: 'TroncUserApportionEdge',
                };

                $uibModalInstance.close(edge);
                break;
              }
              default:
                AlertService.add('danger', translations['STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_OTHER']);
                break;
            }
          },
        })
        .catch(({ graphQLErrors, networkError }) => {
          $scope.props.loadingData = false;

          if (networkError && networkError.statusCode === 403) {
            AlertService.add('warning', translations['STAFF.VIEW.PAY.ADD_TRONC_MODAL.ERROR_UNAUTHORIZED']);
          }
        });
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  },
]);
