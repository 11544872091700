const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/staff/view/documents/signatureModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/staff/view/documents/editForm.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/staff/view/documents/uploadModal.html');

module.exports = (
  $scope,
  $uibModal,
  $uibModalDrawer,
  $state,
  $stateParams,
  $window,
  $translate,
  $q,
  StaffService,
  AlertService,
  EnvironmentDataService,
  StaffCommon,
) => {
  'ngInject';

  const translations = $translate.instant([
    'STAFF.VIEW.DOCUMENTS.ERROR_500',
    'STAFF.VIEW.DOCUMENTS.ERROR_403',
    'STAFF.VIEW.DOCUMENTS.ALERT_CONFIRM_DELETE',
  ]);

  const { id: rawUserId } = $stateParams;
  const userId = parseInt(rawUserId, 10);

  $scope.props = {
    accordionOneAtATime: false,
    loadingData: true,
    data: [],
    categoryOptions: [],
    noCategories: false,
  };

  $scope.sendToSign = () => {
    if ($scope.props.noCategories) {
      return;
    }

    $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: require('./signatureModal'),
      resolve: {
        data: () => ({
          employeeUserId: userId,
        }),
      },
    });
  };

  $scope.goToPending = () => {
    if ($scope.props.noCategories) {
      return;
    }

    $state.go('reports.documentSignatures', {
      recipientId: userId,
    });
  };

  $scope.onCategoryFilter = (selectedOptions) => {
    const [selectedCategoryId] = selectedOptions;
    $scope.props.data.forEach((r) => {
      r.visible = selectedCategoryId ? r.category.id === selectedCategoryId : true;
    });
  };

  function isValid(category, documents, notApplicable) {
    if (notApplicable || !category.required) {
      return true;
    }

    if (!category.expirable) {
      return !!documents.length;
    }

    return documents.some(({ expiryDate }) => expiryDate && expiryDate.endOf('day').isAfter());
  }

  function setValidityAndSortDocuments() {
    $scope.props.data.forEach(({ category, documents, notApplicable }) => {
      documents.forEach((document) => {
        document.expiryDate = moment(document.expiryDate);
        document.expired = document.expiryDate.clone().add(1, 'day').isBefore();
        document.friendlyFileSize = StaffService.getDocumentFriendlyFileSize(document.fileSize);
        document.acknowledgedTooltip = document.acknowledged
          ? $translate.instant('STAFF.VIEW.DOCUMENTS.TOOLTIP_ACKNOWLEDGED', {
            date: moment(document.acknowledgedDate).format('lll'),
          }) : undefined;
      });

      if (category.expirable) {
        documents.sort((a, b) => b.expiryDate.valueOf() - a.expiryDate.valueOf());
      } else {
        documents.sort((a, b) => a.fileName.localeCompare(b.fileName));
      }

      category.valid = isValid(category, documents, notApplicable);
    });
  }

  function onApiError(status) {
    $scope.props.loadingData = false;

    if (status === 500) {
      AlertService.add('danger', translations['STAFF.VIEW.DOCUMENTS.ERROR_500']);
      return;
    }

    if (status === 403) {
      AlertService.add('warning', translations['STAFF.VIEW.DOCUMENTS.ERROR_403']);
      return;
    }

    $state.go('staff.overview');
  }

  function loadData() {
    $scope.props.loadingData = true;

    $q.all([
      StaffService.getDocuments(userId),
      StaffService.getDocumentCategoriesNotApplicable(userId),
      EnvironmentDataService.fetch(EnvironmentDataService.DataType.DocumentCategory),
    ]).then(([
      documentsResponse,
      categoriesNotApplicableResponse,
      documentCategoriesResponse,
    ]) => {
      const { results: documents } = documentsResponse.data;
      const { results: categoriesNotApplicable } = categoriesNotApplicableResponse.data;

      const documentCategories = documentCategoriesResponse.data
        .filter(({ deleted, sensitive }) => {
          if (deleted) {
            return false;
          }

          if (sensitive && !StaffCommon.canAccessSensitiveDocuments()) {
            return false;
          }

          return true;
        });

      $scope.props.categoryOptions = documentCategories
        .map(({ id, name, deleted }) => ({ id, label: name, deleted }));

      $scope.props.data = documentCategories.map((category) => {
        const { id } = category;

        return {
          open: true,
          visible: true,
          category,
          documents: documents.filter(({ categoryId }) => categoryId === id),
          notApplicable: categoriesNotApplicable.find(({ categoryId }) => categoryId === id),
        };
      });

      $scope.props.noCategories = !$scope.props.data.length;

      setValidityAndSortDocuments();

      $scope.props.loadingData = false;
    }).catch(({ status }) => onApiError(status));
  }

  loadData();

  $scope.toggleCategoryApplicable = (row) => {
    if ($scope.props.loadingData) {
      return;
    }

    const {
      category: {
        id: categoryId,
      },
    } = row;

    $scope.props.loadingData = true;

    if (row.notApplicable) {
      StaffService.markDocumentCategoryApplicable(userId, categoryId)
        .then(({ data }) => {
          row.notApplicable = undefined;
          $scope.$parent.userData.flags.documentsValid = data.userDocumentValidity;

          setValidityAndSortDocuments();
          $scope.props.loadingData = false;
        }).catch(({ status }) => onApiError(status));
    } else {
      StaffService.markDocumentCategoryNotApplicable(userId, categoryId)
        .then(({ data }) => {
          row.notApplicable = true;
          $scope.$parent.userData.flags.documentsValid = data.userDocumentValidity;

          setValidityAndSortDocuments();
          $scope.props.loadingData = false;
        }).catch(({ status }) => onApiError(status));
    }
  };

  $scope.deleteDocument = (row, document, index) => {
    if (document.actionInProgress) {
      return;
    }

    if (!confirm(translations['STAFF.VIEW.DOCUMENTS.ALERT_CONFIRM_DELETE'])) {
      return false;
    }

    document.actionInProgress = true;

    StaffService.deleteDocument(document.id)
      .then(({ data }) => {
        row.documents.splice(index, 1);
        $scope.$parent.userData.flags.documentsValid = data.userDocumentValidity;
        setValidityAndSortDocuments();
      })
      .catch(({ status }) => {
        document.actionInProgress = false;
        onApiError(status);
      });
  };

  $scope.editDocument = (row, document, index) => {
    if (document.actionInProgress) {
      return;
    }

    const drawerInstance = $uibModalDrawer.open({
      templateUrl: autoNgTemplateLoaderTemplate2,
      controller: require('./editForm'),
      resolve: {
        data: () => ({
          document,
          category: row.category,
        }),
      },
    });

    drawerInstance.result.then((result) => {
      if (!result) {
        return;
      }

      document.actionInProgress = true;
      $scope.$parent.userData.flags.documentsValid = result.userDocumentValidity;

      StaffService.getDocument(document.id)
        .then(({ data }) => {
          const { document: updatedDocument } = data;

          row.documents[index] = updatedDocument;
          document.actionInProgress = false;
          setValidityAndSortDocuments();
        })
        .catch(({ status }) => {
          document.actionInProgress = false;
          onApiError(status);
        });
    });
  };

  $scope.viewDocument = (document) => {
    if (document.actionInProgress) {
      return;
    }

    document.actionInProgress = true;

    StaffService.getDocument(document.id, { download: true })
      .then(({ data }) => {
        const { signedUrl } = data;

        if (signedUrl) {
          $window.location = signedUrl;
        }

        document.actionInProgress = false;
      })
      .catch(({ status }) => {
        document.actionInProgress = false;
        onApiError(status);
      });
  };

  $scope.upload = () => {
    if ($scope.props.noCategories) {
      return;
    }

    const uploadModal = $uibModal.open({
      templateUrl: autoNgTemplateLoaderTemplate3,
      controller: require('./uploadModal'),
      resolve: {
        data: () => ({
          userId,
        }),
      },
    });

    uploadModal.result.then((result) => {
      if (!result) {
        return;
      }

      $scope.$parent.userData.flags.documentsValid = result.userDocumentValidity;
      loadData();
    });
  };
};
