'use strict';
var controllers = require('../../module');

controllers.controller('EditShiftModalCtrl', ['$scope', '$rootScope', '$uibModalInstance', '$uibModal', 'StaffService', 'RotaService', 'SessionService', 'CommonService', 'LocaleService', 'data',
    function ($scope, $rootScope, $uibModalInstance, $uibModal, StaffService, RotaService, SessionService, CommonService, LocaleService, data) {
        $scope.dataLoaded = false;
        $scope.loadingInProgress = false;
        $scope.showRotaSelection = false;

        $scope.disabledShiftTypes = [];
        $scope.disabledShiftLocations = [];

        $scope.staffSelectionLoading = false;
        $scope.masterStaffList = false;
        $scope.availableStaff = [];
        $scope.availableStaffDisplayLimit = 12;
        $scope.isInPast = false;
        var staffListFirstLoad = true;

        $scope.staffExpander = true;
        $scope.advancedExpander = true;

        $scope.breakMessageType = false;

        var startOfDay = moment().set({ hour: 0, minute: 0, second: 0});

        $scope.additionalFormData = {
            workEntityPays: true
        };

        var linked = true;
        var originalAssignedUserCount = 0;
        var haveRefreshedCalculatedProperties = false;

        $scope.retry = function () {};

        function payRuleUpdated(data) {
            if (!data.payRule) {
                delete $scope.formData.payRuleId;
                $scope.payRule = null;
                $scope.scheduledPayRate = null;
                $scope.overtimePayRate = null;
            } else {
                $scope.formData.payRuleId = data.payRule.id;

                $scope.payRule = data.payRule;

                $scope.payRule.payRates.forEach(function (rate) {
                    if (rate.isScheduled) {
                        $scope.scheduledPayRate = rate;
                    } else {
                        $scope.overtimePayRate = rate;
                    }
                });
            }
        }

        $scope.locationPickerFilters = [
            {
                id: 1,
                label: 'Open & available',
                apply: function (callback) {
                    var draftRotaId = $scope.formData.isPublished ? undefined : $scope.formData.rotaId;

                    // The API interprets capacity required as additional slots required over and above
                    // those used by shifts already scheduled. So, for a pre-existing shift this value
                    // should be the difference between users assigned now and those assigned before any
                    // edits were made
                    var capacityRequired = Math.max($scope.assignedStaff.length - originalAssignedUserCount, 0);

                    RotaService.getOpenShiftLocations($scope.formData.start, $scope.formData.end,
                        $scope.formData.workEntityId, draftRotaId, capacityRequired)
                        .success(function (result) {
                            callback(null, result.locations);
                        })
                        .error(function () {
                            callback(true);
                        });
                },
                applyAutomatically: function (locations) {
                    return locations.some(function (location) {
                        return location.depth;
                    });
                },
            },
        ];

        if (data.isAdd) {
            $scope.isAdd = true;

            $scope.formData = {
                start: data.start.clone(),
                end: data.end.clone(),
                endRest: data.end.clone(),
                rest: startOfDay.clone(),
                isPublished: data.isPublished,
                rotaId: data.rotaId,
                originEntityId: SessionService.getEntity(),
                workEntityId: SessionService.getEntity(),
                typeId: data.typeId || null,
                tagsType: 1,
                tagsValue: [],
                breakType: 0,
                breakValue: startOfDay.clone(),
                paid: true,
                shiftDate: data.start.clone()
            };

            $scope.assignedStaff = data.assignedStaff || [];

            getMetadata(function () {
                isDataLoaded();
            });
        } else {
            $scope.isAdd = false;

            RotaService.getShift(data.id)
                .success(function (result) {
                    $scope.shift = result.shift;

                    $scope.formData = angular.copy(result.shift);

                    $scope.formData.start = moment($scope.formData.start);
                    $scope.formData.end = moment($scope.formData.end);
                    $scope.formData.rest = durationToMoment($scope.formData.rest);

                    $scope.isInPast = $scope.formData.end.isSameOrBefore();

                    $scope.formData.breakValue = durationToMoment($scope.formData.breakValue);

                    if ($scope.formData.tagsType === 2) {
                        $scope.formData.tagsType = 1;
                    }

                    $scope.formData.shiftDate = moment($scope.formData.start);

                    payRuleUpdated(result.shift);

                    $scope.isCover = $scope.shift.isCover;

                    if ($scope.isCover && $scope.shift.workEntityId === $scope.shift.accountingEntityId) {
                        $scope.additionalFormData.workEntityPays = true;
                    } else {
                        $scope.additionalFormData.workEntityPays = false;
                    }

                    $scope.assignedStaff = result.users;
                    originalAssignedUserCount = result.users.length;

                    getMetadata(function () {
                        isDataLoaded();
                    });
                })
                .error(function () {
                    $scope.close();
                });
        }

        function getMetadata(callback) {
            CommonService.getMetadata(['tags', 'shiftTypes', 'shiftLocations', 'entities', 'payElementTypes'],
            [$scope.formData.workEntityId], true)
                .success(function (metadata) {
                    $scope.metadata = metadata;

                    var payElementTypesById = {};

                    metadata.payElementTypes.forEach(function (type) {
                        payElementTypesById[type.id] = type;
                    });

                    $scope.payElementTypesById = payElementTypesById;

                    callback();
                });
        }

        function isDataLoaded() {
            $scope.dataLoaded = $scope.metadata && $scope.formData;

            if ($scope.dataLoaded) {
                $scope.updateForm();
            }
        }

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.save = function (ignoreValidation) {
            $scope.validationFailure = false;

            $scope.retry = function () {
                $scope.save(true);
            };

            if (!$scope.assignedStaff.length) {
                $scope.staffExpander = false;
                alert('You must assign at least one staff member.');
                return;
            }

            if (!$scope.formData.typeId) {
                alert('You must select a shift type.');
                return;
            }

            if ($scope.disabledShiftTypes.indexOf($scope.formData.typeId) !== -1) {
                alert('Unfortunately the shift type you selected is not valid for the site you have chosen.');
                return;
            }

            if ($scope.formData.locationId && $scope.disabledShiftLocations.indexOf($scope.formData.locationId) !== -1) {
                alert('Unfortunately the location you selected is not valid for the site you have chosen.');
                return;
            }

            // TODO remove this when no longer required
            angular.forEach($scope.assignedStaff, function (user) {
                // An artifact left behind by FullCalendar that creates a circular reference...
                delete user.source;
            });

            var shiftCopy = angular.copy($scope.formData);

            shiftCopy.rest = momentToDuration(shiftCopy.rest);

            if (shiftCopy.breakType) {
                shiftCopy.breakValue = momentToDuration(shiftCopy.breakValue);
            }

            // Only if it's a cover, and the user has said the work entity doesn't pay for the shift, then
            // we attribute the cost to the origin entity. Otherwise it's always the work entity that pays.
            if ($scope.isCover && !$scope.additionalFormData.workEntityPays) {
                shiftCopy.accountingEntityId = shiftCopy.originEntityId;
            } else {
                shiftCopy.accountingEntityId = shiftCopy.workEntityId;
            }

            // This isn't needed by the API at all, but the shift object gets passed back to anything that
            // opened the modal, and some of these places depend on this property being correct
            shiftCopy.isCover = $scope.isCover;

            var fields = {
                shift: shiftCopy,
                linked: linked,
                users: $scope.assignedStaff,
                ignoreValidation: ignoreValidation
            };

            $scope.saveActioning = true;

            if ($scope.isAdd) {
                RotaService.addShift(fields)
                    .success(function (result) {
                        $rootScope.$broadcast('shiftUpdate', {
                            operation: 1,
                            shifts: result.addedShifts
                        });

                        $scope.close();
                    })
                    .error(function (error, status) {
                        onSaveError(error, status);
                    });
            } else {
                RotaService.updateShift(data.id, fields)
                    .success(function () {
                        $rootScope.$broadcast('shiftUpdate', {
                            operation: 2,
                            shift: fields.shift,
                            users: fields.users
                        });

                        $scope.close();
                    })
                    .error(function (error, status) {
                        onSaveError(error, status);
                    });
            }
        };

        function onSaveError(error, status) {
            $scope.saveActioning = false;
            $scope.deleteActioning = false;

            if (status !== 400) {
                alert('We hit a little snag actioning your request just then. Please try again.');
                return;
            }

            $scope.validationFailure = error.validationFailure;
        }

        $scope.delete = function (ignoreValidation) {
            if ($scope.isAdd) {
                return;
            }

            $scope.validationFailure = false;

            $scope.retry = function () {
                $scope.delete(true);
            };

            var extraText = "";

            if ($scope.assignedStaff.length > 1) {
                extraText += "\n\nThis will apply to all " + $scope.assignedStaff.length + " people allocated.";
            }

            if (!ignoreValidation && !confirm("Are you sure you wish to delete this shift?"+extraText))
                return;

            $scope.deleteActioning = true;

            RotaService.deleteShift(data.id, false, ignoreValidation)
                .success(function () {
                    $rootScope.$broadcast('shiftUpdate', {
                        operation: 3,
                        shiftIdUserIdTuples: [[data.id, false]],
                    });

                    $scope.close();
                })
                .error(function (error, status) {
                    onSaveError(error, status);
                });
        };

        $scope.timePickerOpen = function () {
            $scope.saveDisabled = true;
        };

        $scope.onEntityChange = function (entity) {
            $scope.formData.workEntityId = entity.id;
            $scope.formData.workEntityName = entity.name;

            // Reset these two as any values previously chosen may now be unavailable for this entity
            $scope.formData.typeId = null;
            $scope.formData.locationId = null;

            // Refresh metadata (using the selected workEntityId) to get global & entity-specific shift types/locations
            $scope.loadingInProgress = true;

            getMetadata(function () {
                $scope.loadingInProgress = false;

                $scope.updateForm();
            });
        };

        $scope.onDateChange = function (newDate) {
            $scope.formData.start.set({
                date: newDate.date(),
                month: newDate.month(),
                year: newDate.year()
            });

            $scope.updateForm();
        };

        $scope.updateForm = function () {
            $scope.saveDisabled = false;

            // The first time only, decide whether or not to show the 'pick a rota' drop down
            if (!$scope.showRotaSelection) {
                $scope.showRotaSelection = !$scope.formData.rotaId && $scope.formData.isPublished;
            }

            $scope.dateSpansMidnight = $scope.formData.end.isBefore($scope.formData.start);

            var isCover = $scope.formData.workEntityId !== SessionService.getEntity();

            // Enable the checkbox for the work entity paying if it wasn't a cover but they've changed it to one!
            if (!$scope.isCover && isCover) {
                $scope.additionalFormData.workEntityPays = true;
            }

            $scope.isCover = isCover;

            $scope.disabledShiftTypes = [];
            $scope.disabledShiftLocations = [];

            angular.forEach($scope.metadata.shiftTypes, function (t) {
                if (t.depth === 0 && t.entityId && t.entityId !== $scope.formData.workEntityId) {
                    $scope.disabledShiftTypes.push(t.id);
                }
            });

            angular.forEach($scope.metadata.shiftLocations, function (t) {
                if (t.depth === 0 && t.entityId && t.entityId !== $scope.formData.workEntityId) {
                    $scope.disabledShiftLocations.push(t.id);
                }
            });

            // If the shift end time is earlier than the shift start time, ensure it is set to the following day
            var windEndDateForwards = false;

            if (getMinutesOfDayFromDate($scope.formData.end) <= getMinutesOfDayFromDate($scope.formData.start)) {
                windEndDateForwards = true;
            }

            $scope.formData.end.set({
                year: $scope.formData.start.year(),
                month: $scope.formData.start.month(),
                date: $scope.formData.start.date() + (windEndDateForwards ? 1 : 0),
            });

            $scope.refreshCalculatedProperties(true, true);

            refreshRotasSpanningDate();
            refreshMasterStaffList();
        };

        function getMinutesOfDayFromDate(date) {
            return date.minutes() + (date.hours() * 60);
        }

        function processBreaks(userIds, breakData) {
            // We will have to unlink this shift if we calculate that the people working it
            // have different break requirements (i.e. the set of breaks differs)
            // For now apply the default break settings
            $scope.breakMessageType = false;
            linked = true;
            $scope.formData.breakType = 0;
            $scope.formData.breakValue = startOfDay.clone();

            // The breaks differ across the staff members
            if (breakData.breaksDiffer) {
                linked = false;
                $scope.breakMessageType = 1;
                return;
            }

            // There's either one user and therefore a single break requirement for this shift
            // or multiple users who all require the same break
            $scope.breakMessageType = 2;

            var restBreak = breakData.breakByUserId[userIds[0]];

            // If there is no break necessary, great
            if (restBreak.breakType === 0) {
                return;
            }

            // Otherwise we should apply the break and show a message
            $scope.formData.breakType = restBreak.breakType;
            $scope.formData.breakValue = durationToMoment(restBreak.breakValue);
        }

        $scope.refreshCalculatedProperties = function (restBreaks, payRule) {
            $rootScope.$broadcast('directive-hierarchyItemPicker', {
                id: 'editModalLocation1',
                action: 1,
            });

            if (!$scope.dataLoaded) {
                return;
            }

            var start = $scope.formData.start;
            var end = $scope.formData.end;
            var shiftTypeId = $scope.formData.typeId;

            if (!start) return;

            var userIds = $scope.assignedStaff.map(function (user) {
                return user.id;
            });

            var propertyTypes = [];

            if (restBreaks && end && userIds.length) {
                propertyTypes.push('restBreaks');
            }

            if (payRule && shiftTypeId) {
                propertyTypes.push('payRule');
            }

            if (!propertyTypes.length) {
                return;
            }

            if (!$scope.isAdd && !haveRefreshedCalculatedProperties) {
                haveRefreshedCalculatedProperties = true;
                return;
            }

            $scope.loadingInProgress = true;

            RotaService.getCalculatedProperties(propertyTypes, $scope.formData.start, $scope.formData.end, userIds,
                $scope.formData.workEntityId, shiftTypeId)
                .success(function(data) {
                    $scope.loadingInProgress = false;

                    if (restBreaks) {
                        processBreaks(userIds, data);
                    }

                    if (payRule) {
                        payRuleUpdated(data);
                    }
                })
                .error(function () {
                    $scope.close();
                });
        };

        $scope.onRestBreakChange = function () {
            // User has manually modified the rest break, we won't split this shift
            linked = true;
            $scope.breakMessageType = false;
        };

        function refreshRotasSpanningDate() {
            if (!$scope.showRotaSelection) {
                return;
            }

            $scope.rotasLoaded = false;

            RotaService.getRotasSpanningDate($scope.formData.start, $scope.formData.workEntityId)
                .success(function (rotas) {
                    $scope.rotas = rotas.map(function (rota) {
                        var name = '';

                        if (rota.name) {
                            name += ' (' + rota.name + ')';
                        }

                        return {
                            id: rota.id,
                            name: moment(rota.start).format('ll') + ' - ' + moment(rota.end).format('ll') + name
                        }
                    });

                    if (rotas.length === 1) {
                        $scope.formData.rotaId = $scope.rotas[0].id;
                    }

                    $scope.rotasLoaded = true;
                });
        }

        // Staff selection tool
        function refreshMasterStaffList() {
            if (!$scope.dataLoaded) {
                return;
            }

            $scope.staffSelectionLoading = true;

            RotaService.getAvailableStaffOnDate($scope.formData.start, $scope.formData.workEntityId)
                .success(function (result) {
                    $scope.staffSelectionLoading = false;

                    $scope.masterStaffList = result.availableStaff.map(function (staff) {
                        return {
                            id: staff.id,
                            name: staff.firstName + ' ' + staff.lastName
                        }
                    });

                    if (data.staff) {
                        var masterStaffListIds = $scope.masterStaffList.map(function (staff) {
                            return staff.id;
                        });

                        // Add any supplied staff to the master list, if provided by the caller
                        angular.forEach(data.staff, function (staff) {
                            if (masterStaffListIds.indexOf(staff.id) === -1) {
                                $scope.masterStaffList.push(staff);
                            }
                        });
                    }

                    refreshAvailableStaff();
                })
                .error(function () {
                    $scope.close();
                });
        }

        function refreshAvailableStaff() {
            // Prevent this from being fired multiple times at once...
            // This happens when formData.tagsType and .tagsValue are both set one after the other
            if ($scope.staffSelectionLoading) {
                return;
            }

            if (!$scope.masterStaffList) {
                return;
            }

            $scope.staffSelectionLoading = true;

            var fields = {
                users: $scope.masterStaffList,
                tags: $scope.formData.tagsValue,
                tagMatchingType: $scope.formData.tagsType
            };

            StaffService.filterStaffByTags(fields)
                .success(function (filteredStaff) {
                    $scope.staffSelectionLoading = false;
                    updateStaffLists(filteredStaff);
                })
                .error(function () {
                    $scope.close();
                });
        }

        function updateStaffLists(filteredStaff) {
            var assignedIds = [];

            var j = $scope.assignedStaff.length;
            var changesMade = false;

            // Remove assigned staff that are not (/no longer) in the filtered list
            while (j--) {
                var found = false;

                for (var i = 0; i < filteredStaff.length; i++) {
                    if (filteredStaff[i].id === $scope.assignedStaff[j].id) {
                        found = true;
                    }
                }

                // Don't do this on first load. If the shift had been given to someone who doesn't
                // have the tags to work it (our user must have previously 'ignored & continued' the validation
                // error), refreshing the available staff right now will automatically remove them from the list
                // and therefore if our user doesn't spot and just clicks Save, it'll unassign that staff member
                if (!found && !staffListFirstLoad) {
                    $scope.assignedStaff.splice(j, 1);
                    changesMade = true;
                } else {
                    assignedIds.push($scope.assignedStaff[j].id);
                }
            }

            staffListFirstLoad = false;

            // Add the remaining staff to the 'available' list
            // (ie those that are in the filtered list and not assigned)
            $scope.availableStaff = [];
            angular.forEach(filteredStaff, function (staffMember) {
                if (assignedIds.indexOf(staffMember.id) === -1) {
                    $scope.availableStaff.push(staffMember);
                }
            });

            if (changesMade && $scope.refreshCalculatedProperties) {
                $scope.refreshCalculatedProperties(true);
            }
        }

        $scope.unassignStaff = function (staffId) {
            angular.forEach($scope.assignedStaff, function (assignedStaff, index) {
                if (assignedStaff.id === staffId) {
                    $scope.assignedStaff.splice(index, 1);
                }
            });

            angular.forEach($scope.masterStaffList, function (staff) {
                if (staff.id === staffId) {
                    $scope.availableStaff.push(staff);
                }
            });

            $scope.refreshCalculatedProperties(true);
        };

        $scope.assignStaff = function (staffId) {
            angular.forEach($scope.availableStaff, function (availableStaff, index) {
                if (availableStaff.id === staffId) {
                    $scope.availableStaff.splice(index, 1);
                }
            });

            angular.forEach($scope.masterStaffList, function (staff) {
                if (staff.id === staffId) {
                    $scope.assignedStaff.push(staff);
                }
            });

            $scope.refreshCalculatedProperties(true);
        };

        $scope.$watch('formData.tagsType', function (newVal, oldVal) {
            if (!newVal || newVal == oldVal) return;
            refreshAvailableStaff();
        });

        $scope.$watch('formData.tagsValue', function (newVal, oldVal) {
            if (!newVal || newVal == oldVal) return;
            refreshAvailableStaff();
        }, true);

        function durationToMoment(duration) {
            return duration ? startOfDay.clone().add(moment.duration(duration)) : startOfDay.clone();
        }

        function momentToDuration(date) {
            return moment.duration(date.diff(startOfDay));
        }

        $scope.$watch('formData.typeId', function (newVal, oldVal) {
            // Prevent the refresh when we load in the shift type metadata on an edit
            if (newVal && (oldVal || $scope.isAdd)) {
                $scope.refreshCalculatedProperties(false, true);
            }
        });

        $scope.getRateDescription = function (rate) {
            var typeId = rate.typeId;
            var elementTypeId = rate.elementTypeId;
            var rateValue = rate.rateValue;
            var capTypeId = rate.capTypeId;
            var timeCapStart = rate.timeCapStart;
            var timeCapEnd = rate.timeCapEnd;
            var minuteCap = rate.minuteCap;

            var description = '';

            if (typeId === 0) {
                description += $scope.payElementTypesById[elementTypeId].name;
            } else if (typeId === 1) {
                description += 'Zero pay';
            } else if (typeId === 2) {
                description += rateValue + 'x ' + $scope.payElementTypesById[elementTypeId].name;
            } else {
                var currencyCode;

                $scope.metadata.entities.forEach(function (group) {
                    group.entities.forEach(function (entity) {
                        if (entity.id === $scope.formData.workEntityId) {
                            currencyCode = entity.currencyCode;
                        }
                    });
                });

                var currencySymbol = LocaleService.getCurrencySymbol(currencyCode);

                description += currencySymbol + rateValue + ' per hour';
            }

            if (capTypeId === 2) {
                description += ' between ' + timeCapStart + ' and ' + timeCapEnd;
            } else if (capTypeId === 3) {
                description += ' for the first ' + minuteCap + ' minutes';
            }

            return description;
        };
    }]);
