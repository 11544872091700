const services = require('./module');

services.factory('BetaService', ['$q', '$cookies', '$window', '$httpParamSerializer', 'WFM_ENDPOINT', 'COOKIE_DOMAIN', 'ENV',
  function ($q, $cookies, $window, $httpParamSerializer, WFM_ENDPOINT, COOKIE_DOMAIN, ENV) {
    const features = Object.freeze({
      ROTA_EDITOR: {
        cookie: 'rr_new_editor',
        url: '/new-rota',
      },
      COST_CONTROL: {
        cookie: 'rr_new_cost_control',
        url: '/cost-control',
      },
      READ_ONLY_ROTA: {
        url: '/rota/read-only',
      },
    });

    const setCookie = function (cookie, expires) {
      $cookies.put(cookie, true, {
        domain: COOKIE_DOMAIN,
        path: '/',
        expires,
      });
    };

    const getFullyQualifiedCookieName = function (feature) {
      return feature.cookie + (ENV === 'prod' ? '' : `_${ENV}`);
    };

    const redirect = function (feature, queryStringParams) {
      const qs = queryStringParams ? (`?${$httpParamSerializer(queryStringParams)}`) : '';
      $window.location.replace(WFM_ENDPOINT + feature.url + qs);
    };

    return {
      Features: features,

      isOptedIn(feature) {
        return $cookies.get(getFullyQualifiedCookieName(feature)) === 'true';
      },

      optInAndRedirect(feature) {
        const deferred = $q.defer();
        deferred.reject();

        setCookie(getFullyQualifiedCookieName(feature), moment().add(1, 'year').toDate());
        redirect(feature);

        return deferred.promise;
      },

      redirect(feature, queryStringParams) {
        const deferred = $q.defer();
        deferred.reject();

        redirect(feature, queryStringParams);
        return deferred.promise;
      },

      redirectIfOptedIn(feature, queryStringParams) {
        const deferred = $q.defer();

        if (this.isOptedIn(feature)) {
          redirect(feature, queryStringParams);
          deferred.reject();
        } else {
          deferred.resolve();
        }

        return deferred.promise;
      },
    };
  }]);
