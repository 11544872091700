const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/staff/allowanceImpact.html');

import directives from '../module';

directives.directive('rrAllowanceImpact', [
  () => ({
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      transactions: '=',
      hoursPerDay: '=',
    },
    link: ($scope) => {
      $scope.calculateHoursPerDay = (transaction) => {
        if ($scope.hoursPerDay <= 0) {
          return;
        }

        return Math.round((transaction.availableToBook / $scope.hoursPerDay) * 100) / 100;
      };
    },
  }),
]);
