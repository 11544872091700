const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/settings/permissions/editPermissionLevelModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/settings/permissions/editPermissionLevelModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/settings/permissions/deletePermissionLevelModal.html');

var controllers = require('../../module');

controllers.controller('PermissionsSettingsCtrl', ['$scope', '$uibModal', '$state', 'StaffService', 'SettingsService', 'AlertService',
    function ($scope, $uibModal, $state, StaffService, SettingsService, AlertService) {

        $scope.changedLevels = [];

        var deletedLevelMappings = {};
        var newLevelSeed = -1;

        function refreshData() {
            $scope.dataLoaded = false;

            SettingsService.getPermissionLevels()
                .success(function (tree) {
                    $scope.levels = tree.rootLevels;
                    $scope.dataLoaded = true;
                })
                .error(function (error, status) {
                    if (status == 500) {
                        AlertService.add('danger', 'We weren\'t able to load the permissions manager just then. Please try again');
                        $state.go('dashboard');
                    }
                });
        }

        refreshData();

        function resetSandbox() {
            $scope.sandboxSuccess = false;
            $scope.sandboxFail = false;

            delete $scope.formData.sourceUser;
            delete $scope.formData.targetUser;
            $scope.formData.checkIndex = 0;
        }

        $scope.editLevel = function (index, parentList) {
            var level = parentList ? parentList[index] : $scope.levels[index];

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'EditPermissionLevelModalCtrl',
                size: 'lg',
                resolve: {
                    data: function () {
                        return {
                            isAdd: false,
                            level: level
                        };
                    }
                }
            });

            modalInstance.result.then(function (newData) {
                resetSandbox();

                if ($scope.changedLevels.indexOf(level.id) === -1) {
                    if (newData.name !== level.name || newData.isFullAccess !== level.isFullAccess ||
                            newData.grantKeys.length !== level.grantKeys.length) {
                        $scope.changedLevels.push(level.id);
                    } else {
                        var foundChange = false;

                        level.grantKeys.forEach(function (key) {
                            if (foundChange) {
                                return;
                            }

                            if (newData.grantKeys.indexOf(key) === -1) {
                                $scope.changedLevels.push(level.id);
                                foundChange = true;
                            }
                        });
                    }
                }

                level.name = newData.name;
                level.isFullAccess = newData.isFullAccess;
                level.grantKeys = newData.grantKeys;
            });
        };

        $scope.addChild = function (level) {
            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'EditPermissionLevelModalCtrl',
                size: 'lg',
                resolve: {
                    data: function () {
                        return {
                            isAdd: true
                        };
                    }
                }
            });

            modalInstance.result.then(function (newData) {
                resetSandbox();

                var newLevel = {
                    id: newLevelSeed,
                    name: newData.name,
                    parentId: 0,
                    depth: 0,
                    isFullAccess: newData.isFullAccess,
                    grantKeys: newData.grantKeys,
                    children: []
                };

                $scope.levels.push(newLevel);

                $scope.changedLevels.push(newLevel.id);

                newLevelSeed--;
            });
        };

        $scope.deleteLevel = function (index, parentList) {
            var level = parentList ? parentList[index] : $scope.levels[index];

            var modalInstance = $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate3,
                controller: 'DeletePermissionLevelModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            level: level,
                            tree: angular.copy($scope.levels)
                        };
                    }
                }
            });

            modalInstance.result.then(function (moveTo) {
                resetSandbox();

                deletedLevelMappings[level.id] = moveTo;

                if (parentList) {
                    parentList.splice(index, 1);
                } else {
                    $scope.levels.splice(index, 1);
                }

                $scope.changedLevels.push(level.id);
            });
        };

        function recalculateDepthsAndParentIDs(parent, level) {
            var oldParent = level.parentId;
            var oldDepth = level.depth;

            if (parent) {
                level.parentId = parent.id;
                level.depth = parent.depth + 1;
            } else {
                level.parentId = 0;
                level.depth = 0;
            }

            if ((oldParent !== level.parentId || oldDepth !== level.depth) && $scope.changedLevels.indexOf(level.id) === -1) {
                $scope.changedLevels.push(level.id);
            }

            level.children.forEach(function (child) {
                recalculateDepthsAndParentIDs(level, child);
            });
        }

        $scope.levelMoved = function (index, parent) {
            resetSandbox();

            if (!parent) {
                $scope.levels.splice(index, 1);
            } else {
                parent.splice(index, 1);
            }

            $scope.levels.forEach(function (level) {
                recalculateDepthsAndParentIDs(false, level);
            });
        };

        $scope.save = function () {
            var data = {
                rootLevels: $scope.levels,
                deletedLevelMappings: deletedLevelMappings
            };

            SettingsService.updatePermissionLevels(data)
                .success(function () {
                    $scope.changedLevels = [];
                    resetSandbox();

                    refreshData();
                })
                .error(function (err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', 'We weren\'t able to save the permission levels just then. Please try again');
                    }
                });
        };

        $scope.sandboxOptions = [
            {
                checkType: 1,
                description: 'View financial information',
                isRead: true
            },
            {
                checkType: 1,
                description: 'Edit financial information',
                isRead: false
            },
            {
                checkType: 2,
                description: 'View shift routines',
                isRead: true
            },
            {
                checkType: 2,
                description: 'Add, edit and delete shift routines',
                isRead: false
            },
            {
                checkType: 3,
                description: 'View personal details',
                isRead: true
            },
            {
                checkType: 3,
                description: 'Edit personal details',
                isRead: false
            },
            {
                checkType: 4,
                description: 'View appointments',
                isRead: true
            },
            {
                checkType: 4,
                description: 'Add, edit and delete appointments',
                isRead: false
            },
            {
                checkType: 5,
                description: 'View pay records',
                isRead: true
            },
            {
                checkType: 5,
                description: 'Add, update and delete pay records',
                isRead: false
            },
            {
                checkType: 6,
                description: 'View absence allowances',
                isRead: true
            },
            {
                checkType: 6,
                description: 'Add, update and delete absence allowances',
                isRead: false
            },
            {
                checkType: 7,
                description: 'View absence bookings',
                isRead: true
            },
            {
                checkType: 7,
                description: 'Approve absence requests',
                isRead: false
            },
            {
                checkType: 8,
                description: 'View attendance events',
                isRead: true
            },
            {
                checkType: 8,
                description: 'Add, update and delete attendance events',
                isRead: false
            },
        ]

        $scope.searchStaff = function (searchTerm) {
            return StaffService.search(searchTerm, $scope.activeOnly, 'name').then(
                function (response) {
                    return response.data.users.map(function (row) {
                        var name = row.firstName + ' ' + row.lastName + ' (' + row.appointment.entityName + ')';

                        if (!row.flags.active) {
                            name += ' *Inactive*';
                        }

                        return {
                            id: row.id,
                            name: name
                        }
                    });
                }
            );
        };

        function runSandbox() {
            $scope.sandboxSuccess = false;
            $scope.sandboxFail = false;

            var sourceUser = $scope.formData.sourceUser;
            var targetUser = $scope.formData.targetUser;
            var checkOption = $scope.sandboxOptions[$scope.formData.checkIndex];

            if (!sourceUser || !targetUser) {
                return;
            }

            var data = {
                sourceUserId: sourceUser.id,
                targetUserId: targetUser.id,
                isRead: checkOption.isRead,
                checkType: checkOption.checkType,
                rootLevels: $scope.levels,
                deletedLevelMappings: deletedLevelMappings,
            };

            SettingsService.checkPermissionsSandbox(data)
                .success(function (data) {
                    if (data.isPermitted) {
                        $scope.sandboxSuccess = true;
                        $scope.sandboxFail = false;
                    } else {
                        $scope.sandboxSuccess = false;
                        $scope.sandboxFail = true;
                    }
                })
                .error(function (err, status) {
                    if (status !== 403) {
                        AlertService.add('danger', 'We weren\'t able to run the permissions sandbox just then. Please try again');
                    }
                });
        }

        $scope.formData = {
            checkIndex: 0
        };

        $scope.$watch('formData.sourceUser', function () {
            runSandbox();
        });

        $scope.$watch('formData.targetUser', function()  {
            runSandbox();
        });

        $scope.$watch('formData.checkIndex', function () {
            runSandbox();
        });
    }]);
