const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/rotamanagement/shift/viewModal.html');
const autoNgTemplateLoaderTemplate2 = require('/home/circleci/project/app/htdocs/views/absence/viewModal.html');
const autoNgTemplateLoaderTemplate3 = require('/home/circleci/project/app/htdocs/views/dialog/twoButtonDialog.html');
const autoNgTemplateLoaderTemplate4 = require('/home/circleci/project/app/htdocs/views/directiveTpls/calendar.html');

'use strict';
var directives = require('./module');

directives.directive('rrCalendar', ['$uibModal', '$window', '$timeout', '$state', 'AvailabilityService', 'SessionService', 'AlertService',
    function ($uibModal, $window, $timeout, $state, AvailabilityService, SessionService, AlertService) {
        return {
            restrict: 'E',
            scope: {
                userId: '=',
                defaultDate: '=',
                addEvents: '=',
                eventSources: '=?',
                compactRows: '='
            },
            controller: function ($scope, $element, $attrs) {
                $scope.userCalendarLoading = true;

                var calendar = $('#schedule');
                var allowAbsenceAdding = false;

                function goToDefaultDate() {
                    if ($scope.defaultDate) {
                        calendar.fullCalendar('gotoDate', $scope.defaultDate);
                    }
                }

                $scope.$watch('defaultDate', function () {
                    goToDefaultDate();
                });

                $scope.$watch('addEvents', function (events) {
                    if (!angular.isArray(events)) {
                        return;
                    }

                    // Timeout to defer until calendar has loaded
                    $timeout(function () {
                        events.forEach(function (e) {
                            calendar.fullCalendar('renderEvent', e, true);
                        });
                    }, 0);
                });

                if ($attrs.allowAbsenceAdding) {
                    allowAbsenceAdding = true;
                    $scope.showAbsenceTip = true;
                }

                var hasRendered = false;

                $scope.calendar = {
                    aspectRatio: $window.innerWidth < 768 ? 0.9 : 1.35,
                    eventLimit: true,
                    editable: false,
                    selectable: allowAbsenceAdding,
                    selectHelper: false,
                    slotDuration: '00:15:00',
                    timezone: false,
                    firstDay: SessionService.getSetting('locale.firstDow'),
                    minTime: SessionService.getSetting('calendar.minTime'),
                    maxTime: SessionService.getSetting('calendar.maxTime'),
                    header: {
                        left: 'title',
                        center: '',
                        right: ''
                    },
                    columnFormat: {
                        month: 'ddd',
                        week: 'ddd Do',
                        day: 'dddd Do'
                    },
                    viewRender: function () {
                        if (!hasRendered) {
                            hasRendered = true;
                            goToDefaultDate();
                        }
                    },
                    loading: function (isLoading) {
                        $scope.userCalendarLoading = isLoading;
                    },
                    select: function (startDate, endDate) {
                        if ($attrs.readOnly) {
                            return;
                        }

                      $state.go('absenceRequest', {
                        start: moment(startDate).format('YYYY-MM-DD'),
                        end: moment(endDate).format('YYYY-MM-DD'),
                        userId: $scope.userId,
                      });
                    },
                    eventClick: function (calEvent) {
                        if ($attrs.readOnly) {
                            return;
                        }

                        if (calEvent.eventType === 1) {
                            $uibModal.open({
                                templateUrl: autoNgTemplateLoaderTemplate1,
                                controller: 'ViewShiftModalCtrl',
                                resolve: {
                                    data: function () {
                                        return {
                                            id: calEvent.eventId
                                        };
                                    }
                                }
                            });
                        }

                        if (calEvent.eventType === 3) {
                            $uibModal.open({
                                templateUrl: autoNgTemplateLoaderTemplate2,
                                controller: require('../controllers/absence/viewModal'),
                                resolve: {
                                    data: function () {
                                        return {
                                            id: calEvent.eventId,
                                            launchedFromStaffModule: $attrs.allowAbsenceEditing,
                                        };
                                    }
                                }
                            });
                        }

                        if (calEvent.eventType === 4) {
                            var modalInstance = $uibModal.open({
                                templateUrl: autoNgTemplateLoaderTemplate3,
                                controller: 'TwoButtonDialogCtrl',
                                resolve: {
                                    data: function () {
                                        return {
                                            title: 'Availability exception',
                                            message: 'Your availability pattern(s) have you marked as unavailable for ' + moment(calEvent.start).format('ll') + '. ' +
                                            'If, in this instance you\'re now free to work this day, you can mark yourself as available.',
                                            optionATitle: 'I\'m available',
                                            optionAText: 'Mark this day as an exception to my availability pattern, as I\'m able to work.',
                                            optionBTitle: 'Cancel',
                                            optionBText: 'I\'m not available to work this day, so leave things as they are.'
                                        }
                                    }
                                }
                            });

                            modalInstance.result.then(function (optionASelected) {
                                if (optionASelected) {
                                    $scope.userCalendarLoading = true;
                                    var exceptionDate = moment(calEvent.start);

                                    var fields = {
                                        date: exceptionDate.format('YYYY-MM-DD'),
                                        userId: calEvent.resource
                                    };

                                    AvailabilityService.addPatternException(fields)
                                        .success(function () {
                                            calendar.fullCalendar('removeEvents', function (e) {
                                                if (moment(e.start).format('DDD') == exceptionDate.format('DDD')
                                                    && e.eventType === 4) {
                                                    return true;
                                                }
                                            });

                                            $scope.userCalendarLoading = false;
                                        })
                                        .error(function (error, status) {
                                            $scope.userCalendarLoading = false;

                                            if (status == 500) {
                                                AlertService.add("danger", "We were unable to make an exception to your pattern for that date. Please try again.");
                                            }
                                        });
                                }
                            });
                        }
                    }
                };

                if ($scope.compactRows)  {
                    $scope.calendar.height = 600;
                }

                if (!$scope.eventSources) {
                    $scope.eventSources = [function (start, end, timezone, callback) {
                        AvailabilityService.getCalendar(start, end, $scope.userId, true)
                            .success(function (data) {
                                angular.forEach(data, function (e) {
                                    // We MUST do this otherwise fullcalendar ignores the timezone set on moment
                                    // by sessionService and uses the client's local timezone instead
                                    e.start = moment(e.start);
                                    e.end = moment(e.end);

                                    // Prevent objects from being dragged or resized
                                    e.editable = false;
                                });

                                callback(data);
                            })
                            .error(function (error) {
                                AlertService.add("danger", "We couldn't load the calendar just then. Please try again!");
                            });
                    }];

                    $scope.$on('shiftUpdate', function () {
                        calendar.fullCalendar('refetchEvents');
                    });
                }

                $scope.changeView = function (view) {
                    calendar.fullCalendar('changeView', view);
                };

                $scope.calendarNavigate = function (backwards) {
                    if (backwards) {
                        calendar.fullCalendar('prev');
                    } else {
                        calendar.fullCalendar('next');
                    }
                };

                $scope.goToToday = function () {
                    calendar.fullCalendar('today');
                };

                $scope.calendarTitle = 'Calendar';
                $scope.calendarNavigateToggle = 'month';
            },
            templateUrl: autoNgTemplateLoaderTemplate4
        }
    }]);
