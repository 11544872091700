const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/pickList.html');

const directives = require('./module');

directives.directive('rrPickList', (
  $translate,
) => {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: autoNgTemplateLoaderTemplate1,
    scope: {
      onSelect: '&?',
      selectedOptionId: '=?',
      optionList: '=?',
      readOnly: '=?',
      borderless: '@?',
      dropup: '@?',
      includeNullOption: '@?',
      nullOptionLabel: '@?',
    },
    link: ($scope, element) => {
      const translations = $translate.instant([
        'DIRECTIVES.PICK_LIST.SEARCH_PLACEHOLDER',
        'DIRECTIVES.PICK_LIST.DEFAULT_NULL_OPTION_LABEL',
      ]);

      $scope.props = {
        displayText: '',
        optionList: [],
        highlightedOptionId: undefined,
        showNoOptionsWarning: false,
      };

      const nullOptionLabel = $scope.nullOptionLabel
        || translations['DIRECTIVES.PICK_LIST.DEFAULT_NULL_OPTION_LABEL'];
      let options = new Map();
      const searchInput = angular.element(element.find('input')[0]);
      let highlightPointer = 0;
      let hasMenuOpenedOnce = false;

      function evaluateEnabledState() {
        $scope.isEnabled = !$scope.readOnly && $scope.initialised;
      }

      $scope.$watch('readOnly', () => {
        evaluateEnabledState();
      });

      function updateDisplayText() {
        if ($scope.selectedOptionId === undefined) {
          $scope.props.displayText = translations['DIRECTIVES.PICK_LIST.SEARCH_PLACEHOLDER'];
          return;
        }

        const option = options.get($scope.selectedOptionId);

        if (option) {
          $scope.props.displayText = option.label;
        }
      }

      function evaluateDisplayableOptions() {
        const validOptions = !!$scope.props.optionList.filter(({ deleted }) => !deleted).length;
        $scope.props.showNoOptionsWarning = !validOptions && $scope.selectedOptionId === undefined;
      }

      $scope.$watch('selectedOptionId', () => {
        updateDisplayText();
      });

      $scope.$watch('optionList', (newVal) => {
        if (!newVal || !Array.isArray(newVal)) {
          return;
        }

        $scope.props.optionList = [...newVal];

        if ($scope.includeNullOption) {
          $scope.props.optionList.unshift({
            id: null,
            label: nullOptionLabel,
          });
        }

        options = new Map($scope.props.optionList.map((option) => [option.id, option]));

        $scope.initialised = true;
        evaluateEnabledState();
        updateDisplayText();
        evaluateDisplayableOptions();
      });

      $scope.onOptionClick = (option) => {
        $scope.selectedOptionId = option ? option.id : undefined;
        updateDisplayText();
        $scope.isMenuOpen = false;
      };

      $scope.onElementClick = () => {
        if (!$scope.isEnabled) {
          return;
        }

        searchInput.focus();
      };

      $scope.onFilterTextKeyPress = (event) => {
        if (!$scope.isEnabled) {
          return;
        }

        $scope.isMenuOpen = true;

        if (event.key === 'Escape') {
          event.preventDefault();
          $scope.props.filterText = '';
          $scope.onFilterTextChange();
          return;
        }

        if (event.key === 'Enter' && $scope.props.highlightedOptionId !== undefined) {
          event.preventDefault();
          $scope.onOptionClick(options.get($scope.props.highlightedOptionId));
          return;
        }

        if (event.key === 'ArrowDown') {
          event.preventDefault();
          highlightPointer += 1;
          $scope.onFilterTextChange();
          return;
        }

        if (event.key === 'ArrowUp') {
          event.preventDefault();
          highlightPointer -= 1;
          $scope.onFilterTextChange();
          return;
        }

        highlightPointer = 0;
      };

      $scope.filterOptions = (option) => {
        const { filterText } = $scope.props;

        if (!filterText) {
          return true;
        }

        return !option.deleted
          && option.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) !== -1;
      };

      $scope.onFilterTextChange = () => {
        $scope.props.showNoOptionsWarning = false;
        $scope.props.highlightedOptionId = undefined;

        const { filterText } = $scope.props;

        if (filterText) {
          const matches = Array.from(options.entries())
            .filter(([, option]) => $scope.filterOptions(option))
            .map(([id]) => id);

          $scope.props.showNoOptionsWarning = !matches.length;
          const count = matches.length;

          if (count) {
            // Modulo wrap negative index to array size: https://stackoverflow.com/a/43827557/416191
            const elementId = ((highlightPointer % count) + count) % count;
            $scope.props.highlightedOptionId = matches[elementId];
          }
        }
      };

      function onFinish() {
        const obj = {
          option: options.get($scope.selectedOptionId),
        };

        searchInput.blur();

        if ($scope.onSelect) {
          $scope.onSelect(obj);
        }
      }

      $scope.$watch('isMenuOpen', (val) => {
        if (val === true) {
          hasMenuOpenedOnce = true;
          return;
        }

        if (val === false && hasMenuOpenedOnce) {
          $scope.props.filterText = '';
          $scope.props.highlightedOptionId = undefined;
          evaluateDisplayableOptions();
          highlightPointer = 0;
          onFinish();
        }
      });
    },
  };
});
