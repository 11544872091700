module.exports = (
  $scope,
  $state,
  $stateParams,
  $http,
  $translate,
  EnvironmentDataService,
  AlertService,
  ExportService,
  StaffService,
  ENDPOINT_API,
) => {
  'ngInject';

  let isFirstLoad = true;
  const translations = $translate.instant([
    'REPORTS.EMPLOYMENT_CHANGES.TITLE',
    'REPORTS.EMPLOYMENT_CHANGES.DATE_ERROR_DIFF',
    'REPORTS.EMPLOYMENT_CHANGES.ERROR_500',
    'REPORTS.EMPLOYMENT_CHANGES.MODEL_EMPLOYEE',
    'REPORTS.EMPLOYMENT_CHANGES.MODEL_APPOINTMENT',
    'REPORTS.EMPLOYMENT_CHANGES.MODEL_PAY',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_EMPLOYEE_FIRST_NAME',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_EMPLOYEE_LAST_NAME',
    'STAFF.OVERVIEW.COLUMN_HR_ID',
    'STAFF.OVERVIEW.COLUMN_PAYROLL_ID',
    'STAFF.OVERVIEW.COLUMN_PERMISSION_LEVEL_NAME',
    'STAFF.OVERVIEW.COLUMN_BIRTH_DATE',
    'STAFF.OVERVIEW.COLUMN_AVERAGE_HOURS_DAY',
    'STAFF.OVERVIEW.COLUMN_AVERAGE_HOURS_HALF_DAY',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET',
    'STAFF.OVERVIEW.COLUMN_EMAIL',
    'STAFF.OVERVIEW.COLUMN_MOBILE',
    'STAFF.OVERVIEW.COLUMN_NATIONAL_INSURANCE_NUMBER',
    'STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_NUMBER',
    'STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_SORT_CODE',
    'STAFF.OVERVIEW.COLUMN_TITLE',
    'STAFF.OVERVIEW.COLUMN_GENDER',
    'STAFF.OVERVIEW.COLUMN_OTHER_NAMES',
    'STAFF.OVERVIEW.COLUMN_NATIONALITY',
    'STAFF.OVERVIEW.COLUMN_WORK_PHONE',
    'STAFF.OVERVIEW.COLUMN_WORK_EMAIL',
    'STAFF.OVERVIEW.COLUMN_SETTLED_STATUS',
    'STAFF.OVERVIEW.COLUMN_ETHNICITY',
    'STAFF.OVERVIEW.COLUMN_MARITAL_STATUS',
    'STAFF.OVERVIEW.COLUMN_PASSPORT_NUMBER',
    'STAFF.OVERVIEW.COLUMN_PASSPORT_EXPIRY_DATE',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_LINE_1',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_LINE_2',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_CITY',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_COUNTY',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_POST_CODE',
    'STAFF.OVERVIEW.COLUMN_ADDRESS_COUNTRY',
    'STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_NAME',
    'STAFF.OVERVIEW.COLUMN_PAYROLL_PAYMENT_METHOD',
    'STAFF.OVERVIEW.COLUMN_PRE_AVERAGE_HOURS_PER_DAY',
    'STAFF.OVERVIEW.COLUMN_PRE_AVERAGE_HOURS_DATE',
    'STAFF.OVERVIEW.COLUMN_PREFERRED_NAME',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_ENTITY_GROUP',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_ENTITY',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_GROUP',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_POSITION',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_COMPANY',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_LINE_MANAGER',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_PAYROLL_CALENDAR',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_PAY_AMOUNT',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_PAY_AMOUNT_TYPE',
    'REPORTS.EMPLOYMENT_CHANGES.PROPERTY_PAY_UPLIFT',
  ]);

  const defaultDateStart = $stateParams.start && moment($stateParams.start).isValid()
    ? moment($stateParams.start) : moment().startOf('week');
  const defaultDateEnd = $stateParams.end && moment($stateParams.end).isValid()
    ? moment($stateParams.end) : moment().startOf('week').add(6, 'days');
  const defaultEntityIds = $stateParams.entityIds
    ? $stateParams.entityIds.split(',') : [];

  $scope.props = {
    loadingData: false,
    data: {},
    defaultDateFilter: {
      option: 4,
      dateStart: defaultDateStart,
      dateEnd: defaultDateEnd,
    },
    defaultEntityIds,
    selectedDateStart: defaultDateStart,
    selectedDateEnd: defaultDateEnd,
    selectedEntityIds: defaultEntityIds,
    entityById: {},
    propertyOptions: [
      { id: 'EmployeeFirstName', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_EMPLOYEE_FIRST_NAME'] },
      { id: 'EmployeeLastName', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_EMPLOYEE_LAST_NAME'] },
      { id: 'EmployeeHrId', label: translations['STAFF.OVERVIEW.COLUMN_HR_ID'] },
      { id: 'EmployeePayrollId', label: translations['STAFF.OVERVIEW.COLUMN_PAYROLL_ID'] },
      { id: 'EmployeePermissionLevelId', label: translations['STAFF.OVERVIEW.COLUMN_PERMISSION_LEVEL_NAME'] },
      { id: 'EmployeeBirthDate', label: translations['STAFF.OVERVIEW.COLUMN_BIRTH_DATE'] },
      { id: 'EmployeeAverageHoursDay', label: translations['STAFF.OVERVIEW.COLUMN_AVERAGE_HOURS_DAY'] },
      { id: 'EmployeeAverageHoursHalfDay', label: translations['STAFF.OVERVIEW.COLUMN_AVERAGE_HOURS_HALF_DAY'] },
      { id: 'EmployeeAverageHoursManuallySet', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_EMPLOYEE_ABSENCE_HOURS_MANUALLY_SET'] },
      { id: 'EmployeeEmail', label: translations['STAFF.OVERVIEW.COLUMN_EMAIL'] },
      { id: 'EmployeeMobile', label: translations['STAFF.OVERVIEW.COLUMN_MOBILE'] },
      { id: 'EmployeeNationalInsuranceNumber', label: translations['STAFF.OVERVIEW.COLUMN_NATIONAL_INSURANCE_NUMBER'] },
      { id: 'EmployeeBankAccountNumber', label: translations['STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_NUMBER'] },
      { id: 'EmployeeSortCode', label: translations['STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_SORT_CODE'] },
      { id: 'EmployeeTitleId', label: translations['STAFF.OVERVIEW.COLUMN_TITLE'] },
      { id: 'EmployeeGenderId', label: translations['STAFF.OVERVIEW.COLUMN_GENDER'] },
      { id: 'EmployeeOtherNames', label: translations['STAFF.OVERVIEW.COLUMN_OTHER_NAMES'] },
      { id: 'EmployeeNationalityId', label: translations['STAFF.OVERVIEW.COLUMN_NATIONALITY'] },
      { id: 'EmployeeWorkPhone', label: translations['STAFF.OVERVIEW.COLUMN_WORK_PHONE'] },
      { id: 'EmployeeWorkEmail', label: translations['STAFF.OVERVIEW.COLUMN_WORK_EMAIL'] },
      { id: 'EmployeeUkSettledStatus', label: translations['STAFF.OVERVIEW.COLUMN_SETTLED_STATUS'] },
      { id: 'EmployeeEthnicityId', label: translations['STAFF.OVERVIEW.COLUMN_ETHNICITY'] },
      { id: 'EmployeeMaritalStatusId', label: translations['STAFF.OVERVIEW.COLUMN_MARITAL_STATUS'] },
      { id: 'EmployeePassportNumber', label: translations['STAFF.OVERVIEW.COLUMN_PASSPORT_NUMBER'] },
      { id: 'EmployeePassportExpiryDate', label: translations['STAFF.OVERVIEW.COLUMN_PASSPORT_EXPIRY_DATE'] },
      { id: 'EmployeeAddressLine1', label: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_LINE_1'] },
      { id: 'EmployeeAddressLine2', label: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_LINE_2'] },
      { id: 'EmployeeAddressCity', label: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_CITY'] },
      { id: 'EmployeeAddressCounty', label: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_COUNTY'] },
      { id: 'EmployeeAddressPostCode', label: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_POST_CODE'] },
      { id: 'EmployeeAddressCountry', label: translations['STAFF.OVERVIEW.COLUMN_ADDRESS_COUNTRY'] },
      { id: 'EmployeeBankAccountName', label: translations['STAFF.OVERVIEW.COLUMN_BANK_ACCOUNT_NAME'] },
      { id: 'EmployeePayrollPaymentMethod', label: translations['STAFF.OVERVIEW.COLUMN_PAYROLL_PAYMENT_METHOD'] },
      { id: 'EmployeePreRotareadyAverageHoursPerDay', label: translations['STAFF.OVERVIEW.COLUMN_PRE_AVERAGE_HOURS_PER_DAY'] },
      { id: 'EmployeePreRotareadyAverageHoursDate', label: translations['STAFF.OVERVIEW.COLUMN_PRE_AVERAGE_HOURS_DATE'] },
      { id: 'EmployeePreferredName', label: translations['STAFF.OVERVIEW.COLUMN_PREFERRED_NAME'] },
      { id: 'AppointmentEntityGroup', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_ENTITY_GROUP'] },
      { id: 'AppointmentEntity', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_ENTITY'] },
      { id: 'AppointmentGroup', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_GROUP'] },
      { id: 'AppointmentPosition', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_POSITION'] },
      { id: 'AppointmentCompany', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_COMPANY'] },
      { id: 'AppointmentMinObligationValue', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MIN_OBLIGATION_VALUE'] },
      { id: 'AppointmentMinObligationUnit', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MIN_OBLIGATION_UNIT'] },
      { id: 'AppointmentMinObligationPeriod', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MIN_OBLIGATION_PERIOD'] },
      { id: 'AppointmentMaxObligationValue', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MAX_OBLIGATION_VALUE'] },
      { id: 'AppointmentMaxObligationUnit', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MAX_OBLIGATION_UNIT'] },
      { id: 'AppointmentMaxObligationPeriod', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_MAX_OBLIGATION_PERIOD'] },
      { id: 'AppointmentLineManager', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_LINE_MANAGER'] },
      { id: 'AppointmentPayrollCalendar', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_APPOINTMENT_PAYROLL_CALENDAR'] },
      { id: 'PayAmount', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_PAY_AMOUNT'] },
      { id: 'PayAmountType', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_PAY_AMOUNT_TYPE'] },
      { id: 'PayUplift', label: translations['REPORTS.EMPLOYMENT_CHANGES.PROPERTY_PAY_UPLIFT'] },
    ],
  };

  function evaluateQueryParams() {
    const {
      selectedDateStart: start,
      selectedDateEnd: end,
      selectedEntityIds,
    } = $scope.props;

    $state.go('.', {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
      entityIds: selectedEntityIds ? selectedEntityIds.join(',') : undefined,
    }, {
      notify: false,
      location: isFirstLoad ? true : 'replace',
    });

    if (isFirstLoad) {
      isFirstLoad = false;
    }

    $scope.getDataParams = {
      dateRange: [
        start.format('YYYY-MM-DD'),
        end.clone().add(1, 'day').format('YYYY-MM-DD'),
      ].join('/'),
      'currentEntityIds[]': selectedEntityIds,
    };
  }

  $scope.onEntityFilter = (selectedOptions) => {
    const entityIds = selectedOptions.filter((o) => typeof o === 'string');
    $scope.props.selectedEntityIds = entityIds.length ? entityIds : undefined;
    $scope.loadData();
  };

  $scope.onPropertyFilter = (selectedOptions) => {
    $scope.props.selectedProperties = selectedOptions;
  };

  $scope.changeSetFilter = (change) => {
    const { selectedProperties } = $scope.props;

    if (!selectedProperties || !selectedProperties.length) {
      return true;
    }

    return selectedProperties.includes(change.property);
  };

  $scope.onDateFilter = ({
    dateEnd,
    dateStart,
  }) => {
    $scope.props.selectedDateStart = dateStart;
    $scope.props.selectedDateEnd = dateEnd;

    $scope.loadData();
  };

  $scope.jumpTo = (when) => {
    if ($scope.props.loadingData) {
      return;
    }

    let dateStart;
    let dateEnd;

    if (when === 'thisWeek') {
      dateStart = moment().startOf('week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastWeek') {
      dateStart = moment().startOf('week').subtract(1, 'week');
      dateEnd = dateStart.clone().add(6, 'days');
    } else if (when === 'lastTwoWeeks') {
      dateStart = moment().startOf('week').subtract(2, 'weeks');
      dateEnd = dateStart.clone().add(13, 'days');
    } else if (when === 'lastMonth') {
      dateStart = moment().startOf('month').subtract(1, 'month');
      dateEnd = moment().startOf('month').subtract(1, 'day');
    }

    $scope.props.defaultDateFilter = {
      option: 4,
      dateStart,
      dateEnd,
    };

    $scope.onDateFilter({
      dateStart,
      dateEnd,
    });
  };

  function mapChangeModel(value) {
    switch (value) {
      case 'Employee':
        return translations['REPORTS.EMPLOYMENT_CHANGES.MODEL_EMPLOYEE'];
      case 'Appointment':
        return translations['REPORTS.EMPLOYMENT_CHANGES.MODEL_APPOINTMENT'];
      case 'Pay':
        return translations['REPORTS.EMPLOYMENT_CHANGES.MODEL_PAY'];
      default:
        return '';
    }
  }

  function getLabelFromStaticOptionList(list, value, labelKey = 'title', idIsInt = true) {
    const valueAsInt = idIsInt ? parseInt(value, 10) : value;
    const option = list.find(({ id }) => id === valueAsInt);
    return option ? option[labelKey] : '';
  }

  function mapChangeProperty(property) {
    const option = $scope.props.propertyOptions.find(({ id }) => id === property);
    return option ? option.label : '';
  }

  function mapValue(property, value) {
    switch (property) {
      case 'EmployeePermissionLevelId':
        return getLabelFromStaticOptionList($scope.props.permissionLevelList, value, 'name');
      case 'EmployeeBirthDate':
      case 'EmployeePassportExpiryDate':
      case 'EmployeePreRotareadyAverageHoursDate':
        return value ? moment(value).format('L') : '';
      case 'EmployeeTitleId':
        return getLabelFromStaticOptionList($scope.props.titleList, value);
      case 'EmployeeGenderId':
        return getLabelFromStaticOptionList($scope.props.genderList, value);
      case 'EmployeeNationalityId':
        return getLabelFromStaticOptionList(StaffService.getNationalityTypes(), value);
      case 'EmployeeUkSettledStatus':
        return getLabelFromStaticOptionList(StaffService.getUkSettledStatusTypes(), value, 'title', false);
      case 'EmployeeEthnicityId':
        return getLabelFromStaticOptionList($scope.props.ethnicityList, value);
      case 'EmployeeMaritalStatusId':
        return getLabelFromStaticOptionList(StaffService.getMaritalStatusTypes(), value);
      case 'EmployeePayrollPaymentMethod':
        return getLabelFromStaticOptionList(StaffService.getPayrollPaymentMethods(), value, 'title', false);
      case 'EmployeeAddressCountry':
        return getLabelFromStaticOptionList(StaffService.getCountryList(), value, 'title', false);
      case 'AppointmentMinObligationUnit':
      case 'AppointmentMaxObligationUnit':
        return getLabelFromStaticOptionList(StaffService.getContractualObligationUnits(), value);
      case 'AppointmentMinObligationPeriod':
      case 'AppointmentMaxObligationPeriod':
        return getLabelFromStaticOptionList(StaffService.getContractualObligationPeriods(), value);
      case 'PayAmountType':
        return getLabelFromStaticOptionList(StaffService.getPayAmountTypes(), value);
      default:
        return value;
    }
  }

  function mapGroupingId(model, value) {
    switch (model) {
      case 'Pay':
        return $scope.props.payElementTypeList.find(({ id }) => id === parseInt(value, 10)).name;
      default:
        return value;
    }
  }

  $scope.loadData = () => {
    const diff = $scope.props.selectedDateEnd.clone().add(1, 'day').diff($scope.props.selectedDateStart, 'years', true);

    if (diff < 0 || diff > 1) {
      AlertService.add('info', translations['REPORTS.EMPLOYMENT_CHANGES.DATE_ERROR_DIFF']);
      return;
    }

    evaluateQueryParams();

    $scope.props.loadingData = true;

    $http.get(`${ENDPOINT_API}/report/employmentChanges`, {
      params: $scope.getDataParams,
    })
      .then(({ data }) => {
        const { results } = data;

        $scope.props.data = results.map(({ user, changeSet }) => ({
          user,
          changeSet: changeSet.map(({
            model,
            property,
            effectiveDate,
            reasonId,
            newValue,
            oldValue,
            groupingId,
            edited,
          }) => ({
            model: mapChangeModel(model),
            property,
            propertyLabel: mapChangeProperty(property),
            effectiveDate: moment(effectiveDate).format('L'),
            reasonId,
            newValue: mapValue(property, newValue),
            oldValue: mapValue(property, oldValue),
            groupingId: mapGroupingId(model, groupingId),
            edited,
          })),
        }));

        $scope.props.loadingData = false;
      })
      .catch(({ status }) => {
        $scope.props.loadingData = false;

        if (status === 500) {
          AlertService.add('danger', translations['REPORTS.EMPLOYMENT_CHANGES.ERROR_500']);
        }
      });
  };

  EnvironmentDataService.fetchAll([
    EnvironmentDataService.DataType.EntityGroup,
    EnvironmentDataService.DataType.PayElementType,
    EnvironmentDataService.DataType.Ethnicity,
    EnvironmentDataService.DataType.Gender,
    EnvironmentDataService.DataType.Title,
    EnvironmentDataService.DataType.PermissionLevel,
  ])
    .then(([
      entityGroup,
      payElementType,
      ethnicity,
      gender,
      title,
      permissionLevel,
    ]) => {
      $scope.props.entityGroupList = entityGroup.data
        .flatMap(({
          id: groupId,
          name,
          entities,
          deleted,
        }) => ([
          {
            id: groupId,
            label: name,
            depth: 0,
            deleted,
          },
          ...entities.map((entity) => {
            $scope.props.entityById[entity.id] = entity;

            return {
              id: entity.id,
              label: entity.name,
              parentId: entity.groupId,
              depth: 1,
            };
          }),
        ]));

      $scope.props.payElementTypeList = payElementType.data;
      $scope.props.ethnicityList = ethnicity.data;
      $scope.props.genderList = gender.data;
      $scope.props.titleList = title.data;
      $scope.props.permissionLevelList = permissionLevel.data;

      $scope.loadData();
    });

  $scope.export = ($event, tableId, format) => {
    ExportService.export($event.currentTarget, translations['REPORTS.EMPLOYMENT_CHANGES.TITLE'],
      tableId, format);
  };
};
