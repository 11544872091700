const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/rotamanagement/shift/editModal.html');

'use strict';
var controllers = require('../../module');

controllers.controller('ViewShiftModalCtrl', ['$scope', '$uibModalInstance', '$timeout', '$uibModal', 'NgMap', 'RotaService', 'SessionService', 'data',
    function ($scope, $uibModalInstance, $timeout, $uibModal, NgMap, RotaService, SessionService, data) {
        $scope.dataLoaded = false;

        $scope.hasEditPermissions = SessionService.isPermissioned('rota');
        $scope.canViewRestBreak = SessionService.isPermissioned('rota.shifts.viewBreak');
        $scope.canViewLocation = SessionService.isPermissioned('rota.shifts.viewLocation');
        $scope.canViewShiftType = SessionService.isPermissioned('rota.shifts.viewShiftType');

        RotaService.getShift(data.id)
            .success(function (result) {
                $scope.shift = result.shift;
                $scope.assignedStaff = result.users;

                $scope.dataLoaded = true;
            })
            .error(function () {
                $scope.close();
            });

        $scope.close = function () {
            $scope.isClosing = true;
            $uibModalInstance.dismiss('cancel');
        };

        $scope.edit = function () {
            $scope.close();

            $uibModal.open({
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'EditShiftModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            isAdd: false,
                            id: $scope.shift.id,
                            staff: $scope.assignedStaff
                        }
                    }
                }
            });
        };

        // Location tab
        $scope.location = {
            showTraffic: false
        };

        var googleMap;
        var trafficLayer = new google.maps.TrafficLayer();

        $scope.locationTabSelected = function () {
            // angular-ui bug: select() is fired on each tab when modal closes
            if ($scope.isClosing) {
                return;
            }

            // If the location was assigned to the shift, then the location itself was deleted
            var latitude = parseFloat($scope.shift.location.latitude);
            var longitude = parseFloat($scope.shift.location.longitude);

            if (isNaN(latitude) || isNaN(longitude)) {
                return;
            }

            if (!googleMap) {
                NgMap.initMap('shiftMap');
            }

            NgMap.getMap({id: 'shiftMap'}).then(function (map) {
                googleMap = map;

                $timeout(function () {
                    google.maps.event.trigger(googleMap, 'resize');

                    var marker = new google.maps.Marker({
                        position: {
                            lat: latitude,
                            lng: longitude
                        },
                        map: googleMap,
                        title: $scope.shift.locationName
                    });

                    googleMap.panTo(marker.getPosition());
                    googleMap.setZoom(15);
                }, 500);
            });
        };

        $scope.$watch('location.showTraffic', function (val) {
            if (!googleMap) {
                return;
            }

            if (val) {
                trafficLayer.setMap(googleMap);
            } else {
                trafficLayer.setMap(null);
            }
        });
    }]);
