'use strict';
var controllers = require('../module');

controllers.controller('AbsenceUserCtrl', ['$scope', '$state', '$translate',
    function ($scope, $state, $translate) {
        var translations = $translate.instant([
            'AVAILABILITY.ABSENCE.TAB_ABSENCE',
            'AVAILABILITY.ABSENCE.TAB_ALLOWANCES',
        ]);

        $scope.props = {
            tabs: [
                {state: 'bookings', label: translations['AVAILABILITY.ABSENCE.TAB_ABSENCE']},
                {state: 'allowances', label: translations['AVAILABILITY.ABSENCE.TAB_ALLOWANCES']},
            ],
        };

        $scope.setSelectedTab = function (tab) {
            $state.go('personal.absence.' + tab.state);
        };
    }]);
