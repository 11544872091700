var animations = angular.module('rotareadyUiApp.animations', ['ngAnimate']);

animations.animation(".slide-animation", function () {
    return {
        enter: function (element, done) {
            try {
                element.hide();
                element.slideDown(function () {
                    done();
                });
            }
            catch (ex) {
                console.log(ex);
            }
        },
        leave: function (element, done) {
            element.slideUp(function () {
                done();
            });
        }
    };
});

module.exports = animations;
