module.exports = (
  $scope,
  $state,
  $translate,
  $uibModalInstance,
  SessionService,
  StaffService,
  AlertService,
  data,
) => {
  'ngInject';

  const {
    signOffData,
    selectedDateStart,
    selectedDateEnd,
    selectedEntityIds,
    entityGroups,
  } = data;

  $scope.props = {
    datesByMonth: [],
    showDates: true,
    showDetail: false,
    entityGroups,
  };

  const entityCount = selectedEntityIds.length;
  const dayCount = selectedDateEnd.diff(selectedDateStart, 'days') + 1;

  const dates = Array(dayCount).fill()
    .map((_, index) => {
      const date = selectedDateStart.clone().add(index, 'days');
      const dateIso = date.format('YYYY-MM-DD');
      const signOffs = signOffData.filter((r) => r.date === dateIso);
      const completeSignOffs = signOffs.filter((r) => !r.reversed);
      const complete = completeSignOffs.length >= entityCount;
      const noData = !completeSignOffs.length;

      return {
        date,
        dateIso,
        month: date.clone().startOf('month').valueOf(),
        signOffs,
        // eslint-disable-next-line no-nested-ternary
        class: complete ? 'label-success' : (noData ? 'label-danger' : 'label-warning'),
        label: date.format('D'),
      };
    });

  const months = Array.from(new Set(dates.map(({ month }) => month)));

  $scope.props.datesByMonth = months.map((date) => ({
    label: moment(date).format('MMMM YYYY'),
    dates: dates.filter(({ month }) => month === date),
  }));

  $scope.close = () => $uibModalInstance.dismiss();

  $scope.goToDate = (date) => {
    $scope.props.showDates = false;
    $scope.props.showDetail = true;
    $scope.props.selectedDate = date;

    $scope.props.dataByEntityGroup = entityGroups.flatMap((group) => {
      const entities = group.entities.flatMap((entity) => {
        if (!selectedEntityIds.includes(entity.id)) {
          return [];
        }

        const signOffs = date.signOffs.filter(({ entityId }) => entityId === entity.id)
          .reverse();

        return [{
          entity,
          signOffs: signOffs.slice(0, 3),
        }];
      });

      if (!entities.length) {
        return [];
      }

      return [{
        group,
        entities,
      }];
    });
  };

  $scope.clearDate = () => {
    $scope.props.showDates = true;
    $scope.props.showDetail = false;
  };
};
