const autoNgTemplateLoaderTemplate1 = require('/home/circleci/project/app/htdocs/views/directiveTpls/validationFailure.html');

var directives = require('./module');

directives.directive('rrValidationFailure', ['$rootScope', 'AvailabilityService',
    function ($rootScope, AvailabilityService) {
        return {
            restrict: 'E',
            templateUrl: autoNgTemplateLoaderTemplate1,
            scope: {
                failureObject: '=',
                onIgnore: '&',
                onTryAgain: '&?'
            },

            link: function ($scope) {
                $scope.doIgnore = function () {
                    $scope.ignoreActioning = true;
                    $scope.onIgnore();
                };

                $scope.$watch('failureObject', function () {
                    $scope.ignoreActioning = false;
                    $scope.tryAgainActioning = false;
                });

                $scope.doTryAgain = function () {
                    $scope.tryAgainActioning = true;
                    $scope.onTryAgain();
                }
            },

            controller: function ($scope) {
                $scope.addPatternException = function (data) {
                    $scope.isLoading = true;

                    var date = moment(data.event.start).format('YYYY-MM-DD');

                    var fields = {
                        date: date,
                        userId: data.event.userId,
                    };

                    AvailabilityService.addPatternException(fields)
                        .success(function () {
                            $scope.isLoading = false;

                            $rootScope.$broadcast('availabilityUpdate', {
                                operation: 1,
                                date: fields.date,
                                userId: fields.userId,
                            });

                            $scope.doIgnore();
                        })
                        .error(function (error, status) {
                            $scope.isLoading = false;

                            if (status === 500) {
                                alert('We were unable to make a pattern exception for that date. Please try again.');
                            }
                        });
                };
            },
        };
    }]);
