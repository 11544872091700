'use strict';
var controllers = require('../module');

controllers.controller('WeeklyAvailabilityModalCtrl', ['$scope', '$uibModalInstance', 'AvailabilityService', 'data',
    function ($scope, $uibModalInstance, availabilityService, data) {
        $scope.dataLoaded = data.isAdd;
        $scope.isAdd = data.isAdd;
        $scope.pattern = {
            info: {
                hasStopDate: 0
            },
            rules: []
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.weeklyAvailability = {
            dayOptionNames: ['Unavailable', 'Available', 'Available (AM only)', 'Available (PM only)', 'Other (specify...)'],
            dayOptions: [],

            weekOptions: [
                {week: 1, verbose: 'week'},
                {week: 2, verbose: '2 weeks'},
                {week: 3, verbose: '3 weeks'},
                {week: 4, verbose: '4 weeks'},
                {week: 5, verbose: '5 weeks'},
                {week: 6, verbose: '6 weeks'},
                {week: 7, verbose: '7 weeks'},
                {week: 8, verbose: '8 weeks'}
            ],

            selectedWeekOptionAsArray: [],

            initialise: function () {
                // Populate all the default day options
                var vm = this;
                angular.forEach(this.dayOptionNames, function (option, index) {
                    vm.dayOptions.push({
                        id: index,
                        name: option
                    });
                });

                // Default the selected week option to 1 week
                this.setSelectedWeekOption(1);
            },

            weekOptionToArray: function () {
                this.selectedWeekOptionAsArray = new Array(this.selectedWeekOption.week);
            },

            setSelectedWeekOption: function (weeks) {
                this.selectedWeekOption = this.weekOptions[weeks - 1];
                this.weekOptionToArray();
            },

            createAvailabilityOption: function (index) {
                return {
                    id: index,
                    startTime: moment('2014-01-01 09:00:00'),
                    endTime: moment('2014-01-01 17:00:00')
                };
            },

            // Resets an availability drop down to 1 (available) to hide time controls
            hideTimeControls: function (week, day) {
                this.getAvailabilityOption(week, day).id = 1;
            },

            // Getter for a given week/day of availability
            // Pads out a missing entry with a copy of the default option where necessary
            getAvailabilityOption: function (week, day) {
                if (!$scope.pattern.rules[week] ||
                    typeof $scope.pattern.rules[week] == 'undefined') {
                    $scope.pattern.rules[week] = [];
                    $scope.pattern.rules[week][day] = this.createAvailabilityOption(1);
                }

                if (!$scope.pattern.rules[week][day] ||
                    typeof $scope.pattern.rules[week][day] == 'undefined') {
                    $scope.pattern.rules[week][day] = this.createAvailabilityOption(1);
                }

                return $scope.pattern.rules[week][day];
            }
        };

        var daysOfWeek = [];
        for (var i = 0; i < 7; i++) {
            daysOfWeek.push({id: i, name: moment().weekday(i).format('dddd')});
        }

        $scope.weeklyAvailability.initialise();
        $scope.weeklyAvailability.daysOfWeek = daysOfWeek;

        // Week pickers for setting start/end dates
        $scope.datePickerStart = moment().startOf('week');
        $scope.datePickerEnd = moment().add(1, 'month');

        $scope.startDateChanged = function (newDate) {
            $scope.datePickerStart = newDate.clone().startOf('week');
        };

        $scope.endDateChanged = function (newDate) {
            $scope.datePickerEnd = newDate.clone();
        };

        if (!data.isAdd) {
            availabilityService.getAvailabilityPattern(data.id)
                .success(function (pattern) {
                    $scope.pattern = pattern;

                    if (pattern.rules.length > 0) {
                        $scope.weeklyAvailability.setSelectedWeekOption(pattern.rules.length);
                    }

                    $scope.datePickerStart = moment(pattern.info.date_start);

                    if (pattern.info.date_end) {
                        $scope.pattern.info.hasStopDate = 1;
                        $scope.datePickerEnd = moment(pattern.info.date_end);
                    } else {
                        $scope.pattern.info.hasStopDate = 0;
                    }

                    $scope.dataLoaded = true;
                })
                .error(function () {
                    $uibModalInstance.dismiss('cancel');
                });
        }

        $scope.saveAvailability = function () {
            if ($scope.pattern.info.hasStopDate && $scope.datePickerEnd.isSameOrBefore($scope.datePickerStart)) {
                return alert('Your pattern is currently set to stop before it starts!');
            }

            var toSend = {
                info: {
                    type: 1,
                    nickname: $scope.pattern.info.nickname,
                    dateStart: $scope.datePickerStart.format('YYYY-MM-DD'),
                    hasStopDate: $scope.pattern.info.hasStopDate,
                    dateEnd: $scope.datePickerEnd.format('YYYY-MM-DD'),
                    userId: data.userIdOverride
                },
                rules: angular.copy($scope.pattern.rules.slice(0, $scope.weeklyAvailability.selectedWeekOption.week))
            };

            var rulesOk = true;

            angular.forEach(toSend.rules, function (week) {
                angular.forEach(week, function (day) {
                    if (day.id !== 4 && day.id !== 5) {
                        return;
                    }

                    var startTime = moment(day.startTime);
                    var endTime = moment(day.endTime);

                    if (endTime.format('Hm') !== '00' && endTime.isSameOrBefore(startTime)) {
                        rulesOk = false;
                        return;
                    }

                    day.startTime = startTime.format('HH:mm:ss');
                    day.endTime = endTime.format('HH:mm:ss');
                });
            });

            if (!rulesOk) {
                return alert('One or more of your availability rules have an end time that falls before the start time.');
            }

            $scope.saveActioning = true;

            if (data.isAdd) {
                availabilityService.addAvailabilityPattern(toSend)
                    .success(function (newPattern) {
                        $uibModalInstance.close(newPattern);
                    })
                    .error(function (error, status) {
                        if (status == 403) {
                            $uibModalInstance.dismiss('cancel');
                            return;
                        }

                        $scope.saveActioning = false;
                        alert('Currently unable to save this availability pattern. Please try again.');
                    });
            } else {
                availabilityService.updateAvailabilityPattern(data.id, toSend)
                    .success(function (updatedPattern) {
                        $uibModalInstance.close(updatedPattern);
                    })
                    .error(function (error, status) {
                        if (status == 403) {
                            $uibModalInstance.dismiss('cancel');
                            return;
                        }

                        $scope.saveActioning = false;
                        alert('Currently unable to update this availability pattern. Please try again.');
                    });
            }
        };
    }]);
